import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import {
  SupportedLocaleEnum,
  SupportedLocaleEnumType,
  UpsertTranslationsForPlanInput,
} from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

import { Plan, Translation } from "../types";

export type EditPlanTranslationsFormValues = Partial<{
  planName: string;
  planNameEn: string;
  planNameKr: string;
  planNameCn: string;

  description: string;
  descriptionEn: string;
  descriptionKr: string;
  descriptionCn: string;

  featureLabel: string;
  featureLabelEn: string;
  featureLabelKr: string;
  featureLabelCn: string;
}>;

const getInitialValues = ({
  plan,
  localeToPlanNameTranslationMap,
  localeToDescriptionTranslationMap,
  localeToFeatureLabelTranslationMap,
}: {
  plan: Plan | undefined;
  localeToPlanNameTranslationMap: Map<SupportedLocaleEnum, Translation>;
  localeToDescriptionTranslationMap: Map<SupportedLocaleEnum, Translation>;
  localeToFeatureLabelTranslationMap: Map<SupportedLocaleEnum, Translation>;
}): EditPlanTranslationsFormValues => ({
  planName: plan?.planName ?? "",
  planNameEn: localeToPlanNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  planNameKr: localeToPlanNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  planNameCn: localeToPlanNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,

  description: plan?.description ?? "",
  descriptionEn: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  descriptionKr: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  descriptionCn: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,

  featureLabel: plan?.featuredLabelText ?? "",
  featureLabelEn: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  featureLabelKr: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  featureLabelCn: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
});

export const EditPlanTranslationsFormItem = createFormItem<EditPlanTranslationsFormValues>();

export const useEditPlanTranslationsForm = ({
  plan,
  translations,
  onSubmit,
}: {
  plan: Plan | undefined;
  translations: Translation[];
  onSubmit: (inputs: UpsertTranslationsForPlanInput) => void;
}) => {
  const localeToPlanNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.PlanName)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const localeToDescriptionTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Description)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const localeToFeatureLabelTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter(
            (translation) => translation.columnName === TranslationColumnNameEnum.FeaturedLabelText,
          )
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const [form] = Form.useForm<EditPlanTranslationsFormValues>();

  const initialValues = useMemo(
    () =>
      getInitialValues({
        plan,
        localeToPlanNameTranslationMap,
        localeToDescriptionTranslationMap,
        localeToFeatureLabelTranslationMap,
      }),
    [
      localeToPlanNameTranslationMap,
      localeToDescriptionTranslationMap,
      localeToFeatureLabelTranslationMap,
      plan,
    ],
  );

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    const {
      planNameEn,
      planNameKr,
      planNameCn,
      descriptionEn,
      descriptionCn,
      descriptionKr,
      featureLabelEn,
      featureLabelCn,
      featureLabelKr,
    } = formValues;

    const inputs = [
      {
        value: planNameEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.PlanName,
      },
      {
        value: planNameKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.PlanName,
      },
      {
        value: planNameCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.PlanName,
      },
      {
        value: descriptionEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: descriptionKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: descriptionCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: featureLabelEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
      {
        value: featureLabelKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
      {
        value: featureLabelCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
    ];

    const filterAndMapSources = (columnName: TranslationColumnNameEnum) =>
      inputs
        .filter((input) => input.columnName === columnName)
        .map(({ value, locale }) => ({ value, locale }));

    plan &&
      onSubmit({
        planId: plan.id,
        companyId: plan.company.id,
        planNameSources: filterAndMapSources(TranslationColumnNameEnum.PlanName),
        featureLabelSources: filterAndMapSources(TranslationColumnNameEnum.FeaturedLabelText),
        descriptionSources: filterAndMapSources(TranslationColumnNameEnum.Description),
      });
  }, [form, plan, onSubmit]);

  return { form, initialValues, submit };
};
