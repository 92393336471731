import React, { memo } from "react";
import styled from "styled-components";
import { Button, Card } from "antd";
import { englishCardTypeToJapaneseCardTypeMap } from "models/membershipCard";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { MembershipCardAppearanceTypeEnum } from "types/graphql";

import { AppearanceType, MembershipRankConfig } from "../types";

import { AppearanceTypeTable } from "./AppearanceTypeTable";
import {
  EditMembershipCardAppearanceTypeFormItem,
  OnSubmitEditMembershipCardAppearanceTypeForm,
  useEditMembershipCardAppearanceTypeForm,
} from "./useEditMembershipCardAppearanceTypeForm";

const CurrentAppearanceType = styled.span`
  font-weight: bold;
`;

type Props = {
  loading: boolean;
  appearanceTypes: AppearanceType[];
  currentAppearanceType: MembershipCardAppearanceTypeEnum | null;
  membershipRankConfig: MembershipRankConfig;
  onSubmit: OnSubmitEditMembershipCardAppearanceTypeForm;
};

export const EditMembershipCardAppearanceTypeForm = memo(
  ({ loading, appearanceTypes, currentAppearanceType, membershipRankConfig, onSubmit }: Props) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const { form, submit, resetForm } = useEditMembershipCardAppearanceTypeForm({
      loading,
      initialData: { appearanceType: currentAppearanceType ?? undefined },
      onSubmit,
    });
    return (
      <>
        <Card>
          <span>現在のカードデザイン: </span>
          <CurrentAppearanceType>
            {currentAppearanceType
              ? englishCardTypeToJapaneseCardTypeMap[currentAppearanceType]
              : "未設定"}
          </CurrentAppearanceType>
          <Spacer size={24} />
          <Form form={form} name="appearanceType" layout="vertical">
            <EditMembershipCardAppearanceTypeFormItem.NonProperty label="カードデザイン" required>
              <Spacer size={4} />
              <span>以下のデザインからお客さまのカードデザインに適応するものを選んでください</span>
              <Spacer size={8} />
              <EditMembershipCardAppearanceTypeFormItem name="appearanceType" noStyle>
                <AppearanceTypeTable
                  loading={loading}
                  appearanceTypes={appearanceTypes}
                  membershipRankConfig={membershipRankConfig}
                />
              </EditMembershipCardAppearanceTypeFormItem>
            </EditMembershipCardAppearanceTypeFormItem.NonProperty>
          </Form>
        </Card>
        <Spacer size={24} />
        {isFeatureEnabled("editMembershipCardAppearanceType") && (
          <FormActions>
            <Button onClick={resetForm}>キャンセル</Button>
            <Button type="primary" onClick={submit} loading={loading} disabled={loading}>
              更新
            </Button>
          </FormActions>
        )}
      </>
    );
  },
);
