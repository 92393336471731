import React, { memo } from "react";
import styled from "styled-components";
import { Button, Divider, Typography } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";

import { FileUploadField } from "./FileUploadField";
import { ConvertTecFormatFormValues, useConvertTecFormatForm } from "./useConvertTecFormatForm";

const StyledParagraph = styled(Typography.Paragraph)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  :last-of-type {
    margin-bottom: 8px;
  }
`;

type Props = {
  onSubmit: (args: ConvertTecFormatFormValues) => void;
  submitting: boolean;
};

export const ConvertTecFormatForm = memo<Props>(({ onSubmit, submitting }) => {
  const { form, submit } = useConvertTecFormatForm({ onSubmit });

  return (
    <>
      <FormContent>
        <SubTitle>TEC フォーマット加工手順</SubTitle>
        <StyledParagraph>通常の TEC フォーマットに以下の列を加えてください。</StyledParagraph>
        <SubTitle level={5}>1. 「プラン」列</SubTitle>
        <StyledParagraph>
          プランとして扱うメニュー行を「o」(英数字のオー)としてください
        </StyledParagraph>
        <SubTitle level={5}>2. 「オプション名」列</SubTitle>
        <StyledParagraph>
          選択肢として扱う行について、想定されるオプション名を記載してください
        </StyledParagraph>
        <SubTitle level={5}>3. 「個数選択式オプション」列</SubTitle>
        <StyledParagraph>
          個数選択を行うオプション(グラスなど)として扱う行を「o」としてください
        </StyledParagraph>
        <SubTitle level={5}>4. 「付属オプション名」列</SubTitle>
        <StyledParagraph>
          オプションをつけるメニューについて、「オプション名」列で名付けたオプション名をカンマ(,)区切りで指定してください
        </StyledParagraph>
        <Form name="convertTecFormat" form={form}>
          <FileUploadField title="menuMaster.csv" />
          <Divider />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={submit} loading={submitting}>
          アップロード
        </Button>
      </FormActions>
    </>
  );
});
