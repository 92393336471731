import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { EditCorporationForm } from "pages/EditCorporation/EditCorporationForm";

import { useEditCorporationGetCorporationQuery } from "./queries";

export const EditCorporation = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data,
    loading: loadingCorporation,
    error,
  } = useEditCorporationGetCorporationQuery(
    id !== undefined ? { variables: { corporationId: id } } : { skip: true },
  );
  const corporation = data?.corporation_by_pk;

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={corporation?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "法人一覧" }],
      }}
    >
      <PageHeader title={corporation?.name} onBack={goBack} />
      {loadingCorporation && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {corporation && <EditCorporationForm corporation={corporation} />}
    </DashboardLayout>
  );
};
