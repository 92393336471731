import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: #52c41a;
  font-size: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

type Props = {
  visible: boolean;
  fileName: string;
  closeModal: () => void;
  confirmModal: () => void;
};

export const UploadMenuCodeCsvCompleteModal = memo(
  ({ visible, fileName, closeModal, confirmModal }: Props) => (
    <Modal
      title={
        <TitleContainer>
          <StyledCheckCircleFilled />
          <Spacer size={16} />
          メニューコードの一括登録が完了しました
        </TitleContainer>
      }
      centered
      closable={false}
      open={visible}
      footer={
        <>
          <FooterContainer>
            <Button onClick={closeModal}>閉じる</Button>
            <Button type="primary" onClick={confirmModal}>
              メニューを確認
            </Button>
          </FooterContainer>
        </>
      }
    >
      <ContentWrapper>
        <Spacer size={38} />
        <>
          ファイル名:
          <br />
          {fileName}
        </>
      </ContentWrapper>
    </Modal>
  ),
);
