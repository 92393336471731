import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { OrderableTimeTermDayWeekType } from "models/orderableTime";

import { DragState } from "components/VerticalDragEventObserver/types";

import { HEIGHT_PER_HOUR } from "../constants";
import { WeeklyEvent } from "../types";

import { makeEventFromDragState } from "./makeEventFromDragState";

export type DragStateWithDayWeek = DragState & {
  dayWeek: OrderableTimeTermDayWeekType | undefined;
};
export const useDragState = ({
  changeDateTime,
  existedEvents,
}: {
  changeDateTime: dayjs.Dayjs;
  existedEvents: WeeklyEvent[];
}) => {
  const [dragState, setDragState] = useState<DragStateWithDayWeek>({
    dayWeek: undefined,
    isDrag: false,
    start: 0,
    distance: 0,
  });

  const setDragStateDiscretely = useCallback(
    ({ distance, start, ...newState }: DragStateWithDayWeek) => {
      const unitPx = HEIGHT_PER_HOUR / 4; // height of 15 min
      // 上からのドラッグに最適化
      const discreteStart = Math.floor(start / unitPx) * unitPx;
      const discreteDistance = Math.ceil(distance / unitPx) * unitPx;

      setDragState({ ...newState, start: discreteStart, distance: discreteDistance });
    },
    [setDragState],
  );

  const draggingEvent = useMemo(
    () => makeEventFromDragState(dragState, existedEvents, changeDateTime),
    [dragState, existedEvents, changeDateTime],
  );

  const makeEvent = useCallback(
    (state: DragStateWithDayWeek) => {
      if (!state.dayWeek) return;
      const event = makeEventFromDragState(state, existedEvents, changeDateTime);
      if (!event) return;

      // クリックしただけで作成されないように，30分以上のイベントのみ作成可能にする
      if (Math.abs(state.distance) >= HEIGHT_PER_HOUR / 2) {
        return event;
      }
    },
    [existedEvents, changeDateTime],
  );

  return {
    dragState,
    setDragStateDiscretely,
    draggingEvent,
    makeEvent,
  };
};
