import React, { memo, useCallback } from "react";
import { FormInstance, NamePath } from "rc-field-form/lib/interface";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

const MIN_CHOICE_NUM_FIELD = "minChoiceNum";
const MAX_CHOICE_NUM_FIELD = "maxChoiceNum";

type Props = FormItemProps & {
  minChoiceNumFieldName?: NamePath;
  disabled?: boolean;
};

export const MaxChoiceNumWithMinChoiceNumField = memo<Props>(
  ({
    children,
    minChoiceNumFieldName = MIN_CHOICE_NUM_FIELD,
    disabled = false,
    rules = [],
    ...props
  }) => {
    const maxChoiceNumWithMinChoiceNum = useCallback(
      (form: FormInstance) => {
        // NOTE: 最大選択数が最小選択数より小さい場合にエラーを出す
        const minChoiceNum: number = form.getFieldValue(minChoiceNumFieldName) ?? 0;
        return {
          async validator(_: unknown, value: number | null) {
            if (value === null || value >= minChoiceNum) return;
            throw new Error("最小選択数以上の値を入力してください");
          },
        };
      },
      [minChoiceNumFieldName],
    );

    return (
      <FormItem
        name={props.name ?? MAX_CHOICE_NUM_FIELD}
        rules={disabled ? [] : [...rules, maxChoiceNumWithMinChoiceNum]}
        // NOTE: minChoiceNumFieldName の変更時にバリデーションの再検証を走らせるため（shouldUpdate では再レンダリングはされるがバリデーションが実行されず代用不可）
        // eslint-disable-next-line react/forbid-component-props
        dependencies={[minChoiceNumFieldName]}
        {...props}
      >
        {children}
      </FormItem>
    );
  },
);
