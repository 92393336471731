import React, { memo } from "react";
import { DatabaseOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum } from "types/graphql";

export const MenuMasterMenu = memo<SubMenuPropsWithoutFeatures>((props) => (
  <SubMenu
    key="menuMaster"
    icon={<DatabaseOutlined />}
    title="メニューマスター"
    features={[DashboardFeatureEnum.MenuMasterData]}
    {...props}
  >
    <MenuItem route="menus" to="/menu" text="メニュー" />
    <MenuItem route="plans" to="/plan" text="プラン" />
    <MenuItem route="options" to="/option" text="オプション" />
    <MenuItem route="categories" to="/category" text="カテゴリ" />
    <MenuItem route="menuRecommendations" to="/menuRecommendations" text="おすすめ" />
    <MenuItem route="planGroups" to="/planGroup" text="プラングループ" />
    <MenuItem route="menuMasterCsv" to="/menuMasterCsv" text="メニュー一括編集" />
  </SubMenu>
));
