import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationPlanGetPlan = gql`
    query EditTecAggregationPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const EditTecAggregationPlanGetMenuCode = gql`
    query EditTecAggregationPlanGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const EditTecAggregationPlanUpdateTecAggregationMenu = gql`
    mutation EditTecAggregationPlanUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditTecAggregationPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditTecAggregationPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type EditTecAggregationPlanGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationPlanGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type EditTecAggregationPlanUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertTecAggregationMenuInput;
}>;


export type EditTecAggregationPlanUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) }
);


export const EditTecAggregationPlanGetPlanDocument = gql`
    query EditTecAggregationPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useEditTecAggregationPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditTecAggregationPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationPlanGetPlanQuery, EditTecAggregationPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationPlanGetPlanQuery, EditTecAggregationPlanGetPlanQueryVariables>(EditTecAggregationPlanGetPlanDocument, options);
      }
export function useEditTecAggregationPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationPlanGetPlanQuery, EditTecAggregationPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationPlanGetPlanQuery, EditTecAggregationPlanGetPlanQueryVariables>(EditTecAggregationPlanGetPlanDocument, options);
        }
export type EditTecAggregationPlanGetPlanQueryHookResult = ReturnType<typeof useEditTecAggregationPlanGetPlanQuery>;
export type EditTecAggregationPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditTecAggregationPlanGetPlanLazyQuery>;
export type EditTecAggregationPlanGetPlanQueryResult = Apollo.QueryResult<EditTecAggregationPlanGetPlanQuery, EditTecAggregationPlanGetPlanQueryVariables>;
export const EditTecAggregationPlanGetMenuCodeDocument = gql`
    query EditTecAggregationPlanGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useEditTecAggregationPlanGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationPlanGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationPlanGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationPlanGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditTecAggregationPlanGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationPlanGetMenuCodeQuery, EditTecAggregationPlanGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationPlanGetMenuCodeQuery, EditTecAggregationPlanGetMenuCodeQueryVariables>(EditTecAggregationPlanGetMenuCodeDocument, options);
      }
export function useEditTecAggregationPlanGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationPlanGetMenuCodeQuery, EditTecAggregationPlanGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationPlanGetMenuCodeQuery, EditTecAggregationPlanGetMenuCodeQueryVariables>(EditTecAggregationPlanGetMenuCodeDocument, options);
        }
export type EditTecAggregationPlanGetMenuCodeQueryHookResult = ReturnType<typeof useEditTecAggregationPlanGetMenuCodeQuery>;
export type EditTecAggregationPlanGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditTecAggregationPlanGetMenuCodeLazyQuery>;
export type EditTecAggregationPlanGetMenuCodeQueryResult = Apollo.QueryResult<EditTecAggregationPlanGetMenuCodeQuery, EditTecAggregationPlanGetMenuCodeQueryVariables>;
export const EditTecAggregationPlanUpdateTecAggregationMenuDocument = gql`
    mutation EditTecAggregationPlanUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditTecAggregationPlanUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<EditTecAggregationPlanUpdateTecAggregationMenuMutation, EditTecAggregationPlanUpdateTecAggregationMenuMutationVariables>;

/**
 * __useEditTecAggregationPlanUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationPlanUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationPlanUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationPlanUpdateTecAggregationMenuMutation, { data, loading, error }] = useEditTecAggregationPlanUpdateTecAggregationMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTecAggregationPlanUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationPlanUpdateTecAggregationMenuMutation, EditTecAggregationPlanUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationPlanUpdateTecAggregationMenuMutation, EditTecAggregationPlanUpdateTecAggregationMenuMutationVariables>(EditTecAggregationPlanUpdateTecAggregationMenuDocument, options);
      }
export type EditTecAggregationPlanUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useEditTecAggregationPlanUpdateTecAggregationMenuMutation>;
export type EditTecAggregationPlanUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<EditTecAggregationPlanUpdateTecAggregationMenuMutation>;
export type EditTecAggregationPlanUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationPlanUpdateTecAggregationMenuMutation, EditTecAggregationPlanUpdateTecAggregationMenuMutationVariables>;