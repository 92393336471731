export const PermissionLabel = {
  Editable: {
    label: "編集可能",
    help: "機能全体が使える権限です。閲覧可能の権限も含まれます。",
  },
  Viewable: {
    label: "閲覧可能",
    help: "閲覧のみの権限です。編集を行うことはできません。",
  },
  NotViewable: {
    label: "閲覧不可",
    help: "閲覧および編集ができなくなります。",
  },
} as const;
