import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { FormInstance } from "antd";

import { VisitedBusinessOperationHoursLabel } from "components/CustomerSegment/VisitedBusinessOperationHoursLabel";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import {
  AddMessageDeliveryFormItem,
  AddMessageDeliveryFormValues,
} from "pages/AddMessageDelivery/AddMessageDeliveryForm/useAddMessageDeliveryForm";
import { Menu, Shop } from "pages/AddMessageDelivery/types";
import { BusinessOperationHourTypeEnum } from "types/graphql";

import { BirthdayCountFilter } from "./BirthdayCountFilter";
import { ConsecutiveUnvisitedDayCountFilter } from "./ConsecutiveUnvisitedDayCountFilter";
import { GenderFilter } from "./GenderFilter";
import { OrderedMenusFilter } from "./OrderedMenusFilter";
import { QuestionnaireAnswerStatusFilter } from "./QuestionnaireAnswerStatusFilter";
import { VisitedBusinessOperationHoursFilter } from "./VisitedBusinessOperationHoursFilter";
import { VisitedDayCountFilterField } from "./VisitedDayCountFilterField";
import { VisitedDayOfWeekFilter } from "./VisitedDayOfWeekFilter";
import { VisitedShopsFilter } from "./VisitedShopsFilter";

const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type Props = {
  form: FormInstance<AddMessageDeliveryFormValues>;
  shops: Shop[];
  menus: Menu[];
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
};

export const CustomerSegmentField = memo<Props>(
  ({ form, shops, menus, shopBusinessOperationHourTypes }) => {
    const [isOrderedMenuSegmentActive, setIsOrderedMenusSegmentActive] = useState(false);

    const changeIsOrderedMenuSegmentActive = useCallback(
      ({ selectedMenuIds }: { selectedMenuIds: number[] }) =>
        setIsOrderedMenusSegmentActive(selectedMenuIds.length > 0),
      [],
    );

    return (
      <Wrapper>
        <AddMessageDeliveryFormItem.NonProperty
          label={
            <>
              <img
                src="/message-delivery/directions-walk.png"
                width={20}
                height={20}
                alt="来店回数"
              />
              <Spacer size={4} />
              来店回数
            </>
          }
          style={{ marginBottom: 0 }}
        >
          <ItemContent>
            <VisitedDayCountFilterField form={form} />
          </ItemContent>
        </AddMessageDeliveryFormItem.NonProperty>
        <AddMessageDeliveryFormItem.NonProperty
          label={
            <>
              <img
                src="/message-delivery/carry-out.png"
                width={20}
                height={20}
                alt="来店からの日数"
              />
              <Spacer size={4} />
              来店からの日数
            </>
          }
          style={{ marginBottom: 0 }}
        >
          <ItemContent>
            <ConsecutiveUnvisitedDayCountFilter form={form} />
          </ItemContent>
        </AddMessageDeliveryFormItem.NonProperty>
        <AddMessageDeliveryFormItem.NonProperty label="来店した曜日">
          <ItemContent>
            <VisitedDayOfWeekFilter />
          </ItemContent>
        </AddMessageDeliveryFormItem.NonProperty>
        {Boolean(shopBusinessOperationHourTypes.length) && (
          <AddMessageDeliveryFormItem.NonProperty label={<VisitedBusinessOperationHoursLabel />}>
            <ItemContent>
              <VisitedBusinessOperationHoursFilter
                shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
              />
            </ItemContent>
          </AddMessageDeliveryFormItem.NonProperty>
        )}
        <AddMessageDeliveryFormItem.NonProperty label="アンケート回答状況">
          <ItemContent>
            <QuestionnaireAnswerStatusFilter />
          </ItemContent>
        </AddMessageDeliveryFormItem.NonProperty>

        <AddMessageDeliveryFormItem.NonProperty label="来店した店舗">
          <VisitedShopsFilter form={form} shops={shops} />
        </AddMessageDeliveryFormItem.NonProperty>

        <AddMessageDeliveryFormItem.NonProperty label="注文したメニュー">
          <OrderedMenusFilter
            form={form}
            menus={menus}
            onChangeSelectedMenuIds={changeIsOrderedMenuSegmentActive}
          />
        </AddMessageDeliveryFormItem.NonProperty>

        <AddMessageDeliveryFormItem.NonProperty label="性別">
          <GenderFilter />
        </AddMessageDeliveryFormItem.NonProperty>

        <AddMessageDeliveryFormItem.NonProperty label="誕生日までの日数">
          <BirthdayCountFilter form={form} />
        </AddMessageDeliveryFormItem.NonProperty>
      </Wrapper>
    );
  },
);
