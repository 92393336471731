import React, { memo } from "react";

import { Select } from "components/Input/Select";

import { Table } from "../../types";

type Props = {
  value?: string;
  tables: Table[];
  onChange: (value: string) => void;
};

export const TableField = memo<Props>(({ value, tables, onChange }) => (
  <Select
    value={value}
    options={tables.map(({ id, name }) => ({ label: name, value: id }))}
    onChange={(value) => onChange(value)}
    optionFilterProp="label"
    showSearch
    fullWidth
  />
));
