import React, { memo, useCallback } from "react";
import { FormListFieldData } from "antd";
import { ColumnsType } from "antd/lib/table";

import { FormList } from "components/antd/Form";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { EditTecAggregationPlanFormItem } from "pages/EditTecAggregationPlan/EditTecAggregationPlanForm/useEditTecAggregationPlanForm";

import { TecAggregationGetAvailableCodeForOptionButtonField } from "./TecAggregationGetAvailableMenuCdForOptionButtonField";
import { TecAggregationPlanChoiceTable } from "./TecAggregationPlanChoiceTable";

type Props = {
  loading?: boolean;
  handleGetAvailableMenuCodeForOptionButtonPressed: () => void;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const TecAggregationPlanOptionTable = memo<Props>(
  ({ loading, handleGetAvailableMenuCodeForOptionButtonPressed }) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<FormListFieldDataSource> = [
      {
        title: "オプション名",
        key: "name",
        render(_: unknown, field: FormListFieldDataSource) {
          return (
            <EditTecAggregationPlanFormItem.NonProperty noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const optionName = getFieldValue(["plan", "planOptions", field.name, "name"]);
                return <span>{optionName}</span>;
              }}
            </EditTecAggregationPlanFormItem.NonProperty>
          );
        },
      },
    ];

    const expandedRowRender = useCallback(
      (optionField: FormListFieldData) => (
        <TecAggregationPlanChoiceTable optionField={optionField} />
      ),
      [],
    );

    return (
      <FormList name={["plan", "planOptions"]}>
        {(planOptionFields) => (
          <Table
            rowKey="planOptionId"
            columns={columns}
            dataSource={planOptionFields}
            loading={loading}
            bordered
            expandable={{ expandedRowRender, defaultExpandAllRows: true }}
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            shouldDisableMaxWidthScroll
            footer={() => (
              <TecAggregationGetAvailableCodeForOptionButtonField
                handleGetAvailableMenuCodeButtonPressed={
                  handleGetAvailableMenuCodeForOptionButtonPressed
                }
              />
            )}
          />
        )}
      </FormList>
    );
  },
);
