import React, { memo } from "react";
import dayjs from "dayjs";
import { formatOrderableTimeWithChangeDateTime } from "models/orderableTime";

import { eventToPosition } from "../calcEventPosition";
import { HEIGHT_PER_HOUR } from "../constants";
import { EventCell } from "../EventCell";
import { WeeklyEvent } from "../types";

export const DraggingEventCell = memo(
  ({ event, changeDateTime }: { event: WeeklyEvent | undefined; changeDateTime: dayjs.Dayjs }) => {
    if (!event) return <></>;
    const { top, height } = eventToPosition(event, changeDateTime, HEIGHT_PER_HOUR);

    const text = formatOrderableTimeWithChangeDateTime({
      ...event,
      changeDateTime: changeDateTime.format("HH:mm"),
    });
    return (
      <EventCell top={top} height={height} isDragging>
        {/* 小さいと時間が見えないため、1時間以上のときに表示 */}
        {height / HEIGHT_PER_HOUR >= 1 ? `${text.start} - ${text.end}` : <></>}
      </EventCell>
    );
  },
);
