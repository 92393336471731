import { useCallback, useMemo } from "react";
import { isNotNullable } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccount } from "pages/EditDashboardAccount/types";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

export type EditDashboardAccountFormValues = {
  name: string;
  password: string;
  companies: string[];
  shops: string[];
  scope: "corporation" | "company" | "shop";
  email: string;
  dashboardAccountRoleId: string;
};

const getInitialValues = (dashboardAccount: DashboardAccount) => {
  const { userName, role, email } = dashboardAccount;
  return {
    name: userName,
    password: "",
    companies: dashboardAccount.dashboardAccountAccessibleCompanies
      .map(({ company }) => company?.id)
      .filter(isNotNullable),
    shops: dashboardAccount.dashboardAccountAccessibleShops.map(({ shop }) => shop.shopId),
    scope:
      role === DashboardAccountRoleTypeEnum.CorporationManager
        ? "corporation"
        : role === DashboardAccountRoleTypeEnum.CompanyManager
        ? "company"
        : role === DashboardAccountRoleTypeEnum.ShopMember
        ? "shop"
        : "",
    email,
    dashboardAccountRoleId: dashboardAccount.dashboardAccountRole.id,
  };
};

export const EditDashboardAccountFormItem = createFormItem<EditDashboardAccountFormValues>();

export const useEditDashboardAccountForm = ({
  dashboardAccount,
  onSubmit,
}: {
  dashboardAccount: DashboardAccount;
  onSubmit: (formValues: EditDashboardAccountFormValues) => void;
}) => {
  const [form] = Form.useForm<EditDashboardAccountFormValues>();

  const initialValues = useMemo(() => getInitialValues(dashboardAccount), [dashboardAccount]);

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit, reset: form.resetFields };
};
