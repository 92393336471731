import React, { memo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useShopSelectPerCorporation } from "hooks/useShopSelectPerCorporation";

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
`;

const StyledLink = styled(Link)`
  font-size: 14px;
`;

type Props = {
  name: string;
  shopId?: string;
  disabled: boolean;
};

export const NameCell = memo<Props>(({ name, shopId, disabled }) => {
  const { state } = useLocation();
  const { setShop } = useShopSelectPerCorporation();

  const handleSetShop = useCallback(
    () => (shopId && !disabled ? setShop(shopId) : null),
    [shopId, disabled, setShop],
  );

  return disabled ? (
    <Name>{name}</Name>
  ) : (
    <StyledLink to="/questionnaireAnalytics/shop" state={state} onClick={handleSetShop}>
      {name}
    </StyledLink>
  );
});
