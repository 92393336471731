import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { Role } from "pages/EditRole/types";

export type AddRoleFormValues = Pick<
  Role,
  | "name"
  | "shouldPrintDishUpSlip"
  | "shouldPrintPaymentReceipt"
  | "firstSpareRoleId"
  | "secondSpareRoleId"
  | "printerSound"
  | "printDishUpSlipAsPrimarySlip"
  | "shopId"
  | "companyId"
> & {
  shouldPrintFreeOrderOnDishUpSlip: boolean;
  shouldPrintOnlinePaymentSlip: boolean;
};
const getInitialValues = () => ({
  shouldPrintDishUpSlip: false,
  shouldPrintPaymentReceipt: false,
  printerSound: 1,
  printDishUpSlipAsPrimarySlip: false,
  shouldPrintFreeOrderOnDishUpSlip: false,
  shouldPrintOnlinePaymentSlip: false,
});

export const AddRoleFormItem = createFormItem<AddRoleFormValues>();

export const useAddRoleForm = (onSubmit: (role: AddRoleFormValues) => void) => {
  const [company] = useCompany();
  const [shop] = useShop();

  const companyId = company?.id;
  const shopId = shop?.shopId;

  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    if (!companyId || !shopId) return;

    const values = form.getFieldsValue();

    onSubmit({
      ...values,
      companyId,
      shopId,
    });
  }, [form, onSubmit, companyId, shopId]);

  return { form, initialValues, submit };
};
