import { useCallback } from "react";
import useEffectOnce from "react-use/esm/useEffectOnce";
import { useForm } from "antd/lib/form/Form";

import { createFormItem } from "components/antd/Form";

export type AddAccountingSlipImageFormValues = {
  description: string | null;
  imageUrl: string | null;
};

const getInitialValues = () => ({ description: null, imageUrl: null });

export const AddAccountingSlipImageFormItem = createFormItem<AddAccountingSlipImageFormValues>();

export const useAddAccountingSlipImageForm = ({
  onChange,
}: {
  onChange: (values: AddAccountingSlipImageFormValues) => void;
}) => {
  const [form] = useForm();
  const initialValues = getInitialValues();

  useEffectOnce(() => {
    onChange(initialValues);
  });

  const onValuesChange = useCallback(
    ({ imageUrl }: { imageUrl: string | null }) => {
      const values = form.getFieldsValue() as AddAccountingSlipImageFormValues;
      onChange({ ...values, imageUrl });
    },
    [form, onChange],
  );

  return { form, initialValues, onValuesChange };
};
