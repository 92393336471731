import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Spin } from "antd";

type Size = "large" | "medium";

const getAntSize = (size?: Size) => (size === "medium" ? "default" : size);

const StyledLoading = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      flex: 1;
    `};
  ${({ width }) =>
    width !== undefined
      ? css`
          width: ${width}px;
        `
      : null};
  ${({ height }) =>
    height !== undefined
      ? css`
          height: ${height}px;
        `
      : null};
`;

const StyledSpin = styled(Spin)`
  display: inline-flex;
`;

type Props = {
  width?: number;
  height?: number;
  fullWidth?: boolean;
  size?: Size;
};

export const Loading = memo<Props>(({ width, height, size }) => (
  <StyledLoading width={width} height={height}>
    <StyledSpin size={getAntSize(size)} />
  </StyledLoading>
));
