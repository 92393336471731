import React, { memo, useMemo } from "react";
import { ColumnsType } from "antd/es/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { LineReportingBotConfig } from "types/graphql";

type LineReportingBotConfigType = Pick<LineReportingBotConfig, "id" | "name">;

type Props = {
  loading?: boolean;
  lineReportingBotConfigs: LineReportingBotConfigType[];
  isEditFeatureEnabled: boolean;
};

export const LineReportingBotConfigTable = memo<Props>(
  ({ loading, lineReportingBotConfigs, isEditFeatureEnabled }) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<LineReportingBotConfigType> = useMemo(
      () => [
        {
          title: "グループ名",
          dataIndex: "name",
        },
        ...(isEditFeatureEnabled
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                fixed: "right",
                render(_: string, { id }: LineReportingBotConfigType) {
                  return (
                    <IconLink to={`/lineReportingBotConfig/${id}/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                },
              } as const,
            ]
          : []),
      ],
      [isEditFeatureEnabled],
    );

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={lineReportingBotConfigs}
        loading={loading}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
