import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Input, Row } from "antd";
import { filterCompany } from "models/company";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";

import { CompanyTable } from "./CompanyTable";
import { useCompaniesArchiveCompaniesMutation, useCompaniesGetCompaniesQuery } from "./queries";

export const Companies = () => {
  const { canAccess } = useCanAccess();
  const {
    data: getCompaniesData,
    loading: loadingCompanies,
    refetch: refetchCompanies,
    error,
  } = useCompaniesGetCompaniesQuery();
  const [name, setName] = useState("");
  const filteredCompanies = useMemo(() => {
    const companies = getCompaniesData?.company ?? [];
    return filterCompany(companies, { name });
  }, [getCompaniesData, name]);

  const [archiveCompaniesMutation] = useCompaniesArchiveCompaniesMutation();

  const onArchive = useCallback(
    async ({ companyId }: { companyId: string }) => {
      try {
        await archiveCompaniesMutation({
          variables: { input: { companyId } },
        });
        message.success("アーカイブしました");
      } catch (err) {
        message.error("アーカイブに失敗しました");
      }
      await refetchCompanies();
    },
    [archiveCompaniesMutation, refetchCompanies],
  );

  const onChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <DashboardLayout title="業態一覧">
      <PageHeader
        title="業態一覧"
        extra={[
          canAccess("addCompany") && (
            <Link key="add" to="/company/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={
          <Row>
            <Input allowClear placeholder="業態名" value={name} onChange={onChangeFilter} />
          </Row>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CompanyTable
        companies={filteredCompanies}
        loading={loadingCompanies}
        onArchive={onArchive}
      />
    </DashboardLayout>
  );
};
