import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import dayjs from "dayjs";
import { formatOrderableTimeTermDayWeek } from "models/orderableTime";

import { colors } from "constants/colors";

import { DayWeekKeys, HEIGHT_PER_HOUR, Hours } from "../constants";

const HOUR_LABEL_WIDTH = 60;
const DAY_WEEK_LABEL_HEIGHT = 40;

const DayWeekScheduleGridContainer = styled.div`
  display: grid;
  grid-template: ${DAY_WEEK_LABEL_HEIGHT}px 1fr / ${HOUR_LABEL_WIDTH}px 1fr;
  grid-template-areas:
    "empty header"
    "aside main";
  user-select: none;
`;

const HourLabelColumn = styled.div`
  grid-area: aside;
  overflow: visible;
  border-right: 1px solid ${colors.Border.Default};
`;

const HourLabelColumnCell = styled.div<{ translateY: number }>`
  position: relative;
  height: ${HEIGHT_PER_HOUR}px;
  & span {
    position: absolute;
    top: 0;
    right: 1rem;
    transform: translate(0, -50%);
  }
  ${({ translateY }) => css`
    transform: translateY(${translateY}px);
  `}
`;

const VerticalDividerContainer = styled.div`
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(${DayWeekKeys.length}, 1fr);
`;

const VerticalDivider = styled.div`
  border-right: 1px solid ${colors.Divider.Default};
`;

const HorizontalDividerContainer = styled.div`
  grid-area: main;
  border-bottom: 1px solid ${colors.Divider.Default};
  position: relative;
`;

// border が連続して表示されないように position: absolute で配置する
const TopBorder = styled.div`
  border-top: 1px solid ${colors.Border.Default};
  position: absolute;
  top: 0;
  width: 100%;
`;

const HorizontalDivider = styled.div<{ translateY: number }>`
  border-top: 1px solid ${colors.Divider.Default};
  height: ${HEIGHT_PER_HOUR}px;

  ${({ translateY }) => css`
    transform: translateY(${translateY}px);
  `};
`;

const DayWeekLabelContainer = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: repeat(${DayWeekKeys.length}, 1fr);
`;

const DayWeekLabel = styled.div`
  display: grid;
  place-items: center;
  height: ${DAY_WEEK_LABEL_HEIGHT}px;
`;

const DayWeekColumnContainer = styled.div`
  grid-area: main;
  // transform することで重なり順が変わるので，明示的に最前にする
  z-index: 1;
`;

const LeftTopEmptySpace = styled.div`
  grid-area: empty;
`;

/**
 * Scheduler の見た目だけの部分
 */
export const DayWeekSchedulerDecorator = React.memo(
  ({ changeDateTime, children }: { changeDateTime: dayjs.Dayjs; children: React.ReactNode }) => {
    const getHourLabel = useCallback(
      (idx: number) =>
        changeDateTime.minute() > 0
          ? `${idx + 1 + changeDateTime.hour()}:00`
          : `${idx + changeDateTime.hour()}:00`,
      [changeDateTime],
    );

    const translateY = (changeDateTime.minute() * HEIGHT_PER_HOUR) / 60;

    return (
      <DayWeekScheduleGridContainer>
        <LeftTopEmptySpace />
        <HourLabelColumn>
          {Hours.map((hour) => (
            <HourLabelColumnCell key={hour} translateY={translateY}>
              <span>{getHourLabel(hour)}</span>
            </HourLabelColumnCell>
          ))}
        </HourLabelColumn>
        <HorizontalDividerContainer>
          <TopBorder />
          {Hours.map((hour) => (
            <HorizontalDivider key={hour} translateY={translateY} />
          ))}
        </HorizontalDividerContainer>
        <VerticalDividerContainer>
          {DayWeekKeys.map((dayWeek) => (
            <VerticalDivider key={dayWeek} />
          ))}
        </VerticalDividerContainer>
        <DayWeekLabelContainer>
          {DayWeekKeys.map((dayWeek) => (
            <DayWeekLabel key={dayWeek}>{formatOrderableTimeTermDayWeek(dayWeek)}</DayWeekLabel>
          ))}
        </DayWeekLabelContainer>
        <DayWeekColumnContainer>{children}</DayWeekColumnContainer>
      </DayWeekScheduleGridContainer>
    );
  },
);
