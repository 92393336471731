import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SalesAnalyticsGetBusinessOperationHourTypesByShopId = gql`
    query SalesAnalyticsGetBusinessOperationHourTypesByShopId($shopId: uuid!) {
  shopBusinessOperationHour(where: {shopId: {_eq: $shopId}}) {
    businessOperationHourType
    start
    end
  }
}
    `;
export const SalesAnalyticsGetShops = gql`
    query SalesAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies {
      id
      name
      shops {
        shopId
        name
      }
    }
  }
}
    `;
export type SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'businessOperationHourType' | 'start' | 'end'>
  )> }
);

export type SalesAnalyticsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type SalesAnalyticsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);


export const SalesAnalyticsGetBusinessOperationHourTypesByShopIdDocument = gql`
    query SalesAnalyticsGetBusinessOperationHourTypesByShopId($shopId: uuid!) {
  shopBusinessOperationHour(where: {shopId: {_eq: $shopId}}) {
    businessOperationHourType
    start
    end
  }
}
    `;

/**
 * __useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery__
 *
 * To run a query within a React component, call `useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery(baseOptions: Apollo.QueryHookOptions<SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery, SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery, SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables>(SalesAnalyticsGetBusinessOperationHourTypesByShopIdDocument, options);
      }
export function useSalesAnalyticsGetBusinessOperationHourTypesByShopIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery, SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery, SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables>(SalesAnalyticsGetBusinessOperationHourTypesByShopIdDocument, options);
        }
export type SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryHookResult = ReturnType<typeof useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery>;
export type SalesAnalyticsGetBusinessOperationHourTypesByShopIdLazyQueryHookResult = ReturnType<typeof useSalesAnalyticsGetBusinessOperationHourTypesByShopIdLazyQuery>;
export type SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryResult = Apollo.QueryResult<SalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery, SalesAnalyticsGetBusinessOperationHourTypesByShopIdQueryVariables>;
export const SalesAnalyticsGetShopsDocument = gql`
    query SalesAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies {
      id
      name
      shops {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useSalesAnalyticsGetShopsQuery__
 *
 * To run a query within a React component, call `useSalesAnalyticsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesAnalyticsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesAnalyticsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useSalesAnalyticsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<SalesAnalyticsGetShopsQuery, SalesAnalyticsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesAnalyticsGetShopsQuery, SalesAnalyticsGetShopsQueryVariables>(SalesAnalyticsGetShopsDocument, options);
      }
export function useSalesAnalyticsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesAnalyticsGetShopsQuery, SalesAnalyticsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesAnalyticsGetShopsQuery, SalesAnalyticsGetShopsQueryVariables>(SalesAnalyticsGetShopsDocument, options);
        }
export type SalesAnalyticsGetShopsQueryHookResult = ReturnType<typeof useSalesAnalyticsGetShopsQuery>;
export type SalesAnalyticsGetShopsLazyQueryHookResult = ReturnType<typeof useSalesAnalyticsGetShopsLazyQuery>;
export type SalesAnalyticsGetShopsQueryResult = Apollo.QueryResult<SalesAnalyticsGetShopsQuery, SalesAnalyticsGetShopsQueryVariables>;