import React from "react";
import styled, { css } from "styled-components";
// eslint-disable-next-line no-restricted-imports
import { Table as Original, TableProps as OriginalTableProps } from "antd";

const largePaginationStyle = css`
  .ant-table-pagination.ant-pagination {
    margin: 24px 0;
  }
`;

export type TableProps<RecordType> = OriginalTableProps<RecordType> & {
  largePagination?: boolean;
};

// https://github.com/ant-design/ant-design/blob/16492a2090161cf6cc255c0291cf481d3449a8a3/components/table/Table.tsx#L533-L535
type TableType = <RecordType extends object = any>(
  props: TableProps<RecordType> & {
    children?: React.ReactNode;
  } & {
    ref?: React.Ref<HTMLDivElement> | undefined;
  },
) => React.ReactElement;

const AntdTable = <RecordType extends object>(props: OriginalTableProps<RecordType>) => (
  <Original<RecordType> {...props} />
);

export const Table: TableType = styled(AntdTable as TableType)`
  ${({ largePagination }) => (largePagination ? largePaginationStyle : "")}
`;

export const TableColumn = Original.Column;
export const TableColumnGroup = Original.ColumnGroup;
export const TableSummary = Original.Summary;
export const EXPAND_COLUMN = Original.EXPAND_COLUMN;
export const SELECTION_ALL = Original.SELECTION_ALL;
export const SELECTION_COLUMN = Original.SELECTION_COLUMN;
export const SELECTION_INVERT = Original.SELECTION_INVERT;
export const SELECTION_NONE = Original.SELECTION_NONE;
