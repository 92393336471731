import React, { memo } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { ExternalOnlineMenuConfigInsertInput } from "types/graphql";

import { UrlField } from "./UrlField";
import { useAddShopExternalOnlineMenuConfigForm } from "./useAddShopExternalOnlineMenuConfigForm";

type Props = {
  onSubmit: (input: ExternalOnlineMenuConfigInsertInput) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onCancel: () => void;
  shopId: string;
  loading: boolean;
};

export const AddShopExternalOnlineMenuConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, onCancel, shopId, loading }) => {
    const { form, initialValues, submit } = useAddShopExternalOnlineMenuConfigForm(
      shopId,
      onSubmit,
      onFormValidationError,
    );
    return (
      <>
        <FormContent>
          <Form
            name="externalOnlineMenuConfig"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <UrlField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
