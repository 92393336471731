import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanChoiceWinboardMenuFormItem } from "../useEditPlanChoiceWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const BumonCodeField = memo<Props>((props) => (
  <EditPlanChoiceWinboardMenuFormItem label="部門コード" name="bumonCode" {...props}>
    <InputCode />
  </EditPlanChoiceWinboardMenuFormItem>
));
