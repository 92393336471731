import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuTecAggregationMenuGetMenu = gql`
    query EditMenuTecAggregationMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const EditMenuTecAggregationMenuGetMenuCode = gql`
    query EditMenuTecAggregationMenuGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const EditMenuTecAggregationMenuUpdateTecAggregationMenu = gql`
    mutation EditMenuTecAggregationMenuUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditMenuTecAggregationMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuTecAggregationMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type EditMenuTecAggregationMenuGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuTecAggregationMenuGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertTecAggregationMenuInput;
}>;


export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) }
);


export const EditMenuTecAggregationMenuGetMenuDocument = gql`
    query EditMenuTecAggregationMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useEditMenuTecAggregationMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuTecAggregationMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuTecAggregationMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuTecAggregationMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuTecAggregationMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuTecAggregationMenuGetMenuQuery, EditMenuTecAggregationMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuTecAggregationMenuGetMenuQuery, EditMenuTecAggregationMenuGetMenuQueryVariables>(EditMenuTecAggregationMenuGetMenuDocument, options);
      }
export function useEditMenuTecAggregationMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuTecAggregationMenuGetMenuQuery, EditMenuTecAggregationMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuTecAggregationMenuGetMenuQuery, EditMenuTecAggregationMenuGetMenuQueryVariables>(EditMenuTecAggregationMenuGetMenuDocument, options);
        }
export type EditMenuTecAggregationMenuGetMenuQueryHookResult = ReturnType<typeof useEditMenuTecAggregationMenuGetMenuQuery>;
export type EditMenuTecAggregationMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuTecAggregationMenuGetMenuLazyQuery>;
export type EditMenuTecAggregationMenuGetMenuQueryResult = Apollo.QueryResult<EditMenuTecAggregationMenuGetMenuQuery, EditMenuTecAggregationMenuGetMenuQueryVariables>;
export const EditMenuTecAggregationMenuGetMenuCodeDocument = gql`
    query EditMenuTecAggregationMenuGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useEditMenuTecAggregationMenuGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditMenuTecAggregationMenuGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuTecAggregationMenuGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuTecAggregationMenuGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuTecAggregationMenuGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditMenuTecAggregationMenuGetMenuCodeQuery, EditMenuTecAggregationMenuGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuTecAggregationMenuGetMenuCodeQuery, EditMenuTecAggregationMenuGetMenuCodeQueryVariables>(EditMenuTecAggregationMenuGetMenuCodeDocument, options);
      }
export function useEditMenuTecAggregationMenuGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuTecAggregationMenuGetMenuCodeQuery, EditMenuTecAggregationMenuGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuTecAggregationMenuGetMenuCodeQuery, EditMenuTecAggregationMenuGetMenuCodeQueryVariables>(EditMenuTecAggregationMenuGetMenuCodeDocument, options);
        }
export type EditMenuTecAggregationMenuGetMenuCodeQueryHookResult = ReturnType<typeof useEditMenuTecAggregationMenuGetMenuCodeQuery>;
export type EditMenuTecAggregationMenuGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditMenuTecAggregationMenuGetMenuCodeLazyQuery>;
export type EditMenuTecAggregationMenuGetMenuCodeQueryResult = Apollo.QueryResult<EditMenuTecAggregationMenuGetMenuCodeQuery, EditMenuTecAggregationMenuGetMenuCodeQueryVariables>;
export const EditMenuTecAggregationMenuUpdateTecAggregationMenuDocument = gql`
    mutation EditMenuTecAggregationMenuUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation, EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationVariables>;

/**
 * __useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuTecAggregationMenuUpdateTecAggregationMenuMutation, { data, loading, error }] = useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation, EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation, EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationVariables>(EditMenuTecAggregationMenuUpdateTecAggregationMenuDocument, options);
      }
export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useEditMenuTecAggregationMenuUpdateTecAggregationMenuMutation>;
export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation>;
export type EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<EditMenuTecAggregationMenuUpdateTecAggregationMenuMutation, EditMenuTecAggregationMenuUpdateTecAggregationMenuMutationVariables>;