import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddLineOfficialAccountFormItem } from "../useAddLineOfficialAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChannelSecretField = memo<Props>(({ ...props }) => (
  <AddLineOfficialAccountFormItem label="チャンネルシークレット" name="channelSecret" {...props}>
    <Input />
  </AddLineOfficialAccountFormItem>
));
