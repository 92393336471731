import React, { memo } from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const Icon = styled.img`
  height: 16px;
  width: auto;
`;

export const TableHeaderTitle = memo<{ icon: string; title: string }>(({ icon, title }) => (
  <Title>
    <Icon src={icon} alt="" />
    <Spacer size={6} inline />
    {title}
  </Title>
));
