import dayjs from "dayjs";

/**
 * 営業日変更時刻（HH:mm）と日付から営業日（YYYY-MM-DD）を返す
 *
 * 日付変更時刻は，12時より前(午前)の値の場合は深夜時間
 * 12時より後(午後)の時間の場合は当日時間として扱う
 *
 * TODO: UT
 *
 * | targetMoment     | changeDateTime | 営業日     |
 * | ---------------- | -------------- | ---------- |
 * | 2021/10/13 01:00 | 04:00          | 2021/10/12 |
 * | 2021/10/13 01:00 | 23:00          | 2021/10/13 |
 * | 2021/10/13 22:00 | 04:00          | 2021/10/13 |
 * | 2021/10/13 22:00 | 23:00          | 2021/10/13 |
 * | 2021/10/13 23:30 | 23:00          | 2021/10/14 |
 */
export const getWorkDay = (changeDateTime: string, target = dayjs()): dayjs.Dayjs => {
  const [h, m] = changeDateTime.split(":");

  if (!h || !m) {
    throw new Error("Invalid changeDateTime format");
  }

  const changeDateTimeH = parseInt(h);
  const changeDateTimeM = parseInt(m);

  const NOON_TIME = 12;

  // NOTE: target と同日の時刻で比較するため
  const changeDateTimeMoment = target.hour(changeDateTimeH).minute(changeDateTimeM);

  if (changeDateTimeH < NOON_TIME && target.isBefore(changeDateTimeMoment)) {
    return target.add(-1, "days");
  }

  if (changeDateTimeH >= NOON_TIME && !target.isBefore(changeDateTimeMoment)) {
    return target.add(1, "days");
  }

  return target;
};

export const getDayBeforeWorkDay = (changeDateTime: string, target = dayjs()) =>
  getWorkDay(changeDateTime, target).add(-1, "days");
