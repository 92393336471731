import React, { useCallback } from "react";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { WinboardConfigInsertInput } from "types/graphql";

import { EditWinboardConfigForm } from "./EditWinboardConfigForm";
import {
  useEditWinboardConfigGetWinboardConfigQuery,
  useEditWinboardConfigUpsertWinboardConfigMutation,
} from "./queries";

export const EditWinboardConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, error, refetch } = useEditWinboardConfigGetWinboardConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const winboardConfig = data?.winboardConfig?.[0];

  const [upsertWinboardConfigMutation, { loading: submitting }] =
    useEditWinboardConfigUpsertWinboardConfigMutation();

  const onSubmit = useCallback(
    async (input: Omit<WinboardConfigInsertInput, "shopId">) => {
      if (!shopId) return;

      const winboardConfigId = winboardConfig?.winboardConfigId ?? uuidv4();
      const winboardConfigDto = {
        winboardConfigId,
        shopId,
        ...input,
      };

      try {
        await upsertWinboardConfigMutation({
          variables: {
            winboardConfig: winboardConfigDto,
          },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }
      await refetch();
    },
    [shopId, upsertWinboardConfigMutation, winboardConfig?.winboardConfigId, refetch],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout title="ウィンボード連携">
      <PageHeader title="設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <EditWinboardConfigForm
          config={winboardConfig}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={submitting}
        />
      )}
    </DashboardLayout>
  );
};
