import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddConnectGameConfigGetCoupons = gql`
    query AddConnectGameConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddConnectGameConfigGetShops = gql`
    query AddConnectGameConfigGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export const AddConnectGameConfigGetConnectGameConfigShops = gql`
    query AddConnectGameConfigGetConnectGameConfigShops($companyId: uuid!) {
  connectGameConfig(where: {enabled: {_eq: true}, companyId: {_eq: $companyId}}) {
    id
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;
export const AddConnectGameConfigInsertConnectGameConfig = gql`
    mutation AddConnectGameConfigInsertConnectGameConfig($connectGameConfig: connectGameConfig_insert_input!) {
  insert_connectGameConfig_one(object: $connectGameConfig) {
    id
  }
}
    `;
export type AddConnectGameConfigGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddConnectGameConfigGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type AddConnectGameConfigGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddConnectGameConfigGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type AddConnectGameConfigGetConnectGameConfigShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddConnectGameConfigGetConnectGameConfigShopsQuery = (
  { __typename?: 'query_root' }
  & { connectGameConfig: Array<(
    { __typename?: 'connectGameConfig' }
    & Pick<Types.ConnectGameConfig, 'id'>
    & { connectGameConfigShops: Array<(
      { __typename?: 'connectGameConfigShop' }
      & Pick<Types.ConnectGameConfigShop, 'id'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
        & { id: Types.Shop['shopId'] }
      ) }
    )> }
  )> }
);

export type AddConnectGameConfigInsertConnectGameConfigMutationVariables = Types.Exact<{
  connectGameConfig: Types.ConnectGameConfigInsertInput;
}>;


export type AddConnectGameConfigInsertConnectGameConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_connectGameConfig_one?: Types.Maybe<(
    { __typename?: 'connectGameConfig' }
    & Pick<Types.ConnectGameConfig, 'id'>
  )> }
);


export const AddConnectGameConfigGetCouponsDocument = gql`
    query AddConnectGameConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddConnectGameConfigGetCouponsQuery__
 *
 * To run a query within a React component, call `useAddConnectGameConfigGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddConnectGameConfigGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddConnectGameConfigGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddConnectGameConfigGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<AddConnectGameConfigGetCouponsQuery, AddConnectGameConfigGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddConnectGameConfigGetCouponsQuery, AddConnectGameConfigGetCouponsQueryVariables>(AddConnectGameConfigGetCouponsDocument, options);
      }
export function useAddConnectGameConfigGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddConnectGameConfigGetCouponsQuery, AddConnectGameConfigGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddConnectGameConfigGetCouponsQuery, AddConnectGameConfigGetCouponsQueryVariables>(AddConnectGameConfigGetCouponsDocument, options);
        }
export type AddConnectGameConfigGetCouponsQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetCouponsQuery>;
export type AddConnectGameConfigGetCouponsLazyQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetCouponsLazyQuery>;
export type AddConnectGameConfigGetCouponsQueryResult = Apollo.QueryResult<AddConnectGameConfigGetCouponsQuery, AddConnectGameConfigGetCouponsQueryVariables>;
export const AddConnectGameConfigGetShopsDocument = gql`
    query AddConnectGameConfigGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useAddConnectGameConfigGetShopsQuery__
 *
 * To run a query within a React component, call `useAddConnectGameConfigGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddConnectGameConfigGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddConnectGameConfigGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddConnectGameConfigGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AddConnectGameConfigGetShopsQuery, AddConnectGameConfigGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddConnectGameConfigGetShopsQuery, AddConnectGameConfigGetShopsQueryVariables>(AddConnectGameConfigGetShopsDocument, options);
      }
export function useAddConnectGameConfigGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddConnectGameConfigGetShopsQuery, AddConnectGameConfigGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddConnectGameConfigGetShopsQuery, AddConnectGameConfigGetShopsQueryVariables>(AddConnectGameConfigGetShopsDocument, options);
        }
export type AddConnectGameConfigGetShopsQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetShopsQuery>;
export type AddConnectGameConfigGetShopsLazyQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetShopsLazyQuery>;
export type AddConnectGameConfigGetShopsQueryResult = Apollo.QueryResult<AddConnectGameConfigGetShopsQuery, AddConnectGameConfigGetShopsQueryVariables>;
export const AddConnectGameConfigGetConnectGameConfigShopsDocument = gql`
    query AddConnectGameConfigGetConnectGameConfigShops($companyId: uuid!) {
  connectGameConfig(where: {enabled: {_eq: true}, companyId: {_eq: $companyId}}) {
    id
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;

/**
 * __useAddConnectGameConfigGetConnectGameConfigShopsQuery__
 *
 * To run a query within a React component, call `useAddConnectGameConfigGetConnectGameConfigShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddConnectGameConfigGetConnectGameConfigShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddConnectGameConfigGetConnectGameConfigShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddConnectGameConfigGetConnectGameConfigShopsQuery(baseOptions: Apollo.QueryHookOptions<AddConnectGameConfigGetConnectGameConfigShopsQuery, AddConnectGameConfigGetConnectGameConfigShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddConnectGameConfigGetConnectGameConfigShopsQuery, AddConnectGameConfigGetConnectGameConfigShopsQueryVariables>(AddConnectGameConfigGetConnectGameConfigShopsDocument, options);
      }
export function useAddConnectGameConfigGetConnectGameConfigShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddConnectGameConfigGetConnectGameConfigShopsQuery, AddConnectGameConfigGetConnectGameConfigShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddConnectGameConfigGetConnectGameConfigShopsQuery, AddConnectGameConfigGetConnectGameConfigShopsQueryVariables>(AddConnectGameConfigGetConnectGameConfigShopsDocument, options);
        }
export type AddConnectGameConfigGetConnectGameConfigShopsQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetConnectGameConfigShopsQuery>;
export type AddConnectGameConfigGetConnectGameConfigShopsLazyQueryHookResult = ReturnType<typeof useAddConnectGameConfigGetConnectGameConfigShopsLazyQuery>;
export type AddConnectGameConfigGetConnectGameConfigShopsQueryResult = Apollo.QueryResult<AddConnectGameConfigGetConnectGameConfigShopsQuery, AddConnectGameConfigGetConnectGameConfigShopsQueryVariables>;
export const AddConnectGameConfigInsertConnectGameConfigDocument = gql`
    mutation AddConnectGameConfigInsertConnectGameConfig($connectGameConfig: connectGameConfig_insert_input!) {
  insert_connectGameConfig_one(object: $connectGameConfig) {
    id
  }
}
    `;
export type AddConnectGameConfigInsertConnectGameConfigMutationFn = Apollo.MutationFunction<AddConnectGameConfigInsertConnectGameConfigMutation, AddConnectGameConfigInsertConnectGameConfigMutationVariables>;

/**
 * __useAddConnectGameConfigInsertConnectGameConfigMutation__
 *
 * To run a mutation, you first call `useAddConnectGameConfigInsertConnectGameConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConnectGameConfigInsertConnectGameConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConnectGameConfigInsertConnectGameConfigMutation, { data, loading, error }] = useAddConnectGameConfigInsertConnectGameConfigMutation({
 *   variables: {
 *      connectGameConfig: // value for 'connectGameConfig'
 *   },
 * });
 */
export function useAddConnectGameConfigInsertConnectGameConfigMutation(baseOptions?: Apollo.MutationHookOptions<AddConnectGameConfigInsertConnectGameConfigMutation, AddConnectGameConfigInsertConnectGameConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConnectGameConfigInsertConnectGameConfigMutation, AddConnectGameConfigInsertConnectGameConfigMutationVariables>(AddConnectGameConfigInsertConnectGameConfigDocument, options);
      }
export type AddConnectGameConfigInsertConnectGameConfigMutationHookResult = ReturnType<typeof useAddConnectGameConfigInsertConnectGameConfigMutation>;
export type AddConnectGameConfigInsertConnectGameConfigMutationResult = Apollo.MutationResult<AddConnectGameConfigInsertConnectGameConfigMutation>;
export type AddConnectGameConfigInsertConnectGameConfigMutationOptions = Apollo.BaseMutationOptions<AddConnectGameConfigInsertConnectGameConfigMutation, AddConnectGameConfigInsertConnectGameConfigMutationVariables>;