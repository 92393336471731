import { useCallback, useEffect, useState } from "react";
import { isNumber } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { ChoiceInsertInput, MenuTypeEnum, OptionSetInput } from "types/graphql";

export type EditOptionFormValues = {
  id: string;
  optionId: number;
  name: string;
  receiptDisplayName: string;
  minChoiceNum: number;
  maxChoiceNum?: number | null;
  choices: {
    choiceId: number;
    name: string;
    receiptDisplayName: string;
    price: number;
    costPrice?: number | null;
    costTaxRate?: number | null;
    isDefaultSelection: boolean;
    menuType?: MenuTypeEnum | null;
    imageUrl?: string | null;
    shopChoices: { id: string }[] | null;
  }[];
};

export const EditOptionFormItem = createFormItem<EditOptionFormValues>();

export const useEditOptionForm = ({
  onSubmit,
  option,
}: {
  onSubmit: (
    option: OptionSetInput,
    choices: ChoiceInsertInput[],
    choiceIdsForDelete: number[],
    shopChoiceIdsForDelete: string[],
  ) => void;
  option?: EditOptionFormValues | null;
}) => {
  const [form] = Form.useForm();
  const [choiceIdsForDelete, setChoiceIdsForDelete] = useState<number[]>([]);

  const [isEnabledMaxChoiceNum, setIsEnabledMaxChoiceNum] = useState(false);
  const [isEnabledMinChoiceNum, setIsEnabledMinChoiceNum] = useState(false);

  useEffect(() => {
    form.setFieldsValue(option);
    setIsEnabledMaxChoiceNum(isNumber(option?.maxChoiceNum));
    setIsEnabledMinChoiceNum(isNumber(option?.minChoiceNum) && Number(option?.minChoiceNum) > 0);
  }, [option, form]);

  const toggleMaxChoiceNum = useCallback(() => {
    const isEnabledMaxChoiceNum = isNumber(form.getFieldsValue().maxChoiceNum);
    const choicesLength = form.getFieldsValue().choices?.length ?? 1;

    form.setFields([{ name: "maxChoiceNum", value: isEnabledMaxChoiceNum ? null : choicesLength }]);
    setIsEnabledMaxChoiceNum(!isEnabledMaxChoiceNum);
  }, [form]);
  const toggleMinChoiceNum = useCallback(() => {
    const isEnabledMinChoiceNum = isNumber(form.getFieldsValue().minChoiceNum);

    form.setFields([{ name: "minChoiceNum", value: isEnabledMinChoiceNum ? null : 1 }]);
    setIsEnabledMinChoiceNum(!isEnabledMinChoiceNum);
  }, [form]);

  const submit = useCallback(async () => {
    if (!option) return;
    await form.validateFields();
    const values = form.getFieldsValue() as EditOptionFormValues;

    const shopChoiceIdsForDelete = option.choices
      .filter((choice) => choiceIdsForDelete.includes(choice.choiceId))
      .flatMap(({ shopChoices }) => shopChoices?.map(({ id }) => id) ?? []);

    onSubmit(
      {
        name: values.name,
        receiptDisplayName: values.receiptDisplayName,
        minChoiceNum: values.minChoiceNum ?? 0,
        maxChoiceNum: values.maxChoiceNum ? values.maxChoiceNum : null,
      },
      values.choices.map((choice, index) => ({
        // NOTE: GraphQL のクエリも変更しないと更新されないことに注意
        serial: choice.choiceId,
        name: choice.name,
        price: choice.price,
        priority: index,
        receiptDisplayName: choice.receiptDisplayName,
        costPrice: choice.costPrice,
        costTaxRate: choice.costTaxRate,
        isDefaultSelection: choice.isDefaultSelection,
        menuType: choice.menuType,
        optionId: option.id,
        _optionId: option.optionId,
        imageUrl: choice.imageUrl,
      })),
      choiceIdsForDelete,
      shopChoiceIdsForDelete,
    );
    setChoiceIdsForDelete([]);
  }, [option, form, onSubmit, choiceIdsForDelete]);

  const toggleChoiceForDelete = useCallback(
    ({ choiceIndex }: { choiceIndex: number }) => {
      const choices = form.getFieldValue("choices") as EditOptionFormValues["choices"];

      const choice = choices[choiceIndex];
      const choiceId = choice?.choiceId;

      if (!isNumber(choiceId)) {
        // if the choice hasn't been saved to the server yet, we don't need to mark it for deletion
        form.setFieldValue(
          "choices",
          choices.filter((_, index) => index !== choiceIndex),
        );

        // NOTE: we have to revalidate fields here because the choice indices have changed
        form.validateFields();
      } else {
        // handle delete toggle for existing choices
        setChoiceIdsForDelete((choiceIdsForDelete) =>
          !choiceIdsForDelete.includes(choiceId)
            ? [...choiceIdsForDelete, choiceId]
            : choiceIdsForDelete.filter((id) => id !== choiceId),
        );
      }
    },
    [form],
  );

  return {
    submit,
    form,
    toggleMinChoiceNum,
    toggleMaxChoiceNum,
    isEnabledMinChoiceNum,
    isEnabledMaxChoiceNum,
    choiceIdsForDelete,
    toggleChoiceForDelete,
  };
};
