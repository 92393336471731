import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { KdDisplayTarget } from "types/graphql";

type Props = {
  kdDisplayTarget: Pick<KdDisplayTarget, "name" | "id"> | null;
  onBack: () => void;
};

export const KitchenDisplayDisplayTargetHeader = memo<Props>(({ kdDisplayTarget, onBack }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={onBack} title={kdDisplayTarget?.name ?? ""} />
      {kdDisplayTarget && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/kitchenDisplayDisplayTarget/${kdDisplayTarget.id}/edit`} replace>
              キッチンディスプレイ表示対象
            </Link>
          </Menu.Item>
          <Menu.Item key="menu">
            <Link to={`/kitchenDisplayDisplayTarget/${kdDisplayTarget.id}/menu`} replace>
              メニュー
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
