import { NamePath } from "rc-field-form/es/interface";

export const moreThanOrEqualFieldTransformer = ({
  value,
  name,
  getFieldValue,
  setFieldsValue,
}: {
  value: string | null;
  name: string;
  getFieldValue: (name: NamePath) => any;
  setFieldsValue: (values: any) => void;
}) => {
  const moreThanOrEqual = Number(value);
  const { lessThanOrEqual } = getFieldValue(name);
  if (moreThanOrEqual) {
    setFieldsValue({
      [name]:
        moreThanOrEqual === lessThanOrEqual
          ? { equal: moreThanOrEqual, moreThanOrEqual }
          : { equal: null, moreThanOrEqual },
    });
  } else {
    setFieldsValue({
      [name]: { moreThanOrEqual: null, equal: null },
    });
  }
};

export const lessThanOrEqualFieldTransformer = ({
  value,
  name,
  getFieldValue,
  setFieldsValue,
}: {
  value: string | null;
  name: string;
  getFieldValue: (name: NamePath) => any;
  setFieldsValue: (values: any) => void;
}) => {
  const lessThanOrEqual = Number(value);
  const { moreThanOrEqual } = getFieldValue(name);
  if (lessThanOrEqual) {
    setFieldsValue({
      [name]:
        lessThanOrEqual === moreThanOrEqual
          ? { equal: lessThanOrEqual, lessThanOrEqual }
          : { equal: null, lessThanOrEqual },
    });
  } else {
    setFieldsValue({
      [name]: { lessThanOrEqual: null, equal: null },
    });
  }
};
