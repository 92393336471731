import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationMediaMapGetTecAggregationMediaMap = gql`
    query EditTecAggregationMediaMapGetTecAggregationMediaMap($id: uuid!) {
  tecAggregationMediaMap_by_pk(id: $id) {
    id
    aggregationNumber
    mediaName
  }
}
    `;
export const EditTecAggregationMediaMapUpdateTecAggregationMediaMap = gql`
    mutation EditTecAggregationMediaMapUpdateTecAggregationMediaMap($object: tecAggregationMediaMap_insert_input!) {
  insert_tecAggregationMediaMap_one(
    object: $object
    on_conflict: {constraint: tecAggregationMediaMap_companyUuid_aggregationNumber_key, update_columns: [mediaName]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditTecAggregationMediaMapGetTecAggregationMediaMapQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMediaMap_by_pk?: Types.Maybe<(
    { __typename?: 'tecAggregationMediaMap' }
    & Pick<Types.TecAggregationMediaMap, 'id' | 'aggregationNumber' | 'mediaName'>
  )> }
);

export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationVariables = Types.Exact<{
  object: Types.TecAggregationMediaMapInsertInput;
}>;


export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tecAggregationMediaMap_one?: Types.Maybe<(
    { __typename?: 'tecAggregationMediaMap' }
    & Pick<Types.TecAggregationMediaMap, 'id'>
  )> }
);


export const EditTecAggregationMediaMapGetTecAggregationMediaMapDocument = gql`
    query EditTecAggregationMediaMapGetTecAggregationMediaMap($id: uuid!) {
  tecAggregationMediaMap_by_pk(id: $id) {
    id
    aggregationNumber
    mediaName
  }
}
    `;

/**
 * __useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationMediaMapGetTecAggregationMediaMapQuery, EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationMediaMapGetTecAggregationMediaMapQuery, EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables>(EditTecAggregationMediaMapGetTecAggregationMediaMapDocument, options);
      }
export function useEditTecAggregationMediaMapGetTecAggregationMediaMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationMediaMapGetTecAggregationMediaMapQuery, EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationMediaMapGetTecAggregationMediaMapQuery, EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables>(EditTecAggregationMediaMapGetTecAggregationMediaMapDocument, options);
        }
export type EditTecAggregationMediaMapGetTecAggregationMediaMapQueryHookResult = ReturnType<typeof useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery>;
export type EditTecAggregationMediaMapGetTecAggregationMediaMapLazyQueryHookResult = ReturnType<typeof useEditTecAggregationMediaMapGetTecAggregationMediaMapLazyQuery>;
export type EditTecAggregationMediaMapGetTecAggregationMediaMapQueryResult = Apollo.QueryResult<EditTecAggregationMediaMapGetTecAggregationMediaMapQuery, EditTecAggregationMediaMapGetTecAggregationMediaMapQueryVariables>;
export const EditTecAggregationMediaMapUpdateTecAggregationMediaMapDocument = gql`
    mutation EditTecAggregationMediaMapUpdateTecAggregationMediaMap($object: tecAggregationMediaMap_insert_input!) {
  insert_tecAggregationMediaMap_one(
    object: $object
    on_conflict: {constraint: tecAggregationMediaMap_companyUuid_aggregationNumber_key, update_columns: [mediaName]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationFn = Apollo.MutationFunction<EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation, EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationVariables>;

/**
 * __useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationMediaMapUpdateTecAggregationMediaMapMutation, { data, loading, error }] = useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation, EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation, EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationVariables>(EditTecAggregationMediaMapUpdateTecAggregationMediaMapDocument, options);
      }
export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationHookResult = ReturnType<typeof useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation>;
export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationResult = Apollo.MutationResult<EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation>;
export type EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation, EditTecAggregationMediaMapUpdateTecAggregationMediaMapMutationVariables>;