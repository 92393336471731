import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuPikaichiMenuGetMenu = gql`
    query EditMenuPikaichiMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const EditMenuPikaichiMenuGetPikaichiMenuCd = gql`
    query EditMenuPikaichiMenuGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(where: {companyId: {_eq: $companyId}}) {
    pikaichiMenuCd
  }
}
    `;
export const EditMenuPikaichiMenuUpdatePikaichiMenu = gql`
    mutation EditMenuPikaichiMenuUpdatePikaichiMenu($menuId: uuid!, $_menuId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuMenu_one(
    object: {pikaichiMenuId: $pikaichiMenuId, menuId: $menuId, _menuId: $_menuId}
    on_conflict: {constraint: pikaichiMenuMenu_pikaichiMenuId_menuId_key, update_columns: []}
  ) {
    pikaichiMenuId
    menuId
    _menuId
  }
}
    `;
export type EditMenuPikaichiMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuPikaichiMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, pikaichiMenuMenus: Array<(
      { __typename?: 'pikaichiMenuMenu' }
      & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId'>
      & { menuId: Types.PikaichiMenuMenu['_menuId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuPikaichiMenuGetPikaichiMenuCdQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenu: Array<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuCd'>
  )> }
);

export type EditMenuPikaichiMenuUpdatePikaichiMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['uuid'];
  _menuId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
}>;


export type EditMenuPikaichiMenuUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuMenu' }
    & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId' | 'menuId' | '_menuId'>
  )> }
);


export const EditMenuPikaichiMenuGetMenuDocument = gql`
    query EditMenuPikaichiMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __useEditMenuPikaichiMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuPikaichiMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPikaichiMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuPikaichiMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuPikaichiMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuPikaichiMenuGetMenuQuery, EditMenuPikaichiMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuPikaichiMenuGetMenuQuery, EditMenuPikaichiMenuGetMenuQueryVariables>(EditMenuPikaichiMenuGetMenuDocument, options);
      }
export function useEditMenuPikaichiMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuPikaichiMenuGetMenuQuery, EditMenuPikaichiMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuPikaichiMenuGetMenuQuery, EditMenuPikaichiMenuGetMenuQueryVariables>(EditMenuPikaichiMenuGetMenuDocument, options);
        }
export type EditMenuPikaichiMenuGetMenuQueryHookResult = ReturnType<typeof useEditMenuPikaichiMenuGetMenuQuery>;
export type EditMenuPikaichiMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuPikaichiMenuGetMenuLazyQuery>;
export type EditMenuPikaichiMenuGetMenuQueryResult = Apollo.QueryResult<EditMenuPikaichiMenuGetMenuQuery, EditMenuPikaichiMenuGetMenuQueryVariables>;
export const EditMenuPikaichiMenuGetPikaichiMenuCdDocument = gql`
    query EditMenuPikaichiMenuGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(where: {companyId: {_eq: $companyId}}) {
    pikaichiMenuCd
  }
}
    `;

/**
 * __useEditMenuPikaichiMenuGetPikaichiMenuCdQuery__
 *
 * To run a query within a React component, call `useEditMenuPikaichiMenuGetPikaichiMenuCdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPikaichiMenuGetPikaichiMenuCdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuPikaichiMenuGetPikaichiMenuCdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuPikaichiMenuGetPikaichiMenuCdQuery(baseOptions: Apollo.QueryHookOptions<EditMenuPikaichiMenuGetPikaichiMenuCdQuery, EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuPikaichiMenuGetPikaichiMenuCdQuery, EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables>(EditMenuPikaichiMenuGetPikaichiMenuCdDocument, options);
      }
export function useEditMenuPikaichiMenuGetPikaichiMenuCdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuPikaichiMenuGetPikaichiMenuCdQuery, EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuPikaichiMenuGetPikaichiMenuCdQuery, EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables>(EditMenuPikaichiMenuGetPikaichiMenuCdDocument, options);
        }
export type EditMenuPikaichiMenuGetPikaichiMenuCdQueryHookResult = ReturnType<typeof useEditMenuPikaichiMenuGetPikaichiMenuCdQuery>;
export type EditMenuPikaichiMenuGetPikaichiMenuCdLazyQueryHookResult = ReturnType<typeof useEditMenuPikaichiMenuGetPikaichiMenuCdLazyQuery>;
export type EditMenuPikaichiMenuGetPikaichiMenuCdQueryResult = Apollo.QueryResult<EditMenuPikaichiMenuGetPikaichiMenuCdQuery, EditMenuPikaichiMenuGetPikaichiMenuCdQueryVariables>;
export const EditMenuPikaichiMenuUpdatePikaichiMenuDocument = gql`
    mutation EditMenuPikaichiMenuUpdatePikaichiMenu($menuId: uuid!, $_menuId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuMenu_one(
    object: {pikaichiMenuId: $pikaichiMenuId, menuId: $menuId, _menuId: $_menuId}
    on_conflict: {constraint: pikaichiMenuMenu_pikaichiMenuId_menuId_key, update_columns: []}
  ) {
    pikaichiMenuId
    menuId
    _menuId
  }
}
    `;
export type EditMenuPikaichiMenuUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<EditMenuPikaichiMenuUpdatePikaichiMenuMutation, EditMenuPikaichiMenuUpdatePikaichiMenuMutationVariables>;

/**
 * __useEditMenuPikaichiMenuUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `useEditMenuPikaichiMenuUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPikaichiMenuUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuPikaichiMenuUpdatePikaichiMenuMutation, { data, loading, error }] = useEditMenuPikaichiMenuUpdatePikaichiMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      _menuId: // value for '_menuId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *   },
 * });
 */
export function useEditMenuPikaichiMenuUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuPikaichiMenuUpdatePikaichiMenuMutation, EditMenuPikaichiMenuUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuPikaichiMenuUpdatePikaichiMenuMutation, EditMenuPikaichiMenuUpdatePikaichiMenuMutationVariables>(EditMenuPikaichiMenuUpdatePikaichiMenuDocument, options);
      }
export type EditMenuPikaichiMenuUpdatePikaichiMenuMutationHookResult = ReturnType<typeof useEditMenuPikaichiMenuUpdatePikaichiMenuMutation>;
export type EditMenuPikaichiMenuUpdatePikaichiMenuMutationResult = Apollo.MutationResult<EditMenuPikaichiMenuUpdatePikaichiMenuMutation>;
export type EditMenuPikaichiMenuUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<EditMenuPikaichiMenuUpdatePikaichiMenuMutation, EditMenuPikaichiMenuUpdatePikaichiMenuMutationVariables>;