import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationGiftsGetMenus = gql`
    query TecAggregationGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const TecAggregationGiftsGetShops = gql`
    query TecAggregationGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const TecAggregationGiftsDeleteTecAggregationMenu = gql`
    mutation TecAggregationGiftsDeleteTecAggregationMenu($tecAggregationMenuId: uuid!) {
  delete_tecAggregationMenu_by_pk(id: $tecAggregationMenuId) {
    id
  }
}
    `;
export type TecAggregationGiftsGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationGiftsGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'name'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'optionId' | 'name'>
      ) }
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type TecAggregationGiftsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationGiftsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type TecAggregationGiftsDeleteTecAggregationMenuMutationVariables = Types.Exact<{
  tecAggregationMenuId: Types.Scalars['uuid'];
}>;


export type TecAggregationGiftsDeleteTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_tecAggregationMenu_by_pk?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'id'>
  )> }
);


export const TecAggregationGiftsGetMenusDocument = gql`
    query TecAggregationGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useTecAggregationGiftsGetMenusQuery__
 *
 * To run a query within a React component, call `useTecAggregationGiftsGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationGiftsGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationGiftsGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationGiftsGetMenusQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationGiftsGetMenusQuery, TecAggregationGiftsGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationGiftsGetMenusQuery, TecAggregationGiftsGetMenusQueryVariables>(TecAggregationGiftsGetMenusDocument, options);
      }
export function useTecAggregationGiftsGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationGiftsGetMenusQuery, TecAggregationGiftsGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationGiftsGetMenusQuery, TecAggregationGiftsGetMenusQueryVariables>(TecAggregationGiftsGetMenusDocument, options);
        }
export type TecAggregationGiftsGetMenusQueryHookResult = ReturnType<typeof useTecAggregationGiftsGetMenusQuery>;
export type TecAggregationGiftsGetMenusLazyQueryHookResult = ReturnType<typeof useTecAggregationGiftsGetMenusLazyQuery>;
export type TecAggregationGiftsGetMenusQueryResult = Apollo.QueryResult<TecAggregationGiftsGetMenusQuery, TecAggregationGiftsGetMenusQueryVariables>;
export const TecAggregationGiftsGetShopsDocument = gql`
    query TecAggregationGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useTecAggregationGiftsGetShopsQuery__
 *
 * To run a query within a React component, call `useTecAggregationGiftsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationGiftsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationGiftsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationGiftsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationGiftsGetShopsQuery, TecAggregationGiftsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationGiftsGetShopsQuery, TecAggregationGiftsGetShopsQueryVariables>(TecAggregationGiftsGetShopsDocument, options);
      }
export function useTecAggregationGiftsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationGiftsGetShopsQuery, TecAggregationGiftsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationGiftsGetShopsQuery, TecAggregationGiftsGetShopsQueryVariables>(TecAggregationGiftsGetShopsDocument, options);
        }
export type TecAggregationGiftsGetShopsQueryHookResult = ReturnType<typeof useTecAggregationGiftsGetShopsQuery>;
export type TecAggregationGiftsGetShopsLazyQueryHookResult = ReturnType<typeof useTecAggregationGiftsGetShopsLazyQuery>;
export type TecAggregationGiftsGetShopsQueryResult = Apollo.QueryResult<TecAggregationGiftsGetShopsQuery, TecAggregationGiftsGetShopsQueryVariables>;
export const TecAggregationGiftsDeleteTecAggregationMenuDocument = gql`
    mutation TecAggregationGiftsDeleteTecAggregationMenu($tecAggregationMenuId: uuid!) {
  delete_tecAggregationMenu_by_pk(id: $tecAggregationMenuId) {
    id
  }
}
    `;
export type TecAggregationGiftsDeleteTecAggregationMenuMutationFn = Apollo.MutationFunction<TecAggregationGiftsDeleteTecAggregationMenuMutation, TecAggregationGiftsDeleteTecAggregationMenuMutationVariables>;

/**
 * __useTecAggregationGiftsDeleteTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useTecAggregationGiftsDeleteTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationGiftsDeleteTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tecAggregationGiftsDeleteTecAggregationMenuMutation, { data, loading, error }] = useTecAggregationGiftsDeleteTecAggregationMenuMutation({
 *   variables: {
 *      tecAggregationMenuId: // value for 'tecAggregationMenuId'
 *   },
 * });
 */
export function useTecAggregationGiftsDeleteTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<TecAggregationGiftsDeleteTecAggregationMenuMutation, TecAggregationGiftsDeleteTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TecAggregationGiftsDeleteTecAggregationMenuMutation, TecAggregationGiftsDeleteTecAggregationMenuMutationVariables>(TecAggregationGiftsDeleteTecAggregationMenuDocument, options);
      }
export type TecAggregationGiftsDeleteTecAggregationMenuMutationHookResult = ReturnType<typeof useTecAggregationGiftsDeleteTecAggregationMenuMutation>;
export type TecAggregationGiftsDeleteTecAggregationMenuMutationResult = Apollo.MutationResult<TecAggregationGiftsDeleteTecAggregationMenuMutation>;
export type TecAggregationGiftsDeleteTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<TecAggregationGiftsDeleteTecAggregationMenuMutation, TecAggregationGiftsDeleteTecAggregationMenuMutationVariables>;