import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert, Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Spacer } from "components/Spacer";
import { MenuRecommendation } from "pages/MenuRecommendation/types";

type Props = {
  menuRecommendation: Pick<MenuRecommendation, "name" | "id"> | null;
  onBack: () => void;
  shouldShowManagingShopAlert: boolean;
};

export const MenuRecommendationHeader = memo<Props>(
  ({ menuRecommendation, onBack, shouldShowManagingShopAlert }) => {
    const { pathname } = useLocation();

    const selectedKey = pathname.split("/")[3];
    const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

    return (
      <>
        <PageHeader onBack={onBack} title={menuRecommendation?.name ?? ""} />
        {shouldShowManagingShopAlert && (
          <>
            <Alert
              type="warning"
              message="管理外の店舗のメニューに影響を与える可能性があります。注意して編集してください。"
            />
            <Spacer size={16} />
          </>
        )}
        {menuRecommendation && (
          <Menu selectedKeys={selectedKeys} mode="horizontal">
            <Menu.Item key="edit">
              <Link to={`/menuRecommendation/${menuRecommendation.id}/edit`}>基本情報</Link>
            </Menu.Item>
            <Menu.Item key="menus">
              <Link to={`/menuRecommendation/${menuRecommendation.id}/menus`}>メニュー一覧</Link>
            </Menu.Item>
            <Menu.Item key="translation">
              <Link to={`/menuRecommendation/${menuRecommendation.id}/translation/edit`} replace>
                多言語設定
              </Link>
            </Menu.Item>
          </Menu>
        )}
      </>
    );
  },
);
