import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddGmoBankAccountFormItem } from "../useAddGmoBankAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AccountNumberField = memo<Props>((props) => (
  <AddGmoBankAccountFormItem
    label="口座番号（数字7桁）"
    name="accountNumber"
    rules={[
      {
        required: true,
        message: "口座番号を7桁の数字で入力して下さい",
        pattern: /^[0-9]{7}$/,
      },
    ]}
    {...props}
  >
    <Input />
  </AddGmoBankAccountFormItem>
));
