import React, { isValidElement } from "react";

type RenderChildren<T> = (value: T) => React.ReactNode;
type RenderLayout = (bypassed: React.ReactNode) => JSX.Element;

type BypassHelperProps = {
  [key: string]: unknown;
  __original__: React.ReactNode;
  __renderLayout__: RenderLayout;
};

const BypassHelper = ({
  __original__: original,
  __renderLayout__: renderLayout,
  ...props
}: BypassHelperProps) => {
  if (isValidElement(original)) {
    return renderLayout(React.cloneElement(original, { ...props }));
  }
  return renderLayout(original);
};

/**
 * 以下のリンクにある例のように、 antd のコンポーネントの中には children から渡された element の props を上書きする実装がある。
 * https://github.com/ant-design/ant-design/blob/91ff1dcce2308b422ad0da00ab37b775cf06441b/components/form/FormItem.tsx#L422-L448
 * そのようなコンポーネントの子要素の周りに他の要素を足す、例えば Spacer を入れたい場合、複数の子要素を渡すと上書きの処理がスキップされる。
 * また、 Fragment で囲ったものを渡してしまうと、上書きされた props は Fragment に適用されてしまう。
 * ```tsx
 * // 子要素が複数があると value が input に渡らない
 * <FormItem>
 *   <Spacer size={16} />
 *   <input />
 * </FormItem>
 * // 子要素は一つだが value は Fragment に渡ってしまう
 * <FormItem>
 *   <>
 *     <Spacer size={16} />
 *     <input />
 *   </>
 * </FormItem>
 * ```
 * この問題に対し、この関数はオリジナルの children への props の上書きをバイパスしつつ、メインの子要素の前後に追加の要素を配置する手段を提供する。
 * ```tsx
 * <FormItem>
 *   {bypassPropsOverwrite(<input />, (bypassed) => (
 *     <>
 *       <Spacer size={16} />
 *       {bypassed}
 *     </>
 *   ))}
 * </FormItem>
 * ```
 */
export const bypassPropsOverwrite = <T extends RenderChildren<U> | React.ReactNode, U>(
  original: T,
  renderLayout: RenderLayout,
): T => {
  if (original instanceof Function) {
    return ((value) => (
      <BypassHelper __original__={original(value)} __renderLayout__={renderLayout} />
    )) as typeof original;
  }
  if (isValidElement(original)) {
    return (
      <BypassHelper __original__={original} __renderLayout__={renderLayout} {...original.props} />
    ) as typeof original;
  }
  return original;
};
