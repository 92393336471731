import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, DatePicker, Select, TreeSelect } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";

import { Company } from "../types";

const MAX_SELECTABLE_DAYS = 366;
const fieldSpacingAmount = 12;

const EntityMultiSelect = styled(Select<string[]>)`
  width: 275px;
  flex-grow: 1;
  max-width: 400px;
`;

const BusinessOperationHourTypeSelect = styled(EntityMultiSelect)`
  width: 250px;
  flex-grow: 0;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 256px;
`;

const FlexGrowSpacer = styled.div`
  flex-grow: 1;
`;

const FiltersRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const StyledTreeSelect = styled(TreeSelect<string[]>)`
  width: 100%;
`;

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  businessOperationHourTypes: string[];
  shopIds: string[] | null;
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null];
  businessOperationHourTypes: string[] | null;
  shopIds: string[] | null;
} & Omit<FilterConditions, "range">;

type Props = {
  companies: Company[];
  allShopIds: string[];
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  onCsvExport: () => void;
  onEditColumns: () => void;
};

export const Filters = memo<Props>(
  ({
    companies,
    allShopIds,
    filterConditions,
    onChangeFilterCondition,
    onCsvExport,
    onEditColumns,
  }) => {
    // const { data: businessOperationHourTypesData } =
    //   useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery(
    //     !shopId
    //       ? { skip: true }
    //       : {
    //           variables: {
    //             shopId,
    //           },
    //         },
    //   );

    // const businessOperationHourTypes = useMemo(
    //   () =>
    //     uniq(
    //       (businessOperationHourTypesData?.shopBusinessOperationHour ?? []).map(
    //         ({ businessOperationHourType }) => businessOperationHourType,
    //       ),
    //     ),
    //   [businessOperationHourTypesData],
    // );

    // const businessOperationHourTypeOptions = useMemo(
    //   () =>
    //     businessOperationHourTypes.map((key) => ({
    //       value: key,
    //       label: businessOperationHourTypeToWord[key],
    //     })),
    //   [businessOperationHourTypes],
    // );

    // NOTE: null の場合すべての店舗を表示する
    const [selectedShopIds, setSelectedShopIds] = useState<string[] | null>(null);

    const shopTreeData = useMemo(
      () =>
        (
          companies
            .filter(({ shops }) => shops.length > 0)
            .sort((a, b) => a.name.localeCompare(b.name)) ?? []
        ).map((company) => ({
          key: company.id,
          value: company.id,
          title: company.name,
          selectable: true,
          children: company.shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop) => ({
              key: shop.shopId,
              value: shop.shopId,
              title: shop.name,
              selectable: true,
            })),
        })),
      [companies],
    );

    const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(
      () => [dayjs().startOf("month"), dayjs()],
      [],
    );

    const handleSelectShops = useCallback(
      (value: string[]) => {
        setSelectedShopIds(value);
        return onChangeFilterCondition({ shopIds: value });
      },
      [onChangeFilterCondition],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const startAt = values?.[0]?.startOf("day") ?? null;
        const endAt = values?.[1]?.endOf("day") ?? null;

        onChangeFilterCondition({ range: [startAt, endAt] });
      },
      [onChangeFilterCondition],
    );

    // NOTE: API のパフォーマンス観点から選択可能な期間を最大一年間とする
    const disabledDate = useCallback(
      (current: dayjs.Dayjs | null) => {
        const startAt = filterConditions.range?.[0] ?? null;
        const endAt = filterConditions.range?.[1] ?? null;

        if (startAt === null && endAt === null) return false;

        const isTooLate = Boolean(
          current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
        );
        const isTooEarly = Boolean(
          current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
        );

        return isTooLate || isTooEarly;
      },
      [filterConditions.range],
    );

    const { rangePresets } = useRangePresets();

    return (
      <>
        <FiltersRow>
          <StyledTreeSelect
            treeData={shopTreeData}
            treeDefaultExpandAll
            treeCheckable
            value={selectedShopIds ?? []}
            defaultValue={allShopIds}
            onChange={handleSelectShops}
            maxTagCount={10}
            placeholder="店舗を選択してください"
            treeNodeFilterProp="title"
          />

          <Spacer size={fieldSpacingAmount} inline />
        </FiltersRow>
        <Spacer size={fieldSpacingAmount} inline />
        <FiltersRow>
          <StyledRangePicker
            value={filterConditions.range ?? initialRange}
            ranges={rangePresets}
            onCalendarChange={handleChangeRange}
            clearIcon={false}
            disabledDate={disabledDate}
            picker="date"
            renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
          />
          <Spacer size={fieldSpacingAmount} inline />
          {/* TODO: implement
          <BusinessOperationHourTypeSelect
            mode="multiple"
            showSearch
            allowClear
            placeholder="営業時間帯"
            value={filterConditions.businessOperationHourTypes}
            onChange={(businessOperationHourTypes) =>
              onChangeFilterCondition({ businessOperationHourTypes })
            }
            optionFilterProp="label"
            options={businessOperationHourTypeOptions}
            maxTagCount="responsive"
            dropdownRender={(options) => (
              <>
                {options}
                <Spacer size={10} />
                <StyledDivider />
                <BusinessOperationHourTypeTips>
                  時間帯について{" "}
                  <Tooltip
                    placement="top"
                    title="店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 に設定されている時間帯が表示されます"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </BusinessOperationHourTypeTips>
              </>
            )}
          />
          */}

          <FlexGrowSpacer />
          <Button onClick={onEditColumns}>表の項目を編集</Button>
          <Spacer inline size={fieldSpacingAmount} />

          <Button type="primary" onClick={onCsvExport}>
            CSV ダウンロード
          </Button>
        </FiltersRow>

        <Spacer size={fieldSpacingAmount} />
      </>
    );
  },
);
