import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Popconfirm, Tag, Tooltip, Typography } from "antd";
import { updatePriorities } from "util/priority";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { SortableTable } from "components/SortableTable";
import { Thumbnail } from "components/Thumbnail";
import { PlanChoice } from "pages/PlanOptions/types";
import { OptionInputTypeEnum, PlanChoiceInsertInput } from "types/graphql";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  planId: number;
  planOptionId: number;
  inputType: OptionInputTypeEnum;
  planChoices: PlanChoice[];
  onUpdate: (planChoices: PlanChoiceInsertInput[]) => void;
  onDelete: ({ planChoiceId }: { planChoiceId: number }) => void;
  canEditPlanChoice: boolean;
  canDeletePlanChoice: boolean;
};

export const PlanChoiceTable = memo<Props>(
  ({
    loading,
    planId,
    planOptionId,
    inputType,
    planChoices,
    onUpdate,
    onDelete,
    canEditPlanChoice,
    canDeletePlanChoice,
  }) => {
    const showImageColumn = planChoices.some(({ imageUrl }) => Boolean(imageUrl));
    const showMaxOrderableNumColumn = inputType === OptionInputTypeEnum.Count;

    const handleMoveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        if (dragIndex !== hoverIndex) {
          const planChoicesInput = planChoices.map((planChoice) => ({
            imageUrl: planChoice.imageUrl,
            isDefaultSelection: planChoice.isDefaultSelection,
            maxOrderableNum: planChoice.maxOrderableNum,
            name: planChoice.name,
            serial: planChoice.planChoiceId,
            planOptionId: planChoice.planOptionId,
            _planOptionId: planChoice._planOptionId,
            price: planChoice.price,
            priority: planChoice.priority,
            receiptDisplayName: planChoice.receiptDisplayName,
          }));

          onUpdate(updatePriorities(planChoicesInput, dragIndex, hoverIndex));
        }
      },
      [onUpdate, planChoices],
    );

    const columns = [
      ...(showImageColumn
        ? [
            {
              title: "画像",
              align: "center",
              render(_: string, { imageUrl }: PlanChoice) {
                return <Thumbnail url={imageUrl} width={64} height={64} />;
              },
            } as const,
          ]
        : []),
      {
        title: "選択肢名",
        render(_: unknown, { planChoiceId, name }: PlanChoice) {
          return (
            <Tooltip title="選択肢を編集">
              <Link
                to={`/plan/${planId}/option/${planOptionId}/choice/${planChoiceId}/edit`}
                replace
              >
                {name}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: "価格",
        width: 120,
        align: "right" as const,
        render(_: string, { price }: PlanChoice) {
          return `${price} 円`;
        },
      },
      {
        title: "初期選択状態",
        width: 120,
        align: "center" as const,
        render(_: string, { isDefaultSelection }: PlanChoice) {
          return isDefaultSelection ? <Tag>選択</Tag> : <Tag>未選択</Tag>;
        },
      },
      showMaxOrderableNumColumn
        ? {
            title: "最大注文個数",
            width: 120,
            align: "center" as const,
            fixed: "right" as const,
            render(_: unknown, { maxOrderableNum }: PlanChoice) {
              return maxOrderableNum;
            },
          }
        : null,
      canDeletePlanChoice
        ? ({
            title: "",
            align: "center",
            width: 60,
            render(_: string, { planChoiceId }: PlanChoice) {
              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>選択肢を削除しますか？</Paragraph>
                      <Paragraph>一度削除した選択肢を元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDelete({ planChoiceId });
                  }}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const)
        : null,
    ].filter(isNotNull);

    return (
      <SortableTable<PlanChoice>
        type="planChoice"
        rowKey="planChoiceId"
        columns={columns}
        dataSource={planChoices}
        loading={loading}
        bordered
        pagination={false}
        onMove={handleMoveItem}
      />
    );
  },
);
