import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShop } from "hooks/useShop";
import { TableAreaInsertInput } from "types/graphql";

import { AddTableAreaForm } from "./AddTableAreaForm";
import {
  useAddTableAreaGetTableAreaMaxPriorityQuery,
  useAddTableAreaInsertTableAreaMutation,
} from "./queries";

export const AddTableArea = () => {
  const navigate = useNavigate();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data: tableMaxPriorityData } = useAddTableAreaGetTableAreaMaxPriorityQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tableAreaMaxPriority =
    tableMaxPriorityData?.tableArea_aggregate.aggregate?.max?.priority ?? 0;

  const [addTableAreaMutation, { loading: loadingInsertTableArea }] =
    useAddTableAreaInsertTableAreaMutation();

  const onSubmit = useCallback(
    async (tableArea: Omit<TableAreaInsertInput, "priority">) => {
      try {
        const { data: addTableAreaData } = await addTableAreaMutation({
          variables: {
            tableArea: {
              ...tableArea,
              priority: tableAreaMaxPriority + 1,
            },
          },
        });
        const tableAreaId = addTableAreaData?.insert_tableArea?.returning?.[0]?.id;

        if (tableAreaId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/table", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addTableAreaMutation, navigate, tableAreaMaxPriority],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const onFormValidationError = useCallback(() => {
    message.error("編集の保存に失敗しました");
  }, []);

  return (
    <DashboardLayout
      title="エリアを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "エリア / テーブル" }],
      }}
    >
      <PageHeader title="エリアを新規作成" onBack={goBack} />
      <AddTableAreaForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertTableArea}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
