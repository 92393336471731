import React, { useMemo, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { getLastMonthAllTenantAverageScoreByAnswerSegments } from "models/questionnaireAnalytics";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { useCorporation } from "hooks/useCorporation";
import { useDinii } from "hooks/useDinii";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import {
  useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery,
  useQuestionnaireAnalyticsScoresGetQuestionnaireQuery,
  useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery,
  useQuestionnaireAnalyticsScoresGetShopsQuery,
} from "pages/QuestionnaireAnalyticsScoresMetrics/queries";
import {
  FilterConditions,
  ScoreFilter,
  SerializedFilterConditions,
} from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreFilter";
import { ScoreList } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList";

const Title = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 0 16px;
  }
`;

export const QuestionnaireAnalyticsScoresMetrics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );
  const [selectedShopIds, setSelectedShopIds] = useState<string[]>(filterConditions.shopIds ?? []);

  const { data: getQuestionnaire } = useQuestionnaireAnalyticsScoresGetQuestionnaireQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const questionnaireId = getQuestionnaire?.questionnaire[0]?.id;

  const { data: getShopsData } = useQuestionnaireAnalyticsScoresGetShopsQuery(
    corporationId && questionnaireId
      ? {
          variables: { corporationId, questionnaireId },
          onCompleted: (data) => {
            if (!filterConditions.shopIds) {
              const targetShopIds = data.corporation[0]?.companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .flatMap(({ shops }) =>
                  shops.sort((a, b) => a.name.localeCompare(b.name)).map(({ shopId }) => shopId),
                );

              updateFilterCondition({ shopIds: targetShopIds });
              setSelectedShopIds(targetShopIds ?? []);
            }
          },
        }
      : { skip: true },
  );

  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const shopIds = useMemo(() => filterConditions.shopIds ?? [], [filterConditions.shopIds]);

  const range = useMemo(
    () =>
      !filterConditions.range?.[0] || !filterConditions.range[1]
        ? null
        : {
            startAt: filterConditions.range[0].toISOString(),
            endAt: filterConditions.range[1].toISOString(),
          },

    [filterConditions.range],
  );

  const { data: getQuestionnaireAnswers, loading: getQuestionnaireAnswersLoading } =
    useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery(
      questionnaireId && shopIds.length > 0 && range
        ? {
            variables: {
              questionnaireId,
              shopIds,
              ...range,
            },
          }
        : { skip: true },
    );

  const {
    data: getQuestionnaireScoreMetrics,
    error: getQuestionnaireScoreMetricsError,
    loading: getQuestionnaireScoreMetricsLoading,
  } = useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery(
    corporationId && questionnaireId && shopIds.length > 0 && range
      ? {
          variables: {
            input: {
              questionnaireId,
              corporationId,
              shopIds,
              ...range,
              answererSegments: filterConditions.answerSegments,
            },
          },
        }
      : { skip: true },
  );

  const isEmpty = !getQuestionnaireAnswers?.questionnaireAnswer.length;

  const shouldShowError = getQuestionnaireScoreMetricsError;

  const [dinii, getContext] = useDinii();

  const [{ loading: isDownloadingQuestionnaireAnswers }, downloadQuestionnaireAnswers] =
    useAsyncFn(async () => {
      const context = await getContext();

      const startDate = filterConditions.range?.[0];
      const endDate = filterConditions.range?.[1];

      if (!context || !questionnaireId || !corporationId || !startDate || !endDate) {
        return;
      }

      const startDateString = startDate.format("YYYYMMDD");
      const endDateString = endDate.format("YYYYMMDD");

      try {
        const { data } = await dinii.questionnaire.download(context, {
          questionnaireId,
          corporationId,
          shopIds,
          startDate: startDateString,
          endDate: endDateString,
        });
        saveAs(
          data,
          `questionnaire_answers_${corporationId}_${startDateString}_${endDateString}.csv`,
        );

        message.success("ダウンロードが完了しました");
      } catch (err) {
        message.error("不明なエラーが発生しました");
      }
    }, [
      getContext,
      filterConditions.range,
      questionnaireId,
      corporationId,
      dinii.questionnaire,
      shopIds,
    ]);

  const lastMonthAllTenantAverageScore = useMemo(
    () => getLastMonthAllTenantAverageScoreByAnswerSegments(filterConditions.answerSegments ?? []),
    [filterConditions.answerSegments],
  );

  return (
    <DashboardLayout title="店舗スコア一覧">
      <PageHeader
        title={<Title>店舗スコア一覧</Title>}
        footer={
          <ScoreFilter
            companies={companies}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            downloadQuestionnaireAnswers={downloadQuestionnaireAnswers}
            isDownloadingQuestionnaireAnswers={isDownloadingQuestionnaireAnswers}
            selectedShopIds={selectedShopIds}
            setSelectedShopIds={setSelectedShopIds}
          />
        }
      />

      {shouldShowError && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
          <Spacer height={28} />
        </>
      )}

      <ScoreList
        questionnaireScoreMetrics={getQuestionnaireScoreMetrics?.questionnaireScoreMetrics}
        isLoading={
          !getQuestionnaireScoreMetrics &&
          (getQuestionnaireAnswersLoading || getQuestionnaireScoreMetricsLoading)
        }
        isEmpty={isEmpty}
        lastMonthAllTenantAverageScore={lastMonthAllTenantAverageScore}
      />

      <Spacer height={40} />
    </DashboardLayout>
  );
};
