import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { emptyArrayIfUnselected } from "hooks/useMenuOrderItemAnalytics/util";
import { usePagination } from "hooks/usePagination";

import { useMenuOrderItemAnalytics } from "../../hooks/useMenuOrderItemAnalytics";

import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import { exportMenuOrderItemAnalyticsCsv } from "./menuOrderItemAnalyticsCsvExport";
import { MenuOrderItemAnalyticsTable } from "./MenuOrderItemAnalyticsTable";
import { useMenuOrderItemAnalyticsGetEntitiesQuery } from "./queries";
import {
  MenuOrderItemAnalyticsRowWithSortedIndex,
  RepeaterTableType,
  ReportByType,
  Sort,
} from "./types";

const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FiltersWrapper = styled.div`
  flex-grow: 1;
`;

const TableWrapper = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const AbcBadge = styled.span<{
  color: string;
  backgroundColor: string;
}>`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid;
  border-radius: 6px;
  padding: 1px 8px;
  width: 70px;
  height: 24px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const AbcBadgeLighterText = styled.span`
  font-weight: 300;
`;

const AbcAnalysisExplanation = styled.div`
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const TableTopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const MenuOrderItemAnalytics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;
  const [company] = useCompany();
  const companyId = company?.id ?? null;
  const {
    analyticsSetting: { displayTaxIncluded },
  } = useAnalyticsSetting({ corporationId });

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();
    return [now.subtract(30, "days"), now];
  }, []);

  const [pagination, setPagination] = usePagination();
  const [sortedInfo, setSortedInfo] = useState<Sort>({
    column: "taxExcludedAmount",
    direction: "descend",
  });

  const handleSortChange = useCallback(
    (newSort: Sort) =>
      setSortedInfo({
        column: newSort.column ?? "taxExcludedAmount",
        direction: newSort.direction ?? "descend",
      }),
    [],
  );

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      businessOperationHourTypes: "unselected",
      categoryIds: "unselected",
      menuTypes: "unselected",
      range: initialRange,
      shopIds: "unselected",
      reportByType: ReportByType.menu,
      repeaterTableType: RepeaterTableType.allTables,
    },
    undefined,
    {
      serialize: ({
        businessOperationHourTypes,
        categoryIds,
        menuTypes,
        range,
        shopIds,
        reportByType,
        repeaterTableType,
      }) => ({
        businessOperationHourTypes: emptyArrayIfUnselected(businessOperationHourTypes),
        categoryIds: emptyArrayIfUnselected(categoryIds),
        menuTypes: emptyArrayIfUnselected(menuTypes),
        range: serializeRange(range),
        shopIds: emptyArrayIfUnselected(shopIds),
        reportByType: reportByType ?? ReportByType.category,
        repeaterTableType:
          repeaterTableType === "unselected" || repeaterTableType === undefined
            ? RepeaterTableType.allTables
            : repeaterTableType,
      }),
      deserialize: ({
        businessOperationHourTypes,
        categoryIds,
        menuTypes,
        range,
        shopIds,
        reportByType,
        repeaterTableType,
      }) => ({
        businessOperationHourTypes,
        categoryIds,
        menuTypes,
        range: deserializeRange(range),
        shopIds: shopIds ?? null,
        reportByType,
        repeaterTableType,
      }),
    },
  );

  const {
    data: entitiesData,
    loading: entitiesLoading,
    error: entitiesError,
  } = useMenuOrderItemAnalyticsGetEntitiesQuery(
    companyId
      ? {
          variables: {
            companyId,
          },
        }
      : { skip: true },
  );

  const allShopIds = useMemo(
    () => (entitiesData?.shop ?? []).map(({ shopId }) => shopId),
    [entitiesData?.shop],
  );

  const { menuOrderItemAnalytics, isLoading } = useMenuOrderItemAnalytics({
    companyId: companyId ?? null,
    allShopIds,
    filterConditions,
  });

  const sortedMenuOrderItemAnalytics: MenuOrderItemAnalyticsRowWithSortedIndex[] = useMemo(() => {
    const { column, direction } = sortedInfo;
    const isAscending = direction === "ascend";

    return menuOrderItemAnalytics
      .sort((rowA, rowB) => {
        if (column === "repeaterPercentage") {
          return isAscending ? rowA[column] - rowB[column] : rowB[column] - rowA[column];
        }

        return isAscending
          ? rowA[column].actualValue - rowB[column].actualValue
          : rowB[column].actualValue - rowA[column].actualValue;
      })
      .map((row, index) => ({
        ...row,
        sortedIndex: isAscending ? menuOrderItemAnalytics.length - index : index + 1,
      }));
  }, [menuOrderItemAnalytics, sortedInfo]);

  const shops = entitiesData?.shop ?? [];
  const categories = entitiesData?.category ?? [];

  const handleExportCsv = useCallback(() => {
    if (!filterConditions.range?.[0] || !filterConditions.range?.[1]) return;

    const reportByType = filterConditions.reportByType ?? ReportByType.menu;
    const [startDate, endDate] = filterConditions.range;

    const fileName = `${company?.name ?? ""}_商品分析_${reportByType}_${startDate.format(
      "YYYYMMDD",
    )}-${endDate.format("YYYYMMDD")}`;

    exportMenuOrderItemAnalyticsCsv({
      fileName,
      reportByType,
      showTaxIncluded: displayTaxIncluded,
      rows: sortedMenuOrderItemAnalytics,
    });
  }, [company?.name, sortedMenuOrderItemAnalytics, displayTaxIncluded, filterConditions]);

  return (
    <DashboardLayout title="商品分析">
      <PageHeader title="商品分析" footer={null} />
      <Spacer size={12} />
      <TopBar>
        <FiltersWrapper>
          <Filters
            filterConditions={{
              businessOperationHourTypes:
                filterConditions.businessOperationHourTypes ?? "unselected",
              categoryIds: filterConditions.categoryIds ?? "unselected",
              menuTypes: filterConditions.menuTypes ?? "unselected",
              range: filterConditions.range,
              shopIds: filterConditions.shopIds ?? "unselected",
              reportByType: filterConditions.reportByType ?? ReportByType.menu,
              repeaterTableType: filterConditions.repeaterTableType ?? "unselected",
            }}
            shops={shops}
            categories={categories}
            onChangeFilterCondition={updateFilterCondition}
            onCsvExport={handleExportCsv}
          />
        </FiltersWrapper>
      </TopBar>

      {entitiesError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <TableWrapper>
        <TableTopBar>
          {filterConditions.reportByType !== ReportByType.menuType ? (
            <AbcAnalysisExplanation>
              <span>ABC分析の説明</span>
              <Spacer size={4} />
              <Tooltip
                placement="top"
                title="出数・売上・粗利の構成比を大きいものから足していき、上位70%をAランク、上位70~90%をBランク、上位90~100%をCランクとします。
              売れ筋や改善すべき商品が分かります。"
              >
                <QuestionCircleOutlined />
              </Tooltip>

              <Spacer size={8} />
              <AbcBadge color="#F462A8" backgroundColor="#FDE7F2">
                A<AbcBadgeLighterText>ランク</AbcBadgeLighterText>
              </AbcBadge>
              <Spacer size={4} />
              <AbcBadge color="#73D13D" backgroundColor="#F6FFED">
                B<AbcBadgeLighterText>ランク</AbcBadgeLighterText>
              </AbcBadge>
              <Spacer size={4} />
              <AbcBadge color="#BFBFBF" backgroundColor={colors.BackGround.Tertiary}>
                C<AbcBadgeLighterText>ランク</AbcBadgeLighterText>
              </AbcBadge>
            </AbcAnalysisExplanation>
          ) : null}
        </TableTopBar>

        <MenuOrderItemAnalyticsTable
          showTaxIncluded={displayTaxIncluded}
          rows={sortedMenuOrderItemAnalytics}
          isLoading={isLoading}
          reportByType={filterConditions.reportByType ?? ReportByType.menu}
          repeaterTableType={
            filterConditions.repeaterTableType === undefined ||
            filterConditions.repeaterTableType === "unselected"
              ? RepeaterTableType.allTables
              : filterConditions.repeaterTableType
          }
          pagination={pagination}
          sortedInfo={sortedInfo}
          onPaginationChange={setPagination}
          onSortChange={handleSortChange}
        />
      </TableWrapper>
    </DashboardLayout>
  );
};
