import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import { blue } from "@ant-design/colors";
import { CloseOutlined } from "@ant-design/icons";

import { InputNumber } from "components/Input/InputNumber";
import { DishUpSlipGroupField } from "pages/ShopMenus/ShopMenuFields/DishUpSlipGroupField";
import { IsSoldOutField } from "pages/ShopMenus/ShopMenuFields/IsSoldOutField";
import { IsVisibleForCustomerField } from "pages/ShopMenus/ShopMenuFields/IsVisibleForCustomerField";
import { IsVisibleForStaffField } from "pages/ShopMenus/ShopMenuFields/IsVisibleForStaffField";
import { KdDisplayTargetField } from "pages/ShopMenus/ShopMenuFields/KdDisplayTargetField";
import { KitchenRoleField } from "pages/ShopMenus/ShopMenuFields/KitchenRoleField";
import type { DishUpSlipGroup, KdDisplayTarget, Role } from "pages/ShopMenus/types";

import type { BulkEditConditions } from "../types";

const Wrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  background-color: ${blue[0]};
`;

const LeftColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 8px;
`;

const SelectedCount = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 4px;
`;

const CenterColumn = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const WithLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  margin-right: 8px;
  word-break: keep-all;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 120px;
`;

type Props = {
  selectedCount: number;
  onClearButtonClick: () => void;
  roles: Role[];
  dishUpSlipGroups: DishUpSlipGroup[];
  kdDisplayTargets: KdDisplayTarget[];
  bulkEditConditions: BulkEditConditions;
  updateBulkEditCondition: <K extends keyof BulkEditConditions>(
    key: K,
    value: BulkEditConditions[K],
  ) => void;
  onUpdateButtonClick: () => void;
  loading: boolean;
  shouldShowKdDisplayTargetField: boolean;
};

export const BulkUpdateBanner = memo<Props>(
  ({
    selectedCount,
    onClearButtonClick,
    roles,
    dishUpSlipGroups,
    kdDisplayTargets,
    bulkEditConditions,
    updateBulkEditCondition,
    onUpdateButtonClick,
    loading,
    shouldShowKdDisplayTargetField,
  }) => {
    const onChangeInputNumber = useCallback(
      (key: keyof BulkEditConditions, value: string | number | null | undefined) => {
        if (value === null || (typeof value === "string" && value.length === 0)) {
          updateBulkEditCondition(key, undefined);
          return;
        }

        const casted = Number(value);
        updateBulkEditCondition(key, !Number.isNaN(casted) ? casted : undefined);
      },
      [updateBulkEditCondition],
    );

    return (
      <Wrapper>
        <LeftColumn>
          <ClearButton role="button" tabIndex={0} onClick={onClearButtonClick}>
            <CloseOutlined />
          </ClearButton>

          <SelectedCount>{selectedCount}</SelectedCount>
          <span>件 選択中</span>
        </LeftColumn>

        <CenterColumn>
          <Row gutter={[16, 16]}>
            <Col flex="1 0">
              <IsVisibleForCustomerField
                value={bulkEditConditions.isVisibleForCustomer}
                onChange={(value) => updateBulkEditCondition("isVisibleForCustomer", value)}
              />
            </Col>
            <Col flex="1 0">
              <IsVisibleForStaffField
                value={bulkEditConditions.isVisibleForStaff}
                onChange={(value) => updateBulkEditCondition("isVisibleForStaff", value)}
              />
            </Col>
            <Col flex="1 0">
              <IsSoldOutField
                value={bulkEditConditions.isSoldOut}
                onChange={(value) => updateBulkEditCondition("isSoldOut", value)}
              />
            </Col>
            <Col flex="1 0">
              <WithLabelWrapper>
                <Label>在庫数</Label>
                <StyledInputNumber
                  min={0}
                  value={bulkEditConditions.currentStockNum ?? undefined}
                  onChange={(value) => onChangeInputNumber("currentStockNum", value)}
                />
              </WithLabelWrapper>
            </Col>
            <Col flex="1 0">
              <WithLabelWrapper>
                <Label>日次在庫数</Label>
                <StyledInputNumber
                  min={0}
                  value={bulkEditConditions.dailyStockNum ?? undefined}
                  onChange={(value) => onChangeInputNumber("dailyStockNum", value)}
                />
              </WithLabelWrapper>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col flex="2 0">
              <WithLabelWrapper>
                <Label>キッチンプリンター</Label>
                <KitchenRoleField
                  value={bulkEditConditions.kitchenRoleIds}
                  onChange={(value) => updateBulkEditCondition("kitchenRoleIds", value)}
                  roles={roles}
                />
              </WithLabelWrapper>
            </Col>
            <Col flex="2 0">
              <WithLabelWrapper>
                <Label>デシャップ伝票グループ</Label>
                <DishUpSlipGroupField
                  value={bulkEditConditions.dishUpSlipGroupIds}
                  onChange={(value) => updateBulkEditCondition("dishUpSlipGroupIds", value)}
                  dishUpSlipGroups={dishUpSlipGroups}
                />
              </WithLabelWrapper>
            </Col>
            {shouldShowKdDisplayTargetField && (
              <Col flex="2 0">
                <WithLabelWrapper>
                  <Label>キッチンディスプレイ</Label>
                  <KdDisplayTargetField
                    value={bulkEditConditions.kdDisplayTargetIds}
                    onChange={(value) => updateBulkEditCondition("kdDisplayTargetIds", value)}
                    kdDisplayTargets={kdDisplayTargets}
                  />
                </WithLabelWrapper>
              </Col>
            )}
          </Row>
        </CenterColumn>
        <div>
          <Button type="primary" onClick={onUpdateButtonClick} loading={loading}>
            更新する
          </Button>
        </div>
      </Wrapper>
    );
  },
);
