import dayjs from "dayjs";

import { MessageDelivery } from "types/graphql";

/**
 * 一つの配信に対して設定できる最大のメッセージ数
 */
export const MAXIMUM_LENGTH_OF_MESSAGES = 5;

export const getNextDeliverAt = ({
  messageDelivery,
  now,
}: {
  messageDelivery: Pick<MessageDelivery, "deliverAt" | "repeatDeliveryTime" | "isDraft">;
  now: dayjs.Dayjs;
}) => {
  const deliverAt = dayjs(messageDelivery.deliverAt);

  // 下書き
  if (messageDelivery.isDraft) return deliverAt;

  // 初回配信
  if (now.isBefore(deliverAt)) return deliverAt;

  // 初回配信済、毎日配信無効
  if (messageDelivery.repeatDeliveryTime === null) return deliverAt;

  // 毎日配信
  const deliverAtWithRepeatDeliveryTime = dayjs(
    `${now.format("YYYY/MM/DD")} ${messageDelivery.repeatDeliveryTime}`,
  );
  return deliverAtWithRepeatDeliveryTime.isAfter(now)
    ? deliverAtWithRepeatDeliveryTime
    : deliverAtWithRepeatDeliveryTime.add(1, "day");
};

export const MIN_DELIVERY_AT_MINUTES_DIFF = 2;
