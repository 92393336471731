import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditMenuPikaichiMenuFormItem } from "../useEditMenuPikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiCategoryCdField = memo<Props>((props) => (
  <EditMenuPikaichiMenuFormItem label="分類コード" name="pikaichiCategoryCd" {...props}>
    <InputCode />
  </EditMenuPikaichiMenuFormItem>
));
