import React, { memo } from "react";
import styled from "styled-components";
import { Button, Card, Col, Row } from "antd";
import { MAXIMUM_LENGTH_OF_MESSAGES } from "models/messageDelivery";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { Form, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { WithPreviewFormContainer } from "components/Form/WithPreviewFormContainer";
import { MessagePreviewContent } from "components/MessageDeliveryForm/MessagePreview/MessagePreviewContent";
import { LineOfficialAccountFragment } from "components/MessageDeliveryForm/MessagePreview/queries";
import { Spacer } from "components/Spacer";
import { UserAppPreview } from "components/UserAppPreview";
import { CustomerListField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/CustomerListField";
import { CustomerSegmentField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/CustomerSegmentField";
import { NameField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/NameField";
import { QuestionnaireConfigField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/QuestionnaireConfigField";
import { Scope, ScopeField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/ScopeField";
import { TextField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/TextField";
import { TypeField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/TypeField";
import { UrlField } from "pages/EditMessageDelivery/EditMessageDeliveryForm/UrlField";
import {
  Coupon,
  Customer,
  Menu,
  MessageDelivery,
  QuestionnaireConfig,
  Shop,
} from "pages/EditMessageDelivery/types";
import {
  BusinessOperationHourTypeEnum,
  MessageDeliveryMessageTypeEnum,
  UpdateMessageDeliveryInput,
} from "types/graphql";
import { Role } from "types/role";

import { CouponSelectField } from "./CouponSelectField";
import { CSVUploadCustomerListField } from "./CSVUploadCustomerListField";
import { DateField } from "./DateField";
import { IsRepeatDeliveryField } from "./IsRepeatDeliveryField";
import { TimeField } from "./TimeField";
import {
  EditMessageDeliveryFormItem,
  EditMessageDeliveryFormValues,
  useEditMessageDeliveryForm,
} from "./useEditMessageDeliveryForm";

const StyledCard = styled(Card)`
  margin: 20px;
`;

const AddMessageButton = styled(Button)`
  margin: 0 20px 20px;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 300px;
`;

type Props = {
  loading: boolean;
  messageDelivery: MessageDelivery;
  onSubmit: ({ messageDelivery }: { messageDelivery: UpdateMessageDeliveryInput }) => void;
  onSuspend: () => void;
  onResume: () => void;
  shops: Shop[];
  menus: Menu[];
  coupons: Coupon[];
  questionnaireConfigs: QuestionnaireConfig[];
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
  role: Role | undefined;
  lineOfficialAccounts: LineOfficialAccountFragment[];
  onClose: () => void;
  onSearchCustomersByLikeName: ({ likeName }: { likeName: string }) => Promise<Customer[]>;
  onSearchCustomersByCustomerIds: ({
    customerIds,
  }: {
    customerIds: string[];
  }) => Promise<Customer[]>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  inputDisabled: boolean;
};

export const EditMessageDeliveryForm = memo<Props>(
  ({
    loading,
    coupons,
    questionnaireConfigs,
    messageDelivery,
    shops,
    menus,
    shopBusinessOperationHourTypes,
    role,
    lineOfficialAccounts,
    onSubmit,
    onSuspend,
    onResume,
    onClose,
    onSearchCustomersByCustomerIds,
    onSearchCustomersByLikeName,
    onFormValidationError,
    inputDisabled,
  }: Props) => {
    const { form, initialValues, submit, submitAsDraft, submitAsSuspended } =
      useEditMessageDeliveryForm({
        messageDelivery,
        onSubmit,
        onResume,
        onSuspend,
        inputDisabled,
        onFormValidationError,
        shops,
      });

    const initialCustomers =
      messageDelivery.customerList?.customerListCustomers.map(({ customer }) => customer) ?? [];

    return (
      <Form
        name="messageDelivery"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        disabled={inputDisabled}
      >
        <WithPreviewFormContainer>
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ情報">
                <NameField endSpacer={null} />
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="配信対象">
                <ScopeField endSpacer={null} />
                <Spacer size={24} />
                <EditMessageDeliveryFormItem.NonProperty noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const scope: Scope = getFieldValue("scope");
                    if (scope === "customerSegment") {
                      return (
                        <CustomerSegmentField
                          form={form}
                          shops={shops}
                          menus={menus}
                          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
                        />
                      );
                    }
                    if (scope === "customerListCsvUpload") {
                      return (
                        <CSVUploadCustomerListField
                          onSearchCustomersByCustomerIds={onSearchCustomersByCustomerIds}
                        />
                      );
                    }
                    if (scope === "customerList") {
                      return (
                        <CustomerListField
                          onSearchCustomersByLikeName={onSearchCustomersByLikeName}
                          initialCustomers={initialCustomers}
                        />
                      );
                    }
                  }}
                </EditMessageDeliveryFormItem.NonProperty>
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <FormSection title="配信日時">
            <Row>
              <Col span={12}>
                <DateField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverTimeName="deliverTime"
                />
              </Col>
              <Col span={12}>
                <TimeField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverDateName="deliverDate"
                />
              </Col>
            </Row>
            <Spacer size={24} />
            <IsRepeatDeliveryField />
          </FormSection>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ">
                <FormList name="messages">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <StyledCard key={field.key}>
                          <Row justify="space-around" align="middle">
                            <Col flex={1}>
                              <TypeField name={field.name} />
                            </Col>
                            <Col>
                              <Button
                                icon={<DeleteIcon disabled={fields.length === 1} />}
                                onClick={() => remove(field.name)}
                                disabled={fields.length === 1}
                              />
                            </Col>
                          </Row>
                          <Spacer height={20} />
                          <EditMessageDeliveryFormItem.NonProperty noStyle shouldUpdate>
                            {({ getFieldValue, setFields }) => {
                              const type: MessageDeliveryMessageTypeEnum = getFieldValue([
                                "messages",
                                field.name,
                                "type",
                              ]);

                              switch (type) {
                                case "text":
                                  return (
                                    <TextField
                                      name={field.name}
                                      endSpacer={<Spacer size={22} /* char count の高さ */ />}
                                    />
                                  );
                                case "image": {
                                  const imageNamePath = ["messages", field.name, "imageUrl"];
                                  const imageUrl: string = getFieldValue(imageNamePath);
                                  return (
                                    <>
                                      <Row gutter={16}>
                                        <Col>
                                          <ImageField
                                            image={imageUrl}
                                            setUploadImage={(image) =>
                                              setFields([{ name: imageNamePath, value: image }])
                                            }
                                            uploadImageApiKey="messageImage"
                                            formName="EditMessageDeliveryForm"
                                            bottomHelpText="画像は、正方形のみ選択可能です"
                                            shouldValidateSquareImage
                                          />
                                        </Col>
                                        <Col flex={1}>
                                          <UrlField name={field.name} endSpacer={null} />
                                        </Col>
                                      </Row>
                                      <Spacer
                                        size={8} /* ImageField に 24px の margin-bottom がある */
                                      />
                                    </>
                                  );
                                }
                                case "coupon": {
                                  const couponId = getFieldValue([
                                    "messages",
                                    field.name,
                                    "couponId",
                                  ]);
                                  return (
                                    <>
                                      <CouponSelectField
                                        name={field.name}
                                        couponId={couponId}
                                        coupons={coupons}
                                      />
                                      <Spacer size={32} />
                                    </>
                                  );
                                }
                                case "questionnaire": {
                                  const questionnaireConfigId = getFieldValue([
                                    "messages",
                                    field.name,
                                    "questionnaireConfigId",
                                  ]);
                                  return (
                                    <>
                                      <QuestionnaireConfigField
                                        name={field.name}
                                        questionnaireConfigId={questionnaireConfigId}
                                        questionnaireConfigs={questionnaireConfigs}
                                      />
                                      <Spacer size={32} />
                                    </>
                                  );
                                }
                                default:
                                  return <p>Unreachable</p>;
                              }
                            }}
                          </EditMessageDeliveryFormItem.NonProperty>
                        </StyledCard>
                      ))}
                      <FormActions>
                        <AddMessageButton
                          type="primary"
                          onClick={() => add({ type: "text", text: "" })}
                          disabled={fields.length >= MAXIMUM_LENGTH_OF_MESSAGES}
                        >
                          追加する
                        </AddMessageButton>
                      </FormActions>
                    </>
                  )}
                </FormList>
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />

          {messageDelivery.isDraft ? (
            <FormActions>
              <Button onClick={onClose}>キャンセル</Button>
              <Button onClick={submitAsDraft} loading={loading} disabled={loading}>
                下書き保存
              </Button>
              <Button type="primary" onClick={submit} loading={loading} disabled={loading}>
                配信予約
              </Button>
            </FormActions>
          ) : messageDelivery.isSuspended ? (
            <FormActions>
              <Button danger onClick={submit} loading={loading} disabled={loading}>
                配信再開
              </Button>
              <Button onClick={onClose}>キャンセル</Button>
              <Button
                type="primary"
                onClick={submitAsSuspended}
                loading={loading}
                disabled={inputDisabled || loading}
              >
                更新
              </Button>
            </FormActions>
          ) : (
            <FormActions>
              <Button danger onClick={submitAsSuspended} loading={loading} disabled={loading}>
                配信停止
              </Button>
              <Button onClick={onClose}>キャンセル</Button>
              <Button
                type="primary"
                onClick={submit}
                loading={loading}
                disabled={inputDisabled || loading}
              >
                更新
              </Button>
            </FormActions>
          )}

          <EditMessageDeliveryFormItem.NonProperty
            shouldUpdate
            noStyle
            initialValue={initialValues}
          >
            {({ getFieldsValue }) => {
              const { messages } = getFieldsValue() as EditMessageDeliveryFormValues;

              return (
                <PreviewWrapper>
                  <UserAppPreview>
                    <MessagePreviewContent
                      lineOfficialAccount={lineOfficialAccounts[0]}
                      messages={messages ?? []}
                    />
                  </UserAppPreview>
                </PreviewWrapper>
              );
            }}
          </EditMessageDeliveryFormItem.NonProperty>
        </WithPreviewFormContainer>
      </Form>
    );
  },
);
