import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";

import { EditDiniiPayConfigFormItem } from "../useEditDiniiPayConfigForm";

import { CardFeeRateField } from "./CardFeeRateField";
import { GmoTerminalIdField } from "./GmoTerminalIdField";
import { UseAdyenTerminalPaymentField } from "./UseAdyenTerminalPaymentField";
import { UseGmoTerminalPaymentField } from "./UseGmoTerminalPaymentField";

const SectionTitle = styled.h2`
  font-weight: bold;
  margin: 0;
`;

export const EditTerminalPaymentConfigFields = memo(() => (
  <>
    <SectionTitle>オフライン決済設定</SectionTitle>

    <Spacer size={24} />

    <Row>
      <Col span={6}>
        <UseAdyenTerminalPaymentField />
      </Col>
    </Row>

    <EditDiniiPayConfigFormItem.NonProperty
      noStyle
      shouldUpdate={withFormDependencies(({ terminalPaymentConfig }) => [
        terminalPaymentConfig.useAdyenTerminalPayment,
      ])}
    >
      {({ getFieldValue }) => {
        const useAdyenTerminalPayment = getFieldValue([
          "terminalPaymentConfig",
          "useAdyenTerminalPayment",
        ]) as boolean;
        return (
          <>
            <Row>
              <Col span={6}>
                <CardFeeRateField
                  label="VISA 料率"
                  name={["terminalPaymentConfig", "visaCardFeeRate"]}
                  disabled={!useAdyenTerminalPayment}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <CardFeeRateField
                  name={["terminalPaymentConfig", "masterCardFeeRate"]}
                  label="MASTER 料率"
                  disabled={!useAdyenTerminalPayment}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <CardFeeRateField
                  name={["terminalPaymentConfig", "jcbCardFeeRate"]}
                  label="JCB 料率"
                  disabled={!useAdyenTerminalPayment}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <CardFeeRateField
                  name={["terminalPaymentConfig", "amexCardFeeRate"]}
                  label="AMEX 料率"
                  disabled={!useAdyenTerminalPayment}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <CardFeeRateField
                  name={["terminalPaymentConfig", "dinersCardFeeRate"]}
                  label="DINERS 料率"
                  disabled={!useAdyenTerminalPayment}
                />
              </Col>
            </Row>
          </>
        );
      }}
    </EditDiniiPayConfigFormItem.NonProperty>

    <Spacer size={24} />

    <Row>
      <Col span={6}>
        <UseGmoTerminalPaymentField />
      </Col>
    </Row>

    <EditDiniiPayConfigFormItem.NonProperty
      noStyle
      shouldUpdate={withFormDependencies(({ terminalPaymentConfig }) => [
        terminalPaymentConfig.useGmoTerminalPayment,
      ])}
    >
      {({ getFieldValue }) => {
        const useGmoTerminalPayment = getFieldValue([
          "terminalPaymentConfig",
          "useGmoTerminalPayment",
        ]) as boolean;
        return (
          <Row>
            <Col span={6}>
              <GmoTerminalIdField disabled={!useGmoTerminalPayment} />
            </Col>
          </Row>
        );
      }}
    </EditDiniiPayConfigFormItem.NonProperty>
  </>
));
