import React, { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { FilterConditions } from "models/closeCashRegister";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useShop } from "hooks/useShop";

import { CloseCashRegisterTable } from "./CloseCashRegisterTable";
import {
  useExcludeFromAggregationGetCloseCashRegistersQuery,
  useExcludeFromAggregationUpdateExcludeFromAggregationMutation,
} from "./queries";
import { RangeFilter } from "./RangeFilter";

type Serialized = { range?: [number | null, number | null] | undefined } & Omit<
  FilterConditions,
  "range"
>;

export const ExcludeFromAggregation = () => {
  const [shop] = useShop();

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    Serialized
  >(
    {
      // NOTE: 直近数日間の確認が多いので、直近一週間の日付を設定しておく
      range: [dayjs().add(-7, "days").startOf("day"), dayjs().endOf("day")],
    },
    "filterConditions",
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const { data, loading, refetch } = useExcludeFromAggregationGetCloseCashRegistersQuery(
    shop && filterConditions?.range?.[0] && filterConditions.range[1]
      ? {
          variables: {
            shopId: shop.shopId,
            from: dayjs(filterConditions.range[0]).startOf("day").format(),
            to: dayjs(filterConditions.range[1]).endOf("day").format(),
          },
        }
      : {
          skip: true,
        },
  );

  const closeCashRegisters = useMemo(() => data?.closeCashRegister ?? [], [data]);

  const [updateExcludedFromAggregationMutation] =
    useExcludeFromAggregationUpdateExcludeFromAggregationMutation({
      onCompleted() {
        message.success("更新しました");
      },
    });

  const updateExcludedFromAggregation = useCallback(
    async ({
      closeCashRegisterId,
      excludedFromAggregation,
    }: {
      closeCashRegisterId: string;
      excludedFromAggregation: boolean;
    }) => {
      await updateExcludedFromAggregationMutation({
        variables: {
          id: closeCashRegisterId,
          excludedFromAggregation,
        },
      });
      await refetch();
    },
    [updateExcludedFromAggregationMutation, refetch],
  );

  return (
    <DashboardLayout title="集計除外設定">
      <PageHeader
        title="集計除外設定"
        description="集計から除外されたレジ精算に紐づくデータが、売上や BI 連携の集計対象から除外されるようになります。"
      />

      <ShopSelector />

      <Spacer height={16} />

      <RangeFilter filterConditions={filterConditions} onChange={updateFilterCondition} />

      <Spacer height={16} />

      <CloseCashRegisterTable
        closeCashRegisters={closeCashRegisters}
        loading={loading}
        onCheckExcludedFromAggregation={updateExcludedFromAggregation}
      />
    </DashboardLayout>
  );
};
