import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCorporation } from "hooks/useCorporation";

import { EditLineReportingBotConfigForm } from "./EditLineReportingBotConfigForm";
import {
  useEditLineReportingBotConfigGetLineReportingBotConfigQuery,
  useEditLineReportingBotConfigGetShopsQuery,
  useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation,
} from "./queries";

export const EditLineReportingBotConfig = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    data: getShopsData,
    loading: loadingGetShopsData,
    error: errorGetShopsData,
  } = useEditLineReportingBotConfigGetShopsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);

  const {
    data: getLineReportingBotConfigData,
    loading: loadingLineReportingBotConfigData,
    error: errorLineReportingBotConfigData,
    refetch: refetchLineReportingBotConfigData,
  } = useEditLineReportingBotConfigGetLineReportingBotConfigQuery(
    id ? { variables: { lineReportingBotConfigId: id } } : { skip: true },
  );
  const lineReportingBotConfig = useMemo(
    () => getLineReportingBotConfigData?.lineReportingBotConfig_by_pk,
    [getLineReportingBotConfigData?.lineReportingBotConfig_by_pk],
  );

  const [
    updateLineReportingBotConfig,
    { loading: loadingUpdateLineReportingBotConfig, error: errorUpdateLineReportingBotConfig },
  ] = useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [{ loading: loadingSubmit, error: errorSubmit }, submit] = useAsyncFn(
    async ({
      name,
      shopIds,
      isSuspended,
    }: {
      name: string;
      shopIds: string[];
      isSuspended: boolean;
    }) => {
      if (!corporationId) return;
      if (!lineReportingBotConfig) return;

      await updateLineReportingBotConfig({
        variables: {
          corporationId,
          lineReportingBotConfigId: lineReportingBotConfig.id,
          name,
          isSuspended,
          input: shopIds.map((shopId) => ({
            shopId,
            lineReportingBotConfigId: lineReportingBotConfig.id,
          })),
        },
      });

      message.success("作成しました");

      goBack();

      refetchLineReportingBotConfigData();
    },
    [
      corporationId,
      lineReportingBotConfig,
      goBack,
      refetchLineReportingBotConfigData,
      updateLineReportingBotConfig,
    ],
  );

  const showErrorMessage = useCallback(() => {
    message.error("入力内容に誤りがあります");
  }, []);

  const error =
    errorGetShopsData ||
    errorLineReportingBotConfigData ||
    errorUpdateLineReportingBotConfig ||
    errorSubmit;

  const loading =
    loadingGetShopsData ||
    loadingLineReportingBotConfigData ||
    loadingUpdateLineReportingBotConfig ||
    loadingSubmit;

  return (
    <DashboardLayout
      title="ダイニーAIくん連携"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ダイニーAIくん連携" }],
      }}
    >
      <PageHeader title="ダイニーAIくん連携" onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {lineReportingBotConfig && (
        <EditLineReportingBotConfigForm
          shops={shops}
          lineReportingBotConfig={lineReportingBotConfig}
          onSubmit={submit}
          onClickBackButton={goBack}
          onFormValidationError={showErrorMessage}
          disabled={Boolean(error || loading)}
        />
      )}
    </DashboardLayout>
  );
};
