import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

import { EditShopMenusBulkSummary } from "../EditShopMenusBulkSummary";
import { BulkEditConditions, Choice, Menu, Shop } from "../types";

type Props = {
  selectedMenuRowKeys: string[];
  selectedChoiceRowKeys: string[];
  selectedRowKeys: string[];
  menuRowKeyToMenuMap: Map<string, Menu>;
  choiceRowKeyToChoiceMap: Map<string, Choice>;
  shopIdToShopMap: Map<string, Shop>;
  bulkEditConditions: BulkEditConditions;
  loading: boolean;
  onSubmit: () => void;
  allShopIds: string[];
};

export const EditShopMenusBulkConfirmModal = memo<Props>(
  ({
    selectedMenuRowKeys,
    selectedChoiceRowKeys,
    selectedRowKeys,
    menuRowKeyToMenuMap,
    choiceRowKeyToChoiceMap,
    shopIdToShopMap,
    bulkEditConditions,
    loading,
    onSubmit,
    allShopIds,
  }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => {
      navigate(`/shopMenu/edit/bulk`, { replace: true });
    }, [navigate]);
    return (
      <Modal
        title="変更内容確認"
        open
        width="100%"
        onCancel={goBack}
        footer={[
          <Button key="cancel" onClick={goBack}>
            キャンセル
          </Button>,
          <Button
            key="add"
            type="primary"
            loading={loading}
            onClick={onSubmit}
            disabled={selectedRowKeys.length === 0}
          >
            更新
          </Button>,
        ]}
      >
        <EditShopMenusBulkSummary
          selectedMenuRowKeys={selectedMenuRowKeys}
          selectedChoiceRowKeys={selectedChoiceRowKeys}
          menuRowKeyToMenuMap={menuRowKeyToMenuMap}
          choiceRowKeyToChoiceMap={choiceRowKeyToChoiceMap}
          shopIdToShopMap={shopIdToShopMap}
          bulkEditConditions={bulkEditConditions}
          allShopIds={allShopIds}
        />
      </Modal>
    );
  },
);
