import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Tag } from "antd";
import { isNotNull } from "util/type/primitive";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useShop } from "hooks/useShop";
import { useViewport } from "hooks/useViewport";

import { Shop, ShopOptionKitchenRole } from "../types";

import { DefaultText } from "./DefaultText";

const DEFAULT_PAGE_SIZE = 10;

const Wrapper = styled.div`
  padding: 24px;
  background-color: #ffffff;
`;

type Props = {
  loading: boolean;
  optionId: number;
  shops: Shop[];
  shopOptionKitchenRoles: ShopOptionKitchenRole[];
};

const createColumns = ({
  useFixedColumns,
  canAccessEditOptionRoles,
  shopOptionKitchenRoles,
  optionId,
  setShop,
}: {
  useFixedColumns: boolean;
  canAccessEditOptionRoles: boolean;
  shopOptionKitchenRoles: ShopOptionKitchenRole[];
  optionId: number;
  setShop(shopId?: string): void;
}) => [
  {
    title: "店舗名",
    dataIndex: "name",
    width: 240,
    fixed: useFixedColumns ? ("left" as const) : undefined,
  },
  {
    title: "出力先設定",
    children: [
      {
        title: "キッチンプリンター",
        width: 160,
        render(_: string, { shopId }: Shop) {
          const kitchenRoles = shopOptionKitchenRoles.filter((role) => role.shopId === shopId);

          return kitchenRoles.length === 0 ? (
            <DefaultText>メニュー本体と一緒に印字されます</DefaultText>
          ) : (
            kitchenRoles.map((role) => (
              <Tag color="blue" key={role?.kitchenRoleId ?? 0}>
                {role?.kitchenRole.name}
              </Tag>
            ))
          );
        },
      },
      canAccessEditOptionRoles
        ? {
            title: "",
            width: 60,
            align: "center",
            render(_: string, { shopId }: Shop) {
              return (
                <IconLink to={`/option/${optionId}/dealer/edit/${shopId}`}>
                  <EditIcon onClick={() => setShop(shopId)} />
                </IconLink>
              );
            },
          }
        : null,
    ].filter(isNotNull),
  },
];

export const OptionDealerTable = memo<Props>(
  ({ loading, optionId, shops, shopOptionKitchenRoles }) => {
    const [defaultPagination, setPagination] = usePagination();

    const { isFeatureEnabled } = useIsFeatureEnabled();
    const pagination = useMemo(
      () => ({
        ...defaultPagination,
        defaultCurrent: defaultPagination.defaultCurrent ?? 1,
        defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
      }),
      [defaultPagination],
    );

    const [_, setShop] = useShop();

    const { isDesktop } = useViewport();

    const columns = useMemo(
      () =>
        createColumns({
          useFixedColumns: isDesktop,
          optionId,
          shopOptionKitchenRoles,
          setShop,
          canAccessEditOptionRoles: isFeatureEnabled("editOptionKitchenRoles"),
        }),
      [isDesktop, isFeatureEnabled, optionId, setShop, shopOptionKitchenRoles],
    );

    return (
      <Wrapper>
        <Table
          bordered
          rowKey="shopId"
          loading={loading}
          dataSource={shops}
          columns={columns}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          pagination={pagination}
        />
      </Wrapper>
    );
  },
);
