import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SalesBudgetGetSalesForBudget = gql`
    query SalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByDays {
      dailySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;
export const SalesBudgetGetDailySalesBudget = gql`
    query SalesBudgetGetDailySalesBudget($shopId: uuid!, $from: date!, $to: date!) {
  dailySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $from, _lte: $to}}
  ) {
    id
    businessDate
    taxExcludedAmount
  }
}
    `;
export const SalesBudgetGetMonthlySalesBudget = gql`
    query SalesBudgetGetMonthlySalesBudget($shopId: uuid!, $from: date!, $to: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $from, _lte: $to}}
  ) {
    id
    businessDate
    taxExcludedAmount
  }
}
    `;
export type SalesBudgetGetSalesForBudgetQueryVariables = Types.Exact<{
  input: Types.SalesForBudgetInput;
}>;


export type SalesBudgetGetSalesForBudgetQuery = (
  { __typename?: 'query_root' }
  & { salesForBudget: (
    { __typename?: 'SalesForBudget' }
    & { salesByDays?: Types.Maybe<(
      { __typename?: 'SalesByDays' }
      & { dailySalesAmount: Array<(
        { __typename?: 'DailySalesAmount' }
        & Pick<Types.DailySalesAmount, 'businessDate' | 'taxExcludedSalesAmount'>
      )> }
    )>, salesByMonths?: Types.Maybe<(
      { __typename?: 'SalesByMonths' }
      & { monthlySalesAmount: Array<(
        { __typename?: 'MonthlySalesAmount' }
        & Pick<Types.MonthlySalesAmount, 'businessDate' | 'taxExcludedSalesAmount'>
      )> }
    )>, averageAndMaxSalesByDayOfWeek?: Types.Maybe<Array<(
      { __typename?: 'AverageAndMaxSalesByDayOfWeek' }
      & Pick<Types.AverageAndMaxSalesByDayOfWeek, 'dayOfWeek' | 'averageTaxExcludedSalesAmount' | 'maxTaxExcludedSalesAmount'>
    )>> }
  ) }
);

export type SalesBudgetGetDailySalesBudgetQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['date'];
  to: Types.Scalars['date'];
}>;


export type SalesBudgetGetDailySalesBudgetQuery = (
  { __typename?: 'query_root' }
  & { dailySalesBudget: Array<(
    { __typename?: 'dailySalesBudget' }
    & Pick<Types.DailySalesBudget, 'id' | 'businessDate' | 'taxExcludedAmount'>
  )> }
);

export type SalesBudgetGetMonthlySalesBudgetQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['date'];
  to: Types.Scalars['date'];
}>;


export type SalesBudgetGetMonthlySalesBudgetQuery = (
  { __typename?: 'query_root' }
  & { monthlySalesBudget: Array<(
    { __typename?: 'monthlySalesBudget' }
    & Pick<Types.MonthlySalesBudget, 'id' | 'businessDate' | 'taxExcludedAmount'>
  )> }
);


export const SalesBudgetGetSalesForBudgetDocument = gql`
    query SalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByDays {
      dailySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;

/**
 * __useSalesBudgetGetSalesForBudgetQuery__
 *
 * To run a query within a React component, call `useSalesBudgetGetSalesForBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesBudgetGetSalesForBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesBudgetGetSalesForBudgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesBudgetGetSalesForBudgetQuery(baseOptions: Apollo.QueryHookOptions<SalesBudgetGetSalesForBudgetQuery, SalesBudgetGetSalesForBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesBudgetGetSalesForBudgetQuery, SalesBudgetGetSalesForBudgetQueryVariables>(SalesBudgetGetSalesForBudgetDocument, options);
      }
export function useSalesBudgetGetSalesForBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesBudgetGetSalesForBudgetQuery, SalesBudgetGetSalesForBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesBudgetGetSalesForBudgetQuery, SalesBudgetGetSalesForBudgetQueryVariables>(SalesBudgetGetSalesForBudgetDocument, options);
        }
export type SalesBudgetGetSalesForBudgetQueryHookResult = ReturnType<typeof useSalesBudgetGetSalesForBudgetQuery>;
export type SalesBudgetGetSalesForBudgetLazyQueryHookResult = ReturnType<typeof useSalesBudgetGetSalesForBudgetLazyQuery>;
export type SalesBudgetGetSalesForBudgetQueryResult = Apollo.QueryResult<SalesBudgetGetSalesForBudgetQuery, SalesBudgetGetSalesForBudgetQueryVariables>;
export const SalesBudgetGetDailySalesBudgetDocument = gql`
    query SalesBudgetGetDailySalesBudget($shopId: uuid!, $from: date!, $to: date!) {
  dailySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $from, _lte: $to}}
  ) {
    id
    businessDate
    taxExcludedAmount
  }
}
    `;

/**
 * __useSalesBudgetGetDailySalesBudgetQuery__
 *
 * To run a query within a React component, call `useSalesBudgetGetDailySalesBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesBudgetGetDailySalesBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesBudgetGetDailySalesBudgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSalesBudgetGetDailySalesBudgetQuery(baseOptions: Apollo.QueryHookOptions<SalesBudgetGetDailySalesBudgetQuery, SalesBudgetGetDailySalesBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesBudgetGetDailySalesBudgetQuery, SalesBudgetGetDailySalesBudgetQueryVariables>(SalesBudgetGetDailySalesBudgetDocument, options);
      }
export function useSalesBudgetGetDailySalesBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesBudgetGetDailySalesBudgetQuery, SalesBudgetGetDailySalesBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesBudgetGetDailySalesBudgetQuery, SalesBudgetGetDailySalesBudgetQueryVariables>(SalesBudgetGetDailySalesBudgetDocument, options);
        }
export type SalesBudgetGetDailySalesBudgetQueryHookResult = ReturnType<typeof useSalesBudgetGetDailySalesBudgetQuery>;
export type SalesBudgetGetDailySalesBudgetLazyQueryHookResult = ReturnType<typeof useSalesBudgetGetDailySalesBudgetLazyQuery>;
export type SalesBudgetGetDailySalesBudgetQueryResult = Apollo.QueryResult<SalesBudgetGetDailySalesBudgetQuery, SalesBudgetGetDailySalesBudgetQueryVariables>;
export const SalesBudgetGetMonthlySalesBudgetDocument = gql`
    query SalesBudgetGetMonthlySalesBudget($shopId: uuid!, $from: date!, $to: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $from, _lte: $to}}
  ) {
    id
    businessDate
    taxExcludedAmount
  }
}
    `;

/**
 * __useSalesBudgetGetMonthlySalesBudgetQuery__
 *
 * To run a query within a React component, call `useSalesBudgetGetMonthlySalesBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesBudgetGetMonthlySalesBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesBudgetGetMonthlySalesBudgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSalesBudgetGetMonthlySalesBudgetQuery(baseOptions: Apollo.QueryHookOptions<SalesBudgetGetMonthlySalesBudgetQuery, SalesBudgetGetMonthlySalesBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesBudgetGetMonthlySalesBudgetQuery, SalesBudgetGetMonthlySalesBudgetQueryVariables>(SalesBudgetGetMonthlySalesBudgetDocument, options);
      }
export function useSalesBudgetGetMonthlySalesBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesBudgetGetMonthlySalesBudgetQuery, SalesBudgetGetMonthlySalesBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesBudgetGetMonthlySalesBudgetQuery, SalesBudgetGetMonthlySalesBudgetQueryVariables>(SalesBudgetGetMonthlySalesBudgetDocument, options);
        }
export type SalesBudgetGetMonthlySalesBudgetQueryHookResult = ReturnType<typeof useSalesBudgetGetMonthlySalesBudgetQuery>;
export type SalesBudgetGetMonthlySalesBudgetLazyQueryHookResult = ReturnType<typeof useSalesBudgetGetMonthlySalesBudgetLazyQuery>;
export type SalesBudgetGetMonthlySalesBudgetQueryResult = Apollo.QueryResult<SalesBudgetGetMonthlySalesBudgetQuery, SalesBudgetGetMonthlySalesBudgetQueryVariables>;