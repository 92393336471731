import React, { memo } from "react";
import { Switch } from "antd";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { CloseCashRegister } from "../types";

type Props = {
  loading?: boolean;
  closeCashRegisters: CloseCashRegister[];
  onCheckExcludedFromAggregation({
    closeCashRegisterId,
    excludedFromAggregation,
  }: {
    closeCashRegisterId: string;
    excludedFromAggregation: boolean;
  }): void;
};

export const CloseCashRegisterTable = memo<Props>(
  ({ loading, closeCashRegisters, onCheckExcludedFromAggregation }) => {
    const [pagination, setPagination] = usePagination();

    const columns = [
      {
        title: "精算日時",
        render(_: string, { createdAt }: CloseCashRegister) {
          return dayjs(createdAt).format("YYYY/MM/DD HH:mm");
        },
      },
      { title: "対象日", dataIndex: "targetDate" },
      {
        title: "金額",
        render(_: number, { amount }: CloseCashRegister) {
          return formatPrice(amount);
        },
      },
      {
        title: "集計から除外",
        render(_: string, { id, excludedFromAggregation }: CloseCashRegister) {
          return (
            <Switch
              checked={excludedFromAggregation}
              onChange={(checked) =>
                onCheckExcludedFromAggregation({
                  closeCashRegisterId: id,
                  excludedFromAggregation: checked,
                })
              }
            />
          );
        },
      },
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={closeCashRegisters}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
