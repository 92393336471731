import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Select, Typography } from "antd";
import { Store } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { OnboardingHandoutDescriptions } from "components/OnboardingHandoutDescriptions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { OnboardingHandoutField } from "pages/OnboardingHandout/EditOnboardingHandoutForm/OnboardingHandoutField";
import {
  EditOnboardingHandoutFormItem,
  EditOnboardingHandoutFormValues,
  useEditOnboardingHandoutForm,
} from "pages/OnboardingHandout/EditOnboardingHandoutForm/useEditOnboardingHandoutForm";
import { Shop } from "pages/OnboardingHandout/types";

const Preview = styled.div`
  margin-top: 24px;
  > .ant-typography {
    margin-bottom: 16px;
  }
  .ant-descriptions-item {
    padding: 0;
  }
`;

type Props = {
  shops: Shop[];
  onSubmit: (formValues: EditOnboardingHandoutFormValues) => void;
  loading: boolean;
};

export const EditOnboardingHandoutForm = memo<Props>(({ shops, onSubmit, loading }) => {
  const { form, initialValues, submit } = useEditOnboardingHandoutForm(onSubmit);
  const [onboardingHandout, setOnboardingHandout] = useState(initialValues.onboardingHandout);

  const handleSubmit = useCallback(async () => {
    submit();
  }, [submit]);

  const onValuesChange = useCallback((_: Store, formValues: Store) => {
    const values = formValues as EditOnboardingHandoutFormValues;
    setOnboardingHandout(values.onboardingHandout);
  }, []);

  return (
    <>
      <FormContent>
        <Form
          name="onboardingHandout"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <EditOnboardingHandoutFormItem
            label="店舗"
            name="shopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={shops.length === 0}
              showSearch
              options={shops.map((shop) => ({ label: shop.name, value: shop.shopId }))}
              optionFilterProp="label"
            />
          </EditOnboardingHandoutFormItem>
          <OnboardingHandoutField />
          <Preview>
            <Typography.Paragraph>プレビュー</Typography.Paragraph>
            <OnboardingHandoutDescriptions onboardingHandout={onboardingHandout} />
          </Preview>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          作成
        </Button>
      </FormActions>
    </>
  );
});
