import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Divider } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { grey } from "constants/colors";
import { UpsertTranslationsForMenuInput } from "types/graphql";

import { Menu, Translation } from "../types";

import { DescriptionCnField } from "./DescriptionCnField";
import { DescriptionEnField } from "./DescriptionEnField";
import { DescriptionField } from "./DescriptionField";
import { DescriptionKrField } from "./DescriptionKrField";
import { FeatureLabelCnField } from "./FeatureLabelCnField";
import { FeatureLabelEnField } from "./FeatureLabelEnField";
import { FeatureLabelField } from "./FeatureLabelField";
import { FeatureLabelKrField } from "./FeatureLabelKrField";
import { NameCnField } from "./NameCnField";
import { NameEnField } from "./NameEnField";
import { NameField } from "./NameField";
import { NameKrField } from "./NameKrField";
import { useEditMenuTranslationsForm } from "./useEditMenuTranslationsForm";

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledForm = styled(Form)`
  width: 640px;
  margin: 0 auto;
`;

const StyledFormSection = styled(FormSection)`
  padding: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 32px 0;
`;

type Props = {
  menu: Menu;
  translations: Translation[];
  onClose: () => void;
  onSubmit: (inputs: UpsertTranslationsForMenuInput) => Promise<void>;
  onFormValidationError: () => void;
  disabled: boolean;
};

export const EditMenuTranslationsForm = memo<Props>(
  ({ menu, translations, onSubmit, onClose, onFormValidationError, disabled }) => {
    const { form, initialValues, submit } = useEditMenuTranslationsForm({
      menu,
      onSubmit,
      translations,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [submit, form, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormWrapper>
          <StyledForm
            form={form}
            initialValues={initialValues}
            layout="vertical"
            disabled={disabled}
          >
            <StyledFormSection title="メニュー名">
              <NameField />
              <NameEnField />
              <NameCnField />
              <NameKrField />
            </StyledFormSection>
            <StyledDivider />

            <StyledFormSection title="メニュー説明文">
              <DescriptionField />
              <DescriptionEnField disabled={disabled} />
              <DescriptionCnField disabled={disabled} />
              <DescriptionKrField disabled={disabled} />
            </StyledFormSection>
            <StyledDivider />

            <StyledFormSection title="特集ラベル">
              <FeatureLabelField />
              <FeatureLabelEnField />
              <FeatureLabelCnField />
              <FeatureLabelKrField />
            </StyledFormSection>
            <StyledDivider />
          </StyledForm>
        </FormWrapper>

        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} disabled={disabled}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
