import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditOptionPikaichiOptionFormItem } from "../useEditOptionPikaichiOptionForm";

type Props = {
  loading?: boolean;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const PikaichiOptionChoiceTable = memo<Props>(({ loading }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ option }) => [
              option?.choices.map((choice) => choice.name),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue(["option", "choices", field.name, "name"]);

              return <span>{choiceName}</span>;
            }}
          </EditOptionPikaichiOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "pikaichiMenuCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiMenuCd"]}
            rules={[
              { required: true, message: "メニュー名称を入力してください" },
              { max: 25, message: "25文字以下で入力してください" },
            ]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "pikaichiMenuName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiMenuName"]}
            rules={[
              { required: true, message: "メニュー名称を入力してください" },
              { max: 25, message: "25文字以下で入力してください" },
            ]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "pikaichiBumonCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiBumonCd"]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "pikaichiBumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiBumonName"]}
            rules={[{ max: 25, message: "25文字以下で入力してください" }]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "pikaichiCategoryCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiCategoryCd"]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "pikaichiCategoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionPikaichiOptionFormItem
            noStyle
            name={[field.name, "pikaichiMenuChoices", 0, "pikaichiMenu", "pikaichiCategoryName"]}
            rules={[{ max: 25, message: "25文字以下で入力してください" }]}
          >
            <InputCode />
          </EditOptionPikaichiOptionFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={["option", "choices"]}>
      {(optionChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={optionChoiceFields}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
