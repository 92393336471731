import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuRecommendationMenusGetMenuRecommendation = gql`
    query MenuRecommendationMenusGetMenuRecommendation($menuRecommendationMetaId: uuid!) {
  menuRecommendationMeta_by_pk(id: $menuRecommendationMetaId) {
    id
    name
    category {
      id
      categoryId
      name
      categoryMenus(
        order_by: {priority: asc}
        where: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}
      ) {
        categoryMenuId
        priority
        menu {
          id
          menuId
          name
          imageUrl
          price
          openPrice
          taxMethod
          categoryMenus {
            category {
              categoryId
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const MenuRecommendationMenusDeleteMenuRecommendation = gql`
    mutation MenuRecommendationMenusDeleteMenuRecommendation($categoryMenuId: Int!) {
  delete_categoryMenu(where: {serial: {_eq: $categoryMenuId}}) {
    affected_rows
  }
}
    `;
export const MenuRecommendationMenusInsertCategoryMenus = gql`
    mutation MenuRecommendationMenusInsertCategoryMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_menuId_categoryId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId
    }
  }
}
    `;
export type MenuRecommendationMenusGetMenuRecommendationQueryVariables = Types.Exact<{
  menuRecommendationMetaId: Types.Scalars['uuid'];
}>;


export type MenuRecommendationMenusGetMenuRecommendationQuery = (
  { __typename?: 'query_root' }
  & { menuRecommendationMeta_by_pk?: Types.Maybe<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id' | 'name'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'id' | 'categoryId' | 'name'>
      & { categoryMenus: Array<(
        { __typename?: 'categoryMenu' }
        & Pick<Types.CategoryMenu, 'categoryMenuId' | 'priority'>
        & { menu: (
          { __typename?: 'menu' }
          & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'imageUrl' | 'price' | 'openPrice' | 'taxMethod'>
          & { categoryMenus: Array<(
            { __typename?: 'categoryMenu' }
            & { category: (
              { __typename?: 'category' }
              & Pick<Types.Category, 'categoryId' | 'name'>
            ) }
          )> }
        ) }
      )> }
    ) }
  )> }
);

export type MenuRecommendationMenusDeleteMenuRecommendationMutationVariables = Types.Exact<{
  categoryMenuId: Types.Scalars['Int'];
}>;


export type MenuRecommendationMenusDeleteMenuRecommendationMutation = (
  { __typename?: 'mutation_root' }
  & { delete_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
  )> }
);

export type MenuRecommendationMenusInsertCategoryMenusMutationVariables = Types.Exact<{
  categoryMenus: Array<Types.CategoryMenuInsertInput> | Types.CategoryMenuInsertInput;
}>;


export type MenuRecommendationMenusInsertCategoryMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId'>
    )> }
  )> }
);


export const MenuRecommendationMenusGetMenuRecommendationDocument = gql`
    query MenuRecommendationMenusGetMenuRecommendation($menuRecommendationMetaId: uuid!) {
  menuRecommendationMeta_by_pk(id: $menuRecommendationMetaId) {
    id
    name
    category {
      id
      categoryId
      name
      categoryMenus(
        order_by: {priority: asc}
        where: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}
      ) {
        categoryMenuId
        priority
        menu {
          id
          menuId
          name
          imageUrl
          price
          openPrice
          taxMethod
          categoryMenus {
            category {
              categoryId
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMenuRecommendationMenusGetMenuRecommendationQuery__
 *
 * To run a query within a React component, call `useMenuRecommendationMenusGetMenuRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuRecommendationMenusGetMenuRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuRecommendationMenusGetMenuRecommendationQuery({
 *   variables: {
 *      menuRecommendationMetaId: // value for 'menuRecommendationMetaId'
 *   },
 * });
 */
export function useMenuRecommendationMenusGetMenuRecommendationQuery(baseOptions: Apollo.QueryHookOptions<MenuRecommendationMenusGetMenuRecommendationQuery, MenuRecommendationMenusGetMenuRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuRecommendationMenusGetMenuRecommendationQuery, MenuRecommendationMenusGetMenuRecommendationQueryVariables>(MenuRecommendationMenusGetMenuRecommendationDocument, options);
      }
export function useMenuRecommendationMenusGetMenuRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuRecommendationMenusGetMenuRecommendationQuery, MenuRecommendationMenusGetMenuRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuRecommendationMenusGetMenuRecommendationQuery, MenuRecommendationMenusGetMenuRecommendationQueryVariables>(MenuRecommendationMenusGetMenuRecommendationDocument, options);
        }
export type MenuRecommendationMenusGetMenuRecommendationQueryHookResult = ReturnType<typeof useMenuRecommendationMenusGetMenuRecommendationQuery>;
export type MenuRecommendationMenusGetMenuRecommendationLazyQueryHookResult = ReturnType<typeof useMenuRecommendationMenusGetMenuRecommendationLazyQuery>;
export type MenuRecommendationMenusGetMenuRecommendationQueryResult = Apollo.QueryResult<MenuRecommendationMenusGetMenuRecommendationQuery, MenuRecommendationMenusGetMenuRecommendationQueryVariables>;
export const MenuRecommendationMenusDeleteMenuRecommendationDocument = gql`
    mutation MenuRecommendationMenusDeleteMenuRecommendation($categoryMenuId: Int!) {
  delete_categoryMenu(where: {serial: {_eq: $categoryMenuId}}) {
    affected_rows
  }
}
    `;
export type MenuRecommendationMenusDeleteMenuRecommendationMutationFn = Apollo.MutationFunction<MenuRecommendationMenusDeleteMenuRecommendationMutation, MenuRecommendationMenusDeleteMenuRecommendationMutationVariables>;

/**
 * __useMenuRecommendationMenusDeleteMenuRecommendationMutation__
 *
 * To run a mutation, you first call `useMenuRecommendationMenusDeleteMenuRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuRecommendationMenusDeleteMenuRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuRecommendationMenusDeleteMenuRecommendationMutation, { data, loading, error }] = useMenuRecommendationMenusDeleteMenuRecommendationMutation({
 *   variables: {
 *      categoryMenuId: // value for 'categoryMenuId'
 *   },
 * });
 */
export function useMenuRecommendationMenusDeleteMenuRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<MenuRecommendationMenusDeleteMenuRecommendationMutation, MenuRecommendationMenusDeleteMenuRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuRecommendationMenusDeleteMenuRecommendationMutation, MenuRecommendationMenusDeleteMenuRecommendationMutationVariables>(MenuRecommendationMenusDeleteMenuRecommendationDocument, options);
      }
export type MenuRecommendationMenusDeleteMenuRecommendationMutationHookResult = ReturnType<typeof useMenuRecommendationMenusDeleteMenuRecommendationMutation>;
export type MenuRecommendationMenusDeleteMenuRecommendationMutationResult = Apollo.MutationResult<MenuRecommendationMenusDeleteMenuRecommendationMutation>;
export type MenuRecommendationMenusDeleteMenuRecommendationMutationOptions = Apollo.BaseMutationOptions<MenuRecommendationMenusDeleteMenuRecommendationMutation, MenuRecommendationMenusDeleteMenuRecommendationMutationVariables>;
export const MenuRecommendationMenusInsertCategoryMenusDocument = gql`
    mutation MenuRecommendationMenusInsertCategoryMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_menuId_categoryId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId
    }
  }
}
    `;
export type MenuRecommendationMenusInsertCategoryMenusMutationFn = Apollo.MutationFunction<MenuRecommendationMenusInsertCategoryMenusMutation, MenuRecommendationMenusInsertCategoryMenusMutationVariables>;

/**
 * __useMenuRecommendationMenusInsertCategoryMenusMutation__
 *
 * To run a mutation, you first call `useMenuRecommendationMenusInsertCategoryMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuRecommendationMenusInsertCategoryMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuRecommendationMenusInsertCategoryMenusMutation, { data, loading, error }] = useMenuRecommendationMenusInsertCategoryMenusMutation({
 *   variables: {
 *      categoryMenus: // value for 'categoryMenus'
 *   },
 * });
 */
export function useMenuRecommendationMenusInsertCategoryMenusMutation(baseOptions?: Apollo.MutationHookOptions<MenuRecommendationMenusInsertCategoryMenusMutation, MenuRecommendationMenusInsertCategoryMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuRecommendationMenusInsertCategoryMenusMutation, MenuRecommendationMenusInsertCategoryMenusMutationVariables>(MenuRecommendationMenusInsertCategoryMenusDocument, options);
      }
export type MenuRecommendationMenusInsertCategoryMenusMutationHookResult = ReturnType<typeof useMenuRecommendationMenusInsertCategoryMenusMutation>;
export type MenuRecommendationMenusInsertCategoryMenusMutationResult = Apollo.MutationResult<MenuRecommendationMenusInsertCategoryMenusMutation>;
export type MenuRecommendationMenusInsertCategoryMenusMutationOptions = Apollo.BaseMutationOptions<MenuRecommendationMenusInsertCategoryMenusMutation, MenuRecommendationMenusInsertCategoryMenusMutationVariables>;