import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCouponAnalyticsSummary = gql`
    query GetCouponAnalyticsSummary($input: CouponAnalyticsInput!) {
  couponAnalyticsSummary(input: $input) {
    couponUsedCount
    visitedTableUserCustomersCount
    visitedTableUserCustomersSales
    shopUsages {
      shopId
      shopName
      couponUsedCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
    }
  }
}
    `;
export const GetCouponAnalyticsPerCoupon = gql`
    query GetCouponAnalyticsPerCoupon($input: CouponAnalyticsInput!) {
  couponAnalyticsPerCoupon(input: $input) {
    items {
      couponId
      couponName
      couponImageUrl
      couponUsedCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
      shopUsages {
        shopId
        shopName
        couponUsedCount
        visitedTableUserCustomersCount
        visitedTableUserCustomersSales
      }
    }
  }
}
    `;
export const CouponAnalyticsGetCompanies = gql`
    query CouponAnalyticsGetCompanies($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
}
    `;
export type GetCouponAnalyticsSummaryQueryVariables = Types.Exact<{
  input: Types.CouponAnalyticsInput;
}>;


export type GetCouponAnalyticsSummaryQuery = (
  { __typename?: 'query_root' }
  & { couponAnalyticsSummary: (
    { __typename?: 'CouponAnalyticsSummary' }
    & Pick<Types.CouponAnalyticsSummary, 'couponUsedCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
    & { shopUsages: Array<(
      { __typename?: 'ShopCouponUsage' }
      & Pick<Types.ShopCouponUsage, 'shopId' | 'shopName' | 'couponUsedCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
    )> }
  ) }
);

export type GetCouponAnalyticsPerCouponQueryVariables = Types.Exact<{
  input: Types.CouponAnalyticsInput;
}>;


export type GetCouponAnalyticsPerCouponQuery = (
  { __typename?: 'query_root' }
  & { couponAnalyticsPerCoupon: (
    { __typename?: 'CouponAnalyticsPerCoupon' }
    & { items: Array<(
      { __typename?: 'CouponAnalyticsPerCouponItem' }
      & Pick<Types.CouponAnalyticsPerCouponItem, 'couponId' | 'couponName' | 'couponImageUrl' | 'couponUsedCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
      & { shopUsages: Array<(
        { __typename?: 'ShopCouponUsage' }
        & Pick<Types.ShopCouponUsage, 'shopId' | 'shopName' | 'couponUsedCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
      )> }
    )> }
  ) }
);

export type CouponAnalyticsGetCompaniesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type CouponAnalyticsGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
    )> }
  )> }
);


export const GetCouponAnalyticsSummaryDocument = gql`
    query GetCouponAnalyticsSummary($input: CouponAnalyticsInput!) {
  couponAnalyticsSummary(input: $input) {
    couponUsedCount
    visitedTableUserCustomersCount
    visitedTableUserCustomersSales
    shopUsages {
      shopId
      shopName
      couponUsedCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
    }
  }
}
    `;

/**
 * __useGetCouponAnalyticsSummaryQuery__
 *
 * To run a query within a React component, call `useGetCouponAnalyticsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponAnalyticsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponAnalyticsSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCouponAnalyticsSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetCouponAnalyticsSummaryQuery, GetCouponAnalyticsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponAnalyticsSummaryQuery, GetCouponAnalyticsSummaryQueryVariables>(GetCouponAnalyticsSummaryDocument, options);
      }
export function useGetCouponAnalyticsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponAnalyticsSummaryQuery, GetCouponAnalyticsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponAnalyticsSummaryQuery, GetCouponAnalyticsSummaryQueryVariables>(GetCouponAnalyticsSummaryDocument, options);
        }
export type GetCouponAnalyticsSummaryQueryHookResult = ReturnType<typeof useGetCouponAnalyticsSummaryQuery>;
export type GetCouponAnalyticsSummaryLazyQueryHookResult = ReturnType<typeof useGetCouponAnalyticsSummaryLazyQuery>;
export type GetCouponAnalyticsSummaryQueryResult = Apollo.QueryResult<GetCouponAnalyticsSummaryQuery, GetCouponAnalyticsSummaryQueryVariables>;
export const GetCouponAnalyticsPerCouponDocument = gql`
    query GetCouponAnalyticsPerCoupon($input: CouponAnalyticsInput!) {
  couponAnalyticsPerCoupon(input: $input) {
    items {
      couponId
      couponName
      couponImageUrl
      couponUsedCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
      shopUsages {
        shopId
        shopName
        couponUsedCount
        visitedTableUserCustomersCount
        visitedTableUserCustomersSales
      }
    }
  }
}
    `;

/**
 * __useGetCouponAnalyticsPerCouponQuery__
 *
 * To run a query within a React component, call `useGetCouponAnalyticsPerCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponAnalyticsPerCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponAnalyticsPerCouponQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCouponAnalyticsPerCouponQuery(baseOptions: Apollo.QueryHookOptions<GetCouponAnalyticsPerCouponQuery, GetCouponAnalyticsPerCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponAnalyticsPerCouponQuery, GetCouponAnalyticsPerCouponQueryVariables>(GetCouponAnalyticsPerCouponDocument, options);
      }
export function useGetCouponAnalyticsPerCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponAnalyticsPerCouponQuery, GetCouponAnalyticsPerCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponAnalyticsPerCouponQuery, GetCouponAnalyticsPerCouponQueryVariables>(GetCouponAnalyticsPerCouponDocument, options);
        }
export type GetCouponAnalyticsPerCouponQueryHookResult = ReturnType<typeof useGetCouponAnalyticsPerCouponQuery>;
export type GetCouponAnalyticsPerCouponLazyQueryHookResult = ReturnType<typeof useGetCouponAnalyticsPerCouponLazyQuery>;
export type GetCouponAnalyticsPerCouponQueryResult = Apollo.QueryResult<GetCouponAnalyticsPerCouponQuery, GetCouponAnalyticsPerCouponQueryVariables>;
export const CouponAnalyticsGetCompaniesDocument = gql`
    query CouponAnalyticsGetCompanies($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
}
    `;

/**
 * __useCouponAnalyticsGetCompaniesQuery__
 *
 * To run a query within a React component, call `useCouponAnalyticsGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponAnalyticsGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponAnalyticsGetCompaniesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useCouponAnalyticsGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<CouponAnalyticsGetCompaniesQuery, CouponAnalyticsGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponAnalyticsGetCompaniesQuery, CouponAnalyticsGetCompaniesQueryVariables>(CouponAnalyticsGetCompaniesDocument, options);
      }
export function useCouponAnalyticsGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponAnalyticsGetCompaniesQuery, CouponAnalyticsGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponAnalyticsGetCompaniesQuery, CouponAnalyticsGetCompaniesQueryVariables>(CouponAnalyticsGetCompaniesDocument, options);
        }
export type CouponAnalyticsGetCompaniesQueryHookResult = ReturnType<typeof useCouponAnalyticsGetCompaniesQuery>;
export type CouponAnalyticsGetCompaniesLazyQueryHookResult = ReturnType<typeof useCouponAnalyticsGetCompaniesLazyQuery>;
export type CouponAnalyticsGetCompaniesQueryResult = Apollo.QueryResult<CouponAnalyticsGetCompaniesQuery, CouponAnalyticsGetCompaniesQueryVariables>;