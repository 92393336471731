import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PlanGroup } from "pages/EditPlanGroup/types";
import { PlanGroupSetInput } from "types/graphql";

export type EditPlanGroupFormValues = Pick<PlanGroup, "name">;

const getInitialValues = (planGroup: PlanGroup) => {
  const { name } = planGroup;
  return { name };
};

export const EditPlanGroupFormItem = createFormItem<EditPlanGroupFormValues>();

export const useEditPlanGroupForm = (
  planGroup: PlanGroup,
  onSubmit: (planGroup: PlanGroupSetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(planGroup);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditPlanGroupFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
