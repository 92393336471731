import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

import { EditOptionTranslationsForm } from "./EditOptionTranslationsForm";
import {
  useEditOptionTranslationsGetOptionQuery,
  useEditOptionTranslationsUpdateOptionTranslationsMutation,
} from "./queries";

export const EditOptionTranslations = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getOptionData,
    loading: loadingOption,
    error: errorGetOptionData,
    refetch: refetchGetOptionData,
  } = useEditOptionTranslationsGetOptionQuery(
    !Number.isNaN(optionId) ? { variables: { optionId } } : { skip: true },
  );
  const option = useMemo(() => getOptionData?.option?.[0], [getOptionData?.option]);
  const optionTranslations = useMemo(() => option?.translations ?? [], [option?.translations]);
  const choiceTranslations = useMemo(
    () => option?.choices?.flatMap(({ translations }) => translations) ?? [],
    [option?.choices],
  );

  const [
    updateOptionTranslationsMutation,
    {
      loading: loadingUpdateOptionTranslationsMutation,
      error: errorUpdateOptionTranslationsMutation,
    },
  ] = useEditOptionTranslationsUpdateOptionTranslationsMutation();

  const [
    { loading: loadingUpdateOptionTranslations, error: errorUpdateOptionTranslations },
    updateOptionTranslations,
  ] = useAsyncFn(
    async ({ optionId, companyId, nameSources, choiceSources }) => {
      await updateOptionTranslationsMutation({
        variables: {
          input: {
            companyId,
            optionId,
            nameSources,
            choiceSources,
          },
        },
      });

      message.success("編集を保存しました");

      goBack();

      refetchGetOptionData();
    },
    [updateOptionTranslationsMutation, goBack, refetchGetOptionData],
  );

  const showErrorMessage = useCallback(() => {
    message.error("編集に失敗しました");
  }, []);

  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  const loading =
    loadingOption || loadingUpdateOptionTranslations || loadingUpdateOptionTranslationsMutation;
  const error =
    errorGetOptionData || errorUpdateOptionTranslations || errorUpdateOptionTranslationsMutation;

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "オプション" }],
      }}
    >
      <OptionHeader
        option={option ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {option && (
        <EditOptionTranslationsForm
          option={option}
          optionTranslations={optionTranslations}
          choiceTranslations={choiceTranslations}
          onSubmit={updateOptionTranslations}
          onClose={goBack}
          onFormValidationError={showErrorMessage}
          disabled={!isFeatureEnabled("editOption")}
        />
      )}
    </DashboardLayout>
  );
};
