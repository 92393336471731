import { useCallback, useMemo } from "react";
import { logEvent as firebaseLogEvent } from "firebase/analytics";

import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";

import pkg from "../../../package.json";
import { useCurrentUser } from "../../hooks/useUser";
import { analytics } from "../../libs/firebase";

const product = pkg.name;
const version = pkg.version;

export const useAnalytics = () => {
  const [company] = useCompany();
  const companyId = company?.companyId;

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const user = useCurrentUser();

  const role = user?.role;

  const extra = useMemo(
    () => ({
      user_id: user?.uid ?? "",
      role,
      shop_id: shopId,
      company_id: companyId,
      product,
      version,
    }),
    [companyId, role, shopId, user?.uid],
  );

  const logEvent = useCallback(
    (args: { type: "page_view" } | { type: "check_in" } | { type: "customer_dashboard_view" }) => {
      if (process.env.NODE_ENV === "development") return;

      switch (args.type) {
        case "page_view":
          firebaseLogEvent(analytics, "page_view", { ...extra });
          return;
        case "check_in":
          firebaseLogEvent(analytics, "check_in", { ...extra });
          return;
        case "customer_dashboard_view":
          firebaseLogEvent(analytics, "select_content", {
            content_type: "pageview",
            content_id: args.type,
            ...extra,
          });
          return;
      }
    },
    [extra],
  );

  return { logEvent };
};
