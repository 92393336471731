import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PikaichiPlanHeader } from "components/PageHeader/PikaichiPlanHeader";
import { useCompany } from "hooks/useCompany";
import { PikaichiMenuInsertInput } from "types/graphql";

import { EditPikaichiPlanForm } from "./EditPikaichiPlanForm";
import {
  useEditPikaichiPlanGetPlanQuery,
  useEditPikaichiPlanUpdatePikaichiMenuMutation,
} from "./queries";

export const EditPikaichiPlan = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    loading: loadingPlan,
    refetch: refetchPlan,
    error,
  } = useEditPikaichiPlanGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];
  const companyId = plan?.companyId;

  const pikaichiMenu = plan?.pikaichiMenuPlans?.[0]?.pikaichiMenu;

  setCompany(companyId);

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    useEditPikaichiPlanUpdatePikaichiMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiMenuInsertInput, "companyId">) => {
      if (!company || !plan) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();
      const pikaichiMenuDto = {
        pikaichiMenuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updatePikaichiMenuMutation({
          variables: {
            planId: plan.id,
            _planId: plan.planId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchPlan();
    },
    [company, plan, pikaichiMenu?.pikaichiMenuId, refetchPlan, updatePikaichiMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビプラン" }],
      }}
    >
      <PikaichiPlanHeader plan={plan ?? null} onBack={goBack} />
      {loadingPlan && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {plan && (
        <EditPikaichiPlanForm
          plan={plan}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </DashboardLayout>
  );
};
