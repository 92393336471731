export const defaultTecAggregationMediaMaps = [
  { itemNumber: 100, mediaName: "金券支払１", aggregationNumber: 1 },
  { itemNumber: 101, mediaName: "金券支払２", aggregationNumber: 2 },
  { itemNumber: 102, mediaName: "金券支払３", aggregationNumber: 3 },
  { itemNumber: 103, mediaName: "金券支払４", aggregationNumber: 4 },
  { itemNumber: 104, mediaName: "金券支払５", aggregationNumber: 5 },
  { itemNumber: 105, mediaName: "金券支払６", aggregationNumber: 6 },
  { itemNumber: 106, mediaName: "金券支払７", aggregationNumber: 7 },
  { itemNumber: 107, mediaName: "金券支払８", aggregationNumber: 8 },
  { itemNumber: 108, mediaName: "金券支払９", aggregationNumber: 9 },
  { itemNumber: 109, mediaName: "金券支払１０", aggregationNumber: 10 },
  { itemNumber: 112, mediaName: "現金", aggregationNumber: 11 },
  { itemNumber: 113, mediaName: "クレジット計", aggregationNumber: 12 },
  { itemNumber: 114, mediaName: "掛計", aggregationNumber: 13 },
  { itemNumber: 115, mediaName: "テスト用", aggregationNumber: 14 },
  { itemNumber: 116, mediaName: "交通系IC", aggregationNumber: 15 },
  { itemNumber: 117, mediaName: "掛売個別", aggregationNumber: 16 },
  { itemNumber: 118, mediaName: "掛売１", aggregationNumber: 17 },
  { itemNumber: 119, mediaName: "掛売２", aggregationNumber: 18 },
  { itemNumber: 120, mediaName: "掛売３", aggregationNumber: 19 },
  { itemNumber: 121, mediaName: "掛売４", aggregationNumber: 20 },
  { itemNumber: 122, mediaName: "掛売５", aggregationNumber: 21 },
  { itemNumber: 123, mediaName: "掛売６", aggregationNumber: 22 },
  { itemNumber: 124, mediaName: "掛売７", aggregationNumber: 23 },
  { itemNumber: 125, mediaName: "掛売８", aggregationNumber: 24 },
  { itemNumber: 126, mediaName: "掛売９", aggregationNumber: 25 },
  { itemNumber: 127, mediaName: "掛売１０", aggregationNumber: 26 },
  { itemNumber: 128, mediaName: "掛売１１", aggregationNumber: 27 },
  { itemNumber: 129, mediaName: "掛売１２", aggregationNumber: 28 },
  { itemNumber: 130, mediaName: "掛売１３", aggregationNumber: 29 },
  { itemNumber: 131, mediaName: "掛売１４", aggregationNumber: 30 },
];
