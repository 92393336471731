import React, { memo } from "react";
import styled from "styled-components";
import { List } from "antd";

import { AnswerListItem } from "components/QuestionnaireAnalyticsCommentModal/AnswerList/AnswerListItem";
import { oldColors } from "constants/colors";

const Header = styled.div`
  background-color: ${oldColors.Neutral100};
  padding: 0 16px;
  height: 28px;
  display: flex;
  align-items: center;
  border-top: 1px solid ${oldColors.Neutral200};
  border-bottom: 1px solid ${oldColors.Neutral200};
`;

type Props = {
  title: string;
  columns: {
    label: string;
    value?: string | number;
  }[];
};

export const AnswerListSection = memo<Props>(({ title, columns }) => (
  <>
    <Header>{title}</Header>
    <List
      dataSource={columns}
      renderItem={({ label, value }) => <AnswerListItem label={label} value={value} />}
    />
  </>
));
