import { useCallback } from "react";
import { useForm } from "antd/lib/form/Form";

import { createFormItem } from "components/antd/Form";

import { AccountingSlipImage } from "../types";

export type EditAccountingSlipImageFormValues = Pick<AccountingSlipImage, "description"> & {
  imageUrl: string | null;
};

const getInitialValues = (image: AccountingSlipImage) => {
  const { description, imageUrl } = image;
  return { description: description ?? null, imageUrl };
};

export const EditAccountingSlipImageFormItem = createFormItem<EditAccountingSlipImageFormValues>();

export const useEditAccountingSlipImageForm = ({
  accountingSlipImage,
  onSubmit,
}: {
  accountingSlipImage: AccountingSlipImage;
  onSubmit: ({
    imageUrl,
    description,
  }: {
    imageUrl: string | null;
    description: string | null;
  }) => Promise<void>;
}) => {
  const [form] = useForm();
  const initialValues = getInitialValues(accountingSlipImage);

  const submit = useCallback(
    async ({ imageUrl }: { imageUrl: string | null }) => {
      const formValues = form.getFieldsValue() as { description: string | null };
      const { description } = formValues;

      onSubmit({ imageUrl, description });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
