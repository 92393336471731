import React, { memo } from "react";
import styled, { css } from "styled-components";

import { Loading } from "components/Loading";
import { grey } from "constants/colors";

const COUNT_TEXT_HEIGHT = 80;

const textStyle = css`
  color: ${grey[0]};
  font-weight: bold;
  margin-bottom: 0px;
`;

const Wrapper = styled.div`
  height: 120px;
  padding-top: 20px;
  margin-bottom: 16px;
  position: relative;
  background: linear-gradient(
    91deg,
    #5f9ff0 1%,
    #7281c5 24%,
    #8d72ab 47%,
    #ba8eba 67%,
    #ebb4b0 97%
  );
  box-shadow: 0px 21px 32px rgba(211, 228, 244, 0.45);
  border-radius: 10px;
`;

const UserImg = styled.img`
  position: absolute;
`;

const UserImg1 = styled(UserImg)`
  left: 4%;
  top: 9%;
`;

const UserImg2 = styled(UserImg)`
  left: 12%;
  top: 18%;
`;

const UserImg3 = styled(UserImg)`
  right: 12%;
  top: 18%;
`;

const UserImg4 = styled(UserImg)`
  right: 4%;
  top: 9%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  ${textStyle}
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

const CountTextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  height: ${COUNT_TEXT_HEIGHT}px;
`;

const CountText = styled.p`
  ${textStyle}
  font-size: 64px;
  line-height: ${COUNT_TEXT_HEIGHT}px;
`;

const IdText = styled(Text)`
  margin-left: 5px;
`;

type Props = {
  title: string;
  cumulativeCount: number | undefined;
  unitName: string;
  isLoading: boolean;
};

export const CumulativeCountDisplay = memo<Props>(
  ({ title, cumulativeCount, unitName, isLoading }) => {
    const formatter = new Intl.NumberFormat("ja");

    return (
      <Wrapper>
        <UserImg1 src="/user_1.png" alt="user_1" width="74px" height="74px" />
        <UserImg2 src="/user_2.png" alt="user_2" width="88px" height="88px" />
        <UserImg3 src="/user_3.png" alt="user_3" width="88px" height="88px" />
        <UserImg4 src="/user_4.png" alt="user_4" width="77px" height="77px" />

        <Content>
          <Text>{title}</Text>

          <CountTextWrapper>
            {isLoading ? (
              <Loading height={COUNT_TEXT_HEIGHT} />
            ) : (
              <>
                <CountText>{cumulativeCount && formatter.format(cumulativeCount)}</CountText>
                <IdText>{unitName}</IdText>
              </>
            )}
          </CountTextWrapper>
        </Content>
      </Wrapper>
    );
  },
);
