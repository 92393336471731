import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddRoleFormItem } from "../useAddRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShouldPrintFreeOrderOnDishUpSlipField = memo<Props>(({ ...props }) => (
  <AddRoleFormItem
    label="フリー注文をデシャップ伝票に印刷する"
    name="shouldPrintFreeOrderOnDishUpSlip"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </AddRoleFormItem>
));
