import React, { useMemo } from "react";
import styled from "styled-components";
import { Modal, Switch } from "antd";
import { ColumnsType } from "antd/es/table";
import Title from "antd/lib/typography/Title";
import { MenuOutlined } from "@ant-design/icons";

import { SortableTable } from "components/SortableTable";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { CompanySalesAnalyticsColumnWithSelectState } from "../types";

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.Divider.Default};
`;

const StyledModal = styled(Modal)`
  width: 800px;
`;

const ColumnTitle = styled.span`
  font-weight: bold;
  width: 150px;
`;

const ColumnDescription = styled.span`
  width: 320px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

type Props = {
  isOpen: boolean;
  columns: CompanySalesAnalyticsColumnWithSelectState[];
  onToggleColumn: (columnId: string) => void;
  onSave: () => void;
  onCancel: () => void;
  onMove: ({ dragIndex, hoverIndex }: { dragIndex: number; hoverIndex: number }) => void;
};

export const ColumnSelectModal = React.memo(
  ({ isOpen, columns, onToggleColumn, onCancel, onSave, onMove }: Props) => {
    const filteredColumns = useMemo(
      () => columns.filter(({ hideInColumnSelect }) => !hideInColumnSelect),
      [columns],
    );

    const tableColumns: ColumnsType<CompanySalesAnalyticsColumnWithSelectState> = [
      {
        title: "icon",
        width: 10,
        align: "center",
        render: () => <MenuOutlined />,
      },
      {
        title: "カラム",
        width: 100,
        align: "left",
        render: (_, { label }) => <ColumnTitle>{label}</ColumnTitle>,
      },
      {
        title: "説明",
        width: 200,
        align: "left",
        render: (_, { description }) => <ColumnDescription>{description}</ColumnDescription>,
      },
      {
        title: "表示する",
        width: 50,
        align: "center",
        render: (_, { columnId, isEnabled }) => (
          <Switch checked={isEnabled} onChange={() => onToggleColumn(columnId)} />
        ),
      },
    ];

    return (
      <StyledModal
        open={isOpen}
        cancelText="閉じる"
        okText="保存"
        width={1000}
        onCancel={onCancel}
        onOk={onSave}
      >
        <Title level={4}>表の項目を編集</Title>
        <span>表で表示する項目を設定できます。また、ドラッグ&ドロップで表示順を変更できます。</span>
        <Spacer size={10} />

        <Divider />

        <SortableTable
          type="companySalesAnalyticsColumnSelectModal"
          rowKey="columnId"
          dataSource={filteredColumns}
          pagination={false}
          onMove={(dragIndex, hoverIndex) => onMove({ dragIndex, hoverIndex })}
          columns={tableColumns}
          showHeader={false}
        />

        <Divider />
      </StyledModal>
    );
  },
);
