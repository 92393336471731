import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { LineReportingBotMenu } from "components/Menu/LineReportingBotMenu";
import { FormContent } from "components/Template/FormTemplate";
import { useCorporation } from "hooks/useCorporation";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { LineReportingBotConfigTable } from "./LineReportingBotConfigTable";
import { useLineReportingBotGetLineReportingBotConfigsQuery } from "./queries";

export const LineReportingBotConfigs = () => {
  const { state } = useParams<{ state: "active" | "suspended" }>();
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const {
    data: lineReportingBotConfigData,
    loading,
    error,
  } = useLineReportingBotGetLineReportingBotConfigsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const isDisplayingSuspended = state === "suspended";

  const filteredLineReportingBotConfigs = useMemo(
    () =>
      lineReportingBotConfigData?.lineReportingBotConfig?.filter(
        (config) => config.isSuspended === isDisplayingSuspended,
      ) ?? [],
    [lineReportingBotConfigData, isDisplayingSuspended],
  );

  return (
    <DashboardLayout title="ダイニー AI くん設定">
      <PageHeader
        title="ダイニー AI くん設定"
        extra={[
          isFeatureEnabled("addLineReportingBotConfig") && (
            <Link key="add" to="/lineReportingBotConfig/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <LineReportingBotMenu />
      <FormContent>
        <LineReportingBotConfigTable
          lineReportingBotConfigs={filteredLineReportingBotConfigs}
          loading={loading}
          isEditFeatureEnabled={isFeatureEnabled("editLineReportingBotConfig")}
        />
      </FormContent>
    </DashboardLayout>
  );
};
