import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditWinboardConfigGetWinboardConfig = gql`
    query EditWinboardConfigGetWinboardConfig($shopId: uuid!) {
  winboardConfig(where: {shopId: {_eq: $shopId}}) {
    winboardConfigId
    enabled
    ftpHost
    ftpPassword
    ftpPort
    ftpUser
    putPath
    shopId
    winboardCompanyCd
    winboardConfigId
    winboardPosNumber
    winboardShopCd
  }
}
    `;
export const EditWinboardConfigUpsertWinboardConfig = gql`
    mutation EditWinboardConfigUpsertWinboardConfig($winboardConfig: winboardConfig_insert_input!) {
  insert_winboardConfig_one(
    object: $winboardConfig
    on_conflict: {constraint: winboardConfig_shopId_key, update_columns: [enabled, ftpHost, ftpPassword, ftpPort, ftpUser, putPath, shopId, winboardCompanyCd, winboardConfigId, winboardPosNumber, winboardShopCd]}
  ) {
    winboardConfigId
  }
}
    `;
export type EditWinboardConfigGetWinboardConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditWinboardConfigGetWinboardConfigQuery = (
  { __typename?: 'query_root' }
  & { winboardConfig: Array<(
    { __typename?: 'winboardConfig' }
    & Pick<Types.WinboardConfig, 'winboardConfigId' | 'enabled' | 'ftpHost' | 'ftpPassword' | 'ftpPort' | 'ftpUser' | 'putPath' | 'shopId' | 'winboardCompanyCd' | 'winboardPosNumber' | 'winboardShopCd'>
  )> }
);

export type EditWinboardConfigUpsertWinboardConfigMutationVariables = Types.Exact<{
  winboardConfig: Types.WinboardConfigInsertInput;
}>;


export type EditWinboardConfigUpsertWinboardConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardConfig_one?: Types.Maybe<(
    { __typename?: 'winboardConfig' }
    & Pick<Types.WinboardConfig, 'winboardConfigId'>
  )> }
);


export const EditWinboardConfigGetWinboardConfigDocument = gql`
    query EditWinboardConfigGetWinboardConfig($shopId: uuid!) {
  winboardConfig(where: {shopId: {_eq: $shopId}}) {
    winboardConfigId
    enabled
    ftpHost
    ftpPassword
    ftpPort
    ftpUser
    putPath
    shopId
    winboardCompanyCd
    winboardConfigId
    winboardPosNumber
    winboardShopCd
  }
}
    `;

/**
 * __useEditWinboardConfigGetWinboardConfigQuery__
 *
 * To run a query within a React component, call `useEditWinboardConfigGetWinboardConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardConfigGetWinboardConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWinboardConfigGetWinboardConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditWinboardConfigGetWinboardConfigQuery(baseOptions: Apollo.QueryHookOptions<EditWinboardConfigGetWinboardConfigQuery, EditWinboardConfigGetWinboardConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditWinboardConfigGetWinboardConfigQuery, EditWinboardConfigGetWinboardConfigQueryVariables>(EditWinboardConfigGetWinboardConfigDocument, options);
      }
export function useEditWinboardConfigGetWinboardConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditWinboardConfigGetWinboardConfigQuery, EditWinboardConfigGetWinboardConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditWinboardConfigGetWinboardConfigQuery, EditWinboardConfigGetWinboardConfigQueryVariables>(EditWinboardConfigGetWinboardConfigDocument, options);
        }
export type EditWinboardConfigGetWinboardConfigQueryHookResult = ReturnType<typeof useEditWinboardConfigGetWinboardConfigQuery>;
export type EditWinboardConfigGetWinboardConfigLazyQueryHookResult = ReturnType<typeof useEditWinboardConfigGetWinboardConfigLazyQuery>;
export type EditWinboardConfigGetWinboardConfigQueryResult = Apollo.QueryResult<EditWinboardConfigGetWinboardConfigQuery, EditWinboardConfigGetWinboardConfigQueryVariables>;
export const EditWinboardConfigUpsertWinboardConfigDocument = gql`
    mutation EditWinboardConfigUpsertWinboardConfig($winboardConfig: winboardConfig_insert_input!) {
  insert_winboardConfig_one(
    object: $winboardConfig
    on_conflict: {constraint: winboardConfig_shopId_key, update_columns: [enabled, ftpHost, ftpPassword, ftpPort, ftpUser, putPath, shopId, winboardCompanyCd, winboardConfigId, winboardPosNumber, winboardShopCd]}
  ) {
    winboardConfigId
  }
}
    `;
export type EditWinboardConfigUpsertWinboardConfigMutationFn = Apollo.MutationFunction<EditWinboardConfigUpsertWinboardConfigMutation, EditWinboardConfigUpsertWinboardConfigMutationVariables>;

/**
 * __useEditWinboardConfigUpsertWinboardConfigMutation__
 *
 * To run a mutation, you first call `useEditWinboardConfigUpsertWinboardConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardConfigUpsertWinboardConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWinboardConfigUpsertWinboardConfigMutation, { data, loading, error }] = useEditWinboardConfigUpsertWinboardConfigMutation({
 *   variables: {
 *      winboardConfig: // value for 'winboardConfig'
 *   },
 * });
 */
export function useEditWinboardConfigUpsertWinboardConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditWinboardConfigUpsertWinboardConfigMutation, EditWinboardConfigUpsertWinboardConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWinboardConfigUpsertWinboardConfigMutation, EditWinboardConfigUpsertWinboardConfigMutationVariables>(EditWinboardConfigUpsertWinboardConfigDocument, options);
      }
export type EditWinboardConfigUpsertWinboardConfigMutationHookResult = ReturnType<typeof useEditWinboardConfigUpsertWinboardConfigMutation>;
export type EditWinboardConfigUpsertWinboardConfigMutationResult = Apollo.MutationResult<EditWinboardConfigUpsertWinboardConfigMutation>;
export type EditWinboardConfigUpsertWinboardConfigMutationOptions = Apollo.BaseMutationOptions<EditWinboardConfigUpsertWinboardConfigMutation, EditWinboardConfigUpsertWinboardConfigMutationVariables>;