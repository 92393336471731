import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Col, DatePicker, Radio, RadioChangeEvent, Row } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { Select } from "components/Input/Select";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";
import {
  QuestionnaireAnswererSegmentType,
  QuestionnaireScoreChangesDimensionType,
} from "types/graphql";

import { Company, ShopMetricsShopSelector } from "./ShopMetricsShopSelector";

export type FilterConditions = {
  answerSegments?: QuestionnaireAnswererSegmentType[];
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  dimension?: QuestionnaireScoreChangesDimensionType;
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null] | undefined;
} & Omit<FilterConditions, "range">;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const RangePickerPanelContainer = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    .ant-picker-panels {
      flex-direction: column;
    }
  }
`;

const Container = styled(Row).attrs({ gutter: [16, 16] })``;

const RangePickerCol = styled(Col)`
  @media ${viewport.desktop} {
    min-width: 260px;
  }
`;

const questionnaireAnswererSegmentOptions = [
  { label: "新規", value: QuestionnaireAnswererSegmentType.NewCustomer },
  { label: "リピーター", value: QuestionnaireAnswererSegmentType.RepeatCustomer },
];

type Props = {
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  companies: Company[];
  loading: boolean;
};

export const ShopMetricsFilter = memo<Props>(
  ({ filterConditions, updateFilterCondition, companies, loading }) => {
    const isMonthlyFilter = useMemo(
      () => filterConditions.dimension === QuestionnaireScoreChangesDimensionType.Month,
      [filterConditions],
    );
    const onChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const start = isMonthlyFilter
          ? values?.[0]?.startOf("month") ?? null
          : values?.[0]?.startOf("day") ?? null;
        const end = isMonthlyFilter
          ? values?.[1]?.endOf("month") ?? null
          : values?.[1]?.endOf("day") ?? null;

        updateFilterCondition({ range: [start, end] });
      },
      [isMonthlyFilter, updateFilterCondition],
    );

    const onChangeAnswererSegment = useCallback(
      (answerSegments: QuestionnaireAnswererSegmentType[]) =>
        updateFilterCondition({ answerSegments }),
      [updateFilterCondition],
    );

    const onChangeDimension = useCallback(
      (e: RadioChangeEvent) =>
        updateFilterCondition({
          dimension: e.target.value as QuestionnaireScoreChangesDimensionType,
        }),
      [updateFilterCondition],
    );

    const { rangePresets } = useRangePresets();

    return (
      <Wrapper>
        <ShopMetricsShopSelector companies={companies} loading={loading} />
        <Spacer size={16} />
        <Container>
          <RangePickerCol span={24} xl={6}>
            {isMonthlyFilter ? (
              <DatePicker.RangePicker
                value={filterConditions.range}
                onCalendarChange={onChangeRange}
                clearIcon={false}
                picker="month"
                style={{ width: "100%" }}
                panelRender={(panelNode) => (
                  <RangePickerPanelContainer>{panelNode}</RangePickerPanelContainer>
                )}
              />
            ) : (
              <DatePicker.RangePicker
                value={filterConditions.range}
                ranges={rangePresets}
                onCalendarChange={onChangeRange}
                clearIcon={false}
                style={{ width: "100%" }}
                panelRender={(panelNode) => (
                  <RangePickerPanelContainer>{panelNode}</RangePickerPanelContainer>
                )}
              />
            )}
          </RangePickerCol>
          <Col span={24} xl={6}>
            <Select<QuestionnaireAnswererSegmentType[]>
              allowClear
              placeholder="来店経験"
              value={filterConditions.answerSegments}
              onChange={onChangeAnswererSegment}
              mode="multiple"
              options={questionnaireAnswererSegmentOptions}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Col>
          {filterConditions.dimension && (
            <>
              <Col flex={1} />
              <Col>
                <Radio.Group value={filterConditions.dimension} onChange={onChangeDimension}>
                  <Radio.Button value={QuestionnaireScoreChangesDimensionType.Month}>
                    月別
                  </Radio.Button>
                  <Radio.Button value={QuestionnaireScoreChangesDimensionType.Day}>
                    日別
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </>
          )}
        </Container>
      </Wrapper>
    );
  },
);
