import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableAutoFractionalDiscountField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label="自動端数値引"
    name="enableAutoFractionalDiscount"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
