import React, { memo } from "react";
import { Input } from "antd";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditPlanPikaichiPlanFormItem } from "../../useEditPlanPikaichiPlanForm";

type Props = {
  optionField: FormListFieldData;
  loading?: boolean;
};

type FormListFieldDataSource = {
  planOptionField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
  planChoiceField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
};

export const PikaichiPlanChoiceTable = memo<Props>(({ optionField, loading }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ plan }) => [
              plan?.planOptions.map((option) => {
                option.planChoices.map((choice) => choice.name);
              }),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue([
                "plan",
                "planOptions",
                field.planOptionField.name,
                "planChoices",
                field.planChoiceField.name,
                "name",
              ]);

              return <span>{choiceName}</span>;
            }}
          </EditPlanPikaichiPlanFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "pikaichiMenuCd",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiMenuCd",
            ]}
            rules={[
              { required: true, message: "メニュー名称を入力してください" },
              { max: 25, message: "25文字以下で入力してください" },
            ]}
          >
            <Input />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "pikaichiMenuName",
      align: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiMenuName",
            ]}
            rules={[
              { required: true, message: "メニュー名称を入力してください" },
              { max: 25, message: "25文字以下で入力してください" },
            ]}
          >
            <Input />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "pikaichiBumonCd",
      align: "left",
      width: 100,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiBumonCd",
            ]}
          >
            <InputCode />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "pikaichiBumonName",
      align: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiBumonName",
            ]}
            rules={[{ max: 25, message: "25文字以下で入力してください" }]}
          >
            <Input />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "pikaichiCategoryCd",
      align: "left",
      width: 100,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiCategoryCd",
            ]}
          >
            <InputCode />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "pikaichiCategoryName",
      align: "left",
      width: 150,
      render(_: string, field: FormListFieldDataSource) {
        return (
          <EditPlanPikaichiPlanFormItem
            noStyle
            name={[
              field.planChoiceField.name,
              "pikaichiMenuPlanChoices",
              0,
              "pikaichiMenu",
              "pikaichiCategoryName",
            ]}
            rules={[{ max: 25, message: "25文字以下で入力してください" }]}
          >
            <Input />
          </EditPlanPikaichiPlanFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={[optionField.name, "planChoices"]}>
      {(planChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={planChoiceFields.map((field) => ({
            planOptionField: optionField,
            planChoiceField: field,
          }))}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
