import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddShopInsertShop = gql`
    mutation AddShopInsertShop($shops: [shop_insert_input!]!) {
  insert_shop(objects: $shops) {
    affected_rows
    returning {
      shopId
    }
  }
}
    `;
export const CreateShop = gql`
    mutation CreateShop($input: CreateShopInput!) {
  createShop(input: $input)
}
    `;
export type AddShopInsertShopMutationVariables = Types.Exact<{
  shops: Array<Types.ShopInsertInput> | Types.ShopInsertInput;
}>;


export type AddShopInsertShopMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shop?: Types.Maybe<(
    { __typename?: 'shop_mutation_response' }
    & Pick<Types.ShopMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId'>
    )> }
  )> }
);

export type CreateShopMutationVariables = Types.Exact<{
  input: Types.CreateShopInput;
}>;


export type CreateShopMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'createShop'>
);


export const AddShopInsertShopDocument = gql`
    mutation AddShopInsertShop($shops: [shop_insert_input!]!) {
  insert_shop(objects: $shops) {
    affected_rows
    returning {
      shopId
    }
  }
}
    `;
export type AddShopInsertShopMutationFn = Apollo.MutationFunction<AddShopInsertShopMutation, AddShopInsertShopMutationVariables>;

/**
 * __useAddShopInsertShopMutation__
 *
 * To run a mutation, you first call `useAddShopInsertShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShopInsertShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShopInsertShopMutation, { data, loading, error }] = useAddShopInsertShopMutation({
 *   variables: {
 *      shops: // value for 'shops'
 *   },
 * });
 */
export function useAddShopInsertShopMutation(baseOptions?: Apollo.MutationHookOptions<AddShopInsertShopMutation, AddShopInsertShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddShopInsertShopMutation, AddShopInsertShopMutationVariables>(AddShopInsertShopDocument, options);
      }
export type AddShopInsertShopMutationHookResult = ReturnType<typeof useAddShopInsertShopMutation>;
export type AddShopInsertShopMutationResult = Apollo.MutationResult<AddShopInsertShopMutation>;
export type AddShopInsertShopMutationOptions = Apollo.BaseMutationOptions<AddShopInsertShopMutation, AddShopInsertShopMutationVariables>;
export const CreateShopDocument = gql`
    mutation CreateShop($input: CreateShopInput!) {
  createShop(input: $input)
}
    `;
export type CreateShopMutationFn = Apollo.MutationFunction<CreateShopMutation, CreateShopMutationVariables>;

/**
 * __useCreateShopMutation__
 *
 * To run a mutation, you first call `useCreateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopMutation, { data, loading, error }] = useCreateShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopMutation, CreateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, options);
      }
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = Apollo.MutationResult<CreateShopMutation>;
export type CreateShopMutationOptions = Apollo.BaseMutationOptions<CreateShopMutation, CreateShopMutationVariables>;