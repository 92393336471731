import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Input, Row } from "antd";
import { filterShop } from "models/shop";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useShopsArchiveShopsMutation, useShopsGetShopsQuery } from "pages/Shops/queries";
import { ShopTable } from "pages/Shops/ShopTable";

export const Shops = () => {
  const { canAccess } = useCanAccess();
  const [name, setName] = useState("");

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getShopsData,
    loading: loadingShops,
    refetch: refetchShops,
    error,
  } = useShopsGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const filteredShops = useMemo(() => {
    const shops = getShopsData?.company?.[0]?.shops ?? [];
    return filterShop(shops, { name });
  }, [getShopsData, name]);

  const [archiveShopsMutation] = useShopsArchiveShopsMutation();

  const onArchive = useCallback(
    async ({ shopId }: { shopId: string }) => {
      try {
        await archiveShopsMutation({
          variables: { input: { shopId } },
        });

        message.success("アーカイブしました");
      } catch (err) {
        message.error("アーカイブに失敗しました");
      }

      await refetchShops();
    },
    [archiveShopsMutation, refetchShops],
  );

  const onChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <DashboardLayout title="店舗一覧">
      <PageHeader
        title="店舗一覧"
        extra={[
          canAccess("addShop") ? (
            <Link key="add" to="/shop/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ) : null,
        ]}
        footer={
          <Row>
            <Input allowClear placeholder="店舗名" value={name} onChange={onChangeFilter} />
          </Row>
        }
      />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ShopTable shops={filteredShops} loading={loadingShops} onArchive={onArchive} />
    </DashboardLayout>
  );
};
