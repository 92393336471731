import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { CreateLineOfficialAccountInput } from "types/graphql";

export type AddLineOfficialAccountFormValues = Pick<
  CreateLineOfficialAccountInput,
  "lineId" | "longLivedChannelAccessToken" | "channelId" | "channelSecret"
>;

const getInitialValues = () => ({});

export const AddLineOfficialAccountFormItem = createFormItem<AddLineOfficialAccountFormValues>();

export const useAddLineOfficialAccountForm = (
  onSubmit: (lineOfficialAccount: AddLineOfficialAccountFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
