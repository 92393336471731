import React, { memo } from "react";
import { isNot } from "util/type/primitive";

import { Select } from "components/Input/Select";
import type { DishUpSlipGroup } from "pages/ShopMenus/types";

type Props = {
  onChange: (roleIds: "empty" | number[]) => void;
  dishUpSlipGroups: DishUpSlipGroup[];
  value: "empty" | number[] | undefined;
};

export const DishUpSlipGroupField = memo<Props>(({ onChange, dishUpSlipGroups, value }) => (
  <Select<(number | "empty")[]>
    mode="multiple"
    allowClear
    placeholder="デシャップ伝票グループ"
    defaultValue={[]}
    value={value === "empty" ? [value] : value ?? []}
    onChange={(dishUpSlipGroupIds) =>
      onChange(
        dishUpSlipGroupIds.slice(-1)[0] === "empty"
          ? "empty"
          : dishUpSlipGroupIds.filter(isNot("empty")),
      )
    }
    optionFilterProp="label"
    options={[
      { label: "未設定", value: "empty" },
      ...dishUpSlipGroups.map(({ id, name }) => ({ label: name, value: id })),
    ]}
    data-cy="select-dish-up-slip-group"
  />
));
