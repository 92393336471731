import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Avatar } from "antd";
import { AvatarProps } from "antd/lib/avatar";
import {
  blue,
  cyan,
  geekblue,
  gold,
  green,
  lime,
  magenta,
  orange,
  purple,
  red,
  volcano,
  yellow,
} from "@ant-design/colors";
import { sum } from "util/array";

const colors = [
  red[5],
  volcano[5],
  orange[5],
  gold[5],
  yellow[5],
  lime[5],
  green[5],
  cyan[5],
  blue[5],
  geekblue[5],
  purple[5],
  magenta[5],
];

const StyledAvatar = styled(Avatar)<{ color: string }>`
  flex: none;
  ${({ color }) =>
    css`
      background: ${color};
    `}
`;

type Props = AvatarProps;

export const InitialAvatar = memo<Props>(({ children, ...props }) => {
  const text = String(children).toLocaleUpperCase();
  const initialCodePoint = text.codePointAt(0);
  const initial = initialCodePoint ? String.fromCodePoint(initialCodePoint) : "";
  const code = sum(Array.from(text).map((character) => character.charCodeAt(0)));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const color = colors[code % (colors.length - 1)]!;

  return (
    <StyledAvatar color={color} {...props}>
      {initial}
    </StyledAvatar>
  );
});
