import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { WinboardMenuInsertInput } from "types/graphql";

import { Menu, WinboardMenu } from "../types";

export type EditMenuWinboardMenuFormValues = Pick<
  WinboardMenu,
  "name" | "code" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
>;

export const EditMenuWinboardMenuFormItem = createFormItem<EditMenuWinboardMenuFormValues>();

export const useEditMenuWinboardMenuForm = ({
  menu,
  winboardMenu,
  onSubmit,
}: {
  menu?: Menu;
  winboardMenu?: WinboardMenu;
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId" | "_companyId">) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditMenuWinboardMenuFormValues>(
    () => ({
      name: winboardMenu?.name ?? menu?.name ?? "",
      code: winboardMenu?.code ?? "",
      bumonCode: winboardMenu?.bumonCode ?? "",
      bumonName: winboardMenu?.bumonName ?? "",
      categoryCode: winboardMenu?.categoryCode ?? "",
      categoryName: winboardMenu?.categoryName ?? "",
    }),
    [menu, winboardMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditMenuWinboardMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
