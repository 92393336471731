import React, { memo, useCallback } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Option } from "../types";

import { ChoiceTable } from "./ChoiceTable";

type Props = {
  loading?: boolean;
  menuId: number;
  options: Option[];
};

export const OptionTable = memo<Props>(({ loading, menuId, options }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [{ title: "オプション名", dataIndex: "name", key: "name" }];

  const expandedRowRender = useCallback(
    ({ optionId, choices }: Option) => (
      <ChoiceTable
        menuId={menuId}
        optionId={optionId}
        choices={choices.map((c) => ({ ...c, winboardMenu: c.winboardMenus?.[0] }))}
      />
    ),
    [menuId],
  );

  return (
    <Table
      rowKey="optionId"
      columns={columns}
      dataSource={options}
      loading={loading}
      bordered
      expandable={{ expandedRowRender, defaultExpandAllRows: true }}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      shouldDisableMaxWidthScroll
    />
  );
});
