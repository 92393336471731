import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { Role } from "pages/AddRole/types";

import { AddRoleFormItem } from "../useAddRoleForm";

type Props = {
  roles: Role[];
} & Omit<FormItemProps, "children" | "name">;

export const FirstSpareRoleField = memo<Props>(({ roles, ...props }) => (
  <AddRoleFormItem
    label={
      <FormHelp
        label="緊急時自動振替先プリンタ1"
        help="伝票の未印刷を検知してから 2 分程度経過したときに自動で指定したプリンタに印刷先を振り替えます。"
      />
    }
    name="firstSpareRoleId"
    {...props}
  >
    <Select<number>
      allowClear
      optionFilterProp="label"
      options={roles.map(({ roleId, name }) => ({ label: name, value: roleId }))}
      data-cy="select-first-spare-role-id"
    />
  </AddRoleFormItem>
));
