import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PikaichiOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { PikaichiOnSitePaymentDetailType } from "../types";

export type EditPikaichiOnSitePaymentDetailTypeFormValues = Pick<
  PikaichiOnSitePaymentDetailType,
  "paymentMediaCd" | "paymentMediaName" | "paymentUchiwakeCd" | "paymentUchiwakeName"
>;

export const EditPikaichiOnSitePaymentDetailTypeFormItem =
  createFormItem<EditPikaichiOnSitePaymentDetailTypeFormValues>();

export const useEditPikaichiOnSitePaymentDetailTypeForm = ({
  pikaichiDetailType,
  onSubmit,
}: {
  pikaichiDetailType?: PikaichiOnSitePaymentDetailType;
  onSubmit: (
    args: Omit<
      PikaichiOnSitePaymentDetailTypeInsertInput,
      "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditPikaichiOnSitePaymentDetailTypeFormValues>(
    () => ({
      paymentMediaCd: pikaichiDetailType?.paymentMediaCd ?? "",
      paymentMediaName: pikaichiDetailType?.paymentMediaName ?? "",
      paymentUchiwakeCd: pikaichiDetailType?.paymentUchiwakeCd ?? "",
      paymentUchiwakeName: pikaichiDetailType?.paymentUchiwakeName ?? "",
    }),
    [
      pikaichiDetailType?.paymentMediaCd,
      pikaichiDetailType?.paymentMediaName,
      pikaichiDetailType?.paymentUchiwakeCd,
      pikaichiDetailType?.paymentUchiwakeName,
    ],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditPikaichiOnSitePaymentDetailTypeFormValues;

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
