import React, { useCallback, useMemo, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Collapse } from "antd";
import { noop } from "dinii-self-js-lib/noop";
import { filterMenusByName } from "models/menu";
import { isSoldOut as checkIsSoldOut } from "models/stock";
import { isNotUndefined } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { EditShopMenusBulkConfirmModal } from "./EditShopMenusBulkConfirmModal";
import { EditShopMenusBulkSummary } from "./EditShopMenusBulkSummary";
import { EditShopMenusBulkFormTable } from "./EditShopMenusBulkTable";
import { FilterConditions, MenuFilter } from "./MenuFilter";
import {
  useEditShopMenusBulkGetCategoriesQuery,
  useEditShopMenusBulkGetMenusQuery,
  useEditShopMenusBulkGetShopsQuery,
  useUpdateShopMenusBulkMutation,
} from "./queries";
import { BulkEditConditions, Choice, Menu } from "./types";

const StyledAlert = styled(Alert)`
  background-color: #fffbe6;
  border: 1px solid #ffe58f;

  .ant-alert-icon {
    color: #52c41a;
  }
`;

const Wrapper = styled.div`
  background: ${grey[0]};
  padding: 24px;
  border-radius: 8px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const isIncludedInCategoryIds = ({ menu, categoryIds }: { menu: Menu; categoryIds: string[] }) => {
  const menuCategoryIds = menu.categoryMenus.map(({ category }) => category.id);
  return categoryIds.some((categoryId) => menuCategoryIds.includes(categoryId));
};

const filterMenus = ({
  menus,
  filterConditions: {
    name,
    categoryIds,
    isDealing,
    isVisibleForCustomer,
    isVisibleForStaff,
    isSoldOut,
  },
}: {
  menus: Menu[];
  filterConditions: FilterConditions;
}) => {
  const filteredMenus = menus.filter((menu) => {
    const shopMenus = menu.shopMenus;
    return (
      (categoryIds === null || isIncludedInCategoryIds({ menu, categoryIds })) &&
      (isDealing === null || (isDealing ? shopMenus.length > 0 : shopMenus.length === 0)) &&
      (isVisibleForCustomer === null ||
        shopMenus.some((shopMenu) => shopMenu.isVisibleForCustomer === isVisibleForCustomer)) &&
      (isVisibleForStaff === null ||
        shopMenus.some((shopMenu) => shopMenu.isVisibleForStaff === isVisibleForStaff)) &&
      (isSoldOut === null || shopMenus.some(({ stock }) => checkIsSoldOut({ stock }) === isSoldOut))
    );
  });

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const EditShopMenusBulk = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    hasFilterConditions,
    filterConditions: partialFilterConditions,
    updateFilterCondition,
    clearFilterConditions,
  } = useFilterConditions<FilterConditions>({
    name: null,
    categoryIds: null,
    isDealing: null,
    isVisibleForCustomer: null,
    isVisibleForStaff: null,
    isSoldOut: null,
  });
  const filterConditions: FilterConditions = useMemo(
    () => ({
      name: partialFilterConditions.name ?? null,
      categoryIds: partialFilterConditions.categoryIds ?? null,
      isDealing: partialFilterConditions.isDealing ?? null,
      isVisibleForCustomer: partialFilterConditions.isVisibleForCustomer ?? null,
      isVisibleForStaff: partialFilterConditions.isVisibleForStaff ?? null,
      isSoldOut: partialFilterConditions.isSoldOut ?? null,
    }),
    [partialFilterConditions],
  );

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
  } = useEditShopMenusBulkGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const menus: Menu[] = useMemo(() => getMenusData?.menu ?? [], [getMenusData]);

  const choices = useMemo<Choice[]>(
    () =>
      menus.flatMap((menu) =>
        menu.menuOptions.flatMap(({ option }) =>
          option.choices.map((choice) => ({
            ...choice,
            menuId: menu.id,
            optionId: option.optionId,
          })),
        ),
      ),
    [menus],
  );

  const filteredMenus = useMemo(
    () => filterMenus({ menus, filterConditions }),
    [menus, filterConditions],
  );
  const menuRowKeyToMenuMap = useMemo(
    () => new Map<string, Menu>(menus.map((menu) => [menu.id, menu])),
    [menus],
  );
  const choiceRowKeyToChoiceMap = useMemo(
    () =>
      new Map<string, Choice>(choices.map((choice) => [`${choice.menuId}-${choice.id}`, choice])),
    [choices],
  );

  const { data: getCategoriesData } = useEditShopMenusBulkGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = useMemo(
    () => getCategoriesData?.category ?? [],
    [getCategoriesData?.category],
  );

  const { data: getShopsData } = useEditShopMenusBulkGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);
  const shopIdToShopMap = useMemo(() => new Map(shops.map((shop) => [shop.shopId, shop])), [shops]);
  const allShopIds = useMemo(() => shops.map((shop) => shop.shopId), [shops]);

  const [updateShopMenusBulkMutation, { loading: loadingUpdateShopMenusBulk }] =
    useUpdateShopMenusBulkMutation();

  const [bulkEditConditions, setBulkEditConditions] = useState<BulkEditConditions>({
    shopIds: null,
    isDealing: null,
    isVisibleForCustomer: null,
    isVisibleForStaff: null,
    isSoldOut: null,
  });
  const [selectedMenuRowKeys, setSelectedMenuRowKeys] = useState<string[]>([]);
  const [selectedChoiceRowKeys, setSelectedChoiceRowKeys] = useState<string[]>([]);
  const selectedRowKeys = useMemo(
    () => [...selectedMenuRowKeys, ...selectedChoiceRowKeys],
    [selectedMenuRowKeys, selectedChoiceRowKeys],
  );

  const clearBulkEditConditions = useCallback(() => {
    setSelectedMenuRowKeys([]);
    setSelectedChoiceRowKeys([]);
    setBulkEditConditions({
      shopIds: null,
      isDealing: null,
      isVisibleForCustomer: null,
      isVisibleForStaff: null,
      isSoldOut: null,
    });
  }, [setBulkEditConditions, setSelectedChoiceRowKeys, setSelectedMenuRowKeys]);

  const onSubmit = useCallback(async () => {
    const { shopIds, isDealing, isVisibleForCustomer, isVisibleForStaff, isSoldOut } =
      bulkEditConditions;

    try {
      await updateShopMenusBulkMutation({
        variables: {
          input: {
            shopIds: shopIds && shopIds.length > 0 ? shopIds : allShopIds,
            menuIds: selectedMenuRowKeys
              .map((menuRowKey) => {
                const menu = menuRowKeyToMenuMap.get(menuRowKey);
                if (!menu) return;
                return menu.id;
              })
              .filter(isNotUndefined),
            choiceIds: selectedChoiceRowKeys
              .map((choiceRowKey) => {
                const choice = choiceRowKeyToChoiceMap.get(choiceRowKey);
                if (!choice) return;
                return choice.id;
              })
              .filter(isNotUndefined),
            isDealing,
            isVisibleForCustomer,
            isVisibleForStaff,
            currentStockNum: isSoldOut ? 0 : null,
          },
        },
      });

      await refetchMenus({ companyId }).catch(noop);
      message.success("編集を保存しました");
      clearBulkEditConditions();
      navigate("/shopMenu/edit/bulk", { replace: true });
    } catch (err) {
      message.error("編集の保存に失敗しました");
    }
  }, [
    allShopIds,
    bulkEditConditions,
    choiceRowKeyToChoiceMap,
    clearBulkEditConditions,
    companyId,
    menuRowKeyToMenuMap,
    navigate,
    refetchMenus,
    selectedChoiceRowKeys,
    selectedMenuRowKeys,
    updateShopMenusBulkMutation,
  ]);

  const loading = loadingMenus || loadingUpdateShopMenusBulk;

  return (
    <DashboardLayout
      title="取扱メニュー"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "取扱メニュー" }],
      }}
    >
      <PageHeader title="複数店舗の取扱一括設定" onBack={goBack} />

      <Wrapper>
        <MenuFilter
          categories={categories}
          hasFilterConditions={hasFilterConditions}
          filterConditions={filterConditions}
          updateFilterCondition={updateFilterCondition}
          clearFilterConditions={clearFilterConditions}
        />
        <Spacer size={16} />
        {selectedRowKeys.length === 0 ? (
          <Alert message="設定を変更するメニューを選択してください" type="info" showIcon />
        ) : (
          <StyledAlert
            message="選択したメニューすべてに変更内容が更新されます"
            type="info"
            showIcon
          />
        )}
        <Spacer size={16} />
        <EditShopMenusBulkFormTable
          menus={filteredMenus}
          shops={shops}
          shopIdToShopMap={shopIdToShopMap}
          bulkEditConditions={bulkEditConditions}
          setBulkEditConditions={setBulkEditConditions}
          selectedMenuRowKeys={selectedMenuRowKeys}
          setSelectedMenuRowKeys={setSelectedMenuRowKeys}
          selectedChoiceRowKeys={selectedChoiceRowKeys}
          setSelectedChoiceRowKeys={setSelectedChoiceRowKeys}
          selectedRowKeys={selectedRowKeys}
          clearBulkEditConditions={clearBulkEditConditions}
        />
        <Collapse>
          <Collapse.Panel header="変更内容一覧" key={1}>
            <EditShopMenusBulkSummary
              selectedMenuRowKeys={selectedMenuRowKeys}
              selectedChoiceRowKeys={selectedChoiceRowKeys}
              menuRowKeyToMenuMap={menuRowKeyToMenuMap}
              choiceRowKeyToChoiceMap={choiceRowKeyToChoiceMap}
              shopIdToShopMap={shopIdToShopMap}
              bulkEditConditions={bulkEditConditions}
              allShopIds={allShopIds}
            />
          </Collapse.Panel>
        </Collapse>
      </Wrapper>

      <Spacer size={24} />

      <Actions>
        <Button onClick={goBack}>キャンセル</Button>

        <Link to="/shopMenu/edit/bulk/confirm" replace>
          <Button type="primary">確認して更新</Button>
        </Link>

        <Routes>
          <Route
            path="confirm"
            element={
              <EditShopMenusBulkConfirmModal
                selectedRowKeys={selectedRowKeys}
                selectedMenuRowKeys={selectedMenuRowKeys}
                selectedChoiceRowKeys={selectedChoiceRowKeys}
                menuRowKeyToMenuMap={menuRowKeyToMenuMap}
                choiceRowKeyToChoiceMap={choiceRowKeyToChoiceMap}
                shopIdToShopMap={shopIdToShopMap}
                bulkEditConditions={bulkEditConditions}
                loading={loading}
                onSubmit={onSubmit}
                allShopIds={allShopIds}
              />
            }
          />
        </Routes>
      </Actions>
    </DashboardLayout>
  );
};
