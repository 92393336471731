import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem, Form } from "components/antd/Form";

export type AddPlanFormValues = {
  plans: number[];
};

const getInitialValues = () => ({});

export const AddPlanFormItem = createFormItem<AddPlanFormValues>();

export const useAddPlanForm = (onChange: (plan: AddPlanFormValues) => void) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as AddPlanFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { form, initialValues, change };
};
