import React, { memo, useMemo } from "react";
import { Popconfirm, Popover, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { Category } from "pages/Categories/types";

type Props = {
  loading?: boolean;
  categories: Category[];
  deleteCategory(categoryId: number): void;
};

export const CategoryTable = memo<Props>(({ loading, categories, deleteCategory }) => {
  const [pagination, setPagination] = usePagination();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns = useMemo<ColumnsType<Category>>(
    () => [
      {
        width: 200,
        title: "カテゴリ名",
        fixed: "left",
        render(_: unknown, { name, shopSideName }: Category) {
          return shopSideName || name;
        },
      },
      {
        title: "メニュー一覧",
        width: 300,
        render(_: unknown, { categoryMenus }: Category) {
          return categoryMenus.map((categoryMenu) => (
            <Tag key={categoryMenu.menu.menuId}>{categoryMenu.menu.name}</Tag>
          ));
        },
      },
      {
        title: "プラン一覧",
        width: 300,
        render(_: unknown, { plans }: Category) {
          return plans.map((plan) => <Tag key={plan.planId}>{plan.planName}</Tag>);
        },
      },
      {
        title: "プラン内カテゴリ一覧",
        width: 300,
        render(_: unknown, { planMenuCategories }: Category) {
          return planMenuCategories.map(({ plan }) => <Tag key={plan.planId}>{plan.planName}</Tag>);
        },
      },
      ...(isFeatureEnabled("editCategory")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              fixed: "right",
              render(_: string, { categoryId }: Category) {
                return (
                  <IconLink to={`/category/${categoryId}/edit`}>
                    <EditIcon />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteCategory")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(
                _: string,
                { categoryId, plans, categoryMenus, planMenuCategories }: Category,
              ) {
                if (plans.length > 0) {
                  return (
                    <Popover
                      content={
                        <Typography.Paragraph>
                          プランを別のカテゴリに紐づけるか、
                          <br />
                          紐づくプランを削除してからカテゴリを削除してください
                        </Typography.Paragraph>
                      }
                      title="プランが紐づくカテゴリは削除できません"
                    >
                      <DeleteIcon id={`category:${categoryId}:delete`} disabled={true} />
                    </Popover>
                  );
                }
                if (categoryMenus.length > 0) {
                  return (
                    <Popover
                      content={
                        <Typography.Paragraph>
                          メニューを別のカテゴリに紐づけるか、
                          <br />
                          紐づくメニューを削除してからカテゴリを削除してください
                        </Typography.Paragraph>
                      }
                      title="メニューが紐づくカテゴリは削除できません"
                    >
                      <DeleteIcon id={`category:${categoryId}:delete`} disabled={true} />
                    </Popover>
                  );
                }
                if (planMenuCategories.length > 0) {
                  return (
                    <Popover
                      content={
                        <Typography.Paragraph>
                          プラン内カテゴリとしての紐づけを解除するか、
                          <br />
                          紐づくプランを削除してからカテゴリを削除してください
                        </Typography.Paragraph>
                      }
                      title="プランに紐づくカテゴリは削除できません"
                    >
                      <DeleteIcon id={`category:${categoryId}:delete`} disabled={true} />
                    </Popover>
                  );
                }
                return (
                  <Popconfirm
                    title="カテゴリーを削除しますか？"
                    onConfirm={() => deleteCategory(categoryId)}
                  >
                    <DeleteIcon id={`category:${categoryId}:delete`} />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ],
    [deleteCategory, isFeatureEnabled],
  );

  return (
    <Table
      rowKey="categoryId"
      columns={columns}
      dataSource={categories}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      data-cy="category-table"
    />
  );
});
