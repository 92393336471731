import React, { memo, Ref, useMemo, useState } from "react";
import styled from "styled-components";
import { Col, InputRef, Row } from "antd";
import { filterCompany } from "models/company";

import { CompanyList } from "components/Layout/DashboardLayout/CompanySelect/CompanyList";
import { HistorySelect } from "components/Layout/DashboardLayout/CompanySelect/HistorySelect";
import { Navigation } from "components/Layout/DashboardLayout/CompanySelect/Navigation";
import { Company } from "components/Layout/DashboardLayout/types";
import { grey } from "constants/colors";
import { useViewport } from "hooks/useViewport";

const SEARCH_HEIGHT = 60;

const Wrapper = styled(Row)`
  height: 100%;
  overflow: hidden;
`;

const DirectoryCol = styled(Col)`
  border-right: 1px solid ${grey[2]};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${grey[3]};
`;

const HistoryCol = styled(Col)``;

type Props = {
  inputRef?: Ref<InputRef>;
  companies: Company[];
  onSelect: (companyId: string) => void;
  onClose: () => void;
  height: number;
};

export const CompanySelectOverlay = memo<Props>(
  ({ inputRef, companies, onSelect, onClose, height }) => {
    const [name, setName] = useState("");
    const filteredCompanies = useMemo(() => filterCompany(companies, { name }), [companies, name]);

    const { isSmartphone, isTablet, isDesktop } = useViewport();

    return (
      <Wrapper>
        <DirectoryCol span={isDesktop ? 16 : 24}>
          <Navigation
            inputRef={inputRef}
            onChangeName={setName}
            onClose={!isDesktop ? onClose : undefined}
          />
          <CompanyList
            companies={filteredCompanies}
            onSelect={onSelect}
            height={height - SEARCH_HEIGHT}
            columnCount={isSmartphone ? 1 : 2}
          />
        </DirectoryCol>
        {!isDesktop && <Divider />}
        <HistoryCol span={isDesktop ? 8 : 24}>
          <HistorySelect companies={companies} onSelect={onSelect} columnCount={isTablet ? 2 : 1} />
        </HistoryCol>
      </Wrapper>
    );
  },
);
