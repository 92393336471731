export const messageDeliveryAnalyticsProperties = {
  messageSentCount: {
    label: "配信数",
    iconSrc: "/message-delivery-analytics/message_sent_count.png",
  },
  messageOpenCount: {
    label: "開封数",
    iconSrc: "/message-delivery-analytics/message_open_count.png",
  },
  visitedCustomersCount: {
    label: "来店客数",
    iconSrc: "/message-delivery-analytics/visited_customers_count.png",
  },
  visitedTableUserCustomersCount: {
    label: "来店組客数",
    iconSrc: "/message-delivery-analytics/visited_table_user_customers_count.png",
  },
  visitedTableUserCustomersSales: {
    label: "配信効果売上",
    iconSrc: "/message-delivery-analytics/visited_table_user_customers_sales.png",
  },
};
