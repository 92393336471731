import { AxiosError } from "axios";
import { isAxiosError } from "util/type/axios";
import { isObject } from "util/type/primitive";

export const isDiniiApiError = (
  error: any,
): error is AxiosError<{ title: string; message: string }> => {
  if (!isAxiosError(error)) return false;

  const data = error.response?.data;
  if (!isObject(data)) return false;

  return "title" in data && "message" in data;
};
