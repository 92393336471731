import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GenerateQrCodesGetShops = gql`
    query GenerateQrCodesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
    lineChannelConfig {
      liffId
    }
  }
}
    `;
export const GenerateQrCodesGetTables = gql`
    query GenerateQrCodesGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    shopId
    tableId
  }
}
    `;
export type GenerateQrCodesGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GenerateQrCodesGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
    & { lineChannelConfig?: Types.Maybe<(
      { __typename?: 'lineChannelConfig' }
      & Pick<Types.LineChannelConfig, 'liffId'>
    )> }
  )> }
);

export type GenerateQrCodesGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type GenerateQrCodesGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'code' | 'name' | 'shopId' | 'tableId'>
  )> }
);


export const GenerateQrCodesGetShopsDocument = gql`
    query GenerateQrCodesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
    lineChannelConfig {
      liffId
    }
  }
}
    `;

/**
 * __useGenerateQrCodesGetShopsQuery__
 *
 * To run a query within a React component, call `useGenerateQrCodesGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateQrCodesGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateQrCodesGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGenerateQrCodesGetShopsQuery(baseOptions: Apollo.QueryHookOptions<GenerateQrCodesGetShopsQuery, GenerateQrCodesGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateQrCodesGetShopsQuery, GenerateQrCodesGetShopsQueryVariables>(GenerateQrCodesGetShopsDocument, options);
      }
export function useGenerateQrCodesGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateQrCodesGetShopsQuery, GenerateQrCodesGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateQrCodesGetShopsQuery, GenerateQrCodesGetShopsQueryVariables>(GenerateQrCodesGetShopsDocument, options);
        }
export type GenerateQrCodesGetShopsQueryHookResult = ReturnType<typeof useGenerateQrCodesGetShopsQuery>;
export type GenerateQrCodesGetShopsLazyQueryHookResult = ReturnType<typeof useGenerateQrCodesGetShopsLazyQuery>;
export type GenerateQrCodesGetShopsQueryResult = Apollo.QueryResult<GenerateQrCodesGetShopsQuery, GenerateQrCodesGetShopsQueryVariables>;
export const GenerateQrCodesGetTablesDocument = gql`
    query GenerateQrCodesGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    shopId
    tableId
  }
}
    `;

/**
 * __useGenerateQrCodesGetTablesQuery__
 *
 * To run a query within a React component, call `useGenerateQrCodesGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateQrCodesGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateQrCodesGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useGenerateQrCodesGetTablesQuery(baseOptions: Apollo.QueryHookOptions<GenerateQrCodesGetTablesQuery, GenerateQrCodesGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateQrCodesGetTablesQuery, GenerateQrCodesGetTablesQueryVariables>(GenerateQrCodesGetTablesDocument, options);
      }
export function useGenerateQrCodesGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateQrCodesGetTablesQuery, GenerateQrCodesGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateQrCodesGetTablesQuery, GenerateQrCodesGetTablesQueryVariables>(GenerateQrCodesGetTablesDocument, options);
        }
export type GenerateQrCodesGetTablesQueryHookResult = ReturnType<typeof useGenerateQrCodesGetTablesQuery>;
export type GenerateQrCodesGetTablesLazyQueryHookResult = ReturnType<typeof useGenerateQrCodesGetTablesLazyQuery>;
export type GenerateQrCodesGetTablesQueryResult = Apollo.QueryResult<GenerateQrCodesGetTablesQuery, GenerateQrCodesGetTablesQueryVariables>;