import React, { memo } from "react";
import { Checkbox } from "antd";

import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";

export const UseAdyenTerminalPaymentField = memo(() => (
  <EditDiniiPayConfigFormItem
    name={["terminalPaymentConfig", "useAdyenTerminalPayment"]}
    valuePropName="checked"
  >
    <Checkbox>Adyenを利用する</Checkbox>
  </EditDiniiPayConfigFormItem>
));
