import React, { memo, useCallback, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { AddPlanForm } from "pages/PlanGroupPlans/AddPlanGroupModal/AddPlanForm";
import { AddPlanFormValues } from "pages/PlanGroupPlans/AddPlanGroupModal/AddPlanForm/useAddPlanForm";
import {
  useAddPlanGroupPlanModalPlansQuery,
  useAddPlanInsertPlanGroupPlansMutation,
} from "pages/PlanGroupPlans/AddPlanGroupModal/queries";
import { PlanGroup } from "pages/PlanGroupPlans/types";

type Props = {
  planGroup: PlanGroup;
  goBack: () => void;
  refetchPlanGroup: () => void;
};

export const AddPlanGroupPlanModal = memo<Props>(({ planGroup, goBack, refetchPlanGroup }) => {
  const { planGroupId } = planGroup;

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getPlansData, error } = useAddPlanGroupPlanModalPlansQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const plans = getPlansData?.plan ?? [];

  const [addPlanGroupPlans, { loading: loadingInsertPlanGroupPlans }] =
    useAddPlanInsertPlanGroupPlansMutation();

  const [formValues, setFormValues] = useState<AddPlanFormValues | null>(null);
  const onOk = useCallback(async () => {
    if (formValues) {
      try {
        const planIds = formValues.plans;
        await addPlanGroupPlans({
          variables: { planGroupId, planIds },
        });

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
      refetchPlanGroup();
    }

    goBack();
  }, [planGroupId, formValues, addPlanGroupPlans, goBack, refetchPlanGroup]);
  const onCancel = goBack;

  return (
    <Modal
      title="プラン追加"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button key="add" type="primary" onClick={onOk} loading={loadingInsertPlanGroupPlans}>
          追加
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddPlanForm planGroup={planGroup} plans={plans} onChange={setFormValues} />
    </Modal>
  );
});
