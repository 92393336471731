import dayjs from "dayjs";

import { Maybe } from "types/graphql";

const isExpired = ({ now, expiredAt }: { now: Date; expiredAt: dayjs.Dayjs | null }) =>
  Boolean(expiredAt && dayjs(now).isAfter(expiredAt));

const getExpiredAt = ({
  availableFrom,
  availableUntil,
  availableDays,
}: {
  availableFrom: string;
  availableUntil: Maybe<string> | undefined;
  availableDays: Maybe<number> | undefined;
}) => {
  if (availableUntil) return dayjs(availableUntil);

  if (availableDays) {
    return dayjs(availableFrom).add(availableDays, "days");
  }

  return null;
};

export const getAvailablePeriod = ({
  now,
  availableFrom,
  availableUntil,
  availableDays,
}: {
  now: Date;
  availableFrom: string;
  availableUntil: Maybe<string> | undefined;
  availableDays: Maybe<number> | undefined;
}) => {
  const expiredAt = getExpiredAt({ availableFrom, availableUntil, availableDays });

  if (isExpired({ now, expiredAt })) return "期限切れ";

  const isAvailable = dayjs(now).isSame(availableFrom) || dayjs(now).isAfter(availableFrom);
  const formattedAvailableFrom = dayjs(availableFrom).format("YYYY年M月D日");

  if (!expiredAt) return isAvailable ? "無期限" : `${formattedAvailableFrom}〜`;

  const formattedExpiredAt =
    dayjs(availableFrom).get("year") === expiredAt.get("year")
      ? expiredAt.format("M月D日")
      : expiredAt.format("YYYY年M月D日");

  return `${formattedAvailableFrom}〜${formattedExpiredAt}`;
};
