import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardInflowSourceTagGetInflowSourceTag = gql`
    query WinboardInflowSourceTagGetInflowSourceTag($companyId: uuid!) {
  inflowSourceTag(where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}) {
    id
    companyId
    displayName
    winboardInflowSourceTag {
      id
      code
    }
  }
}
    `;
export type WinboardInflowSourceTagGetInflowSourceTagQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardInflowSourceTagGetInflowSourceTagQuery = (
  { __typename?: 'query_root' }
  & { inflowSourceTag: Array<(
    { __typename?: 'inflowSourceTag' }
    & Pick<Types.InflowSourceTag, 'id' | 'companyId' | 'displayName'>
    & { winboardInflowSourceTag?: Types.Maybe<(
      { __typename?: 'winboardInflowSourceTag' }
      & Pick<Types.WinboardInflowSourceTag, 'id' | 'code'>
    )> }
  )> }
);


export const WinboardInflowSourceTagGetInflowSourceTagDocument = gql`
    query WinboardInflowSourceTagGetInflowSourceTag($companyId: uuid!) {
  inflowSourceTag(where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}) {
    id
    companyId
    displayName
    winboardInflowSourceTag {
      id
      code
    }
  }
}
    `;

/**
 * __useWinboardInflowSourceTagGetInflowSourceTagQuery__
 *
 * To run a query within a React component, call `useWinboardInflowSourceTagGetInflowSourceTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardInflowSourceTagGetInflowSourceTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardInflowSourceTagGetInflowSourceTagQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardInflowSourceTagGetInflowSourceTagQuery(baseOptions: Apollo.QueryHookOptions<WinboardInflowSourceTagGetInflowSourceTagQuery, WinboardInflowSourceTagGetInflowSourceTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardInflowSourceTagGetInflowSourceTagQuery, WinboardInflowSourceTagGetInflowSourceTagQueryVariables>(WinboardInflowSourceTagGetInflowSourceTagDocument, options);
      }
export function useWinboardInflowSourceTagGetInflowSourceTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardInflowSourceTagGetInflowSourceTagQuery, WinboardInflowSourceTagGetInflowSourceTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardInflowSourceTagGetInflowSourceTagQuery, WinboardInflowSourceTagGetInflowSourceTagQueryVariables>(WinboardInflowSourceTagGetInflowSourceTagDocument, options);
        }
export type WinboardInflowSourceTagGetInflowSourceTagQueryHookResult = ReturnType<typeof useWinboardInflowSourceTagGetInflowSourceTagQuery>;
export type WinboardInflowSourceTagGetInflowSourceTagLazyQueryHookResult = ReturnType<typeof useWinboardInflowSourceTagGetInflowSourceTagLazyQuery>;
export type WinboardInflowSourceTagGetInflowSourceTagQueryResult = Apollo.QueryResult<WinboardInflowSourceTagGetInflowSourceTagQuery, WinboardInflowSourceTagGetInflowSourceTagQueryVariables>;