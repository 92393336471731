import React, { memo } from "react";
import { Radio, Space } from "antd";

import { PaymentProviderType } from "types/graphql";

import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";

type Props = {
  disabled: boolean;
};

export const PaymentProviderField = memo<Props>(({ disabled }) => (
  <EditDiniiPayConfigFormItem name={["onlinePaymentConfig", "paymentProvider"]}>
    <Radio.Group disabled={disabled}>
      <Space direction="vertical">
        <Radio value={null}>利用しない</Radio>
        <Radio value={PaymentProviderType.Adyen}>Adyenを利用する</Radio>
        <Radio value={PaymentProviderType.Gmo}>GMOを利用する</Radio>
      </Space>
    </Radio.Group>
  </EditDiniiPayConfigFormItem>
));
