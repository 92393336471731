import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";

import { EditDiniiPayConfigFormValues } from "./EditDiniiPayConfigForm/useEditDiniiPayConfigForm";
import { EditDiniiPayConfigForm } from "./EditDiniiPayConfigForm";
import {
  useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery,
  useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery,
  useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery,
  useEditDiniiPayConfigGetGmoShopConfigDetailQuery,
  useEditDiniiPayConfigGetShopQuery,
  useEditDiniiPayConfigUpsertDiniiPayConfigMutation,
} from "./queries";

export const EditDiniiPayConfig = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const { id: shopId } = useParams<{ id: string }>();

  const {
    data: getShopData,
    loading: loadingShop,
    error: getShopError,
  } = useEditDiniiPayConfigGetShopQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shop = useMemo(() => getShopData?.shop?.[0], [getShopData]);

  const {
    data: getGmoShopConfigDetailData,
    loading: loadingGetGmoShopConfigDetail,
    error: getGmoShopConfigDataDetailError,
  } = useEditDiniiPayConfigGetGmoShopConfigDetailQuery(
    shopId ? { variables: { input: { shopId } } } : { skip: true },
  );
  const gmoShopConfigDetail = getGmoShopConfigDetailData?.onlinePaymentRoot?.gmoShopConfigDetail;

  const {
    data: getGmoOffPaymentConfigData,
    loading: loadingGetGmoOffPaymentConfig,
    error: getGmoOffPaymentConfigError,
  } = useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const gmoOffPaymentConfig = getGmoOffPaymentConfigData?.gmoOffPaymentShopConfig[0];

  const {
    data: getAdyenPaymentConfigData,
    loading: loadingGetAdyenPaymentConfig,
    error: getAdyenPaymentConfigError,
  } = useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const adyenPaymentConfig = getAdyenPaymentConfigData?.adyenPaymentShopConfig[0];

  const {
    data: getAdyenTerminalPaymentConfigData,
    loading: loadingGetAdyenTerminalPaymentConfig,
    error: getAdyenTerminalPaymentConfigError,
  } = useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const adyenTerminalPaymentConfig =
    getAdyenTerminalPaymentConfigData?.adyenTerminalPaymentShopConfig[0];

  const [updateDiniiPayConfigDetail, { loading: updatingDiniiPayConfigDetail }] =
    useEditDiniiPayConfigUpsertDiniiPayConfigMutation();

  const onSubmit = useCallback(
    async (values: EditDiniiPayConfigFormValues) => {
      if (!shopId) return;

      const { onlinePaymentConfig, terminalPaymentConfig } = values;
      try {
        await updateDiniiPayConfigDetail({
          variables: {
            input: {
              shopId,
              onlinePaymentConfig,
              terminalPaymentConfig,
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateDiniiPayConfigDetail],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const shouldShowError =
    getShopError ||
    getGmoShopConfigDataDetailError ||
    getAdyenPaymentConfigError ||
    getAdyenTerminalPaymentConfigError ||
    getGmoOffPaymentConfigError;
  const loading =
    loadingShop ||
    loadingGetGmoShopConfigDetail ||
    loadingGetAdyenPaymentConfig ||
    loadingGetAdyenTerminalPaymentConfig ||
    loadingGetGmoOffPaymentConfig;

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loading || !shopId ? (
        <Loading height={300} />
      ) : (
        <>
          {shouldShowError && (
            <Alert
              message="通信に失敗しました"
              type="error"
              description="ネットワーク環境を確認してください"
            />
          )}
          <EditDiniiPayConfigForm
            shopId={shopId}
            gmoShopConfigDetail={gmoShopConfigDetail ?? null}
            gmoOffPaymentConfig={gmoOffPaymentConfig ?? null}
            adyenPaymentConfig={adyenPaymentConfig ?? null}
            adyenTerminalPaymentConfig={adyenTerminalPaymentConfig ?? null}
            loading={updatingDiniiPayConfigDetail}
            onSubmit={onSubmit}
            onClose={goBack}
            onFormValidationError={onFormValidationError}
          />
        </>
      )}
    </DashboardLayout>
  );
};
