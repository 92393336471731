import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanMenusGetPlan = gql`
    query PlanMenusGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopId
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
      shopSideName
    }
    companyId
    description
    planId
    planName
    planTime
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;
export const PlanMenusGetMenus = gql`
    query PlanMenusGetMenus($categoryIds: [Int!]) {
  category(
    where: {categoryId: {_in: $categoryIds}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryMenus(
      where: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      category {
        categoryId
        name
        shopSideName
      }
      menu {
        description
        imageUrl
        menuId
        name
        openPrice
        price
        menuOptions(
          where: {option: {archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            name
            optionId
          }
        }
      }
    }
  }
}
    `;
export type PlanMenusGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PlanMenusGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'planId' | 'planName' | 'planTime'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'shopId'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
    ), planMenuCategories: Array<(
      { __typename?: 'planMenuCategory' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
      ) }
    )> }
  )> }
);

export type PlanMenusGetMenusQueryVariables = Types.Exact<{
  categoryIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type PlanMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'description' | 'imageUrl' | 'menuId' | 'name' | 'openPrice' | 'price'>
        & { menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'name' | 'optionId'>
          ) }
        )> }
      ) }
    )> }
  )> }
);


export const PlanMenusGetPlanDocument = gql`
    query PlanMenusGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopId
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
      shopSideName
    }
    companyId
    description
    planId
    planName
    planTime
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;

/**
 * __usePlanMenusGetPlanQuery__
 *
 * To run a query within a React component, call `usePlanMenusGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanMenusGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanMenusGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanMenusGetPlanQuery(baseOptions: Apollo.QueryHookOptions<PlanMenusGetPlanQuery, PlanMenusGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanMenusGetPlanQuery, PlanMenusGetPlanQueryVariables>(PlanMenusGetPlanDocument, options);
      }
export function usePlanMenusGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanMenusGetPlanQuery, PlanMenusGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanMenusGetPlanQuery, PlanMenusGetPlanQueryVariables>(PlanMenusGetPlanDocument, options);
        }
export type PlanMenusGetPlanQueryHookResult = ReturnType<typeof usePlanMenusGetPlanQuery>;
export type PlanMenusGetPlanLazyQueryHookResult = ReturnType<typeof usePlanMenusGetPlanLazyQuery>;
export type PlanMenusGetPlanQueryResult = Apollo.QueryResult<PlanMenusGetPlanQuery, PlanMenusGetPlanQueryVariables>;
export const PlanMenusGetMenusDocument = gql`
    query PlanMenusGetMenus($categoryIds: [Int!]) {
  category(
    where: {categoryId: {_in: $categoryIds}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryMenus(
      where: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      category {
        categoryId
        name
        shopSideName
      }
      menu {
        description
        imageUrl
        menuId
        name
        openPrice
        price
        menuOptions(
          where: {option: {archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            name
            optionId
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePlanMenusGetMenusQuery__
 *
 * To run a query within a React component, call `usePlanMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanMenusGetMenusQuery({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function usePlanMenusGetMenusQuery(baseOptions?: Apollo.QueryHookOptions<PlanMenusGetMenusQuery, PlanMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanMenusGetMenusQuery, PlanMenusGetMenusQueryVariables>(PlanMenusGetMenusDocument, options);
      }
export function usePlanMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanMenusGetMenusQuery, PlanMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanMenusGetMenusQuery, PlanMenusGetMenusQueryVariables>(PlanMenusGetMenusDocument, options);
        }
export type PlanMenusGetMenusQueryHookResult = ReturnType<typeof usePlanMenusGetMenusQuery>;
export type PlanMenusGetMenusLazyQueryHookResult = ReturnType<typeof usePlanMenusGetMenusLazyQuery>;
export type PlanMenusGetMenusQueryResult = Apollo.QueryResult<PlanMenusGetMenusQuery, PlanMenusGetMenusQueryVariables>;