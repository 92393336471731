import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { PlanGroup } from "pages/AddPlan/types";

import { AddPlanFormItem } from "../useAddPlanForm";

const { Option } = Select;

type Props = {
  planGroups: PlanGroup[];
} & Omit<FormItemProps, "children" | "name">;

export const PlanGroupField = memo<Props>(({ planGroups, ...props }) => (
  <AddPlanFormItem label="プラングループ" name="planGroupId" {...props}>
    <Select<number> placeholder="プラングループを選択してください">
      {planGroups.map(({ planGroupId, name }) => (
        <Option key={planGroupId} value={planGroupId}>
          {name}
        </Option>
      ))}
    </Select>
  </AddPlanFormItem>
));
