import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AppGetCompany = gql`
    query AppGetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    companyId
    name
    corporationId
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
      useKiosk
    }
  }
}
    `;
export const AppGetDashboardAccount = gql`
    query AppGetDashboardAccount($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    userName
    id
  }
}
    `;
export type AppGetCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'companyId' | 'name' | 'corporationId'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'name' | 'shopId' | 'useKiosk'>
    )> }
  )> }
);

export type AppGetDashboardAccountQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type AppGetDashboardAccountQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccount: Array<(
    { __typename?: 'dashboardAccount' }
    & Pick<Types.DashboardAccount, 'userName' | 'id'>
  )> }
);


export const AppGetCompanyDocument = gql`
    query AppGetCompany {
  company(where: {archivedAt: {_is_null: true}}) {
    id
    companyId
    name
    corporationId
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
      useKiosk
    }
  }
}
    `;

/**
 * __useAppGetCompanyQuery__
 *
 * To run a query within a React component, call `useAppGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppGetCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppGetCompanyQuery(baseOptions?: Apollo.QueryHookOptions<AppGetCompanyQuery, AppGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppGetCompanyQuery, AppGetCompanyQueryVariables>(AppGetCompanyDocument, options);
      }
export function useAppGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppGetCompanyQuery, AppGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppGetCompanyQuery, AppGetCompanyQueryVariables>(AppGetCompanyDocument, options);
        }
export type AppGetCompanyQueryHookResult = ReturnType<typeof useAppGetCompanyQuery>;
export type AppGetCompanyLazyQueryHookResult = ReturnType<typeof useAppGetCompanyLazyQuery>;
export type AppGetCompanyQueryResult = Apollo.QueryResult<AppGetCompanyQuery, AppGetCompanyQueryVariables>;
export const AppGetDashboardAccountDocument = gql`
    query AppGetDashboardAccount($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    userName
    id
  }
}
    `;

/**
 * __useAppGetDashboardAccountQuery__
 *
 * To run a query within a React component, call `useAppGetDashboardAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppGetDashboardAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppGetDashboardAccountQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAppGetDashboardAccountQuery(baseOptions: Apollo.QueryHookOptions<AppGetDashboardAccountQuery, AppGetDashboardAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppGetDashboardAccountQuery, AppGetDashboardAccountQueryVariables>(AppGetDashboardAccountDocument, options);
      }
export function useAppGetDashboardAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppGetDashboardAccountQuery, AppGetDashboardAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppGetDashboardAccountQuery, AppGetDashboardAccountQueryVariables>(AppGetDashboardAccountDocument, options);
        }
export type AppGetDashboardAccountQueryHookResult = ReturnType<typeof useAppGetDashboardAccountQuery>;
export type AppGetDashboardAccountLazyQueryHookResult = ReturnType<typeof useAppGetDashboardAccountLazyQuery>;
export type AppGetDashboardAccountQueryResult = Apollo.QueryResult<AppGetDashboardAccountQuery, AppGetDashboardAccountQueryVariables>;