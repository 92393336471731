import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";

import { GmoBankAccountTable } from "./GmoBankAccountTable";
import { useGmoBankAccountGetGmoBankAccountsQuery } from "./queries";

export const GmoBankAccount = () => {
  const { data: gmoBankAccountsData, loading: loadingGmoBankAccounts } =
    useGmoBankAccountGetGmoBankAccountsQuery();

  const gmoBankAccounts = useMemo(
    () => gmoBankAccountsData?.gmoBankAccount ?? [],
    [gmoBankAccountsData],
  );

  const loading = loadingGmoBankAccounts;

  return (
    <DashboardLayout title="法人口座設定">
      <PageHeader
        title="法人口座設定"
        extra={
          <Link to="/gmoBankAccount/add">
            <Button type="primary">新規作成</Button>
          </Link>
        }
      />
      <GmoBankAccountTable gmoBankAccounts={gmoBankAccounts} loading={loading} />
    </DashboardLayout>
  );
};
