import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanCategoriesGetPlan = gql`
    query PlanCategoriesGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      id
      categoryId
      name
      shopSideName
    }
    companyId
    description
    planId
    planName
    planTime
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;
export const PlanCategoriesDeletePlanMenuCategory = gql`
    mutation PlanCategoriesDeletePlanMenuCategory($planId: Int!, $categoryId: Int!) {
  delete_planMenuCategory(
    where: {_planId: {_eq: $planId}, _categoryId: {_eq: $categoryId}}
  ) {
    affected_rows
    returning {
      categoryId: _categoryId
      planId: _planId
    }
  }
}
    `;
export type PlanCategoriesGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PlanCategoriesGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'planId' | 'planName' | 'planTime'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'id' | 'categoryId' | 'name' | 'shopSideName'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, planMenuCategories: Array<(
      { __typename?: 'planMenuCategory' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
      ) }
    )> }
  )> }
);

export type PlanCategoriesDeletePlanMenuCategoryMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  categoryId: Types.Scalars['Int'];
}>;


export type PlanCategoriesDeletePlanMenuCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { delete_planMenuCategory?: Types.Maybe<(
    { __typename?: 'planMenuCategory_mutation_response' }
    & Pick<Types.PlanMenuCategoryMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planMenuCategory' }
      & { categoryId: Types.PlanMenuCategory['_categoryId'], planId: Types.PlanMenuCategory['_planId'] }
    )> }
  )> }
);


export const PlanCategoriesGetPlanDocument = gql`
    query PlanCategoriesGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      id
      categoryId
      name
      shopSideName
    }
    companyId
    description
    planId
    planName
    planTime
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;

/**
 * __usePlanCategoriesGetPlanQuery__
 *
 * To run a query within a React component, call `usePlanCategoriesGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanCategoriesGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanCategoriesGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanCategoriesGetPlanQuery(baseOptions: Apollo.QueryHookOptions<PlanCategoriesGetPlanQuery, PlanCategoriesGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanCategoriesGetPlanQuery, PlanCategoriesGetPlanQueryVariables>(PlanCategoriesGetPlanDocument, options);
      }
export function usePlanCategoriesGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanCategoriesGetPlanQuery, PlanCategoriesGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanCategoriesGetPlanQuery, PlanCategoriesGetPlanQueryVariables>(PlanCategoriesGetPlanDocument, options);
        }
export type PlanCategoriesGetPlanQueryHookResult = ReturnType<typeof usePlanCategoriesGetPlanQuery>;
export type PlanCategoriesGetPlanLazyQueryHookResult = ReturnType<typeof usePlanCategoriesGetPlanLazyQuery>;
export type PlanCategoriesGetPlanQueryResult = Apollo.QueryResult<PlanCategoriesGetPlanQuery, PlanCategoriesGetPlanQueryVariables>;
export const PlanCategoriesDeletePlanMenuCategoryDocument = gql`
    mutation PlanCategoriesDeletePlanMenuCategory($planId: Int!, $categoryId: Int!) {
  delete_planMenuCategory(
    where: {_planId: {_eq: $planId}, _categoryId: {_eq: $categoryId}}
  ) {
    affected_rows
    returning {
      categoryId: _categoryId
      planId: _planId
    }
  }
}
    `;
export type PlanCategoriesDeletePlanMenuCategoryMutationFn = Apollo.MutationFunction<PlanCategoriesDeletePlanMenuCategoryMutation, PlanCategoriesDeletePlanMenuCategoryMutationVariables>;

/**
 * __usePlanCategoriesDeletePlanMenuCategoryMutation__
 *
 * To run a mutation, you first call `usePlanCategoriesDeletePlanMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanCategoriesDeletePlanMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planCategoriesDeletePlanMenuCategoryMutation, { data, loading, error }] = usePlanCategoriesDeletePlanMenuCategoryMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function usePlanCategoriesDeletePlanMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<PlanCategoriesDeletePlanMenuCategoryMutation, PlanCategoriesDeletePlanMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanCategoriesDeletePlanMenuCategoryMutation, PlanCategoriesDeletePlanMenuCategoryMutationVariables>(PlanCategoriesDeletePlanMenuCategoryDocument, options);
      }
export type PlanCategoriesDeletePlanMenuCategoryMutationHookResult = ReturnType<typeof usePlanCategoriesDeletePlanMenuCategoryMutation>;
export type PlanCategoriesDeletePlanMenuCategoryMutationResult = Apollo.MutationResult<PlanCategoriesDeletePlanMenuCategoryMutation>;
export type PlanCategoriesDeletePlanMenuCategoryMutationOptions = Apollo.BaseMutationOptions<PlanCategoriesDeletePlanMenuCategoryMutation, PlanCategoriesDeletePlanMenuCategoryMutationVariables>;