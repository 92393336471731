import { UnreachableError } from "libs/unreachable";

import { streamedResponseFetch } from "./streamedResponseFetch";

type ProgressPayload = { percentage: number };

type ErrorPayload = { message: string };

type PayloadType<T> =
  | {
      progress: ProgressPayload;
    }
  | { error: ErrorPayload }
  | { data: { value: T } };

type Params = {
  url: string;
  init: RequestInit;
  onError: (error: ErrorPayload) => void;
  onProgress: (progress: ProgressPayload) => void;
};

/**
 * Send a request to the backend using fetch and receive progress percentage updates.
 * - Handle progress changes by supplying `onProgress` callback
 * - Returns a promise of the supplied type
 * - Backend Implementation is in [createProgressStream](https://github.com/dinii-inc/dinii-self-all/blob/0a2f3f208431549f79aecf20b9ba7b1280fb8d98/packages/dinii-self-backend/src/lib/createProgressStream.ts#L24)
 */
export const progressStreamFetch = <T>({ url, init, onError, onProgress }: Params) =>
  new Promise<T>((resolve, reject) => {
    try {
      const onJsonObjectReceived = (rawData: object) => {
        const data = rawData as PayloadType<T>;
        if ("progress" in data) {
          onProgress(data.progress);
        } else if ("error" in data) {
          onError(data.error);
        } else if ("data" in data) {
          resolve(data.data.value);
        } else {
          throw new UnreachableError(data);
        }
      };

      streamedResponseFetch({ url, init, onJsonObjectReceived, onError: reject });
    } catch (err) {
      reject(err);
    }
  });
