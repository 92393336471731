import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditBankDepositAmountModalUpdateBankDepositAmount = gql`
    mutation EditBankDepositAmountModalUpdateBankDepositAmount($input: UpdateBankDepositAmountInput!) {
  dashboardAccountUpdateBankDepositAmount(input: $input)
}
    `;
export type EditBankDepositAmountModalUpdateBankDepositAmountMutationVariables = Types.Exact<{
  input: Types.UpdateBankDepositAmountInput;
}>;


export type EditBankDepositAmountModalUpdateBankDepositAmountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountUpdateBankDepositAmount'>
);


export const EditBankDepositAmountModalUpdateBankDepositAmountDocument = gql`
    mutation EditBankDepositAmountModalUpdateBankDepositAmount($input: UpdateBankDepositAmountInput!) {
  dashboardAccountUpdateBankDepositAmount(input: $input)
}
    `;
export type EditBankDepositAmountModalUpdateBankDepositAmountMutationFn = Apollo.MutationFunction<EditBankDepositAmountModalUpdateBankDepositAmountMutation, EditBankDepositAmountModalUpdateBankDepositAmountMutationVariables>;

/**
 * __useEditBankDepositAmountModalUpdateBankDepositAmountMutation__
 *
 * To run a mutation, you first call `useEditBankDepositAmountModalUpdateBankDepositAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBankDepositAmountModalUpdateBankDepositAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBankDepositAmountModalUpdateBankDepositAmountMutation, { data, loading, error }] = useEditBankDepositAmountModalUpdateBankDepositAmountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBankDepositAmountModalUpdateBankDepositAmountMutation(baseOptions?: Apollo.MutationHookOptions<EditBankDepositAmountModalUpdateBankDepositAmountMutation, EditBankDepositAmountModalUpdateBankDepositAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditBankDepositAmountModalUpdateBankDepositAmountMutation, EditBankDepositAmountModalUpdateBankDepositAmountMutationVariables>(EditBankDepositAmountModalUpdateBankDepositAmountDocument, options);
      }
export type EditBankDepositAmountModalUpdateBankDepositAmountMutationHookResult = ReturnType<typeof useEditBankDepositAmountModalUpdateBankDepositAmountMutation>;
export type EditBankDepositAmountModalUpdateBankDepositAmountMutationResult = Apollo.MutationResult<EditBankDepositAmountModalUpdateBankDepositAmountMutation>;
export type EditBankDepositAmountModalUpdateBankDepositAmountMutationOptions = Apollo.BaseMutationOptions<EditBankDepositAmountModalUpdateBankDepositAmountMutation, EditBankDepositAmountModalUpdateBankDepositAmountMutationVariables>;