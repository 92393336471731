import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { sum } from "util/array";

import { CumulativeCountDisplay } from "components/CumulativeCountDisplay";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { LineOfficialAccountSummaryTable } from "pages/LineOfficialAccounts/LineOfficialAccountSummaryTable";
import { useLineOfficialAccountSummariesQuery } from "pages/LineOfficialAccounts/queries";

export const LineOfficialAccounts = memo(() => {
  const { canAccess } = useCanAccess();
  const [company] = useCompany();
  const _companyId = company?.companyId;

  // NOTE: fetchPolicy: "cache-first" にして、 60 リクエスト / 時の Limit をなるべく超えないよう対策
  const { data, loading, error } = useLineOfficialAccountSummariesQuery(
    _companyId ? { variables: { _companyId }, fetchPolicy: "cache-first" } : { skip: true },
  );

  const lineOfficialAccountSummaries = useMemo(
    () =>
      data?.lineOfficialAccountSummaries.map((lineOfficialAccount) => ({
        ...lineOfficialAccount,
        followers: lineOfficialAccount.followers ?? undefined,
        blocks: lineOfficialAccount.blocks ?? undefined,
        targetedReaches: lineOfficialAccount.targetedReaches ?? undefined,
        pictureUrl: lineOfficialAccount.pictureUrl ?? undefined,
        quotaLimit: lineOfficialAccount.quotaLimit ?? undefined,
      })) ?? [],
    [data?.lineOfficialAccountSummaries],
  );

  const cumulativeLineFollowersCount = useMemo(
    () => sum(lineOfficialAccountSummaries.map((summary) => summary.followers ?? 0)),
    [lineOfficialAccountSummaries],
  );

  return (
    <DashboardLayout title="LINE公式アカウント一覧">
      <PageHeader
        title="LINE公式アカウント一覧"
        extra={[
          canAccess("addLineOfficialAccount") && (
            <Link key="add" to="/lineOfficialAccounts/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spacer size={32} />
      <CumulativeCountDisplay
        title="累計友だち数"
        cumulativeCount={cumulativeLineFollowersCount}
        isLoading={loading}
        unitName="ID"
      />
      <Spacer size={32} />
      <LineOfficialAccountSummaryTable
        loading={loading}
        lineOfficialAccountSummaries={lineOfficialAccountSummaries}
      />
    </DashboardLayout>
  );
});
