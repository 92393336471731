import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalOnSitePaymentDetailTypesGetTypes = gql`
    query FoodingJournalOnSitePaymentDetailTypesGetTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    label
    foodingJournalOnSitePaymentDetailType {
      id
      paymentType
      code
      name
    }
  }
}
    `;
export const FoodingJournalOnSitePaymentDetailTypeUpsertTypes = gql`
    mutation FoodingJournalOnSitePaymentDetailTypeUpsertTypes($input: [foodingJournalOnSitePaymentDetailType_insert_input!]!) {
  insert_foodingJournalOnSitePaymentDetailType(
    objects: $input
    on_conflict: {constraint: foodingJournalOnSitePaymentDetailType_onSitePaymentDetailTypeId, update_columns: [paymentType, name, code]}
  ) {
    affected_rows
  }
}
    `;
export const FoodingJournalOnSitePaymentDetailTypesDeleteType = gql`
    mutation FoodingJournalOnSitePaymentDetailTypesDeleteType($foodingJournalOnSitePaymentDetailTypeId: uuid!) {
  delete_foodingJournalOnSitePaymentDetailType(
    where: {id: {_eq: $foodingJournalOnSitePaymentDetailTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalOnSitePaymentDetailTypesGetTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'label'>
    & { foodingJournalOnSitePaymentDetailType?: Types.Maybe<(
      { __typename?: 'foodingJournalOnSitePaymentDetailType' }
      & Pick<Types.FoodingJournalOnSitePaymentDetailType, 'id' | 'paymentType' | 'code' | 'name'>
    )> }
  )> }
);

export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationVariables = Types.Exact<{
  input: Array<Types.FoodingJournalOnSitePaymentDetailTypeInsertInput> | Types.FoodingJournalOnSitePaymentDetailTypeInsertInput;
}>;


export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'foodingJournalOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.FoodingJournalOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);

export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationVariables = Types.Exact<{
  foodingJournalOnSitePaymentDetailTypeId: Types.Scalars['uuid'];
}>;


export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'foodingJournalOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.FoodingJournalOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);


export const FoodingJournalOnSitePaymentDetailTypesGetTypesDocument = gql`
    query FoodingJournalOnSitePaymentDetailTypesGetTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    label
    foodingJournalOnSitePaymentDetailType {
      id
      paymentType
      code
      name
    }
  }
}
    `;

/**
 * __useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery__
 *
 * To run a query within a React component, call `useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalOnSitePaymentDetailTypesGetTypesQuery, FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalOnSitePaymentDetailTypesGetTypesQuery, FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables>(FoodingJournalOnSitePaymentDetailTypesGetTypesDocument, options);
      }
export function useFoodingJournalOnSitePaymentDetailTypesGetTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalOnSitePaymentDetailTypesGetTypesQuery, FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalOnSitePaymentDetailTypesGetTypesQuery, FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables>(FoodingJournalOnSitePaymentDetailTypesGetTypesDocument, options);
        }
export type FoodingJournalOnSitePaymentDetailTypesGetTypesQueryHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery>;
export type FoodingJournalOnSitePaymentDetailTypesGetTypesLazyQueryHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDetailTypesGetTypesLazyQuery>;
export type FoodingJournalOnSitePaymentDetailTypesGetTypesQueryResult = Apollo.QueryResult<FoodingJournalOnSitePaymentDetailTypesGetTypesQuery, FoodingJournalOnSitePaymentDetailTypesGetTypesQueryVariables>;
export const FoodingJournalOnSitePaymentDetailTypeUpsertTypesDocument = gql`
    mutation FoodingJournalOnSitePaymentDetailTypeUpsertTypes($input: [foodingJournalOnSitePaymentDetailType_insert_input!]!) {
  insert_foodingJournalOnSitePaymentDetailType(
    objects: $input
    on_conflict: {constraint: foodingJournalOnSitePaymentDetailType_onSitePaymentDetailTypeId, update_columns: [paymentType, name, code]}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationFn = Apollo.MutationFunction<FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationVariables>;

/**
 * __useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation__
 *
 * To run a mutation, you first call `useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalOnSitePaymentDetailTypeUpsertTypesMutation, { data, loading, error }] = useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationVariables>(FoodingJournalOnSitePaymentDetailTypeUpsertTypesDocument, options);
      }
export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation>;
export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationResult = Apollo.MutationResult<FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation>;
export type FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationOptions = Apollo.BaseMutationOptions<FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDetailTypeUpsertTypesMutationVariables>;
export const FoodingJournalOnSitePaymentDetailTypesDeleteTypeDocument = gql`
    mutation FoodingJournalOnSitePaymentDetailTypesDeleteType($foodingJournalOnSitePaymentDetailTypeId: uuid!) {
  delete_foodingJournalOnSitePaymentDetailType(
    where: {id: {_eq: $foodingJournalOnSitePaymentDetailTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationFn = Apollo.MutationFunction<FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationVariables>;

/**
 * __useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation__
 *
 * To run a mutation, you first call `useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalOnSitePaymentDetailTypesDeleteTypeMutation, { data, loading, error }] = useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation({
 *   variables: {
 *      foodingJournalOnSitePaymentDetailTypeId: // value for 'foodingJournalOnSitePaymentDetailTypeId'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationVariables>(FoodingJournalOnSitePaymentDetailTypesDeleteTypeDocument, options);
      }
export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation>;
export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationResult = Apollo.MutationResult<FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation>;
export type FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationOptions = Apollo.BaseMutationOptions<FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDetailTypesDeleteTypeMutationVariables>;