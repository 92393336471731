import React, { memo } from "react";
import { Button, Typography } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { FileUploadField } from "./FileUploadField";
import { InputShopDataFormValues, useInputShopDataForm } from "./useInputShopDataForm";

type Props = {
  selectedCompany: string | undefined;
  downloadTemplate: () => Promise<void>;
  onSubmit: (args: InputShopDataFormValues) => void;
  submitting: boolean;
};

export const InputShopDataForm = memo<Props>(
  ({ selectedCompany, downloadTemplate, onSubmit, submitting }) => {
    const { form, submit } = useInputShopDataForm({ onSubmit });

    return (
      <>
        <FormContent>
          <Typography.Paragraph>
            対象法人: <strong>{selectedCompany}</strong>
          </Typography.Paragraph>
          <ShopSelector />
          <Typography.Paragraph>
            <ul>
              <li>
                <Typography.Link
                  href="https://www.notion.so/diniinote/44a44c7ff3fb4017b52c070e576b665c"
                  target="_blank"
                >
                  作業マニュアル
                </Typography.Link>
              </li>
            </ul>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Button onClick={downloadTemplate}>テンプレートをダウンロード</Button>
          </Typography.Paragraph>
          <Form name="inputShopData" form={form}>
            <FileUploadField title="table.csv" name="table" />
            <FileUploadField title="dish_up_slip_group.csv" name="dishUpSlipGroup" />
            <FileUploadField title="role.csv" name="role" />
            <FileUploadField title="shop_menu.csv" name="shopMenu" />
            <FileUploadField title="shop_plan.csv" name="shopPlan" />
            <FileUploadField title="shop_orderable_time_term.csv" name="shopOrderableTimeTerm" />
            <FileUploadField title="cooking_item.csv" name="cookingItem" />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={submitting}>
            アップロード
          </Button>
        </FormActions>
      </>
    );
  },
);
