import React, { memo } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { Typography } from "antd";

import Loading from "../../../assets/animations/loading.json";

type Props = {
  number: number;
  loading?: boolean;
  remark?: string;
};

const LottieContainer = styled.div`
  display: inline-block;
  justify-content: "center";
  width: 24px;
`;

const StyledText = styled(Typography.Text)`
  font-size: 12px;
`;

export const TargetCustomers = memo<Props>(({ number, loading, remark }) => (
  <>
    <Typography.Title level={5} style={{ margin: 0, display: "flex" }}>
      現時点の対象者数：
      {loading ? (
        <LottieContainer>
          <Lottie options={{ animationData: Loading }} speed={1.5} />
        </LottieContainer>
      ) : (
        number.toLocaleString()
      )}
    </Typography.Title>
    {Boolean(remark) && <StyledText>※ {remark}</StyledText>}
  </>
));
