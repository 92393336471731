import React, { memo, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: #ff4d4f;
`;

export const MessageDeliveryMenu = memo<{ showErrorIcon?: boolean }>(({ showErrorIcon }) => {
  const { state } = useParams<{ state?: string }>();
  const { pathname } = useLocation();

  const key = pathname.split("/")[2];
  const selectedKey = state ?? key;
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <Menu selectedKeys={selectedKeys} mode="horizontal">
      <Menu.Item key="reserved">
        <Link to="/messageDelivery/reserved" replace>
          予約中
        </Link>
      </Menu.Item>
      <Menu.Item key="draft">
        <Link to="/messageDelivery/draft" replace>
          下書き
        </Link>
      </Menu.Item>
      <Menu.Item key="suspended">
        <Link to="/messageDelivery/suspended" replace>
          停止中
        </Link>
      </Menu.Item>
      <Menu.Item key="job">
        <Link to="/messageDelivery/job" replace>
          配信履歴
          <Spacer inline width={4} />
          {showErrorIcon && <StyledExclamationCircleOutlined />}
        </Link>
      </Menu.Item>
    </Menu>
  );
});
