import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Card, Col, Row, Space } from "antd";

import TableUser from "assets/table_user.svg";
import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { colors, oldColors } from "constants/colors";

import {
  AddConnectGameConfigFormItem,
  MaxWinningPercentageType,
} from "../useAddConnectGameConfigForm";

const usersCount = 4;
const usersCountArray = Array.from({ length: usersCount }, (_, i) => i);

const StyledCard = styled(Card).attrs({
  bodyStyle: { padding: 0 },
})`
  background-color: #f0f2f5;
  border-radius: 6px;
  padding: 12px 16px;
  width: 420px;
`;

const Chip = styled.div<{ color: "red" | "blue"; large?: boolean }>`
  display: inline-block;
  border-radius: 6px;
  font-size: 14px;
  ${({ color }) =>
    color === "red"
      ? css`
          background-color: #fff0f6;
          border: 1px solid #ff4d4f;
          color: #ff4d4f;
        `
      : color === "blue"
      ? css`
          background-color: ${colors.BackGround.PrimarySecondary};
          border: 1px solid #1890ff;
          color: #1890ff;
        `
      : css``}
  ${({ large }) =>
    large
      ? css`
          font-weight: bold;
          padding: 6px 10px;
        `
      : css`
          font-weight: normal;
          padding: 2px 6px;
        `}
`;

const NormalText = styled.p`
  font-weight: normal;
  font-size: 14px;
  margin: 0;
`;

const BoldText = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

const ActiveTableUser = styled(TableUser)`
  fill: #1890ff;
`;

const InactiveTableUser = styled(TableUser)`
  fill: ${oldColors.BaseGrey};
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

export const WinningPercentageCard = memo(() => (
  <AddConnectGameConfigFormItem.NonProperty
    noStyle
    shouldUpdate={withFormDependencies(
      ({ maxWinningPercentageType, selectedMaxWinningPercentage, enteredMaxWinningPercentage }) => [
        maxWinningPercentageType,
        selectedMaxWinningPercentage,
        enteredMaxWinningPercentage,
      ],
    )}
  >
    {({ getFieldValue }) => {
      const maxWinningPercentageType = getFieldValue(
        "maxWinningPercentageType",
      ) as MaxWinningPercentageType;
      const maxWinningPercentage =
        maxWinningPercentageType === "select"
          ? getFieldValue("selectedMaxWinningPercentage") ?? 0
          : maxWinningPercentageType === "custom"
          ? getFieldValue("enteredMaxWinningPercentage") ?? 0
          : 0;
      return (
        <StyledCard>
          <NormalText>{maxWinningPercentage}%に設定した場合</NormalText>
          <Row align="middle" gutter={4}>
            <Col>
              <Chip color="red" large>
                {maxWinningPercentage}%
              </Chip>
            </Col>
            <Col>
              <BoldText>×</BoldText>
            </Col>
            <Col>
              <Chip color="blue" large>
                チェックイン率
              </Chip>
            </Col>
            <Col>
              <BoldText>= 当選確率</BoldText>
            </Col>
          </Row>
          <Spacer size={16} />
          <StyledSpace direction="vertical" size={12}>
            {usersCountArray.map((i) => (
              <Row key={i} gutter={24} justify="space-between">
                <Col span={10}>
                  <Row gutter={8}>
                    {usersCountArray.map((j) => (
                      <Col key={j} span={6}>
                        {i + j < usersCount ? <ActiveTableUser /> : <InactiveTableUser />}
                      </Col>
                    ))}
                  </Row>
                  <Row gutter={4} align="middle">
                    <Col>
                      <Chip color="blue">
                        {usersCount - i}/{usersCount}人
                      </Chip>
                    </Col>
                    <Col>
                      <NormalText>がチェックイン</NormalText>
                    </Col>
                  </Row>
                </Col>
                <Col span={14}>
                  <Row align="middle" gutter={4}>
                    <Col>
                      <Chip color="red">{maxWinningPercentage}%</Chip>
                    </Col>
                    <Col>
                      <NormalText>&times;</NormalText>
                    </Col>
                    <Col>
                      <Chip color="blue">
                        {usersCount - i}/{usersCount}人
                      </Chip>
                    </Col>
                    <Col>
                      <NormalText>
                        = {maxWinningPercentage * ((usersCount - i) / usersCount)}%
                      </NormalText>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
          </StyledSpace>
        </StyledCard>
      );
    }}
  </AddConnectGameConfigFormItem.NonProperty>
));
