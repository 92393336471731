import React, { memo } from "react";
import { Alert, Button, Modal } from "antd";

import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useShopSelect } from "hooks/useShopSelect";

import { useDownloadQrCodes } from "./useDownloadQrCodes";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const DownloadQrCodesModal = memo<Props>(({ visible, onClose }) => {
  const { currentShop } = useShopSelect();

  const { downloadQrCodes, loading } = useDownloadQrCodes();

  const handleDownload = async () => {
    await downloadQrCodes();
    onClose();
  };

  return (
    <Modal
      title="QRコードダウンロード"
      width={800}
      open={visible}
      footer={[
        <Button key="cancel" onClick={onClose}>
          キャンセル
        </Button>,
        <Button
          key="download"
          type="primary"
          loading={loading}
          disabled={!currentShop}
          onClick={handleDownload}
        >
          ダウンロード
        </Button>,
      ]}
      onCancel={onClose}
    >
      <Alert
        message="【QRコードの注意事項】"
        description={
          <ul>
            <li>お客様がチェックインできるQRコードがダウンロードできます</li>
            <li>ダウンロードされたファイルは圧縮ファイル（.zip）で保存されます</li>
            <li>店舗に選択されているすべてのテーブルQRコード画像が保存されます</li>
          </ul>
        }
        type="info"
      />

      <Spacer size={32} />

      <ShopSelector />
    </Modal>
  );
});
