import React, { memo, useCallback } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { FilterConditions } from "models/onSitePayment";
import { RangeValue } from "rc-picker/lib/interface";

import type { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";

const { RangePicker } = DatePicker;

type Props = {
  filterConditions: FilterConditions;
  onChange: UpdateFilterConditionFunctionType<FilterConditions>;
};

const getDate = (value: dayjs.Dayjs | null) =>
  value?.hour(0).minute(0).second(0).millisecond(0) ?? null;

export const SalesFilter = memo<Props>(({ filterConditions, onChange }) => {
  const handleChange = useCallback(
    (values: RangeValue<dayjs.Dayjs>) =>
      onChange(
        values
          ? {
              range: [getDate(values[0]) ?? null, getDate(values[1]) ?? null],
            }
          : { range: undefined },
      ),
    [onChange],
  );

  const disabledDate = useCallback(
    (current: dayjs.Dayjs | null) => {
      if (!filterConditions.range || filterConditions.range.every((r) => r === null)) {
        return false;
      }
      const tooLate =
        current &&
        filterConditions.range[0] &&
        current.diff(filterConditions.range[0], "month") > 1;
      const tooEarly =
        current &&
        filterConditions.range[1] &&
        filterConditions.range[1].diff(current, "month") > 1;
      return Boolean(tooEarly) || Boolean(tooLate);
    },
    [filterConditions],
  );

  const { rangePresets } = useRangePresets();

  return (
    <RangePicker
      value={filterConditions.range}
      ranges={rangePresets}
      onCalendarChange={handleChange}
      disabledDate={disabledDate}
      renderExtraFooter={() => "※ 集計期間は最大2ヶ月です"}
    />
  );
});
