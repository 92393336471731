import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { InputCostPrice } from "components/Input/InputCostPrice";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CostPriceField = memo<Props>((props) => (
  <AddMenuFormItem
    label={<FormHelp label="原価" help="原価は小数点以下二桁まで設定できます" />}
    name="costPrice"
    rules={[{ required: true, message: "原価を入力してください" }]}
    {...props}
  >
    <InputCostPrice step={0.01} precision={2} />
  </AddMenuFormItem>
));
