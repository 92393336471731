import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import {
  ExternalOnlineMenuAvailableTimeTermInsertInput,
  ExternalOnlineMenuSetInput,
} from "types/graphql";

import { EditShopExternalOnlineMenuForm } from "./EditShopExternalOnlineMenuForm";
import {
  useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery,
  useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation,
} from "./queries";

export const EditShopExternalOnlineMenu = () => {
  const navigate = useNavigate();
  const { shopId } = useParams<{ shopId: string }>();
  const { externalOnlineMenuId } = useParams<{ externalOnlineMenuId: string }>();

  const { data, error, loading } = useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery(
    shopId && externalOnlineMenuId
      ? { variables: { shopId, externalOnlineMenuId } }
      : { skip: true },
  );
  const changeDateTime = data?.shop_by_pk?.changeDateTime;
  const externalOnlineMenu = data?.externalOnlineMenu_by_pk;

  const [updateExternalOnlineMenuMutation, { loading: loadingUpdateExternalOnlineMenuMutation }] =
    useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation();

  const onSubmit = useCallback(
    async ({
      externalOnlineMenu,
      externalOnlineMenuAvailableTimeTerms,
    }: {
      externalOnlineMenu: ExternalOnlineMenuSetInput;
      externalOnlineMenuAvailableTimeTerms: Omit<
        ExternalOnlineMenuAvailableTimeTermInsertInput,
        "externalOnlineMenuId"
      >[];
    }) => {
      try {
        if (!externalOnlineMenuId || !shopId) return;
        await updateExternalOnlineMenuMutation({
          variables: {
            externalOnlineMenuId,
            externalOnlineMenu,
            externalOnlineMenuAvailableTimeTermIds: externalOnlineMenuAvailableTimeTerms
              .map((term) => term.id)
              .filter(isNotNullable),
            externalOnlineMenuAvailableTimeTerms: externalOnlineMenuAvailableTimeTerms.map(
              ({ id: _, ...externalOnlineMenuAvailableTimeTerm }) => ({
                ...externalOnlineMenuAvailableTimeTerm,
                externalOnlineMenuId,
              }),
            ),
          },
        });
        message.success("更新しました");

        navigate("/externalOnlineMenu", { replace: true });
      } catch (err) {
        message.error("更新に失敗しました");
      }
    },
    [updateExternalOnlineMenuMutation, externalOnlineMenuId, shopId, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("更新に失敗しました");
    },
    [],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="外部オンラインメニューを編集"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "外部オンラインメニュー" }],
      }}
    >
      <PageHeader title="外部オンラインメニューを編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {changeDateTime && externalOnlineMenu && (
        <EditShopExternalOnlineMenuForm
          externalOnlineMenu={externalOnlineMenu}
          changeDateTime={changeDateTime}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          onCancel={goBack}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
