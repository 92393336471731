import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";

import { ConnectGameConfigsTable } from "./ConnectGameConfigsTable";
import { useConnectGameConfigsGetConnectGameConfigsQuery } from "./queries";

export const ConnectGameConfigs = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data, error, loading } = useConnectGameConfigsGetConnectGameConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const connectGameConfigs = useMemo(() => data?.connectGameConfig ?? [], [data]);

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout>
      <PageHeader
        title="ミニゲーム"
        extra={[
          canAccess("addConnectGameConfig") && (
            <Link key="add" to="/connectGameConfig/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ConnectGameConfigsTable connectGameConfigs={connectGameConfigs} loading={loading} />
    </DashboardLayout>
  );
};
