import React, { useCallback, useMemo, useState } from "react";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { CopyMasterDataFormValues } from "./CopyMasterDataForm/useCopyMasterDataForm";
import { CopyMasterDataForm } from "./CopyMasterDataForm";
import {
  useCopyMasterDataCopyMasterDataMutation,
  useCopyMasterDataGetCompaniesQuery,
  useCopyMasterDataGetShopsQuery,
} from "./queries";

export const CopyMasterData = () => {
  const [company] = useCompany();

  const [formValues, setFormValues] = useState<CopyMasterDataFormValues | null>(null);

  const { data: sourceShopsData } = useCopyMasterDataGetShopsQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );
  const sourceShops = useMemo(() => sourceShopsData?.shop ?? [], [sourceShopsData?.shop]);

  const { data: companiesData } = useCopyMasterDataGetCompaniesQuery();
  const companies = useMemo(
    () => companiesData?.company?.filter(({ companyId }) => companyId !== company?.companyId) ?? [],
    [companiesData?.company, company?.companyId],
  );

  const { targetCompanyId } = formValues ?? {};
  const { data: targetShopsData } = useCopyMasterDataGetShopsQuery(
    targetCompanyId ? { variables: { companyId: targetCompanyId } } : { skip: true },
  );
  const targetShops = useMemo(() => targetShopsData?.shop ?? [], [targetShopsData?.shop]);

  const [copyMasterData, { loading }] = useCopyMasterDataCopyMasterDataMutation({
    onError(error) {
      message.error(error.message ?? "マスターデータコピーが失敗しました");
    },
    onCompleted() {
      message.success("マスターデータコピーが完了しました");
    },
  });

  const onSubmit = useCallback(
    ({ sourceShopId, targetShopId, enableCopyTable }: CopyMasterDataFormValues) => {
      copyMasterData({
        variables: {
          sourceShopId: sourceShopId ?? "",
          targetShopId: targetShopId ?? "",
          enableCopyTable,
        },
      });
    },
    [copyMasterData],
  );

  return (
    <DashboardLayout title="マスターデータをコピー">
      <PageHeader title="マスターデータをコピー" />
      <CopyMasterDataForm
        sourceShops={sourceShops}
        targetCompanies={companies}
        targetShops={targetShops}
        onSubmit={onSubmit}
        canSubmit={!loading}
        onChange={setFormValues}
      />
    </DashboardLayout>
  );
};
