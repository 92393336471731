import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { placeholder } from "../placeholder";
import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <AddCouponFormItem
    label="説明文"
    name="description"
    rules={[{ required: true, message: "クーポンの説明文を入力してください" }]}
    {...props}
  >
    <TextArea rows={4} placeholder={placeholder.description} />
  </AddCouponFormItem>
));
