import React, { memo, useMemo } from "react";
import { Radio, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RowSelectionType } from "antd/lib/table/interface";
import { getTaxMethodName } from "models/taxMethod";

import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";

import { Category, Menu } from "../../../types";

type Props = {
  value?: string | undefined;
  onChange?(menuId: string | undefined): void;
  menus: Menu[];
};

export const MenuTable = memo(({ menus, value, onChange }: Props) => {
  const [pagination, setPagination] = usePagination();

  const columns: ColumnsType<Menu> = useMemo(
    () => [
      {
        title: "画像",
        align: "center" as const,
        width: 100,
        render(_: string, { imageUrl }: Menu) {
          return <Thumbnail url={imageUrl} width={64} height={64} />;
        },
      },
      {
        title: "商品名",
        dataIndex: "name",
        width: 200,
      },
      {
        title: "カテゴリ名",
        render(_: string, { categoryMenus }: Menu) {
          return categoryMenus.map(({ category }: { category: Category }) => (
            <Tag key={category.categoryId}>{category.name}</Tag>
          ));
        },
      },
      {
        title: "価格",
        render(_: string, { openPrice, price, taxMethod }: Menu) {
          return openPrice
            ? "フリー"
            : `${price.toLocaleString()}円(${getTaxMethodName(taxMethod)})`;
        },
      },
    ],
    [],
  );

  const rowSelection = useMemo(
    () => ({
      type: "radio" as RowSelectionType,
      renderCell: (checked: boolean, menu: Menu, _index: number, originNode: React.ReactNode) => {
        if (!React.isValidElement(originNode) || !originNode.props) {
          return null;
        }

        return <Radio onClick={() => onChange?.(menu.id)} checked={value === menu.id} />;
      },
    }),
    [value, onChange],
  );

  return (
    <Table
      columns={columns}
      dataSource={menus}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      rowSelection={rowSelection}
    />
  );
});
