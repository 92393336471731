import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveryConsumptionGetShopLineOfficialAccount = gql`
    query MessageDeliveryConsumptionGetShopLineOfficialAccount($lineOfficialAccountId: Int!) {
  dashboardAccountGetMessageDeliveryConsumption(
    input: {lineOfficialAccountId: $lineOfficialAccountId}
  ) {
    messageDeliveryConsumption
    messageDeliveryLimit
  }
}
    `;
export type MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables = Types.Exact<{
  lineOfficialAccountId: Types.Scalars['Int'];
}>;


export type MessageDeliveryConsumptionGetShopLineOfficialAccountQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountGetMessageDeliveryConsumption: (
    { __typename?: 'MessageDeliveryConsumption' }
    & Pick<Types.MessageDeliveryConsumption, 'messageDeliveryConsumption' | 'messageDeliveryLimit'>
  ) }
);


export const MessageDeliveryConsumptionGetShopLineOfficialAccountDocument = gql`
    query MessageDeliveryConsumptionGetShopLineOfficialAccount($lineOfficialAccountId: Int!) {
  dashboardAccountGetMessageDeliveryConsumption(
    input: {lineOfficialAccountId: $lineOfficialAccountId}
  ) {
    messageDeliveryConsumption
    messageDeliveryLimit
  }
}
    `;

/**
 * __useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery({
 *   variables: {
 *      lineOfficialAccountId: // value for 'lineOfficialAccountId'
 *   },
 * });
 */
export function useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryConsumptionGetShopLineOfficialAccountQuery, MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryConsumptionGetShopLineOfficialAccountQuery, MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables>(MessageDeliveryConsumptionGetShopLineOfficialAccountDocument, options);
      }
export function useMessageDeliveryConsumptionGetShopLineOfficialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryConsumptionGetShopLineOfficialAccountQuery, MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryConsumptionGetShopLineOfficialAccountQuery, MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables>(MessageDeliveryConsumptionGetShopLineOfficialAccountDocument, options);
        }
export type MessageDeliveryConsumptionGetShopLineOfficialAccountQueryHookResult = ReturnType<typeof useMessageDeliveryConsumptionGetShopLineOfficialAccountQuery>;
export type MessageDeliveryConsumptionGetShopLineOfficialAccountLazyQueryHookResult = ReturnType<typeof useMessageDeliveryConsumptionGetShopLineOfficialAccountLazyQuery>;
export type MessageDeliveryConsumptionGetShopLineOfficialAccountQueryResult = Apollo.QueryResult<MessageDeliveryConsumptionGetShopLineOfficialAccountQuery, MessageDeliveryConsumptionGetShopLineOfficialAccountQueryVariables>;