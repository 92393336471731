import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { AddInflowSourceTagForm } from "./AddInflowSourceTagForm";
import {
  useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery,
  useAddInflowSourceTagInsertInflowSourceTagMutation,
} from "./queries";

export const AddInflowSourceTag = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: inflowSourceTagMaxPriorityData, refetch: refetchMaxPriority } =
    useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const inflowSourceTagMaxPriority =
    inflowSourceTagMaxPriorityData?.inflowSourceTag_aggregate.aggregate?.max?.priority ?? 0;

  const [addInflowSourceTag, { loading }] = useAddInflowSourceTagInsertInflowSourceTagMutation();

  const handleSubmit = useCallback(
    async ({ displayName }: { displayName: string }) => {
      if (!companyId) return;

      try {
        const priority = inflowSourceTagMaxPriority + 1;
        await addInflowSourceTag({
          variables: { inflowSourceTag: { companyId, displayName, priority } },
        });

        await refetchMaxPriority();

        message.success("作成しました");

        navigate("/inflowSourceTag", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [companyId, inflowSourceTagMaxPriority, addInflowSourceTag, navigate, refetchMaxPriority],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <DashboardLayout title="媒体情報を新規作成">
      <PageHeader title="媒体情報を新規作成" onBack={goBack} />
      <AddInflowSourceTagForm onSubmit={handleSubmit} loading={loading} />
    </DashboardLayout>
  );
};
