import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import { EditPlanTranslationsFormItem } from "./useEditPlanTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled: boolean };

export const DescriptionKrField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanTranslationsFormItem label="韓国語" name="descriptionKr" {...props}>
    <TextArea rows={2} disabled={disabled} />
  </EditPlanTranslationsFormItem>
));
