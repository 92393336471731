import dayjs from "dayjs";
import { normalizeHoursAndMinutes } from "util/time";

import { Hours } from "./constants";

const pxToTime = (px: number, changeDateTime: dayjs.Dayjs, heightPerHour: number) => {
  const hour = Math.floor(px / heightPerHour);
  const minute = Math.floor(((px % heightPerHour) / heightPerHour) * 60);
  const timeDiffByChangeTime = dayjs().hour(hour).minute(minute);
  const actualTime = timeDiffByChangeTime
    .add(changeDateTime.hour(), "hour")
    .add(changeDateTime.minute(), "minute");
  return normalizeHoursAndMinutes(actualTime);
};

const timeToPx = (
  time: dayjs.Dayjs,
  changeDateTime: dayjs.Dayjs,
  heightPerHour: number,
  options?: {
    isBaseEnd?: boolean;
  },
) => {
  if (options?.isBaseEnd && time.isSame(changeDateTime)) {
    return heightPerHour * Hours.length;
  }
  const timeDiffByChangeTime = time

    .subtract(changeDateTime.hour(), "hour")
    .subtract(changeDateTime.minute(), "minute");
  const hourPxHeight = timeDiffByChangeTime.hour() * heightPerHour;
  const minutePxHeight = (timeDiffByChangeTime.minute() * heightPerHour) / 60;
  return hourPxHeight + minutePxHeight;
};

export const positionToEvent = (
  position: { top: number; height: number },
  changeDateTime: dayjs.Dayjs,
  heightPerHour: number,
) => {
  const start = pxToTime(position.top, changeDateTime, heightPerHour);
  const end = pxToTime(position.top + position.height, changeDateTime, heightPerHour);

  return {
    start,
    end,
  };
};

export const eventToPosition = (
  event: { start: dayjs.Dayjs; end: dayjs.Dayjs },
  changeDateTime: dayjs.Dayjs,
  heightPerHour: number,
) => {
  const top = timeToPx(event.start, changeDateTime, heightPerHour);
  const end = timeToPx(event.end, changeDateTime, heightPerHour, { isBaseEnd: true });
  const height = end - top;

  return {
    top,
    height,
  };
};
