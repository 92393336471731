import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterPlansByName } from "models/plan";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, PlanFilter } from "./PlanFilter";
import {
  NonNullableFoodingJournalPlanSource,
  PlanTable,
  PlanWithFoodingJournalMenu,
} from "./PlanTable";
import {
  useFoodingJournalPlanDeletePlanMutation,
  useFoodingJournalPlansGetCategoriesQuery,
  useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery,
  useFoodingJournalPlansGetMastersQuery,
  useFoodingJournalPlansGetPlansQuery,
  useFoodingJournalPlansGetShopsQuery,
  useFoodingJournalPlansUpsertPlansMutation,
} from "./queries";
import { Plan } from "./types";

type FoodingJournalPlanInput = {
  companyId: string;
  code: string;
  name: string;
  foodingJournalDepartmentId: string;
  foodingJournalGroupId: string;
};

export const filterPlans = (
  plans: (PlanWithFoodingJournalMenu & { shopPlans: Plan["shopPlans"] })[],
  { categoryIds, name, shopId, showOnlyPlansWithoutCode }: FilterConditions,
) => {
  const filteredPlans = plans
    .filter(
      (plan) =>
        (categoryIds === undefined || categoryIds.includes(plan.category.categoryId)) &&
        (shopId === undefined || plan.shopPlans.some(({ shop }) => shop.shopId === shopId)) &&
        (showOnlyPlansWithoutCode ? !plan.code : true),
    )
    .map((filteredPlan) => ({
      ...filteredPlan,
      planName: filteredPlan.name,
    }));

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans;
};

export const FoodingJournalPlans = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const {
    data: getPlansData,
    loading: loadingPlans,
    error: getPlansDataError,
    refetch: refetchFoodingJournalPlans,
  } = useFoodingJournalPlansGetPlansQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const plans = useMemo<(PlanWithFoodingJournalMenu & { shopPlans: Plan["shopPlans"] })[]>(
    () =>
      (getPlansData?.plans ?? []).map((plan) => ({
        type: "plan",
        planId: plan.planId,
        category: plan.category,
        name: plan.name,
        foodingJournalMenuId: plan.foodingJournalMenu?.id,
        code: plan.foodingJournalMenu?.code,
        foodingJournalName: plan.foodingJournalMenu?.name,
        foodingJournalDepartmentId: plan.foodingJournalMenu?.foodingJournalDepartmentMaster.id,
        foodingJournalGroupId: plan.foodingJournalMenu?.foodingJournalGroupMaster.id,
        shopPlans: plan.shopPlans,
        planOptions: plan.planOptions,
        planChoices: plan.planOptions.flatMap((planOption) =>
          planOption.planChoices.map((planChoice) => ({
            type: "planChoice",
            planId: plan.planId,
            planOptionId: planOption.planOptionId,
            planChoiceId: planChoice.planChoiceId,
            category: plan.category,
            name: `${planOption.name} ${planChoice.name}`,
            foodingJournalMenuId: planChoice.foodingJournalMenu?.id,
            code: planChoice.foodingJournalMenu?.code,
            foodingJournalName: planChoice.foodingJournalMenu?.name,
            foodingJournalDepartmentId:
              planChoice.foodingJournalMenu?.foodingJournalDepartmentMaster.id,
            foodingJournalGroupId: planChoice.foodingJournalMenu?.foodingJournalGroupMaster.id,
          })),
        ),
      })),
    [getPlansData?.plans],
  );
  const filteredPlans = useMemo(
    () => filterPlans(plans, filterConditions),
    [plans, filterConditions],
  );

  const { data: getCategoriesData, error: getCategoriesDataError } =
    useFoodingJournalPlansGetCategoriesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = useFoodingJournalPlansGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const { data: getMastersData, error: getMastersError } = useFoodingJournalPlansGetMastersQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const foodingJournalDepartmentMasters = useMemo(
    () =>
      [...(getMastersData?.foodingJournalDepartmentMaster ?? [])].sort((a, b) =>
        a.code.localeCompare(b.code),
      ),
    [getMastersData?.foodingJournalDepartmentMaster],
  );
  const foodingJournalGroupMasters = useMemo(
    () =>
      [...(getMastersData?.foodingJournalGroupMaster ?? [])].sort((a, b) =>
        a.code.localeCompare(b.code),
      ),
    [getMastersData?.foodingJournalGroupMaster],
  );

  const { data: getMenusData } = useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menusAndPlans = useMemo(
    () => [
      ...plans.flatMap((plan) => [
        {
          code: plan.code,
          foodingJournalName: plan.foodingJournalName,
          foodingJournalMenuId: plan.foodingJournalMenuId,
        },
        ...plan.planChoices.map((planChoice) => ({
          code: planChoice.code,
          foodingJournalName: planChoice.foodingJournalName,
          foodingJournalMenuId: planChoice.foodingJournalMenuId,
        })),
      ]),
      ...(getMenusData?.foodingJournalMenu ?? []).map((plan) => ({
        code: plan.code,
        foodingJournalName: plan.name,
        foodingJournalMenuId: plan.id,
      })),
    ],
    [plans, getMenusData],
  );

  const [upsertPlans, { loading: upsertPlansLoading, error: upsertPlansError }] =
    useFoodingJournalPlansUpsertPlansMutation();

  const [deletePlanFunc, { loading: deletePlanLoading, error: deletePlanError }] =
    useFoodingJournalPlanDeletePlanMutation();

  const refetchPlans = useCallback(async () => {
    if (!companyId) return;

    await refetchFoodingJournalPlans({ companyId });
  }, [companyId, refetchFoodingJournalPlans]);

  const upsertCodes = useCallback(
    async ({
      planCodes,
      planChoiceCodes,
    }: {
      planCodes: (NonNullableFoodingJournalPlanSource & { planId: string })[];
      planChoiceCodes: (NonNullableFoodingJournalPlanSource & { planChoiceId: string })[];
    }) => {
      if (!companyId) return;

      try {
        await upsertPlans({
          variables: {
            plansInput: planCodes.map((code): FoodingJournalPlanInput & { planId: string } => ({
              companyId,
              planId: code.planId,
              code: code.code,
              name: code.foodingJournalName,
              foodingJournalDepartmentId: code.foodingJournalDepartmentId,
              foodingJournalGroupId: code.foodingJournalGroupId,
            })),
            planChoicesInput: planChoiceCodes.map(
              (code): FoodingJournalPlanInput & { planChoiceId: string } => ({
                companyId,
                planChoiceId: code.planChoiceId,
                code: code.code,
                name: code.foodingJournalName,
                foodingJournalDepartmentId: code.foodingJournalDepartmentId,
                foodingJournalGroupId: code.foodingJournalGroupId,
              }),
            ),
          },
        });

        message.success("更新に成功しました");
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [companyId, upsertPlans],
  );

  const deletePlan = useCallback(
    async ({ foodingJournalMenuId }: { foodingJournalMenuId: string }) => {
      try {
        await deletePlanFunc({
          variables: { foodingJournalMenuId },
        });

        message.success("設定の削除に成功しました");
      } catch (e) {
        message.error("設定の削除に失敗しました");
      }
    },
    [deletePlanFunc],
  );

  const shouldShowAlert =
    getMastersError ||
    getCategoriesDataError ||
    getPlansDataError ||
    getMastersError ||
    upsertPlansError ||
    deletePlanError;

  const loading = loadingPlans || upsertPlansLoading || deletePlanLoading;

  return (
    <DashboardLayout title="プラン一覧">
      <PageHeader
        title="プラン一覧"
        footer={
          <>
            <PlanFilter
              shops={shops}
              categories={categories}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />

      {shouldShowAlert && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />

          <Spacer height={20} />
        </>
      )}

      <PlanTable
        plans={filteredPlans}
        foodingJournalDepartmentMasters={foodingJournalDepartmentMasters}
        foodingJournalGroupMasters={foodingJournalGroupMasters}
        menusAndPlans={menusAndPlans}
        loading={loadingPlans}
        upsertCodes={upsertCodes}
        refetchPlans={refetchPlans}
        deleteFoodingJournalPlan={deletePlan}
      />
    </DashboardLayout>
  );
};
