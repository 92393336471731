import React, { memo } from "react";
import { NamePath } from "antd/es/form/interface";

import { FormList } from "components/antd/Form";
import { locales } from "constants/locales";

import { DescriptionField } from "./DescriptionField";
import { OriginalDescriptionField } from "./OriginalDescriptionField";

type Props = {
  originalFieldName: NamePath;
  label: string;
};

export const TranslationDescriptionFields = memo<Props>(({ originalFieldName, label }) => (
  <>
    <OriginalDescriptionField label={`${label}：原文`} name={originalFieldName} />
    <FormList
      name="descriptionSources"
      initialValue={locales.map(({ code }) => ({ locale: code, value: null }))}
    >
      {(fields) =>
        fields.map((field, ind) => (
          <DescriptionField
            key={field.key}
            name={[field.name, "value"]}
            label={`${label}：${locales[ind]?.label}`}
          />
        ))
      }
    </FormList>
  </>
));
