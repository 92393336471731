import React, { memo } from "react";
import { Radio } from "antd";
import { menuTypes } from "models/menuType";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { AddMenuFormItem } from "../useAddMenuForm";

const options = Object.entries(menuTypes).map(([menuType, menuTypeName]) => ({
  label: menuTypeName,
  value: menuType,
}));

type Props = Omit<FormItemProps, "children" | "name">;

export const MenuTypeField = memo<Props>(({ ...props }) => (
  <AddMenuFormItem
    label={
      <FormHelp
        label="メニュータイプ"
        help="設定したメニュータイプはお客様の注文画面への表示や、出数集計に紐づきます"
      />
    }
    name="menuType"
    rules={[{ required: true, message: "メニュータイプを入力してください" }]}
    {...props}
  >
    <Radio.Group options={options} optionType="button" />
  </AddMenuFormItem>
));
