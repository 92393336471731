import React, { memo, useMemo } from "react";
import { Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { steraPaymentMethodTypeDetails } from "models/steraPaymentMethodType";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { SteraPaymentMethodTypeEnum } from "types/graphql";

import {
  DeleteSteraOnSitePaymentDetailTypeInput,
  OnSitePaymentDetailType,
  SteraOnSitePaymentDetailType,
  UpdateSteraOnSitePaymentDetailTypeInput,
} from "../types";

import { OnSitePaymentDetailTypeField } from "./OnSitePaymentDetailTypeField";

type Props = {
  steraOnSitePaymentDetailTypes: SteraOnSitePaymentDetailType[];
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  loading: boolean;
  onUpdate: (input: UpdateSteraOnSitePaymentDetailTypeInput) => void;
  onDelete: (input: DeleteSteraOnSitePaymentDetailTypeInput) => void;
};

type DataSource = {
  steraPaymentMethodType: SteraPaymentMethodTypeEnum;
  steraOnSitePaymentDetailType: SteraOnSitePaymentDetailType | undefined;
};

export const DetailTypeTable = memo<Props>(
  ({ steraOnSitePaymentDetailTypes, onSitePaymentDetailTypes, loading, onDelete, onUpdate }) => {
    const [pagination, setPagination] = usePagination();

    const selectableOnSitePaymentDetailTypes = useMemo(
      () =>
        onSitePaymentDetailTypes
          .filter(
            ({ type }) =>
              !steraOnSitePaymentDetailTypes.some(
                (steraType) => steraType.onSitePaymentDetailTypeEntityType === type,
              ),
          )
          .map(({ id, label }) => ({ value: id, label })),
      [onSitePaymentDetailTypes, steraOnSitePaymentDetailTypes],
    );

    const columns: ColumnsType<DataSource> = useMemo(
      () => [
        {
          title: "stera支払方法",
          width: 300,
          fixed: "left",
          render(_: string, { steraPaymentMethodType: paymentMethodType }: DataSource) {
            return steraPaymentMethodTypeDetails[paymentMethodType].label ?? "";
          },
        },
        {
          title: "支払方法",
          fixed: "left",
          render(_: string, { steraPaymentMethodType }: DataSource) {
            return (
              <OnSitePaymentDetailTypeField
                values={steraOnSitePaymentDetailTypes
                  .filter((type) => type.steraPaymentMethodType === steraPaymentMethodType)
                  .map(({ onSitePaymentDetailType }) =>
                    onSitePaymentDetailType
                      ? {
                          label: onSitePaymentDetailType.label,
                          value: onSitePaymentDetailType.id,
                        }
                      : null,
                  )
                  .filter(isNotNull)}
                options={selectableOnSitePaymentDetailTypes}
                onChange={(onSitePaymentDetailTypeIds: string[]) => {
                  onUpdate({
                    onSitePaymentDetailTypeIds,
                    steraPaymentMethodType,
                  });
                }}
              />
            );
          },
        },
        {
          title: "",
          align: "center",
          width: 60,
          render(_: string, { steraOnSitePaymentDetailType, steraPaymentMethodType }: DataSource) {
            const disabled = !steraOnSitePaymentDetailType;

            return (
              <Popconfirm
                title={
                  <>
                    <Paragraph>stera支払方法設定を削除しますか？</Paragraph>
                    <Paragraph>一度削除したstera支払方法設定を元に戻すことはできません。</Paragraph>
                  </>
                }
                okText="はい"
                cancelText="キャンセル"
                onConfirm={() => onDelete({ steraPaymentMethodType })}
                disabled={disabled}
              >
                <DeleteIcon disabled={disabled} />
              </Popconfirm>
            );
          },
        } as const,
      ],
      [steraOnSitePaymentDetailTypes, selectableOnSitePaymentDetailTypes, onUpdate, onDelete],
    );

    const dataSource = useMemo<DataSource[]>(() => {
      const steraPaymentMethodTypeToSteraOnSitePaymentDetailTypeMap = Object.fromEntries(
        steraOnSitePaymentDetailTypes.map((steraOnSitePaymentDetailType) => [
          steraOnSitePaymentDetailType.steraPaymentMethodType,
          steraOnSitePaymentDetailType,
        ]),
      );

      return Object.values(SteraPaymentMethodTypeEnum)
        .sort(
          (a, b) =>
            steraPaymentMethodTypeDetails[a].priority - steraPaymentMethodTypeDetails[b].priority,
        )
        .map((steraPaymentMethodType) => ({
          steraPaymentMethodType,
          steraOnSitePaymentDetailType:
            steraPaymentMethodTypeToSteraOnSitePaymentDetailTypeMap[steraPaymentMethodType],
        }));
    }, [steraOnSitePaymentDetailTypes]);

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        loading={loading}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
