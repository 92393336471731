import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = {
  hasPlanTimeLimit: boolean;
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const PlanTimeField = memo<Props>(({ hasPlanTimeLimit, disabled, ...props }) => (
  // NOTE: 縦を揃えるためラベルに半角スペースを挿入
  <EditPlanFormItem label=" " name="planTime" {...props}>
    <InputNumber
      formatter={(value) => `${value}分`}
      parser={(value) => (value ?? "").replace("分", "")}
      min={0}
      disabled={!hasPlanTimeLimit || disabled}
    />
  </EditPlanFormItem>
));
