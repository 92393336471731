import React, { memo, useMemo } from "react";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import type { AxisDomain, BaseAxisProps } from "recharts/types/util/types";
import { convertUnitToTenThousand } from "util/numberUnit/numberUnit";

import { Loading } from "components/Loading";

import { CustomerDashboardCard } from "../CustomerDashboardCard";

import {
  CARTESIAN_GRID_STROKE_DASHARRAY,
  GRID_COLOR,
  LEGEND_HEIGHT,
  LINE_COLORS,
  TICK_FONT_SIZE,
} from "./graphStyles";
import type { LineGraphProps } from "./types";
import { useFormatter } from "./useFormatter";

export const LineGraph = memo<LineGraphProps>(
  ({ data, loading, dataKeys, title, caption, units, width, height }) => {
    const xAxisTicks = [data[0]?.date ?? "", data[data.length - 1]?.date ?? ""];

    const { legendFormatter, tooltipFormatter } = useFormatter({ title });

    const tickFormatter: BaseAxisProps["tickFormatter"] = useMemo(() => {
      if (units !== "円") return undefined;

      return (value) => {
        const { value: result, isOveredMillion } = convertUnitToTenThousand(value);
        if (!isOveredMillion) return String(result);

        // NOTE: 円 はここで指定せずとも Props で渡している unit が付与される
        return `${String(result)}万`;
      };
    }, [units]);

    return (
      <CustomerDashboardCard title={title} caption={caption}>
        {loading ? (
          <Loading width={width} height={height} />
        ) : (
          <LineChart width={width} height={height} data={data}>
            <CartesianGrid stroke={GRID_COLOR} strokeDasharray={CARTESIAN_GRID_STROKE_DASHARRAY} />
            <XAxis
              dataKey="date"
              ticks={xAxisTicks}
              tick={{ fontSize: TICK_FONT_SIZE }}
              axisLine={false}
              tickLine={{ stroke: GRID_COLOR }}
            />
            <Tooltip formatter={tooltipFormatter} />
            <Legend
              iconType="rect"
              verticalAlign="top"
              height={LEGEND_HEIGHT}
              formatter={legendFormatter}
            />

            {dataKeys.map((dataKey, index) => {
              const isPrimaryAxis = index === 0;
              const unit = typeof units === "string" ? units : units[index];

              const yAxisTickCount = unit === "%" ? 5 : undefined;
              const yAxisDomain: AxisDomain | undefined = unit === "%" ? [0, 100] : undefined;

              return (
                <React.Fragment key={dataKey}>
                  <Line
                    yAxisId={dataKey}
                    dataKey={dataKey}
                    unit={unit}
                    stroke={isPrimaryAxis ? LINE_COLORS.PRIMARY : LINE_COLORS.SECONDARY}
                    dot={false}
                  />
                  <YAxis
                    yAxisId={dataKey}
                    orientation={isPrimaryAxis ? "left" : "right"}
                    tickCount={yAxisTickCount}
                    tick={{ fontSize: TICK_FONT_SIZE }}
                    tickFormatter={tickFormatter}
                    domain={yAxisDomain}
                    unit={unit}
                    allowDecimals={false}
                    axisLine={{ stroke: GRID_COLOR }}
                    tickLine={{ stroke: GRID_COLOR }}
                  />
                </React.Fragment>
              );
            })}
          </LineChart>
        )}
      </CustomerDashboardCard>
    );
  },
);
