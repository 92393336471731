import React, { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Alert, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { PikaichiMenuInsertInput } from "types/graphql";

import { EditChoicePikaichiMenuForm } from "./EditChoicePikaichiMenuForm";
import {
  usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery,
  usePikaichiMenuOptionsUpdatePikaichiMenuMutation,
} from "./queries";

type Props = {
  onCompleted: () => void;
  onCloseModal: () => void;
};

export const EditChoiceModal = memo<Props>(({ onCompleted, onCloseModal }) => {
  const [company, setCompany] = useCompany();
  const { choiceId: id } = useParams<{ choiceId: string }>();
  const choiceId = Number(id);

  if (Number.isNaN(choiceId)) {
    onCloseModal();
  }

  const {
    data: getChoiceData,
    loading,
    error,
  } = usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery({
    variables: { choiceId },
  });
  const choice = getChoiceData?.choice?.[0];

  const companyId = choice?.option.companyId;
  setCompany(companyId);

  const pikaichiMenu = choice?.pikaichiMenuChoices?.[0]?.pikaichiMenu;

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    usePikaichiMenuOptionsUpdatePikaichiMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiMenuInsertInput, "companyId">) => {
      if (!company || !choice) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();
      const pikaichiMenuDto: PikaichiMenuInsertInput = {
        pikaichiMenuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updatePikaichiMenuMutation({
          variables: {
            choiceId: choice.id,
            _choiceId: choice.choiceId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      onCompleted();
    },
    [company, choice, pikaichiMenu?.pikaichiMenuId, onCompleted, updatePikaichiMenuMutation],
  );

  return (
    <Modal title={choice?.name} open width={900} onCancel={onCloseModal} footer={null}>
      {loading && <Loading fullWidth height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {choice && (
        <EditChoicePikaichiMenuForm
          choice={choice}
          onSubmit={onSubmit}
          onClose={onCloseModal}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </Modal>
  );
});
