import React, { memo } from "react";
import styled from "styled-components";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  padding: 16px 16px 0 16px;
  align-items: center;
`;

const StyledInput = styled(Input)`
  border-radius: 16px;
`;

type Props = {
  onChangeName: (name: string) => void;
};

export const Navigation = memo<Props>(({ onChangeName }) => (
  <Wrapper>
    <StyledInput
      placeholder="店舗名で検索"
      prefix={<SearchOutlined />}
      onChange={(e) => onChangeName(e.target.value)}
      onClick={(e) => e.stopPropagation()}
    />
  </Wrapper>
));
