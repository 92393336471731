import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OptionHeaderGetBiOptions = gql`
    query OptionHeaderGetBIOptions($optionId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  option(where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}) {
    choices {
      winboardMenus @include(if: $isWinboardEnabled) {
        id
      }
      tecAggregationMenu @include(if: $isTecAggregationEnabled) {
        id
      }
      pikaichiMenuChoices @include(if: $isPikaichiEnabled) {
        pikaichiMenu {
          pikaichiMenuId
        }
      }
    }
  }
}
    `;
export type OptionHeaderGetBiOptionsQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
  isWinboardEnabled: Types.Scalars['Boolean'];
  isPikaichiEnabled: Types.Scalars['Boolean'];
  isTecAggregationEnabled: Types.Scalars['Boolean'];
}>;


export type OptionHeaderGetBiOptionsQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & { choices: Array<(
      { __typename?: 'choice' }
      & { winboardMenus?: Array<(
        { __typename?: 'winboardMenu' }
        & Pick<Types.WinboardMenu, 'id'>
      )>, tecAggregationMenu?: Types.Maybe<(
        { __typename?: 'tecAggregationMenu' }
        & Pick<Types.TecAggregationMenu, 'id'>
      )>, pikaichiMenuChoices?: Array<(
        { __typename?: 'pikaichiMenuChoice' }
        & { pikaichiMenu: (
          { __typename?: 'pikaichiMenu' }
          & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
        ) }
      )> }
    )> }
  )> }
);


export const OptionHeaderGetBiOptionsDocument = gql`
    query OptionHeaderGetBIOptions($optionId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  option(where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}) {
    choices {
      winboardMenus @include(if: $isWinboardEnabled) {
        id
      }
      tecAggregationMenu @include(if: $isTecAggregationEnabled) {
        id
      }
      pikaichiMenuChoices @include(if: $isPikaichiEnabled) {
        pikaichiMenu {
          pikaichiMenuId
        }
      }
    }
  }
}
    `;

/**
 * __useOptionHeaderGetBiOptionsQuery__
 *
 * To run a query within a React component, call `useOptionHeaderGetBiOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionHeaderGetBiOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionHeaderGetBiOptionsQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      isWinboardEnabled: // value for 'isWinboardEnabled'
 *      isPikaichiEnabled: // value for 'isPikaichiEnabled'
 *      isTecAggregationEnabled: // value for 'isTecAggregationEnabled'
 *   },
 * });
 */
export function useOptionHeaderGetBiOptionsQuery(baseOptions: Apollo.QueryHookOptions<OptionHeaderGetBiOptionsQuery, OptionHeaderGetBiOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionHeaderGetBiOptionsQuery, OptionHeaderGetBiOptionsQueryVariables>(OptionHeaderGetBiOptionsDocument, options);
      }
export function useOptionHeaderGetBiOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionHeaderGetBiOptionsQuery, OptionHeaderGetBiOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionHeaderGetBiOptionsQuery, OptionHeaderGetBiOptionsQueryVariables>(OptionHeaderGetBiOptionsDocument, options);
        }
export type OptionHeaderGetBiOptionsQueryHookResult = ReturnType<typeof useOptionHeaderGetBiOptionsQuery>;
export type OptionHeaderGetBiOptionsLazyQueryHookResult = ReturnType<typeof useOptionHeaderGetBiOptionsLazyQuery>;
export type OptionHeaderGetBiOptionsQueryResult = Apollo.QueryResult<OptionHeaderGetBiOptionsQuery, OptionHeaderGetBiOptionsQueryVariables>;