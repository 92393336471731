import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCategoryPrioritiesGetCategories = gql`
    query EditCategoryPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    companyId
    name
    priority
    shopSideName
  }
}
    `;
export const EditCategoryPrioritiesInsertCategories = gql`
    mutation EditCategoryPrioritiesInsertCategories($categories: [category_insert_input!]!) {
  insert_category(
    objects: $categories
    on_conflict: {constraint: idx_46459_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryId
    }
  }
}
    `;
export type EditCategoryPrioritiesGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditCategoryPrioritiesGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'companyId' | 'name' | 'priority' | 'shopSideName'>
  )> }
);

export type EditCategoryPrioritiesInsertCategoriesMutationVariables = Types.Exact<{
  categories: Array<Types.CategoryInsertInput> | Types.CategoryInsertInput;
}>;


export type EditCategoryPrioritiesInsertCategoriesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_category?: Types.Maybe<(
    { __typename?: 'category_mutation_response' }
    & Pick<Types.CategoryMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId'>
    )> }
  )> }
);


export const EditCategoryPrioritiesGetCategoriesDocument = gql`
    query EditCategoryPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    companyId
    name
    priority
    shopSideName
  }
}
    `;

/**
 * __useEditCategoryPrioritiesGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditCategoryPrioritiesGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryPrioritiesGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCategoryPrioritiesGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditCategoryPrioritiesGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditCategoryPrioritiesGetCategoriesQuery, EditCategoryPrioritiesGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCategoryPrioritiesGetCategoriesQuery, EditCategoryPrioritiesGetCategoriesQueryVariables>(EditCategoryPrioritiesGetCategoriesDocument, options);
      }
export function useEditCategoryPrioritiesGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCategoryPrioritiesGetCategoriesQuery, EditCategoryPrioritiesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCategoryPrioritiesGetCategoriesQuery, EditCategoryPrioritiesGetCategoriesQueryVariables>(EditCategoryPrioritiesGetCategoriesDocument, options);
        }
export type EditCategoryPrioritiesGetCategoriesQueryHookResult = ReturnType<typeof useEditCategoryPrioritiesGetCategoriesQuery>;
export type EditCategoryPrioritiesGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditCategoryPrioritiesGetCategoriesLazyQuery>;
export type EditCategoryPrioritiesGetCategoriesQueryResult = Apollo.QueryResult<EditCategoryPrioritiesGetCategoriesQuery, EditCategoryPrioritiesGetCategoriesQueryVariables>;
export const EditCategoryPrioritiesInsertCategoriesDocument = gql`
    mutation EditCategoryPrioritiesInsertCategories($categories: [category_insert_input!]!) {
  insert_category(
    objects: $categories
    on_conflict: {constraint: idx_46459_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryId
    }
  }
}
    `;
export type EditCategoryPrioritiesInsertCategoriesMutationFn = Apollo.MutationFunction<EditCategoryPrioritiesInsertCategoriesMutation, EditCategoryPrioritiesInsertCategoriesMutationVariables>;

/**
 * __useEditCategoryPrioritiesInsertCategoriesMutation__
 *
 * To run a mutation, you first call `useEditCategoryPrioritiesInsertCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryPrioritiesInsertCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryPrioritiesInsertCategoriesMutation, { data, loading, error }] = useEditCategoryPrioritiesInsertCategoriesMutation({
 *   variables: {
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useEditCategoryPrioritiesInsertCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<EditCategoryPrioritiesInsertCategoriesMutation, EditCategoryPrioritiesInsertCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCategoryPrioritiesInsertCategoriesMutation, EditCategoryPrioritiesInsertCategoriesMutationVariables>(EditCategoryPrioritiesInsertCategoriesDocument, options);
      }
export type EditCategoryPrioritiesInsertCategoriesMutationHookResult = ReturnType<typeof useEditCategoryPrioritiesInsertCategoriesMutation>;
export type EditCategoryPrioritiesInsertCategoriesMutationResult = Apollo.MutationResult<EditCategoryPrioritiesInsertCategoriesMutation>;
export type EditCategoryPrioritiesInsertCategoriesMutationOptions = Apollo.BaseMutationOptions<EditCategoryPrioritiesInsertCategoriesMutation, EditCategoryPrioritiesInsertCategoriesMutationVariables>;