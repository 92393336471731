import React, { memo, useCallback, useState } from "react";
import { Button, Divider } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ImageField } from "components/Form/ImageField";
import { AccountingSlipImagePositionEnum } from "types/graphql";

import { AccountingSlipImage } from "../types";

import { DescriptionField } from "./DescriptionField";
import { useEditAccountingSlipImageForm } from "./useEditAccountingSlipImageForm";

type Props = {
  accountingSlipImage: AccountingSlipImage;
  onSubmit: ({
    imageUrl,
    description,
  }: {
    imageUrl: string | null;
    description: string | null;
  }) => Promise<void>;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loading: boolean;
};

export const EditAccountingSlipImageForm = memo<Props>(
  ({ accountingSlipImage, onSubmit, onClose, onFormValidationError, loading }) => {
    const { form, submit, initialValues } = useEditAccountingSlipImageForm({
      accountingSlipImage,
      onSubmit,
    });

    const [uploadImage, setUploadImage] = useState<string | null>(accountingSlipImage.imageUrl);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({ imageUrl: uploadImage });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit, uploadImage]);

    return (
      <Form name="shop" form={form} layout="vertical" initialValues={initialValues}>
        <ImageField
          image={uploadImage}
          setUploadImage={setUploadImage}
          uploadImageApiKey="accountingSlipImage"
          formName="EditAccountingSlipImageForm"
          label="レシート画像"
          objectFitContain
          shouldShowDeleteButton={false}
        />

        {accountingSlipImage.position === AccountingSlipImagePositionEnum.Footer && (
          <DescriptionField />
        )}

        <Divider />

        <FormActions>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
          <Button onClick={onClose}>キャンセル</Button>
        </FormActions>
      </Form>
    );
  },
);
