import React, { memo } from "react";
import styled from "styled-components";
import { Divider } from "antd";
import { sumBy } from "util/array";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { DistributionTypeAnswerListItem } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory/QuestionAnswerMetricsPanel/DistributionTypeAnswerListItem";
import { TextTypeAnswerListItem } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory/QuestionAnswerMetricsPanel/TextTypeAnswerListItem";
import { QuestionAnswerMetrics } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/types";

const ListWrapper = styled.div`
  height: 208px;
  overflow-y: auto;
  /* 親要素に padding が指定されているためスクロールバーが文字と重なってしまうのを避ける */
  margin-right: -16px;
  padding-right: 16px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    height: 180px;
    margin-right: -12px;
    padding-right: 12px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0px 8px;
`;

type Props = {
  questionAnswerMetrics: QuestionAnswerMetrics;
};

export const QuestionAnswerMetricsPanel = memo<Props>(({ questionAnswerMetrics }) => {
  const isEmpty =
    "answers" in questionAnswerMetrics
      ? questionAnswerMetrics.answers.length === 0
      : questionAnswerMetrics.distributionItems.length === 0;

  const totalCount =
    "distributionItems" in questionAnswerMetrics
      ? sumBy(questionAnswerMetrics.distributionItems, "count")
      : 0;

  return (
    <BasePanel
      key={questionAnswerMetrics.title}
      title={questionAnswerMetrics.title}
      isEmpty={isEmpty}
    >
      <Spacer height={16} />

      <ListWrapper>
        {"distributionItems" in questionAnswerMetrics &&
          questionAnswerMetrics.distributionItems.map((distribution, index) => (
            <DistributionTypeAnswerListItem
              key={index}
              distribution={distribution}
              totalCount={totalCount}
              isEmpty={isEmpty}
            />
          ))}

        {"answers" in questionAnswerMetrics &&
          questionAnswerMetrics.answers.map((answer, index) => (
            <React.Fragment key={index}>
              <TextTypeAnswerListItem answer={answer} />
              <StyledDivider />
            </React.Fragment>
          ))}
      </ListWrapper>
    </BasePanel>
  );
});
