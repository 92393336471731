import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ClerksGetClerks = gql`
    query ClerksGetClerks($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopClerks(
      where: {shop: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_clerkId: asc}]
    ) {
      clerk {
        clerkId
        name
      }
      isDisplay
      priority
    }
  }
}
    `;
export type ClerksGetClerksQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ClerksGetClerksQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { shopClerks: Array<(
      { __typename?: 'shopClerk' }
      & Pick<Types.ShopClerk, 'isDisplay' | 'priority'>
      & { clerk: (
        { __typename?: 'clerk' }
        & Pick<Types.Clerk, 'clerkId' | 'name'>
      ) }
    )> }
  )> }
);


export const ClerksGetClerksDocument = gql`
    query ClerksGetClerks($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopClerks(
      where: {shop: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_clerkId: asc}]
    ) {
      clerk {
        clerkId
        name
      }
      isDisplay
      priority
    }
  }
}
    `;

/**
 * __useClerksGetClerksQuery__
 *
 * To run a query within a React component, call `useClerksGetClerksQuery` and pass it any options that fit your needs.
 * When your component renders, `useClerksGetClerksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClerksGetClerksQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useClerksGetClerksQuery(baseOptions: Apollo.QueryHookOptions<ClerksGetClerksQuery, ClerksGetClerksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClerksGetClerksQuery, ClerksGetClerksQueryVariables>(ClerksGetClerksDocument, options);
      }
export function useClerksGetClerksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClerksGetClerksQuery, ClerksGetClerksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClerksGetClerksQuery, ClerksGetClerksQueryVariables>(ClerksGetClerksDocument, options);
        }
export type ClerksGetClerksQueryHookResult = ReturnType<typeof useClerksGetClerksQuery>;
export type ClerksGetClerksLazyQueryHookResult = ReturnType<typeof useClerksGetClerksLazyQuery>;
export type ClerksGetClerksQueryResult = Apollo.QueryResult<ClerksGetClerksQuery, ClerksGetClerksQueryVariables>;