import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import constate from "constate";

import { useStorage } from "hooks/useStorage";
import { useCurrentUser } from "hooks/useUser";
import { logger } from "libs/logger";

/**
 * company 配下の店舗から選択した店舗情報のキャッシュ
 */
export const [ShopProvider, useShop] = constate(() => {
  const [shop, setShop] = useState<{ shopId: string } | null>(null);
  const shopIdRef = useRef(shop?.shopId);
  shopIdRef.current = shop?.shopId;
  const currentUser = useCurrentUser();

  const { setShopId } = useStorage();
  const handleSetShop = useCallback(
    (shopId?: string) => {
      if (shopId && shopId !== shopIdRef.current) {
        logger.addContext({ key: "shopId", value: shopId });
        setShop({ shopId });
        setShopId(shopId);
      } else if (!shopId) {
        logger.addContext({ key: "shopId", value: null });
        setShop(null);
        setShopId(null);
      }
    },
    [setShopId],
  );

  useEffect(() => {
    if (!currentUser) {
      setShop(null);
      logger.addContext({ key: "shopId", value: null });
    }
  }, [currentUser]);

  return useMemo(() => [shop, handleSetShop] as const, [shop, handleSetShop]);
});
