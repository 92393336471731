import React, { memo } from "react";
import { getRole } from "models/user";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useCurrentUser } from "hooks/useUser";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";
import { Role } from "types/role";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const PikaichiMenu = memo<Props>(({ company, ...props }) => {
  const user = useCurrentUser();
  const role = getRole(user);

  const companyId = company?.id;
  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const { isFeatureAvailable } = useFeatureFlag();
  const canShowYellMenu = isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds });
  // NOTE: UAT業態と特定法人のみに公開する
  const canShowMenuCodeCsv =
    role === Role.ServiceAdmin ||
    (companyId !== undefined &&
      [
        "20d1ff6f-64b4-4db3-a2d6-3d63766f9ac3", // 鯛之鯛
        "88f1227c-a068-4977-8bf7-0639dfcf3c2b", // すしまにし
        "69f2cf7c-af03-4f9f-8c8d-c2229973099d", // スシトフジ
        "21c1381a-f992-4667-82d1-573b6ff7d473", // ビーフキッチンスタンド
        "32f305b9-f870-4e03-baba-8af937d7880d", // UAT (production)
        "961da169-b622-47ab-a710-af4314d8710f", // UAT (beta)
        "90b0c42e-09da-4c36-b340-588acf98f0f1", // UAT (staging)
        "3ac270d6-5cfb-4adc-8919-0163968bdcd2", // UAT (develop)
      ].includes(companyId));

  return (
    <SubMenu
      key="pikaichi"
      title="ぴかいちナビ連携"
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      <MenuItem route="pikaichiUpload" to="/pikaichi/upload" text="連携処理" />
      <MenuItem route="editPikaichiConfig" to="/pikaichi/config" text="設定" />
      <MenuItem
        route="pikaichiOnSitePaymentTypes"
        to="/pikaichi/onSitePaymentTypes"
        text="支払・割引方法設定"
      />
      <MenuItem route="pikaichiMenus" to="/pikaichi/menu" text="メニュー" />
      <MenuItem route="pikaichiPlans" to="/pikaichi/plan" text="プラン" />
      <MenuItem
        route="pikaichiGifts"
        to="/pikaichi/gift"
        text="推しエール"
        canShow={canShowYellMenu}
      />
      <MenuItem
        route="menuCodeCsv"
        to="/menuCodeCsv/pikaichi"
        text="メニューコード一括編集"
        canShow={canShowMenuCodeCsv}
      ></MenuItem>
    </SubMenu>
  );
});
