import { useCallback, useMemo, useState } from "react";
import { isNotNull } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountRole } from "pages/AddDashboardAccountRole/types";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

export type AddDashboardAccountRoleFormValues = Pick<DashboardAccountRole, "name">;

export type Permissions = Record<
  DashboardFeatureEnum,
  DashboardAccountRolePermissionTypeEnum | undefined
>;

export type DashboardAccountRoleFormValuesWithPermissions = AddDashboardAccountRoleFormValues & {
  permissions: Permissions;
};

const getInitialValues = ({ name }: { name?: string }) => ({
  name: name ?? "",
});

export const AddDashboardAccountRoleFormItem = createFormItem<AddDashboardAccountRoleFormValues>();

export const useAddDashboardAccountRoleForm = ({
  onSubmit,
  sourceDashboardAccountRole,
}: {
  onSubmit: (formValues: DashboardAccountRoleFormValuesWithPermissions) => void;
  sourceDashboardAccountRole?: DashboardAccountRole;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ name: sourceDashboardAccountRole?.name });

  const sourceEditPermissions = useMemo(
    () =>
      sourceDashboardAccountRole?.dashboardAccountRolePermissions.filter(
        ({ type }) => type === DashboardAccountRolePermissionTypeEnum.Edit,
      ) ?? [],
    [sourceDashboardAccountRole],
  );

  const [permissions, setPermissions] = useState<Permissions>(
    Object.fromEntries(
      (sourceDashboardAccountRole?.dashboardAccountRolePermissions ?? [])
        .map(({ feature, type }) => {
          if (
            type === DashboardAccountRolePermissionTypeEnum.View &&
            sourceEditPermissions.some(({ feature: sourceFeature }) => sourceFeature === feature)
          ) {
            return null;
          }
          return [feature, type];
        })
        .filter(isNotNull),
    ),
  );

  const setPermission = useCallback(
    ({
      feature,
      type,
    }: {
      feature: DashboardFeatureEnum;
      type: DashboardAccountRolePermissionTypeEnum | undefined;
    }) => setPermissions((permissions) => ({ ...permissions, [feature]: type })),
    [setPermissions],
  );

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AddDashboardAccountRoleFormValues;

    onSubmit({ ...values, permissions });
  }, [form, onSubmit, permissions]);

  return { form, initialValues, submit, permissions, setPermission };
};
