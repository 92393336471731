import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { EditPlanTranslationsForm } from "./EditPlanTranslationsForm";
import {
  useEditPlanTranslationsGetPlanQuery,
  useEditPlanTranslationsUpdatePlanTranslationsMutation,
} from "./queries";

export const EditPlanTranslations = () => {
  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getPlanData,
    loading: loadingPlan,
    error: errorGetPlanData,
    refetch: refetchGetPlanData,
  } = useEditPlanTranslationsGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = useMemo(() => getPlanData?.plan?.[0], [getPlanData?.plan]);
  const translations = useMemo(() => plan?.translations ?? [], [plan?.translations]);

  const [
    updatePlanTranslationsMutation,
    { loading: loadingUpdatePlanTranslationsMutation, error: errorUpdatePlanTranslationsMutation },
  ] = useEditPlanTranslationsUpdatePlanTranslationsMutation();

  const [
    { loading: loadingUpdatePlanTranslations, error: errorUpdatePlanTranslations },
    updatePlanTranslations,
  ] = useAsyncFn(
    async ({ planId, companyId, planNameSources, descriptionSources, featureLabelSources }) => {
      await updatePlanTranslationsMutation({
        variables: {
          input: {
            companyId,
            planId,
            planNameSources,
            descriptionSources,
            featureLabelSources,
          },
        },
      });

      message.success("編集を保存しました");

      goBack();

      refetchGetPlanData();
    },
    [updatePlanTranslationsMutation, goBack, refetchGetPlanData],
  );

  const showErrorMessage = useCallback(() => {
    message.error("編集に失敗しました");
  }, []);

  const loading =
    loadingPlan || loadingUpdatePlanTranslations || loadingUpdatePlanTranslationsMutation;
  const error =
    errorGetPlanData || errorUpdatePlanTranslations || errorUpdatePlanTranslationsMutation;
  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラン" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loading && <Loading height={300} />}
      {plan && (
        <EditPlanTranslationsForm
          plan={plan}
          translations={translations}
          onSubmit={updatePlanTranslations}
          onClose={goBack}
          onFormValidationError={showErrorMessage}
          disabled={!isEditPlanFeatureEnabled || !canEditAllShopPlans}
        />
      )}
    </DashboardLayout>
  );
};
