import React, { memo } from "react";
import { FormItemProps, Input } from "antd";
import { getTaxRate } from "models/taxRate";

import { AddPlanChoiceFormItem } from "../useAddPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  taxRate: number;
};

export const TaxRateField = memo<Props>(({ taxRate, ...props }) => (
  <AddPlanChoiceFormItem.NonProperty label="税率" {...props}>
    {/* NOTE: 変更不可な仕様 */}
    <Input disabled={true} value={getTaxRate(taxRate)} suffix="%" />
  </AddPlanChoiceFormItem.NonProperty>
));
