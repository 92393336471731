import { Environment } from "../types/environment";

export const envToWebBaseUrl: Record<Environment, string> = {
  localhost: import.meta.env.APP_LOCALHOST_BASE_URL ?? "https://localhost:3000",
  pr: `https://pr${import.meta.env.PR_NUMBER}---dinii-self-web-pr-gmju4p474a-an.a.run.app`,
  develop: "https://web.develop.self.dinii.jp",
  staging: "https://web.stag.self.dinii.jp",
  beta: "https://web.beta.self.dinii.jp",
  production: "https://web.self.dinii.jp",
};
