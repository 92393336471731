import {
  useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery,
  useGmoOffPaymentFeatureFlagGetShopIdsQuery,
} from "./queries";

const useShopIds = ({ companyId, skip }: { companyId?: string; skip: boolean }) => {
  const { data, loading } = useGmoOffPaymentFeatureFlagGetShopIdsQuery(
    companyId && !skip ? { variables: { companyId } } : { skip: true },
  );
  return {
    loading,
    shopIds: data?.shop.map((shop) => shop.shopId) ?? [],
  };
};

const useGmoOffPaymentShopIds = ({
  shopIds,
  loadingShopIds,
  skip,
}: {
  shopIds: string[];
  loadingShopIds: boolean;
  skip: boolean;
}) => {
  const { data, loading } = useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery(
    loadingShopIds || skip ? { skip: true } : { variables: { shopIds } },
  );
  return {
    loading: loading || loadingShopIds,
    gmoOffPaymentShopIds: data?.gmoOffPaymentShopConfig.map((shop) => shop.shopId) ?? [],
  };
};

export const useGmoOffPaymentFeatureFlag = ({
  companyId,
  skip,
}: {
  companyId?: string;
  skip: boolean;
}) => {
  const { shopIds, loading: loadingShopIds } = useShopIds({ companyId, skip });
  const { gmoOffPaymentShopIds, loading } = useGmoOffPaymentShopIds({
    shopIds,
    skip,
    loadingShopIds,
  });

  return {
    loading,
    isGmoOffPaymentFeatureAvailable: gmoOffPaymentShopIds.length > 0,
  };
};
