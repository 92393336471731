// NOTE: TaxMethodEnum には NonTaxable = 'nonTaxable' が存在する
export const taxMethods = {
  excluded: "税別",
  included: "税込",
  nonTaxable: "非課税",
} as const;

export const isTaxMethod = (taxMethodKey: string): taxMethodKey is keyof typeof taxMethods =>
  taxMethodKey in taxMethods;

export const getTaxMethodName = (taxMethodKey: string) =>
  isTaxMethod(taxMethodKey) ? taxMethods[taxMethodKey] : ("" as const);
