import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { CreateShopInput } from "types/graphql";

import { AddShopForm } from "./AddShopForm";
import { useCreateShopMutation } from "./queries";

export const AddShop = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;

  const [createShopMutation, { loading: loadingCreateShop }] = useCreateShopMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (shop: CreateShopInput) => {
      try {
        await createShopMutation({
          variables: { input: shop },
        });

        message.success("作成しました");

        goBack();
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [createShopMutation, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="店舗を新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <PageHeader title="店舗を新規作成" onBack={goBack} />
      {companyId ? (
        <AddShopForm
          companyId={companyId}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={loadingCreateShop}
        />
      ) : null}
    </DashboardLayout>
  );
};
