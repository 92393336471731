import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShop } from "hooks/useShop";
import { unwrap } from "libs/unwrap";
import { AddTableForm } from "pages/AddTable/AddTableForm";
import {
  useAddTableGetTableAreasQuery,
  useAddTableGetTableMaxPriorityLazyQuery,
  useAddTableInsertTableMutation,
} from "pages/AddTable/queries";
import { TableInsertInput } from "types/graphql";

export const AddTable = () => {
  const navigate = useNavigate();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data: tableAreasData } = useAddTableGetTableAreasQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const tableAreas = useMemo(() => tableAreasData?.tableArea ?? [], [tableAreasData?.tableArea]);

  const [getTableMaxPriority] = useAddTableGetTableMaxPriorityLazyQuery();
  const [addTableMutation, { loading: loadingInsertTable }] = useAddTableInsertTableMutation();

  const onSubmit = useCallback(
    async (table: TableInsertInput) => {
      if (!shopId) return;

      try {
        const tableAreaId = unwrap(table.tableAreaId);

        const { data: tableMaxPriorityData } = await getTableMaxPriority({
          variables: { shopId, tableAreaId },
        });

        const tableMaxPriority =
          tableMaxPriorityData?.table_aggregate?.aggregate?.max?.priority ?? 0;

        const tables = [{ ...table, priority: tableMaxPriority + 1 }];
        const { data: addTableData } = await addTableMutation({
          variables: { tables },
        });
        const tableId = addTableData?.insert_table?.returning?.[0]?.tableId;

        if (tableId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/table", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [getTableMaxPriority, addTableMutation, shopId, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const onFormValidationError = useCallback(() => {
    message.error("編集の保存に失敗しました");
  }, []);

  return (
    <DashboardLayout
      title="テーブルを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "エリア / テーブル" }],
      }}
    >
      <PageHeader title="テーブルを新規作成" onBack={goBack} />
      <AddTableForm
        tableAreas={tableAreas}
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertTable}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
