import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopOrderableTimesGetOrderableTimes = gql`
    query ShopOrderableTimesGetOrderableTimes($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    orderableTimes {
      id
      name
    }
  }
}
    `;
export const ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTerms = gql`
    query ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTerms($companyId: uuid!, $shopId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    orderableTimes {
      id
      name
      shopOrderableTimeTerms(
        where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
      ) {
        id
        dayWeek
        start
        end
        shop {
          shopId
          changeDateTime
        }
      }
    }
  }
}
    `;
export type ShopOrderableTimesGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ShopOrderableTimesGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & { orderableTimes: Array<(
      { __typename?: 'orderableTime' }
      & Pick<Types.OrderableTime, 'id' | 'name'>
    )> }
  )> }
);

export type ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
}>;


export type ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & { orderableTimes: Array<(
      { __typename?: 'orderableTime' }
      & Pick<Types.OrderableTime, 'id' | 'name'>
      & { shopOrderableTimeTerms: Array<(
        { __typename?: 'shopOrderableTimeTerm' }
        & Pick<Types.ShopOrderableTimeTerm, 'id' | 'dayWeek' | 'start' | 'end'>
        & { shop: (
          { __typename?: 'shop' }
          & Pick<Types.Shop, 'shopId' | 'changeDateTime'>
        ) }
      )> }
    )> }
  )> }
);


export const ShopOrderableTimesGetOrderableTimesDocument = gql`
    query ShopOrderableTimesGetOrderableTimes($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    orderableTimes {
      id
      name
    }
  }
}
    `;

/**
 * __useShopOrderableTimesGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useShopOrderableTimesGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopOrderableTimesGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopOrderableTimesGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useShopOrderableTimesGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<ShopOrderableTimesGetOrderableTimesQuery, ShopOrderableTimesGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopOrderableTimesGetOrderableTimesQuery, ShopOrderableTimesGetOrderableTimesQueryVariables>(ShopOrderableTimesGetOrderableTimesDocument, options);
      }
export function useShopOrderableTimesGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopOrderableTimesGetOrderableTimesQuery, ShopOrderableTimesGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopOrderableTimesGetOrderableTimesQuery, ShopOrderableTimesGetOrderableTimesQueryVariables>(ShopOrderableTimesGetOrderableTimesDocument, options);
        }
export type ShopOrderableTimesGetOrderableTimesQueryHookResult = ReturnType<typeof useShopOrderableTimesGetOrderableTimesQuery>;
export type ShopOrderableTimesGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useShopOrderableTimesGetOrderableTimesLazyQuery>;
export type ShopOrderableTimesGetOrderableTimesQueryResult = Apollo.QueryResult<ShopOrderableTimesGetOrderableTimesQuery, ShopOrderableTimesGetOrderableTimesQueryVariables>;
export const ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsDocument = gql`
    query ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTerms($companyId: uuid!, $shopId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    orderableTimes {
      id
      name
      shopOrderableTimeTerms(
        where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
      ) {
        id
        dayWeek
        start
        end
        shop {
          shopId
          changeDateTime
        }
      }
    }
  }
}
    `;

/**
 * __useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery__
 *
 * To run a query within a React component, call `useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery(baseOptions: Apollo.QueryHookOptions<ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery, ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery, ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables>(ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsDocument, options);
      }
export function useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery, ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery, ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables>(ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsDocument, options);
        }
export type ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryHookResult = ReturnType<typeof useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery>;
export type ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsLazyQueryHookResult = ReturnType<typeof useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsLazyQuery>;
export type ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryResult = Apollo.QueryResult<ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery, ShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQueryVariables>;