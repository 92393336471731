import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuKdDisplayTargetFormItem } from "../useEditMenuKdDisplayTargetForm";

type Props = {
  loading?: boolean;
  name: string;
} & Omit<FormItemProps, "children" | "name">;

export const MenuKdDisplayTargetField = memo<Props>(({ loading, ...props }) => (
  <EditMenuKdDisplayTargetFormItem valuePropName="checked" {...props}>
    <Switch loading={loading} />
  </EditMenuKdDisplayTargetFormItem>
));
