import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";
import { CouponTypeEnum } from "types/graphql";

import { Coupon, Menu } from "../types";

type CommonCouponFormValues = Pick<
  Coupon,
  | "availableDays"
  | "availableFrom"
  | "availableUntil"
  | "content"
  | "description"
  | "name"
  | "termsOfUse"
  | "title"
> & {
  isDisabledAvailableDays: boolean;
  isDisabledAvailableUntil: boolean;
};

type MenuDiscountTypeCouponFormValues = {
  [CouponTypeEnum.MenuDiscount]?: Pick<Menu, "menuId" | "taxMethod" | "taxRate"> & {
    normalPrice: number;
    discountedPrice: number;
  };
};

type TicketTypeCouponFormValues = {
  [CouponTypeEnum.Ticket]?: Pick<Coupon, "imageUrl">;
};

export type EditCouponFormValues = Pick<Coupon, "couponType"> &
  CommonCouponFormValues &
  MenuDiscountTypeCouponFormValues &
  TicketTypeCouponFormValues;

const getInitialValues = ({
  id: _,
  imageUrl,
  availableFrom,
  availableUntil,
  availableDays,
  couponMenuDiscount,
  ...coupon
}: Coupon) => ({
  ...coupon,
  availableFrom: dayjs(availableFrom),
  availableUntil: availableUntil && dayjs(availableUntil),
  isDisabledAvailableUntil: !availableUntil,
  availableDays,
  isDisabledAvailableDays: !availableDays,
  imageUrl: imageUrl ?? null,
  menuDiscount: couponMenuDiscount && {
    menuId: couponMenuDiscount.menuId,
    discountedPrice: couponMenuDiscount.discountedPrice,
  },
});

export const EditCouponFormItem = createFormItem<EditCouponFormValues>();

export const useEditCouponForm = (
  coupon: Coupon,
  onSubmit: ({ coupon }: { coupon: EditCouponFormValues }) => void,
) => {
  const [form] = Form.useForm<EditCouponFormValues>();
  const initialValues = getInitialValues(coupon);

  const submit = useCallback(
    ({ imageUrl }: { imageUrl: string | null }) => {
      const formValues = form.getFieldsValue() as EditCouponFormValues;
      const availableFrom = dayjs(formValues.availableFrom).format("YYYY-MM-DD HH:mmZZ");
      const availableUntil = formValues.availableUntil
        ? dayjs(formValues.availableUntil).format("YYYY-MM-DD HH:mm:59ZZ")
        : null;
      const availableDays = formValues.availableDays ?? null;

      onSubmit({
        coupon: {
          ...formValues,
          availableFrom,
          availableUntil,
          availableDays,
          ticket: { imageUrl },
        },
      });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
