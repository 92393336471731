import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Result } from "antd";

import { colors } from "constants/colors";
import { logger } from "libs/logger";

const Wrapper = styled.div`
  background: ${colors.BackGround.Tertiary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

export const NotFound = () => {
  useEffect(
    () => logger.warn("404: user opened not found page", { path: window.location.href }),
    [],
  );

  return (
    <Wrapper>
      <Result
        status="404"
        title="404 Not Found"
        subTitle="お探しのページが削除されているか、URLが変更されている可能性があります。URLをご確認の上もう一度お試しください。"
        extra={
          <Link to="/">
            <Button type="primary">トップページに戻る</Button>
          </Link>
        }
      />
    </Wrapper>
  );
};
