import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditWinboardPlanFormItem } from "../useEditWinboardPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CodeField = memo<Props>((props) => (
  <EditWinboardPlanFormItem label="メニューコード" name="code" {...props}>
    <InputCode />
  </EditWinboardPlanFormItem>
));
