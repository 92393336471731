import React, { useCallback } from "react";
import { OrderableTimeTermDayWeek } from "models/orderableTime";
import { getHours } from "util/duration";
import { isNotNull } from "util/type/primitive";

import { message } from "components/antd/message";

import { DayWeekScheduler } from "../DayWeekScheduler";
import { WeeklyEvent } from "../DayWeekScheduler/types";
import { EditOrderableTimeFormValues } from "../useEditOrderableTimeForm";

export const DayWeekSchedulerForm = React.memo(
  ({
    terms,
    onCreateTerm,
    onDeleteTerm,
    changeDateTime,
  }: {
    terms: EditOrderableTimeFormValues["terms"];
    onCreateTerm: (_: EditOrderableTimeFormValues["terms"][number]) => void;
    onDeleteTerm: (_: number) => void;
    changeDateTime: string;
  }) => {
    const normalizedChangeDateTime = getHours(changeDateTime);

    const createTerm = useCallback(
      ({ dayWeek, start, end }: WeeklyEvent) => {
        message.warning("変更はまだ適用されていません。右下から「更新」を行ってください。");
        onCreateTerm({
          dayWeek: {
            value: OrderableTimeTermDayWeek[dayWeek],
          },
          start,
          end,
        });
      },
      [onCreateTerm],
    );

    const deleteTerm = useCallback(
      ({ dayWeek, start, end }: WeeklyEvent) => {
        const deleteTermIdx = terms.findIndex(
          (term) =>
            term &&
            term.dayWeek.value === dayWeek &&
            term.start.isSame(start) &&
            term.end.isSame(end),
        );
        if (deleteTermIdx < 0) {
          return;
        }
        onDeleteTerm(deleteTermIdx);
      },
      [onDeleteTerm, terms],
    );

    return (
      <DayWeekScheduler
        changeDateTime={normalizedChangeDateTime}
        onCreateEvent={(e) => createTerm(e)}
        onDeleteEvent={(e) => deleteTerm(e)}
        events={terms.filter(isNotNull).map(({ start, end, dayWeek }) => ({
          start,
          end,
          dayWeek: dayWeek.value,
        }))}
      />
    );
  },
);
