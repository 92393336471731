import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { WinboardMenuInsertInput } from "types/graphql";

import { ChoiceWithWinboardMenu, WinboardMenu } from "../../types";

export type EditChoiceWinboardMenuFormValues = Pick<
  WinboardMenu,
  "name" | "code" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
>;

export const EditChoiceWinboardMenuFormItem = createFormItem<EditChoiceWinboardMenuFormValues>();

export const useEditChoiceWinboardMenuForm = ({
  choice,
  winboardMenu,
  onSubmit,
}: {
  choice?: ChoiceWithWinboardMenu;
  winboardMenu?: WinboardMenu;
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId">) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditChoiceWinboardMenuFormValues>(
    () => ({
      name: winboardMenu?.name ?? choice?.name ?? "",
      code: winboardMenu?.code ?? "",
      bumonCode: winboardMenu?.bumonCode ?? "",
      bumonName: winboardMenu?.bumonName ?? "",
      categoryCode: winboardMenu?.categoryCode ?? "",
      categoryName: winboardMenu?.categoryName ?? "",
    }),
    [choice, winboardMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditChoiceWinboardMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
