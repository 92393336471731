import React, { memo } from "react";
import { FormItemProps, Input } from "antd";
import { getTaxMethodName } from "models/taxMethod";

import { FormHelp } from "components/Form/FormHelp";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  taxMethod: string;
};

export const TaxMethodField = memo<Props>(({ taxMethod, ...props }) => (
  <EditPlanChoiceFormItem.NonProperty
    label={
      <FormHelp
        label="税種別"
        help="プランで設定された税種別が適用されています。変更したい場合はプランの税種別を編集してください。"
      />
    }
    {...props}
  >
    {/* NOTE: 変更不可な仕様 */}
    <Input disabled={true} value={getTaxMethodName(taxMethod)} />
  </EditPlanChoiceFormItem.NonProperty>
));
