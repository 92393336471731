import { onError } from "@apollo/client/link/error";

import { message } from "components/antd/message";
import { isAPIError } from "libs/isApiError";
import { logger } from "libs/logger";

export const createErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        const { extensions, message: errorMessage, path, ...rest } = graphQLError;

        if (isAPIError(graphQLError)) {
          logger.error("API Error:", extensions?.exception.response);
        } else {
          logger.critical(errorMessage, { path, extensions, ...rest });
        }
      });

      message.error("エラーが発生しました");
    }

    if (networkError) console.error("[Network error]: ", networkError);
  });
