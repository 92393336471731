import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPikaichiOnSitePaymentDetailTypeFormItem } from "../useEditPikaichiOnSitePaymentDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiUchiwakeNameField = memo<Props>((props) => (
  <EditPikaichiOnSitePaymentDetailTypeFormItem
    label="ぴかいち内訳名称"
    name="paymentUchiwakeName"
    rules={[{ required: true, message: "内訳名称を入力してください" }]}
    {...props}
  >
    <Input />
  </EditPikaichiOnSitePaymentDetailTypeFormItem>
));
