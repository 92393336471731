import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypes = gql`
    query EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    companyId
    id
    label
    priority
    type
  }
}
    `;
export const EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypes = gql`
    mutation EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypes($onSitePaymentDiscountTypes: [onSitePaymentDiscountType_insert_input!]!) {
  insert_onSitePaymentDiscountType(
    objects: $onSitePaymentDiscountTypes
    on_conflict: {constraint: onSitePaymentDiscountType_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'companyId' | 'id' | 'label' | 'priority' | 'type'>
  )> }
);

export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationVariables = Types.Exact<{
  onSitePaymentDiscountTypes: Array<Types.OnSitePaymentDiscountTypeInsertInput> | Types.OnSitePaymentDiscountTypeInsertInput;
}>;


export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDiscountType_mutation_response' }
    & Pick<Types.OnSitePaymentDiscountTypeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'onSitePaymentDiscountType' }
      & Pick<Types.OnSitePaymentDiscountType, 'id'>
    )> }
  )> }
);


export const EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesDocument = gql`
    query EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    companyId
    id
    label
    priority
    type
  }
}
    `;

/**
 * __useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery, EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery, EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables>(EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesDocument, options);
      }
export function useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery, EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery, EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables>(EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesDocument, options);
        }
export type EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery>;
export type EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesLazyQuery>;
export type EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryResult = Apollo.QueryResult<EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery, EditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQueryVariables>;
export const EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesDocument = gql`
    mutation EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypes($onSitePaymentDiscountTypes: [onSitePaymentDiscountType_insert_input!]!) {
  insert_onSitePaymentDiscountType(
    objects: $onSitePaymentDiscountTypes
    on_conflict: {constraint: onSitePaymentDiscountType_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationFn = Apollo.MutationFunction<EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation, EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationVariables>;

/**
 * __useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation, { data, loading, error }] = useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation({
 *   variables: {
 *      onSitePaymentDiscountTypes: // value for 'onSitePaymentDiscountTypes'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation, EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation, EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationVariables>(EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesDocument, options);
      }
export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation>;
export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationResult = Apollo.MutationResult<EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation>;
export type EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation, EditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutationVariables>;