import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { CategoryMenu } from "pages/EditMenuPriorities/types";
import { CategoryMenuInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  categoryMenus: CategoryMenu[];
  onUpdate: (categoryMenus: CategoryMenuInsertInput[]) => void;
};

export const EditMenuPriorityList = memo<Props>(({ loading, categoryMenus, onUpdate }) => {
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex !== hoverIndex) {
      onUpdate(
        updatePriorities(categoryMenus, dragIndex, hoverIndex).map(({ menu, ...categoryMenu }) => {
          const { categoryMenuId, categoryId, ...rest } = categoryMenu;

          return {
            ...rest,
            serial: categoryMenuId,
            _categoryId: categoryId,
            _menuId: menu.menuId,
          };
        }),
      );
    }
  };

  return (
    <PriorityList<CategoryMenu>
      itemLayout="horizontal"
      loading={loading}
      dataSource={categoryMenus}
      renderItem={({ categoryMenuId, menu, priority }, index) => (
        <PriorityListItem
          id={categoryMenuId}
          name={menu.shopSideName || menu.name}
          priority={priority}
          index={index}
          onMove={handleMoveItem}
        />
      )}
    />
  );
});
