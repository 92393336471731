import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Tag } from "antd";
import Column from "antd/es/table/Column";
import Modal from "antd/lib/modal/Modal";
import { isNotNull } from "util/type/primitive";

import { Table } from "components/Table";
import { colors } from "constants/colors";

import {
  FoodingJournalOnSitePaymentDiscountTypeSource,
  foodingJournalPaymentTypes,
} from "../OnSitePaymentDiscountTypeTable";
import { OnSitePaymentDiscountType } from "../types";

const ComparisonText = styled.p`
  color: ${colors.Text.Primary};
`;

type Props = {
  editingOnSitePaymentDiscountTypes: Record<
    string,
    FoodingJournalOnSitePaymentDiscountTypeSource & {
      foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
    }
  >;
  onSitePaymentDiscountTypes: OnSitePaymentDiscountType[];
  loading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

type RowItem = {
  id: string;
  label: string;
  foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
  discountType: number;
  code: string;
  name: string;
  previousPaymentType: number | undefined;
  previousCode: string | undefined;
  previousName: string | undefined;
};

export const ConfirmationModal = memo<Props>(
  ({
    editingOnSitePaymentDiscountTypes,
    onSitePaymentDiscountTypes,
    loading,
    onSubmit,
    onCancel,
  }: Props) => {
    const idToOnSitePaymentDiscountTypeMap = useMemo(
      () => new Map(onSitePaymentDiscountTypes.map((type) => [type.id, type])),
      [onSitePaymentDiscountTypes],
    );

    const dataSources = useMemo(
      () =>
        Object.entries(editingOnSitePaymentDiscountTypes)
          .map(([id, type]) => {
            const detailType = idToOnSitePaymentDiscountTypeMap.get(id);

            if (!detailType) return null;

            if (!type.discountType || !type.code || !type.name) return null;

            return {
              id,
              label: detailType.label,
              foodingJournalOnSitePaymentDiscountTypeId:
                type.foodingJournalOnSitePaymentDiscountTypeId,
              discountType: type.discountType,
              code: type.code,
              name: type.name,
              previousPaymentType:
                detailType.foodingJournalOnSitePaymentDiscountType?.discountType.toString() !==
                type.discountType
                  ? detailType.foodingJournalOnSitePaymentDiscountType?.discountType
                  : undefined,
              previousCode:
                detailType.foodingJournalOnSitePaymentDiscountType?.code !== type.code
                  ? detailType.foodingJournalOnSitePaymentDiscountType?.code
                  : undefined,
              previousName:
                detailType.foodingJournalOnSitePaymentDiscountType?.name !== type.name
                  ? detailType.foodingJournalOnSitePaymentDiscountType?.name
                  : undefined,
            };
          })
          .filter(isNotNull),
      [editingOnSitePaymentDiscountTypes, idToOnSitePaymentDiscountTypeMap],
    );

    const onOk = useCallback(async () => {
      await onSubmit();
    }, [onSubmit]);

    return (
      <Modal
        title="変更内容確認"
        visible
        width={3000}
        onCancel={onCancel}
        footer={
          <>
            <Button key="cancel" onClick={onCancel}>
              キャンセル
            </Button>

            <Button key="add" type="primary" onClick={onOk} loading={loading}>
              更新
            </Button>
          </>
        }
      >
        <Table bordered dataSource={dataSources}>
          <Column
            title=""
            width={100}
            fixed="left"
            render={(_: string, rowItem: RowItem) =>
              rowItem.foodingJournalOnSitePaymentDiscountTypeId ? (
                <Tag>更新</Tag>
              ) : (
                <Tag color={colors.BackGround.PrimaryDefault}>追加</Tag>
              )
            }
          />

          <Column dataIndex="label" title="割引・値引種別" width={200} />

          <Column
            dataIndex="discountType"
            title="値引き種別"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              const discountTypeText = `${rowItem.discountType}: ${
                foodingJournalPaymentTypes[rowItem.discountType]?.name ?? ""
              }`;

              if (!rowItem.foodingJournalOnSitePaymentDiscountTypeId) {
                return <ComparisonText>{discountTypeText}</ComparisonText>;
              }

              if (!rowItem.previousPaymentType) return rowItem.discountType;

              const previousPaymentTypeText = `${rowItem.previousPaymentType}: ${
                foodingJournalPaymentTypes[rowItem.previousPaymentType]?.name ?? ""
              }`;

              return (
                <ComparisonText>
                  {`${previousPaymentTypeText} → ${discountTypeText}`}
                </ComparisonText>
              );
            }}
          />

          <Column
            dataIndex="code"
            title="値引きコード"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalOnSitePaymentDiscountTypeId) {
                return <ComparisonText>{rowItem.code}</ComparisonText>;
              }

              if (!rowItem.previousCode) return rowItem.code;

              return <ComparisonText>{`${rowItem.previousCode} → ${rowItem.code}`}</ComparisonText>;
            }}
          />

          <Column
            dataIndex="name"
            title="値引き名称"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalOnSitePaymentDiscountTypeId) {
                return <ComparisonText>{rowItem.name}</ComparisonText>;
              }

              if (!rowItem.previousName) return rowItem.name;

              return (
                <>
                  <ComparisonText>{`${rowItem.previousName} → ${rowItem.name}`}</ComparisonText>
                </>
              );
            }}
          />
        </Table>
      </Modal>
    );
  },
);
