import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { useWinboardInflowSourceTagGetInflowSourceTagQuery } from "./queries";
import { WinboardInflowSourceTagTable } from "./WinboardInflowSourceTagTable";

export const WinboardInflowSourceTags = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data, loading, error } = useWinboardInflowSourceTagGetInflowSourceTagQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const inflowSourceTags = data?.inflowSourceTag ?? [];

  const shouldShowAlert = error;

  return (
    <DashboardLayout title="媒体設定一覧">
      <PageHeader title="媒体設定一覧" />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <WinboardInflowSourceTagTable inflowSourceTags={inflowSourceTags} loading={loading} />
    </DashboardLayout>
  );
};
