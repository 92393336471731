import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationMenusGetMenus = gql`
    query TecAggregationMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
            choices {
              choiceId
              name
              tecAggregationMenu {
                id
                name
                menuCode
                dpCode
                dpName
                gpCode
                gpName
              }
            }
          }
        }
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;
export const TecAggregationMenusGetCategories = gql`
    query TecAggregationMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const TecAggregationMenusGetShops = gql`
    query TecAggregationMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export type TecAggregationMenusGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
        & { shopMenus: Array<(
          { __typename?: 'shopMenu' }
          & { shop: (
            { __typename?: 'shop' }
            & Pick<Types.Shop, 'shopId' | 'name'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'optionId' | 'name'>
            & { choices: Array<(
              { __typename?: 'choice' }
              & Pick<Types.Choice, 'choiceId' | 'name'>
              & { tecAggregationMenu?: Types.Maybe<(
                { __typename?: 'tecAggregationMenu' }
                & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
              )> }
            )> }
          ) }
        )>, tecAggregationMenu?: Types.Maybe<(
          { __typename?: 'tecAggregationMenu' }
          & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
        )> }
      ) }
    )> }
  )> }
);

export type TecAggregationMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type TecAggregationMenusGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationMenusGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const TecAggregationMenusGetMenusDocument = gql`
    query TecAggregationMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
            choices {
              choiceId
              name
              tecAggregationMenu {
                id
                name
                menuCode
                dpCode
                dpName
                gpCode
                gpName
              }
            }
          }
        }
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;

/**
 * __useTecAggregationMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useTecAggregationMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMenusGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMenusGetMenusQuery, TecAggregationMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMenusGetMenusQuery, TecAggregationMenusGetMenusQueryVariables>(TecAggregationMenusGetMenusDocument, options);
      }
export function useTecAggregationMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMenusGetMenusQuery, TecAggregationMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMenusGetMenusQuery, TecAggregationMenusGetMenusQueryVariables>(TecAggregationMenusGetMenusDocument, options);
        }
export type TecAggregationMenusGetMenusQueryHookResult = ReturnType<typeof useTecAggregationMenusGetMenusQuery>;
export type TecAggregationMenusGetMenusLazyQueryHookResult = ReturnType<typeof useTecAggregationMenusGetMenusLazyQuery>;
export type TecAggregationMenusGetMenusQueryResult = Apollo.QueryResult<TecAggregationMenusGetMenusQuery, TecAggregationMenusGetMenusQueryVariables>;
export const TecAggregationMenusGetCategoriesDocument = gql`
    query TecAggregationMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useTecAggregationMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useTecAggregationMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMenusGetCategoriesQuery, TecAggregationMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMenusGetCategoriesQuery, TecAggregationMenusGetCategoriesQueryVariables>(TecAggregationMenusGetCategoriesDocument, options);
      }
export function useTecAggregationMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMenusGetCategoriesQuery, TecAggregationMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMenusGetCategoriesQuery, TecAggregationMenusGetCategoriesQueryVariables>(TecAggregationMenusGetCategoriesDocument, options);
        }
export type TecAggregationMenusGetCategoriesQueryHookResult = ReturnType<typeof useTecAggregationMenusGetCategoriesQuery>;
export type TecAggregationMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useTecAggregationMenusGetCategoriesLazyQuery>;
export type TecAggregationMenusGetCategoriesQueryResult = Apollo.QueryResult<TecAggregationMenusGetCategoriesQuery, TecAggregationMenusGetCategoriesQueryVariables>;
export const TecAggregationMenusGetShopsDocument = gql`
    query TecAggregationMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useTecAggregationMenusGetShopsQuery__
 *
 * To run a query within a React component, call `useTecAggregationMenusGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenusGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMenusGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationMenusGetShopsQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMenusGetShopsQuery, TecAggregationMenusGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMenusGetShopsQuery, TecAggregationMenusGetShopsQueryVariables>(TecAggregationMenusGetShopsDocument, options);
      }
export function useTecAggregationMenusGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMenusGetShopsQuery, TecAggregationMenusGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMenusGetShopsQuery, TecAggregationMenusGetShopsQueryVariables>(TecAggregationMenusGetShopsDocument, options);
        }
export type TecAggregationMenusGetShopsQueryHookResult = ReturnType<typeof useTecAggregationMenusGetShopsQuery>;
export type TecAggregationMenusGetShopsLazyQueryHookResult = ReturnType<typeof useTecAggregationMenusGetShopsLazyQuery>;
export type TecAggregationMenusGetShopsQueryResult = Apollo.QueryResult<TecAggregationMenusGetShopsQuery, TecAggregationMenusGetShopsQueryVariables>;