import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CopyMasterDataGetShops = gql`
    query CopyMasterDataGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export const CopyMasterDataGetCompanies = gql`
    query CopyMasterDataGetCompanies {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    id
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;
export const CopyMasterDataCopyMasterData = gql`
    mutation CopyMasterDataCopyMasterData($sourceShopId: String!, $targetShopId: String!, $enableCopyTable: Boolean!) {
  dashboardAccountCopyMasterData(
    input: {sourceShopId: $sourceShopId, targetShopId: $targetShopId, enableCopyTable: $enableCopyTable}
  )
}
    `;
export type CopyMasterDataGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CopyMasterDataGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);

export type CopyMasterDataGetCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CopyMasterDataGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'companyId' | 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId' | 'name'>
    )> }
  )> }
);

export type CopyMasterDataCopyMasterDataMutationVariables = Types.Exact<{
  sourceShopId: Types.Scalars['String'];
  targetShopId: Types.Scalars['String'];
  enableCopyTable: Types.Scalars['Boolean'];
}>;


export type CopyMasterDataCopyMasterDataMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCopyMasterData'>
);


export const CopyMasterDataGetShopsDocument = gql`
    query CopyMasterDataGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useCopyMasterDataGetShopsQuery__
 *
 * To run a query within a React component, call `useCopyMasterDataGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyMasterDataGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyMasterDataGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCopyMasterDataGetShopsQuery(baseOptions: Apollo.QueryHookOptions<CopyMasterDataGetShopsQuery, CopyMasterDataGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyMasterDataGetShopsQuery, CopyMasterDataGetShopsQueryVariables>(CopyMasterDataGetShopsDocument, options);
      }
export function useCopyMasterDataGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyMasterDataGetShopsQuery, CopyMasterDataGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyMasterDataGetShopsQuery, CopyMasterDataGetShopsQueryVariables>(CopyMasterDataGetShopsDocument, options);
        }
export type CopyMasterDataGetShopsQueryHookResult = ReturnType<typeof useCopyMasterDataGetShopsQuery>;
export type CopyMasterDataGetShopsLazyQueryHookResult = ReturnType<typeof useCopyMasterDataGetShopsLazyQuery>;
export type CopyMasterDataGetShopsQueryResult = Apollo.QueryResult<CopyMasterDataGetShopsQuery, CopyMasterDataGetShopsQueryVariables>;
export const CopyMasterDataGetCompaniesDocument = gql`
    query CopyMasterDataGetCompanies {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    id
    companyId
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;

/**
 * __useCopyMasterDataGetCompaniesQuery__
 *
 * To run a query within a React component, call `useCopyMasterDataGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyMasterDataGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyMasterDataGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCopyMasterDataGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CopyMasterDataGetCompaniesQuery, CopyMasterDataGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyMasterDataGetCompaniesQuery, CopyMasterDataGetCompaniesQueryVariables>(CopyMasterDataGetCompaniesDocument, options);
      }
export function useCopyMasterDataGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyMasterDataGetCompaniesQuery, CopyMasterDataGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyMasterDataGetCompaniesQuery, CopyMasterDataGetCompaniesQueryVariables>(CopyMasterDataGetCompaniesDocument, options);
        }
export type CopyMasterDataGetCompaniesQueryHookResult = ReturnType<typeof useCopyMasterDataGetCompaniesQuery>;
export type CopyMasterDataGetCompaniesLazyQueryHookResult = ReturnType<typeof useCopyMasterDataGetCompaniesLazyQuery>;
export type CopyMasterDataGetCompaniesQueryResult = Apollo.QueryResult<CopyMasterDataGetCompaniesQuery, CopyMasterDataGetCompaniesQueryVariables>;
export const CopyMasterDataCopyMasterDataDocument = gql`
    mutation CopyMasterDataCopyMasterData($sourceShopId: String!, $targetShopId: String!, $enableCopyTable: Boolean!) {
  dashboardAccountCopyMasterData(
    input: {sourceShopId: $sourceShopId, targetShopId: $targetShopId, enableCopyTable: $enableCopyTable}
  )
}
    `;
export type CopyMasterDataCopyMasterDataMutationFn = Apollo.MutationFunction<CopyMasterDataCopyMasterDataMutation, CopyMasterDataCopyMasterDataMutationVariables>;

/**
 * __useCopyMasterDataCopyMasterDataMutation__
 *
 * To run a mutation, you first call `useCopyMasterDataCopyMasterDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMasterDataCopyMasterDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMasterDataCopyMasterDataMutation, { data, loading, error }] = useCopyMasterDataCopyMasterDataMutation({
 *   variables: {
 *      sourceShopId: // value for 'sourceShopId'
 *      targetShopId: // value for 'targetShopId'
 *      enableCopyTable: // value for 'enableCopyTable'
 *   },
 * });
 */
export function useCopyMasterDataCopyMasterDataMutation(baseOptions?: Apollo.MutationHookOptions<CopyMasterDataCopyMasterDataMutation, CopyMasterDataCopyMasterDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyMasterDataCopyMasterDataMutation, CopyMasterDataCopyMasterDataMutationVariables>(CopyMasterDataCopyMasterDataDocument, options);
      }
export type CopyMasterDataCopyMasterDataMutationHookResult = ReturnType<typeof useCopyMasterDataCopyMasterDataMutation>;
export type CopyMasterDataCopyMasterDataMutationResult = Apollo.MutationResult<CopyMasterDataCopyMasterDataMutation>;
export type CopyMasterDataCopyMasterDataMutationOptions = Apollo.BaseMutationOptions<CopyMasterDataCopyMasterDataMutation, CopyMasterDataCopyMasterDataMutationVariables>;