import React, { memo, useMemo } from "react";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { ShopSelectDropdown } from "components/ShopSelector/ShopSelectDropdown";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useShopSelect } from "hooks/useShopSelect";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

export const PermittedShopSelector = memo(() => {
  const user = useCurrentUser();

  const { currentShopName, loading, setShop, shops } = useShopSelect();

  const { accessibleShopIds } = useCurrentUserPermissions();

  const filteredShops = useMemo(() => {
    if (user?.role === Role.ServiceAdmin) {
      return shops;
    }

    return shops.filter((shop) =>
      accessibleShopIds ? accessibleShopIds.includes(shop.shopId) : false,
    );
  }, [accessibleShopIds, shops, user?.role]);

  return (
    <div>
      <ShopSelectDropdown shops={filteredShops} setShop={setShop}>
        <Button loading={loading}>
          {currentShopName ?? "店舗を選択"} <DownOutlined />
        </Button>
      </ShopSelectDropdown>
    </div>
  );
});
