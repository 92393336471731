import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaire = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetrics = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetrics($input: QuestionnaireScoreChangesMetricsInput!) {
  questionnaireScoreChangesMetrics(input: $input) {
    repeatability {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    customerService {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    speed {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    deliciousness {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    cleanliness {
      dimension
      shopAverageScore
      corporationAverageScore
    }
  }
}
    `;
export const QuestionnaireAnalyticsScoreChangesMetricsGetShops = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id'>
  )> }
);

export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables = Types.Exact<{
  input: Types.QuestionnaireScoreChangesMetricsInput;
}>;


export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireScoreChangesMetrics: (
    { __typename?: 'QuestionnaireScoreChangesMetrics' }
    & { repeatability: Array<(
      { __typename?: 'AggregatedQuestionnaireScore' }
      & Pick<Types.AggregatedQuestionnaireScore, 'dimension' | 'shopAverageScore' | 'corporationAverageScore'>
    )>, customerService: Array<(
      { __typename?: 'AggregatedQuestionnaireScore' }
      & Pick<Types.AggregatedQuestionnaireScore, 'dimension' | 'shopAverageScore' | 'corporationAverageScore'>
    )>, speed: Array<(
      { __typename?: 'AggregatedQuestionnaireScore' }
      & Pick<Types.AggregatedQuestionnaireScore, 'dimension' | 'shopAverageScore' | 'corporationAverageScore'>
    )>, deliciousness: Array<(
      { __typename?: 'AggregatedQuestionnaireScore' }
      & Pick<Types.AggregatedQuestionnaireScore, 'dimension' | 'shopAverageScore' | 'corporationAverageScore'>
    )>, cleanliness: Array<(
      { __typename?: 'AggregatedQuestionnaireScore' }
      & Pick<Types.AggregatedQuestionnaireScore, 'dimension' | 'shopAverageScore' | 'corporationAverageScore'>
    )> }
  ) }
);

export type QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  questionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);


export const QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQueryVariables>;
export const QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsDocument = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetrics($input: QuestionnaireScoreChangesMetricsInput!) {
  questionnaireScoreChangesMetrics(input: $input) {
    repeatability {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    customerService {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    speed {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    deliciousness {
      dimension
      shopAverageScore
      corporationAverageScore
    }
    cleanliness {
      dimension
      shopAverageScore
      corporationAverageScore
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsDocument, options);
      }
export function useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsDocument, options);
        }
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsLazyQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQueryVariables>;
export const QuestionnaireAnalyticsScoreChangesMetricsGetShopsDocument = gql`
    query QuestionnaireAnalyticsScoreChangesMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetShopsDocument, options);
      }
export function useQuestionnaireAnalyticsScoreChangesMetricsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsScoreChangesMetricsGetShopsDocument, options);
        }
export type QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetShopsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoreChangesMetricsGetShopsLazyQuery>;
export type QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery, QuestionnaireAnalyticsScoreChangesMetricsGetShopsQueryVariables>;