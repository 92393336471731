import React, { memo, useMemo } from "react";
import { Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { gmoOffPaymentMethodTypeDetails } from "models/gmoOffPaymentMethodType";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Select } from "components/Input/Select";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { GmoOffPaymentMethodTypeEnum } from "types/graphql";

import { GmoOffPaymentOnSitePaymentDetailType, OnSitePaymentDetailType } from "../types";

type Props = {
  gmoOffPaymentOnSitePaymentDetailTypes: GmoOffPaymentOnSitePaymentDetailType[];
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  loading: boolean;
  onUpdate: ({
    gmoOffPaymentMethodType,
    onSitePaymentDetailTypeIds,
  }: {
    gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum;
    onSitePaymentDetailTypeIds: string[];
  }) => void;
  onDelete: (toDeleteId: GmoOffPaymentMethodTypeEnum) => void;
};

type DataSource = {
  gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum;
  selectedOnSitePaymentDetailTypeIds: string[];
};

export const DetailTypeTable = memo<Props>(
  ({
    gmoOffPaymentOnSitePaymentDetailTypes,
    onSitePaymentDetailTypes,
    loading,
    onDelete,
    onUpdate,
  }) => {
    const [pagination, setPagination] = usePagination();

    const dataSource = useMemo<DataSource[]>(
      () =>
        Object.values(GmoOffPaymentMethodTypeEnum).map((gmoOffPaymentMethodType) => ({
          gmoOffPaymentMethodType,
          selectedOnSitePaymentDetailTypeIds: gmoOffPaymentOnSitePaymentDetailTypes
            .filter(
              (gmoOffPaymentOnSitePaymentDetailType) =>
                gmoOffPaymentOnSitePaymentDetailType.gmoOffPaymentMethodType ===
                gmoOffPaymentMethodType,
            )
            .map(({ onSitePaymentDetailTypeId }) => onSitePaymentDetailTypeId),
        })),
      [gmoOffPaymentOnSitePaymentDetailTypes],
    );

    const columns: ColumnsType<DataSource> = useMemo(
      () => [
        {
          title: "オフライン端末 支払方法",
          width: 300,
          fixed: "left",
          render: (_, { gmoOffPaymentMethodType }) =>
            gmoOffPaymentMethodTypeDetails[gmoOffPaymentMethodType].label,
        },
        {
          title: "支払方法",
          fixed: "left",
          render(_, { gmoOffPaymentMethodType, selectedOnSitePaymentDetailTypeIds }) {
            return (
              <Select
                mode="multiple"
                value={selectedOnSitePaymentDetailTypeIds}
                options={onSitePaymentDetailTypes.map(({ label, id }) => ({ label, value: id }))}
                onChange={(onSitePaymentDetailTypeIds) =>
                  onUpdate({
                    gmoOffPaymentMethodType,
                    onSitePaymentDetailTypeIds,
                  })
                }
                fullWidth
              />
            );
          },
        },
        {
          title: "",
          align: "center",
          width: 60,
          render(_, { gmoOffPaymentMethodType, selectedOnSitePaymentDetailTypeIds }) {
            const disabled = selectedOnSitePaymentDetailTypeIds.length === 0;
            return (
              <Popconfirm
                title={
                  <>
                    <Paragraph>オフライン端末支払方法設定を削除しますか？</Paragraph>
                    <Paragraph>
                      一度削除したオフライン端末支払方法設定を元に戻すことはできません。
                    </Paragraph>
                  </>
                }
                okText="はい"
                cancelText="キャンセル"
                onConfirm={() => onDelete(gmoOffPaymentMethodType)}
                disabled={disabled}
              >
                <DeleteIcon disabled={disabled} />
              </Popconfirm>
            );
          },
        },
      ],
      [onSitePaymentDetailTypes, onUpdate, onDelete],
    );

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        loading={loading}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
