import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { placeholder } from "../placeholder";
import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TitleField = memo<Props>((props) => (
  <AddCouponFormItem
    label="クーポン表示名"
    name="title"
    rules={[{ required: true, message: "クーポン表示名を入力してください" }]}
    {...props}
  >
    <Input placeholder={placeholder.title} />
  </AddCouponFormItem>
));
