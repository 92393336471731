import { useEffect, useMemo } from "react";

import { useCorporation } from "hooks/useCorporation";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { useShopSelectorGetShopsPerCorporationQuery } from "../../components/ShopSelector/queries";

export const useShopSelectPerCorporation = () => {
  const [corporation] = useCorporation();

  const { data: shopsData, loading } = useShopSelectorGetShopsPerCorporationQuery(
    corporation
      ? {
          variables: { corporationId: corporation.corporationId },
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );

  const shops = useMemo(() => shopsData?.shop ?? [], [shopsData?.shop]);

  const { shop, setShop } = useShopPerCorporation();

  const currentShop = useMemo(() => {
    const currentShop = shops.find((s) => s.shopId === shop?.shopId);
    return currentShop;
  }, [shop?.shopId, shops]);
  const currentShopName = currentShop?.name;

  useEffect(() => {
    if (currentShop) return;
    const fallbackShopId = shops[0]?.shopId;
    if (fallbackShopId) {
      setShop(fallbackShopId);
    }
  }, [setShop, shops, currentShop]);

  return { loading, shops, setShop, currentShopName, currentShop };
};
