import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const KitchenDisplayDisplayTargetMenusGetKdDisplayTarget = gql`
    query KitchenDisplayDisplayTargetMenusGetKdDisplayTarget($kdDisplayTargetId: uuid!) {
  kdDisplayTarget_by_pk(id: $kdDisplayTargetId) {
    id
    name
    shop {
      companyId
      shopId
    }
    shopMenuKdDisplayTargets(
      where: {shopMenu: {archivedAt: {_is_null: true}, menu: {archivedAt: {_is_null: true}}}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopMenu {
        menuId: _menuId
        menu {
          menuId
          name
          categoryMenus(
            where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
          ) {
            categoryId: _categoryId
            category {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export const KitchenDisplayDisplayTargetMenusGetCategories = gql`
    query KitchenDisplayDisplayTargetMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenus = gql`
    mutation KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenus($kdDisplayTargetId: uuid!, $menuId: Int!) {
  delete_shopMenuKdDisplayTarget(
    where: {_and: [{kdDisplayTargetId: {_eq: $kdDisplayTargetId}, _menuId: {_eq: $menuId}}]}
  ) {
    affected_rows
    returning {
      kdDisplayTargetId
    }
  }
}
    `;
export type KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables = Types.Exact<{
  kdDisplayTargetId: Types.Scalars['uuid'];
}>;


export type KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery = (
  { __typename?: 'query_root' }
  & { kdDisplayTarget_by_pk?: Types.Maybe<(
    { __typename?: 'kdDisplayTarget' }
    & Pick<Types.KdDisplayTarget, 'id' | 'name'>
    & { shop: (
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'companyId' | 'shopId'>
    ), shopMenuKdDisplayTargets: Array<(
      { __typename?: 'shopMenuKdDisplayTarget' }
      & { shopMenu?: Types.Maybe<(
        { __typename?: 'shopMenu' }
        & { menuId: Types.ShopMenu['_menuId'] }
        & { menu: (
          { __typename?: 'menu' }
          & Pick<Types.Menu, 'menuId' | 'name'>
          & { categoryMenus: Array<(
            { __typename?: 'categoryMenu' }
            & { categoryId: Types.CategoryMenu['_categoryId'] }
            & { category: (
              { __typename?: 'category' }
              & Pick<Types.Category, 'name'>
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type KitchenDisplayDisplayTargetMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationVariables = Types.Exact<{
  kdDisplayTargetId: Types.Scalars['uuid'];
  menuId: Types.Scalars['Int'];
}>;


export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuKdDisplayTarget?: Types.Maybe<(
    { __typename?: 'shopMenuKdDisplayTarget_mutation_response' }
    & Pick<Types.ShopMenuKdDisplayTargetMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopMenuKdDisplayTarget' }
      & Pick<Types.ShopMenuKdDisplayTarget, 'kdDisplayTargetId'>
    )> }
  )> }
);


export const KitchenDisplayDisplayTargetMenusGetKdDisplayTargetDocument = gql`
    query KitchenDisplayDisplayTargetMenusGetKdDisplayTarget($kdDisplayTargetId: uuid!) {
  kdDisplayTarget_by_pk(id: $kdDisplayTargetId) {
    id
    name
    shop {
      companyId
      shopId
    }
    shopMenuKdDisplayTargets(
      where: {shopMenu: {archivedAt: {_is_null: true}, menu: {archivedAt: {_is_null: true}}}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopMenu {
        menuId: _menuId
        menu {
          menuId
          name
          categoryMenus(
            where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
          ) {
            categoryId: _categoryId
            category {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery__
 *
 * To run a query within a React component, call `useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery({
 *   variables: {
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *   },
 * });
 */
export function useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery(baseOptions: Apollo.QueryHookOptions<KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery, KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery, KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables>(KitchenDisplayDisplayTargetMenusGetKdDisplayTargetDocument, options);
      }
export function useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery, KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery, KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables>(KitchenDisplayDisplayTargetMenusGetKdDisplayTargetDocument, options);
        }
export type KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery>;
export type KitchenDisplayDisplayTargetMenusGetKdDisplayTargetLazyQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetLazyQuery>;
export type KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryResult = Apollo.QueryResult<KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery, KitchenDisplayDisplayTargetMenusGetKdDisplayTargetQueryVariables>;
export const KitchenDisplayDisplayTargetMenusGetCategoriesDocument = gql`
    query KitchenDisplayDisplayTargetMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useKitchenDisplayDisplayTargetMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useKitchenDisplayDisplayTargetMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenDisplayDisplayTargetMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenDisplayDisplayTargetMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useKitchenDisplayDisplayTargetMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<KitchenDisplayDisplayTargetMenusGetCategoriesQuery, KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenDisplayDisplayTargetMenusGetCategoriesQuery, KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables>(KitchenDisplayDisplayTargetMenusGetCategoriesDocument, options);
      }
export function useKitchenDisplayDisplayTargetMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenDisplayDisplayTargetMenusGetCategoriesQuery, KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenDisplayDisplayTargetMenusGetCategoriesQuery, KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables>(KitchenDisplayDisplayTargetMenusGetCategoriesDocument, options);
        }
export type KitchenDisplayDisplayTargetMenusGetCategoriesQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetMenusGetCategoriesQuery>;
export type KitchenDisplayDisplayTargetMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetMenusGetCategoriesLazyQuery>;
export type KitchenDisplayDisplayTargetMenusGetCategoriesQueryResult = Apollo.QueryResult<KitchenDisplayDisplayTargetMenusGetCategoriesQuery, KitchenDisplayDisplayTargetMenusGetCategoriesQueryVariables>;
export const KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusDocument = gql`
    mutation KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenus($kdDisplayTargetId: uuid!, $menuId: Int!) {
  delete_shopMenuKdDisplayTarget(
    where: {_and: [{kdDisplayTargetId: {_eq: $kdDisplayTargetId}, _menuId: {_eq: $menuId}}]}
  ) {
    affected_rows
    returning {
      kdDisplayTargetId
    }
  }
}
    `;
export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationFn = Apollo.MutationFunction<KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation, KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationVariables>;

/**
 * __useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation__
 *
 * To run a mutation, you first call `useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation, { data, loading, error }] = useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation({
 *   variables: {
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation(baseOptions?: Apollo.MutationHookOptions<KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation, KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation, KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationVariables>(KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusDocument, options);
      }
export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation>;
export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationResult = Apollo.MutationResult<KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation>;
export type KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationOptions = Apollo.BaseMutationOptions<KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation, KitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutationVariables>;