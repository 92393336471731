import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiPlansGetPlans = gql`
    query PikaichiPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const PikaichiPlansGetCategories = gql`
    query PikaichiPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const PikaichiPlansGetShops = gql`
    query PikaichiPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const PikaichiPlansDeletePikaichiMenu = gql`
    mutation PikaichiPlansDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiPlansGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plans: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'name' | 'planOptionId'>
    )>, pikaichiMenuPlans: Array<(
      { __typename?: 'pikaichiMenuPlan' }
      & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId'>
      & { planId: Types.PikaichiMenuPlan['_planId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type PikaichiPlansGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiPlansGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type PikaichiPlansGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiPlansGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type PikaichiPlansDeletePikaichiMenuMutationVariables = Types.Exact<{
  pikaichiMenuId: Types.Scalars['uuid'];
}>;


export type PikaichiPlansDeletePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_pikaichiMenu_by_pk?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )> }
);


export const PikaichiPlansGetPlansDocument = gql`
    query PikaichiPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    pikaichiMenuPlans {
      pikaichiMenuId
      planId: _planId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __usePikaichiPlansGetPlansQuery__
 *
 * To run a query within a React component, call `usePikaichiPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiPlansGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<PikaichiPlansGetPlansQuery, PikaichiPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiPlansGetPlansQuery, PikaichiPlansGetPlansQueryVariables>(PikaichiPlansGetPlansDocument, options);
      }
export function usePikaichiPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiPlansGetPlansQuery, PikaichiPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiPlansGetPlansQuery, PikaichiPlansGetPlansQueryVariables>(PikaichiPlansGetPlansDocument, options);
        }
export type PikaichiPlansGetPlansQueryHookResult = ReturnType<typeof usePikaichiPlansGetPlansQuery>;
export type PikaichiPlansGetPlansLazyQueryHookResult = ReturnType<typeof usePikaichiPlansGetPlansLazyQuery>;
export type PikaichiPlansGetPlansQueryResult = Apollo.QueryResult<PikaichiPlansGetPlansQuery, PikaichiPlansGetPlansQueryVariables>;
export const PikaichiPlansGetCategoriesDocument = gql`
    query PikaichiPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __usePikaichiPlansGetCategoriesQuery__
 *
 * To run a query within a React component, call `usePikaichiPlansGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlansGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiPlansGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiPlansGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PikaichiPlansGetCategoriesQuery, PikaichiPlansGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiPlansGetCategoriesQuery, PikaichiPlansGetCategoriesQueryVariables>(PikaichiPlansGetCategoriesDocument, options);
      }
export function usePikaichiPlansGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiPlansGetCategoriesQuery, PikaichiPlansGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiPlansGetCategoriesQuery, PikaichiPlansGetCategoriesQueryVariables>(PikaichiPlansGetCategoriesDocument, options);
        }
export type PikaichiPlansGetCategoriesQueryHookResult = ReturnType<typeof usePikaichiPlansGetCategoriesQuery>;
export type PikaichiPlansGetCategoriesLazyQueryHookResult = ReturnType<typeof usePikaichiPlansGetCategoriesLazyQuery>;
export type PikaichiPlansGetCategoriesQueryResult = Apollo.QueryResult<PikaichiPlansGetCategoriesQuery, PikaichiPlansGetCategoriesQueryVariables>;
export const PikaichiPlansGetShopsDocument = gql`
    query PikaichiPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __usePikaichiPlansGetShopsQuery__
 *
 * To run a query within a React component, call `usePikaichiPlansGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlansGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiPlansGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiPlansGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PikaichiPlansGetShopsQuery, PikaichiPlansGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiPlansGetShopsQuery, PikaichiPlansGetShopsQueryVariables>(PikaichiPlansGetShopsDocument, options);
      }
export function usePikaichiPlansGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiPlansGetShopsQuery, PikaichiPlansGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiPlansGetShopsQuery, PikaichiPlansGetShopsQueryVariables>(PikaichiPlansGetShopsDocument, options);
        }
export type PikaichiPlansGetShopsQueryHookResult = ReturnType<typeof usePikaichiPlansGetShopsQuery>;
export type PikaichiPlansGetShopsLazyQueryHookResult = ReturnType<typeof usePikaichiPlansGetShopsLazyQuery>;
export type PikaichiPlansGetShopsQueryResult = Apollo.QueryResult<PikaichiPlansGetShopsQuery, PikaichiPlansGetShopsQueryVariables>;
export const PikaichiPlansDeletePikaichiMenuDocument = gql`
    mutation PikaichiPlansDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiPlansDeletePikaichiMenuMutationFn = Apollo.MutationFunction<PikaichiPlansDeletePikaichiMenuMutation, PikaichiPlansDeletePikaichiMenuMutationVariables>;

/**
 * __usePikaichiPlansDeletePikaichiMenuMutation__
 *
 * To run a mutation, you first call `usePikaichiPlansDeletePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlansDeletePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pikaichiPlansDeletePikaichiMenuMutation, { data, loading, error }] = usePikaichiPlansDeletePikaichiMenuMutation({
 *   variables: {
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *   },
 * });
 */
export function usePikaichiPlansDeletePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<PikaichiPlansDeletePikaichiMenuMutation, PikaichiPlansDeletePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PikaichiPlansDeletePikaichiMenuMutation, PikaichiPlansDeletePikaichiMenuMutationVariables>(PikaichiPlansDeletePikaichiMenuDocument, options);
      }
export type PikaichiPlansDeletePikaichiMenuMutationHookResult = ReturnType<typeof usePikaichiPlansDeletePikaichiMenuMutation>;
export type PikaichiPlansDeletePikaichiMenuMutationResult = Apollo.MutationResult<PikaichiPlansDeletePikaichiMenuMutation>;
export type PikaichiPlansDeletePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<PikaichiPlansDeletePikaichiMenuMutation, PikaichiPlansDeletePikaichiMenuMutationVariables>;