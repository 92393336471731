import React, { memo } from "react";
import { Select } from "antd";
import { taxMethods } from "models/taxMethod";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

const { Option } = Select;

type Props = Omit<FormItemProps, "children" | "name">;

export const TaxMethodField = memo<Props>((props) => (
  <AddMenuFormItem label="税種別" name="taxMethod" required {...props}>
    <Select<string>>
      {Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => (
        <Option key={taxMethod} value={taxMethod}>
          {taxMethodName}
        </Option>
      ))}
    </Select>
  </AddMenuFormItem>
));
