import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { MenusField } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/AddMenuForm/MenusField";
import {
  AddMenuFormValues,
  useAddMenuForm,
} from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/AddMenuForm/useAddMenuForm";
import { Category } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/types";
import { Plan } from "pages/PlanFirstOrderCategoryMenus/types";

type Props = {
  plan: Plan;
  categories: Category[];
  onChange: (plan: AddMenuFormValues) => void;
};

export const AddMenuForm = memo<Props>(({ plan, categories, onChange }) => {
  const { form, change: onValuesChange } = useAddMenuForm(onChange);

  const categoryMenuIds = plan.planFirstOrderCategoryMenus.flatMap(
    ({ categoryMenu }) => categoryMenu.categoryMenuId,
  );
  const filteredCategories = categories.map((category) => ({
    ...category,
    categoryMenus: category.categoryMenus.filter(
      ({ categoryMenuId }) => !categoryMenuIds.includes(categoryMenuId),
    ),
  }));

  return (
    <Form name="menus" form={form} onValuesChange={onValuesChange} layout="vertical">
      <MenusField categories={filteredCategories} />
    </Form>
  );
});
