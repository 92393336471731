import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { DishUpSlipGroupHeader } from "components/PageHeader/DishUpReceiptGroupHeader";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { DishUpSlipGroupRolesInsertInput, DishUpSlipGroupSetInput } from "types/graphql";

import { EditDishUpSlipGroupForm } from "./EditDishUpSlipGroupForm";
import {
  useEditDishUpSlipGroupGetDishUpSlipGroupsQuery,
  useEditDishUpSlipGroupGetRolesQuery,
  useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation,
} from "./queries";

export const EditDishUpSlipGroup = () => {
  const { id } = useParams<{ id: string }>();
  const dishUpSlipGroupId = Number(id);
  const navigate = useNavigate();

  const {
    data: getDishUpSlipGroupData,
    loading: loadingDishUpSlipGroup,
    refetch: refetchDishUpSlipGroup,
    error,
  } = useEditDishUpSlipGroupGetDishUpSlipGroupsQuery(
    !Number.isNaN(dishUpSlipGroupId) ? { variables: { dishUpSlipGroupId } } : { skip: true },
  );
  const dishUpSlipGroup = getDishUpSlipGroupData?.dishUpSlipGroup[0];

  useSetCurrentCompanyEffect(dishUpSlipGroup?.shop);

  const shopId = dishUpSlipGroup?.shopId;

  const { data: getRolesData } = useEditDishUpSlipGroupGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const [updateDishUpSlipGroupMutation, { loading: loadingUpdateDishUpReceipt }] =
    useEditDishUpSlipGroupUpdateDishUpSlipGroupMutation();

  const onSubmit = useCallback(
    async ({
      dishUpSlipGroup,
      dishUpSlipGroupRoles,
    }: {
      dishUpSlipGroup: DishUpSlipGroupSetInput;
      dishUpSlipGroupRoles: DishUpSlipGroupRolesInsertInput[];
    }) => {
      if (!Number.isNaN(dishUpSlipGroupId)) {
        try {
          if (dishUpSlipGroup.name?.length === 0 || dishUpSlipGroupRoles.length === 0) {
            throw new Error("");
          }
          const roleIds = dishUpSlipGroupRoles.map(({ roleId }) => roleId);

          await updateDishUpSlipGroupMutation({
            variables: {
              dishUpSlipGroupId,
              dishUpSlipGroup,
              roleIds,
              dishUpSlipGroupRoles,
            },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
        await refetchDishUpSlipGroup();
      }
    },
    [dishUpSlipGroupId, updateDishUpSlipGroupMutation, refetchDishUpSlipGroup],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("編集の保存に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout
      title={dishUpSlipGroup?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "デシャップ伝票グループ" }],
      }}
    >
      <DishUpSlipGroupHeader dishUpSlipGroup={dishUpSlipGroup ?? null} onBack={goBack} />
      {loadingDishUpSlipGroup && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {dishUpSlipGroup && shopId && (
        <EditDishUpSlipGroupForm
          shopId={shopId}
          onSubmit={onSubmit}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
          dishUpSlipGroup={dishUpSlipGroup}
          roles={roles}
          loading={loadingUpdateDishUpReceipt}
        />
      )}
    </DashboardLayout>
  );
};
