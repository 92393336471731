import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { FormContent } from "components/Template/FormTemplate";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditPlanOptionRoleForm } from "pages/EditShopPlanOptionRoles/EditPlanOptionRoleForm";
import {
  useEditShopPlanOptionRolesGetPlanOptionQuery,
  useEditShopPlanOptionRolesGetRolesQuery,
} from "pages/EditShopPlanOptionRoles/queries";

export const EditShopPlanOptionRoles = () => {
  const { shopId = "", planOptionId: planOptionIdString = "" } =
    useParams<Record<"shopId" | "planOptionId", string>>();
  const planOptionId = Number(planOptionIdString);
  const navigate = useNavigate();

  const {
    data: getPlanOption,
    loading: loadingPlan,
    error,
  } = useEditShopPlanOptionRolesGetPlanOptionQuery(
    !Number.isNaN(planOptionId) && shopId
      ? { variables: { planOptionId, shopId } }
      : { skip: true },
  );
  const planOption = getPlanOption?.planOption?.[0];

  const companyId = planOption?.plan?.companyId;

  useSetCurrentCompanyEffect(companyId && shopId ? { companyId, shopId } : null);

  const planOptionRoles =
    planOption?.shopPlanOptionKitchenRoles?.map(({ role }) => role)?.filter(isNotNullable) ?? [];

  const { data: getRolesData, loading: loadingRoles } = useEditShopPlanOptionRolesGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={planOption?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "プランオプション" }],
      }}
    >
      <PageHeader title={planOption?.name} onBack={goBack} />
      {(loadingPlan || loadingRoles) && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planOption && roles && (
        <FormContent>
          <EditPlanOptionRoleForm
            shopId={shopId}
            planOption={planOption}
            planOptionRoles={planOptionRoles}
            roles={roles}
          />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
