import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories = gql`
    query EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories {
  onSitePaymentDetailTypeCategoryPriority(order_by: {priority: asc}) {
    value
  }
}
    `;
export const EditOnSitePaymentDetailTypeGetOnSitePaymentDetailType = gql`
    query EditOnSitePaymentDetailTypeGetOnSitePaymentDetailType($id: uuid!) {
  onSitePaymentDetailType(where: {id: {_eq: $id}}) {
    id
    type
    label
    onSitePaymentDetailTypeCategory
    allowChange
    isEditable
  }
}
    `;
export const EditOnSitePaymentDetailTypeGetShops = gql`
    query EditOnSitePaymentDetailTypeGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopPaymentTypes(
      where: {onSitePaymentDetailType: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;
export const EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailType = gql`
    mutation EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailType($onSitePaymentDetailTypeId: uuid!, $onSitePaymentDetailType: onSitePaymentDetailType_set_input!) {
  update_onSitePaymentDetailType(
    _set: $onSitePaymentDetailType
    where: {id: {_eq: $onSitePaymentDetailTypeId}}
  ) {
    affected_rows
  }
}
    `;
export const EditOnSitePaymentDetailTypeUpdateShopPaymentType = gql`
    mutation EditOnSitePaymentDetailTypeUpdateShopPaymentType($shopPaymentType: shopPaymentType_insert_input!) {
  insert_shopPaymentType_one(
    object: $shopPaymentType
    on_conflict: {constraint: shopPaymentType_pkey, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export const EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypes = gql`
    mutation EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypes($shopPaymentTypes: [shopPaymentType_insert_input!]!) {
  insert_shopPaymentType(
    objects: $shopPaymentTypes
    on_conflict: {constraint: shopPaymentType_pkey, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailTypeCategoryPriority: Array<(
    { __typename?: 'onSitePaymentDetailTypeCategoryPriority' }
    & Pick<Types.OnSitePaymentDetailTypeCategoryPriority, 'value'>
  )> }
);

export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label' | 'onSitePaymentDetailTypeCategory' | 'allowChange' | 'isEditable'>
  )> }
);

export type EditOnSitePaymentDetailTypeGetShopsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDetailTypeGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { shopPaymentTypes: Array<(
      { __typename?: 'shopPaymentType' }
      & Pick<Types.ShopPaymentType, 'isDisplay'>
    )> }
  )> }
);

export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  onSitePaymentDetailTypeId: Types.Scalars['uuid'];
  onSitePaymentDetailType: Types.OnSitePaymentDetailTypeSetInput;
}>;


export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_onSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDetailType_mutation_response' }
    & Pick<Types.OnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);

export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationVariables = Types.Exact<{
  shopPaymentType: Types.ShopPaymentTypeInsertInput;
}>;


export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopPaymentType_one?: Types.Maybe<(
    { __typename?: 'shopPaymentType' }
    & Pick<Types.ShopPaymentType, 'shopId'>
  )> }
);

export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationVariables = Types.Exact<{
  shopPaymentTypes: Array<Types.ShopPaymentTypeInsertInput> | Types.ShopPaymentTypeInsertInput;
}>;


export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopPaymentType?: Types.Maybe<(
    { __typename?: 'shopPaymentType_mutation_response' }
    & Pick<Types.ShopPaymentTypeMutationResponse, 'affected_rows'>
  )> }
);


export const EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument = gql`
    query EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories {
  onSitePaymentDetailTypeCategoryPriority(order_by: {priority: asc}) {
    value
  }
}
    `;

/**
 * __useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>(EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument, options);
      }
export function useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>(EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument, options);
        }
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery>;
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQuery>;
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryResult = Apollo.QueryResult<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>;
export const EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeDocument = gql`
    query EditOnSitePaymentDetailTypeGetOnSitePaymentDetailType($id: uuid!) {
  onSitePaymentDetailType(where: {id: {_eq: $id}}) {
    id
    type
    label
    onSitePaymentDetailTypeCategory
    allowChange
    isEditable
  }
}
    `;

/**
 * __useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables>(EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeDocument, options);
      }
export function useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables>(EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeDocument, options);
        }
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery>;
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeLazyQuery>;
export type EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryResult = Apollo.QueryResult<EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery, EditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQueryVariables>;
export const EditOnSitePaymentDetailTypeGetShopsDocument = gql`
    query EditOnSitePaymentDetailTypeGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopPaymentTypes(
      where: {onSitePaymentDetailType: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;

/**
 * __useEditOnSitePaymentDetailTypeGetShopsQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDetailTypeGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDetailTypeGetShopsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDetailTypeGetShopsQuery, EditOnSitePaymentDetailTypeGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDetailTypeGetShopsQuery, EditOnSitePaymentDetailTypeGetShopsQueryVariables>(EditOnSitePaymentDetailTypeGetShopsDocument, options);
      }
export function useEditOnSitePaymentDetailTypeGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDetailTypeGetShopsQuery, EditOnSitePaymentDetailTypeGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDetailTypeGetShopsQuery, EditOnSitePaymentDetailTypeGetShopsQueryVariables>(EditOnSitePaymentDetailTypeGetShopsDocument, options);
        }
export type EditOnSitePaymentDetailTypeGetShopsQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetShopsQuery>;
export type EditOnSitePaymentDetailTypeGetShopsLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeGetShopsLazyQuery>;
export type EditOnSitePaymentDetailTypeGetShopsQueryResult = Apollo.QueryResult<EditOnSitePaymentDetailTypeGetShopsQuery, EditOnSitePaymentDetailTypeGetShopsQueryVariables>;
export const EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeDocument = gql`
    mutation EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailType($onSitePaymentDetailTypeId: uuid!, $onSitePaymentDetailType: onSitePaymentDetailType_set_input!) {
  update_onSitePaymentDetailType(
    _set: $onSitePaymentDetailType
    where: {id: {_eq: $onSitePaymentDetailTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation, EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation, { data, loading, error }] = useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      onSitePaymentDetailTypeId: // value for 'onSitePaymentDetailTypeId'
 *      onSitePaymentDetailType: // value for 'onSitePaymentDetailType'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation, EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation, EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationVariables>(EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeDocument, options);
      }
export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation>;
export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation>;
export type EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation, EditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutationVariables>;
export const EditOnSitePaymentDetailTypeUpdateShopPaymentTypeDocument = gql`
    mutation EditOnSitePaymentDetailTypeUpdateShopPaymentType($shopPaymentType: shopPaymentType_insert_input!) {
  insert_shopPaymentType_one(
    object: $shopPaymentType
    on_conflict: {constraint: shopPaymentType_pkey, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationFn = Apollo.MutationFunction<EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation, EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationVariables>;

/**
 * __useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation, { data, loading, error }] = useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation({
 *   variables: {
 *      shopPaymentType: // value for 'shopPaymentType'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation, EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation, EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationVariables>(EditOnSitePaymentDetailTypeUpdateShopPaymentTypeDocument, options);
      }
export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation>;
export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationResult = Apollo.MutationResult<EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation>;
export type EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation, EditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutationVariables>;
export const EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesDocument = gql`
    mutation EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypes($shopPaymentTypes: [shopPaymentType_insert_input!]!) {
  insert_shopPaymentType(
    objects: $shopPaymentTypes
    on_conflict: {constraint: shopPaymentType_pkey, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationFn = Apollo.MutationFunction<EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation, EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationVariables>;

/**
 * __useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation, { data, loading, error }] = useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation({
 *   variables: {
 *      shopPaymentTypes: // value for 'shopPaymentTypes'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation, EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation, EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationVariables>(EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesDocument, options);
      }
export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation>;
export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationResult = Apollo.MutationResult<EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation>;
export type EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation, EditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutationVariables>;