import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CompaniesGetCompanies = gql`
    query CompaniesGetCompanies {
  company(where: {archivedAt: {_is_null: true}}) {
    name
    id
    companyId
    corporation {
      id
      name
    }
  }
}
    `;
export const CompaniesDeleteCompanies = gql`
    mutation CompaniesDeleteCompanies($companyId: uuid!, $companySerialId: Int!) {
  delete_onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, type: {_eq: "Cash"}}
  ) {
    affected_rows
  }
  delete_inflowSourceTag(
    where: {companyId: {_eq: $companyId}, isWalkIn: {_eq: true}}
  ) {
    affected_rows
  }
  delete_onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, type: {_eq: "FractionalDiscount"}}
  ) {
    affected_rows
  }
  delete_membershipRankConfig(where: {companyId: {_eq: $companyId}}) {
    affected_rows
  }
  delete_company(where: {companyId: {_eq: $companySerialId}}) {
    affected_rows
  }
}
    `;
export const CompaniesArchiveCompanies = gql`
    mutation CompaniesArchiveCompanies($input: ArchiveCompanyInput!) {
  dashboardAccountArchiveCompany(input: $input)
}
    `;
export type CompaniesGetCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompaniesGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'name' | 'id' | 'companyId'>
    & { corporation?: Types.Maybe<(
      { __typename?: 'corporation' }
      & Pick<Types.Corporation, 'id' | 'name'>
    )> }
  )> }
);

export type CompaniesDeleteCompaniesMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  companySerialId: Types.Scalars['Int'];
}>;


export type CompaniesDeleteCompaniesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_onSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDetailType_mutation_response' }
    & Pick<Types.OnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )>, delete_inflowSourceTag?: Types.Maybe<(
    { __typename?: 'inflowSourceTag_mutation_response' }
    & Pick<Types.InflowSourceTagMutationResponse, 'affected_rows'>
  )>, delete_onSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDiscountType_mutation_response' }
    & Pick<Types.OnSitePaymentDiscountTypeMutationResponse, 'affected_rows'>
  )>, delete_membershipRankConfig?: Types.Maybe<(
    { __typename?: 'membershipRankConfig_mutation_response' }
    & Pick<Types.MembershipRankConfigMutationResponse, 'affected_rows'>
  )>, delete_company?: Types.Maybe<(
    { __typename?: 'company_mutation_response' }
    & Pick<Types.CompanyMutationResponse, 'affected_rows'>
  )> }
);

export type CompaniesArchiveCompaniesMutationVariables = Types.Exact<{
  input: Types.ArchiveCompanyInput;
}>;


export type CompaniesArchiveCompaniesMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountArchiveCompany'>
);


export const CompaniesGetCompaniesDocument = gql`
    query CompaniesGetCompanies {
  company(where: {archivedAt: {_is_null: true}}) {
    name
    id
    companyId
    corporation {
      id
      name
    }
  }
}
    `;

/**
 * __useCompaniesGetCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesGetCompaniesQuery, CompaniesGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesGetCompaniesQuery, CompaniesGetCompaniesQueryVariables>(CompaniesGetCompaniesDocument, options);
      }
export function useCompaniesGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesGetCompaniesQuery, CompaniesGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesGetCompaniesQuery, CompaniesGetCompaniesQueryVariables>(CompaniesGetCompaniesDocument, options);
        }
export type CompaniesGetCompaniesQueryHookResult = ReturnType<typeof useCompaniesGetCompaniesQuery>;
export type CompaniesGetCompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesGetCompaniesLazyQuery>;
export type CompaniesGetCompaniesQueryResult = Apollo.QueryResult<CompaniesGetCompaniesQuery, CompaniesGetCompaniesQueryVariables>;
export const CompaniesDeleteCompaniesDocument = gql`
    mutation CompaniesDeleteCompanies($companyId: uuid!, $companySerialId: Int!) {
  delete_onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, type: {_eq: "Cash"}}
  ) {
    affected_rows
  }
  delete_inflowSourceTag(
    where: {companyId: {_eq: $companyId}, isWalkIn: {_eq: true}}
  ) {
    affected_rows
  }
  delete_onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, type: {_eq: "FractionalDiscount"}}
  ) {
    affected_rows
  }
  delete_membershipRankConfig(where: {companyId: {_eq: $companyId}}) {
    affected_rows
  }
  delete_company(where: {companyId: {_eq: $companySerialId}}) {
    affected_rows
  }
}
    `;
export type CompaniesDeleteCompaniesMutationFn = Apollo.MutationFunction<CompaniesDeleteCompaniesMutation, CompaniesDeleteCompaniesMutationVariables>;

/**
 * __useCompaniesDeleteCompaniesMutation__
 *
 * To run a mutation, you first call `useCompaniesDeleteCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompaniesDeleteCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companiesDeleteCompaniesMutation, { data, loading, error }] = useCompaniesDeleteCompaniesMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companySerialId: // value for 'companySerialId'
 *   },
 * });
 */
export function useCompaniesDeleteCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<CompaniesDeleteCompaniesMutation, CompaniesDeleteCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompaniesDeleteCompaniesMutation, CompaniesDeleteCompaniesMutationVariables>(CompaniesDeleteCompaniesDocument, options);
      }
export type CompaniesDeleteCompaniesMutationHookResult = ReturnType<typeof useCompaniesDeleteCompaniesMutation>;
export type CompaniesDeleteCompaniesMutationResult = Apollo.MutationResult<CompaniesDeleteCompaniesMutation>;
export type CompaniesDeleteCompaniesMutationOptions = Apollo.BaseMutationOptions<CompaniesDeleteCompaniesMutation, CompaniesDeleteCompaniesMutationVariables>;
export const CompaniesArchiveCompaniesDocument = gql`
    mutation CompaniesArchiveCompanies($input: ArchiveCompanyInput!) {
  dashboardAccountArchiveCompany(input: $input)
}
    `;
export type CompaniesArchiveCompaniesMutationFn = Apollo.MutationFunction<CompaniesArchiveCompaniesMutation, CompaniesArchiveCompaniesMutationVariables>;

/**
 * __useCompaniesArchiveCompaniesMutation__
 *
 * To run a mutation, you first call `useCompaniesArchiveCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompaniesArchiveCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companiesArchiveCompaniesMutation, { data, loading, error }] = useCompaniesArchiveCompaniesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompaniesArchiveCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<CompaniesArchiveCompaniesMutation, CompaniesArchiveCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompaniesArchiveCompaniesMutation, CompaniesArchiveCompaniesMutationVariables>(CompaniesArchiveCompaniesDocument, options);
      }
export type CompaniesArchiveCompaniesMutationHookResult = ReturnType<typeof useCompaniesArchiveCompaniesMutation>;
export type CompaniesArchiveCompaniesMutationResult = Apollo.MutationResult<CompaniesArchiveCompaniesMutation>;
export type CompaniesArchiveCompaniesMutationOptions = Apollo.BaseMutationOptions<CompaniesArchiveCompaniesMutation, CompaniesArchiveCompaniesMutationVariables>;