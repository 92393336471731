import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/AddKitchenDisplayDisplayTarget/AddKitchenDisplayDisplayTargetForm/NameField";
import { RoleField } from "pages/EditKitchenDisplayDisplayTarget/EditKitchenDisplayDisplayTargetForm/RoleField";
import { useEditKitchenDisplayDisplayTargetForm } from "pages/EditKitchenDisplayDisplayTarget/EditKitchenDisplayDisplayTargetForm/useEditKitchenDisplayDisplayTargetForm";
import { KdDisplayTarget, Role } from "pages/EditKitchenDisplayDisplayTarget/types";
import { KdDisplayTargetSetInput } from "types/graphql";

type Props = {
  kdDisplayTarget: KdDisplayTarget;
  onSubmit: (kdDisplayTarget: KdDisplayTargetSetInput) => void;
  onClose: () => void;
  loading: boolean;
  roles: Role[];
};

export const EditKitchenDisplayDisplayTargetForm = memo<Props>(
  ({ kdDisplayTarget, onClose, onSubmit, loading, roles }) => {
    const { form, initialValues, submit } = useEditKitchenDisplayDisplayTargetForm({
      roles,
      kdDisplayTarget,
      onSubmit,
    });

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="kdDisplayTarget" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <RoleField roles={roles} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
