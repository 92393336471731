import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Popconfirm, Popover, Typography } from "antd";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { Table as TableType, TableArea } from "pages/Tables/types";

import {
  TableAreaRowItem,
  TableAreaSortableColumn,
  TableAreaSortableTable,
} from "./TableAreaSortableTable";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  tableAreas: TableArea[];
  onArchiveTable: ({ tableId }: { tableId: number }) => void;
  onArchiveTableArea: ({ tableAreaId }: { tableAreaId: string }) => void;
  onMove: (args: { draggingRowItem: TableAreaRowItem; targetRowItem: TableAreaRowItem }) => void;
};

const AreaName = styled.div`
  font-weight: bold;
`;

export const TableAreaTable = memo<Props>(
  ({ loading, onArchiveTable, onArchiveTableArea, onMove, tableAreas }) => {
    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = [
      {
        title: "エリア / テーブル名",
        render(_: string, tableArea: TableArea) {
          return (
            <TableAreaSortableColumn canDrag={canAccess("editTable")}>
              <AreaName>{tableArea.name}</AreaName>
            </TableAreaSortableColumn>
          );
        },
      },
      ...(canAccess("editTable")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, tableArea: TableArea) {
                return (
                  <IconLink to={`/table/area/${tableArea.id}/edit`}>
                    <EditIcon />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteTable")
        ? [
            {
              title: "",
              align: "center",
              fixed: "right",
              width: 60,
              render(_: string, tableArea: TableArea) {
                if (tableArea.tables.length > 0) {
                  return (
                    <Popover
                      content={
                        <Paragraph>
                          紐づくテーブルを移動もしくは削除してからエリアを削除してください
                        </Paragraph>
                      }
                      title="テーブルが紐づくエリアは削除できません"
                    >
                      <DeleteIcon disabled />
                    </Popover>
                  );
                }
                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>エリアを削除しますか？</Paragraph>
                        <Paragraph>
                          ハンディやレジを再起動するとエリアが表示されなくなります。
                        </Paragraph>
                        <Paragraph>一度削除したエリアを元に戻すことはできません。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => onArchiveTableArea({ tableAreaId: tableArea.id })}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    const expandedRowRender = useCallback(
      (tableArea) => (
        <TableAreaSortableTable
          tableAreaId={tableArea.id}
          onMove={onMove}
          rowKey="id"
          showHeader={false}
          columns={[
            {
              title: "エリア / テーブル名",
              render(_: string, table: TableType) {
                return (
                  <TableAreaSortableColumn canDrag={canAccess("editTable")}>
                    {table.name}
                  </TableAreaSortableColumn>
                );
              },
            },
            ...(canAccess("editTable")
              ? [
                  {
                    title: "",
                    align: "center",
                    width: 60,
                    render(_: string, table: TableType) {
                      return (
                        <IconLink to={`/table/${table.tableId}/edit`}>
                          <EditIcon />
                        </IconLink>
                      );
                    },
                  } as const,
                ]
              : []),
            ...(isFeatureEnabled("deleteTable")
              ? [
                  {
                    title: "",
                    align: "center",
                    fixed: "right",
                    width: 60,
                    render(_: string, table: TableType) {
                      return (
                        <Popconfirm
                          title={
                            <>
                              <Paragraph>テーブルを削除しますか？</Paragraph>
                              <Paragraph>
                                ハンディやレジを再起動するとテーブルが表示されなくなります。
                              </Paragraph>
                              <Paragraph>
                                一度削除したテーブルを元に戻すことはできません。
                              </Paragraph>
                            </>
                          }
                          okText="はい"
                          cancelText="キャンセル"
                          onConfirm={() => onArchiveTable({ tableId: table.tableId })}
                        >
                          <DeleteIcon />
                        </Popconfirm>
                      );
                    },
                  } as const,
                ]
              : []),
          ]}
          dataSource={tableArea.tables}
          pagination={false}
          loading={loading}
          bordered
        />
      ),
      [canAccess, isFeatureEnabled, loading, onArchiveTable, onMove],
    );

    if (loading) return null;

    return (
      <TableAreaSortableTable
        onMove={onMove}
        rowKey="id"
        columns={columns}
        dataSource={tableAreas}
        loading={loading}
        pagination={false}
        bordered
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
        }}
      />
    );
  },
);
