import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { getDayBeforeWorkDay } from "util/workDay";

import { useChangeDateTime } from "hooks/useChangeDateTime";
import type { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

type FilterConditions = {
  [K: string]: unknown;
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null] | undefined;
};

/**
 * 日付の範囲選択で前営業日をフィルイン
 */
export const useFillInRangeConditions = (
  filterConditions: FilterConditions,
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>,
) => {
  const { changeDateTime } = useChangeDateTime();

  const shouldFillIn = useRef(true);

  useEffect(() => {
    if (!shouldFillIn.current || !changeDateTime) return;

    // NOTE: ユーザー操作により日付が選択された場合やLocationStateからfilterConditions.rangeが復元できた場合は無効化
    if (filterConditions?.range) {
      shouldFillIn.current = false;
      return;
    }

    shouldFillIn.current = false;

    const dayBeforeWorkDay = getDayBeforeWorkDay(changeDateTime);

    updateFilterCondition({
      range: [dayBeforeWorkDay, dayBeforeWorkDay],
    });
  }, [changeDateTime, filterConditions, updateFilterCondition]);
};
