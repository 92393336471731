import React, { memo } from "react";
import { Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { taxRates } from "models/taxRate";

import { EditMenuFormItem } from "../useEditMenuForm";

const { Option } = Select;

type Props = {
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const CostTaxRateField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem label="原価税率" name="costTaxRate" {...props}>
    <Select<string> disabled={disabled}>
      {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
        <Option key={taxRateKey} value={taxRate}>
          {taxRate * 100}%
        </Option>
      ))}
    </Select>
  </EditMenuFormItem>
));
