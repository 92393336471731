import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanGroupHeader } from "components/PageHeader/PlanGroupHeader";
import { useCompany } from "hooks/useCompany";
import { EditPlanGroupForm } from "pages/EditPlanGroup/EditPlanGroupForm";
import {
  useEditPlanGroupGetPlanGroupQuery,
  useEditPlanGroupUpdatePlanGroupMutation,
} from "pages/EditPlanGroup/queries";
import { PlanGroupSetInput } from "types/graphql";

export const EditPlanGroup = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planGroupId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanGroupData,
    loading: loadingPlanGroup,
    refetch: refetchPlanGroup,
    error,
  } = useEditPlanGroupGetPlanGroupQuery(
    !Number.isNaN(planGroupId) ? { variables: { planGroupId } } : { skip: true },
  );
  const planGroup = getPlanGroupData?.planGroup?.[0];

  setCompany(planGroup?.companyId);

  const [updatePlanGroupMutation, { loading: loadingUpdatePlanGroup }] =
    useEditPlanGroupUpdatePlanGroupMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (planGroup: PlanGroupSetInput) => {
      if (!Number.isNaN(planGroupId)) {
        try {
          await updatePlanGroupMutation({
            variables: { planGroupId, planGroup },
          });
          await refetchPlanGroup();
          message.success("編集を保存しました");
          goBack();
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [planGroupId, updatePlanGroupMutation, goBack, refetchPlanGroup],
  );

  return (
    <DashboardLayout
      title={planGroup?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラングループ" }],
      }}
    >
      <PlanGroupHeader planGroup={planGroup ?? null} onBack={goBack} />
      {loadingPlanGroup && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planGroup && (
        <EditPlanGroupForm
          planGroup={planGroup}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdatePlanGroup}
        />
      )}
    </DashboardLayout>
  );
};
