import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { InflowSourceTag } from "../types";

import { CodeField } from "./CodeField";
import { useEditWinboardInflowSourceTagForm } from "./useEditWinboardInflowSourceTagForm";

type Props = {
  inflowSourceTag: InflowSourceTag;
  onClose: () => void;
  onSubmit: ({ code }: { code: string }) => void;
  loading: boolean;
};

export const EditWinboardInflowSourceTagForm = memo<Props>(
  ({ onClose, onSubmit, loading, inflowSourceTag }) => {
    const { form, submit, initialValues } = useEditWinboardInflowSourceTagForm({
      inflowSourceTag,
      onSubmit,
    });

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="tecAggregationOnSitePaymentDetailType"
            form={form}
            initialValues={initialValues}
          >
            <CodeField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
