import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { OnlinePaymentPrinterRoleMeta, Role } from "pages/EditRole/types";

export type EditRoleFormValues = Pick<
  Role,
  | "name"
  | "shouldPrintClerkCallSlip"
  | "shouldPrintDishUpSlip"
  | "shouldPrintPaymentReceipt"
  | "printerSound"
  | "printDishUpSlipAsPrimarySlip"
  | "firstSpareRoleId"
  | "secondSpareRoleId"
> & {
  shouldPrintFreeOrderOnDishUpSlip: boolean;
  shouldPrintOnlinePaymentSlip: boolean;
};

const getInitialValues = (
  role: Role,
  onlinePaymentPrinterRoleMeta: OnlinePaymentPrinterRoleMeta | undefined,
): EditRoleFormValues => {
  const {
    name,
    shouldPrintClerkCallSlip,
    shouldPrintDishUpSlip,
    shouldPrintPaymentReceipt,
    printerSound,
    removeFreeOrderFromDishUpSlip,
    printDishUpSlipAsPrimarySlip,
    firstSpareRoleId,
    secondSpareRoleId,
  } = role;
  return {
    name,
    shouldPrintClerkCallSlip,
    shouldPrintDishUpSlip,
    shouldPrintPaymentReceipt,
    printerSound,
    printDishUpSlipAsPrimarySlip,
    shouldPrintFreeOrderOnDishUpSlip: !removeFreeOrderFromDishUpSlip,
    shouldPrintOnlinePaymentSlip: Boolean(onlinePaymentPrinterRoleMeta),
    firstSpareRoleId,
    secondSpareRoleId,
  };
};

export const EditRoleFormItem = createFormItem<EditRoleFormValues>();

export const useEditRoleForm = (
  role: Role,
  onlinePaymentPrinterRoleMeta: OnlinePaymentPrinterRoleMeta | undefined,
  onSubmit: (role: EditRoleFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(role, onlinePaymentPrinterRoleMeta);

  const submit = useCallback(() => {
    const values = form.getFieldsValue();
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
