import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Checkbox, Input, Steps } from "antd";
import { isValidateErrorEntity } from "util/validation";

// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";

import { Shop } from "../types";

import { useEditLineReportingBotConfigForm } from "./useEditLineReportingBotConfigForm";

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckBox = styled(Checkbox)`
  && {
    margin-left: 0;
  }
`;

const steps = [{ title: "グループに追加" }, { title: "コードをLINEに投稿" }, { title: "店舗選択" }];

type Props = {
  shops: Shop[];
  onClickBackButton: () => void;
  onSubmit: ({ name, shopIds }: { name: string; shopIds: string[] }) => void;
  onFormValidationError: () => void;
  disabled: boolean;
};

export const EditLineReportingBotConfig = memo<Props>(
  ({ shops, onClickBackButton, onSubmit, onFormValidationError, disabled }) => {
    const { form, submit } = useEditLineReportingBotConfigForm({ onSubmit });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();

        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [form, onFormValidationError, submit]);

    return (
      <Form layout="vertical" form={form}>
        <FormSection>
          <Steps current={2} items={steps} />
          <Spacer size={24} />
          <FormItem
            label="グループ名を登録してください。"
            name="name"
            rules={[{ required: true, message: "グループ名を登録してください。" }]}
            labelSpacer={<Spacer size={16} />}
            endSpacer={null}
          >
            <Input placeholder="ダイニー経営陣グループ" />
          </FormItem>
          <Spacer size={24} />
          <FormItem
            name="shopIds"
            label="配信する店舗を選択してください。"
            rules={[{ required: true, message: "配信する店舗を選択してください。" }]}
            labelSpacer={<Spacer size={16} />}
          >
            <CheckboxGroup>
              {shops.map((shop) => (
                <StyledCheckBox key={shop.shopId} value={shop.shopId}>
                  {shop.name}
                </StyledCheckBox>
              ))}
            </CheckboxGroup>
          </FormItem>
        </FormSection>
        <Spacer size={16} />
        <FormActions>
          <Button onClick={onClickBackButton}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} disabled={disabled}>
            完了
          </Button>
        </FormActions>
      </Form>
    );
  },
);
