import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Select } from "antd";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import {
  businessOperationHourTypeToWord,
  getDateTimeBasedOnChangeDateTime,
  isDateChanged,
  isValidBusinessOperationHourDuration,
  isValidBusinessOperationHourInterval,
  sortBusinessOperationHourTypes,
} from "models/businessOperationHour";
import { normalizeHoursAndMinutes } from "util/time";

import { FormList } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormHelp } from "components/Form/FormHelp";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { BusinessOperationHourTypeEnum } from "types/graphql";

import {
  BusinessOperationHourFormValues,
  EditShopBusinessOperationHourFormItem,
} from "../useEditShopBusinessOperationHourForm";

const { Option } = Select;

type Props = {
  formValues: BusinessOperationHourFormValues[];
  changeDateTime: string;
};

type FormListFieldDataWithRemoveAndMoveOperation = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
  remove: FormListOperation["remove"];
  move: FormListOperation["move"];
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  text-align: right;
`;
const TimePickerContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
`;

const DateTime = styled.span`
  color: #999999;
  font-size: 12px;
  width: 80px;
`;

export const ShopBusinessOperationHourField = memo<Props>(({ changeDateTime, formValues }) => {
  const businessOperationHourTypes = useMemo(
    () => sortBusinessOperationHourTypes(Object.values(BusinessOperationHourTypeEnum)),
    [],
  );

  const takenBusinessOperationHourTypes = useMemo(
    () => formValues.map(({ type }) => type),
    [formValues],
  );

  const nextBusinessOperationHourType = useMemo(
    () =>
      businessOperationHourTypes.find((value) => !takenBusinessOperationHourTypes.includes(value)),
    [takenBusinessOperationHourTypes, businessOperationHourTypes],
  );
  const getSortedPosition = useCallback(
    ({ value, name }: { value: dayjs.Dayjs; name: number }) => {
      const startTime = isDateChanged({ dateTime: value, changeDateTime })
        ? normalizeHoursAndMinutes(value).add(24, "hours")
        : normalizeHoursAndMinutes(value);
      return formValues.filter(
        ({ start }, index) =>
          index !== name && start && startTime.isAfter(normalizeHoursAndMinutes(start)),
      ).length;
    },
    [changeDateTime, formValues],
  );

  const columns: ColumnsType<FormListFieldDataWithRemoveAndMoveOperation> = useMemo(
    () => [
      {
        title: (
          <FormHelp
            label="営業時間帯"
            help={
              <>
                設定すると、 売上集計などを
                <br />
                ランチ / ディナー別で見られます。
              </>
            }
          />
        ),
        render: (_: unknown, { name }: FormListFieldDataWithRemoveAndMoveOperation) => (
          <EditShopBusinessOperationHourFormItem
            name={[name, "type"]}
            required
            rules={[{ required: true, message: "曜日を設定してください" }]}
            validateTrigger="onSubmit"
            endSpacer={null}
          >
            <StyledSelect>
              {businessOperationHourTypes
                .filter(
                  (value) =>
                    !takenBusinessOperationHourTypes.includes(value) ||
                    value === formValues[name]?.type,
                )
                .map((value) => (
                  <Option key={value} value={value}>
                    {businessOperationHourTypeToWord[value]}
                  </Option>
                ))}
            </StyledSelect>
          </EditShopBusinessOperationHourFormItem>
        ),
      },
      {
        title: "開始時間",
        width: 220,
        render: (_: unknown, { name, move }: FormListFieldDataWithRemoveAndMoveOperation) => (
          <TimePickerContainer>
            <EditShopBusinessOperationHourFormItem
              name={[name, "start"]}
              required
              rules={[{ required: true, message: "開始時間を設定してください" }]}
              validateTrigger="onSubmit"
              endSpacer={null}
            >
              <StyledTimePicker
                formItemName={[name, "start"]}
                onChange={(value) => {
                  if (!value) return;
                  const position = getSortedPosition({ value, name });
                  move(name, position);
                }}
              />
            </EditShopBusinessOperationHourFormItem>
            <DateTime>
              {isDateChanged({
                dateTime: dayjs(formValues[name]?.start, "HH:mm"),
                changeDateTime,
              }) && (
                <>
                  ({" "}
                  {getDateTimeBasedOnChangeDateTime({
                    dateTime: dayjs(formValues[name]?.start, "HH:mm"),
                    changeDateTime,
                  })}{" "}
                  )
                </>
              )}
            </DateTime>
          </TimePickerContainer>
        ),
      },
      {
        title: "終了時間",
        width: 220,
        render: (_: unknown, { name }: FormListFieldDataWithRemoveAndMoveOperation) => (
          <TimePickerContainer>
            <EditShopBusinessOperationHourFormItem
              name={[name, "end"]}
              required
              rules={[
                { required: true, message: "終了時間を設定してください" },
                ({ getFieldValue }) => ({
                  validator: async () => {
                    const start = getFieldValue(["businessOperationHours", name, "start"]);
                    const end = getFieldValue(["businessOperationHours", name, "end"]);
                    const nextBusinessOperationHourStart = getFieldValue([
                      "businessOperationHours",
                      name + 1,
                      "start",
                    ]);

                    if (!start || !end) return;

                    const normalizedStart = normalizeHoursAndMinutes(start);
                    const normalizedEnd = normalizeHoursAndMinutes(end);

                    if (
                      !isValidBusinessOperationHourDuration({
                        start: normalizedStart,
                        end: normalizedEnd,
                        changeDateTime,
                      })
                    ) {
                      throw new Error("開始時間より後の時間を入力してください");
                    }

                    if (!nextBusinessOperationHourStart) return;

                    const normalizedNextBusinessOperationHourStart = normalizeHoursAndMinutes(
                      nextBusinessOperationHourStart,
                    );

                    if (
                      !isValidBusinessOperationHourInterval({
                        end: normalizedEnd,
                        nextStart: normalizedNextBusinessOperationHourStart,
                        changeDateTime,
                      })
                    ) {
                      throw new Error("時間が被っています。被らないように設定してください");
                    }
                  },
                }),
              ]}
              validateTrigger="onSubmit"
              endSpacer={null}
            >
              <StyledTimePicker formItemName={[name, "end"]} />
            </EditShopBusinessOperationHourFormItem>
            <DateTime>
              {isDateChanged({
                dateTime: dayjs(formValues[name]?.end, "HH:mm"),
                changeDateTime,
              }) && (
                <>
                  ({" "}
                  {getDateTimeBasedOnChangeDateTime({
                    dateTime: dayjs(formValues[name]?.end, "HH:mm"),
                    changeDateTime,
                  })}{" "}
                  )
                </>
              )}
            </DateTime>
          </TimePickerContainer>
        ),
      },
      {
        title: "",
        align: "center",
        width: 60,
        render: (_: string, { name, remove }: FormListFieldDataWithRemoveAndMoveOperation) => (
          <DeleteIcon onClick={() => remove(name)} />
        ),
      },
    ],
    [
      changeDateTime,
      formValues,
      takenBusinessOperationHourTypes,
      businessOperationHourTypes,
      getSortedPosition,
    ],
  );
  return (
    <FormList name="businessOperationHours">
      {(fields, { add, remove, move }) => {
        const fieldsWithRemoveFunction: FormListFieldDataWithRemoveAndMoveOperation[] = fields.map(
          (field) => ({ ...field, remove, move }),
        );
        return (
          <>
            <Table
              rowKey="key"
              columns={columns}
              dataSource={fieldsWithRemoveFunction}
              bordered
              pagination={false}
            />
            <Spacer size={8} />
            <ButtonContainer>
              <Button
                onClick={
                  nextBusinessOperationHourType
                    ? () => add({ type: nextBusinessOperationHourType })
                    : undefined
                }
              >
                追加する
              </Button>
            </ButtonContainer>
          </>
        );
      }}
    </FormList>
  );
});
