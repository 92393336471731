import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FoodingJournalConfigFormItem } from "pages/EditFoodingJournalConfig/useFoodingJournalConfigForm";

type Props = Omit<FormItemProps, "children">;

export const InputUrlField = memo<Props>((props) => (
  <FoodingJournalConfigFormItem
    label="連携先URL"
    name="inputUrl"
    rules={[{ required: true }]}
    required
    {...props}
  >
    <Input />
  </FoodingJournalConfigFormItem>
));
