import { useMemo } from "react";
import dayjs from "dayjs";
import { isNotNullable } from "util/type/primitive";

import { QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery } from "components/QuestionnaireAnalyticsCommentModal/queries";
import { QuestionCategoryEnum, QuestionTypeEnum } from "types/graphql";

const PATH_QUESTION_NAME = "認知経路";
const GENDER_QUESTION_NAME = "性別";

const requiredMark = (required: boolean) => (required ? " *" : "");

const getColumnsFromQuestionAnswers = ({
  answers,
  questionnaireQuestions,
}: {
  answers: NonNullable<
    NonNullable<
      QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery["customerQuestionnaire_by_pk"]
    >["questionnaireAnswer"]
  >["questionAnswers"];
  questionnaireQuestions: {
    priority: number;
    questionId: string;
  }[];
}) =>
  answers
    .map((answer) => {
      const priority =
        questionnaireQuestions.find((q) => q.questionId === answer.question.id)?.priority ?? 0;
      if (answer.scoreTypeQuestionAnswer) {
        return {
          label: `点数${requiredMark(answer.question.required)}`,
          value: answer.scoreTypeQuestionAnswer.score,
          priority,
        };
      }

      const label = `${answer.question.name}${requiredMark(answer.question.required)}`;

      if (answer.multipleChoiceTypeQuestionAnswer) {
        return {
          label,
          value:
            answer.multipleChoiceTypeQuestionAnswer.multipleChoiceTypeQuestionChoiceAnswers[0]
              ?.questionChoice.name,
          priority,
        };
      }

      if (answer.textTypeQuestionAnswer) {
        return {
          label,
          value: answer.textTypeQuestionAnswer.text,
          priority,
        };
      }

      return undefined;
    })
    .filter(isNotNullable)
    .sort((a, b) => a.priority - b.priority);

export const useAnswerList = (
  answer: QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery["customerQuestionnaire_by_pk"],
) => {
  const answeredAt = answer?.questionnaireAnswer?.answeredAt;

  const answerInfoColumns = useMemo(
    () =>
      [
        answer?.checkInEvent?.shop.name
          ? { label: "店舗", value: answer.checkInEvent.shop.name }
          : undefined,
        answer?.questionnaireAnswer?.visitedAt
          ? {
              label: "来店日時",
              value: dayjs(answer.questionnaireAnswer.visitedAt).format("YYYY/MM/DD HH:mm"),
            }
          : undefined,
        answer?.customer.checkInEvents && answer.checkInEvent?.checkedInAt
          ? {
              label: "来店回数",
              value: `${
                answer.customer.checkInEvents.filter(
                  ({ checkedInAt, shopId }) =>
                    shopId === answer.checkInEvent?.shopId &&
                    dayjs(checkedInAt).isSameOrBefore(dayjs(answer.checkInEvent?.checkedInAt)),
                ).length
              }回目`,
            }
          : undefined,
      ].filter(isNotNullable),
    [
      answer?.checkInEvent?.shop.name,
      answer?.checkInEvent?.shopId,
      answer?.checkInEvent?.checkedInAt,
      answer?.customer.checkInEvents,
      answer?.questionnaireAnswer?.visitedAt,
    ],
  );

  const {
    attributeAnswers,
    repeatabilityAnswers,
    customerServiceAnswers,
    deliciousnessAnswers,
    speedAnswers,
    cleanlinessAnswers,
  } = useMemo(() => {
    const attributeAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.Attribute,
      ) ?? [];

    const repeatabilityAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.Repeatability,
      ) ?? [];

    const customerServiceAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.CustomerService,
      ) ?? [];

    const deliciousnessAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.Deliciousness,
      ) ?? [];

    const speedAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.Speed,
      ) ?? [];

    const cleanlinessAnswers =
      answer?.questionnaireAnswer?.questionAnswers.filter(
        (answer) => answer.question.category === QuestionCategoryEnum.Cleanliness,
      ) ?? [];

    return {
      attributeAnswers,
      repeatabilityAnswers,
      customerServiceAnswers,
      deliciousnessAnswers,
      speedAnswers,
      cleanlinessAnswers,
    };
  }, [answer?.questionnaireAnswer?.questionAnswers]);

  const attributeColumns = useMemo(() => {
    const birthday = attributeAnswers.find(
      (answer) =>
        answer.question.type === QuestionTypeEnum.Birthday && answer.birthdayTypeQuestionAnswer,
    );
    const path = attributeAnswers.find(
      (answer) =>
        answer.question.name === PATH_QUESTION_NAME &&
        answer.question.type === QuestionTypeEnum.MultipleChoice,
    );
    const gender = attributeAnswers.find(
      (answer) =>
        answer.question.name === GENDER_QUESTION_NAME &&
        answer.question.type === QuestionTypeEnum.MultipleChoice,
    );

    return [
      gender && {
        label: `${gender.question.name}${requiredMark(gender.question.required)}`,
        value:
          gender.multipleChoiceTypeQuestionAnswer?.multipleChoiceTypeQuestionChoiceAnswers[0]
            ?.questionChoice.name,
      },
      birthday && answeredAt
        ? {
            label: "年代",
            value: `${
              Math.floor(
                dayjs(answeredAt).diff(
                  dayjs(birthday.birthdayTypeQuestionAnswer?.birthday),
                  "year",
                ) / 10,
              ) * 10
            }代`,
          }
        : undefined,
      birthday && {
        label: `誕生日${requiredMark(birthday.question.required)}`,
        value: dayjs(birthday.birthdayTypeQuestionAnswer?.birthday).format("YYYY/MM/DD"),
      },
      path && {
        label: `${path.question.name}${requiredMark(path.question.required)}`,
        value:
          path.multipleChoiceTypeQuestionAnswer?.multipleChoiceTypeQuestionChoiceAnswers[0]
            ?.questionChoice.name,
      },
    ].filter(isNotNullable);
  }, [attributeAnswers, answeredAt]);

  const questionnaireQuestions = useMemo(
    () =>
      answer?.questionnaireAnswer?.questionnaire?.questionnaireQuestions.map((q) => ({
        priority: q.priority,
        questionId: q.questionId,
      })) ?? [],
    [answer?.questionnaireAnswer?.questionnaire?.questionnaireQuestions],
  );

  const repeatabilityColumns = useMemo(
    () => getColumnsFromQuestionAnswers({ answers: repeatabilityAnswers, questionnaireQuestions }),
    [repeatabilityAnswers, questionnaireQuestions],
  );

  const customerServiceColumns = useMemo(
    () =>
      getColumnsFromQuestionAnswers({ answers: customerServiceAnswers, questionnaireQuestions }),
    [customerServiceAnswers, questionnaireQuestions],
  );

  const deliciousnessColumns = useMemo(
    () => getColumnsFromQuestionAnswers({ answers: deliciousnessAnswers, questionnaireQuestions }),
    [deliciousnessAnswers, questionnaireQuestions],
  );

  const speedColumn = useMemo(
    () => getColumnsFromQuestionAnswers({ answers: speedAnswers, questionnaireQuestions }),
    [speedAnswers, questionnaireQuestions],
  );

  const cleanlinessColumns = useMemo(
    () => getColumnsFromQuestionAnswers({ answers: cleanlinessAnswers, questionnaireQuestions }),
    [cleanlinessAnswers, questionnaireQuestions],
  );

  return {
    answerInfoColumns,
    attributeColumns,
    repeatabilityColumns,
    customerServiceColumns,
    deliciousnessColumns,
    speedColumn,
    cleanlinessColumns,
  };
};
