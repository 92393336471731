import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { sessionId } from "constants/sessionId";

import pkg from "../../package.json";

const appVersion = pkg.version;

const SENTRY_DSN = import.meta.env.SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    // NOTE: ページをローカルに保存してページを表示しようとしてしまう方が多いので、そういったケースではエラーを飛ばさないようにする
    enabled: window.location.host.endsWith(".self.dinii.jp"),
    release: appVersion,
    dsn: SENTRY_DSN,
    environment: import.meta.env.APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // NOTE: antd 内で使っている react-component で吐き出しているエラー
      // 無視して問題ないので、sentry には送信されないようにする
      // https://github.com/ant-design/ant-design/issues/15075#issuecomment-467740817
      "ResizeObserver loop limit exceeded",
    ],
  });

  Sentry.setTag("sessionId", sessionId);
}

export const reportToSentry = ({
  message,
  extra = {},
  severity,
}: {
  message: string;
  extra?: Record<string, unknown>;
  severity: Sentry.Severity;
}) => {
  console.warn(message, extra);

  if (SENTRY_DSN) {
    Sentry.captureMessage(`${message}: ${JSON.stringify(extra)}`, severity);
  }
};
