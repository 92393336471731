import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddCookingItemGetRoles = gql`
    query AddCookingItemGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    roles {
      id
      name
      roleId
    }
  }
}
    `;
export const AddCookingItemInsertShopCookingItemRole = gql`
    mutation AddCookingItemInsertShopCookingItemRole($shopCookingItemRole: shopCookingItemRole_insert_input!) {
  insert_shopCookingItemRole_one(object: $shopCookingItemRole) {
    _cookingItemId
    cookingItemId
  }
}
    `;
export type AddCookingItemGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddCookingItemGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )> }
  )> }
);

export type AddCookingItemInsertShopCookingItemRoleMutationVariables = Types.Exact<{
  shopCookingItemRole: Types.ShopCookingItemRoleInsertInput;
}>;


export type AddCookingItemInsertShopCookingItemRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopCookingItemRole_one?: Types.Maybe<(
    { __typename?: 'shopCookingItemRole' }
    & Pick<Types.ShopCookingItemRole, '_cookingItemId' | 'cookingItemId'>
  )> }
);


export const AddCookingItemGetRolesDocument = gql`
    query AddCookingItemGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    roles {
      id
      name
      roleId
    }
  }
}
    `;

/**
 * __useAddCookingItemGetRolesQuery__
 *
 * To run a query within a React component, call `useAddCookingItemGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddCookingItemGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddCookingItemGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddCookingItemGetRolesQuery(baseOptions: Apollo.QueryHookOptions<AddCookingItemGetRolesQuery, AddCookingItemGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddCookingItemGetRolesQuery, AddCookingItemGetRolesQueryVariables>(AddCookingItemGetRolesDocument, options);
      }
export function useAddCookingItemGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddCookingItemGetRolesQuery, AddCookingItemGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddCookingItemGetRolesQuery, AddCookingItemGetRolesQueryVariables>(AddCookingItemGetRolesDocument, options);
        }
export type AddCookingItemGetRolesQueryHookResult = ReturnType<typeof useAddCookingItemGetRolesQuery>;
export type AddCookingItemGetRolesLazyQueryHookResult = ReturnType<typeof useAddCookingItemGetRolesLazyQuery>;
export type AddCookingItemGetRolesQueryResult = Apollo.QueryResult<AddCookingItemGetRolesQuery, AddCookingItemGetRolesQueryVariables>;
export const AddCookingItemInsertShopCookingItemRoleDocument = gql`
    mutation AddCookingItemInsertShopCookingItemRole($shopCookingItemRole: shopCookingItemRole_insert_input!) {
  insert_shopCookingItemRole_one(object: $shopCookingItemRole) {
    _cookingItemId
    cookingItemId
  }
}
    `;
export type AddCookingItemInsertShopCookingItemRoleMutationFn = Apollo.MutationFunction<AddCookingItemInsertShopCookingItemRoleMutation, AddCookingItemInsertShopCookingItemRoleMutationVariables>;

/**
 * __useAddCookingItemInsertShopCookingItemRoleMutation__
 *
 * To run a mutation, you first call `useAddCookingItemInsertShopCookingItemRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCookingItemInsertShopCookingItemRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCookingItemInsertShopCookingItemRoleMutation, { data, loading, error }] = useAddCookingItemInsertShopCookingItemRoleMutation({
 *   variables: {
 *      shopCookingItemRole: // value for 'shopCookingItemRole'
 *   },
 * });
 */
export function useAddCookingItemInsertShopCookingItemRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddCookingItemInsertShopCookingItemRoleMutation, AddCookingItemInsertShopCookingItemRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCookingItemInsertShopCookingItemRoleMutation, AddCookingItemInsertShopCookingItemRoleMutationVariables>(AddCookingItemInsertShopCookingItemRoleDocument, options);
      }
export type AddCookingItemInsertShopCookingItemRoleMutationHookResult = ReturnType<typeof useAddCookingItemInsertShopCookingItemRoleMutation>;
export type AddCookingItemInsertShopCookingItemRoleMutationResult = Apollo.MutationResult<AddCookingItemInsertShopCookingItemRoleMutation>;
export type AddCookingItemInsertShopCookingItemRoleMutationOptions = Apollo.BaseMutationOptions<AddCookingItemInsertShopCookingItemRoleMutation, AddCookingItemInsertShopCookingItemRoleMutationVariables>;