import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useSalesByDayofweekAndHourAnalytics } from "hooks/useSalesByDayofweekAndHourAnalytics";
import { useShop } from "hooks/useShop";

import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import { SalesHeatmap } from "./SalesHeatmap";

const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FiltersWrapper = styled.div`
  flex-grow: 1;
`;

export const SalesByDayofweekAndHourAnalytics = () => {
  const navigate = useNavigate();
  const [salesValueType, setSalesValueType] = useState<
    "totalAmount" | "numPeople" | "salesPerCustomer"
  >("totalAmount");

  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;

  const [shop] = useShop();

  const {
    analyticsSetting: { displayTaxIncluded },
  } = useAnalyticsSetting({ corporationId });

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: [dayjs().subtract(30, "days"), dayjs()],
    },
    undefined,
    {
      serialize: ({ range }) => ({
        range: serializeRange(range),
      }),
      deserialize: ({ range }) => ({
        range: deserializeRange(range),
      }),
    },
  );

  const { normalizedRows, isLoading } = useSalesByDayofweekAndHourAnalytics({
    shopId: shop?.shopId ?? null,
    startDate: filterConditions.range?.[0] ?? dayjs().subtract(30, "days"),
    endDate: filterConditions.range?.[1] ?? dayjs(),
    showTaxIncluded: displayTaxIncluded,
  });

  const handleChangeShop = useCallback(() => {
    navigate("/sales-by-dayofweek-and-hour-analytics", { replace: true });
  }, [navigate]);

  return (
    <DashboardLayout title="曜日・時間別分析">
      <PageHeader title="曜日・時間別分析" footer={null} />
      <Spacer size={12} />
      <TopBar>
        <FiltersWrapper>
          <Filters
            filterConditions={{
              range: filterConditions.range,
            }}
            onShopChange={handleChangeShop}
            onChangeFilterCondition={updateFilterCondition}
          />
        </FiltersWrapper>
      </TopBar>
      <Spacer size={12} />
      <SalesHeatmap
        normalizedRows={normalizedRows}
        salesValueType={salesValueType}
        setSalesValueType={setSalesValueType}
        isLoading={isLoading}
      />
    </DashboardLayout>
  );
};
