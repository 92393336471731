import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanChoicePikaichiMenuFormItem } from "../useEditPlanChoicePikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiCategoryCdField = memo<Props>((props) => (
  <EditPlanChoicePikaichiMenuFormItem label="分類コード" name="pikaichiCategoryCd" {...props}>
    <InputCode />
  </EditPlanChoicePikaichiMenuFormItem>
));
