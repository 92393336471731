import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuTranslationsFormItem } from "./useEditMenuTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeatureLabelEnField = memo<Props>(({ ...props }) => (
  <EditMenuTranslationsFormItem label="英語" name="featureLabelEn" {...props}>
    <Input />
  </EditMenuTranslationsFormItem>
));
