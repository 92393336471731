import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanPrioritiesGetPlans = gql`
    query EditPlanPrioritiesGetPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    companyId
    planCategoryId
    planId
    planName
    priority
    receiptDisplayName
    shopSideName
    orderableTimeId
  }
}
    `;
export const EditPlanPrioritiesGetCategories = gql`
    query EditPlanPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const EditPlanPrioritiesInsertPlans = gql`
    mutation EditPlanPrioritiesInsertPlans($plans: [plan_insert_input!]!) {
  insert_plan(
    objects: $plans
    on_conflict: {constraint: idx_46598_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planId
    }
  }
}
    `;
export type EditPlanPrioritiesGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanPrioritiesGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'companyId' | 'planCategoryId' | 'planId' | 'planName' | 'priority' | 'receiptDisplayName' | 'shopSideName' | 'orderableTimeId'>
  )> }
);

export type EditPlanPrioritiesGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanPrioritiesGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type EditPlanPrioritiesInsertPlansMutationVariables = Types.Exact<{
  plans: Array<Types.PlanInsertInput> | Types.PlanInsertInput;
}>;


export type EditPlanPrioritiesInsertPlansMutation = (
  { __typename?: 'mutation_root' }
  & { insert_plan?: Types.Maybe<(
    { __typename?: 'plan_mutation_response' }
    & Pick<Types.PlanMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'planId'>
    )> }
  )> }
);


export const EditPlanPrioritiesGetPlansDocument = gql`
    query EditPlanPrioritiesGetPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    companyId
    planCategoryId
    planId
    planName
    priority
    receiptDisplayName
    shopSideName
    orderableTimeId
  }
}
    `;

/**
 * __useEditPlanPrioritiesGetPlansQuery__
 *
 * To run a query within a React component, call `useEditPlanPrioritiesGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPrioritiesGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanPrioritiesGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanPrioritiesGetPlansQuery(baseOptions: Apollo.QueryHookOptions<EditPlanPrioritiesGetPlansQuery, EditPlanPrioritiesGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanPrioritiesGetPlansQuery, EditPlanPrioritiesGetPlansQueryVariables>(EditPlanPrioritiesGetPlansDocument, options);
      }
export function useEditPlanPrioritiesGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanPrioritiesGetPlansQuery, EditPlanPrioritiesGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanPrioritiesGetPlansQuery, EditPlanPrioritiesGetPlansQueryVariables>(EditPlanPrioritiesGetPlansDocument, options);
        }
export type EditPlanPrioritiesGetPlansQueryHookResult = ReturnType<typeof useEditPlanPrioritiesGetPlansQuery>;
export type EditPlanPrioritiesGetPlansLazyQueryHookResult = ReturnType<typeof useEditPlanPrioritiesGetPlansLazyQuery>;
export type EditPlanPrioritiesGetPlansQueryResult = Apollo.QueryResult<EditPlanPrioritiesGetPlansQuery, EditPlanPrioritiesGetPlansQueryVariables>;
export const EditPlanPrioritiesGetCategoriesDocument = gql`
    query EditPlanPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useEditPlanPrioritiesGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditPlanPrioritiesGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPrioritiesGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanPrioritiesGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanPrioritiesGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditPlanPrioritiesGetCategoriesQuery, EditPlanPrioritiesGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanPrioritiesGetCategoriesQuery, EditPlanPrioritiesGetCategoriesQueryVariables>(EditPlanPrioritiesGetCategoriesDocument, options);
      }
export function useEditPlanPrioritiesGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanPrioritiesGetCategoriesQuery, EditPlanPrioritiesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanPrioritiesGetCategoriesQuery, EditPlanPrioritiesGetCategoriesQueryVariables>(EditPlanPrioritiesGetCategoriesDocument, options);
        }
export type EditPlanPrioritiesGetCategoriesQueryHookResult = ReturnType<typeof useEditPlanPrioritiesGetCategoriesQuery>;
export type EditPlanPrioritiesGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditPlanPrioritiesGetCategoriesLazyQuery>;
export type EditPlanPrioritiesGetCategoriesQueryResult = Apollo.QueryResult<EditPlanPrioritiesGetCategoriesQuery, EditPlanPrioritiesGetCategoriesQueryVariables>;
export const EditPlanPrioritiesInsertPlansDocument = gql`
    mutation EditPlanPrioritiesInsertPlans($plans: [plan_insert_input!]!) {
  insert_plan(
    objects: $plans
    on_conflict: {constraint: idx_46598_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planId
    }
  }
}
    `;
export type EditPlanPrioritiesInsertPlansMutationFn = Apollo.MutationFunction<EditPlanPrioritiesInsertPlansMutation, EditPlanPrioritiesInsertPlansMutationVariables>;

/**
 * __useEditPlanPrioritiesInsertPlansMutation__
 *
 * To run a mutation, you first call `useEditPlanPrioritiesInsertPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanPrioritiesInsertPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanPrioritiesInsertPlansMutation, { data, loading, error }] = useEditPlanPrioritiesInsertPlansMutation({
 *   variables: {
 *      plans: // value for 'plans'
 *   },
 * });
 */
export function useEditPlanPrioritiesInsertPlansMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanPrioritiesInsertPlansMutation, EditPlanPrioritiesInsertPlansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanPrioritiesInsertPlansMutation, EditPlanPrioritiesInsertPlansMutationVariables>(EditPlanPrioritiesInsertPlansDocument, options);
      }
export type EditPlanPrioritiesInsertPlansMutationHookResult = ReturnType<typeof useEditPlanPrioritiesInsertPlansMutation>;
export type EditPlanPrioritiesInsertPlansMutationResult = Apollo.MutationResult<EditPlanPrioritiesInsertPlansMutation>;
export type EditPlanPrioritiesInsertPlansMutationOptions = Apollo.BaseMutationOptions<EditPlanPrioritiesInsertPlansMutation, EditPlanPrioritiesInsertPlansMutationVariables>;