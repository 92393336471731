import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanWinboardPlanGetPlan = gql`
    query EditPlanWinboardPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        winboardMenus(where: {_planId: {_eq: $planId}}) {
          id
          name
          code
          bumonCode
          bumonName
          categoryCode
          categoryName
        }
      }
    }
  }
}
    `;
export const EditPlanWinboardPlanGetCode = gql`
    query EditPlanWinboardPlanGetCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
  }
}
    `;
export const EditPlanWinboardPlanUpdateWinboardMenu = gql`
    mutation EditPlanWinboardPlanUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!, $winboardMenuPlanChoices: [winboardMenu_insert_input!]!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planId, _planId]}
  ) {
    id
  }
  insert_winboardMenu(
    objects: $winboardMenuPlanChoices
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, _planChoiceId]}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanWinboardPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanWinboardPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'planChoiceId' | 'name'>
        & { winboardMenus: Array<(
          { __typename?: 'winboardMenu' }
          & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
        )> }
      )> }
    )> }
  )> }
);

export type EditPlanWinboardPlanGetCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanWinboardPlanGetCodeQuery = (
  { __typename?: 'query_root' }
  & { winboardMenu: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'code'>
  )> }
);

export type EditPlanWinboardPlanUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
  winboardMenuPlanChoices: Array<Types.WinboardMenuInsertInput> | Types.WinboardMenuInsertInput;
}>;


export type EditPlanWinboardPlanUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )>, insert_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanWinboardPlanGetPlanDocument = gql`
    query EditPlanWinboardPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        winboardMenus(where: {_planId: {_eq: $planId}}) {
          id
          name
          code
          bumonCode
          bumonName
          categoryCode
          categoryName
        }
      }
    }
  }
}
    `;

/**
 * __useEditPlanWinboardPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanWinboardPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanWinboardPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanWinboardPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanWinboardPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanWinboardPlanGetPlanQuery, EditPlanWinboardPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanWinboardPlanGetPlanQuery, EditPlanWinboardPlanGetPlanQueryVariables>(EditPlanWinboardPlanGetPlanDocument, options);
      }
export function useEditPlanWinboardPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanWinboardPlanGetPlanQuery, EditPlanWinboardPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanWinboardPlanGetPlanQuery, EditPlanWinboardPlanGetPlanQueryVariables>(EditPlanWinboardPlanGetPlanDocument, options);
        }
export type EditPlanWinboardPlanGetPlanQueryHookResult = ReturnType<typeof useEditPlanWinboardPlanGetPlanQuery>;
export type EditPlanWinboardPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanWinboardPlanGetPlanLazyQuery>;
export type EditPlanWinboardPlanGetPlanQueryResult = Apollo.QueryResult<EditPlanWinboardPlanGetPlanQuery, EditPlanWinboardPlanGetPlanQueryVariables>;
export const EditPlanWinboardPlanGetCodeDocument = gql`
    query EditPlanWinboardPlanGetCode($companyId: uuid!) {
  winboardMenu(where: {companyId: {_eq: $companyId}}) {
    code
  }
}
    `;

/**
 * __useEditPlanWinboardPlanGetCodeQuery__
 *
 * To run a query within a React component, call `useEditPlanWinboardPlanGetCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanWinboardPlanGetCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanWinboardPlanGetCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanWinboardPlanGetCodeQuery(baseOptions: Apollo.QueryHookOptions<EditPlanWinboardPlanGetCodeQuery, EditPlanWinboardPlanGetCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanWinboardPlanGetCodeQuery, EditPlanWinboardPlanGetCodeQueryVariables>(EditPlanWinboardPlanGetCodeDocument, options);
      }
export function useEditPlanWinboardPlanGetCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanWinboardPlanGetCodeQuery, EditPlanWinboardPlanGetCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanWinboardPlanGetCodeQuery, EditPlanWinboardPlanGetCodeQueryVariables>(EditPlanWinboardPlanGetCodeDocument, options);
        }
export type EditPlanWinboardPlanGetCodeQueryHookResult = ReturnType<typeof useEditPlanWinboardPlanGetCodeQuery>;
export type EditPlanWinboardPlanGetCodeLazyQueryHookResult = ReturnType<typeof useEditPlanWinboardPlanGetCodeLazyQuery>;
export type EditPlanWinboardPlanGetCodeQueryResult = Apollo.QueryResult<EditPlanWinboardPlanGetCodeQuery, EditPlanWinboardPlanGetCodeQueryVariables>;
export const EditPlanWinboardPlanUpdateWinboardMenuDocument = gql`
    mutation EditPlanWinboardPlanUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!, $winboardMenuPlanChoices: [winboardMenu_insert_input!]!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planId, _planId]}
  ) {
    id
  }
  insert_winboardMenu(
    objects: $winboardMenuPlanChoices
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, _planChoiceId]}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanWinboardPlanUpdateWinboardMenuMutationFn = Apollo.MutationFunction<EditPlanWinboardPlanUpdateWinboardMenuMutation, EditPlanWinboardPlanUpdateWinboardMenuMutationVariables>;

/**
 * __useEditPlanWinboardPlanUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useEditPlanWinboardPlanUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanWinboardPlanUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanWinboardPlanUpdateWinboardMenuMutation, { data, loading, error }] = useEditPlanWinboardPlanUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *      winboardMenuPlanChoices: // value for 'winboardMenuPlanChoices'
 *   },
 * });
 */
export function useEditPlanWinboardPlanUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanWinboardPlanUpdateWinboardMenuMutation, EditPlanWinboardPlanUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanWinboardPlanUpdateWinboardMenuMutation, EditPlanWinboardPlanUpdateWinboardMenuMutationVariables>(EditPlanWinboardPlanUpdateWinboardMenuDocument, options);
      }
export type EditPlanWinboardPlanUpdateWinboardMenuMutationHookResult = ReturnType<typeof useEditPlanWinboardPlanUpdateWinboardMenuMutation>;
export type EditPlanWinboardPlanUpdateWinboardMenuMutationResult = Apollo.MutationResult<EditPlanWinboardPlanUpdateWinboardMenuMutation>;
export type EditPlanWinboardPlanUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<EditPlanWinboardPlanUpdateWinboardMenuMutation, EditPlanWinboardPlanUpdateWinboardMenuMutationVariables>;