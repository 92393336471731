import { createFormItem, Form } from "components/antd/Form";
import { ServiceAdmin } from "pages/EditServiceAdmin/types";

export type EditServiceAdminFormValues = Pick<ServiceAdmin, "email">;

const getInitialValues = (serviceAdmin: ServiceAdmin) => {
  const { email } = serviceAdmin;
  return {
    email,
  };
};

export const EditServiceAdminFormItem = createFormItem<EditServiceAdminFormValues>();

export const useEditServiceAdminForm = (serviceAdmin: ServiceAdmin) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(serviceAdmin);
  const values = form.getFieldsValue() as EditServiceAdminFormValues;

  return { form, initialValues, values };
};
