import { useSteraFeatureFlagQuery } from "./queries";

export const useSteraFeatureFlag = ({ companyId }: { companyId?: string }) => {
  const { data, loading } = useSteraFeatureFlagQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const useSteraShopCount = data?.cashRegisterConfig_aggregate.aggregate?.count ?? 0;

  return {
    loading,
    isSteraFeatureAvailable: useSteraShopCount > 0,
  };
};
