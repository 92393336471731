import React, { memo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { FormSection } from "components/Form/FormSection";
import { InputPrice } from "components/Input/InputPrice";
import { Spacer } from "components/Spacer";

import { SalesByMonths } from "../../types";
import { EditSalesBudgetFormItem } from "../useEditSalesBudgetForm";

const Price = styled.span`
  font-weight: bold;
`;

type Props = {
  targetMonth: dayjs.Dayjs;
  salesByMonths?: SalesByMonths;
};

export const MonthlyBudgetField = memo<Props>(({ targetMonth, salesByMonths }) => {
  const lastMonthSales = salesByMonths?.monthlySalesAmount.find(
    (sales) => sales.businessDate === targetMonth.subtract(1, "month").format("YYYY-MM-DD"),
  )?.taxExcludedSalesAmount;

  const lastYearSales = salesByMonths?.monthlySalesAmount.find(
    (sales) => sales.businessDate === targetMonth.subtract(1, "year").format("YYYY-MM-DD"),
  )?.taxExcludedSalesAmount;

  return (
    <FormSection title="2. 月間目標を設定してください">
      <EditSalesBudgetFormItem
        label="月間目標"
        name="monthlyBudget"
        rules={[{ required: true, message: "月間目標を入力してください" }]}
        endSpacer={null}
      >
        <InputPrice />
      </EditSalesBudgetFormItem>
      <Spacer size={4} />
      <div>
        先月の売上: {lastMonthSales ? <Price>{formatPrice(lastMonthSales)}（税抜）</Price> : "-"}
        <> / </>
        前年同月の売上: {lastYearSales ? <Price>{formatPrice(lastYearSales)}（税抜）</Price> : "-"}
      </div>
    </FormSection>
  );
});
