import React, { memo } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

import AccountNoImageIcon from "assets/account_no_image.svg";
import { Spacer } from "components/Spacer";
import { colors, grey } from "constants/colors";

import { MessageFormValue } from "../../types";
import { LineOfficialAccountFragment } from "../queries";

import { Room } from "./Room";
import { RoomWrapper } from "./RoomWrapper";

const Container = styled.div`
  padding: 8px;
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 3px;
  color: ${colors.Text.Secondary};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  list-style: none;

  & + & {
    margin-top: 16px;
  }
`;

const RoomContentSkeltonWrapper = styled.div`
  padding-top: 6px;
`;

const RoomContentSkelton = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 8px;
  background-color: ${grey[3]};

  & + & {
    margin-top: 6px;
  }
`;

type Props = {
  lineOfficialAccount: LineOfficialAccountFragment;
  messages: MessageFormValue[];
};

export const RoomListPanel = memo(({ lineOfficialAccount, messages }: Props) => {
  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : undefined;

  return (
    <Container>
      <SearchBox>
        <SearchOutlined />
        <Spacer width={6} />
        検索
      </SearchBox>

      <Spacer height={12} />

      <List>
        {lastMessage ? (
          <Item>
            <RoomWrapper
              icon={
                <Avatar size={48} src={lineOfficialAccount.pictureUrl ?? <AccountNoImageIcon />} />
              }
            >
              <Room companyName={lineOfficialAccount.channelName} message={lastMessage} />
            </RoomWrapper>
          </Item>
        ) : null}

        <Item>
          <RoomWrapper icon={<Avatar size={48} icon={<UserOutlined />} />}>
            <RoomContentSkeltonWrapper>
              <RoomContentSkelton width="110px" />
              <RoomContentSkelton width="100%" />
              <RoomContentSkelton width="100%" />
            </RoomContentSkeltonWrapper>
          </RoomWrapper>
        </Item>
      </List>
    </Container>
  );
});
