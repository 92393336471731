import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Descriptions, Modal, Select, Tag } from "antd";

import { Option } from "../types";

const SelectOption = Select.Option;

type Props = {
  options: Option[];
  visible: boolean;
  onDismiss(): void;
  onSubmit: (option: Option) => void;
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-content {
    flex-wrap: wrap;
    row-gap: 8px;
  }
`;

export const SelectCopyOptionModal = memo<Props>(({ visible, options, onSubmit, onDismiss }) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const selectOption = useCallback(
    (optionId: unknown) => {
      setSelectedOption(options.find((option) => option.optionId === Number(optionId)) ?? null);
    },
    [options],
  );
  const confirm = useCallback(() => {
    if (!selectedOption) return;
    onSubmit(selectedOption);
    onDismiss();
  }, [selectedOption, onSubmit, onDismiss]);

  const generateDescriptionsItems = (option: Option) => [
    {
      key: "choices",
      label: "選択肢",
      children: option.choices.map((choice) => <Tag key={choice.choiceId}>{choice.name}</Tag>),
    },
    {
      key: "menuOptions",
      label: "紐づくメニュー",
      children: option.menuOptions.map((menuOption) => (
        <Tag key={menuOption.menu.menuId}>{menuOption.menu.name}</Tag>
      )),
    },
  ];

  return (
    <Modal
      title="オプションを選択"
      open={visible}
      okText="コピーする"
      cancelText="キャンセル"
      okButtonProps={{ disabled: !selectedOption }}
      onCancel={onDismiss}
      onOk={confirm}
    >
      <StyledSelect
        showSearch
        optionFilterProp="label"
        onSelect={selectOption}
        optionLabelProp="label"
      >
        {options.map((option) => (
          <SelectOption key={option.optionId} label={option.name} value={option.optionId}>
            {option.name}

            <StyledDescriptions column={1} items={generateDescriptionsItems(option)} />
          </SelectOption>
        ))}
      </StyledSelect>
    </Modal>
  );
});
