import "libs/sentry";

import React, { memo, useCallback, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { Loading } from "components/App/Loading";
import { useAppGetCompanyQuery, useAppGetDashboardAccountQuery } from "components/App/queries";
import { useAnalytics } from "hooks/useAnalytics";
import { useAuthEffect } from "hooks/useAuthEffect";
import { useCompany } from "hooks/useCompany";
import { useCurrentRole } from "hooks/useCurrentRole";
import { useDatadogLogs } from "hooks/useDatadogLogs";
import { useDatadogRum } from "hooks/useDatadogRum";
import { useFirebaseAuth } from "hooks/useFirebaseAuth";
import { useLocationChange } from "hooks/useLocationChange";
import { useShop } from "hooks/useShop";
import { useStorage } from "hooks/useStorage";
import { useUpdate } from "hooks/useUpdate";
import { useUser } from "hooks/useUser";
import { Role } from "types/role";

export const App = memo(({ children }) => {
  useUpdate();
  useFirebaseAuth();
  useAuthEffect();
  useDatadogRum();
  useDatadogLogs();

  const { logEvent } = useAnalytics();
  const logCheckInEvent = useCallback(() => logEvent({ type: "check_in" }), [logEvent]);

  useLocationChange(() => logEvent({ type: "page_view" }));

  const { loadCompanyIds, loadLastShopId } = useStorage();

  const [currentCompany, setCurrentCompany] = useCompany();
  const [currentShop, setCurrentShop] = useShop();
  const { user: currentUser, isInitialized: isUserInitialized } = useUser();
  const currentRole = useCurrentRole();

  const { data: getCompany, loading: isGetCompanyLoading } = useAppGetCompanyQuery(
    currentRole && [Role.ServiceAdmin, Role.DashboardAccount].includes(currentRole)
      ? {
          onCompleted: logCheckInEvent,
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );

  const { data: getDashboardAccount } = useAppGetDashboardAccountQuery(
    currentUser?.email
      ? {
          variables: { email: currentUser.email },
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );
  const accountName = getDashboardAccount?.dashboardAccount?.[0]?.userName;

  const shouldSkipCacheLoad = Boolean(currentCompany || isGetCompanyLoading || !currentRole);
  useEffect(() => {
    if (shouldSkipCacheLoad) return;
    const {
      companyIds: [lastCompanyIdCache],
    } = loadCompanyIds();
    const lastShopIdCache = loadLastShopId();
    const companies = getCompany?.company ?? [];

    const company = companies.find(
      (company) => company.id === lastCompanyIdCache || company.companyId === lastCompanyIdCache,
    );

    if (lastCompanyIdCache && company) {
      // NOTE: companyId のキャッシュがある場合
      const shops = company?.shops ?? [];
      setCurrentCompany(company.id);

      if (lastShopIdCache && shops.some(({ shopId }) => shopId === lastShopIdCache)) {
        setCurrentShop(lastShopIdCache);
      } else if (!currentShop || !shops.some(({ shopId }) => shopId === currentShop.shopId)) {
        setCurrentShop(shops[0]?.shopId);
      }
    } else {
      // NOTE: companyId のキャッシュがない場合
      const company = companies[0];
      const shops = companies[0]?.shops ?? [];
      setCurrentCompany(company?.id);

      if (!currentShop || !shops.some(({ shopId }) => shopId === currentShop.shopId)) {
        setCurrentShop(shops[0]?.shopId);
      }
    }
  }, [
    currentShop,
    getCompany,
    loadCompanyIds,
    loadLastShopId,
    setCurrentCompany,
    setCurrentShop,
    shouldSkipCacheLoad,
  ]);

  useEffect(() => {
    if (!currentUser) return;

    datadogRum.setUser({
      id: currentUser.uid,
      email: currentUser.email ?? undefined,
      name: accountName,
    });
  }, [accountName, currentUser]);

  const loading = !isUserInitialized;

  return <Loading loading={loading}>{children}</Loading>;
});
