import { useCallback, useMemo } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";
import { PlanOption, Translation } from "pages/PlanOptions/EditPlanOptionModal/types";
import { SupportedLocaleEnum } from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

export type EditPlanOptionFormValues = Pick<
  PlanOption,
  | "description"
  | "inputType"
  | "isMainOption"
  | "maxChoiceNum"
  | "minChoiceNum"
  | "name"
  | "receiptDisplayName"
> &
  Partial<{
    nameEn: string;
    nameKr: string;
    nameCn: string;
  }>;

const getInitialValues = ({
  planOption,
  localeToNameTranslationMap,
}: {
  planOption: PlanOption;
  localeToNameTranslationMap: Map<SupportedLocaleEnum, Translation>;
}) => {
  const {
    description,
    inputType,
    isMainOption,
    maxChoiceNum,
    minChoiceNum,
    name,
    receiptDisplayName,
  } = planOption;

  return {
    description,
    inputType,
    isMainOption,
    maxChoiceNum,
    minChoiceNum,
    name,
    receiptDisplayName,
    nameEn: localeToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
    nameKr: localeToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
    nameCn: localeToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
  };
};

export const EditPlanOptionFormItem = createFormItem<EditPlanOptionFormValues>();

export const useEditPlanOptionForm = (
  planOption: PlanOption,
  translations: Translation[],
  onChange: (formValues: EditPlanOptionFormValues) => void,
) => {
  const localeToNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Name)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );
  const initialValues = getInitialValues({ planOption, localeToNameTranslationMap });

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditPlanOptionFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { initialValues, change };
};
