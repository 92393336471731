import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditOptionTranslationsFormItem } from "./useEditOptionTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled: boolean };

export const OptionNameKrField = memo<Props>(({ ...props }) => (
  <EditOptionTranslationsFormItem label="オプション名：韓国語" name="optionNameKr" {...props}>
    <Input disabled={props.disabled} />
  </EditOptionTranslationsFormItem>
));
