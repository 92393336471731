import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuTranslationsFormItem } from "./useEditMenuTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeatureLabelCnField = memo<Props>(({ ...props }) => (
  <EditMenuTranslationsFormItem label="中国語 (簡体字)" name="featureLabelCn" {...props}>
    <Input />
  </EditMenuTranslationsFormItem>
));
