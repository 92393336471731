import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditOnSitePaymentDiscountTypeFormItem } from "../useEditOnSitePaymentDiscountTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LabelField = memo<Props>((props) => (
  <EditOnSitePaymentDiscountTypeFormItem
    label="割引方法名"
    name="label"
    rules={[{ required: true, message: "割引方法名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditOnSitePaymentDiscountTypeFormItem>
));
