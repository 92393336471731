import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardUploadGetShops = gql`
    query WinboardUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    winboardConfig {
      winboardConfigId
      enabled
    }
  }
}
    `;
export type WinboardUploadGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardUploadGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { winboardConfig?: Types.Maybe<(
      { __typename?: 'winboardConfig' }
      & Pick<Types.WinboardConfig, 'winboardConfigId' | 'enabled'>
    )> }
  )> }
);


export const WinboardUploadGetShopsDocument = gql`
    query WinboardUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    winboardConfig {
      winboardConfigId
      enabled
    }
  }
}
    `;

/**
 * __useWinboardUploadGetShopsQuery__
 *
 * To run a query within a React component, call `useWinboardUploadGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardUploadGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardUploadGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardUploadGetShopsQuery(baseOptions: Apollo.QueryHookOptions<WinboardUploadGetShopsQuery, WinboardUploadGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardUploadGetShopsQuery, WinboardUploadGetShopsQueryVariables>(WinboardUploadGetShopsDocument, options);
      }
export function useWinboardUploadGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardUploadGetShopsQuery, WinboardUploadGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardUploadGetShopsQuery, WinboardUploadGetShopsQueryVariables>(WinboardUploadGetShopsDocument, options);
        }
export type WinboardUploadGetShopsQueryHookResult = ReturnType<typeof useWinboardUploadGetShopsQuery>;
export type WinboardUploadGetShopsLazyQueryHookResult = ReturnType<typeof useWinboardUploadGetShopsLazyQuery>;
export type WinboardUploadGetShopsQueryResult = Apollo.QueryResult<WinboardUploadGetShopsQuery, WinboardUploadGetShopsQueryVariables>;