import React, { memo } from "react";
import styled from "styled-components";
import { Card, Col, Row, Statistic } from "antd";
import { sumBy } from "util/array";

import { Spacer } from "components/Spacer";
import { Sales } from "pages/Sales/types";

type Props = {
  sales: Sales[];
};

const Text = styled.span`
  font-size: 24px;
  font-weight: 300;
`;

const SmallText = styled(Text)`
  font-size: 14px;
`;

const formatter = new Intl.NumberFormat("ja");

export const SalesStatistics = memo<Props>(({ sales }) => {
  const salesAmount = formatter.format(sumBy(sales, "taxIncludedAmount"));
  const netIncome = formatter.format(sumBy(sales, "taxExcludedAmount"));
  const taxIncludedTotalCostPrice = formatter.format(sumBy(sales, "taxIncludedCostPrice"));
  const taxExcludedTotalCostPrice = formatter.format(sumBy(sales, "taxExcludedCostPrice"));

  return (
    <Row gutter={32}>
      <Col span={12}>
        <Card>
          <Statistic
            title="売上高"
            valueRender={() => (
              <>
                <SmallText>税込</SmallText>
                <Text>{salesAmount}</Text>
                <SmallText>円</SmallText>
                <Spacer size={16} inline />
                <SmallText>（税抜</SmallText>
                <Text>{netIncome}</Text>
                <SmallText>円）</SmallText>
              </>
            )}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <Statistic
            title="売上原価"
            valueRender={() => (
              <>
                <SmallText>税込</SmallText>
                <Text>{taxIncludedTotalCostPrice}</Text>
                <SmallText>円</SmallText>
                <Spacer size={16} inline />
                <SmallText>（税抜</SmallText>
                <Text>{taxExcludedTotalCostPrice}</Text>
                <SmallText>円）</SmallText>
              </>
            )}
          />
        </Card>
      </Col>
    </Row>
  );
});
