import React, { memo } from "react";
import styled from "styled-components";
import { Layout } from "antd";

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  height: 100%;
  align-items: center;
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
`;

export const SimpleLayout = memo(({ children }) => (
  <StyledLayout>
    <StyledContent>{children}</StyledContent>
  </StyledLayout>
));
