import { CurrentUser } from "hooks/useUser";
import { Role } from "types/role";

export const getRole = (
  currentUser: Pick<CurrentUser, "role"> | null | undefined,
): Role | undefined => {
  if (currentUser === undefined) return undefined;
  if (currentUser === null) return Role.Guest;
  if (currentUser.role === Role.ServiceAdmin) return Role.ServiceAdmin;
  if (currentUser.role === Role.DashboardAccount) return Role.DashboardAccount;
  if (typeof currentUser.role === "string") return Role.Invalid;
  return undefined;
};
