import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditTecAggregationMenuFormItem } from "../useEditTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationDpCodeField = memo<Props>((props) => (
  <EditTecAggregationMenuFormItem
    label="部門コード"
    name="dpCode"
    rules={[{ required: true, pattern: /^\d{1,4}$/, message: "数字で入力してください" }]}
    required
    {...props}
  >
    <InputCode />
  </EditTecAggregationMenuFormItem>
));
