import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { FormHelp } from "components/Form/FormHelp";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { ShopOrderableTimeTable } from "pages/ShopOrderableTimes/ShopOrderableTimeTable";

import {
  useShopOrderableTimesGetOrderableTimesQuery,
  useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery,
} from "./queries";

export const ShopOrderableTimes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [shop] = useShop();
  const shopId = shop?.shopId ?? null;

  const {
    data: getOrderableTimesData,
    loading: loadingGetOrderableTimesData,
    error: getOrderableTimesError,
  } = useShopOrderableTimesGetOrderableTimesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const {
    data: getOrderableTimeWithShopOrderableTimeTermsData,
    loading: loadingGetOrderableTimeWithShopOrderableTimeTerms,
    error: getOrderableTimeWithShopOrderableTimeTermsError,
  } = useShopOrderableTimesGetOrderableTimeWithShopOrderableTimeTermsQuery(
    companyId && shopId ? { variables: { companyId, shopId } } : { skip: true },
  );

  const data = shopId ? getOrderableTimeWithShopOrderableTimeTermsData : getOrderableTimesData;
  const orderableTimes = data?.company?.[0]?.orderableTimes ?? [];

  const loading = loadingGetOrderableTimesData || loadingGetOrderableTimeWithShopOrderableTimeTerms;
  const shouldShowError = getOrderableTimesError || getOrderableTimeWithShopOrderableTimeTermsError;

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="アプリ表示時間設定">
      <PageHeader
        title="アプリ表示時間設定"
        extra={
          canAccess("addOrderableTime")
            ? [
                <Link key="add" to="/orderableTime/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []
        }
        description={
          <FormHelp
            label="アプリ表示時間とはメニューやプランがお客様のスマートフォンアプリ上の画面に表示される時間帯のことで、店舗別に設定できます。"
            help="ハンディではアプリ表示時間に関係なく常に注文が可能です。"
          />
        }
        footer={<ShopSelector />}
      />
      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <ShopOrderableTimeTable shopId={shopId} orderableTimes={orderableTimes} loading={loading} />
    </DashboardLayout>
  );
};
