import React, { memo } from "react";
import { Space, Typography } from "antd";
import {
  findSortKeyByDayWeek,
  formatOrderableTimeTermDayWeek,
  formatOrderableTimeWithChangeDateTime,
  OrderableTimeTermDayWeekType,
} from "models/orderableTime";
import { normalizeDuration } from "util/duration";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";
import {
  OrderableTime,
  OrderableTimeWithShopOrderableTimeTerms,
} from "pages/ShopOrderableTimes/type";

type Props = {
  loading?: boolean;
  shopId: string | null;
  orderableTimes: OrderableTime[] | OrderableTimeWithShopOrderableTimeTerms[];
};

export const ShopOrderableTimeTable = memo<Props>(({ loading, shopId, orderableTimes }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns = [
    { title: "名前", dataIndex: "name" },
    {
      title: "アプリ表示時間",
      render(_: string, orderableTime: OrderableTime | OrderableTimeWithShopOrderableTimeTerms) {
        const shopOrderableTimeTerms =
          "shopOrderableTimeTerms" in orderableTime ? orderableTime.shopOrderableTimeTerms : [];
        return (
          <Space direction="vertical">
            {shopOrderableTimeTerms
              .map((term) => {
                const dayWeekName = formatOrderableTimeTermDayWeek(term.dayWeek);
                const { start, end } = formatOrderableTimeWithChangeDateTime({
                  ...normalizeDuration(term),
                  changeDateTime: term.shop.changeDateTime,
                });
                return { ...term, dayWeekName, start, end };
              })
              /* changeDateTime を考慮した start / end にしてから sort する */
              .sort((a, b) => {
                if (a.start > b.start) return 1;
                if (a.start < b.start) return -1;
                return 0;
              })
              .sort(
                (a, b) =>
                  findSortKeyByDayWeek(a.dayWeek as OrderableTimeTermDayWeekType) -
                  findSortKeyByDayWeek(b.dayWeek as OrderableTimeTermDayWeekType),
              )
              .map(({ id, dayWeekName, start, end }) => (
                <Typography.Text key={id}>
                  {dayWeekName}: {start} ~ {end}
                </Typography.Text>
              ))}
          </Space>
        );
      },
    },
    ...(canAccess("editOrderableTime")
      ? [
          {
            title: "",
            width: 60,
            align: "center",
            fixed: "right",
            render(_: string, { id }: OrderableTime) {
              if (shopId === null) return null;

              return (
                <IconLink to={`/orderableTime/${id}/shop/${shopId}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={orderableTimes}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
