import React, { memo, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "antd";
import { blue, grey, red } from "@ant-design/colors";
import { DeleteOutlined } from "@ant-design/icons";

const Container = styled.div<{ isDragging: boolean }>`
  position: absolute;
  left: -1px;
  width: calc(100% + 1px);
  border: 1px solid ${blue.primary};
  background-color: ${blue[0]};
  color: ${blue.primary};

  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: ${grey[0]};
      border: 1px solid ${grey[9]};
      opacity: 0.5;
      color: ${grey[9]};
    `}
`;

const Cell = styled.div<{ isDragging: boolean }>`
  position: relative;
  height: 100%;
  padding-top: 4px;
  padding-left: 6px;
  cursor: ${({ isDragging }) => (isDragging ? "move" : "pointer")};
`;

const StyledDeleteButton = styled(Button).attrs({
  type: "link",
})`
  position: absolute;
  color: ${red[3]};
  bottom: 2px;
  right: 2px;

  &:hover,
  &:focus {
    color: ${red[5]};
  }
`;

type Props = {
  top: number;
  height: number;
  isDragging?: boolean;
  onDeleteButtonClick?: () => void;
  children: React.ReactNode;
  // NOTE: テスト用の識別子
  label?: string;
  onStartMove?: React.MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const EventCell = memo<Props>(
  ({
    top,
    height,
    children,
    isDragging = false,
    onDeleteButtonClick,
    label,
    onStartMove,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onClick,
  }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isMoving, setIsMoving] = useState(false);

    const handleMouseDown = useCallback(() => {
      setIsFocused(true);
      setIsMoving(false);
    }, []);

    const handleMouseUp = useCallback(() => {
      setIsFocused(false);
      setIsMoving(false);
    }, []);

    const handleMouseMove = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (isFocused && !isMoving) {
          setIsMoving(true);
          onStartMove?.(e);
        }
      },
      [isFocused, isMoving, onStartMove],
    );

    return (
      <Container
        data-label={label}
        style={{
          transform: `translateY(${top}px)`,
          height: `${height + 1}px`,
        }}
        isDragging={isDragging}
      >
        <Cell
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onClick={onClick}
          isDragging={isDragging}
        >
          {children}
          {!isDragging && (
            <StyledDeleteButton icon={<DeleteOutlined />} onClick={onDeleteButtonClick} />
          )}
        </Cell>
      </Container>
    );
  },
);
