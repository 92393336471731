import React, { memo } from "react";
import { DatePicker } from "antd";

import { FormSection } from "components/Form/FormSection";

import { AddSalesBudgetFormItem, AddSalesBudgetFormValues } from "../useAddSalesBudgetForm";

type Props = {
  onChange: (value: AddSalesBudgetFormValues["targetMonth"] | null) => void;
};

export const TargetMonthField = memo<Props>(({ onChange }) => (
  <FormSection title="1. 目標設定をする月を選択してください">
    <AddSalesBudgetFormItem
      label="月"
      name="targetMonth"
      rules={[{ required: true, message: "目標設定する月を選択してください" }]}
      endSpacer={null}
    >
      <DatePicker picker="month" onChange={onChange} />
    </AddSalesBudgetFormItem>
  </FormSection>
));
