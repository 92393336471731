import dayjs from "dayjs";
import { normalizeHoursAndMinutes } from "util/time";

type Duration<T> = {
  start: T;
  end: T;
};

type MaybeDuration = Partial<Duration<string | null | undefined>>;

const normalizeTime = (time: string) => {
  const isTime = time.match(/\d{2}:\d{2}$/);
  if (isTime) {
    const hours = Number(time.slice(0, 2));
    return `${hours > 24 ? hours % 24 : hours}${time.slice(2)}`;
  }
  return time;
};

export const getHours = (time: string) =>
  normalizeHoursAndMinutes(dayjs(normalizeTime(time), "HH:mm"));

export const normalizeDuration = ({ start, end }: MaybeDuration): Duration<dayjs.Dayjs> => ({
  start: getHours(start ?? ""),
  end: getHours(end ?? ""),
});

export const addHours = (time: string, hours: number) => {
  const isTime = time.match(/\d{2}:\d{2}$/);
  if (isTime) {
    return `${Number(time.slice(0, 2)) + hours}${time.slice(2)}`;
  }
  return time;
};

export const formatBusinessDuration = ({ start, end }: Duration<dayjs.Dayjs>): Duration<string> =>
  end.isAfter(start)
    ? { start: start.format("HH:mm"), end: end.format("HH:mm") }
    : { start: start.format("HH:mm"), end: addHours(end.format("HH:mm"), 24) };
