import React, { memo, useMemo } from "react";
import { Typography } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Table } from "components/Table";

type DataSource = ValidateErrorEntity["errorFields"][number];

type Props = {
  formValidationError: ValidateErrorEntity;
};

export const EditMenusPricesValidationErrorPanel = memo<Props>(({ formValidationError }) => {
  const columns = useMemo(
    () => [
      {
        title: "名前",
        key: "name",
        width: 300,
        render(_: unknown, { name: [categoryMenuId] }: DataSource) {
          const name = formValidationError.values[categoryMenuId ?? ""]?.name;
          return name ?? "";
        },
      },
      {
        title: "エラー内容",
        key: "error",
        width: 600,
        render(_: unknown, { errors }: DataSource) {
          const error = errors.map((error) => `・${error}`).join("\n");
          return <Typography.Text type="danger">{error}</Typography.Text>;
        },
      },
    ],
    [formValidationError],
  );

  return (
    <Table
      bordered
      dataSource={formValidationError.errorFields}
      scroll={{ x: true }}
      columns={columns}
    />
  );
});
