import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionPikaichiOptionGetOption = gql`
    query EditOptionPikaichiOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      id
      choiceId
      name
      pikaichiMenuChoices {
        pikaichiMenuId
        choiceId: _choiceId
        pikaichiMenu {
          pikaichiMenuId
          pikaichiMenuCd
          pikaichiMenuName
          pikaichiBumonCd
          pikaichiBumonName
          pikaichiCategoryCd
          pikaichiCategoryName
        }
      }
    }
  }
}
    `;
export const EditOptionPikaichiOptionGetPikaichiMenuCd = gql`
    query EditOptionPikaichiOptionGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(where: {companyId: {_eq: $companyId}}) {
    pikaichiMenuCd
  }
}
    `;
export const EditOptionPikaichiOptionUpdateChoices = gql`
    mutation EditOptionPikaichiOptionUpdateChoices($pikaichiMenus: [pikaichiMenu_insert_input!]!, $pikaichiMenuChoices: [pikaichiMenuChoice_insert_input!]!) {
  insert_pikaichiMenu(
    objects: $pikaichiMenus
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    affected_rows
  }
  insert_pikaichiMenuChoice(
    objects: $pikaichiMenuChoices
    on_conflict: {constraint: pikaichiMenuChoice_pikaichiMenuId_choiceId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditOptionPikaichiOptionGetOptionQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionPikaichiOptionGetOptionQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name'>
    & { choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'id' | 'choiceId' | 'name'>
      & { pikaichiMenuChoices: Array<(
        { __typename?: 'pikaichiMenuChoice' }
        & Pick<Types.PikaichiMenuChoice, 'pikaichiMenuId'>
        & { choiceId: Types.PikaichiMenuChoice['_choiceId'] }
        & { pikaichiMenu: (
          { __typename?: 'pikaichiMenu' }
          & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
        ) }
      )> }
    )> }
  )> }
);

export type EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOptionPikaichiOptionGetPikaichiMenuCdQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenu: Array<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuCd'>
  )> }
);

export type EditOptionPikaichiOptionUpdateChoicesMutationVariables = Types.Exact<{
  pikaichiMenus: Array<Types.PikaichiMenuInsertInput> | Types.PikaichiMenuInsertInput;
  pikaichiMenuChoices: Array<Types.PikaichiMenuChoiceInsertInput> | Types.PikaichiMenuChoiceInsertInput;
}>;


export type EditOptionPikaichiOptionUpdateChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, insert_pikaichiMenuChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuChoice_mutation_response' }
    & Pick<Types.PikaichiMenuChoiceMutationResponse, 'affected_rows'>
  )> }
);


export const EditOptionPikaichiOptionGetOptionDocument = gql`
    query EditOptionPikaichiOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      id
      choiceId
      name
      pikaichiMenuChoices {
        pikaichiMenuId
        choiceId: _choiceId
        pikaichiMenu {
          pikaichiMenuId
          pikaichiMenuCd
          pikaichiMenuName
          pikaichiBumonCd
          pikaichiBumonName
          pikaichiCategoryCd
          pikaichiCategoryName
        }
      }
    }
  }
}
    `;

/**
 * __useEditOptionPikaichiOptionGetOptionQuery__
 *
 * To run a query within a React component, call `useEditOptionPikaichiOptionGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionPikaichiOptionGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionPikaichiOptionGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionPikaichiOptionGetOptionQuery(baseOptions: Apollo.QueryHookOptions<EditOptionPikaichiOptionGetOptionQuery, EditOptionPikaichiOptionGetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionPikaichiOptionGetOptionQuery, EditOptionPikaichiOptionGetOptionQueryVariables>(EditOptionPikaichiOptionGetOptionDocument, options);
      }
export function useEditOptionPikaichiOptionGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionPikaichiOptionGetOptionQuery, EditOptionPikaichiOptionGetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionPikaichiOptionGetOptionQuery, EditOptionPikaichiOptionGetOptionQueryVariables>(EditOptionPikaichiOptionGetOptionDocument, options);
        }
export type EditOptionPikaichiOptionGetOptionQueryHookResult = ReturnType<typeof useEditOptionPikaichiOptionGetOptionQuery>;
export type EditOptionPikaichiOptionGetOptionLazyQueryHookResult = ReturnType<typeof useEditOptionPikaichiOptionGetOptionLazyQuery>;
export type EditOptionPikaichiOptionGetOptionQueryResult = Apollo.QueryResult<EditOptionPikaichiOptionGetOptionQuery, EditOptionPikaichiOptionGetOptionQueryVariables>;
export const EditOptionPikaichiOptionGetPikaichiMenuCdDocument = gql`
    query EditOptionPikaichiOptionGetPikaichiMenuCd($companyId: uuid!) {
  pikaichiMenu(where: {companyId: {_eq: $companyId}}) {
    pikaichiMenuCd
  }
}
    `;

/**
 * __useEditOptionPikaichiOptionGetPikaichiMenuCdQuery__
 *
 * To run a query within a React component, call `useEditOptionPikaichiOptionGetPikaichiMenuCdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionPikaichiOptionGetPikaichiMenuCdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionPikaichiOptionGetPikaichiMenuCdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOptionPikaichiOptionGetPikaichiMenuCdQuery(baseOptions: Apollo.QueryHookOptions<EditOptionPikaichiOptionGetPikaichiMenuCdQuery, EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionPikaichiOptionGetPikaichiMenuCdQuery, EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables>(EditOptionPikaichiOptionGetPikaichiMenuCdDocument, options);
      }
export function useEditOptionPikaichiOptionGetPikaichiMenuCdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionPikaichiOptionGetPikaichiMenuCdQuery, EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionPikaichiOptionGetPikaichiMenuCdQuery, EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables>(EditOptionPikaichiOptionGetPikaichiMenuCdDocument, options);
        }
export type EditOptionPikaichiOptionGetPikaichiMenuCdQueryHookResult = ReturnType<typeof useEditOptionPikaichiOptionGetPikaichiMenuCdQuery>;
export type EditOptionPikaichiOptionGetPikaichiMenuCdLazyQueryHookResult = ReturnType<typeof useEditOptionPikaichiOptionGetPikaichiMenuCdLazyQuery>;
export type EditOptionPikaichiOptionGetPikaichiMenuCdQueryResult = Apollo.QueryResult<EditOptionPikaichiOptionGetPikaichiMenuCdQuery, EditOptionPikaichiOptionGetPikaichiMenuCdQueryVariables>;
export const EditOptionPikaichiOptionUpdateChoicesDocument = gql`
    mutation EditOptionPikaichiOptionUpdateChoices($pikaichiMenus: [pikaichiMenu_insert_input!]!, $pikaichiMenuChoices: [pikaichiMenuChoice_insert_input!]!) {
  insert_pikaichiMenu(
    objects: $pikaichiMenus
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    affected_rows
  }
  insert_pikaichiMenuChoice(
    objects: $pikaichiMenuChoices
    on_conflict: {constraint: pikaichiMenuChoice_pikaichiMenuId_choiceId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditOptionPikaichiOptionUpdateChoicesMutationFn = Apollo.MutationFunction<EditOptionPikaichiOptionUpdateChoicesMutation, EditOptionPikaichiOptionUpdateChoicesMutationVariables>;

/**
 * __useEditOptionPikaichiOptionUpdateChoicesMutation__
 *
 * To run a mutation, you first call `useEditOptionPikaichiOptionUpdateChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionPikaichiOptionUpdateChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionPikaichiOptionUpdateChoicesMutation, { data, loading, error }] = useEditOptionPikaichiOptionUpdateChoicesMutation({
 *   variables: {
 *      pikaichiMenus: // value for 'pikaichiMenus'
 *      pikaichiMenuChoices: // value for 'pikaichiMenuChoices'
 *   },
 * });
 */
export function useEditOptionPikaichiOptionUpdateChoicesMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionPikaichiOptionUpdateChoicesMutation, EditOptionPikaichiOptionUpdateChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionPikaichiOptionUpdateChoicesMutation, EditOptionPikaichiOptionUpdateChoicesMutationVariables>(EditOptionPikaichiOptionUpdateChoicesDocument, options);
      }
export type EditOptionPikaichiOptionUpdateChoicesMutationHookResult = ReturnType<typeof useEditOptionPikaichiOptionUpdateChoicesMutation>;
export type EditOptionPikaichiOptionUpdateChoicesMutationResult = Apollo.MutationResult<EditOptionPikaichiOptionUpdateChoicesMutation>;
export type EditOptionPikaichiOptionUpdateChoicesMutationOptions = Apollo.BaseMutationOptions<EditOptionPikaichiOptionUpdateChoicesMutation, EditOptionPikaichiOptionUpdateChoicesMutationVariables>;