import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OpenPriceField = memo<Props>((props) => (
  <AddMenuFormItem.NonProperty {...props}>
    <AddMenuFormItem
      name="openPrice"
      valuePropName="checked"
      help="ハンディから注文時に価格を都度設定できるようになります。利用時にはモバイルオーダーの画面には表示されなくなります。"
    >
      <Checkbox>フリーキーとして利用する</Checkbox>
    </AddMenuFormItem>
  </AddMenuFormItem.NonProperty>
));
