import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddGmoBankAccountGetShopsByCompany = gql`
    query AddGmoBankAccountGetShopsByCompany {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;
export const AddGmoBankAccountCreateAccount = gql`
    mutation AddGmoBankAccountCreateAccount($input: DashboardAccountCreateOrUpdateGmoBankAccountInput!) {
  onlinePaymentRoot {
    createOrUpdateGmoBankAccount(input: $input) {
      gmoBankAccountId
    }
  }
}
    `;
export type AddGmoBankAccountGetShopsByCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddGmoBankAccountGetShopsByCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId' | 'name'>
    )> }
  )> }
);

export type AddGmoBankAccountCreateAccountMutationVariables = Types.Exact<{
  input: Types.DashboardAccountCreateOrUpdateGmoBankAccountInput;
}>;


export type AddGmoBankAccountCreateAccountMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { createOrUpdateGmoBankAccount: (
      { __typename?: 'DashboardAccountCreateOrUpdateGmoBankAccountResult' }
      & Pick<Types.DashboardAccountCreateOrUpdateGmoBankAccountResult, 'gmoBankAccountId'>
    ) }
  )> }
);


export const AddGmoBankAccountGetShopsByCompanyDocument = gql`
    query AddGmoBankAccountGetShopsByCompany {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;

/**
 * __useAddGmoBankAccountGetShopsByCompanyQuery__
 *
 * To run a query within a React component, call `useAddGmoBankAccountGetShopsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddGmoBankAccountGetShopsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddGmoBankAccountGetShopsByCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddGmoBankAccountGetShopsByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<AddGmoBankAccountGetShopsByCompanyQuery, AddGmoBankAccountGetShopsByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddGmoBankAccountGetShopsByCompanyQuery, AddGmoBankAccountGetShopsByCompanyQueryVariables>(AddGmoBankAccountGetShopsByCompanyDocument, options);
      }
export function useAddGmoBankAccountGetShopsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddGmoBankAccountGetShopsByCompanyQuery, AddGmoBankAccountGetShopsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddGmoBankAccountGetShopsByCompanyQuery, AddGmoBankAccountGetShopsByCompanyQueryVariables>(AddGmoBankAccountGetShopsByCompanyDocument, options);
        }
export type AddGmoBankAccountGetShopsByCompanyQueryHookResult = ReturnType<typeof useAddGmoBankAccountGetShopsByCompanyQuery>;
export type AddGmoBankAccountGetShopsByCompanyLazyQueryHookResult = ReturnType<typeof useAddGmoBankAccountGetShopsByCompanyLazyQuery>;
export type AddGmoBankAccountGetShopsByCompanyQueryResult = Apollo.QueryResult<AddGmoBankAccountGetShopsByCompanyQuery, AddGmoBankAccountGetShopsByCompanyQueryVariables>;
export const AddGmoBankAccountCreateAccountDocument = gql`
    mutation AddGmoBankAccountCreateAccount($input: DashboardAccountCreateOrUpdateGmoBankAccountInput!) {
  onlinePaymentRoot {
    createOrUpdateGmoBankAccount(input: $input) {
      gmoBankAccountId
    }
  }
}
    `;
export type AddGmoBankAccountCreateAccountMutationFn = Apollo.MutationFunction<AddGmoBankAccountCreateAccountMutation, AddGmoBankAccountCreateAccountMutationVariables>;

/**
 * __useAddGmoBankAccountCreateAccountMutation__
 *
 * To run a mutation, you first call `useAddGmoBankAccountCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGmoBankAccountCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGmoBankAccountCreateAccountMutation, { data, loading, error }] = useAddGmoBankAccountCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGmoBankAccountCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddGmoBankAccountCreateAccountMutation, AddGmoBankAccountCreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGmoBankAccountCreateAccountMutation, AddGmoBankAccountCreateAccountMutationVariables>(AddGmoBankAccountCreateAccountDocument, options);
      }
export type AddGmoBankAccountCreateAccountMutationHookResult = ReturnType<typeof useAddGmoBankAccountCreateAccountMutation>;
export type AddGmoBankAccountCreateAccountMutationResult = Apollo.MutationResult<AddGmoBankAccountCreateAccountMutation>;
export type AddGmoBankAccountCreateAccountMutationOptions = Apollo.BaseMutationOptions<AddGmoBankAccountCreateAccountMutation, AddGmoBankAccountCreateAccountMutationVariables>;