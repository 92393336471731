import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, withFormDependencies } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { WithPreviewFormContainer } from "components/Form/WithPreviewFormContainer";
import { Spacer } from "components/Spacer";
import { UserAppPreview } from "components/UserAppPreview";
import { AddCouponFormItem } from "pages/AddCoupon/AddCouponForm/useAddCouponForm";
import { CouponTypeEnum } from "types/graphql";

import { Coupon, Menu } from "../types";

import { CouponContent } from "./CouponPreview/CouponContent";
import { CouponTypeField } from "./CouponTypeField";
import { MenuDiscountTypeCouponForm } from "./MenuDiscountTypeCouponForm";
import { TicketTypeCouponForm } from "./TicketTypeCouponForm";
import { useEditCouponForm } from "./useEditCouponForm";

const FlexField = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 300px;
`;

type Props = {
  coupon: Coupon;
  loading: boolean;
  onSubmit: ({ coupon }: { coupon: any }) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  menus: Menu[];
};

export const EditCouponForm = memo(
  ({ coupon, loading, onSubmit, onClose, onFormValidationError, menus }: Props) => {
    const { form, initialValues, submit } = useEditCouponForm(coupon, onSubmit);
    const [uploadImage, setUploadImage] = useState(initialValues.imageUrl ?? "");

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, submit, onFormValidationError, uploadImage]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const onValuesChange = useCallback(
      (changeValue) => {
        const { isDisabledAvailableUntil, isDisabledAvailableDays } = changeValue;
        if (isDisabledAvailableUntil) {
          form.setFieldsValue({ availableUntil: undefined });
        }
        if (isDisabledAvailableDays) {
          form.setFieldsValue({ availableDays: undefined });
        }
      },
      [form],
    );

    return (
      <Form
        name="coupon"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        layout="vertical"
      >
        <WithPreviewFormContainer>
          <FormSection title="1. クーポンタイプを決めましょう">
            <CouponTypeField />
          </FormSection>
          <Spacer size={32} />
          <AddCouponFormItem.NonProperty
            shouldUpdate={withFormDependencies(({ couponType }) => [couponType])}
            noStyle
          >
            {({ getFieldValue }) => {
              const couponTypeFieldValue: CouponTypeEnum = getFieldValue("couponType");
              switch (couponTypeFieldValue) {
                case CouponTypeEnum.MenuDiscount:
                  return <MenuDiscountTypeCouponForm menus={menus} />;
                case CouponTypeEnum.Ticket:
                  return (
                    <TicketTypeCouponForm image={uploadImage} setUploadImage={setUploadImage} />
                  );
              }
            }}
          </AddCouponFormItem.NonProperty>

          <Spacer size={24} />
          <FormActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button
              type="primary"
              onClick={loading ? undefined : handleSubmit}
              loading={loading}
              disabled={loading}
            >
              更新
            </Button>
          </FormActions>
        </WithPreviewFormContainer>

        {/* 各フィールドの値をプレビューにリアルタイムに表示するために、FormItem を利用 */}
        <AddCouponFormItem.NonProperty noStyle shouldUpdate initialValue={initialValues}>
          {({ getFieldsValue }) => {
            const {
              title,
              content,
              description,
              availableFrom,
              availableUntil,
              availableDays,
              termsOfUse,
              couponType,
            } = getFieldsValue();

            return (
              <PreviewWrapper>
                <UserAppPreview>
                  <CouponContent
                    coupon={{
                      title,
                      content,
                      description,
                      availableFrom,
                      availableUntil,
                      availableDays,
                      termsOfUse,
                      couponType,
                      imageUrl: uploadImage,
                    }}
                  />
                </UserAppPreview>
              </PreviewWrapper>
            );
          }}
        </AddCouponFormItem.NonProperty>
      </Form>
    );
  },
);
