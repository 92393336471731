import { move } from "util/array";

export const normalizePriorities = <T extends { priority: number }>(priorities: T[]) =>
  priorities.map((v, i) => ({ ...v, priority: i }));

export const updatePriorities = <T extends { priority: number; __typename?: any }>(
  priorities: T[],
  from: number,
  to: number,
) => normalizePriorities(move(priorities, from, to)).map(({ __typename: _, ...item }) => item);
