import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { QuestionnaireConfig } from "../types";

export type EditQuestionnaireConfigFormValues = Pick<
  QuestionnaireConfig,
  "id" | "imageUrl" | "questionnaireId" | "rewardCouponId"
>;

export const EditQuestionnaireConfigFormItem = createFormItem<EditQuestionnaireConfigFormValues>();

export const useEditQuestionnaireConfigForm = ({
  onSubmit,
  questionnaireConfig,
}: {
  onSubmit: ({
    questionnaireConfig,
  }: {
    questionnaireConfig: EditQuestionnaireConfigFormValues;
  }) => void;
  questionnaireConfig: QuestionnaireConfig;
}) => {
  const [form] = Form.useForm();
  const initialValues = questionnaireConfig;

  const submit = useCallback(
    ({ imageUrl }: { imageUrl: string | null }) => {
      const formValues = form.getFieldsValue() as EditQuestionnaireConfigFormValues;

      onSubmit({ questionnaireConfig: { ...formValues, imageUrl } });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
