import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useLiffId } from "hooks/useLiffId";
import { useLocationState } from "hooks/useLocationState";
import { ConnectQrCodePanel } from "pages/QrCodes/ConnectQrCodePanel";
import { QrCodeTable } from "pages/QrCodes/QrCodeTable";
import { useQrCodesGetShopsQuery, useQrCodesGetTableQuery } from "pages/QrCodes/queries";
import { Selectors, ShopSelectors } from "pages/QrCodes/ShopSelectors";

export const QrCodes = () => {
  const [selectors, setSelectors] = useLocationState<Selectors>("selectors", {});

  const { shopId } = selectors;

  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const {
    data: getShopsData,
    loading: loadingShops,
    error: getShopsDataError,
  } = useQrCodesGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = getShopsData?.shop ?? [];

  const {
    data: getTableData,
    loading: loadingTable,
    error: getTableDataError,
  } = useQrCodesGetTableQuery(shopId ? { variables: { shopId } } : { skip: true });
  const tables = getTableData?.table ?? [];

  const { liffId, loading: loadingLiffId, error: getLiffError } = useLiffId(shopId);

  const loading = loadingShops || loadingTable || loadingLiffId;

  const error = getShopsDataError || getTableDataError || getLiffError;

  return (
    <DashboardLayout title="QR コード一覧">
      <PageHeader title="QR コード一覧" />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {loading && <Loading height={300} />}
      {!loading && (
        <>
          <ShopSelectors shops={shops} selectors={selectors} setSelectors={setSelectors} />
          <ConnectQrCodePanel liffId={liffId} shopId={shopId} _companyId={_companyId} />
          <Spacer height={16} />
          <QrCodeTable loading={loading} liffId={liffId} shopId={shopId} tables={tables} />
        </>
      )}
    </DashboardLayout>
  );
};
