import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { Corporation } from "types/graphql";

export const filterCorporationsByName = <T extends Pick<Corporation, "name">>(
  corporations: T[],
  name: string,
) => {
  if (name) {
    const fuse = new Fuse(
      corporations.map(({ name }) => ({ name: hiraganaToKatakana(name) })),
      { keys: ["name"] },
    );
    const indexes = fuse.search(hiraganaToKatakana(name)).map(({ refIndex }) => refIndex);

    return indexes.map((index) => corporations[index]).filter(isNotNullable);
  }
  return corporations;
};
