import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";

import { DiscountTypeTable } from "./DiscountTypeTable";
import {
  useOnSitePaymentDiscountTypesArchiveMutation,
  useOnSitePaymentDiscountTypesGetDiscountTypesQuery,
} from "./queries";

export const OnSitePaymentDiscountTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getDiscountTypesData,
    loading: loadingDiscountTypes,
    refetch: refetchDiscountTypes,
    error,
  } = useOnSitePaymentDiscountTypesGetDiscountTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDiscountTypes = getDiscountTypesData?.onSitePaymentDiscountType ?? [];

  const [archiveMutation] = useOnSitePaymentDiscountTypesArchiveMutation();

  const onArchive = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await archiveMutation({ variables: { id } });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchDiscountTypes();
    },
    [archiveMutation, refetchDiscountTypes],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="割引方法一覧">
      <PageHeader
        title="割引方法一覧"
        extra={[
          ...(canAccess("editOnSitePaymentDiscountTypes")
            ? [
                <Link key="add" to="/onSitePaymentDiscountType/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []),
          ...(canAccess("editOnSitePaymentDiscountTypesPriorities")
            ? [
                <Link key="priority" to="/onSitePaymentDiscountType/priority/edit">
                  <Button>表示順編集</Button>
                </Link>,
              ]
            : []),
        ]}
      />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <DiscountTypeTable
        discountTypes={onSitePaymentDiscountTypes}
        loading={loadingDiscountTypes}
        onArchive={onArchive}
      />
    </DashboardLayout>
  );
};
