import React, { memo } from "react";
import styled from "styled-components";

import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";

import { AvailableDaysField } from "../AvailableDaysField";
import { AvailableFromField } from "../AvailableFromField";
import { AvailableUntilField } from "../AvailableUntilField";
import { ContentField } from "../ContentField";
import { DescriptionField } from "../DescriptionField";
import { NameField } from "../NameField";
import { TermsOfUseField } from "../TermsOfUseField";
import { TitleField } from "../TitleField";

const FlexField = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type Props = {
  image: string;
  setUploadImage: (image: string) => void;
};

export const TicketTypeCouponForm = memo<Props>(({ image, setUploadImage }) => {
  const [company] = useCompany();

  return (
    <>
      <FormSection title="2. クーポンの概要を登録しましょう">
        <ImageField
          image={image}
          setUploadImage={setUploadImage}
          uploadImageApiKey="coupon"
          formName="AddCouponForm"
        />
        <NameField />
        <TitleField />
        <ContentField />
        <DescriptionField />
        <TermsOfUseField />
      </FormSection>
      <Spacer size={32} />
      <FormSection title="3. 有効期間を決めましょう">
        <AvailableFromField />
        <FlexField>
          <AvailableUntilField />
        </FlexField>
        <FlexField>
          <AvailableDaysField />
        </FlexField>
      </FormSection>
    </>
  );
});
