import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { AddCompanyFormItem } from "../useAddCompanyForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddCompanyFormItem
    label="業態名"
    name="name"
    rules={[{ required: true, message: "業態名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddCompanyFormItem>
));
