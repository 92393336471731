import React, { memo } from "react";
import { Radio } from "antd";
import { QuestionnaireAnswerRadioOptions } from "models/questionnaireAnswer";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

export const VisitedQuestionnaireAnswerFilter = memo(() => (
  <MessageDeliveryJobFormItem name="questionnaireAnswerStatusFilter">
    <Radio.Group options={QuestionnaireAnswerRadioOptions} />
  </MessageDeliveryJobFormItem>
));
