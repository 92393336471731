import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditAccountingSlipImageModalGetAccountingSlipImage = gql`
    query EditAccountingSlipImageModalGetAccountingSlipImage($accountingSlipImageId: uuid!) {
  accountingSlipImage_by_pk(id: $accountingSlipImageId) {
    accountingSlipImageId: id
    imageUrl
    position
    description
  }
}
    `;
export const EditAccountingSlipImageModalEditAccountingSlipImage = gql`
    mutation EditAccountingSlipImageModalEditAccountingSlipImage($pk: accountingSlipImage_pk_columns_input!, $input: accountingSlipImage_set_input!) {
  update_accountingSlipImage_by_pk(pk_columns: $pk, _set: $input) {
    accountingSlipImageId: id
  }
}
    `;
export type EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables = Types.Exact<{
  accountingSlipImageId: Types.Scalars['uuid'];
}>;


export type EditAccountingSlipImageModalGetAccountingSlipImageQuery = (
  { __typename?: 'query_root' }
  & { accountingSlipImage_by_pk?: Types.Maybe<(
    { __typename?: 'accountingSlipImage' }
    & Pick<Types.AccountingSlipImage, 'imageUrl' | 'position' | 'description'>
    & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
  )> }
);

export type EditAccountingSlipImageModalEditAccountingSlipImageMutationVariables = Types.Exact<{
  pk: Types.AccountingSlipImagePkColumnsInput;
  input: Types.AccountingSlipImageSetInput;
}>;


export type EditAccountingSlipImageModalEditAccountingSlipImageMutation = (
  { __typename?: 'mutation_root' }
  & { update_accountingSlipImage_by_pk?: Types.Maybe<(
    { __typename?: 'accountingSlipImage' }
    & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
  )> }
);


export const EditAccountingSlipImageModalGetAccountingSlipImageDocument = gql`
    query EditAccountingSlipImageModalGetAccountingSlipImage($accountingSlipImageId: uuid!) {
  accountingSlipImage_by_pk(id: $accountingSlipImageId) {
    accountingSlipImageId: id
    imageUrl
    position
    description
  }
}
    `;

/**
 * __useEditAccountingSlipImageModalGetAccountingSlipImageQuery__
 *
 * To run a query within a React component, call `useEditAccountingSlipImageModalGetAccountingSlipImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAccountingSlipImageModalGetAccountingSlipImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAccountingSlipImageModalGetAccountingSlipImageQuery({
 *   variables: {
 *      accountingSlipImageId: // value for 'accountingSlipImageId'
 *   },
 * });
 */
export function useEditAccountingSlipImageModalGetAccountingSlipImageQuery(baseOptions: Apollo.QueryHookOptions<EditAccountingSlipImageModalGetAccountingSlipImageQuery, EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditAccountingSlipImageModalGetAccountingSlipImageQuery, EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables>(EditAccountingSlipImageModalGetAccountingSlipImageDocument, options);
      }
export function useEditAccountingSlipImageModalGetAccountingSlipImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditAccountingSlipImageModalGetAccountingSlipImageQuery, EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditAccountingSlipImageModalGetAccountingSlipImageQuery, EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables>(EditAccountingSlipImageModalGetAccountingSlipImageDocument, options);
        }
export type EditAccountingSlipImageModalGetAccountingSlipImageQueryHookResult = ReturnType<typeof useEditAccountingSlipImageModalGetAccountingSlipImageQuery>;
export type EditAccountingSlipImageModalGetAccountingSlipImageLazyQueryHookResult = ReturnType<typeof useEditAccountingSlipImageModalGetAccountingSlipImageLazyQuery>;
export type EditAccountingSlipImageModalGetAccountingSlipImageQueryResult = Apollo.QueryResult<EditAccountingSlipImageModalGetAccountingSlipImageQuery, EditAccountingSlipImageModalGetAccountingSlipImageQueryVariables>;
export const EditAccountingSlipImageModalEditAccountingSlipImageDocument = gql`
    mutation EditAccountingSlipImageModalEditAccountingSlipImage($pk: accountingSlipImage_pk_columns_input!, $input: accountingSlipImage_set_input!) {
  update_accountingSlipImage_by_pk(pk_columns: $pk, _set: $input) {
    accountingSlipImageId: id
  }
}
    `;
export type EditAccountingSlipImageModalEditAccountingSlipImageMutationFn = Apollo.MutationFunction<EditAccountingSlipImageModalEditAccountingSlipImageMutation, EditAccountingSlipImageModalEditAccountingSlipImageMutationVariables>;

/**
 * __useEditAccountingSlipImageModalEditAccountingSlipImageMutation__
 *
 * To run a mutation, you first call `useEditAccountingSlipImageModalEditAccountingSlipImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccountingSlipImageModalEditAccountingSlipImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccountingSlipImageModalEditAccountingSlipImageMutation, { data, loading, error }] = useEditAccountingSlipImageModalEditAccountingSlipImageMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccountingSlipImageModalEditAccountingSlipImageMutation(baseOptions?: Apollo.MutationHookOptions<EditAccountingSlipImageModalEditAccountingSlipImageMutation, EditAccountingSlipImageModalEditAccountingSlipImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAccountingSlipImageModalEditAccountingSlipImageMutation, EditAccountingSlipImageModalEditAccountingSlipImageMutationVariables>(EditAccountingSlipImageModalEditAccountingSlipImageDocument, options);
      }
export type EditAccountingSlipImageModalEditAccountingSlipImageMutationHookResult = ReturnType<typeof useEditAccountingSlipImageModalEditAccountingSlipImageMutation>;
export type EditAccountingSlipImageModalEditAccountingSlipImageMutationResult = Apollo.MutationResult<EditAccountingSlipImageModalEditAccountingSlipImageMutation>;
export type EditAccountingSlipImageModalEditAccountingSlipImageMutationOptions = Apollo.BaseMutationOptions<EditAccountingSlipImageModalEditAccountingSlipImageMutation, EditAccountingSlipImageModalEditAccountingSlipImageMutationVariables>;