import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GiftOptionGetGiftOptionMeta = gql`
    query GiftOptionGetGiftOptionMeta($shopId: uuid!) {
  giftOptionMeta(where: {shopId: {_eq: $shopId}}, limit: 1) {
    id
    option {
      id
      optionId
    }
  }
}
    `;
export const GiftOptionInsertGiftOption = gql`
    mutation GiftOptionInsertGiftOption($option: option_insert_input!) {
  insert_option_one(object: $option) {
    id
    optionId
  }
}
    `;
export const GiftOptionInsertGiftOptionMeta = gql`
    mutation GiftOptionInsertGiftOptionMeta($giftOptionMeta: giftOptionMeta_insert_input!) {
  insert_giftOptionMeta_one(object: $giftOptionMeta) {
    id
  }
}
    `;
export type GiftOptionGetGiftOptionMetaQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type GiftOptionGetGiftOptionMetaQuery = (
  { __typename?: 'query_root' }
  & { giftOptionMeta: Array<(
    { __typename?: 'giftOptionMeta' }
    & Pick<Types.GiftOptionMeta, 'id'>
    & { option: (
      { __typename?: 'option' }
      & Pick<Types.Option, 'id' | 'optionId'>
    ) }
  )> }
);

export type GiftOptionInsertGiftOptionMutationVariables = Types.Exact<{
  option: Types.OptionInsertInput;
}>;


export type GiftOptionInsertGiftOptionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_option_one?: Types.Maybe<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'id' | 'optionId'>
  )> }
);

export type GiftOptionInsertGiftOptionMetaMutationVariables = Types.Exact<{
  giftOptionMeta: Types.GiftOptionMetaInsertInput;
}>;


export type GiftOptionInsertGiftOptionMetaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_giftOptionMeta_one?: Types.Maybe<(
    { __typename?: 'giftOptionMeta' }
    & Pick<Types.GiftOptionMeta, 'id'>
  )> }
);


export const GiftOptionGetGiftOptionMetaDocument = gql`
    query GiftOptionGetGiftOptionMeta($shopId: uuid!) {
  giftOptionMeta(where: {shopId: {_eq: $shopId}}, limit: 1) {
    id
    option {
      id
      optionId
    }
  }
}
    `;

/**
 * __useGiftOptionGetGiftOptionMetaQuery__
 *
 * To run a query within a React component, call `useGiftOptionGetGiftOptionMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftOptionGetGiftOptionMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftOptionGetGiftOptionMetaQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useGiftOptionGetGiftOptionMetaQuery(baseOptions: Apollo.QueryHookOptions<GiftOptionGetGiftOptionMetaQuery, GiftOptionGetGiftOptionMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftOptionGetGiftOptionMetaQuery, GiftOptionGetGiftOptionMetaQueryVariables>(GiftOptionGetGiftOptionMetaDocument, options);
      }
export function useGiftOptionGetGiftOptionMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftOptionGetGiftOptionMetaQuery, GiftOptionGetGiftOptionMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftOptionGetGiftOptionMetaQuery, GiftOptionGetGiftOptionMetaQueryVariables>(GiftOptionGetGiftOptionMetaDocument, options);
        }
export type GiftOptionGetGiftOptionMetaQueryHookResult = ReturnType<typeof useGiftOptionGetGiftOptionMetaQuery>;
export type GiftOptionGetGiftOptionMetaLazyQueryHookResult = ReturnType<typeof useGiftOptionGetGiftOptionMetaLazyQuery>;
export type GiftOptionGetGiftOptionMetaQueryResult = Apollo.QueryResult<GiftOptionGetGiftOptionMetaQuery, GiftOptionGetGiftOptionMetaQueryVariables>;
export const GiftOptionInsertGiftOptionDocument = gql`
    mutation GiftOptionInsertGiftOption($option: option_insert_input!) {
  insert_option_one(object: $option) {
    id
    optionId
  }
}
    `;
export type GiftOptionInsertGiftOptionMutationFn = Apollo.MutationFunction<GiftOptionInsertGiftOptionMutation, GiftOptionInsertGiftOptionMutationVariables>;

/**
 * __useGiftOptionInsertGiftOptionMutation__
 *
 * To run a mutation, you first call `useGiftOptionInsertGiftOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftOptionInsertGiftOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftOptionInsertGiftOptionMutation, { data, loading, error }] = useGiftOptionInsertGiftOptionMutation({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useGiftOptionInsertGiftOptionMutation(baseOptions?: Apollo.MutationHookOptions<GiftOptionInsertGiftOptionMutation, GiftOptionInsertGiftOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftOptionInsertGiftOptionMutation, GiftOptionInsertGiftOptionMutationVariables>(GiftOptionInsertGiftOptionDocument, options);
      }
export type GiftOptionInsertGiftOptionMutationHookResult = ReturnType<typeof useGiftOptionInsertGiftOptionMutation>;
export type GiftOptionInsertGiftOptionMutationResult = Apollo.MutationResult<GiftOptionInsertGiftOptionMutation>;
export type GiftOptionInsertGiftOptionMutationOptions = Apollo.BaseMutationOptions<GiftOptionInsertGiftOptionMutation, GiftOptionInsertGiftOptionMutationVariables>;
export const GiftOptionInsertGiftOptionMetaDocument = gql`
    mutation GiftOptionInsertGiftOptionMeta($giftOptionMeta: giftOptionMeta_insert_input!) {
  insert_giftOptionMeta_one(object: $giftOptionMeta) {
    id
  }
}
    `;
export type GiftOptionInsertGiftOptionMetaMutationFn = Apollo.MutationFunction<GiftOptionInsertGiftOptionMetaMutation, GiftOptionInsertGiftOptionMetaMutationVariables>;

/**
 * __useGiftOptionInsertGiftOptionMetaMutation__
 *
 * To run a mutation, you first call `useGiftOptionInsertGiftOptionMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftOptionInsertGiftOptionMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftOptionInsertGiftOptionMetaMutation, { data, loading, error }] = useGiftOptionInsertGiftOptionMetaMutation({
 *   variables: {
 *      giftOptionMeta: // value for 'giftOptionMeta'
 *   },
 * });
 */
export function useGiftOptionInsertGiftOptionMetaMutation(baseOptions?: Apollo.MutationHookOptions<GiftOptionInsertGiftOptionMetaMutation, GiftOptionInsertGiftOptionMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftOptionInsertGiftOptionMetaMutation, GiftOptionInsertGiftOptionMetaMutationVariables>(GiftOptionInsertGiftOptionMetaDocument, options);
      }
export type GiftOptionInsertGiftOptionMetaMutationHookResult = ReturnType<typeof useGiftOptionInsertGiftOptionMetaMutation>;
export type GiftOptionInsertGiftOptionMetaMutationResult = Apollo.MutationResult<GiftOptionInsertGiftOptionMetaMutation>;
export type GiftOptionInsertGiftOptionMetaMutationOptions = Apollo.BaseMutationOptions<GiftOptionInsertGiftOptionMetaMutation, GiftOptionInsertGiftOptionMetaMutationVariables>;