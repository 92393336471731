import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { CreateServiceAdminInput, ServiceAdmin } from "types/graphql";

export type AddServiceAdminFormValues = Pick<ServiceAdmin, "email"> & {
  password: string;
};

const getInitialValues = () => ({});

export const AddServiceAdminFormItem = createFormItem<AddServiceAdminFormValues>();

export const useAddServiceAdminForm = (
  onSubmit: (serviceAdmin: CreateServiceAdminInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();
  const values = form.getFieldsValue() as AddServiceAdminFormValues;

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AddServiceAdminFormValues;
    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, values, submit };
};
