import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditOnSitePaymentDiscountTypePriorityList } from "pages/EditOnSitePaymentDiscountTypePriorities/EditOnSitePaymentDiscountTypeList";
import {
  useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery,
  useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation,
} from "pages/EditOnSitePaymentDiscountTypePriorities/queries";
import { OnSitePaymentDiscountTypeInsertInput } from "types/graphql";

export const EditOnSitePaymentDiscountTypePriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getOnSitePaymentDiscountTypesData,
    loading: loadingOnSitePaymentDiscountTypes,
    refetch: refetchOnSitePaymentDiscountTypes,
    error,
  } = useEditOnSitePaymentDiscountTypePrioritiesGetOnSitePaymentDiscountTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDiscountTypes =
    getOnSitePaymentDiscountTypesData?.onSitePaymentDiscountType ?? [];

  const [
    insertOnSitePaymentDiscountTypesMutation,
    { loading: loadingInsertOnSitePaymentDiscountTypes },
  ] = useEditOnSitePaymentDiscountTypePrioritiesInsertOnSitePaymentDiscountTypesMutation();

  const onUpdate = useCallback(
    async (onSitePaymentDiscountTypes: OnSitePaymentDiscountTypeInsertInput[]) => {
      try {
        await insertOnSitePaymentDiscountTypesMutation({
          variables: { onSitePaymentDiscountTypes },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchOnSitePaymentDiscountTypes();
    },
    [insertOnSitePaymentDiscountTypesMutation, refetchOnSitePaymentDiscountTypes],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout title="割引方法の表示順を編集">
      <PageHeader title="割引方法の表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditOnSitePaymentDiscountTypePriorityList
          loading={loadingOnSitePaymentDiscountTypes || loadingInsertOnSitePaymentDiscountTypes}
          onSitePaymentDiscountTypes={onSitePaymentDiscountTypes}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
