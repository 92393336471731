import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import { withFormDependencies } from "components/antd/Form";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { CouponTypeEnum } from "types/graphql";

import { Menu } from "../../types";
import { AvailableDaysField } from "../AvailableDaysField";
import { AvailableFromField } from "../AvailableFromField";
import { AvailableUntilField } from "../AvailableUntilField";
import { ContentField } from "../ContentField";
import { DescriptionField } from "../DescriptionField";
import { NameField } from "../NameField";
import { TermsOfUseField } from "../TermsOfUseField";
import { TitleField } from "../TitleField";
import { AddCouponFormItem } from "../useAddCouponForm";

import { AutoFillEffect } from "./AutoFillEffect";
import { DiscountedPriceField } from "./DiscountedPriceField";
import { MenusField } from "./MenusField";
import { NormalPriceField } from "./NormalPriceField";
import { TaxMethodField } from "./TaxMethodField";
import { TaxRateField } from "./TaxRateField";

const FlexField = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type Props = {
  menus: Menu[];
};

export const MenuDiscountTypeCouponForm = memo<Props>(({ menus }) => (
  <>
    <FormSection title="2. クーポンの概要を登録しましょう">
      <NameField />
      <TitleField />
      <ContentField />
      <DescriptionField />
      <TermsOfUseField />
    </FormSection>
    <Spacer size={32} />
    <FormSection title="3. 適用するメニューを決めましょう">
      <AddCouponFormItem.NonProperty
        shouldUpdate={withFormDependencies(({ [CouponTypeEnum.MenuDiscount]: menuDiscount }) => [
          menuDiscount?.menuId,
        ])}
        noStyle
      >
        {(form) => {
          const { getFieldValue, setFieldValue } = form;
          const menuId = getFieldValue([CouponTypeEnum.MenuDiscount, "menuId"]);
          return <AutoFillEffect menuId={menuId} menus={menus} setFieldValue={setFieldValue} />;
        }}
      </AddCouponFormItem.NonProperty>
      <MenusField menus={menus} />
      <Row gutter={[24, 0]} align="top">
        <Col span={5}>
          <TaxMethodField />
        </Col>
        <Col span={5}>
          <TaxRateField />
        </Col>
        <Col span={5}>
          <NormalPriceField />
        </Col>
        <Col span={9}>
          <DiscountedPriceField />
        </Col>
      </Row>
    </FormSection>
    <Spacer size={32} />
    <FormSection title="4. 有効期間を決めましょう">
      <AvailableFromField />
      <FlexField>
        <AvailableUntilField />
      </FlexField>
      <FlexField>
        <AvailableDaysField />
      </FlexField>
    </FormSection>
  </>
));
