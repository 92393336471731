import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { Maybe, PikaichiConfigInsertInput } from "types/graphql";

import { PikaichiConfig } from "./types";

export type PikaichiConfigFormValues = Pick<
  Partial<PikaichiConfig>,
  | "enabled"
  | "pikaichiCompanyCd"
  | "pikaichiShopCd"
  | "pikaichiPosNumber"
  | "ftpHost"
  | "ftpPort"
  | "ftpUser"
  | "ftpPassword"
  | "useNewari"
  | "useKansa"
>;

export const PikaichiConfigFormItem = createFormItem<PikaichiConfigFormValues>();

export const usePikaichiConfigForm = ({
  pikaichiConfig,
  onSubmit,
  onFormValidationError,
}: {
  pikaichiConfig?: Maybe<PikaichiConfig>;
  onSubmit: (args: Omit<PikaichiConfigInsertInput, "shopId">) => Promise<void>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<PikaichiConfigFormValues>(
    () => ({
      enabled: pikaichiConfig?.enabled ?? false,
      pikaichiCompanyCd: pikaichiConfig?.pikaichiCompanyCd,
      pikaichiShopCd: pikaichiConfig?.pikaichiShopCd,
      pikaichiPosNumber: pikaichiConfig?.pikaichiPosNumber,
      ftpHost: pikaichiConfig?.ftpHost,
      ftpPort: pikaichiConfig?.ftpPort,
      ftpUser: pikaichiConfig?.ftpUser,
      ftpPassword: pikaichiConfig?.ftpPassword,
      useNewari: pikaichiConfig?.useNewari ?? false,
      useKansa: pikaichiConfig?.useKansa ?? false,
    }),
    [pikaichiConfig],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as PikaichiConfigFormValues;

      await onSubmit({
        enabled: values.enabled ?? false,
        pikaichiCompanyCd: values.pikaichiCompanyCd ?? "",
        pikaichiShopCd: values.pikaichiShopCd ?? "",
        pikaichiPosNumber: values.pikaichiPosNumber ?? "",
        ftpHost: values.ftpHost,
        ftpPort: values.ftpPort,
        ftpUser: values.ftpUser,
        ftpPassword: values.ftpPassword,
        useNewari: values.useNewari,
        useKansa: values.useKansa,
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, submit };
};
