import { useCallback } from "react";
import { Store } from "antd/lib/form/interface";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";

export type GenerateQrCodesFormValues = {
  shopId: string;
  zipPath: string;
  fileNamePrefix: string;
};

const getInitialValues = () => ({});

export const GenerateQrCodesFormItem = createFormItem<GenerateQrCodesFormValues>();

export const useGenerateQrCodesForm = (
  onChange: (formValues: GenerateQrCodesFormValues) => void,
  onSubmit: (formValues: GenerateQrCodesFormValues) => void,
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as GenerateQrCodesFormValues;
      onChange(values);
    },
    [onChange],
  );

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue() as GenerateQrCodesFormValues;
      onSubmit(formValues);
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, change, submit };
};
