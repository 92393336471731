import React, { memo, useCallback } from "react";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { IsDealingField } from "../EditShopMenusBulkFields/IsDealingField";
import { IsSoldOutField } from "../EditShopMenusBulkFields/IsSoldOutField";
import { IsVisibleForCustomerField } from "../EditShopMenusBulkFields/IsVisibleForCustomerField";
import { IsVisibleForStaffField } from "../EditShopMenusBulkFields/IsVisibleForStaffField";
import { Category } from "../types";

export type FilterConditions = {
  name: string | null;
  categoryIds: string[] | null;
  isDealing: boolean | null;
  isVisibleForCustomer: boolean | null;
  isVisibleForStaff: boolean | null;
  isSoldOut: boolean | null;
};

type Props = {
  categories: Category[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const MenuFilter = memo<Props>(
  ({
    categories,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => {
    const handleUpdateFilterCondition = useCallback<
      UpdateFilterConditionFunctionType<FilterConditions>
    >((values) => updateFilterCondition(values), [updateFilterCondition]);
    return (
      <Row gutter={[16, 16]} align="middle">
        <Col span={12}>
          <Input
            placeholder="メニュー名"
            value={filterConditions.name ?? undefined}
            onChange={(e) => handleUpdateFilterCondition({ name: e.target.value })}
          />
        </Col>
        <Col span={12}>
          <Select<string[]>
            mode="multiple"
            placeholder="カテゴリ名"
            value={filterConditions.categoryIds ?? []}
            onChange={(categoryIds) => handleUpdateFilterCondition({ categoryIds })}
            optionFilterProp="label"
            options={categories.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </Col>
        <Col flex="1 0">
          <IsDealingField
            value={filterConditions.isDealing}
            onChange={(isDealing) => handleUpdateFilterCondition({ isDealing })}
          />
        </Col>
        <Col flex="1 0">
          <IsVisibleForCustomerField
            value={filterConditions.isVisibleForCustomer}
            onChange={(isVisibleForCustomer) =>
              handleUpdateFilterCondition({ isVisibleForCustomer })
            }
          />
        </Col>
        <Col flex="1 0">
          <IsVisibleForStaffField
            value={filterConditions.isVisibleForStaff}
            onChange={(isVisibleForStaff) => handleUpdateFilterCondition({ isVisibleForStaff })}
          />
        </Col>
        <Col flex="1 0">
          <IsSoldOutField
            value={filterConditions.isSoldOut}
            onChange={(isSoldOut) => handleUpdateFilterCondition({ isSoldOut })}
          />
        </Col>
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Row>
    );
  },
);
