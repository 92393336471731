import React, { memo } from "react";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditGoogleMapConfigFormItem } from "../useEditGoogleMapConfigForm";

type Props = { disabled?: boolean } & Omit<FormItemProps, "children" | "name">;

export const GoogleMapPlaceIdField = memo<Props>(({ disabled, ...props }) => (
  <EditGoogleMapConfigFormItem.NonProperty
    noStyle
    shouldUpdate={withFormDependencies(({ enableGoogleMapReview }) => [enableGoogleMapReview])}
  >
    {({ getFieldValue }) => (
      <EditGoogleMapConfigFormItem
        label="プレイス ID"
        name="googleMapPlaceId"
        help="プレイス IDを登録することで、モバイルオーダーのアンケート内容を半自動的に反映することができるようになります。"
        wrapperCol={{ span: 20 }}
        rules={
          getFieldValue("enableGoogleMapReview")
            ? [
                {
                  required: true,
                  message: "プレイス IDを入力してください",
                },
              ]
            : []
        }
        {...props}
      >
        <InputCode disabled={!getFieldValue("enableGoogleMapReview")} />
      </EditGoogleMapConfigFormItem>
    )}
  </EditGoogleMapConfigFormItem.NonProperty>
));
