import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuWinboardMenuFormItem } from "../useEditMenuWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const WinboardBumonNameField = memo<Props>((props) => (
  <EditMenuWinboardMenuFormItem label="部門名" name="bumonName" {...props}>
    <Input />
  </EditMenuWinboardMenuFormItem>
));
