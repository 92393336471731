import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { EbicaConfigInsertInput } from "types/graphql";

import { EditEbicaConfigForm } from "./EditEbicaConfigForm";
import {
  useEditEbicaConfigGetEbicaConfigQuery,
  useEditEbicaConfigUpsertEbicaConfigMutation,
} from "./queries";

export const EditEbicaConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data,
    loading: loadingGetEbicaConfig,
    error,
  } = useEditEbicaConfigGetEbicaConfigQuery(
    shopId
      ? {
          variables: {
            shopId,
          },
        }
      : {
          skip: true,
        },
  );

  const ebicaConfig = useMemo(() => data?.ebicaConfig[0] ?? {}, [data]);

  const [upsertEbicaConfigMutation, { loading: loadingUpdateEbicaConfig }] =
    useEditEbicaConfigUpsertEbicaConfigMutation();

  const onSubmit = useCallback(
    async ({ ebicaConfig }: { ebicaConfig: EbicaConfigInsertInput }) => {
      try {
        await upsertEbicaConfigMutation({ variables: { input: ebicaConfig } });
        message.success("保存しました");
      } catch (e) {
        console.warn(e);

        message.error("保存に失敗しました");
      }
    },
    [upsertEbicaConfigMutation],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("保存に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout title="連携処理">
      <PageHeader title="連携処理" footer={<ShopSelector />} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {!loadingGetEbicaConfig && ebicaConfig && (
        <EditEbicaConfigForm
          onSubmit={onSubmit}
          ebicaConfig={ebicaConfig}
          loading={loadingUpdateEbicaConfig}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
