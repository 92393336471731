import React, { memo } from "react";
import styled from "styled-components";
import { Button, Checkbox, Col, DatePicker, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CsvNameByShops, csvNamesByShops } from "models/csvName";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";

import {
  csvByShopsDescriptionMap,
  csvByShopsFileNamesMap,
  csvNameByShopsMap,
} from "../csvNamesMap";

import {
  AggregatedByShopsDataFormItem,
  useAggregatedByShopsDataForm,
} from "./useAggregatedByShopsDataForm";

type Props = {
  isDownloading: boolean;
  disabled: boolean;
  onSubmit: ({
    csvNamesByShops,
  }: {
    csvNamesByShops: CsvNameByShops[];
    targetDate?: dayjs.Dayjs;
  }) => Promise<void>;
};

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CsvDescription = styled.div`
  color: gray;
  margin-left: 24px;
  margin-bottom: 8px;
`;

export const AggregatedByShopsDataForm = memo<Props>(({ onSubmit, isDownloading, disabled }) => {
  const { form, submit, initialValues } = useAggregatedByShopsDataForm({ onSubmit });

  return (
    <>
      <Form
        name="aggregatedDataByShopsForm"
        form={form}
        layout="vertical"
        initialValues={initialValues}
      >
        <AggregatedByShopsDataFormItem
          label="日付選択"
          name="targetDate"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </AggregatedByShopsDataFormItem>

        <AggregatedByShopsDataFormItem
          label="出力ファイル選択"
          name="csvNamesByShops"
          rules={[{ required: true }]}
        >
          <Checkbox.Group>
            {csvNamesByShops.map((csvName, index) => (
              <Row key={index}>
                <Col>
                  <Checkbox value={csvName}>
                    {csvNameByShopsMap[csvName]}({csvByShopsFileNamesMap[csvName]})
                  </Checkbox>
                  <CsvDescription>{csvByShopsDescriptionMap[csvName]}</CsvDescription>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        </AggregatedByShopsDataFormItem>
      </Form>

      <DescriptionContainer>
        <p>集計完了まで数分かかる場合があります</p>
      </DescriptionContainer>

      <FormActions>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          disabled={disabled}
          loading={isDownloading}
          onClick={submit}
        >
          ダウンロード
        </Button>
      </FormActions>
    </>
  );
});
