import React from "react";
import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { Select } from "components/Input/Select";
import { QuestionnaireConfig } from "pages/EditMessageDelivery/types";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = {
  name: number;
  questionnaireConfigId: string;
  questionnaireConfigs: QuestionnaireConfig[];
};

export const QuestionnaireConfigField = React.memo<Props>(
  ({ name, questionnaireConfigId, questionnaireConfigs }) => (
    <MessageDeliveryJobFormItem name={[name, "questionnaireConfigId"]} endSpacer={null}>
      <Select
        placeholder="アンケートを選択してください"
        defaultValue={questionnaireConfigId}
        options={questionnaireConfigs.map(({ id, questionnaire }) => ({
          label: questionnaire.name,
          value: id,
        }))}
        showSearch
        allowClear
        fullWidth
        notFoundContent={
          <Paragraph type="secondary">
            選択できるアンケートが存在しません。
            <Typography.Link href="/questionnaireConfig/add" target="_blank">
              アンケート作成画面
            </Typography.Link>
            にてアンケートを作成してください。
          </Paragraph>
        }
      />
    </MessageDeliveryJobFormItem>
  ),
);
