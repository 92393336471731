import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalMastersGetMasters = gql`
    query FoodingJournalMastersGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(
    where: {companyId: {_eq: $companyId}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    foodingJournalMenus(limit: 1) {
      id
    }
  }
  foodingJournalGroupMaster(
    where: {companyId: {_eq: $companyId}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    foodingJournalMenus(limit: 1) {
      id
    }
  }
}
    `;
export const FoodingJournalMastersUpsertDepartmentMaster = gql`
    mutation FoodingJournalMastersUpsertDepartmentMaster($input: [foodingJournalDepartmentMaster_insert_input!]!) {
  insert_foodingJournalDepartmentMaster(
    objects: $input
    on_conflict: {constraint: foodingJournalDepartmentMaster_pkey, update_columns: [code, name]}
  ) {
    affected_rows
  }
}
    `;
export const FoodingJournalMastersUpsertGroupMaster = gql`
    mutation FoodingJournalMastersUpsertGroupMaster($input: [foodingJournalGroupMaster_insert_input!]!) {
  insert_foodingJournalGroupMaster(
    objects: $input
    on_conflict: {constraint: foodingJournalGroupMaster_pkey, update_columns: [code, name]}
  ) {
    affected_rows
  }
}
    `;
export const FoodingJournalMastersDeleteDepartmentMaster = gql`
    mutation FoodingJournalMastersDeleteDepartmentMaster($foodingJournalDepartmentId: uuid!) {
  delete_foodingJournalDepartmentMaster(
    where: {id: {_eq: $foodingJournalDepartmentId}}
  ) {
    affected_rows
  }
}
    `;
export const FoodingJournalMastersDeleteGroupMaster = gql`
    mutation FoodingJournalMastersDeleteGroupMaster($foodingJournalGroupId: uuid!) {
  delete_foodingJournalGroupMaster(where: {id: {_eq: $foodingJournalGroupId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalMastersGetMastersQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMastersGetMastersQuery = (
  { __typename?: 'query_root' }
  & { foodingJournalDepartmentMaster: Array<(
    { __typename?: 'foodingJournalDepartmentMaster' }
    & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
    & { foodingJournalMenus: Array<(
      { __typename?: 'foodingJournalMenu' }
      & Pick<Types.FoodingJournalMenu, 'id'>
    )> }
  )>, foodingJournalGroupMaster: Array<(
    { __typename?: 'foodingJournalGroupMaster' }
    & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
    & { foodingJournalMenus: Array<(
      { __typename?: 'foodingJournalMenu' }
      & Pick<Types.FoodingJournalMenu, 'id'>
    )> }
  )> }
);

export type FoodingJournalMastersUpsertDepartmentMasterMutationVariables = Types.Exact<{
  input: Array<Types.FoodingJournalDepartmentMasterInsertInput> | Types.FoodingJournalDepartmentMasterInsertInput;
}>;


export type FoodingJournalMastersUpsertDepartmentMasterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalDepartmentMaster?: Types.Maybe<(
    { __typename?: 'foodingJournalDepartmentMaster_mutation_response' }
    & Pick<Types.FoodingJournalDepartmentMasterMutationResponse, 'affected_rows'>
  )> }
);

export type FoodingJournalMastersUpsertGroupMasterMutationVariables = Types.Exact<{
  input: Array<Types.FoodingJournalGroupMasterInsertInput> | Types.FoodingJournalGroupMasterInsertInput;
}>;


export type FoodingJournalMastersUpsertGroupMasterMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalGroupMaster?: Types.Maybe<(
    { __typename?: 'foodingJournalGroupMaster_mutation_response' }
    & Pick<Types.FoodingJournalGroupMasterMutationResponse, 'affected_rows'>
  )> }
);

export type FoodingJournalMastersDeleteDepartmentMasterMutationVariables = Types.Exact<{
  foodingJournalDepartmentId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMastersDeleteDepartmentMasterMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalDepartmentMaster?: Types.Maybe<(
    { __typename?: 'foodingJournalDepartmentMaster_mutation_response' }
    & Pick<Types.FoodingJournalDepartmentMasterMutationResponse, 'affected_rows'>
  )> }
);

export type FoodingJournalMastersDeleteGroupMasterMutationVariables = Types.Exact<{
  foodingJournalGroupId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMastersDeleteGroupMasterMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalGroupMaster?: Types.Maybe<(
    { __typename?: 'foodingJournalGroupMaster_mutation_response' }
    & Pick<Types.FoodingJournalGroupMasterMutationResponse, 'affected_rows'>
  )> }
);


export const FoodingJournalMastersGetMastersDocument = gql`
    query FoodingJournalMastersGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(
    where: {companyId: {_eq: $companyId}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    foodingJournalMenus(limit: 1) {
      id
    }
  }
  foodingJournalGroupMaster(
    where: {companyId: {_eq: $companyId}}
    order_by: {code: asc}
  ) {
    id
    code
    name
    foodingJournalMenus(limit: 1) {
      id
    }
  }
}
    `;

/**
 * __useFoodingJournalMastersGetMastersQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMastersGetMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMastersGetMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMastersGetMastersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMastersGetMastersQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMastersGetMastersQuery, FoodingJournalMastersGetMastersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMastersGetMastersQuery, FoodingJournalMastersGetMastersQueryVariables>(FoodingJournalMastersGetMastersDocument, options);
      }
export function useFoodingJournalMastersGetMastersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMastersGetMastersQuery, FoodingJournalMastersGetMastersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMastersGetMastersQuery, FoodingJournalMastersGetMastersQueryVariables>(FoodingJournalMastersGetMastersDocument, options);
        }
export type FoodingJournalMastersGetMastersQueryHookResult = ReturnType<typeof useFoodingJournalMastersGetMastersQuery>;
export type FoodingJournalMastersGetMastersLazyQueryHookResult = ReturnType<typeof useFoodingJournalMastersGetMastersLazyQuery>;
export type FoodingJournalMastersGetMastersQueryResult = Apollo.QueryResult<FoodingJournalMastersGetMastersQuery, FoodingJournalMastersGetMastersQueryVariables>;
export const FoodingJournalMastersUpsertDepartmentMasterDocument = gql`
    mutation FoodingJournalMastersUpsertDepartmentMaster($input: [foodingJournalDepartmentMaster_insert_input!]!) {
  insert_foodingJournalDepartmentMaster(
    objects: $input
    on_conflict: {constraint: foodingJournalDepartmentMaster_pkey, update_columns: [code, name]}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalMastersUpsertDepartmentMasterMutationFn = Apollo.MutationFunction<FoodingJournalMastersUpsertDepartmentMasterMutation, FoodingJournalMastersUpsertDepartmentMasterMutationVariables>;

/**
 * __useFoodingJournalMastersUpsertDepartmentMasterMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMastersUpsertDepartmentMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMastersUpsertDepartmentMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMastersUpsertDepartmentMasterMutation, { data, loading, error }] = useFoodingJournalMastersUpsertDepartmentMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodingJournalMastersUpsertDepartmentMasterMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMastersUpsertDepartmentMasterMutation, FoodingJournalMastersUpsertDepartmentMasterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMastersUpsertDepartmentMasterMutation, FoodingJournalMastersUpsertDepartmentMasterMutationVariables>(FoodingJournalMastersUpsertDepartmentMasterDocument, options);
      }
export type FoodingJournalMastersUpsertDepartmentMasterMutationHookResult = ReturnType<typeof useFoodingJournalMastersUpsertDepartmentMasterMutation>;
export type FoodingJournalMastersUpsertDepartmentMasterMutationResult = Apollo.MutationResult<FoodingJournalMastersUpsertDepartmentMasterMutation>;
export type FoodingJournalMastersUpsertDepartmentMasterMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMastersUpsertDepartmentMasterMutation, FoodingJournalMastersUpsertDepartmentMasterMutationVariables>;
export const FoodingJournalMastersUpsertGroupMasterDocument = gql`
    mutation FoodingJournalMastersUpsertGroupMaster($input: [foodingJournalGroupMaster_insert_input!]!) {
  insert_foodingJournalGroupMaster(
    objects: $input
    on_conflict: {constraint: foodingJournalGroupMaster_pkey, update_columns: [code, name]}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalMastersUpsertGroupMasterMutationFn = Apollo.MutationFunction<FoodingJournalMastersUpsertGroupMasterMutation, FoodingJournalMastersUpsertGroupMasterMutationVariables>;

/**
 * __useFoodingJournalMastersUpsertGroupMasterMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMastersUpsertGroupMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMastersUpsertGroupMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMastersUpsertGroupMasterMutation, { data, loading, error }] = useFoodingJournalMastersUpsertGroupMasterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodingJournalMastersUpsertGroupMasterMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMastersUpsertGroupMasterMutation, FoodingJournalMastersUpsertGroupMasterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMastersUpsertGroupMasterMutation, FoodingJournalMastersUpsertGroupMasterMutationVariables>(FoodingJournalMastersUpsertGroupMasterDocument, options);
      }
export type FoodingJournalMastersUpsertGroupMasterMutationHookResult = ReturnType<typeof useFoodingJournalMastersUpsertGroupMasterMutation>;
export type FoodingJournalMastersUpsertGroupMasterMutationResult = Apollo.MutationResult<FoodingJournalMastersUpsertGroupMasterMutation>;
export type FoodingJournalMastersUpsertGroupMasterMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMastersUpsertGroupMasterMutation, FoodingJournalMastersUpsertGroupMasterMutationVariables>;
export const FoodingJournalMastersDeleteDepartmentMasterDocument = gql`
    mutation FoodingJournalMastersDeleteDepartmentMaster($foodingJournalDepartmentId: uuid!) {
  delete_foodingJournalDepartmentMaster(
    where: {id: {_eq: $foodingJournalDepartmentId}}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalMastersDeleteDepartmentMasterMutationFn = Apollo.MutationFunction<FoodingJournalMastersDeleteDepartmentMasterMutation, FoodingJournalMastersDeleteDepartmentMasterMutationVariables>;

/**
 * __useFoodingJournalMastersDeleteDepartmentMasterMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMastersDeleteDepartmentMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMastersDeleteDepartmentMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMastersDeleteDepartmentMasterMutation, { data, loading, error }] = useFoodingJournalMastersDeleteDepartmentMasterMutation({
 *   variables: {
 *      foodingJournalDepartmentId: // value for 'foodingJournalDepartmentId'
 *   },
 * });
 */
export function useFoodingJournalMastersDeleteDepartmentMasterMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMastersDeleteDepartmentMasterMutation, FoodingJournalMastersDeleteDepartmentMasterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMastersDeleteDepartmentMasterMutation, FoodingJournalMastersDeleteDepartmentMasterMutationVariables>(FoodingJournalMastersDeleteDepartmentMasterDocument, options);
      }
export type FoodingJournalMastersDeleteDepartmentMasterMutationHookResult = ReturnType<typeof useFoodingJournalMastersDeleteDepartmentMasterMutation>;
export type FoodingJournalMastersDeleteDepartmentMasterMutationResult = Apollo.MutationResult<FoodingJournalMastersDeleteDepartmentMasterMutation>;
export type FoodingJournalMastersDeleteDepartmentMasterMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMastersDeleteDepartmentMasterMutation, FoodingJournalMastersDeleteDepartmentMasterMutationVariables>;
export const FoodingJournalMastersDeleteGroupMasterDocument = gql`
    mutation FoodingJournalMastersDeleteGroupMaster($foodingJournalGroupId: uuid!) {
  delete_foodingJournalGroupMaster(where: {id: {_eq: $foodingJournalGroupId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalMastersDeleteGroupMasterMutationFn = Apollo.MutationFunction<FoodingJournalMastersDeleteGroupMasterMutation, FoodingJournalMastersDeleteGroupMasterMutationVariables>;

/**
 * __useFoodingJournalMastersDeleteGroupMasterMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMastersDeleteGroupMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMastersDeleteGroupMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMastersDeleteGroupMasterMutation, { data, loading, error }] = useFoodingJournalMastersDeleteGroupMasterMutation({
 *   variables: {
 *      foodingJournalGroupId: // value for 'foodingJournalGroupId'
 *   },
 * });
 */
export function useFoodingJournalMastersDeleteGroupMasterMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMastersDeleteGroupMasterMutation, FoodingJournalMastersDeleteGroupMasterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMastersDeleteGroupMasterMutation, FoodingJournalMastersDeleteGroupMasterMutationVariables>(FoodingJournalMastersDeleteGroupMasterDocument, options);
      }
export type FoodingJournalMastersDeleteGroupMasterMutationHookResult = ReturnType<typeof useFoodingJournalMastersDeleteGroupMasterMutation>;
export type FoodingJournalMastersDeleteGroupMasterMutationResult = Apollo.MutationResult<FoodingJournalMastersDeleteGroupMasterMutation>;
export type FoodingJournalMastersDeleteGroupMasterMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMastersDeleteGroupMasterMutation, FoodingJournalMastersDeleteGroupMasterMutationVariables>;