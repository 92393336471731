import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";

const StyledRow = styled(Row)`
  padding: 0 10px;
`;

export const SelectAutoOrderTimeForm = memo(() => (
  <StyledRow>
    <Col span={12}>
      <FormItem label="開始時間" name="autoOrderStartTime" rules={[{ required: true }]} required>
        <TimePicker formItemName="autoOrderStartTime" />
      </FormItem>
    </Col>
    <Col span={12}>
      <FormItem label="終了時間" name="autoOrderEndTime" rules={[{ required: true }]} required>
        <TimePicker formItemName="autoOrderEndTime" />
      </FormItem>
    </Col>
  </StyledRow>
));
