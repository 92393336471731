import "components/antd/styles";

import { createGlobalStyle } from "styled-components";
import { blue } from "@ant-design/colors";

import { grey } from "constants/colors";

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  body {
    margin: 0;
  }
  #root {
    height: 100%;
  }

  tr.drop-over-downward > td {
    /* NOTE: antd の td が border-bottom を持っているため !important を使用している */
    border-bottom: 2px dashed ${blue[5]} !important;
  }
  tr.drop-over-upward > td {
    border-top: 2px dashed ${blue[5]};
  }
  tr.drop-over-entirely > td {
    /* NOTE: antd の特定のセルが background-color: #fff を持っているため !important を使用している */
    background-color: ${blue[1]} !important;
  }

  /* NOTE: ナビゲーションのポップアップの背景が透明になるのを防ぐため。Menu コンポーネントの items props を使うようにすれば直ると思われる。 */
  .ant-menu-submenu-popup {
    background: ${grey[0]};
  }
`;
