import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditQuestionnaireConfigGetQuestionnaireConfigs = gql`
    query EditQuestionnaireConfigGetQuestionnaireConfigs($id: uuid!) {
  questionnaireConfig_by_pk(id: $id) {
    id
    imageUrl
    questionnaireId
    questionnaire {
      id
      name
    }
    rewardCouponId
    coupon {
      id
      title
    }
  }
}
    `;
export const EditQuestionnaireConfigGetCoupons = gql`
    query EditQuestionnaireConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditQuestionnaireConfigUpdateQuestionnaireConfig = gql`
    mutation EditQuestionnaireConfigUpdateQuestionnaireConfig($id: uuid!, $companyId: uuid!, $questionnaireConfig: questionnaireConfig_set_input!) {
  update_questionnaireConfig(
    _set: $questionnaireConfig
    where: {id: {_eq: $id}, companyId: {_eq: $companyId}}
  ) {
    affected_rows
  }
}
    `;
export type EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditQuestionnaireConfigGetQuestionnaireConfigsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig_by_pk?: Types.Maybe<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id' | 'imageUrl' | 'questionnaireId' | 'rewardCouponId'>
    & { questionnaire: (
      { __typename?: 'questionnaire' }
      & Pick<Types.Questionnaire, 'id' | 'name'>
    ), coupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'title'>
    )> }
  )> }
);

export type EditQuestionnaireConfigGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditQuestionnaireConfigGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
  questionnaireConfig: Types.QuestionnaireConfigSetInput;
}>;


export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_questionnaireConfig?: Types.Maybe<(
    { __typename?: 'questionnaireConfig_mutation_response' }
    & Pick<Types.QuestionnaireConfigMutationResponse, 'affected_rows'>
  )> }
);


export const EditQuestionnaireConfigGetQuestionnaireConfigsDocument = gql`
    query EditQuestionnaireConfigGetQuestionnaireConfigs($id: uuid!) {
  questionnaireConfig_by_pk(id: $id) {
    id
    imageUrl
    questionnaireId
    questionnaire {
      id
      name
    }
    rewardCouponId
    coupon {
      id
      title
    }
  }
}
    `;

/**
 * __useEditQuestionnaireConfigGetQuestionnaireConfigsQuery__
 *
 * To run a query within a React component, call `useEditQuestionnaireConfigGetQuestionnaireConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireConfigGetQuestionnaireConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditQuestionnaireConfigGetQuestionnaireConfigsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditQuestionnaireConfigGetQuestionnaireConfigsQuery(baseOptions: Apollo.QueryHookOptions<EditQuestionnaireConfigGetQuestionnaireConfigsQuery, EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditQuestionnaireConfigGetQuestionnaireConfigsQuery, EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables>(EditQuestionnaireConfigGetQuestionnaireConfigsDocument, options);
      }
export function useEditQuestionnaireConfigGetQuestionnaireConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditQuestionnaireConfigGetQuestionnaireConfigsQuery, EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditQuestionnaireConfigGetQuestionnaireConfigsQuery, EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables>(EditQuestionnaireConfigGetQuestionnaireConfigsDocument, options);
        }
export type EditQuestionnaireConfigGetQuestionnaireConfigsQueryHookResult = ReturnType<typeof useEditQuestionnaireConfigGetQuestionnaireConfigsQuery>;
export type EditQuestionnaireConfigGetQuestionnaireConfigsLazyQueryHookResult = ReturnType<typeof useEditQuestionnaireConfigGetQuestionnaireConfigsLazyQuery>;
export type EditQuestionnaireConfigGetQuestionnaireConfigsQueryResult = Apollo.QueryResult<EditQuestionnaireConfigGetQuestionnaireConfigsQuery, EditQuestionnaireConfigGetQuestionnaireConfigsQueryVariables>;
export const EditQuestionnaireConfigGetCouponsDocument = gql`
    query EditQuestionnaireConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditQuestionnaireConfigGetCouponsQuery__
 *
 * To run a query within a React component, call `useEditQuestionnaireConfigGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireConfigGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditQuestionnaireConfigGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditQuestionnaireConfigGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<EditQuestionnaireConfigGetCouponsQuery, EditQuestionnaireConfigGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditQuestionnaireConfigGetCouponsQuery, EditQuestionnaireConfigGetCouponsQueryVariables>(EditQuestionnaireConfigGetCouponsDocument, options);
      }
export function useEditQuestionnaireConfigGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditQuestionnaireConfigGetCouponsQuery, EditQuestionnaireConfigGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditQuestionnaireConfigGetCouponsQuery, EditQuestionnaireConfigGetCouponsQueryVariables>(EditQuestionnaireConfigGetCouponsDocument, options);
        }
export type EditQuestionnaireConfigGetCouponsQueryHookResult = ReturnType<typeof useEditQuestionnaireConfigGetCouponsQuery>;
export type EditQuestionnaireConfigGetCouponsLazyQueryHookResult = ReturnType<typeof useEditQuestionnaireConfigGetCouponsLazyQuery>;
export type EditQuestionnaireConfigGetCouponsQueryResult = Apollo.QueryResult<EditQuestionnaireConfigGetCouponsQuery, EditQuestionnaireConfigGetCouponsQueryVariables>;
export const EditQuestionnaireConfigUpdateQuestionnaireConfigDocument = gql`
    mutation EditQuestionnaireConfigUpdateQuestionnaireConfig($id: uuid!, $companyId: uuid!, $questionnaireConfig: questionnaireConfig_set_input!) {
  update_questionnaireConfig(
    _set: $questionnaireConfig
    where: {id: {_eq: $id}, companyId: {_eq: $companyId}}
  ) {
    affected_rows
  }
}
    `;
export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutationFn = Apollo.MutationFunction<EditQuestionnaireConfigUpdateQuestionnaireConfigMutation, EditQuestionnaireConfigUpdateQuestionnaireConfigMutationVariables>;

/**
 * __useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation__
 *
 * To run a mutation, you first call `useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionnaireConfigUpdateQuestionnaireConfigMutation, { data, loading, error }] = useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *      questionnaireConfig: // value for 'questionnaireConfig'
 *   },
 * });
 */
export function useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditQuestionnaireConfigUpdateQuestionnaireConfigMutation, EditQuestionnaireConfigUpdateQuestionnaireConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditQuestionnaireConfigUpdateQuestionnaireConfigMutation, EditQuestionnaireConfigUpdateQuestionnaireConfigMutationVariables>(EditQuestionnaireConfigUpdateQuestionnaireConfigDocument, options);
      }
export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutationHookResult = ReturnType<typeof useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation>;
export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutationResult = Apollo.MutationResult<EditQuestionnaireConfigUpdateQuestionnaireConfigMutation>;
export type EditQuestionnaireConfigUpdateQuestionnaireConfigMutationOptions = Apollo.BaseMutationOptions<EditQuestionnaireConfigUpdateQuestionnaireConfigMutation, EditQuestionnaireConfigUpdateQuestionnaireConfigMutationVariables>;