import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { CookingItemTable } from "pages/CookingItems/CookingItemTable";
import { useCookingItemsGetCookingItemsQuery } from "pages/CookingItems/queries";

export const CookingItems = () => {
  const { canAccess } = useCanAccess();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getCookingItemsData,
    loading: loadingCookingItems,
    error,
  } = useCookingItemsGetCookingItemsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const cookingItems = getCookingItemsData?.shop?.[0]?.cookingItems ?? [];

  return (
    <DashboardLayout title="調理アイテム一覧">
      <PageHeader
        title="調理アイテム一覧"
        extra={[
          canAccess("addCookingItem") && (
            <Link key="add" to="/cookingItem/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CookingItemTable cookingItems={cookingItems} loading={loadingCookingItems} />
    </DashboardLayout>
  );
};
