import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Form, Input, Popconfirm, Popover, Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { PlusOutlined } from "@ant-design/icons";
import { isEqual, omit } from "lodash";
import * as uuid from "uuid";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { InputCode } from "components/Input/InputCode";
import { FoodingJournalMenu } from "types/graphql";

import { ConfirmationModal } from "../ConfirmationModal";
import { FoodingJournalDepartmentMaster, FoodingJournalGroupMaster } from "../types";
import { FoodingJournalMasterType } from "..";

const { Paragraph } = Typography;

export type FoodingJournalMasterSource = {
  id: string;
  code: string;
  name: string;
  foodingJournalMenus?: Pick<FoodingJournalMenu, "id">[];
};

type RowItem = FoodingJournalMasterSource;

type Props = {
  type: FoodingJournalMasterType;
  foodingJournalMasters: FoodingJournalDepartmentMaster[] | FoodingJournalGroupMaster[];
  upsertMasters: (args: {
    masterSources: FoodingJournalMasterSource[];
    type: FoodingJournalMasterType;
  }) => Promise<void>;
  deleteMaster: (args: { id: string; type: FoodingJournalMasterType }) => Promise<void>;
  refetchMasters: ({
    type,
  }: {
    type: FoodingJournalMasterType;
  }) => Promise<{ masters: FoodingJournalDepartmentMaster[] | FoodingJournalGroupMaster[] }>;
  loading: boolean;
};

export const MasterTable = React.memo<Props>(
  ({
    upsertMasters,
    type,
    foodingJournalMasters: masters,
    deleteMaster,
    refetchMasters,
    loading,
  }) => {
    const masterName = type === "department" ? "部門" : "分類";

    const [form] = Form.useForm();

    const [shouldShowConfirmationModal, setShouldConfirmationModal] = useState(false);

    const [editingMasters, setEditingMasters] = useState<
      Record<string, FoodingJournalMasterSource>
    >({});

    const [foodingJournalMasters, setFoodingJournalMasters] = useState<
      FoodingJournalMasterSource[]
    >([]);

    useEffect(() => {
      if (loading) return;

      if (foodingJournalMasters.length === 0 && masters.length > 0) {
        setFoodingJournalMasters(masters);
      }
    }, [foodingJournalMasters, masters, loading]);

    const openModal = useCallback(() => setShouldConfirmationModal(true), []);
    const closeModal = useCallback(() => setShouldConfirmationModal(false), []);

    const handleEmptyMaster = useCallback(() => {
      setFoodingJournalMasters((m) => [...m, { id: uuid.v4(), code: "", name: "" }]);
    }, []);

    const handleSubmit = useCallback(async () => {
      await upsertMasters({
        masterSources: Object.entries(editingMasters).map(([id, master]) => ({ ...master, id })),
        type,
      });

      const { masters } = await refetchMasters({ type });

      setFoodingJournalMasters(masters);

      setEditingMasters({});

      form.resetFields();

      closeModal();
    }, [closeModal, editingMasters, form, refetchMasters, type, upsertMasters]);

    const handleChange = useCallback(
      ({ rowItem }: { rowItem: RowItem }) => {
        const editingValue = (
          form.getFieldsValue() as Record<string, { code: string; name: string }>
        )[rowItem.id];

        if (!editingValue) return;

        const mergedValue = {
          id: rowItem.id,
          code: editingValue.code ?? rowItem.code,
          name: editingValue.name ?? rowItem.name,
        };

        if (isEqual(mergedValue, { id: rowItem.id, code: rowItem.code, name: rowItem.name })) {
          return setEditingMasters((m) => omit(m, rowItem.id));
        }

        setEditingMasters((m) => ({ ...m, [rowItem.id]: mergedValue }));

        form.setFieldValue(rowItem.id, mergedValue);
      },
      [form],
    );

    const handleConfirm = useCallback(async () => {
      try {
        await form.validateFields(
          Object.entries(editingMasters).map(([id]) => id),
          { recursive: true },
        );
      } catch {
        return;
      }

      openModal();
    }, [editingMasters, form, openModal]);

    const handleClear = useCallback(() => {
      setEditingMasters({});

      form.resetFields();
    }, [form]);

    const handleDelete = useCallback(
      async ({ rowItem }: { rowItem: RowItem }) => {
        await deleteMaster({ id: rowItem.id, type });

        const { masters } = await refetchMasters({ type });

        setFoodingJournalMasters(masters);
      },
      [deleteMaster, refetchMasters, type],
    );

    return (
      <Form form={form} component={false}>
        <Table
          rowKey={(rowItem: RowItem) => rowItem.id}
          dataSource={foodingJournalMasters}
          loading={loading}
          bordered
          pagination={false}
          style={{ width: "40%" }}
          footer={() => (
            <>
              <Button onClick={handleEmptyMaster}>
                <PlusOutlined />
                追加する
              </Button>

              <FormActions>
                <Button onClick={handleClear}>クリア</Button>

                <Badge count={Object.keys(editingMasters).length}>
                  <Button
                    type="primary"
                    disabled={Object.keys(editingMasters).length === 0}
                    onClick={handleConfirm}
                  >
                    確認
                  </Button>
                </Badge>
              </FormActions>
            </>
          )}
        >
          <Column
            title="コード"
            dataIndex="code"
            align="left"
            render={(_: string, rowItem: RowItem, index) => (
              <Form.Item
                name={[rowItem.id, "code"]}
                key={rowItem.id}
                rules={[
                  {
                    required: true,
                    min: 1,
                    max: 2,
                    message: `2桁以下の数値を入力してください`,
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) return;

                      const editingRows = Object.values(editingMasters);
                      const conflictingExistingRow = foodingJournalMasters.find(
                        ({ code, id }) => code === value && id !== rowItem.id,
                      );

                      if (conflictingExistingRow) {
                        const editingRow = editingRows.find(
                          ({ code, id }) => code !== value && id === conflictingExistingRow.id,
                        );

                        if (!editingRow) {
                          throw new Error(
                            `既存のコードと競合しています (${conflictingExistingRow.name})`,
                          );
                        }
                      }

                      const conflictingEditingRows = editingRows.filter(
                        ({ code }) => code === value,
                      );

                      if (
                        conflictingEditingRows[0] &&
                        conflictingEditingRows[0].id !== rowItem.id
                      ) {
                        throw new Error(
                          `既存のコードと競合しています (${conflictingEditingRows[0].name})`,
                        );
                      }
                    },
                  },
                ]}
              >
                <InputCode
                  key={rowItem.id}
                  defaultValue={rowItem.code}
                  placeholder="コード"
                  onBlur={() => handleChange({ rowItem })}
                />
              </Form.Item>
            )}
          />

          <Column
            title={`${masterName}名称`}
            dataIndex="name"
            fixed="left"
            render={(_: string, rowItem: RowItem, index) => (
              <Form.Item
                name={[rowItem.id, "name"]}
                key={rowItem.id}
                rules={[
                  {
                    required: true,
                    min: 1,
                    max: 20,
                    message: `${masterName}名称を入力してください`,
                  },
                ]}
              >
                <Input
                  key={rowItem.id}
                  defaultValue={rowItem.name}
                  placeholder={`${masterName}名称`}
                  onBlur={() => handleChange({ rowItem })}
                />
              </Form.Item>
            )}
          />

          <Column
            title=""
            align="center"
            width={100}
            render={(_: string, rowItem: RowItem) => (
              <>
                {rowItem.foodingJournalMenus && rowItem.foodingJournalMenus.length > 0 ? (
                  <Popover
                    title={`メニューに紐づく${masterName}は削除できません`}
                    content={
                      <Typography.Paragraph>
                        メニューを別の{masterName}に紐づけるか、
                        <br />
                        紐づくメニューを削除してから{masterName}を削除してください
                      </Typography.Paragraph>
                    }
                  >
                    <DeleteIcon disabled={true} />
                  </Popover>
                ) : (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>設定を削除しますか？</Paragraph>
                        <Paragraph>一度削除した設定を元に戻すことはできません。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => {
                      handleDelete({ rowItem });
                    }}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                )}
              </>
            )}
          />
        </Table>

        {shouldShowConfirmationModal ? (
          <ConfirmationModal
            editingFoodingJournalMasters={editingMasters}
            masters={masters}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={closeModal}
          />
        ) : null}
      </Form>
    );
  },
);
