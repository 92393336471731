import React, { memo } from "react";
import styled from "styled-components";
import { getAvailablePeriod } from "models/coupon";

import { colors } from "constants/colors";

import { AddCouponFormValues } from "../useAddCouponForm";

const Content = styled.p<{ isPlaceholder: boolean }>`
  display: inline-block;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? `${colors.Text.Secondary}` : `${colors.Text.Default}`};
  background: #f0f2f5;
  border-radius: 60px;
  font-size: 10px;
  line-height: 18px;
  padding: 4px 8px;
`;

type Props = Pick<AddCouponFormValues, "availableFrom" | "availableUntil" | "availableDays">;

export const CouponAvailableDate = memo<Props>(
  ({ availableFrom, availableUntil, availableDays }) => (
    <Content isPlaceholder={!availableFrom}>
      {availableFrom
        ? getAvailablePeriod({ availableFrom, availableUntil, availableDays, now: new Date() })
        : "有効期間"}
    </Content>
  ),
);
