import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuAutoCompulsoryAppetizerShopsGetShops = gql`
    query MenuAutoCompulsoryAppetizerShopsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    enableAutoCompulsoryAppetizerOrder
  }
}
    `;
export const UpdateShopAutoCompulsoryAppetizerEnabled = gql`
    mutation UpdateShopAutoCompulsoryAppetizerEnabled($shopIds: [uuid!]!, $isEnable: Boolean!) {
  update_shop(
    where: {shopId: {_in: $shopIds}}
    _set: {enableAutoCompulsoryAppetizerOrder: $isEnable}
  ) {
    affected_rows
  }
}
    `;
export type MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenuAutoCompulsoryAppetizerShopsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'enableAutoCompulsoryAppetizerOrder'>
  )> }
);

export type UpdateShopAutoCompulsoryAppetizerEnabledMutationVariables = Types.Exact<{
  shopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  isEnable: Types.Scalars['Boolean'];
}>;


export type UpdateShopAutoCompulsoryAppetizerEnabledMutation = (
  { __typename?: 'mutation_root' }
  & { update_shop?: Types.Maybe<(
    { __typename?: 'shop_mutation_response' }
    & Pick<Types.ShopMutationResponse, 'affected_rows'>
  )> }
);


export const MenuAutoCompulsoryAppetizerShopsGetShopsDocument = gql`
    query MenuAutoCompulsoryAppetizerShopsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    enableAutoCompulsoryAppetizerOrder
  }
}
    `;

/**
 * __useMenuAutoCompulsoryAppetizerShopsGetShopsQuery__
 *
 * To run a query within a React component, call `useMenuAutoCompulsoryAppetizerShopsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuAutoCompulsoryAppetizerShopsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuAutoCompulsoryAppetizerShopsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenuAutoCompulsoryAppetizerShopsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<MenuAutoCompulsoryAppetizerShopsGetShopsQuery, MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuAutoCompulsoryAppetizerShopsGetShopsQuery, MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables>(MenuAutoCompulsoryAppetizerShopsGetShopsDocument, options);
      }
export function useMenuAutoCompulsoryAppetizerShopsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuAutoCompulsoryAppetizerShopsGetShopsQuery, MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuAutoCompulsoryAppetizerShopsGetShopsQuery, MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables>(MenuAutoCompulsoryAppetizerShopsGetShopsDocument, options);
        }
export type MenuAutoCompulsoryAppetizerShopsGetShopsQueryHookResult = ReturnType<typeof useMenuAutoCompulsoryAppetizerShopsGetShopsQuery>;
export type MenuAutoCompulsoryAppetizerShopsGetShopsLazyQueryHookResult = ReturnType<typeof useMenuAutoCompulsoryAppetizerShopsGetShopsLazyQuery>;
export type MenuAutoCompulsoryAppetizerShopsGetShopsQueryResult = Apollo.QueryResult<MenuAutoCompulsoryAppetizerShopsGetShopsQuery, MenuAutoCompulsoryAppetizerShopsGetShopsQueryVariables>;
export const UpdateShopAutoCompulsoryAppetizerEnabledDocument = gql`
    mutation UpdateShopAutoCompulsoryAppetizerEnabled($shopIds: [uuid!]!, $isEnable: Boolean!) {
  update_shop(
    where: {shopId: {_in: $shopIds}}
    _set: {enableAutoCompulsoryAppetizerOrder: $isEnable}
  ) {
    affected_rows
  }
}
    `;
export type UpdateShopAutoCompulsoryAppetizerEnabledMutationFn = Apollo.MutationFunction<UpdateShopAutoCompulsoryAppetizerEnabledMutation, UpdateShopAutoCompulsoryAppetizerEnabledMutationVariables>;

/**
 * __useUpdateShopAutoCompulsoryAppetizerEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateShopAutoCompulsoryAppetizerEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopAutoCompulsoryAppetizerEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopAutoCompulsoryAppetizerEnabledMutation, { data, loading, error }] = useUpdateShopAutoCompulsoryAppetizerEnabledMutation({
 *   variables: {
 *      shopIds: // value for 'shopIds'
 *      isEnable: // value for 'isEnable'
 *   },
 * });
 */
export function useUpdateShopAutoCompulsoryAppetizerEnabledMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopAutoCompulsoryAppetizerEnabledMutation, UpdateShopAutoCompulsoryAppetizerEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopAutoCompulsoryAppetizerEnabledMutation, UpdateShopAutoCompulsoryAppetizerEnabledMutationVariables>(UpdateShopAutoCompulsoryAppetizerEnabledDocument, options);
      }
export type UpdateShopAutoCompulsoryAppetizerEnabledMutationHookResult = ReturnType<typeof useUpdateShopAutoCompulsoryAppetizerEnabledMutation>;
export type UpdateShopAutoCompulsoryAppetizerEnabledMutationResult = Apollo.MutationResult<UpdateShopAutoCompulsoryAppetizerEnabledMutation>;
export type UpdateShopAutoCompulsoryAppetizerEnabledMutationOptions = Apollo.BaseMutationOptions<UpdateShopAutoCompulsoryAppetizerEnabledMutation, UpdateShopAutoCompulsoryAppetizerEnabledMutationVariables>;