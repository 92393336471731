import { getRole } from "models/user";

import { useCurrentUser } from "hooks/useUser";

export const useCurrentRole = () => {
  const user = useCurrentUser();

  const role = getRole(user);

  return role;
};
