import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import jaJP from "antd/es/locale/ja_JP";
import { ApolloProvider } from "@apollo/client";

import { App } from "components/App";
import { ErrorFallback } from "components/ErrorFallback";
import { NavigationDrawerProvider } from "components/Layout/DashboardLayout/NavigationDrawer";
import { Router } from "components/Router";
import { theme } from "constants/theme";
import { CompanyProvider } from "hooks/useCompany";
import { CompanySelectOverlayProvider } from "hooks/useCompanySelectOverlay";
import { CorporationProvider } from "hooks/useCorporation";
import { ShopProvider } from "hooks/useShop";
import { ShopPerCorporationProvider } from "hooks/useShopPerCorporation";
import { UserContext } from "hooks/useUser";
import { client } from "store/client";
import { GlobalStyle } from "styles/global";

import "antd/dist/reset.css";

const DataProvider = ({ children }: React.PropsWithChildren<unknown>) => (
  <UserContext>
    <CorporationProvider>
      <CompanyProvider>
        <ShopProvider>
          <ShopPerCorporationProvider>{children}</ShopPerCorporationProvider>
        </ShopProvider>
      </CompanyProvider>
    </CorporationProvider>
  </UserContext>
);

export const Root = () => (
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ConfigProvider locale={jaJP}>
            <DndProvider backend={HTML5Backend}>
              <DataProvider>
                <CompanySelectOverlayProvider>
                  <NavigationDrawerProvider>
                    <GlobalStyle />
                    <App>
                      <Router />
                    </App>
                  </NavigationDrawerProvider>
                </CompanySelectOverlayProvider>
              </DataProvider>
            </DndProvider>
          </ConfigProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
