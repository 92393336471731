import React, { useMemo, useState } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Alert, Button, Divider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { getLastMonthAllTenantAverageScoreByAnswerSegments } from "models/questionnaireAnalytics";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import {
  FilterConditions,
  SerializedFilterConditions,
  ShopMetricsFilter,
} from "components/QuestionnaireAnalytics/ShopMetricsFilter";
import { ShopMetricsMenu } from "components/QuestionnaireAnalytics/ShopMetricsMenu";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { viewport } from "constants/viewport";
import { useCorporation } from "hooks/useCorporation";
import { useDinii } from "hooks/useDinii";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import { useViewport } from "hooks/useViewport";
import {
  useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery,
  useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery,
  useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery,
  useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery,
  useQuestionnaireAnalyticsShopMetricsGetShopsQuery,
} from "pages/QuestionnaireAnalyticsShopMetrics/queries";
import { ScoreMetrics } from "pages/QuestionnaireAnalyticsShopMetrics/ScoreMetrics";

import { AnswererAttributeMetrics } from "./AnswererAttributeMetrics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 0 16px;
  }
`;

const PageHeaderFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 16px;
  flex-flow: wrap;
`;

const StyledFormContent = styled(FormContent)`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 12px;
  }
`;

const ShopMetricsFilterCol = styled.div`
  flex: 1;
`;

const QUESTIONNAIRE_CONFIG_NOT_FOUND_ERROR_MESSAGE = "アンケートの設定が見つかりませんでした";

export const QuestionnaireAnalyticsShopMetrics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const { shop: currentShop, setShop } = useShopPerCorporation();
  const currentShopId = currentShop?.shopId ?? "";

  const [isQuestionnaireConfigNotFoundError, setIsQuestionnaireConfigNotFoundError] =
    useState(false);

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const { data: getQuestionnaire } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const questionnaireId = getQuestionnaire?.questionnaire[0]?.id;

  const { data: getShopsData, loading: getShopsLoading } =
    useQuestionnaireAnalyticsShopMetricsGetShopsQuery(
      corporationId && questionnaireId
        ? {
            variables: { corporationId, questionnaireId },
            onCompleted: (data) => {
              // NOTE: アンケート設定を持つ店舗を選択するようにする
              const shopIds = data.corporation[0]?.companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .flatMap(({ shops }) =>
                  shops.sort((a, b) => a.name.localeCompare(b.name)).map(({ shopId }) => shopId),
                );
              if (!shopIds || shopIds.length === 0) return;
              if (shopIds.includes(currentShopId)) return;
              setShop(shopIds[0]);
            },
          }
        : { skip: true },
    );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const range = useMemo(
    () =>
      !filterConditions.range?.[0] || !filterConditions.range[1]
        ? null
        : {
            startAt: filterConditions.range[0].toISOString(),
            endAt: filterConditions.range[1].toISOString(),
          },

    [filterConditions.range],
  );

  const { data: getQuestionnaireAnswers } =
    useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery(
      questionnaireId && currentShopId && range
        ? {
            variables: {
              questionnaireId,
              shopId: currentShopId,
              ...range,
            },
          }
        : { skip: true },
    );

  const {
    data: getQuestionnaireScoreMetricsData,
    error: getQuestionnaireScoreMetricsError,
    loading: getQuestionnaireScoreMetricsLoading,
  } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery(
    corporationId && questionnaireId && range
      ? {
          variables: {
            input: {
              questionnaireId,
              corporationId,
              shopIds: null,
              ...range,
              answererSegments: filterConditions.answerSegments,
            },
          },
        }
      : { skip: true },
  );
  const questionnaireScoreMetrics = getQuestionnaireScoreMetricsData?.questionnaireScoreMetrics;

  const {
    data: questionnaireAnswererMetricsData,
    error: getQuestionnaireAnswererAttributeMetricsError,
  } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery(
    corporationId && questionnaireId && range
      ? {
          variables: {
            input: {
              questionnaireId,
              corporationId,
              shopId: currentShopId,
              ...range,
              answererSegments: filterConditions.answerSegments,
            },
          },
        }
      : { skip: true },
  );
  const questionnaireAnswererMetrics =
    questionnaireAnswererMetricsData?.questionnaireAnswererAttributeMetrics;

  const isEmpty = !getQuestionnaireAnswers?.questionnaireAnswer.length;

  const shouldShowError =
    getQuestionnaireScoreMetricsError || getQuestionnaireAnswererAttributeMetricsError;

  const [dinii, getContext] = useDinii();

  const [{ loading: isDownloadingQuestionnaireAnswers }, downloadQuestionnaireAnswers] =
    useAsyncFn(async () => {
      const context = await getContext();

      const startDate = filterConditions.range?.[0];
      const endDate = filterConditions.range?.[1];

      if (
        !context ||
        !questionnaireId ||
        !corporationId ||
        !currentShopId ||
        !startDate ||
        !endDate
      ) {
        return;
      }

      const startDateString = startDate.format("YYYYMMDD");
      const endDateString = endDate.format("YYYYMMDD");

      try {
        const { data } = await dinii.questionnaire.download(context, {
          questionnaireId,
          corporationId,
          shopIds: [currentShopId],
          startDate: startDateString,
          endDate: endDateString,
        });
        saveAs(
          data,
          `questionnaire_answers_${corporationId}_${startDateString}_${endDateString}.csv`,
        );

        message.success("ダウンロードが完了しました");
      } catch (err) {
        message.error("不明なエラーが発生しました");
      }
    }, [
      getContext,
      filterConditions.range,
      questionnaireId,
      corporationId,
      currentShopId,
      dinii.questionnaire,
    ]);

  const lastMonthAllTenantAverageScore = useMemo(
    () => getLastMonthAllTenantAverageScoreByAnswerSegments(filterConditions.answerSegments ?? []),
    [filterConditions.answerSegments],
  );

  const { isDesktop } = useViewport();

  return (
    <DashboardLayout title="店舗詳細">
      <Container>
        <PageHeader
          title={<Title>店舗詳細</Title>}
          footer={
            <PageHeaderFooter>
              <ShopMetricsFilterCol>
                <ShopMetricsFilter
                  filterConditions={filterConditions}
                  updateFilterCondition={updateFilterCondition}
                  companies={companies}
                  loading={getShopsLoading}
                />
              </ShopMetricsFilterCol>
              {isDesktop && (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadQuestionnaireAnswers()}
                  loading={isDownloadingQuestionnaireAnswers}
                >
                  CSV ダウンロード
                </Button>
              )}
            </PageHeaderFooter>
          }
        />

        {isQuestionnaireConfigNotFoundError && (
          <Alert
            message="アンケートが見つかりませんでした"
            type="error"
            description="アンケートの設定を行ってから再度お試しください"
          />
        )}

        {!isQuestionnaireConfigNotFoundError && shouldShowError && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}

        <ShopMetricsMenu />

        <StyledFormContent>
          <ScoreMetrics
            shopId={currentShopId}
            questionnaireScoreMetrics={questionnaireScoreMetrics}
            isLoading={!questionnaireScoreMetrics && getQuestionnaireScoreMetricsLoading}
            isEmpty={isEmpty}
            lastMonthAllTenantAverageScore={lastMonthAllTenantAverageScore}
          />
          <Spacer height={16} />

          <Divider orientation="left">回答者データ</Divider>

          {questionnaireAnswererMetrics && (
            <AnswererAttributeMetrics answererAttributes={questionnaireAnswererMetrics} />
          )}

          <Spacer height={16} />
        </StyledFormContent>
      </Container>
    </DashboardLayout>
  );
};
