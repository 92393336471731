import React, { memo, ReactNode } from "react";
import styled from "styled-components";

import { grey, oldColors } from "constants/colors";

const Container = styled.div<{ height: number }>`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}px;
  height: 204px;
  border-radius: 12px;
  overflow: hidden;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  will-change: filter;
`;

const LeftContent = styled.div`
  flex: 8;
  padding: 16px 0 16px 40px;
  background-color: ${grey[0]};
`;

const RightContent = styled.div`
  min-width: 177px;
  flex: 3;
  background-color: ${oldColors.Orange};
  color: ${grey[0]};
`;

export const Panel = memo<{ height: number; left: ReactNode; right: ReactNode }>(
  ({ height, left, right }) => (
    <Container height={height}>
      <LeftContent>{left}</LeftContent>
      <RightContent>{right}</RightContent>
    </Container>
  ),
);
