import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddDashboardAccountRoleInsertDashboardAccountRole = gql`
    mutation AddDashboardAccountRoleInsertDashboardAccountRole($input: dashboardAccountRole_insert_input!) {
  insert_dashboardAccountRole_one(object: $input) {
    id
  }
}
    `;
export const CopyDashboardAccountRoleGetDashboardAccountRole = gql`
    query CopyDashboardAccountRoleGetDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  dashboardAccountRole_by_pk(id: $dashboardAccountRoleId) {
    id
    name
    dashboardAccountRolePermissions {
      id
      feature
      type
    }
  }
}
    `;
export type AddDashboardAccountRoleInsertDashboardAccountRoleMutationVariables = Types.Exact<{
  input: Types.DashboardAccountRoleInsertInput;
}>;


export type AddDashboardAccountRoleInsertDashboardAccountRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dashboardAccountRole_one?: Types.Maybe<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id'>
  )> }
);

export type CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables = Types.Exact<{
  dashboardAccountRoleId: Types.Scalars['uuid'];
}>;


export type CopyDashboardAccountRoleGetDashboardAccountRoleQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountRole_by_pk?: Types.Maybe<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id' | 'name'>
    & { dashboardAccountRolePermissions: Array<(
      { __typename?: 'dashboardAccountRolePermission' }
      & Pick<Types.DashboardAccountRolePermission, 'id' | 'feature' | 'type'>
    )> }
  )> }
);


export const AddDashboardAccountRoleInsertDashboardAccountRoleDocument = gql`
    mutation AddDashboardAccountRoleInsertDashboardAccountRole($input: dashboardAccountRole_insert_input!) {
  insert_dashboardAccountRole_one(object: $input) {
    id
  }
}
    `;
export type AddDashboardAccountRoleInsertDashboardAccountRoleMutationFn = Apollo.MutationFunction<AddDashboardAccountRoleInsertDashboardAccountRoleMutation, AddDashboardAccountRoleInsertDashboardAccountRoleMutationVariables>;

/**
 * __useAddDashboardAccountRoleInsertDashboardAccountRoleMutation__
 *
 * To run a mutation, you first call `useAddDashboardAccountRoleInsertDashboardAccountRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardAccountRoleInsertDashboardAccountRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDashboardAccountRoleInsertDashboardAccountRoleMutation, { data, loading, error }] = useAddDashboardAccountRoleInsertDashboardAccountRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDashboardAccountRoleInsertDashboardAccountRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddDashboardAccountRoleInsertDashboardAccountRoleMutation, AddDashboardAccountRoleInsertDashboardAccountRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDashboardAccountRoleInsertDashboardAccountRoleMutation, AddDashboardAccountRoleInsertDashboardAccountRoleMutationVariables>(AddDashboardAccountRoleInsertDashboardAccountRoleDocument, options);
      }
export type AddDashboardAccountRoleInsertDashboardAccountRoleMutationHookResult = ReturnType<typeof useAddDashboardAccountRoleInsertDashboardAccountRoleMutation>;
export type AddDashboardAccountRoleInsertDashboardAccountRoleMutationResult = Apollo.MutationResult<AddDashboardAccountRoleInsertDashboardAccountRoleMutation>;
export type AddDashboardAccountRoleInsertDashboardAccountRoleMutationOptions = Apollo.BaseMutationOptions<AddDashboardAccountRoleInsertDashboardAccountRoleMutation, AddDashboardAccountRoleInsertDashboardAccountRoleMutationVariables>;
export const CopyDashboardAccountRoleGetDashboardAccountRoleDocument = gql`
    query CopyDashboardAccountRoleGetDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  dashboardAccountRole_by_pk(id: $dashboardAccountRoleId) {
    id
    name
    dashboardAccountRolePermissions {
      id
      feature
      type
    }
  }
}
    `;

/**
 * __useCopyDashboardAccountRoleGetDashboardAccountRoleQuery__
 *
 * To run a query within a React component, call `useCopyDashboardAccountRoleGetDashboardAccountRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyDashboardAccountRoleGetDashboardAccountRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyDashboardAccountRoleGetDashboardAccountRoleQuery({
 *   variables: {
 *      dashboardAccountRoleId: // value for 'dashboardAccountRoleId'
 *   },
 * });
 */
export function useCopyDashboardAccountRoleGetDashboardAccountRoleQuery(baseOptions: Apollo.QueryHookOptions<CopyDashboardAccountRoleGetDashboardAccountRoleQuery, CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyDashboardAccountRoleGetDashboardAccountRoleQuery, CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables>(CopyDashboardAccountRoleGetDashboardAccountRoleDocument, options);
      }
export function useCopyDashboardAccountRoleGetDashboardAccountRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyDashboardAccountRoleGetDashboardAccountRoleQuery, CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyDashboardAccountRoleGetDashboardAccountRoleQuery, CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables>(CopyDashboardAccountRoleGetDashboardAccountRoleDocument, options);
        }
export type CopyDashboardAccountRoleGetDashboardAccountRoleQueryHookResult = ReturnType<typeof useCopyDashboardAccountRoleGetDashboardAccountRoleQuery>;
export type CopyDashboardAccountRoleGetDashboardAccountRoleLazyQueryHookResult = ReturnType<typeof useCopyDashboardAccountRoleGetDashboardAccountRoleLazyQuery>;
export type CopyDashboardAccountRoleGetDashboardAccountRoleQueryResult = Apollo.QueryResult<CopyDashboardAccountRoleGetDashboardAccountRoleQuery, CopyDashboardAccountRoleGetDashboardAccountRoleQueryVariables>;