import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPikaichiConfigGetShopPikaichiConfig = gql`
    query EditPikaichiConfigGetShopPikaichiConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    pikaichiConfig {
      pikaichiConfigId
      enabled
      pikaichiCompanyCd
      pikaichiShopCd
      pikaichiPosNumber
      ftpHost
      ftpPassword
      ftpPort
      ftpUser
      pikaichiConfigId
      useKansa
      useKinken
      useNewari
    }
  }
}
    `;
export const EditPikaichiConfigUpsertPikaichiConfig = gql`
    mutation EditPikaichiConfigUpsertPikaichiConfig($pikaichiConfig: pikaichiConfig_insert_input!) {
  insert_pikaichiConfig_one(
    object: $pikaichiConfig
    on_conflict: {constraint: pikaichiConfig_pkey, update_columns: [enabled, pikaichiCompanyCd, pikaichiShopCd, pikaichiPosNumber, ftpHost, ftpPassword, ftpPort, ftpUser, useKansa, useKinken, useNewari]}
  ) {
    pikaichiConfigId
  }
}
    `;
export type EditPikaichiConfigGetShopPikaichiConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditPikaichiConfigGetShopPikaichiConfigQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { pikaichiConfig?: Types.Maybe<(
      { __typename?: 'pikaichiConfig' }
      & Pick<Types.PikaichiConfig, 'pikaichiConfigId' | 'enabled' | 'pikaichiCompanyCd' | 'pikaichiShopCd' | 'pikaichiPosNumber' | 'ftpHost' | 'ftpPassword' | 'ftpPort' | 'ftpUser' | 'useKansa' | 'useKinken' | 'useNewari'>
    )> }
  )> }
);

export type EditPikaichiConfigUpsertPikaichiConfigMutationVariables = Types.Exact<{
  pikaichiConfig: Types.PikaichiConfigInsertInput;
}>;


export type EditPikaichiConfigUpsertPikaichiConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiConfig_one?: Types.Maybe<(
    { __typename?: 'pikaichiConfig' }
    & Pick<Types.PikaichiConfig, 'pikaichiConfigId'>
  )> }
);


export const EditPikaichiConfigGetShopPikaichiConfigDocument = gql`
    query EditPikaichiConfigGetShopPikaichiConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    pikaichiConfig {
      pikaichiConfigId
      enabled
      pikaichiCompanyCd
      pikaichiShopCd
      pikaichiPosNumber
      ftpHost
      ftpPassword
      ftpPort
      ftpUser
      pikaichiConfigId
      useKansa
      useKinken
      useNewari
    }
  }
}
    `;

/**
 * __useEditPikaichiConfigGetShopPikaichiConfigQuery__
 *
 * To run a query within a React component, call `useEditPikaichiConfigGetShopPikaichiConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiConfigGetShopPikaichiConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiConfigGetShopPikaichiConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditPikaichiConfigGetShopPikaichiConfigQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiConfigGetShopPikaichiConfigQuery, EditPikaichiConfigGetShopPikaichiConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiConfigGetShopPikaichiConfigQuery, EditPikaichiConfigGetShopPikaichiConfigQueryVariables>(EditPikaichiConfigGetShopPikaichiConfigDocument, options);
      }
export function useEditPikaichiConfigGetShopPikaichiConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiConfigGetShopPikaichiConfigQuery, EditPikaichiConfigGetShopPikaichiConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiConfigGetShopPikaichiConfigQuery, EditPikaichiConfigGetShopPikaichiConfigQueryVariables>(EditPikaichiConfigGetShopPikaichiConfigDocument, options);
        }
export type EditPikaichiConfigGetShopPikaichiConfigQueryHookResult = ReturnType<typeof useEditPikaichiConfigGetShopPikaichiConfigQuery>;
export type EditPikaichiConfigGetShopPikaichiConfigLazyQueryHookResult = ReturnType<typeof useEditPikaichiConfigGetShopPikaichiConfigLazyQuery>;
export type EditPikaichiConfigGetShopPikaichiConfigQueryResult = Apollo.QueryResult<EditPikaichiConfigGetShopPikaichiConfigQuery, EditPikaichiConfigGetShopPikaichiConfigQueryVariables>;
export const EditPikaichiConfigUpsertPikaichiConfigDocument = gql`
    mutation EditPikaichiConfigUpsertPikaichiConfig($pikaichiConfig: pikaichiConfig_insert_input!) {
  insert_pikaichiConfig_one(
    object: $pikaichiConfig
    on_conflict: {constraint: pikaichiConfig_pkey, update_columns: [enabled, pikaichiCompanyCd, pikaichiShopCd, pikaichiPosNumber, ftpHost, ftpPassword, ftpPort, ftpUser, useKansa, useKinken, useNewari]}
  ) {
    pikaichiConfigId
  }
}
    `;
export type EditPikaichiConfigUpsertPikaichiConfigMutationFn = Apollo.MutationFunction<EditPikaichiConfigUpsertPikaichiConfigMutation, EditPikaichiConfigUpsertPikaichiConfigMutationVariables>;

/**
 * __useEditPikaichiConfigUpsertPikaichiConfigMutation__
 *
 * To run a mutation, you first call `useEditPikaichiConfigUpsertPikaichiConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiConfigUpsertPikaichiConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPikaichiConfigUpsertPikaichiConfigMutation, { data, loading, error }] = useEditPikaichiConfigUpsertPikaichiConfigMutation({
 *   variables: {
 *      pikaichiConfig: // value for 'pikaichiConfig'
 *   },
 * });
 */
export function useEditPikaichiConfigUpsertPikaichiConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditPikaichiConfigUpsertPikaichiConfigMutation, EditPikaichiConfigUpsertPikaichiConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPikaichiConfigUpsertPikaichiConfigMutation, EditPikaichiConfigUpsertPikaichiConfigMutationVariables>(EditPikaichiConfigUpsertPikaichiConfigDocument, options);
      }
export type EditPikaichiConfigUpsertPikaichiConfigMutationHookResult = ReturnType<typeof useEditPikaichiConfigUpsertPikaichiConfigMutation>;
export type EditPikaichiConfigUpsertPikaichiConfigMutationResult = Apollo.MutationResult<EditPikaichiConfigUpsertPikaichiConfigMutation>;
export type EditPikaichiConfigUpsertPikaichiConfigMutationOptions = Apollo.BaseMutationOptions<EditPikaichiConfigUpsertPikaichiConfigMutation, EditPikaichiConfigUpsertPikaichiConfigMutationVariables>;