import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCorporation } from "hooks/useCorporation";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useCurrentUser } from "hooks/useUser";
import { DashboardAccountTable } from "pages/DashboardAccounts/DashboardAccountTable";
import {
  useDashboardAccountsDeleteDashboardAccountMutation,
  useDashboardAccountsGetDashboardAccountsQuery,
} from "pages/DashboardAccounts/queries";

import {
  DashboardAccountFilter,
  DashboardAccountsFilterConditions,
} from "./DashboardAccountFilter";

const { confirm } = Modal;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DashboardAccounts = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const [corporation] = useCorporation();

  const [filterConditions, setFilterConditions] =
    useState<DashboardAccountsFilterConditions | null>(null);

  const {
    data: getDashboardAccountsData,
    loading: loadingDashboardAccounts,
    refetch: refetchDashboardAccounts,
  } = useDashboardAccountsGetDashboardAccountsQuery(
    corporation
      ? {
          variables: {
            corporationId: corporation.corporationId,
            query: filterConditions?.query ?? "%",
          },
        }
      : { skip: true },
  );

  const currentUser = useCurrentUser();

  // NOTE: 自身のアカウントを先頭に持ってくるために、sort を行う
  const sortedDashboardAccounts = useMemo(() => {
    const dashboardAccounts =
      getDashboardAccountsData?.dashboardAccount?.map((account) => ({
        ...account,
        isMe: currentUser?.email === account.email,
      })) ?? [];
    return (
      dashboardAccounts.sort((a1, a2) => {
        if (a1.isMe) return -1;
        if (a2.isMe) return 1;
        return 0;
      }) ?? []
    );
  }, [getDashboardAccountsData?.dashboardAccount, currentUser?.email]);

  const showAddAccountButton = isFeatureEnabled("showAddDashboardAccountButton");
  const showDeleteAccountButton = showAddAccountButton;
  const showEditAccountButtonAnywhere = showAddAccountButton;

  const [deleteDashboardAccountMutation] = useDashboardAccountsDeleteDashboardAccountMutation();

  const showConfirmDeleteModal = useCallback(
    ({ dashboardAccountId, userName }: { dashboardAccountId: string; userName: string }) =>
      confirm({
        title: `${userName}のアカウントを削除しますか？`,
        icon: <ExclamationCircleOutlined />,
        content: "アカウントを削除すると復元ができません",
        okText: "削除",
        cancelText: "キャンセル",
        onOk: async () => {
          if (!corporation) return;
          try {
            const { errors } = await deleteDashboardAccountMutation({
              variables: { input: { dashboardAccountId } },
            });

            await refetchDashboardAccounts();

            if (errors) {
              message.error(errors[0]?.message || "アカウントの削除が失敗しました");
            } else {
              message.success("アカウントを削除しました");
            }
          } catch (e) {
            message.error("アカウントの削除が失敗しました");
          }
        },
        onCancel() {},
      }),
    [corporation, deleteDashboardAccountMutation, refetchDashboardAccounts],
  );

  return (
    <DashboardLayout title="アカウント一覧">
      <PageHeader
        title="アカウント一覧"
        extra={
          showAddAccountButton && (
            <Link to="/setting/account/add">
              <Button type="primary">新規作成</Button>
            </Link>
          )
        }
        footer={
          <Wrapper>
            <DashboardAccountFilter
              key="dashboardAccountFilter"
              onChangeFilterConditions={setFilterConditions}
            />
          </Wrapper>
        }
      />
      <DashboardAccountTable
        onPressDelete={showConfirmDeleteModal}
        dashboardAccounts={sortedDashboardAccounts}
        loading={loadingDashboardAccounts}
        showDeleteAccountButton={showDeleteAccountButton}
        showEditAccountButtonAnywhere={showEditAccountButtonAnywhere}
      />
    </DashboardLayout>
  );
};
