import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddTableFormItem } from "../useAddTableForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TableNameField = memo<Props>((props) => (
  <AddTableFormItem
    label="テーブル名"
    name="name"
    rules={[{ required: true, message: "テーブル名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddTableFormItem>
));
