import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CompanySalesAnalyticsGetShops = gql`
    query CompanySalesAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export const CompanySalesAnalyticsGetBusinessOperationHourTypes = gql`
    query CompanySalesAnalyticsGetBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    businessOperationHourType
    start
    end
  }
}
    `;
export type CompanySalesAnalyticsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type CompanySalesAnalyticsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);

export type CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CompanySalesAnalyticsGetBusinessOperationHourTypesQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'businessOperationHourType' | 'start' | 'end'>
  )> }
);


export const CompanySalesAnalyticsGetShopsDocument = gql`
    query CompanySalesAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useCompanySalesAnalyticsGetShopsQuery__
 *
 * To run a query within a React component, call `useCompanySalesAnalyticsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySalesAnalyticsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySalesAnalyticsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useCompanySalesAnalyticsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<CompanySalesAnalyticsGetShopsQuery, CompanySalesAnalyticsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySalesAnalyticsGetShopsQuery, CompanySalesAnalyticsGetShopsQueryVariables>(CompanySalesAnalyticsGetShopsDocument, options);
      }
export function useCompanySalesAnalyticsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySalesAnalyticsGetShopsQuery, CompanySalesAnalyticsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySalesAnalyticsGetShopsQuery, CompanySalesAnalyticsGetShopsQueryVariables>(CompanySalesAnalyticsGetShopsDocument, options);
        }
export type CompanySalesAnalyticsGetShopsQueryHookResult = ReturnType<typeof useCompanySalesAnalyticsGetShopsQuery>;
export type CompanySalesAnalyticsGetShopsLazyQueryHookResult = ReturnType<typeof useCompanySalesAnalyticsGetShopsLazyQuery>;
export type CompanySalesAnalyticsGetShopsQueryResult = Apollo.QueryResult<CompanySalesAnalyticsGetShopsQuery, CompanySalesAnalyticsGetShopsQueryVariables>;
export const CompanySalesAnalyticsGetBusinessOperationHourTypesDocument = gql`
    query CompanySalesAnalyticsGetBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    businessOperationHourType
    start
    end
  }
}
    `;

/**
 * __useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery__
 *
 * To run a query within a React component, call `useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery(baseOptions: Apollo.QueryHookOptions<CompanySalesAnalyticsGetBusinessOperationHourTypesQuery, CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySalesAnalyticsGetBusinessOperationHourTypesQuery, CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables>(CompanySalesAnalyticsGetBusinessOperationHourTypesDocument, options);
      }
export function useCompanySalesAnalyticsGetBusinessOperationHourTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySalesAnalyticsGetBusinessOperationHourTypesQuery, CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySalesAnalyticsGetBusinessOperationHourTypesQuery, CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables>(CompanySalesAnalyticsGetBusinessOperationHourTypesDocument, options);
        }
export type CompanySalesAnalyticsGetBusinessOperationHourTypesQueryHookResult = ReturnType<typeof useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery>;
export type CompanySalesAnalyticsGetBusinessOperationHourTypesLazyQueryHookResult = ReturnType<typeof useCompanySalesAnalyticsGetBusinessOperationHourTypesLazyQuery>;
export type CompanySalesAnalyticsGetBusinessOperationHourTypesQueryResult = Apollo.QueryResult<CompanySalesAnalyticsGetBusinessOperationHourTypesQuery, CompanySalesAnalyticsGetBusinessOperationHourTypesQueryVariables>;