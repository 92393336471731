import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCategoryFormItem } from "../useEditCategoryForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const ShopSideNameField = memo<Props>(({ disabled, ...props }) => (
  <EditCategoryFormItem label="ハンディ表示名" name="shopSideName" {...props}>
    <Input disabled={disabled} />
  </EditCategoryFormItem>
));
