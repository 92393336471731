import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalOnSitePaymentDiscountTypesGetTypes = gql`
    query FoodingJournalOnSitePaymentDiscountTypesGetTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    label
    foodingJournalOnSitePaymentDiscountType {
      id
      discountType
      code
      name
    }
  }
}
    `;
export const FoodingJournalOnSitePaymentDiscountTypeUpsertTypes = gql`
    mutation FoodingJournalOnSitePaymentDiscountTypeUpsertTypes($input: [foodingJournalOnSitePaymentDiscountType_insert_input!]!) {
  insert_foodingJournalOnSitePaymentDiscountType(
    objects: $input
    on_conflict: {constraint: foodingJournalOnSitePaymentDiscountType_onSitePaymentDiscountTy, update_columns: [discountType, name, code]}
  ) {
    affected_rows
  }
}
    `;
export const FoodingJournalOnSitePaymentDiscountTypesDeleteType = gql`
    mutation FoodingJournalOnSitePaymentDiscountTypesDeleteType($foodingJournalOnSitePaymentDiscountTypeId: uuid!) {
  delete_foodingJournalOnSitePaymentDiscountType(
    where: {id: {_eq: $foodingJournalOnSitePaymentDiscountTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'id' | 'label'>
    & { foodingJournalOnSitePaymentDiscountType?: Types.Maybe<(
      { __typename?: 'foodingJournalOnSitePaymentDiscountType' }
      & Pick<Types.FoodingJournalOnSitePaymentDiscountType, 'id' | 'discountType' | 'code' | 'name'>
    )> }
  )> }
);

export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationVariables = Types.Exact<{
  input: Array<Types.FoodingJournalOnSitePaymentDiscountTypeInsertInput> | Types.FoodingJournalOnSitePaymentDiscountTypeInsertInput;
}>;


export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalOnSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'foodingJournalOnSitePaymentDiscountType_mutation_response' }
    & Pick<Types.FoodingJournalOnSitePaymentDiscountTypeMutationResponse, 'affected_rows'>
  )> }
);

export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationVariables = Types.Exact<{
  foodingJournalOnSitePaymentDiscountTypeId: Types.Scalars['uuid'];
}>;


export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalOnSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'foodingJournalOnSitePaymentDiscountType_mutation_response' }
    & Pick<Types.FoodingJournalOnSitePaymentDiscountTypeMutationResponse, 'affected_rows'>
  )> }
);


export const FoodingJournalOnSitePaymentDiscountTypesGetTypesDocument = gql`
    query FoodingJournalOnSitePaymentDiscountTypesGetTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    label
    foodingJournalOnSitePaymentDiscountType {
      id
      discountType
      code
      name
    }
  }
}
    `;

/**
 * __useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery__
 *
 * To run a query within a React component, call `useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery, FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery, FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables>(FoodingJournalOnSitePaymentDiscountTypesGetTypesDocument, options);
      }
export function useFoodingJournalOnSitePaymentDiscountTypesGetTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery, FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery, FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables>(FoodingJournalOnSitePaymentDiscountTypesGetTypesDocument, options);
        }
export type FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery>;
export type FoodingJournalOnSitePaymentDiscountTypesGetTypesLazyQueryHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDiscountTypesGetTypesLazyQuery>;
export type FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryResult = Apollo.QueryResult<FoodingJournalOnSitePaymentDiscountTypesGetTypesQuery, FoodingJournalOnSitePaymentDiscountTypesGetTypesQueryVariables>;
export const FoodingJournalOnSitePaymentDiscountTypeUpsertTypesDocument = gql`
    mutation FoodingJournalOnSitePaymentDiscountTypeUpsertTypes($input: [foodingJournalOnSitePaymentDiscountType_insert_input!]!) {
  insert_foodingJournalOnSitePaymentDiscountType(
    objects: $input
    on_conflict: {constraint: foodingJournalOnSitePaymentDiscountType_onSitePaymentDiscountTy, update_columns: [discountType, name, code]}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationFn = Apollo.MutationFunction<FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationVariables>;

/**
 * __useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation__
 *
 * To run a mutation, you first call `useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation, { data, loading, error }] = useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationVariables>(FoodingJournalOnSitePaymentDiscountTypeUpsertTypesDocument, options);
      }
export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation>;
export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationResult = Apollo.MutationResult<FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation>;
export type FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationOptions = Apollo.BaseMutationOptions<FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation, FoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutationVariables>;
export const FoodingJournalOnSitePaymentDiscountTypesDeleteTypeDocument = gql`
    mutation FoodingJournalOnSitePaymentDiscountTypesDeleteType($foodingJournalOnSitePaymentDiscountTypeId: uuid!) {
  delete_foodingJournalOnSitePaymentDiscountType(
    where: {id: {_eq: $foodingJournalOnSitePaymentDiscountTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationFn = Apollo.MutationFunction<FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationVariables>;

/**
 * __useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation__
 *
 * To run a mutation, you first call `useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation, { data, loading, error }] = useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation({
 *   variables: {
 *      foodingJournalOnSitePaymentDiscountTypeId: // value for 'foodingJournalOnSitePaymentDiscountTypeId'
 *   },
 * });
 */
export function useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationVariables>(FoodingJournalOnSitePaymentDiscountTypesDeleteTypeDocument, options);
      }
export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationHookResult = ReturnType<typeof useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation>;
export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationResult = Apollo.MutationResult<FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation>;
export type FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationOptions = Apollo.BaseMutationOptions<FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation, FoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutationVariables>;