import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type CopyDealingDataFormValues = {
  sourceShopId: string | null;
  targetShopId: string | null;
  enableCopyTable: boolean;
};

const getInitialValues = (): Partial<CopyDealingDataFormValues> => ({
  sourceShopId: null,
  targetShopId: null,
  enableCopyTable: false,
});

export const CopyDealingDataFormItem = createFormItem<CopyDealingDataFormValues>();

export const useCopyDealingDataForm = (
  onChange: (formValues: CopyDealingDataFormValues) => void,
  onSubmit: (formValues: CopyDealingDataFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_, values: CopyDealingDataFormValues) => {
      if (values.sourceShopId === values.targetShopId) {
        form.setFields([{ name: "targetShopId", value: null }]);
        onChange({ ...values, targetShopId: null });
        return;
      }

      onChange(values);
    },
    [form, onChange],
  );

  const submit = useCallback(async () => {
    await form.validateFields();
    const formValues = form.getFieldsValue() as CopyDealingDataFormValues;
    onSubmit(formValues);
  }, [form, onSubmit]);

  return { form, initialValues, change, submit };
};
