import React, { memo } from "react";
import styled from "styled-components";
import { Col, Progress, Row } from "antd";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";

import { QuestionnaireAnswererAttributeMetrics } from "../types";

const StyledCol = styled(Col)`
  width: 314px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    width: 100%;
  }
`;

const ListWrapper = styled.div`
  height: 208px;
  overflow-y: auto;
  /* 親要素に padding が指定されているためスクロールバーが文字と重なってしまうのを避ける */
  margin-right: -16px;
  padding-right: 16px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    height: 180px;
    margin-right: -12px;
    padding-right: 12px;
  }
`;

const DistributionTypeAnswerLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const metricsOrders = ["性別", "年代", "認知経路"];

type Props = {
  answererAttributes: QuestionnaireAnswererAttributeMetrics;
};

export const AnswererAttributeMetrics = memo<Props>(({ answererAttributes }) => (
  <Row gutter={[16, 16]}>
    {[...answererAttributes]
      .sort(({ title: a }, { title: b }) => metricsOrders.indexOf(a) - metricsOrders.indexOf(b))
      .map(({ title, totalCount, distributionItems }) => (
        <StyledCol key={title}>
          <BasePanel title={title}>
            <Spacer height={16} />

            <ListWrapper>
              {distributionItems.map(({ label, count }) => (
                <div key={label}>
                  <DistributionTypeAnswerLabelWrapper>
                    <span>{label}</span>
                    <BoldText>{count}</BoldText>
                  </DistributionTypeAnswerLabelWrapper>
                  <Progress
                    percent={totalCount === 0 ? 0 : (count / totalCount) * 100}
                    strokeColor="#408DAE"
                    showInfo={false}
                  />
                </div>
              ))}
            </ListWrapper>
          </BasePanel>
        </StyledCol>
      ))}
  </Row>
));
