import React, { memo } from "react";
import { ShopOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import { PrintingOptionMenu } from "components/Layout/DashboardLayout/NavigationDrawer/ShopMenu/PrintingOptionMenu";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";

import { GiftsMenu } from "./GiftsMenu";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const ShopMenu = memo<Props>(({ company, ...props }) => {
  const { isFeatureAvailable } = useFeatureFlag();

  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const shouldShowYellMenu = isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds });

  return (
    <SubMenu
      key="shop"
      icon={<ShopOutlined />}
      title="店舗管理"
      features={[DashboardFeatureEnum.ShopManagement]}
      {...props}
    >
      <MenuItem route="shops" to="/shop" text="店舗一覧" />
      <MenuItem route="menus" to="/shop/menu" text="取扱メニュー" />
      <MenuItem route="plans" to="/shop/plan" text="取扱プラン" />
      <MenuItem route="shopOrderableTimes" to="/orderableTime/shop" text="アプリ表示時間設定" />
      <MenuItem route="roles" to="/role" text="キッチンプリンター" />
      <PrintingOptionMenu company={company} />
      <MenuItem route="clerks" to="/clerk" text="店員" />
      <MenuItem route="tables" to="/table" text="テーブル" />
      <MenuItem route="previewUserApp" to="/previewUserApp" text="アプリ表示確認" />
      <MenuItem route="handouts" to="/handout" text="配布物" />
      <GiftsMenu canShow={shouldShowYellMenu} />
    </SubMenu>
  );
});
