import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { AddCategoryForm } from "pages/PlanCategories/AddPlanCategoryModal/AddCategoryForm";
import { AddCategoryFormValues } from "pages/PlanCategories/AddPlanCategoryModal/AddCategoryForm/useAddCategoryForm";
import {
  useAddPlanCategoryModalCategoriesQuery,
  useAddPlanCategoryModalInsertPlanMenuCategoryMutation,
} from "pages/PlanCategories/AddPlanCategoryModal/queries";
import { Plan } from "pages/PlanCategories/types";

type Props = {
  plan: Plan;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddPlanCategoryModal = memo<Props>(({ plan, goBack, onUpdated }) => {
  const { planId } = plan;

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getCategoriesData, error } = useAddPlanCategoryModalCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = useMemo(
    () => getCategoriesData?.category ?? [],
    [getCategoriesData?.category],
  );

  const [addPlanMenuCategory, { loading: loadingInsertPlanMenuCategory }] =
    useAddPlanCategoryModalInsertPlanMenuCategoryMutation();

  const [formValues, setFormValues] = useState<AddCategoryFormValues | null>(null);
  const onOk = useCallback(async () => {
    if (formValues) {
      try {
        const { categories: categoryIds } = formValues;

        const planCategories = categoryIds.map((categoryId) => {
          const category = categories.find((category) => category.categoryId === categoryId);

          if (!category) throw new Error("category is not found");

          return {
            categoryId: category.id,
            _categoryId: category.categoryId,
            planId: plan.id,
            _planId: plan.planId,
          };
        });

        const { data: addPlanMenuCategoryData } = await addPlanMenuCategory({
          variables: { planCategories },
        });

        const returning = addPlanMenuCategoryData?.insert_planMenuCategory?.returning;

        if (returning?.length === 0) {
          throw new Error("");
        }

        onUpdated();

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
    }

    goBack();
  }, [addPlanMenuCategory, categories, formValues, goBack, onUpdated, plan.id, plan.planId]);
  const onCancel = goBack;

  return (
    <Modal
      title="カテゴリ追加"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button key="add" type="primary" onClick={onOk} loading={loadingInsertPlanMenuCategory}>
          追加
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddCategoryForm plan={plan} categories={categories} onChange={setFormValues} />
    </Modal>
  );
});
