import React, { memo } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.div`
  margin-bottom: 4px;
`;

const Message = styled.div``;

type Props = {
  title: string;
  message: string;
};

export const MessageContent = memo<Props>(({ title, message }) => (
  <Container>
    <Title>{title}</Title>
    <Message>{message}</Message>
  </Container>
));
