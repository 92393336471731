import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddTableAreaInsertTableArea = gql`
    mutation AddTableAreaInsertTableArea($tableArea: tableArea_insert_input!) {
  insert_tableArea(objects: [$tableArea]) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const AddTableAreaGetTableAreaMaxPriority = gql`
    query AddTableAreaGetTableAreaMaxPriority($shopId: uuid!) {
  tableArea_aggregate(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export type AddTableAreaInsertTableAreaMutationVariables = Types.Exact<{
  tableArea: Types.TableAreaInsertInput;
}>;


export type AddTableAreaInsertTableAreaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tableArea?: Types.Maybe<(
    { __typename?: 'tableArea_mutation_response' }
    & Pick<Types.TableAreaMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'tableArea' }
      & Pick<Types.TableArea, 'id'>
    )> }
  )> }
);

export type AddTableAreaGetTableAreaMaxPriorityQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddTableAreaGetTableAreaMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { tableArea_aggregate: (
    { __typename?: 'tableArea_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'tableArea_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'tableArea_max_fields' }
        & Pick<Types.TableAreaMaxFields, 'priority'>
      )> }
    )> }
  ) }
);


export const AddTableAreaInsertTableAreaDocument = gql`
    mutation AddTableAreaInsertTableArea($tableArea: tableArea_insert_input!) {
  insert_tableArea(objects: [$tableArea]) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type AddTableAreaInsertTableAreaMutationFn = Apollo.MutationFunction<AddTableAreaInsertTableAreaMutation, AddTableAreaInsertTableAreaMutationVariables>;

/**
 * __useAddTableAreaInsertTableAreaMutation__
 *
 * To run a mutation, you first call `useAddTableAreaInsertTableAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTableAreaInsertTableAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTableAreaInsertTableAreaMutation, { data, loading, error }] = useAddTableAreaInsertTableAreaMutation({
 *   variables: {
 *      tableArea: // value for 'tableArea'
 *   },
 * });
 */
export function useAddTableAreaInsertTableAreaMutation(baseOptions?: Apollo.MutationHookOptions<AddTableAreaInsertTableAreaMutation, AddTableAreaInsertTableAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTableAreaInsertTableAreaMutation, AddTableAreaInsertTableAreaMutationVariables>(AddTableAreaInsertTableAreaDocument, options);
      }
export type AddTableAreaInsertTableAreaMutationHookResult = ReturnType<typeof useAddTableAreaInsertTableAreaMutation>;
export type AddTableAreaInsertTableAreaMutationResult = Apollo.MutationResult<AddTableAreaInsertTableAreaMutation>;
export type AddTableAreaInsertTableAreaMutationOptions = Apollo.BaseMutationOptions<AddTableAreaInsertTableAreaMutation, AddTableAreaInsertTableAreaMutationVariables>;
export const AddTableAreaGetTableAreaMaxPriorityDocument = gql`
    query AddTableAreaGetTableAreaMaxPriority($shopId: uuid!) {
  tableArea_aggregate(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddTableAreaGetTableAreaMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddTableAreaGetTableAreaMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTableAreaGetTableAreaMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTableAreaGetTableAreaMaxPriorityQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddTableAreaGetTableAreaMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddTableAreaGetTableAreaMaxPriorityQuery, AddTableAreaGetTableAreaMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTableAreaGetTableAreaMaxPriorityQuery, AddTableAreaGetTableAreaMaxPriorityQueryVariables>(AddTableAreaGetTableAreaMaxPriorityDocument, options);
      }
export function useAddTableAreaGetTableAreaMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTableAreaGetTableAreaMaxPriorityQuery, AddTableAreaGetTableAreaMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTableAreaGetTableAreaMaxPriorityQuery, AddTableAreaGetTableAreaMaxPriorityQueryVariables>(AddTableAreaGetTableAreaMaxPriorityDocument, options);
        }
export type AddTableAreaGetTableAreaMaxPriorityQueryHookResult = ReturnType<typeof useAddTableAreaGetTableAreaMaxPriorityQuery>;
export type AddTableAreaGetTableAreaMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddTableAreaGetTableAreaMaxPriorityLazyQuery>;
export type AddTableAreaGetTableAreaMaxPriorityQueryResult = Apollo.QueryResult<AddTableAreaGetTableAreaMaxPriorityQuery, AddTableAreaGetTableAreaMaxPriorityQueryVariables>;