import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { ShopBusinessOperationHourInsertInput } from "types/graphql";

import { EditShopBusinessOperationHourForm } from "./EditShopBusinessOperationHourForm";
import {
  useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery,
  useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation,
} from "./queries";

export const EditShopBusinessOperationHour = () => {
  const { id: shopId } = useParams<{ id: string }>();

  const { data, error, loading, refetch } =
    useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery(
      shopId ? { variables: { shopId } } : { skip: true },
    );
  const shop = data?.shop_by_pk;
  const shopBusinessOperationHours = data?.shopBusinessOperationHour;

  const [updateShopBusinessOperationHour, { loading: loadingUpdateShopBusinessOperationHour }] =
    useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation();

  const onSubmit = useCallback(
    async (shopBusinessOperationHoursInput: ShopBusinessOperationHourInsertInput[]) => {
      if (shopId) {
        const deletedIds = shopBusinessOperationHours?.map(({ id }) => id) ?? [];
        const timeRegExp = /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/;
        try {
          await updateShopBusinessOperationHour({
            variables: {
              shopId,
              shopBusinessOperationHours: shopBusinessOperationHoursInput
                .map(({ shopId, businessOperationHourType, start, end }) => ({
                  shopId,
                  businessOperationHourType,
                  start: start.replace(timeRegExp, "$1:$2:00"),
                  end: end.replace(timeRegExp, "$1:$2:00"),
                }))
                .filter(isNotNullable),
              deletedIds,
            },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
        await refetch();
      }
    },
    [shopId, shopBusinessOperationHours, updateShopBusinessOperationHour, refetch],
  );

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && shopBusinessOperationHours && (
        <EditShopBusinessOperationHourForm
          shop={shop}
          shopBusinessOperationHours={shopBusinessOperationHours}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateShopBusinessOperationHour}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
