import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { EditOrderableTimeForm } from "pages/EditOrderableTime/EditOrderableTimeForm";
import { OrderableTimeInsertInput, ShopOrderableTimeTermInsertInput } from "types/graphql";

import {
  useEditOrderableTimeGetOrderableTimeAndShopQuery,
  useEditOrderableTimeUpdateOrderableTimeMutation,
} from "./queries";

export const EditOrderableTime = () => {
  const navigate = useNavigate();

  const { id: orderableTimeId = "", shopId = "" } = useParams<{ id: string; shopId: string }>();

  const { data, loading, error } = useEditOrderableTimeGetOrderableTimeAndShopQuery(
    shopId ? { variables: { orderableTimeId, shopId } } : { skip: true },
  );
  const shop = data?.shop_by_pk;
  const companyId = shop?.companyId;
  const orderableTime = data?.orderableTime_by_pk;

  const [editOrderableTimeMutation, { loading: submitting }] =
    useEditOrderableTimeUpdateOrderableTimeMutation();

  const onSubmit = useCallback(
    async ({
      orderableTime,
      shopOrderableTimeTerms,
    }: {
      orderableTime: Omit<OrderableTimeInsertInput, "companyId">;
      shopOrderableTimeTerms: Omit<ShopOrderableTimeTermInsertInput, "shopId">[];
    }) => {
      try {
        if (!companyId) return;
        if (!shopId) return;

        const result = await editOrderableTimeMutation({
          variables: {
            shopId,
            orderableTimeId,
            orderableTime,
            shopOrderableTimeTermIds: shopOrderableTimeTerms
              .map((term) => term.id)
              .filter(isNotNullable),
            shopOrderableTimeTerms: shopOrderableTimeTerms.map(
              ({ id: _, ...shopOrderableTimeTerm }) => ({ ...shopOrderableTimeTerm, shopId }),
            ),
          },
        });

        if (result?.data?.update_orderableTime?.affected_rows !== 1) {
          throw new Error("");
        }

        message.success("更新しました");

        navigate(-1);
      } catch (err) {
        message.error("更新に失敗しました");
      }
    },
    [editOrderableTimeMutation, companyId, shopId, orderableTimeId, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={orderableTime?.name}
      locationBreadcrumb={
        orderableTime
          ? {
              showShop: false,
              items: [
                { name: "アプリ表示時間" },
                { name: shop?.name ?? "" },
                { name: orderableTime?.name },
              ],
            }
          : undefined
      }
    >
      <PageHeader title={orderableTime?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && orderableTime && (
        <EditOrderableTimeForm
          orderableTime={orderableTime}
          changeDateTime={shop.changeDateTime}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          onClose={goBack}
          loading={submitting}
        />
      )}
    </DashboardLayout>
  );
};
