import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { logger } from "libs/logger";

import { defaultTecAggregationMediaMaps } from "../const";

import { EditTecAggregationMediaMapFormValues } from "./EditTecAggregationMediaMapForm/useEditTecAggregationMediaMapForm";
import { EditTecAggregationMediaMapForm } from "./EditTecAggregationMediaMapForm";
import {
  useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery,
  useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation,
} from "./queries";

type Props = {
  onUpdated(): void;
  goBack(): void;
  aggregationNumber: number;
};

export const EditTecAggregationMediaMapModal = memo<Props>(
  ({ goBack, onUpdated, aggregationNumber }) => {
    const { id = "" } = useParams<{ id: string }>();
    const [company] = useCompany();

    const { data, loading, error } = useEditTecAggregationMediaMapGetTecAggregationMediaMapQuery({
      variables: { id },
    });

    const tecAggregationMediaMap = data
      ? data.tecAggregationMediaMap_by_pk ??
        defaultTecAggregationMediaMaps.find(
          (mediaMap) => mediaMap.aggregationNumber === aggregationNumber,
        )
      : undefined;

    const [updateTecAggregationMediaMap, { loading: updating }] =
      useEditTecAggregationMediaMapUpdateTecAggregationMediaMapMutation();

    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<EditTecAggregationMediaMapFormValues | null>(null);

    const onOk = useCallback(async () => {
      if (!formValues || !company) return;

      try {
        await form.validateFields();

        const { mediaName } = formValues;

        const { data } = await updateTecAggregationMediaMap({
          variables: {
            object: {
              id,
              aggregationNumber,
              mediaName,
              companyId: company.id,
            },
          },
        });

        if (!data?.insert_tecAggregationMediaMap_one?.id) {
          throw new Error(
            `Failed to insert insert_tecAggregationMediaMap: id=${id}, aggregationNumber=${aggregationNumber}, mediaName=${mediaName} companyId=${company?.companyId}`,
          );
        }

        onUpdated();

        goBack();

        message.success("保存しました");
      } catch (err) {
        if (err instanceof Error) {
          logger.error(err.message);
        }

        message.error("保存に失敗しました");
      }
    }, [
      aggregationNumber,
      company,
      form,
      formValues,
      goBack,
      id,
      onUpdated,
      updateTecAggregationMediaMap,
    ]);

    return (
      <Modal
        title={`項番 ${tecAggregationMediaMap?.aggregationNumber ?? ""}`}
        open
        width={900}
        onOk={goBack}
        onCancel={goBack}
        footer={[
          <Button key="cancel" onClick={goBack}>
            キャンセル
          </Button>,
          <Button key="save" type="primary" onClick={onOk} loading={updating}>
            保存
          </Button>,
        ]}
      >
        {loading && <Loading height={300} />}
        {error && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}
        {tecAggregationMediaMap && (
          <EditTecAggregationMediaMapForm
            form={form}
            tecAggregationMediaMap={tecAggregationMediaMap}
            onChange={setFormValues}
          />
        )}
      </Modal>
    );
  },
);
