export const LINE_COLORS = {
  PRIMARY: "#0091CF",
  SECONDARY: "#F6AA00",
};

export const GRID_COLOR = "#C4C4C4";

export const TICK_FONT_SIZE = 10;

export const BAR_COLORS: Record<number, string> = {
  0: "#0091CF",
  1: "#F6AA00",
  2: "#03AF7A",
};

export const LEGEND_HEIGHT = 52;

export const CARTESIAN_GRID_STROKE_DASHARRAY = "5 5";
