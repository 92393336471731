import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeaturedLabelTextField = memo<Props>((props) => (
  <AddMenuFormItem
    label={
      <FormHelp
        label="特集ラベル (日本語)"
        help="メニュー画像の左下にラベルを追加することができます。「期間限定！」「大人気メニュー」などの短いフレーズがおすすめです"
      />
    }
    name="featuredLabelText"
    {...props}
  >
    <Input />
  </AddMenuFormItem>
));
