import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardPlanOptionsEditPlanChoiceModalGetPlanChoice = gql`
    query WinboardPlanOptionsEditPlanChoiceModalGetPlanChoice($planId: Int!, $planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    winboardMenus(where: {_planId: {_eq: $planId}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const WinboardPlanOptionsUpdateWinboardMenu = gql`
    mutation WinboardPlanOptionsUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planChoiceId, _planChoiceId]}
  ) {
    id
  }
}
    `;
export type WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  planChoiceId: Types.Scalars['Int'];
}>;


export type WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery = (
  { __typename?: 'query_root' }
  & { planChoice: Array<(
    { __typename?: 'planChoice' }
    & Pick<Types.PlanChoice, 'id' | 'planChoiceId' | 'name'>
    & { winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type WinboardPlanOptionsUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
}>;


export type WinboardPlanOptionsUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument = gql`
    query WinboardPlanOptionsEditPlanChoiceModalGetPlanChoice($planId: Int!, $planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    winboardMenus(where: {_planId: {_eq: $planId}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery__
 *
 * To run a query within a React component, call `useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      planChoiceId: // value for 'planChoiceId'
 *   },
 * });
 */
export function useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery(baseOptions: Apollo.QueryHookOptions<WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
      }
export function useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
        }
export type WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryHookResult = ReturnType<typeof useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery>;
export type WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQueryHookResult = ReturnType<typeof useWinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery>;
export type WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryResult = Apollo.QueryResult<WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, WinboardPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>;
export const WinboardPlanOptionsUpdateWinboardMenuDocument = gql`
    mutation WinboardPlanOptionsUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, planChoiceId, _planChoiceId]}
  ) {
    id
  }
}
    `;
export type WinboardPlanOptionsUpdateWinboardMenuMutationFn = Apollo.MutationFunction<WinboardPlanOptionsUpdateWinboardMenuMutation, WinboardPlanOptionsUpdateWinboardMenuMutationVariables>;

/**
 * __useWinboardPlanOptionsUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useWinboardPlanOptionsUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlanOptionsUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [winboardPlanOptionsUpdateWinboardMenuMutation, { data, loading, error }] = useWinboardPlanOptionsUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *   },
 * });
 */
export function useWinboardPlanOptionsUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<WinboardPlanOptionsUpdateWinboardMenuMutation, WinboardPlanOptionsUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WinboardPlanOptionsUpdateWinboardMenuMutation, WinboardPlanOptionsUpdateWinboardMenuMutationVariables>(WinboardPlanOptionsUpdateWinboardMenuDocument, options);
      }
export type WinboardPlanOptionsUpdateWinboardMenuMutationHookResult = ReturnType<typeof useWinboardPlanOptionsUpdateWinboardMenuMutation>;
export type WinboardPlanOptionsUpdateWinboardMenuMutationResult = Apollo.MutationResult<WinboardPlanOptionsUpdateWinboardMenuMutation>;
export type WinboardPlanOptionsUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<WinboardPlanOptionsUpdateWinboardMenuMutation, WinboardPlanOptionsUpdateWinboardMenuMutationVariables>;