import React, { memo } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { MIN_DELIVERY_AT_MINUTES_DIFF } from "models/messageDelivery";

import { FormItemProps } from "components/antd/Form";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = Omit<FormItemProps, "children" | "name"> & { deliverTimeName: string };

export const DateField = memo<Props>((props) => (
  <AddMessageDeliveryFormItem.NonProperty noStyle shouldUpdate>
    {({ getFieldValue }) => (
      <AddMessageDeliveryFormItem
        label="配信日"
        name="deliverDate"
        rules={[
          { required: true, message: "配信日を入力してください" },
          {
            validator: async (_, value) => {
              if (!value) return;

              const now = dayjs();
              const currentDeliverTime = getFieldValue(props.deliverTimeName);
              const deliverDate = dayjs(value);
              const deliverTime = currentDeliverTime ? dayjs(currentDeliverTime) : now.endOf("day");
              const deliveryAt = deliverDate.hour(deliverTime.hour()).minute(deliverTime.minute());

              if (now.isAfter(deliveryAt)) {
                throw new Error("過去の日時は設定できません");
              }
              if (deliveryAt.diff(now, "minute") < MIN_DELIVERY_AT_MINUTES_DIFF) {
                throw new Error(`${MIN_DELIVERY_AT_MINUTES_DIFF}分以内の日時は設定できません`);
              }
            },
          },
        ]}
        {...props}
      >
        <DatePicker inputReadOnly />
      </AddMessageDeliveryFormItem>
    )}
  </AddMessageDeliveryFormItem.NonProperty>
));
