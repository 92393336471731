import React, { useMemo, useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Alert, Button, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { chunk } from "lodash";

// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useDinii } from "hooks/useDinii";

import { useWinboardMenuRegistrationCsvGetShopsQuery } from "./queries";
import {
  useWinboardMenuRegistrationDataForm,
  WinboardMenuRegistrationDataFormValues,
} from "./useWinboardMenuRegistrationDataForm";

const { Option } = Select;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BATCH_RUN_THRESHOLD = 6;
const BATCH_RUN_CHUNK_SIZE = 5;

export const WinboardMenuRegistrationCsv = () => {
  const [dinii, getContext] = useDinii();

  const [formData, setFormData] = useState<WinboardMenuRegistrationDataFormValues>({
    shopIds: [],
  });

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getShopsData, error } = useWinboardMenuRegistrationCsvGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const shops = useMemo(() => getShopsData?.company?.[0]?.shops ?? [], [getShopsData]);

  const { form, change: onValuesChange } = useWinboardMenuRegistrationDataForm(setFormData);

  const [{ loading: downloading }, exportWinboardMenuRegistrationCsv] = useAsyncFn(async () => {
    const { shopIds: targetShopIds } = formData;

    const partialContext = await getContext();

    if (!targetShopIds || targetShopIds.length === 0 || !partialContext) {
      return;
    }

    const shopId = targetShopIds[0];
    const context = partialContext.shopId ? partialContext : { ...partialContext, shopId };

    const chunks =
      targetShopIds.length > BATCH_RUN_THRESHOLD
        ? chunk(targetShopIds, BATCH_RUN_CHUNK_SIZE)
        : [targetShopIds];
    try {
      await chunks.reduce<Promise<unknown>>(async (prev, shopIds) => {
        await prev;
        return Promise.all(
          shopIds.map(async (shopId) => {
            const { data } = await dinii.winboard.getMenuRegistrationCsv(context, {
              shopId,
            });
            const name = shops.find((s) => s.shopId === shopId)?.name ?? "";
            saveAs(data, `${name}_winboard_menu.zip`);
          }),
        );
      }, Promise.resolve());
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [dinii, getContext, formData, shops]);

  return (
    <DashboardLayout title="ウィンボード商品登録フォーマットダウンロード">
      <PageHeader
        title="ウィンボード商品登録フォーマットダウンロード"
        description="ウィンボード商品登録フォーマットをダウンロードできます。"
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <Form
          name="winboardMenuRegistrationDataForm"
          form={form}
          layout="vertical"
          onValuesChange={onValuesChange}
        >
          <FormItem label="出力対象店舗" name="shopIds" rules={[{ required: true }]}>
            <Select mode="multiple">
              {shops?.map(({ shopId, name }) => (
                <Option key={shopId} value={shopId}>
                  {name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Form>

        <DescriptionContainer>
          <p>CSVのダウンロードまで数分かかる場合があります</p>
        </DescriptionContainer>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          disabled={!formData.shopIds || formData.shopIds.length === 0}
          loading={downloading}
          onClick={exportWinboardMenuRegistrationCsv}
        >
          ダウンロード
        </Button>
      </FormActions>
    </DashboardLayout>
  );
};
