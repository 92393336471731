import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuRecommendationTranslationsGetMenuRecommendation = gql`
    query EditMenuRecommendationTranslationsGetMenuRecommendation($menuRecommendationMetaId: uuid!) {
  menuRecommendationMeta(where: {id: {_eq: $menuRecommendationMetaId}}, limit: 1) {
    id
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "menuRecommendationMeta"}}) {
      columnName
      locale
      value
    }
  }
}
    `;
export const EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslations = gql`
    mutation EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslations($input: UpsertTranslationsForMenuRecommendationMetaInput!) {
  upsertTranslationsForMenuRecommendationMeta(input: $input)
}
    `;
export type EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables = Types.Exact<{
  menuRecommendationMetaId: Types.Scalars['uuid'];
}>;


export type EditMenuRecommendationTranslationsGetMenuRecommendationQuery = (
  { __typename?: 'query_root' }
  & { menuRecommendationMeta: Array<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id' | 'name'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationVariables = Types.Exact<{
  input: Types.UpsertTranslationsForMenuRecommendationMetaInput;
}>;


export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForMenuRecommendationMeta'>
);


export const EditMenuRecommendationTranslationsGetMenuRecommendationDocument = gql`
    query EditMenuRecommendationTranslationsGetMenuRecommendation($menuRecommendationMetaId: uuid!) {
  menuRecommendationMeta(where: {id: {_eq: $menuRecommendationMetaId}}, limit: 1) {
    id
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "menuRecommendationMeta"}}) {
      columnName
      locale
      value
    }
  }
}
    `;

/**
 * __useEditMenuRecommendationTranslationsGetMenuRecommendationQuery__
 *
 * To run a query within a React component, call `useEditMenuRecommendationTranslationsGetMenuRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuRecommendationTranslationsGetMenuRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuRecommendationTranslationsGetMenuRecommendationQuery({
 *   variables: {
 *      menuRecommendationMetaId: // value for 'menuRecommendationMetaId'
 *   },
 * });
 */
export function useEditMenuRecommendationTranslationsGetMenuRecommendationQuery(baseOptions: Apollo.QueryHookOptions<EditMenuRecommendationTranslationsGetMenuRecommendationQuery, EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuRecommendationTranslationsGetMenuRecommendationQuery, EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables>(EditMenuRecommendationTranslationsGetMenuRecommendationDocument, options);
      }
export function useEditMenuRecommendationTranslationsGetMenuRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuRecommendationTranslationsGetMenuRecommendationQuery, EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuRecommendationTranslationsGetMenuRecommendationQuery, EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables>(EditMenuRecommendationTranslationsGetMenuRecommendationDocument, options);
        }
export type EditMenuRecommendationTranslationsGetMenuRecommendationQueryHookResult = ReturnType<typeof useEditMenuRecommendationTranslationsGetMenuRecommendationQuery>;
export type EditMenuRecommendationTranslationsGetMenuRecommendationLazyQueryHookResult = ReturnType<typeof useEditMenuRecommendationTranslationsGetMenuRecommendationLazyQuery>;
export type EditMenuRecommendationTranslationsGetMenuRecommendationQueryResult = Apollo.QueryResult<EditMenuRecommendationTranslationsGetMenuRecommendationQuery, EditMenuRecommendationTranslationsGetMenuRecommendationQueryVariables>;
export const EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsDocument = gql`
    mutation EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslations($input: UpsertTranslationsForMenuRecommendationMetaInput!) {
  upsertTranslationsForMenuRecommendationMeta(input: $input)
}
    `;
export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationFn = Apollo.MutationFunction<EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation, EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationVariables>;

/**
 * __useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation__
 *
 * To run a mutation, you first call `useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation, { data, loading, error }] = useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation, EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation, EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationVariables>(EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsDocument, options);
      }
export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationHookResult = ReturnType<typeof useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation>;
export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationResult = Apollo.MutationResult<EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation>;
export type EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationOptions = Apollo.BaseMutationOptions<EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation, EditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutationVariables>;