import dayjs from "dayjs";
import { sumBy } from "util/array";
import { isNotNullable } from "util/type/primitive";

import { OnSitePayment } from "types/graphql";
import { Maybe } from "types/Maybe";

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
};

export const filter = <T extends Pick<OnSitePayment, "createdAt">>(
  onSitePayments: T[],
  { range }: FilterConditions,
) => {
  const start = range?.[0];
  const end = range?.[1];
  const filteredOnSitePayments = onSitePayments.filter(
    (onSitePayment) =>
      (start ? dayjs(onSitePayment.createdAt).isSameOrAfter(start) : true) &&
      (end ? dayjs(onSitePayment.createdAt).isSameOrBefore(end) : true),
  );

  return filteredOnSitePayments;
};

export const getTotalNetAmount = (onSitePayment: {
  onSitePaymentDetails: { netPrice: number }[];
}) => sumBy(onSitePayment.onSitePaymentDetails, (detail) => detail.netPrice);

type ItemSales = {
  billingAmount: number;
  taxAmount: number;
};

export const getTotalAmounts = (onSitePayment: {
  payingMenuOrderItems: { itemSales?: Maybe<ItemSales> }[];
  payingActivePlanChoices: { itemSales?: Maybe<ItemSales> }[];
  payingActivePlanOpenPriceMetas: { itemSales?: Maybe<ItemSales> }[];
}) => {
  const itemSales = [
    ...onSitePayment.payingMenuOrderItems.map((item) => item.itemSales),
    ...onSitePayment.payingActivePlanChoices.map((item) => item.itemSales),
    ...onSitePayment.payingActivePlanOpenPriceMetas.map((item) => item.itemSales),
  ].filter(isNotNullable);

  return {
    billingAmount: sumBy(itemSales, (item) => item.billingAmount),
    taxAmount: sumBy(itemSales, (item) => item.taxAmount),
  };
};
