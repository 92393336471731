import { useCallback } from "react";
import { groupBy } from "util/array";

import { FeatureFlagEnum } from "../../types/graphql";

import { useFeatureFlagQuery } from "./queries";

export const useFeatureFlag = () => {
  const { data, loading } = useFeatureFlagQuery({ fetchPolicy: "cache-and-network" });
  const featureFlagShops = groupBy(data?.featureFlagShop ?? [], (flag) => flag.shopId);

  const isFeatureAvailable = useCallback(
    ({ name, shopIds }: { name: FeatureFlagEnum; shopIds: string[] }) => {
      const now = Date.now();

      return shopIds.some((shopId) => {
        const featureFlags = featureFlagShops[shopId];

        if (!featureFlags) return false;

        const featureFlag = featureFlags.find((flag) => flag.name === name);

        if (!featureFlag) return false;

        const availableFrom = featureFlag.availableFrom
          ? new Date(featureFlag.availableFrom).getTime()
          : 0;
        const availableUntil = featureFlag.availableUntil
          ? new Date(featureFlag.availableUntil).getTime()
          : Infinity;

        return availableFrom <= now && now <= availableUntil;
      });
    },
    [featureFlagShops],
  );

  return { loading, isFeatureAvailable };
};
