import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterMenusByName } from "models/menu";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import { MenuTable } from "./MenuTable";
import {
  useWinboardGiftsDeleteWinboardMenuMutation,
  useWinboardGiftsGetMenusQuery,
  useWinboardGiftsGetShopsQuery,
} from "./queries";
import { Menu } from "./types";

export const filterMenus = (menus: Menu[], { name, shopId }: FilterConditions) => {
  const filteredMenus = menus.filter(
    (menu) =>
      shopId === undefined || menu.shopMenus.map(({ shop }) => shop.shopId).includes(shopId),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const WinboardGifts = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
    error: getMenusDataError,
  } = useWinboardGiftsGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData]);
  const menusWithWinboardMenu = menus.map((m) => ({ ...m, winboardMenu: m.winboardMenus?.[0] }));

  const { data: getShopsData } = useWinboardGiftsGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [deleteWinboardMenuMutation] = useWinboardGiftsDeleteWinboardMenuMutation();

  const onDelete = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await deleteWinboardMenuMutation({
          variables: { id },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchMenus();
    },
    [deleteWinboardMenuMutation, refetchMenus],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredMenus = useMemo(
    () => filterMenus(menusWithWinboardMenu, filterConditions),
    [menusWithWinboardMenu, filterConditions],
  );

  const shouldShowAlert = getMenusDataError;

  return (
    <DashboardLayout title="推しエール対象メニュー一覧">
      <PageHeader
        title="推しエール対象メニュー一覧"
        footer={
          <MenuFilter
            shops={shops}
            hasFilterConditions={hasFilterConditions}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            clearFilterConditions={clearFilterConditions}
          />
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <MenuTable menus={filteredMenus} loading={loadingMenus} onDelete={onDelete} />
    </DashboardLayout>
  );
};
