import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditInflowSourceTagPriorityList } from "pages/EditInflowSourceTagPriorities/EditInflowSourceTagList";
import {
  useEditInflowSourceTagPrioritiesGetInflowSourceTagsQuery,
  useEditInflowSourceTagPrioritiesInsertInflowSourceTagsMutation,
} from "pages/EditInflowSourceTagPriorities/queries";
import { InflowSourceTagInsertInput } from "types/graphql";

export const EditInflowSourceTagPriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getInflowSourceTagsData,
    loading: loadingInflowSourceTags,
    refetch: refetchInflowSourceTags,
    error,
  } = useEditInflowSourceTagPrioritiesGetInflowSourceTagsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const inflowSourceTags = getInflowSourceTagsData?.inflowSourceTag ?? [];

  const [insertInflowSourceTagsMutation, { loading: loadingInsertInflowSourceTags }] =
    useEditInflowSourceTagPrioritiesInsertInflowSourceTagsMutation();

  const onUpdate = useCallback(
    async (inflowSourceTags: InflowSourceTagInsertInput[]) => {
      try {
        await insertInflowSourceTagsMutation({
          variables: { inflowSourceTags },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchInflowSourceTags();
    },
    [insertInflowSourceTagsMutation, refetchInflowSourceTags],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout title="媒体の表示順を編集">
      <PageHeader title="媒体の表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditInflowSourceTagPriorityList
          loading={loadingInflowSourceTags || loadingInsertInflowSourceTags}
          inflowSourceTags={inflowSourceTags}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
