import { useCallback, useEffect, useMemo, useState } from "react";
import constate from "constate";

import { useCurrentUser } from "hooks/useUser";
import { logger } from "libs/logger";

export const [CorporationProvider, useCorporation] = constate(() => {
  const [corporation, setCorporation] = useState<{ corporationId: string } | null>(null);
  const currentUser = useCurrentUser();

  const handleSetCorporation = useCallback(
    async (corporationId?: string) => {
      if (corporationId && corporationId !== corporation?.corporationId) {
        logger.addContext({ key: "corporationId", value: corporationId });
        await setCorporation({ corporationId });
      }
    },
    [corporation?.corporationId],
  );

  useEffect(() => {
    if (!currentUser) {
      setCorporation(null);
      logger.addContext({ key: "corporationId", value: null });
    }
  }, [currentUser]);

  return useMemo(
    () => [corporation, handleSetCorporation] as const,
    [corporation, handleSetCorporation],
  );
});
