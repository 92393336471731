import React, { memo } from "react";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { Plan } from "pages/PlanGroupPlans/types";

type Props = {
  onRemove: (planId: number) => Promise<void>;
  loading?: boolean;
  plans: Plan[];
};

export const PlanTable = memo<Props>(({ loading, plans, onRemove }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "プラン名", dataIndex: "planName" },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { planId }: Plan) {
        return (
          <IconLink to={`/plan/${planId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { planId }: Plan) {
        return <DeleteIcon onClick={() => onRemove(planId)} />;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="planId"
      columns={columns}
      dataSource={plans}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
