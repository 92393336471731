import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { CategoriesField } from "pages/PlanCategories/AddPlanCategoryModal/AddCategoryForm/CategoriesField";
import {
  AddCategoryFormValues,
  useAddCategoryForm,
} from "pages/PlanCategories/AddPlanCategoryModal/AddCategoryForm/useAddCategoryForm";
import { Category, Plan } from "pages/PlanCategories/types";

type Props = {
  plan: Plan;
  categories: Category[];
  onChange: (plan: AddCategoryFormValues) => void;
};

export const AddCategoryForm = memo<Props>(({ plan, categories, onChange }) => {
  const { form, change: onValuesChange } = useAddCategoryForm(onChange);

  const planCategoriesIds = plan.planMenuCategories.map(({ category }) => category.categoryId);
  const filteredCategories = categories.filter(
    ({ categoryId }) => !planCategoriesIds.includes(categoryId),
  );

  return (
    <Form name="category" form={form} onValuesChange={onValuesChange} layout="vertical">
      <CategoriesField categories={filteredCategories} />
    </Form>
  );
});
