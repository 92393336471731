import React, { memo } from "react";
import { Tree } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Category } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/types";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = {
  categories: Category[];
} & Omit<FormItemProps, "children" | "name">;

export const MenusField = memo<Props>(({ categories, ...props }) => {
  const treeData = categories.map(({ categoryId, name: title, shopSideName, categoryMenus }) => ({
    key: `categoryId:${categoryId}`,
    title: shopSideName || title,
    disableCheckbox: false,
    selectable: false,
    children: categoryMenus.map(({ menu }) => ({
      key: `categoryId:${categoryId} menuId:${menu.menuId}`,
      title: menu.name,
      selectable: false,
    })),
  }));

  return (
    <EditMenuFormItem
      name="categoriesAndMenus"
      valuePropName="checkedKeys"
      trigger="onCheck"
      {...props}
    >
      <Tree treeData={treeData} checkable />
    </EditMenuFormItem>
  );
});
