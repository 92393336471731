import React, { CSSProperties } from "react";
import { Select as AntdSelect, SelectProps } from "antd";
import { SelectValue } from "antd/lib/select";

// NOTE: styled-components を使うと Select コンポーネントの Generics に型を与えられなくなるので CSSProperties を使う
const selectStyle: CSSProperties = {
  width: "100%",
};

const fullWidthStyle: CSSProperties = {
  display: "block",
};

type Props<T> = SelectProps<T> & {
  fullWidth?: boolean;
};

export const Select = <T extends SelectValue = SelectValue>({
  style,
  fullWidth = true,
  ...props
}: Props<T>) => (
  <AntdSelect
    style={{ ...selectStyle, ...(fullWidth ? fullWidthStyle : {}), ...style }}
    {...props}
  />
);
