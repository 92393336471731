import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import { FormItemProps } from "components/antd/Form";
import { ExternalLink } from "components/ExternalLink";
import { FormHelp } from "components/Form/FormHelp";
import { InputCode } from "components/Input/InputCode";
import { Spacer } from "components/Spacer";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

const StyledEditCashRegisterConfigFormItem = styled(EditCashRegisterConfigFormItem)`
  position: relative;
  &::before {
    content: "T";
    position: absolute;
    top: 32px;
    left: 4px;
  }
`;

const StyledInputCode = styled(InputCode)`
  width: calc(100% - 22px);
  margin-left: 22px;
`;

export const EligibleInvoiceIssuerRegistrationNumberField = memo<Props>((props) => (
  <Row>
    <Col span={12}>
      <StyledEditCashRegisterConfigFormItem
        label={
          <FormHelp
            label="適格請求書発行事業者の登録番号"
            help={
              <p>
                適格請求書等保存方式（インボイス制度）の登録事業者へ通知される登録番号をご入力ください（
                <ExternalLink
                  externalLinkUrl="https://www.invoice-kohyo.nta.go.jp/about-toroku/index.html"
                  text="詳しくはこちら"
                />
                ）
              </p>
            }
          />
        }
        name="eligibleInvoiceIssuerRegistrationNumber"
        labelSpacer={<Spacer size={8} />}
        wrapperCol={{ span: 20 }}
        rules={[
          {
            pattern: new RegExp(/^\d{13}$/),
            message: "13桁の数字で入力してください",
          },
        ]}
        validateTrigger="onBlur"
        {...props}
      >
        <StyledInputCode placeholder="1234567890123" />
      </StyledEditCashRegisterConfigFormItem>
    </Col>
  </Row>
));
