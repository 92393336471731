import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ServiceAdminsGetServiceAdmins = gql`
    query ServiceAdminsGetServiceAdmins {
  serviceAdmin {
    email
    serviceAdminId: id
  }
}
    `;
export type ServiceAdminsGetServiceAdminsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ServiceAdminsGetServiceAdminsQuery = (
  { __typename?: 'query_root' }
  & { serviceAdmin: Array<(
    { __typename?: 'serviceAdmin' }
    & Pick<Types.ServiceAdmin, 'email'>
    & { serviceAdminId: Types.ServiceAdmin['id'] }
  )> }
);


export const ServiceAdminsGetServiceAdminsDocument = gql`
    query ServiceAdminsGetServiceAdmins {
  serviceAdmin {
    email
    serviceAdminId: id
  }
}
    `;

/**
 * __useServiceAdminsGetServiceAdminsQuery__
 *
 * To run a query within a React component, call `useServiceAdminsGetServiceAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAdminsGetServiceAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAdminsGetServiceAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceAdminsGetServiceAdminsQuery(baseOptions?: Apollo.QueryHookOptions<ServiceAdminsGetServiceAdminsQuery, ServiceAdminsGetServiceAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceAdminsGetServiceAdminsQuery, ServiceAdminsGetServiceAdminsQueryVariables>(ServiceAdminsGetServiceAdminsDocument, options);
      }
export function useServiceAdminsGetServiceAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceAdminsGetServiceAdminsQuery, ServiceAdminsGetServiceAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceAdminsGetServiceAdminsQuery, ServiceAdminsGetServiceAdminsQueryVariables>(ServiceAdminsGetServiceAdminsDocument, options);
        }
export type ServiceAdminsGetServiceAdminsQueryHookResult = ReturnType<typeof useServiceAdminsGetServiceAdminsQuery>;
export type ServiceAdminsGetServiceAdminsLazyQueryHookResult = ReturnType<typeof useServiceAdminsGetServiceAdminsLazyQuery>;
export type ServiceAdminsGetServiceAdminsQueryResult = Apollo.QueryResult<ServiceAdminsGetServiceAdminsQuery, ServiceAdminsGetServiceAdminsQueryVariables>;