import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuHeaderGetBiMenus = gql`
    query MenuHeaderGetBIMenus($menuId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  pikaichiMenuMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isPikaichiEnabled) {
    pikaichiMenuId
  }
  tecAggregationMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isTecAggregationEnabled) {
    id
  }
  winboardMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isWinboardEnabled) {
    id
  }
}
    `;
export type MenuHeaderGetBiMenusQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  isWinboardEnabled: Types.Scalars['Boolean'];
  isPikaichiEnabled: Types.Scalars['Boolean'];
  isTecAggregationEnabled: Types.Scalars['Boolean'];
}>;


export type MenuHeaderGetBiMenusQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenuMenu?: Array<(
    { __typename?: 'pikaichiMenuMenu' }
    & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId'>
  )>, tecAggregationMenu?: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'id'>
  )>, winboardMenu?: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const MenuHeaderGetBiMenusDocument = gql`
    query MenuHeaderGetBIMenus($menuId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  pikaichiMenuMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isPikaichiEnabled) {
    pikaichiMenuId
  }
  tecAggregationMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isTecAggregationEnabled) {
    id
  }
  winboardMenu(where: {_menuId: {_eq: $menuId}}) @include(if: $isWinboardEnabled) {
    id
  }
}
    `;

/**
 * __useMenuHeaderGetBiMenusQuery__
 *
 * To run a query within a React component, call `useMenuHeaderGetBiMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuHeaderGetBiMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuHeaderGetBiMenusQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      isWinboardEnabled: // value for 'isWinboardEnabled'
 *      isPikaichiEnabled: // value for 'isPikaichiEnabled'
 *      isTecAggregationEnabled: // value for 'isTecAggregationEnabled'
 *   },
 * });
 */
export function useMenuHeaderGetBiMenusQuery(baseOptions: Apollo.QueryHookOptions<MenuHeaderGetBiMenusQuery, MenuHeaderGetBiMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuHeaderGetBiMenusQuery, MenuHeaderGetBiMenusQueryVariables>(MenuHeaderGetBiMenusDocument, options);
      }
export function useMenuHeaderGetBiMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuHeaderGetBiMenusQuery, MenuHeaderGetBiMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuHeaderGetBiMenusQuery, MenuHeaderGetBiMenusQueryVariables>(MenuHeaderGetBiMenusDocument, options);
        }
export type MenuHeaderGetBiMenusQueryHookResult = ReturnType<typeof useMenuHeaderGetBiMenusQuery>;
export type MenuHeaderGetBiMenusLazyQueryHookResult = ReturnType<typeof useMenuHeaderGetBiMenusLazyQuery>;
export type MenuHeaderGetBiMenusQueryResult = Apollo.QueryResult<MenuHeaderGetBiMenusQuery, MenuHeaderGetBiMenusQueryVariables>;