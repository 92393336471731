import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { getTypeName, isDisplayType } from "models/displayType";
import { getTaxMethodName } from "models/taxMethod";
import { formatPrice } from "util/price";
import { isNumber } from "util/type/primitive";

import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";

import {
  EditMenusBulkFormInitialValue,
  EditMenusBulkFormValues,
} from "../EditMenusBulkForm/useEditMenusBulkForm";

type Props = {
  targetValues: { [categoryMenuId: number]: EditMenusBulkFormValues };
  categoryMenuIdToInitialValueMap: Map<number, EditMenusBulkFormInitialValue>;
  loading: boolean;
  onSubmit: () => void;
};

export const EditMenusBulkConfirmModal = memo<Props>(
  ({ targetValues, loading, categoryMenuIdToInitialValueMap, onSubmit }: Props) => {
    const navigate = useNavigate();

    const targetCategoryMenus = useMemo(
      () =>
        Object.entries(targetValues).map(([categoryMenuIdString, value]) => ({
          categoryMenuId: Number(categoryMenuIdString),
          ...value,
        })),
      [targetValues],
    );

    const goBack = useCallback(() => {
      navigate(`/menu/edit/bulk`, { replace: true });
    }, [navigate]);

    const onOk = useCallback(() => {
      onSubmit();
      goBack();
    }, [goBack, onSubmit]);

    return (
      <Modal
        title="変更内容確認"
        visible
        width={3000}
        onCancel={goBack}
        footer={[
          <Button
            key="add"
            type="primary"
            onClick={onOk}
            loading={loading}
            disabled={targetCategoryMenus.length === 0}
          >
            更新
          </Button>,
          <Button key="cancel" onClick={goBack}>
            キャンセル
          </Button>,
        ]}
      >
        <Table
          bordered
          dataSource={targetCategoryMenus}
          columns={[
            {
              title: "画像",
              key: "imageUrl",
              dataIndex: "imageUrl",
              width: 100,
              render: (value: string | undefined) =>
                value && <Thumbnail url={value} width={100} height={100} />,
            },
            {
              title: "商品名",
              key: "name",
              width: 300,
              render: (_, { categoryMenuId }) => {
                const name = categoryMenuIdToInitialValueMap.get(categoryMenuId)?.name ?? "-";
                return name;
              },
            },
            {
              title: "カテゴリ名",
              key: "categoryName",
              width: 300,
              render: (_, { categoryMenuId }) => {
                const name =
                  categoryMenuIdToInitialValueMap.get(categoryMenuId)?.categoryName ?? "-";
                return name;
              },
            },
            {
              title: "画像の表示サイズ",
              dataIndex: "displayType",
              key: "displayType",
              width: 300,
              render: (value: string | undefined) =>
                value && isDisplayType(value) ? getTypeName(value) : "",
            },
            {
              title: "金額",
              dataIndex: "price",
              key: "price",
              width: 300,
              render: (value: number | undefined) => (isNumber(value) ? formatPrice(value) : ""),
            },
            {
              title: "税率",
              dataIndex: "taxRate",
              key: "taxRate",
              width: 150,
              render: (value: number | undefined) => value ?? "",
            },
            {
              title: "税種別",
              dataIndex: "taxMethod",
              key: "taxMethod",
              width: 150,
              render: (value: string | undefined) => (value ? getTaxMethodName(value) : ""),
            },
            {
              title: "原価",
              dataIndex: "costPrice",
              key: "costPrice",
              width: 300,
              render: (value: number | undefined) => (isNumber(value) ? formatPrice(value) : ""),
            },
            {
              title: "原価税率",
              dataIndex: "costTaxRate",
              key: "costTaxRate",
              width: 150,
              render: (value: number | undefined) => value ?? "",
            },
          ]}
        />
      </Modal>
    );
  },
);
