import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { Alert, message } from "antd";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { useCompany } from "hooks/useCompany";
import { WinboardMenuInsertInput } from "types/graphql";

import { EditOptionWinboardOptionFormValues } from "./EditOptionWinboardOptionForm/useEditOptionWinboardOptionForm";
import { EditOptionWinboardOptionForm } from "./EditOptionWinboardOptionForm";
import {
  EditOptionWinboardOptionGetOptionDocument,
  useEditOptionWinboardOptionGetCodeQuery,
  useEditOptionWinboardOptionGetOptionQuery,
  useEditOptionWinboardOptionUpdateChoicesMutation,
} from "./queries";

export const EditOptionWinboardOption = () => {
  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);
  const navigate = useNavigate();

  const {
    data: getOptionData,
    loading: loadingOption,
    error: errorGetOption,
  } = useEditOptionWinboardOptionGetOptionQuery(
    !Number.isNaN(optionId) ? { variables: { optionId } } : { skip: true },
  );
  const option = getOptionData?.option?.[0];

  const companyId = company?.id;

  const {
    data: getWinboardMenuCodesData,
    loading: loadingGetWinboardMenuCodes,
    error: errorGetWinboardMenuCodes,
  } = useEditOptionWinboardOptionGetCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updateWinboardChoicesMutation, { loading: loadingUpdateWinboardChoices }] =
    useEditOptionWinboardOptionUpdateChoicesMutation();

  const onSubmit = useCallback(
    async (input: EditOptionWinboardOptionFormValues) => {
      if (!company) return;

      const winboardChoicesDto: WinboardMenuInsertInput[] =
        input.option.choices.flatMap((inputChoice) => {
          const choice = option?.choices.find((c) => c.choiceId === inputChoice.choiceId);

          if (!choice) throw new Error("Choice not found");

          return inputChoice.winboardMenus?.flatMap((v) => {
            const id = v.id ?? uuidv4();

            const menu: WinboardMenuInsertInput = {
              id,
              choiceId: choice.id,
              _choiceId: choice.choiceId,
              companyId: company.id,
              name: v.name,
              code: v.code,
              bumonCode: v.bumonCode,
              bumonName: v.bumonName,
              categoryCode: v.categoryCode,
              categoryName: v.categoryName,
            };

            return menu;
          });
        }) ?? [];

      try {
        await updateWinboardChoicesMutation({
          variables: { winboardChoices: winboardChoicesDto },
          refetchQueries: [
            {
              query: EditOptionWinboardOptionGetOptionDocument,
              variables: { optionId },
            },
          ],
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, option?.choices, optionId, updateWinboardChoicesMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getWinboardMenuCodesData?.winboardMenu ?? [])
      .map((c) => (!isNaN(Number(c.code)) ? Number(c.code) : null))
      .filter(isNotNull);
    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getWinboardMenuCodesData?.winboardMenu]);

  const loading = loadingOption || loadingUpdateWinboardChoices || loadingGetWinboardMenuCodes;

  const shouldShowNetworkErrorAlert = Boolean(errorGetOption || errorGetWinboardMenuCodes);

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ウィンボードオプション" }],
      }}
    >
      <OptionHeader option={option ?? null} onBack={goBack} />
      {shouldShowNetworkErrorAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {option && (
        <EditOptionWinboardOptionForm
          option={option}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
