import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Card, Radio, Tooltip } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";

import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCorporation } from "hooks/useCorporation";

const ExplanationText = styled.span`
  font-size: 14px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const StyledRadio = styled(Radio)`
  line-height: 22px;
`;

export const AnalyticsSettings = () => {
  const [corporation] = useCorporation();

  const { analyticsSetting, updateAnalyticsSetting, loading } = useAnalyticsSetting({
    corporationId: corporation?.corporationId ?? null,
  });

  const [displayTaxIncluded, setDisplayTaxIncluded] = useState<boolean | null>(null);

  const handleReset = useCallback(() => {
    setDisplayTaxIncluded(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    const didSucceed = await updateAnalyticsSetting({
      displayTaxIncluded:
        displayTaxIncluded === null ? analyticsSetting.displayTaxIncluded : displayTaxIncluded,
    });

    if (didSucceed) {
      message.success("更新が成功しました");
      handleReset();
    } else {
      message.error("更新が失敗しました");
    }
  }, [analyticsSetting, displayTaxIncluded, updateAnalyticsSetting, handleReset]);

  const currentDisplayValues = useMemo(
    () => ({
      displayTaxIncluded:
        displayTaxIncluded === null ? analyticsSetting.displayTaxIncluded : displayTaxIncluded,
    }),
    [displayTaxIncluded, analyticsSetting],
  );

  const isValueUpdated =
    displayTaxIncluded !== null && displayTaxIncluded !== analyticsSetting.displayTaxIncluded;

  return (
    <>
      <DashboardLayout title="分析設定">
        <PageHeader title="分析設定" />
        <Spacer size={16} />
        <ExplanationText>各種分析ページでの表示設定を行うことができます。</ExplanationText>
        <Spacer size={16} />
        <Card>
          <label htmlFor="displayTaxIncluded">税表示</label>
          <Spacer inline width={4} />
          <Tooltip title="売上や客単価、原価などの税表示を設定します。">
            <QuestionCircleOutlined />
          </Tooltip>

          <Spacer size={5} />

          <StyledRadioGroup
            name="displayTaxIncluded"
            key={String(currentDisplayValues.displayTaxIncluded)}
            onChange={(e: RadioChangeEvent) =>
              setDisplayTaxIncluded(e.target.value === "showTaxIncluded")
            }
            defaultValue={
              currentDisplayValues.displayTaxIncluded ? "showTaxIncluded" : "showTaxExcluded"
            }
          >
            <StyledRadio value="showTaxExcluded">税抜</StyledRadio>
            <Spacer size={5} />
            <StyledRadio value="showTaxIncluded">税込</StyledRadio>
          </StyledRadioGroup>
        </Card>
        <Spacer size={10} />
        <FormActions>
          <Button onClick={handleReset} disabled={!isValueUpdated}>
            キャンセル
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={loading}
            disabled={!isValueUpdated}
          >
            更新
          </Button>
        </FormActions>
      </DashboardLayout>
    </>
  );
};
