import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { AllowChangeField } from "pages/EditOnSitePaymentDetailType/EditOnSitePaymentDetailTypeForm/AllowChangeField";
import { LabelField } from "pages/EditOnSitePaymentDetailType/EditOnSitePaymentDetailTypeForm/LabelField";
import { useEditOnSitePaymentDetailTypeForm } from "pages/EditOnSitePaymentDetailType/EditOnSitePaymentDetailTypeForm/useEditOnSitePaymentDetailTypeForm";
import { OnSitePaymentDetailType } from "pages/OnSitePaymentDetailTypes/types";
import { OnSitePaymentDetailTypeSetInput } from "types/graphql";

import { OnSitePaymentDetailTypeCategory } from "../types";

import { CategoryField } from "./CategoryField";

type Props = {
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSitePaymentDetailTypeCategories: OnSitePaymentDetailTypeCategory[];
  onSubmit: (onSitePaymentDetailType: OnSitePaymentDetailTypeSetInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditOnSitePaymentDetailTypeForm = memo<Props>(
  ({ onSitePaymentDetailType, onSitePaymentDetailTypeCategories, onClose, onSubmit, loading }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const { form, initialValues, submit } = useEditOnSitePaymentDetailTypeForm(
      onSitePaymentDetailType,
      onSubmit,
    );

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="onSitePaymentDetailType"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <LabelField />
            <CategoryField onSitePaymentDetailTypeCategories={onSitePaymentDetailTypeCategories} />
            {isFeatureEnabled("showAllowChangeField") && <AllowChangeField />}
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
