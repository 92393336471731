import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuPikaichiMenuFormItem } from "../useEditMenuPikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiCategoryNameField = memo<Props>((props) => (
  <EditMenuPikaichiMenuFormItem
    label="分類名"
    name="pikaichiCategoryName"
    rules={[{ max: 25, message: "25文字以下で入力してください" }]}
    {...props}
  >
    <Input />
  </EditMenuPikaichiMenuFormItem>
));
