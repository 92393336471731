import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { displayTypes } from "models/displayType";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { Category, Shop } from "pages/Menus/types";
import { DisplayTypeEnum } from "types/graphql";

export type FilterConditions = {
  shopIds?: string[];
  categoryIds?: number[];
  name?: string;
  displayTypes?: DisplayTypeEnum[];
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  shops: Shop[];
  categories: Category[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const MenuFilter = memo<Props>(
  ({
    shops,
    categories,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => (
    <Wrapper>
      <Col flex="1 0">
        <Select<string[]>
          showSearch
          allowClear
          mode="multiple"
          maxTagCount={5}
          placeholder="店舗"
          value={filterConditions.shopIds}
          onChange={(shopIds) => updateFilterCondition({ shopIds })}
          optionFilterProp="label"
          options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
        />
      </Col>
      <Col flex="1 0">
        <Input
          allowClear
          placeholder="メニュー名"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
        />
      </Col>
      <Col flex="1 0">
        <Select<number[]>
          mode="multiple"
          allowClear
          placeholder="カテゴリ"
          defaultValue={[]}
          value={filterConditions.categoryIds ?? []}
          onChange={(categoryIds) => updateFilterCondition({ categoryIds })}
          optionFilterProp="label"
          options={categories.map(({ categoryId, name, shopSideName }) => ({
            label: shopSideName || name,
            value: categoryId,
          }))}
        />
      </Col>
      <Col flex="1 0">
        <Select<DisplayTypeEnum[]>
          mode="multiple"
          allowClear
          placeholder="画像の表示サイズ"
          defaultValue={[]}
          value={filterConditions.displayTypes}
          onChange={(displayTypes) => updateFilterCondition({ displayTypes })}
          optionFilterProp="label"
          options={[
            { label: displayTypes.text, value: DisplayTypeEnum.Text },
            { label: displayTypes.small, value: DisplayTypeEnum.Small },
            { label: displayTypes.medium, value: DisplayTypeEnum.Medium },
            { label: displayTypes.large, value: DisplayTypeEnum.Large },
          ]}
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
