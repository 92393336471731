import React, { memo, useCallback, useState } from "react";
import { Button, Col, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { MenuInsertInput } from "types/graphql";

import { DescriptionField } from "./DescriptionField";
import { NameField } from "./NameField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { useAddGiftForm } from "./useAddGiftForm";

type Props = {
  loading: boolean;
  onSubmit: ({
    menu,
  }: {
    menu: Pick<MenuInsertInput, "name" | "description" | "imageUrl" | "receiptDisplayName">;
  }) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddGiftForm = memo<Props>(
  ({ onSubmit, loading, onClose, onFormValidationError }: Props) => {
    const { form, submit } = useAddGiftForm(onSubmit);

    const [uploadImage, setUploadImage] = useState("");

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit(uploadImage);
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit, uploadImage]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="gift" form={form} layout="vertical">
            <ImageField
              image={uploadImage}
              setUploadImage={setUploadImage}
              uploadImageApiKey="menu"
              formName="AddGiftForm"
              label="メニュー画像"
              isMovieAccepted
            />
            <Row>
              <Col span={12}>
                <NameField wrapperCol={{ span: 16 }} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ReceiptDisplayNameField wrapperCol={{ span: 16 }} />
              </Col>
            </Row>

            <DescriptionField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
