import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";

import { InflowSourceTagTable } from "./InflowSourceTagTable";
import {
  useInflowSourceTagsArchiveMutation,
  useInflowSourceTagsGetInflowSourceTagsQuery,
} from "./queries";

export const InflowSourceTags = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getInflowSourceTagsData,
    loading: loadingInflowSourceTags,
    refetch: refetchInflowSourceTags,
    error,
  } = useInflowSourceTagsGetInflowSourceTagsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const inflowSourceTags = getInflowSourceTagsData?.inflowSourceTag ?? [];

  const [archiveMutation] = useInflowSourceTagsArchiveMutation();

  const onArchive = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await archiveMutation({ variables: { id } });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchInflowSourceTags();
    },
    [archiveMutation, refetchInflowSourceTags],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="媒体一覧">
      <PageHeader
        title="媒体一覧"
        extra={[
          ...(canAccess("editInflowSourceTags")
            ? [
                <Link key="add" to="/inflowSourceTag/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []),
          ...(canAccess("editInflowSourceTagPriorities")
            ? [
                <Link key="priority" to="/inflowSourceTag/priority/edit">
                  <Button>表示順編集</Button>
                </Link>,
              ]
            : []),
        ]}
      />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <InflowSourceTagTable
        inflowSourceTags={inflowSourceTags}
        loading={loadingInflowSourceTags}
        onArchive={onArchive}
      />
    </DashboardLayout>
  );
};
