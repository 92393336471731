import React, { memo, useMemo } from "react";
import { Descriptions } from "antd";
import { isUrl } from "util/url";

type Props = {
  className?: string;
  onboardingHandout: string;
};

const emptyDescription = { label: "", value: "" };

export const OnboardingHandoutDescriptions = memo<Props>(({ className, onboardingHandout }) => {
  const descriptions = useMemo(
    () =>
      onboardingHandout
        .split("\n")
        .filter((value) => value.trim() !== "")
        .reduce((acc, current, index) => {
          const key = Math.floor(index / 2);
          const isLabel = index % 2 === 0;

          acc[key] = isLabel
            ? { ...emptyDescription, label: current }
            : { ...emptyDescription, ...acc[key], value: current };

          return [...acc];
        }, [] as { label: string; value: string }[]),
    [onboardingHandout],
  );
  const items = descriptions.map(({ label, value }) => ({
    key: [label, value].join("-"),
    label,
    children: isUrl(value) ? (
      <a href={value} rel="noopener noreferrer">
        {value}
      </a>
    ) : (
      value
    ),
  }));

  return <Descriptions className={className} column={1} items={items} />;
});
