import React, { useCallback, useMemo } from "react";
import { Alert, Button } from "antd";
import { filterMenusByName } from "models/menu";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import { MenuTable } from "./MenuTable";
import {
  useTecAggregationMenusGetCategoriesQuery,
  useTecAggregationMenusGetMenusQuery,
  useTecAggregationMenusGetShopsQuery,
} from "./queries";
import { Menu } from "./types";
import { useTecAggregationGetMasterCsv } from "./useTecAggregationGetMasterCsv";

export const filterMenus = (
  menus: Menu[],
  { categoryIds, name, shopId, showOnlyMenusWithoutCode }: FilterConditions,
) => {
  const filteredMenus = menus.filter(
    (menu) =>
      (categoryIds === undefined || categoryIds.includes(menu.category.categoryId)) &&
      (shopId === undefined || menu.shopMenus.map(({ shop }) => shop.shopId).includes(shopId)) &&
      (showOnlyMenusWithoutCode ? !menu.tecAggregationMenu : true),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const TecAggregationMenus = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { loading: loadingGetMasterCsv, submit: getMasterCsv } = useTecAggregationGetMasterCsv();
  const {
    data: getMenusData,
    loading: loadingMenus,
    error: getMenusDataError,
  } = useTecAggregationMenusGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menus = useMemo(
    () =>
      (getMenusData?.category.flatMap(({ categoryMenus }) => categoryMenus) ?? []).map(
        ({ __typename: _, menu, ...categoryMenu }) => ({
          ...menu,
          ...categoryMenu,
        }),
      ),
    [getMenusData?.category],
  );

  const { data: getCategoriesData, error: getCategoriesDataError } =
    useTecAggregationMenusGetCategoriesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = useTecAggregationMenusGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const onClickDownload = useCallback(async () => {
    try {
      await getMasterCsv();
    } catch (e) {
      message.error("ダウンロードに失敗しました");
    }
  }, [getMasterCsv]);

  const shouldShowAlert = getCategoriesDataError || getMenusDataError;

  return (
    <DashboardLayout title="メニュー一覧">
      <PageHeader
        title="メニュー一覧"
        footer={
          <>
            <MenuFilter
              shops={shops}
              categories={categories}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />

            <Button type="primary" loading={loadingGetMasterCsv} onClick={onClickDownload}>
              マスターダウンロード
            </Button>
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <MenuTable menus={filteredMenus} loading={loadingMenus} />
    </DashboardLayout>
  );
};
