import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardGiftsGetMenus = gql`
    query WinboardGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const WinboardGiftsGetShops = gql`
    query WinboardGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const WinboardGiftsDeleteWinboardMenu = gql`
    mutation WinboardGiftsDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardGiftsGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardGiftsGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'name'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'optionId' | 'name'>
      ) }
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type WinboardGiftsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardGiftsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type WinboardGiftsDeleteWinboardMenuMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type WinboardGiftsDeleteWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_winboardMenu_by_pk?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const WinboardGiftsGetMenusDocument = gql`
    query WinboardGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useWinboardGiftsGetMenusQuery__
 *
 * To run a query within a React component, call `useWinboardGiftsGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardGiftsGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardGiftsGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardGiftsGetMenusQuery(baseOptions: Apollo.QueryHookOptions<WinboardGiftsGetMenusQuery, WinboardGiftsGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardGiftsGetMenusQuery, WinboardGiftsGetMenusQueryVariables>(WinboardGiftsGetMenusDocument, options);
      }
export function useWinboardGiftsGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardGiftsGetMenusQuery, WinboardGiftsGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardGiftsGetMenusQuery, WinboardGiftsGetMenusQueryVariables>(WinboardGiftsGetMenusDocument, options);
        }
export type WinboardGiftsGetMenusQueryHookResult = ReturnType<typeof useWinboardGiftsGetMenusQuery>;
export type WinboardGiftsGetMenusLazyQueryHookResult = ReturnType<typeof useWinboardGiftsGetMenusLazyQuery>;
export type WinboardGiftsGetMenusQueryResult = Apollo.QueryResult<WinboardGiftsGetMenusQuery, WinboardGiftsGetMenusQueryVariables>;
export const WinboardGiftsGetShopsDocument = gql`
    query WinboardGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useWinboardGiftsGetShopsQuery__
 *
 * To run a query within a React component, call `useWinboardGiftsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardGiftsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardGiftsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardGiftsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<WinboardGiftsGetShopsQuery, WinboardGiftsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardGiftsGetShopsQuery, WinboardGiftsGetShopsQueryVariables>(WinboardGiftsGetShopsDocument, options);
      }
export function useWinboardGiftsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardGiftsGetShopsQuery, WinboardGiftsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardGiftsGetShopsQuery, WinboardGiftsGetShopsQueryVariables>(WinboardGiftsGetShopsDocument, options);
        }
export type WinboardGiftsGetShopsQueryHookResult = ReturnType<typeof useWinboardGiftsGetShopsQuery>;
export type WinboardGiftsGetShopsLazyQueryHookResult = ReturnType<typeof useWinboardGiftsGetShopsLazyQuery>;
export type WinboardGiftsGetShopsQueryResult = Apollo.QueryResult<WinboardGiftsGetShopsQuery, WinboardGiftsGetShopsQueryVariables>;
export const WinboardGiftsDeleteWinboardMenuDocument = gql`
    mutation WinboardGiftsDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardGiftsDeleteWinboardMenuMutationFn = Apollo.MutationFunction<WinboardGiftsDeleteWinboardMenuMutation, WinboardGiftsDeleteWinboardMenuMutationVariables>;

/**
 * __useWinboardGiftsDeleteWinboardMenuMutation__
 *
 * To run a mutation, you first call `useWinboardGiftsDeleteWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWinboardGiftsDeleteWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [winboardGiftsDeleteWinboardMenuMutation, { data, loading, error }] = useWinboardGiftsDeleteWinboardMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWinboardGiftsDeleteWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<WinboardGiftsDeleteWinboardMenuMutation, WinboardGiftsDeleteWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WinboardGiftsDeleteWinboardMenuMutation, WinboardGiftsDeleteWinboardMenuMutationVariables>(WinboardGiftsDeleteWinboardMenuDocument, options);
      }
export type WinboardGiftsDeleteWinboardMenuMutationHookResult = ReturnType<typeof useWinboardGiftsDeleteWinboardMenuMutation>;
export type WinboardGiftsDeleteWinboardMenuMutationResult = Apollo.MutationResult<WinboardGiftsDeleteWinboardMenuMutation>;
export type WinboardGiftsDeleteWinboardMenuMutationOptions = Apollo.BaseMutationOptions<WinboardGiftsDeleteWinboardMenuMutation, WinboardGiftsDeleteWinboardMenuMutationVariables>;