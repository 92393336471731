import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";

import { OnSitePaymentDetailTypeTable } from "./OnSitePaymentDetailTypeTable";
import {
  useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation,
  useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery,
  useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation,
} from "./queries";

export const FoodingJournalOnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getOnSitePaymentDetailTypesData,
    loading: loadingOnSitePaymentDetailTypes,
    error: getOnSitePaymentDetailTypesError,
    refetch,
  } = useFoodingJournalOnSitePaymentDetailTypesGetTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDetailTypes = useMemo(
    () => getOnSitePaymentDetailTypesData?.onSitePaymentDetailType ?? [],
    [getOnSitePaymentDetailTypesData?.onSitePaymentDetailType],
  );

  const [
    upsertFoodingJournalOnSitePaymentDetailTypes,
    { loading: loadingUpsertTypes, error: upsertTypesError },
  ] = useFoodingJournalOnSitePaymentDetailTypeUpsertTypesMutation();

  const [
    deleteFoodingJournalOnSitePaymentDetailType,
    {
      loading: loadingDeleteFoodingJournalOnSitePaymentDetailType,
      error: deleteFoodingJournalOnSitePaymentDetailTypeError,
    },
  ] = useFoodingJournalOnSitePaymentDetailTypesDeleteTypeMutation();

  const refetchTypes = useCallback(async () => {
    if (!companyId) return;

    await refetch({ companyId });
  }, [companyId, refetch]);

  const upsertTypes = useCallback(
    async ({
      types,
    }: {
      types: {
        onSitePaymentDetailTypeId: string;
        foodingJournalOnSitePaymentDetailTypeId: string | undefined;
        paymentType: number;
        code: string;
        name: string;
      }[];
    }) => {
      if (!companyId) return;

      try {
        await upsertFoodingJournalOnSitePaymentDetailTypes({
          variables: {
            input: types.map((type) => ({
              companyId,
              id: type.foodingJournalOnSitePaymentDetailTypeId,
              onSitePaymentDetailTypeId: type.onSitePaymentDetailTypeId,
              paymentType: type.paymentType,
              code: type.code,
              name: type.name,
            })),
          },
        });
        message.success("更新に成功しました");
      } catch {
        message.error("更新に失敗しました");
      }
    },
    [companyId, upsertFoodingJournalOnSitePaymentDetailTypes],
  );

  const deleteType = useCallback(
    async ({
      foodingJournalOnSitePaymentDetailTypeId,
    }: {
      foodingJournalOnSitePaymentDetailTypeId: string;
    }) => {
      try {
        await deleteFoodingJournalOnSitePaymentDetailType({
          variables: { foodingJournalOnSitePaymentDetailTypeId },
        });
        message.success("設定を削除しました");
      } catch {
        message.error("設定の削除に失敗しました");
      }
    },
    [deleteFoodingJournalOnSitePaymentDetailType],
  );

  const shouldShowAlert =
    getOnSitePaymentDetailTypesError &&
    deleteFoodingJournalOnSitePaymentDetailTypeError &&
    upsertTypesError;

  const loading =
    loadingOnSitePaymentDetailTypes ||
    loadingDeleteFoodingJournalOnSitePaymentDetailType ||
    loadingUpsertTypes;

  return (
    <DashboardLayout title="会計種別設定一覧">
      <PageHeader title="会計種別設定一覧" />

      {shouldShowAlert && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />

          <Spacer height={20} />
        </>
      )}

      <OnSitePaymentDetailTypeTable
        onSitePaymentDetailTypes={onSitePaymentDetailTypes}
        refetchTypes={refetchTypes}
        upsertFoodingJournalOnSitePaymentDetailTypes={upsertTypes}
        deleteFoodingJournalOnSitePaymentDetailType={deleteType}
        loading={loading}
      />
    </DashboardLayout>
  );
};
