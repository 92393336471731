import React, { memo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { colors, grey, oldColors } from "constants/colors";

import { RecentVisitedTableUserReport } from "../type";

import { TableUserCustomerAvatars } from "./TableUserCustomerAvatars";

const RECENT_VISITED_TABLE_USERS_REPORT_PANEL_HEIGHT = 142;

const Panel = styled.div`
  display: flex;
  width: 100%;
  height: ${RECENT_VISITED_TABLE_USERS_REPORT_PANEL_HEIGHT}px;
  gap: 8px;
  overflow: scroll;
  padding-bottom: 3px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PanelItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  gap: 2px;
  min-width: 155px;
  background: ${grey[0]};
  border-radius: 6px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
  will-change: filter;
`;

const TextBase = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DateText = styled(TextBase)`
  color: ${colors.Text.Secondary};
  font-size: 12px;
`;

const NameText = styled(TextBase)`
  font-weight: 700;
`;

const VisitedCountText = styled(TextBase)``;

const SalesText = styled(TextBase)`
  font-weight: 700;
  color: ${oldColors.Orange};
`;

const formatter = new Intl.NumberFormat("ja");

type Props = {
  recentVisitedTableUserReport: RecentVisitedTableUserReport | undefined;
};

export const RecentVisitedTableUsersReportPanel = memo<Props>(
  ({ recentVisitedTableUserReport }) => {
    if (!recentVisitedTableUserReport) {
      return <Loading height={RECENT_VISITED_TABLE_USERS_REPORT_PANEL_HEIGHT} />;
    }

    return (
      <Panel>
        {recentVisitedTableUserReport.recentVisitedTableUsers.map(
          (
            { customerName, customerProfileImages, visitedAt, visitedCount, sales, numPeople },
            i,
          ) => (
            <PanelItem key={i}>
              <TableUserCustomerAvatars tableUserCustomerProfileImages={customerProfileImages} />
              <Spacer size={2} />
              <DateText>{dayjs(visitedAt).format("YYYY/MM/DD")}</DateText>
              <NameText>{customerName}</NameText>
              <VisitedCountText>{visitedCount}回目来店</VisitedCountText>
              <SalesText>
                ¥{formatter.format(sales)} / {numPeople}名
              </SalesText>
            </PanelItem>
          ),
        )}
      </Panel>
    );
  },
);
