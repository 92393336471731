import React, { memo } from "react";
import styled from "styled-components";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { OnSitePaymentDetailType } from "../types";

import { PikaichiMediaCdField } from "./PikaichiPaymentMediaCdField";
import { PikaichiMediaNameField } from "./PikaichiPaymentMediaNameField";
import { PikaichiUchiwakeCdField } from "./PikaichiPaymentUchiwakeCdField";
import { PikaichiUchiwakeNameField } from "./PikaichiPaymentUchiwakeNameField";
import { useEditPikaichiOnSitePaymentDetailTypeForm } from "./useEditPikaichiOnSitePaymentDetailTypeForm";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

type Props = {
  onSitePaymentDetailType: OnSitePaymentDetailType;
  onSubmit: (
    args: Omit<
      PikaichiOnSitePaymentDetailTypeInsertInput,
      "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
    >,
  ) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPikaichiOnSitePaymentDetailForm = memo<Props>(
  ({ onSitePaymentDetailType, onClose, onSubmit, loading }) => {
    const pikaichiOnSitePaymentDetailType =
      onSitePaymentDetailType.pikaichiOnSitePaymentDetailTypes[0];

    const { form, initialValues, submit } = useEditPikaichiOnSitePaymentDetailTypeForm({
      pikaichiDetailType: pikaichiOnSitePaymentDetailType,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <StyledForm
            name="pikaichiOnSitePaymentDetailType"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <PikaichiMediaCdField />
            <PikaichiMediaNameField />
            <PikaichiUchiwakeCdField />
            <PikaichiUchiwakeNameField />
          </StyledForm>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
