import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterMenusByName } from "models/menu";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import {
  MenuTable,
  MenuWithFoodingJournalMenu,
  NonNullableFoodingJournalMenuSource,
} from "./MenuTable";
import {
  useFoodingJournalMenuDeleteMenuMutation,
  useFoodingJournalMenusGetCategoriesQuery,
  useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery,
  useFoodingJournalMenusGetMastersQuery,
  useFoodingJournalMenusGetMenusQuery,
  useFoodingJournalMenusGetShopsQuery,
  useFoodingJournalMenusUpsertMenusMutation,
} from "./queries";
import { Menu } from "./types";

export const filterMenus = (
  menus: (MenuWithFoodingJournalMenu & { shopMenus: Menu["shopMenus"] })[],
  { categoryIds, name, shopId, showOnlyMenusWithoutCode }: FilterConditions,
) => {
  const filteredMenus = menus.filter(
    (menu) =>
      (categoryIds === undefined || categoryIds.includes(menu.category.categoryId)) &&
      (shopId === undefined || menu.shopMenus.some(({ shop }) => shop.shopId === shopId)) &&
      (showOnlyMenusWithoutCode ? !menu.code : true),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const FoodingJournalMenus = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const {
    data: getMenusData,
    loading: loadingMenus,
    error: getMenusDataError,
    refetch: refetchFoodingJournalMenus,
  } = useFoodingJournalMenusGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const menus = useMemo<(MenuWithFoodingJournalMenu & { shopMenus: Menu["shopMenus"] })[]>(
    () =>
      (getMenusData?.category.flatMap(({ categoryMenus }) => categoryMenus) ?? [])
        .map(({ __typename: _, menu, ...categoryMenu }) => ({
          ...menu,
          ...categoryMenu,
        }))
        .map((menu) => ({
          type: "menu",
          menuId: menu.menuId,
          category: menu.category,
          name: menu.name,
          foodingJournalMenuId: menu.foodingJournalMenu?.id,
          code: menu.foodingJournalMenu?.code,
          foodingJournalName: menu.foodingJournalMenu?.name,
          foodingJournalDepartmentId: menu.foodingJournalMenu?.foodingJournalDepartmentMaster.id,
          foodingJournalGroupId: menu.foodingJournalMenu?.foodingJournalGroupMaster.id,
          shopMenus: menu.shopMenus,
          menuOptions: menu.menuOptions,
          choices: menu.menuOptions.flatMap(({ option }) =>
            option.choices.map((choice) => ({
              type: "choice",
              menuId: menu.menuId,
              optionId: option.optionId,
              choiceId: choice.choiceId,
              category: menu.category,
              name: `${option.name} ${choice.name}`,
              foodingJournalMenuId: choice.foodingJournalMenu?.id,
              code: choice.foodingJournalMenu?.code,
              foodingJournalName: choice.foodingJournalMenu?.name,
              foodingJournalDepartmentId:
                choice.foodingJournalMenu?.foodingJournalDepartmentMaster.id,
              foodingJournalGroupId: choice.foodingJournalMenu?.foodingJournalGroupMaster.id,
            })),
          ),
        })),
    [getMenusData?.category],
  );
  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const { data: getCategoriesData, error: getCategoriesDataError } =
    useFoodingJournalMenusGetCategoriesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = useFoodingJournalMenusGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const { data: getMastersData, error: getMastersError } = useFoodingJournalMenusGetMastersQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const foodingJournalDepartmentMasters = useMemo(
    () =>
      [...(getMastersData?.foodingJournalDepartmentMaster ?? [])].sort((a, b) =>
        a.code.localeCompare(b.code),
      ),
    [getMastersData?.foodingJournalDepartmentMaster],
  );
  const foodingJournalGroupMasters = useMemo(
    () =>
      [...(getMastersData?.foodingJournalGroupMaster ?? [])].sort((a, b) =>
        a.code.localeCompare(b.code),
      ),
    [getMastersData?.foodingJournalGroupMaster],
  );

  const { data: getPlansData } = useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menusAndPlans = useMemo(
    () => [
      ...menus.flatMap((menu) => [
        {
          code: menu.code,
          foodingJournalName: menu.foodingJournalName,
          foodingJournalMenuId: menu.foodingJournalMenuId,
        },
        ...menu.choices.map((choice) => ({
          code: choice.code,
          foodingJournalName: choice.foodingJournalName,
          foodingJournalMenuId: choice.foodingJournalMenuId,
        })),
      ]),
      ...(getPlansData?.foodingJournalMenu ?? []).map((plan) => ({
        code: plan.code,
        foodingJournalName: plan.name,
        foodingJournalMenuId: plan.id,
      })),
    ],
    [menus, getPlansData],
  );

  const [upsertMenus, { loading: upsertMenusLoading, error: upsertMenusError }] =
    useFoodingJournalMenusUpsertMenusMutation();

  const [deleteMenuFunc, { loading: deleteMenuLoading, error: deleteMenuError }] =
    useFoodingJournalMenuDeleteMenuMutation();

  const refetchMenus = useCallback(async () => {
    if (!companyId) return;

    await refetchFoodingJournalMenus({ companyId });
  }, [companyId, refetchFoodingJournalMenus]);

  const upsertCodes = useCallback(
    async ({
      menuCodes,
      choiceCodes,
    }: {
      menuCodes: (NonNullableFoodingJournalMenuSource & { menuId: string })[];
      choiceCodes: (NonNullableFoodingJournalMenuSource & { choiceId: string })[];
    }) => {
      if (!companyId) return;

      try {
        await upsertMenus({
          variables: {
            menusInput: menuCodes.map((code) => ({
              companyId,
              menuId: code.menuId,
              code: code.code,
              name: code.foodingJournalName,
              foodingJournalDepartmentId: code.foodingJournalDepartmentId,
              foodingJournalGroupId: code.foodingJournalGroupId,
            })),
            choicesInput: choiceCodes.map((code) => ({
              companyId,
              choiceId: code.choiceId,
              code: code.code,
              name: code.foodingJournalName,
              foodingJournalDepartmentId: code.foodingJournalDepartmentId,
              foodingJournalGroupId: code.foodingJournalGroupId,
            })),
          },
        });

        message.success("更新に成功しました");
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [companyId, upsertMenus],
  );

  const deleteMenu = useCallback(
    async ({ foodingJournalMenuId }: { foodingJournalMenuId: string }) => {
      try {
        await deleteMenuFunc({
          variables: { foodingJournalMenuId },
        });

        message.success("設定の削除に成功しました");
      } catch (e) {
        message.error("設定の削除に失敗しました");
      }
    },
    [deleteMenuFunc],
  );

  const shouldShowAlert =
    getCategoriesDataError ||
    getMenusDataError ||
    getMastersError ||
    upsertMenusError ||
    deleteMenuError;

  const loading = loadingMenus || upsertMenusLoading || deleteMenuLoading;

  return (
    <DashboardLayout title="メニュー一覧">
      <PageHeader
        title="メニュー一覧"
        footer={
          <>
            <MenuFilter
              shops={shops}
              categories={categories}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />

      {shouldShowAlert && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />

          <Spacer height={20} />
        </>
      )}

      <MenuTable
        menus={filteredMenus}
        foodingJournalDepartmentMasters={foodingJournalDepartmentMasters}
        foodingJournalGroupMasters={foodingJournalGroupMasters}
        menusAndPlans={menusAndPlans}
        loading={loading}
        upsertCodes={upsertCodes}
        refetchMenus={refetchMenus}
        deleteFoodingJournalMenu={deleteMenu}
      />
    </DashboardLayout>
  );
};
