import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type CopyMasterDataFormValues = {
  sourceShopId: string | null;
  targetCompanyId: string | null;
  targetShopId: string | null;
  enableCopyTable: boolean;
};

const getInitialValues = (): CopyMasterDataFormValues => ({
  sourceShopId: null,
  targetCompanyId: null,
  targetShopId: null,
  enableCopyTable: false,
});

export const CopyMasterDataFormItem = createFormItem<CopyMasterDataFormValues>();

export const useCopyMasterDataForm = (
  onChange: (formValues: CopyMasterDataFormValues) => void,
  onSubmit: (formValues: CopyMasterDataFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (changedValues: Partial<CopyMasterDataFormValues>, values: CopyMasterDataFormValues) => {
      if ("targetCompanyId" in changedValues) {
        form.setFields([{ name: "targetShopId", value: null }]);
        onChange({ ...values, targetShopId: null });
        return;
      }

      onChange(values);
    },
    [form, onChange],
  );

  const submit = useCallback(async () => {
    await form.validateFields();
    const formValues = form.getFieldsValue() as CopyMasterDataFormValues;
    onSubmit(formValues);
  }, [form, onSubmit]);

  return { form, initialValues, change, submit };
};
