import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { CheckboxRef } from "antd";

const Wrapper = styled.span`
  margin-right: 8px;
  margin-left: 8px;
  width: 90px;
  height: 32px;
`;

type Props = {
  checkboxRef: React.RefObject<CheckboxRef>;
};

export const CountFieldWrapper = ({ checkboxRef, children }: React.PropsWithChildren<Props>) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      if (!checkboxRef.current?.input) {
        return;
      }

      if (checkboxRef.current.input.checked) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [checkboxRef],
  );

  return <Wrapper onClick={handleClick}>{children}</Wrapper>;
};

CountFieldWrapper.useRef = () => useRef<CheckboxRef>(null);
