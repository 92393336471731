import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { EmailField } from "pages/AddServiceAdmin/AddServiceAdminForm/EmailField";
import { PasswordField } from "pages/AddServiceAdmin/AddServiceAdminForm/PasswordField";
import { useAddServiceAdminForm } from "pages/AddServiceAdmin/AddServiceAdminForm/useAddServiceAdminForm";
import { CreateServiceAdminInput } from "types/graphql";

type Props = {
  onSubmit: (serviceAdmin: CreateServiceAdminInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddServiceAdminForm = memo<Props>(({ onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useAddServiceAdminForm(onSubmit);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="serviceAdmin" form={form} initialValues={initialValues} layout="vertical">
          <EmailField />
          <PasswordField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          登録
        </Button>
      </FormActions>
    </>
  );
});
