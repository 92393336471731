import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ExportDailyAggregatedDataGetShops = gql`
    query ExportDailyAggregatedDataGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
        useKd
      }
    }
  }
}
    `;
export type ExportDailyAggregatedDataGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type ExportDailyAggregatedDataGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name' | 'useKd'>
      )> }
    )> }
  )> }
);


export const ExportDailyAggregatedDataGetShopsDocument = gql`
    query ExportDailyAggregatedDataGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
        useKd
      }
    }
  }
}
    `;

/**
 * __useExportDailyAggregatedDataGetShopsQuery__
 *
 * To run a query within a React component, call `useExportDailyAggregatedDataGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDailyAggregatedDataGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDailyAggregatedDataGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useExportDailyAggregatedDataGetShopsQuery(baseOptions: Apollo.QueryHookOptions<ExportDailyAggregatedDataGetShopsQuery, ExportDailyAggregatedDataGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportDailyAggregatedDataGetShopsQuery, ExportDailyAggregatedDataGetShopsQueryVariables>(ExportDailyAggregatedDataGetShopsDocument, options);
      }
export function useExportDailyAggregatedDataGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportDailyAggregatedDataGetShopsQuery, ExportDailyAggregatedDataGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportDailyAggregatedDataGetShopsQuery, ExportDailyAggregatedDataGetShopsQueryVariables>(ExportDailyAggregatedDataGetShopsDocument, options);
        }
export type ExportDailyAggregatedDataGetShopsQueryHookResult = ReturnType<typeof useExportDailyAggregatedDataGetShopsQuery>;
export type ExportDailyAggregatedDataGetShopsLazyQueryHookResult = ReturnType<typeof useExportDailyAggregatedDataGetShopsLazyQuery>;
export type ExportDailyAggregatedDataGetShopsQueryResult = Apollo.QueryResult<ExportDailyAggregatedDataGetShopsQuery, ExportDailyAggregatedDataGetShopsQueryVariables>;