import { useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

export const useLocationChange = (callback: (location: Location) => void) => {
  const location = useLocation();
  const callbackRef = useRef<((location: Location) => void) | null>(null);

  callbackRef.current = callback;

  useEffect(() => callbackRef.current?.(location), [location]);
};
