import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { EditConnectGameConfigFormValues } from "./EditConnectGameConfigForm/useEditConnectGameConfigForm";
import { EditConnectGameConfigForm } from "./EditConnectGameConfigForm";
import {
  useEditConnectGameConfigGetConnectGameConfigQuery,
  useEditConnectGameConfigGetConnectGameConfigShopsQuery,
  useEditConnectGameConfigGetCouponsQuery,
  useEditConnectGameConfigGetShopsQuery,
  useEditConnectGameConfigUpdateConnectGameConfigMutation,
} from "./queries";

export const EditConnectGameConfig = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { id: connectGameConfigId } = useParams<{ id: string }>();

  const {
    data: getConnectGameConfigData,
    loading: loadingConnectGameConfigData,
    error: getConnectGameConfigError,
  } = useEditConnectGameConfigGetConnectGameConfigQuery(
    companyId && connectGameConfigId
      ? { variables: { id: connectGameConfigId, companyId } }
      : { skip: true },
  );
  const connectGameConfig = useMemo(
    () => getConnectGameConfigData?.connectGameConfig[0],
    [getConnectGameConfigData],
  );

  const {
    data: getCouponsData,
    loading: loadingCouponsData,
    error: getCouponsError,
  } = useEditConnectGameConfigGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = useMemo(() => getCouponsData?.coupon ?? [], [getCouponsData]);

  const {
    data: getShopsData,
    loading: loadingShopsData,
    error: getShopsError,
  } = useEditConnectGameConfigGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData]);

  const {
    data: getConnectGameConfigGetConnectGameConfigShopsData,
    loading: loadingConnectGameConfigGetConnectGameConfigShopsData,
    error: getConnectGameConfigGetConnectGameConfigShopsError,
  } = useEditConnectGameConfigGetConnectGameConfigShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const connectGameConfigShopsInCompany = useMemo(
    () =>
      getConnectGameConfigGetConnectGameConfigShopsData?.connectGameConfig
        .filter(({ id }) => id !== connectGameConfigId)
        .flatMap(({ connectGameConfigShops }) => connectGameConfigShops) ?? [],
    [connectGameConfigId, getConnectGameConfigGetConnectGameConfigShopsData?.connectGameConfig],
  );

  const [updateConnectGameConfig] = useEditConnectGameConfigUpdateConnectGameConfigMutation();

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({
      connectGameConfig: formValues,
    }: {
      connectGameConfig: EditConnectGameConfigFormValues;
    }) => {
      try {
        if (!connectGameConfigId || !companyId) return;

        const {
          enabled,
          startImageUrl,
          startTime,
          endTime,
          prizeCouponId,
          consolationCouponId: consolationCouponIdValue,
          shopIds,
          maxWinningPercentageType,
          selectedMaxWinningPercentage,
          enteredMaxWinningPercentage,
          consolationType,
        } = formValues;

        const consolationCouponId =
          consolationType === "coupon" ? consolationCouponIdValue ?? null : null;
        const maxWinningRate =
          (maxWinningPercentageType === "select"
            ? selectedMaxWinningPercentage ?? 0
            : maxWinningPercentageType === "custom"
            ? enteredMaxWinningPercentage ?? 0
            : 0) / 100;

        const updatedConnectGameConfig = {
          consolationCouponId,
          enabled,
          endTime: endTime.format("HH:mm:00"),
          maxWinningRate,
          prizeCouponId,
          startImageUrl,
          startTime: startTime.format("HH:mm:00"),
        };

        await updateConnectGameConfig({
          variables: {
            id: connectGameConfigId,
            connectGameConfig: updatedConnectGameConfig,
            deletedShopIds:
              connectGameConfig?.connectGameConfigShops
                .filter(({ shop }) => !shopIds.includes(shop.id))
                .map(({ shop }) => shop.id) ?? [],
            connectGameConfigShops: shopIds.map((shopId) => ({
              connectGameConfigId,
              shopId,
            })),
          },
        });

        message.success("更新しました");

        navigate("/connectGameConfig", { replace: true });
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [
      companyId,
      connectGameConfig?.connectGameConfigShops,
      connectGameConfigId,
      navigate,
      updateConnectGameConfig,
    ],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const onImageFieldValidationError = useCallback((e: Error) => {
    message.error(e.message);
  }, []);

  const loading =
    loadingConnectGameConfigData ||
    loadingCouponsData ||
    loadingShopsData ||
    loadingConnectGameConfigGetConnectGameConfigShopsData ||
    submitting;
  const shouldShowAlert =
    getConnectGameConfigError ||
    getCouponsError ||
    getShopsError ||
    getConnectGameConfigGetConnectGameConfigShopsError;

  return (
    <DashboardLayout title="ミニゲームを設定">
      <PageHeader title="ミニゲームを設定" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {connectGameConfig && (
        <EditConnectGameConfigForm
          loading={loading}
          connectGameConfig={connectGameConfig}
          connectGameConfigShopsInCompany={connectGameConfigShopsInCompany}
          coupons={coupons}
          shops={shops}
          onFormValidationError={onFormValidationError}
          onImageFieldValidationError={onImageFieldValidationError}
          onSubmit={onSubmit}
          onCancel={goBack}
        />
      )}
    </DashboardLayout>
  );
};
