import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { ConnectGameConfigShop, Coupon, Shop } from "../types";

import { ConsolationField } from "./ConsolationField";
import { EnabledField } from "./EnabledField";
import { MaxWinningPercentageField } from "./MaxWinningPercentageField";
import { PrizeCouponField } from "./PrizeCouponField";
import { ShopsField } from "./ShopsField";
import { TimeTermField } from "./TimeTermField";
import {
  AddConnectGameConfigFormValues,
  useAddConnectGameConfigForm,
} from "./useAddConnectGameConfigForm";
import { WinningPercentageCard } from "./WinningPercentageCard";

const Description = styled.p`
  font-size: 14px;
  color: ${colors.Text.Secondary};
  margin: 0;
`;

type Props = {
  loading: boolean;
  coupons: Coupon[];
  shops: Shop[];
  connectGameConfigShopsInCompany: ConnectGameConfigShop[];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onImageFieldValidationError: (e: Error) => void;
  onSubmit: ({ connectGameConfig }: { connectGameConfig: AddConnectGameConfigFormValues }) => void;
  onCancel: () => void;
};

export const AddConnectGameConfigForm = memo(
  ({
    loading,
    coupons,
    shops,
    connectGameConfigShopsInCompany,
    onFormValidationError,
    onImageFieldValidationError,
    onSubmit,
    onCancel,
  }: Props) => {
    const { form, initialValues, submit } = useAddConnectGameConfigForm({ onSubmit });
    const [uploadImage, setUploadImage] = useState<string | null>(null);

    const handleSubmit = useCallback(async () => {
      try {
        if (!uploadImage) throw new Error("スタート画面の画像を設定してください");
        await form.validateFields();
        submit({ startImageUrl: uploadImage });
      } catch (e) {
        if (e instanceof Error) onImageFieldValidationError(e);
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, uploadImage, onFormValidationError, onImageFieldValidationError, submit]);

    const handleCancel = useCallback(() => {
      onCancel();
    }, [onCancel]);

    return (
      <Form name="connectGameConfig" form={form} initialValues={initialValues} layout="vertical">
        <FormSection title="1. 利用するミニゲームと店舗を設定しましょう">
          <Row>
            <Col span="auto">
              <EnabledField />
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <ShopsField shops={shops} connectGameConfigShops={connectGameConfigShopsInCompany} />
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <TimeTermField />
            </Col>
          </Row>
        </FormSection>
        <Spacer size={32} />
        <FormSection title="2. スタート画面の画像を設定しましょう">
          <Row>
            <Col span="auto">
              <Description>最初の画面に利用されます</Description>
            </Col>
          </Row>
          <Spacer size={32} />
          <Row>
            <Col span="auto">
              <ImageField
                image={uploadImage}
                setUploadImage={setUploadImage}
                uploadImageApiKey="connectGameConfig"
                formName="AddConnectGameConfig"
              />
            </Col>
          </Row>
        </FormSection>
        <Spacer size={32} />
        <FormSection title="3. 当選確率を設定しましょう">
          <Row>
            <Col span="auto">
              <MaxWinningPercentageField />
            </Col>
          </Row>
          <Spacer size={8} />
          <Row>
            <Col span="auto">
              <Description>チェックイン率が下がるごとに、当選確率も下がります</Description>
            </Col>
          </Row>
          <Spacer size={16} />
          <Row>
            <Col span="auto">
              <WinningPercentageCard />
            </Col>
          </Row>
        </FormSection>
        <Spacer size={32} />
        <FormSection title="4. あたりのお客様へのクーポンを設定しましょう">
          <Row>
            <Col span="24">
              <PrizeCouponField coupons={coupons} />
            </Col>
          </Row>
        </FormSection>
        <Spacer size={32} />
        <FormSection title="5. その他のお客様の画面を設定しましょう">
          <Row>
            <Col span="24">
              <ConsolationField coupons={coupons} />
            </Col>
          </Row>
        </FormSection>
        <Spacer size={32} />
        <FormActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button
            type="primary"
            onClick={loading ? undefined : handleSubmit}
            loading={loading}
            disabled={loading}
          >
            登録
          </Button>
        </FormActions>
      </Form>
    );
  },
);
