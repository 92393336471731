import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { PlanField } from "pages/DishUpSlipGroupPlans/AddDishUpSlipGroupPlanModal/AddDishUpSlipGroupPlanForm/PlanField";
import {
  AddDishUpSlipGroupPlanFormValues,
  useAddDishUpSlipGroupPlanForm,
} from "pages/DishUpSlipGroupPlans/AddDishUpSlipGroupPlanModal/AddDishUpSlipGroupPlanForm/useAddDishUpSlipGroupPlanForm";
import { Plan } from "pages/DishUpSlipGroupPlans/AddDishUpSlipGroupPlanModal/types";
import { DishUpSlipGroup } from "pages/DishUpSlipGroupPlans/types";

type Props = {
  dishUpSlipGroup: DishUpSlipGroup;
  plans: Plan[];
  onChange: (dishUpSlipGroup: AddDishUpSlipGroupPlanFormValues) => void;
};

export const AddPlanForm = memo<Props>(({ dishUpSlipGroup, plans, onChange }) => {
  const { form, change: onValuesChange } = useAddDishUpSlipGroupPlanForm(onChange);

  const dishUpSlipGroupPlansIds = dishUpSlipGroup.dishUpSlipGroupShopPlans.map(
    ({ plan }) => plan?.planId,
  );
  const filteredPlans = plans.filter(({ planId }) => !dishUpSlipGroupPlansIds.includes(planId));

  return (
    <Form name="plan" form={form} onValuesChange={onValuesChange} layout="vertical">
      <PlanField plans={filteredPlans} />
    </Form>
  );
});
