import { createContext, RefObject, useContext } from "react";

type TableAreaSortableTableContext = {
  dragRef: RefObject<HTMLDivElement>;
};

const tableAreaSortableTableContext = createContext<TableAreaSortableTableContext>(
  undefined as unknown as TableAreaSortableTableContext,
);

export const TableAreaSortableTableProvider = tableAreaSortableTableContext.Provider;

export const useTableAreaSortableTableContext = () => {
  const context = useContext(tableAreaSortableTableContext);

  if (!context) {
    throw new Error(
      "useTableAreaSortableTable must be used within a TableAreaSortableTableProvider",
    );
  }

  return context;
};
