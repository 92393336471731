import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditGiftFormItem } from "../useEditGiftForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ReceiptDisplayNameField = memo<Props>((props) => (
  <EditGiftFormItem
    label="伝票表示名"
    name="receiptDisplayName"
    rules={[{ required: true, message: "伝票表示名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditGiftFormItem>
));
