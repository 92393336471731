import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { KitchenDisplayDisplayTargetTable } from "pages/KitchenDisplayDisplayTargets/KitchenDisplayTable";
import { useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery } from "pages/KitchenDisplayDisplayTargets/queries";

export const KitchenDisplayDisplayTargets = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getRolesData,
    loading: loadingRoles,
    error,
  } = useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const kdDisplayTargets = getRolesData?.shop_by_pk?.kdDisplayTargets ?? [];

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="キッチンディスプレイ表示対象一覧">
      <PageHeader
        title="キッチンディスプレイ表示対象ー覧"
        extra={
          canAccess("addKitchenDisplayDisplayTarget")
            ? [
                <Link key="add" to="/kitchenDisplayDisplayTarget/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []
        }
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <KitchenDisplayDisplayTargetTable
        kdDisplayTargets={kdDisplayTargets}
        loading={loadingRoles}
      />
    </DashboardLayout>
  );
};
