import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PikaichiMenuInsertInput, PlanOption } from "types/graphql";

import {
  PikaichiMenu,
  PikaichiMenuPlanChoice,
  PikaichiPlanChoiceMenu,
  Plan,
  PlanChoice,
} from "../types";

export type EditPlanPikaichiPlanFormValues = Pick<
  PikaichiMenu,
  | "pikaichiMenuCd"
  | "pikaichiMenuName"
  | "pikaichiBumonCd"
  | "pikaichiBumonName"
  | "pikaichiCategoryCd"
  | "pikaichiCategoryName"
> & {
  plan?: Pick<Plan, "planId"> & {
    planOptions: Array<
      Pick<PlanOption, "name"> & {
        planChoices: Array<
          Pick<PlanChoice, "name" | "planChoiceId"> & {
            pikaichiMenuPlanChoices: Array<
              Pick<PikaichiMenuPlanChoice, "planChoiceId" | "pikaichiMenuId"> & {
                pikaichiMenu: Pick<
                  PikaichiPlanChoiceMenu,
                  | "pikaichiMenuCd"
                  | "pikaichiMenuName"
                  | "pikaichiBumonCd"
                  | "pikaichiBumonName"
                  | "pikaichiCategoryCd"
                  | "pikaichiCategoryName"
                >;
              }
            >;
          }
        >;
      }
    >;
  };
};

export const EditPlanPikaichiPlanFormItem = createFormItem<EditPlanPikaichiPlanFormValues>();

export const useEditPlanPikaichiPlanForm = ({
  plan,
  pikaichiMenu,
  onSubmit,
}: {
  plan?: Plan;
  pikaichiMenu?: PikaichiMenu;
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId" | "_companyId">) => void;
}) => {
  const [form] = Form.useForm<EditPlanPikaichiPlanFormValues>();

  const initialValues = useMemo<EditPlanPikaichiPlanFormValues>(
    () => ({
      pikaichiMenuCd: pikaichiMenu?.pikaichiMenuCd ?? "",
      pikaichiMenuName: pikaichiMenu?.pikaichiMenuName ?? plan?.planName ?? "",
      pikaichiBumonCd: pikaichiMenu?.pikaichiBumonCd ?? "",
      pikaichiBumonName: pikaichiMenu?.pikaichiBumonName ?? "",
      pikaichiCategoryCd: pikaichiMenu?.pikaichiCategoryCd ?? "",
      pikaichiCategoryName: pikaichiMenu?.pikaichiCategoryName ?? "",
      plan,
    }),
    [plan, pikaichiMenu],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
