import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import { sortBusinessOperationHourTypes } from "models/businessOperationHour";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { uniq } from "util/array";
import { isNotNull } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useMessageDeliveryFormGetLineOfficialAccountsQuery } from "components/MessageDeliveryForm/MessagePreview/queries";
import { useCompany } from "hooks/useCompany";
import { useCurrentRole } from "hooks/useCurrentRole";
import { EditMessageDeliveryForm } from "pages/EditMessageDelivery/EditMessageDeliveryForm";
import {
  useEditMessageDeliveryGetCouponsQuery,
  useEditMessageDeliveryGetCustomersByIdsLazyQuery,
  useEditMessageDeliveryGetCustomersByLikeNameLazyQuery,
  useEditMessageDeliveryGetMenusQuery,
  useEditMessageDeliveryGetMessageDeliveryQuery,
  useEditMessageDeliveryGetQuestionnaireConfigsQuery,
  useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery,
  useEditMessageDeliveryGetShopsQuery,
  useEditMessageDeliveryInsertMessageDeliveryMutation,
  useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation,
} from "pages/EditMessageDelivery/queries";
import { UpdateMessageDeliveryInput } from "types/graphql";

export const EditMessageDelivery = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [company] = useCompany();
  const companyId = company?.id;
  const role = useCurrentRole();

  const {
    data: getMessageDeliveryData,
    loading: getMessageDeliveryLoading,
    error: getMessageDeliveryError,
    refetch: refetchMessageDeliveryData,
  } = useEditMessageDeliveryGetMessageDeliveryQuery(id ? { variables: { id } } : { skip: true });

  const messageDelivery = getMessageDeliveryData?.messageDelivery_by_pk;

  const {
    data: getCouponsData,
    loading: getCouponsLoading,
    error: getCouponsError,
  } = useEditMessageDeliveryGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = getCouponsData?.coupon ?? [];

  const {
    data: getShopsData,
    loading: getShopsLoading,
    error: getShopsError,
  } = useEditMessageDeliveryGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const {
    data: getQuestionnaireConfigsData,
    loading: getQuestionnaireConfigsLoading,
    error: getQuestionnaireConfigsError,
  } = useEditMessageDeliveryGetQuestionnaireConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const questionnaireConfigs = getQuestionnaireConfigsData?.questionnaireConfig ?? [];

  const { data: getShopBusinessOperationHourTypesData } =
    useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const shopBusinessOperationHourTypes = sortBusinessOperationHourTypes(
    uniq(
      getShopBusinessOperationHourTypesData?.shopBusinessOperationHour.map(
        ({ businessOperationHourType }) => businessOperationHourType,
      ) ?? [],
    ),
  );

  const { data: getMenusData } = useEditMessageDeliveryGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData?.menu]);

  const [getCustomersByLikeNameQuery] = useEditMessageDeliveryGetCustomersByLikeNameLazyQuery();

  const getCustomersByLikeName = useCallback(
    async ({ likeName }: { likeName: string }) => {
      if (!companyId) return [];
      if (likeName.length === 0) return [];

      const res = await getCustomersByLikeNameQuery({
        variables: { companyId, likeName: `%${likeName}%` },
      });
      return res.data?.customer ?? [];
    },
    [companyId, getCustomersByLikeNameQuery],
  );

  const [getCustomersByCustomerIdsQuery] = useEditMessageDeliveryGetCustomersByIdsLazyQuery();

  const getCustomersByCustomerIds = useCallback(
    async ({ customerIds }: { customerIds: string[] }) => {
      if (!companyId) return [];
      if (customerIds.length === 0) return [];

      const res = await getCustomersByCustomerIdsQuery({
        variables: { companyId, customerIds },
      });
      return res.data?.customer ?? [];
    },
    [companyId, getCustomersByCustomerIdsQuery],
  );

  const {
    data: getLineOfficialAccounts,
    loading: loadingLineOfficialAccounts,
    error: getLineOfficialAccountsError,
  } = useMessageDeliveryFormGetLineOfficialAccountsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const lineOfficialAccounts = useMemo(
    () =>
      [...(getLineOfficialAccounts?.lineOfficialAccounts ?? [])].sort((a, b) =>
        a.channelName > b.channelName ? 1 : -1,
      ),
    [getLineOfficialAccounts?.lineOfficialAccounts],
  );

  const [editMessageDeliveryMutation] = useEditMessageDeliveryInsertMessageDeliveryMutation();

  const [{ loading: submitting }, submitMessageDelivery] = useAsyncFn(
    async ({ messageDelivery }: { messageDelivery: UpdateMessageDeliveryInput }) => {
      if (!id || !companyId) return;

      // 画像形式 && 画像が登録されていないメッセージが存在する場合エラーを表示する
      // TODO: ImageField でバリデーションしたい
      const imageEmptyMessageIndices = messageDelivery.messageDeliveryMessages
        .map((message, index) =>
          message.imageTypeMeta && !message.imageTypeMeta.imageUrl ? index + 1 : null,
        )
        .filter(isNotNull);
      if (imageEmptyMessageIndices.length > 0) {
        return message.error(
          `${imageEmptyMessageIndices.join(",")}番目のメッセージに画像を登録してください`,
        );
      }

      try {
        await editMessageDeliveryMutation({
          variables: { messageDelivery },
        });
        message.success("更新しました");
        refetchMessageDeliveryData();

        const isDraft = messageDelivery.isDraft;
        const isSuspended = messageDelivery.isSuspended;

        navigate(`/messageDelivery/${isDraft ? "draft" : isSuspended ? "suspended" : "reserved"}`, {
          replace: true,
        });
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [companyId, editMessageDeliveryMutation, id, navigate, refetchMessageDeliveryData],
  );

  const [updateIsSuspendedMutation] =
    useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation();

  const [{ loading: suspendingMessageDelivery }, suspendMessageDelivery] = useAsyncFn(async () => {
    if (!id || !companyId) return;

    await updateIsSuspendedMutation({
      variables: { id, isSuspended: true },
    });

    message.success("更新しました");
    refetchMessageDeliveryData();

    navigate("/messageDelivery/suspended", { replace: true });
  });

  const [{ loading: resumingMessageDelivery }, resumeMessageDelivery] = useAsyncFn(async () => {
    if (!id || !companyId) return;

    await updateIsSuspendedMutation({
      variables: { id, isSuspended: false },
    });

    message.success("更新しました");
    refetchMessageDeliveryData();

    navigate("/messageDelivery/reserved", { replace: true });
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    getMessageDeliveryLoading ||
    getCouponsLoading ||
    submitting ||
    getShopsLoading ||
    getQuestionnaireConfigsLoading ||
    loadingLineOfficialAccounts ||
    suspendingMessageDelivery ||
    resumingMessageDelivery;

  const error =
    getMessageDeliveryError ||
    getCouponsError ||
    getShopsError ||
    getQuestionnaireConfigsError ||
    getLineOfficialAccountsError;

  // 配信分析の整合性の観点から、一件でも配信済の場合は、フォームを編集させない
  const inputDisabled = !messageDelivery || messageDelivery.messageDeliveryJobs.length > 0;

  return (
    <DashboardLayout
      title={messageDelivery?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "メッセージ配信" }],
      }}
    >
      <PageHeader title={messageDelivery?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {messageDelivery && (
        <EditMessageDeliveryForm
          loading={loading}
          coupons={coupons}
          menus={menus}
          questionnaireConfigs={questionnaireConfigs}
          messageDelivery={messageDelivery}
          shops={shops}
          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
          role={role}
          lineOfficialAccounts={lineOfficialAccounts}
          onSubmit={submitMessageDelivery}
          onResume={resumeMessageDelivery}
          onSuspend={suspendMessageDelivery}
          onSearchCustomersByCustomerIds={getCustomersByCustomerIds}
          onSearchCustomersByLikeName={getCustomersByLikeName}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
          inputDisabled={inputDisabled}
        />
      )}
    </DashboardLayout>
  );
};
