import React, { memo, useCallback, useState } from "react";
import { Button, Form } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import {
  AdyenPaymentConfig,
  AdyenTerminalPaymentConfig,
  GmoOffPaymentConfig,
  GmoShopConfigDetail,
} from "../types";

import { DiniiPayConfigFormConfirmModal } from "./DiniiPayConfigFormConfirmModal";
import { EditOnlinePaymentConfigFields } from "./EditOnlinePaymentConfigFields";
import { EditTerminalPaymentConfigFields } from "./EditTerminalPaymentConfigFields";
import {
  EditDiniiPayConfigFormValues,
  useEditDiniiPayConfigForm,
} from "./useEditDiniiPayConfigForm";

type Props = {
  gmoShopConfigDetail: GmoShopConfigDetail | null;
  gmoOffPaymentConfig: GmoOffPaymentConfig | null;
  adyenPaymentConfig: AdyenPaymentConfig | null;
  adyenTerminalPaymentConfig: AdyenTerminalPaymentConfig | null;
  shopId: string;
  loading: boolean;
  onSubmit: (values: EditDiniiPayConfigFormValues) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditDiniiPayConfigForm = memo<Props>(
  ({
    gmoShopConfigDetail,
    gmoOffPaymentConfig,
    adyenPaymentConfig,
    adyenTerminalPaymentConfig,
    shopId,
    loading,
    onSubmit,
    onClose,
    onFormValidationError,
  }) => {
    const { form, initialValues, submit } = useEditDiniiPayConfigForm({
      gmoShopConfigDetail,
      gmoOffPaymentConfig,
      adyenPaymentConfig,
      adyenTerminalPaymentConfig,
      onSubmit,
    });

    const [showConfirm, setShowConfirm] = useState(false);
    const showConfirmModal = useCallback(async () => {
      try {
        await form.validateFields();
        setShowConfirm(true);
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, onFormValidationError]);

    const closeModal = useCallback(() => setShowConfirm(false), []);

    const handleSubmit = useCallback(async () => {
      closeModal();
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, onFormValidationError, submit, closeModal]);

    const getFieldsValue = useCallback(() => form.getFieldsValue(), [form]);

    return (
      <>
        <FormContent>
          <Form name="diniiPayConfig" form={form} layout="vertical" initialValues={initialValues}>
            <EditOnlinePaymentConfigFields shopId={shopId} />
            <Spacer size={32} />
            <EditTerminalPaymentConfigFields />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button loading={loading} onClick={onClose}>
            キャンセル
          </Button>
          <Button loading={loading} type="primary" onClick={showConfirmModal}>
            更新
          </Button>
        </FormActions>
        <DiniiPayConfigFormConfirmModal
          visible={showConfirm}
          getFieldsValue={getFieldsValue}
          onCancel={closeModal}
          onConfirm={handleSubmit}
        />
      </>
    );
  },
);
