import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { Menu } from "../types";

export type EditGiftFormValues = Pick<
  Menu,
  "serial" | "name" | "imageUrl" | "receiptDisplayName" | "description"
>;

const getInitialValues = (menu: Menu) => ({ ...menu, imageUrl: menu.imageUrl ?? null });

export const EditGiftFormItem = createFormItem<EditGiftFormValues>();

export const useEditGiftForm = (
  menu: Menu,
  onSubmit: ({ menu }: { menu: Omit<Menu, "shopMenus"> }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(menu);

  const submit = useCallback(
    (imageUrl: string | null | undefined) => {
      const formValues = form.getFieldsValue() as EditGiftFormValues;

      onSubmit({ menu: { ...formValues, imageUrl } });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
