import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OpenField = memo<Props>((props) => (
  <EditShopFormItem
    label="開店時刻"
    name="open"
    rules={[{ type: "object", required: true, message: "開店時刻を入力してください" }]}
    {...props}
  >
    <TimePicker formItemName="open" />
  </EditShopFormItem>
));
