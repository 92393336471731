import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OrderableTimeShopsGetOrderableTime = gql`
    query OrderableTimeShopsGetOrderableTime($orderableTimeId: uuid!) {
  orderableTime_by_pk(id: $orderableTimeId) {
    id
    name
    shopOrderableTimeTerms(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      orderableTimeId
      shopId
      dayWeek
      start
      end
      shop {
        shopId
        changeDateTime
      }
    }
  }
}
    `;
export const OrderableTimeShopsGetShops = gql`
    query OrderableTimeShopsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export type OrderableTimeShopsGetOrderableTimeQueryVariables = Types.Exact<{
  orderableTimeId: Types.Scalars['uuid'];
}>;


export type OrderableTimeShopsGetOrderableTimeQuery = (
  { __typename?: 'query_root' }
  & { orderableTime_by_pk?: Types.Maybe<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
    & { shopOrderableTimeTerms: Array<(
      { __typename?: 'shopOrderableTimeTerm' }
      & Pick<Types.ShopOrderableTimeTerm, 'id' | 'orderableTimeId' | 'shopId' | 'dayWeek' | 'start' | 'end'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'changeDateTime'>
      ) }
    )> }
  )> }
);

export type OrderableTimeShopsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OrderableTimeShopsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const OrderableTimeShopsGetOrderableTimeDocument = gql`
    query OrderableTimeShopsGetOrderableTime($orderableTimeId: uuid!) {
  orderableTime_by_pk(id: $orderableTimeId) {
    id
    name
    shopOrderableTimeTerms(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      orderableTimeId
      shopId
      dayWeek
      start
      end
      shop {
        shopId
        changeDateTime
      }
    }
  }
}
    `;

/**
 * __useOrderableTimeShopsGetOrderableTimeQuery__
 *
 * To run a query within a React component, call `useOrderableTimeShopsGetOrderableTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderableTimeShopsGetOrderableTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderableTimeShopsGetOrderableTimeQuery({
 *   variables: {
 *      orderableTimeId: // value for 'orderableTimeId'
 *   },
 * });
 */
export function useOrderableTimeShopsGetOrderableTimeQuery(baseOptions: Apollo.QueryHookOptions<OrderableTimeShopsGetOrderableTimeQuery, OrderableTimeShopsGetOrderableTimeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderableTimeShopsGetOrderableTimeQuery, OrderableTimeShopsGetOrderableTimeQueryVariables>(OrderableTimeShopsGetOrderableTimeDocument, options);
      }
export function useOrderableTimeShopsGetOrderableTimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderableTimeShopsGetOrderableTimeQuery, OrderableTimeShopsGetOrderableTimeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderableTimeShopsGetOrderableTimeQuery, OrderableTimeShopsGetOrderableTimeQueryVariables>(OrderableTimeShopsGetOrderableTimeDocument, options);
        }
export type OrderableTimeShopsGetOrderableTimeQueryHookResult = ReturnType<typeof useOrderableTimeShopsGetOrderableTimeQuery>;
export type OrderableTimeShopsGetOrderableTimeLazyQueryHookResult = ReturnType<typeof useOrderableTimeShopsGetOrderableTimeLazyQuery>;
export type OrderableTimeShopsGetOrderableTimeQueryResult = Apollo.QueryResult<OrderableTimeShopsGetOrderableTimeQuery, OrderableTimeShopsGetOrderableTimeQueryVariables>;
export const OrderableTimeShopsGetShopsDocument = gql`
    query OrderableTimeShopsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useOrderableTimeShopsGetShopsQuery__
 *
 * To run a query within a React component, call `useOrderableTimeShopsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderableTimeShopsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderableTimeShopsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOrderableTimeShopsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<OrderableTimeShopsGetShopsQuery, OrderableTimeShopsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderableTimeShopsGetShopsQuery, OrderableTimeShopsGetShopsQueryVariables>(OrderableTimeShopsGetShopsDocument, options);
      }
export function useOrderableTimeShopsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderableTimeShopsGetShopsQuery, OrderableTimeShopsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderableTimeShopsGetShopsQuery, OrderableTimeShopsGetShopsQueryVariables>(OrderableTimeShopsGetShopsDocument, options);
        }
export type OrderableTimeShopsGetShopsQueryHookResult = ReturnType<typeof useOrderableTimeShopsGetShopsQuery>;
export type OrderableTimeShopsGetShopsLazyQueryHookResult = ReturnType<typeof useOrderableTimeShopsGetShopsLazyQuery>;
export type OrderableTimeShopsGetShopsQueryResult = Apollo.QueryResult<OrderableTimeShopsGetShopsQuery, OrderableTimeShopsGetShopsQueryVariables>;