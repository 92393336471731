import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanCategoryModalCategories = gql`
    query AddPlanCategoryModalCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
  }
}
    `;
export const AddPlanCategoryModalInsertPlanMenuCategory = gql`
    mutation AddPlanCategoryModalInsertPlanMenuCategory($planCategories: [planMenuCategory_insert_input!]!) {
  insert_planMenuCategory(
    objects: $planCategories
    on_conflict: {constraint: planMenuCategory_planId_categoryId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      categoryId: _categoryId
      planId: _planId
    }
  }
}
    `;
export type AddPlanCategoryModalCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanCategoryModalCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type AddPlanCategoryModalInsertPlanMenuCategoryMutationVariables = Types.Exact<{
  planCategories: Array<Types.PlanMenuCategoryInsertInput> | Types.PlanMenuCategoryInsertInput;
}>;


export type AddPlanCategoryModalInsertPlanMenuCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planMenuCategory?: Types.Maybe<(
    { __typename?: 'planMenuCategory_mutation_response' }
    & Pick<Types.PlanMenuCategoryMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planMenuCategory' }
      & { categoryId: Types.PlanMenuCategory['_categoryId'], planId: Types.PlanMenuCategory['_planId'] }
    )> }
  )> }
);


export const AddPlanCategoryModalCategoriesDocument = gql`
    query AddPlanCategoryModalCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useAddPlanCategoryModalCategoriesQuery__
 *
 * To run a query within a React component, call `useAddPlanCategoryModalCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanCategoryModalCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanCategoryModalCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanCategoryModalCategoriesQuery(baseOptions: Apollo.QueryHookOptions<AddPlanCategoryModalCategoriesQuery, AddPlanCategoryModalCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanCategoryModalCategoriesQuery, AddPlanCategoryModalCategoriesQueryVariables>(AddPlanCategoryModalCategoriesDocument, options);
      }
export function useAddPlanCategoryModalCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanCategoryModalCategoriesQuery, AddPlanCategoryModalCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanCategoryModalCategoriesQuery, AddPlanCategoryModalCategoriesQueryVariables>(AddPlanCategoryModalCategoriesDocument, options);
        }
export type AddPlanCategoryModalCategoriesQueryHookResult = ReturnType<typeof useAddPlanCategoryModalCategoriesQuery>;
export type AddPlanCategoryModalCategoriesLazyQueryHookResult = ReturnType<typeof useAddPlanCategoryModalCategoriesLazyQuery>;
export type AddPlanCategoryModalCategoriesQueryResult = Apollo.QueryResult<AddPlanCategoryModalCategoriesQuery, AddPlanCategoryModalCategoriesQueryVariables>;
export const AddPlanCategoryModalInsertPlanMenuCategoryDocument = gql`
    mutation AddPlanCategoryModalInsertPlanMenuCategory($planCategories: [planMenuCategory_insert_input!]!) {
  insert_planMenuCategory(
    objects: $planCategories
    on_conflict: {constraint: planMenuCategory_planId_categoryId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      categoryId: _categoryId
      planId: _planId
    }
  }
}
    `;
export type AddPlanCategoryModalInsertPlanMenuCategoryMutationFn = Apollo.MutationFunction<AddPlanCategoryModalInsertPlanMenuCategoryMutation, AddPlanCategoryModalInsertPlanMenuCategoryMutationVariables>;

/**
 * __useAddPlanCategoryModalInsertPlanMenuCategoryMutation__
 *
 * To run a mutation, you first call `useAddPlanCategoryModalInsertPlanMenuCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanCategoryModalInsertPlanMenuCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanCategoryModalInsertPlanMenuCategoryMutation, { data, loading, error }] = useAddPlanCategoryModalInsertPlanMenuCategoryMutation({
 *   variables: {
 *      planCategories: // value for 'planCategories'
 *   },
 * });
 */
export function useAddPlanCategoryModalInsertPlanMenuCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanCategoryModalInsertPlanMenuCategoryMutation, AddPlanCategoryModalInsertPlanMenuCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanCategoryModalInsertPlanMenuCategoryMutation, AddPlanCategoryModalInsertPlanMenuCategoryMutationVariables>(AddPlanCategoryModalInsertPlanMenuCategoryDocument, options);
      }
export type AddPlanCategoryModalInsertPlanMenuCategoryMutationHookResult = ReturnType<typeof useAddPlanCategoryModalInsertPlanMenuCategoryMutation>;
export type AddPlanCategoryModalInsertPlanMenuCategoryMutationResult = Apollo.MutationResult<AddPlanCategoryModalInsertPlanMenuCategoryMutation>;
export type AddPlanCategoryModalInsertPlanMenuCategoryMutationOptions = Apollo.BaseMutationOptions<AddPlanCategoryModalInsertPlanMenuCategoryMutation, AddPlanCategoryModalInsertPlanMenuCategoryMutationVariables>;