import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditCategoryTranslationsFormItem } from "./useEditCategoryTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameCnField = memo<Props>(({ ...props }) => (
  <EditCategoryTranslationsFormItem label="カテゴリ名：中国語 (簡体字)" name="nameCn" {...props}>
    <Input />
  </EditCategoryTranslationsFormItem>
));
