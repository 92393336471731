import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox, FormItemProps } from "antd";

import { KitchenRole } from "../types";
import { EditKitchenRolesFormItem } from "../useEditKitchenRolesForm";

const StyledCheckbox = styled(Checkbox)`
  display: flex;

  &:not(:first-child) {
    margin-left: 0px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: inline-block;
`;

type Props = {
  kitchenRoles: KitchenRole[];
} & Omit<FormItemProps, "children" | "name">;

export const KitchenRolesField = memo<Props>(({ kitchenRoles, ...props }) => (
  <EditKitchenRolesFormItem name="kitchenRoles" {...props}>
    <CheckboxGroup>
      {kitchenRoles.map(({ id, name }) => (
        <StyledCheckbox key={id} value={id}>
          {name}
        </StyledCheckbox>
      ))}
    </CheckboxGroup>
  </EditKitchenRolesFormItem>
));
