import React, { memo } from "react";
import { Popconfirm, Popover, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { onSitePaymentDetailTypeCategory } from "models/onSitePaymentDetailTypeCategory";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { OnSitePaymentDetailType, OnSitePaymentDetailTypeEntityType } from "../types";

type Props = {
  loading?: boolean;
  detailTypes: OnSitePaymentDetailType[];
  onArchive: ({ id }: { id: string }) => void;
  hasSteraOnSitePaymentDetailType: (type: OnSitePaymentDetailTypeEntityType) => boolean;
};

export const DetailTypeTable = memo<Props>(
  ({ loading, detailTypes, onArchive, hasSteraOnSitePaymentDetailType }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns: ColumnsType<OnSitePaymentDetailType> = [
      {
        title: "支払方法",
        render(_: string, { label }: OnSitePaymentDetailType) {
          return label;
        },
      },
      {
        title: "支払種別",
        width: 240,
        render(_: string, { onSitePaymentDetailTypeCategory: category }: OnSitePaymentDetailType) {
          return category ? onSitePaymentDetailTypeCategory[category] : "";
        },
      },
      ...(canAccess("editOnSitePaymentDetailTypes")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { id, isEditable }: OnSitePaymentDetailType) {
                if (!isEditable) return null;

                return (
                  <Tooltip title="支払方法を編集">
                    <IconLink to={`/onSitePaymentDetailType/${id}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteOnSitePaymentDetailTypes")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { id, type, isEditable }: OnSitePaymentDetailType) {
                if (!isEditable) return null;

                if (hasSteraOnSitePaymentDetailType(type)) {
                  return (
                    <Popover
                      content={<Paragraph>紐づくstera支払方法を削除してください</Paragraph>}
                      title="stera支払方法に紐づく支払方法は削除できません"
                    >
                      <DeleteIcon disabled />
                    </Popover>
                  );
                }

                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>支払方法を削除しますか？</Paragraph>
                        <Paragraph>レジを再起動すると支払方法が表示されなくなります。</Paragraph>
                        <Paragraph>一度削除した支払方法を元に戻すことはできません。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => onArchive({ id })}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    return (
      <Table
        columns={columns}
        dataSource={detailTypes}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
