import React, { memo } from "react";
import styled from "styled-components";

import NoImageIcon from "assets/no_image.svg";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { getCroppedUrl } from "libs/imgix";
import { CouponTypeEnum } from "types/graphql";

import { placeholder } from "../placeholder";
import { AddCouponFormValues } from "../useAddCouponForm";

import { CouponAvailableDate } from "./CouponAvailableDate";

const Container = styled.div`
  padding: 16px 16px 80px;
`;

const ImageContainer = styled.div`
  padding: 0 24px 16px;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 118px;
  border-radius: 10px;
`;

const NoImageIconWrapper = styled.div`
  width: 100%;
  height: 118px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
`;

const Title = styled.p<{ isPlaceholder: boolean }>`
  margin: 0;
  width: 100%;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? `${colors.Text.Secondary}` : `${colors.Text.Default}`};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word;
`;

const Content = styled.p<{ isPlaceholder: boolean }>`
  margin: 0;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? `${colors.Text.Secondary}` : `${colors.Text.Default}`};
  font-size: 12px;
  line-height: 20px;
`;

const Description = styled.p<{ isPlaceholder: boolean }>`
  margin: 0;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? `${colors.Text.Secondary}` : `${colors.Text.Default}`};
  font-size: 12px;
  line-height: 20px;
  word-break: break-all;
  white-space: pre-wrap;
`;

const TermsOfUse = styled.p`
  margin: 0;
  color: ${colors.Text.Default};
  font-size: 12px;
  line-height: 20px;
`;

const TermsOfUseContent = styled.p<{ isPlaceholder: boolean }>`
  margin: 0;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? `${colors.Text.Secondary}` : `${colors.Text.Default}`};
  font-size: 10px;
  line-height: 15px;
  white-space: pre-wrap;
  word-break: break-all;
`;

const Button = styled.div`
  padding: 8px;
  color: #ffffff;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  background: linear-gradient(115.86deg, #de8500 3.03%, #f06141 63.59%, #ec284b 96.9%);
  border-radius: 4px;
`;

const truncate = (input: string, { length }: { length: number }): string =>
  input.length > length ? `${input.slice(0, length)}...` : input;

type Props = {
  coupon: Pick<
    AddCouponFormValues,
    | "title"
    | "content"
    | "description"
    | "availableFrom"
    | "availableUntil"
    | "availableDays"
    | "termsOfUse"
    | "couponType"
  > & {
    imageUrl?: string;
  };
  maxContentLength?: number;
  maxDescriptionLength?: number;
};

/**
 * UserAppのクーポン画面に表示される内容とほぼ同等のUIを表示するコンポーネント
 */
export const CouponContent = memo<Props>(({ coupon, maxContentLength, maxDescriptionLength }) => {
  const {
    imageUrl,
    title,
    content,
    description,
    availableFrom,
    availableUntil,
    availableDays,
    termsOfUse,
    couponType,
  } = coupon;

  const croppedImageUrl =
    couponType === CouponTypeEnum.Ticket ? getCroppedUrl({ url: imageUrl, w: 212, h: 118 }) : null;

  return (
    <Container>
      <ImageContainer>
        {croppedImageUrl ? (
          <Image src={croppedImageUrl} />
        ) : (
          <NoImageIconWrapper>
            <NoImageIcon />
          </NoImageIconWrapper>
        )}
      </ImageContainer>
      <Title isPlaceholder={!title}>{title || placeholder.title}</Title>
      <Spacer size={8} />
      <CouponAvailableDate
        availableFrom={availableFrom}
        availableUntil={availableUntil}
        availableDays={availableDays}
      />
      <Spacer size={8} />
      <Content isPlaceholder={!content}>
        {maxContentLength
          ? truncate(content || placeholder.content, { length: maxContentLength })
          : content || placeholder.content}
      </Content>
      <Spacer size={24} />
      <Description isPlaceholder={!description}>
        {maxDescriptionLength
          ? truncate(description || placeholder.description, { length: maxDescriptionLength })
          : description || placeholder.description}
      </Description>
      <Spacer size={12} />
      <TermsOfUse>利用規約</TermsOfUse>
      <Spacer size={8} />
      <TermsOfUseContent isPlaceholder={!termsOfUse}>
        {termsOfUse || placeholder.termsOfUse}
      </TermsOfUseContent>

      <Spacer size={16} />
      <Button>クーポンを使う</Button>
    </Container>
  );
});
