import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { ShopClerk } from "pages/EditClerkPriorities/types";
import { ShopClerkInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  shopClerks: ShopClerk[];
  onUpdate: (shopClerks: ShopClerkInsertInput[]) => void;
};

export const EditClerkPriorityList = memo<Props>(({ loading, shopClerks, onUpdate }) => {
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex !== hoverIndex) {
      onUpdate(
        updatePriorities(shopClerks, dragIndex, hoverIndex).map(({ clerk, ...shopClerk }) => ({
          ...shopClerk,
          clerkId: clerk.id,
          _clerkId: clerk.clerkId,
        })),
      );
    }
  };

  return (
    <PriorityList<ShopClerk>
      itemLayout="horizontal"
      loading={loading}
      dataSource={shopClerks}
      renderItem={({ clerk, priority }, index) => (
        <PriorityListItem
          id={clerk.clerkId}
          name={clerk.name}
          priority={priority}
          index={index}
          onMove={handleMoveItem}
        />
      )}
    />
  );
});
