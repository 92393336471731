import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { FormInstance } from "antd";

import { VisitedBusinessOperationHoursLabel } from "components/CustomerSegment/VisitedBusinessOperationHoursLabel";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { Shop } from "pages/AddMessageDelivery/types";
import { VisitedShopsFilter } from "pages/EditMessageDelivery/EditMessageDeliveryForm/CustomerSegmentField/VisitedShopsFilter";
import {
  EditMessageDeliveryFormItem,
  EditMessageDeliveryFormValues,
} from "pages/EditMessageDelivery/EditMessageDeliveryForm/useEditMessageDeliveryForm";
import { Menu } from "pages/EditMessageDelivery/types";
import { BusinessOperationHourTypeEnum } from "types/graphql";

import { BirthdayCountFilter } from "./BirthdayCountFilter";
import { ConsecutiveUnvisitedDayCountFilter } from "./ConsecutiveUnvisitedDayCountFilter";
import { GenderFilter } from "./GenderFilter";
import { OrderedMenusFilter } from "./OrderedMenusFilter";
import { VisitedBusinessOperationHoursFilter } from "./VisitedBusinessOperationHoursFilter";
import { VisitedDayCountFilterField } from "./VisitedDayCountFilterField";
import { VisitedDayOfWeekFilter } from "./VisitedDayOfWeekFilter";
import { VisitedQuestionnaireAnswerFilter } from "./VisitedQuestionnaireAnswerFilter";

const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type Props = {
  form: FormInstance<EditMessageDeliveryFormValues>;
  shops: Shop[];
  menus: Menu[];
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
};

export const CustomerSegmentField = memo<Props>(
  ({ form, shops, menus, shopBusinessOperationHourTypes }) => {
    const [isOrderedMenuSegmentActive, setIsOrderedMenusSegmentActive] = useState(
      (Array.isArray(form.getFieldValue(["orderedMenusFilter", "menuIds"]))
        ? form.getFieldValue(["orderedMenusFilter", "menuIds"])
        : []
      ).length > 0,
    );

    const changeIsOrderedMenuSegmentActive = useCallback(
      ({ selectedMenuIds }: { selectedMenuIds: number[] }) =>
        setIsOrderedMenusSegmentActive(selectedMenuIds.length > 0),
      [],
    );

    return (
      <>
        <Wrapper>
          <EditMessageDeliveryFormItem.NonProperty
            label={
              <>
                <img
                  src="/message-delivery/directions-walk.png"
                  width={20}
                  height={20}
                  alt="来店回数"
                />
                <Spacer size={4} />
                来店回数
              </>
            }
            style={{ marginBottom: 0 }}
          >
            <ItemContent>
              <VisitedDayCountFilterField form={form} />
            </ItemContent>
          </EditMessageDeliveryFormItem.NonProperty>
          <EditMessageDeliveryFormItem.NonProperty
            label={
              <>
                <img
                  src="/message-delivery/carry-out.png"
                  width={20}
                  height={20}
                  alt="来店からの日数"
                />
                <Spacer size={4} />
                来店からの日数
              </>
            }
            style={{ marginBottom: 0 }}
          >
            <ItemContent>
              <ConsecutiveUnvisitedDayCountFilter form={form} />
            </ItemContent>
          </EditMessageDeliveryFormItem.NonProperty>
          <EditMessageDeliveryFormItem.NonProperty label="来店した曜日">
            <ItemContent>
              <VisitedDayOfWeekFilter />
            </ItemContent>
          </EditMessageDeliveryFormItem.NonProperty>
          {Boolean(shopBusinessOperationHourTypes.length) && (
            <EditMessageDeliveryFormItem.NonProperty label={<VisitedBusinessOperationHoursLabel />}>
              <ItemContent>
                <VisitedBusinessOperationHoursFilter
                  shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
                />
              </ItemContent>
            </EditMessageDeliveryFormItem.NonProperty>
          )}
          <EditMessageDeliveryFormItem.NonProperty label="アンケート回答状況">
            <ItemContent>
              <VisitedQuestionnaireAnswerFilter />
            </ItemContent>
          </EditMessageDeliveryFormItem.NonProperty>

          <EditMessageDeliveryFormItem.NonProperty label="来店した店舗">
            <VisitedShopsFilter form={form} shops={shops} />
          </EditMessageDeliveryFormItem.NonProperty>

          <EditMessageDeliveryFormItem.NonProperty label="注文したメニュー">
            <OrderedMenusFilter form={form} menus={menus} />
          </EditMessageDeliveryFormItem.NonProperty>

          <EditMessageDeliveryFormItem.NonProperty label="性別">
            <GenderFilter />
          </EditMessageDeliveryFormItem.NonProperty>

          <EditMessageDeliveryFormItem.NonProperty label="誕生日までの日数">
            <BirthdayCountFilter form={form} />
          </EditMessageDeliveryFormItem.NonProperty>
        </Wrapper>
      </>
    );
  },
);
