import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";

import { Menu } from "pages/EditMenu/types";

import { PlanInfo } from "..";

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

type Props = {
  onCancel(): void;
  onSubmit(): void;
  open: boolean;
  menu: Menu;
  removedPlansWithFirstOrder: PlanInfo;
};

export const FirstOrderMenuDeletionConfirmationModal = memo(
  ({ onCancel, onSubmit, open, menu, removedPlansWithFirstOrder }: Props) => (
    <Modal
      title={<ModalTitle>自動注文設定確認</ModalTitle>}
      centered
      closable={false}
      open={open}
      footer={
        <>
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" onClick={onSubmit}>
            更新
          </Button>
        </>
      }
      onCancel={onCancel}
    >
      <div>
        以下プランの自動注文設定から、{menu.name}が削除されてしまいますがよろしいですか。
        <br />
        <br />
        <ul>
          {removedPlansWithFirstOrder.map((plan) => (
            <li key={plan.id}>{plan.planName}</li>
          ))}
        </ul>
      </div>
    </Modal>
  ),
);
