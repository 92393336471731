import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";

import { PlanChoice, WinboardMenu } from "../../types";

type Props = {
  loading?: boolean;
  planId: number;
  planOptionId: number;
  planChoices: PlanChoiceWithWinboardMenu[];
};

type PlanChoiceWithWinboardMenu = Omit<PlanChoice, "winboardMenus"> & {
  winboardMenu?: WinboardMenu;
};

export const PlanChoiceTable = memo<Props>(({ loading, planId, planOptionId, planChoices }) => {
  const columns: ColumnsType<PlanChoiceWithWinboardMenu> = [
    { title: "選択肢名", dataIndex: "name", key: "name", fixed: "left", width: 150 },
    {
      title: "メニューコード",
      align: "left",
      width: 130,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.code;
      },
    },
    {
      title: "メニュー名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.name;
      },
    },
    {
      title: "部門コード",
      align: "left",
      width: 100,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.bumonCode;
      },
    },
    {
      title: "部門名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.bumonName;
      },
    },
    {
      title: "分類コード",
      align: "left",
      width: 100,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.categoryCode;
      },
    },
    {
      title: "分類名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoiceWithWinboardMenu) {
        return choice.winboardMenu?.categoryName;
      },
    },
    {
      title: "",
      width: 60,
      align: "center",
      fixed: "right",
      render(_: string, { planChoiceId }: PlanChoiceWithWinboardMenu) {
        return (
          <IconLink
            to={`/winboard/plan/${planId}/option/${planOptionId}/choice/${planChoiceId}/edit`}
          >
            <EditIcon />
          </IconLink>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="planChoiceId"
      columns={columns}
      dataSource={planChoices}
      loading={loading}
      bordered
      pagination={false}
      scroll={{ x: 100 }}
    />
  );
});
