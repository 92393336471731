import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SteraFeatureFlag = gql`
    query SteraFeatureFlag($companyId: uuid!) {
  cashRegisterConfig_aggregate(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}, useStera: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type SteraFeatureFlagQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type SteraFeatureFlagQuery = (
  { __typename?: 'query_root' }
  & { cashRegisterConfig_aggregate: (
    { __typename?: 'cashRegisterConfig_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'cashRegisterConfig_aggregate_fields' }
      & Pick<Types.CashRegisterConfigAggregateFields, 'count'>
    )> }
  ) }
);


export const SteraFeatureFlagDocument = gql`
    query SteraFeatureFlag($companyId: uuid!) {
  cashRegisterConfig_aggregate(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}, useStera: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useSteraFeatureFlagQuery__
 *
 * To run a query within a React component, call `useSteraFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useSteraFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSteraFeatureFlagQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSteraFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<SteraFeatureFlagQuery, SteraFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SteraFeatureFlagQuery, SteraFeatureFlagQueryVariables>(SteraFeatureFlagDocument, options);
      }
export function useSteraFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SteraFeatureFlagQuery, SteraFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SteraFeatureFlagQuery, SteraFeatureFlagQueryVariables>(SteraFeatureFlagDocument, options);
        }
export type SteraFeatureFlagQueryHookResult = ReturnType<typeof useSteraFeatureFlagQuery>;
export type SteraFeatureFlagLazyQueryHookResult = ReturnType<typeof useSteraFeatureFlagLazyQuery>;
export type SteraFeatureFlagQueryResult = Apollo.QueryResult<SteraFeatureFlagQuery, SteraFeatureFlagQueryVariables>;