import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useShop } from "hooks/useShop";
import { EditGiftPriorityList } from "pages/EditGiftPriorities/EditGiftPriorityList";
import {
  useEditGiftPrioritiesGetGiftMetaQuery,
  useEditGiftPrioritiesInsertGiftMetaMutation,
} from "pages/EditGiftPriorities/queries";
import { GiftMetaInsertInput } from "types/graphql";

export const EditGiftPriorities = () => {
  const navigate = useNavigate();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getGiftMetaData,
    loading: loadingGiftMeta,
    refetch: refetchGiftMetaData,
    error,
  } = useEditGiftPrioritiesGetGiftMetaQuery(shopId ? { variables: { shopId } } : { skip: true });
  const giftMeta = getGiftMetaData?.giftMeta ?? [];

  const [insertGiftMetaMutation, { loading: loadingInsertGiftMeta }] =
    useEditGiftPrioritiesInsertGiftMetaMutation();

  const onUpdate = useCallback(
    async (giftMeta: GiftMetaInsertInput[]) => {
      try {
        await insertGiftMetaMutation({
          variables: { giftMeta },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchGiftMetaData();
    },
    [refetchGiftMetaData, insertGiftMetaMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="推しエール対象の表示順を編集"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "推しエール" }],
      }}
    >
      <PageHeader title="推しエール対象の表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditGiftPriorityList
          loading={loadingGiftMeta || loadingInsertGiftMeta}
          giftMeta={giftMeta}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
