import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type ConvertTecFormatFormValues = {
  menuMaster: File;
};

export const ConvertTecFormatFormItem = createFormItem<ConvertTecFormatFormValues>();

export const useConvertTecFormatForm = ({
  onSubmit,
}: {
  onSubmit: (args: ConvertTecFormatFormValues) => void;
}) => {
  const [form] = Form.useForm<ConvertTecFormatFormValues>();

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue());
  }, [form, onSubmit]);

  return { form, submit };
};
