import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { round } from "util/math";
import { formatPrice } from "util/price";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";

import { SalesBudget } from "../types";

type Props = {
  salesBudgetData: SalesBudget[];
  isMonthlyFilter: boolean;
  canEdit: boolean;
  loading: boolean;
};

const CellText = styled.div`
  font-size: 14px;
`;

export const SalesBudgetTable = memo<Props>(
  ({ salesBudgetData, isMonthlyFilter, canEdit, loading }) => {
    const columns: ColumnsType<SalesBudget> = useMemo(
      () => [
        {
          title: "期間",
          width: 160,
          dataIndex: "businessDate",
          render: (value: string) => (
            <CellText>
              {dayjs(value).format(isMonthlyFilter ? "YYYY/MM" : "YYYY/MM/DD (ddd)")}
            </CellText>
          ),
        },
        {
          title: "売上 (税抜)",
          width: 300,
          align: "right",
          dataIndex: "salesAmount",
          render: (value?: number | undefined) => (
            <CellText>{value ? `${formatPrice(value)}` : "-"}</CellText>
          ),
        },
        {
          title: "売上目標",
          width: 300,
          align: "right",
          dataIndex: "budget",
          render: (value?: number | undefined) => (
            <CellText>{value ? `${formatPrice(value)}` : "-"}</CellText>
          ),
        },
        {
          title: "目標達成率",
          width: 300,
          align: "right",
          render: (_: unknown, { salesAmount, budget }: SalesBudget) => (
            <CellText>
              {salesAmount && budget ? `${round((salesAmount / budget) * 100, 1)}%` : "-"}
            </CellText>
          ),
        },
        ...(isMonthlyFilter && canEdit
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                fixed: "right",
                dataIndex: "id",
                render: (value?: string | undefined) =>
                  value ? (
                    <IconLink to={`/salesBudget/${value}/edit`}>
                      <EditIcon />
                    </IconLink>
                  ) : (
                    <EditIcon disabled={!value} />
                  ),
              } as const,
            ]
          : []),
      ],
      [isMonthlyFilter, canEdit],
    );

    return (
      <Table
        rowKey="businessDate"
        columns={columns}
        dataSource={salesBudgetData}
        bordered
        loading={loading}
        pagination={false}
      />
    );
  },
);
