import { useCallback, useMemo, useState } from "react";
import { isNotNull } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountRole } from "pages/EditDashboardAccountRole/types";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

export type EditDashboardAccountRoleFormValues = Pick<DashboardAccountRole, "name">;

export type Permissions = Record<
  DashboardFeatureEnum,
  DashboardAccountRolePermissionTypeEnum | undefined
>;

export type DashboardAccountRoleFormValuesWithPermissions = EditDashboardAccountRoleFormValues & {
  permissions: Permissions;
};

const getInitialValues = (dashboardAccountRole: DashboardAccountRole) => ({
  name: dashboardAccountRole.name,
});

export const EditDashboardAccountRoleFormItem =
  createFormItem<EditDashboardAccountRoleFormValues>();

export const useEditDashboardAccountRoleForm = ({
  dashboardAccountRole,
  onSubmit,
}: {
  dashboardAccountRole: DashboardAccountRole;
  onSubmit: (formValues: DashboardAccountRoleFormValuesWithPermissions) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(dashboardAccountRole);

  const editPermissions = useMemo(
    () =>
      dashboardAccountRole.dashboardAccountRolePermissions.filter(
        ({ type }) => type === DashboardAccountRolePermissionTypeEnum.Edit,
      ),
    [dashboardAccountRole],
  );

  const [permissions, setPermissions] = useState<Permissions>(
    Object.fromEntries(
      dashboardAccountRole.dashboardAccountRolePermissions
        .map(({ feature, type }) => {
          if (
            type === DashboardAccountRolePermissionTypeEnum.View &&
            editPermissions.some(({ feature: editFeature }) => editFeature === feature)
          ) {
            return null;
          }
          return [feature, type];
        })
        .filter(isNotNull),
    ) as Permissions,
  );

  const setPermission = useCallback(
    ({
      feature,
      type,
    }: {
      feature: DashboardFeatureEnum;
      type: DashboardAccountRolePermissionTypeEnum | undefined;
    }) => setPermissions((permissions) => ({ ...permissions, [feature]: type })),
    [setPermissions],
  );

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditDashboardAccountRoleFormValues;

    onSubmit({ ...values, permissions });
  }, [form, onSubmit, permissions]);

  return { form, initialValues, submit, permissions, setPermission };
};
