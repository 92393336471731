import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditOptionTecAggregationOptionFormItem } from "../useEditOptionTecAggregationOptionForm";

type Props = {
  loading?: boolean;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const TecAggregationOptionChoiceTable = memo<Props>(({ loading }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "optionName",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ option }) => [
              option?.choices.map((choice) => choice.name),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue(["option", "choices", field.name, "name"]);

              return <span>{choiceName}</span>;
            }}
          </EditOptionTecAggregationOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "menuCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "menuCode"]}
            rules={[
              {
                pattern: /^([-]?[1-9][0-9]*|0)$/,
                message: "数字で入力してください",
                required: true,
              },
            ]}
            required
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "name"]}
            rules={[
              { required: true, max: 25, message: "メニュー名称を25文字以内で入力してください" },
            ]}
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "dpCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "dpCode"]}
            rules={[{ pattern: /^\d{1,4}$/, message: "数字で入力してください", required: true }]}
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "dpName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "dpName"]}
            rules={[{ required: true, max: 20, message: "20文字以下で入力してください" }]}
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "gpCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "gpCode"]}
            rules={[{ pattern: /^\d{1,4}$/, message: "数字で入力してください", required: true }]}
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "gpName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionTecAggregationOptionFormItem
            noStyle
            name={[field.name, "tecAggregationMenu", "gpName"]}
            rules={[{ max: 20, message: "20文字以下で入力してください", required: true }]}
          >
            <InputCode />
          </EditOptionTecAggregationOptionFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={["option", "choices"]}>
      {(optionChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={optionChoiceFields}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
