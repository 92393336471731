import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddRoleFormItem } from "../useAddRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddRoleFormItem
    label="キッチンプリンター名"
    name="name"
    rules={[{ required: true, message: "キッチンプリンター名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddRoleFormItem>
));
