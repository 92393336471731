import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Popconfirm, Tag, Tooltip, Typography } from "antd";
import { dashboardFeatures, getDashboardFeatureName } from "models/dashboardFeature";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { FormHelp } from "components/Form/FormHelp";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { PermissionLabel } from "constants/dashboardAccountRolePermissionLabels";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { DashboardAccountRole } from "pages/DashboardAccountRoles/types";
import { DashboardAccountRolePermissionTypeEnum } from "types/graphql";

const { Paragraph } = Typography;

const dashboardFeatureKeys = Object.keys(dashboardFeatures);

const Container = styled.div`
  table {
    /* NOTE: table-cell 内の要素の高さを 100 % にするために必要 */
    height: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`;

type Props = {
  loading: boolean;
  dashboardAccountRoles: DashboardAccountRole[];
  onDelete: ({ dashboardAccountRoleId }: { dashboardAccountRoleId: string }) => void;
};

export const DashboardAccountRoleTable = memo<Props>(
  ({ loading, dashboardAccountRoles, onDelete }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = useMemo(
      () => [
        {
          title: "操作権限",
          render(_: unknown, { name }: DashboardAccountRole) {
            return name;
          },
        },
        {
          title: <FormHelp {...PermissionLabel.Editable} />,
          render(_: unknown, { dashboardAccountRolePermissions }: DashboardAccountRole) {
            const permissions = dashboardAccountRolePermissions.filter(
              ({ type }) => type === DashboardAccountRolePermissionTypeEnum.Edit,
            );
            const sortedPermissions = permissions.sort(
              (a, b) =>
                dashboardFeatureKeys.indexOf(a.feature) - dashboardFeatureKeys.indexOf(b.feature),
            );

            return (
              <Column>
                {sortedPermissions.map(({ id, feature }) => (
                  <Tag key={id} color="blue">
                    {getDashboardFeatureName(feature)}
                  </Tag>
                ))}
              </Column>
            );
          },
        },
        {
          title: <FormHelp {...PermissionLabel.Viewable} />,
          render(_: unknown, { dashboardAccountRolePermissions }: DashboardAccountRole) {
            const editablePermissions = dashboardAccountRolePermissions.filter(
              ({ type }) => type === DashboardAccountRolePermissionTypeEnum.Edit,
            );
            const permissions = dashboardAccountRolePermissions.filter(
              ({ feature, type }) =>
                type === DashboardAccountRolePermissionTypeEnum.View &&
                // Edit 権限もシステム的には View 権限を持つので表示上は除外する
                !editablePermissions.some(
                  ({ feature: editableFeature }) => feature === editableFeature,
                ),
            );
            const sortedPermissions = permissions.sort(
              (a, b) =>
                dashboardFeatureKeys.indexOf(a.feature) - dashboardFeatureKeys.indexOf(b.feature),
            );

            return (
              <Column>
                {sortedPermissions.map(({ id, feature }) => (
                  <Tag key={id} color="blue">
                    {getDashboardFeatureName(feature)}
                  </Tag>
                ))}
              </Column>
            );
          },
        },
        {
          title: <FormHelp {...PermissionLabel.NotViewable} />,
          render(_: unknown, { dashboardAccountRolePermissions }: DashboardAccountRole) {
            const features = dashboardFeatureKeys.filter(
              (dashboardFeature) =>
                !dashboardAccountRolePermissions.some(
                  ({ feature }) => feature === dashboardFeature,
                ),
            );

            return (
              <Column>
                {features.map((feature) => (
                  <Tag key={feature} color="blue">
                    {getDashboardFeatureName(feature)}
                  </Tag>
                ))}
              </Column>
            );
          },
        },
        ...(canAccess("editDashboardAccountRole")
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                render(_: unknown, { id }: DashboardAccountRole) {
                  return (
                    <IconLink to={`/setting/accountRole/${id}/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                },
              } as const,
            ]
          : []),
        ...(canAccess("addDashboardAccountRole")
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                render(_: string, { id }: DashboardAccountRole) {
                  return (
                    <Tooltip title="コピーして新規作成">
                      <IconLink to={`/setting/accountRole/add?id=${id}`}>
                        <CopyIcon />
                      </IconLink>
                    </Tooltip>
                  );
                },
              } as const,
            ]
          : []),
        ...(isFeatureEnabled("deleteDashboardAccountRole")
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                render(_: string, { id, dashboardAccounts }: DashboardAccountRole) {
                  return dashboardAccounts.length > 0 ? (
                    <Tooltip title="権限が紐づいたアカウントがあります。削除するにはアカウント一覧から紐づけを解除してください。">
                      <DeleteIcon disabled />
                    </Tooltip>
                  ) : (
                    <Popconfirm
                      title={
                        <>
                          <Paragraph>権限を削除しますか？</Paragraph>
                          <Paragraph>一度削除した権限を元に戻すことはできません。</Paragraph>
                        </>
                      }
                      okText="はい"
                      cancelText="キャンセル"
                      onConfirm={() => {
                        onDelete({ dashboardAccountRoleId: id });
                      }}
                    >
                      <DeleteIcon />
                    </Popconfirm>
                  );
                },
              } as const,
            ]
          : []),
      ],
      [canAccess, isFeatureEnabled, onDelete],
    );

    return (
      <Container>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={dashboardAccountRoles}
          loading={loading}
          bordered
          pagination={pagination}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        />
      </Container>
    );
  },
);
