import React, { memo } from "react";
import styled from "styled-components";
import { Button, Select, Tooltip } from "antd";
import { SelectProps } from "antd/lib/select";
import { ClearOutlined } from "@ant-design/icons";

import { Shop } from "pages/QrCodes/types";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const ShopSelect = memo<SelectProps<string>>((props) => <Select<string> {...props} />);

const StyledShopSelect = styled(ShopSelect)`
  flex: 4;
  margin-right: 16px;
`;

export type Selectors = {
  shopId?: string;
};

type Props = {
  shops: Shop[];
  selectors: Selectors;
  setSelectors: (selectors: Selectors) => void;
};

export const ShopSelectors = memo<Props>(({ shops, selectors, setSelectors }) => {
  const hasSelectors = Object.keys(selectors).length === 0;

  const onChangeShopId = (shopId: string) => setSelectors({ ...selectors, shopId });

  const clearSelectors = () => setSelectors({});

  return (
    <Wrapper>
      <StyledShopSelect
        placeholder="店舗を選択"
        value={selectors.shopId}
        onChange={onChangeShopId}
        showSearch
        options={shops.map((shop) => ({ label: shop.name, value: shop.shopId }))}
        optionFilterProp="label"
      />
      <Tooltip title="選択をクリア">
        <Button
          shape="circle"
          icon={<ClearOutlined />}
          disabled={hasSelectors}
          onClick={clearSelectors}
        />
      </Tooltip>
    </Wrapper>
  );
});
