import React, { memo, useCallback } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { PlanOption } from "../types";

import { PlanChoiceTable } from "./PlanChoiceTable";

type Props = {
  loading?: boolean;
  planId: number;
  planOptions: PlanOption[];
};

export const PlanOptionTable = memo<Props>(({ loading, planId, planOptions }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [{ title: "オプション名", dataIndex: "name", key: "name" }];

  const expandedRowRender = useCallback(
    ({ planOptionId, planChoices }: PlanOption) => (
      <PlanChoiceTable
        planId={planId}
        planOptionId={planOptionId}
        planChoices={planChoices.map((c) => ({ ...c, winboardMenu: c.winboardMenus?.[0] }))}
      />
    ),
    [planId],
  );

  return (
    <Table
      rowKey="planOptionId"
      columns={columns}
      dataSource={planOptions}
      loading={loading}
      bordered
      expandable={{ expandedRowRender, defaultExpandAllRows: true }}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      shouldDisableMaxWidthScroll
    />
  );
});
