import { useAsyncFn } from "react-use";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { toDataURL } from "qrcode";
import { stringify } from "query-string";
import { getSelfWebUrl } from "util/selfWeb";

import { message } from "components/antd/message";
import { useLiffId } from "hooks/useLiffId";
import { useShopSelect } from "hooks/useShopSelect";

import { useDownloadQrModalGetTablesLazyQuery } from "./queries";

const getQrFileName = (shopName: string, tableName: string) => `${shopName}_${tableName}.png`;

const getRedirectQRUrl = ({
  shopId,
  tableCode,
  liffId,
}: {
  shopId: string;
  tableCode: string;
  liffId: string | undefined;
}): string => {
  const queryParams = {
    shopId,
    tableCode,
    ...(liffId ? { liffId } : {}),
  };

  return getSelfWebUrl(`gateway?${stringify(queryParams)}`);
};

const saveQrCodes = async (name: string, urls: { fileName: string; url: string }[]) => {
  const zip = new JSZip();

  await Promise.all(
    urls.map(async ({ fileName, url }) => {
      const image = await toDataURL(url);
      zip.file(fileName, image.substring(22), { base64: true });
    }),
  );

  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, `${name}.zip`);
};

export const useDownloadQrCodes = () => {
  const { currentShop } = useShopSelect();
  const { liffId } = useLiffId();

  const [getTables] = useDownloadQrModalGetTablesLazyQuery();

  const [{ loading }, downloadQrCodes] = useAsyncFn(async () => {
    if (!currentShop?.shopId) return message.error("店舗が選択されていません");

    try {
      const { data } = await getTables({ variables: { shopId: currentShop.shopId } });
      const tables = data?.table ?? [];

      if (!tables.length) return message.error("テーブルが存在しない店舗です");

      const redirectQrCodes = tables.map(({ shopId, name, code }) => ({
        tableName: name,
        fileName: getQrFileName(currentShop.name, name),
        url: getRedirectQRUrl({ shopId, tableCode: code, liffId }),
      }));

      const redirectQrFolderName = `${currentShop.name}_QRコード`;
      await saveQrCodes(redirectQrFolderName, redirectQrCodes);
    } catch (error) {
      message.error("ダウンロードに失敗しました。再度お試しください。");
    }
  }, [currentShop, getTables, liffId]);

  return { downloadQrCodes, loading };
};
