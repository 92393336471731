import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { isNotNull } from "util/type/primitive";

import { AsyncSwitch } from "components/AsyncSwitch";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { Menu, Role } from "../types";

type Props = {
  loading?: boolean;
  menus: Menu[];
  roles: Role[];
  shopId?: string;
  onDelete: ({ menuId, serial }: { menuId: string; serial: number }) => void;
  onUpdateIsVisibleForCustomer: ({
    menuId,
    isVisibleForCustomer,
  }: {
    menuId: string;
    isVisibleForCustomer: boolean;
  }) => Promise<void>;
  onTogglePrinting: ({ menuId, roleId }: { menuId: number; roleId: string }) => Promise<void>;
};

export const GiftsTable = memo<Props>(
  ({ loading, menus, roles, onDelete, onUpdateIsVisibleForCustomer, onTogglePrinting }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns: ColumnsType<Menu> = [
      {
        title: "画像",
        align: "center" as const,
        width: 100,
        render(_: string, { imageUrl }: Menu) {
          return <Thumbnail url={imageUrl} width={64} height={64} />;
        },
      },
      {
        title: "メニュー名",
        dataIndex: "name",
        width: 150,
        render(text: string, { menuId }: Menu) {
          return canAccess("editGift") ? (
            <Link to={`/gift/${menuId}/edit`}>
              <Button type="link">{text}</Button>
            </Link>
          ) : (
            text
          );
        },
      },
      {
        title: "伝票表示名",
        width: 200,
        render(_: unknown, { receiptDisplayName }: Menu) {
          return receiptDisplayName;
        },
      },
      {
        title: "表示設定",
        align: "center" as const,
        width: 120,
        render(_: string, menu: Menu, index: number) {
          const { shopMenus } = menu;
          const shopMenu = shopMenus[0];

          if (!shopMenu?.menuId) throw new Error("shopMenu not found for gift menu");

          const { isVisibleForCustomer, menuId } = shopMenu;
          return (
            <AsyncSwitch
              data-cy={`gift_${index}_isVisibleForCustomer`}
              checked={isVisibleForCustomer}
              onChange={(isVisibleForCustomer) =>
                onUpdateIsVisibleForCustomer({ menuId, isVisibleForCustomer })
              }
              disabled={!isFeatureEnabled("editGiftDisplaySettings")}
            />
          );
        },
      },
      {
        title: "キッチンプリンター",
        width: 160,
        children: roles.map((role, roleIndex) => ({
          title: role.name,
          align: "center" as const,
          width: 120,
          render(_: string, menu: Menu, rowIndex: number) {
            const { shopMenus, menuId } = menu;
            const shopMenu = shopMenus[0];

            if (!shopMenu) throw new Error("shopMenu not found for gift menu");

            const enabled = shopMenu.shopMenuKitchenRoles.some((r) => r.roleId === role.id);

            return (
              <AsyncSwitch
                key={role.roleId.toString()}
                data-cy={`gift_${rowIndex}_role_${roleIndex}`}
                checked={enabled}
                onChange={() => onTogglePrinting({ menuId, roleId: role.id })}
                disabled={!isFeatureEnabled("editGiftRole")}
              />
            );
          },
        })),
      },
      ...(isFeatureEnabled("deleteGift")
        ? [
            {
              title: "",
              align: "center" as const,
              fixed: "right" as const,
              width: 60,
              render(_: string, { id, menuId }: Menu) {
                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>推しエール対象を削除しますか？</Paragraph>
                        <Paragraph>
                          一度削除した推しエール対象を元に戻すことはできません。
                        </Paragraph>
                        <Paragraph>売上の記録は維持されます。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => {
                      onDelete({ menuId: id, serial: menuId });
                    }}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                );
              },
            },
          ]
        : []),
    ].filter(isNotNull);

    return (
      <Table
        rowKey="menuId"
        columns={columns}
        dataSource={menus}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
