import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { Menu, TecAggregationMenu } from "../types";

export type EditTecAggregationMenuFormValues = Pick<
  TecAggregationMenu,
  "menuCode" | "dpCode" | "dpName" | "gpCode" | "gpName"
> & { name: string };

export const EditTecAggregationMenuFormItem = createFormItem<EditTecAggregationMenuFormValues>();

export const useEditTecAggregationMenuForm = ({
  menu,
  tecAggregationMenu,
  onSubmit,
}: {
  menu?: Menu;
  tecAggregationMenu?: TecAggregationMenu;
  onSubmit: (
    args: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditTecAggregationMenuFormValues>(
    () => ({
      name: tecAggregationMenu?.name ?? menu?.name ?? "",
      menuCode: tecAggregationMenu?.menuCode ?? "",
      dpCode: tecAggregationMenu?.dpCode ?? "",
      dpName: tecAggregationMenu?.dpName ?? "",
      gpCode: tecAggregationMenu?.gpCode ?? "",
      gpName: tecAggregationMenu?.gpName ?? "",
    }),
    [menu, tecAggregationMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditTecAggregationMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
