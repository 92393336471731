import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, FormInstance, Menu, Modal } from "antd";
import { ModalProps } from "antd/lib";

import { Form } from "components/antd/Form";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";

import { CompulsoryAppetizerModalRoute, Menu as MenuType } from "../types";
import { CompulsoryAppetizerSelectFormValues } from "../useCompulsoryAppetizerSelectForm";

import { SelectAppetizerMenu } from "./SelectAppetizerMenuForm";
import { SelectAutoOrderTimeForm } from "./SelectAutoOrderTimeForm";

const StyledFormSection = styled(FormSection)<{ shouldDisplay: boolean }>`
  padding: 0;
  ${({ shouldDisplay }) => (shouldDisplay ? "" : "display: none;")}
`;

type Props = {
  initialValues: CompulsoryAppetizerSelectFormValues | null;
  menus: MenuType[];
  onSubmit: () => void;
  onCancel: () => void;
  form: FormInstance<CompulsoryAppetizerSelectFormValues>;
  modalRoute: CompulsoryAppetizerModalRoute;
} & ModalProps;

export const AutoCompulsoryAppetizerMenuModal = memo<Props>(
  ({ form, menus, initialValues, onSubmit, onCancel, modalRoute, ...props }) => {
    const selectedKeys = useMemo(() => [modalRoute], [modalRoute]);

    return (
      <Modal
        title="お通しメニューの設定"
        width={900}
        onOk={onSubmit}
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>,
          <Button key="add" type="primary" onClick={onSubmit}>
            更新
          </Button>,
        ]}
        {...props}
      >
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key={CompulsoryAppetizerModalRoute.MenuSelect}>
            <Link to={`/menu/autoCompulsoryAppetizer/${CompulsoryAppetizerModalRoute.MenuSelect}`}>
              お通し商品の選択
            </Link>
          </Menu.Item>
          <Menu.Item key={CompulsoryAppetizerModalRoute.TimeSelect}>
            <Link to={`/menu/autoCompulsoryAppetizer/${CompulsoryAppetizerModalRoute.TimeSelect}`}>
              自動注文の時間設定
            </Link>
          </Menu.Item>
        </Menu>
        <Spacer size={20} />
        <Form form={form} layout="vertical" initialValues={initialValues ?? undefined}>
          <StyledFormSection
            shouldDisplay={modalRoute === CompulsoryAppetizerModalRoute.MenuSelect}
          >
            <SelectAppetizerMenu menus={menus} />
          </StyledFormSection>
          <StyledFormSection
            shouldDisplay={modalRoute === CompulsoryAppetizerModalRoute.TimeSelect}
          >
            <SelectAutoOrderTimeForm />
          </StyledFormSection>
        </Form>
      </Modal>
    );
  },
);
