import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { Category, Role } from "pages/PlanMainOptionPrintings/types";

export type FilterConditions = {
  name?: string;
  categoryIds?: number[];
  roleIds?: number[];
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  categories: Category[];
  roles: Role[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const PlanMainOptionPrintingFilter = memo<Props>(
  ({
    categories,
    roles,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => (
    <Wrapper>
      <Col flex="1 0">
        <Select<number[]>
          mode="multiple"
          placeholder="カテゴリ"
          defaultValue={[]}
          value={filterConditions.categoryIds ?? []}
          onChange={(categoryIds) => updateFilterCondition({ categoryIds })}
          optionFilterProp="label"
          options={categories.map(({ categoryId, name, shopSideName }) => ({
            label: shopSideName || name,
            value: categoryId,
          }))}
        />
      </Col>
      <Col flex="1 0">
        <Input
          placeholder="プランオプション名"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
        />
      </Col>
      <Col flex="1 0">
        <Select<number[]>
          mode="multiple"
          placeholder="キッチンプリンター"
          defaultValue={[]}
          value={filterConditions.roleIds ?? []}
          onChange={(roleIds) => updateFilterCondition({ roleIds })}
          optionFilterProp="label"
          options={roles.map(({ roleId, name }) => ({
            label: name,
            value: roleId,
          }))}
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
