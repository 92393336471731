import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DashboardCurrentSales = gql`
    query DashboardCurrentSales($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeSales
      activeUnitSales
      activeNumberOfPeople
      activeNumberOfGroup
    }
  }
}
    `;
export type DashboardCurrentSalesQueryVariables = Types.Exact<{
  input: Types.QueryCurrentSalesInput;
}>;


export type DashboardCurrentSalesQuery = (
  { __typename?: 'query_root' }
  & { dashboardCurrentSaleses: (
    { __typename?: 'CurrentSaleses' }
    & { currentSaleses: Array<(
      { __typename?: 'CurrentSales' }
      & Pick<Types.CurrentSales, 'currentSales' | 'currentUnitSales' | 'currentTaxExcludedSales' | 'currentNumberOfPeople' | 'currentNumberOfGroup' | 'activeSales' | 'activeUnitSales' | 'activeNumberOfPeople' | 'activeNumberOfGroup'>
    )> }
  ) }
);


export const DashboardCurrentSalesDocument = gql`
    query DashboardCurrentSales($input: QueryCurrentSalesInput!) {
  dashboardCurrentSaleses(input: $input) {
    currentSaleses {
      currentSales
      currentUnitSales
      currentTaxExcludedSales
      currentNumberOfPeople
      currentNumberOfGroup
      activeSales
      activeUnitSales
      activeNumberOfPeople
      activeNumberOfGroup
    }
  }
}
    `;

/**
 * __useDashboardCurrentSalesQuery__
 *
 * To run a query within a React component, call `useDashboardCurrentSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCurrentSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCurrentSalesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCurrentSalesQuery(baseOptions: Apollo.QueryHookOptions<DashboardCurrentSalesQuery, DashboardCurrentSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCurrentSalesQuery, DashboardCurrentSalesQueryVariables>(DashboardCurrentSalesDocument, options);
      }
export function useDashboardCurrentSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCurrentSalesQuery, DashboardCurrentSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCurrentSalesQuery, DashboardCurrentSalesQueryVariables>(DashboardCurrentSalesDocument, options);
        }
export type DashboardCurrentSalesQueryHookResult = ReturnType<typeof useDashboardCurrentSalesQuery>;
export type DashboardCurrentSalesLazyQueryHookResult = ReturnType<typeof useDashboardCurrentSalesLazyQuery>;
export type DashboardCurrentSalesQueryResult = Apollo.QueryResult<DashboardCurrentSalesQuery, DashboardCurrentSalesQueryVariables>;