import React, { memo } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { PikaichiMenuInsertInput } from "types/graphql";

import { Choice } from "../../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { useEditChoicePikaichiMenuForm } from "./useEditChoicePikaichiMenuForm";

type Props = {
  choice: Choice;
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditChoicePikaichiMenuForm = memo<Props>(({ choice, onClose, onSubmit, loading }) => {
  const pikaichiMenu = choice?.pikaichiMenuChoices?.[0]?.pikaichiMenu;

  const { form, initialValues, submit } = useEditChoicePikaichiMenuForm({
    choice,
    pikaichiMenu,
    onSubmit,
  });

  return (
    <Form name="pikaichiMenu" form={form} layout="vertical" initialValues={initialValues}>
      <Row>
        <Col span={24}>
          <PikaichiMenuCdField />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PikaichiMenuNameField />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PikaichiBumonCdField />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PikaichiBumonNameField />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PikaichiCategoryCdField />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PikaichiCategoryNameField />
        </Col>
      </Row>

      <FormActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button type="primary" onClick={submit} loading={loading}>
          保存
        </Button>
      </FormActions>
    </Form>
  );
});
