import React, { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import dayjs from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { FormHelp } from "components/Form/FormHelp";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { MenuTypeEnum } from "types/graphql";

import { AutoCompulsoryAppetizerMenuModal } from "./AutoCompulsoryAppetizerMenuModal";
import { CompulsoryAppetizerConfigTable } from "./CompulsoryAppetizerConfigTable";
import {
  useDeleteAutoCompulsoryAppetizerConfigMutation,
  useGetMenuAutoCompulsoryAppetizerConfigQuery,
  useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation,
} from "./queries";
import { CompulsoryAppetizerModalRoute } from "./types";
import { useCompulsoryAppetizerSelectForm } from "./useCompulsoryAppetizerSelectForm";

const RedText = styled.p`
  margin: 0;
  margin-right: 5px;
  color: red;
`;

const TableTitle = styled.p`
  margin: 0;
  display: inline-flex;
`;

const StyledFormActions = styled(FormActions)`
  align-items: flex-end;
  justify-content: space-between;
`;

type Props = {
  company: {
    id: string;
    companyId: number;
  } | null;
};

const CompulsoryAppetizerPath = "/menu/autoCompulsoryAppetizer";
const MenuSelectPath = `${CompulsoryAppetizerPath}/${CompulsoryAppetizerModalRoute.MenuSelect}`;
export const TimeSelectPath = `${CompulsoryAppetizerPath}/${CompulsoryAppetizerModalRoute.TimeSelect}`;

export const CompulsoryAppetizerSelectForm = memo<Props>(({ company }) => {
  const navigate = useNavigate();

  const { modalRoute } = useParams<{ modalRoute: CompulsoryAppetizerModalRoute }>();

  const { data, refetch } = useGetMenuAutoCompulsoryAppetizerConfigQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );

  const menus = (data?.company?.[0]?.menus ?? []).filter(
    ({ menuType }) => menuType !== MenuTypeEnum.FaveYell,
  );
  const compulsoryAppetizerConfig = data?.compulsoryAppetizerConfig[0];

  const [setCompulsoryAppetizerConfigMutation] =
    useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation();

  const [deleteAutoAppetizerConfig] = useDeleteAutoCompulsoryAppetizerConfigMutation();

  const submitChangeCompulsoryAppetizerMenu = useCallback(
    async ({
      autoOrderStartTime,
      autoOrderEndTime,
      menuId,
    }: {
      autoOrderStartTime: dayjs.Dayjs;
      autoOrderEndTime: dayjs.Dayjs;
      menuId: string;
    }) => {
      if (!company) return;

      try {
        await setCompulsoryAppetizerConfigMutation({
          variables: {
            autoCompulsoryAppetizer: {
              companyId: company.id,
              menuId,
              autoOrderStartTime: autoOrderStartTime.format("HH:mm:00"),
              autoOrderEndTime: autoOrderEndTime.format("HH:mm:00"),
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, setCompulsoryAppetizerConfigMutation],
  );

  const onDeleteCompulsoryAppetizerConfig = useCallback(async () => {
    if (!company?.id) return;

    try {
      await deleteAutoAppetizerConfig({
        variables: { companyId: company.id },
      });

      refetch();

      message.success("編集を保存しました");
    } catch (err) {
      message.error("編集の保存に失敗しました");
    }
  }, [company, refetch, deleteAutoAppetizerConfig]);

  const onFormValidationError = useCallback(
    ({ formValidationError }: { formValidationError: ValidateErrorEntity }) => {
      // NOTE: since there are two tabs in this modal, we should navigate to the time select page if there are any errors there
      const missingTimeValue = formValidationError.errorFields.find(
        ({ name }) => name[0] === "autoOrderStartTime" || name[0] === "autoOrderEndTime",
      );

      if (missingTimeValue) navigate(TimeSelectPath);
    },
    [navigate],
  );

  const { form, initialValues, submit, deleteCompulsoryAppetizerConfig } =
    useCompulsoryAppetizerSelectForm({
      onSubmit: submitChangeCompulsoryAppetizerMenu,
      onDeleteCompulsoryAppetizerConfig,
      compulsoryAppetizerConfig,
    });

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      await submit();
      refetch();
      navigate(CompulsoryAppetizerPath);
    } catch (err) {
      if (isValidateErrorEntity(err)) onFormValidationError({ formValidationError: err });
    }
  }, [form, submit, navigate, refetch, onFormValidationError]);

  const openMenuModal = useCallback(() => navigate(MenuSelectPath), [navigate]);

  const handleOnCancel = useCallback(() => navigate(CompulsoryAppetizerPath), [navigate]);

  const hasCurrentConfigMenu = Boolean(compulsoryAppetizerConfig);

  return (
    <FormContent>
      <Form form={form} layout="vertical">
        <StyledFormActions>
          <TableTitle>
            <RedText>*</RedText>
            <FormHelp label="お通しメニューの設定" help="自動化できるメニューは1つまでです" />
          </TableTitle>
          {isFeatureEnabled("editCompulsoryAppetizer") && (
            <Button
              type={hasCurrentConfigMenu ? "default" : "primary"}
              disabled={hasCurrentConfigMenu}
              onClick={openMenuModal}
            >
              追加
            </Button>
          )}
        </StyledFormActions>
        <Spacer size={10} />
        <CompulsoryAppetizerConfigTable
          compulsoryAppetizerConfig={compulsoryAppetizerConfig ?? null}
          onDeleteCompulsoryAppetizerConfig={deleteCompulsoryAppetizerConfig}
        />
        {modalRoute && (
          <AutoCompulsoryAppetizerMenuModal
            form={form}
            menus={menus}
            initialValues={initialValues}
            open
            onSubmit={handleSubmit}
            onCancel={handleOnCancel}
            modalRoute={modalRoute}
          />
        )}
      </Form>
    </FormContent>
  );
});
