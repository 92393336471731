import React, { CSSProperties, memo, useRef } from "react";
import { DragObjectWithType, useDrag, useDrop } from "react-dnd";
import { TableProps } from "antd/lib/table";

import { Table } from "components/antd/Table";
import { Loading } from "components/Loading";
import { defaultTableScrollHeight } from "constants/defaultTableScrollHeight";

type BodyRowProps = {
  type: string;
  index: number;
  className: string;
  style?: CSSProperties;
  onMove: (dragIndex: number, hoverIndex: number) => void;
};

const BodyRow = memo<BodyRowProps>(({ type, index, className, style, onMove, ...props }) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: DragObjectWithType & { index: number }) => {
      onMove(item.index, index);
    },
  });

  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...props}
    />
  );
});

// eslint-disable-next-line react/display-name
const createBodyRow =
  (type: string): React.ComponentType<Omit<BodyRowProps, "type">> =>
  (props) =>
    <BodyRow type={type} {...props} />;

type Props<RecordType extends object> = Omit<TableProps<RecordType>, "components" | "onRow"> & {
  type: string;
  onMove: ((dragIndex: number, hoverIndex: number) => void) | null;
  loading?: boolean;
};

export const SortableTable = <RecordType extends object = any>({
  type,
  onMove,
  loading = false,
  ...props
}: Props<RecordType>) => {
  const components = {
    body: {
      row: createBodyRow(type),
    },
  };

  return (
    <Table<RecordType>
      components={components}
      onRow={onMove ? (_, index) => ({ index, onMove } as any) : undefined}
      loading={{
        spinning: loading,
        indicator: <Loading />,
        size: "large" as const,
      }}
      {...props}
      scroll={{
        // Allowing only scroll property to be specified while giving the other a default value
        x: props.scroll?.x ?? "max-content",
        y: props.scroll?.y ?? defaultTableScrollHeight,
      }}
    />
  );
};
