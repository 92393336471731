import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditSalesBudgetGetSalesForBudget = gql`
    query EditSalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;
export const EditSalesBudgetGetBudget = gql`
    query EditSalesBudgetGetBudget($id: uuid!, $shopId: uuid!) {
  monthlySalesBudget(where: {id: {_eq: $id}, shopId: {_eq: $shopId}}) {
    id
    shopId
    businessDate
    taxExcludedAmount
    dailySalesBudgets {
      id
      businessDate
      taxExcludedAmount
    }
  }
}
    `;
export const EditSalesBudgetGetSalesBudgetAllocationSetting = gql`
    query EditSalesBudgetGetSalesBudgetAllocationSetting($shopId: uuid!) {
  salesBudgetAllocationSetting(where: {shopId: {_eq: $shopId}}) {
    id
    monRate
    tueRate
    wedRate
    thuRate
    friRate
    satRate
    sunRate
  }
}
    `;
export const EditSalesBudgetUpdateBudget = gql`
    mutation EditSalesBudgetUpdateBudget($id: uuid!, $monthlySalesBudget: monthlySalesBudget_set_input!, $dailySalesBudgets: [dailySalesBudget_insert_input!]!) {
  update_monthlySalesBudget(_set: $monthlySalesBudget, where: {id: {_eq: $id}}) {
    affected_rows
  }
  insert_dailySalesBudget(
    objects: $dailySalesBudgets
    on_conflict: {constraint: dailySalesBudget_shopId_businessDate_key, update_columns: [taxExcludedAmount, updatedAt]}
  ) {
    affected_rows
  }
}
    `;
export const EditSalesBudgetInsertSalesBudgetAllocationSetting = gql`
    mutation EditSalesBudgetInsertSalesBudgetAllocationSetting($allocationSetting: salesBudgetAllocationSetting_insert_input!) {
  insert_salesBudgetAllocationSetting_one(
    object: $allocationSetting
    on_conflict: {constraint: salesBudgetAllocationSetting_shopId_key, update_columns: [monRate, tueRate, wedRate, thuRate, friRate, satRate, sunRate]}
  ) {
    id
  }
}
    `;
export type EditSalesBudgetGetSalesForBudgetQueryVariables = Types.Exact<{
  input: Types.SalesForBudgetInput;
}>;


export type EditSalesBudgetGetSalesForBudgetQuery = (
  { __typename?: 'query_root' }
  & { salesForBudget: (
    { __typename?: 'SalesForBudget' }
    & { salesByMonths?: Types.Maybe<(
      { __typename?: 'SalesByMonths' }
      & { monthlySalesAmount: Array<(
        { __typename?: 'MonthlySalesAmount' }
        & Pick<Types.MonthlySalesAmount, 'businessDate' | 'taxExcludedSalesAmount'>
      )> }
    )>, averageAndMaxSalesByDayOfWeek?: Types.Maybe<Array<(
      { __typename?: 'AverageAndMaxSalesByDayOfWeek' }
      & Pick<Types.AverageAndMaxSalesByDayOfWeek, 'dayOfWeek' | 'averageTaxExcludedSalesAmount' | 'maxTaxExcludedSalesAmount'>
    )>> }
  ) }
);

export type EditSalesBudgetGetBudgetQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
}>;


export type EditSalesBudgetGetBudgetQuery = (
  { __typename?: 'query_root' }
  & { monthlySalesBudget: Array<(
    { __typename?: 'monthlySalesBudget' }
    & Pick<Types.MonthlySalesBudget, 'id' | 'shopId' | 'businessDate' | 'taxExcludedAmount'>
    & { dailySalesBudgets: Array<(
      { __typename?: 'dailySalesBudget' }
      & Pick<Types.DailySalesBudget, 'id' | 'businessDate' | 'taxExcludedAmount'>
    )> }
  )> }
);

export type EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditSalesBudgetGetSalesBudgetAllocationSettingQuery = (
  { __typename?: 'query_root' }
  & { salesBudgetAllocationSetting: Array<(
    { __typename?: 'salesBudgetAllocationSetting' }
    & Pick<Types.SalesBudgetAllocationSetting, 'id' | 'monRate' | 'tueRate' | 'wedRate' | 'thuRate' | 'friRate' | 'satRate' | 'sunRate'>
  )> }
);

export type EditSalesBudgetUpdateBudgetMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  monthlySalesBudget: Types.MonthlySalesBudgetSetInput;
  dailySalesBudgets: Array<Types.DailySalesBudgetInsertInput> | Types.DailySalesBudgetInsertInput;
}>;


export type EditSalesBudgetUpdateBudgetMutation = (
  { __typename?: 'mutation_root' }
  & { update_monthlySalesBudget?: Types.Maybe<(
    { __typename?: 'monthlySalesBudget_mutation_response' }
    & Pick<Types.MonthlySalesBudgetMutationResponse, 'affected_rows'>
  )>, insert_dailySalesBudget?: Types.Maybe<(
    { __typename?: 'dailySalesBudget_mutation_response' }
    & Pick<Types.DailySalesBudgetMutationResponse, 'affected_rows'>
  )> }
);

export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables = Types.Exact<{
  allocationSetting: Types.SalesBudgetAllocationSettingInsertInput;
}>;


export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_salesBudgetAllocationSetting_one?: Types.Maybe<(
    { __typename?: 'salesBudgetAllocationSetting' }
    & Pick<Types.SalesBudgetAllocationSetting, 'id'>
  )> }
);


export const EditSalesBudgetGetSalesForBudgetDocument = gql`
    query EditSalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;

/**
 * __useEditSalesBudgetGetSalesForBudgetQuery__
 *
 * To run a query within a React component, call `useEditSalesBudgetGetSalesForBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSalesBudgetGetSalesForBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSalesBudgetGetSalesForBudgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSalesBudgetGetSalesForBudgetQuery(baseOptions: Apollo.QueryHookOptions<EditSalesBudgetGetSalesForBudgetQuery, EditSalesBudgetGetSalesForBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSalesBudgetGetSalesForBudgetQuery, EditSalesBudgetGetSalesForBudgetQueryVariables>(EditSalesBudgetGetSalesForBudgetDocument, options);
      }
export function useEditSalesBudgetGetSalesForBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSalesBudgetGetSalesForBudgetQuery, EditSalesBudgetGetSalesForBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSalesBudgetGetSalesForBudgetQuery, EditSalesBudgetGetSalesForBudgetQueryVariables>(EditSalesBudgetGetSalesForBudgetDocument, options);
        }
export type EditSalesBudgetGetSalesForBudgetQueryHookResult = ReturnType<typeof useEditSalesBudgetGetSalesForBudgetQuery>;
export type EditSalesBudgetGetSalesForBudgetLazyQueryHookResult = ReturnType<typeof useEditSalesBudgetGetSalesForBudgetLazyQuery>;
export type EditSalesBudgetGetSalesForBudgetQueryResult = Apollo.QueryResult<EditSalesBudgetGetSalesForBudgetQuery, EditSalesBudgetGetSalesForBudgetQueryVariables>;
export const EditSalesBudgetGetBudgetDocument = gql`
    query EditSalesBudgetGetBudget($id: uuid!, $shopId: uuid!) {
  monthlySalesBudget(where: {id: {_eq: $id}, shopId: {_eq: $shopId}}) {
    id
    shopId
    businessDate
    taxExcludedAmount
    dailySalesBudgets {
      id
      businessDate
      taxExcludedAmount
    }
  }
}
    `;

/**
 * __useEditSalesBudgetGetBudgetQuery__
 *
 * To run a query within a React component, call `useEditSalesBudgetGetBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSalesBudgetGetBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSalesBudgetGetBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditSalesBudgetGetBudgetQuery(baseOptions: Apollo.QueryHookOptions<EditSalesBudgetGetBudgetQuery, EditSalesBudgetGetBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSalesBudgetGetBudgetQuery, EditSalesBudgetGetBudgetQueryVariables>(EditSalesBudgetGetBudgetDocument, options);
      }
export function useEditSalesBudgetGetBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSalesBudgetGetBudgetQuery, EditSalesBudgetGetBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSalesBudgetGetBudgetQuery, EditSalesBudgetGetBudgetQueryVariables>(EditSalesBudgetGetBudgetDocument, options);
        }
export type EditSalesBudgetGetBudgetQueryHookResult = ReturnType<typeof useEditSalesBudgetGetBudgetQuery>;
export type EditSalesBudgetGetBudgetLazyQueryHookResult = ReturnType<typeof useEditSalesBudgetGetBudgetLazyQuery>;
export type EditSalesBudgetGetBudgetQueryResult = Apollo.QueryResult<EditSalesBudgetGetBudgetQuery, EditSalesBudgetGetBudgetQueryVariables>;
export const EditSalesBudgetGetSalesBudgetAllocationSettingDocument = gql`
    query EditSalesBudgetGetSalesBudgetAllocationSetting($shopId: uuid!) {
  salesBudgetAllocationSetting(where: {shopId: {_eq: $shopId}}) {
    id
    monRate
    tueRate
    wedRate
    thuRate
    friRate
    satRate
    sunRate
  }
}
    `;

/**
 * __useEditSalesBudgetGetSalesBudgetAllocationSettingQuery__
 *
 * To run a query within a React component, call `useEditSalesBudgetGetSalesBudgetAllocationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditSalesBudgetGetSalesBudgetAllocationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditSalesBudgetGetSalesBudgetAllocationSettingQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditSalesBudgetGetSalesBudgetAllocationSettingQuery(baseOptions: Apollo.QueryHookOptions<EditSalesBudgetGetSalesBudgetAllocationSettingQuery, EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditSalesBudgetGetSalesBudgetAllocationSettingQuery, EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>(EditSalesBudgetGetSalesBudgetAllocationSettingDocument, options);
      }
export function useEditSalesBudgetGetSalesBudgetAllocationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditSalesBudgetGetSalesBudgetAllocationSettingQuery, EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditSalesBudgetGetSalesBudgetAllocationSettingQuery, EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>(EditSalesBudgetGetSalesBudgetAllocationSettingDocument, options);
        }
export type EditSalesBudgetGetSalesBudgetAllocationSettingQueryHookResult = ReturnType<typeof useEditSalesBudgetGetSalesBudgetAllocationSettingQuery>;
export type EditSalesBudgetGetSalesBudgetAllocationSettingLazyQueryHookResult = ReturnType<typeof useEditSalesBudgetGetSalesBudgetAllocationSettingLazyQuery>;
export type EditSalesBudgetGetSalesBudgetAllocationSettingQueryResult = Apollo.QueryResult<EditSalesBudgetGetSalesBudgetAllocationSettingQuery, EditSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>;
export const EditSalesBudgetUpdateBudgetDocument = gql`
    mutation EditSalesBudgetUpdateBudget($id: uuid!, $monthlySalesBudget: monthlySalesBudget_set_input!, $dailySalesBudgets: [dailySalesBudget_insert_input!]!) {
  update_monthlySalesBudget(_set: $monthlySalesBudget, where: {id: {_eq: $id}}) {
    affected_rows
  }
  insert_dailySalesBudget(
    objects: $dailySalesBudgets
    on_conflict: {constraint: dailySalesBudget_shopId_businessDate_key, update_columns: [taxExcludedAmount, updatedAt]}
  ) {
    affected_rows
  }
}
    `;
export type EditSalesBudgetUpdateBudgetMutationFn = Apollo.MutationFunction<EditSalesBudgetUpdateBudgetMutation, EditSalesBudgetUpdateBudgetMutationVariables>;

/**
 * __useEditSalesBudgetUpdateBudgetMutation__
 *
 * To run a mutation, you first call `useEditSalesBudgetUpdateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSalesBudgetUpdateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSalesBudgetUpdateBudgetMutation, { data, loading, error }] = useEditSalesBudgetUpdateBudgetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      monthlySalesBudget: // value for 'monthlySalesBudget'
 *      dailySalesBudgets: // value for 'dailySalesBudgets'
 *   },
 * });
 */
export function useEditSalesBudgetUpdateBudgetMutation(baseOptions?: Apollo.MutationHookOptions<EditSalesBudgetUpdateBudgetMutation, EditSalesBudgetUpdateBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSalesBudgetUpdateBudgetMutation, EditSalesBudgetUpdateBudgetMutationVariables>(EditSalesBudgetUpdateBudgetDocument, options);
      }
export type EditSalesBudgetUpdateBudgetMutationHookResult = ReturnType<typeof useEditSalesBudgetUpdateBudgetMutation>;
export type EditSalesBudgetUpdateBudgetMutationResult = Apollo.MutationResult<EditSalesBudgetUpdateBudgetMutation>;
export type EditSalesBudgetUpdateBudgetMutationOptions = Apollo.BaseMutationOptions<EditSalesBudgetUpdateBudgetMutation, EditSalesBudgetUpdateBudgetMutationVariables>;
export const EditSalesBudgetInsertSalesBudgetAllocationSettingDocument = gql`
    mutation EditSalesBudgetInsertSalesBudgetAllocationSetting($allocationSetting: salesBudgetAllocationSetting_insert_input!) {
  insert_salesBudgetAllocationSetting_one(
    object: $allocationSetting
    on_conflict: {constraint: salesBudgetAllocationSetting_shopId_key, update_columns: [monRate, tueRate, wedRate, thuRate, friRate, satRate, sunRate]}
  ) {
    id
  }
}
    `;
export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutationFn = Apollo.MutationFunction<EditSalesBudgetInsertSalesBudgetAllocationSettingMutation, EditSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>;

/**
 * __useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation__
 *
 * To run a mutation, you first call `useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSalesBudgetInsertSalesBudgetAllocationSettingMutation, { data, loading, error }] = useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation({
 *   variables: {
 *      allocationSetting: // value for 'allocationSetting'
 *   },
 * });
 */
export function useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation(baseOptions?: Apollo.MutationHookOptions<EditSalesBudgetInsertSalesBudgetAllocationSettingMutation, EditSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSalesBudgetInsertSalesBudgetAllocationSettingMutation, EditSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>(EditSalesBudgetInsertSalesBudgetAllocationSettingDocument, options);
      }
export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutationHookResult = ReturnType<typeof useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation>;
export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutationResult = Apollo.MutationResult<EditSalesBudgetInsertSalesBudgetAllocationSettingMutation>;
export type EditSalesBudgetInsertSalesBudgetAllocationSettingMutationOptions = Apollo.BaseMutationOptions<EditSalesBudgetInsertSalesBudgetAllocationSettingMutation, EditSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>;