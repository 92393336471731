import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Choice } from "pages/EditGiftChoice/types";
import { ChoiceSetInput } from "types/graphql";

export type EditGiftChoiceFormValues = Pick<Choice, "price">;

const getInitialValues = (choice: Choice) => {
  const { price } = choice;

  return {
    price,
  };
};

export const EditGiftChoiceFormItem = createFormItem<EditGiftChoiceFormValues>();

export const useEditGiftChoiceForm = (
  choice: Choice,
  onSubmit: (choice: ChoiceSetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(choice);

  const submit = useCallback(() => {
    const { price } = form.getFieldsValue() as EditGiftChoiceFormValues;
    onSubmit({
      name: `${price} 円`,
      receiptDisplayName: `${price} 円`,
      price,
      priority: price,
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
