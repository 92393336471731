import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuRecommendationHeader } from "components/PageHeader/MenuRecommendationHeader";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

import { EditMenuRecommendationTranslationsForm } from "./EditMenuRecommendationTranslationsForm";
import {
  useEditMenuRecommendationTranslationsGetMenuRecommendationQuery,
  useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation,
} from "./queries";

export const EditMenuRecommendationTranslations = () => {
  const { menuRecommendationMetaId } = useParams<{ menuRecommendationMetaId: string }>();
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getMenuRecommendationData,
    loading: loadingMenuRecommendation,
    error: errorGetMenuRecommendationData,
    refetch: refetchGetMenuRecommendationData,
  } = useEditMenuRecommendationTranslationsGetMenuRecommendationQuery(
    menuRecommendationMetaId ? { variables: { menuRecommendationMetaId } } : { skip: true },
  );
  const menuRecommendation = useMemo(
    () => getMenuRecommendationData?.menuRecommendationMeta?.[0],
    [getMenuRecommendationData?.menuRecommendationMeta],
  );
  const translations = useMemo(
    () => menuRecommendation?.translations ?? [],
    [menuRecommendation?.translations],
  );
  const companyId = useMemo(() => menuRecommendation?.company.id, [menuRecommendation?.company.id]);

  const [
    updateMenuRecommendationTranslationsMutation,
    {
      loading: loadingUpdateMenuRecommendationTranslationsMutation,
      error: errorUpdateMenuRecommendationTranslationsMutation,
    },
  ] = useEditMenuRecommendationTranslationsUpdateMenuRecommendationTranslationsMutation();

  const [
    {
      loading: loadingUpdateMenuRecommendationTranslations,
      error: errorUpdateMenuRecommendationTranslations,
    },
    updateMenuRecommendationTranslations,
  ] = useAsyncFn(
    async ({ menuRecommendationMetaId, companyId, nameSources }) => {
      await updateMenuRecommendationTranslationsMutation({
        variables: {
          input: {
            companyId,
            menuRecommendationMetaId,
            nameSources,
          },
        },
      });

      message.success("編集を保存しました");

      goBack();

      refetchGetMenuRecommendationData();
    },
    [updateMenuRecommendationTranslationsMutation, goBack, refetchGetMenuRecommendationData],
  );

  const showErrorMessage = useCallback(() => {
    message.error("編集に失敗しました");
  }, []);

  const loading =
    loadingMenuRecommendation ||
    loadingUpdateMenuRecommendationTranslations ||
    loadingUpdateMenuRecommendationTranslationsMutation;
  const error =
    errorGetMenuRecommendationData ||
    errorUpdateMenuRecommendationTranslations ||
    errorUpdateMenuRecommendationTranslationsMutation;

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title={menuRecommendation?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "おすすめ" }],
      }}
    >
      <MenuRecommendationHeader
        menuRecommendation={menuRecommendation ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menuRecommendation && (
        <EditMenuRecommendationTranslationsForm
          menuRecommendation={menuRecommendation}
          translations={translations}
          onSubmit={updateMenuRecommendationTranslations}
          onClose={goBack}
          onFormValidationError={showErrorMessage}
        />
      )}
    </DashboardLayout>
  );
};
