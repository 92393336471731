import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const ChoiceNameField = memo<Props>(({ ...props }) => (
  <FormItem label="選択肢名：原文" name="choiceName" {...props}>
    <Input disabled />
  </FormItem>
));
