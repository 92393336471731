import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useGmoOffPaymentFeatureFlag } from "hooks/useGmoOffPaymentFeatureFlag";
import { useSteraFeatureFlag } from "hooks/useSteraFeatureFlag";
import { DashboardFeatureEnum } from "types/graphql";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const PaymentTypesMenu = memo<Props>((props) => {
  const { isSteraFeatureAvailable } = useSteraFeatureFlag({ companyId: props.company?.id });
  const { canAccess } = useCanAccess();
  const canAccessGmoOffPaymentTypes = canAccess("gmoOffPaymentOnSitePaymentDetailTypes");
  const [company] = useCompany();
  const companyId = company?.id;
  const { isGmoOffPaymentFeatureAvailable } = useGmoOffPaymentFeatureFlag({
    companyId,
    skip: !canAccessGmoOffPaymentTypes,
  });

  return (
    <SubMenu
      key="paymentTypes"
      title="支払方法等設定"
      features={[DashboardFeatureEnum.PaymentMethodSettings]}
      {...props}
    >
      <MenuItem route="onSitePaymentDetailTypes" to="/onSitePaymentDetailType" text="支払方法" />
      <MenuItem
        route="onSitePaymentDiscountTypes"
        to="/onSitePaymentDiscountType"
        text="割引方法"
      />
      <MenuItem route="inflowSourceTags" to="/inflowSourceTag" text="媒体" />
      <MenuItem
        route="steraOnSitePaymentDetailTypes"
        to="/steraOnSitePaymentDetailType"
        text="stera支払方法"
        canShow={isSteraFeatureAvailable}
      />
      {canAccessGmoOffPaymentTypes && (
        <MenuItem
          route="gmoOffPaymentOnSitePaymentDetailTypes"
          to="/offlineTerminalOnSitePaymentDetailType"
          text="オフライン端末支払方法"
          canShow={isGmoOffPaymentFeatureAvailable}
        />
      )}
    </SubMenu>
  );
});
