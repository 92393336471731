import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";
import { isNotNull } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";

export type AddDishUpSlipGroupMenuFormValues = {
  categoriesAndMenus: string[];
};

const getInitialValues = () => ({});

export const AddDishUpSlipGroupMenuFormItem = createFormItem<AddDishUpSlipGroupMenuFormValues>();

export const useAddDishUpSlipGroupMenuForm = (
  onChange: ({ menuIds }: { menuIds: number[] }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as AddDishUpSlipGroupMenuFormValues;
      const menuIds = values.categoriesAndMenus
        .map((key) => key.match(/(?:menuId:)(?<id>\d+)/)?.groups?.id ?? null)
        .filter(isNotNull)
        .map((menuId) => Number(menuId));
      onChange({ menuIds });
    },
    [onChange],
  );

  return { form, initialValues, change };
};
