import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddCategoryFormItem } from "../useAddCategoryForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShopSideNameField = memo<Props>((props) => (
  <AddCategoryFormItem label="ハンディ表示名" name="shopSideName" {...props}>
    <Input />
  </AddCategoryFormItem>
));
