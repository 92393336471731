import React, { memo } from "react";
import styled from "styled-components";
import { Collapse, Typography } from "antd";
import { CollapseProps } from "antd/lib";

import { grey } from "constants/colors";

const Title = styled(Typography.Title)``;

const StyledCollapse = styled(Collapse)`
  background-color: ${grey[0]};
  border-radius: 8px;
`;

type Props = {
  title?: string;
  className?: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
} & CollapseProps;

export const CollapsableFormSection = memo<Props>(
  ({ title, className, children, defaultOpen, titleLevel, ...props }) => {
    const collapseItems = [
      {
        key: "1",
        label: <Title level={titleLevel ?? 4}>{title}</Title>,
        children,
      },
    ];

    return (
      <StyledCollapse
        className={className}
        bordered={false}
        defaultActiveKey={defaultOpen ? ["1"] : undefined}
        items={collapseItems}
        {...props}
      />
    );
  },
);
