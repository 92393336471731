import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { updatePriorities } from "util/priority";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { SortableTable } from "components/SortableTable";
import { TableHeaderHelp } from "components/TableHeaderHelp";
import { usePagination } from "hooks/usePagination";
import { PlanChoiceTable } from "pages/PlanOptions/PlanOptionTable/PlanChoiceTable";
import { PlanOption } from "pages/PlanOptions/types";
import { OptionInputTypeEnum, PlanChoiceInsertInput, PlanOptionInsertInput } from "types/graphql";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  planId: number;
  planOptions: PlanOption[];
  onPlanOptionsUpdate: (planOptions: PlanOptionInsertInput[]) => void;
  onPlanChoicesUpdate: (planChoices: PlanChoiceInsertInput[]) => void;
  onDeleteOption: ({ planOptionId }: { planOptionId: number }) => void;
  onDeleteChoice: ({ planChoiceId }: { planChoiceId: number }) => void;
  canEditPlan: boolean;
  canDeletePlanOption: boolean;
  canAddPlanChoice: boolean;
  canEditPlanChoice: boolean;
  canDeletePlanChoice: boolean;
};

export const PlanOptionTable = memo<Props>(
  ({
    loading,
    planId,
    planOptions,
    onPlanOptionsUpdate,
    onPlanChoicesUpdate,
    onDeleteOption,
    onDeleteChoice,
    canEditPlan,
    canDeletePlanOption,
    canAddPlanChoice,
    canEditPlanChoice,
    canDeletePlanChoice,
  }) => {
    const [pagination, setPagination] = usePagination();

    const handleMoveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        if (dragIndex !== hoverIndex) {
          const planOptionsSetInput = planOptions.map(({ planChoices: _, ...planOption }) => ({
            serial: planOption.planOptionId,
            planId: planOption.planId,
            _planId: planOption._planId,
            inputType: planOption.inputType,
            maxChoiceNum: planOption.maxChoiceNum,
            minChoiceNum: planOption.minChoiceNum,
            name: planOption.name,
            priority: planOption.priority,
            receiptDisplayName: planOption.receiptDisplayName,
          }));
          onPlanOptionsUpdate(updatePriorities(planOptionsSetInput, dragIndex, hoverIndex));
        }
      },
      [onPlanOptionsUpdate, planOptions],
    );

    const columns = [
      {
        title: "オプション名",
        render(_: unknown, { planOptionId, name }: PlanOption) {
          return (
            <Tooltip title="オプションを編集">
              <Link to={`/plan/${planId}/option/${planOptionId}/edit`} replace>
                {name}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        title: (
          <TableHeaderHelp
            title="入力方法"
            help={
              <>
                選択式の場合、お客様は選択肢を選択できます。
                <br />
                個数入力式の場合、お客様は選択肢に個数を入力できます。
              </>
            }
          />
        ),
        width: 120,
        align: "center" as const,
        fixed: "right" as const,
        render(_: string, { inputType }: PlanOption) {
          return inputType === OptionInputTypeEnum.Select ? <Tag>選択</Tag> : <Tag>個数入力</Tag>;
        },
      },
      {
        title: "最小選択種類数",
        width: 120,
        align: "center" as const,
        fixed: "right" as const,
        render(_: string, { minChoiceNum }: PlanOption) {
          return minChoiceNum;
        },
      },
      {
        title: "最大選択種類数",
        width: 120,
        align: "center" as const,
        fixed: "right" as const,
        render(_: string, { maxChoiceNum }: PlanOption) {
          return maxChoiceNum;
        },
      },
      canDeletePlanOption
        ? {
            title: "",
            width: 60,
            align: "center" as const,
            fixed: "right" as const,
            render(_: string, { planOptionId }: PlanOption) {
              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>オプションを削除しますか？</Paragraph>
                      <Paragraph>一度削除したオプションを元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDeleteOption({ planOptionId });
                  }}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          }
        : null,
      canAddPlanChoice
        ? {
            title: "",
            width: 60,
            align: "center" as const,
            fixed: "right" as const,
            render(_: string, { planOptionId }: PlanOption) {
              return (
                <Link to={`/plan/${planId}/option/${planOptionId}/choice/add`}>
                  <Button type="default" size="small">
                    選択肢を追加
                  </Button>
                </Link>
              );
            },
          }
        : null,
    ].filter(isNotNull);

    const expandedRowRender = useCallback(
      ({ planOptionId, inputType, planChoices }: PlanOption) => (
        <PlanChoiceTable
          planId={planId}
          planOptionId={planOptionId}
          inputType={inputType}
          planChoices={planChoices}
          onUpdate={onPlanChoicesUpdate}
          onDelete={onDeleteChoice}
          canEditPlanChoice={canEditPlanChoice}
          canDeletePlanChoice={canDeletePlanChoice}
        />
      ),
      [planId, onPlanChoicesUpdate, onDeleteChoice, canEditPlanChoice, canDeletePlanChoice],
    );

    return (
      <SortableTable<PlanOption>
        type="planOption"
        rowKey="planOptionId"
        columns={columns}
        dataSource={planOptions}
        loading={loading}
        bordered
        expandable={{ expandedRowRender, defaultExpandAllRows: true }}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        onMove={handleMoveItem}
      />
    );
  },
);
