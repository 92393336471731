import React, { memo, useState } from "react";
import styled from "styled-components";
import { Checkbox as AntdCheckbox } from "antd";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";

import { BusinessOperationHourTypeEnum } from "types/graphql";

import { EditMessageDeliveryFormItem } from "../useEditMessageDeliveryForm";

const CheckboxGroup = styled(AntdCheckbox.Group)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const Checkbox = styled(AntdCheckbox)`
  & {
    margin-left: 0;
  }
`;

type Props = {
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
};

export const VisitedBusinessOperationHoursFilter = memo<Props>(
  ({ shopBusinessOperationHourTypes }) => {
    const [isCheckedAll, setIsCheckedAll] = useState(true);

    return (
      <EditMessageDeliveryFormItem
        noStyle
        name="visitedBusinessOperationHoursFilterBusinessOperationHourTypes"
        rules={[
          ({ setFieldsValue }) => ({
            transform(values: (BusinessOperationHourTypeEnum | "All")[]) {
              const isAll = values.includes("All");
              const filteredValues = values.filter((value) => value !== "All");

              if ((!isCheckedAll && isAll) || filteredValues.length === 0) {
                setIsCheckedAll(true);
                setFieldsValue({
                  visitedBusinessOperationHoursFilterBusinessOperationHourTypes: ["All"],
                });
                return;
              }

              if (isCheckedAll && isAll && filteredValues.length > 0) {
                setIsCheckedAll(false);
                setFieldsValue({
                  visitedBusinessOperationHoursFilterBusinessOperationHourTypes: filteredValues,
                });
                return;
              }

              if (filteredValues.length === shopBusinessOperationHourTypes.length) {
                setIsCheckedAll(true);
                setFieldsValue({
                  visitedBusinessOperationHoursFilterBusinessOperationHourTypes: ["All"],
                });
              }
            },
          }),
        ]}
      >
        <CheckboxGroup>
          <Checkbox key="All" value="All">
            すべて
          </Checkbox>
          {shopBusinessOperationHourTypes.map((value) => (
            <Checkbox key={value} value={value}>
              {businessOperationHourTypeToWord[value]}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </EditMessageDeliveryFormItem>
    );
  },
);
