import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditWinboardInflowSourceTagGetInflowSourceTag = gql`
    query EditWinboardInflowSourceTagGetInflowSourceTag($inflowSourceTagId: uuid!) {
  inflowSourceTag_by_pk(id: $inflowSourceTagId) {
    id
    displayName
    winboardInflowSourceTag {
      id
      code
    }
  }
}
    `;
export const EditWinboardInflowSourceTagUpdateWinboardInflowSourceTag = gql`
    mutation EditWinboardInflowSourceTagUpdateWinboardInflowSourceTag($winboardInflowSourceTag: winboardInflowSourceTag_insert_input!) {
  insert_winboardInflowSourceTag_one(
    object: $winboardInflowSourceTag
    on_conflict: {constraint: winboardInflowSourceTag_pkey, update_columns: [code]}
  ) {
    id
  }
}
    `;
export type EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables = Types.Exact<{
  inflowSourceTagId: Types.Scalars['uuid'];
}>;


export type EditWinboardInflowSourceTagGetInflowSourceTagQuery = (
  { __typename?: 'query_root' }
  & { inflowSourceTag_by_pk?: Types.Maybe<(
    { __typename?: 'inflowSourceTag' }
    & Pick<Types.InflowSourceTag, 'id' | 'displayName'>
    & { winboardInflowSourceTag?: Types.Maybe<(
      { __typename?: 'winboardInflowSourceTag' }
      & Pick<Types.WinboardInflowSourceTag, 'id' | 'code'>
    )> }
  )> }
);

export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationVariables = Types.Exact<{
  winboardInflowSourceTag: Types.WinboardInflowSourceTagInsertInput;
}>;


export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardInflowSourceTag_one?: Types.Maybe<(
    { __typename?: 'winboardInflowSourceTag' }
    & Pick<Types.WinboardInflowSourceTag, 'id'>
  )> }
);


export const EditWinboardInflowSourceTagGetInflowSourceTagDocument = gql`
    query EditWinboardInflowSourceTagGetInflowSourceTag($inflowSourceTagId: uuid!) {
  inflowSourceTag_by_pk(id: $inflowSourceTagId) {
    id
    displayName
    winboardInflowSourceTag {
      id
      code
    }
  }
}
    `;

/**
 * __useEditWinboardInflowSourceTagGetInflowSourceTagQuery__
 *
 * To run a query within a React component, call `useEditWinboardInflowSourceTagGetInflowSourceTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardInflowSourceTagGetInflowSourceTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWinboardInflowSourceTagGetInflowSourceTagQuery({
 *   variables: {
 *      inflowSourceTagId: // value for 'inflowSourceTagId'
 *   },
 * });
 */
export function useEditWinboardInflowSourceTagGetInflowSourceTagQuery(baseOptions: Apollo.QueryHookOptions<EditWinboardInflowSourceTagGetInflowSourceTagQuery, EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditWinboardInflowSourceTagGetInflowSourceTagQuery, EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables>(EditWinboardInflowSourceTagGetInflowSourceTagDocument, options);
      }
export function useEditWinboardInflowSourceTagGetInflowSourceTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditWinboardInflowSourceTagGetInflowSourceTagQuery, EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditWinboardInflowSourceTagGetInflowSourceTagQuery, EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables>(EditWinboardInflowSourceTagGetInflowSourceTagDocument, options);
        }
export type EditWinboardInflowSourceTagGetInflowSourceTagQueryHookResult = ReturnType<typeof useEditWinboardInflowSourceTagGetInflowSourceTagQuery>;
export type EditWinboardInflowSourceTagGetInflowSourceTagLazyQueryHookResult = ReturnType<typeof useEditWinboardInflowSourceTagGetInflowSourceTagLazyQuery>;
export type EditWinboardInflowSourceTagGetInflowSourceTagQueryResult = Apollo.QueryResult<EditWinboardInflowSourceTagGetInflowSourceTagQuery, EditWinboardInflowSourceTagGetInflowSourceTagQueryVariables>;
export const EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagDocument = gql`
    mutation EditWinboardInflowSourceTagUpdateWinboardInflowSourceTag($winboardInflowSourceTag: winboardInflowSourceTag_insert_input!) {
  insert_winboardInflowSourceTag_one(
    object: $winboardInflowSourceTag
    on_conflict: {constraint: winboardInflowSourceTag_pkey, update_columns: [code]}
  ) {
    id
  }
}
    `;
export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationFn = Apollo.MutationFunction<EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation, EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationVariables>;

/**
 * __useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation__
 *
 * To run a mutation, you first call `useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation, { data, loading, error }] = useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation({
 *   variables: {
 *      winboardInflowSourceTag: // value for 'winboardInflowSourceTag'
 *   },
 * });
 */
export function useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation(baseOptions?: Apollo.MutationHookOptions<EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation, EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation, EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationVariables>(EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagDocument, options);
      }
export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationHookResult = ReturnType<typeof useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation>;
export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationResult = Apollo.MutationResult<EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation>;
export type EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationOptions = Apollo.BaseMutationOptions<EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation, EditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutationVariables>;