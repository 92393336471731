import React, { memo, useCallback } from "react";
import { FormInstance, NamePath } from "rc-field-form/lib/interface";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

const COST_PRICE_FIELD = "costPrice";
const COST_PRICE_FIELD_LABEL = "原価";

const COST_TAX_RATE_FIELD = "costTaxRate";

type Props = FormItemProps & {
  costTaxRateFieldName?: NamePath;
  getCostTaxRateFieldValue?: (form: FormInstance) => any;
  disabled?: boolean;
};

export const CostPriceWithCostTaxRateField = memo<Props>(
  ({
    children,
    costTaxRateFieldName = COST_TAX_RATE_FIELD,
    getCostTaxRateFieldValue,
    disabled = false,
    rules = [],
    ...props
  }) => {
    const costPriceWithCostTaxRate = useCallback(
      (form: FormInstance) => {
        // NOTE: 原価税率が入力されていたら必須に
        const costTaxRate: string | undefined =
          getCostTaxRateFieldValue?.(form) ?? form.getFieldValue(costTaxRateFieldName);
        return { required: typeof costTaxRate === "number", message: "原価を入力してください" };
      },
      [costTaxRateFieldName, getCostTaxRateFieldValue],
    );

    return (
      <FormItem
        label={props.label ?? COST_PRICE_FIELD_LABEL}
        name={props.name ?? COST_PRICE_FIELD}
        rules={disabled ? [] : [...rules, costPriceWithCostTaxRate]}
        {...props}
      >
        {children}
      </FormItem>
    );
  },
);
