import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { FoodingJournalConfigFormItem } from "pages/EditFoodingJournalConfig/useFoodingJournalConfigForm";

type Props = Omit<FormItemProps, "children">;

export const ShopCodeField = memo<Props>((props) => (
  <FoodingJournalConfigFormItem
    label="店舗コード"
    name="shopCode"
    rules={[{ required: true, len: 7 }]}
    required
    {...props}
  >
    <InputCode />
  </FoodingJournalConfigFormItem>
));
