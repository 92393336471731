import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveriesGetMessageDeliveries = gql`
    query MessageDeliveriesGetMessageDeliveries($companyId: uuid!) {
  messageDelivery(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
  ) {
    id
    name
    isSuspended
    scope
    isDraft
    deliverAt
    repeatDeliveryTime
    targetCount
    targetCountUpdatedAt
    customerList {
      id
    }
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
  }
}
    `;
export const MessageDeliveriesArchiveMessageDelivery = gql`
    mutation MessageDeliveriesArchiveMessageDelivery($id: uuid!, $archivedAt: timestamptz!) {
  update_messageDelivery(where: {id: {_eq: $id}}, _set: {archivedAt: $archivedAt}) {
    affected_rows
  }
}
    `;
export const MessageDeliveriesRefetchTargetCount = gql`
    mutation MessageDeliveriesRefetchTargetCount($id: uuid!) {
  update_messageDelivery(
    where: {id: {_eq: $id}}
    _set: {targetCount: null, targetCountUpdatedAt: null}
  ) {
    affected_rows
  }
}
    `;
export type MessageDeliveriesGetMessageDeliveriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveriesGetMessageDeliveriesQuery = (
  { __typename?: 'query_root' }
  & { messageDelivery: Array<(
    { __typename?: 'messageDelivery' }
    & Pick<Types.MessageDelivery, 'id' | 'name' | 'isSuspended' | 'scope' | 'isDraft' | 'deliverAt' | 'repeatDeliveryTime' | 'targetCount' | 'targetCountUpdatedAt'>
    & { customerList?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerList' }
      & Pick<Types.MessageDeliveryCustomerList, 'id'>
    )>, customerSegment?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerSegment' }
      & { messageDeliveryCustomerSegmentVisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentVisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentUnvisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentBusinessOperationHoursCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentBusinessOperationHoursCondition, 'businessOperationHourType'>
      )>, messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition, 'messageDeliveryQuestionnaireAnswerStatus'>
      )>, messageDeliveryCustomerSegmentDayOfWeekConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentDayOfWeekCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentDayOfWeekCondition, 'messageDeliveryDayOfWeek'>
      )>, messageDeliveryCustomerSegmentMenuConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentMenuCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentMenuCondition, 'menuId' | 'moreThanOrEqualQuantity'>
      )>, messageDeliveryCustomerSegmentShopConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentShopCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentShopCondition, 'shopId'>
      )>, messageDeliveryCustomerSegmentGenderCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentGenderCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentGenderCondition, 'messageDeliveryGender'>
      )>, messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )> }
    )> }
  )> }
);

export type MessageDeliveriesArchiveMessageDeliveryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type MessageDeliveriesArchiveMessageDeliveryMutation = (
  { __typename?: 'mutation_root' }
  & { update_messageDelivery?: Types.Maybe<(
    { __typename?: 'messageDelivery_mutation_response' }
    & Pick<Types.MessageDeliveryMutationResponse, 'affected_rows'>
  )> }
);

export type MessageDeliveriesRefetchTargetCountMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type MessageDeliveriesRefetchTargetCountMutation = (
  { __typename?: 'mutation_root' }
  & { update_messageDelivery?: Types.Maybe<(
    { __typename?: 'messageDelivery_mutation_response' }
    & Pick<Types.MessageDeliveryMutationResponse, 'affected_rows'>
  )> }
);


export const MessageDeliveriesGetMessageDeliveriesDocument = gql`
    query MessageDeliveriesGetMessageDeliveries($companyId: uuid!) {
  messageDelivery(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
  ) {
    id
    name
    isSuspended
    scope
    isDraft
    deliverAt
    repeatDeliveryTime
    targetCount
    targetCountUpdatedAt
    customerList {
      id
    }
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
  }
}
    `;

/**
 * __useMessageDeliveriesGetMessageDeliveriesQuery__
 *
 * To run a query within a React component, call `useMessageDeliveriesGetMessageDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveriesGetMessageDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveriesGetMessageDeliveriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveriesGetMessageDeliveriesQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveriesGetMessageDeliveriesQuery, MessageDeliveriesGetMessageDeliveriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveriesGetMessageDeliveriesQuery, MessageDeliveriesGetMessageDeliveriesQueryVariables>(MessageDeliveriesGetMessageDeliveriesDocument, options);
      }
export function useMessageDeliveriesGetMessageDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveriesGetMessageDeliveriesQuery, MessageDeliveriesGetMessageDeliveriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveriesGetMessageDeliveriesQuery, MessageDeliveriesGetMessageDeliveriesQueryVariables>(MessageDeliveriesGetMessageDeliveriesDocument, options);
        }
export type MessageDeliveriesGetMessageDeliveriesQueryHookResult = ReturnType<typeof useMessageDeliveriesGetMessageDeliveriesQuery>;
export type MessageDeliveriesGetMessageDeliveriesLazyQueryHookResult = ReturnType<typeof useMessageDeliveriesGetMessageDeliveriesLazyQuery>;
export type MessageDeliveriesGetMessageDeliveriesQueryResult = Apollo.QueryResult<MessageDeliveriesGetMessageDeliveriesQuery, MessageDeliveriesGetMessageDeliveriesQueryVariables>;
export const MessageDeliveriesArchiveMessageDeliveryDocument = gql`
    mutation MessageDeliveriesArchiveMessageDelivery($id: uuid!, $archivedAt: timestamptz!) {
  update_messageDelivery(where: {id: {_eq: $id}}, _set: {archivedAt: $archivedAt}) {
    affected_rows
  }
}
    `;
export type MessageDeliveriesArchiveMessageDeliveryMutationFn = Apollo.MutationFunction<MessageDeliveriesArchiveMessageDeliveryMutation, MessageDeliveriesArchiveMessageDeliveryMutationVariables>;

/**
 * __useMessageDeliveriesArchiveMessageDeliveryMutation__
 *
 * To run a mutation, you first call `useMessageDeliveriesArchiveMessageDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveriesArchiveMessageDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageDeliveriesArchiveMessageDeliveryMutation, { data, loading, error }] = useMessageDeliveriesArchiveMessageDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useMessageDeliveriesArchiveMessageDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<MessageDeliveriesArchiveMessageDeliveryMutation, MessageDeliveriesArchiveMessageDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageDeliveriesArchiveMessageDeliveryMutation, MessageDeliveriesArchiveMessageDeliveryMutationVariables>(MessageDeliveriesArchiveMessageDeliveryDocument, options);
      }
export type MessageDeliveriesArchiveMessageDeliveryMutationHookResult = ReturnType<typeof useMessageDeliveriesArchiveMessageDeliveryMutation>;
export type MessageDeliveriesArchiveMessageDeliveryMutationResult = Apollo.MutationResult<MessageDeliveriesArchiveMessageDeliveryMutation>;
export type MessageDeliveriesArchiveMessageDeliveryMutationOptions = Apollo.BaseMutationOptions<MessageDeliveriesArchiveMessageDeliveryMutation, MessageDeliveriesArchiveMessageDeliveryMutationVariables>;
export const MessageDeliveriesRefetchTargetCountDocument = gql`
    mutation MessageDeliveriesRefetchTargetCount($id: uuid!) {
  update_messageDelivery(
    where: {id: {_eq: $id}}
    _set: {targetCount: null, targetCountUpdatedAt: null}
  ) {
    affected_rows
  }
}
    `;
export type MessageDeliveriesRefetchTargetCountMutationFn = Apollo.MutationFunction<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>;

/**
 * __useMessageDeliveriesRefetchTargetCountMutation__
 *
 * To run a mutation, you first call `useMessageDeliveriesRefetchTargetCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveriesRefetchTargetCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageDeliveriesRefetchTargetCountMutation, { data, loading, error }] = useMessageDeliveriesRefetchTargetCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageDeliveriesRefetchTargetCountMutation(baseOptions?: Apollo.MutationHookOptions<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>(MessageDeliveriesRefetchTargetCountDocument, options);
      }
export type MessageDeliveriesRefetchTargetCountMutationHookResult = ReturnType<typeof useMessageDeliveriesRefetchTargetCountMutation>;
export type MessageDeliveriesRefetchTargetCountMutationResult = Apollo.MutationResult<MessageDeliveriesRefetchTargetCountMutation>;
export type MessageDeliveriesRefetchTargetCountMutationOptions = Apollo.BaseMutationOptions<MessageDeliveriesRefetchTargetCountMutation, MessageDeliveriesRefetchTargetCountMutationVariables>;