import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DishUpSlipGroup } from "types/graphql";

type Props = {
  dishUpSlipGroup: {
    id: DishUpSlipGroup["serial"];
    name: DishUpSlipGroup["name"];
  } | null;
  onBack: () => void;
};

export const DishUpSlipGroupHeader = memo<Props>(({ dishUpSlipGroup, onBack }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={onBack} title={dishUpSlipGroup?.name ?? ""} />
      {dishUpSlipGroup && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/dishUpSlipGroup/${dishUpSlipGroup.id}/edit`} replace>
              デシャップグループ
            </Link>
          </Menu.Item>
          <Menu.Item key="menu">
            <Link to={`/dishUpSlipGroup/${dishUpSlipGroup.id}/menu`} replace>
              デシャップグループ内メニュー
            </Link>
          </Menu.Item>
          <Menu.Item key="plan">
            <Link to={`/dishUpSlipGroup/${dishUpSlipGroup.id}/plan`} replace>
              デシャップグループ内プラン
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
