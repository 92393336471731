import React, { useMemo } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import {
  useMessageDeliveryAnalyticsGetCompaniesQuery,
  useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery,
  useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery,
  useMessageDeliveryAnalyticsGetSummaryPerShopQuery,
  useMessageDeliveryAnalyticsGetSummaryQuery,
} from "./queries";
import { RecentVisitedTableUsersReportPanel } from "./RecentVisitedTableUsersReportPanel";
import { SummaryPanel } from "./SummaryPanel";
import { SummaryPerMessageDeliveryTable } from "./SummaryPerMessageDeliveryTable";
import { SummaryPerShopTable } from "./SummaryPerShopTable";

const now = dayjs();

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
`;

export const MessageDeliveryAnalytics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const [company] = useCompany();
  const companyIdDefaultValue = company?.id;

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >({ companyId: companyIdDefaultValue, targetDate: now }, undefined, {
    serialize: ({ targetDate }) => ({
      targetDate: targetDate ? targetDate.valueOf() : now.valueOf(),
    }),
    deserialize: ({ targetDate }) => ({
      targetDate: dayjs(targetDate),
    }),
  });

  const companyId = filterConditions.companyId ?? companyIdDefaultValue;
  const targetDate = filterConditions.targetDate ?? now;

  const {
    data: getCompaniesData,
    error: getCompaniesError,
    loading: loadingCompaniesData,
  } = useMessageDeliveryAnalyticsGetCompaniesQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const companies = getCompaniesData?.corporation[0]?.companies ?? [];

  const range = useMemo(() => {
    const startAt = targetDate.startOf("month");
    const endAt = targetDate.endOf("month");

    return { startAt, endAt };
  }, [targetDate]);

  const input = useMemo(
    () =>
      !companyId
        ? null
        : {
            companyId,
            startAt: range.startAt.toISOString(),
            endAt: range.endAt.toISOString(),
          },
    [companyId, range.endAt, range.startAt],
  );

  const { data: getSummary, error: getSummaryError } = useMessageDeliveryAnalyticsGetSummaryQuery(
    input ? { variables: { input } } : { skip: true },
  );
  const messageDeliveryAnalyticsSummary = getSummary?.messageDeliveryAnalyticsSummary;

  const { data: getRecentVisitedTableUserReport, error: getRecentVisitedTableUserReportError } =
    useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery(
      // NOTE: サマリーと同時に取得すると重くなるため、遅延取得する
      input && messageDeliveryAnalyticsSummary ? { variables: { input } } : { skip: true },
    );
  const recentVisitedTableUserReport =
    getRecentVisitedTableUserReport?.messageDeliveryAnalyticsRecentVisitedTableUserReport;

  const { data: getSummaryPerShop, error: getSummaryPerShopError } =
    useMessageDeliveryAnalyticsGetSummaryPerShopQuery(
      // NOTE: サマリーと同時に取得すると重くなるため、遅延取得する
      input && messageDeliveryAnalyticsSummary ? { variables: { input } } : { skip: true },
    );

  const messageDeliveryAnalyticsSummaryPerShop =
    getSummaryPerShop?.messageDeliveryAnalyticsSummaryPerShop;

  const { data: getSummaryPerMessageDelivery, error: getSummaryPerMessageDeliveryError } =
    useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery(
      input &&
        // NOTE: サマリーや店舗別集計と同時に取得すると重くなるため、遅延取得する
        messageDeliveryAnalyticsSummaryPerShop
        ? { variables: { input } }
        : { skip: true },
    );
  const messageDeliveryAnalyticsSummaryPerMessageDelivery =
    getSummaryPerMessageDelivery?.messageDeliveryAnalyticsSummaryPerMessageDelivery;

  // NOTE: 開封数は 2023/3 から収集を始めたため、それ以前の期間が選択された場合エラーを表示する
  const isNotMessageOpenCountCollected =
    range.startAt.year() <= 2023 && range.startAt.month() + 1 <= 2;

  const isError =
    getSummaryError ||
    getSummaryPerShopError ||
    getSummaryPerMessageDeliveryError ||
    getRecentVisitedTableUserReportError ||
    getCompaniesError;

  return (
    <DashboardLayout title="メッセージ配信分析">
      <PageHeader
        title="メッセージ配信分析"
        footer={
          <Filters
            companies={companies}
            companyIdDefaultValue={companyIdDefaultValue}
            filterConditions={filterConditions}
            onChangeFilterCondition={updateFilterCondition}
            loading={loadingCompaniesData}
          />
        }
      />

      {isError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <SummaryPanel summary={messageDeliveryAnalyticsSummary} />

      <Spacer size={32} />

      <Title>最近の来店者組売上</Title>
      <RecentVisitedTableUsersReportPanel
        recentVisitedTableUserReport={recentVisitedTableUserReport}
      />

      <Spacer size={32} />

      <Title>店舗別配信結果</Title>
      <SummaryPerShopTable
        summaryPerShop={messageDeliveryAnalyticsSummaryPerShop}
        isNotMessageOpenCountCollected={isNotMessageOpenCountCollected}
      />

      <Spacer size={32} />

      <Title>メッセージ別結果</Title>
      <SummaryPerMessageDeliveryTable
        summaryPerMessageDelivery={messageDeliveryAnalyticsSummaryPerMessageDelivery}
        isNotMessageOpenCountCollected={isNotMessageOpenCountCollected}
      />
    </DashboardLayout>
  );
};
