import React, { memo } from "react";
import { Tag } from "antd";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { CookingItem } from "pages/EditShopMenuOutput/types";

type Props = {
  loading?: boolean;
  menuCookingItems: CookingItem[];
  onRemove: (cookingItemId: string) => void;
};

export const MenuCookingItemTable = memo<Props>(({ loading, menuCookingItems, onRemove }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "調理アイテム名",
      render(_: unknown, { name }: CookingItem) {
        return name;
      },
    },
    {
      title: "キッチンプリンター",
      width: 160,
      render(_: unknown, { shopCookingItemRole }: CookingItem) {
        return <Tag>{shopCookingItemRole?.role?.name}</Tag>;
      },
    },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { cookingItemId }: CookingItem) {
        return <DeleteIcon onClick={() => onRemove(cookingItemId)} />;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={menuCookingItems}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
