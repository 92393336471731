import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { PlansField } from "pages/PlanGroupPlans/AddPlanGroupModal/AddPlanForm/PlansField";
import {
  AddPlanFormValues,
  useAddPlanForm,
} from "pages/PlanGroupPlans/AddPlanGroupModal/AddPlanForm/useAddPlanForm";
import { Plan, PlanGroup } from "pages/PlanGroupPlans/types";

type Props = {
  planGroup: PlanGroup;
  plans: Plan[];
  onChange: (planGroup: AddPlanFormValues) => void;
};

export const AddPlanForm = memo<Props>(({ planGroup, plans, onChange }) => {
  const { form, change: onValuesChange } = useAddPlanForm(onChange);

  const plansIds = planGroup.plans.map(({ planId }) => planId);
  const filteredPlans = plans.filter(({ planId }) => !plansIds.includes(planId));

  return (
    <Form name="plan" form={form} onValuesChange={onValuesChange} layout="vertical">
      <PlansField plans={filteredPlans} />
    </Form>
  );
});
