import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Avatar, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { QuestionCircleOutlined } from "@ant-design/icons";

import AccountNoImageIcon from "assets/account_no_image.svg";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { grey } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";
import { QuotaLimitTag } from "pages/LineOfficialAccounts/LineOfficialAccountSummaryTable/QuotaLimitTag";

const { Link } = Typography;

type lineOfficialAccountSummary = {
  lineOfficialAccountId: number;
  lineId: string;
  channelName: string;
  pictureUrl?: string;
  quotaLimit?: number;
  followers?: number;
  targetedReaches?: number;
  blocks?: number;
  isInvalidAccount: boolean;
};

const BoldText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${grey[10]};
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  && {
    color: ${grey[10]};
  }
`;

const ChannelName = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InvalidChannelName = styled(ChannelName)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const ColumnTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

  display: flex;
  align-items: center;
  gap: 8px;
`;

type Props = {
  loading?: boolean;
  lineOfficialAccountSummaries: lineOfficialAccountSummary[];
};

export const LineOfficialAccountSummaryTable = memo<Props>(
  ({ loading, lineOfficialAccountSummaries }) => {
    const [pagination, setPagination] = usePagination();
    const { canAccess } = useCanAccess();

    const columns: ColumnsType<lineOfficialAccountSummary> = useMemo(
      () => [
        {
          title: "",
          align: "center",
          width: 42,
          render(_: unknown, _record: unknown, index: number) {
            return <BoldText>{(index + 1).toLocaleString()}</BoldText>;
          },
        },
        {
          title: <ColumnTitle>アカウント名</ColumnTitle>,
          dataIndex: "channelName",
          render(
            _: unknown,
            { lineId, channelName, pictureUrl, isInvalidAccount }: lineOfficialAccountSummary,
          ) {
            if (isInvalidAccount) {
              return (
                <InvalidChannelName>
                  <Avatar size={32} src={pictureUrl ?? <AccountNoImageIcon />} />
                  {`${isInvalidAccount ? "[無効]" : ""}${channelName}`}
                </InvalidChannelName>
              );
            }

            return (
              <StyledLink href={`https://manager.line.biz/account/${lineId}`} underline>
                <ChannelName>
                  <Avatar size={32} src={pictureUrl ?? <AccountNoImageIcon />} />
                  {channelName}
                </ChannelName>
              </StyledLink>
            );
          },
        },
        {
          title: (
            <ColumnTitle>
              今月の送信可能数
              <Tooltip title="当月、月初時点での集計">
                <QuestionCircleOutlined />
              </Tooltip>
            </ColumnTitle>
          ),
          dataIndex: "quotaLimit",
          render(_: unknown, { quotaLimit, isInvalidAccount }: lineOfficialAccountSummary) {
            return <QuotaLimitTag quotaLimit={quotaLimit} isInvalidAccount={isInvalidAccount} />;
          },
        },
        {
          title: <ColumnTitle>累計友達数</ColumnTitle>,
          dataIndex: "followers",
          render(_: unknown, { followers, isInvalidAccount }: lineOfficialAccountSummary) {
            if (isInvalidAccount) return <BoldText>無効</BoldText>;
            return <BoldText>{followers ? followers.toLocaleString() : "集計中"}</BoldText>;
          },
        },
        {
          title: <ColumnTitle>有効友達数</ColumnTitle>,
          dataIndex: "targetedReaches",
          render(_: unknown, { targetedReaches, isInvalidAccount }: lineOfficialAccountSummary) {
            if (isInvalidAccount) return <BoldText>無効</BoldText>;
            return (
              <BoldText>{targetedReaches ? targetedReaches.toLocaleString() : "集計中"}</BoldText>
            );
          },
        },
        {
          title: <ColumnTitle>ブロック数</ColumnTitle>,
          dataIndex: "blocks",
          render(_: unknown, { blocks, isInvalidAccount }: lineOfficialAccountSummary) {
            if (isInvalidAccount) return <BoldText>無効</BoldText>;
            return <BoldText>{blocks ? blocks.toLocaleString() : "集計中"}</BoldText>;
          },
        },
        ...(canAccess("editLineOfficialAccount")
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                fixed: "right",
                render(_: string, { lineOfficialAccountId }: lineOfficialAccountSummary) {
                  return (
                    canAccess("editLineOfficialAccount") && (
                      <IconLink to={`/lineOfficialAccounts/${lineOfficialAccountId}/edit`}>
                        <EditIcon />
                      </IconLink>
                    )
                  );
                },
              } as const,
            ]
          : []),
      ],
      [canAccess],
    );

    return (
      <Table
        rowKey="lineOfficialAccountId"
        columns={columns}
        dataSource={lineOfficialAccountSummaries}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
