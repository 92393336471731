import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { DayOfWeekCheckboxOptions } from "models/dayOfWeek";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

const AllChecker = styled.div`
  width: 16%;
`;

export const VisitedDayOfWeekFilter = memo(() => (
  <>
    <AllChecker>
      <MessageDeliveryJobFormItem noStyle name="visitedDayOfWeekAllChecker" valuePropName="checked">
        <Checkbox>すべて</Checkbox>
      </MessageDeliveryJobFormItem>
    </AllChecker>
    <MessageDeliveryJobFormItem noStyle name="visitedDayOfWeekFilter">
      <Checkbox.Group options={DayOfWeekCheckboxOptions} />
    </MessageDeliveryJobFormItem>
  </>
));
