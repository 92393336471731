import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditPlanTranslationsFormItem } from "./useEditPlanTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeatureLabelEnField = memo<Props>(({ ...props }) => (
  <EditPlanTranslationsFormItem label="英語" name="featureLabelEn" {...props}>
    <Input />
  </EditPlanTranslationsFormItem>
));
