import React, { useMemo } from "react";
import { Button, Select, Switch, Typography } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Shop } from "../types";

import {
  CopyDealingDataFormItem,
  CopyDealingDataFormValues,
  useCopyDealingDataForm,
} from "./useCopyDealingDataForm";

type Props = {
  selectedCompany: string | undefined;
  shops: Shop[];
  canSubmit: boolean;
  onChange: (formValues: CopyDealingDataFormValues) => void;
  onSubmit: (formValues: CopyDealingDataFormValues) => void;
};

export const CopyDealingDataForm = ({
  selectedCompany,
  shops,
  canSubmit,
  onChange,
  onSubmit,
}: Props) => {
  const {
    form,
    initialValues,
    change: handleChange,
    submit: handleSubmit,
  } = useCopyDealingDataForm(onChange, onSubmit);

  const sourceShopId = form.getFieldValue("sourceShopId");

  const sourceShopOptions = useMemo(
    () => shops.map(({ name, shopId }) => ({ label: name, value: shopId })),
    [shops],
  );

  const targetShopOptions = useMemo(
    () =>
      shops
        .filter(({ shopId }) => shopId !== sourceShopId)
        .map(({ name, shopId }) => ({ label: name, value: shopId })),
    [shops, sourceShopId],
  );

  return (
    <>
      <FormContent>
        <Typography.Paragraph>
          対象法人: <strong>{selectedCompany}</strong>
        </Typography.Paragraph>
        <Form
          name="copyDealingData"
          form={form}
          initialValues={initialValues}
          layout="vertical"
          onValuesChange={handleChange}
        >
          <CopyDealingDataFormItem
            label="コピー元店舗"
            name="sourceShopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={sourceShopOptions.length === 0}
              showSearch
              options={sourceShopOptions}
              optionFilterProp="label"
            />
          </CopyDealingDataFormItem>
          <CopyDealingDataFormItem
            label="コピー先店舗"
            name="targetShopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={targetShopOptions.length === 0}
              showSearch
              options={targetShopOptions}
              optionFilterProp="label"
            />
          </CopyDealingDataFormItem>
          <CopyDealingDataFormItem
            label="テーブルコピー"
            name="enableCopyTable"
            required
            valuePropName="checked"
          >
            <Switch />
          </CopyDealingDataFormItem>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={handleSubmit} disabled={!canSubmit}>
          コピー
        </Button>
      </FormActions>
    </>
  );
};
