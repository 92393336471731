import React, { memo, useEffect } from "react";
import { FormInstance } from "antd";

import { FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { AddPlanFormItem } from "../useAddPlanForm";

type Props = {
  hasEndNotificationTime: boolean;
  form: FormInstance;
} & Omit<FormItemProps, "children" | "name">;

export const PlanEndNotificationThresholdField = memo<Props>(
  ({ form, hasEndNotificationTime, ...props }) => {
    useEffect(() => {
      if (!hasEndNotificationTime) {
        form.validateFields(["endNotificationThresholdMinutes"]);
      }
    }, [hasEndNotificationTime, form]);

    // NOTE: 縦を揃えるためラベルに半角スペースを挿入
    return (
      <AddPlanFormItem
        label="　"
        name="endNotificationThresholdMinutes"
        {...props}
        rules={[
          {
            validator: async (_, endNotificationThresholdMinutes: number) => {
              if (!hasEndNotificationTime) return;

              if (hasEndNotificationTime && typeof endNotificationThresholdMinutes !== "number") {
                throw new Error("プラン終了通知時間を入力してください");
              }

              const planTime: number = form.getFieldValue("planTime");

              if (hasEndNotificationTime && planTime <= endNotificationThresholdMinutes) {
                throw new Error("制限時間より短い時間を入力してください");
              }
            },
          },
        ]}
      >
        <InputNumber
          formatter={(value) => `${value}分前`}
          parser={(value) => (value ?? "").replace("分前", "")}
          min={0}
          disabled={!hasEndNotificationTime}
        />
      </AddPlanFormItem>
    );
  },
);
