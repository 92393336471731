import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuOptionsGetMenuOption = gql`
    query MenuOptionsGetMenuOption($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    companyId
    description
    featuredLabelText
    imageUrl
    menuId
    orderableTimeId
    name
    openPrice
    orderMaxNum
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    unitMaxOrderNumForNumPeople
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        id
        name
        optionId
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          imageUrl
          isDefaultSelection
          name
          price
        }
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryMenuId
      categoryId: _categoryId
      displayType
      menuId: _menuId
      priority
    }
  }
  menuOption(
    where: {_menuId: {_eq: $menuId}, option: {archivedAt: {_is_null: true}}}
    order_by: [{priority: asc}, {_optionId: asc}]
  ) {
    optionId: _optionId
    priority
    option {
      id
      inputType
      maxChoiceNum
      minChoiceNum
      name
      optionId
      receiptDisplayName
      choices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {choiceId: asc}]
      ) {
        choiceId
        imageUrl
        isDefaultSelection
        maxOrderableNum
        name
        optionId: _optionId
        price
        priority
        receiptDisplayName
      }
    }
  }
}
    `;
export const MenuOptionsGetOptions = gql`
    query MenuOptionsGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _or: [{_not: {menuOptions: {}}}, {menuOptions: {option: {_not: {giftOptionMeta: {}}}}}]}
    order_by: {optionId: asc}
  ) {
    id
    optionId
    name
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}, menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {_menuId: asc}
    ) {
      menuId: _menuId
      menu {
        name
      }
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
  }
}
    `;
export const MenuOptionsUpdateMenuOptions = gql`
    mutation MenuOptionsUpdateMenuOptions($menuId: Int!, $menuOptions: [menuOption_insert_input!]!) {
  delete_menuOption(where: {_menuId: {_eq: $menuId}}) {
    affected_rows
  }
  insert_menuOption(
    objects: $menuOptions
    on_conflict: {constraint: menuOption_menuId_optionId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      optionId: _optionId
    }
  }
}
    `;
export type MenuOptionsGetMenuOptionQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type MenuOptionsGetMenuOptionQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'featuredLabelText' | 'imageUrl' | 'menuId' | 'orderableTimeId' | 'name' | 'openPrice' | 'orderMaxNum' | 'receiptDisplayName' | 'shopSideName' | 'taxMethod' | 'taxRate' | 'unitMaxOrderNumForNumPeople' | 'shouldMergeSlipOptions' | 'costPrice' | 'costTaxRate' | 'menuType'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'id' | 'name' | 'optionId'>
        & { choices: Array<(
          { __typename?: 'choice' }
          & Pick<Types.Choice, 'choiceId' | 'imageUrl' | 'isDefaultSelection' | 'name' | 'price'>
        )> }
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId' | 'displayType' | 'priority'>
      & { categoryId: Types.CategoryMenu['_categoryId'], menuId: Types.CategoryMenu['_menuId'] }
    )> }
  )>, menuOption: Array<(
    { __typename?: 'menuOption' }
    & Pick<Types.MenuOption, 'priority'>
    & { optionId: Types.MenuOption['_optionId'] }
    & { option: (
      { __typename?: 'option' }
      & Pick<Types.Option, 'id' | 'inputType' | 'maxChoiceNum' | 'minChoiceNum' | 'name' | 'optionId' | 'receiptDisplayName'>
      & { choices: Array<(
        { __typename?: 'choice' }
        & Pick<Types.Choice, 'choiceId' | 'imageUrl' | 'isDefaultSelection' | 'maxOrderableNum' | 'name' | 'price' | 'priority' | 'receiptDisplayName'>
        & { optionId: Types.Choice['_optionId'] }
      )> }
    ) }
  )> }
);

export type MenuOptionsGetOptionsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenuOptionsGetOptionsQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'id' | 'optionId' | 'name'>
    & { menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { menuId: Types.MenuOption['_menuId'] }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'name'>
      ) }
    )>, choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'name'>
    )> }
  )> }
);

export type MenuOptionsUpdateMenuOptionsMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  menuOptions: Array<Types.MenuOptionInsertInput> | Types.MenuOptionInsertInput;
}>;


export type MenuOptionsUpdateMenuOptionsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_menuOption?: Types.Maybe<(
    { __typename?: 'menuOption_mutation_response' }
    & Pick<Types.MenuOptionMutationResponse, 'affected_rows'>
  )>, insert_menuOption?: Types.Maybe<(
    { __typename?: 'menuOption_mutation_response' }
    & Pick<Types.MenuOptionMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'menuOption' }
      & { optionId: Types.MenuOption['_optionId'] }
    )> }
  )> }
);


export const MenuOptionsGetMenuOptionDocument = gql`
    query MenuOptionsGetMenuOption($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    companyId
    description
    featuredLabelText
    imageUrl
    menuId
    orderableTimeId
    name
    openPrice
    orderMaxNum
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    unitMaxOrderNumForNumPeople
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        id
        name
        optionId
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          imageUrl
          isDefaultSelection
          name
          price
        }
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryMenuId
      categoryId: _categoryId
      displayType
      menuId: _menuId
      priority
    }
  }
  menuOption(
    where: {_menuId: {_eq: $menuId}, option: {archivedAt: {_is_null: true}}}
    order_by: [{priority: asc}, {_optionId: asc}]
  ) {
    optionId: _optionId
    priority
    option {
      id
      inputType
      maxChoiceNum
      minChoiceNum
      name
      optionId
      receiptDisplayName
      choices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {choiceId: asc}]
      ) {
        choiceId
        imageUrl
        isDefaultSelection
        maxOrderableNum
        name
        optionId: _optionId
        price
        priority
        receiptDisplayName
      }
    }
  }
}
    `;

/**
 * __useMenuOptionsGetMenuOptionQuery__
 *
 * To run a query within a React component, call `useMenuOptionsGetMenuOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOptionsGetMenuOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOptionsGetMenuOptionQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useMenuOptionsGetMenuOptionQuery(baseOptions: Apollo.QueryHookOptions<MenuOptionsGetMenuOptionQuery, MenuOptionsGetMenuOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuOptionsGetMenuOptionQuery, MenuOptionsGetMenuOptionQueryVariables>(MenuOptionsGetMenuOptionDocument, options);
      }
export function useMenuOptionsGetMenuOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuOptionsGetMenuOptionQuery, MenuOptionsGetMenuOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuOptionsGetMenuOptionQuery, MenuOptionsGetMenuOptionQueryVariables>(MenuOptionsGetMenuOptionDocument, options);
        }
export type MenuOptionsGetMenuOptionQueryHookResult = ReturnType<typeof useMenuOptionsGetMenuOptionQuery>;
export type MenuOptionsGetMenuOptionLazyQueryHookResult = ReturnType<typeof useMenuOptionsGetMenuOptionLazyQuery>;
export type MenuOptionsGetMenuOptionQueryResult = Apollo.QueryResult<MenuOptionsGetMenuOptionQuery, MenuOptionsGetMenuOptionQueryVariables>;
export const MenuOptionsGetOptionsDocument = gql`
    query MenuOptionsGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _or: [{_not: {menuOptions: {}}}, {menuOptions: {option: {_not: {giftOptionMeta: {}}}}}]}
    order_by: {optionId: asc}
  ) {
    id
    optionId
    name
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}, menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {_menuId: asc}
    ) {
      menuId: _menuId
      menu {
        name
      }
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
  }
}
    `;

/**
 * __useMenuOptionsGetOptionsQuery__
 *
 * To run a query within a React component, call `useMenuOptionsGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOptionsGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOptionsGetOptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenuOptionsGetOptionsQuery(baseOptions: Apollo.QueryHookOptions<MenuOptionsGetOptionsQuery, MenuOptionsGetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuOptionsGetOptionsQuery, MenuOptionsGetOptionsQueryVariables>(MenuOptionsGetOptionsDocument, options);
      }
export function useMenuOptionsGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuOptionsGetOptionsQuery, MenuOptionsGetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuOptionsGetOptionsQuery, MenuOptionsGetOptionsQueryVariables>(MenuOptionsGetOptionsDocument, options);
        }
export type MenuOptionsGetOptionsQueryHookResult = ReturnType<typeof useMenuOptionsGetOptionsQuery>;
export type MenuOptionsGetOptionsLazyQueryHookResult = ReturnType<typeof useMenuOptionsGetOptionsLazyQuery>;
export type MenuOptionsGetOptionsQueryResult = Apollo.QueryResult<MenuOptionsGetOptionsQuery, MenuOptionsGetOptionsQueryVariables>;
export const MenuOptionsUpdateMenuOptionsDocument = gql`
    mutation MenuOptionsUpdateMenuOptions($menuId: Int!, $menuOptions: [menuOption_insert_input!]!) {
  delete_menuOption(where: {_menuId: {_eq: $menuId}}) {
    affected_rows
  }
  insert_menuOption(
    objects: $menuOptions
    on_conflict: {constraint: menuOption_menuId_optionId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      optionId: _optionId
    }
  }
}
    `;
export type MenuOptionsUpdateMenuOptionsMutationFn = Apollo.MutationFunction<MenuOptionsUpdateMenuOptionsMutation, MenuOptionsUpdateMenuOptionsMutationVariables>;

/**
 * __useMenuOptionsUpdateMenuOptionsMutation__
 *
 * To run a mutation, you first call `useMenuOptionsUpdateMenuOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuOptionsUpdateMenuOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuOptionsUpdateMenuOptionsMutation, { data, loading, error }] = useMenuOptionsUpdateMenuOptionsMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      menuOptions: // value for 'menuOptions'
 *   },
 * });
 */
export function useMenuOptionsUpdateMenuOptionsMutation(baseOptions?: Apollo.MutationHookOptions<MenuOptionsUpdateMenuOptionsMutation, MenuOptionsUpdateMenuOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuOptionsUpdateMenuOptionsMutation, MenuOptionsUpdateMenuOptionsMutationVariables>(MenuOptionsUpdateMenuOptionsDocument, options);
      }
export type MenuOptionsUpdateMenuOptionsMutationHookResult = ReturnType<typeof useMenuOptionsUpdateMenuOptionsMutation>;
export type MenuOptionsUpdateMenuOptionsMutationResult = Apollo.MutationResult<MenuOptionsUpdateMenuOptionsMutation>;
export type MenuOptionsUpdateMenuOptionsMutationOptions = Apollo.BaseMutationOptions<MenuOptionsUpdateMenuOptionsMutation, MenuOptionsUpdateMenuOptionsMutationVariables>;