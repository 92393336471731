import React, { memo } from "react";
import { Radio } from "antd/lib";
import { displayTypes } from "models/displayType";

import { DisplayTypeEnum } from "types/graphql";

import { EditMenusBulkFormItem } from "../../useEditMenusBulkForm";

type Props = {
  initialValue: string;
  value: string | undefined;
  categoryMenuId: number;
  disabled: boolean;
};

export const DisplayTypeField = memo<Props>(({ initialValue, value, categoryMenuId, disabled }) => (
  <EditMenusBulkFormItem name={[categoryMenuId, "displayType"]} initialValue={initialValue} noStyle>
    <Radio.Group>
      {Object.values(DisplayTypeEnum).map((displayType) => (
        <Radio.Button value={displayType} key={displayType} disabled={disabled}>
          {displayTypes[displayType]}
        </Radio.Button>
      ))}
    </Radio.Group>
  </EditMenusBulkFormItem>
));
