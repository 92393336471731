import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { FoodingJournalConfigFormItem } from "pages/EditFoodingJournalConfig/useFoodingJournalConfigForm";

type Props = Omit<FormItemProps, "children">;

export const PosNumberField = memo<Props>((props) => (
  <FoodingJournalConfigFormItem
    label="POS番号"
    name="posNumber"
    rules={[{ required: true, max: 2 }]}
    required
    {...props}
  >
    <InputCode />
  </FoodingJournalConfigFormItem>
));
