import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalPlansGetPlans = gql`
    query FoodingJournalPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId: id
    name: planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId: id
      planChoices(where: {archivedAt: {_is_null: true}}) {
        planChoiceId: id
        name
        foodingJournalMenu {
          id
          name
          code
          foodingJournalDepartmentMaster {
            id
            code
            name
          }
          foodingJournalGroupMaster {
            id
            code
            name
          }
        }
      }
    }
    foodingJournalMenu {
      id
      name
      code
      foodingJournalDepartmentMaster {
        id
        code
        name
      }
      foodingJournalGroupMaster {
        id
        code
        name
      }
    }
  }
}
    `;
export const FoodingJournalPlansGetCategories = gql`
    query FoodingJournalPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const FoodingJournalPlansGetShops = gql`
    query FoodingJournalPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const FoodingJournalPlansGetMasters = gql`
    query FoodingJournalPlansGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
  foodingJournalGroupMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
}
    `;
export const FoodingJournalPlansGetFoodingJournalMenusRelatedToMenus = gql`
    query FoodingJournalPlansGetFoodingJournalMenusRelatedToMenus($companyId: uuid!) {
  foodingJournalMenu(
    where: {_and: [{companyId: {_eq: $companyId}}, {_or: [{menuId: {_is_null: false}}, {choiceId: {_is_null: false}}]}]}
  ) {
    id
    name
    code
  }
}
    `;
export const FoodingJournalPlansUpsertPlans = gql`
    mutation FoodingJournalPlansUpsertPlans($plansInput: [foodingJournalMenu_insert_input!]!, $planChoicesInput: [foodingJournalMenu_insert_input!]!) {
  insert_foodingJournalMenu(
    objects: $plansInput
    on_conflict: {constraint: foodingJournalMenu_planId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
  insert_choice_foodingJournalMenu: insert_foodingJournalMenu(
    objects: $planChoicesInput
    on_conflict: {constraint: foodingJournalMenu_planChoiceId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
}
    `;
export const FoodingJournalPlanDeletePlan = gql`
    mutation FoodingJournalPlanDeletePlan($foodingJournalMenuId: uuid!) {
  delete_foodingJournalMenu(where: {id: {_eq: $foodingJournalMenuId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalPlansGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plans: Array<(
    { __typename?: 'plan' }
    & { planId: Types.Plan['id'], name: Types.Plan['planName'] }
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'name'>
      & { planOptionId: Types.PlanOption['id'] }
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'name'>
        & { planChoiceId: Types.PlanChoice['id'] }
        & { foodingJournalMenu?: Types.Maybe<(
          { __typename?: 'foodingJournalMenu' }
          & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
          & { foodingJournalDepartmentMaster: (
            { __typename?: 'foodingJournalDepartmentMaster' }
            & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
          ), foodingJournalGroupMaster: (
            { __typename?: 'foodingJournalGroupMaster' }
            & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
          ) }
        )> }
      )> }
    )>, foodingJournalMenu?: Types.Maybe<(
      { __typename?: 'foodingJournalMenu' }
      & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
      & { foodingJournalDepartmentMaster: (
        { __typename?: 'foodingJournalDepartmentMaster' }
        & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
      ), foodingJournalGroupMaster: (
        { __typename?: 'foodingJournalGroupMaster' }
        & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
      ) }
    )> }
  )> }
);

export type FoodingJournalPlansGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlansGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type FoodingJournalPlansGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlansGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type FoodingJournalPlansGetMastersQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlansGetMastersQuery = (
  { __typename?: 'query_root' }
  & { foodingJournalDepartmentMaster: Array<(
    { __typename?: 'foodingJournalDepartmentMaster' }
    & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
  )>, foodingJournalGroupMaster: Array<(
    { __typename?: 'foodingJournalGroupMaster' }
    & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
  )> }
);

export type FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery = (
  { __typename?: 'query_root' }
  & { foodingJournalMenu: Array<(
    { __typename?: 'foodingJournalMenu' }
    & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
  )> }
);

export type FoodingJournalPlansUpsertPlansMutationVariables = Types.Exact<{
  plansInput: Array<Types.FoodingJournalMenuInsertInput> | Types.FoodingJournalMenuInsertInput;
  planChoicesInput: Array<Types.FoodingJournalMenuInsertInput> | Types.FoodingJournalMenuInsertInput;
}>;


export type FoodingJournalPlansUpsertPlansMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalMenu?: Types.Maybe<{ __typename: 'foodingJournalMenu_mutation_response' }>, insert_choice_foodingJournalMenu?: Types.Maybe<{ __typename: 'foodingJournalMenu_mutation_response' }> }
);

export type FoodingJournalPlanDeletePlanMutationVariables = Types.Exact<{
  foodingJournalMenuId: Types.Scalars['uuid'];
}>;


export type FoodingJournalPlanDeletePlanMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalMenu?: Types.Maybe<(
    { __typename?: 'foodingJournalMenu_mutation_response' }
    & Pick<Types.FoodingJournalMenuMutationResponse, 'affected_rows'>
  )> }
);


export const FoodingJournalPlansGetPlansDocument = gql`
    query FoodingJournalPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId: id
    name: planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId: id
      planChoices(where: {archivedAt: {_is_null: true}}) {
        planChoiceId: id
        name
        foodingJournalMenu {
          id
          name
          code
          foodingJournalDepartmentMaster {
            id
            code
            name
          }
          foodingJournalGroupMaster {
            id
            code
            name
          }
        }
      }
    }
    foodingJournalMenu {
      id
      name
      code
      foodingJournalDepartmentMaster {
        id
        code
        name
      }
      foodingJournalGroupMaster {
        id
        code
        name
      }
    }
  }
}
    `;

/**
 * __useFoodingJournalPlansGetPlansQuery__
 *
 * To run a query within a React component, call `useFoodingJournalPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalPlansGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalPlansGetPlansQuery, FoodingJournalPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalPlansGetPlansQuery, FoodingJournalPlansGetPlansQueryVariables>(FoodingJournalPlansGetPlansDocument, options);
      }
export function useFoodingJournalPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalPlansGetPlansQuery, FoodingJournalPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalPlansGetPlansQuery, FoodingJournalPlansGetPlansQueryVariables>(FoodingJournalPlansGetPlansDocument, options);
        }
export type FoodingJournalPlansGetPlansQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetPlansQuery>;
export type FoodingJournalPlansGetPlansLazyQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetPlansLazyQuery>;
export type FoodingJournalPlansGetPlansQueryResult = Apollo.QueryResult<FoodingJournalPlansGetPlansQuery, FoodingJournalPlansGetPlansQueryVariables>;
export const FoodingJournalPlansGetCategoriesDocument = gql`
    query FoodingJournalPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useFoodingJournalPlansGetCategoriesQuery__
 *
 * To run a query within a React component, call `useFoodingJournalPlansGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalPlansGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalPlansGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalPlansGetCategoriesQuery, FoodingJournalPlansGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalPlansGetCategoriesQuery, FoodingJournalPlansGetCategoriesQueryVariables>(FoodingJournalPlansGetCategoriesDocument, options);
      }
export function useFoodingJournalPlansGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalPlansGetCategoriesQuery, FoodingJournalPlansGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalPlansGetCategoriesQuery, FoodingJournalPlansGetCategoriesQueryVariables>(FoodingJournalPlansGetCategoriesDocument, options);
        }
export type FoodingJournalPlansGetCategoriesQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetCategoriesQuery>;
export type FoodingJournalPlansGetCategoriesLazyQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetCategoriesLazyQuery>;
export type FoodingJournalPlansGetCategoriesQueryResult = Apollo.QueryResult<FoodingJournalPlansGetCategoriesQuery, FoodingJournalPlansGetCategoriesQueryVariables>;
export const FoodingJournalPlansGetShopsDocument = gql`
    query FoodingJournalPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useFoodingJournalPlansGetShopsQuery__
 *
 * To run a query within a React component, call `useFoodingJournalPlansGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalPlansGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalPlansGetShopsQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalPlansGetShopsQuery, FoodingJournalPlansGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalPlansGetShopsQuery, FoodingJournalPlansGetShopsQueryVariables>(FoodingJournalPlansGetShopsDocument, options);
      }
export function useFoodingJournalPlansGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalPlansGetShopsQuery, FoodingJournalPlansGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalPlansGetShopsQuery, FoodingJournalPlansGetShopsQueryVariables>(FoodingJournalPlansGetShopsDocument, options);
        }
export type FoodingJournalPlansGetShopsQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetShopsQuery>;
export type FoodingJournalPlansGetShopsLazyQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetShopsLazyQuery>;
export type FoodingJournalPlansGetShopsQueryResult = Apollo.QueryResult<FoodingJournalPlansGetShopsQuery, FoodingJournalPlansGetShopsQueryVariables>;
export const FoodingJournalPlansGetMastersDocument = gql`
    query FoodingJournalPlansGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
  foodingJournalGroupMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
}
    `;

/**
 * __useFoodingJournalPlansGetMastersQuery__
 *
 * To run a query within a React component, call `useFoodingJournalPlansGetMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansGetMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalPlansGetMastersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalPlansGetMastersQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalPlansGetMastersQuery, FoodingJournalPlansGetMastersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalPlansGetMastersQuery, FoodingJournalPlansGetMastersQueryVariables>(FoodingJournalPlansGetMastersDocument, options);
      }
export function useFoodingJournalPlansGetMastersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalPlansGetMastersQuery, FoodingJournalPlansGetMastersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalPlansGetMastersQuery, FoodingJournalPlansGetMastersQueryVariables>(FoodingJournalPlansGetMastersDocument, options);
        }
export type FoodingJournalPlansGetMastersQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetMastersQuery>;
export type FoodingJournalPlansGetMastersLazyQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetMastersLazyQuery>;
export type FoodingJournalPlansGetMastersQueryResult = Apollo.QueryResult<FoodingJournalPlansGetMastersQuery, FoodingJournalPlansGetMastersQueryVariables>;
export const FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusDocument = gql`
    query FoodingJournalPlansGetFoodingJournalMenusRelatedToMenus($companyId: uuid!) {
  foodingJournalMenu(
    where: {_and: [{companyId: {_eq: $companyId}}, {_or: [{menuId: {_is_null: false}}, {choiceId: {_is_null: false}}]}]}
  ) {
    id
    name
    code
  }
}
    `;

/**
 * __useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery__
 *
 * To run a query within a React component, call `useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery, FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery, FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables>(FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusDocument, options);
      }
export function useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery, FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery, FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables>(FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusDocument, options);
        }
export type FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery>;
export type FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusLazyQueryHookResult = ReturnType<typeof useFoodingJournalPlansGetFoodingJournalMenusRelatedToMenusLazyQuery>;
export type FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryResult = Apollo.QueryResult<FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQuery, FoodingJournalPlansGetFoodingJournalMenusRelatedToMenusQueryVariables>;
export const FoodingJournalPlansUpsertPlansDocument = gql`
    mutation FoodingJournalPlansUpsertPlans($plansInput: [foodingJournalMenu_insert_input!]!, $planChoicesInput: [foodingJournalMenu_insert_input!]!) {
  insert_foodingJournalMenu(
    objects: $plansInput
    on_conflict: {constraint: foodingJournalMenu_planId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
  insert_choice_foodingJournalMenu: insert_foodingJournalMenu(
    objects: $planChoicesInput
    on_conflict: {constraint: foodingJournalMenu_planChoiceId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
}
    `;
export type FoodingJournalPlansUpsertPlansMutationFn = Apollo.MutationFunction<FoodingJournalPlansUpsertPlansMutation, FoodingJournalPlansUpsertPlansMutationVariables>;

/**
 * __useFoodingJournalPlansUpsertPlansMutation__
 *
 * To run a mutation, you first call `useFoodingJournalPlansUpsertPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlansUpsertPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalPlansUpsertPlansMutation, { data, loading, error }] = useFoodingJournalPlansUpsertPlansMutation({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *      planChoicesInput: // value for 'planChoicesInput'
 *   },
 * });
 */
export function useFoodingJournalPlansUpsertPlansMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalPlansUpsertPlansMutation, FoodingJournalPlansUpsertPlansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalPlansUpsertPlansMutation, FoodingJournalPlansUpsertPlansMutationVariables>(FoodingJournalPlansUpsertPlansDocument, options);
      }
export type FoodingJournalPlansUpsertPlansMutationHookResult = ReturnType<typeof useFoodingJournalPlansUpsertPlansMutation>;
export type FoodingJournalPlansUpsertPlansMutationResult = Apollo.MutationResult<FoodingJournalPlansUpsertPlansMutation>;
export type FoodingJournalPlansUpsertPlansMutationOptions = Apollo.BaseMutationOptions<FoodingJournalPlansUpsertPlansMutation, FoodingJournalPlansUpsertPlansMutationVariables>;
export const FoodingJournalPlanDeletePlanDocument = gql`
    mutation FoodingJournalPlanDeletePlan($foodingJournalMenuId: uuid!) {
  delete_foodingJournalMenu(where: {id: {_eq: $foodingJournalMenuId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalPlanDeletePlanMutationFn = Apollo.MutationFunction<FoodingJournalPlanDeletePlanMutation, FoodingJournalPlanDeletePlanMutationVariables>;

/**
 * __useFoodingJournalPlanDeletePlanMutation__
 *
 * To run a mutation, you first call `useFoodingJournalPlanDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalPlanDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalPlanDeletePlanMutation, { data, loading, error }] = useFoodingJournalPlanDeletePlanMutation({
 *   variables: {
 *      foodingJournalMenuId: // value for 'foodingJournalMenuId'
 *   },
 * });
 */
export function useFoodingJournalPlanDeletePlanMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalPlanDeletePlanMutation, FoodingJournalPlanDeletePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalPlanDeletePlanMutation, FoodingJournalPlanDeletePlanMutationVariables>(FoodingJournalPlanDeletePlanDocument, options);
      }
export type FoodingJournalPlanDeletePlanMutationHookResult = ReturnType<typeof useFoodingJournalPlanDeletePlanMutation>;
export type FoodingJournalPlanDeletePlanMutationResult = Apollo.MutationResult<FoodingJournalPlanDeletePlanMutation>;
export type FoodingJournalPlanDeletePlanMutationOptions = Apollo.BaseMutationOptions<FoodingJournalPlanDeletePlanMutation, FoodingJournalPlanDeletePlanMutationVariables>;