import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsFeatureFlag = gql`
    query QuestionnaireAnalyticsFeatureFlag($corporationId: uuid!) {
  questionnaireConfig(where: {company: {corporationId: {_eq: $corporationId}}}) {
    id
  }
}
    `;
export type QuestionnaireAnalyticsFeatureFlagQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsFeatureFlagQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig: Array<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id'>
  )> }
);


export const QuestionnaireAnalyticsFeatureFlagDocument = gql`
    query QuestionnaireAnalyticsFeatureFlag($corporationId: uuid!) {
  questionnaireConfig(where: {company: {corporationId: {_eq: $corporationId}}}) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsFeatureFlagQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsFeatureFlagQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsFeatureFlagQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsFeatureFlagQuery, QuestionnaireAnalyticsFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsFeatureFlagQuery, QuestionnaireAnalyticsFeatureFlagQueryVariables>(QuestionnaireAnalyticsFeatureFlagDocument, options);
      }
export function useQuestionnaireAnalyticsFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsFeatureFlagQuery, QuestionnaireAnalyticsFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsFeatureFlagQuery, QuestionnaireAnalyticsFeatureFlagQueryVariables>(QuestionnaireAnalyticsFeatureFlagDocument, options);
        }
export type QuestionnaireAnalyticsFeatureFlagQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsFeatureFlagQuery>;
export type QuestionnaireAnalyticsFeatureFlagLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsFeatureFlagLazyQuery>;
export type QuestionnaireAnalyticsFeatureFlagQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsFeatureFlagQuery, QuestionnaireAnalyticsFeatureFlagQueryVariables>;