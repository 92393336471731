import React, { memo } from "react";
import { Select } from "antd";
import { InternalNamePath } from "antd/es/form/interface";
import {
  formatOrderableTimeTermDayWeek,
  OrderableTimeTermDayWeek,
  OrderableTimeTermDayWeekType,
} from "models/orderableTime";

import { FormItemProps } from "components/antd/Form";

import { EditShopExternalOnlineMenuFormItem } from "../useEditShopExternalOnlineMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  name: InternalNamePath;
  takenDayWeeks: OrderableTimeTermDayWeekType[];
  onChange: (value: { value: string; label: string }) => void;
};

export const DayWeekField = memo<Props>(({ takenDayWeeks, onChange, ...props }) => (
  <EditShopExternalOnlineMenuFormItem
    label="曜日"
    rules={[{ required: true, message: "曜日を設定してください" }]}
    required
    {...props}
  >
    <Select labelInValue onChange={onChange}>
      {Object.keys(OrderableTimeTermDayWeek)
        .filter((dayWeek) => !takenDayWeeks.includes(dayWeek as OrderableTimeTermDayWeekType))
        .map((dayWeek) => (
          <Select.Option key={dayWeek} value={dayWeek}>
            {formatOrderableTimeTermDayWeek(dayWeek)}
          </Select.Option>
        ))}
    </Select>
  </EditShopExternalOnlineMenuFormItem>
));
