import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Table, Tag } from "antd";
import { isNotNullable, isNotUndefined } from "util/type/primitive";

import { FormHelp } from "components/Form/FormHelp";
import { Spacer } from "components/Spacer";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";

import { BulkEditConditions, Plan, PlanChoice, RowItem, Shop } from "../types";

const BoldText = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

type Props = {
  selectedPlanRowKeys: string[];
  selectedPlanChoiceRowKeys: string[];
  planRowKeyToPlanMap: Map<string, Plan>;
  planChoiceRowKeyToPlanChoiceMap: Map<string, PlanChoice>;
  shopIdToShopMap: Map<string, Shop>;
  bulkEditConditions: BulkEditConditions;
  allShopIds: string[];
};

export const EditShopPlansBulkSummary = memo<Props>(
  ({
    selectedPlanRowKeys,
    selectedPlanChoiceRowKeys,
    planRowKeyToPlanMap,
    planChoiceRowKeyToPlanChoiceMap,
    shopIdToShopMap,
    bulkEditConditions,
    allShopIds,
  }) => {
    const columns = useMemo(
      () => [
        {
          title: "",
          width: 40,
        } as const,
        {
          title: "画像",
          align: "center",
          width: 100,
          render(_: string, rowItem: RowItem) {
            if (rowItem.type !== "plan") return null;
            return <Thumbnail url={rowItem.imageUrl} width={64} height={64} />;
          },
        } as const,
        {
          title: "プラン名",
          render(_: string, rowItem: RowItem) {
            switch (rowItem.type) {
              case "plan": {
                return rowItem.planName;
              }
              case "planChoice": {
                return rowItem.name;
              }
              default:
                return null;
            }
          },
        },
        {
          title: "取扱",
          align: "center",
          width: 120,
          render() {
            if (!isNotNullable(bulkEditConditions.isDealing)) return null;
            return bulkEditConditions.isDealing ? "あり" : "なし";
          },
        } as const,
        {
          title: "公開設定",
          children: [
            {
              title() {
                return <FormHelp label="お客様" help="スマートフォン上での設定" />;
              },
              align: "center",
              width: 120,
              render() {
                if (!isNotNullable(bulkEditConditions.isVisibleForCustomer)) return null;
                return bulkEditConditions.isVisibleForCustomer ? "あり" : "なし";
              },
            },
            {
              title() {
                return <FormHelp label="スタッフ" help="ハンディ上での表示設定" />;
              },
              align: "center",
              width: 120,
              render() {
                if (!isNotNullable(bulkEditConditions.isVisibleForStaff)) return null;
                return bulkEditConditions.isVisibleForStaff ? "あり" : "なし";
              },
            },
          ],
        } as const,
        {
          title: "在庫設定",
          align: "center",
          width: 120,
          render() {
            if (!isNotNullable(bulkEditConditions.isSoldOut)) return null;
            return bulkEditConditions.isSoldOut ? "なし" : "あり";
          },
        } as const,
      ],
      [bulkEditConditions],
    );

    const [pagination, setPagination] = usePagination();
    const rows = useMemo<RowItem[]>(() => {
      const planChoiceRows = selectedPlanChoiceRowKeys
        .map((planChoiceRowKey) => {
          const planChoice = planChoiceRowKeyToPlanChoiceMap.get(planChoiceRowKey);
          if (!planChoice) return;

          return {
            ...planChoice,
            rowKey: planChoiceRowKey,
            type: "planChoice" as const,
          };
        })
        .filter(isNotUndefined);
      const planChoicePlanIds = planChoiceRows.map(({ planId }) => planId);

      const planRows = selectedPlanRowKeys
        .map((planRowKey) => {
          const plan = planRowKeyToPlanMap.get(planRowKey);
          if (!plan) return;

          return {
            ...plan,
            rowKey: planRowKey,
            type: "plan" as const,
            children: planChoicePlanIds.includes(plan.id)
              ? planChoiceRows.filter(({ planId }) => planId === plan.id)
              : null,
          };
        })
        .filter(isNotUndefined);

      return [
        ...planRows,
        ...planChoiceRows.filter(({ planId }) => !selectedPlanRowKeys.includes(planId)),
      ];
    }, [
      selectedPlanChoiceRowKeys,
      selectedPlanRowKeys,
      planChoiceRowKeyToPlanChoiceMap,
      planRowKeyToPlanMap,
    ]);

    const targetShops = useMemo(
      () =>
        (bulkEditConditions.shopIds && bulkEditConditions.shopIds.length > 0
          ? bulkEditConditions.shopIds
          : allShopIds
        )
          .map((shopId) => shopIdToShopMap.get(shopId))
          .filter(isNotUndefined),
      [allShopIds, bulkEditConditions.shopIds, shopIdToShopMap],
    );

    return (
      <>
        <div>
          <BoldText>変更対象店舗:</BoldText>
          {targetShops.map((shop) => (
            <Tag key={shop.shopId} color="processing">
              {shop.name}
            </Tag>
          ))}
        </div>
        <Spacer size={8} />
        <Table<RowItem>
          rowKey={({ rowKey }) => rowKey}
          dataSource={rows}
          columns={columns}
          pagination={pagination}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          bordered
        />
      </>
    );
  },
);
