import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddSalesBudgetGetSalesForBudget = gql`
    query AddSalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;
export const AddSalesBudgetGetMonthlySalesBudget = gql`
    query AddSalesBudgetGetMonthlySalesBudget($shopId: uuid!, $targetMonth: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_eq: $targetMonth}}
  ) {
    id
  }
}
    `;
export const AddSalesBudgetGetSalesBudgetAllocationSetting = gql`
    query AddSalesBudgetGetSalesBudgetAllocationSetting($shopId: uuid!) {
  salesBudgetAllocationSetting(where: {shopId: {_eq: $shopId}}) {
    id
    monRate
    tueRate
    wedRate
    thuRate
    friRate
    satRate
    sunRate
  }
}
    `;
export const AddSalesBudgetInsertBudget = gql`
    mutation AddSalesBudgetInsertBudget($budget: monthlySalesBudget_insert_input!) {
  insert_monthlySalesBudget_one(object: $budget) {
    id
  }
}
    `;
export const AddSalesBudgetInsertSalesBudgetAllocationSetting = gql`
    mutation AddSalesBudgetInsertSalesBudgetAllocationSetting($allocationSetting: salesBudgetAllocationSetting_insert_input!) {
  insert_salesBudgetAllocationSetting_one(
    object: $allocationSetting
    on_conflict: {constraint: salesBudgetAllocationSetting_shopId_key, update_columns: [monRate, tueRate, wedRate, thuRate, friRate, satRate, sunRate]}
  ) {
    id
  }
}
    `;
export type AddSalesBudgetGetSalesForBudgetQueryVariables = Types.Exact<{
  input: Types.SalesForBudgetInput;
}>;


export type AddSalesBudgetGetSalesForBudgetQuery = (
  { __typename?: 'query_root' }
  & { salesForBudget: (
    { __typename?: 'SalesForBudget' }
    & { salesByMonths?: Types.Maybe<(
      { __typename?: 'SalesByMonths' }
      & { monthlySalesAmount: Array<(
        { __typename?: 'MonthlySalesAmount' }
        & Pick<Types.MonthlySalesAmount, 'businessDate' | 'taxExcludedSalesAmount'>
      )> }
    )>, averageAndMaxSalesByDayOfWeek?: Types.Maybe<Array<(
      { __typename?: 'AverageAndMaxSalesByDayOfWeek' }
      & Pick<Types.AverageAndMaxSalesByDayOfWeek, 'dayOfWeek' | 'averageTaxExcludedSalesAmount' | 'maxTaxExcludedSalesAmount'>
    )>> }
  ) }
);

export type AddSalesBudgetGetMonthlySalesBudgetQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  targetMonth: Types.Scalars['date'];
}>;


export type AddSalesBudgetGetMonthlySalesBudgetQuery = (
  { __typename?: 'query_root' }
  & { monthlySalesBudget: Array<(
    { __typename?: 'monthlySalesBudget' }
    & Pick<Types.MonthlySalesBudget, 'id'>
  )> }
);

export type AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddSalesBudgetGetSalesBudgetAllocationSettingQuery = (
  { __typename?: 'query_root' }
  & { salesBudgetAllocationSetting: Array<(
    { __typename?: 'salesBudgetAllocationSetting' }
    & Pick<Types.SalesBudgetAllocationSetting, 'id' | 'monRate' | 'tueRate' | 'wedRate' | 'thuRate' | 'friRate' | 'satRate' | 'sunRate'>
  )> }
);

export type AddSalesBudgetInsertBudgetMutationVariables = Types.Exact<{
  budget: Types.MonthlySalesBudgetInsertInput;
}>;


export type AddSalesBudgetInsertBudgetMutation = (
  { __typename?: 'mutation_root' }
  & { insert_monthlySalesBudget_one?: Types.Maybe<(
    { __typename?: 'monthlySalesBudget' }
    & Pick<Types.MonthlySalesBudget, 'id'>
  )> }
);

export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables = Types.Exact<{
  allocationSetting: Types.SalesBudgetAllocationSettingInsertInput;
}>;


export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_salesBudgetAllocationSetting_one?: Types.Maybe<(
    { __typename?: 'salesBudgetAllocationSetting' }
    & Pick<Types.SalesBudgetAllocationSetting, 'id'>
  )> }
);


export const AddSalesBudgetGetSalesForBudgetDocument = gql`
    query AddSalesBudgetGetSalesForBudget($input: SalesForBudgetInput!) {
  salesForBudget(input: $input) {
    salesByMonths {
      monthlySalesAmount {
        businessDate
        taxExcludedSalesAmount
      }
    }
    averageAndMaxSalesByDayOfWeek {
      dayOfWeek
      averageTaxExcludedSalesAmount
      maxTaxExcludedSalesAmount
    }
  }
}
    `;

/**
 * __useAddSalesBudgetGetSalesForBudgetQuery__
 *
 * To run a query within a React component, call `useAddSalesBudgetGetSalesForBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddSalesBudgetGetSalesForBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddSalesBudgetGetSalesForBudgetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSalesBudgetGetSalesForBudgetQuery(baseOptions: Apollo.QueryHookOptions<AddSalesBudgetGetSalesForBudgetQuery, AddSalesBudgetGetSalesForBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddSalesBudgetGetSalesForBudgetQuery, AddSalesBudgetGetSalesForBudgetQueryVariables>(AddSalesBudgetGetSalesForBudgetDocument, options);
      }
export function useAddSalesBudgetGetSalesForBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddSalesBudgetGetSalesForBudgetQuery, AddSalesBudgetGetSalesForBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddSalesBudgetGetSalesForBudgetQuery, AddSalesBudgetGetSalesForBudgetQueryVariables>(AddSalesBudgetGetSalesForBudgetDocument, options);
        }
export type AddSalesBudgetGetSalesForBudgetQueryHookResult = ReturnType<typeof useAddSalesBudgetGetSalesForBudgetQuery>;
export type AddSalesBudgetGetSalesForBudgetLazyQueryHookResult = ReturnType<typeof useAddSalesBudgetGetSalesForBudgetLazyQuery>;
export type AddSalesBudgetGetSalesForBudgetQueryResult = Apollo.QueryResult<AddSalesBudgetGetSalesForBudgetQuery, AddSalesBudgetGetSalesForBudgetQueryVariables>;
export const AddSalesBudgetGetMonthlySalesBudgetDocument = gql`
    query AddSalesBudgetGetMonthlySalesBudget($shopId: uuid!, $targetMonth: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_eq: $targetMonth}}
  ) {
    id
  }
}
    `;

/**
 * __useAddSalesBudgetGetMonthlySalesBudgetQuery__
 *
 * To run a query within a React component, call `useAddSalesBudgetGetMonthlySalesBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddSalesBudgetGetMonthlySalesBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddSalesBudgetGetMonthlySalesBudgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      targetMonth: // value for 'targetMonth'
 *   },
 * });
 */
export function useAddSalesBudgetGetMonthlySalesBudgetQuery(baseOptions: Apollo.QueryHookOptions<AddSalesBudgetGetMonthlySalesBudgetQuery, AddSalesBudgetGetMonthlySalesBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddSalesBudgetGetMonthlySalesBudgetQuery, AddSalesBudgetGetMonthlySalesBudgetQueryVariables>(AddSalesBudgetGetMonthlySalesBudgetDocument, options);
      }
export function useAddSalesBudgetGetMonthlySalesBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddSalesBudgetGetMonthlySalesBudgetQuery, AddSalesBudgetGetMonthlySalesBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddSalesBudgetGetMonthlySalesBudgetQuery, AddSalesBudgetGetMonthlySalesBudgetQueryVariables>(AddSalesBudgetGetMonthlySalesBudgetDocument, options);
        }
export type AddSalesBudgetGetMonthlySalesBudgetQueryHookResult = ReturnType<typeof useAddSalesBudgetGetMonthlySalesBudgetQuery>;
export type AddSalesBudgetGetMonthlySalesBudgetLazyQueryHookResult = ReturnType<typeof useAddSalesBudgetGetMonthlySalesBudgetLazyQuery>;
export type AddSalesBudgetGetMonthlySalesBudgetQueryResult = Apollo.QueryResult<AddSalesBudgetGetMonthlySalesBudgetQuery, AddSalesBudgetGetMonthlySalesBudgetQueryVariables>;
export const AddSalesBudgetGetSalesBudgetAllocationSettingDocument = gql`
    query AddSalesBudgetGetSalesBudgetAllocationSetting($shopId: uuid!) {
  salesBudgetAllocationSetting(where: {shopId: {_eq: $shopId}}) {
    id
    monRate
    tueRate
    wedRate
    thuRate
    friRate
    satRate
    sunRate
  }
}
    `;

/**
 * __useAddSalesBudgetGetSalesBudgetAllocationSettingQuery__
 *
 * To run a query within a React component, call `useAddSalesBudgetGetSalesBudgetAllocationSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddSalesBudgetGetSalesBudgetAllocationSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddSalesBudgetGetSalesBudgetAllocationSettingQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddSalesBudgetGetSalesBudgetAllocationSettingQuery(baseOptions: Apollo.QueryHookOptions<AddSalesBudgetGetSalesBudgetAllocationSettingQuery, AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddSalesBudgetGetSalesBudgetAllocationSettingQuery, AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>(AddSalesBudgetGetSalesBudgetAllocationSettingDocument, options);
      }
export function useAddSalesBudgetGetSalesBudgetAllocationSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddSalesBudgetGetSalesBudgetAllocationSettingQuery, AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddSalesBudgetGetSalesBudgetAllocationSettingQuery, AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>(AddSalesBudgetGetSalesBudgetAllocationSettingDocument, options);
        }
export type AddSalesBudgetGetSalesBudgetAllocationSettingQueryHookResult = ReturnType<typeof useAddSalesBudgetGetSalesBudgetAllocationSettingQuery>;
export type AddSalesBudgetGetSalesBudgetAllocationSettingLazyQueryHookResult = ReturnType<typeof useAddSalesBudgetGetSalesBudgetAllocationSettingLazyQuery>;
export type AddSalesBudgetGetSalesBudgetAllocationSettingQueryResult = Apollo.QueryResult<AddSalesBudgetGetSalesBudgetAllocationSettingQuery, AddSalesBudgetGetSalesBudgetAllocationSettingQueryVariables>;
export const AddSalesBudgetInsertBudgetDocument = gql`
    mutation AddSalesBudgetInsertBudget($budget: monthlySalesBudget_insert_input!) {
  insert_monthlySalesBudget_one(object: $budget) {
    id
  }
}
    `;
export type AddSalesBudgetInsertBudgetMutationFn = Apollo.MutationFunction<AddSalesBudgetInsertBudgetMutation, AddSalesBudgetInsertBudgetMutationVariables>;

/**
 * __useAddSalesBudgetInsertBudgetMutation__
 *
 * To run a mutation, you first call `useAddSalesBudgetInsertBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalesBudgetInsertBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalesBudgetInsertBudgetMutation, { data, loading, error }] = useAddSalesBudgetInsertBudgetMutation({
 *   variables: {
 *      budget: // value for 'budget'
 *   },
 * });
 */
export function useAddSalesBudgetInsertBudgetMutation(baseOptions?: Apollo.MutationHookOptions<AddSalesBudgetInsertBudgetMutation, AddSalesBudgetInsertBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSalesBudgetInsertBudgetMutation, AddSalesBudgetInsertBudgetMutationVariables>(AddSalesBudgetInsertBudgetDocument, options);
      }
export type AddSalesBudgetInsertBudgetMutationHookResult = ReturnType<typeof useAddSalesBudgetInsertBudgetMutation>;
export type AddSalesBudgetInsertBudgetMutationResult = Apollo.MutationResult<AddSalesBudgetInsertBudgetMutation>;
export type AddSalesBudgetInsertBudgetMutationOptions = Apollo.BaseMutationOptions<AddSalesBudgetInsertBudgetMutation, AddSalesBudgetInsertBudgetMutationVariables>;
export const AddSalesBudgetInsertSalesBudgetAllocationSettingDocument = gql`
    mutation AddSalesBudgetInsertSalesBudgetAllocationSetting($allocationSetting: salesBudgetAllocationSetting_insert_input!) {
  insert_salesBudgetAllocationSetting_one(
    object: $allocationSetting
    on_conflict: {constraint: salesBudgetAllocationSetting_shopId_key, update_columns: [monRate, tueRate, wedRate, thuRate, friRate, satRate, sunRate]}
  ) {
    id
  }
}
    `;
export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutationFn = Apollo.MutationFunction<AddSalesBudgetInsertSalesBudgetAllocationSettingMutation, AddSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>;

/**
 * __useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation__
 *
 * To run a mutation, you first call `useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalesBudgetInsertSalesBudgetAllocationSettingMutation, { data, loading, error }] = useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation({
 *   variables: {
 *      allocationSetting: // value for 'allocationSetting'
 *   },
 * });
 */
export function useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation(baseOptions?: Apollo.MutationHookOptions<AddSalesBudgetInsertSalesBudgetAllocationSettingMutation, AddSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSalesBudgetInsertSalesBudgetAllocationSettingMutation, AddSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>(AddSalesBudgetInsertSalesBudgetAllocationSettingDocument, options);
      }
export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutationHookResult = ReturnType<typeof useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation>;
export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutationResult = Apollo.MutationResult<AddSalesBudgetInsertSalesBudgetAllocationSettingMutation>;
export type AddSalesBudgetInsertSalesBudgetAllocationSettingMutationOptions = Apollo.BaseMutationOptions<AddSalesBudgetInsertSalesBudgetAllocationSettingMutation, AddSalesBudgetInsertSalesBudgetAllocationSettingMutationVariables>;