import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/EditCookingItem/EditCookingItemForm/NameField";
import { RoleField } from "pages/EditCookingItem/EditCookingItemForm/RoleField";
import { useEditCookingItemForm } from "pages/EditCookingItem/EditCookingItemForm/useEditCookingItemForm";
import { CookingItem, Role } from "pages/EditCookingItem/types";
import { CookingItemSetInput, ShopCookingItemRoleInsertInput } from "types/graphql";

type Props = {
  shopId: string;
  cookingItem: CookingItem;
  roles: Role[];
  onSubmit: (
    cookingItem: CookingItemSetInput,
    shopCookingItemRole:
      | ShopCookingItemRoleInsertInput
      | {
          roleId: undefined;
          _roleId: undefined;
        },
  ) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditCookingItemForm = memo<Props>(
  ({ shopId, cookingItem, roles, onSubmit, onClose: handleClose, loading }) => {
    const {
      form,
      initialValues,
      submit: handleSubmit,
    } = useEditCookingItemForm({ shopId, cookingItem, roles, onSubmit });

    return (
      <>
        <FormContent>
          <Form name="store" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <RoleField roles={roles} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
