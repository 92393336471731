import React, { memo } from "react";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { DashboardAccount } from "pages/DashboardAccounts/types";

const NameCellContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MeIcon = styled(UserOutlined)`
  margin-right: 8px;
`;

type Props = {
  loading?: boolean;
  dashboardAccounts: (DashboardAccount & { isMe: boolean })[];
  onPressDelete(props: { userName: string; dashboardAccountId: string }): void;
  showDeleteAccountButton: boolean;
  showEditAccountButtonAnywhere: boolean;
};

export const DashboardAccountTable = memo<Props>(
  ({
    loading,
    dashboardAccounts,
    onPressDelete,
    showDeleteAccountButton,
    showEditAccountButtonAnywhere,
  }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();

    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = [
      {
        title: "氏名",
        dataIndex: "userName",
        render(_: unknown, { userName, isMe }: typeof dashboardAccounts[number]) {
          return (
            <NameCellContainer>
              {isMe && <MeIcon />}
              {userName}
            </NameCellContainer>
          );
        },
      },
      {
        title: "権限",
        render(_: unknown, { dashboardAccountRole }: DashboardAccount) {
          return dashboardAccountRole.name;
        },
      },
      {
        title: "店舗",
        render(_: unknown, { dashboardAccountAccessibleShops }: DashboardAccount) {
          if (dashboardAccountAccessibleShops.length <= 4) {
            return (
              dashboardAccountAccessibleShops
                // NOTE: dashboardAccountAccessibleShops は shops へのアクセス権限を管理するテーブルのため、permission を設定していない
                // そのため、ここでは dashboardAccountAccessibleShops.shop が型的には not null だが null になりうる。
                .map((accessibleShop) => accessibleShop.shop?.name ?? null)
                .filter(isNotNull)
                .join("・")
            );
          }

          const visibleShops = dashboardAccountAccessibleShops
            // NOTE: ここについても上のコメント同様。accessibleShop.shop が null になりうる。
            .filter((accessibleShop) => accessibleShop.shop)
            .slice(0, 3);
          const shopNames = visibleShops.map((shop) => shop.shop.name).join("・");
          return `${shopNames}・他${dashboardAccountAccessibleShops.length - 3}店舗`;
        },
      },
      { title: "メールアドレス", width: 300, dataIndex: "email" },
      ...(canAccess("editDashboardAccount")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(
                _: unknown,
                { id, isMe, dashboardAccountAccessibleShops }: typeof dashboardAccounts[number],
              ) {
                // NOTE: 対象アカウントのアクセス可能店舗が、ログイン中のアカウントがアクセスできない店舗を含む場合、編集できないようにする
                // https://dinii.slack.com/archives/C048W96CX1C/p1685004216235739
                // 上記 NOTE 同様、ログイン中のアカウントがアクセスできない shop は null になる
                const canEditAccount = dashboardAccountAccessibleShops.every(
                  ({ shop }) => shop !== null,
                );
                if (!canEditAccount) {
                  return <EditIcon disabled />;
                }
                if (isMe || showEditAccountButtonAnywhere) {
                  return (
                    <IconLink to={`/setting/account/${id}/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                }
                return null;
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteDashboardAccount")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: unknown, { id, userName, isMe }: typeof dashboardAccounts[number]) {
                if (!isMe && showDeleteAccountButton) {
                  return (
                    <DeleteIcon
                      onClick={() => onPressDelete({ dashboardAccountId: id, userName })}
                    />
                  );
                }
                return null;
              },
            } as const,
          ]
        : []),
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dashboardAccounts}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
