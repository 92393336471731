import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "antd";
import { isNumber } from "lodash";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { AddPlanChoiceForm } from "pages/PlanOptions/AddPlanChoiceModal/AddPlanChoiceForm";
import {
  useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery,
  useAddPlanChoiceModalGetPlanOptionQuery,
  useAddPlanChoiceModalInsertPlanChoiceMutation,
} from "pages/PlanOptions/AddPlanChoiceModal/queries";

import { AddPlanChoiceFormValues } from "./AddPlanChoiceForm/useAddPlanChoiceForm";

type Props = {
  planId: number;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddPlanChoiceModal = memo<Props>(({ planId, goBack, onUpdated }) => {
  const params = useParams<Record<"planId" | "planOptionId", string>>();
  const _planOptionId = Number(params.planOptionId);
  const [company] = useCompany();

  if (Number.isNaN(_planOptionId)) {
    goBack();
  }

  const { data: getPlanOptionData, error: getPlanOptionDataError } =
    useAddPlanChoiceModalGetPlanOptionQuery({
      variables: { planId, planOptionId: _planOptionId },
    });
  const planOption = getPlanOptionData?.planOption[0];
  const planOptionId = planOption?.id;

  const { refetch, error: getPlanChoiceDataError } =
    useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery({
      variables: { planId, planOptionId: _planOptionId },
    });

  const [addPlanChoiceMutation, { loading: loadingInsertPlanChoice }] =
    useAddPlanChoiceModalInsertPlanChoiceMutation();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<AddPlanChoiceFormValues | null>(null);
  const onOk = useCallback(async () => {
    const companyId = company?.id;
    const _companyId = company?.companyId;

    if (!formValues || !isNumber(_companyId) || !companyId || !planOptionId) {
      return;
    }

    try {
      await form.validateFields();

      const { data: getMaxPlanChoicePriorityData } = await refetch();

      const maxPriority =
        getMaxPlanChoicePriorityData?.planChoice_aggregate.aggregate?.max?.priority;
      const priority = maxPriority ? maxPriority + 1 : 0;

      const {
        hasOrderCountLimit,
        description,
        isDefaultSelection,
        maxOrderableNum,
        name,
        price,
        receiptDisplayName,
        costPrice,
        costTaxRate,
        menuType,
        imageUrl,
        nameSources,
      } = formValues;

      const { data: addPlanChoiceData } = await addPlanChoiceMutation({
        variables: {
          planChoice: {
            createPlanChoiceSource: {
              description,
              name,
              price,
              costPrice,
              costTaxRate,
              menuType,
              imageUrl,
              receiptDisplayName,
              planOptionId,
              _planOptionId,
              companyId,
              _companyId,
              // NOTE: maxOrderableNum は hasOrderCountLimit がtrue の時のみ有効とする仕様
              maxOrderableNum: hasOrderCountLimit ? maxOrderableNum : null,
              isDefaultSelection: Boolean(isDefaultSelection),
              priority,
            },
            createTranslationsSource: {
              nameSources: (nameSources ?? []).filter(({ value }) => value !== null),
            },
          },
        },
      });
      const planChoiceId = addPlanChoiceData?.createPlanChoice?.planChoiceId;

      if (planChoiceId === undefined) {
        throw new Error("");
      }

      onUpdated();

      message.success("作成しました");
    } catch (err) {
      return message.error("作成に失敗しました");
    }

    goBack();
  }, [
    company?.id,
    company?.companyId,
    formValues,
    goBack,
    form,
    refetch,
    addPlanChoiceMutation,
    planOptionId,
    _planOptionId,
    onUpdated,
  ]);

  const onCancel = goBack;

  const shouldShowAlert = getPlanChoiceDataError || getPlanOptionDataError;

  return (
    <Modal
      title="選択肢新規作成"
      open
      width={900}
      onOk={goBack}
      onCancel={onCancel}
      footer={[
        <Button key="save" type="primary" onClick={onOk} loading={loadingInsertPlanChoice}>
          保存
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planOption && (
        <AddPlanChoiceForm
          form={form}
          planOption={planOption}
          inputType={planOption.inputType}
          onChange={setFormValues}
        />
      )}
    </Modal>
  );
});
