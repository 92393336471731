import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PikaichiMenuInsertInput } from "types/graphql";

import { PikaichiMenu, PlanChoice } from "../../types";

export type EditPlanChoicePikaichiMenuFormValues = Pick<
  PikaichiMenu,
  | "pikaichiMenuCd"
  | "pikaichiMenuName"
  | "pikaichiCategoryCd"
  | "pikaichiCategoryName"
  | "pikaichiBumonCd"
  | "pikaichiBumonName"
>;

export const EditPlanChoicePikaichiMenuFormItem =
  createFormItem<EditPlanChoicePikaichiMenuFormValues>();

export const useEditPlanChoicePikaichiMenuForm = ({
  planChoice,
  pikaichiMenu,
  onSubmit,
}: {
  planChoice?: PlanChoice;
  pikaichiMenu?: PikaichiMenu;
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
}) => {
  const [form] = Form.useForm<EditPlanChoicePikaichiMenuFormValues>();

  const initialValues = useMemo<EditPlanChoicePikaichiMenuFormValues>(
    () => ({
      pikaichiMenuCd: pikaichiMenu?.pikaichiMenuCd ?? "",
      pikaichiMenuName: pikaichiMenu?.pikaichiMenuName ?? planChoice?.name ?? "",
      pikaichiBumonCd: pikaichiMenu?.pikaichiBumonCd ?? "",
      pikaichiBumonName: pikaichiMenu?.pikaichiBumonName ?? "",
      pikaichiCategoryCd: pikaichiMenu?.pikaichiCategoryCd ?? "",
      pikaichiCategoryName: pikaichiMenu?.pikaichiCategoryName ?? "",
    }),
    [planChoice, pikaichiMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
