import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { OnSitePaymentDetailType } from "pages/EditOnSitePaymentDetailTypePriorities/types";

type Props = {
  loading?: boolean;
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  onUpdate: (onSitePaymentDetailTypes: OnSitePaymentDetailType[]) => void;
};

export const EditOnSitePaymentDetailTypePriorityList = memo<Props>(
  ({ loading, onSitePaymentDetailTypes, onUpdate }) => {
    const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        onUpdate(updatePriorities(onSitePaymentDetailTypes, dragIndex, hoverIndex));
      }
    };

    return (
      <PriorityList<OnSitePaymentDetailType>
        itemLayout="horizontal"
        loading={loading}
        dataSource={onSitePaymentDetailTypes}
        renderItem={({ id, label, priority }, index) => (
          <PriorityListItem
            id={id}
            name={label}
            priority={priority}
            index={index}
            onMove={handleMoveItem}
          />
        )}
      />
    );
  },
);
