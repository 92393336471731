import React, { memo } from "react";
import { Switch } from "antd";

import { EditShopFormItem } from "../useEditShopForm";

export const OneOrderLimitPerPeopleField = memo(() => (
  <EditShopFormItem
    label="1回の注文の1人あたりの注文上限"
    name="hasMaxNumOfOrdersPerPeople"
    valuePropName="checked"
  >
    <Switch />
  </EditShopFormItem>
));
