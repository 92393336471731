import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, DatePicker } from "antd";
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { ShopSelectDropdown } from "components/ShopSelector/ShopSelectDropdown";
import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useShopSelect } from "hooks/useShopSelect";

const MAX_SELECTABLE_DAYS = 366;
const fieldSpacingAmount = 12;

const LoadingIcon = styled(LoadingOutlined)`
  margin-right: 8px;
`;

const CaretDownIcon = styled(CaretDownOutlined)`
  margin-left: 8px;
`;

const FiltersRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null];
} & Omit<FilterConditions, "range">;

type Props = {
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  onShopChange: () => void;
};

export const Filters = memo<Props>(
  ({ filterConditions, onChangeFilterCondition, onShopChange }) => {
    const { currentShop, currentShopName, loading, setShop, shops } = useShopSelect();
    const shopId = useMemo(() => currentShop?.shopId, [currentShop]);
    const now = useMemo(() => {
      void shopId;
      return dayjs();
    }, [shopId]);
    const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(
      () => [now.subtract(30, "days"), now],
      [now],
    );

    const handleChangeShop = useCallback(
      (shopId: string) => {
        onShopChange();
        setShop(shopId);
      },
      [onShopChange, setShop],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const startAt = values?.[0]?.startOf("day") ?? null;
        const endAt = values?.[1]?.endOf("day") ?? null;

        onChangeFilterCondition({ range: [startAt, endAt] });
      },
      [onChangeFilterCondition],
    );

    // NOTE: API のパフォーマンス観点から選択可能な期間を最大一年間とする
    const disabledDate = useCallback(
      (current: dayjs.Dayjs | null) => {
        const startAt = filterConditions.range?.[0] ?? null;
        const endAt = filterConditions.range?.[1] ?? null;

        if (startAt === null && endAt === null) return false;

        const isTooLate = Boolean(
          current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
        );
        const isTooEarly = Boolean(
          current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
        );

        return isTooLate || isTooEarly;
      },
      [filterConditions.range],
    );

    return (
      <>
        <FiltersRow>
          <ShopSelectDropdown shops={shops} setShop={handleChangeShop}>
            <Button>
              {loading && <LoadingIcon />}
              {currentShopName ?? "店舗を選択"}
              <CaretDownIcon />
            </Button>
          </ShopSelectDropdown>
          <Spacer size={fieldSpacingAmount} />
          <DatePicker.RangePicker
            value={filterConditions.range ?? initialRange}
            onCalendarChange={handleChangeRange}
            clearIcon={false}
            disabledDate={disabledDate}
            renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
          />
        </FiltersRow>
      </>
    );
  },
);
