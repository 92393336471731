import React, { memo } from "react";
import { InputNumber, Space } from "antd";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

import { lessThanOrEqualFieldTransformer, moreThanOrEqualFieldTransformer } from "./functions";

export const ConsecutiveUnvisitedDayCountFilter = memo(() => (
  <>
    <Space size="small">
      <MessageDeliveryJobFormItem
        noStyle
        name={["consecutiveUnvisitedDayCountFilter", "moreThanOrEqual"]}
        rules={[
          ({ getFieldValue, setFieldsValue }) => ({
            transform(value: string | null) {
              moreThanOrEqualFieldTransformer({
                value,
                name: "consecutiveUnvisitedDayCountFilter",
                getFieldValue,
                setFieldsValue,
              });
            },
          }),
        ]}
      >
        <InputNumber controls={false} style={{ width: "88px" }} min={1} />
      </MessageDeliveryJobFormItem>
      日
    </Space>
    <span>〜</span>
    <Space size="small">
      <MessageDeliveryJobFormItem
        noStyle
        name={["consecutiveUnvisitedDayCountFilter", "lessThanOrEqual"]}
        rules={[
          ({ getFieldValue, setFieldsValue }) => ({
            transform(value: string | null) {
              lessThanOrEqualFieldTransformer({
                value,
                name: "consecutiveUnvisitedDayCountFilter",
                getFieldValue,
                setFieldsValue,
              });
            },
          }),
        ]}
      >
        <InputNumber controls={false} style={{ width: "88px" }} min={1} />
      </MessageDeliveryJobFormItem>
      日
    </Space>
  </>
));
