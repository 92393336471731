import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopMenuOutputGetMenu = gql`
    query EditShopMenuOutputGetMenu($menuId: Int!, $shopId: uuid!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    ) {
      shop {
        shopId
        name
        useKd
      }
      shopMenuKitchenRoles {
        menuId: _menuId
        shopId
        roleId
        role {
          id
          name
          roleId
        }
      }
      shopMenuCookingItems {
        cookingItem {
          id: serial
          cookingItemId
          name
          shopCookingItemRole {
            role {
              id
              roleId
              name
            }
          }
        }
      }
      shopMenuKdDisplayTargets {
        kdDisplayTarget {
          id
          name
        }
      }
    }
    companyId
    menuId
    id
    name
  }
}
    `;
export const EditShopMenuOutputGetShopAndRolesAndKdDisplayTargets = gql`
    query EditShopMenuOutputGetShopAndRolesAndKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    useKd
    roles {
      id
      name
      roleId
    }
    kdDisplayTargets {
      id
      name
    }
  }
}
    `;
export const EditShopMenuOutputInsertShopMenuRolesRole = gql`
    mutation EditShopMenuOutputInsertShopMenuRolesRole($shopMenuKitchenRole: [shopMenuKitchenRole_insert_input!]!) {
  insert_shopMenuKitchenRole(
    objects: $shopMenuKitchenRole
    on_conflict: {constraint: shopMenuKitchenRole_shopId_menuId_roleId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export const EditShopMenuOutputDeleteShopMenuRolesRole = gql`
    mutation EditShopMenuOutputDeleteShopMenuRolesRole($menuId: Int!, $roleId: uuid!) {
  delete_shopMenuKitchenRole(
    where: {_menuId: {_eq: $menuId}, roleId: {_eq: $roleId}}
  ) {
    affected_rows
  }
}
    `;
export const EditShopMenuOutputInsertShopMenuKdDisplayTarget = gql`
    mutation EditShopMenuOutputInsertShopMenuKdDisplayTarget($shopMenuKdDisplayTarget: [shopMenuKdDisplayTarget_insert_input!]!) {
  insert_shopMenuKdDisplayTarget(
    objects: $shopMenuKdDisplayTarget
    on_conflict: {constraint: shopMenuKdDisplayTarget_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export const EditShopMenuOutputDeleteShopMenuKdDisplayTarget = gql`
    mutation EditShopMenuOutputDeleteShopMenuKdDisplayTarget($menuId: Int!, $kdDisplayTargetId: uuid!) {
  delete_shopMenuKdDisplayTarget(
    where: {_and: [{_menuId: {_eq: $menuId}}, {kdDisplayTargetId: {_eq: $kdDisplayTargetId}}]}
  ) {
    affected_rows
  }
}
    `;
export const EditShopMenuOutputDeleteMenuCookingItem = gql`
    mutation EditShopMenuOutputDeleteMenuCookingItem($menuId: Int!, $cookingItemId: uuid!) {
  delete_shopMenuCookingItem(
    where: {_and: [{_menuId: {_eq: $menuId}, cookingItemId: {_eq: $cookingItemId}}]}
  ) {
    affected_rows
    returning {
      shopId
      menuId: _menuId
      cookingItemId
    }
  }
}
    `;
export type EditShopMenuOutputGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopMenuOutputGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'companyId' | 'menuId' | 'id' | 'name'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name' | 'useKd'>
      ), shopMenuKitchenRoles: Array<(
        { __typename?: 'shopMenuKitchenRole' }
        & Pick<Types.ShopMenuKitchenRole, 'shopId' | 'roleId'>
        & { menuId: Types.ShopMenuKitchenRole['_menuId'] }
        & { role: (
          { __typename?: 'role' }
          & Pick<Types.Role, 'id' | 'name' | 'roleId'>
        ) }
      )>, shopMenuCookingItems: Array<(
        { __typename?: 'shopMenuCookingItem' }
        & { cookingItem?: Types.Maybe<(
          { __typename?: 'cookingItem' }
          & Pick<Types.CookingItem, 'cookingItemId' | 'name'>
          & { id: Types.CookingItem['serial'] }
          & { shopCookingItemRole?: Types.Maybe<(
            { __typename?: 'shopCookingItemRole' }
            & { role: (
              { __typename?: 'role' }
              & Pick<Types.Role, 'id' | 'roleId' | 'name'>
            ) }
          )> }
        )> }
      )>, shopMenuKdDisplayTargets: Array<(
        { __typename?: 'shopMenuKdDisplayTarget' }
        & { kdDisplayTarget?: Types.Maybe<(
          { __typename?: 'kdDisplayTarget' }
          & Pick<Types.KdDisplayTarget, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'useKd'>
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )>, kdDisplayTargets: Array<(
      { __typename?: 'kdDisplayTarget' }
      & Pick<Types.KdDisplayTarget, 'id' | 'name'>
    )> }
  )> }
);

export type EditShopMenuOutputInsertShopMenuRolesRoleMutationVariables = Types.Exact<{
  shopMenuKitchenRole: Array<Types.ShopMenuKitchenRoleInsertInput> | Types.ShopMenuKitchenRoleInsertInput;
}>;


export type EditShopMenuOutputInsertShopMenuRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopMenuKitchenRole?: Types.Maybe<(
    { __typename?: 'shopMenuKitchenRole_mutation_response' }
    & Pick<Types.ShopMenuKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopMenuOutputDeleteShopMenuRolesRoleMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  roleId: Types.Scalars['uuid'];
}>;


export type EditShopMenuOutputDeleteShopMenuRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuKitchenRole?: Types.Maybe<(
    { __typename?: 'shopMenuKitchenRole_mutation_response' }
    & Pick<Types.ShopMenuKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationVariables = Types.Exact<{
  shopMenuKdDisplayTarget: Array<Types.ShopMenuKdDisplayTargetInsertInput> | Types.ShopMenuKdDisplayTargetInsertInput;
}>;


export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopMenuKdDisplayTarget?: Types.Maybe<(
    { __typename?: 'shopMenuKdDisplayTarget_mutation_response' }
    & Pick<Types.ShopMenuKdDisplayTargetMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  kdDisplayTargetId: Types.Scalars['uuid'];
}>;


export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuKdDisplayTarget?: Types.Maybe<(
    { __typename?: 'shopMenuKdDisplayTarget_mutation_response' }
    & Pick<Types.ShopMenuKdDisplayTargetMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopMenuOutputDeleteMenuCookingItemMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  cookingItemId: Types.Scalars['uuid'];
}>;


export type EditShopMenuOutputDeleteMenuCookingItemMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuCookingItem?: Types.Maybe<(
    { __typename?: 'shopMenuCookingItem_mutation_response' }
    & Pick<Types.ShopMenuCookingItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopMenuCookingItem' }
      & Pick<Types.ShopMenuCookingItem, 'shopId' | 'cookingItemId'>
      & { menuId: Types.ShopMenuCookingItem['_menuId'] }
    )> }
  )> }
);


export const EditShopMenuOutputGetMenuDocument = gql`
    query EditShopMenuOutputGetMenu($menuId: Int!, $shopId: uuid!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    ) {
      shop {
        shopId
        name
        useKd
      }
      shopMenuKitchenRoles {
        menuId: _menuId
        shopId
        roleId
        role {
          id
          name
          roleId
        }
      }
      shopMenuCookingItems {
        cookingItem {
          id: serial
          cookingItemId
          name
          shopCookingItemRole {
            role {
              id
              roleId
              name
            }
          }
        }
      }
      shopMenuKdDisplayTargets {
        kdDisplayTarget {
          id
          name
        }
      }
    }
    companyId
    menuId
    id
    name
  }
}
    `;

/**
 * __useEditShopMenuOutputGetMenuQuery__
 *
 * To run a query within a React component, call `useEditShopMenuOutputGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenuOutputGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopMenuOutputGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenuOutputGetMenuQuery, EditShopMenuOutputGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenuOutputGetMenuQuery, EditShopMenuOutputGetMenuQueryVariables>(EditShopMenuOutputGetMenuDocument, options);
      }
export function useEditShopMenuOutputGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenuOutputGetMenuQuery, EditShopMenuOutputGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenuOutputGetMenuQuery, EditShopMenuOutputGetMenuQueryVariables>(EditShopMenuOutputGetMenuDocument, options);
        }
export type EditShopMenuOutputGetMenuQueryHookResult = ReturnType<typeof useEditShopMenuOutputGetMenuQuery>;
export type EditShopMenuOutputGetMenuLazyQueryHookResult = ReturnType<typeof useEditShopMenuOutputGetMenuLazyQuery>;
export type EditShopMenuOutputGetMenuQueryResult = Apollo.QueryResult<EditShopMenuOutputGetMenuQuery, EditShopMenuOutputGetMenuQueryVariables>;
export const EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsDocument = gql`
    query EditShopMenuOutputGetShopAndRolesAndKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    useKd
    roles {
      id
      name
      roleId
    }
    kdDisplayTargets {
      id
      name
    }
  }
}
    `;

/**
 * __useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery__
 *
 * To run a query within a React component, call `useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery, EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery, EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables>(EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsDocument, options);
      }
export function useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery, EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery, EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables>(EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsDocument, options);
        }
export type EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryHookResult = ReturnType<typeof useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery>;
export type EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsLazyQueryHookResult = ReturnType<typeof useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsLazyQuery>;
export type EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryResult = Apollo.QueryResult<EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery, EditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQueryVariables>;
export const EditShopMenuOutputInsertShopMenuRolesRoleDocument = gql`
    mutation EditShopMenuOutputInsertShopMenuRolesRole($shopMenuKitchenRole: [shopMenuKitchenRole_insert_input!]!) {
  insert_shopMenuKitchenRole(
    objects: $shopMenuKitchenRole
    on_conflict: {constraint: shopMenuKitchenRole_shopId_menuId_roleId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditShopMenuOutputInsertShopMenuRolesRoleMutationFn = Apollo.MutationFunction<EditShopMenuOutputInsertShopMenuRolesRoleMutation, EditShopMenuOutputInsertShopMenuRolesRoleMutationVariables>;

/**
 * __useEditShopMenuOutputInsertShopMenuRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopMenuOutputInsertShopMenuRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputInsertShopMenuRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuOutputInsertShopMenuRolesRoleMutation, { data, loading, error }] = useEditShopMenuOutputInsertShopMenuRolesRoleMutation({
 *   variables: {
 *      shopMenuKitchenRole: // value for 'shopMenuKitchenRole'
 *   },
 * });
 */
export function useEditShopMenuOutputInsertShopMenuRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuOutputInsertShopMenuRolesRoleMutation, EditShopMenuOutputInsertShopMenuRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuOutputInsertShopMenuRolesRoleMutation, EditShopMenuOutputInsertShopMenuRolesRoleMutationVariables>(EditShopMenuOutputInsertShopMenuRolesRoleDocument, options);
      }
export type EditShopMenuOutputInsertShopMenuRolesRoleMutationHookResult = ReturnType<typeof useEditShopMenuOutputInsertShopMenuRolesRoleMutation>;
export type EditShopMenuOutputInsertShopMenuRolesRoleMutationResult = Apollo.MutationResult<EditShopMenuOutputInsertShopMenuRolesRoleMutation>;
export type EditShopMenuOutputInsertShopMenuRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopMenuOutputInsertShopMenuRolesRoleMutation, EditShopMenuOutputInsertShopMenuRolesRoleMutationVariables>;
export const EditShopMenuOutputDeleteShopMenuRolesRoleDocument = gql`
    mutation EditShopMenuOutputDeleteShopMenuRolesRole($menuId: Int!, $roleId: uuid!) {
  delete_shopMenuKitchenRole(
    where: {_menuId: {_eq: $menuId}, roleId: {_eq: $roleId}}
  ) {
    affected_rows
  }
}
    `;
export type EditShopMenuOutputDeleteShopMenuRolesRoleMutationFn = Apollo.MutationFunction<EditShopMenuOutputDeleteShopMenuRolesRoleMutation, EditShopMenuOutputDeleteShopMenuRolesRoleMutationVariables>;

/**
 * __useEditShopMenuOutputDeleteShopMenuRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopMenuOutputDeleteShopMenuRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputDeleteShopMenuRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuOutputDeleteShopMenuRolesRoleMutation, { data, loading, error }] = useEditShopMenuOutputDeleteShopMenuRolesRoleMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEditShopMenuOutputDeleteShopMenuRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuOutputDeleteShopMenuRolesRoleMutation, EditShopMenuOutputDeleteShopMenuRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuOutputDeleteShopMenuRolesRoleMutation, EditShopMenuOutputDeleteShopMenuRolesRoleMutationVariables>(EditShopMenuOutputDeleteShopMenuRolesRoleDocument, options);
      }
export type EditShopMenuOutputDeleteShopMenuRolesRoleMutationHookResult = ReturnType<typeof useEditShopMenuOutputDeleteShopMenuRolesRoleMutation>;
export type EditShopMenuOutputDeleteShopMenuRolesRoleMutationResult = Apollo.MutationResult<EditShopMenuOutputDeleteShopMenuRolesRoleMutation>;
export type EditShopMenuOutputDeleteShopMenuRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopMenuOutputDeleteShopMenuRolesRoleMutation, EditShopMenuOutputDeleteShopMenuRolesRoleMutationVariables>;
export const EditShopMenuOutputInsertShopMenuKdDisplayTargetDocument = gql`
    mutation EditShopMenuOutputInsertShopMenuKdDisplayTarget($shopMenuKdDisplayTarget: [shopMenuKdDisplayTarget_insert_input!]!) {
  insert_shopMenuKdDisplayTarget(
    objects: $shopMenuKdDisplayTarget
    on_conflict: {constraint: shopMenuKdDisplayTarget_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationFn = Apollo.MutationFunction<EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation, EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationVariables>;

/**
 * __useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation__
 *
 * To run a mutation, you first call `useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuOutputInsertShopMenuKdDisplayTargetMutation, { data, loading, error }] = useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation({
 *   variables: {
 *      shopMenuKdDisplayTarget: // value for 'shopMenuKdDisplayTarget'
 *   },
 * });
 */
export function useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation, EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation, EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationVariables>(EditShopMenuOutputInsertShopMenuKdDisplayTargetDocument, options);
      }
export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationHookResult = ReturnType<typeof useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation>;
export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationResult = Apollo.MutationResult<EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation>;
export type EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationOptions = Apollo.BaseMutationOptions<EditShopMenuOutputInsertShopMenuKdDisplayTargetMutation, EditShopMenuOutputInsertShopMenuKdDisplayTargetMutationVariables>;
export const EditShopMenuOutputDeleteShopMenuKdDisplayTargetDocument = gql`
    mutation EditShopMenuOutputDeleteShopMenuKdDisplayTarget($menuId: Int!, $kdDisplayTargetId: uuid!) {
  delete_shopMenuKdDisplayTarget(
    where: {_and: [{_menuId: {_eq: $menuId}}, {kdDisplayTargetId: {_eq: $kdDisplayTargetId}}]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationFn = Apollo.MutationFunction<EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation, EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationVariables>;

/**
 * __useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation__
 *
 * To run a mutation, you first call `useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuOutputDeleteShopMenuKdDisplayTargetMutation, { data, loading, error }] = useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *   },
 * });
 */
export function useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation, EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation, EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationVariables>(EditShopMenuOutputDeleteShopMenuKdDisplayTargetDocument, options);
      }
export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationHookResult = ReturnType<typeof useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation>;
export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationResult = Apollo.MutationResult<EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation>;
export type EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationOptions = Apollo.BaseMutationOptions<EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation, EditShopMenuOutputDeleteShopMenuKdDisplayTargetMutationVariables>;
export const EditShopMenuOutputDeleteMenuCookingItemDocument = gql`
    mutation EditShopMenuOutputDeleteMenuCookingItem($menuId: Int!, $cookingItemId: uuid!) {
  delete_shopMenuCookingItem(
    where: {_and: [{_menuId: {_eq: $menuId}, cookingItemId: {_eq: $cookingItemId}}]}
  ) {
    affected_rows
    returning {
      shopId
      menuId: _menuId
      cookingItemId
    }
  }
}
    `;
export type EditShopMenuOutputDeleteMenuCookingItemMutationFn = Apollo.MutationFunction<EditShopMenuOutputDeleteMenuCookingItemMutation, EditShopMenuOutputDeleteMenuCookingItemMutationVariables>;

/**
 * __useEditShopMenuOutputDeleteMenuCookingItemMutation__
 *
 * To run a mutation, you first call `useEditShopMenuOutputDeleteMenuCookingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuOutputDeleteMenuCookingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuOutputDeleteMenuCookingItemMutation, { data, loading, error }] = useEditShopMenuOutputDeleteMenuCookingItemMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      cookingItemId: // value for 'cookingItemId'
 *   },
 * });
 */
export function useEditShopMenuOutputDeleteMenuCookingItemMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuOutputDeleteMenuCookingItemMutation, EditShopMenuOutputDeleteMenuCookingItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuOutputDeleteMenuCookingItemMutation, EditShopMenuOutputDeleteMenuCookingItemMutationVariables>(EditShopMenuOutputDeleteMenuCookingItemDocument, options);
      }
export type EditShopMenuOutputDeleteMenuCookingItemMutationHookResult = ReturnType<typeof useEditShopMenuOutputDeleteMenuCookingItemMutation>;
export type EditShopMenuOutputDeleteMenuCookingItemMutationResult = Apollo.MutationResult<EditShopMenuOutputDeleteMenuCookingItemMutation>;
export type EditShopMenuOutputDeleteMenuCookingItemMutationOptions = Apollo.BaseMutationOptions<EditShopMenuOutputDeleteMenuCookingItemMutation, EditShopMenuOutputDeleteMenuCookingItemMutationVariables>;