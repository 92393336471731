import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";

import { CouponsTable } from "./CouponsTable";
import { useCouponsGetCouponsQuery } from "./queries";

export const Coupons = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data, error, loading } = useCouponsGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="クーポン一覧">
      <PageHeader
        title="クーポン一覧"
        extra={[
          canAccess("addCoupon") && (
            <Link key="add" to="/coupon/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CouponsTable loading={loading} coupons={data?.coupon ?? []} />
    </DashboardLayout>
  );
};
