import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Radio } from "antd";
import { intersection } from "lodash";
import { filterPlansByName } from "models/plan";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { FilterConditions, PlanFilter } from "pages/Plans/PlanFilter";
import { PlanTable, PlanTableDisplayType } from "pages/Plans/PlanTable";
import {
  usePlansArchivePlanMutation,
  usePlansGetCategoriesQuery,
  usePlansGetPlansQuery,
  usePlansGetShopsQuery,
} from "pages/Plans/queries";
import { Plan } from "pages/Plans/types";
import { DashboardAccountRoleTypeEnum, DisplayTypeEnum } from "types/graphql";

const isIncludedInDisplayTypes = (
  plan: Pick<Plan, "displayType">,
  displayTypes: DisplayTypeEnum[],
) => displayTypes.includes(plan.displayType ?? DisplayTypeEnum.Medium);

const filterPlans = (
  plans: Plan[],
  { name, categoryIds, displayTypes, shopIds }: FilterConditions,
) => {
  const filteredPlans = plans.filter(
    (plan) =>
      (categoryIds === undefined || categoryIds.includes(plan.category.categoryId)) &&
      (displayTypes === undefined || isIncludedInDisplayTypes(plan, displayTypes)) &&
      (shopIds === undefined ||
        shopIds.length === 0 ||
        intersection(
          shopIds,
          plan.shopPlans.map(({ shop }) => shop.shopId),
        ).length > 0),
  );

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans.filter(isNotNullable);
};

const displayTypeOptions: { label: string; value: PlanTableDisplayType }[] = [
  { label: "簡易表示", value: "compact" },
  { label: "詳細表示", value: "detail" },
];

const RadioGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Plans = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getPlansData,
    loading: loadingPlans,
    refetch: refetchPlans,
    error: errorGetPlansData,
  } = usePlansGetPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const plans = useMemo(() => getPlansData?.plans ?? [], [getPlansData]);

  const { data: getCategoriesData, error: errorGetCategoriesData } = usePlansGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData, error: errorGetShopsData } = usePlansGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);
  const shopIdToShopMap = useMemo(() => new Map(shops.map((shop) => [shop.shopId, shop])), [shops]);

  const [archivePlanMutation] = usePlansArchivePlanMutation();

  const onDelete = useCallback(
    async ({ planId }: { planId: string }) => {
      try {
        if (!companyId) {
          return;
        }

        await archivePlanMutation({
          variables: { input: { planId, companyId } },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchPlans();
    },
    [archivePlanMutation, companyId, refetchPlans],
  );

  const { accessibleShopIds, role } = useCurrentUserPermissions();
  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({
      shopIds:
        role === DashboardAccountRoleTypeEnum.ShopMember
          ? accessibleShopIds?.filter((shopId) => shopIdToShopMap.has(shopId))
          : undefined,
    });

  const filteredPlans = useMemo(
    () => filterPlans(plans, filterConditions),
    [plans, filterConditions],
  );

  const [displayType, setDisplayType] = useState<PlanTableDisplayType>("compact");

  const onClickDisplayTypeButton = useCallback((event) => {
    setDisplayType(event.target.value);
  }, []);

  const shouldShowAlert = errorGetShopsData || errorGetCategoriesData || errorGetPlansData;

  return (
    <DashboardLayout title="プラン一覧">
      <PageHeader
        title="プラン一覧"
        extra={[
          isFeatureEnabled("addPlan") && (
            <Link key="add" to="/plan/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
          isFeatureEnabled("editPlan") && (
            <Link key="editPriority" to="/plan/priority/edit">
              <Button>表示順編集</Button>
            </Link>
          ),
        ]}
        footer={
          <>
            <RadioGroupWrapper>
              <Radio.Group
                options={displayTypeOptions}
                onChange={onClickDisplayTypeButton}
                value={displayType}
                optionType="button"
              />
            </RadioGroupWrapper>
            <PlanFilter
              shops={shops}
              categories={categories}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <PlanTable
        displayType={displayType}
        plans={filteredPlans}
        loading={loadingPlans}
        onDelete={onDelete}
      />
    </DashboardLayout>
  );
};
