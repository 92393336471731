import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditOnSitePaymentDetailTypeFormItem } from "../useEditOnSitePaymentDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LabelField = memo<Props>((props) => (
  <EditOnSitePaymentDetailTypeFormItem
    label="支払方法名"
    name="label"
    rules={[{ required: true, message: "支払方法名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditOnSitePaymentDetailTypeFormItem>
));
