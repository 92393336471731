import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCategoryFormItem } from "../useEditCategoryForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>(({ disabled, ...props }) => (
  <EditCategoryFormItem
    label="カテゴリ名"
    name="name"
    rules={[{ required: true, message: "カテゴリ名を入力してください" }]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditCategoryFormItem>
));
