import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { DishUpReceiptField } from "pages/AddRole/AddRoleForm/DishUpReceiptField";
import { FirstSpareRoleField } from "pages/AddRole/AddRoleForm/FirstSpareRoleField";
import { NameField } from "pages/AddRole/AddRoleForm/NameField";
import { PaymentReceiptField } from "pages/AddRole/AddRoleForm/PaymentReceiptField";
import { PrinterSoundField } from "pages/AddRole/AddRoleForm/PrinterSoundField";
import { SecondSpareRoleField } from "pages/AddRole/AddRoleForm/SecondSpareRoleField";
import { ShouldPrintFreeOrderOnDishUpSlipField } from "pages/AddRole/AddRoleForm/ShouldPrintFreeOrderOnDishUpSlipField";
import { AddRoleFormValues, useAddRoleForm } from "pages/AddRole/AddRoleForm/useAddRoleForm";
import { Role } from "pages/AddRole/types";

import { OnlinePaymentSlipField } from "./OnlinePaymentSlipField";
import { PrintDishUpSlipAsPrimarySlipField } from "./PrintDishUpSlipAsPrimarySlipField";

type Props = {
  roles: Role[];
  onSubmit: (plan: AddRoleFormValues) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddRoleForm = memo<Props>(({ roles, onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useAddRoleForm(onSubmit);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="role" form={form} layout="vertical" initialValues={initialValues}>
          <NameField wrapperCol={{ span: 8 }} />
          <OnlinePaymentSlipField />
          <PaymentReceiptField />
          <DishUpReceiptField />
          <PrintDishUpSlipAsPrimarySlipField />
          <ShouldPrintFreeOrderOnDishUpSlipField />
          <PrinterSoundField />
          <FirstSpareRoleField roles={roles} wrapperCol={{ span: 8 }} />
          <SecondSpareRoleField roles={roles} wrapperCol={{ span: 8 }} />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          登録
        </Button>
      </FormActions>
    </>
  );
});
