import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Card, Col, Row } from "antd";
import { MAXIMUM_LENGTH_OF_MESSAGES } from "models/messageDelivery";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { WithPreviewFormContainer } from "components/Form/WithPreviewFormContainer";
import { MessagePreviewContent } from "components/MessageDeliveryForm/MessagePreview/MessagePreviewContent";
import { LineOfficialAccountFragment } from "components/MessageDeliveryForm/MessagePreview/queries";
import { Spacer } from "components/Spacer";
import { UserAppPreview } from "components/UserAppPreview";
import { CSVUploadCustomerListField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/CSVUploadCustomerListField";
import { CustomerListField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/CustomerListField";
import { CustomerSegmentField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/CustomerSegmentField";
import { NameField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/NameField";
import { QuestionnaireConfigField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/QuestionnaireConfigField";
import { Scope, ScopeField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/ScopeField";
import { TextField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/TextField";
import { TimeField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/TimeField";
import { TypeField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/TypeField";
import { UrlField } from "pages/AddMessageDelivery/AddMessageDeliveryForm/UrlField";
import {
  Coupon,
  Customer,
  Menu,
  MessageDelivery,
  QuestionnaireConfig,
  Shop,
} from "pages/AddMessageDelivery/types";
import {
  BusinessOperationHourTypeEnum,
  CreateMessageDeliveryInput,
  MessageDeliveryMessageTypeEnum,
} from "types/graphql";

import { CouponSelectField } from "./CouponSelectField";
import { DateField } from "./DateField";
import { IsRepeatDeliveryField } from "./IsRepeatDeliveryField";
import {
  AddMessageDeliveryFormItem,
  AddMessageDeliveryFormValues,
  useAddMessageDeliveryForm,
} from "./useAddMessageDeliveryForm";

const StyledCard = styled(Card)`
  margin: 20px;
`;

const AddMessageButton = styled(Button)`
  margin: 0 20px 20px;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 300px;
`;

type Props = {
  sourceMessageDelivery: MessageDelivery | undefined;
  loading: boolean;
  submitting: boolean;
  onSubmit: ({ messageDelivery }: { messageDelivery: CreateMessageDeliveryInput }) => void;
  onClose: () => void;
  shops: Shop[];
  menus: Menu[];
  coupons: Coupon[];
  questionnaireConfigs: QuestionnaireConfig[];
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
  lineOfficialAccounts: LineOfficialAccountFragment[];
  onSearchCustomersByLikeName: ({ likeName }: { likeName: string }) => Promise<Customer[]>;
  onSearchCustomersByCustomerIds: ({
    customerIds,
  }: {
    customerIds: string[];
  }) => Promise<Customer[]>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddMessageDeliveryForm = memo<Props>(
  ({
    sourceMessageDelivery,
    loading,
    submitting,
    coupons,
    shops,
    menus,
    questionnaireConfigs,
    shopBusinessOperationHourTypes,
    lineOfficialAccounts,
    onSubmit,
    onClose,
    onSearchCustomersByCustomerIds,
    onSearchCustomersByLikeName,
    onFormValidationError,
  }: Props) => {
    const { form, initialValues, submit, submitAsDraft } = useAddMessageDeliveryForm({
      sourceMessageDelivery,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    const handleSubmitAsDraft = useCallback(async () => {
      try {
        await form.validateFields();
        submitAsDraft();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submitAsDraft]);

    return (
      <Form name="messageDelivery" form={form} initialValues={initialValues} layout="vertical">
        <WithPreviewFormContainer>
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ情報">
                <NameField endSpacer={null} />
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="配信対象">
                <ScopeField endSpacer={null} />
                <Spacer size={24} />
                <AddMessageDeliveryFormItem.NonProperty noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const scope: Scope = getFieldValue("scope");
                    if (scope === "customerSegment") {
                      return (
                        <CustomerSegmentField
                          form={form}
                          shops={shops}
                          menus={menus}
                          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
                        />
                      );
                    }
                    if (scope === "customerListCsvUpload") {
                      return (
                        <CSVUploadCustomerListField
                          onSearchCustomersByCustomerIds={onSearchCustomersByCustomerIds}
                        />
                      );
                    }
                    if (scope === "customerList") {
                      return (
                        <CustomerListField
                          onSearchCustomersByLikeName={onSearchCustomersByLikeName}
                        />
                      );
                    }
                  }}
                </AddMessageDeliveryFormItem.NonProperty>
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <FormSection title="配信日時">
            <Row>
              <Col span={12}>
                <DateField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverTimeName="deliverTime"
                />
              </Col>
              <Col span={12}>
                <TimeField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverDateName="deliverDate"
                />
              </Col>
            </Row>
            <Spacer size={24} />
            <IsRepeatDeliveryField />
          </FormSection>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ">
                <FormList name="messages">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <StyledCard key={field.key}>
                          <Row justify="space-around" align="middle">
                            <Col flex={1}>
                              <TypeField name={field.name} />
                            </Col>
                            <Col>
                              <Button
                                icon={<DeleteIcon disabled={fields.length === 1} />}
                                onClick={() => remove(field.name)}
                                disabled={fields.length === 1}
                              />
                            </Col>
                          </Row>
                          <Spacer height={20} />
                          <AddMessageDeliveryFormItem.NonProperty noStyle shouldUpdate>
                            {({ getFieldValue, setFields }) => {
                              const type: MessageDeliveryMessageTypeEnum = getFieldValue([
                                "messages",
                                field.name,
                                "type",
                              ]);

                              switch (type) {
                                case "text":
                                  return (
                                    <TextField
                                      name={field.name}
                                      endSpacer={<Spacer size={22} /* char count の高さ */ />}
                                    />
                                  );
                                case "image": {
                                  const imageNamePath = ["messages", field.name, "imageUrl"];
                                  const imageUrl: string = getFieldValue(imageNamePath);
                                  return (
                                    <>
                                      <Row gutter={16}>
                                        <Col>
                                          <ImageField
                                            image={imageUrl}
                                            setUploadImage={(image) =>
                                              setFields([{ name: imageNamePath, value: image }])
                                            }
                                            uploadImageApiKey="messageImage"
                                            formName="AddMessageDeliveryForm"
                                            bottomHelpText="画像は、正方形のみ選択可能です"
                                            shouldValidateSquareImage
                                          />
                                        </Col>
                                        <Col flex={1}>
                                          <UrlField name={field.name} endSpacer={null} />
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }
                                case "coupon":
                                  return (
                                    <>
                                      <CouponSelectField name={field.name} coupons={coupons} />
                                    </>
                                  );
                                case "questionnaire": {
                                  return (
                                    <>
                                      <QuestionnaireConfigField
                                        name={field.name}
                                        questionnaireConfigs={questionnaireConfigs}
                                      />
                                      <Spacer size={32} />
                                    </>
                                  );
                                }
                                default:
                                  return <p>Unreachable</p>;
                              }
                            }}
                          </AddMessageDeliveryFormItem.NonProperty>
                        </StyledCard>
                      ))}
                      <FormActions>
                        <AddMessageButton
                          type="primary"
                          onClick={() => add({ type: "text", text: "" })}
                          disabled={fields.length >= MAXIMUM_LENGTH_OF_MESSAGES}
                        >
                          追加する
                        </AddMessageButton>
                      </FormActions>
                    </>
                  )}
                </FormList>
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <FormActions>
            <Button onClick={onClose}>キャンセル</Button>
            <Button
              onClick={loading || submitting ? undefined : handleSubmitAsDraft}
              loading={loading || submitting}
              disabled={loading || submitting}
            >
              下書き保存
            </Button>
            <Button
              type="primary"
              onClick={loading || submitting ? undefined : handleSubmit}
              loading={loading || submitting}
              disabled={loading || submitting}
            >
              配信予約
            </Button>
          </FormActions>

          <AddMessageDeliveryFormItem.NonProperty shouldUpdate noStyle initialValue={initialValues}>
            {({ getFieldsValue }) => {
              const { messages } = getFieldsValue() as AddMessageDeliveryFormValues;

              return (
                <PreviewWrapper>
                  <UserAppPreview>
                    <MessagePreviewContent
                      lineOfficialAccount={lineOfficialAccounts[0]}
                      messages={messages ?? []}
                    />
                  </UserAppPreview>
                </PreviewWrapper>
              );
            }}
          </AddMessageDeliveryFormItem.NonProperty>
        </WithPreviewFormContainer>
      </Form>
    );
  },
);
