import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiMenuOptionsGetMenu = gql`
    query PikaichiMenuOptionsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    menuId
    name
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          name
          costPrice
          pikaichiMenuChoices {
            pikaichiMenuId
            choiceId: _choiceId
            pikaichiMenu {
              pikaichiMenuId
              pikaichiMenuCd
              pikaichiMenuName
              pikaichiBumonCd
              pikaichiBumonName
              pikaichiCategoryCd
              pikaichiCategoryName
            }
          }
        }
      }
    }
  }
}
    `;
export type PikaichiMenuOptionsGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type PikaichiMenuOptionsGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'name' | 'companyId' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'optionId' | 'name'>
        & { choices: Array<(
          { __typename?: 'choice' }
          & Pick<Types.Choice, 'choiceId' | 'name' | 'costPrice'>
          & { pikaichiMenuChoices: Array<(
            { __typename?: 'pikaichiMenuChoice' }
            & Pick<Types.PikaichiMenuChoice, 'pikaichiMenuId'>
            & { choiceId: Types.PikaichiMenuChoice['_choiceId'] }
            & { pikaichiMenu: (
              { __typename?: 'pikaichiMenu' }
              & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
            ) }
          )> }
        )> }
      ) }
    )> }
  )> }
);


export const PikaichiMenuOptionsGetMenuDocument = gql`
    query PikaichiMenuOptionsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    menuId
    name
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          name
          costPrice
          pikaichiMenuChoices {
            pikaichiMenuId
            choiceId: _choiceId
            pikaichiMenu {
              pikaichiMenuId
              pikaichiMenuCd
              pikaichiMenuName
              pikaichiBumonCd
              pikaichiBumonName
              pikaichiCategoryCd
              pikaichiCategoryName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePikaichiMenuOptionsGetMenuQuery__
 *
 * To run a query within a React component, call `usePikaichiMenuOptionsGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenuOptionsGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiMenuOptionsGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function usePikaichiMenuOptionsGetMenuQuery(baseOptions: Apollo.QueryHookOptions<PikaichiMenuOptionsGetMenuQuery, PikaichiMenuOptionsGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiMenuOptionsGetMenuQuery, PikaichiMenuOptionsGetMenuQueryVariables>(PikaichiMenuOptionsGetMenuDocument, options);
      }
export function usePikaichiMenuOptionsGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiMenuOptionsGetMenuQuery, PikaichiMenuOptionsGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiMenuOptionsGetMenuQuery, PikaichiMenuOptionsGetMenuQueryVariables>(PikaichiMenuOptionsGetMenuDocument, options);
        }
export type PikaichiMenuOptionsGetMenuQueryHookResult = ReturnType<typeof usePikaichiMenuOptionsGetMenuQuery>;
export type PikaichiMenuOptionsGetMenuLazyQueryHookResult = ReturnType<typeof usePikaichiMenuOptionsGetMenuLazyQuery>;
export type PikaichiMenuOptionsGetMenuQueryResult = Apollo.QueryResult<PikaichiMenuOptionsGetMenuQuery, PikaichiMenuOptionsGetMenuQueryVariables>;