import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const RankingVisitedCountsGetCustomerVisitedCountRanking = gql`
    query RankingVisitedCountsGetCustomerVisitedCountRanking($input: QueryCustomerRankingInput!) {
  customerVisitedCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;
export type RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type RankingVisitedCountsGetCustomerVisitedCountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerVisitedCountRanking: (
    { __typename?: 'CustomerRanking' }
    & Pick<Types.CustomerRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerRankingCustomer' }
      & Pick<Types.CustomerRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastVisitedAt'>
    )> }
  ) }
);


export const RankingVisitedCountsGetCustomerVisitedCountRankingDocument = gql`
    query RankingVisitedCountsGetCustomerVisitedCountRanking($input: QueryCustomerRankingInput!) {
  customerVisitedCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;

/**
 * __useRankingVisitedCountsGetCustomerVisitedCountRankingQuery__
 *
 * To run a query within a React component, call `useRankingVisitedCountsGetCustomerVisitedCountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankingVisitedCountsGetCustomerVisitedCountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankingVisitedCountsGetCustomerVisitedCountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRankingVisitedCountsGetCustomerVisitedCountRankingQuery(baseOptions: Apollo.QueryHookOptions<RankingVisitedCountsGetCustomerVisitedCountRankingQuery, RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RankingVisitedCountsGetCustomerVisitedCountRankingQuery, RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables>(RankingVisitedCountsGetCustomerVisitedCountRankingDocument, options);
      }
export function useRankingVisitedCountsGetCustomerVisitedCountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RankingVisitedCountsGetCustomerVisitedCountRankingQuery, RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RankingVisitedCountsGetCustomerVisitedCountRankingQuery, RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables>(RankingVisitedCountsGetCustomerVisitedCountRankingDocument, options);
        }
export type RankingVisitedCountsGetCustomerVisitedCountRankingQueryHookResult = ReturnType<typeof useRankingVisitedCountsGetCustomerVisitedCountRankingQuery>;
export type RankingVisitedCountsGetCustomerVisitedCountRankingLazyQueryHookResult = ReturnType<typeof useRankingVisitedCountsGetCustomerVisitedCountRankingLazyQuery>;
export type RankingVisitedCountsGetCustomerVisitedCountRankingQueryResult = Apollo.QueryResult<RankingVisitedCountsGetCustomerVisitedCountRankingQuery, RankingVisitedCountsGetCustomerVisitedCountRankingQueryVariables>;