import React, { memo, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";

import { Form } from "components/antd/Form";

import { KitchenRolesField } from "./KitchenRolesField";
import { KitchenRole, ShopOptionKitchenRole } from "./types";
import { EditKitchenRolesFormValues, useEditKitchenRolesForm } from "./useEditKitchenRolesForm";

type Props = {
  shopOptionKitchenRoles: ShopOptionKitchenRole[];
  kitchenRoles: KitchenRole[];
  loading: boolean;
  onCancel: () => void;
  onSubmit: (formValues: EditKitchenRolesFormValues | null) => void;
};

const Heading = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const EditKitchenRolesForm = memo<Props>(
  ({ shopOptionKitchenRoles, kitchenRoles, loading, onCancel, onSubmit }) => {
    const [formValues, setFormValues] = useState<EditKitchenRolesFormValues | null>(null);

    const { form, initialValues, change } = useEditKitchenRolesForm({
      shopOptionKitchenRoles,
      onChange: setFormValues,
    });

    return (
      <Form
        name="kitchenRoles"
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={change}
      >
        <Heading>キッチンプリンター</Heading>
        <KitchenRolesField kitchenRoles={kitchenRoles} />

        <Footer>
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            key="add"
            type="primary"
            onClick={() => onSubmit(formValues)}
            loading={loading}
            disabled={kitchenRoles.length === 0}
          >
            更新
          </Button>
        </Footer>
      </Form>
    );
  },
);
