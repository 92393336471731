import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";

export type EditBankDepositAmountFormValues = {
  bankDepositAmount: number;
};

const getInitialValues = (bankDepositAmount: number) => ({ bankDepositAmount });

export const EditBankDepositAmountFormItem = createFormItem<EditBankDepositAmountFormValues>();

export const useEditBankDepositAmountForm = (
  bankDepositAmount: number,
  onChange: (bankDepositAmount: EditBankDepositAmountFormValues) => void,
) => {
  const initialValues = getInitialValues(bankDepositAmount);

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditBankDepositAmountFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { initialValues, change };
};
