import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Clerk } from "pages/EditClerk/types";
import { ClerkSetInput, ShopClerk, ShopClerkSetInput } from "types/graphql";

export type EditClerkFormValues = Pick<Clerk, "name"> & Pick<ShopClerk, "isDisplay">;

const getInitialValues = (clerk: Clerk) => {
  const { name, isDisplay } = clerk;
  return { name, isDisplay };
};

export const EditClerkFormItem = createFormItem<EditClerkFormValues>();

export const useEditClerkForm = (
  clerk: Clerk,
  onSubmit: ({ clerk, shopClerk }: { clerk: ClerkSetInput; shopClerk: ShopClerkSetInput }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(clerk);

  const submit = useCallback(() => {
    const { isDisplay, ...values } = form.getFieldsValue() as EditClerkFormValues;
    const clerk = values;
    const shopClerk = { isDisplay };
    onSubmit({ clerk, shopClerk });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
