import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditChoicePikaichiMenuFormItem } from "../useEditChoicePikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiMenuNameField = memo<Props>((props) => (
  <EditChoicePikaichiMenuFormItem
    label="メニュー名称"
    name="pikaichiMenuName"
    rules={[
      { required: true, message: "メニュー名称を入力してください" },
      { max: 25, message: "25文字以下で入力してください" },
    ]}
    {...props}
  >
    <Input />
  </EditChoicePikaichiMenuFormItem>
));
