import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddGiftFormItem } from "../useAddGiftForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddGiftFormItem
    label="メニュー名"
    name="name"
    rules={[{ required: true, message: "メニュー名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddGiftFormItem>
));
