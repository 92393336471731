import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditGiftPrioritiesGetGiftMeta = gql`
    query EditGiftPrioritiesGetGiftMeta($shopId: uuid!) {
  giftMeta(
    where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    priority
    menu {
      id
      menuId
      name
    }
  }
}
    `;
export const EditGiftPrioritiesInsertGiftMeta = gql`
    mutation EditGiftPrioritiesInsertGiftMeta($giftMeta: [giftMeta_insert_input!]!) {
  insert_giftMeta(
    objects: $giftMeta
    on_conflict: {constraint: giftMeta_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      menuId
    }
  }
}
    `;
export type EditGiftPrioritiesGetGiftMetaQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditGiftPrioritiesGetGiftMetaQuery = (
  { __typename?: 'query_root' }
  & { giftMeta: Array<(
    { __typename?: 'giftMeta' }
    & Pick<Types.GiftMeta, 'id' | 'priority'>
    & { menu: (
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'menuId' | 'name'>
    ) }
  )> }
);

export type EditGiftPrioritiesInsertGiftMetaMutationVariables = Types.Exact<{
  giftMeta: Array<Types.GiftMetaInsertInput> | Types.GiftMetaInsertInput;
}>;


export type EditGiftPrioritiesInsertGiftMetaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_giftMeta?: Types.Maybe<(
    { __typename?: 'giftMeta_mutation_response' }
    & Pick<Types.GiftMetaMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'giftMeta' }
      & Pick<Types.GiftMeta, 'menuId'>
    )> }
  )> }
);


export const EditGiftPrioritiesGetGiftMetaDocument = gql`
    query EditGiftPrioritiesGetGiftMeta($shopId: uuid!) {
  giftMeta(
    where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    priority
    menu {
      id
      menuId
      name
    }
  }
}
    `;

/**
 * __useEditGiftPrioritiesGetGiftMetaQuery__
 *
 * To run a query within a React component, call `useEditGiftPrioritiesGetGiftMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGiftPrioritiesGetGiftMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGiftPrioritiesGetGiftMetaQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditGiftPrioritiesGetGiftMetaQuery(baseOptions: Apollo.QueryHookOptions<EditGiftPrioritiesGetGiftMetaQuery, EditGiftPrioritiesGetGiftMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGiftPrioritiesGetGiftMetaQuery, EditGiftPrioritiesGetGiftMetaQueryVariables>(EditGiftPrioritiesGetGiftMetaDocument, options);
      }
export function useEditGiftPrioritiesGetGiftMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGiftPrioritiesGetGiftMetaQuery, EditGiftPrioritiesGetGiftMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGiftPrioritiesGetGiftMetaQuery, EditGiftPrioritiesGetGiftMetaQueryVariables>(EditGiftPrioritiesGetGiftMetaDocument, options);
        }
export type EditGiftPrioritiesGetGiftMetaQueryHookResult = ReturnType<typeof useEditGiftPrioritiesGetGiftMetaQuery>;
export type EditGiftPrioritiesGetGiftMetaLazyQueryHookResult = ReturnType<typeof useEditGiftPrioritiesGetGiftMetaLazyQuery>;
export type EditGiftPrioritiesGetGiftMetaQueryResult = Apollo.QueryResult<EditGiftPrioritiesGetGiftMetaQuery, EditGiftPrioritiesGetGiftMetaQueryVariables>;
export const EditGiftPrioritiesInsertGiftMetaDocument = gql`
    mutation EditGiftPrioritiesInsertGiftMeta($giftMeta: [giftMeta_insert_input!]!) {
  insert_giftMeta(
    objects: $giftMeta
    on_conflict: {constraint: giftMeta_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      menuId
    }
  }
}
    `;
export type EditGiftPrioritiesInsertGiftMetaMutationFn = Apollo.MutationFunction<EditGiftPrioritiesInsertGiftMetaMutation, EditGiftPrioritiesInsertGiftMetaMutationVariables>;

/**
 * __useEditGiftPrioritiesInsertGiftMetaMutation__
 *
 * To run a mutation, you first call `useEditGiftPrioritiesInsertGiftMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGiftPrioritiesInsertGiftMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGiftPrioritiesInsertGiftMetaMutation, { data, loading, error }] = useEditGiftPrioritiesInsertGiftMetaMutation({
 *   variables: {
 *      giftMeta: // value for 'giftMeta'
 *   },
 * });
 */
export function useEditGiftPrioritiesInsertGiftMetaMutation(baseOptions?: Apollo.MutationHookOptions<EditGiftPrioritiesInsertGiftMetaMutation, EditGiftPrioritiesInsertGiftMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGiftPrioritiesInsertGiftMetaMutation, EditGiftPrioritiesInsertGiftMetaMutationVariables>(EditGiftPrioritiesInsertGiftMetaDocument, options);
      }
export type EditGiftPrioritiesInsertGiftMetaMutationHookResult = ReturnType<typeof useEditGiftPrioritiesInsertGiftMetaMutation>;
export type EditGiftPrioritiesInsertGiftMetaMutationResult = Apollo.MutationResult<EditGiftPrioritiesInsertGiftMetaMutation>;
export type EditGiftPrioritiesInsertGiftMetaMutationOptions = Apollo.BaseMutationOptions<EditGiftPrioritiesInsertGiftMetaMutation, EditGiftPrioritiesInsertGiftMetaMutationVariables>;