import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const LiffIdGetShop = gql`
    query LiffIdGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    lineChannelConfig {
      liffId
    }
  }
}
    `;
export type LiffIdGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type LiffIdGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { lineChannelConfig?: Types.Maybe<(
      { __typename?: 'lineChannelConfig' }
      & Pick<Types.LineChannelConfig, 'liffId'>
    )> }
  )> }
);


export const LiffIdGetShopDocument = gql`
    query LiffIdGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    lineChannelConfig {
      liffId
    }
  }
}
    `;

/**
 * __useLiffIdGetShopQuery__
 *
 * To run a query within a React component, call `useLiffIdGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiffIdGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiffIdGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useLiffIdGetShopQuery(baseOptions: Apollo.QueryHookOptions<LiffIdGetShopQuery, LiffIdGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LiffIdGetShopQuery, LiffIdGetShopQueryVariables>(LiffIdGetShopDocument, options);
      }
export function useLiffIdGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LiffIdGetShopQuery, LiffIdGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LiffIdGetShopQuery, LiffIdGetShopQueryVariables>(LiffIdGetShopDocument, options);
        }
export type LiffIdGetShopQueryHookResult = ReturnType<typeof useLiffIdGetShopQuery>;
export type LiffIdGetShopLazyQueryHookResult = ReturnType<typeof useLiffIdGetShopLazyQuery>;
export type LiffIdGetShopQueryResult = Apollo.QueryResult<LiffIdGetShopQuery, LiffIdGetShopQueryVariables>;