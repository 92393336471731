import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetMenuAutoCompulsoryAppetizerConfig = gql`
    query GetMenuAutoCompulsoryAppetizerConfig($companyId: uuid!) {
  compulsoryAppetizerConfig(
    where: {companyId: {_eq: $companyId}, menu: {categoryMenus: {category: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
  ) {
    id
    menuId
    autoOrderStartTime
    autoOrderEndTime
    menu {
      id
      serialId: menuId
      name
      price
      openPrice
      taxMethod
      categoryMenus {
        category {
          categoryId
          name
        }
      }
    }
  }
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    menus {
      id
      name
      price
      openPrice
      taxMethod
      imageUrl
      menuType
      categoryMenus {
        category {
          categoryId
          name
        }
      }
    }
  }
}
    `;
export const DeleteAutoCompulsoryAppetizerConfig = gql`
    mutation DeleteAutoCompulsoryAppetizerConfig($companyId: uuid!) {
  delete_compulsoryAppetizerConfig(where: {companyId: {_eq: $companyId}}) {
    affected_rows
  }
}
    `;
export const MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfig = gql`
    mutation MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfig($autoCompulsoryAppetizer: compulsoryAppetizerConfig_insert_input!) {
  insert_compulsoryAppetizerConfig_one(
    object: $autoCompulsoryAppetizer
    on_conflict: {constraint: compulsoryAppetizerConfig_companyId_key, update_columns: [menuId, autoOrderStartTime, autoOrderEndTime]}
  ) {
    companyId
  }
}
    `;
export type GetMenuAutoCompulsoryAppetizerConfigQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GetMenuAutoCompulsoryAppetizerConfigQuery = (
  { __typename?: 'query_root' }
  & { compulsoryAppetizerConfig: Array<(
    { __typename?: 'compulsoryAppetizerConfig' }
    & Pick<Types.CompulsoryAppetizerConfig, 'id' | 'menuId' | 'autoOrderStartTime' | 'autoOrderEndTime'>
    & { menu: (
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'name' | 'price' | 'openPrice' | 'taxMethod'>
      & { serialId: Types.Menu['menuId'] }
      & { categoryMenus: Array<(
        { __typename?: 'categoryMenu' }
        & { category: (
          { __typename?: 'category' }
          & Pick<Types.Category, 'categoryId' | 'name'>
        ) }
      )> }
    ) }
  )>, company: Array<(
    { __typename?: 'company' }
    & { menus: Array<(
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'name' | 'price' | 'openPrice' | 'taxMethod' | 'imageUrl' | 'menuType'>
      & { categoryMenus: Array<(
        { __typename?: 'categoryMenu' }
        & { category: (
          { __typename?: 'category' }
          & Pick<Types.Category, 'categoryId' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type DeleteAutoCompulsoryAppetizerConfigMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type DeleteAutoCompulsoryAppetizerConfigMutation = (
  { __typename?: 'mutation_root' }
  & { delete_compulsoryAppetizerConfig?: Types.Maybe<(
    { __typename?: 'compulsoryAppetizerConfig_mutation_response' }
    & Pick<Types.CompulsoryAppetizerConfigMutationResponse, 'affected_rows'>
  )> }
);

export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationVariables = Types.Exact<{
  autoCompulsoryAppetizer: Types.CompulsoryAppetizerConfigInsertInput;
}>;


export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_compulsoryAppetizerConfig_one?: Types.Maybe<(
    { __typename?: 'compulsoryAppetizerConfig' }
    & Pick<Types.CompulsoryAppetizerConfig, 'companyId'>
  )> }
);


export const GetMenuAutoCompulsoryAppetizerConfigDocument = gql`
    query GetMenuAutoCompulsoryAppetizerConfig($companyId: uuid!) {
  compulsoryAppetizerConfig(
    where: {companyId: {_eq: $companyId}, menu: {categoryMenus: {category: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
  ) {
    id
    menuId
    autoOrderStartTime
    autoOrderEndTime
    menu {
      id
      serialId: menuId
      name
      price
      openPrice
      taxMethod
      categoryMenus {
        category {
          categoryId
          name
        }
      }
    }
  }
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    menus {
      id
      name
      price
      openPrice
      taxMethod
      imageUrl
      menuType
      categoryMenus {
        category {
          categoryId
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuAutoCompulsoryAppetizerConfigQuery__
 *
 * To run a query within a React component, call `useGetMenuAutoCompulsoryAppetizerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuAutoCompulsoryAppetizerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuAutoCompulsoryAppetizerConfigQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetMenuAutoCompulsoryAppetizerConfigQuery(baseOptions: Apollo.QueryHookOptions<GetMenuAutoCompulsoryAppetizerConfigQuery, GetMenuAutoCompulsoryAppetizerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuAutoCompulsoryAppetizerConfigQuery, GetMenuAutoCompulsoryAppetizerConfigQueryVariables>(GetMenuAutoCompulsoryAppetizerConfigDocument, options);
      }
export function useGetMenuAutoCompulsoryAppetizerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuAutoCompulsoryAppetizerConfigQuery, GetMenuAutoCompulsoryAppetizerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuAutoCompulsoryAppetizerConfigQuery, GetMenuAutoCompulsoryAppetizerConfigQueryVariables>(GetMenuAutoCompulsoryAppetizerConfigDocument, options);
        }
export type GetMenuAutoCompulsoryAppetizerConfigQueryHookResult = ReturnType<typeof useGetMenuAutoCompulsoryAppetizerConfigQuery>;
export type GetMenuAutoCompulsoryAppetizerConfigLazyQueryHookResult = ReturnType<typeof useGetMenuAutoCompulsoryAppetizerConfigLazyQuery>;
export type GetMenuAutoCompulsoryAppetizerConfigQueryResult = Apollo.QueryResult<GetMenuAutoCompulsoryAppetizerConfigQuery, GetMenuAutoCompulsoryAppetizerConfigQueryVariables>;
export const DeleteAutoCompulsoryAppetizerConfigDocument = gql`
    mutation DeleteAutoCompulsoryAppetizerConfig($companyId: uuid!) {
  delete_compulsoryAppetizerConfig(where: {companyId: {_eq: $companyId}}) {
    affected_rows
  }
}
    `;
export type DeleteAutoCompulsoryAppetizerConfigMutationFn = Apollo.MutationFunction<DeleteAutoCompulsoryAppetizerConfigMutation, DeleteAutoCompulsoryAppetizerConfigMutationVariables>;

/**
 * __useDeleteAutoCompulsoryAppetizerConfigMutation__
 *
 * To run a mutation, you first call `useDeleteAutoCompulsoryAppetizerConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAutoCompulsoryAppetizerConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAutoCompulsoryAppetizerConfigMutation, { data, loading, error }] = useDeleteAutoCompulsoryAppetizerConfigMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteAutoCompulsoryAppetizerConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAutoCompulsoryAppetizerConfigMutation, DeleteAutoCompulsoryAppetizerConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAutoCompulsoryAppetizerConfigMutation, DeleteAutoCompulsoryAppetizerConfigMutationVariables>(DeleteAutoCompulsoryAppetizerConfigDocument, options);
      }
export type DeleteAutoCompulsoryAppetizerConfigMutationHookResult = ReturnType<typeof useDeleteAutoCompulsoryAppetizerConfigMutation>;
export type DeleteAutoCompulsoryAppetizerConfigMutationResult = Apollo.MutationResult<DeleteAutoCompulsoryAppetizerConfigMutation>;
export type DeleteAutoCompulsoryAppetizerConfigMutationOptions = Apollo.BaseMutationOptions<DeleteAutoCompulsoryAppetizerConfigMutation, DeleteAutoCompulsoryAppetizerConfigMutationVariables>;
export const MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigDocument = gql`
    mutation MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfig($autoCompulsoryAppetizer: compulsoryAppetizerConfig_insert_input!) {
  insert_compulsoryAppetizerConfig_one(
    object: $autoCompulsoryAppetizer
    on_conflict: {constraint: compulsoryAppetizerConfig_companyId_key, update_columns: [menuId, autoOrderStartTime, autoOrderEndTime]}
  ) {
    companyId
  }
}
    `;
export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationFn = Apollo.MutationFunction<MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation, MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationVariables>;

/**
 * __useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation__
 *
 * To run a mutation, you first call `useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation, { data, loading, error }] = useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation({
 *   variables: {
 *      autoCompulsoryAppetizer: // value for 'autoCompulsoryAppetizer'
 *   },
 * });
 */
export function useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation(baseOptions?: Apollo.MutationHookOptions<MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation, MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation, MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationVariables>(MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigDocument, options);
      }
export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationHookResult = ReturnType<typeof useMenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation>;
export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationResult = Apollo.MutationResult<MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation>;
export type MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationOptions = Apollo.BaseMutationOptions<MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutation, MenuAutoCompulsoryAppetizerSetCompulsoryAppetizerConfigMutationVariables>;