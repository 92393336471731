import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableCustomerSegmentSelectionField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="属性入力"
        help="属性入力を有効にすると、ハンディから属性入力を行えるようになります"
      />
    }
    name="enableCustomerSegmentSelection"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
