import React, { memo, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { EditPlanOptionFormValues } from "pages/PlanOptions/EditPlanOptionModal/EditPlanOptionForm/useEditPlanOptionForm";
import {
  useEditPlanOptionModalGetPlanOptionQuery,
  useEditPlanOptionModalUpdateCountPlanOptionMutation,
  useEditPlanOptionModalUpdateSelectPlanOptionMutation,
} from "pages/PlanOptions/EditPlanOptionModal/queries";
import { OptionInputTypeEnum, SupportedLocaleEnumType } from "types/graphql";

import { EditPlanOptionModalForm } from "./EditPlanOptionModalForm";

type Props = {
  goBack: () => void;
  onUpdated: () => void;
  canEditPlanOption: boolean;
  canEditPlanChoice: boolean;
};

export const EditPlanOptionModal = memo<Props>(
  ({ goBack, onUpdated, canEditPlanOption, canEditPlanChoice }) => {
    const { planOptionId: id } = useParams<{ planOptionId: string }>();
    const planOptionId = Number(id);

    if (Number.isNaN(planOptionId)) {
      goBack();
    }

    const {
      data: getOptionData,
      loading: loadingOption,
      refetch: refetchPlanOption,
      error,
    } = useEditPlanOptionModalGetPlanOptionQuery({
      variables: { planOptionId },
    });
    const planOption = getOptionData?.planOption?.[0];
    const translations = useMemo(() => planOption?.translations ?? [], [planOption?.translations]);
    const uuid = planOption?.id;
    const companyId = planOption?.plan.company.id;

    const [updateSelectPlanOptionMutation, { loading: loadingUpdateSelectPlanOption }] =
      useEditPlanOptionModalUpdateSelectPlanOptionMutation();
    const [updateCountPlanOptionMutation, { loading: loadingUpdateCountPlanOption }] =
      useEditPlanOptionModalUpdateCountPlanOptionMutation();

    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<EditPlanOptionFormValues | null>(null);
    const onOk = useCallback(async () => {
      if (planOptionId && formValues && uuid && companyId) {
        try {
          await form.validateFields();

          const {
            description,
            inputType,
            isMainOption,
            maxChoiceNum,
            minChoiceNum,
            name,
            receiptDisplayName,
            nameEn,
            nameKr,
            nameCn,
          } = formValues;

          const nameSources = [
            {
              value: nameEn ?? "",
              locale: SupportedLocaleEnumType.EnUs,
            },
            {
              value: nameKr ?? "",
              locale: SupportedLocaleEnumType.KoKr,
            },
            {
              value: nameCn ?? "",
              locale: SupportedLocaleEnumType.ZhCn,
            },
          ];

          const variables = {
            planOptionId,
            planOption: {
              description,
              inputType,
              isMainOption,
              maxChoiceNum,
              minChoiceNum,
              name,
              receiptDisplayName,
            },
            translations: {
              companyId,
              planOptionId: uuid,
              nameSources,
            },
          };

          if (inputType === OptionInputTypeEnum.Select) {
            await updateSelectPlanOptionMutation({
              variables,
            });
          }

          if (inputType === OptionInputTypeEnum.Count) {
            await updateCountPlanOptionMutation({
              variables,
            });
          }

          await refetchPlanOption();

          onUpdated();

          message.success("編集を保存しました");
        } catch (err) {
          return message.error("編集の保存に失敗しました");
        }
      }

      goBack();
    }, [
      planOptionId,
      formValues,
      uuid,
      companyId,
      goBack,
      form,
      onUpdated,
      updateSelectPlanOptionMutation,
      updateCountPlanOptionMutation,
      refetchPlanOption,
    ]);

    const onCancel = goBack;

    return (
      <Modal
        title={planOption?.name}
        open
        width={900}
        onOk={goBack}
        onCancel={goBack}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>,
          canEditPlanOption && (
            <Button
              key="save"
              type="primary"
              onClick={onOk}
              disabled={loadingOption}
              loading={loadingUpdateSelectPlanOption || loadingUpdateCountPlanOption}
            >
              更新
            </Button>
          ),
        ]}
      >
        {loadingOption && <Loading fullWidth height={300} />}
        {error && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}
        {planOption && (
          <EditPlanOptionModalForm
            form={form}
            planOption={planOption}
            translations={translations}
            onChange={setFormValues}
            disabled={!canEditPlanChoice}
          />
        )}
      </Modal>
    );
  },
);
