import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditWinboardMenuGetMenu = gql`
    query EditWinboardMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const EditWinboardMenuUpdateWinboardMenu = gql`
    mutation EditWinboardMenuUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, menuId, _menuId]}
  ) {
    id
  }
}
    `;
export type EditWinboardMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditWinboardMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type EditWinboardMenuUpdateWinboardMenuMutationVariables = Types.Exact<{
  winboardMenu: Types.WinboardMenuInsertInput;
}>;


export type EditWinboardMenuUpdateWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_winboardMenu_one?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const EditWinboardMenuGetMenuDocument = gql`
    query EditWinboardMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    winboardMenus(where: {_choiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useEditWinboardMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditWinboardMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditWinboardMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditWinboardMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditWinboardMenuGetMenuQuery, EditWinboardMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditWinboardMenuGetMenuQuery, EditWinboardMenuGetMenuQueryVariables>(EditWinboardMenuGetMenuDocument, options);
      }
export function useEditWinboardMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditWinboardMenuGetMenuQuery, EditWinboardMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditWinboardMenuGetMenuQuery, EditWinboardMenuGetMenuQueryVariables>(EditWinboardMenuGetMenuDocument, options);
        }
export type EditWinboardMenuGetMenuQueryHookResult = ReturnType<typeof useEditWinboardMenuGetMenuQuery>;
export type EditWinboardMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditWinboardMenuGetMenuLazyQuery>;
export type EditWinboardMenuGetMenuQueryResult = Apollo.QueryResult<EditWinboardMenuGetMenuQuery, EditWinboardMenuGetMenuQueryVariables>;
export const EditWinboardMenuUpdateWinboardMenuDocument = gql`
    mutation EditWinboardMenuUpdateWinboardMenu($winboardMenu: winboardMenu_insert_input!) {
  insert_winboardMenu_one(
    object: $winboardMenu
    on_conflict: {constraint: winboardMenu_pkey, update_columns: [name, code, bumonCode, bumonName, categoryCode, categoryName, menuId, _menuId]}
  ) {
    id
  }
}
    `;
export type EditWinboardMenuUpdateWinboardMenuMutationFn = Apollo.MutationFunction<EditWinboardMenuUpdateWinboardMenuMutation, EditWinboardMenuUpdateWinboardMenuMutationVariables>;

/**
 * __useEditWinboardMenuUpdateWinboardMenuMutation__
 *
 * To run a mutation, you first call `useEditWinboardMenuUpdateWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWinboardMenuUpdateWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWinboardMenuUpdateWinboardMenuMutation, { data, loading, error }] = useEditWinboardMenuUpdateWinboardMenuMutation({
 *   variables: {
 *      winboardMenu: // value for 'winboardMenu'
 *   },
 * });
 */
export function useEditWinboardMenuUpdateWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditWinboardMenuUpdateWinboardMenuMutation, EditWinboardMenuUpdateWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWinboardMenuUpdateWinboardMenuMutation, EditWinboardMenuUpdateWinboardMenuMutationVariables>(EditWinboardMenuUpdateWinboardMenuDocument, options);
      }
export type EditWinboardMenuUpdateWinboardMenuMutationHookResult = ReturnType<typeof useEditWinboardMenuUpdateWinboardMenuMutation>;
export type EditWinboardMenuUpdateWinboardMenuMutationResult = Apollo.MutationResult<EditWinboardMenuUpdateWinboardMenuMutation>;
export type EditWinboardMenuUpdateWinboardMenuMutationOptions = Apollo.BaseMutationOptions<EditWinboardMenuUpdateWinboardMenuMutation, EditWinboardMenuUpdateWinboardMenuMutationVariables>;