import { useCallback, useMemo } from "react";
import { groupBy } from "util/array";

import { createFormItem, Form } from "components/antd/Form";
import {
  SupportedLocaleEnum,
  SupportedLocaleEnumType,
  UpsertTranslationsForOptionInput,
} from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

import { ChoiceTranslation, Option, OptionTranslation } from "../types";

export type EditOptionTranslationsFormValues = Partial<{
  optionName: string;
  optionNameEn: string;
  optionNameKr: string;
  optionNameCn: string;
  choiceSources?: {
    choiceId: string;
    choiceName: string;
    choiceNameEn: string;
    choiceNameKr: string;
    choiceNameCn: string;
  }[];
}>;

const getChoiceLocaleToNameTranslationMap = (
  choiceTranslations: ChoiceTranslation[],
): Map<string, Map<SupportedLocaleEnum, ChoiceTranslation>> =>
  new Map(
    Object.entries(groupBy(choiceTranslations, ({ recordId }) => recordId)).map(
      ([recordId, translations]) => [
        recordId,
        new Map(translations.map((translation) => [translation.locale, translation])),
      ],
    ),
  );

const getInitialValues = ({
  option,
  optionLocaleToNameTranslationMap,
  choiceLocaleToNameTranslationMaps,
}: {
  option: Option;
  optionLocaleToNameTranslationMap: Map<SupportedLocaleEnum, OptionTranslation>;
  choiceLocaleToNameTranslationMaps: Map<string, Map<SupportedLocaleEnum, ChoiceTranslation>>;
}): EditOptionTranslationsFormValues => ({
  optionName: option.name,
  optionNameEn: optionLocaleToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value ?? "",
  optionNameKr: optionLocaleToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value ?? "",
  optionNameCn: optionLocaleToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value ?? "",
  choiceSources: option.choices.map((choice) => {
    const localeToTranslationMap = choiceLocaleToNameTranslationMaps.get(choice.id);
    return {
      choiceId: choice.id,
      choiceName: choice.name,
      choiceNameEn: localeToTranslationMap?.get(SupportedLocaleEnum.EnUs)?.value ?? "",
      choiceNameKr: localeToTranslationMap?.get(SupportedLocaleEnum.KoKr)?.value ?? "",
      choiceNameCn: localeToTranslationMap?.get(SupportedLocaleEnum.ZhCn)?.value ?? "",
    };
  }),
});

export const EditOptionTranslationsFormItem = createFormItem<EditOptionTranslationsFormValues>();

export const useEditOptionTranslationsForm = ({
  option,
  optionTranslations,
  choiceTranslations,
  onSubmit,
}: {
  option: Option;
  optionTranslations: OptionTranslation[];
  choiceTranslations: ChoiceTranslation[];
  onSubmit: (inputs: UpsertTranslationsForOptionInput) => void;
}) => {
  const optionLocaleToNameTranslationMap = useMemo(
    () =>
      new Map(
        optionTranslations
          .filter(
            (optionTranslation) => optionTranslation.columnName === TranslationColumnNameEnum.Name,
          )
          .map((optionTranslation) => [optionTranslation.locale, optionTranslation]),
      ),
    [optionTranslations],
  );

  const choiceLocaleToNameTranslationMaps = useMemo(
    () =>
      getChoiceLocaleToNameTranslationMap(
        choiceTranslations.filter(
          (choiceTranslation) => choiceTranslation.columnName === TranslationColumnNameEnum.Name,
        ),
      ),
    [choiceTranslations],
  );

  const [form] = Form.useForm<EditOptionTranslationsFormValues>();

  const initialValues = useMemo(
    () =>
      getInitialValues({
        option,
        optionLocaleToNameTranslationMap,
        choiceLocaleToNameTranslationMaps,
      }),
    [option, optionLocaleToNameTranslationMap, choiceLocaleToNameTranslationMaps],
  );

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    const { optionNameEn, optionNameKr, optionNameCn, choiceSources } = formValues;

    const optionInputs = [
      {
        value: optionNameEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
      },
      {
        value: optionNameKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
      },
      {
        value: optionNameCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
      },
    ];

    const choiceInputs = choiceSources?.map(
      ({ choiceId, choiceNameEn, choiceNameKr, choiceNameCn }) => ({
        choiceId,
        nameSources: [
          {
            value: choiceNameEn ?? "",
            locale: SupportedLocaleEnumType.EnUs,
          },
          {
            value: choiceNameKr ?? "",
            locale: SupportedLocaleEnumType.KoKr,
          },
          {
            value: choiceNameCn ?? "",
            locale: SupportedLocaleEnumType.ZhCn,
          },
        ],
      }),
    );

    option &&
      onSubmit({
        optionId: option.id,
        companyId: option.company.id,
        nameSources: optionInputs,
        choiceSources: choiceInputs,
      });
  }, [form, onSubmit, option]);

  return { form, initialValues, submit };
};
