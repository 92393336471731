import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { BulkUpdateIsDisplayByShopTable } from "components/BulkUpdateIsDisplayByShopTable";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditOnSitePaymentDiscountTypeForm } from "pages/EditOnSitePaymentDiscountType/EditOnSitePaymentDiscountTypeForm";
import {
  useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation,
  useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery,
  useEditOnSitePaymentDiscountTypeGetShopsQuery,
  useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation,
  useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation,
} from "pages/EditOnSitePaymentDiscountType/queries";
import { OnSitePaymentDiscountTypeSetInput } from "types/graphql";

export const EditOnSitePaymentDiscountType = () => {
  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: getOnSitePaymentDiscountTypeData,
    loading: loadingOnSitePaymentDiscountType,
    refetch: refetchOnSitePaymentDiscountType,
    error,
  } = useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery(
    id ? { variables: { id } } : { skip: true },
  );
  const onSitePaymentDiscountType = getOnSitePaymentDiscountTypeData?.onSitePaymentDiscountType[0];

  const {
    data: getShopsData,
    loading: loadingShops,
    refetch: refetchShops,
  } = useEditOnSitePaymentDiscountTypeGetShopsQuery(
    id && companyId ? { variables: { id, companyId } } : { skip: true },
  );

  const shops = getShopsData?.shop ?? [];

  const [
    updateOnSitePaymentDiscountTypeMutation,
    { loading: loadingUpdateOnSitePaymentDiscountType },
  ] = useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation();

  const onSubmit = useCallback(
    async (onSitePaymentDiscountType: OnSitePaymentDiscountTypeSetInput) => {
      try {
        await updateOnSitePaymentDiscountTypeMutation({
          variables: { onSitePaymentDiscountTypeId: id, onSitePaymentDiscountType },
        });
        await refetchOnSitePaymentDiscountType();
        message.success("編集を保存しました");

        navigate("/onSitePaymentDiscountType", { replace: true });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [updateOnSitePaymentDiscountTypeMutation, id, navigate, refetchOnSitePaymentDiscountType],
  );

  const [updateShopDiscountTypeMutation] =
    useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation();

  const [bulkUpdateShopDiscountTypesMutation] =
    useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation();

  const onUpdateIsDisplay = useCallback(
    async (shopId: string, isDisplay: boolean) => {
      if (!companyId) return;
      if (!onSitePaymentDiscountType) return;

      try {
        await updateShopDiscountTypeMutation({
          variables: {
            shopDiscountType: {
              shopId,
              type: onSitePaymentDiscountType.type,
              onSitePaymentDiscountTypeId: onSitePaymentDiscountType.id,
              companyId,
              isDisplay,
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [companyId, onSitePaymentDiscountType, updateShopDiscountTypeMutation, refetchShops],
  );

  const onBulkUpdateIsDisplay = useCallback(
    async ({ shopIds, isDisplay }: { shopIds: string[]; isDisplay: boolean }) => {
      if (!companyId || !_companyId || !onSitePaymentDiscountType) return;

      try {
        await bulkUpdateShopDiscountTypesMutation({
          variables: {
            shopDiscountTypes: shopIds.map((shopId) => ({
              shopId,
              type: onSitePaymentDiscountType.type,
              onSitePaymentDiscountTypeId: onSitePaymentDiscountType.id,
              companyId,
              _companyId,
              isDisplay,
            })),
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [
      companyId,
      _companyId,
      onSitePaymentDiscountType,
      bulkUpdateShopDiscountTypesMutation,
      refetchShops,
    ],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={onSitePaymentDiscountType?.label}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "割引方法" }],
      }}
    >
      <PageHeader title={onSitePaymentDiscountType?.label} onBack={goBack} />
      {loadingOnSitePaymentDiscountType && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {onSitePaymentDiscountType && (
        <>
          <EditOnSitePaymentDiscountTypeForm
            onSitePaymentDiscountType={onSitePaymentDiscountType}
            onSubmit={onSubmit}
            onClose={goBack}
            loading={loadingUpdateOnSitePaymentDiscountType}
          />
          <Spacer height={32} />
          <FormContent>
            <SubTitle>店舗ごとの表示設定</SubTitle>
          </FormContent>
          <BulkUpdateIsDisplayByShopTable
            loading={loadingShops}
            shops={shops}
            onUpdateIsDisplay={onUpdateIsDisplay}
            onBulkUpdateIsDisplay={onBulkUpdateIsDisplay}
            updateTarget="shopDiscountTypes"
          />
        </>
      )}
    </DashboardLayout>
  );
};
