import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { Category } from "pages/EditCategoryPriorities/types";
import { CategoryInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  categories: Category[];
  onUpdate: (categories: CategoryInsertInput[]) => void;
};

export const EditCategoryPriorityList = memo<Props>(({ loading, categories, onUpdate }) => {
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex !== hoverIndex) {
      onUpdate(
        updatePriorities(categories, dragIndex, hoverIndex).map((category) => {
          const { categoryId, companyId, ...rest } = category;

          return {
            ...rest,
            serial: categoryId,
            companyId,
          };
        }),
      );
    }
  };

  return (
    <PriorityList<Category>
      itemLayout="horizontal"
      loading={loading}
      dataSource={categories}
      renderItem={({ categoryId, name, shopSideName, priority }, index) => (
        <PriorityListItem
          id={categoryId}
          name={shopSideName || name}
          priority={priority}
          index={index}
          onMove={handleMoveItem}
        />
      )}
    />
  );
});
