import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopPlansGetPlans = gql`
    query ShopPlansGetPlans($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, plan: {archivedAt: {_is_null: true}}}
      order_by: {plan: {planName: asc}}
    ) {
      shopId
      planId
      _planId
      plan {
        planId: id
        _planId: planId
        planName
        planOptions(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
          planOptionId
          name
          planChoices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
            planChoiceId: id
            _planChoiceId: planChoiceId
            name
            shopPlanChoices(where: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}}) {
              shopId
              planChoiceId
              _planChoiceId
              isVisibleForCustomer
              isVisibleForStaff
              stock {
                id
                currentStockNum
              }
            }
          }
        }
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopPlanKitchenRoles(where: {shopPlan: {archivedAt: {_is_null: true}}}) {
        role {
          name
          roleId
        }
      }
      dishUpSlipGroupShopPlans(where: {shopPlan: {archivedAt: {_is_null: true}}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
    }
  }
}
    `;
export const ShopPlansGetRoles = gql`
    query ShopPlansGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      roleId
      name
    }
  }
}
    `;
export const ShopPlansGetDishUpSlipGroups = gql`
    query ShopPlansGetDishUpSlipGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    dishUpSlipGroups {
      id: serial
      name
    }
  }
}
    `;
export const ShopPlansUpdateShopPlanVisibleForCustomer = gql`
    mutation ShopPlansUpdateShopPlanVisibleForCustomer($shopId: uuid!, $planId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export const ShopPlansUpdateShopPlanVisibleForStaff = gql`
    mutation ShopPlansUpdateShopPlanVisibleForStaff($shopId: uuid!, $planId: uuid!, $isVisibleForStaff: Boolean!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForStaff: $isVisibleForStaff}
  ) {
    affected_rows
  }
}
    `;
export const ShopPlansUpdateShopPlanStock = gql`
    mutation ShopPlansUpdateShopPlanStock($shopId: uuid!, $planId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum]}
  ) {
    id
  }
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export const ShopPlansUpsertShopPlanChoice = gql`
    mutation ShopPlansUpsertShopPlanChoice($input: UpsertShopPlanChoiceInput!) {
  upsertShopPlanChoice(input: $input) {
    result
  }
}
    `;
export type ShopPlansGetPlansQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'shopId' | 'planId' | '_planId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'planName'>
        & { planId: Types.Plan['id'], _planId: Types.Plan['planId'] }
        & { planOptions: Array<(
          { __typename?: 'planOption' }
          & Pick<Types.PlanOption, 'planOptionId' | 'name'>
          & { planChoices: Array<(
            { __typename?: 'planChoice' }
            & Pick<Types.PlanChoice, 'name'>
            & { planChoiceId: Types.PlanChoice['id'], _planChoiceId: Types.PlanChoice['planChoiceId'] }
            & { shopPlanChoices: Array<(
              { __typename?: 'shopPlanChoice' }
              & Pick<Types.ShopPlanChoice, 'shopId' | 'planChoiceId' | '_planChoiceId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
              & { stock?: Types.Maybe<(
                { __typename?: 'stock' }
                & Pick<Types.Stock, 'id' | 'currentStockNum'>
              )> }
            )> }
          )> }
        )> }
      ), stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum' | 'dailyStockNum'>
      )>, shopPlanKitchenRoles: Array<(
        { __typename?: 'shopPlanKitchenRole' }
        & { role: (
          { __typename?: 'role' }
          & Pick<Types.Role, 'name' | 'roleId'>
        ) }
      )>, dishUpSlipGroupShopPlans: Array<(
        { __typename?: 'dishUpSlipGroupShopPlans' }
        & { dishUpSlipGroup?: Types.Maybe<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      )> }
    )> }
  )> }
);

export type ShopPlansGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopPlansGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'roleId' | 'name'>
    )> }
  )> }
);

export type ShopPlansGetDishUpSlipGroupsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopPlansGetDishUpSlipGroupsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { dishUpSlipGroups: Array<(
      { __typename?: 'dishUpSlipGroup' }
      & Pick<Types.DishUpSlipGroup, 'name'>
      & { id: Types.DishUpSlipGroup['serial'] }
    )> }
  )> }
);

export type ShopPlansUpdateShopPlanVisibleForCustomerMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  planId: Types.Scalars['uuid'];
  isVisibleForCustomer: Types.Scalars['Boolean'];
}>;


export type ShopPlansUpdateShopPlanVisibleForCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopPlan?: Types.Maybe<(
    { __typename?: 'shopPlan_mutation_response' }
    & Pick<Types.ShopPlanMutationResponse, 'affected_rows'>
  )> }
);

export type ShopPlansUpdateShopPlanVisibleForStaffMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  planId: Types.Scalars['uuid'];
  isVisibleForStaff: Types.Scalars['Boolean'];
}>;


export type ShopPlansUpdateShopPlanVisibleForStaffMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopPlan?: Types.Maybe<(
    { __typename?: 'shopPlan_mutation_response' }
    & Pick<Types.ShopPlanMutationResponse, 'affected_rows'>
  )> }
);

export type ShopPlansUpdateShopPlanStockMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  planId: Types.Scalars['uuid'];
  stockId: Types.Scalars['uuid'];
  stock: Types.StockInsertInput;
}>;


export type ShopPlansUpdateShopPlanStockMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stock_one?: Types.Maybe<(
    { __typename?: 'stock' }
    & Pick<Types.Stock, 'id'>
  )>, update_shopPlan?: Types.Maybe<(
    { __typename?: 'shopPlan_mutation_response' }
    & Pick<Types.ShopPlanMutationResponse, 'affected_rows'>
  )> }
);

export type ShopPlansUpsertShopPlanChoiceMutationVariables = Types.Exact<{
  input: Types.UpsertShopPlanChoiceInput;
}>;


export type ShopPlansUpsertShopPlanChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { upsertShopPlanChoice: (
    { __typename?: 'UpsertShopPlanChoiceResult' }
    & Pick<Types.UpsertShopPlanChoiceResult, 'result'>
  ) }
);


export const ShopPlansGetPlansDocument = gql`
    query ShopPlansGetPlans($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, plan: {archivedAt: {_is_null: true}}}
      order_by: {plan: {planName: asc}}
    ) {
      shopId
      planId
      _planId
      plan {
        planId: id
        _planId: planId
        planName
        planOptions(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
          planOptionId
          name
          planChoices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
            planChoiceId: id
            _planChoiceId: planChoiceId
            name
            shopPlanChoices(where: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}}) {
              shopId
              planChoiceId
              _planChoiceId
              isVisibleForCustomer
              isVisibleForStaff
              stock {
                id
                currentStockNum
              }
            }
          }
        }
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopPlanKitchenRoles(where: {shopPlan: {archivedAt: {_is_null: true}}}) {
        role {
          name
          roleId
        }
      }
      dishUpSlipGroupShopPlans(where: {shopPlan: {archivedAt: {_is_null: true}}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
    }
  }
}
    `;

/**
 * __useShopPlansGetPlansQuery__
 *
 * To run a query within a React component, call `useShopPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPlansGetPlansQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<ShopPlansGetPlansQuery, ShopPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopPlansGetPlansQuery, ShopPlansGetPlansQueryVariables>(ShopPlansGetPlansDocument, options);
      }
export function useShopPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopPlansGetPlansQuery, ShopPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopPlansGetPlansQuery, ShopPlansGetPlansQueryVariables>(ShopPlansGetPlansDocument, options);
        }
export type ShopPlansGetPlansQueryHookResult = ReturnType<typeof useShopPlansGetPlansQuery>;
export type ShopPlansGetPlansLazyQueryHookResult = ReturnType<typeof useShopPlansGetPlansLazyQuery>;
export type ShopPlansGetPlansQueryResult = Apollo.QueryResult<ShopPlansGetPlansQuery, ShopPlansGetPlansQueryVariables>;
export const ShopPlansGetRolesDocument = gql`
    query ShopPlansGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      roleId
      name
    }
  }
}
    `;

/**
 * __useShopPlansGetRolesQuery__
 *
 * To run a query within a React component, call `useShopPlansGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPlansGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPlansGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopPlansGetRolesQuery(baseOptions: Apollo.QueryHookOptions<ShopPlansGetRolesQuery, ShopPlansGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopPlansGetRolesQuery, ShopPlansGetRolesQueryVariables>(ShopPlansGetRolesDocument, options);
      }
export function useShopPlansGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopPlansGetRolesQuery, ShopPlansGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopPlansGetRolesQuery, ShopPlansGetRolesQueryVariables>(ShopPlansGetRolesDocument, options);
        }
export type ShopPlansGetRolesQueryHookResult = ReturnType<typeof useShopPlansGetRolesQuery>;
export type ShopPlansGetRolesLazyQueryHookResult = ReturnType<typeof useShopPlansGetRolesLazyQuery>;
export type ShopPlansGetRolesQueryResult = Apollo.QueryResult<ShopPlansGetRolesQuery, ShopPlansGetRolesQueryVariables>;
export const ShopPlansGetDishUpSlipGroupsDocument = gql`
    query ShopPlansGetDishUpSlipGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    dishUpSlipGroups {
      id: serial
      name
    }
  }
}
    `;

/**
 * __useShopPlansGetDishUpSlipGroupsQuery__
 *
 * To run a query within a React component, call `useShopPlansGetDishUpSlipGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopPlansGetDishUpSlipGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopPlansGetDishUpSlipGroupsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopPlansGetDishUpSlipGroupsQuery(baseOptions: Apollo.QueryHookOptions<ShopPlansGetDishUpSlipGroupsQuery, ShopPlansGetDishUpSlipGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopPlansGetDishUpSlipGroupsQuery, ShopPlansGetDishUpSlipGroupsQueryVariables>(ShopPlansGetDishUpSlipGroupsDocument, options);
      }
export function useShopPlansGetDishUpSlipGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopPlansGetDishUpSlipGroupsQuery, ShopPlansGetDishUpSlipGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopPlansGetDishUpSlipGroupsQuery, ShopPlansGetDishUpSlipGroupsQueryVariables>(ShopPlansGetDishUpSlipGroupsDocument, options);
        }
export type ShopPlansGetDishUpSlipGroupsQueryHookResult = ReturnType<typeof useShopPlansGetDishUpSlipGroupsQuery>;
export type ShopPlansGetDishUpSlipGroupsLazyQueryHookResult = ReturnType<typeof useShopPlansGetDishUpSlipGroupsLazyQuery>;
export type ShopPlansGetDishUpSlipGroupsQueryResult = Apollo.QueryResult<ShopPlansGetDishUpSlipGroupsQuery, ShopPlansGetDishUpSlipGroupsQueryVariables>;
export const ShopPlansUpdateShopPlanVisibleForCustomerDocument = gql`
    mutation ShopPlansUpdateShopPlanVisibleForCustomer($shopId: uuid!, $planId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export type ShopPlansUpdateShopPlanVisibleForCustomerMutationFn = Apollo.MutationFunction<ShopPlansUpdateShopPlanVisibleForCustomerMutation, ShopPlansUpdateShopPlanVisibleForCustomerMutationVariables>;

/**
 * __useShopPlansUpdateShopPlanVisibleForCustomerMutation__
 *
 * To run a mutation, you first call `useShopPlansUpdateShopPlanVisibleForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopPlansUpdateShopPlanVisibleForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopPlansUpdateShopPlanVisibleForCustomerMutation, { data, loading, error }] = useShopPlansUpdateShopPlanVisibleForCustomerMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      planId: // value for 'planId'
 *      isVisibleForCustomer: // value for 'isVisibleForCustomer'
 *   },
 * });
 */
export function useShopPlansUpdateShopPlanVisibleForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ShopPlansUpdateShopPlanVisibleForCustomerMutation, ShopPlansUpdateShopPlanVisibleForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopPlansUpdateShopPlanVisibleForCustomerMutation, ShopPlansUpdateShopPlanVisibleForCustomerMutationVariables>(ShopPlansUpdateShopPlanVisibleForCustomerDocument, options);
      }
export type ShopPlansUpdateShopPlanVisibleForCustomerMutationHookResult = ReturnType<typeof useShopPlansUpdateShopPlanVisibleForCustomerMutation>;
export type ShopPlansUpdateShopPlanVisibleForCustomerMutationResult = Apollo.MutationResult<ShopPlansUpdateShopPlanVisibleForCustomerMutation>;
export type ShopPlansUpdateShopPlanVisibleForCustomerMutationOptions = Apollo.BaseMutationOptions<ShopPlansUpdateShopPlanVisibleForCustomerMutation, ShopPlansUpdateShopPlanVisibleForCustomerMutationVariables>;
export const ShopPlansUpdateShopPlanVisibleForStaffDocument = gql`
    mutation ShopPlansUpdateShopPlanVisibleForStaff($shopId: uuid!, $planId: uuid!, $isVisibleForStaff: Boolean!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForStaff: $isVisibleForStaff}
  ) {
    affected_rows
  }
}
    `;
export type ShopPlansUpdateShopPlanVisibleForStaffMutationFn = Apollo.MutationFunction<ShopPlansUpdateShopPlanVisibleForStaffMutation, ShopPlansUpdateShopPlanVisibleForStaffMutationVariables>;

/**
 * __useShopPlansUpdateShopPlanVisibleForStaffMutation__
 *
 * To run a mutation, you first call `useShopPlansUpdateShopPlanVisibleForStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopPlansUpdateShopPlanVisibleForStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopPlansUpdateShopPlanVisibleForStaffMutation, { data, loading, error }] = useShopPlansUpdateShopPlanVisibleForStaffMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      planId: // value for 'planId'
 *      isVisibleForStaff: // value for 'isVisibleForStaff'
 *   },
 * });
 */
export function useShopPlansUpdateShopPlanVisibleForStaffMutation(baseOptions?: Apollo.MutationHookOptions<ShopPlansUpdateShopPlanVisibleForStaffMutation, ShopPlansUpdateShopPlanVisibleForStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopPlansUpdateShopPlanVisibleForStaffMutation, ShopPlansUpdateShopPlanVisibleForStaffMutationVariables>(ShopPlansUpdateShopPlanVisibleForStaffDocument, options);
      }
export type ShopPlansUpdateShopPlanVisibleForStaffMutationHookResult = ReturnType<typeof useShopPlansUpdateShopPlanVisibleForStaffMutation>;
export type ShopPlansUpdateShopPlanVisibleForStaffMutationResult = Apollo.MutationResult<ShopPlansUpdateShopPlanVisibleForStaffMutation>;
export type ShopPlansUpdateShopPlanVisibleForStaffMutationOptions = Apollo.BaseMutationOptions<ShopPlansUpdateShopPlanVisibleForStaffMutation, ShopPlansUpdateShopPlanVisibleForStaffMutationVariables>;
export const ShopPlansUpdateShopPlanStockDocument = gql`
    mutation ShopPlansUpdateShopPlanStock($shopId: uuid!, $planId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum]}
  ) {
    id
  }
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export type ShopPlansUpdateShopPlanStockMutationFn = Apollo.MutationFunction<ShopPlansUpdateShopPlanStockMutation, ShopPlansUpdateShopPlanStockMutationVariables>;

/**
 * __useShopPlansUpdateShopPlanStockMutation__
 *
 * To run a mutation, you first call `useShopPlansUpdateShopPlanStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopPlansUpdateShopPlanStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopPlansUpdateShopPlanStockMutation, { data, loading, error }] = useShopPlansUpdateShopPlanStockMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      planId: // value for 'planId'
 *      stockId: // value for 'stockId'
 *      stock: // value for 'stock'
 *   },
 * });
 */
export function useShopPlansUpdateShopPlanStockMutation(baseOptions?: Apollo.MutationHookOptions<ShopPlansUpdateShopPlanStockMutation, ShopPlansUpdateShopPlanStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopPlansUpdateShopPlanStockMutation, ShopPlansUpdateShopPlanStockMutationVariables>(ShopPlansUpdateShopPlanStockDocument, options);
      }
export type ShopPlansUpdateShopPlanStockMutationHookResult = ReturnType<typeof useShopPlansUpdateShopPlanStockMutation>;
export type ShopPlansUpdateShopPlanStockMutationResult = Apollo.MutationResult<ShopPlansUpdateShopPlanStockMutation>;
export type ShopPlansUpdateShopPlanStockMutationOptions = Apollo.BaseMutationOptions<ShopPlansUpdateShopPlanStockMutation, ShopPlansUpdateShopPlanStockMutationVariables>;
export const ShopPlansUpsertShopPlanChoiceDocument = gql`
    mutation ShopPlansUpsertShopPlanChoice($input: UpsertShopPlanChoiceInput!) {
  upsertShopPlanChoice(input: $input) {
    result
  }
}
    `;
export type ShopPlansUpsertShopPlanChoiceMutationFn = Apollo.MutationFunction<ShopPlansUpsertShopPlanChoiceMutation, ShopPlansUpsertShopPlanChoiceMutationVariables>;

/**
 * __useShopPlansUpsertShopPlanChoiceMutation__
 *
 * To run a mutation, you first call `useShopPlansUpsertShopPlanChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopPlansUpsertShopPlanChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopPlansUpsertShopPlanChoiceMutation, { data, loading, error }] = useShopPlansUpsertShopPlanChoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopPlansUpsertShopPlanChoiceMutation(baseOptions?: Apollo.MutationHookOptions<ShopPlansUpsertShopPlanChoiceMutation, ShopPlansUpsertShopPlanChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopPlansUpsertShopPlanChoiceMutation, ShopPlansUpsertShopPlanChoiceMutationVariables>(ShopPlansUpsertShopPlanChoiceDocument, options);
      }
export type ShopPlansUpsertShopPlanChoiceMutationHookResult = ReturnType<typeof useShopPlansUpsertShopPlanChoiceMutation>;
export type ShopPlansUpsertShopPlanChoiceMutationResult = Apollo.MutationResult<ShopPlansUpsertShopPlanChoiceMutation>;
export type ShopPlansUpsertShopPlanChoiceMutationOptions = Apollo.BaseMutationOptions<ShopPlansUpsertShopPlanChoiceMutation, ShopPlansUpsertShopPlanChoiceMutationVariables>;