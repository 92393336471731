import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { ChoiceInsertInput, DashboardAccountRoleTypeEnum, OptionSetInput } from "types/graphql";

import { EditOptionForm } from "./EditOptionForm";
import {
  useEditOptionGetOptionQuery,
  useEditOptionUpdateOptionAndInsertChoicesMutation,
} from "./queries";

export const EditOption = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);

  const {
    data: optionData,
    loading: loadingOption,
    refetch: refetchOption,
  } = useEditOptionGetOptionQuery({
    variables: { optionId },
  });

  const option = optionData?.option?.[0];

  const [updateOptionMutation, { loading: loadingUpdateOption }] =
    useEditOptionUpdateOptionAndInsertChoicesMutation();

  const goBack = useCallback(() => navigate("/option"), [navigate]);

  const onSubmit = useCallback(
    async (
      option: OptionSetInput,
      choices: ChoiceInsertInput[],
      choiceIdsForDelete: number[],
      shopChoiceIdsForDelete: string[],
    ) => {
      try {
        await updateOptionMutation({
          variables: {
            optionId,
            option,
            choices,
            choiceIdsForDelete,
            shopChoiceIdsForDelete,
            archivedAt: new Date().toISOString(),
          },
        });

        await refetchOption();
        message.success("更新しました");
        goBack();
      } catch (err) {
        message.error("更新に失敗しました");
      }
    },
    [updateOptionMutation, optionId, goBack, refetchOption],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "オプション" }],
      }}
    >
      <OptionHeader
        option={option ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      <EditOptionForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        onClose={goBack}
        loading={loadingOption || loadingUpdateOption}
        option={option}
        disabled={!isFeatureEnabled("editOption")}
      />
    </DashboardLayout>
  );
};
