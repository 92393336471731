import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = { name: string | number } & Omit<FormItemProps, "children" | "name">;

export const TextField = memo<Props>(({ name, ...props }) => (
  <AddMessageDeliveryFormItem
    name={[name, "text"]}
    rules={[{ required: true, message: "メッセージの入力は必須です" }]}
    {...props}
  >
    <TextArea placeholder="メッセージを入力" rows={6} showCount maxLength={500} />
  </AddMessageDeliveryFormItem>
));
