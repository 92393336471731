import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CouponsGetCoupons = gql`
    query CouponsGetCoupons($companyId: uuid!) {
  coupon(order_by: {createdAt: desc}, where: {companyId: {_eq: $companyId}}) {
    id
    name
    title
    content
    availableFrom
    availableUntil
    availableDays
    imageUrl
  }
}
    `;
export type CouponsGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CouponsGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name' | 'title' | 'content' | 'availableFrom' | 'availableUntil' | 'availableDays' | 'imageUrl'>
  )> }
);


export const CouponsGetCouponsDocument = gql`
    query CouponsGetCoupons($companyId: uuid!) {
  coupon(order_by: {createdAt: desc}, where: {companyId: {_eq: $companyId}}) {
    id
    name
    title
    content
    availableFrom
    availableUntil
    availableDays
    imageUrl
  }
}
    `;

/**
 * __useCouponsGetCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCouponsGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<CouponsGetCouponsQuery, CouponsGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsGetCouponsQuery, CouponsGetCouponsQueryVariables>(CouponsGetCouponsDocument, options);
      }
export function useCouponsGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsGetCouponsQuery, CouponsGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsGetCouponsQuery, CouponsGetCouponsQueryVariables>(CouponsGetCouponsDocument, options);
        }
export type CouponsGetCouponsQueryHookResult = ReturnType<typeof useCouponsGetCouponsQuery>;
export type CouponsGetCouponsLazyQueryHookResult = ReturnType<typeof useCouponsGetCouponsLazyQuery>;
export type CouponsGetCouponsQueryResult = Apollo.QueryResult<CouponsGetCouponsQuery, CouponsGetCouponsQueryVariables>;