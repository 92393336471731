import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopExternalOnlineMenuGetExternalOnlineMenuAndShop = gql`
    query EditShopExternalOnlineMenuGetExternalOnlineMenuAndShop($shopId: uuid!, $externalOnlineMenuId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenu_by_pk(id: $externalOnlineMenuId) {
    id
    name
    url
    externalOnlineMenuAvailableTimeTerms {
      id
      dayWeek
      start
      end
    }
  }
}
    `;
export const EditShopExternalOnlineMenuUpdateExternalOnlineMenu = gql`
    mutation EditShopExternalOnlineMenuUpdateExternalOnlineMenu($externalOnlineMenuId: uuid!, $externalOnlineMenu: externalOnlineMenu_set_input!, $externalOnlineMenuAvailableTimeTermIds: [uuid!]!, $externalOnlineMenuAvailableTimeTerms: [externalOnlineMenuAvailableTimeTerm_insert_input!]!) {
  update_externalOnlineMenu_by_pk(
    pk_columns: {id: $externalOnlineMenuId}
    _set: $externalOnlineMenu
  ) {
    __typename
  }
  delete_externalOnlineMenuAvailableTimeTerm(
    where: {id: {_nin: $externalOnlineMenuAvailableTimeTermIds}, externalOnlineMenuId: {_eq: $externalOnlineMenuId}}
  ) {
    affected_rows
  }
  insert_externalOnlineMenuAvailableTimeTerm(
    objects: $externalOnlineMenuAvailableTimeTerms
    on_conflict: {constraint: externalOnlineMenuId_dayWeek_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  externalOnlineMenuId: Types.Scalars['uuid'];
}>;


export type EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'changeDateTime'>
  )>, externalOnlineMenu_by_pk?: Types.Maybe<(
    { __typename?: 'externalOnlineMenu' }
    & Pick<Types.ExternalOnlineMenu, 'id' | 'name' | 'url'>
    & { externalOnlineMenuAvailableTimeTerms: Array<(
      { __typename?: 'externalOnlineMenuAvailableTimeTerm' }
      & Pick<Types.ExternalOnlineMenuAvailableTimeTerm, 'id' | 'dayWeek' | 'start' | 'end'>
    )> }
  )> }
);

export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationVariables = Types.Exact<{
  externalOnlineMenuId: Types.Scalars['uuid'];
  externalOnlineMenu: Types.ExternalOnlineMenuSetInput;
  externalOnlineMenuAvailableTimeTermIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  externalOnlineMenuAvailableTimeTerms: Array<Types.ExternalOnlineMenuAvailableTimeTermInsertInput> | Types.ExternalOnlineMenuAvailableTimeTermInsertInput;
}>;


export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation = (
  { __typename?: 'mutation_root' }
  & { update_externalOnlineMenu_by_pk?: Types.Maybe<{ __typename: 'externalOnlineMenu' }>, delete_externalOnlineMenuAvailableTimeTerm?: Types.Maybe<(
    { __typename?: 'externalOnlineMenuAvailableTimeTerm_mutation_response' }
    & Pick<Types.ExternalOnlineMenuAvailableTimeTermMutationResponse, 'affected_rows'>
  )>, insert_externalOnlineMenuAvailableTimeTerm?: Types.Maybe<(
    { __typename?: 'externalOnlineMenuAvailableTimeTerm_mutation_response' }
    & Pick<Types.ExternalOnlineMenuAvailableTimeTermMutationResponse, 'affected_rows'>
  )> }
);


export const EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopDocument = gql`
    query EditShopExternalOnlineMenuGetExternalOnlineMenuAndShop($shopId: uuid!, $externalOnlineMenuId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenu_by_pk(id: $externalOnlineMenuId) {
    id
    name
    url
    externalOnlineMenuAvailableTimeTerms {
      id
      dayWeek
      start
      end
    }
  }
}
    `;

/**
 * __useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery__
 *
 * To run a query within a React component, call `useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      externalOnlineMenuId: // value for 'externalOnlineMenuId'
 *   },
 * });
 */
export function useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery(baseOptions: Apollo.QueryHookOptions<EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery, EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery, EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables>(EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopDocument, options);
      }
export function useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery, EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery, EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables>(EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopDocument, options);
        }
export type EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryHookResult = ReturnType<typeof useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery>;
export type EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopLazyQueryHookResult = ReturnType<typeof useEditShopExternalOnlineMenuGetExternalOnlineMenuAndShopLazyQuery>;
export type EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryResult = Apollo.QueryResult<EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQuery, EditShopExternalOnlineMenuGetExternalOnlineMenuAndShopQueryVariables>;
export const EditShopExternalOnlineMenuUpdateExternalOnlineMenuDocument = gql`
    mutation EditShopExternalOnlineMenuUpdateExternalOnlineMenu($externalOnlineMenuId: uuid!, $externalOnlineMenu: externalOnlineMenu_set_input!, $externalOnlineMenuAvailableTimeTermIds: [uuid!]!, $externalOnlineMenuAvailableTimeTerms: [externalOnlineMenuAvailableTimeTerm_insert_input!]!) {
  update_externalOnlineMenu_by_pk(
    pk_columns: {id: $externalOnlineMenuId}
    _set: $externalOnlineMenu
  ) {
    __typename
  }
  delete_externalOnlineMenuAvailableTimeTerm(
    where: {id: {_nin: $externalOnlineMenuAvailableTimeTermIds}, externalOnlineMenuId: {_eq: $externalOnlineMenuId}}
  ) {
    affected_rows
  }
  insert_externalOnlineMenuAvailableTimeTerm(
    objects: $externalOnlineMenuAvailableTimeTerms
    on_conflict: {constraint: externalOnlineMenuId_dayWeek_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationFn = Apollo.MutationFunction<EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation, EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationVariables>;

/**
 * __useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation__
 *
 * To run a mutation, you first call `useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopExternalOnlineMenuUpdateExternalOnlineMenuMutation, { data, loading, error }] = useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation({
 *   variables: {
 *      externalOnlineMenuId: // value for 'externalOnlineMenuId'
 *      externalOnlineMenu: // value for 'externalOnlineMenu'
 *      externalOnlineMenuAvailableTimeTermIds: // value for 'externalOnlineMenuAvailableTimeTermIds'
 *      externalOnlineMenuAvailableTimeTerms: // value for 'externalOnlineMenuAvailableTimeTerms'
 *   },
 * });
 */
export function useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation, EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation, EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationVariables>(EditShopExternalOnlineMenuUpdateExternalOnlineMenuDocument, options);
      }
export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationHookResult = ReturnType<typeof useEditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation>;
export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationResult = Apollo.MutationResult<EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation>;
export type EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationOptions = Apollo.BaseMutationOptions<EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutation, EditShopExternalOnlineMenuUpdateExternalOnlineMenuMutationVariables>;