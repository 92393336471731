import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const EnglishNameField = memo<Props>((props) => (
  <FormItem
    label="英語店舗名"
    name="nameEn"
    rules={[{ required: true, message: "英語店舗名を入力してください" }]}
    {...props}
  >
    <Input />
  </FormItem>
));
