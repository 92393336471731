import React, { useMemo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { getDayOfWeek } from "models/salesBudget";
import {
  Area,
  ComposedChart,
  Legend as RechartsLegend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { isNumber } from "util/type/primitive";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { SalesAnalyticsRow } from "hooks/useSalesAnalytics/types";
import { DateString } from "libs/DateString";

import { formatPriceForSalesAnalyticsChart } from "./utils";

const StyledContainer = styled(ResponsiveContainer)`
  background: ${colors.BackGround.Default};
  border-radius: 6px;
  height: 230px;
`;

const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.BackGround.Default};
  box-sizing: border-box;
  border: 1px solid ${colors.Border.Default};
  padding: 12px;
`;

const ToolTipTitle = styled.text`
  font-weight: bold;
  font-size: 14px;
`;

const ToolTipTextContainer = styled.text`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding-top: 6px;
`;

const HighlightedToolTipText = styled.text<{ isPlus: boolean }>`
  font-size: 14px;
  color: ${({ isPlus }) => (isPlus ? "#52c41a" : colors.Text.Error)};
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const LegendSquareIcon = styled.div<{ type: "currentYear" | "previousYear" }>`
  width: 12px;
  height: 10px;
  background-color: ${({ type }) => (type === "currentYear" ? "#B9D6FF" : "#F0F0F0")};
`;

const LegendText = styled.text`
  color: #767676;
  font-size: 10px;
`;

type Props = {
  rows: SalesAnalyticsRow[];
};

const renderXAxisTick = ({
  x,
  y,
  payload: { value },
}: {
  x: number;
  y: number;
  payload: { value: DateString };
}) => {
  // NOTE: data が空のとき value に XAxis の props の値が入ってくるので日付文字列のときのみ処理するようにしておく
  if (typeof value !== "string" || value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/) === null) {
    return <></>;
  }

  const date = dayjs(value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text fill="#999999" dy={16} textAnchor="middle" fontSize={10}>
        {date.format("D")}
      </text>
      <text fill="#999999" dy={32} textAnchor="middle" fontSize={10}>
        {getDayOfWeek(date)}
      </text>
    </g>
  );
};

const renderYAxisTick = ({
  x,
  y,
  payload: { value },
}: {
  x: number;
  y: number;
  payload: { value: number };
}) => {
  const formattedValue = Math.floor(value / 10000) > 0 ? `${Math.floor(value / 10000)}万` : null;

  if (formattedValue === null) return <></>;

  return (
    <g transform={`translate(${x - 20},${y + 5})`}>
      <text fill="#999999" textAnchor="middle" fontSize={10}>
        {formattedValue}
      </text>
    </g>
  );
};

const renderToolTip = ({
  payload,
  label,
  active,
}: {
  payload?: {
    dataKey?: string | number | undefined;
    value?: number;
  }[];
  label?: string;
  active?: boolean;
}) => {
  if (active && payload && label) {
    const totalAmount = payload.find(({ dataKey }) => dataKey === "totalAmount")?.value;
    const previousYearTotalAmount = payload.find(
      ({ dataKey }) => dataKey === "previousYearTotalAmount",
    )?.value;
    const salesTargetAmount = payload.find(({ dataKey }) => dataKey === "salesTargetAmount")?.value;

    return (
      <ToolTipContainer>
        <ToolTipTitle>{label}</ToolTipTitle>
        {isNumber(totalAmount) ? (
          <ToolTipTextContainer>
            売上実績：
            <HighlightedToolTipText isPlus={totalAmount >= (salesTargetAmount ?? 0)}>
              {formatPriceForSalesAnalyticsChart(totalAmount)}
            </HighlightedToolTipText>
          </ToolTipTextContainer>
        ) : null}
        {isNumber(salesTargetAmount) ? (
          <ToolTipTextContainer>
            売上目標：{formatPriceForSalesAnalyticsChart(salesTargetAmount)}
          </ToolTipTextContainer>
        ) : null}
        {isNumber(previousYearTotalAmount) ? (
          <ToolTipTextContainer>
            前年同月：{formatPriceForSalesAnalyticsChart(previousYearTotalAmount)}
          </ToolTipTextContainer>
        ) : null}
      </ToolTipContainer>
    );
  }

  return <></>;
};

const Legend = React.memo(() => (
  <LegendContainer>
    <LegendSquareIcon type="currentYear" />
    <Spacer width={4} />
    <LegendText>売上実績</LegendText>
    <Spacer width={8} />

    <svg viewBox="0 0 15 15" width="15" height="15">
      <path d="M 0,7 15,7" fill="none" stroke="black" strokeDasharray="5" />
    </svg>
    <Spacer width={4} />
    <LegendText>売上目標</LegendText>
    <Spacer width={4} />

    <LegendSquareIcon type="previousYear" />
    <Spacer width={4} />
    <LegendText>前年同月</LegendText>
  </LegendContainer>
));

export const DaySalesAnalyticsCharts = ({ rows }: Props) => {
  const filteredRows = useMemo(
    () =>
      rows
        .filter((row) => row.isEmpty || !row.isSummaryRow)
        .reduce<
          {
            name: string;
            salesTargetAmount: number | null;
            totalAmount: number | null;
            previousYearTotalAmount: number | null;
          }[]
        >((acc, current, idx) => {
          const previous = acc[idx - 1];

          const currentSalesTargetAmount = current.salesTargetAmount ?? 0;
          const currentTotalAmount = !current.isEmpty ? current.totalAmount : 0;
          const currentPreviousYearTotalAmount = !current.isEmpty
            ? current.previousYearTotalAmount
            : 0;

          const isFutureDate = dayjs(current.name).isAfter(dayjs());

          return [
            ...acc,
            {
              name: current.name,
              salesTargetAmount: (previous?.salesTargetAmount ?? 0) + currentSalesTargetAmount,
              totalAmount: !isFutureDate ? (previous?.totalAmount ?? 0) + currentTotalAmount : null,
              previousYearTotalAmount:
                (previous?.previousYearTotalAmount ?? 0) + (currentPreviousYearTotalAmount ?? 0),
            },
          ];
        }, []),
    [rows],
  );

  return (
    <StyledContainer width="100%" height={230}>
      <ComposedChart
        width={976}
        height={230}
        data={filteredRows}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis
          tick={renderXAxisTick}
          tickLine={false}
          dataKey="name"
          scale="auto"
          interval={0}
          axisLine={{ stroke: colors.Border.Default, strokeDasharray: "5 5" }}
        />
        <YAxis
          tick={renderYAxisTick}
          tickLine={false}
          domain={[0, "dataMax"]}
          axisLine={{ stroke: colors.Border.Default }}
          yAxisId="left"
        />
        <YAxis orientation="right" axisLine={{ stroke: colors.Border.Default }} tickLine={false} />

        <Tooltip content={renderToolTip} />
        <RechartsLegend verticalAlign="top" content={<Legend />} />

        <Area
          yAxisId="left"
          type="monotone"
          dataKey="previousYearTotalAmount"
          fill="#E6E6E6"
          fillOpacity={1}
          stroke="#E6E6E6"
        />
        <Area
          yAxisId="left"
          type="monotone"
          dataKey="totalAmount"
          fill={colors.BackGround.PrimaryDefault}
          fillOpacity={0.3}
          stroke={colors.BackGround.PrimaryDefault}
        />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="salesTargetAmount"
          dot={false}
          stroke="#595959"
          strokeDasharray="5 5"
          isAnimationActive={false}
        />
      </ComposedChart>
    </StyledContainer>
  );
};
