import React, { memo } from "react";
import styled from "styled-components";
import { Alert, Col, Row } from "antd";
import { isNotNullable } from "util/type/primitive";

import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlagEnum, PaymentProviderType } from "types/graphql";

import { EditDiniiPayConfigFormItem } from "../useEditDiniiPayConfigForm";

import { CardFeeRateField } from "./CardFeeRateField";
import { GmoShopIdField } from "./GmoShopIdField";
import { GmoShopPasswordField } from "./GmoShopPasswordField";
import { PaymentProviderField } from "./PaymentProviderField";

type Props = {
  shopId: string;
};

const SectionTitle = styled.h2`
  font-weight: bold;
  margin: 0;
`;

export const EditOnlinePaymentConfigFields = memo<Props>(({ shopId }) => {
  const { isFeatureAvailable } = useFeatureFlag();
  const isGmoPaymentEnabled = isFeatureAvailable({
    name: FeatureFlagEnum.EnableGmoOnlinePayment,
    shopIds: [shopId],
  });
  const isAdyenPaymentEnabled = isFeatureAvailable({
    name: FeatureFlagEnum.EnableAdyenOnlinePayment,
    shopIds: [shopId],
  });
  const isOnlinePaymentEnabled = isGmoPaymentEnabled || isAdyenPaymentEnabled;

  return (
    <>
      <SectionTitle>オンライン決済設定</SectionTitle>

      {isOnlinePaymentEnabled && (
        <Row>
          <Col span={6}>
            <Spacer size={16} />
            <Alert
              type="warning"
              description="オンライン決済の設定は、運用中には編集できません。編集するには、フィーチャーフラグをオフにしてください"
            />
          </Col>
        </Row>
      )}

      <Spacer size={24} />

      <Row>
        <Col span={6}>
          <PaymentProviderField disabled={isOnlinePaymentEnabled} />
        </Col>
      </Row>
      <EditDiniiPayConfigFormItem.NonProperty
        noStyle
        shouldUpdate={withFormDependencies(({ onlinePaymentConfig }) => [
          onlinePaymentConfig.paymentProvider,
        ])}
      >
        {({ getFieldValue }) => {
          const paymentProvider = getFieldValue([
            "onlinePaymentConfig",
            "paymentProvider",
          ]) as PaymentProviderType | null;
          return (
            isNotNullable(paymentProvider) && (
              <>
                {paymentProvider === PaymentProviderType.Gmo && (
                  <>
                    <Row>
                      <Col span={6}>
                        <GmoShopIdField disabled={isOnlinePaymentEnabled} />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <GmoShopPasswordField disabled={isOnlinePaymentEnabled} />
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col span={6}>
                    <CardFeeRateField
                      label="VISA 料率"
                      name={["onlinePaymentConfig", "visaCardFeeRate"]}
                      disabled={isOnlinePaymentEnabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CardFeeRateField
                      name={["onlinePaymentConfig", "masterCardFeeRate"]}
                      label="MASTER 料率"
                      disabled={isOnlinePaymentEnabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CardFeeRateField
                      name={["onlinePaymentConfig", "jcbCardFeeRate"]}
                      label="JCB 料率"
                      disabled={isOnlinePaymentEnabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CardFeeRateField
                      name={["onlinePaymentConfig", "amexCardFeeRate"]}
                      label="AMEX 料率"
                      disabled={isOnlinePaymentEnabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <CardFeeRateField
                      name={["onlinePaymentConfig", "dinersCardFeeRate"]}
                      label="DINERS 料率"
                      disabled={isOnlinePaymentEnabled}
                    />
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </EditDiniiPayConfigFormItem.NonProperty>
    </>
  );
});
