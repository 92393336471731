import React from "react";
import styled from "styled-components";
import { Divider, List } from "antd";
import { ListProps } from "antd/lib/list";

import { Loading } from "components/Loading";

const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 0;
  }
`;

type Props<T> = ListProps<T> & { loading?: boolean };

export const PriorityList = <T,>({ renderItem, loading, ...props }: Props<T>) => {
  const { dataSource = [] } = props;
  const lastIndex = dataSource.length - 1;

  return (
    <List
      loading={{
        spinning: loading,
        indicator: <Loading />,
        size: "large" as const,
      }}
      {...props}
      renderItem={(item, index) => (
        <>
          {renderItem?.(item, index)}
          {index < lastIndex && <StyledDivider />}
        </>
      )}
    />
  );
};
