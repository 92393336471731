import React, { memo } from "react";
import { getRole } from "models/user";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useCurrentUser } from "hooks/useUser";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";
import { Role } from "types/role";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const WinboardMenu = memo<Props>(({ company, ...props }) => {
  const user = useCurrentUser();
  const role = getRole(user);

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isShowConfig = isFeatureEnabled("showWinboardConfig");

  const companyId = company?.id;
  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const { isFeatureAvailable } = useFeatureFlag();
  const canShowYellMenu = isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds });
  // NOTE: UAT業態と特定法人のみに公開する
  const canShowMenuCodeCsv =
    role === Role.ServiceAdmin ||
    (companyId !== undefined &&
      [
        "2620ba45-0844-4603-baf0-523505e7f85b", // 五反田カキイロハ
        "1a048960-7353-4e11-8557-33e274b5c196", // 牛タンいろ葉調布
        "32f305b9-f870-4e03-baba-8af937d7880d", // UAT (production)
        "961da169-b622-47ab-a710-af4314d8710f", // UAT (beta)
        "90b0c42e-09da-4c36-b340-588acf98f0f1", // UAT (staging)
        "4ac270d6-5cfb-4adc-8919-0163968bdcd2", // UAT (develop)
      ].includes(companyId));

  return (
    <SubMenu
      key="winboard"
      title="ウィンボード連携"
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      {isShowConfig && <MenuItem route="editWinboardConfig" to="/winboard/config" text="設定" />}
      <MenuItem route="winboardUpload" to="/winboard/upload" text="連携処理" />
      <MenuItem
        route="winboardMenuRegistrationCsv"
        to="/winboard/menuRegistrationCsv"
        text="商品登録CSV"
      />
      <MenuItem route="winboardInflowSourceTags" to="/winboard/inflowSourceTag" text="媒体設定" />
      <MenuItem route="winboardMenus" to="/winboard/menu" text="メニュー" />
      <MenuItem route="winboardPlans" to="/winboard/plan" text="プラン" />
      <MenuItem
        route="winboardMenus"
        to="/winboard/gift"
        text="推しエール"
        canShow={canShowYellMenu}
      />
      <MenuItem
        route="menuCodeCsv"
        to="/menuCodeCsv/winboard"
        text="メニューコード一括編集"
        canShow={canShowMenuCodeCsv}
      ></MenuItem>
    </SubMenu>
  );
});
