import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuSetInput } from "types/graphql";

import { EditGiftForm } from "./EditGiftForm";
import { useEditGiftGetMenuQuery, useEditGiftUpdateMenuMutation } from "./queries";

export const EditGift = () => {
  const { id } = useParams<{ id: string }>();

  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    error: getMenuError,
  } = useEditGiftGetMenuQuery(!Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true });
  const menu = getMenuData?.menu?.[0];

  const [updateMenuMutation, { loading: loadingUpdateMenu }] = useEditGiftUpdateMenuMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async ({ menu }: { menu: MenuSetInput }) => {
      if (!Number.isNaN(menuId)) {
        try {
          await updateMenuMutation({
            variables: { menuId, menu },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [menuId, updateMenuMutation],
  );

  const shouldShowAlert = getMenuError;
  const loading = loadingMenu || loadingUpdateMenu;

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <PageHeader title="推しエールメニュー編集" onBack={goBack} />
      {loading && <Loading height={300} />}

      {shouldShowAlert && (
        <Alert
          message="通信に失敗した"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {menu && <EditGiftForm menu={menu} onSubmit={onSubmit} onClose={goBack} loading={loading} />}
    </DashboardLayout>
  );
};
