import React, { memo } from "react";

type Props = {
  externalLinkUrl: string;
  text: string;
};

export const ExternalLink = memo<Props>(({ externalLinkUrl, text }) => (
  <a href={externalLinkUrl} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
));
