import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanChoicePikaichiMenuFormItem } from "../useEditPlanChoicePikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiMenuCdField = memo<Props>((props) => (
  <EditPlanChoicePikaichiMenuFormItem
    label="メニューコード"
    name="pikaichiMenuCd"
    rules={[{ required: true, message: "メニューコードを入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditPlanChoicePikaichiMenuFormItem>
));
