import React from "react";
import styled from "styled-components";

import AIcon from "assets/menu-order-item-analytics/abc_analysis_a.svg";
import BIcon from "assets/menu-order-item-analytics/abc_analysis_b.svg";
import CIcon from "assets/menu-order-item-analytics/abc_analysis_c.svg";

import { defaultFixedDecimalPointForDisplay } from ".";

const CellContent = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StatsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const Percentage = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

type Props = {
  actualPercentage: number;
  subTitle: string;
  abcRank: string;
  hideAbcRank?: boolean;
};

export const renderMenuOrderItemAnalyticsCell = ({
  actualPercentage,
  subTitle,
  abcRank,
  hideAbcRank,
}: Props) => ({
  props: {
    style: hideAbcRank
      ? {}
      : {
          backgroundColor: abcRank === "A" ? "#FDE7F2" : abcRank === "B" ? "#F6FFED" : "#FAFAFA",
        },
  },
  children: (
    <CellContent>
      {hideAbcRank ? (
        <div></div>
      ) : (
        <div>{abcRank === "A" ? <AIcon /> : abcRank === "B" ? <BIcon /> : <CIcon />}</div>
      )}
      <StatsWrapper>
        <Percentage>
          {isNaN(actualPercentage)
            ? 0
            : (actualPercentage * 100).toFixed(defaultFixedDecimalPointForDisplay)}
          %
        </Percentage>
        <Subtitle>({subTitle})</Subtitle>
      </StatsWrapper>
    </CellContent>
  ),
});
