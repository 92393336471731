import React from "react";
import { Col, Row } from "antd";

import { InputPrice } from "components/Input/InputPrice";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = {
  openPrice: boolean;
  disabled?: boolean;
};

export const SinglePriceField = React.memo<Props>(({ openPrice, disabled }) => (
  <Row gutter={24}>
    <Col span={6}>
      <AddMenuFormItem
        name="price"
        label="販売価格"
        required={!openPrice}
        rules={!openPrice ? [{ required: true, message: "価格を入力してください" }] : []}
      >
        <InputPrice disabled={disabled} />
      </AddMenuFormItem>
    </Col>
  </Row>
));
