import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { AccountingSlipImagePositionEnum } from "types/graphql";

import { AddAccountingSlipImageFormValues } from "./AddAccountingSlipImageForm/useAddAccountingSlipImageForm";
import { AddAccountingSlipImageForm } from "./AddAccountingSlipImageForm";
import { useAddAccountingSlipImageModalAddAccountingSlipImageMutation } from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

type Props = {
  shopId: string;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddAccountingSlipImageModal = memo<Props>(({ shopId, goBack, onUpdated }) => {
  const { position = AccountingSlipImagePositionEnum.Header } = useParams<{
    position: AccountingSlipImagePositionEnum;
  }>();

  if (!position) {
    goBack();
  }

  const onCompleteUpdate = useCallback(() => {
    message.success("作成しました");
    onUpdated();
    goBack();
  }, [goBack, onUpdated]);
  const [addAccountingSlipMutation, { loading: addLoading }] =
    useAddAccountingSlipImageModalAddAccountingSlipImageMutation({
      onCompleted: onCompleteUpdate,
      onError: () => message.error("作成に失敗しました"),
    });

  const [formValues, setFormValues] = useState<AddAccountingSlipImageFormValues | null>(null);

  const onOk = useCallback(async () => {
    if (!formValues) return;
    const { imageUrl, description } = formValues;
    if (!imageUrl) return;
    await addAccountingSlipMutation({
      variables: {
        input: {
          imageUrl,
          description,
          shopId,
          position,
        },
      },
    });
  }, [addAccountingSlipMutation, formValues, position, shopId]);

  const onCancel = goBack;

  return (
    <Modal
      title="レシート画像新規作成"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button
          key="add"
          type="primary"
          onClick={onOk}
          loading={addLoading}
          disabled={formValues === null || formValues.imageUrl === null}
        >
          作成
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      <StyledAlert
        message="営業中に伝票表示設定を変更した場合は、レジにて再度営業開始を行うか、アプリを立ち上げ直す必要があります"
        type="info"
      />
      <AddAccountingSlipImageForm position={position} onChange={setFormValues} />
    </Modal>
  );
});
