import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client/errors";
import { FirebaseError } from "firebase/app";

import Logo from "assets/logo.svg";
// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { SimpleLayout } from "components/Layout/SimpleLayout";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";

import { useAdyenTerminalPaymentSignInSignInMutation } from "./queries";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  background: ${grey[0]};
  width: 400px;
  margin-top: 16px;
  padding: 48px;
  border-radius: 8px;
  box-sizing: border-box;
`;

const StyledInput = styled(Input)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const StyledButton = styled(Button)`
  padding-top: 8px;
  padding-bottom: 8px;
  height: auto;
`;

export const AdyenTerminalPaymentSignIn = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  const [signInMutation] = useAdyenTerminalPaymentSignInSignInMutation({ fetchPolicy: "no-cache" });

  const handleFinish = useCallback(async () => {
    const values = await form.validateFields();
    try {
      const email = values.email;
      const password = values.password;

      await signInMutation({ variables: { email, password } });
    } catch (err: unknown) {
      if (err instanceof ApolloError && err?.networkError instanceof FirebaseError) {
        if (err.networkError.code === "auth/user-not-found") {
          return form.setFields([
            {
              name: "email",
              value: values.email,
              errors: ["ユーザーが存在しません"],
            },
          ]);
        }
        if (err.networkError.code === "auth/invalid-email") {
          return form.setFields([
            {
              name: "email",
              value: values.email,
              errors: ["メールアドレスが不正です"],
            },
          ]);
        }
        if (err.networkError.code === "auth/wrong-password") {
          return form.setFields([
            {
              name: "password",
              value: values.password,
              errors: ["パスワードが間違っています"],
            },
          ]);
        }
        if (err.networkError.code === "auth/too-many-requests") {
          return form.setFields([
            {
              name: "password",
              value: values.password,
              errors: [
                "ログインに何度も失敗したため、このアカウントへのログインが一時的に無効になっています。後ほどお試しください。",
              ],
            },
          ]);
        }
      }
      if (err instanceof Error) {
        return setError(`ログインに失敗しました（${err.message}）`);
      }
    }
  }, [form, signInMutation]);

  const handleChange = useCallback(() => setError(""), [setError]);

  return (
    <SimpleLayout>
      <Logo width={200} />
      <StyledForm
        name="sign-in"
        form={form}
        onFinish={handleFinish}
        initialValues={
          import.meta.env.PR_NUMBER
            ? { email: "admin@pr-test.dinii.jp", password: "testshop2020" }
            : undefined
        }
      >
        <FormItem
          name="email"
          rules={[{ required: true, message: "メールアドレスを入力してください" }]}
          endSpacer={null}
        >
          <StyledInput
            prefix={<UserOutlined />}
            placeholder="メールアドレス"
            onChange={handleChange}
          />
        </FormItem>
        <Spacer size={24} />
        <FormItem
          name="password"
          rules={[{ required: true, message: "パスワードを入力してください" }]}
          endSpacer={null}
        >
          <StyledInput
            prefix={<LockOutlined />}
            type="password"
            placeholder="パスワード"
            onChange={handleChange}
          />
        </FormItem>
        <Spacer size={24} />

        {error && (
          <>
            <StyledAlert message={error} type="error" />
            <Spacer size={24} />
          </>
        )}

        <FormItem endSpacer={null}>
          <StyledButton type="primary" htmlType="submit" block>
            ログイン
          </StyledButton>
        </FormItem>
      </StyledForm>
    </SimpleLayout>
  );
};
