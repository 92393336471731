import React, { memo } from "react";
import { Select } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { CouponTypeEnum } from "types/graphql";

import { Menu } from "../../../types";

const { Option } = Select;

type Props = {
  menus: Menu[];
};

export const MenusField = memo<Props>(({ menus }) => (
  <FormItem
    name={[CouponTypeEnum.MenuDiscount, "menuId"]}
    label="メニュー"
    rules={[{ required: true, message: "メニューを選択してください" }]}
  >
    <Select<string> placeholder="メニュー">
      {menus.map(({ menuId, name }) => (
        <Option key={menuId} value={menuId}>
          {name}
        </Option>
      ))}
    </Select>
  </FormItem>
));
