import React, { useCallback, useMemo, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Collapse } from "antd";
import { noop } from "dinii-self-js-lib/noop";
import { filterPlansByName } from "models/plan";
import { isSoldOut as checkIsSoldOut } from "models/stock";
import { isNotUndefined } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { EditShopPlansBulkConfirmModal } from "./EditShopPlansBulkConfirmModal";
import { EditShopPlansBulkSummary } from "./EditShopPlansBulkSummary";
import { EditShopPlansBulkFormTable } from "./EditShopPlansBulkTable";
import { FilterConditions, PlanFilter } from "./PlanFilter";
import {
  useEditShopPlansBulkGetPlansQuery,
  useEditShopPlansBulkGetShopsQuery,
  useUpdateShopPlansBulkMutation,
} from "./queries";
import { BulkEditConditions, Plan, PlanChoice } from "./types";

const StyledAlert = styled(Alert)`
  background-color: #fffbe6;
  border: 1px solid #ffe58f;

  .ant-alert-icon {
    color: #52c41a;
  }
`;

const Wrapper = styled.div`
  background: ${grey[0]};
  padding: 24px;
  border-radius: 8px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const filterPlans = ({
  plans,
  filterConditions: { name, isDealing, isVisibleForCustomer, isVisibleForStaff, isSoldOut },
}: {
  plans: Plan[];
  filterConditions: FilterConditions;
}) => {
  const filteredPlans = plans.filter((plan) => {
    const shopPlans = plan.shopPlans;
    return (
      (isDealing === null || (isDealing ? shopPlans.length > 0 : shopPlans.length === 0)) &&
      (isVisibleForCustomer === null ||
        shopPlans.some((shopPlan) => shopPlan.isVisibleForCustomer === isVisibleForCustomer)) &&
      (isVisibleForStaff === null ||
        shopPlans.some((shopPlan) => shopPlan.isVisibleForStaff === isVisibleForStaff)) &&
      (isSoldOut === null || shopPlans.some(({ stock }) => checkIsSoldOut({ stock }) === isSoldOut))
    );
  });

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans;
};

export const EditShopPlansBulk = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    hasFilterConditions,
    filterConditions: partialFilterConditions,
    updateFilterCondition,
    clearFilterConditions,
  } = useFilterConditions<FilterConditions>({
    name: null,
    isDealing: null,
    isVisibleForCustomer: null,
    isVisibleForStaff: null,
    isSoldOut: null,
  });
  const filterConditions: FilterConditions = useMemo(
    () => ({
      name: partialFilterConditions.name ?? null,
      isDealing: partialFilterConditions.isDealing ?? null,
      isVisibleForCustomer: partialFilterConditions.isVisibleForCustomer ?? null,
      isVisibleForStaff: partialFilterConditions.isVisibleForStaff ?? null,
      isSoldOut: partialFilterConditions.isSoldOut ?? null,
    }),
    [partialFilterConditions],
  );

  const {
    data: getPlansData,
    loading: loadingPlans,
    refetch: refetchPlans,
  } = useEditShopPlansBulkGetPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const plans: Plan[] = useMemo(() => getPlansData?.plan ?? [], [getPlansData]);

  const planChoices = useMemo<PlanChoice[]>(
    () =>
      plans.flatMap((plan) =>
        plan.planOptions.flatMap(({ planOptionId, planChoices }) =>
          planChoices.map((choice) => ({
            ...choice,
            planId: plan.id,
            _planId: plan.planId,
            planOptionId,
          })),
        ),
      ),
    [plans],
  );

  const filteredPlans = useMemo(
    () => filterPlans({ plans, filterConditions }),
    [plans, filterConditions],
  );
  const planRowKeyToPlanMap = useMemo(
    () => new Map<string, Plan>(plans.map((plan) => [plan.id, plan])),
    [plans],
  );
  const planChoiceRowKeyToPlanChoiceMap = useMemo(
    () =>
      new Map<string, PlanChoice>(
        planChoices.map((planChoice) => [`${planChoice.planId}-${planChoice.id}`, planChoice]),
      ),
    [planChoices],
  );

  const { data: getShopsData } = useEditShopPlansBulkGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);
  const shopIdToShopMap = useMemo(() => new Map(shops.map((shop) => [shop.shopId, shop])), [shops]);
  const allShopIds = useMemo(() => shops.map((shop) => shop.shopId), [shops]);

  const [updateShopPlansBulkMutation, { loading: loadingUpdateShopPlansBulk }] =
    useUpdateShopPlansBulkMutation();

  const [bulkEditConditions, setBulkEditConditions] = useState<BulkEditConditions>({
    shopIds: null,
    isDealing: null,
    isVisibleForCustomer: null,
    isVisibleForStaff: null,
    isSoldOut: null,
  });
  const [selectedPlanRowKeys, setSelectedPlanRowKeys] = useState<string[]>([]);
  const [selectedPlanChoiceRowKeys, setSelectedPlanChoiceRowKeys] = useState<string[]>([]);
  const selectedRowKeys = useMemo(
    () => [...selectedPlanRowKeys, ...selectedPlanChoiceRowKeys],
    [selectedPlanRowKeys, selectedPlanChoiceRowKeys],
  );

  const clearBulkEditConditions = useCallback(() => {
    setSelectedPlanRowKeys([]);
    setSelectedPlanChoiceRowKeys([]);
    setBulkEditConditions({
      shopIds: null,
      isDealing: null,
      isVisibleForCustomer: null,
      isVisibleForStaff: null,
      isSoldOut: null,
    });
  }, [setSelectedPlanRowKeys, setSelectedPlanChoiceRowKeys, setBulkEditConditions]);

  const onSubmit = useCallback(async () => {
    const { shopIds, isDealing, isVisibleForCustomer, isVisibleForStaff, isSoldOut } =
      bulkEditConditions;

    try {
      await updateShopPlansBulkMutation({
        variables: {
          input: {
            shopIds: shopIds && shopIds.length > 0 ? shopIds : allShopIds,
            planIds: selectedPlanRowKeys
              .map((planRowKey) => {
                const plan = planRowKeyToPlanMap.get(planRowKey);
                if (!plan) return;
                return plan.id;
              })
              .filter(isNotUndefined),
            planChoiceIds: selectedPlanChoiceRowKeys
              .map((planChoiceRowKey) => {
                const planChoice = planChoiceRowKeyToPlanChoiceMap.get(planChoiceRowKey);
                if (!planChoice) return;
                return planChoice.id;
              })
              .filter(isNotUndefined),
            isDealing,
            isVisibleForCustomer,
            isVisibleForStaff,
            isSoldOut,
          },
        },
      });

      await refetchPlans({ companyId }).catch(noop);
      message.success("編集を保存しました");
      clearBulkEditConditions();
      navigate("/shopPlan/edit/bulk", { replace: true });
    } catch (err) {
      message.error("編集の保存に失敗しました");
    }
  }, [
    allShopIds,
    bulkEditConditions,
    clearBulkEditConditions,
    companyId,
    navigate,
    planChoiceRowKeyToPlanChoiceMap,
    planRowKeyToPlanMap,
    refetchPlans,
    selectedPlanChoiceRowKeys,
    selectedPlanRowKeys,
    updateShopPlansBulkMutation,
  ]);

  const loading = loadingPlans || loadingUpdateShopPlansBulk;

  return (
    <DashboardLayout
      title="取扱プラン"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "取扱プラン" }],
      }}
    >
      <PageHeader title="複数店舗の取扱一括設定" onBack={goBack} />

      <Wrapper>
        <PlanFilter
          hasFilterConditions={hasFilterConditions}
          filterConditions={filterConditions}
          updateFilterCondition={updateFilterCondition}
          clearFilterConditions={clearFilterConditions}
        />
        <Spacer size={16} />
        {selectedRowKeys.length === 0 ? (
          <Alert message="設定を変更するプランを選択してください" type="info" showIcon />
        ) : (
          <StyledAlert
            message="選択したプランすべてに変更内容が更新されます"
            type="info"
            showIcon
          />
        )}
        <Spacer size={16} />
        <EditShopPlansBulkFormTable
          plans={filteredPlans}
          shops={shops}
          shopIdToShopMap={shopIdToShopMap}
          bulkEditConditions={bulkEditConditions}
          setBulkEditConditions={setBulkEditConditions}
          selectedPlanRowKeys={selectedPlanRowKeys}
          setSelectedPlanRowKeys={setSelectedPlanRowKeys}
          selectedPlanChoiceRowKeys={selectedPlanChoiceRowKeys}
          setSelectedPlanChoiceRowKeys={setSelectedPlanChoiceRowKeys}
          selectedRowKeys={selectedRowKeys}
          clearBulkEditConditions={clearBulkEditConditions}
        />
        <Collapse>
          <Collapse.Panel header="変更内容一覧" key={1}>
            <EditShopPlansBulkSummary
              selectedPlanRowKeys={selectedPlanRowKeys}
              selectedPlanChoiceRowKeys={selectedPlanChoiceRowKeys}
              planRowKeyToPlanMap={planRowKeyToPlanMap}
              planChoiceRowKeyToPlanChoiceMap={planChoiceRowKeyToPlanChoiceMap}
              shopIdToShopMap={shopIdToShopMap}
              bulkEditConditions={bulkEditConditions}
              allShopIds={allShopIds}
            />
          </Collapse.Panel>
        </Collapse>
      </Wrapper>

      <Spacer size={24} />

      <Actions>
        <Button onClick={goBack}>キャンセル</Button>

        <Link to="/shopPlan/edit/bulk/confirm" replace>
          <Button type="primary">確認して更新</Button>
        </Link>

        <Routes>
          <Route
            path="confirm"
            element={
              <EditShopPlansBulkConfirmModal
                selectedPlanRowKeys={selectedPlanRowKeys}
                selectedPlanChoiceRowKeys={selectedPlanChoiceRowKeys}
                selectedRowKeys={selectedRowKeys}
                planRowKeyToPlanMap={planRowKeyToPlanMap}
                planChoiceRowKeyToPlanChoiceMap={planChoiceRowKeyToPlanChoiceMap}
                shopIdToShopMap={shopIdToShopMap}
                bulkEditConditions={bulkEditConditions}
                loading={loading}
                onSubmit={onSubmit}
                allShopIds={allShopIds}
              />
            }
          />
        </Routes>
      </Actions>
    </DashboardLayout>
  );
};
