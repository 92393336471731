import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { DisplayField } from "pages/EditClerk/EditClerkForm/DisplayField";
import { NameField } from "pages/EditClerk/EditClerkForm/NameField";
import { useEditClerkForm } from "pages/EditClerk/EditClerkForm/useEditClerkForm";
import { Clerk } from "pages/EditClerk/types";
import { ClerkSetInput, ShopClerkSetInput } from "types/graphql";

type Props = {
  clerk: Clerk;
  onSubmit: ({ clerk, shopClerk }: { clerk: ClerkSetInput; shopClerk: ShopClerkSetInput }) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditClerkForm = memo<Props>(({ clerk, onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useEditClerkForm(clerk, onSubmit);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="clerk" form={form} initialValues={initialValues} layout="vertical">
          <NameField />
          <DisplayField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
