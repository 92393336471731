import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { useShop } from "hooks/useShop";
import { EditCookingItemForm } from "pages/EditCookingItem/EditCookingItemForm";
import {
  useEditCookingItemGetCookingItemsAndShopAndRolesQuery,
  useEditCookingItemUpdateCookingItemAndDeleteRoleMutation,
  useEditCookingItemUpdateCookingItemMutation,
} from "pages/EditCookingItem/queries";
import { CookingItemSetInput, ShopCookingItemRoleInsertInput } from "types/graphql";

export const EditCookingItem = () => {
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getCookingItemsAndShopAndRolesData,
    loading: loadingCookingItem,
    refetch: refetchCookingItem,
    error,
  } = useEditCookingItemGetCookingItemsAndShopAndRolesQuery(
    id && shopId ? { variables: { id } } : { skip: true },
  );
  const cookingItem = getCookingItemsAndShopAndRolesData?.cookingItem[0];
  const dataShop = getCookingItemsAndShopAndRolesData?.shop[0];
  const roles = dataShop?.roles ?? [];

  useSetCurrentCompanyEffect(dataShop);

  const [updateCookingItemMutation, { loading: loadingUpdateCookingItem }] =
    useEditCookingItemUpdateCookingItemMutation();

  const [
    updateCookingItemAndDeleteRoleMutation,
    { loading: loadingUpdateCookingItemAndDeleteRole },
  ] = useEditCookingItemUpdateCookingItemAndDeleteRoleMutation();

  const onSubmit = useCallback(
    async (
      cookingItem: CookingItemSetInput,
      shopCookingItemRole:
        | ShopCookingItemRoleInsertInput
        | { roleId: undefined; _roleId: undefined },
    ) => {
      if (id && shopId) {
        try {
          shopCookingItemRole._roleId
            ? await updateCookingItemMutation({
                variables: { id, shopId, cookingItem, shopCookingItemRole },
              })
            : await updateCookingItemAndDeleteRoleMutation({
                variables: { id, shopId, cookingItem },
              });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }

        await refetchCookingItem();
      }
    },
    [
      id,
      shopId,
      updateCookingItemMutation,
      updateCookingItemAndDeleteRoleMutation,
      refetchCookingItem,
    ],
  );
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={cookingItem?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "調理アイテム" }],
      }}
    >
      <PageHeader title={cookingItem?.name} onBack={goBack} />
      {(loadingCookingItem || loadingUpdateCookingItemAndDeleteRole) && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shopId && cookingItem && (
        <EditCookingItemForm
          shopId={shopId}
          cookingItem={cookingItem}
          roles={roles}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateCookingItem}
        />
      )}
    </DashboardLayout>
  );
};
