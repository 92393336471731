import React from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { convertToRanking } from "models/customerDashboard";

import { CustomerRanking } from "components/CustomerRanking";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { useRankingVisitedCountsGetCustomerVisitedCountRankingQuery } from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const RankingVisitedCounts = () => {
  const { shop } = useShopPerCorporation();

  const {
    data: getCustomerVisitedCountRankingData,
    loading: getCustomerVisitedCountRankingDataLoading,
    error: getCustomerVisitedCountRankingDataError,
  } = useRankingVisitedCountsGetCustomerVisitedCountRankingQuery(
    shop ? { variables: { input: { shopId: shop.shopId } } } : { skip: true },
  );

  const visitedRankingData = convertToRanking(
    getCustomerVisitedCountRankingData?.customerVisitedCountRanking.customers,
  );

  return (
    <DashboardLayout title="リピート数ランキング">
      {getCustomerVisitedCountRankingDataError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CustomerRanking
        rankingData={visitedRankingData}
        loading={getCustomerVisitedCountRankingDataLoading}
        title="リピート数ランキング"
        valueUnit="回"
      />
    </DashboardLayout>
  );
};
