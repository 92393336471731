import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditWinboardMenuFormItem } from "../useEditWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CategoryNameField = memo<Props>((props) => (
  <EditWinboardMenuFormItem label="分類名" name="categoryName" {...props}>
    <Input />
  </EditWinboardMenuFormItem>
));
