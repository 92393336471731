import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCorporationGetCorporation = gql`
    query EditCorporationGetCorporation($corporationId: uuid!) {
  corporation_by_pk(id: $corporationId) {
    id
    name
  }
}
    `;
export const EditCorporationUpdateCorporation = gql`
    mutation EditCorporationUpdateCorporation($corporationId: uuid!, $name: String!) {
  update_corporation(_set: {name: $name}, where: {id: {_eq: $corporationId}}) {
    affected_rows
  }
}
    `;
export type EditCorporationGetCorporationQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type EditCorporationGetCorporationQuery = (
  { __typename?: 'query_root' }
  & { corporation_by_pk?: Types.Maybe<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
  )> }
);

export type EditCorporationUpdateCorporationMutationVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
}>;


export type EditCorporationUpdateCorporationMutation = (
  { __typename?: 'mutation_root' }
  & { update_corporation?: Types.Maybe<(
    { __typename?: 'corporation_mutation_response' }
    & Pick<Types.CorporationMutationResponse, 'affected_rows'>
  )> }
);


export const EditCorporationGetCorporationDocument = gql`
    query EditCorporationGetCorporation($corporationId: uuid!) {
  corporation_by_pk(id: $corporationId) {
    id
    name
  }
}
    `;

/**
 * __useEditCorporationGetCorporationQuery__
 *
 * To run a query within a React component, call `useEditCorporationGetCorporationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCorporationGetCorporationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCorporationGetCorporationQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useEditCorporationGetCorporationQuery(baseOptions: Apollo.QueryHookOptions<EditCorporationGetCorporationQuery, EditCorporationGetCorporationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCorporationGetCorporationQuery, EditCorporationGetCorporationQueryVariables>(EditCorporationGetCorporationDocument, options);
      }
export function useEditCorporationGetCorporationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCorporationGetCorporationQuery, EditCorporationGetCorporationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCorporationGetCorporationQuery, EditCorporationGetCorporationQueryVariables>(EditCorporationGetCorporationDocument, options);
        }
export type EditCorporationGetCorporationQueryHookResult = ReturnType<typeof useEditCorporationGetCorporationQuery>;
export type EditCorporationGetCorporationLazyQueryHookResult = ReturnType<typeof useEditCorporationGetCorporationLazyQuery>;
export type EditCorporationGetCorporationQueryResult = Apollo.QueryResult<EditCorporationGetCorporationQuery, EditCorporationGetCorporationQueryVariables>;
export const EditCorporationUpdateCorporationDocument = gql`
    mutation EditCorporationUpdateCorporation($corporationId: uuid!, $name: String!) {
  update_corporation(_set: {name: $name}, where: {id: {_eq: $corporationId}}) {
    affected_rows
  }
}
    `;
export type EditCorporationUpdateCorporationMutationFn = Apollo.MutationFunction<EditCorporationUpdateCorporationMutation, EditCorporationUpdateCorporationMutationVariables>;

/**
 * __useEditCorporationUpdateCorporationMutation__
 *
 * To run a mutation, you first call `useEditCorporationUpdateCorporationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCorporationUpdateCorporationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCorporationUpdateCorporationMutation, { data, loading, error }] = useEditCorporationUpdateCorporationMutation({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditCorporationUpdateCorporationMutation(baseOptions?: Apollo.MutationHookOptions<EditCorporationUpdateCorporationMutation, EditCorporationUpdateCorporationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCorporationUpdateCorporationMutation, EditCorporationUpdateCorporationMutationVariables>(EditCorporationUpdateCorporationDocument, options);
      }
export type EditCorporationUpdateCorporationMutationHookResult = ReturnType<typeof useEditCorporationUpdateCorporationMutation>;
export type EditCorporationUpdateCorporationMutationResult = Apollo.MutationResult<EditCorporationUpdateCorporationMutation>;
export type EditCorporationUpdateCorporationMutationOptions = Apollo.BaseMutationOptions<EditCorporationUpdateCorporationMutation, EditCorporationUpdateCorporationMutationVariables>;