import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanGroupGetPlanGroup = gql`
    query EditPlanGroupGetPlanGroup($planGroupId: Int!) {
  planGroup(where: {planGroupId: {_eq: $planGroupId}}, limit: 1) {
    companyId
    planGroupId
    name
  }
}
    `;
export const EditPlanGroupUpdatePlanGroup = gql`
    mutation EditPlanGroupUpdatePlanGroup($planGroupId: Int!, $planGroup: planGroup_set_input!) {
  update_planGroup(_set: $planGroup, where: {planGroupId: {_eq: $planGroupId}}) {
    affected_rows
  }
}
    `;
export type EditPlanGroupGetPlanGroupQueryVariables = Types.Exact<{
  planGroupId: Types.Scalars['Int'];
}>;


export type EditPlanGroupGetPlanGroupQuery = (
  { __typename?: 'query_root' }
  & { planGroup: Array<(
    { __typename?: 'planGroup' }
    & Pick<Types.PlanGroup, 'companyId' | 'planGroupId' | 'name'>
  )> }
);

export type EditPlanGroupUpdatePlanGroupMutationVariables = Types.Exact<{
  planGroupId: Types.Scalars['Int'];
  planGroup: Types.PlanGroupSetInput;
}>;


export type EditPlanGroupUpdatePlanGroupMutation = (
  { __typename?: 'mutation_root' }
  & { update_planGroup?: Types.Maybe<(
    { __typename?: 'planGroup_mutation_response' }
    & Pick<Types.PlanGroupMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanGroupGetPlanGroupDocument = gql`
    query EditPlanGroupGetPlanGroup($planGroupId: Int!) {
  planGroup(where: {planGroupId: {_eq: $planGroupId}}, limit: 1) {
    companyId
    planGroupId
    name
  }
}
    `;

/**
 * __useEditPlanGroupGetPlanGroupQuery__
 *
 * To run a query within a React component, call `useEditPlanGroupGetPlanGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGroupGetPlanGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanGroupGetPlanGroupQuery({
 *   variables: {
 *      planGroupId: // value for 'planGroupId'
 *   },
 * });
 */
export function useEditPlanGroupGetPlanGroupQuery(baseOptions: Apollo.QueryHookOptions<EditPlanGroupGetPlanGroupQuery, EditPlanGroupGetPlanGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanGroupGetPlanGroupQuery, EditPlanGroupGetPlanGroupQueryVariables>(EditPlanGroupGetPlanGroupDocument, options);
      }
export function useEditPlanGroupGetPlanGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanGroupGetPlanGroupQuery, EditPlanGroupGetPlanGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanGroupGetPlanGroupQuery, EditPlanGroupGetPlanGroupQueryVariables>(EditPlanGroupGetPlanGroupDocument, options);
        }
export type EditPlanGroupGetPlanGroupQueryHookResult = ReturnType<typeof useEditPlanGroupGetPlanGroupQuery>;
export type EditPlanGroupGetPlanGroupLazyQueryHookResult = ReturnType<typeof useEditPlanGroupGetPlanGroupLazyQuery>;
export type EditPlanGroupGetPlanGroupQueryResult = Apollo.QueryResult<EditPlanGroupGetPlanGroupQuery, EditPlanGroupGetPlanGroupQueryVariables>;
export const EditPlanGroupUpdatePlanGroupDocument = gql`
    mutation EditPlanGroupUpdatePlanGroup($planGroupId: Int!, $planGroup: planGroup_set_input!) {
  update_planGroup(_set: $planGroup, where: {planGroupId: {_eq: $planGroupId}}) {
    affected_rows
  }
}
    `;
export type EditPlanGroupUpdatePlanGroupMutationFn = Apollo.MutationFunction<EditPlanGroupUpdatePlanGroupMutation, EditPlanGroupUpdatePlanGroupMutationVariables>;

/**
 * __useEditPlanGroupUpdatePlanGroupMutation__
 *
 * To run a mutation, you first call `useEditPlanGroupUpdatePlanGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGroupUpdatePlanGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanGroupUpdatePlanGroupMutation, { data, loading, error }] = useEditPlanGroupUpdatePlanGroupMutation({
 *   variables: {
 *      planGroupId: // value for 'planGroupId'
 *      planGroup: // value for 'planGroup'
 *   },
 * });
 */
export function useEditPlanGroupUpdatePlanGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanGroupUpdatePlanGroupMutation, EditPlanGroupUpdatePlanGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanGroupUpdatePlanGroupMutation, EditPlanGroupUpdatePlanGroupMutationVariables>(EditPlanGroupUpdatePlanGroupDocument, options);
      }
export type EditPlanGroupUpdatePlanGroupMutationHookResult = ReturnType<typeof useEditPlanGroupUpdatePlanGroupMutation>;
export type EditPlanGroupUpdatePlanGroupMutationResult = Apollo.MutationResult<EditPlanGroupUpdatePlanGroupMutation>;
export type EditPlanGroupUpdatePlanGroupMutationOptions = Apollo.BaseMutationOptions<EditPlanGroupUpdatePlanGroupMutation, EditPlanGroupUpdatePlanGroupMutationVariables>;