import React, { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import {
  HEIGHT_PER_HOUR,
  Hours,
} from "pages/EditOrderableTime/EditOrderableTimeForm/DayWeekScheduler/constants";

import { DragState } from "./types";

const disableUserSelectGlobal = () => {
  document.body.style.userSelect = "none";
};

const enableUserSelectGlobal = () => {
  document.body.style.userSelect = "auto";
};

const StyledDiv = styled.div<{ height: number }>`
  ${({ height }) => css`
    height: ${height}px;
  `}
`;

export const VerticalDragEventObserver = ({
  children,
  height,
  dragState,
  isDraggingTargetEvent,
  onSetDragState,
  onDragEnd,
}: {
  dragState: DragState;
  isDraggingTargetEvent: boolean;
  height: number;
  onSetDragState: (_: DragState) => void;
  onDragEnd: (_: DragState) => void;
  children: React.ReactNode;
}) => {
  const dragStateRef = useRef(dragState);
  const isRegisteredEventListeners = useRef(false);

  // sync dragState with Ref to use dragState in mouseEventHandler
  useEffect(() => {
    dragStateRef.current = dragState;
  }, [dragState]);

  const elem = useRef<HTMLDivElement>(null);
  const getRectTop = () => {
    const rect = elem.current?.getBoundingClientRect();
    return rect?.top ?? 0;
  };

  const resetDragState = useCallback(() => {
    onSetDragState({ isDrag: false, start: 0, distance: 0 });
  }, [onSetDragState]);

  const calcMovedDragState = useCallback((pageY: number) => {
    const { start } = dragStateRef.current;
    const distance = pageY - getRectTop() - start;

    return { ...dragStateRef.current, start, distance };
  }, []);

  const calcFixedDistanceMovedDragState = useCallback((pageY: number) => {
    const { dragStartedEventOffsetY: offsetY } = dragStateRef.current;
    const start = pageY - getRectTop() - (offsetY ?? 0);

    return { ...dragStateRef.current, start };
  }, []);

  const mouseMoveHandler = useCallback(
    ({ pageY }: MouseEvent) => {
      if (!dragStateRef.current.isDrag) return;

      const nextState = dragStateRef.current.fixDistance
        ? calcFixedDistanceMovedDragState(pageY)
        : calcMovedDragState(pageY);

      const { start, distance } = nextState;

      // 領域外に出た場合は更新しない
      if (start + distance < 0 || start + distance > HEIGHT_PER_HOUR * Hours.length || start < 0) {
        return;
      }
      onSetDragState(nextState);
    },
    [onSetDragState, calcMovedDragState, calcFixedDistanceMovedDragState],
  );

  const mouseUpHandler = useCallback(() => {
    const state = dragStateRef.current;
    onDragEnd(state);
    resetDragState();
    enableUserSelectGlobal();
    document.removeEventListener("mousemove", mouseMoveHandler);
    isRegisteredEventListeners.current = false;
  }, [mouseMoveHandler, onDragEnd, resetDragState]);

  useEffect(() => {
    if (isDraggingTargetEvent && !isRegisteredEventListeners.current) {
      disableUserSelectGlobal();
      // 領域外に出ても反応するように global に登録する
      document.addEventListener("mouseup", mouseUpHandler, { once: true });
      document.addEventListener("mousemove", mouseMoveHandler);

      // NOTE: dragState の変更のたびに addEventListener が多重発火するのを防ぐため
      isRegisteredEventListeners.current = true;
    }
  }, [isDraggingTargetEvent, mouseUpHandler, mouseMoveHandler]);

  const mouseDownHandler: React.MouseEventHandler<HTMLDivElement> = ({
    pageY,
    target,
    currentTarget,
  }) => {
    // children の上からクリックしても反応しないように
    if (target !== currentTarget) return;

    onSetDragState({ isDrag: true, start: pageY - getRectTop(), distance: 0 });
  };

  return (
    <StyledDiv ref={elem} height={height} onMouseDown={mouseDownHandler} data-drag-target={true}>
      {children}
    </StyledDiv>
  );
};
