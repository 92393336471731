import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalMenusGetMenus = gql`
    query FoodingJournalMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId: id
        name
        shopMenus(where: {shop: {archivedAt: {_is_null: true}}}) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId: id
            name
            choices {
              choiceId: id
              name
              foodingJournalMenu {
                id
                name
                code
                foodingJournalDepartmentMaster {
                  id
                  code
                  name
                }
                foodingJournalGroupMaster {
                  id
                  code
                  name
                }
              }
            }
          }
        }
        foodingJournalMenu {
          id
          name
          code
          foodingJournalDepartmentMaster {
            id
            code
            name
          }
          foodingJournalGroupMaster {
            id
            code
            name
          }
        }
      }
    }
  }
}
    `;
export const FoodingJournalMenusGetCategories = gql`
    query FoodingJournalMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const FoodingJournalMenusGetShops = gql`
    query FoodingJournalMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const FoodingJournalMenusGetMasters = gql`
    query FoodingJournalMenusGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
  foodingJournalGroupMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
}
    `;
export const FoodingJournalMenusGetFoodingJournalMenusRelatedToPlans = gql`
    query FoodingJournalMenusGetFoodingJournalMenusRelatedToPlans($companyId: uuid!) {
  foodingJournalMenu(
    where: {_and: [{companyId: {_eq: $companyId}}, {_or: [{planId: {_is_null: false}}, {planChoiceId: {_is_null: false}}]}]}
  ) {
    id
    name
    code
  }
}
    `;
export const FoodingJournalMenusUpsertMenus = gql`
    mutation FoodingJournalMenusUpsertMenus($menusInput: [foodingJournalMenu_insert_input!]!, $choicesInput: [foodingJournalMenu_insert_input!]!) {
  insert_foodingJournalMenu(
    objects: $menusInput
    on_conflict: {constraint: foodingJournalMenu_menuId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
  insert_choice_foodingJournalMenu: insert_foodingJournalMenu(
    objects: $choicesInput
    on_conflict: {constraint: foodingJournalMenu_choiceId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
}
    `;
export const FoodingJournalMenuDeleteMenu = gql`
    mutation FoodingJournalMenuDeleteMenu($foodingJournalMenuId: uuid!) {
  delete_foodingJournalMenu(where: {id: {_eq: $foodingJournalMenuId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalMenusGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'name'>
        & { menuId: Types.Menu['id'] }
        & { shopMenus: Array<(
          { __typename?: 'shopMenu' }
          & { shop: (
            { __typename?: 'shop' }
            & Pick<Types.Shop, 'shopId' | 'name'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'name'>
            & { optionId: Types.Option['id'] }
            & { choices: Array<(
              { __typename?: 'choice' }
              & Pick<Types.Choice, 'name'>
              & { choiceId: Types.Choice['id'] }
              & { foodingJournalMenu?: Types.Maybe<(
                { __typename?: 'foodingJournalMenu' }
                & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
                & { foodingJournalDepartmentMaster: (
                  { __typename?: 'foodingJournalDepartmentMaster' }
                  & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
                ), foodingJournalGroupMaster: (
                  { __typename?: 'foodingJournalGroupMaster' }
                  & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
                ) }
              )> }
            )> }
          ) }
        )>, foodingJournalMenu?: Types.Maybe<(
          { __typename?: 'foodingJournalMenu' }
          & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
          & { foodingJournalDepartmentMaster: (
            { __typename?: 'foodingJournalDepartmentMaster' }
            & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
          ), foodingJournalGroupMaster: (
            { __typename?: 'foodingJournalGroupMaster' }
            & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type FoodingJournalMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type FoodingJournalMenusGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenusGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type FoodingJournalMenusGetMastersQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenusGetMastersQuery = (
  { __typename?: 'query_root' }
  & { foodingJournalDepartmentMaster: Array<(
    { __typename?: 'foodingJournalDepartmentMaster' }
    & Pick<Types.FoodingJournalDepartmentMaster, 'id' | 'code' | 'name'>
  )>, foodingJournalGroupMaster: Array<(
    { __typename?: 'foodingJournalGroupMaster' }
    & Pick<Types.FoodingJournalGroupMaster, 'id' | 'code' | 'name'>
  )> }
);

export type FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery = (
  { __typename?: 'query_root' }
  & { foodingJournalMenu: Array<(
    { __typename?: 'foodingJournalMenu' }
    & Pick<Types.FoodingJournalMenu, 'id' | 'name' | 'code'>
  )> }
);

export type FoodingJournalMenusUpsertMenusMutationVariables = Types.Exact<{
  menusInput: Array<Types.FoodingJournalMenuInsertInput> | Types.FoodingJournalMenuInsertInput;
  choicesInput: Array<Types.FoodingJournalMenuInsertInput> | Types.FoodingJournalMenuInsertInput;
}>;


export type FoodingJournalMenusUpsertMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalMenu?: Types.Maybe<{ __typename: 'foodingJournalMenu_mutation_response' }>, insert_choice_foodingJournalMenu?: Types.Maybe<{ __typename: 'foodingJournalMenu_mutation_response' }> }
);

export type FoodingJournalMenuDeleteMenuMutationVariables = Types.Exact<{
  foodingJournalMenuId: Types.Scalars['uuid'];
}>;


export type FoodingJournalMenuDeleteMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_foodingJournalMenu?: Types.Maybe<(
    { __typename?: 'foodingJournalMenu_mutation_response' }
    & Pick<Types.FoodingJournalMenuMutationResponse, 'affected_rows'>
  )> }
);


export const FoodingJournalMenusGetMenusDocument = gql`
    query FoodingJournalMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId: id
        name
        shopMenus(where: {shop: {archivedAt: {_is_null: true}}}) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId: id
            name
            choices {
              choiceId: id
              name
              foodingJournalMenu {
                id
                name
                code
                foodingJournalDepartmentMaster {
                  id
                  code
                  name
                }
                foodingJournalGroupMaster {
                  id
                  code
                  name
                }
              }
            }
          }
        }
        foodingJournalMenu {
          id
          name
          code
          foodingJournalDepartmentMaster {
            id
            code
            name
          }
          foodingJournalGroupMaster {
            id
            code
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFoodingJournalMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMenusGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMenusGetMenusQuery, FoodingJournalMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMenusGetMenusQuery, FoodingJournalMenusGetMenusQueryVariables>(FoodingJournalMenusGetMenusDocument, options);
      }
export function useFoodingJournalMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMenusGetMenusQuery, FoodingJournalMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMenusGetMenusQuery, FoodingJournalMenusGetMenusQueryVariables>(FoodingJournalMenusGetMenusDocument, options);
        }
export type FoodingJournalMenusGetMenusQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetMenusQuery>;
export type FoodingJournalMenusGetMenusLazyQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetMenusLazyQuery>;
export type FoodingJournalMenusGetMenusQueryResult = Apollo.QueryResult<FoodingJournalMenusGetMenusQuery, FoodingJournalMenusGetMenusQueryVariables>;
export const FoodingJournalMenusGetCategoriesDocument = gql`
    query FoodingJournalMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useFoodingJournalMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMenusGetCategoriesQuery, FoodingJournalMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMenusGetCategoriesQuery, FoodingJournalMenusGetCategoriesQueryVariables>(FoodingJournalMenusGetCategoriesDocument, options);
      }
export function useFoodingJournalMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMenusGetCategoriesQuery, FoodingJournalMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMenusGetCategoriesQuery, FoodingJournalMenusGetCategoriesQueryVariables>(FoodingJournalMenusGetCategoriesDocument, options);
        }
export type FoodingJournalMenusGetCategoriesQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetCategoriesQuery>;
export type FoodingJournalMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetCategoriesLazyQuery>;
export type FoodingJournalMenusGetCategoriesQueryResult = Apollo.QueryResult<FoodingJournalMenusGetCategoriesQuery, FoodingJournalMenusGetCategoriesQueryVariables>;
export const FoodingJournalMenusGetShopsDocument = gql`
    query FoodingJournalMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useFoodingJournalMenusGetShopsQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMenusGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMenusGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMenusGetShopsQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMenusGetShopsQuery, FoodingJournalMenusGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMenusGetShopsQuery, FoodingJournalMenusGetShopsQueryVariables>(FoodingJournalMenusGetShopsDocument, options);
      }
export function useFoodingJournalMenusGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMenusGetShopsQuery, FoodingJournalMenusGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMenusGetShopsQuery, FoodingJournalMenusGetShopsQueryVariables>(FoodingJournalMenusGetShopsDocument, options);
        }
export type FoodingJournalMenusGetShopsQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetShopsQuery>;
export type FoodingJournalMenusGetShopsLazyQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetShopsLazyQuery>;
export type FoodingJournalMenusGetShopsQueryResult = Apollo.QueryResult<FoodingJournalMenusGetShopsQuery, FoodingJournalMenusGetShopsQueryVariables>;
export const FoodingJournalMenusGetMastersDocument = gql`
    query FoodingJournalMenusGetMasters($companyId: uuid!) {
  foodingJournalDepartmentMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
  foodingJournalGroupMaster(where: {companyId: {_eq: $companyId}}) {
    id
    code
    name
  }
}
    `;

/**
 * __useFoodingJournalMenusGetMastersQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMenusGetMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusGetMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMenusGetMastersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMenusGetMastersQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMenusGetMastersQuery, FoodingJournalMenusGetMastersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMenusGetMastersQuery, FoodingJournalMenusGetMastersQueryVariables>(FoodingJournalMenusGetMastersDocument, options);
      }
export function useFoodingJournalMenusGetMastersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMenusGetMastersQuery, FoodingJournalMenusGetMastersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMenusGetMastersQuery, FoodingJournalMenusGetMastersQueryVariables>(FoodingJournalMenusGetMastersDocument, options);
        }
export type FoodingJournalMenusGetMastersQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetMastersQuery>;
export type FoodingJournalMenusGetMastersLazyQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetMastersLazyQuery>;
export type FoodingJournalMenusGetMastersQueryResult = Apollo.QueryResult<FoodingJournalMenusGetMastersQuery, FoodingJournalMenusGetMastersQueryVariables>;
export const FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansDocument = gql`
    query FoodingJournalMenusGetFoodingJournalMenusRelatedToPlans($companyId: uuid!) {
  foodingJournalMenu(
    where: {_and: [{companyId: {_eq: $companyId}}, {_or: [{planId: {_is_null: false}}, {planChoiceId: {_is_null: false}}]}]}
  ) {
    id
    name
    code
  }
}
    `;

/**
 * __useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery__
 *
 * To run a query within a React component, call `useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery, FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery, FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables>(FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansDocument, options);
      }
export function useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery, FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery, FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables>(FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansDocument, options);
        }
export type FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery>;
export type FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansLazyQueryHookResult = ReturnType<typeof useFoodingJournalMenusGetFoodingJournalMenusRelatedToPlansLazyQuery>;
export type FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryResult = Apollo.QueryResult<FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQuery, FoodingJournalMenusGetFoodingJournalMenusRelatedToPlansQueryVariables>;
export const FoodingJournalMenusUpsertMenusDocument = gql`
    mutation FoodingJournalMenusUpsertMenus($menusInput: [foodingJournalMenu_insert_input!]!, $choicesInput: [foodingJournalMenu_insert_input!]!) {
  insert_foodingJournalMenu(
    objects: $menusInput
    on_conflict: {constraint: foodingJournalMenu_menuId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
  insert_choice_foodingJournalMenu: insert_foodingJournalMenu(
    objects: $choicesInput
    on_conflict: {constraint: foodingJournalMenu_choiceId_key, update_columns: [code, name, foodingJournalDepartmentId, foodingJournalGroupId]}
  ) {
    __typename
  }
}
    `;
export type FoodingJournalMenusUpsertMenusMutationFn = Apollo.MutationFunction<FoodingJournalMenusUpsertMenusMutation, FoodingJournalMenusUpsertMenusMutationVariables>;

/**
 * __useFoodingJournalMenusUpsertMenusMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMenusUpsertMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenusUpsertMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMenusUpsertMenusMutation, { data, loading, error }] = useFoodingJournalMenusUpsertMenusMutation({
 *   variables: {
 *      menusInput: // value for 'menusInput'
 *      choicesInput: // value for 'choicesInput'
 *   },
 * });
 */
export function useFoodingJournalMenusUpsertMenusMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMenusUpsertMenusMutation, FoodingJournalMenusUpsertMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMenusUpsertMenusMutation, FoodingJournalMenusUpsertMenusMutationVariables>(FoodingJournalMenusUpsertMenusDocument, options);
      }
export type FoodingJournalMenusUpsertMenusMutationHookResult = ReturnType<typeof useFoodingJournalMenusUpsertMenusMutation>;
export type FoodingJournalMenusUpsertMenusMutationResult = Apollo.MutationResult<FoodingJournalMenusUpsertMenusMutation>;
export type FoodingJournalMenusUpsertMenusMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMenusUpsertMenusMutation, FoodingJournalMenusUpsertMenusMutationVariables>;
export const FoodingJournalMenuDeleteMenuDocument = gql`
    mutation FoodingJournalMenuDeleteMenu($foodingJournalMenuId: uuid!) {
  delete_foodingJournalMenu(where: {id: {_eq: $foodingJournalMenuId}}) {
    affected_rows
  }
}
    `;
export type FoodingJournalMenuDeleteMenuMutationFn = Apollo.MutationFunction<FoodingJournalMenuDeleteMenuMutation, FoodingJournalMenuDeleteMenuMutationVariables>;

/**
 * __useFoodingJournalMenuDeleteMenuMutation__
 *
 * To run a mutation, you first call `useFoodingJournalMenuDeleteMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalMenuDeleteMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalMenuDeleteMenuMutation, { data, loading, error }] = useFoodingJournalMenuDeleteMenuMutation({
 *   variables: {
 *      foodingJournalMenuId: // value for 'foodingJournalMenuId'
 *   },
 * });
 */
export function useFoodingJournalMenuDeleteMenuMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalMenuDeleteMenuMutation, FoodingJournalMenuDeleteMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalMenuDeleteMenuMutation, FoodingJournalMenuDeleteMenuMutationVariables>(FoodingJournalMenuDeleteMenuDocument, options);
      }
export type FoodingJournalMenuDeleteMenuMutationHookResult = ReturnType<typeof useFoodingJournalMenuDeleteMenuMutation>;
export type FoodingJournalMenuDeleteMenuMutationResult = Apollo.MutationResult<FoodingJournalMenuDeleteMenuMutation>;
export type FoodingJournalMenuDeleteMenuMutationOptions = Apollo.BaseMutationOptions<FoodingJournalMenuDeleteMenuMutation, FoodingJournalMenuDeleteMenuMutationVariables>;