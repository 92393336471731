import React, { memo } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Switch, SwitchProps } from "antd";

type Props = SwitchProps & { loading?: never; onChange(checked: boolean): Promise<void> };

/**
 * onChange に async function を渡し、その関数が実行中は loading を true にする
 */
export const AsyncSwitch = memo<Props>(({ onChange, ...props }) => {
  const [{ loading }, handleChange] = useAsyncFn(onChange, [onChange]);

  return <Switch {...props} onChange={handleChange} loading={loading} />;
});
