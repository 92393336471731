import React, { memo } from "react";
import { Button, Divider } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { Maybe } from "types/Maybe";

import { PikaichiConfig } from "../types";
import { usePikaichiConfigForm } from "../usePikaichiConfigForm";

import { EnabledField } from "./EnabledField";
import { FtpHostField } from "./FtpHostField";
import { FtpPasswordField } from "./FtpPasswordField";
import { FtpPortField } from "./FtpPortField";
import { FtpUserField } from "./FtpUserField";
import { PikaichiCompanyCdField } from "./PikaichiCompanyCdField";
import { PikaichiPosNumberField } from "./PikaichiPosNumberField";
import { PikaichiShopCdField } from "./PikaichiShopCdField";
import { UseKansaField } from "./UseKansaField";
import { UseNewariField } from "./UseNewariField";

type Props = {
  pikaichiConfig?: Maybe<PikaichiConfig>;
  onSubmit: Parameters<typeof usePikaichiConfigForm>[0]["onSubmit"];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loading: boolean;
};

export const EditPikaichiConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, pikaichiConfig }) => {
    const { form, initialValues, submit } = usePikaichiConfigForm({
      pikaichiConfig,
      onFormValidationError,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form name="pikaichiConfig" form={form} initialValues={initialValues} layout="vertical">
            <EnabledField />
            <PikaichiCompanyCdField />
            <PikaichiShopCdField />
            <PikaichiPosNumberField />
            <FtpHostField />
            <FtpPortField />
            <FtpUserField />
            <FtpPasswordField />
            <UseNewariField />
            <UseKansaField />
            <Divider />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
