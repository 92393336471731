import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditMenuPikaichiMenuFormItem } from "../useEditMenuPikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiMenuCdField = memo<Props>((props) => (
  <EditMenuPikaichiMenuFormItem
    label="メニューコード"
    name="pikaichiMenuCd"
    rules={[{ required: true, message: "メニューコードを入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditMenuPikaichiMenuFormItem>
));
