import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanWinboardPlanFormItem } from "../useEditPlanWinboardPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const BumonNameField = memo<Props>((props) => (
  <EditPlanWinboardPlanFormItem label="部門名" name="bumonName" {...props}>
    <Input />
  </EditPlanWinboardPlanFormItem>
));
