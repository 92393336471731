import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterMenusByName } from "models/menu";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import { MenuTable } from "./MenuTable";
import {
  usePikaichiMenusDeletePikaichiMenuMutation,
  usePikaichiMenusGetCategoriesQuery,
  usePikaichiMenusGetMenusQuery,
  usePikaichiMenusGetShopsQuery,
} from "./queries";
import { Menu } from "./types";

export const filterMenus = (menus: Menu[], { categoryIds, name, shopId }: FilterConditions) => {
  const filteredMenus = menus.filter(
    (menu) =>
      (categoryIds === undefined || categoryIds.includes(menu.category.categoryId)) &&
      (shopId === undefined || menu.shopMenus.map(({ shop }) => shop.shopId).includes(shopId)),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const PikaichiMenus = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
    error: getMenusDataError,
  } = usePikaichiMenusGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const categoryMenus = getMenusData?.category.flatMap(({ categoryMenus }) => categoryMenus) ?? [];
  const menus = categoryMenus.map(({ __typename: _, menu, ...categoryMenu }) => ({
    ...menu,
    ...categoryMenu,
  }));

  const { data: getCategoriesData, error: getCategoriesDataError } =
    usePikaichiMenusGetCategoriesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = usePikaichiMenusGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [deletePikaichiMenuMutation] = usePikaichiMenusDeletePikaichiMenuMutation();

  const onDelete = useCallback(
    async ({ pikaichiMenuId }: { pikaichiMenuId: string }) => {
      try {
        await deletePikaichiMenuMutation({
          variables: { pikaichiMenuId },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchMenus();
    },
    [deletePikaichiMenuMutation, refetchMenus],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const shouldShowAlert = getCategoriesDataError || getMenusDataError;

  return (
    <DashboardLayout title="メニュー一覧">
      <PageHeader
        title="メニュー一覧"
        footer={
          <MenuFilter
            shops={shops}
            categories={categories}
            hasFilterConditions={hasFilterConditions}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            clearFilterConditions={clearFilterConditions}
          />
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <MenuTable menus={filteredMenus} loading={loadingMenus} onDelete={onDelete} />
    </DashboardLayout>
  );
};
