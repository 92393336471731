import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { WinboardInflowSourceTag } from "types/graphql";

import { InflowSourceTag } from "../types";

export type EditWinboardInflowSourceTagFormValues = Pick<WinboardInflowSourceTag, "code">;

const getInitialValues = (inflowSourceTag: InflowSourceTag) => ({
  code: inflowSourceTag.winboardInflowSourceTag?.code,
});

export const EditWinboardInflowSourceTagFormItem =
  createFormItem<EditWinboardInflowSourceTagFormValues>();

export const useEditWinboardInflowSourceTagForm = ({
  onSubmit,
  inflowSourceTag,
}: {
  onSubmit: ({ code }: { code: string }) => void;
  inflowSourceTag: InflowSourceTag;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(inflowSourceTag);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditWinboardInflowSourceTagFormValues;
    onSubmit({ code: values.code });
  }, [form, onSubmit]);

  return { form, submit, initialValues };
};
