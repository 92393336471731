import React, { memo, useCallback } from "react";
import { Select } from "antd";
import { FormInstance } from "rc-field-form/lib/interface";

import { withFormDependencies } from "components/antd/Form";
import { ConnectGameConfigShop } from "pages/EditConnectGameConfig/types";

import { AddConnectGameConfigFormItem } from "../useAddConnectGameConfigForm";

const { Option } = Select;

type Props = {
  shops: { name: string; shopId: string }[];
  connectGameConfigShops: ConnectGameConfigShop[];
};

export const ShopsField = memo<Props>(({ shops, connectGameConfigShops }) => {
  const shopValidation = useCallback(
    (form: FormInstance) => {
      const isEnabled: boolean = form.getFieldValue("enabled");
      return {
        validator: async (_: unknown, value: string[]) => {
          if (!isEnabled) return;

          const existingEnabledShops = connectGameConfigShops.filter(({ shop }) =>
            value.includes(shop.id),
          );

          if (existingEnabledShops.length > 0) {
            throw new Error(
              `${existingEnabledShops
                .map(({ shop }) => shop.name)
                .join(", ")}には既に有効になっているミニゲーム設定があります`,
            );
          }
        },
      };
    },
    [connectGameConfigShops],
  );

  return (
    <AddConnectGameConfigFormItem
      label="適用店舗"
      name="shopIds"
      shouldUpdate={withFormDependencies(({ enabled }) => [enabled])}
      rules={[{ required: true, message: "適用店舗を選択してください" }, shopValidation]}
    >
      <Select<string> mode="multiple" placeholder="選択してください">
        {shops.map(({ shopId, name }) => (
          <Option key={shopId} value={shopId}>
            {name}
          </Option>
        ))}
      </Select>
    </AddConnectGameConfigFormItem>
  );
});
