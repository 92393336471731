import React, { memo } from "react";
import styled, { css } from "styled-components";

import { isUrlImage, isUrlMovie } from "libs/detectMediaType";
import { getCroppedUrl } from "libs/imgix";

type ImageProps = {
  width: number;
  height: number;
};

const Image = styled.img<ImageProps>`
  ${({ width }) =>
    css`
      width: ${width}px;
    `};
  ${({ height }) =>
    css`
      height: ${height}px;
    `};
  object-fit: cover;
`;

const Video = styled.video<ImageProps>`
  ${({ width }) =>
    css`
      width: ${width}px;
    `};
  ${({ height }) =>
    css`
      height: ${height}px;
    `};
  object-fit: cover;
`;

type Props = {
  url: string | null | undefined;
  width: number;
  height: number;
};

export const Thumbnail = memo<Props>(({ url: imageUrl, width, height }) => {
  const url = getCroppedUrl({ url: imageUrl, w: width, h: height, frame: 0 });
  if (!url) return null;
  if (isUrlImage(url)) return <Image src={url} width={width} height={height} />;
  if (isUrlMovie(url)) return <Video src={url} width={width} height={height} />;
  return null;
});
