import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = { name: string | number } & Omit<FormItemProps, "children" | "name">;

export const UrlField = memo<Props>(({ name, ...props }) => (
  <MessageDeliveryJobFormItem
    label={<FormHelp label="遷移先URL" help="画像をタップした際にこのURLに遷移します。" />}
    name={[name, "url"]}
    rules={[
      {
        validator: async (_, value) => {
          if (!value) return;

          const pattern = /^(https?|line):\/\/.+|tel:[0-9]+(-[0-9]+)*$/;
          if (!pattern.test(value)) {
            throw new Error("URL は http, https, line, または tel で始まる必要があります");
          }
        },
      },
    ]}
    {...props}
  >
    <Input />
  </MessageDeliveryJobFormItem>
));
