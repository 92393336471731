/**
 * Fileオブジェクトから画像に変換し、返却する
 */
export const loadImage = (file: File): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      URL.revokeObjectURL(image.src);
      resolve(image);
    };
    image.onerror = (e) => reject(e);
    image.src = URL.createObjectURL(file);
  });
