import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShowFreeItemInAccountingSlipField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="レシート・会計伝票に0円のメニュー・オプション・選択肢を表示する"
        help="この設定を有効にすると0円のメニュー・オプション・選択肢はレジでの会計対象として表示されます"
      />
    }
    name="showFreeItemInAccountingSlip"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
