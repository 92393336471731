import React, { memo } from "react";
import { Radio } from "antd";
import { QuestionnaireAnswerRadioOptions } from "models/questionnaireAnswer";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

export const QuestionnaireAnswerStatusFilter = memo(() => (
  <AddMessageDeliveryFormItem name="questionnaireAnswerStatusFilter">
    <Radio.Group options={QuestionnaireAnswerRadioOptions} />
  </AddMessageDeliveryFormItem>
));
