import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { Choice, TecAggregationMenu } from "../../types";

export type EditChoiceTecAggregationMenuFormValues = Pick<
  TecAggregationMenu,
  "name" | "menuCode" | "dpCode" | "dpName" | "gpCode" | "gpName"
> & { name: string };

export const EditChoiceTecAggregationMenuFormItem =
  createFormItem<EditChoiceTecAggregationMenuFormValues>();

export const useEditChoiceTecAggregationMenuForm = ({
  choice,
  tecAggregationMenu,
  onSubmit,
}: {
  choice?: Choice;
  tecAggregationMenu?: TecAggregationMenu;
  onSubmit: (
    args: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditChoiceTecAggregationMenuFormValues>(
    () => ({
      name: tecAggregationMenu?.name ?? choice?.name ?? "",
      menuCode: tecAggregationMenu?.menuCode ?? "",
      dpCode: tecAggregationMenu?.dpCode ?? "",
      dpName: tecAggregationMenu?.dpName ?? "",
      gpCode: tecAggregationMenu?.gpCode ?? "",
      gpName: tecAggregationMenu?.gpName ?? "",
    }),
    [choice, tecAggregationMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditChoiceTecAggregationMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
