import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Col } from "antd";
import { ColProps } from "antd/lib/col";
import { blue } from "@ant-design/colors";

import { InitialAvatar } from "components/InitialAvatar";

const StyledCol = styled(Col)`
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  padding: 8px;

  &:hover {
    color: ${blue[5]};
  }
`;

const TextSection = styled.span`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  height: 32px;
`;

const Name = styled.span<{ small: boolean }>`
  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
    `}
`;

const Text = styled.span`
  font-size: 8px;
`;

type Props = {
  name: string;
  text?: string;
} & ColProps;

export const InitialAvatarListItem = memo<Props>(({ name, text, ...props }) => (
  <StyledCol {...props}>
    <InitialAvatar>{name}</InitialAvatar>
    <TextSection>
      <Name small={Boolean(text)}>{name}</Name>
      {text && <Text>{text}</Text>}
    </TextSection>
  </StyledCol>
));
