import React, { memo } from "react";

import { AnswerListSection } from "components/QuestionnaireAnalyticsCommentModal/AnswerList/AnswerListSection";
import { useAnswerList } from "components/QuestionnaireAnalyticsCommentModal/AnswerList/useAnswerList";
import { QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery } from "components/QuestionnaireAnalyticsCommentModal/queries";

type Props = {
  answer: QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery["customerQuestionnaire_by_pk"];
};

export const AnswerList = memo<Props>(({ answer }) => {
  const {
    answerInfoColumns,
    attributeColumns,
    repeatabilityColumns,
    customerServiceColumns,
    deliciousnessColumns,
    speedColumn,
    cleanlinessColumns,
  } = useAnswerList(answer);

  return (
    <>
      <AnswerListSection title="アンケート情報" columns={answerInfoColumns} />
      <AnswerListSection title="属性" columns={attributeColumns} />
      <AnswerListSection title="再来店意欲" columns={repeatabilityColumns} />
      <AnswerListSection title="接客サービス" columns={customerServiceColumns} />
      <AnswerListSection title="料理" columns={deliciousnessColumns} />
      <AnswerListSection title="提供速度" columns={speedColumn} />
      <AnswerListSection title="清潔感" columns={cleanlinessColumns} />
    </>
  );
});
