import React, { memo } from "react";
import { Radio } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

export type Scope = "broadcast" | "customerSegment" | "customerList" | "customerListCsvUpload";

type Props = Omit<FormItemProps, "children" | "name">;

export const ScopeField = memo<Props>((props) => (
  <AddMessageDeliveryFormItem
    name="scope"
    label="配信対象"
    rules={[{ required: true, message: "配信対象を選択してください" }]}
    {...props}
  >
    <Radio.Group
      options={[
        { label: "すべてのお客様", value: "broadcast" },
        { label: "条件絞り込み", value: "customerSegment" },
        { label: "LINE ID アップロード", value: "customerListCsvUpload" },
        // { label: "LINE アカウント選択", value: "customerList" },
      ]}
      optionType="button"
    />
  </AddMessageDeliveryFormItem>
));
