import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { TablePrintingText } from "components/TablePrintingText";
import { useShop } from "hooks/useShop";

import { KitchenSlipTableTable } from "./KitchenSlipTableTable";
import {
  useKitchenSlipTablePrintingDeleteRoleTablesTableMutation,
  useKitchenSlipTablePrintingGetTablesQuery,
  useKitchenSlipTablePrintingInsertRoleTablesTableMutation,
} from "./queries";

export const KitchenSlipTablePrinting = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, loading, refetch, error } = useKitchenSlipTablePrintingGetTablesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tables = useMemo(() => data?.table ?? [], [data]);
  const roles = useMemo(() => data?.role ?? [], [data]);
  const isTablePrintingEnabled = tables.flatMap((table) => table.roleTablesTables).length > 0;

  const [deleteRoleTablesTable] = useKitchenSlipTablePrintingDeleteRoleTablesTableMutation();

  const [insertRoleTablesTable] = useKitchenSlipTablePrintingInsertRoleTablesTableMutation();

  const handleToggle = useCallback(
    async ({ tableId, roleId }: { tableId: number; roleId: number }) => {
      try {
        const table = tables.find((table) => table.tableId === tableId);
        const checked = table?.roleTablesTables.some(
          (roleTablesTable) => roleTablesTable.roleRoleId === roleId,
        );

        if (!table) throw new Error("no table found");
        if (!shopId) throw new Error("shopId is not available");

        if (checked) {
          await deleteRoleTablesTable({
            variables: { roleId, tableId },
          });
        } else {
          await insertRoleTablesTable({
            variables: { input: { roleRoleId: roleId, tableTableId: tableId, shopId } },
          });
        }

        message.success("設定を更新しました");
      } catch (e) {
        message.error("設定の更新に失敗しました");
      }
      await refetch();
    },
    [deleteRoleTablesTable, insertRoleTablesTable, shopId, tables, refetch],
  );

  return (
    <DashboardLayout title="キッチン伝票テーブル出し分け">
      <PageHeader
        title="キッチン伝票テーブル出し分け"
        footer={
          <>
            <ShopSelector />
            <div>
              テーブル出し分け: <TablePrintingText isEnabled={isTablePrintingEnabled} />
              <br />
              テーブル出し分けが無効の場合、テーブルによらずメニューが紐づくすべてのプリンターから印刷されます。
            </div>
          </>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <KitchenSlipTableTable
        tables={tables}
        loading={loading}
        roles={roles}
        onTogglePrintingConfig={handleToggle}
      />
    </DashboardLayout>
  );
};
