import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { Alert, Descriptions, message } from "antd";
import { red } from "@ant-design/colors";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { EditOptionTecAggregationOptionFormValues } from "./EditOptionTecAggregationOptionForm/useEditOptionTecAggregationOptionForm";
import { EditOptionTecAggregationOptionForm } from "./EditOptionTecAggregationOptionForm";
import {
  EditOptionTecAggregationOptionGetOptionDocument,
  useEditOptionTecAggregationOptionGetMenuCodeQuery,
  useEditOptionTecAggregationOptionGetOptionQuery,
  useEditOptionTecAggregationOptionUpdateChoicesMutation,
} from "./queries";

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const EditOptionTecAggregationOption = () => {
  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);
  const navigate = useNavigate();

  const [errorCodes, setErrorCodes] = useState<
    | {
        name: string;
        menuCode: string;
        dpName?: string | null;
        dpCode?: string | null;
        gpCode?: string | null;
        gpName?: string | null;
      }[]
    | null
  >(null);

  const {
    data: getOptionData,
    loading: loadingOption,
    error: errorGetOption,
  } = useEditOptionTecAggregationOptionGetOptionQuery(
    !Number.isNaN(optionId) ? { variables: { optionId } } : { skip: true },
  );
  const option = getOptionData?.option?.[0];

  const companyId = company?.id;

  const {
    data: getTecAggregationMenuCodesData,
    loading: loadingGetTecAggregationMenuCodes,
    error: errorGetTecAggregationMenuCodes,
  } = useEditOptionTecAggregationOptionGetMenuCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updateTecAggregationChoicesMutation, { loading: loadingUpdateTecAggregationChoices }] =
    useEditOptionTecAggregationOptionUpdateChoicesMutation();

  const onSubmit = useCallback(
    async (input: EditOptionTecAggregationOptionFormValues) => {
      if (!company) return;

      setErrorCodes(null);

      const tecAggregationChoicesDto: DashboardAccountUpsertTecAggregationMenuInput[] =
        input.option.choices.flatMap((choice) => {
          const id = choice.tecAggregationMenu?.id ?? uuidv4();

          const menu: DashboardAccountUpsertTecAggregationMenuInput = {
            id,
            _choiceId: choice.choiceId,
            companyId: company.id,
            name: choice.tecAggregationMenu?.name ?? "",
            menuCode: choice.tecAggregationMenu?.menuCode ?? "",
            dpCode: choice.tecAggregationMenu?.dpCode ?? "",
            dpName: choice.tecAggregationMenu?.dpName ?? "",
            gpCode: choice.tecAggregationMenu?.gpCode ?? "",
            gpName: choice.tecAggregationMenu?.gpName ?? "",
          };

          return menu;
        }) ?? [];

      try {
        const result = await updateTecAggregationChoicesMutation({
          variables: { tecAggregationChoices: { menus: tecAggregationChoicesDto } },
          refetchQueries: [
            {
              query: EditOptionTecAggregationOptionGetOptionDocument,
              variables: { optionId },
            },
          ],
        });

        if (
          result.data?.upsertTecAggregationMenusBulk.__typename ===
          "DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode"
        ) {
          setErrorCodes(
            result.data.upsertTecAggregationMenusBulk.dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode.map(
              (v) => v,
            ),
          );

          message.error("編集の保存に失敗しました");
          return;
        }

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, optionId, updateTecAggregationChoicesMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getTecAggregationMenuCodesData?.tecAggregationMenu ?? [])
      .map((c) => (!isNaN(Number(c.menuCode)) ? Number(c.menuCode) : null))
      .filter(isNotNull);
    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getTecAggregationMenuCodesData?.tecAggregationMenu]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingOption || loadingUpdateTecAggregationChoices || loadingGetTecAggregationMenuCodes;

  const shouldShowNetworkErrorAlert = Boolean(errorGetOption || errorGetTecAggregationMenuCodes);

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "連携オプション" }],
      }}
    >
      <OptionHeader option={option ?? null} onBack={goBack} />
      {shouldShowNetworkErrorAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {errorCodes && (
        <>
          <Alert
            message="以下のコード設定と競合しています"
            description={errorCodes.map((v) => (
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    {
                      label: "メニュー名",
                      children: v.name,
                      contentStyle: { backgroundColor: "white" },
                    },
                    {
                      label: "メニューコード",
                      children: v.menuCode,
                      contentStyle: { backgroundColor: "white" },
                    },
                    v.dpCode
                      ? {
                          label: "部門コード",
                          children: v.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.dpName
                      ? {
                          label: "部門名称",
                          children: v.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.gpCode
                      ? {
                          label: "分類コード",
                          children: v.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.gpName
                      ? {
                          label: "分類名称",
                          children: v.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            ))}
            type="error"
          />
          <Spacer size={10} />
        </>
      )}
      {option && (
        <EditOptionTecAggregationOptionForm
          option={option}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loading}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
