import React, { memo } from "react";
import { FormItemProps, Input } from "antd";
import { getTaxMethodName } from "models/taxMethod";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  costTaxMethod: string;
};

export const CostTaxMethodField = memo<Props>(({ costTaxMethod, ...props }) => (
  <EditPlanChoiceFormItem.NonProperty label="原価税種別" {...props}>
    {/* NOTE: 変更不可な仕様 */}
    <Input disabled={true} value={getTaxMethodName(costTaxMethod)} />
  </EditPlanChoiceFormItem.NonProperty>
));
