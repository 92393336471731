import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditRoleFormItem } from "../useEditRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;
export const PrintDishUpSlipAsPrimarySlipField = memo<Props>(({ ...props }) => (
  <EditRoleFormItem
    label={
      <FormHelp
        label="デシャップ伝票を本伝票レイアウトで印刷する"
        help="本伝票レイアウトでは、注文情報に加えて金額や合計会計などが表示されます。"
      />
    }
    name="printDishUpSlipAsPrimarySlip"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditRoleFormItem>
));
