import React from "react";
import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { Select } from "components/Input/Select";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = {
  name: number;
  couponId: string;
  coupons: {
    id: string;
    name: string;
  }[];
};

export const CouponSelectField = React.memo<Props>(({ name, couponId, coupons }) => (
  <MessageDeliveryJobFormItem name={[name, "couponId"]} endSpacer={null}>
    <Select
      placeholder="クーポンを選択してください"
      defaultValue={couponId}
      options={coupons.map(({ id, name }) => ({ label: name, value: id }))}
      showSearch
      optionFilterProp="label"
      allowClear
      fullWidth
      notFoundContent={
        <Paragraph type="secondary">
          選択できるクーポンが存在しません。
          <Typography.Link href="/coupon/add" target="_blank">
            クーポン作成画面
          </Typography.Link>
          にてクーポンを作成してください。
        </Paragraph>
      }
    />
  </MessageDeliveryJobFormItem>
));
