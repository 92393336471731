import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { insertWithRelation } from "util/type/insert-with-relation";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCorporation } from "hooks/useCorporation";
import { useQueryParams } from "hooks/useQuery";
import { AddDashboardAccountRoleForm } from "pages/AddDashboardAccountRole/AddDashboardAccountRoleForm";
import { DashboardAccountRoleFormValuesWithPermissions } from "pages/AddDashboardAccountRole/AddDashboardAccountRoleForm/useAddDashboardAccountRoleForm";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

import {
  useAddDashboardAccountRoleInsertDashboardAccountRoleMutation,
  useCopyDashboardAccountRoleGetDashboardAccountRoleQuery,
} from "./queries";

export const AddDashboardAccountRole = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const query = useQueryParams();
  const sourceDashboardAccountRoleId = query.get("id");

  const { data: sourceDashboardAccountRoleData } =
    useCopyDashboardAccountRoleGetDashboardAccountRoleQuery(
      sourceDashboardAccountRoleId
        ? {
            variables: {
              dashboardAccountRoleId: sourceDashboardAccountRoleId,
            },
          }
        : { skip: true },
    );
  const sourceDashboardAccountRole =
    sourceDashboardAccountRoleData?.dashboardAccountRole_by_pk ?? undefined;

  const [addDashboardAccountRoleMutation, { loading: loadingAddDashboardAccountRoleMutation }] =
    useAddDashboardAccountRoleInsertDashboardAccountRoleMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (formValues: DashboardAccountRoleFormValuesWithPermissions) => {
      if (!corporationId) return;

      try {
        const { name, permissions } = formValues;

        const dashboardAccountRolePermissions = Object.entries(permissions).flatMap(
          ([feature, type]) => {
            // Edit 権限は View 権限も追加する
            if (type === DashboardAccountRolePermissionTypeEnum.Edit) {
              return [
                { feature: feature as DashboardFeatureEnum, type },
                {
                  feature: feature as DashboardFeatureEnum,
                  type: DashboardAccountRolePermissionTypeEnum.View,
                },
              ];
            }
            if (type) {
              return [{ feature: feature as DashboardFeatureEnum, type }];
            }
            return [];
          },
        );

        await addDashboardAccountRoleMutation({
          variables: {
            input: insertWithRelation<["dashboardAccountRole", "dashboardAccountRolePermissions"]>({
              corporationId,
              name,
              dashboardAccountRolePermissions: { data: dashboardAccountRolePermissions },
            }),
          },
        });
        message.success("作成しました");
        goBack();
      } catch (err) {
        console.error(err);
        message.error("作成に失敗しました");
      }
    },
    [addDashboardAccountRoleMutation, corporationId, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="権限を新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "権限一覧" }],
      }}
    >
      {(!sourceDashboardAccountRoleId || sourceDashboardAccountRole) && (
        <AddDashboardAccountRoleForm
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={loadingAddDashboardAccountRoleMutation}
          sourceDashboardAccountRole={sourceDashboardAccountRole}
        />
      )}
    </DashboardLayout>
  );
};
