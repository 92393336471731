import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { AddOrderableTimeForm } from "pages/AddOrderableTime/AddOrderableTimeForm";
import { useAddOrderableTimeInsertOrderableTimeMutation } from "pages/AddOrderableTime/queries";
import { OrderableTimeInsertInput } from "types/graphql";

export const AddOrderableTime = () => {
  const navigate = useNavigate();

  const [addOrderableTimeMutation, { loading }] = useAddOrderableTimeInsertOrderableTimeMutation();

  const onSubmit = useCallback(
    async (input: OrderableTimeInsertInput) => {
      try {
        const { data } = await addOrderableTimeMutation({
          variables: { orderableTimes: [input] },
        });
        const orderableTimeId = data?.insert_orderableTime?.returning?.[0]?.id;

        if (orderableTimeId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/orderableTime/shop", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addOrderableTimeMutation, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="アプリ表示時間を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "アプリ表示時間" }],
      }}
    >
      <PageHeader title="アプリ表示時間を新規作成" onBack={goBack} />
      <AddOrderableTimeForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        onClose={goBack}
        loading={loading}
      />
    </DashboardLayout>
  );
};
