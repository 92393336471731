import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditGiftChoiceGetGiftChoice = gql`
    query EditGiftChoiceGetGiftChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    choiceId
    name
    optionId: _optionId
    price
  }
}
    `;
export const EditGiftChoiceUpdateGiftChoice = gql`
    mutation EditGiftChoiceUpdateGiftChoice($choiceId: Int!, $choice: choice_set_input!) {
  update_choice(_set: $choice, where: {choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
}
    `;
export type EditGiftChoiceGetGiftChoiceQueryVariables = Types.Exact<{
  choiceId: Types.Scalars['Int'];
}>;


export type EditGiftChoiceGetGiftChoiceQuery = (
  { __typename?: 'query_root' }
  & { choice: Array<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'choiceId' | 'name' | 'price'>
    & { optionId: Types.Choice['_optionId'] }
  )> }
);

export type EditGiftChoiceUpdateGiftChoiceMutationVariables = Types.Exact<{
  choiceId: Types.Scalars['Int'];
  choice: Types.ChoiceSetInput;
}>;


export type EditGiftChoiceUpdateGiftChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { update_choice?: Types.Maybe<(
    { __typename?: 'choice_mutation_response' }
    & Pick<Types.ChoiceMutationResponse, 'affected_rows'>
  )> }
);


export const EditGiftChoiceGetGiftChoiceDocument = gql`
    query EditGiftChoiceGetGiftChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    choiceId
    name
    optionId: _optionId
    price
  }
}
    `;

/**
 * __useEditGiftChoiceGetGiftChoiceQuery__
 *
 * To run a query within a React component, call `useEditGiftChoiceGetGiftChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGiftChoiceGetGiftChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGiftChoiceGetGiftChoiceQuery({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function useEditGiftChoiceGetGiftChoiceQuery(baseOptions: Apollo.QueryHookOptions<EditGiftChoiceGetGiftChoiceQuery, EditGiftChoiceGetGiftChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGiftChoiceGetGiftChoiceQuery, EditGiftChoiceGetGiftChoiceQueryVariables>(EditGiftChoiceGetGiftChoiceDocument, options);
      }
export function useEditGiftChoiceGetGiftChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGiftChoiceGetGiftChoiceQuery, EditGiftChoiceGetGiftChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGiftChoiceGetGiftChoiceQuery, EditGiftChoiceGetGiftChoiceQueryVariables>(EditGiftChoiceGetGiftChoiceDocument, options);
        }
export type EditGiftChoiceGetGiftChoiceQueryHookResult = ReturnType<typeof useEditGiftChoiceGetGiftChoiceQuery>;
export type EditGiftChoiceGetGiftChoiceLazyQueryHookResult = ReturnType<typeof useEditGiftChoiceGetGiftChoiceLazyQuery>;
export type EditGiftChoiceGetGiftChoiceQueryResult = Apollo.QueryResult<EditGiftChoiceGetGiftChoiceQuery, EditGiftChoiceGetGiftChoiceQueryVariables>;
export const EditGiftChoiceUpdateGiftChoiceDocument = gql`
    mutation EditGiftChoiceUpdateGiftChoice($choiceId: Int!, $choice: choice_set_input!) {
  update_choice(_set: $choice, where: {choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
}
    `;
export type EditGiftChoiceUpdateGiftChoiceMutationFn = Apollo.MutationFunction<EditGiftChoiceUpdateGiftChoiceMutation, EditGiftChoiceUpdateGiftChoiceMutationVariables>;

/**
 * __useEditGiftChoiceUpdateGiftChoiceMutation__
 *
 * To run a mutation, you first call `useEditGiftChoiceUpdateGiftChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGiftChoiceUpdateGiftChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGiftChoiceUpdateGiftChoiceMutation, { data, loading, error }] = useEditGiftChoiceUpdateGiftChoiceMutation({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *      choice: // value for 'choice'
 *   },
 * });
 */
export function useEditGiftChoiceUpdateGiftChoiceMutation(baseOptions?: Apollo.MutationHookOptions<EditGiftChoiceUpdateGiftChoiceMutation, EditGiftChoiceUpdateGiftChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGiftChoiceUpdateGiftChoiceMutation, EditGiftChoiceUpdateGiftChoiceMutationVariables>(EditGiftChoiceUpdateGiftChoiceDocument, options);
      }
export type EditGiftChoiceUpdateGiftChoiceMutationHookResult = ReturnType<typeof useEditGiftChoiceUpdateGiftChoiceMutation>;
export type EditGiftChoiceUpdateGiftChoiceMutationResult = Apollo.MutationResult<EditGiftChoiceUpdateGiftChoiceMutation>;
export type EditGiftChoiceUpdateGiftChoiceMutationOptions = Apollo.BaseMutationOptions<EditGiftChoiceUpdateGiftChoiceMutation, EditGiftChoiceUpdateGiftChoiceMutationVariables>;