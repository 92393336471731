import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import { getLastMonthAllTenantAverageScoreByAnswerSegments } from "models/questionnaireAnalytics";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import {
  FilterConditions,
  SerializedFilterConditions,
  ShopMetricsFilter,
} from "components/QuestionnaireAnalytics/ShopMetricsFilter";
import { ShopMetricsMenu } from "components/QuestionnaireAnalytics/ShopMetricsMenu";
import { FormContent } from "components/Template/FormTemplate";
import { viewport } from "constants/viewport";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import { MetricsPerCategory } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory";
import {
  useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery,
  useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery,
  useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery,
  useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery,
} from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/queries";
import { QuestionCategoryType } from "types/graphql";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 0 16px;
  }
`;

const StyledFormContent = styled(FormContent)`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 12px;
  }
`;

type CategoryParam =
  | "cleanliness"
  | "customerService"
  | "deliciousness"
  | "repeatability"
  | "speed";

const categoryParamToQuestionCategoryTypeMap = {
  attribute: QuestionCategoryType.Attribute,
  cleanliness: QuestionCategoryType.Cleanliness,
  customerService: QuestionCategoryType.CustomerService,
  deliciousness: QuestionCategoryType.Deliciousness,
  repeatability: QuestionCategoryType.Repeatability,
  speed: QuestionCategoryType.Speed,
} as const;

const QUESTIONNAIRE_CONFIG_NOT_FOUND_ERROR_MESSAGE = "アンケートの設定が見つかりませんでした";

export const QuestionnaireAnalyticsShopMetricsPerCategory = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const { shop: currentShop, setShop } = useShopPerCorporation();
  const currentShopId = currentShop?.shopId ?? "";

  const [isQuestionnaireConfigNotFoundError, setIsQuestionnaireConfigNotFoundError] =
    useState(false);

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const { category } = useParams<{ category: CategoryParam }>();

  const { data: getQuestionnaire } =
    useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery(
      corporationId ? { variables: { corporationId } } : { skip: true },
    );

  const questionnaireId = getQuestionnaire?.questionnaire[0]?.id;

  const { data: getShopsData, loading: getShopsLoading } =
    useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery(
      corporationId && questionnaireId
        ? {
            variables: { corporationId, questionnaireId },
            onCompleted: (data) => {
              // NOTE: アンケート設定を持つ店舗を選択するようにする
              const shopIds = data.corporation[0]?.companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .flatMap(({ shops }) =>
                  shops.sort((a, b) => a.name.localeCompare(b.name)).map(({ shopId }) => shopId),
                );
              if (!shopIds || shopIds.length === 0) return;
              if (shopIds.includes(currentShopId)) return;
              setShop(shopIds[0]);
            },
          }
        : { skip: true },
    );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const range = useMemo(
    () =>
      !filterConditions.range?.[0] || !filterConditions.range[1]
        ? null
        : {
            startAt: filterConditions.range[0].toISOString(),
            endAt: filterConditions.range[1].toISOString(),
          },

    [filterConditions.range],
  );

  const { data: getQuestionnaireAnswers } =
    useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery(
      questionnaireId && currentShopId && range
        ? {
            variables: {
              questionnaireId,
              shopId: currentShopId,
              ...range,
            },
          }
        : { skip: true },
    );

  const {
    data: getQuestionnaireMetrics,
    error: getQuestionnaireMetricsError,
    loading: getQuestionnaireMetricsLoading,
  } = useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery(
    corporationId && questionnaireId && currentShopId && category && range
      ? {
          variables: {
            input: {
              questionnaireId,
              corporationId,
              shopId: currentShopId,
              ...range,
              category: categoryParamToQuestionCategoryTypeMap[category],
              answererSegments: filterConditions.answerSegments,
            },
          },
          onCompleted: (_) => setIsQuestionnaireConfigNotFoundError(false),
          onError: (error) =>
            error.message === QUESTIONNAIRE_CONFIG_NOT_FOUND_ERROR_MESSAGE
              ? setIsQuestionnaireConfigNotFoundError(true)
              : null,
        }
      : { skip: true },
  );

  const lastMonthAllTenantAverageScore = useMemo(
    () => getLastMonthAllTenantAverageScoreByAnswerSegments(filterConditions.answerSegments ?? []),
    [filterConditions.answerSegments],
  );

  const isEmpty = !getQuestionnaireAnswers?.questionnaireAnswer.length;

  return (
    <DashboardLayout title="店舗詳細">
      <Container>
        <PageHeader
          title={<Title>店舗詳細</Title>}
          footer={
            <ShopMetricsFilter
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              companies={companies}
              loading={getShopsLoading}
            />
          }
        />

        {isQuestionnaireConfigNotFoundError && (
          <Alert
            message="アンケートが見つかりませんでした"
            type="error"
            description="アンケートの設定を行ってから再度お試しください"
          />
        )}

        {!isQuestionnaireConfigNotFoundError && getQuestionnaireMetricsError && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}

        <ShopMetricsMenu />

        <StyledFormContent>
          <MetricsPerCategory
            questionnaireMetricsPerCategory={
              getQuestionnaireMetrics?.questionnaireMetricsPerCategory
            }
            allTenantAverageScore={!category ? 0 : lastMonthAllTenantAverageScore[category] ?? 0}
            isLoading={!getQuestionnaireMetrics && getQuestionnaireMetricsLoading}
            isEmpty={isEmpty}
          />
        </StyledFormContent>
      </Container>
    </DashboardLayout>
  );
};
