import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { colors } from "constants/colors";
import { UnreachableError } from "libs/unreachable";
import { MessageDeliveryMessageTypeEnum } from "types/graphql";

import { MessageFormValue } from "../types";

import { LineOfficialAccountFragment } from "./queries";
import { RoomListPanel } from "./RoomListPanel";
import { RoomPanel } from "./RoomPanel";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding-top: 12px;
  background-color: ${colors.BackGround.Tertiary};
`;

const Tab = styled.div`
  display: flex;
`;

const TabItem = styled.button`
  width: 50%;
  padding: 0;
  background-color: transparent;
  border: none;
  line-height: 40px;

  & + & {
    border-left: 1px solid ${colors.Border.Default};
  }

  &[aria-selected="true"] {
    color: #1890ff;
    border-top: 1px solid ${colors.Border.Default};
  }

  &[aria-selected="false"] {
    cursor: pointer;
    border-bottom: 1px solid ${colors.Border.Default};
  }
`;

const TabPanel = styled.div`
  flex: 1;
`;

type TabKey = "list" | "item";

type Props = {
  messages: MessageFormValue[];
  lineOfficialAccount?: LineOfficialAccountFragment;
};

export const MessagePreviewContent = memo(
  ({
    lineOfficialAccount = {
      channelName: "{ACCOUNT NAME}",
      lineOfficialAccountId: -1,
      pictureUrl: undefined,
    },
    messages,
  }: Props) => {
    const [activeTab, setActiveTab] = useState<TabKey>("item");

    const messagesExceptEmptyContent = useMemo(
      () =>
        messages.filter((message) => {
          switch (message.type) {
            case MessageDeliveryMessageTypeEnum.Text:
              return Boolean(message.text);
            case MessageDeliveryMessageTypeEnum.Image:
              return Boolean(message.imageUrl);
            case MessageDeliveryMessageTypeEnum.Coupon:
              return Boolean(message.couponId);
            case MessageDeliveryMessageTypeEnum.Questionnaire:
              return Boolean(message.questionnaireConfigId);
            default:
              throw new UnreachableError(message);
          }
        }),
      [messages],
    );

    const handleKeydown = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        setActiveTab((c) => (c === "list" ? "item" : "list"));
      }
    }, []);

    return (
      <Wrapper>
        <Header>
          <Tab>
            <TabItem
              role="tab"
              aria-selected={activeTab === "item"}
              aria-controls="panel-item"
              id="tab-item"
              type="button"
              tabIndex={activeTab === "item" ? 0 : -1}
              onClick={() => setActiveTab("item")}
              onKeyDown={handleKeydown}
            >
              トーク画面
            </TabItem>

            <TabItem
              role="tab"
              aria-selected={activeTab === "list"}
              aria-controls="panel-list"
              id="tab-list"
              type="button"
              tabIndex={activeTab === "list" ? 0 : -1}
              onClick={() => setActiveTab("list")}
              onKeyDown={handleKeydown}
            >
              トーク一覧画面
            </TabItem>
          </Tab>
        </Header>

        <TabPanel
          id="panel-item"
          role="tabpanel"
          tabIndex={0}
          aria-labelledby="tab-item"
          hidden={activeTab !== "item"}
        >
          <RoomPanel
            messages={messagesExceptEmptyContent}
            lineOfficialAccount={lineOfficialAccount}
          />
        </TabPanel>

        <TabPanel
          id="panel-list"
          role="tabpanel"
          tabIndex={0}
          aria-labelledby="tab-list"
          hidden={activeTab !== "list"}
        >
          <RoomListPanel
            messages={messagesExceptEmptyContent}
            lineOfficialAccount={lineOfficialAccount}
          />
        </TabPanel>
      </Wrapper>
    );
  },
);
