import React, { useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Button } from "antd";
import dayjs from "dayjs";
import { getNextDeliverAt } from "models/messageDelivery";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MessageDeliveryMenu } from "components/Menu/MessageDeliveryMenu";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useErrorMessageDeliveryJob } from "hooks/useErrorMessageDeliveryJob";
import {
  useMessageDeliveriesArchiveMessageDeliveryMutation,
  useMessageDeliveriesGetMessageDeliveriesQuery,
  useMessageDeliveriesRefetchTargetCountMutation,
} from "pages/MessageDeliveries/queries";

import { MessageDeliveryConsumption } from "./MessageDeliveryConsumption";
import { MessageDeliveryTable } from "./MessageDeliveryTable";

export const MessageDeliveries = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { state } = useParams<{ state: "reserved" | "draft" | "suspended" }>();

  const {
    data: getMessageDeliveriesData,
    error: getMessageDeliveriesError,
    loading: loadingMessageDeliveries,
    refetch: refetchMessageDeliveries,
  } = useMessageDeliveriesGetMessageDeliveriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const { getHasUncheckedErrorMessageDeliveryShopJob } = useErrorMessageDeliveryJob();

  const messageDeliveries = useMemo(
    () =>
      [...(getMessageDeliveriesData?.messageDelivery ?? [])].sort((a, b) => {
        const now = dayjs();
        const aDeliverAt = getNextDeliverAt({ messageDelivery: a, now });
        const bDeliverAt = getNextDeliverAt({ messageDelivery: b, now });

        if (!aDeliverAt) return 1;
        if (!bDeliverAt) return -1;

        return bDeliverAt.unix() - aDeliverAt.unix();
      }),
    [getMessageDeliveriesData],
  );

  const filteredMessageDeliveries = useMemo(
    () =>
      messageDeliveries.filter(({ isDraft, repeatDeliveryTime, deliverAt, isSuspended }) => {
        if (isDraft) return state === "draft";
        if (isSuspended) return state === "suspended";

        // NOTE: 予約中タブでは、一回配信かつ配信日時が過ぎているメッセージ配信は無視する
        return state === "reserved" && (repeatDeliveryTime || dayjs().isBefore(dayjs(deliverAt)));
      }),
    [messageDeliveries, state],
  );

  const [archiveMutation] = useMessageDeliveriesArchiveMessageDeliveryMutation();

  const onArchive = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await archiveMutation({
          variables: { id, archivedAt: new Date().toISOString() },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchMessageDeliveries();
    },
    [archiveMutation, refetchMessageDeliveries],
  );

  const [refetchTargetCountMutation] = useMessageDeliveriesRefetchTargetCountMutation();

  const refetchTargetCount = useCallback(
    async ({ id }: { id: string }) => {
      await refetchTargetCountMutation({ variables: { id } });
      await refetchMessageDeliveries();
    },
    [refetchTargetCountMutation, refetchMessageDeliveries],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="メッセージ配信一覧">
      <PageHeader
        title="メッセージ配信一覧"
        extra={[
          canAccess("addMessageDelivery") && (
            <Link key="add" to="/messageDelivery/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      <MessageDeliveryConsumption />
      <SubTitle bottomSpacer={<Spacer size={16} />}>メッセージ一覧</SubTitle>
      <MessageDeliveryMenu showErrorIcon={getHasUncheckedErrorMessageDeliveryShopJob()} />
      {getMessageDeliveriesError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <MessageDeliveryTable
          isSuspended={state === "suspended"}
          messageDeliveries={filteredMessageDeliveries}
          loading={loadingMessageDeliveries}
          onArchive={onArchive}
          refetchTargetCount={refetchTargetCount}
        />
      </FormContent>
    </DashboardLayout>
  );
};
