import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const InflowSourceTagsGetInflowSourceTags = gql`
    query InflowSourceTagsGetInflowSourceTags($companyId: uuid!) {
  inflowSourceTag(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    displayName
    isWalkIn
  }
}
    `;
export const InflowSourceTagsArchive = gql`
    mutation InflowSourceTagsArchive($id: uuid!) {
  update_inflowSourceTag(where: {id: {_eq: $id}}, _set: {isArchived: true}) {
    returning {
      id
    }
  }
}
    `;
export type InflowSourceTagsGetInflowSourceTagsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type InflowSourceTagsGetInflowSourceTagsQuery = (
  { __typename?: 'query_root' }
  & { inflowSourceTag: Array<(
    { __typename?: 'inflowSourceTag' }
    & Pick<Types.InflowSourceTag, 'id' | 'displayName' | 'isWalkIn'>
  )> }
);

export type InflowSourceTagsArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type InflowSourceTagsArchiveMutation = (
  { __typename?: 'mutation_root' }
  & { update_inflowSourceTag?: Types.Maybe<(
    { __typename?: 'inflowSourceTag_mutation_response' }
    & { returning: Array<(
      { __typename?: 'inflowSourceTag' }
      & Pick<Types.InflowSourceTag, 'id'>
    )> }
  )> }
);


export const InflowSourceTagsGetInflowSourceTagsDocument = gql`
    query InflowSourceTagsGetInflowSourceTags($companyId: uuid!) {
  inflowSourceTag(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    displayName
    isWalkIn
  }
}
    `;

/**
 * __useInflowSourceTagsGetInflowSourceTagsQuery__
 *
 * To run a query within a React component, call `useInflowSourceTagsGetInflowSourceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInflowSourceTagsGetInflowSourceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInflowSourceTagsGetInflowSourceTagsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInflowSourceTagsGetInflowSourceTagsQuery(baseOptions: Apollo.QueryHookOptions<InflowSourceTagsGetInflowSourceTagsQuery, InflowSourceTagsGetInflowSourceTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InflowSourceTagsGetInflowSourceTagsQuery, InflowSourceTagsGetInflowSourceTagsQueryVariables>(InflowSourceTagsGetInflowSourceTagsDocument, options);
      }
export function useInflowSourceTagsGetInflowSourceTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InflowSourceTagsGetInflowSourceTagsQuery, InflowSourceTagsGetInflowSourceTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InflowSourceTagsGetInflowSourceTagsQuery, InflowSourceTagsGetInflowSourceTagsQueryVariables>(InflowSourceTagsGetInflowSourceTagsDocument, options);
        }
export type InflowSourceTagsGetInflowSourceTagsQueryHookResult = ReturnType<typeof useInflowSourceTagsGetInflowSourceTagsQuery>;
export type InflowSourceTagsGetInflowSourceTagsLazyQueryHookResult = ReturnType<typeof useInflowSourceTagsGetInflowSourceTagsLazyQuery>;
export type InflowSourceTagsGetInflowSourceTagsQueryResult = Apollo.QueryResult<InflowSourceTagsGetInflowSourceTagsQuery, InflowSourceTagsGetInflowSourceTagsQueryVariables>;
export const InflowSourceTagsArchiveDocument = gql`
    mutation InflowSourceTagsArchive($id: uuid!) {
  update_inflowSourceTag(where: {id: {_eq: $id}}, _set: {isArchived: true}) {
    returning {
      id
    }
  }
}
    `;
export type InflowSourceTagsArchiveMutationFn = Apollo.MutationFunction<InflowSourceTagsArchiveMutation, InflowSourceTagsArchiveMutationVariables>;

/**
 * __useInflowSourceTagsArchiveMutation__
 *
 * To run a mutation, you first call `useInflowSourceTagsArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInflowSourceTagsArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inflowSourceTagsArchiveMutation, { data, loading, error }] = useInflowSourceTagsArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInflowSourceTagsArchiveMutation(baseOptions?: Apollo.MutationHookOptions<InflowSourceTagsArchiveMutation, InflowSourceTagsArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InflowSourceTagsArchiveMutation, InflowSourceTagsArchiveMutationVariables>(InflowSourceTagsArchiveDocument, options);
      }
export type InflowSourceTagsArchiveMutationHookResult = ReturnType<typeof useInflowSourceTagsArchiveMutation>;
export type InflowSourceTagsArchiveMutationResult = Apollo.MutationResult<InflowSourceTagsArchiveMutation>;
export type InflowSourceTagsArchiveMutationOptions = Apollo.BaseMutationOptions<InflowSourceTagsArchiveMutation, InflowSourceTagsArchiveMutationVariables>;