import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { PlanGroupTable } from "pages/PlanGroups/PlanGroupTable";
import { usePlanGroupsGetPlanGroupsQuery } from "pages/PlanGroups/queries";

export const PlanGroups = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getPlanGroupsData,
    loading: loadingPlanGroups,
    error,
  } = usePlanGroupsGetPlanGroupsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const planGroups = getPlanGroupsData?.planGroup ?? [];

  return (
    <DashboardLayout title="プラングループ一覧">
      <PageHeader
        title="プラングループ一覧"
        extra={[
          <Link key="add" to="/planGroup/add">
            <Button type="primary">新規作成</Button>
          </Link>,
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <PlanGroupTable planGroups={planGroups} loading={loadingPlanGroups} />
    </DashboardLayout>
  );
};
