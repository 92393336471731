import React, { memo, useCallback, useMemo } from "react";
import { Popconfirm, Popover, Tag, Typography } from "antd";
import { ExclamationOutlined } from "@ant-design/icons";
import { getTypeName } from "models/printerSound";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { OnlinePaymentPrinterRoleMeta, Role } from "pages/Roles/types";

type DataSource = Role & { shouldPrintOnlinePaymentSlip: boolean };

type Props = {
  loading?: boolean;
  roles: Role[];
  onlinePaymentPrinterRoleMetas: OnlinePaymentPrinterRoleMeta[];
  onDelete: (roleId: string) => void;
};

export const RoleTable = memo<Props>(
  ({ loading, roles, onlinePaymentPrinterRoleMetas, onDelete }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const getRoleRelations = useCallback(
      (roleId: string) => {
        const spareRoles = roles.filter(
          (role) => role.firstSpareRole?.id === roleId || role.secondSpareRole?.id === roleId,
        );
        const role = roles.filter((role) => role.id === roleId)?.[0];
        const menus =
          role?.shopMenuKitchenRoles.map((shopMenuKitchenRole) => shopMenuKitchenRole.menu) ?? [];
        const dishUpSlipGroups =
          role?.dishUpSlipGroupRoles.map(
            (dishUpSlipGroupRole) => dishUpSlipGroupRole.dishUpSlipGroup,
          ) ?? [];
        const kdDisplayTargets = role?.kdDisplayTargets ?? [];

        return { spareRoles, menus, dishUpSlipGroups, kdDisplayTargets };
      },
      [roles],
    );

    const roleIdToOnlinePaymentPrinterRoleMetaMap = useMemo(
      () =>
        new Map(
          onlinePaymentPrinterRoleMetas.map((onlinePaymentPrinterRoleMeta) => [
            onlinePaymentPrinterRoleMeta.printerRoleId,
            onlinePaymentPrinterRoleMeta,
          ]),
        ),
      [onlinePaymentPrinterRoleMetas],
    );

    const dataSource = useMemo<DataSource[]>(
      () =>
        roles.map((role) => ({
          ...role,
          shouldPrintOnlinePaymentSlip: Boolean(
            roleIdToOnlinePaymentPrinterRoleMetaMap.get(role.id),
          ),
        })),
      [roleIdToOnlinePaymentPrinterRoleMetaMap, roles],
    );

    const columns = [
      {
        title: "キッチンプリンター名",
        width: 170,
        dataIndex: "name",
      },
      {
        title: "オンライン決済通知",
        align: "center",
        width: 160,
        render(_: string, { shouldPrintOnlinePaymentSlip }: DataSource) {
          return shouldPrintOnlinePaymentSlip ? <Tag color="blue">する</Tag> : <Tag>しない</Tag>;
        },
      } as const,
      {
        title: "呼出伝票印刷",
        align: "center",
        width: 160,
        render(_: string, { shouldPrintClerkCallSlip }: DataSource) {
          return shouldPrintClerkCallSlip ? <Tag color="blue">する</Tag> : <Tag>しない</Tag>;
        },
      } as const,
      {
        title: "自動会計伝票印刷",
        align: "center",
        width: 160,
        render(_: string, { shouldPrintPaymentReceipt }: DataSource) {
          return shouldPrintPaymentReceipt ? <Tag color="blue">する</Tag> : <Tag>しない</Tag>;
        },
      } as const,
      {
        title: "デシャップ伝票印刷",
        align: "center",
        width: 160,
        render(_: string, { shouldPrintDishUpSlip }: DataSource) {
          return shouldPrintDishUpSlip ? <Tag color="blue">する</Tag> : <Tag>しない</Tag>;
        },
      } as const,
      {
        title: "デシャップレイアウト",
        align: "center",
        width: 180,
        render(_: string, { printDishUpSlipAsPrimarySlip }: DataSource) {
          return printDishUpSlipAsPrimarySlip ? <Tag>本伝票</Tag> : <Tag>デシャップ</Tag>;
        },
      } as const,
      {
        title: "印刷音",
        align: "center",
        width: 80,
        render(_: string, { printerSound }: DataSource) {
          return getTypeName(printerSound);
        },
      } as const,
      {
        title: "緊急時自動振替先プリンタ1",
        align: "center",
        width: 220,
        render(_: string, { firstSpareRole }: DataSource) {
          return firstSpareRole?.name ?? "なし";
        },
      } as const,
      {
        title: "緊急時自動振替先プリンタ2",
        align: "center",
        width: 220,
        render(_: string, { secondSpareRole }: DataSource) {
          return secondSpareRole?.name ?? "なし";
        },
      } as const,
      ...(canAccess("editRole")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { id }: DataSource) {
                return (
                  <IconLink to={`/role/${id}/edit`}>
                    <EditIcon />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deleteRole")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { id, name }: DataSource) {
                const { spareRoles, menus, dishUpSlipGroups, kdDisplayTargets } =
                  getRoleRelations(id);
                if (spareRoles.length > 0) {
                  return (
                    <Popover
                      title="自動振替先プリンタの設定を削除してから削除してください"
                      content={
                        <Typography.Paragraph>
                          {spareRoles.map((role) => `「${role.name}」`)}
                          の緊急時自動振替先プリンタに設定されているため削除できません
                        </Typography.Paragraph>
                      }
                    >
                      <DeleteIcon id={`category:${id}:delete`} disabled={true} />
                    </Popover>
                  );
                }

                if (menus.length > 0) {
                  return (
                    <Popover
                      title="メニューとの紐づけを解除してから削除してください"
                      content={
                        <Typography.Paragraph>
                          {`「${menus[0]?.name}」${menus.length > 1 ? "等" : ""}`}
                          の提供伝票印刷プリンターに設定されているため削除できません
                        </Typography.Paragraph>
                      }
                    >
                      <DeleteIcon id={`category:${id}:delete`} disabled={true} />
                    </Popover>
                  );
                }

                if (dishUpSlipGroups.length > 0) {
                  return (
                    <Popover
                      title="デシャップ伝票グループとの紐づけを解除してから削除してください"
                      content={
                        <Typography.Paragraph>
                          {dishUpSlipGroups.map((group) => `「${group?.name}」`)}
                          のロールに設定されているため削除できません`
                        </Typography.Paragraph>
                      }
                    >
                      <DeleteIcon id={`category:${id}:delete`} disabled={true} />
                    </Popover>
                  );
                }

                if (kdDisplayTargets.length > 0) {
                  return (
                    <Popover
                      title="キッチンディスプレイとの紐づけを解除してから削除してください"
                      content={
                        <Typography.Paragraph>
                          {kdDisplayTargets.map((target) => `「${target.name}」`)}
                          の提供伝票印刷プリンターに設定されているため削除できません
                        </Typography.Paragraph>
                      }
                    >
                      <DeleteIcon id={`category:${id}:delete`} disabled={true} />
                    </Popover>
                  );
                }

                return (
                  <Popconfirm
                    title={`${name}を削除しますか？一度削除したキッチンプリンターを元に戻すことはできません`}
                    icon={<ExclamationOutlined />}
                    onConfirm={() => onDelete(id)}
                  >
                    <DeleteIcon data-cy={`delete-${name}`} />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ];

    return (
      <Table
        rowKey="roleId"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
