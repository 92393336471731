import React, { useCallback, useMemo, useState } from "react";
import { Badge, Button, Form, Popconfirm, Select, Table as AntdTable, Typography } from "antd";
import { isEqual, omit } from "lodash";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { FormActions } from "components/Form/FormActions";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { ConfirmationModal } from "../ConfirmationModal";
import { OnSitePaymentDiscountType } from "../types";

const { Paragraph } = Typography;

export const foodingJournalPaymentTypes: { discountType: number; name: string }[] = [
  {
    discountType: 0,
    name: "値引",
  },
  {
    discountType: 1,
    name: "割引",
  },
  {
    discountType: 2,
    name: "サービス券",
  },
];

export type FoodingJournalOnSitePaymentDiscountTypeSource = {
  discountType: string | undefined;
  code: string | undefined;
  name: string | undefined;
};

export type NonNullableFoodingJournalOnSitePaymentDiscountTypeSource = {
  discountType: string;
  code: string;
  name: string;
};

type RowItem = {
  id: string;
  label: string;
  foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
  discountType: string | undefined;
  code: string | undefined;
  name: string | undefined;
};

type Props = {
  loading: boolean;
  onSitePaymentDiscountTypes: OnSitePaymentDiscountType[];
  refetchTypes: () => Promise<void>;
  upsertFoodingJournalOnSitePaymentDiscountTypes: (args: {
    types: {
      onSitePaymentDiscountTypeId: string;
      foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
      discountType: number;
      code: string;
      name: string;
    }[];
  }) => Promise<void>;
  deleteFoodingJournalOnSitePaymentDiscountType: (args: {
    foodingJournalOnSitePaymentDiscountTypeId: string;
  }) => Promise<void>;
};

const isEditingValueEmpty = ({
  discountType,
  code,
  name,
}: FoodingJournalOnSitePaymentDiscountTypeSource) => !code && !name && !discountType;

const isCodesValid = <T extends FoodingJournalOnSitePaymentDiscountTypeSource>(
  rows: T[],
): rows is (T & NonNullableFoodingJournalOnSitePaymentDiscountTypeSource)[] =>
  rows.every((row) => Boolean(row.code && row.discountType && row.name));

const { Column } = AntdTable;

export const OnSitePaymentDiscountTypeTable = React.memo<Props>(
  ({
    loading,
    onSitePaymentDiscountTypes,
    refetchTypes,
    upsertFoodingJournalOnSitePaymentDiscountTypes,
    deleteFoodingJournalOnSitePaymentDiscountType,
  }) => {
    const [form] = Form.useForm();
    const [pagination, setPagination] = usePagination();

    const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

    const [editingOnSitePaymentDiscountTypes, setEditingOnSitePaymentDiscountTypes] = useState<
      Record<
        string,
        FoodingJournalOnSitePaymentDiscountTypeSource & {
          foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
        }
      >
    >({});

    const dataSources = useMemo<RowItem[]>(
      () =>
        onSitePaymentDiscountTypes.map((detail) => ({
          id: detail.id,
          label: detail.label,
          foodingJournalOnSitePaymentDiscountTypeId:
            detail.foodingJournalOnSitePaymentDiscountType?.id,
          discountType: detail.foodingJournalOnSitePaymentDiscountType?.discountType.toString(),
          code: detail.foodingJournalOnSitePaymentDiscountType?.code,
          name: detail.foodingJournalOnSitePaymentDiscountType?.name,
        })),
      [onSitePaymentDiscountTypes],
    );

    const openModal = useCallback(() => setShouldShowConfirmationModal(true), []);

    const closeModal = useCallback(() => setShouldShowConfirmationModal(false), []);

    const handleChange = useCallback(
      ({ rowItem }: { rowItem: RowItem }) => {
        const editingValue = (
          form.getFieldsValue() as Record<string, FoodingJournalOnSitePaymentDiscountTypeSource>
        )[rowItem.id];

        if (!editingValue) return;

        const mergedValue = {
          foodingJournalOnSitePaymentDiscountTypeId:
            rowItem.foodingJournalOnSitePaymentDiscountTypeId,
          discountType: editingValue.discountType ?? rowItem.discountType,
          code: editingValue.code ?? rowItem.code,
          name: editingValue.name ?? rowItem.name,
        };

        if (
          isEditingValueEmpty(editingValue) ||
          isEqual(mergedValue, {
            foodingJournalOnSitePaymentDiscountTypeId:
              rowItem.foodingJournalOnSitePaymentDiscountTypeId,
            discountType: rowItem.discountType,
            code: rowItem.code,
            name: rowItem.name,
          })
        ) {
          return setEditingOnSitePaymentDiscountTypes((m) => omit(m, rowItem.id));
        }

        setEditingOnSitePaymentDiscountTypes((m) => ({ ...m, [rowItem.id]: mergedValue }));

        form.setFieldValue(rowItem.id, mergedValue);
      },
      [form],
    );

    const handleConfirm = useCallback(async () => {
      try {
        await form.validateFields(
          Object.entries(editingOnSitePaymentDiscountTypes).map(([id]) => [id]),
          { recursive: true },
        );
      } catch {
        return;
      }

      openModal();
    }, [editingOnSitePaymentDiscountTypes, form, openModal]);

    const handleClear = useCallback(() => {
      setEditingOnSitePaymentDiscountTypes({});

      form.resetFields();
    }, [form]);

    const handleSubmit = useCallback(async () => {
      const types = Object.entries(editingOnSitePaymentDiscountTypes).map(([id, type]) => ({
        onSitePaymentDiscountTypeId: id,
        foodingJournalOnSitePaymentDiscountTypeId: type.foodingJournalOnSitePaymentDiscountTypeId,
        discountType: type.discountType,
        code: type.code,
        name: type.name,
      }));

      if (!isCodesValid(types)) return;

      await upsertFoodingJournalOnSitePaymentDiscountTypes({
        types: types.map((type) => ({ ...type, discountType: Number(type.discountType) })),
      });

      await refetchTypes();

      form.resetFields();

      setEditingOnSitePaymentDiscountTypes({});

      closeModal();
    }, [
      closeModal,
      editingOnSitePaymentDiscountTypes,
      form,
      refetchTypes,
      upsertFoodingJournalOnSitePaymentDiscountTypes,
    ]);

    const handleDelete = useCallback(
      async ({ rowItem }: { rowItem: RowItem }) => {
        if (rowItem.foodingJournalOnSitePaymentDiscountTypeId) {
          await deleteFoodingJournalOnSitePaymentDiscountType({
            foodingJournalOnSitePaymentDiscountTypeId:
              rowItem.foodingJournalOnSitePaymentDiscountTypeId,
          });
          await refetchTypes();
        }

        setEditingOnSitePaymentDiscountTypes((m) => omit(m, rowItem.id));

        form.resetFields([
          [rowItem.id, "discountType"],
          [rowItem.id, "code"],
          [rowItem.id, "name"],
        ]);
      },
      [deleteFoodingJournalOnSitePaymentDiscountType, form, refetchTypes],
    );

    return (
      <>
        <Form form={form} component={false}>
          <Table
            rowKey={(rowItem: RowItem) => rowItem.id}
            dataSource={dataSources}
            loading={loading}
            bordered
            pagination={pagination}
            onChange={({ position: _, ...pagination }) => setPagination(pagination)}
            footer={() => (
              <>
                <FormActions>
                  <Button onClick={handleClear}>クリア</Button>

                  <Badge count={Object.keys(editingOnSitePaymentDiscountTypes).length}>
                    <Button
                      type="primary"
                      disabled={Object.keys(editingOnSitePaymentDiscountTypes).length === 0}
                      onClick={handleConfirm}
                    >
                      確認
                    </Button>
                  </Badge>
                </FormActions>
              </>
            )}
          >
            <Column dataIndex="label" title="割引・値引種別" width={200} />

            <Column
              dataIndex="discountType"
              title="値引き種別"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "discountType"]}
                  rules={[
                    {
                      required: true,
                      message: "支払種別を選択してください",
                    },
                  ]}
                >
                  <Select<string>
                    defaultValue={rowItem.discountType}
                    placeholder="値引き種別"
                    onSelect={() => handleChange({ rowItem })}
                  >
                    {foodingJournalPaymentTypes.map(({ discountType, name }) => (
                      <Select.Option key={discountType} value={discountType.toString()}>
                        {`${discountType}: ${name}`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            />

            <Column
              dataIndex="code"
              title="値引きコード"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "code"]}
                  rules={[
                    {
                      required: true,
                      min: 1,
                      max: 3,
                      message: `3桁以下の数値を入力してください`,
                    },
                  ]}
                >
                  <InputCode
                    defaultValue={rowItem.code}
                    placeholder="値引きコード"
                    onBlur={() => handleChange({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              dataIndex="name"
              title="値引き名称"
              align="left"
              render={(_: string, rowItem: RowItem) => (
                <FormItem
                  style={{ width: 300 }}
                  key={rowItem.id}
                  name={[rowItem.id, "name"]}
                  rules={[
                    {
                      required: true,
                      min: 1,
                      max: 20,
                      message: `20桁以下の文字列を入力してください`,
                    },
                  ]}
                >
                  <InputCode
                    defaultValue={rowItem.name}
                    placeholder="値引き名称"
                    onBlur={() => handleChange({ rowItem })}
                  />
                </FormItem>
              )}
            />

            <Column
              title=""
              align="left"
              width="50"
              render={(_: string, rowItem: RowItem) => (
                <>
                  {rowItem.foodingJournalOnSitePaymentDiscountTypeId ? (
                    <Popconfirm
                      title={
                        <>
                          <Paragraph>設定を削除しますか？</Paragraph>
                          <Paragraph>一度削除した設定を元に戻すことはできません。</Paragraph>
                        </>
                      }
                      okText="はい"
                      cancelText="キャンセル"
                      onConfirm={() => {
                        handleDelete({ rowItem });
                      }}
                    >
                      <DeleteIcon />
                    </Popconfirm>
                  ) : (
                    <DeleteIcon onClick={() => handleDelete({ rowItem })} />
                  )}
                </>
              )}
            />
          </Table>
        </Form>

        {shouldShowConfirmationModal && (
          <ConfirmationModal
            editingOnSitePaymentDiscountTypes={editingOnSitePaymentDiscountTypes}
            onSitePaymentDiscountTypes={onSitePaymentDiscountTypes}
            loading={loading}
            onSubmit={handleSubmit}
            onCancel={closeModal}
          />
        )}
      </>
    );
  },
);
