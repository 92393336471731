import React, { memo } from "react";

import { AsyncSwitch } from "components/AsyncSwitch";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Role, Table as TableType } from "../type";

type Props = {
  loading?: boolean;
  tables: TableType[];
  roles: Role[];
  onTogglePrintingConfig(args: { roleId: number; tableId: number }): Promise<void>;
};

export const KitchenSlipTableTable = memo<Props>(
  ({ loading, tables, roles, onTogglePrintingConfig }) => {
    const [pagination, setPagination] = usePagination();

    const columns = [
      { title: "テーブル名", dataIndex: "name", fixed: "left" as const, width: 100 },
      ...roles.map((role) => ({
        title: role.name,
        width: 130,
        render(_: string, table: TableType) {
          const checked = table.roleTablesTables.some(
            ({ roleRoleId }) => roleRoleId === role.roleId,
          );

          const handleChange = async () => {
            await onTogglePrintingConfig({ roleId: role.roleId, tableId: table.tableId });
          };

          return (
            <AsyncSwitch
              data-cy={`toggle-${role.name}`}
              checked={checked}
              onChange={handleChange}
            />
          );
        },
      })),
    ];

    return (
      <Table
        rowKey="tableId"
        columns={columns}
        dataSource={tables}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
