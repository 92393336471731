import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanPikaichiPlanFormItem } from "../useEditPlanPikaichiPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiBumonCdField = memo<Props>((props) => (
  <EditPlanPikaichiPlanFormItem label="部門コード" name="pikaichiBumonCd" {...props}>
    <InputCode />
  </EditPlanPikaichiPlanFormItem>
));
