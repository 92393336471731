import React, { FC } from "react";
import styled from "styled-components";
import { Alert, Collapse } from "antd";
import { stringify } from "query-string";
import { getSelfWebUrl } from "util/selfWeb";

import { QrCodeCard } from "components/QrCodeCard";

const { Panel } = Collapse;

const DeepLinkMapForConnect = {
  redirectWeb: {
    checkIn({ liffId, shopId }: { liffId: string; shopId: string }) {
      const queryParams = { liffId, shopId };
      return getSelfWebUrl(`crm/gateway/check_in?${stringify(queryParams)}`);
    },
    checkInAndLottery({ liffId, shopId }: { liffId: string; shopId: string }) {
      const queryParams = { liffId, shopId, redirectTo: "/crm/lottery" };
      return getSelfWebUrl(`crm/gateway/check_in?${stringify(queryParams)}`);
    },
    richMenu({ liffId, shopId }: { liffId: string; shopId: string }) {
      const queryParams = { liffId, shopId };
      return getSelfWebUrl(`crm/gateway?${stringify(queryParams)}`);
    },
    richMenuUnderCompany({ liffId, _companyId }: { liffId: string; _companyId: number }) {
      const queryParams = { liffId, companyId: _companyId, redirectTo: "/crm/home" };
      return getSelfWebUrl(`crm/gateway?${stringify(queryParams)}`);
    },
  },
};

const DeepLinkMapForConnectWithMO = {
  redirectWeb: {
    richMenu({ liffId, shopId }: { liffId: string; shopId: string }) {
      const queryParams = { liffId, shopId, redirectTo: "/crm/home" };
      return getSelfWebUrl(`gateway?${stringify(queryParams)}`);
    },
    richMenuUnderCompany({ liffId, _companyId }: { liffId: string; _companyId: number }) {
      const queryParams = { liffId, companyId: _companyId, redirectTo: "/crm/home" };
      return getSelfWebUrl(`crm/gateway?${stringify(queryParams)}`);
    },
  },
};

const StyledAlert = styled(Alert)`
  margin: 8px;
`;

type Props = {
  liffId?: string;
  shopId?: string;
  _companyId?: number;
};

export const ConnectQrCodePanel: FC<Props> = ({ liffId, shopId, _companyId }) => {
  const collapseItems = [
    {
      key: "CRM QR",
      label: "CRM QR",
      children:
        liffId && shopId && _companyId ? (
          <>
            <QrCodeCard
              title="Redirect Web URL (Check In)"
              url={DeepLinkMapForConnect.redirectWeb.checkIn({ liffId, shopId })}
            />

            <QrCodeCard
              title="Redirect Web URL (Check In &amp; Lottery)"
              url={DeepLinkMapForConnect.redirectWeb.checkInAndLottery({ liffId, shopId })}
            />

            <QrCodeCard
              title="Redirect Web URL (Rich Menu)"
              url={DeepLinkMapForConnect.redirectWeb.richMenu({ liffId, shopId })}
            />

            <QrCodeCard
              title="Redirect Web URL Under Company (Rich Menu)"
              url={DeepLinkMapForConnect.redirectWeb.richMenuUnderCompany({
                liffId,
                _companyId,
              })}
            />
          </>
        ) : (
          <StyledAlert message="店舗の liffId が設定されていないため、ミニアプリ用の QR コードを生成できません。詳しくは dinii 担当者にお問合せください。" />
        ),
    },
    {
      key: "2",
      label: "CRM with MO QR",
      children:
        liffId && shopId && _companyId ? (
          <>
            <QrCodeCard
              title="Redirect Web URL (Rich Menu)"
              url={DeepLinkMapForConnectWithMO.redirectWeb.richMenu({ liffId, shopId })}
            />

            <QrCodeCard
              title="Redirect Web URL Under Company (Rich Menu)"
              url={DeepLinkMapForConnectWithMO.redirectWeb.richMenuUnderCompany({
                liffId,
                _companyId,
              })}
            />
          </>
        ) : (
          <StyledAlert message="店舗の liffId が設定されていないため、ミニアプリ用の QR コードを生成できません。詳しくは dinii 担当者にお問合せください。" />
        ),
    },
  ];

  return <Collapse accordion bordered={false} items={collapseItems} />;
};
