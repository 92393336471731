import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Tag } from "antd";
import Column from "antd/es/table/Column";
import Modal from "antd/lib/modal/Modal";
import { isNotNull } from "util/type/primitive";

import { Table } from "components/Table";
import { colors } from "constants/colors";

import {
  FoodingJournalPlanSource,
  NonNullableFoodingJournalPlanSource,
  PlanWithFoodingJournalMenu,
} from "../PlanTable";
import { FoodingJournalDepartmentMaster, FoodingJournalGroupMaster } from "../types";

const ComparisonText = styled.p`
  color: ${colors.Text.Primary};
`;

type Props = {
  editingFoodingJournalPlans: Record<
    string,
    FoodingJournalPlanSource & { type: "plan" | "planChoice" }
  >;
  plans: PlanWithFoodingJournalMenu[];
  foodingJournalDepartmentMasters: FoodingJournalDepartmentMaster[];
  foodingJournalGroupMasters: FoodingJournalGroupMaster[];
  loading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

type RowItem = NonNullableFoodingJournalPlanSource & {
  id: string;
  categoryName: string;
  name: string;
  type: "plan" | "planChoice";

  previousCode: string | undefined;
  previousName: string | undefined;
  previousFoodingJournalDepartmentId: string | undefined;
  previousFoodingJournalGroupId: string | undefined;
};

const isEditingValueValid = <T extends FoodingJournalPlanSource>(
  value: T,
): value is T & NonNullableFoodingJournalPlanSource =>
  Boolean(
    value.code &&
      value.foodingJournalName &&
      value.foodingJournalDepartmentId &&
      value.foodingJournalGroupId,
  );

export const ConfirmationModal = memo<Props>(
  ({
    editingFoodingJournalPlans,
    plans,
    foodingJournalDepartmentMasters,
    foodingJournalGroupMasters,
    loading,
    onSubmit,
    onCancel,
  }: Props) => {
    const planIdToPlanMap = useMemo(
      () => new Map(plans.map((plan) => [plan.planId, plan])),
      [plans],
    );

    const planChoiceIdToPlanChoiceSourceMap = useMemo(
      () =>
        new Map(
          Object.entries(editingFoodingJournalPlans).filter(
            ([_, plan]) => plan.type === "planChoice",
          ),
        ),
      [editingFoodingJournalPlans],
    );

    const foodingJournalDepartmentMap = useMemo(
      () => new Map(foodingJournalDepartmentMasters.map((master) => [master.id, master])),
      [foodingJournalDepartmentMasters],
    );
    const foodingJournalGroupMap = useMemo(
      () => new Map(foodingJournalGroupMasters.map((master) => [master.id, master])),
      [foodingJournalGroupMasters],
    );

    const dataSource = useMemo(() => {
      const planChoiceIds = new Set();

      const plans = Object.entries(editingFoodingJournalPlans)
        .map(([id, planSource]) => {
          if (planSource.type === "plan") {
            const plan = planIdToPlanMap.get(id);

            if (!plan) return null;

            const categoryName = plan.category.name;

            const children = plan.planChoices
              .map((planChoice) => {
                const planChoiceSource = planChoiceIdToPlanChoiceSourceMap.get(
                  planChoice.planChoiceId,
                );

                if (!planChoiceSource || !isEditingValueValid(planChoiceSource)) return null;

                planChoiceIds.add(planChoice.planChoiceId);

                return {
                  ...planChoiceSource,
                  categoryName,
                  id: planChoice.planChoiceId,
                  name: planChoice.name,
                  previousCode:
                    planChoice.code !== planChoiceSource.code ? planChoice.code : undefined,
                  previousName:
                    planChoice.foodingJournalName !== planChoiceSource.foodingJournalName
                      ? planChoice.foodingJournalName
                      : undefined,
                  previousFoodingJournalDepartmentId:
                    planChoice.foodingJournalDepartmentId !==
                    planChoiceSource.foodingJournalDepartmentId
                      ? planChoice.foodingJournalDepartmentId
                      : undefined,
                  previousFoodingJournalGroupId:
                    planChoice.foodingJournalGroupId !== planChoiceSource.foodingJournalGroupId
                      ? planChoice.foodingJournalGroupId
                      : undefined,
                };
              })
              .filter(isNotNull);

            return {
              ...planSource,
              id,
              categoryName,
              name: plan.name,
              previousCode: plan.code !== planSource.code ? plan.code : undefined,
              previousName:
                plan.foodingJournalName !== planSource.foodingJournalName
                  ? plan.foodingJournalName
                  : undefined,
              previousFoodingJournalDepartmentId:
                plan.foodingJournalDepartmentId !== planSource.foodingJournalDepartmentId
                  ? plan.foodingJournalDepartmentId
                  : undefined,
              previousFoodingJournalGroupId:
                plan.foodingJournalGroupId !== planSource.foodingJournalGroupId
                  ? plan.foodingJournalGroupId
                  : undefined,
              children: children.length > 0 ? children : null,
            };
          } else {
            const planChoice = Array.from(planIdToPlanMap.values())
              .flatMap((plan) => plan.planChoices)
              .find((planChoice) => planChoice.planChoiceId === id);

            if (!planChoice) return null;

            const planChoiceSource = planChoiceIdToPlanChoiceSourceMap.get(planChoice.planChoiceId);

            if (!planChoiceSource || !isEditingValueValid(planChoiceSource)) return null;

            return {
              ...planChoiceSource,
              categoryName: planChoice.category.name,
              id: planChoice.planChoiceId,
              name: planChoice.name,
              previousCode: planChoice.code !== planChoiceSource.code ? planChoice.code : undefined,
              previousName:
                planChoice.foodingJournalName !== planChoiceSource.foodingJournalName
                  ? planChoice.foodingJournalName
                  : undefined,
              previousFoodingJournalDepartmentId:
                planChoice.foodingJournalDepartmentId !==
                planChoiceSource.foodingJournalDepartmentId
                  ? planChoice.foodingJournalDepartmentId
                  : undefined,
              previousFoodingJournalGroupId:
                planChoice.foodingJournalGroupId !== planChoiceSource.foodingJournalGroupId
                  ? planChoice.foodingJournalGroupId
                  : undefined,
              children: null,
            };
          }
        })
        .filter(isNotNull);

      // NOTE: plan.children の planChoice を優先する
      const filteredPlans = plans.filter((plan) => {
        if (planChoiceIds.has(plan.id)) return false;
        return true;
      });

      return { plans: filteredPlans };
    }, [planChoiceIdToPlanChoiceSourceMap, editingFoodingJournalPlans, planIdToPlanMap]);

    const onOk = useCallback(async () => {
      await onSubmit();
    }, [onSubmit]);

    const tableComponent = useMemo(
      () => (
        <Table bordered dataSource={dataSource.plans}>
          <Column
            title=""
            width={100}
            fixed="left"
            render={(_: string, rowItem: RowItem) =>
              rowItem.foodingJournalMenuId ? (
                <Tag>更新</Tag>
              ) : (
                <Tag color={colors.BackGround.PrimaryDefault}>追加</Tag>
              )
            }
          />

          <Column
            title="カテゴリ名"
            width={150}
            fixed="left"
            render={(_: string, rowItem: RowItem) => {
              if (rowItem.type !== "plan" && !rowItem.categoryName) {
                return {
                  props: {
                    style: {
                      backgroundColor: colors.BackGround.Tertiary,
                    },
                  },
                };
              }

              const { categoryName } = rowItem;

              return {
                children: categoryName,
              };
            }}
          />

          <Column dataIndex="name" title="商品名" width={200} />

          <Column
            dataIndex="code"
            title="メニューコード"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{rowItem.code}</ComparisonText>;
              }

              if (!rowItem.previousCode) return rowItem.code;

              return (
                <>
                  <ComparisonText> {`${rowItem.previousCode} → ${rowItem.code}`} </ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalName"
            title="プラン名称"
            align="left"
            width="300"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{rowItem.name}</ComparisonText>;
              }

              if (!rowItem.previousName) return rowItem.foodingJournalName;

              return (
                <>
                  <ComparisonText>{`${rowItem.previousName} → ${rowItem.foodingJournalName}`}</ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalDepartmentId"
            title="部門"
            align="left"
            width="200"
            render={(_: string, rowItem: RowItem) => {
              const foodingJournalDepartment = foodingJournalDepartmentMap.get(
                rowItem.foodingJournalDepartmentId,
              );

              if (!foodingJournalDepartment) return "";

              const departmentLabel = `${foodingJournalDepartment.code}: ${foodingJournalDepartment.name}`;

              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{departmentLabel}</ComparisonText>;
              }

              if (!rowItem.previousFoodingJournalDepartmentId) {
                return departmentLabel;
              }

              const previousFoodingJournalDepartment = foodingJournalDepartmentMap.get(
                rowItem.previousFoodingJournalDepartmentId,
              );

              if (!previousFoodingJournalDepartment) return "";

              const previousDepartmentLabel = `${previousFoodingJournalDepartment.code}: ${previousFoodingJournalDepartment.name}`;

              return (
                <>
                  <ComparisonText>{`${previousDepartmentLabel} → ${departmentLabel}`}</ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalGroupId"
            title="分類"
            align="left"
            width="150"
            render={(_: string, rowItem: RowItem) => {
              const foodingJournalGroup = foodingJournalGroupMap.get(rowItem.foodingJournalGroupId);

              if (!foodingJournalGroup) return "";

              const groupLabel = `${foodingJournalGroup.code}: ${foodingJournalGroup.name}`;

              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{groupLabel}</ComparisonText>;
              }

              if (!rowItem.previousFoodingJournalGroupId) {
                return groupLabel;
              }

              const previousFoodingJournalGroup = foodingJournalGroupMap.get(
                rowItem.previousFoodingJournalGroupId,
              );

              if (!previousFoodingJournalGroup) return "";

              const previousGroupLabel = `${previousFoodingJournalGroup.code}: ${previousFoodingJournalGroup.name}`;

              return (
                <>
                  <ComparisonText>{`${previousGroupLabel} → ${groupLabel}`}</ComparisonText>
                </>
              );
            }}
          />
        </Table>
      ),
      [dataSource.plans, foodingJournalDepartmentMap, foodingJournalGroupMap],
    );

    return (
      <Modal
        title="変更内容確認"
        open
        width={3000}
        onCancel={onCancel}
        footer={
          <>
            <Button key="cancel" onClick={onCancel}>
              キャンセル
            </Button>

            <Button
              key="add"
              type="primary"
              onClick={onOk}
              loading={loading}
              disabled={plans.length === 0}
            >
              更新
            </Button>
          </>
        }
      >
        {tableComponent}
      </Modal>
    );
  },
);
