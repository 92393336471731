import { useCallback, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { readonlyMenuTypes } from "models/menuType";
import { isNotNull } from "util/type/primitive";

import { dayjsToDateString } from "libs/DateString";
import { unwrap } from "libs/unwrap";

import { FilterConditions } from "../../pages/MenuOrderItemAnalytics/Filters";
import {
  MenuOrderItemAnalyticsField,
  MenuOrderItemAnalyticsRow,
  NormalizedMenuOrderItemAnalyticsInputRow,
  RawMenuOrderItemAnalyticsRow,
  RepeaterTableType,
  ReportByType,
} from "../../pages/MenuOrderItemAnalytics/types";

import { GetMenuOrderItemAnalyticsQuery, useGetMenuOrderItemAnalyticsQuery } from "./queries";
import { emptyArrayIfUnselected } from "./util";

export const abcAnalysisARankThreshold = 0.7;
export const abcAnalysisBRankThreshold = 0.9;

const defaultFixedDecimalPoint = 3;

const groupAndNormalizeRows = ({
  rows,
  groupByColumn,
  getName,
}: {
  rows: RawMenuOrderItemAnalyticsRow[];
  groupByColumn: keyof RawMenuOrderItemAnalyticsRow;
  getName: (row: RawMenuOrderItemAnalyticsRow) => string;
}): NormalizedMenuOrderItemAnalyticsInputRow[] => {
  const groupedBy = groupBy(rows, (row) => row[groupByColumn]);

  return Object.keys(groupedBy).map((groupId) => ({
    id: groupId,
    name: getName(unwrap(groupedBy[groupId]?.[0])),
    ...(groupedBy[groupId] ?? []).reduce(
      (acc, row) => ({
        totalTaxIncludedAmount: acc.totalTaxIncludedAmount + row.totalTaxIncludedAmount,
        totalTaxExcludedAmount: acc.totalTaxExcludedAmount + row.totalTaxExcludedAmount,
        totalTaxIncludedCostAmount: acc.totalTaxIncludedCostAmount + row.totalTaxIncludedCostAmount,
        totalTaxExcludedCostAmount: acc.totalTaxExcludedCostAmount + row.totalTaxExcludedCostAmount,
        totalTaxIncludedNetProfitAmount:
          acc.totalTaxIncludedNetProfitAmount + row.totalTaxIncludedNetProfitAmount,
        totalTaxExcludedNetProfitAmount:
          acc.totalTaxExcludedNetProfitAmount + row.totalTaxExcludedNetProfitAmount,
        orderedQuantity: acc.orderedQuantity + row.orderedQuantity,
        repeatOrderQuantity: acc.repeatOrderQuantity + row.repeatOrderQuantity,
        firstTimeOrderQuantity: acc.firstTimeOrderQuantity + row.firstTimeOrderQuantity,
      }),
      {
        totalTaxIncludedAmount: 0,
        totalTaxExcludedAmount: 0,
        totalTaxIncludedCostAmount: 0,
        totalTaxExcludedCostAmount: 0,
        totalTaxIncludedNetProfitAmount: 0,
        totalTaxExcludedNetProfitAmount: 0,
        orderedQuantity: 0,
        repeatOrderQuantity: 0,
        firstTimeOrderQuantity: 0,
      },
    ),
  }));
};

const getFinalValue = ({
  actualValue,
  cumulativeValue,
  totalsValue,
}: {
  actualValue: number;
  cumulativeValue: number;
  totalsValue: number;
}): MenuOrderItemAnalyticsField => {
  const actualPercentage = actualValue / totalsValue;
  const cumulativePercentage = cumulativeValue / totalsValue;

  return {
    actualValue,
    actualPercentage: isNaN(actualPercentage)
      ? 0
      : Number(actualPercentage.toFixed(defaultFixedDecimalPoint)),
    cumulativeValue,
    cumulativePercentage: isNaN(cumulativePercentage)
      ? 0
      : Number(cumulativePercentage.toFixed(defaultFixedDecimalPoint)),
    abcRank:
      cumulativePercentage <= abcAnalysisARankThreshold
        ? "A"
        : cumulativePercentage <= abcAnalysisBRankThreshold
        ? "B"
        : "C",
  };
};

const getRowIdToMenuOrderItemAnalyticsFieldMap = ({
  normalizedRows,
  column,
  totalValue,
}: {
  normalizedRows: NormalizedMenuOrderItemAnalyticsInputRow[];
  column:
    | "totalTaxIncludedAmount"
    | "totalTaxExcludedAmount"
    | "totalTaxIncludedCostAmount"
    | "totalTaxExcludedCostAmount"
    | "totalTaxIncludedNetProfitAmount"
    | "totalTaxExcludedNetProfitAmount"
    | "orderedQuantity"
    | "repeatOrderQuantity"
    | "firstTimeOrderQuantity";
  totalValue: number;
}): Map<string, MenuOrderItemAnalyticsField> => {
  let cumulativeValue = 0;
  return new Map(
    normalizedRows
      .sort((a, b) => b[column] - a[column])
      .map((row) => {
        cumulativeValue += row[column];
        return [
          row.id,
          getFinalValue({
            actualValue: row[column],
            cumulativeValue,
            totalsValue: totalValue,
          }),
        ];
      }),
  );
};

const getMenuOrderItemAnalyticsItemsFromRows = ({
  normalizedRows,
}: {
  normalizedRows: NormalizedMenuOrderItemAnalyticsInputRow[];
}): MenuOrderItemAnalyticsRow[] => {
  const totals = normalizedRows.reduce(
    (acc, row) => ({
      totalTaxIncludedAmount: acc.totalTaxIncludedAmount + row.totalTaxIncludedAmount,
      totalTaxExcludedAmount: acc.totalTaxExcludedAmount + row.totalTaxExcludedAmount,
      totalTaxIncludedCostAmount: acc.totalTaxIncludedCostAmount + row.totalTaxIncludedCostAmount,
      totalTaxExcludedCostAmount: acc.totalTaxExcludedCostAmount + row.totalTaxExcludedCostAmount,
      totalTaxIncludedNetProfitAmount:
        acc.totalTaxIncludedNetProfitAmount + row.totalTaxIncludedNetProfitAmount,
      totalTaxExcludedNetProfitAmount:
        acc.totalTaxExcludedNetProfitAmount + row.totalTaxExcludedNetProfitAmount,
      orderedQuantity: acc.orderedQuantity + row.orderedQuantity,
      repeatOrderQuantity: acc.repeatOrderQuantity + row.repeatOrderQuantity,
      firstTimeOrderQuantity: acc.firstTimeOrderQuantity + row.firstTimeOrderQuantity,
    }),
    {
      totalTaxIncludedAmount: 0,
      totalTaxExcludedAmount: 0,
      totalTaxIncludedCostAmount: 0,
      totalTaxExcludedCostAmount: 0,
      totalTaxIncludedNetProfitAmount: 0,
      totalTaxExcludedNetProfitAmount: 0,
      orderedQuantity: 0,
      repeatOrderQuantity: 0,
      firstTimeOrderQuantity: 0,
    },
  );

  const totalTaxIncludedAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxIncludedAmount",
    totalValue: totals.totalTaxIncludedAmount,
  });

  const totalTaxExcludedAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxExcludedAmount",
    totalValue: totals.totalTaxExcludedAmount,
  });

  const totalTaxIncludedCostAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxIncludedCostAmount",
    totalValue: totals.totalTaxIncludedCostAmount,
  });

  const totalTaxExcludedCostAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxExcludedCostAmount",
    totalValue: totals.totalTaxExcludedCostAmount,
  });

  const totalTaxIncludedNetProfitAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxIncludedNetProfitAmount",
    totalValue: totals.totalTaxIncludedNetProfitAmount,
  });

  const totalTaxExcludedNetProfitAmountMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "totalTaxExcludedNetProfitAmount",
    totalValue: totals.totalTaxExcludedNetProfitAmount,
  });

  const orderedQuantityMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "orderedQuantity",
    totalValue: totals.orderedQuantity,
  });

  const repeatOrderQuantityMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "repeatOrderQuantity",
    totalValue: totals.repeatOrderQuantity,
  });

  const firstTimeOrderQuantityMap = getRowIdToMenuOrderItemAnalyticsFieldMap({
    normalizedRows,
    column: "firstTimeOrderQuantity",
    totalValue: totals.firstTimeOrderQuantity,
  });

  return normalizedRows
    .map((row) => {
      const orderedQuantity = orderedQuantityMap.get(row.id);
      const taxIncludedAmount = totalTaxIncludedAmountMap.get(row.id);
      const taxExcludedAmount = totalTaxExcludedAmountMap.get(row.id);
      const taxIncludedCostAmount = totalTaxIncludedCostAmountMap.get(row.id);
      const taxExcludedCostAmount = totalTaxExcludedCostAmountMap.get(row.id);
      const taxIncludedNetProfitAmount = totalTaxIncludedNetProfitAmountMap.get(row.id);
      const taxExcludedNetProfitAmount = totalTaxExcludedNetProfitAmountMap.get(row.id);
      const repeatOrderQuantity = repeatOrderQuantityMap.get(row.id);
      const firstTimeOrderQuantity = firstTimeOrderQuantityMap.get(row.id);

      if (
        !orderedQuantity ||
        !taxIncludedAmount ||
        !taxExcludedAmount ||
        !taxIncludedCostAmount ||
        !taxExcludedCostAmount ||
        !taxIncludedNetProfitAmount ||
        !taxExcludedNetProfitAmount ||
        !repeatOrderQuantity ||
        !firstTimeOrderQuantity
      ) {
        return null;
      }

      const repeaterPercentage = Number(
        (
          repeatOrderQuantity.actualValue /
          (firstTimeOrderQuantity.actualValue + repeatOrderQuantity.actualValue)
        ).toFixed(defaultFixedDecimalPoint),
      );
      const actualRepeaterPercentage = isNaN(repeaterPercentage) ? 0 : repeaterPercentage;

      return {
        id: row.id,
        name: row.name,
        orderedQuantity,
        taxIncludedAmount,
        taxExcludedAmount,
        taxIncludedCostAmount,
        taxExcludedCostAmount,
        taxIncludedNetProfitAmount,
        taxExcludedNetProfitAmount,
        repeatOrderQuantity,
        firstTimeOrderQuantity,
        repeaterPercentage: actualRepeaterPercentage,
      };
    })
    .filter(isNotNull);
};

type QueryConditions = {
  shopIds: string[] | "unselected";
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
};

const isNeedingRefetch = ({
  currentQueryConditions,
  previousQueryConditions,
}: {
  currentQueryConditions: QueryConditions | null;
  previousQueryConditions: QueryConditions | null;
}): boolean =>
  !currentQueryConditions ||
  !previousQueryConditions ||
  emptyArrayIfUnselected(currentQueryConditions.shopIds).some(
    (shopId) => !previousQueryConditions.shopIds.includes(shopId),
  ) ||
  emptyArrayIfUnselected(previousQueryConditions.shopIds).some(
    (shopId) => !currentQueryConditions.shopIds.includes(shopId),
  ) ||
  dayjsToDateString(currentQueryConditions.startDate) !==
    dayjsToDateString(previousQueryConditions.startDate) ||
  dayjsToDateString(currentQueryConditions.endDate) !==
    dayjsToDateString(previousQueryConditions.endDate);

export const useMenuOrderItemAnalytics = ({
  companyId,
  allShopIds,
  filterConditions,
}: {
  companyId: string | null;
  allShopIds: string[];
  filterConditions: Partial<FilterConditions>;
}) => {
  const [rows, setRows] = useState<RawMenuOrderItemAnalyticsRow[]>([]);
  const previousQueryConditionsRef = useRef<QueryConditions | null>(null);

  const range = useMemo(() => {
    const startAt = filterConditions.range?.[0];
    const endAt = filterConditions.range?.[1];
    if (!startAt || !endAt) return null;

    return { startAt: dayjsToDateString(startAt), endAt: dayjsToDateString(endAt) };
  }, [filterConditions.range]);

  const currentQueryConditions = useMemo(
    () =>
      range
        ? {
            shopIds: filterConditions.shopIds ?? allShopIds,
            startDate: dayjs(range.startAt),
            endDate: dayjs(range.endAt),
          }
        : null,
    [range, filterConditions, allShopIds],
  );

  const onCompleted = useCallback(
    (data: GetMenuOrderItemAnalyticsQuery) => {
      previousQueryConditionsRef.current = currentQueryConditions;
      setRows(
        (data?.menuOrderItemAnalytics.rows ?? []).map((row) => ({
          ...row,
          menuType: row.menuType ?? null,
        })),
      );
    },
    [currentQueryConditions],
  );

  const needsUpdated = useMemo(
    () =>
      isNeedingRefetch({
        currentQueryConditions,
        previousQueryConditions: previousQueryConditionsRef.current,
      }),
    [currentQueryConditions],
  );

  const { loading, error } = useGetMenuOrderItemAnalyticsQuery(
    companyId && range
      ? {
          onCompleted,
          fetchPolicy: needsUpdated ? "network-only" : "standby",
          variables: {
            input: {
              companyId,
              startAt: range.startAt,
              endAt: range.endAt,
              shopIds:
                filterConditions.shopIds === "unselected" ||
                filterConditions.shopIds === undefined ||
                filterConditions.shopIds.length === 0
                  ? allShopIds
                  : filterConditions.shopIds,
            },
          },
        }
      : { skip: true },
  );

  const selectedCategoriesMap = useMemo(
    () =>
      new Map<string, boolean>(
        emptyArrayIfUnselected(filterConditions.categoryIds).map((categoryId) => [
          categoryId,
          true,
        ]),
      ),
    [filterConditions.categoryIds],
  );

  const isNoCategorySelected =
    filterConditions.categoryIds === "unselected" ||
    (filterConditions.categoryIds ?? []).length === 0;

  const selectedMenuTypesMap = useMemo(
    () =>
      new Map<string, boolean>(
        emptyArrayIfUnselected(filterConditions.menuTypes).map((menuType) => [menuType, true]),
      ),
    [filterConditions.menuTypes],
  );

  const isNoBusinessOperationHourTypeSelected =
    filterConditions.businessOperationHourTypes === "unselected" ||
    (filterConditions.businessOperationHourTypes ?? []).length === 0;

  const selectedBusinessOperationHourTypesMap = useMemo(
    () =>
      new Map<string, boolean>(
        emptyArrayIfUnselected(filterConditions.businessOperationHourTypes).map(
          (businessOperationHourType) => [businessOperationHourType, true],
        ),
      ),
    [filterConditions.businessOperationHourTypes],
  );

  const isNoMenuTypeSelected =
    filterConditions.menuTypes === "unselected" || (filterConditions.menuTypes ?? []).length === 0;

  const isShowingNonRepeaterTables =
    filterConditions.repeaterTableType === RepeaterTableType.nonRepeaterTables;
  const isShowingRepeaterTables =
    filterConditions.repeaterTableType === RepeaterTableType.repeaterTables;
  const isShowingAllRepeaterTypes =
    !filterConditions.repeaterTableType ||
    filterConditions.repeaterTableType === "unselected" ||
    filterConditions.repeaterTableType === RepeaterTableType.allTables;

  const filteredRawRows = useMemo(
    () =>
      rows.filter(
        (row) =>
          (isNoCategorySelected || selectedCategoriesMap.get(row.categoryId)) &&
          (isNoMenuTypeSelected || selectedMenuTypesMap.get(row.menuType ?? "")) &&
          (isNoBusinessOperationHourTypeSelected ||
            selectedBusinessOperationHourTypesMap.get(row.businessOperationHourType)) &&
          (isShowingAllRepeaterTypes ||
            (isShowingNonRepeaterTables && !row.isRepeaterTable) ||
            (isShowingRepeaterTables && row.isRepeaterTable)),
      ),
    [
      rows,
      selectedCategoriesMap,
      isNoCategorySelected,
      selectedMenuTypesMap,
      isNoMenuTypeSelected,
      isNoBusinessOperationHourTypeSelected,
      selectedBusinessOperationHourTypesMap,
      isShowingNonRepeaterTables,
      isShowingRepeaterTables,
      isShowingAllRepeaterTypes,
    ],
  );

  const normalizedRows = useMemo(
    () =>
      groupAndNormalizeRows({
        rows: filteredRawRows,
        groupByColumn:
          filterConditions.reportByType === ReportByType.category
            ? "categoryId"
            : filterConditions.reportByType === ReportByType.menuType
            ? "menuType"
            : "menuId",
        getName:
          filterConditions.reportByType === ReportByType.category
            ? (row: RawMenuOrderItemAnalyticsRow) =>
                row.categoryName === "other" ? "その他" : row.categoryName
            : filterConditions.reportByType === ReportByType.menuType
            ? (row: RawMenuOrderItemAnalyticsRow) =>
                row.menuType ? readonlyMenuTypes[row.menuType] ?? "" : ""
            : (row: RawMenuOrderItemAnalyticsRow) => row.menuName,
      }),
    [filteredRawRows, filterConditions.reportByType],
  );

  const menuOrderItemAnalytics = useMemo(
    () => getMenuOrderItemAnalyticsItemsFromRows({ normalizedRows }),
    [normalizedRows],
  );

  return {
    menuOrderItemAnalytics,
    isLoading: loading,
  };
};
