import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GiftChoicesGetGiftOptionMeta = gql`
    query GiftChoicesGetGiftOptionMeta($shopId: uuid!) {
  giftOptionMeta(
    where: {shopId: {_eq: $shopId}, option: {archivedAt: {_is_null: true}}}
  ) {
    id
    option {
      optionId
      choices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{price: asc}, {choiceId: asc}]
      ) {
        choiceId
        name
        price
        priority
        shopChoices(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          id
        }
      }
    }
  }
}
    `;
export const GiftChoicesDeleteGiftChoice = gql`
    mutation GiftChoicesDeleteGiftChoice($choiceId: Int!, $shopChoiceIds: [String!]!, $archivedAt: timestamptz!) {
  update_choice(
    _set: {archivedAt: $archivedAt}
    where: {choiceId: {_eq: $choiceId}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  delete_pikaichiMenu(where: {pikaichiMenuChoices: {_choiceId: {_eq: $choiceId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  deleteShopChoices(input: {shopChoiceIds: $shopChoiceIds}) {
    result
  }
}
    `;
export type GiftChoicesGetGiftOptionMetaQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type GiftChoicesGetGiftOptionMetaQuery = (
  { __typename?: 'query_root' }
  & { giftOptionMeta: Array<(
    { __typename?: 'giftOptionMeta' }
    & Pick<Types.GiftOptionMeta, 'id'>
    & { option: (
      { __typename?: 'option' }
      & Pick<Types.Option, 'optionId'>
      & { choices: Array<(
        { __typename?: 'choice' }
        & Pick<Types.Choice, 'choiceId' | 'name' | 'price' | 'priority'>
        & { shopChoices: Array<(
          { __typename?: 'shopChoice' }
          & Pick<Types.ShopChoice, 'id'>
        )> }
      )> }
    ) }
  )> }
);

export type GiftChoicesDeleteGiftChoiceMutationVariables = Types.Exact<{
  choiceId: Types.Scalars['Int'];
  shopChoiceIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type GiftChoicesDeleteGiftChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { update_choice?: Types.Maybe<(
    { __typename?: 'choice_mutation_response' }
    & Pick<Types.ChoiceMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenuChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuChoice_mutation_response' }
    & Pick<Types.PikaichiMenuChoiceMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationMenu?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu_mutation_response' }
    & Pick<Types.TecAggregationMenuMutationResponse, 'affected_rows'>
  )>, delete_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )>, deleteShopChoices: (
    { __typename?: 'DeleteShopChoicesResult' }
    & Pick<Types.DeleteShopChoicesResult, 'result'>
  ) }
);


export const GiftChoicesGetGiftOptionMetaDocument = gql`
    query GiftChoicesGetGiftOptionMeta($shopId: uuid!) {
  giftOptionMeta(
    where: {shopId: {_eq: $shopId}, option: {archivedAt: {_is_null: true}}}
  ) {
    id
    option {
      optionId
      choices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{price: asc}, {choiceId: asc}]
      ) {
        choiceId
        name
        price
        priority
        shopChoices(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGiftChoicesGetGiftOptionMetaQuery__
 *
 * To run a query within a React component, call `useGiftChoicesGetGiftOptionMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftChoicesGetGiftOptionMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftChoicesGetGiftOptionMetaQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useGiftChoicesGetGiftOptionMetaQuery(baseOptions: Apollo.QueryHookOptions<GiftChoicesGetGiftOptionMetaQuery, GiftChoicesGetGiftOptionMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftChoicesGetGiftOptionMetaQuery, GiftChoicesGetGiftOptionMetaQueryVariables>(GiftChoicesGetGiftOptionMetaDocument, options);
      }
export function useGiftChoicesGetGiftOptionMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftChoicesGetGiftOptionMetaQuery, GiftChoicesGetGiftOptionMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftChoicesGetGiftOptionMetaQuery, GiftChoicesGetGiftOptionMetaQueryVariables>(GiftChoicesGetGiftOptionMetaDocument, options);
        }
export type GiftChoicesGetGiftOptionMetaQueryHookResult = ReturnType<typeof useGiftChoicesGetGiftOptionMetaQuery>;
export type GiftChoicesGetGiftOptionMetaLazyQueryHookResult = ReturnType<typeof useGiftChoicesGetGiftOptionMetaLazyQuery>;
export type GiftChoicesGetGiftOptionMetaQueryResult = Apollo.QueryResult<GiftChoicesGetGiftOptionMetaQuery, GiftChoicesGetGiftOptionMetaQueryVariables>;
export const GiftChoicesDeleteGiftChoiceDocument = gql`
    mutation GiftChoicesDeleteGiftChoice($choiceId: Int!, $shopChoiceIds: [String!]!, $archivedAt: timestamptz!) {
  update_choice(
    _set: {archivedAt: $archivedAt}
    where: {choiceId: {_eq: $choiceId}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  delete_pikaichiMenu(where: {pikaichiMenuChoices: {_choiceId: {_eq: $choiceId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_choiceId: {_eq: $choiceId}}) {
    affected_rows
  }
  deleteShopChoices(input: {shopChoiceIds: $shopChoiceIds}) {
    result
  }
}
    `;
export type GiftChoicesDeleteGiftChoiceMutationFn = Apollo.MutationFunction<GiftChoicesDeleteGiftChoiceMutation, GiftChoicesDeleteGiftChoiceMutationVariables>;

/**
 * __useGiftChoicesDeleteGiftChoiceMutation__
 *
 * To run a mutation, you first call `useGiftChoicesDeleteGiftChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftChoicesDeleteGiftChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftChoicesDeleteGiftChoiceMutation, { data, loading, error }] = useGiftChoicesDeleteGiftChoiceMutation({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *      shopChoiceIds: // value for 'shopChoiceIds'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useGiftChoicesDeleteGiftChoiceMutation(baseOptions?: Apollo.MutationHookOptions<GiftChoicesDeleteGiftChoiceMutation, GiftChoicesDeleteGiftChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftChoicesDeleteGiftChoiceMutation, GiftChoicesDeleteGiftChoiceMutationVariables>(GiftChoicesDeleteGiftChoiceDocument, options);
      }
export type GiftChoicesDeleteGiftChoiceMutationHookResult = ReturnType<typeof useGiftChoicesDeleteGiftChoiceMutation>;
export type GiftChoicesDeleteGiftChoiceMutationResult = Apollo.MutationResult<GiftChoicesDeleteGiftChoiceMutation>;
export type GiftChoicesDeleteGiftChoiceMutationOptions = Apollo.BaseMutationOptions<GiftChoicesDeleteGiftChoiceMutation, GiftChoicesDeleteGiftChoiceMutationVariables>;