import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { Plan } from "types/graphql";

export const filterPlansByName = <T extends Pick<Plan, "planName">>(plans: T[], name: string) => {
  const fuse = new Fuse(
    plans.map(({ planName }) => ({ planName: hiraganaToKatakana(planName) })),
    { keys: ["planName"] },
  );
  const indexes = fuse.search(hiraganaToKatakana(name)).map(({ refIndex }) => refIndex);

  return indexes.map((index) => plans[index]).filter(isNotNullable);
};
