import { useCallback, useMemo } from "react";

import { useCurrentUser } from "hooks/useUser";
import { DashboardAccountRolePermission } from "types/graphql";

import { useDashboardAccountRolePermissionsQuery } from "./queries";

export const useCurrentUserPermissions = () => {
  const user = useCurrentUser();
  const { data: permissionsData, loading } = useDashboardAccountRolePermissionsQuery(
    user?.email
      ? {
          variables: { email: user.email },
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );
  const role = permissionsData?.dashboardAccount?.[0]?.role;

  const permissions = useMemo(
    () =>
      permissionsData?.dashboardAccount?.[0]?.dashboardAccountRole
        ?.dashboardAccountRolePermissions ?? [],
    [permissionsData],
  );

  const hasPermission = useCallback(
    (dashboardAccountPermission: Pick<DashboardAccountRolePermission, "feature" | "type">) =>
      permissions.some(
        (p) =>
          p.feature === dashboardAccountPermission.feature &&
          p.type === dashboardAccountPermission.type,
      ),
    [permissions],
  );
  const accessibleShopIds =
    permissionsData?.dashboardAccount?.[0]?.dashboardAccountAccessibleShops.map(
      (val) => val.shopId,
    );

  return { role, permissions, hasPermission, isLoading: loading, accessibleShopIds };
};
