import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import {
  useEditPlanChoiceModalGetPlanChoiceQuery,
  useEditPlanChoiceModalUpdatePlanChoiceMutation,
} from "pages/PlanOptions/EditPlanChoiceModal/queries";
import { SupportedLocaleEnumType } from "types/graphql";

import { EditPlanChoiceFormValues } from "./EditPlanChoiceForm/useEditPlanChoiceForm";
import { EditPlanChoiceModalForm } from "./EditPlanChoiceModalForm";

type Props = {
  goBack: () => void;
  onUpdated: () => void;
  canEditPlanChoice: boolean;
};

export const EditPlanChoiceModal = memo<Props>(({ goBack, onUpdated, canEditPlanChoice }) => {
  const { planChoiceId: id } = useParams<{ planChoiceId: string }>();
  const planChoiceId = Number(id);

  if (Number.isNaN(planChoiceId)) {
    goBack();
  }

  const {
    data: getPlanChoiceData,
    loading,
    refetch: refetchPlanChoice,
    error,
  } = useEditPlanChoiceModalGetPlanChoiceQuery({
    variables: { planChoiceId },
  });
  const planChoice = getPlanChoiceData?.planChoice?.[0];
  const uuid = planChoice?.id;
  const companyId = planChoice?.planOption.plan.company.id;
  const [updatePlanChoiceMutation, { loading: loadingUpdatePlanChoice }] =
    useEditPlanChoiceModalUpdatePlanChoiceMutation();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<EditPlanChoiceFormValues | null>(null);
  const onOk = useCallback(async () => {
    if (planChoiceId && formValues && uuid && companyId) {
      try {
        await form.validateFields();

        const { hasOrderCountLimit, nameEn, nameKr, nameCn, ...values } = formValues;
        // NOTE: maxOrderableNum はhasOrderCountLimit がtrue の時のみ有効とする仕様
        const maxOrderableNum = hasOrderCountLimit ? values.maxOrderableNum : null;

        const planChoice = { ...values, maxOrderableNum };

        const nameSources = [
          {
            value: nameEn ?? "",
            locale: SupportedLocaleEnumType.EnUs,
          },
          {
            value: nameKr ?? "",
            locale: SupportedLocaleEnumType.KoKr,
          },
          {
            value: nameCn ?? "",
            locale: SupportedLocaleEnumType.ZhCn,
          },
        ];

        const variables = {
          planChoiceId,
          planChoice,
          translations: {
            companyId,
            planChoiceId: uuid,
            nameSources,
          },
        };

        await updatePlanChoiceMutation({
          variables,
        });

        await refetchPlanChoice();
        onUpdated();

        message.success("編集を保存しました");
      } catch (err) {
        return message.error("編集の保存に失敗しました");
      }
    }

    goBack();
  }, [
    planChoiceId,
    formValues,
    uuid,
    companyId,
    goBack,
    form,
    updatePlanChoiceMutation,
    onUpdated,
    refetchPlanChoice,
  ]);

  const onCancel = goBack;

  return (
    <Modal
      title={planChoice?.name}
      open
      width={640}
      onOk={goBack}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
        canEditPlanChoice && (
          <Button
            key="save"
            type="primary"
            onClick={onOk}
            disabled={loading}
            loading={loadingUpdatePlanChoice}
          >
            更新
          </Button>
        ),
      ]}
    >
      {loading && <Loading fullWidth height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {planChoice && (
        <EditPlanChoiceModalForm
          form={form}
          planChoice={planChoice}
          translations={planChoice.translations}
          inputType={planChoice.planOption.inputType}
          disabled={!canEditPlanChoice}
          onChange={setFormValues}
        />
      )}
    </Modal>
  );
});
