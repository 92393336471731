import React, { memo, useMemo } from "react";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { Choice, Menu } from "../types";

type Props = {
  loading?: boolean;
  menus: Menu[];
};

type RowItem =
  | ({ type: "menu" } & Menu)
  | ({ type: "choice" } & Choice & { menuId: number; optionId: number });

export const MenuTable = memo<Props>(({ loading, menus }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<RowItem> = useMemo(
    () => [
      {
        title: "カテゴリ名",
        width: 150,
        fixed: "left",
        render(_: string, rowItem: RowItem) {
          if (rowItem.type !== "menu") {
            return {
              props: {
                style: {
                  backgroundColor: colors.BackGround.Secondary,
                },
              },
            };
          }

          const { category } = rowItem;
          return {
            children: category.name,
          };
        },
      },
      {
        title: "商品名",
        dataIndex: "name",
        fixed: "left",
        width: 200,
      },
      {
        title: "メニューコード",
        align: "left",
        width: 130,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.menuCode;
        },
      },
      {
        title: "メニュー名",
        align: "left",
        width: 150,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.name;
        },
      },
      {
        title: "部門コード",
        align: "left",
        width: 100,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.dpCode;
        },
      },
      {
        title: "部門名",
        align: "left",
        width: 150,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.dpName;
        },
      },
      {
        title: "分類コード",
        align: "left",
        width: 100,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.gpCode;
        },
      },
      {
        title: "分類名",
        align: "left",
        width: 150,
        render(_: string, rowItem: RowItem) {
          return rowItem.tecAggregationMenu?.gpName;
        },
      },
      ...(canAccess("editTecAggregationMenu")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, rowItem: RowItem) {
                if (rowItem.type === "menu") {
                  return (
                    <Tooltip title="メニューを編集">
                      <IconLink to={`/tec/menu/${rowItem.menuId}/edit`}>
                        <EditIcon />
                      </IconLink>
                    </Tooltip>
                  );
                }

                return (
                  <Tooltip title="メニューを編集">
                    <IconLink
                      to={`/tec/menu/${rowItem.menuId}/option/${rowItem.optionId}/choice/${rowItem.choiceId}/edit`}
                    >
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  const rows = useMemo<RowItem[]>(
    () =>
      menus.map((menu) => ({
        ...menu,
        type: "menu",
        children:
          menu.menuOptions.length > 0
            ? menu.menuOptions.flatMap(({ option }) =>
                option.choices.map((choice) => ({
                  ...choice,
                  type: "choice",
                  menuId: menu.menuId,
                  optionId: option.optionId,
                  name: `${option.name} ${choice.name}`,
                  category: menu.category,
                })),
              )
            : null,
      })),
    [menus],
  );

  const genRowKey = (rowItem: RowItem) => {
    switch (rowItem.type) {
      case "menu":
        return `${rowItem.category.categoryId}-${rowItem.menuId}`;
      case "choice":
        return `${rowItem.category.categoryId}-${rowItem.menuId}-${rowItem.choiceId}`;
    }
  };

  const expandableConfig = useMemo(() => {
    const defaultExpandedRowKeys = rows.flatMap((row) =>
      row.type === "menu" && row.menuOptions.length > 0 ? [genRowKey(row)] : [],
    );
    return {
      defaultExpandedRowKeys,
    };
  }, [rows]);

  return (
    <Table
      rowKey={genRowKey}
      columns={columns}
      dataSource={rows}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      expandable={expandableConfig}
    />
  );
});
