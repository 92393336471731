import React, { memo } from "react";
import { Radio, Space } from "antd";
import { parseNumber } from "util/input/parseNumber";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";
import { Spacer } from "components/Spacer";

import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AvailableDaysField = memo<Props>((props) => (
  <AddCouponFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ isDisabledAvailableDays }) => [isDisabledAvailableDays])}
    noStyle
  >
    {({ getFieldValue }) => (
      <AddCouponFormItem
        label="使用可能日数"
        name="isDisabledAvailableDays"
        rules={[
          { required: true },
          {
            validator: async (_, isDisabledAvailableDays) => {
              if (isDisabledAvailableDays === true) return;

              const availableDays = getFieldValue("availableDays");

              if (!availableDays) {
                throw new Error("クーポンの使用可能日数を入力してください");
              }
            },
            validateTrigger: "onSubmit",
          },
        ]}
        {...props}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}>指定しない (無期限)</Radio>
            <Radio value={false}>
              指定する
              <Spacer size={4} />
              <AddCouponFormItem name="availableDays" noStyle>
                <InputNumber
                  disabled={getFieldValue("isDisabledAvailableDays")}
                  min={1}
                  controls={false}
                  formatter={(value) => `${value}日`}
                  parser={(value) => parseNumber(value, (value) => value.replace("日", ""))}
                />
              </AddCouponFormItem>
            </Radio>
          </Space>
        </Radio.Group>
      </AddCouponFormItem>
    )}
  </AddCouponFormItem.NonProperty>
));
