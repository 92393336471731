import React, { memo } from "react";
import styled from "styled-components";
import { Card, FormInstance } from "antd";

import { Form, FormList } from "components/antd/Form";
import { FormSection } from "components/Form/FormSection";
import { grey } from "constants/colors";

import { EditOptionTranslationsFormValues } from "../EditOptionTranslationsForm/useEditOptionTranslationsForm";

import { ChoiceNameCnField } from "./ChoiceNameCnField";
import { ChoiceNameEnField } from "./ChoiceNameEnField";
import { ChoiceNameField } from "./ChoiceNameField";
import { ChoiceNameKrField } from "./ChoiceNameKrField";

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledForm = styled(Form)`
  width: 60%;
`;

const StyledFormSection = styled(FormSection)`
  padding: 0;
`;

const StyledCard = styled(Card)`
  position: relative;
  overflow: hidden;
`;

type Props = {
  form: FormInstance<EditOptionTranslationsFormValues>;
  initialValues: EditOptionTranslationsFormValues;
  disabled: boolean;
};

export const EditChoiceTranslationsForm = memo<Props>(({ form, initialValues, disabled }) => (
  <FormWrapper>
    <StyledForm form={form} initialValues={initialValues} layout="vertical">
      <StyledFormSection>
        <FormList name="choiceSources">
          {(fields) => (
            <>
              {fields.map(({ name, ...field }, index) => (
                <StyledCard key={index}>
                  <ChoiceNameField {...field} name={[name, "choiceName"]} />
                  <ChoiceNameEnField {...field} name={[name, "choiceNameEn"]} disabled={disabled} />
                  <ChoiceNameCnField {...field} name={[name, "choiceNameCn"]} disabled={disabled} />
                  <ChoiceNameKrField {...field} name={[name, "choiceNameKr"]} disabled={disabled} />
                </StyledCard>
              ))}
            </>
          )}
        </FormList>
      </StyledFormSection>
    </StyledForm>
  </FormWrapper>
));
