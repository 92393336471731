import React, { memo, useCallback } from "react";
import { Space } from "antd";
import { FormInstance } from "antd/lib";
import { isNumber } from "util/type/primitive";

import { InputNumber } from "components/Input/InputNumber";

import { EditMessageDeliveryFormItem } from "../useEditMessageDeliveryForm";

import { lessThanOrEqualFieldTransformer, moreThanOrEqualFieldTransformer } from "./functions";

type Props = {
  form: FormInstance;
};

export const VisitedDayCountFilterField = memo<Props>(({ form }) => {
  const revalidate = useCallback(
    () => form.validateFields([["visitedDayCountFilter", "lessThanOrEqual"]]),
    [form],
  );

  const validator = useCallback(async () => {
    const moreThanOrEqual = form.getFieldValue(["visitedDayCountFilter", "moreThanOrEqual"]);
    const lessThanOrEqual = form.getFieldValue(["visitedDayCountFilter", "lessThanOrEqual"]);

    if (!isNumber(moreThanOrEqual) && !isNumber(lessThanOrEqual)) {
      throw new Error("来店回数を入力してください");
    }

    if (
      (isNumber(lessThanOrEqual) && !isNumber(moreThanOrEqual)) ||
      (!isNumber(lessThanOrEqual) && isNumber(moreThanOrEqual))
    ) {
      throw new Error("来店回数は以上と以下の両方の回数を入力してください");
    }

    if (
      isNumber(lessThanOrEqual) &&
      isNumber(moreThanOrEqual) &&
      lessThanOrEqual < moreThanOrEqual
    ) {
      throw new Error("来店回数は以下の方に大きい値を入力してください");
    }
  }, [form]);

  return (
    <>
      <Space size="small">
        <EditMessageDeliveryFormItem
          noStyle
          name={["visitedDayCountFilter", "moreThanOrEqual"]}
          rules={[
            ({ getFieldValue, setFieldsValue }) => ({
              transform(value: string | null) {
                moreThanOrEqualFieldTransformer({
                  value,
                  name: "visitedDayCountFilter",
                  getFieldValue,
                  setFieldsValue,
                });
              },
            }),
          ]}
        >
          <InputNumber style={{ width: "88px" }} min={1} onChange={revalidate} />
        </EditMessageDeliveryFormItem>
        回
      </Space>
      <span>〜</span>
      <Space size="small">
        <EditMessageDeliveryFormItem
          noStyle
          name={["visitedDayCountFilter", "lessThanOrEqual"]}
          rules={[
            ({ getFieldValue, setFieldsValue }) => ({
              transform(value: string | null) {
                lessThanOrEqualFieldTransformer({
                  value,
                  name: "visitedDayCountFilter",
                  getFieldValue,
                  setFieldsValue,
                });
              },
            }),
            () => ({
              validator,
            }),
          ]}
        >
          <InputNumber style={{ width: "88px" }} min={1} onChange={revalidate} />
        </EditMessageDeliveryFormItem>
        回
      </Space>
    </>
  );
});
