import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionUpdateOptionAndInsertChoices = gql`
    mutation EditOptionUpdateOptionAndInsertChoices($optionId: Int!, $option: option_set_input!, $choices: [choice_insert_input!]!, $choiceIdsForDelete: [Int!]!, $shopChoiceIdsForDelete: [String!]!, $archivedAt: timestamptz!) {
  update_option(_set: $option, where: {optionId: {_eq: $optionId}}) {
    affected_rows
  }
  insert_choice(
    objects: $choices
    on_conflict: {constraint: idx_46469_PRIMARY, update_columns: [costPrice, costTaxRate, isDefaultSelection, name, price, priority, receiptDisplayName, menuType, imageUrl]}
  ) {
    affected_rows
  }
  update_choice(
    _set: {archivedAt: $archivedAt}
    where: {choiceId: {_in: $choiceIdsForDelete}}
  ) {
    affected_rows
  }
  deleteShopChoices(input: {shopChoiceIds: $shopChoiceIdsForDelete}) {
    result
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuChoices: {_choiceId: {_in: $choiceIdsForDelete}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
}
    `;
export const EditOptionGetOption = gql`
    query EditOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    maxChoiceNum
    inputType
    name
    minChoiceNum
    optionId
    receiptDisplayName
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      costPrice
      costTaxRate
      isDefaultSelection
      name
      price
      priority
      receiptDisplayName
      menuType
      imageUrl
      shopChoices(
        where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
      ) {
        id
      }
    }
  }
}
    `;
export type EditOptionUpdateOptionAndInsertChoicesMutationVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
  option: Types.OptionSetInput;
  choices: Array<Types.ChoiceInsertInput> | Types.ChoiceInsertInput;
  choiceIdsForDelete: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  shopChoiceIdsForDelete: Array<Types.Scalars['String']> | Types.Scalars['String'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type EditOptionUpdateOptionAndInsertChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { update_option?: Types.Maybe<(
    { __typename?: 'option_mutation_response' }
    & Pick<Types.OptionMutationResponse, 'affected_rows'>
  )>, insert_choice?: Types.Maybe<(
    { __typename?: 'choice_mutation_response' }
    & Pick<Types.ChoiceMutationResponse, 'affected_rows'>
  )>, update_choice?: Types.Maybe<(
    { __typename?: 'choice_mutation_response' }
    & Pick<Types.ChoiceMutationResponse, 'affected_rows'>
  )>, deleteShopChoices: (
    { __typename?: 'DeleteShopChoicesResult' }
    & Pick<Types.DeleteShopChoicesResult, 'result'>
  ), delete_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenuChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuChoice_mutation_response' }
    & Pick<Types.PikaichiMenuChoiceMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationMenu?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu_mutation_response' }
    & Pick<Types.TecAggregationMenuMutationResponse, 'affected_rows'>
  )>, delete_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )> }
);

export type EditOptionGetOptionQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionGetOptionQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'id' | 'maxChoiceNum' | 'inputType' | 'name' | 'minChoiceNum' | 'optionId' | 'receiptDisplayName'>
    & { choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'costPrice' | 'costTaxRate' | 'isDefaultSelection' | 'name' | 'price' | 'priority' | 'receiptDisplayName' | 'menuType' | 'imageUrl'>
      & { shopChoices: Array<(
        { __typename?: 'shopChoice' }
        & Pick<Types.ShopChoice, 'id'>
      )> }
    )> }
  )> }
);


export const EditOptionUpdateOptionAndInsertChoicesDocument = gql`
    mutation EditOptionUpdateOptionAndInsertChoices($optionId: Int!, $option: option_set_input!, $choices: [choice_insert_input!]!, $choiceIdsForDelete: [Int!]!, $shopChoiceIdsForDelete: [String!]!, $archivedAt: timestamptz!) {
  update_option(_set: $option, where: {optionId: {_eq: $optionId}}) {
    affected_rows
  }
  insert_choice(
    objects: $choices
    on_conflict: {constraint: idx_46469_PRIMARY, update_columns: [costPrice, costTaxRate, isDefaultSelection, name, price, priority, receiptDisplayName, menuType, imageUrl]}
  ) {
    affected_rows
  }
  update_choice(
    _set: {archivedAt: $archivedAt}
    where: {choiceId: {_in: $choiceIdsForDelete}}
  ) {
    affected_rows
  }
  deleteShopChoices(input: {shopChoiceIds: $shopChoiceIdsForDelete}) {
    result
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuChoices: {_choiceId: {_in: $choiceIdsForDelete}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_choiceId: {_in: $choiceIdsForDelete}}) {
    affected_rows
  }
}
    `;
export type EditOptionUpdateOptionAndInsertChoicesMutationFn = Apollo.MutationFunction<EditOptionUpdateOptionAndInsertChoicesMutation, EditOptionUpdateOptionAndInsertChoicesMutationVariables>;

/**
 * __useEditOptionUpdateOptionAndInsertChoicesMutation__
 *
 * To run a mutation, you first call `useEditOptionUpdateOptionAndInsertChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionUpdateOptionAndInsertChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionUpdateOptionAndInsertChoicesMutation, { data, loading, error }] = useEditOptionUpdateOptionAndInsertChoicesMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      option: // value for 'option'
 *      choices: // value for 'choices'
 *      choiceIdsForDelete: // value for 'choiceIdsForDelete'
 *      shopChoiceIdsForDelete: // value for 'shopChoiceIdsForDelete'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useEditOptionUpdateOptionAndInsertChoicesMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionUpdateOptionAndInsertChoicesMutation, EditOptionUpdateOptionAndInsertChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionUpdateOptionAndInsertChoicesMutation, EditOptionUpdateOptionAndInsertChoicesMutationVariables>(EditOptionUpdateOptionAndInsertChoicesDocument, options);
      }
export type EditOptionUpdateOptionAndInsertChoicesMutationHookResult = ReturnType<typeof useEditOptionUpdateOptionAndInsertChoicesMutation>;
export type EditOptionUpdateOptionAndInsertChoicesMutationResult = Apollo.MutationResult<EditOptionUpdateOptionAndInsertChoicesMutation>;
export type EditOptionUpdateOptionAndInsertChoicesMutationOptions = Apollo.BaseMutationOptions<EditOptionUpdateOptionAndInsertChoicesMutation, EditOptionUpdateOptionAndInsertChoicesMutationVariables>;
export const EditOptionGetOptionDocument = gql`
    query EditOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    maxChoiceNum
    inputType
    name
    minChoiceNum
    optionId
    receiptDisplayName
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      costPrice
      costTaxRate
      isDefaultSelection
      name
      price
      priority
      receiptDisplayName
      menuType
      imageUrl
      shopChoices(
        where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
      ) {
        id
      }
    }
  }
}
    `;

/**
 * __useEditOptionGetOptionQuery__
 *
 * To run a query within a React component, call `useEditOptionGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionGetOptionQuery(baseOptions: Apollo.QueryHookOptions<EditOptionGetOptionQuery, EditOptionGetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionGetOptionQuery, EditOptionGetOptionQueryVariables>(EditOptionGetOptionDocument, options);
      }
export function useEditOptionGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionGetOptionQuery, EditOptionGetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionGetOptionQuery, EditOptionGetOptionQueryVariables>(EditOptionGetOptionDocument, options);
        }
export type EditOptionGetOptionQueryHookResult = ReturnType<typeof useEditOptionGetOptionQuery>;
export type EditOptionGetOptionLazyQueryHookResult = ReturnType<typeof useEditOptionGetOptionLazyQuery>;
export type EditOptionGetOptionQueryResult = Apollo.QueryResult<EditOptionGetOptionQuery, EditOptionGetOptionQueryVariables>;