import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuTranslationsGetMenu = gql`
    query EditMenuTranslationsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    name
    description
    totalDealingShopCount
    featuredLabelText
    company {
      id
    }
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    translations(where: {tableName: {_eq: "menu"}}) {
      columnName
      locale
      value
    }
  }
}
    `;
export const EditMenuTranslationsUpdateMenuTranslations = gql`
    mutation EditMenuTranslationsUpdateMenuTranslations($input: UpsertTranslationsForMenuInput!) {
  upsertTranslationsForMenu(input: $input)
}
    `;
export type EditMenuTranslationsGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuTranslationsGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'description' | 'totalDealingShopCount' | 'featuredLabelText'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ), shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type EditMenuTranslationsUpdateMenuTranslationsMutationVariables = Types.Exact<{
  input: Types.UpsertTranslationsForMenuInput;
}>;


export type EditMenuTranslationsUpdateMenuTranslationsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForMenu'>
);


export const EditMenuTranslationsGetMenuDocument = gql`
    query EditMenuTranslationsGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    name
    description
    totalDealingShopCount
    featuredLabelText
    company {
      id
    }
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    translations(where: {tableName: {_eq: "menu"}}) {
      columnName
      locale
      value
    }
  }
}
    `;

/**
 * __useEditMenuTranslationsGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuTranslationsGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuTranslationsGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuTranslationsGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuTranslationsGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuTranslationsGetMenuQuery, EditMenuTranslationsGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuTranslationsGetMenuQuery, EditMenuTranslationsGetMenuQueryVariables>(EditMenuTranslationsGetMenuDocument, options);
      }
export function useEditMenuTranslationsGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuTranslationsGetMenuQuery, EditMenuTranslationsGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuTranslationsGetMenuQuery, EditMenuTranslationsGetMenuQueryVariables>(EditMenuTranslationsGetMenuDocument, options);
        }
export type EditMenuTranslationsGetMenuQueryHookResult = ReturnType<typeof useEditMenuTranslationsGetMenuQuery>;
export type EditMenuTranslationsGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuTranslationsGetMenuLazyQuery>;
export type EditMenuTranslationsGetMenuQueryResult = Apollo.QueryResult<EditMenuTranslationsGetMenuQuery, EditMenuTranslationsGetMenuQueryVariables>;
export const EditMenuTranslationsUpdateMenuTranslationsDocument = gql`
    mutation EditMenuTranslationsUpdateMenuTranslations($input: UpsertTranslationsForMenuInput!) {
  upsertTranslationsForMenu(input: $input)
}
    `;
export type EditMenuTranslationsUpdateMenuTranslationsMutationFn = Apollo.MutationFunction<EditMenuTranslationsUpdateMenuTranslationsMutation, EditMenuTranslationsUpdateMenuTranslationsMutationVariables>;

/**
 * __useEditMenuTranslationsUpdateMenuTranslationsMutation__
 *
 * To run a mutation, you first call `useEditMenuTranslationsUpdateMenuTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuTranslationsUpdateMenuTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuTranslationsUpdateMenuTranslationsMutation, { data, loading, error }] = useEditMenuTranslationsUpdateMenuTranslationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuTranslationsUpdateMenuTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuTranslationsUpdateMenuTranslationsMutation, EditMenuTranslationsUpdateMenuTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuTranslationsUpdateMenuTranslationsMutation, EditMenuTranslationsUpdateMenuTranslationsMutationVariables>(EditMenuTranslationsUpdateMenuTranslationsDocument, options);
      }
export type EditMenuTranslationsUpdateMenuTranslationsMutationHookResult = ReturnType<typeof useEditMenuTranslationsUpdateMenuTranslationsMutation>;
export type EditMenuTranslationsUpdateMenuTranslationsMutationResult = Apollo.MutationResult<EditMenuTranslationsUpdateMenuTranslationsMutation>;
export type EditMenuTranslationsUpdateMenuTranslationsMutationOptions = Apollo.BaseMutationOptions<EditMenuTranslationsUpdateMenuTranslationsMutation, EditMenuTranslationsUpdateMenuTranslationsMutationVariables>;