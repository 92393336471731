import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import {
  claimKeyForHasuraCompanyUuid,
  claimKeyForHasuraCorporationId,
  claimKeyForHasuraDashboardAccountId,
  claimKeyForHasuraDefaultRole,
  claimNamespaceForHasura,
} from "constants/hasura";
import { useSetCurrentUser } from "hooks/useUser";
import { auth } from "libs/firebase";

declare module "@firebase/auth" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ParsedToken {
    email?: string;
    [claimNamespaceForHasura]?: {
      [claimKeyForHasuraDefaultRole]?: string;
      [claimKeyForHasuraDashboardAccountId]?: string;
      [claimKeyForHasuraCompanyUuid]?: string;
      [claimKeyForHasuraCorporationId]?: string;
    };
  }
}

export const useFirebaseAuth = () => {
  const { setCurrentUser } = useSetCurrentUser();

  useEffect(
    () =>
      auth.onIdTokenChanged(async (currentUser) => {
        const claims = (await currentUser?.getIdTokenResult())?.claims;
        const hasuraClaims = claims?.[claimNamespaceForHasura];

        const role = hasuraClaims?.[claimKeyForHasuraDefaultRole] ?? "";

        if (currentUser && role !== "anonymous") {
          setCurrentUser({
            email: currentUser.email,
            uid: currentUser.uid,
            role,
          });
        }
      }),
    [setCurrentUser],
  );

  useEffect(
    () =>
      auth.onAuthStateChanged(async (currentUser) => {
        if (!currentUser) {
          setCurrentUser(null);
        } else {
          const idTokenResult = await currentUser.getIdTokenResult();
          const claims = idTokenResult.claims;
          Sentry.setTags({
            role: claims[claimNamespaceForHasura]?.[claimKeyForHasuraDefaultRole],
            companyId: claims[claimNamespaceForHasura]?.[claimKeyForHasuraCompanyUuid],
            accountId: claims[claimNamespaceForHasura]?.[claimKeyForHasuraDashboardAccountId],
          });
          Sentry.setUser({ id: currentUser.uid, email: claims["email"] });
        }
      }),
    [setCurrentUser],
  );
};
