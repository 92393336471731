import { useCallback } from "react";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { createFormItem, Form } from "components/antd/Form";

import { CsvName } from "../../../models/csvName";

export type AggregatedDataFormValues = {
  dateRange?: RangeValue<dayjs.Dayjs>;
  csvNames: CsvName[];
};

const getInitialValues: () => AggregatedDataFormValues = () => ({
  dateRange: [
    dayjs().subtract(1, "months").startOf("month"),
    dayjs().subtract(1, "months").endOf("month"),
  ],
  csvNames: [],
});

export type OnSubmitAggregatedData = ({
  dateRange,
  csvNames,
}: {
  dateRange?: RangeValue<dayjs.Dayjs>;
  csvNames: CsvName[];
}) => Promise<void>;

export const AggregatedDataFormItem = createFormItem<AggregatedDataFormValues>();

export const useAggregatedDataForm = ({ onSubmit }: { onSubmit: OnSubmitAggregatedData }) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AggregatedDataFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
