import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AccountType, AddGmoBankAccountFormItem } from "../useAddGmoBankAccountForm";

const { Option } = Select;

type Props = {
  accountTypes: readonly AccountType[];
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const AccountTypeField = memo<Props>(({ accountTypes, disabled, ...props }) => (
  <AddGmoBankAccountFormItem.NonProperty noStyle>
    <AddGmoBankAccountFormItem
      label="口座種別"
      name="accountType"
      rules={[{ required: true, message: "口座種別を選択して下さい" }]}
      {...props}
    >
      <Select<string> placeholder="口座種別を選択" disabled={disabled}>
        {accountTypes.map(({ value, label }) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </AddGmoBankAccountFormItem>
  </AddGmoBankAccountFormItem.NonProperty>
));
