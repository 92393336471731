import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import {
  SupportedLocaleEnum,
  SupportedLocaleEnumType,
  UpsertTranslationsForMenuInput,
} from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

import { Menu, Translation } from "../types";

export type EditMenuTranslationsFormValues = Partial<{
  name: string;
  nameEn: string;
  nameKr: string;
  nameCn: string;

  description: string;
  descriptionEn: string;
  descriptionKr: string;
  descriptionCn: string;

  featureLabel: string;
  featureLabelEn: string;
  featureLabelKr: string;
  featureLabelCn: string;
}>;

const getInitialValues = ({
  menu,
  localeToNameTranslationMap,
  localeToDescriptionTranslationMap,
  localeToFeatureLabelTranslationMap,
}: {
  menu: Menu | undefined;
  localeToNameTranslationMap: Map<SupportedLocaleEnum, Translation>;
  localeToDescriptionTranslationMap: Map<SupportedLocaleEnum, Translation>;
  localeToFeatureLabelTranslationMap: Map<SupportedLocaleEnum, Translation>;
}): EditMenuTranslationsFormValues => ({
  name: menu?.name,
  nameEn: localeToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  nameKr: localeToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  nameCn: localeToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,

  description: menu?.description ?? "",
  descriptionEn: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  descriptionKr: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  descriptionCn: localeToDescriptionTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,

  featureLabel: menu?.featuredLabelText ?? "",
  featureLabelEn: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  featureLabelKr: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  featureLabelCn: localeToFeatureLabelTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
});

export const EditMenuTranslationsFormItem = createFormItem<EditMenuTranslationsFormValues>();

export const useEditMenuTranslationsForm = ({
  menu,
  translations,
  onSubmit,
}: {
  menu: Menu | undefined;
  translations: Translation[];
  onSubmit: (inputs: UpsertTranslationsForMenuInput) => void;
}) => {
  const localeToNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Name)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const localeToDescriptionTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Description)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const localeToFeatureLabelTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter(
            (translation) => translation.columnName === TranslationColumnNameEnum.FeaturedLabelText,
          )
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const [form] = Form.useForm<EditMenuTranslationsFormValues>();

  const initialValues = useMemo(
    () =>
      getInitialValues({
        menu,
        localeToNameTranslationMap,
        localeToDescriptionTranslationMap,
        localeToFeatureLabelTranslationMap,
      }),
    [
      localeToDescriptionTranslationMap,
      localeToFeatureLabelTranslationMap,
      localeToNameTranslationMap,
      menu,
    ],
  );

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    const {
      nameEn,
      nameKr,
      nameCn,
      descriptionEn,
      descriptionCn,
      descriptionKr,
      featureLabelEn,
      featureLabelCn,
      featureLabelKr,
    } = formValues;

    const inputs = [
      {
        value: nameEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.Name,
      },
      {
        value: nameKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.Name,
      },
      {
        value: nameCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.Name,
      },
      {
        value: descriptionEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: descriptionKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: descriptionCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.Description,
      },
      {
        value: featureLabelEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
      {
        value: featureLabelKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
      {
        value: featureLabelCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
        columnName: TranslationColumnNameEnum.FeaturedLabelText,
      },
    ];

    const filterAndMapSources = (columnName: TranslationColumnNameEnum) =>
      inputs
        .filter((input) => input.columnName === columnName)
        .map(({ value, locale }) => ({ value, locale }));

    menu &&
      onSubmit({
        menuId: menu.id,
        companyId: menu.company.id,
        nameSources: filterAndMapSources(TranslationColumnNameEnum.Name),
        featureLabelSources: filterAndMapSources(TranslationColumnNameEnum.FeaturedLabelText),
        descriptionSources: filterAndMapSources(TranslationColumnNameEnum.Description),
      });
  }, [form, menu, onSubmit]);

  return { form, initialValues, submit };
};
