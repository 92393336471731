import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddDishUpReceiptGroupInsertDishUpReceiptGroup = gql`
    mutation AddDishUpReceiptGroupInsertDishUpReceiptGroup($dishUpGroups: [dishUpSlipGroup_insert_input!]!) {
  insert_dishUpSlipGroup(
    objects: $dishUpGroups
    on_conflict: {constraint: dishUpSlipGroup_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      id: serial
    }
  }
}
    `;
export const AddDishUpReceiptGroupGetRoles = gql`
    query AddDishUpReceiptGroupGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(where: {shop: {archivedAt: {_is_null: true}}}, order_by: {roleId: asc}) {
      id
      name
      roleId
      shop {
        dishUpSlipGroups {
          id: serial
          name
        }
      }
    }
  }
}
    `;
export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationVariables = Types.Exact<{
  dishUpGroups: Array<Types.DishUpSlipGroupInsertInput> | Types.DishUpSlipGroupInsertInput;
}>;


export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dishUpSlipGroup?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroup_mutation_response' }
    & Pick<Types.DishUpSlipGroupMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dishUpSlipGroup' }
      & { id: Types.DishUpSlipGroup['serial'] }
    )> }
  )> }
);

export type AddDishUpReceiptGroupGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddDishUpReceiptGroupGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
      & { shop: (
        { __typename?: 'shop' }
        & { dishUpSlipGroups: Array<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      ) }
    )> }
  )> }
);


export const AddDishUpReceiptGroupInsertDishUpReceiptGroupDocument = gql`
    mutation AddDishUpReceiptGroupInsertDishUpReceiptGroup($dishUpGroups: [dishUpSlipGroup_insert_input!]!) {
  insert_dishUpSlipGroup(
    objects: $dishUpGroups
    on_conflict: {constraint: dishUpSlipGroup_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      id: serial
    }
  }
}
    `;
export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationFn = Apollo.MutationFunction<AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation, AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationVariables>;

/**
 * __useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation__
 *
 * To run a mutation, you first call `useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDishUpReceiptGroupInsertDishUpReceiptGroupMutation, { data, loading, error }] = useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation({
 *   variables: {
 *      dishUpGroups: // value for 'dishUpGroups'
 *   },
 * });
 */
export function useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation, AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation, AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationVariables>(AddDishUpReceiptGroupInsertDishUpReceiptGroupDocument, options);
      }
export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationHookResult = ReturnType<typeof useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation>;
export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationResult = Apollo.MutationResult<AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation>;
export type AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationOptions = Apollo.BaseMutationOptions<AddDishUpReceiptGroupInsertDishUpReceiptGroupMutation, AddDishUpReceiptGroupInsertDishUpReceiptGroupMutationVariables>;
export const AddDishUpReceiptGroupGetRolesDocument = gql`
    query AddDishUpReceiptGroupGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(where: {shop: {archivedAt: {_is_null: true}}}, order_by: {roleId: asc}) {
      id
      name
      roleId
      shop {
        dishUpSlipGroups {
          id: serial
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAddDishUpReceiptGroupGetRolesQuery__
 *
 * To run a query within a React component, call `useAddDishUpReceiptGroupGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpReceiptGroupGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDishUpReceiptGroupGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddDishUpReceiptGroupGetRolesQuery(baseOptions: Apollo.QueryHookOptions<AddDishUpReceiptGroupGetRolesQuery, AddDishUpReceiptGroupGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDishUpReceiptGroupGetRolesQuery, AddDishUpReceiptGroupGetRolesQueryVariables>(AddDishUpReceiptGroupGetRolesDocument, options);
      }
export function useAddDishUpReceiptGroupGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDishUpReceiptGroupGetRolesQuery, AddDishUpReceiptGroupGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDishUpReceiptGroupGetRolesQuery, AddDishUpReceiptGroupGetRolesQueryVariables>(AddDishUpReceiptGroupGetRolesDocument, options);
        }
export type AddDishUpReceiptGroupGetRolesQueryHookResult = ReturnType<typeof useAddDishUpReceiptGroupGetRolesQuery>;
export type AddDishUpReceiptGroupGetRolesLazyQueryHookResult = ReturnType<typeof useAddDishUpReceiptGroupGetRolesLazyQuery>;
export type AddDishUpReceiptGroupGetRolesQueryResult = Apollo.QueryResult<AddDishUpReceiptGroupGetRolesQuery, AddDishUpReceiptGroupGetRolesQueryVariables>;