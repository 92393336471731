import React, { memo } from "react";
import { Flex, Tooltip, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const QrCodeTableTitle = memo<{ title: string; description: string }>(
  ({ title, description }) => (
    <Flex align="center" justify="center" gap={12}>
      <Typography>{title}</Typography>
      <Tooltip title={description}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Flex>
  ),
);
