import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled: boolean;
};

export const ReceiptDisplayNameField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanChoiceFormItem
    label="伝票表示名"
    name="receiptDisplayName"
    rules={[{ required: true, message: "伝票表示名を入力してください" }]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditPlanChoiceFormItem>
));
