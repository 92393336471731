import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import * as uuid from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { WinboardInflowSourceTagInsertInput } from "types/graphql";

import { EditWinboardInflowSourceTagForm } from "./EditWinboardInflowSourceTagForm";
import {
  useEditWinboardInflowSourceTagGetInflowSourceTagQuery,
  useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation,
} from "./queries";

export const EditWinboardInflowSourceTag = () => {
  const { id: inflowSourceTagId } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    data: getInflowSourceTagData,
    loading: loadingInflowSourceTag,
    refetch: refetchInflowSourceTag,
    error: getInflowSourceTagError,
  } = useEditWinboardInflowSourceTagGetInflowSourceTagQuery(
    inflowSourceTagId ? { variables: { inflowSourceTagId } } : { skip: true },
  );
  const inflowSourceTag = getInflowSourceTagData?.inflowSourceTag_by_pk;

  const [upsertWinboardInflowSourceTagMutation, { loading: loadingUpsertWinboardInflowSourceTag }] =
    useEditWinboardInflowSourceTagUpdateWinboardInflowSourceTagMutation();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit = useCallback(
    async ({ code }: { code: string }) => {
      if (!inflowSourceTagId) return;

      const id = inflowSourceTag?.winboardInflowSourceTag?.id ?? uuid.v4();
      const dto: WinboardInflowSourceTagInsertInput = {
        code,
        id,
        inflowSourceTagId,
      };
      try {
        await upsertWinboardInflowSourceTagMutation({
          variables: {
            winboardInflowSourceTag: dto,
          },
        });
        await refetchInflowSourceTag();
        message.success("更新しました");
        goBack();
      } catch {
        message.error("更新に失敗しました");
      }
    },
    [
      goBack,
      inflowSourceTag?.winboardInflowSourceTag?.id,
      inflowSourceTagId,
      upsertWinboardInflowSourceTagMutation,
      refetchInflowSourceTag,
    ],
  );

  const loading = loadingInflowSourceTag || loadingUpsertWinboardInflowSourceTag;
  const shouldShowAlert = getInflowSourceTagError;

  return (
    <DashboardLayout
      title={inflowSourceTag?.displayName ?? ""}
      locationBreadcrumb={{ showShop: false, items: [{ name: "媒体設定" }] }}
    >
      <PageHeader title={inflowSourceTag?.displayName} onBack={goBack} />
      {loading && <Loading height={300} />}

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {inflowSourceTag && (
        <EditWinboardInflowSourceTagForm
          inflowSourceTag={inflowSourceTag}
          loading={loading}
          onSubmit={onSubmit}
          onClose={goBack}
        />
      )}
    </DashboardLayout>
  );
};
