import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SalesAnalyticsGetMonthlySalesBudget = gql`
    query SalesAnalyticsGetMonthlySalesBudget($shopId: uuid!, $startMonth: date!, $endMonth: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $startMonth, _lte: $endMonth}}
  ) {
    id
    businessDate
    taxExcludedAmount
    dailySalesBudgets {
      id
      businessDate
      taxExcludedAmount
    }
  }
}
    `;
export const GetSalesAnalytics = gql`
    query GetSalesAnalytics($input: SalesAnalyticsInput!) {
  salesAnalytics(input: $input) {
    rows {
      name
      shopName
      shopId
      businessDaysCount
      customerCount
      newCustomerCount
      repeatVisitCustomerCount
      numPeople
      takeOutNumPeople
      eatInNumPeople
      checkedInGroupCount
      groupCount
      mobileOrderQuantity
      nonMobileOrderQuantity
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      takeOutTotalTaxIncludedAmount
      takeOutTotalTaxExcludedAmount
      eatInTotalTaxIncludedAmount
      eatInTotalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      repeatOrderQuantity
      firstTimeOrderQuantity
      orderedQuantity
      repeaterTableTotalTaxExcludedAmount
      repeaterTableTotalTaxIncludedAmount
      dinnerTotalTaxIncludedAmount
      lunchTotalTaxIncludedAmount
      faveYellTotalTaxIncludedAmount
      drinkTotalTaxIncludedAmount
      foodTotalTaxIncludedAmount
      otherTotalTaxIncludedAmount
      planTotalTaxIncludedAmount
      dinnerTotalTaxExcludedAmount
      lunchTotalTaxExcludedAmount
      faveYellTotalTaxExcludedAmount
      drinkTotalTaxExcludedAmount
      foodTotalTaxExcludedAmount
      otherTotalTaxExcludedAmount
      planTotalTaxExcludedAmount
      previousMonthTotalTaxIncludedAmount
      previousMonthTotalTaxExcludedAmount
      previousYearTotalTaxIncludedAmount
      previousYearTotalTaxExcludedAmount
      previousMonthSameDowTotalTaxIncludedAmount
      previousMonthSameDowTotalTaxExcludedAmount
      previousYearSameDowTotalTaxIncludedAmount
      previousYearSameDowTotalTaxExcludedAmount
      salesTargetAmount
      lunchCustomerCount
      dinnerCustomerCount
      ambassadorCount
      introducedCustomerCount
    }
  }
}
    `;
export type SalesAnalyticsGetMonthlySalesBudgetQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  startMonth: Types.Scalars['date'];
  endMonth: Types.Scalars['date'];
}>;


export type SalesAnalyticsGetMonthlySalesBudgetQuery = (
  { __typename?: 'query_root' }
  & { monthlySalesBudget: Array<(
    { __typename?: 'monthlySalesBudget' }
    & Pick<Types.MonthlySalesBudget, 'id' | 'businessDate' | 'taxExcludedAmount'>
    & { dailySalesBudgets: Array<(
      { __typename?: 'dailySalesBudget' }
      & Pick<Types.DailySalesBudget, 'id' | 'businessDate' | 'taxExcludedAmount'>
    )> }
  )> }
);

export type GetSalesAnalyticsQueryVariables = Types.Exact<{
  input: Types.SalesAnalyticsInput;
}>;


export type GetSalesAnalyticsQuery = (
  { __typename?: 'query_root' }
  & { salesAnalytics: (
    { __typename?: 'SalesAnalyticsOutput' }
    & { rows: Array<(
      { __typename?: 'SalesAnalyticsOutputItem' }
      & Pick<Types.SalesAnalyticsOutputItem, 'name' | 'shopName' | 'shopId' | 'businessDaysCount' | 'customerCount' | 'newCustomerCount' | 'repeatVisitCustomerCount' | 'numPeople' | 'takeOutNumPeople' | 'eatInNumPeople' | 'checkedInGroupCount' | 'groupCount' | 'mobileOrderQuantity' | 'nonMobileOrderQuantity' | 'totalTaxIncludedAmount' | 'totalTaxExcludedAmount' | 'takeOutTotalTaxIncludedAmount' | 'takeOutTotalTaxExcludedAmount' | 'eatInTotalTaxIncludedAmount' | 'eatInTotalTaxExcludedAmount' | 'totalTaxIncludedCostAmount' | 'totalTaxExcludedCostAmount' | 'totalTaxIncludedNetProfitAmount' | 'totalTaxExcludedNetProfitAmount' | 'repeatOrderQuantity' | 'firstTimeOrderQuantity' | 'orderedQuantity' | 'repeaterTableTotalTaxExcludedAmount' | 'repeaterTableTotalTaxIncludedAmount' | 'dinnerTotalTaxIncludedAmount' | 'lunchTotalTaxIncludedAmount' | 'faveYellTotalTaxIncludedAmount' | 'drinkTotalTaxIncludedAmount' | 'foodTotalTaxIncludedAmount' | 'otherTotalTaxIncludedAmount' | 'planTotalTaxIncludedAmount' | 'dinnerTotalTaxExcludedAmount' | 'lunchTotalTaxExcludedAmount' | 'faveYellTotalTaxExcludedAmount' | 'drinkTotalTaxExcludedAmount' | 'foodTotalTaxExcludedAmount' | 'otherTotalTaxExcludedAmount' | 'planTotalTaxExcludedAmount' | 'previousMonthTotalTaxIncludedAmount' | 'previousMonthTotalTaxExcludedAmount' | 'previousYearTotalTaxIncludedAmount' | 'previousYearTotalTaxExcludedAmount' | 'previousMonthSameDowTotalTaxIncludedAmount' | 'previousMonthSameDowTotalTaxExcludedAmount' | 'previousYearSameDowTotalTaxIncludedAmount' | 'previousYearSameDowTotalTaxExcludedAmount' | 'salesTargetAmount' | 'lunchCustomerCount' | 'dinnerCustomerCount' | 'ambassadorCount' | 'introducedCustomerCount'>
    )> }
  ) }
);


export const SalesAnalyticsGetMonthlySalesBudgetDocument = gql`
    query SalesAnalyticsGetMonthlySalesBudget($shopId: uuid!, $startMonth: date!, $endMonth: date!) {
  monthlySalesBudget(
    where: {shopId: {_eq: $shopId}, businessDate: {_gte: $startMonth, _lte: $endMonth}}
  ) {
    id
    businessDate
    taxExcludedAmount
    dailySalesBudgets {
      id
      businessDate
      taxExcludedAmount
    }
  }
}
    `;

/**
 * __useSalesAnalyticsGetMonthlySalesBudgetQuery__
 *
 * To run a query within a React component, call `useSalesAnalyticsGetMonthlySalesBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesAnalyticsGetMonthlySalesBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesAnalyticsGetMonthlySalesBudgetQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      startMonth: // value for 'startMonth'
 *      endMonth: // value for 'endMonth'
 *   },
 * });
 */
export function useSalesAnalyticsGetMonthlySalesBudgetQuery(baseOptions: Apollo.QueryHookOptions<SalesAnalyticsGetMonthlySalesBudgetQuery, SalesAnalyticsGetMonthlySalesBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesAnalyticsGetMonthlySalesBudgetQuery, SalesAnalyticsGetMonthlySalesBudgetQueryVariables>(SalesAnalyticsGetMonthlySalesBudgetDocument, options);
      }
export function useSalesAnalyticsGetMonthlySalesBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesAnalyticsGetMonthlySalesBudgetQuery, SalesAnalyticsGetMonthlySalesBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesAnalyticsGetMonthlySalesBudgetQuery, SalesAnalyticsGetMonthlySalesBudgetQueryVariables>(SalesAnalyticsGetMonthlySalesBudgetDocument, options);
        }
export type SalesAnalyticsGetMonthlySalesBudgetQueryHookResult = ReturnType<typeof useSalesAnalyticsGetMonthlySalesBudgetQuery>;
export type SalesAnalyticsGetMonthlySalesBudgetLazyQueryHookResult = ReturnType<typeof useSalesAnalyticsGetMonthlySalesBudgetLazyQuery>;
export type SalesAnalyticsGetMonthlySalesBudgetQueryResult = Apollo.QueryResult<SalesAnalyticsGetMonthlySalesBudgetQuery, SalesAnalyticsGetMonthlySalesBudgetQueryVariables>;
export const GetSalesAnalyticsDocument = gql`
    query GetSalesAnalytics($input: SalesAnalyticsInput!) {
  salesAnalytics(input: $input) {
    rows {
      name
      shopName
      shopId
      businessDaysCount
      customerCount
      newCustomerCount
      repeatVisitCustomerCount
      numPeople
      takeOutNumPeople
      eatInNumPeople
      checkedInGroupCount
      groupCount
      mobileOrderQuantity
      nonMobileOrderQuantity
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      takeOutTotalTaxIncludedAmount
      takeOutTotalTaxExcludedAmount
      eatInTotalTaxIncludedAmount
      eatInTotalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      repeatOrderQuantity
      firstTimeOrderQuantity
      orderedQuantity
      repeaterTableTotalTaxExcludedAmount
      repeaterTableTotalTaxIncludedAmount
      dinnerTotalTaxIncludedAmount
      lunchTotalTaxIncludedAmount
      faveYellTotalTaxIncludedAmount
      drinkTotalTaxIncludedAmount
      foodTotalTaxIncludedAmount
      otherTotalTaxIncludedAmount
      planTotalTaxIncludedAmount
      dinnerTotalTaxExcludedAmount
      lunchTotalTaxExcludedAmount
      faveYellTotalTaxExcludedAmount
      drinkTotalTaxExcludedAmount
      foodTotalTaxExcludedAmount
      otherTotalTaxExcludedAmount
      planTotalTaxExcludedAmount
      previousMonthTotalTaxIncludedAmount
      previousMonthTotalTaxExcludedAmount
      previousYearTotalTaxIncludedAmount
      previousYearTotalTaxExcludedAmount
      previousMonthSameDowTotalTaxIncludedAmount
      previousMonthSameDowTotalTaxExcludedAmount
      previousYearSameDowTotalTaxIncludedAmount
      previousYearSameDowTotalTaxExcludedAmount
      salesTargetAmount
      lunchCustomerCount
      dinnerCustomerCount
      ambassadorCount
      introducedCustomerCount
    }
  }
}
    `;

/**
 * __useGetSalesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetSalesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>(GetSalesAnalyticsDocument, options);
      }
export function useGetSalesAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>(GetSalesAnalyticsDocument, options);
        }
export type GetSalesAnalyticsQueryHookResult = ReturnType<typeof useGetSalesAnalyticsQuery>;
export type GetSalesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetSalesAnalyticsLazyQuery>;
export type GetSalesAnalyticsQueryResult = Apollo.QueryResult<GetSalesAnalyticsQuery, GetSalesAnalyticsQueryVariables>;