import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { Switch, Tag } from "antd";
import { isSoldOut } from "models/stock";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { FormHelp } from "components/Form/FormHelp";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { Plan, PlanChoice } from "pages/ShopPlans/types";

type Props = {
  loading?: boolean;
  shopId?: string;
  plans: Plan[];
  onUpdateIsVisibleForCustomer: ({
    planId,
    isVisibleForCustomer,
  }: {
    planId: string;
    isVisibleForCustomer: boolean;
  }) => void;
  onUpdateIsVisibleForStaff: ({
    planId,
    isVisibleForStaff,
  }: {
    planId: string;
    isVisibleForStaff: boolean;
  }) => void;
  onUpdateIsSoldOut: ({ planId, isSoldOut }: { planId: string; isSoldOut: boolean }) => void;
  onUpsertShopPlanChoice: ({
    planChoiceId,
    isVisibleForCustomer,
    isVisibleForStaff,
    isSoldOut,
  }: {
    planChoiceId: string;
    isVisibleForCustomer?: boolean;
    isVisibleForStaff?: boolean;
    isSoldOut?: boolean;
  }) => void;
};

type RowItem =
  | ({ type: "plan" } & Plan)
  | ({ type: "planChoice" } & PlanChoice & { _planId: number; _planOptionId: number });

export const ShopPlanTable = memo<Props>(
  ({
    loading,
    shopId,
    plans,
    onUpdateIsVisibleForCustomer,
    onUpdateIsVisibleForStaff,
    onUpdateIsSoldOut,
    onUpsertShopPlanChoice,
  }) => {
    const [pagination, setPagination] = usePagination();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const rows = useMemo<RowItem[]>(
      () =>
        plans.map((plan) => ({
          ...plan,
          type: "plan",
          children:
            plan.planOptions.length > 0
              ? plan.planOptions.flatMap((planOption) =>
                  planOption.planChoices.map((planChoice) => ({
                    ...planChoice,
                    type: "planChoice",
                    _planId: plan._planId,
                    planOptionId: planOption.planOptionId,
                    name: `${planOption.name} ${planChoice.name}`,
                  })),
                )
              : null,
        })),
      [plans],
    );

    const columns = [
      {
        title: "",
        width: 40,
      } as const,
      {
        title: "プラン名",
        render(_: string, rowItem: RowItem) {
          if (shopId === undefined) return null;

          switch (rowItem.type) {
            case "planChoice": {
              const { _planId, _planOptionId, _planChoiceId } = rowItem;
              return (
                <Link to={`/plan/${_planId}/option/${_planOptionId}/choice/${_planChoiceId}/edit`}>
                  {rowItem.name}
                </Link>
              );
            }
            case "plan": {
              const { _planId, planName } = rowItem;
              return <Link to={`/plan/${_planId}/edit`}>{planName}</Link>;
            }
            default:
              return null;
          }
        },
      },
      {
        title: "公開設定",
        children: [
          {
            title() {
              return <FormHelp label="お客様" help="スマートフォン上での表示設定" />;
            },
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "planChoice": {
                  const { planChoiceId, shopPlanChoices } = rowItem;
                  const shopPlanChoice = shopPlanChoices[0];
                  const isVisibleForCustomer = shopPlanChoice?.isVisibleForCustomer ?? true;

                  return (
                    <Switch
                      checked={isVisibleForCustomer}
                      onChange={(value) =>
                        onUpsertShopPlanChoice({
                          planChoiceId,
                          isVisibleForCustomer: value,
                        })
                      }
                      disabled={!isFeatureEnabled("editShopPlanDisplaySettings")}
                    />
                  );
                }
                case "plan": {
                  const { planId, isVisibleForCustomer } = rowItem;

                  return (
                    <Switch
                      checked={isVisibleForCustomer}
                      onChange={(value) =>
                        onUpdateIsVisibleForCustomer({ planId, isVisibleForCustomer: value })
                      }
                      disabled={!isFeatureEnabled("editShopPlanDisplaySettings")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
          {
            title() {
              return <FormHelp label="スタッフ" help="ハンディ上での表示設定" />;
            },
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "planChoice": {
                  const { planChoiceId, shopPlanChoices } = rowItem;
                  const shopPlanChoice = shopPlanChoices[0];
                  const isVisibleForStaff = shopPlanChoice?.isVisibleForStaff ?? true;

                  return (
                    <Switch
                      checked={isVisibleForStaff}
                      onChange={(value) =>
                        onUpsertShopPlanChoice({
                          planChoiceId,
                          isVisibleForStaff: value,
                        })
                      }
                      disabled={!isFeatureEnabled("editShopPlanDisplaySettings")}
                    />
                  );
                }
                case "plan": {
                  const { planId, isVisibleForStaff } = rowItem;

                  return (
                    <Switch
                      checked={isVisibleForStaff}
                      onChange={(value) =>
                        onUpdateIsVisibleForStaff({ planId, isVisibleForStaff: value })
                      }
                      disabled={!isFeatureEnabled("editShopPlanDisplaySettings")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
        ],
      },
      {
        title: "在庫設定",
        children: [
          {
            title: "在庫",
            align: "center",
            width: 100,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "planChoice": {
                  const { planChoiceId, shopPlanChoices } = rowItem;
                  const shopPlanChoice = shopPlanChoices[0];
                  const stock = shopPlanChoice?.stock;

                  return (
                    <Switch
                      checked={!isSoldOut({ stock })}
                      onChange={(value) =>
                        onUpsertShopPlanChoice({
                          planChoiceId,
                          isSoldOut: !value,
                        })
                      }
                      disabled={!isFeatureEnabled("editShopPlanStock")}
                    />
                  );
                }
                case "plan": {
                  const { planId, stock } = rowItem;

                  return (
                    <Switch
                      checked={!isSoldOut({ stock })}
                      onChange={(isSoldOut) => onUpdateIsSoldOut({ planId, isSoldOut })}
                      disabled={!isFeatureEnabled("editShopPlanStock")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
        ],
      },
      {
        title: "印刷設定",
        children: [
          {
            title: "キッチンプリンター",
            width: 160,
            render(_: unknown, rowItem: RowItem) {
              if (rowItem.type !== "plan") return null;
              return rowItem.shopPlanKitchenRoles.map(({ role }) => (
                <Tag key={role?.roleId}>{role?.name}</Tag>
              ));
            },
          },
          {
            title: "デシャップグループ",
            width: 160,
            render(_: number, rowItem: RowItem) {
              if (rowItem.type !== "plan") return null;
              return rowItem.dishUpSlipGroupShopPlans.map(({ dishUpSlipGroup }) => (
                <Tag key={dishUpSlipGroup?.id}>{dishUpSlipGroup?.name}</Tag>
              ));
            },
          } as const,
          ...(canAccess("editShopPlanRoles")
            ? [
                {
                  title: "",
                  align: "center",
                  width: 60,
                  render(_: string, rowItem: RowItem) {
                    if (shopId === undefined) return null;
                    if (rowItem.type !== "plan") return null;
                    return (
                      <IconLink to={`/shop/${shopId}/plan/${rowItem._planId}/role/edit`}>
                        <EditIcon />
                      </IconLink>
                    );
                  },
                } as const,
              ]
            : []),
        ],
      },
    ];

    return (
      <Table
        rowKey={(rowItem) =>
          rowItem.type === "plan" ? `plan:${rowItem._planId}` : `choice:${rowItem._planChoiceId}`
        }
        columns={columns}
        dataSource={rows}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
