type Params = {
  url: string;
  init: RequestInit;
  onJsonObjectReceived: (data: object) => void;
  onError: (error: unknown) => void;
};

export const streamedResponseFetch = async ({
  url,
  init,
  onJsonObjectReceived,
  onError,
}: Params) => {
  const decoder = new TextDecoder();

  // NOTE: 未完成の json string を保留する変数
  let accumulativeString = "";

  const handleChunk = (chunk: Uint8Array) => {
    const string = decoder.decode(chunk);

    accumulativeString += string;

    /* NOTE: because we use newline-delimited json, if the accumulative string contains a newline character,
     * there is a json object ready to parse
     * https://jsonlines.org
     */
    if (accumulativeString.includes("\n")) {
      // NOTE: json の後に必ず newline があるため、最後の文字列が未完成の json 文字列かデータの終わりです
      // それ以外の文字列は valid json
      const rawLines = accumulativeString.split("\n");

      const incompleteJsonString = rawLines.pop();

      accumulativeString = incompleteJsonString ?? "";

      rawLines.forEach((jsonString) => {
        onJsonObjectReceived(JSON.parse(jsonString));
      });
    }
  };

  try {
    const res = await fetch(url, init);
    const stream = res.body;

    if (!stream) return;

    const reader = stream.getReader();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();

      // when no more data needs to be consumed, break the reading
      if (done) {
        break;
      }

      // pass received chunk to supplied callback
      handleChunk(value);
    }
  } catch (err) {
    onError(err);
  }
};
