import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Company } from "types/graphql";

export type AddCompanyFormValues = Pick<Company, "name"> & {
  corporationId: string;
};

const getInitialValues = () => ({});

export const AddCompanyFormItem = createFormItem<AddCompanyFormValues>();

export const useAddCompanyForm = (onSubmit: (args: AddCompanyFormValues) => void) => {
  const [form] = Form.useForm<AddCompanyFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
