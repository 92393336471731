import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { EditServiceAdminForm } from "pages/EditServiceAdmin/EditServiceAdminForm";
import { useEditServiceAdminGetServiceAdminQuery } from "pages/EditServiceAdmin/queries";

export const EditServiceAdmin = () => {
  const { id: serviceAdminId } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    data: getServiceAdminData,
    loading: loadingServiceAdmin,
    error,
  } = useEditServiceAdminGetServiceAdminQuery(
    serviceAdminId ? { variables: { serviceAdminId } } : { skip: true },
  );
  const serviceAdmin = getServiceAdminData?.serviceAdmin_by_pk;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={serviceAdmin?.email}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "サービス管理者" }],
      }}
    >
      <PageHeader title={serviceAdmin?.email} onBack={goBack} />
      {loadingServiceAdmin && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {serviceAdmin && (
        <EditServiceAdminForm
          serviceAdmin={serviceAdmin}
          onClose={goBack}
          loading={loadingServiceAdmin}
        />
      )}
    </DashboardLayout>
  );
};
