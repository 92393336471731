import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddDishUpSlipGroupFormItem } from "../useAddDishUpSlipGroupForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddDishUpSlipGroupFormItem
    label="グループ名"
    name="name"
    rules={[{ required: true, message: "デシャップグループ名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddDishUpSlipGroupFormItem>
));
