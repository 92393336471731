import React, { ChangeEvent, memo, useCallback } from "react";
import { Input, InputProps } from "antd";

type Props = InputProps;

export const InputCode = memo<Props>(({ onChange, ...props }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = e.target.value.replace(/[０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0),
      );

      onChange?.(e);
    },
    [onChange],
  );

  return <Input onChange={handleChange} {...props} />;
});
