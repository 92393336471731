import React, { memo } from "react";
import { Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  findSortKeyByDayWeek,
  formatExternalOnlineMenuAvailableTimeTermDayWeek,
  formatExternalOnlineMenuAvailableTimeWithChangeDateTime,
} from "models/externalOnlineMenuAvailableTime";
import { normalizeDuration } from "util/duration";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { OrderableTimeTermDayWeekEnum } from "types/graphql";

import { ExternalOnlineMenu, ExternalOnlineMenuAvailableTimeTerm } from "../types";

type Props = {
  externalOnlineMenus: ExternalOnlineMenu[];
  externalOnlineMenuConfigId?: string;
  shopId: string | null;
  changeDateTime: string;
  loading: boolean;
};

export const ShopExternalOnlineMenuTable = memo<Props>(
  ({ externalOnlineMenus, externalOnlineMenuConfigId, shopId, changeDateTime, loading }) => {
    const [pagination, setPagination] = usePagination();
    const columns: ColumnsType<ExternalOnlineMenu> = [
      { title: "名前", dataIndex: "name" },
      { title: "URL", dataIndex: "url" },
      {
        title: "利用時間",
        width: 250,
        dataIndex: "externalOnlineMenuAvailableTimeTerms",
        render: (externalOnlineMenuAvailableTimeTerms: ExternalOnlineMenuAvailableTimeTerm[]) => (
          <Space direction="vertical">
            {externalOnlineMenuAvailableTimeTerms
              .slice()
              .sort(
                (a, b) =>
                  findSortKeyByDayWeek(a.dayWeek as OrderableTimeTermDayWeekEnum) -
                  findSortKeyByDayWeek(b.dayWeek as OrderableTimeTermDayWeekEnum),
              )
              .map((term) => {
                const dayWeekName = formatExternalOnlineMenuAvailableTimeTermDayWeek(term.dayWeek);
                const { start, end } = formatExternalOnlineMenuAvailableTimeWithChangeDateTime({
                  ...normalizeDuration(term),
                  changeDateTime,
                });
                return (
                  <Typography.Text key={term.id}>
                    {dayWeekName}: {start} ~ {end}
                  </Typography.Text>
                );
              })}
          </Space>
        ),
      },
      {
        title: "",
        width: 60,
        align: "center",
        fixed: "right",
        dataIndex: "id",
        render: (id: string) => {
          if (!shopId || !externalOnlineMenuConfigId) return null;
          return externalOnlineMenuConfigId === id ? (
            <IconLink to={`/shop/${shopId}/externalOnlineMenuConfig`}>
              <EditIcon />
            </IconLink>
          ) : (
            <IconLink to={`/shop/${shopId}/externalOnlineMenu/${id}/edit`}>
              <EditIcon />
            </IconLink>
          );
        },
      },
    ];
    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={externalOnlineMenus}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
