import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { useShop } from "hooks/useShop";
import { EditClerkForm } from "pages/EditClerk/EditClerkForm";
import {
  useEditClerkGetClerkQuery,
  useEditClerkUpdateClerkMutation,
} from "pages/EditClerk/queries";
import { ClerkSetInput, ShopClerkSetInput } from "types/graphql";

export const EditClerk = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { id } = useParams<{ id: string }>();
  const clerkId = Number(id);
  const navigate = useNavigate();

  const {
    data: getClerkData,
    loading: loadingClerk,
    refetch: refetchClerk,
    error,
  } = useEditClerkGetClerkQuery(
    shopId && !Number.isNaN(clerkId) ? { variables: { shopId, clerkId } } : { skip: true },
  );
  const shopClerk = getClerkData?.shopClerk?.[0];
  const clerk = shopClerk?.clerk && { ...shopClerk?.clerk, isDisplay: shopClerk?.isDisplay };

  useSetCurrentCompanyEffect(clerk);

  const [updateClerkMutation, { loading: loadingUpdateClerk }] = useEditClerkUpdateClerkMutation();

  const onSubmit = useCallback(
    async ({ clerk, shopClerk }: { clerk: ClerkSetInput; shopClerk: ShopClerkSetInput }) => {
      if (shopId && !Number.isNaN(clerkId)) {
        try {
          await updateClerkMutation({
            variables: { clerkId, clerk, shopId, shopClerk },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }

        await refetchClerk();
      }
    },
    [shopId, clerkId, updateClerkMutation, refetchClerk],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={clerk?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "店員" }],
      }}
    >
      <PageHeader title={clerk?.name} onBack={goBack} />
      {loadingClerk && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {clerk && (
        <EditClerkForm
          clerk={clerk}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateClerk}
        />
      )}
    </DashboardLayout>
  );
};
