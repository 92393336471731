import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import {
  SupportedLocaleEnum,
  SupportedLocaleEnumType,
  UpsertTranslationsForCategoryInput,
} from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

import { Category, Translation } from "../types";

export type EditCategoryTranslationsFormValues = Partial<{
  name: string;
  nameEn: string;
  nameKr: string;
  nameCn: string;
}>;

const getInitialValues = ({
  category,
  localeToNameTranslationMap,
}: {
  category: Category | undefined;
  localeToNameTranslationMap: Map<SupportedLocaleEnum, Translation>;
}): EditCategoryTranslationsFormValues => ({
  name: category?.name,
  nameEn: localeToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  nameKr: localeToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  nameCn: localeToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
});

export const EditCategoryTranslationsFormItem =
  createFormItem<EditCategoryTranslationsFormValues>();

export const useEditCategoryTranslationsForm = ({
  category,
  translations,
  onSubmit,
}: {
  category: Category | undefined;
  translations: Translation[];
  onSubmit: (inputs: UpsertTranslationsForCategoryInput) => void;
}) => {
  const localeToNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Name)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const [form] = Form.useForm<EditCategoryTranslationsFormValues>();

  const initialValues = useMemo(
    () =>
      getInitialValues({
        category,
        localeToNameTranslationMap,
      }),
    [category, localeToNameTranslationMap],
  );

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    const { nameEn, nameKr, nameCn } = formValues;

    const nameSources = [
      {
        value: nameEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
      },
      {
        value: nameKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
      },
      {
        value: nameCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
      },
    ];

    category &&
      onSubmit({
        categoryId: category.id,
        companyId: category.company.id,
        nameSources,
      });
  }, [form, category, onSubmit]);

  return { form, initialValues, submit };
};
