import React, { memo } from "react";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrUndefined } from "hooks/useFilterConditions";

type Props = {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
};

export const IsSoldOutField = memo<Props>(({ value, onChange }) => (
  <Select<string>
    showSearch
    allowClear
    defaultValue="undefined"
    value={String(value)}
    onChange={(SoldOut) => onChange(convertStringToBooleanOrUndefined(SoldOut))}
    optionFilterProp="label"
    options={[
      { label: "在庫設定", value: "undefined" },
      { label: "在庫あり", value: "false" },
      { label: "在庫なし", value: "true" },
    ]}
  />
));
