import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AccountingDetailGetOnSitePayment = gql`
    query accountingDetailGetOnSitePayment($onSitePaymentId: Int!) {
  onSitePayment(
    where: {onSitePaymentId: {_eq: $onSitePaymentId}, shop: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    onSitePaymentId
    closeCashRegisterId
    createdAt
    voidedAt
    localAccountingSlipId
    shopId
    shop {
      shopId
      companyId
    }
    payingActivePlanChoices {
      payingActivePlanChoiceId: id
      quantity
      price
      activePlanChoiceId
      itemSales {
        id
        billingAmount
        taxAmount
      }
      activePlanChoice {
        activePlanChoiceId
        name
        price
        taxMethod
        taxRate
        activePlanOption {
          name
          activePlan {
            name
            activePlanId
          }
          activePlanOptionId
        }
        quantity
        createdAt
      }
    }
    payingActivePlanOpenPriceMetas {
      payingActivePlanOpenPriceMetaId
      quantity
      price
      itemSales {
        id
        billingAmount
        taxAmount
      }
      activePlanOpenPriceMeta {
        price
        taxMethod
        taxRate
        id
        activePlan {
          name
          activePlanId
          startAt
        }
      }
      activePlanOpenPriceMetaId
    }
    payingMenuOrderItems {
      id
      menuOrderItemId
      quantity
      price
      itemSales {
        id
        billingAmount
        taxAmount
      }
      menuOrderItem {
        id
        name
        price
        quantity
        taxMethod
        taxRate
        order {
          orderedAt
        }
        menuOrderItemOptions {
          id
          optionId: _optionId
          name
          menuOrderItemChoices {
            id
            choiceId: _choiceId
            name
            price
            quantity
          }
        }
      }
    }
    receipts {
      createdAt
      receiptId
      charge
      clerk {
        clerkId
        name
      }
    }
    onSitePaymentTableUsers {
      tableUser {
        activatedAt
        deactivatedAt
        numPeople
        inflowSourceTag {
          displayName
        }
        customerSegment
      }
    }
    onSitePaymentDetails {
      clerkId: _clerkId
      clerk {
        id
        name
        clerkId
      }
      changePrice
      netPrice
      receivedPrice
      onSitePaymentDetailId: id
      onSitePaymentDetailType
      description
      onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype {
        label
        allowChange
        type
      }
    }
    onSitePaymentDiscounts {
      amount
      clerkId: _clerkId
      clerk {
        id
        name
        clerkId
      }
      createdAt
      description
      onSitePaymentDiscountId
      onSitePaymentDiscountType
      onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype {
        label
        type
      }
    }
    onSitePaymentTableUsers {
      tableUser {
        table {
          name
          tableId
        }
      }
    }
  }
}
    `;
export const AccountingDetailUpdateOnSitePayment = gql`
    mutation AccountingDetailUpdateOnSitePayment($input: UpdateOnSitePaymentInput!) {
  dashboardAccountUpdateOnSitePayment(input: $input)
}
    `;
export const AccountingDetailCancelOnSitePayment = gql`
    mutation AccountingDetailCancelOnSitePayment($input: DashboardAccountCancelOnSitePaymentInput!) {
  dashboardAccountCancelOnSitePayment(input: $input)
}
    `;
export type AccountingDetailGetOnSitePaymentQueryVariables = Types.Exact<{
  onSitePaymentId: Types.Scalars['Int'];
}>;


export type AccountingDetailGetOnSitePaymentQuery = (
  { __typename?: 'query_root' }
  & { onSitePayment: Array<(
    { __typename?: 'onSitePayment' }
    & Pick<Types.OnSitePayment, 'id' | 'onSitePaymentId' | 'closeCashRegisterId' | 'createdAt' | 'voidedAt' | 'localAccountingSlipId' | 'shopId'>
    & { shop: (
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId' | 'companyId'>
    ), payingActivePlanChoices: Array<(
      { __typename?: 'payingActivePlanChoice' }
      & Pick<Types.PayingActivePlanChoice, 'quantity' | 'price' | 'activePlanChoiceId'>
      & { payingActivePlanChoiceId: Types.PayingActivePlanChoice['id'] }
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'id' | 'billingAmount' | 'taxAmount'>
      )>, activePlanChoice: (
        { __typename?: 'activePlanChoice' }
        & Pick<Types.ActivePlanChoice, 'activePlanChoiceId' | 'name' | 'price' | 'taxMethod' | 'taxRate' | 'quantity' | 'createdAt'>
        & { activePlanOption: (
          { __typename?: 'activePlanOption' }
          & Pick<Types.ActivePlanOption, 'name' | 'activePlanOptionId'>
          & { activePlan: (
            { __typename?: 'activePlan' }
            & Pick<Types.ActivePlan, 'name' | 'activePlanId'>
          ) }
        ) }
      ) }
    )>, payingActivePlanOpenPriceMetas: Array<(
      { __typename?: 'payingActivePlanOpenPriceMeta' }
      & Pick<Types.PayingActivePlanOpenPriceMeta, 'payingActivePlanOpenPriceMetaId' | 'quantity' | 'price' | 'activePlanOpenPriceMetaId'>
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'id' | 'billingAmount' | 'taxAmount'>
      )>, activePlanOpenPriceMeta: (
        { __typename?: 'activePlanOpenPriceMeta' }
        & Pick<Types.ActivePlanOpenPriceMeta, 'price' | 'taxMethod' | 'taxRate' | 'id'>
        & { activePlan: (
          { __typename?: 'activePlan' }
          & Pick<Types.ActivePlan, 'name' | 'activePlanId' | 'startAt'>
        ) }
      ) }
    )>, payingMenuOrderItems: Array<(
      { __typename?: 'payingMenuOrderItem' }
      & Pick<Types.PayingMenuOrderItem, 'id' | 'menuOrderItemId' | 'quantity' | 'price'>
      & { itemSales?: Types.Maybe<(
        { __typename?: 'itemSales' }
        & Pick<Types.ItemSales, 'id' | 'billingAmount' | 'taxAmount'>
      )>, menuOrderItem: (
        { __typename?: 'menuOrderItem' }
        & Pick<Types.MenuOrderItem, 'id' | 'name' | 'price' | 'quantity' | 'taxMethod' | 'taxRate'>
        & { order: (
          { __typename?: 'order' }
          & Pick<Types.Order, 'orderedAt'>
        ), menuOrderItemOptions: Array<(
          { __typename?: 'menuOrderItemOption' }
          & Pick<Types.MenuOrderItemOption, 'id' | 'name'>
          & { optionId: Types.MenuOrderItemOption['_optionId'] }
          & { menuOrderItemChoices: Array<(
            { __typename?: 'menuOrderItemChoice' }
            & Pick<Types.MenuOrderItemChoice, 'id' | 'name' | 'price' | 'quantity'>
            & { choiceId: Types.MenuOrderItemChoice['_choiceId'] }
          )> }
        )> }
      ) }
    )>, receipts: Array<(
      { __typename?: 'receipt' }
      & Pick<Types.Receipt, 'createdAt' | 'receiptId' | 'charge'>
      & { clerk: (
        { __typename?: 'clerk' }
        & Pick<Types.Clerk, 'clerkId' | 'name'>
      ) }
    )>, onSitePaymentTableUsers: Array<(
      { __typename?: 'onSitePaymentTableUsers' }
      & { tableUser: (
        { __typename?: 'tableUser' }
        & Pick<Types.TableUser, 'activatedAt' | 'deactivatedAt' | 'numPeople' | 'customerSegment'>
        & { inflowSourceTag: (
          { __typename?: 'inflowSourceTag' }
          & Pick<Types.InflowSourceTag, 'displayName'>
        ), table: (
          { __typename?: 'table' }
          & Pick<Types.Table, 'name' | 'tableId'>
        ) }
      ) }
    )>, onSitePaymentDetails: Array<(
      { __typename?: 'onSitePaymentDetail' }
      & Pick<Types.OnSitePaymentDetail, 'changePrice' | 'netPrice' | 'receivedPrice' | 'onSitePaymentDetailType' | 'description'>
      & { clerkId: Types.OnSitePaymentDetail['_clerkId'], onSitePaymentDetailId: Types.OnSitePaymentDetail['id'] }
      & { clerk?: Types.Maybe<(
        { __typename?: 'clerk' }
        & Pick<Types.Clerk, 'id' | 'name' | 'clerkId'>
      )>, onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype: (
        { __typename?: 'onSitePaymentDetailType' }
        & Pick<Types.OnSitePaymentDetailType, 'label' | 'allowChange' | 'type'>
      ) }
    )>, onSitePaymentDiscounts: Array<(
      { __typename?: 'onSitePaymentDiscount' }
      & Pick<Types.OnSitePaymentDiscount, 'amount' | 'createdAt' | 'description' | 'onSitePaymentDiscountId' | 'onSitePaymentDiscountType'>
      & { clerkId: Types.OnSitePaymentDiscount['_clerkId'] }
      & { clerk?: Types.Maybe<(
        { __typename?: 'clerk' }
        & Pick<Types.Clerk, 'id' | 'name' | 'clerkId'>
      )>, onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype: (
        { __typename?: 'onSitePaymentDiscountType' }
        & Pick<Types.OnSitePaymentDiscountType, 'label' | 'type'>
      ) }
    )> }
  )> }
);

export type AccountingDetailUpdateOnSitePaymentMutationVariables = Types.Exact<{
  input: Types.UpdateOnSitePaymentInput;
}>;


export type AccountingDetailUpdateOnSitePaymentMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountUpdateOnSitePayment'>
);

export type AccountingDetailCancelOnSitePaymentMutationVariables = Types.Exact<{
  input: Types.DashboardAccountCancelOnSitePaymentInput;
}>;


export type AccountingDetailCancelOnSitePaymentMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCancelOnSitePayment'>
);


export const AccountingDetailGetOnSitePaymentDocument = gql`
    query accountingDetailGetOnSitePayment($onSitePaymentId: Int!) {
  onSitePayment(
    where: {onSitePaymentId: {_eq: $onSitePaymentId}, shop: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    onSitePaymentId
    closeCashRegisterId
    createdAt
    voidedAt
    localAccountingSlipId
    shopId
    shop {
      shopId
      companyId
    }
    payingActivePlanChoices {
      payingActivePlanChoiceId: id
      quantity
      price
      activePlanChoiceId
      itemSales {
        id
        billingAmount
        taxAmount
      }
      activePlanChoice {
        activePlanChoiceId
        name
        price
        taxMethod
        taxRate
        activePlanOption {
          name
          activePlan {
            name
            activePlanId
          }
          activePlanOptionId
        }
        quantity
        createdAt
      }
    }
    payingActivePlanOpenPriceMetas {
      payingActivePlanOpenPriceMetaId
      quantity
      price
      itemSales {
        id
        billingAmount
        taxAmount
      }
      activePlanOpenPriceMeta {
        price
        taxMethod
        taxRate
        id
        activePlan {
          name
          activePlanId
          startAt
        }
      }
      activePlanOpenPriceMetaId
    }
    payingMenuOrderItems {
      id
      menuOrderItemId
      quantity
      price
      itemSales {
        id
        billingAmount
        taxAmount
      }
      menuOrderItem {
        id
        name
        price
        quantity
        taxMethod
        taxRate
        order {
          orderedAt
        }
        menuOrderItemOptions {
          id
          optionId: _optionId
          name
          menuOrderItemChoices {
            id
            choiceId: _choiceId
            name
            price
            quantity
          }
        }
      }
    }
    receipts {
      createdAt
      receiptId
      charge
      clerk {
        clerkId
        name
      }
    }
    onSitePaymentTableUsers {
      tableUser {
        activatedAt
        deactivatedAt
        numPeople
        inflowSourceTag {
          displayName
        }
        customerSegment
      }
    }
    onSitePaymentDetails {
      clerkId: _clerkId
      clerk {
        id
        name
        clerkId
      }
      changePrice
      netPrice
      receivedPrice
      onSitePaymentDetailId: id
      onSitePaymentDetailType
      description
      onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype {
        label
        allowChange
        type
      }
    }
    onSitePaymentDiscounts {
      amount
      clerkId: _clerkId
      clerk {
        id
        name
        clerkId
      }
      createdAt
      description
      onSitePaymentDiscountId
      onSitePaymentDiscountType
      onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype {
        label
        type
      }
    }
    onSitePaymentTableUsers {
      tableUser {
        table {
          name
          tableId
        }
      }
    }
  }
}
    `;

/**
 * __useAccountingDetailGetOnSitePaymentQuery__
 *
 * To run a query within a React component, call `useAccountingDetailGetOnSitePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingDetailGetOnSitePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingDetailGetOnSitePaymentQuery({
 *   variables: {
 *      onSitePaymentId: // value for 'onSitePaymentId'
 *   },
 * });
 */
export function useAccountingDetailGetOnSitePaymentQuery(baseOptions: Apollo.QueryHookOptions<AccountingDetailGetOnSitePaymentQuery, AccountingDetailGetOnSitePaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingDetailGetOnSitePaymentQuery, AccountingDetailGetOnSitePaymentQueryVariables>(AccountingDetailGetOnSitePaymentDocument, options);
      }
export function useAccountingDetailGetOnSitePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingDetailGetOnSitePaymentQuery, AccountingDetailGetOnSitePaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingDetailGetOnSitePaymentQuery, AccountingDetailGetOnSitePaymentQueryVariables>(AccountingDetailGetOnSitePaymentDocument, options);
        }
export type AccountingDetailGetOnSitePaymentQueryHookResult = ReturnType<typeof useAccountingDetailGetOnSitePaymentQuery>;
export type AccountingDetailGetOnSitePaymentLazyQueryHookResult = ReturnType<typeof useAccountingDetailGetOnSitePaymentLazyQuery>;
export type AccountingDetailGetOnSitePaymentQueryResult = Apollo.QueryResult<AccountingDetailGetOnSitePaymentQuery, AccountingDetailGetOnSitePaymentQueryVariables>;
export const AccountingDetailUpdateOnSitePaymentDocument = gql`
    mutation AccountingDetailUpdateOnSitePayment($input: UpdateOnSitePaymentInput!) {
  dashboardAccountUpdateOnSitePayment(input: $input)
}
    `;
export type AccountingDetailUpdateOnSitePaymentMutationFn = Apollo.MutationFunction<AccountingDetailUpdateOnSitePaymentMutation, AccountingDetailUpdateOnSitePaymentMutationVariables>;

/**
 * __useAccountingDetailUpdateOnSitePaymentMutation__
 *
 * To run a mutation, you first call `useAccountingDetailUpdateOnSitePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountingDetailUpdateOnSitePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountingDetailUpdateOnSitePaymentMutation, { data, loading, error }] = useAccountingDetailUpdateOnSitePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountingDetailUpdateOnSitePaymentMutation(baseOptions?: Apollo.MutationHookOptions<AccountingDetailUpdateOnSitePaymentMutation, AccountingDetailUpdateOnSitePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountingDetailUpdateOnSitePaymentMutation, AccountingDetailUpdateOnSitePaymentMutationVariables>(AccountingDetailUpdateOnSitePaymentDocument, options);
      }
export type AccountingDetailUpdateOnSitePaymentMutationHookResult = ReturnType<typeof useAccountingDetailUpdateOnSitePaymentMutation>;
export type AccountingDetailUpdateOnSitePaymentMutationResult = Apollo.MutationResult<AccountingDetailUpdateOnSitePaymentMutation>;
export type AccountingDetailUpdateOnSitePaymentMutationOptions = Apollo.BaseMutationOptions<AccountingDetailUpdateOnSitePaymentMutation, AccountingDetailUpdateOnSitePaymentMutationVariables>;
export const AccountingDetailCancelOnSitePaymentDocument = gql`
    mutation AccountingDetailCancelOnSitePayment($input: DashboardAccountCancelOnSitePaymentInput!) {
  dashboardAccountCancelOnSitePayment(input: $input)
}
    `;
export type AccountingDetailCancelOnSitePaymentMutationFn = Apollo.MutationFunction<AccountingDetailCancelOnSitePaymentMutation, AccountingDetailCancelOnSitePaymentMutationVariables>;

/**
 * __useAccountingDetailCancelOnSitePaymentMutation__
 *
 * To run a mutation, you first call `useAccountingDetailCancelOnSitePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountingDetailCancelOnSitePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountingDetailCancelOnSitePaymentMutation, { data, loading, error }] = useAccountingDetailCancelOnSitePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountingDetailCancelOnSitePaymentMutation(baseOptions?: Apollo.MutationHookOptions<AccountingDetailCancelOnSitePaymentMutation, AccountingDetailCancelOnSitePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountingDetailCancelOnSitePaymentMutation, AccountingDetailCancelOnSitePaymentMutationVariables>(AccountingDetailCancelOnSitePaymentDocument, options);
      }
export type AccountingDetailCancelOnSitePaymentMutationHookResult = ReturnType<typeof useAccountingDetailCancelOnSitePaymentMutation>;
export type AccountingDetailCancelOnSitePaymentMutationResult = Apollo.MutationResult<AccountingDetailCancelOnSitePaymentMutation>;
export type AccountingDetailCancelOnSitePaymentMutationOptions = Apollo.BaseMutationOptions<AccountingDetailCancelOnSitePaymentMutation, AccountingDetailCancelOnSitePaymentMutationVariables>;