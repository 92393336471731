import React, { memo } from "react";
import { Button, FormInstance, Modal } from "antd";

import { FormList } from "components/antd/Form";
import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";

import { AddOptionFormValues } from "../useAddOptionForm";

type Props = {
  form: FormInstance<{ choices: AddOptionFormValues["choices"] }>;
  visible: boolean;
  closeModal(): void;
};

export const EditChoiceOrderModal = memo<Props>(({ form, visible, closeModal }) => (
  <Modal
    title="選択肢の並び替え"
    open={visible}
    footer={<Button onClick={closeModal}>閉じる</Button>}
    onCancel={closeModal}
  >
    <FormList name="choices">
      {(fields, { move }) => (
        <PriorityList<{ name: number; key: number }>
          loading={false}
          itemLayout="horizontal"
          dataSource={fields}
          renderItem={({ key }, index) => {
            const choice: AddOptionFormValues["choices"][number] = form.getFieldValue([
              "choices",
              index,
            ]);
            return (
              <PriorityListItem
                id={key}
                name={choice.name || "新しい選択肢"}
                priority={index}
                index={index}
                onMove={(dragIndex, hoverIndex) => {
                  if (dragIndex !== hoverIndex) {
                    move(dragIndex, hoverIndex);
                  }
                }}
              />
            );
          }}
        />
      )}
    </FormList>
  </Modal>
));
