import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { Plan } from "pages/EditPlanPriorities/types";
import { PlanInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  plans: Plan[];
  onUpdate: (plans: PlanInsertInput[]) => void;
};

export const EditPlanPriorityList = memo<Props>(({ loading, plans, onUpdate }) => {
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex !== hoverIndex) {
      onUpdate(
        updatePriorities(plans, dragIndex, hoverIndex).map((plan) => {
          const { companyId, planId, ...rest } = plan;

          return {
            companyId,
            serial: planId,
            ...rest,
          };
        }),
      );
    }
  };

  return (
    <PriorityList<Plan>
      itemLayout="horizontal"
      loading={loading}
      dataSource={plans}
      renderItem={({ planId, planName, shopSideName, priority }, index) => (
        <PriorityListItem
          id={planId}
          name={shopSideName || planName}
          priority={priority}
          index={index}
          onMove={handleMoveItem}
        />
      )}
    />
  );
});
