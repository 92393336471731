import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddDashboardAccountFormItem } from "../useAddDashboardAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PasswordField = memo<Props>((props) => (
  <AddDashboardAccountFormItem
    label="パスワード"
    name="password"
    rules={[
      { required: true, message: "パスワードを入力してください" },
      {
        pattern: new RegExp(/(?=.*[0-9a-zA-Z]).{6,}/),
        message: "パスワードは6文字以上の英数字を入力してください",
      },
    ]}
    help="パスワードは英数字を組み合わせ6文字以上にしてください。"
    {...props}
  >
    <Input.Password placeholder="パスワード" />
  </AddDashboardAccountFormItem>
));
