import React, { memo } from "react";
import { DatePicker } from "antd";

import { FormSection } from "components/Form/FormSection";

import { EditSalesBudgetFormItem } from "../useEditSalesBudgetForm";

export const TargetMonthField = memo(() => (
  <FormSection title="1. 目標設定をする月を選択してください">
    <EditSalesBudgetFormItem
      label="月"
      name="targetMonth"
      rules={[{ required: true, message: "目標設定する月を選択してください" }]}
      endSpacer={null}
    >
      <DatePicker picker="month" disabled={true} />
    </EditSalesBudgetFormItem>
  </FormSection>
));
