import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const UploadMenuCsvVerifyGetActiveShops = gql`
    query UploadMenuCsvVerifyGetActiveShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}, tableUsers: {deactivatedAt: {_is_null: true}}}
  ) {
    shopId
    name
  }
}
    `;
export type UploadMenuCsvVerifyGetActiveShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type UploadMenuCsvVerifyGetActiveShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const UploadMenuCsvVerifyGetActiveShopsDocument = gql`
    query UploadMenuCsvVerifyGetActiveShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}, tableUsers: {deactivatedAt: {_is_null: true}}}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useUploadMenuCsvVerifyGetActiveShopsQuery__
 *
 * To run a query within a React component, call `useUploadMenuCsvVerifyGetActiveShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadMenuCsvVerifyGetActiveShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadMenuCsvVerifyGetActiveShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUploadMenuCsvVerifyGetActiveShopsQuery(baseOptions: Apollo.QueryHookOptions<UploadMenuCsvVerifyGetActiveShopsQuery, UploadMenuCsvVerifyGetActiveShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadMenuCsvVerifyGetActiveShopsQuery, UploadMenuCsvVerifyGetActiveShopsQueryVariables>(UploadMenuCsvVerifyGetActiveShopsDocument, options);
      }
export function useUploadMenuCsvVerifyGetActiveShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadMenuCsvVerifyGetActiveShopsQuery, UploadMenuCsvVerifyGetActiveShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadMenuCsvVerifyGetActiveShopsQuery, UploadMenuCsvVerifyGetActiveShopsQueryVariables>(UploadMenuCsvVerifyGetActiveShopsDocument, options);
        }
export type UploadMenuCsvVerifyGetActiveShopsQueryHookResult = ReturnType<typeof useUploadMenuCsvVerifyGetActiveShopsQuery>;
export type UploadMenuCsvVerifyGetActiveShopsLazyQueryHookResult = ReturnType<typeof useUploadMenuCsvVerifyGetActiveShopsLazyQuery>;
export type UploadMenuCsvVerifyGetActiveShopsQueryResult = Apollo.QueryResult<UploadMenuCsvVerifyGetActiveShopsQuery, UploadMenuCsvVerifyGetActiveShopsQueryVariables>;