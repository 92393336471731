import { QuestionCategoryType, QuestionnaireAnswererSegmentType } from "types/graphql";

export type AllTenantAverageScore = {
  answerCount: number;
  repeatability: number;
  customerService: number;
  speed: number;
  deliciousness: number;
  cleanliness: number;
};

/**
 * ダイニー導入店平均をリアルタイムで集計するのはかなり重いかつ、リアルタイムである必要はないので、先月の平均値を埋め混んで使う
 *
 * https://dinii.slack.com/archives/C011CD9MGTZ/p1669769581464729?thread_ts=1669678639.125039&cid=C011CD9MGTZ
 */
const lastMonthAllTenantAverageScore: Record<
  "all" | QuestionnaireAnswererSegmentType,
  AllTenantAverageScore
> =
  import.meta.env.APP_ENV === "production"
    ? {
        all: {
          answerCount: 60,
          repeatability: 82,
          customerService: 80,
          speed: 77,
          deliciousness: 84,
          cleanliness: 80,
        },
        [QuestionnaireAnswererSegmentType.NewCustomer]: {
          answerCount: 9,
          repeatability: 87,
          customerService: 81,
          speed: 79,
          deliciousness: 86,
          cleanliness: 81,
        },
        [QuestionnaireAnswererSegmentType.RepeatCustomer]: {
          answerCount: 12,
          repeatability: 89,
          customerService: 83,
          speed: 82,
          deliciousness: 86,
          cleanliness: 80,
        },
      }
    : import.meta.env.APP_ENV === "beta"
    ? {
        all: {
          answerCount: 66,
          repeatability: 80,
          customerService: 81,
          speed: 78,
          deliciousness: 82,
          cleanliness: 82,
        },
        [QuestionnaireAnswererSegmentType.NewCustomer]: {
          answerCount: 6,
          repeatability: 88,
          customerService: 83,
          speed: 82,
          deliciousness: 89,
          cleanliness: 83,
        },
        [QuestionnaireAnswererSegmentType.RepeatCustomer]: {
          answerCount: 3,
          repeatability: 91,
          customerService: 91,
          speed: 89,
          deliciousness: 93,
          cleanliness: 91,
        },
      }
    : {
        all: {
          answerCount: 30,
          repeatability: 80,
          customerService: 80,
          speed: 70,
          deliciousness: 90,
          cleanliness: 80,
        },
        [QuestionnaireAnswererSegmentType.NewCustomer]: {
          answerCount: 30,
          repeatability: 80,
          customerService: 80,
          speed: 70,
          deliciousness: 90,
          cleanliness: 80,
        },
        [QuestionnaireAnswererSegmentType.RepeatCustomer]: {
          answerCount: 30,
          repeatability: 80,
          customerService: 80,
          speed: 70,
          deliciousness: 90,
          cleanliness: 80,
        },
      };

export const getLastMonthAllTenantAverageScoreByAnswerSegments = (
  answerSegments: QuestionnaireAnswererSegmentType[],
): AllTenantAverageScore => {
  const segment = answerSegments.length === 1 ? answerSegments[0] ?? "all" : "all";

  return lastMonthAllTenantAverageScore[segment];
};

export type ScoreRank = "S" | "A" | "B" | "C" | "D";

const thresholds: Record<ScoreRank, number> = {
  S: 90,
  A: 80,
  B: 70,
  C: 60,
  D: 0,
};

export const BAD_SCORE_THRESHOLD = thresholds.C;

type RankViewProperties = {
  [R in ScoreRank]: {
    rank: R;
    color: `#${string}`;
    backgroundColor: `#${string}`;
    iconSrc: `/questionnaire-analytics/rank_face_icon_${Lowercase<ScoreRank>}.png`;
  };
};

export const rankViewProperties: RankViewProperties = {
  S: {
    rank: "S",
    color: "#F462A8",
    backgroundColor: "#FFE7F2",
    iconSrc: "/questionnaire-analytics/rank_face_icon_s.png",
  },
  A: {
    rank: "A",
    color: "#F4815C",
    backgroundColor: "#F8DBD2",
    iconSrc: "/questionnaire-analytics/rank_face_icon_a.png",
  },
  B: {
    rank: "B",
    color: "#FFDB5A",
    backgroundColor: "#FDF8CC",
    iconSrc: "/questionnaire-analytics/rank_face_icon_b.png",
  },
  C: {
    rank: "C",
    color: "#4FC79C",
    backgroundColor: "#D1EEE4",
    iconSrc: "/questionnaire-analytics/rank_face_icon_c.png",
  },
  D: {
    rank: "D",
    color: "#6F82E0",
    backgroundColor: "#D9DFFF",
    iconSrc: "/questionnaire-analytics/rank_face_icon_d.png",
  },
};

export const getRankViewPropertiesByScore = (score: number) => {
  if (score >= thresholds.S) return rankViewProperties.S;
  if (score >= thresholds.A) return rankViewProperties.A;
  if (score >= thresholds.B) return rankViewProperties.B;
  if (score >= thresholds.C) return rankViewProperties.C;

  return rankViewProperties.D;
};

export const applySignToFluctuation = (fluctuation: number): `${"+" | "-"}${number}` =>
  `${fluctuation >= 0 ? `+` : `-`}${Math.abs(fluctuation)}`;

type RankedInNumbers = 1 | 2 | 3;
const UNRANKED_MIN_RANK = 4;

export const isRankedIn = (rank: number): rank is RankedInNumbers => rank < UNRANKED_MIN_RANK;

export const QuestionCategoryTypeToWord: Record<QuestionCategoryType, string> = {
  Attribute: "属性",
  Cleanliness: "清潔感",
  CustomerService: "接客",
  Deliciousness: "料理",
  Repeatability: "再来店意欲",
  Speed: "提供速度",
};
