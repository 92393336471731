import { useMemo } from "react";
import { useAsync, useAsyncFn } from "react-use";

const BANK_CODE_API_BASE_URL = import.meta.env.BANK_CODE_API_BASE_URL ?? "";
const BANK_CODE_API_KEY = import.meta.env.BANK_CODE_API_KEY ?? "";

export type BankCode = {
  code: string;
  name: string;
};

export type BranchCode = {
  code: string;
  name: string;
};

const getValidatedBankCodes = (data: Record<string, unknown>): BankCode[] => {
  if (!Array.isArray(data)) return [];
  return data.flatMap((item) => {
    if (!("code" in item && "name" in item)) return [];
    const { code, name } = item;
    if (typeof code !== "string" || typeof name !== "string") return [];
    return [
      {
        code,
        name,
      },
    ];
  });
};

const getValidatedBranchCodes = (data: Record<string, unknown>): BranchCode[] => {
  if (!Array.isArray(data)) return [];
  return data.flatMap((item) => {
    if (!("code" in item && "name" in item)) return [];
    const { code, name } = item;
    if (typeof code !== "string" || typeof name !== "string") return [];
    return [
      {
        code,
        name,
      },
    ];
  });
};

const fetchBankCodes = async () => {
  // 全部で 1132 件（2023/09 現在）なので、limit 2000 で一気に取得する
  const response = await fetch(`${BANK_CODE_API_BASE_URL}/banks?limit=2000`, {
    method: "GET",
    headers: { apiKey: BANK_CODE_API_KEY },
  });
  if (!response.ok) return [];

  const data = await response.json();
  return getValidatedBankCodes(data.data);
};

const fetchBranchCodes = async ({ bankCode }: { bankCode: string }) => {
  const response = await fetch(`${BANK_CODE_API_BASE_URL}/banks/${bankCode}/branches?limit=2000`, {
    method: "GET",
    headers: { apiKey: BANK_CODE_API_KEY },
  });
  if (!response.ok) return [];

  const data = await response.json();
  return getValidatedBranchCodes(data.data);
};

export const useBankCode = () => {
  const {
    value: bankCodesData,
    loading: loadingBankCodes,
    error: getBankCodesError,
  } = useAsync(fetchBankCodes);

  const [
    { value: branchCodesData, loading: loadingBranchCodes, error: getBranchCodesError },
    fetchBranchCodeByBankCode,
  ] = useAsyncFn(fetchBranchCodes);

  const branchCodes = useMemo(() => branchCodesData ?? [], [branchCodesData]);

  // ゆうちょ銀行（9900）は除外する
  const bankCodes = useMemo(
    () => (bankCodesData ?? []).filter((bank) => bank.code !== "9900"),
    [bankCodesData],
  );

  return {
    bankCodes,
    branchCodes,
    loading: loadingBankCodes || loadingBranchCodes,
    error: getBankCodesError ?? getBranchCodesError,
    fetchBranchCodeByBankCode,
  };
};
