import React, { memo } from "react";
import styled from "styled-components";
import { SolutionOutlined } from "@ant-design/icons";

const NoDataText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
`;

const NoDataIcon = styled(SolutionOutlined)`
  font-size: 30px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
`;

export const NoDataDisplay = memo(() => (
  <NoDataText>
    <NoDataIcon />
    回答がありません
  </NoDataText>
));
