import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { couponAnalyticsProperties } from "models/couponAnalytics";

import { TableContent } from "components/AnalyticsTable/TableContent";
import { TableHeaderTitle } from "components/AnalyticsTable/TableHeaderTitle";
import { TableStyleModifier } from "components/AnalyticsTable/TableStyleModifier";
import { Spacer } from "components/Spacer";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { ShopCouponUsageSummary } from "../type";

const Name = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

const formatter = new Intl.NumberFormat("ja");

const { couponUsedCount, visitedTableUserCustomersCount, visitedTableUserCustomersSales } =
  couponAnalyticsProperties;

const columns: ColumnsType<ShopCouponUsageSummary> = [
  {
    title: "店舗名",
    dataIndex: "shopName",
    fixed: "left",
    align: "left",
    render: (_, { shopName }) => <Name>{shopName}</Name>,
  },
  {
    title: <TableHeaderTitle icon={couponUsedCount.iconSrc} title={couponUsedCount.label} />,
    align: "right",
    dataIndex: "couponUsedCount",
    width: 128,
    render: (value: number) => <TableContent content={String(value)} />,
    sorter: (a, b) => a.couponUsedCount - b.couponUsedCount,
  },
  {
    title: (
      <TableHeaderTitle
        icon={visitedTableUserCustomersCount.iconSrc}
        title={visitedTableUserCustomersCount.label}
      />
    ),
    align: "right",
    dataIndex: "visitedTableUserCustomersCount",
    width: 158,
    render: (value: number) => <TableContent content={String(value)} />,
    sorter: (a, b) => a.visitedTableUserCustomersCount - b.visitedTableUserCustomersCount,
  },
  {
    title: visitedTableUserCustomersSales.label,
    fixed: "right",
    align: "right",
    dataIndex: "visitedTableUserCustomersSales",
    width: 200,
    render: (value: number) => <TableContent content={`¥${formatter.format(value)}`} isHighlight />,
    sorter: (a, b) => a.visitedTableUserCustomersSales - b.visitedTableUserCustomersSales,
  },
];

type Props = {
  isLoading: boolean;
  summaryPerShop: ShopCouponUsageSummary[];
};

export const PerShopTable = memo<Props>(({ isLoading, summaryPerShop }) => {
  const [pagination, setPagination] = usePagination();

  const handleChangePagination = useCallback(
    ({ position: _, ...pagination }) => setPagination(pagination),
    [setPagination],
  );

  const showPagination = summaryPerShop.length >= 10;

  return (
    <TableStyleModifier>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={summaryPerShop}
        bordered
        pagination={showPagination ? pagination : false}
        onChange={handleChangePagination}
        scroll={{ x: 1136 }}
      />
      {!showPagination && <Spacer size={20} />}
    </TableStyleModifier>
  );
});
