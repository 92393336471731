import React from "react";
import { Alert } from "antd";
import dayjs from "dayjs";
import { FilterConditions } from "models/onSitePayment";
import { isNotNull } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useFillInRangeConditions } from "hooks/useFillInRangeConditions";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useLocationState } from "hooks/useLocationState";
import { useShop } from "hooks/useShop";
import { useSalesGetSalesQuery } from "pages/Sales/queries";
import { SalesFilter } from "pages/Sales/SalesFilter";
import { GroupVariables, SalesGroup } from "pages/Sales/SalesGroup";
import { SalesQueries } from "pages/Sales/SalesQueries";
import { SalesStatistics } from "pages/Sales/SalesStatistics/SalesStatistics";
import { SalesTable } from "pages/Sales/SalesTable";
import { SalesAggregationDimensionType } from "types/graphql";

type Serialized = { range?: [number | null, number | null] | undefined } & Omit<
  FilterConditions,
  "range"
>;

export const Sales = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    Serialized
  >({}, "filterConditions", {
    serialize: ({ range, ...filterConditions }) => ({
      ...filterConditions,
      range: serializeRange(range),
    }),
    deserialize: ({ range, ...filterConditions }) => ({
      ...filterConditions,
      range: deserializeRange(range),
    }),
  });

  useFillInRangeConditions(filterConditions, updateFilterCondition);

  const range = filterConditions.range?.every(isNotNull)
    ? (filterConditions.range as [dayjs.Dayjs, dayjs.Dayjs])
    : null;

  const [groupVariables, setGroupVariables] = useLocationState<GroupVariables>("groupVariables", {
    dimension: "hour",
  });

  const dimension = {
    day: SalesAggregationDimensionType.Day,
    dayOfWeek: SalesAggregationDimensionType.DayOfWeek,
    hour: SalesAggregationDimensionType.Hour,
    month: SalesAggregationDimensionType.Month,
    week: SalesAggregationDimensionType.Week,
  }[groupVariables.dimension];

  const {
    data: getSalesData,
    loading: loadingSales,
    error: getSalesDataError,
  } = useSalesGetSalesQuery(
    shopId && range
      ? {
          variables: {
            input: {
              shopId,
              dimension,
              startDate: range[0].format("YYYY-MM-DD"),
              endDate: range[1].format("YYYY-MM-DD"),
            },
          },
        }
      : { skip: true },
  );
  const sales = range ? getSalesData?.sales.aggregatedSales ?? [] : [];

  const shouldShowAlert = getSalesDataError;

  return (
    <DashboardLayout title="売上">
      <PageHeader
        title="売上"
        footer={
          <>
            <ShopSelector />
            <SalesQueries>
              <SalesFilter filterConditions={filterConditions} onChange={updateFilterCondition} />
              <SalesGroup values={groupVariables} onChange={setGroupVariables} />
            </SalesQueries>
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <SalesStatistics sales={sales} />
      <Spacer size={32} />
      <SalesTable sales={sales} loading={loadingSales} />
    </DashboardLayout>
  );
};
