import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { red } from "@ant-design/colors";
import { CloseCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

type Props = {
  visible: boolean;
  closeModal: () => void;
};

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  color: ${red[5]};
  font-size: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UploadMenuCodeCsvUploadFailureModal = memo(({ visible, closeModal }: Props) => (
  <Modal
    title={
      <TitleContainer>
        <StyledCloseCircleOutlined />
        <Spacer size={16} />
        メニューコード一括登録に失敗しました
      </TitleContainer>
    }
    centered
    closable={false}
    open={visible}
    footer={<Button onClick={closeModal}>閉じる</Button>}
    onCancel={closeModal}
  >
    <ContentWrapper>
      <Spacer size={38} />
      CSV のデータ反映時にエラーが発生しました。
      <br />
      データを確認するか、もう一度同じファイルのアップロードをお試しください。
    </ContentWrapper>
  </Modal>
));
