import React from "react";
import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { Select } from "components/Input/Select";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = {
  name: number;
  coupons: {
    id: string;
    name: string;
  }[];
};

export const CouponSelectField = React.memo<Props>(({ name, coupons }) => (
  <AddMessageDeliveryFormItem
    name={[name, "couponId"]}
    endSpacer={null}
    rules={[{ required: true, message: "クーポンを選択してください" }]}
  >
    <Select
      placeholder="クーポンを選択してください"
      options={coupons.map(({ id, name }) => ({ label: name, value: id }))}
      showSearch
      optionFilterProp="label"
      allowClear
      fullWidth
      notFoundContent={
        <Paragraph type="secondary">
          選択できるクーポンが存在しません。
          <Typography.Link href="/coupon/add" target="_blank">
            クーポン作成画面
          </Typography.Link>
          にてクーポンを作成してください。
        </Paragraph>
      }
    />
  </AddMessageDeliveryFormItem>
));
