import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanTecAggregationPlanGetPlan = gql`
    query EditPlanTecAggregationPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;
export const EditPlanTecAggregationPlanGetMenuCode = gql`
    query EditPlanTecAggregationPlanGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const EditPlanTecAggregationPlanUpdateTecAggregationMenu = gql`
    mutation EditPlanTecAggregationPlanUpdateTecAggregationMenu($tecAggregationMenu: DashboardAccountUpsertTecAggregationMenuInput!, $tecAggregationMenus: DashboardAccountUpsertTecAggregationMenusBulkInput!) {
  upsertTecAggregationMenu(input: $tecAggregationMenu) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
  upsertTecAggregationMenusBulk(input: $tecAggregationMenus) {
    ... on DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
      __typename
      dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
        __typename
        dpCode
        dpName
        gpCode
        gpName
        menuCode
        name
      }
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditPlanTecAggregationPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanTecAggregationPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'planChoiceId' | 'name'>
        & { tecAggregationMenu?: Types.Maybe<(
          { __typename?: 'tecAggregationMenu' }
          & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
        )> }
      )> }
    )> }
  )> }
);

export type EditPlanTecAggregationPlanGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanTecAggregationPlanGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  tecAggregationMenu: Types.DashboardAccountUpsertTecAggregationMenuInput;
  tecAggregationMenus: Types.DashboardAccountUpsertTecAggregationMenusBulkInput;
}>;


export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ), upsertTecAggregationMenusBulk: (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode' }
    & { dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode: Array<(
      { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
      & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
    )> }
  ) }
);


export const EditPlanTecAggregationPlanGetPlanDocument = gql`
    query EditPlanTecAggregationPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planId
    planName
    companyId
    totalDealingShopCount
    category {
      categoryId
      name
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;

/**
 * __useEditPlanTecAggregationPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanTecAggregationPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanTecAggregationPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanTecAggregationPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanTecAggregationPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanTecAggregationPlanGetPlanQuery, EditPlanTecAggregationPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanTecAggregationPlanGetPlanQuery, EditPlanTecAggregationPlanGetPlanQueryVariables>(EditPlanTecAggregationPlanGetPlanDocument, options);
      }
export function useEditPlanTecAggregationPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanTecAggregationPlanGetPlanQuery, EditPlanTecAggregationPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanTecAggregationPlanGetPlanQuery, EditPlanTecAggregationPlanGetPlanQueryVariables>(EditPlanTecAggregationPlanGetPlanDocument, options);
        }
export type EditPlanTecAggregationPlanGetPlanQueryHookResult = ReturnType<typeof useEditPlanTecAggregationPlanGetPlanQuery>;
export type EditPlanTecAggregationPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanTecAggregationPlanGetPlanLazyQuery>;
export type EditPlanTecAggregationPlanGetPlanQueryResult = Apollo.QueryResult<EditPlanTecAggregationPlanGetPlanQuery, EditPlanTecAggregationPlanGetPlanQueryVariables>;
export const EditPlanTecAggregationPlanGetMenuCodeDocument = gql`
    query EditPlanTecAggregationPlanGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useEditPlanTecAggregationPlanGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditPlanTecAggregationPlanGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanTecAggregationPlanGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanTecAggregationPlanGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanTecAggregationPlanGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditPlanTecAggregationPlanGetMenuCodeQuery, EditPlanTecAggregationPlanGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanTecAggregationPlanGetMenuCodeQuery, EditPlanTecAggregationPlanGetMenuCodeQueryVariables>(EditPlanTecAggregationPlanGetMenuCodeDocument, options);
      }
export function useEditPlanTecAggregationPlanGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanTecAggregationPlanGetMenuCodeQuery, EditPlanTecAggregationPlanGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanTecAggregationPlanGetMenuCodeQuery, EditPlanTecAggregationPlanGetMenuCodeQueryVariables>(EditPlanTecAggregationPlanGetMenuCodeDocument, options);
        }
export type EditPlanTecAggregationPlanGetMenuCodeQueryHookResult = ReturnType<typeof useEditPlanTecAggregationPlanGetMenuCodeQuery>;
export type EditPlanTecAggregationPlanGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditPlanTecAggregationPlanGetMenuCodeLazyQuery>;
export type EditPlanTecAggregationPlanGetMenuCodeQueryResult = Apollo.QueryResult<EditPlanTecAggregationPlanGetMenuCodeQuery, EditPlanTecAggregationPlanGetMenuCodeQueryVariables>;
export const EditPlanTecAggregationPlanUpdateTecAggregationMenuDocument = gql`
    mutation EditPlanTecAggregationPlanUpdateTecAggregationMenu($tecAggregationMenu: DashboardAccountUpsertTecAggregationMenuInput!, $tecAggregationMenus: DashboardAccountUpsertTecAggregationMenusBulkInput!) {
  upsertTecAggregationMenu(input: $tecAggregationMenu) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
  upsertTecAggregationMenusBulk(input: $tecAggregationMenus) {
    ... on DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
      __typename
      dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
        __typename
        dpCode
        dpName
        gpCode
        gpName
        menuCode
        name
      }
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation, EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationVariables>;

/**
 * __useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanTecAggregationPlanUpdateTecAggregationMenuMutation, { data, loading, error }] = useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation({
 *   variables: {
 *      tecAggregationMenu: // value for 'tecAggregationMenu'
 *      tecAggregationMenus: // value for 'tecAggregationMenus'
 *   },
 * });
 */
export function useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation, EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation, EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationVariables>(EditPlanTecAggregationPlanUpdateTecAggregationMenuDocument, options);
      }
export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation>;
export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation>;
export type EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<EditPlanTecAggregationPlanUpdateTecAggregationMenuMutation, EditPlanTecAggregationPlanUpdateTecAggregationMenuMutationVariables>;