import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuTranslationsFormItem } from "./useEditMenuTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeatureLabelKrField = memo<Props>(({ ...props }) => (
  <EditMenuTranslationsFormItem label="韓国語" name="featureLabelKr" {...props}>
    <Input />
  </EditMenuTranslationsFormItem>
));
