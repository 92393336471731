import React, { memo } from "react";
import styled from "styled-components";
import { parsePrice } from "util/input/parsePrice";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";
import { colors } from "constants/colors";
import { CouponTypeEnum } from "types/graphql";

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const Description = styled.div`
  color: ${colors.Text.Secondary};
  font-size: 12px;
`;

export const DiscountedPriceField = memo(() => (
  <>
    <FormItem
      name={[CouponTypeEnum.MenuDiscount, "discountedPrice"]}
      label="値引・割引後の価格"
      required
      rules={[{ required: true, message: "値引・割引後の価格を入力してください" }]}
      endSpacer={null}
    >
      <StyledInputNumber
        min={0}
        formatter={(value) => `${value}円`}
        parser={parsePrice}
        placeholder="100円"
      />
    </FormItem>
    <Description>無料の場合は0円で設定してください</Description>
  </>
));
