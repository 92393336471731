import React, { memo } from "react";
import { SmileOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";

import { MembershipCardMenu } from "./MembershipCardMenu";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const CustomerRelationshipManagementMenu = memo<Props>(({ company, ...props }) => {
  const { isFeatureAvailable } = useFeatureFlag();

  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const shouldShowExternalOnlineMenu = isFeatureAvailable({
    name: FeatureFlagEnum.ExternalOnlineMenu,
    shopIds,
  });

  return (
    <SubMenu
      key="serviceAdmin"
      icon={<SmileOutlined />}
      title="CRM"
      features={[DashboardFeatureEnum.CustomerRelationshipManagement]}
      {...props}
    >
      <MenuItem route="messageDeliveries" to="/messageDelivery/reserved" text="メッセージ配信" />
      <MenuItem route="coupons" to="/coupon" text="クーポン" />
      <MenuItem route="lineOfficialAccounts" to="/lineOfficialAccounts" text="LINE公式アカウント" />
      <MenuItem route="connectGameConfigs" to="/connectGameConfig" text="ミニゲーム" />
      <MembershipCardMenu />
      <MenuItem
        route="externalOnlineMenus"
        to="/externalOnlineMenu"
        text="オンラインメニュー"
        canShow={shouldShowExternalOnlineMenu}
      />
      <MenuItem route="questionnaireConfigs" to="/questionnaireConfig" text="アンケート" />
    </SubMenu>
  );
});
