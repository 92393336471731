import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/EditDishUpSlipGroup/EditDishUpSlipGroupForm/NameField";
import { RoleField } from "pages/EditDishUpSlipGroup/EditDishUpSlipGroupForm/RoleField";
import { DishUpSlipGroup, Role } from "pages/EditDishUpSlipGroup/types";
import { DishUpSlipGroupRolesInsertInput, DishUpSlipGroupSetInput } from "types/graphql";

import { useEditDishUpSlipGroupForm } from "./useEditDishUpSlipGroupForm";

type Props = {
  shopId: string;
  dishUpSlipGroup: DishUpSlipGroup;
  roles: Role[];
  onSubmit: ({
    dishUpSlipGroup,
    dishUpSlipGroupRoles,
  }: {
    dishUpSlipGroup: DishUpSlipGroupSetInput;
    dishUpSlipGroupRoles: DishUpSlipGroupRolesInsertInput[];
  }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditDishUpSlipGroupForm = memo<Props>(
  ({ shopId, dishUpSlipGroup, roles, onClose, onFormValidationError, onSubmit, loading }) => {
    const { form, initialValues, submit } = useEditDishUpSlipGroupForm({
      roles,
      shopId,
      dishUpSlipGroup,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, form, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="dishUpSlipGroup" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <RoleField roles={roles} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
