import React, { memo } from "react";
import { Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const PutPathField = memo<Props>((props) => (
  <FormItem label="Put Path" name="putPath" rules={[{ required: true }]} required {...props}>
    <Input />
  </FormItem>
));
