import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import {
  DashboardAccountGmoBankAccountDetailResult,
  DepositCycleTypeEnum,
  GmoBankAccount as GmoBankAccountGql,
  GmoBankAccountShop as GmoBankAccountShopGql,
  GmoBankAccountTypeType,
} from "types/graphql";

export type GmoBankAccountDetail = DashboardAccountGmoBankAccountDetailResult;

type GmoBankAccount = Pick<GmoBankAccountGql, "id" | "name">;
type GmoBankAccountShop = Pick<GmoBankAccountShopGql, "shopId" | "depositCycleType">;

export const ACCOUNT_TYPES = [
  { value: GmoBankAccountTypeType.Checking, label: "普通" },
  { value: GmoBankAccountTypeType.Current, label: "当座" },
  { value: GmoBankAccountTypeType.Savings, label: "貯蓄" },
] as const;

export type AccountType = typeof ACCOUNT_TYPES[number];

export type EditGmoBankAccountFormValues = {
  name: string;
  bank: string;
  branch: string;
  accountType: GmoBankAccountTypeType | undefined;
  accountNumber: string;
  accountHolder: string;
  depositCycleShopIds: {
    sixAMonthShopIds: string[];
    twiceAMonthShopIds: string[];
  };
};

const getInitialValues = ({
  gmoBankAccount,
  gmoBankAccountShops,
  gmoBankAccountDetail,
}: {
  gmoBankAccount: GmoBankAccount;
  gmoBankAccountShops: GmoBankAccountShop[];
  gmoBankAccountDetail: GmoBankAccountDetail;
}): EditGmoBankAccountFormValues => {
  const { name } = gmoBankAccount;
  const depositCycleShopIds = {
    sixAMonthShopIds: gmoBankAccountShops
      .filter(({ depositCycleType }) => depositCycleType === DepositCycleTypeEnum.SixAMonth)
      .map(({ shopId }) => shopId),
    twiceAMonthShopIds: gmoBankAccountShops
      .filter(({ depositCycleType }) => depositCycleType === DepositCycleTypeEnum.TwiceAMonth)
      .map(({ shopId }) => shopId),
  };
  const { branchCode, bankCode, accountType, accountNumber, accountName } = gmoBankAccountDetail;

  return {
    name,
    bank: bankCode,
    branch: branchCode,
    accountType,
    accountNumber,
    accountHolder: accountName,
    depositCycleShopIds,
  };
};

export const EditGmoBankAccountFormItem = createFormItem<EditGmoBankAccountFormValues>();

export const useEditGmoBankAccountForm = ({
  gmoBankAccount,
  gmoBankAccountShops,
  gmoBankAccountDetail,
  onSubmit,
}: {
  gmoBankAccount: GmoBankAccount;
  gmoBankAccountShops: GmoBankAccountShop[];
  gmoBankAccountDetail: GmoBankAccountDetail;
  onSubmit: (formValues: EditGmoBankAccountFormValues) => void;
}) => {
  const [form] = Form.useForm<EditGmoBankAccountFormValues>();

  const initialValues = useMemo(
    () => getInitialValues({ gmoBankAccount, gmoBankAccountShops, gmoBankAccountDetail }),
    [gmoBankAccount, gmoBankAccountShops, gmoBankAccountDetail],
  );

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit, reset: form.resetFields };
};
