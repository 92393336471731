import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";

import { TimePicker } from "components/antd/TimePicker";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { EditConnectGameConfigFormItem } from "../useEditConnectGameConfigForm";

const Description = styled.p`
  font-size: 14px;
  color: ${colors.Text.Secondary};
  margin: 0;
`;

const Tilda = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 8px;
`;

export const TimeTermField = memo(() => (
  <EditConnectGameConfigFormItem.NonProperty label="適用時間帯" required>
    <Row>
      <Col>
        <EditConnectGameConfigFormItem
          name="startTime"
          rules={[{ required: true, message: "開始時間を入力してください" }]}
          endSpacer={null}
        >
          <TimePicker formItemName="startTime" />
        </EditConnectGameConfigFormItem>
      </Col>
      <Col>
        <Tilda>~</Tilda>
      </Col>
      <Col>
        <EditConnectGameConfigFormItem
          name="endTime"
          rules={[{ required: true, message: "終了時間を入力してください" }]}
          endSpacer={null}
        >
          <TimePicker formItemName="endTime" />
        </EditConnectGameConfigFormItem>
      </Col>
    </Row>
    <Spacer size={4} />
    <Row>
      <Col span="auto">
        <Description>ミニゲームの画面を出す時間帯を制限することができます</Description>
      </Col>
    </Row>
  </EditConnectGameConfigFormItem.NonProperty>
));
