import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuGetMenu = gql`
    query EditMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    companyId
    description
    featuredLabelText
    imageUrl
    menuId
    orderableTimeId
    name
    openPrice
    orderMaxNum
    orderMaxNumPerTableUser
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    unitMaxOrderNumForNumPeople
    unitMaxOrderNumPerCustomer
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    price
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        name
        optionId
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          imageUrl
          isDefaultSelection
          name
          price
        }
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryMenuId
      categoryId: _categoryId
      displayType
      menuId: _menuId
      priority
      planFirstOrderCategoryMenus(where: {plan: {archivedAt: {_is_null: true}}}) {
        categoryMenuId: _categoryMenuId
        plan {
          id
          planName
        }
      }
    }
  }
}
    `;
export const EditMenuGetCategories = gql`
    query EditMenuGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus {
      categoryId: _categoryId
      priority
    }
    menuRecommendationMeta {
      id
    }
  }
}
    `;
export const EditMenuGetOrderableTimes = gql`
    query EditMenuGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditMenuGetCompany = gql`
    query EditMenuGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultCostTaxMethod
  }
}
    `;
export const EditMenuUpdateMenu = gql`
    mutation EditMenuUpdateMenu($menuId: Int!, $menu: menu_set_input!, $categoryIds: [Int!]!, $categoryMenus: [categoryMenu_insert_input!]!) {
  update_menu(_set: $menu, where: {menuId: {_eq: $menuId}}) {
    affected_rows
  }
  delete_categoryMenu(
    where: {_menuId: {_eq: $menuId}, _categoryId: {_nin: $categoryIds}}
  ) {
    affected_rows
  }
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_pkey, update_columns: [displayType]}
  ) {
    affected_rows
  }
}
    `;
export type EditMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'featuredLabelText' | 'imageUrl' | 'menuId' | 'orderableTimeId' | 'name' | 'openPrice' | 'orderMaxNum' | 'orderMaxNumPerTableUser' | 'receiptDisplayName' | 'shopSideName' | 'taxMethod' | 'taxRate' | 'unitMaxOrderNumForNumPeople' | 'unitMaxOrderNumPerCustomer' | 'shouldMergeSlipOptions' | 'costPrice' | 'costTaxRate' | 'menuType' | 'price'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'name' | 'optionId'>
        & { choices: Array<(
          { __typename?: 'choice' }
          & Pick<Types.Choice, 'choiceId' | 'imageUrl' | 'isDefaultSelection' | 'name' | 'price'>
        )> }
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId' | 'displayType' | 'priority'>
      & { categoryId: Types.CategoryMenu['_categoryId'], menuId: Types.CategoryMenu['_menuId'] }
      & { planFirstOrderCategoryMenus: Array<(
        { __typename?: 'planFirstOrderCategoryMenu' }
        & { categoryMenuId: Types.PlanFirstOrderCategoryMenu['_categoryMenuId'] }
        & { plan: (
          { __typename?: 'plan' }
          & Pick<Types.Plan, 'id' | 'planName'>
        ) }
      )> }
    )> }
  )> }
);

export type EditMenuGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'categoryId' | 'name' | 'shopSideName'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'priority'>
      & { categoryId: Types.CategoryMenu['_categoryId'] }
    )>, menuRecommendationMeta?: Types.Maybe<(
      { __typename?: 'menuRecommendationMeta' }
      & Pick<Types.MenuRecommendationMeta, 'id'>
    )> }
  )> }
);

export type EditMenuGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type EditMenuGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'companyId' | 'defaultCostTaxMethod'>
  )> }
);

export type EditMenuUpdateMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  menu: Types.MenuSetInput;
  categoryIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  categoryMenus: Array<Types.CategoryMenuInsertInput> | Types.CategoryMenuInsertInput;
}>;


export type EditMenuUpdateMenuMutation = (
  { __typename?: 'mutation_root' }
  & { update_menu?: Types.Maybe<(
    { __typename?: 'menu_mutation_response' }
    & Pick<Types.MenuMutationResponse, 'affected_rows'>
  )>, delete_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
  )>, insert_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
  )> }
);


export const EditMenuGetMenuDocument = gql`
    query EditMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    companyId
    description
    featuredLabelText
    imageUrl
    menuId
    orderableTimeId
    name
    openPrice
    orderMaxNum
    orderMaxNumPerTableUser
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
    unitMaxOrderNumForNumPeople
    unitMaxOrderNumPerCustomer
    shouldMergeSlipOptions
    costPrice
    costTaxRate
    menuType
    price
    menuOptions(
      where: {option: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        name
        optionId
        choices(
          where: {archivedAt: {_is_null: true}}
          order_by: [{priority: asc}, {choiceId: asc}]
        ) {
          choiceId
          imageUrl
          isDefaultSelection
          name
          price
        }
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryMenuId
      categoryId: _categoryId
      displayType
      menuId: _menuId
      priority
      planFirstOrderCategoryMenus(where: {plan: {archivedAt: {_is_null: true}}}) {
        categoryMenuId: _categoryMenuId
        plan {
          id
          planName
        }
      }
    }
  }
}
    `;

/**
 * __useEditMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuGetMenuQuery, EditMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuGetMenuQuery, EditMenuGetMenuQueryVariables>(EditMenuGetMenuDocument, options);
      }
export function useEditMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuGetMenuQuery, EditMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuGetMenuQuery, EditMenuGetMenuQueryVariables>(EditMenuGetMenuDocument, options);
        }
export type EditMenuGetMenuQueryHookResult = ReturnType<typeof useEditMenuGetMenuQuery>;
export type EditMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuGetMenuLazyQuery>;
export type EditMenuGetMenuQueryResult = Apollo.QueryResult<EditMenuGetMenuQuery, EditMenuGetMenuQueryVariables>;
export const EditMenuGetCategoriesDocument = gql`
    query EditMenuGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus {
      categoryId: _categoryId
      priority
    }
    menuRecommendationMeta {
      id
    }
  }
}
    `;

/**
 * __useEditMenuGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditMenuGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditMenuGetCategoriesQuery, EditMenuGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuGetCategoriesQuery, EditMenuGetCategoriesQueryVariables>(EditMenuGetCategoriesDocument, options);
      }
export function useEditMenuGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuGetCategoriesQuery, EditMenuGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuGetCategoriesQuery, EditMenuGetCategoriesQueryVariables>(EditMenuGetCategoriesDocument, options);
        }
export type EditMenuGetCategoriesQueryHookResult = ReturnType<typeof useEditMenuGetCategoriesQuery>;
export type EditMenuGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditMenuGetCategoriesLazyQuery>;
export type EditMenuGetCategoriesQueryResult = Apollo.QueryResult<EditMenuGetCategoriesQuery, EditMenuGetCategoriesQueryVariables>;
export const EditMenuGetOrderableTimesDocument = gql`
    query EditMenuGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditMenuGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useEditMenuGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<EditMenuGetOrderableTimesQuery, EditMenuGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuGetOrderableTimesQuery, EditMenuGetOrderableTimesQueryVariables>(EditMenuGetOrderableTimesDocument, options);
      }
export function useEditMenuGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuGetOrderableTimesQuery, EditMenuGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuGetOrderableTimesQuery, EditMenuGetOrderableTimesQueryVariables>(EditMenuGetOrderableTimesDocument, options);
        }
export type EditMenuGetOrderableTimesQueryHookResult = ReturnType<typeof useEditMenuGetOrderableTimesQuery>;
export type EditMenuGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useEditMenuGetOrderableTimesLazyQuery>;
export type EditMenuGetOrderableTimesQueryResult = Apollo.QueryResult<EditMenuGetOrderableTimesQuery, EditMenuGetOrderableTimesQueryVariables>;
export const EditMenuGetCompanyDocument = gql`
    query EditMenuGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultCostTaxMethod
  }
}
    `;

/**
 * __useEditMenuGetCompanyQuery__
 *
 * To run a query within a React component, call `useEditMenuGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<EditMenuGetCompanyQuery, EditMenuGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuGetCompanyQuery, EditMenuGetCompanyQueryVariables>(EditMenuGetCompanyDocument, options);
      }
export function useEditMenuGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuGetCompanyQuery, EditMenuGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuGetCompanyQuery, EditMenuGetCompanyQueryVariables>(EditMenuGetCompanyDocument, options);
        }
export type EditMenuGetCompanyQueryHookResult = ReturnType<typeof useEditMenuGetCompanyQuery>;
export type EditMenuGetCompanyLazyQueryHookResult = ReturnType<typeof useEditMenuGetCompanyLazyQuery>;
export type EditMenuGetCompanyQueryResult = Apollo.QueryResult<EditMenuGetCompanyQuery, EditMenuGetCompanyQueryVariables>;
export const EditMenuUpdateMenuDocument = gql`
    mutation EditMenuUpdateMenu($menuId: Int!, $menu: menu_set_input!, $categoryIds: [Int!]!, $categoryMenus: [categoryMenu_insert_input!]!) {
  update_menu(_set: $menu, where: {menuId: {_eq: $menuId}}) {
    affected_rows
  }
  delete_categoryMenu(
    where: {_menuId: {_eq: $menuId}, _categoryId: {_nin: $categoryIds}}
  ) {
    affected_rows
  }
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_pkey, update_columns: [displayType]}
  ) {
    affected_rows
  }
}
    `;
export type EditMenuUpdateMenuMutationFn = Apollo.MutationFunction<EditMenuUpdateMenuMutation, EditMenuUpdateMenuMutationVariables>;

/**
 * __useEditMenuUpdateMenuMutation__
 *
 * To run a mutation, you first call `useEditMenuUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuUpdateMenuMutation, { data, loading, error }] = useEditMenuUpdateMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      menu: // value for 'menu'
 *      categoryIds: // value for 'categoryIds'
 *      categoryMenus: // value for 'categoryMenus'
 *   },
 * });
 */
export function useEditMenuUpdateMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuUpdateMenuMutation, EditMenuUpdateMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuUpdateMenuMutation, EditMenuUpdateMenuMutationVariables>(EditMenuUpdateMenuDocument, options);
      }
export type EditMenuUpdateMenuMutationHookResult = ReturnType<typeof useEditMenuUpdateMenuMutation>;
export type EditMenuUpdateMenuMutationResult = Apollo.MutationResult<EditMenuUpdateMenuMutation>;
export type EditMenuUpdateMenuMutationOptions = Apollo.BaseMutationOptions<EditMenuUpdateMenuMutation, EditMenuUpdateMenuMutationVariables>;