import React, { memo } from "react";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import { blue, red } from "@ant-design/colors";

type Props = {
  isEnabled?: boolean;
};

const TablePrintingEnabledText = styled(Text)`
  font-weight: bold;
  color: ${red[4]};
`;

const TablePrintingDisabledText = styled(Text)`
  font-weight: bold;
  color: ${blue[4]};
`;

export const TablePrintingText = memo<Props>(({ isEnabled = false }) =>
  isEnabled ? (
    <TablePrintingEnabledText>有効</TablePrintingEnabledText>
  ) : (
    <TablePrintingDisabledText>無効</TablePrintingDisabledText>
  ),
);
