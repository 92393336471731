import React, { memo } from "react";

import ArrowDownPcMiddle from "assets/arrow_down_pc_middle.svg";
import ArrowDownPcSmall from "assets/arrow_down_pc_small.svg";
import ArrowKeepPcMiddle from "assets/arrow_keep_pc_middle.svg";
import ArrowKeepPcSmall from "assets/arrow_keep_pc_small.svg";
import ArrowUpPcMiddle from "assets/arrow_up_pc_middle.svg";
import ArrowUpPcSmall from "assets/arrow_up_pc_small.svg";
import { FluctuatingStatusType } from "types/graphql";

import type { PanelSize } from "../types";

type Props = {
  status: FluctuatingStatusType;
  size: PanelSize;
};

export const FluctuatingStatusIcon = memo<Props>(({ status, size }) => {
  if (size === "md") {
    if (status === FluctuatingStatusType.Up) return <ArrowUpPcMiddle />;
    if (status === FluctuatingStatusType.Down) return <ArrowDownPcMiddle />;

    return <ArrowKeepPcMiddle />;
  }

  if (status === FluctuatingStatusType.Up) return <ArrowUpPcSmall />;
  if (status === FluctuatingStatusType.Down) return <ArrowDownPcSmall />;

  return <ArrowKeepPcSmall />;
});
