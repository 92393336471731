import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

export type PikaichiUploadFormValues = {
  date?: dayjs.Dayjs;
};

export const PikaichiUploadFormItem = createFormItem<PikaichiUploadFormValues>();

export const usePikaichiUploadForm = () => {
  const [form] = Form.useForm();

  const getValues = useCallback(() => {
    const values = form.getFieldsValue() as PikaichiUploadFormValues;
    return values;
  }, [form]);

  return { form, getValues };
};
