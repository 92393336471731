import React, { memo } from "react";

import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";

export const AddPlanChoiceTranslationOptions = memo(() => (
  <CollapsableFormSection title="多言語設定" titleLevel={5}>
    <TranslationNameFields label="プランチョイス名" originalFieldName="name" />
  </CollapsableFormSection>
));
