import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { ShopMenu } from "pages/EditShopMenuStock/types";
import { Stock } from "types/graphql";

export type EditShopMenuStockFormValues = Pick<
  Stock,
  "currentStockNum" | "dailyStockNum" | "id"
> & {
  noCurrentStockNum: boolean;
  noDailyStockNum: boolean;
};

const getInitialValues = (shopMenu: ShopMenu) => {
  const { stock } = shopMenu;
  const currentStockNum = stock?.currentStockNum;
  const dailyStockNum = stock?.dailyStockNum;
  return {
    currentStockNum: currentStockNum ?? 0,
    noCurrentStockNum: typeof currentStockNum !== "number",
    dailyStockNum: dailyStockNum ?? 0,
    noDailyStockNum: typeof dailyStockNum !== "number",
  };
};

export const EditShopMenuStockFormItem = createFormItem<EditShopMenuStockFormValues>();

export const useEditShopMenuStockForm = (
  shopMenu: ShopMenu,
  onSubmit: (stock: Pick<Stock, "currentStockNum" | "dailyStockNum">) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(shopMenu);

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue() as EditShopMenuStockFormValues;
    const { noCurrentStockNum, noDailyStockNum } = formValues;
    const currentStockNum = noCurrentStockNum ? null : formValues.currentStockNum;
    const dailyStockNum = noDailyStockNum ? null : formValues.dailyStockNum;
    onSubmit({ currentStockNum, dailyStockNum });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
