// NOTE: 法人ごとの機能開放のために使用する

export const companyIds = {
  production: {
    aphdSichifuku: 126,
    aphdYonpachiGyojyo: 220,
    demo: 4,
    donanNorinSuisanbu: 214,
    idea1: 45,
    idea2: 51,
    idea3: 57,
    jucchuhakku: 144,
    kusuo: 158,
    mirakugekijou: 146,
    nikudoushi: 125,
    nikudoushiHormone: 139,
    nikudoushiHukushima: 150,
    nikudoushiUrananba: 168,
    okkyagari: 140,
    ramuGoro: 178,
    secondArrow: 40,
    swSushinjuku: 129,
    swEnzoOtsuka: 124,
    swHiStand: 58,
    swOkinawaOceanGoodTable: 117,
    swOkinawaCreole: 106,
    swOkinawaRocky: 105,
    swOkinawaHokusai: 107,
    swOkinawaShinton: 110,
    swOkinawaKamakuraHam: 108,
    swSasaya: 78,
    swIdumo: 177,
    swIdumoIkebukuro: 121,
    swSacreeFleur: 77,
    swSacreeFleurKaruizawa: 118,
    swSushiEbisuOtsuka: 122,
    swSushinjukuGotanda: 213,
    swButaMamireOtsuka: 123,
    swHormonPepe: 68,
    swRocky: 162,
    swShigotoumaSuidobashi: 159,
    swHokusai: 116,
    swHokkaiMarugami: 69,
    swTairyoAkabane: 167,
    swMukauSinkei: 195,
    swShintonMatudo: 179,
    swShinkei: 138,
    swKaigaraSo: 76,
    swMarugamiHimeji: 170,
    tanaka: 42,
    tanakaAkihabara: 74,
    tanakaRoadside: 191,
    tanakaShinmaruko: 2204,
    tanakaToritama: 2167,
    tebaichi: 155,
    torihuji: 141,
    uat: 28,
    uossyu: 147,
  },
  beta: {
    mugenKyotoKawaraMachi: 38,
    mugenKarasuma: 149,
  },
};
