type DisplayTypeKey = keyof typeof displayTypes;

export const displayTypes = {
  large: "大サイズ",
  medium: "中サイズ",
  small: "小サイズ",
  text: "画像非表示",
} as const;

export const isDisplayType = (displayTypeKey: string): displayTypeKey is DisplayTypeKey =>
  displayTypeKey in displayTypes;

export const getTypeName = (displayTypeKey: string) =>
  isDisplayType(displayTypeKey) ? displayTypes[displayTypeKey] : ("" as const);
