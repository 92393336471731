import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { AddMenuForm } from "pages/AddMenu/AddMenuForm";
import {
  useAddMenuGetCategoriesAndMenusQuery,
  useAddMenuGetCompanyQuery,
  useAddMenuGetOrderableTimesQuery,
  useAddMenuInsertMenuMutation,
} from "pages/AddMenu/queries";
import {
  CreateMenuInputCategoryMenu,
  CreateMenuInputMenuSource,
  CreateMenuInputTranslationSource,
} from "types/graphql";

export const AddMenu = () => {
  const navigate = useNavigate();

  const [company] = useCompany();

  const companyId = company?.id;

  const {
    data: getCategoriesAndMenusData,
    error: errorGetCategoriesAndMenusData,
    refetch: refetchCategoriesAndMenusData,
  } = useAddMenuGetCategoriesAndMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesAndMenusData?.category ?? [];
  const menus = getCategoriesAndMenusData?.menu ?? [];
  const options = getCategoriesAndMenusData?.option ?? [];
  const categoryMaps = Object.fromEntries(
    categories.map(({ categoryId, name, shopSideName }) => [categoryId, shopSideName || name]),
  );

  const { data: getOrderableTimeData, error: errorGetOrderableTimeData } =
    useAddMenuGetOrderableTimesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const orderableTimes = getOrderableTimeData?.orderableTime ?? [];

  const { data: getCompanyData, error: errorGetCompanyData } = useAddMenuGetCompanyQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const defaultMenuTaxMethod = getCompanyData?.company?.[0]?.defaultMenuTaxMethod;
  const defaultCostTaxMethod = getCompanyData?.company?.[0]?.defaultCostTaxMethod;

  const [addMenuMutation, { loading: loadingInsertMenu }] = useAddMenuInsertMenuMutation();

  const onSubmit = useCallback(
    async ({
      menu,
      categoryMenuSources,
      sourceMenuId,
      createTranslationsSource,
    }: {
      menu: CreateMenuInputMenuSource;
      categoryMenuSources: CreateMenuInputCategoryMenu[];
      sourceMenuId?: number;
      createTranslationsSource: CreateMenuInputTranslationSource;
    }) => {
      try {
        const { data: addMenuResult } = await addMenuMutation({
          variables: {
            menu: {
              createMenuSource: menu,
              categoryMenuSources,
              sourceMenuId,
              createTranslationsSource,
            },
          },
        });
        const menuId = addMenuResult?.createMenu.menuId;

        if (menuId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/menu/${menuId}/dealer/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addMenuMutation, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout
      title="メニューを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      {getCompanyData && (
        <AddMenuForm
          categories={categories}
          menus={menus}
          options={options}
          categoryMaps={categoryMaps}
          orderableTimes={orderableTimes}
          defaultMenuTaxMethod={defaultMenuTaxMethod}
          defaultCostTaxMethod={defaultCostTaxMethod}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loadOptions={refetchCategoriesAndMenusData}
          loading={loadingInsertMenu}
        />
      )}

      {(errorGetCategoriesAndMenusData || errorGetOrderableTimeData || errorGetCompanyData) && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
    </DashboardLayout>
  );
};
