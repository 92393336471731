import React from "react";
import styled from "styled-components";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { ConvertTecFormatFormItem } from "../useConvertTecFormatForm";

const getValueFromEvent = (event: { file: File }) => event.file;

const Label = styled.div`
  width: 200px;
`;

export const FileUploadField = ({ title }: { title: string }) => (
  <ConvertTecFormatFormItem
    label={<Label>{title}</Label>}
    name="menuMaster"
    getValueFromEvent={getValueFromEvent}
    rules={[{ required: true, message: "必須です" }]}
  >
    <Upload beforeUpload={() => false} maxCount={1}>
      <Button icon={<UploadOutlined />}>ファイルを選択する</Button>
    </Upload>
  </ConvertTecFormatFormItem>
);
