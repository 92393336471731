import React, { memo } from "react";
import { FormItemProps, Input } from "antd";
import { getTaxRate } from "models/taxRate";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  taxRate: number;
};

export const TaxRateField = memo<Props>(({ taxRate, ...props }) => (
  <EditPlanChoiceFormItem.NonProperty label="税率" {...props}>
    {/* NOTE: 変更不可な仕様 */}
    <Input disabled={true} value={getTaxRate(taxRate)} suffix="%" />
  </EditPlanChoiceFormItem.NonProperty>
));
