import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { KitchenDisplayDisplayTargetHeader } from "components/PageHeader/KitchenDisplayDisplayTargetHeader";
import { useShop } from "hooks/useShop";
import { EditKitchenDisplayDisplayTargetForm } from "pages/EditKitchenDisplayDisplayTarget/EditKitchenDisplayDisplayTargetForm";
import {
  useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery,
  useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation,
} from "pages/EditKitchenDisplayDisplayTarget/queries";
import { KdDisplayTargetSetInput } from "types/graphql";

export const EditKitchenDisplayDisplayTarget = () => {
  const [shop] = useShop();

  const { id } = useParams<{ id: string }>();
  const kdDisplayTargetId = String(id);
  const navigate = useNavigate();
  const shopId = shop?.shopId;

  const {
    data: getKdDisplayTargetAndRolesData,
    loading: loadingKdDisplayTarget,
    refetch: refetchKdDisplayTarget,
    error,
  } = useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery(
    shopId && kdDisplayTargetId ? { variables: { kdDisplayTargetId, shopId } } : { skip: true },
  );
  const kdDisplayTarget = getKdDisplayTargetAndRolesData?.kdDisplayTarget_by_pk;
  const roles = getKdDisplayTargetAndRolesData?.role ?? [];

  const [updateKdDisplayTargetMutation, { loading: loadingUpdateKdDisplayTarget }] =
    useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation();

  const onSubmit = useCallback(
    async (kdDisplayTarget: KdDisplayTargetSetInput) => {
      if (kdDisplayTargetId && shopId) {
        try {
          await updateKdDisplayTargetMutation({
            variables: {
              kdDisplayTargetId,
              kdDisplayTarget: {
                ...kdDisplayTarget,
                roleId: kdDisplayTarget.roleId ?? null,
                _roleId: kdDisplayTarget._roleId ?? null,
              },
            },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
        await refetchKdDisplayTarget();
      }
    },
    [shopId, kdDisplayTargetId, updateKdDisplayTargetMutation, refetchKdDisplayTarget],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={kdDisplayTarget?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "キッチンディスプレイ表示対象" }],
      }}
    >
      <KitchenDisplayDisplayTargetHeader
        kdDisplayTarget={kdDisplayTarget ?? null}
        onBack={goBack}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {loadingKdDisplayTarget && <Loading height={300} />}
      {kdDisplayTarget && (
        <EditKitchenDisplayDisplayTargetForm
          kdDisplayTarget={kdDisplayTarget}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateKdDisplayTarget}
          roles={roles}
        />
      )}
    </DashboardLayout>
  );
};
