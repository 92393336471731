import React, { memo } from "react";
import styled from "styled-components";
import { Button, QRCode, Row, Steps } from "antd";

import AddLineReportingBotToFriendsListSource from "assets/line-reporting-bot/add-line-reporting-bot-to-friends-list.png";
import AddLineReportingBotToGroupTalkSource from "assets/line-reporting-bot/add-line-reporting-bot-to-group-talk.png";
import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";

import { AddLineReportingBotConfigText } from "../AddLineReportingBotConfigText";

const AddLineReportingBotToFriendsListImage = styled.img.attrs({
  src: AddLineReportingBotToFriendsListSource,
})`
  width: 150px;
  height: 247px;
`;

const AddLineReportingBotToGroupTalkImage = styled.img.attrs({
  src: AddLineReportingBotToGroupTalkSource,
})`
  width: 200px;
  height: 270px;
`;

const steps = [{ title: "グループに追加" }, { title: "コードをLINEに投稿" }, { title: "店舗選択" }];
const lineReportingBotUrl = import.meta.env.LINE_REPORTING_BOT_URL ?? "";

type Props = {
  onClickBackButton: () => void;
  onClickForwardButton: () => void;
  disabled: boolean;
};

export const AddLineReportingBotToGroupTalk = memo<Props>(
  ({ onClickBackButton, onClickForwardButton, disabled }) => (
    <Form layout="vertical">
      <FormSection>
        <Steps current={0} items={steps} />
        <Spacer size={24} />
        <AddLineReportingBotConfigText>
          LINEのアプリから下のQRコードを読み取り、ダイニーAIくんを友だち登録してください。
        </AddLineReportingBotConfigText>
        <Spacer size={8} />
        <Row>
          <QRCode value={lineReportingBotUrl} bordered={false} />
          <Spacer size={20} />
          <AddLineReportingBotToFriendsListImage />
        </Row>
        <Spacer size={24} />
        <AddLineReportingBotConfigText>
          ご希望のグループにダイニーAIくんを追加してください。
        </AddLineReportingBotConfigText>
        <Spacer size={8} />
        <Row>
          <AddLineReportingBotToGroupTalkImage />
        </Row>
      </FormSection>
      <Spacer size={16} />
      <FormActions>
        <Button onClick={onClickBackButton}>キャンセル</Button>
        <Button type="primary" onClick={onClickForwardButton} disabled={disabled}>
          次へ
        </Button>
      </FormActions>
    </Form>
  ),
);
