import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, DatePicker, Divider, Radio, Select, Tooltip, TreeSelect } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { CaretDownOutlined, LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";
import { RangeValue } from "rc-picker/lib/interface";

import { Spacer } from "components/Spacer";
import { useCorporation } from "hooks/useCorporation";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";
import { ReportByType } from "hooks/useSalesAnalytics/types";
import { useShopSelectPerCorporation } from "hooks/useShopSelectPerCorporation";

import {
  useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery,
  useSalesAnalyticsGetShopsQuery,
} from "../queries";

const MAX_SELECTABLE_DAYS = 366;
const fieldSpacingAmount = 12;

const getInitialTimeRange = (reportByType: ReportByType): [dayjs.Dayjs, dayjs.Dayjs] => {
  const now = dayjs();

  switch (reportByType) {
    case ReportByType.month:
      return [now.startOf("month").subtract(2, "month"), now.startOf("month")];

    case ReportByType.day:
      return [now.startOf("month"), now];

    default:
      return [now.subtract(30, "days"), now];
  }
};

const StyledTreeSelect = styled(TreeSelect<string>)`
  width: 275px;
`;

const EntityMultiSelect = styled(Select<string[]>)`
  width: 275px;
  flex-grow: 1;
  max-width: 400px;
`;

const BusinessOperationHourTypeSelect = styled(EntityMultiSelect)`
  width: 250px;
  flex-grow: 0;
`;

const BusinessOperationHourTypeTips = styled.div`
  padding: 10px;
`;

const FlexGrowSpacer = styled.div`
  flex-grow: 1;
`;

const LoadingIcon = styled(LoadingOutlined)`
  margin-right: 8px;
`;

const CaretDownIcon = styled(CaretDownOutlined)`
  margin-left: 8px;
`;

const FiltersRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  businessOperationHourTypes: string[];
  reportByType: ReportByType;
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null];
  businessOperationHourTypes: string[] | null;
  reportByType: ReportByType;
} & Omit<FilterConditions, "range">;

type Props = {
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  onPressCsvExport: () => void;
  onEditColumns: () => void;
  onShopChange: () => void;
};

export const Filters = memo<Props>(
  ({
    filterConditions,
    onChangeFilterCondition,
    onPressCsvExport,
    onEditColumns,
    onShopChange,
  }) => {
    const [corporation] = useCorporation();
    const corporationId = corporation?.corporationId ?? null;

    const { currentShop, setShop } = useShopSelectPerCorporation();
    const shopId = currentShop?.shopId ?? null;

    const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(
      () => getInitialTimeRange(filterConditions.reportByType),
      [filterConditions.reportByType],
    );

    const { data: businessOperationHourTypesData } =
      useSalesAnalyticsGetBusinessOperationHourTypesByShopIdQuery(
        !shopId
          ? { skip: true }
          : {
              variables: {
                shopId,
              },
            },
      );

    const { data: getShopsData } = useSalesAnalyticsGetShopsQuery(
      corporationId ? { variables: { corporationId } } : { skip: true },
    );

    const shopTreeData = useMemo(
      () =>
        (getShopsData?.corporation[0]?.companies ?? [])
          .filter(({ shops }) => shops.length > 0)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((company) => ({
            key: company.id,
            value: company.id,
            title: company.name,
            selectable: false,
            children: company.shops
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((shop) => ({
                key: shop.shopId,
                value: shop.shopId,
                title: shop.name,
                selectable: true,
              })),
          })),
      [getShopsData?.corporation],
    );

    const businessOperationHourTypes = useMemo(
      () =>
        uniq(
          (businessOperationHourTypesData?.shopBusinessOperationHour ?? []).map(
            ({ businessOperationHourType }) => businessOperationHourType,
          ),
        ),
      [businessOperationHourTypesData],
    );

    const businessOperationHourTypeOptions = useMemo(
      () =>
        businessOperationHourTypes.map((key) => ({
          value: key,
          label: businessOperationHourTypeToWord[key],
        })),
      [businessOperationHourTypes],
    );

    const handleChangeShop = useCallback(
      (shopId: string) => {
        onShopChange();
        setShop(shopId);
      },
      [onShopChange, setShop],
    );

    const handleChangeMonth = useCallback(
      (date: dayjs.Dayjs | null) => {
        if (!date) return;

        onChangeFilterCondition({ range: [date.startOf("month"), date.endOf("month")] });
      },
      [onChangeFilterCondition],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const startAt = values?.[0]?.startOf("day") ?? null;
        const endAt = values?.[1]?.endOf("day") ?? null;

        onChangeFilterCondition({ range: [startAt, endAt] });
      },
      [onChangeFilterCondition],
    );

    const handleChangeReportByType = useCallback(
      (e: RadioChangeEvent) => {
        const initialRange = getInitialTimeRange(e.target.value);

        onChangeFilterCondition({ range: initialRange, reportByType: e.target.value });
      },
      [onChangeFilterCondition],
    );

    // NOTE: API のパフォーマンス観点から選択可能な期間を最大一年間とする
    const disabledDate = useCallback(
      (current: dayjs.Dayjs | null) => {
        const startAt = filterConditions.range?.[0] ?? null;
        const endAt = filterConditions.range?.[1] ?? null;

        if (startAt === null && endAt === null) return false;

        const isTooLate = Boolean(
          current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
        );
        const isTooEarly = Boolean(
          current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
        );

        return isTooLate || isTooEarly;
      },
      [filterConditions.range],
    );

    const { rangePresets } = useRangePresets();

    return (
      <>
        <FiltersRow>
          <StyledTreeSelect
            treeData={shopTreeData}
            treeDefaultExpandAll
            value={currentShop?.shopId ?? ""}
            onChange={handleChangeShop}
            treeNodeFilterProp="title"
            placeholder="店舗を選択"
          />
          <Spacer size={fieldSpacingAmount} inline />
          <Radio.Group
            onChange={handleChangeReportByType}
            defaultValue={filterConditions.reportByType}
          >
            <Radio.Button value={ReportByType.month}>月別</Radio.Button>
            <Radio.Button value={ReportByType.day}>日別</Radio.Button>
            <Radio.Button value={ReportByType.businessOperationHourType}>時間帯別</Radio.Button>
            <Radio.Button value={ReportByType.dayOfWeek}>曜日別 (平均)</Radio.Button>
            <Radio.Button value={ReportByType.hour}>時間別 (平均)</Radio.Button>
          </Radio.Group>
        </FiltersRow>
        <Spacer size={fieldSpacingAmount} inline />
        <FiltersRow>
          {filterConditions.reportByType === ReportByType.day ? (
            <DatePicker.MonthPicker
              placeholder="月を選択"
              value={filterConditions.range?.[0] ?? initialRange[0]}
              onChange={handleChangeMonth}
              allowClear={false}
            />
          ) : null}
          {filterConditions.reportByType !== ReportByType.day ? (
            <DatePicker.RangePicker
              value={filterConditions.range ?? initialRange}
              ranges={filterConditions.reportByType !== ReportByType.month ? rangePresets : {}}
              onCalendarChange={handleChangeRange}
              clearIcon={false}
              disabledDate={disabledDate}
              picker={filterConditions.reportByType === ReportByType.month ? "month" : "date"}
              renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
            />
          ) : null}
          <Spacer size={fieldSpacingAmount} inline />

          <BusinessOperationHourTypeSelect
            mode="multiple"
            showSearch
            allowClear
            placeholder="営業時間帯"
            value={filterConditions.businessOperationHourTypes}
            onChange={(businessOperationHourTypes) =>
              onChangeFilterCondition({ businessOperationHourTypes })
            }
            optionFilterProp="label"
            options={businessOperationHourTypeOptions}
            maxTagCount="responsive"
            dropdownRender={(options) => (
              <>
                {options}
                <Spacer size={10} />
                <StyledDivider />
                <BusinessOperationHourTypeTips>
                  時間帯について{" "}
                  <Tooltip
                    placement="top"
                    title="店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 に設定されている時間帯が表示されます"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </BusinessOperationHourTypeTips>
              </>
            )}
          />

          <FlexGrowSpacer />
          <Button onClick={onEditColumns}>表の項目を編集</Button>
          <Spacer inline size={fieldSpacingAmount} />

          <Button type="primary" onClick={onPressCsvExport}>
            CSVダウンロード
          </Button>
        </FiltersRow>

        <Spacer size={fieldSpacingAmount} />
      </>
    );
  },
);
