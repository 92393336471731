import React, { memo } from "react";
import { Alert, Col, FormInstance, Input, InputNumber, Radio, Row, Switch } from "antd";

import { Form } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp/index";
import { MaxChoiceNumWithMinChoiceNumField } from "components/Form/MaxChoiceNumWithMinChoiceNumField";
import { MinChoiceNumWithMaxChoiceNumField } from "components/Form/MinChoiceNumWithMaxChoiceNumField";
import {
  AddPlanOptionFormItem,
  AddPlanOptionFormValues,
  useAddPlanOptionForm,
} from "pages/PlanOptions/AddPlanOptionModal/AddPlanOptionForm/useAddPlanOptionForm";
import { OptionInputTypeEnum } from "types/graphql";

import { AddPlanOptionTranslationOptions } from "./AddPlanOptionTranslationOptions";

type Props = {
  form: FormInstance;
  onChange: (option: AddPlanOptionFormValues) => void;
};

export const AddPlanOptionForm = memo<Props>(({ form, onChange }) => {
  const { initialValues, change: onValuesChange } = useAddPlanOptionForm(onChange);

  return (
    <Form
      name="option"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <AddPlanOptionFormItem
        label="オプション名"
        name="name"
        rules={[{ required: true, message: "オプション名を入力してください" }]}
        wrapperCol={{ span: 12 }}
      >
        <Input />
      </AddPlanOptionFormItem>
      <AddPlanOptionFormItem
        label="伝票表示名"
        name="receiptDisplayName"
        rules={[{ required: true, message: "伝票表示名を入力してください" }]}
        wrapperCol={{ span: 12 }}
      >
        <Input />
      </AddPlanOptionFormItem>
      <AddPlanOptionFormItem label="選択肢の印刷" name="isMainOption" valuePropName="checked">
        <Switch />
      </AddPlanOptionFormItem>
      <AddPlanOptionFormItem label="入力方法" name="inputType">
        <Radio.Group>
          <Radio value={OptionInputTypeEnum.Count}>個数入力</Radio>
          <Radio value={OptionInputTypeEnum.Select}>選択</Radio>
        </Radio.Group>
      </AddPlanOptionFormItem>
      <Row>
        <Col span={12}>
          <MinChoiceNumWithMaxChoiceNumField
            label="最小選択種類数"
            name="minChoiceNum"
            rules={[{ required: true, message: "最小選択種類数を入力してください" }]}
          >
            <InputNumber min={0} />
          </MinChoiceNumWithMaxChoiceNumField>
        </Col>
        <Col span={12}>
          <MaxChoiceNumWithMinChoiceNumField
            label={
              <FormHelp
                label="最大選択種類数"
                help="最大注文個数を指定しない場合は、何個でも選択できます。"
              />
            }
            name="maxChoiceNum"
          >
            <InputNumber min={0} />
          </MaxChoiceNumWithMinChoiceNumField>
        </Col>
      </Row>

      <AddPlanOptionTranslationOptions />

      <Alert
        type="info"
        message="印刷先は「印刷設定 > プランオプション」から選択できます"
        showIcon
      />
    </Form>
  );
});
