import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsScoresGetShops = gql`
    query QuestionnaireAnalyticsScoresGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export const QuestionnaireAnalyticsScoresGetQuestionnaire = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const QuestionnaireAnalyticsScoresGetQuestionnaireAnswer = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaireAnswer($questionnaireId: uuid!, $shopIds: [uuid!]!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_in: $shopIds}, visitedAt: {_lte: $endAt, _gte: $startAt}, shop: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;
export const QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetrics = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetrics($input: QuestionnaireScoreMetricsInput!) {
  questionnaireScoreMetrics(input: $input) {
    corporationAverageScore {
      corporationId
      answerCount
      cleanliness
      customerService
      repeatability
      deliciousness
      speed
    }
    shopAverageScores {
      shopId
      shopName
      answerCount
      cleanliness {
        fluctuation
        score
      }
      customerService {
        fluctuation
        score
      }
      deliciousness {
        fluctuation
        score
      }
      repeatability {
        fluctuation
        score
      }
      speed {
        fluctuation
        score
      }
    }
  }
}
    `;
export type QuestionnaireAnalyticsScoresGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  questionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsScoresGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);

export type QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsScoresGetQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id'>
  )> }
);

export type QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid'];
  shopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  startAt: Types.Scalars['timestamptz'];
  endAt: Types.Scalars['timestamptz'];
}>;


export type QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery = (
  { __typename?: 'query_root' }
  & { questionnaireAnswer: Array<(
    { __typename?: 'questionnaireAnswer' }
    & Pick<Types.QuestionnaireAnswer, 'id' | 'shopId'>
  )> }
);

export type QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables = Types.Exact<{
  input: Types.QuestionnaireScoreMetricsInput;
}>;


export type QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireScoreMetrics: (
    { __typename?: 'QuestionnaireScoreMetricsOutput' }
    & { corporationAverageScore: (
      { __typename?: 'QuestionnaireCorporationAverageScore' }
      & Pick<Types.QuestionnaireCorporationAverageScore, 'corporationId' | 'answerCount' | 'cleanliness' | 'customerService' | 'repeatability' | 'deliciousness' | 'speed'>
    ), shopAverageScores: Array<(
      { __typename?: 'QuestionnaireShopAverageScore' }
      & Pick<Types.QuestionnaireShopAverageScore, 'shopId' | 'shopName' | 'answerCount'>
      & { cleanliness: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), customerService: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), deliciousness: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), repeatability: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), speed: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ) }
    )> }
  ) }
);


export const QuestionnaireAnalyticsScoresGetShopsDocument = gql`
    query QuestionnaireAnalyticsScoresGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoresGetShopsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoresGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoresGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoresGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoresGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoresGetShopsQuery, QuestionnaireAnalyticsScoresGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoresGetShopsQuery, QuestionnaireAnalyticsScoresGetShopsQueryVariables>(QuestionnaireAnalyticsScoresGetShopsDocument, options);
      }
export function useQuestionnaireAnalyticsScoresGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoresGetShopsQuery, QuestionnaireAnalyticsScoresGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoresGetShopsQuery, QuestionnaireAnalyticsScoresGetShopsQueryVariables>(QuestionnaireAnalyticsScoresGetShopsDocument, options);
        }
export type QuestionnaireAnalyticsScoresGetShopsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetShopsQuery>;
export type QuestionnaireAnalyticsScoresGetShopsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetShopsLazyQuery>;
export type QuestionnaireAnalyticsScoresGetShopsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoresGetShopsQuery, QuestionnaireAnalyticsScoresGetShopsQueryVariables>;
export const QuestionnaireAnalyticsScoresGetQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoresGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoresGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoresGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoresGetQuestionnaireQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoresGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireQuery, QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoresGetQuestionnaireQuery, QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsScoresGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireQuery, QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoresGetQuestionnaireQuery, QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsScoresGetQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoresGetQuestionnaireQuery, QuestionnaireAnalyticsScoresGetQuestionnaireQueryVariables>;
export const QuestionnaireAnalyticsScoresGetQuestionnaireAnswerDocument = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaireAnswer($questionnaireId: uuid!, $shopIds: [uuid!]!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_in: $shopIds}, visitedAt: {_lte: $endAt, _gte: $startAt}, shop: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      shopIds: // value for 'shopIds'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireAnswerDocument, options);
      }
export function useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireAnswerDocument, options);
        }
export type QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireAnswerLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireAnswerLazyQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsScoresGetQuestionnaireAnswerQueryVariables>;
export const QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsDocument = gql`
    query QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetrics($input: QuestionnaireScoreMetricsInput!) {
  questionnaireScoreMetrics(input: $input) {
    corporationAverageScore {
      corporationId
      answerCount
      cleanliness
      customerService
      repeatability
      deliciousness
      speed
    }
    shopAverageScores {
      shopId
      shopName
      answerCount
      cleanliness {
        fluctuation
        score
      }
      customerService {
        fluctuation
        score
      }
      deliciousness {
        fluctuation
        score
      }
      repeatability {
        fluctuation
        score
      }
      speed {
        fluctuation
        score
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsDocument, options);
      }
export function useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables>(QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsDocument, options);
        }
export type QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsLazyQuery>;
export type QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsScoresGetQuestionnaireScoreMetricsQueryVariables>;