import React, { memo, ReactNode } from "react";
import styled from "styled-components";
import { Divider } from "antd";
import { StarFilled } from "@ant-design/icons";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { ScoreMetrics } from "components/QuestionnaireAnalytics/ScoreMetricsPanel/ScoreMetrics";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

const StyledStarIcon = styled(StarFilled)`
  font-size: 16px;
  margin-right: 4px;
`;

const ScoreWithAverage = styled.div`
  width: 168px;
  @media ${viewport.smartphone} {
    width: 140px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled(FlexBox)`
  @media ${viewport.tablet}, ${viewport.desktop} {
    justify-content: center;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0px 0px 8px;
`;

const AverageScoreText = styled(FlexBox)`
  font-weight: 500;
`;

type Props = {
  title: string;
  shopAverageScore: { score: number; fluctuation: number };
  corporationAverageScore: number;
  allTenantAverageScore: number;
  className?: string;
  shouldShowStarIcon?: boolean;
  isEmpty: boolean;
  children?: ReactNode;
};

export const PrimaryScoreMetricsPanel = memo<Props>(
  ({
    title,
    shopAverageScore,
    corporationAverageScore,
    allTenantAverageScore,
    className,
    children,
    shouldShowStarIcon = true,
    isEmpty,
  }) => {
    const { score, fluctuation } = shopAverageScore;

    const { isSmartphone } = useViewport();

    return (
      <BasePanel
        className={className}
        title={
          <>
            {shouldShowStarIcon && <StyledStarIcon />}
            {title}
          </>
        }
        isEmpty={isEmpty}
      >
        <Spacer height={16} />
        <Container>
          {children}
          <Spacer size={24} />
          <ScoreWithAverage>
            <div>
              <ScoreMetrics
                score={score}
                fluctuation={fluctuation}
                large={!isSmartphone}
                isEmpty={isEmpty}
              />
            </div>
            <StyledDivider />
            <AverageScoreText>
              <span>社内平均</span>
              <span>{corporationAverageScore}</span>
            </AverageScoreText>

            <Spacer height={8} />

            <AverageScoreText>
              <span>ダイニー導入店平均</span>
              <span>{allTenantAverageScore}</span>
            </AverageScoreText>
          </ScoreWithAverage>
        </Container>
      </BasePanel>
    );
  },
);
