import React, { memo } from "react";
import { Popconfirm, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { FractionalDiscountOnSitePaymentDiscountType } from "models/onSitePaymentDiscountType";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { OnSitePaymentDiscountType } from "../types";

type Props = {
  loading?: boolean;
  discountTypes: OnSitePaymentDiscountType[];
  onArchive: ({ id }: { id: string }) => void;
};

export const DiscountTypeTable = memo<Props>(({ loading, discountTypes, onArchive }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns: ColumnsType<OnSitePaymentDiscountType> = [
    {
      title: "割引方法",
      render(_: string, { label }: OnSitePaymentDiscountType) {
        return label;
      },
    },
    ...(canAccess("editOnSitePaymentDiscountTypes")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id, type }: OnSitePaymentDiscountType) {
              if (type === FractionalDiscountOnSitePaymentDiscountType) return null;

              return (
                <Tooltip title="割引方法を編集">
                  <IconLink to={`/onSitePaymentDiscountType/${id}/edit`}>
                    <EditIcon />
                  </IconLink>
                </Tooltip>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("deleteOnSitePaymentDiscountTypes")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id, type }: OnSitePaymentDiscountType) {
              if (type === FractionalDiscountOnSitePaymentDiscountType) return null;

              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>割引方法を削除しますか？</Paragraph>
                      <Paragraph>レジを再起動すると割引方法が表示されなくなります。</Paragraph>
                      <Paragraph>一度削除した割引方法を元に戻すことはできません。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => onArchive({ id })}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={discountTypes}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
