import React, { useMemo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import type { ColumnsType } from "antd/lib/table";

import { AsyncSwitch } from "components/AsyncSwitch";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { useSelectedTableShopIds } from "hooks/useSelectedTableShopIds";
import type { Shop as BaseShop } from "types/graphql";

import { BulkUpdateIsDisplayBanner } from "./BulkUpdateIsDisplayBanner";

const DEFAULT_PAGE_SIZE = 10;

const AllCheckbox = styled(Checkbox)`
  margin-left: 8px;
`;

// NOTE: styled(Table) だとtsエラー
const TableWrapper = styled.div`
  .ant-table {
    padding: 0 24px 24px;
  }
  .ant-table-selection-column {
    text-align: right;
    padding-left: 16px;
    padding-right: 16px;
  }
  th.ant-table-selection-column {
    width: 100px;
  }
`;

type UpdateTargetCandidate = keyof {
  [K in keyof BaseShop as BaseShop[K] extends { isDisplay: boolean }[] ? K : never]: BaseShop[K];
};

export const BulkUpdateIsDisplayByShopTable = <
  UpdateTarget extends UpdateTargetCandidate,
  Shop extends Pick<BaseShop, "shopId" | "name"> & {
    [K in UpdateTarget]: { isDisplay: boolean }[];
  },
>({
  loading,
  shops,
  updateTarget,
  onUpdateIsDisplay,
  onBulkUpdateIsDisplay,
}: {
  loading?: boolean;
  shops: Shop[];
  updateTarget: UpdateTarget;
  onUpdateIsDisplay(shopId: string, isDisplay: boolean): Promise<void>;
  onBulkUpdateIsDisplay({
    shopIds,
    isDisplay,
  }: {
    shopIds: string[];
    isDisplay: boolean;
  }): Promise<void>;
}) => {
  const [defaultPagination, setPagination] = usePagination();

  const pagination = useMemo(
    () => ({
      ...defaultPagination,
      defaultCurrent: defaultPagination.defaultCurrent ?? 1,
      defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
    }),
    [defaultPagination],
  );

  const {
    selectedShopIds,
    setSelectedShopIds,
    currentPageSelectedShopIds,
    clearCurrentPageSelectedShopIds,
    allCheckboxProps,
  } = useSelectedTableShopIds({ pagination, shops });

  const onDisplayButtonClick = async (isDisplay: boolean) => {
    await onBulkUpdateIsDisplay({ shopIds: currentPageSelectedShopIds, isDisplay });
    clearCurrentPageSelectedShopIds();
  };

  const columns: ColumnsType<Shop> = [
    {
      title: "表示設定",
      width: 100,
      align: "center",
      render(_, { shopId, [updateTarget]: target }) {
        const isDisplay = target.every(({ isDisplay }) => isDisplay === true);
        return (
          <AsyncSwitch onChange={() => onUpdateIsDisplay(shopId, !isDisplay)} checked={isDisplay} />
        );
      },
    },
    {
      title: "店舗名",
      dataIndex: "name",
    },
  ];

  return (
    <>
      {currentPageSelectedShopIds.length !== 0 && (
        <BulkUpdateIsDisplayBanner
          onClearButtonClick={clearCurrentPageSelectedShopIds}
          selectedCount={currentPageSelectedShopIds.length}
          onDisplayButtonClick={onDisplayButtonClick}
        />
      )}

      <TableWrapper>
        <Table
          loading={loading}
          dataSource={shops}
          columns={columns}
          rowKey="shopId"
          bordered
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          pagination={pagination}
          rowSelection={{
            columnTitle: (
              <>
                全選択
                <AllCheckbox {...allCheckboxProps} />
              </>
            ),
            selectedRowKeys: selectedShopIds,
            onChange: (_, rows) => setSelectedShopIds(rows.map(({ shopId }) => shopId)),
          }}
        />
      </TableWrapper>
    </>
  );
};
