import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useShop } from "hooks/useShop";
import { GiftChoiceTable } from "pages/GiftChoices/GiftChoiceTable";
import {
  useGiftChoicesDeleteGiftChoiceMutation,
  useGiftChoicesGetGiftOptionMetaQuery,
} from "pages/GiftChoices/queries";

export const GiftChoices = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getGiftChoicesData,
    loading: loadingGiftChoices,
    refetch: refetchGiftChoices,
    error: getGiftChoicesError,
  } = useGiftChoicesGetGiftOptionMetaQuery(shopId ? { variables: { shopId } } : { skip: true });
  const giftOptionMeta = useMemo(
    () => getGiftChoicesData?.giftOptionMeta[0] ?? null,
    [getGiftChoicesData],
  );
  const choices = useMemo(
    () => giftOptionMeta?.option.choices ?? [],
    [giftOptionMeta?.option.choices],
  );

  const [deleteGiftChoiceMutation] = useGiftChoicesDeleteGiftChoiceMutation();

  const onDeleteChoice = useCallback(
    async ({ choiceId }: { choiceId: number }) => {
      try {
        const targetChoice = choices.find((choice) => choice.choiceId === choiceId);
        if (!targetChoice) throw new Error();

        await deleteGiftChoiceMutation({
          variables: {
            choiceId,
            shopChoiceIds: targetChoice.shopChoices.map(({ id }) => id),
            archivedAt: new Date().toISOString(),
          },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchGiftChoices();
    },
    [choices, deleteGiftChoiceMutation, refetchGiftChoices],
  );

  const shouldShowAlert = getGiftChoicesError;

  const { isFeatureEnabled } = useIsFeatureEnabled();

  return (
    <DashboardLayout title="推しエール金額一覧">
      <PageHeader
        title="推しエール金額一覧"
        extra={
          isFeatureEnabled("addChoice")
            ? [
                <Link key="add" to="/giftChoice/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []
        }
        footer={<ShopSelector />}
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <GiftChoiceTable choices={choices} loading={loadingGiftChoices} onDelete={onDeleteChoice} />
    </DashboardLayout>
  );
};
