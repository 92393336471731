import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Alert, Spin } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { EditMembershipRankConfigFormValues } from "./EditMembershipRankConfigForm/useEditMembershipRankConfigForm";
import { EditMembershipRankConfigForm } from "./EditMembershipRankConfigForm";
import {
  useEditMembershipRankConfigGetRankConfigQuery,
  useEditMembershipRankConfigUpdateRankConfigMutation,
} from "./queries";

export const EditMembershipRankConfig = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;
  const { id } = useParams<{ id: string }>();

  const {
    data,
    loading: loadingGetRankConfig,
    refetch: refetchRankConfig,
    error,
  } = useEditMembershipRankConfigGetRankConfigQuery(
    id && companyId ? { variables: { id, companyId } } : { skip: true },
  );
  const rankConfig = useMemo(
    () => data?.membershipRankConfig_by_pk,
    [data?.membershipRankConfig_by_pk],
  );
  const coupons = useMemo(() => data?.coupon ?? [], [data?.coupon]);
  const [updateRankConfigMutation, { loading: loadingUpdateRankConfig }] =
    useEditMembershipRankConfigUpdateRankConfigMutation();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const onSubmit = useCallback(
    async ({ input }: { input: EditMembershipRankConfigFormValues }) => {
      try {
        if (!id) return;
        await updateRankConfigMutation({
          variables: { id, input },
        });
        await refetchRankConfig();
        message.success("更新しました");
        goBack();
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [id, updateRankConfigMutation, goBack, refetchRankConfig],
  );

  return (
    <DashboardLayout
      title="会員ランクの編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "会員ランク管理" }],
      }}
    >
      <PageHeader title="会員ランクの編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spin spinning={loadingGetRankConfig || loadingUpdateRankConfig}>
        {rankConfig && (
          <EditMembershipRankConfigForm
            rankConfig={rankConfig}
            coupons={coupons}
            loading={loadingGetRankConfig || loadingUpdateRankConfig}
            onSubmit={onSubmit}
            onCancel={goBack}
            onFormValidationError={onFormValidationError}
          />
        )}
      </Spin>
    </DashboardLayout>
  );
};
