import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanOptionModalGetPlanOption = gql`
    query EditPlanOptionModalGetPlanOption($planOptionId: Int!) {
  planOption(
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    description
    inputType
    isMainOption
    maxChoiceNum
    minChoiceNum
    name
    planOptionId
    receiptDisplayName
    plan {
      company {
        id
      }
    }
    translations(where: {tableName: {_eq: "planOption"}}) {
      columnName
      locale
      value
    }
  }
}
    `;
export const EditPlanOptionModalGetRoles = gql`
    query EditPlanOptionModalGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      name
      roleId
    }
  }
}
    `;
export const EditPlanOptionModalUpdateSelectPlanOption = gql`
    mutation EditPlanOptionModalUpdateSelectPlanOption($planOptionId: Int!, $planOption: planOption_set_input!, $translations: UpsertTranslationsForPlanOptionInput!) {
  update_planOption(
    _set: $planOption
    where: {planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  update_planChoice(
    _set: {maxOrderableNum: 1}
    where: {_planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanOption(input: $translations)
}
    `;
export const EditPlanOptionModalUpdateCountPlanOption = gql`
    mutation EditPlanOptionModalUpdateCountPlanOption($planOptionId: Int!, $planOption: planOption_set_input!, $translations: UpsertTranslationsForPlanOptionInput!) {
  update_planOption(
    _set: $planOption
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
  update_planChoice(
    _set: {maxOrderableNum: null}
    where: {_planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanOption(input: $translations)
}
    `;
export type EditPlanOptionModalGetPlanOptionQueryVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
}>;


export type EditPlanOptionModalGetPlanOptionQuery = (
  { __typename?: 'query_root' }
  & { planOption: Array<(
    { __typename?: 'planOption' }
    & Pick<Types.PlanOption, 'id' | 'description' | 'inputType' | 'isMainOption' | 'maxChoiceNum' | 'minChoiceNum' | 'name' | 'planOptionId' | 'receiptDisplayName'>
    & { plan: (
      { __typename?: 'plan' }
      & { company: (
        { __typename?: 'company' }
        & Pick<Types.Company, 'id'>
      ) }
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type EditPlanOptionModalGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditPlanOptionModalGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'name' | 'roleId'>
    )> }
  )> }
);

export type EditPlanOptionModalUpdateSelectPlanOptionMutationVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
  planOption: Types.PlanOptionSetInput;
  translations: Types.UpsertTranslationsForPlanOptionInput;
}>;


export type EditPlanOptionModalUpdateSelectPlanOptionMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForPlanOption'>
  & { update_planOption?: Types.Maybe<(
    { __typename?: 'planOption_mutation_response' }
    & Pick<Types.PlanOptionMutationResponse, 'affected_rows'>
  )>, update_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
  )> }
);

export type EditPlanOptionModalUpdateCountPlanOptionMutationVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
  planOption: Types.PlanOptionSetInput;
  translations: Types.UpsertTranslationsForPlanOptionInput;
}>;


export type EditPlanOptionModalUpdateCountPlanOptionMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForPlanOption'>
  & { update_planOption?: Types.Maybe<(
    { __typename?: 'planOption_mutation_response' }
    & Pick<Types.PlanOptionMutationResponse, 'affected_rows'>
  )>, update_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanOptionModalGetPlanOptionDocument = gql`
    query EditPlanOptionModalGetPlanOption($planOptionId: Int!) {
  planOption(
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    description
    inputType
    isMainOption
    maxChoiceNum
    minChoiceNum
    name
    planOptionId
    receiptDisplayName
    plan {
      company {
        id
      }
    }
    translations(where: {tableName: {_eq: "planOption"}}) {
      columnName
      locale
      value
    }
  }
}
    `;

/**
 * __useEditPlanOptionModalGetPlanOptionQuery__
 *
 * To run a query within a React component, call `useEditPlanOptionModalGetPlanOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanOptionModalGetPlanOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanOptionModalGetPlanOptionQuery({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *   },
 * });
 */
export function useEditPlanOptionModalGetPlanOptionQuery(baseOptions: Apollo.QueryHookOptions<EditPlanOptionModalGetPlanOptionQuery, EditPlanOptionModalGetPlanOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanOptionModalGetPlanOptionQuery, EditPlanOptionModalGetPlanOptionQueryVariables>(EditPlanOptionModalGetPlanOptionDocument, options);
      }
export function useEditPlanOptionModalGetPlanOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanOptionModalGetPlanOptionQuery, EditPlanOptionModalGetPlanOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanOptionModalGetPlanOptionQuery, EditPlanOptionModalGetPlanOptionQueryVariables>(EditPlanOptionModalGetPlanOptionDocument, options);
        }
export type EditPlanOptionModalGetPlanOptionQueryHookResult = ReturnType<typeof useEditPlanOptionModalGetPlanOptionQuery>;
export type EditPlanOptionModalGetPlanOptionLazyQueryHookResult = ReturnType<typeof useEditPlanOptionModalGetPlanOptionLazyQuery>;
export type EditPlanOptionModalGetPlanOptionQueryResult = Apollo.QueryResult<EditPlanOptionModalGetPlanOptionQuery, EditPlanOptionModalGetPlanOptionQueryVariables>;
export const EditPlanOptionModalGetRolesDocument = gql`
    query EditPlanOptionModalGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      name
      roleId
    }
  }
}
    `;

/**
 * __useEditPlanOptionModalGetRolesQuery__
 *
 * To run a query within a React component, call `useEditPlanOptionModalGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanOptionModalGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanOptionModalGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditPlanOptionModalGetRolesQuery(baseOptions: Apollo.QueryHookOptions<EditPlanOptionModalGetRolesQuery, EditPlanOptionModalGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanOptionModalGetRolesQuery, EditPlanOptionModalGetRolesQueryVariables>(EditPlanOptionModalGetRolesDocument, options);
      }
export function useEditPlanOptionModalGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanOptionModalGetRolesQuery, EditPlanOptionModalGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanOptionModalGetRolesQuery, EditPlanOptionModalGetRolesQueryVariables>(EditPlanOptionModalGetRolesDocument, options);
        }
export type EditPlanOptionModalGetRolesQueryHookResult = ReturnType<typeof useEditPlanOptionModalGetRolesQuery>;
export type EditPlanOptionModalGetRolesLazyQueryHookResult = ReturnType<typeof useEditPlanOptionModalGetRolesLazyQuery>;
export type EditPlanOptionModalGetRolesQueryResult = Apollo.QueryResult<EditPlanOptionModalGetRolesQuery, EditPlanOptionModalGetRolesQueryVariables>;
export const EditPlanOptionModalUpdateSelectPlanOptionDocument = gql`
    mutation EditPlanOptionModalUpdateSelectPlanOption($planOptionId: Int!, $planOption: planOption_set_input!, $translations: UpsertTranslationsForPlanOptionInput!) {
  update_planOption(
    _set: $planOption
    where: {planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  update_planChoice(
    _set: {maxOrderableNum: 1}
    where: {_planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanOption(input: $translations)
}
    `;
export type EditPlanOptionModalUpdateSelectPlanOptionMutationFn = Apollo.MutationFunction<EditPlanOptionModalUpdateSelectPlanOptionMutation, EditPlanOptionModalUpdateSelectPlanOptionMutationVariables>;

/**
 * __useEditPlanOptionModalUpdateSelectPlanOptionMutation__
 *
 * To run a mutation, you first call `useEditPlanOptionModalUpdateSelectPlanOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanOptionModalUpdateSelectPlanOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanOptionModalUpdateSelectPlanOptionMutation, { data, loading, error }] = useEditPlanOptionModalUpdateSelectPlanOptionMutation({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *      planOption: // value for 'planOption'
 *      translations: // value for 'translations'
 *   },
 * });
 */
export function useEditPlanOptionModalUpdateSelectPlanOptionMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanOptionModalUpdateSelectPlanOptionMutation, EditPlanOptionModalUpdateSelectPlanOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanOptionModalUpdateSelectPlanOptionMutation, EditPlanOptionModalUpdateSelectPlanOptionMutationVariables>(EditPlanOptionModalUpdateSelectPlanOptionDocument, options);
      }
export type EditPlanOptionModalUpdateSelectPlanOptionMutationHookResult = ReturnType<typeof useEditPlanOptionModalUpdateSelectPlanOptionMutation>;
export type EditPlanOptionModalUpdateSelectPlanOptionMutationResult = Apollo.MutationResult<EditPlanOptionModalUpdateSelectPlanOptionMutation>;
export type EditPlanOptionModalUpdateSelectPlanOptionMutationOptions = Apollo.BaseMutationOptions<EditPlanOptionModalUpdateSelectPlanOptionMutation, EditPlanOptionModalUpdateSelectPlanOptionMutationVariables>;
export const EditPlanOptionModalUpdateCountPlanOptionDocument = gql`
    mutation EditPlanOptionModalUpdateCountPlanOption($planOptionId: Int!, $planOption: planOption_set_input!, $translations: UpsertTranslationsForPlanOptionInput!) {
  update_planOption(
    _set: $planOption
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
  update_planChoice(
    _set: {maxOrderableNum: null}
    where: {_planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanOption(input: $translations)
}
    `;
export type EditPlanOptionModalUpdateCountPlanOptionMutationFn = Apollo.MutationFunction<EditPlanOptionModalUpdateCountPlanOptionMutation, EditPlanOptionModalUpdateCountPlanOptionMutationVariables>;

/**
 * __useEditPlanOptionModalUpdateCountPlanOptionMutation__
 *
 * To run a mutation, you first call `useEditPlanOptionModalUpdateCountPlanOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanOptionModalUpdateCountPlanOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanOptionModalUpdateCountPlanOptionMutation, { data, loading, error }] = useEditPlanOptionModalUpdateCountPlanOptionMutation({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *      planOption: // value for 'planOption'
 *      translations: // value for 'translations'
 *   },
 * });
 */
export function useEditPlanOptionModalUpdateCountPlanOptionMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanOptionModalUpdateCountPlanOptionMutation, EditPlanOptionModalUpdateCountPlanOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanOptionModalUpdateCountPlanOptionMutation, EditPlanOptionModalUpdateCountPlanOptionMutationVariables>(EditPlanOptionModalUpdateCountPlanOptionDocument, options);
      }
export type EditPlanOptionModalUpdateCountPlanOptionMutationHookResult = ReturnType<typeof useEditPlanOptionModalUpdateCountPlanOptionMutation>;
export type EditPlanOptionModalUpdateCountPlanOptionMutationResult = Apollo.MutationResult<EditPlanOptionModalUpdateCountPlanOptionMutation>;
export type EditPlanOptionModalUpdateCountPlanOptionMutationOptions = Apollo.BaseMutationOptions<EditPlanOptionModalUpdateCountPlanOptionMutation, EditPlanOptionModalUpdateCountPlanOptionMutationVariables>;