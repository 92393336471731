import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditOptionTranslationsFormItem } from "./useEditOptionTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OptionNameField = memo<Props>(({ ...props }) => (
  <EditOptionTranslationsFormItem label="オプション名：原文" name="optionName" {...props}>
    <Input disabled />
  </EditOptionTranslationsFormItem>
));
