import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { adyenTerminalPaymentPath, adyenTerminalPaymentSignOutPagePath } from "constants/auth";
import { useSignOutSignOutMutation } from "hooks/useSignOut/queries";

export const useSignOut = () => {
  const [signOutMutation] = useSignOutSignOutMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const signOut = useCallback(() => {
    // NOTE: 決済端末管理ページ以下の場合は決済端末管理用のログアウトページにリダイレクトされる
    if (location.pathname.includes(adyenTerminalPaymentPath)) {
      return navigate(adyenTerminalPaymentSignOutPagePath, { replace: true });
    }

    navigate("/signOut", { replace: true });
  }, [location.pathname, navigate]);

  return { signOut, signOutMutation };
};
