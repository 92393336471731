import { useCallback } from "react";
import { Dayjs } from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

export type EditTargetDateFormValues = {
  targetDate: Dayjs;
};

const getInitialValues = (targetDate: Dayjs) => ({ targetDate });

export const EditTargetDateFormItem = createFormItem<EditTargetDateFormValues>();

export const useEditTargetDateForm = (
  targetDate: Dayjs,
  onSubmit: (targetDate: EditTargetDateFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(targetDate);

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
