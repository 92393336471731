import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetFaveYellAnalyticsByCorporation = gql`
    query GetFaveYellAnalyticsByCorporation($input: FaveYellAnalyticsByCorporationInput!) {
  faveYellAnalyticsByCorporation(input: $input) {
    summary {
      corporationId
      faveYellCount
      faveYellCustomerCount
      totalTaxIncludedSales
    }
    items {
      corporationId
      companyId
      _menuId
      shopId
      shopName
      staffName
      staffImageUrl
      faveYellCount
      faveYellCustomerCount
      faveYellSalesRank
      totalTaxIncludedSales
    }
  }
}
    `;
export const FaveYellAnalyticsGetShops = gql`
    query FaveYellAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
        shopId
        name
        useKd
      }
    }
  }
}
    `;
export type GetFaveYellAnalyticsByCorporationQueryVariables = Types.Exact<{
  input: Types.FaveYellAnalyticsByCorporationInput;
}>;


export type GetFaveYellAnalyticsByCorporationQuery = (
  { __typename?: 'query_root' }
  & { faveYellAnalyticsByCorporation: (
    { __typename?: 'FaveYellAnalyticsByCorporationOutput' }
    & { summary: Array<(
      { __typename?: 'FaveYellAnalyticsSummaryByCorporationOutputItem' }
      & Pick<Types.FaveYellAnalyticsSummaryByCorporationOutputItem, 'corporationId' | 'faveYellCount' | 'faveYellCustomerCount' | 'totalTaxIncludedSales'>
    )>, items: Array<(
      { __typename?: 'FaveYellAnalyticsPerStaffByCorporationItem' }
      & Pick<Types.FaveYellAnalyticsPerStaffByCorporationItem, 'corporationId' | 'companyId' | '_menuId' | 'shopId' | 'shopName' | 'staffName' | 'staffImageUrl' | 'faveYellCount' | 'faveYellCustomerCount' | 'faveYellSalesRank' | 'totalTaxIncludedSales'>
    )> }
  ) }
);

export type FaveYellAnalyticsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type FaveYellAnalyticsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name' | 'useKd'>
      )> }
    )> }
  )> }
);


export const GetFaveYellAnalyticsByCorporationDocument = gql`
    query GetFaveYellAnalyticsByCorporation($input: FaveYellAnalyticsByCorporationInput!) {
  faveYellAnalyticsByCorporation(input: $input) {
    summary {
      corporationId
      faveYellCount
      faveYellCustomerCount
      totalTaxIncludedSales
    }
    items {
      corporationId
      companyId
      _menuId
      shopId
      shopName
      staffName
      staffImageUrl
      faveYellCount
      faveYellCustomerCount
      faveYellSalesRank
      totalTaxIncludedSales
    }
  }
}
    `;

/**
 * __useGetFaveYellAnalyticsByCorporationQuery__
 *
 * To run a query within a React component, call `useGetFaveYellAnalyticsByCorporationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaveYellAnalyticsByCorporationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaveYellAnalyticsByCorporationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFaveYellAnalyticsByCorporationQuery(baseOptions: Apollo.QueryHookOptions<GetFaveYellAnalyticsByCorporationQuery, GetFaveYellAnalyticsByCorporationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFaveYellAnalyticsByCorporationQuery, GetFaveYellAnalyticsByCorporationQueryVariables>(GetFaveYellAnalyticsByCorporationDocument, options);
      }
export function useGetFaveYellAnalyticsByCorporationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFaveYellAnalyticsByCorporationQuery, GetFaveYellAnalyticsByCorporationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFaveYellAnalyticsByCorporationQuery, GetFaveYellAnalyticsByCorporationQueryVariables>(GetFaveYellAnalyticsByCorporationDocument, options);
        }
export type GetFaveYellAnalyticsByCorporationQueryHookResult = ReturnType<typeof useGetFaveYellAnalyticsByCorporationQuery>;
export type GetFaveYellAnalyticsByCorporationLazyQueryHookResult = ReturnType<typeof useGetFaveYellAnalyticsByCorporationLazyQuery>;
export type GetFaveYellAnalyticsByCorporationQueryResult = Apollo.QueryResult<GetFaveYellAnalyticsByCorporationQuery, GetFaveYellAnalyticsByCorporationQueryVariables>;
export const FaveYellAnalyticsGetShopsDocument = gql`
    query FaveYellAnalyticsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    companies(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
        shopId
        name
        useKd
      }
    }
  }
}
    `;

/**
 * __useFaveYellAnalyticsGetShopsQuery__
 *
 * To run a query within a React component, call `useFaveYellAnalyticsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaveYellAnalyticsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaveYellAnalyticsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useFaveYellAnalyticsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<FaveYellAnalyticsGetShopsQuery, FaveYellAnalyticsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FaveYellAnalyticsGetShopsQuery, FaveYellAnalyticsGetShopsQueryVariables>(FaveYellAnalyticsGetShopsDocument, options);
      }
export function useFaveYellAnalyticsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaveYellAnalyticsGetShopsQuery, FaveYellAnalyticsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FaveYellAnalyticsGetShopsQuery, FaveYellAnalyticsGetShopsQueryVariables>(FaveYellAnalyticsGetShopsDocument, options);
        }
export type FaveYellAnalyticsGetShopsQueryHookResult = ReturnType<typeof useFaveYellAnalyticsGetShopsQuery>;
export type FaveYellAnalyticsGetShopsLazyQueryHookResult = ReturnType<typeof useFaveYellAnalyticsGetShopsLazyQuery>;
export type FaveYellAnalyticsGetShopsQueryResult = Apollo.QueryResult<FaveYellAnalyticsGetShopsQuery, FaveYellAnalyticsGetShopsQueryVariables>;