import useAsyncFn from "react-use/esm/useAsyncFn";
import { isDiniiApiError } from "util/type/diniiApi";

import { message } from "components/antd/message";
import { useDinii } from "hooks/useDinii";
import { TecAggregationIntegrationTypeEnum } from "types/graphql";

export const useTecAggregationUpload = (integrationType?: TecAggregationIntegrationTypeEnum) => {
  const [dinii, getContext] = useDinii();

  const [{ loading }, submit] = useAsyncFn(
    async ({ date }: { date: Date }) => {
      if (!integrationType) return;

      const context = await getContext();

      if (!context) {
        return;
      }

      try {
        await dinii.tec.upload(context, date, integrationType);
        message.success("転送しました");
      } catch (err) {
        if (isDiniiApiError(err) && err.response?.data.title) {
          return message.error(err.response.data.title);
        }

        message.error("転送に失敗しました");
      }
    },
    [dinii.tec, getContext, integrationType],
  );

  return {
    loading,
    submit,
  };
};
