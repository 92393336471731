import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Checkbox, Col, Divider, Input, Row, Select, Switch } from "antd";
import { EditChoiceOrderModal } from "forms/AddOptionForm/EditChoiceOrderModal";
import { menuTypes } from "models/menuType";
import { taxRates } from "models/taxRate";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { FormActions } from "components/Form/FormActions";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";
import { MaxChoiceNumWithMinChoiceNumField } from "components/Form/MaxChoiceNumWithMinChoiceNumField";
import { MinChoiceNumWithMaxChoiceNumField } from "components/Form/MinChoiceNumWithMaxChoiceNumField";
import { InputNumber } from "components/Input/InputNumber";
import { InputPrice } from "components/Input/InputPrice";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useQueryParams } from "hooks/useQuery";
import { CreateOptionInputOptionSource } from "types/graphql";

import { OptionTranslationOptions } from "./OptionTranslationOptions";
import { SelectCopyOptionModal } from "./SelectCopyOptionModal";
import { Option as OptionType } from "./types";
import { AddOptionFormItem, useAddOptionForm } from "./useAddOptionForm";

const { Option } = Select;

const StyledCheckBox = styled(Checkbox)`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  margin: 20px;
`;

const AddChoiceButton = styled(Button)`
  margin: 0 20px 20px;
`;

const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const EditChoiceOrderButton = styled(Button)`
  margin: 0 20px;
`;

type Props = {
  onSubmit: (option: CreateOptionInputOptionSource) => void;
  navigateToAddOptionWithOptionId?: (option: OptionType) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose(): void;
  loading?: boolean;
  companyId?: string;
  options: OptionType[];
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const AddOptionForm = memo<Props>(
  ({
    onSubmit,
    navigateToAddOptionWithOptionId,
    onFormValidationError,
    onClose,
    loading,
    companyId,
    options,
  }) => {
    const {
      initialValues,
      submit,
      form,
      isEnabledMaxChoiceNum,
      isEnabledMinChoiceNum,
      copyFromAnotherOption,
      toggleMaxChoiceNum,
      toggleMinChoiceNum,
    } = useAddOptionForm({
      onSubmit,
      companyId,
    });

    const [selectCopyOptionModalVisible, setSelectCopyOptionModalVisible] = useState(false);
    const openSelectCopyOptionModal = useCallback(() => setSelectCopyOptionModalVisible(true), []);
    const closeSelectCopyOptionModal = useCallback(
      () => setSelectCopyOptionModalVisible(false),
      [],
    );

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, form, onFormValidationError]);

    const [editChoiceOrderModalVisible, setEditChoiceOrderModalVisible] = useState(false);
    const openEditChoiceOrderModal = useCallback(() => setEditChoiceOrderModalVisible(true), []);
    const closeEditChoiceOrderModal = useCallback(() => setEditChoiceOrderModalVisible(false), []);

    const query = useQueryParams();
    const sourceOptionId = query.get("optionId");

    useEffect(() => {
      if (!sourceOptionId) return;

      const option = options.find((option) => option.optionId === Number(sourceOptionId));

      if (option) {
        copyFromAnotherOption(option);
      }
    }, [sourceOptionId, options, copyFromAnotherOption]);

    return (
      <>
        <FormContent>
          <Form name="option" layout="vertical" form={form} initialValues={initialValues}>
            <ButtonContainer>
              <Button onClick={openSelectCopyOptionModal}>オプション情報をコピー</Button>
            </ButtonContainer>
            <Divider />
            <Row gutter={12}>
              <Col span={6}>
                <AddOptionFormItem
                  label="オプション名"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "オプション名を入力してください",
                    },
                  ]}
                >
                  <Input />
                </AddOptionFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <AddOptionFormItem
                  label="伝票表示名"
                  name="receiptDisplayName"
                  rules={[
                    {
                      required: true,
                      message: "伝票表示名を入力してください",
                    },
                  ]}
                >
                  <Input />
                </AddOptionFormItem>
              </Col>
            </Row>
            <Divider orientation="left">ルールの設定</Divider>
            <Row gutter={[16, 16]}>
              <Col>
                <StyledCheckBox checked={isEnabledMinChoiceNum} onChange={toggleMinChoiceNum}>
                  最小選択数を設定する
                </StyledCheckBox>
              </Col>
            </Row>
            {isEnabledMinChoiceNum && (
              <Row gutter={[16, 16]}>
                <Col>
                  <MinChoiceNumWithMaxChoiceNumField
                    name="minChoiceNum"
                    rules={[{ required: true, message: "最小選択数を入力してください" }]}
                  >
                    <InputNumber min={0} />
                  </MinChoiceNumWithMaxChoiceNumField>
                </Col>
              </Row>
            )}
            <Row>
              <StyledCheckBox checked={isEnabledMaxChoiceNum} onChange={toggleMaxChoiceNum}>
                最大選択数を設定する
              </StyledCheckBox>
            </Row>
            {isEnabledMaxChoiceNum && (
              <Row>
                <Col span={12}>
                  <MaxChoiceNumWithMinChoiceNumField
                    name="maxChoiceNum"
                    rules={[{ required: true, message: "最大選択数を入力してください" }]}
                  >
                    <InputNumber min={1} />
                  </MaxChoiceNumWithMinChoiceNumField>
                </Col>
              </Row>
            )}
            <Divider orientation="left">選択肢の設定</Divider>
            <FormList name="choices">
              {(fields, { add, remove }) => (
                <>
                  <FormActions>
                    <EditChoiceOrderButton
                      type="primary"
                      disabled={!(fields.length > 1)}
                      onClick={openEditChoiceOrderModal}
                    >
                      並び替え
                    </EditChoiceOrderButton>
                  </FormActions>
                  {fields.map((field, index) => (
                    <StyledCard key={field.key}>
                      <AddOptionFormItem noStyle required={true} name={[field.name]}>
                        <AddOptionFormItem.NonProperty noStyle shouldUpdate>
                          {({ getFieldValue, setFields }) => {
                            const imageNamePath = ["choices", field.name, "imageUrl"];
                            const imageUrl: string = getFieldValue(imageNamePath);
                            return (
                              <Row>
                                <ImageField
                                  image={imageUrl}
                                  setUploadImage={(image) =>
                                    setFields([{ name: imageNamePath, value: image }])
                                  }
                                  uploadImageApiKey="choice"
                                  formName="AddOptionForm"
                                  label="選択肢画像"
                                  isMovieAccepted
                                />
                              </Row>
                            );
                          }}
                        </AddOptionFormItem.NonProperty>
                        <Row>
                          <Col span={8}>
                            <AddOptionFormItem
                              label="選択肢名"
                              name={[field.name, "name"]}
                              rules={[{ required: true, message: "選択肢名を入力してください" }]}
                              wrapperCol={{ span: 18 }}
                            >
                              <Input />
                            </AddOptionFormItem>
                          </Col>
                          <Col span={8}>
                            <AddOptionFormItem
                              label="伝票表示名"
                              name={[field.name, "receiptDisplayName"]}
                              rules={[{ required: true, message: "伝票表示名を入力してください" }]}
                              wrapperCol={{ span: 18 }}
                            >
                              <Input />
                            </AddOptionFormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <AddOptionFormItem
                              label="価格"
                              name={[field.name, "price"]}
                              rules={[{ required: true, message: "価格を入力してください" }]}
                            >
                              <InputPrice />
                            </AddOptionFormItem>
                          </Col>
                          <Col span={8}>
                            <CostPriceWithCostTaxRateField
                              name={[field.name, "costPrice"]}
                              getCostTaxRateFieldValue={({ getFieldValue }) =>
                                getFieldValue("choices")?.[field.name]?.["costTaxRate"]
                              }
                            >
                              <InputNumber min={0} step={0.01} precision={2} />
                            </CostPriceWithCostTaxRateField>
                          </Col>
                          <Col span={8}>
                            <CostTaxRateWithCostPriceField
                              name={[field.name, "costTaxRate"]}
                              getCostPriceFieldValue={({ getFieldValue }) =>
                                getFieldValue("choices")?.[field.name]?.["costPrice"]
                              }
                            >
                              <Select<string>
                                data-cy={`option_choices_${index}_costTaxRate_select`}
                              >
                                {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
                                  <Option key={taxRateKey} value={taxRate}>
                                    {taxRate * 100}%
                                  </Option>
                                ))}
                              </Select>
                            </CostTaxRateWithCostPriceField>
                          </Col>
                        </Row>
                        <Row align="middle">
                          <Col span={8}>
                            <AddOptionFormItem
                              label="デフォルトで選択済みにする"
                              name={[field.name, "isDefaultSelection"]}
                              valuePropName="checked"
                            >
                              <Switch />
                            </AddOptionFormItem>
                          </Col>
                          <Col span={8}>
                            <AddOptionFormItem
                              label={
                                <FormHelp
                                  label="メニュータイプ"
                                  help="設定した値が CSV ダウンロードの出数集計にて出力されます。"
                                />
                              }
                              name={[field.name, "menuType"]}
                            >
                              <Select<string> data-cy={`option_choices_${index}_menuType_select`}>
                                {Object.entries(menuTypes).map(([menuType, menuTypeName]) => (
                                  <Option key={menuType} value={menuType}>
                                    {menuTypeName}
                                  </Option>
                                ))}
                              </Select>
                            </AddOptionFormItem>
                          </Col>
                          <Col span={8}>
                            <CardActions>
                              <Button icon={<DeleteIcon />} onClick={() => remove(field.name)} />
                            </CardActions>
                          </Col>
                        </Row>
                      </AddOptionFormItem>
                    </StyledCard>
                  ))}
                  <FormActions>
                    <AddChoiceButton type="primary" onClick={add}>
                      選択肢を追加
                    </AddChoiceButton>
                  </FormActions>
                </>
              )}
            </FormList>
            <EditChoiceOrderModal
              form={form}
              visible={editChoiceOrderModalVisible}
              closeModal={closeEditChoiceOrderModal}
            />
            <SelectCopyOptionModal
              visible={selectCopyOptionModalVisible}
              options={options}
              onDismiss={closeSelectCopyOptionModal}
              onSubmit={(option: OptionType) => {
                copyFromAnotherOption(option);
                navigateToAddOptionWithOptionId?.(option);
              }}
            />

            <OptionTranslationOptions />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
