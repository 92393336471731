import React, { memo } from "react";
import styled from "styled-components";

import { colors } from "constants/colors";

// Equivalent to `.ant-form-item-label > label`
const Wrapper = styled.p`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: ${colors.Text.Default};
  font-size: 14px;
  margin: 0;

  .ant-form-vertical & {
    height: auto;
  }
`;

type Props = {
  children?: React.ReactNode;
};

export const FormLabel = memo<Props>(({ children }) => <Wrapper>{children}</Wrapper>);
