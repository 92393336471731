import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/EditMenuRecommendation/EditMenuRecommendationForm/NameField";

import { MenuRecommendation, MenuRecommendationSetInput } from "../types";

import { useEditMenuRecommendationForm } from "./useEditMenuRecommendationForm";

type Props = {
  menuRecommendation: MenuRecommendation;
  onSubmit: (menuRecommendation: MenuRecommendationSetInput) => void;
  onClose: () => void;
  loading: boolean;
  disabled: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditMenuRecommendationForm = memo<Props>(
  ({ menuRecommendation, onClose, onSubmit, loading, disabled, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditMenuRecommendationForm(
      menuRecommendation,
      onSubmit,
    );

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="MenuRecommendation"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <NameField disabled={disabled} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
