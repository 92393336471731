import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { AccountingActionTypeEnum } from "types/graphql";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableOfflinePayment = memo<Props>(({ ...props }) => (
  <EditShopFormItem.NonProperty
    noStyle
    shouldUpdate={withFormDependencies(({ accountingActionType, enableOfflinePayment }) => [
      accountingActionType,
      enableOfflinePayment,
    ])}
  >
    {({ getFieldValue }) => {
      const accountingActionType = getFieldValue("accountingActionType");
      return (
        <EditShopFormItem
          label={
            <FormHelp
              label="自動会計伝票機能"
              help="自動会計伝票機能をONに設定した場合は、お客様がモバイルオーダーより「会計」ボタンを押すと会計待ちの状態になり、注文ができなくなります。
              また、自動会計伝票印刷設定がされているプリンターから会計伝票が印刷されます。
              OFFの場合は、『テーブルでスタッフをお待ちいただく案内』はご利用いただけません。自動会計伝票は印刷されません。"
            />
          }
          name="enableOfflinePayment"
          valuePropName="checked"
          {...props}
        >
          <Switch disabled={accountingActionType === AccountingActionTypeEnum.WaitAtTable} />
        </EditShopFormItem>
      );
    }}
  </EditShopFormItem.NonProperty>
));
