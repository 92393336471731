import React from "react";
import { Button, Select, Switch } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Company, Shop } from "../types";

import {
  CopyMasterDataFormItem,
  CopyMasterDataFormValues,
  useCopyMasterDataForm,
} from "./useCopyMasterDataForm";

type Props = {
  sourceShops: Shop[];
  targetCompanies: Company[];
  targetShops: Shop[];
  canSubmit: boolean;
  onChange: (formValues: CopyMasterDataFormValues) => void;
  onSubmit: (formValues: CopyMasterDataFormValues) => void;
};

export const CopyMasterDataForm = ({
  sourceShops,
  targetCompanies,
  targetShops,
  canSubmit,
  onChange,
  onSubmit,
}: Props) => {
  const {
    form,
    initialValues,
    change: handleChange,
    submit: handleSubmit,
  } = useCopyMasterDataForm(onChange, onSubmit);

  return (
    <>
      <FormContent>
        <Form
          name="copyMasterData"
          form={form}
          initialValues={initialValues}
          layout="vertical"
          onValuesChange={handleChange}
        >
          <CopyMasterDataFormItem
            label="コピー元店舗"
            name="sourceShopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={sourceShops.length === 0}
              showSearch
              options={sourceShops.map(({ name, shopId }) => ({ label: name, value: shopId }))}
              optionFilterProp="label"
            />
          </CopyMasterDataFormItem>
          <CopyMasterDataFormItem
            label="コピー先法人"
            name="targetCompanyId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={targetCompanies.length === 0}
              showSearch
              options={targetCompanies.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              optionFilterProp="label"
            />
          </CopyMasterDataFormItem>
          <CopyMasterDataFormItem
            label="コピー先店舗"
            name="targetShopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={targetShops.length === 0}
              showSearch
              options={targetShops.map(({ name, shopId }) => ({ label: name, value: shopId }))}
              optionFilterProp="label"
            />
          </CopyMasterDataFormItem>
          <CopyMasterDataFormItem
            label="テーブルコピー"
            name="enableCopyTable"
            required
            valuePropName="checked"
          >
            <Switch />
          </CopyMasterDataFormItem>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={handleSubmit} disabled={!canSubmit}>
          コピー
        </Button>
      </FormActions>
    </>
  );
};
