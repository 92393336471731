import React, { memo } from "react";
import { parsePrice } from "util/input/parsePrice";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";
import { CouponTypeEnum } from "types/graphql";

export const NormalPriceField = memo(() => (
  <FormItem name={[CouponTypeEnum.MenuDiscount, "normalPrice"]} label="通常価格">
    <InputNumber
      min={0}
      formatter={(value) => `${value}円`}
      parser={parsePrice}
      placeholder="100円"
      disabled
    />
  </FormItem>
));
