import { useCallback, useMemo, useState } from "react";

export const useSelectedRowKeys = <RK extends string | number>({
  currentPageRowKeys,
}: {
  currentPageRowKeys: RK[];
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<RK[]>([]);

  const currentPageSelectedRowKeys = useMemo(
    () => selectedRowKeys.filter((rowKey) => currentPageRowKeys.includes(rowKey)),
    [selectedRowKeys, currentPageRowKeys],
  );

  const clearCurrentPageSelectedRowKeys = useCallback(() => {
    const filtered = selectedRowKeys.filter(
      (rowKey) => !currentPageSelectedRowKeys.includes(rowKey),
    );
    setSelectedRowKeys(filtered);
  }, [currentPageSelectedRowKeys, selectedRowKeys]);

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    currentPageSelectedRowKeys,
    clearCurrentPageSelectedRowKeys,
  };
};
