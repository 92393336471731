import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import type { Formatter as LegendFormatter } from "recharts/types/component/DefaultLegendContent";

import type { GraphDataKeys } from "./types";

const LegendText = styled.span`
  color: #767676;
  font-size: 8px;
  font-weight: bold;
`;

const formatter = new Intl.NumberFormat("ja");

export const useFormatter = ({ title }: { title: string }) => {
  const dataKeyLegendTextMap: Record<GraphDataKeys, string> = useMemo(
    () => ({
      repeaterRate: "リピーター割合",
      newCustomer: "新規",
      newCustomerViaAmbassador: "バイラル",
      repeater: "リピーター",
      ambassadorRate: "アンバサダー割合",
      newCustomerViaAmbassadorCount: "アンバサダー経由客数",
      faveYellAmount: "1日の推しエール額推移",
      faveYellUserCount: "1日の推しエール人数推移",
      value: title,
    }),
    [title],
  );

  const legendFormatter: LegendFormatter = useCallback(
    (value: GraphDataKeys) => <LegendText>{dataKeyLegendTextMap[value]}</LegendText>,
    [dataKeyLegendTextMap],
  );

  const tooltipFormatter = useCallback(
    (value: number, name: GraphDataKeys) => [formatter.format(value), dataKeyLegendTextMap[name]],
    [dataKeyLegendTextMap],
  );

  return { legendFormatter, tooltipFormatter };
};
