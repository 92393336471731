import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Descriptions } from "antd";
import { red } from "@ant-design/colors";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { EditPlanTecAggregationPlanFormValues } from "./EditPlanTecAggregationPlanForm/useEditPlanTecAggregationPlanForm";
import { EditPlanTecAggregationPlanForm } from "./EditPlanTecAggregationPlanForm";
import {
  EditPlanTecAggregationPlanGetPlanDocument,
  useEditPlanTecAggregationPlanGetMenuCodeQuery,
  useEditPlanTecAggregationPlanGetPlanQuery,
  useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation,
} from "./queries";

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const EditPlanTecAggregationPlan = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const _planId = Number(id);
  const navigate = useNavigate();

  const [errorCodes, setErrorCodes] = useState<
    | {
        name: string;
        menuCode: string;
        dpName?: string | null;
        dpCode?: string | null;
        gpCode?: string | null;
        gpName?: string | null;
      }[]
    | null
  >(null);

  const {
    data: getPlanData,
    loading: loadingPlan,
    error: errorGetPlan,
  } = useEditPlanTecAggregationPlanGetPlanQuery(
    !Number.isNaN(_planId) ? { variables: { planId: _planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];
  const planId = plan?.id;

  const tecAggregationMenu = plan?.tecAggregationMenu;

  const companyId = plan?.companyId;
  setCompany(companyId);

  const {
    data: getMenuCodesData,
    loading: loadingGetMenuCodes,
    error: errorGetMenuCodes,
  } = useEditPlanTecAggregationPlanGetMenuCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updateTecAggregationMenuMutation, { loading: loadingUpdateTecAggregationMenu }] =
    useEditPlanTecAggregationPlanUpdateTecAggregationMenuMutation();

  const onSubmit = useCallback(
    async (input: EditPlanTecAggregationPlanFormValues) => {
      if (!company) return;

      setErrorCodes(null);

      const id = tecAggregationMenu?.id ?? uuidv4();
      const tecAggregationMenuDto: DashboardAccountUpsertTecAggregationMenuInput = {
        id,
        planId,
        _planId,
        companyId: company.id,
        name: input.name,
        menuCode: input.menuCode,
        dpCode: input.dpCode,
        dpName: input.dpName,
        gpCode: input.gpCode,
        gpName: input.gpName,
      };

      const tecAggregationMenusDto: DashboardAccountUpsertTecAggregationMenuInput[] =
        input.plan?.planOptions.flatMap((option) =>
          option.planChoices.flatMap((planChoice) => {
            const id = planChoice.tecAggregationMenu?.id ?? uuidv4();

            const menu: DashboardAccountUpsertTecAggregationMenuInput = {
              id,
              _planChoiceId: planChoice.planChoiceId,
              companyId: company.id,
              name: planChoice.tecAggregationMenu?.name ?? "",
              menuCode: planChoice.tecAggregationMenu?.menuCode ?? "",
              dpCode: planChoice.tecAggregationMenu?.dpCode ?? "",
              dpName: planChoice.tecAggregationMenu?.dpName ?? "",
              gpCode: planChoice.tecAggregationMenu?.gpCode ?? "",
              gpName: planChoice.tecAggregationMenu?.gpName ?? "",
            };

            return menu;
          }),
        ) ?? [];

      try {
        const result = await updateTecAggregationMenuMutation({
          variables: {
            tecAggregationMenu: tecAggregationMenuDto,
            tecAggregationMenus: { menus: tecAggregationMenusDto },
          },
          refetchQueries: [
            {
              query: EditPlanTecAggregationPlanGetPlanDocument,
              variables: { planId: _planId },
            },
          ],
        });

        if (
          result.data?.upsertTecAggregationMenu.__typename ===
          "DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode"
        ) {
          const { name, menuCode, dpCode, dpName, gpCode, gpName } =
            result.data.upsertTecAggregationMenu;

          setErrorCodes((prevErrorCodes) => [
            ...(prevErrorCodes ?? []),
            {
              name,
              menuCode,
              dpCode,
              dpName,
              gpCode,
              gpName,
            },
          ]);
        }

        if (
          result.data?.upsertTecAggregationMenusBulk.__typename ===
          "DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode"
        ) {
          const codes =
            result.data.upsertTecAggregationMenusBulk.dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode.map(
              (v) => v,
            );

          setErrorCodes((prevErrorCodes) => [...(prevErrorCodes ?? []), ...codes]);
        }

        if (
          result.data?.upsertTecAggregationMenu.__typename ===
            "DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode" ||
          result.data?.upsertTecAggregationMenusBulk.__typename ===
            "DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode"
        ) {
          message.error("編集の保存に失敗しました");
          return;
        }

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [_planId, company, planId, tecAggregationMenu?.id, updateTecAggregationMenuMutation],
  );

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getMenuCodesData?.tecAggregationMenu ?? [])
      .map((c) => (!isNaN(Number(c.menuCode)) ? Number(c.menuCode) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getMenuCodesData?.tecAggregationMenu]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingPlan || loadingGetMenuCodes;

  const shouldShowNetworkErrorAlert = Boolean(errorGetPlan || errorGetMenuCodes);
  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "連携プラン" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={shouldShowNetworkErrorAlert}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loading && <Loading height={300} />}
      {errorCodes && (
        <>
          <Alert
            message="以下のコード設定と競合しています"
            description={errorCodes.map((v) => (
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    {
                      label: "メニュー名",
                      children: v.name,
                      contentStyle: { backgroundColor: "white" },
                    },
                    {
                      label: "メニューコード",
                      children: v.menuCode,
                      contentStyle: { backgroundColor: "white" },
                    },
                    v.dpCode
                      ? {
                          label: "部門コード",
                          children: v.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.dpName
                      ? {
                          label: "部門名称",
                          children: v.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.gpCode
                      ? {
                          label: "分類コード",
                          children: v.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    v.gpName
                      ? {
                          label: "分類名称",
                          children: v.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            ))}
            type="error"
          />
          <Spacer size={10} />
        </>
      )}

      {plan && (
        <EditPlanTecAggregationPlanForm
          plan={plan}
          onSubmit={onSubmit}
          onClose={goBack}
          getAvailableMenuCode={getAvailableMenuCode}
          loading={loadingUpdateTecAggregationMenu}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
