import React, { memo } from "react";

import { withFormDependencies } from "components/antd/Form";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

import { NameField } from "./NameField";

type Props = {
  disabled?: boolean | undefined;
};

export const TranslationField = memo<Props>(({ disabled }) => (
  <EditPlanChoiceFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ name }) => [name])}
    noStyle
  >
    <NameField name="name" label="選択肢名：原文" disabled />
    <NameField name="nameEn" label="選択肢名：英語" disabled={disabled} />
    <NameField name="nameCn" label="選択肢名：中国語 (簡体字)" disabled={disabled} />
    <NameField name="nameKr" label="選択肢名：韓国語" disabled={disabled} />
  </EditPlanChoiceFormItem.NonProperty>
));
