type PrinterSoundKey = keyof typeof printerSoundTypes;

export const printerSoundTypes = {
  0: "なし",
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
} as const;

export const isPrinterType = (
  printerSoundTypeKey: number,
): printerSoundTypeKey is PrinterSoundKey => printerSoundTypeKey in printerSoundTypes;

export const getTypeName = (printerSoundTypeKey: number) =>
  isPrinterType(printerSoundTypeKey)
    ? printerSoundTypes[printerSoundTypeKey]
    : printerSoundTypes[0];
