import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

export type WinboardUploadFormValues = {
  date?: dayjs.Dayjs;
};

export const WinboardUploadFormItem = createFormItem<WinboardUploadFormValues>();

export const useWinboardUploadForm = () => {
  const [form] = Form.useForm();

  const getValues = useCallback(() => {
    const values = form.getFieldsValue() as WinboardUploadFormValues;
    return values;
  }, [form]);

  return { form, getValues };
};
