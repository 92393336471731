import "dayjs/locale/ja";

import dayjs from "dayjs";

import { useCompany } from "hooks/useCompany";

import { useErrorMessageDeliveryJobGetMessageDeliveryJobsQuery } from "./queries";

dayjs.locale("ja");

const today = dayjs();

const errorMessageDeliveryShopJobKey = "errorMessageDeliveryShopJobKey";

type ErrorMessageDeliveryShopJob = {
  id: string;
  executedAt: string;
  isChecked: boolean;
};

const getErrorMessageDeliveryShopJobs = (): ErrorMessageDeliveryShopJob[] =>
  JSON.parse(localStorage.getItem(errorMessageDeliveryShopJobKey) ?? "[]");

const getHasUncheckedErrorMessageDeliveryShopJob = () =>
  getErrorMessageDeliveryShopJobs().some(({ isChecked }) => !isChecked);

const checkErrorMessageDeliveryShopJob = ({
  errorMessageDeliveryShopJobIds,
}: {
  errorMessageDeliveryShopJobIds: string[];
}) => {
  const newErrorMessageDeliveryShopJobs = getErrorMessageDeliveryShopJobs().map((existingJob) => {
    if (!errorMessageDeliveryShopJobIds.includes(existingJob.id)) return existingJob;

    return { ...existingJob, isChecked: true };
  });

  localStorage.setItem(
    errorMessageDeliveryShopJobKey,
    JSON.stringify(newErrorMessageDeliveryShopJobs),
  );
};

export const useErrorMessageDeliveryJob = () => {
  const [company] = useCompany();
  const companyId = company?.id;
  const oneWeekAgo = today.subtract(1, "week");

  useErrorMessageDeliveryJobGetMessageDeliveryJobsQuery(
    companyId
      ? {
          variables: { companyId, executedAt: oneWeekAgo.toISOString() },
          onCompleted(data) {
            const newErrorMessageDeliveryJob = data.messageDeliveryJob.flatMap(
              ({ messageDeliveryShopJobs }) =>
                messageDeliveryShopJobs.map((messageDeliveryShopJob) => {
                  const existingErrorMessageDeliveryShopJob =
                    getErrorMessageDeliveryShopJobs().find(
                      ({ id }) => id === messageDeliveryShopJob.id,
                    );
                  // NOTE: 取得した messageDeliveryJob が LocalStorage に存在する場合は、エラーがチェックしたかの状態を引き継ぐ
                  if (existingErrorMessageDeliveryShopJob) {
                    return existingErrorMessageDeliveryShopJob;
                  }

                  return {
                    id: messageDeliveryShopJob.id,
                    executedAt: messageDeliveryShopJob.executedAt,
                    isChecked: false,
                  };
                }),
            );

            // NOTE: LocalStorage には、１週間前までのデータのみ保存する
            localStorage.setItem(
              errorMessageDeliveryShopJobKey,
              JSON.stringify(newErrorMessageDeliveryJob),
            );
          },
        }
      : { skip: true },
  );

  return { getHasUncheckedErrorMessageDeliveryShopJob, checkErrorMessageDeliveryShopJob };
};
