import React, { useCallback } from "react";
import styled from "styled-components";
import { Alert, Button, Card, DatePicker } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";

import { useFoodingJournalUploadGetShopsQuery, useFoodingJournalUploadMutation } from "./queries";
import { useFoodingJournalGetFileToUpload } from "./useFoodingJournalGetZipToUpload";
import { useFoodingJournalUploadForm } from "./useTecAggregationUploadForm";

const FormContainer = styled.div`
  margin: 48px 0px;
`;

export const FoodingJournalUpload = () => {
  const { form, getValues } = useFoodingJournalUploadForm();

  const [currentCompany] = useCompany();
  const [currentShop] = useShop();

  const { data: shopsData, error } = useFoodingJournalUploadGetShopsQuery(
    currentCompany ? { variables: { companyId: currentCompany.id } } : { skip: true },
  );

  const [foodingJournalUpload, { loading: loadingFoodingJournalUpload }] =
    useFoodingJournalUploadMutation();

  const shop = shopsData?.shops.find((s) => s.shopId === currentShop?.shopId);

  const { loading: loadingGetFileToUpload, submit: getFileToUpload } =
    useFoodingJournalGetFileToUpload();

  const loading = loadingGetFileToUpload || loadingFoodingJournalUpload;

  const onClickUpload = useCallback(async () => {
    if (!shop) {
      return message.error("アップロードに失敗しました");
    }

    try {
      await form.validateFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const date = getValues().date!.format("YYYY-MM-DD");

      await foodingJournalUpload({ variables: { shopId: shop.shopId, targetDate: date } });

      message.success("アップロードが完了しました");
    } catch (e) {
      message.error("アップロードに失敗しました");
    }
  }, [foodingJournalUpload, form, getValues, shop]);

  const onClickDownload = useCallback(async () => {
    try {
      await form.validateFields();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const date = getValues().date!.toDate();

      await getFileToUpload({ date });
    } catch (e) {
      message.error("ダウンロードに失敗しました");
    }
  }, [form, getValues, getFileToUpload]);

  return (
    <DashboardLayout title="Fooding Journal連携">
      <PageHeader title="連携処理" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Card>
        {shop && !shop?.foodingJournalConfig?.enabled && (
          <Paragraph>Fooding Journal連携が有効になっていない店舗です。</Paragraph>
        )}

        {shop?.foodingJournalConfig?.enabled && (
          <>
            <Paragraph>集計データをFooding Journalに転送します。</Paragraph>

            <FormContainer>
              <Form name="foodingJournalUpload" form={form} layout="vertical">
                <FormItem label="対象日" name="date" rules={[{ required: true, type: "object" }]}>
                  <DatePicker />
                </FormItem>
              </Form>
            </FormContainer>

            <FormActions>
              <Button
                type="link"
                loading={loadingGetFileToUpload}
                onClick={onClickDownload}
                disabled={loading}
              >
                JSONファイルをダウンロード
              </Button>

              <Button
                type="primary"
                loading={loadingFoodingJournalUpload}
                onClick={onClickUpload}
                disabled={loading}
              >
                転送
              </Button>
            </FormActions>
          </>
        )}
      </Card>
    </DashboardLayout>
  );
};
