import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { Shop } from "types/graphql";

type FilterConditions = {
  name?: string;
};

export const filterShop = <T extends Pick<Shop, "shopId" | "name">>(
  shops: T[],
  { name }: FilterConditions,
) => {
  if (name) {
    const katakanaName = hiraganaToKatakana(name);

    const shopFuse = new Fuse(
      shops.map(({ shopId, name }) => ({ shopId, name: hiraganaToKatakana(name) })),
      { keys: ["name"], useExtendedSearch: true },
    );
    const shopIds = shopFuse.search(`'${katakanaName}`).map(({ item }) => item.shopId);

    return shops
      .map((shop) => {
        if (shopIds.includes(shop.shopId)) return shop;
        return null;
      })
      .filter(isNotNullable);
  }

  return shops;
};
