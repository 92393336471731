import React, { memo, useCallback } from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";

import { EditTaxMethodForm } from "./EditTaxMethodForm";
import {
  useEditDefaultTaxMethodsGetCompanyQuery,
  useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation,
} from "./queries";
import type { FormValues } from "./types";

export const EditDefaultTaxMethods = memo(() => {
  const [currentCompany] = useCompany();
  const companyId = currentCompany?.id;

  const {
    data: getCompanyData,
    loading,
    refetch: refetchCompany,
    error,
  } = useEditDefaultTaxMethodsGetCompanyQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const company = getCompanyData?.company?.[0];

  const [updateTaxMethodMutation, { loading: loadingUpdateTaxMethodMutation }] =
    useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation();

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!companyId) throw new Error("no companyId");

      await updateTaxMethodMutation({
        variables: {
          companyId,
          defaultMenuTaxMethod: formValues.defaultMenuTaxMethod,
          defaultCostTaxMethod: formValues.defaultCostTaxMethod,
        },
      });
      await refetchCompany();
    },
    [companyId, updateTaxMethodMutation, refetchCompany],
  );

  return (
    <DashboardLayout title="税種別設定">
      <PageHeader title="税種別設定" />
      {!company && loading && <Loading height={300} />}

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {company && (
        <EditTaxMethodForm
          loading={loadingUpdateTaxMethodMutation}
          company={company}
          onSubmit={onSubmit}
        />
      )}
    </DashboardLayout>
  );
});
