import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const RankingViralCountsGetCustomerViralCountRanking = gql`
    query RankingViralCountsGetCustomerViralCountRanking($input: QueryCustomerRankingInput!) {
  customerViralCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;
export type RankingViralCountsGetCustomerViralCountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type RankingViralCountsGetCustomerViralCountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerViralCountRanking: (
    { __typename?: 'CustomerRanking' }
    & Pick<Types.CustomerRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerRankingCustomer' }
      & Pick<Types.CustomerRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastVisitedAt'>
    )> }
  ) }
);


export const RankingViralCountsGetCustomerViralCountRankingDocument = gql`
    query RankingViralCountsGetCustomerViralCountRanking($input: QueryCustomerRankingInput!) {
  customerViralCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;

/**
 * __useRankingViralCountsGetCustomerViralCountRankingQuery__
 *
 * To run a query within a React component, call `useRankingViralCountsGetCustomerViralCountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankingViralCountsGetCustomerViralCountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankingViralCountsGetCustomerViralCountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRankingViralCountsGetCustomerViralCountRankingQuery(baseOptions: Apollo.QueryHookOptions<RankingViralCountsGetCustomerViralCountRankingQuery, RankingViralCountsGetCustomerViralCountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RankingViralCountsGetCustomerViralCountRankingQuery, RankingViralCountsGetCustomerViralCountRankingQueryVariables>(RankingViralCountsGetCustomerViralCountRankingDocument, options);
      }
export function useRankingViralCountsGetCustomerViralCountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RankingViralCountsGetCustomerViralCountRankingQuery, RankingViralCountsGetCustomerViralCountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RankingViralCountsGetCustomerViralCountRankingQuery, RankingViralCountsGetCustomerViralCountRankingQueryVariables>(RankingViralCountsGetCustomerViralCountRankingDocument, options);
        }
export type RankingViralCountsGetCustomerViralCountRankingQueryHookResult = ReturnType<typeof useRankingViralCountsGetCustomerViralCountRankingQuery>;
export type RankingViralCountsGetCustomerViralCountRankingLazyQueryHookResult = ReturnType<typeof useRankingViralCountsGetCustomerViralCountRankingLazyQuery>;
export type RankingViralCountsGetCustomerViralCountRankingQueryResult = Apollo.QueryResult<RankingViralCountsGetCustomerViralCountRankingQuery, RankingViralCountsGetCustomerViralCountRankingQueryVariables>;