import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { DashboardFeatureEnum } from "types/graphql";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const PrintingOptionMenu = memo<Props>(({ company, ...props }) => {
  const canShowKitchenDisplayMenuItem = company?.shops.some(({ useKd }) => useKd) ?? false;

  return (
    <SubMenu
      key="printing"
      title="印刷オプション設定"
      features={[DashboardFeatureEnum.ShopManagement]}
      {...props}
    >
      <MenuItem route="planMainOptionPrintings" to="/printing/mainOption" text="プランオプション" />
      <MenuItem route="dishUpSlipGroups" to="/dishUpSlipGroup" text="デシャップグループ" />
      <MenuItem route="cookingItems" to="/cookingItem" text="調理アイテム" />
      <MenuItem
        route="kitchenDisplayDisplayTargets"
        to="/kitchenDisplayDisplayTarget"
        text="キッチンディスプレイ"
        canShow={canShowKitchenDisplayMenuItem}
      />
    </SubMenu>
  );
});
