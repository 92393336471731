import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditGiftGetMenu = gql`
    query EditGiftGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    serial
    imageUrl
    name
    receiptDisplayName
    description
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        name
        shopId
      }
    }
  }
}
    `;
export const EditGiftUpdateMenu = gql`
    mutation EditGiftUpdateMenu($menuId: Int!, $menu: menu_set_input!) {
  update_menu(_set: $menu, where: {menuId: {_eq: $menuId}}) {
    affected_rows
  }
}
    `;
export type EditGiftGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditGiftGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'serial' | 'imageUrl' | 'name' | 'receiptDisplayName' | 'description'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name' | 'shopId'>
      ) }
    )> }
  )> }
);

export type EditGiftUpdateMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  menu: Types.MenuSetInput;
}>;


export type EditGiftUpdateMenuMutation = (
  { __typename?: 'mutation_root' }
  & { update_menu?: Types.Maybe<(
    { __typename?: 'menu_mutation_response' }
    & Pick<Types.MenuMutationResponse, 'affected_rows'>
  )> }
);


export const EditGiftGetMenuDocument = gql`
    query EditGiftGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    serial
    imageUrl
    name
    receiptDisplayName
    description
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        name
        shopId
      }
    }
  }
}
    `;

/**
 * __useEditGiftGetMenuQuery__
 *
 * To run a query within a React component, call `useEditGiftGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGiftGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGiftGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditGiftGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditGiftGetMenuQuery, EditGiftGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGiftGetMenuQuery, EditGiftGetMenuQueryVariables>(EditGiftGetMenuDocument, options);
      }
export function useEditGiftGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGiftGetMenuQuery, EditGiftGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGiftGetMenuQuery, EditGiftGetMenuQueryVariables>(EditGiftGetMenuDocument, options);
        }
export type EditGiftGetMenuQueryHookResult = ReturnType<typeof useEditGiftGetMenuQuery>;
export type EditGiftGetMenuLazyQueryHookResult = ReturnType<typeof useEditGiftGetMenuLazyQuery>;
export type EditGiftGetMenuQueryResult = Apollo.QueryResult<EditGiftGetMenuQuery, EditGiftGetMenuQueryVariables>;
export const EditGiftUpdateMenuDocument = gql`
    mutation EditGiftUpdateMenu($menuId: Int!, $menu: menu_set_input!) {
  update_menu(_set: $menu, where: {menuId: {_eq: $menuId}}) {
    affected_rows
  }
}
    `;
export type EditGiftUpdateMenuMutationFn = Apollo.MutationFunction<EditGiftUpdateMenuMutation, EditGiftUpdateMenuMutationVariables>;

/**
 * __useEditGiftUpdateMenuMutation__
 *
 * To run a mutation, you first call `useEditGiftUpdateMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGiftUpdateMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGiftUpdateMenuMutation, { data, loading, error }] = useEditGiftUpdateMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useEditGiftUpdateMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditGiftUpdateMenuMutation, EditGiftUpdateMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGiftUpdateMenuMutation, EditGiftUpdateMenuMutationVariables>(EditGiftUpdateMenuDocument, options);
      }
export type EditGiftUpdateMenuMutationHookResult = ReturnType<typeof useEditGiftUpdateMenuMutation>;
export type EditGiftUpdateMenuMutationResult = Apollo.MutationResult<EditGiftUpdateMenuMutation>;
export type EditGiftUpdateMenuMutationOptions = Apollo.BaseMutationOptions<EditGiftUpdateMenuMutation, EditGiftUpdateMenuMutationVariables>;