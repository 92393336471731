import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useShop } from "hooks/useShop";
import { TableArea, TableAreaInsertInput } from "types/graphql";

export type AddTableAreaFormValues = Pick<TableArea, "name">;

const getInitialValues = () => ({});

export const AddTableAreaFormItem = createFormItem<AddTableAreaFormValues>();

export const useAddTableAreaForm = (
  onSubmit: (input: Omit<TableAreaInsertInput, "priority">) => Promise<void>,
) => {
  const [shop] = useShop();

  const shopId = shop?.shopId;

  const [form] = Form.useForm<AddTableAreaFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(async () => {
    if (!shopId) return;

    await form.validateFields();

    const { name } = form.getFieldsValue();

    await onSubmit({
      name,
      shopId,
    });
  }, [form, onSubmit, shopId]);

  return { form, initialValues, submit };
};
