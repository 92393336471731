import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddMenuRecommendationMenuModalGetMenus = gql`
    query AddMenuRecommendationMenuModalGetMenus($companyId: uuid!, $categoryId: Int!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {menuId: asc}
  ) {
    id
    menuId
    name
    imageUrl
    price
    openPrice
    taxMethod
    menuType
    categoryMenus(
      where: {_categoryId: {_neq: $categoryId}, category: {archivedAt: {_is_null: true}}}
    ) {
      category {
        id
        categoryId
        name
      }
    }
  }
}
    `;
export const AddMenuRecommendationMenuModalInsertMenus = gql`
    mutation AddMenuRecommendationMenuModalInsertMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(objects: $categoryMenus) {
    affected_rows
  }
}
    `;
export type AddMenuRecommendationMenuModalGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  categoryId: Types.Scalars['Int'];
}>;


export type AddMenuRecommendationMenuModalGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'name' | 'imageUrl' | 'price' | 'openPrice' | 'taxMethod' | 'menuType'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'id' | 'categoryId' | 'name'>
      ) }
    )> }
  )> }
);

export type AddMenuRecommendationMenuModalInsertMenusMutationVariables = Types.Exact<{
  categoryMenus: Array<Types.CategoryMenuInsertInput> | Types.CategoryMenuInsertInput;
}>;


export type AddMenuRecommendationMenuModalInsertMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
  )> }
);


export const AddMenuRecommendationMenuModalGetMenusDocument = gql`
    query AddMenuRecommendationMenuModalGetMenus($companyId: uuid!, $categoryId: Int!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {menuId: asc}
  ) {
    id
    menuId
    name
    imageUrl
    price
    openPrice
    taxMethod
    menuType
    categoryMenus(
      where: {_categoryId: {_neq: $categoryId}, category: {archivedAt: {_is_null: true}}}
    ) {
      category {
        id
        categoryId
        name
      }
    }
  }
}
    `;

/**
 * __useAddMenuRecommendationMenuModalGetMenusQuery__
 *
 * To run a query within a React component, call `useAddMenuRecommendationMenuModalGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMenuRecommendationMenuModalGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMenuRecommendationMenuModalGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useAddMenuRecommendationMenuModalGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddMenuRecommendationMenuModalGetMenusQuery, AddMenuRecommendationMenuModalGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMenuRecommendationMenuModalGetMenusQuery, AddMenuRecommendationMenuModalGetMenusQueryVariables>(AddMenuRecommendationMenuModalGetMenusDocument, options);
      }
export function useAddMenuRecommendationMenuModalGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMenuRecommendationMenuModalGetMenusQuery, AddMenuRecommendationMenuModalGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMenuRecommendationMenuModalGetMenusQuery, AddMenuRecommendationMenuModalGetMenusQueryVariables>(AddMenuRecommendationMenuModalGetMenusDocument, options);
        }
export type AddMenuRecommendationMenuModalGetMenusQueryHookResult = ReturnType<typeof useAddMenuRecommendationMenuModalGetMenusQuery>;
export type AddMenuRecommendationMenuModalGetMenusLazyQueryHookResult = ReturnType<typeof useAddMenuRecommendationMenuModalGetMenusLazyQuery>;
export type AddMenuRecommendationMenuModalGetMenusQueryResult = Apollo.QueryResult<AddMenuRecommendationMenuModalGetMenusQuery, AddMenuRecommendationMenuModalGetMenusQueryVariables>;
export const AddMenuRecommendationMenuModalInsertMenusDocument = gql`
    mutation AddMenuRecommendationMenuModalInsertMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(objects: $categoryMenus) {
    affected_rows
  }
}
    `;
export type AddMenuRecommendationMenuModalInsertMenusMutationFn = Apollo.MutationFunction<AddMenuRecommendationMenuModalInsertMenusMutation, AddMenuRecommendationMenuModalInsertMenusMutationVariables>;

/**
 * __useAddMenuRecommendationMenuModalInsertMenusMutation__
 *
 * To run a mutation, you first call `useAddMenuRecommendationMenuModalInsertMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuRecommendationMenuModalInsertMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuRecommendationMenuModalInsertMenusMutation, { data, loading, error }] = useAddMenuRecommendationMenuModalInsertMenusMutation({
 *   variables: {
 *      categoryMenus: // value for 'categoryMenus'
 *   },
 * });
 */
export function useAddMenuRecommendationMenuModalInsertMenusMutation(baseOptions?: Apollo.MutationHookOptions<AddMenuRecommendationMenuModalInsertMenusMutation, AddMenuRecommendationMenuModalInsertMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMenuRecommendationMenuModalInsertMenusMutation, AddMenuRecommendationMenuModalInsertMenusMutationVariables>(AddMenuRecommendationMenuModalInsertMenusDocument, options);
      }
export type AddMenuRecommendationMenuModalInsertMenusMutationHookResult = ReturnType<typeof useAddMenuRecommendationMenuModalInsertMenusMutation>;
export type AddMenuRecommendationMenuModalInsertMenusMutationResult = Apollo.MutationResult<AddMenuRecommendationMenuModalInsertMenusMutation>;
export type AddMenuRecommendationMenuModalInsertMenusMutationOptions = Apollo.BaseMutationOptions<AddMenuRecommendationMenuModalInsertMenusMutation, AddMenuRecommendationMenuModalInsertMenusMutationVariables>;