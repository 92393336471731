import React, { memo, useCallback } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { updatePriorities } from "util/priority";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { SortableTable } from "components/SortableTable";
import { usePagination } from "hooks/usePagination";
import { CategoryMenu } from "pages/PlanFirstOrderCategoryMenus/types";

type Props = {
  loading?: boolean;
  menus: CategoryMenu[];
  onRemove: (menuId: number) => void;
  onChangeOrder: (args: CategoryMenu[]) => void;
  canEditPlan: boolean;
};

export const MenuTable = memo<Props>(({ loading, menus, onRemove, onChangeOrder, canEditPlan }) => {
  const [pagination, setPagination] = usePagination();

  const handleMoveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        onChangeOrder(
          updatePriorities(
            menus.map((menu, index) => ({ ...menu, priority: index })),
            dragIndex,
            hoverIndex,
          ),
        );
      }
    },
    [menus, onChangeOrder],
  );

  const columns = [
    {
      title: "",
      width: 50,
      render() {
        return <MenuOutlined />;
      },
    },
    {
      title: "カテゴリ名",
      render(_: string, { category }: CategoryMenu) {
        return category.name;
      },
    },
    {
      title: "商品名",
      render(_: string, { menu }: CategoryMenu) {
        return menu.name;
      },
    },
    {
      title: "",
      render(_: string, { menu }: CategoryMenu) {
        return (
          <IconLink to={`/menu/${menu.menuId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    },
    canEditPlan
      ? ({
          title: "",
          align: "center",
          width: 60,
          render(_: string, { categoryMenuId }: CategoryMenu) {
            return <DeleteIcon onClick={() => onRemove(categoryMenuId)} />;
          },
        } as const)
      : null,
  ].filter(isNotNull);

  return (
    <SortableTable
      type="planFirstOrderCategoryMenus"
      rowKey="categoryMenuId"
      columns={columns}
      dataSource={menus}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      onMove={canEditPlan ? handleMoveItem : null}
    />
  );
});
