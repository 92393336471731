import { SupportedLocaleEnum, SupportedLocaleEnumType } from "types/graphql";

export const localesEnumConversionMap: {
  [key in SupportedLocaleEnum]: SupportedLocaleEnumType;
} = {
  [SupportedLocaleEnum.EnUs]: SupportedLocaleEnumType.EnUs,
  [SupportedLocaleEnum.KoKr]: SupportedLocaleEnumType.KoKr,
  [SupportedLocaleEnum.ZhCn]: SupportedLocaleEnumType.ZhCn,
};

export const locales = [
  {
    code: SupportedLocaleEnumType.EnUs,
    label: "英語",
  },
  {
    code: SupportedLocaleEnumType.ZhCn,
    label: "中国語 (簡体字)",
  },
  {
    code: SupportedLocaleEnumType.KoKr,
    label: "韓国語",
  },
];

// 多言語対応のフォームは FormList を使っており、渡した配列の順序で表示されるため、fetch したデータを form の順序と合わせるために locale でソートする
export const sortByLocale = (
  a: { locale: SupportedLocaleEnumType },
  b: { locale: SupportedLocaleEnumType },
) => {
  const aIndex = locales.findIndex((locale) => locale.code === a.locale);
  const bIndex = locales.findIndex((locale) => locale.code === b.locale);
  return aIndex - bIndex;
};
