import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery } from "./queries";
import {
  OnSitePaymentDetailTypeWithTecAggregationDetailType,
  TecAggregationDetailTypeTable,
} from "./TecAggregationDetailTypeTable";

export const TecAggregationOnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getDetailTypesData,
    loading: loadingGetDetailTypes,
    error: getDetailTypesError,
  } = useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const detailTypes: OnSitePaymentDetailTypeWithTecAggregationDetailType[] =
    getDetailTypesData?.onSitePaymentDetailType?.map((onSitePaymentDetailType) => ({
      ...onSitePaymentDetailType,
      tecAggregationOnSitePaymentDetailType:
        // NOTE: ある支払方法に紐づく tecAggregationOnSitePaymentDetailType は必ず一つのはず
        onSitePaymentDetailType.tecAggregationOnSitePaymentDetailTypes[0],
    })) ?? [];

  const shouldShowAlert = getDetailTypesError;
  const loading = loadingGetDetailTypes;

  return (
    <DashboardLayout title="支払方法一覧">
      <PageHeader title="支払方法一覧" />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <TecAggregationDetailTypeTable loading={loading} onSitePaymentDetailTypes={detailTypes} />
    </DashboardLayout>
  );
};
