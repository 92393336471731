import React, { memo } from "react";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";

import { BulkUpdateAppetizerSetting } from "./BulkUpdateAppetizerSetting";
import { CompulsoryAppetizerSelectForm } from "./CompulsoryAppetizerSelectForm";

export const MenuAutoCompulsoryAppetizer = memo(() => {
  const [company] = useCompany();
  return (
    <DashboardLayout title="お通し自動設定">
      <PageHeader title="お通し自動設定" />
      <CompulsoryAppetizerSelectForm company={company ?? null} />
      <Spacer size={16} />
      <BulkUpdateAppetizerSetting company={company ?? null} />
    </DashboardLayout>
  );
});
