import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { MembershipRankConfig } from "../types";

export type EditMembershipRankConfigFormValues = Omit<MembershipRankConfig, "id">;

export const EditMembershipRankConfigFormItem =
  createFormItem<EditMembershipRankConfigFormValues>();

export const useEditMembershipRankConfigForm = (
  rankConfig: MembershipRankConfig,
  onSubmit: ({ input }: { input: EditMembershipRankConfigFormValues }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = rankConfig;

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue() as EditMembershipRankConfigFormValues;
    onSubmit({
      input: { ...formValues, rewardCouponId: formValues.rewardCouponId ?? null },
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
