import React, { memo, useMemo } from "react";
import { Select } from "antd";

import { useCompany } from "hooks/useCompany";

import { useLineOfficialAccountsQuery } from "./queries";

type Props = {
  onSelectLineOfficialAccount: (lineOfficialAccountId: number) => void;
};

export const LineOfficialAccountSelector = memo<Props>(({ onSelectLineOfficialAccount }) => {
  const [company] = useCompany();

  const { data, loading } = useLineOfficialAccountsQuery(
    company
      ? {
          variables: { companyId: company.id },
        }
      : { skip: true },
  );

  const lineOfficialAccounts = useMemo(
    () => data?.lineOfficialAccounts ?? [],
    [data?.lineOfficialAccounts],
  );

  return (
    <div>
      <Select
        showSearch
        placeholder="LINE アカウントを選択"
        dropdownMatchSelectWidth={false}
        loading={loading}
        onChange={onSelectLineOfficialAccount}
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        options={lineOfficialAccounts.map((account) => ({
          value: account.lineOfficialAccountId,
          label: account.channelName,
        }))}
      />
    </div>
  );
});
