import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, DatePicker, Divider, Radio, Select, SelectProps, Tag, Tooltip } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { ClearOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";
import { readonlyMenuTypes } from "models/menuType";
import { RangeValue } from "rc-picker/lib/interface";

import { Spacer } from "components/Spacer";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { emptyArrayIfUnselected } from "hooks/useMenuOrderItemAnalytics/util";
import { useRangePresets } from "hooks/useRangePresets";

import { useGetBusinessOperationHourTypesByShopIdsQuery } from "../queries";
import { RepeaterTableType, ReportByType } from "../types";

const MAX_SELECTABLE_DAYS = 366;

const fieldSpacingAmount = 12;

const StyledSelect = styled(Select<string>)`
  width: 275px;
`;
const EntityMultiSelect = styled(Select<string[]>)`
  width: 275px;
  flex-grow: 1;
  max-width: 400px;
`;

const StyledDatePicker = styled(DatePicker.RangePicker)``;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const FiltersRow = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const BusinessOperationHourTypeTips = styled.div`
  padding: 10px;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
`;

const menuTypeOptions = Object.keys(readonlyMenuTypes).map((key) => ({
  value: key,
  label: readonlyMenuTypes[key],
}));
const repeaterTableTypeOptions = [
  {
    value: RepeaterTableType.repeaterTables,
    label: "リピーター",
  },
  {
    value: RepeaterTableType.nonRepeaterTables,
    label: "新規",
  },
];

type TagRender = SelectProps["tagRender"];

export type FilterConditions = {
  categoryIds: string[] | "unselected";
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  shopIds: string[] | "unselected";
  menuTypes: string[] | "unselected";
  businessOperationHourTypes: string[] | "unselected";
  reportByType: ReportByType;
  repeaterTableType: RepeaterTableType | "unselected";
};

export type SerializedFilterConditions = {
  categoryIds: string[] | null;
  range?: [number | null, number | null] | undefined;
  shopIds: string[] | null;
  menuTypes: string[] | null;
  businessOperationHourTypes: string[] | null;
  reportByType: ReportByType;
  repeaterTableType: RepeaterTableType | null;
} & Omit<FilterConditions, "range">;

type Props = {
  filterConditions: FilterConditions;
  shops: { name: string; shopId: string }[];
  categories: { name: string; id: string }[];
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  onCsvExport: () => void;
};

export const Filters = memo<Props>(
  ({ filterConditions, shops, categories, onChangeFilterCondition, onCsvExport }) => {
    const [selectedShopIds, setSelectedShopIds] = useState<string[] | "unselected">(
      filterConditions.shopIds ?? "unselected",
    );

    const shopIds = useMemo(
      () =>
        selectedShopIds !== "unselected" && selectedShopIds.length > 0
          ? selectedShopIds
          : shops.map(({ shopId }) => shopId),
      [selectedShopIds, shops],
    );

    const { data: businessOperationHourTypesData } = useGetBusinessOperationHourTypesByShopIdsQuery(
      shopIds.length === 0
        ? { skip: true }
        : {
            variables: {
              shopIds,
            },
          },
    );

    const businessOperationHourTypes = useMemo(
      () =>
        uniq(
          (businessOperationHourTypesData?.shopBusinessOperationHour ?? []).map(
            ({ businessOperationHourType }) => businessOperationHourType,
          ),
        ),
      [businessOperationHourTypesData],
    );

    const businessOperationHourTypeOptions = useMemo(
      () =>
        businessOperationHourTypes.map((key) => ({
          value: key,
          label: businessOperationHourTypeToWord[key],
        })),
      [businessOperationHourTypes],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const startAt = values?.[0]?.startOf("day") ?? null;
        const endAt = values?.[1]?.endOf("day") ?? null;

        onChangeFilterCondition({ range: [startAt, endAt] });
      },
      [onChangeFilterCondition],
    );

    // NOTE: API のパフォーマンス観点から選択可能な期間を最大一年間とする
    const disabledDate = useCallback(
      (current: dayjs.Dayjs | null) => {
        const startAt = filterConditions.range?.[0] ?? null;
        const endAt = filterConditions.range?.[1] ?? null;

        if (startAt === null && endAt === null) return false;

        const isTooLate = Boolean(
          current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
        );
        const isTooEarly = Boolean(
          current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
        );

        return isTooLate || isTooEarly;
      },
      [filterConditions.range],
    );

    const handleClearFilters = useCallback(() => {
      setSelectedShopIds("unselected");

      onChangeFilterCondition({
        shopIds: "unselected",
        categoryIds: "unselected",
        menuTypes: "unselected",
        businessOperationHourTypes: "unselected",
        repeaterTableType: RepeaterTableType.allTables,
      });
    }, [onChangeFilterCondition]);

    const updateShopIdsFilterCondition = useCallback(
      (newShopIds: string[] | "unselected") => onChangeFilterCondition({ shopIds: newShopIds }),
      [onChangeFilterCondition],
    );

    const { rangePresets } = useRangePresets();

    const shopIdsTagRender: TagRender = useMemo(
      () => (props) =>
        (
          <Tag
            {...props}
            onClose={() => {
              props.onClose();
              updateShopIdsFilterCondition("unselected");
            }}
          >
            {props.label}
          </Tag>
        ),
      [updateShopIdsFilterCondition],
    );

    return (
      <>
        <FiltersRow>
          <EntityMultiSelect
            mode="multiple"
            showSearch
            allowClear
            placeholder="店舗"
            value={selectedShopIds === "unselected" ? [] : selectedShopIds}
            onChange={(shopIds) => setSelectedShopIds(shopIds)}
            onBlur={() => updateShopIdsFilterCondition(selectedShopIds)}
            onClear={() => {
              setSelectedShopIds("unselected");
              updateShopIdsFilterCondition("unselected");
            }}
            optionFilterProp="label"
            options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
            tagRender={shopIdsTagRender}
            maxTagCount="responsive"
          />
          <Spacer size={fieldSpacingAmount} inline />
          <StyledDatePicker
            value={filterConditions.range}
            ranges={rangePresets}
            onCalendarChange={handleChangeRange}
            clearIcon={false}
            disabledDate={disabledDate}
            renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
          />
          <Spacer size={fieldSpacingAmount} inline />
          <EntityMultiSelect
            mode="multiple"
            showSearch
            allowClear
            placeholder="時間帯"
            value={
              filterConditions.businessOperationHourTypes === "unselected"
                ? []
                : filterConditions.businessOperationHourTypes
            }
            onChange={(businessOperationHourTypes) =>
              onChangeFilterCondition({ businessOperationHourTypes })
            }
            optionFilterProp="label"
            options={businessOperationHourTypeOptions}
            maxTagCount="responsive"
            dropdownRender={(options) => (
              <>
                {options}
                <Spacer size={10} />
                <StyledDivider />
                <BusinessOperationHourTypeTips>
                  時間帯について{" "}
                  <Tooltip
                    placement="top"
                    title="店舗管理 > 店舗一覧 > 店舗詳細 > 営業時間帯 に設定されている時間帯が表示されます"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </BusinessOperationHourTypeTips>
              </>
            )}
          />
        </FiltersRow>

        <Spacer size={fieldSpacingAmount} />

        <FiltersRow>
          <EntityMultiSelect
            mode="multiple"
            allowClear
            placeholder="メニュータイプ"
            value={emptyArrayIfUnselected(filterConditions.menuTypes)}
            onChange={(menuTypes) => onChangeFilterCondition({ menuTypes })}
            optionFilterProp="label"
            options={menuTypeOptions}
            maxTagCount="responsive"
          />
          <Spacer size={fieldSpacingAmount} inline />
          <EntityMultiSelect
            mode="multiple"
            showSearch
            allowClear
            placeholder="カテゴリ"
            value={emptyArrayIfUnselected(filterConditions.categoryIds)}
            onChange={(categoryIds) => onChangeFilterCondition({ categoryIds })}
            optionFilterProp="label"
            options={categories.map(({ id, name }) => ({ label: name, value: id }))}
            maxTagCount="responsive"
          />
          <Spacer size={fieldSpacingAmount} inline />
          <StyledSelect
            placeholder="来店経験"
            value={
              filterConditions.repeaterTableType === RepeaterTableType.allTables
                ? null
                : filterConditions.repeaterTableType
            }
            onChange={(repeaterTableType) =>
              onChangeFilterCondition({
                repeaterTableType: repeaterTableType
                  ? (repeaterTableType as RepeaterTableType)
                  : RepeaterTableType.allTables,
              })
            }
            optionFilterProp="label"
            options={repeaterTableTypeOptions}
            allowClear
          />
          <Spacer size={fieldSpacingAmount} inline />
          <Button shape="circle" icon={<ClearOutlined />} onClick={handleClearFilters} />
        </FiltersRow>
        <Spacer size={fieldSpacingAmount} />

        <ActionRow>
          <Radio.Group
            onChange={(e: RadioChangeEvent) =>
              onChangeFilterCondition({ reportByType: e.target.value })
            }
            defaultValue={filterConditions.reportByType}
          >
            <Radio.Button value={ReportByType.menuType}>メニュータイプ別</Radio.Button>
            <Radio.Button value={ReportByType.category}>カテゴリ別</Radio.Button>
            <Radio.Button value={ReportByType.menu}>商品別</Radio.Button>
          </Radio.Group>
          <Button type="primary" onClick={onCsvExport}>
            CSV ダウンロード
          </Button>
        </ActionRow>
        <Spacer size={fieldSpacingAmount} />
      </>
    );
  },
);
