import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddPlanGroupFormItem } from "../useAddPlanGroupForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddPlanGroupFormItem
    label="プラングループ名"
    name="name"
    rules={[{ required: true, message: "プラングループを入力してください" }]}
    {...props}
  >
    <Input />
  </AddPlanGroupFormItem>
));
