import React, { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button } from "antd";
import { filterOptionsByName } from "models/option";
import { uniqBy } from "util/array";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { FilterConditions, OptionFilter } from "pages/Options/OptionFilter";

import { OptionTable } from "./OptionTable";
import { useOptionsDeleteOptionMutation, useOptionsGetOptionsQuery } from "./queries";
import { Option } from "./types";

export const filterOptions = (
  options: Option[],
  { name, choiceNames, menuIds }: FilterConditions,
) => {
  const filteredMenus = options.filter(
    (option) =>
      (menuIds === undefined ||
        option.menuOptions
          .flatMap(({ menu }) => menu)
          .some((menu) => menuIds.includes(menu.menuId))) &&
      (choiceNames === undefined ||
        option.choices.some((choice) => choiceNames.includes(choice.name))),
  );

  return name ? filterOptionsByName(filteredMenus, name) : filteredMenus;
};

export const Options = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getOptionsData,
    loading: loadingOptions,
    refetch: refetchOptions,
    error: getOptionsError,
  } = useOptionsGetOptionsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const options = useMemo(() => getOptionsData?.option ?? [], [getOptionsData]);
  const menus = useMemo(
    () =>
      uniqBy(
        options.flatMap(({ menuOptions }) => menuOptions.flatMap(({ menu }) => menu)),
        "menuId",
      ),
    [options],
  );
  const choices = useMemo(
    () =>
      uniqBy(
        options.flatMap(({ choices }) => choices ?? []),
        "name",
      ),
    [options],
  );

  const shouldShowAlert = getOptionsError;

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [deleteOptionMutation] = useOptionsDeleteOptionMutation();

  const onDelete = useCallback(
    async ({ optionId }: { optionId: number }) => {
      try {
        await deleteOptionMutation({
          variables: { optionId, archivedAt: new Date().toISOString() },
        });
        await refetchOptions();
        message.success("削除しました");

        navigate("/option", { replace: true });
      } catch (err) {
        message.error("削除に失敗しました");
      }
    },
    [deleteOptionMutation, navigate, refetchOptions],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredOptions = useMemo(
    () => filterOptions(options, filterConditions),
    [options, filterConditions],
  );

  return (
    <DashboardLayout title="オプション一覧">
      <PageHeader
        title="オプション一覧"
        extra={
          isFeatureEnabled("addOption")
            ? [
                <Link key="add" to="/option/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []
        }
        footer={
          <OptionFilter
            menus={menus}
            choices={choices}
            hasFilterConditions={hasFilterConditions}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            clearFilterConditions={clearFilterConditions}
          />
        }
      />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <OptionTable options={filteredOptions} loading={loadingOptions} onDelete={onDelete} />
    </DashboardLayout>
  );
};
