import React, { memo } from "react";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrNull } from "hooks/useFilterConditions";

type Props = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
};

export const IsDealingField = memo<Props>(({ value, onChange }) => (
  <Select<string>
    showSearch
    allowClear
    placeholder="取扱設定"
    value={typeof value === "boolean" ? String(value) : null}
    onChange={(isDealing) => onChange(convertStringToBooleanOrNull(isDealing))}
    optionFilterProp="label"
    options={[
      { label: "取扱あり", value: "true" },
      { label: "取扱なし", value: "false" },
    ]}
  />
));
