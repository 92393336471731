import React, { memo, useCallback } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";

const MAX_SELECTABLE_DAYS = 366;

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  shopIds: string[] | null;
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null] | undefined;
} & Omit<FilterConditions, "range">;

type Props = {
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
};

export const Filters = memo<Props>(({ filterConditions, onChangeFilterCondition }) => {
  const handleChangeRange = useCallback(
    (values: RangeValue<dayjs.Dayjs>) => {
      const startAt = values?.[0]?.startOf("day") ?? null;
      const endAt = values?.[1]?.endOf("day") ?? null;

      onChangeFilterCondition({ range: [startAt, endAt] });
    },
    [onChangeFilterCondition],
  );

  // NOTE: API のパフォーマンス観点から選択可能な期間を最大一年間とする
  const disabledDate = useCallback(
    (current: dayjs.Dayjs | null) => {
      const startAt = filterConditions.range?.[0] ?? null;
      const endAt = filterConditions.range?.[1] ?? null;

      if (startAt === null && endAt === null) return false;

      const isTooLate = Boolean(
        current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
      );
      const isTooEarly = Boolean(
        current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
      );

      return isTooLate || isTooEarly;
    },
    [filterConditions.range],
  );

  const { rangePresets } = useRangePresets();

  return (
    <DatePicker.RangePicker
      value={filterConditions.range}
      ranges={rangePresets}
      onCalendarChange={handleChangeRange}
      clearIcon={false}
      disabledDate={disabledDate}
      renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
    />
  );
});
