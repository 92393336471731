import React, { memo } from "react";
import { Input } from "antd";
import { getTaxMethodName } from "models/taxMethod";

import { FormHelp } from "components/Form/FormHelp";
import type { TaxMethodEnum } from "types/graphql";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = { value: TaxMethodEnum | undefined };

export const CostTaxMethodField = memo<Props>(({ value }) => (
  <AddMenuFormItem.NonProperty
    label={
      <FormHelp
        label="原価税種別"
        help="原価の税種別は法人ごとに設定されています。変更する場合はダイニーカスタマーサポートにお問い合わせください"
      />
    }
  >
    <Input value={value ? getTaxMethodName(value as string) : undefined} disabled />
  </AddMenuFormItem.NonProperty>
));
