import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { ChannelAccessTokenField } from "./ChannelAccessTokenField";
import { ChannelIdField } from "./ChannelIdField";
import { ChannelSecretField } from "./ChannelSecretField";
import { LineIdField } from "./LineIdField";
import {
  AddLineOfficialAccountFormValues,
  useAddLineOfficialAccountForm,
} from "./useAddLineOfficialAccountForm";

type Props = {
  onSubmit: (lineOfficialAccount: AddLineOfficialAccountFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
};

export const AddLineOfficialAccountForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading }) => {
    const { form, initialValues, submit } = useAddLineOfficialAccountForm(onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <>
        <FormContent>
          <Form
            name="lineOfficialAccount"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <LineIdField />
            <ChannelAccessTokenField />
            <ChannelIdField />
            <ChannelSecretField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
