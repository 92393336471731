import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";

import {
  EdiLineOfficialAccountGetLineOfficialAccountDocument,
  useEdiLineOfficialAccountUpdateLineOfficialAccountMutation,
} from "../queries";
import { LineOfficialAccount } from "../types";

const getInitialValues = (lineOfficialAccount: LineOfficialAccount) => ({
  ...lineOfficialAccount,
});

type EditLineOfficialAccountFormValues = {
  channelId: string;
  channelSecret: string;
  lineId: string;
  longLivedChannelAccessToken: string;
};

export const EditLineOfficialAccountFormItem = createFormItem<EditLineOfficialAccountFormValues>();

export const useEditLineOfficialAccountForm = (lineOfficialAccount: LineOfficialAccount) => {
  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const id = lineOfficialAccount.id;
  const lineOfficialAccountId = lineOfficialAccount.lineOfficialAccountId;

  const [updateLineOfficialAccountMutation, { loading }] =
    useEdiLineOfficialAccountUpdateLineOfficialAccountMutation();

  const [form] = Form.useForm();
  const initialValues = getInitialValues(lineOfficialAccount);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const submit = useCallback(async () => {
    if (!companyId || !_companyId || !id || !lineOfficialAccountId) return;
    const values = form.getFieldsValue() as EditLineOfficialAccountFormValues;

    try {
      await updateLineOfficialAccountMutation({
        variables: { input: { ...values, id, lineOfficialAccountId, companyId, _companyId } },
        refetchQueries: [
          {
            query: EdiLineOfficialAccountGetLineOfficialAccountDocument,
            variables: { lineOfficialAccountId },
          },
        ],
      });
      message.success("編集を保存しました");
      goBack();
    } catch (error) {
      message.error(error instanceof Error ? error.message : "編集の保存に失敗しました");
    }
  }, [
    form,
    updateLineOfficialAccountMutation,
    id,
    lineOfficialAccountId,
    _companyId,
    companyId,
    goBack,
  ]);

  return { form, initialValues, loading, submit, goBack };
};
