import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const NameField = memo<Props>((props) => (
  <FormItem
    label="店舗名"
    name="name"
    rules={[{ required: true, message: "店舗名を入力してください" }]}
    {...props}
  >
    <Input />
  </FormItem>
));
