import React, { memo } from "react";
import styled from "styled-components";
import { Alert } from "antd";

import { QuestionnaireMessageFormValue } from "../../types";
import { useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery } from "../queries";

const Image = styled.img`
  border-radius: 15px;
  object-fit: cover;
  max-width: 180px;
  min-width: 180px;
  max-height: 180px;
  min-height: 180px;
`;

type Props = {
  message: QuestionnaireMessageFormValue;
};

export const QuestionnaireMessage = memo(({ message }: Props) => {
  const { data, error } = useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery({
    variables: { id: message.questionnaireConfigId },
  });
  const questionnaireConfig = data?.questionnaireConfig_by_pk;

  if (!questionnaireConfig) {
    return (
      <div>
        {error ? (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        ) : null}
      </div>
    );
  }

  return <Image src={questionnaireConfig?.imageUrl ?? ""} alt="" />;
});
