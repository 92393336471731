import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanGroupPlanModalPlans = gql`
    query AddPlanGroupPlanModalPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    planId
    planGroupId: _planGroupId
    planName
  }
}
    `;
export const AddPlanInsertPlanGroupPlans = gql`
    mutation AddPlanInsertPlanGroupPlans($planGroupId: Int!, $planIds: [Int!]!) {
  update_plan(
    _set: {_planGroupId: $planGroupId}
    where: {planId: {_in: $planIds}}
  ) {
    affected_rows
  }
}
    `;
export type AddPlanGroupPlanModalPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanGroupPlanModalPlansQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName'>
    & { planGroupId: Types.Plan['_planGroupId'] }
  )> }
);

export type AddPlanInsertPlanGroupPlansMutationVariables = Types.Exact<{
  planGroupId: Types.Scalars['Int'];
  planIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type AddPlanInsertPlanGroupPlansMutation = (
  { __typename?: 'mutation_root' }
  & { update_plan?: Types.Maybe<(
    { __typename?: 'plan_mutation_response' }
    & Pick<Types.PlanMutationResponse, 'affected_rows'>
  )> }
);


export const AddPlanGroupPlanModalPlansDocument = gql`
    query AddPlanGroupPlanModalPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    planId
    planGroupId: _planGroupId
    planName
  }
}
    `;

/**
 * __useAddPlanGroupPlanModalPlansQuery__
 *
 * To run a query within a React component, call `useAddPlanGroupPlanModalPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanGroupPlanModalPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanGroupPlanModalPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanGroupPlanModalPlansQuery(baseOptions: Apollo.QueryHookOptions<AddPlanGroupPlanModalPlansQuery, AddPlanGroupPlanModalPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanGroupPlanModalPlansQuery, AddPlanGroupPlanModalPlansQueryVariables>(AddPlanGroupPlanModalPlansDocument, options);
      }
export function useAddPlanGroupPlanModalPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanGroupPlanModalPlansQuery, AddPlanGroupPlanModalPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanGroupPlanModalPlansQuery, AddPlanGroupPlanModalPlansQueryVariables>(AddPlanGroupPlanModalPlansDocument, options);
        }
export type AddPlanGroupPlanModalPlansQueryHookResult = ReturnType<typeof useAddPlanGroupPlanModalPlansQuery>;
export type AddPlanGroupPlanModalPlansLazyQueryHookResult = ReturnType<typeof useAddPlanGroupPlanModalPlansLazyQuery>;
export type AddPlanGroupPlanModalPlansQueryResult = Apollo.QueryResult<AddPlanGroupPlanModalPlansQuery, AddPlanGroupPlanModalPlansQueryVariables>;
export const AddPlanInsertPlanGroupPlansDocument = gql`
    mutation AddPlanInsertPlanGroupPlans($planGroupId: Int!, $planIds: [Int!]!) {
  update_plan(
    _set: {_planGroupId: $planGroupId}
    where: {planId: {_in: $planIds}}
  ) {
    affected_rows
  }
}
    `;
export type AddPlanInsertPlanGroupPlansMutationFn = Apollo.MutationFunction<AddPlanInsertPlanGroupPlansMutation, AddPlanInsertPlanGroupPlansMutationVariables>;

/**
 * __useAddPlanInsertPlanGroupPlansMutation__
 *
 * To run a mutation, you first call `useAddPlanInsertPlanGroupPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanInsertPlanGroupPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanInsertPlanGroupPlansMutation, { data, loading, error }] = useAddPlanInsertPlanGroupPlansMutation({
 *   variables: {
 *      planGroupId: // value for 'planGroupId'
 *      planIds: // value for 'planIds'
 *   },
 * });
 */
export function useAddPlanInsertPlanGroupPlansMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanInsertPlanGroupPlansMutation, AddPlanInsertPlanGroupPlansMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanInsertPlanGroupPlansMutation, AddPlanInsertPlanGroupPlansMutationVariables>(AddPlanInsertPlanGroupPlansDocument, options);
      }
export type AddPlanInsertPlanGroupPlansMutationHookResult = ReturnType<typeof useAddPlanInsertPlanGroupPlansMutation>;
export type AddPlanInsertPlanGroupPlansMutationResult = Apollo.MutationResult<AddPlanInsertPlanGroupPlansMutation>;
export type AddPlanInsertPlanGroupPlansMutationOptions = Apollo.BaseMutationOptions<AddPlanInsertPlanGroupPlansMutation, AddPlanInsertPlanGroupPlansMutationVariables>;