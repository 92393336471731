import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useGiftOption } from "hooks/useGiftOption";
import { useShop } from "hooks/useShop";
import { logger } from "libs/logger";
import { AddGiftChoiceForm } from "pages/AddGiftChoice/AddGiftChoiceForm";
import { useAddGiftChoiceInsertGiftChoiceMutation } from "pages/AddGiftChoice/queries";
import { ChoiceInsertInput, MenuTypeEnum } from "types/graphql";

export const AddGiftChoice = () => {
  const [company] = useCompany();
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const navigate = useNavigate();

  const { loading: loadingFindOrIssueGiftOption, findOrIssueGiftOption } = useGiftOption();

  const [addGiftChoiceMutation, { loading: loadingInsertGiftChoice }] =
    useAddGiftChoiceInsertGiftChoiceMutation();

  const onSubmit = useCallback(
    async (choice: Omit<ChoiceInsertInput, "optionId" | "_optionId">) => {
      try {
        if (!company) return;
        if (!shopId) return;

        const option = await findOrIssueGiftOption({
          companyId: company.id,
          shopId,
        });

        const { name, receiptDisplayName, price, priority } = choice;

        const { data: addGiftChoiceData, errors } = await addGiftChoiceMutation({
          variables: {
            choice: {
              name,
              receiptDisplayName,
              price,
              priority,
              optionId: option.id,
              _optionId: option.optionId,
              menuType: MenuTypeEnum.FaveYell,
            },
          },
        });

        const choiceId = addGiftChoiceData?.insert_choice_one?.choiceId;

        if (choiceId === undefined) {
          logger.error("Failed to create gift choice", { errors });
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/giftChoice", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [company, shopId, findOrIssueGiftOption, addGiftChoiceMutation, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="推しエール金額を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "推しエール金額" }],
      }}
    >
      <PageHeader title="推しエール金額を新規作成" onBack={goBack} />
      <AddGiftChoiceForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingFindOrIssueGiftOption || loadingInsertGiftChoice}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
