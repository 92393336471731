import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditGoogleMapConfigFormItem } from "../useEditGoogleMapConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableGoogleMapReviewField = memo<Props>(({ ...props }) => (
  <EditGoogleMapConfigFormItem
    label="アンケート内容をGoogle マップで口コミ投稿できるようにする"
    name="enableGoogleMapReview"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditGoogleMapConfigFormItem>
));
