import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddRoleFormItem } from "../useAddRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DishUpReceiptField = memo<Props>(({ ...props }) => (
  <AddRoleFormItem
    label="デシャップ伝票印刷"
    name="shouldPrintDishUpSlip"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </AddRoleFormItem>
));
