import React, { memo } from "react";
import { Radio } from "antd";
import { printerSoundTypes } from "models/printerSound";

import { FormItemProps } from "components/antd/Form";

import { AddRoleFormItem } from "../useAddRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PrinterSoundField = memo<Props>(({ ...props }) => (
  <AddRoleFormItem label="印刷音" name="printerSound" {...props}>
    <Radio.Group>
      {Object.entries(printerSoundTypes).map(([key, type]) => (
        <Radio key={key} value={Number(key)}>
          {type}
        </Radio>
      ))}
    </Radio.Group>
  </AddRoleFormItem>
));
