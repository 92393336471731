import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { CurrentStockNumField } from "pages/EditShopMenuStock/EditShopMenuStockForm/CurrentStockNumField";
import { DailyStockNumField } from "pages/EditShopMenuStock/EditShopMenuStockForm/DailyStockNumField";
import { NoCurrentStockNumField } from "pages/EditShopMenuStock/EditShopMenuStockForm/NoCurrentStockNumField";
import { NoDailyStockNumField } from "pages/EditShopMenuStock/EditShopMenuStockForm/NoDailyStockNumField";
import { useEditShopMenuStockForm } from "pages/EditShopMenuStock/EditShopMenuStockForm/useEditShopMenuStockForm";
import { ShopMenu } from "pages/EditShopMenuStock/types";
import { Stock } from "types/graphql";

type Props = {
  shopMenu: ShopMenu;
  onSubmit: (stock: Pick<Stock, "currentStockNum" | "dailyStockNum">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditShopMenuStockForm = memo<Props>(({ shopMenu, onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useEditShopMenuStockForm(shopMenu, onSubmit);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  return (
    <>
      <FormContent>
        <Form name="shopMenuStock" layout="vertical" form={form} initialValues={initialValues}>
          <CurrentStockNumField />
          <NoCurrentStockNumField />
          <DailyStockNumField />
          <NoDailyStockNumField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
