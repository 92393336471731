import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type InputMasterDataFormValues = {
  orderableTime: File;
  category: File;
  menu: File;
  option: File;
  choice: File;
  planGroup: File;
  plan: File;
  planOption: File;
  planChoice: File;
};

export const InputMasterDataFormItem = createFormItem<InputMasterDataFormValues>();

export const useInputMasterDataForm = ({
  onSubmit,
}: {
  onSubmit: (args: InputMasterDataFormValues) => void;
}) => {
  const [form] = Form.useForm<InputMasterDataFormValues>();

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue());
  }, [form, onSubmit]);

  return { form, submit };
};
