import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanTecAggregationPlanFormItem } from "../useEditPlanTecAggregationPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationGpCodeField = memo<Props>((props) => (
  <EditPlanTecAggregationPlanFormItem
    label="分類コード"
    name="gpCode"
    rules={[{ pattern: /^\d{1,4}$/, message: "数字で入力してください", required: true }]}
    required
    {...props}
  >
    <InputCode />
  </EditPlanTecAggregationPlanFormItem>
));
