import React, { memo } from "react";
import { Card, Col, Row, Statistic } from "antd";
import { sumBy } from "util/array";

import { CoineySettlement } from "pages/CoineySales/types";

type Props = {
  coineySettlements: CoineySettlement[];
};

export const CoineySalesStatistics = memo<Props>(({ coineySettlements }) => {
  const salesAmount = sumBy(coineySettlements, "amount");

  return (
    <Row>
      <Col span={24}>
        <Card>
          <Statistic title="売上高 (税込)" value={salesAmount} suffix="円" />
        </Card>
      </Col>
    </Row>
  );
});
