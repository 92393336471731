import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { MediaNameField } from "pages/TecAggregationMediaMaps/EditTecAggregationMediaMapModal/EditTecAggregationMediaMapForm/MediaNameField";
import { OnSitePaymentDetailTypeWithTecAggregationDetailType } from "pages/TecAggregationOnSitePaymentDetailTypes/TecAggregationDetailTypeTable";
import { TecAggregationOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { MediaCodeField } from "./MediaCodeField";
import { MediaMapNameField } from "./MediaMapNameField";
import { PaymentTypeField } from "./PaymentTypeField";
import { useEditDetailTypeForm } from "./useEditDetailTypeForm";

type Props = {
  onSitePaymentDetailType: OnSitePaymentDetailTypeWithTecAggregationDetailType;
  mediaMaps: { aggregationNumber: number; mediaName: string }[];
  onClose: () => void;
  onSubmit: ({
    detailType,
    aggregationNumber,
  }: {
    detailType: Omit<
      TecAggregationOnSitePaymentDetailTypeInsertInput,
      "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
    >;
    aggregationNumber: number;
  }) => void;
  loading: boolean;
};

export const EditTecAggregationOnSitePaymentDetailTypeForm = memo<Props>(
  ({ onClose, onSubmit, loading, onSitePaymentDetailType, mediaMaps }) => {
    const { form, submit, initialValues } = useEditDetailTypeForm({
      onSitePaymentDetailType,
      onSubmit,
    });

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="tecAggregationOnSitePaymentDetailType"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <MediaCodeField />
            <MediaNameField />
            <PaymentTypeField />
            <MediaMapNameField mediaMaps={mediaMaps} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
