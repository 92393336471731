import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";

import { ClearTableHistoryTable } from "./ClearTableHistoryTable";
import { useClearTableHistoriesGetHistoriesQuery } from "./queries";

export const ClearTableHistory = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getHistoriesData,
    loading: loadingHistoriesData,
    error: getHistoriesQueryError,
  } = useClearTableHistoriesGetHistoriesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const histories = getHistoriesData?.tableUser ?? [];

  return (
    <DashboardLayout title="テーブルクリア履歴一覧">
      <PageHeader title="テーブルクリア履歴一覧" footer={<ShopSelector />} />
      {getHistoriesQueryError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ClearTableHistoryTable loading={loadingHistoriesData} clearTableHistories={histories} />
    </DashboardLayout>
  );
};
