import React, { memo, useCallback } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { CompanyTable } from "pages/Corporations/CompanyTable";

import { Corporation } from "../types";

type Props = {
  loading?: boolean;
  corporations: Corporation[];
};

export const CorporationTable = memo<Props>(({ loading, corporations }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "法人名", dataIndex: "name" },
    {
      title: "業態数",
      width: 80,
      align: "right",
      render(_: string, { companies }: Corporation) {
        return companies.length;
      },
    } as const,
    {
      title: "",
      align: "center",
      width: 60,
      fixed: "right",
      render(_: string, { id }: Corporation) {
        return (
          <IconLink to={`/corporation/${id}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
  ];

  const expandedRowRender = useCallback(
    ({ companies }: Corporation) => <CompanyTable companies={companies} />,
    [],
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={corporations}
      loading={loading}
      expandable={{ expandedRowRender }}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
