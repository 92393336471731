import React, { memo, useMemo } from "react";
import { ColumnsType } from "antd/lib/table";

import { ImageField } from "components/Form/ImageField";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { CategoryNameField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/CategoryNameField";
import { CostPriceField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/CostPriceField";
import { CostTaxRateField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/CostTaxRateField";
import { NameField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/NameField";
import { PriceField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/PriceField";
import { TaxMethodField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/TaxMethodField";
import { TaxRateField } from "pages/EditMenusBulk/EditMenusBulkForm/EditMenusBulkFormTable/TaxRateField";
import {
  EditMenusBulkFormInitialValue,
  EditMenusBulkFormItem,
  EditMenusBulkMultipleEditValues,
  EditMenusBulkTargetValues,
} from "pages/EditMenusBulk/EditMenusBulkForm/useEditMenusBulkForm";
import { DisplayTypeEnum } from "types/graphql";

import { DisplayTypeField } from "./DisplayTypeField";

type Props = {
  initialValues: EditMenusBulkFormInitialValue[];
  targetValues: EditMenusBulkTargetValues;
  selectedIds: number[];
  setSelectedIds: (selectedIds: number[]) => void;
  handleChange: (changedValue: EditMenusBulkTargetValues | EditMenusBulkMultipleEditValues) => void;
};

export const EditMenusBulkFormTable = memo<Props>(
  ({ initialValues, targetValues, selectedIds, setSelectedIds, handleChange }) => {
    const [pagination, setPagination] = usePagination();

    const dataSource = useMemo(
      () => initialValues.map((value) => ({ key: value.categoryMenuId, ...value })),
      [initialValues],
    );

    const columns: ColumnsType<EditMenusBulkFormInitialValue> = useMemo(
      () => [
        {
          title: "画像",
          align: "center",
          key: "imageUrl",
          dataIndex: "imageUrl",
          width: 100,
          render(value: string, { categoryMenuId, canEdit }) {
            return (
              <EditMenusBulkFormItem.NonProperty noStyle shouldUpdate>
                <ImageField
                  image={targetValues[categoryMenuId]?.imageUrl ?? value}
                  setUploadImage={(image) =>
                    handleChange({
                      [categoryMenuId]: { imageUrl: image },
                    } as EditMenusBulkTargetValues)
                  }
                  uploadImageApiKey="menu"
                  formName="EditMenusBulkForm"
                  width={120}
                  height={100}
                  isAutoHeight
                  isMovieAccepted
                  objectFitContain
                  disabled={!canEdit}
                />
              </EditMenusBulkFormItem.NonProperty>
            );
          },
        },
        {
          title: "商品名",
          dataIndex: "name",
          key: "name",
          width: 300,
          render(value: string, { categoryMenuId }: { categoryMenuId: number }) {
            return <NameField value={value} categoryMenuId={categoryMenuId} />;
          },
        },
        {
          title: "カテゴリ名",
          dataIndex: "categoryName",
          key: "categoryName",
          width: 300,
          render(value: string, { categoryMenuId }: { categoryMenuId: number }) {
            return <CategoryNameField value={value} categoryMenuId={categoryMenuId} />;
          },
        },
        {
          title: "画像の表示サイズ",
          dataIndex: "displayType",
          key: "displayType",
          width: 400,
          render(initialValue: DisplayTypeEnum, { categoryMenuId, canEdit }) {
            return (
              <DisplayTypeField
                initialValue={initialValue}
                value={targetValues[categoryMenuId]?.displayType}
                categoryMenuId={categoryMenuId}
                disabled={!canEdit}
              />
            );
          },
        },
        {
          title: "金額",
          dataIndex: "price",
          key: "price",
          width: 100,
          render(initialValue: number, { categoryMenuId, canEdit }) {
            return (
              <PriceField
                initialValue={initialValue}
                categoryMenuId={categoryMenuId}
                value={targetValues[categoryMenuId]?.price}
                disabled={!canEdit}
              />
            );
          },
        },
        {
          title: "税率",
          dataIndex: "taxRate",
          key: "taxRate",
          width: 100,
          render(initialValue: number, { categoryMenuId, canEdit }) {
            return (
              <TaxRateField
                value={targetValues[categoryMenuId]?.taxRate}
                initialValue={initialValue}
                categoryMenuId={categoryMenuId}
                disabled={!canEdit}
              />
            );
          },
        },
        {
          title: "税種別",
          dataIndex: "taxMethod",
          key: "taxMethod",
          width: 100,
          render(initialValue: string, { categoryMenuId, canEdit }) {
            return (
              <TaxMethodField
                value={targetValues[categoryMenuId]?.taxMethod}
                initialValue={initialValue}
                categoryMenuId={categoryMenuId}
                disabled={!canEdit}
              />
            );
          },
        },
        {
          title: "原価",
          dataIndex: "costPrice",
          key: "costPrice",
          width: 100,
          render(initialValue: number | undefined, { categoryMenuId, canEdit }) {
            return (
              <CostPriceField
                value={targetValues[categoryMenuId]?.costPrice}
                initialValue={initialValue}
                categoryMenuId={categoryMenuId}
                disabled={!canEdit}
              />
            );
          },
        },
        {
          title: "原価税率",
          dataIndex: "costTaxRate",
          key: "costTaxRate",
          width: 100,
          render(initialValue: number | undefined, { categoryMenuId, canEdit }) {
            return (
              <CostTaxRateField
                value={targetValues[categoryMenuId]?.costTaxRate}
                initialValue={initialValue}
                categoryMenuId={categoryMenuId}
                disabled={!canEdit}
              />
            );
          },
        },
      ],
      [targetValues, handleChange],
    );

    return (
      <Table
        largePagination
        rowKey="key"
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedIds,
          onChange: (_, rows) => setSelectedIds(rows.map(({ categoryMenuId }) => categoryMenuId)),
          getCheckboxProps: ({ canEdit }) => ({ disabled: !canEdit }),
        }}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
