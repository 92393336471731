import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddAccountingSlipImageModalAddAccountingSlipImage = gql`
    mutation AddAccountingSlipImageModalAddAccountingSlipImage($input: accountingSlipImage_insert_input!) {
  insert_accountingSlipImage_one(object: $input) {
    accountingSlipImageId: id
  }
}
    `;
export type AddAccountingSlipImageModalAddAccountingSlipImageMutationVariables = Types.Exact<{
  input: Types.AccountingSlipImageInsertInput;
}>;


export type AddAccountingSlipImageModalAddAccountingSlipImageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_accountingSlipImage_one?: Types.Maybe<(
    { __typename?: 'accountingSlipImage' }
    & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
  )> }
);


export const AddAccountingSlipImageModalAddAccountingSlipImageDocument = gql`
    mutation AddAccountingSlipImageModalAddAccountingSlipImage($input: accountingSlipImage_insert_input!) {
  insert_accountingSlipImage_one(object: $input) {
    accountingSlipImageId: id
  }
}
    `;
export type AddAccountingSlipImageModalAddAccountingSlipImageMutationFn = Apollo.MutationFunction<AddAccountingSlipImageModalAddAccountingSlipImageMutation, AddAccountingSlipImageModalAddAccountingSlipImageMutationVariables>;

/**
 * __useAddAccountingSlipImageModalAddAccountingSlipImageMutation__
 *
 * To run a mutation, you first call `useAddAccountingSlipImageModalAddAccountingSlipImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountingSlipImageModalAddAccountingSlipImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountingSlipImageModalAddAccountingSlipImageMutation, { data, loading, error }] = useAddAccountingSlipImageModalAddAccountingSlipImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountingSlipImageModalAddAccountingSlipImageMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountingSlipImageModalAddAccountingSlipImageMutation, AddAccountingSlipImageModalAddAccountingSlipImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountingSlipImageModalAddAccountingSlipImageMutation, AddAccountingSlipImageModalAddAccountingSlipImageMutationVariables>(AddAccountingSlipImageModalAddAccountingSlipImageDocument, options);
      }
export type AddAccountingSlipImageModalAddAccountingSlipImageMutationHookResult = ReturnType<typeof useAddAccountingSlipImageModalAddAccountingSlipImageMutation>;
export type AddAccountingSlipImageModalAddAccountingSlipImageMutationResult = Apollo.MutationResult<AddAccountingSlipImageModalAddAccountingSlipImageMutation>;
export type AddAccountingSlipImageModalAddAccountingSlipImageMutationOptions = Apollo.BaseMutationOptions<AddAccountingSlipImageModalAddAccountingSlipImageMutation, AddAccountingSlipImageModalAddAccountingSlipImageMutationVariables>;