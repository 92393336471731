import React, { memo } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { WinboardMenuInsertInput } from "types/graphql";

import { PlanChoiceWithWinboardMenu } from "../../types";

import { BumonCodeField } from "./BumonCodeField";
import { BumonNameField } from "./BumonNameField";
import { CategoryCodeField } from "./CategoryCodeField";
import { CategoryNameField } from "./CategoryNameField";
import { CodeField } from "./CodeField";
import { NameField } from "./NameField";
import { useEditPlanChoiceWinboardMenuForm } from "./useEditPlanChoiceWinboardMenuForm";

type Props = {
  planChoice: PlanChoiceWithWinboardMenu;
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPlanChoiceWinboardMenuForm = memo<Props>(
  ({ planChoice, onClose, onSubmit, loading }) => {
    const winboardMenu = planChoice?.winboardMenu ?? undefined;

    const { form, initialValues, submit } = useEditPlanChoiceWinboardMenuForm({
      planChoice,
      winboardMenu,
      onSubmit,
    });

    return (
      <Form name="winboardMenu" form={form} layout="vertical" initialValues={initialValues}>
        <Row>
          <Col span={24}>
            <CodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <NameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BumonCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <BumonNameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CategoryCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CategoryNameField />
          </Col>
        </Row>

        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            保存
          </Button>
        </FormActions>
      </Form>
    );
  },
);
