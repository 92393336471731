import React, { memo } from "react";
import styled from "styled-components";
import { List } from "antd";

import { usePagination } from "hooks/usePagination";
import { ListItem } from "pages/QuestionnaireAnalyticsCommentsMetrics/CommentList/ListItem";
import { QuestionCategoryType } from "types/graphql";

import { FilterConditions } from "../CommentFilter";
import { QuestionnaireComment } from "../types";

import { HeaderMenu } from "./HeaderMenu";

const Container = styled.div`
  margin-bottom: 32px;
  .ant-list-items,
  .ant-list-empty-text {
    background-color: #ffffff;
  }
`;

type Props = {
  questionnaireComments: QuestionnaireComment[];
  category?: QuestionCategoryType;
  loading: boolean;
  filterConditions: Partial<FilterConditions>;
};

export const CommentList = memo<Props>(
  ({ questionnaireComments, category, loading, filterConditions }) => {
    const [pagination, setPagination] = usePagination();

    return (
      <Container>
        <HeaderMenu category={category} filterConditions={filterConditions} />
        <List
          dataSource={questionnaireComments}
          loading={loading}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
            defaultPageSize: 10,
          }}
          renderItem={(comment, index) => <ListItem key={index} comment={comment} />}
        />
      </Container>
    );
  },
);
