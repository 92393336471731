import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useShop } from "hooks/useShop";
import { Table, TableInsertInput } from "types/graphql";

export type AddTableFormValues = Pick<Table, "name" | "tableAreaId">;

const getInitialValues = () => ({});

export const AddTableFormItem = createFormItem<AddTableFormValues>();

export const useAddTableForm = (onSubmit: (input: TableInsertInput) => Promise<void>) => {
  const [shop] = useShop();

  const shopId = shop?.shopId;

  const [form] = Form.useForm<AddTableFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(async () => {
    if (!shopId) return;

    await form.validateFields();

    const { name, tableAreaId } = form.getFieldsValue();

    await onSubmit({
      name,
      tableAreaId,
      shopId,
    });
  }, [form, onSubmit, shopId]);

  return { form, initialValues, submit };
};
