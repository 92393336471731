import React, { memo } from "react";
import { Switch, Typography } from "antd";

const { Paragraph } = Typography;

export const DealingSwitch = memo<{
  shopId: string;
  isDealing: boolean;
  disabled: boolean;
  onSelectShopId({ shopId }: { shopId: string }): void;
  onDeselectShopId({ shopId }: { shopId: string }): void;
}>(({ isDealing, shopId, disabled, onDeselectShopId, onSelectShopId }) => (
  <Switch
    checked={isDealing}
    onChange={(value) => (value ? onSelectShopId({ shopId }) : onDeselectShopId({ shopId }))}
    disabled={disabled}
  />
));
