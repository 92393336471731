import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddDashboardAccountGetCompanies = gql`
    query AddDashboardAccountGetCompanies($corporationId: uuid!) {
  company(where: {corporationId: {_eq: $corporationId}}, order_by: {name: asc}) {
    id
    corporationId
    companyId
    name
  }
}
    `;
export const AddDashboardAccountGetShops = gql`
    query AddDashboardAccountGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const AddDashboardAccountGetDashboardAccountRoles = gql`
    query AddDashboardAccountGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
  }
}
    `;
export const AddDashboardAccountAddAccount = gql`
    mutation AddDashboardAccountAddAccount($input: CreateDashboardAccountInput!) {
  createDashboardAccount(input: $input)
}
    `;
export type AddDashboardAccountGetCompaniesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type AddDashboardAccountGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'corporationId' | 'companyId' | 'name'>
  )> }
);

export type AddDashboardAccountGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type AddDashboardAccountGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type AddDashboardAccountGetDashboardAccountRolesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type AddDashboardAccountGetDashboardAccountRolesQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountRole: Array<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id' | 'name'>
  )> }
);

export type AddDashboardAccountAddAccountMutationVariables = Types.Exact<{
  input: Types.CreateDashboardAccountInput;
}>;


export type AddDashboardAccountAddAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'createDashboardAccount'>
);


export const AddDashboardAccountGetCompaniesDocument = gql`
    query AddDashboardAccountGetCompanies($corporationId: uuid!) {
  company(where: {corporationId: {_eq: $corporationId}}, order_by: {name: asc}) {
    id
    corporationId
    companyId
    name
  }
}
    `;

/**
 * __useAddDashboardAccountGetCompaniesQuery__
 *
 * To run a query within a React component, call `useAddDashboardAccountGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardAccountGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDashboardAccountGetCompaniesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useAddDashboardAccountGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<AddDashboardAccountGetCompaniesQuery, AddDashboardAccountGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDashboardAccountGetCompaniesQuery, AddDashboardAccountGetCompaniesQueryVariables>(AddDashboardAccountGetCompaniesDocument, options);
      }
export function useAddDashboardAccountGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDashboardAccountGetCompaniesQuery, AddDashboardAccountGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDashboardAccountGetCompaniesQuery, AddDashboardAccountGetCompaniesQueryVariables>(AddDashboardAccountGetCompaniesDocument, options);
        }
export type AddDashboardAccountGetCompaniesQueryHookResult = ReturnType<typeof useAddDashboardAccountGetCompaniesQuery>;
export type AddDashboardAccountGetCompaniesLazyQueryHookResult = ReturnType<typeof useAddDashboardAccountGetCompaniesLazyQuery>;
export type AddDashboardAccountGetCompaniesQueryResult = Apollo.QueryResult<AddDashboardAccountGetCompaniesQuery, AddDashboardAccountGetCompaniesQueryVariables>;
export const AddDashboardAccountGetShopsDocument = gql`
    query AddDashboardAccountGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useAddDashboardAccountGetShopsQuery__
 *
 * To run a query within a React component, call `useAddDashboardAccountGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardAccountGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDashboardAccountGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useAddDashboardAccountGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AddDashboardAccountGetShopsQuery, AddDashboardAccountGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDashboardAccountGetShopsQuery, AddDashboardAccountGetShopsQueryVariables>(AddDashboardAccountGetShopsDocument, options);
      }
export function useAddDashboardAccountGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDashboardAccountGetShopsQuery, AddDashboardAccountGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDashboardAccountGetShopsQuery, AddDashboardAccountGetShopsQueryVariables>(AddDashboardAccountGetShopsDocument, options);
        }
export type AddDashboardAccountGetShopsQueryHookResult = ReturnType<typeof useAddDashboardAccountGetShopsQuery>;
export type AddDashboardAccountGetShopsLazyQueryHookResult = ReturnType<typeof useAddDashboardAccountGetShopsLazyQuery>;
export type AddDashboardAccountGetShopsQueryResult = Apollo.QueryResult<AddDashboardAccountGetShopsQuery, AddDashboardAccountGetShopsQueryVariables>;
export const AddDashboardAccountGetDashboardAccountRolesDocument = gql`
    query AddDashboardAccountGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddDashboardAccountGetDashboardAccountRolesQuery__
 *
 * To run a query within a React component, call `useAddDashboardAccountGetDashboardAccountRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardAccountGetDashboardAccountRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDashboardAccountGetDashboardAccountRolesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useAddDashboardAccountGetDashboardAccountRolesQuery(baseOptions: Apollo.QueryHookOptions<AddDashboardAccountGetDashboardAccountRolesQuery, AddDashboardAccountGetDashboardAccountRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDashboardAccountGetDashboardAccountRolesQuery, AddDashboardAccountGetDashboardAccountRolesQueryVariables>(AddDashboardAccountGetDashboardAccountRolesDocument, options);
      }
export function useAddDashboardAccountGetDashboardAccountRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDashboardAccountGetDashboardAccountRolesQuery, AddDashboardAccountGetDashboardAccountRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDashboardAccountGetDashboardAccountRolesQuery, AddDashboardAccountGetDashboardAccountRolesQueryVariables>(AddDashboardAccountGetDashboardAccountRolesDocument, options);
        }
export type AddDashboardAccountGetDashboardAccountRolesQueryHookResult = ReturnType<typeof useAddDashboardAccountGetDashboardAccountRolesQuery>;
export type AddDashboardAccountGetDashboardAccountRolesLazyQueryHookResult = ReturnType<typeof useAddDashboardAccountGetDashboardAccountRolesLazyQuery>;
export type AddDashboardAccountGetDashboardAccountRolesQueryResult = Apollo.QueryResult<AddDashboardAccountGetDashboardAccountRolesQuery, AddDashboardAccountGetDashboardAccountRolesQueryVariables>;
export const AddDashboardAccountAddAccountDocument = gql`
    mutation AddDashboardAccountAddAccount($input: CreateDashboardAccountInput!) {
  createDashboardAccount(input: $input)
}
    `;
export type AddDashboardAccountAddAccountMutationFn = Apollo.MutationFunction<AddDashboardAccountAddAccountMutation, AddDashboardAccountAddAccountMutationVariables>;

/**
 * __useAddDashboardAccountAddAccountMutation__
 *
 * To run a mutation, you first call `useAddDashboardAccountAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDashboardAccountAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDashboardAccountAddAccountMutation, { data, loading, error }] = useAddDashboardAccountAddAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDashboardAccountAddAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddDashboardAccountAddAccountMutation, AddDashboardAccountAddAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDashboardAccountAddAccountMutation, AddDashboardAccountAddAccountMutationVariables>(AddDashboardAccountAddAccountDocument, options);
      }
export type AddDashboardAccountAddAccountMutationHookResult = ReturnType<typeof useAddDashboardAccountAddAccountMutation>;
export type AddDashboardAccountAddAccountMutationResult = Apollo.MutationResult<AddDashboardAccountAddAccountMutation>;
export type AddDashboardAccountAddAccountMutationOptions = Apollo.BaseMutationOptions<AddDashboardAccountAddAccountMutation, AddDashboardAccountAddAccountMutationVariables>;