import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { Option } from "types/graphql";

export const filterOptionsByName = <T extends Pick<Option, "name">>(options: T[], name: string) => {
  const fuse = new Fuse(
    options.map(({ name }) => ({ name: hiraganaToKatakana(name) })),
    { keys: ["name"] },
  );
  const indexes = fuse.search(hiraganaToKatakana(name)).map(({ refIndex }) => refIndex);

  return indexes.map((index) => options[index]).filter(isNotNullable);
};
