import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Descriptions } from "antd";
import { sortBusinessOperationHourTypes } from "models/businessOperationHour";
import { getMessageDeliveryShopJobErrorMessage } from "models/messageDeliveryShopJob";
import { uniq } from "util/array";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useMessageDeliveryFormGetLineOfficialAccountsQuery } from "components/MessageDeliveryForm/MessagePreview/queries";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useErrorMessageDeliveryJob } from "hooks/useErrorMessageDeliveryJob";
import {
  useMessageDeliveryJobGetCouponsQuery,
  useMessageDeliveryJobGetMenusQuery,
  useMessageDeliveryJobGetMessageDeliveryJobQuery,
  useMessageDeliveryJobGetQuestionnaireConfigsQuery,
  useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery,
  useMessageDeliveryJobGetShopsQuery,
} from "pages/MessageDeliveryJob/queries";
import { MessageDeliveryShopJobErrorTypeEnum } from "types/graphql";

import { MessageDeliveryJobForm } from "./MessageDeliveryJobForm";

const StyledAlert = styled(Alert)`
  display: flex;
  align-items: flex-start;
`;

export const MessageDeliveryJob = () => {
  const navigate = useNavigate();
  const { checkErrorMessageDeliveryShopJob } = useErrorMessageDeliveryJob();

  const { id } = useParams<{ id: string }>();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMessageDeliveryJobData,
    loading: getMessageDeliveryJobLoading,
    error: getMessageDeliveryJobError,
  } = useMessageDeliveryJobGetMessageDeliveryJobQuery(
    id
      ? {
          variables: { id },
          onCompleted(data) {
            const errorMessageDeliveryShopJobIds =
              data.messageDeliveryJob_by_pk?.messageDeliveryShopJobs
                .filter((messageDeliveryShopJob) => Boolean(messageDeliveryShopJob.errorType))
                ?.map(({ id }) => id) ?? [];

            checkErrorMessageDeliveryShopJob({ errorMessageDeliveryShopJobIds });
          },
        }
      : { skip: true },
  );

  const messageDelivery = getMessageDeliveryJobData?.messageDeliveryJob_by_pk?.messageDelivery;

  const messageDeliveryShopJobErrors = useMemo(
    () =>
      getMessageDeliveryJobData?.messageDeliveryJob_by_pk?.messageDeliveryShopJobs.filter(
        (messageDeliveryShopJob) => Boolean(messageDeliveryShopJob.errorType),
      ) ?? [],
    [getMessageDeliveryJobData?.messageDeliveryJob_by_pk?.messageDeliveryShopJobs],
  );

  const {
    data: getCouponsData,
    loading: getCouponsLoading,
    error: getCouponsError,
  } = useMessageDeliveryJobGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = getCouponsData?.coupon ?? [];

  const {
    data: getShopsData,
    loading: getShopsLoading,
    error: getShopsError,
  } = useMessageDeliveryJobGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = getShopsData?.shop ?? [];

  const {
    data: getQuestionnaireConfigsData,
    loading: getQuestionnaireConfigsLoading,
    error: getQuestionnaireConfigsError,
  } = useMessageDeliveryJobGetQuestionnaireConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const questionnaireConfigs = getQuestionnaireConfigsData?.questionnaireConfig ?? [];

  const { data: getShopBusinessOperationHourTypesData } =
    useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const shopBusinessOperationHourTypes = sortBusinessOperationHourTypes(
    uniq(
      getShopBusinessOperationHourTypesData?.shopBusinessOperationHour.map(
        ({ businessOperationHourType }) => businessOperationHourType,
      ) ?? [],
    ),
  );

  const {
    data: getLineOfficialAccounts,
    loading: loadingLineOfficialAccounts,
    error: getLineOfficialAccountsError,
  } = useMessageDeliveryFormGetLineOfficialAccountsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const lineOfficialAccounts = useMemo(
    () =>
      [...(getLineOfficialAccounts?.lineOfficialAccounts ?? [])].sort((a, b) =>
        a.channelName > b.channelName ? 1 : -1,
      ),
    [getLineOfficialAccounts?.lineOfficialAccounts],
  );

  const { data: getMenusData } = useMessageDeliveryJobGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData?.menu]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const loading =
    getMessageDeliveryJobLoading ||
    getCouponsLoading ||
    getShopsLoading ||
    getQuestionnaireConfigsLoading ||
    loadingLineOfficialAccounts;

  const error =
    getMessageDeliveryJobError ||
    getCouponsError ||
    getShopsError ||
    getQuestionnaireConfigsError ||
    getLineOfficialAccountsError;

  return (
    <DashboardLayout
      title={messageDelivery?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "メッセージ" }],
      }}
    >
      <PageHeader title={messageDelivery?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {!loading &&
        (messageDeliveryShopJobErrors.length === 0 ? (
          <Alert message="配信が完了しました。" type="success" showIcon />
        ) : (
          messageDeliveryShopJobErrors.map((messageDeliveryShopJobError, i, arr) => (
            <>
              <StyledAlert
                key={messageDeliveryShopJobError.id}
                type="error"
                showIcon
                message={messageDeliveryShopJobError.shop.name}
                description={
                  <Descriptions
                    items={[
                      {
                        key: "message",
                        span: 3,
                        children: (
                          <>
                            {getMessageDeliveryShopJobErrorMessage({
                              error:
                                messageDeliveryShopJobError.errorType ??
                                MessageDeliveryShopJobErrorTypeEnum.UnexpectedServerError,
                            })}
                          </>
                        ),
                      },
                      {
                        key: "total",
                        label: "送信対象",
                        children: `${messageDeliveryShopJobError.targetLineMessagingPerShopCount}件`,
                      },
                      {
                        key: "succeeded",
                        label: "送信成功",
                        children: `${messageDeliveryShopJobError.succeededLineMessagingPerShopCount}件`,
                      },
                      {
                        key: "failed",
                        label: "送信失敗",
                        children: `${
                          messageDeliveryShopJobError.targetLineMessagingPerShopCount -
                          messageDeliveryShopJobError.succeededLineMessagingPerShopCount
                        }件`,
                      },
                    ]}
                  />
                }
              />
              {arr.length - 1 !== i ? <Spacer size={12} /> : null}
            </>
          ))
        ))}
      <Spacer size={16} />
      {messageDelivery && (
        <MessageDeliveryJobForm
          loading={loading}
          coupons={coupons}
          questionnaireConfigs={questionnaireConfigs}
          messageDelivery={messageDelivery}
          shops={shops}
          menus={menus}
          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
          lineOfficialAccounts={lineOfficialAccounts}
        />
      )}
    </DashboardLayout>
  );
};
