import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ClearTableHistoriesGetHistories = gql`
    query ClearTableHistoriesGetHistories($shopId: uuid!) {
  tableUser(
    where: {shopId: {_eq: $shopId}, deactivatedAt: {_is_null: false}, tableClearReason: {_is_null: false}}
    order_by: {deactivatedAt: desc}
  ) {
    id
    tableClearReason
    tableClearedClerkId
    table {
      name
    }
    tableClearedClerk {
      name
    }
    deactivatedAt
    taxIncludedPaidAmount
  }
}
    `;
export type ClearTableHistoriesGetHistoriesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ClearTableHistoriesGetHistoriesQuery = (
  { __typename?: 'query_root' }
  & { tableUser: Array<(
    { __typename?: 'tableUser' }
    & Pick<Types.TableUser, 'id' | 'tableClearReason' | 'tableClearedClerkId' | 'deactivatedAt' | 'taxIncludedPaidAmount'>
    & { table: (
      { __typename?: 'table' }
      & Pick<Types.Table, 'name'>
    ), tableClearedClerk?: Types.Maybe<(
      { __typename?: 'clerk' }
      & Pick<Types.Clerk, 'name'>
    )> }
  )> }
);


export const ClearTableHistoriesGetHistoriesDocument = gql`
    query ClearTableHistoriesGetHistories($shopId: uuid!) {
  tableUser(
    where: {shopId: {_eq: $shopId}, deactivatedAt: {_is_null: false}, tableClearReason: {_is_null: false}}
    order_by: {deactivatedAt: desc}
  ) {
    id
    tableClearReason
    tableClearedClerkId
    table {
      name
    }
    tableClearedClerk {
      name
    }
    deactivatedAt
    taxIncludedPaidAmount
  }
}
    `;

/**
 * __useClearTableHistoriesGetHistoriesQuery__
 *
 * To run a query within a React component, call `useClearTableHistoriesGetHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClearTableHistoriesGetHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClearTableHistoriesGetHistoriesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useClearTableHistoriesGetHistoriesQuery(baseOptions: Apollo.QueryHookOptions<ClearTableHistoriesGetHistoriesQuery, ClearTableHistoriesGetHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClearTableHistoriesGetHistoriesQuery, ClearTableHistoriesGetHistoriesQueryVariables>(ClearTableHistoriesGetHistoriesDocument, options);
      }
export function useClearTableHistoriesGetHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClearTableHistoriesGetHistoriesQuery, ClearTableHistoriesGetHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClearTableHistoriesGetHistoriesQuery, ClearTableHistoriesGetHistoriesQueryVariables>(ClearTableHistoriesGetHistoriesDocument, options);
        }
export type ClearTableHistoriesGetHistoriesQueryHookResult = ReturnType<typeof useClearTableHistoriesGetHistoriesQuery>;
export type ClearTableHistoriesGetHistoriesLazyQueryHookResult = ReturnType<typeof useClearTableHistoriesGetHistoriesLazyQuery>;
export type ClearTableHistoriesGetHistoriesQueryResult = Apollo.QueryResult<ClearTableHistoriesGetHistoriesQuery, ClearTableHistoriesGetHistoriesQueryVariables>;