import React, { memo } from "react";
import { FormInstance } from "antd";

import { Form } from "components/antd/Form";

import { MediaNameField } from "./MediaNameField";
import {
  EditTecAggregationMediaMapFormValues,
  useEditTecAggregationMediaMapForm,
} from "./useEditTecAggregationMediaMapForm";

type Props = {
  form: FormInstance;
  tecAggregationMediaMap: EditTecAggregationMediaMapFormValues;
  onChange: (values: EditTecAggregationMediaMapFormValues) => void;
};

export const EditTecAggregationMediaMapForm = memo<Props>(
  ({ form, tecAggregationMediaMap, onChange }) => {
    const { initialValues, change } = useEditTecAggregationMediaMapForm(
      tecAggregationMediaMap,
      onChange,
    );

    return (
      <Form
        name="tecAggregationMediaMap"
        form={form}
        initialValues={initialValues}
        onValuesChange={change}
        layout="vertical"
      >
        <MediaNameField />
      </Form>
    );
  },
);
