import React, { memo } from "react";
import { Radio } from "antd";
import { displayTypes } from "models/displayType";

import { DisplayTypeEnum } from "types/graphql";

import { EditMenuFormItem } from "../../useEditMenuForm";

const options = Object.values(DisplayTypeEnum).map((displayType) => ({
  label: displayTypes[displayType],
  value: displayType,
}));

type Props = {
  categoryId: number;
  disabled?: boolean;
};

export const DisplayTypeField = memo<Props>(({ categoryId, disabled }) => (
  <EditMenuFormItem
    label="画像の表示サイズ"
    name={["displayTypes", categoryId.toString()]}
    rules={[{ required: true, message: "画像の表示サイズを入力してください" }]}
  >
    <Radio.Group options={options} optionType="button" disabled={disabled} />
  </EditMenuFormItem>
));
