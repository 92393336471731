import React, { useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useQueryParams } from "hooks/useQuery";
import { useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery } from "pages/TecAggregationMediaMaps/queries";
import { TecAggregationMediaMapTable } from "pages/TecAggregationMediaMaps/TecAggregationMediaMapTable";

import { EditTecAggregationMediaMapModal } from "./EditTecAggregationMediaMapModal";

export const TecAggregationMediaMaps = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const queryParams = useQueryParams();
  const aggregationNumber = queryParams.get("aggregationNumber");

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const { data, loading, error, refetch } =
    useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const tecAggregationMediaMaps = data?.tecAggregationMediaMap ?? [];

  return (
    <DashboardLayout title="掛売等設定一覧">
      <PageHeader title="掛売等設定一覧" />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <TecAggregationMediaMapTable
        tecAggregationMediaMaps={tecAggregationMediaMaps}
        loading={loading}
      />
      <Routes>
        <Route
          path=":id/edit"
          element={
            <EditTecAggregationMediaMapModal
              goBack={goBack}
              onUpdated={refetch}
              aggregationNumber={Number(aggregationNumber)}
            />
          }
        />
      </Routes>
    </DashboardLayout>
  );
};
