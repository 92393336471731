import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useShop } from "hooks/useShop";
import { EditTableForm } from "pages/EditTable/EditTableForm";
import {
  useEditTableGetTableAreasQuery,
  useEditTableGetTableQuery,
  useEditTableUpdateTableMutation,
} from "pages/EditTable/queries";
import { TableSetInput } from "types/graphql";

export const EditTable = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;
  const { id } = useParams<{ id: string }>();
  const tableId = Number(id);
  const navigate = useNavigate();

  const {
    data: getTableData,
    loading: loadingTable,
    error,
  } = useEditTableGetTableQuery(
    !Number.isNaN(tableId) ? { variables: { tableId } } : { skip: true },
  );
  const table = getTableData?.table?.[0];

  const { data: tableAreasData, loading: loadingTableAreas } = useEditTableGetTableAreasQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tableAreas = useMemo(() => tableAreasData?.tableArea ?? [], [tableAreasData?.tableArea]);

  const loading = loadingTable || loadingTableAreas;

  const [updateTableMutation, { loading: loadingUpdateTable }] = useEditTableUpdateTableMutation();

  const onSubmit = useCallback(
    async (table: TableSetInput) => {
      if (!Number.isNaN(tableId)) {
        try {
          await updateTableMutation({
            variables: { tableId, table },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [tableId, updateTableMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const onFormValidationError = useCallback(() => {
    message.error("編集の保存に失敗しました");
  }, []);

  return (
    <DashboardLayout
      title={table?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "エリア / テーブル" }],
      }}
    >
      <PageHeader title={table?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {table && tableAreas.length > 0 && (
        <EditTableForm
          tableAreas={tableAreas}
          table={table}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateTable}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
