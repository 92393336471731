import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox as AntdCheckbox } from "antd";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";

import { BusinessOperationHourTypeEnum } from "types/graphql";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

const CheckboxGroup = styled(AntdCheckbox.Group)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const Checkbox = styled(AntdCheckbox)`
  & {
    margin-left: 0;
  }
`;

type Props = {
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
};

export const VisitedBusinessOperationHoursFilter = memo<Props>(
  ({ shopBusinessOperationHourTypes }) => (
    <MessageDeliveryJobFormItem
      noStyle
      name="visitedBusinessOperationHoursFilterBusinessOperationHourTypes"
    >
      <CheckboxGroup>
        <Checkbox key="All" value="All">
          すべて
        </Checkbox>
        {shopBusinessOperationHourTypes.map((value) => (
          <Checkbox key={value} value={value}>
            {businessOperationHourTypeToWord[value]}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </MessageDeliveryJobFormItem>
  ),
);
