import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPikaichiOnSitePaymentDiscountTypeFormItem } from "../useEditPikaichiOnSitePaymentDiscountTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiNewariCdField = memo<Props>((props) => (
  <EditPikaichiOnSitePaymentDiscountTypeFormItem
    label="ぴかいち値割コード"
    name="newariCd"
    rules={[{ required: true, message: "値割コードを入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditPikaichiOnSitePaymentDiscountTypeFormItem>
));
