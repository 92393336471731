import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopMenuStockGetShop = gql`
    query EditShopMenuStockGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
  }
}
    `;
export const EditShopMenuStockGetShopMenu = gql`
    query EditShopMenuStockGetShopMenu($shopId: uuid!, $menuId: Int!) {
  shopMenu(
    where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}, _menuId: {_eq: $menuId}, menu: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    menu {
      menuId
      name
    }
    stock {
      id
      currentStockNum
      dailyStockNum
    }
  }
}
    `;
export const EditShopMenuStockUpdateStock = gql`
    mutation EditShopMenuStockUpdateStock($shopId: uuid!, $menuId: Int!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, _menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type EditShopMenuStockGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopMenuStockGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'companyId'>
  )> }
);

export type EditShopMenuStockGetShopMenuQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['Int'];
}>;


export type EditShopMenuStockGetShopMenuQuery = (
  { __typename?: 'query_root' }
  & { shopMenu: Array<(
    { __typename?: 'shopMenu' }
    & { menu: (
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'menuId' | 'name'>
    ), stock?: Types.Maybe<(
      { __typename?: 'stock' }
      & Pick<Types.Stock, 'id' | 'currentStockNum' | 'dailyStockNum'>
    )> }
  )> }
);

export type EditShopMenuStockUpdateStockMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['Int'];
  stockId: Types.Scalars['uuid'];
  stock: Types.StockInsertInput;
}>;


export type EditShopMenuStockUpdateStockMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stock_one?: Types.Maybe<(
    { __typename?: 'stock' }
    & Pick<Types.Stock, 'id'>
  )>, update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & { returning: Array<(
      { __typename?: 'shopMenu' }
      & { menuId: Types.ShopMenu['_menuId'] }
    )> }
  )> }
);


export const EditShopMenuStockGetShopDocument = gql`
    query EditShopMenuStockGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
  }
}
    `;

/**
 * __useEditShopMenuStockGetShopQuery__
 *
 * To run a query within a React component, call `useEditShopMenuStockGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuStockGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenuStockGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopMenuStockGetShopQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenuStockGetShopQuery, EditShopMenuStockGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenuStockGetShopQuery, EditShopMenuStockGetShopQueryVariables>(EditShopMenuStockGetShopDocument, options);
      }
export function useEditShopMenuStockGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenuStockGetShopQuery, EditShopMenuStockGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenuStockGetShopQuery, EditShopMenuStockGetShopQueryVariables>(EditShopMenuStockGetShopDocument, options);
        }
export type EditShopMenuStockGetShopQueryHookResult = ReturnType<typeof useEditShopMenuStockGetShopQuery>;
export type EditShopMenuStockGetShopLazyQueryHookResult = ReturnType<typeof useEditShopMenuStockGetShopLazyQuery>;
export type EditShopMenuStockGetShopQueryResult = Apollo.QueryResult<EditShopMenuStockGetShopQuery, EditShopMenuStockGetShopQueryVariables>;
export const EditShopMenuStockGetShopMenuDocument = gql`
    query EditShopMenuStockGetShopMenu($shopId: uuid!, $menuId: Int!) {
  shopMenu(
    where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}, _menuId: {_eq: $menuId}, menu: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    menu {
      menuId
      name
    }
    stock {
      id
      currentStockNum
      dailyStockNum
    }
  }
}
    `;

/**
 * __useEditShopMenuStockGetShopMenuQuery__
 *
 * To run a query within a React component, call `useEditShopMenuStockGetShopMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuStockGetShopMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenuStockGetShopMenuQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditShopMenuStockGetShopMenuQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenuStockGetShopMenuQuery, EditShopMenuStockGetShopMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenuStockGetShopMenuQuery, EditShopMenuStockGetShopMenuQueryVariables>(EditShopMenuStockGetShopMenuDocument, options);
      }
export function useEditShopMenuStockGetShopMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenuStockGetShopMenuQuery, EditShopMenuStockGetShopMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenuStockGetShopMenuQuery, EditShopMenuStockGetShopMenuQueryVariables>(EditShopMenuStockGetShopMenuDocument, options);
        }
export type EditShopMenuStockGetShopMenuQueryHookResult = ReturnType<typeof useEditShopMenuStockGetShopMenuQuery>;
export type EditShopMenuStockGetShopMenuLazyQueryHookResult = ReturnType<typeof useEditShopMenuStockGetShopMenuLazyQuery>;
export type EditShopMenuStockGetShopMenuQueryResult = Apollo.QueryResult<EditShopMenuStockGetShopMenuQuery, EditShopMenuStockGetShopMenuQueryVariables>;
export const EditShopMenuStockUpdateStockDocument = gql`
    mutation EditShopMenuStockUpdateStock($shopId: uuid!, $menuId: Int!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, _menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type EditShopMenuStockUpdateStockMutationFn = Apollo.MutationFunction<EditShopMenuStockUpdateStockMutation, EditShopMenuStockUpdateStockMutationVariables>;

/**
 * __useEditShopMenuStockUpdateStockMutation__
 *
 * To run a mutation, you first call `useEditShopMenuStockUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenuStockUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMenuStockUpdateStockMutation, { data, loading, error }] = useEditShopMenuStockUpdateStockMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      stockId: // value for 'stockId'
 *      stock: // value for 'stock'
 *   },
 * });
 */
export function useEditShopMenuStockUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<EditShopMenuStockUpdateStockMutation, EditShopMenuStockUpdateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopMenuStockUpdateStockMutation, EditShopMenuStockUpdateStockMutationVariables>(EditShopMenuStockUpdateStockDocument, options);
      }
export type EditShopMenuStockUpdateStockMutationHookResult = ReturnType<typeof useEditShopMenuStockUpdateStockMutation>;
export type EditShopMenuStockUpdateStockMutationResult = Apollo.MutationResult<EditShopMenuStockUpdateStockMutation>;
export type EditShopMenuStockUpdateStockMutationOptions = Apollo.BaseMutationOptions<EditShopMenuStockUpdateStockMutation, EditShopMenuStockUpdateStockMutationVariables>;