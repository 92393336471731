import React, { memo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Divider, Modal, Tag } from "antd";

import { message } from "components/antd/message";
import { logger } from "libs/logger";

import { EditKitchenRolesFormValues } from "./EditKitchenRolesForm/useEditKitchenRolesForm";
import { EditKitchenRolesForm } from "./EditKitchenRolesForm";
import {
  useEditOptionDealersGetKitchenRolesQuery,
  useEditOptionDealersUpdateKitchenRolesMutation,
} from "./queries";

const Header = styled.div``;

const Heading = styled.h2`
  font-size: 16px;
  font-weight: 500;
`;

const HelperTextContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;

type Props = {
  option: {
    id: string;
    name: string;
  };
  goBack: () => void;
  onUpdated: () => void;
};

export const EditOptionKitchenRolesModal = memo<Props>(({ option, goBack, onUpdated }) => {
  const { shopId } = useParams<{ shopId: string }>();
  const optionId = option.id;

  const {
    data: getKitchenRolesData,
    error,
    refetch,
  } = useEditOptionDealersGetKitchenRolesQuery(
    shopId ? { variables: { shopId, optionId } } : { skip: true },
  );

  const shop = getKitchenRolesData?.shop;
  const roles = shop?.roles ?? [];
  const shopOptionKitchenRoles = shop?.shopOptionKitchenRoles ?? [];

  const [updateKitchenRoles, { loading }] = useEditOptionDealersUpdateKitchenRolesMutation();

  const onOk = useCallback(
    async (formValues: EditKitchenRolesFormValues | null) => {
      if (formValues && shopId) {
        try {
          const { kitchenRoles: enabledKitchenRoles } = formValues;

          const { data } = await updateKitchenRoles({
            variables: {
              shopId,
              optionId,
              objects: enabledKitchenRoles.map((roleId) => ({
                shopId,
                optionId,
                kitchenRoleId: roleId,
              })),
            },
          });

          const deletedRowsCount = data?.delete_shopOptionKitchenRole?.affected_rows ?? 0;

          if (shopOptionKitchenRoles.length !== deletedRowsCount) {
            logger.error("Unexpected deleted ShopOptionKitchenRole rows.", {
              shopId,
              shopOptionKitchenRolesCount: shopOptionKitchenRoles.length,
              deletedRowsCount,
            });
          }

          refetch();
          onUpdated();

          message.success("編集しました");
        } catch (err) {
          message.error("編集に失敗しました");
        }
      }

      goBack();
    },
    [
      shopId,
      goBack,
      updateKitchenRoles,
      optionId,
      shopOptionKitchenRoles.length,
      refetch,
      onUpdated,
    ],
  );
  const onCancel = goBack;

  return (
    <Modal
      title={
        <Header>
          <Heading>{option.name}の出力先設定</Heading>

          <HelperTextContainer>
            変更対象店舗: <Tag color="blue">{shop?.name}</Tag>
          </HelperTextContainer>

          <Divider />
        </Header>
      }
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[]}
    >
      {roles.length === 0 && (
        <Alert
          message={
            <>
              キッチンプリンターがありません。<Link to="/role">キッチンプリンター一覧</Link>
              より、店舗に追加してください。
            </>
          }
          type="info"
        />
      )}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {roles.length > 0 && (
        <EditKitchenRolesForm
          kitchenRoles={roles}
          shopOptionKitchenRoles={shopOptionKitchenRoles}
          loading={loading}
          onCancel={onCancel}
          onSubmit={onOk}
        />
      )}
    </Modal>
  );
});
