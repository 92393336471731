import dayjs from "dayjs";
import { addHours, getHours } from "util/duration";
import { normalizeHoursAndMinutes } from "util/time";
import { isNotNull } from "util/type/primitive";

import { EditOrderableTimeFormValues } from "pages/EditOrderableTime/EditOrderableTimeForm/useEditOrderableTimeForm";

export const OrderableTimeTermDayWeek = {
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  Holiday: "Holiday",
} as const;

export type OrderableTimeTermDayWeekType =
  typeof OrderableTimeTermDayWeek[keyof typeof OrderableTimeTermDayWeek];

export const OrderableTimeTermDayWeekToWord: Record<OrderableTimeTermDayWeekType, string> = {
  Mon: "月曜日",
  Tue: "火曜日",
  Wed: "水曜日",
  Thu: "木曜日",
  Fri: "金曜日",
  Sat: "土曜日",
  Sun: "日曜日",
  Holiday: "祝日",
};

export const isOrderableTimeTermDayWeek = (
  arg: string | undefined,
): arg is OrderableTimeTermDayWeekType => {
  if (!arg) return false;

  return arg in OrderableTimeTermDayWeekToWord;
};

export const formatOrderableTimeTermDayWeek = (arg: string) => {
  if (isOrderableTimeTermDayWeek(arg)) {
    return OrderableTimeTermDayWeekToWord[arg];
  }

  return "";
};

export const formatOrderableTimeTermTime = (time: string) => {
  const [hour, minute] = time.split(":");

  return `${hour}:${minute}`;
};

export const findSortKeyByDayWeek = (dayWeek: OrderableTimeTermDayWeekType) =>
  Object.keys(OrderableTimeTermDayWeek).findIndex((v) => v === dayWeek);

/**
 * changeDateTime を考慮し，比較用の時刻を返す
 * 時刻が changeDateTime であるとき，一日の始めと一日の終わりの2つの解釈がある
 * isBaseEnd が false であれば前前，true であれば後者の解釈で結果を返す
 */
export const getTimeForCompare = (
  time: dayjs.Dayjs,
  changeDateTime: dayjs.Dayjs,
  options?: {
    isBaseEnd?: boolean;
  },
) => {
  const isBefore = options?.isBaseEnd
    ? time.isSameOrBefore(changeDateTime)
    : time.isBefore(changeDateTime);
  return isBefore ? dayjs(time).add(24, "hours") : time;
};

export const isBetweenDuration = ({
  time,
  start,
  end,
  changeDateTime,
  isBaseEnd,
}: {
  time: dayjs.Dayjs;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: dayjs.Dayjs;
  isBaseEnd?: boolean;
}) => {
  const normalizedTime = getTimeForCompare(time, changeDateTime, { isBaseEnd });
  const normalizedStart = getTimeForCompare(start, changeDateTime);
  const normalizedEnd = getTimeForCompare(end, changeDateTime, { isBaseEnd: true });

  return !normalizedTime.isBefore(normalizedStart) && normalizedTime.isSameOrBefore(normalizedEnd);
};

export const extractNotOverlappedDuration = ({
  duration,
  avoidDuration,
  changeDateTime,
}: {
  duration: { start: dayjs.Dayjs; end: dayjs.Dayjs };
  avoidDuration: { start: dayjs.Dayjs; end: dayjs.Dayjs };
  changeDateTime: dayjs.Dayjs;
}) => {
  const isStartTimeInAvoidDuration = isBetweenDuration({
    time: duration.start,
    start: avoidDuration.start,
    end: avoidDuration.end,
    changeDateTime,
  });
  const isEndTimeInAvoidDuration = isBetweenDuration({
    time: duration.end,
    start: avoidDuration.start,
    end: avoidDuration.end,
    changeDateTime,
    isBaseEnd: true,
  });
  if (isStartTimeInAvoidDuration && isEndTimeInAvoidDuration) {
    // avoidDuration に duration が覆われているケース
    return undefined;
  } else if (isStartTimeInAvoidDuration) {
    // start が avoidDuration にあり，end は重なっていないケース
    return {
      start: avoidDuration.end,
      end: duration.end,
    };
  } else if (isEndTimeInAvoidDuration) {
    // end が avoidDuration にあり，start は重なっていないケース
    return {
      start: duration.start,
      end: avoidDuration.start,
    };
  }
  // duration に avoidDuration が覆われているケース
  const isDurationCoverAvoidDuration = isBetweenDuration({
    time: avoidDuration.start,
    start: duration.start,
    end: duration.end,
    changeDateTime,
  });
  return isDurationCoverAvoidDuration
    ? {
        start: duration.start,
        end: avoidDuration.start,
      }
    : // 重なっていないケース
      {
        ...duration,
      };
};

export const isValidOrderableTimeDuration = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const orderableTimeStart = getTimeForCompare(startTime, baseTime);
  const orderableTimeEnd = getTimeForCompare(endTime, baseTime);

  return orderableTimeStart.isBefore(orderableTimeEnd);
};

export const isNotOverlappedOrderableTimeDurations = ({
  terms,
  changeDateTime,
}: {
  terms: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }[];
  changeDateTime: string;
}): boolean => {
  const baseTime = getHours(changeDateTime);
  const termsSortedByStartTime = terms.sort((t1, t2) => (t1.start.isBefore(t2.start) ? -1 : 1));
  for (let idx = 0; idx < termsSortedByStartTime.length; idx += 1) {
    const term = termsSortedByStartTime[idx];
    if (!term || idx + 1 === termsSortedByStartTime.length) {
      continue;
    }
    const searchTerms = termsSortedByStartTime.slice(idx + 1);
    const isOverlapped = searchTerms.some(({ start, end }) => {
      const notOverlappedDuration = extractNotOverlappedDuration({
        duration: { start, end },
        avoidDuration: term,
        changeDateTime: baseTime,
      });
      // 完全に重なると undefined を返す = 重なっている
      if (!notOverlappedDuration) return true;
      // overlap を除くと start か end が変化 = 重なっている
      return !notOverlappedDuration.start.isSame(start) || !notOverlappedDuration.end.isSame(end);
    });
    if (isOverlapped) {
      return false;
    }
  }
  return true;
};

export const isValidOrderableTimeDurationTerms = ({
  terms: _terms,
  changeDateTime,
}: {
  terms: EditOrderableTimeFormValues["terms"];
  changeDateTime: string;
}) => {
  const terms = _terms.filter(isNotNull);
  for (const dayWeek of Object.keys(OrderableTimeTermDayWeek)) {
    const termsOnDayWeek = terms.filter((t) => t?.dayWeek.value === dayWeek);
    const isValid = isNotOverlappedOrderableTimeDurations({
      terms: termsOnDayWeek,
      changeDateTime,
    });
    if (!isValid) {
      return false;
    }
  }
  return true;
};

export const formatOrderableTimeWithChangeDateTime = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const orderableTimeStart = startTime.isBefore(baseTime)
    ? addHours(startTime.format("HH:mm"), 24)
    : startTime.format("HH:mm");
  const orderableTimeEnd = endTime.isSameOrBefore(baseTime)
    ? addHours(endTime.format("HH:mm"), 24)
    : endTime.format("HH:mm");

  return { start: orderableTimeStart, end: orderableTimeEnd };
};
