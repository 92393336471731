import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditOnSitePaymentDetailTypePriorityList } from "pages/EditOnSitePaymentDetailTypePriorities/EditOnSitePaymentDetailTypeList";
import {
  useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery,
  useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation,
} from "pages/EditOnSitePaymentDetailTypePriorities/queries";
import { OnSitePaymentDetailType } from "pages/EditOnSitePaymentDetailTypePriorities/types";
import { OnSitePaymentDetailTypeInsertInput } from "types/graphql";

export const EditOnSitePaymentDetailTypePriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getOnSitePaymentDetailTypesData,
    loading: loadingOnSitePaymentDetailTypes,
    refetch: refetchOnSitePaymentDetailTypes,
    error,
  } = useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDetailTypes = getOnSitePaymentDetailTypesData?.onSitePaymentDetailType ?? [];

  const [
    insertOnSitePaymentDetailTypesMutation,
    { loading: loadingInsertOnSitePaymentDetailTypes },
  ] = useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation();

  const onUpdate = useCallback(
    async (onSitePaymentDetailTypes: OnSitePaymentDetailType[]) => {
      if (!companyId) return;
      const onSitePaymentDetailTypeInputs: OnSitePaymentDetailTypeInsertInput[] =
        onSitePaymentDetailTypes.map((detailType) => ({
          companyId,
          id: detailType.id,
          label: detailType.label,
          priority: detailType.priority,
          type: detailType.type,
          onSitePaymentDetailTypeCategory: detailType.onSitePaymentDetailTypeCategory,
        }));

      try {
        await insertOnSitePaymentDetailTypesMutation({
          variables: { onSitePaymentDetailTypes: onSitePaymentDetailTypeInputs },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchOnSitePaymentDetailTypes();
    },
    [companyId, insertOnSitePaymentDetailTypesMutation, refetchOnSitePaymentDetailTypes],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout title="支払方法の表示順を編集">
      <PageHeader title="支払方法の表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditOnSitePaymentDetailTypePriorityList
          loading={loadingOnSitePaymentDetailTypes || loadingInsertOnSitePaymentDetailTypes}
          onSitePaymentDetailTypes={onSitePaymentDetailTypes}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
