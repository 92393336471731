import { MembershipRankEnum } from "types/graphql";

export const membershipRanks = {
  [MembershipRankEnum.Regular]: "レギュラー",
  [MembershipRankEnum.Silver]: "シルバー",
  [MembershipRankEnum.Gold]: "ゴールド",
  [MembershipRankEnum.Platinum]: "プラチナ",
} as const;

export const membershipRanksDisplayOrder: Record<MembershipRankEnum, number> = {
  [MembershipRankEnum.Regular]: 1,
  [MembershipRankEnum.Silver]: 2,
  [MembershipRankEnum.Gold]: 3,
  [MembershipRankEnum.Platinum]: 4,
} as const;

export type MembershipRank = typeof membershipRanks[keyof typeof membershipRanks];
