import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

const SummaryPanel = styled.div<{ primary: boolean; flexGrow: number }>`
  background-color: ${({ primary }) => (primary ? "#F37D58" : colors.BackGround.Default)};
  color: ${({ primary }) => (primary ? colors.BackGround.Default : "black")};
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.08);
  flex-grow: ${({ flexGrow }) => flexGrow};
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.08);
`;

const Title = styled.span<{ primary: boolean }>`
  color: ${({ primary }) => (primary ? "white" : "black")};
  font-size: 14px;
  font-weight: bold;
`;
const ValueWrapper = styled.span<{ primary: boolean }>`
  color: ${({ primary }) => (primary ? "white" : "black")};
  font-size: 32px;
  font-weight: 700;
  text-align: right;
`;

const Unit = styled.span<{ primary: boolean }>`
  color: ${({ primary }) => (primary ? "white" : "black")};
  font-size: 14px;
  font-weight: bold;
`;

type Props = {
  title: string;
  value: number | string;
  unit: string;
  primary?: boolean;
  flexGrow: number;
};

export const SummaryRowPanel = ({ title, value, unit, primary, flexGrow }: Props) => (
  <SummaryPanel primary={Boolean(primary)} flexGrow={flexGrow}>
    <Title primary={Boolean(primary)}>{title}</Title>
    <Spacer size={10} />
    <ValueWrapper primary={Boolean(primary)}>
      {value}
      <Unit primary={Boolean(primary)}> {unit}</Unit>
    </ValueWrapper>
  </SummaryPanel>
);
