import React, { memo } from "react";
import { Menu as AntdMenu } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { useConstateNavigationDrawer } from "components/Layout/DashboardLayout/NavigationDrawer";
import { CompanyMenu } from "components/Layout/DashboardLayout/NavigationDrawer/CompanyMenu";
import { CustomerRelationshipManagementMenu } from "components/Layout/DashboardLayout/NavigationDrawer/CustomerRelationshipManagementMenu";
import { DashboardMenu } from "components/Layout/DashboardLayout/NavigationDrawer/DashboardMenu";
import { HeadquartersMenu } from "components/Layout/DashboardLayout/NavigationDrawer/HeadquartersMenu";
import { MenuMasterMenu } from "components/Layout/DashboardLayout/NavigationDrawer/MenuMasterMenu";
import { ServiceAdminMenu } from "components/Layout/DashboardLayout/NavigationDrawer/ServiceAdminMenu";
import { SettingMenu } from "components/Layout/DashboardLayout/NavigationDrawer/SettingMenu";
import { ShopMenu } from "components/Layout/DashboardLayout/NavigationDrawer/ShopMenu";
import { StatsMenu } from "components/Layout/DashboardLayout/NavigationDrawer/StatsMenu";
import { Company, Shop } from "components/Layout/DashboardLayout/types";

import { MenuItem } from "../MenuItem";

type Props = {
  company: Company | undefined;
  shop: Shop | undefined;
  onSelect: () => void;
};

export const Menu = memo<Props>(({ company, shop, onSelect }) => {
  const { selectedKey, openKeys, updateSelectedKey, updateOpenKeys } =
    useConstateNavigationDrawer();

  return (
    <AntdMenu
      mode="inline"
      defaultOpenKeys={openKeys}
      onOpenChange={updateOpenKeys}
      selectedKeys={[selectedKey]}
      onClick={updateSelectedKey}
      onSelect={onSelect}
    >
      <DashboardMenu company={company} />
      <MenuItem
        route="currentSales"
        to="/currentSales"
        icon={<InfoCircleOutlined />}
        text="売上速報"
      />
      <StatsMenu shop={shop} company={company} />
      <HeadquartersMenu company={company} />
      <MenuMasterMenu />
      <CompanyMenu company={company} />
      <ShopMenu company={company} />
      <CustomerRelationshipManagementMenu company={company} />
      <SettingMenu />
      <ServiceAdminMenu company={company} />
    </AntdMenu>
  );
});
