import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";

import { Choice } from "../../types";

type Props = {
  loading?: boolean;
  menuId: number;
  optionId: number;
  choices: Choice[];
};

export const ChoiceTable = memo<Props>(({ loading, menuId, optionId, choices }) => {
  const columns: ColumnsType<Choice> = [
    { title: "選択肢名", dataIndex: "name", key: "name", fixed: "left", width: 150 },
    {
      title: "メニューコード",
      align: "left",
      width: 130,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.menuCode;
      },
    },
    {
      title: "メニュー名",
      align: "left",
      width: 150,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.name;
      },
    },
    {
      title: "部門コード",
      align: "left",
      width: 100,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.dpCode;
      },
    },
    {
      title: "部門名",
      align: "left",
      width: 150,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.dpName;
      },
    },
    {
      title: "分類コード",
      align: "left",
      width: 100,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.gpCode;
      },
    },
    {
      title: "分類名",
      align: "left",
      width: 150,
      render(_: string, choice: Choice) {
        return choice.tecAggregationMenu?.gpName;
      },
    },
    {
      title: "",
      width: 60,
      align: "center",
      fixed: "right",
      render(_: string, { choiceId }: Choice) {
        return (
          <IconLink to={`/tec/menu/${menuId}/option/${optionId}/choice/${choiceId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="choiceId"
      columns={columns}
      dataSource={choices}
      loading={loading}
      bordered
      pagination={false}
    />
  );
});
