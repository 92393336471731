import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddDishUpSlipGroupPlanModalGetPlans = gql`
    query AddDishUpSlipGroupPlanModalGetPlans($shopId: uuid!) {
  plan(
    where: {shopPlans: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    id
    planName
    planId
  }
}
    `;
export const AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlan = gql`
    mutation AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlan($dishUpSlipGroupShopPlans: [dishUpSlipGroupShopPlans_insert_input!]!) {
  insert_dishUpSlipGroupShopPlans(
    objects: $dishUpSlipGroupShopPlans
    on_conflict: {constraint: dishUpSlipGroupShopPlans_shopId_planId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      planId: _planId
    }
  }
}
    `;
export type AddDishUpSlipGroupPlanModalGetPlansQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddDishUpSlipGroupPlanModalGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planName' | 'planId'>
  )> }
);

export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationVariables = Types.Exact<{
  dishUpSlipGroupShopPlans: Array<Types.DishUpSlipGroupShopPlansInsertInput> | Types.DishUpSlipGroupShopPlansInsertInput;
}>;


export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dishUpSlipGroupShopPlans?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupShopPlans_mutation_response' }
    & Pick<Types.DishUpSlipGroupShopPlansMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dishUpSlipGroupShopPlans' }
      & Pick<Types.DishUpSlipGroupShopPlans, 'dishUpSlipGroupId'>
      & { planId: Types.DishUpSlipGroupShopPlans['_planId'] }
    )> }
  )> }
);


export const AddDishUpSlipGroupPlanModalGetPlansDocument = gql`
    query AddDishUpSlipGroupPlanModalGetPlans($shopId: uuid!) {
  plan(
    where: {shopPlans: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {planId: asc}]
  ) {
    id
    planName
    planId
  }
}
    `;

/**
 * __useAddDishUpSlipGroupPlanModalGetPlansQuery__
 *
 * To run a query within a React component, call `useAddDishUpSlipGroupPlanModalGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpSlipGroupPlanModalGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDishUpSlipGroupPlanModalGetPlansQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddDishUpSlipGroupPlanModalGetPlansQuery(baseOptions: Apollo.QueryHookOptions<AddDishUpSlipGroupPlanModalGetPlansQuery, AddDishUpSlipGroupPlanModalGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDishUpSlipGroupPlanModalGetPlansQuery, AddDishUpSlipGroupPlanModalGetPlansQueryVariables>(AddDishUpSlipGroupPlanModalGetPlansDocument, options);
      }
export function useAddDishUpSlipGroupPlanModalGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDishUpSlipGroupPlanModalGetPlansQuery, AddDishUpSlipGroupPlanModalGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDishUpSlipGroupPlanModalGetPlansQuery, AddDishUpSlipGroupPlanModalGetPlansQueryVariables>(AddDishUpSlipGroupPlanModalGetPlansDocument, options);
        }
export type AddDishUpSlipGroupPlanModalGetPlansQueryHookResult = ReturnType<typeof useAddDishUpSlipGroupPlanModalGetPlansQuery>;
export type AddDishUpSlipGroupPlanModalGetPlansLazyQueryHookResult = ReturnType<typeof useAddDishUpSlipGroupPlanModalGetPlansLazyQuery>;
export type AddDishUpSlipGroupPlanModalGetPlansQueryResult = Apollo.QueryResult<AddDishUpSlipGroupPlanModalGetPlansQuery, AddDishUpSlipGroupPlanModalGetPlansQueryVariables>;
export const AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanDocument = gql`
    mutation AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlan($dishUpSlipGroupShopPlans: [dishUpSlipGroupShopPlans_insert_input!]!) {
  insert_dishUpSlipGroupShopPlans(
    objects: $dishUpSlipGroupShopPlans
    on_conflict: {constraint: dishUpSlipGroupShopPlans_shopId_planId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      planId: _planId
    }
  }
}
    `;
export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationFn = Apollo.MutationFunction<AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation, AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationVariables>;

/**
 * __useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation__
 *
 * To run a mutation, you first call `useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation, { data, loading, error }] = useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation({
 *   variables: {
 *      dishUpSlipGroupShopPlans: // value for 'dishUpSlipGroupShopPlans'
 *   },
 * });
 */
export function useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation, AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation, AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationVariables>(AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanDocument, options);
      }
export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationHookResult = ReturnType<typeof useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation>;
export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationResult = Apollo.MutationResult<AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation>;
export type AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationOptions = Apollo.BaseMutationOptions<AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation, AddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutationVariables>;