import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";

type Props = { disabled?: boolean } & Omit<FormItemProps, "children" | "name">;

export const GmoTerminalIdField = memo<Props>(({ disabled, ...props }) => (
  <EditDiniiPayConfigFormItem
    label="端末ID"
    name={["terminalPaymentConfig", "gmoTerminalId"]}
    rules={[
      { required: !disabled, message: "端末IDを入力してください" },
      { pattern: /^\d{13}$/, message: "端末IDは13桁の数字である必要があります" },
    ]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditDiniiPayConfigFormItem>
));
