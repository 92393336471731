import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditWinboardInflowSourceTagFormItem } from "../useEditWinboardInflowSourceTagForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CodeField = memo<Props>((props) => (
  <EditWinboardInflowSourceTagFormItem
    label="媒体コード"
    name="code"
    rules={[{ required: true, message: "媒体コードを入力してください" }]}
    {...props}
  >
    <Input />
  </EditWinboardInflowSourceTagFormItem>
));
