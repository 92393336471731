import React, { memo } from "react";
import styled from "styled-components";
import { Avatar } from "antd";

import AccountNoImageIcon from "assets/account_no_image.svg";

import { MessageFormValue } from "../../types";
import { LineOfficialAccountFragment } from "../queries";

import { Message } from "./Message";

const Wrapper = styled.div`
  height: 100%;
  padding: 8px 24px 8px 8px;
  display: flex;
  column-gap: 12px;
  background-image: url("/line_room.png");
`;

const Messages = styled.ul`
  padding: 0;
  margin: 0;
`;

const MessageListItem = styled.li`
  list-style: none;

  & + & {
    margin-top: 8px;
  }
`;

type Props = {
  lineOfficialAccount: LineOfficialAccountFragment;
  messages: MessageFormValue[];
};

export const RoomPanel = memo(({ lineOfficialAccount, messages }: Props) => (
  <Wrapper>
    {messages.length > 0 ? (
      <>
        <div>
          <Avatar size={40} src={lineOfficialAccount.pictureUrl ?? <AccountNoImageIcon />} />
        </div>
        <Messages>
          {messages.map((message, index) => (
            <MessageListItem key={index}>
              <Message message={message} index={index} />
            </MessageListItem>
          ))}
        </Messages>
      </>
    ) : null}
  </Wrapper>
));
