import React, { memo } from "react";
import styled from "styled-components";

import { Loading as Spin } from "components/Loading";
import { grey } from "constants/colors";

const Content = memo<Props>(({ loading: _, ...props }) => <div {...props} />);

const Wrapper = styled(Content)<Props>`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #001529;
  font-size: 64px;
  color: ${grey[0]};
  transition: opacity ease-out 0.2s;
  opacity: ${({ loading }) => (loading ? "1" : "0")};
  pointer-events: none;
  z-index: 1;
`;

type Props = {
  loading: boolean;
  children: React.ReactNode;
};

export const Loading = memo<Props>(({ loading, children }) => (
  <>
    <Wrapper loading={loading}>
      <Spin size="large" />
    </Wrapper>
    {loading ? null : children}
  </>
));
