import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveryJobsGetMessageDeliveryJobs = gql`
    query MessageDeliveryJobsGetMessageDeliveryJobs($companyId: uuid!) {
  messageDeliveryJob(
    where: {messageDelivery: {companyId: {_eq: $companyId}}}
    order_by: {executedAt: desc}
  ) {
    id
    executedAt
    targetLineMessagingCount
    succeededLineMessagingCount
    messageDelivery {
      id
      scope
      name
      isSuspended
      deliverAt
      repeatDeliveryTime
      customerListId
      customerSegmentId
    }
    messageDeliveryShopJobs(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      errorType
    }
  }
}
    `;
export type MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobsGetMessageDeliveryJobsQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryJob: Array<(
    { __typename?: 'messageDeliveryJob' }
    & Pick<Types.MessageDeliveryJob, 'id' | 'executedAt' | 'targetLineMessagingCount' | 'succeededLineMessagingCount'>
    & { messageDelivery: (
      { __typename?: 'messageDelivery' }
      & Pick<Types.MessageDelivery, 'id' | 'scope' | 'name' | 'isSuspended' | 'deliverAt' | 'repeatDeliveryTime' | 'customerListId' | 'customerSegmentId'>
    ), messageDeliveryShopJobs: Array<(
      { __typename?: 'messageDeliveryShopJob' }
      & Pick<Types.MessageDeliveryShopJob, 'id' | 'errorType'>
    )> }
  )> }
);


export const MessageDeliveryJobsGetMessageDeliveryJobsDocument = gql`
    query MessageDeliveryJobsGetMessageDeliveryJobs($companyId: uuid!) {
  messageDeliveryJob(
    where: {messageDelivery: {companyId: {_eq: $companyId}}}
    order_by: {executedAt: desc}
  ) {
    id
    executedAt
    targetLineMessagingCount
    succeededLineMessagingCount
    messageDelivery {
      id
      scope
      name
      isSuspended
      deliverAt
      repeatDeliveryTime
      customerListId
      customerSegmentId
    }
    messageDeliveryShopJobs(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      errorType
    }
  }
}
    `;

/**
 * __useMessageDeliveryJobsGetMessageDeliveryJobsQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobsGetMessageDeliveryJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobsGetMessageDeliveryJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobsGetMessageDeliveryJobsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobsGetMessageDeliveryJobsQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobsGetMessageDeliveryJobsQuery, MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobsGetMessageDeliveryJobsQuery, MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables>(MessageDeliveryJobsGetMessageDeliveryJobsDocument, options);
      }
export function useMessageDeliveryJobsGetMessageDeliveryJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobsGetMessageDeliveryJobsQuery, MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobsGetMessageDeliveryJobsQuery, MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables>(MessageDeliveryJobsGetMessageDeliveryJobsDocument, options);
        }
export type MessageDeliveryJobsGetMessageDeliveryJobsQueryHookResult = ReturnType<typeof useMessageDeliveryJobsGetMessageDeliveryJobsQuery>;
export type MessageDeliveryJobsGetMessageDeliveryJobsLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobsGetMessageDeliveryJobsLazyQuery>;
export type MessageDeliveryJobsGetMessageDeliveryJobsQueryResult = Apollo.QueryResult<MessageDeliveryJobsGetMessageDeliveryJobsQuery, MessageDeliveryJobsGetMessageDeliveryJobsQueryVariables>;