import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TableNameField } from "pages/AddTable/AddTableForm/TableNameField/index";
import { useAddTableForm } from "pages/AddTable/AddTableForm/useAddTableForm";
import { TableArea, TableInsertInput } from "types/graphql";

import { TableAreaSelectField } from "./TableAreaSelectField";

type Props = {
  tableAreas: Pick<TableArea, "id" | "name">[];
  onSubmit: (table: TableInsertInput) => Promise<void>;
  onFormValidationError: (error: unknown) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddTableForm = memo<Props>(
  ({ tableAreas, onClose, onSubmit, onFormValidationError, loading }) => {
    const { form, initialValues, submit } = useAddTableForm(onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError(e);
      }
    }, [onFormValidationError, submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="table" form={form} initialValues={initialValues} layout="vertical">
            <TableNameField />

            <TableAreaSelectField tableAreas={tableAreas} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
