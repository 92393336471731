import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/EditPlanGroup/EditPlanGroupForm/NameField";
import { useEditPlanGroupForm } from "pages/EditPlanGroup/EditPlanGroupForm/useEditPlanGroupForm";
import { PlanGroup } from "pages/EditPlanGroup/types";
import { PlanGroupSetInput } from "types/graphql";

type Props = {
  planGroup: PlanGroup;
  onSubmit: (plan: PlanGroupSetInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPlanGroupForm = memo<Props>(({ planGroup, onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useEditPlanGroupForm(planGroup, onSubmit);

  const handleSubmit = useCallback(() => {
    submit();
  }, [submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="planGroup" form={form} initialValues={initialValues} layout="vertical">
          <NameField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
