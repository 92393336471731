import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Category } from "pages/PlanCategories/types";

import { AddCategoryFormItem } from "../useAddCategoryForm";

const StyledCheckbox = styled(Checkbox)`
  display: flex;

  &:not(:first-child) {
    margin-left: 0px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: inline-block;
`;

type Props = {
  categories: Category[];
} & Omit<FormItemProps, "children" | "name">;

export const CategoriesField = memo<Props>(({ categories, ...props }) => (
  <AddCategoryFormItem name="categories" {...props}>
    <CheckboxGroup>
      {categories.map(({ categoryId, name, shopSideName }) => (
        <StyledCheckbox key={categoryId} value={categoryId}>
          {shopSideName || name}
        </StyledCheckbox>
      ))}
    </CheckboxGroup>
  </AddCategoryFormItem>
));
