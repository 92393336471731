import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { CategoryHeader } from "components/PageHeader/CategoryHeader";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

import { EditCategoryTranslationsForm } from "./EditCategoryTranslationsForm";
import {
  useEditCategoryTranslationsGetCategoryQuery,
  useEditCategoryTranslationsUpdateCategoryTranslationsMutation,
} from "./queries";

export const EditCategoryTranslations = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { id } = useParams<{ id: string }>();
  const categoryId = Number(id);
  const navigate = useNavigate();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getCategoryData,
    loading: loadingCategory,
    error: errorGetCategoryData,
    refetch: refetchGetCategoryData,
  } = useEditCategoryTranslationsGetCategoryQuery(
    !Number.isNaN(categoryId) ? { variables: { categoryId } } : { skip: true },
  );
  const category = useMemo(() => getCategoryData?.category?.[0], [getCategoryData?.category]);
  const translations = useMemo(() => category?.translations ?? [], [category?.translations]);

  const [
    updateCategoryTranslationsMutation,
    {
      loading: loadingUpdateCategoryTranslationsMutation,
      error: errorUpdateCategoryTranslationsMutation,
    },
  ] = useEditCategoryTranslationsUpdateCategoryTranslationsMutation();

  const [
    { loading: loadingUpdateCategoryTranslations, error: errorUpdateCategoryTranslations },
    updateCategoryTranslations,
  ] = useAsyncFn(
    async ({ categoryId, companyId, nameSources }) => {
      await updateCategoryTranslationsMutation({
        variables: {
          input: {
            companyId,
            categoryId,
            nameSources,
          },
        },
      });

      message.success("編集を保存しました");

      goBack();

      refetchGetCategoryData();
    },
    [updateCategoryTranslationsMutation, goBack, refetchGetCategoryData],
  );

  const showErrorMessage = useCallback(() => {
    message.error("編集に失敗しました");
  }, []);

  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  const loading =
    loadingCategory ||
    loadingUpdateCategoryTranslations ||
    loadingUpdateCategoryTranslationsMutation;
  const error =
    errorGetCategoryData ||
    errorUpdateCategoryTranslations ||
    errorUpdateCategoryTranslationsMutation;

  return (
    <DashboardLayout
      title={category?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "カテゴリ" }],
      }}
    >
      <CategoryHeader
        category={category ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {category && (
        <EditCategoryTranslationsForm
          category={category}
          translations={translations}
          onSubmit={updateCategoryTranslations}
          onClose={goBack}
          onFormValidationError={showErrorMessage}
        />
      )}
    </DashboardLayout>
  );
};
