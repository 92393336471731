import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlagEnum } from "types/graphql";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const EbicaMenu = memo<Props>(({ company, ...props }) => {
  const { isFeatureAvailable } = useFeatureFlag();

  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const shouldShowEbica = isFeatureAvailable({ name: FeatureFlagEnum.Ebica, shopIds });

  return (
    <SubMenu
      key="ebica"
      title="ebica / resty 連携"
      features="onlyServiceAdmin"
      canShow={shouldShowEbica}
      {...props}
    >
      <MenuItem route="editEbicaConfig" to="/ebica/config" text="連携処理" />
      <MenuItem route="ebicaTables" to="/ebica/table" text="テーブル連携" />
    </SubMenu>
  );
});
