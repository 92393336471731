import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";
import { ApolloError } from "@apollo/client";
import { move } from "util/array";
import { updatePriorities } from "util/priority";
import { extractError } from "util/remoteSchema";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MessageContent } from "components/MessageContent";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { unwrap } from "libs/unwrap";
import {
  useTablesArchiveTableAreaMutation,
  useTablesArchiveTableMutation,
  useTablesGetTableAreasQuery,
  useTablesInsertTableAreasMutation,
  useTablesInsertTablesMutation,
} from "pages/Tables/queries";

import { TableAreaRowItem } from "./TableAreaTable/TableAreaSortableTable";
import { DownloadQrCodesModal } from "./DownloadQrCodesModal";
import { TableAreaTable } from "./TableAreaTable";

export const Tables = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getTableAreasData,
    loading: loadingTableAreas,
    called: calledTableAreas,
    refetch: refetchTableAreas,
    error,
  } = useTablesGetTableAreasQuery(shopId ? { variables: { shopId } } : { skip: true });

  const tableAreas = useMemo(
    () => getTableAreasData?.shop_by_pk?.tableAreas ?? [],
    [getTableAreasData?.shop_by_pk?.tableAreas],
  );

  const tables = useMemo(() => tableAreas.flatMap((tableArea) => tableArea.tables), [tableAreas]);

  const [archiveTableMutation] = useTablesArchiveTableMutation();

  const archiveTable = useCallback(
    async ({ tableId }: { tableId: number }) => {
      if (!shopId) return;

      try {
        await archiveTableMutation({
          variables: { input: { shopId, tableId } },
        });

        message.success("削除しました");
      } catch (err) {
        const remoteError = err instanceof ApolloError ? extractError(err) : null;
        if (remoteError) return message.error(<MessageContent {...remoteError} />);

        message.error("削除に失敗しました");
      }

      await refetchTableAreas();
    },
    [archiveTableMutation, shopId, refetchTableAreas],
  );

  const [archiveTableAreaMutation] = useTablesArchiveTableAreaMutation();

  const archiveTableArea = useCallback(
    async ({ tableAreaId }: { tableAreaId: string }) => {
      if (!shopId) return;

      try {
        await archiveTableAreaMutation({
          variables: { tableAreaId, archivedAt: new Date().toISOString() },
        });
        await refetchTableAreas();

        message.success("削除しました");
      } catch (err) {
        const remoteError = err instanceof ApolloError ? extractError(err) : null;
        if (remoteError) return message.error(<MessageContent {...remoteError} />);

        message.error("削除に失敗しました");
      }
    },
    [archiveTableAreaMutation, shopId, refetchTableAreas],
  );

  const [insertTables] = useTablesInsertTablesMutation();
  const [insertTableAreas] = useTablesInsertTableAreasMutation();

  const handleMove = useCallback(
    async ({
      draggingRowItem,
      targetRowItem,
    }: {
      draggingRowItem: TableAreaRowItem;
      targetRowItem: TableAreaRowItem;
    }) => {
      if (!shopId) return;

      if (draggingRowItem.type === "tableArea" && targetRowItem.type === "tableArea") {
        await insertTableAreas({
          variables: {
            tableAreas: updatePriorities(
              tableAreas,
              draggingRowItem.index,
              targetRowItem.index,
            ).map((tableArea) => ({
              id: tableArea.id,
              priority: tableArea.priority,
              name: tableArea.name,
              shopId,
            })),
          },
        });
        await refetchTableAreas();
      } else if (
        draggingRowItem.type === "table" &&
        targetRowItem.type === "table" &&
        draggingRowItem.tableAreaId === targetRowItem.tableAreaId
      ) {
        const tableArea = unwrap(
          tableAreas.find((tableArea) => tableArea.id === draggingRowItem.tableAreaId),
        );

        const tablesToUpdate = move(
          tableArea.tables,
          draggingRowItem.index,
          targetRowItem.index,
        ).map((table, index) => ({
          id: table.id,
          name: table.name,
          priority: index,
          tableAreaId: tableArea.id,
          shopId,
        }));

        await insertTables({
          variables: {
            tables: tablesToUpdate,
          },
        });
        await refetchTableAreas();
      } else if (
        draggingRowItem.type === "table" &&
        targetRowItem.type === "table" &&
        draggingRowItem.tableAreaId !== targetRowItem.tableAreaId
      ) {
        const sourceTableArea = unwrap(
          tableAreas.find((tableArea) => tableArea.id === draggingRowItem.tableAreaId),
        );

        const targetTableArea = unwrap(
          tableAreas.find((tableArea) => tableArea.id === targetRowItem.tableAreaId),
        );

        const sourceTable = unwrap(tables.find((table) => table.id === draggingRowItem.id));

        const sourceTables = sourceTableArea.tables
          .filter((table) => table.id !== sourceTable.id)
          .map((table, index) => ({
            ...table,
            priority: index,
            tableAreaId: sourceTableArea.id,
          }));

        const targetTables = move(
          [...targetTableArea.tables, sourceTable],
          targetTableArea.tables.length,
          targetRowItem.index,
        ).map((table, index) => ({
          ...table,
          priority: index,
          tableAreaId: targetTableArea.id,
        }));

        const tablesToUpdate = [...sourceTables, ...targetTables].map((table) => ({
          id: table.id,
          priority: table.priority,
          name: table.name,
          tableAreaId: table.tableAreaId,
          shopId,
        }));

        await insertTables({
          variables: {
            tables: tablesToUpdate,
          },
        });
        await refetchTableAreas();
      } else if (
        draggingRowItem.type === "table" &&
        targetRowItem.type === "tableArea" &&
        draggingRowItem.tableAreaId !== targetRowItem.id
      ) {
        const sourceTableArea = unwrap(
          tableAreas.find((tableArea) => tableArea.id === draggingRowItem.tableAreaId),
        );

        const targetTableArea = unwrap(
          tableAreas.find((tableArea) => tableArea.id === targetRowItem.id),
        );

        const sourceTable = unwrap(tables.find((table) => table.id === draggingRowItem.id));

        const sourceTables = sourceTableArea.tables
          .filter((table) => table.id !== sourceTable.id)
          .map((table, index) => ({
            ...table,
            priority: index,
            tableAreaId: sourceTableArea.id,
          }));

        const targetTables = [...targetTableArea.tables, sourceTable].map((table, index) => ({
          ...table,
          priority: index,
          tableAreaId: targetTableArea.id,
        }));

        const tablesToUpdate = [...sourceTables, ...targetTables].map((table) => ({
          id: table.id,
          priority: table.priority,
          name: table.name,
          tableAreaId: table.tableAreaId,
          shopId,
        }));

        await insertTables({
          variables: {
            tables: tablesToUpdate,
          },
        });
        await refetchTableAreas();
      } else {
        throw new Error(`Invalid move: ${JSON.stringify({ draggingRowItem, targetRowItem })}`);
      }

      message.success("編集を保存しました");
    },
    [insertTableAreas, insertTables, shopId, tableAreas, tables, refetchTableAreas],
  );

  const [visibleDownloadQrModal, setVisibleDownloadQrModal] = useState(false);
  const showDownloadQrModal = useCallback(() => setVisibleDownloadQrModal(true), []);
  const closeDownloadQrModal = useCallback(() => setVisibleDownloadQrModal(false), []);

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="エリア / テーブル一覧">
      <PageHeader
        title="エリア / テーブル一覧"
        extra={[
          canAccess("addTableArea") && (
            <Link key="addArea" to="/table/area/add">
              <Button type="primary">エリア新規作成</Button>
            </Link>
          ),
          canAccess("addTable") && (
            <Link key="addTable" to="/table/add">
              <Button type="primary">テーブル新規作成</Button>
            </Link>
          ),
          <Button key="downloadQr" type="primary" onClick={showDownloadQrModal}>
            QRコードダウンロード
          </Button>,
        ]}
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <TableAreaTable
        tableAreas={tableAreas}
        loading={loadingTableAreas || !calledTableAreas}
        onArchiveTable={archiveTable}
        onArchiveTableArea={archiveTableArea}
        onMove={handleMove}
      />

      <DownloadQrCodesModal visible={visibleDownloadQrModal} onClose={closeDownloadQrModal} />
    </DashboardLayout>
  );
};
