import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type EditLineReportingBotConfigFormValues = Partial<{
  name: string;
  shopIds: string[];
}>;

export const EditLineReportingBotConfigFormItem =
  createFormItem<EditLineReportingBotConfigFormValues>();

export const useEditLineReportingBotConfigForm = ({
  onSubmit,
}: {
  onSubmit: ({ name, shopIds }: { name: string; shopIds: string[] }) => void;
}) => {
  const [form] = Form.useForm<EditLineReportingBotConfigFormValues>();

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    onSubmit({ name: formValues.name ?? "", shopIds: formValues.shopIds ?? [] });
  }, [form, onSubmit]);

  return { form, submit };
};
