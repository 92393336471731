import React, { memo, useCallback } from "react";
import { Button, Col, Row } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Plan } from "../types";

import {
  EditPlanWinboardPlanFormValues,
  useEditPlanWinboardPlanForm,
} from "./useEditPlanWinboardPlanForm";
import { BumonCodeField } from "./WinboardBumonCodeField";
import { BumonNameField } from "./WinboardBumonNameField";
import { CategoryCodeField } from "./WinboardCategoryCodeField";
import { CategoryNameField } from "./WinboardCategoryNameField";
import { CodeField } from "./WinboardCodeField";
import { WinboardGetAvailableCodeButtonField } from "./WinboardGetAvailableMenuCdButtonField";
import { NameField } from "./WinboardNameField";
import { PlanOptionTable } from "./WinboardPlanOptionTable";

type Props = {
  plan: Plan;
  onSubmit: (args: EditPlanWinboardPlanFormValues) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => number;
  loading: boolean;
};

export const EditPlanWinboardPlanForm = memo<Props>(
  ({ plan, onClose, onSubmit, onGetAvailableMenuCodeButtonPressed, loading }) => {
    const winboardMenu = plan?.winboardMenus?.[0] ?? undefined;

    const { form, initialValues, submit } = useEditPlanWinboardPlanForm({
      plan,
      winboardMenu,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();
      form.setFields([{ name: "code", value: maxMenuCode.toString() }]);
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    const handleGetAvailableMenuCodeForOptionButtonPressed = useCallback(() => {
      const fieldsValue = form.getFieldsValue();

      let maxMenuCode = onGetAvailableMenuCodeButtonPressed();

      fieldsValue.plan?.planOptions.forEach((planOption, planOptionIndex) => {
        planOption.planChoices.forEach((planChoice, planChoiceIndex) => {
          if (planChoice.winboardMenus?.[0]?.code) {
            return;
          }

          const fieldPath = [
            "plan",
            "planOptions",
            planOptionIndex,
            "planChoices",
            planChoiceIndex,
            "winboardMenus",
            0,
            "code",
          ];

          form.setFieldValue(fieldPath, (maxMenuCode++).toString());
        });
      });
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form name="winboardMenu" form={form} initialValues={initialValues} layout="vertical">
            <Row gutter={24}>
              <Col flex={1}>
                <CodeField />
              </Col>
              <Col>
                <WinboardGetAvailableCodeButtonField
                  handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
                />
              </Col>
            </Row>
            <NameField />
            <BumonCodeField />
            <BumonNameField />
            <CategoryCodeField />
            <CategoryNameField />
            <PlanOptionTable
              handleGetAvailableMenuCodeForOptionButtonPressed={
                handleGetAvailableMenuCodeForOptionButtonPressed
              }
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
