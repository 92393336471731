import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { DisplayNameField } from "pages/EditInflowSourceTag/EditInflowSourceTagForm/DisplayNameField";
import { useEditInflowSourceTagForm } from "pages/EditInflowSourceTag/EditInflowSourceTagForm/useEditInflowSourceTagForm";
import { InflowSourceTag } from "pages/InflowSourceTags/types";
import { InflowSourceTagSetInput } from "types/graphql";

type Props = {
  inflowSourceTag: InflowSourceTag;
  onSubmit: (inflowSourceTag: InflowSourceTagSetInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditInflowSourceTagForm = memo<Props>(
  ({ inflowSourceTag, onClose, onSubmit, loading }) => {
    const { form, initialValues, submit } = useEditInflowSourceTagForm(inflowSourceTag, onSubmit);

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="inflowSourceTag" form={form} initialValues={initialValues} layout="vertical">
            <DisplayNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
