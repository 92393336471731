import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPikaichiOnSitePaymentDiscountTypeFormItem } from "../useEditPikaichiOnSitePaymentDiscountTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiNewariNameField = memo<Props>((props) => (
  <EditPikaichiOnSitePaymentDiscountTypeFormItem
    label="ぴかいち値割名称"
    name="newariName"
    rules={[{ required: true, message: "値割名称を入力してください" }]}
    {...props}
  >
    <Input />
  </EditPikaichiOnSitePaymentDiscountTypeFormItem>
));
