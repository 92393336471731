import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShop } from "hooks/useShop";
import { AddKitchenDisplayDisplayTargetForm } from "pages/AddKitchenDisplayDisplayTarget/AddKitchenDisplayDisplayTargetForm";
import {
  useAddKitchenDisplayDisplayTargetGetRolesQuery,
  useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation,
} from "pages/AddKitchenDisplayDisplayTarget/queries";
import { KdDisplayTargetInsertInput } from "types/graphql";

export const AddKitchenDisplayDisplayTarget = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const navigate = useNavigate();

  const [insertKdDisplayTargetMutation, { loading: loadingInsertKdDisplayTarget }] =
    useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation();

  const {
    data: getRolesData,
    loading: getRolesLoading,
    error,
  } = useAddKitchenDisplayDisplayTargetGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const onSubmit = useCallback(
    async (kdDisplayTarget: Omit<KdDisplayTargetInsertInput, "shopId">) => {
      if (!shopId) return;
      try {
        const kdDisplayTargets = [{ ...kdDisplayTarget, shopId }];
        const { data: insertKdDisplayTargetData } = await insertKdDisplayTargetMutation({
          variables: { kdDisplayTargets },
        });
        const kdDisplayTargetId =
          insertKdDisplayTargetData?.insert_kdDisplayTarget?.returning?.[0]?.id;

        if (kdDisplayTargetId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/kitchenDisplayDisplayTarget/${kdDisplayTargetId}/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [insertKdDisplayTargetMutation, navigate, shopId],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="キッチンディスプレイ表示対象を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "キッチンディスプレイ表示対象" }],
      }}
    >
      <PageHeader title="キッチンディスプレイ表示対象を新規作成" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddKitchenDisplayDisplayTargetForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertKdDisplayTarget || getRolesLoading}
        roles={getRolesData?.role ?? []}
      />
    </DashboardLayout>
  );
};
