import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";

import { NameField } from "./NameField";
import {
  AddMenuRecommendationFormItem,
  AddMenuRecommendationFormValues,
  useAddMenuRecommendationForm,
} from "./useAddMenuRecommendationForm";

type Props = {
  onSubmit: (menuRecommendationMeta: AddMenuRecommendationFormValues) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddMenuRecommendationForm = memo<Props>(
  ({ onClose, onSubmit, loading, onFormValidationError }) => {
    const { form, submit } = useAddMenuRecommendationForm(onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="menuRecommendation" form={form} layout="vertical">
            <NameField />

            <CollapsableFormSection title="多言語設定">
              <AddMenuRecommendationFormItem.NonProperty>
                <TranslationNameFields label="おすすめ名" originalFieldName="name" />
              </AddMenuRecommendationFormItem.NonProperty>
            </CollapsableFormSection>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
