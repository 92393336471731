import React, { memo } from "react";
import styled from "styled-components";
import { WarningOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.25);
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  max-width: 120px;
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.4;
`;

export const DefaultText = memo(({ children }) => (
  <Wrapper>
    <WarningOutlined />
    <Text>{children}</Text>
  </Wrapper>
));
