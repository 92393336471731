import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Descriptions, Modal, Select, Tag } from "antd";

import { Menu } from "pages/AddMenu/types";

const SelectOption = Select.Option;

const StyledSelect = styled(Select)`
  width: 100%;
`;

type Props = {
  menus: Menu[];
  visible: boolean;
  onDismiss(): void;
  onSubmit: (menu: Menu) => void;
};

const renderDescriptions = (menu: Menu) => {
  const items = [
    {
      key: `${menu.menuId}-category`,
      label: "カテゴリ",
      children: menu.categoryMenus.map((categoryMenu) => (
        <Tag key={`${categoryMenu.categoryId}:${menu.menuId}`}>{categoryMenu.category.name}</Tag>
      )),
    },
  ];

  return <Descriptions column={1} items={items} />;
};

export const SelectCopyMenuModal = memo<Props>(({ visible, menus, onSubmit, onDismiss }) => {
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
  const selectMenu = useCallback(
    (menuId: unknown) => {
      setSelectedMenu(menus.find((menu) => menu.menuId === Number(menuId)) ?? null);
    },
    [menus],
  );
  const confirm = useCallback(() => {
    if (!selectedMenu) return;
    onSubmit(selectedMenu);
    onDismiss();
  }, [selectedMenu, onSubmit, onDismiss]);
  return (
    <Modal
      title="メニューを選択"
      open={visible}
      okText="コピーする"
      cancelText="キャンセル"
      okButtonProps={{ disabled: !selectedMenu }}
      onCancel={onDismiss}
      onOk={confirm}
    >
      <StyledSelect
        showSearch
        optionFilterProp="label"
        onSelect={selectMenu}
        optionLabelProp="label"
      >
        {menus.map((menu) => (
          <SelectOption key={menu.menuId} label={menu.name} value={menu.menuId}>
            {menu.name}
            {renderDescriptions(menu)}
          </SelectOption>
        ))}
      </StyledSelect>
    </Modal>
  );
});
