import React, { memo } from "react";
import { Alert, Col, Input, Radio, Row, Switch } from "antd";

import { MaxChoiceNumWithMinChoiceNumField } from "components/Form/MaxChoiceNumWithMinChoiceNumField";
import { MinChoiceNumWithMaxChoiceNumField } from "components/Form/MinChoiceNumWithMaxChoiceNumField";
import { InputNumber } from "components/Input/InputNumber";
import { OptionInputTypeEnum } from "types/graphql";

import { EditPlanOptionFormItem } from "./useEditPlanOptionForm";

type Props = {
  disabled: boolean;
};

export const EditPlanOptionForm = memo<Props>(({ disabled }) => (
  <>
    <EditPlanOptionFormItem
      label="オプション名"
      name="name"
      rules={[{ required: true, message: "オプション名を入力してください" }]}
      wrapperCol={{ span: 12 }}
    >
      <Input disabled={disabled} />
    </EditPlanOptionFormItem>
    <EditPlanOptionFormItem
      label="伝票表示名"
      name="receiptDisplayName"
      rules={[{ required: true, message: "伝票表示名を入力してください" }]}
      wrapperCol={{ span: 12 }}
    >
      <Input disabled={disabled} />
    </EditPlanOptionFormItem>
    <EditPlanOptionFormItem label="選択肢の印刷" name="isMainOption" valuePropName="checked">
      <Switch disabled={disabled} />
    </EditPlanOptionFormItem>
    <EditPlanOptionFormItem label="入力方法" name="inputType">
      <Radio.Group disabled={disabled}>
        <Radio value={OptionInputTypeEnum.Count}>個数入力</Radio>
        <Radio value={OptionInputTypeEnum.Select}>選択</Radio>
      </Radio.Group>
    </EditPlanOptionFormItem>
    <Row>
      <Col span={12}>
        <MinChoiceNumWithMaxChoiceNumField
          label="最小選択種類数"
          name="minChoiceNum"
          rules={[{ required: true, message: "最小選択種類数を入力してください" }]}
        >
          <InputNumber min={0} disabled={disabled} />
        </MinChoiceNumWithMaxChoiceNumField>
      </Col>
      <Col span={12}>
        <MaxChoiceNumWithMinChoiceNumField
          label="最大選択種類数"
          name="maxChoiceNum"
          disabled={disabled}
        >
          <InputNumber min={0} disabled={disabled} />
        </MaxChoiceNumWithMinChoiceNumField>
      </Col>
    </Row>
    <Alert type="info" message="印刷先は「印刷設定 > プランオプション」から選択できます" showIcon />
  </>
));
