import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuDealersGetMenu = gql`
    query EditMenuDealersGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}) {
    id
    name
    menuId
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shopId
      menuId
      _menuId
      isVisibleForCustomer
      isVisibleForStaff
      shop {
        shopId
        name
        useKd
      }
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopMenuKitchenRoles(order_by: {role: {roleId: asc}}) {
        role {
          roleId
          name
        }
      }
      dishUpSlipGroupShopMenus(order_by: {dishUpSlipGroup: {id: asc}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
      shopMenuKdDisplayTargets(order_by: {kdDisplayTargetId: asc}) {
        kdDisplayTarget {
          id
          name
        }
      }
    }
  }
}
    `;
export const EditMenuDealersGetShops = gql`
    query EditMenuDealersGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    useKd
  }
}
    `;
export const EditMenuDealersUpdateShopMenu = gql`
    mutation EditMenuDealersUpdateShopMenu($menuId: uuid!, $shopId: uuid!, $input: shopMenu_set_input!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: $input
  ) {
    affected_rows
  }
}
    `;
export const EditMenuDealersUpdateStock = gql`
    mutation EditMenuDealersUpdateStock($shopId: uuid!, $menuId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export const EditMenuDealersUpdateShopMenus = gql`
    mutation EditMenuDealersUpdateShopMenus($input: UpdateShopMenusBulkInput!) {
  updateShopMenusBulk(input: $input) {
    result
  }
}
    `;
export const EditMenuDealersInsertShopMenus = gql`
    mutation EditMenuDealersInsertShopMenus($input: CreateShopMenusInput!) {
  createShopMenus(input: $input) {
    result
  }
}
    `;
export const EditMenuDealersDeleteShopMenus = gql`
    mutation EditMenuDealersDeleteShopMenus($input: DeleteShopMenusInput!) {
  deleteShopMenus(input: $input) {
    result
  }
}
    `;
export type EditMenuDealersGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditMenuDealersGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'name' | 'menuId' | 'companyId' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & Pick<Types.ShopMenu, 'id' | 'shopId' | 'menuId' | '_menuId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name' | 'useKd'>
      ), stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum' | 'dailyStockNum'>
      )>, shopMenuKitchenRoles: Array<(
        { __typename?: 'shopMenuKitchenRole' }
        & { role: (
          { __typename?: 'role' }
          & Pick<Types.Role, 'roleId' | 'name'>
        ) }
      )>, dishUpSlipGroupShopMenus: Array<(
        { __typename?: 'dishUpSlipGroupShopMenus' }
        & { dishUpSlipGroup?: Types.Maybe<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      )>, shopMenuKdDisplayTargets: Array<(
        { __typename?: 'shopMenuKdDisplayTarget' }
        & { kdDisplayTarget?: Types.Maybe<(
          { __typename?: 'kdDisplayTarget' }
          & Pick<Types.KdDisplayTarget, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type EditMenuDealersGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuDealersGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'useKd'>
  )> }
);

export type EditMenuDealersUpdateShopMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
  input: Types.ShopMenuSetInput;
}>;


export type EditMenuDealersUpdateShopMenuMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type EditMenuDealersUpdateStockMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['uuid'];
  stockId: Types.Scalars['uuid'];
  stock: Types.StockInsertInput;
}>;


export type EditMenuDealersUpdateStockMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stock_one?: Types.Maybe<(
    { __typename?: 'stock' }
    & Pick<Types.Stock, 'id'>
  )>, update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type EditMenuDealersUpdateShopMenusMutationVariables = Types.Exact<{
  input: Types.UpdateShopMenusBulkInput;
}>;


export type EditMenuDealersUpdateShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & { updateShopMenusBulk: (
    { __typename?: 'UpdateShopMenusBulkResult' }
    & Pick<Types.UpdateShopMenusBulkResult, 'result'>
  ) }
);

export type EditMenuDealersInsertShopMenusMutationVariables = Types.Exact<{
  input: Types.CreateShopMenusInput;
}>;


export type EditMenuDealersInsertShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & { createShopMenus: (
    { __typename?: 'CreateShopMenusResult' }
    & Pick<Types.CreateShopMenusResult, 'result'>
  ) }
);

export type EditMenuDealersDeleteShopMenusMutationVariables = Types.Exact<{
  input: Types.DeleteShopMenusInput;
}>;


export type EditMenuDealersDeleteShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & { deleteShopMenus: (
    { __typename?: 'DeleteShopMenusResult' }
    & Pick<Types.DeleteShopMenusResult, 'result'>
  ) }
);


export const EditMenuDealersGetMenuDocument = gql`
    query EditMenuDealersGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}) {
    id
    name
    menuId
    companyId
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shopId
      menuId
      _menuId
      isVisibleForCustomer
      isVisibleForStaff
      shop {
        shopId
        name
        useKd
      }
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopMenuKitchenRoles(order_by: {role: {roleId: asc}}) {
        role {
          roleId
          name
        }
      }
      dishUpSlipGroupShopMenus(order_by: {dishUpSlipGroup: {id: asc}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
      shopMenuKdDisplayTargets(order_by: {kdDisplayTargetId: asc}) {
        kdDisplayTarget {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useEditMenuDealersGetMenuQuery__
 *
 * To run a query within a React component, call `useEditMenuDealersGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuDealersGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditMenuDealersGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditMenuDealersGetMenuQuery, EditMenuDealersGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuDealersGetMenuQuery, EditMenuDealersGetMenuQueryVariables>(EditMenuDealersGetMenuDocument, options);
      }
export function useEditMenuDealersGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuDealersGetMenuQuery, EditMenuDealersGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuDealersGetMenuQuery, EditMenuDealersGetMenuQueryVariables>(EditMenuDealersGetMenuDocument, options);
        }
export type EditMenuDealersGetMenuQueryHookResult = ReturnType<typeof useEditMenuDealersGetMenuQuery>;
export type EditMenuDealersGetMenuLazyQueryHookResult = ReturnType<typeof useEditMenuDealersGetMenuLazyQuery>;
export type EditMenuDealersGetMenuQueryResult = Apollo.QueryResult<EditMenuDealersGetMenuQuery, EditMenuDealersGetMenuQueryVariables>;
export const EditMenuDealersGetShopsDocument = gql`
    query EditMenuDealersGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    useKd
  }
}
    `;

/**
 * __useEditMenuDealersGetShopsQuery__
 *
 * To run a query within a React component, call `useEditMenuDealersGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuDealersGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuDealersGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditMenuDealersGetShopsQuery, EditMenuDealersGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuDealersGetShopsQuery, EditMenuDealersGetShopsQueryVariables>(EditMenuDealersGetShopsDocument, options);
      }
export function useEditMenuDealersGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuDealersGetShopsQuery, EditMenuDealersGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuDealersGetShopsQuery, EditMenuDealersGetShopsQueryVariables>(EditMenuDealersGetShopsDocument, options);
        }
export type EditMenuDealersGetShopsQueryHookResult = ReturnType<typeof useEditMenuDealersGetShopsQuery>;
export type EditMenuDealersGetShopsLazyQueryHookResult = ReturnType<typeof useEditMenuDealersGetShopsLazyQuery>;
export type EditMenuDealersGetShopsQueryResult = Apollo.QueryResult<EditMenuDealersGetShopsQuery, EditMenuDealersGetShopsQueryVariables>;
export const EditMenuDealersUpdateShopMenuDocument = gql`
    mutation EditMenuDealersUpdateShopMenu($menuId: uuid!, $shopId: uuid!, $input: shopMenu_set_input!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: $input
  ) {
    affected_rows
  }
}
    `;
export type EditMenuDealersUpdateShopMenuMutationFn = Apollo.MutationFunction<EditMenuDealersUpdateShopMenuMutation, EditMenuDealersUpdateShopMenuMutationVariables>;

/**
 * __useEditMenuDealersUpdateShopMenuMutation__
 *
 * To run a mutation, you first call `useEditMenuDealersUpdateShopMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersUpdateShopMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuDealersUpdateShopMenuMutation, { data, loading, error }] = useEditMenuDealersUpdateShopMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      shopId: // value for 'shopId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuDealersUpdateShopMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuDealersUpdateShopMenuMutation, EditMenuDealersUpdateShopMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuDealersUpdateShopMenuMutation, EditMenuDealersUpdateShopMenuMutationVariables>(EditMenuDealersUpdateShopMenuDocument, options);
      }
export type EditMenuDealersUpdateShopMenuMutationHookResult = ReturnType<typeof useEditMenuDealersUpdateShopMenuMutation>;
export type EditMenuDealersUpdateShopMenuMutationResult = Apollo.MutationResult<EditMenuDealersUpdateShopMenuMutation>;
export type EditMenuDealersUpdateShopMenuMutationOptions = Apollo.BaseMutationOptions<EditMenuDealersUpdateShopMenuMutation, EditMenuDealersUpdateShopMenuMutationVariables>;
export const EditMenuDealersUpdateStockDocument = gql`
    mutation EditMenuDealersUpdateStock($shopId: uuid!, $menuId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export type EditMenuDealersUpdateStockMutationFn = Apollo.MutationFunction<EditMenuDealersUpdateStockMutation, EditMenuDealersUpdateStockMutationVariables>;

/**
 * __useEditMenuDealersUpdateStockMutation__
 *
 * To run a mutation, you first call `useEditMenuDealersUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuDealersUpdateStockMutation, { data, loading, error }] = useEditMenuDealersUpdateStockMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      stockId: // value for 'stockId'
 *      stock: // value for 'stock'
 *   },
 * });
 */
export function useEditMenuDealersUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuDealersUpdateStockMutation, EditMenuDealersUpdateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuDealersUpdateStockMutation, EditMenuDealersUpdateStockMutationVariables>(EditMenuDealersUpdateStockDocument, options);
      }
export type EditMenuDealersUpdateStockMutationHookResult = ReturnType<typeof useEditMenuDealersUpdateStockMutation>;
export type EditMenuDealersUpdateStockMutationResult = Apollo.MutationResult<EditMenuDealersUpdateStockMutation>;
export type EditMenuDealersUpdateStockMutationOptions = Apollo.BaseMutationOptions<EditMenuDealersUpdateStockMutation, EditMenuDealersUpdateStockMutationVariables>;
export const EditMenuDealersUpdateShopMenusDocument = gql`
    mutation EditMenuDealersUpdateShopMenus($input: UpdateShopMenusBulkInput!) {
  updateShopMenusBulk(input: $input) {
    result
  }
}
    `;
export type EditMenuDealersUpdateShopMenusMutationFn = Apollo.MutationFunction<EditMenuDealersUpdateShopMenusMutation, EditMenuDealersUpdateShopMenusMutationVariables>;

/**
 * __useEditMenuDealersUpdateShopMenusMutation__
 *
 * To run a mutation, you first call `useEditMenuDealersUpdateShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersUpdateShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuDealersUpdateShopMenusMutation, { data, loading, error }] = useEditMenuDealersUpdateShopMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuDealersUpdateShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuDealersUpdateShopMenusMutation, EditMenuDealersUpdateShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuDealersUpdateShopMenusMutation, EditMenuDealersUpdateShopMenusMutationVariables>(EditMenuDealersUpdateShopMenusDocument, options);
      }
export type EditMenuDealersUpdateShopMenusMutationHookResult = ReturnType<typeof useEditMenuDealersUpdateShopMenusMutation>;
export type EditMenuDealersUpdateShopMenusMutationResult = Apollo.MutationResult<EditMenuDealersUpdateShopMenusMutation>;
export type EditMenuDealersUpdateShopMenusMutationOptions = Apollo.BaseMutationOptions<EditMenuDealersUpdateShopMenusMutation, EditMenuDealersUpdateShopMenusMutationVariables>;
export const EditMenuDealersInsertShopMenusDocument = gql`
    mutation EditMenuDealersInsertShopMenus($input: CreateShopMenusInput!) {
  createShopMenus(input: $input) {
    result
  }
}
    `;
export type EditMenuDealersInsertShopMenusMutationFn = Apollo.MutationFunction<EditMenuDealersInsertShopMenusMutation, EditMenuDealersInsertShopMenusMutationVariables>;

/**
 * __useEditMenuDealersInsertShopMenusMutation__
 *
 * To run a mutation, you first call `useEditMenuDealersInsertShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersInsertShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuDealersInsertShopMenusMutation, { data, loading, error }] = useEditMenuDealersInsertShopMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuDealersInsertShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuDealersInsertShopMenusMutation, EditMenuDealersInsertShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuDealersInsertShopMenusMutation, EditMenuDealersInsertShopMenusMutationVariables>(EditMenuDealersInsertShopMenusDocument, options);
      }
export type EditMenuDealersInsertShopMenusMutationHookResult = ReturnType<typeof useEditMenuDealersInsertShopMenusMutation>;
export type EditMenuDealersInsertShopMenusMutationResult = Apollo.MutationResult<EditMenuDealersInsertShopMenusMutation>;
export type EditMenuDealersInsertShopMenusMutationOptions = Apollo.BaseMutationOptions<EditMenuDealersInsertShopMenusMutation, EditMenuDealersInsertShopMenusMutationVariables>;
export const EditMenuDealersDeleteShopMenusDocument = gql`
    mutation EditMenuDealersDeleteShopMenus($input: DeleteShopMenusInput!) {
  deleteShopMenus(input: $input) {
    result
  }
}
    `;
export type EditMenuDealersDeleteShopMenusMutationFn = Apollo.MutationFunction<EditMenuDealersDeleteShopMenusMutation, EditMenuDealersDeleteShopMenusMutationVariables>;

/**
 * __useEditMenuDealersDeleteShopMenusMutation__
 *
 * To run a mutation, you first call `useEditMenuDealersDeleteShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuDealersDeleteShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuDealersDeleteShopMenusMutation, { data, loading, error }] = useEditMenuDealersDeleteShopMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMenuDealersDeleteShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuDealersDeleteShopMenusMutation, EditMenuDealersDeleteShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuDealersDeleteShopMenusMutation, EditMenuDealersDeleteShopMenusMutationVariables>(EditMenuDealersDeleteShopMenusDocument, options);
      }
export type EditMenuDealersDeleteShopMenusMutationHookResult = ReturnType<typeof useEditMenuDealersDeleteShopMenusMutation>;
export type EditMenuDealersDeleteShopMenusMutationResult = Apollo.MutationResult<EditMenuDealersDeleteShopMenusMutation>;
export type EditMenuDealersDeleteShopMenusMutationOptions = Apollo.BaseMutationOptions<EditMenuDealersDeleteShopMenusMutation, EditMenuDealersDeleteShopMenusMutationVariables>;