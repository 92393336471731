import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { AddPlanFormItem } from "../useAddPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <AddPlanFormItem label="プラン説明文：(日本語)" name="description" {...props}>
    <TextArea rows={2} placeholder="プランの概要をご記載ください" />
  </AddPlanFormItem>
));
