import React from "react";
import styled from "styled-components";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { InputShopDataFormItem, InputShopDataFormValues } from "../useInputShopDataForm";

const getValueFromEvent = (event: { file: File }) => event.file;

const Label = styled.div`
  width: 300px;
`;

export const FileUploadField = ({
  title,
  name,
}: {
  title: string;
  name: keyof InputShopDataFormValues;
}) => (
  <InputShopDataFormItem
    label={<Label>{title}</Label>}
    name={name}
    getValueFromEvent={getValueFromEvent}
    rules={[{ required: true, message: "必須です" }]}
  >
    <Upload beforeUpload={() => false} maxCount={1}>
      <Button icon={<UploadOutlined />}>ファイルを選択する</Button>
    </Upload>
  </InputShopDataFormItem>
);
