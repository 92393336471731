import type { relationships } from "store/schemas/relationships";
import { ResolversTypes } from "types/graphql";

// typeormRelations にも ResolversTypes にも存在しているも table 名の Union Types
type Table = {
  [K in keyof typeof relationships]: `${K}_insert_input` extends keyof ResolversTypes ? K : never;
}[keyof typeof relationships];

// Relations と InsertInputs が過不足なく共通の Table というキーを持つようにする
type Relations = Pick<typeof relationships, Table>;
type InsertInputs = {
  [K in Table]: ResolversTypes[`${K}_insert_input`];
};

// 入力するパラメーターをテーブルごとにタプルにしておくことで少しだけ扱いやすくする(他のテーブルの property 名が混ざらない)
type Param = {
  [T in Table]: [T, keyof Relations[T]];
}[Table];

// パラメーターがタプルになった関係で T[0] などの形でアクセスしなくてはならないが、読みにくいのでここで吸収しておく
type RelationsOf<T extends Param> = Pick<Relations[T[0]], Extract<T[1], keyof Relations[T[0]]>>;
type InsertInputOf<T extends Param> = InsertInputs[T[0]];

// どの relation type のときにどちら側の column を省略すべきかをここで決めている
type TypesShouldOmitLeftColumns = "one-to-one" | "many-to-one";
type TypesShouldOmitRightColumns = "one-to-one" | "one-to-many";

// 自分側の column の省略をここで判断・処理し、Omit すべき column の名前を返す
type ResolveLeftColumns<T extends Param> = {
  [K in keyof RelationsOf<T>]: RelationsOf<T>[K] extends {
    type: TypesShouldOmitLeftColumns; // ここで条件を確認しつつ、
    lColumns: infer U extends string[]; // ここで対象の column 名を取得している
  }
    ? Extract<U[number], keyof InsertInputOf<T>>
    : never;
}[keyof RelationsOf<T>];

// 相手側の column の省略をここで判断・処理し、中身が適切に Omit された状態の object 型を返す
type ResolveRightColumns<T extends Param> = {
  [K in keyof RelationsOf<T>]: InsertInputOf<T> extends { [_ in K]?: infer V } // Omit するかに関わらずまずはオリジナルの型を取得する
    ? RelationsOf<T>[K] extends {
        type: TypesShouldOmitRightColumns; // ここで条件を確認しつつ、
        rColumns: infer U extends string[]; // ここで対象の column 名を取得している
      }
      ? OmitRel<NonNullable<V>, U[number]>
      : NonNullable<V> // 特に変更が必要なくても対象のエンティティ自体は必須で指定するように定義する
    : never;
};

// {table name}_(arr|obj)_rel_insert_input 型の data プロパティに対して Omit を行うヘルパー
type OmitRel<T, U extends string> = T extends {
  data: infer V;
  on_conflict?: infer W;
}
  ? {
      data: V extends (infer X)[] ? Omit<X, U>[] : Omit<V, U>;
      on_conflict?: W;
    }
  : never;

// ここで仕上げを行っている
type InputWithRelation<T extends Param> = Omit<
  InsertInputOf<T>,
  ResolveLeftColumns<T> | keyof RelationsOf<T>
> &
  ResolveRightColumns<T>;

/**
 * {table name}_insert_input に required な column の情報を持たせるようにしたことで、
 * insert 時に一緒に relationship 先のエンティティも作成したいケースで required な column
 * の定義が邪魔になることがあった。この関数は PostgresQL から読み取ったスキーマや制約の情報をもとに
 * {table name}_insert_input の型を適切に変形することで、relation 先のエンティティを
 * 一緒に作成するケースでも型チェックの正当性を保ちつつ type casting を行うことができる。
 *
 * T には [(テーブル名), (一緒に作成したい relation の名前の Union Types)] の形式のタプルを指定する。
 * U はオプショナルで、Omit したい column の名前の Union Types を指定する。
 */
export const insertWithRelation = <T extends Param, U extends string = never>(
  input: Omit<InputWithRelation<T>, U>,
) => input as Omit<InsertInputOf<T>, U>;
