/**
 * [Rust unwrap method](https://doc.rust-lang.org/std/option/enum.Option.html#method.unwrap)
 */
export const unwrap = <T>(val: T | null | undefined): T => {
  if (val === null || val === undefined) {
    throw new Error("val is falsy");
  }

  return val;
};
