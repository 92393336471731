import dayjs from "dayjs";

/**
 * YYYY-MM-DD
 */
export type DateString = string & { readonly _brand: unique symbol };

export const dayjsToDateString = (dayJsDate: dayjs.Dayjs) =>
  dayJsDate.format("YYYY-MM-DD") as DateString;

export const dateStringToDayjs = (dateString: DateString) => dayjs(dateString);
