import React, { memo, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import { menuTypes } from "models/menuType";
import { taxRates } from "models/taxRate";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { FormActions } from "components/Form/FormActions";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";
import { MaxChoiceNumWithMinChoiceNumField } from "components/Form/MaxChoiceNumWithMinChoiceNumField";
import { MinChoiceNumWithMaxChoiceNumField } from "components/Form/MinChoiceNumWithMaxChoiceNumField";
import { InputNumber } from "components/Input/InputNumber";
import { InputPrice } from "components/Input/InputPrice";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { grey } from "constants/colors";
import { ChoiceInsertInput, OptionSetInput } from "types/graphql";

import { EditChoiceOrderModal } from "../EditChoiceOrderModal";
import { Option } from "../types";

import { EditOptionFormItem, EditOptionFormValues, useEditOptionForm } from "./useEditOptionForm";

const { Option: SelectOption } = Select;

const { Paragraph } = Typography;

const StyledCheckBox = styled(Checkbox)`
  margin: 8px 0;
`;

const StyledCard = styled(Card)`
  margin: 20px;
  position: relative;
  overflow: hidden;
`;

const MarkedForDeleteOverlay = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${grey[3]};
  opacity: 0;
  transition: 0.2s ease-in-out;
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
        `}
`;

const AddChoiceButton = styled(Button)`
  margin: 0 20px 20px;
`;

const CardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DeleteChoiceDescription = styled.div`
  width: 100%;
  padding: 20px;
  font-weight: bold;
`;

const EditChoiceOrderButton = styled(Button)`
  margin: 0 20px;
`;

type Props = {
  onSubmit: (
    option: OptionSetInput,
    choices: ChoiceInsertInput[],
    choiceIdsForDelete: number[],
    shopChoiceIdsForDelete: string[],
  ) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose(): void;
  loading?: boolean;
  option?: Option | null;
  disabled: boolean;
};

export const EditOptionForm = memo<Props>(
  ({ onSubmit, onFormValidationError, onClose, loading, option, disabled }) => {
    const {
      submit,
      form,
      toggleMaxChoiceNum,
      toggleMinChoiceNum,
      isEnabledMaxChoiceNum,
      isEnabledMinChoiceNum,
      choiceIdsForDelete,
      toggleChoiceForDelete,
    } = useEditOptionForm({ onSubmit, option });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    const [editChoiceOrderModalVisible, setEditChoiceOrderModalVisible] = useState(false);
    const openEditChoiceOrderModal = useCallback(() => setEditChoiceOrderModalVisible(true), []);
    const closeEditChoiceOrderModal = useCallback(() => setEditChoiceOrderModalVisible(false), []);

    if (!option) {
      return <Loading />;
    }

    return (
      <>
        <FormContent>
          <Form name="option" layout="vertical" form={form}>
            <Row gutter={12}>
              <Col span={6}>
                <EditOptionFormItem
                  label="オプション名"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "オプション名を入力してください",
                    },
                  ]}
                >
                  <Input disabled={disabled} />
                </EditOptionFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <EditOptionFormItem
                  label="伝票表示名"
                  name="receiptDisplayName"
                  rules={[
                    {
                      required: true,
                      message: "伝票表示名を入力してください",
                    },
                  ]}
                >
                  <Input disabled={disabled} />
                </EditOptionFormItem>
              </Col>
            </Row>
            <Divider orientation="left">ルールの設定</Divider>
            <Row gutter={[16, 16]}>
              <Col>
                <StyledCheckBox
                  disabled={disabled}
                  checked={isEnabledMinChoiceNum}
                  onChange={toggleMinChoiceNum}
                >
                  最小選択数を設定する
                </StyledCheckBox>
              </Col>
            </Row>
            {isEnabledMinChoiceNum && (
              <Row gutter={[16, 16]}>
                <Col>
                  <MinChoiceNumWithMaxChoiceNumField
                    name="minChoiceNum"
                    disabled={disabled}
                    rules={[{ required: true, message: "最小選択数を入力してください" }]}
                  >
                    <InputNumber disabled={disabled} min={0} />
                  </MinChoiceNumWithMaxChoiceNumField>
                </Col>
              </Row>
            )}
            <Row>
              <StyledCheckBox
                disabled={disabled}
                checked={isEnabledMaxChoiceNum}
                onChange={toggleMaxChoiceNum}
              >
                最大選択数を設定する
              </StyledCheckBox>
            </Row>
            {isEnabledMaxChoiceNum && (
              <Row>
                <Col span={12}>
                  <MaxChoiceNumWithMinChoiceNumField
                    name="maxChoiceNum"
                    disabled={disabled}
                    rules={[{ required: true, message: "最大選択数を入力してください" }]}
                  >
                    <InputNumber min={1} disabled={disabled} />
                  </MaxChoiceNumWithMinChoiceNumField>
                </Col>
              </Row>
            )}
            <Divider orientation="left">選択肢の設定</Divider>
            <Typography>
              選択肢の設定を変更する場合は、ページ下部の更新ボタンを押すと変更が適用されます。
            </Typography>
            <FormList name="choices">
              {(fields, { add, remove }) => (
                <>
                  <FormActions>
                    <EditChoiceOrderButton
                      type="primary"
                      disabled={disabled || fields.length < 2}
                      onClick={openEditChoiceOrderModal}
                    >
                      並び替え
                    </EditChoiceOrderButton>
                  </FormActions>
                  {fields.map((field, choiceIndex) => {
                    const choice: EditOptionFormValues["choices"][number] = form.getFieldValue([
                      "choices",
                      field.name,
                    ]);
                    const { choiceId } = choice;
                    const deleted = choiceIdsForDelete.includes(choiceId);

                    return (
                      <StyledCard key={field.key}>
                        <MarkedForDeleteOverlay show={deleted ?? false} />
                        <EditOptionFormItem noStyle required={true} name={[field.name]}>
                          <EditOptionFormItem.NonProperty noStyle shouldUpdate>
                            {({ getFieldValue, setFields }) => {
                              const imageNamePath = ["choices", field.name, "imageUrl"];
                              const imageUrl: string = getFieldValue(imageNamePath);
                              return (
                                <Row>
                                  <ImageField
                                    disabled={disabled || deleted}
                                    image={imageUrl}
                                    setUploadImage={(image) =>
                                      setFields([{ name: imageNamePath, value: image }])
                                    }
                                    uploadImageApiKey="choice"
                                    formName="EditOptionForm"
                                    label="選択肢画像"
                                    isMovieAccepted
                                  />
                                </Row>
                              );
                            }}
                          </EditOptionFormItem.NonProperty>
                          <Row>
                            <Col span={8}>
                              <EditOptionFormItem
                                label="選択肢名"
                                name={[field.name, "name"]}
                                rules={[{ required: true, message: "選択肢名を入力してください" }]}
                                wrapperCol={{ span: 18 }}
                              >
                                <Input disabled={disabled || deleted} />
                              </EditOptionFormItem>
                            </Col>
                            <Col span={8}>
                              <EditOptionFormItem
                                label="伝票表示名"
                                name={[field.name, "receiptDisplayName"]}
                                rules={[
                                  { required: true, message: "伝票表示名を入力してください" },
                                ]}
                                wrapperCol={{ span: 18 }}
                              >
                                <Input disabled={disabled || deleted} />
                              </EditOptionFormItem>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={8}>
                              <EditOptionFormItem
                                label="価格"
                                name={[field.name, "price"]}
                                rules={[{ required: true, message: "価格を入力してください" }]}
                              >
                                <InputPrice disabled={disabled || deleted} />
                              </EditOptionFormItem>
                            </Col>
                            <Col span={8}>
                              <CostPriceWithCostTaxRateField
                                name={[field.name, "costPrice"]}
                                getCostTaxRateFieldValue={({ getFieldValue }) =>
                                  getFieldValue("choices")?.[field.name]?.["costTaxRate"]
                                }
                              >
                                <InputNumber
                                  min={0}
                                  step={0.01}
                                  precision={2}
                                  disabled={disabled || deleted}
                                />
                              </CostPriceWithCostTaxRateField>
                            </Col>
                            <Col span={8}>
                              <CostTaxRateWithCostPriceField
                                name={[field.name, "costTaxRate"]}
                                getCostPriceFieldValue={({ getFieldValue }) =>
                                  getFieldValue("choices")?.[field.name]?.["costPrice"]
                                }
                              >
                                <Select<string> disabled={disabled || deleted}>
                                  {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
                                    <SelectOption key={taxRateKey} value={taxRate}>
                                      {taxRate * 100}%
                                    </SelectOption>
                                  ))}
                                </Select>
                              </CostTaxRateWithCostPriceField>
                            </Col>
                          </Row>
                          <Row align="middle">
                            <Col span={8}>
                              <EditOptionFormItem
                                label="デフォルトで選択済みにする"
                                name={[field.name, "isDefaultSelection"]}
                                valuePropName="checked"
                              >
                                <Switch disabled={disabled || deleted} />
                              </EditOptionFormItem>
                            </Col>
                            <Col span={8}>
                              <EditOptionFormItem
                                label={
                                  <FormHelp
                                    label="メニュータイプ"
                                    help="設定した値が CSV ダウンロードの出数集計にて出力されます。"
                                  />
                                }
                                name={[field.name, "menuType"]}
                              >
                                <Select<string> disabled={disabled || deleted}>
                                  {Object.entries(menuTypes).map(([menuType, menuTypeName]) => (
                                    <SelectOption key={menuType} value={menuType}>
                                      {menuTypeName}
                                    </SelectOption>
                                  ))}
                                </Select>
                              </EditOptionFormItem>
                            </Col>
                            <Col span={8}>
                              <CardActions>
                                <Button
                                  icon={<DeleteIcon disabled={disabled} />}
                                  loading={loading}
                                  disabled={disabled}
                                  onClick={() => toggleChoiceForDelete({ choiceIndex })}
                                />
                              </CardActions>
                              {deleted && (
                                <DeleteChoiceDescription>
                                  <Typography.Text>
                                    ※ 選択肢はまだ削除されていません
                                    <br />※ 削除するにはページ下部の「更新」を押してください
                                  </Typography.Text>
                                </DeleteChoiceDescription>
                              )}
                            </Col>
                          </Row>
                        </EditOptionFormItem>
                      </StyledCard>
                    );
                  })}
                  <FormActions>
                    <AddChoiceButton type="primary" onClick={add} disabled={disabled}>
                      選択肢を追加
                    </AddChoiceButton>
                  </FormActions>
                </>
              )}
            </FormList>
            <EditChoiceOrderModal
              form={form}
              visible={editChoiceOrderModalVisible}
              closeModal={closeEditChoiceOrderModal}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          {Object.keys(choiceIdsForDelete).length > 0 ? (
            <Popconfirm
              title={
                <>
                  <Paragraph>選択肢を削除しますか？</Paragraph>
                  <Paragraph>一度削除した選択肢を元に戻すことはできません。</Paragraph>
                  <Paragraph>売上の記録は維持されます。</Paragraph>
                </>
              }
              okText="はい"
              cancelText="キャンセル"
              onConfirm={handleSubmit}
            >
              <Button type="primary" loading={loading} disabled={disabled}>
                更新
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" onClick={handleSubmit} loading={loading} disabled={disabled}>
              更新
            </Button>
          )}
        </FormActions>
      </>
    );
  },
);
