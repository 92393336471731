import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CloseField = memo<Props>((props) => (
  <EditShopFormItem
    label="閉店時刻"
    name="close"
    rules={[{ type: "object", required: true, message: "閉店時刻を入力してください" }]}
    {...props}
  >
    <TimePicker formItemName="close" />
  </EditShopFormItem>
));
