import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddClerkInsertClerk = gql`
    mutation AddClerkInsertClerk($clerk: clerk_insert_input!) {
  insert_clerk_one(
    object: $clerk
    on_conflict: {constraint: idx_46484_PRIMARY, update_columns: []}
  ) {
    clerkId
  }
}
    `;
export type AddClerkInsertClerkMutationVariables = Types.Exact<{
  clerk: Types.ClerkInsertInput;
}>;


export type AddClerkInsertClerkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_clerk_one?: Types.Maybe<(
    { __typename?: 'clerk' }
    & Pick<Types.Clerk, 'clerkId'>
  )> }
);


export const AddClerkInsertClerkDocument = gql`
    mutation AddClerkInsertClerk($clerk: clerk_insert_input!) {
  insert_clerk_one(
    object: $clerk
    on_conflict: {constraint: idx_46484_PRIMARY, update_columns: []}
  ) {
    clerkId
  }
}
    `;
export type AddClerkInsertClerkMutationFn = Apollo.MutationFunction<AddClerkInsertClerkMutation, AddClerkInsertClerkMutationVariables>;

/**
 * __useAddClerkInsertClerkMutation__
 *
 * To run a mutation, you first call `useAddClerkInsertClerkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClerkInsertClerkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClerkInsertClerkMutation, { data, loading, error }] = useAddClerkInsertClerkMutation({
 *   variables: {
 *      clerk: // value for 'clerk'
 *   },
 * });
 */
export function useAddClerkInsertClerkMutation(baseOptions?: Apollo.MutationHookOptions<AddClerkInsertClerkMutation, AddClerkInsertClerkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClerkInsertClerkMutation, AddClerkInsertClerkMutationVariables>(AddClerkInsertClerkDocument, options);
      }
export type AddClerkInsertClerkMutationHookResult = ReturnType<typeof useAddClerkInsertClerkMutation>;
export type AddClerkInsertClerkMutationResult = Apollo.MutationResult<AddClerkInsertClerkMutation>;
export type AddClerkInsertClerkMutationOptions = Apollo.BaseMutationOptions<AddClerkInsertClerkMutation, AddClerkInsertClerkMutationVariables>;