import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";

import { SummaryRowPanel } from "../SummaryRowPanel";

const formatter = new Intl.NumberFormat("ja");

const Row = styled.div`
  display: flex;
`;

type Props = {
  summary: {
    faveYellCount: number;
    faveYellCustomerCount: number;
    totalTaxIncludedSales: number;
  } | null;
};
export const SummaryRow = ({ summary }: Props) => {
  const salesAmount = summary?.totalTaxIncludedSales ?? 0;
  const faveYellCount = summary?.faveYellCount ?? 0;
  const faveYellCustomerCount = summary?.faveYellCustomerCount ?? 0;
  const salesPerCustomer = Math.floor(
    faveYellCustomerCount ? salesAmount / faveYellCustomerCount : 0,
  );
  const salesPerFaveYell = Math.floor(faveYellCount ? salesAmount / faveYellCount : 0);
  return (
    <Row>
      <SummaryRowPanel
        title="推しエール人数"
        value={faveYellCustomerCount}
        unit="人"
        flexGrow={1}
      />
      <Spacer size={16} inline />
      <SummaryRowPanel
        title="推しエール単価 / 人"
        value={formatter.format(salesPerCustomer)}
        unit="円"
        flexGrow={1}
      />
      <Spacer size={16} inline />
      <SummaryRowPanel title="推しエール件数" value={faveYellCount} unit="件" flexGrow={1} />
      <Spacer size={16} inline />
      <SummaryRowPanel
        title="推しエール単価 / 件"
        value={formatter.format(salesPerFaveYell)}
        unit="円"
        flexGrow={1}
      />
      <Spacer size={16} inline />
      <SummaryRowPanel
        title="推しエール合計額"
        value={formatter.format(salesAmount)}
        unit="円"
        primary
        flexGrow={2}
      />
    </Row>
  );
};
