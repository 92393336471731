import React, { memo } from "react";
import { Button } from "antd";

import { EditPlanWinboardPlanFormItem } from "../../useEditPlanWinboardPlanForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const WinboardGetAvailableCodeForOptionButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed: handleAutoAdoptMenuCodeButtonPressed }) => (
    <EditPlanWinboardPlanFormItem
      name="winboardGetAvailableCodeForOptionButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditPlanWinboardPlanFormItem>
  ),
);
