import React, { useState } from "react";
import useInterval from "react-use/esm/useInterval";
import { Button, notification } from "antd";
import { getHasUpdate } from "util/version";

export const useUpdate = () => {
  const [shouldCheckImmediately, setShouldCheckImmediately] = useState(true);
  const [isDismissed, setIsDismissed] = useState(false);

  useInterval(
    async () => {
      if (process.env.NODE_ENV !== "production") return;
      if (isDismissed) return;

      setShouldCheckImmediately(false);

      const hasUpdate = await getHasUpdate();

      if (hasUpdate) {
        notification.open({
          message: "新しいバージョンがご利用可能です",
          description: "安全にご利用いただくため、早急に更新してください",
          btn: (
            <Button type="primary" size="small" onClick={() => location.reload()}>
              更新
            </Button>
          ),
          key: "update",
          onClose: () => setIsDismissed(true),
          duration: null,
        });
      }
    },
    shouldCheckImmediately ? 0 : 60 * 1000,
  );
};
