import React, { memo, useMemo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";

import { OnSitePaymentDetailType, TecAggregationOnSitePaymentDetailType } from "../types";

type Props = {
  loading?: boolean;
  onSitePaymentDetailTypes: OnSitePaymentDetailTypeWithTecAggregationDetailType[];
};

export type OnSitePaymentDetailTypeWithTecAggregationDetailType = Omit<
  OnSitePaymentDetailType,
  "tecAggregationOnSitePaymentDetailTypes"
> & { tecAggregationOnSitePaymentDetailType?: TecAggregationOnSitePaymentDetailType };

export const TecAggregationDetailTypeTable = memo<Props>(
  ({ loading, onSitePaymentDetailTypes }) => {
    const { canAccess } = useCanAccess();
    const columns = useMemo(
      () => [
        {
          title: "支払方法",
          render(_: string, detailType: OnSitePaymentDetailTypeWithTecAggregationDetailType) {
            return detailType.label;
          },
        } as const,
        {
          title: "メディア種別",
          dataIndex: "mediaCode",
          render(_: string, detailType: OnSitePaymentDetailTypeWithTecAggregationDetailType) {
            return detailType.tecAggregationOnSitePaymentDetailType?.mediaCode ?? "";
          },
        },
        {
          title: "メディア名称",
          dataIndex: "mediaName",
          render(_: string, detailType: OnSitePaymentDetailTypeWithTecAggregationDetailType) {
            return detailType.tecAggregationOnSitePaymentDetailType?.mediaName ?? "";
          },
        },
        {
          title: "明細種別",
          dataIndex: "paymentType",
          render(_: string, detailType: OnSitePaymentDetailTypeWithTecAggregationDetailType) {
            return detailType.tecAggregationOnSitePaymentDetailType?.paymentType ?? "";
          },
        },
        {
          title: "集計種別",
          render(_: string, detailType: OnSitePaymentDetailTypeWithTecAggregationDetailType) {
            return (
              detailType.tecAggregationOnSitePaymentDetailType?.tecAggregationMediaMap?.mediaName ??
              ""
            );
          },
        },
        ...(canAccess("editTecAggregationOnSitePaymentDetailType")
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                render(_: string, { type }: { type: string }) {
                  return (
                    <IconLink to={`/tec/detailType/${type}/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                },
              } as const,
            ]
          : []),
      ],
      [canAccess],
    );

    return (
      <Table
        rowKey="type"
        columns={columns}
        dataSource={onSitePaymentDetailTypes}
        loading={loading}
        bordered
      />
    );
  },
);
