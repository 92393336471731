import React, { memo } from "react";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { ConnectGameConfig, ConnectGameConfigShop, ConsolationCoupon, PrizeCoupon } from "../types";

const Text = styled.p`
  margin: 0;
`;

type Props = {
  connectGameConfigs: ConnectGameConfig[];
  loading: boolean;
};

export const ConnectGameConfigsTable = memo(({ connectGameConfigs, loading }: Props) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<ConnectGameConfig> = [
    {
      title: "ゲーム名",
      render: () => <Text>くじ</Text>,
    },
    {
      title: "利用状況",
      dataIndex: "enabled",
      render: (value: boolean) => <Text>{value ? "利用中" : "停止中"}</Text>,
    },
    {
      title: "利用店舗",
      dataIndex: "connectGameConfigShops",
      render: (value: ConnectGameConfigShop[]) =>
        value
          // NOTE:
          // connectGameConfigShops と shop の hasura permission の違いから、connectGameConfigShop は取得できるものの join した shop にアクセスできないことがある
          // そのため、shop は型的には not null だが null になりうる
          .filter(({ shop }) => shop !== null)
          .map(({ shop }: { shop: { id: string; name: string } }) => (
            <Text key={shop.id}>{shop.name}</Text>
          )),
    },
    {
      title: "メイン画像",
      dataIndex: "startImageUrl",
      align: "center",
      width: 130,
      render: (value: string) => <Thumbnail url={value} width={64} height={64} />,
    },
    {
      title: "当選確率",
      width: 90,
      dataIndex: "maxWinningRate",
      render: (value: number) => <Text>{value * 100}%</Text>,
    },
    {
      title: "当たりのお客様",
      dataIndex: "prizeCoupon",
      render: (value: PrizeCoupon) => <Text>{value.name}</Text>,
    },
    {
      title: "その他のお客様",
      width: 130,
      dataIndex: "consolationCoupon",
      render: (value: ConsolationCoupon) => <Text>{value?.name ?? "はずれ"}</Text>,
    },
    ...(canAccess("editConnectGameConfig")
      ? [
          {
            title: "",
            dataIndex: "id",
            align: "center",
            width: 60,
            render: (value: string, { connectGameConfigShops }: ConnectGameConfig) => {
              const canEditConnectGameConfig = connectGameConfigShops.every(
                // NOTE:
                // connectGameConfigShops と shop の hasura permission の違いから、connectGameConfigShop は取得できるものの join した shop にアクセスできないことがある
                // そのため、shop は型的には not null だが null になりうる
                // 自身がアクセスできない店舗を含む設定は編集できないようにする必要があり、そのためには自身がアクセスできない店舗の connectGameConfigShop も取得できる必要がある。
                // そのため、permission は変更しない
                // https://dinii.slack.com/archives/C048W96CX1C/p1685428907019489
                ({ shop }) => shop !== null,
              );
              if (canEditConnectGameConfig) {
                return (
                  <IconLink to={`/connectGameConfig/${value}/edit`}>
                    <EditIcon />
                  </IconLink>
                );
              }
              return <EditIcon disabled />;
            },
          } as const,
        ]
      : []),
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={connectGameConfigs}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
