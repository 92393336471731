import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { isNotNullable } from "util/type/primitive";

import { QuestionnaireAnalyticsCommentModal } from "components/QuestionnaireAnalyticsCommentModal";
import { oldColors } from "constants/colors";
import { TextAnswer } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/types";

const AnswerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  color: ${oldColors.Neutral400};
  text-decoration: underline;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const FlexBox = styled.div`
  display: flex;
`;

const MultipleChoiceTypeAnswerLeftColumn = styled.div`
  width: 34px;
`;

const MultipleChoiceTypeAnswerRightColumn = styled.div`
  flex: 1;
`;

type Props = {
  answer: TextAnswer;
};

export const TextTypeAnswerListItem = memo<Props>(({ answer }) => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const openModal = useCallback(() => setIsVisibleModal(true), [setIsVisibleModal]);
  const dismissModal = useCallback(() => setIsVisibleModal(false), [setIsVisibleModal]);

  return (
    <>
      <FlexBox>
        {isNotNullable(answer.score) && (
          <MultipleChoiceTypeAnswerLeftColumn>
            <BoldText>{answer.score}</BoldText>
          </MultipleChoiceTypeAnswerLeftColumn>
        )}

        <MultipleChoiceTypeAnswerRightColumn>
          <AnswerTitle>
            <div>
              <BoldText>{answer.title}</BoldText> {answer.attribute ? `(${answer.attribute})` : ""}
            </div>
            <StyledButton type="link" onClick={openModal}>
              回答をみる
            </StyledButton>
          </AnswerTitle>
          <div>{answer.detail}</div>
        </MultipleChoiceTypeAnswerRightColumn>
      </FlexBox>
      <QuestionnaireAnalyticsCommentModal
        customerQuestionnaireId={answer.customerQuestionnaireId}
        isVisibleModal={isVisibleModal}
        onDismiss={dismissModal}
      />
    </>
  );
});
