import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PreviewAccountingSlipImageModalGetShop = gql`
    query PreviewAccountingSlipImageModalGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    contactInfo
    disableClerkCache
    enableDefaultInflowSourceTag
    enableReserveCashSeparation
    hideFreeItemInAccountingSlip
    issuer
    accountingSlipImages(order_by: [{position: desc}, {priority: asc}]) {
      accountingSlipImageId: id
      position
      imageUrl
      description
      priority
    }
  }
}
    `;
export type PreviewAccountingSlipImageModalGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type PreviewAccountingSlipImageModalGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'contactInfo' | 'disableClerkCache' | 'enableDefaultInflowSourceTag' | 'enableReserveCashSeparation' | 'hideFreeItemInAccountingSlip' | 'issuer'>
    & { accountingSlipImages: Array<(
      { __typename?: 'accountingSlipImage' }
      & Pick<Types.AccountingSlipImage, 'position' | 'imageUrl' | 'description' | 'priority'>
      & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
    )> }
  )> }
);


export const PreviewAccountingSlipImageModalGetShopDocument = gql`
    query PreviewAccountingSlipImageModalGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    contactInfo
    disableClerkCache
    enableDefaultInflowSourceTag
    enableReserveCashSeparation
    hideFreeItemInAccountingSlip
    issuer
    accountingSlipImages(order_by: [{position: desc}, {priority: asc}]) {
      accountingSlipImageId: id
      position
      imageUrl
      description
      priority
    }
  }
}
    `;

/**
 * __usePreviewAccountingSlipImageModalGetShopQuery__
 *
 * To run a query within a React component, call `usePreviewAccountingSlipImageModalGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewAccountingSlipImageModalGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewAccountingSlipImageModalGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function usePreviewAccountingSlipImageModalGetShopQuery(baseOptions: Apollo.QueryHookOptions<PreviewAccountingSlipImageModalGetShopQuery, PreviewAccountingSlipImageModalGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewAccountingSlipImageModalGetShopQuery, PreviewAccountingSlipImageModalGetShopQueryVariables>(PreviewAccountingSlipImageModalGetShopDocument, options);
      }
export function usePreviewAccountingSlipImageModalGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewAccountingSlipImageModalGetShopQuery, PreviewAccountingSlipImageModalGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewAccountingSlipImageModalGetShopQuery, PreviewAccountingSlipImageModalGetShopQueryVariables>(PreviewAccountingSlipImageModalGetShopDocument, options);
        }
export type PreviewAccountingSlipImageModalGetShopQueryHookResult = ReturnType<typeof usePreviewAccountingSlipImageModalGetShopQuery>;
export type PreviewAccountingSlipImageModalGetShopLazyQueryHookResult = ReturnType<typeof usePreviewAccountingSlipImageModalGetShopLazyQuery>;
export type PreviewAccountingSlipImageModalGetShopQueryResult = Apollo.QueryResult<PreviewAccountingSlipImageModalGetShopQuery, PreviewAccountingSlipImageModalGetShopQueryVariables>;