import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const InputShopDataGetCompany = gql`
    query InputShopDataGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    name
  }
}
    `;
export type InputShopDataGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type InputShopDataGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'name'>
  )> }
);


export const InputShopDataGetCompanyDocument = gql`
    query InputShopDataGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    name
  }
}
    `;

/**
 * __useInputShopDataGetCompanyQuery__
 *
 * To run a query within a React component, call `useInputShopDataGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputShopDataGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputShopDataGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useInputShopDataGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<InputShopDataGetCompanyQuery, InputShopDataGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputShopDataGetCompanyQuery, InputShopDataGetCompanyQueryVariables>(InputShopDataGetCompanyDocument, options);
      }
export function useInputShopDataGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputShopDataGetCompanyQuery, InputShopDataGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputShopDataGetCompanyQuery, InputShopDataGetCompanyQueryVariables>(InputShopDataGetCompanyDocument, options);
        }
export type InputShopDataGetCompanyQueryHookResult = ReturnType<typeof useInputShopDataGetCompanyQuery>;
export type InputShopDataGetCompanyLazyQueryHookResult = ReturnType<typeof useInputShopDataGetCompanyLazyQuery>;
export type InputShopDataGetCompanyQueryResult = Apollo.QueryResult<InputShopDataGetCompanyQuery, InputShopDataGetCompanyQueryVariables>;