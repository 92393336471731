import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { AddServiceAdminForm } from "pages/AddServiceAdmin/AddServiceAdminForm";
import { useAddServiceAdminCreateServiceAdminMutation } from "pages/AddServiceAdmin/queries";
import { CreateServiceAdminInput } from "types/graphql";

export const AddServiceAdmin = () => {
  const navigate = useNavigate();

  const [addServiceAdmin, { loading: loadingCreateServiceAdmin }] =
    useAddServiceAdminCreateServiceAdminMutation();

  const onSubmit = useCallback(
    async (serviceAdmin: CreateServiceAdminInput) => {
      try {
        const { data: addServiceAdminData } = await addServiceAdmin({
          variables: { serviceAdmin },
        });

        if (!addServiceAdminData) {
          throw new Error("");
        }

        message.success("作成しました");
        navigate("/serviceAdmin", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addServiceAdmin, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="サービス管理者を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "サービス管理者" }],
      }}
    >
      <PageHeader title="サービス管理者を新規作成" onBack={goBack} />
      <AddServiceAdminForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingCreateServiceAdmin}
      />
    </DashboardLayout>
  );
};
