import { isNumber } from "lodash";
import { exportCsv } from "util/exportCsv";

import { CompanySalesAnalyticsRow } from "hooks/useCompanySalesAnalytics/types";

import { CompanySalesAnalyticsColumnWithSelectState } from "./types";

const formatNumber = (toFormat: number) => (!isFinite(toFormat) ? "" : String(toFormat));

export const exportCompanySalesAnalyticsCsv = ({
  fileName,
  columnsWithEnabledStatus,
  rows,
}: {
  fileName: string;
  columnsWithEnabledStatus: CompanySalesAnalyticsColumnWithSelectState[];
  rows: CompanySalesAnalyticsRow[];
}) => {
  const filteredColumns = columnsWithEnabledStatus.filter(({ isEnabled }) => isEnabled);

  exportCsv({
    fileName,
    columnHeaders: filteredColumns.map((column) => column.label),
    rows: rows
      .filter((row) => row.isEmpty || !row.isSummaryRow)
      .map((row) => {
        if (row.isEmpty) {
          return filteredColumns.map(({ columnId }) =>
            columnId === "shopName" ? row[columnId] : "",
          );
        }

        return filteredColumns.map(({ columnId }) => {
          if (row[columnId] === undefined || row[columnId] === null) return "";

          return isNumber(row[columnId])
            ? formatNumber(Number(row[columnId]))
            : String(row[columnId]);
        });
      }),
  });
};
