import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { placeholder } from "../placeholder";
import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddCouponFormItem
    label="クーポン管理名"
    name="name"
    rules={[{ required: true, message: "クーポン管理名を入力してください" }]}
    {...props}
  >
    <Input placeholder={placeholder.name} />
  </AddCouponFormItem>
));
