import React, { memo, useState } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { CheckInUrlType } from "libs/dinii/link";

import { Table as TableType } from "../types";

import { QrCodeTableCell } from "./QrCodeTableCell";
import { QrCodeTableTitle } from "./QrCodeTableTitle";

type Props = {
  loading?: boolean;
  shopId?: string;
  liffId?: string;
  tables: TableType[];
};

export const QrCodeTable = memo<Props>(({ loading, shopId, liffId, tables }) => {
  const [pagination, setPagination] = usePagination();
  const [visibleCell, setVisibleCell] = useState<{ type: CheckInUrlType; tableId: number } | null>(
    null,
  );

  const columns = [
    { title: "テーブル名", dataIndex: "name" },
    {
      title: (
        <QrCodeTableTitle
          title="Web URL"
          description="廃止済みで既存店舗のみ、緊急時に LINE を通さずに MO を立ち上げる"
        />
      ),
      align: "start" as const,
      width: 200,
      onCell: (table: TableType) => ({
        style: {
          verticalAlign: "top",
        },
        onMouseEnter: () => setVisibleCell({ type: "web", tableId: table.tableId }),
        onMouseLeave: () => setVisibleCell(null),
      }),
      render(_: string, table: TableType) {
        return (
          <QrCodeTableCell
            type="web"
            table={table}
            shopId={shopId}
            visible={
              !visibleCell || (visibleCell.type === "web" && visibleCell.tableId === table.tableId)
            }
          />
        );
      },
    },
    {
      title: (
        <QrCodeTableTitle
          title="LIFF URL"
          description="廃止済みで既存店舗のみ、通常の LINE で MO を立ち上げる"
        />
      ),
      align: "start" as const,
      width: 200,
      onCell: (table: TableType) => ({
        style: {
          verticalAlign: "top",
        },
        onMouseEnter: () => setVisibleCell({ type: "liff", tableId: table.tableId }),
        onMouseLeave: () => setVisibleCell(null),
      }),
      render(_: string, table: TableType) {
        return (
          <QrCodeTableCell
            type="liff"
            table={table}
            shopId={shopId}
            liffId={liffId}
            visible={
              !visibleCell || (visibleCell.type === "liff" && visibleCell.tableId === table.tableId)
            }
          />
        );
      },
    },
    {
      title: (
        <QrCodeTableTitle
          title="Redirect Web URL"
          description="現役、通常時は LINE で MO を立ち上げるが、緊急時には Web 版に切り替えることもできる"
        />
      ),
      align: "start" as const,
      width: 200,
      onCell: (table: TableType) => ({
        style: {
          verticalAlign: "top",
        },
        onMouseEnter: () => setVisibleCell({ type: "redirect", tableId: table.tableId }),
        onMouseLeave: () => setVisibleCell(null),
      }),
      render(_: string, table: TableType) {
        return (
          <QrCodeTableCell
            type="redirect"
            table={table}
            shopId={shopId}
            liffId={liffId}
            visible={
              !visibleCell ||
              (visibleCell.type === "redirect" && visibleCell.tableId === table.tableId)
            }
          />
        );
      },
    },
  ];

  return (
    <Table
      rowKey="tableId"
      columns={columns}
      dataSource={tables}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
