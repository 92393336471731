import { companyIds } from "constants/companyIds";
import { Shop } from "types/graphql";

// NOTE: Shop と同様の Feature Flag の仕組みにしたい

const isBeta = import.meta.env.APP_ENV === "beta";

const { production } = companyIds;

export const isCustomerSegmentAggregationEnabled = (_companyId?: number) => {
  if (_companyId === undefined) return false;
  if (isBeta) return false;

  return [
    production.uat,
    production.secondArrow,
    production.tanaka,
    production.tanakaAkihabara,
    production.tanakaRoadside,
    production.tanakaShinmaruko,
    production.tanakaToritama,
  ].includes(_companyId);
};

export const isKioskServedTimeAggregationEnabled = (companyId?: number) => {
  if (companyId === undefined) return false;
  if (isBeta) return false;

  return [production.uat, production.secondArrow].includes(companyId);
};

export const isKdServedTimeAggregationEnabled = (shops: Pick<Shop, "useKd">[]) =>
  shops.some((shop) => shop.useKd);
