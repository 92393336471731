import "libs/dayjs";

import React from "react";
import ReactDOM from "react-dom";

import { Root } from "components/Root";

ReactDOM.render(<Root />, document.getElementById("root"));

// 何故かアプリがフリーズするので一旦昔の方法に戻している
// import * as ReactDOMClient from "react-dom/client";
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
// const root = ReactDOMClient.createRoot(document.getElementById("root")!);
// root.render(<Root />);
