import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { CreateOptionInputOptionSource } from "types/graphql";

import { AddOptionForm } from "../../forms/AddOptionForm";
import {
  useAddOptionFormInsertOptionMutation,
  useAddOptionGetOptionsQuery,
} from "../../forms/AddOptionForm/queries";
import { Option } from "../../forms/AddOptionForm/types";

export const AddOption = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getOptionsData, error: getOptionsError } = useAddOptionGetOptionsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const options = getOptionsData?.option ?? [];

  const [createOption, { loading: loadingInsertOption }] = useAddOptionFormInsertOptionMutation();

  const onSubmit = useCallback(
    async (option: CreateOptionInputOptionSource) => {
      try {
        const { data: createOptionData } = await createOption({ variables: { option } });
        const optionId = createOptionData?.createOption.optionId;
        const _optionId = createOptionData?.createOption._optionId;

        if (optionId === undefined || _optionId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/option/${_optionId}/edit`);
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [createOption, navigate],
  );

  const navigateToAddOptionWithOptionId = useCallback(
    async (option: Option) => {
      navigate(`/option/add?optionId=${option.optionId}`, { replace: true });
    },
    [navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const shouldShowAlert = Boolean(getOptionsError);

  return (
    <DashboardLayout
      title="オプションを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "オプション" }],
      }}
    >
      <PageHeader title="オプションを新規作成" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddOptionForm
        options={options}
        onSubmit={onSubmit}
        navigateToAddOptionWithOptionId={navigateToAddOptionWithOptionId}
        onFormValidationError={onFormValidationError}
        onClose={goBack}
        loading={loadingInsertOption}
        companyId={companyId}
      />
    </DashboardLayout>
  );
};
