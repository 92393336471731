import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CustomerDashboardsGetCumulativeCount = gql`
    query CustomerDashboardsGetCumulativeCount($input: QueryCumulativeCountInput!) {
  cumulativeCount(input: $input) {
    lineIdCount
    shopId
  }
}
    `;
export const CustomerDashboardsGetCustomerDashboardKpis = gql`
    query CustomerDashboardsGetCustomerDashboardKpis($input: CustomerDashboardKpisInput!) {
  customerDashboardKpis(input: $input) {
    shopId
    sales {
      fluctuatingStatus
      value
    }
    customerCount {
      fluctuatingStatus
      value
    }
    groupCount {
      fluctuatingStatus
      value
    }
    salesPerCustomer {
      fluctuatingStatus
      value
    }
    checkInRate {
      fluctuatingStatus
      value
    }
    faveYellUserCount {
      fluctuatingStatus
      value
    }
    faveYellAmount {
      fluctuatingStatus
      value
    }
    repeaterCount {
      fluctuatingStatus
      value
    }
    newLineIdCount {
      fluctuatingStatus
      value
    }
    ambassadorCount {
      fluctuatingStatus
      value
    }
    newCustomerViaAmbassadorCount {
      fluctuatingStatus
      value
    }
  }
}
    `;
export const CustomerDashboardsGetTransitions = gql`
    query CustomerDashboardsGetTransitions($input: QueryTransitionsInput!) {
  customerDashboardTransitions(input: $input) {
    shopId
    repeater {
      date
      repeaterRate
    }
    checkInRate {
      date
      value
    }
    newLineIdCount {
      date
      value
    }
    sales {
      date
      value
    }
    salesPerCustomer {
      date
      value
    }
    customerCount {
      date
      newCustomer
      newCustomerViaAmbassador
      repeater
    }
    ambassador {
      date
      ambassadorRate
      newCustomerViaAmbassadorCount
    }
    faveYell {
      date
      faveYellAmount
      faveYellUserCount
    }
  }
}
    `;
export const CustomerDashboardsGetCustomerVisitedCountRanking = gql`
    query CustomerDashboardsGetCustomerVisitedCountRanking($input: QueryCustomerRankingInput!) {
  customerVisitedCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;
export const CustomerDashboardsGetCustomerViralCountRanking = gql`
    query CustomerDashboardsGetCustomerViralCountRanking($input: QueryCustomerRankingInput!) {
  customerViralCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;
export const CustomerDashboardsGetCustomerFaveYellAmountRanking = gql`
    query CustomerDashboardsGetCustomerFaveYellAmountRanking($input: QueryCustomerRankingInput!) {
  customerFaveYellAmountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastFaveYellCreatedAt
    }
  }
}
    `;
export const CustomerDashboardsGetCustomerFaveYellCountRanking = gql`
    query CustomerDashboardsGetCustomerFaveYellCountRanking($input: QueryCustomerRankingInput!) {
  customerFaveYellCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastFaveYellCreatedAt
    }
  }
}
    `;
export const CustomerDashboardsGetShops = gql`
    query CustomerDashboardsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(where: {archivedAt: {_is_null: true}}) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export type CustomerDashboardsGetCumulativeCountQueryVariables = Types.Exact<{
  input: Types.QueryCumulativeCountInput;
}>;


export type CustomerDashboardsGetCumulativeCountQuery = (
  { __typename?: 'query_root' }
  & { cumulativeCount: (
    { __typename?: 'CumulativeCount' }
    & Pick<Types.CumulativeCount, 'lineIdCount' | 'shopId'>
  ) }
);

export type CustomerDashboardsGetCustomerDashboardKpisQueryVariables = Types.Exact<{
  input: Types.CustomerDashboardKpisInput;
}>;


export type CustomerDashboardsGetCustomerDashboardKpisQuery = (
  { __typename?: 'query_root' }
  & { customerDashboardKpis: (
    { __typename?: 'CustomerDashboardKpis' }
    & Pick<Types.CustomerDashboardKpis, 'shopId'>
    & { sales: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), customerCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), groupCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), salesPerCustomer: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), checkInRate: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), faveYellUserCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), faveYellAmount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), repeaterCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), newLineIdCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), ambassadorCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ), newCustomerViaAmbassadorCount: (
      { __typename?: 'CustomerDashboardKpi' }
      & Pick<Types.CustomerDashboardKpi, 'fluctuatingStatus' | 'value'>
    ) }
  ) }
);

export type CustomerDashboardsGetTransitionsQueryVariables = Types.Exact<{
  input: Types.QueryTransitionsInput;
}>;


export type CustomerDashboardsGetTransitionsQuery = (
  { __typename?: 'query_root' }
  & { customerDashboardTransitions: (
    { __typename?: 'CustomerDashboardTransitions' }
    & Pick<Types.CustomerDashboardTransitions, 'shopId'>
    & { repeater: Array<(
      { __typename?: 'RepeaterTransition' }
      & Pick<Types.RepeaterTransition, 'date' | 'repeaterRate'>
    )>, checkInRate: Array<(
      { __typename?: 'GeneralTransition' }
      & Pick<Types.GeneralTransition, 'date' | 'value'>
    )>, newLineIdCount: Array<(
      { __typename?: 'GeneralTransition' }
      & Pick<Types.GeneralTransition, 'date' | 'value'>
    )>, sales: Array<(
      { __typename?: 'GeneralTransition' }
      & Pick<Types.GeneralTransition, 'date' | 'value'>
    )>, salesPerCustomer: Array<(
      { __typename?: 'GeneralTransition' }
      & Pick<Types.GeneralTransition, 'date' | 'value'>
    )>, customerCount: Array<(
      { __typename?: 'CustomerCountTransition' }
      & Pick<Types.CustomerCountTransition, 'date' | 'newCustomer' | 'newCustomerViaAmbassador' | 'repeater'>
    )>, ambassador: Array<(
      { __typename?: 'AmbassadorTransition' }
      & Pick<Types.AmbassadorTransition, 'date' | 'ambassadorRate' | 'newCustomerViaAmbassadorCount'>
    )>, faveYell: Array<(
      { __typename?: 'FaveYellTransition' }
      & Pick<Types.FaveYellTransition, 'date' | 'faveYellAmount' | 'faveYellUserCount'>
    )> }
  ) }
);

export type CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type CustomerDashboardsGetCustomerVisitedCountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerVisitedCountRanking: (
    { __typename?: 'CustomerRanking' }
    & Pick<Types.CustomerRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerRankingCustomer' }
      & Pick<Types.CustomerRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastVisitedAt'>
    )> }
  ) }
);

export type CustomerDashboardsGetCustomerViralCountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type CustomerDashboardsGetCustomerViralCountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerViralCountRanking: (
    { __typename?: 'CustomerRanking' }
    & Pick<Types.CustomerRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerRankingCustomer' }
      & Pick<Types.CustomerRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastVisitedAt'>
    )> }
  ) }
);

export type CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type CustomerDashboardsGetCustomerFaveYellAmountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerFaveYellAmountRanking: (
    { __typename?: 'CustomerFaveYellRanking' }
    & Pick<Types.CustomerFaveYellRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerFaveYellRankingCustomer' }
      & Pick<Types.CustomerFaveYellRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastFaveYellCreatedAt'>
    )> }
  ) }
);

export type CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables = Types.Exact<{
  input: Types.QueryCustomerRankingInput;
}>;


export type CustomerDashboardsGetCustomerFaveYellCountRankingQuery = (
  { __typename?: 'query_root' }
  & { customerFaveYellCountRanking: (
    { __typename?: 'CustomerFaveYellRanking' }
    & Pick<Types.CustomerFaveYellRanking, 'shopId'>
    & { customers: Array<(
      { __typename?: 'CustomerFaveYellRankingCustomer' }
      & Pick<Types.CustomerFaveYellRankingCustomer, 'customerId' | 'value' | 'lineProfileName' | 'lineProfileImage' | 'lastFaveYellCreatedAt'>
    )> }
  ) }
);

export type CustomerDashboardsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type CustomerDashboardsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);


export const CustomerDashboardsGetCumulativeCountDocument = gql`
    query CustomerDashboardsGetCumulativeCount($input: QueryCumulativeCountInput!) {
  cumulativeCount(input: $input) {
    lineIdCount
    shopId
  }
}
    `;

/**
 * __useCustomerDashboardsGetCumulativeCountQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCumulativeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCumulativeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCumulativeCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCumulativeCountQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCumulativeCountQuery, CustomerDashboardsGetCumulativeCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCumulativeCountQuery, CustomerDashboardsGetCumulativeCountQueryVariables>(CustomerDashboardsGetCumulativeCountDocument, options);
      }
export function useCustomerDashboardsGetCumulativeCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCumulativeCountQuery, CustomerDashboardsGetCumulativeCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCumulativeCountQuery, CustomerDashboardsGetCumulativeCountQueryVariables>(CustomerDashboardsGetCumulativeCountDocument, options);
        }
export type CustomerDashboardsGetCumulativeCountQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCumulativeCountQuery>;
export type CustomerDashboardsGetCumulativeCountLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCumulativeCountLazyQuery>;
export type CustomerDashboardsGetCumulativeCountQueryResult = Apollo.QueryResult<CustomerDashboardsGetCumulativeCountQuery, CustomerDashboardsGetCumulativeCountQueryVariables>;
export const CustomerDashboardsGetCustomerDashboardKpisDocument = gql`
    query CustomerDashboardsGetCustomerDashboardKpis($input: CustomerDashboardKpisInput!) {
  customerDashboardKpis(input: $input) {
    shopId
    sales {
      fluctuatingStatus
      value
    }
    customerCount {
      fluctuatingStatus
      value
    }
    groupCount {
      fluctuatingStatus
      value
    }
    salesPerCustomer {
      fluctuatingStatus
      value
    }
    checkInRate {
      fluctuatingStatus
      value
    }
    faveYellUserCount {
      fluctuatingStatus
      value
    }
    faveYellAmount {
      fluctuatingStatus
      value
    }
    repeaterCount {
      fluctuatingStatus
      value
    }
    newLineIdCount {
      fluctuatingStatus
      value
    }
    ambassadorCount {
      fluctuatingStatus
      value
    }
    newCustomerViaAmbassadorCount {
      fluctuatingStatus
      value
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetCustomerDashboardKpisQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCustomerDashboardKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCustomerDashboardKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCustomerDashboardKpisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCustomerDashboardKpisQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCustomerDashboardKpisQuery, CustomerDashboardsGetCustomerDashboardKpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCustomerDashboardKpisQuery, CustomerDashboardsGetCustomerDashboardKpisQueryVariables>(CustomerDashboardsGetCustomerDashboardKpisDocument, options);
      }
export function useCustomerDashboardsGetCustomerDashboardKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCustomerDashboardKpisQuery, CustomerDashboardsGetCustomerDashboardKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCustomerDashboardKpisQuery, CustomerDashboardsGetCustomerDashboardKpisQueryVariables>(CustomerDashboardsGetCustomerDashboardKpisDocument, options);
        }
export type CustomerDashboardsGetCustomerDashboardKpisQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerDashboardKpisQuery>;
export type CustomerDashboardsGetCustomerDashboardKpisLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerDashboardKpisLazyQuery>;
export type CustomerDashboardsGetCustomerDashboardKpisQueryResult = Apollo.QueryResult<CustomerDashboardsGetCustomerDashboardKpisQuery, CustomerDashboardsGetCustomerDashboardKpisQueryVariables>;
export const CustomerDashboardsGetTransitionsDocument = gql`
    query CustomerDashboardsGetTransitions($input: QueryTransitionsInput!) {
  customerDashboardTransitions(input: $input) {
    shopId
    repeater {
      date
      repeaterRate
    }
    checkInRate {
      date
      value
    }
    newLineIdCount {
      date
      value
    }
    sales {
      date
      value
    }
    salesPerCustomer {
      date
      value
    }
    customerCount {
      date
      newCustomer
      newCustomerViaAmbassador
      repeater
    }
    ambassador {
      date
      ambassadorRate
      newCustomerViaAmbassadorCount
    }
    faveYell {
      date
      faveYellAmount
      faveYellUserCount
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetTransitionsQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetTransitionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetTransitionsQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetTransitionsQuery, CustomerDashboardsGetTransitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetTransitionsQuery, CustomerDashboardsGetTransitionsQueryVariables>(CustomerDashboardsGetTransitionsDocument, options);
      }
export function useCustomerDashboardsGetTransitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetTransitionsQuery, CustomerDashboardsGetTransitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetTransitionsQuery, CustomerDashboardsGetTransitionsQueryVariables>(CustomerDashboardsGetTransitionsDocument, options);
        }
export type CustomerDashboardsGetTransitionsQueryHookResult = ReturnType<typeof useCustomerDashboardsGetTransitionsQuery>;
export type CustomerDashboardsGetTransitionsLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetTransitionsLazyQuery>;
export type CustomerDashboardsGetTransitionsQueryResult = Apollo.QueryResult<CustomerDashboardsGetTransitionsQuery, CustomerDashboardsGetTransitionsQueryVariables>;
export const CustomerDashboardsGetCustomerVisitedCountRankingDocument = gql`
    query CustomerDashboardsGetCustomerVisitedCountRanking($input: QueryCustomerRankingInput!) {
  customerVisitedCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetCustomerVisitedCountRankingQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCustomerVisitedCountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCustomerVisitedCountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCustomerVisitedCountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCustomerVisitedCountRankingQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCustomerVisitedCountRankingQuery, CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCustomerVisitedCountRankingQuery, CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables>(CustomerDashboardsGetCustomerVisitedCountRankingDocument, options);
      }
export function useCustomerDashboardsGetCustomerVisitedCountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCustomerVisitedCountRankingQuery, CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCustomerVisitedCountRankingQuery, CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables>(CustomerDashboardsGetCustomerVisitedCountRankingDocument, options);
        }
export type CustomerDashboardsGetCustomerVisitedCountRankingQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerVisitedCountRankingQuery>;
export type CustomerDashboardsGetCustomerVisitedCountRankingLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerVisitedCountRankingLazyQuery>;
export type CustomerDashboardsGetCustomerVisitedCountRankingQueryResult = Apollo.QueryResult<CustomerDashboardsGetCustomerVisitedCountRankingQuery, CustomerDashboardsGetCustomerVisitedCountRankingQueryVariables>;
export const CustomerDashboardsGetCustomerViralCountRankingDocument = gql`
    query CustomerDashboardsGetCustomerViralCountRanking($input: QueryCustomerRankingInput!) {
  customerViralCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastVisitedAt
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetCustomerViralCountRankingQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCustomerViralCountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCustomerViralCountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCustomerViralCountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCustomerViralCountRankingQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCustomerViralCountRankingQuery, CustomerDashboardsGetCustomerViralCountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCustomerViralCountRankingQuery, CustomerDashboardsGetCustomerViralCountRankingQueryVariables>(CustomerDashboardsGetCustomerViralCountRankingDocument, options);
      }
export function useCustomerDashboardsGetCustomerViralCountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCustomerViralCountRankingQuery, CustomerDashboardsGetCustomerViralCountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCustomerViralCountRankingQuery, CustomerDashboardsGetCustomerViralCountRankingQueryVariables>(CustomerDashboardsGetCustomerViralCountRankingDocument, options);
        }
export type CustomerDashboardsGetCustomerViralCountRankingQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerViralCountRankingQuery>;
export type CustomerDashboardsGetCustomerViralCountRankingLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerViralCountRankingLazyQuery>;
export type CustomerDashboardsGetCustomerViralCountRankingQueryResult = Apollo.QueryResult<CustomerDashboardsGetCustomerViralCountRankingQuery, CustomerDashboardsGetCustomerViralCountRankingQueryVariables>;
export const CustomerDashboardsGetCustomerFaveYellAmountRankingDocument = gql`
    query CustomerDashboardsGetCustomerFaveYellAmountRanking($input: QueryCustomerRankingInput!) {
  customerFaveYellAmountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastFaveYellCreatedAt
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCustomerFaveYellAmountRankingQuery, CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCustomerFaveYellAmountRankingQuery, CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables>(CustomerDashboardsGetCustomerFaveYellAmountRankingDocument, options);
      }
export function useCustomerDashboardsGetCustomerFaveYellAmountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCustomerFaveYellAmountRankingQuery, CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCustomerFaveYellAmountRankingQuery, CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables>(CustomerDashboardsGetCustomerFaveYellAmountRankingDocument, options);
        }
export type CustomerDashboardsGetCustomerFaveYellAmountRankingQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerFaveYellAmountRankingQuery>;
export type CustomerDashboardsGetCustomerFaveYellAmountRankingLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerFaveYellAmountRankingLazyQuery>;
export type CustomerDashboardsGetCustomerFaveYellAmountRankingQueryResult = Apollo.QueryResult<CustomerDashboardsGetCustomerFaveYellAmountRankingQuery, CustomerDashboardsGetCustomerFaveYellAmountRankingQueryVariables>;
export const CustomerDashboardsGetCustomerFaveYellCountRankingDocument = gql`
    query CustomerDashboardsGetCustomerFaveYellCountRanking($input: QueryCustomerRankingInput!) {
  customerFaveYellCountRanking(input: $input) {
    shopId
    customers {
      customerId
      value
      lineProfileName
      lineProfileImage
      lastFaveYellCreatedAt
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetCustomerFaveYellCountRankingQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetCustomerFaveYellCountRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetCustomerFaveYellCountRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetCustomerFaveYellCountRankingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDashboardsGetCustomerFaveYellCountRankingQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetCustomerFaveYellCountRankingQuery, CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetCustomerFaveYellCountRankingQuery, CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables>(CustomerDashboardsGetCustomerFaveYellCountRankingDocument, options);
      }
export function useCustomerDashboardsGetCustomerFaveYellCountRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetCustomerFaveYellCountRankingQuery, CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetCustomerFaveYellCountRankingQuery, CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables>(CustomerDashboardsGetCustomerFaveYellCountRankingDocument, options);
        }
export type CustomerDashboardsGetCustomerFaveYellCountRankingQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerFaveYellCountRankingQuery>;
export type CustomerDashboardsGetCustomerFaveYellCountRankingLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetCustomerFaveYellCountRankingLazyQuery>;
export type CustomerDashboardsGetCustomerFaveYellCountRankingQueryResult = Apollo.QueryResult<CustomerDashboardsGetCustomerFaveYellCountRankingQuery, CustomerDashboardsGetCustomerFaveYellCountRankingQueryVariables>;
export const CustomerDashboardsGetShopsDocument = gql`
    query CustomerDashboardsGetShops($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(where: {archivedAt: {_is_null: true}}) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useCustomerDashboardsGetShopsQuery__
 *
 * To run a query within a React component, call `useCustomerDashboardsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDashboardsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDashboardsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useCustomerDashboardsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<CustomerDashboardsGetShopsQuery, CustomerDashboardsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDashboardsGetShopsQuery, CustomerDashboardsGetShopsQueryVariables>(CustomerDashboardsGetShopsDocument, options);
      }
export function useCustomerDashboardsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDashboardsGetShopsQuery, CustomerDashboardsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDashboardsGetShopsQuery, CustomerDashboardsGetShopsQueryVariables>(CustomerDashboardsGetShopsDocument, options);
        }
export type CustomerDashboardsGetShopsQueryHookResult = ReturnType<typeof useCustomerDashboardsGetShopsQuery>;
export type CustomerDashboardsGetShopsLazyQueryHookResult = ReturnType<typeof useCustomerDashboardsGetShopsLazyQuery>;
export type CustomerDashboardsGetShopsQueryResult = Apollo.QueryResult<CustomerDashboardsGetShopsQuery, CustomerDashboardsGetShopsQueryVariables>;