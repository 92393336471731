import React, { memo } from "react";
import { getTypeName, isDisplayType } from "models/displayType";
import { getTaxMethodName } from "models/taxMethod";
import { formatPrice } from "util/price";
import { isNumber } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";

import {
  EditMenusBulkFormInitialValue,
  EditMenusBulkFormValues,
} from "../EditMenusBulkForm/useEditMenusBulkForm";

type Props = {
  targetCategoryMenus: (EditMenusBulkFormValues & { categoryMenuId: number })[];
  categoryMenuIdToInitialValueMap: Map<number, EditMenusBulkFormInitialValue>;
  onClear: (categoryMenuId: number) => void;
};

export const EditMenusPricesTargetSummary = memo<Props>(
  ({ targetCategoryMenus, categoryMenuIdToInitialValueMap, onClear }) => (
    <Table
      bordered
      dataSource={targetCategoryMenus}
      columns={[
        {
          title: "画像",
          key: "imageUrl",
          dataIndex: "imageUrl",
          width: 100,
          render: (value: string | undefined) => <Thumbnail url={value} width={100} height={100} />,
        },
        {
          title: "商品名",
          key: "name",
          width: 300,
          render: (_, { categoryMenuId }) => {
            const name = categoryMenuIdToInitialValueMap.get(categoryMenuId)?.name ?? "-";
            return name;
          },
        },
        {
          title: "カテゴリ名",
          key: "categoryName",
          width: 300,
          render: (_, { categoryMenuId }) => {
            const name = categoryMenuIdToInitialValueMap.get(categoryMenuId)?.categoryName ?? "-";
            return name;
          },
        },
        {
          title: "画像の表示サイズ",
          dataIndex: "displayType",
          key: "displayType",
          width: 300,
          render: (value: string | undefined) =>
            value && isDisplayType(value) ? getTypeName(value) : "",
        },
        {
          title: "金額",
          dataIndex: "price",
          key: "price",
          width: 300,
          render: (value: number | undefined) => (isNumber(value) ? formatPrice(value) : ""),
        },
        {
          title: "税率",
          dataIndex: "taxRate",
          key: "taxRate",
          width: 150,
          render: (value: number | undefined) => value ?? "",
        },
        {
          title: "税種別",
          dataIndex: "taxMethod",
          key: "taxMethod",
          width: 150,
          render: (value: string | undefined) => (value ? getTaxMethodName(value) : ""),
        },
        {
          title: "原価",
          dataIndex: "costPrice",
          key: "costPrice",
          width: 300,
          render: (value: number | undefined) => (isNumber(value) ? formatPrice(value) : ""),
        },
        {
          title: "原価税率",
          dataIndex: "costTaxRate",
          key: "costTaxRate",
          width: 150,
          render: (value: number | undefined) => value ?? "",
        },
        {
          title: "",
          key: "clear",
          width: 60,
          align: "center",
          render(_, { categoryMenuId }) {
            return <DeleteIcon onClick={() => onClear(categoryMenuId)} />;
          },
        },
      ]}
    />
  ),
);
