import React, { memo } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatPrice, parsePrice } from "util/price";

import { InputNumber } from "components/Input/InputNumber";
import { Table } from "components/Table";

import { OnSitePaymentDiscount } from "../types";

import { useOnSitePaymentDetailsGetDiscountTypesQuery } from "./queries";

const { Option } = Select;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

type Props = {
  shopId: string;
  onSitePaymentDiscounts: OnSitePaymentDiscount[];
  updateOnSitePaymentDiscount(args: {
    onSitePaymentDiscountId: string;
    amount?: number;
    onSitePaymentDiscountType?: string;
  }): void;
  disabled: boolean;
};

const OnSitePaymentDiscountItemLabel = memo<{
  discount: OnSitePaymentDiscount;
  onSitePaymentDiscountTypes: { label: string; type: string }[];
  updateOnSitePaymentDiscount(args: {
    onSitePaymentDiscountId: string;
    amount?: number;
    onSitePaymentDiscountType?: string;
  }): void;
  disabled: boolean;
}>(({ discount, onSitePaymentDiscountTypes, updateOnSitePaymentDiscount, disabled }) => {
  // NOTE: 過去の detailType が現状存在しているとは限らない
  const types = [
    {
      label: discount.onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?.label ?? "",
      type: discount.onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?.type ?? "",
    },
  ].concat(
    onSitePaymentDiscountTypes.filter((type) => type.type !== discount.onSitePaymentDiscountType),
  );

  return (
    <StyledSelect
      defaultValue={discount.onSitePaymentDiscountType}
      onChange={(value) =>
        updateOnSitePaymentDiscount({
          onSitePaymentDiscountId: discount.onSitePaymentDiscountId,
          onSitePaymentDiscountType: String(value),
        })
      }
      disabled={disabled}
    >
      {types.map((type) => (
        <Option key={type.type} value={type.type}>
          {type.label}
        </Option>
      ))}
    </StyledSelect>
  );
});

const OnSitePaymentDiscountItemAmount = memo<{
  discount: OnSitePaymentDiscount;
  updateOnSitePaymentDiscount(args: {
    onSitePaymentDiscountId: string;
    amount?: number;
    onSitePaymentDiscountType?: string;
  }): void;
  disabled: boolean;
}>(({ discount, updateOnSitePaymentDiscount, disabled }) => (
  <InputNumber
    formatter={(value) => formatPrice(Number(value))}
    parser={(value = "") => parsePrice(value)}
    defaultValue={discount.amount}
    onChange={(priceString) =>
      updateOnSitePaymentDiscount({
        onSitePaymentDiscountId: discount.onSitePaymentDiscountId,
        amount: Number(priceString),
      })
    }
    disabled={disabled}
  />
));

export const OnSitePaymentBreakdownByDiscountType = memo<Props>(
  ({ shopId, onSitePaymentDiscounts, updateOnSitePaymentDiscount, disabled }) => {
    const { data } = useOnSitePaymentDetailsGetDiscountTypesQuery({ variables: { shopId } });

    const onSitePaymentDiscountTypes = data?.onSitePaymentDiscountType ?? [];

    const columns: ColumnsType<OnSitePaymentDiscount> = [
      {
        title: "割引方法",
        render(_, discount: OnSitePaymentDiscount) {
          return (
            <OnSitePaymentDiscountItemLabel
              discount={discount}
              onSitePaymentDiscountTypes={onSitePaymentDiscountTypes}
              updateOnSitePaymentDiscount={updateOnSitePaymentDiscount}
              disabled={disabled}
            />
          );
        },
      },
      {
        title: "金額",
        render(_, discount: OnSitePaymentDiscount) {
          return (
            <OnSitePaymentDiscountItemAmount
              discount={discount}
              updateOnSitePaymentDiscount={updateOnSitePaymentDiscount}
              disabled={disabled}
            />
          );
        },
      },
    ];

    return (
      <Table
        bordered
        dataSource={onSitePaymentDiscounts}
        columns={columns}
        rowKey={(row) => row.onSitePaymentDiscountId}
      />
    );
  },
);
