import React, { memo } from "react";
import styled from "styled-components";
import { Tag } from "antd";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { BaseLight } from "styles/typography";
import { Shop } from "types/graphql";

const HiddenShopText = styled(BaseLight)`
  color: ${colors.Text.Default};
`;

type Props = {
  shops: Pick<Shop, "shopId" | "name">[] | null;
  hiddenShopCount: number;
};

export const Dealers = memo<Props>(({ shops, hiddenShopCount }) => (
  <div>
    <span>取扱店舗:</span>
    <Spacer inline width={8} />
    {shops && shops.length > 0 ? (
      <>
        {shops.map(({ shopId, name }) => (
          <Tag key={shopId}>{name}</Tag>
        ))}
        {(hiddenShopCount ?? 0) > 0 && (
          <>
            <Spacer inline width={8} />
            <HiddenShopText>他管理外{hiddenShopCount}店舗</HiddenShopText>
          </>
        )}
      </>
    ) : (hiddenShopCount ?? 0) > 0 ? (
      <HiddenShopText>管理外{hiddenShopCount}店舗</HiddenShopText>
    ) : (
      <Tag color="error">取扱店舗がありません</Tag>
    )}
  </div>
));
