import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddLineOfficialAccountFormItem } from "../useAddLineOfficialAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChannelAccessTokenField = memo<Props>(({ ...props }) => (
  <AddLineOfficialAccountFormItem
    label="長期アクセストークン"
    name="longLivedChannelAccessToken"
    rules={[{ required: true, message: "長期アクセストークンを入力してください" }]}
    {...props}
  >
    <Input />
  </AddLineOfficialAccountFormItem>
));
