import React, { memo } from "react";
import { SettingOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum } from "types/graphql";

export const SettingMenu = memo<SubMenuPropsWithoutFeatures>((props) => (
  <SubMenu
    key="setting"
    icon={<SettingOutlined />}
    title="設定"
    features={[DashboardFeatureEnum.DashboardAccountManagement]}
    {...props}
  >
    <MenuItem to="/setting/account" route="dashboardAccounts" text="アカウント一覧" />
    <MenuItem to="/setting/accountRole" route="dashboardAccountRoles" text="権限一覧" />
  </SubMenu>
));
