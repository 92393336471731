import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Select } from "antd";
import { blue } from "@ant-design/colors";
import { CloseOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: ${blue[0]};
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 8px;
`;

const SelectedCount = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 4px;
`;

const BannerButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

type Props = {
  selectedCount: number;
  onClearSelection: () => void;
  onSubmit: (bulkEditAction: BulkEditAction) => void;
};

export enum BulkEditAction {
  Disable = "disable",
  Enable = "enable",
}

const bulkEditOptions = [
  {
    value: BulkEditAction.Enable,
    label: "自動注文をする",
  },
  {
    value: BulkEditAction.Disable,
    label: "自動注文をしない",
  },
];

export const BulkUpdateIsAppetizerEnable = memo<Props>(
  ({ selectedCount, onClearSelection, onSubmit }) => {
    const [action, setAction] = useState<BulkEditAction | null>(null);

    const handleSubmit = useCallback(() => action && onSubmit(action), [action, onSubmit]);

    return (
      <Wrapper>
        <ClearButton role="button" tabIndex={0} onClick={onClearSelection}>
          <CloseOutlined />
        </ClearButton>

        <SelectedCount>{selectedCount}</SelectedCount>
        <span>件 選択中</span>

        <Spacer size={30} />

        <Select
          placeholder="自動注文の設定"
          dropdownMatchSelectWidth={false}
          value={action}
          onChange={setAction}
          options={bulkEditOptions}
        />
        <BannerButtonsWrapper>
          <Button disabled={!action} type="primary" onClick={handleSubmit}>
            更新する
          </Button>
        </BannerButtonsWrapper>
      </Wrapper>
    );
  },
);
