import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenTerminalPaymentHistoryGetAuthorizedEvents = gql`
    query AdyenTerminalPaymentHistoryGetAuthorizedEvents($shopId: uuid!, $since: timestamptz!, $until: timestamptz!) {
  adyenPaymentReportTerminalPaymentAuthorizedEvent(
    where: {shopId: {_eq: $shopId}, createdAt: {_gte: $since, _lt: $until}}
  ) {
    id
    createdAt
    amount
    paymentMethod
    pspReference
    adyenPaymentReportTerminalPaymentCanceledEvent {
      id
      createdAt
    }
  }
}
    `;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  since: Types.Scalars['timestamptz'];
  until: Types.Scalars['timestamptz'];
}>;


export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentReportTerminalPaymentAuthorizedEvent: Array<(
    { __typename?: 'adyenPaymentReportTerminalPaymentAuthorizedEvent' }
    & Pick<Types.AdyenPaymentReportTerminalPaymentAuthorizedEvent, 'id' | 'createdAt' | 'amount' | 'paymentMethod' | 'pspReference'>
    & { adyenPaymentReportTerminalPaymentCanceledEvent?: Types.Maybe<(
      { __typename?: 'adyenPaymentReportTerminalPaymentCanceledEvent' }
      & Pick<Types.AdyenPaymentReportTerminalPaymentCanceledEvent, 'id' | 'createdAt'>
    )> }
  )> }
);


export const AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument = gql`
    query AdyenTerminalPaymentHistoryGetAuthorizedEvents($shopId: uuid!, $since: timestamptz!, $until: timestamptz!) {
  adyenPaymentReportTerminalPaymentAuthorizedEvent(
    where: {shopId: {_eq: $shopId}, createdAt: {_gte: $since, _lt: $until}}
  ) {
    id
    createdAt
    amount
    paymentMethod
    pspReference
    adyenPaymentReportTerminalPaymentCanceledEvent {
      id
      createdAt
    }
  }
}
    `;

/**
 * __useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery__
 *
 * To run a query within a React component, call `useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      since: // value for 'since'
 *      until: // value for 'until'
 *   },
 * });
 */
export function useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery(baseOptions: Apollo.QueryHookOptions<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>(AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument, options);
      }
export function useAdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>(AdyenTerminalPaymentHistoryGetAuthorizedEventsDocument, options);
        }
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery>;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQueryHookResult = ReturnType<typeof useAdyenTerminalPaymentHistoryGetAuthorizedEventsLazyQuery>;
export type AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryResult = Apollo.QueryResult<AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery, AdyenTerminalPaymentHistoryGetAuthorizedEventsQueryVariables>;