import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";

import { ShouldPrintPaymentReceiptTablePrintingSettingTable } from "./PaymentReceiptRoleTablePrintingSettingTable";
import {
  useDeletePaymentReceiptRoleTablePrintingTablesMutation,
  useInsertPaymentReceiptRoleTablePrintingTablesMutation,
  usePaymentReceiptRoleTablePrintingGetTablesQuery,
} from "./queries";

export const PaymentReceiptRoleTablePrinting = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, loading, refetch, error } = usePaymentReceiptRoleTablePrintingGetTablesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const [insertPaymentReceiptRoleTablePrintingTablesMutation] =
    useInsertPaymentReceiptRoleTablePrintingTablesMutation();

  const [deletePaymentReceiptRoleTablePrintingTablesMutation] =
    useDeletePaymentReceiptRoleTablePrintingTablesMutation();

  const tables = useMemo(() => data?.table ?? [], [data]);
  const roles = useMemo(() => data?.role ?? [], [data]);

  const handleToggle = useCallback(
    async ({ tableId, _roleId }: { tableId: number; _roleId: number }) => {
      try {
        if (!shopId) throw new Error("shopId is not available");

        const table = tables.find((table) => table.tableId === tableId);

        if (!table) throw new Error("no table found");

        const role = roles.find((role) => role.roleId === _roleId);

        if (!role) throw new Error("no role found");

        const checked = table?.paymentReceiptRoleTables.some(
          ({ roleId: paymentReceiptRoleTableRoleId }) => paymentReceiptRoleTableRoleId === role.id,
        );

        if (checked) {
          await deletePaymentReceiptRoleTablePrintingTablesMutation({
            variables: { roleId: role.id, tableId },
          });
        } else {
          await insertPaymentReceiptRoleTablePrintingTablesMutation({
            variables: {
              input: {
                roleId: role.id,
                _roleId: role.roleId,
                tableId: table.id,
                _tableId: table.tableId,
                shopId,
              },
            },
          });
        }
        message.success("設定を更新しました");
      } catch (e) {
        message.error("設定の更新に失敗しました");
      }

      await refetch();
    },
    [
      shopId,
      tables,
      roles,
      deletePaymentReceiptRoleTablePrintingTablesMutation,
      insertPaymentReceiptRoleTablePrintingTablesMutation,
      refetch,
    ],
  );

  return (
    <DashboardLayout title="自動会計伝票テーブル出し分け">
      <PageHeader
        title="自動会計伝票テーブル出し分け"
        footer={
          <>
            <ShopSelector />
            <div>
              テーブル出し分けが無効の場合、テーブルによらずメニューが紐づくすべてのプリンターから印刷されます。
            </div>
          </>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ShouldPrintPaymentReceiptTablePrintingSettingTable
        tables={tables}
        loading={loading}
        roles={roles}
        onTogglePrintingConfig={handleToggle}
      />
    </DashboardLayout>
  );
};
