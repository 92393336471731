import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { Shop } from "../types";

const getInitialValues = ({ shop }: { shop: Shop }) => ({
  enableGoogleMapReview: shop.enableGoogleMapReview,
  googleMapPlaceId: shop.googleMapPlaceId,
});

export type EditGoogleMapConfigFormValues = {
  enableGoogleMapReview: boolean;
  googleMapPlaceId: string;
};

export const EditGoogleMapConfigFormItem = createFormItem<EditGoogleMapConfigFormValues>();

export const useEditGoogleMapConfigForm = ({
  shop,
  onSubmit,
}: {
  shop: Shop;
  onSubmit: (form: EditGoogleMapConfigFormValues) => void;
}) => {
  const [form] = Form.useForm<EditGoogleMapConfigFormValues>();
  const initialValues = getInitialValues({ shop });

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue() as EditGoogleMapConfigFormValues);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
