import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type InputShopDataFormValues = {
  table: File;
  dishUpSlipGroup: File;
  role: File;
  shopMenu: File;
  shopPlan: File;
  shopOrderableTimeTerm: File;
  cookingItem: File;
};

export const InputShopDataFormItem = createFormItem<InputShopDataFormValues>();

export const useInputShopDataForm = ({
  onSubmit,
}: {
  onSubmit: (args: InputShopDataFormValues) => void;
}) => {
  const [form] = Form.useForm<InputShopDataFormValues>();

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue());
  }, [form, onSubmit]);

  return { form, submit };
};
