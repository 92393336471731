import { useCallback, useMemo } from "react";
import { Form } from "antd";
import dayjs from "dayjs";

import { createFormItem } from "components/antd/Form";

export type CompulsoryAppetizerSelectFormValues = {
  menuId: string;
  autoOrderStartTime: dayjs.Dayjs;
  autoOrderEndTime: dayjs.Dayjs;
};

export const CompulsoryAppetizerSelectFormItem =
  createFormItem<CompulsoryAppetizerSelectFormValues>();

export const useCompulsoryAppetizerSelectForm = ({
  onSubmit,
  onDeleteCompulsoryAppetizerConfig,
  compulsoryAppetizerConfig,
}: {
  onSubmit: ({
    autoOrderStartTime,
    autoOrderEndTime,
    menuId,
  }: {
    autoOrderStartTime: dayjs.Dayjs;
    autoOrderEndTime: dayjs.Dayjs;
    menuId: string;
  }) => void;
  onDeleteCompulsoryAppetizerConfig: () => void;
  compulsoryAppetizerConfig:
    | {
        autoOrderStartTime: string;
        autoOrderEndTime: string;
        menuId: string;
      }
    | undefined;
}) => {
  const [form] = Form.useForm<CompulsoryAppetizerSelectFormValues>();

  const initialValues = useMemo(
    () =>
      compulsoryAppetizerConfig
        ? {
            menuId: compulsoryAppetizerConfig.menuId,
            autoOrderStartTime: dayjs(compulsoryAppetizerConfig?.autoOrderStartTime, "HH:mm:00"),
            autoOrderEndTime: dayjs(compulsoryAppetizerConfig?.autoOrderEndTime, "HH:mm:00"),
          }
        : null,
    [compulsoryAppetizerConfig],
  );

  const submit = useCallback(async () => {
    const values = form.getFieldsValue();
    const { autoOrderStartTime, autoOrderEndTime, menuId } = values;

    const submitValues = {
      autoOrderStartTime: autoOrderStartTime ?? initialValues?.autoOrderStartTime,
      autoOrderEndTime: autoOrderEndTime ?? initialValues?.autoOrderEndTime,
      menuId: menuId ?? initialValues?.menuId,
    };

    if (
      !submitValues.autoOrderEndTime ||
      !submitValues.autoOrderStartTime ||
      !submitValues.menuId
    ) {
      return;
    }

    await onSubmit(submitValues);
  }, [form, onSubmit, initialValues]);

  return {
    form,
    initialValues,
    submit,
    deleteCompulsoryAppetizerConfig: onDeleteCompulsoryAppetizerConfig,
  };
};
