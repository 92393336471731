import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { MenuTable } from "pages/PlanMenus/MenuTable";
import { usePlanMenusGetMenusQuery, usePlanMenusGetPlanQuery } from "pages/PlanMenus/queries";

export const PlanMenus = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const {
    data: getPlanData,
    loading: loadingPlan,
    error,
  } = usePlanMenusGetPlanQuery(!Number.isNaN(planId) ? { variables: { planId } } : { skip: true });
  const plan = getPlanData?.plan?.[0];

  setCompany(plan?.companyId);

  const planMenuCategories = plan?.planMenuCategories ?? [];
  const categoryIds = planMenuCategories?.map(({ category }) => category.categoryId);

  const { data: getMenusData } = usePlanMenusGetMenusQuery(
    categoryIds ? { variables: { categoryIds } } : { skip: true },
  );
  const categoryMenus = getMenusData?.category.flatMap(({ categoryMenus }) => categoryMenus) ?? [];

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout title={plan?.planName}>
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
      />
      {loadingPlan && <Loading height={300} />}
      {plan && (
        <FormContent>
          <MenuTable loading={loadingPlan} categoryMenus={categoryMenus} />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
