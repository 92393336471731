import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { MenusField } from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal/AddDishUpSlipGroupMenuForm/MenusField";
import { useAddDishUpSlipGroupMenuForm } from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal/AddDishUpSlipGroupMenuForm/useAddDishUpSlipGroupMenuForm";
import { Category } from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal/types";
import { DishUpSlipGroup } from "pages/DishUpSlipGroupMenus/types";

type Props = {
  dishUpSlipGroup: DishUpSlipGroup;
  categories: Category[];
  onChange: ({ menuIds }: { menuIds: number[] }) => void;
};

export const AddMenuForm = memo<Props>(({ dishUpSlipGroup, categories, onChange }) => {
  const { form, initialValues, change: onValuesChange } = useAddDishUpSlipGroupMenuForm(onChange);

  const menuIds = dishUpSlipGroup.dishUpSlipGroupShopMenus.map(({ menu }) => menu?.menuId);
  const filteredCategories = categories.map((category) => ({
    ...category,
    categoryMenus: category.categoryMenus.filter(({ menu }) => !menuIds.includes(menu.menuId)),
  }));

  return (
    <Form
      name="menu"
      form={form}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      layout="vertical"
    >
      <MenusField categories={filteredCategories} />
    </Form>
  );
});
