import React from "react";
import { parsePrice } from "util/input/parsePrice";

import { InputNumber, Props as InputNumberProps } from "components/Input/InputNumber";

type Props = InputNumberProps;

export const InputPrice = (props: Props) => (
  <InputNumber formatter={(value) => `${value}円`} parser={parsePrice} min={0} {...props} />
);
