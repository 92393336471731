import { useCallback, useMemo, useState } from "react";
import { sum } from "util/array";
import { formatPrice } from "util/price";

import { message } from "components/antd/message";
import { AccountingHistoriesGetOnSitePaymentsDocument } from "pages/AccountingHistory/queries";
import { Maybe } from "types/Maybe";

import {
  AccountingDetailGetOnSitePaymentDocument,
  useAccountingDetailUpdateOnSitePaymentMutation,
} from "./queries";
import { OnSitePayment } from "./types";

export const useUpdateOnSitePayment = ({
  onSitePayment,
  shopId,
  date,
}: {
  onSitePayment?: Maybe<OnSitePayment>;
  shopId: Maybe<string>;
  date: Maybe<string>;
}) => {
  const [editedOnSitePaymentDetailMap, setEditedOnSitePaymentDetailMap] = useState<
    Map<
      string,
      {
        changePrice: number;
        description?: Maybe<string>;
        netPrice: number;
        onSitePaymentDetailType: string;
        receivedPrice: number;
      }
    >
  >(new Map());
  const updateOnSitePaymentDetail = useCallback(
    ({
      onSitePaymentDetailId,
      onSitePaymentDetailType,
      netPrice,
    }: {
      onSitePaymentDetailId: string;
      onSitePaymentDetailType?: string;
      netPrice?: number;
    }) => {
      const original = onSitePayment?.onSitePaymentDetails.find(
        (detail) => detail.onSitePaymentDetailId === onSitePaymentDetailId,
      );
      if (!original) return;
      setEditedOnSitePaymentDetailMap((prev) => {
        const ret = new Map(prev);
        const current = ret.get(onSitePaymentDetailId);
        const base = current ?? original;
        ret.set(onSitePaymentDetailId, {
          changePrice: base.changePrice,
          receivedPrice: base.receivedPrice,
          onSitePaymentDetailType: onSitePaymentDetailType ?? base.onSitePaymentDetailType,
          netPrice: netPrice ?? base.netPrice,
          description: base.description,
        });
        return ret;
      });
    },
    [onSitePayment?.onSitePaymentDetails],
  );

  const [editedOnSitePaymentDiscountMap, setEditedOnSitePaymentDiscountMap] = useState<
    Map<
      string,
      {
        amount: number;
        onSitePaymentDiscountType: string;
      }
    >
  >(new Map());
  const updateOnSitePaymentDiscount = useCallback(
    ({
      onSitePaymentDiscountId,
      onSitePaymentDiscountType,
      amount,
    }: {
      onSitePaymentDiscountId: string;
      amount?: number;
      onSitePaymentDiscountType?: string;
    }) => {
      const original = onSitePayment?.onSitePaymentDiscounts.find(
        (discount) => discount.onSitePaymentDiscountId === onSitePaymentDiscountId,
      );
      if (!original) return;
      setEditedOnSitePaymentDiscountMap((prev) => {
        const ret = new Map(prev);
        const current = ret.get(onSitePaymentDiscountId);
        const base = current ?? original;
        ret.set(onSitePaymentDiscountId, {
          onSitePaymentDiscountType: onSitePaymentDiscountType ?? base.onSitePaymentDiscountType,
          amount: amount ?? base.amount,
        });
        return ret;
      });
    },
    [onSitePayment?.onSitePaymentDiscounts],
  );

  const [editedPayingActivePlanChoiceMap, setEditedPayingActivePlanChoiceMap] = useState<
    Map<
      string,
      {
        activePlanChoiceId: string;
        price: number;
        quantity: number;
      }
    >
  >(new Map());
  const updatePayingActivePlanChoice = useCallback(
    ({
      payingActivePlanChoiceId,
      activePlanChoiceId,
      price,
      quantity,
    }: {
      payingActivePlanChoiceId: string;
      activePlanChoiceId: string;
      price?: number;
      quantity?: number;
    }) => {
      const original = onSitePayment?.payingActivePlanChoices.find(
        (choice) => choice.payingActivePlanChoiceId === payingActivePlanChoiceId,
      );
      if (!original) return;

      setEditedPayingActivePlanChoiceMap((prev) => {
        const ret = new Map(prev);
        const current = ret.get(payingActivePlanChoiceId);
        const base = current ?? original;
        ret.set(payingActivePlanChoiceId, {
          activePlanChoiceId,
          price: price ?? base.price,
          quantity: quantity ?? base.quantity,
        });
        return ret;
      });
    },
    [onSitePayment?.payingActivePlanChoices],
  );

  const [editedPayingActivePlanOpenPriceMetaMap, setEditedPayingActivePlanOpenPriceMetaMap] =
    useState<
      Map<
        string,
        {
          activePlanOpenPriceMetaId: string;
          price: number;
          quantity: number;
        }
      >
    >(new Map());
  const updatePayingActivePlanOpenPriceMeta = useCallback(
    ({
      payingActivePlanOpenPriceMetaId,
      activePlanOpenPriceMetaId,
      price,
      quantity,
    }: {
      payingActivePlanOpenPriceMetaId: string;
      activePlanOpenPriceMetaId: string;
      price: number;
      quantity: number;
    }) => {
      const original = onSitePayment?.payingActivePlanOpenPriceMetas.find(
        (meta) => meta.payingActivePlanOpenPriceMetaId === payingActivePlanOpenPriceMetaId,
      );
      if (!original) return;
      setEditedPayingActivePlanOpenPriceMetaMap((prev) => {
        const ret = new Map(prev);
        const current = ret.get(payingActivePlanOpenPriceMetaId);
        const base = current ?? original;
        ret.set(payingActivePlanOpenPriceMetaId, {
          activePlanOpenPriceMetaId,
          price: price ?? base.price,
          quantity: quantity ?? base.quantity,
        });
        return ret;
      });
    },
    [onSitePayment?.payingActivePlanOpenPriceMetas],
  );

  const [editedPayingMenuOrderItemMap, setEditedPayingMenuOrderItemMap] = useState<
    Map<
      string,
      {
        menuOrderItemId: string;
        price: number;
        quantity: number;
      }
    >
  >(new Map());
  const updatePayingMenuOrderItem = useCallback(
    ({
      payingMenuOrderItemId,
      menuOrderItemId,
      price,
      quantity,
    }: {
      payingMenuOrderItemId: string;
      menuOrderItemId: string;
      price?: number;
      quantity?: number;
    }) => {
      const original = onSitePayment?.payingMenuOrderItems.find(
        (item) => item.id === payingMenuOrderItemId,
      );
      if (!original) return;
      setEditedPayingMenuOrderItemMap((prev) => {
        const ret = new Map(prev);
        const current = ret.get(payingMenuOrderItemId);
        const base = current ?? original;
        ret.set(payingMenuOrderItemId, {
          menuOrderItemId,
          price: price ?? base.price,
          quantity: quantity ?? base.quantity,
        });
        return ret;
      });
    },
    [onSitePayment?.payingMenuOrderItems],
  );

  const [updateOnSitePaymentMutation, { loading }] =
    useAccountingDetailUpdateOnSitePaymentMutation();

  const updateInput = useMemo(() => {
    if (!onSitePayment) return null;
    return {
      newOnSitePaymentDetails: onSitePayment.onSitePaymentDetails.map((detail) => {
        const newDetail = editedOnSitePaymentDetailMap.get(detail.onSitePaymentDetailId);
        return (
          newDetail ?? {
            netPrice: detail.netPrice,
            changePrice: detail.changePrice,
            receivedPrice: detail.receivedPrice,
            description: detail.description,
            onSitePaymentDetailType: detail.onSitePaymentDetailType,
          }
        );
      }),
      newOnSitePaymentDiscounts: onSitePayment.onSitePaymentDiscounts.map((discount) => {
        const newDiscount = editedOnSitePaymentDiscountMap.get(discount.onSitePaymentDiscountId);
        return (
          newDiscount ?? {
            amount: discount.amount,
            onSitePaymentDiscountType: discount.onSitePaymentDiscountType,
          }
        );
      }),
      newPayingActivePlanChoices: onSitePayment.payingActivePlanChoices.map((choice) => {
        const newChoice = editedPayingActivePlanChoiceMap.get(choice.payingActivePlanChoiceId);
        return (
          newChoice ?? {
            activePlanChoiceId: choice.activePlanChoiceId,
            price: choice.price,
            quantity: choice.quantity,
          }
        );
      }),
      newPayingActivePlanOpenPriceMetas: onSitePayment.payingActivePlanOpenPriceMetas.map(
        (meta) => {
          const newMeta = editedPayingActivePlanOpenPriceMetaMap.get(
            meta.payingActivePlanOpenPriceMetaId,
          );
          return (
            newMeta ?? {
              activePlanOpenPriceMetaId: meta.activePlanOpenPriceMetaId,
              price: meta.price,
              quantity: meta.quantity,
            }
          );
        },
      ),
      newPayingMenuOrderItems: onSitePayment.payingMenuOrderItems.map((item) => {
        const newItem = editedPayingMenuOrderItemMap.get(item.id);
        return (
          newItem ?? {
            menuOrderItemId: item.menuOrderItemId,
            price: item.price,
            quantity: item.quantity,
          }
        );
      }),
    };
  }, [
    editedOnSitePaymentDetailMap,
    editedOnSitePaymentDiscountMap,
    editedPayingActivePlanChoiceMap,
    editedPayingActivePlanOpenPriceMetaMap,
    editedPayingMenuOrderItemMap,
    onSitePayment,
  ]);

  const updateOnSitePayment = useCallback(async () => {
    if (!onSitePayment || !updateInput || !shopId) return;

    const clerk = onSitePayment.onSitePaymentDetails
      .map((d) => d.clerk)
      .concat(onSitePayment.onSitePaymentDiscounts.map((d) => d.clerk))[0];

    if (!clerk) return;

    try {
      const {
        newOnSitePaymentDetails,
        newOnSitePaymentDiscounts,
        newPayingActivePlanChoices,
        newPayingActivePlanOpenPriceMetas,
        newPayingMenuOrderItems,
      } = updateInput;

      const { errors } = await updateOnSitePaymentMutation({
        refetchQueries: [
          {
            query: AccountingDetailGetOnSitePaymentDocument,
            variables: { onSitePaymentId: onSitePayment.onSitePaymentId },
          },
          {
            query: AccountingHistoriesGetOnSitePaymentsDocument,
            variables: { shopId, targetDate: date },
          },
        ],
        variables: {
          input: {
            shopId,
            clerkId: clerk.id,
            _clerkId: clerk.clerkId,
            onSitePaymentId: onSitePayment.id,
            _onSitePaymentId: onSitePayment.onSitePaymentId,
            newOnSitePaymentDetails,
            newOnSitePaymentDiscounts,
            newPayingActivePlanChoices,
            newPayingActivePlanOpenPriceMetas,
            newPayingMenuOrderItems,
          },
        },
      });
      if (errors) {
        message.error(`修正に失敗しました ${errors[0]?.message ?? ""}`);
      } else {
        message.success("修正が完了しました");
      }
    } catch (e) {
      message.error(`修正に失敗しました ${e instanceof Error ? e.message : ""}`);
    }
  }, [onSitePayment, updateInput, updateOnSitePaymentMutation, shopId, date]);

  const validationErrorMessage = useMemo(() => {
    if (!updateInput) return "会計情報が取得できません。";
    const paymentAmount =
      sum(updateInput.newOnSitePaymentDetails.map((d) => d.netPrice)) +
      sum(updateInput.newOnSitePaymentDiscounts.map((d) => d.amount));
    const itemAmount =
      sum(updateInput.newPayingActivePlanChoices.map((d) => d.price * d.quantity)) +
      sum(updateInput.newPayingActivePlanOpenPriceMetas.map((d) => d.price * d.quantity)) +
      sum(updateInput.newPayingMenuOrderItems.map((d) => d.price * d.quantity));

    if (paymentAmount !== itemAmount) {
      return `商品総額: ${formatPrice(itemAmount)}\n支払・割引総額: ${formatPrice(paymentAmount)}`;
    }
    return null;
  }, [updateInput]);

  return {
    updateOnSitePaymentDetail,
    updateOnSitePaymentDiscount,
    updatePayingActivePlanChoice,
    updatePayingActivePlanOpenPriceMeta,
    updatePayingMenuOrderItem,
    loading,
    updateOnSitePayment,
    validationErrorMessage,
  };
};
