import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPikaichiOnSitePaymentDiscountGetDetailType = gql`
    query EditPikaichiOnSitePaymentDiscountGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    pikaichiOnSitePaymentDiscountTypes(where: {companyId: {_eq: $companyId}}) {
      pikaichiDiscountTypeId
      onSitePaymentDiscountTypeKey
      newariKubun
      newariCd
      newariName
    }
  }
}
    `;
export const EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailType = gql`
    mutation EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailType($pikaichiOnSitePaymentDiscountType: pikaichiOnSitePaymentDiscountType_insert_input!) {
  insert_pikaichiOnSitePaymentDiscountType_one(
    object: $pikaichiOnSitePaymentDiscountType
    on_conflict: {constraint: PikaichiOnSitePaymentDiscountType_pkey, update_columns: [newariKubun, newariCd, newariName, onSitePaymentDiscountTypeId]}
  ) {
    pikaichiDiscountTypeId
  }
}
    `;
export type EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables = Types.Exact<{
  type: Types.Scalars['String'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'id' | 'type' | 'label'>
    & { pikaichiOnSitePaymentDiscountTypes: Array<(
      { __typename?: 'pikaichiOnSitePaymentDiscountType' }
      & Pick<Types.PikaichiOnSitePaymentDiscountType, 'pikaichiDiscountTypeId' | 'onSitePaymentDiscountTypeKey' | 'newariKubun' | 'newariCd' | 'newariName'>
    )> }
  )> }
);

export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationVariables = Types.Exact<{
  pikaichiOnSitePaymentDiscountType: Types.PikaichiOnSitePaymentDiscountTypeInsertInput;
}>;


export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiOnSitePaymentDiscountType_one?: Types.Maybe<(
    { __typename?: 'pikaichiOnSitePaymentDiscountType' }
    & Pick<Types.PikaichiOnSitePaymentDiscountType, 'pikaichiDiscountTypeId'>
  )> }
);


export const EditPikaichiOnSitePaymentDiscountGetDetailTypeDocument = gql`
    query EditPikaichiOnSitePaymentDiscountGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    pikaichiOnSitePaymentDiscountTypes(where: {companyId: {_eq: $companyId}}) {
      pikaichiDiscountTypeId
      onSitePaymentDiscountTypeKey
      newariKubun
      newariCd
      newariName
    }
  }
}
    `;

/**
 * __useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery__
 *
 * To run a query within a React component, call `useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery, EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery, EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables>(EditPikaichiOnSitePaymentDiscountGetDetailTypeDocument, options);
      }
export function useEditPikaichiOnSitePaymentDiscountGetDetailTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery, EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery, EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables>(EditPikaichiOnSitePaymentDiscountGetDetailTypeDocument, options);
        }
export type EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery>;
export type EditPikaichiOnSitePaymentDiscountGetDetailTypeLazyQueryHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDiscountGetDetailTypeLazyQuery>;
export type EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryResult = Apollo.QueryResult<EditPikaichiOnSitePaymentDiscountGetDetailTypeQuery, EditPikaichiOnSitePaymentDiscountGetDetailTypeQueryVariables>;
export const EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeDocument = gql`
    mutation EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailType($pikaichiOnSitePaymentDiscountType: pikaichiOnSitePaymentDiscountType_insert_input!) {
  insert_pikaichiOnSitePaymentDiscountType_one(
    object: $pikaichiOnSitePaymentDiscountType
    on_conflict: {constraint: PikaichiOnSitePaymentDiscountType_pkey, update_columns: [newariKubun, newariCd, newariName, onSitePaymentDiscountTypeId]}
  ) {
    pikaichiDiscountTypeId
  }
}
    `;
export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationFn = Apollo.MutationFunction<EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationVariables>;

/**
 * __useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation__
 *
 * To run a mutation, you first call `useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation, { data, loading, error }] = useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation({
 *   variables: {
 *      pikaichiOnSitePaymentDiscountType: // value for 'pikaichiOnSitePaymentDiscountType'
 *   },
 * });
 */
export function useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationVariables>(EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeDocument, options);
      }
export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation>;
export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationResult = Apollo.MutationResult<EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation>;
export type EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationOptions = Apollo.BaseMutationOptions<EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutationVariables>;