import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuFormItem } from "../../useEditMenuForm";
import { CountFieldWrapper } from "../CountFieldWrapper";
import { OrderLimitFieldContainer } from "../OrderLimitFieldContainer";

import { OrderLimitCountPerTableUserField } from "./OrderLimitCountPerTableUserField";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

const BoldSpan = styled.span`
  font-weight: bold;
`;

export const OrderLimitPerTableUserField = memo<Props>(({ disabled, ...props }) => {
  const checkboxRef = CountFieldWrapper.useRef();

  return (
    <EditMenuFormItem name="hasOrderLimitPerTableUser" valuePropName="checked" {...props}>
      <Checkbox disabled={disabled} ref={checkboxRef}>
        <OrderLimitFieldContainer>
          <BoldSpan>来店ごと</BoldSpan>に、<BoldSpan>1組あたり</BoldSpan>
          <CountFieldWrapper checkboxRef={checkboxRef}>
            <OrderLimitCountPerTableUserField disabled={disabled} />
          </CountFieldWrapper>
          注文できるようにする
        </OrderLimitFieldContainer>
      </Checkbox>
    </EditMenuFormItem>
  );
});
