import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Title from "antd/lib/typography/Title";
import { isNotNullable } from "util/type/primitive";

import { CompanyList } from "components/Layout/DashboardLayout/CompanySelect/CompanyList";
import { useWindowHeight } from "components/Layout/DashboardLayout/CompanySelect/useWindowHeight";
import { Company } from "components/Layout/DashboardLayout/types";
import { useCompany } from "hooks/useCompany";
import { useStorage } from "hooks/useStorage";
import { useViewport } from "hooks/useViewport";

const Wrapper = styled.div``;

const TitleContainer = styled.div`
  padding: 20px 24px;
  padding-bottom: 0;
`;

const StyledTitle = styled(Title)`
  line-height: 32px;
  margin: 0;
`;

const SEARCH_HEIGHT = 48;

type Props = {
  companies?: Company[];
  onSelect: (companyId: string) => void;
  columnCount: number;
};

export const HistorySelect = memo<Props>(({ companies, onSelect, columnCount }) => {
  const { loadCompanyIds } = useStorage();
  const [company] = useCompany();

  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);

  useEffect(() => {
    setFilteredCompanies(
      loadCompanyIds()
        .companyIds.map((id) => companies?.find((c) => c.companyId === id || c.id === id))
        .filter(isNotNullable),
    );
    // NOTE: company が変わるたびに履歴を更新する
  }, [company, companies, loadCompanyIds]);

  const { isSmartphone } = useViewport();
  const { height } = useWindowHeight();
  const companySelectHeight = isSmartphone ? height * 0.4 : height - SEARCH_HEIGHT;

  return (
    <Wrapper>
      <TitleContainer>
        <StyledTitle level={3}>履歴</StyledTitle>
      </TitleContainer>
      <CompanyList
        companies={filteredCompanies ?? []}
        onSelect={onSelect}
        height={companySelectHeight}
        columnCount={columnCount}
      />
    </Wrapper>
  );
});
