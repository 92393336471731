import { useCallback } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";

import { ExternalOnlineMenuConfig } from "../types";

type EditShopExternalOnlineMenuConfigFormValues = Pick<ExternalOnlineMenuConfig, "defaultMenuUrl">;

export const EditShopExternalOnlineMenuConfigFormItem =
  createFormItem<EditShopExternalOnlineMenuConfigFormValues>();

export const useEditShopExternalOnlineMenuConfigForm = (
  shopId: string,
  externalOnlineMenuConfig: ExternalOnlineMenuConfig,
  onSubmit: ({ id, defaultMenuUrl }: { id: string; defaultMenuUrl: string }) => void,
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues: EditShopExternalOnlineMenuConfigFormValues = {
    defaultMenuUrl: externalOnlineMenuConfig.defaultMenuUrl,
  };

  const submit = useCallback(async () => {
    try {
      await form.validateFields();

      const { defaultMenuUrl } =
        form.getFieldsValue() as EditShopExternalOnlineMenuConfigFormValues;

      onSubmit({
        id: externalOnlineMenuConfig.id,
        defaultMenuUrl: defaultMenuUrl ?? "",
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, externalOnlineMenuConfig.id, onFormValidationError]);

  return { form, initialValues, submit };
};
