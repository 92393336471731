import { useChangeDateTimeGetChangeDateTimeQuery } from "hooks/useChangeDateTime/queries";
import { useShop } from "hooks/useShop";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

export const useChangeDateTime = () => {
  const [shop] = useShop();
  const { shop: shopPerCorporation } = useShopPerCorporation();
  const shopId = shop?.shopId ?? shopPerCorporation?.shopId;

  const { data: getChangeDateTimeData } = useChangeDateTimeGetChangeDateTimeQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const changeDateTime = getChangeDateTimeData?.shop_by_pk?.changeDateTime;

  return {
    changeDateTime,
  };
};
