import React from "react";
import { parseQuantity } from "util/input/parseQuantity";

import { InputNumber, Props as InputNumberProps } from "components/Input/InputNumber";

type Props = InputNumberProps;

export const InputQuantity = (props: Props) => (
  <InputNumber formatter={(value) => `${value}個`} parser={parseQuantity} min={1} {...props} />
);
