import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { EditCouponFormItem } from "../useEditCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <EditCouponFormItem
    label="説明文"
    name="description"
    rules={[{ required: true, message: "クーポンの説明を入力してください" }]}
    {...props}
  >
    <TextArea rows={4} placeholder="コロナ明け記念！" />
  </EditCouponFormItem>
));
