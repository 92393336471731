import React, { CSSProperties, memo, ReactNode, useRef } from "react";
import { DragObjectWithType, useDrag, useDrop } from "react-dnd";
import styled from "styled-components";
import { TableProps } from "antd/lib/table";
import { MenuOutlined } from "@ant-design/icons";

import { Table } from "components/antd/Table";
import { Loading } from "components/Loading";

import { Table as TableType, TableArea } from "../types";

import { TableAreaSortableTableProvider, useTableAreaSortableTableContext } from "./context";

const DROP_TYPE = "table";

export type TableAreaRowItem =
  | {
      type: "table";
      id: string;
      index: number;
      tableAreaId: string;
    }
  | {
      type: "tableArea";
      id: string;
      index: number;
    };

type BodyRowProps = {
  className: string;
  style?: CSSProperties;
  // NOTE: 空表示の行、expand 表示の行についても BodyRow が使用されるが、その場合 rowItem, onMove は undefined となる
  rowItem?: TableAreaRowItem;
  onMove?: (args: { draggingRowItem: TableAreaRowItem; targetRowItem: TableAreaRowItem }) => void;
};

const getIsDropAllowed = ({
  draggingRowItem,
  targetRowItem,
}: {
  draggingRowItem: TableAreaRowItem;
  targetRowItem: TableAreaRowItem;
}) => {
  if (draggingRowItem.id === targetRowItem.id) return false;

  return (
    (draggingRowItem.type === "tableArea" && targetRowItem.type === "tableArea") ||
    (draggingRowItem.type === "table" && targetRowItem.type === "table") ||
    (draggingRowItem.type === "table" &&
      targetRowItem.type === "tableArea" &&
      draggingRowItem.tableAreaId !== targetRowItem.id)
  );
};

const BodyRow = memo<BodyRowProps>(({ rowItem, className, style, onMove, ...props }) => {
  const dragRef = useRef<HTMLTableRowElement>(null);
  const previewRef = useRef<HTMLTableRowElement>(null);
  const isDragDropTarget = Boolean(rowItem && onMove);

  const [{ isOver, dropClassName }, drop] = useDrop({
    canDrop: (item: DragObjectWithType & { rowItem: TableAreaRowItem }) => {
      if (!rowItem) return false;

      const { rowItem: draggingRowItem } = item;

      if (!draggingRowItem) return false;

      return getIsDropAllowed({ draggingRowItem, targetRowItem: rowItem });
    },
    accept: DROP_TYPE,
    collect: (monitor) => {
      if (!rowItem) {
        return {};
      }

      const { rowItem: draggingRowItem } = monitor.getItem() || {};

      if (
        !draggingRowItem ||
        !getIsDropAllowed({
          draggingRowItem,
          targetRowItem: rowItem,
        })
      ) {
        return {};
      }

      if (rowItem.type === "tableArea" && draggingRowItem.type === "table") {
        return {
          isOver: monitor.isOver({ shallow: true }),
          dropClassName: " drop-over-entirely",
        };
      }

      // NOTE: テーブルを別エリア中のテーブルに差し込む場合は、常に上に差し込むこととする
      if (
        rowItem.type === "table" &&
        draggingRowItem.type === "table" &&
        rowItem.tableAreaId !== draggingRowItem.tableAreaId
      ) {
        return {
          isOver: monitor.isOver({ shallow: true }),
          dropClassName: " drop-over-upward",
        };
      }

      return {
        isOver: monitor.isOver({ shallow: true }),
        dropClassName:
          draggingRowItem.index < rowItem.index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: DragObjectWithType & { rowItem: TableAreaRowItem }) => {
      if (!rowItem || !onMove) return;

      onMove({ draggingRowItem: item.rowItem, targetRowItem: rowItem });
    },
  });

  const [, drag, preview] = useDrag({
    canDrag: () => isDragDropTarget,
    item: { type: DROP_TYPE, rowItem },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(dragRef));
  drop(preview(previewRef));

  return (
    <TableAreaSortableTableProvider value={{ dragRef }}>
      <tr
        ref={previewRef}
        className={`${className}${isDragDropTarget && isOver ? dropClassName : ""}`}
        style={style}
        {...props}
      />
    </TableAreaSortableTableProvider>
  );
});

const TableAreaSortableColumnContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const TableAreaSortableHandleContainer = styled.div`
  padding: 0 8px;
  cursor: move;
`;

export const TableAreaSortableColumn = memo<{ children: ReactNode; canDrag: boolean }>(
  ({ children, canDrag }) => {
    const { dragRef } = useTableAreaSortableTableContext();

    return (
      <TableAreaSortableColumnContainer>
        {canDrag && (
          <TableAreaSortableHandleContainer ref={dragRef}>
            <MenuOutlined />
          </TableAreaSortableHandleContainer>
        )}
        {children}
      </TableAreaSortableColumnContainer>
    );
  },
);

const components = {
  body: {
    row: (props: BodyRowProps) => <BodyRow {...props} />,
  },
};

type Props<RecordType extends object> = Omit<TableProps<RecordType>, "components" | "onRow"> & {
  onMove:
    | ((args: { draggingRowItem: TableAreaRowItem; targetRowItem: TableAreaRowItem }) => void)
    | null;
  tableAreaId?: string;
  loading?: boolean;
};

export const TableAreaSortableTable = <RecordType extends TableType | TableArea>({
  onMove,
  loading = false,
  tableAreaId,
  ...props
}: Props<RecordType>) => (
  <Table<RecordType>
    components={components}
    onRow={(record, index) => {
      const rowItem =
        record.__typename === "table"
          ? { type: "table", id: record.id, index, tableAreaId }
          : { type: "tableArea", id: record.id, index };

      return { onMove, rowItem } as any;
    }}
    loading={{
      spinning: loading,
      indicator: <Loading />,
      size: "large" as const,
    }}
    {...props}
  />
);
