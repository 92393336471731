import React, { memo } from "react";
import styled from "styled-components";
import { Progress, Typography } from "antd";

const Display = styled(Typography.Text)`
  font-size: 16px;
  font-weight: bold;
`;

type Props = {
  limit: number;
  consumption: number;
};

export const ConsumptionDetails = memo<Props>(({ limit, consumption }) => {
  const percent = Math.floor((consumption / limit) * 100);
  const isOneHundredPercent = percent === 100;

  return (
    <>
      <Display>
        今月の配信済みメッセージ: {consumption.toLocaleString()} / {limit.toLocaleString()} (残り
        {(limit - consumption).toLocaleString()})
      </Display>
      <Progress
        strokeWidth={12}
        percent={percent}
        status="active"
        strokeColor={isOneHundredPercent ? "#FF4D4F" : "#1890FF"}
      />
      <Typography.Text>
        メッセージ数を追加したい場合は、LINE
        公式アカウントのプランを変更、または追加メッセージ数の上限を変更してください (
        <Typography.Link
          href="https://www.linebiz.com/jp/manual/OfficialAccountManager/account-settings_plan/?list=7171"
          target="_blank"
        >
          LINE 公式アカウント - プランの変更方法
        </Typography.Link>
        ) <br />
        配信済みのメッセージ数について、通常は毎日午前中に更新されます。
      </Typography.Text>
    </>
  );
});
