import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddMessageDeliveryFormItem
    label="配信管理名"
    name="name"
    rules={[{ required: true, message: "配信管理名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddMessageDeliveryFormItem>
));
