import { useCallback, useMemo } from "react";

import { AnalyticsSettingInsertInput } from "types/graphql";

import {
  useAnalyticsSettingInsertSettingMutation,
  useAnalyticsSettingUpdateSettingMutation,
  useGetCorporationAnalyticsSettingQuery,
} from "./queries";

export type AnalyticsSetting = {
  displayTaxIncluded: boolean;
};

const defaultSettings: AnalyticsSetting = {
  displayTaxIncluded: false,
};

export const useAnalyticsSetting = ({ corporationId }: { corporationId: string | null }) => {
  const {
    data,
    loading: loadingSettings,
    refetch,
  } = useGetCorporationAnalyticsSettingQuery(
    corporationId
      ? {
          variables: {
            corporationId,
          },
        }
      : {
          skip: true,
        },
  );
  const [createAnalyticsSetting, { loading: creatingLoading }] =
    useAnalyticsSettingInsertSettingMutation();
  const [updateAnalyticsSettingMutation, { loading: updatingLoading }] =
    useAnalyticsSettingUpdateSettingMutation();

  const existingAnalyticsSettingId = data?.analyticsSetting?.[0]?.id;

  const analyticsSetting: AnalyticsSetting = useMemo(
    () => ({
      displayTaxIncluded:
        data?.analyticsSetting?.[0]?.displayTaxIncluded ?? defaultSettings.displayTaxIncluded,
    }),
    [data?.analyticsSetting],
  );

  const updateAnalyticsSetting = useCallback(
    async (newSettings: Omit<AnalyticsSettingInsertInput, "corporationId">) => {
      if (!corporationId) return false;

      try {
        if (existingAnalyticsSettingId) {
          await updateAnalyticsSettingMutation({
            variables: {
              id: existingAnalyticsSettingId,
              input: newSettings,
            },
          });
        } else {
          await createAnalyticsSetting({
            variables: {
              input: {
                ...newSettings,
                corporationId,
              },
            },
          });
        }
      } catch (err) {
        return false;
      }

      await refetch();

      return true;
    },
    [
      corporationId,
      refetch,
      existingAnalyticsSettingId,
      updateAnalyticsSettingMutation,
      createAnalyticsSetting,
    ],
  );

  return {
    analyticsSetting,
    updateAnalyticsSetting,
    loading: loadingSettings || creatingLoading || updatingLoading,
  };
};
