import React, { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Modal } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";

import { EditAccountingSlipImageForm } from "./EditAccountingSlipImageForm";
import {
  useEditAccountingSlipImageModalEditAccountingSlipImageMutation,
  useEditAccountingSlipImageModalGetAccountingSlipImageQuery,
} from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

type Props = {
  goBack: () => void;
  onUpdated: () => void;
};

export const EditAccountingSlipImageModal = memo<Props>(({ goBack, onUpdated }) => {
  const { accountingSlipImageId = "" } = useParams<{ accountingSlipImageId: string }>();

  const {
    data: getAccountingSlipImageData,
    loading: getDataLoading,
    error,
  } = useEditAccountingSlipImageModalGetAccountingSlipImageQuery({
    variables: { accountingSlipImageId },
  });
  const [updateAccountingSlipMutation, { loading: updateLoading }] =
    useEditAccountingSlipImageModalEditAccountingSlipImageMutation();

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const onSubmit = useCallback(
    async ({ imageUrl, description }: { imageUrl: string | null; description: string | null }) => {
      try {
        await updateAccountingSlipMutation({
          variables: {
            pk: { id: accountingSlipImageId },
            input: {
              imageUrl,
              description,
            },
          },
        });

        message.success("更新しました");
        onUpdated();
        goBack();
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [accountingSlipImageId, goBack, onUpdated, updateAccountingSlipMutation],
  );

  return (
    <Modal title="レシート画像編集" open width={900} onOk={goBack} onCancel={goBack} footer={null}>
      <StyledAlert
        message="営業中に伝票表示設定を変更した場合は、レジにて再度営業開始を行うか、アプリを立ち上げ直す必要があります"
        type="info"
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {getDataLoading || !getAccountingSlipImageData?.accountingSlipImage_by_pk ? (
        <Loading />
      ) : (
        <>
          <EditAccountingSlipImageForm
            accountingSlipImage={getAccountingSlipImageData.accountingSlipImage_by_pk}
            onSubmit={onSubmit}
            onClose={goBack}
            onFormValidationError={onFormValidationError}
            loading={updateLoading}
          />
        </>
      )}
    </Modal>
  );
});
