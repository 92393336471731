import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useShop } from "hooks/useShop";
import { EditClerkPriorityList } from "pages/EditClerkPriorities/EditClerkPriorityList";
import {
  useEditClerkPrioritiesGetShopClerksQuery,
  useEditClerkPrioritiesInsertShopClerksMutation,
} from "pages/EditClerkPriorities/queries";
import { ShopClerkInsertInput } from "types/graphql";

export const EditClerkPriorities = () => {
  const navigate = useNavigate();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getShopClerksData,
    loading: loadingShopClerks,
    refetch: refetchShopClerks,
    error,
  } = useEditClerkPrioritiesGetShopClerksQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shopClerks = getShopClerksData?.shop_by_pk?.shopClerks ?? [];

  const [insertShopClerksMutation, { loading: loadingInsertShopClerks }] =
    useEditClerkPrioritiesInsertShopClerksMutation();

  const onUpdate = useCallback(
    async (shopClerks: ShopClerkInsertInput[]) => {
      try {
        await insertShopClerksMutation({
          variables: { shopClerks },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      await refetchShopClerks();
    },
    [refetchShopClerks, insertShopClerksMutation],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="店員の表示順を編集"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "店員" }],
      }}
    >
      <PageHeader title="店員の表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditClerkPriorityList
          loading={loadingShopClerks || loadingInsertShopClerks}
          shopClerks={shopClerks}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
