import React, { memo } from "react";
import { Radio } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = { name: string | number } & Omit<FormItemProps, "children" | "name">;

export const TypeField = memo<Props>(({ name, ...props }) => (
  <AddMessageDeliveryFormItem
    name={[name, "type"]}
    rules={[{ required: true, message: "種別を選択してください" }]}
    noStyle
    {...props}
  >
    <Radio.Group
      options={[
        { label: "テキスト", value: "text" },
        { label: "画像", value: "image" },
        { label: "クーポン", value: "coupon" },
        { label: "アンケート", value: "questionnaire" },
      ]}
      optionType="button"
    />
  </AddMessageDeliveryFormItem>
));
