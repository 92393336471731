import React, { memo } from "react";
import styled from "styled-components";
import { Typography } from "antd";

type Props = {
  number: number;
};

const LottieContainer = styled.div`
  display: inline-block;
  justify-content: "center";
  width: 24px;
`;

const StyledText = styled(Typography.Text)`
  font-size: 12px;
`;

export const TargetCustomers = memo<Props>(({ number }) => (
  <>
    <Typography.Title level={5} style={{ margin: 0, display: "flex" }}>
      現時点の対象者数：{number.toLocaleString()}
    </Typography.Title>
  </>
));
