import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanFormItem label="プラン説明文 (日本語)" name="description" {...props}>
    <TextArea rows={2} placeholder="プランの概要をご記載ください" disabled={disabled} />
  </EditPlanFormItem>
));
