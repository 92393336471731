import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditChoiceWinboardMenuFormItem } from "../useEditChoiceWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const BumonCodeField = memo<Props>((props) => (
  <EditChoiceWinboardMenuFormItem label="部門コード" name="bumonCode" {...props}>
    <InputCode />
  </EditChoiceWinboardMenuFormItem>
));
