import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";
import dayjs from "dayjs";
import { range as lodashRange } from "lodash";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useShop } from "hooks/useShop";

import {
  useSalesBudgetGetDailySalesBudgetQuery,
  useSalesBudgetGetMonthlySalesBudgetQuery,
  useSalesBudgetGetSalesForBudgetQuery,
} from "./queries";
import {
  FilterConditions,
  SalesBudgetFilter,
  SerializedFilterConditions,
} from "./SalesBudgetFilter";
import { SalesBudgetTable } from "./SalesBudgetTable";

export const SalesBudget = () => {
  const { canAccess } = useCanAccess();

  const [shop] = useShop();
  const currentShopId = shop?.shopId;

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.subtract(6, "month").startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
      dimension: "month",
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const range = useMemo(
    () =>
      filterConditions.range?.[0] && filterConditions.range[1]
        ? {
            from: filterConditions.range[0].toISOString(),
            to: filterConditions.range[1].toISOString(),
          }
        : null,

    [filterConditions.range],
  );

  const {
    data: salesForBudget,
    loading: loadingSalesForBudget,
    error: getSalesForBudgetError,
  } = useSalesBudgetGetSalesForBudgetQuery(
    currentShopId
      ? {
          variables: {
            input: {
              shopId: currentShopId,
              averageAndMaxSalesByDayOfWeekInput: null,
              salesByDaysInput: filterConditions.dimension === "day" ? range : null,
              salesByMonthsInput: filterConditions.dimension === "month" ? range : null,
            },
          },
        }
      : { skip: true },
  );

  const businessDateToSalesAmountMap: Map<string, number> = useMemo(
    () =>
      filterConditions.dimension === "day"
        ? new Map(
            salesForBudget?.salesForBudget.salesByDays?.dailySalesAmount.map(
              ({ businessDate, taxExcludedSalesAmount }) => [businessDate, taxExcludedSalesAmount],
            ) ?? [],
          )
        : filterConditions.dimension === "month"
        ? new Map(
            salesForBudget?.salesForBudget.salesByMonths?.monthlySalesAmount.map(
              ({ businessDate, taxExcludedSalesAmount }) => [businessDate, taxExcludedSalesAmount],
            ) ?? [],
          )
        : new Map(),
    [salesForBudget, filterConditions.dimension],
  );

  const {
    data: dailySalesBudget,
    loading: loadingDailySalesBudget,
    error: getDailySalesBudgetError,
  } = useSalesBudgetGetDailySalesBudgetQuery(
    filterConditions.dimension === "day" && currentShopId && range
      ? { variables: { shopId: currentShopId, ...range } }
      : { skip: true },
  );

  const {
    data: monthlySalesBudget,
    loading: loadingMonthlySalesBudget,
    error: getMonthlySalesBudgetError,
  } = useSalesBudgetGetMonthlySalesBudgetQuery(
    filterConditions.dimension === "month" && currentShopId && range
      ? { variables: { shopId: currentShopId, ...range } }
      : { skip: true },
  );

  const businessDateToBudgetMap: Map<
    string,
    {
      id: string;
      budget: number;
    }
  > = useMemo(
    () =>
      filterConditions.dimension === "day"
        ? new Map(
            dailySalesBudget?.dailySalesBudget.map(({ id, businessDate, taxExcludedAmount }) => [
              businessDate,
              { id, budget: taxExcludedAmount },
            ]) ?? [],
          )
        : filterConditions.dimension === "month"
        ? new Map(
            monthlySalesBudget?.monthlySalesBudget.map(
              ({ id, businessDate, taxExcludedAmount }) => [
                businessDate,
                { id, budget: taxExcludedAmount },
              ],
            ) ?? [],
          )
        : new Map(),
    [dailySalesBudget, monthlySalesBudget, filterConditions.dimension],
  );

  const businessDates = useMemo(() => {
    if (!range || !filterConditions.dimension) return [];
    return lodashRange(dayjs(range.to).diff(range.from, filterConditions.dimension) + 1).map(
      (businessDate) =>
        dayjs(range.from)
          .add(businessDate, filterConditions.dimension)
          .startOf(filterConditions.dimension ?? "month")
          .format("YYYY-MM-DD"),
    );
  }, [range, filterConditions.dimension]);

  const salesBudgetData = useMemo(
    () =>
      businessDates.map((businessDate) => ({
        id: businessDateToBudgetMap.get(businessDate)?.id,
        businessDate,
        salesAmount: businessDateToSalesAmountMap.get(businessDate),
        budget: businessDateToBudgetMap.get(businessDate)?.budget,
      })),
    [businessDateToSalesAmountMap, businessDateToBudgetMap, businessDates],
  );

  const loading = loadingSalesForBudget || loadingDailySalesBudget || loadingMonthlySalesBudget;
  const error = getSalesForBudgetError || getDailySalesBudgetError || getMonthlySalesBudgetError;

  return (
    <DashboardLayout title="目標設定">
      <PageHeader
        title="目標設定"
        extra={[
          canAccess("addSalesBudget") && (
            <Link key="add" to="/salesBudget/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={
          <SalesBudgetFilter
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
          />
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <SalesBudgetTable
        salesBudgetData={salesBudgetData}
        isMonthlyFilter={filterConditions.dimension === "month"}
        canEdit={canAccess("editSalesBudget")}
        loading={loading}
      />
    </DashboardLayout>
  );
};
