import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCategoryGetCategory = gql`
    query EditCategoryGetCategory($categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    categoryId
    companyId
    imageUrl
    name
    shopSideBackgroundColor
    shopSideName
  }
}
    `;
export const EditCategoryUpdateCategory = gql`
    mutation EditCategoryUpdateCategory($categoryId: Int!, $category: category_set_input!) {
  update_category(_set: $category, where: {categoryId: {_eq: $categoryId}}) {
    affected_rows
  }
}
    `;
export type EditCategoryGetCategoryQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['Int'];
}>;


export type EditCategoryGetCategoryQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'companyId' | 'imageUrl' | 'name' | 'shopSideBackgroundColor' | 'shopSideName'>
  )> }
);

export type EditCategoryUpdateCategoryMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['Int'];
  category: Types.CategorySetInput;
}>;


export type EditCategoryUpdateCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { update_category?: Types.Maybe<(
    { __typename?: 'category_mutation_response' }
    & Pick<Types.CategoryMutationResponse, 'affected_rows'>
  )> }
);


export const EditCategoryGetCategoryDocument = gql`
    query EditCategoryGetCategory($categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    categoryId
    companyId
    imageUrl
    name
    shopSideBackgroundColor
    shopSideName
  }
}
    `;

/**
 * __useEditCategoryGetCategoryQuery__
 *
 * To run a query within a React component, call `useEditCategoryGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCategoryGetCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useEditCategoryGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<EditCategoryGetCategoryQuery, EditCategoryGetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCategoryGetCategoryQuery, EditCategoryGetCategoryQueryVariables>(EditCategoryGetCategoryDocument, options);
      }
export function useEditCategoryGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCategoryGetCategoryQuery, EditCategoryGetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCategoryGetCategoryQuery, EditCategoryGetCategoryQueryVariables>(EditCategoryGetCategoryDocument, options);
        }
export type EditCategoryGetCategoryQueryHookResult = ReturnType<typeof useEditCategoryGetCategoryQuery>;
export type EditCategoryGetCategoryLazyQueryHookResult = ReturnType<typeof useEditCategoryGetCategoryLazyQuery>;
export type EditCategoryGetCategoryQueryResult = Apollo.QueryResult<EditCategoryGetCategoryQuery, EditCategoryGetCategoryQueryVariables>;
export const EditCategoryUpdateCategoryDocument = gql`
    mutation EditCategoryUpdateCategory($categoryId: Int!, $category: category_set_input!) {
  update_category(_set: $category, where: {categoryId: {_eq: $categoryId}}) {
    affected_rows
  }
}
    `;
export type EditCategoryUpdateCategoryMutationFn = Apollo.MutationFunction<EditCategoryUpdateCategoryMutation, EditCategoryUpdateCategoryMutationVariables>;

/**
 * __useEditCategoryUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useEditCategoryUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryUpdateCategoryMutation, { data, loading, error }] = useEditCategoryUpdateCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useEditCategoryUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditCategoryUpdateCategoryMutation, EditCategoryUpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCategoryUpdateCategoryMutation, EditCategoryUpdateCategoryMutationVariables>(EditCategoryUpdateCategoryDocument, options);
      }
export type EditCategoryUpdateCategoryMutationHookResult = ReturnType<typeof useEditCategoryUpdateCategoryMutation>;
export type EditCategoryUpdateCategoryMutationResult = Apollo.MutationResult<EditCategoryUpdateCategoryMutation>;
export type EditCategoryUpdateCategoryMutationOptions = Apollo.BaseMutationOptions<EditCategoryUpdateCategoryMutation, EditCategoryUpdateCategoryMutationVariables>;