import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopPlanOptionRolesGetPlanOption = gql`
    query EditShopPlanOptionRolesGetPlanOption($planOptionId: Int!, $shopId: uuid!) {
  planOption(
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planOptionId
    name
    plan {
      id
      planId
      companyId
    }
    shopPlanOptionKitchenRoles(
      where: {shop: {archivedAt: {_is_null: true}}, _planOptionId: {_eq: $planOptionId}, shopId: {_eq: $shopId}}
    ) {
      role {
        id
        name
        roleId
      }
    }
  }
}
    `;
export const EditShopPlanOptionRolesGetRoles = gql`
    query EditShopPlanOptionRolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      id
      name
      roleId
    }
  }
}
    `;
export const EditShopPlanOptionRolesInsertPlanRolesRole = gql`
    mutation EditShopPlanOptionRolesInsertPlanRolesRole($shopPlanOptionKitchenRoles: [shopPlanOptionKitchenRole_insert_input!]!) {
  insert_shopPlanOptionKitchenRole(
    objects: $shopPlanOptionKitchenRoles
    on_conflict: {constraint: shopPlanOptionKitchenRole_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export const EditShopPlanOptionRolesDeletePlanRolesRole = gql`
    mutation EditShopPlanOptionRolesDeletePlanRolesRole($planOptionId: Int!, $roleId: Int!) {
  delete_shopPlanOptionKitchenRole(
    where: {_and: [{_planOptionId: {_eq: $planOptionId}}, {_roleId: {_eq: $roleId}}]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanOptionRolesGetPlanOptionQueryVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopPlanOptionRolesGetPlanOptionQuery = (
  { __typename?: 'query_root' }
  & { planOption: Array<(
    { __typename?: 'planOption' }
    & Pick<Types.PlanOption, 'id' | 'planOptionId' | 'name'>
    & { plan: (
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'id' | 'planId' | 'companyId'>
    ), shopPlanOptionKitchenRoles: Array<(
      { __typename?: 'shopPlanOptionKitchenRole' }
      & { role: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'name' | 'roleId'>
      ) }
    )> }
  )> }
);

export type EditShopPlanOptionRolesGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopPlanOptionRolesGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )> }
  )> }
);

export type EditShopPlanOptionRolesInsertPlanRolesRoleMutationVariables = Types.Exact<{
  shopPlanOptionKitchenRoles: Array<Types.ShopPlanOptionKitchenRoleInsertInput> | Types.ShopPlanOptionKitchenRoleInsertInput;
}>;


export type EditShopPlanOptionRolesInsertPlanRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopPlanOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopPlanOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopPlanOptionKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopPlanOptionRolesDeletePlanRolesRoleMutationVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
  roleId: Types.Scalars['Int'];
}>;


export type EditShopPlanOptionRolesDeletePlanRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopPlanOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopPlanOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopPlanOptionKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);


export const EditShopPlanOptionRolesGetPlanOptionDocument = gql`
    query EditShopPlanOptionRolesGetPlanOption($planOptionId: Int!, $shopId: uuid!) {
  planOption(
    where: {planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    planOptionId
    name
    plan {
      id
      planId
      companyId
    }
    shopPlanOptionKitchenRoles(
      where: {shop: {archivedAt: {_is_null: true}}, _planOptionId: {_eq: $planOptionId}, shopId: {_eq: $shopId}}
    ) {
      role {
        id
        name
        roleId
      }
    }
  }
}
    `;

/**
 * __useEditShopPlanOptionRolesGetPlanOptionQuery__
 *
 * To run a query within a React component, call `useEditShopPlanOptionRolesGetPlanOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanOptionRolesGetPlanOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlanOptionRolesGetPlanOptionQuery({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopPlanOptionRolesGetPlanOptionQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlanOptionRolesGetPlanOptionQuery, EditShopPlanOptionRolesGetPlanOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlanOptionRolesGetPlanOptionQuery, EditShopPlanOptionRolesGetPlanOptionQueryVariables>(EditShopPlanOptionRolesGetPlanOptionDocument, options);
      }
export function useEditShopPlanOptionRolesGetPlanOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlanOptionRolesGetPlanOptionQuery, EditShopPlanOptionRolesGetPlanOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlanOptionRolesGetPlanOptionQuery, EditShopPlanOptionRolesGetPlanOptionQueryVariables>(EditShopPlanOptionRolesGetPlanOptionDocument, options);
        }
export type EditShopPlanOptionRolesGetPlanOptionQueryHookResult = ReturnType<typeof useEditShopPlanOptionRolesGetPlanOptionQuery>;
export type EditShopPlanOptionRolesGetPlanOptionLazyQueryHookResult = ReturnType<typeof useEditShopPlanOptionRolesGetPlanOptionLazyQuery>;
export type EditShopPlanOptionRolesGetPlanOptionQueryResult = Apollo.QueryResult<EditShopPlanOptionRolesGetPlanOptionQuery, EditShopPlanOptionRolesGetPlanOptionQueryVariables>;
export const EditShopPlanOptionRolesGetRolesDocument = gql`
    query EditShopPlanOptionRolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      id
      name
      roleId
    }
  }
}
    `;

/**
 * __useEditShopPlanOptionRolesGetRolesQuery__
 *
 * To run a query within a React component, call `useEditShopPlanOptionRolesGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanOptionRolesGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlanOptionRolesGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopPlanOptionRolesGetRolesQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlanOptionRolesGetRolesQuery, EditShopPlanOptionRolesGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlanOptionRolesGetRolesQuery, EditShopPlanOptionRolesGetRolesQueryVariables>(EditShopPlanOptionRolesGetRolesDocument, options);
      }
export function useEditShopPlanOptionRolesGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlanOptionRolesGetRolesQuery, EditShopPlanOptionRolesGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlanOptionRolesGetRolesQuery, EditShopPlanOptionRolesGetRolesQueryVariables>(EditShopPlanOptionRolesGetRolesDocument, options);
        }
export type EditShopPlanOptionRolesGetRolesQueryHookResult = ReturnType<typeof useEditShopPlanOptionRolesGetRolesQuery>;
export type EditShopPlanOptionRolesGetRolesLazyQueryHookResult = ReturnType<typeof useEditShopPlanOptionRolesGetRolesLazyQuery>;
export type EditShopPlanOptionRolesGetRolesQueryResult = Apollo.QueryResult<EditShopPlanOptionRolesGetRolesQuery, EditShopPlanOptionRolesGetRolesQueryVariables>;
export const EditShopPlanOptionRolesInsertPlanRolesRoleDocument = gql`
    mutation EditShopPlanOptionRolesInsertPlanRolesRole($shopPlanOptionKitchenRoles: [shopPlanOptionKitchenRole_insert_input!]!) {
  insert_shopPlanOptionKitchenRole(
    objects: $shopPlanOptionKitchenRoles
    on_conflict: {constraint: shopPlanOptionKitchenRole_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanOptionRolesInsertPlanRolesRoleMutationFn = Apollo.MutationFunction<EditShopPlanOptionRolesInsertPlanRolesRoleMutation, EditShopPlanOptionRolesInsertPlanRolesRoleMutationVariables>;

/**
 * __useEditShopPlanOptionRolesInsertPlanRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopPlanOptionRolesInsertPlanRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanOptionRolesInsertPlanRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopPlanOptionRolesInsertPlanRolesRoleMutation, { data, loading, error }] = useEditShopPlanOptionRolesInsertPlanRolesRoleMutation({
 *   variables: {
 *      shopPlanOptionKitchenRoles: // value for 'shopPlanOptionKitchenRoles'
 *   },
 * });
 */
export function useEditShopPlanOptionRolesInsertPlanRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopPlanOptionRolesInsertPlanRolesRoleMutation, EditShopPlanOptionRolesInsertPlanRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopPlanOptionRolesInsertPlanRolesRoleMutation, EditShopPlanOptionRolesInsertPlanRolesRoleMutationVariables>(EditShopPlanOptionRolesInsertPlanRolesRoleDocument, options);
      }
export type EditShopPlanOptionRolesInsertPlanRolesRoleMutationHookResult = ReturnType<typeof useEditShopPlanOptionRolesInsertPlanRolesRoleMutation>;
export type EditShopPlanOptionRolesInsertPlanRolesRoleMutationResult = Apollo.MutationResult<EditShopPlanOptionRolesInsertPlanRolesRoleMutation>;
export type EditShopPlanOptionRolesInsertPlanRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopPlanOptionRolesInsertPlanRolesRoleMutation, EditShopPlanOptionRolesInsertPlanRolesRoleMutationVariables>;
export const EditShopPlanOptionRolesDeletePlanRolesRoleDocument = gql`
    mutation EditShopPlanOptionRolesDeletePlanRolesRole($planOptionId: Int!, $roleId: Int!) {
  delete_shopPlanOptionKitchenRole(
    where: {_and: [{_planOptionId: {_eq: $planOptionId}}, {_roleId: {_eq: $roleId}}]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanOptionRolesDeletePlanRolesRoleMutationFn = Apollo.MutationFunction<EditShopPlanOptionRolesDeletePlanRolesRoleMutation, EditShopPlanOptionRolesDeletePlanRolesRoleMutationVariables>;

/**
 * __useEditShopPlanOptionRolesDeletePlanRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopPlanOptionRolesDeletePlanRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanOptionRolesDeletePlanRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopPlanOptionRolesDeletePlanRolesRoleMutation, { data, loading, error }] = useEditShopPlanOptionRolesDeletePlanRolesRoleMutation({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEditShopPlanOptionRolesDeletePlanRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopPlanOptionRolesDeletePlanRolesRoleMutation, EditShopPlanOptionRolesDeletePlanRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopPlanOptionRolesDeletePlanRolesRoleMutation, EditShopPlanOptionRolesDeletePlanRolesRoleMutationVariables>(EditShopPlanOptionRolesDeletePlanRolesRoleDocument, options);
      }
export type EditShopPlanOptionRolesDeletePlanRolesRoleMutationHookResult = ReturnType<typeof useEditShopPlanOptionRolesDeletePlanRolesRoleMutation>;
export type EditShopPlanOptionRolesDeletePlanRolesRoleMutationResult = Apollo.MutationResult<EditShopPlanOptionRolesDeletePlanRolesRoleMutation>;
export type EditShopPlanOptionRolesDeletePlanRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopPlanOptionRolesDeletePlanRolesRoleMutation, EditShopPlanOptionRolesDeletePlanRolesRoleMutationVariables>;