import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { logger } from "libs/logger";
import { EditGiftChoiceForm } from "pages/EditGiftChoice/EditGiftChoiceForm";
import {
  useEditGiftChoiceGetGiftChoiceQuery,
  useEditGiftChoiceUpdateGiftChoiceMutation,
} from "pages/EditGiftChoice/queries";
import { ChoiceSetInput } from "types/graphql";

export const EditGiftChoice = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const choiceId = Number(id);

  const {
    data: giftChoiceData,
    loading: loadingGiftChoice,
    error: giftChoiceError,
  } = useEditGiftChoiceGetGiftChoiceQuery(choiceId ? { variables: { choiceId } } : { skip: true });
  const choice = giftChoiceData?.choice?.[0];

  const [editGiftChoiceMutation, { loading: loadingUpdateGiftChoice }] =
    useEditGiftChoiceUpdateGiftChoiceMutation();

  const onSubmit = useCallback(
    async (choice: ChoiceSetInput) => {
      try {
        const { data: editGiftChoiceData, errors } = await editGiftChoiceMutation({
          variables: { choiceId, choice },
        });
        const affected_rows = editGiftChoiceData?.update_choice?.affected_rows;

        if (affected_rows !== 1) {
          logger.error("Failed to update gift choice", { affected_rows, errors });
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/giftChoice", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [choiceId, editGiftChoiceMutation, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const shouldShowAlert = giftChoiceError;

  return (
    <DashboardLayout
      title={choice?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "推しエール金額" }],
      }}
    >
      <PageHeader title={choice?.name} onBack={goBack} />
      {loadingGiftChoice && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {choice && (
        <EditGiftChoiceForm
          choice={choice}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateGiftChoice}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
