import React, { Fragment, memo } from "react";
import { Card } from "antd";

import { FormList } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";

export const ChoiceTranslationFields = memo(() => (
  <FormList name="choices">
    {(fields) =>
      fields.map((field) => (
        <Fragment key={field.key}>
          <Card>
            <TranslationNameFields
              originalFieldName={[field.name, "name"]}
              name={[field.name, "nameSources"]}
              label="選択肢"
            />
          </Card>

          <Spacer size={20} />
        </Fragment>
      ))
    }
  </FormList>
));
