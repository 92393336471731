import React, { memo } from "react";
import { Col, Row } from "antd";

import { FormHelp } from "components/Form/FormHelp";
import { Select } from "components/Input/Select";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = {
  taxOffices: {
    id: string;
    name: string;
  }[];
};

export const SelectTaxOfficeField = memo<Props>(({ taxOffices }) => (
  <Row>
    <Col span={10}>
      <EditCashRegisterConfigFormItem
        name="taxOfficeId"
        label={
          <FormHelp
            label="収入印紙の貼付省略による税務署名"
            help="印紙税納付のための収入印紙貼り付けを省略するには所定の手続きが必要です。手続きは課税文書を作成しようとする場所の所在地を所轄する税務署にお問い合わせください。"
          />
        }
      >
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          dropdownMatchSelectWidth={false}
          options={taxOffices.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </EditCashRegisterConfigFormItem>
    </Col>
  </Row>
));
