import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopMenusBulkGetMenus = gql`
    query EditShopMenusBulkGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}, serviceChargeMeta: {}, specificTimeSurchargeMeta: {}}}
    order_by: {menuId: asc}
  ) {
    id
    menuId
    companyId
    imageUrl
    name
    categoryMenus(
      order_by: [{priority: asc}, {_categoryId: asc}]
      where: {category: {archivedAt: {_is_null: true}}, _not: {category: {menuRecommendationMeta: {}}}}
    ) {
      category {
        id
        name
      }
    }
    shopMenus(order_by: {shopId: asc}) {
      shopId
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        id
        optionId
        name
        choices {
          id
          choiceId
          name
          shopChoices(order_by: {shopId: asc}) {
            shopId
          }
        }
      }
    }
  }
}
    `;
export const EditShopMenusBulkGetCategories = gql`
    query EditShopMenusBulkGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    id
    name
  }
}
    `;
export const EditShopMenusBulkGetShops = gql`
    query EditShopMenusBulkGetShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const UpdateShopMenusBulk = gql`
    mutation UpdateShopMenusBulk($input: UpdateShopMenusBulkInput!) {
  updateShopMenusBulk(input: $input) {
    result
  }
}
    `;
export type EditShopMenusBulkGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditShopMenusBulkGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'imageUrl' | 'name'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'id' | 'name'>
      ) }
    )>, shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & Pick<Types.ShopMenu, 'shopId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum'>
      )> }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'id' | 'optionId' | 'name'>
        & { choices: Array<(
          { __typename?: 'choice' }
          & Pick<Types.Choice, 'id' | 'choiceId' | 'name'>
          & { shopChoices: Array<(
            { __typename?: 'shopChoice' }
            & Pick<Types.ShopChoice, 'shopId'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type EditShopMenusBulkGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditShopMenusBulkGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'name'>
  )> }
);

export type EditShopMenusBulkGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditShopMenusBulkGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type UpdateShopMenusBulkMutationVariables = Types.Exact<{
  input: Types.UpdateShopMenusBulkInput;
}>;


export type UpdateShopMenusBulkMutation = (
  { __typename?: 'mutation_root' }
  & { updateShopMenusBulk: (
    { __typename?: 'UpdateShopMenusBulkResult' }
    & Pick<Types.UpdateShopMenusBulkResult, 'result'>
  ) }
);


export const EditShopMenusBulkGetMenusDocument = gql`
    query EditShopMenusBulkGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}, serviceChargeMeta: {}, specificTimeSurchargeMeta: {}}}
    order_by: {menuId: asc}
  ) {
    id
    menuId
    companyId
    imageUrl
    name
    categoryMenus(
      order_by: [{priority: asc}, {_categoryId: asc}]
      where: {category: {archivedAt: {_is_null: true}}, _not: {category: {menuRecommendationMeta: {}}}}
    ) {
      category {
        id
        name
      }
    }
    shopMenus(order_by: {shopId: asc}) {
      shopId
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        id
        optionId
        name
        choices {
          id
          choiceId
          name
          shopChoices(order_by: {shopId: asc}) {
            shopId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEditShopMenusBulkGetMenusQuery__
 *
 * To run a query within a React component, call `useEditShopMenusBulkGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenusBulkGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenusBulkGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditShopMenusBulkGetMenusQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenusBulkGetMenusQuery, EditShopMenusBulkGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenusBulkGetMenusQuery, EditShopMenusBulkGetMenusQueryVariables>(EditShopMenusBulkGetMenusDocument, options);
      }
export function useEditShopMenusBulkGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenusBulkGetMenusQuery, EditShopMenusBulkGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenusBulkGetMenusQuery, EditShopMenusBulkGetMenusQueryVariables>(EditShopMenusBulkGetMenusDocument, options);
        }
export type EditShopMenusBulkGetMenusQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetMenusQuery>;
export type EditShopMenusBulkGetMenusLazyQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetMenusLazyQuery>;
export type EditShopMenusBulkGetMenusQueryResult = Apollo.QueryResult<EditShopMenusBulkGetMenusQuery, EditShopMenusBulkGetMenusQueryVariables>;
export const EditShopMenusBulkGetCategoriesDocument = gql`
    query EditShopMenusBulkGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    id
    name
  }
}
    `;

/**
 * __useEditShopMenusBulkGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditShopMenusBulkGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenusBulkGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenusBulkGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditShopMenusBulkGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenusBulkGetCategoriesQuery, EditShopMenusBulkGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenusBulkGetCategoriesQuery, EditShopMenusBulkGetCategoriesQueryVariables>(EditShopMenusBulkGetCategoriesDocument, options);
      }
export function useEditShopMenusBulkGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenusBulkGetCategoriesQuery, EditShopMenusBulkGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenusBulkGetCategoriesQuery, EditShopMenusBulkGetCategoriesQueryVariables>(EditShopMenusBulkGetCategoriesDocument, options);
        }
export type EditShopMenusBulkGetCategoriesQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetCategoriesQuery>;
export type EditShopMenusBulkGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetCategoriesLazyQuery>;
export type EditShopMenusBulkGetCategoriesQueryResult = Apollo.QueryResult<EditShopMenusBulkGetCategoriesQuery, EditShopMenusBulkGetCategoriesQueryVariables>;
export const EditShopMenusBulkGetShopsDocument = gql`
    query EditShopMenusBulkGetShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditShopMenusBulkGetShopsQuery__
 *
 * To run a query within a React component, call `useEditShopMenusBulkGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopMenusBulkGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopMenusBulkGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditShopMenusBulkGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditShopMenusBulkGetShopsQuery, EditShopMenusBulkGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopMenusBulkGetShopsQuery, EditShopMenusBulkGetShopsQueryVariables>(EditShopMenusBulkGetShopsDocument, options);
      }
export function useEditShopMenusBulkGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopMenusBulkGetShopsQuery, EditShopMenusBulkGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopMenusBulkGetShopsQuery, EditShopMenusBulkGetShopsQueryVariables>(EditShopMenusBulkGetShopsDocument, options);
        }
export type EditShopMenusBulkGetShopsQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetShopsQuery>;
export type EditShopMenusBulkGetShopsLazyQueryHookResult = ReturnType<typeof useEditShopMenusBulkGetShopsLazyQuery>;
export type EditShopMenusBulkGetShopsQueryResult = Apollo.QueryResult<EditShopMenusBulkGetShopsQuery, EditShopMenusBulkGetShopsQueryVariables>;
export const UpdateShopMenusBulkDocument = gql`
    mutation UpdateShopMenusBulk($input: UpdateShopMenusBulkInput!) {
  updateShopMenusBulk(input: $input) {
    result
  }
}
    `;
export type UpdateShopMenusBulkMutationFn = Apollo.MutationFunction<UpdateShopMenusBulkMutation, UpdateShopMenusBulkMutationVariables>;

/**
 * __useUpdateShopMenusBulkMutation__
 *
 * To run a mutation, you first call `useUpdateShopMenusBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopMenusBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopMenusBulkMutation, { data, loading, error }] = useUpdateShopMenusBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopMenusBulkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopMenusBulkMutation, UpdateShopMenusBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopMenusBulkMutation, UpdateShopMenusBulkMutationVariables>(UpdateShopMenusBulkDocument, options);
      }
export type UpdateShopMenusBulkMutationHookResult = ReturnType<typeof useUpdateShopMenusBulkMutation>;
export type UpdateShopMenusBulkMutationResult = Apollo.MutationResult<UpdateShopMenusBulkMutation>;
export type UpdateShopMenusBulkMutationOptions = Apollo.BaseMutationOptions<UpdateShopMenusBulkMutation, UpdateShopMenusBulkMutationVariables>;