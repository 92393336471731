import React, { memo } from "react";

import { AsyncSwitch } from "components/AsyncSwitch";
import { Table } from "components/Table";
import { FeatureFlagEnum } from "types/graphql";

import { isFeatureFlagEnum } from "../isFeatureFlagEnum";
import type { FeatureFlag, FeatureFlagShop } from "../types";

const featureFlagNameToDisplayName: Record<
  FeatureFlagEnum,
  { label: string; useInvertedValue?: boolean }
> = {
  [FeatureFlagEnum.Yell]: { label: "推しエール" },
  [FeatureFlagEnum.Connect]: { label: "connect" },
  [FeatureFlagEnum.ExternalOnlineMenu]: { label: "外部オンラインメニュー" },
  [FeatureFlagEnum.DisableCustomerAttribute]: {
    label: "顧客の属性情報入力画面",
    useInvertedValue: true,
  },
  [FeatureFlagEnum.EnableOnlinePayment]: {
    label: "オンライン決済(Stripe)",
  },
  [FeatureFlagEnum.EnableAdyenOnlinePayment]: {
    label: "オンライン決済(Adyen)",
  },
  [FeatureFlagEnum.EnableGmoOnlinePayment]: {
    label: "オンライン決済(GMO)",
  },
  [FeatureFlagEnum.Ebica]: {
    label: "ebica",
  },
};

type Props = {
  featureFlagShops: FeatureFlagShop[];
  featureFlags: FeatureFlag[];
  onSwitchChange: (args: { name: FeatureFlag["name"]; checked: boolean }) => Promise<void>;
  loading: boolean;
};

export const FeatureFlagTable = memo<Props>(
  ({ featureFlagShops, featureFlags, onSwitchChange, loading }) => {
    const columns = [
      {
        title: "機能種別",
        render(_: string, { name }: FeatureFlag) {
          return isFeatureFlagEnum(name) ? featureFlagNameToDisplayName[name].label : name;
        },
      },
      {
        title: "状態",
        render(_: string, { name }: FeatureFlag) {
          const isEnabled = featureFlagShops.some(
            (featureFlagShop) => featureFlagShop.name === name,
          );

          const onChange = async (checked: boolean) => {
            await onSwitchChange({ name, checked });
          };

          if (!isFeatureFlagEnum(name)) return null;

          return (
            <AsyncSwitch
              checked={featureFlagNameToDisplayName[name].useInvertedValue ? !isEnabled : isEnabled}
              onChange={async (checked) => {
                await onChange(
                  featureFlagNameToDisplayName[name].useInvertedValue ? !checked : checked,
                );
              }}
            />
          );
        },
      },
    ];

    return (
      <Table
        rowKey="name"
        columns={columns}
        dataSource={featureFlags}
        loading={loading}
        pagination={false}
      />
    );
  },
);
