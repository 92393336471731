import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditOptionTranslationsFormItem } from "./useEditOptionTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled: boolean };

export const OptionNameCnField = memo<Props>(({ ...props }) => (
  <EditOptionTranslationsFormItem
    label="オプション名：中国語 (簡体字)"
    name="optionNameCn"
    {...props}
  >
    <Input disabled={props.disabled} />
  </EditOptionTranslationsFormItem>
));
