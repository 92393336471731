import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OnSitePaymentDetailTypesGetDetailTypes = gql`
    query OnSitePaymentDetailTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    type
    label
    allowChange
    onSitePaymentDetailTypeCategory
    isEditable
  }
}
    `;
export const OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes = gql`
    query OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes($companyId: uuid!) {
  steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, onSitePaymentDetailType: {isArchived: {_eq: false}}}
  ) {
    id
    onSitePaymentDetailTypeEntityType
  }
}
    `;
export const OnSitePaymentDetailTypesArchive = gql`
    mutation OnSitePaymentDetailTypesArchive($id: uuid!) {
  update_onSitePaymentDetailType(
    where: {id: {_eq: $id}}
    _set: {isArchived: true}
  ) {
    returning {
      id
    }
  }
  delete_pikaichiOnSitePaymentDetailType(
    where: {onSitePaymentDetailType: {id: {_eq: $id}}}
  ) {
    affected_rows
  }
  delete_tecAggregationOnSitePaymentDetailType(
    where: {onSitePaymentDetailType: {id: {_eq: $id}}}
  ) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesGetDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDetailTypesGetDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label' | 'allowChange' | 'onSitePaymentDetailTypeCategory' | 'isEditable'>
  )> }
);

export type OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { steraOnSitePaymentDetailType: Array<(
    { __typename?: 'steraOnSitePaymentDetailType' }
    & Pick<Types.SteraOnSitePaymentDetailType, 'id' | 'onSitePaymentDetailTypeEntityType'>
  )> }
);

export type OnSitePaymentDetailTypesArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDetailTypesArchiveMutation = (
  { __typename?: 'mutation_root' }
  & { update_onSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDetailType_mutation_response' }
    & { returning: Array<(
      { __typename?: 'onSitePaymentDetailType' }
      & Pick<Types.OnSitePaymentDetailType, 'id'>
    )> }
  )>, delete_pikaichiOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'pikaichiOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.PikaichiOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'tecAggregationOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.TecAggregationOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);


export const OnSitePaymentDetailTypesGetDetailTypesDocument = gql`
    query OnSitePaymentDetailTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    type
    label
    allowChange
    onSitePaymentDetailTypeCategory
    isEditable
  }
}
    `;

/**
 * __useOnSitePaymentDetailTypesGetDetailTypesQuery__
 *
 * To run a query within a React component, call `useOnSitePaymentDetailTypesGetDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesGetDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSitePaymentDetailTypesGetDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesGetDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<OnSitePaymentDetailTypesGetDetailTypesQuery, OnSitePaymentDetailTypesGetDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnSitePaymentDetailTypesGetDetailTypesQuery, OnSitePaymentDetailTypesGetDetailTypesQueryVariables>(OnSitePaymentDetailTypesGetDetailTypesDocument, options);
      }
export function useOnSitePaymentDetailTypesGetDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnSitePaymentDetailTypesGetDetailTypesQuery, OnSitePaymentDetailTypesGetDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnSitePaymentDetailTypesGetDetailTypesQuery, OnSitePaymentDetailTypesGetDetailTypesQueryVariables>(OnSitePaymentDetailTypesGetDetailTypesDocument, options);
        }
export type OnSitePaymentDetailTypesGetDetailTypesQueryHookResult = ReturnType<typeof useOnSitePaymentDetailTypesGetDetailTypesQuery>;
export type OnSitePaymentDetailTypesGetDetailTypesLazyQueryHookResult = ReturnType<typeof useOnSitePaymentDetailTypesGetDetailTypesLazyQuery>;
export type OnSitePaymentDetailTypesGetDetailTypesQueryResult = Apollo.QueryResult<OnSitePaymentDetailTypesGetDetailTypesQuery, OnSitePaymentDetailTypesGetDetailTypesQueryVariables>;
export const OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument = gql`
    query OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes($companyId: uuid!) {
  steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, onSitePaymentDetailType: {isArchived: {_eq: false}}}
  ) {
    id
    onSitePaymentDetailTypeEntityType
  }
}
    `;

/**
 * __useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>(OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument, options);
      }
export function useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>(OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument, options);
        }
export type OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery>;
export type OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQuery>;
export type OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, OnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>;
export const OnSitePaymentDetailTypesArchiveDocument = gql`
    mutation OnSitePaymentDetailTypesArchive($id: uuid!) {
  update_onSitePaymentDetailType(
    where: {id: {_eq: $id}}
    _set: {isArchived: true}
  ) {
    returning {
      id
    }
  }
  delete_pikaichiOnSitePaymentDetailType(
    where: {onSitePaymentDetailType: {id: {_eq: $id}}}
  ) {
    affected_rows
  }
  delete_tecAggregationOnSitePaymentDetailType(
    where: {onSitePaymentDetailType: {id: {_eq: $id}}}
  ) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesArchiveMutationFn = Apollo.MutationFunction<OnSitePaymentDetailTypesArchiveMutation, OnSitePaymentDetailTypesArchiveMutationVariables>;

/**
 * __useOnSitePaymentDetailTypesArchiveMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDetailTypesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDetailTypesArchiveMutation, { data, loading, error }] = useOnSitePaymentDetailTypesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesArchiveMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDetailTypesArchiveMutation, OnSitePaymentDetailTypesArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDetailTypesArchiveMutation, OnSitePaymentDetailTypesArchiveMutationVariables>(OnSitePaymentDetailTypesArchiveDocument, options);
      }
export type OnSitePaymentDetailTypesArchiveMutationHookResult = ReturnType<typeof useOnSitePaymentDetailTypesArchiveMutation>;
export type OnSitePaymentDetailTypesArchiveMutationResult = Apollo.MutationResult<OnSitePaymentDetailTypesArchiveMutation>;
export type OnSitePaymentDetailTypesArchiveMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDetailTypesArchiveMutation, OnSitePaymentDetailTypesArchiveMutationVariables>;