import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditMenuWinboardMenuFormItem } from "../useEditMenuWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const WinboardCategoryCodeField = memo<Props>((props) => (
  <EditMenuWinboardMenuFormItem label="分類コード" name="categoryCode" {...props}>
    <InputCode />
  </EditMenuWinboardMenuFormItem>
));
