import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { extractError } from "util/remoteSchema";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MessageContent } from "components/MessageContent";
import { useCorporation } from "hooks/useCorporation";
import { EditDashboardAccountForm } from "pages/EditDashboardAccount/EditDashboardAccountForm";
import { EditDashboardAccountFormValues } from "pages/EditDashboardAccount/EditDashboardAccountForm/useEditDashboardAccountForm";

import {
  useEditDashboardAccountGetCompaniesQuery,
  useEditDashboardAccountGetDashboardAccountQuery,
  useEditDashboardAccountGetDashboardAccountRolesQuery,
  useEditDashboardAccountGetShopsQuery,
  useEditDashboardAccountUpdateAccountMutation,
} from "./queries";

export const EditDashboardAccount = () => {
  const [corporation] = useCorporation();
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const {
    data: companiesData,
    error: getCompaniesError,
    loading: loadingCompaniesData,
  } = useEditDashboardAccountGetCompaniesQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const companies = useMemo(() => companiesData?.company ?? [], [companiesData?.company]);

  const {
    data: shopsData,
    error: getShopsError,
    loading: loadingShopsData,
  } = useEditDashboardAccountGetShopsQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const shops = useMemo(() => shopsData?.shop ?? [], [shopsData?.shop]);

  const {
    data: dashboardAccountRolesData,
    error: getDashboardAccountRolesError,
    refetch: refetchDashboardAccountRoles,
    loading: loadingDashboardAccountRolesData,
  } = useEditDashboardAccountGetDashboardAccountRolesQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const dashboardAccountRoles = useMemo(
    () => dashboardAccountRolesData?.dashboardAccountRole ?? [],
    [dashboardAccountRolesData?.dashboardAccountRole],
  );

  const {
    data: dashboardAccountData,
    error: getDashboardAccountError,
    loading: loadingDashboardAccountData,
  } = useEditDashboardAccountGetDashboardAccountQuery(
    id ? { variables: { dashboardAccountId: id } } : { skip: true },
  );
  const dashboardAccount = dashboardAccountData?.dashboardAccount[0];

  const [updateAccountMutation, { loading: loadingUpdateAccount }] =
    useEditDashboardAccountUpdateAccountMutation();
  const onSubmit = useCallback(
    async (formValues: EditDashboardAccountFormValues) => {
      if (!corporation) return;
      if (!id) return;

      try {
        const companyIds =
          formValues.scope === "corporation"
            ? companies.map(({ id }) => id)
            : formValues.scope === "company"
            ? formValues.companies
            : [];
        const shopIds =
          formValues.scope === "corporation"
            ? shops.map(({ shopId }) => shopId)
            : formValues.scope === "shop"
            ? formValues.shops
            : [];

        const role =
          formValues.scope === "corporation"
            ? "corporationManager"
            : formValues.scope === "company"
            ? "companyManager"
            : formValues.scope === "shop"
            ? "shopMember"
            : "";

        const { errors } = await updateAccountMutation({
          variables: {
            input: {
              dashboardAccountId: id,
              email: formValues.email,
              name: formValues.name,
              role,
              companyIds,
              shopIds,
              dashboardAccountRoleId: formValues.dashboardAccountRoleId,
            },
          },
        });

        await refetchDashboardAccountRoles();

        if (errors) {
          return message.error("アカウントの更新に失敗しました");
        }

        message.success("アカウントを更新しました");

        navigate("/setting/account", { replace: true });
      } catch (e) {
        const remoteError = e instanceof ApolloError ? extractError(e) : null;
        if (remoteError) return message.error(<MessageContent {...remoteError} />);

        message.error("アカウントの更新に失敗しました");
      }
    },
    [
      corporation,
      id,
      companies,
      shops,
      updateAccountMutation,
      navigate,
      refetchDashboardAccountRoles,
    ],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingCompaniesData ||
    loadingShopsData ||
    loadingUpdateAccount ||
    loadingDashboardAccountRolesData ||
    loadingDashboardAccountData;
  const shouldShowAlert =
    getCompaniesError ?? getShopsError ?? getDashboardAccountRolesError ?? getDashboardAccountError;

  return (
    <DashboardLayout
      title={dashboardAccount?.userName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "アカウント" }],
      }}
    >
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {dashboardAccount && (
        <EditDashboardAccountForm
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={loading}
          dashboardAccount={dashboardAccount}
          companies={companies}
          shops={shops}
          dashboardAccountRoles={dashboardAccountRoles}
        />
      )}
    </DashboardLayout>
  );
};
