import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableAutoPrintReceiptField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="レシート自動印刷設定"
        help="この設定を無効にすると、会計時にレシートが自動で印刷されなくなります。レシートを印刷したい場合は、会計後のお釣り画面で、「レシート再印刷」ボタンを押してください。"
      />
    }
    name="enableAutoPrintReceipt"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
