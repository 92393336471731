import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { isNotNull } from "util/type/primitive";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { Coupon } from "../types";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";

type Props = {
  loading?: boolean;
  coupons: Coupon[];
};

export const CouponsTable = memo(({ loading, coupons }: Props) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<Coupon> = [
    {
      title: "画像",
      dataIndex: "imageUrl",
      align: "center" as const,
      width: 100,
      render(imageUrl: string) {
        return <Thumbnail url={imageUrl} width={64} height={64} />;
      },
    },
    {
      title: "管理名",
      dataIndex: "name",
      width: 150,
      render(name: string, { id }: Coupon) {
        return canAccess("editCoupon") ? (
          <Link to={`/coupon/${id}/edit`}>
            <Button type="link">{name}</Button>
          </Link>
        ) : (
          name
        );
      },
    },
    {
      title: "表示名",
      dataIndex: "title",
      width: 150,
    },
    {
      title: "クーポン内容",
      dataIndex: "content",
      width: 150,
    },
    {
      title: "クーポン期間",
      width: 150,
      render(_: string, { availableFrom, availableUntil }: Coupon) {
        return `${dayjs(availableFrom).format(DATE_FORMAT)} ~ ${
          availableUntil ? dayjs(availableUntil).format(DATE_FORMAT) : "無期限"
        }`;
      },
    },
    {
      title: "クーポン有効期限",
      dataIndex: "availableDays",
      width: 160,
      render(days: string | null) {
        return days ? `${days} 日` : "無期限";
      },
    },
    canAccess("addCoupon")
      ? ({
          title: "",
          align: "center",
          fixed: "right",
          width: 60,
          render(_: string, { id }: Coupon) {
            return (
              <Tooltip title="コピーして新規作成">
                <IconLink to={`/coupon/add?id=${id}`}>
                  <CopyIcon />
                </IconLink>
              </Tooltip>
            );
          },
        } as const)
      : null,
  ].filter(isNotNull);

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={coupons}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
