import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddLineReportingBotConfigIssueLineReportingBotVerificationCode = gql`
    mutation AddLineReportingBotConfigIssueLineReportingBotVerificationCode($input: IssueLineReportingBotVerificationCodeInput!) {
  dashboardAccountIssueLineReportingBotVerificationCode(input: $input) {
    expiredAt
    lineReportingBotVerificationId
    verificationCode
  }
}
    `;
export const AddLineReportingBotConfigGetLineReportingBotConfig = gql`
    query AddLineReportingBotConfigGetLineReportingBotConfig($corporationId: uuid!, $lineReportingBotVerificationId: uuid!) {
  lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, lineReportingBotVerificationId: {_eq: $lineReportingBotVerificationId}}
  ) {
    id
  }
}
    `;
export const AddLineReportingBotConfigUpdateLineReportingBotConfig = gql`
    mutation AddLineReportingBotConfigUpdateLineReportingBotConfig($corporationId: uuid!, $lineReportingBotConfigId: uuid!, $name: String!, $input: [lineReportingBotConfigShop_insert_input!]!) {
  update_lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, id: {_eq: $lineReportingBotConfigId}}
    _set: {name: $name}
  ) {
    affected_rows
  }
  insert_lineReportingBotConfigShop(objects: $input) {
    affected_rows
  }
}
    `;
export const AddLineReportingBotConfigGetLineReportingBotVerification = gql`
    query AddLineReportingBotConfigGetLineReportingBotVerification($lineReportingBotVerificationId: uuid!) {
  lineReportingBotVerification_by_pk(id: $lineReportingBotVerificationId) {
    id
    expiredAt
    code
  }
}
    `;
export const AddLineReportingBotConfigGetShops = gql`
    query AddLineReportingBotConfigGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationVariables = Types.Exact<{
  input: Types.IssueLineReportingBotVerificationCodeInput;
}>;


export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountIssueLineReportingBotVerificationCode: (
    { __typename?: 'IssueLineReportingBotVerificationCodeResponse' }
    & Pick<Types.IssueLineReportingBotVerificationCodeResponse, 'expiredAt' | 'lineReportingBotVerificationId' | 'verificationCode'>
  ) }
);

export type AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  lineReportingBotVerificationId: Types.Scalars['uuid'];
}>;


export type AddLineReportingBotConfigGetLineReportingBotConfigQuery = (
  { __typename?: 'query_root' }
  & { lineReportingBotConfig: Array<(
    { __typename?: 'lineReportingBotConfig' }
    & Pick<Types.LineReportingBotConfig, 'id'>
  )> }
);

export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  lineReportingBotConfigId: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
  input: Array<Types.LineReportingBotConfigShopInsertInput> | Types.LineReportingBotConfigShopInsertInput;
}>;


export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_lineReportingBotConfig?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfig_mutation_response' }
    & Pick<Types.LineReportingBotConfigMutationResponse, 'affected_rows'>
  )>, insert_lineReportingBotConfigShop?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfigShop_mutation_response' }
    & Pick<Types.LineReportingBotConfigShopMutationResponse, 'affected_rows'>
  )> }
);

export type AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables = Types.Exact<{
  lineReportingBotVerificationId: Types.Scalars['uuid'];
}>;


export type AddLineReportingBotConfigGetLineReportingBotVerificationQuery = (
  { __typename?: 'query_root' }
  & { lineReportingBotVerification_by_pk?: Types.Maybe<(
    { __typename?: 'lineReportingBotVerification' }
    & Pick<Types.LineReportingBotVerification, 'id' | 'expiredAt' | 'code'>
  )> }
);

export type AddLineReportingBotConfigGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type AddLineReportingBotConfigGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const AddLineReportingBotConfigIssueLineReportingBotVerificationCodeDocument = gql`
    mutation AddLineReportingBotConfigIssueLineReportingBotVerificationCode($input: IssueLineReportingBotVerificationCodeInput!) {
  dashboardAccountIssueLineReportingBotVerificationCode(input: $input) {
    expiredAt
    lineReportingBotVerificationId
    verificationCode
  }
}
    `;
export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationFn = Apollo.MutationFunction<AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation, AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationVariables>;

/**
 * __useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation__
 *
 * To run a mutation, you first call `useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation, { data, loading, error }] = useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation, AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation, AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationVariables>(AddLineReportingBotConfigIssueLineReportingBotVerificationCodeDocument, options);
      }
export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationHookResult = ReturnType<typeof useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation>;
export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationResult = Apollo.MutationResult<AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation>;
export type AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationOptions = Apollo.BaseMutationOptions<AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation, AddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutationVariables>;
export const AddLineReportingBotConfigGetLineReportingBotConfigDocument = gql`
    query AddLineReportingBotConfigGetLineReportingBotConfig($corporationId: uuid!, $lineReportingBotVerificationId: uuid!) {
  lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, lineReportingBotVerificationId: {_eq: $lineReportingBotVerificationId}}
  ) {
    id
  }
}
    `;

/**
 * __useAddLineReportingBotConfigGetLineReportingBotConfigQuery__
 *
 * To run a query within a React component, call `useAddLineReportingBotConfigGetLineReportingBotConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddLineReportingBotConfigGetLineReportingBotConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddLineReportingBotConfigGetLineReportingBotConfigQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      lineReportingBotVerificationId: // value for 'lineReportingBotVerificationId'
 *   },
 * });
 */
export function useAddLineReportingBotConfigGetLineReportingBotConfigQuery(baseOptions: Apollo.QueryHookOptions<AddLineReportingBotConfigGetLineReportingBotConfigQuery, AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddLineReportingBotConfigGetLineReportingBotConfigQuery, AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables>(AddLineReportingBotConfigGetLineReportingBotConfigDocument, options);
      }
export function useAddLineReportingBotConfigGetLineReportingBotConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddLineReportingBotConfigGetLineReportingBotConfigQuery, AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddLineReportingBotConfigGetLineReportingBotConfigQuery, AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables>(AddLineReportingBotConfigGetLineReportingBotConfigDocument, options);
        }
export type AddLineReportingBotConfigGetLineReportingBotConfigQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetLineReportingBotConfigQuery>;
export type AddLineReportingBotConfigGetLineReportingBotConfigLazyQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetLineReportingBotConfigLazyQuery>;
export type AddLineReportingBotConfigGetLineReportingBotConfigQueryResult = Apollo.QueryResult<AddLineReportingBotConfigGetLineReportingBotConfigQuery, AddLineReportingBotConfigGetLineReportingBotConfigQueryVariables>;
export const AddLineReportingBotConfigUpdateLineReportingBotConfigDocument = gql`
    mutation AddLineReportingBotConfigUpdateLineReportingBotConfig($corporationId: uuid!, $lineReportingBotConfigId: uuid!, $name: String!, $input: [lineReportingBotConfigShop_insert_input!]!) {
  update_lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, id: {_eq: $lineReportingBotConfigId}}
    _set: {name: $name}
  ) {
    affected_rows
  }
  insert_lineReportingBotConfigShop(objects: $input) {
    affected_rows
  }
}
    `;
export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutationFn = Apollo.MutationFunction<AddLineReportingBotConfigUpdateLineReportingBotConfigMutation, AddLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>;

/**
 * __useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation__
 *
 * To run a mutation, you first call `useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineReportingBotConfigUpdateLineReportingBotConfigMutation, { data, loading, error }] = useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      lineReportingBotConfigId: // value for 'lineReportingBotConfigId'
 *      name: // value for 'name'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation(baseOptions?: Apollo.MutationHookOptions<AddLineReportingBotConfigUpdateLineReportingBotConfigMutation, AddLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLineReportingBotConfigUpdateLineReportingBotConfigMutation, AddLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>(AddLineReportingBotConfigUpdateLineReportingBotConfigDocument, options);
      }
export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutationHookResult = ReturnType<typeof useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation>;
export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutationResult = Apollo.MutationResult<AddLineReportingBotConfigUpdateLineReportingBotConfigMutation>;
export type AddLineReportingBotConfigUpdateLineReportingBotConfigMutationOptions = Apollo.BaseMutationOptions<AddLineReportingBotConfigUpdateLineReportingBotConfigMutation, AddLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>;
export const AddLineReportingBotConfigGetLineReportingBotVerificationDocument = gql`
    query AddLineReportingBotConfigGetLineReportingBotVerification($lineReportingBotVerificationId: uuid!) {
  lineReportingBotVerification_by_pk(id: $lineReportingBotVerificationId) {
    id
    expiredAt
    code
  }
}
    `;

/**
 * __useAddLineReportingBotConfigGetLineReportingBotVerificationQuery__
 *
 * To run a query within a React component, call `useAddLineReportingBotConfigGetLineReportingBotVerificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddLineReportingBotConfigGetLineReportingBotVerificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddLineReportingBotConfigGetLineReportingBotVerificationQuery({
 *   variables: {
 *      lineReportingBotVerificationId: // value for 'lineReportingBotVerificationId'
 *   },
 * });
 */
export function useAddLineReportingBotConfigGetLineReportingBotVerificationQuery(baseOptions: Apollo.QueryHookOptions<AddLineReportingBotConfigGetLineReportingBotVerificationQuery, AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddLineReportingBotConfigGetLineReportingBotVerificationQuery, AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables>(AddLineReportingBotConfigGetLineReportingBotVerificationDocument, options);
      }
export function useAddLineReportingBotConfigGetLineReportingBotVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddLineReportingBotConfigGetLineReportingBotVerificationQuery, AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddLineReportingBotConfigGetLineReportingBotVerificationQuery, AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables>(AddLineReportingBotConfigGetLineReportingBotVerificationDocument, options);
        }
export type AddLineReportingBotConfigGetLineReportingBotVerificationQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetLineReportingBotVerificationQuery>;
export type AddLineReportingBotConfigGetLineReportingBotVerificationLazyQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetLineReportingBotVerificationLazyQuery>;
export type AddLineReportingBotConfigGetLineReportingBotVerificationQueryResult = Apollo.QueryResult<AddLineReportingBotConfigGetLineReportingBotVerificationQuery, AddLineReportingBotConfigGetLineReportingBotVerificationQueryVariables>;
export const AddLineReportingBotConfigGetShopsDocument = gql`
    query AddLineReportingBotConfigGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useAddLineReportingBotConfigGetShopsQuery__
 *
 * To run a query within a React component, call `useAddLineReportingBotConfigGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddLineReportingBotConfigGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddLineReportingBotConfigGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useAddLineReportingBotConfigGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AddLineReportingBotConfigGetShopsQuery, AddLineReportingBotConfigGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddLineReportingBotConfigGetShopsQuery, AddLineReportingBotConfigGetShopsQueryVariables>(AddLineReportingBotConfigGetShopsDocument, options);
      }
export function useAddLineReportingBotConfigGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddLineReportingBotConfigGetShopsQuery, AddLineReportingBotConfigGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddLineReportingBotConfigGetShopsQuery, AddLineReportingBotConfigGetShopsQueryVariables>(AddLineReportingBotConfigGetShopsDocument, options);
        }
export type AddLineReportingBotConfigGetShopsQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetShopsQuery>;
export type AddLineReportingBotConfigGetShopsLazyQueryHookResult = ReturnType<typeof useAddLineReportingBotConfigGetShopsLazyQuery>;
export type AddLineReportingBotConfigGetShopsQueryResult = Apollo.QueryResult<AddLineReportingBotConfigGetShopsQuery, AddLineReportingBotConfigGetShopsQueryVariables>;