import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { PikaichiMenuInsertInput } from "types/graphql";

import { EditMenuPikaichiMenuForm } from "./EditMenuPikaichiMenuForm";
import {
  useEditMenuPikaichiMenuGetMenuQuery,
  useEditMenuPikaichiMenuGetPikaichiMenuCdQuery,
  useEditMenuPikaichiMenuUpdatePikaichiMenuMutation,
} from "./queries";

export const EditMenuPikaichiMenu = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
    error: errorGetPikaichiMenuData,
  } = useEditMenuPikaichiMenuGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];

  const pikaichiMenu = menu?.pikaichiMenuMenus?.[0]?.pikaichiMenu;

  const companyId = menu?.companyId;

  const { data: getPikaichiMenuCodesData, loading: loadingGetPikaichiMenuCodes } =
    useEditMenuPikaichiMenuGetPikaichiMenuCdQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );

  const [updatePikaichiMenuMutation, { loading: loadingUpdatePikaichiMenu }] =
    useEditMenuPikaichiMenuUpdatePikaichiMenuMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiMenuInsertInput, "companyId">) => {
      if (!company || !menu) return;

      const pikaichiMenuId = pikaichiMenu?.pikaichiMenuId ?? uuidv4();
      const pikaichiMenuDto: PikaichiMenuInsertInput = {
        pikaichiMenuId,
        companyId: company.id,
        ...input,
      };

      try {
        await updatePikaichiMenuMutation({
          variables: {
            menuId: menu.id,
            _menuId: menu.menuId,
            pikaichiMenuId,
            pikaichiMenu: pikaichiMenuDto,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchMenu();
    },
    [company, menu, pikaichiMenu?.pikaichiMenuId, refetchMenu, updatePikaichiMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingMenu || loadingGetPikaichiMenuCodes;

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getPikaichiMenuCodesData?.pikaichiMenu ?? [])
      .map((c) => (!isNaN(Number(c.pikaichiMenuCd)) ? Number(c.pikaichiMenuCd) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? (Math.max(...menuCodes) + 1).toString() : "1";
  }, [getPikaichiMenuCodesData?.pikaichiMenu]);

  const shouldShowNetworkErrorAlert = Boolean(errorGetPikaichiMenuData);

  const canEditAllShopMenus = menu?.totalDealingShopCount === menu?.shopMenus.length;
  const isEditMenuFeatureEnabled = isFeatureEnabled("editMenu");

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(shouldShowNetworkErrorAlert)}
        shouldShowManagingShopErrorAlert={isEditMenuFeatureEnabled && !canEditAllShopMenus}
      />
      {loading && <Loading height={300} />}
      {menu && (
        <EditMenuPikaichiMenuForm
          menu={menu}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdatePikaichiMenu}
        />
      )}
    </DashboardLayout>
  );
};
