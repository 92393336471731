import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanGroupFormItem } from "../useEditPlanGroupForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditPlanGroupFormItem
    label="プラングループ名"
    name="name"
    rules={[{ required: true, message: "プラングループ名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditPlanGroupFormItem>
));
