import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCompanyGetCompany = gql`
    query EditCompanyGetCompany($serial: Int!) {
  company(where: {serial: {_eq: $serial}, archivedAt: {_is_null: true}}) {
    id
    companyId
    name
  }
}
    `;
export const EditCompanyUpdateCompany = gql`
    mutation EditCompanyUpdateCompany($companyId: uuid!, $name: String!) {
  update_company(_set: {name: $name}, where: {id: {_eq: $companyId}}) {
    affected_rows
  }
}
    `;
export type EditCompanyGetCompanyQueryVariables = Types.Exact<{
  serial: Types.Scalars['Int'];
}>;


export type EditCompanyGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'companyId' | 'name'>
  )> }
);

export type EditCompanyUpdateCompanyMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
}>;


export type EditCompanyUpdateCompanyMutation = (
  { __typename?: 'mutation_root' }
  & { update_company?: Types.Maybe<(
    { __typename?: 'company_mutation_response' }
    & Pick<Types.CompanyMutationResponse, 'affected_rows'>
  )> }
);


export const EditCompanyGetCompanyDocument = gql`
    query EditCompanyGetCompany($serial: Int!) {
  company(where: {serial: {_eq: $serial}, archivedAt: {_is_null: true}}) {
    id
    companyId
    name
  }
}
    `;

/**
 * __useEditCompanyGetCompanyQuery__
 *
 * To run a query within a React component, call `useEditCompanyGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCompanyGetCompanyQuery({
 *   variables: {
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useEditCompanyGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<EditCompanyGetCompanyQuery, EditCompanyGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCompanyGetCompanyQuery, EditCompanyGetCompanyQueryVariables>(EditCompanyGetCompanyDocument, options);
      }
export function useEditCompanyGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCompanyGetCompanyQuery, EditCompanyGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCompanyGetCompanyQuery, EditCompanyGetCompanyQueryVariables>(EditCompanyGetCompanyDocument, options);
        }
export type EditCompanyGetCompanyQueryHookResult = ReturnType<typeof useEditCompanyGetCompanyQuery>;
export type EditCompanyGetCompanyLazyQueryHookResult = ReturnType<typeof useEditCompanyGetCompanyLazyQuery>;
export type EditCompanyGetCompanyQueryResult = Apollo.QueryResult<EditCompanyGetCompanyQuery, EditCompanyGetCompanyQueryVariables>;
export const EditCompanyUpdateCompanyDocument = gql`
    mutation EditCompanyUpdateCompany($companyId: uuid!, $name: String!) {
  update_company(_set: {name: $name}, where: {id: {_eq: $companyId}}) {
    affected_rows
  }
}
    `;
export type EditCompanyUpdateCompanyMutationFn = Apollo.MutationFunction<EditCompanyUpdateCompanyMutation, EditCompanyUpdateCompanyMutationVariables>;

/**
 * __useEditCompanyUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyUpdateCompanyMutation, { data, loading, error }] = useEditCompanyUpdateCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditCompanyUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<EditCompanyUpdateCompanyMutation, EditCompanyUpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCompanyUpdateCompanyMutation, EditCompanyUpdateCompanyMutationVariables>(EditCompanyUpdateCompanyDocument, options);
      }
export type EditCompanyUpdateCompanyMutationHookResult = ReturnType<typeof useEditCompanyUpdateCompanyMutation>;
export type EditCompanyUpdateCompanyMutationResult = Apollo.MutationResult<EditCompanyUpdateCompanyMutation>;
export type EditCompanyUpdateCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyUpdateCompanyMutation, EditCompanyUpdateCompanyMutationVariables>;