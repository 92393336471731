import React, { memo } from "react";
import { FormItemProps } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { EditOnboardingHandoutFormItem } from "../useEditOnboardingHandoutForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OnboardingHandoutField = memo<Props>((props) => (
  <EditOnboardingHandoutFormItem
    label="配布物"
    name="onboardingHandout"
    help="ラベルと URL(またはテキスト)を改行して入力してください"
    {...props}
  >
    <TextArea rows={12} />
  </EditOnboardingHandoutFormItem>
));
