import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddKitchenDisplayDisplayTargetInsertKdDisplayTarget = gql`
    mutation AddKitchenDisplayDisplayTargetInsertKdDisplayTarget($kdDisplayTargets: [kdDisplayTarget_insert_input!]!) {
  insert_kdDisplayTarget(
    objects: $kdDisplayTargets
    on_conflict: {constraint: kdDisplayTarget_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const AddKitchenDisplayDisplayTargetGetRoles = gql`
    query AddKitchenDisplayDisplayTargetGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;
export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationVariables = Types.Exact<{
  kdDisplayTargets: Array<Types.KdDisplayTargetInsertInput> | Types.KdDisplayTargetInsertInput;
}>;


export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation = (
  { __typename?: 'mutation_root' }
  & { insert_kdDisplayTarget?: Types.Maybe<(
    { __typename?: 'kdDisplayTarget_mutation_response' }
    & Pick<Types.KdDisplayTargetMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'kdDisplayTarget' }
      & Pick<Types.KdDisplayTarget, 'id'>
    )> }
  )> }
);

export type AddKitchenDisplayDisplayTargetGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddKitchenDisplayDisplayTargetGetRolesQuery = (
  { __typename?: 'query_root' }
  & { role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'id' | 'name' | 'roleId'>
  )> }
);


export const AddKitchenDisplayDisplayTargetInsertKdDisplayTargetDocument = gql`
    mutation AddKitchenDisplayDisplayTargetInsertKdDisplayTarget($kdDisplayTargets: [kdDisplayTarget_insert_input!]!) {
  insert_kdDisplayTarget(
    objects: $kdDisplayTargets
    on_conflict: {constraint: kdDisplayTarget_pkey, update_columns: []}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationFn = Apollo.MutationFunction<AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation, AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationVariables>;

/**
 * __useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation__
 *
 * To run a mutation, you first call `useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation, { data, loading, error }] = useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation({
 *   variables: {
 *      kdDisplayTargets: // value for 'kdDisplayTargets'
 *   },
 * });
 */
export function useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation(baseOptions?: Apollo.MutationHookOptions<AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation, AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation, AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationVariables>(AddKitchenDisplayDisplayTargetInsertKdDisplayTargetDocument, options);
      }
export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation>;
export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationResult = Apollo.MutationResult<AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation>;
export type AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationOptions = Apollo.BaseMutationOptions<AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutation, AddKitchenDisplayDisplayTargetInsertKdDisplayTargetMutationVariables>;
export const AddKitchenDisplayDisplayTargetGetRolesDocument = gql`
    query AddKitchenDisplayDisplayTargetGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;

/**
 * __useAddKitchenDisplayDisplayTargetGetRolesQuery__
 *
 * To run a query within a React component, call `useAddKitchenDisplayDisplayTargetGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddKitchenDisplayDisplayTargetGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddKitchenDisplayDisplayTargetGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddKitchenDisplayDisplayTargetGetRolesQuery(baseOptions: Apollo.QueryHookOptions<AddKitchenDisplayDisplayTargetGetRolesQuery, AddKitchenDisplayDisplayTargetGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddKitchenDisplayDisplayTargetGetRolesQuery, AddKitchenDisplayDisplayTargetGetRolesQueryVariables>(AddKitchenDisplayDisplayTargetGetRolesDocument, options);
      }
export function useAddKitchenDisplayDisplayTargetGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddKitchenDisplayDisplayTargetGetRolesQuery, AddKitchenDisplayDisplayTargetGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddKitchenDisplayDisplayTargetGetRolesQuery, AddKitchenDisplayDisplayTargetGetRolesQueryVariables>(AddKitchenDisplayDisplayTargetGetRolesDocument, options);
        }
export type AddKitchenDisplayDisplayTargetGetRolesQueryHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetGetRolesQuery>;
export type AddKitchenDisplayDisplayTargetGetRolesLazyQueryHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetGetRolesLazyQuery>;
export type AddKitchenDisplayDisplayTargetGetRolesQueryResult = Apollo.QueryResult<AddKitchenDisplayDisplayTargetGetRolesQuery, AddKitchenDisplayDisplayTargetGetRolesQueryVariables>;