import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { KdDisplayTarget, KdDisplayTargetInsertInput } from "types/graphql";

import { Role } from "../types";

export type AddKitchenDisplayDisplayTargetFormValues = Pick<KdDisplayTarget, "name"> & {
  roleId: KdDisplayTarget["_roleId"];
};

const getInitialValues = () => ({});

export const AddKitchenDisplayDisplayTargetFormItem =
  createFormItem<AddKitchenDisplayDisplayTargetFormValues>();

export const useAddKitchenDisplayDisplayTargetForm = ({
  roles,
  onSubmit,
}: {
  roles: Role[];
  onSubmit: (kdDisplayTarget: Omit<KdDisplayTargetInsertInput, "shopId">) => void;
}) => {
  const [form] = Form.useForm<AddKitchenDisplayDisplayTargetFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const { roleId, ...rest } = form.getFieldsValue();

    const role = roles.find((role) => role.roleId === roleId);

    if (roleId && !role) throw new Error("role not found");

    onSubmit({
      roleId: role?.id,
      _roleId: role?.roleId,
      ...rest,
    });
  }, [form, onSubmit, roles]);

  return { form, initialValues, submit };
};
