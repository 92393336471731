import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { Spacer } from "../../components/Spacer/index";

import { MenuRecommendationTable } from "./MenuRecommendationTable";
import {
  useMenuRecommendationGetMenuRecommendationQuery,
  useMenuRecommendationUpdateMenuRecommendationEnabledMutation,
} from "./queries";

export const MenuRecommendations = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenuRecommendationsData,
    loading: loadingMenuRecommendations,
    refetch: refetchMenuRecommendation,
    error,
  } = useMenuRecommendationGetMenuRecommendationQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const menuRecommendations = useMemo(
    () => getMenuRecommendationsData?.menuRecommendationMeta ?? [],
    [getMenuRecommendationsData],
  );

  const [updateEnabledMutation] = useMenuRecommendationUpdateMenuRecommendationEnabledMutation();

  const handleToggle = useCallback(
    async ({ id, enabled }: { id: string; enabled: boolean }) => {
      try {
        await updateEnabledMutation({
          variables: { id, enabled },
        });

        await refetchMenuRecommendation();

        message.success("設定を更新しました");
      } catch (e) {
        message.error("設定の更新に失敗しました");
      }
    },
    [refetchMenuRecommendation, updateEnabledMutation],
  );

  const hasMenuRecommendation = menuRecommendations.length > 0;

  return (
    <DashboardLayout title="おすすめ一覧">
      <PageHeader
        title="おすすめ一覧"
        extra={[
          isFeatureEnabled("addMenuRecommendation") &&
            !loadingMenuRecommendations &&
            !hasMenuRecommendation && (
              <Link key="add" to="/menuRecommendation/add">
                <Button type="primary">新規作成</Button>
              </Link>
            ),
        ]}
      />
      <Spacer size={16} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <MenuRecommendationTable
        loading={loadingMenuRecommendations}
        menuRecommendations={menuRecommendations}
        onToggleMenuRecommendation={handleToggle}
      />
    </DashboardLayout>
  );
};
