import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { OnSitePaymentDetailType } from "pages/EditOnSitePaymentDetailType/types";
import { OnSitePaymentDetailTypeSetInput } from "types/graphql";

export type EditOnSitePaymentDetailTypeFormValues = Pick<
  OnSitePaymentDetailType,
  "label" | "onSitePaymentDetailTypeCategory" | "allowChange"
>;

const getInitialValues = (onSitePaymentDetailType: OnSitePaymentDetailType) => {
  const { label, allowChange, onSitePaymentDetailTypeCategory } = onSitePaymentDetailType;
  return { label, allowChange, onSitePaymentDetailTypeCategory };
};

export const EditOnSitePaymentDetailTypeFormItem =
  createFormItem<EditOnSitePaymentDetailTypeFormValues>();

export const useEditOnSitePaymentDetailTypeForm = (
  onSitePaymentDetailType: OnSitePaymentDetailType,
  onSubmit: (onSitePaymentDetailType: OnSitePaymentDetailTypeSetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(onSitePaymentDetailType);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditOnSitePaymentDetailTypeFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
