import React, { memo } from "react";
import styled, { css } from "styled-components";
import { type ScoreRank, rankViewProperties } from "models/questionnaireAnalytics";

const rankDegrees = {
  S: 17,
  A: 54,
  B: 91,
  C: 126,
  D: 162,
};

const ORIGIN_TO_ICON_LENGTH = 72;
const ICON_SIDE_LENGTH = 24;

const calcRadian = (degree: number) => degree * (Math.PI / 180);

const calcIconCoordinate = (rank: ScoreRank) => {
  const radian = calcRadian(rankDegrees[rank]);
  return {
    x: Math.cos(radian) * ORIGIN_TO_ICON_LENGTH,
    y: Math.sin(radian) * ORIGIN_TO_ICON_LENGTH,
  };
};

const ranks: ScoreRank[] = ["S", "A", "B", "C", "D"];

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 16px;
  position: relative;
`;

const Container = styled.div`
  position: relative;
`;

const GaugeNeedle = styled.div<{ rank: ScoreRank }>`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  background-color: #8c8c8c;
  border-radius: 50%;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 34px;
    height: 4px;
    background-color: #8c8c8c;
    top: 50%;
    left: 50%;
    transform-origin: left center;

    ${({ rank }) => css`
      transform: rotate(-${rankDegrees[rank]}deg);
    `}
  }
`;

const FaceIcon = styled.img<{ x: number; y: number }>`
  width: ${ICON_SIDE_LENGTH}px;
  height: ${ICON_SIDE_LENGTH}px;
  position: absolute;
  transform: translateX(-50%) translateY(50%);
  ${({ x, y }) => css`
    left: calc(50% + ${x}px);
    bottom: ${y}px;
  `}
`;

type Props = {
  rank: ScoreRank;
};

export const ScoreGauge = memo<Props>(({ rank }) => (
  <Wrapper>
    <Container>
      <img
        src="/questionnaire-analytics/rank_gauge.png"
        width={269}
        height={135}
        alt="rank gauge"
      />

      {ranks.map((rank) => {
        const { iconSrc } = rankViewProperties[rank];
        const { x, y } = calcIconCoordinate(rank);

        return (
          <FaceIcon
            key={rank}
            alt={`icon ${rank}`}
            src={iconSrc}
            width={ICON_SIDE_LENGTH}
            height={ICON_SIDE_LENGTH}
            x={x}
            y={y}
          />
        );
      })}
      <GaugeNeedle rank={rank} />
    </Container>
  </Wrapper>
));
