import styled from "styled-components";

import { colors, grey, oldColors } from "constants/colors";

export const TableStyleModifier = styled.div`
  // 一番右のカラムの背景をオレンジにして、文字色を白にする対応
  .ant-table-cell:last-child {
    background-color: ${oldColors.Orange};
    color: ${grey[0]};
  }

  // 上記の対応によって、レコードが空のときに「データがありません」表示もオレンジになってしまうので、それを防ぐ対応
  .ant-table-tbody .ant-table-cell:first-child {
    background-color: ${grey[0]};
    color: ${colors.Text.Default};
  }

  // 一番右のカラムのソートアイコンを白にする対応
  .ant-table-cell:last-child .ant-table-column-sorter {
    color: ${grey[0]};
  }

  // 一番右のカラムのホバー時の色を変える対応
  .ant-table-tbody > tr.ant-table-row:hover > td:last-child,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover:last-child,
  .ant-table-thead th.ant-table-column-has-sorters:hover:last-child {
    background: ${oldColors.Orange};
    opacity: 0.8;
  }

  // アコーディオンで開いた子行のボーダーをなくす対応
  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }
  .ant-table-tbody > tr > td {
    border-top: 1px solid ${grey[3]};
  }
  .ant-table-tbody > tr.ant-table-row-level-1 > td {
    border-top: 0px;
  }
  .ant-table-thead > tr > th {
    border-bottom: 0px;
  }
`;
