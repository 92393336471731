import { memo, useEffect } from "react";
import { NamePath } from "antd/es/form/interface";

import { CouponTypeEnum } from "types/graphql";

import { Menu } from "../../../types";

type Props = {
  menuId: number;
  menus: Menu[];
  setFieldValue: (name: NamePath, value: any) => void;
};

export const AutoFillEffect = memo<Props>(({ menuId, menus, setFieldValue }) => {
  const selectedMenu = menus.find((menu) => menu.menuId === menuId);
  const taxMethod = selectedMenu?.taxMethod;
  const taxRate = selectedMenu?.taxRate;
  const price = selectedMenu?.price;

  useEffect(() => {
    setFieldValue([CouponTypeEnum.MenuDiscount, "taxMethod"], taxMethod);
    setFieldValue([CouponTypeEnum.MenuDiscount, "taxRate"], taxRate);
    setFieldValue([CouponTypeEnum.MenuDiscount, "normalPrice"], price);
  }, [taxMethod, taxRate, price, setFieldValue]);

  return null;
});
