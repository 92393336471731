import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanChoiceModalGetPlanOption = gql`
    query AddPlanChoiceModalGetPlanOption($planId: Int!, $planOptionId: Int!) {
  planOption(
    where: {_planId: {_eq: $planId}, planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
  ) {
    id
    planOptionId
    inputType
    plan {
      taxMethod
      taxRate
      company {
        defaultCostTaxMethod
      }
    }
  }
}
    `;
export const AddPlanChoiceModalGetMaxPlanChoicePriority = gql`
    query AddPlanChoiceModalGetMaxPlanChoicePriority($planId: Int!, $planOptionId: Int!) {
  planChoice_aggregate(
    where: {_planOptionId: {_eq: $planOptionId}, planOption: {_planId: {_eq: $planId}, archivedAt: {_is_null: true}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddPlanChoiceModalInsertPlanChoice = gql`
    mutation AddPlanChoiceModalInsertPlanChoice($planChoice: CreatePlanChoiceInput!) {
  createPlanChoice(input: $planChoice) {
    planChoiceId
  }
}
    `;
export type AddPlanChoiceModalGetPlanOptionQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  planOptionId: Types.Scalars['Int'];
}>;


export type AddPlanChoiceModalGetPlanOptionQuery = (
  { __typename?: 'query_root' }
  & { planOption: Array<(
    { __typename?: 'planOption' }
    & Pick<Types.PlanOption, 'id' | 'planOptionId' | 'inputType'>
    & { plan: (
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'taxMethod' | 'taxRate'>
      & { company: (
        { __typename?: 'company' }
        & Pick<Types.Company, 'defaultCostTaxMethod'>
      ) }
    ) }
  )> }
);

export type AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  planOptionId: Types.Scalars['Int'];
}>;


export type AddPlanChoiceModalGetMaxPlanChoicePriorityQuery = (
  { __typename?: 'query_root' }
  & { planChoice_aggregate: (
    { __typename?: 'planChoice_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'planChoice_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'planChoice_max_fields' }
        & Pick<Types.PlanChoiceMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddPlanChoiceModalInsertPlanChoiceMutationVariables = Types.Exact<{
  planChoice: Types.CreatePlanChoiceInput;
}>;


export type AddPlanChoiceModalInsertPlanChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { createPlanChoice: (
    { __typename?: 'CreatePlanChoiceOutput' }
    & Pick<Types.CreatePlanChoiceOutput, 'planChoiceId'>
  ) }
);


export const AddPlanChoiceModalGetPlanOptionDocument = gql`
    query AddPlanChoiceModalGetPlanOption($planId: Int!, $planOptionId: Int!) {
  planOption(
    where: {_planId: {_eq: $planId}, planOptionId: {_eq: $planOptionId}, archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}
  ) {
    id
    planOptionId
    inputType
    plan {
      taxMethod
      taxRate
      company {
        defaultCostTaxMethod
      }
    }
  }
}
    `;

/**
 * __useAddPlanChoiceModalGetPlanOptionQuery__
 *
 * To run a query within a React component, call `useAddPlanChoiceModalGetPlanOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanChoiceModalGetPlanOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanChoiceModalGetPlanOptionQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      planOptionId: // value for 'planOptionId'
 *   },
 * });
 */
export function useAddPlanChoiceModalGetPlanOptionQuery(baseOptions: Apollo.QueryHookOptions<AddPlanChoiceModalGetPlanOptionQuery, AddPlanChoiceModalGetPlanOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanChoiceModalGetPlanOptionQuery, AddPlanChoiceModalGetPlanOptionQueryVariables>(AddPlanChoiceModalGetPlanOptionDocument, options);
      }
export function useAddPlanChoiceModalGetPlanOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanChoiceModalGetPlanOptionQuery, AddPlanChoiceModalGetPlanOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanChoiceModalGetPlanOptionQuery, AddPlanChoiceModalGetPlanOptionQueryVariables>(AddPlanChoiceModalGetPlanOptionDocument, options);
        }
export type AddPlanChoiceModalGetPlanOptionQueryHookResult = ReturnType<typeof useAddPlanChoiceModalGetPlanOptionQuery>;
export type AddPlanChoiceModalGetPlanOptionLazyQueryHookResult = ReturnType<typeof useAddPlanChoiceModalGetPlanOptionLazyQuery>;
export type AddPlanChoiceModalGetPlanOptionQueryResult = Apollo.QueryResult<AddPlanChoiceModalGetPlanOptionQuery, AddPlanChoiceModalGetPlanOptionQueryVariables>;
export const AddPlanChoiceModalGetMaxPlanChoicePriorityDocument = gql`
    query AddPlanChoiceModalGetMaxPlanChoicePriority($planId: Int!, $planOptionId: Int!) {
  planChoice_aggregate(
    where: {_planOptionId: {_eq: $planOptionId}, planOption: {_planId: {_eq: $planId}, archivedAt: {_is_null: true}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery__
 *
 * To run a query within a React component, call `useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      planOptionId: // value for 'planOptionId'
 *   },
 * });
 */
export function useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery(baseOptions: Apollo.QueryHookOptions<AddPlanChoiceModalGetMaxPlanChoicePriorityQuery, AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanChoiceModalGetMaxPlanChoicePriorityQuery, AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables>(AddPlanChoiceModalGetMaxPlanChoicePriorityDocument, options);
      }
export function useAddPlanChoiceModalGetMaxPlanChoicePriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanChoiceModalGetMaxPlanChoicePriorityQuery, AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanChoiceModalGetMaxPlanChoicePriorityQuery, AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables>(AddPlanChoiceModalGetMaxPlanChoicePriorityDocument, options);
        }
export type AddPlanChoiceModalGetMaxPlanChoicePriorityQueryHookResult = ReturnType<typeof useAddPlanChoiceModalGetMaxPlanChoicePriorityQuery>;
export type AddPlanChoiceModalGetMaxPlanChoicePriorityLazyQueryHookResult = ReturnType<typeof useAddPlanChoiceModalGetMaxPlanChoicePriorityLazyQuery>;
export type AddPlanChoiceModalGetMaxPlanChoicePriorityQueryResult = Apollo.QueryResult<AddPlanChoiceModalGetMaxPlanChoicePriorityQuery, AddPlanChoiceModalGetMaxPlanChoicePriorityQueryVariables>;
export const AddPlanChoiceModalInsertPlanChoiceDocument = gql`
    mutation AddPlanChoiceModalInsertPlanChoice($planChoice: CreatePlanChoiceInput!) {
  createPlanChoice(input: $planChoice) {
    planChoiceId
  }
}
    `;
export type AddPlanChoiceModalInsertPlanChoiceMutationFn = Apollo.MutationFunction<AddPlanChoiceModalInsertPlanChoiceMutation, AddPlanChoiceModalInsertPlanChoiceMutationVariables>;

/**
 * __useAddPlanChoiceModalInsertPlanChoiceMutation__
 *
 * To run a mutation, you first call `useAddPlanChoiceModalInsertPlanChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanChoiceModalInsertPlanChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanChoiceModalInsertPlanChoiceMutation, { data, loading, error }] = useAddPlanChoiceModalInsertPlanChoiceMutation({
 *   variables: {
 *      planChoice: // value for 'planChoice'
 *   },
 * });
 */
export function useAddPlanChoiceModalInsertPlanChoiceMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanChoiceModalInsertPlanChoiceMutation, AddPlanChoiceModalInsertPlanChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanChoiceModalInsertPlanChoiceMutation, AddPlanChoiceModalInsertPlanChoiceMutationVariables>(AddPlanChoiceModalInsertPlanChoiceDocument, options);
      }
export type AddPlanChoiceModalInsertPlanChoiceMutationHookResult = ReturnType<typeof useAddPlanChoiceModalInsertPlanChoiceMutation>;
export type AddPlanChoiceModalInsertPlanChoiceMutationResult = Apollo.MutationResult<AddPlanChoiceModalInsertPlanChoiceMutation>;
export type AddPlanChoiceModalInsertPlanChoiceMutationOptions = Apollo.BaseMutationOptions<AddPlanChoiceModalInsertPlanChoiceMutation, AddPlanChoiceModalInsertPlanChoiceMutationVariables>;