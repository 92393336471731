import React, { useMemo } from "react";
import styled from "styled-components";
import { Alert, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { dayjsToDateString } from "libs/DateString";

import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import { PerCouponTable } from "./PerCouponTable";
import { PerShopTable } from "./PerShopTable";
import {
  useCouponAnalyticsGetCompaniesQuery,
  useGetCouponAnalyticsPerCouponQuery,
  useGetCouponAnalyticsSummaryQuery,
} from "./queries";
import { SummaryPanel } from "./SummaryPanel";

const CouponSectionTitle = styled.div`
  display: flex;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
`;

export const CouponAnalytics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const [company] = useCompany();
  const companyIdDefaultValue = company?.id;

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >({ companyId: companyIdDefaultValue, range: initialRange }, undefined, {
    serialize: ({ range }) => ({ range: serializeRange(range) }),
    deserialize: ({ range }) => ({ range: deserializeRange(range) }),
  });

  const companyId = filterConditions.companyId ?? companyIdDefaultValue;
  const range = useMemo(() => {
    const startAt = filterConditions.range?.[0];
    const endAt = filterConditions.range?.[1];
    if (!startAt || !endAt) return null;

    return { startAt: dayjsToDateString(startAt), endAt: dayjsToDateString(endAt) };
  }, [filterConditions.range]);

  const {
    data: getCompaniesData,
    error: getCompaniesError,
    loading: loadingCompaniesData,
  } = useCouponAnalyticsGetCompaniesQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const companies = getCompaniesData?.corporation[0]?.companies ?? [];

  const {
    data: getSummary,
    error: getSummaryError,
    loading: summaryLoading,
  } = useGetCouponAnalyticsSummaryQuery(
    companyId && range
      ? {
          variables: {
            input: { companyId, startAt: range.startAt, endAt: range.endAt },
          },
        }
      : { skip: true },
  );

  const couponAnalyticsSummary = getSummary?.couponAnalyticsSummary;

  const {
    data: getPerCoupon,
    error: getPerCouponError,
    loading: perCouponLoading,
  } = useGetCouponAnalyticsPerCouponQuery(
    companyId && range
      ? {
          variables: {
            input: { companyId, startAt: range.startAt, endAt: range.endAt },
          },
        }
      : { skip: true },
  );
  const couponAnalyticsSummaryPerCoupon = getPerCoupon?.couponAnalyticsPerCoupon.items ?? [];

  const isError = getSummaryError || getPerCouponError || getCompaniesError;

  const shopUsages = getSummary?.couponAnalyticsSummary?.shopUsages ?? [];

  return (
    <DashboardLayout title="クーポン利用分析">
      <PageHeader
        title="クーポン利用分析"
        footer={
          <Filters
            companies={companies}
            companyIdDefaultValue={companyIdDefaultValue}
            filterConditions={filterConditions}
            onChangeFilterCondition={updateFilterCondition}
            loading={loadingCompaniesData}
          />
        }
      />

      {isError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <SummaryPanel summary={couponAnalyticsSummary} />

      <Spacer size={32} />

      <CouponSectionTitle>
        <Title>店舗別クーポン結果</Title>
      </CouponSectionTitle>

      <Spacer size={5} />

      <PerShopTable isLoading={summaryLoading} summaryPerShop={shopUsages} />

      <Spacer size={5} />

      <CouponSectionTitle>
        <Title>クーポン別結果</Title>
        <Spacer size={5} inline />
        <Tooltip
          title={
            <span>
              業態全体での数値は、クーポン別の数値の合計とはならない場合があります。
              <br />
              <br />
              例：1回の来店で、複数のクーポンを利用された場合、それぞれのクーポン別結果に計上されますが、業態全体の数値は1回の来店分の数値として計上します
            </span>
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </CouponSectionTitle>

      <Spacer size={5} />

      <PerCouponTable
        isLoading={perCouponLoading}
        summaryPerCoupon={couponAnalyticsSummaryPerCoupon}
      />
    </DashboardLayout>
  );
};
