import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { CreateShopInput, Shop } from "types/graphql";

export type ShopFormValues = Pick<Shop, "name" | "nameEn">;

const getInitialValues = () => ({});

export const ShopFormItem = createFormItem<ShopFormValues>();

export const useShopForm = ({
  onSubmit,
  companyId,
}: {
  onSubmit: (shop: CreateShopInput) => void;
  companyId: string;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as ShopFormValues;
    onSubmit({
      companyId,
      ...values,
    });
  }, [companyId, form, onSubmit]);

  return { form, initialValues, submit };
};
