import React, { memo } from "react";
import styled from "styled-components";
import { Typography } from "antd";

import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";

import { ChoiceTranslationFields } from "./ChoiceTranslationFields";

const Title = Typography.Title;

const StyledCollapsableFormSection = styled(CollapsableFormSection)`
  padding: 24px 32px;
`;

export const OptionTranslationOptions = memo(() => (
  <StyledCollapsableFormSection title="多言語設定">
    <TranslationNameFields label="オプション名" originalFieldName="name" />

    <Title level={4}>各選択肢名</Title>

    <ChoiceTranslationFields />
  </StyledCollapsableFormSection>
));
