import React, { memo } from "react";
import { Select } from "antd";
import { FormItemProps } from "antd/lib/form";
import { taxMethods } from "models/taxMethod";

import { EditMenuFormItem } from "../useEditMenuForm";

const { Option } = Select;

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const TaxMethodField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem label="税種別" name="taxMethod" required {...props}>
    <Select<string> disabled={disabled}>
      {Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => (
        <Option key={taxMethod} value={taxMethod}>
          {taxMethodName}
        </Option>
      ))}
    </Select>
  </EditMenuFormItem>
));
