import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPikaichiOnSitePaymentDetailTypeFormItem } from "../useEditPikaichiOnSitePaymentDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiMediaNameField = memo<Props>((props) => (
  <EditPikaichiOnSitePaymentDetailTypeFormItem
    label="ぴかいちメディア名称"
    name="paymentMediaName"
    rules={[{ required: true, message: "メディア名称を入力してください" }]}
    {...props}
  >
    <Input />
  </EditPikaichiOnSitePaymentDetailTypeFormItem>
));
