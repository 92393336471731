import React, { memo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";
import { DishUpGroup } from "pages/DishUpSlipGroups/types";

type Props = {
  loading?: boolean;
  dishUpGroups: DishUpGroup[];
};

export const DishUpGroupDishUpGroup = memo<Props>(({ loading, dishUpGroups }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns = [
    { title: "デシャップグループ名", dataIndex: "name" },
    ...(canAccess("editDishUpSlipGroup")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id }: DishUpGroup) {
              return (
                <IconLink to={`/dishUpSlipGroup/${id}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="dishUpSlipGroupId"
      columns={columns}
      dataSource={dishUpGroups}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
