import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { DayOfWeekCheckboxOptions } from "models/dayOfWeek";

import { EditMessageDeliveryFormItem } from "../useEditMessageDeliveryForm";

const AllChecker = styled.div`
  white-space: nowrap;
`;

export const VisitedDayOfWeekFilter = memo(() => (
  <>
    <AllChecker>
      <EditMessageDeliveryFormItem
        noStyle
        name="visitedDayOfWeekAllChecker"
        valuePropName="checked"
        rules={[
          ({ getFieldValue, setFieldsValue }) => ({
            transform(value: boolean) {
              if (value) {
                setFieldsValue({
                  visitedDayOfWeekFilter: [],
                });
              } else if (!value && getFieldValue("visitedDayOfWeekFilter").length === 0) {
                setFieldsValue({
                  visitedDayOfWeekAllChecker: true,
                  visitedDayOfWeekFilter: [],
                });
              }
            },
          }),
        ]}
      >
        <Checkbox>すべて</Checkbox>
      </EditMessageDeliveryFormItem>
    </AllChecker>
    <EditMessageDeliveryFormItem
      noStyle
      name="visitedDayOfWeekFilter"
      rules={[
        ({ getFieldValue, setFieldsValue }) => ({
          transform(values: string[]) {
            if (getFieldValue("visitedDayOfWeekAllChecker") && values.length > 0) {
              setFieldsValue({
                visitedDayOfWeekAllChecker: false,
              });
            } else if (values.length === 8 || values.length === 0) {
              setFieldsValue({
                visitedDayOfWeekAllChecker: true,
                visitedDayOfWeekFilter: [],
              });
            }
          },
        }),
      ]}
    >
      <Checkbox.Group options={DayOfWeekCheckboxOptions} />
    </EditMessageDeliveryFormItem>
  </>
));
