import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenTerminalPaymentSignInSignIn = gql`
    mutation AdyenTerminalPaymentSignInSignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) @client
}
    `;
export type AdyenTerminalPaymentSignInSignInMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type AdyenTerminalPaymentSignInSignInMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'signIn'>
);


export const AdyenTerminalPaymentSignInSignInDocument = gql`
    mutation AdyenTerminalPaymentSignInSignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password) @client
}
    `;
export type AdyenTerminalPaymentSignInSignInMutationFn = Apollo.MutationFunction<AdyenTerminalPaymentSignInSignInMutation, AdyenTerminalPaymentSignInSignInMutationVariables>;

/**
 * __useAdyenTerminalPaymentSignInSignInMutation__
 *
 * To run a mutation, you first call `useAdyenTerminalPaymentSignInSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdyenTerminalPaymentSignInSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adyenTerminalPaymentSignInSignInMutation, { data, loading, error }] = useAdyenTerminalPaymentSignInSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdyenTerminalPaymentSignInSignInMutation(baseOptions?: Apollo.MutationHookOptions<AdyenTerminalPaymentSignInSignInMutation, AdyenTerminalPaymentSignInSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdyenTerminalPaymentSignInSignInMutation, AdyenTerminalPaymentSignInSignInMutationVariables>(AdyenTerminalPaymentSignInSignInDocument, options);
      }
export type AdyenTerminalPaymentSignInSignInMutationHookResult = ReturnType<typeof useAdyenTerminalPaymentSignInSignInMutation>;
export type AdyenTerminalPaymentSignInSignInMutationResult = Apollo.MutationResult<AdyenTerminalPaymentSignInSignInMutation>;
export type AdyenTerminalPaymentSignInSignInMutationOptions = Apollo.BaseMutationOptions<AdyenTerminalPaymentSignInSignInMutation, AdyenTerminalPaymentSignInSignInMutationVariables>;