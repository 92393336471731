import React, { memo } from "react";
import styled from "styled-components";
import { Popconfirm, Typography } from "antd";
import { isNumber } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { Shop } from "pages/Shops/types";

const NoWrapSpan = styled.span`
  white-space: nowrap;
`;

const CellContent = styled.div`
  min-width: 70px;
`;

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  shops: Shop[];
  onArchive: ({ shopId }: { shopId: string }) => void;
};

export const ShopTable = memo<Props>(({ loading, shops, onArchive }) => {
  const { canAccess } = useCanAccess();
  const [pagination, setPagination] = usePagination();

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns = [
    {
      title: "店舗名",
      dataIndex: "name",
    },
    {
      title: "開店",
      dataIndex: "open",
    },
    {
      title: "閉店",
      dataIndex: "close",
    },
    {
      title: (
        <>
          <NoWrapSpan>1人あたり</NoWrapSpan>
          <br />
          <NoWrapSpan>注文上限数</NoWrapSpan>
        </>
      ),
      align: "right",
      render(_: string, { maxNumForNumPeople }: Shop) {
        return (
          <CellContent>
            {isNumber(maxNumForNumPeople) ? `${maxNumForNumPeople}個` : "なし"}
          </CellContent>
        );
      },
    } as const,
    ...(canAccess("editShop")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { shopId }: Shop) {
              return (
                <IconLink to={`/shop/${shopId}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("archiveShop")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            fixed: "right",
            render(_: string, { shopId }: Shop) {
              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>店舗をアーカイブしますか？</Paragraph>
                      <Paragraph>一度アーカイブした店舗を元に戻すことはできません。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => onArchive({ shopId })}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="shopId"
      columns={columns}
      dataSource={shops}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
