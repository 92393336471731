import React, { memo } from "react";
import styled from "styled-components";
import { Radio } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { colors, grey } from "constants/colors";
import { AccountingActionTypeEnum } from "types/graphql";

import callClerkTypeAccountingImg from "../../../../assets/call_clerk_type_accounting.png";
import PayAtCashRegisterTypeAccountingImg from "../../../../assets/pay_at_cash_register_type_accounting.png";
import waitAtTableTypeAccountingImg from "../../../../assets/wait_at_table_type_accounting.png";
import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

const AccountingActionTypeToTableTitleMap: Record<AccountingActionTypeEnum, string> = {
  [AccountingActionTypeEnum.WaitAtTable]: "テーブルでスタッフをお待ちいただく案内",
  [AccountingActionTypeEnum.CallClerk]: "スタッフにお声がけいただく案内",
  [AccountingActionTypeEnum.PayAtCashRegister]: "レジにお越しいただく案内",
};

const StyledTable = styled.table`
  thead {
    background-color: ${colors.BackGround.Tertiary};
    th {
      border: 1px solid ${grey[3]};
      padding: 16px;
      text-align: left;
      color: ${colors.Text.Default};
      font-weight: 500;
      font-size: 14px;
    }
  }
  tbody {
    td {
      border: 1px solid ${grey[3]};
      padding: 14px;
      text-align: center;
      &.checked {
        background-color: ${colors.BackGround.PrimarySecondary};
      }
    }
  }
`;

const StyledRadio = styled(Radio)`
  padding: 16px;
`;

const Image = styled.img`
  width: 148px;
  height: 148px;
  object-fit: cover;
`;

export const AccountingActionTypeField = memo<Props>((props) => (
  <EditShopFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ accountingActionType, enableOfflinePayment }) => [
      accountingActionType,
      enableOfflinePayment,
    ])}
    noStyle
  >
    {({ getFieldValue }) => {
      const accountingActionType = getFieldValue("accountingActionType");
      const enableOfflinePayment = getFieldValue("enableOfflinePayment");
      const isSelected = (actionType: AccountingActionTypeEnum) =>
        accountingActionType === actionType;

      return (
        <EditShopFormItem
          label={
            <FormHelp
              label="会計ボタン押下後の画面"
              help="お客様の注文画面にて、会計ボタンを押した際に表示される画面を設定いただけます"
            />
          }
          rules={[{ required: true, message: "会計ボタン押下後の画面を選択してください" }]}
          name="accountingActionType"
          {...props}
        >
          <Radio.Group>
            <StyledTable>
              <thead>
                <tr>
                  <th>
                    {AccountingActionTypeToTableTitleMap[AccountingActionTypeEnum.WaitAtTable]}
                  </th>
                  <th>{AccountingActionTypeToTableTitleMap[AccountingActionTypeEnum.CallClerk]}</th>
                  <th>
                    {
                      AccountingActionTypeToTableTitleMap[
                        AccountingActionTypeEnum.PayAtCashRegister
                      ]
                    }
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.WaitAtTable) ? "checked" : undefined
                    }
                  >
                    <Image src={waitAtTableTypeAccountingImg} />
                  </td>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.CallClerk) ? "checked" : undefined
                    }
                  >
                    <Image src={callClerkTypeAccountingImg} />
                  </td>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.PayAtCashRegister) ? "checked" : undefined
                    }
                  >
                    <Image src={PayAtCashRegisterTypeAccountingImg} />
                  </td>
                </tr>
                <tr>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.WaitAtTable) ? "checked" : undefined
                    }
                  >
                    <StyledRadio
                      value={AccountingActionTypeEnum.WaitAtTable}
                      disabled={!enableOfflinePayment}
                    />
                  </td>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.CallClerk) ? "checked" : undefined
                    }
                  >
                    <StyledRadio value={AccountingActionTypeEnum.CallClerk} />
                  </td>
                  <td
                    className={
                      isSelected(AccountingActionTypeEnum.PayAtCashRegister) ? "checked" : undefined
                    }
                  >
                    <StyledRadio value={AccountingActionTypeEnum.PayAtCashRegister} />
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </Radio.Group>
        </EditShopFormItem>
      );
    }}
  </EditShopFormItem.NonProperty>
));
