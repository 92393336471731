import { colors } from "./colors";

export const theme = {
  textColor: colors.Text.Primary,
  textColorSecondary: colors.Text.Secondary,
  customerDashboard: {
    gray800: "#404040",
  },
};

export type Theme = typeof theme;
