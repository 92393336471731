import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { Menu } from "types/graphql";

export const filterMenusByName = <T extends Pick<Menu, "name">>(menus: T[], name: string) => {
  const fuse = new Fuse(
    menus.map(({ name }) => ({ name: hiraganaToKatakana(name) })),
    { keys: ["name"] },
  );
  const indexes = fuse.search(hiraganaToKatakana(name)).map(({ refIndex }) => refIndex);

  return indexes.map((index) => menus[index]).filter(isNotNullable);
};
