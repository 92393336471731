import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LastOrderTextField = memo<Props>((props) => (
  <EditShopFormItem
    name="lastOrderText"
    {...props}
    label={
      <FormHelp label="ラストオーダーラベル" help="ラストオーダーラベルはご利用いただけません" />
    }
  >
    {/* ラストオーダーラベルはすでに MO 上に表示されなくなっているが、サイレントに消してしまうと問合せにつながるので disabled にしておく */}
    <Input disabled />
  </EditShopFormItem>
));
