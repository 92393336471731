import { CsvName, CsvNameByShops } from "models/csvName";

export const csvNameMap: Record<CsvName, string> = {
  orders: "注文一覧",
  canceledOrders: "キャンセル注文一覧",
  payments: "支払一覧",
  onlinePaymentTransaction: "オンライン決済取引一覧",
  timeSummary: "日計(日別・会計時刻別)",
  visitedTimeSummary: "日計(日別・来店時刻別)",
  discounts: "値引・割引一覧",
  withdrawAndDeposit: "入出金一覧",
  orderSummary: "出数集計",
  customerSegment: "来店属性一覧",
  customerInflowSourceTag: "媒体集計",
  kdServedTime: "キッチンディスプレイ提供時間",
  kioskServedTime: "KIOSK提供時間",
  receipt: "領収証発行集計",
  audit: "監査",
  transactions: "取引一覧",
};

export const csvDescriptionMap: Record<CsvName, string> = {
  timeSummary: "日計集計(日別・会計時刻別)を出力します",
  visitedTimeSummary: "日計集計(日別・来店時刻別)を出力します",
  orders: "注文商品を時系列順に出力します",
  canceledOrders: "キャンセル注文商品を時系列順に出力します",
  orderSummary: "出数集計を出力します",
  payments: "支払い内訳を出力します",
  onlinePaymentTransaction: "オンライン決済の取引一覧を出力します",
  discounts: "値引・割引内訳を出力します",
  withdrawAndDeposit: "入出金内訳を出力します",
  customerSegment: "顧客属性一覧を出力します",
  customerInflowSourceTag: "来店客媒体集計を出力します",
  kdServedTime: "提供時間を出力します(キッチンディスプレイ利用店舗のみ出力されます)",
  kioskServedTime: "提供時間を出力します(KIOSK利用店舗のみ出力されます)",
  receipt: "領収証発行集計を出力します",
  audit: "テーブルクリア・会計取り消し・会計修正履歴を出力します",
  transactions: "会計履歴の一覧を出力します",
};

export const csvFileNamesMap: Record<CsvName, string> = {
  timeSummary: "summary.csv / timeSummary.csv",
  visitedTimeSummary: "summary.csv / visitedTimeSummary.csv",
  orders: "orders.csv",
  canceledOrders: "canceledOrders.csv",
  orderSummary: "orderSummary.csv",
  payments: "payments.csv",
  onlinePaymentTransaction: "onlinePayment.csv",
  discounts: "discounts.csv",
  withdrawAndDeposit: "withdrawAndDeposit.csv",
  customerSegment: "segment.csv",
  customerInflowSourceTag: "customerSource.csv",
  kdServedTime: "kdServedTime.csv",
  kioskServedTime: "kioskServedTime.csv",
  receipt: "receipt.csv",
  audit: "audit.csv",
  transactions: "transaction.csv",
};

export const csvNameByShopsMap: Record<CsvNameByShops, string> = {
  summaryByShops: "日計(日別・店舗統一)",
};

export const csvByShopsDescriptionMap: Record<CsvNameByShops, string> = {
  summaryByShops: "日計集計(日別・店舗統一)を出力します",
};

export const csvByShopsFileNamesMap: Record<CsvNameByShops, string> = {
  summaryByShops: "summaryByShops.csv",
};
