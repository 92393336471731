import { useCallback } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { ExternalOnlineMenuConfig, ExternalOnlineMenuConfigInsertInput } from "types/graphql";

type AddShopExternalOnlineMenuConfigFormValues = Pick<ExternalOnlineMenuConfig, "defaultMenuUrl">;

export const AddShopExternalOnlineMenuConfigFormItem =
  createFormItem<AddShopExternalOnlineMenuConfigFormValues>();

export const useAddShopExternalOnlineMenuConfigForm = (
  shopId: string,
  onSubmit: (orderableTime: ExternalOnlineMenuConfigInsertInput) => void,
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void,
) => {
  const [form] = Form.useForm();
  const initialValues: AddShopExternalOnlineMenuConfigFormValues = { defaultMenuUrl: "" };

  const submit = useCallback(async () => {
    try {
      await form.validateFields();

      const values = form.getFieldsValue() as AddShopExternalOnlineMenuConfigFormValues;

      onSubmit({
        shopId,
        defaultMenuUrl: values.defaultMenuUrl,
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError, shopId]);

  return { form, initialValues, submit };
};
