import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CustomerFields = gql`
    fragment customerFields on customer {
  id
  lineProfile {
    name
    profileImage
  }
}
    `;
export const EditMessageDeliveryGetMessageDelivery = gql`
    query EditMessageDeliveryGetMessageDelivery($id: uuid!) {
  messageDelivery_by_pk(id: $id) {
    id
    name
    companyId
    isSuspended
    scope
    customerSegmentId
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
    customerListId
    customerList {
      isFromCsv
      customerListCustomers {
        customer {
          id
          lineProfile {
            name
            profileImage
          }
        }
      }
    }
    isDraft
    deliverAt
    repeatDeliveryTime
    messageDeliveryMessages(order_by: {position: asc}) {
      id
      type
      position
      textTypeMeta {
        id
        text
      }
      imageTypeMeta {
        id
        imageUrl
        url
      }
      couponTypeMeta {
        id
        couponId
      }
      questionnaireTypeMeta {
        id
        questionnaireConfigId
      }
    }
    messageDeliveryJobs(limit: 1) {
      id
    }
  }
}
    `;
export const EditMessageDeliveryGetCoupons = gql`
    query EditMessageDeliveryGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditMessageDeliveryGetQuestionnaireConfigs = gql`
    query EditMessageDeliveryGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;
export const EditMessageDeliveryGetCustomersByLikeName = gql`
    query EditMessageDeliveryGetCustomersByLikeName($companyId: uuid!, $likeName: String!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, lineProfile: {name: {_like: $likeName}}}
  ) {
    ...customerFields
  }
}
    ${CustomerFields}`;
export const EditMessageDeliveryGetCustomersByIds = gql`
    query EditMessageDeliveryGetCustomersByIds($companyId: uuid!, $customerIds: [String!]!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, id: {_in: $customerIds}, lineId: {_is_null: false}}
  ) {
    ...customerFields
  }
}
    ${CustomerFields}`;
export const EditMessageDeliveryGetMenus = gql`
    query EditMessageDeliveryGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;
export const EditMessageDeliveryInsertMessageDelivery = gql`
    mutation EditMessageDeliveryInsertMessageDelivery($messageDelivery: UpdateMessageDeliveryInput!) {
  dashboardAccountUpdateMessageDelivery(input: $messageDelivery)
}
    `;
export const EditMessageDeliveryUpdateIsSuspendedMessageDelivery = gql`
    mutation EditMessageDeliveryUpdateIsSuspendedMessageDelivery($id: uuid!, $isSuspended: Boolean!) {
  update_messageDelivery(
    where: {id: {_eq: $id}}
    _set: {isSuspended: $isSuspended}
  ) {
    affected_rows
  }
}
    `;
export const EditMessageDeliveryGetShopBusinessOperationHourTypes = gql`
    query EditMessageDeliveryGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;
export const EditMessageDeliveryGetShops = gql`
    query EditMessageDeliveryGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export type EditMessageDeliveryGetMessageDeliveryQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetMessageDeliveryQuery = (
  { __typename?: 'query_root' }
  & { messageDelivery_by_pk?: Types.Maybe<(
    { __typename?: 'messageDelivery' }
    & Pick<Types.MessageDelivery, 'id' | 'name' | 'companyId' | 'isSuspended' | 'scope' | 'customerSegmentId' | 'customerListId' | 'isDraft' | 'deliverAt' | 'repeatDeliveryTime'>
    & { customerSegment?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerSegment' }
      & { messageDeliveryCustomerSegmentVisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentVisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentUnvisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentBusinessOperationHoursCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentBusinessOperationHoursCondition, 'businessOperationHourType'>
      )>, messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition, 'messageDeliveryQuestionnaireAnswerStatus'>
      )>, messageDeliveryCustomerSegmentDayOfWeekConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentDayOfWeekCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentDayOfWeekCondition, 'messageDeliveryDayOfWeek'>
      )>, messageDeliveryCustomerSegmentMenuConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentMenuCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentMenuCondition, 'menuId' | 'moreThanOrEqualQuantity'>
      )>, messageDeliveryCustomerSegmentShopConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentShopCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentShopCondition, 'shopId'>
      )>, messageDeliveryCustomerSegmentGenderCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentGenderCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentGenderCondition, 'messageDeliveryGender'>
      )>, messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )> }
    )>, customerList?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerList' }
      & Pick<Types.MessageDeliveryCustomerList, 'isFromCsv'>
      & { customerListCustomers: Array<(
        { __typename?: 'messageDeliveryCustomerListCustomer' }
        & { customer: (
          { __typename?: 'customer' }
          & Pick<Types.Customer, 'id'>
          & { lineProfile?: Types.Maybe<(
            { __typename?: 'lineProfile' }
            & Pick<Types.LineProfile, 'name' | 'profileImage'>
          )> }
        ) }
      )> }
    )>, messageDeliveryMessages: Array<(
      { __typename?: 'messageDeliveryMessage' }
      & Pick<Types.MessageDeliveryMessage, 'id' | 'type' | 'position'>
      & { textTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageTextTypeMeta' }
        & Pick<Types.MessageDeliveryMessageTextTypeMeta, 'id' | 'text'>
      )>, imageTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageImageTypeMeta' }
        & Pick<Types.MessageDeliveryMessageImageTypeMeta, 'id' | 'imageUrl' | 'url'>
      )>, couponTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageCouponTypeMeta' }
        & Pick<Types.MessageDeliveryMessageCouponTypeMeta, 'id' | 'couponId'>
      )>, questionnaireTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageQuestionnaireTypeMeta' }
        & Pick<Types.MessageDeliveryMessageQuestionnaireTypeMeta, 'id' | 'questionnaireConfigId'>
      )> }
    )>, messageDeliveryJobs: Array<(
      { __typename?: 'messageDeliveryJob' }
      & Pick<Types.MessageDeliveryJob, 'id'>
    )> }
  )> }
);

export type EditMessageDeliveryGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type EditMessageDeliveryGetQuestionnaireConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetQuestionnaireConfigsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig: Array<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id' | 'imageUrl'>
    & { questionnaire: (
      { __typename?: 'questionnaire' }
      & Pick<Types.Questionnaire, 'id' | 'name'>
    ), coupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'title'>
    )> }
  )> }
);

export type CustomerFieldsFragment = (
  { __typename?: 'customer' }
  & Pick<Types.Customer, 'id'>
  & { lineProfile?: Types.Maybe<(
    { __typename?: 'lineProfile' }
    & Pick<Types.LineProfile, 'name' | 'profileImage'>
  )> }
);

export type EditMessageDeliveryGetCustomersByLikeNameQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  likeName: Types.Scalars['String'];
}>;


export type EditMessageDeliveryGetCustomersByLikeNameQuery = (
  { __typename?: 'query_root' }
  & { customer: Array<(
    { __typename?: 'customer' }
    & CustomerFieldsFragment
  )> }
);

export type EditMessageDeliveryGetCustomersByIdsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  customerIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type EditMessageDeliveryGetCustomersByIdsQuery = (
  { __typename?: 'query_root' }
  & { customer: Array<(
    { __typename?: 'customer' }
    & CustomerFieldsFragment
  )> }
);

export type EditMessageDeliveryGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'name'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'name'>
      ) }
    )> }
  )> }
);

export type EditMessageDeliveryInsertMessageDeliveryMutationVariables = Types.Exact<{
  messageDelivery: Types.UpdateMessageDeliveryInput;
}>;


export type EditMessageDeliveryInsertMessageDeliveryMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountUpdateMessageDelivery'>
);

export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  isSuspended: Types.Scalars['Boolean'];
}>;


export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation = (
  { __typename?: 'mutation_root' }
  & { update_messageDelivery?: Types.Maybe<(
    { __typename?: 'messageDelivery_mutation_response' }
    & Pick<Types.MessageDeliveryMutationResponse, 'affected_rows'>
  )> }
);

export type EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetShopBusinessOperationHourTypesQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'id' | 'businessOperationHourType'>
  )> }
);

export type EditMessageDeliveryGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMessageDeliveryGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export const CustomerFieldsFragmentDoc = gql`
    fragment customerFields on customer {
  id
  lineProfile {
    name
    profileImage
  }
}
    `;
export const EditMessageDeliveryGetMessageDeliveryDocument = gql`
    query EditMessageDeliveryGetMessageDelivery($id: uuid!) {
  messageDelivery_by_pk(id: $id) {
    id
    name
    companyId
    isSuspended
    scope
    customerSegmentId
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
    customerListId
    customerList {
      isFromCsv
      customerListCustomers {
        customer {
          id
          lineProfile {
            name
            profileImage
          }
        }
      }
    }
    isDraft
    deliverAt
    repeatDeliveryTime
    messageDeliveryMessages(order_by: {position: asc}) {
      id
      type
      position
      textTypeMeta {
        id
        text
      }
      imageTypeMeta {
        id
        imageUrl
        url
      }
      couponTypeMeta {
        id
        couponId
      }
      questionnaireTypeMeta {
        id
        questionnaireConfigId
      }
    }
    messageDeliveryJobs(limit: 1) {
      id
    }
  }
}
    `;

/**
 * __useEditMessageDeliveryGetMessageDeliveryQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetMessageDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetMessageDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetMessageDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditMessageDeliveryGetMessageDeliveryQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetMessageDeliveryQuery, EditMessageDeliveryGetMessageDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetMessageDeliveryQuery, EditMessageDeliveryGetMessageDeliveryQueryVariables>(EditMessageDeliveryGetMessageDeliveryDocument, options);
      }
export function useEditMessageDeliveryGetMessageDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetMessageDeliveryQuery, EditMessageDeliveryGetMessageDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetMessageDeliveryQuery, EditMessageDeliveryGetMessageDeliveryQueryVariables>(EditMessageDeliveryGetMessageDeliveryDocument, options);
        }
export type EditMessageDeliveryGetMessageDeliveryQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetMessageDeliveryQuery>;
export type EditMessageDeliveryGetMessageDeliveryLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetMessageDeliveryLazyQuery>;
export type EditMessageDeliveryGetMessageDeliveryQueryResult = Apollo.QueryResult<EditMessageDeliveryGetMessageDeliveryQuery, EditMessageDeliveryGetMessageDeliveryQueryVariables>;
export const EditMessageDeliveryGetCouponsDocument = gql`
    query EditMessageDeliveryGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditMessageDeliveryGetCouponsQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMessageDeliveryGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetCouponsQuery, EditMessageDeliveryGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetCouponsQuery, EditMessageDeliveryGetCouponsQueryVariables>(EditMessageDeliveryGetCouponsDocument, options);
      }
export function useEditMessageDeliveryGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetCouponsQuery, EditMessageDeliveryGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetCouponsQuery, EditMessageDeliveryGetCouponsQueryVariables>(EditMessageDeliveryGetCouponsDocument, options);
        }
export type EditMessageDeliveryGetCouponsQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCouponsQuery>;
export type EditMessageDeliveryGetCouponsLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCouponsLazyQuery>;
export type EditMessageDeliveryGetCouponsQueryResult = Apollo.QueryResult<EditMessageDeliveryGetCouponsQuery, EditMessageDeliveryGetCouponsQueryVariables>;
export const EditMessageDeliveryGetQuestionnaireConfigsDocument = gql`
    query EditMessageDeliveryGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;

/**
 * __useEditMessageDeliveryGetQuestionnaireConfigsQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetQuestionnaireConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetQuestionnaireConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetQuestionnaireConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMessageDeliveryGetQuestionnaireConfigsQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetQuestionnaireConfigsQuery, EditMessageDeliveryGetQuestionnaireConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetQuestionnaireConfigsQuery, EditMessageDeliveryGetQuestionnaireConfigsQueryVariables>(EditMessageDeliveryGetQuestionnaireConfigsDocument, options);
      }
export function useEditMessageDeliveryGetQuestionnaireConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetQuestionnaireConfigsQuery, EditMessageDeliveryGetQuestionnaireConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetQuestionnaireConfigsQuery, EditMessageDeliveryGetQuestionnaireConfigsQueryVariables>(EditMessageDeliveryGetQuestionnaireConfigsDocument, options);
        }
export type EditMessageDeliveryGetQuestionnaireConfigsQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetQuestionnaireConfigsQuery>;
export type EditMessageDeliveryGetQuestionnaireConfigsLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetQuestionnaireConfigsLazyQuery>;
export type EditMessageDeliveryGetQuestionnaireConfigsQueryResult = Apollo.QueryResult<EditMessageDeliveryGetQuestionnaireConfigsQuery, EditMessageDeliveryGetQuestionnaireConfigsQueryVariables>;
export const EditMessageDeliveryGetCustomersByLikeNameDocument = gql`
    query EditMessageDeliveryGetCustomersByLikeName($companyId: uuid!, $likeName: String!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, lineProfile: {name: {_like: $likeName}}}
  ) {
    ...customerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useEditMessageDeliveryGetCustomersByLikeNameQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetCustomersByLikeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetCustomersByLikeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetCustomersByLikeNameQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      likeName: // value for 'likeName'
 *   },
 * });
 */
export function useEditMessageDeliveryGetCustomersByLikeNameQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetCustomersByLikeNameQuery, EditMessageDeliveryGetCustomersByLikeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetCustomersByLikeNameQuery, EditMessageDeliveryGetCustomersByLikeNameQueryVariables>(EditMessageDeliveryGetCustomersByLikeNameDocument, options);
      }
export function useEditMessageDeliveryGetCustomersByLikeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetCustomersByLikeNameQuery, EditMessageDeliveryGetCustomersByLikeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetCustomersByLikeNameQuery, EditMessageDeliveryGetCustomersByLikeNameQueryVariables>(EditMessageDeliveryGetCustomersByLikeNameDocument, options);
        }
export type EditMessageDeliveryGetCustomersByLikeNameQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCustomersByLikeNameQuery>;
export type EditMessageDeliveryGetCustomersByLikeNameLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCustomersByLikeNameLazyQuery>;
export type EditMessageDeliveryGetCustomersByLikeNameQueryResult = Apollo.QueryResult<EditMessageDeliveryGetCustomersByLikeNameQuery, EditMessageDeliveryGetCustomersByLikeNameQueryVariables>;
export const EditMessageDeliveryGetCustomersByIdsDocument = gql`
    query EditMessageDeliveryGetCustomersByIds($companyId: uuid!, $customerIds: [String!]!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, id: {_in: $customerIds}, lineId: {_is_null: false}}
  ) {
    ...customerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useEditMessageDeliveryGetCustomersByIdsQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetCustomersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetCustomersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetCustomersByIdsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useEditMessageDeliveryGetCustomersByIdsQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetCustomersByIdsQuery, EditMessageDeliveryGetCustomersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetCustomersByIdsQuery, EditMessageDeliveryGetCustomersByIdsQueryVariables>(EditMessageDeliveryGetCustomersByIdsDocument, options);
      }
export function useEditMessageDeliveryGetCustomersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetCustomersByIdsQuery, EditMessageDeliveryGetCustomersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetCustomersByIdsQuery, EditMessageDeliveryGetCustomersByIdsQueryVariables>(EditMessageDeliveryGetCustomersByIdsDocument, options);
        }
export type EditMessageDeliveryGetCustomersByIdsQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCustomersByIdsQuery>;
export type EditMessageDeliveryGetCustomersByIdsLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetCustomersByIdsLazyQuery>;
export type EditMessageDeliveryGetCustomersByIdsQueryResult = Apollo.QueryResult<EditMessageDeliveryGetCustomersByIdsQuery, EditMessageDeliveryGetCustomersByIdsQueryVariables>;
export const EditMessageDeliveryGetMenusDocument = gql`
    query EditMessageDeliveryGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;

/**
 * __useEditMessageDeliveryGetMenusQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMessageDeliveryGetMenusQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetMenusQuery, EditMessageDeliveryGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetMenusQuery, EditMessageDeliveryGetMenusQueryVariables>(EditMessageDeliveryGetMenusDocument, options);
      }
export function useEditMessageDeliveryGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetMenusQuery, EditMessageDeliveryGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetMenusQuery, EditMessageDeliveryGetMenusQueryVariables>(EditMessageDeliveryGetMenusDocument, options);
        }
export type EditMessageDeliveryGetMenusQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetMenusQuery>;
export type EditMessageDeliveryGetMenusLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetMenusLazyQuery>;
export type EditMessageDeliveryGetMenusQueryResult = Apollo.QueryResult<EditMessageDeliveryGetMenusQuery, EditMessageDeliveryGetMenusQueryVariables>;
export const EditMessageDeliveryInsertMessageDeliveryDocument = gql`
    mutation EditMessageDeliveryInsertMessageDelivery($messageDelivery: UpdateMessageDeliveryInput!) {
  dashboardAccountUpdateMessageDelivery(input: $messageDelivery)
}
    `;
export type EditMessageDeliveryInsertMessageDeliveryMutationFn = Apollo.MutationFunction<EditMessageDeliveryInsertMessageDeliveryMutation, EditMessageDeliveryInsertMessageDeliveryMutationVariables>;

/**
 * __useEditMessageDeliveryInsertMessageDeliveryMutation__
 *
 * To run a mutation, you first call `useEditMessageDeliveryInsertMessageDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryInsertMessageDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMessageDeliveryInsertMessageDeliveryMutation, { data, loading, error }] = useEditMessageDeliveryInsertMessageDeliveryMutation({
 *   variables: {
 *      messageDelivery: // value for 'messageDelivery'
 *   },
 * });
 */
export function useEditMessageDeliveryInsertMessageDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<EditMessageDeliveryInsertMessageDeliveryMutation, EditMessageDeliveryInsertMessageDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMessageDeliveryInsertMessageDeliveryMutation, EditMessageDeliveryInsertMessageDeliveryMutationVariables>(EditMessageDeliveryInsertMessageDeliveryDocument, options);
      }
export type EditMessageDeliveryInsertMessageDeliveryMutationHookResult = ReturnType<typeof useEditMessageDeliveryInsertMessageDeliveryMutation>;
export type EditMessageDeliveryInsertMessageDeliveryMutationResult = Apollo.MutationResult<EditMessageDeliveryInsertMessageDeliveryMutation>;
export type EditMessageDeliveryInsertMessageDeliveryMutationOptions = Apollo.BaseMutationOptions<EditMessageDeliveryInsertMessageDeliveryMutation, EditMessageDeliveryInsertMessageDeliveryMutationVariables>;
export const EditMessageDeliveryUpdateIsSuspendedMessageDeliveryDocument = gql`
    mutation EditMessageDeliveryUpdateIsSuspendedMessageDelivery($id: uuid!, $isSuspended: Boolean!) {
  update_messageDelivery(
    where: {id: {_eq: $id}}
    _set: {isSuspended: $isSuspended}
  ) {
    affected_rows
  }
}
    `;
export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationFn = Apollo.MutationFunction<EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation, EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationVariables>;

/**
 * __useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation__
 *
 * To run a mutation, you first call `useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation, { data, loading, error }] = useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isSuspended: // value for 'isSuspended'
 *   },
 * });
 */
export function useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation, EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation, EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationVariables>(EditMessageDeliveryUpdateIsSuspendedMessageDeliveryDocument, options);
      }
export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationHookResult = ReturnType<typeof useEditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation>;
export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationResult = Apollo.MutationResult<EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation>;
export type EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationOptions = Apollo.BaseMutationOptions<EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutation, EditMessageDeliveryUpdateIsSuspendedMessageDeliveryMutationVariables>;
export const EditMessageDeliveryGetShopBusinessOperationHourTypesDocument = gql`
    query EditMessageDeliveryGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;

/**
 * __useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetShopBusinessOperationHourTypesQuery, EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetShopBusinessOperationHourTypesQuery, EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>(EditMessageDeliveryGetShopBusinessOperationHourTypesDocument, options);
      }
export function useEditMessageDeliveryGetShopBusinessOperationHourTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetShopBusinessOperationHourTypesQuery, EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetShopBusinessOperationHourTypesQuery, EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>(EditMessageDeliveryGetShopBusinessOperationHourTypesDocument, options);
        }
export type EditMessageDeliveryGetShopBusinessOperationHourTypesQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetShopBusinessOperationHourTypesQuery>;
export type EditMessageDeliveryGetShopBusinessOperationHourTypesLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetShopBusinessOperationHourTypesLazyQuery>;
export type EditMessageDeliveryGetShopBusinessOperationHourTypesQueryResult = Apollo.QueryResult<EditMessageDeliveryGetShopBusinessOperationHourTypesQuery, EditMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>;
export const EditMessageDeliveryGetShopsDocument = gql`
    query EditMessageDeliveryGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useEditMessageDeliveryGetShopsQuery__
 *
 * To run a query within a React component, call `useEditMessageDeliveryGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDeliveryGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMessageDeliveryGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMessageDeliveryGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditMessageDeliveryGetShopsQuery, EditMessageDeliveryGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMessageDeliveryGetShopsQuery, EditMessageDeliveryGetShopsQueryVariables>(EditMessageDeliveryGetShopsDocument, options);
      }
export function useEditMessageDeliveryGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMessageDeliveryGetShopsQuery, EditMessageDeliveryGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMessageDeliveryGetShopsQuery, EditMessageDeliveryGetShopsQueryVariables>(EditMessageDeliveryGetShopsDocument, options);
        }
export type EditMessageDeliveryGetShopsQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetShopsQuery>;
export type EditMessageDeliveryGetShopsLazyQueryHookResult = ReturnType<typeof useEditMessageDeliveryGetShopsLazyQuery>;
export type EditMessageDeliveryGetShopsQueryResult = Apollo.QueryResult<EditMessageDeliveryGetShopsQuery, EditMessageDeliveryGetShopsQueryVariables>;