import React, { memo, useCallback, useState } from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import { getAllocatedDailySalesBudget } from "models/salesBudget";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNotUndefined } from "util/type/primitive";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import {
  DailySalesBudgetInsertInput,
  MonthlySalesBudgetSetInput,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import { AllocationSetting, AverageAndMaxSalesByDayOfWeek, Budget, SalesByMonths } from "../types";

import { AllocationSettingModal } from "./AllocationSettingModal";
import { DailyBudgetField } from "./DailyBudgetField";
import { MonthlyBudgetField } from "./MonthlyBudgetField";
import { TargetMonthField } from "./TargetMonthField";
import { EditSalesBudgetFormValues, useEditSalesBudgetForm } from "./useEditSalesBudgetForm";

type Props = {
  budget: Budget;
  shopId: string;
  loading: boolean;
  onSubmit: ({
    monthlySalesBudget,
    dailySalesBudgets,
  }: {
    monthlySalesBudget: MonthlySalesBudgetSetInput;
    dailySalesBudgets: DailySalesBudgetInsertInput[];
  }) => void;
  onSubmitAllocationSetting: (allocationSetting: SalesBudgetAllocationSettingInsertInput) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  allocationSetting?: AllocationSetting;
  averageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek[];
  salesByMonths?: SalesByMonths;
};

export const EditSalesBudgetForm = memo<Props>(
  ({
    budget,
    shopId,
    loading,
    onSubmit,
    onSubmitAllocationSetting,
    onClose,
    onFormValidationError,
    allocationSetting,
    averageAndMaxSalesByDayOfWeek,
    salesByMonths,
  }) => {
    const {
      form,
      initialValues,
      submit,
      submitAllocationSetting,
      resetAllocationSettingToFormInitialValue,
      resetAllocationSettingToDefaultValue,
    } = useEditSalesBudgetForm({
      budget,
      shopId,
      onSubmit,
      onSubmitAllocationSetting,
      onFormValidationError,
      allocationSetting,
    });
    const targetMonth = dayjs(budget.businessDate);

    const [allocationSettingModalVisible, setAllocationSettingModalVisible] = useState(false);
    const openAllocationSettingModal = useCallback(
      () => setAllocationSettingModalVisible(true),
      [],
    );
    const onCancelAllocationSetting = useCallback(() => {
      resetAllocationSettingToFormInitialValue();
      setAllocationSettingModalVisible(false);
    }, [resetAllocationSettingToFormInitialValue]);

    const allocateDailySalesBudget = useCallback(() => {
      const allocationSetting = form.getFieldValue("allocationSetting") as
        | EditSalesBudgetFormValues["allocationSetting"];
      const monthlyBudget = form.getFieldValue("monthlyBudget") as
        | EditSalesBudgetFormValues["monthlyBudget"];

      if (!targetMonth || !monthlyBudget) return;

      const dayOff = form.getFieldValue("dayOff") as EditSalesBudgetFormValues["dayOff"];
      const dayOffDates = Object.entries(dayOff ?? {})
        .map(([date, dayOff]) => (dayOff ? date : undefined))
        .filter(isNotUndefined);

      const allocatedDailySalesBudget = getAllocatedDailySalesBudget({
        targetMonth,
        monthlyBudget,
        dayOffDates,
        allocationSetting,
      });
      form.setFieldsValue({
        dailyBudget: allocatedDailySalesBudget,
      });
    }, [form, targetMonth]);

    const onConfirmAllocationSetting = useCallback(async () => {
      await submitAllocationSetting();
      allocateDailySalesBudget();
      setAllocationSettingModalVisible(false);
    }, [submitAllocationSetting, allocateDailySalesBudget]);

    return (
      <Form name="editSalesBudget" form={form} layout="vertical" initialValues={initialValues}>
        <TargetMonthField />
        <Spacer size={32} />
        <MonthlyBudgetField targetMonth={targetMonth} salesByMonths={salesByMonths} />
        <Spacer size={32} />
        <DailyBudgetField
          targetMonth={targetMonth}
          averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
          onOpenAllocationSettingModal={openAllocationSettingModal}
          allocateDailySalesBudget={allocateDailySalesBudget}
        />
        <AllocationSettingModal
          averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
          open={allocationSettingModalVisible}
          onOk={onConfirmAllocationSetting}
          onCancel={onCancelAllocationSetting}
          resetAllocationSettingToDefaultValue={resetAllocationSettingToDefaultValue}
        />
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </Form>
    );
  },
);
