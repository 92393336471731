import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { ModalProps } from "antd/lib";
import { WarningOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const WarningRed = "#FF4D4F";

const ShopTimeConfirmationModal = styled(Modal)`
  padding: 32px;
`;

const ConfirmBusinessHoursUpdateButton = styled(Button)`
  background-color: ${WarningRed};
  color: white;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledWarningIcon = styled(WarningOutlined)`
  color: ${WarningRed};
  font-size: 16px;
  height: 28px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

const HighlightedWarningText = styled(Text)`
  color: ${WarningRed};
`;

const DuringHoursShopList = styled.ul`
  max-height: 230px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: block;
  padding: 5px 10px;
  list-style: none;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
  }
`;

type Props = {
  onCancel(): void;
  onSubmit(): void;
  open: ModalProps["open"];
  activeShops: {
    shopId: string;
    name: string;
  }[];
};

export const ActiveShopsConfirmationModal = memo(
  ({ onCancel, onSubmit, open, activeShops }: Props) => (
    <ShopTimeConfirmationModal
      zIndex={1003}
      width={450}
      centered
      closable={false}
      open={open}
      footer={
        <>
          <Button onClick={onCancel}>キャンセル</Button>
          <Spacer inline size={24} />
          <ConfirmBusinessHoursUpdateButton type="primary" onClick={onSubmit}>
            同意の上更新
          </ConfirmBusinessHoursUpdateButton>
        </>
      }
    >
      <ContentWrapper>
        <StyledWarningIcon />
        <Spacer inline size={20} />
        <div>
          <ModalTitle>
            現在、ご利用中の卓のある店舗がありますが、本当に更新してもよろしいですか？
          </ModalTitle>
          <Spacer size={8} />
          <Text>
            現在、ご利用中の卓のある店舗があるようです。
            <br />
            ご利用中にメニューの一括更新をすると、モバイルオーダー、レジ、ハンディの予期せぬトラブルの原因となるため、ご利用中の卓がない事を確認した上で更新する事を推奨しています。本当に更新しますか？
            <br />
            <br />
            ご利用中の卓のある店舗: {activeShops.length}店舗: <br />
            <DuringHoursShopList>
              {activeShops.map(({ name, shopId }) => (
                <li key={shopId}>{name}</li>
              ))}
            </DuringHoursShopList>
          </Text>
        </div>
      </ContentWrapper>
    </ShopTimeConfirmationModal>
  ),
);
