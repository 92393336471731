import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditLineChannelConfigFormItem } from "../useEditLineChannelConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LiffIdField = memo<Props>((props) => (
  <EditLineChannelConfigFormItem
    label="LIFF ID"
    name="liffId"
    wrapperCol={{ span: 20 }}
    rules={[{ required: true, message: "LIFF IDを入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditLineChannelConfigFormItem>
));
