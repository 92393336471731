import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum } from "types/graphql";

export const GiftsMenu = memo<SubMenuPropsWithoutFeatures>(({ ...props }) => (
  <SubMenu
    key="gifts"
    title="推しエール"
    features={[DashboardFeatureEnum.ShopManagement]}
    {...props}
  >
    <MenuItem route="gifts" to="/gift" text="推しエール対象" />
    <MenuItem route="giftChoices" to="/giftChoice" text="推しエール金額" />
  </SubMenu>
));
