import { useCallback } from "react";
import dayjs from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { insertWithRelation } from "util/type/insert-with-relation";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import {
  MonthlySalesBudget,
  MonthlySalesBudgetInsertInput,
  SalesBudgetAllocationSetting,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import { AllocationSetting } from "../types";

export type AddSalesBudgetFormValues = Pick<MonthlySalesBudget, "shopId"> & {
  targetMonth: dayjs.Dayjs;
  monthlyBudget: number | null;
  dailyBudget: {
    [date: string]: number | null | undefined;
  } | null;
  dayOff: {
    [date: string]: boolean;
  } | null;
  allocationSetting: Pick<
    SalesBudgetAllocationSetting,
    "monRate" | "tueRate" | "wedRate" | "thuRate" | "friRate" | "satRate" | "sunRate"
  >;
  shouldSaveAllocationSetting: boolean;
};

// NOTE: 曜日ごとの売上比率を過去データから算出
const allocationSettingDefaultValue: AddSalesBudgetFormValues["allocationSetting"] = {
  monRate: 11,
  tueRate: 10,
  wedRate: 11,
  thuRate: 11,
  friRate: 18,
  satRate: 22,
  sunRate: 17,
};

const getInitialValues = ({ allocationSetting }: { allocationSetting?: AllocationSetting }) => ({
  allocationSetting: allocationSetting
    ? {
        monRate: allocationSetting.monRate,
        tueRate: allocationSetting.tueRate,
        wedRate: allocationSetting.wedRate,
        thuRate: allocationSetting.thuRate,
        friRate: allocationSetting.friRate,
        satRate: allocationSetting.satRate,
        sunRate: allocationSetting.sunRate,
      }
    : allocationSettingDefaultValue,
  shouldSaveAllocationSetting: Boolean(allocationSetting),
});

export const AddSalesBudgetFormItem = createFormItem<AddSalesBudgetFormValues>();

export const useAddSalesBudgetForm = ({
  shopId,
  onSubmit,
  onSubmitAllocationSetting,
  onFormValidationError,
  allocationSetting,
}: {
  shopId: string;
  onSubmit: (budget: MonthlySalesBudgetInsertInput) => void;
  onSubmitAllocationSetting: (allocationSetting: SalesBudgetAllocationSettingInsertInput) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  allocationSetting?: AllocationSetting;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ allocationSetting });

  const submit = useCallback(async () => {
    try {
      await form.validateFields();

      const now = dayjs().toISOString();
      const formValues = form.getFieldsValue() as AddSalesBudgetFormValues;

      onSubmit(
        insertWithRelation<["monthlySalesBudget", "dailySalesBudgets"]>({
          businessDate: formValues.targetMonth.format("YYYY-MM-DD"),
          createdAt: now,
          shopId,
          taxExcludedAmount: formValues.monthlyBudget ?? 0,
          updatedAt: now,
          dailySalesBudgets: {
            data: Object.entries(formValues.dailyBudget ?? {}).map(([date, budget]) => ({
              businessDate: dayjs(date).format("YYYY-MM-DD"),
              createdAt: now,
              shopId,
              taxExcludedAmount: budget ?? 0,
              updatedAt: now,
            })),
          },
        }),
      );
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, shopId, onSubmit, onFormValidationError]);

  const submitAllocationSetting = useCallback(async () => {
    const { allocationSetting, shouldSaveAllocationSetting } =
      form.getFieldsValue() as AddSalesBudgetFormValues;

    if (shouldSaveAllocationSetting) {
      onSubmitAllocationSetting({ shopId, ...allocationSetting });
    }
  }, [form, shopId, onSubmitAllocationSetting]);

  // NOTE: 割合設定をフォームの初期値に戻す
  const resetAllocationSettingToFormInitialValue = useCallback(() => {
    form.setFieldsValue({ allocationSetting: initialValues.allocationSetting });
  }, [form, initialValues]);

  // NOTE: 割合設定を過去データから算出したデフォルト値に戻す
  const resetAllocationSettingToDefaultValue = useCallback(() => {
    form.setFieldsValue({ allocationSetting: allocationSettingDefaultValue });
  }, [form]);

  return {
    form,
    submit,
    submitAllocationSetting,
    resetAllocationSettingToFormInitialValue,
    resetAllocationSettingToDefaultValue,
    initialValues,
  };
};
