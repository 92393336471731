import React, { memo } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import type { RankingData } from "models/customerDashboard";

import { colors } from "constants/colors";

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTr = styled.tr`
  border-bottom: 1px solid ${colors.Divider.Default};
  height: 48px;
`;

const StyledTd = styled.td`
  padding-left: 8px;
  padding-right: 16px;

  :first-child {
    text-align: right;
    padding-left: 0px;
    width: 60px;
    font-weight: bold;
    font-size: 14px;
    color: #404040;
  }

  :not(:first-child) {
    &:not(:last-child) {
      border-right: 1px solid ${colors.Divider.Default};
    }
  }

  :nth-child(2) {
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :nth-child(3) {
    font-size: 12px;
  }

  :last-child {
    font-weight: bold;
    font-size: 14px;
  }

  :nth-child(3),
  :last-child {
    width: 254px;
    color: ${colors.Text.Secondary};
  }
`;

const Name = styled.span`
  font-size: 14px;
  color: ${colors.Text.Default};
  margin-left: 24px;
`;

type Props = {
  rankingData: RankingData;
  valueUnit: string;
};

export const CustomerRankingTable = memo<Props>(({ rankingData, valueUnit }) => (
  <StyledTable>
    <tbody>
      {rankingData.map((customer) => (
        <StyledTr key={customer.customerId}>
          <StyledTd>{customer.rank}.</StyledTd>
          <StyledTd>
            <Avatar size={32} src={customer.lineProfileImage} alt={customer.lineProfileName} />
            <Name>{customer.lineProfileName}</Name>
          </StyledTd>
          <StyledTd>{customer.lastVisitedAt}</StyledTd>
          <StyledTd>
            {customer.value}
            {valueUnit}
          </StyledTd>
        </StyledTr>
      ))}
    </tbody>
  </StyledTable>
));
