import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { PlanGroup } from "pages/EditPlan/types";

import { EditPlanFormItem } from "../useEditPlanForm";

const { Option } = Select;

type Props = {
  planGroups: PlanGroup[];
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const PlanGroupField = memo<Props>(({ planGroups, disabled, ...props }) => (
  <EditPlanFormItem label="プラングループ" name="planGroupId" {...props}>
    <Select<number> disabled={disabled} placeholder="プラングループを選択してください">
      {planGroups.map(({ planGroupId, name }) => (
        <Option key={planGroupId} value={planGroupId}>
          {name}
        </Option>
      ))}
    </Select>
  </EditPlanFormItem>
));
