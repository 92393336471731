import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { DashboardAccountRole } from "pages/AddDashboardAccount/types";

import { EditDashboardAccountFormItem } from "../useEditDashboardAccountForm";

type Props = {
  dashboardAccountRoles: DashboardAccountRole[];
} & Omit<FormItemProps, "children" | "name">;

export const DashboardAccountRoleField = memo<Props>(({ dashboardAccountRoles, ...props }) => (
  <EditDashboardAccountFormItem
    label="アカウントに付与する権限"
    name="dashboardAccountRoleId"
    rules={[{ required: true, message: "権限を選択してください" }]}
    {...props}
  >
    <Select<string>
      placeholder="権限"
      showSearch
      optionFilterProp="label"
      options={dashboardAccountRoles.map(({ name, id }) => ({ label: name, value: id }))}
    />
  </EditDashboardAccountFormItem>
));
