import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Checkbox, Switch } from "antd";
import type { ColumnsType } from "antd/lib/table";

import { message } from "components/antd/message";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { useSelectedTableShopIds } from "hooks/useSelectedTableShopIds";
import type { Shop as BaseShop } from "types/graphql";

import { BulkEditAction, BulkUpdateIsAppetizerEnable } from "./BulkUpdateIsAppetizerEnable";
import {
  useMenuAutoCompulsoryAppetizerShopsGetShopsQuery,
  useUpdateShopAutoCompulsoryAppetizerEnabledMutation,
} from "./queries";

const DEFAULT_PAGE_SIZE = 10;

const AllCheckbox = styled(Checkbox)`
  margin-left: 8px;
`;

const TableWrapper = styled.div`
  .ant-table {
    padding: 24px;
  }
  .ant-table-selection-column {
    text-align: right;
    padding-left: 16px;
    padding-right: 16px;
    width: 100px;
  }
  th.ant-table-selection-column {
    width: 100px;
  }
`;

type Shop = Pick<BaseShop, "shopId" | "name" | "enableAutoCompulsoryAppetizerOrder">;

type Props = {
  company: {
    id: string;
  } | null;
};

export const BulkUpdateAppetizerSetting = memo<Props>(({ company }) => {
  const { data, refetch, loading } = useMenuAutoCompulsoryAppetizerShopsGetShopsQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );

  const shops = data?.shop ?? [];

  const [updateShopAutoCompulsoryAppetizerEnabledMutation] =
    useUpdateShopAutoCompulsoryAppetizerEnabledMutation();

  const onUpdateIsEnable = useCallback(
    async (shopId: string, isEnable: boolean) => {
      if (!company) return;
      try {
        await updateShopAutoCompulsoryAppetizerEnabledMutation({
          variables: { shopIds: [shopId], isEnable },
        });

        message.success("編集を保存しました");
        refetch();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, updateShopAutoCompulsoryAppetizerEnabledMutation, refetch],
  );

  const onBulkUpdateIsEnable = useCallback(
    async ({ shopIds, isEnable }: { shopIds: string[]; isEnable: boolean }) => {
      if (!company) return;
      try {
        await updateShopAutoCompulsoryAppetizerEnabledMutation({
          variables: { shopIds, isEnable },
        });

        message.success("編集を保存しました");
        refetch();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },

    [company, updateShopAutoCompulsoryAppetizerEnabledMutation, refetch],
  );

  const [defaultPagination, setPagination] = usePagination();

  const pagination = useMemo(
    () => ({
      ...defaultPagination,
      defaultCurrent: defaultPagination.defaultCurrent ?? 1,
      defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
    }),
    [defaultPagination],
  );

  const {
    selectedShopIds,
    setSelectedShopIds,
    currentPageSelectedShopIds,
    clearCurrentPageSelectedShopIds,
    allCheckboxProps,
  } = useSelectedTableShopIds({ pagination, shops });

  const handleBulkEditAction = useCallback(
    async (action: BulkEditAction) => {
      await onBulkUpdateIsEnable({
        shopIds: currentPageSelectedShopIds,
        isEnable: action === BulkEditAction.Enable,
      });
      clearCurrentPageSelectedShopIds();
    },
    [currentPageSelectedShopIds, clearCurrentPageSelectedShopIds, onBulkUpdateIsEnable],
  );

  const columns: ColumnsType<Shop> = useMemo(
    () => [
      {
        title: "自動注文",
        width: 100,
        align: "center",
        render(_, { shopId }) {
          const isEnable = Boolean(
            (data?.shop ?? []).find((s) => s.shopId === shopId)?.enableAutoCompulsoryAppetizerOrder,
          );

          return <Switch onChange={() => onUpdateIsEnable(shopId, !isEnable)} checked={isEnable} />;
        },
      },
      {
        title: "店舗名",
        dataIndex: "name",
      },
    ],
    [data?.shop, onUpdateIsEnable],
  );

  return (
    <>
      {currentPageSelectedShopIds.length !== 0 && (
        <BulkUpdateIsAppetizerEnable
          onClearSelection={clearCurrentPageSelectedShopIds}
          onSubmit={handleBulkEditAction}
          selectedCount={currentPageSelectedShopIds.length}
        />
      )}
      <TableWrapper>
        <Table
          loading={loading}
          dataSource={shops}
          columns={columns}
          rowKey="shopId"
          bordered
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          pagination={pagination}
          rowSelection={{
            columnTitle: (
              <>
                全選択
                <AllCheckbox {...allCheckboxProps} />
              </>
            ),
            selectedRowKeys: selectedShopIds,
            onChange: (_, rows) => setSelectedShopIds(rows.map(({ shopId }) => shopId)),
          }}
        />
      </TableWrapper>
    </>
  );
});
