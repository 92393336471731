import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditRoleFormItem } from "../useEditRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PaymentReceiptField = memo<Props>(({ ...props }) => (
  <EditRoleFormItem
    label={
      <FormHelp
        label="自動会計伝票印刷"
        help="オンにした場合はお客様がダイニー上で会計ボタンを押すと自動で会計伝票が印刷されます"
      />
    }
    name="shouldPrintPaymentReceipt"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditRoleFormItem>
));
