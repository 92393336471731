import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment-timezone";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { AccountingHistory } from "pages/AccountingHistory/index";

type Props = {
  loading?: boolean;
  accountingHistories: AccountingHistory[];
};

const createColumns = (): ColumnsType<AccountingHistory> => [
  {
    title: "会計ID",
    align: "center",
    width: 100,
    dataIndex: "onSitePaymentId",
    render(_: string, { onSitePaymentId }) {
      return <Link to={`/accounting/${onSitePaymentId}`}>{onSitePaymentId}</Link>;
    },
  },
  {
    title: "レシート番号",
    align: "center",
    width: 100,
    dataIndex: "receiptId",
  },
  {
    title: "テーブル番号",
    align: "center",
    width: 100,
    render(_: string, { tables }: { tables: { name: string }[] }) {
      return tables.map((t) => t.name).join(", ");
    },
  },
  {
    title: "支払方法",
    align: "center",
    width: 100,
    render(_: string, { onSitePaymentDetailTypes }: { onSitePaymentDetailTypes: string[] }) {
      return (
        <>
          {onSitePaymentDetailTypes.sort().map((onSitePaymentDetailType) => (
            <Tag key={onSitePaymentDetailType}>{onSitePaymentDetailType}</Tag>
          ))}
        </>
      );
    },
  },
  {
    title: "割引方法",
    align: "center",
    width: 100,
    render(_: string, { onSitePaymentDiscountTypes }: { onSitePaymentDiscountTypes: string[] }) {
      return (
        <>
          {onSitePaymentDiscountTypes.sort().map((onSitePaymentDiscountType) => (
            <Tag key={onSitePaymentDiscountType}>{onSitePaymentDiscountType}</Tag>
          ))}
        </>
      );
    },
  },
  {
    title: "会計金額",
    align: "center",
    width: 100,
    render(_: string, { amount }: { amount: number }) {
      return `${String(amount)}円`;
    },
  },
  {
    title: "会計時刻",
    align: "center",
    width: 100,
    render(_: string, { createdAt }: { createdAt: string }) {
      return moment(new Date(createdAt)).utc().tz("Asia/Tokyo").format("HH:mm");
    },
  },
  {
    title: "取り消し",
    align: "center",
    width: 100,
    render(_: string, { voidedAt }: { voidedAt?: string }) {
      return voidedAt ? <Tag color="red">取り消し済み</Tag> : "";
    },
  },
];

export const AccountingHistoryTable = memo<Props>(({ loading, accountingHistories }) => {
  const [pagination, setPagination] = usePagination();

  const columns = createColumns();

  return (
    <Table
      rowKey="onSitePaymentId"
      columns={columns}
      dataSource={accountingHistories}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
