import React, { memo } from "react";
import { range } from "lodash";

import { FormItemProps } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChangeDateTimeField = memo<Props>((props) => (
  <EditShopFormItem label="営業日変更時刻" name="changeDateTime" {...props}>
    <TimePicker
      formItemName="changeDateTime"
      disabledTime={() => ({
        disabledHours: () => range(12, 24),
      })}
    />
  </EditShopFormItem>
));
