import React, { memo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0px 21px 32px rgba(211, 228, 244, 0.45);
  border-radius: 10px;
`;

const Title = styled.div<{ mb: number }>`
  font-weight: bold;
  font-size: 16px;
  color: #00638d;
  height: 20px;
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

const Caption = styled.div`
  font-size: 12px;
  color: #8ca1aa;
  margin-bottom: 16px;
  height: 14px;
`;

type Props = {
  title: string;
  caption?: string;
  children: React.ReactNode;
};

export const CustomerDashboardCard = memo<Props>(({ title, caption, children }) => (
  <Wrapper>
    <Title mb={caption ? 8 : 38}>{title}</Title>
    {caption && <Caption>{caption}</Caption>}
    {children}
  </Wrapper>
));
