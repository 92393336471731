import React, { memo, useMemo } from "react";
import styled, { css } from "styled-components";
import { Divider } from "antd";
import { isRankedIn } from "models/questionnaireAnalytics";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { NoDataDisplay } from "components/QuestionnaireAnalytics/NoDataDisplay";

const Wrapper = styled(BasePanel)<{ maskImageSrc?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  object-fit: fill;

  ${({ maskImageSrc }) =>
    maskImageSrc &&
    css`
      background-image: url(${maskImageSrc});
      background-size: cover;
    `};
`;

const Fluctuation = styled.div`
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  height: 22px;
  white-space: nowrap;
`;

const SmallText = styled.span`
  font-size: 12px;
`;

const AllShopsCountText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 8px 4px;
  font-weight: 500;
`;

const ZeroMarginDivider = styled(Divider)`
  margin: 0px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const RankedOutBadge = styled.div`
  position: relative;
`;

const RankedOutNumber = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 38px;
  color: #ffffffd9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type Ranking = {
  ranking: number;
  prevRanking: number;
};

type Props = {
  ranking: Ranking;
  shopCount: number;
  isEmpty: boolean;
  className?: string;
};

const getFluctuationText = ({ ranking, prevRanking }: { ranking: number; prevRanking: number }) =>
  ranking === prevRanking ? "→ KEEP" : ranking < prevRanking ? "↑ UP" : "↓ DOWN";

export const CompanyRankingPanel = memo<Props>(
  ({ ranking: { ranking, prevRanking }, shopCount, isEmpty, className }) => {
    const { rankImageSrc, maskImageSrc } = useMemo(
      () =>
        isRankedIn(ranking)
          ? {
              rankImageSrc: `/questionnaire-analytics/company_rank_${ranking}.png`,
              maskImageSrc: `/questionnaire-analytics/company_rank_mask_${ranking}.png`,
            }
          : { rankImageSrc: "/questionnaire-analytics/company_unranked.png" },
      [ranking],
    );

    return (
      <Wrapper
        title="社内ランキング"
        className={className}
        maskImageSrc={isEmpty ? undefined : maskImageSrc}
      >
        {isEmpty ? (
          <NoDataDisplay />
        ) : (
          <>
            {isRankedIn(ranking) ? (
              <img src={rankImageSrc} alt={`rank ${ranking}`} />
            ) : (
              <RankedOutBadge>
                <img src={rankImageSrc} alt={`rank ${ranking}`} />
                <RankedOutNumber>{ranking}</RankedOutNumber>
              </RankedOutBadge>
            )}

            <Fluctuation>
              {getFluctuationText({ ranking, prevRanking })} ( <SmallText>前期間</SmallText>
              {prevRanking}位 )
            </Fluctuation>

            <ZeroMarginDivider />

            <AllShopsCountText>
              <span>全店舗数</span>
              <BoldText>{shopCount}</BoldText>
            </AllShopsCountText>
          </>
        )}
      </Wrapper>
    );
  },
);
