import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveryAnalyticsGetSummary = gql`
    query MessageDeliveryAnalyticsGetSummary($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummary(input: $input) {
    messageSentCount
    messageOpenCount
    visitedCustomersCount
    visitedTableUserCustomersCount
    visitedTableUserCustomersSales
  }
}
    `;
export const MessageDeliveryAnalyticsGetSummaryPerShop = gql`
    query MessageDeliveryAnalyticsGetSummaryPerShop($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummaryPerShop(input: $input) {
    items {
      shopName
      messageSentCount
      messageOpenCount
      visitedCustomersCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
    }
  }
}
    `;
export const MessageDeliveryAnalyticsGetSummaryPerMessageDelivery = gql`
    query MessageDeliveryAnalyticsGetSummaryPerMessageDelivery($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummaryPerMessageDelivery(input: $input) {
    items {
      messageDeliveryName
      messageDeliveryImageUrl
      lastMessageDeliveryJobId
      startAt
      endAt
      messageSentCount
      messageOpenCount
      visitedCustomersCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
      items {
        shopName
        messageSentCount
        messageOpenCount
        visitedCustomersCount
        visitedTableUserCustomersCount
        visitedTableUserCustomersSales
      }
    }
  }
}
    `;
export const MessageDeliveryAnalyticsGetRecentVisitedTableUserReport = gql`
    query MessageDeliveryAnalyticsGetRecentVisitedTableUserReport($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsRecentVisitedTableUserReport(input: $input) {
    recentVisitedTableUsers {
      customerName
      customerProfileImages
      numPeople
      sales
      visitedAt
      visitedCount
    }
  }
}
    `;
export const MessageDeliveryAnalyticsGetCompanies = gql`
    query MessageDeliveryAnalyticsGetCompanies($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
}
    `;
export type MessageDeliveryAnalyticsGetSummaryQueryVariables = Types.Exact<{
  input: Types.MessageDeliveryAnalyticsInput;
}>;


export type MessageDeliveryAnalyticsGetSummaryQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryAnalyticsSummary: (
    { __typename?: 'MessageDeliveryAnalyticsSummary' }
    & Pick<Types.MessageDeliveryAnalyticsSummary, 'messageSentCount' | 'messageOpenCount' | 'visitedCustomersCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
  ) }
);

export type MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables = Types.Exact<{
  input: Types.MessageDeliveryAnalyticsInput;
}>;


export type MessageDeliveryAnalyticsGetSummaryPerShopQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryAnalyticsSummaryPerShop: (
    { __typename?: 'MessageDeliveryAnalyticsSummaryPerShop' }
    & { items: Array<(
      { __typename?: 'MessageDeliveryAnalyticsSummaryPerShopItem' }
      & Pick<Types.MessageDeliveryAnalyticsSummaryPerShopItem, 'shopName' | 'messageSentCount' | 'messageOpenCount' | 'visitedCustomersCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
    )> }
  ) }
);

export type MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables = Types.Exact<{
  input: Types.MessageDeliveryAnalyticsInput;
}>;


export type MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryAnalyticsSummaryPerMessageDelivery: (
    { __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDelivery' }
    & { items: Array<(
      { __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem' }
      & Pick<Types.MessageDeliveryAnalyticsSummaryPerMessageDeliveryItem, 'messageDeliveryName' | 'messageDeliveryImageUrl' | 'lastMessageDeliveryJobId' | 'startAt' | 'endAt' | 'messageSentCount' | 'messageOpenCount' | 'visitedCustomersCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
      & { items: Array<(
        { __typename?: 'MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem' }
        & Pick<Types.MessageDeliveryAnalyticsSummaryPerMessageDeliveryAndShopItem, 'shopName' | 'messageSentCount' | 'messageOpenCount' | 'visitedCustomersCount' | 'visitedTableUserCustomersCount' | 'visitedTableUserCustomersSales'>
      )> }
    )> }
  ) }
);

export type MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables = Types.Exact<{
  input: Types.MessageDeliveryAnalyticsInput;
}>;


export type MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryAnalyticsRecentVisitedTableUserReport: (
    { __typename?: 'MessageDeliveryAnalyticsRecentVisitedTableUsersReport' }
    & { recentVisitedTableUsers: Array<(
      { __typename?: 'MessageDeliveryAnalyticsRecentVisitedTableUser' }
      & Pick<Types.MessageDeliveryAnalyticsRecentVisitedTableUser, 'customerName' | 'customerProfileImages' | 'numPeople' | 'sales' | 'visitedAt' | 'visitedCount'>
    )> }
  ) }
);

export type MessageDeliveryAnalyticsGetCompaniesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryAnalyticsGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
    )> }
  )> }
);


export const MessageDeliveryAnalyticsGetSummaryDocument = gql`
    query MessageDeliveryAnalyticsGetSummary($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummary(input: $input) {
    messageSentCount
    messageOpenCount
    visitedCustomersCount
    visitedTableUserCustomersCount
    visitedTableUserCustomersSales
  }
}
    `;

/**
 * __useMessageDeliveryAnalyticsGetSummaryQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryAnalyticsGetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryAnalyticsGetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryAnalyticsGetSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageDeliveryAnalyticsGetSummaryQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryAnalyticsGetSummaryQuery, MessageDeliveryAnalyticsGetSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryAnalyticsGetSummaryQuery, MessageDeliveryAnalyticsGetSummaryQueryVariables>(MessageDeliveryAnalyticsGetSummaryDocument, options);
      }
export function useMessageDeliveryAnalyticsGetSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryAnalyticsGetSummaryQuery, MessageDeliveryAnalyticsGetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryAnalyticsGetSummaryQuery, MessageDeliveryAnalyticsGetSummaryQueryVariables>(MessageDeliveryAnalyticsGetSummaryDocument, options);
        }
export type MessageDeliveryAnalyticsGetSummaryQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryQuery>;
export type MessageDeliveryAnalyticsGetSummaryLazyQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryLazyQuery>;
export type MessageDeliveryAnalyticsGetSummaryQueryResult = Apollo.QueryResult<MessageDeliveryAnalyticsGetSummaryQuery, MessageDeliveryAnalyticsGetSummaryQueryVariables>;
export const MessageDeliveryAnalyticsGetSummaryPerShopDocument = gql`
    query MessageDeliveryAnalyticsGetSummaryPerShop($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummaryPerShop(input: $input) {
    items {
      shopName
      messageSentCount
      messageOpenCount
      visitedCustomersCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
    }
  }
}
    `;

/**
 * __useMessageDeliveryAnalyticsGetSummaryPerShopQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryAnalyticsGetSummaryPerShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryAnalyticsGetSummaryPerShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryAnalyticsGetSummaryPerShopQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageDeliveryAnalyticsGetSummaryPerShopQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryAnalyticsGetSummaryPerShopQuery, MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryAnalyticsGetSummaryPerShopQuery, MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables>(MessageDeliveryAnalyticsGetSummaryPerShopDocument, options);
      }
export function useMessageDeliveryAnalyticsGetSummaryPerShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryAnalyticsGetSummaryPerShopQuery, MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryAnalyticsGetSummaryPerShopQuery, MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables>(MessageDeliveryAnalyticsGetSummaryPerShopDocument, options);
        }
export type MessageDeliveryAnalyticsGetSummaryPerShopQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryPerShopQuery>;
export type MessageDeliveryAnalyticsGetSummaryPerShopLazyQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryPerShopLazyQuery>;
export type MessageDeliveryAnalyticsGetSummaryPerShopQueryResult = Apollo.QueryResult<MessageDeliveryAnalyticsGetSummaryPerShopQuery, MessageDeliveryAnalyticsGetSummaryPerShopQueryVariables>;
export const MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryDocument = gql`
    query MessageDeliveryAnalyticsGetSummaryPerMessageDelivery($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsSummaryPerMessageDelivery(input: $input) {
    items {
      messageDeliveryName
      messageDeliveryImageUrl
      lastMessageDeliveryJobId
      startAt
      endAt
      messageSentCount
      messageOpenCount
      visitedCustomersCount
      visitedTableUserCustomersCount
      visitedTableUserCustomersSales
      items {
        shopName
        messageSentCount
        messageOpenCount
        visitedCustomersCount
        visitedTableUserCustomersCount
        visitedTableUserCustomersSales
      }
    }
  }
}
    `;

/**
 * __useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery, MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery, MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables>(MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryDocument, options);
      }
export function useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery, MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery, MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables>(MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryDocument, options);
        }
export type MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery>;
export type MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryLazyQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetSummaryPerMessageDeliveryLazyQuery>;
export type MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryResult = Apollo.QueryResult<MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQuery, MessageDeliveryAnalyticsGetSummaryPerMessageDeliveryQueryVariables>;
export const MessageDeliveryAnalyticsGetRecentVisitedTableUserReportDocument = gql`
    query MessageDeliveryAnalyticsGetRecentVisitedTableUserReport($input: MessageDeliveryAnalyticsInput!) {
  messageDeliveryAnalyticsRecentVisitedTableUserReport(input: $input) {
    recentVisitedTableUsers {
      customerName
      customerProfileImages
      numPeople
      sales
      visitedAt
      visitedCount
    }
  }
}
    `;

/**
 * __useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery, MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery, MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables>(MessageDeliveryAnalyticsGetRecentVisitedTableUserReportDocument, options);
      }
export function useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery, MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery, MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables>(MessageDeliveryAnalyticsGetRecentVisitedTableUserReportDocument, options);
        }
export type MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery>;
export type MessageDeliveryAnalyticsGetRecentVisitedTableUserReportLazyQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetRecentVisitedTableUserReportLazyQuery>;
export type MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryResult = Apollo.QueryResult<MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQuery, MessageDeliveryAnalyticsGetRecentVisitedTableUserReportQueryVariables>;
export const MessageDeliveryAnalyticsGetCompaniesDocument = gql`
    query MessageDeliveryAnalyticsGetCompanies($corporationId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}
      order_by: [{name: asc}]
    ) {
      id
      name
    }
  }
}
    `;

/**
 * __useMessageDeliveryAnalyticsGetCompaniesQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryAnalyticsGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryAnalyticsGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryAnalyticsGetCompaniesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useMessageDeliveryAnalyticsGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryAnalyticsGetCompaniesQuery, MessageDeliveryAnalyticsGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryAnalyticsGetCompaniesQuery, MessageDeliveryAnalyticsGetCompaniesQueryVariables>(MessageDeliveryAnalyticsGetCompaniesDocument, options);
      }
export function useMessageDeliveryAnalyticsGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryAnalyticsGetCompaniesQuery, MessageDeliveryAnalyticsGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryAnalyticsGetCompaniesQuery, MessageDeliveryAnalyticsGetCompaniesQueryVariables>(MessageDeliveryAnalyticsGetCompaniesDocument, options);
        }
export type MessageDeliveryAnalyticsGetCompaniesQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetCompaniesQuery>;
export type MessageDeliveryAnalyticsGetCompaniesLazyQueryHookResult = ReturnType<typeof useMessageDeliveryAnalyticsGetCompaniesLazyQuery>;
export type MessageDeliveryAnalyticsGetCompaniesQueryResult = Apollo.QueryResult<MessageDeliveryAnalyticsGetCompaniesQuery, MessageDeliveryAnalyticsGetCompaniesQueryVariables>;