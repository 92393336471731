import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Category } from "pages/EditPlan/types";

import { EditPlanFormItem } from "../useEditPlanForm";

const { Option } = Select;

type Props = {
  categories: Category[];
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const CategoryField = memo<Props>(({ categories, disabled, ...props }) => (
  <EditPlanFormItem
    label="カテゴリ"
    name="categoryId"
    rules={[{ required: true, message: "カテゴリ名を入力してください" }]}
    {...props}
  >
    <Select<number> disabled={disabled} placeholder="カテゴリを選択してください">
      {categories.map(({ categoryId, name, shopSideName }) => (
        <Option key={categoryId} value={categoryId}>
          {shopSideName || name}
        </Option>
      ))}
    </Select>
  </EditPlanFormItem>
));
