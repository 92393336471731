import React, { memo } from "react";
import styled from "styled-components";

import { colors } from "constants/colors";
import { MessageDeliveryMessageTypeEnum } from "types/graphql";

import { MessageFormValue } from "../../types";

const RoomContentCompanyName = styled.p`
  color: ${colors.Text.Secondary};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
`;

const RoomContentText = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: ${colors.Text.Secondary};
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  companyName: string;
  message: MessageFormValue;
};

export const Room = memo(({ companyName, message }: Props) => {
  const text = (() => {
    switch (message.type) {
      case MessageDeliveryMessageTypeEnum.Text:
        return message.text;
      case MessageDeliveryMessageTypeEnum.Image:
        return "画像";
      case MessageDeliveryMessageTypeEnum.Coupon:
        return "クーポン";
      case MessageDeliveryMessageTypeEnum.Questionnaire:
        return "3分で終わる簡単なアンケートにご協力をお願いします！";
    }
  })();

  return (
    <>
      <RoomContentCompanyName>{companyName}</RoomContentCompanyName>
      <RoomContentText>{text}</RoomContentText>
    </>
  );
});
