import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { QuestionnaireConfig } from "../types";

type Props = {
  loading?: boolean;
  questionnaireConfigs: QuestionnaireConfig[];
};

export const QuestionnaireConfigsTable = memo<Props>(({ loading, questionnaireConfigs }) => {
  const { canAccess } = useCanAccess();
  const [pagination, setPagination] = usePagination();

  const columns: ColumnsType<QuestionnaireConfig> = [
    {
      title: "画像",
      dataIndex: "imageUrl",
      align: "center" as const,
      width: 100,
      render: (imageUrl: string) => <Thumbnail url={imageUrl} width={64} height={64} />,
    },
    {
      title: "アンケート",
      width: 200,
      render: (_: unknown, { questionnaire }: QuestionnaireConfig) => questionnaire?.name,
    },
    {
      title: "クーポン",
      width: 300,
      render: (_: unknown, { coupon }: QuestionnaireConfig) => coupon?.title,
    },
    ...(canAccess("editQuestionnaireConfig")
      ? [
          {
            title: "",
            align: "center" as const,
            width: 60,
            render(_: unknown, { id }: QuestionnaireConfig) {
              return (
                <IconLink to={`/questionnaireConfig/${id}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={questionnaireConfigs}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
