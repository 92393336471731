import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import { isNot } from "util/type/primitive";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { DishUpSlipGroup, Role } from "pages/ShopPlans/types";

export type FilterConditions = {
  name?: string;
  isVisibleForCustomer?: boolean;
  isVisibleForStaff?: boolean;
  isSoldOut?: boolean;
  roleIds?: number[] | "empty";
  dishUpSlipGroupIds?: number[] | "empty";
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  roles: Role[];
  dishUpSlipGroups: DishUpSlipGroup[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const ShopPlanFilter = memo<Props>(
  ({
    roles,
    dishUpSlipGroups,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => (
    <Wrapper>
      <Col flex="2 0">
        <Input
          placeholder="プラン名"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
        />
      </Col>
      <Col flex="1 0">
        <Select<string>
          value={String(filterConditions.isVisibleForCustomer)}
          onChange={(value) =>
            updateFilterCondition({
              isVisibleForCustomer: value === "undefined" ? undefined : value === "true",
            })
          }
          optionFilterProp="label"
          options={[
            { label: "お客様への公開設定", value: "undefined" },
            { label: "お客様に公開", value: "true" },
            { label: "お客様に非公開", value: "false" },
          ]}
        />
      </Col>
      <Col flex="1 0">
        <Select<string>
          value={String(filterConditions.isVisibleForStaff)}
          onChange={(value) =>
            updateFilterCondition({
              isVisibleForStaff: value === "undefined" ? undefined : value === "true",
            })
          }
          optionFilterProp="label"
          options={[
            { label: "スタッフへの公開設定", value: "undefined" },
            { label: "スタッフに公開", value: "true" },
            { label: "スタッフに非公開", value: "false" },
          ]}
        />
      </Col>
      <Col flex="1 0">
        <Select<string>
          value={String(filterConditions.isSoldOut)}
          onChange={(isSoldOut) =>
            updateFilterCondition({
              isSoldOut: isSoldOut === "undefined" ? undefined : isSoldOut === "true",
            })
          }
          optionFilterProp="label"
          options={[
            { label: "在庫設定", value: "undefined" },
            { label: "在庫あり", value: "false" },
            { label: "在庫なし", value: "true" },
          ]}
        />
      </Col>
      <Col flex="2 0">
        <Select<(number | "empty")[]>
          mode="multiple"
          allowClear
          placeholder="キッチンプリンター"
          defaultValue={[]}
          value={
            filterConditions.roleIds === "empty"
              ? [filterConditions.roleIds]
              : filterConditions.roleIds ?? []
          }
          onChange={(roleIds) =>
            updateFilterCondition({
              roleIds: roleIds.slice(-1)[0] === "empty" ? "empty" : roleIds.filter(isNot("empty")),
            })
          }
          optionFilterProp="label"
          options={[
            { label: "未設定", value: "empty" },
            ...roles.map(({ roleId, name }) => ({ label: name, value: roleId })),
          ]}
          data-cy="select-kitchen-role"
        />
      </Col>
      <Col flex="2 0">
        <Select<(number | "empty")[]>
          mode="multiple"
          allowClear
          placeholder="デシャップ伝票グループ"
          defaultValue={[]}
          value={
            filterConditions.dishUpSlipGroupIds === "empty"
              ? [filterConditions.dishUpSlipGroupIds]
              : filterConditions.dishUpSlipGroupIds ?? []
          }
          onChange={(dishUpSlipGroupIds) =>
            updateFilterCondition({
              dishUpSlipGroupIds:
                dishUpSlipGroupIds.slice(-1)[0] === "empty"
                  ? "empty"
                  : dishUpSlipGroupIds.filter(isNot("empty")),
            })
          }
          optionFilterProp="label"
          options={[
            { label: "未設定", value: "empty" },
            ...dishUpSlipGroups.map(({ id, name }) => ({ label: name, value: id })),
          ]}
          data-cy="select-dish-up-slip-group"
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
