import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopsGetShops = gql`
    query ShopsGetShops($companyId: uuid!) {
  company(where: {archivedAt: {_is_null: true}, id: {_eq: $companyId}}, limit: 1) {
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      close
      maxNumForNumPeople
      name
      open
      shopId
    }
  }
}
    `;
export const ShopsArchiveShops = gql`
    mutation ShopsArchiveShops($input: ArchiveShopInput!) {
  dashboardAccountArchiveShop(input: $input)
}
    `;
export type ShopsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ShopsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'close' | 'maxNumForNumPeople' | 'name' | 'open' | 'shopId'>
    )> }
  )> }
);

export type ShopsArchiveShopsMutationVariables = Types.Exact<{
  input: Types.ArchiveShopInput;
}>;


export type ShopsArchiveShopsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountArchiveShop'>
);


export const ShopsGetShopsDocument = gql`
    query ShopsGetShops($companyId: uuid!) {
  company(where: {archivedAt: {_is_null: true}, id: {_eq: $companyId}}, limit: 1) {
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      close
      maxNumForNumPeople
      name
      open
      shopId
    }
  }
}
    `;

/**
 * __useShopsGetShopsQuery__
 *
 * To run a query within a React component, call `useShopsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useShopsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<ShopsGetShopsQuery, ShopsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsGetShopsQuery, ShopsGetShopsQueryVariables>(ShopsGetShopsDocument, options);
      }
export function useShopsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsGetShopsQuery, ShopsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsGetShopsQuery, ShopsGetShopsQueryVariables>(ShopsGetShopsDocument, options);
        }
export type ShopsGetShopsQueryHookResult = ReturnType<typeof useShopsGetShopsQuery>;
export type ShopsGetShopsLazyQueryHookResult = ReturnType<typeof useShopsGetShopsLazyQuery>;
export type ShopsGetShopsQueryResult = Apollo.QueryResult<ShopsGetShopsQuery, ShopsGetShopsQueryVariables>;
export const ShopsArchiveShopsDocument = gql`
    mutation ShopsArchiveShops($input: ArchiveShopInput!) {
  dashboardAccountArchiveShop(input: $input)
}
    `;
export type ShopsArchiveShopsMutationFn = Apollo.MutationFunction<ShopsArchiveShopsMutation, ShopsArchiveShopsMutationVariables>;

/**
 * __useShopsArchiveShopsMutation__
 *
 * To run a mutation, you first call `useShopsArchiveShopsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopsArchiveShopsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopsArchiveShopsMutation, { data, loading, error }] = useShopsArchiveShopsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopsArchiveShopsMutation(baseOptions?: Apollo.MutationHookOptions<ShopsArchiveShopsMutation, ShopsArchiveShopsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopsArchiveShopsMutation, ShopsArchiveShopsMutationVariables>(ShopsArchiveShopsDocument, options);
      }
export type ShopsArchiveShopsMutationHookResult = ReturnType<typeof useShopsArchiveShopsMutation>;
export type ShopsArchiveShopsMutationResult = Apollo.MutationResult<ShopsArchiveShopsMutation>;
export type ShopsArchiveShopsMutationOptions = Apollo.BaseMutationOptions<ShopsArchiveShopsMutation, ShopsArchiveShopsMutationVariables>;