import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditShopMenuStockForm } from "pages/EditShopMenuStock/EditShopMenuStockForm";
import {
  useEditShopMenuStockGetShopMenuQuery,
  useEditShopMenuStockGetShopQuery,
  useEditShopMenuStockUpdateStockMutation,
} from "pages/EditShopMenuStock/queries";
import { Stock } from "types/graphql";

export const EditShopMenuStock = () => {
  const { shopId, menuId: menuIdString } = useParams<Record<"shopId" | "menuId", string>>();
  const menuId = Number(menuIdString);
  const navigate = useNavigate();

  const { data: getShopData, error: getShopDataError } = useEditShopMenuStockGetShopQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const shop = getShopData?.shop_by_pk;

  useSetCurrentCompanyEffect(shop);

  const {
    data: getShopMenuData,
    loading: loadingShopMenu,
    refetch: refetchShopMenu,
    error: getShopMenuDataError,
  } = useEditShopMenuStockGetShopMenuQuery(
    shopId && !Number.isNaN(menuId) ? { variables: { shopId, menuId } } : { skip: true },
  );

  const shouldShowAlert = getShopDataError || getShopMenuDataError;

  const shopMenu = getShopMenuData?.shopMenu?.[0];

  const [updateStockMutation, { loading: loadingUpdateStock }] =
    useEditShopMenuStockUpdateStockMutation();

  const onSubmit = useCallback(
    async (stock: Pick<Stock, "currentStockNum" | "dailyStockNum">) => {
      if (shopId && !Number.isNaN(menuId)) {
        const stockId = shopMenu?.stock?.id ?? uuidv4();
        const stockDto = { id: stockId, shopId, ...stock };
        try {
          await updateStockMutation({
            variables: { shopId, menuId, stock: stockDto, stockId },
          });
          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }

        await refetchShopMenu();
      }
    },
    [shopId, menuId, updateStockMutation, shopMenu?.stock, refetchShopMenu],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={shopMenu?.menu.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "メニュー" }],
      }}
    >
      <PageHeader title={shopMenu?.menu.name} onBack={goBack} />
      {loadingShopMenu && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shopMenu && (
        <EditShopMenuStockForm
          shopMenu={shopMenu}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateStock}
        />
      )}
    </DashboardLayout>
  );
};
