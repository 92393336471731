import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterPlansByName } from "models/plan";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, PlanFilter } from "./PlanFilter";
import { PlanTable } from "./PlanTable";
import {
  usePikaichiPlansDeletePikaichiMenuMutation,
  usePikaichiPlansGetCategoriesQuery,
  usePikaichiPlansGetPlansQuery,
  usePikaichiPlansGetShopsQuery,
} from "./queries";
import { Plan } from "./types";

export const filterPlans = (plans: Plan[], { categoryIds, name, shopId }: FilterConditions) => {
  const filteredPlans = plans.filter(
    (plan) =>
      (categoryIds === undefined || categoryIds.includes(plan.category.categoryId)) &&
      (shopId === undefined || plan.shopPlans.map(({ shop }) => shop.shopId).includes(shopId)),
  );

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans;
};

export const PikaichiPlans = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getPlansData,
    loading: loadingPlans,
    refetch: refetchPlans,
    error,
  } = usePikaichiPlansGetPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const plans = useMemo(() => getPlansData?.plans ?? [], [getPlansData]);

  const { data: getCategoriesData } = usePikaichiPlansGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = usePikaichiPlansGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [deletePikaichiMenuMutation] = usePikaichiPlansDeletePikaichiMenuMutation();

  const onDelete = useCallback(
    async ({ pikaichiMenuId }: { pikaichiMenuId: string }) => {
      try {
        await deletePikaichiMenuMutation({
          variables: { pikaichiMenuId },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }

      await refetchPlans();
    },
    [deletePikaichiMenuMutation, refetchPlans],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredPlans = useMemo(
    () => filterPlans(plans, filterConditions),
    [plans, filterConditions],
  );

  return (
    <DashboardLayout title="プラン一覧">
      <PageHeader title="プラン一覧" />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <PlanFilter
        shops={shops}
        categories={categories}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
        clearFilterConditions={clearFilterConditions}
      />
      <PlanTable plans={filteredPlans} loading={loadingPlans} onDelete={onDelete} />
    </DashboardLayout>
  );
};
