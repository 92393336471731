import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditDetailTypeFormItem } from "../useEditDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const MediaCodeField = memo<Props>((props) => (
  <EditDetailTypeFormItem
    label="メディア種別"
    name="mediaCode"
    rules={[{ required: true, message: "メディア種別を入力してください" }]}
    {...props}
  >
    <Input />
  </EditDetailTypeFormItem>
));
