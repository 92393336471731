import React, { memo } from "react";
import { taxRates } from "models/taxRate";

import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { Select } from "components/Input/Select";

type Props = {
  value: number | undefined;
  initialValue: number | undefined;
  categoryMenuId: number;
  disabled: boolean;
};

export const CostTaxRateField = memo<Props>(({ initialValue, value, categoryMenuId, disabled }) => (
  <CostTaxRateWithCostPriceField
    name={[categoryMenuId, "costTaxRate"]}
    costPriceFieldName={[categoryMenuId, "costPrice"]}
    noStyle
    disabled={initialValue !== undefined}
  >
    <Select
      placeholder={initialValue}
      defaultValue={value}
      showSearch
      options={Object.entries(taxRates).map(([taxRateKey, taxRate]) => ({
        label: taxRate,
        value: taxRateKey,
      }))}
      allowClear
      fullWidth
      disabled={disabled}
    />
  </CostTaxRateWithCostPriceField>
));
