import { AccountingSlipImagePositionEnum } from "types/graphql";

export const getPositionText = (position: AccountingSlipImagePositionEnum) => {
  switch (position) {
    case AccountingSlipImagePositionEnum.Footer:
      return "レシート下部";
    case AccountingSlipImagePositionEnum.Header:
    default:
      return "レシート上部";
  }
};
