import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { FormInstance, Radio, RadioChangeEvent, Space } from "antd";
import { isEmptyNumericFilter } from "models/customerSegment";
import { isNumber } from "util/type/primitive";

import { InputNumber } from "components/Input/InputNumber";
import {
  EditMessageDeliveryFormItem,
  EditMessageDeliveryFormValues,
} from "pages/EditMessageDelivery/EditMessageDeliveryForm/useEditMessageDeliveryForm";

import { lessThanOrEqualFieldTransformer, moreThanOrEqualFieldTransformer } from "./functions";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  form: FormInstance<EditMessageDeliveryFormValues>;
};

export const BirthdayCountFilter = memo<Props>(({ form }) => {
  const [shouldFilter, setShouldFilter] = useState<"true" | "false">(
    isEmptyNumericFilter(form.getFieldValue(["birthdayCountFilter"])) ? "false" : "true",
  );

  const onChangeRadio = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;

    if (value === "true" || value === "false") {
      setShouldFilter(value);
    }
  }, []);

  const revalidate = useCallback(
    () => form.validateFields([["birthdayCountFilter", "lessThanOrEqual"]]),
    [form],
  );

  const validator = useCallback(async () => {
    const moreThanOrEqual = form.getFieldValue(["birthdayCountFilter", "moreThanOrEqual"]);
    const lessThanOrEqual = form.getFieldValue(["birthdayCountFilter", "lessThanOrEqual"]);

    if (shouldFilter === "true" && !isNumber(moreThanOrEqual) && !isNumber(lessThanOrEqual)) {
      throw new Error("誕生日までの日数を入力してください");
    }

    if (
      (isNumber(moreThanOrEqual) && !isNumber(lessThanOrEqual)) ||
      (!isNumber(moreThanOrEqual) && isNumber(lessThanOrEqual))
    ) {
      throw new Error("誕生日までの日数は以上と以下の両方の日数を入力してください");
    }

    if (
      isNumber(lessThanOrEqual) &&
      isNumber(moreThanOrEqual) &&
      lessThanOrEqual < moreThanOrEqual
    ) {
      throw new Error("誕生日までの日数は以下の方に大きい値を入力してください");
    }
  }, [form, shouldFilter]);

  return (
    <Content>
      <EditMessageDeliveryFormItem.NonProperty>
        <Radio.Group value={shouldFilter} onChange={onChangeRadio}>
          <Space direction="vertical">
            <Radio value="false">絞り込まない</Radio>
            <Radio value="true">絞り込む</Radio>
          </Space>
        </Radio.Group>
      </EditMessageDeliveryFormItem.NonProperty>
      <Space size="small">
        <EditMessageDeliveryFormItem
          noStyle
          name={["birthdayCountFilter", "moreThanOrEqual"]}
          rules={[
            ({ getFieldValue, setFieldsValue }) => ({
              transform(value: string | null) {
                moreThanOrEqualFieldTransformer({
                  value,
                  name: "birthdayCountFilter",
                  getFieldValue,
                  setFieldsValue,
                });
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: "88px" }}
            min={1}
            disabled={shouldFilter === "false"}
            onChange={revalidate}
          />
        </EditMessageDeliveryFormItem>
        日<span>〜</span>
        <EditMessageDeliveryFormItem
          noStyle
          name={["birthdayCountFilter", "lessThanOrEqual"]}
          rules={[
            ({ getFieldValue, setFieldsValue }) => ({
              transform(value: string | null) {
                lessThanOrEqualFieldTransformer({
                  value,
                  name: "birthdayCountFilter",
                  getFieldValue,
                  setFieldsValue,
                });
              },
            }),
            () => ({ validator }),
          ]}
        >
          <InputNumber style={{ width: "88px" }} min={1} disabled={shouldFilter === "false"} />
        </EditMessageDeliveryFormItem>
        日
      </Space>
    </Content>
  );
});
