import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import {
  useEditMenusBulkGetCompanyQuery,
  useEditMenusBulkGetMenusQuery,
  useUpdateMenusBulkMutation,
} from "pages/EditMenusBulk/queries";
import { DashboardAccountRoleTypeEnum, UpdateCategoryMenusBulkInput } from "types/graphql";

import { EditMenusBulkForm } from "./EditMenusBulkForm";

export const EditMenusBulk = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [company] = useCompany();
  const companyId = company?.id;
  const { role } = useCurrentUserPermissions();

  const {
    data: getMenusData,
    loading: loadingMenus,
    refetch: refetchMenus,
  } = useEditMenusBulkGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });

  const { data: getCompanyData } = useEditMenusBulkGetCompanyQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const defaultCostTaxMethod = getCompanyData?.company?.[0]?.defaultCostTaxMethod;

  const [updateMenusPricesMutation, { loading: loadingUpdateMenusPrices }] =
    useUpdateMenusBulkMutation();

  const onSubmit = useCallback(
    async (input: UpdateCategoryMenusBulkInput) => {
      try {
        await updateMenusPricesMutation({
          variables: { input },
        });

        refetchMenus({ companyId });
        message.success("編集を保存しました");
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [companyId, goBack, refetchMenus, updateMenusPricesMutation],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("編集に失敗しました");
    },
    [],
  );

  const shouldShowManagingShopAlert = role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title="一括設定"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <PageHeader title="一括設定" onBack={goBack} />

      {shouldShowManagingShopAlert && (
        <>
          <Alert
            type="warning"
            message="管理店舗以外で取り扱われているメニューの一括編集は行えません。"
            description="編集したい場合は法人管理者または業態管理者に依頼してください。"
          />
          <Spacer size={16} />
        </>
      )}

      <EditMenusBulkForm
        companyId={companyId}
        getMenusData={getMenusData}
        defaultCostTaxMethod={defaultCostTaxMethod}
        loading={loadingMenus || loadingUpdateMenusPrices}
        onSubmit={onSubmit}
        onClose={goBack}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
