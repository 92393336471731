import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useShop } from "hooks/useShop";
import {
  useAddDishUpSlipGroupPlanModalGetPlansQuery,
  useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation,
} from "pages/DishUpSlipGroupPlans/AddDishUpSlipGroupPlanModal/queries";
import { DishUpSlipGroup } from "pages/DishUpSlipGroupPlans/types";

import { AddDishUpSlipGroupPlanFormValues } from "./AddDishUpSlipGroupPlanForm/useAddDishUpSlipGroupPlanForm";
import { AddPlanForm } from "./AddDishUpSlipGroupPlanForm";

type Props = {
  dishUpSlipGroup: DishUpSlipGroup;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddDishUpSlipGroupPlanModal = memo<Props>(({ dishUpSlipGroup, goBack, onUpdated }) => {
  const dishUpSlipGroupId = dishUpSlipGroup.dishUpSlipGroupId;
  const _dishUpSlipGroupId = dishUpSlipGroup.id;

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data: getPlansData, error } = useAddDishUpSlipGroupPlanModalGetPlansQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const plans = useMemo(() => getPlansData?.plan ?? [], [getPlansData?.plan]);

  const [addDishUpSlipGroupPlansPlan, { loading: loadingInsertDishUpSlipGroupPlansPlan }] =
    useAddDishUpSlipGroupPlanModalInsertAddDishUpSlipGroupPlansPlanMutation();

  const [formValues, setFormValues] = useState<AddDishUpSlipGroupPlanFormValues | null>(null);
  const onOk = useCallback(async () => {
    if (formValues && shopId) {
      try {
        const { plans: planIds } = formValues;
        const dishUpSlipGroupShopPlans = planIds.map((planId) => {
          const plan = plans.find(({ planId: _planId }) => _planId === planId);

          if (!plan) throw new Error("plan not found");

          return {
            dishUpSlipGroupId,
            _dishUpSlipGroupId,
            planId: plan.id,
            _planId: plan.planId,
            shopId,
          };
        });
        const { data: addDishUpSlipGroupPlansPlanData } = await addDishUpSlipGroupPlansPlan({
          variables: { dishUpSlipGroupShopPlans },
        });
        const returning =
          addDishUpSlipGroupPlansPlanData?.insert_dishUpSlipGroupShopPlans?.returning;

        if (returning?.length === 0) {
          throw new Error("");
        }

        onUpdated();

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
    }

    goBack();
  }, [
    formValues,
    shopId,
    goBack,
    addDishUpSlipGroupPlansPlan,
    onUpdated,
    plans,
    dishUpSlipGroupId,
    _dishUpSlipGroupId,
  ]);
  const onCancel = goBack;

  return (
    <Modal
      title="プラン追加"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button
          key="add"
          type="primary"
          onClick={onOk}
          loading={loadingInsertDishUpSlipGroupPlansPlan}
          disabled={formValues === null || formValues.plans.length === 0}
        >
          追加
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddPlanForm dishUpSlipGroup={dishUpSlipGroup} plans={plans} onChange={setFormValues} />
    </Modal>
  );
});
