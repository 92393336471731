import React from "react";

import { Select } from "components/Input/Select";

import { EditTableFormItem } from "../useEditTableForm";

type Props = {
  tableAreas: {
    id: string;
    name: string;
  }[];
};

export const TableAreaSelectField = React.memo<Props>(({ tableAreas }) => (
  <EditTableFormItem
    label="所属エリア名"
    name="tableAreaId"
    endSpacer={null}
    rules={[{ required: true, message: "所属エリアを選択してください" }]}
  >
    <Select
      options={tableAreas.map(({ id, name }) => ({ label: name, value: id }))}
      showSearch
      optionFilterProp="label"
      allowClear
      fullWidth
    />
  </EditTableFormItem>
));
