import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { AddPlanGroupForm } from "pages/AddPlanGroup/AddPlanGroupForm";
import { useAddPlanGroupInsertPlanGroupMutation } from "pages/AddPlanGroup/queries";
import { PlanGroupInsertInput } from "types/graphql";

export const AddPlanGroup = () => {
  const navigate = useNavigate();

  const [addPlanGroupMutation, { loading: loadingInsertPlanGroup }] =
    useAddPlanGroupInsertPlanGroupMutation();

  const onSubmit = useCallback(
    async (planGroup: PlanGroupInsertInput) => {
      try {
        const planGroups = [planGroup];
        const { data: addPlanGroupData } = await addPlanGroupMutation({
          variables: { planGroups },
        });
        const planGroupId = addPlanGroupData?.insert_planGroup?.returning?.[0]?.planGroupId;

        if (planGroupId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");
        navigate(`/planGroup/${planGroupId}/plan`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addPlanGroupMutation, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="プラングループを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラングループ" }],
      }}
    >
      <PageHeader title="プラングループを新規作成" onBack={goBack} />
      <AddPlanGroupForm onSubmit={onSubmit} onClose={goBack} loading={loadingInsertPlanGroup} />
    </DashboardLayout>
  );
};
