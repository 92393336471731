import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { AdyenPaymentMethodTypeEnum, PaymentProviderType } from "types/graphql";

import {
  AdyenPaymentConfig,
  AdyenTerminalPaymentConfig,
  GmoOffPaymentConfig,
  GmoShopConfigDetail,
} from "../types";

const getOnlinePaymentConfigInitialValues = ({
  adyenPaymentConfig,
  gmoShopConfigDetail,
}: {
  adyenPaymentConfig: AdyenPaymentConfig | null;
  gmoShopConfigDetail: GmoShopConfigDetail | null;
}): EditOnlinePaymentConfigFormValues => {
  if (adyenPaymentConfig) {
    const adyenTransactionFeeRateMap = new Map(
      adyenPaymentConfig.adyenPaymentShopPaymentMethods.map(
        ({ paymentMethod, transactionFeeRate }) => [paymentMethod, transactionFeeRate],
      ),
    );
    return {
      paymentProvider: PaymentProviderType.Adyen,
      visaCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.VisaCard) ?? null,
      masterCardFeeRate:
        adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.MasterCard) ?? null,
      jcbCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.JcbCard) ?? null,
      amexCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.AmexCard) ?? null,
      dinersCardFeeRate:
        adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.DinersCard) ?? null,
    };
  }

  if (gmoShopConfigDetail?.gmoShopId && gmoShopConfigDetail?.gmoShopPassword) {
    return {
      paymentProvider: PaymentProviderType.Gmo,
      gmoShopId: gmoShopConfigDetail.gmoShopId,
      gmoShopPassword: gmoShopConfigDetail.gmoShopPassword,
      visaCardFeeRate: gmoShopConfigDetail.visaCardFeeRate ?? null,
      masterCardFeeRate: gmoShopConfigDetail.masterCardFeeRate ?? null,
      jcbCardFeeRate: gmoShopConfigDetail.jcbCardFeeRate ?? null,
      amexCardFeeRate: gmoShopConfigDetail.amexCardFeeRate ?? null,
      dinersCardFeeRate: gmoShopConfigDetail.dinersCardFeeRate ?? null,
    };
  }

  return {
    paymentProvider: null,
    visaCardFeeRate: null,
    masterCardFeeRate: null,
    jcbCardFeeRate: null,
    amexCardFeeRate: null,
    dinersCardFeeRate: null,
  };
};

const getTerminalPaymentInitialValues = ({
  adyenTerminalPaymentConfig,
  gmoOffPaymentConfig,
}: {
  adyenTerminalPaymentConfig: AdyenTerminalPaymentConfig | null;
  gmoOffPaymentConfig: GmoOffPaymentConfig | null;
}): EditTerminalPaymentConfigFormValues => {
  const adyenTransactionFeeRateMap = new Map(
    adyenTerminalPaymentConfig?.adyenTerminalPaymentShopPaymentMethods.map(
      ({ paymentMethod, transactionFeeRate }) => [paymentMethod, transactionFeeRate],
    ),
  );
  return {
    useAdyenTerminalPayment: Boolean(adyenTerminalPaymentConfig),
    visaCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.VisaCard) ?? null,
    masterCardFeeRate:
      adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.MasterCard) ?? null,
    jcbCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.JcbCard) ?? null,
    amexCardFeeRate: adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.AmexCard) ?? null,
    dinersCardFeeRate:
      adyenTransactionFeeRateMap.get(AdyenPaymentMethodTypeEnum.DinersCard) ?? null,
    useGmoTerminalPayment: Boolean(gmoOffPaymentConfig?.isEnabled),
    gmoTerminalId: gmoOffPaymentConfig?.terminalId ?? null,
  };
};

const getInitialValues = ({
  gmoShopConfigDetail,
  gmoOffPaymentConfig,
  adyenPaymentConfig,
  adyenTerminalPaymentConfig,
}: {
  gmoShopConfigDetail: GmoShopConfigDetail | null;
  gmoOffPaymentConfig: GmoOffPaymentConfig | null;
  adyenPaymentConfig: AdyenPaymentConfig | null;
  adyenTerminalPaymentConfig: AdyenTerminalPaymentConfig | null;
}): {
  onlinePaymentConfig: Partial<EditOnlinePaymentConfigFormValues>;
  terminalPaymentConfig: Partial<EditTerminalPaymentConfigFormValues>;
} => ({
  onlinePaymentConfig: getOnlinePaymentConfigInitialValues({
    adyenPaymentConfig,
    gmoShopConfigDetail,
  }),
  terminalPaymentConfig: getTerminalPaymentInitialValues({
    adyenTerminalPaymentConfig,
    gmoOffPaymentConfig,
  }),
});

type EditCardFeeRateFormValues = {
  visaCardFeeRate: number | null;
  masterCardFeeRate: number | null;
  jcbCardFeeRate: number | null;
  amexCardFeeRate: number | null;
  dinersCardFeeRate: number | null;
};

export type EditAdyenOnlinePaymentConfigFormValues = {
  paymentProvider: PaymentProviderType.Adyen | null;
};

export type EditGmoOnlinePaymentConfigFormValues = {
  paymentProvider: PaymentProviderType.Gmo | null;
  gmoShopId: string;
  gmoShopPassword: string;
};

export type EditAdyenTerminalPaymentConfigFormValues = {
  useAdyenTerminalPayment: boolean;
} & EditCardFeeRateFormValues;

export type EditGmoTerminalPaymentConfigFormValues = {
  useGmoTerminalPayment: boolean;
  gmoTerminalId: string | null;
};

type EditOnlinePaymentConfigFormValues = (
  | EditAdyenOnlinePaymentConfigFormValues
  | EditGmoOnlinePaymentConfigFormValues
) &
  EditCardFeeRateFormValues;

type EditTerminalPaymentConfigFormValues = EditAdyenTerminalPaymentConfigFormValues &
  EditGmoTerminalPaymentConfigFormValues;

export type EditDiniiPayConfigFormValues = {
  onlinePaymentConfig: EditOnlinePaymentConfigFormValues;
  terminalPaymentConfig: EditTerminalPaymentConfigFormValues;
};

export const isGmoOnlinePaymentConfig = (
  onlinePaymentConfig: EditOnlinePaymentConfigFormValues,
): onlinePaymentConfig is EditCardFeeRateFormValues & EditGmoOnlinePaymentConfigFormValues =>
  onlinePaymentConfig.paymentProvider === PaymentProviderType.Gmo;

export const EditDiniiPayConfigFormItem = createFormItem<EditDiniiPayConfigFormValues>();

export const useEditDiniiPayConfigForm = ({
  gmoShopConfigDetail,
  gmoOffPaymentConfig,
  adyenPaymentConfig,
  adyenTerminalPaymentConfig,
  onSubmit,
}: {
  gmoShopConfigDetail: GmoShopConfigDetail | null;
  gmoOffPaymentConfig: GmoOffPaymentConfig | null;
  adyenPaymentConfig: AdyenPaymentConfig | null;
  adyenTerminalPaymentConfig: AdyenTerminalPaymentConfig | null;
  onSubmit: (form: EditDiniiPayConfigFormValues) => void;
}) => {
  const [form] = Form.useForm<EditDiniiPayConfigFormValues>();
  const initialValues = getInitialValues({
    gmoShopConfigDetail,
    gmoOffPaymentConfig,
    adyenPaymentConfig,
    adyenTerminalPaymentConfig,
  });

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue() as EditDiniiPayConfigFormValues);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
