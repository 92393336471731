import React, { memo } from "react";
import { Button } from "antd";

import { EditMenuWinboardMenuFormItem } from "../useEditMenuWinboardMenuForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const WinboardGetAvailableMenuCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed }) => (
    <EditMenuWinboardMenuFormItem label=" " name="winboardGetAvailableMenuCdButton">
      <Button onClick={handleGetAvailableMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditMenuWinboardMenuFormItem>
  ),
);
