import { grey } from "constants/colors";
import { MembershipCardAppearanceTypeEnum } from "types/graphql";

import JapaneseGoldImg from "../assets/crm-members-card/background-japan-gold.png";
import JapanesePlatinumImg from "../assets/crm-members-card/background-japan-platinum.png";
import JapaneseRegularImg from "../assets/crm-members-card/background-japan-regular.png";
import JapaneseSilverImg from "../assets/crm-members-card/background-japan-silver.png";
import StandardGoldImg from "../assets/crm-members-card/background-standard-gold.png";
import StandardPlatinumImg from "../assets/crm-members-card/background-standard-platinum.png";
import StandardRegularImg from "../assets/crm-members-card/background-standard-regular.png";
import StandardSilverImg from "../assets/crm-members-card/background-standard-silver.png";

export type AppearanceTypeEnumOrEmptyString = MembershipCardAppearanceTypeEnum | "";

export type CardRank = "bronze" | "silver" | "gold" | "platinum";

type CardCategory = Record<MembershipCardAppearanceTypeEnum, { imageUrl: string; color: string }>;

type Card = { requiredPoints: number; categories: CardCategory };

export const cardRankToCardMap: Record<CardRank, Card> = {
  bronze: {
    requiredPoints: 0,
    categories: {
      standard: {
        imageUrl: StandardRegularImg,
        color: grey[9],
      },
      japanese: {
        imageUrl: JapaneseRegularImg,
        color: grey[9],
      },
    },
  },
  silver: {
    requiredPoints: 3,
    categories: {
      standard: {
        imageUrl: StandardSilverImg,
        color: grey[0],
      },
      japanese: {
        imageUrl: JapaneseSilverImg,
        color: grey[9],
      },
    },
  },
  gold: {
    requiredPoints: 7,
    categories: {
      standard: {
        imageUrl: StandardGoldImg,
        color: grey[9],
      },
      japanese: {
        imageUrl: JapaneseGoldImg,
        color: grey[9],
      },
    },
  },
  platinum: {
    requiredPoints: 13,
    categories: {
      standard: {
        imageUrl: StandardPlatinumImg,
        color: grey[0],
      },
      japanese: {
        imageUrl: JapanesePlatinumImg,
        color: grey[0],
      },
    },
  },
};

export const englishCardTypeToJapaneseCardTypeMap: Record<
  MembershipCardAppearanceTypeEnum,
  string
> = {
  standard: "スタンダード",
  japanese: "ジャパニーズ",
};
