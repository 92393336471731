import React, { memo } from "react";
import { Col, Row } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { InputCode } from "components/Input/InputCode";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ContactInfoField = memo<Props>((props) => (
  <Row>
    <Col span={12}>
      <EditCashRegisterConfigFormItem
        label={<FormHelp label="連絡先情報" help="電話番号等の連絡先情報をご入力ください" />}
        name="contactInfo"
        wrapperCol={{ span: 20 }}
        {...props}
      >
        <InputCode placeholder="080-0000-1234" />
      </EditCashRegisterConfigFormItem>
    </Col>
  </Row>
));
