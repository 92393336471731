import React, { memo, useMemo } from "react";
import { Space, Typography } from "antd";
import {
  findSortKeyByDayWeek,
  formatOrderableTimeTermDayWeek,
  formatOrderableTimeWithChangeDateTime,
  OrderableTimeTermDayWeekType,
} from "models/orderableTime";
import { groupBy } from "util/array";
import { normalizeDuration } from "util/duration";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { Shop, ShopOrderableTimeTerm } from "pages/OrderableTimeShops/type";

type ShopAndShopOrderableTimeTerms = {
  shop: Shop;
  shopOrderableTimeTerms: ShopOrderableTimeTerm[];
};

type Props = {
  loading?: boolean;
  shops: Shop[];
  orderableTimeId?: string;
  shopOrderableTimeTerms: ShopOrderableTimeTerm[];
};

export const OrderableTimeShopsTable = memo<Props>(
  ({ loading, shops, orderableTimeId, shopOrderableTimeTerms }) => {
    const dataSource: ShopAndShopOrderableTimeTerms[] = useMemo(() => {
      const groupedShopOrderableTimeTerms = groupBy(shopOrderableTimeTerms, ({ shopId }) => shopId);

      return shops.map((shop) => {
        const shopOrderableTimeTerms = groupedShopOrderableTimeTerms[shop.shopId] ?? [];

        return { shop, shopOrderableTimeTerms };
      });
    }, [shops, shopOrderableTimeTerms]);

    const [pagination, setPagination] = usePagination();

    const columns = [
      {
        title: "店舗名",
        render(_: unknown, { shop }: ShopAndShopOrderableTimeTerms) {
          return shop.name;
        },
      },
      {
        title: "アプリ表示時間",
        render(_: string, { shopOrderableTimeTerms }: ShopAndShopOrderableTimeTerms) {
          return (
            <Space direction="vertical">
              {shopOrderableTimeTerms
                .slice()
                .sort(
                  (a, b) =>
                    findSortKeyByDayWeek(a.dayWeek as OrderableTimeTermDayWeekType) -
                    findSortKeyByDayWeek(b.dayWeek as OrderableTimeTermDayWeekType),
                )
                .map((term) => {
                  const dayWeekName = formatOrderableTimeTermDayWeek(term.dayWeek);
                  const { start, end } = formatOrderableTimeWithChangeDateTime({
                    ...normalizeDuration(term),
                    changeDateTime: term.shop.changeDateTime,
                  });
                  return (
                    <Typography.Text key={term.id}>
                      {dayWeekName}: {start} ~ {end}
                    </Typography.Text>
                  );
                })}
            </Space>
          );
        },
      },
      {
        title: "",
        width: 60,
        align: "center",
        fixed: "right",
        render(_: string, { shop }: ShopAndShopOrderableTimeTerms) {
          return orderableTimeId ? (
            <IconLink to={`/orderableTime/${orderableTimeId}/shop/${shop.shopId}/edit`}>
              <EditIcon />
            </IconLink>
          ) : null;
        },
      } as const,
    ];

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
