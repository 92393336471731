import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OptionsGetOptions = gql`
    query OptionsGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftOptionMeta: {}}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    maxChoiceNum
    minChoiceNum
    receiptDisplayName
    inputType
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {menu: {menuId: asc}}
    ) {
      menu {
        menuId
        name
        shopSideName
      }
    }
  }
}
    `;
export const OptionsDeleteOption = gql`
    mutation OptionsDeleteOption($optionId: Int!, $archivedAt: timestamptz!) {
  update_option(
    _set: {archivedAt: $archivedAt}
    where: {optionId: {_eq: $optionId}}
  ) {
    affected_rows
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuChoices: {choice: {_optionId: {_eq: $optionId}}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_winboardMenu(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_shopOptionKitchenRole(where: {option: {optionId: {_eq: $optionId}}}) {
    affected_rows
  }
}
    `;
export type OptionsGetOptionsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OptionsGetOptionsQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name' | 'maxChoiceNum' | 'minChoiceNum' | 'receiptDisplayName' | 'inputType'>
    & { choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'name'>
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name' | 'shopSideName'>
      ) }
    )> }
  )> }
);

export type OptionsDeleteOptionMutationVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type OptionsDeleteOptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_option?: Types.Maybe<(
    { __typename?: 'option_mutation_response' }
    & Pick<Types.OptionMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenuChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuChoice_mutation_response' }
    & Pick<Types.PikaichiMenuChoiceMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationMenu?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu_mutation_response' }
    & Pick<Types.TecAggregationMenuMutationResponse, 'affected_rows'>
  )>, delete_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )>, delete_shopOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopOptionKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);


export const OptionsGetOptionsDocument = gql`
    query OptionsGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftOptionMeta: {}}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    maxChoiceNum
    minChoiceNum
    receiptDisplayName
    inputType
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      name
    }
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: {menu: {menuId: asc}}
    ) {
      menu {
        menuId
        name
        shopSideName
      }
    }
  }
}
    `;

/**
 * __useOptionsGetOptionsQuery__
 *
 * To run a query within a React component, call `useOptionsGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionsGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionsGetOptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOptionsGetOptionsQuery(baseOptions: Apollo.QueryHookOptions<OptionsGetOptionsQuery, OptionsGetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptionsGetOptionsQuery, OptionsGetOptionsQueryVariables>(OptionsGetOptionsDocument, options);
      }
export function useOptionsGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptionsGetOptionsQuery, OptionsGetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptionsGetOptionsQuery, OptionsGetOptionsQueryVariables>(OptionsGetOptionsDocument, options);
        }
export type OptionsGetOptionsQueryHookResult = ReturnType<typeof useOptionsGetOptionsQuery>;
export type OptionsGetOptionsLazyQueryHookResult = ReturnType<typeof useOptionsGetOptionsLazyQuery>;
export type OptionsGetOptionsQueryResult = Apollo.QueryResult<OptionsGetOptionsQuery, OptionsGetOptionsQueryVariables>;
export const OptionsDeleteOptionDocument = gql`
    mutation OptionsDeleteOption($optionId: Int!, $archivedAt: timestamptz!) {
  update_option(
    _set: {archivedAt: $archivedAt}
    where: {optionId: {_eq: $optionId}}
  ) {
    affected_rows
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuChoices: {choice: {_optionId: {_eq: $optionId}}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuChoice(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_winboardMenu(where: {choice: {_optionId: {_eq: $optionId}}}) {
    affected_rows
  }
  delete_shopOptionKitchenRole(where: {option: {optionId: {_eq: $optionId}}}) {
    affected_rows
  }
}
    `;
export type OptionsDeleteOptionMutationFn = Apollo.MutationFunction<OptionsDeleteOptionMutation, OptionsDeleteOptionMutationVariables>;

/**
 * __useOptionsDeleteOptionMutation__
 *
 * To run a mutation, you first call `useOptionsDeleteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptionsDeleteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optionsDeleteOptionMutation, { data, loading, error }] = useOptionsDeleteOptionMutation({
 *   variables: {
 *      optionId: // value for 'optionId'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useOptionsDeleteOptionMutation(baseOptions?: Apollo.MutationHookOptions<OptionsDeleteOptionMutation, OptionsDeleteOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptionsDeleteOptionMutation, OptionsDeleteOptionMutationVariables>(OptionsDeleteOptionDocument, options);
      }
export type OptionsDeleteOptionMutationHookResult = ReturnType<typeof useOptionsDeleteOptionMutation>;
export type OptionsDeleteOptionMutationResult = Apollo.MutationResult<OptionsDeleteOptionMutation>;
export type OptionsDeleteOptionMutationOptions = Apollo.BaseMutationOptions<OptionsDeleteOptionMutation, OptionsDeleteOptionMutationVariables>;