import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Modal, Typography } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getPositionText } from "models/accountingSlipImagePosition";

import { message } from "components/antd/message";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { getClippedUrl } from "libs/imgix";
import { AccountingSlipImagePositionEnum } from "types/graphql";

import { AccountingSlipImage } from "../../types";

import { useAccountingSlipImageTableDeleteAccountingSlipImageMutation } from "./queries";

type AccountingSlipImageItem = {
  position: AccountingSlipImagePositionEnum;
  accountingSlipImage?: AccountingSlipImage;
};

type Props = {
  shopId: string;
  headerImage?: AccountingSlipImage;
  footerImage?: AccountingSlipImage;
  refetchShop: () => void;
};

const Image = styled.img`
  width: 240px;
  object-fit: cover;
`;

const TableTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const makeColumns = (param: {
  shopId: string;
  deleteImage: (param: { accountingSlipImageId: string }) => void;
}): ColumnsType<AccountingSlipImageItem> => [
  {
    title: "位置",
    align: "center",
    width: 300,
    render(_: string, { position }: AccountingSlipImageItem) {
      return getPositionText(position);
    },
  },
  {
    title: "画像",
    align: "center",
    width: 360,
    render(_: string, { accountingSlipImage, position }: AccountingSlipImageItem) {
      if (!accountingSlipImage) {
        return (
          <Link
            to={`/shop/${param.shopId}/cashRegisterConfig/edit/accountingSlipImage/add/${position}`}
          >
            <Button icon={<PlusOutlined />}>画像を追加</Button>
          </Link>
        );
      }
      const url = getClippedUrl({ url: accountingSlipImage.imageUrl, w: 720, h: 720 });
      return url ? <Image src={url} /> : null;
    },
  },
  {
    title: "コメント",
    render(_: string, { accountingSlipImage }: AccountingSlipImageItem) {
      if (!accountingSlipImage) return null;
      return accountingSlipImage.description;
    },
  },
  {
    title: "",
    align: "center",
    width: 100,
    render(_: string, { accountingSlipImage }: AccountingSlipImageItem) {
      if (!accountingSlipImage) return null;
      const { accountingSlipImageId } = accountingSlipImage;
      return (
        <IconLink
          to={`/shop/${param.shopId}/cashRegisterConfig/edit/accountingSlipImage/${accountingSlipImageId}/edit`}
        >
          <EditIcon />
        </IconLink>
      );
    },
  },
  {
    title: "",
    align: "center",
    width: 100,
    render(_: string, { accountingSlipImage }: AccountingSlipImageItem) {
      if (!accountingSlipImage) {
        return null;
      }

      const { accountingSlipImageId } = accountingSlipImage;
      return (
        <DeleteIcon
          onClick={() =>
            Modal.confirm({
              title: "削除しますか？",
              icon: <ExclamationCircleOutlined />,
              okText: "はい",
              cancelText: "キャンセル",
              onOk: () => param.deleteImage({ accountingSlipImageId }),
            })
          }
        />
      );
    },
  },
];

const showDeleteSuccessMessage = () => message.success("削除しました");
const showDeleteErrorMessage = () => message.success("削除に失敗しました");

export const AccountingSlipImageTable = memo<Props>(
  ({ headerImage, footerImage, shopId, refetchShop }) => {
    const [deleteAccountingSlipMutation] =
      useAccountingSlipImageTableDeleteAccountingSlipImageMutation({
        onCompleted: showDeleteSuccessMessage,
        onError: showDeleteErrorMessage,
      });

    const deleteImage = useCallback(
      async ({ accountingSlipImageId }: { accountingSlipImageId: string }) => {
        await deleteAccountingSlipMutation({
          variables: {
            accountingSlipImageId,
          },
        });
        await refetchShop();
      },
      [deleteAccountingSlipMutation, refetchShop],
    );

    const data = useMemo(
      () => [
        { position: AccountingSlipImagePositionEnum.Header, accountingSlipImage: headerImage },
        { position: AccountingSlipImagePositionEnum.Footer, accountingSlipImage: footerImage },
      ],
      [headerImage, footerImage],
    );

    const columns = useMemo(() => makeColumns({ shopId, deleteImage }), [shopId, deleteImage]);

    return (
      <Table
        rowKey="accountingSlipImageId"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        title={() => (
          <TableTitle>
            <Typography>会計レシート画像設定</Typography>
            <Link to={`/shop/${shopId}/cashRegisterConfig/edit/accountingSlipImage/preview`}>
              <Button>プレビュー</Button>
            </Link>
          </TableTitle>
        )}
      />
    );
  },
);
