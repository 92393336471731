import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";
import { Coupon, CouponTypeEnum } from "types/graphql";

import { Coupon as SourceCoupon, Menu } from "../types";

type CommonCouponFormValues = Pick<
  Coupon,
  | "availableDays"
  | "availableFrom"
  | "availableUntil"
  | "content"
  | "description"
  | "name"
  | "termsOfUse"
  | "title"
> & {
  isDisabledAvailableDays: boolean;
  isDisabledAvailableUntil: boolean;
};

type MenuDiscountTypeCouponFormValues = {
  [CouponTypeEnum.MenuDiscount]?: Pick<Menu, "menuId" | "taxMethod" | "taxRate"> & {
    normalPrice: number;
    discountedPrice: number;
  };
};

type TicketTypeCouponFormValues = {
  [CouponTypeEnum.Ticket]?: Pick<Coupon, "imageUrl">;
};

export type AddCouponFormValues = Pick<Coupon, "couponType"> &
  CommonCouponFormValues &
  MenuDiscountTypeCouponFormValues &
  TicketTypeCouponFormValues;

const getInitialValues = ({ sourceCoupon }: { sourceCoupon: SourceCoupon | undefined }) => {
  if (!sourceCoupon) {
    return {
      isDisabledAvailableDays: false,
      isDisabledAvailableUntil: false,
      imageUrl: null,
    };
  }

  const {
    id: _,
    imageUrl,
    availableFrom,
    availableUntil,
    availableDays,
    couponMenuDiscount,
    ...coupon
  } = sourceCoupon;

  return {
    ...coupon,
    availableFrom: dayjs(availableFrom),
    availableUntil: availableUntil && dayjs(availableUntil),
    isDisabledAvailableUntil: !availableUntil,
    availableDays,
    isDisabledAvailableDays: !availableDays,
    imageUrl: imageUrl ?? null,
    menuDiscount: couponMenuDiscount && {
      menuId: couponMenuDiscount.menuId,
      discountedPrice: couponMenuDiscount.discountedPrice,
    },
  };
};

export const AddCouponFormItem = createFormItem<AddCouponFormValues>();

export const useAddCouponForm = ({
  sourceCoupon,
  onSubmit,
}: {
  sourceCoupon: SourceCoupon | undefined;
  onSubmit: ({ coupon }: { coupon: AddCouponFormValues }) => void;
}) => {
  const [form] = Form.useForm<AddCouponFormValues>();
  const initialValues = getInitialValues({ sourceCoupon });

  const submit = useCallback(
    ({ imageUrl }: { imageUrl?: string }) => {
      const formValues = form.getFieldsValue() as AddCouponFormValues;
      const availableFrom = dayjs(formValues.availableFrom).format("YYYY-MM-DD HH:mmZZ");
      const availableUntil =
        formValues.availableUntil &&
        dayjs(formValues.availableUntil).format("YYYY-MM-DD HH:mm:59ZZ");

      onSubmit({
        coupon: { ...formValues, availableFrom, availableUntil, ticket: { imageUrl } },
      });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
