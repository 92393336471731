import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCashRegisterConfigGetShop = gql`
    query EditCashRegisterConfigGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    contactInfo
    disableClerkCache
    enableDefaultInflowSourceTag
    enableReserveCashSeparation
    enableAutoFractionalDiscount
    hideFreeItemInAccountingSlip
    issuer
    shouldOpenDrawerAtOnSitePaymentEveryTime
    eligibleInvoiceIssuerRegistrationNumber
    enableAutoPrintReceipt
    taxOfficeId
    changeDateTime
    cashRegisterSecurityLevel
    accountingSlipImages(order_by: [{position: desc}, {priority: asc}]) {
      accountingSlipImageId: id
      position
      imageUrl
      description
      priority
    }
    cashRegisterConfig {
      enableRequiringPasswordForDrawerOpen
      drawerOpenPassword
      useStera
    }
    serviceChargeConfig {
      id
      chargeRate
    }
    specificTimeSurchargeConfig {
      id
      chargeRate
      startTime
      endTime
    }
  }
}
    `;
export const EditCashRegisterConfigGetDiscountTypes = gql`
    query EditCashRegisterConfigGetDiscountTypes {
  onSitePaymentDiscountType {
    id
    type
  }
}
    `;
export const EditCashRegisterConfigUpdateShop = gql`
    mutation EditCashRegisterConfigUpdateShop($shopId: uuid!, $shop: shop_set_input!, $shopDiscountType: shopDiscountType_insert_input!, $cashRegisterConfig: cashRegisterConfig_insert_input!) {
  update_shop(
    _set: $shop
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
  insert_shopDiscountType_one(
    object: $shopDiscountType
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    __typename
  }
  insert_cashRegisterConfig_one(
    object: $cashRegisterConfig
    on_conflict: {constraint: cashRegisterConfig_shopId_key, update_columns: [enableRequiringPasswordForDrawerOpen, drawerOpenPassword, useStera]}
  ) {
    __typename
  }
}
    `;
export const EditCashRegisterConfigCreateSurchargeConfigs = gql`
    mutation EditCashRegisterConfigCreateSurchargeConfigs($input: CreateSurchargeConfigsInput!) {
  dashboardAccountCreateSurchargeConfigs(input: $input) {
    result
  }
}
    `;
export type EditCashRegisterConfigGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditCashRegisterConfigGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'companyId' | 'name' | 'contactInfo' | 'disableClerkCache' | 'enableDefaultInflowSourceTag' | 'enableReserveCashSeparation' | 'enableAutoFractionalDiscount' | 'hideFreeItemInAccountingSlip' | 'issuer' | 'shouldOpenDrawerAtOnSitePaymentEveryTime' | 'eligibleInvoiceIssuerRegistrationNumber' | 'enableAutoPrintReceipt' | 'taxOfficeId' | 'changeDateTime' | 'cashRegisterSecurityLevel'>
    & { accountingSlipImages: Array<(
      { __typename?: 'accountingSlipImage' }
      & Pick<Types.AccountingSlipImage, 'position' | 'imageUrl' | 'description' | 'priority'>
      & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
    )>, cashRegisterConfig?: Types.Maybe<(
      { __typename?: 'cashRegisterConfig' }
      & Pick<Types.CashRegisterConfig, 'enableRequiringPasswordForDrawerOpen' | 'drawerOpenPassword' | 'useStera'>
    )>, serviceChargeConfig?: Types.Maybe<(
      { __typename?: 'serviceChargeConfig' }
      & Pick<Types.ServiceChargeConfig, 'id' | 'chargeRate'>
    )>, specificTimeSurchargeConfig?: Types.Maybe<(
      { __typename?: 'specificTimeSurchargeConfig' }
      & Pick<Types.SpecificTimeSurchargeConfig, 'id' | 'chargeRate' | 'startTime' | 'endTime'>
    )> }
  )> }
);

export type EditCashRegisterConfigGetDiscountTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditCashRegisterConfigGetDiscountTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'id' | 'type'>
  )> }
);

export type EditCashRegisterConfigUpdateShopMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  shop: Types.ShopSetInput;
  shopDiscountType: Types.ShopDiscountTypeInsertInput;
  cashRegisterConfig: Types.CashRegisterConfigInsertInput;
}>;


export type EditCashRegisterConfigUpdateShopMutation = (
  { __typename?: 'mutation_root' }
  & { update_shop?: Types.Maybe<(
    { __typename?: 'shop_mutation_response' }
    & Pick<Types.ShopMutationResponse, 'affected_rows'>
  )>, insert_shopDiscountType_one?: Types.Maybe<{ __typename: 'shopDiscountType' }>, insert_cashRegisterConfig_one?: Types.Maybe<{ __typename: 'cashRegisterConfig' }> }
);

export type EditCashRegisterConfigCreateSurchargeConfigsMutationVariables = Types.Exact<{
  input: Types.CreateSurchargeConfigsInput;
}>;


export type EditCashRegisterConfigCreateSurchargeConfigsMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountCreateSurchargeConfigs: (
    { __typename?: 'CreateSurchargeConfigsResult' }
    & Pick<Types.CreateSurchargeConfigsResult, 'result'>
  ) }
);


export const EditCashRegisterConfigGetShopDocument = gql`
    query EditCashRegisterConfigGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    contactInfo
    disableClerkCache
    enableDefaultInflowSourceTag
    enableReserveCashSeparation
    enableAutoFractionalDiscount
    hideFreeItemInAccountingSlip
    issuer
    shouldOpenDrawerAtOnSitePaymentEveryTime
    eligibleInvoiceIssuerRegistrationNumber
    enableAutoPrintReceipt
    taxOfficeId
    changeDateTime
    cashRegisterSecurityLevel
    accountingSlipImages(order_by: [{position: desc}, {priority: asc}]) {
      accountingSlipImageId: id
      position
      imageUrl
      description
      priority
    }
    cashRegisterConfig {
      enableRequiringPasswordForDrawerOpen
      drawerOpenPassword
      useStera
    }
    serviceChargeConfig {
      id
      chargeRate
    }
    specificTimeSurchargeConfig {
      id
      chargeRate
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useEditCashRegisterConfigGetShopQuery__
 *
 * To run a query within a React component, call `useEditCashRegisterConfigGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCashRegisterConfigGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCashRegisterConfigGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditCashRegisterConfigGetShopQuery(baseOptions: Apollo.QueryHookOptions<EditCashRegisterConfigGetShopQuery, EditCashRegisterConfigGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCashRegisterConfigGetShopQuery, EditCashRegisterConfigGetShopQueryVariables>(EditCashRegisterConfigGetShopDocument, options);
      }
export function useEditCashRegisterConfigGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCashRegisterConfigGetShopQuery, EditCashRegisterConfigGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCashRegisterConfigGetShopQuery, EditCashRegisterConfigGetShopQueryVariables>(EditCashRegisterConfigGetShopDocument, options);
        }
export type EditCashRegisterConfigGetShopQueryHookResult = ReturnType<typeof useEditCashRegisterConfigGetShopQuery>;
export type EditCashRegisterConfigGetShopLazyQueryHookResult = ReturnType<typeof useEditCashRegisterConfigGetShopLazyQuery>;
export type EditCashRegisterConfigGetShopQueryResult = Apollo.QueryResult<EditCashRegisterConfigGetShopQuery, EditCashRegisterConfigGetShopQueryVariables>;
export const EditCashRegisterConfigGetDiscountTypesDocument = gql`
    query EditCashRegisterConfigGetDiscountTypes {
  onSitePaymentDiscountType {
    id
    type
  }
}
    `;

/**
 * __useEditCashRegisterConfigGetDiscountTypesQuery__
 *
 * To run a query within a React component, call `useEditCashRegisterConfigGetDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCashRegisterConfigGetDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCashRegisterConfigGetDiscountTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditCashRegisterConfigGetDiscountTypesQuery(baseOptions?: Apollo.QueryHookOptions<EditCashRegisterConfigGetDiscountTypesQuery, EditCashRegisterConfigGetDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCashRegisterConfigGetDiscountTypesQuery, EditCashRegisterConfigGetDiscountTypesQueryVariables>(EditCashRegisterConfigGetDiscountTypesDocument, options);
      }
export function useEditCashRegisterConfigGetDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCashRegisterConfigGetDiscountTypesQuery, EditCashRegisterConfigGetDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCashRegisterConfigGetDiscountTypesQuery, EditCashRegisterConfigGetDiscountTypesQueryVariables>(EditCashRegisterConfigGetDiscountTypesDocument, options);
        }
export type EditCashRegisterConfigGetDiscountTypesQueryHookResult = ReturnType<typeof useEditCashRegisterConfigGetDiscountTypesQuery>;
export type EditCashRegisterConfigGetDiscountTypesLazyQueryHookResult = ReturnType<typeof useEditCashRegisterConfigGetDiscountTypesLazyQuery>;
export type EditCashRegisterConfigGetDiscountTypesQueryResult = Apollo.QueryResult<EditCashRegisterConfigGetDiscountTypesQuery, EditCashRegisterConfigGetDiscountTypesQueryVariables>;
export const EditCashRegisterConfigUpdateShopDocument = gql`
    mutation EditCashRegisterConfigUpdateShop($shopId: uuid!, $shop: shop_set_input!, $shopDiscountType: shopDiscountType_insert_input!, $cashRegisterConfig: cashRegisterConfig_insert_input!) {
  update_shop(
    _set: $shop
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
  insert_shopDiscountType_one(
    object: $shopDiscountType
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    __typename
  }
  insert_cashRegisterConfig_one(
    object: $cashRegisterConfig
    on_conflict: {constraint: cashRegisterConfig_shopId_key, update_columns: [enableRequiringPasswordForDrawerOpen, drawerOpenPassword, useStera]}
  ) {
    __typename
  }
}
    `;
export type EditCashRegisterConfigUpdateShopMutationFn = Apollo.MutationFunction<EditCashRegisterConfigUpdateShopMutation, EditCashRegisterConfigUpdateShopMutationVariables>;

/**
 * __useEditCashRegisterConfigUpdateShopMutation__
 *
 * To run a mutation, you first call `useEditCashRegisterConfigUpdateShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCashRegisterConfigUpdateShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCashRegisterConfigUpdateShopMutation, { data, loading, error }] = useEditCashRegisterConfigUpdateShopMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      shop: // value for 'shop'
 *      shopDiscountType: // value for 'shopDiscountType'
 *      cashRegisterConfig: // value for 'cashRegisterConfig'
 *   },
 * });
 */
export function useEditCashRegisterConfigUpdateShopMutation(baseOptions?: Apollo.MutationHookOptions<EditCashRegisterConfigUpdateShopMutation, EditCashRegisterConfigUpdateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCashRegisterConfigUpdateShopMutation, EditCashRegisterConfigUpdateShopMutationVariables>(EditCashRegisterConfigUpdateShopDocument, options);
      }
export type EditCashRegisterConfigUpdateShopMutationHookResult = ReturnType<typeof useEditCashRegisterConfigUpdateShopMutation>;
export type EditCashRegisterConfigUpdateShopMutationResult = Apollo.MutationResult<EditCashRegisterConfigUpdateShopMutation>;
export type EditCashRegisterConfigUpdateShopMutationOptions = Apollo.BaseMutationOptions<EditCashRegisterConfigUpdateShopMutation, EditCashRegisterConfigUpdateShopMutationVariables>;
export const EditCashRegisterConfigCreateSurchargeConfigsDocument = gql`
    mutation EditCashRegisterConfigCreateSurchargeConfigs($input: CreateSurchargeConfigsInput!) {
  dashboardAccountCreateSurchargeConfigs(input: $input) {
    result
  }
}
    `;
export type EditCashRegisterConfigCreateSurchargeConfigsMutationFn = Apollo.MutationFunction<EditCashRegisterConfigCreateSurchargeConfigsMutation, EditCashRegisterConfigCreateSurchargeConfigsMutationVariables>;

/**
 * __useEditCashRegisterConfigCreateSurchargeConfigsMutation__
 *
 * To run a mutation, you first call `useEditCashRegisterConfigCreateSurchargeConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCashRegisterConfigCreateSurchargeConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCashRegisterConfigCreateSurchargeConfigsMutation, { data, loading, error }] = useEditCashRegisterConfigCreateSurchargeConfigsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCashRegisterConfigCreateSurchargeConfigsMutation(baseOptions?: Apollo.MutationHookOptions<EditCashRegisterConfigCreateSurchargeConfigsMutation, EditCashRegisterConfigCreateSurchargeConfigsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCashRegisterConfigCreateSurchargeConfigsMutation, EditCashRegisterConfigCreateSurchargeConfigsMutationVariables>(EditCashRegisterConfigCreateSurchargeConfigsDocument, options);
      }
export type EditCashRegisterConfigCreateSurchargeConfigsMutationHookResult = ReturnType<typeof useEditCashRegisterConfigCreateSurchargeConfigsMutation>;
export type EditCashRegisterConfigCreateSurchargeConfigsMutationResult = Apollo.MutationResult<EditCashRegisterConfigCreateSurchargeConfigsMutation>;
export type EditCashRegisterConfigCreateSurchargeConfigsMutationOptions = Apollo.BaseMutationOptions<EditCashRegisterConfigCreateSurchargeConfigsMutation, EditCashRegisterConfigCreateSurchargeConfigsMutationVariables>;