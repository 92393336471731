import React from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import { saveAs } from "file-saver";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useDinii } from "hooks/useDinii";

import { ConvertTecFormatFormValues } from "./ConvertTecFormatForm/useConvertTecFormatForm";
import { ConvertTecFormatForm } from "./ConvertTecFormatForm";

export const ConvertTecFormat = () => {
  const [dinii, getContext] = useDinii();

  const [{ loading, error }, onSubmit] = useAsyncFn(
    async (args: ConvertTecFormatFormValues) => {
      const context = await getContext();

      if (!context) throw new Error("context is not available");

      const formData = new FormData();

      formData.append("menuMaster", args.menuMaster);
      const { data } = await dinii.convertTecFormat(context, formData);
      await saveAs(data, `マスターデータ投入テンプレート.xlsx`);
    },
    [dinii, getContext],
  );

  return (
    <DashboardLayout title="TEC フォーマット変換">
      <PageHeader
        title="TEC フォーマット変換"
        description="TEC フォーマット変換とは TEC フォーマットのメニューマスターから dinii のメニューマスタ投入テンプレートファイルを作成することです。"
      />
      {error && (
        <Alert
          type="error"
          message={(error as any)?.response?.data?.title}
          description={(error as any)?.response?.data?.message}
        />
      )}
      <ConvertTecFormatForm onSubmit={onSubmit} submitting={loading} />
    </DashboardLayout>
  );
};
