import React, { memo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { PlanGroup } from "pages/PlanGroups/types";

type Props = {
  loading?: boolean;
  planGroups: PlanGroup[];
};

export const PlanGroupTable = memo<Props>(({ loading, planGroups }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "プラングループ名", dataIndex: "name" },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { planGroupId }: PlanGroup) {
        return (
          <IconLink to={`/planGroup/${planGroupId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="planGroupId"
      columns={columns}
      dataSource={planGroups}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
