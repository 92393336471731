import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const DescriptionField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem label="メニュー説明文 (日本語)" name="description" {...props}>
    <TextArea rows={2} placeholder="メニューの概要をご記載ください" disabled={disabled} />
  </EditMenuFormItem>
));
