import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputPrice } from "components/Input/InputPrice";

import { AddPlanChoiceFormItem } from "../useAddPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PriceField = memo<Props>((props) => (
  <AddPlanChoiceFormItem
    label="価格"
    name="price"
    rules={[{ required: true, message: "価格を入力してください" }]}
    {...props}
  >
    <InputPrice />
  </AddPlanChoiceFormItem>
));
