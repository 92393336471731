import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { FormContent } from "components/Template/FormTemplate";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { AddMenuCookingItemModal } from "pages/EditShopMenuOutput/AddMenuCookingItemModal";
import { EditMenuKdDisplayTargetForm } from "pages/EditShopMenuOutput/EditMenuKdDisplayTargetForm";
import { EditMenuRoleForm } from "pages/EditShopMenuOutput/EditMenuRoleForm";
import { MenuCookingItemTable } from "pages/EditShopMenuOutput/MenuCookingItemTable";
import {
  useEditShopMenuOutputDeleteMenuCookingItemMutation,
  useEditShopMenuOutputGetMenuQuery,
  useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery,
} from "pages/EditShopMenuOutput/queries";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const EditShopMenuOutput = () => {
  const { shopId = "", menuId: menuIdString = "" } =
    useParams<Record<"shopId" | "menuId", string>>();
  const menuId = Number(menuIdString);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    refetch,
    loading: loadingMenu,
    error: getMenuDataError,
  } = useEditShopMenuOutputGetMenuQuery(
    !Number.isNaN(menuId) && shopId ? { variables: { menuId, shopId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];
  const shopMenu = menu?.shopMenus[0];

  useSetCurrentCompanyEffect(menu);

  const menuRoles = shopMenu?.shopMenuKitchenRoles?.map(({ role }) => role) ?? [];

  const menuKdDisplayTargets =
    shopMenu?.shopMenuKdDisplayTargets?.map(({ kdDisplayTarget }) => kdDisplayTarget) ?? [];

  const menuCookingItems = shopMenu?.shopMenuCookingItems ?? [];

  const {
    data: getShopAndRolesAndKdDisplayTargets,
    loading: loadingShopAndRolesAndKdDisplayTargets,
    error: getShopAndRolesAndKdDisplayTargetsError,
  } = useEditShopMenuOutputGetShopAndRolesAndKdDisplayTargetsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const shop = getShopAndRolesAndKdDisplayTargets?.shop_by_pk;
  const kdDisplayTargets = shop?.kdDisplayTargets;
  const roles = shop?.roles;

  const loading = loadingMenu || loadingShopAndRolesAndKdDisplayTargets;

  const shouldShowAlert = getMenuDataError || getShopAndRolesAndKdDisplayTargetsError;

  const [deleteMenuCookingItemMutation, { loading: loadingDeleteMenuCookingItem }] =
    useEditShopMenuOutputDeleteMenuCookingItemMutation();

  const onRemove = useCallback(
    async (cookingItemId: string) => {
      const menuId = menu?.menuId;
      if (menuId) {
        try {
          const { data: deleteMenuCookingItem } = await deleteMenuCookingItemMutation({
            variables: { menuId, cookingItemId },
          });
          const returning = deleteMenuCookingItem?.delete_shopMenuCookingItem?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("印刷設定から削除しました");
        } catch (err) {
          message.error("印刷設定からの削除に失敗しました");
        }
      }
    },
    [menu?.menuId, deleteMenuCookingItemMutation, refetch],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToEditShopMenuOutput = useCallback(() => {
    navigate(`/shop/${shopId}/menu/${menuId}/output/edit`, { replace: true });
  }, [navigate, shopId, menuId]);

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "メニュー" }],
      }}
    >
      <PageHeader title={menu?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && menu && shopMenu && roles && kdDisplayTargets && (
        <FormContent>
          <EditMenuRoleForm
            shopId={shopId}
            menu={menu}
            menuRoles={menuRoles.filter(isNotNullable)}
            roles={roles}
          />
          {shop.useKd && (
            <EditMenuKdDisplayTargetForm
              shopId={shopId}
              menu={menu}
              menuKdDisplayTargets={menuKdDisplayTargets.filter(isNotNullable)}
              kdDisplayTargets={kdDisplayTargets}
            />
          )}
          <ButtonWrapper>
            <Link
              key="addCookingItem"
              to={`/shop/${shopId}/menu/${menuId}/output/edit/cookingItem/add`}
              replace
            >
              <Button type="primary">追加</Button>
            </Link>
          </ButtonWrapper>
          <MenuCookingItemTable
            menuCookingItems={menuCookingItems
              .map((menuCookingItem) => menuCookingItem.cookingItem)
              .filter(isNotNullable)}
            loading={loadingDeleteMenuCookingItem}
            onRemove={onRemove}
          />
          <Routes>
            <Route
              path="cookingItem/add"
              element={
                <AddMenuCookingItemModal
                  menu={menu}
                  shopMenu={shopMenu}
                  goBack={goBackToEditShopMenuOutput}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
