import React, { memo } from "react";
import { Button } from "antd";

import { EditPlanTecAggregationPlanFormItem } from "../../useEditPlanTecAggregationPlanForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const TecAggregationGetAvailableCodeForOptionButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed: handleAutoAdoptMenuCodeButtonPressed }) => (
    <EditPlanTecAggregationPlanFormItem
      name="TecAggregationGetAvailableCodeButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditPlanTecAggregationPlanFormItem>
  ),
);
