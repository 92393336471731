import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditGmoBankAccountFormItem } from "../useEditGmoBankAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AccountHolderField = memo<Props>((props) => (
  <EditGmoBankAccountFormItem
    label="口座名義（カナ）"
    name="accountHolder"
    rules={[{ required: true, message: "口座名義を入力して下さい", max: 60 }]}
    {...props}
  >
    <Input />
  </EditGmoBankAccountFormItem>
));
