import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { GmoBankAccountTypeType } from "types/graphql";

export const ACCOUNT_TYPES = [
  { value: GmoBankAccountTypeType.Checking, label: "普通" },
  { value: GmoBankAccountTypeType.Current, label: "当座" },
  { value: GmoBankAccountTypeType.Savings, label: "貯蓄" },
] as const;

export type AccountType = typeof ACCOUNT_TYPES[number];

export type AddGmoBankAccountFormValues = {
  name: string;
  bank: string;
  branch: string;
  accountType: GmoBankAccountTypeType | undefined;
  accountNumber: string;
  accountHolder: string;
  depositCycleShopIds: {
    sixAMonthShopIds: string[];
    twiceAMonthShopIds: string[];
  };
};

const initialValues = {
  name: "",
  bank: "",
  branch: "",
  accountType: undefined,
  accountNumber: "",
  accountHolder: "",
  depositCycleShopIds: {
    sixAMonthShopIds: [],
    twiceAMonthShopIds: [],
  },
};

export const AddGmoBankAccountFormItem = createFormItem<AddGmoBankAccountFormValues>();

export const useAddGmoBankAccountForm = ({
  onSubmit,
}: {
  onSubmit: (formValues: AddGmoBankAccountFormValues) => void;
}) => {
  const [form] = Form.useForm<AddGmoBankAccountFormValues>();

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit, reset: form.resetFields };
};
