import React from "react";
import styled from "styled-components";
import {
  PageHeader as Original,
  // eslint-disable-next-line no-restricted-imports
  PageHeaderProps as OriginalPageHeaderProps,
} from "@ant-design/pro-layout";

import { Spacer } from "components/Spacer";

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export type PageHeaderProps = OriginalPageHeaderProps & {
  description?: React.ReactNode;
};

export const PageHeader = ({ description, footer, ...props }: PageHeaderProps) => (
  <Original
    {...props}
    footer={
      footer !== null && (
        <>
          {description ? <Spacer height={8} /> : <Spacer height={16} />}
          <Footer>
            {description}
            {footer}
          </Footer>
          <Spacer height={24} />
        </>
      )
    }
  />
);
