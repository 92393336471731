import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

export type TecAggregationUploadFormValues = {
  date?: dayjs.Dayjs;
};

export const TecAggregationUploadFormItem = createFormItem<TecAggregationUploadFormValues>();

export const useTecAggregationUploadForm = () => {
  const [form] = Form.useForm();

  const getValues = useCallback(() => {
    const values = form.getFieldsValue() as TecAggregationUploadFormValues;
    return values;
  }, [form]);

  return { form, getValues };
};
