import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { AddLineOfficialAccountFormValues } from "./AddLineOfficialAccountForm/useAddLineOfficialAccountForm";
import { AddLineOfficialAccountForm } from "./AddLineOfficialAccountForm";
import { useAddLineOfficialAccountInsertLineOfficialAccountMutation } from "./queries";

export const AddLineOfficialAccount = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [addLineOfficialAccountMutation, { loading: loadingAddLineOfficialAccountMutation }] =
    useAddLineOfficialAccountInsertLineOfficialAccountMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (lineOfficialAccount: AddLineOfficialAccountFormValues) => {
      if (!companyId) return;
      try {
        await addLineOfficialAccountMutation({
          variables: { input: { ...lineOfficialAccount, companyId } },
        });
        message.success("作成しました");
        goBack();
      } catch (error) {
        message.error(error instanceof Error ? error.message : "作成に失敗しました");
      }
    },
    [addLineOfficialAccountMutation, companyId, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout title="LINE公式アカウントを新規作成">
      <PageHeader title="LINE公式アカウントを新規作成" onBack={goBack} />
      <AddLineOfficialAccountForm
        onSubmit={onSubmit}
        loading={loadingAddLineOfficialAccountMutation}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
