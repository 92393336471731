import React, { memo } from "react";
import { Button, Divider } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { Maybe } from "types/Maybe";

import { WinboardConfig } from "../types";
import { useWinboardConfigForm } from "../useWinboardConfigForm";

import { EnabledField } from "./EnabledField";
import { FtpHostField } from "./FtpHostField";
import { FtpPasswordField } from "./FtpPasswordField";
import { FtpPortField } from "./FtpPortField";
import { FtpUserField } from "./FtpUserField";
import { PutPathField } from "./PutPathField";
import { WinboardCompanyCdField } from "./WinboardCompanyCdField";
import { WinboardPosNumberField } from "./WinboardPosNumberField";
import { WinboardShopCdField } from "./WinboardShopCdField";

type Props = {
  config?: Maybe<WinboardConfig>;
  onSubmit: Parameters<typeof useWinboardConfigForm>[0]["onSubmit"];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loading: boolean;
};

export const EditWinboardConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, config }) => {
    const { form, initialValues, submit } = useWinboardConfigForm({
      config,
      onFormValidationError,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form name="winboardConfig" form={form} initialValues={initialValues} layout="vertical">
            <EnabledField />
            <WinboardCompanyCdField />
            <WinboardShopCdField />
            <WinboardPosNumberField />
            <FtpHostField />
            <FtpPortField />
            <FtpUserField />
            <FtpPasswordField />
            <PutPathField />
            <Divider />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
