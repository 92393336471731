import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddCompanyInsertCompany = gql`
    mutation AddCompanyInsertCompany($input: CreateCompanyInput!) {
  dashboardAccountCreateCompany(input: $input)
}
    `;
export const AddCompanyGetCorporations = gql`
    query AddCompanyGetCorporations {
  corporation {
    id
    name
  }
}
    `;
export type AddCompanyInsertCompanyMutationVariables = Types.Exact<{
  input: Types.CreateCompanyInput;
}>;


export type AddCompanyInsertCompanyMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCreateCompany'>
);

export type AddCompanyGetCorporationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddCompanyGetCorporationsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
  )> }
);


export const AddCompanyInsertCompanyDocument = gql`
    mutation AddCompanyInsertCompany($input: CreateCompanyInput!) {
  dashboardAccountCreateCompany(input: $input)
}
    `;
export type AddCompanyInsertCompanyMutationFn = Apollo.MutationFunction<AddCompanyInsertCompanyMutation, AddCompanyInsertCompanyMutationVariables>;

/**
 * __useAddCompanyInsertCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyInsertCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyInsertCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyInsertCompanyMutation, { data, loading, error }] = useAddCompanyInsertCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCompanyInsertCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyInsertCompanyMutation, AddCompanyInsertCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyInsertCompanyMutation, AddCompanyInsertCompanyMutationVariables>(AddCompanyInsertCompanyDocument, options);
      }
export type AddCompanyInsertCompanyMutationHookResult = ReturnType<typeof useAddCompanyInsertCompanyMutation>;
export type AddCompanyInsertCompanyMutationResult = Apollo.MutationResult<AddCompanyInsertCompanyMutation>;
export type AddCompanyInsertCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyInsertCompanyMutation, AddCompanyInsertCompanyMutationVariables>;
export const AddCompanyGetCorporationsDocument = gql`
    query AddCompanyGetCorporations {
  corporation {
    id
    name
  }
}
    `;

/**
 * __useAddCompanyGetCorporationsQuery__
 *
 * To run a query within a React component, call `useAddCompanyGetCorporationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyGetCorporationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddCompanyGetCorporationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddCompanyGetCorporationsQuery(baseOptions?: Apollo.QueryHookOptions<AddCompanyGetCorporationsQuery, AddCompanyGetCorporationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddCompanyGetCorporationsQuery, AddCompanyGetCorporationsQueryVariables>(AddCompanyGetCorporationsDocument, options);
      }
export function useAddCompanyGetCorporationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddCompanyGetCorporationsQuery, AddCompanyGetCorporationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddCompanyGetCorporationsQuery, AddCompanyGetCorporationsQueryVariables>(AddCompanyGetCorporationsDocument, options);
        }
export type AddCompanyGetCorporationsQueryHookResult = ReturnType<typeof useAddCompanyGetCorporationsQuery>;
export type AddCompanyGetCorporationsLazyQueryHookResult = ReturnType<typeof useAddCompanyGetCorporationsLazyQuery>;
export type AddCompanyGetCorporationsQueryResult = Apollo.QueryResult<AddCompanyGetCorporationsQuery, AddCompanyGetCorporationsQueryVariables>;