import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoice = gql`
    query TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCode = gql`
    query TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const TecAggregationPlanOptionsUpdateTecAggregationMenu = gql`
    mutation TecAggregationPlanOptionsUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables = Types.Exact<{
  planChoiceId: Types.Scalars['Int'];
}>;


export type TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery = (
  { __typename?: 'query_root' }
  & { planChoice: Array<(
    { __typename?: 'planChoice' }
    & Pick<Types.PlanChoice, 'id' | 'planChoiceId' | 'name'>
    & { tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertTecAggregationMenuInput;
}>;


export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) }
);


export const TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument = gql`
    query TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *   },
 * });
 */
export function useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
      }
export function useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
        }
export type TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery>;
export type TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery>;
export type TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryResult = Apollo.QueryResult<TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>;
export const TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeDocument = gql`
    query TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables>(TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeDocument, options);
      }
export function useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables>(TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeDocument, options);
        }
export type TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery>;
export type TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeLazyQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeLazyQuery>;
export type TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryResult = Apollo.QueryResult<TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQuery, TecAggregationPlanOptionsEditPlanChoiceModalGetMenuCodeQueryVariables>;
export const TecAggregationPlanOptionsUpdateTecAggregationMenuDocument = gql`
    mutation TecAggregationPlanOptionsUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<TecAggregationPlanOptionsUpdateTecAggregationMenuMutation, TecAggregationPlanOptionsUpdateTecAggregationMenuMutationVariables>;

/**
 * __useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tecAggregationPlanOptionsUpdateTecAggregationMenuMutation, { data, loading, error }] = useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<TecAggregationPlanOptionsUpdateTecAggregationMenuMutation, TecAggregationPlanOptionsUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TecAggregationPlanOptionsUpdateTecAggregationMenuMutation, TecAggregationPlanOptionsUpdateTecAggregationMenuMutationVariables>(TecAggregationPlanOptionsUpdateTecAggregationMenuDocument, options);
      }
export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useTecAggregationPlanOptionsUpdateTecAggregationMenuMutation>;
export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<TecAggregationPlanOptionsUpdateTecAggregationMenuMutation>;
export type TecAggregationPlanOptionsUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<TecAggregationPlanOptionsUpdateTecAggregationMenuMutation, TecAggregationPlanOptionsUpdateTecAggregationMenuMutationVariables>;