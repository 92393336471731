import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetMenuOrderItemAnalytics = gql`
    query GetMenuOrderItemAnalytics($input: MenuOrderItemAnalyticsInput!) {
  menuOrderItemAnalytics(input: $input) {
    rows {
      categoryId
      menuId
      businessOperationHourType
      isRepeaterTable
      menuName
      menuType
      categoryName
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      orderedQuantity
      repeatOrderQuantity
      firstTimeOrderQuantity
    }
  }
}
    `;
export type GetMenuOrderItemAnalyticsQueryVariables = Types.Exact<{
  input: Types.MenuOrderItemAnalyticsInput;
}>;


export type GetMenuOrderItemAnalyticsQuery = (
  { __typename?: 'query_root' }
  & { menuOrderItemAnalytics: (
    { __typename?: 'MenuOrderItemAnalyticsOutput' }
    & { rows: Array<(
      { __typename?: 'MenuOrderItemAnalyticsOutputItem' }
      & Pick<Types.MenuOrderItemAnalyticsOutputItem, 'categoryId' | 'menuId' | 'businessOperationHourType' | 'isRepeaterTable' | 'menuName' | 'menuType' | 'categoryName' | 'totalTaxIncludedAmount' | 'totalTaxExcludedAmount' | 'totalTaxIncludedCostAmount' | 'totalTaxExcludedCostAmount' | 'totalTaxIncludedNetProfitAmount' | 'totalTaxExcludedNetProfitAmount' | 'orderedQuantity' | 'repeatOrderQuantity' | 'firstTimeOrderQuantity'>
    )> }
  ) }
);


export const GetMenuOrderItemAnalyticsDocument = gql`
    query GetMenuOrderItemAnalytics($input: MenuOrderItemAnalyticsInput!) {
  menuOrderItemAnalytics(input: $input) {
    rows {
      categoryId
      menuId
      businessOperationHourType
      isRepeaterTable
      menuName
      menuType
      categoryName
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      orderedQuantity
      repeatOrderQuantity
      firstTimeOrderQuantity
    }
  }
}
    `;

/**
 * __useGetMenuOrderItemAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetMenuOrderItemAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuOrderItemAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuOrderItemAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMenuOrderItemAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetMenuOrderItemAnalyticsQuery, GetMenuOrderItemAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuOrderItemAnalyticsQuery, GetMenuOrderItemAnalyticsQueryVariables>(GetMenuOrderItemAnalyticsDocument, options);
      }
export function useGetMenuOrderItemAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuOrderItemAnalyticsQuery, GetMenuOrderItemAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuOrderItemAnalyticsQuery, GetMenuOrderItemAnalyticsQueryVariables>(GetMenuOrderItemAnalyticsDocument, options);
        }
export type GetMenuOrderItemAnalyticsQueryHookResult = ReturnType<typeof useGetMenuOrderItemAnalyticsQuery>;
export type GetMenuOrderItemAnalyticsLazyQueryHookResult = ReturnType<typeof useGetMenuOrderItemAnalyticsLazyQuery>;
export type GetMenuOrderItemAnalyticsQueryResult = Apollo.QueryResult<GetMenuOrderItemAnalyticsQuery, GetMenuOrderItemAnalyticsQueryVariables>;