import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { List, Row } from "antd";
import { chunk } from "lodash";
import VirtualList from "rc-virtual-list";

import { InitialAvatarListItem } from "components/InitialAvatarListItem";
import { Company } from "components/Layout/DashboardLayout/types";

const VERTICAL_PADDING = 20;

const StyledList = styled(List)`
  position: relative;
  padding: 8px 24px;
  padding-bottom: 0;
`;

type Props = {
  companies: Company[];
  onSelect: (companyId: string) => void;
  height: number;
  columnCount: number;
};

export const CompanyList = memo<Props>(({ companies, onSelect, height, columnCount }) => {
  const chunkedCompanies = useMemo(() => chunk(companies, columnCount), [columnCount, companies]);
  const span = 24 / columnCount;

  return (
    <StyledList>
      <VirtualList
        data={chunkedCompanies}
        height={height - VERTICAL_PADDING}
        itemHeight={48}
        itemKey="key"
      >
        {(companies) => (
          <Row id={companies.map(({ companyId }) => companyId).join(":")}>
            {companies.map(({ id, name }) => (
              <InitialAvatarListItem
                key={id}
                name={name}
                span={span}
                onClick={() => onSelect(id)}
              />
            ))}
          </Row>
        )}
      </VirtualList>
    </StyledList>
  );
});
