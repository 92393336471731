import React, { memo } from "react";
import { Select } from "antd";

import { AddCompanyGetCorporationsQuery } from "../../queries";
import { AddCompanyFormItem } from "../useAddCompanyForm";

export type Corporation = AddCompanyGetCorporationsQuery["corporation"][number];

type Props = {
  corporations: Corporation[];
};

export const CorporationField = memo<Props>(({ corporations }) => (
  <AddCompanyFormItem
    label="法人"
    rules={[{ required: true, message: "法人を選択してください" }]}
    name="corporationId"
  >
    <Select
      disabled={corporations.length === 0}
      showSearch
      options={corporations.map((corporation) => ({
        label: corporation.name,
        value: corporation.id,
      }))}
      optionFilterProp="label"
    />
  </AddCompanyFormItem>
));
