import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";

import { AddDashboardAccountFormItem } from "../useAddDashboardAccountForm";

type Props = {
  shops: { name: string; shopId: string }[];
} & Omit<FormItemProps, "children" | "name">;

export const ShopsField = memo<Props>(({ shops, ...props }) => (
  <AddDashboardAccountFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ scope }) => [scope])}
    noStyle
  >
    {({ getFieldValue }) => {
      const scope = getFieldValue("scope");
      if (scope === "shop") {
        return (
          <AddDashboardAccountFormItem
            label="アクセス可能店舗"
            name="shops"
            rules={[{ required: true, message: "アクセス可能店舗を選択してください" }]}
            {...props}
          >
            <Select<string>
              mode="multiple"
              placeholder="アクセス可能店舗"
              optionFilterProp="label"
              options={shops.map(({ name, shopId }) => ({ label: name, value: shopId }))}
            />
          </AddDashboardAccountFormItem>
        );
      }
    }}
  </AddDashboardAccountFormItem.NonProperty>
));
