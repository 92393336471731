import React from "react";
import styled from "styled-components";
import { Empty } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { OnboardingHandoutDescriptions } from "components/OnboardingHandoutDescriptions";
import { ShopSelector } from "components/ShopSelector";
import { grey } from "constants/colors";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { useHandoutsGetShopsQuery } from "pages/Handouts/queries";

const Container = styled.div`
  background-color: ${grey[0]};
  padding: 40px;
`;

export const Handouts = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data } = useHandoutsGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = data?.shop ?? [];
  const onboardingHandout = shops.find((shop) => shop.shopId === shopId)?.onboardingHandout;

  return (
    <DashboardLayout title="配布物">
      <PageHeader title="配布物" footer={<ShopSelector />} />
      <Container>
        {shopId ? (
          onboardingHandout ? (
            <OnboardingHandoutDescriptions onboardingHandout={onboardingHandout} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="配布物はありません" />
          )
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="店舗を選択してください" />
        )}
      </Container>
    </DashboardLayout>
  );
};
