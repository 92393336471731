import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Input, Row } from "antd";
import { filterCorporationsByName } from "models/corporation";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";

import { CorporationTable } from "./CorporationTable";
import { useCorporationsGetCorporationsQuery } from "./queries";

export const Corporations = () => {
  const {
    data: getCorporationsData,
    loading: loadingCorporations,
    error,
  } = useCorporationsGetCorporationsQuery();
  const [name, setName] = useState("");

  const filteredCorporations = useMemo(() => {
    const corporations = getCorporationsData?.corporation ?? [];
    return filterCorporationsByName(corporations, name);
  }, [getCorporationsData, name]);

  const onChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  return (
    <DashboardLayout title="法人一覧">
      <PageHeader
        title="法人一覧"
        extra={[
          <Link key="add" to="/corporation/add">
            <Button type="primary">新規作成</Button>
          </Link>,
        ]}
        footer={
          <Row>
            <Input allowClear placeholder="法人名" value={name} onChange={onChangeFilter} />
          </Row>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CorporationTable corporations={filteredCorporations} loading={loadingCorporations} />
    </DashboardLayout>
  );
};
