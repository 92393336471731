import React, { memo } from "react";
import { Button, Col, Input, Row } from "antd";
import { displayTypes } from "models/displayType";
import { isTaxMethod, taxMethods } from "models/taxMethod";
import { taxRates } from "models/taxRate";

import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { Select } from "components/Input/Select";
import { DisplayTypeEnum, TaxMethodEnum } from "types/graphql";

import { EditMenusBulkFormItem } from "../useEditMenusBulkForm";

type Props = {
  onSubmit: () => void;
  defaultCostTaxMethod: TaxMethodEnum | undefined;
};

export const EditMultipleMenusFields = memo<Props>(({ onSubmit, defaultCostTaxMethod }) => (
  <Row>
    <Col span={6}>
      <EditMenusBulkFormItem
        name={["multiEditValues", "displayType"]}
        label="画像の表示サイズ"
        wrapperCol={{ span: 16 }}
      >
        <Select
          showSearch
          options={Object.values(DisplayTypeEnum).map((displayType) => ({
            label: displayTypes[displayType],
            value: displayType,
          }))}
          allowClear
        />
      </EditMenusBulkFormItem>
    </Col>
    <Col span={4}>
      <EditMenusBulkFormItem
        name={["multiEditValues", "price"]}
        label="金額"
        wrapperCol={{ span: 16 }}
      >
        <Input allowClear />
      </EditMenusBulkFormItem>
    </Col>
    <Col span={4}>
      <EditMenusBulkFormItem
        name={["multiEditValues", "taxRate"]}
        label="税率"
        wrapperCol={{ span: 16 }}
      >
        <Select
          showSearch
          options={Object.entries(taxRates).map(([taxRateKey, taxRate]) => ({
            label: taxRate,
            value: taxRateKey,
          }))}
          allowClear
        />
      </EditMenusBulkFormItem>
    </Col>
    <Col span={4}>
      <EditMenusBulkFormItem
        name={["multiEditValues", "taxMethod"]}
        label="税種別"
        wrapperCol={{ span: 16 }}
      >
        <Select
          showSearch
          options={Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => ({
            label: taxMethodName,
            value: taxMethod,
          }))}
          allowClear
        />
      </EditMenusBulkFormItem>
    </Col>
    <Col span={4}>
      <CostPriceWithCostTaxRateField
        name={["multiEditValues", "costPrice"]}
        costTaxRateFieldName={["multiEditValues", "costTaxRate"]}
        label={
          defaultCostTaxMethod && isTaxMethod(defaultCostTaxMethod)
            ? `原価(${taxMethods[defaultCostTaxMethod]})`
            : "原価"
        }
        wrapperCol={{ span: 16 }}
      >
        <Input allowClear />
      </CostPriceWithCostTaxRateField>
    </Col>
    <Col span={4}>
      <CostTaxRateWithCostPriceField
        name={["multiEditValues", "costTaxRate"]}
        costPriceFieldName={["multiEditValues", "costPrice"]}
        wrapperCol={{ span: 16 }}
      >
        <Select
          showSearch
          options={Object.entries(taxRates).map(([taxRateKey, taxRate]) => ({
            label: taxRate,
            value: taxRateKey,
          }))}
          allowClear
        />
      </CostTaxRateWithCostPriceField>
    </Col>
    <Col span={4}>
      <Button onClick={onSubmit} type="primary">
        編集対象に適用
      </Button>
    </Col>
  </Row>
));
