import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Plan } from "pages/PlanGroupPlans/types";

import { AddPlanFormItem } from "../useAddPlanForm";

const StyledCheckbox = styled(Checkbox)`
  display: flex;

  &:not(:first-child) {
    margin-left: 0px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: inline-block;
`;

type Props = {
  plans: Plan[];
} & Omit<FormItemProps, "children" | "name">;

export const PlansField = memo<Props>(({ plans, ...props }) => (
  <AddPlanFormItem name="plans" {...props}>
    <CheckboxGroup>
      {plans.map(({ planId, planName }) => (
        <StyledCheckbox key={planId} value={planId}>
          {planName}
        </StyledCheckbox>
      ))}
    </CheckboxGroup>
  </AddPlanFormItem>
));
