import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiPlanOptionsGetPlan = gql`
    query PikaichiPlanOptionsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    planId
    planName
    companyId
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        pikaichiMenuPlanChoices {
          pikaichiMenuId
          planChoiceId: _planChoiceId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;
export type PikaichiPlanOptionsGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PikaichiPlanOptionsGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName' | 'companyId'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'planChoiceId' | 'name'>
        & { pikaichiMenuPlanChoices: Array<(
          { __typename?: 'pikaichiMenuPlanChoice' }
          & Pick<Types.PikaichiMenuPlanChoice, 'pikaichiMenuId'>
          & { planChoiceId: Types.PikaichiMenuPlanChoice['_planChoiceId'] }
          & { pikaichiMenu: (
            { __typename?: 'pikaichiMenu' }
            & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);


export const PikaichiPlanOptionsGetPlanDocument = gql`
    query PikaichiPlanOptionsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    planId
    planName
    companyId
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        pikaichiMenuPlanChoices {
          pikaichiMenuId
          planChoiceId: _planChoiceId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePikaichiPlanOptionsGetPlanQuery__
 *
 * To run a query within a React component, call `usePikaichiPlanOptionsGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlanOptionsGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiPlanOptionsGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePikaichiPlanOptionsGetPlanQuery(baseOptions: Apollo.QueryHookOptions<PikaichiPlanOptionsGetPlanQuery, PikaichiPlanOptionsGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiPlanOptionsGetPlanQuery, PikaichiPlanOptionsGetPlanQueryVariables>(PikaichiPlanOptionsGetPlanDocument, options);
      }
export function usePikaichiPlanOptionsGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiPlanOptionsGetPlanQuery, PikaichiPlanOptionsGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiPlanOptionsGetPlanQuery, PikaichiPlanOptionsGetPlanQueryVariables>(PikaichiPlanOptionsGetPlanDocument, options);
        }
export type PikaichiPlanOptionsGetPlanQueryHookResult = ReturnType<typeof usePikaichiPlanOptionsGetPlanQuery>;
export type PikaichiPlanOptionsGetPlanLazyQueryHookResult = ReturnType<typeof usePikaichiPlanOptionsGetPlanLazyQuery>;
export type PikaichiPlanOptionsGetPlanQueryResult = Apollo.QueryResult<PikaichiPlanOptionsGetPlanQuery, PikaichiPlanOptionsGetPlanQueryVariables>;