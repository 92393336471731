import React, { memo } from "react";
import { Select } from "antd";
import { FormItemProps } from "antd/lib/form";

import { Category } from "pages/EditMenu/types";

import { EditMenuFormItem } from "../useEditMenuForm";

const { Option } = Select;

type Props = {
  categories: Category[];
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const CategoryField = memo<Props>(({ categories, disabled, ...props }) => (
  <EditMenuFormItem
    label="カテゴリ"
    name="categoryIds"
    rules={[{ required: true, message: "カテゴリ名を入力してください" }]}
    {...props}
  >
    <Select<number>
      mode="multiple"
      placeholder="カテゴリを選択してください"
      optionFilterProp="children"
      disabled={disabled}
    >
      {categories.map(({ categoryId, name, shopSideName }) => (
        <Option key={categoryId} value={categoryId}>
          {shopSideName || name}
        </Option>
      ))}
    </Select>
  </EditMenuFormItem>
));
