import React, { memo } from "react";
import { Radio } from "antd";
import { displayTypes } from "models/displayType";

import { FormItemProps } from "components/antd/Form";
import { DisplayTypeEnum } from "types/graphql";

import { EditPlanFormItem } from "../useEditPlanForm";

const options = Object.values(DisplayTypeEnum).map((displayType) => ({
  label: displayTypes[displayType],
  value: displayType,
}));

type Props = { disabled: boolean } & Omit<FormItemProps, "children">;

export const DisplayTypeField = memo<Props>(({ disabled }) => (
  <EditPlanFormItem
    label="画像の表示サイズ"
    name="displayType"
    rules={[{ required: true, message: "画像の表示サイズを入力してください" }]}
  >
    <Radio.Group options={options} optionType="button" disabled={disabled} />
  </EditPlanFormItem>
));
