import React, { memo } from "react";
import styled from "styled-components";

import { grey } from "constants/colors";

const MAX_AVATAR_COUNT = 5;

const AvatarContainer = styled.div`
  display: flex;
  width: 112px;
  height: 36px;
  position: relative;
  align-items: end;
`;

const AvatarIcon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const PrimaryAvatar = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  z-index: 5;
  left: 0;
  border-radius: 50%;
  background: ${grey[5]};
`;

const SecondaryAvatarContainer = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  width: 100%;
  height: 24px;
  left: 30px;
`;

const SecondaryAvatar = styled.div<{ index: number }>`
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: ${({ index }) => 4 - index};
  // 一番左のアイコンから 20 px ずつずらして、それぞれ 4 px 重なるようにする
  left: ${({ index }) => index * 20}px;
  border-radius: 50%;
  background: ${grey[5]};
`;

type Props = {
  tableUserCustomerProfileImages: string[];
};

export const TableUserCustomerAvatars = memo<Props>(({ tableUserCustomerProfileImages }) => (
  <AvatarContainer>
    <PrimaryAvatar>
      {tableUserCustomerProfileImages[0] && <AvatarIcon src={tableUserCustomerProfileImages[0]} />}
    </PrimaryAvatar>

    <SecondaryAvatarContainer>
      {tableUserCustomerProfileImages
        .slice(1, MAX_AVATAR_COUNT)
        .map((tableUserCustomerProfileImage, i) => (
          <SecondaryAvatar key={i} index={i}>
            {tableUserCustomerProfileImage && <AvatarIcon src={tableUserCustomerProfileImage} />}
          </SecondaryAvatar>
        ))}
    </SecondaryAvatarContainer>
  </AvatarContainer>
));
