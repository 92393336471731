import React, { useCallback } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { OptionHeader } from "components/PageHeader/OptionHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";

import { EditOptionKitchenRolesModal } from "./EditOptionKitchenRolesModal";
import { OptionDealerTable } from "./OptionDealerTable";
import { useEditOptionDealersGetOptionsQuery, useEditOptionDealersGetShopsQuery } from "./queries";

export const EditOptionDealers = () => {
  const [, setCompany] = useCompany();

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const optionId = Number(id);

  const {
    data: optionData,
    refetch,
    loading: loadingOptions,
    error: getOptionsError,
  } = useEditOptionDealersGetOptionsQuery(
    Number.isNaN(optionId)
      ? { skip: true }
      : {
          variables: { optionId },
          onCompleted: ({ options }) => {
            setCompany(options[0]?.company.id);
          },
        },
  );

  const option = optionData?.options?.[0];

  const {
    data: shopsData,
    loading: loadingShops,
    error: getShopsError,
  } = useEditOptionDealersGetShopsQuery(
    option?.companyId ? { variables: { companyId: option.companyId } } : { skip: true },
  );

  const shops = shopsData?.shops ?? [];
  const shopOptionKitchenRoles = option?.shopOptionKitchenRoles ?? [];

  const shouldShowAlert = getOptionsError || getShopsError;

  const loading = loadingOptions || loadingShops;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToEditOptionDealers = useCallback(() => {
    navigate(`/option/${optionId}/dealer/edit`, { replace: true });
  }, [navigate, optionId]);

  return (
    <DashboardLayout
      title={option?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "オプション" }],
      }}
    >
      <OptionHeader option={option ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {option && (
        <FormContent>
          <OptionDealerTable
            loading={loading}
            shops={shops}
            optionId={optionId}
            shopOptionKitchenRoles={shopOptionKitchenRoles}
          />
        </FormContent>
      )}

      <Routes>
        <Route
          path=":shopId"
          element={
            option && (
              <EditOptionKitchenRolesModal
                option={option}
                goBack={goBackToEditOptionDealers}
                onUpdated={refetch}
              />
            )
          }
        />
      </Routes>
    </DashboardLayout>
  );
};
