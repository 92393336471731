import React, { useCallback } from "react";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { FoodingJournalConfigInsertInput } from "types/graphql";

import { EditFoodingJournalConfigForm } from "./EditFoodingJournalConfigForm";
import {
  useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery,
  useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation,
} from "./queries";

export const EditFoodingJournalConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, error, refetch } = useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const foodingJournalConfig = data?.shop[0]?.foodingJournalConfig;

  const [upsertFoodingJournalConfigMutation, { loading: submitting }] =
    useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation();

  const onSubmit = useCallback(
    async (input: Omit<FoodingJournalConfigInsertInput, "shopId">) => {
      if (!shopId) return;

      const id = foodingJournalConfig?.id ?? uuidv4();
      const foodingJournalConfigDto = {
        id,
        shopId,
        ...input,
      };

      try {
        await upsertFoodingJournalConfigMutation({
          variables: {
            foodingJournalConfig: foodingJournalConfigDto,
          },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }
      await refetch();
    },
    [foodingJournalConfig, shopId, upsertFoodingJournalConfigMutation, refetch],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout title="Fooding Journal連携">
      <PageHeader title="設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <EditFoodingJournalConfigForm
          foodingJournalConfig={foodingJournalConfig}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={submitting}
        />
      )}
    </DashboardLayout>
  );
};
