import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const ShopSideNameField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanFormItem
    label={
      <FormHelp
        label="ハンディ表示名"
        help="ハンディでの表示名。未入力時はプラン名が表示されます"
      />
    }
    name="shopSideName"
    {...props}
  >
    <Input disabled={disabled} />
  </EditPlanFormItem>
));
