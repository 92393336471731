import React, { memo } from "react";
import styled from "styled-components";
import { Button, Input } from "antd";

import { withFormDependencies } from "components/antd/Form";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

const InputContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledInputPassword = styled(Input.Password)`
  width: 360px;
`;

const StyledButton = styled(Button)`
  width: 192px;
`;

type Props = {
  passwordMinLength: number;
  passwordRegex: RegExp;
  isEditingPassword: boolean;
  onClickPasswordChangeButton: () => void;
};

export const DrawerOpenPasswordField = memo<Props>(
  ({
    passwordMinLength,
    passwordRegex: drawerOpenPasswordRegex,
    isEditingPassword,
    onClickPasswordChangeButton,
  }) => {
    const message = `パスワードは英数字${passwordMinLength}文字以上にしてください。`;

    return (
      <EditCashRegisterConfigFormItem.NonProperty
        shouldUpdate={withFormDependencies(({ enableRequiringPasswordForDrawerOpen }) => [
          enableRequiringPasswordForDrawerOpen,
        ])}
      >
        {({ getFieldValue }) => {
          const enableRequiringPasswordForDrawerOpen = getFieldValue(
            "enableRequiringPasswordForDrawerOpen",
          );
          const enabledPasswordInput = enableRequiringPasswordForDrawerOpen && isEditingPassword;
          return (
            <EditCashRegisterConfigFormItem
              name="drawerOpenPassword"
              rules={[
                {
                  required: enabledPasswordInput,
                  message,
                },
                {
                  pattern: drawerOpenPasswordRegex,
                  message,
                },
              ]}
              help={isEditingPassword && message}
            >
              <InputContainer>
                <StyledInputPassword
                  placeholder={isEditingPassword ? "新しいパスワード" : "••••••"}
                  disabled={!enabledPasswordInput}
                />
                {!isEditingPassword && (
                  <StyledButton
                    disabled={!enableRequiringPasswordForDrawerOpen}
                    onClick={onClickPasswordChangeButton}
                  >
                    パスワードを変更する
                  </StyledButton>
                )}
              </InputContainer>
            </EditCashRegisterConfigFormItem>
          );
        }}
      </EditCashRegisterConfigFormItem.NonProperty>
    );
  },
);
