import React, { memo } from "react";
import { Checkbox } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const ShouldMergeSlipOptionsField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    name="shouldMergeSlipOptions"
    valuePropName="checked"
    help="本メニューが異なるオプションで複数注文された際、それらをまとめて伝票に印字します"
    {...props}
  >
    <Checkbox disabled={disabled}>オプションをまとめて印字する</Checkbox>
  </EditMenuFormItem>
));
