import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DashboardAccountsGetDashboardAccounts = gql`
    query DashboardAccountsGetDashboardAccounts($corporationId: uuid!, $query: String) {
  dashboardAccount(
    where: {_and: [{corporationId: {_eq: $corporationId}}, {_or: [{email: {_like: $query}}, {userName: {_like: $query}}]}]}
    order_by: {createdAt: asc}
  ) {
    corporation {
      id
      name
    }
    email
    id
    userName
    dashboardAccountRole {
      id
      name
    }
    dashboardAccountAccessibleCompanies {
      company {
        companyId
        name
      }
    }
    dashboardAccountAccessibleShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;
export const DashboardAccountsDeleteDashboardAccount = gql`
    mutation DashboardAccountsDeleteDashboardAccount($input: CompanyManagerDeleteDashboardAccountInput!) {
  companyManagerDeleteDashboardAccount(input: $input)
}
    `;
export type DashboardAccountsGetDashboardAccountsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DashboardAccountsGetDashboardAccountsQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccount: Array<(
    { __typename?: 'dashboardAccount' }
    & Pick<Types.DashboardAccount, 'email' | 'id' | 'userName'>
    & { corporation?: Types.Maybe<(
      { __typename?: 'corporation' }
      & Pick<Types.Corporation, 'id' | 'name'>
    )>, dashboardAccountRole: (
      { __typename?: 'dashboardAccountRole' }
      & Pick<Types.DashboardAccountRole, 'id' | 'name'>
    ), dashboardAccountAccessibleCompanies: Array<(
      { __typename?: 'dashboardAccountAccessibleCompany' }
      & { company?: Types.Maybe<(
        { __typename?: 'company' }
        & Pick<Types.Company, 'companyId' | 'name'>
      )> }
    )>, dashboardAccountAccessibleShops: Array<(
      { __typename?: 'dashboardAccountAccessibleShop' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )> }
  )> }
);

export type DashboardAccountsDeleteDashboardAccountMutationVariables = Types.Exact<{
  input: Types.CompanyManagerDeleteDashboardAccountInput;
}>;


export type DashboardAccountsDeleteDashboardAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'companyManagerDeleteDashboardAccount'>
);


export const DashboardAccountsGetDashboardAccountsDocument = gql`
    query DashboardAccountsGetDashboardAccounts($corporationId: uuid!, $query: String) {
  dashboardAccount(
    where: {_and: [{corporationId: {_eq: $corporationId}}, {_or: [{email: {_like: $query}}, {userName: {_like: $query}}]}]}
    order_by: {createdAt: asc}
  ) {
    corporation {
      id
      name
    }
    email
    id
    userName
    dashboardAccountRole {
      id
      name
    }
    dashboardAccountAccessibleCompanies {
      company {
        companyId
        name
      }
    }
    dashboardAccountAccessibleShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useDashboardAccountsGetDashboardAccountsQuery__
 *
 * To run a query within a React component, call `useDashboardAccountsGetDashboardAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAccountsGetDashboardAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAccountsGetDashboardAccountsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDashboardAccountsGetDashboardAccountsQuery(baseOptions: Apollo.QueryHookOptions<DashboardAccountsGetDashboardAccountsQuery, DashboardAccountsGetDashboardAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAccountsGetDashboardAccountsQuery, DashboardAccountsGetDashboardAccountsQueryVariables>(DashboardAccountsGetDashboardAccountsDocument, options);
      }
export function useDashboardAccountsGetDashboardAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAccountsGetDashboardAccountsQuery, DashboardAccountsGetDashboardAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAccountsGetDashboardAccountsQuery, DashboardAccountsGetDashboardAccountsQueryVariables>(DashboardAccountsGetDashboardAccountsDocument, options);
        }
export type DashboardAccountsGetDashboardAccountsQueryHookResult = ReturnType<typeof useDashboardAccountsGetDashboardAccountsQuery>;
export type DashboardAccountsGetDashboardAccountsLazyQueryHookResult = ReturnType<typeof useDashboardAccountsGetDashboardAccountsLazyQuery>;
export type DashboardAccountsGetDashboardAccountsQueryResult = Apollo.QueryResult<DashboardAccountsGetDashboardAccountsQuery, DashboardAccountsGetDashboardAccountsQueryVariables>;
export const DashboardAccountsDeleteDashboardAccountDocument = gql`
    mutation DashboardAccountsDeleteDashboardAccount($input: CompanyManagerDeleteDashboardAccountInput!) {
  companyManagerDeleteDashboardAccount(input: $input)
}
    `;
export type DashboardAccountsDeleteDashboardAccountMutationFn = Apollo.MutationFunction<DashboardAccountsDeleteDashboardAccountMutation, DashboardAccountsDeleteDashboardAccountMutationVariables>;

/**
 * __useDashboardAccountsDeleteDashboardAccountMutation__
 *
 * To run a mutation, you first call `useDashboardAccountsDeleteDashboardAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDashboardAccountsDeleteDashboardAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dashboardAccountsDeleteDashboardAccountMutation, { data, loading, error }] = useDashboardAccountsDeleteDashboardAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardAccountsDeleteDashboardAccountMutation(baseOptions?: Apollo.MutationHookOptions<DashboardAccountsDeleteDashboardAccountMutation, DashboardAccountsDeleteDashboardAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DashboardAccountsDeleteDashboardAccountMutation, DashboardAccountsDeleteDashboardAccountMutationVariables>(DashboardAccountsDeleteDashboardAccountDocument, options);
      }
export type DashboardAccountsDeleteDashboardAccountMutationHookResult = ReturnType<typeof useDashboardAccountsDeleteDashboardAccountMutation>;
export type DashboardAccountsDeleteDashboardAccountMutationResult = Apollo.MutationResult<DashboardAccountsDeleteDashboardAccountMutation>;
export type DashboardAccountsDeleteDashboardAccountMutationOptions = Apollo.BaseMutationOptions<DashboardAccountsDeleteDashboardAccountMutation, DashboardAccountsDeleteDashboardAccountMutationVariables>;