import React, { memo } from "react";
import { Radio } from "antd";
import { integrationTypes } from "models/tecAggregationIntegrationType";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const IntegrationTypeField = memo<Props>((props) => (
  <FormItem
    label="連携方法"
    name="integrationType"
    rules={[{ required: true }]}
    required
    {...props}
  >
    <Radio.Group>
      {Object.entries(integrationTypes).map(([key, value]) => (
        <Radio.Button value={key} key={key}>
          {value}
        </Radio.Button>
      ))}
    </Radio.Group>
  </FormItem>
));
