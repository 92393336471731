import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopPlansBulkGetPlans = gql`
    query EditShopPlansBulkGetPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {planId: asc}
  ) {
    id
    planId
    companyId
    imageUrl
    planName
    shopPlans(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
      shopId
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      id
      planOptionId
      name
      planChoices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
        id
        planChoiceId
        name
        shopPlanChoices(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
          shopId
        }
      }
    }
  }
}
    `;
export const EditShopPlansBulkGetShops = gql`
    query EditShopPlansBulkGetShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const UpdateShopPlansBulk = gql`
    mutation UpdateShopPlansBulk($input: UpdateShopPlansBulkInput!) {
  updateShopPlansBulk(input: $input) {
    result
  }
}
    `;
export type EditShopPlansBulkGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditShopPlansBulkGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'companyId' | 'imageUrl' | 'planName'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'shopId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum'>
      )> }
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'id' | 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'id' | 'planChoiceId' | 'name'>
        & { shopPlanChoices: Array<(
          { __typename?: 'shopPlanChoice' }
          & Pick<Types.ShopPlanChoice, 'shopId'>
        )> }
      )> }
    )> }
  )> }
);

export type EditShopPlansBulkGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditShopPlansBulkGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type UpdateShopPlansBulkMutationVariables = Types.Exact<{
  input: Types.UpdateShopPlansBulkInput;
}>;


export type UpdateShopPlansBulkMutation = (
  { __typename?: 'mutation_root' }
  & { updateShopPlansBulk: (
    { __typename?: 'UpdateShopPlansBulkResult' }
    & Pick<Types.UpdateShopPlansBulkResult, 'result'>
  ) }
);


export const EditShopPlansBulkGetPlansDocument = gql`
    query EditShopPlansBulkGetPlans($companyId: uuid!) {
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {planId: asc}
  ) {
    id
    planId
    companyId
    imageUrl
    planName
    shopPlans(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
      shopId
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      id
      planOptionId
      name
      planChoices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
        id
        planChoiceId
        name
        shopPlanChoices(where: {archivedAt: {_is_null: true}}, order_by: {shopId: asc}) {
          shopId
        }
      }
    }
  }
}
    `;

/**
 * __useEditShopPlansBulkGetPlansQuery__
 *
 * To run a query within a React component, call `useEditShopPlansBulkGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlansBulkGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlansBulkGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditShopPlansBulkGetPlansQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlansBulkGetPlansQuery, EditShopPlansBulkGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlansBulkGetPlansQuery, EditShopPlansBulkGetPlansQueryVariables>(EditShopPlansBulkGetPlansDocument, options);
      }
export function useEditShopPlansBulkGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlansBulkGetPlansQuery, EditShopPlansBulkGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlansBulkGetPlansQuery, EditShopPlansBulkGetPlansQueryVariables>(EditShopPlansBulkGetPlansDocument, options);
        }
export type EditShopPlansBulkGetPlansQueryHookResult = ReturnType<typeof useEditShopPlansBulkGetPlansQuery>;
export type EditShopPlansBulkGetPlansLazyQueryHookResult = ReturnType<typeof useEditShopPlansBulkGetPlansLazyQuery>;
export type EditShopPlansBulkGetPlansQueryResult = Apollo.QueryResult<EditShopPlansBulkGetPlansQuery, EditShopPlansBulkGetPlansQueryVariables>;
export const EditShopPlansBulkGetShopsDocument = gql`
    query EditShopPlansBulkGetShops($companyId: uuid!) {
  shop(
    where: {archivedAt: {_is_null: true}, companyId: {_eq: $companyId}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditShopPlansBulkGetShopsQuery__
 *
 * To run a query within a React component, call `useEditShopPlansBulkGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlansBulkGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlansBulkGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditShopPlansBulkGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlansBulkGetShopsQuery, EditShopPlansBulkGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlansBulkGetShopsQuery, EditShopPlansBulkGetShopsQueryVariables>(EditShopPlansBulkGetShopsDocument, options);
      }
export function useEditShopPlansBulkGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlansBulkGetShopsQuery, EditShopPlansBulkGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlansBulkGetShopsQuery, EditShopPlansBulkGetShopsQueryVariables>(EditShopPlansBulkGetShopsDocument, options);
        }
export type EditShopPlansBulkGetShopsQueryHookResult = ReturnType<typeof useEditShopPlansBulkGetShopsQuery>;
export type EditShopPlansBulkGetShopsLazyQueryHookResult = ReturnType<typeof useEditShopPlansBulkGetShopsLazyQuery>;
export type EditShopPlansBulkGetShopsQueryResult = Apollo.QueryResult<EditShopPlansBulkGetShopsQuery, EditShopPlansBulkGetShopsQueryVariables>;
export const UpdateShopPlansBulkDocument = gql`
    mutation UpdateShopPlansBulk($input: UpdateShopPlansBulkInput!) {
  updateShopPlansBulk(input: $input) {
    result
  }
}
    `;
export type UpdateShopPlansBulkMutationFn = Apollo.MutationFunction<UpdateShopPlansBulkMutation, UpdateShopPlansBulkMutationVariables>;

/**
 * __useUpdateShopPlansBulkMutation__
 *
 * To run a mutation, you first call `useUpdateShopPlansBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopPlansBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopPlansBulkMutation, { data, loading, error }] = useUpdateShopPlansBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShopPlansBulkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopPlansBulkMutation, UpdateShopPlansBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopPlansBulkMutation, UpdateShopPlansBulkMutationVariables>(UpdateShopPlansBulkDocument, options);
      }
export type UpdateShopPlansBulkMutationHookResult = ReturnType<typeof useUpdateShopPlansBulkMutation>;
export type UpdateShopPlansBulkMutationResult = Apollo.MutationResult<UpdateShopPlansBulkMutation>;
export type UpdateShopPlansBulkMutationOptions = Apollo.BaseMutationOptions<UpdateShopPlansBulkMutation, UpdateShopPlansBulkMutationVariables>;