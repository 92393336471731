import { useCallback } from "react";
import { getRole } from "models/user";

import { RouteName } from "components/Router";
import { accessPermissions } from "constants/accessPermissions";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

export const useCanAccess = () => {
  const user = useCurrentUser();
  const role = getRole(user);
  const { hasPermission, isLoading } = useCurrentUserPermissions();

  // ユーザが取得できていて、かつ権限も取得できている
  const isInitialized = role !== undefined && !isLoading;

  const canAccess = useCallback(
    (route: RouteName) => {
      if (role === undefined) {
        return false;
      }

      if (role === Role.ServiceAdmin) {
        // serviceAdmin はすべてのページにアクセスできる
        return true;
      }

      if (
        route === "signIn" ||
        route === "signOut" ||
        route === "adyenTerminalPaymentSignIn" ||
        route === "adyenTerminalPaymentSignOut"
      ) {
        // signIn, signOut ページは誰でもアクセスできる
        return true;
      }

      if (route === "invalidAccount" && role === Role.Invalid) {
        return true;
      }

      const accessiblePermission = accessPermissions[route];

      if (!accessiblePermission) {
        return false;
      }

      return hasPermission(accessiblePermission);
    },
    [role, hasPermission],
  );

  return { canAccess, isInitialized };
};
