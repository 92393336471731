import React from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { convertToFaveYellRanking } from "models/customerDashboard";

import { CustomerRanking } from "components/CustomerRanking";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { useRankingFaveYellAmountsGetCustomerFaveYellAmountRankingQuery } from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const RankingFaveYellAmounts = () => {
  const { shop } = useShopPerCorporation();

  const {
    data: getCustomerFaveYellAmountRankingData,
    loading: getCustomerFaveYellAmountRankingDataLoading,
    error: getCustomerFaveYellAmountRankingDataError,
  } = useRankingFaveYellAmountsGetCustomerFaveYellAmountRankingQuery(
    shop ? { variables: { input: { shopId: shop.shopId } } } : { skip: true },
  );

  const viralRankingData = convertToFaveYellRanking(
    getCustomerFaveYellAmountRankingData?.customerFaveYellAmountRanking.customers,
  );

  return (
    <DashboardLayout title="推しエール金額ランキング">
      {getCustomerFaveYellAmountRankingDataError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CustomerRanking
        rankingData={viralRankingData}
        loading={getCustomerFaveYellAmountRankingDataLoading}
        title="推しエール金額ランキング"
        valueUnit="円"
      />
    </DashboardLayout>
  );
};
