import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ExternalOnlineMenusGetExternalOnlineMenuAndShop = gql`
    query ExternalOnlineMenusGetExternalOnlineMenuAndShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
    externalOnlineMenus {
      id
      name
      url
      externalOnlineMenuAvailableTimeTerms {
        id
        dayWeek
        start
        end
      }
    }
  }
}
    `;
export type ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'changeDateTime'>
  )>, externalOnlineMenuConfig: Array<(
    { __typename?: 'externalOnlineMenuConfig' }
    & Pick<Types.ExternalOnlineMenuConfig, 'id' | 'defaultMenuUrl'>
    & { externalOnlineMenus: Array<(
      { __typename?: 'externalOnlineMenu' }
      & Pick<Types.ExternalOnlineMenu, 'id' | 'name' | 'url'>
      & { externalOnlineMenuAvailableTimeTerms: Array<(
        { __typename?: 'externalOnlineMenuAvailableTimeTerm' }
        & Pick<Types.ExternalOnlineMenuAvailableTimeTerm, 'id' | 'dayWeek' | 'start' | 'end'>
      )> }
    )> }
  )> }
);


export const ExternalOnlineMenusGetExternalOnlineMenuAndShopDocument = gql`
    query ExternalOnlineMenusGetExternalOnlineMenuAndShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
    externalOnlineMenus {
      id
      name
      url
      externalOnlineMenuAvailableTimeTerms {
        id
        dayWeek
        start
        end
      }
    }
  }
}
    `;

/**
 * __useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery__
 *
 * To run a query within a React component, call `useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery(baseOptions: Apollo.QueryHookOptions<ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery, ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery, ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables>(ExternalOnlineMenusGetExternalOnlineMenuAndShopDocument, options);
      }
export function useExternalOnlineMenusGetExternalOnlineMenuAndShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery, ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery, ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables>(ExternalOnlineMenusGetExternalOnlineMenuAndShopDocument, options);
        }
export type ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryHookResult = ReturnType<typeof useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery>;
export type ExternalOnlineMenusGetExternalOnlineMenuAndShopLazyQueryHookResult = ReturnType<typeof useExternalOnlineMenusGetExternalOnlineMenuAndShopLazyQuery>;
export type ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryResult = Apollo.QueryResult<ExternalOnlineMenusGetExternalOnlineMenuAndShopQuery, ExternalOnlineMenusGetExternalOnlineMenuAndShopQueryVariables>;