import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { DishUpSlipGroupHeader } from "components/PageHeader/DishUpReceiptGroupHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import {
  useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation,
  useDishUpSlipGroupPlansGetPlansQuery,
} from "pages/DishUpSlipGroupPlans/queries";
import { PlanTable } from "pages/PlanGroupPlans/PlanTable";

import { AddDishUpSlipGroupPlanModal } from "./AddDishUpSlipGroupPlanModal";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const DishUpSlipGroupPlans = () => {
  const { id } = useParams<{ id: string }>();

  const dishUpSlipGroupId = Number(id);
  const navigate = useNavigate();

  const {
    data: getDishUpSlipGroupData,
    refetch,
    loading: loadingDishUpSlipGroup,
    error,
  } = useDishUpSlipGroupPlansGetPlansQuery(
    !Number.isNaN(dishUpSlipGroupId) ? { variables: { dishUpSlipGroupId } } : { skip: true },
  );
  const dishUpSlipGroup = getDishUpSlipGroupData?.dishUpSlipGroup[0];

  useSetCurrentCompanyEffect(dishUpSlipGroup?.shop);

  const plans =
    dishUpSlipGroup?.dishUpSlipGroupShopPlans?.map(({ plan }) => plan)?.filter(isNotNullable) ?? [];
  const [
    deleteDishUpSlipGroupDeleteDishUpSlipGroupPlansPlan,
    { loading: loadingDeleteDishUpSlipGroupPlansPlan },
  ] = useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation();

  const onRemove = useCallback(
    async (planId: number) => {
      const dishUpSlipGroupId = dishUpSlipGroup?.dishUpSlipGroupId;
      if (dishUpSlipGroupId) {
        try {
          const { data: deleteDishUpSlipGroupDeleteDishUpSlipGroupPlan } =
            await deleteDishUpSlipGroupDeleteDishUpSlipGroupPlansPlan({
              variables: { dishUpSlipGroupId, planId },
            });
          const returning =
            deleteDishUpSlipGroupDeleteDishUpSlipGroupPlan?.delete_dishUpSlipGroupShopPlans
              ?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("デシャップグループから削除しました");
        } catch (err) {
          message.success("デシャップグループからの削除に失敗しました");
        }
      }
    },
    [
      deleteDishUpSlipGroupDeleteDishUpSlipGroupPlansPlan,
      dishUpSlipGroup?.dishUpSlipGroupId,
      refetch,
    ],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToDishUpSlipGroupPlans = useCallback(() => {
    navigate(`/dishUpSlipGroup/${dishUpSlipGroupId}/plan`, { replace: true });
  }, [navigate, dishUpSlipGroupId]);

  return (
    <DashboardLayout
      title={dishUpSlipGroup?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "デシャップレシートグループ" }],
      }}
    >
      <DishUpSlipGroupHeader dishUpSlipGroup={dishUpSlipGroup ?? null} onBack={goBack} />
      {loadingDishUpSlipGroup && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {dishUpSlipGroup && (
        <FormContent>
          <ButtonWrapper>
            <Link key="addPlan" to={`/dishUpSlipGroup/${dishUpSlipGroupId}/plan/add`} replace>
              <Button type="primary">追加</Button>
            </Link>
          </ButtonWrapper>
          <PlanTable
            plans={plans}
            loading={loadingDeleteDishUpSlipGroupPlansPlan}
            onRemove={onRemove}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddDishUpSlipGroupPlanModal
                  dishUpSlipGroup={dishUpSlipGroup}
                  goBack={goBackToDishUpSlipGroupPlans}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
