import React, { memo } from "react";
import { Select } from "antd";
import { taxMethods } from "models/taxMethod";

import { FormItemProps } from "components/antd/Form";

import { EditPlanFormItem } from "../useEditPlanForm";

const { Option } = Select;

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const TaxMethodField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanFormItem label="税種別" name="taxMethod" {...props}>
    <Select<string> disabled={disabled}>
      {Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => (
        <Option key={taxMethod} value={taxMethod}>
          {taxMethodName}
        </Option>
      ))}
    </Select>
  </EditPlanFormItem>
));
