import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { PikaichiOnSitePaymentDiscountTypeInsertInput } from "types/graphql";

import { EditPikaichiOnSitePaymentDiscountForm } from "./EditPikaichiOnSitePaymentDiscountTypeForm";
import {
  useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery,
  useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation,
} from "./queries";

export const EditPikaichiOnSitePaymentDiscountType = () => {
  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();

  const [company] = useCompany();

  const {
    data: getDetailTypeData,
    loading: loadingDetailType,
    refetch: refetchDetailType,
    error,
  } = useEditPikaichiOnSitePaymentDiscountGetDetailTypeQuery(
    company && type
      ? {
          variables: {
            type,
            companyId: company.id,
          },
        }
      : { skip: true },
  );
  const onSitePaymentDiscountType = getDetailTypeData?.onSitePaymentDiscountType[0];
  const pikaichiOnSitePaymentDiscountType =
    onSitePaymentDiscountType?.pikaichiOnSitePaymentDiscountTypes[0];

  const [
    updatePikaichiOnSitePaymentDiscountTypeMutation,
    { loading: loadingUpdatePikaichiOnSitePaymentDiscountType },
  ] = useEditPikaichiOnSitePaymentDiscountTypeUpdatePikaichiDetailTypeMutation();

  const onSubmit = useCallback(
    async (
      input: Omit<
        PikaichiOnSitePaymentDiscountTypeInsertInput,
        "companyId" | "onSitePaymentDiscountTypeKey" | "onSitePaymentDiscountTypeId"
      >,
    ) => {
      if (!company) return;
      if (!type) return;
      if (!onSitePaymentDiscountType) return;

      const pikaichiDiscountTypeId =
        pikaichiOnSitePaymentDiscountType?.pikaichiDiscountTypeId ?? uuidv4();

      try {
        await updatePikaichiOnSitePaymentDiscountTypeMutation({
          variables: {
            pikaichiOnSitePaymentDiscountType: {
              pikaichiDiscountTypeId,
              onSitePaymentDiscountTypeKey: type,
              companyId: company.id,
              onSitePaymentDiscountTypeId: onSitePaymentDiscountType.id,
              ...input,
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchDetailType();
    },
    [
      company,
      onSitePaymentDiscountType,
      pikaichiOnSitePaymentDiscountType?.pikaichiDiscountTypeId,
      type,
      updatePikaichiOnSitePaymentDiscountTypeMutation,
      refetchDetailType,
    ],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingDetailType && loadingUpdatePikaichiOnSitePaymentDiscountType;
  return (
    <DashboardLayout
      title={onSitePaymentDiscountType?.label}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビメニュー" }],
      }}
    >
      <PageHeader title={onSitePaymentDiscountType?.label} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {onSitePaymentDiscountType && (
        <EditPikaichiOnSitePaymentDiscountForm
          onSitePaymentDiscountType={onSitePaymentDiscountType}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingDetailType}
        />
      )}
    </DashboardLayout>
  );
};
