import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCompanySalesAnalytics = gql`
    query GetCompanySalesAnalytics($input: CompanySalesAnalyticsInput!) {
  companySalesAnalytics(input: $input) {
    rows {
      name
      shopId
      businessDaysCount
      customerCount
      newCustomerCount
      repeatVisitCustomerCount
      numPeople
      takeOutNumPeople
      eatInNumPeople
      checkedInGroupCount
      groupCount
      mobileOrderQuantity
      nonMobileOrderQuantity
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      takeOutTotalTaxIncludedAmount
      takeOutTotalTaxExcludedAmount
      eatInTotalTaxIncludedAmount
      eatInTotalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      repeatOrderQuantity
      firstTimeOrderQuantity
      orderedQuantity
      repeaterTableTotalTaxExcludedAmount
      repeaterTableTotalTaxIncludedAmount
      dinnerTotalTaxIncludedAmount
      lunchTotalTaxIncludedAmount
      faveYellTotalTaxIncludedAmount
      drinkTotalTaxIncludedAmount
      foodTotalTaxIncludedAmount
      otherTotalTaxIncludedAmount
      planTotalTaxIncludedAmount
      dinnerTotalTaxExcludedAmount
      lunchTotalTaxExcludedAmount
      faveYellTotalTaxExcludedAmount
      drinkTotalTaxExcludedAmount
      foodTotalTaxExcludedAmount
      otherTotalTaxExcludedAmount
      planTotalTaxExcludedAmount
      salesTargetAmount
      lunchCustomerCount
      dinnerCustomerCount
      ambassadorCount
      introducedCustomerCount
    }
  }
}
    `;
export type GetCompanySalesAnalyticsQueryVariables = Types.Exact<{
  input: Types.CompanySalesAnalyticsInput;
}>;


export type GetCompanySalesAnalyticsQuery = (
  { __typename?: 'query_root' }
  & { companySalesAnalytics: (
    { __typename?: 'CompanySalesAnalyticsOutput' }
    & { rows: Array<(
      { __typename?: 'CompanySalesAnalyticsOutputItem' }
      & Pick<Types.CompanySalesAnalyticsOutputItem, 'name' | 'shopId' | 'businessDaysCount' | 'customerCount' | 'newCustomerCount' | 'repeatVisitCustomerCount' | 'numPeople' | 'takeOutNumPeople' | 'eatInNumPeople' | 'checkedInGroupCount' | 'groupCount' | 'mobileOrderQuantity' | 'nonMobileOrderQuantity' | 'totalTaxIncludedAmount' | 'totalTaxExcludedAmount' | 'takeOutTotalTaxIncludedAmount' | 'takeOutTotalTaxExcludedAmount' | 'eatInTotalTaxIncludedAmount' | 'eatInTotalTaxExcludedAmount' | 'totalTaxIncludedCostAmount' | 'totalTaxExcludedCostAmount' | 'totalTaxIncludedNetProfitAmount' | 'totalTaxExcludedNetProfitAmount' | 'repeatOrderQuantity' | 'firstTimeOrderQuantity' | 'orderedQuantity' | 'repeaterTableTotalTaxExcludedAmount' | 'repeaterTableTotalTaxIncludedAmount' | 'dinnerTotalTaxIncludedAmount' | 'lunchTotalTaxIncludedAmount' | 'faveYellTotalTaxIncludedAmount' | 'drinkTotalTaxIncludedAmount' | 'foodTotalTaxIncludedAmount' | 'otherTotalTaxIncludedAmount' | 'planTotalTaxIncludedAmount' | 'dinnerTotalTaxExcludedAmount' | 'lunchTotalTaxExcludedAmount' | 'faveYellTotalTaxExcludedAmount' | 'drinkTotalTaxExcludedAmount' | 'foodTotalTaxExcludedAmount' | 'otherTotalTaxExcludedAmount' | 'planTotalTaxExcludedAmount' | 'salesTargetAmount' | 'lunchCustomerCount' | 'dinnerCustomerCount' | 'ambassadorCount' | 'introducedCustomerCount'>
    )> }
  ) }
);


export const GetCompanySalesAnalyticsDocument = gql`
    query GetCompanySalesAnalytics($input: CompanySalesAnalyticsInput!) {
  companySalesAnalytics(input: $input) {
    rows {
      name
      shopId
      businessDaysCount
      customerCount
      newCustomerCount
      repeatVisitCustomerCount
      numPeople
      takeOutNumPeople
      eatInNumPeople
      checkedInGroupCount
      groupCount
      mobileOrderQuantity
      nonMobileOrderQuantity
      totalTaxIncludedAmount
      totalTaxExcludedAmount
      takeOutTotalTaxIncludedAmount
      takeOutTotalTaxExcludedAmount
      eatInTotalTaxIncludedAmount
      eatInTotalTaxExcludedAmount
      totalTaxIncludedCostAmount
      totalTaxExcludedCostAmount
      totalTaxIncludedNetProfitAmount
      totalTaxExcludedNetProfitAmount
      repeatOrderQuantity
      firstTimeOrderQuantity
      orderedQuantity
      repeaterTableTotalTaxExcludedAmount
      repeaterTableTotalTaxIncludedAmount
      dinnerTotalTaxIncludedAmount
      lunchTotalTaxIncludedAmount
      faveYellTotalTaxIncludedAmount
      drinkTotalTaxIncludedAmount
      foodTotalTaxIncludedAmount
      otherTotalTaxIncludedAmount
      planTotalTaxIncludedAmount
      dinnerTotalTaxExcludedAmount
      lunchTotalTaxExcludedAmount
      faveYellTotalTaxExcludedAmount
      drinkTotalTaxExcludedAmount
      foodTotalTaxExcludedAmount
      otherTotalTaxExcludedAmount
      planTotalTaxExcludedAmount
      salesTargetAmount
      lunchCustomerCount
      dinnerCustomerCount
      ambassadorCount
      introducedCustomerCount
    }
  }
}
    `;

/**
 * __useGetCompanySalesAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetCompanySalesAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySalesAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySalesAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompanySalesAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetCompanySalesAnalyticsQuery, GetCompanySalesAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanySalesAnalyticsQuery, GetCompanySalesAnalyticsQueryVariables>(GetCompanySalesAnalyticsDocument, options);
      }
export function useGetCompanySalesAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanySalesAnalyticsQuery, GetCompanySalesAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanySalesAnalyticsQuery, GetCompanySalesAnalyticsQueryVariables>(GetCompanySalesAnalyticsDocument, options);
        }
export type GetCompanySalesAnalyticsQueryHookResult = ReturnType<typeof useGetCompanySalesAnalyticsQuery>;
export type GetCompanySalesAnalyticsLazyQueryHookResult = ReturnType<typeof useGetCompanySalesAnalyticsLazyQuery>;
export type GetCompanySalesAnalyticsQueryResult = Apollo.QueryResult<GetCompanySalesAnalyticsQuery, GetCompanySalesAnalyticsQueryVariables>;