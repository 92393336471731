import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationPlansGetPlans = gql`
    query TecAggregationPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const TecAggregationPlansGetCategories = gql`
    query TecAggregationPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const TecAggregationPlansGetShops = gql`
    query TecAggregationPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export type TecAggregationPlansGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plans: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'name' | 'planOptionId'>
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type TecAggregationPlansGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationPlansGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type TecAggregationPlansGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationPlansGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const TecAggregationPlansGetPlansDocument = gql`
    query TecAggregationPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useTecAggregationPlansGetPlansQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlansGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlansGetPlansQuery, TecAggregationPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlansGetPlansQuery, TecAggregationPlansGetPlansQueryVariables>(TecAggregationPlansGetPlansDocument, options);
      }
export function useTecAggregationPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlansGetPlansQuery, TecAggregationPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlansGetPlansQuery, TecAggregationPlansGetPlansQueryVariables>(TecAggregationPlansGetPlansDocument, options);
        }
export type TecAggregationPlansGetPlansQueryHookResult = ReturnType<typeof useTecAggregationPlansGetPlansQuery>;
export type TecAggregationPlansGetPlansLazyQueryHookResult = ReturnType<typeof useTecAggregationPlansGetPlansLazyQuery>;
export type TecAggregationPlansGetPlansQueryResult = Apollo.QueryResult<TecAggregationPlansGetPlansQuery, TecAggregationPlansGetPlansQueryVariables>;
export const TecAggregationPlansGetCategoriesDocument = gql`
    query TecAggregationPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useTecAggregationPlansGetCategoriesQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlansGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlansGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlansGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationPlansGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlansGetCategoriesQuery, TecAggregationPlansGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlansGetCategoriesQuery, TecAggregationPlansGetCategoriesQueryVariables>(TecAggregationPlansGetCategoriesDocument, options);
      }
export function useTecAggregationPlansGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlansGetCategoriesQuery, TecAggregationPlansGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlansGetCategoriesQuery, TecAggregationPlansGetCategoriesQueryVariables>(TecAggregationPlansGetCategoriesDocument, options);
        }
export type TecAggregationPlansGetCategoriesQueryHookResult = ReturnType<typeof useTecAggregationPlansGetCategoriesQuery>;
export type TecAggregationPlansGetCategoriesLazyQueryHookResult = ReturnType<typeof useTecAggregationPlansGetCategoriesLazyQuery>;
export type TecAggregationPlansGetCategoriesQueryResult = Apollo.QueryResult<TecAggregationPlansGetCategoriesQuery, TecAggregationPlansGetCategoriesQueryVariables>;
export const TecAggregationPlansGetShopsDocument = gql`
    query TecAggregationPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useTecAggregationPlansGetShopsQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlansGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlansGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlansGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationPlansGetShopsQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlansGetShopsQuery, TecAggregationPlansGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlansGetShopsQuery, TecAggregationPlansGetShopsQueryVariables>(TecAggregationPlansGetShopsDocument, options);
      }
export function useTecAggregationPlansGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlansGetShopsQuery, TecAggregationPlansGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlansGetShopsQuery, TecAggregationPlansGetShopsQueryVariables>(TecAggregationPlansGetShopsDocument, options);
        }
export type TecAggregationPlansGetShopsQueryHookResult = ReturnType<typeof useTecAggregationPlansGetShopsQuery>;
export type TecAggregationPlansGetShopsLazyQueryHookResult = ReturnType<typeof useTecAggregationPlansGetShopsLazyQuery>;
export type TecAggregationPlansGetShopsQueryResult = Apollo.QueryResult<TecAggregationPlansGetShopsQuery, TecAggregationPlansGetShopsQueryVariables>;