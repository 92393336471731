import React, { memo } from "react";
import styled from "styled-components";
import { BarChartOutlined } from "@ant-design/icons";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  ResponsiveContainer,
  XAxis,
} from "recharts";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";
import { useViewport } from "hooks/useViewport";
import { ScoreDistributionMetrics } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/types";

const StyledBarChartIcon = styled(BarChartOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

type Props = {
  scoreDistributionMetrics: ScoreDistributionMetrics;
};

export const ScoreDistributionGraph = memo<Props>(({ scoreDistributionMetrics }) => {
  const { isDesktop } = useViewport();
  return (
    <BasePanel
      title={
        <>
          <StyledBarChartIcon />
          スコア分布
        </>
      }
    >
      <Spacer height={16} />
      <ResponsiveContainer width={isDesktop ? 472 : "100%"} height={165}>
        <BarChart data={scoreDistributionMetrics.distributionItems} margin={{ top: 24 }}>
          <CartesianGrid vertical={false} strokeDasharray="4" />
          <XAxis dataKey="label" padding={{ right: 32 }}>
            <Label value="(点)" offset={8} position="insideTopRight" fill={grey[7]} />
          </XAxis>
          <Bar dataKey="count" fill="#408DAE" barSize={9}>
            <LabelList dataKey="count" position="top" fill="#408DAE" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </BasePanel>
  );
});
