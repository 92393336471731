const DIGITS_MILLION = 7;
const DIGITS_THOUSAND = 4;

/**
 * 100万を超える数をN万に変換
 */
export const convertUnitToTenThousand = (value: number) => {
  const valueStr = value.toString();

  // NOTE: 自然数以外にも適用したい場合は対応が必要
  const isOveredMillion = valueStr.length >= DIGITS_MILLION;

  return {
    isOveredMillion,
    value: isOveredMillion ? Number(valueStr.slice(0, valueStr.length - DIGITS_THOUSAND)) : value,
  };
};
