import React, { useCallback, useMemo, useState } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { KitchenDisplayDisplayTargetHeader } from "components/PageHeader/KitchenDisplayDisplayTargetHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditKitchenDisplayDisplayTargetMenuModal } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal";
import { MenuFilter } from "pages/KitchenDisplayDisplayTargetMenus/MenuFilter";
import { MenuTable } from "pages/KitchenDisplayDisplayTargetMenus/MenuTable";
import {
  useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation,
  useKitchenDisplayDisplayTargetMenusGetCategoriesQuery,
  useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery,
} from "pages/KitchenDisplayDisplayTargetMenus/queries";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const KitchenDisplayDisplayTargetMenus = () => {
  const { id } = useParams<{ id: string }>();
  const kdDisplayTargetId = String(id);
  const navigate = useNavigate();

  const {
    data: getKdDisplayTargetData,
    refetch,
    error: errorGetKdDisplayTargetData,
  } = useKitchenDisplayDisplayTargetMenusGetKdDisplayTargetQuery(
    kdDisplayTargetId ? { variables: { kdDisplayTargetId } } : { skip: true },
  );
  const kdDisplayTarget = getKdDisplayTargetData?.kdDisplayTarget_by_pk;
  const shop = kdDisplayTarget?.shop;
  const menus = useMemo(
    () =>
      kdDisplayTarget?.shopMenuKdDisplayTargets.flatMap(({ shopMenu }) => shopMenu?.menu ?? []) ??
      [],
    [kdDisplayTarget],
  );

  useSetCurrentCompanyEffect(shop);

  const companyId = shop?.companyId;

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    error: errorGetCategoriesData,
  } = useKitchenDisplayDisplayTargetMenusGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const shouldShowAlert = errorGetCategoriesData || errorGetKdDisplayTargetData;

  const categories = getCategoriesData?.category ?? [];

  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [deleteKdDisplayTargetMenusMutation, { loading: loadingDeleteKdDisplayTargetMenus }] =
    useKitchenDisplayDisplayTargetMenusDeleteKdDisplayTargetMenusMutation();

  const onRemove = useCallback(
    async (menuId: number) => {
      if (kdDisplayTargetId) {
        try {
          const { data: deleteKdDisplayTargetMenus } = await deleteKdDisplayTargetMenusMutation({
            variables: { menuId, kdDisplayTargetId },
          });

          const returning =
            deleteKdDisplayTargetMenus?.delete_shopMenuKdDisplayTarget?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("表示対象から削除しました");
        } catch (err) {
          message.error("表示対象からの削除に失敗しました");
        }
      }
    },
    [kdDisplayTargetId, deleteKdDisplayTargetMenusMutation, refetch],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToKitchenDisplayDisplayTargetMenus = useCallback(() => {
    navigate(`/kitchenDisplayDisplayTarget/${kdDisplayTargetId}/menu`, { replace: true });
  }, [navigate, kdDisplayTargetId]);

  const filteredMenus = useMemo(
    () =>
      categoryId
        ? menus.filter((menu) =>
            menu.categoryMenus.some((categoryMenu) => categoryMenu.categoryId === categoryId),
          )
        : menus,
    [menus, categoryId],
  );

  return (
    <DashboardLayout
      title={kdDisplayTarget?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "キッチンディスプレイ表示対象" }, { name: "メニュー" }],
      }}
    >
      <KitchenDisplayDisplayTargetHeader
        kdDisplayTarget={kdDisplayTarget ?? null}
        onBack={goBack}
      />
      {loadingCategories && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {kdDisplayTarget && !loadingCategories && (
        <FormContent>
          <ButtonWrapper>
            <Link
              key="addMenu"
              to={`/kitchenDisplayDisplayTarget/${kdDisplayTargetId}/menu/edit`}
              replace
            >
              <Button type="primary">編集</Button>
            </Link>
          </ButtonWrapper>
          <MenuFilter
            categories={categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
          <MenuTable
            menus={filteredMenus}
            loading={loadingDeleteKdDisplayTargetMenus}
            onRemove={onRemove}
          />
          <Routes>
            <Route
              path="edit"
              element={
                <EditKitchenDisplayDisplayTargetMenuModal
                  kdDisplayTarget={kdDisplayTarget}
                  goBack={goBackToKitchenDisplayDisplayTargetMenus}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
