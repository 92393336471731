import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

import { CsvNameByShops } from "../../../models/csvName";

export type AggregatedByShopsDataFormValues = {
  targetDate?: dayjs.Dayjs;
  csvNamesByShops: CsvNameByShops[];
};

const getInitialValues: () => AggregatedByShopsDataFormValues = () => ({
  csvNamesByShops: [],
});

export const AggregatedByShopsDataFormItem = createFormItem<AggregatedByShopsDataFormValues>();

export const useAggregatedByShopsDataForm = ({
  onSubmit,
}: {
  onSubmit: ({
    csvNamesByShops,
    targetDate,
  }: {
    csvNamesByShops: CsvNameByShops[];
    targetDate?: dayjs.Dayjs;
  }) => Promise<void>;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AggregatedByShopsDataFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
