import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuRecommendationTranslationsFormItem } from "./useEditMenuRecommendationTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameKrField = memo<Props>(({ ...props }) => (
  <EditMenuRecommendationTranslationsFormItem label="表示名：韓国語" name="nameKr" {...props}>
    <Input />
  </EditMenuRecommendationTranslationsFormItem>
));
