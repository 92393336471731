import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Col, DatePicker, Radio, RadioChangeEvent, Row } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const RangePickerPanelContainer = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    .ant-picker-panels {
      flex-direction: column;
    }
  }
`;

const RangePickerCol = styled(Col)`
  @media ${viewport.desktop} {
    min-width: 260px;
  }
`;

type DimensionType = "month" | "day";

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  dimension?: DimensionType;
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null] | undefined;
} & Omit<FilterConditions, "range">;

export type Props = {
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
};

export const SalesBudgetFilter = memo<Props>(({ filterConditions, updateFilterCondition }) => {
  const isMonthlyFilter = useMemo(() => filterConditions.dimension === "month", [filterConditions]);
  const onChangeRange = useCallback(
    (values: RangeValue<dayjs.Dayjs>) => {
      const start = isMonthlyFilter
        ? values?.[0]?.startOf("month") ?? null
        : values?.[0]?.startOf("day") ?? null;
      const end = isMonthlyFilter
        ? values?.[1]?.endOf("month") ?? null
        : values?.[1]?.endOf("day") ?? null;

      updateFilterCondition({ range: [start, end] });
    },
    [isMonthlyFilter, updateFilterCondition],
  );

  const onChangeDimension = useCallback(
    (e: RadioChangeEvent) =>
      updateFilterCondition({
        dimension: e.target.value as DimensionType,
      }),
    [updateFilterCondition],
  );

  const { rangePresets } = useRangePresets();

  return (
    <Wrapper>
      <ShopSelector />
      <Spacer size={24} />
      <Row gutter={[16, 16]}>
        <RangePickerCol span={24} xl={6}>
          {isMonthlyFilter ? (
            <DatePicker.RangePicker
              value={filterConditions.range}
              onCalendarChange={onChangeRange}
              clearIcon={false}
              picker="month"
              style={{ width: "100%" }}
              panelRender={(panelNode) => (
                <RangePickerPanelContainer>{panelNode}</RangePickerPanelContainer>
              )}
            />
          ) : (
            <DatePicker.RangePicker
              value={filterConditions.range}
              ranges={rangePresets}
              onCalendarChange={onChangeRange}
              clearIcon={false}
              style={{ width: "100%" }}
              panelRender={(panelNode) => (
                <RangePickerPanelContainer>{panelNode}</RangePickerPanelContainer>
              )}
            />
          )}
        </RangePickerCol>
        <>
          <Col flex={1} />
          <Col>
            <Radio.Group value={filterConditions.dimension} onChange={onChangeDimension}>
              <Radio.Button value="month">月別</Radio.Button>
              <Radio.Button value="day">日別</Radio.Button>
            </Radio.Group>
          </Col>
        </>
      </Row>
    </Wrapper>
  );
});
