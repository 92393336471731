import React, { memo, useCallback, useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import { loadImage } from "util/image";

import { message } from "components/antd/message";
import { useDinii } from "hooks/useDinii";
import { isImageType } from "libs/detectMediaType";
import { dinii } from "libs/dinii/rest";
import { logger } from "libs/logger";

import { FormImageItem } from "./FormImageItem";

type Dinii = typeof dinii;

type UploadImageApiKey = keyof {
  [K in keyof Dinii as Dinii[K] extends { uploadImage: unknown } ? K : never]: Dinii[K];
};

type Props = Omit<
  React.ComponentProps<typeof FormImageItem>,
  "isUploading" | "onUpload" | "onRemove" | "uploadFile"
> & {
  setUploadImage: (image: string) => void;
  formName: string;
  uploadImageApiKey: UploadImageApiKey;
  shouldValidateSquareImage?: boolean;
};

export const ImageField = memo<Props>(
  ({
    setUploadImage,
    formName,
    uploadImageApiKey,
    shouldValidateSquareImage,
    bottomHelpText,
    ...props
  }) => {
    const [dinii, getContext] = useDinii();
    const [uploadFile, setUploadFile] = useState<UploadFile | null>(null);

    const [{ loading: isUploading }, handleUpload] = useAsyncFn(
      async (file: UploadFile<any>) => {
        const originFile = file.originFileObj;
        if (originFile) {
          const fileType = originFile.type;

          // アスペクト比1:1以外の画像は弾く
          if (shouldValidateSquareImage && fileType && isImageType(originFile.type)) {
            const image = await loadImage(originFile);
            if (image.width !== image.height) {
              return message.error("正方形の画像を選択してください");
            }
          }

          const formData = new FormData();
          formData.append("image", originFile);
          const context = await getContext();
          if (context) {
            try {
              const { data } = await dinii[uploadImageApiKey].uploadImage(context, formData);
              const { url } = data;

              setUploadImage(url);
              setUploadFile(file);
            } catch (err) {
              if (axios.isAxiosError(err)) {
                const status = err.response?.status;

                if (status === 413) {
                  return message.error("ファイルサイズが大きすぎます");
                }
              }

              message.error("アップロードに失敗しました");
              logger.error(`Failed to upload image on ${formName}`, { error: err });
            }
          }
        }
      },
      [shouldValidateSquareImage, getContext, dinii, uploadImageApiKey, setUploadImage, formName],
    );

    const handleRemove = useCallback(() => {
      setUploadImage("");
      setUploadFile(null);
    }, [setUploadImage, setUploadFile]);

    return (
      <FormImageItem
        isUploading={isUploading}
        onUpload={handleUpload}
        onRemove={handleRemove}
        uploadFile={uploadFile}
        bottomHelpText={bottomHelpText}
        {...props}
      />
    );
  },
);
