import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useCompany } from "hooks/useCompany";
import { PlanGroup, PlanGroupInsertInput } from "types/graphql";

export type AddPlanGroupFormValues = Pick<PlanGroup, "name">;

export const AddPlanGroupFormItem = createFormItem<AddPlanGroupFormValues>();

export const useAddPlanGroupForm = (onSubmit: (planGroup: PlanGroupInsertInput) => void) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [form] = Form.useForm();

  const submit = useCallback(() => {
    if (!companyId) return;

    const formValues = form.getFieldsValue() as AddPlanGroupFormValues;

    onSubmit({
      ...formValues,
      companyId,
    });
  }, [companyId, form, onSubmit]);

  return { form, submit };
};
