import React, { memo, useCallback, useMemo } from "react";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { MenuOutlined } from "@ant-design/icons";
import { getTaxMethodName } from "models/taxMethod";
import { updatePriorities } from "util/priority";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { SortableTable } from "components/SortableTable";
import { Thumbnail } from "components/Thumbnail";
import { CategoryMenu } from "pages/MenuRecommendationMenus/types";

type Props = {
  onRemove: (categoryMenuId: number) => Promise<void>;
  loading?: boolean;
  categoryMenus: CategoryMenu[];
  menuRecommendationCategoryId: number;
  mutationDisabled: boolean;
  onChangeOrder: (args: CategoryMenu[]) => void;
};

export const MenuTable = memo<Props>(
  ({
    loading,
    categoryMenus,
    onRemove,
    menuRecommendationCategoryId,
    mutationDisabled,
    onChangeOrder,
  }) => {
    const handleMoveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        if (dragIndex !== hoverIndex) {
          onChangeOrder(
            updatePriorities(
              categoryMenus.map((menu, index) => ({ ...menu, priority: index })),
              dragIndex,
              hoverIndex,
            ),
          );
        }
      },
      [categoryMenus, onChangeOrder],
    );

    const columns: ColumnsType<CategoryMenu> = useMemo(
      () => [
        ...(!mutationDisabled
          ? [
              {
                title: "",
                width: 50,
                render() {
                  return <MenuOutlined />;
                },
              } as const,
            ]
          : []),
        {
          title: "画像",
          align: "center" as const,
          width: 100,
          render(_: string, { menu }: CategoryMenu) {
            return <Thumbnail url={menu.imageUrl} width={64} height={64} />;
          },
        },
        {
          title: "商品名",
          render(_: string, { menu }: CategoryMenu) {
            return menu.name;
          },
        } as const,
        {
          title: "カテゴリ名",
          render(_: string, { menu }: CategoryMenu) {
            return menu.categoryMenus.map(
              ({ category }) =>
                category.categoryId !== menuRecommendationCategoryId && (
                  <Tag key={category.categoryId}>{category.name}</Tag>
                ),
            );
          },
        },
        {
          title: "価格",
          render(_: string, { menu }: CategoryMenu) {
            return menu.openPrice
              ? "フリー"
              : `${menu.price.toLocaleString()}円(${getTaxMethodName(menu.taxMethod)})`;
          },
        } as const,
        ...(!mutationDisabled
          ? [
              {
                title: "",
                align: "center",
                width: 60,
                render(_: string, { categoryMenuId }: CategoryMenu) {
                  return <DeleteIcon onClick={() => onRemove(categoryMenuId)} />;
                },
              } as const,
            ]
          : []),
      ],
      [mutationDisabled, menuRecommendationCategoryId, onRemove],
    );

    return (
      <SortableTable
        type="menuRecommendationMenus"
        rowKey="categoryMenuId"
        columns={columns}
        dataSource={categoryMenus}
        loading={loading}
        bordered
        pagination={false}
        onMove={handleMoveItem}
      />
    );
  },
);
