import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCorporation } from "hooks/useCorporation";
import { DashboardAccountRoleTable } from "pages/DashboardAccountRoles/DashboardAccountRoleTable";
import {
  useDashboardAccountRolesGetDashboardAccountRolesQuery,
  useDeleteDashboardAccountRoleMutation,
} from "pages/DashboardAccountRoles/queries";
import { DashboardAccountRolePermissionTypeEnum } from "types/graphql";

import { DashboardAccountRole } from "./types";

const sortByNumOfPermissionsDesc = (
  role1: DashboardAccountRole,
  role2: DashboardAccountRole,
  type: DashboardAccountRolePermissionTypeEnum,
) => {
  const permissions1 = role1.dashboardAccountRolePermissions.filter(
    (permission) => permission.type === type,
  );
  const permissions2 = role2.dashboardAccountRolePermissions.filter(
    (permission) => permission.type === type,
  );

  return permissions2.length - permissions1.length;
};

export const DashboardAccountRoles = () => {
  const [corporation] = useCorporation();

  const {
    data: getDashboardAccountRolesData,
    loading: loadingDashboardAccountRoles,
    refetch: refetchDashboardAccountRoles,
  } = useDashboardAccountRolesGetDashboardAccountRolesQuery(
    corporation ? { variables: { corporationId: corporation.corporationId } } : { skip: true },
  );

  const dashboardAccountRoles = useMemo(() => {
    const dashboardAccountRoles = getDashboardAccountRolesData?.dashboardAccountRole ?? [];

    return [...dashboardAccountRoles].sort(
      (a, b) =>
        // createdAt がないため、編集権限の数 → 閲覧権限の数の優先度で sort する
        sortByNumOfPermissionsDesc(a, b, DashboardAccountRolePermissionTypeEnum.Edit) ||
        sortByNumOfPermissionsDesc(a, b, DashboardAccountRolePermissionTypeEnum.View),
    );
  }, [getDashboardAccountRolesData]);

  const [deleteDashboardAccountRoleMutation] = useDeleteDashboardAccountRoleMutation();

  const onDelete = useCallback(
    async ({ dashboardAccountRoleId }: { dashboardAccountRoleId: string }) => {
      try {
        await deleteDashboardAccountRoleMutation({
          variables: { dashboardAccountRoleId },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchDashboardAccountRoles();
    },
    [deleteDashboardAccountRoleMutation, refetchDashboardAccountRoles],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="権限一覧">
      <PageHeader
        title="権限一覧"
        extra={
          canAccess("addDashboardAccountRole") && (
            <Link to="/setting/accountRole/add">
              <Button type="primary">新規作成</Button>
            </Link>
          )
        }
      />
      <DashboardAccountRoleTable
        dashboardAccountRoles={dashboardAccountRoles}
        loading={loadingDashboardAccountRoles}
        onDelete={onDelete}
      />
    </DashboardLayout>
  );
};
