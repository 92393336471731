import { useCallback } from "react";
import dayjs from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import {
  DailySalesBudgetInsertInput,
  MonthlySalesBudget,
  MonthlySalesBudgetSetInput,
  SalesBudgetAllocationSetting,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import { AllocationSetting, Budget } from "../types";

export type EditSalesBudgetFormValues = Pick<MonthlySalesBudget, "shopId"> & {
  targetMonth: dayjs.Dayjs;
  monthlyBudget: number | null;
  dailyBudget: {
    [date: string]: number | null | undefined;
  } | null;
  dayOff: {
    [date: string]: boolean;
  } | null;
  allocationSetting: Pick<
    SalesBudgetAllocationSetting,
    "monRate" | "tueRate" | "wedRate" | "thuRate" | "friRate" | "satRate" | "sunRate"
  >;
  shouldSaveAllocationSetting: boolean;
};

// NOTE: 曜日ごとの売上比率を過去データから算出
const allocationSettingDefaultValue: EditSalesBudgetFormValues["allocationSetting"] = {
  monRate: 11,
  tueRate: 10,
  wedRate: 11,
  thuRate: 11,
  friRate: 18,
  satRate: 22,
  sunRate: 17,
};

const getInitialValues = ({
  budget,
  allocationSetting,
}: {
  budget: Budget;
  allocationSetting?: AllocationSetting;
}) => {
  const { shopId, businessDate, taxExcludedAmount, dailySalesBudgets } = budget;
  const dailyBudget = Object.fromEntries(
    dailySalesBudgets.map(({ businessDate, taxExcludedAmount }) => [
      dayjs(businessDate).format("YYYY-MM-DD"),
      taxExcludedAmount,
    ]),
  );
  const dayOff = Object.fromEntries(
    dailySalesBudgets.map(({ businessDate, taxExcludedAmount }) => [
      dayjs(businessDate).format("YYYY-MM-DD"),
      taxExcludedAmount === 0,
    ]),
  );

  return {
    shopId,
    targetMonth: dayjs(businessDate),
    monthlyBudget: taxExcludedAmount,
    dailyBudget,
    dayOff,
    allocationSetting: allocationSetting
      ? {
          monRate: allocationSetting.monRate,
          tueRate: allocationSetting.tueRate,
          wedRate: allocationSetting.wedRate,
          thuRate: allocationSetting.thuRate,
          friRate: allocationSetting.friRate,
          satRate: allocationSetting.satRate,
          sunRate: allocationSetting.sunRate,
        }
      : allocationSettingDefaultValue,
    shouldSaveAllocationSetting: Boolean(allocationSetting),
  };
};

export const EditSalesBudgetFormItem = createFormItem<EditSalesBudgetFormValues>();

export const useEditSalesBudgetForm = ({
  budget,
  shopId,
  onSubmit,
  onSubmitAllocationSetting,
  onFormValidationError,
  allocationSetting,
}: {
  budget: Budget;
  shopId: string;
  onSubmit: ({
    monthlySalesBudget,
    dailySalesBudgets,
  }: {
    monthlySalesBudget: MonthlySalesBudgetSetInput;
    dailySalesBudgets: DailySalesBudgetInsertInput[];
  }) => void;
  onSubmitAllocationSetting: (allocationSetting: SalesBudgetAllocationSettingInsertInput) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  allocationSetting?: AllocationSetting;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ budget, allocationSetting });

  const submit = useCallback(async () => {
    try {
      await form.validateFields();

      const now = dayjs().toISOString();
      const monthlySalesBudgetId = budget.id;
      const formValues = form.getFieldsValue() as EditSalesBudgetFormValues;

      onSubmit({
        monthlySalesBudget: {
          taxExcludedAmount: formValues.monthlyBudget,
          updatedAt: now,
        },
        dailySalesBudgets: Object.entries(formValues.dailyBudget ?? {}).map(([date, budget]) => ({
          monthlySalesBudgetId,
          businessDate: dayjs(date).format("YYYY-MM-DD"),
          createdAt: now,
          shopId,
          taxExcludedAmount: budget ?? 0,
          updatedAt: now,
        })),
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, shopId, budget.id, onSubmit, onFormValidationError]);

  const submitAllocationSetting = useCallback(async () => {
    const { allocationSetting, shouldSaveAllocationSetting } =
      form.getFieldsValue() as EditSalesBudgetFormValues;

    if (shouldSaveAllocationSetting) {
      onSubmitAllocationSetting({ shopId, ...allocationSetting });
    }
  }, [form, shopId, onSubmitAllocationSetting]);

  // NOTE: 割合設定をフォームの初期値に戻す
  const resetAllocationSettingToFormInitialValue = useCallback(() => {
    form.setFieldsValue({ allocationSetting: initialValues.allocationSetting });
  }, [form, initialValues]);

  // NOTE: 割合設定を過去データから算出したデフォルト値に戻す
  const resetAllocationSettingToDefaultValue = useCallback(() => {
    form.setFieldsValue({ allocationSetting: allocationSettingDefaultValue });
  }, [form]);

  return {
    form,
    initialValues,
    submit,
    submitAllocationSetting,
    resetAllocationSettingToFormInitialValue,
    resetAllocationSettingToDefaultValue,
  };
};
