import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const HandoutsGetShops = gql`
    query HandoutsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    onboardingHandout
  }
}
    `;
export type HandoutsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type HandoutsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'onboardingHandout'>
  )> }
);


export const HandoutsGetShopsDocument = gql`
    query HandoutsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    onboardingHandout
  }
}
    `;

/**
 * __useHandoutsGetShopsQuery__
 *
 * To run a query within a React component, call `useHandoutsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandoutsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandoutsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useHandoutsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<HandoutsGetShopsQuery, HandoutsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HandoutsGetShopsQuery, HandoutsGetShopsQueryVariables>(HandoutsGetShopsDocument, options);
      }
export function useHandoutsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HandoutsGetShopsQuery, HandoutsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HandoutsGetShopsQuery, HandoutsGetShopsQueryVariables>(HandoutsGetShopsDocument, options);
        }
export type HandoutsGetShopsQueryHookResult = ReturnType<typeof useHandoutsGetShopsQuery>;
export type HandoutsGetShopsLazyQueryHookResult = ReturnType<typeof useHandoutsGetShopsLazyQuery>;
export type HandoutsGetShopsQueryResult = Apollo.QueryResult<HandoutsGetShopsQuery, HandoutsGetShopsQueryVariables>;