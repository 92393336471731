import React, { memo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import { viewport } from "constants/viewport";

import { AccountAvatar } from "./AccountAvatar/index";

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  @media ${viewport.desktop} {
    gap: 8px;
  }
`;

const Title = styled.div`
  font-size: 24px;
`;

type Props = {
  customerName?: string;
  profileImage?: string;
  visitedAt?: string;
};

export const Header = memo<Props>(({ customerName, profileImage, visitedAt }) => (
  <Content>
    <AccountAvatar profileImage={profileImage} />
    <Title>
      {customerName ? customerName : "名前未設定"}さんの回答
      {visitedAt && `( ${dayjs(new Date(visitedAt)).format("YYYY/MM/DD HH:mm")} )`}
    </Title>
  </Content>
));
