import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";

import { OnSitePaymentDiscountTypeTable } from "./OnSitePaymentDiscountTypeTable";
import {
  useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation,
  useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery,
  useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation,
} from "./queries";

export const FoodingJournalOnSitePaymentDiscountTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getOnSitePaymentDiscountTypesData,
    loading: loadingOnSitePaymentDiscountTypes,
    error: getOnSitePaymentDiscountTypesError,
    refetch,
  } = useFoodingJournalOnSitePaymentDiscountTypesGetTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDiscountTypes = useMemo(
    () => getOnSitePaymentDiscountTypesData?.onSitePaymentDiscountType ?? [],
    [getOnSitePaymentDiscountTypesData?.onSitePaymentDiscountType],
  );

  const [
    upsertFoodingJournalOnSitePaymentDiscountTypes,
    { loading: loadingUpsertTypes, error: upsertTypesError },
  ] = useFoodingJournalOnSitePaymentDiscountTypeUpsertTypesMutation();

  const [
    deleteFoodingJournalOnSitePaymentDiscountType,
    {
      loading: loadingDeleteFoodingJournalOnSitePaymentDiscountType,
      error: deleteFoodingJournalOnSitePaymentDiscountTypeError,
    },
  ] = useFoodingJournalOnSitePaymentDiscountTypesDeleteTypeMutation();

  const refetchTypes = useCallback(async () => {
    if (!companyId) return;

    await refetch({ companyId });
  }, [companyId, refetch]);

  const upsertTypes = useCallback(
    async ({
      types,
    }: {
      types: {
        onSitePaymentDiscountTypeId: string;
        foodingJournalOnSitePaymentDiscountTypeId: string | undefined;
        discountType: number;
        code: string;
        name: string;
      }[];
    }) => {
      if (!companyId) return;

      try {
        await upsertFoodingJournalOnSitePaymentDiscountTypes({
          variables: {
            input: types.map((type) => ({
              companyId,
              id: type.foodingJournalOnSitePaymentDiscountTypeId,
              onSitePaymentDiscountTypeId: type.onSitePaymentDiscountTypeId,
              discountType: type.discountType,
              code: type.code,
              name: type.name,
            })),
          },
        });
        message.success("更新に成功しました");
      } catch {
        message.error("更新に失敗しました");
      }
    },
    [companyId, upsertFoodingJournalOnSitePaymentDiscountTypes],
  );

  const deleteType = useCallback(
    async ({
      foodingJournalOnSitePaymentDiscountTypeId,
    }: {
      foodingJournalOnSitePaymentDiscountTypeId: string;
    }) => {
      try {
        await deleteFoodingJournalOnSitePaymentDiscountType({
          variables: { foodingJournalOnSitePaymentDiscountTypeId },
        });
        message.success("設定を削除しました");
      } catch {
        message.error("設定の削除に失敗しました");
      }
    },
    [deleteFoodingJournalOnSitePaymentDiscountType],
  );

  const shouldShowAlert =
    getOnSitePaymentDiscountTypesError &&
    deleteFoodingJournalOnSitePaymentDiscountTypeError &&
    upsertTypesError;

  const loading =
    loadingOnSitePaymentDiscountTypes ||
    loadingDeleteFoodingJournalOnSitePaymentDiscountType ||
    loadingUpsertTypes;

  return (
    <DashboardLayout title="割引・値引種別設定一覧">
      <PageHeader title="割引・値引種別設定一覧" />

      {shouldShowAlert && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />

          <Spacer height={20} />
        </>
      )}

      <OnSitePaymentDiscountTypeTable
        onSitePaymentDiscountTypes={onSitePaymentDiscountTypes}
        refetchTypes={refetchTypes}
        upsertFoodingJournalOnSitePaymentDiscountTypes={upsertTypes}
        deleteFoodingJournalOnSitePaymentDiscountType={deleteType}
        loading={loading}
      />
    </DashboardLayout>
  );
};
