import React, { memo } from "react";
import styled from "styled-components";
import { Divider } from "antd";

import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";

import { TranslationDescriptionFields } from "../../../../components/TranslationsFields/TranslationDescriptionFields";
import { TranslationFeaturedLabelTextFields } from "../../../../components/TranslationsFields/TranslationFeaturedLabelTextFields";

const StyledCollapsableFormSection = styled(CollapsableFormSection)`
  padding: 24px 32px;
`;

export const PlanTranslationOptions = memo(() => (
  <StyledCollapsableFormSection title="多言語設定">
    <TranslationNameFields label="プラン名" originalFieldName="planName" />

    <Divider />

    <TranslationDescriptionFields label="プラン説明文" originalFieldName="description" />

    <Divider />

    <TranslationFeaturedLabelTextFields label="特集ラベル" originalFieldName="featuredLabelText" />
  </StyledCollapsableFormSection>
));
