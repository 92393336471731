import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QrCodesGetTable = gql`
    query QrCodesGetTable($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    tableId
  }
}
    `;
export const QrCodesGetShops = gql`
    query QrCodesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export type QrCodesGetTableQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type QrCodesGetTableQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'code' | 'name' | 'tableId'>
  )> }
);

export type QrCodesGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type QrCodesGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);


export const QrCodesGetTableDocument = gql`
    query QrCodesGetTable($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    tableId
  }
}
    `;

/**
 * __useQrCodesGetTableQuery__
 *
 * To run a query within a React component, call `useQrCodesGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodesGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodesGetTableQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useQrCodesGetTableQuery(baseOptions: Apollo.QueryHookOptions<QrCodesGetTableQuery, QrCodesGetTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QrCodesGetTableQuery, QrCodesGetTableQueryVariables>(QrCodesGetTableDocument, options);
      }
export function useQrCodesGetTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QrCodesGetTableQuery, QrCodesGetTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QrCodesGetTableQuery, QrCodesGetTableQueryVariables>(QrCodesGetTableDocument, options);
        }
export type QrCodesGetTableQueryHookResult = ReturnType<typeof useQrCodesGetTableQuery>;
export type QrCodesGetTableLazyQueryHookResult = ReturnType<typeof useQrCodesGetTableLazyQuery>;
export type QrCodesGetTableQueryResult = Apollo.QueryResult<QrCodesGetTableQuery, QrCodesGetTableQueryVariables>;
export const QrCodesGetShopsDocument = gql`
    query QrCodesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useQrCodesGetShopsQuery__
 *
 * To run a query within a React component, call `useQrCodesGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrCodesGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrCodesGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useQrCodesGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QrCodesGetShopsQuery, QrCodesGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QrCodesGetShopsQuery, QrCodesGetShopsQueryVariables>(QrCodesGetShopsDocument, options);
      }
export function useQrCodesGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QrCodesGetShopsQuery, QrCodesGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QrCodesGetShopsQuery, QrCodesGetShopsQueryVariables>(QrCodesGetShopsDocument, options);
        }
export type QrCodesGetShopsQueryHookResult = ReturnType<typeof useQrCodesGetShopsQuery>;
export type QrCodesGetShopsLazyQueryHookResult = ReturnType<typeof useQrCodesGetShopsLazyQuery>;
export type QrCodesGetShopsQueryResult = Apollo.QueryResult<QrCodesGetShopsQuery, QrCodesGetShopsQueryVariables>;