import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { OptionsField } from "components/OptionsField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { MenuOption, Option } from "../types";

import { EditMenuOptionFormValues, useEditMenuOptionForm } from "./useEditMenuOptionForm";

type Props = {
  menuOptions: MenuOption[];
  options: Option[];
  onSubmit: ({ menuOptions }: { menuOptions: { _optionId: number; priority: number }[] }) => void;
  onClose: () => void;
  loading: boolean;
  disabled: boolean;
  loadOptions: () => Promise<unknown>;
};

export const EditMenuOptionForm = memo<Props>(
  ({ menuOptions, options, onSubmit, onClose, loading, disabled, loadOptions }) => {
    const { form, submit } = useEditMenuOptionForm(menuOptions, onSubmit);

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleAddOption = useCallback(
      (option: EditMenuOptionFormValues["menuOptions"][number]) => {
        const currentOptions = form.getFieldValue(
          "menuOptions",
        ) as EditMenuOptionFormValues["menuOptions"];
        form.setFieldsValue({ menuOptions: [...currentOptions, option] });
      },
      [form],
    );

    return (
      <>
        <FormContent>
          <Form name="menuOptions" form={form} layout="vertical">
            <OptionsField
              formPropName="menuOptions"
              options={options}
              loadOptions={loadOptions}
              onAddOption={handleAddOption}
            />
          </Form>
        </FormContent>
        <Spacer height={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading} disabled={disabled}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
