import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const LineReportingBotGetLineReportingBotConfigs = gql`
    query LineReportingBotGetLineReportingBotConfigs($corporationId: uuid!) {
  lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}}
    order_by: {createdAt: desc}
  ) {
    id
    name
    isSuspended
  }
}
    `;
export type LineReportingBotGetLineReportingBotConfigsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type LineReportingBotGetLineReportingBotConfigsQuery = (
  { __typename?: 'query_root' }
  & { lineReportingBotConfig: Array<(
    { __typename?: 'lineReportingBotConfig' }
    & Pick<Types.LineReportingBotConfig, 'id' | 'name' | 'isSuspended'>
  )> }
);


export const LineReportingBotGetLineReportingBotConfigsDocument = gql`
    query LineReportingBotGetLineReportingBotConfigs($corporationId: uuid!) {
  lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}}
    order_by: {createdAt: desc}
  ) {
    id
    name
    isSuspended
  }
}
    `;

/**
 * __useLineReportingBotGetLineReportingBotConfigsQuery__
 *
 * To run a query within a React component, call `useLineReportingBotGetLineReportingBotConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineReportingBotGetLineReportingBotConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineReportingBotGetLineReportingBotConfigsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useLineReportingBotGetLineReportingBotConfigsQuery(baseOptions: Apollo.QueryHookOptions<LineReportingBotGetLineReportingBotConfigsQuery, LineReportingBotGetLineReportingBotConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LineReportingBotGetLineReportingBotConfigsQuery, LineReportingBotGetLineReportingBotConfigsQueryVariables>(LineReportingBotGetLineReportingBotConfigsDocument, options);
      }
export function useLineReportingBotGetLineReportingBotConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LineReportingBotGetLineReportingBotConfigsQuery, LineReportingBotGetLineReportingBotConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LineReportingBotGetLineReportingBotConfigsQuery, LineReportingBotGetLineReportingBotConfigsQueryVariables>(LineReportingBotGetLineReportingBotConfigsDocument, options);
        }
export type LineReportingBotGetLineReportingBotConfigsQueryHookResult = ReturnType<typeof useLineReportingBotGetLineReportingBotConfigsQuery>;
export type LineReportingBotGetLineReportingBotConfigsLazyQueryHookResult = ReturnType<typeof useLineReportingBotGetLineReportingBotConfigsLazyQuery>;
export type LineReportingBotGetLineReportingBotConfigsQueryResult = Apollo.QueryResult<LineReportingBotGetLineReportingBotConfigsQuery, LineReportingBotGetLineReportingBotConfigsQueryVariables>;