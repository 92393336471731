import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { useDeleteRoleMutation, useRolesGetRolesQuery } from "pages/Roles/queries";
import { RoleTable } from "pages/Roles/RoleTable";

export const Roles = () => {
  const { canAccess } = useCanAccess();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getRolesData,
    loading: loadingRoles,
    refetch: refetchRoles,
    error,
  } = useRolesGetRolesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const roles = useMemo(() => getRolesData?.shop_by_pk?.roles ?? [], [getRolesData]);
  const onlinePaymentPrinterRoleMetas = useMemo(
    () => getRolesData?.onlinePaymentPrinterRoleMeta ?? [],
    [getRolesData?.onlinePaymentPrinterRoleMeta],
  );

  const [deleteRoleMutation] = useDeleteRoleMutation();

  const handleRoleDelete = useCallback(
    async (roleId: string) => {
      try {
        const { errors } = await deleteRoleMutation({
          variables: { roleId },
        });

        await refetchRoles();

        if (errors) {
          message.error(errors[0]?.message || "キッチンプリンターの削除に失敗しました");
        } else {
          message.success("キッチンプリンターを削除しました");
        }
      } catch (e) {
        message.error("キッチンプリンターの削除に失敗しました");
      }
    },
    [deleteRoleMutation, refetchRoles],
  );

  return (
    <DashboardLayout title="キッチンプリンター一覧">
      <PageHeader
        title="キッチンプリンター一覧"
        extra={[
          canAccess("addRole") && (
            <Link key="add" to="/role/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <RoleTable
        roles={roles}
        onlinePaymentPrinterRoleMetas={onlinePaymentPrinterRoleMetas}
        loading={loadingRoles}
        onDelete={handleRoleDelete}
      />
    </DashboardLayout>
  );
};
