import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal, Table } from "antd";
import { isEmptyObject } from "util/object";

import { Spacer } from "components/Spacer";
import { PaymentProviderType } from "types/graphql";

import {
  EditDiniiPayConfigFormValues,
  isGmoOnlinePaymentConfig,
} from "../useEditDiniiPayConfigForm";

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const paymentProviderTypeToWord: Record<PaymentProviderType, string> = {
  [PaymentProviderType.Adyen]: "Adyen",
  [PaymentProviderType.Gmo]: "GMO",
};

const feeRateColumns = [
  {
    title: "カードブランド",
    dataIndex: "brand",
  },
  {
    title: "料率",
    dataIndex: "feeRate",
    width: 160,
  },
];

const formatFeeRate = (feeRate: number | null) => (feeRate ? `${feeRate}%` : "決済不可");

const getDataSource = ({
  visaCardFeeRate,
  masterCardFeeRate,
  jcbCardFeeRate,
  amexCardFeeRate,
  dinersCardFeeRate,
}: {
  visaCardFeeRate: number | null;
  masterCardFeeRate: number | null;
  jcbCardFeeRate: number | null;
  amexCardFeeRate: number | null;
  dinersCardFeeRate: number | null;
}) => [
  {
    brand: "VISA",
    feeRate: formatFeeRate(visaCardFeeRate),
  },
  {
    brand: "MASTER",
    feeRate: formatFeeRate(masterCardFeeRate),
  },
  {
    brand: "JCB",
    feeRate: formatFeeRate(jcbCardFeeRate),
  },
  {
    brand: "Amex",
    feeRate: formatFeeRate(amexCardFeeRate),
  },
  {
    brand: "DINERS",
    feeRate: formatFeeRate(dinersCardFeeRate),
  },
];

type Props = {
  visible: boolean;
  getFieldsValue: () => EditDiniiPayConfigFormValues;
  onCancel: () => void;
  onConfirm: () => void;
};

export const DiniiPayConfigFormConfirmModal = memo<Props>(
  ({ visible, getFieldsValue, onCancel, onConfirm }) => {
    const fieldsValue = getFieldsValue();

    if (!fieldsValue || isEmptyObject(fieldsValue)) return null;

    const { onlinePaymentConfig, terminalPaymentConfig } = fieldsValue;

    return (
      <Modal
        title={<Title>変更内容確認</Title>}
        zIndex={1002}
        centered
        closable={false}
        open={visible}
        footer={
          <>
            <Button onClick={onCancel}>キャンセル</Button>
            <Button type="primary" onClick={onConfirm}>
              登録
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        下記の内容で登録します。よろしいですか？
        {onlinePaymentConfig.paymentProvider && (
          <>
            <Spacer size={16} />
            <Title>オンライン決済</Title>
            <Spacer size={4} />
            {paymentProviderTypeToWord[onlinePaymentConfig.paymentProvider]}{" "}
            {isGmoOnlinePaymentConfig(onlinePaymentConfig) &&
              `(ID: ${onlinePaymentConfig.gmoShopId})`}
            <Spacer size={12} />
            <Table
              dataSource={getDataSource(onlinePaymentConfig)}
              columns={feeRateColumns}
              rowKey="brand"
              size="small"
              pagination={false}
              bordered
              scroll={{ y: 100 }}
            />
          </>
        )}
        {terminalPaymentConfig.useAdyenTerminalPayment && (
          <>
            <Spacer size={16} />
            <Title>オフライン決済</Title>
            <Spacer size={12} />
            <Table
              dataSource={getDataSource(terminalPaymentConfig)}
              columns={feeRateColumns}
              rowKey="brand"
              size="small"
              pagination={false}
              bordered
              scroll={{ y: 100 }}
            />
          </>
        )}
        <Spacer size={16} />
      </Modal>
    );
  },
);
