import { useCallback } from "react";
import dayjs from "dayjs";
import { sortShopBusinessOperationHours } from "models/businessOperationHour";

import { createFormItem, Form } from "components/antd/Form";
import { BusinessOperationHourTypeEnum, ShopBusinessOperationHourInsertInput } from "types/graphql";

import { Shop, ShopBusinessOperationHour } from "../types";

export type BusinessOperationHourFormValues = {
  type: BusinessOperationHourTypeEnum;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
};

export type EditShopBusinessOperationHourFormValues = {
  businessOperationHours: BusinessOperationHourFormValues[];
};

const getInitialValues = ({
  shop,
  shopBusinessOperationHours,
}: {
  shop: Shop;
  shopBusinessOperationHours: ShopBusinessOperationHour[];
}) => {
  const businessOperationHours = shopBusinessOperationHours.length
    ? sortShopBusinessOperationHours({
        shopBusinessOperationHours: shopBusinessOperationHours.map(
          ({ businessOperationHourType, start, end }) => ({
            type: businessOperationHourType,
            start: dayjs(start, "HH:mm:00"),
            end: dayjs(end, "HH:mm:00"),
          }),
        ),
        changeDateTime: shop.changeDateTime,
      })
    : [{ type: "lunch" }];

  return {
    businessOperationHours,
  };
};

export const EditShopBusinessOperationHourFormItem =
  createFormItem<EditShopBusinessOperationHourFormValues>();

export const useEditShopBusinessOperationHourForm = ({
  shop,
  shopBusinessOperationHours,
  onSubmit,
}: {
  shop: Shop;
  shopBusinessOperationHours: ShopBusinessOperationHour[];
  onSubmit: (shopBusinessOperationHours: ShopBusinessOperationHourInsertInput[]) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ shop, shopBusinessOperationHours });

  const submit = useCallback(() => {
    const { businessOperationHours } =
      form.getFieldsValue() as EditShopBusinessOperationHourFormValues;

    onSubmit(
      businessOperationHours.map(({ type, start, end }) => ({
        shopId: shop.shopId,
        businessOperationHourType: type,
        start: start.format("HH:mm:00"),
        end: end.format("HH:mm:00"),
      })),
    );
  }, [form, onSubmit, shop.shopId]);

  return { form, initialValues, submit };
};
