import React, { memo } from "react";
import { Button, Input } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import {
  AddOnSitePaymentDiscountTypeFormItem,
  useAddOnSitePaymentDiscountTypeForm,
} from "./useAddOnSitePaymentDiscountTypeForm";

type Props = {
  onSubmit: (args: { label: string; type: string }) => void;
  loading: boolean;
};

export const AddOnSitePaymentDiscountTypeForm = memo<Props>(({ onSubmit, loading }) => {
  const { form, initialValues, submit } = useAddOnSitePaymentDiscountTypeForm(onSubmit);

  return (
    <>
      <FormContent>
        <Form
          name="onSitePaymentDiscountType"
          form={form}
          layout="vertical"
          initialValues={initialValues}
        >
          <AddOnSitePaymentDiscountTypeFormItem
            label="割引方法名"
            name="label"
            rules={[{ required: true }]}
            wrapperCol={{ span: 16 }}
          >
            <Input />
          </AddOnSitePaymentDiscountTypeFormItem>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={submit} loading={loading}>
          作成
        </Button>
      </FormActions>
    </>
  );
});
