import React, { memo } from "react";
import styled from "styled-components";
import { FormInstance } from "antd/es/form/Form";

import { Form } from "components/antd/Form";
import { InputPrice } from "components/Input/InputPrice";
import {
  EditBankDepositAmountFormItem,
  EditBankDepositAmountFormValues,
  useEditBankDepositAmountForm,
} from "pages/ShopDailyCashRegisterBalancing/EditBankDepositAmountModal/EditBankDepositAmountForm/useEditBankDepositAmountForm";

const StyledInputPrice = styled(InputPrice)`
  width: 200px;
`;

type Props = {
  form: FormInstance;
  bankDepositAmount: number;
  onChange: (option: EditBankDepositAmountFormValues) => void;
};

export const EditBankDepositAmountForm = memo<Props>(({ form, bankDepositAmount, onChange }) => {
  const { initialValues, change: onValuesChange } = useEditBankDepositAmountForm(
    bankDepositAmount,
    onChange,
  );

  return (
    <Form
      name="option"
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <EditBankDepositAmountFormItem
        label="銀行預入金額"
        name="bankDepositAmount"
        rules={[{ required: true, message: "銀行預入金額を入力してください" }]}
        wrapperCol={{ span: 12 }}
      >
        <StyledInputPrice />
      </EditBankDepositAmountFormItem>
    </Form>
  );
});
