import React, { memo } from "react";
import { Radio } from "antd";
import { FormItemProps } from "antd/lib/form";
import { menuTypes } from "models/menuType";

import { FormHelp } from "components/Form/FormHelp";

import { EditMenuFormItem } from "../useEditMenuForm";

const options = Object.entries(menuTypes).map(([menuType, menuTypeName]) => ({
  label: menuTypeName,
  value: menuType,
}));

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const MenuTypeField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    label={
      <FormHelp
        label="メニュータイプ"
        help="設定したメニュータイプはお客様の注文画面への表示や、出数集計に紐づきます"
      />
    }
    name="menuType"
    {...props}
  >
    <Radio.Group options={options} optionType="button" disabled={disabled} />
  </EditMenuFormItem>
));
