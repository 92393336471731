import React, { memo } from "react";
import styled from "styled-components";
import { isUrl } from "util/url";

import { ImageMessageFormValue } from "../../types";

const Image = styled.img`
  border-radius: 15px;
  object-fit: cover;
  max-width: 180px;
  min-width: 180px;
  max-height: 180px;
  min-height: 180px;
`;

type Props = {
  message: ImageMessageFormValue;
};

export const ImageMessage = memo(({ message }: Props) => {
  const url = message.url && isUrl(message.url) ? message.url : undefined;
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Image src={message.imageUrl} alt="" />
    </a>
  ) : (
    <Image src={message.imageUrl} alt="" />
  );
});
