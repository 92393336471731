import { CashBalanceCashDenominationCount } from "types/graphql";

type CashDenominationCountType = Exclude<
  {
    [K in keyof CashBalanceCashDenominationCount]: CashBalanceCashDenominationCount[K] extends number
      ? K
      : never;
  }[keyof CashBalanceCashDenominationCount],
  undefined
>;

export const CashDenominationCountToWord: Record<CashDenominationCountType, string> = {
  oneYenCoinCount: "1円硬貨",
  tenYenCoinCount: "10円硬貨",
  oneHundredYenCoinCount: "100円硬貨",
  oneThousandYenBillCount: "千円紙幣",
  tenThousandYenBillCount: "1万円紙幣",
  twoThousandYenBillCount: "2千円紙幣",
  fiveYenCoinCount: "5円硬貨",
  fiftyYenCoinCount: "50円硬貨",
  fiveHundredYenCoinCount: "500円硬貨",
  fiveThousandYenBillCount: "5千円紙幣",
};

export const isCashDenominationCount = (
  arg: string | undefined,
): arg is CashDenominationCountType => {
  if (!arg) return false;

  return arg in CashDenominationCountToWord;
};

export const formatCashDenominationCount = (arg: string) => {
  if (isCashDenominationCount(arg)) {
    return CashDenominationCountToWord[arg];
  }

  return "";
};
