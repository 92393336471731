import React, { memo } from "react";
import { getRankViewPropertiesByScore } from "models/questionnaireAnalytics";

import { BasePanel } from "components/QuestionnaireAnalytics/BasePanel";
import { ScoreMetrics } from "components/QuestionnaireAnalytics/ScoreMetricsPanel/ScoreMetrics";
import { Spacer } from "components/Spacer";
import { useViewport } from "hooks/useViewport";

type Props = {
  title: string;
  score: number;
  fluctuation?: number;
  shouldShowRank?: boolean;
  isEmpty?: boolean;
  className?: string;
};

export const ScoreMetricsPanel = memo<Props>(
  ({ title, score, fluctuation, shouldShowRank = true, isEmpty = false, className }) => {
    const { isDesktop } = useViewport();
    return (
      <BasePanel
        className={className}
        title={
          <>
            {title}
            <Spacer size={8} />
            {!isEmpty && shouldShowRank && (
              <img
                src={getRankViewPropertiesByScore(score).iconSrc}
                width={24}
                height={24}
                alt="icon"
              />
            )}
          </>
        }
      >
        <Spacer height={isDesktop ? 24 : 16} />
        <ScoreMetrics
          score={score}
          fluctuation={fluctuation}
          shouldShowRank={shouldShowRank}
          isEmpty={isEmpty}
        />
      </BasePanel>
    );
  },
);
