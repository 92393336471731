import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TablesGetTableAreas = gql`
    query TablesGetTableAreas($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    tableAreas(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {id: asc}]
    ) {
      id
      name
      priority
      tables(
        where: {isArchived: {_eq: false}}
        order_by: [{priority: asc}, {tableId: asc}]
      ) {
        id
        name
        priority
        tableId
      }
    }
  }
}
    `;
export const TablesArchiveTable = gql`
    mutation TablesArchiveTable($input: ArchiveTableInput!) {
  archiveTable(input: $input)
}
    `;
export const TablesArchiveTableArea = gql`
    mutation TablesArchiveTableArea($tableAreaId: uuid!, $archivedAt: timestamptz!) {
  update_tableArea(
    _set: {archivedAt: $archivedAt}
    where: {id: {_eq: $tableAreaId}}
  ) {
    affected_rows
  }
}
    `;
export const TablesInsertTables = gql`
    mutation TablesInsertTables($tables: [table_insert_input!]!) {
  insert_table(
    objects: $tables
    on_conflict: {constraint: table_id_key, update_columns: [priority, tableAreaId]}
  ) {
    affected_rows
    returning {
      tableId
    }
  }
}
    `;
export const TablesInsertTableAreas = gql`
    mutation TablesInsertTableAreas($tableAreas: [tableArea_insert_input!]!) {
  insert_tableArea(
    objects: $tableAreas
    on_conflict: {constraint: tableArea_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type TablesGetTableAreasQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type TablesGetTableAreasQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { tableAreas: Array<(
      { __typename?: 'tableArea' }
      & Pick<Types.TableArea, 'id' | 'name' | 'priority'>
      & { tables: Array<(
        { __typename?: 'table' }
        & Pick<Types.Table, 'id' | 'name' | 'priority' | 'tableId'>
      )> }
    )> }
  )> }
);

export type TablesArchiveTableMutationVariables = Types.Exact<{
  input: Types.ArchiveTableInput;
}>;


export type TablesArchiveTableMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'archiveTable'>
);

export type TablesArchiveTableAreaMutationVariables = Types.Exact<{
  tableAreaId: Types.Scalars['uuid'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type TablesArchiveTableAreaMutation = (
  { __typename?: 'mutation_root' }
  & { update_tableArea?: Types.Maybe<(
    { __typename?: 'tableArea_mutation_response' }
    & Pick<Types.TableAreaMutationResponse, 'affected_rows'>
  )> }
);

export type TablesInsertTablesMutationVariables = Types.Exact<{
  tables: Array<Types.TableInsertInput> | Types.TableInsertInput;
}>;


export type TablesInsertTablesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_table?: Types.Maybe<(
    { __typename?: 'table_mutation_response' }
    & Pick<Types.TableMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'table' }
      & Pick<Types.Table, 'tableId'>
    )> }
  )> }
);

export type TablesInsertTableAreasMutationVariables = Types.Exact<{
  tableAreas: Array<Types.TableAreaInsertInput> | Types.TableAreaInsertInput;
}>;


export type TablesInsertTableAreasMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tableArea?: Types.Maybe<(
    { __typename?: 'tableArea_mutation_response' }
    & Pick<Types.TableAreaMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'tableArea' }
      & Pick<Types.TableArea, 'id'>
    )> }
  )> }
);


export const TablesGetTableAreasDocument = gql`
    query TablesGetTableAreas($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    tableAreas(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {id: asc}]
    ) {
      id
      name
      priority
      tables(
        where: {isArchived: {_eq: false}}
        order_by: [{priority: asc}, {tableId: asc}]
      ) {
        id
        name
        priority
        tableId
      }
    }
  }
}
    `;

/**
 * __useTablesGetTableAreasQuery__
 *
 * To run a query within a React component, call `useTablesGetTableAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useTablesGetTableAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTablesGetTableAreasQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useTablesGetTableAreasQuery(baseOptions: Apollo.QueryHookOptions<TablesGetTableAreasQuery, TablesGetTableAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TablesGetTableAreasQuery, TablesGetTableAreasQueryVariables>(TablesGetTableAreasDocument, options);
      }
export function useTablesGetTableAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TablesGetTableAreasQuery, TablesGetTableAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TablesGetTableAreasQuery, TablesGetTableAreasQueryVariables>(TablesGetTableAreasDocument, options);
        }
export type TablesGetTableAreasQueryHookResult = ReturnType<typeof useTablesGetTableAreasQuery>;
export type TablesGetTableAreasLazyQueryHookResult = ReturnType<typeof useTablesGetTableAreasLazyQuery>;
export type TablesGetTableAreasQueryResult = Apollo.QueryResult<TablesGetTableAreasQuery, TablesGetTableAreasQueryVariables>;
export const TablesArchiveTableDocument = gql`
    mutation TablesArchiveTable($input: ArchiveTableInput!) {
  archiveTable(input: $input)
}
    `;
export type TablesArchiveTableMutationFn = Apollo.MutationFunction<TablesArchiveTableMutation, TablesArchiveTableMutationVariables>;

/**
 * __useTablesArchiveTableMutation__
 *
 * To run a mutation, you first call `useTablesArchiveTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTablesArchiveTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tablesArchiveTableMutation, { data, loading, error }] = useTablesArchiveTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTablesArchiveTableMutation(baseOptions?: Apollo.MutationHookOptions<TablesArchiveTableMutation, TablesArchiveTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TablesArchiveTableMutation, TablesArchiveTableMutationVariables>(TablesArchiveTableDocument, options);
      }
export type TablesArchiveTableMutationHookResult = ReturnType<typeof useTablesArchiveTableMutation>;
export type TablesArchiveTableMutationResult = Apollo.MutationResult<TablesArchiveTableMutation>;
export type TablesArchiveTableMutationOptions = Apollo.BaseMutationOptions<TablesArchiveTableMutation, TablesArchiveTableMutationVariables>;
export const TablesArchiveTableAreaDocument = gql`
    mutation TablesArchiveTableArea($tableAreaId: uuid!, $archivedAt: timestamptz!) {
  update_tableArea(
    _set: {archivedAt: $archivedAt}
    where: {id: {_eq: $tableAreaId}}
  ) {
    affected_rows
  }
}
    `;
export type TablesArchiveTableAreaMutationFn = Apollo.MutationFunction<TablesArchiveTableAreaMutation, TablesArchiveTableAreaMutationVariables>;

/**
 * __useTablesArchiveTableAreaMutation__
 *
 * To run a mutation, you first call `useTablesArchiveTableAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTablesArchiveTableAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tablesArchiveTableAreaMutation, { data, loading, error }] = useTablesArchiveTableAreaMutation({
 *   variables: {
 *      tableAreaId: // value for 'tableAreaId'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useTablesArchiveTableAreaMutation(baseOptions?: Apollo.MutationHookOptions<TablesArchiveTableAreaMutation, TablesArchiveTableAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TablesArchiveTableAreaMutation, TablesArchiveTableAreaMutationVariables>(TablesArchiveTableAreaDocument, options);
      }
export type TablesArchiveTableAreaMutationHookResult = ReturnType<typeof useTablesArchiveTableAreaMutation>;
export type TablesArchiveTableAreaMutationResult = Apollo.MutationResult<TablesArchiveTableAreaMutation>;
export type TablesArchiveTableAreaMutationOptions = Apollo.BaseMutationOptions<TablesArchiveTableAreaMutation, TablesArchiveTableAreaMutationVariables>;
export const TablesInsertTablesDocument = gql`
    mutation TablesInsertTables($tables: [table_insert_input!]!) {
  insert_table(
    objects: $tables
    on_conflict: {constraint: table_id_key, update_columns: [priority, tableAreaId]}
  ) {
    affected_rows
    returning {
      tableId
    }
  }
}
    `;
export type TablesInsertTablesMutationFn = Apollo.MutationFunction<TablesInsertTablesMutation, TablesInsertTablesMutationVariables>;

/**
 * __useTablesInsertTablesMutation__
 *
 * To run a mutation, you first call `useTablesInsertTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTablesInsertTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tablesInsertTablesMutation, { data, loading, error }] = useTablesInsertTablesMutation({
 *   variables: {
 *      tables: // value for 'tables'
 *   },
 * });
 */
export function useTablesInsertTablesMutation(baseOptions?: Apollo.MutationHookOptions<TablesInsertTablesMutation, TablesInsertTablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TablesInsertTablesMutation, TablesInsertTablesMutationVariables>(TablesInsertTablesDocument, options);
      }
export type TablesInsertTablesMutationHookResult = ReturnType<typeof useTablesInsertTablesMutation>;
export type TablesInsertTablesMutationResult = Apollo.MutationResult<TablesInsertTablesMutation>;
export type TablesInsertTablesMutationOptions = Apollo.BaseMutationOptions<TablesInsertTablesMutation, TablesInsertTablesMutationVariables>;
export const TablesInsertTableAreasDocument = gql`
    mutation TablesInsertTableAreas($tableAreas: [tableArea_insert_input!]!) {
  insert_tableArea(
    objects: $tableAreas
    on_conflict: {constraint: tableArea_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type TablesInsertTableAreasMutationFn = Apollo.MutationFunction<TablesInsertTableAreasMutation, TablesInsertTableAreasMutationVariables>;

/**
 * __useTablesInsertTableAreasMutation__
 *
 * To run a mutation, you first call `useTablesInsertTableAreasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTablesInsertTableAreasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tablesInsertTableAreasMutation, { data, loading, error }] = useTablesInsertTableAreasMutation({
 *   variables: {
 *      tableAreas: // value for 'tableAreas'
 *   },
 * });
 */
export function useTablesInsertTableAreasMutation(baseOptions?: Apollo.MutationHookOptions<TablesInsertTableAreasMutation, TablesInsertTableAreasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TablesInsertTableAreasMutation, TablesInsertTableAreasMutationVariables>(TablesInsertTableAreasDocument, options);
      }
export type TablesInsertTableAreasMutationHookResult = ReturnType<typeof useTablesInsertTableAreasMutation>;
export type TablesInsertTableAreasMutationResult = Apollo.MutationResult<TablesInsertTableAreasMutation>;
export type TablesInsertTableAreasMutationOptions = Apollo.BaseMutationOptions<TablesInsertTableAreasMutation, TablesInsertTableAreasMutationVariables>;