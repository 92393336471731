import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { MenuRecommendationMeta } from "types/graphql";

export type AddMenuRecommendationFormValues = Pick<MenuRecommendationMeta, "name"> & {
  nameSources: LocaleValuePair[];
};

export const AddMenuRecommendationFormItem = createFormItem<AddMenuRecommendationFormValues>();

export const useAddMenuRecommendationForm = (
  onSubmit: (
    menuRecommendationInput: AddMenuRecommendationFormValues & {
      nameSources: LocaleValuePair[];
    },
  ) => void,
) => {
  const [form] = Form.useForm<AddMenuRecommendationFormValues>();
  const submit = useCallback(() => {
    const { name, nameSources } = form.getFieldsValue();
    onSubmit({
      name,
      nameSources: (nameSources ?? []).filter(({ value }) => value),
    });
  }, [form, onSubmit]);

  return { form, submit };
};
