import React, { useCallback, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { TimePicker as Original, TimePickerProps as OriginalTimePickerProps } from "antd";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { NamePath } from "antd/es/form/interface";
import { Dayjs } from "dayjs";

export type TimePickerProps = {
  formItemName?: NamePath;
} & OriginalTimePickerProps;

export const TimePicker = (props: TimePickerProps) => {
  const { onChange, formItemName, onSelect, value, defaultValue } = props;
  const form = useFormInstance();

  /* 
        If we received a formItemName, we know this component is being used in a form, so we can get the value directly from the form.
        Otherwise, this picker will be a manually controlled component, so we'll use <props className="value"></props>

        We use "initialValue" to check if the minute has changed (so we can close the time picker automatically).
    */
  const initialValue: Dayjs | undefined = formItemName
    ? form.getFieldValue(formItemName)
    : value ?? defaultValue ?? undefined;

  const timeValueRef = useRef<Dayjs | undefined | null>(initialValue);
  const timePickerRef = useRef<HTMLInputElement>(null);

  const handleSelect = useCallback(
    (newValue: Dayjs | null) => {
      if (!newValue) {
        return;
      }

      const currentMinute = timeValueRef.current?.minute();
      const currentHour = timeValueRef.current?.hour();
      /*
        There is a bug here that will close the time picker when the same value is selected for the hour.
        This is an edge case and the solution was complex, so it was reverted.
      */
      if (
        typeof currentMinute === "number" &&
        (currentMinute !== newValue.minute() ||
          (currentMinute === newValue.minute() && currentHour === newValue.hour()))
      ) {
        timePickerRef.current?.blur();
      }

      timeValueRef.current = newValue;

      if (formItemName) {
        form.setFieldValue(formItemName, newValue);
      }

      if (onChange) {
        onChange(timeValueRef.current, timeValueRef.current.toISOString());
      }

      if (onSelect) {
        // Call onSelect here to allow this component to be interchangeable with the standard TimePicker component.
        onSelect(newValue);
      }
    },
    [form, formItemName, onChange, onSelect],
  );

  const handleChange = useCallback(
    (newValue: Dayjs | null, dateString: string) => {
      // We need to set the value here separately to change the ref value when the clear button (X) is pressed.
      timeValueRef.current = newValue;

      if (onChange) {
        onChange(newValue, dateString);
      }
    },
    [onChange],
  );

  return (
    <Original
      format="HH:mm"
      showNow={false}
      {...props}
      ref={timePickerRef}
      onSelect={handleSelect}
      onChange={handleChange}
      popupClassName={[
        "time-picker-no-submit-popup",
        ...(props.showNow ? ["time-picker-show-now"] : []),
        ...(props.popupClassName ? [props.popupClassName] : []),
      ].join(" ")}
    />
  );
};
