import React, { memo, useMemo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { range } from "lodash";
import {
  dayOfWeekToSalesBudgetAllocationSettingColumnName,
  getDayOfWeek,
} from "models/salesBudget";
import { sumBy } from "util/array";
import { parsePercentage } from "util/input/parsePercentage";
import { formatPrice } from "util/price";

import { FormHelp } from "components/Form/FormHelp";
import { InputNumber } from "components/Input/InputNumber";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";

import { AverageAndMaxSalesByDayOfWeek } from "../../../types";
import { EditSalesBudgetFormItem } from "../../useEditSalesBudgetForm";

const Table = styled.div`
  display: flex;
  border: 1px solid ${grey[3]};
`;

const Column = styled.div`
  width: 160px;
`;

const Header = styled.div`
  font-weight: bold;
  padding: 16px;
  background-color: ${grey[1]};
  border: 1px solid ${grey[3]};
`;

const Cell = styled.div`
  padding: 16px;
  border: 1px solid ${grey[3]};
`;

const PriceAndPercentage = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

type Props = {
  averageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek[];
};

const getPercentage = ({ value, total }: { value: number; total: number }) =>
  Math.round((value / total) * 100);

export const AllocationSettingModalTable = memo<Props>(({ averageAndMaxSalesByDayOfWeek }) => {
  const totalAverageTaxExcludedSalesAmount = sumBy(
    averageAndMaxSalesByDayOfWeek,
    ({ averageTaxExcludedSalesAmount }) => averageTaxExcludedSalesAmount ?? 0,
  );
  const totalMaxTaxExcludedSalesAmount = sumBy(
    averageAndMaxSalesByDayOfWeek,
    ({ maxTaxExcludedSalesAmount }) => maxTaxExcludedSalesAmount ?? 0,
  );

  const columns = useMemo(
    () =>
      range(0, 7).map((i) => {
        const dayOfWeek = getDayOfWeek(dayjs().day((i + 1) % 7));

        const averageAndMaxSales = averageAndMaxSalesByDayOfWeek?.find(
          (sale) => sale.dayOfWeek === dayOfWeek,
        );
        const columnName = dayOfWeekToSalesBudgetAllocationSettingColumnName[dayOfWeek];

        return {
          dayOfWeek,
          averageAndMaxSales,
          columnName,
        };
      }),
    [averageAndMaxSalesByDayOfWeek],
  );

  return (
    <Table>
      {columns.map(({ dayOfWeek, averageAndMaxSales, columnName }) => (
        <Column key={dayOfWeek}>
          <Header>{dayOfWeek}</Header>
          <Cell>
            <EditSalesBudgetFormItem name={["allocationSetting", columnName]} noStyle>
              <InputNumber
                formatter={(value) => `${value}%`}
                parser={parsePercentage}
                min={0}
                max={100}
              />
            </EditSalesBudgetFormItem>
          </Cell>
          <Cell>
            {dayOfWeek === "月" ? (
              <FormHelp
                label="平均売上"
                help={
                  <>
                    過去3ヶ月の各曜日別
                    <br />
                    平均売上（税抜）です
                  </>
                }
              />
            ) : (
              <span>平均売上</span>
            )}
            <PriceAndPercentage>
              {averageAndMaxSales?.averageTaxExcludedSalesAmount
                ? formatPrice(averageAndMaxSales.averageTaxExcludedSalesAmount)
                : "-"}
              (
              {totalAverageTaxExcludedSalesAmount > 0
                ? getPercentage({
                    value: averageAndMaxSales?.averageTaxExcludedSalesAmount ?? 0,
                    total: totalAverageTaxExcludedSalesAmount,
                  })
                : "-"}
              %)
            </PriceAndPercentage>
            <Spacer size={8} />
            {dayOfWeek === "月" ? (
              <FormHelp
                label="最高売上"
                help={
                  <>
                    過去3ヶ月の各曜日別
                    <br />
                    最高売上（税抜）です
                  </>
                }
              />
            ) : (
              <span>最高売上</span>
            )}
            <PriceAndPercentage>
              {averageAndMaxSales?.maxTaxExcludedSalesAmount
                ? formatPrice(averageAndMaxSales.maxTaxExcludedSalesAmount)
                : "-"}
              (
              {totalMaxTaxExcludedSalesAmount > 0
                ? getPercentage({
                    value: averageAndMaxSales?.maxTaxExcludedSalesAmount ?? 0,
                    total: totalMaxTaxExcludedSalesAmount,
                  })
                : "-"}
              %)
            </PriceAndPercentage>
          </Cell>
        </Column>
      ))}
    </Table>
  );
});
