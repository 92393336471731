import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditDashboardAccountFormItem } from "../useEditDashboardAccountForm";

const { Option } = Select;

type Props = { disabled?: boolean } & Omit<FormItemProps, "children" | "name">;

export const ScopeField = memo<Props>(({ disabled = false, ...props }) => (
  <EditDashboardAccountFormItem
    label="アクセス可能範囲"
    name="scope"
    rules={[{ required: true, message: "アクセス可能範囲を選択してください" }]}
    {...props}
  >
    <Select<string> placeholder="アクセス可能範囲" disabled={disabled}>
      {[
        { name: "法人", value: "corporation" },
        { name: "業態", value: "company" },
        { name: "店舗", value: "shop" },
      ].map(({ name, value }) => (
        <Option key={value} value={value}>
          {name}
        </Option>
      ))}
    </Select>
  </EditDashboardAccountFormItem>
));
