import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Dealers } from "components/Dealers";
import { Menu as MenuType, Shop } from "types/graphql";

type Props = {
  menu:
    | (Pick<MenuType, "name" | "menuId" | "totalDealingShopCount"> & {
        shopMenus: { shop: Pick<Shop, "shopId" | "name"> }[];
      })
    | null;
  onBack: () => void;
};

export const TecAggregationMenuHeader = memo<Props>(({ menu, onBack }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[4];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  const dealers = menu?.shopMenus.flatMap(({ shop }) => shop);

  return (
    <>
      <PageHeader
        onBack={onBack}
        title={menu?.name ?? ""}
        footer={
          <Dealers
            shops={dealers ?? null}
            hiddenShopCount={(menu?.totalDealingShopCount ?? 0) - (menu?.shopMenus.length ?? 0)}
          />
        }
      />
      {menu && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/tec/menu/${menu.menuId}/edit`} replace>
              メニュー
            </Link>
          </Menu.Item>
          <Menu.Item key="option">
            <Link to={`/tec/menu/${menu.menuId}/option`} replace>
              オプション
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
