import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { NameField } from "pages/EditDashboardAccountRole/EditDashboardAccountRoleForm/NameField";
import { PermissionsField } from "pages/EditDashboardAccountRole/EditDashboardAccountRoleForm/PermissionsField";
import {
  DashboardAccountRoleFormValuesWithPermissions,
  useEditDashboardAccountRoleForm,
} from "pages/EditDashboardAccountRole/EditDashboardAccountRoleForm/useEditDashboardAccountRoleForm";
import { DashboardAccountRole } from "pages/EditDashboardAccountRole/types";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  dashboardAccountRole: DashboardAccountRole;
  onSubmit: (formValues: DashboardAccountRoleFormValuesWithPermissions) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
};

export const EditDashboardAccountRoleForm = memo<Props>(
  ({ dashboardAccountRole, onSubmit, onFormValidationError, loading }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const { form, initialValues, submit, permissions, setPermission } =
      useEditDashboardAccountRoleForm({ dashboardAccountRole, onSubmit });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <Wrapper>
        <PageHeader title="権限を編集" onBack={goBack} />
        <StyledForm
          name="dashboardAccountRole"
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <FormSection title="権限の名称">
            <NameField />
          </FormSection>
          <FormSection title="権限の有効範囲">
            <PermissionsField permissions={permissions} setPermission={setPermission} />
          </FormSection>
        </StyledForm>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={goBack}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </Wrapper>
    );
  },
);
