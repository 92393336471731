import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { OnSitePaymentDiscountType } from "pages/EditOnSitePaymentDiscountType/types";
import { OnSitePaymentDiscountTypeSetInput } from "types/graphql";

export type EditOnSitePaymentDiscountTypeFormValues = Pick<OnSitePaymentDiscountType, "label">;

const getInitialValues = (onSitePaymentDiscountType: OnSitePaymentDiscountType) => {
  const { label } = onSitePaymentDiscountType;
  return { label };
};

export const EditOnSitePaymentDiscountTypeFormItem =
  createFormItem<EditOnSitePaymentDiscountTypeFormValues>();

export const useEditOnSitePaymentDiscountTypeForm = (
  onSitePaymentDiscountType: OnSitePaymentDiscountType,
  onSubmit: (onSitePaymentDiscountType: OnSitePaymentDiscountTypeSetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(onSitePaymentDiscountType);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditOnSitePaymentDiscountTypeFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
