import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiUploadGetShops = gql`
    query PikaichiUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    pikaichiConfig {
      enabled
    }
  }
}
    `;
export type PikaichiUploadGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiUploadGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { pikaichiConfig?: Types.Maybe<(
      { __typename?: 'pikaichiConfig' }
      & Pick<Types.PikaichiConfig, 'enabled'>
    )> }
  )> }
);


export const PikaichiUploadGetShopsDocument = gql`
    query PikaichiUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    pikaichiConfig {
      enabled
    }
  }
}
    `;

/**
 * __usePikaichiUploadGetShopsQuery__
 *
 * To run a query within a React component, call `usePikaichiUploadGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiUploadGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiUploadGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiUploadGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PikaichiUploadGetShopsQuery, PikaichiUploadGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiUploadGetShopsQuery, PikaichiUploadGetShopsQueryVariables>(PikaichiUploadGetShopsDocument, options);
      }
export function usePikaichiUploadGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiUploadGetShopsQuery, PikaichiUploadGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiUploadGetShopsQuery, PikaichiUploadGetShopsQueryVariables>(PikaichiUploadGetShopsDocument, options);
        }
export type PikaichiUploadGetShopsQueryHookResult = ReturnType<typeof usePikaichiUploadGetShopsQuery>;
export type PikaichiUploadGetShopsLazyQueryHookResult = ReturnType<typeof usePikaichiUploadGetShopsLazyQuery>;
export type PikaichiUploadGetShopsQueryResult = Apollo.QueryResult<PikaichiUploadGetShopsQuery, PikaichiUploadGetShopsQueryVariables>;