import { TecAggregationIntegrationTypeEnum } from "types/graphql";

export const integrationTypes = {
  gulfNet: "ガルフネット連携",
  aspit: "ASPIT連携",
  datatrade: "データトレード連携",
  mierukaSystem: "ミエルカシステム連携",
  makaseteNet: "まかせてネット連携",
  hanzo: "HANZO連携",
  rakmy: "ラクミー連携",
} as const;

export const isTecAggregationIntegrationType = (
  integrationTypeKey: string,
): integrationTypeKey is TecAggregationIntegrationTypeEnum =>
  integrationTypeKey in integrationTypes;

export const getTecAggregationIntegrationTypeName = (integrationTypeKey: string) =>
  isTecAggregationIntegrationType(integrationTypeKey)
    ? integrationTypes[integrationTypeKey]
    : ("" as const);
