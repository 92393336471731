import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";

import { EditGoogleMapConfigFormValues } from "./EditGoogleMapConfigForm/useEditGoogleMapConfigForm";
import { EditGoogleMapConfigForm } from "./EditGoogleMapConfigForm";
import {
  useEditGoogleMapConfigGetConfigQuery,
  useEditGoogleMapConfigUpdateGoogleMapConfigMutation,
} from "./queries";

export const EditGoogleMapConfig = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const { id: shopId } = useParams<{ id: string }>();

  const {
    data: getShopData,
    loading: loadingShop,
    error: getShopError,
  } = useEditGoogleMapConfigGetConfigQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shop = getShopData?.shop;

  const [updateGoogleMapConfigMutation, { loading: updating }] =
    useEditGoogleMapConfigUpdateGoogleMapConfigMutation();

  const onSubmit = useCallback(
    async (values: EditGoogleMapConfigFormValues) => {
      if (!shopId) return;

      const { enableGoogleMapReview, googleMapPlaceId } = values;
      try {
        await updateGoogleMapConfigMutation({
          variables: { shopId, enableGoogleMapReview, googleMapPlaceId },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateGoogleMapConfigMutation],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const shouldShowError = getShopError;
  const loading = loadingShop;

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}
      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <EditGoogleMapConfigForm
          shop={shop}
          loading={updating}
          onSubmit={onSubmit}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
