import React, { memo } from "react";

import { CumulativeCountDisplay } from "components/CumulativeCountDisplay";

import type { CumulativeCount } from "../types";

type Props = {
  cumulativeCount: CumulativeCount | undefined;
  loading: boolean;
};

export const CustomerDashboardCumulativeCustomerIdCount = memo<Props>(
  ({ cumulativeCount, loading }) => (
    <CumulativeCountDisplay
      title="獲得ユーザー累計ID"
      cumulativeCount={cumulativeCount?.lineIdCount}
      isLoading={loading}
      unitName="ID"
    />
  ),
);
