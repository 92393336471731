import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopExternalOnlineMenuConfigFormItem } from "../useEditShopExternalOnlineMenuConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const UrlField = memo<Props>((props) => (
  <EditShopExternalOnlineMenuConfigFormItem
    label="メニューURL"
    name="defaultMenuUrl"
    rules={[{ required: true, message: "メニューURLを入力してください" }]}
    required
    {...props}
  >
    <Input />
  </EditShopExternalOnlineMenuConfigFormItem>
));
