import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddInflowSourceTagGetInflowSourceTagMaxPriority = gql`
    query AddInflowSourceTagGetInflowSourceTagMaxPriority($companyId: uuid!) {
  inflowSourceTag_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddInflowSourceTagInsertInflowSourceTag = gql`
    mutation AddInflowSourceTagInsertInflowSourceTag($inflowSourceTag: inflowSourceTag_insert_input!) {
  insert_inflowSourceTag_one(object: $inflowSourceTag) {
    __typename
  }
}
    `;
export type AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { inflowSourceTag_aggregate: (
    { __typename?: 'inflowSourceTag_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'inflowSourceTag_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'inflowSourceTag_max_fields' }
        & Pick<Types.InflowSourceTagMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddInflowSourceTagInsertInflowSourceTagMutationVariables = Types.Exact<{
  inflowSourceTag: Types.InflowSourceTagInsertInput;
}>;


export type AddInflowSourceTagInsertInflowSourceTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_inflowSourceTag_one?: Types.Maybe<{ __typename: 'inflowSourceTag' }> }
);


export const AddInflowSourceTagGetInflowSourceTagMaxPriorityDocument = gql`
    query AddInflowSourceTagGetInflowSourceTagMaxPriority($companyId: uuid!) {
  inflowSourceTag_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery, AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery, AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables>(AddInflowSourceTagGetInflowSourceTagMaxPriorityDocument, options);
      }
export function useAddInflowSourceTagGetInflowSourceTagMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery, AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery, AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables>(AddInflowSourceTagGetInflowSourceTagMaxPriorityDocument, options);
        }
export type AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryHookResult = ReturnType<typeof useAddInflowSourceTagGetInflowSourceTagMaxPriorityQuery>;
export type AddInflowSourceTagGetInflowSourceTagMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddInflowSourceTagGetInflowSourceTagMaxPriorityLazyQuery>;
export type AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryResult = Apollo.QueryResult<AddInflowSourceTagGetInflowSourceTagMaxPriorityQuery, AddInflowSourceTagGetInflowSourceTagMaxPriorityQueryVariables>;
export const AddInflowSourceTagInsertInflowSourceTagDocument = gql`
    mutation AddInflowSourceTagInsertInflowSourceTag($inflowSourceTag: inflowSourceTag_insert_input!) {
  insert_inflowSourceTag_one(object: $inflowSourceTag) {
    __typename
  }
}
    `;
export type AddInflowSourceTagInsertInflowSourceTagMutationFn = Apollo.MutationFunction<AddInflowSourceTagInsertInflowSourceTagMutation, AddInflowSourceTagInsertInflowSourceTagMutationVariables>;

/**
 * __useAddInflowSourceTagInsertInflowSourceTagMutation__
 *
 * To run a mutation, you first call `useAddInflowSourceTagInsertInflowSourceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInflowSourceTagInsertInflowSourceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInflowSourceTagInsertInflowSourceTagMutation, { data, loading, error }] = useAddInflowSourceTagInsertInflowSourceTagMutation({
 *   variables: {
 *      inflowSourceTag: // value for 'inflowSourceTag'
 *   },
 * });
 */
export function useAddInflowSourceTagInsertInflowSourceTagMutation(baseOptions?: Apollo.MutationHookOptions<AddInflowSourceTagInsertInflowSourceTagMutation, AddInflowSourceTagInsertInflowSourceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInflowSourceTagInsertInflowSourceTagMutation, AddInflowSourceTagInsertInflowSourceTagMutationVariables>(AddInflowSourceTagInsertInflowSourceTagDocument, options);
      }
export type AddInflowSourceTagInsertInflowSourceTagMutationHookResult = ReturnType<typeof useAddInflowSourceTagInsertInflowSourceTagMutation>;
export type AddInflowSourceTagInsertInflowSourceTagMutationResult = Apollo.MutationResult<AddInflowSourceTagInsertInflowSourceTagMutation>;
export type AddInflowSourceTagInsertInflowSourceTagMutationOptions = Apollo.BaseMutationOptions<AddInflowSourceTagInsertInflowSourceTagMutation, AddInflowSourceTagInsertInflowSourceTagMutationVariables>;