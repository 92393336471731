import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { Option } from "../types";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  options: Option[];
  onDelete: ({ optionId }: { optionId: number }) => void;
};

export const OptionTable = memo<Props>(({ loading, options, onDelete }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const [pagination, setPagination] = usePagination();

  const columns: ColumnsType<Option> = [
    {
      title: "オプション名",
      dataIndex: "name",
      render(text: string, { optionId }: Option) {
        return (
          <Link to={`/option/${optionId}/edit`}>
            <Button type="link">{text}</Button>
          </Link>
        );
      },
    },
    {
      title: "選択肢",
      width: 300,
      render(_: string, { choices }: Option) {
        return (
          <>
            {choices.map((choice) => (
              <Tag key={choice.choiceId}>{choice.name}</Tag>
            ))}
          </>
        );
      },
    },
    {
      title: "紐づいているメニュー",
      width: 300,
      render(_: string, { menuOptions }: Option) {
        return (
          <>
            {menuOptions.map((menuOption) => (
              <Tag key={menuOption.menu.menuId}>{menuOption.menu.name}</Tag>
            ))}
          </>
        );
      },
    },
    ...(isFeatureEnabled("addOption")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { optionId }: Option) {
              return (
                <Tooltip title="コピーして新規作成">
                  <IconLink to={`/option/add?optionId=${optionId}`}>
                    <CopyIcon />
                  </IconLink>
                </Tooltip>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("deleteOption")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { optionId }: Option) {
              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>オプションを削除しますか？</Paragraph>
                      <Paragraph>一度削除したオプションを元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDelete({ optionId });
                  }}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="optionId"
      columns={columns}
      dataSource={options}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
