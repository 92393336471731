import React, { memo } from "react";
import styled from "styled-components";
import { Progress } from "antd";

import { DistributionItem } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/types";

const DistributionTypeAnswerLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

type Props = {
  distribution: DistributionItem;
  totalCount: number;
  isEmpty: boolean;
};

export const DistributionTypeAnswerListItem = memo<Props>(
  ({ distribution, totalCount, isEmpty }) => (
    <div>
      <DistributionTypeAnswerLabelWrapper>
        <span>{distribution.label}</span>
        {!isEmpty && <BoldText>{distribution.count}</BoldText>}
      </DistributionTypeAnswerLabelWrapper>
      <Progress
        percent={totalCount === 0 ? 0 : (distribution.count / totalCount) * 100}
        strokeColor="#408DAE"
        showInfo={false}
      />
    </div>
  ),
);
