import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "antd";

export type DashboardAccountsFilterConditions = {
  query?: string;
};

const { Search } = Input;

const QueryInput = styled(Search)`
  max-width: 320px;
`;

type Props = {
  onChangeFilterConditions(filterConditions: DashboardAccountsFilterConditions): void;
};

export const DashboardAccountFilter = memo<Props>(({ onChangeFilterConditions }) => {
  const [filterConditions, setFilterConditions] = useState<DashboardAccountsFilterConditions>({
    query: undefined,
  });

  useEffect(() => {
    onChangeFilterConditions(filterConditions);
  }, [filterConditions, onChangeFilterConditions]);

  const onChangeQuery = useCallback(
    (query: string) => {
      if (!query) {
        return setFilterConditions({ ...filterConditions, query: undefined });
      }

      setFilterConditions({ ...filterConditions, query: `%${query}%` });
    },
    [filterConditions],
  );

  return <QueryInput placeholder="氏名,店舗名,メールアドレス" onSearch={onChangeQuery} />;
});
