import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import table from "antd/es/table";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { TableAreaSetInput } from "types/graphql";

import { EditTableAreaForm } from "./EditTableAreaForm";
import {
  useEditTableAreaGetTableAreaQuery,
  useEditTableAreaUpdateTableAreaMutation,
} from "./queries";

export const EditTableArea = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: getTableAreaData,
    loading: loadingTableArea,
    refetch: refetchTableArea,
    error,
  } = useEditTableAreaGetTableAreaQuery(id ? { variables: { id } } : { skip: true });
  const tableArea = getTableAreaData?.tableArea?.[0];

  const [updateTableAreaMutation, { loading: loadingUpdateTableArea }] =
    useEditTableAreaUpdateTableAreaMutation();

  const onSubmit = useCallback(
    async (tableArea: TableAreaSetInput) => {
      if (!id) return;

      try {
        await updateTableAreaMutation({
          variables: { id, tableArea },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      await refetchTableArea();
    },
    [id, updateTableAreaMutation, refetchTableArea],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const onFormValidationError = useCallback(() => {
    message.error("編集の保存に失敗しました");
  }, []);

  return (
    <DashboardLayout
      title={table?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "エリア / テーブル" }],
      }}
    >
      <PageHeader title={tableArea?.name} onBack={goBack} />
      {loadingTableArea && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {tableArea && (
        <EditTableAreaForm
          tableArea={tableArea}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateTableArea}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
