import { CsvHeaders, exportCsvWithType } from "util/exportCsv";

import { MenuOrderItemAnalyticsRowWithSortedIndex, ReportByType } from "./types";

type CsvExportObject = {
  sortedIndex: string;
  name: string;
  orderedQuantityPercentage: string;
  orderedQuantity: string;
  orderedQuantityAbcRank: string;
  taxExcludedSalesPercentage: string;
  taxExcludedSalesAmount: string;
  taxExcludedSalesAbcRank: string;
  taxIncludedSalesPercentage: string;
  taxIncludedSalesAmount: string;
  taxIncludedSalesAbcRank: string;
  taxIncludedNetProfitPercentage: string;
  taxIncludedNetProfitAmount: string;
  taxIncludedNetProfitAmountAbcRank: string;
  taxExcludedNetProfitPercentage: string;
  taxExcludedNetProfitAmount: string;
  taxExcludedNetProfitAmountAbcRank: string;
  repeatPercentage: number;
};

type CsvHeadersWithAbcRankFlag = (CsvHeaders<CsvExportObject>[number] & {
  isAbcRankColumn?: boolean;
})[];

const taxIncludedItems: CsvHeadersWithAbcRankFlag = [
  {
    property: "taxIncludedSalesPercentage",
    label: "売上構成比 (税込)",
  },
  {
    property: "taxIncludedSalesAmount",
    label: "売上合計 (税込)",
  },
  {
    property: "taxIncludedSalesAbcRank",
    label: "売上合計 (税込) ABCランク",
    isAbcRankColumn: true,
  },
  {
    property: "taxIncludedNetProfitPercentage",
    label: "粗利構成比 (税込)",
  },
  {
    property: "taxIncludedNetProfitAmount",
    label: "粗利合計 (税込)",
  },
  {
    property: "taxIncludedNetProfitAmountAbcRank",
    label: "粗利合計 (税込) ABCランク",
    isAbcRankColumn: true,
  },
];

const taxExcludedItems: CsvHeadersWithAbcRankFlag = [
  {
    property: "taxExcludedSalesPercentage",
    label: "売上構成比 (税抜)",
  },
  {
    property: "taxExcludedSalesAmount",
    label: "売上合計 (税抜)",
  },
  {
    property: "taxExcludedSalesAbcRank",
    label: "売上合計 (税抜) ABCランク",
    isAbcRankColumn: true,
  },
  {
    property: "taxExcludedNetProfitPercentage",
    label: "粗利構成比 (税抜)",
  },
  {
    property: "taxExcludedNetProfitAmount",
    label: "粗利合計 (税抜)",
  },
  {
    property: "taxExcludedNetProfitAmountAbcRank",
    label: "粗利合計 (税抜) ABCランク",
    isAbcRankColumn: true,
  },
];

const getColumnHeaders: ({
  reportByType,
  showTaxIncluded,
}: {
  reportByType: ReportByType;
  showTaxIncluded: boolean;
}) => CsvHeaders<CsvExportObject> = ({ reportByType, showTaxIncluded }) => {
  const headers: CsvHeadersWithAbcRankFlag = [
    {
      property: "sortedIndex",
      label: "順位",
    },
    {
      property: "name",
      label:
        reportByType === ReportByType.category
          ? "カテゴリ名"
          : reportByType === ReportByType.menuType
          ? "メニュータイプ"
          : "商品名",
    },
    {
      property: "orderedQuantityPercentage",
      label: "出数構成比",
    },
    {
      property: "orderedQuantity",
      label: "出数",
    },
    {
      property: "orderedQuantityAbcRank",
      label: "出数 ABCランク",
      isAbcRankColumn: true,
    },
    ...(showTaxIncluded ? taxIncludedItems : taxExcludedItems),
    {
      property: "repeatPercentage",
      label: "リピート率",
    },
  ];

  switch (reportByType) {
    case ReportByType.menuType:
      return headers.filter(
        (header) => !header.isAbcRankColumn && header.property !== "repeatPercentage",
      );

    case ReportByType.category:
      return headers.filter((header) => header.property !== "repeatPercentage");

    default:
      return headers;
  }
};

export const exportMenuOrderItemAnalyticsCsv = ({
  fileName,
  reportByType,
  showTaxIncluded,
  rows,
}: {
  fileName: string;
  reportByType: ReportByType;
  showTaxIncluded: boolean;
  rows: MenuOrderItemAnalyticsRowWithSortedIndex[];
}) =>
  exportCsvWithType<CsvExportObject>({
    fileName,
    headers: getColumnHeaders({ reportByType, showTaxIncluded }),
    rows: rows.map((row) => ({
      sortedIndex: String(row.sortedIndex),
      name: row.name,

      orderedQuantityPercentage: String(row.orderedQuantity.actualPercentage),
      orderedQuantity: String(row.orderedQuantity.actualValue),
      orderedQuantityAbcRank: row.orderedQuantity.abcRank,

      taxExcludedSalesPercentage: String(row.taxExcludedAmount.actualPercentage),
      taxExcludedSalesAmount: String(row.taxExcludedAmount.actualValue),
      taxExcludedSalesAbcRank: row.taxExcludedAmount.abcRank,

      taxIncludedSalesPercentage: String(row.taxIncludedAmount.actualPercentage),
      taxIncludedSalesAmount: String(row.taxIncludedAmount.actualValue),
      taxIncludedSalesAbcRank: row.taxIncludedAmount.abcRank,

      taxIncludedNetProfitPercentage: String(row.taxIncludedNetProfitAmount.actualPercentage),
      taxIncludedNetProfitAmount: String(row.taxIncludedNetProfitAmount.actualValue),
      taxIncludedNetProfitAmountAbcRank: row.taxIncludedNetProfitAmount.abcRank,

      taxExcludedNetProfitPercentage: String(row.taxExcludedNetProfitAmount.actualPercentage),
      taxExcludedNetProfitAmount: String(row.taxExcludedNetProfitAmount.actualValue),
      taxExcludedNetProfitAmountAbcRank: row.taxExcludedNetProfitAmount.abcRank,

      repeatPercentage: row.repeaterPercentage,
    })),
  });
