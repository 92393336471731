import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";

import { EditDashboardAccountFormItem } from "../useEditDashboardAccountForm";

type Props = {
  companies: { name: string; id: string }[];
} & Omit<FormItemProps, "children" | "name">;

export const CompaniesField = memo<Props>(({ companies, ...props }) => (
  <EditDashboardAccountFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ scope }) => [scope])}
    noStyle
  >
    {({ getFieldValue }) => {
      const scope = getFieldValue("scope");
      if (scope === "company") {
        return (
          <EditDashboardAccountFormItem
            label="アクセス可能業態"
            name="companies"
            rules={[{ required: true, message: "アクセス可能業態を選択してください" }]}
            {...props}
          >
            <Select<string>
              mode="multiple"
              placeholder="アクセス可能業態"
              optionFilterProp="label"
              options={companies.map(({ name, id }) => ({ label: name, value: id }))}
            />
          </EditDashboardAccountFormItem>
        );
      }
    }}
  </EditDashboardAccountFormItem.NonProperty>
));
