import React, { memo } from "react";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children">;

export const OneOrderLimitPerPeopleCountField = memo<Props>(() => (
  <EditShopFormItem.NonProperty
    noStyle
    shouldUpdate={withFormDependencies(({ hasMaxNumOfOrdersPerPeople }) => [
      hasMaxNumOfOrdersPerPeople,
    ])}
  >
    {({ getFieldValue }) => {
      const hasMaxNumOfOrdersPerPeople = getFieldValue("hasMaxNumOfOrdersPerPeople");
      return (
        <EditShopFormItem label="1回の注文の1人あたりの注文上限数" name="maxNumForNumPeople">
          <InputNumber min={1} disabled={!hasMaxNumOfOrdersPerPeople} />
        </EditShopFormItem>
      );
    }}
  </EditShopFormItem.NonProperty>
));
