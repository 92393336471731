import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { PlanChoice, TecAggregationMenu } from "../../types";

export type EditPlanChoiceTecAggregationMenuFormValues = Pick<
  TecAggregationMenu,
  "name" | "menuCode" | "dpCode" | "dpName" | "gpCode" | "gpName"
> & { name: string };

export const EditPlanChoiceTecAggregationMenuFormItem =
  createFormItem<EditPlanChoiceTecAggregationMenuFormValues>();

export const useEditPlanChoiceTecAggregationMenuForm = ({
  planChoice,
  tecAggregationMenu,
  onSubmit,
}: {
  planChoice?: PlanChoice;
  tecAggregationMenu?: TecAggregationMenu;
  onSubmit: (
    args: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditPlanChoiceTecAggregationMenuFormValues>(
    () => ({
      name: tecAggregationMenu?.name ?? planChoice?.name ?? "",
      menuCode: tecAggregationMenu?.menuCode ?? "",
      dpCode: tecAggregationMenu?.dpCode ?? "",
      dpName: tecAggregationMenu?.dpName ?? "",
      gpCode: tecAggregationMenu?.gpCode ?? "",
      gpName: tecAggregationMenu?.gpName ?? "",
    }),
    [planChoice, tecAggregationMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditPlanChoiceTecAggregationMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
