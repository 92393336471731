import { useCallback } from "react";
import dayjs from "dayjs";
import { formatBusinessDuration, getHours, normalizeDuration } from "util/duration";

import { createFormItem, Form } from "components/antd/Form";
import { Shop } from "pages/EditShop/types";
import { ShopSetInput } from "types/graphql";

export type EditShopFormValues = Pick<
  Shop,
  | "enableAutoResetMenuSoldOut"
  | "lastOrderText"
  | "maxNumForNumPeople"
  | "name"
  | "enableRemark"
  | "offlinePaymentDescription"
  | "accountingActionType"
> &
  // NOTE: 権限的に表示されない可能性のあるフィールドは nullable にする
  Partial<
    Pick<
      Shop,
      | "enableAlert"
      | "enableCustomerSegmentSelection"
      | "useKd"
      | "enableDisplayTaxExcludedPriceForCustomer"
    >
  > & {
    open: dayjs.Dayjs;
    close: dayjs.Dayjs;
    enableCallClerk: boolean;
    hasMaxNumOfOrdersPerPeople: boolean;
    changeDateTime?: dayjs.Dayjs;
    enableOfflinePayment?: boolean;
  };

const getInitialValues = (shop: Shop) => {
  const {
    enableAutoResetMenuSoldOut,
    lastOrderText,
    maxNumForNumPeople,
    name,
    enableAlert,
    enableRemark,
    disableCallClerk,
    enableCustomerSegmentSelection,
    disableSelfAccountingStart,
    offlinePaymentDescription,
    useKd,
    accountingActionType,
    iconImageUrl,
    enableDisplayTaxExcludedPriceForCustomer,
  } = shop;
  const { start: open, end: close } = normalizeDuration({ start: shop.open, end: shop.close });
  const changeDateTime = getHours(shop.changeDateTime);
  const hasMaxNumOfOrdersPerPeople = Boolean(maxNumForNumPeople);

  return {
    name,
    open,
    close,
    lastOrderText,
    enableAutoResetMenuSoldOut,
    hasMaxNumOfOrdersPerPeople,
    maxNumForNumPeople,
    changeDateTime,
    enableAlert,
    enableRemark,
    enableCallClerk: !disableCallClerk,
    enableCustomerSegmentSelection,
    enableOfflinePayment: !disableSelfAccountingStart,
    offlinePaymentDescription,
    useKd,
    enableDisplayTaxExcludedPriceForCustomer,
    accountingActionType,
    iconImageUrl,
  };
};

export const EditShopFormItem = createFormItem<EditShopFormValues>();

export const useEditShopForm = (shop: Shop, onSubmit: (shop: ShopSetInput) => void) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(shop);

  const submit = useCallback(
    ({ iconImageUrl }: { iconImageUrl: string | null }) => {
      const values = form.getFieldsValue() as EditShopFormValues;
      const disableCallClerk = !values.enableCallClerk;
      const disableSelfAccountingStart =
        values.enableOfflinePayment === undefined ? undefined : !values.enableOfflinePayment;
      const { start: open, end: close } = formatBusinessDuration({
        start: values.open,
        end: values.close,
      });
      const changeDateTime = values.changeDateTime?.format("HH:mm");
      const maxNumForNumPeople = values.hasMaxNumOfOrdersPerPeople
        ? values.maxNumForNumPeople
        : null;

      const {
        enableCallClerk: _enableCallClerk,
        enableOfflinePayment: _enableOfflinePayment,
        hasMaxNumOfOrdersPerPeople: _hasMaxNumOfOrdersPerPeople,
        ...inputValues
      } = values;
      onSubmit({
        ...inputValues,
        disableCallClerk,
        disableSelfAccountingStart,
        open,
        close,
        changeDateTime,
        maxNumForNumPeople,
        iconImageUrl,
      });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
