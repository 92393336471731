import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { Table } from "components/Table";
import { Pagination } from "hooks/usePagination";
import { ReportByType, SalesAnalyticsRow } from "hooks/useSalesAnalytics/types";

import { SalesAnalyticsColumnWithSelectState } from "../types";

const TableStyleProvider = styled.div`
  .ant-table-container {
    border: none;
  }
`;

type Props = {
  rows: SalesAnalyticsRow[];
  columnsWithEnabledState: SalesAnalyticsColumnWithSelectState[];
  isLoading: boolean;
  reportByType: ReportByType;
  pagination: Pagination;
  onPaginationChange: (newPagination: Pagination) => void;
};

export const SalesAnalyticsTable = ({
  rows,
  columnsWithEnabledState,
  isLoading,
  reportByType,
  pagination,
  onPaginationChange,
}: Props) => {
  const handleChange = useCallback(
    ({ position: _, ...pagination }, sorter) => {
      onPaginationChange(pagination);
    },
    [onPaginationChange],
  );

  const columns = useMemo(
    () => columnsWithEnabledState.filter(({ isEnabled }) => isEnabled),
    [columnsWithEnabledState],
  );

  const isRequiredPagination = useMemo(() => reportByType !== "hour", [reportByType]);

  return (
    <TableStyleProvider>
      <Table
        key={reportByType}
        loading={isLoading}
        columns={columns}
        dataSource={rows}
        bordered
        pagination={isRequiredPagination && pagination}
        onChange={handleChange}
        rowKey="id"
      />
    </TableStyleProvider>
  );
};
