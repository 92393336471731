import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfig = gql`
    query EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfig($shopId: uuid!) {
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
  }
}
    `;
export const EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfig = gql`
    mutation EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfig($id: uuid!, $defaultMenuUrl: String!) {
  update_externalOnlineMenuConfig_by_pk(
    pk_columns: {id: $id}
    _set: {defaultMenuUrl: $defaultMenuUrl}
  ) {
    __typename
  }
}
    `;
export type EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery = (
  { __typename?: 'query_root' }
  & { externalOnlineMenuConfig: Array<(
    { __typename?: 'externalOnlineMenuConfig' }
    & Pick<Types.ExternalOnlineMenuConfig, 'id' | 'defaultMenuUrl'>
  )> }
);

export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  defaultMenuUrl: Types.Scalars['String'];
}>;


export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_externalOnlineMenuConfig_by_pk?: Types.Maybe<{ __typename: 'externalOnlineMenuConfig' }> }
);


export const EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigDocument = gql`
    query EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfig($shopId: uuid!) {
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
  }
}
    `;

/**
 * __useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery__
 *
 * To run a query within a React component, call `useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery(baseOptions: Apollo.QueryHookOptions<EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery, EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery, EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables>(EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigDocument, options);
      }
export function useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery, EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery, EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables>(EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigDocument, options);
        }
export type EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryHookResult = ReturnType<typeof useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery>;
export type EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigLazyQueryHookResult = ReturnType<typeof useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigLazyQuery>;
export type EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryResult = Apollo.QueryResult<EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery, EditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQueryVariables>;
export const EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigDocument = gql`
    mutation EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfig($id: uuid!, $defaultMenuUrl: String!) {
  update_externalOnlineMenuConfig_by_pk(
    pk_columns: {id: $id}
    _set: {defaultMenuUrl: $defaultMenuUrl}
  ) {
    __typename
  }
}
    `;
export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationFn = Apollo.MutationFunction<EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation, EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationVariables>;

/**
 * __useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation__
 *
 * To run a mutation, you first call `useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation, { data, loading, error }] = useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      defaultMenuUrl: // value for 'defaultMenuUrl'
 *   },
 * });
 */
export function useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation, EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation, EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationVariables>(EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigDocument, options);
      }
export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationHookResult = ReturnType<typeof useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation>;
export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationResult = Apollo.MutationResult<EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation>;
export type EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationOptions = Apollo.BaseMutationOptions<EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation, EditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutationVariables>;