import React from "react";
import styled from "styled-components";
// eslint-disable-next-line no-restricted-imports
import { Form as Original, FormItemProps as OriginalFormItemProps, FormProps } from "antd";
import { NamePath } from "antd/es/form/interface";
import { ShouldUpdate } from "rc-field-form/es/Field";

import { Spacer } from "../Spacer";

import { bypassPropsOverwrite } from "./utils/bypass-props-overwrite";

export const Form = (props: FormProps) => <Original {...props} />;
Form.useForm = Original.useForm;

export const FormList = Original.List;
export const FormErrorList = Original.ErrorList;
export const FormProvider = Original.Provider;

export type FormItemProps<Values = any> = OriginalFormItemProps<Values> & {
  horizontal?: boolean;
  labelSpacer?: JSX.Element | null;
  endSpacer?: JSX.Element | null;
};

const FormItemWrapper = styled.div`
  width: 100%;
`;

const renderFormItem = <Values,>({ horizontal, children, ...props }: FormItemProps<Values>) => {
  const { label, noStyle } = props;

  const shouldShowLabelSpacer = !noStyle && !horizontal && label;
  const labelSpacer = shouldShowLabelSpacer ? (
    props.labelSpacer !== undefined ? (
      props.labelSpacer
    ) : (
      <Spacer size={4} />
    )
  ) : null;

  const shouldShowBottomSpacer = !noStyle;
  const endSpacer = shouldShowBottomSpacer ? (
    props.endSpacer !== undefined ? (
      props.endSpacer
    ) : (
      <Spacer size={16} />
    )
  ) : null;

  return (
    <FormItemWrapper>
      <Original.Item {...props} data-horizontal={horizontal} colon={false}>
        {labelSpacer
          ? bypassPropsOverwrite(children, (bypassed) => (
              <>
                {labelSpacer}
                {bypassed}
              </>
            ))
          : children}
      </Original.Item>
      {endSpacer}
    </FormItemWrapper>
  );
};

// https://github.com/ant-design/ant-design/blob/16492a2090161cf6cc255c0291cf481d3449a8a3/components/form/FormItem/index.tsx#L85
export const FormItem = (props: FormItemProps<any>) => renderFormItem(props);

export type TypedFormItemProps<Values> = Omit<FormItemProps<Values>, "name"> & {
  name: Exclude<keyof Values, symbol> | Exclude<NamePath, string | number>;
};

/**
 * FormItem コンポーネントはそのままだとフォームの値の型が any になってしまい、
 * 実装のミスが増えたりレビューやコールドリーディングのコストが高くなる。
 * そこで、各フォームごとに適切な型を設定した専用の FormItem を作成し、
 * check-form-naming-convention によって専用の FormItem の利用を強制することで
 * 型の恩恵を得られる状態を担保している。
 */
export const createFormItem = <T,>() => {
  const TypedFormItem = (props: TypedFormItemProps<T>) => renderFormItem(props);
  TypedFormItem.NonProperty = (props: Omit<TypedFormItemProps<T>, "name">) => renderFormItem(props);

  return TypedFormItem;
};

export const withFormDependencies =
  <Values,>(picker: (values: Values) => unknown[]): ShouldUpdate<Values> =>
  (prev, next) => {
    const prevDependencies = picker(prev);
    const nextDependencies = picker(next);
    return prevDependencies.some((value, index) => value !== nextDependencies[index]);
  };
