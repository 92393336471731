import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CopyDealingDataGetShops = gql`
    query CopyDealingDataGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export const CopyDealingDataGetCompany = gql`
    query CopyDealingDataGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    name
  }
}
    `;
export const CopyDealingDataCopyDealingData = gql`
    mutation CopyDealingDataCopyDealingData($sourceShopId: String!, $targetShopId: String!, $enableCopyTable: Boolean!) {
  dashboardAccountCopyDealingData(
    input: {sourceShopId: $sourceShopId, targetShopId: $targetShopId, enableCopyTable: $enableCopyTable}
  )
}
    `;
export type CopyDealingDataGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CopyDealingDataGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);

export type CopyDealingDataGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CopyDealingDataGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'name'>
  )> }
);

export type CopyDealingDataCopyDealingDataMutationVariables = Types.Exact<{
  sourceShopId: Types.Scalars['String'];
  targetShopId: Types.Scalars['String'];
  enableCopyTable: Types.Scalars['Boolean'];
}>;


export type CopyDealingDataCopyDealingDataMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCopyDealingData'>
);


export const CopyDealingDataGetShopsDocument = gql`
    query CopyDealingDataGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useCopyDealingDataGetShopsQuery__
 *
 * To run a query within a React component, call `useCopyDealingDataGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyDealingDataGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyDealingDataGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCopyDealingDataGetShopsQuery(baseOptions: Apollo.QueryHookOptions<CopyDealingDataGetShopsQuery, CopyDealingDataGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyDealingDataGetShopsQuery, CopyDealingDataGetShopsQueryVariables>(CopyDealingDataGetShopsDocument, options);
      }
export function useCopyDealingDataGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyDealingDataGetShopsQuery, CopyDealingDataGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyDealingDataGetShopsQuery, CopyDealingDataGetShopsQueryVariables>(CopyDealingDataGetShopsDocument, options);
        }
export type CopyDealingDataGetShopsQueryHookResult = ReturnType<typeof useCopyDealingDataGetShopsQuery>;
export type CopyDealingDataGetShopsLazyQueryHookResult = ReturnType<typeof useCopyDealingDataGetShopsLazyQuery>;
export type CopyDealingDataGetShopsQueryResult = Apollo.QueryResult<CopyDealingDataGetShopsQuery, CopyDealingDataGetShopsQueryVariables>;
export const CopyDealingDataGetCompanyDocument = gql`
    query CopyDealingDataGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    name
  }
}
    `;

/**
 * __useCopyDealingDataGetCompanyQuery__
 *
 * To run a query within a React component, call `useCopyDealingDataGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyDealingDataGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyDealingDataGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCopyDealingDataGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<CopyDealingDataGetCompanyQuery, CopyDealingDataGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyDealingDataGetCompanyQuery, CopyDealingDataGetCompanyQueryVariables>(CopyDealingDataGetCompanyDocument, options);
      }
export function useCopyDealingDataGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyDealingDataGetCompanyQuery, CopyDealingDataGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyDealingDataGetCompanyQuery, CopyDealingDataGetCompanyQueryVariables>(CopyDealingDataGetCompanyDocument, options);
        }
export type CopyDealingDataGetCompanyQueryHookResult = ReturnType<typeof useCopyDealingDataGetCompanyQuery>;
export type CopyDealingDataGetCompanyLazyQueryHookResult = ReturnType<typeof useCopyDealingDataGetCompanyLazyQuery>;
export type CopyDealingDataGetCompanyQueryResult = Apollo.QueryResult<CopyDealingDataGetCompanyQuery, CopyDealingDataGetCompanyQueryVariables>;
export const CopyDealingDataCopyDealingDataDocument = gql`
    mutation CopyDealingDataCopyDealingData($sourceShopId: String!, $targetShopId: String!, $enableCopyTable: Boolean!) {
  dashboardAccountCopyDealingData(
    input: {sourceShopId: $sourceShopId, targetShopId: $targetShopId, enableCopyTable: $enableCopyTable}
  )
}
    `;
export type CopyDealingDataCopyDealingDataMutationFn = Apollo.MutationFunction<CopyDealingDataCopyDealingDataMutation, CopyDealingDataCopyDealingDataMutationVariables>;

/**
 * __useCopyDealingDataCopyDealingDataMutation__
 *
 * To run a mutation, you first call `useCopyDealingDataCopyDealingDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyDealingDataCopyDealingDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyDealingDataCopyDealingDataMutation, { data, loading, error }] = useCopyDealingDataCopyDealingDataMutation({
 *   variables: {
 *      sourceShopId: // value for 'sourceShopId'
 *      targetShopId: // value for 'targetShopId'
 *      enableCopyTable: // value for 'enableCopyTable'
 *   },
 * });
 */
export function useCopyDealingDataCopyDealingDataMutation(baseOptions?: Apollo.MutationHookOptions<CopyDealingDataCopyDealingDataMutation, CopyDealingDataCopyDealingDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyDealingDataCopyDealingDataMutation, CopyDealingDataCopyDealingDataMutationVariables>(CopyDealingDataCopyDealingDataDocument, options);
      }
export type CopyDealingDataCopyDealingDataMutationHookResult = ReturnType<typeof useCopyDealingDataCopyDealingDataMutation>;
export type CopyDealingDataCopyDealingDataMutationResult = Apollo.MutationResult<CopyDealingDataCopyDealingDataMutation>;
export type CopyDealingDataCopyDealingDataMutationOptions = Apollo.BaseMutationOptions<CopyDealingDataCopyDealingDataMutation, CopyDealingDataCopyDealingDataMutationVariables>;