import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";
import { halfWidthNumberToFullWidthNumber } from "util/charWidth";

const StyledInputNumber = styled(AntdInputNumber)<Props>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: ${({ fullWidth }) => (fullWidth ? "block" : "inline-block")};

  input {
    text-align: right;
    padding-right: calc(22px + 4px); /* stepAreaWidth + paddingRight */
  }
`;

export type Props = InputNumberProps & {
  fullWidth?: boolean;
  suffix?: string;
};

export const InputNumber = memo<Props>(
  ({ formatter: originalFormatter, parser: originalParser, suffix, ...props }) => {
    const formatter: NonNullable<InputNumberProps["formatter"]> = useCallback(
      (value = "", info) => {
        const convertedValue =
          typeof value === "string" ? halfWidthNumberToFullWidthNumber(value) : value;
        if (originalFormatter) return originalFormatter(convertedValue, info);
        return convertedValue.toString();
      },
      [originalFormatter],
    );

    const parser: NonNullable<InputNumberProps["parser"]> = useCallback(
      (displayValue) => {
        const convertedValue = halfWidthNumberToFullWidthNumber(displayValue ?? "");
        if (originalParser) return originalParser(convertedValue);
        return convertedValue;
      },
      [originalParser],
    );

    return <StyledInputNumber formatter={formatter} parser={parser} suffix={suffix} {...props} />;
  },
);
