import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { FormHelp } from "components/Form/FormHelp";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const NameField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    label={<FormHelp label="メニュー名" help="お客様が見る注文画面や会計伝票での表示名" />}
    name="name"
    rules={[{ required: true, message: "メニュー名を入力してください" }]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditMenuFormItem>
));
