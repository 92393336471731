import React, { memo } from "react";
import { Switch } from "antd";

import { AddConnectGameConfigFormItem } from "../useAddConnectGameConfigForm";

export const EnabledField = memo(() => (
  <AddConnectGameConfigFormItem
    label="ミニゲームの利用"
    name="enabled"
    horizontal
    valuePropName="checked"
  >
    <Switch checkedChildren="利用する" unCheckedChildren="利用しない" />
  </AddConnectGameConfigFormItem>
));
