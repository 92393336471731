import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetGmoOffPaymentOnSitePaymentDetailTypes = gql`
    query GetGmoOffPaymentOnSitePaymentDetailTypes($companyId: uuid!) {
  gmoOffPaymentOnSitePaymentDetailType(where: {companyId: {_eq: $companyId}}) {
    id
    gmoOffPaymentMethodType
    onSitePaymentDetailTypeId
  }
}
    `;
export const GetOnSitePaymentDetailTypes = gql`
    query GetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {company: {id: {_eq: $companyId}}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;
export const OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailType = gql`
    mutation OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailType($companyId: uuid!, $gmoOffPaymentMethodType: gmoOffPaymentMethodType_enum!, $objects: [gmoOffPaymentOnSitePaymentDetailType_insert_input!]!) {
  delete_gmoOffPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, gmoOffPaymentMethodType: {_eq: $gmoOffPaymentMethodType}}
  ) {
    affected_rows
  }
  insert_gmoOffPaymentOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export const OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailType = gql`
    mutation OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailType($companyId: uuid!, $gmoOffPaymentMethodType: gmoOffPaymentMethodType_enum!) {
  delete_gmoOffPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, gmoOffPaymentMethodType: {_eq: $gmoOffPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GetGmoOffPaymentOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { gmoOffPaymentOnSitePaymentDetailType: Array<(
    { __typename?: 'gmoOffPaymentOnSitePaymentDetailType' }
    & Pick<Types.GmoOffPaymentOnSitePaymentDetailType, 'id' | 'gmoOffPaymentMethodType' | 'onSitePaymentDetailTypeId'>
  )> }
);

export type GetOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GetOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
  )> }
);

export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  gmoOffPaymentMethodType: Types.GmoOffPaymentMethodTypeEnum;
  objects: Array<Types.GmoOffPaymentOnSitePaymentDetailTypeInsertInput> | Types.GmoOffPaymentOnSitePaymentDetailTypeInsertInput;
}>;


export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_gmoOffPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'gmoOffPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.GmoOffPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )>, insert_gmoOffPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'gmoOffPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.GmoOffPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);

export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  gmoOffPaymentMethodType: Types.GmoOffPaymentMethodTypeEnum;
}>;


export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_gmoOffPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'gmoOffPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.GmoOffPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);


export const GetGmoOffPaymentOnSitePaymentDetailTypesDocument = gql`
    query GetGmoOffPaymentOnSitePaymentDetailTypes($companyId: uuid!) {
  gmoOffPaymentOnSitePaymentDetailType(where: {companyId: {_eq: $companyId}}) {
    id
    gmoOffPaymentMethodType
    onSitePaymentDetailTypeId
  }
}
    `;

/**
 * __useGetGmoOffPaymentOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useGetGmoOffPaymentOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGmoOffPaymentOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGmoOffPaymentOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetGmoOffPaymentOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<GetGmoOffPaymentOnSitePaymentDetailTypesQuery, GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGmoOffPaymentOnSitePaymentDetailTypesQuery, GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables>(GetGmoOffPaymentOnSitePaymentDetailTypesDocument, options);
      }
export function useGetGmoOffPaymentOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGmoOffPaymentOnSitePaymentDetailTypesQuery, GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGmoOffPaymentOnSitePaymentDetailTypesQuery, GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables>(GetGmoOffPaymentOnSitePaymentDetailTypesDocument, options);
        }
export type GetGmoOffPaymentOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useGetGmoOffPaymentOnSitePaymentDetailTypesQuery>;
export type GetGmoOffPaymentOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useGetGmoOffPaymentOnSitePaymentDetailTypesLazyQuery>;
export type GetGmoOffPaymentOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<GetGmoOffPaymentOnSitePaymentDetailTypesQuery, GetGmoOffPaymentOnSitePaymentDetailTypesQueryVariables>;
export const GetOnSitePaymentDetailTypesDocument = gql`
    query GetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {company: {id: {_eq: $companyId}}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;

/**
 * __useGetOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useGetOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>(GetOnSitePaymentDetailTypesDocument, options);
      }
export function useGetOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>(GetOnSitePaymentDetailTypesDocument, options);
        }
export type GetOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useGetOnSitePaymentDetailTypesQuery>;
export type GetOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useGetOnSitePaymentDetailTypesLazyQuery>;
export type GetOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>;
export const OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeDocument = gql`
    mutation OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailType($companyId: uuid!, $gmoOffPaymentMethodType: gmoOffPaymentMethodType_enum!, $objects: [gmoOffPaymentOnSitePaymentDetailType_insert_input!]!) {
  delete_gmoOffPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, gmoOffPaymentMethodType: {_eq: $gmoOffPaymentMethodType}}
  ) {
    affected_rows
  }
  insert_gmoOffPaymentOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation, { data, loading, error }] = useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      gmoOffPaymentMethodType: // value for 'gmoOffPaymentMethodType'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationVariables>(OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeDocument, options);
      }
export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutationVariables>;
export const OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeDocument = gql`
    mutation OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailType($companyId: uuid!, $gmoOffPaymentMethodType: gmoOffPaymentMethodType_enum!) {
  delete_gmoOffPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, gmoOffPaymentMethodType: {_eq: $gmoOffPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation, { data, loading, error }] = useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      gmoOffPaymentMethodType: // value for 'gmoOffPaymentMethodType'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationVariables>(OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeDocument, options);
      }
export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutationVariables>;