import React, { memo } from "react";
import { Card, Col, Row, Statistic } from "antd";

import { FormHelp } from "components/Form/FormHelp";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";

import { CurrentSales } from "../types";

type Props = {
  currentSales?: CurrentSales;
};

export const CurrentSalesCards = memo<Props>(({ currentSales }) => (
  <Card>
    <SubTitle>
      <FormHelp label="現在の売上高" help="現在ご利用中のお客様売上を含む未精算の売上高です。" />
    </SubTitle>
    <Row gutter={[32, 32]}>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="売上（税込）" value={currentSales?.currentSales ?? ""} suffix="円" />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic
            title="売上（税抜）"
            value={currentSales?.currentTaxExcludedSales ?? ""}
            suffix="円"
          />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic
            title="客単価（税込）"
            value={currentSales?.currentUnitSales ?? ""}
            suffix="円"
          />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="人数" value={currentSales?.currentNumberOfPeople ?? ""} suffix="人" />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="組数" value={currentSales?.currentNumberOfGroup ?? ""} suffix="組" />
        </Card>
      </Col>
    </Row>
    <Spacer size={32} />

    <SubTitle>うち現在ご利用中のお客様情報</SubTitle>
    <Row gutter={[32, 32]}>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="注文金額" value={currentSales?.activeSales ?? ""} suffix="円" />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="注文金額単価" value={currentSales?.activeUnitSales ?? ""} suffix="円" />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="人数" value={currentSales?.activeNumberOfPeople ?? ""} suffix="人" />
        </Card>
      </Col>
      <Col span={12} md={6}>
        <Card>
          <Statistic title="組数" value={currentSales?.activeNumberOfGroup ?? ""} suffix="組" />
        </Card>
      </Col>
    </Row>
  </Card>
));
