import { ApolloClient, ApolloLink, createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import * as uuid from "uuid";

import { sessionId } from "constants/sessionId";
import { auth } from "libs/firebase";
import { createCache } from "store/cache";
import { signIn } from "store/mutations/signIn";
import { signOut } from "store/mutations/signOut";

import { createErrorLink } from "./createErrorLink";

export const createApolloClient = () => {
  const cache = createCache();

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      setContext(async () => {
        const token = await auth.currentUser?.getIdToken();
        const headers = {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          "x-session-id": sessionId,
          "x-request-id": uuid.v4(),
        };

        return { headers };
        // NOTE: https://github.com/apollographql/apollo-client/issues/6011
      }) as any,
      createErrorLink(),
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return definition.kind === "OperationDefinition" && definition.operation === "query";
        },
        createHttpLink({
          uri: import.meta.env.HASURA_QUERY_URL || import.meta.env.HASURA_URL,
          print: (node, print) => print(node).replace(/@asScalar(\(\s*key:\s*"\w*"\s*\))?/g, ""),
        }),
        createHttpLink({
          uri: import.meta.env.HASURA_URL,
          print: (node, print) => print(node).replace(/@asScalar(\(\s*key:\s*"\w*"\s*\))?/g, ""),
        }),
      ),
    ]),
    defaultOptions: {
      watchQuery: {
        // NOTE: Apollo Client でキャッシュを利用すると書き込み時の処理が追加で発生してしまいパフォーマンス面の懸念がある。
        // 幸いなことにダッシュボードでは基本的に最新のデータを利用してほしく、オフライン状態への対応なども特に必要ないため、
        // あえてキャッシュを利用しないようにしている。一方で、スムーズな体験のためにキャッシュを利用しておいたほうが良い箇所では
        // 個別に fetchPolicy を設定することとしている。
        fetchPolicy: "no-cache",
      },
    },
    resolvers: {
      Mutation: {
        signIn,
        signOut,
      } as any,
    },
  });
};
