import { useEffect } from "react";

import { useSignOut } from "hooks/useSignOut";

export const SignOut = () => {
  const { signOutMutation } = useSignOut();

  useEffect(() => {
    signOutMutation();
  }, [signOutMutation]);

  return null;
};
