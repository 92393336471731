import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Role } from "pages/EditDishUpSlipGroup/types";

import { EditDishUpSlipGroupFormItem } from "../useEditDishUpSlipGroupForm";

const { Option } = Select;

type Props = {
  roles: Role[];
} & Omit<FormItemProps, "children" | "name">;

export const RoleField = memo<Props>(({ roles, ...props }) => (
  <EditDishUpSlipGroupFormItem
    label="ロール"
    name="roleIds"
    rules={[{ required: true, message: "ロール名を入力してください" }]}
    {...props}
  >
    <Select<number> mode="multiple">
      {roles.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  </EditDishUpSlipGroupFormItem>
));
