import React, { useCallback } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { OnSubmitEditMembershipCardAppearanceTypeForm } from "./EditMembershipCardAppearanceTypeForm/useEditMembershipCardAppearanceTypeForm";
import { EditMembershipCardAppearanceTypeForm } from "./EditMembershipCardAppearanceTypeForm";
import {
  useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery,
  useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery,
  useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation,
} from "./queries";

export const EditMembershipCardAppearanceType = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getAppearanceTypesData,
    error: getAppearanceTypesError,
    loading: loadingGetAppearanceTypes,
  } = useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery();
  const appearanceTypes = getAppearanceTypesData?.membershipCardAppearanceType ?? [];

  const {
    data: getCurrentAppearanceTypeData,
    error: getCurrentAppearanceTypeError,
    refetch: refetchCurrentAppearanceType,
    loading: loadingGetCurrentAppearanceType,
  } = useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const currentAppearanceType =
    getCurrentAppearanceTypeData?.membershipCard[0]?.appearanceType ?? null;
  const membershipRankConfig = getCurrentAppearanceTypeData?.membershipRankConfig ?? [];

  const shouldShowAlert = getAppearanceTypesError || getCurrentAppearanceTypeError;

  const [addAppearanceTypeMutation, { loading: loadingInsertAppearanceType }] =
    useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation();

  const onSubmit = useCallback<OnSubmitEditMembershipCardAppearanceTypeForm>(
    async ({ appearanceType }) => {
      try {
        if (!appearanceType || !companyId) throw new Error("");
        if (appearanceType === currentAppearanceType) return;

        await addAppearanceTypeMutation({
          variables: {
            input: {
              companyId,
              appearanceType,
            },
          },
        });

        message.success("更新しました");
      } catch (e) {
        message.error("更新に失敗しました");
      }
      await refetchCurrentAppearanceType();
    },
    [companyId, currentAppearanceType, addAppearanceTypeMutation, refetchCurrentAppearanceType],
  );

  return (
    <DashboardLayout title="カードデザイン">
      <PageHeader title="カードデザイン" />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <EditMembershipCardAppearanceTypeForm
        loading={
          loadingGetAppearanceTypes ||
          loadingGetCurrentAppearanceType ||
          loadingInsertAppearanceType
        }
        membershipRankConfig={membershipRankConfig}
        currentAppearanceType={currentAppearanceType}
        appearanceTypes={appearanceTypes}
        onSubmit={onSubmit}
      />
    </DashboardLayout>
  );
};
