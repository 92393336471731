import React, { memo, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  column-gap: 12px;
`;

const Content = styled.div`
  flex: 1;
`;

type Props = {
  icon: ReactNode;
  children: ReactNode;
};

export const RoomWrapper = memo(({ icon, children }: Props) => (
  <Container>
    {icon}
    <Content>{children}</Content>
  </Container>
));
