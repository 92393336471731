import React, { memo } from "react";
import { Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";

import { Spacer } from "components/Spacer";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = {
  onChangeSwitch: SwitchChangeEventHandler;
};

export const EnableRequiringPasswordForDrawerOpenField = memo<Props>(({ onChangeSwitch }) => (
  <EditCashRegisterConfigFormItem
    label="会計、点検・精算以外でドロワーを開く際のパスワード設定"
    name="enableRequiringPasswordForDrawerOpen"
    valuePropName="checked"
    endSpacer={<Spacer size={8} />}
  >
    <Switch onChange={onChangeSwitch} />
  </EditCashRegisterConfigFormItem>
));
