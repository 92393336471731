import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";
import { membershipRanks } from "models/membershipRank";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { MembershipRankEnum } from "types/graphql";

import { MembershipRankByMembershipRank, MembershipRankConfig, RewardCoupon } from "../types";

type Props = {
  loading: boolean;
  ranks: MembershipRankConfig[];
};

export const MembershipRankConfigsTable = memo(({ loading, ranks }: Props) => {
  const { canAccess } = useCanAccess();
  const columns: ColumnsType<MembershipRankConfig> = [
    {
      title: "ランク名",
      dataIndex: "membershipRankByMembershipRank",
      render: (membershipRankByMembershipRank: MembershipRankByMembershipRank) =>
        membershipRanks[membershipRankByMembershipRank?.value as MembershipRankEnum],
    },
    {
      title: "来店回数",
      dataIndex: "requiredVisitCount",
      render: (requiredVisitCount: number) => `${requiredVisitCount}回以上`,
    },
    {
      title: "クーポン",
      dataIndex: "rewardCoupon",
      render: (rewardCoupon: RewardCoupon) => rewardCoupon?.name ?? "なし",
    },
    {
      title: "会員数",
      dataIndex: "membershipRankByMembershipRank",
      render: (membershipRankByMembershipRank: MembershipRankByMembershipRank) =>
        membershipRankByMembershipRank?.companyMembers_aggregate?.aggregate?.count ?? 0,
    },
    ...(canAccess("editMembershipRankConfig")
      ? [
          {
            title: "",
            dataIndex: "id",
            render: (id: string) => (
              <IconLink to={`/membershipCard/rankConfig/${id}/edit`}>
                <EditIcon />
              </IconLink>
            ),
            width: 60,
            align: "center",
          } as const,
        ]
      : []),
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={ranks}
      loading={loading}
      bordered
      pagination={false}
    />
  );
});
