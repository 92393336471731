import React from "react";
import { Alert } from "antd";
import { filter, FilterConditions } from "models/coineySettlement";
import moment from "moment";
import { groupBy, sumBy } from "util/array";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useLocationState } from "hooks/useLocationState";
import { useShop } from "hooks/useShop";
import { CoineySalesFilter } from "pages/CoineySales/CoineySalesFilter";
import { CoineySalesGroup, Dimension, GroupVariables } from "pages/CoineySales/CoineySalesGroup";
import { CoineySalesQueries } from "pages/CoineySales/CoineySalesQueries";
import { CoineySalesStatistics } from "pages/CoineySales/CoineySalesStatistics/CoineySalesStatistics";
import { CoineySalesTable } from "pages/CoineySales/CoineySalesTable";
import { useCoineySalesGetCoineySettlementsQuery } from "pages/CoineySales/queries";

import { ShopSelector } from "../../components/ShopSelector/index";

const formatters: Record<Dimension, string> = {
  month: "YYYY年MM月",
  week: "YYYY年 第W週",
  dayOfWeek: "dddd",
  day: "YYYY年MM月DD日",
  hour: "YYYY年MM月DD日 HH時",
};

type Serialized = { range?: [number | null, number | null] | undefined } & Omit<
  FilterConditions,
  "range"
>;

export const CoineySales = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getCoineySettlementsData,
    loading: loadingCoineySettlements,
    error,
  } = useCoineySalesGetCoineySettlementsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const coineySettlements = getCoineySettlementsData?.coineySettlement ?? [];

  const [filterConditions, setFilterConditions] = useLocationState<FilterConditions, Serialized>(
    "filterConditions",
    {},
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const range: FilterConditions["range"] = filterConditions.range
    ? [filterConditions.range[0] ?? null, filterConditions.range[1]?.add(1, "d") ?? null]
    : filterConditions.range;
  const [groupVariables, setGroupVariables] = useLocationState<GroupVariables>("groupVariables", {
    dimension: "hour",
  });

  const filteredCoineySettlements = filter(coineySettlements, { range });
  const groupedCoineySettlements = groupBy(filteredCoineySettlements, ({ chargeDate }) => {
    const formatter = formatters[groupVariables.dimension];

    return moment(chargeDate).format(formatter);
  });

  const aggregatedCoineySettlements = Object.entries(groupedCoineySettlements).map(
    ([dimension, coineySettlements]) => ({
      dimension,
      amount: sumBy(coineySettlements, "amount"),
    }),
  );

  return (
    <DashboardLayout title="コイニー売上">
      <PageHeader
        title="コイニー売上"
        footer={
          <>
            <ShopSelector />
            <CoineySalesQueries>
              <CoineySalesFilter
                filterConditions={filterConditions}
                coineySettlements={coineySettlements}
                onChange={setFilterConditions}
              />
              <CoineySalesGroup values={groupVariables} onChange={setGroupVariables} />
            </CoineySalesQueries>
          </>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CoineySalesStatistics coineySettlements={filteredCoineySettlements} />
      <Spacer size={32} />
      <CoineySalesTable
        coineySettlements={aggregatedCoineySettlements}
        loading={loadingCoineySettlements}
      />
    </DashboardLayout>
  );
};
