import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditDashboardAccountRoleGetDashboardAccountRole = gql`
    query EditDashboardAccountRoleGetDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  dashboardAccountRole(where: {id: {_eq: $dashboardAccountRoleId}}) {
    id
    name
    dashboardAccountRolePermissions {
      id
      feature
      type
    }
  }
}
    `;
export const EditDashboardAccountRoleUpdateDashboardAccountRole = gql`
    mutation EditDashboardAccountRoleUpdateDashboardAccountRole($dashboardAccountRoleId: uuid!, $dashboardAccountRole: dashboardAccountRole_set_input!, $dashboardAccountRolePermissions: [dashboardAccountRolePermission_insert_input!]!) {
  update_dashboardAccountRole(
    where: {id: {_eq: $dashboardAccountRoleId}}
    _set: $dashboardAccountRole
  ) {
    affected_rows
  }
  delete_dashboardAccountRolePermission(
    where: {dashboardAccountRoleId: {_eq: $dashboardAccountRoleId}}
  ) {
    affected_rows
  }
  insert_dashboardAccountRolePermission(objects: $dashboardAccountRolePermissions) {
    affected_rows
  }
}
    `;
export type EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables = Types.Exact<{
  dashboardAccountRoleId: Types.Scalars['uuid'];
}>;


export type EditDashboardAccountRoleGetDashboardAccountRoleQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountRole: Array<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id' | 'name'>
    & { dashboardAccountRolePermissions: Array<(
      { __typename?: 'dashboardAccountRolePermission' }
      & Pick<Types.DashboardAccountRolePermission, 'id' | 'feature' | 'type'>
    )> }
  )> }
);

export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutationVariables = Types.Exact<{
  dashboardAccountRoleId: Types.Scalars['uuid'];
  dashboardAccountRole: Types.DashboardAccountRoleSetInput;
  dashboardAccountRolePermissions: Array<Types.DashboardAccountRolePermissionInsertInput> | Types.DashboardAccountRolePermissionInsertInput;
}>;


export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutation = (
  { __typename?: 'mutation_root' }
  & { update_dashboardAccountRole?: Types.Maybe<(
    { __typename?: 'dashboardAccountRole_mutation_response' }
    & Pick<Types.DashboardAccountRoleMutationResponse, 'affected_rows'>
  )>, delete_dashboardAccountRolePermission?: Types.Maybe<(
    { __typename?: 'dashboardAccountRolePermission_mutation_response' }
    & Pick<Types.DashboardAccountRolePermissionMutationResponse, 'affected_rows'>
  )>, insert_dashboardAccountRolePermission?: Types.Maybe<(
    { __typename?: 'dashboardAccountRolePermission_mutation_response' }
    & Pick<Types.DashboardAccountRolePermissionMutationResponse, 'affected_rows'>
  )> }
);


export const EditDashboardAccountRoleGetDashboardAccountRoleDocument = gql`
    query EditDashboardAccountRoleGetDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  dashboardAccountRole(where: {id: {_eq: $dashboardAccountRoleId}}) {
    id
    name
    dashboardAccountRolePermissions {
      id
      feature
      type
    }
  }
}
    `;

/**
 * __useEditDashboardAccountRoleGetDashboardAccountRoleQuery__
 *
 * To run a query within a React component, call `useEditDashboardAccountRoleGetDashboardAccountRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountRoleGetDashboardAccountRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDashboardAccountRoleGetDashboardAccountRoleQuery({
 *   variables: {
 *      dashboardAccountRoleId: // value for 'dashboardAccountRoleId'
 *   },
 * });
 */
export function useEditDashboardAccountRoleGetDashboardAccountRoleQuery(baseOptions: Apollo.QueryHookOptions<EditDashboardAccountRoleGetDashboardAccountRoleQuery, EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDashboardAccountRoleGetDashboardAccountRoleQuery, EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables>(EditDashboardAccountRoleGetDashboardAccountRoleDocument, options);
      }
export function useEditDashboardAccountRoleGetDashboardAccountRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDashboardAccountRoleGetDashboardAccountRoleQuery, EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDashboardAccountRoleGetDashboardAccountRoleQuery, EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables>(EditDashboardAccountRoleGetDashboardAccountRoleDocument, options);
        }
export type EditDashboardAccountRoleGetDashboardAccountRoleQueryHookResult = ReturnType<typeof useEditDashboardAccountRoleGetDashboardAccountRoleQuery>;
export type EditDashboardAccountRoleGetDashboardAccountRoleLazyQueryHookResult = ReturnType<typeof useEditDashboardAccountRoleGetDashboardAccountRoleLazyQuery>;
export type EditDashboardAccountRoleGetDashboardAccountRoleQueryResult = Apollo.QueryResult<EditDashboardAccountRoleGetDashboardAccountRoleQuery, EditDashboardAccountRoleGetDashboardAccountRoleQueryVariables>;
export const EditDashboardAccountRoleUpdateDashboardAccountRoleDocument = gql`
    mutation EditDashboardAccountRoleUpdateDashboardAccountRole($dashboardAccountRoleId: uuid!, $dashboardAccountRole: dashboardAccountRole_set_input!, $dashboardAccountRolePermissions: [dashboardAccountRolePermission_insert_input!]!) {
  update_dashboardAccountRole(
    where: {id: {_eq: $dashboardAccountRoleId}}
    _set: $dashboardAccountRole
  ) {
    affected_rows
  }
  delete_dashboardAccountRolePermission(
    where: {dashboardAccountRoleId: {_eq: $dashboardAccountRoleId}}
  ) {
    affected_rows
  }
  insert_dashboardAccountRolePermission(objects: $dashboardAccountRolePermissions) {
    affected_rows
  }
}
    `;
export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutationFn = Apollo.MutationFunction<EditDashboardAccountRoleUpdateDashboardAccountRoleMutation, EditDashboardAccountRoleUpdateDashboardAccountRoleMutationVariables>;

/**
 * __useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation__
 *
 * To run a mutation, you first call `useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDashboardAccountRoleUpdateDashboardAccountRoleMutation, { data, loading, error }] = useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation({
 *   variables: {
 *      dashboardAccountRoleId: // value for 'dashboardAccountRoleId'
 *      dashboardAccountRole: // value for 'dashboardAccountRole'
 *      dashboardAccountRolePermissions: // value for 'dashboardAccountRolePermissions'
 *   },
 * });
 */
export function useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditDashboardAccountRoleUpdateDashboardAccountRoleMutation, EditDashboardAccountRoleUpdateDashboardAccountRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDashboardAccountRoleUpdateDashboardAccountRoleMutation, EditDashboardAccountRoleUpdateDashboardAccountRoleMutationVariables>(EditDashboardAccountRoleUpdateDashboardAccountRoleDocument, options);
      }
export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutationHookResult = ReturnType<typeof useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation>;
export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutationResult = Apollo.MutationResult<EditDashboardAccountRoleUpdateDashboardAccountRoleMutation>;
export type EditDashboardAccountRoleUpdateDashboardAccountRoleMutationOptions = Apollo.BaseMutationOptions<EditDashboardAccountRoleUpdateDashboardAccountRoleMutation, EditDashboardAccountRoleUpdateDashboardAccountRoleMutationVariables>;