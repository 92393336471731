import React, { memo } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

import { Loading } from "components/Loading";

import { CustomerDashboardCard } from "../CustomerDashboardCard";

import {
  BAR_COLORS,
  CARTESIAN_GRID_STROKE_DASHARRAY,
  GRID_COLOR,
  LEGEND_HEIGHT,
  TICK_FONT_SIZE,
} from "./graphStyles";
import type { BarGraphProps } from "./types";
import { useFormatter } from "./useFormatter";

export const BarGraph = memo<BarGraphProps>(
  ({ data, loading, dataKeys, title, caption, unit, width, height }) => {
    const xAxisTicks = [data[0]?.date ?? "", data[data.length - 1]?.date ?? ""];

    const { legendFormatter, tooltipFormatter } = useFormatter({ title });

    return (
      <CustomerDashboardCard title={title} caption={caption}>
        {loading ? (
          <Loading width={width} height={height} />
        ) : (
          <BarChart width={width} height={height} data={data}>
            <CartesianGrid stroke={GRID_COLOR} strokeDasharray={CARTESIAN_GRID_STROKE_DASHARRAY} />
            <XAxis
              dataKey="date"
              ticks={xAxisTicks}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: TICK_FONT_SIZE }}
            />
            <Tooltip formatter={tooltipFormatter} />
            <Legend
              iconType="rect"
              verticalAlign="top"
              height={LEGEND_HEIGHT}
              formatter={legendFormatter}
            />
            <YAxis
              unit={unit}
              allowDecimals={false}
              axisLine={{ stroke: GRID_COLOR }}
              tickLine={{ stroke: GRID_COLOR }}
              tick={{ fontSize: TICK_FONT_SIZE }}
            />

            {dataKeys.map((dataKey, index) => (
              <Bar
                key={dataKey}
                dataKey={dataKey}
                stackId={title}
                fill={BAR_COLORS?.[index]}
                unit={unit}
              />
            ))}
          </BarChart>
        )}
      </CustomerDashboardCard>
    );
  },
);
