import React, { memo, useCallback } from "react";
import { Modal } from "antd";
import { Dayjs } from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";

import { EditTargetDateFormValues } from "./EditTargetDateForm/useEditTargetDateForm";
import { EditTargetDateForm } from "./EditTargetDateForm";
import { useEditTargetDateModalUpdateCloseCashRegisterMutation } from "./queries";

type Props = {
  loading: boolean;
  closeCashRegisterId: string | undefined;
  targetDate: Dayjs;
  goBack: () => void;
  onUpdated: (date: string) => void;
};

export const EditTargetDateModal = memo<Props>(
  ({ loading, closeCashRegisterId, targetDate, goBack, onUpdated }) => {
    const [editTargetDateMutation, { loading: updating }] =
      useEditTargetDateModalUpdateCloseCashRegisterMutation();

    const handleSubmit = useCallback(
      async (option: EditTargetDateFormValues) => {
        if (!closeCashRegisterId) return;
        try {
          const targetDateString = option.targetDate.format("YYYY-MM-DD");

          await editTargetDateMutation({
            variables: { closeCashRegisterId, targetDate: targetDateString },
          });

          onUpdated(targetDateString);

          message.success("編集を保存しました");

          return;
        } catch (err) {
          message.error(err instanceof Error ? err.message : "編集の保存に失敗しました");
        }

        goBack();
      },
      [closeCashRegisterId, editTargetDateMutation, onUpdated, goBack],
    );

    const onFormValidationError = useCallback(
      (_args: { formValidationError: ValidateErrorEntity }) => {
        message.error("入力内容に誤りがあります");
      },
      [],
    );

    return (
      <Modal title="対象日を変更する" open width={900} onCancel={goBack} footer={null}>
        {loading && <Loading fullWidth height={300} />}
        {closeCashRegisterId && (
          <EditTargetDateForm
            targetDate={targetDate}
            onSubmit={handleSubmit}
            onFormValidationError={onFormValidationError}
            onCancel={goBack}
            loading={updating}
          />
        )}
      </Modal>
    );
  },
);
