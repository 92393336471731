import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { ClerkTable } from "pages/Clerks/ClerkTable";
import { useClerksGetClerksQuery } from "pages/Clerks/queries";

export const Clerks = () => {
  const { canAccess } = useCanAccess();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getClerksData,
    loading: loadingClerks,
    error,
  } = useClerksGetClerksQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shopClerks = getClerksData?.shop_by_pk?.shopClerks ?? [];
  const clerks = shopClerks.flatMap(({ clerk, isDisplay }) => ({ ...clerk, isDisplay }));

  return (
    <DashboardLayout title="店員一覧">
      <PageHeader
        title="店員一覧"
        extra={[
          canAccess("addClerk") && (
            <Link key="add" to="/clerk/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
          canAccess("editClerkPriorities") && (
            <Link key="priority" to="/clerk/priority/edit">
              <Button>表示順編集</Button>
            </Link>
          ),
        ]}
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ClerkTable clerks={clerks} loading={loadingClerks} />
    </DashboardLayout>
  );
};
