import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { BulkUpdateIsDisplayByShopTable } from "components/BulkUpdateIsDisplayByShopTable";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditInflowSourceTagForm } from "pages/EditInflowSourceTag/EditInflowSourceTagForm";
import {
  useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation,
  useEditInflowSourceTagGetInflowSourceTagQuery,
  useEditInflowSourceTagGetShopsQuery,
  useEditInflowSourceTagUpdateInflowSourceTagMutation,
  useEditInflowSourceTagUpdateShopInflowSourceTagMutation,
} from "pages/EditInflowSourceTag/queries";
import { InflowSourceTagSetInput } from "types/graphql";

export const EditInflowSourceTag = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: getInflowSourceTagData,
    loading: loadingInflowSourceTag,
    refetch: refetchInflowSourceTag,
    error,
  } = useEditInflowSourceTagGetInflowSourceTagQuery(id ? { variables: { id } } : { skip: true });
  const inflowSourceTag = getInflowSourceTagData?.inflowSourceTag[0];

  const {
    data: getShopsData,
    loading: loadingShops,
    refetch: refetchShops,
  } = useEditInflowSourceTagGetShopsQuery(
    id && companyId ? { variables: { id, companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [updateInflowSourceTagMutation, { loading: loadingUpdateInflowSourceTag }] =
    useEditInflowSourceTagUpdateInflowSourceTagMutation();

  const onSubmit = useCallback(
    async (inflowSourceTag: InflowSourceTagSetInput) => {
      if (!id) return;

      try {
        await updateInflowSourceTagMutation({
          variables: { inflowSourceTagId: id, inflowSourceTag },
        });

        await refetchInflowSourceTag();
        message.success("編集を保存しました");

        navigate("/inflowSourceTag", { replace: true });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [navigate, id, updateInflowSourceTagMutation, refetchInflowSourceTag],
  );

  const [updateShopInflowSourceTagMutation] =
    useEditInflowSourceTagUpdateShopInflowSourceTagMutation();

  const [bulkUpdateInflowSourceTagsMutation] =
    useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation();

  const onUpdateIsDisplay = useCallback(
    async (shopId: string, isDisplay: boolean) => {
      if (!companyId) return;
      if (!inflowSourceTag) return;

      const shopInflowSourceTag = { shopId, inflowSourceTagId: inflowSourceTag.id, isDisplay };

      try {
        await updateShopInflowSourceTagMutation({
          variables: { shopInflowSourceTag },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [companyId, inflowSourceTag, updateShopInflowSourceTagMutation, refetchShops],
  );

  const onBulkUpdateIsDisplay = useCallback(
    async ({ shopIds, isDisplay }: { shopIds: string[]; isDisplay: boolean }) => {
      if (!companyId || !inflowSourceTag) return;

      const shopInflowSourceTags = shopIds.map((shopId) => ({
        shopId,
        inflowSourceTagId: inflowSourceTag.id,
        isDisplay,
      }));

      try {
        await bulkUpdateInflowSourceTagsMutation({
          variables: { shopInflowSourceTags },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [companyId, inflowSourceTag, bulkUpdateInflowSourceTagsMutation, refetchShops],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={inflowSourceTag?.displayName}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "媒体" }],
      }}
    >
      <PageHeader title={inflowSourceTag?.displayName} onBack={goBack} />
      {loadingInflowSourceTag && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {inflowSourceTag && (
        <>
          <EditInflowSourceTagForm
            inflowSourceTag={inflowSourceTag}
            onSubmit={onSubmit}
            onClose={goBack}
            loading={loadingUpdateInflowSourceTag}
          />
          <Spacer height={32} />
          <FormContent>
            <SubTitle>店舗ごとの表示設定</SubTitle>
          </FormContent>
          <BulkUpdateIsDisplayByShopTable
            loading={loadingShops}
            shops={shops}
            onUpdateIsDisplay={onUpdateIsDisplay}
            onBulkUpdateIsDisplay={onBulkUpdateIsDisplay}
            updateTarget="shopInflowSourceTags"
          />
        </>
      )}
    </DashboardLayout>
  );
};
