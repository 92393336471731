import { useCallback, useState } from "react";
import { FormInstance, Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { OptionInputTypeEnum, PlanChoice } from "types/graphql";

export type AddPlanChoiceFormValues = Pick<
  PlanChoice,
  | "description"
  | "isDefaultSelection"
  | "maxOrderableNum"
  | "name"
  | "price"
  | "receiptDisplayName"
  | "costPrice"
  | "costTaxRate"
  | "menuType"
  | "imageUrl"
> & {
  hasOrderCountLimit: boolean;
  nameSources: LocaleValuePair[];
};

type usePlanChoiceFormInput = {
  form: FormInstance<unknown>;
  inputType: OptionInputTypeEnum;
  onChange: (planChoice: AddPlanChoiceFormValues) => void;
};

export const AddPlanChoiceFormItem = createFormItem<AddPlanChoiceFormValues>();

export const useAddPlanChoiceForm = ({ form, inputType, onChange }: usePlanChoiceFormInput) => {
  const [formValues, setFormValues] = useState<Partial<AddPlanChoiceFormValues>>({});
  const [uploadImage, setUploadImage] = useState<string | null>(null);

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as AddPlanChoiceFormValues;
      const imageUrl = uploadImage;
      setFormValues({ ...values, imageUrl });
      onChange({ ...values, imageUrl });
    },
    [onChange, uploadImage],
  );

  const changeImage = useCallback(
    (imageUrl: string) => {
      const formValues = form.getFieldsValue() as AddPlanChoiceFormValues;
      setUploadImage(imageUrl);
      onChange({ ...formValues, imageUrl });
    },
    [form, onChange],
  );

  return { formValues, change, changeImage, uploadImage };
};
