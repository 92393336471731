import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionTecAggregationOptionGetOption = gql`
    query EditOptionTecAggregationOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      choiceId
      name
      tecAggregationMenu {
        id
        name
        menuCode
        dpCode
        dpName
        gpCode
        gpName
      }
    }
  }
}
    `;
export const EditOptionTecAggregationOptionGetMenuCode = gql`
    query EditOptionTecAggregationOptionGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const EditOptionTecAggregationOptionUpdateChoices = gql`
    mutation EditOptionTecAggregationOptionUpdateChoices($tecAggregationChoices: DashboardAccountUpsertTecAggregationMenusBulkInput!) {
  upsertTecAggregationMenusBulk(input: $tecAggregationChoices) {
    ... on DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
      __typename
      dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
        __typename
        dpCode
        dpName
        gpCode
        gpName
        menuCode
        name
      }
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditOptionTecAggregationOptionGetOptionQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionTecAggregationOptionGetOptionQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name'>
    & { choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'name'>
      & { tecAggregationMenu?: Types.Maybe<(
        { __typename?: 'tecAggregationMenu' }
        & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
      )> }
    )> }
  )> }
);

export type EditOptionTecAggregationOptionGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOptionTecAggregationOptionGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type EditOptionTecAggregationOptionUpdateChoicesMutationVariables = Types.Exact<{
  tecAggregationChoices: Types.DashboardAccountUpsertTecAggregationMenusBulkInput;
}>;


export type EditOptionTecAggregationOptionUpdateChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenusBulk: (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode' }
    & { dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode: Array<(
      { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
      & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
    )> }
  ) }
);


export const EditOptionTecAggregationOptionGetOptionDocument = gql`
    query EditOptionTecAggregationOptionGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    optionId
    name
    choices(order_by: [{priority: asc}, {choiceId: asc}]) {
      choiceId
      name
      tecAggregationMenu {
        id
        name
        menuCode
        dpCode
        dpName
        gpCode
        gpName
      }
    }
  }
}
    `;

/**
 * __useEditOptionTecAggregationOptionGetOptionQuery__
 *
 * To run a query within a React component, call `useEditOptionTecAggregationOptionGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionTecAggregationOptionGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionTecAggregationOptionGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionTecAggregationOptionGetOptionQuery(baseOptions: Apollo.QueryHookOptions<EditOptionTecAggregationOptionGetOptionQuery, EditOptionTecAggregationOptionGetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionTecAggregationOptionGetOptionQuery, EditOptionTecAggregationOptionGetOptionQueryVariables>(EditOptionTecAggregationOptionGetOptionDocument, options);
      }
export function useEditOptionTecAggregationOptionGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionTecAggregationOptionGetOptionQuery, EditOptionTecAggregationOptionGetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionTecAggregationOptionGetOptionQuery, EditOptionTecAggregationOptionGetOptionQueryVariables>(EditOptionTecAggregationOptionGetOptionDocument, options);
        }
export type EditOptionTecAggregationOptionGetOptionQueryHookResult = ReturnType<typeof useEditOptionTecAggregationOptionGetOptionQuery>;
export type EditOptionTecAggregationOptionGetOptionLazyQueryHookResult = ReturnType<typeof useEditOptionTecAggregationOptionGetOptionLazyQuery>;
export type EditOptionTecAggregationOptionGetOptionQueryResult = Apollo.QueryResult<EditOptionTecAggregationOptionGetOptionQuery, EditOptionTecAggregationOptionGetOptionQueryVariables>;
export const EditOptionTecAggregationOptionGetMenuCodeDocument = gql`
    query EditOptionTecAggregationOptionGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useEditOptionTecAggregationOptionGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditOptionTecAggregationOptionGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionTecAggregationOptionGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionTecAggregationOptionGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOptionTecAggregationOptionGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditOptionTecAggregationOptionGetMenuCodeQuery, EditOptionTecAggregationOptionGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionTecAggregationOptionGetMenuCodeQuery, EditOptionTecAggregationOptionGetMenuCodeQueryVariables>(EditOptionTecAggregationOptionGetMenuCodeDocument, options);
      }
export function useEditOptionTecAggregationOptionGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionTecAggregationOptionGetMenuCodeQuery, EditOptionTecAggregationOptionGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionTecAggregationOptionGetMenuCodeQuery, EditOptionTecAggregationOptionGetMenuCodeQueryVariables>(EditOptionTecAggregationOptionGetMenuCodeDocument, options);
        }
export type EditOptionTecAggregationOptionGetMenuCodeQueryHookResult = ReturnType<typeof useEditOptionTecAggregationOptionGetMenuCodeQuery>;
export type EditOptionTecAggregationOptionGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditOptionTecAggregationOptionGetMenuCodeLazyQuery>;
export type EditOptionTecAggregationOptionGetMenuCodeQueryResult = Apollo.QueryResult<EditOptionTecAggregationOptionGetMenuCodeQuery, EditOptionTecAggregationOptionGetMenuCodeQueryVariables>;
export const EditOptionTecAggregationOptionUpdateChoicesDocument = gql`
    mutation EditOptionTecAggregationOptionUpdateChoices($tecAggregationChoices: DashboardAccountUpsertTecAggregationMenusBulkInput!) {
  upsertTecAggregationMenusBulk(input: $tecAggregationChoices) {
    ... on DashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
      __typename
      dashboardUpsertTecAggregationMenusBulkDifferentCodeIsUsedForSameMenuCode {
        __typename
        dpCode
        dpName
        gpCode
        gpName
        menuCode
        name
      }
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditOptionTecAggregationOptionUpdateChoicesMutationFn = Apollo.MutationFunction<EditOptionTecAggregationOptionUpdateChoicesMutation, EditOptionTecAggregationOptionUpdateChoicesMutationVariables>;

/**
 * __useEditOptionTecAggregationOptionUpdateChoicesMutation__
 *
 * To run a mutation, you first call `useEditOptionTecAggregationOptionUpdateChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionTecAggregationOptionUpdateChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionTecAggregationOptionUpdateChoicesMutation, { data, loading, error }] = useEditOptionTecAggregationOptionUpdateChoicesMutation({
 *   variables: {
 *      tecAggregationChoices: // value for 'tecAggregationChoices'
 *   },
 * });
 */
export function useEditOptionTecAggregationOptionUpdateChoicesMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionTecAggregationOptionUpdateChoicesMutation, EditOptionTecAggregationOptionUpdateChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionTecAggregationOptionUpdateChoicesMutation, EditOptionTecAggregationOptionUpdateChoicesMutationVariables>(EditOptionTecAggregationOptionUpdateChoicesDocument, options);
      }
export type EditOptionTecAggregationOptionUpdateChoicesMutationHookResult = ReturnType<typeof useEditOptionTecAggregationOptionUpdateChoicesMutation>;
export type EditOptionTecAggregationOptionUpdateChoicesMutationResult = Apollo.MutationResult<EditOptionTecAggregationOptionUpdateChoicesMutation>;
export type EditOptionTecAggregationOptionUpdateChoicesMutationOptions = Apollo.BaseMutationOptions<EditOptionTecAggregationOptionUpdateChoicesMutation, EditOptionTecAggregationOptionUpdateChoicesMutationVariables>;