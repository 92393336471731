import React, { memo } from "react";
import { isNot } from "util/type/primitive";

import { Select } from "components/Input/Select";
import type { KdDisplayTarget } from "pages/ShopMenus/types";

type Props = {
  onChange: (kdDisplayTargetIds: "empty" | string[]) => void;
  kdDisplayTargets: KdDisplayTarget[];
  value: "empty" | string[] | undefined;
};

export const KdDisplayTargetField = memo<Props>(({ onChange, kdDisplayTargets, value }) => (
  <Select<(string | "empty")[]>
    mode="multiple"
    allowClear
    placeholder="キッチンディスプレイ"
    defaultValue={[]}
    value={value === "empty" ? [value] : value ?? []}
    onChange={(kdDisplayTargetIds) =>
      onChange(
        kdDisplayTargetIds.slice(-1)[0] === "empty"
          ? "empty"
          : kdDisplayTargetIds.filter(isNot("empty")),
      )
    }
    optionFilterProp="label"
    options={[
      { label: "未設定", value: "empty" },
      ...kdDisplayTargets.map(({ id, name }) => ({ label: name, value: id })),
    ]}
    data-cy="select-kd-display-target"
  />
));
