import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditGiftFormItem } from "../useEditGiftForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <EditGiftFormItem label="説明" name="description" {...props}>
    <Input />
  </EditGiftFormItem>
));
