import React, { memo } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { GiftMeta } from "pages/EditGiftPriorities/types";
import { GiftMetaInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  giftMeta: GiftMeta[];
  onUpdate: (giftMeta: GiftMetaInsertInput[]) => void;
};

export const EditGiftPriorityList = memo<Props>(({ loading, giftMeta, onUpdate }) => {
  const handleMoveItem = (dragIndex: number, hoverIndex: number) => {
    if (dragIndex !== hoverIndex) {
      onUpdate(
        updatePriorities(giftMeta, dragIndex, hoverIndex).map(({ menu, ...giftMeta }) => ({
          ...giftMeta,
          menuId: menu.id,
          _menuId: menu.menuId,
        })),
      );
    }
  };

  return (
    <PriorityList<GiftMeta>
      itemLayout="horizontal"
      loading={loading}
      dataSource={giftMeta}
      renderItem={({ id, menu, priority }, index) => (
        <PriorityListItem
          id={id}
          name={menu.name}
          priority={priority}
          index={index}
          onMove={handleMoveItem}
        />
      )}
    />
  );
});
