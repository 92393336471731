import React, { memo } from "react";
import { Space, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const VisitedBusinessOperationHoursLabel = memo(() => (
  <Space>
    来店した時間帯
    <Tooltip
      title="店舗管理 > 店舗ページ の「営業時間帯」でランチなどの時間を設定している場合のみご利用いただけます"
      overlayInnerStyle={{
        width: 412,
      }}
    >
      <QuestionCircleOutlined />
    </Tooltip>
  </Space>
));
