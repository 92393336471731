import React, { memo, useMemo } from "react";
import styled, { css } from "styled-components";
import {
  applySignToFluctuation,
  getRankViewPropertiesByScore,
  QuestionCategoryTypeToWord,
} from "models/questionnaireAnalytics";

import { colors } from "constants/colors";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";
import type { Score } from "pages/QuestionnaireAnalyticsScoresMetrics/types";
import { QuestionCategoryType } from "types/graphql";

const Wrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
  padding: 4px 8px;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  @media ${viewport.smartphone}, ${viewport.tablet} {
    flex-direction: column;
    gap: 2px;
    width: 100%;
    height: 50px;
    border-radius: 4px;
  }
`;

const IconWrapper = styled.div`
  position: relative;
`;

const RankText = styled.div<{ color: string; isDesktop: boolean }>`
  position: absolute;
  right: 100%;
  top: ${({ isDesktop }) => (isDesktop ? "-25%" : 0)};
  font-weight: bold;
  font-size: 16px;

  color: ${({ color }) => color};
`;

const ScorePanel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ScoreWithFluctuation = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    display: flex;
    gap: 2px;
  }
`;

const Category = styled.div`
  width: 100%;
  font-size: 10px;
  color: ${colors.Text.Secondary};
  text-align: left;
`;

const ScoreText = styled.div<{ isInactive: boolean }>`
  font-weight: bold;
  color: ${(props) => props.isInactive && `${colors.Text.Secondary}`};
`;

const FluctuationText = styled.div`
  font-size: 12px;
  color: ${colors.Text.Secondary};
  padding-top: 2px;
`;

type Props = {
  score: Score;
  category?: QuestionCategoryType;
  showIcon?: boolean;
  isInactive: boolean;
  isEmpty: boolean;
};

export const ScoreCell = memo<Props>(({ score, category, showIcon, isInactive, isEmpty }) => {
  const { backgroundColor, rank, color, iconSrc } = useMemo(
    () => getRankViewPropertiesByScore(score.score),
    [score.score],
  );

  const { isDesktop } = useViewport();

  const shouldShowIcon = !isEmpty && !isInactive && showIcon;

  return (
    <Wrapper
      backgroundColor={shouldShowIcon ? backgroundColor : !isDesktop ? "#F1F1F1" : undefined}
    >
      {!isDesktop && category && <Category>{QuestionCategoryTypeToWord[category]}</Category>}
      <ScorePanel>
        {shouldShowIcon && (
          <IconWrapper>
            <img src={iconSrc} width={24} height={24} alt={`icon ${rank}`} />
            <RankText color={color} isDesktop={isDesktop}>
              {rank}
            </RankText>
          </IconWrapper>
        )}

        <ScoreWithFluctuation>
          <ScoreText isInactive={isInactive}>{isEmpty ? "-" : score.score}</ScoreText>
          {!isEmpty && !isInactive && (
            <FluctuationText>{applySignToFluctuation(score.fluctuation)}</FluctuationText>
          )}
        </ScoreWithFluctuation>
      </ScorePanel>
    </Wrapper>
  );
});
