import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Category } from "pages/EditCategory/types";
import { CategorySetInput } from "types/graphql";

export type EditCategoryFormValues = Pick<
  Category,
  "name" | "shopSideName" | "shopSideBackgroundColor" | "imageUrl"
>;

const getInitialValues = (category: Category) => {
  const { name, shopSideName, shopSideBackgroundColor, imageUrl } = category;
  return { name, shopSideName, shopSideBackgroundColor, imageUrl: imageUrl ?? null };
};

export const EditCategoryFormItem = createFormItem<EditCategoryFormValues>();

export const useEditCategoryForm = (
  category: Category,
  onSubmit: (category: CategorySetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(category);

  const submit = useCallback(
    ({
      imageUrl,
      shopSideBackgroundColor,
    }: {
      imageUrl: string | null | undefined;
      shopSideBackgroundColor: string;
    }) => {
      const values = form.getFieldsValue() as EditCategoryFormValues;
      onSubmit({ ...values, imageUrl, shopSideBackgroundColor });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
