import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { LineChannelConfigInsertInput } from "types/graphql";

import { Shop } from "../types";

const getInitialValues = ({ shop }: { shop: Shop }) => ({
  channelId: shop.lineChannelConfig?.channelId,
  channelSecret: shop.lineChannelConfig?.channelSecret,
  liffId: shop.lineChannelConfig?.liffId,
  lineOfficialAccountId: shop.lineOfficialAccountId,
});

export type EditLineChannelConfigFormValues = Pick<
  LineChannelConfigInsertInput,
  "liffId" | "channelId" | "channelSecret"
> & {
  lineOfficialAccountId?: number | null;
};

export const EditLineChannelConfigFormItem = createFormItem<EditLineChannelConfigFormValues>();

export const useEditLineChannelConfigForm = ({
  shop,
  onSubmit,
}: {
  shop: Shop;
  onSubmit: (form: EditLineChannelConfigFormValues) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ shop });

  const submit = useCallback(() => {
    onSubmit(form.getFieldsValue() as EditLineChannelConfigFormValues);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
