import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddOnSitePaymentDetailTypeFormItem } from "../useAddOnSitePaymentDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AllowChangeField = memo<Props>(({ ...props }) => (
  <AddOnSitePaymentDetailTypeFormItem
    label="お釣りあり"
    name="allowChange"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </AddOnSitePaymentDetailTypeFormItem>
));
