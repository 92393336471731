import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCategoryTranslationsGetCategory = gql`
    query EditCategoryTranslationsGetCategory($categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    categoryId
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "category"}}) {
      columnName
      locale
      value
    }
  }
}
    `;
export const EditCategoryTranslationsUpdateCategoryTranslations = gql`
    mutation EditCategoryTranslationsUpdateCategoryTranslations($input: UpsertTranslationsForCategoryInput!) {
  upsertTranslationsForCategory(input: $input)
}
    `;
export type EditCategoryTranslationsGetCategoryQueryVariables = Types.Exact<{
  categoryId: Types.Scalars['Int'];
}>;


export type EditCategoryTranslationsGetCategoryQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'categoryId' | 'name'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type EditCategoryTranslationsUpdateCategoryTranslationsMutationVariables = Types.Exact<{
  input: Types.UpsertTranslationsForCategoryInput;
}>;


export type EditCategoryTranslationsUpdateCategoryTranslationsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForCategory'>
);


export const EditCategoryTranslationsGetCategoryDocument = gql`
    query EditCategoryTranslationsGetCategory($categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    categoryId
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "category"}}) {
      columnName
      locale
      value
    }
  }
}
    `;

/**
 * __useEditCategoryTranslationsGetCategoryQuery__
 *
 * To run a query within a React component, call `useEditCategoryTranslationsGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryTranslationsGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCategoryTranslationsGetCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useEditCategoryTranslationsGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<EditCategoryTranslationsGetCategoryQuery, EditCategoryTranslationsGetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCategoryTranslationsGetCategoryQuery, EditCategoryTranslationsGetCategoryQueryVariables>(EditCategoryTranslationsGetCategoryDocument, options);
      }
export function useEditCategoryTranslationsGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCategoryTranslationsGetCategoryQuery, EditCategoryTranslationsGetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCategoryTranslationsGetCategoryQuery, EditCategoryTranslationsGetCategoryQueryVariables>(EditCategoryTranslationsGetCategoryDocument, options);
        }
export type EditCategoryTranslationsGetCategoryQueryHookResult = ReturnType<typeof useEditCategoryTranslationsGetCategoryQuery>;
export type EditCategoryTranslationsGetCategoryLazyQueryHookResult = ReturnType<typeof useEditCategoryTranslationsGetCategoryLazyQuery>;
export type EditCategoryTranslationsGetCategoryQueryResult = Apollo.QueryResult<EditCategoryTranslationsGetCategoryQuery, EditCategoryTranslationsGetCategoryQueryVariables>;
export const EditCategoryTranslationsUpdateCategoryTranslationsDocument = gql`
    mutation EditCategoryTranslationsUpdateCategoryTranslations($input: UpsertTranslationsForCategoryInput!) {
  upsertTranslationsForCategory(input: $input)
}
    `;
export type EditCategoryTranslationsUpdateCategoryTranslationsMutationFn = Apollo.MutationFunction<EditCategoryTranslationsUpdateCategoryTranslationsMutation, EditCategoryTranslationsUpdateCategoryTranslationsMutationVariables>;

/**
 * __useEditCategoryTranslationsUpdateCategoryTranslationsMutation__
 *
 * To run a mutation, you first call `useEditCategoryTranslationsUpdateCategoryTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryTranslationsUpdateCategoryTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCategoryTranslationsUpdateCategoryTranslationsMutation, { data, loading, error }] = useEditCategoryTranslationsUpdateCategoryTranslationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCategoryTranslationsUpdateCategoryTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<EditCategoryTranslationsUpdateCategoryTranslationsMutation, EditCategoryTranslationsUpdateCategoryTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCategoryTranslationsUpdateCategoryTranslationsMutation, EditCategoryTranslationsUpdateCategoryTranslationsMutationVariables>(EditCategoryTranslationsUpdateCategoryTranslationsDocument, options);
      }
export type EditCategoryTranslationsUpdateCategoryTranslationsMutationHookResult = ReturnType<typeof useEditCategoryTranslationsUpdateCategoryTranslationsMutation>;
export type EditCategoryTranslationsUpdateCategoryTranslationsMutationResult = Apollo.MutationResult<EditCategoryTranslationsUpdateCategoryTranslationsMutation>;
export type EditCategoryTranslationsUpdateCategoryTranslationsMutationOptions = Apollo.BaseMutationOptions<EditCategoryTranslationsUpdateCategoryTranslationsMutation, EditCategoryTranslationsUpdateCategoryTranslationsMutationVariables>;