import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Corporation } from "types/graphql";

export type AddCorporationFormValues = Pick<Corporation, "name">;

const getInitialValues = () => ({});

export const AddCorporationFormItem = createFormItem<AddCorporationFormValues>();

export const useAddCorporationForm = (onSubmit: (args: { name: string }) => void) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AddCorporationFormValues;

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
