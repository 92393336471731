import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OnSitePaymentDiscountTypesGetDiscountTypes = gql`
    query OnSitePaymentDiscountTypesGetDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    type
    label
  }
}
    `;
export const OnSitePaymentDiscountTypesArchive = gql`
    mutation OnSitePaymentDiscountTypesArchive($id: uuid!) {
  update_onSitePaymentDiscountType(
    where: {id: {_eq: $id}}
    _set: {isArchived: true}
  ) {
    returning {
      id
    }
  }
}
    `;
export type OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDiscountTypesGetDiscountTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'id' | 'type' | 'label'>
  )> }
);

export type OnSitePaymentDiscountTypesArchiveMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDiscountTypesArchiveMutation = (
  { __typename?: 'mutation_root' }
  & { update_onSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDiscountType_mutation_response' }
    & { returning: Array<(
      { __typename?: 'onSitePaymentDiscountType' }
      & Pick<Types.OnSitePaymentDiscountType, 'id'>
    )> }
  )> }
);


export const OnSitePaymentDiscountTypesGetDiscountTypesDocument = gql`
    query OnSitePaymentDiscountTypesGetDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    type
    label
  }
}
    `;

/**
 * __useOnSitePaymentDiscountTypesGetDiscountTypesQuery__
 *
 * To run a query within a React component, call `useOnSitePaymentDiscountTypesGetDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDiscountTypesGetDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSitePaymentDiscountTypesGetDiscountTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnSitePaymentDiscountTypesGetDiscountTypesQuery(baseOptions: Apollo.QueryHookOptions<OnSitePaymentDiscountTypesGetDiscountTypesQuery, OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnSitePaymentDiscountTypesGetDiscountTypesQuery, OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables>(OnSitePaymentDiscountTypesGetDiscountTypesDocument, options);
      }
export function useOnSitePaymentDiscountTypesGetDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnSitePaymentDiscountTypesGetDiscountTypesQuery, OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnSitePaymentDiscountTypesGetDiscountTypesQuery, OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables>(OnSitePaymentDiscountTypesGetDiscountTypesDocument, options);
        }
export type OnSitePaymentDiscountTypesGetDiscountTypesQueryHookResult = ReturnType<typeof useOnSitePaymentDiscountTypesGetDiscountTypesQuery>;
export type OnSitePaymentDiscountTypesGetDiscountTypesLazyQueryHookResult = ReturnType<typeof useOnSitePaymentDiscountTypesGetDiscountTypesLazyQuery>;
export type OnSitePaymentDiscountTypesGetDiscountTypesQueryResult = Apollo.QueryResult<OnSitePaymentDiscountTypesGetDiscountTypesQuery, OnSitePaymentDiscountTypesGetDiscountTypesQueryVariables>;
export const OnSitePaymentDiscountTypesArchiveDocument = gql`
    mutation OnSitePaymentDiscountTypesArchive($id: uuid!) {
  update_onSitePaymentDiscountType(
    where: {id: {_eq: $id}}
    _set: {isArchived: true}
  ) {
    returning {
      id
    }
  }
}
    `;
export type OnSitePaymentDiscountTypesArchiveMutationFn = Apollo.MutationFunction<OnSitePaymentDiscountTypesArchiveMutation, OnSitePaymentDiscountTypesArchiveMutationVariables>;

/**
 * __useOnSitePaymentDiscountTypesArchiveMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDiscountTypesArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDiscountTypesArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDiscountTypesArchiveMutation, { data, loading, error }] = useOnSitePaymentDiscountTypesArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnSitePaymentDiscountTypesArchiveMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDiscountTypesArchiveMutation, OnSitePaymentDiscountTypesArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDiscountTypesArchiveMutation, OnSitePaymentDiscountTypesArchiveMutationVariables>(OnSitePaymentDiscountTypesArchiveDocument, options);
      }
export type OnSitePaymentDiscountTypesArchiveMutationHookResult = ReturnType<typeof useOnSitePaymentDiscountTypesArchiveMutation>;
export type OnSitePaymentDiscountTypesArchiveMutationResult = Apollo.MutationResult<OnSitePaymentDiscountTypesArchiveMutation>;
export type OnSitePaymentDiscountTypesArchiveMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDiscountTypesArchiveMutation, OnSitePaymentDiscountTypesArchiveMutationVariables>;