import React, { memo } from "react";
import { getTaxMethodName, taxMethods } from "models/taxMethod";

import { Select } from "components/Input/Select";

import { EditMenusBulkFormItem } from "../../useEditMenusBulkForm";

type Props = {
  value: string | undefined;
  initialValue: string;
  categoryMenuId: number;
  disabled: boolean;
};

export const TaxMethodField = memo<Props>(({ initialValue, value, categoryMenuId, disabled }) => (
  <EditMenusBulkFormItem name={[categoryMenuId, "taxMethod"]} noStyle>
    <Select
      placeholder={getTaxMethodName(initialValue)}
      defaultValue={value}
      showSearch
      options={Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => ({
        label: taxMethodName,
        value: taxMethod,
      }))}
      allowClear
      fullWidth
      disabled={disabled}
    />
  </EditMenusBulkFormItem>
));
