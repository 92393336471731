import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditRoleFormItem } from "../useEditRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OnlinePaymentSlipField = memo<Props>(({ ...props }) => (
  <EditRoleFormItem
    label="オンライン決済通知"
    name="shouldPrintOnlinePaymentSlip"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditRoleFormItem>
));
