import React, { memo, useMemo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";

import { InflowSourceTag } from "../type";

type Props = {
  loading?: boolean;
  inflowSourceTags: InflowSourceTag[];
};

export const WinboardInflowSourceTagTable = memo<Props>(({ loading, inflowSourceTags }) => {
  const { canAccess } = useCanAccess();

  const columns = useMemo(
    () => [
      {
        title: "媒体名",
        render(_: string, { displayName }: InflowSourceTag) {
          return displayName;
        },
      } as const,
      {
        title: "媒体コード",
        width: 300,
        render(_: string, { winboardInflowSourceTag }: InflowSourceTag) {
          return winboardInflowSourceTag?.code;
        },
      } as const,
      ...(canAccess("editWinboardInflowSourceTag")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { id }: InflowSourceTag) {
                return (
                  <IconLink to={`/winboard/inflowSourceTag/${id}/edit`}>
                    <EditIcon />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  return (
    <Table
      rowKey="type"
      columns={columns}
      dataSource={inflowSourceTags}
      loading={loading}
      bordered
    />
  );
});
