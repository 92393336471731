import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";

import { EditCategoryFormItem } from "../useEditCategoryForm";

type Props = {
  uploadImage: string | null;
  setUploadImage: React.Dispatch<React.SetStateAction<string | null>>;
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const HeaderUploadField = memo<Props>(
  ({ uploadImage, setUploadImage, disabled, ...props }) => (
    <EditCategoryFormItem.NonProperty
      label={
        <FormHelp
          label="カテゴリ画像"
          help="カテゴリ画像の比率は、特に指定はありません。最適なサイズに自動的に変換されます"
        />
      }
      {...props}
    >
      <ImageField
        image={uploadImage}
        setUploadImage={setUploadImage}
        uploadImageApiKey="category"
        formName="EditCategoryForm"
        disabled={disabled}
        width={416}
        height={234}
        isAutoHeight
        isMovieAccepted
      />
    </EditCategoryFormItem.NonProperty>
  ),
);
