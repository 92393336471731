import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import dayjs from "dayjs";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCorporation } from "hooks/useCorporation";

import { AddLineReportingBotToGroupTalk } from "./AddLineReportingBotToGroupTalk";
import { EditLineReportingBotConfig } from "./EditLineReportingBotConfig";
import {
  useAddLineReportingBotConfigGetLineReportingBotConfigQuery,
  useAddLineReportingBotConfigGetLineReportingBotVerificationQuery,
  useAddLineReportingBotConfigGetShopsQuery,
  useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation,
  useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation,
} from "./queries";
import { SendVerificationCodeToLine } from "./SendVerificationCodeToLine";

const pageIndexes = {
  addLineReportingBotToGroupTalk: 0,
  sendVerificationCodeToLine: 1,
  editLineReportingBotConfig: 2,
};

const GET_LINE_REPORTING_BOT_CONFIG_POLLING_INTERVAL = 10 * 1000;

export const AddLineReportingBotConfig = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState(pageIndexes.addLineReportingBotToGroupTalk);

  const [lineReportingBotVerificationId, setLineReportingBotVerificationId] = useState<
    string | null
  >(null);

  const {
    data: getShopsData,
    loading: loadingGetShopsData,
    error: errorGetShopsData,
  } = useAddLineReportingBotConfigGetShopsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);

  const {
    data: getLineReportingBotVerificationData,
    loading: loadingGetLineReportingBotVerificationData,
    error: errorGetLineReportingBotVerificationData,
  } = useAddLineReportingBotConfigGetLineReportingBotVerificationQuery(
    lineReportingBotVerificationId
      ? { variables: { lineReportingBotVerificationId } }
      : { skip: true },
  );
  const lineReportingBotVerification = useMemo(
    () => getLineReportingBotVerificationData?.lineReportingBotVerification_by_pk,
    [getLineReportingBotVerificationData?.lineReportingBotVerification_by_pk],
  );

  const {
    data: getLineReportingBotConfigData,
    loading: loadingLineReportingBotConfigData,
    error: errorLineReportingBotConfigData,
  } = useAddLineReportingBotConfigGetLineReportingBotConfigQuery(
    corporationId && lineReportingBotVerification
      ? {
          variables: {
            corporationId,
            lineReportingBotVerificationId: lineReportingBotVerification.id,
          },
          // 認証コードを表示 → 顧客が LINE グループに認証コードを投稿 → webhook → バックエンドで LineReportingBotConfig 作成 という流れのため、
          // 負荷をかけすぎない程度に LineReportingBotConfig をポーリングする
          pollInterval:
            pageIndex !== pageIndexes.sendVerificationCodeToLine
              ? 0
              : GET_LINE_REPORTING_BOT_CONFIG_POLLING_INTERVAL,
        }
      : { skip: true },
  );
  const lineReportingBotConfig = useMemo(
    () => getLineReportingBotConfigData?.lineReportingBotConfig[0],
    [getLineReportingBotConfigData?.lineReportingBotConfig],
  );

  const [
    issueLineReportingBotVerificationCodeMutation,
    {
      loading: loadingIssueLineReportingBotVerificationCode,
      error: errorIssueLineReportingBotVerificationCode,
    },
  ] = useAddLineReportingBotConfigIssueLineReportingBotVerificationCodeMutation();

  const issueLineReportingBotVerificationCode = useCallback(async () => {
    if (!corporationId) return;
    if (pageIndex !== pageIndexes.addLineReportingBotToGroupTalk) return;

    setPageIndex(pageIndexes.sendVerificationCodeToLine);

    const res = await issueLineReportingBotVerificationCodeMutation({
      variables: { input: { corporationId } },
    });
    const lineReportingBotVerificationId =
      res.data?.dashboardAccountIssueLineReportingBotVerificationCode
        .lineReportingBotVerificationId;
    if (!lineReportingBotVerificationId) return;

    setLineReportingBotVerificationId(lineReportingBotVerificationId);
  }, [corporationId, pageIndex, issueLineReportingBotVerificationCodeMutation]);

  const [
    updateLineReportingBotConfig,
    { loading: loadingUpdateLineReportingBotConfig, error: errorUpdateLineReportingBotConfig },
  ] = useAddLineReportingBotConfigUpdateLineReportingBotConfigMutation();

  const [{ loading: loadingSubmit, error: errorSubmit }, submit] = useAsyncFn(
    async ({ name, shopIds }: { name: string; shopIds: string[] }) => {
      if (!corporationId) return;
      if (!lineReportingBotConfig) return;

      await updateLineReportingBotConfig({
        variables: {
          corporationId,
          lineReportingBotConfigId: lineReportingBotConfig.id,
          name,
          input: shopIds.map((shopId) => ({
            shopId,
            lineReportingBotConfigId: lineReportingBotConfig.id,
          })),
        },
      });

      message.success("作成しました");

      navigate("/lineReportingBotConfig/active", { replace: true });
    },
    [corporationId, lineReportingBotConfig, navigate, updateLineReportingBotConfig],
  );

  const completeLineBotVerification = useCallback(
    () => setPageIndex(pageIndexes.editLineReportingBotConfig),
    [],
  );

  const showErrorMessage = useCallback(() => {
    message.error("入力内容に誤りがあります");
  }, []);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const error =
    errorGetShopsData ||
    errorGetLineReportingBotVerificationData ||
    errorLineReportingBotConfigData ||
    errorIssueLineReportingBotVerificationCode ||
    errorUpdateLineReportingBotConfig ||
    errorSubmit;

  const loading =
    loadingGetShopsData ||
    loadingLineReportingBotConfigData ||
    loadingGetLineReportingBotVerificationData ||
    loadingIssueLineReportingBotVerificationCode ||
    loadingUpdateLineReportingBotConfig ||
    loadingSubmit;

  return (
    <DashboardLayout
      title="ダイニーAIくん連携"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ダイニーAIくん連携" }],
      }}
    >
      <PageHeader title="ダイニーAIくん連携" onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {pageIndex === pageIndexes.addLineReportingBotToGroupTalk ? (
        <AddLineReportingBotToGroupTalk
          onClickBackButton={goBack}
          onClickForwardButton={issueLineReportingBotVerificationCode}
          disabled={Boolean(loading || error)}
        />
      ) : pageIndex === pageIndexes.sendVerificationCodeToLine ? (
        <SendVerificationCodeToLine
          onClickBackButton={goBack}
          onClickForwardButton={completeLineBotVerification}
          verificationCode={lineReportingBotVerification?.code ?? ""}
          verificationCodeExpiredAt={dayjs(lineReportingBotVerification?.expiredAt)}
          disabled={Boolean(loading || error || !lineReportingBotConfig)}
        />
      ) : pageIndex === pageIndexes.editLineReportingBotConfig ? (
        <EditLineReportingBotConfig
          shops={shops}
          onClickBackButton={goBack}
          onSubmit={submit}
          onFormValidationError={showErrorMessage}
          disabled={Boolean(loading || error)}
        />
      ) : null}
    </DashboardLayout>
  );
};
