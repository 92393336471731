import { useCallback } from "react";
import * as uuid from "uuid";

import { createFormItem, Form } from "components/antd/Form";
import { OnSitePaymentDetailTypeCategoryEnum } from "types/graphql";

export type AddOnSitePaymentDetailTypeFormValues = {
  label: string;
  allowChange: boolean;
  onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum | null;
};

const getInitialValues = () => ({
  label: "",
  allowChange: false,
  onSitePaymentDetailTypeCategory: null,
});

export const AddOnSitePaymentDetailTypeFormItem =
  createFormItem<AddOnSitePaymentDetailTypeFormValues>();

export const useAddOnSitePaymentDetailTypeForm = (
  onSubmit: (args: {
    label: string;
    type: string;
    allowChange: boolean;
    onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum;
  }) => void,
) => {
  const [form] = Form.useForm<AddOnSitePaymentDetailTypeFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    if (!values.onSitePaymentDetailTypeCategory) return;

    onSubmit({
      label: values.label,
      type: uuid.v4(),
      allowChange: values.allowChange,
      onSitePaymentDetailTypeCategory: values.onSitePaymentDetailTypeCategory,
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
