import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { Plan, PlanChoice, PlanOption, WinboardMenu } from "../types";

export type EditPlanWinboardPlanFormValues = Pick<
  WinboardMenu,
  "name" | "code" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
> & {
  plan?: Pick<Plan, "planId"> & {
    planOptions: Array<
      Pick<PlanOption, "name"> & {
        planChoices: Array<
          Pick<PlanChoice, "name" | "planChoiceId"> & {
            winboardMenus: Array<
              Pick<
                WinboardMenu,
                "id" | "code" | "name" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
              >
            >;
          }
        >;
      }
    >;
  };
};

export const EditPlanWinboardPlanFormItem = createFormItem<EditPlanWinboardPlanFormValues>();

export const useEditPlanWinboardPlanForm = ({
  plan,
  winboardMenu,
  onSubmit,
}: {
  plan?: Plan;
  winboardMenu?: WinboardMenu;
  onSubmit: (args: EditPlanWinboardPlanFormValues) => void;
}) => {
  const [form] = Form.useForm<EditPlanWinboardPlanFormValues>();

  const initialValues = useMemo<EditPlanWinboardPlanFormValues>(
    () => ({
      name: winboardMenu?.name ?? plan?.planName ?? "",
      code: winboardMenu?.code ?? "",
      bumonCode: winboardMenu?.bumonCode ?? "",
      bumonName: winboardMenu?.bumonName ?? "",
      categoryCode: winboardMenu?.categoryCode ?? "",
      categoryName: winboardMenu?.categoryName ?? "",
      plan,
    }),
    [plan, winboardMenu],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
