import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationMenuGetMenu = gql`
    query EditTecAggregationMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const EditTecAggregationMenuGetMenuCode = gql`
    query EditTecAggregationMenuGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const EditTecAggregationMenuUpdateTecAggregationMenu = gql`
    mutation EditTecAggregationMenuUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditTecAggregationMenuGetMenuQueryVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
}>;


export type EditTecAggregationMenuGetMenuQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'menuId' | 'companyId' | 'name' | 'costPrice' | 'totalDealingShopCount'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    )>, tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type EditTecAggregationMenuGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationMenuGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type EditTecAggregationMenuUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertTecAggregationMenuInput;
}>;


export type EditTecAggregationMenuUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) }
);


export const EditTecAggregationMenuGetMenuDocument = gql`
    query EditTecAggregationMenuGetMenu($menuId: Int!) {
  menu(where: {menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    menuId
    companyId
    name
    costPrice
    totalDealingShopCount
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        categoryId
        name
      }
    }
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useEditTecAggregationMenuGetMenuQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationMenuGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationMenuGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationMenuGetMenuQuery({
 *   variables: {
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useEditTecAggregationMenuGetMenuQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationMenuGetMenuQuery, EditTecAggregationMenuGetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationMenuGetMenuQuery, EditTecAggregationMenuGetMenuQueryVariables>(EditTecAggregationMenuGetMenuDocument, options);
      }
export function useEditTecAggregationMenuGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationMenuGetMenuQuery, EditTecAggregationMenuGetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationMenuGetMenuQuery, EditTecAggregationMenuGetMenuQueryVariables>(EditTecAggregationMenuGetMenuDocument, options);
        }
export type EditTecAggregationMenuGetMenuQueryHookResult = ReturnType<typeof useEditTecAggregationMenuGetMenuQuery>;
export type EditTecAggregationMenuGetMenuLazyQueryHookResult = ReturnType<typeof useEditTecAggregationMenuGetMenuLazyQuery>;
export type EditTecAggregationMenuGetMenuQueryResult = Apollo.QueryResult<EditTecAggregationMenuGetMenuQuery, EditTecAggregationMenuGetMenuQueryVariables>;
export const EditTecAggregationMenuGetMenuCodeDocument = gql`
    query EditTecAggregationMenuGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useEditTecAggregationMenuGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationMenuGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationMenuGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationMenuGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditTecAggregationMenuGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationMenuGetMenuCodeQuery, EditTecAggregationMenuGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationMenuGetMenuCodeQuery, EditTecAggregationMenuGetMenuCodeQueryVariables>(EditTecAggregationMenuGetMenuCodeDocument, options);
      }
export function useEditTecAggregationMenuGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationMenuGetMenuCodeQuery, EditTecAggregationMenuGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationMenuGetMenuCodeQuery, EditTecAggregationMenuGetMenuCodeQueryVariables>(EditTecAggregationMenuGetMenuCodeDocument, options);
        }
export type EditTecAggregationMenuGetMenuCodeQueryHookResult = ReturnType<typeof useEditTecAggregationMenuGetMenuCodeQuery>;
export type EditTecAggregationMenuGetMenuCodeLazyQueryHookResult = ReturnType<typeof useEditTecAggregationMenuGetMenuCodeLazyQuery>;
export type EditTecAggregationMenuGetMenuCodeQueryResult = Apollo.QueryResult<EditTecAggregationMenuGetMenuCodeQuery, EditTecAggregationMenuGetMenuCodeQueryVariables>;
export const EditTecAggregationMenuUpdateTecAggregationMenuDocument = gql`
    mutation EditTecAggregationMenuUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type EditTecAggregationMenuUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<EditTecAggregationMenuUpdateTecAggregationMenuMutation, EditTecAggregationMenuUpdateTecAggregationMenuMutationVariables>;

/**
 * __useEditTecAggregationMenuUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationMenuUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationMenuUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationMenuUpdateTecAggregationMenuMutation, { data, loading, error }] = useEditTecAggregationMenuUpdateTecAggregationMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTecAggregationMenuUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationMenuUpdateTecAggregationMenuMutation, EditTecAggregationMenuUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationMenuUpdateTecAggregationMenuMutation, EditTecAggregationMenuUpdateTecAggregationMenuMutationVariables>(EditTecAggregationMenuUpdateTecAggregationMenuDocument, options);
      }
export type EditTecAggregationMenuUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useEditTecAggregationMenuUpdateTecAggregationMenuMutation>;
export type EditTecAggregationMenuUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<EditTecAggregationMenuUpdateTecAggregationMenuMutation>;
export type EditTecAggregationMenuUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationMenuUpdateTecAggregationMenuMutation, EditTecAggregationMenuUpdateTecAggregationMenuMutationVariables>;