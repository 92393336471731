import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

export type FoodingJournalUploadFormValues = {
  date?: dayjs.Dayjs;
};

export const FoodingJournalUploadFormItem = createFormItem<FoodingJournalUploadFormValues>();

export const useFoodingJournalUploadForm = () => {
  const [form] = Form.useForm();

  const getValues = useCallback(() => {
    const values = form.getFieldsValue() as FoodingJournalUploadFormValues;
    return values;
  }, [form]);

  return { form, getValues };
};
