import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type EditLineReportingBotConfigFormValues = Partial<{
  name: string;
  shopIds: string[];
  isSuspended: boolean;
}>;

const getInitialValues = ({
  currentSelectedShopIds,
  currentEditLineReportingBotConfigName,
  currentIsSuspended,
}: {
  currentSelectedShopIds: string[];
  currentEditLineReportingBotConfigName: string;
  currentIsSuspended: boolean;
}): EditLineReportingBotConfigFormValues => ({
  name: currentEditLineReportingBotConfigName,
  shopIds: currentSelectedShopIds,
  isSuspended: currentIsSuspended,
});

export const EditLineReportingBotConfigFormItem =
  createFormItem<EditLineReportingBotConfigFormValues>();

export const useEditLineReportingBotConfigForm = ({
  onSubmit,
  currentSelectedShopIds,
  currentEditLineReportingBotConfigName,
  currentIsSuspended,
}: {
  onSubmit: ({
    name,
    shopIds,
    isSuspended,
  }: {
    name: string;
    shopIds: string[];
    isSuspended: boolean;
  }) => void;
  currentSelectedShopIds: string[];
  currentEditLineReportingBotConfigName: string;
  currentIsSuspended: boolean;
}) => {
  const [form] = Form.useForm<EditLineReportingBotConfigFormValues>();

  const initialValues = getInitialValues({
    currentEditLineReportingBotConfigName,
    currentSelectedShopIds,
    currentIsSuspended,
  });

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    onSubmit({
      name: formValues.name ?? "",
      shopIds: formValues.shopIds ?? [],
      isSuspended: initialValues.isSuspended ?? false,
    });
  }, [form, onSubmit, initialValues.isSuspended]);

  const submitAsSuspended = useCallback(() => {
    const formValues = form.getFieldsValue();

    onSubmit({ name: formValues.name ?? "", shopIds: formValues.shopIds ?? [], isSuspended: true });
  }, [onSubmit, form]);

  const submitAsUnsuspend = useCallback(() => {
    const formValues = form.getFieldsValue();

    onSubmit({
      name: formValues.name ?? "",
      shopIds: formValues.shopIds ?? [],
      isSuspended: false,
    });
  }, [onSubmit, form]);

  return { form, submit, initialValues, submitAsSuspended, submitAsUnsuspend };
};
