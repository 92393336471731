import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Checkbox, Col, Row } from "antd";
import dayjs from "dayjs";
import { range } from "lodash";
import { formatPrice } from "util/price";

import { FormHelp } from "components/Form/FormHelp";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { AverageAndMaxSalesByDayOfWeek } from "../../../types";
import { EditSalesBudgetFormItem } from "../../useEditSalesBudgetForm";

const Wrapper = styled.div`
  display: flex;
`;

const HeaderItem = styled.div`
  width: 100%;
  margin: 0 4px;
`;

const Top = styled(Row).attrs({ justify: "space-between" })`
  padding: 8px;
  background-color: ${colors.BackGround.Secondary};
`;

const DayOfWeek = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Label = styled.span`
  color: ${colors.Text.Secondary};
  padding-right: 6px;
  font-size: 12px;
`;

const Bottom = styled.div`
  padding: 8px;
  background-color: ${colors.BackGround.Tertiary};
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

type Props = {
  targetMonth: dayjs.Dayjs;
  averageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek[];
};

export const CalendarHeader = memo<Props>(({ targetMonth, averageAndMaxSalesByDayOfWeek }) => {
  const onClick = useCallback(
    ({
      checked,
      dayOfWeekDates,
      setFieldsValue,
    }: {
      checked: boolean;
      dayOfWeekDates: string[];
      setFieldsValue: (value: {
        dayOff: { [date: string]: boolean };
        dailyBudget: { [date: string]: number };
      }) => void;
    }) => {
      setFieldsValue({
        dayOff: Object.fromEntries(dayOfWeekDates.map((date) => [date, !checked])),
        dailyBudget: Object.fromEntries(dayOfWeekDates.map((date) => [date, 0])),
      });
    },
    [],
  );

  const targetMonthDates = useMemo(
    () =>
      range(0, targetMonth.daysInMonth()).map((i) =>
        targetMonth.add(i, "day").format("YYYY-MM-DD"),
      ),
    [targetMonth],
  );
  const dayOfWeekToDatesMap: Map<string, string[]> = useMemo(() => {
    const map = new Map<string, string[]>();
    targetMonthDates.map((date) => {
      const dayOfWeek = dayjs(date).format("ddd");
      map.set(dayOfWeek, [...(map.get(dayOfWeek) ?? []), date]);
    });
    return map;
  }, [targetMonthDates]);

  return (
    <Wrapper>
      {range(0, 7).map((i) => {
        const dayOfWeek = dayjs()
          .day((i + 1) % 7)
          .format("ddd");
        const averageAndMaxSales = averageAndMaxSalesByDayOfWeek?.find(
          (sale) => sale.dayOfWeek === dayOfWeek,
        );

        return (
          <HeaderItem key={i}>
            <Top>
              <Col span={4}>
                <DayOfWeek>{dayOfWeek}</DayOfWeek>
              </Col>
              <Col span={20} style={{ textAlign: "right" }}>
                <EditSalesBudgetFormItem.NonProperty noStyle shouldUpdate>
                  {({ getFieldsValue, setFieldsValue }) => {
                    const { dayOff: dayOffValues } = getFieldsValue();
                    const dayOfWeekDates = dayOfWeekToDatesMap.get(dayOfWeek) ?? [];
                    const checked = dayOfWeekDates.every((date) => dayOffValues?.[date]);

                    return (
                      <>
                        <Label>休</Label>
                        <Checkbox
                          checked={checked}
                          onClick={() =>
                            onClick({
                              checked,
                              dayOfWeekDates,
                              setFieldsValue,
                            })
                          }
                        />
                      </>
                    );
                  }}
                </EditSalesBudgetFormItem.NonProperty>
              </Col>
            </Top>
            <Bottom>
              {dayOfWeek === "月" ? (
                <FormHelp
                  label="平均売上"
                  help={
                    <>
                      過去3ヶ月の各曜日別
                      <br />
                      平均売上（税抜）です
                    </>
                  }
                />
              ) : (
                <span>平均売上</span>
              )}
              <>
                {averageAndMaxSales?.averageTaxExcludedSalesAmount ? (
                  <Price>{formatPrice(averageAndMaxSales.averageTaxExcludedSalesAmount)}</Price>
                ) : (
                  "-"
                )}
              </>
              <Spacer size={8} />
              {dayOfWeek === "月" ? (
                <FormHelp
                  label="最高売上"
                  help={
                    <>
                      過去3ヶ月の各曜日別
                      <br />
                      最高売上（税抜）です
                    </>
                  }
                />
              ) : (
                <span>最高売上</span>
              )}
              <Price>
                {averageAndMaxSales?.maxTaxExcludedSalesAmount ? (
                  <Price>{formatPrice(averageAndMaxSales.maxTaxExcludedSalesAmount)}</Price>
                ) : (
                  "-"
                )}
              </Price>
            </Bottom>
          </HeaderItem>
        );
      })}
    </Wrapper>
  );
});
