import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { DatePicker, Flex, Select } from "antd";
import dayjs from "dayjs";
import { PickerSharedProps } from "rc-picker/lib/Picker";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { Company } from "../type";

const StyledSelect = styled(Select<string>)`
  width: 256px;
`;

export type FilterConditions = {
  companyId?: string;
  targetDate?: dayjs.Dayjs;
};

export type SerializedFilterConditions = {
  targetDate?: number;
} & Omit<FilterConditions, "targetDate">;

type Props = {
  companies: Company[];
  companyIdDefaultValue?: string;
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  loading: boolean;
};

export const Filters = memo<Props>(
  ({ companies, companyIdDefaultValue, filterConditions, onChangeFilterCondition, loading }) => {
    const handleChangeCompany = useCallback(
      (value: string | null) => {
        if (value) onChangeFilterCondition({ companyId: value });
      },
      [onChangeFilterCondition],
    );

    const handleChangeTargetDate = useCallback<
      NonNullable<PickerSharedProps<dayjs.Dayjs>["onChange"]>
    >(
      (value: dayjs.Dayjs | null) => {
        if (value) onChangeFilterCondition({ targetDate: value });
      },
      [onChangeFilterCondition],
    );

    const options = useMemo(
      () =>
        companies.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      [companies],
    );

    return (
      <Flex gap={16}>
        <StyledSelect
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          value={filterConditions.companyId ?? companyIdDefaultValue}
          onChange={handleChangeCompany}
          options={options}
          loading={loading}
        />
        <DatePicker
          value={filterConditions.targetDate}
          onChange={handleChangeTargetDate}
          picker="month"
          placeholder="配信月を選択"
        />
      </Flex>
    );
  },
);
