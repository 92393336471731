import React, { memo } from "react";
import { taxRates } from "models/taxRate";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { Select } from "components/Input/Select";
import { CouponTypeEnum } from "types/graphql";

export const TaxRateField = memo(() => (
  <FormItem name={[CouponTypeEnum.MenuDiscount, "taxRate"]} label="税率">
    <Select
      showSearch
      options={Object.entries(taxRates).map(([taxRateKey, taxRate]) => ({
        label: taxRate,
        value: taxRateKey,
      }))}
      disabled
    />
  </FormItem>
));
