import { createFormItem, Form } from "components/antd/Form";

export type AutoTranslationFormValues = {
  enableAutoTranslation: boolean;
};

export const AutoTranslationFormItem = createFormItem<AutoTranslationFormValues>();

export const useAutoTranslationForm = ({
  enableAutoTranslation,
}: {
  enableAutoTranslation?: boolean;
}) => {
  const [form] = Form.useForm<AutoTranslationFormValues>();
  const initialValues = { enableAutoTranslation };

  return { form, initialValues };
};
