import React, { useCallback, useMemo } from "react";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { GmoOffPaymentMethodTypeEnum } from "types/graphql";

import { DetailTypeTable } from "./DetailTypeTable";
import {
  useGetGmoOffPaymentOnSitePaymentDetailTypesQuery,
  useGetOnSitePaymentDetailTypesQuery,
  useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation,
  useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation,
} from "./queries";

export const GmoTerminalOnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data,
    loading: loadingGmoOffPaymentOnSitePaymentDetailTypes,
    refetch: refetchGmoOffPaymentOnSitePaymentDetailTypes,
  } = useGetGmoOffPaymentOnSitePaymentDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const { data: onSitePaymentDetailTypesData, loading: loadingOnSitePaymentDetailTypesData } =
    useGetOnSitePaymentDetailTypesQuery(companyId ? { variables: { companyId } } : { skip: true });

  const [updateGmoOffPaymentOnSitePaymentDetailType] =
    useOnSitePaymentDetailTypesUpdateGmoOffOnSitePaymentDetailTypeMutation();

  const [deleteGmoOffPaymentOnSitePaymentDetailType] =
    useOnSitePaymentDetailTypesDeleteGmoOffPaymentOnSitePaymentDetailTypeMutation();

  const updateDetailType = useCallback(
    async ({
      gmoOffPaymentMethodType,
      onSitePaymentDetailTypeIds,
    }: {
      gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum;
      onSitePaymentDetailTypeIds: string[];
    }) => {
      if (!companyId) return;

      try {
        const objects = onSitePaymentDetailTypeIds.map((onSitePaymentDetailTypeId) => ({
          companyId,
          onSitePaymentDetailTypeId,
          gmoOffPaymentMethodType,
        }));

        await updateGmoOffPaymentOnSitePaymentDetailType({
          variables: {
            companyId,
            gmoOffPaymentMethodType,
            objects,
          },
        });

        refetchGmoOffPaymentOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      companyId,
      updateGmoOffPaymentOnSitePaymentDetailType,
      refetchGmoOffPaymentOnSitePaymentDetailTypes,
    ],
  );

  const deleteDetailType = useCallback(
    async (gmoOffPaymentMethodType: GmoOffPaymentMethodTypeEnum) => {
      if (!companyId) return;

      try {
        await deleteGmoOffPaymentOnSitePaymentDetailType({
          variables: { companyId, gmoOffPaymentMethodType },
        });

        refetchGmoOffPaymentOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      companyId,
      refetchGmoOffPaymentOnSitePaymentDetailTypes,
      deleteGmoOffPaymentOnSitePaymentDetailType,
    ],
  );

  const gmoOffPaymentOnSitePaymentDetailTypes = useMemo(
    () => data?.gmoOffPaymentOnSitePaymentDetailType ?? [],
    [data?.gmoOffPaymentOnSitePaymentDetailType],
  );

  const onSitePaymentDetailTypes = useMemo(
    () => onSitePaymentDetailTypesData?.onSitePaymentDetailType ?? [],
    [onSitePaymentDetailTypesData],
  );

  const loading =
    loadingGmoOffPaymentOnSitePaymentDetailTypes || loadingOnSitePaymentDetailTypesData;

  return (
    <DashboardLayout title="オフライン端末 支払方法設定">
      <PageHeader title="オフライン端末 支払方法設定" />
      <DetailTypeTable
        gmoOffPaymentOnSitePaymentDetailTypes={gmoOffPaymentOnSitePaymentDetailTypes}
        onSitePaymentDetailTypes={onSitePaymentDetailTypes}
        loading={loading}
        onUpdate={updateDetailType}
        onDelete={deleteDetailType}
      />
    </DashboardLayout>
  );
};
