import React, { memo, useMemo } from "react";
import { ColumnsType } from "antd/lib/table";
import { messageDeliveryAnalyticsProperties } from "models/messageDeliveryAnalytics";

import { TableContent } from "components/AnalyticsTable/TableContent";
import { TableErrorContent } from "components/AnalyticsTable/TableErrorContent";
import { TableHeaderTitle } from "components/AnalyticsTable/TableHeaderTitle";
import { TableStyleModifier } from "components/AnalyticsTable/TableStyleModifier";
import { Loading } from "components/Loading";
import { Table } from "components/Table";

import {
  MessageDeliveryAnalyticsSummaryPerShop,
  MessageDeliveryAnalyticsSummaryPerShopItem,
} from "../type";

type Props = {
  summaryPerShop: MessageDeliveryAnalyticsSummaryPerShop | undefined;
  isNotMessageOpenCountCollected: boolean;
};

type DataSource = MessageDeliveryAnalyticsSummaryPerShopItem & {
  key: number;
  isNotMessageOpenCountCollected: boolean;
};

const TABLE_MAX_HEIGHT = 407;

const formatter = new Intl.NumberFormat("ja");

const {
  messageSentCount,
  messageOpenCount,
  visitedCustomersCount,
  visitedTableUserCustomersCount,
  visitedTableUserCustomersSales,
} = messageDeliveryAnalyticsProperties;

const columns: ColumnsType<DataSource> = [
  {
    title: "店舗名",
    fixed: "left",
    dataIndex: "shopName",
    align: "left",
    render(value: string) {
      return <TableContent content={value} />;
    },
  },
  {
    title: <TableHeaderTitle icon={messageSentCount.iconSrc} title={messageSentCount.label} />,
    align: "right",
    dataIndex: "messageSentCount",
    width: 128,
    render(value: number) {
      return <TableContent content={String(value)} subContent="100%" />;
    },
    sorter: (a, b) => a.messageSentCount - b.messageSentCount,
  },
  {
    title: <TableHeaderTitle icon={messageOpenCount.iconSrc} title={messageOpenCount.label} />,
    align: "right",
    dataIndex: "messageOpenCount",
    width: 128,
    render(
      value: MessageDeliveryAnalyticsSummaryPerShopItem["messageOpenCount"],
      { messageSentCount, isNotMessageOpenCountCollected },
    ) {
      if (isNotMessageOpenCountCollected || !value) {
        return (
          <TableErrorContent
            message={
              isNotMessageOpenCountCollected
                ? "2023年2月以前のデータは表示されません"
                : "開封数が20未満の場合、表示されません"
            }
          />
        );
      }

      return (
        <TableContent
          content={String(value)}
          subContent={`${
            messageSentCount === 0 ? 0 : Math.round((value / messageSentCount) * 100 * 10) / 10
          }%`}
        />
      );
    },
    sorter: (a, b) => (a.messageOpenCount ?? 0) - (b.messageOpenCount ?? 0),
  },
  {
    title: (
      <TableHeaderTitle icon={visitedCustomersCount.iconSrc} title={visitedCustomersCount.label} />
    ),
    align: "right",
    dataIndex: "visitedCustomersCount",
    width: 128,
    render(value: number, { messageSentCount }) {
      return (
        <TableContent
          content={String(value)}
          subContent={`${
            messageSentCount === 0 ? 0 : Math.round((value / messageSentCount) * 100 * 10) / 10
          }%`}
        />
      );
    },
    sorter: (a, b) => a.visitedCustomersCount - b.visitedCustomersCount,
  },
  {
    title: (
      <TableHeaderTitle
        icon={visitedTableUserCustomersCount.iconSrc}
        title={visitedTableUserCustomersCount.label}
      />
    ),
    align: "right",
    dataIndex: "visitedTableUserCustomersCount",
    width: 158,
    render(value: number) {
      return <TableContent content={String(value)} />;
    },
    sorter: (a, b) => a.visitedTableUserCustomersCount - b.visitedTableUserCustomersCount,
  },
  {
    title: visitedTableUserCustomersSales.label,
    fixed: "right",
    align: "right",
    dataIndex: "visitedTableUserCustomersSales",
    width: 158,
    render(value: number) {
      return <TableContent content={`¥${formatter.format(value)}`} isHighlight />;
    },
    sorter: (a, b) => a.visitedTableUserCustomersSales - b.visitedTableUserCustomersSales,
  },
];

export const SummaryPerShopTable = memo<Props>(
  ({ summaryPerShop, isNotMessageOpenCountCollected }) => {
    const dataSource = useMemo<DataSource[]>(
      () =>
        !summaryPerShop
          ? []
          : summaryPerShop.items.map((summaryPerShopItem, i) => ({
              key: i,
              isNotMessageOpenCountCollected,
              ...summaryPerShopItem,
            })),
      [isNotMessageOpenCountCollected, summaryPerShop],
    );

    if (!summaryPerShop) return <Loading height={TABLE_MAX_HEIGHT} />;

    return (
      <TableStyleModifier>
        <Table
          columns={columns}
          dataSource={dataSource}
          bordered
          pagination={false}
          scroll={{ y: TABLE_MAX_HEIGHT, x: 1136 }}
        />
      </TableStyleModifier>
    );
  },
);
