import React, { useCallback, useMemo } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Alert, Button, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { saveAs } from "file-saver";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useDinii } from "hooks/useDinii";
import { useLocationState } from "hooks/useLocationState";
import { useShop } from "hooks/useShop";

import { AdyenTerminalPaymentHistoryTable } from "./AdyenTerminalPaymentHistoryTable";
import { PermittedShopSelector } from "./PermittedShopSelector";
import {
  AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery,
  useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery,
} from "./queries";

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export type PaymentHistoryType = NonNullable<
  AdyenTerminalPaymentHistoryGetAuthorizedEventsQuery["adyenPaymentReportTerminalPaymentAuthorizedEvent"]
>[number];

export const AdyenTerminalPaymentHistory = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [dinii, getContext] = useDinii();

  const [{ targetDateString }, setTargetDateString] = useLocationState<{
    targetDateString: string | null;
  }>("targetDateString", { targetDateString: dayjs().subtract(1, "days").format("YYYY-MM-DD") });
  const targetDate = useMemo(
    () => (targetDateString ? dayjs(targetDateString) : undefined),
    [targetDateString],
  );

  const { data, loading, error } = useAdyenTerminalPaymentHistoryGetAuthorizedEventsQuery(
    shopId && targetDateString
      ? {
          variables: {
            shopId,
            since: dayjs(targetDateString).startOf("day").toISOString(),
            until: dayjs(targetDateString).endOf("day").toISOString(),
          },
        }
      : { skip: true },
  );

  const paymentHistories: PaymentHistoryType[] = useMemo(
    () => data?.adyenPaymentReportTerminalPaymentAuthorizedEvent ?? [],
    [data?.adyenPaymentReportTerminalPaymentAuthorizedEvent],
  );

  const handleChangeDate = useCallback(
    (_: dayjs.Dayjs | null, targetDateString: string) => {
      setTargetDateString({ targetDateString });
    },
    [setTargetDateString],
  );

  const [{ loading: isDownloading }, submit] = useAsyncFn(async () => {
    const context = await getContext();

    if (!shopId || !targetDate || !context) {
      return;
    }

    try {
      const { data } = await dinii.aggregatedData.adyenTerminalPayment.download(context, {
        shopId,
        targetDate: targetDate.format("YYYYMMDD"),
      });
      saveAs(data, `${shopId}_${targetDate.format("YYYYMMDD")}_terminal_payment.zip`);

      message.success("ダウンロードが完了しました");
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [getContext, shopId, targetDate, dinii.aggregatedData.adyenTerminalPayment]);

  const disabled = paymentHistories.length === 0;

  return (
    <DashboardLayout title="決済履歴一覧">
      <PageHeader
        title="決済履歴一覧"
        footer={
          <HeaderContainer>
            <SelectorContainer>
              <PermittedShopSelector />
              <DatePicker
                defaultValue={targetDate}
                value={targetDate}
                onChange={handleChangeDate}
              />
            </SelectorContainer>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              disabled={disabled}
              loading={isDownloading}
              onClick={submit}
            >
              ダウンロード
            </Button>
          </HeaderContainer>
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AdyenTerminalPaymentHistoryTable loading={loading} paymentHistories={paymentHistories} />
    </DashboardLayout>
  );
};
