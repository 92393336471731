import React, { memo, useMemo } from "react";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { OnSitePaymentDiscountType } from "../types";

type Props = {
  loading?: boolean;
  discountTypes: OnSitePaymentDiscountType[];
};

export const DiscountTypeTable = memo<Props>(({ loading, discountTypes }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<OnSitePaymentDiscountType> = useMemo(
    () => [
      {
        title: "割引方法",
        width: 150,
        fixed: "left",
        render(_: string, { label }: OnSitePaymentDiscountType) {
          return label;
        },
      },
      {
        title: "ぴかいち値割引区分",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDiscountTypes }: OnSitePaymentDiscountType) {
          const pikaichiOnSitePaymentDiscountType = pikaichiOnSitePaymentDiscountTypes[0];

          return pikaichiOnSitePaymentDiscountType
            ? pikaichiOnSitePaymentDiscountType.newariKubun
            : "";
        },
      },
      {
        title: "ぴかいち値割引コード",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDiscountTypes }: OnSitePaymentDiscountType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDiscountTypes[0];

          return pikaichiOnSitePaymentDetailType ? pikaichiOnSitePaymentDetailType.newariCd : "";
        },
      },
      {
        title: "ぴかいち値割引名称",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDiscountTypes }: OnSitePaymentDiscountType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDiscountTypes[0];

          return pikaichiOnSitePaymentDetailType ? pikaichiOnSitePaymentDetailType.newariName : "";
        },
      },
      ...(canAccess("editPikaichiOnSitePaymentDiscountType")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, { type }: OnSitePaymentDiscountType) {
                return (
                  <Tooltip title="メニューを編集">
                    <IconLink to={`/pikaichi/onSitePaymentDiscountType/${encodeURI(type)}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  return (
    <Table
      columns={columns}
      dataSource={discountTypes}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
