import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DishUpGroupsGetDishUpGroups = gql`
    query DishUpGroupsGetDishUpGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    dishUpSlipGroups(order_by: {serial: asc}) {
      id: serial
      name
    }
  }
}
    `;
export type DishUpGroupsGetDishUpGroupsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type DishUpGroupsGetDishUpGroupsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { dishUpSlipGroups: Array<(
      { __typename?: 'dishUpSlipGroup' }
      & Pick<Types.DishUpSlipGroup, 'name'>
      & { id: Types.DishUpSlipGroup['serial'] }
    )> }
  )> }
);


export const DishUpGroupsGetDishUpGroupsDocument = gql`
    query DishUpGroupsGetDishUpGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    dishUpSlipGroups(order_by: {serial: asc}) {
      id: serial
      name
    }
  }
}
    `;

/**
 * __useDishUpGroupsGetDishUpGroupsQuery__
 *
 * To run a query within a React component, call `useDishUpGroupsGetDishUpGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishUpGroupsGetDishUpGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishUpGroupsGetDishUpGroupsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useDishUpGroupsGetDishUpGroupsQuery(baseOptions: Apollo.QueryHookOptions<DishUpGroupsGetDishUpGroupsQuery, DishUpGroupsGetDishUpGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DishUpGroupsGetDishUpGroupsQuery, DishUpGroupsGetDishUpGroupsQueryVariables>(DishUpGroupsGetDishUpGroupsDocument, options);
      }
export function useDishUpGroupsGetDishUpGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DishUpGroupsGetDishUpGroupsQuery, DishUpGroupsGetDishUpGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DishUpGroupsGetDishUpGroupsQuery, DishUpGroupsGetDishUpGroupsQueryVariables>(DishUpGroupsGetDishUpGroupsDocument, options);
        }
export type DishUpGroupsGetDishUpGroupsQueryHookResult = ReturnType<typeof useDishUpGroupsGetDishUpGroupsQuery>;
export type DishUpGroupsGetDishUpGroupsLazyQueryHookResult = ReturnType<typeof useDishUpGroupsGetDishUpGroupsLazyQuery>;
export type DishUpGroupsGetDishUpGroupsQueryResult = Apollo.QueryResult<DishUpGroupsGetDishUpGroupsQuery, DishUpGroupsGetDishUpGroupsQueryVariables>;