import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditDashboardAccountRoleFormItem } from "../useEditDashboardAccountRoleForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditDashboardAccountRoleFormItem
    label="権限名"
    name="name"
    rules={[{ required: true, message: "権限名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditDashboardAccountRoleFormItem>
));
