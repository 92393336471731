import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import { parseInteger } from "util/input/parseInteger";

import { withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";
import { EditMenuFormItem } from "pages/EditMenu/EditMenuForm/useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

const parseCount = (value: string | undefined) =>
  parseInteger(value, (value) => value.replace("個", ""));

export const OrderLimitCountPerTableUserField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ hasOrderLimitPerTableUser }) => [
      hasOrderLimitPerTableUser,
    ])}
    noStyle
    {...props}
  >
    {({ getFieldValue }) => {
      const hasOrderLimitPerTableUser: boolean = getFieldValue("hasOrderLimitPerTableUser");

      return (
        <EditMenuFormItem
          name="orderMaxNumPerTableUser"
          rules={
            hasOrderLimitPerTableUser ? [{ required: true, message: "個数を入力してください" }] : []
          }
        >
          <InputNumber
            formatter={(value) => `${value}個`}
            parser={parseCount}
            min={1}
            disabled={!hasOrderLimitPerTableUser || disabled}
          />
        </EditMenuFormItem>
      );
    }}
  </EditMenuFormItem.NonProperty>
));
