import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/AddCookingItem/AddCookingItemForm/NameField";
import { RoleField } from "pages/AddCookingItem/AddCookingItemForm/RoleField";
import {
  AddCookingItemFormValues,
  useAddCookingItemForm,
} from "pages/AddCookingItem/AddCookingItemForm/useAddCookingItemForm";
import { Role } from "pages/AddCookingItem/types";

type Props = {
  roles: Role[];
  onSubmit: (cookingItemFormValues: AddCookingItemFormValues) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddCookingItemForm = memo<Props>(
  ({ roles, onSubmit, onClose: handleClose, loading }) => {
    const { form, initialValues, submit: handleSubmit } = useAddCookingItemForm(onSubmit);

    return (
      <>
        <FormContent>
          <Form name="store" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <RoleField roles={roles} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
