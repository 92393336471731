import { useCallback, useState } from "react";
import { isNumber } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { CreateOptionInputOptionSource, MenuTypeEnum, OptionInputTypeEnum } from "types/graphql";

import { Option } from "./types";

const getInitialValues = () => ({
  inputType: OptionInputTypeEnum.Select,
  choices: [{ isDefaultSelection: false }],
});

export type AddOptionFormValues = {
  name: string;
  receiptDisplayName: string;
  minChoiceNum?: number | null;
  maxChoiceNum?: number | null;
  inputType: OptionInputTypeEnum;
  choices: {
    name: string;
    receiptDisplayName: string;
    price: number;
    costPrice?: number | null;
    costTaxRate?: number | null;
    isDefaultSelection: boolean;
    menuType?: MenuTypeEnum | null;
    imageUrl?: string | null;
    nameSources: LocaleValuePair[];
  }[];
  nameSources: LocaleValuePair[];
};

export const AddOptionFormItem = createFormItem<AddOptionFormValues>();

export const useAddOptionForm = ({
  companyId,
  onSubmit,
}: {
  companyId?: string;
  onSubmit: (option: CreateOptionInputOptionSource) => void;
}) => {
  const [form] = Form.useForm<AddOptionFormValues>();

  const initialValues = getInitialValues();

  const [isEnabledMaxChoiceNum, setIsEnabledMaxChoiceNum] = useState(false);
  const [isEnabledMinChoiceNum, setIsEnabledMinChoiceNum] = useState(false);

  const toggleMaxChoiceNum = useCallback(() => {
    const isEnabledMaxChoiceNum = isNumber(form.getFieldsValue().maxChoiceNum);
    const choicesLength = form.getFieldsValue().choices?.length ?? 1;

    form.setFields([{ name: "maxChoiceNum", value: isEnabledMaxChoiceNum ? null : choicesLength }]);
    setIsEnabledMaxChoiceNum(!isEnabledMaxChoiceNum);
  }, [form]);
  const toggleMinChoiceNum = useCallback(() => {
    const isEnabledMinChoiceNum = isNumber(form.getFieldsValue().minChoiceNum);

    form.setFields([{ name: "minChoiceNum", value: isEnabledMinChoiceNum ? null : 1 }]);
    setIsEnabledMinChoiceNum(!isEnabledMinChoiceNum);
  }, [form]);

  const submit = useCallback(async () => {
    if (!companyId) return;
    await form.validateFields();
    const { name, receiptDisplayName, minChoiceNum, maxChoiceNum, choices, nameSources } =
      form.getFieldsValue();

    onSubmit({
      name,
      receiptDisplayName,
      companyId,
      minChoiceNum: minChoiceNum ?? 0,
      maxChoiceNum: maxChoiceNum ? maxChoiceNum : null,
      choices: choices.map((choice, index) => ({
        name: choice.name,
        price: choice.price,
        priority: index,
        receiptDisplayName: choice.receiptDisplayName,
        costPrice: choice.costPrice,
        costTaxRate: choice.costTaxRate,
        isDefaultSelection: choice.isDefaultSelection ?? false,
        menuType: choice.menuType,
        imageUrl: choice.imageUrl,
        nameSources: (choice.nameSources ?? []).filter(({ value }) => value),
      })),
      createTranslationSource: {
        nameSources: (nameSources ?? []).filter(({ value }) => value),
      },
    });
  }, [companyId, form, onSubmit]);

  const copyFromAnotherOption = useCallback(
    (option: Option) => {
      const copiedChoiceList = option.choices.map((choice) => ({
        name: choice.name,
        receiptDisplayName: choice.receiptDisplayName,
        price: choice.price,
        costPrice: choice.costPrice,
        costTaxRate: choice.costTaxRate,
        isDefaultSelection: choice.isDefaultSelection ?? false,
        menuType: choice.menuType,
        imageUrl: choice.imageUrl,
      }));

      form.setFieldsValue({ ...option, choices: copiedChoiceList });

      setIsEnabledMaxChoiceNum(isNumber(option.maxChoiceNum));
      setIsEnabledMinChoiceNum(isNumber(option.minChoiceNum));
    },
    [form],
  );

  return {
    initialValues,
    submit,
    form,
    toggleMaxChoiceNum,
    toggleMinChoiceNum,
    copyFromAnotherOption,
    isEnabledMaxChoiceNum,
    isEnabledMinChoiceNum,
  };
};
