import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaire = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswer = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswer($questionnaireId: uuid!, $shopId: uuid!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_eq: $shopId}, visitedAt: {_lte: $endAt, _gte: $startAt}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategory = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategory($input: QuestionnaireMetricsPerCategoryInput!) {
  questionnaireMetricsPerCategory(input: $input) {
    scoreMetrics {
      shopAverageScore {
        score
        fluctuation
      }
      corporationAverageScore
    }
    scoreDistributionMetrics {
      distributionItems {
        count
        label
      }
    }
    questionAnswerMetrics {
      ... on QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics {
        title
        answers {
          score
          title
          detail
          attribute
          customerQuestionnaireId
        }
      }
      ... on QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics {
        title
        distributionItems {
          count
          label
        }
      }
    }
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetShops = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id'>
  )> }
);

export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
  startAt: Types.Scalars['timestamptz'];
  endAt: Types.Scalars['timestamptz'];
}>;


export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery = (
  { __typename?: 'query_root' }
  & { questionnaireAnswer: Array<(
    { __typename?: 'questionnaireAnswer' }
    & Pick<Types.QuestionnaireAnswer, 'id' | 'shopId'>
  )> }
);

export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables = Types.Exact<{
  input: Types.QuestionnaireMetricsPerCategoryInput;
}>;


export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery = (
  { __typename?: 'query_root' }
  & { questionnaireMetricsPerCategory: (
    { __typename?: 'QuestionnaireMetricsPerCategoryOutput' }
    & { scoreMetrics?: Types.Maybe<(
      { __typename?: 'QuestionnaireMetricsPerCategoryScoreMetrics' }
      & Pick<Types.QuestionnaireMetricsPerCategoryScoreMetrics, 'corporationAverageScore'>
      & { shopAverageScore: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'score' | 'fluctuation'>
      ) }
    )>, scoreDistributionMetrics?: Types.Maybe<(
      { __typename?: 'QuestionnaireMetricsPerCategoryScoreDistributionMetrics' }
      & { distributionItems: Array<(
        { __typename?: 'DistributionItem' }
        & Pick<Types.DistributionItem, 'count' | 'label'>
      )> }
    )>, questionAnswerMetrics: Array<(
      { __typename?: 'QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics' }
      & Pick<Types.QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics, 'title'>
      & { distributionItems: Array<(
        { __typename?: 'DistributionItem' }
        & Pick<Types.DistributionItem, 'count' | 'label'>
      )> }
    ) | (
      { __typename?: 'QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics' }
      & Pick<Types.QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics, 'title'>
      & { answers: Array<(
        { __typename?: 'QuestionnaireMetricsPerCategoryTextTypeAnswer' }
        & Pick<Types.QuestionnaireMetricsPerCategoryTextTypeAnswer, 'score' | 'title' | 'detail' | 'attribute' | 'customerQuestionnaireId'>
      )> }
    )> }
  ) }
);

export type QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  questionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);


export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerDocument = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswer($questionnaireId: uuid!, $shopId: uuid!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_eq: $shopId}, visitedAt: {_lte: $endAt, _gte: $startAt}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      shopId: // value for 'shopId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireAnswerQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryDocument = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategory($input: QuestionnaireMetricsPerCategoryInput!) {
  questionnaireMetricsPerCategory(input: $input) {
    scoreMetrics {
      shopAverageScore {
        score
        fluctuation
      }
      corporationAverageScore
    }
    scoreDistributionMetrics {
      distributionItems {
        count
        label
      }
    }
    questionAnswerMetrics {
      ... on QuestionnaireMetricsPerCategoryTextTypeAnswerMetrics {
        title
        answers {
          score
          title
          detail
          attribute
          customerQuestionnaireId
        }
      }
      ... on QuestionnaireMetricsPerCategoryDistributionTypeAnswerMetrics {
        title
        distributionItems {
          count
          label
        }
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetQuestionnaireMetricsPerCategoryQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsDocument = gql`
    query QuestionnaireAnalyticsShopMetricsPerCategoryGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables>(QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsPerCategoryGetShopsLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQuery, QuestionnaireAnalyticsShopMetricsPerCategoryGetShopsQueryVariables>;