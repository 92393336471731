import React, { memo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";

import { Company } from "../types";

type Props = {
  loading?: boolean;
  companies: Company[];
};

const columns = [
  { title: "業態名", dataIndex: "name" },
  {
    title: "",
    align: "center",
    width: 60,
    fixed: "right",
    render(_: string, { companyId }: Company) {
      return (
        <IconLink to={`/company/${companyId}/edit`}>
          <EditIcon />
        </IconLink>
      );
    },
  } as const,
];

export const CompanyTable = memo<Props>(({ loading, companies }) => (
  <Table
    rowKey="id"
    columns={columns}
    dataSource={companies}
    loading={loading}
    bordered
    pagination={false}
  />
));
