import React, { memo } from "react";
import dayjs from "dayjs";

import { FormItemProps } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  deliverDateName: string;
};

export const TimeField = memo<Props>((props) => (
  <MessageDeliveryJobFormItem.NonProperty noStyle shouldUpdate>
    {({ getFieldValue }) => (
      <MessageDeliveryJobFormItem
        label="配信時刻"
        name="deliverTime"
        rules={[
          { required: true, message: "配信時刻を入力してください" },
          {
            validator: async (_, value) => {
              if (!value) return;

              const currentDeliverDate = getFieldValue(props.deliverDateName);
              if (!currentDeliverDate) return;

              const now = dayjs();
              const deliverDate = dayjs(currentDeliverDate);
              const deliverTime = dayjs(value);
              const deliveryAt = deliverDate.hour(deliverTime.hour()).minute(deliverTime.minute());

              if (now.isAfter(deliveryAt)) {
                throw new Error("過去の日時は設定できません");
              }
            },
          },
        ]}
        getValueFromEvent={(e: dayjs.Dayjs | null) => {
          if (!e) {
            return null;
          }
          const now = dayjs();
          const deliveryAt = now.hour(e.hour()).minute(e.minute());
          // 現在時刻を押したとき
          const acceptableError = 10 * 60 * 1000; //現在時刻と10分未満の誤差だったら現在時刻を押したと判断する
          if (now.diff(deliveryAt) < acceptableError && now.diff(deliveryAt) > 0) {
            return deliveryAt.add(10, "m");
          }
          return e;
        }}
        {...props}
      >
        <TimePicker formItemName="deliverTime" showNow={true} minuteStep={10} inputReadOnly />
      </MessageDeliveryJobFormItem>
    )}
  </MessageDeliveryJobFormItem.NonProperty>
));
