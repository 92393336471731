import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { AddPlanChoiceModal } from "pages/PlanOptions/AddPlanChoiceModal";
import { AddPlanOptionModal } from "pages/PlanOptions/AddPlanOptionModal";
import { EditPlanChoiceModal } from "pages/PlanOptions/EditPlanChoiceModal";
import { EditPlanOptionModal } from "pages/PlanOptions/EditPlanOptionModal";
import { PlanOptionTable } from "pages/PlanOptions/PlanOptionTable";
import {
  usePlanOptionsDeletePlanChoiceMutation,
  usePlanOptionsDeletePlanOptionMutation,
  usePlanOptionsGetPlanQuery,
  usePlanOptionsInsertPlanChoicesMutation,
  usePlanOptionsInsertPlanOptionsMutation,
} from "pages/PlanOptions/queries";
import { PlanChoiceInsertInput, PlanOptionInsertInput } from "types/graphql";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const PlanOptions = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanOptionData,
    refetch: refetchPlan,
    loading: loadingPlan,
    error,
  } = usePlanOptionsGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanOptionData?.plan?.[0];

  setCompany(plan?.companyId);

  const planOptions = plan?.planOptions;

  const [insertPlanOptionsMutation, { loading: loadingInsertingPlanOptions }] =
    usePlanOptionsInsertPlanOptionsMutation();

  const onPlanOptionsUpdate = useCallback(
    async (planOptions: PlanOptionInsertInput[]) => {
      try {
        await insertPlanOptionsMutation({
          variables: { planOptions },
        });

        await refetchPlan();

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [insertPlanOptionsMutation, refetchPlan],
  );

  const [deletePlanOptionMutation] = usePlanOptionsDeletePlanOptionMutation();

  const onDeleteOption = useCallback(
    async ({ planOptionId }: { planOptionId: number }) => {
      try {
        await deletePlanOptionMutation({
          variables: { planOptionId, archivedAt: new Date().toISOString() },
        });

        await refetchPlan();
        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
    },
    [deletePlanOptionMutation, refetchPlan],
  );

  const [insertPlanChoicesMutation, { loading: loadingInsertingPlanChoices }] =
    usePlanOptionsInsertPlanChoicesMutation();

  const onPlanChoicesUpdate = useCallback(
    async (planChoices: PlanChoiceInsertInput[]) => {
      try {
        await insertPlanChoicesMutation({
          variables: { planChoices },
        });

        await refetchPlan();

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [refetchPlan, insertPlanChoicesMutation],
  );

  const [deletePlanChoiceMutation] = usePlanOptionsDeletePlanChoiceMutation();

  const onDeleteChoice = useCallback(
    async ({ planChoiceId }: { planChoiceId: number }) => {
      const shopPlanChoiceIds =
        planOptions
          ?.flatMap(({ planChoices }) => planChoices)
          .filter((planChoice) => planChoice.planChoiceId === planChoiceId)
          .flatMap(({ shopPlanChoices }) => shopPlanChoices.map(({ id }) => id)) ?? [];

      try {
        await deletePlanChoiceMutation({
          variables: { planChoiceId, archivedAt: new Date().toISOString(), shopPlanChoiceIds },
        });

        await refetchPlan();
        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
    },
    [deletePlanChoiceMutation, planOptions, refetchPlan],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToPlanOption = useCallback(() => {
    navigate(`/plan/${planId}/option`, { replace: true });
  }, [navigate, planId]);

  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");
  const isAddPlanOptionFeatureEnabled = isFeatureEnabled("addPlanOption");
  const canAddPlanOption = isAddPlanOptionFeatureEnabled && canEditAllShopPlans;

  return (
    <DashboardLayout title={plan?.planName}>
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loadingPlan && <Loading height={300} />}
      {plan && planOptions && (
        <FormContent>
          {canAddPlanOption && (
            <ButtonWrapper>
              <Link key="addOption" to={`/plan/${planId}/option/add`} replace>
                <Button type="primary">新規作成</Button>
              </Link>
            </ButtonWrapper>
          )}
          <PlanOptionTable
            planId={plan.planId}
            planOptions={planOptions}
            onPlanOptionsUpdate={onPlanOptionsUpdate}
            onPlanChoicesUpdate={onPlanChoicesUpdate}
            onDeleteOption={onDeleteOption}
            onDeleteChoice={onDeleteChoice}
            loading={loadingInsertingPlanOptions || loadingInsertingPlanChoices}
            canEditPlan={isFeatureEnabled("editPlan") && canEditAllShopPlans}
            canDeletePlanOption={isFeatureEnabled("deletePlanOption") && canEditAllShopPlans}
            canAddPlanChoice={isFeatureEnabled("addPlanChoice") && canEditAllShopPlans}
            canEditPlanChoice={isFeatureEnabled("editPlanChoice") && canEditAllShopPlans}
            canDeletePlanChoice={isFeatureEnabled("deletePlanChoice") && canEditAllShopPlans}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddPlanOptionModal
                  planId={plan.id}
                  _planId={plan.planId}
                  goBack={goBackToPlanOption}
                  onUpdated={refetchPlan}
                />
              }
            />
            <Route
              path=":planOptionId/choice/add"
              element={
                <AddPlanChoiceModal
                  planId={plan.planId}
                  goBack={goBackToPlanOption}
                  onUpdated={refetchPlan}
                />
              }
            />
            <Route
              path=":planOptionId/edit"
              element={
                <EditPlanOptionModal
                  goBack={goBackToPlanOption}
                  onUpdated={refetchPlan}
                  canEditPlanOption={isFeatureEnabled("editPlanOption") && canEditAllShopPlans}
                  canEditPlanChoice={isFeatureEnabled("editPlanChoice") && canEditAllShopPlans}
                />
              }
            />
            <Route
              path=":planOptionId/choice/:planChoiceId/edit"
              element={
                <EditPlanChoiceModal
                  goBack={goBackToPlanOption}
                  onUpdated={refetchPlan}
                  canEditPlanChoice={isFeatureEnabled("editPlanChoice") && canEditAllShopPlans}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
