import { useCallback, useEffect, useRef } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { MembershipCardAppearanceTypeEnum } from "types/graphql";

type EditMembershipCardAppearanceTypeFormValues = {
  appearanceType?: MembershipCardAppearanceTypeEnum;
};

export type OnSubmitEditMembershipCardAppearanceTypeForm = (
  formValue: EditMembershipCardAppearanceTypeFormValues,
) => void;

type Props = {
  loading: boolean;
  initialData: EditMembershipCardAppearanceTypeFormValues | null;
  onSubmit: OnSubmitEditMembershipCardAppearanceTypeForm;
};

export const EditMembershipCardAppearanceTypeFormItem =
  createFormItem<EditMembershipCardAppearanceTypeFormValues>();

export const useEditMembershipCardAppearanceTypeForm = ({
  initialData,
  loading,
  onSubmit,
}: Props) => {
  const [form] = Form.useForm<EditMembershipCardAppearanceTypeFormValues>();
  const initialValueRef = useRef<EditMembershipCardAppearanceTypeFormValues | null>(null);

  useEffect(() => {
    if (!initialValueRef.current && !loading) {
      if (initialData) {
        initialValueRef.current = { ...initialData };
        form.setFieldsValue({ ...initialValueRef.current });
      } else {
        initialValueRef.current = {};
      }
    }
  }, [loading, initialData, form]);

  const submit = useCallback(() => {
    const formValue = form.getFieldsValue();
    onSubmit(formValue);
  }, [form, onSubmit]);

  const resetForm = useCallback(() => {
    form.setFieldsValue(initialValueRef.current ? { ...initialValueRef.current } : {});
  }, [form]);

  const disabled = loading;

  return { form, disabled, submit, resetForm };
};
