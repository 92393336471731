import React, { memo } from "react";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

type Props = Omit<FormItemProps, "children">;

export const FtpPortField = memo<Props>((props) => (
  <FormItem label="FTPポート" name="ftpPort" rules={[{ required: true }]} required {...props}>
    <InputNumber fullWidth />
  </FormItem>
));
