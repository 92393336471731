import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditDetailTypeFormItem } from "../useEditDetailTypeForm";

const { Option } = Select;

type Props = { mediaMaps: { aggregationNumber: number; mediaName: string }[] } & Omit<
  FormItemProps,
  "children" | "name"
>;

export const MediaMapNameField = memo<Props>(({ mediaMaps, ...props }) => (
  <EditDetailTypeFormItem
    label="集計種別"
    name="aggregationNumber"
    rules={[{ required: true, message: "集計種別を設定してください" }]}
    {...props}
  >
    <Select<string>>
      {mediaMaps.map(({ aggregationNumber, mediaName }) => (
        <Option key={aggregationNumber} value={aggregationNumber}>
          {mediaName}
        </Option>
      ))}
    </Select>
  </EditDetailTypeFormItem>
));
