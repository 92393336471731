import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionTranslationsGetOption = gql`
    query EditOptionTranslationsGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    optionId
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "option"}}) {
      columnName
      locale
      value
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      id
      name
      translations(where: {tableName: {_eq: "choice"}}) {
        columnName
        locale
        value
        recordId
      }
    }
  }
}
    `;
export const EditOptionTranslationsUpdateOptionTranslations = gql`
    mutation EditOptionTranslationsUpdateOptionTranslations($input: UpsertTranslationsForOptionInput!) {
  upsertTranslationsForOption(input: $input)
}
    `;
export type EditOptionTranslationsGetOptionQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionTranslationsGetOptionQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'id' | 'optionId' | 'name'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )>, choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'id' | 'name'>
      & { translations: Array<(
        { __typename?: 'translation' }
        & Pick<Types.Translation, 'columnName' | 'locale' | 'value' | 'recordId'>
      )> }
    )> }
  )> }
);

export type EditOptionTranslationsUpdateOptionTranslationsMutationVariables = Types.Exact<{
  input: Types.UpsertTranslationsForOptionInput;
}>;


export type EditOptionTranslationsUpdateOptionTranslationsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForOption'>
);


export const EditOptionTranslationsGetOptionDocument = gql`
    query EditOptionTranslationsGetOption($optionId: Int!) {
  option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    optionId
    name
    company {
      id
    }
    translations(where: {tableName: {_eq: "option"}}) {
      columnName
      locale
      value
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      id
      name
      translations(where: {tableName: {_eq: "choice"}}) {
        columnName
        locale
        value
        recordId
      }
    }
  }
}
    `;

/**
 * __useEditOptionTranslationsGetOptionQuery__
 *
 * To run a query within a React component, call `useEditOptionTranslationsGetOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionTranslationsGetOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionTranslationsGetOptionQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionTranslationsGetOptionQuery(baseOptions: Apollo.QueryHookOptions<EditOptionTranslationsGetOptionQuery, EditOptionTranslationsGetOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionTranslationsGetOptionQuery, EditOptionTranslationsGetOptionQueryVariables>(EditOptionTranslationsGetOptionDocument, options);
      }
export function useEditOptionTranslationsGetOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionTranslationsGetOptionQuery, EditOptionTranslationsGetOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionTranslationsGetOptionQuery, EditOptionTranslationsGetOptionQueryVariables>(EditOptionTranslationsGetOptionDocument, options);
        }
export type EditOptionTranslationsGetOptionQueryHookResult = ReturnType<typeof useEditOptionTranslationsGetOptionQuery>;
export type EditOptionTranslationsGetOptionLazyQueryHookResult = ReturnType<typeof useEditOptionTranslationsGetOptionLazyQuery>;
export type EditOptionTranslationsGetOptionQueryResult = Apollo.QueryResult<EditOptionTranslationsGetOptionQuery, EditOptionTranslationsGetOptionQueryVariables>;
export const EditOptionTranslationsUpdateOptionTranslationsDocument = gql`
    mutation EditOptionTranslationsUpdateOptionTranslations($input: UpsertTranslationsForOptionInput!) {
  upsertTranslationsForOption(input: $input)
}
    `;
export type EditOptionTranslationsUpdateOptionTranslationsMutationFn = Apollo.MutationFunction<EditOptionTranslationsUpdateOptionTranslationsMutation, EditOptionTranslationsUpdateOptionTranslationsMutationVariables>;

/**
 * __useEditOptionTranslationsUpdateOptionTranslationsMutation__
 *
 * To run a mutation, you first call `useEditOptionTranslationsUpdateOptionTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionTranslationsUpdateOptionTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionTranslationsUpdateOptionTranslationsMutation, { data, loading, error }] = useEditOptionTranslationsUpdateOptionTranslationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOptionTranslationsUpdateOptionTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionTranslationsUpdateOptionTranslationsMutation, EditOptionTranslationsUpdateOptionTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionTranslationsUpdateOptionTranslationsMutation, EditOptionTranslationsUpdateOptionTranslationsMutationVariables>(EditOptionTranslationsUpdateOptionTranslationsDocument, options);
      }
export type EditOptionTranslationsUpdateOptionTranslationsMutationHookResult = ReturnType<typeof useEditOptionTranslationsUpdateOptionTranslationsMutation>;
export type EditOptionTranslationsUpdateOptionTranslationsMutationResult = Apollo.MutationResult<EditOptionTranslationsUpdateOptionTranslationsMutation>;
export type EditOptionTranslationsUpdateOptionTranslationsMutationOptions = Apollo.BaseMutationOptions<EditOptionTranslationsUpdateOptionTranslationsMutation, EditOptionTranslationsUpdateOptionTranslationsMutationVariables>;