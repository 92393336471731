import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { placeholder } from "../placeholder";
import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ContentField = memo<Props>((props) => (
  <AddCouponFormItem
    label="クーポン内容"
    name="content"
    rules={[{ required: true, message: "クーポン内容を入力してください" }]}
    {...props}
  >
    <Input placeholder={placeholder.content} />
  </AddCouponFormItem>
));
