import React, { memo } from "react";
import { InputNumber } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditEbicaConfigFormItem } from "../useEditEbicaConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const WalkInStayTimeInMinuteField = memo<Props>((props) => (
  <EditEbicaConfigFormItem
    label="ウォークイン　デフォルト滞在時間"
    required
    name="walkInStayTimeInMinute"
    rules={[{ required: true, message: "ウォークイン　デフォルト滞在時間を入力してください" }]}
    {...props}
  >
    <InputNumber />
  </EditEbicaConfigFormItem>
));
