import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import { filterPlansByName } from "models/plan";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, PlanFilter } from "./PlanFilter";
import { PlanTable } from "./PlanTable";
import {
  useWinboardPlansDeleteWinboardMenuMutation,
  useWinboardPlansGetCategoriesQuery,
  useWinboardPlansGetPlansQuery,
  useWinboardPlansGetShopsQuery,
} from "./queries";
import { Plan } from "./types";

export const filterPlans = (plans: Plan[], { categoryIds, name, shopId }: FilterConditions) => {
  const filteredPlans = plans.filter(
    (plan) =>
      (categoryIds === undefined || categoryIds.includes(plan.category.categoryId)) &&
      (shopId === undefined || plan.shopPlans.map(({ shop }) => shop.shopId).includes(shopId)),
  );

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans;
};

export const WinboardPlans = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getPlansData,
    loading: loadingPlans,
    refetch: refetchPlans,
    error,
  } = useWinboardPlansGetPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const plans = useMemo(() => getPlansData?.plans ?? [], [getPlansData]);
  const plansWithWinboardMenu = plans.map((p) => ({ ...p, winboardMenu: p.winboardMenus?.[0] }));

  const { data: getCategoriesData } = useWinboardPlansGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = useWinboardPlansGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [deleteWinboardMenuMutation] = useWinboardPlansDeleteWinboardMenuMutation();

  const onDelete = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await deleteWinboardMenuMutation({
          variables: { id },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchPlans();
    },
    [deleteWinboardMenuMutation, refetchPlans],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredPlans = useMemo(
    () => filterPlans(plansWithWinboardMenu, filterConditions),
    [plansWithWinboardMenu, filterConditions],
  );

  return (
    <DashboardLayout title="プラン一覧">
      <PageHeader
        title="プラン一覧"
        footer={
          <PlanFilter
            shops={shops}
            categories={categories}
            hasFilterConditions={hasFilterConditions}
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            clearFilterConditions={clearFilterConditions}
          />
        }
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <PlanTable plans={filteredPlans} loading={loadingPlans} onDelete={onDelete} />
    </DashboardLayout>
  );
};
