import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { FileTextOutlined } from "@ant-design/icons";

import { message } from "components/antd/message";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";

const zIndex = {
  formImageAction: 1,
  iconRemove: 2,
} as const;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const FormFileAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: ${zIndex.formImageAction};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s;
  content: "";

  &:hover {
    opacity: 1;
  }
`;

const UploadDragger = styled(Upload.Dragger)`
  &.ant-upload {
    background-color: #f0f2f5;

    .ant-upload-btn {
      padding: 0px;
    }

    &.ant-upload-drag {
      border: none;
    }
  }

  /* ドラッグ中 & ホバー時 */
  &.ant-upload-drag-hover {
    ${FormFileAction} {
      opacity: 1;
    }
  }
`;

const NoFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
`;

const IconFile = styled(FileTextOutlined)`
  font-size: 48px;
  color: rgba(0, 0, 0, 0.4);
`;

const SelectFileText = styled.div`
  height: 24px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  color: ${grey[0]};
`;

const SelectFileSubText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;

type Props = {
  files: UploadFile<any>[];
  acceptedTypes: string[];
  targetFileLabel?: React.ReactNode;
  onUpload: (files: UploadFile<any>[]) => void;
  onRemove: () => void;
  disabled?: boolean;
  allowMultiple: boolean;
};

export const FormFileItem = memo<Props>(
  ({
    files: uploadFiles,
    acceptedTypes,
    targetFileLabel,
    onUpload,
    onRemove,
    disabled = false,
    allowMultiple,
  }) => {
    const beforeUpload = useCallback(
      (file: RcFile) => {
        if (!acceptedTypes.includes(file.type)) {
          message.error("サポートされていないファイル形式です");
          return Upload.LIST_IGNORE;
        }
        /* NOTE: antdの自動アップロードを利用してないので、file statusをuploadingにしないためにfalseを返す
         * falseでも選ばれてるままに残る
         * https://ant.design/components/upload#api
         */
        return false;
      },
      [acceptedTypes],
    );

    const handleUpload = useCallback(
      ({ fileList }: UploadChangeParam) => onUpload(fileList),
      [onUpload],
    );

    const accept = useMemo(() => acceptedTypes.join(","), [acceptedTypes]);

    return (
      <Container>
        <UploadDragger
          name="file"
          multiple={Boolean(allowMultiple)}
          fileList={uploadFiles}
          showUploadList={true}
          onChange={handleUpload}
          accept={accept}
          disabled={disabled}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          customRequest={() => {
            /* デフォルトで upload する機能を止める */
          }}
        >
          <NoFileContainer>
            <IconFile />
            <Spacer size={16} />
            <SelectFileText>+ {targetFileLabel}ファイルを選択</SelectFileText>
            <Spacer size={14} />
            <SelectFileSubText>またはドラッグ&ドロップで追加</SelectFileSubText>
          </NoFileContainer>
        </UploadDragger>
      </Container>
    );
  },
);
