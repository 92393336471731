import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

export type ShopDailyCashRegisterBalancing = {
  shopId: string;
  shopName: string;
  closeCashRegisterId: string | null;
  createdAt: string | null;
  totalSales: number | null;
  depositAmount: number | null;
  cashDiscrepancies: number | null;
};

const CellContent = styled.div`
  min-width: 70px;
`;

type ShopDailyCashRegisterBalancingWithIndex = ShopDailyCashRegisterBalancing & { index: number };

type Props = {
  loading?: boolean;
  dataSource: ShopDailyCashRegisterBalancing[];
  date: string | null;
};

export const DailyCashRegisterBalancingTable = memo<Props>(({ loading, dataSource, date }) => {
  const [pagination] = usePagination();
  const dataSourceWithIndex = dataSource.reduce(
    (result, source) =>
      result.concat({ ...source, index: result.filter((r) => r.shopId === source.shopId).length }),
    [] as ShopDailyCashRegisterBalancingWithIndex[],
  );

  const columns = [
    {
      title: "店舗名",
      render(
        _: unknown,
        shopDailyCashRegisterBalancing: ShopDailyCashRegisterBalancingWithIndex,
        index: number,
      ) {
        return (
          <CellContent>
            {shopDailyCashRegisterBalancing.index === 0 || index % pagination.defaultPageSize === 0
              ? shopDailyCashRegisterBalancing.shopName
              : ""}
          </CellContent>
        );
      },
    },
    {
      title: "精算時刻",
      render(_: unknown, { createdAt, shopId, index }: ShopDailyCashRegisterBalancingWithIndex) {
        return (
          <CellContent>
            {createdAt && (
              <Link to={`/dailyCashRegisterBalancing/${shopId}/${date}?index=${index}`}>
                {dayjs(createdAt).format("YYYY/MM/DD HH:mm")}
              </Link>
            )}
          </CellContent>
        );
      },
    },
    {
      title: "総売上",
      dataIndex: "totalSales",
      align: "right",
      render(totalSales: number | null) {
        return <CellContent>{totalSales !== null ? formatPrice(totalSales) : "-"}</CellContent>;
      },
    } as const,
    {
      title: "銀行預入金",
      dataIndex: "depositAmount",
      align: "right",
      render(depositAmount: number | null) {
        return (
          <CellContent>{depositAmount !== null ? formatPrice(depositAmount) : "-"}</CellContent>
        );
      },
    } as const,
    {
      title: "現金過不足",
      dataIndex: "cashDiscrepancies",
      align: "right",
      render(cashDiscrepancies: number | null) {
        return (
          <CellContent>
            {cashDiscrepancies !== null ? formatPrice(cashDiscrepancies) : "-"}
          </CellContent>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey={({ shopId, closeCashRegisterId }) => `${shopId}-${closeCashRegisterId}`}
      columns={columns}
      dataSource={dataSourceWithIndex}
      loading={loading}
      bordered
      pagination={pagination}
    />
  );
});
