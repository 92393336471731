import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert, Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Spacer } from "components/Spacer";
import { Category as CategoryType } from "types/graphql";

type Props = {
  category: Pick<CategoryType, "name" | "categoryId"> | null;
  onBack: () => void;
  shouldShowManagingShopAlert: boolean;
};

export const CategoryHeader = memo<Props>(({ category, onBack, shouldShowManagingShopAlert }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={onBack} title={category?.name ?? ""} />

      {shouldShowManagingShopAlert && (
        <>
          <Alert
            type="warning"
            message="管理外の店舗のカテゴリに影響を与える可能性があります。注意して編集してください。"
          />

          <Spacer size={16} />
        </>
      )}

      {category && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/category/${category.categoryId}/edit`} replace>
              基本情報
            </Link>
          </Menu.Item>
          <Menu.Item key="translation">
            <Link to={`/category/${category.categoryId}/translation/edit`} replace>
              多言語設定
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
