import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const RolesGetRoles = gql`
    query RolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      id
      name
      roleId
      shopId
      shouldPrintClerkCallSlip
      shouldPrintDishUpSlip
      shouldPrintPaymentReceipt
      printerSound
      printDishUpSlipAsPrimarySlip
      kdDisplayTargets {
        roleId: _roleId
        name
      }
      firstSpareRole {
        id
        roleId
        name
      }
      secondSpareRole {
        id
        roleId
        name
      }
      shopMenuKitchenRoles(
        where: {shopMenu: {archivedAt: {_is_null: true}}, menu: {archivedAt: {_is_null: true}}}
      ) {
        roleId
        menu {
          id
          name
        }
      }
      dishUpSlipGroupRoles {
        roleId
        dishUpSlipGroup {
          id
          name
        }
      }
    }
  }
  onlinePaymentPrinterRoleMeta(where: {shopId: {_eq: $shopId}}) {
    id
    shopId
    printerRoleId
  }
}
    `;
export const DeleteRole = gql`
    mutation DeleteRole($roleId: uuid!) {
  delete_role(where: {id: {_eq: $roleId}}) {
    affected_rows
  }
  delete_onlinePaymentPrinterRoleMeta(where: {id: {_eq: $roleId}}) {
    affected_rows
  }
}
    `;
export type RolesGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type RolesGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId' | 'shopId' | 'shouldPrintClerkCallSlip' | 'shouldPrintDishUpSlip' | 'shouldPrintPaymentReceipt' | 'printerSound' | 'printDishUpSlipAsPrimarySlip'>
      & { kdDisplayTargets: Array<(
        { __typename?: 'kdDisplayTarget' }
        & Pick<Types.KdDisplayTarget, 'name'>
        & { roleId: Types.KdDisplayTarget['_roleId'] }
      )>, firstSpareRole?: Types.Maybe<(
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'roleId' | 'name'>
      )>, secondSpareRole?: Types.Maybe<(
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'roleId' | 'name'>
      )>, shopMenuKitchenRoles: Array<(
        { __typename?: 'shopMenuKitchenRole' }
        & Pick<Types.ShopMenuKitchenRole, 'roleId'>
        & { menu: (
          { __typename?: 'menu' }
          & Pick<Types.Menu, 'id' | 'name'>
        ) }
      )>, dishUpSlipGroupRoles: Array<(
        { __typename?: 'dishUpSlipGroupRoles' }
        & Pick<Types.DishUpSlipGroupRoles, 'roleId'>
        & { dishUpSlipGroup?: Types.Maybe<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'id' | 'name'>
        )> }
      )> }
    )> }
  )>, onlinePaymentPrinterRoleMeta: Array<(
    { __typename?: 'onlinePaymentPrinterRoleMeta' }
    & Pick<Types.OnlinePaymentPrinterRoleMeta, 'id' | 'shopId' | 'printerRoleId'>
  )> }
);

export type DeleteRoleMutationVariables = Types.Exact<{
  roleId: Types.Scalars['uuid'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_role?: Types.Maybe<(
    { __typename?: 'role_mutation_response' }
    & Pick<Types.RoleMutationResponse, 'affected_rows'>
  )>, delete_onlinePaymentPrinterRoleMeta?: Types.Maybe<(
    { __typename?: 'onlinePaymentPrinterRoleMeta_mutation_response' }
    & Pick<Types.OnlinePaymentPrinterRoleMetaMutationResponse, 'affected_rows'>
  )> }
);


export const RolesGetRolesDocument = gql`
    query RolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      id
      name
      roleId
      shopId
      shouldPrintClerkCallSlip
      shouldPrintDishUpSlip
      shouldPrintPaymentReceipt
      printerSound
      printDishUpSlipAsPrimarySlip
      kdDisplayTargets {
        roleId: _roleId
        name
      }
      firstSpareRole {
        id
        roleId
        name
      }
      secondSpareRole {
        id
        roleId
        name
      }
      shopMenuKitchenRoles(
        where: {shopMenu: {archivedAt: {_is_null: true}}, menu: {archivedAt: {_is_null: true}}}
      ) {
        roleId
        menu {
          id
          name
        }
      }
      dishUpSlipGroupRoles {
        roleId
        dishUpSlipGroup {
          id
          name
        }
      }
    }
  }
  onlinePaymentPrinterRoleMeta(where: {shopId: {_eq: $shopId}}) {
    id
    shopId
    printerRoleId
  }
}
    `;

/**
 * __useRolesGetRolesQuery__
 *
 * To run a query within a React component, call `useRolesGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useRolesGetRolesQuery(baseOptions: Apollo.QueryHookOptions<RolesGetRolesQuery, RolesGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesGetRolesQuery, RolesGetRolesQueryVariables>(RolesGetRolesDocument, options);
      }
export function useRolesGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesGetRolesQuery, RolesGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesGetRolesQuery, RolesGetRolesQueryVariables>(RolesGetRolesDocument, options);
        }
export type RolesGetRolesQueryHookResult = ReturnType<typeof useRolesGetRolesQuery>;
export type RolesGetRolesLazyQueryHookResult = ReturnType<typeof useRolesGetRolesLazyQuery>;
export type RolesGetRolesQueryResult = Apollo.QueryResult<RolesGetRolesQuery, RolesGetRolesQueryVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($roleId: uuid!) {
  delete_role(where: {id: {_eq: $roleId}}) {
    affected_rows
  }
  delete_onlinePaymentPrinterRoleMeta(where: {id: {_eq: $roleId}}) {
    affected_rows
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;