import React, { memo, useMemo } from "react";
import { Checkbox, Tag, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnsType } from "antd/lib/table";
import { getTaxMethodName } from "models/taxMethod";

import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";

import { Menu } from "../../types";

type Props = {
  loading: boolean;
  menus: Menu[];
  menuRecommendationCategoryId: number;
  disabledIds: number[];
  onChange: (menuIds: number[]) => void;
};

type CustomCheckboxProps = {
  disabled: boolean;
  hasNoImage: boolean;
  onChange: (e: CheckboxChangeEvent) => void;
  checked: boolean;
};

const CustomCheckbox = ({ disabled, hasNoImage, onChange, checked }: CustomCheckboxProps) => (
  <Tooltip title={hasNoImage && "画像登録のない商品は追加できません"}>
    <Checkbox disabled={disabled} onChange={onChange} checked={checked} />
  </Tooltip>
);

export const CategoryMenuTable = memo(
  ({ loading, menus, menuRecommendationCategoryId, disabledIds, onChange }: Props) => {
    const [pagination, setPagination] = usePagination();

    const columns: ColumnsType<Menu> = useMemo(
      () => [
        {
          title: "画像",
          align: "center" as const,
          width: 100,
          render(_: string, { imageUrl }: Menu) {
            return <Thumbnail url={imageUrl} width={64} height={64} />;
          },
        },
        {
          title: "商品名",
          dataIndex: "name",
        },
        {
          title: "カテゴリ名",
          render(_: string, { categoryMenus }: Menu) {
            return categoryMenus.map(
              ({ category }) =>
                category.categoryId !== menuRecommendationCategoryId && (
                  <Tag key={category.categoryId}>{category.name}</Tag>
                ),
            );
          },
        },
        {
          title: "価格",
          render(_: string, { openPrice, price, taxMethod }: Menu) {
            return openPrice
              ? "フリー"
              : `${price.toLocaleString()}円(${getTaxMethodName(taxMethod)})`;
          },
        } as const,
      ],
      [menuRecommendationCategoryId],
    );

    const rowSelection = useMemo(
      () => ({
        onChange: (_: any, menus: Menu[]) => {
          onChange(menus.map(({ menuId }) => menuId));
        },
        getCheckboxProps: (menu: Menu) => {
          if (disabledIds.includes(menu.menuId)) {
            return {
              disabled: true,
              hasNoImage: false,
            };
          }
          if (!menu.imageUrl) {
            return {
              disabled: true,
              hasNoImage: true,
            };
          }
          return {
            disabled: false,
            hasNoImage: false,
          };
        },
        renderCell: (
          checked: boolean,
          _record: Menu,
          _index: number,
          originNode: React.ReactNode,
        ) => {
          if (!React.isValidElement(originNode) || !originNode.props) {
            return null;
          }

          const { hasNoImage, disabled, onChange } = originNode.props;
          return (
            <CustomCheckbox
              hasNoImage={hasNoImage}
              disabled={disabled}
              onChange={onChange}
              checked={checked}
            />
          );
        },
      }),
      [disabledIds, onChange],
    );

    return (
      <Table
        rowKey={({ menuId }) => menuId}
        columns={columns}
        dataSource={menus}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        rowSelection={rowSelection}
      />
    );
  },
);
