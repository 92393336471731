import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { TitleProps } from "antd/lib/typography/Title";

import { Spacer } from "components/Spacer";

type Props = Omit<TitleProps, "level"> & {
  /** @default 4 */
  level?: TitleProps["level"];
  bottomSpacer?: React.ReactNode;
};

const StyledTitle = styled(Typography.Title)`
  && {
    margin-bottom: 0;
  }
`;

export const SubTitle = ({ bottomSpacer = <Spacer size={8} />, level = 4, ...props }: Props) => (
  <div>
    <StyledTitle level={level} {...props} />
    {bottomSpacer}
  </div>
);
