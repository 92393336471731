import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type AddDashboardAccountFormValues = {
  name: string;
  password: string;
  companies: string[];
  shops: string[];
  scope: "corporation" | "company" | "shop";
  email: string;
  dashboardAccountRoleId: string;
};

const initialValues = {
  name: "",
  password: "",
  shops: [],
  role: "shop",
  email: "",
  dashboardAccountRoleId: null,
};

export const AddDashboardAccountFormItem = createFormItem<AddDashboardAccountFormValues>();

export const useAddDashboardAccountForm = (
  onSubmit: (formValues: AddDashboardAccountFormValues) => void,
) => {
  const [form] = Form.useForm<AddDashboardAccountFormValues>();

  const submit = useCallback(() => {
    const values = form.getFieldsValue();

    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit, reset: form.resetFields };
};
