import { useCallback, useEffect } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { MenuOption } from "../types";

export type EditMenuOptionFormValues = {
  menuOptions: { optionId: string; _optionId: number }[];
};

export const EditMenuOptionFormItem = createFormItem<EditMenuOptionFormValues>();

export const useEditMenuOptionForm = (
  menuOptions: MenuOption[],
  onSubmit: ({ menuOptions }: { menuOptions: { _optionId: number; priority: number }[] }) => void,
) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      menuOptions: menuOptions.map((menuOption) => ({
        optionId: menuOption.option.id,
        _optionId: menuOption.optionId,
      })),
    });
  }, [menuOptions, form]);

  const submit = useCallback(async () => {
    await form.validateFields();
    const values = form.getFieldsValue() as {
      menuOptions: { _optionId: number }[];
    };
    onSubmit({
      menuOptions: values.menuOptions.map((value, index) => ({
        _optionId: value._optionId,
        priority: index,
      })),
    });
  }, [form, onSubmit]);

  return { form, submit };
};
