import React, { memo, useCallback, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { AddPlanOptionForm } from "pages/PlanOptions/AddPlanOptionModal/AddPlanOptionForm";
import { AddPlanOptionFormValues } from "pages/PlanOptions/AddPlanOptionModal/AddPlanOptionForm/useAddPlanOptionForm";
import {
  useAddPlanOptionModalGetMaxPlanOptionPriorityQuery,
  useAddPlanOptionModalInsertPlanOptionMutation,
} from "pages/PlanOptions/AddPlanOptionModal/queries";

type Props = {
  planId: string;
  _planId: number;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddPlanOptionModal = memo<Props>(({ planId, _planId, goBack, onUpdated }) => {
  const { refetch, error } = useAddPlanOptionModalGetMaxPlanOptionPriorityQuery({
    variables: { planId: _planId },
  });

  const [company] = useCompany();

  const [addOptionMutation, { loading: loadingInsertPlanOption }] =
    useAddPlanOptionModalInsertPlanOptionMutation();

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<AddPlanOptionFormValues | null>(null);

  const onOk = useCallback(async () => {
    if (!formValues || !company?.companyId) return;

    try {
      await form.validateFields();

      const { data: getMaxPlanOptionPriorityData } = await refetch();
      const maxPriority =
        getMaxPlanOptionPriorityData?.planOption_aggregate.aggregate?.max?.priority;
      const priority = maxPriority ? maxPriority + 1 : 0;
      const planOption = {
        companyId: company.id,
        _companyId: company.companyId,
        description: formValues.description,
        maxChoiceNum: formValues.maxChoiceNum,
        minChoiceNum: formValues.minChoiceNum,
        name: formValues.name,
        planId,
        _planId,
        priority,
        receiptDisplayName: formValues.receiptDisplayName,
        isMainOption: formValues.isMainOption ?? false,
        inputType: formValues.inputType,
      };

      const { data: addOptionData } = await addOptionMutation({
        variables: {
          planOption: {
            createPlanOptionSource: planOption,
            createTranslationsSource: {
              nameSources: (formValues.nameSources ?? []).filter(({ value }) => value !== null),
            },
          },
        },
      });

      const planOptionId = addOptionData?.createPlanOption.planOptionId;

      if (planOptionId === undefined) {
        throw new Error("");
      }

      onUpdated();

      message.success("作成しました");
    } catch (err) {
      return message.error("作成に失敗しました");
    }

    goBack();
  }, [
    formValues,
    company?.companyId,
    company?.id,
    goBack,
    form,
    refetch,
    planId,
    _planId,
    addOptionMutation,
    onUpdated,
  ]);
  const onCancel = goBack;

  return (
    <Modal
      title="オプション新規作成"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button key="save" type="primary" onClick={onOk} loading={loadingInsertPlanOption}>
          保存
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddPlanOptionForm form={form} onChange={setFormValues} />
    </Modal>
  );
});
