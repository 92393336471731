import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddGiftChoiceInsertGiftChoice = gql`
    mutation AddGiftChoiceInsertGiftChoice($choice: choice_insert_input!) {
  insert_choice_one(
    object: $choice
    on_conflict: {constraint: idx_46469_PRIMARY, update_columns: []}
  ) {
    choiceId
  }
}
    `;
export type AddGiftChoiceInsertGiftChoiceMutationVariables = Types.Exact<{
  choice: Types.ChoiceInsertInput;
}>;


export type AddGiftChoiceInsertGiftChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_choice_one?: Types.Maybe<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'choiceId'>
  )> }
);


export const AddGiftChoiceInsertGiftChoiceDocument = gql`
    mutation AddGiftChoiceInsertGiftChoice($choice: choice_insert_input!) {
  insert_choice_one(
    object: $choice
    on_conflict: {constraint: idx_46469_PRIMARY, update_columns: []}
  ) {
    choiceId
  }
}
    `;
export type AddGiftChoiceInsertGiftChoiceMutationFn = Apollo.MutationFunction<AddGiftChoiceInsertGiftChoiceMutation, AddGiftChoiceInsertGiftChoiceMutationVariables>;

/**
 * __useAddGiftChoiceInsertGiftChoiceMutation__
 *
 * To run a mutation, you first call `useAddGiftChoiceInsertGiftChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftChoiceInsertGiftChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftChoiceInsertGiftChoiceMutation, { data, loading, error }] = useAddGiftChoiceInsertGiftChoiceMutation({
 *   variables: {
 *      choice: // value for 'choice'
 *   },
 * });
 */
export function useAddGiftChoiceInsertGiftChoiceMutation(baseOptions?: Apollo.MutationHookOptions<AddGiftChoiceInsertGiftChoiceMutation, AddGiftChoiceInsertGiftChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGiftChoiceInsertGiftChoiceMutation, AddGiftChoiceInsertGiftChoiceMutationVariables>(AddGiftChoiceInsertGiftChoiceDocument, options);
      }
export type AddGiftChoiceInsertGiftChoiceMutationHookResult = ReturnType<typeof useAddGiftChoiceInsertGiftChoiceMutation>;
export type AddGiftChoiceInsertGiftChoiceMutationResult = Apollo.MutationResult<AddGiftChoiceInsertGiftChoiceMutation>;
export type AddGiftChoiceInsertGiftChoiceMutationOptions = Apollo.BaseMutationOptions<AddGiftChoiceInsertGiftChoiceMutation, AddGiftChoiceInsertGiftChoiceMutationVariables>;