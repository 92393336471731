import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { InflowSourceTag } from "pages/EditInflowSourceTag/types";
import { InflowSourceTagSetInput } from "types/graphql";

export type EditInflowSourceTagFormValues = Pick<InflowSourceTag, "displayName">;

const getInitialValues = (inflowSourceTag: InflowSourceTag) => {
  const { displayName } = inflowSourceTag;
  return { displayName };
};

export const EditInflowSourceTagFormItem = createFormItem<EditInflowSourceTagFormValues>();

export const useEditInflowSourceTagForm = (
  inflowSourceTag: InflowSourceTag,
  onSubmit: (inflowSourceTag: InflowSourceTagSetInput) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(inflowSourceTag);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditInflowSourceTagFormValues;
    onSubmit({ ...values });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
