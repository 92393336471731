import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { PlanFilter } from "pages/EditPlanPriorities/PlanFilter";
import {
  useEditPlanPrioritiesGetCategoriesQuery,
  useEditPlanPrioritiesGetPlansQuery,
  useEditPlanPrioritiesInsertPlansMutation,
} from "pages/EditPlanPriorities/queries";
import { PlanInsertInput } from "types/graphql";

import { EditPlanPriorityList } from "./EditPlanPriorityList";

export const EditPlanPriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getPlansData,
    loading: loadingPlans,
    refetch: refetchPriorities,
    error: getPlansDataError,
  } = useEditPlanPrioritiesGetPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const plans = useMemo(() => getPlansData?.plan ?? [], [getPlansData]);

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    error: getCategoriesDataError,
  } = useEditPlanPrioritiesGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const shouldShowAlert = getPlansDataError || getCategoriesDataError;

  const categories = getCategoriesData?.category ?? [];

  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [insertPlansMutation, { loading: loadingInsertingPlans }] =
    useEditPlanPrioritiesInsertPlansMutation();

  const onUpdate = useCallback(
    async (plans: PlanInsertInput[]) => {
      try {
        await insertPlansMutation({
          variables: { plans },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      await refetchPriorities();
    },
    [refetchPriorities, insertPlansMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const filteredPlans = useMemo(
    () => plans.filter((plan) => plan.planCategoryId === categoryId),
    [plans, categoryId],
  );

  return (
    <DashboardLayout
      title="プランの表示順を編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラン" }],
      }}
    >
      <PageHeader title="プランの表示順を編集" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <PlanFilter categories={categories} categoryId={categoryId} setCategoryId={setCategoryId} />
        {categoryId !== null && (
          <EditPlanPriorityList
            loading={loadingPlans || loadingCategories || loadingInsertingPlans}
            plans={filteredPlans}
            onUpdate={onUpdate}
          />
        )}
      </FormContent>
    </DashboardLayout>
  );
};
