import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import {
  useEditCategoryPrioritiesGetCategoriesQuery,
  useEditCategoryPrioritiesInsertCategoriesMutation,
} from "pages/EditCategoryPriorities/queries";
import { CategoryInsertInput } from "types/graphql";

import { EditCategoryPriorityList } from "./EditCategoryPriorityList";

export const EditCategoryPriorities = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    refetch: refetchCategoryPriorities,
    error,
  } = useEditCategoryPrioritiesGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const [insertCategoriesMutation, { loading: loadingInsertingCategories }] =
    useEditCategoryPrioritiesInsertCategoriesMutation();

  const onUpdate = useCallback(
    async (categories: CategoryInsertInput[]) => {
      try {
        await insertCategoriesMutation({
          variables: { categories },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchCategoryPriorities();
    },
    [refetchCategoryPriorities, insertCategoriesMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="カテゴリの表示順を編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "カテゴリ" }],
      }}
    >
      <PageHeader title="カテゴリの表示順を編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <EditCategoryPriorityList
          loading={loadingCategories || loadingInsertingCategories}
          categories={categories}
          onUpdate={onUpdate}
        />
      </FormContent>
    </DashboardLayout>
  );
};
