import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Coupon, QuestionnaireConfig } from "../types";

import { CouponSelectField } from "./CouponSelectField";
import {
  EditQuestionnaireConfigFormValues,
  useEditQuestionnaireConfigForm,
} from "./useEditQuestionnaireConfigForm";

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

type Props = {
  loading: boolean;
  questionnaireConfig: QuestionnaireConfig;
  coupons: Coupon[];
  onSubmit: ({
    questionnaireConfig,
  }: {
    questionnaireConfig: EditQuestionnaireConfigFormValues;
  }) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditQuestionnaireConfigForm = memo<Props>(
  ({ loading, questionnaireConfig, coupons, onSubmit, onClose, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditQuestionnaireConfigForm({
      onSubmit,
      questionnaireConfig,
    });
    const [uploadImage, setUploadImage] = useState(initialValues.imageUrl ?? null);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, submit, onFormValidationError, uploadImage]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="questionnaireConfig"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <ImageField
              image={uploadImage}
              setUploadImage={setUploadImage}
              uploadImageApiKey="questionnaire"
              formName="EditQuestionnaireConfigForm"
            />
            <CouponSelectField coupons={coupons} wrapperCol={{ span: 8 }} />
          </Form>
        </FormContent>
        <Spacer size={32} />
        <Footer>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button
            type="primary"
            onClick={loading ? undefined : handleSubmit}
            loading={loading}
            disabled={loading}
          >
            更新
          </Button>
        </Footer>
      </>
    );
  },
);
