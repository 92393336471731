import dayjs from "dayjs";

import type { Transformers } from "./types";

type RangeTransformers = Transformers<
  [dayjs.Dayjs | null, dayjs.Dayjs | null] | undefined,
  [number | null, number | null] | undefined
>;

export const serializeRange: RangeTransformers["serialize"] = (range) => {
  const serializeDate = (date: dayjs.Dayjs | null) => date?.valueOf() ?? null;
  return range ? [serializeDate(range[0]), serializeDate(range[1]) ?? null] : undefined;
};

export const deserializeRange: RangeTransformers["deserialize"] = (range) => {
  const deserializeDate = (date: number | null) => (date ? dayjs(date) : null);
  return range ? [deserializeDate(range[0]), deserializeDate(range[1])] : undefined;
};
