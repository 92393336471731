import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMembershipCardAppearanceTypeGetAppearanceTypes = gql`
    query EditMembershipCardAppearanceTypeGetAppearanceTypes {
  membershipCardAppearanceType {
    value
  }
}
    `;
export const EditMembershipCardAppearanceTypeGetCurrentAppearanceType = gql`
    query EditMembershipCardAppearanceTypeGetCurrentAppearanceType($companyId: uuid!) {
  membershipCard(where: {companyId: {_eq: $companyId}}) {
    id
    appearanceType
  }
  membershipRankConfig(where: {companyId: {_eq: $companyId}}) {
    requiredVisitCount
    membershipRank
  }
}
    `;
export const EditMembershipCardAppearanceTypeInsertAppearanceType = gql`
    mutation EditMembershipCardAppearanceTypeInsertAppearanceType($input: membershipCard_insert_input!) {
  insert_membershipCard_one(
    object: $input
    on_conflict: {constraint: membershipCard_companyUuid_key, update_columns: [appearanceType]}
  ) {
    appearanceType
  }
}
    `;
export type EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditMembershipCardAppearanceTypeGetAppearanceTypesQuery = (
  { __typename?: 'query_root' }
  & { membershipCardAppearanceType: Array<(
    { __typename?: 'membershipCardAppearanceType' }
    & Pick<Types.MembershipCardAppearanceType, 'value'>
  )> }
);

export type EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery = (
  { __typename?: 'query_root' }
  & { membershipCard: Array<(
    { __typename?: 'membershipCard' }
    & Pick<Types.MembershipCard, 'id' | 'appearanceType'>
  )>, membershipRankConfig: Array<(
    { __typename?: 'membershipRankConfig' }
    & Pick<Types.MembershipRankConfig, 'requiredVisitCount' | 'membershipRank'>
  )> }
);

export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationVariables = Types.Exact<{
  input: Types.MembershipCardInsertInput;
}>;


export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_membershipCard_one?: Types.Maybe<(
    { __typename?: 'membershipCard' }
    & Pick<Types.MembershipCard, 'appearanceType'>
  )> }
);


export const EditMembershipCardAppearanceTypeGetAppearanceTypesDocument = gql`
    query EditMembershipCardAppearanceTypeGetAppearanceTypes {
  membershipCardAppearanceType {
    value
  }
}
    `;

/**
 * __useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery__
 *
 * To run a query within a React component, call `useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery(baseOptions?: Apollo.QueryHookOptions<EditMembershipCardAppearanceTypeGetAppearanceTypesQuery, EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMembershipCardAppearanceTypeGetAppearanceTypesQuery, EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables>(EditMembershipCardAppearanceTypeGetAppearanceTypesDocument, options);
      }
export function useEditMembershipCardAppearanceTypeGetAppearanceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMembershipCardAppearanceTypeGetAppearanceTypesQuery, EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMembershipCardAppearanceTypeGetAppearanceTypesQuery, EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables>(EditMembershipCardAppearanceTypeGetAppearanceTypesDocument, options);
        }
export type EditMembershipCardAppearanceTypeGetAppearanceTypesQueryHookResult = ReturnType<typeof useEditMembershipCardAppearanceTypeGetAppearanceTypesQuery>;
export type EditMembershipCardAppearanceTypeGetAppearanceTypesLazyQueryHookResult = ReturnType<typeof useEditMembershipCardAppearanceTypeGetAppearanceTypesLazyQuery>;
export type EditMembershipCardAppearanceTypeGetAppearanceTypesQueryResult = Apollo.QueryResult<EditMembershipCardAppearanceTypeGetAppearanceTypesQuery, EditMembershipCardAppearanceTypeGetAppearanceTypesQueryVariables>;
export const EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeDocument = gql`
    query EditMembershipCardAppearanceTypeGetCurrentAppearanceType($companyId: uuid!) {
  membershipCard(where: {companyId: {_eq: $companyId}}) {
    id
    appearanceType
  }
  membershipRankConfig(where: {companyId: {_eq: $companyId}}) {
    requiredVisitCount
    membershipRank
  }
}
    `;

/**
 * __useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery__
 *
 * To run a query within a React component, call `useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery(baseOptions: Apollo.QueryHookOptions<EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery, EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery, EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables>(EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeDocument, options);
      }
export function useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery, EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery, EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables>(EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeDocument, options);
        }
export type EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryHookResult = ReturnType<typeof useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery>;
export type EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeLazyQueryHookResult = ReturnType<typeof useEditMembershipCardAppearanceTypeGetCurrentAppearanceTypeLazyQuery>;
export type EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryResult = Apollo.QueryResult<EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQuery, EditMembershipCardAppearanceTypeGetCurrentAppearanceTypeQueryVariables>;
export const EditMembershipCardAppearanceTypeInsertAppearanceTypeDocument = gql`
    mutation EditMembershipCardAppearanceTypeInsertAppearanceType($input: membershipCard_insert_input!) {
  insert_membershipCard_one(
    object: $input
    on_conflict: {constraint: membershipCard_companyUuid_key, update_columns: [appearanceType]}
  ) {
    appearanceType
  }
}
    `;
export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationFn = Apollo.MutationFunction<EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation, EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationVariables>;

/**
 * __useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation__
 *
 * To run a mutation, you first call `useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMembershipCardAppearanceTypeInsertAppearanceTypeMutation, { data, loading, error }] = useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation, EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation, EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationVariables>(EditMembershipCardAppearanceTypeInsertAppearanceTypeDocument, options);
      }
export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationHookResult = ReturnType<typeof useEditMembershipCardAppearanceTypeInsertAppearanceTypeMutation>;
export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationResult = Apollo.MutationResult<EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation>;
export type EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationOptions = Apollo.BaseMutationOptions<EditMembershipCardAppearanceTypeInsertAppearanceTypeMutation, EditMembershipCardAppearanceTypeInsertAppearanceTypeMutationVariables>;