import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditAccountFormItem } from "../useEditAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CurrentPasswordField = memo<Props>((props) => (
  <EditAccountFormItem
    name="current"
    rules={[{ required: true, message: "パスワードを入力してください" }]}
    help="パスワードをお忘れの場合は本部スタッフへご確認ください。"
    {...props}
  >
    <Input.Password placeholder="現在のパスワード" />
  </EditAccountFormItem>
));
