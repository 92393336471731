import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";
import { uniqBy } from "util/array";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { CategoryMenu } from "pages/MenuRecommendationMenus/types";
import { MenuTypeEnum } from "types/graphql";

import { AddCategoryMenuForm } from "./AddCategoryMenuForm";
import {
  useAddMenuRecommendationMenuModalGetMenusQuery,
  useAddMenuRecommendationMenuModalInsertMenusMutation,
} from "./queries";
import { Category } from "./types";

type Props = {
  categoryMenus: CategoryMenu[];
  menuRecommendationMetaId: string;
  menuRecommendationCategory: Category;
  goBack: () => void;
  disabled: boolean;
  refetchMenuRecommendation: () => void;
};

export const AddMenuRecommendationMenuModal = memo<Props>(
  ({
    categoryMenus,
    menuRecommendationMetaId,
    menuRecommendationCategory,
    goBack,
    disabled,
    refetchMenuRecommendation,
  }) => {
    const [company] = useCompany();
    const companyId = company?.id;

    const {
      data: getMenusData,
      error,
      loading: loadingGetMenus,
    } = useAddMenuRecommendationMenuModalGetMenusQuery(
      companyId
        ? { variables: { companyId, categoryId: menuRecommendationCategory.categoryId } }
        : { skip: true },
    );
    const menus = useMemo(
      () => (getMenusData?.menu ?? []).filter(({ menuType }) => menuType !== MenuTypeEnum.FaveYell),
      [getMenusData],
    );

    const categories = useMemo(
      () =>
        uniqBy(
          menus.flatMap(({ categoryMenus }) => categoryMenus.map(({ category }) => category)),
          ({ categoryId }) => categoryId,
        ),
      [menus],
    );

    const [addMenuRecommendationMenus, { loading: loadingInsertMenuRecommendationMenus }] =
      useAddMenuRecommendationMenuModalInsertMenusMutation();

    const [menuIds, setMenuIds] = useState<number[]>([]);
    const onOk = useCallback(async () => {
      if (menuIds.length === 0) return;

      try {
        const insertCategoryMenus = menuIds.map((menuId) => {
          const menu = menus.find((menu) => menu.menuId === menuId);

          if (!menu) throw new Error("menu is not found");

          return {
            menuId: menu.id,
            _menuId: menu.menuId,
            categoryId: menuRecommendationCategory.id,
            _categoryId: menuRecommendationCategory.categoryId,
            priority: categoryMenus.length,
          };
        });
        await addMenuRecommendationMenus({
          variables: { categoryMenus: insertCategoryMenus },
        });

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }

      await refetchMenuRecommendation();
      goBack();
    }, [
      addMenuRecommendationMenus,
      categoryMenus.length,
      goBack,
      menuIds,
      menuRecommendationCategory.categoryId,
      menuRecommendationCategory.id,
      menus,
      refetchMenuRecommendation,
    ]);
    const onCancel = goBack;

    return (
      <Modal
        title="メニュー追加"
        open
        width={900}
        onOk={goBack}
        onCancel={goBack}
        footer={[
          <Button
            key="add"
            type="primary"
            onClick={onOk}
            loading={loadingInsertMenuRecommendationMenus}
            disabled={disabled}
          >
            追加
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>,
        ]}
      >
        {error && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}
        <AddCategoryMenuForm
          menus={menus}
          menuRecommendationCategoryId={menuRecommendationCategory.categoryId}
          onChange={setMenuIds}
          loading={loadingGetMenus}
          disabledIds={categoryMenus.map((categoryMenu) => categoryMenu.menu.menuId)}
          categories={categories}
        />
      </Modal>
    );
  },
);
