import "dayjs/locale/ja";

import React, { memo } from "react";
import styled from "styled-components";
import { Button, Calendar, Col, Divider, Row } from "antd";
import dayjs from "dayjs";
import { sum } from "util/array";
import { formatPrice } from "util/price";
import { isNotNullable } from "util/type/primitive";

import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";

import { AverageAndMaxSalesByDayOfWeek } from "../../types";
import { EditSalesBudgetFormItem } from "../useEditSalesBudgetForm";

import { CalendarCell } from "./CalendarCell";
import { CalendarHeader } from "./CalendarHeader";

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
`;

const StyledCalendar = styled(Calendar)`
  thead {
    display: none;
  }
  .ant-picker-panel .ant-picker-body {
    padding-top: 0;
  }
`;

const BoldText = styled.span<{ red?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  color: ${({ red }) => red && "#ff4d4f"};
`;

type Props = {
  targetMonth: dayjs.Dayjs;
  averageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek[];
  onOpenAllocationSettingModal: () => void;
  allocateDailySalesBudget: () => void;
};

export const DailyBudgetField = memo<Props>(
  ({
    targetMonth,
    averageAndMaxSalesByDayOfWeek,
    onOpenAllocationSettingModal,
    allocateDailySalesBudget,
  }) => (
    <FormSection title="3. 日別目標を設定してください">
      <EditSalesBudgetFormItem.NonProperty noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const fieldsValue = getFieldsValue();
          const monthlyBudget = fieldsValue.monthlyBudget ?? 0;
          const dailyBudget = fieldsValue.dailyBudget ?? {};
          const totalDailyBudget = sum(Object.values(dailyBudget).filter(isNotNullable));

          return (
            <Row align="middle" gutter={16}>
              <Col flex="280px">
                <Row justify="space-between">
                  <Col>
                    <BoldText>日別目標の合計</BoldText>
                  </Col>
                  <Col>
                    <BoldText>{formatPrice(totalDailyBudget)}</BoldText>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <BoldText>月間目標との差分</BoldText>
                  </Col>
                  <Col>
                    <BoldText red={totalDailyBudget < monthlyBudget}>
                      {totalDailyBudget < monthlyBudget && "-"}
                      {formatPrice(Math.abs(totalDailyBudget - monthlyBudget))}
                    </BoldText>
                  </Col>
                </Row>
              </Col>
              <Col flex={1} />
              <Col span="auto">
                <Button
                  type="primary"
                  onClick={allocateDailySalesBudget}
                  disabled={monthlyBudget <= 0}
                >
                  月間目標を日別に割り振る
                </Button>
              </Col>
              <Col span="auto">
                <Button type="primary" onClick={onOpenAllocationSettingModal}>
                  詳細設定
                </Button>
              </Col>
            </Row>
          );
        }}
      </EditSalesBudgetFormItem.NonProperty>
      <Divider />
      <Row>
        <Col span="auto">
          <Title>{targetMonth.format("YYYY年M月")}の目標</Title>
          <Spacer size={24} />
          <CalendarHeader
            targetMonth={targetMonth}
            averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
          />
          <StyledCalendar
            value={targetMonth}
            headerRender={() => null}
            disabledDate={(date) => targetMonth.month() !== date.month()}
            fullCellRender={(date) => <CalendarCell date={date} targetMonth={targetMonth} />}
          />
        </Col>
      </Row>
    </FormSection>
  ),
);
