import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useBankCode } from "hooks/useBankCode";

import { EditGmoBankAccountFormValues } from "./EditGmoBankAccountForm/useEditGmoBankAccountForm";
import { EditGmoBankAccountForm } from "./EditGmoBankAccountForm";
import {
  useEditGmoBankAccountGetGmoBankAccountDetailQuery,
  useEditGmoBankAccountGetGmoBankAccountQuery,
  useEditGmoBankAccountGetShopsByCompanyQuery,
  useEditGmoBankAccountUpdateAccountMutation,
} from "./queries";

export const EditGmoBankAccount = () => {
  const { id: gmoBankAccountId } = useParams<{ id: string }>();

  const {
    bankCodes,
    branchCodes,
    fetchBranchCodeByBankCode,
    loading: loadingBankCode,
    error: getBankCodeError,
  } = useBankCode();

  const {
    data: shopsData,
    error: getShopsError,
    loading: loadingShopsData,
  } = useEditGmoBankAccountGetShopsByCompanyQuery();
  const shopsByCompany = useMemo(
    () => (shopsData?.company ?? []).filter((item) => item.shops.length > 0),
    [shopsData?.company],
  );

  const {
    data: gmoBankAccountData,
    loading: loadingGmoBankAccount,
    refetch: refetchGmoBankAccount,
    error: getGmoBankAccountError,
  } = useEditGmoBankAccountGetGmoBankAccountQuery(
    gmoBankAccountId
      ? {
          variables: {
            gmoBankAccountId,
          },
        }
      : { skip: true },
  );

  const gmoBankAccount = useMemo(
    () => gmoBankAccountData?.gmoBankAccount[0] ?? null,
    [gmoBankAccountData],
  );

  const gmoBankAccountShops = useMemo(
    () => gmoBankAccount?.gmoBankAccountShops ?? [],
    [gmoBankAccount],
  );

  const {
    data: gmoBankAccountDetailData,
    loading: loadingGmoBankAccountDetail,
    refetch: refetchGmoBankAccountDetail,
    error: getGmoBankAccountDetailError,
  } = useEditGmoBankAccountGetGmoBankAccountDetailQuery(
    gmoBankAccountId
      ? {
          variables: {
            input: {
              gmoBankAccountId,
            },
          },
          onCompleted(data) {
            const bankCode = data?.onlinePaymentRoot?.gmoBankAccountDetail?.bankCode;
            if (bankCode) {
              fetchBranchCodeByBankCode({ bankCode });
            }
          },
        }
      : { skip: true },
  );

  const gmoBankAccountDetail = useMemo(
    () => gmoBankAccountDetailData?.onlinePaymentRoot?.gmoBankAccountDetail ?? null,
    [gmoBankAccountDetailData],
  );

  const [updateGmoBankAccountMutation, { loading: loadingUpdateGmoBankAccount }] =
    useEditGmoBankAccountUpdateAccountMutation();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (formValues: EditGmoBankAccountFormValues) => {
      if (!formValues.accountType || !gmoBankAccountId) return;

      try {
        const { errors } = await updateGmoBankAccountMutation({
          variables: {
            input: {
              accountName: formValues.accountHolder,
              accountNumber: formValues.accountNumber,
              accountType: formValues.accountType,
              bankCode: formValues.bank,
              branchCode: formValues.branch,
              name: formValues.name,
              depositCycleShopIds: formValues.depositCycleShopIds,
              gmoBankAccountId,
            },
          },
        });
        await refetchGmoBankAccount();
        await refetchGmoBankAccountDetail();
        if (errors) {
          return message.error("口座の更新に失敗しました");
        }
        message.success("口座の更新に成功しました");
        navigate(-1);
      } catch (e) {
        if (
          e instanceof ApolloError &&
          e.graphQLErrors.some((graphQlError) => {
            const originalError = graphQlError.extensions.originalError ?? {};
            return (
              "code" in originalError &&
              originalError.code === "GmoTransferInternalServerErrorException"
            );
          })
        ) {
          message.error("現在メンテナンス中のため、しばらくの間ご利用いただけません");
        } else {
          message.error("口座の更新に失敗しました");
        }
      }
    },
    [
      updateGmoBankAccountMutation,
      navigate,
      gmoBankAccountId,
      refetchGmoBankAccount,
      refetchGmoBankAccountDetail,
    ],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingBankCode ||
    loadingShopsData ||
    loadingGmoBankAccount ||
    loadingGmoBankAccountDetail ||
    loadingUpdateGmoBankAccount;

  const shouldShowAlert =
    getShopsError ?? getBankCodeError ?? getGmoBankAccountDetailError ?? getGmoBankAccountError;

  return (
    <DashboardLayout title={gmoBankAccount?.name}>
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {gmoBankAccount && gmoBankAccountDetail ? (
        <EditGmoBankAccountForm
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          fetchBranchCode={fetchBranchCodeByBankCode}
          gmoBankAccount={gmoBankAccount}
          gmoBankAccountDetail={gmoBankAccountDetail}
          gmoBankAccountShops={gmoBankAccountShops}
          loading={loading}
          shopsByCompany={shopsByCompany}
          banks={bankCodes}
          branches={branchCodes}
          defaultBranchCode={gmoBankAccountDetail.branchCode}
        />
      ) : null}
    </DashboardLayout>
  );
};
