import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRole } from "models/user";

import { adyenTerminalPaymentPath, adyenTerminalPaymentSignInPagePath } from "constants/auth";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

const signInPagePath = "/signIn";

export const useAuthEffect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useCurrentUser();
  const role = getRole(user);

  const redirect = useCallback(() => {
    if (role === undefined) return;

    if (role === Role.Invalid) navigate("/invalidAccount");

    const isAuthenticated = role !== Role.Guest;

    // NOTE: 決済端末管理ページ以下の場合は決済端末管理用のログインページにリダイレクトされる
    const isAdyenTerminalPaymentSignInPagePath =
      location.pathname === adyenTerminalPaymentSignInPagePath;
    if (isAuthenticated && isAdyenTerminalPaymentSignInPagePath) {
      return navigate(`${adyenTerminalPaymentPath}/history`);
    }
    const isAdyenTerminalPaymentPagePath = location.pathname.includes(adyenTerminalPaymentPath);
    if (!isAuthenticated && isAdyenTerminalPaymentPagePath) {
      return navigate(adyenTerminalPaymentSignInPagePath);
    }

    const isSignInPagePath = location.pathname === signInPagePath;

    if (isAuthenticated && isSignInPagePath) navigate("/");
    if (!isAuthenticated && !isSignInPagePath) navigate(signInPagePath);
  }, [role, navigate, location.pathname]);

  useEffect(redirect, [redirect]);
};
