import React, { memo } from "react";
import styled from "styled-components";
import { List } from "antd";
import { getRankViewPropertiesByScore } from "models/questionnaireAnalytics";

import { colors, grey } from "constants/colors";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

const Content = styled(List.Item)`
  display: flex;
  align-items: stretch;
  && {
    padding: 0;
  }
`;

const Label = styled.div<{ isSmartphone: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isSmartphone }) => (isSmartphone ? 180 : 270)}px;
  padding 8px;
  border-right: 1px solid ${grey[3]};
  background-color: ${colors.BackGround.Tertiary};

  @media ${viewport.desktop} {
    padding: 16px;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background-color: ${grey[0]};
  flex: 1;

  @media ${viewport.desktop} {
    padding: 16px;
  }
`;

type Props = {
  label: string;
  value?: string | number;
};

export const AnswerListItem = memo<Props>(({ label, value }) => {
  const { isSmartphone } = useViewport();

  return (
    <Content>
      <Label isSmartphone={isSmartphone}>{label}</Label>
      <Value>
        {typeof value === "number" && (
          <img
            src={getRankViewPropertiesByScore(value).iconSrc}
            width={20}
            height={20}
            alt={`${label}_rank`}
          />
        )}
        {value}
      </Value>
    </Content>
  );
});
