import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Descriptions, Modal, Select, Tag } from "antd";

import { Plan } from "pages/AddPlan/types";

const SelectOption = Select.Option;

const StyledSelect = styled(Select)`
  width: 100%;
`;

type Props = {
  plans: Plan[];
  visible: boolean;
  onDismiss(): void;
  onSubmit: (plan: Plan) => void;
};

const generateDescriptionsItems = (plan: Plan) => [
  {
    key: "category",
    label: "カテゴリ",
    children: <Tag key={`${plan.category.categoryId}:${plan.planId}`}>{plan.category.name}</Tag>,
  },
];

export const SelectCopyPlanModal = memo<Props>(({ visible, plans, onSubmit, onDismiss }) => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const selectPlan = useCallback(
    (planId: unknown) => {
      setSelectedPlan(plans.find((plan) => plan.planId === Number(planId)) ?? null);
    },
    [plans],
  );
  const confirm = useCallback(() => {
    if (!selectedPlan) return;
    onSubmit(selectedPlan);
    onDismiss();
  }, [selectedPlan, onSubmit, onDismiss]);
  return (
    <Modal
      title="プランを選択"
      open={visible}
      okText="コピーする"
      cancelText="キャンセル"
      okButtonProps={{ disabled: !selectedPlan }}
      onCancel={onDismiss}
      onOk={confirm}
    >
      <StyledSelect
        showSearch
        optionFilterProp="label"
        onSelect={selectPlan}
        optionLabelProp="label"
      >
        {plans.map((plan) => (
          <SelectOption key={plan.planId} label={plan.planName} value={plan.planId}>
            {plan.planName}
            <Descriptions column={1} items={generateDescriptionsItems(plan)} />
          </SelectOption>
        ))}
      </StyledSelect>
    </Modal>
  );
});
