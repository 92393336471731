import React, { memo } from "react";
import { Button } from "antd";

import { EditPlanWinboardPlanFormItem } from "../useEditPlanWinboardPlanForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const WinboardGetAvailableCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed: handleAutoAdoptMenuCodeButtonPressed }) => (
    <EditPlanWinboardPlanFormItem label=" " name="winboardGetAvailableCodeButton">
      <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditPlanWinboardPlanFormItem>
  ),
);
