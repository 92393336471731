import React, { memo, useMemo } from "react";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { OnSitePaymentDetailType } from "../types";

type Props = {
  loading?: boolean;
  detailTypes: OnSitePaymentDetailType[];
};

export const DetailTypeTable = memo<Props>(({ loading, detailTypes }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<OnSitePaymentDetailType> = useMemo(
    () => [
      {
        title: "支払方法",
        width: 150,
        fixed: "left",
        render(_: string, { label }: OnSitePaymentDetailType) {
          return label;
        },
      },
      {
        title: "ぴかいち支払いメディアコード",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDetailTypes }: OnSitePaymentDetailType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDetailTypes[0];

          return pikaichiOnSitePaymentDetailType
            ? pikaichiOnSitePaymentDetailType.paymentMediaCd
            : "";
        },
      },
      {
        title: "ぴかいち支払いメディア名称",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDetailTypes }: OnSitePaymentDetailType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDetailTypes[0];

          return pikaichiOnSitePaymentDetailType
            ? pikaichiOnSitePaymentDetailType.paymentMediaName
            : "";
        },
      },
      {
        title: "ぴかいち内訳コード",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDetailTypes }: OnSitePaymentDetailType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDetailTypes[0];

          return pikaichiOnSitePaymentDetailType
            ? pikaichiOnSitePaymentDetailType.paymentUchiwakeCd
            : "";
        },
      },
      {
        title: "ぴかいち内訳名称",
        width: 150,
        fixed: "left",
        render(_: string, { pikaichiOnSitePaymentDetailTypes }: OnSitePaymentDetailType) {
          const pikaichiOnSitePaymentDetailType = pikaichiOnSitePaymentDetailTypes[0];

          return pikaichiOnSitePaymentDetailType
            ? pikaichiOnSitePaymentDetailType.paymentUchiwakeName
            : "";
        },
      },
      ...(canAccess("editPikaichiOnSitePaymentDetailType")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, { type }: OnSitePaymentDetailType) {
                return (
                  <Tooltip title="メニューを編集">
                    <IconLink to={`/pikaichi/onSitePaymentDetailType/${encodeURI(type)}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  return (
    <Table
      columns={columns}
      dataSource={detailTypes}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
