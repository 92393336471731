import { useCallback, useEffect, useMemo, useState } from "react";
import useEffectOnce from "react-use/esm/useEffectOnce";
import constate from "constate";

import { useCorporation } from "hooks/useCorporation";
import { useStorage } from "hooks/useStorage";
import { logger } from "libs/logger";

import { useShopPerCorporationGetShopsQuery } from "./queries";

/**
 * corporation 配下のすべての店舗から選択した店舗情報のキャッシュ
 * 分析系は company 横断 (corporation ごと) に行いたいので、useShop の代わりに使う
 */
export const [ShopPerCorporationProvider, useShopPerCorporation] = constate(() => {
  const [corporation] = useCorporation();
  const [shop, setShop] = useState<{ shopId: string }>();

  const { data: shopsData, loading } = useShopPerCorporationGetShopsQuery(
    corporation
      ? {
          variables: { corporationId: corporation.corporationId },
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );
  const shops = useMemo(
    () =>
      shopsData?.corporation[0]?.companies
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .flatMap((company) => company.shops.slice().sort((a, b) => a.name.localeCompare(b.name))) ??
      [],
    [shopsData?.corporation],
  );

  const { loadLastShopIdPerCorporation, setShopIdPerCorporation } = useStorage();

  useEffectOnce(() => {
    const lastShopId = loadLastShopIdPerCorporation();

    if (lastShopId) {
      setShop({ shopId: lastShopId });
    }
  });

  const handleSetShop = useCallback(
    (shopId: string | undefined) => {
      if (shopId) {
        logger.addContext({ key: "shopId", value: shopId });
        setShop({ shopId });
        setShopIdPerCorporation(shopId);
      } else if (!shopId) {
        logger.addContext({ key: "shopId", value: null });
        setShop(undefined);
        setShopIdPerCorporation(null);
      }
    },
    [setShopIdPerCorporation],
  );

  const currentShop = useMemo(() => {
    const currentShop = shops.find((s) => s.shopId === shop?.shopId);
    return currentShop;
  }, [shop?.shopId, shops]);

  useEffect(() => {
    if (currentShop) return;
    const fallbackShopId = shops[0]?.shopId;
    if (fallbackShopId) {
      setShop({ shopId: fallbackShopId });
    }
  }, [setShop, shops, currentShop]);

  return useMemo(() => ({ loading, shop, setShop: handleSetShop }), [loading, shop, handleSetShop]);
});
