import React, { memo, useCallback } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { OnSitePaymentDiscountType } from "pages/EditOnSitePaymentDiscountTypePriorities/types";
import { OnSitePaymentDiscountTypeInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  onSitePaymentDiscountTypes: OnSitePaymentDiscountType[];
  onUpdate: (onSitePaymentDiscountTypes: OnSitePaymentDiscountTypeInsertInput[]) => void;
};

export const EditOnSitePaymentDiscountTypePriorityList = memo<Props>(
  ({ loading, onSitePaymentDiscountTypes, onUpdate }) => {
    const handleMoveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        if (dragIndex !== hoverIndex) {
          const onSitePaymentDiscountTypesInput = onSitePaymentDiscountTypes.map(
            (onSitePaymentDiscountType) => ({
              id: onSitePaymentDiscountType.id,
              companyId: onSitePaymentDiscountType.companyId,
              label: onSitePaymentDiscountType.label,
              priority: onSitePaymentDiscountType.priority,
              type: onSitePaymentDiscountType.type,
            }),
          );
          onUpdate(updatePriorities(onSitePaymentDiscountTypesInput, dragIndex, hoverIndex));
        }
      },
      [onSitePaymentDiscountTypes, onUpdate],
    );

    return (
      <PriorityList<OnSitePaymentDiscountType>
        itemLayout="horizontal"
        loading={loading}
        dataSource={onSitePaymentDiscountTypes}
        renderItem={({ id, label, priority }, index) => (
          <PriorityListItem
            id={id}
            name={label}
            priority={priority}
            index={index}
            onMove={handleMoveItem}
          />
        )}
      />
    );
  },
);
