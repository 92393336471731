import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MessageDeliveryMenu } from "components/Menu/MessageDeliveryMenu";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useErrorMessageDeliveryJob } from "hooks/useErrorMessageDeliveryJob";
import { MessageDeliveryConsumption } from "pages/MessageDeliveries/MessageDeliveryConsumption";
import { useMessageDeliveryJobsGetMessageDeliveryJobsQuery } from "pages/MessageDeliveryJobs/queries";

import { MessageDeliveryJobTable } from "./MessageDeliveryJobTable";

export const MessageDeliveryJobs = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { getHasUncheckedErrorMessageDeliveryShopJob } = useErrorMessageDeliveryJob();

  const {
    data: getMessageDeliveryJobsData,
    error: getMessageDeliveryJobsError,
    loading: loadingMessageDeliveryJobs,
  } = useMessageDeliveryJobsGetMessageDeliveryJobsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const messageDeliveryJobs = useMemo(
    () => getMessageDeliveryJobsData?.messageDeliveryJob ?? [],
    [getMessageDeliveryJobsData],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="メッセージ配信一覧">
      <PageHeader
        title="メッセージ配信一覧"
        extra={[
          canAccess("addMessageDelivery") && (
            <Link key="add" to="/messageDelivery/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      <MessageDeliveryConsumption />
      <SubTitle bottomSpacer={<Spacer size={16} />}>メッセージ一覧</SubTitle>
      <MessageDeliveryMenu showErrorIcon={getHasUncheckedErrorMessageDeliveryShopJob()} />
      {getMessageDeliveryJobsError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <MessageDeliveryJobTable
          messageDeliveryJobs={messageDeliveryJobs}
          loading={loadingMessageDeliveryJobs}
        />
      </FormContent>
    </DashboardLayout>
  );
};
