import { genders } from "models/gender";
import { QuestionnaireAnswerRadioOptions } from "models/questionnaireAnswer";
import { isNotNull } from "util/type/primitive";

import { UnreachableError } from "libs/unreachable";
import {
  BusinessOperationHourTypeEnum,
  BusinessOperationHourTypeEnumType,
  Maybe,
  MessageDeliveryCustomerSegmentBusinessOperationHoursCondition,
  MessageDeliveryCustomerSegmentDayOfWeekCondition,
  MessageDeliveryCustomerSegmentDayOfWeekTypeEnum,
  MessageDeliveryCustomerSegmentGenderCondition,
  MessageDeliveryCustomerSegmentGenderEnum,
  MessageDeliveryCustomerSegmentMenuCondition,
  MessageDeliveryCustomerSegmentNumericCondition,
  MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition,
  MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum,
  MessageDeliveryCustomerSegmentShopCondition,
  MessageDeliveryDayOfWeekTypeType,
  MessageDeliveryGenderType,
  MessageDeliveryQuestionnaireAnswerStatusType,
} from "types/graphql";

import { businessOperationHourTypeToWord } from "./businessOperationHour";

export type NumericFilter = Pick<
  MessageDeliveryCustomerSegmentNumericCondition,
  "equal" | "lessThanOrEqual" | "moreThanOrEqual"
>;

export const emptyNumericFilter: NumericFilter = {
  equal: undefined,
  lessThanOrEqual: undefined,
  moreThanOrEqual: undefined,
};

export type MenusFilter = {
  type: "menu";
  menuIds: string[];
  moreThanOrEqualQuantity: number | null;
};

export const emptyMenusFilter: MenusFilter = {
  type: "menu",
  menuIds: [],
  moreThanOrEqualQuantity: null,
};

export const isEmptyNumericFilter = (filter: Partial<NumericFilter> | undefined) =>
  !filter?.equal && !filter?.lessThanOrEqual && !filter?.moreThanOrEqual;

export const messageDeliveryDayOfWeekToText = {
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sun]: "日",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Mon]: "月",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Tue]: "火",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Wed]: "水",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Thu]: "木",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Fri]: "金",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sat]: "土",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Holiday]: "祝",
};

export const getMessageDeliveryDayOfWeekTypeType = (
  messageDeliveryCustomerSegmentDayOfWeekTypeEnum: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum,
) => {
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sun
  ) {
    return MessageDeliveryDayOfWeekTypeType.Sun;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Mon
  ) {
    return MessageDeliveryDayOfWeekTypeType.Mon;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Tue
  ) {
    return MessageDeliveryDayOfWeekTypeType.Tue;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Wed
  ) {
    return MessageDeliveryDayOfWeekTypeType.Wed;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Thu
  ) {
    return MessageDeliveryDayOfWeekTypeType.Thu;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Fri
  ) {
    return MessageDeliveryDayOfWeekTypeType.Fri;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sat
  ) {
    return MessageDeliveryDayOfWeekTypeType.Sat;
  }
  if (
    messageDeliveryCustomerSegmentDayOfWeekTypeEnum ===
    MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Holiday
  ) {
    return MessageDeliveryDayOfWeekTypeType.Holiday;
  }
  throw new UnreachableError(messageDeliveryCustomerSegmentDayOfWeekTypeEnum);
};

export const getMessageDeliveryGender = (
  messageDeliveryCustomerSegmentGenderEnum: MessageDeliveryCustomerSegmentGenderEnum,
) => {
  if (messageDeliveryCustomerSegmentGenderEnum === MessageDeliveryCustomerSegmentGenderEnum.Male) {
    return MessageDeliveryGenderType.Male;
  }
  if (
    messageDeliveryCustomerSegmentGenderEnum === MessageDeliveryCustomerSegmentGenderEnum.Female
  ) {
    return MessageDeliveryGenderType.Female;
  }
  throw new UnreachableError(messageDeliveryCustomerSegmentGenderEnum);
};

export const getMessageDeliveryQuestionnaireAnswerStatusType = (
  messageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum: MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum,
) => {
  if (
    messageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum ===
    MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum.Answered
  ) {
    return MessageDeliveryQuestionnaireAnswerStatusType.Answered;
  }
  if (
    messageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum ===
    MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum.Unanswered
  ) {
    return MessageDeliveryQuestionnaireAnswerStatusType.Unanswered;
  }
  throw new UnreachableError(messageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum);
};

export const getBusinessOperationHourType = (
  businessOperationHourTypeEnum: BusinessOperationHourTypeEnum | "All",
) => {
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.Cafe) {
    return BusinessOperationHourTypeEnumType.Cafe;
  }
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.Dinner) {
    return BusinessOperationHourTypeEnumType.Dinner;
  }
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.HappyHour) {
    return BusinessOperationHourTypeEnumType.HappyHour;
  }
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.Lunch) {
    return BusinessOperationHourTypeEnumType.Lunch;
  }
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.Midnight) {
    return BusinessOperationHourTypeEnumType.Midnight;
  }
  if (businessOperationHourTypeEnum === BusinessOperationHourTypeEnum.Morning) {
    return BusinessOperationHourTypeEnumType.Morning;
  }
  if (businessOperationHourTypeEnum === "All") {
    return;
  }
  throw new UnreachableError(businessOperationHourTypeEnum);
};

export type CustomerSegment = {
  visitedDayCountCondition?: {
    messageDeliveryCustomerSegmentNumericCondition: {
      lessThanOrEqual: number | null;
      moreThanOrEqual: number | null;
      equal: number | null;
    };
  };
  unvisitedDayCountCondition?: {
    messageDeliveryCustomerSegmentNumericCondition: {
      lessThanOrEqual: number | null;
      moreThanOrEqual: number | null;
      equal: number | null;
    };
  };
  questionnaireAnswerCondition?: {
    messageDeliveryQuestionnaireAnswerStatus: MessageDeliveryQuestionnaireAnswerStatusType;
  };
  businessOperationHoursConditions?: [businessOperation: BusinessOperationHourTypeEnum];
  dayOfWeekConditions?: [dayOfWeek: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum];
  shopConditions?: [shopId: string];
  menuConditions?: [
    {
      menuId: string;
      moreThanOrEqualQuantity: number;
    },
  ];
};

type NumericCondition = Pick<
  MessageDeliveryCustomerSegmentNumericCondition,
  "equal" | "lessThanOrEqual" | "moreThanOrEqual"
>;

export const getTextFromNumericCondition = (condition: NumericCondition) => {
  if (!condition) return null;
  const equal = condition.equal;
  const start = condition.moreThanOrEqual;
  const end = condition.lessThanOrEqual;

  // equalのみ入っているデータと、equalはnullだがstartとendが同じ値のデータが混在しているため、それぞれ同等の条件として扱う
  if (equal) return `${equal}`;
  if (start && end && start === end) return `${start}`;

  if (start !== null && end !== null) return [start, end].join(" ~ ");
  if (start) return `${start} ~`;
  if (end) return `~ ${end}`;

  return null;
};

export const getText = ({
  customerSegment,
}: {
  customerSegment: {
    messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<
      Pick<
        MessageDeliveryCustomerSegmentBusinessOperationHoursCondition,
        "businessOperationHourType"
      >
    >;
    messageDeliveryCustomerSegmentDayOfWeekConditions: Array<
      Pick<MessageDeliveryCustomerSegmentDayOfWeekCondition, "messageDeliveryDayOfWeek">
    >;
    messageDeliveryCustomerSegmentMenuConditions: Array<
      Pick<MessageDeliveryCustomerSegmentMenuCondition, "menuId" | "moreThanOrEqualQuantity">
    >;
    messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Maybe<
      Pick<
        MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition,
        "messageDeliveryQuestionnaireAnswerStatus"
      >
    >;
    messageDeliveryCustomerSegmentShopConditions: Array<
      Pick<MessageDeliveryCustomerSegmentShopCondition, "shopId">
    >;
    messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Maybe<{
      messageDeliveryCustomerSegmentNumericCondition: NumericCondition;
    }>;
    messageDeliveryCustomerSegmentVisitedDayCountCondition?: Maybe<{
      messageDeliveryCustomerSegmentNumericCondition: NumericCondition;
    }>;
    messageDeliveryCustomerSegmentGenderCondition?: Maybe<
      Pick<MessageDeliveryCustomerSegmentGenderCondition, "messageDeliveryGender">
    >;
    messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Maybe<{
      messageDeliveryCustomerSegmentNumericCondition: NumericCondition;
    }>;
  };
}) => {
  const {
    messageDeliveryCustomerSegmentVisitedDayCountCondition,
    messageDeliveryCustomerSegmentUnvisitedDayCountCondition,
    messageDeliveryCustomerSegmentQuestionnaireAnswerCondition,
    messageDeliveryCustomerSegmentBusinessOperationHoursConditions,
    messageDeliveryCustomerSegmentDayOfWeekConditions,
    messageDeliveryCustomerSegmentShopConditions,
    messageDeliveryCustomerSegmentMenuConditions,
    messageDeliveryCustomerSegmentGenderCondition,
    messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition,
  } = customerSegment;

  const visitedDayCount = messageDeliveryCustomerSegmentVisitedDayCountCondition
    ? getTextFromNumericCondition(
        messageDeliveryCustomerSegmentVisitedDayCountCondition.messageDeliveryCustomerSegmentNumericCondition,
      )
    : null;

  const visitedDayCountText = visitedDayCount ? ["来店回数", visitedDayCount] : null;

  const unvisitedDayCount = messageDeliveryCustomerSegmentUnvisitedDayCountCondition
    ? getTextFromNumericCondition(
        messageDeliveryCustomerSegmentUnvisitedDayCountCondition.messageDeliveryCustomerSegmentNumericCondition,
      )
    : null;

  const unvisitedDayCountText = unvisitedDayCount
    ? ["最終来店からの経過日数", unvisitedDayCount]
    : null;

  const dayOfWeekText =
    messageDeliveryCustomerSegmentDayOfWeekConditions.length > 0
      ? [
          "来店した曜日",
          messageDeliveryCustomerSegmentDayOfWeekConditions
            .map(
              ({ messageDeliveryDayOfWeek }) =>
                messageDeliveryDayOfWeekToText[messageDeliveryDayOfWeek],
            )
            .join(", "),
        ]
      : null;

  const businessOperationHoursText =
    messageDeliveryCustomerSegmentBusinessOperationHoursConditions.length > 0
      ? [
          "時間帯",
          messageDeliveryCustomerSegmentBusinessOperationHoursConditions
            .map(
              ({ businessOperationHourType }) =>
                businessOperationHourTypeToWord[businessOperationHourType],
            )
            .join(", "),
        ]
      : null;

  const questionnaireAnswerStatus = QuestionnaireAnswerRadioOptions.find(
    ({ value }) =>
      value ===
      (messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?.messageDeliveryQuestionnaireAnswerStatus as unknown as string),
  )?.label;
  const questionnaireAnswerStatusText = questionnaireAnswerStatus
    ? ["アンケート回答状況", questionnaireAnswerStatus]
    : null;

  const shopCount = messageDeliveryCustomerSegmentShopConditions.length;
  const shopText = shopCount ? ["来店済み店舗", `${shopCount} 店舗`] : null;

  const menuCount = messageDeliveryCustomerSegmentMenuConditions
    ? messageDeliveryCustomerSegmentMenuConditions.length
    : 0;

  const orderedMenusText = menuCount ? ["注文したメニュー", `${menuCount} メニュー`] : null;

  const genderText = messageDeliveryCustomerSegmentGenderCondition?.messageDeliveryGender
    ? ["性別", genders[messageDeliveryCustomerSegmentGenderCondition.messageDeliveryGender]]
    : null;

  const daysCountUntilBirthday = messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition
    ? getTextFromNumericCondition(
        messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition.messageDeliveryCustomerSegmentNumericCondition,
      )
    : null;

  const daysCountUntilBirthdayText = daysCountUntilBirthday
    ? ["誕生日までの日数", daysCountUntilBirthday]
    : null;

  return [
    visitedDayCountText,
    unvisitedDayCountText,
    dayOfWeekText,
    businessOperationHoursText,
    questionnaireAnswerStatusText,
    shopText,
    orderedMenusText,
    genderText,
    daysCountUntilBirthdayText,
  ]
    .filter(isNotNull)
    .map((value) => value.join(" : "))
    .join(" / ");
};
