import React, { memo } from "react";
import { FormItemProps, Radio } from "antd";
import { menuTypes } from "models/menuType";

import { FormHelp } from "components/Form/FormHelp";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

const menuTypeOptions = Object.entries(menuTypes).map(([key, value]) => ({
  label: value,
  value: key,
}));

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled: boolean;
};

export const MenuTypeField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanChoiceFormItem
    label={
      <FormHelp
        label="メニュータイプ"
        help="設定した値が CSV ダウンロードの出数集計にて出力されます。"
      />
    }
    name="menuType"
    {...props}
  >
    <Radio.Group options={menuTypeOptions} optionType="button" disabled={disabled} />
  </EditPlanChoiceFormItem>
));
