import React, { memo, useCallback } from "react";
import { Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { useUpdateAccountPassword } from "hooks/useUpdateAccountPassword";

import { CurrentPasswordField } from "./EditAccountForm/CurrentPasswordField";
import { NewPasswordField } from "./EditAccountForm/NewPasswordField";
import { useEditAccountForm } from "./EditAccountForm/useEditAccountForm";

type Props = {
  visible: boolean;
  onDismiss(): void;
};

export const EditPasswordModal = memo<Props>(({ visible, onDismiss }) => {
  const { form, initialValues, validate, reset } = useEditAccountForm();
  const { updateAccountPassword, loading } = useUpdateAccountPassword();
  const onSubmit = useCallback(async () => {
    const values = await validate();
    if (!values) return;
    const { error } = await updateAccountPassword({
      currentPassword: values.current,
      newPassword: values.new,
    });
    if (error) {
      message.error(error);
    } else {
      message.success("パスワードを更新しました");
      reset();
      onDismiss();
    }
  }, [onDismiss, reset, updateAccountPassword, validate]);

  const onCancel = useCallback(() => {
    reset();
    onDismiss();
  }, [onDismiss, reset]);

  return (
    <Modal
      open={visible}
      okText="変更"
      cancelText="キャンセル"
      onCancel={onCancel}
      title="パスワード変更"
      onOk={onSubmit}
      confirmLoading={loading}
    >
      <Form name="editAccount" form={form} initialValues={initialValues}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <CurrentPasswordField horizontal />
          </Col>
          <Col span={24}>
            <NewPasswordField horizontal />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
