import React, { memo } from "react";
import { Switch } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

export const EnableAutoTranslationSwitch = memo(() => (
  <FormItem
    label="自動翻訳設定"
    name="enableAutoTranslation"
    required
    valuePropName="checked"
    endSpacer={null}
  >
    <Switch />
  </FormItem>
));
