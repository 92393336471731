import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { isEqualWith } from "util/array";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { OptionField } from "components/OptionsField/OptionField";
import { SortableTable } from "components/SortableTable";
import { colors } from "constants/colors";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { Option } from "pages/AddMenu/types";

import { AddOptionModal } from "./AddOptionModal";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  height: 28px;
  font-weight: 700;
  font-size: 20px;
  color: ${colors.Text.Default};
`;

const AddOptionButton = styled(Button)`
  width: 100%;
`;

type Props = Omit<FormItemProps, "children"> & {
  options: Option[];
  formPropName: string;
  loadOptions: () => Promise<unknown>;
  onAddOption: (option: { optionId: string; _optionId: number }) => void;
  title?: string;
};

export const OptionsField = memo<Props>(
  ({ options, formPropName, loadOptions, onAddOption, title }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const [addOptionModalVisible, setAddOptionModalVisible] = useState(false);
    const openAddOptionModal = useCallback(() => setAddOptionModalVisible(true), []);
    const closeAddOptionModal = useCallback(async () => {
      setAddOptionModalVisible(false);
    }, []);

    const getIsOptionsValueChanged = useCallback(
      (prev, current) =>
        !isEqualWith<Option>({
          array: prev[formPropName],
          other: current[formPropName],
          getIdentifier: ({ optionId }) => optionId,
        }),
      [formPropName],
    );

    const handleAddOption = useCallback(
      async (...args: Parameters<typeof onAddOption>) => {
        await loadOptions();

        onAddOption(...args);
      },
      [loadOptions, onAddOption],
    );

    return (
      <>
        <FormList name={formPropName}>
          {(fields, { remove, add, move }) => (
            <>
              <Header>
                <Title>{title}</Title>

                <Button disabled={!isFeatureEnabled("addOption")} onClick={openAddOptionModal}>
                  オプションを新規作成
                </Button>
              </Header>

              <SortableTable
                type="option"
                onMove={(dragIndex, hoverIndex) => move(dragIndex, hoverIndex)}
                bordered
                dataSource={fields ?? []}
                rowKey="key"
                /*
                  This needs to be set to override the default "max-content" scroll property.
                  This is necessary because this table is inside another table.
                */
                scroll={{ x: 0 }}
                columns={[
                  {
                    title: "",
                    width: 50,
                    render(_) {
                      return <MenuOutlined />;
                    },
                  },
                  {
                    title: "オプション",
                    render(_, field: FormListFieldData) {
                      return (
                        <FormItem shouldUpdate={getIsOptionsValueChanged} noStyle>
                          {({ getFieldValue }) => {
                            const optionFieldName = [field.name, "_optionId"];

                            const selectedOptions = (getFieldValue(formPropName) ?? []) as Pick<
                              Option,
                              "optionId"
                            >[];
                            const selectedOptionIds = selectedOptions.map(
                              ({ optionId }) => optionId,
                            );

                            const selectedOptionId = getFieldValue([
                              formPropName,
                              ...optionFieldName,
                            ]) as Option["optionId"] | undefined;

                            const filteredOptions = options.filter(
                              ({ optionId }) =>
                                !selectedOptionIds.includes(optionId) ||
                                selectedOptionId === optionId,
                            );

                            return <OptionField name={optionFieldName} options={filteredOptions} />;
                          }}
                        </FormItem>
                      );
                    },
                  },
                  {
                    width: 50,
                    align: "center",
                    render(_, field: FormListFieldData) {
                      return <DeleteIcon onClick={() => remove(field.name)} />;
                    },
                  },
                ]}
              />

              <AddOptionButton onClick={add}>
                <PlusOutlined />
                オプションを追加
              </AddOptionButton>
            </>
          )}
        </FormList>
        {addOptionModalVisible && (
          <AddOptionModal onDismiss={closeAddOptionModal} onAddOption={handleAddOption} />
        )}
      </>
    );
  },
);
