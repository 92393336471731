import React, { memo } from "react";
import styled from "styled-components";
import { Breadcrumb } from "antd";

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  items: {
    name: string;
  }[];
  companyName: string;
  shopName: string;
  showShopName: boolean;
};

export const LocationBreadCrumb = memo<Props>(({ items, companyName, shopName, showShopName }) => {
  const breadcrumbItems = [
    { title: companyName },
    ...(showShopName ? [{ title: shopName }] : []),
    ...items.map((item) => ({ title: item.name })),
  ];

  return (
    <Wrapper>
      <Breadcrumb items={breadcrumbItems} />
    </Wrapper>
  );
});
