import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Role } from "pages/EditCookingItem/types";

import { EditCookingItemFormItem } from "../useEditCookingItemForm";

const { Option } = Select;

type Props = {
  roles: Role[];
} & Omit<FormItemProps, "children" | "name">;

export const RoleField = memo<Props>(({ roles, ...props }) => (
  <EditCookingItemFormItem label="印刷先" name="roleId" {...props}>
    <Select allowClear>
      {roles.map(({ roleId, name }) => (
        <Option key={roleId} value={roleId}>
          {name}
        </Option>
      ))}
    </Select>
  </EditCookingItemFormItem>
));
