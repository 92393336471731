import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <AddMenuFormItem label="メニュー説明文 (日本語)" name="description" {...props}>
    <TextArea rows={2} placeholder="メニューの概要をご記載ください" />
  </AddMenuFormItem>
));
