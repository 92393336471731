import React, { memo, useMemo } from "react";
import { Tag } from "antd";

import { AsyncSwitch } from "components/AsyncSwitch";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { MenuRecommendation } from "../types";

type Props = {
  loading?: boolean;
  menuRecommendations: MenuRecommendation[];
  onToggleMenuRecommendation(args: { id: string; enabled: boolean }): Promise<void>;
};

export const MenuRecommendationTable = memo<Props>(
  ({ loading, menuRecommendations, onToggleMenuRecommendation }) => {
    const { canAccess } = useCanAccess();

    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = useMemo(
      () => [
        {
          title: "表示名",
          dataIndex: "name",
        },
        {
          title: "メニュー一覧",
          render(_: string, { category }: MenuRecommendation) {
            return category.categoryMenus.map(({ menu }) => (
              <>
                <Tag key={menu.menuId}>{menu.name}</Tag>
                <br />
              </>
            ));
          },
        },
        {
          title: "公開設定",
          width: 90,
          render(_: string, { id, enabled }: MenuRecommendation) {
            const handleChange = async () => {
              await onToggleMenuRecommendation({ id, enabled: !enabled });
            };

            return (
              <AsyncSwitch
                checked={enabled}
                onChange={handleChange}
                disabled={!isFeatureEnabled("editMenuRecommendation")}
              />
            );
          },
        },
        ...(canAccess("editMenuRecommendation")
          ? [
              {
                title: "",
                align: "center",
                width: 20,
                render(_: string, { id }: MenuRecommendation) {
                  return (
                    <IconLink to={`/menuRecommendation/${id}/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                },
              } as const,
            ]
          : []),
      ],
      [canAccess, isFeatureEnabled, onToggleMenuRecommendation],
    );

    return (
      <Table
        rowKey="menuRecommendationId"
        columns={columns}
        dataSource={menuRecommendations}
        loading={loading}
        bordered
        pagination={false}
      />
    );
  },
);
