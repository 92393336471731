import { useCallback, useState } from "react";
import { Store } from "antd/lib/form/interface";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import {
  EditShopPlanOptionRolesGetPlanOption,
  useEditShopPlanOptionRolesDeletePlanRolesRoleMutation,
  useEditShopPlanOptionRolesInsertPlanRolesRoleMutation,
} from "pages/EditShopPlanOptionRoles/queries";
import { PlanOption, Role } from "pages/EditShopPlanOptionRoles/types";
import { ShopPlanOptionKitchenRoleInsertInput } from "types/graphql";

export type EditPlanOptionRoleFormValues = Record<number, boolean>;

const getInitialValues = (planOption: Role[], roles: Role[]) => {
  const planOptionRoleIds = planOption.map(({ roleId }) => roleId);
  return Object.fromEntries(
    roles.map(({ roleId }) => [roleId, planOptionRoleIds.includes(roleId)]),
  );
};

const useLoadings = (roles: Role[]) => {
  const initialLoadings = Object.fromEntries<boolean>(roles.map(({ roleId }) => [roleId, false]));
  const [loadings, setLoadings] = useState(initialLoadings);

  const onLoad = useCallback(
    (roleId: number, state: boolean) =>
      setLoadings((loadings) => ({ ...loadings, [roleId]: state })),
    [],
  );

  const onRequest = useCallback((roleId: number) => onLoad(roleId, true), [onLoad]);
  const onFinish = useCallback((roleId: number) => onLoad(roleId, false), [onLoad]);

  return { loadings, onRequest, onFinish };
};

export const EditPlanOptionRoleFormItem = createFormItem<EditPlanOptionRoleFormValues>();

export const useEditPlanOptionRoleForm = (
  shopId: string,
  planOption: PlanOption,
  planOptionRoles: Role[],
  roles: Role[],
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(planOptionRoles, roles);

  const { loadings, onRequest, onFinish } = useLoadings(roles);

  const [insertPlanRolesRoleMutation] = useEditShopPlanOptionRolesInsertPlanRolesRoleMutation();

  const insertPlanRolesRole = useCallback(
    async (roleId: number) => {
      const role = roles.find((role) => role.roleId === roleId);

      if (!role) throw new Error("role not found");

      try {
        onRequest(roleId);

        const shopPlanOptionKitchenRoles: ShopPlanOptionKitchenRoleInsertInput[] = [
          {
            planOptionId: planOption.id,
            _planOptionId: planOption.planOptionId,
            roleId: role.id,
            _roleId: role.roleId,
            shopId,
          },
        ];
        await insertPlanRolesRoleMutation({
          variables: { shopPlanOptionKitchenRoles },
          refetchQueries: [
            {
              query: EditShopPlanOptionRolesGetPlanOption,
              variables: { planOptionId: planOption.planOptionId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [
      roles,
      onRequest,
      planOption.id,
      planOption.planOptionId,
      shopId,
      insertPlanRolesRoleMutation,
      onFinish,
    ],
  );

  const [deletePlanRolesRoleMutation] = useEditShopPlanOptionRolesDeletePlanRolesRoleMutation();

  const deletePlanRolesRole = useCallback(
    async (roleId: number) => {
      try {
        onRequest(roleId);

        await deletePlanRolesRoleMutation({
          variables: { planOptionId: planOption.planOptionId, roleId },
          refetchQueries: [
            {
              query: EditShopPlanOptionRolesGetPlanOption,
              variables: { planOptionId: planOption.planOptionId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [onRequest, deletePlanRolesRoleMutation, planOption.planOptionId, shopId, onFinish],
  );

  const change = useCallback(
    (changedValues: Store) => {
      const values = changedValues as EditPlanOptionRoleFormValues;

      for (const [roleId, state] of Object.entries(values)) {
        if (state) {
          insertPlanRolesRole(Number(roleId));
        } else {
          deletePlanRolesRole(Number(roleId));
        }
      }
    },
    [insertPlanRolesRole, deletePlanRolesRole],
  );

  return { form, initialValues, change, loadings };
};
