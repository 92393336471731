import { useCallback } from "react";
import { Form } from "antd";
import { Store } from "antd/lib/form/interface";
import dayjs from "dayjs";
import {
  formatOrderableTimeTermDayWeek,
  OrderableTimeTermDayWeek,
  OrderableTimeTermDayWeekType,
} from "models/orderableTime";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { normalizeDuration } from "util/duration";
import { isNotNull } from "util/type/primitive";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem } from "components/antd/Form";
import { OrderableTimeInsertInput, ShopOrderableTimeTermInsertInput } from "types/graphql";

import { OrderableTime } from "../types";

export type EditOrderableTimeFormValues = Pick<OrderableTime, "name"> & {
  terms: ({
    dayWeek: { value: OrderableTimeTermDayWeekType };
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
    id?: string;
  } | null)[];
};

const findSortKey = (dayWeek: OrderableTimeTermDayWeekType) =>
  Object.keys(OrderableTimeTermDayWeek).findIndex((v) => v === dayWeek);

const getInitialValues = (orderableTime: OrderableTime): EditOrderableTimeFormValues => ({
  name: orderableTime.name,
  terms: orderableTime.shopOrderableTimeTerms
    .slice()
    .sort(
      (a, b) =>
        findSortKey(a.dayWeek as OrderableTimeTermDayWeekType) -
        findSortKey(b.dayWeek as OrderableTimeTermDayWeekType),
    )
    .map((term) => ({
      dayWeek: {
        value: term.dayWeek as OrderableTimeTermDayWeekType,
        label: formatOrderableTimeTermDayWeek(term.dayWeek),
      },
      ...normalizeDuration(term),
      id: term.id,
    })),
});

export const EditOrderableTimeFormItem = createFormItem<EditOrderableTimeFormValues>();

export const useEditOrderableTimeForm = ({
  orderableTime,
  onSubmit,
  onFormValidationError,
}: {
  orderableTime: OrderableTime;
  onSubmit: (args: {
    orderableTime: Omit<OrderableTimeInsertInput, "companyId">;
    shopOrderableTimeTerms: Omit<ShopOrderableTimeTermInsertInput, "shopId">[];
  }) => Promise<void>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(orderableTime);

  const change = useCallback(
    (changedValues: Store, formValues: unknown) => {
      const orderableTimeFormValues = formValues as EditOrderableTimeFormValues;
      const changedOrderableTimeFormValues = changedValues as EditOrderableTimeFormValues;
      const changedTerms = changedOrderableTimeFormValues.terms ?? [];

      if (changedTerms.length === 0) return;

      form.setFields([
        {
          name: "terms",
          value: orderableTimeFormValues.terms,
        },
      ]);
    },
    [form],
  );

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as EditOrderableTimeFormValues;

      await onSubmit({
        orderableTime: {
          name: values.name,
        },
        shopOrderableTimeTerms: (values.terms ?? []).filter(isNotNull).map((term) => ({
          id: term.id,
          orderableTimeId: orderableTime.id,
          dayWeek: term.dayWeek.value,
          start: term.start.format("HH:mm"),
          end: term.end.format("HH:mm"),
        })),
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError, orderableTime.id]);

  return { form, initialValues, change, submit };
};
