import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddServiceAdminCreateServiceAdmin = gql`
    mutation AddServiceAdminCreateServiceAdmin($serviceAdmin: CreateServiceAdminInput!) {
  createServiceAdmin(input: $serviceAdmin)
}
    `;
export type AddServiceAdminCreateServiceAdminMutationVariables = Types.Exact<{
  serviceAdmin: Types.CreateServiceAdminInput;
}>;


export type AddServiceAdminCreateServiceAdminMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'createServiceAdmin'>
);


export const AddServiceAdminCreateServiceAdminDocument = gql`
    mutation AddServiceAdminCreateServiceAdmin($serviceAdmin: CreateServiceAdminInput!) {
  createServiceAdmin(input: $serviceAdmin)
}
    `;
export type AddServiceAdminCreateServiceAdminMutationFn = Apollo.MutationFunction<AddServiceAdminCreateServiceAdminMutation, AddServiceAdminCreateServiceAdminMutationVariables>;

/**
 * __useAddServiceAdminCreateServiceAdminMutation__
 *
 * To run a mutation, you first call `useAddServiceAdminCreateServiceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceAdminCreateServiceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceAdminCreateServiceAdminMutation, { data, loading, error }] = useAddServiceAdminCreateServiceAdminMutation({
 *   variables: {
 *      serviceAdmin: // value for 'serviceAdmin'
 *   },
 * });
 */
export function useAddServiceAdminCreateServiceAdminMutation(baseOptions?: Apollo.MutationHookOptions<AddServiceAdminCreateServiceAdminMutation, AddServiceAdminCreateServiceAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddServiceAdminCreateServiceAdminMutation, AddServiceAdminCreateServiceAdminMutationVariables>(AddServiceAdminCreateServiceAdminDocument, options);
      }
export type AddServiceAdminCreateServiceAdminMutationHookResult = ReturnType<typeof useAddServiceAdminCreateServiceAdminMutation>;
export type AddServiceAdminCreateServiceAdminMutationResult = Apollo.MutationResult<AddServiceAdminCreateServiceAdminMutation>;
export type AddServiceAdminCreateServiceAdminMutationOptions = Apollo.BaseMutationOptions<AddServiceAdminCreateServiceAdminMutation, AddServiceAdminCreateServiceAdminMutationVariables>;