import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationMenuOptionsEditChoiceModalGetChoice = gql`
    query TecAggregationMenuOptionsEditChoiceModalGetChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    choiceId
    price
    name
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;
export const TecAggregationMenuOptionsEditChoiceModalGetMenuCode = gql`
    query TecAggregationMenuOptionsEditChoiceModalGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;
export const TecAggregationMenuOptionsUpdateTecAggregationMenu = gql`
    mutation TecAggregationMenuOptionsUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables = Types.Exact<{
  choiceId: Types.Scalars['Int'];
}>;


export type TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery = (
  { __typename?: 'query_root' }
  & { choice: Array<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'id' | 'choiceId' | 'price' | 'name'>
    & { tecAggregationMenu?: Types.Maybe<(
      { __typename?: 'tecAggregationMenu' }
      & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
    )> }
  )> }
);

export type TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMenu: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'menuCode'>
  )> }
);

export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertTecAggregationMenuInput;
}>;


export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutation = (
  { __typename?: 'mutation_root' }
  & { upsertTecAggregationMenu: (
    { __typename: 'DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode' }
    & Pick<Types.DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode, 'dpCode' | 'dpName' | 'gpCode' | 'gpName' | 'menuCode' | 'name'>
  ) | (
    { __typename: 'DashboardUpsertTecAggregationMenuSuccess' }
    & Pick<Types.DashboardUpsertTecAggregationMenuSuccess, 'status'>
  ) }
);


export const TecAggregationMenuOptionsEditChoiceModalGetChoiceDocument = gql`
    query TecAggregationMenuOptionsEditChoiceModalGetChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    choiceId
    price
    name
    tecAggregationMenu {
      id
      name
      menuCode
      dpCode
      dpName
      gpCode
      gpName
    }
  }
}
    `;

/**
 * __useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery__
 *
 * To run a query within a React component, call `useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery, TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery, TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables>(TecAggregationMenuOptionsEditChoiceModalGetChoiceDocument, options);
      }
export function useTecAggregationMenuOptionsEditChoiceModalGetChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery, TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery, TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables>(TecAggregationMenuOptionsEditChoiceModalGetChoiceDocument, options);
        }
export type TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryHookResult = ReturnType<typeof useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery>;
export type TecAggregationMenuOptionsEditChoiceModalGetChoiceLazyQueryHookResult = ReturnType<typeof useTecAggregationMenuOptionsEditChoiceModalGetChoiceLazyQuery>;
export type TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryResult = Apollo.QueryResult<TecAggregationMenuOptionsEditChoiceModalGetChoiceQuery, TecAggregationMenuOptionsEditChoiceModalGetChoiceQueryVariables>;
export const TecAggregationMenuOptionsEditChoiceModalGetMenuCodeDocument = gql`
    query TecAggregationMenuOptionsEditChoiceModalGetMenuCode($companyId: uuid!) {
  tecAggregationMenu(where: {companyId: {_eq: $companyId}}) {
    menuCode
  }
}
    `;

/**
 * __useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery__
 *
 * To run a query within a React component, call `useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery, TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery, TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables>(TecAggregationMenuOptionsEditChoiceModalGetMenuCodeDocument, options);
      }
export function useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery, TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery, TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables>(TecAggregationMenuOptionsEditChoiceModalGetMenuCodeDocument, options);
        }
export type TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryHookResult = ReturnType<typeof useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery>;
export type TecAggregationMenuOptionsEditChoiceModalGetMenuCodeLazyQueryHookResult = ReturnType<typeof useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeLazyQuery>;
export type TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryResult = Apollo.QueryResult<TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery, TecAggregationMenuOptionsEditChoiceModalGetMenuCodeQueryVariables>;
export const TecAggregationMenuOptionsUpdateTecAggregationMenuDocument = gql`
    mutation TecAggregationMenuOptionsUpdateTecAggregationMenu($input: DashboardAccountUpsertTecAggregationMenuInput!) {
  upsertTecAggregationMenu(input: $input) {
    ... on DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode {
      __typename
      dpCode
      dpName
      gpCode
      gpName
      menuCode
      name
    }
    ... on DashboardUpsertTecAggregationMenuSuccess {
      __typename
      status
    }
  }
}
    `;
export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutationFn = Apollo.MutationFunction<TecAggregationMenuOptionsUpdateTecAggregationMenuMutation, TecAggregationMenuOptionsUpdateTecAggregationMenuMutationVariables>;

/**
 * __useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation__
 *
 * To run a mutation, you first call `useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tecAggregationMenuOptionsUpdateTecAggregationMenuMutation, { data, loading, error }] = useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation(baseOptions?: Apollo.MutationHookOptions<TecAggregationMenuOptionsUpdateTecAggregationMenuMutation, TecAggregationMenuOptionsUpdateTecAggregationMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TecAggregationMenuOptionsUpdateTecAggregationMenuMutation, TecAggregationMenuOptionsUpdateTecAggregationMenuMutationVariables>(TecAggregationMenuOptionsUpdateTecAggregationMenuDocument, options);
      }
export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutationHookResult = ReturnType<typeof useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation>;
export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutationResult = Apollo.MutationResult<TecAggregationMenuOptionsUpdateTecAggregationMenuMutation>;
export type TecAggregationMenuOptionsUpdateTecAggregationMenuMutationOptions = Apollo.BaseMutationOptions<TecAggregationMenuOptionsUpdateTecAggregationMenuMutation, TecAggregationMenuOptionsUpdateTecAggregationMenuMutationVariables>;