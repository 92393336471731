import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useQuestionnaireAnalyticsFeatureFlag } from "hooks/useQuestionnaireAnalyticsFeatureFlag";
import { DashboardFeatureEnum } from "types/graphql";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const QuestionnaireAnalyticsMenu = memo<Props>(({ company, ...props }) => {
  const { isQuestionnaireAnalyticsFeatureAvailable } = useQuestionnaireAnalyticsFeatureFlag({
    corporationId: company?.corporationId,
  });

  return (
    <SubMenu
      key="questionnaireAnalytics"
      title="アンケート分析"
      features={[DashboardFeatureEnum.Questionnaire]}
      canShow={isQuestionnaireAnalyticsFeatureAvailable}
      {...props}
    >
      <MenuItem
        to="/questionnaireAnalytics/score"
        route="questionnaireAnalyticsScoresMetrics"
        text="店舗スコア一覧"
      />
      <MenuItem
        to="/questionnaireAnalytics/comment"
        route="questionnaireAnalyticsCommentsMetrics"
        text="コメント一覧"
      />
      <MenuItem
        to="/questionnaireAnalytics/shop"
        route="questionnaireAnalyticsShopMetrics"
        text="店舗詳細"
      />
      <MenuItem
        to="/questionnaireAnalytics/scoreChanges"
        route="questionnaireAnalyticsScoreChangesMetrics"
        text="スコア推移"
      />
    </SubMenu>
  );
});
