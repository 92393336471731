import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import {
  useEditPlanDealersDeleteShopPlanMutation,
  useEditPlanDealersGetPlanQuery,
  useEditPlanDealersGetShopsQuery,
  useEditPlanDealersInsertShopPlanMutation,
  useEditPlanDealersUpdateShopPlanMutation,
  useEditPlanDealersUpdateStockMutation,
} from "pages/EditPlanDealers/queries";

import { ShopPlanTable } from "./ShopPlanTable";

export const EditPlanDealers = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const _planId = Number(id);
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const {
    data: getPlanData,
    loading: loadingPlan,
    refetch: refetchPlan,
  } = useEditPlanDealersGetPlanQuery(
    !Number.isNaN(_planId) ? { variables: { planId: _planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];
  const planId = plan?.id;
  const shopPlans = useMemo(() => plan?.shopPlans ?? [], [plan]);

  setCompany(plan?.companyId);

  const {
    data: getShopsData,
    loading: loadingShops,
    error,
  } = useEditPlanDealersGetShopsQuery(
    plan?.companyId ? { variables: { companyId: plan?.companyId } } : { skip: true },
  );
  const shops = getShopsData?.shops;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [updateShopPlanMutation, { loading: loadingUpdateShopPlan }] =
    useEditPlanDealersUpdateShopPlanMutation();
  const [updateShopPlanStockMutation, { loading: loadingUpdateShopPlanStock }] =
    useEditPlanDealersUpdateStockMutation();
  const [insertShopPlanMutation, { loading: loadingInsertShopPlan }] =
    useEditPlanDealersInsertShopPlanMutation();
  const [deleteShopPlanMutation, { loading: loadingDeleteShopPlan }] =
    useEditPlanDealersDeleteShopPlanMutation();

  const updateShopPlanVisibility = useCallback(
    async ({
      shopId,
      status,
    }: {
      shopId: string;
      status: { isVisibleForCustomer?: boolean; isVisibleForStaff?: boolean };
    }) => {
      if (!planId) return;

      const shopPlan = shopPlans.find((shopPlan) => shopPlan.shopId === shopId);

      try {
        await updateShopPlanMutation({
          variables: {
            input: {
              isVisibleForCustomer:
                status.isVisibleForCustomer ?? shopPlan?.isVisibleForCustomer ?? true,
              isVisibleForStaff: status.isVisibleForStaff ?? shopPlan?.isVisibleForStaff ?? true,
            },
            planId,
            shopId,
          },
        });
        await refetchPlan();
        message.success(`表示設定を更新しました`);
      } catch (err) {
        message.error(`表示設定の更新に失敗しました`);
      }
    },
    [planId, refetchPlan, shopPlans, updateShopPlanMutation],
  );
  const updatePlanIsVisibleForCustomer = useCallback(
    ({ shopId, isVisibleForCustomer }: { shopId: string; isVisibleForCustomer: boolean }) =>
      updateShopPlanVisibility({ shopId, status: { isVisibleForCustomer } }),
    [updateShopPlanVisibility],
  );

  const updatePlanIsVisibleForStaff = useCallback(
    ({ shopId, isVisibleForStaff }: { shopId: string; isVisibleForStaff: boolean }) =>
      updateShopPlanVisibility({ shopId, status: { isVisibleForStaff } }),
    [updateShopPlanVisibility],
  );

  const updateShopPlanIsSoldOut = useCallback(
    async (shopId: string, isSoldOut: boolean) => {
      if (!planId) return;
      try {
        const stockId =
          shopPlans.find((shopMenu) => shopMenu.shopId === shopId)?.stock?.id ?? uuidv4();
        await updateShopPlanStockMutation({
          variables: {
            stockId,
            planId,
            shopId,
            stock: { currentStockNum: isSoldOut ? 0 : null, id: stockId, shopId },
          },
        });
        await refetchPlan();
        message.success(`売切設定を更新しました`);
      } catch (err) {
        message.error(`売切設定の更新に失敗しました`);
      }
    },
    [planId, refetchPlan, shopPlans, updateShopPlanStockMutation],
  );

  const selectShop = useCallback(
    async ({ shopId }: { shopId: string }) => {
      if (!planId) return;
      try {
        await insertShopPlanMutation({
          variables: {
            input: {
              shopId,
              planId,
            },
          },
        });
        await refetchPlan();
        message.success(`取扱設定を更新しました`);
      } catch {
        message.error(`取扱設定の更新に失敗しました`);
      }
    },
    [insertShopPlanMutation, planId, refetchPlan],
  );

  const deselectShop = useCallback(
    async ({ shopId }: { shopId: string }) => {
      if (!planId) return;
      const targetShopPlan = shopPlans.find(
        (shopPlan) => shopPlan.planId === planId && shopPlan.shopId === shopId,
      );
      if (!targetShopPlan) return;

      try {
        await deleteShopPlanMutation({
          variables: { input: { shopPlanIds: [targetShopPlan.id] } },
        });
        await refetchPlan();
        message.success(`取扱設定を更新しました`);
      } catch {
        message.error(`取扱設定の更新に失敗しました`);
      }
    },
    [planId, shopPlans, deleteShopPlanMutation, refetchPlan],
  );

  const loadingQuery = loadingPlan || loadingShops;
  const loadingMutation =
    loadingUpdateShopPlan ||
    loadingUpdateShopPlanStock ||
    loadingInsertShopPlan ||
    loadingDeleteShopPlan;

  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラン" }, { name: plan?.planName ?? "" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loadingQuery && <Loading height={300} />}
      {plan && shops && (
        <FormContent>
          <ShopPlanTable
            loading={loadingQuery || loadingMutation}
            shopPlans={shopPlans}
            shops={shops}
            onSelectShopId={selectShop}
            onDeselectShopId={deselectShop}
            onCheckIsVisibleForCustomer={updatePlanIsVisibleForCustomer}
            onCheckIsVisibleForStaff={updatePlanIsVisibleForStaff}
            onCheckIsSoldOut={updateShopPlanIsSoldOut}
          />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
