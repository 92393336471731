import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionDealersGetOptions = gql`
    query EditOptionDealersGetOptions($optionId: Int!) {
  options: option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    optionId
    companyId
    name
    shopOptionKitchenRoles {
      id
      kitchenRoleId
      optionId
      shopId
      kitchenRole {
        id
        name
        roleId
      }
    }
    company {
      id
    }
  }
}
    `;
export const EditOptionDealersGetShops = gql`
    query EditOptionDealersGetShops($companyId: uuid!) {
  shops: shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export type EditOptionDealersGetOptionsQueryVariables = Types.Exact<{
  optionId: Types.Scalars['Int'];
}>;


export type EditOptionDealersGetOptionsQuery = (
  { __typename?: 'query_root' }
  & { options: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'id' | 'optionId' | 'companyId' | 'name'>
    & { shopOptionKitchenRoles: Array<(
      { __typename?: 'shopOptionKitchenRole' }
      & Pick<Types.ShopOptionKitchenRole, 'id' | 'kitchenRoleId' | 'optionId' | 'shopId'>
      & { kitchenRole: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'name' | 'roleId'>
      ) }
    )>, company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ) }
  )> }
);

export type EditOptionDealersGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOptionDealersGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const EditOptionDealersGetOptionsDocument = gql`
    query EditOptionDealersGetOptions($optionId: Int!) {
  options: option(
    where: {optionId: {_eq: $optionId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    id
    optionId
    companyId
    name
    shopOptionKitchenRoles {
      id
      kitchenRoleId
      optionId
      shopId
      kitchenRole {
        id
        name
        roleId
      }
    }
    company {
      id
    }
  }
}
    `;

/**
 * __useEditOptionDealersGetOptionsQuery__
 *
 * To run a query within a React component, call `useEditOptionDealersGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionDealersGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionDealersGetOptionsQuery({
 *   variables: {
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionDealersGetOptionsQuery(baseOptions: Apollo.QueryHookOptions<EditOptionDealersGetOptionsQuery, EditOptionDealersGetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionDealersGetOptionsQuery, EditOptionDealersGetOptionsQueryVariables>(EditOptionDealersGetOptionsDocument, options);
      }
export function useEditOptionDealersGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionDealersGetOptionsQuery, EditOptionDealersGetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionDealersGetOptionsQuery, EditOptionDealersGetOptionsQueryVariables>(EditOptionDealersGetOptionsDocument, options);
        }
export type EditOptionDealersGetOptionsQueryHookResult = ReturnType<typeof useEditOptionDealersGetOptionsQuery>;
export type EditOptionDealersGetOptionsLazyQueryHookResult = ReturnType<typeof useEditOptionDealersGetOptionsLazyQuery>;
export type EditOptionDealersGetOptionsQueryResult = Apollo.QueryResult<EditOptionDealersGetOptionsQuery, EditOptionDealersGetOptionsQueryVariables>;
export const EditOptionDealersGetShopsDocument = gql`
    query EditOptionDealersGetShops($companyId: uuid!) {
  shops: shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useEditOptionDealersGetShopsQuery__
 *
 * To run a query within a React component, call `useEditOptionDealersGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionDealersGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionDealersGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOptionDealersGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditOptionDealersGetShopsQuery, EditOptionDealersGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionDealersGetShopsQuery, EditOptionDealersGetShopsQueryVariables>(EditOptionDealersGetShopsDocument, options);
      }
export function useEditOptionDealersGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionDealersGetShopsQuery, EditOptionDealersGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionDealersGetShopsQuery, EditOptionDealersGetShopsQueryVariables>(EditOptionDealersGetShopsDocument, options);
        }
export type EditOptionDealersGetShopsQueryHookResult = ReturnType<typeof useEditOptionDealersGetShopsQuery>;
export type EditOptionDealersGetShopsLazyQueryHookResult = ReturnType<typeof useEditOptionDealersGetShopsLazyQuery>;
export type EditOptionDealersGetShopsQueryResult = Apollo.QueryResult<EditOptionDealersGetShopsQuery, EditOptionDealersGetShopsQueryVariables>;