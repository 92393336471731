import React, { memo } from "react";
import styled from "styled-components";
import { Modal, Progress, Spin } from "antd";

import { Spacer } from "components/Spacer";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ModalTitle = styled.h2`
  font-size: 16px;
  margin: 0;
`;

const LoadingText = styled.div`
  font-size: 14px;
  text-align: center;
`;
const ProgressBarWrapper = styled.div`
  text-align: center;
`;

export const MenuMasterLoadingModal = memo(
  ({ visible, progressPercentage }: { visible: boolean; progressPercentage: number }) => (
    <Modal
      width={480}
      title={
        <>
          <Spacer size={32} />
          <TitleContainer>
            <Spin size="default" />
            <Spacer inline size={14} />
            <ModalTitle>CSVファイルをアップロードしています...</ModalTitle>
          </TitleContainer>
          <Spacer size={20} />
        </>
      }
      footer={null}
      centered
      closable={false}
      open={visible}
    >
      <ProgressBarWrapper>
        <Progress percent={progressPercentage * 100} size={[352, 4]} showInfo={false} />
      </ProgressBarWrapper>
      <Spacer size={20} />
      <LoadingText>
        アップロードに時間がかかる場合があります。
        <br />
        しばらくお待ちください。
      </LoadingText>
      <Spacer size={40} />
    </Modal>
  ),
);
