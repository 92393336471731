import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditWinboardPlanFormItem } from "../useEditWinboardPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CategoryCodeField = memo<Props>((props) => (
  <EditWinboardPlanFormItem label="分類コード" name="categoryCode" {...props}>
    <InputCode />
  </EditWinboardPlanFormItem>
));
