import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsShopMetricsGetQuestionnaire = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswer = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswer($questionnaireId: uuid!, $shopId: uuid!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_eq: $shopId}, visitedAt: {_lte: $endAt, _gte: $startAt}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetrics = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetrics($input: QuestionnaireScoreMetricsInput!) {
  questionnaireScoreMetrics(input: $input) {
    corporationAverageScore {
      corporationId
      answerCount
      cleanliness
      customerService
      repeatability
      deliciousness
      speed
    }
    shopAverageScores {
      shopId
      shopName
      answerCount
      cleanliness {
        fluctuation
        score
      }
      customerService {
        fluctuation
        score
      }
      deliciousness {
        fluctuation
        score
      }
      repeatability {
        fluctuation
        score
      }
      speed {
        fluctuation
        score
      }
    }
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetrics = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetrics($input: QuestionnaireAnswererAttributeMetricsInput!) {
  questionnaireAnswererAttributeMetrics(input: $input) {
    title
    totalCount
    distributionItems {
      label
      count
    }
  }
}
    `;
export const QuestionnaireAnalyticsShopMetricsGetShops = gql`
    query QuestionnaireAnalyticsShopMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id'>
  )> }
);

export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
  startAt: Types.Scalars['timestamptz'];
  endAt: Types.Scalars['timestamptz'];
}>;


export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery = (
  { __typename?: 'query_root' }
  & { questionnaireAnswer: Array<(
    { __typename?: 'questionnaireAnswer' }
    & Pick<Types.QuestionnaireAnswer, 'id' | 'shopId'>
  )> }
);

export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables = Types.Exact<{
  input: Types.QuestionnaireScoreMetricsInput;
}>;


export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireScoreMetrics: (
    { __typename?: 'QuestionnaireScoreMetricsOutput' }
    & { corporationAverageScore: (
      { __typename?: 'QuestionnaireCorporationAverageScore' }
      & Pick<Types.QuestionnaireCorporationAverageScore, 'corporationId' | 'answerCount' | 'cleanliness' | 'customerService' | 'repeatability' | 'deliciousness' | 'speed'>
    ), shopAverageScores: Array<(
      { __typename?: 'QuestionnaireShopAverageScore' }
      & Pick<Types.QuestionnaireShopAverageScore, 'shopId' | 'shopName' | 'answerCount'>
      & { cleanliness: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), customerService: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), deliciousness: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), repeatability: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ), speed: (
        { __typename?: 'QuestionnaireScoreWithFluctuation' }
        & Pick<Types.QuestionnaireScoreWithFluctuation, 'fluctuation' | 'score'>
      ) }
    )> }
  ) }
);

export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables = Types.Exact<{
  input: Types.QuestionnaireAnswererAttributeMetricsInput;
}>;


export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireAnswererAttributeMetrics: Array<(
    { __typename?: 'QuestionnaireAnswererAttributeMetricsOutput' }
    & Pick<Types.QuestionnaireAnswererAttributeMetricsOutput, 'title' | 'totalCount'>
    & { distributionItems: Array<(
      { __typename?: 'QuestionnaireAnswererAttributeMetricsDistributionItem' }
      & Pick<Types.QuestionnaireAnswererAttributeMetricsDistributionItem, 'label' | 'count'>
    )> }
  )> }
);

export type QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  questionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsShopMetricsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'name'>
      & { shops: Array<(
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      )> }
    )> }
  )> }
);


export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaire($corporationId: uuid!) {
  questionnaire(
    where: {questionnaireConfigs: {company: {corporationId: {_eq: $corporationId}}}}
    limit: 1
  ) {
    id
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsGetQuestionnaireQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerDocument = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswer($questionnaireId: uuid!, $shopId: uuid!, $startAt: timestamptz!, $endAt: timestamptz!) {
  questionnaireAnswer(
    where: {questionnaireId: {_eq: $questionnaireId}, shopId: {_eq: $shopId}, visitedAt: {_lte: $endAt, _gte: $startAt}}
    limit: 1
  ) {
    id
    shopId
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery({
 *   variables: {
 *      questionnaireId: // value for 'questionnaireId'
 *      shopId: // value for 'shopId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswerQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsDocument = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetrics($input: QuestionnaireScoreMetricsInput!) {
  questionnaireScoreMetrics(input: $input) {
    corporationAverageScore {
      corporationId
      answerCount
      cleanliness
      customerService
      repeatability
      deliciousness
      speed
    }
    shopAverageScores {
      shopId
      shopName
      answerCount
      cleanliness {
        fluctuation
        score
      }
      customerService {
        fluctuation
        score
      }
      deliciousness {
        fluctuation
        score
      }
      repeatability {
        fluctuation
        score
      }
      speed {
        fluctuation
        score
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireScoreMetricsQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsDocument = gql`
    query QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetrics($input: QuestionnaireAnswererAttributeMetricsInput!) {
  questionnaireAnswererAttributeMetrics(input: $input) {
    title
    totalCount
    distributionItems {
      label
      count
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQuery, QuestionnaireAnalyticsShopMetricsGetQuestionnaireAnswererAttributeMetricsQueryVariables>;
export const QuestionnaireAnalyticsShopMetricsGetShopsDocument = gql`
    query QuestionnaireAnalyticsShopMetricsGetShops($corporationId: uuid!, $questionnaireId: uuid!) {
  corporation(where: {id: {_eq: $corporationId}}) {
    id
    name
    companies(
      where: {corporationId: {_eq: $corporationId}, questionnaireConfigs: {questionnaireId: {_eq: $questionnaireId}}, archivedAt: {_is_null: true}}
    ) {
      id
      name
      shops(where: {archivedAt: {_is_null: true}}) {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsShopMetricsGetShopsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsShopMetricsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsShopMetricsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsShopMetricsGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      questionnaireId: // value for 'questionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsShopMetricsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsShopMetricsGetShopsQuery, QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsShopMetricsGetShopsQuery, QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetShopsDocument, options);
      }
export function useQuestionnaireAnalyticsShopMetricsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsShopMetricsGetShopsQuery, QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsShopMetricsGetShopsQuery, QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables>(QuestionnaireAnalyticsShopMetricsGetShopsDocument, options);
        }
export type QuestionnaireAnalyticsShopMetricsGetShopsQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetShopsQuery>;
export type QuestionnaireAnalyticsShopMetricsGetShopsLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsShopMetricsGetShopsLazyQuery>;
export type QuestionnaireAnalyticsShopMetricsGetShopsQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsShopMetricsGetShopsQuery, QuestionnaireAnalyticsShopMetricsGetShopsQueryVariables>;