import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { AddOnSitePaymentDiscountTypeForm } from "./AddOnSitePaymentDiscountTypeForm";
import {
  useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery,
  useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation,
} from "./queries";

export const AddOnSitePaymentDiscountType = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: onSitePaymentDiscountTypeMaxPriorityData, refetch: refetchMaxPriority } =
    useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const onSitePaymentDiscountTypeMaxPriority =
    onSitePaymentDiscountTypeMaxPriorityData?.onSitePaymentDiscountType_aggregate.aggregate?.max
      ?.priority ?? 0;

  const [addOnSitePaymentDiscountType, { loading }] =
    useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation();

  const handleSubmit = useCallback(
    async ({ label, type }: { label: string; type: string }) => {
      if (!companyId) return;

      try {
        const priority = onSitePaymentDiscountTypeMaxPriority + 1;
        await addOnSitePaymentDiscountType({
          variables: {
            onSitePaymentDiscountType: {
              companyId,
              label,
              type,
              priority,
              isArchived: false,
            },
          },
        });

        await refetchMaxPriority();
        message.success("作成しました");

        navigate("/onSitePaymentDiscountType", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [
      companyId,
      onSitePaymentDiscountTypeMaxPriority,
      addOnSitePaymentDiscountType,
      navigate,
      refetchMaxPriority,
    ],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <DashboardLayout title="割引方法を新規作成">
      <PageHeader title="割引方法を新規作成" onBack={goBack} />
      <AddOnSitePaymentDiscountTypeForm onSubmit={handleSubmit} loading={loading} />
    </DashboardLayout>
  );
};
