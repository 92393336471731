import React, { memo, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { Col, Row, Upload } from "antd";
import type { ColProps } from "antd/es/col";
import type { RcFile, UploadChangeParam } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import {
  CameraFilled,
  CloseCircleFilled,
  SyncOutlined,
  VideoCameraFilled,
} from "@ant-design/icons";

import { message } from "components/antd/message";
import { FormHelp } from "components/Form/FormHelp";
import { FormLabel } from "components/Form/FormLabel";
import { Loading as Spin } from "components/Loading";
import { colors, grey } from "constants/colors";
import { isUrlMovie } from "libs/detectMediaType";

const zIndex = {
  formImageAction: 1,
  iconRemove: 2,
} as const;

const SELECT_IMAGE_TEXT_DISPLAYABLE_MIN_HEIGHT = 120;

const FormRow = styled(Row)`
  margin-bottom: 24px;

  .ant-form-vertical & {
    flex-direction: column;
  }
`;

const FormLabelCol = styled(Col)`
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  padding-right: 8px;

  .ant-form-vertical & {
    text-align: left;
    padding-right: 0px;
    padding-bottom: 8px;
  }
`;

// NOTE: heightはautoにしないと、antdのcssの影響で縦にはみ出す場合がある
const ImageFrom = styled.div<{ width: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${({ width }) => width}px;
`;

type FormImageContainerProps = { width: number; height: number; autoHeight: boolean };

const FormImageContainer = styled.div<FormImageContainerProps>`
  position: relative;

  ${({ width }) => css`
    max-width: ${width}px;
    min-width: ${width}px;
  `}
  ${({ height, autoHeight }) =>
    !autoHeight &&
    css`
      max-height: ${height}px;
      min-height: ${height}px;
    `}
`;

type FormImageProps = {
  $width: number;
  $height: number;
  objectFitContain?: boolean;
  autoHeight: boolean;
};

const FormImage = styled.img<FormImageProps>`
  object-fit: ${({ objectFitContain }) => (objectFitContain ? "contain" : "cover")};

  ${({ $width }) => css`
    max-width: ${$width}px;
    min-width: ${$width}px;
  `}
  ${({ $height, autoHeight }) =>
    !autoHeight &&
    css`
      max-height: ${$height}px;
      min-height: ${$height}px;
    `}
`;

const FormVideo = FormImage.withComponent("video");

const FormImageDeleteIcon = styled(SyncOutlined)`
  font-size: 48px;
  color: ${grey[0]};
`;

const ChangeImageText = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 12px;
  color: ${grey[0]};
`;

const FormImageAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: ${zIndex.formImageAction};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s;
  content: "";

  &:hover {
    opacity: 1;
  }
`;

const Loading = styled.div<{ width: number; height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px dashed ${colors.Border.Default};
  border-radius: 2px;
  font-size: 32px;
  color: ${grey[5]};

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`;

const UploadDragger = styled(Upload.Dragger)`
  &.ant-upload {
    background-color: #f0f2f5;

    .ant-upload-btn {
      padding: 0px;
    }

    &.ant-upload-drag {
      border: none;
    }
  }

  /* 画像ドラッグ中 & ホバー時 */
  &.ant-upload-drag-hover {
    ${FormImageAction} {
      opacity: 1;
    }
  }
`;

const NoImageContainer = styled.div<{ width: number; height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ width, height }) => css`
    max-width: ${width}px;
    min-width: ${width}px;
    max-height: ${height}px;
    min-height: ${height}px;
  `}
`;

const baseIconStyle = css`
  font-size: 48px;
  color: rgba(0, 0, 0, 0.4);
`;

const IconCamera = styled(CameraFilled)`
  ${baseIconStyle}
`;

const IconVideoCamera = styled(VideoCameraFilled)`
  ${baseIconStyle}
`;

const IconRemove = styled(CloseCircleFilled)`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(50%, -50%);
  font-size: 24px;
  background-color: ${grey[0]};
  border: 2px solid ${grey[0]};
  border-radius: 50%;
  z-index: ${zIndex.iconRemove};
`;

const SelectImageIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const SelectImageText = styled.div`
  margin-top: 16px;
  height: 24px;
  padding: 2px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  color: ${grey[0]};
`;

const SelectImageSubText = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 8px;
  color: rgba(0, 0, 0, 0.4);
`;

const BottomHelpText = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: ${colors.Text.Secondary};
`;

type Props = {
  image: string | null;
  uploadFile: UploadFile<any> | null;
  width?: number;
  height?: number;
  objectFitContain?: boolean;
  wrapperCol?: ColProps;
  isUploading?: boolean;
  disabled?: boolean;
  isMovieAccepted?: boolean;
  isAutoHeight?: boolean;
  onUpload?: (file: UploadFile<any>) => void;
  onRemove?: () => void;
  label?: string;
  helpText?: React.ReactNode;
  labelCol?: ColProps;
  shouldShowDeleteButton?: boolean;
  /**
   * @description ImageForm下部に表示する補足
   */
  bottomHelpText?: string;
};

export const FormImageItem = memo<Props>(
  ({
    image,
    uploadFile,
    width = 180,
    height = 180,
    objectFitContain,
    wrapperCol,
    isUploading,
    disabled = false,
    isMovieAccepted,
    isAutoHeight = false,
    onUpload,
    onRemove,
    label,
    labelCol,
    helpText,
    bottomHelpText,
    shouldShowDeleteButton = true,
  }) => {
    const handleUpload = useCallback(
      ({ fileList }: UploadChangeParam) => {
        const uploadFile = fileList[fileList?.length - 1];
        if (uploadFile) return onUpload?.(uploadFile);
      },
      [onUpload],
    );

    const acceptedTypes = useMemo(
      () => [
        ".jpg",
        "image/jpeg",
        ".png",
        "image/png",
        ...(isMovieAccepted ? [".mp4", "video/mp4"] : []),
      ],
      [isMovieAccepted],
    );

    const beforeUpload = useCallback(
      (file: RcFile) => {
        if (!acceptedTypes.includes(file.type)) {
          message.error("サポートされていないファイル形式です");
          return Upload.LIST_IGNORE;
        }
        return true;
      },
      [acceptedTypes],
    );

    const accept = acceptedTypes.join(",");

    return (
      <FormRow>
        {label && (
          <FormLabelCol {...labelCol}>
            <FormLabel>{helpText ? <FormHelp label={label} help={helpText} /> : label}</FormLabel>
          </FormLabelCol>
        )}
        <Col {...wrapperCol}>
          <ImageFrom width={width}>
            {!isUploading && (
              <UploadDragger
                name="image"
                listType="picture-card"
                fileList={uploadFile ? [uploadFile] : []}
                showUploadList={false}
                onChange={handleUpload}
                accept={accept}
                disabled={disabled}
                beforeUpload={beforeUpload}
              >
                {image && (
                  <FormImageContainer width={width} height={height} autoHeight={isAutoHeight}>
                    {isUrlMovie(image) ? (
                      <FormVideo
                        src={image}
                        $width={width}
                        $height={height}
                        objectFitContain={objectFitContain}
                        autoHeight={isAutoHeight}
                        autoPlay
                        playsInline
                        muted
                        loop
                      />
                    ) : (
                      // NOTE: 拡張子が動画でない場合は画像として表示を試みる
                      <FormImage
                        src={image}
                        $width={width}
                        $height={height}
                        objectFitContain={objectFitContain}
                        autoHeight={isAutoHeight}
                      />
                    )}

                    {!disabled && (
                      <>
                        <FormImageAction>
                          <FormImageDeleteIcon />
                          <ChangeImageText>画像{isMovieAccepted && "・動画"}を変更</ChangeImageText>
                        </FormImageAction>

                        {onRemove && shouldShowDeleteButton && (
                          <IconRemove
                            onClick={(e) => {
                              e.stopPropagation();
                              onRemove();
                            }}
                          />
                        )}
                      </>
                    )}
                  </FormImageContainer>
                )}

                {!image && (
                  <NoImageContainer width={width} height={height}>
                    <SelectImageIconsContainer>
                      <IconCamera />
                      {isMovieAccepted && <IconVideoCamera />}
                    </SelectImageIconsContainer>
                    {height >= SELECT_IMAGE_TEXT_DISPLAYABLE_MIN_HEIGHT && (
                      <>
                        <SelectImageText>+ 画像{isMovieAccepted && "・動画"}を選択</SelectImageText>
                        <SelectImageSubText>またはドラッグ＆ドロップで追加</SelectImageSubText>
                      </>
                    )}
                  </NoImageContainer>
                )}
              </UploadDragger>
            )}
            {isUploading && (
              <Loading width={width} height={height}>
                <Spin />
              </Loading>
            )}
          </ImageFrom>
          {bottomHelpText && <BottomHelpText>{bottomHelpText}</BottomHelpText>}
        </Col>
      </FormRow>
    );
  },
);
