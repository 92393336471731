import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { InputNumber } from "components/Input/InputNumber";
import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import {
  OnSitePaymentDetailType,
  OnSitePaymentDiscountType,
  Table,
} from "pages/AccountingHistory/types";

export type FilterConditions = {
  receiptId?: number;
  onSitePaymentDetailTypes?: string[];
  onSitePaymentDiscountTypes?: string[];
  tableIds?: number[];
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

type Props = {
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  onSitePaymentDiscountTypes: OnSitePaymentDiscountType[];
  tables: Table[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const AccountingHistoryFilter = memo<Props>(
  ({
    onSitePaymentDetailTypes,
    onSitePaymentDiscountTypes,
    tables,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => {
    const onChangeReceiptId = (receiptId: number | string | null) => {
      updateFilterCondition({
        receiptId: receiptId !== null && !Number.isNaN(receiptId) ? Number(receiptId) : undefined,
      });
    };

    return (
      <Wrapper>
        <Col flex="1 0">
          <StyledInputNumber
            controls={false}
            placeholder="レシート番号"
            value={filterConditions.receiptId}
            onChange={onChangeReceiptId}
          />
        </Col>
        <Col flex="1 0">
          <Select<string[]>
            mode="multiple"
            placeholder="支払方法"
            defaultValue={[]}
            value={filterConditions.onSitePaymentDetailTypes ?? []}
            onChange={(onSitePaymentDetailTypes) =>
              updateFilterCondition({ onSitePaymentDetailTypes })
            }
            optionFilterProp="label"
            options={onSitePaymentDetailTypes.map(({ type, label }) => ({ label, value: type }))}
          />
        </Col>
        <Col flex="1 0">
          <Select<string[]>
            mode="multiple"
            placeholder="割引方法"
            defaultValue={[]}
            value={filterConditions.onSitePaymentDiscountTypes ?? []}
            onChange={(onSitePaymentDiscountTypes) =>
              updateFilterCondition({ onSitePaymentDiscountTypes })
            }
            optionFilterProp="label"
            options={onSitePaymentDiscountTypes.map(({ type, label }) => ({ label, value: type }))}
          />
        </Col>
        <Col flex="1 0">
          <Select<number[]>
            mode="multiple"
            placeholder="テーブル番号"
            defaultValue={[]}
            value={filterConditions.tableIds ?? []}
            onChange={(tableIds) => updateFilterCondition({ tableIds })}
            optionFilterProp="label"
            options={tables.map(({ tableId, name }) => ({ label: name, value: tableId }))}
          />
        </Col>
        <Col flex="none">
          <Tooltip title="フィルター条件をクリア">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={hasFilterConditions}
              onClick={clearFilterConditions}
            />
          </Tooltip>
        </Col>
      </Wrapper>
    );
  },
);
