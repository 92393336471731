import React, { memo } from "react";

import { EditMenusBulkFormItem } from "../../useEditMenusBulkForm";

type Props = {
  value: string;
  categoryMenuId: number;
};

export const CategoryNameField = memo<Props>(({ value, categoryMenuId }) => (
  <EditMenusBulkFormItem name={[categoryMenuId, "categoryName"]} initialValue={value} noStyle>
    {value}
  </EditMenusBulkFormItem>
));
