import React, { useCallback, useMemo, useState } from "react";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { CopyDealingDataFormValues } from "./CopyDealingDataForm/useCopyDealingDataForm";
import { CopyDealingDataForm } from "./CopyDealingDataForm";
import {
  useCopyDealingDataCopyDealingDataMutation,
  useCopyDealingDataGetCompanyQuery,
  useCopyDealingDataGetShopsQuery,
} from "./queries";

export const CopyDealingData = () => {
  const [company] = useCompany();

  const [, setFormValues] = useState<CopyDealingDataFormValues | null>(null);

  const { data: sourceShopsData } = useCopyDealingDataGetShopsQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );
  const shops = useMemo(() => sourceShopsData?.shop ?? [], [sourceShopsData?.shop]);

  const { data: companyData } = useCopyDealingDataGetCompanyQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );

  const [copyDealingData, { loading }] = useCopyDealingDataCopyDealingDataMutation({
    onError(error) {
      message.error(error.message ?? "取り扱いデータコピーが失敗しました");
    },
    onCompleted() {
      message.success("取り扱いデータコピーが完了しました");
    },
  });

  const onSubmit = useCallback(
    ({ sourceShopId, targetShopId, enableCopyTable }: CopyDealingDataFormValues) => {
      copyDealingData({
        variables: {
          sourceShopId: sourceShopId ?? "",
          targetShopId: targetShopId ?? "",
          enableCopyTable,
        },
      });
    },
    [copyDealingData],
  );

  return (
    <DashboardLayout title="取り扱いデータコピー">
      <PageHeader title="取り扱いデータコピー" />
      <CopyDealingDataForm
        selectedCompany={companyData?.company?.[0]?.name}
        shops={shops}
        onSubmit={onSubmit}
        canSubmit={!loading}
        onChange={setFormValues}
      />
    </DashboardLayout>
  );
};
