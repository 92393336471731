import React, { memo } from "react";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { ServiceAdmin } from "pages/ServiceAdmins/types";

type Props = {
  loading?: boolean;
  serviceAdmins: ServiceAdmin[];
};

export const ServiceAdminTable = memo<Props>(({ loading, serviceAdmins }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "メールアドレス", dataIndex: "email" },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: unknown, { serviceAdminId }: ServiceAdmin) {
        return (
          <IconLink to={`/serviceAdmin/${serviceAdminId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="serviceAdminId"
      columns={columns}
      dataSource={serviceAdmins}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
