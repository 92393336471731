import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { FormContent } from "components/Template/FormTemplate";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditPlanRoleForm } from "pages/EditShopPlanRoles/EditPlanRoleForm";
import {
  useEditShopPlanRolesGetPlanRolesRolesQuery,
  useEditShopPlanRolesGetRolesQuery,
} from "pages/EditShopPlanRoles/queries";

export const EditShopPlanRoles = () => {
  const { shopId = "", planId: planIdString = "" } =
    useParams<Record<"shopId" | "planId", string>>();
  const planId = Number(planIdString);
  const navigate = useNavigate();

  const {
    data: getPlanRolesRoleData,
    loading: loadingPlan,
    error: getPlanRolesRoleDataError,
  } = useEditShopPlanRolesGetPlanRolesRolesQuery(
    !Number.isNaN(planId) && shopId ? { variables: { planId, shopId } } : { skip: true },
  );
  const plan = getPlanRolesRoleData?.plan?.[0];
  const companyId = plan?.companyId;

  useSetCurrentCompanyEffect(companyId && shopId ? { companyId, shopId } : null);

  const planRoles =
    plan?.shopPlanKitchenRoles?.map(({ role }) => role)?.filter(isNotNullable) ?? [];

  const {
    data: getRolesData,
    loading: loadingRoles,
    error: getRolesDataError,
  } = useEditShopPlanRolesGetRolesQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shouldShowAlert = getPlanRolesRoleDataError || getRolesDataError;

  const roles = getRolesData?.shop_by_pk?.roles;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "プラン" }],
      }}
    >
      <PageHeader title={plan?.planName} onBack={goBack} />
      {(loadingPlan || loadingRoles) && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {plan && roles && (
        <FormContent>
          <EditPlanRoleForm shopId={shopId} plan={plan} planRoles={planRoles} roles={roles} />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
