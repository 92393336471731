import React, { memo } from "react";
import { Switch, Typography } from "antd";

import { ShopPlan } from "../types";

const { Paragraph } = Typography;

export const DealingSwitch = memo<{
  shopId: string;
  shopPlan?: ShopPlan;
  onSelectShopId({ shopId }: { shopId: string }): void;
  onDeselectShopId({ shopId }: { shopId: string }): void;
  disabled?: boolean;
}>(({ shopPlan, shopId, onDeselectShopId, onSelectShopId, disabled = false }) => (
  <Switch
    checked={Boolean(shopPlan)}
    onChange={(value) => (value ? onSelectShopId({ shopId }) : onDeselectShopId({ shopId }))}
    disabled={disabled}
  />
));
