import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const RequirePasswordOnVoidAndTableClearField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="VOID・テーブルクリアのパスワード設定"
        help="有効にするとレジでVOIDとテーブルクリアを実行する際にパスワードを要求します"
      />
    }
    name="requirePasswordOnVoidAndTableClearField"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
