import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OnboardingHandoutGetShops = gql`
    query OnboardingHandoutGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export const OnboardingHandoutUpdateOnboardingHandout = gql`
    mutation OnboardingHandoutUpdateOnboardingHandout($shopId: uuid!, $onboardingHandout: String!) {
  update_shop(
    where: {shopId: {_eq: $shopId}}
    _set: {onboardingHandout: $onboardingHandout}
  ) {
    affected_rows
  }
}
    `;
export type OnboardingHandoutGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type OnboardingHandoutGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);

export type OnboardingHandoutUpdateOnboardingHandoutMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  onboardingHandout: Types.Scalars['String'];
}>;


export type OnboardingHandoutUpdateOnboardingHandoutMutation = (
  { __typename?: 'mutation_root' }
  & { update_shop?: Types.Maybe<(
    { __typename?: 'shop_mutation_response' }
    & Pick<Types.ShopMutationResponse, 'affected_rows'>
  )> }
);


export const OnboardingHandoutGetShopsDocument = gql`
    query OnboardingHandoutGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useOnboardingHandoutGetShopsQuery__
 *
 * To run a query within a React component, call `useOnboardingHandoutGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingHandoutGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingHandoutGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useOnboardingHandoutGetShopsQuery(baseOptions: Apollo.QueryHookOptions<OnboardingHandoutGetShopsQuery, OnboardingHandoutGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingHandoutGetShopsQuery, OnboardingHandoutGetShopsQueryVariables>(OnboardingHandoutGetShopsDocument, options);
      }
export function useOnboardingHandoutGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingHandoutGetShopsQuery, OnboardingHandoutGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingHandoutGetShopsQuery, OnboardingHandoutGetShopsQueryVariables>(OnboardingHandoutGetShopsDocument, options);
        }
export type OnboardingHandoutGetShopsQueryHookResult = ReturnType<typeof useOnboardingHandoutGetShopsQuery>;
export type OnboardingHandoutGetShopsLazyQueryHookResult = ReturnType<typeof useOnboardingHandoutGetShopsLazyQuery>;
export type OnboardingHandoutGetShopsQueryResult = Apollo.QueryResult<OnboardingHandoutGetShopsQuery, OnboardingHandoutGetShopsQueryVariables>;
export const OnboardingHandoutUpdateOnboardingHandoutDocument = gql`
    mutation OnboardingHandoutUpdateOnboardingHandout($shopId: uuid!, $onboardingHandout: String!) {
  update_shop(
    where: {shopId: {_eq: $shopId}}
    _set: {onboardingHandout: $onboardingHandout}
  ) {
    affected_rows
  }
}
    `;
export type OnboardingHandoutUpdateOnboardingHandoutMutationFn = Apollo.MutationFunction<OnboardingHandoutUpdateOnboardingHandoutMutation, OnboardingHandoutUpdateOnboardingHandoutMutationVariables>;

/**
 * __useOnboardingHandoutUpdateOnboardingHandoutMutation__
 *
 * To run a mutation, you first call `useOnboardingHandoutUpdateOnboardingHandoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingHandoutUpdateOnboardingHandoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingHandoutUpdateOnboardingHandoutMutation, { data, loading, error }] = useOnboardingHandoutUpdateOnboardingHandoutMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      onboardingHandout: // value for 'onboardingHandout'
 *   },
 * });
 */
export function useOnboardingHandoutUpdateOnboardingHandoutMutation(baseOptions?: Apollo.MutationHookOptions<OnboardingHandoutUpdateOnboardingHandoutMutation, OnboardingHandoutUpdateOnboardingHandoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardingHandoutUpdateOnboardingHandoutMutation, OnboardingHandoutUpdateOnboardingHandoutMutationVariables>(OnboardingHandoutUpdateOnboardingHandoutDocument, options);
      }
export type OnboardingHandoutUpdateOnboardingHandoutMutationHookResult = ReturnType<typeof useOnboardingHandoutUpdateOnboardingHandoutMutation>;
export type OnboardingHandoutUpdateOnboardingHandoutMutationResult = Apollo.MutationResult<OnboardingHandoutUpdateOnboardingHandoutMutation>;
export type OnboardingHandoutUpdateOnboardingHandoutMutationOptions = Apollo.BaseMutationOptions<OnboardingHandoutUpdateOnboardingHandoutMutation, OnboardingHandoutUpdateOnboardingHandoutMutationVariables>;