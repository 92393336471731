import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const OnSitePaymentDetailsGetDiscountTypes = gql`
    query OnSitePaymentDetailsGetDiscountTypes($shopId: uuid!) {
  onSitePaymentDiscountType(
    where: {company: {shops: {shopId: {_eq: $shopId}}}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
}
    `;
export type OnSitePaymentDetailsGetDiscountTypesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type OnSitePaymentDetailsGetDiscountTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'type' | 'label'>
  )> }
);


export const OnSitePaymentDetailsGetDiscountTypesDocument = gql`
    query OnSitePaymentDetailsGetDiscountTypes($shopId: uuid!) {
  onSitePaymentDiscountType(
    where: {company: {shops: {shopId: {_eq: $shopId}}}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
}
    `;

/**
 * __useOnSitePaymentDetailsGetDiscountTypesQuery__
 *
 * To run a query within a React component, call `useOnSitePaymentDetailsGetDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailsGetDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSitePaymentDetailsGetDiscountTypesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useOnSitePaymentDetailsGetDiscountTypesQuery(baseOptions: Apollo.QueryHookOptions<OnSitePaymentDetailsGetDiscountTypesQuery, OnSitePaymentDetailsGetDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnSitePaymentDetailsGetDiscountTypesQuery, OnSitePaymentDetailsGetDiscountTypesQueryVariables>(OnSitePaymentDetailsGetDiscountTypesDocument, options);
      }
export function useOnSitePaymentDetailsGetDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnSitePaymentDetailsGetDiscountTypesQuery, OnSitePaymentDetailsGetDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnSitePaymentDetailsGetDiscountTypesQuery, OnSitePaymentDetailsGetDiscountTypesQueryVariables>(OnSitePaymentDetailsGetDiscountTypesDocument, options);
        }
export type OnSitePaymentDetailsGetDiscountTypesQueryHookResult = ReturnType<typeof useOnSitePaymentDetailsGetDiscountTypesQuery>;
export type OnSitePaymentDetailsGetDiscountTypesLazyQueryHookResult = ReturnType<typeof useOnSitePaymentDetailsGetDiscountTypesLazyQuery>;
export type OnSitePaymentDetailsGetDiscountTypesQueryResult = Apollo.QueryResult<OnSitePaymentDetailsGetDiscountTypesQuery, OnSitePaymentDetailsGetDiscountTypesQueryVariables>;