import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditRoleGetRole = gql`
    query EditRoleGetRole($roleId: uuid!) {
  role(where: {id: {_eq: $roleId}}, limit: 1) {
    id
    companyId
    name
    roleId
    shopId
    shouldPrintClerkCallSlip
    shouldPrintDishUpSlip
    shouldPrintPaymentReceipt
    removeFreeOrderFromDishUpSlip
    printerSound
    printDishUpSlipAsPrimarySlip
    firstSpareRoleId
    secondSpareRoleId
  }
  onlinePaymentPrinterRoleMeta(where: {printerRoleId: {_eq: $roleId}}) {
    id
    shopId
    printerRoleId
  }
}
    `;
export const EditRoleGetRoles = gql`
    query EditRoleGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      id
      name
      roleId
    }
  }
}
    `;
export const EditRoleUpdateRole = gql`
    mutation EditRoleUpdateRole($roleId: uuid!, $role: role_set_input!) {
  update_role(_set: $role, where: {id: {_eq: $roleId}}) {
    affected_rows
  }
}
    `;
export const EditRoleDeleteOnlinePaymentPrinterRoleMeta = gql`
    mutation EditRoleDeleteOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMetaId: uuid!) {
  delete_onlinePaymentPrinterRoleMeta(
    where: {id: {_eq: $onlinePaymentPrinterRoleMetaId}}
  ) {
    affected_rows
  }
}
    `;
export const EditRoleAddOnlinePaymentPrinterRoleMeta = gql`
    mutation EditRoleAddOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMeta: onlinePaymentPrinterRoleMeta_insert_input!) {
  insert_onlinePaymentPrinterRoleMeta_one(object: $onlinePaymentPrinterRoleMeta) {
    id
  }
}
    `;
export type EditRoleGetRoleQueryVariables = Types.Exact<{
  roleId: Types.Scalars['uuid'];
}>;


export type EditRoleGetRoleQuery = (
  { __typename?: 'query_root' }
  & { role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'id' | 'companyId' | 'name' | 'roleId' | 'shopId' | 'shouldPrintClerkCallSlip' | 'shouldPrintDishUpSlip' | 'shouldPrintPaymentReceipt' | 'removeFreeOrderFromDishUpSlip' | 'printerSound' | 'printDishUpSlipAsPrimarySlip' | 'firstSpareRoleId' | 'secondSpareRoleId'>
  )>, onlinePaymentPrinterRoleMeta: Array<(
    { __typename?: 'onlinePaymentPrinterRoleMeta' }
    & Pick<Types.OnlinePaymentPrinterRoleMeta, 'id' | 'shopId' | 'printerRoleId'>
  )> }
);

export type EditRoleGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditRoleGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )> }
  )> }
);

export type EditRoleUpdateRoleMutationVariables = Types.Exact<{
  roleId: Types.Scalars['uuid'];
  role: Types.RoleSetInput;
}>;


export type EditRoleUpdateRoleMutation = (
  { __typename?: 'mutation_root' }
  & { update_role?: Types.Maybe<(
    { __typename?: 'role_mutation_response' }
    & Pick<Types.RoleMutationResponse, 'affected_rows'>
  )> }
);

export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationVariables = Types.Exact<{
  onlinePaymentPrinterRoleMetaId: Types.Scalars['uuid'];
}>;


export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation = (
  { __typename?: 'mutation_root' }
  & { delete_onlinePaymentPrinterRoleMeta?: Types.Maybe<(
    { __typename?: 'onlinePaymentPrinterRoleMeta_mutation_response' }
    & Pick<Types.OnlinePaymentPrinterRoleMetaMutationResponse, 'affected_rows'>
  )> }
);

export type EditRoleAddOnlinePaymentPrinterRoleMetaMutationVariables = Types.Exact<{
  onlinePaymentPrinterRoleMeta: Types.OnlinePaymentPrinterRoleMetaInsertInput;
}>;


export type EditRoleAddOnlinePaymentPrinterRoleMetaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onlinePaymentPrinterRoleMeta_one?: Types.Maybe<(
    { __typename?: 'onlinePaymentPrinterRoleMeta' }
    & Pick<Types.OnlinePaymentPrinterRoleMeta, 'id'>
  )> }
);


export const EditRoleGetRoleDocument = gql`
    query EditRoleGetRole($roleId: uuid!) {
  role(where: {id: {_eq: $roleId}}, limit: 1) {
    id
    companyId
    name
    roleId
    shopId
    shouldPrintClerkCallSlip
    shouldPrintDishUpSlip
    shouldPrintPaymentReceipt
    removeFreeOrderFromDishUpSlip
    printerSound
    printDishUpSlipAsPrimarySlip
    firstSpareRoleId
    secondSpareRoleId
  }
  onlinePaymentPrinterRoleMeta(where: {printerRoleId: {_eq: $roleId}}) {
    id
    shopId
    printerRoleId
  }
}
    `;

/**
 * __useEditRoleGetRoleQuery__
 *
 * To run a query within a React component, call `useEditRoleGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditRoleGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditRoleGetRoleQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEditRoleGetRoleQuery(baseOptions: Apollo.QueryHookOptions<EditRoleGetRoleQuery, EditRoleGetRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditRoleGetRoleQuery, EditRoleGetRoleQueryVariables>(EditRoleGetRoleDocument, options);
      }
export function useEditRoleGetRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditRoleGetRoleQuery, EditRoleGetRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditRoleGetRoleQuery, EditRoleGetRoleQueryVariables>(EditRoleGetRoleDocument, options);
        }
export type EditRoleGetRoleQueryHookResult = ReturnType<typeof useEditRoleGetRoleQuery>;
export type EditRoleGetRoleLazyQueryHookResult = ReturnType<typeof useEditRoleGetRoleLazyQuery>;
export type EditRoleGetRoleQueryResult = Apollo.QueryResult<EditRoleGetRoleQuery, EditRoleGetRoleQueryVariables>;
export const EditRoleGetRolesDocument = gql`
    query EditRoleGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      id
      name
      roleId
    }
  }
}
    `;

/**
 * __useEditRoleGetRolesQuery__
 *
 * To run a query within a React component, call `useEditRoleGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditRoleGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditRoleGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditRoleGetRolesQuery(baseOptions: Apollo.QueryHookOptions<EditRoleGetRolesQuery, EditRoleGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditRoleGetRolesQuery, EditRoleGetRolesQueryVariables>(EditRoleGetRolesDocument, options);
      }
export function useEditRoleGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditRoleGetRolesQuery, EditRoleGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditRoleGetRolesQuery, EditRoleGetRolesQueryVariables>(EditRoleGetRolesDocument, options);
        }
export type EditRoleGetRolesQueryHookResult = ReturnType<typeof useEditRoleGetRolesQuery>;
export type EditRoleGetRolesLazyQueryHookResult = ReturnType<typeof useEditRoleGetRolesLazyQuery>;
export type EditRoleGetRolesQueryResult = Apollo.QueryResult<EditRoleGetRolesQuery, EditRoleGetRolesQueryVariables>;
export const EditRoleUpdateRoleDocument = gql`
    mutation EditRoleUpdateRole($roleId: uuid!, $role: role_set_input!) {
  update_role(_set: $role, where: {id: {_eq: $roleId}}) {
    affected_rows
  }
}
    `;
export type EditRoleUpdateRoleMutationFn = Apollo.MutationFunction<EditRoleUpdateRoleMutation, EditRoleUpdateRoleMutationVariables>;

/**
 * __useEditRoleUpdateRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleUpdateRoleMutation, { data, loading, error }] = useEditRoleUpdateRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditRoleUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleUpdateRoleMutation, EditRoleUpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleUpdateRoleMutation, EditRoleUpdateRoleMutationVariables>(EditRoleUpdateRoleDocument, options);
      }
export type EditRoleUpdateRoleMutationHookResult = ReturnType<typeof useEditRoleUpdateRoleMutation>;
export type EditRoleUpdateRoleMutationResult = Apollo.MutationResult<EditRoleUpdateRoleMutation>;
export type EditRoleUpdateRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleUpdateRoleMutation, EditRoleUpdateRoleMutationVariables>;
export const EditRoleDeleteOnlinePaymentPrinterRoleMetaDocument = gql`
    mutation EditRoleDeleteOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMetaId: uuid!) {
  delete_onlinePaymentPrinterRoleMeta(
    where: {id: {_eq: $onlinePaymentPrinterRoleMetaId}}
  ) {
    affected_rows
  }
}
    `;
export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationFn = Apollo.MutationFunction<EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation, EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationVariables>;

/**
 * __useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation__
 *
 * To run a mutation, you first call `useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleDeleteOnlinePaymentPrinterRoleMetaMutation, { data, loading, error }] = useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation({
 *   variables: {
 *      onlinePaymentPrinterRoleMetaId: // value for 'onlinePaymentPrinterRoleMetaId'
 *   },
 * });
 */
export function useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation, EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation, EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationVariables>(EditRoleDeleteOnlinePaymentPrinterRoleMetaDocument, options);
      }
export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationHookResult = ReturnType<typeof useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation>;
export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationResult = Apollo.MutationResult<EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation>;
export type EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationOptions = Apollo.BaseMutationOptions<EditRoleDeleteOnlinePaymentPrinterRoleMetaMutation, EditRoleDeleteOnlinePaymentPrinterRoleMetaMutationVariables>;
export const EditRoleAddOnlinePaymentPrinterRoleMetaDocument = gql`
    mutation EditRoleAddOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMeta: onlinePaymentPrinterRoleMeta_insert_input!) {
  insert_onlinePaymentPrinterRoleMeta_one(object: $onlinePaymentPrinterRoleMeta) {
    id
  }
}
    `;
export type EditRoleAddOnlinePaymentPrinterRoleMetaMutationFn = Apollo.MutationFunction<EditRoleAddOnlinePaymentPrinterRoleMetaMutation, EditRoleAddOnlinePaymentPrinterRoleMetaMutationVariables>;

/**
 * __useEditRoleAddOnlinePaymentPrinterRoleMetaMutation__
 *
 * To run a mutation, you first call `useEditRoleAddOnlinePaymentPrinterRoleMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleAddOnlinePaymentPrinterRoleMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleAddOnlinePaymentPrinterRoleMetaMutation, { data, loading, error }] = useEditRoleAddOnlinePaymentPrinterRoleMetaMutation({
 *   variables: {
 *      onlinePaymentPrinterRoleMeta: // value for 'onlinePaymentPrinterRoleMeta'
 *   },
 * });
 */
export function useEditRoleAddOnlinePaymentPrinterRoleMetaMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleAddOnlinePaymentPrinterRoleMetaMutation, EditRoleAddOnlinePaymentPrinterRoleMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditRoleAddOnlinePaymentPrinterRoleMetaMutation, EditRoleAddOnlinePaymentPrinterRoleMetaMutationVariables>(EditRoleAddOnlinePaymentPrinterRoleMetaDocument, options);
      }
export type EditRoleAddOnlinePaymentPrinterRoleMetaMutationHookResult = ReturnType<typeof useEditRoleAddOnlinePaymentPrinterRoleMetaMutation>;
export type EditRoleAddOnlinePaymentPrinterRoleMetaMutationResult = Apollo.MutationResult<EditRoleAddOnlinePaymentPrinterRoleMetaMutation>;
export type EditRoleAddOnlinePaymentPrinterRoleMetaMutationOptions = Apollo.BaseMutationOptions<EditRoleAddOnlinePaymentPrinterRoleMetaMutation, EditRoleAddOnlinePaymentPrinterRoleMetaMutationVariables>;