import React, { memo } from "react";
import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum, TecAggregationIntegrationTypeEnum } from "types/graphql";

type Props = {
  integrationType: TecAggregationIntegrationTypeEnum | null;
} & SubMenuPropsWithoutFeatures;

export const TecAggregationMenu = memo<Props>(({ integrationType, ...props }) => {
  const integrationTypeName = integrationType
    ? getTecAggregationIntegrationTypeName(integrationType)
    : "TECフォーマット連携";

  return (
    <SubMenu
      key="tec"
      title={integrationTypeName}
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      <MenuItem route="tecAggregationMediaMaps" to="/tec/mediaMap" text="掛売等設定" />
      <MenuItem route="tecAggregationDetailTypes" to="/tec/detailType" text="支払方法設定" />
      <MenuItem route="tecUpload" to="/tec/upload" text="連携処理" />
      <MenuItem route="editTecAggregationConfig" to="/tec/config" text="設定" />
      <MenuItem route="tecAggregationMenus" to="/tec/menu" text="メニュー" />
      <MenuItem route="tecPlans" to="/tec/plan" text="プラン" />
      <MenuItem route="tecAggregationGifts" to="/tec/gift" text="推しエール" />
      <MenuItem route="menuCodeCsv" to="/menuCodeCsv/tec" text="メニューコード一括編集" />
    </SubMenu>
  );
});
