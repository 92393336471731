import React, { memo } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import Modal from "antd/lib/modal/Modal";
import Typography from "antd/lib/typography/Typography";
import dayjs from "dayjs";

import { colors } from "constants/colors";
import { getClippedUrl } from "libs/imgix";
import { AccountingSlipImagePositionEnum } from "types/graphql";

import { AccountingSlipImage } from "../types";

import { usePreviewAccountingSlipImageModalGetShopQuery } from "./queries";

type Props = {
  shopId: string;
  goBack: () => void;
};

const scale = 0.5;
const accountingSlipImagePadding = 0 * scale;
const receiptWidth = 420 * scale;
const normalReceiptFontSize = 24 * scale;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled(Typography)`
  margin: 12px;
`;

const StyledCard = styled.div`
  outline: solid 1px ${colors.Border.Default};
  width: ${receiptWidth + 4 * 2}px;
  padding: 0;
  padding: 14px 4px;
`;

const PrinterText = styled.p`
  font-size: ${normalReceiptFontSize}px;
  line-height: ${normalReceiptFontSize}px;
  white-space: pre-wrap;
`;

const PrinterTitleText = styled.p`
  text-align: center;
  font-size: ${normalReceiptFontSize * 2}px;
  line-height: ${normalReceiptFontSize * 2}px;
`;

const PrinterDescriptionText = styled.p`
  text-align: center;
  font-size: ${normalReceiptFontSize}px;
  line-height: ${normalReceiptFontSize}px;
`;

const AccountingSlipImageContainer = styled.div`
  width: ${receiptWidth}px;
`;

const Image = styled.img`
  filter: grayscale(1);
  object-fit: contain;
  margin: ${accountingSlipImagePadding}px;
`;

const Divider = styled.img`
  width: ${receiptWidth - 10}px;
  border-top: 1px solid ${colors.Divider.Default};
  margin: ${normalReceiptFontSize}px 5px;
`;

const PreviewAccountingSlipImage = ({
  image: { imageUrl, description },
}: {
  image: AccountingSlipImage;
}) => {
  const url = getClippedUrl({
    url: imageUrl,
    w: receiptWidth - 2 * accountingSlipImagePadding,
    h: 1000,
  });
  return url ? (
    <AccountingSlipImageContainer>
      <Image src={url} />
      <PrinterDescriptionText>{description}</PrinterDescriptionText>
    </AccountingSlipImageContainer>
  ) : null;
};

export const PreviewAccountingSlipImageModal = memo<Props>(({ goBack, shopId }) => {
  const { data: getShopData, error } = usePreviewAccountingSlipImageModalGetShopQuery({
    variables: { shopId },
  });
  const shop = getShopData?.shop_by_pk;
  const headerImages = shop?.accountingSlipImages?.filter(
    (image) => image.position === AccountingSlipImagePositionEnum.Header,
  );
  const footerImages = shop?.accountingSlipImages?.filter(
    (image) => image.position === AccountingSlipImagePositionEnum.Footer,
  );

  return (
    <Modal
      title="レシートプレビュー"
      visible
      width={600}
      okText="閉じる"
      onOk={goBack}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={goBack}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Container>
        <Description>伝票表示住所、連絡先、レシート画像がプレビュー表示されます</Description>
        {shop ? (
          <StyledCard>
            {(headerImages ?? []).map((image) => (
              <PreviewAccountingSlipImage key={image.accountingSlipImageId} image={image} />
            ))}
            <br />
            <PrinterTitleText>御計算書</PrinterTitleText>
            <PrinterText>
              {shop.issuer ?? ""}
              <br />
              {shop.contactInfo ?? ""}
              <br />
              <br />
              {dayjs().format("YYYY/MM/DD HH:mm:ss")}
              <br />
              <Divider />
              &nbsp;&nbsp;メニュー名
              <br />
              &nbsp;&nbsp;メニュー名
              <br />
              &nbsp;&nbsp;メニュー名
              <br />
              <Divider />
              小計
              <br />
              税
              <br />
              合計
              <br />
              <br />
              カード
              <br />
              お支払い
              <br />
              お釣り
              <br />
              <Divider />
              No. 12345
              <br />
              スタッフ1
              <br />
            </PrinterText>
            {(footerImages ?? []).map((image) => (
              <PreviewAccountingSlipImage key={image.accountingSlipImageId} image={image} />
            ))}
          </StyledCard>
        ) : null}
      </Container>
    </Modal>
  );
});
