import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useShop } from "hooks/useShop";
import { EbicaConfig, EbicaConfigInsertInput } from "types/graphql";

export type EditEbicaConfigFormValues = Pick<EbicaConfig, "walkInStayTimeInMinute" | "ebicaShopId">;

export const EditEbicaConfigFormItem = createFormItem<EditEbicaConfigFormValues>();

export const useEditEbicaConfigForm = ({
  ebicaConfig,
  onSubmit,
}: {
  ebicaConfig: Partial<EbicaConfig>;
  onSubmit: (args: { ebicaConfig: EbicaConfigInsertInput }) => void;
}) => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [form] = Form.useForm();
  const initialValues = useMemo<Partial<EditEbicaConfigFormValues>>(
    () => ({
      walkInStayTimeInMinute: ebicaConfig?.walkInStayTimeInMinute,
      ebicaShopId: ebicaConfig?.ebicaShopId,
    }),
    [ebicaConfig],
  );

  const submit = useCallback(() => {
    if (!shopId) return;
    const values = form.getFieldsValue() as EditEbicaConfigFormValues;
    onSubmit({ ebicaConfig: { ...values, shopId, enabled: true } });
  }, [form, onSubmit, shopId]);

  return { form, initialValues, submit };
};
