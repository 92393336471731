import React, { memo } from "react";
import { Button, Input } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { AddInflowSourceTagFormItem, useAddInflowSourceTagForm } from "./useAddInflowSourceTagForm";

type Props = {
  onSubmit: (args: { displayName: string }) => void;
  loading: boolean;
};

export const AddInflowSourceTagForm = memo<Props>(({ onSubmit, loading }) => {
  const { form, initialValues, submit } = useAddInflowSourceTagForm(onSubmit);

  return (
    <>
      <FormContent>
        <Form name="inflowSourceTag" form={form} layout="vertical" initialValues={initialValues}>
          <AddInflowSourceTagFormItem
            label="名前"
            name="displayName"
            rules={[{ required: true }]}
            wrapperCol={{ span: 16 }}
          >
            <Input />
          </AddInflowSourceTagFormItem>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={submit} loading={loading}>
          作成
        </Button>
      </FormActions>
    </>
  );
});
