import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuRecommendationHeader } from "components/PageHeader/MenuRecommendationHeader";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardAccountRoleTypeEnum } from "types/graphql";

import { EditMenuRecommendationForm } from "./EditMenuRecommendationForm";
import {
  useEditMenuRecommendationGetMenuRecommendationQuery,
  useEditMenuRecommendationUpdateMenuRecommendationMutation,
} from "./queries";
import { MenuRecommendationSetInput } from "./types";

export const EditMenuRecommendation = () => {
  const { menuRecommendationMetaId } = useParams<{ menuRecommendationMetaId: string }>();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const navigate = useNavigate();

  const {
    data: getMenuRecommendationData,
    loading: loadingMenuRecommendation,
    refetch: refetchMenuRecommendation,
    error,
  } = useEditMenuRecommendationGetMenuRecommendationQuery(
    menuRecommendationMetaId ? { variables: { id: menuRecommendationMetaId } } : { skip: true },
  );
  const menuRecommendation = getMenuRecommendationData?.menuRecommendationMeta_by_pk;

  const [updateMenuRecommendationMutation, { loading: loadingUpdateMenuRecommendation }] =
    useEditMenuRecommendationUpdateMenuRecommendationMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (menuRecommendation: MenuRecommendationSetInput) => {
      if (!menuRecommendationMetaId) return;

      try {
        await updateMenuRecommendationMutation({
          variables: { id: menuRecommendationMetaId, menuRecommendation },
        });
        await refetchMenuRecommendation();
        message.success("編集を保存しました");
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [menuRecommendationMetaId, updateMenuRecommendationMutation, goBack, refetchMenuRecommendation],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title="おすすめ"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "おすすめ" }],
      }}
    >
      <MenuRecommendationHeader
        menuRecommendation={menuRecommendation ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loadingMenuRecommendation && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menuRecommendation && (
        <EditMenuRecommendationForm
          menuRecommendation={menuRecommendation}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateMenuRecommendation}
          disabled={!isFeatureEnabled("editMenuRecommendation")}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
