import useEffectOnce from "react-use/esm/useEffectOnce";
import { datadogLogs } from "@datadog/browser-logs";

import { sessionId } from "constants/sessionId";

import pkg from "../../../package.json";

const service = pkg.name;
const version = pkg.version;

export const useDatadogLogs = () => {
  useEffectOnce(() => {
    if (
      import.meta.env.DATADOG_APPLICATION_ID === undefined ||
      import.meta.env.DATADOG_CLIENT_TOKEN === undefined
    ) {
      return;
    }

    datadogLogs.init({
      applicationId: import.meta.env.DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service,
      env: import.meta.env.APP_ENV,
      version,
      sampleRate: 100,
    });

    datadogLogs.addLoggerGlobalContext("sessionId", sessionId);
  });
};
