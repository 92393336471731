import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";

import { DetailTypeTable } from "./DetailTypeTable";
import {
  useOnSitePaymentDetailTypesArchiveMutation,
  useOnSitePaymentDetailTypesGetDetailTypesQuery,
  useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery,
} from "./queries";
import { OnSitePaymentDetailTypeEntityType } from "./types";

export const OnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getDetailTypesData,
    loading: loadingDetailTypes,
    refetch: refetchDetailTypes,
    error,
  } = useOnSitePaymentDetailTypesGetDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDetailTypes = getDetailTypesData?.onSitePaymentDetailType ?? [];

  const { data: getSteraOnSitePaymentDetailTypes, loading: loadingSteraOnSitePaymentDetailTypes } =
    useOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );

  const [archiveMutation] = useOnSitePaymentDetailTypesArchiveMutation();

  const onSitePaymentDetailTypeToSteraOnSitePaymentDetailTypeMap = useMemo(
    () =>
      Object.fromEntries(
        (getSteraOnSitePaymentDetailTypes?.steraOnSitePaymentDetailType ?? []).map(
          (steraOnSitePaymentDetailType) => [
            steraOnSitePaymentDetailType.onSitePaymentDetailTypeEntityType,
            steraOnSitePaymentDetailType,
          ],
        ),
      ),
    [getSteraOnSitePaymentDetailTypes],
  );

  const hasSteraOnSitePaymentDetailType = useCallback(
    (type: OnSitePaymentDetailTypeEntityType) =>
      Boolean(onSitePaymentDetailTypeToSteraOnSitePaymentDetailTypeMap[type]),
    [onSitePaymentDetailTypeToSteraOnSitePaymentDetailTypeMap],
  );

  const onArchive = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await archiveMutation({ variables: { id } });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }
      await refetchDetailTypes();
    },
    [archiveMutation, refetchDetailTypes],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="支払方法一覧">
      <PageHeader
        title="支払方法一覧"
        extra={[
          ...(canAccess("editOnSitePaymentDetailTypes")
            ? [
                <Link key="add" to="/onSitePaymentDetailType/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []),
          ...(canAccess("editOnSitePaymentDetailTypesPriorities")
            ? [
                <Link key="priority" to="/onSitePaymentDetailType/priority/edit">
                  <Button>表示順編集</Button>
                </Link>,
              ]
            : []),
        ]}
      />

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <DetailTypeTable
        detailTypes={onSitePaymentDetailTypes}
        loading={loadingDetailTypes}
        onArchive={onArchive}
        hasSteraOnSitePaymentDetailType={hasSteraOnSitePaymentDetailType}
      />
    </DashboardLayout>
  );
};
