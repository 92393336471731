import React, { memo } from "react";
import { Select } from "antd";
import { taxRates } from "models/taxRate";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { TaxMethodEnum } from "types/graphql";

import { AddMenuFormItem } from "../useAddMenuForm";

const { Option } = Select;

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const TaxRateField = memo<Props>(({ disabled, ...props }) => (
  <AddMenuFormItem.NonProperty
    noStyle
    shouldUpdate={withFormDependencies(({ taxMethod }) => [taxMethod])}
  >
    {({ getFieldValue }) => (
      <AddMenuFormItem label="税率" name="taxRate" required {...props}>
        <Select<string> disabled={disabled}>
          {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
            <Option key={taxRateKey} value={taxRate}>
              {getFieldValue("taxMethod") === TaxMethodEnum.NonTaxable ? 0 : taxRate * 100}%
            </Option>
          ))}
        </Select>
      </AddMenuFormItem>
    )}
  </AddMenuFormItem.NonProperty>
));
