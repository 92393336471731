import React, { memo } from "react";
import { NamePath } from "antd/es/form/interface";

import { FormList } from "components/antd/Form";
import { locales } from "constants/locales";

import { NameField } from "./NameField";
import { OriginalNameField } from "./OriginalNameField";

type Props = {
  originalFieldName: NamePath;
  name?: NamePath;
  label: string;
};

export const TranslationNameFields = memo<Props>(({ name, label, originalFieldName }) => (
  <>
    <OriginalNameField name={originalFieldName} label={`${label}：原文`} />
    <FormList
      name={name ?? "nameSources"}
      initialValue={locales.map(({ code }) => ({ locale: code, value: null }))}
    >
      {(fields) =>
        fields.map((field, ind) => (
          <NameField
            key={field.key}
            name={[field.name, "value"]}
            label={`${label}：${locales?.[ind]?.label}`}
          />
        ))
      }
    </FormList>
  </>
));
