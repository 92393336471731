import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Tag } from "antd";
import Column from "antd/es/table/Column";
import Modal from "antd/lib/modal/Modal";

import { Table } from "components/Table";
import { colors } from "constants/colors";

import { FoodingJournalMasterSource } from "../MasterTable";
import { FoodingJournalDepartmentMaster, FoodingJournalGroupMaster } from "../types";

const ComparisonText = styled.p`
  color: ${colors.Text.Primary};
`;

type RowItem =
  | {
      id: undefined;
      code: string;
      name: string;
    }
  | {
      id: string;
      previousCode: string;
      previousName: string;
      code: string;
      name: string;
    };

type Props = {
  editingFoodingJournalMasters: Record<string, FoodingJournalMasterSource>;
  masters: FoodingJournalDepartmentMaster[] | FoodingJournalGroupMaster[];
  loading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export const ConfirmationModal = memo<Props>(
  ({ editingFoodingJournalMasters, masters, loading, onSubmit, onCancel }: Props) => {
    const dataSource = useMemo(
      () =>
        Object.entries(editingFoodingJournalMasters).map(([id, editingMaster]) => {
          const referenceMaster = masters.find((master) => master.id === id);

          if (!referenceMaster) {
            return {
              ...editingMaster,
              id: undefined,
            };
          }

          return {
            ...editingMaster,
            id,
            previousCode:
              referenceMaster.code !== editingMaster.code ? referenceMaster.code : undefined,
            previousName:
              referenceMaster.name !== editingMaster.name ? referenceMaster.name : undefined,
          };
        }),
      [editingFoodingJournalMasters, masters],
    );

    const onOk = useCallback(async () => {
      await onSubmit();
    }, [onSubmit]);

    return (
      <Modal
        title="変更内容確認"
        visible
        width={3000}
        onCancel={onCancel}
        footer={
          <>
            <Button key="cancel" onClick={onCancel}>
              キャンセル
            </Button>

            <Button
              key="add"
              type="primary"
              onClick={onOk}
              loading={loading}
              disabled={dataSource.length === 0}
            >
              更新
            </Button>
          </>
        }
      >
        <Table bordered dataSource={dataSource}>
          <Column
            title=""
            width={100}
            fixed="left"
            render={(_: string, rowItem: RowItem) =>
              rowItem.id ? (
                <Tag>更新</Tag>
              ) : (
                <Tag color={colors.BackGround.PrimaryDefault}>追加</Tag>
              )
            }
          />

          <Column
            dataIndex="code"
            title="コード"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.id) return <ComparisonText>{rowItem.code}</ComparisonText>;

              if (!rowItem.previousCode) return rowItem.code;

              return (
                <>
                  <ComparisonText> {`${rowItem.previousCode} → ${rowItem.code}`}</ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="name"
            title="名称"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.id) return <ComparisonText>{rowItem.name}</ComparisonText>;

              if (!rowItem.previousName) return rowItem.name;

              return (
                <>
                  <ComparisonText> {`${rowItem.previousName} → ${rowItem.name}`} </ComparisonText>
                </>
              );
            }}
          />
        </Table>
      </Modal>
    );
  },
);
