import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, List, Radio } from "antd";

// eslint-disable-next-line no-restricted-imports
import { Form, FormItem } from "components/antd/Form";
import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TaxMethodEnum } from "types/graphql";

import type { Company, FormValues } from "../types";

import { FormHelp } from "./FormHelp";

const dataSource: { name: keyof FormValues; label: string; help: string }[] = [
  {
    name: "defaultMenuTaxMethod",
    label: "販売価格税種別のデフォルト値",
    help: "メニュー作成画面の税種別デフォルト値。税抜・税込のうち、よく利用する方をデフォルト値として設定いただくと、その税種別が事前に選択されている状態になります。メニュー作成画面でデフォルト値以外の選択も可能です。",
  },
  {
    name: "defaultCostTaxMethod",
    label: "原価税種別",
    help: "メニューの原価税種別。法人全体で税抜・税込どちらかのみ設定できます。変更した場合、過去のメニューの税種別も変更され、集計やBIツールに送信されるデータもその時点から反映されます。",
  },
];

const displayTypeOptions = [
  { label: "税抜", value: TaxMethodEnum.Excluded },
  { label: "税込", value: TaxMethodEnum.Included },
];

type Props = {
  loading: boolean;
  company: Company;
  onSubmit: (formValues: FormValues) => Promise<void>;
};

export const EditTaxMethodForm = memo<Props>(({ loading, company, onSubmit }) => {
  const [isTouched, setIsTouched] = useState(false);

  const [form] = Form.useForm<FormValues>();

  const initialValues = useMemo<FormValues>(
    () => ({
      defaultMenuTaxMethod: company.defaultMenuTaxMethod,
      defaultCostTaxMethod: company.defaultCostTaxMethod,
    }),
    [company],
  );

  const handleSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();

    try {
      await onSubmit(formValues);

      message.success("編集を保存しました");
      setIsTouched(false);
    } catch (err) {
      message.error("編集の保存に失敗しました");
    }
  }, [onSubmit, form]);

  const onFormValueChange = () => {
    setIsTouched(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsTouched(false);
  };

  return (
    <>
      <FormContent>
        <Form
          name="taxMethod"
          form={form}
          initialValues={initialValues}
          onValuesChange={onFormValueChange}
          layout="vertical"
        >
          <List
            loading={{
              spinning: loading,
              indicator: <Loading />,
              size: "large" as const,
            }}
            dataSource={dataSource}
            renderItem={(item) => (
              <li key={item.name}>
                <FormItem name={item.name} label={<FormHelp help={item.help} label={item.label} />}>
                  <Radio.Group options={displayTypeOptions} optionType="button" />
                </FormItem>
              </li>
            )}
          />
        </Form>
      </FormContent>
      <Spacer height={24} />
      <FormActions>
        <Button onClick={handleCancel}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading} disabled={!isTouched}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
