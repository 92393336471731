import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useShop } from "hooks/useShop";
import { DishUpGroupDishUpGroup } from "pages/DishUpSlipGroups/DishUpSlipGroupsDishUpSlipGroups";
import { useDishUpGroupsGetDishUpGroupsQuery } from "pages/DishUpSlipGroups/queries";

export const DishUpSlipGroups = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getDishUpGroupsData,
    loading: loadingDishUpGroups,
    error,
  } = useDishUpGroupsGetDishUpGroupsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const dishUpGroups = getDishUpGroupsData?.shop_by_pk?.dishUpSlipGroups ?? [];
  const { canAccess } = useCanAccess();
  return (
    <DashboardLayout title="デシャップグループ一覧">
      <PageHeader
        title="デシャップグループ一覧"
        extra={
          canAccess("addDishUpSlipGroup")
            ? [
                <Link key="add" to="/dishUpSlipGroup/add">
                  <Button type="primary">新規作成</Button>
                </Link>,
              ]
            : []
        }
        footer={<ShopSelector />}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <DishUpGroupDishUpGroup dishUpGroups={dishUpGroups} loading={loadingDishUpGroups} />
    </DashboardLayout>
  );
};
