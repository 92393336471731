import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DashboardAccountRolePermissions = gql`
    query DashboardAccountRolePermissions($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    id
    role
    dashboardAccountAccessibleShops {
      shopId
    }
    dashboardAccountRole {
      dashboardAccountRolePermissions {
        type
        feature
      }
    }
  }
}
    `;
export type DashboardAccountRolePermissionsQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type DashboardAccountRolePermissionsQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccount: Array<(
    { __typename?: 'dashboardAccount' }
    & Pick<Types.DashboardAccount, 'id' | 'role'>
    & { dashboardAccountAccessibleShops: Array<(
      { __typename?: 'dashboardAccountAccessibleShop' }
      & Pick<Types.DashboardAccountAccessibleShop, 'shopId'>
    )>, dashboardAccountRole: (
      { __typename?: 'dashboardAccountRole' }
      & { dashboardAccountRolePermissions: Array<(
        { __typename?: 'dashboardAccountRolePermission' }
        & Pick<Types.DashboardAccountRolePermission, 'type' | 'feature'>
      )> }
    ) }
  )> }
);


export const DashboardAccountRolePermissionsDocument = gql`
    query DashboardAccountRolePermissions($email: String!) {
  dashboardAccount(where: {email: {_eq: $email}}) {
    id
    role
    dashboardAccountAccessibleShops {
      shopId
    }
    dashboardAccountRole {
      dashboardAccountRolePermissions {
        type
        feature
      }
    }
  }
}
    `;

/**
 * __useDashboardAccountRolePermissionsQuery__
 *
 * To run a query within a React component, call `useDashboardAccountRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAccountRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAccountRolePermissionsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDashboardAccountRolePermissionsQuery(baseOptions: Apollo.QueryHookOptions<DashboardAccountRolePermissionsQuery, DashboardAccountRolePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAccountRolePermissionsQuery, DashboardAccountRolePermissionsQueryVariables>(DashboardAccountRolePermissionsDocument, options);
      }
export function useDashboardAccountRolePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAccountRolePermissionsQuery, DashboardAccountRolePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAccountRolePermissionsQuery, DashboardAccountRolePermissionsQueryVariables>(DashboardAccountRolePermissionsDocument, options);
        }
export type DashboardAccountRolePermissionsQueryHookResult = ReturnType<typeof useDashboardAccountRolePermissionsQuery>;
export type DashboardAccountRolePermissionsLazyQueryHookResult = ReturnType<typeof useDashboardAccountRolePermissionsLazyQuery>;
export type DashboardAccountRolePermissionsQueryResult = Apollo.QueryResult<DashboardAccountRolePermissionsQuery, DashboardAccountRolePermissionsQueryVariables>;