import React, { useCallback } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { OrderableTimeTermDayWeekType } from "models/orderableTime";
import { isNotUndefined } from "util/type/primitive";

import { VerticalDragEventObserver } from "components/VerticalDragEventObserver";

import { DayWeekKeys, HEIGHT_PER_HOUR, Hours } from "./constants";
import { DayWeekSchedulerDecorator } from "./DayWeekSchedulerDecorator";
import { DraggingEventCell } from "./DraggingEventCell";
import { EventCellList } from "./EventCellList";
import { WeeklyEvent } from "./types";
import { DragStateWithDayWeek, useDragState } from "./useDragState";

const DivWithPositionRelative = styled.div`
  position: relative;
`;

const DayWeekColumnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${DayWeekKeys.length}, 1fr);
`;

export const DayWeekScheduler = React.memo(
  ({
    onDeleteEvent,
    onCreateEvent,
    events,
    changeDateTime,
  }: {
    onDeleteEvent: (_: WeeklyEvent) => void;
    onCreateEvent: (_: WeeklyEvent) => void;
    events: WeeklyEvent[];
    changeDateTime: dayjs.Dayjs;
  }) => {
    const { dragState, setDragStateDiscretely, draggingEvent, makeEvent } = useDragState({
      changeDateTime,
      existedEvents: events,
    });

    const emitDraggingEvent = useCallback(
      (state: DragStateWithDayWeek) => {
        const event = makeEvent(state);
        if (!event) return;
        onCreateEvent(event);
      },
      [makeEvent, onCreateEvent],
    );

    const getEventsOnDayWeek = (
      events: (WeeklyEvent | undefined)[],
      dayWeek: OrderableTimeTermDayWeekType,
    ): WeeklyEvent[] => events.filter((e) => e?.dayWeek === dayWeek).filter(isNotUndefined);

    return (
      <DayWeekSchedulerDecorator changeDateTime={changeDateTime}>
        <DayWeekColumnContainer>
          {DayWeekKeys.map((dayWeek) => (
            <VerticalDragEventObserver
              key={dayWeek}
              height={HEIGHT_PER_HOUR * Hours.length}
              dragState={dragState}
              isDraggingTargetEvent={dragState.isDrag && draggingEvent?.dayWeek === dayWeek}
              onSetDragState={(state) => setDragStateDiscretely({ ...state, dayWeek })}
              onDragEnd={(state) => emitDraggingEvent({ ...state, dayWeek })}
            >
              <DivWithPositionRelative>
                <EventCellList
                  events={getEventsOnDayWeek(events, dayWeek)}
                  changeDateTime={changeDateTime}
                  onCreateEvent={onCreateEvent}
                  onDeleteEvent={onDeleteEvent}
                  onSetDragState={(state) => setDragStateDiscretely({ ...state, dayWeek })}
                />
                {dragState.isDrag && draggingEvent?.dayWeek === dayWeek ? (
                  <DraggingEventCell event={draggingEvent} changeDateTime={changeDateTime} />
                ) : (
                  <></>
                )}
              </DivWithPositionRelative>
            </VerticalDragEventObserver>
          ))}
        </DayWeekColumnContainer>
      </DayWeekSchedulerDecorator>
    );
  },
);
