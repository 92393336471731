import React, { memo } from "react";
import { Button } from "antd";

import { EditMenuTecAggregationMenuFormItem } from "../useEditMenuTecAggregationMenuForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const TecAggregationGetAvailableMenuCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed }) => (
    <EditMenuTecAggregationMenuFormItem label=" " name="tecAggregationGetAvailableMenuCdButton">
      <Button onClick={handleGetAvailableMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditMenuTecAggregationMenuFormItem>
  ),
);
