import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddRoleGetRoles = gql`
    query AddRoleGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      name
      roleId
    }
  }
}
    `;
export const AddRoleCreateRole = gql`
    mutation AddRoleCreateRole($roles: [role_insert_input!]!) {
  insert_role(
    objects: $roles
    on_conflict: {constraint: idx_46665_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export const AddRoleCreateOnlinePaymentPrinterRoleMeta = gql`
    mutation AddRoleCreateOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMeta: onlinePaymentPrinterRoleMeta_insert_input!) {
  insert_onlinePaymentPrinterRoleMeta_one(object: $onlinePaymentPrinterRoleMeta) {
    id
  }
}
    `;
export type AddRoleGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddRoleGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'name' | 'roleId'>
    )> }
  )> }
);

export type AddRoleCreateRoleMutationVariables = Types.Exact<{
  roles: Array<Types.RoleInsertInput> | Types.RoleInsertInput;
}>;


export type AddRoleCreateRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_role?: Types.Maybe<(
    { __typename?: 'role_mutation_response' }
    & Pick<Types.RoleMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id'>
    )> }
  )> }
);

export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutationVariables = Types.Exact<{
  onlinePaymentPrinterRoleMeta: Types.OnlinePaymentPrinterRoleMetaInsertInput;
}>;


export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onlinePaymentPrinterRoleMeta_one?: Types.Maybe<(
    { __typename?: 'onlinePaymentPrinterRoleMeta' }
    & Pick<Types.OnlinePaymentPrinterRoleMeta, 'id'>
  )> }
);


export const AddRoleGetRolesDocument = gql`
    query AddRoleGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles(order_by: {roleId: asc}) {
      name
      roleId
    }
  }
}
    `;

/**
 * __useAddRoleGetRolesQuery__
 *
 * To run a query within a React component, call `useAddRoleGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddRoleGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddRoleGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddRoleGetRolesQuery(baseOptions: Apollo.QueryHookOptions<AddRoleGetRolesQuery, AddRoleGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddRoleGetRolesQuery, AddRoleGetRolesQueryVariables>(AddRoleGetRolesDocument, options);
      }
export function useAddRoleGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddRoleGetRolesQuery, AddRoleGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddRoleGetRolesQuery, AddRoleGetRolesQueryVariables>(AddRoleGetRolesDocument, options);
        }
export type AddRoleGetRolesQueryHookResult = ReturnType<typeof useAddRoleGetRolesQuery>;
export type AddRoleGetRolesLazyQueryHookResult = ReturnType<typeof useAddRoleGetRolesLazyQuery>;
export type AddRoleGetRolesQueryResult = Apollo.QueryResult<AddRoleGetRolesQuery, AddRoleGetRolesQueryVariables>;
export const AddRoleCreateRoleDocument = gql`
    mutation AddRoleCreateRole($roles: [role_insert_input!]!) {
  insert_role(
    objects: $roles
    on_conflict: {constraint: idx_46665_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type AddRoleCreateRoleMutationFn = Apollo.MutationFunction<AddRoleCreateRoleMutation, AddRoleCreateRoleMutationVariables>;

/**
 * __useAddRoleCreateRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleCreateRoleMutation, { data, loading, error }] = useAddRoleCreateRoleMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useAddRoleCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleCreateRoleMutation, AddRoleCreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleCreateRoleMutation, AddRoleCreateRoleMutationVariables>(AddRoleCreateRoleDocument, options);
      }
export type AddRoleCreateRoleMutationHookResult = ReturnType<typeof useAddRoleCreateRoleMutation>;
export type AddRoleCreateRoleMutationResult = Apollo.MutationResult<AddRoleCreateRoleMutation>;
export type AddRoleCreateRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleCreateRoleMutation, AddRoleCreateRoleMutationVariables>;
export const AddRoleCreateOnlinePaymentPrinterRoleMetaDocument = gql`
    mutation AddRoleCreateOnlinePaymentPrinterRoleMeta($onlinePaymentPrinterRoleMeta: onlinePaymentPrinterRoleMeta_insert_input!) {
  insert_onlinePaymentPrinterRoleMeta_one(object: $onlinePaymentPrinterRoleMeta) {
    id
  }
}
    `;
export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutationFn = Apollo.MutationFunction<AddRoleCreateOnlinePaymentPrinterRoleMetaMutation, AddRoleCreateOnlinePaymentPrinterRoleMetaMutationVariables>;

/**
 * __useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation__
 *
 * To run a mutation, you first call `useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleCreateOnlinePaymentPrinterRoleMetaMutation, { data, loading, error }] = useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation({
 *   variables: {
 *      onlinePaymentPrinterRoleMeta: // value for 'onlinePaymentPrinterRoleMeta'
 *   },
 * });
 */
export function useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleCreateOnlinePaymentPrinterRoleMetaMutation, AddRoleCreateOnlinePaymentPrinterRoleMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleCreateOnlinePaymentPrinterRoleMetaMutation, AddRoleCreateOnlinePaymentPrinterRoleMetaMutationVariables>(AddRoleCreateOnlinePaymentPrinterRoleMetaDocument, options);
      }
export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutationHookResult = ReturnType<typeof useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation>;
export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutationResult = Apollo.MutationResult<AddRoleCreateOnlinePaymentPrinterRoleMetaMutation>;
export type AddRoleCreateOnlinePaymentPrinterRoleMetaMutationOptions = Apollo.BaseMutationOptions<AddRoleCreateOnlinePaymentPrinterRoleMetaMutation, AddRoleCreateOnlinePaymentPrinterRoleMetaMutationVariables>;