import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanChoiceTecAggregationMenuFormItem } from "../useEditPlanChoiceTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationMenuCodeField = memo<Props>((props) => (
  <EditPlanChoiceTecAggregationMenuFormItem
    label="メニューコード"
    name="menuCode"
    rules={[
      { pattern: /^([-]?[1-9][0-9]*|0)$/, message: "数字で入力してください", required: true },
    ]}
    required
    {...props}
  >
    <InputCode />
  </EditPlanChoiceTecAggregationMenuFormItem>
));
