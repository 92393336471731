import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children"> & { disabled?: boolean; name: string };

export const NameField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanChoiceFormItem {...props}>
    <Input disabled={disabled} />
  </EditPlanChoiceFormItem>
));
