import React, { memo } from "react";

import { Select } from "components/Input/Select";

type Props = {
  options: { value: string; label: string }[];
  values: { value: string; label: string }[];
  onChange: (values: string[]) => void;
};

export const OnSitePaymentDetailTypeField = memo<Props>(({ options, values, onChange }) => (
  <Select
    labelInValue
    mode="multiple"
    value={values}
    options={options.map(({ label, value }) => ({ label, value }))}
    onChange={(selectedValues) => {
      const selectedOnSitePaymentDetailTypes = selectedValues.map(({ value }) => value);
      onChange(selectedOnSitePaymentDetailTypes);
    }}
    fullWidth
  />
));
