import React from "react";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { MembershipRankConfigsTable } from "./MembershipRankConfigTable";
import { useMembershipRankConfigsGetRankConfigsQuery } from "./queries";

export const MembershipRankConfig = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data, error, loading } = useMembershipRankConfigsGetRankConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  return (
    <DashboardLayout title="会員ランク管理">
      <PageHeader title="会員ランク管理" />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <MembershipRankConfigsTable loading={loading} ranks={data?.membershipRankConfig ?? []} />
    </DashboardLayout>
  );
};
