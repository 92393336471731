import React, { useCallback } from "react";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { TecAggregationConfigInsertInput } from "types/graphql";

import { EditTecAggregationConfigForm } from "./EditTecAggregationConfigForm";
import {
  useEditTecAggregationConfigGetShopTecAggregationConfigQuery,
  useEditTecAggregationConfigUpsertTecAggregationConfigMutation,
} from "./queries";

export const EditTecAggregationConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, error, refetch } = useEditTecAggregationConfigGetShopTecAggregationConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const tecAggregationConfig = data?.shop_by_pk?.tecAggregationConfig;

  const [upsertTecAggregationConfigMutation, { loading: submitting }] =
    useEditTecAggregationConfigUpsertTecAggregationConfigMutation();

  const onSubmit = useCallback(
    async (input: Omit<TecAggregationConfigInsertInput, "shopId">) => {
      if (!shopId) return;

      const id = tecAggregationConfig?.id ?? uuidv4();
      const tecAggregationConfigDto = {
        id,
        shopId,
        ...input,
      };

      try {
        await upsertTecAggregationConfigMutation({
          variables: {
            tecAggregationConfig: tecAggregationConfigDto,
          },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }
      await refetch();
    },
    [tecAggregationConfig, shopId, upsertTecAggregationConfigMutation, refetch],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout
      title={
        tecAggregationConfig?.integrationType
          ? getTecAggregationIntegrationTypeName(tecAggregationConfig.integrationType)
          : ""
      }
    >
      <PageHeader title="設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <EditTecAggregationConfigForm
          tecAggregationConfig={tecAggregationConfig}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={submitting}
        />
      )}
    </DashboardLayout>
  );
};
