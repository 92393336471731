import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditDashboardAccountFormItem } from "../useEditDashboardAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PasswordField = memo<Props>((props) => (
  <EditDashboardAccountFormItem
    label="パスワード"
    name="password"
    help="パスワードは英数字を組み合わせ6文字以上にしてください。"
    {...props}
  >
    <Input.Password placeholder="パスワード" disabled />
  </EditDashboardAccountFormItem>
));
