import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanRoleFormItem } from "../useEditPlanRoleForm";

type Props = {
  loading?: boolean;
  name: string;
} & Omit<FormItemProps, "children" | "name">;

export const PlanRoleField = memo<Props>(({ loading, ...props }) => (
  <EditPlanRoleFormItem valuePropName="checked" {...props}>
    <Switch loading={loading} />
  </EditPlanRoleFormItem>
));
