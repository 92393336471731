import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Modal, Row } from "antd";
import { sum } from "util/array";

import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";

import { AverageAndMaxSalesByDayOfWeek } from "../../types";
import { EditSalesBudgetFormItem, EditSalesBudgetFormValues } from "../useEditSalesBudgetForm";

import { AllocationSettingModalFooter } from "./Footer";
import { AllocationSettingModalTable } from "./Table";

const SubTitle = styled.p`
  margin: 0;
`;

const Percentage = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: right;
`;

const CurrentPercentage = styled.span<{ error: boolean }>`
  color: ${({ error }) => (error ? "#ff4d4f" : "inherit")};
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
`;

type Props = {
  averageAndMaxSalesByDayOfWeek: AverageAndMaxSalesByDayOfWeek[];
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  resetAllocationSettingToDefaultValue: () => void;
};

export const AllocationSettingModal = memo<Props>(
  ({
    averageAndMaxSalesByDayOfWeek,
    open,
    onOk,
    onCancel,
    resetAllocationSettingToDefaultValue,
  }) => (
    <Modal
      title="割り振り詳細設定"
      open={open}
      width={1100}
      centered
      onCancel={onCancel}
      footer={<AllocationSettingModalFooter onOk={onOk} onCancel={onCancel} />}
    >
      <SubTitle>合計が100%になるように割り振ってください</SubTitle>
      <Spacer size={12} />
      <AllocationSettingModalTable averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek} />
      <Spacer size={16} />
      <Row align="middle" justify="space-between">
        <Col span="auto">
          <StyledButton type="link" onClick={resetAllocationSettingToDefaultValue}>
            初期設定に戻す
          </StyledButton>
        </Col>
        <Col>
          <EditSalesBudgetFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ allocationSetting }) => [
              allocationSetting["monRate"],
              allocationSetting["tueRate"],
              allocationSetting["wedRate"],
              allocationSetting["thuRate"],
              allocationSetting["friRate"],
              allocationSetting["satRate"],
              allocationSetting["sunRate"],
            ])}
          >
            {({ getFieldValue }) => {
              const allocationSetting = getFieldValue(
                "allocationSetting",
              ) as EditSalesBudgetFormValues["allocationSetting"];
              const total = sum(Object.values(allocationSetting));
              const diff = total - 100;

              return (
                <>
                  <Percentage>
                    <CurrentPercentage error={diff !== 0}>{total}</CurrentPercentage> / 100%
                  </Percentage>
                  {diff !== 0 && (
                    <ErrorMessage>
                      {diff > 0 ? `${diff}% 超過しています` : `${Math.abs(diff)}% 不足しています`}
                    </ErrorMessage>
                  )}
                </>
              );
            }}
          </EditSalesBudgetFormItem.NonProperty>
        </Col>
      </Row>
      <Spacer size={20} />
    </Modal>
  ),
);
