import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditLineOfficialAccountFormItem } from "../useEditLineOfficialAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChannelIdField = memo<Props>(({ ...props }) => (
  <EditLineOfficialAccountFormItem label="チャンネルID" name="channelId" {...props}>
    <Input />
  </EditLineOfficialAccountFormItem>
));
