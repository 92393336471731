import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { Choice, Option, PikaichiMenu, PikaichiMenuChoices } from "../types";

export type EditOptionPikaichiOptionFormValues = {
  option: Pick<Option, "optionId" | "name"> & {
    choices: Array<
      Pick<Choice, "name" | "choiceId"> & {
        pikaichiMenuChoices: Array<
          Pick<PikaichiMenuChoices, "pikaichiMenuId" | "choiceId"> & {
            pikaichiMenu: Pick<
              PikaichiMenu,
              | "pikaichiMenuId"
              | "pikaichiMenuCd"
              | "pikaichiMenuName"
              | "pikaichiBumonCd"
              | "pikaichiBumonName"
              | "pikaichiCategoryCd"
              | "pikaichiCategoryName"
            >;
          }
        >;
      }
    >;
  };
};

export const EditOptionPikaichiOptionFormItem =
  createFormItem<EditOptionPikaichiOptionFormValues>();

export const useEditOptionPikaichiOptionForm = ({
  option,
  onSubmit,
}: {
  option: Option;
  onSubmit: (args: EditOptionPikaichiOptionFormValues) => void;
}) => {
  const [form] = Form.useForm<EditOptionPikaichiOptionFormValues>();

  const initialValues = useMemo<EditOptionPikaichiOptionFormValues>(() => ({ option }), [option]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
