import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Alert, Button, TreeSelect } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useImmutableFn } from "dinii-react-lib/immutability";
import { exportCsv } from "util/exportCsv";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { dayjsToDateString } from "libs/DateString";

import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import {
  useFaveYellAnalyticsGetShopsQuery,
  useGetFaveYellAnalyticsByCorporationQuery,
} from "./queries";
import { SummaryPerStaffTable } from "./StaffTable";
import { SummaryRow } from "./SummaryRow";

const TableTopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  margin: 0;
`;

const StyledTreeSelect = styled(TreeSelect<string[]>)`
  width: 100%;
`;

const FiltersWrapper = styled.div`
  flex-grow: 1;
`;

export const FaveYellAnalytics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;
  const [company] = useCompany();
  const companyId = company?.id;

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >({ range: initialRange, shopIds: null }, undefined, {
    serialize: ({ range, shopIds }) => ({ range: serializeRange(range), shopIds: shopIds ?? null }),
    deserialize: ({ range, shopIds }) => ({
      range: deserializeRange(range),
      shopIds: shopIds ?? null,
    }),
  });
  const updateFilterConditionImmutable = useImmutableFn(updateFilterCondition);

  const range = useMemo(() => {
    const startAt = filterConditions.range?.[0];
    const endAt = filterConditions.range?.[1];
    if (!startAt || !endAt) return null;

    return { startAt: dayjsToDateString(startAt), endAt: dayjsToDateString(endAt) };
  }, [filterConditions.range]);

  const { data: shopsData, loading: shopsLoading } = useFaveYellAnalyticsGetShopsQuery(
    corporationId && range
      ? {
          variables: {
            corporationId,
          },
        }
      : { skip: true },
  );

  const shops = useMemo(
    () => shopsData?.corporation[0]?.companies.flatMap(({ shops }) => shops) ?? [],
    [shopsData],
  );
  const allShopIds = useMemo(() => shops.map(({ shopId }) => shopId), [shops]);

  const [selectedShopIds, setSelectedShopIds] = useState<string[]>(filterConditions.shopIds ?? []);
  const isShopDropdownVisible = useRef<boolean>(false);

  useEffect(() => {
    setSelectedShopIds(allShopIds);
    updateFilterConditionImmutable({ shopIds: allShopIds });
  }, [allShopIds, updateFilterConditionImmutable]);

  const shopTreeData = useMemo(
    () =>
      (
        shopsData?.corporation[0]?.companies
          .filter(({ shops }) => shops.length > 0)
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      ).map((company) => ({
        key: company.id,
        value: company.id,
        title: company.name,
        selectable: true,
        children: company.shops
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((shop) => ({
            key: shop.shopId,
            value: shop.shopId,
            title: shop.name,
            selectable: true,
          })),
      })),
    [shopsData?.corporation],
  );

  const handleShopChange = useCallback(
    (shopIds: string[]) => {
      setSelectedShopIds(shopIds);

      // ドロップダウンが閉じられている時に店舗が削除された場合はフォーカス関係なく更新
      if (isShopDropdownVisible.current === false) {
        updateFilterConditionImmutable({ shopIds });
      }
    },
    [selectedShopIds, updateFilterConditionImmutable],
  );

  const handleBlur = useCallback(() => {
    updateFilterConditionImmutable({ shopIds: selectedShopIds });
  }, [selectedShopIds, updateFilterConditionImmutable]);

  const handleShopDropdownVisibleChange = useCallback((open: boolean) => {
    isShopDropdownVisible.current = open;
  }, []);

  const { data, error, loading } = useGetFaveYellAnalyticsByCorporationQuery(
    corporationId && companyId && range
      ? {
          variables: {
            input: {
              corporationId,
              shopIds: filterConditions.shopIds ?? [],
              startAt: range.startAt,
              endAt: range.endAt,
            },
          },
        }
      : { skip: true },
  );
  const items = useMemo(() => data?.faveYellAnalyticsByCorporation.items ?? [], [data]);
  const summary = data?.faveYellAnalyticsByCorporation.summary?.[0] ?? null;

  const handleExportCsv = useCallback(
    () =>
      exportCsv({
        fileName: "fave_yell_sales",
        columnHeaders: [
          "順位",
          "店舗名",
          "スタッフ名",
          "推しエール人数",
          "推しエール件数",
          "推しエール合計額",
        ],
        rows: items.map((item) => [
          String(item.faveYellSalesRank),
          item.shopName,
          item.staffName,
          String(item.faveYellCustomerCount),
          String(item.faveYellCount),
          String(item.totalTaxIncludedSales),
        ]),
      }),
    [items],
  );

  return (
    <DashboardLayout title="推しエール分析">
      <PageHeader title="推しエール分析" footer={null} />
      <Spacer size={12} />
      <FiltersWrapper>
        <StyledTreeSelect
          treeData={shopTreeData}
          treeDefaultExpandAll
          treeCheckable
          value={selectedShopIds}
          onChange={handleShopChange}
          onBlur={handleBlur}
          onDropdownVisibleChange={handleShopDropdownVisibleChange}
          maxTagCount="responsive"
          treeNodeFilterProp="title"
          allowClear
          placeholder="店舗"
        />
        <Spacer size={12} />
        <Filters
          filterConditions={{
            shopIds: filterConditions.shopIds ?? null,
            range: filterConditions.range,
          }}
          onChangeFilterCondition={updateFilterConditionImmutable}
        />
        <Spacer size={12} />
      </FiltersWrapper>

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <SummaryRow summary={summary} />

      <Spacer size={32} />

      <TableTopBar>
        <StyledTitle level={4}>スタッフ別</StyledTitle>
        <Button type="primary" onClick={handleExportCsv}>
          CSV ダウンロード
        </Button>
      </TableTopBar>
      <Spacer size={12} />
      <SummaryPerStaffTable items={items} />
    </DashboardLayout>
  );
};
