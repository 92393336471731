import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationOnSitePaymentDetailTypesGetDetailTypes = gql`
    query TecAggregationOnSitePaymentDetailTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    tecAggregationOnSitePaymentDetailTypes {
      id
      onSitePaymentDetailTypeEntityType
      mediaCode
      mediaName
      paymentType
      tecAggregationMediaMapId
      tecAggregationMediaMap {
        aggregationNumber
        mediaName
      }
    }
  }
}
    `;
export type TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
    & { tecAggregationOnSitePaymentDetailTypes: Array<(
      { __typename?: 'tecAggregationOnSitePaymentDetailType' }
      & Pick<Types.TecAggregationOnSitePaymentDetailType, 'id' | 'onSitePaymentDetailTypeEntityType' | 'mediaCode' | 'mediaName' | 'paymentType' | 'tecAggregationMediaMapId'>
      & { tecAggregationMediaMap?: Types.Maybe<(
        { __typename?: 'tecAggregationMediaMap' }
        & Pick<Types.TecAggregationMediaMap, 'aggregationNumber' | 'mediaName'>
      )> }
    )> }
  )> }
);


export const TecAggregationOnSitePaymentDetailTypesGetDetailTypesDocument = gql`
    query TecAggregationOnSitePaymentDetailTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    tecAggregationOnSitePaymentDetailTypes {
      id
      onSitePaymentDetailTypeEntityType
      mediaCode
      mediaName
      paymentType
      tecAggregationMediaMapId
      tecAggregationMediaMap {
        aggregationNumber
        mediaName
      }
    }
  }
}
    `;

/**
 * __useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery__
 *
 * To run a query within a React component, call `useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery, TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery, TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables>(TecAggregationOnSitePaymentDetailTypesGetDetailTypesDocument, options);
      }
export function useTecAggregationOnSitePaymentDetailTypesGetDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery, TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery, TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables>(TecAggregationOnSitePaymentDetailTypesGetDetailTypesDocument, options);
        }
export type TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryHookResult = ReturnType<typeof useTecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery>;
export type TecAggregationOnSitePaymentDetailTypesGetDetailTypesLazyQueryHookResult = ReturnType<typeof useTecAggregationOnSitePaymentDetailTypesGetDetailTypesLazyQuery>;
export type TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryResult = Apollo.QueryResult<TecAggregationOnSitePaymentDetailTypesGetDetailTypesQuery, TecAggregationOnSitePaymentDetailTypesGetDetailTypesQueryVariables>;