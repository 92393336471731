import React, { memo } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { grey } from "@ant-design/colors";

import AccountSvg from "assets/account.svg";

const AccountIcon = styled(AccountSvg)`
  width: 32px;
  height: 32px;
  fill: ${grey[5]};
`;

type Props = {
  profileImage: string | undefined;
};

export const AccountAvatar = memo<Props>(({ profileImage }) => {
  if (!profileImage) {
    return <AccountIcon />;
  }

  return <Avatar src={profileImage} />;
});
