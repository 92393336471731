import React, { memo, ReactNode } from "react";
import styled from "styled-components";
import { QRCode, Typography } from "antd";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { CheckInUrlType, getCheckInUrl } from "libs/dinii/link";

import { Table } from "../../types";

type Props = {
  type: CheckInUrlType;
  table: Table;
  shopId?: string;
  liffId?: string;
  visible: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DummyQRCode = styled.div`
  width: 160px;
  height: 160px;
`;

const UrlParagraph = styled(Typography)`
  font-size: 12px;
  color: ${colors.Text.Secondary};
`;

const Cell = memo<{ url: string; children: ReactNode }>(({ url, children }) => (
  <Container>
    {children}
    <Spacer size={8} />
    <UrlParagraph>{url}</UrlParagraph>
  </Container>
));

export const QrCodeTableCell = memo<Props>(({ type, table, shopId, liffId, visible }) => {
  const url = getCheckInUrl(type, table, shopId, liffId);
  if (!url) return null;

  if (!visible) {
    return (
      <Cell url={url}>
        <DummyQRCode />
      </Cell>
    );
  }

  return (
    <Cell url={url}>
      <QRCode value={url} />
    </Cell>
  );
});
