import React, { memo } from "react";

import { Form } from "components/antd/Form";
import {
  AddCookingItemFormValues,
  useAddCookingItemForm,
} from "pages/EditShopMenuOutput/AddMenuCookingItemModal/AddCookingItemForm/useAddCookingItemForm";
import { CookingItem } from "pages/EditShopMenuOutput/AddMenuCookingItemModal/types";
import { ShopMenu } from "pages/EditShopMenuOutput/types";

import { CookingItemsField } from "./CookingItemsField";

type Props = {
  shopMenu: ShopMenu;
  cookingItems: CookingItem[];
  onChange: (plan: AddCookingItemFormValues) => void;
};

export const AddCookingItemForm = memo<Props>(({ shopMenu, cookingItems, onChange }) => {
  const { form, change: onValuesChange } = useAddCookingItemForm(onChange);

  return (
    <Form name="shortSliptItems" form={form} onValuesChange={onValuesChange} layout="vertical">
      <CookingItemsField shopMenu={shopMenu} cookingItems={cookingItems} />
    </Form>
  );
});
