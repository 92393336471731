import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../../useAddMenuForm";
import { CountFieldWrapper } from "../CountFieldWrapper";
import { OrderLimitFieldContainer } from "../OrderLimitFieldContainer";

import { OrderLimitCountPerCustomerField } from "./OrderLimitCountPerCustomerField";

type Props = Omit<FormItemProps, "children" | "name">;
const BoldSpan = styled.span`
  font-weight: bold;
`;

export const OrderLimitPerCustomerField = memo<Props>((props) => {
  const checkboxRef = CountFieldWrapper.useRef();

  return (
    <AddMenuFormItem name="hasOrderLimitPerCustomer" valuePropName="checked" {...props}>
      <Checkbox ref={checkboxRef}>
        <OrderLimitFieldContainer>
          <BoldSpan>来店ごと</BoldSpan>に、<BoldSpan>1人あたり</BoldSpan>
          <CountFieldWrapper checkboxRef={checkboxRef}>
            <OrderLimitCountPerCustomerField />
          </CountFieldWrapper>
          注文できるようにする
        </OrderLimitFieldContainer>
      </Checkbox>
    </AddMenuFormItem>
  );
});
