import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type EditAccountFormValues = {
  current: string;
  new: string;
};

export const EditAccountFormItem = createFormItem<EditAccountFormValues>();

export const useEditAccountForm = () => {
  const [form] = Form.useForm<EditAccountFormValues>();

  const validate = useCallback(async () => {
    const formValues = form.getFieldsValue();
    await form.validateFields();
    const validationResult = form.getFieldsError();
    if (validationResult.some((result) => result.errors.length !== 0)) return;
    return formValues;
  }, [form]);

  return {
    form,
    initialValues: { current: "", new: "" },
    validate,
    reset: form.resetFields,
  };
};
