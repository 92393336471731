import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { BlockPicker } from "components/BlockPicker";

import { EditCategoryFormItem } from "../useEditCategoryForm";

const colors = [
  "#FFFFFF",
  "#FFDBDB",
  "#F8A492",
  "#FDC98C",
  "#FFEF9E",
  "#DBF2AA",
  "#90E6BD",
  "#AEEAF7",
  "#9FCEF9",
  "#CCD1D2",
  "#727272",
];

type Props = {
  shopSideBackgroundColor: string;
  setShopSideBackgroundColor: (ShopDisplayBackgroundColor: string) => void;
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const ShopSideBackgroundColorField = memo<Props>(
  ({ shopSideBackgroundColor, setShopSideBackgroundColor, disabled, ...props }) => (
    <EditCategoryFormItem.NonProperty label="ハンディ表示背景色" {...props}>
      <BlockPicker
        color={shopSideBackgroundColor}
        colors={colors}
        onChange={setShopSideBackgroundColor}
        disabled={disabled}
      />
    </EditCategoryFormItem.NonProperty>
  ),
);
