import React, { memo } from "react";
import { Typography } from "antd";

type Props = {
  number: number;
};

export const TargetCustomers = memo<Props>(({ number }) => (
  <Typography.Title level={5} style={{ margin: 0 }}>
    現時点の対象者数：{number.toLocaleString()}
  </Typography.Title>
));
