import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanHeaderGetBiPlans = gql`
    query PlanHeaderGetBIPlans($planId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  pikaichiMenuPlan(where: {_planId: {_eq: $planId}}) @include(if: $isPikaichiEnabled) {
    pikaichiMenuId
  }
  tecAggregationMenu(where: {_planId: {_eq: $planId}}) @include(if: $isTecAggregationEnabled) {
    id
  }
  winboardMenu(where: {_planId: {_eq: $planId}}) @include(if: $isWinboardEnabled) {
    id
  }
}
    `;
export type PlanHeaderGetBiPlansQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  isWinboardEnabled: Types.Scalars['Boolean'];
  isPikaichiEnabled: Types.Scalars['Boolean'];
  isTecAggregationEnabled: Types.Scalars['Boolean'];
}>;


export type PlanHeaderGetBiPlansQuery = (
  { __typename?: 'query_root' }
  & { pikaichiMenuPlan?: Array<(
    { __typename?: 'pikaichiMenuPlan' }
    & Pick<Types.PikaichiMenuPlan, 'pikaichiMenuId'>
  )>, tecAggregationMenu?: Array<(
    { __typename?: 'tecAggregationMenu' }
    & Pick<Types.TecAggregationMenu, 'id'>
  )>, winboardMenu?: Array<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const PlanHeaderGetBiPlansDocument = gql`
    query PlanHeaderGetBIPlans($planId: Int!, $isWinboardEnabled: Boolean!, $isPikaichiEnabled: Boolean!, $isTecAggregationEnabled: Boolean!) {
  pikaichiMenuPlan(where: {_planId: {_eq: $planId}}) @include(if: $isPikaichiEnabled) {
    pikaichiMenuId
  }
  tecAggregationMenu(where: {_planId: {_eq: $planId}}) @include(if: $isTecAggregationEnabled) {
    id
  }
  winboardMenu(where: {_planId: {_eq: $planId}}) @include(if: $isWinboardEnabled) {
    id
  }
}
    `;

/**
 * __usePlanHeaderGetBiPlansQuery__
 *
 * To run a query within a React component, call `usePlanHeaderGetBiPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanHeaderGetBiPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanHeaderGetBiPlansQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      isWinboardEnabled: // value for 'isWinboardEnabled'
 *      isPikaichiEnabled: // value for 'isPikaichiEnabled'
 *      isTecAggregationEnabled: // value for 'isTecAggregationEnabled'
 *   },
 * });
 */
export function usePlanHeaderGetBiPlansQuery(baseOptions: Apollo.QueryHookOptions<PlanHeaderGetBiPlansQuery, PlanHeaderGetBiPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanHeaderGetBiPlansQuery, PlanHeaderGetBiPlansQueryVariables>(PlanHeaderGetBiPlansDocument, options);
      }
export function usePlanHeaderGetBiPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanHeaderGetBiPlansQuery, PlanHeaderGetBiPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanHeaderGetBiPlansQuery, PlanHeaderGetBiPlansQueryVariables>(PlanHeaderGetBiPlansDocument, options);
        }
export type PlanHeaderGetBiPlansQueryHookResult = ReturnType<typeof usePlanHeaderGetBiPlansQuery>;
export type PlanHeaderGetBiPlansLazyQueryHookResult = ReturnType<typeof usePlanHeaderGetBiPlansLazyQuery>;
export type PlanHeaderGetBiPlansQueryResult = Apollo.QueryResult<PlanHeaderGetBiPlansQuery, PlanHeaderGetBiPlansQueryVariables>;