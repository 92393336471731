import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { Menu } from "../types";

import { NameField } from "./NameField";
import { TecAggregationDpCodeField } from "./TecAggregationDpCodeField";
import { TecAggregationDpNameField } from "./TecAggregationDpNameField";
import { TecAggregationGpCodeField } from "./TecAggregationGpCodeField";
import { TecAggregationGpNameField } from "./TecAggregationGpNameField";
import { TecAggregationMenuCodeField } from "./TecAggregationMenuCodeField";
import { useEditTecAggregationMenuForm } from "./useEditTecAggregationMenuForm";

type Props = {
  menu: Menu;
  onSubmit: (
    args: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
  onClose: () => void;
  onAutoAdoptMenuCodeButtonPressed: () => string;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditTecAggregationMenuForm = memo<Props>(
  ({
    menu,
    onClose,
    onSubmit,
    onAutoAdoptMenuCodeButtonPressed,
    loading,
    onFormValidationError,
  }) => {
    const tecAggregationMenu = menu?.tecAggregationMenu ?? undefined;

    const { form, initialValues, submit } = useEditTecAggregationMenuForm({
      menu,
      tecAggregationMenu,
      onSubmit,
    });

    const handleAutoAdoptMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onAutoAdoptMenuCodeButtonPressed();
      form.setFields([{ name: "menuCode", value: maxMenuCode }]);
    }, [form, onAutoAdoptMenuCodeButtonPressed]);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    return (
      <>
        <FormContent>
          <Form
            name="tecAggregationMenu"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <TecAggregationMenuCodeField />
            <NameField />
            <TecAggregationDpCodeField />
            <TecAggregationDpNameField />
            <TecAggregationGpCodeField />
            <TecAggregationGpNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
