import React, { memo, useCallback, useEffect, useState } from "react";

import { Form } from "components/antd/Form";
import { ImageField } from "components/Form/ImageField";
import { AccountingSlipImagePositionEnum } from "types/graphql";

import { DescriptionField } from "./DescriptionField";
import {
  AddAccountingSlipImageFormValues,
  useAddAccountingSlipImageForm,
} from "./useAddAccountingSlipImageForm";

type Props = {
  onChange: (input: AddAccountingSlipImageFormValues) => void;
  position: AccountingSlipImagePositionEnum;
};

export const AddAccountingSlipImageForm = memo<Props>(({ onChange, position }) => {
  const {
    form,
    initialValues,
    onValuesChange: onFormChange,
  } = useAddAccountingSlipImageForm({
    onChange,
  });

  const [uploadImage, setUploadImage] = useState<string | null>(null);

  const onValuesChange = useCallback(() => {
    onFormChange({ imageUrl: uploadImage });
  }, [uploadImage, onFormChange]);

  useEffect(() => {
    onFormChange({ imageUrl: uploadImage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImage]);

  return (
    <Form
      name="shop"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <ImageField
        image={uploadImage}
        setUploadImage={setUploadImage}
        uploadImageApiKey="accountingSlipImage"
        formName="AddAccountingSlipImageForm"
        label="レシート画像"
        objectFitContain
        shouldShowDeleteButton={false}
      />
      {position === AccountingSlipImagePositionEnum.Footer && <DescriptionField />}
    </Form>
  );
});
