import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditMenuWinboardMenuFormItem } from "../useEditMenuWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const WinboardCodeField = memo<Props>((props) => (
  <EditMenuWinboardMenuFormItem label="メニューコード" name="code" {...props}>
    <InputCode />
  </EditMenuWinboardMenuFormItem>
));
