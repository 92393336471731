import React, { memo } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { getAvailablePeriod } from "models/coupon";

import NoImageIcon from "assets/no_image.svg";
import { Spacer } from "components/Spacer";
import { colors, grey } from "constants/colors";
import { getCroppedUrl } from "libs/imgix";

import { CouponMessageFormValue } from "../../types";
import { useMessageDeliveryFormRoomMessageGetCouponQuery } from "../queries";

const Wrapper = styled.div`
  border-radius: 15px;
  background-color: ${grey[0]};
  overflow: hidden;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 144px;
`;

const NoImageIconWrapper = styled.div`
  width: 100%;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey[3]};
`;

const Content = styled.div`
  padding: 10px 12px;
`;

const TextTitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.Text.Default};
`;

const TextContent = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.Text.Secondary};
  white-space: pre-wrap;
`;

const TextDescription = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.Text.Secondary};
  white-space: pre-wrap;
`;

const TextAvailableDate = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.Text.Secondary};
`;

type Props = {
  message: CouponMessageFormValue;
};

export const CouponMessage = memo(({ message }: Props) => {
  const { data, error } = useMessageDeliveryFormRoomMessageGetCouponQuery({
    variables: { id: message.couponId },
  });
  const coupon = data?.coupon_by_pk;

  if (!coupon) {
    return (
      <div>
        {error ? (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        ) : null}
      </div>
    );
  }

  const croppedImageUrl = getCroppedUrl({ url: coupon.imageUrl, w: 216, h: 144 });

  return (
    <Wrapper>
      {croppedImageUrl ? (
        <Image src={croppedImageUrl} />
      ) : (
        <NoImageIconWrapper>
          <NoImageIcon />
        </NoImageIconWrapper>
      )}

      <Content>
        <TextTitle>{coupon.title}</TextTitle>
        <Spacer height={4} />
        <TextContent>{coupon.content}</TextContent>
        <Spacer height={8} />
        <TextDescription>{coupon.description}</TextDescription>
        <Spacer height={4} />
        <TextAvailableDate>
          有効期間:{" "}
          {getAvailablePeriod({
            now: new Date(),
            availableFrom: coupon.availableFrom,
            availableUntil: coupon.availableUntil,
            availableDays: coupon.availableDays,
          })}
        </TextAvailableDate>
      </Content>
    </Wrapper>
  );
});
