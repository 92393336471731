import { SteraPaymentMethodTypeEnum } from "types/graphql";

export const steraPaymentMethodTypeDetails: {
  [K in SteraPaymentMethodTypeEnum]: { label: string; priority: number };
} = {
  creditCard: { label: "クレジット", priority: 1 },
  threeWayCreditCard: { label: "3面待ちクレジット", priority: 2 },
  prepaidTransportationCard: { label: "交通系IC", priority: 3 },
  QR: { label: "QRコード", priority: 4 },
  QUICPay: { label: "QUICPay", priority: 5 },
  Edy: { label: "楽天Edy", priority: 6 },
  WAON: { label: "WAON", priority: 7 },
  NFC: { label: "NFC", priority: 8 },
  PiTaPa: { label: "PiTaPa", priority: 9 },
  nanaco: { label: "nanaco", priority: 10 },
  iD: { label: "iD", priority: 11 },
  unionPay: { label: "銀聯", priority: 12 },
};
