import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { ShopOptionKitchenRole } from "./types";

export type EditKitchenRolesFormValues = { kitchenRoles: string[] };

const getInitialValues = (shopOptionKitchenRoles: ShopOptionKitchenRole[]) => ({
  kitchenRoles: shopOptionKitchenRoles.map(({ kitchenRoleId }) => kitchenRoleId),
});

export const EditKitchenRolesFormItem = createFormItem<EditKitchenRolesFormValues>();

type Props = {
  shopOptionKitchenRoles: ShopOptionKitchenRole[];
  onChange: (kitchenRoles: EditKitchenRolesFormValues) => void;
};

export const useEditKitchenRolesForm = ({ shopOptionKitchenRoles, onChange }: Props) => {
  const [form] = Form.useForm<EditKitchenRolesFormValues>();
  const initialValues = getInitialValues(shopOptionKitchenRoles);

  const change = useCallback(
    (values: EditKitchenRolesFormValues) => {
      onChange(values);
    },
    [onChange],
  );

  return { form, initialValues, change };
};
