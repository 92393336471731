import React, { memo } from "react";
import { Popconfirm, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { InflowSourceTag } from "../types";

type Props = {
  loading?: boolean;
  inflowSourceTags: InflowSourceTag[];
  onArchive: ({ id }: { id: string }) => void;
};

export const InflowSourceTagTable = memo<Props>(({ loading, inflowSourceTags, onArchive }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns: ColumnsType<InflowSourceTag> = [
    {
      title: "媒体",
      render(_: string, { displayName }: InflowSourceTag) {
        return displayName;
      },
    },
    ...(canAccess("editInflowSourceTags")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id, isWalkIn }: InflowSourceTag) {
              if (isWalkIn) return null;

              return (
                <Tooltip title="媒体を編集">
                  <IconLink to={`/inflowSourceTag/${id}/edit`}>
                    <EditIcon />
                  </IconLink>
                </Tooltip>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("deleteInflowSourceTags")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id, isWalkIn }: InflowSourceTag) {
              if (isWalkIn) return null;

              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>媒体を削除しますか？</Paragraph>
                      <Paragraph>レジを再起動すると媒体が表示されなくなります。</Paragraph>
                      <Paragraph>一度削除した媒体を元に戻すことはできません。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => onArchive({ id })}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={inflowSourceTags}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
