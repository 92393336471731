import React, { memo } from "react";
import { Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { PaymentHistoryType } from "..";

type Props = {
  loading: boolean;
  paymentHistories: PaymentHistoryType[];
};

const createColumns = (): ColumnsType<PaymentHistoryType> => [
  {
    title: "取引ID",
    align: "center",
    width: 100,
    dataIndex: "pspReference",
  },
  {
    title: "日時",
    align: "center",
    width: 100,
    render(_: string, { createdAt }) {
      return dayjs(createdAt).format("HH:mm");
    },
  },
  {
    title: "金額",
    align: "center",
    width: 100,
    render(_: string, { amount }) {
      return formatPrice(amount);
    },
  },
  {
    title: "支払方法",
    align: "center",
    width: 100,
    render(_: string, { paymentMethod }) {
      return paymentMethod;
    },
  },
  {
    title: "取り消し",
    align: "center",
    width: 100,
    render(_: string, { adyenPaymentReportTerminalPaymentCanceledEvent }) {
      return adyenPaymentReportTerminalPaymentCanceledEvent ? (
        <Tag color="red">取り消し済み</Tag>
      ) : (
        ""
      );
    },
  },
];

export const AdyenTerminalPaymentHistoryTable = memo<Props>(({ loading, paymentHistories }) => {
  const [pagination, setPagination] = usePagination();

  const columns = createColumns();

  return (
    <Table
      rowKey="pspReference"
      columns={columns}
      dataSource={paymentHistories}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
