import React, { memo, useCallback } from "react";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { IsDealingField } from "../EditShopPlansBulkFields/IsDealingField";
import { IsSoldOutField } from "../EditShopPlansBulkFields/IsSoldOutField";
import { IsVisibleForCustomerField } from "../EditShopPlansBulkFields/IsVisibleForCustomerField";
import { IsVisibleForStaffField } from "../EditShopPlansBulkFields/IsVisibleForStaffField";

export type FilterConditions = {
  name: string | null;
  isDealing: boolean | null;
  isVisibleForCustomer: boolean | null;
  isVisibleForStaff: boolean | null;
  isSoldOut: boolean | null;
};

type Props = {
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const PlanFilter = memo<Props>(
  ({ hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions }) => {
    const handleUpdateFilterCondition = useCallback<
      UpdateFilterConditionFunctionType<FilterConditions>
    >((values) => updateFilterCondition(values), [updateFilterCondition]);

    return (
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <Input
            placeholder="プラン名"
            value={filterConditions.name ?? undefined}
            onChange={(e) => handleUpdateFilterCondition({ name: e.target.value })}
          />
        </Col>
        <Col flex="1 0">
          <IsDealingField
            value={filterConditions.isDealing}
            onChange={(isDealing) => handleUpdateFilterCondition({ isDealing })}
          />
        </Col>
        <Col flex="1 0">
          <IsVisibleForCustomerField
            value={filterConditions.isVisibleForCustomer}
            onChange={(isVisibleForCustomer) =>
              handleUpdateFilterCondition({ isVisibleForCustomer })
            }
          />
        </Col>
        <Col flex="1 0">
          <IsVisibleForStaffField
            value={filterConditions.isVisibleForStaff}
            onChange={(isVisibleForStaff) => handleUpdateFilterCondition({ isVisibleForStaff })}
          />
        </Col>
        <Col flex="1 0">
          <IsSoldOutField
            value={filterConditions.isSoldOut}
            onChange={(isSoldOut) => handleUpdateFilterCondition({ isSoldOut })}
          />
        </Col>
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Row>
    );
  },
);
