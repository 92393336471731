import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { isNumber } from "util/type/primitive";

import { LineOfficialAccountSelector } from "components/LineOfficialAccountSelector";
import { ConsumptionDetails } from "pages/MessageDeliveries/MessageDeliveryConsumption/ConsumptionDetails";

import { useMessageDeliveryConsumptionGetShopLineOfficialAccountLazyQuery } from "./queries";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
`;

const Title = styled(Typography.Text)`
  font-size: 20px;
  font-weight: bold;
`;

export const MessageDeliveryConsumption = memo(() => {
  const [getDashboardAccountGetMessageDeliveryConsumption, { data }] =
    useMessageDeliveryConsumptionGetShopLineOfficialAccountLazyQuery();

  const onSelectLineOfficialAccount = useCallback(
    async (lineOfficialAccountId: number) => {
      await getDashboardAccountGetMessageDeliveryConsumption({
        variables: {
          lineOfficialAccountId,
        },
      });
    },
    [getDashboardAccountGetMessageDeliveryConsumption],
  );

  const limit = data?.dashboardAccountGetMessageDeliveryConsumption.messageDeliveryLimit;
  const consumption =
    data?.dashboardAccountGetMessageDeliveryConsumption.messageDeliveryConsumption;

  return (
    <Wrapper>
      <Title>利用状況</Title>
      <LineOfficialAccountSelector onSelectLineOfficialAccount={onSelectLineOfficialAccount} />
      <Content>
        {!data ? (
          <>LINE アカウントが選択されていません</>
        ) : !isNumber(limit) || !isNumber(consumption) ? (
          <>店舗に LINE OA アカウントが設定されていません</>
        ) : (
          <ConsumptionDetails limit={limit} consumption={consumption} />
        )}
      </Content>
    </Wrapper>
  );
});
