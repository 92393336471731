import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { WinboardMenuInsertInput } from "types/graphql";

import { EditPlanWinboardPlanFormValues } from "./EditPlanWinboardPlanForm/useEditPlanWinboardPlanForm";
import { EditPlanWinboardPlanForm } from "./EditPlanWinboardPlanForm";
import {
  EditPlanWinboardPlanGetPlanDocument,
  useEditPlanWinboardPlanGetCodeQuery,
  useEditPlanWinboardPlanGetPlanQuery,
  useEditPlanWinboardPlanUpdateWinboardMenuMutation,
} from "./queries";

export const EditPlanWinboardPlan = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const [company] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    loading: loadingPlan,
    error: errorGetPlan,
  } = useEditPlanWinboardPlanGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];

  const winboardMenu = plan?.winboardMenus?.[0];

  const companyId = plan?.companyId;

  const {
    data: getWinboardMenuCodesData,
    loading: loadingGetWinboardMenuCodes,
    error: errorGetWinboardMenuCodes,
  } = useEditPlanWinboardPlanGetCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const [updateWinboardMenuMutation, { loading: loadingUpdateWinboardMenu }] =
    useEditPlanWinboardPlanUpdateWinboardMenuMutation();

  const onSubmit = useCallback(
    async (input: EditPlanWinboardPlanFormValues) => {
      if (!company || !plan) return;

      const id = winboardMenu?.id ?? uuidv4();
      const winboardMenuDto: WinboardMenuInsertInput = {
        id,
        planId: plan.id,
        _planId: plan.planId,
        companyId: company.id,
        name: input.name,
        code: input.code,
        bumonCode: input.bumonCode,
        bumonName: input.bumonName,
        categoryCode: input.categoryCode,
        categoryName: input.categoryName,
      };

      const winboardMenuPlanChoicesDto =
        input.plan?.planOptions.flatMap((option) =>
          option.planChoices.flatMap((planChoice) =>
            planChoice.winboardMenus.flatMap((v) => {
              const id = v.id ?? uuidv4();

              const menu: WinboardMenuInsertInput = {
                id,
                _planId: planId,
                _planChoiceId: planChoice.planChoiceId,
                companyId: company.id,
                name: v.name,
                code: v.code,
                bumonCode: v.bumonCode,
                bumonName: v.bumonName,
                categoryCode: v.categoryCode,
                categoryName: v.categoryName,
              };

              return menu;
            }),
          ),
        ) ?? [];

      try {
        await updateWinboardMenuMutation({
          variables: {
            winboardMenu: winboardMenuDto,
            winboardMenuPlanChoices: winboardMenuPlanChoicesDto,
          },
          refetchQueries: [
            {
              query: EditPlanWinboardPlanGetPlanDocument,
              variables: { planId },
            },
          ],
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [company, plan, winboardMenu?.id, planId, updateWinboardMenuMutation],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingPlan || loadingGetWinboardMenuCodes;

  const getAvailableMenuCode = useCallback(() => {
    const menuCodes = (getWinboardMenuCodesData?.winboardMenu ?? [])
      .map((c) => (!isNaN(Number(c.code)) ? Number(c.code) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? Math.max(...menuCodes) + 1 : 1;
  }, [getWinboardMenuCodesData?.winboardMenu]);

  const shouldShowNetworkErrorAlert = Boolean(errorGetPlan || errorGetWinboardMenuCodes);
  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ウィンボードプラン" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={shouldShowNetworkErrorAlert}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loading && <Loading height={300} />}
      {plan && (
        <EditPlanWinboardPlanForm
          plan={plan}
          onSubmit={onSubmit}
          onClose={goBack}
          onGetAvailableMenuCodeButtonPressed={getAvailableMenuCode}
          loading={loadingUpdateWinboardMenu}
        />
      )}
    </DashboardLayout>
  );
};
