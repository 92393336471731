import React, { useCallback } from "react";
import styled from "styled-components";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { UnreachableError } from "libs/unreachable";

import { FoodingJournalMasterSource, MasterTable } from "./MasterTable";
import {
  useFoodingJournalMastersDeleteDepartmentMasterMutation,
  useFoodingJournalMastersDeleteGroupMasterMutation,
  useFoodingJournalMastersGetMastersQuery,
  useFoodingJournalMastersUpsertDepartmentMasterMutation,
  useFoodingJournalMastersUpsertGroupMasterMutation,
} from "./queries";

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export type FoodingJournalMasterType = "department" | "group";

export type EditingFoodingJournalMenu = {
  code?: string;
  name?: string;
  foodingJournalDepartmentId?: string;
  foodingJournalGroupId?: string;
};

export const FoodingJournalMasters = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMastersData,
    loading: loadingGetMasters,
    error: getMastersError,
    refetch: refetchMastersData,
  } = useFoodingJournalMastersGetMastersQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const foodingJournalDepartmentMasters = getMastersData?.foodingJournalDepartmentMaster ?? [];
  const foodingJournalGroupMasters = getMastersData?.foodingJournalGroupMaster ?? [];

  const [
    upsertDepartmentMasters,
    { loading: loadingUpsertDepartmentMasters, error: upsertDepartmentMastersError },
  ] = useFoodingJournalMastersUpsertDepartmentMasterMutation();
  const [
    upsertGroupMasters,
    { loading: loadingUpsertGroupMasters, error: upsertGroupMastersError },
  ] = useFoodingJournalMastersUpsertGroupMasterMutation();

  const [
    deleteDepartmentMaster,
    { loading: loadingDeleteDepartmentMaster, error: deleteDepartmentMasterError },
  ] = useFoodingJournalMastersDeleteDepartmentMasterMutation();
  const [deleteGroupMaster, { loading: loadingDeleteGroupMaster, error: deleteGroupMasterError }] =
    useFoodingJournalMastersDeleteGroupMasterMutation();

  const refetchMasters = useCallback(
    async ({ type }: { type: FoodingJournalMasterType }) => {
      if (!companyId) return { masters: [] };

      const result = await refetchMastersData({ companyId });

      return {
        masters:
          type === "department"
            ? result.data.foodingJournalDepartmentMaster
            : result.data.foodingJournalGroupMaster,
      };
    },
    [companyId, refetchMastersData],
  );

  const upsertMasters = useCallback(
    async ({
      masterSources,
      type,
    }: {
      masterSources: FoodingJournalMasterSource[];
      type: FoodingJournalMasterType;
    }) => {
      if (!companyId) return;

      try {
        switch (type) {
          case "department":
            await upsertDepartmentMasters({
              variables: {
                input: masterSources.map(({ foodingJournalMenus, ...source }) => ({
                  companyId,
                  ...source,
                })),
              },
            });

            break;

          case "group":
            await upsertGroupMasters({
              variables: {
                input: masterSources.map(({ foodingJournalMenus, ...source }) => ({
                  companyId,
                  ...source,
                })),
              },
            });

            break;

          default:
            throw new UnreachableError(type);
        }

        message.success("更新に成功しました");
      } catch {
        message.error("更新に失敗しました");
      }
    },
    [companyId, upsertDepartmentMasters, upsertGroupMasters],
  );

  const deleteMaster = useCallback(
    async ({ id, type }: { id: string; type: FoodingJournalMasterType }) => {
      try {
        switch (type) {
          case "department":
            await deleteDepartmentMaster({ variables: { foodingJournalDepartmentId: id } });

            break;

          case "group":
            await deleteGroupMaster({ variables: { foodingJournalGroupId: id } });

            break;

          default:
            break;
        }

        message.success("削除に成功しました");
      } catch {
        message.error("削除に失敗しました");
      }
    },
    [deleteDepartmentMaster, deleteGroupMaster],
  );

  const shouldShowAlert =
    getMastersError ||
    upsertDepartmentMastersError ||
    upsertGroupMastersError ||
    deleteDepartmentMasterError ||
    deleteGroupMasterError;

  const loading =
    loadingGetMasters ||
    loadingUpsertDepartmentMasters ||
    loadingUpsertGroupMasters ||
    loadingDeleteDepartmentMaster ||
    loadingDeleteGroupMaster;

  return (
    <DashboardLayout title="部門・分類設定一覧">
      <PageHeader title="部門・分類一覧" />

      {shouldShowAlert && (
        <>
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />

          <Spacer height={20} />
        </>
      )}

      <TablesContainer>
        <MasterTable
          type="department"
          foodingJournalMasters={foodingJournalDepartmentMasters}
          upsertMasters={upsertMasters}
          deleteMaster={deleteMaster}
          refetchMasters={refetchMasters}
          loading={loading}
        />

        <Spacer width={80} />

        <MasterTable
          type="group"
          foodingJournalMasters={foodingJournalGroupMasters}
          upsertMasters={upsertMasters}
          deleteMaster={deleteMaster}
          refetchMasters={refetchMasters}
          loading={loading}
        />
      </TablesContainer>
    </DashboardLayout>
  );
};
