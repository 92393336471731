import React, { memo, useMemo } from "react";
import { TableColumnsType } from "antd";
import dayjs from "dayjs";
import { formatPrice, parsePrice } from "util/price";

import { ExternalLink } from "components/ExternalLink";
import { FormHelp } from "components/Form/FormHelp";
import { InputNumber } from "components/Input/InputNumber";
import { Table } from "components/Table";
import { posAnalyticsHelpPageUrl } from "constants/externalLinks";

import {
  PayingActivePlanChoice,
  PayingActivePlanOpenPriceMeta,
  PayingMenuOrderItem,
} from "../types";

type OnSitePaymentPayingItem = (
  | PayingMenuOrderItem
  | PayingActivePlanChoice
  | PayingActivePlanOpenPriceMeta
) & { key: string };

const extractCreatedAt = (item: OnSitePaymentPayingItem) => {
  switch (item.__typename) {
    case "payingMenuOrderItem": {
      return dayjs(item.menuOrderItem.order.orderedAt);
    }
    case "payingActivePlanChoice": {
      return dayjs(item.activePlanChoice.createdAt);
    }
    case "payingActivePlanOpenPriceMeta": {
      return dayjs(item.activePlanOpenPriceMeta.activePlan.startAt);
    }
  }
};

const getOnSitePaymentPayingItemsFromOnSitePayment = ({
  payingMenuOrderItems,
  payingActivePlanChoices,
  payingActivePlanOpenPriceMetas,
}: {
  payingMenuOrderItems: PayingMenuOrderItem[];
  payingActivePlanChoices: PayingActivePlanChoice[];
  payingActivePlanOpenPriceMetas: PayingActivePlanOpenPriceMeta[];
}): OnSitePaymentPayingItem[] => {
  const ret: OnSitePaymentPayingItem[] = [];

  ret.push(...payingMenuOrderItems.map((item) => ({ ...item, key: item.id })));
  ret.push(
    ...payingActivePlanChoices.map((choice) => ({
      ...choice,
      key: choice.payingActivePlanChoiceId.toString(),
    })),
  );
  ret.push(
    ...payingActivePlanOpenPriceMetas.map((meta) => ({
      ...meta,
      key: meta.activePlanOpenPriceMetaId,
    })),
  );

  return ret.sort(
    (item1, item2) =>
      (extractCreatedAt(item1)?.unix() ?? 0) - (extractCreatedAt(item2)?.unix() ?? 0),
  );
};

type Props = {
  payingMenuOrderItems: PayingMenuOrderItem[];
  payingActivePlanChoices: PayingActivePlanChoice[];
  payingActivePlanOpenPriceMetas: PayingActivePlanOpenPriceMeta[];
  updatePayingMenuOrderItem(args: {
    payingMenuOrderItemId: string;
    menuOrderItemId: string;
    price?: number;
    quantity?: number;
  }): void;
  updatePayingActivePlanChoice(args: {
    payingActivePlanChoiceId: string;
    activePlanChoiceId: string;
    price?: number;
    quantity?: number;
  }): void;
  updatePayingActivePlanOpenPriceMeta(args: {
    payingActivePlanOpenPriceMetaId: string;
    activePlanOpenPriceMetaId: string;
    price?: number;
    quantity?: number;
  }): void;
  disabled: boolean;
};

const createColumns = ({
  updatePayingActivePlanChoice,
  updatePayingActivePlanOpenPriceMeta,
  updatePayingMenuOrderItem,
  disabled,
}: {
  updatePayingMenuOrderItem(args: {
    payingMenuOrderItemId: string;
    menuOrderItemId: string;
    price?: number;
    quantity?: number;
  }): void;
  updatePayingActivePlanChoice(args: {
    payingActivePlanChoiceId: string;
    activePlanChoiceId: string;
    price?: number;
    quantity?: number;
  }): void;
  updatePayingActivePlanOpenPriceMeta(args: {
    payingActivePlanOpenPriceMetaId: string;
    activePlanOpenPriceMetaId: string;
    price?: number;
    quantity?: number;
  }): void;
  disabled: boolean;
}): TableColumnsType<OnSitePaymentPayingItem> => [
  {
    title: "メニュー名",
    render: (_, item) => {
      switch (item.__typename) {
        case "payingMenuOrderItem": {
          return item.menuOrderItem.name;
        }
        case "payingActivePlanChoice": {
          return item.activePlanChoice.name;
        }
        case "payingActivePlanOpenPriceMeta": {
          return item.activePlanOpenPriceMeta.activePlan.name;
        }
      }
    },
  },
  {
    title: (
      <FormHelp
        label="支払価格(税込)"
        help={
          <>
            支払価格は会計時の割引が適用された金額です。そのため、メニューごとの販売価格とは異なることがあります。詳しくは
            <ExternalLink externalLinkUrl={posAnalyticsHelpPageUrl} text="ヘルプサイト" />
            をご覧ください。
          </>
        }
      />
    ),
    // eslint-disable-next-line react/display-name
    render: (_, item) => (
      <InputNumber
        formatter={(value) => formatPrice(Number(value))}
        parser={(value = "") => parsePrice(value)}
        defaultValue={item.price}
        disabled={disabled}
        onChange={(priceValue) => {
          switch (item.__typename) {
            case "payingMenuOrderItem": {
              updatePayingMenuOrderItem({
                payingMenuOrderItemId: item.id,
                menuOrderItemId: item.menuOrderItemId,
                price: Number(priceValue),
              });
              break;
            }
            case "payingActivePlanChoice": {
              updatePayingActivePlanChoice({
                payingActivePlanChoiceId: item.payingActivePlanChoiceId,
                activePlanChoiceId: item.activePlanChoiceId,
                price: Number(priceValue),
              });
              break;
            }
            case "payingActivePlanOpenPriceMeta": {
              updatePayingActivePlanOpenPriceMeta({
                payingActivePlanOpenPriceMetaId: item.payingActivePlanOpenPriceMetaId,
                activePlanOpenPriceMetaId: item.activePlanOpenPriceMetaId,
                price: Number(priceValue),
              });
              break;
            }
          }
        }}
      />
    ),
  },
  {
    title: "個数",
    // eslint-disable-next-line react/display-name
    render: (_, item) => (
      <InputNumber
        defaultValue={item.quantity}
        disabled={disabled}
        onChange={(quantityValue) => {
          switch (item.__typename) {
            case "payingMenuOrderItem": {
              updatePayingMenuOrderItem({
                payingMenuOrderItemId: item.id,
                menuOrderItemId: item.menuOrderItemId,
                quantity: Number(quantityValue),
              });
              break;
            }
            case "payingActivePlanChoice": {
              updatePayingActivePlanChoice({
                payingActivePlanChoiceId: item.payingActivePlanChoiceId,
                activePlanChoiceId: item.activePlanChoiceId,
                quantity: Number(quantityValue),
              });
              break;
            }
            case "payingActivePlanOpenPriceMeta": {
              updatePayingActivePlanOpenPriceMeta({
                payingActivePlanOpenPriceMetaId: item.payingActivePlanOpenPriceMetaId,
                activePlanOpenPriceMetaId: item.activePlanOpenPriceMetaId,
                quantity: Number(quantityValue),
              });
              break;
            }
          }
        }}
      />
    ),
  },
];

export const OnSitePaymentPayingItemTable = memo<Props>(
  ({
    payingActivePlanChoices,
    payingActivePlanOpenPriceMetas,
    payingMenuOrderItems,
    updatePayingActivePlanChoice,
    updatePayingActivePlanOpenPriceMeta,
    updatePayingMenuOrderItem,
    disabled,
  }) => {
    const items = useMemo(
      () =>
        getOnSitePaymentPayingItemsFromOnSitePayment({
          payingMenuOrderItems,
          payingActivePlanChoices,
          payingActivePlanOpenPriceMetas,
        }),
      [payingActivePlanChoices, payingActivePlanOpenPriceMetas, payingMenuOrderItems],
    );

    const columns = createColumns({
      updatePayingActivePlanChoice,
      updatePayingActivePlanOpenPriceMeta,
      updatePayingMenuOrderItem,
      disabled,
    });

    return <Table dataSource={items} columns={columns} bordered largePagination />;
  },
);
