import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditServiceAdminFormItem } from "../useEditServiceAdminForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EmailField = memo<Props>((props) => (
  <EditServiceAdminFormItem
    label="メールアドレス"
    name="email"
    rules={[{ required: true, message: "メールアドレスを入力してください" }]}
    {...props}
  >
    <Input disabled />
  </EditServiceAdminFormItem>
));
