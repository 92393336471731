import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Result } from "antd";

import { colors } from "constants/colors";
import { useCurrentUser } from "hooks/useUser";
import { logger } from "libs/logger";

const Wrapper = styled.div`
  background: ${colors.BackGround.Tertiary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

export const InvalidAccount = () => {
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!currentUser) return;

    logger.warn("403: user signed in with wrong account", { email: currentUser.email });
  }, [currentUser]);

  return (
    <Wrapper>
      <Result
        status="403"
        title="403 Forbidden"
        subTitle="ご使用のアカウントが間違っています。アカウントをお確かめの上で再度お試しください。"
        extra={
          <Link to="/signOut">
            <Button>ログアウト</Button>
          </Link>
        }
      />
    </Wrapper>
  );
};
