import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import {
  useGiftsDeleteMenuMutation,
  useGiftsDeleteShopMenuKitchenRoleMutation,
  useGiftsGetMenusQuery,
  useGiftsGetRolesQuery,
  useGiftsInsertShopMenuKitchenRoleMutation,
  useGiftsUpdateShopMenuDisplayMutation,
} from "pages/Gifts/queries";

import { GiftsTable } from "./GiftsTable";

export const Gifts = () => {
  const [company] = useCompany();
  const companyId = company?.id;
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getGiftsData,
    error: getMenusError,
    loading: loadingGetMenus,
    refetch: refetchGiftMenus,
  } = useGiftsGetMenusQuery(shopId ? { variables: { shopId } } : { skip: true });
  const menus = useMemo(() => getGiftsData?.menu ?? [], [getGiftsData]);

  const {
    data: getRolesData,
    error: getRolesError,
    loading: loadingGetRoles,
  } = useGiftsGetRolesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const roles = useMemo(() => getRolesData?.role ?? [], [getRolesData?.role]);

  const [updateShopMenuDisplayMutation] = useGiftsUpdateShopMenuDisplayMutation();

  const updateIsVisibleForCustomer = useCallback(
    async ({ menuId, isVisibleForCustomer }: { menuId: string; isVisibleForCustomer: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopMenuDisplayMutation({
          variables: { shopId, menuId, isVisibleForCustomer },
        });
        message.success("設定を更新しました");
      } catch (err) {
        message.error("設定の更新に失敗しました");
      }
      await refetchGiftMenus();
    },
    [shopId, updateShopMenuDisplayMutation, refetchGiftMenus],
  );

  const [insertShopMenuKitchenRoleMutation] = useGiftsInsertShopMenuKitchenRoleMutation();

  const [deleteShopMenuKitchenRoleMutation] = useGiftsDeleteShopMenuKitchenRoleMutation();

  const handleToggle = useCallback(
    async ({ menuId, roleId }: { menuId: number; roleId: string }) => {
      try {
        const menu = menus.find((menu) => menu.menuId === menuId);
        const shopMenu = menu?.shopMenus[0];
        const checked = shopMenu?.shopMenuKitchenRoles.some(
          (shopMenuKitchenRole) => shopMenuKitchenRole.roleId === roleId,
        );
        const role = roles.find((role) => role.id === roleId);

        if (!menu) throw new Error("no menu found");
        if (!shopMenu) throw new Error("no shopMenu found");
        if (!role) throw new Error("role is not found");
        if (!shopId) throw new Error("shopId is not available");

        if (checked) {
          await deleteShopMenuKitchenRoleMutation({
            variables: { roleId, menuId },
          });
        } else {
          await insertShopMenuKitchenRoleMutation({
            variables: {
              menuRolesRole: {
                menuId: menu.id,
                _menuId: menu.menuId,
                roleId: role.id,
                _roleId: role.roleId,
                shopId,
              },
            },
          });
        }

        message.success("設定を更新しました");
      } catch (e) {
        message.error("設定の更新に失敗しました");
      }
      await refetchGiftMenus();
    },
    [
      deleteShopMenuKitchenRoleMutation,
      insertShopMenuKitchenRoleMutation,
      menus,
      roles,
      shopId,
      refetchGiftMenus,
    ],
  );

  const shouldShowAlert = getMenusError || getRolesError;
  const loading = loadingGetMenus || loadingGetRoles;

  const [deleteMenuMutation] = useGiftsDeleteMenuMutation();

  const onDelete = useCallback(
    async ({ menuId, serial }: { menuId: string; serial: number }) => {
      if (!companyId) {
        return;
      }

      try {
        await deleteMenuMutation({
          variables: { input: { companyId, menuId, _menuId: serial } },
        });

        message.success("削除しました");
      } catch (err) {
        message.error("削除に失敗しました");
      }

      await refetchGiftMenus();
    },
    [companyId, deleteMenuMutation, refetchGiftMenus],
  );

  const { canAccess } = useCanAccess();

  return (
    <DashboardLayout title="推しエール対象メニュー一覧">
      <PageHeader
        title="推しエール対象メニュー一覧"
        extra={[
          canAccess("addGift") && (
            <Link key="add" to="/gift/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
          canAccess("editGiftPriorities") && (
            <Link key="editPriority" to="/gift/priority/edit">
              <Button>表示順編集</Button>
            </Link>
          ),
        ]}
        footer={<ShopSelector />}
      />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <GiftsTable
        menus={menus}
        roles={roles}
        onDelete={onDelete}
        loading={loading}
        shopId={shopId}
        onUpdateIsVisibleForCustomer={updateIsVisibleForCustomer}
        onTogglePrinting={handleToggle}
      />
    </DashboardLayout>
  );
};
