import { stringify } from "query-string";
import { getSelfWebUrl } from "util/selfWeb";

import { Table as TableType } from "../../types/graphql.js";

type Table = Pick<TableType, "code">;

export type CheckInUrlType = "web" | "liff" | "redirect";

export const getWebCheckInUrl = (shopId: string, table: Table): string => {
  const { code: tableCode } = table;
  const queryParams = { shopId, tableCode };

  return getSelfWebUrl(`?${stringify(queryParams)}`);
};

export const getDeepLinkCheckInUrl = (shopId: string, table: Table, liffId: string) => {
  const { code: tableCode } = table;
  const queryParams = {
    shopId,
    tableCode,
    ...(import.meta.env.PR_NUMBER ? { pr: import.meta.env.PR_NUMBER } : {}),
  };

  return `https://liff.line.me/${liffId}?${stringify(queryParams)}`;
};

export const getDiniiDomainCheckInUrl = (
  shopId: string,
  table: Table,
  liffId: string | undefined,
): string => {
  const { code: tableCode } = table;
  const queryParams = {
    shopId,
    tableCode,
    ...(liffId ? { liffId } : {}),
  };

  return getSelfWebUrl(`gateway?${stringify(queryParams)}`);
};

export const getCheckInUrl = (
  type: CheckInUrlType,
  table: Table,
  shopId?: string,
  liffId?: string,
) => {
  if (!shopId) return null;

  switch (type) {
    case "web":
      return getWebCheckInUrl(shopId, table);
    case "liff":
      return liffId ? getDeepLinkCheckInUrl(shopId, table, liffId) : null;
    case "redirect":
      return getDiniiDomainCheckInUrl(shopId, table, liffId);
  }
};
