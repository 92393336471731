import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "antd";

import { AnswerList } from "components/QuestionnaireAnalyticsCommentModal/AnswerList";
import { Header } from "components/QuestionnaireAnalyticsCommentModal/Header";
import { useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery } from "components/QuestionnaireAnalyticsCommentModal/queries";
import { oldColors } from "constants/colors";
import { viewport } from "constants/viewport";

const StyledModal = styled(Modal)`
  top: 0;
  padding: 0;
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-header {
    width: calc(100% - 30px);
  }

  @media ${viewport.desktop} {
    max-width: 890px;
    height: calc(100vh - 96px);
    .ant-modal-content {
      margin: 48px auto;
      padding: 16px;
    }
  }

  @media ${viewport.tablet} {
    max-width: 889px;
    height: calc(100svh - 40px);
    .ant-modal-content {
      margin: 20px 12px;
      padding: 8px;
    }
  }

  @media ${viewport.smartphone} {
    max-width: 470px;
    height: calc(100svh - 64px);
    .ant-modal-content {
      margin: 32px 6px;
      padding: 8px;
    }
  }
`;

const ListContent = styled.div`
  border: 1px solid ${oldColors.Neutral200};
  border-top: none;
`;

type Props = {
  customerQuestionnaireId: string;
  isVisibleModal: boolean;
  onDismiss: () => void;
};

export const QuestionnaireAnalyticsCommentModal = memo<Props>(
  ({ customerQuestionnaireId, isVisibleModal, onDismiss }) => {
    const { data } = useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery({
      variables: {
        customerQuestionnaireId,
      },
    });
    const customerQuestionnaire = data?.customerQuestionnaire_by_pk;

    // NOTE: Pull-to-Refresh 対策
    // Pull-to-Refresh の挙動が起こると、モーダルの中身がスクロールできない現象になるため
    useEffect(() => {
      if (isVisibleModal) {
        document.body.style.overflow = "hidden";
      }

      return () => {
        document.body.style.overflow = "auto";
      };
    }, [isVisibleModal]);

    return customerQuestionnaire ? (
      <StyledModal
        open={isVisibleModal}
        onCancel={onDismiss}
        footer={null}
        title={
          <Header
            profileImage={customerQuestionnaire.customer.lineProfile?.profileImage}
            customerName={customerQuestionnaire.customer.lineProfile?.name}
            visitedAt={customerQuestionnaire.questionnaireAnswer?.visitedAt ?? undefined}
          />
        }
        width="100%"
        zIndex={1002}
        bodyStyle={{ overflowY: "scroll", height: "100%" }}
      >
        <ListContent>
          <AnswerList answer={customerQuestionnaire} />
        </ListContent>
      </StyledModal>
    ) : null;
  },
);
