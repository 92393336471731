import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const DescriptionField = memo<Props>((props) => (
  <FormItem {...props}>
    <TextArea rows={2} />
  </FormItem>
));
