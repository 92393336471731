import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { grey } from "constants/colors";
import { UpsertTranslationsForOptionInput } from "types/graphql";

import { EditChoiceTranslationsForm } from "../EditChoiceTranslationsForm";
import { ChoiceTranslation, Option, OptionTranslation } from "../types";

import { OptionNameCnField } from "./OptionNameCnField";
import { OptionNameEnField } from "./OptionNameEnField";
import { OptionNameField } from "./OptionNameField";
import { OptionNameKrField } from "./OptionNameKrField";
import { useEditOptionTranslationsForm } from "./useEditOptionTranslationsForm";

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledForm = styled(Form)`
  width: 60%;
`;

const StyledFormSection = styled(FormSection)`
  padding: 0;
`;

const StyledText = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 700;
`;

type Props = {
  option: Option;
  optionTranslations: OptionTranslation[];
  choiceTranslations: ChoiceTranslation[];
  onSubmit: (inputs: UpsertTranslationsForOptionInput) => void;
  onClose: () => void;
  onFormValidationError: () => void;
  disabled: boolean;
};

export const EditOptionTranslationsForm = memo<Props>(
  ({
    option,
    optionTranslations,
    choiceTranslations,
    onSubmit,
    onClose,
    onFormValidationError,
    disabled,
  }) => {
    const { form, initialValues, submit } = useEditOptionTranslationsForm({
      option,
      onSubmit,
      optionTranslations,
      choiceTranslations,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [submit, form, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <FormWrapper>
        <StyledForm form={form} initialValues={initialValues} layout="vertical">
          <StyledFormSection title="オプション名">
            <OptionNameField />
            <OptionNameEnField disabled={disabled} />
            <OptionNameCnField disabled={disabled} />
            <OptionNameKrField disabled={disabled} />
          </StyledFormSection>
          <StyledText>各選択肢名</StyledText>
        </StyledForm>

        <EditChoiceTranslationsForm form={form} initialValues={initialValues} disabled={disabled} />

        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} disabled={disabled}>
            更新
          </Button>
        </FormActions>
      </FormWrapper>
    );
  },
);
