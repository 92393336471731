import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PikaichiMenuInsertInput } from "types/graphql";

import { PikaichiMenu, Plan } from "../types";

export type EditPikaichiPlanFormValues = Pick<
  PikaichiMenu,
  | "pikaichiMenuCd"
  | "pikaichiMenuName"
  | "pikaichiCategoryCd"
  | "pikaichiCategoryName"
  | "pikaichiBumonCd"
  | "pikaichiBumonName"
>;

export const EditPikaichiPlanFormItem = createFormItem<EditPikaichiPlanFormValues>();

export const useEditPikaichiPlanForm = ({
  plan,
  pikaichiMenu,
  onSubmit,
}: {
  plan?: Plan;
  pikaichiMenu?: PikaichiMenu;
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
}) => {
  const [form] = Form.useForm<EditPikaichiPlanFormValues>();

  const initialValues = useMemo<EditPikaichiPlanFormValues>(
    () => ({
      pikaichiMenuCd: pikaichiMenu?.pikaichiMenuCd ?? "",
      pikaichiMenuName: pikaichiMenu?.pikaichiMenuName ?? plan?.planName ?? "",
      pikaichiBumonCd: pikaichiMenu?.pikaichiBumonCd ?? "",
      pikaichiBumonName: pikaichiMenu?.pikaichiBumonName ?? "",
      pikaichiCategoryCd: pikaichiMenu?.pikaichiCategoryCd ?? "",
      pikaichiCategoryName: pikaichiMenu?.pikaichiCategoryName ?? "",
    }),
    [plan, pikaichiMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
