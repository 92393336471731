import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum } from "types/graphql";

export const MembershipCardMenu = memo<SubMenuPropsWithoutFeatures>(({ ...props }) => (
  <SubMenu
    key="membershipCard"
    title="メンバーズカード"
    features={[DashboardFeatureEnum.CustomerRelationshipManagement]}
    {...props}
  >
    <MenuItem
      route="editMembershipCardAppearanceType"
      to="/membershipCard/appearanceType/edit"
      text="カードデザイン"
    />
    <MenuItem route="membershipRankConfig" to="/membershipCard/rankConfig" text="会員ランク管理" />
  </SubMenu>
));
