import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Button, message as originalMessage, notification } from "antd";
import { JointContent } from "antd/es/message/interface";
import { getHasUpdate } from "util/version";

const errorWithVersionErrorCatch = async (
  content: JointContent,
  duration?: number | VoidFunction, // Also can use onClose directly
  onClose?: VoidFunction,
) => {
  originalMessage.error(content, duration, onClose);

  const hasUpdate = await getHasUpdate();

  if (hasUpdate) {
    notification.open({
      message: <span>新しいバージョンがあります</span>,
      description: "ページを更新してから再度お試しください",
      btn: (
        <Button type="primary" size="small" onClick={() => location.reload()}>
          更新
        </Button>
      ),
      className: "hide-notification-close",
      key: "update-for-version-related-error",
      duration: null,
    });
  }
};

export const message = {
  ...originalMessage,
  error: errorWithVersionErrorCatch,
};
