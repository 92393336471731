import React, { memo } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Layout } from "antd";
import { getTitle } from "util/title";

import { useDashboardLayoutGetCompanyQuery } from "components/Layout/DashboardLayout/queries";
import { viewport } from "constants/viewport";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { useCurrentUser } from "hooks/useUser";

import { LocationBreadCrumb } from "./LocationBreadcrumb";

const { Content } = Layout;

const PADDING_TOP_SP = 24;
const PADDING_X_SP = 8;
const PADDING_TOP_X_PC = 32;
const PADDING_BOTTOM_SP_PC = 32;

const StyledContent = styled(Content)`
  padding: ${PADDING_TOP_SP}px ${PADDING_X_SP}px ${PADDING_BOTTOM_SP_PC}px;
  overflow-y: scroll;
  @media ${viewport.desktop} {
    padding: ${PADDING_TOP_X_PC}px ${PADDING_TOP_X_PC}px ${PADDING_BOTTOM_SP_PC}px;
  }
`;

type Props = {
  title?: string | string[];
  locationBreadcrumb?: {
    showShop: boolean;
    items: {
      name: string;
    }[];
  };
  children: React.ReactNode;
};

export const DashboardLayout = memo<Props>(({ title, locationBreadcrumb, children }) => {
  const pageTitle = getTitle(title);

  const user = useCurrentUser();

  const { data: getCompanyData } = useDashboardLayoutGetCompanyQuery(
    user ? { fetchPolicy: "cache-and-network" } : { skip: true },
  );
  const [currentCompany] = useCompany();
  const [currentShop] = useShop();
  const companies = getCompanyData?.company ?? [];
  const company = companies.find((c) => c.companyId === currentCompany?.companyId);
  const shop = company?.shops?.find((s) => s.shopId === currentShop?.shopId);
  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <StyledContent>
        {locationBreadcrumb ? (
          <LocationBreadCrumb
            items={locationBreadcrumb.items}
            showShopName={locationBreadcrumb.showShop}
            shopName={shop?.name ?? ""}
            companyName={company?.name ?? ""}
          />
        ) : null}
        {children}
      </StyledContent>
    </>
  );
});
