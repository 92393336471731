import React, { memo, useMemo } from "react";
import { Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { Plan } from "../types";

type Props = {
  loading?: boolean;
  plans: Plan[];
};

export const PlanTable = memo<Props>(({ loading, plans }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<Plan> = useMemo(
    () => [
      {
        title: "カテゴリ名",
        width: 150,
        fixed: "left",
        render(_: string, { category }: Plan) {
          return category.name;
        },
      },
      {
        title: "プラン名",
        dataIndex: "planName",
        fixed: "left",
        width: 200,
      },
      {
        title: "オプション",
        width: 200,
        render(_: string, { planOptions }: Plan) {
          return planOptions.map(({ name, planOptionId }) => <Tag key={planOptionId}>{name}</Tag>);
        },
      },
      {
        title: "メニューコード",
        align: "left",
        width: 130,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.menuCode;
        },
      },
      {
        title: "メニュー名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.name;
        },
      },
      {
        title: "部門コード",
        align: "left",
        width: 100,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.dpCode;
        },
      },
      {
        title: "部門名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.dpName;
        },
      },
      {
        title: "分類コード",
        align: "left",
        width: 100,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.gpCode;
        },
      },
      {
        title: "分類名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          return plan.tecAggregationMenu?.gpName;
        },
      },
      ...(canAccess("editTecAggregationPlan")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, { planId }: Plan) {
                return (
                  <Tooltip title="メニューを編集">
                    <IconLink to={`/tec/plan/${planId}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  return (
    <Table
      rowKey="planId"
      columns={columns}
      dataSource={plans}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
