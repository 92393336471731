import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Tag } from "antd";
import Column from "antd/es/table/Column";
import Modal from "antd/lib/modal/Modal";
import { isNotNull } from "util/type/primitive";

import { Table } from "components/Table";
import { colors } from "constants/colors";

import {
  FoodingJournalMenuSource,
  MenuWithFoodingJournalMenu,
  NonNullableFoodingJournalMenuSource,
} from "../MenuTable";
import { FoodingJournalDepartmentMaster, FoodingJournalGroupMaster } from "../types";

const ComparisonText = styled.p`
  color: ${colors.Text.Primary};
`;

type Props = {
  editingFoodingJournalMenus: Record<
    string,
    FoodingJournalMenuSource & { type: "menu" | "choice" }
  >;
  menus: MenuWithFoodingJournalMenu[];
  foodingJournalDepartmentMasters: FoodingJournalDepartmentMaster[];
  foodingJournalGroupMasters: FoodingJournalGroupMaster[];
  loading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

type RowItem = NonNullableFoodingJournalMenuSource & {
  id: string;
  categoryName: string;
  name: string;
  type: "menu" | "choice";

  previousCode: string | undefined;
  previousName: string | undefined;
  previousFoodingJournalDepartmentId: string | undefined;
  previousFoodingJournalGroupId: string | undefined;
};

const isEditingValueValid = <T extends FoodingJournalMenuSource>(
  value: T,
): value is T & NonNullableFoodingJournalMenuSource =>
  Boolean(
    value.code &&
      value.foodingJournalName &&
      value.foodingJournalDepartmentId &&
      value.foodingJournalGroupId,
  );

export const ConfirmationModal = memo<Props>(
  ({
    editingFoodingJournalMenus,
    menus,
    foodingJournalDepartmentMasters,
    foodingJournalGroupMasters,
    loading,
    onSubmit,
    onCancel,
  }: Props) => {
    const menuIdToMenuMap = useMemo(
      () => new Map(menus.map((menu) => [menu.menuId, menu])),
      [menus],
    );

    const choiceIdToChoiceSourceMap = useMemo(
      () =>
        new Map(
          Object.entries(editingFoodingJournalMenus).filter(([_, menu]) => menu.type === "choice"),
        ),
      [editingFoodingJournalMenus],
    );

    const foodingJournalDepartmentMap = useMemo(
      () => new Map(foodingJournalDepartmentMasters.map((master) => [master.id, master])),
      [foodingJournalDepartmentMasters],
    );
    const foodingJournalGroupMap = useMemo(
      () => new Map(foodingJournalGroupMasters.map((master) => [master.id, master])),
      [foodingJournalGroupMasters],
    );

    const dataSource = useMemo(() => {
      const choiceIds = new Set();

      const menus = Object.entries(editingFoodingJournalMenus)
        .map(([id, menuSource]) => {
          if (menuSource.type === "menu") {
            const menu = menuIdToMenuMap.get(id);

            if (!menu) return null;

            const categoryName = menu.category.name;

            const children = menu.choices
              .map((choice) => {
                const choiceSource = choiceIdToChoiceSourceMap.get(choice.choiceId);

                if (!choiceSource || !isEditingValueValid(choiceSource)) return null;

                choiceIds.add(choice.choiceId);

                return {
                  ...choiceSource,
                  categoryName: null,
                  id: choice.choiceId,
                  name: choice.name,
                  previousCode: choice.code !== choiceSource.code ? choice.code : undefined,
                  previousName:
                    choice.foodingJournalName !== choiceSource.foodingJournalName
                      ? choice.foodingJournalName
                      : undefined,
                  previousFoodingJournalDepartmentId:
                    choice.foodingJournalDepartmentId !== choiceSource.foodingJournalDepartmentId
                      ? choice.foodingJournalDepartmentId
                      : undefined,
                  previousFoodingJournalGroupId:
                    choice.foodingJournalGroupId !== choiceSource.foodingJournalGroupId
                      ? choice.foodingJournalGroupId
                      : undefined,
                };
              })
              .filter(isNotNull);

            return {
              ...menuSource,
              id,
              categoryName,
              name: menu.name,
              previousCode: menu.code !== menuSource.code ? menu.code : undefined,
              previousName:
                menu.foodingJournalName !== menuSource.foodingJournalName
                  ? menu.foodingJournalName
                  : undefined,
              previousFoodingJournalDepartmentId:
                menu.foodingJournalDepartmentId !== menuSource.foodingJournalDepartmentId
                  ? menu.foodingJournalDepartmentId
                  : undefined,
              previousFoodingJournalGroupId:
                menu.foodingJournalGroupId !== menuSource.foodingJournalGroupId
                  ? menu.foodingJournalGroupId
                  : undefined,
              children: children.length > 0 ? children : null,
            };
          } else {
            const choice = Array.from(menuIdToMenuMap.values())
              .flatMap((menu) => menu.choices)
              .find((choice) => choice.choiceId === id);

            if (!choice) return null;

            const choiceSource = choiceIdToChoiceSourceMap.get(choice.choiceId);

            if (!choiceSource || !isEditingValueValid(choiceSource)) return null;

            return {
              ...choiceSource,
              categoryName: choice.category.name,
              id: choice.choiceId,
              name: choice.name,
              previousCode: choice.code !== choiceSource.code ? choice.code : undefined,
              previousName:
                choice.foodingJournalName !== choiceSource.foodingJournalName
                  ? choice.foodingJournalName
                  : undefined,
              previousFoodingJournalDepartmentId:
                choice.foodingJournalDepartmentId !== choiceSource.foodingJournalDepartmentId
                  ? choice.foodingJournalDepartmentId
                  : undefined,
              previousFoodingJournalGroupId:
                choice.foodingJournalGroupId !== choiceSource.foodingJournalGroupId
                  ? choice.foodingJournalGroupId
                  : undefined,
              children: null,
            };
          }
        })
        .filter(isNotNull);

      // NOTE: menu.children の choice を優先する
      const filteredMenus = menus.filter((menu) => {
        if (choiceIds.has(menu.id)) return false;
        return true;
      });

      return { menus: filteredMenus };
    }, [choiceIdToChoiceSourceMap, editingFoodingJournalMenus, menuIdToMenuMap]);

    const onOk = useCallback(async () => {
      await onSubmit();
    }, [onSubmit]);

    return (
      <Modal
        title="変更内容確認"
        visible
        width={3000}
        onCancel={onCancel}
        footer={
          <>
            <Button key="cancel" onClick={onCancel}>
              キャンセル
            </Button>

            <Button
              key="add"
              type="primary"
              onClick={onOk}
              loading={loading}
              disabled={menus.length === 0}
            >
              更新
            </Button>
          </>
        }
      >
        <Table bordered dataSource={dataSource.menus}>
          <Column
            title=""
            width={100}
            fixed="left"
            render={(_: string, rowItem: RowItem) =>
              rowItem.foodingJournalMenuId ? (
                <Tag>更新</Tag>
              ) : (
                <Tag color={colors.BackGround.PrimaryDefault}>追加</Tag>
              )
            }
          />

          <Column
            title="カテゴリ名"
            width={150}
            fixed="left"
            render={(_: string, rowItem: RowItem) => {
              if (rowItem.type !== "menu" && !rowItem.categoryName) {
                return {
                  props: {
                    style: {
                      backgroundColor: colors.BackGround.Tertiary,
                    },
                  },
                };
              }

              const { categoryName } = rowItem;

              return {
                children: categoryName,
              };
            }}
          />

          <Column dataIndex="name" title="商品名" width={200} />

          <Column
            dataIndex="code"
            title="メニューコード"
            align="left"
            width="100"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{rowItem.code}</ComparisonText>;
              }

              if (!rowItem.previousCode) return rowItem.code;

              return (
                <>
                  <ComparisonText> {`${rowItem.previousCode} → ${rowItem.code}`} </ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalName"
            title="メニュー名称"
            align="left"
            width="300"
            render={(_: string, rowItem: RowItem) => {
              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{rowItem.name}</ComparisonText>;
              }

              if (!rowItem.previousName) return rowItem.foodingJournalName;

              return (
                <>
                  <ComparisonText>{`${rowItem.previousName} → ${rowItem.foodingJournalName}`}</ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalDepartmentId"
            title="部門"
            align="left"
            width="200"
            render={(_: string, rowItem: RowItem) => {
              const foodingJournalDepartment = foodingJournalDepartmentMap.get(
                rowItem.foodingJournalDepartmentId,
              );

              if (!foodingJournalDepartment) return "";

              const departmentLabel = `${foodingJournalDepartment.code}: ${foodingJournalDepartment.name}`;

              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{departmentLabel}</ComparisonText>;
              }

              if (!rowItem.previousFoodingJournalDepartmentId) {
                return departmentLabel;
              }

              const previousFoodingJournalDepartment = foodingJournalDepartmentMap.get(
                rowItem.previousFoodingJournalDepartmentId,
              );

              if (!previousFoodingJournalDepartment) return "";

              const previousDepartmentLabel = `${previousFoodingJournalDepartment.code}: ${previousFoodingJournalDepartment.name}`;

              return (
                <>
                  <ComparisonText>{`${previousDepartmentLabel} → ${departmentLabel}`}</ComparisonText>
                </>
              );
            }}
          />

          <Column
            dataIndex="foodingJournalGroupId"
            title="分類"
            align="left"
            width="150"
            render={(_: string, rowItem: RowItem) => {
              const foodingJournalGroup = foodingJournalGroupMap.get(rowItem.foodingJournalGroupId);

              if (!foodingJournalGroup) return "";

              const groupLabel = `${foodingJournalGroup.code}: ${foodingJournalGroup.name}`;

              if (!rowItem.foodingJournalMenuId) {
                return <ComparisonText>{groupLabel}</ComparisonText>;
              }

              if (!rowItem.previousFoodingJournalGroupId) {
                return groupLabel;
              }

              const previousFoodingJournalGroup = foodingJournalGroupMap.get(
                rowItem.previousFoodingJournalGroupId,
              );

              if (!previousFoodingJournalGroup) return "";

              const previousGroupLabel = `${previousFoodingJournalGroup.code}: ${previousFoodingJournalGroup.name}`;

              return (
                <>
                  <ComparisonText>{`${previousGroupLabel} → ${groupLabel}`}</ComparisonText>
                </>
              );
            }}
          />
        </Table>
      </Modal>
    );
  },
);
