import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem, Form } from "components/antd/Form";
import { Plan } from "pages/PlanFirstOrderCategoryMenus/types";

export type EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues = {
  countAsFirstOrderMenuQuantity: number[];
};

const getInitialValues = (plan: Plan) => ({
  countAsFirstOrderMenuQuantity: plan.planOptions.flatMap(({ planChoices }) =>
    planChoices
      .filter(({ countAsFirstOrderMenuQuantity }) => countAsFirstOrderMenuQuantity)
      .flatMap(({ planChoiceId }) => planChoiceId),
  ),
});

export const EditPlanChoiceCountAsFirstOrderMenuQuantityFormItem =
  createFormItem<EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues>();

export const useEditPlanChoiceCountAsFirstOrderMenuQuantityForm = (
  plan: Plan,
  onChange: (
    planChoiceCountAsFirstOrderMenuQuantity: EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues,
  ) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(plan);

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { form, initialValues, change };
};
