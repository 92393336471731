import React from "react";
import styled from "styled-components";
import { Descriptions } from "antd";
import { getTotalAmounts } from "models/onSitePayment";
import { formatPrice } from "util/price";

import { grey } from "constants/colors";
import { Maybe } from "types/Maybe";

import { ItemSales } from "../types";

const StyledDescriptions = styled(Descriptions)`
  background-color: ${grey[0]};
`;

type Props = {
  onSitePayment: {
    shopId: string;
    payingMenuOrderItems: { itemSales?: Maybe<ItemSales> }[];
    payingActivePlanChoices: { itemSales?: Maybe<ItemSales> }[];
    payingActivePlanOpenPriceMetas: { itemSales?: Maybe<ItemSales> }[];
  };
};

export const OnSitePaymentDealingDetail = React.memo<Props>(({ onSitePayment }) => {
  const { billingAmount, taxAmount } = getTotalAmounts(onSitePayment);

  const descriptionItems = [
    {
      key: "billingAmount",
      label: "合計",
      children: formatPrice(billingAmount),
    },
    {
      key: "taxAmount",
      label: "内消費税",
      children: formatPrice(taxAmount),
    },
  ];

  return <StyledDescriptions bordered column={1} items={descriptionItems} />;
});
