import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { AddCorporationForm } from "pages/AddCorporation/AddCorporationForm";

import { useAddCorporationInsertCorporationMutation } from "./queries";

export const AddCorporation = () => {
  const [addCorporationMutation, { loading: loadingAddCorporationMutation }] =
    useAddCorporationInsertCorporationMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async ({ name }: { name: string }) => {
      try {
        await addCorporationMutation({
          variables: { input: { name } },
        });
        message.success("作成しました");
        goBack();
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addCorporationMutation, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="法人を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "法人一覧" }],
      }}
    >
      <PageHeader title="法人を新規作成" onBack={goBack} />
      <AddCorporationForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        loading={loadingAddCorporationMutation}
      />
    </DashboardLayout>
  );
};
