import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { useCompany } from "hooks/useCompany";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";

import { EditLineChannelConfigForm } from "./EditLineChannelConfigForm/index";
import { EditLineChannelConfigFormValues } from "./EditLineChannelConfigForm/useEditLineChannelConfigForm";
import {
  useEditLineChannelConfigGetConfigQuery,
  useEditLineChannelConfigUpdateConfigMutation,
  useEditLineChannelGetLineOfficialAccountQuery,
} from "./queries";

export const EditLineChannelConfig = () => {
  const { id: shopId } = useParams<{ id: string }>();

  const {
    data: getConfigData,
    loading: getLoading,
    refetch: refetchConfig,
    error,
  } = useEditLineChannelConfigGetConfigQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shop = getConfigData?.shop_by_pk;

  useSetCurrentCompanyEffect(shop);

  const [company] = useCompany();
  const companyId = company?.id;

  const { data } = useEditLineChannelGetLineOfficialAccountQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const lineOfficialAccounts = useMemo(
    () => data?.validLineOfficialAccounts ?? [],
    [data?.validLineOfficialAccounts],
  );

  const [updateLineChannelConfigMutation] = useEditLineChannelConfigUpdateConfigMutation();

  const onSubmit = useCallback(
    async (formValues: EditLineChannelConfigFormValues) => {
      if (!shopId) return;
      try {
        const { lineOfficialAccountId, ...rest } = formValues;

        const lineOfficialAccount = lineOfficialAccounts.find(
          (account) => account.lineOfficialAccountId === lineOfficialAccountId,
        );

        if (lineOfficialAccountId && !lineOfficialAccount) {
          throw new Error("lineOfficialAccount is not found");
        }

        await updateLineChannelConfigMutation({
          variables: {
            input: { ...rest, shopId },
            shopId,
            lineOfficialAccountId: lineOfficialAccount?.id,
            _lineOfficialAccountId: lineOfficialAccount?.lineOfficialAccountId,
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchConfig();
    },
    [lineOfficialAccounts, shopId, updateLineChannelConfigMutation, refetchConfig],
  );

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {getLoading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <EditLineChannelConfigForm
          shop={shop}
          lineOfficialAccounts={lineOfficialAccounts}
          onSubmit={onSubmit}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
