import React, { useCallback } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PikaichiMenuHeader } from "components/PageHeader/PikaichiMenuHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";

import { EditChoiceModal } from "./EditChoicePikaichiMenuModal";
import { OptionTable } from "./OptionTable";
import { usePikaichiMenuOptionsGetMenuQuery } from "./queries";

export const PikaichiMenuOptions = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenuData,
    error,
  } = usePikaichiMenuOptionsGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];

  setCompany(menu?.companyId);

  const options = menu?.menuOptions.map((mo) => mo.option);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToMenuOption = useCallback(() => {
    navigate(`/pikaichi/menu/${menuId}/option`, { replace: true });
    refetchMenuData();
  }, [navigate, menuId, refetchMenuData]);

  const onCompleted = useCallback(async () => {
    await refetchMenuData();
    goBackToMenuOption();
  }, [refetchMenuData, goBackToMenuOption]);

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビメニュー" }],
      }}
    >
      <PikaichiMenuHeader menu={menu ?? null} onBack={goBack} />
      {loadingMenu && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {menu && options && (
        <FormContent>
          <OptionTable menuId={menu.menuId} options={options} />
          <Routes>
            <Route
              path=":optionId/choice/:choiceId/edit"
              element={
                <EditChoiceModal onCloseModal={goBackToMenuOption} onCompleted={onCompleted} />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
