import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OpenDrawerAtOnSitePaymentEveryTimeField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="現金を扱わない会計時にもドロワーを開く"
        help="デフォルトでは現金会計時のみドロワーを開きます"
      />
    }
    name="shouldOpenDrawerAtOnSitePaymentEveryTime"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
