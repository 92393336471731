import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import { EditMenuTranslationsFormItem } from "./useEditMenuTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const DescriptionEnField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuTranslationsFormItem label="英語" name="descriptionEn" {...props}>
    <TextArea rows={2} disabled={disabled} />
  </EditMenuTranslationsFormItem>
));
