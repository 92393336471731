import React, { memo } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { blue } from "@ant-design/colors";
import { CloseOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: ${blue[0]};
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 8px;
`;

const SelectedCount = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 4px;
`;

const BannerButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

type Props = {
  selectedCount: number;
  onClearButtonClick: () => void;
  onDisplayButtonClick: (isDisplay: boolean) => void;
};

export const BulkUpdateIsDisplayBanner = memo<Props>(
  ({ selectedCount, onClearButtonClick, onDisplayButtonClick }) => (
    <Wrapper>
      <ClearButton role="button" tabIndex={0} onClick={onClearButtonClick}>
        <CloseOutlined />
      </ClearButton>

      <SelectedCount>{selectedCount}</SelectedCount>
      <span>件 選択中</span>

      <BannerButtonsWrapper>
        <Button onClick={() => onDisplayButtonClick(true)}>表示する</Button>
        <Button onClick={() => onDisplayButtonClick(false)}>非表示にする</Button>
      </BannerButtonsWrapper>
    </Wrapper>
  ),
);
