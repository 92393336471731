import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditClerkGetClerk = gql`
    query EditClerkGetClerk($shopId: uuid!, $clerkId: Int!) {
  shopClerk(
    where: {shop: {archivedAt: {_is_null: true}}, _clerkId: {_eq: $clerkId}, shopId: {_eq: $shopId}}
    limit: 1
  ) {
    clerk {
      clerkId
      companyId
      name
      shopId
    }
    isDisplay
  }
}
    `;
export const EditClerkUpdateClerk = gql`
    mutation EditClerkUpdateClerk($clerkId: Int!, $clerk: clerk_set_input!, $shopId: uuid!, $shopClerk: shopClerk_set_input!) {
  update_clerk(_set: $clerk, where: {clerkId: {_eq: $clerkId}}) {
    affected_rows
  }
  update_shopClerk(
    _set: $shopClerk
    where: {_clerkId: {_eq: $clerkId}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
}
    `;
export type EditClerkGetClerkQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  clerkId: Types.Scalars['Int'];
}>;


export type EditClerkGetClerkQuery = (
  { __typename?: 'query_root' }
  & { shopClerk: Array<(
    { __typename?: 'shopClerk' }
    & Pick<Types.ShopClerk, 'isDisplay'>
    & { clerk: (
      { __typename?: 'clerk' }
      & Pick<Types.Clerk, 'clerkId' | 'companyId' | 'name' | 'shopId'>
    ) }
  )> }
);

export type EditClerkUpdateClerkMutationVariables = Types.Exact<{
  clerkId: Types.Scalars['Int'];
  clerk: Types.ClerkSetInput;
  shopId: Types.Scalars['uuid'];
  shopClerk: Types.ShopClerkSetInput;
}>;


export type EditClerkUpdateClerkMutation = (
  { __typename?: 'mutation_root' }
  & { update_clerk?: Types.Maybe<(
    { __typename?: 'clerk_mutation_response' }
    & Pick<Types.ClerkMutationResponse, 'affected_rows'>
  )>, update_shopClerk?: Types.Maybe<(
    { __typename?: 'shopClerk_mutation_response' }
    & Pick<Types.ShopClerkMutationResponse, 'affected_rows'>
  )> }
);


export const EditClerkGetClerkDocument = gql`
    query EditClerkGetClerk($shopId: uuid!, $clerkId: Int!) {
  shopClerk(
    where: {shop: {archivedAt: {_is_null: true}}, _clerkId: {_eq: $clerkId}, shopId: {_eq: $shopId}}
    limit: 1
  ) {
    clerk {
      clerkId
      companyId
      name
      shopId
    }
    isDisplay
  }
}
    `;

/**
 * __useEditClerkGetClerkQuery__
 *
 * To run a query within a React component, call `useEditClerkGetClerkQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditClerkGetClerkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditClerkGetClerkQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      clerkId: // value for 'clerkId'
 *   },
 * });
 */
export function useEditClerkGetClerkQuery(baseOptions: Apollo.QueryHookOptions<EditClerkGetClerkQuery, EditClerkGetClerkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditClerkGetClerkQuery, EditClerkGetClerkQueryVariables>(EditClerkGetClerkDocument, options);
      }
export function useEditClerkGetClerkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditClerkGetClerkQuery, EditClerkGetClerkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditClerkGetClerkQuery, EditClerkGetClerkQueryVariables>(EditClerkGetClerkDocument, options);
        }
export type EditClerkGetClerkQueryHookResult = ReturnType<typeof useEditClerkGetClerkQuery>;
export type EditClerkGetClerkLazyQueryHookResult = ReturnType<typeof useEditClerkGetClerkLazyQuery>;
export type EditClerkGetClerkQueryResult = Apollo.QueryResult<EditClerkGetClerkQuery, EditClerkGetClerkQueryVariables>;
export const EditClerkUpdateClerkDocument = gql`
    mutation EditClerkUpdateClerk($clerkId: Int!, $clerk: clerk_set_input!, $shopId: uuid!, $shopClerk: shopClerk_set_input!) {
  update_clerk(_set: $clerk, where: {clerkId: {_eq: $clerkId}}) {
    affected_rows
  }
  update_shopClerk(
    _set: $shopClerk
    where: {_clerkId: {_eq: $clerkId}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
}
    `;
export type EditClerkUpdateClerkMutationFn = Apollo.MutationFunction<EditClerkUpdateClerkMutation, EditClerkUpdateClerkMutationVariables>;

/**
 * __useEditClerkUpdateClerkMutation__
 *
 * To run a mutation, you first call `useEditClerkUpdateClerkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClerkUpdateClerkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClerkUpdateClerkMutation, { data, loading, error }] = useEditClerkUpdateClerkMutation({
 *   variables: {
 *      clerkId: // value for 'clerkId'
 *      clerk: // value for 'clerk'
 *      shopId: // value for 'shopId'
 *      shopClerk: // value for 'shopClerk'
 *   },
 * });
 */
export function useEditClerkUpdateClerkMutation(baseOptions?: Apollo.MutationHookOptions<EditClerkUpdateClerkMutation, EditClerkUpdateClerkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditClerkUpdateClerkMutation, EditClerkUpdateClerkMutationVariables>(EditClerkUpdateClerkDocument, options);
      }
export type EditClerkUpdateClerkMutationHookResult = ReturnType<typeof useEditClerkUpdateClerkMutation>;
export type EditClerkUpdateClerkMutationResult = Apollo.MutationResult<EditClerkUpdateClerkMutation>;
export type EditClerkUpdateClerkMutationOptions = Apollo.BaseMutationOptions<EditClerkUpdateClerkMutation, EditClerkUpdateClerkMutationVariables>;