import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuRoleFormItem } from "../useEditMenuRoleForm";

type Props = {
  loading?: boolean;
  name: string;
} & Omit<FormItemProps, "children" | "name">;

export const MenuRoleField = memo<Props>(({ loading, ...props }) => (
  <EditMenuRoleFormItem valuePropName="checked" {...props}>
    <Switch loading={loading} />
  </EditMenuRoleFormItem>
));
