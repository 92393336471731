import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import {
  FilterConditions,
  SerializedFilterConditions,
  ShopMetricsFilter,
} from "components/QuestionnaireAnalytics/ShopMetricsFilter";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import {
  LineGraph,
  questionnaireScoreChangesItemMap,
} from "pages/QuestionnaireAnalyticsScoreChangesMetrics/LineGraph";
import {
  useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery,
  useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery,
  useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery,
} from "pages/QuestionnaireAnalyticsScoreChangesMetrics/queries";
import { QuestionnaireScoreChangesDimensionType } from "types/graphql";

import { GraphData, QuestionnaireScoreChangesItem } from "./types";

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  min-width: 1168px;
  padding: 32px;
  display: flex;
`;

const GraphWrapper = styled.div`
  width: 940px;
  display: flex;
  flex-direction: column;
  gap: 52px;
`;

const DisplaySwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DisplaySwitch = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
`;

const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 18px;
  color: #1890ff;
  cursor: pointer;
`;

const StyledEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  font-size: 18px;
  color: #00000066;
  cursor: pointer;
`;

const ColorDot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const QuestionnaireAnalyticsScoreChangesMetrics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const { shop: currentShop, setShop } = useShopPerCorporation();
  const currentShopId = currentShop?.shopId ?? "";

  const initialRange = useMemo<[dayjs.Dayjs, dayjs.Dayjs]>(() => {
    const now = dayjs();

    return [now.subtract(11, "month").startOf("month"), now.endOf("month")];
  }, []);

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      range: initialRange,
      dimension: QuestionnaireScoreChangesDimensionType.Month,
    },
    undefined,
    {
      serialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: serializeRange(range),
      }),
      deserialize: ({ range, ...filterConditions }) => ({
        ...filterConditions,
        range: deserializeRange(range),
      }),
    },
  );

  const {
    data: getQuestionnaire,
    error: getQuestionnaireError,
    loading: loadingQuestionnaire,
  } = useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  const questionnaireId = getQuestionnaire?.questionnaire[0]?.id;

  const { data: getShopsData, loading: getShopsLoading } =
    useQuestionnaireAnalyticsScoreChangesMetricsGetShopsQuery(
      corporationId && questionnaireId
        ? {
            variables: { corporationId, questionnaireId },
            onCompleted: (data) => {
              // NOTE: アンケート設定を持つ店舗を選択するようにする
              const shopIds = data.corporation[0]?.companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .flatMap(({ shops }) =>
                  shops.sort((a, b) => a.name.localeCompare(b.name)).map(({ shopId }) => shopId),
                );
              if (!shopIds || shopIds.length === 0) return;
              if (shopIds.includes(currentShopId)) return;
              setShop(shopIds[0]);
            },
          }
        : { skip: true },
    );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );

  const range = useMemo(
    () =>
      !filterConditions.range?.[0] || !filterConditions.range[1]
        ? null
        : {
            startAt: filterConditions.range[0].toISOString(),
            endAt: filterConditions.range[1].toISOString(),
          },

    [filterConditions.range],
  );

  const {
    data: getQuestionnaireScoreChangesMetrics,
    error: getQuestionnaireScoreChangesMetricsError,
    loading: loadingQuestionnaireScoreChangeMetrics,
  } = useQuestionnaireAnalyticsScoreChangesMetricsGetQuestionnaireScoreChangesMetricsQuery(
    corporationId && questionnaireId && range
      ? {
          variables: {
            input: {
              questionnaireId,
              shopId: currentShopId,
              corporationId,
              dimension: filterConditions.dimension ?? QuestionnaireScoreChangesDimensionType.Day,
              ...range,
              answererSegments: filterConditions.answerSegments,
            },
          },
        }
      : { skip: true },
  );

  const questionnaireScoreChangesMetrics =
    getQuestionnaireScoreChangesMetrics?.questionnaireScoreChangesMetrics;

  const graphItems: {
    dataKey: QuestionnaireScoreChangesItem;
    stroke: string;
    type: "score" | "count";
  }[] = useMemo(
    () => [
      { dataKey: "shopAverageScore", stroke: "#00638D", type: "score" },
      { dataKey: "corporationAverageScore", stroke: "#C4C4C4", type: "score" },
    ],
    [],
  );

  const graphProps: {
    title: string;
    data: GraphData;
  }[] = useMemo(
    () => [
      {
        title: "再来店意欲",
        data: questionnaireScoreChangesMetrics?.repeatability ?? [],
      },
      {
        title: "接客",
        data: questionnaireScoreChangesMetrics?.customerService ?? [],
      },
      {
        title: "料理",
        data: questionnaireScoreChangesMetrics?.deliciousness ?? [],
      },
      {
        title: "提供速度",
        data: questionnaireScoreChangesMetrics?.speed ?? [],
      },
      {
        title: "清潔感",
        data: questionnaireScoreChangesMetrics?.cleanliness ?? [],
      },
    ],
    [questionnaireScoreChangesMetrics],
  );

  const [displayItems, setDisplayItems] = useState<QuestionnaireScoreChangesItem[]>([
    "shopAverageScore",
  ]);

  const toggleDisplay = useCallback(
    (item: QuestionnaireScoreChangesItem) => {
      displayItems.includes(item)
        ? setDisplayItems(displayItems.filter((displayItem) => displayItem !== item))
        : setDisplayItems([...displayItems, item]);
    },
    [displayItems],
  );

  const shouldShowError = getQuestionnaireError || getQuestionnaireScoreChangesMetricsError;
  const loading = loadingQuestionnaire || loadingQuestionnaireScoreChangeMetrics;

  return (
    <DashboardLayout title="スコア推移">
      <PageHeader
        title="スコア推移"
        footer={
          <ShopMetricsFilter
            filterConditions={filterConditions}
            updateFilterCondition={updateFilterCondition}
            companies={companies}
            loading={getShopsLoading}
          />
        }
      />

      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Wrapper>
        <GraphWrapper>
          {graphProps.map(({ title, data }) => (
            <LineGraph
              key={title}
              title={title}
              data={data}
              loading={loading}
              lines={graphItems}
              dimension={filterConditions.dimension ?? QuestionnaireScoreChangesDimensionType.Day}
              displayItems={displayItems}
              width={880}
              height={320}
            />
          ))}
        </GraphWrapper>
        <DisplaySwitchWrapper>
          {graphItems.map(({ dataKey, stroke }) => (
            <DisplaySwitch key={dataKey}>
              {displayItems.includes(dataKey) ? (
                <StyledEyeOutlined onClick={() => toggleDisplay(dataKey)} />
              ) : (
                <StyledEyeInvisibleOutlined onClick={() => toggleDisplay(dataKey)} />
              )}
              <ColorDot color={stroke} />
              <span>{questionnaireScoreChangesItemMap[dataKey]}</span>
            </DisplaySwitch>
          ))}
        </DisplaySwitchWrapper>
      </Wrapper>
    </DashboardLayout>
  );
};
