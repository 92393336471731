import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditTecAggregationMenuFormItem } from "../useEditTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditTecAggregationMenuFormItem
    label="メニュー名称"
    name="name"
    rules={[{ required: true, max: 25, message: "メニュー名称を25文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditTecAggregationMenuFormItem>
));
