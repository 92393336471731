import React, { memo } from "react";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { Customer } from "pages/AddMessageDelivery/types";

import { TargetCustomers } from "../TargetCustomers";
import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = {
  customers: Customer[];
} & Omit<FormItemProps, "children" | "name">;

export const CSVUploadCustomerListField = memo<Props>(({ customers, ...props }) => (
  <>
    <MessageDeliveryJobFormItem.NonProperty
      shouldUpdate={withFormDependencies(({ uploadedCsv, customerIdsFromCsv }) => [
        uploadedCsv,
        customerIdsFromCsv,
      ])}
      noStyle
    >
      {({ getFieldsValue }) => {
        const { uploadedCsv, customerIdsFromCsv } = getFieldsValue([
          "uploadedCsv",
          "customerIdsFromCsv",
        ]);
        return (
          <>
            <MessageDeliveryJobFormItem
              label="CSV アップロード"
              name="uploadedCsv"
              rules={[{ required: true, message: "CSV をアップロードしてください" }]}
              {...props}
            >
              <Upload fileList={uploadedCsv?.fileList} accept=".csv" maxCount={1}>
                <Button icon={<UploadOutlined />}>ファイルを選択する</Button>
              </Upload>
            </MessageDeliveryJobFormItem>
            <MessageDeliveryJobFormItem name="customerIdsFromCsv" noStyle />
            <TargetCustomers number={customerIdsFromCsv?.length ?? 0} />
          </>
        );
      }}
    </MessageDeliveryJobFormItem.NonProperty>
  </>
));
