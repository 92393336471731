import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { AddPlanForm } from "pages/AddPlan/AddPlanForm";
import {
  useAddPlanGetCategoriesAndPlansQuery,
  useAddPlanGetOrderableTimesQuery,
  useAddPlanGetPlanGroupsQuery,
  useAddPlanInsertPlanMutation,
} from "pages/AddPlan/queries";
import { CreatePlanInputPlanSource, CreatePlanInputTranslationSource } from "types/graphql";

export const AddPlan = () => {
  const navigate = useNavigate();

  const [company] = useCompany();

  const companyId = company?.id;

  const { data: getCategoriesAndPlansData, error: getCategoriesDataError } =
    useAddPlanGetCategoriesAndPlansQuery(companyId ? { variables: { companyId } } : { skip: true });
  const { category: categories, plan: plans } = getCategoriesAndPlansData ?? {
    category: [],
    plan: [],
  };

  const categoryMaps = Object.fromEntries(
    categories.map(({ categoryId, name, shopSideName }) => [categoryId, shopSideName || name]),
  );

  const { data: getOrderableTimesData, error: getOrderableTimesDataError } =
    useAddPlanGetOrderableTimesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const orderableTimes = getOrderableTimesData?.orderableTime ?? [];

  const { data: getPlanGroupsData, error: getPlanGroupsDataError } = useAddPlanGetPlanGroupsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const planGroups = getPlanGroupsData?.planGroup ?? [];

  const [addPlanMutation, { loading: loadingInsertPlan }] = useAddPlanInsertPlanMutation();

  const onSubmit = useCallback(
    async ({
      plan,
      sourcePlanId,
      createTranslationsSource,
    }: {
      plan: CreatePlanInputPlanSource;
      sourcePlanId?: number;
      createTranslationsSource: CreatePlanInputTranslationSource;
    }) => {
      try {
        const { data: addPlanResult } = await addPlanMutation({
          variables: { plan: { createPlanSource: plan, sourcePlanId, createTranslationsSource } },
        });
        const planId = addPlanResult?.createPlan.planId;

        if (planId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/plan/${planId}/option`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addPlanMutation, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const shouldShowAlert =
    getCategoriesDataError || getOrderableTimesDataError || getPlanGroupsDataError;

  return (
    <DashboardLayout
      title="プランを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラン" }],
      }}
    >
      <AddPlanForm
        categories={categories}
        plans={plans}
        orderableTimes={orderableTimes}
        planGroups={planGroups}
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        onClose={goBack}
        loading={loadingInsertPlan}
      />

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
    </DashboardLayout>
  );
};
