import { useCallback } from "react";
import dayjs from "dayjs";
import { isNumber } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import {
  CashRegisterConfig,
  CashRegisterSecurityLevelEnum,
  ServiceChargeConfigInput,
  SpecificTimeSurchargeConfigInput,
} from "types/graphql";

import { Shop, ShopSetInputWithCashRegisterConfig } from "../types";

export type EditCashRegisterConfigFormValues = Pick<
  Shop,
  | "contactInfo"
  | "disableClerkCache"
  | "enableDefaultInflowSourceTag"
  | "enableReserveCashSeparation"
  | "enableAutoFractionalDiscount"
  | "issuer"
  | "shouldOpenDrawerAtOnSitePaymentEveryTime"
  | "eligibleInvoiceIssuerRegistrationNumber"
  | "enableAutoPrintReceipt"
  | "taxOfficeId"
> & {
  showFreeItemInAccountingSlip: Shop["hideFreeItemInAccountingSlip"];
} & Pick<
    CashRegisterConfig,
    "enableRequiringPasswordForDrawerOpen" | "drawerOpenPassword" | "useStera"
  > & {
    serviceChargeConfig?: {
      chargeRate?: number;
    };
  } & {
    specificTimeSurchargeConfig: {
      chargeRate?: number;
      startTime?: dayjs.Dayjs;
      endTime?: dayjs.Dayjs;
    };
  } & {
    requirePasswordOnVoidAndTableClearField: boolean;
  };

const getInitialValues = (shop: Shop): EditCashRegisterConfigFormValues => {
  const showFreeItemInAccountingSlip = !shop.hideFreeItemInAccountingSlip;
  const enableRequiringPasswordForDrawerOpen = Boolean(
    shop.cashRegisterConfig?.enableRequiringPasswordForDrawerOpen,
  );
  const useStera = Boolean(shop.cashRegisterConfig?.useStera);
  const serviceChargeConfig = shop.serviceChargeConfig;
  const specificTimeSurchargeConfig = shop.specificTimeSurchargeConfig;
  const requirePasswordOnVoidAndTableClearField = Boolean(shop.cashRegisterSecurityLevel);
  return {
    ...shop,
    showFreeItemInAccountingSlip,
    enableRequiringPasswordForDrawerOpen,
    useStera,
    serviceChargeConfig: {
      chargeRate: serviceChargeConfig?.chargeRate,
    },
    specificTimeSurchargeConfig: {
      chargeRate: specificTimeSurchargeConfig?.chargeRate,
      startTime: specificTimeSurchargeConfig?.startTime
        ? dayjs(specificTimeSurchargeConfig.startTime, "HH:mm")
        : undefined,
      endTime: specificTimeSurchargeConfig?.endTime
        ? dayjs(specificTimeSurchargeConfig.endTime, "HH:mm")
        : undefined,
    },
    requirePasswordOnVoidAndTableClearField,
  };
};

export const EditCashRegisterConfigFormItem = createFormItem<EditCashRegisterConfigFormValues>();

export const useEditCashRegisterConfigForm = ({
  shop,
  onSubmit,
}: {
  shop: Shop;
  onSubmit: ({
    shop,
    serviceChargeConfig,
    specificTimeSurchargeConfig,
  }: {
    shop: ShopSetInputWithCashRegisterConfig;
    serviceChargeConfig: ServiceChargeConfigInput | null;
    specificTimeSurchargeConfig: SpecificTimeSurchargeConfigInput | null;
  }) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(shop);

  const submit = useCallback(() => {
    const {
      showFreeItemInAccountingSlip,
      serviceChargeConfig: serviceChargeConfigFieldValue,
      specificTimeSurchargeConfig: specificTimeSurchargeConfigFieldValue,
      taxOfficeId,
      requirePasswordOnVoidAndTableClearField,
      ...formValues
    } = form.getFieldsValue() as EditCashRegisterConfigFormValues;

    const serviceChargeConfig = isNumber(serviceChargeConfigFieldValue?.chargeRate)
      ? {
          chargeRate: serviceChargeConfigFieldValue.chargeRate,
        }
      : null;

    const specificTimeSurchargeConfig =
      isNumber(specificTimeSurchargeConfigFieldValue.chargeRate) &&
      specificTimeSurchargeConfigFieldValue.startTime &&
      specificTimeSurchargeConfigFieldValue.endTime
        ? {
            chargeRate: specificTimeSurchargeConfigFieldValue.chargeRate,
            startTime: specificTimeSurchargeConfigFieldValue.startTime.format("HH:mm"),
            endTime: specificTimeSurchargeConfigFieldValue.endTime.format("HH:mm"),
          }
        : null;

    const cashRegisterSecurityLevel = requirePasswordOnVoidAndTableClearField
      ? CashRegisterSecurityLevelEnum.Amendment
      : null;

    onSubmit({
      shop: {
        ...formValues,
        taxOfficeId: taxOfficeId ?? null,
        hideFreeItemInAccountingSlip: !showFreeItemInAccountingSlip,
        cashRegisterSecurityLevel,
      },
      serviceChargeConfig,
      specificTimeSurchargeConfig,
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
