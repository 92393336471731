import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TableAreaSetInput } from "types/graphql";

import { TableArea } from "../types";

import { NameField } from "./NameField";
import { useEditTableAreaForm } from "./useEditTableAreaForm";

type Props = {
  tableArea: TableArea;
  onSubmit: (plan: TableAreaSetInput) => Promise<void>;
  onFormValidationError: (error: unknown) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditTableAreaForm = memo<Props>(
  ({ tableArea, onClose, onSubmit, onFormValidationError, loading }) => {
    const { form, initialValues, submit } = useEditTableAreaForm(tableArea, onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError(e);
      }
    }, [onFormValidationError, submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="tableArea" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
