import React, { memo } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Error = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: #ff4d4f;
`;

export const TableErrorContent = memo<{ message: string }>(({ message }) => (
  <Container>
    <Error>エラー</Error>
    <Spacer size={4} />
    <Tooltip placement="top" title={message}>
      <StyledExclamationCircleOutlined />
    </Tooltip>
  </Container>
));
