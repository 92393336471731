import React, { memo } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatPrice, parsePrice } from "util/price";

import { InputNumber } from "components/Input/InputNumber";
import { Table } from "components/Table";

import { OnSitePaymentDetail } from "../types";

import { useOnSitePaymentDetailsGetDetailTypesQuery } from "./queries";

const { Option } = Select;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

type Props = {
  shopId: string;
  onSitePaymentDetails: OnSitePaymentDetail[];
  updateOnSitePaymentDetail(args: {
    onSitePaymentDetailId: string;
    netPrice?: number;
    onSitePaymentDetailType?: string;
  }): void;
  disabled: boolean;
};

const OnSitePaymentDetailItemLabel = memo<{
  detail: OnSitePaymentDetail;
  onSitePaymentDetailTypes: { label: string; type: string }[];
  updateOnSitePaymentDetail(args: {
    onSitePaymentDetailId: string;
    netPrice?: number;
    onSitePaymentDetailType?: string;
  }): void;
  disabled: boolean;
}>(({ detail, onSitePaymentDetailTypes, updateOnSitePaymentDetail, disabled }) => {
  // NOTE: 過去の detailType が現状存在しているとは限らない
  const types = [
    {
      label: detail.onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?.label ?? "",
      type: detail.onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?.type ?? "",
    },
  ].concat(onSitePaymentDetailTypes.filter((type) => type.type !== detail.onSitePaymentDetailType));

  return (
    <StyledSelect
      defaultValue={detail.onSitePaymentDetailType}
      onChange={(value) =>
        updateOnSitePaymentDetail({
          onSitePaymentDetailId: detail.onSitePaymentDetailId,
          onSitePaymentDetailType: String(value),
        })
      }
      disabled={disabled}
    >
      {types.map((type) => (
        <Option key={type.type} value={type.type}>
          {type.label}
        </Option>
      ))}
    </StyledSelect>
  );
});

const OnSitePaymentDetailItemAmount = memo<{
  detail: OnSitePaymentDetail;
  updateOnSitePaymentDetail(args: {
    onSitePaymentDetailId: string;
    netPrice?: number;
    onSitePaymentDetailType?: string;
  }): void;
  disabled: boolean;
}>(({ detail, updateOnSitePaymentDetail, disabled }) => (
  <InputNumber
    formatter={(value) => formatPrice(Number(value))}
    parser={(value = "") => parsePrice(value)}
    defaultValue={detail.netPrice}
    onChange={(priceString) =>
      updateOnSitePaymentDetail({
        onSitePaymentDetailId: detail.onSitePaymentDetailId,
        netPrice: Number(priceString),
      })
    }
    disabled={disabled}
  />
));

export const OnSitePaymentBreakdownByDetailType = memo<Props>(
  ({ shopId, onSitePaymentDetails, updateOnSitePaymentDetail, disabled }) => {
    const { data } = useOnSitePaymentDetailsGetDetailTypesQuery({ variables: { shopId } });

    const onSitePaymentDetailTypes = data?.onSitePaymentDetailType ?? [];

    const columns: ColumnsType<OnSitePaymentDetail> = [
      {
        title: "支払方法",
        render(_, detail: OnSitePaymentDetail) {
          return (
            <OnSitePaymentDetailItemLabel
              detail={detail}
              onSitePaymentDetailTypes={onSitePaymentDetailTypes}
              updateOnSitePaymentDetail={updateOnSitePaymentDetail}
              disabled={disabled}
            />
          );
        },
      },
      {
        title: "金額",
        render(_, detail: OnSitePaymentDetail) {
          return (
            <OnSitePaymentDetailItemAmount
              detail={detail}
              updateOnSitePaymentDetail={updateOnSitePaymentDetail}
              disabled={disabled}
            />
          );
        },
      },
    ];

    return (
      <Table
        bordered
        dataSource={onSitePaymentDetails}
        columns={columns}
        rowKey={(row) => row.onSitePaymentDetailId}
      />
    );
  },
);
