import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { AddPlanChoiceFormItem } from "../useAddPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddPlanChoiceFormItem
    label="選択肢名 (日本語)"
    name="name"
    rules={[{ required: true, message: "選択肢名 (日本語)を入力してください" }]}
    {...props}
  >
    <Input />
  </AddPlanChoiceFormItem>
));
