import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Button, Col, Divider, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, withFormDependencies } from "components/antd/Form";
import { PageHeader } from "components/antd/PageHeader";
import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { OptionsField } from "components/OptionsField";
import { TranslationDescriptionFields } from "components/TranslationsFields/TranslationDescriptionFields";
import { TranslationFeaturedLabelTextFields } from "components/TranslationsFields/TranslationFeaturedLabelTextFields";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useQueryParams } from "hooks/useQuery";
import { CostTaxMethodField } from "pages/AddMenu/AddMenuForm//CostTaxMethodField";
import { TaxMethodField } from "pages/AddMenu/AddMenuForm//TaxMethodField";
import { CategoryField } from "pages/AddMenu/AddMenuForm/CategoryField";
import { CostPriceField } from "pages/AddMenu/AddMenuForm/CostPriceField";
import { CostTaxRateField } from "pages/AddMenu/AddMenuForm/CostTaxRateField";
import { DescriptionField } from "pages/AddMenu/AddMenuForm/DescriptionField";
import { DisplayTypeFields } from "pages/AddMenu/AddMenuForm/DisplayTypeFields";
import { FeaturedLabelTextField } from "pages/AddMenu/AddMenuForm/FeaturedLabelTextField";
import { MenuTypeField } from "pages/AddMenu/AddMenuForm/MenuTypeField";
import { NameField } from "pages/AddMenu/AddMenuForm/NameField";
import { OpenPriceField } from "pages/AddMenu/AddMenuForm/OpenPriceField";
import { OrderableTimeField } from "pages/AddMenu/AddMenuForm/OrderableTimeField";
import { ReceiptDisplayNameField } from "pages/AddMenu/AddMenuForm/ReceiptDisplayNameField";
import { SelectCopyMenuModal } from "pages/AddMenu/AddMenuForm/SelectCopyMenuModal";
import { ShopSideNameField } from "pages/AddMenu/AddMenuForm/ShopSideNameField";
import { ShouldMergeSlipOptionsField } from "pages/AddMenu/AddMenuForm/ShouldMergeSlipOptionsField";
import { TaxRateField } from "pages/AddMenu/AddMenuForm/TaxRateField";
import {
  AddMenuFormItem,
  AddMenuFormValues,
  DEFAULT_CATEGORY_ID,
  useAddMenuForm,
} from "pages/AddMenu/AddMenuForm/useAddMenuForm";
import { Category, Menu, Option, OrderableTime } from "pages/AddMenu/types";
import {
  CreateMenuInputCategoryMenu,
  CreateMenuInputMenuSource,
  CreateMenuInputTranslationSource,
  TaxMethodEnum,
} from "types/graphql";

import { OrderLimitField } from "./OrderLimitFields/OrderLimitField";
import { OrderLimitForNumPeopleField } from "./OrderLimitFields/OrderLimitForNumPeopleField";
import { OrderLimitPerCustomerField } from "./OrderLimitFields/OrderLimitPerCustomerField";
import { OrderLimitPerTableUserField } from "./OrderLimitFields/OrderLimitPerTableUserField";
import { SinglePriceField } from "./SinglePriceField";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

type Props = {
  categories: Category[];
  menus: Menu[];
  options: Option[];
  categoryMaps: Record<number, string>;
  orderableTimes: OrderableTime[];
  defaultMenuTaxMethod: TaxMethodEnum | undefined;
  defaultCostTaxMethod: TaxMethodEnum | undefined;
  onSubmit: ({
    menu,
    categoryMenuSources,
    sourceMenuId,
    createTranslationsSource,
  }: {
    menu: CreateMenuInputMenuSource;
    categoryMenuSources: CreateMenuInputCategoryMenu[];
    sourceMenuId?: number;
    createTranslationsSource: CreateMenuInputTranslationSource;
  }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loadOptions: () => Promise<unknown>;
  loading: boolean;
};

export const AddMenuForm = memo<Props>(
  ({
    categories,
    options,
    menus,
    categoryMaps,
    orderableTimes,
    defaultMenuTaxMethod,
    defaultCostTaxMethod,
    onSubmit,
    onFormValidationError,
    loadOptions,
    loading,
  }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const navigate = useNavigate();

    const goBack = useCallback(() => navigate(-1), [navigate]);

    const {
      form,
      initialValues,
      isCopied,
      submit,
      copyFromAnotherMenu,
      updateDisplayTypesWithCategoryIds,
    } = useAddMenuForm({
      categories,
      defaultMenuTaxMethod,
      onSubmit,
    });

    const [disableTaxField, setDisableTaxField] = useState(false);

    const [uploadImage, setUploadImage] = useState("");

    const [selectCopyMenuModalVisible, setSelectCopyMenuModalVisible] = useState(false);
    const openSelectCopyMenuModal = useCallback(() => setSelectCopyMenuModalVisible(true), []);
    const closeSelectCopyMenuModal = useCallback(() => setSelectCopyMenuModalVisible(false), []);

    const query = useQueryParams();
    const sourceMenuId = query.get("menuId");

    useEffect(() => {
      if (!sourceMenuId) return;
      if (isCopied) return;

      const menu = menus.find((menu) => menu.menuId === Number(sourceMenuId));
      if (menu) {
        setUploadImage(menu.imageUrl ?? "");
        copyFromAnotherMenu(menu);
      }
    }, [menus, copyFromAnotherMenu, sourceMenuId, isCopied]);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
          sourceMenuId: sourceMenuId ? Number(sourceMenuId) : undefined,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, submit, uploadImage, sourceMenuId, onFormValidationError]);

    const handleClose = useCallback(() => {
      goBack();
    }, [goBack]);

    const handleAddOption = useCallback(
      (option: AddMenuFormValues["options"][number]) => {
        const currentOptions = form.getFieldValue("options") as AddMenuFormValues["options"];
        form.setFieldsValue({ options: [...currentOptions, option] });
      },
      [form],
    );

    const onValuesChange = useCallback(
      (values: Partial<AddMenuFormValues>) => {
        if (values?.taxMethod) {
          setDisableTaxField(values.taxMethod === TaxMethodEnum.NonTaxable);
          return;
        }

        updateDisplayTypesWithCategoryIds(values);
      },
      [setDisableTaxField, updateDisplayTypesWithCategoryIds],
    );

    return (
      <Wrapper>
        <PageHeader
          title="メニューを新規作成"
          onBack={goBack}
          extra={<Button onClick={openSelectCopyMenuModal}>メニュー情報をコピー</Button>}
        />

        <StyledForm
          name="menu"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <FormSection title="商品画像">
            <ImageField
              image={uploadImage}
              setUploadImage={setUploadImage}
              uploadImageApiKey="menu"
              formName="AddMenuForm"
              isMovieAccepted
            />
          </FormSection>

          <FormSection title="メニュー情報">
            <Row gutter={24}>
              <Col span={12}>
                <NameField />
              </Col>
              <Col span={12}>
                <ReceiptDisplayNameField />
              </Col>
              <Col span={12}>
                <ShopSideNameField />
              </Col>
            </Row>

            <CategoryField data-cy="menu_category_select" categories={categories} />
            <OrderableTimeField
              data-cy="menu_orderableTime_select"
              orderableTimes={orderableTimes}
            />
            <MenuTypeField />
            <DescriptionField />

            <Row gutter={24}>
              <Col span={12}>
                <FeaturedLabelTextField />
              </Col>
            </Row>

            <DisplayTypeFields
              categoryMaps={categoryMaps}
              defaultCategoryId={DEFAULT_CATEGORY_ID}
            />
          </FormSection>

          <FormSection title="販売価格と原価">
            <OpenPriceField />

            <Row gutter={24}>
              <Col span={6}>
                <TaxMethodField />
              </Col>
              <Col span={6}>
                <TaxRateField disabled={disableTaxField} />
              </Col>
            </Row>

            <AddMenuFormItem.NonProperty
              shouldUpdate={withFormDependencies(({ openPrice }) => [openPrice])}
              noStyle
            >
              {({ getFieldValue }) => <SinglePriceField openPrice={getFieldValue("openPrice")} />}
            </AddMenuFormItem.NonProperty>

            <Row gutter={24}>
              <Col span={6}>
                <CostTaxMethodField value={defaultCostTaxMethod} />
              </Col>
              <Col span={6}>
                <CostTaxRateField />
              </Col>
              <Col span={6}>
                <CostPriceField />
              </Col>
            </Row>
          </FormSection>

          <CollapsableFormSection title="多言語設定">
            <TranslationNameFields name="nameSources" originalFieldName="name" label="メニュー名" />

            <Divider />

            <TranslationFeaturedLabelTextFields
              originalFieldName="featuredLabelText"
              label="特集ラベル"
            />

            <Divider />

            <TranslationDescriptionFields originalFieldName="description" label="説明文" />
          </CollapsableFormSection>

          <FormSection>
            <OptionsField
              formPropName="options"
              options={options}
              loadOptions={loadOptions}
              onAddOption={handleAddOption}
              title="オプション"
            />
          </FormSection>

          {isFeatureEnabled("showShouldMergeSlipOptionsFields") && (
            <FormSection title="キッチン伝票">
              <ShouldMergeSlipOptionsField />
            </FormSection>
          )}

          <FormSection title="注文上限">
            <OrderLimitPerTableUserField />
            <OrderLimitPerCustomerField />
            <OrderLimitField />
            <OrderLimitForNumPeopleField />
          </FormSection>

          <Footer>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              登録
            </Button>
          </Footer>

          <SelectCopyMenuModal
            visible={selectCopyMenuModalVisible}
            menus={menus}
            onDismiss={closeSelectCopyMenuModal}
            onSubmit={(menu) => {
              setUploadImage(menu.imageUrl ?? "");
              copyFromAnotherMenu(menu);
              navigate(`/menu/add?menuId=${menu.menuId}`, { replace: true });
            }}
          />
        </StyledForm>
      </Wrapper>
    );
  },
);
