import { useMemo, useState } from "react";
import { uniq } from "util/array";

type Pagination = {
  defaultPageSize: number;
  defaultCurrent: number;
  showSizeChanger: boolean;
};

export const useSelectedTableShopIds = <Shop extends { shopId: string }>({
  pagination,
  shops,
}: {
  pagination: Pagination;
  shops: Shop[];
}) => {
  const [selectedShopIds, setSelectedShopIds] = useState<string[]>([]);

  const currentPageShopIds = useMemo(() => {
    const shopsStartIndex = (pagination.defaultCurrent - 1) * pagination.defaultPageSize;
    const shopsEndIndex = pagination.defaultCurrent * pagination.defaultPageSize;

    return shops.slice(shopsStartIndex, shopsEndIndex).map((shop) => shop.shopId);
  }, [shops, pagination.defaultCurrent, pagination.defaultPageSize]);

  const currentPageSelectedShopIds = useMemo(
    () => selectedShopIds.filter((shopId) => currentPageShopIds.includes(shopId)),
    [selectedShopIds, currentPageShopIds],
  );

  const clearCurrentPageSelectedShopIds = () => {
    const filtered = selectedShopIds.filter(
      (shopId) => !currentPageSelectedShopIds.includes(shopId),
    );
    setSelectedShopIds(filtered);
  };

  const allCheckboxIndeterminate =
    currentPageSelectedShopIds.length !== 0 &&
    currentPageSelectedShopIds.length !== currentPageShopIds.length;

  const allCheckboxChecked =
    currentPageSelectedShopIds.length !== 0 &&
    currentPageSelectedShopIds.length === currentPageShopIds.length;

  const onAllCheckboxClick = () => {
    if (allCheckboxChecked) {
      clearCurrentPageSelectedShopIds();
      return;
    }

    setSelectedShopIds(uniq([...selectedShopIds, ...currentPageShopIds]));
  };

  return {
    selectedShopIds,
    setSelectedShopIds,
    currentPageSelectedShopIds,
    clearCurrentPageSelectedShopIds,
    allCheckboxProps: {
      indeterminate: allCheckboxIndeterminate,
      checked: allCheckboxChecked,
      onClick: onAllCheckboxClick,
    },
  };
};
