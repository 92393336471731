import React, { memo } from "react";
import { TreeSelect } from "antd";

import { Form } from "components/antd/Form";
import {
  EditPlanChoiceCountAsFirstOrderMenuQuantityFormItem,
  EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues,
  useEditPlanChoiceCountAsFirstOrderMenuQuantityForm,
} from "pages/PlanFirstOrderCategoryMenus/EditPlanChoiceCountAsFirstOrderMenuQuantityForm/useEditPlanChoiceCountAsFirstOrderMenuQuantityForm";
import { Plan } from "pages/PlanFirstOrderCategoryMenus/types";

type Props = {
  plan: Plan;
  onChange: (
    planChoiceCountAsFirstOrderMenuQuantity: EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues,
  ) => void;
  disabled: boolean;
};

export const EditPlanChoiceCountAsFirstOrderMenuQuantityForm = memo<Props>(
  ({ plan, onChange, disabled }) => {
    const {
      form,
      initialValues,
      change: onValuesChange,
    } = useEditPlanChoiceCountAsFirstOrderMenuQuantityForm(plan, onChange);

    const treeData = plan.planOptions.map(({ planOptionId: key, name: title, planChoices }) => ({
      key,
      value: key,
      title,
      disableCheckbox: true,
      selectable: false,
      children: planChoices.map(({ planChoiceId: key, name: title }) => ({
        key,
        value: key,
        title,
        selectable: false,
      })),
    }));

    return (
      <Form
        name="planChoiceCountAsFirstOrderMenuQuantity"
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <EditPlanChoiceCountAsFirstOrderMenuQuantityFormItem
          label="自動注文数として数える選択肢"
          name="countAsFirstOrderMenuQuantity"
        >
          <TreeSelect treeData={treeData} treeCheckable disabled={disabled} />
        </EditPlanChoiceCountAsFirstOrderMenuQuantityFormItem>
      </Form>
    );
  },
);
