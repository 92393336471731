import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNumber } from "util/type/primitive";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { EditMenuForm } from "pages/EditMenu/EditMenuForm";
import {
  useEditMenuGetCategoriesQuery,
  useEditMenuGetCompanyQuery,
  useEditMenuGetMenuQuery,
  useEditMenuGetOrderableTimesQuery,
  useEditMenuUpdateMenuMutation,
} from "pages/EditMenu/queries";
import { CategoryMenuInsertInput, MenuSetInput } from "types/graphql";

export const EditMenu = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
    error: errorGetMenuData,
  } = useEditMenuGetMenuQuery(!Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true });
  const menu = getMenuData?.menu?.[0];

  setCompany(menu?.companyId);

  const companyId = menu?.companyId;

  const { data: getCategoriesData, error: errorGetCategoriesData } = useEditMenuGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  // おすすめ用カテゴリーは表示しないが、削除除外対象には含めるため、ここでフィルターする
  const categories = useMemo(
    () =>
      getCategoriesData?.category.filter(({ menuRecommendationMeta }) => !menuRecommendationMeta) ??
      [],
    [getCategoriesData?.category],
  );
  const categoryMaps = Object.fromEntries(
    categories.map(({ categoryId, name, shopSideName }) => [categoryId, shopSideName || name]),
  );

  const { data: getOrderableTimesData, error: errorGetOrderableTimesData } =
    useEditMenuGetOrderableTimesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const orderableTimes = getOrderableTimesData?.orderableTime ?? [];

  const { data: getCompanyData, error: errorGetCompanyData } = useEditMenuGetCompanyQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const defaultCostTaxMethod = getCompanyData?.company?.[0]?.defaultCostTaxMethod;

  const [updateMenuMutation, { loading: loadingUpdateMenu }] = useEditMenuUpdateMenuMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async ({
      menu,
      categoryMenus,
    }: {
      menu: MenuSetInput;
      categoryMenus: CategoryMenuInsertInput[];
    }) => {
      if (!Number.isNaN(menuId)) {
        try {
          const recommendationCategoryIds =
            getCategoriesData?.category
              .filter(({ menuRecommendationMeta }) => menuRecommendationMeta)
              .map(({ categoryId }) => categoryId) ?? [];

          const categoryIds = [
            ...categoryMenus.map(({ _categoryId }) => _categoryId),
            // おすすめ用カテゴリーは削除対象に含めない
            ...recommendationCategoryIds,
          ].filter(isNumber);
          await updateMenuMutation({
            variables: { menuId, menu, categoryIds, categoryMenus },
          });

          await refetchMenu();

          message.success("編集を保存しました");
          goBack();
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [menuId, getCategoriesData?.category, updateMenuMutation, goBack, refetchMenu],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const shouldShowNetworkErrorAlert =
    errorGetOrderableTimesData || errorGetMenuData || errorGetCategoriesData || errorGetCompanyData;

  const canEditAllShopMenus = menu?.totalDealingShopCount === menu?.shopMenus.length;
  const isEditMenuFeatureEnabled = isFeatureEnabled("editMenu");

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(shouldShowNetworkErrorAlert)}
        shouldShowManagingShopErrorAlert={isEditMenuFeatureEnabled && !canEditAllShopMenus}
      />
      {loadingMenu && <Loading height={300} />}
      {menu && (
        <EditMenuForm
          menu={menu}
          categories={categories}
          categoryMaps={categoryMaps}
          orderableTimes={orderableTimes}
          defaultCostTaxMethod={defaultCostTaxMethod}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          onClose={goBack}
          loading={loadingUpdateMenu}
          disabled={!isEditMenuFeatureEnabled || !canEditAllShopMenus}
        />
      )}
    </DashboardLayout>
  );
};
