import React, { memo } from "react";
import { sumBy } from "util/array";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { SalesBreakdownByPaymentMethod } from "pages/ShopDailyCashRegisterBalancing/types";

type Props = {
  loading?: boolean;
  salesBreakdownByPaymentMethod?: SalesBreakdownByPaymentMethod;
};

export const SalesBreakdownByPaymentMethodTable = memo<Props>(
  ({ loading, salesBreakdownByPaymentMethod = [] }) => {
    const salesBreakdownByPaymentMethodWithTotal: SalesBreakdownByPaymentMethod = [
      ...salesBreakdownByPaymentMethod,
      { label: "合計", type: "", sales: sumBy(salesBreakdownByPaymentMethod, "sales") },
    ];

    const columns = [
      {
        title: "金種",
        render(_: unknown, { label }: SalesBreakdownByPaymentMethod[number]) {
          return label;
        },
      },
      {
        title: "金額",
        align: "right",
        render(_: unknown, { sales }: SalesBreakdownByPaymentMethod[number]) {
          return formatPrice(sales);
        },
      } as const,
    ];

    return (
      <Table
        rowKey="type"
        columns={columns}
        dataSource={salesBreakdownByPaymentMethodWithTotal}
        loading={loading}
        bordered
        title={() => "支払方法ごとの売上"}
        pagination={false}
        data-cy="sales-breakdown-by-payment-method-table"
      />
    );
  },
);
