import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SignInSignInToDashboardAccount = gql`
    mutation SignInSignInToDashboardAccount {
  signInToDashboardAccount
}
    `;
export type SignInSignInToDashboardAccountMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SignInSignInToDashboardAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'signInToDashboardAccount'>
);


export const SignInSignInToDashboardAccountDocument = gql`
    mutation SignInSignInToDashboardAccount {
  signInToDashboardAccount
}
    `;
export type SignInSignInToDashboardAccountMutationFn = Apollo.MutationFunction<SignInSignInToDashboardAccountMutation, SignInSignInToDashboardAccountMutationVariables>;

/**
 * __useSignInSignInToDashboardAccountMutation__
 *
 * To run a mutation, you first call `useSignInSignInToDashboardAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInSignInToDashboardAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInSignInToDashboardAccountMutation, { data, loading, error }] = useSignInSignInToDashboardAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignInSignInToDashboardAccountMutation(baseOptions?: Apollo.MutationHookOptions<SignInSignInToDashboardAccountMutation, SignInSignInToDashboardAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInSignInToDashboardAccountMutation, SignInSignInToDashboardAccountMutationVariables>(SignInSignInToDashboardAccountDocument, options);
      }
export type SignInSignInToDashboardAccountMutationHookResult = ReturnType<typeof useSignInSignInToDashboardAccountMutation>;
export type SignInSignInToDashboardAccountMutationResult = Apollo.MutationResult<SignInSignInToDashboardAccountMutation>;
export type SignInSignInToDashboardAccountMutationOptions = Apollo.BaseMutationOptions<SignInSignInToDashboardAccountMutation, SignInSignInToDashboardAccountMutationVariables>;