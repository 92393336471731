import React, { ChangeEvent, memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { Category } from "../../../types";

export type FilterConditions = {
  name?: string;
  categoryIds?: number[];
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  categories: Category[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const MenuFilter = memo<Props>(
  ({
    categories,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => {
    const handleSearchNameChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => updateFilterCondition({ name: e.target.value }),
      [updateFilterCondition],
    );

    const handleCategoryIdsChange = useCallback(
      (newCategoryIds: number[]) => updateFilterCondition({ categoryIds: newCategoryIds }),
      [updateFilterCondition],
    );

    return (
      <Wrapper>
        <Col flex="1 0">
          <Input
            allowClear
            placeholder="メニュー名"
            value={filterConditions.name}
            onChange={handleSearchNameChange}
          />
        </Col>
        <Col flex="1 0">
          <Select<number[]>
            mode="multiple"
            allowClear
            placeholder="カテゴリー"
            defaultValue={[]}
            value={filterConditions.categoryIds ?? []}
            onChange={handleCategoryIdsChange}
            optionFilterProp="label"
            options={categories.map(({ categoryId, name }) => ({
              label: name,
              value: categoryId,
            }))}
          />
        </Col>
        <Col flex="none">
          <Tooltip title="フィルター条件をクリア">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={hasFilterConditions}
              onClick={clearFilterConditions}
            />
          </Tooltip>
        </Col>
      </Wrapper>
    );
  },
);
