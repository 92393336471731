import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Descriptions } from "antd";
import { red } from "@ant-design/colors";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { TecAggregationPlanHeader } from "components/PageHeader/TecAggregationPlanHeader";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { EditTecAggregationPlanForm } from "./EditTecAggregationPlanForm";
import {
  useEditTecAggregationPlanGetMenuCodeQuery,
  useEditTecAggregationPlanGetPlanQuery,
  useEditTecAggregationPlanUpdateTecAggregationMenuMutation,
} from "./queries";

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const EditTecAggregationPlan = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const _planId = Number(id);
  const navigate = useNavigate();

  const [errorCodes, setErrorCodes] = useState<{
    name: string;
    menuCode: string;
    dpName?: string | null;
    dpCode?: string | null;
    gpCode?: string | null;
    gpName?: string | null;
  } | null>(null);

  const {
    data: getPlanData,
    loading: loadingPlan,
    refetch: refetchPlan,
  } = useEditTecAggregationPlanGetPlanQuery(
    !Number.isNaN(_planId) ? { variables: { planId: _planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];
  const planId = plan?.id;

  const tecAggregationMenu = plan?.tecAggregationMenu;

  const companyId = plan?.companyId;
  setCompany(companyId);

  const { data: getMenuCodesData, loading: loadingGetMenuCodes } =
    useEditTecAggregationPlanGetMenuCodeQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );

  const [updateTecAggregationMenuMutation, { loading: loadingUpdateTecAggregationMenu }] =
    useEditTecAggregationPlanUpdateTecAggregationMenuMutation();

  const onSubmit = useCallback(
    async (
      input: Pick<
        DashboardAccountUpsertTecAggregationMenuInput,
        "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
      >,
    ) => {
      if (!company) return;

      setErrorCodes(null);

      const id = tecAggregationMenu?.id ?? uuidv4();
      const tecAggregationMenuDto = {
        id,
        planId,
        _planId,
        companyId: company.id,
        ...input,
      };

      try {
        const result = await updateTecAggregationMenuMutation({
          variables: { input: tecAggregationMenuDto },
        });

        await refetchPlan();

        if (
          result.data?.upsertTecAggregationMenu.__typename ===
          "DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode"
        ) {
          const { name, menuCode, dpCode, dpName, gpCode, gpName } =
            result.data.upsertTecAggregationMenu;
          setErrorCodes({
            name,
            menuCode,
            dpCode,
            dpName,
            gpCode,
            gpName,
          });

          message.error("編集の保存に失敗しました");
          return;
        }

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      _planId,
      company,
      planId,
      tecAggregationMenu?.id,
      updateTecAggregationMenuMutation,
      refetchPlan,
    ],
  );

  const adoptMenuCodeAutomatically = useCallback(() => {
    const menuCodes = (getMenuCodesData?.tecAggregationMenu ?? [])
      .map((c) => (!isNaN(Number(c.menuCode)) ? Number(c.menuCode) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? (Math.max(...menuCodes) + 1).toString() : "1";
  }, [getMenuCodesData?.tecAggregationMenu]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingPlan || loadingGetMenuCodes;

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "連携プラン" }],
      }}
    >
      <TecAggregationPlanHeader plan={plan ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}

      {errorCodes && (
        <>
          <Alert
            message="以下のコード設定と競合しています"
            description={
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    {
                      label: "メニュー名",
                      children: errorCodes.name,
                      contentStyle: { backgroundColor: "white" },
                    },
                    {
                      label: "メニューコード",
                      children: errorCodes.menuCode,
                      contentStyle: { backgroundColor: "white" },
                    },
                    errorCodes.dpCode
                      ? {
                          label: "部門コード",
                          children: errorCodes.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.dpName
                      ? {
                          label: "部門名称",
                          children: errorCodes.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpCode
                      ? {
                          label: "分類コード",
                          children: errorCodes.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpName
                      ? {
                          label: "分類名称",
                          children: errorCodes.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            }
            type="error"
          />
          <Spacer size={10} />
        </>
      )}

      {plan && (
        <EditTecAggregationPlanForm
          plan={plan}
          onSubmit={onSubmit}
          onClose={goBack}
          adoptMenuCodeAutomatically={adoptMenuCodeAutomatically}
          loading={loadingUpdateTecAggregationMenu}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
