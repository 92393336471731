import React, { memo } from "react";

import { RouteName } from "components/Router";
import { useCanAccess } from "hooks/useCanAccess";
import { Forbidden } from "pages/Forbidden";

type Props = { route: RouteName; component: () => React.ReactElement | null };

export const PageElement = memo<Props>(({ route, component: Component }) => {
  const { canAccess, isInitialized } = useCanAccess();
  const canAccessPage = canAccess(route);

  if (!isInitialized) return null;

  return canAccessPage ? <Component /> : <Forbidden />;
});
