import React, { memo } from "react";
import styled from "styled-components";
import { Descriptions } from "antd";
import dayjs from "dayjs";
import { isNotNull } from "util/type/primitive";

import { grey } from "constants/colors";
import { Maybe } from "types/Maybe";

const StyledDescriptions = styled(Descriptions)`
  background-color: ${grey[0]};
`;

type Props = {
  clerkName?: string;
  onSitePaymentId?: number;
  date: string;
  voidedAt?: string;
  accountingSlipId?: Maybe<number>;
};

export const OnSitePaymentSummary = memo<Props>(
  ({ clerkName, onSitePaymentId, date, accountingSlipId, voidedAt }) => {
    const descriptionItems = [
      {
        key: "onSitePaymentId",
        label: "会計ID",
        children: onSitePaymentId,
      },
      {
        key: "date",
        label: "会計時刻",
        children: dayjs(date).format("YYYY年M月D日 H時m分"),
      },
      voidedAt
        ? {
            key: "voidedAt",
            label: "取消時刻",
            children: dayjs(voidedAt).format("YYYY年M月D日 H時m分"),
          }
        : null,
      {
        key: "accountingSlipId",
        label: "レシート番号",
        children: accountingSlipId,
      },
      {
        key: "clerkName",
        label: "担当スタッフ",
        children: clerkName,
      },
    ].filter(isNotNull);

    return <StyledDescriptions bordered column={1} items={descriptionItems} />;
  },
);
