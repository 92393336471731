import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const LineOfficialAccountSummaries = gql`
    query LineOfficialAccountSummaries($_companyId: Int!) {
  lineOfficialAccountSummaries(input: {_companyId: $_companyId}) {
    id: lineOfficialAccountId
    lineOfficialAccountId
    lineId
    channelName
    pictureUrl
    quotaLimit
    followers
    targetedReaches
    blocks
    isInvalidAccount
  }
}
    `;
export type LineOfficialAccountSummariesQueryVariables = Types.Exact<{
  _companyId: Types.Scalars['Int'];
}>;


export type LineOfficialAccountSummariesQuery = (
  { __typename?: 'query_root' }
  & { lineOfficialAccountSummaries: Array<(
    { __typename?: 'LineOfficialAccountSummary' }
    & Pick<Types.LineOfficialAccountSummary, 'lineOfficialAccountId' | 'lineId' | 'channelName' | 'pictureUrl' | 'quotaLimit' | 'followers' | 'targetedReaches' | 'blocks' | 'isInvalidAccount'>
    & { id: Types.LineOfficialAccountSummary['lineOfficialAccountId'] }
  )> }
);


export const LineOfficialAccountSummariesDocument = gql`
    query LineOfficialAccountSummaries($_companyId: Int!) {
  lineOfficialAccountSummaries(input: {_companyId: $_companyId}) {
    id: lineOfficialAccountId
    lineOfficialAccountId
    lineId
    channelName
    pictureUrl
    quotaLimit
    followers
    targetedReaches
    blocks
    isInvalidAccount
  }
}
    `;

/**
 * __useLineOfficialAccountSummariesQuery__
 *
 * To run a query within a React component, call `useLineOfficialAccountSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineOfficialAccountSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineOfficialAccountSummariesQuery({
 *   variables: {
 *      _companyId: // value for '_companyId'
 *   },
 * });
 */
export function useLineOfficialAccountSummariesQuery(baseOptions: Apollo.QueryHookOptions<LineOfficialAccountSummariesQuery, LineOfficialAccountSummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LineOfficialAccountSummariesQuery, LineOfficialAccountSummariesQueryVariables>(LineOfficialAccountSummariesDocument, options);
      }
export function useLineOfficialAccountSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LineOfficialAccountSummariesQuery, LineOfficialAccountSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LineOfficialAccountSummariesQuery, LineOfficialAccountSummariesQueryVariables>(LineOfficialAccountSummariesDocument, options);
        }
export type LineOfficialAccountSummariesQueryHookResult = ReturnType<typeof useLineOfficialAccountSummariesQuery>;
export type LineOfficialAccountSummariesLazyQueryHookResult = ReturnType<typeof useLineOfficialAccountSummariesLazyQuery>;
export type LineOfficialAccountSummariesQueryResult = Apollo.QueryResult<LineOfficialAccountSummariesQuery, LineOfficialAccountSummariesQueryVariables>;