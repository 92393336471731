import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditTecAggregationMediaMapFormItem } from "../useEditTecAggregationMediaMapForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const MediaNameField = memo<Props>((props) => (
  <EditTecAggregationMediaMapFormItem
    label="項目名"
    name="mediaName"
    rules={[{ required: true, message: "項目名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditTecAggregationMediaMapFormItem>
));
