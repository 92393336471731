import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { LineOfficialAccount } from "../types";

import { ChannelAccessTokenField } from "./ChannelAccessTokenField";
import { ChannelIdField } from "./ChannelIdField";
import { ChannelSecretField } from "./ChannelSecretField";
import { LineIdField } from "./LineIdField";
import { useEditLineOfficialAccountForm } from "./useEditLineOfficialAccountForm";

type Props = {
  lineOfficialAccount: LineOfficialAccount;
};

export const EditLineOfficialAccountForm = memo<Props>(({ lineOfficialAccount }) => {
  const { form, initialValues, loading, submit, goBack } =
    useEditLineOfficialAccountForm(lineOfficialAccount);
  return (
    <>
      <FormContent>
        <Form
          name="lineOfficialAccount"
          form={form}
          initialValues={initialValues}
          layout="vertical"
        >
          <LineIdField />
          <ChannelAccessTokenField />
          <ChannelIdField />
          <ChannelSecretField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={goBack}>キャンセル</Button>
        <Button type="primary" loading={loading} onClick={submit}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
