import React, { useCallback, useMemo, useState } from "react";
import { Alert } from "antd";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { toDataURL } from "qrcode";
import { stringify } from "query-string";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { getSelfWebUrl } from "util/selfWeb";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { GenerateQrCodesForm } from "pages/GenerateQrCodes/GenerateQrCodesForm";
import { GenerateQrCodesFormValues } from "pages/GenerateQrCodes/GenerateQrCodesForm/useGenerateQrCodesForm";
import {
  useGenerateQrCodesGetShopsQuery,
  useGenerateQrCodesGetTablesQuery,
} from "pages/GenerateQrCodes/queries";

const getQrFileName = (index: number, tableName: string) =>
  `${String(index + 1).padStart(4, "0")}_${tableName}.png`;

const saveQrCodeListCsv = (
  zipPath: string,
  zipName: string,
  qrcodes: { tableName: string; fileName: string }[],
) => {
  const zipPathWithoutSlash = zipPath[zipPath.length - 1] === "/" ? zipPath.slice(0, -1) : zipPath;

  const header = ["tableName", "@QRコード"].join(",");
  const csvRows = qrcodes.map(({ tableName, fileName }) => {
    const filePath = `${zipPathWithoutSlash}/${zipName}/${fileName}`;
    return [tableName, filePath].join(",");
  });
  const csvText = [header, ...csvRows].join("\n");

  const blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });

  saveAs(blob, `${zipName}.csv`);
};

const saveQrCodes = async (name: string, urls: { fileName: string; url: string }[]) => {
  const zip = new JSZip();

  await Promise.all(
    urls.map(async ({ fileName, url }) => {
      const image = await toDataURL(url);
      zip.file(fileName, image.substring(22), { base64: true });
    }),
  );

  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, `${name}.zip`);
};

const getWebQRUrl = ({ shopId, tableCode }: { shopId: string; tableCode: string }): string => {
  const queryParams = { shopId, tableCode };

  return getSelfWebUrl(`?${stringify(queryParams)}`);
};

const getRedirectQRUrl = ({
  shopId,
  tableCode,
  liffId,
}: {
  shopId: string;
  tableCode: string;
  liffId: string | undefined;
}): string => {
  const queryParams = {
    shopId,
    tableCode,
    ...(liffId ? { liffId } : {}),
  };

  return getSelfWebUrl(`gateway?${stringify(queryParams)}`);
};

export const GenerateQrCodes = () => {
  const [formValues, setFormValues] = useState<GenerateQrCodesFormValues | null>(null);
  const { shopId } = formValues ?? {};

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getShopsData,
    loading: loadingShops,
    error: getShopsDataError,
  } = useGenerateQrCodesGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });

  const shouldShowAlert = getShopsDataError;

  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData]);

  const { data: getTablesData, loading: loadingTables } = useGenerateQrCodesGetTablesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const tables = useMemo(() => getTablesData?.table ?? [], [getTablesData]);

  const download = useCallback(
    ({ shopId, zipPath, fileNamePrefix }: GenerateQrCodesFormValues) => {
      const shop = shops.find((shop) => shop.shopId === shopId);
      const liffId = shop?.lineChannelConfig?.liffId;

      const miniQrCodes = tables.map(({ shopId, name, code }, index) => ({
        tableName: name,
        fileName: getQrFileName(index, name),
        url: `https://liff.line.me/${liffId}?shopId=${shopId}&tableCode=${code}`,
      }));
      const webQrCodes = tables.map(({ shopId, name, code }, index) => ({
        tableName: name,
        fileName: getQrFileName(index, name),
        url: getWebQRUrl({ shopId, tableCode: code }),
      }));
      const redirectQrCodes = tables.map(({ shopId, name, code }, index) => ({
        tableName: name,
        fileName: getQrFileName(index, name),
        url: getRedirectQRUrl({ shopId, tableCode: code, liffId }),
      }));

      const miniQrFolderName = `${fileNamePrefix}_mini_qr`;
      saveQrCodes(miniQrFolderName, miniQrCodes);
      saveQrCodeListCsv(zipPath, miniQrFolderName, miniQrCodes);

      const webQrFolderName = `${fileNamePrefix}_web_qr`;
      saveQrCodes(webQrFolderName, webQrCodes);
      saveQrCodeListCsv(zipPath, webQrFolderName, webQrCodes);

      const redirectQrFolderName = `${fileNamePrefix}_redirect_qr`;
      saveQrCodes(`${fileNamePrefix}_redirect_qr`, redirectQrCodes);
      saveQrCodeListCsv(zipPath, redirectQrFolderName, redirectQrCodes);
    },
    [shops, tables],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const canSubmit = Boolean(!loadingShops && !loadingTables && formValues);

  return (
    <DashboardLayout title="QR コードを生成">
      <PageHeader title="QR コードを生成" />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <GenerateQrCodesForm
        shops={shops}
        canSubmit={canSubmit}
        onFormValidationError={onFormValidationError}
        onChange={setFormValues}
        onSubmit={download}
      />
    </DashboardLayout>
  );
};
