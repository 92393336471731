import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditEbicaConfigGetEbicaConfig = gql`
    query EditEbicaConfigGetEbicaConfig($shopId: uuid!) {
  ebicaConfig(where: {shopId: {_eq: $shopId}}) {
    id
    enabled
    shopId
    walkInStayTimeInMinute
    ebicaShopId
  }
}
    `;
export const EditEbicaConfigUpsertEbicaConfig = gql`
    mutation EditEbicaConfigUpsertEbicaConfig($input: ebicaConfig_insert_input!) {
  insert_ebicaConfig_one(
    object: $input
    on_conflict: {constraint: ebicaConfig_shopId_key, update_columns: [ebicaShopId, walkInStayTimeInMinute]}
  ) {
    id
  }
}
    `;
export type EditEbicaConfigGetEbicaConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditEbicaConfigGetEbicaConfigQuery = (
  { __typename?: 'query_root' }
  & { ebicaConfig: Array<(
    { __typename?: 'ebicaConfig' }
    & Pick<Types.EbicaConfig, 'id' | 'enabled' | 'shopId' | 'walkInStayTimeInMinute' | 'ebicaShopId'>
  )> }
);

export type EditEbicaConfigUpsertEbicaConfigMutationVariables = Types.Exact<{
  input: Types.EbicaConfigInsertInput;
}>;


export type EditEbicaConfigUpsertEbicaConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ebicaConfig_one?: Types.Maybe<(
    { __typename?: 'ebicaConfig' }
    & Pick<Types.EbicaConfig, 'id'>
  )> }
);


export const EditEbicaConfigGetEbicaConfigDocument = gql`
    query EditEbicaConfigGetEbicaConfig($shopId: uuid!) {
  ebicaConfig(where: {shopId: {_eq: $shopId}}) {
    id
    enabled
    shopId
    walkInStayTimeInMinute
    ebicaShopId
  }
}
    `;

/**
 * __useEditEbicaConfigGetEbicaConfigQuery__
 *
 * To run a query within a React component, call `useEditEbicaConfigGetEbicaConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditEbicaConfigGetEbicaConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditEbicaConfigGetEbicaConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditEbicaConfigGetEbicaConfigQuery(baseOptions: Apollo.QueryHookOptions<EditEbicaConfigGetEbicaConfigQuery, EditEbicaConfigGetEbicaConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditEbicaConfigGetEbicaConfigQuery, EditEbicaConfigGetEbicaConfigQueryVariables>(EditEbicaConfigGetEbicaConfigDocument, options);
      }
export function useEditEbicaConfigGetEbicaConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditEbicaConfigGetEbicaConfigQuery, EditEbicaConfigGetEbicaConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditEbicaConfigGetEbicaConfigQuery, EditEbicaConfigGetEbicaConfigQueryVariables>(EditEbicaConfigGetEbicaConfigDocument, options);
        }
export type EditEbicaConfigGetEbicaConfigQueryHookResult = ReturnType<typeof useEditEbicaConfigGetEbicaConfigQuery>;
export type EditEbicaConfigGetEbicaConfigLazyQueryHookResult = ReturnType<typeof useEditEbicaConfigGetEbicaConfigLazyQuery>;
export type EditEbicaConfigGetEbicaConfigQueryResult = Apollo.QueryResult<EditEbicaConfigGetEbicaConfigQuery, EditEbicaConfigGetEbicaConfigQueryVariables>;
export const EditEbicaConfigUpsertEbicaConfigDocument = gql`
    mutation EditEbicaConfigUpsertEbicaConfig($input: ebicaConfig_insert_input!) {
  insert_ebicaConfig_one(
    object: $input
    on_conflict: {constraint: ebicaConfig_shopId_key, update_columns: [ebicaShopId, walkInStayTimeInMinute]}
  ) {
    id
  }
}
    `;
export type EditEbicaConfigUpsertEbicaConfigMutationFn = Apollo.MutationFunction<EditEbicaConfigUpsertEbicaConfigMutation, EditEbicaConfigUpsertEbicaConfigMutationVariables>;

/**
 * __useEditEbicaConfigUpsertEbicaConfigMutation__
 *
 * To run a mutation, you first call `useEditEbicaConfigUpsertEbicaConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEbicaConfigUpsertEbicaConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEbicaConfigUpsertEbicaConfigMutation, { data, loading, error }] = useEditEbicaConfigUpsertEbicaConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditEbicaConfigUpsertEbicaConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditEbicaConfigUpsertEbicaConfigMutation, EditEbicaConfigUpsertEbicaConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditEbicaConfigUpsertEbicaConfigMutation, EditEbicaConfigUpsertEbicaConfigMutationVariables>(EditEbicaConfigUpsertEbicaConfigDocument, options);
      }
export type EditEbicaConfigUpsertEbicaConfigMutationHookResult = ReturnType<typeof useEditEbicaConfigUpsertEbicaConfigMutation>;
export type EditEbicaConfigUpsertEbicaConfigMutationResult = Apollo.MutationResult<EditEbicaConfigUpsertEbicaConfigMutation>;
export type EditEbicaConfigUpsertEbicaConfigMutationOptions = Apollo.BaseMutationOptions<EditEbicaConfigUpsertEbicaConfigMutation, EditEbicaConfigUpsertEbicaConfigMutationVariables>;