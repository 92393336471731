import { useEffect, useMemo } from "react";

import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";

import { useShopSelectorGetShopsQuery } from "../../components/ShopSelector/queries";

export const useShopSelect = () => {
  const [company] = useCompany();
  const { data: shopsData, loading } = useShopSelectorGetShopsQuery(
    company
      ? {
          variables: { companyId: company?.id },
          fetchPolicy: "cache-and-network",
        }
      : { skip: true },
  );
  const shops = useMemo(() => shopsData?.shop ?? [], [shopsData?.shop]);

  const [shop, setShop] = useShop();

  const currentShop = useMemo(() => {
    const currentShop = shops.find((s) => s.shopId === shop?.shopId);
    return currentShop;
  }, [shop?.shopId, shops]);
  const currentShopName = currentShop?.name;

  useEffect(() => {
    if (currentShop) return;
    const fallbackShopId = shops[0]?.shopId;
    if (fallbackShopId) {
      setShop(fallbackShopId);
    }
  }, [setShop, shops, currentShop]);

  return { loading, shops, setShop, currentShopName, currentShop };
};
