import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";
import { CookingItem } from "pages/EditShopMenuOutput/AddMenuCookingItemModal/types";
import { ShopMenu } from "pages/EditShopMenuOutput/types";

import { AddCookingItemFormItem } from "../useAddCookingItemForm";

const StyledCheckbox = styled(Checkbox)`
  display: flex;

  &:not(:first-child) {
    margin-left: 0px;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: inline-block;
`;

type Props = {
  shopMenu: ShopMenu;
  cookingItems: CookingItem[];
} & Omit<FormItemProps, "children" | "name">;

export const CookingItemsField = memo<Props>(({ shopMenu, cookingItems, ...props }) => {
  const shortSlipIds = shopMenu.shopMenuCookingItems.flatMap(({ cookingItem }) => cookingItem?.id);
  const filteredCookingItems = cookingItems.filter(({ id }) => !shortSlipIds.includes(id));

  return (
    <AddCookingItemFormItem name="cookingItems" {...props}>
      <CheckboxGroup>
        {filteredCookingItems.map(({ id, name }) => (
          <StyledCheckbox key={id} value={id}>
            {name}
          </StyledCheckbox>
        ))}
      </CheckboxGroup>
    </AddCookingItemFormItem>
  );
});
