import React, { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { auth } from "libs/firebase";
import { CompaniesField } from "pages/EditDashboardAccount/EditDashboardAccountForm/CompaniesField";
import { DashboardAccountRoleField } from "pages/EditDashboardAccount/EditDashboardAccountForm/DashboardAccountRoleField";
import { EditPasswordModal } from "pages/EditDashboardAccount/EditDashboardAccountForm/EditPasswordModal";
import { EmailField } from "pages/EditDashboardAccount/EditDashboardAccountForm/EmailField";
import { NameField } from "pages/EditDashboardAccount/EditDashboardAccountForm/NameField";
import { PasswordField } from "pages/EditDashboardAccount/EditDashboardAccountForm/PasswordField";
import { ScopeField } from "pages/EditDashboardAccount/EditDashboardAccountForm/ScopeField";
import { ShopsField } from "pages/EditDashboardAccount/EditDashboardAccountForm/ShopsField";
import {
  EditDashboardAccountFormValues,
  useEditDashboardAccountForm,
} from "pages/EditDashboardAccount/EditDashboardAccountForm/useEditDashboardAccountForm";
import {
  Company,
  DashboardAccount,
  DashboardAccountRole,
  Shop,
} from "pages/EditDashboardAccount/types";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  onSubmit: (formValues: EditDashboardAccountFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
  dashboardAccount: DashboardAccount;
  companies: Company[];
  shops: Shop[];
  dashboardAccountRoles: DashboardAccountRole[];
};

export const EditDashboardAccountForm = memo<Props>(
  ({
    onSubmit,
    onFormValidationError,
    loading,
    dashboardAccount,
    companies,
    shops,
    dashboardAccountRoles,
  }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const { form, initialValues, submit } = useEditDashboardAccountForm({
      dashboardAccount,
      onSubmit,
    });

    const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(false);

    const showEditPasswordModal = useCallback(() => setEditPasswordModalVisible(true), []);
    const dismissEditPasswordModal = useCallback(() => setEditPasswordModalVisible(false), []);

    // NOTE: 自身のアカウントのみパスワード変更を許す
    const showChangePasswordButton = auth.currentUser?.email === dashboardAccount?.email;

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <Wrapper>
        <PageHeader title={dashboardAccount.userName} onBack={goBack} />
        <StyledForm name="addAccount" form={form} layout="vertical" initialValues={initialValues}>
          <FormSection title="アカウントの基本情報">
            <NameField />
            <EmailField />
            <PasswordField />
            {showChangePasswordButton && (
              <Button type="link" onClick={showEditPasswordModal}>
                パスワードを変更する
              </Button>
            )}
          </FormSection>

          <FormSection title="アカウント詳細">
            <DashboardAccountRoleField dashboardAccountRoles={dashboardAccountRoles} />
            <ScopeField />
            <CompaniesField companies={companies} />
            <ShopsField shops={shops} />
          </FormSection>
        </StyledForm>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={goBack}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
        {showChangePasswordButton && (
          <EditPasswordModal
            visible={editPasswordModalVisible}
            onDismiss={dismissEditPasswordModal}
          />
        )}
      </Wrapper>
    );
  },
);
