import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiOnSitePaymentTypesGetDetailTypes = gql`
    query PikaichiOnSitePaymentTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    type
    label
    pikaichiOnSitePaymentDetailTypes {
      pikaichiPaymentDetailTypeId
      onSitePaymentDetailTypeEntityType
      paymentMediaCd
      paymentMediaName
      paymentUchiwakeCd
      paymentUchiwakeName
    }
  }
}
    `;
export const PikaichiOnSitePaymentTypesGetDiscountTypes = gql`
    query PikaichiOnSitePaymentTypesGetDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    type
    label
    pikaichiOnSitePaymentDiscountTypes {
      pikaichiDiscountTypeId
      onSitePaymentDiscountTypeKey
      newariKubun
      newariCd
      newariName
    }
  }
}
    `;
export const PikaichiOnSitePaymentTypesGetShops = gql`
    query PikaichiOnSitePaymentTypesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    pikaichiConfig {
      enabled
    }
  }
}
    `;
export type PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiOnSitePaymentTypesGetDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'type' | 'label'>
    & { pikaichiOnSitePaymentDetailTypes: Array<(
      { __typename?: 'pikaichiOnSitePaymentDetailType' }
      & Pick<Types.PikaichiOnSitePaymentDetailType, 'pikaichiPaymentDetailTypeId' | 'onSitePaymentDetailTypeEntityType' | 'paymentMediaCd' | 'paymentMediaName' | 'paymentUchiwakeCd' | 'paymentUchiwakeName'>
    )> }
  )> }
);

export type PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiOnSitePaymentTypesGetDiscountTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'type' | 'label'>
    & { pikaichiOnSitePaymentDiscountTypes: Array<(
      { __typename?: 'pikaichiOnSitePaymentDiscountType' }
      & Pick<Types.PikaichiOnSitePaymentDiscountType, 'pikaichiDiscountTypeId' | 'onSitePaymentDiscountTypeKey' | 'newariKubun' | 'newariCd' | 'newariName'>
    )> }
  )> }
);

export type PikaichiOnSitePaymentTypesGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiOnSitePaymentTypesGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { pikaichiConfig?: Types.Maybe<(
      { __typename?: 'pikaichiConfig' }
      & Pick<Types.PikaichiConfig, 'enabled'>
    )> }
  )> }
);


export const PikaichiOnSitePaymentTypesGetDetailTypesDocument = gql`
    query PikaichiOnSitePaymentTypesGetDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    type
    label
    pikaichiOnSitePaymentDetailTypes {
      pikaichiPaymentDetailTypeId
      onSitePaymentDetailTypeEntityType
      paymentMediaCd
      paymentMediaName
      paymentUchiwakeCd
      paymentUchiwakeName
    }
  }
}
    `;

/**
 * __usePikaichiOnSitePaymentTypesGetDetailTypesQuery__
 *
 * To run a query within a React component, call `usePikaichiOnSitePaymentTypesGetDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiOnSitePaymentTypesGetDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiOnSitePaymentTypesGetDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiOnSitePaymentTypesGetDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<PikaichiOnSitePaymentTypesGetDetailTypesQuery, PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiOnSitePaymentTypesGetDetailTypesQuery, PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables>(PikaichiOnSitePaymentTypesGetDetailTypesDocument, options);
      }
export function usePikaichiOnSitePaymentTypesGetDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiOnSitePaymentTypesGetDetailTypesQuery, PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiOnSitePaymentTypesGetDetailTypesQuery, PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables>(PikaichiOnSitePaymentTypesGetDetailTypesDocument, options);
        }
export type PikaichiOnSitePaymentTypesGetDetailTypesQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetDetailTypesQuery>;
export type PikaichiOnSitePaymentTypesGetDetailTypesLazyQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetDetailTypesLazyQuery>;
export type PikaichiOnSitePaymentTypesGetDetailTypesQueryResult = Apollo.QueryResult<PikaichiOnSitePaymentTypesGetDetailTypesQuery, PikaichiOnSitePaymentTypesGetDetailTypesQueryVariables>;
export const PikaichiOnSitePaymentTypesGetDiscountTypesDocument = gql`
    query PikaichiOnSitePaymentTypesGetDiscountTypes($companyId: uuid!) {
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    type
    label
    pikaichiOnSitePaymentDiscountTypes {
      pikaichiDiscountTypeId
      onSitePaymentDiscountTypeKey
      newariKubun
      newariCd
      newariName
    }
  }
}
    `;

/**
 * __usePikaichiOnSitePaymentTypesGetDiscountTypesQuery__
 *
 * To run a query within a React component, call `usePikaichiOnSitePaymentTypesGetDiscountTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiOnSitePaymentTypesGetDiscountTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiOnSitePaymentTypesGetDiscountTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiOnSitePaymentTypesGetDiscountTypesQuery(baseOptions: Apollo.QueryHookOptions<PikaichiOnSitePaymentTypesGetDiscountTypesQuery, PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiOnSitePaymentTypesGetDiscountTypesQuery, PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables>(PikaichiOnSitePaymentTypesGetDiscountTypesDocument, options);
      }
export function usePikaichiOnSitePaymentTypesGetDiscountTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiOnSitePaymentTypesGetDiscountTypesQuery, PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiOnSitePaymentTypesGetDiscountTypesQuery, PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables>(PikaichiOnSitePaymentTypesGetDiscountTypesDocument, options);
        }
export type PikaichiOnSitePaymentTypesGetDiscountTypesQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetDiscountTypesQuery>;
export type PikaichiOnSitePaymentTypesGetDiscountTypesLazyQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetDiscountTypesLazyQuery>;
export type PikaichiOnSitePaymentTypesGetDiscountTypesQueryResult = Apollo.QueryResult<PikaichiOnSitePaymentTypesGetDiscountTypesQuery, PikaichiOnSitePaymentTypesGetDiscountTypesQueryVariables>;
export const PikaichiOnSitePaymentTypesGetShopsDocument = gql`
    query PikaichiOnSitePaymentTypesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    pikaichiConfig {
      enabled
    }
  }
}
    `;

/**
 * __usePikaichiOnSitePaymentTypesGetShopsQuery__
 *
 * To run a query within a React component, call `usePikaichiOnSitePaymentTypesGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiOnSitePaymentTypesGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiOnSitePaymentTypesGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiOnSitePaymentTypesGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PikaichiOnSitePaymentTypesGetShopsQuery, PikaichiOnSitePaymentTypesGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiOnSitePaymentTypesGetShopsQuery, PikaichiOnSitePaymentTypesGetShopsQueryVariables>(PikaichiOnSitePaymentTypesGetShopsDocument, options);
      }
export function usePikaichiOnSitePaymentTypesGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiOnSitePaymentTypesGetShopsQuery, PikaichiOnSitePaymentTypesGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiOnSitePaymentTypesGetShopsQuery, PikaichiOnSitePaymentTypesGetShopsQueryVariables>(PikaichiOnSitePaymentTypesGetShopsDocument, options);
        }
export type PikaichiOnSitePaymentTypesGetShopsQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetShopsQuery>;
export type PikaichiOnSitePaymentTypesGetShopsLazyQueryHookResult = ReturnType<typeof usePikaichiOnSitePaymentTypesGetShopsLazyQuery>;
export type PikaichiOnSitePaymentTypesGetShopsQueryResult = Apollo.QueryResult<PikaichiOnSitePaymentTypesGetShopsQuery, PikaichiOnSitePaymentTypesGetShopsQueryVariables>;