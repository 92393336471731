import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SalesByDayofweekAndHourAnalytics = gql`
    query SalesByDayofweekAndHourAnalytics($input: SalesByDayOfWeekAndHourAnalyticsInput!) {
  salesByDayOfWeekAndHourAnalytics(input: $input) {
    rows {
      dayofweek
      hour
      numPeople
      shopId
      shopName
      totalTaxExcludedAmount
      totalTaxIncludedAmount
    }
  }
}
    `;
export type SalesByDayofweekAndHourAnalyticsQueryVariables = Types.Exact<{
  input: Types.SalesByDayOfWeekAndHourAnalyticsInput;
}>;


export type SalesByDayofweekAndHourAnalyticsQuery = (
  { __typename?: 'query_root' }
  & { salesByDayOfWeekAndHourAnalytics: (
    { __typename?: 'SalesByDayOfWeekAndHourAnalyticsOutput' }
    & { rows: Array<(
      { __typename?: 'SalesByDayOfWeekAndHourAnalyticsOutputItem' }
      & Pick<Types.SalesByDayOfWeekAndHourAnalyticsOutputItem, 'dayofweek' | 'hour' | 'numPeople' | 'shopId' | 'shopName' | 'totalTaxExcludedAmount' | 'totalTaxIncludedAmount'>
    )> }
  ) }
);


export const SalesByDayofweekAndHourAnalyticsDocument = gql`
    query SalesByDayofweekAndHourAnalytics($input: SalesByDayOfWeekAndHourAnalyticsInput!) {
  salesByDayOfWeekAndHourAnalytics(input: $input) {
    rows {
      dayofweek
      hour
      numPeople
      shopId
      shopName
      totalTaxExcludedAmount
      totalTaxIncludedAmount
    }
  }
}
    `;

/**
 * __useSalesByDayofweekAndHourAnalyticsQuery__
 *
 * To run a query within a React component, call `useSalesByDayofweekAndHourAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesByDayofweekAndHourAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesByDayofweekAndHourAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesByDayofweekAndHourAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<SalesByDayofweekAndHourAnalyticsQuery, SalesByDayofweekAndHourAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesByDayofweekAndHourAnalyticsQuery, SalesByDayofweekAndHourAnalyticsQueryVariables>(SalesByDayofweekAndHourAnalyticsDocument, options);
      }
export function useSalesByDayofweekAndHourAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesByDayofweekAndHourAnalyticsQuery, SalesByDayofweekAndHourAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesByDayofweekAndHourAnalyticsQuery, SalesByDayofweekAndHourAnalyticsQueryVariables>(SalesByDayofweekAndHourAnalyticsDocument, options);
        }
export type SalesByDayofweekAndHourAnalyticsQueryHookResult = ReturnType<typeof useSalesByDayofweekAndHourAnalyticsQuery>;
export type SalesByDayofweekAndHourAnalyticsLazyQueryHookResult = ReturnType<typeof useSalesByDayofweekAndHourAnalyticsLazyQuery>;
export type SalesByDayofweekAndHourAnalyticsQueryResult = Apollo.QueryResult<SalesByDayofweekAndHourAnalyticsQuery, SalesByDayofweekAndHourAnalyticsQueryVariables>;