import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FeatureFlag = gql`
    query FeatureFlag {
  featureFlagShop(where: {shop: {archivedAt: {_is_null: true}}}) {
    shopId
    name
    availableFrom
    availableUntil
  }
}
    `;
export type FeatureFlagQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FeatureFlagQuery = (
  { __typename?: 'query_root' }
  & { featureFlagShop: Array<(
    { __typename?: 'featureFlagShop' }
    & Pick<Types.FeatureFlagShop, 'shopId' | 'name' | 'availableFrom' | 'availableUntil'>
  )> }
);


export const FeatureFlagDocument = gql`
    query FeatureFlag {
  featureFlagShop(where: {shop: {archivedAt: {_is_null: true}}}) {
    shopId
    name
    availableFrom
    availableUntil
  }
}
    `;

/**
 * __useFeatureFlagQuery__
 *
 * To run a query within a React component, call `useFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
      }
export function useFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagQuery, FeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(FeatureFlagDocument, options);
        }
export type FeatureFlagQueryHookResult = ReturnType<typeof useFeatureFlagQuery>;
export type FeatureFlagLazyQueryHookResult = ReturnType<typeof useFeatureFlagLazyQuery>;
export type FeatureFlagQueryResult = Apollo.QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables>;