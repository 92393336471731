import React, { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox, Switch, Tag } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { difference, intersection } from "lodash";
import { isNotNull } from "util/type/primitive";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { FormHelp } from "components/Form/FormHelp";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useSelectedTableShopIds } from "hooks/useSelectedTableShopIds";
import { useShop } from "hooks/useShop";
import { useViewport } from "hooks/useViewport";
import type { Shop, ShopMenu } from "pages/EditMenuDealers/types";
import {
  CreateShopMenusInput,
  DeleteShopMenusInput,
  UpdateShopMenusBulkInput,
} from "types/graphql";

import { DealingSwitch } from "../DealingSwitch";

import { BulkUpdateBanner } from "./BulkUpdateBanner";
import type { BulkEditConditions, DisplayedShop } from "./types";
import { useApplyBulkEditConditions } from "./useApplyBulkEditConditions";
import { WarningText } from "./WarningText";

const DEFAULT_PAGE_SIZE = 10;

export type MenuTableDisplayType = "compact" | "detail";

const Wrapper = styled.div`
  padding: 24px;
  background-color: #ffffff;

  /* NOTE: styled(Table) だとtsエラー */
  .ant-table-selection-column {
    text-align: right;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AllCheckboxWrapper = styled.div`
  white-space: nowrap;
`;

const AllCheckbox = styled(Checkbox)`
  margin-left: 8px;
`;

const createColumns = ({
  shouldShowKdColumn,
  onCheckIsVisibleForCustomer,
  onCheckIsVisibleForStaff,
  onCheckIsSoldOut,
  onSelectShopId,
  deselectShop,
  setShop,
  currentPageSelectedShopIds,
  useFixedColumns,
  canEditMenuDealer,
  canEditShopMenuDisplayConfig,
  canAccessEditShopMenuStock,
  canAccessEditShopMenuOutput,
}: Pick<
  Props,
  | "onCheckIsVisibleForCustomer"
  | "onCheckIsVisibleForStaff"
  | "onCheckIsSoldOut"
  | "onSelectShopId"
  | "deselectShop"
> & {
  shouldShowKdColumn: boolean;
  setShop(shopId?: string): void;
  currentPageSelectedShopIds: string[];
  useFixedColumns: boolean;
  canEditMenuDealer: boolean;
  canEditShopMenuDisplayConfig: boolean;
  canAccessEditShopMenuStock: boolean;
  canAccessEditShopMenuOutput: boolean;
}) => [
  {
    title: "取扱",
    width: 100,
    align: "center" as const,
    fixed: useFixedColumns ? ("left" as const) : undefined,
    render(_: string, { shopId, shopMenu }: DisplayedShop) {
      const isRowChecked = currentPageSelectedShopIds.includes(shopId);

      return (
        <DealingSwitch
          isDealing={typeof shopMenu !== "undefined"}
          shopId={shopId}
          onSelectShopId={onSelectShopId}
          onDeselectShopId={({ shopId }) => deselectShop({ shopId })}
          // NOTE: 取扱の変更権限がない場合は、一括変更もできないようにしている
          disabled={!canEditMenuDealer || isRowChecked}
        />
      );
    },
  },
  {
    title: "店舗名",
    dataIndex: "name",
    width: 240,
    fixed: useFixedColumns ? ("left" as const) : undefined,
  },
  {
    title: "公開設定",
    children: [
      {
        title() {
          return <FormHelp label="お客様" help="スマートフォン上での表示設定" />;
        },
        width: 100,
        align: "center",
        render(_: string, { shopId, shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          const isRowChecked = currentPageSelectedShopIds.includes(shopId);

          return (
            <Switch
              checked={shopMenu.isVisibleForCustomer}
              onChange={(checked) =>
                onCheckIsVisibleForCustomer({ shopId, isVisibleForCustomer: checked })
              }
              disabled={isRowChecked || !canEditShopMenuDisplayConfig}
            />
          );
        },
      },
      {
        title() {
          return <FormHelp label="スタッフ" help="ハンディ上での表示設定" />;
        },
        width: 120,
        align: "center",
        render(_: string, { shopId, shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          const isRowChecked = currentPageSelectedShopIds.includes(shopId);

          return (
            <Switch
              checked={shopMenu.isVisibleForStaff}
              onChange={(checked) =>
                onCheckIsVisibleForStaff({ shopId, isVisibleForStaff: checked })
              }
              disabled={isRowChecked || !canEditShopMenuDisplayConfig}
            />
          );
        },
      },
    ],
  },
  {
    title: "在庫設定",
    children: [
      {
        title: "在庫",
        width: 100,
        align: "center",
        render(_: string, { shopId, shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          const isSoldOut = shopMenu.stock?.currentStockNum === 0;
          const isRowChecked = currentPageSelectedShopIds.includes(shopId);

          return (
            <Switch
              checked={!isSoldOut}
              onChange={(checked) => onCheckIsSoldOut(shopId, !checked)}
              disabled={isRowChecked || !canAccessEditShopMenuStock}
            />
          );
        },
      },
      {
        title: "在庫数",
        width: 100,
        render(_: string, { shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          return <Tag>{shopMenu.stock?.currentStockNum ?? "無制限"}</Tag>;
        },
      },
      {
        title: "日次在庫数",
        width: 120,
        render(_: string, { shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          return <Tag>{shopMenu.stock?.dailyStockNum ?? "未設定"}</Tag>;
        },
      },
      canAccessEditShopMenuStock
        ? ({
            title: "",
            width: 60,
            align: "center",
            render(_: string, { shopId, shopMenu }: DisplayedShop) {
              if (!shopMenu) return null;
              return (
                <IconLink to={`/shop/${shopId}/menu/${shopMenu._menuId}/stock/edit`}>
                  <EditIcon onClick={() => setShop(shopId)} />
                </IconLink>
              );
            },
          } as const)
        : null,
    ].filter(isNotNull),
  },
  {
    title: "出力先設定",
    children: [
      {
        title: "プリンター",
        width: 160,
        render(_: string, { shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          return shopMenu.shopMenuKitchenRoles.length === 0 ? (
            <WarningText>印刷設定がありません</WarningText>
          ) : (
            shopMenu.shopMenuKitchenRoles.map(({ role }) => (
              <Tag color="blue" key={role?.roleId ?? 0}>
                {role?.name}
              </Tag>
            ))
          );
        },
      },
      {
        title: "デシャップグループ",
        width: 160,
        render(_: string, { shopMenu }: DisplayedShop) {
          if (!shopMenu) return null;
          return shopMenu.dishUpSlipGroupShopMenus.length === 0 ? (
            <WarningText>印刷設定がありません</WarningText>
          ) : (
            shopMenu.dishUpSlipGroupShopMenus.map(({ dishUpSlipGroup }) => (
              <Tag color="blue" key={dishUpSlipGroup?.id ?? 0}>
                {dishUpSlipGroup?.name}
              </Tag>
            ))
          );
        },
      },
      shouldShowKdColumn
        ? {
            title: "キッチンディスプレイ",
            width: 180,
            render(_: string, { useKd, shopMenu }: DisplayedShop) {
              if (!shopMenu) return null;
              if (!useKd) return <Tag>KD 未使用</Tag>;
              return shopMenu.shopMenuKdDisplayTargets.length === 0 ? (
                <WarningText>表示設定がありません</WarningText>
              ) : (
                shopMenu.shopMenuKdDisplayTargets.map(({ kdDisplayTarget }) => (
                  <Tag color="blue" key={kdDisplayTarget?.id ?? 0}>
                    {kdDisplayTarget?.name}
                  </Tag>
                ))
              );
            },
          }
        : null,
      canAccessEditShopMenuOutput
        ? {
            title: "",
            width: 60,
            align: "center",
            render(_: string, { shopId, shopMenu }: DisplayedShop) {
              if (!shopMenu) return null;
              return (
                <IconLink to={`/shop/${shopId}/menu/${shopMenu._menuId}/output/edit`}>
                  <EditIcon onClick={() => setShop(shopId)} />
                </IconLink>
              );
            },
          }
        : null,
    ].filter(isNotNull),
  },
];

type Props = {
  loading: boolean;
  shops: Shop[];
  shopMenus: ShopMenu[];
  menuId: string;
  _menuId: number;
  onSelectShopId({ shopId }: { shopId: string }): void;
  deselectShop({ shopId }: { shopId: string }): Promise<void>;
  onCheckIsVisibleForCustomer({
    shopId,
    isVisibleForCustomer,
  }: {
    shopId: string;
    isVisibleForCustomer: boolean;
  }): void;
  onCheckIsVisibleForStaff({
    shopId,
    isVisibleForStaff,
  }: {
    shopId: string;
    isVisibleForStaff: boolean;
  }): void;
  onCheckIsSoldOut(shopId: string, isSoldOut: boolean): void;
  updateShopMenus({
    createShopMenusInput,
    deleteShopMenusInput,
    updateShopMenusInput,
  }: {
    createShopMenusInput?: CreateShopMenusInput;
    deleteShopMenusInput?: DeleteShopMenusInput;
    updateShopMenusInput?: UpdateShopMenusBulkInput;
  }): Promise<void>;
};

export const ShopMenuTable = memo<Props>(
  ({
    loading,
    shopMenus,
    shops,
    menuId,
    _menuId,
    onSelectShopId,
    deselectShop,
    onCheckIsVisibleForCustomer,
    onCheckIsVisibleForStaff,
    onCheckIsSoldOut,
    updateShopMenus,
  }) => {
    const [defaultPagination, setPagination] = usePagination();

    const pagination = useMemo(
      () => ({
        ...defaultPagination,
        defaultCurrent: defaultPagination.defaultCurrent ?? 1,
        defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
      }),
      [defaultPagination],
    );

    const {
      selectedShopIds,
      setSelectedShopIds,
      currentPageSelectedShopIds,
      clearCurrentPageSelectedShopIds,
      allCheckboxProps,
    } = useSelectedTableShopIds({ pagination, shops });

    const [_, setShop] = useShop();

    const [bulkEditConditions, setBulkEditConditions] = useState<BulkEditConditions>({});

    const initialDisplayedShops = useMemo<DisplayedShop[]>(
      () =>
        shops.map((shop) => {
          const shopMenu = shopMenus.find((shopMenu) => shopMenu.shopId === shop.shopId);
          return {
            ...shop,
            shopMenu,
          };
        }),
      [shops, shopMenus],
    );

    const [displayedShops, setDisplayedShops] = useState<DisplayedShop[]>([]);

    const updateBulkEditCondition = <K extends keyof BulkEditConditions>(
      key: K,
      value: BulkEditConditions[K],
    ) => {
      setBulkEditConditions({
        ...bulkEditConditions,
        [key]: value,
      });
    };

    useApplyBulkEditConditions({
      bulkEditConditions,
      currentPageSelectedShopIds,
      initialDisplayedShops,
      menuId,
      _menuId,
      setDisplayedShops,
    });

    const clearBulkEditConditions = () => {
      clearCurrentPageSelectedShopIds();
      setBulkEditConditions({});
    };

    const onUpdateButtonClick = async () => {
      const {
        isDealing,
        isVisibleForCustomer,
        isVisibleForStaff,
        isSoldOut,
        currentStockNum,
        dailyStockNum,
      } = bulkEditConditions;

      if (isDealing === false) {
        const shopMenuIds = shopMenus
          .filter(
            (shopMenu) =>
              menuId === shopMenu.menuId && currentPageSelectedShopIds.includes(shopMenu.shopId),
          )
          .map(({ id }) => id);
        await updateShopMenus({
          deleteShopMenusInput: { shopMenuIds },
        });
        clearBulkEditConditions();
        return;
      }

      const dealingShopIds = shopMenus.map((shopMenu) => shopMenu.shopId);
      const targetShopIds = isDealing
        ? currentPageSelectedShopIds
        : dealingShopIds.filter((shopId) => currentPageSelectedShopIds.includes(shopId));

      const createdShopIds = difference(targetShopIds, dealingShopIds);
      const updatedShopIds = intersection(targetShopIds, dealingShopIds);

      await updateShopMenus({
        createShopMenusInput:
          createdShopIds.length > 0
            ? {
                shopIds: createdShopIds,
                menuId,
                isVisibleForCustomer,
                isVisibleForStaff,
                currentStockNum: isSoldOut ? 0 : currentStockNum,
                dailyStockNum,
              }
            : undefined,
        updateShopMenusInput:
          updatedShopIds.length > 0
            ? {
                shopIds: updatedShopIds,
                menuIds: [menuId],
                choiceIds: [],
                isVisibleForCustomer,
                isVisibleForStaff,
                currentStockNum: isSoldOut ? 0 : currentStockNum,
                dailyStockNum,
              }
            : undefined,
      });

      clearBulkEditConditions();
    };

    const { isDesktop } = useViewport();

    const shouldShowKdColumn = shops.some(({ useKd }) => useKd);

    const { canAccess } = useCanAccess();

    const { isFeatureEnabled } = useIsFeatureEnabled();

    // すべての項目の編集が行える場合には Bulk 更新が行えるようにする（Bulk はどの項目も更新が発生するため）
    const canBulkEdit =
      isFeatureEnabled("editMenuDealer") &&
      isFeatureEnabled("editShopMenuDisplaySettings") &&
      canAccess("editShopMenuStock") &&
      canAccess("editShopMenuOutput");

    const columns = useMemo(
      () =>
        createColumns({
          shouldShowKdColumn,
          onSelectShopId,
          deselectShop,
          onCheckIsSoldOut,
          onCheckIsVisibleForCustomer,
          onCheckIsVisibleForStaff,
          setShop,
          currentPageSelectedShopIds,
          useFixedColumns: isDesktop,
          canEditMenuDealer: isFeatureEnabled("editMenuDealer"),
          canEditShopMenuDisplayConfig: isFeatureEnabled("editShopMenuDisplaySettings"),
          canAccessEditShopMenuStock: canAccess("editShopMenuStock"),
          canAccessEditShopMenuOutput: canAccess("editShopMenuOutput"),
        }),
      [
        shouldShowKdColumn,
        onSelectShopId,
        deselectShop,
        onCheckIsSoldOut,
        onCheckIsVisibleForCustomer,
        onCheckIsVisibleForStaff,
        setShop,
        currentPageSelectedShopIds,
        isDesktop,
        canAccess,
        isFeatureEnabled,
      ],
    );

    const rowSelection: TableRowSelection<DisplayedShop> = useMemo(
      () => ({
        columnTitle: (
          <AllCheckboxWrapper>
            全選択
            <AllCheckbox {...allCheckboxProps} />
          </AllCheckboxWrapper>
        ),
        selectedRowKeys: selectedShopIds,
        onChange: (_, rows) => {
          setSelectedShopIds(rows.map(({ shopId }) => shopId));
        },
        columnWidth: 80,
      }),
      [allCheckboxProps, selectedShopIds, setSelectedShopIds],
    );

    return (
      <Wrapper>
        {currentPageSelectedShopIds.length !== 0 && (
          <BulkUpdateBanner
            onClearButtonClick={clearBulkEditConditions}
            selectedCount={currentPageSelectedShopIds.length}
            bulkEditConditions={bulkEditConditions}
            updateBulkEditCondition={updateBulkEditCondition}
            onUpdateButtonClick={onUpdateButtonClick}
            loading={loading}
            disabled={!canBulkEdit}
          />
        )}
        <Table
          loading={loading}
          dataSource={displayedShops}
          columns={columns}
          rowKey="shopId"
          bordered
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          pagination={pagination}
          rowSelection={canBulkEdit ? rowSelection : undefined}
        />
      </Wrapper>
    );
  },
);
