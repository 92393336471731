import { useCallback, useMemo } from "react";

import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useShop } from "hooks/useShop";
import { Context, dinii } from "libs/dinii/rest";
import { auth } from "libs/firebase";

export const useDinii = () => {
  const [corporation] = useCorporation();
  const [company] = useCompany();
  const [shop] = useShop();

  const getContext = useCallback(async (): Promise<Context | null> => {
    const authKey = await auth.currentUser?.getIdToken();
    const corporationId = corporation?.corporationId;
    const companyId = company?.id;
    const _companyId = company?.companyId;
    const shopId = shop?.shopId;

    return authKey && corporationId && companyId && _companyId
      ? { authKey, corporationId, companyId, _companyId, shopId }
      : null;
  }, [company?.companyId, company?.id, corporation?.corporationId, shop?.shopId]);

  return useMemo(() => [dinii, getContext] as const, [getContext]);
};
