import React, { memo } from "react";
import styled from "styled-components";
import { Card, Col, Row, Statistic } from "antd";
import { round } from "util/math";

import { SubTitle } from "components/SubTitle";
import { DailySalesSummary as DailySalesSummaryType } from "types/graphql";

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

type Props = {
  dailySalesSummary?: DailySalesSummaryType;
};

export const DailySalesSummary = memo<Props>(({ dailySalesSummary }) => {
  const { totalSales, customerCount, groupCount } = dailySalesSummary ?? {};
  const salesPerCustomer = totalSales && customerCount ? round(totalSales / customerCount, 2) : "";

  return (
    <Card data-cy="daily-sales-summary">
      <SubTitle>サマリ</SubTitle>
      <Row gutter={32}>
        <Col span={6}>
          <Card>
            <Statistic title="総売上" value={totalSales ?? ""} suffix="円" />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title="客数" value={customerCount ?? ""} suffix="人" />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title="組数" value={groupCount ?? ""} suffix="組" />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic title="客単価" value={salesPerCustomer} suffix="円" />
          </Card>
        </Col>
      </Row>
    </Card>
  );
});
