import React, { memo } from "react";
import styled from "styled-components";
import { Input, Switch } from "antd";

import { withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

const StyledInputPassword = styled(Input.Password)`
  width: 360px;
`;

type Props = {
  passwordMinLength: number;
  passwordRegex: RegExp;
};

export const InitialSetupDrawerOpenPasswordFields = memo<Props>(
  ({ passwordMinLength, passwordRegex }) => (
    <EditCashRegisterConfigFormItem.NonProperty
      noStyle
      shouldUpdate={withFormDependencies(
        ({ enableRequiringPasswordForDrawerOpen, drawerOpenPassword }) => [
          enableRequiringPasswordForDrawerOpen,
          drawerOpenPassword,
        ],
      )}
    >
      {({ getFieldValue, validateFields }) => {
        const enableRequiringPasswordForDrawerOpen: boolean = getFieldValue(
          "enableRequiringPasswordForDrawerOpen",
        );
        const drawerOpenPassword: string | undefined = getFieldValue("drawerOpenPassword");

        const message = `パスワードは英数字${passwordMinLength}文字以上にしてください。`;

        const isDisabledEnableSwitch = Boolean(
          enableRequiringPasswordForDrawerOpen &&
            drawerOpenPassword &&
            !passwordRegex.test(drawerOpenPassword),
        );
        const isDisabledPasswordInput = !enableRequiringPasswordForDrawerOpen;

        const handleClickEnableSwitch = (checked: boolean) => {
          /**
           * パスワード入力欄で不正規なパスワードを入力したあとに空欄にした状態からこのトグルをON→OFFにした場合、
           * Formのデータとしてはvalidだが、UI上はパスワード入力欄にバリデーションエラーが表示されたままになるため、
           * ここで明示的にバリデーションを実行してエラーを解消し、ユーザーに保存可能であることが明確にわかるようにする
           */
          if (!checked && drawerOpenPassword?.length === 0) {
            validateFields(["drawerOpenPassword"]);
          }
        };

        return (
          <>
            <EditCashRegisterConfigFormItem
              label="会計、点検・精算以外でドロワーを開く際のパスワード設定"
              name="enableRequiringPasswordForDrawerOpen"
              valuePropName="checked"
              endSpacer={<Spacer size={8} />}
            >
              <Switch disabled={isDisabledEnableSwitch} onChange={handleClickEnableSwitch} />
            </EditCashRegisterConfigFormItem>

            <EditCashRegisterConfigFormItem
              name="drawerOpenPassword"
              rules={[
                {
                  required: !isDisabledPasswordInput,
                  message,
                },
                {
                  pattern: passwordRegex,
                  message,
                },
              ]}
              help={message}
            >
              <StyledInputPassword placeholder="パスワード" disabled={isDisabledPasswordInput} />
            </EditCashRegisterConfigFormItem>
          </>
        );
      }}
    </EditCashRegisterConfigFormItem.NonProperty>
  ),
);
