import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Maybe } from "types/graphql";

import { Choice, Option, TecAggregationMenu } from "../types";

export type EditOptionTecAggregationOptionFormValues = {
  option: Pick<Option, "optionId" | "name"> & {
    choices: Array<
      Pick<Choice, "name" | "choiceId"> & {
        tecAggregationMenu?: Maybe<
          Pick<
            TecAggregationMenu,
            "id" | "menuCode" | "name" | "gpCode" | "gpName" | "dpCode" | "dpName"
          >
        >;
      }
    >;
  };
};

export const EditOptionTecAggregationOptionFormItem =
  createFormItem<EditOptionTecAggregationOptionFormValues>();

export const useEditOptionTecAggregationOptionForm = ({
  option,
  onSubmit,
}: {
  option: Option;
  onSubmit: (args: EditOptionTecAggregationOptionFormValues) => void;
}) => {
  const [form] = Form.useForm<EditOptionTecAggregationOptionFormValues>();

  const initialValues = useMemo<EditOptionTecAggregationOptionFormValues>(
    () => ({ option }),
    [option],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
