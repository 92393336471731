import React, { memo } from "react";
import { Tag } from "antd";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";
import { CookingItem } from "pages/CookingItems/types";

type Props = {
  loading?: boolean;
  cookingItems: CookingItem[];
};

export const CookingItemTable = memo<Props>(({ loading, cookingItems }) => {
  const { canAccess } = useCanAccess();
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "調理アイテム名",
      dataIndex: "name",
    },
    {
      title: "キッチンプリンター",
      width: 160,
      render(_: unknown, { shopCookingItemRole }: CookingItem) {
        const itemName = shopCookingItemRole?.role?.name;
        return itemName ? <Tag>{itemName}</Tag> : null;
      },
    },
    ...(canAccess("editCookingItem")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { id }: CookingItem) {
              return (
                <IconLink to={`/cookingItem/${id}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={cookingItems}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
