import React, { useCallback, useMemo } from "react";
import { Alert, DatePicker } from "antd";
import dayjs from "dayjs";
import { getTotalNetAmount } from "models/onSitePayment";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useLocationState } from "hooks/useLocationState";
import { useShop } from "hooks/useShop";

import { AccountingHistoryFilter, FilterConditions } from "./AccountingHistoryFilter";
import { AccountingHistoryTable } from "./AccountingHistoryTable";
import {
  useAccountingHistoriesGetOnSitePaymentsQuery,
  useAccountingHistoriesGetOnSitePaymentTypesQuery,
  useAccountingHistoriesGetTablesQuery,
} from "./queries";
import { OnSitePayment, OnSitePaymentDetailType, OnSitePaymentDiscountType, Table } from "./types";

export type AccountingHistory = {
  onSitePaymentId: number;
  receiptId?: number;
  tables: Table[];
  onSitePaymentDetailTypes: string[];
  onSitePaymentDiscountTypes: string[];
  amount: number;
  createdAt: string;
  voidedAt?: string;
};

export const filterOnSitePayments = (
  onSitePayments: OnSitePayment[],
  { receiptId, onSitePaymentDetailTypes, onSitePaymentDiscountTypes, tableIds }: FilterConditions,
) => {
  const filteredOnSitePayments = onSitePayments.filter(
    (onSitePayment) =>
      (receiptId === undefined || onSitePayment.localAccountingSlipId === receiptId) &&
      (onSitePaymentDetailTypes === undefined ||
        onSitePayment.onSitePaymentDetails.some((detail) =>
          onSitePaymentDetailTypes.includes(detail.onSitePaymentDetailType),
        )) &&
      (onSitePaymentDiscountTypes === undefined ||
        onSitePayment.onSitePaymentDiscounts.some((discount) =>
          onSitePaymentDiscountTypes.includes(discount.onSitePaymentDiscountType),
        )) &&
      (tableIds === undefined ||
        onSitePayment.onSitePaymentTableUsers
          .map(({ tableUser }) => tableUser.table.tableId)
          .some((tableId) => tableIds.includes(tableId))),
  );

  return filteredOnSitePayments;
};

export const AccountingHistory = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [{ targetDateString }, setTargetDateString] = useLocationState<{
    targetDateString: string | null;
  }>("targetDateString", { targetDateString: dayjs().subtract(1, "days").format("YYYY-MM-DD") });
  const targetDate = targetDateString ? dayjs(targetDateString) : undefined;

  const {
    data: getOnSitePaymentTypesData,
    loading: loadingGetOnSitePaymentTypes,
    error: getOnSitePaymentTypesQueryError,
  } = useAccountingHistoriesGetOnSitePaymentTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const onSitePaymentDetailTypes: OnSitePaymentDetailType[] =
    getOnSitePaymentTypesData?.onSitePaymentDetailType ?? [];

  const onSitePaymentDiscountTypes: OnSitePaymentDiscountType[] =
    getOnSitePaymentTypesData?.onSitePaymentDiscountType ?? [];

  const {
    data: getOnSitePaymentsData,
    loading: loadingGetOnSitePayments,
    error: getOnSitePaymentsQueryError,
  } = useAccountingHistoriesGetOnSitePaymentsQuery(
    shopId && targetDateString
      ? { variables: { shopId, targetDate: targetDateString } }
      : { skip: true },
  );

  const {
    data: getTablesData,
    loading: loadingGetTablesData,
    error: getTablesQueryError,
  } = useAccountingHistoriesGetTablesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const tables = getTablesData?.table ?? [];

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredOnSitePayments = useMemo(
    () =>
      filterOnSitePayments(
        getOnSitePaymentsData?.closeCashRegister?.flatMap(({ onSitePayments }) => onSitePayments) ??
          [],
        filterConditions,
      ),
    [filterConditions, getOnSitePaymentsData?.closeCashRegister],
  );

  const accountingHistories: AccountingHistory[] = useMemo(
    () =>
      filteredOnSitePayments?.map((onSitePayment) => ({
        onSitePaymentId: onSitePayment.onSitePaymentId,
        receiptId: onSitePayment.localAccountingSlipId ?? undefined,
        tables: onSitePayment.onSitePaymentTableUsers.map(({ tableUser }) => tableUser.table),
        onSitePaymentDetailTypes: onSitePayment.onSitePaymentDetails
          .map((d) => d.onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype?.label)
          .filter(isNotNullable),
        onSitePaymentDiscountTypes: onSitePayment.onSitePaymentDiscounts
          .map((d) => d.onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype?.label)
          .filter(isNotNullable),
        amount: getTotalNetAmount(onSitePayment),
        createdAt: onSitePayment.createdAt,
        voidedAt:
          typeof onSitePayment.voidedAt === "number"
            ? dayjs.unix(onSitePayment.voidedAt).format()
            : typeof onSitePayment.voidedAt === "string"
            ? dayjs(onSitePayment.voidedAt).format()
            : undefined,
      })) ?? [],
    [filteredOnSitePayments],
  );

  const handleChangeDate = useCallback(
    (_: dayjs.Dayjs | null, targetDateString: string) => {
      setTargetDateString({ targetDateString });
    },
    [setTargetDateString],
  );
  const shouldShowAlert =
    getOnSitePaymentTypesQueryError || getOnSitePaymentsQueryError || getTablesQueryError;

  const loading = loadingGetOnSitePayments || loadingGetOnSitePaymentTypes || loadingGetTablesData;

  return (
    <DashboardLayout title="会計履歴一覧">
      <PageHeader
        title="会計履歴一覧"
        footer={
          <>
            <ShopSelector />
            <DatePicker defaultValue={targetDate} value={targetDate} onChange={handleChangeDate} />
            <AccountingHistoryFilter
              onSitePaymentDetailTypes={onSitePaymentDetailTypes}
              onSitePaymentDiscountTypes={onSitePaymentDiscountTypes}
              tables={tables}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AccountingHistoryTable loading={loading} accountingHistories={accountingHistories} />
    </DashboardLayout>
  );
};
