import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { LabelField } from "pages/EditOnSitePaymentDiscountType/EditOnSitePaymentDiscountTypeForm/LabelField";
import { useEditOnSitePaymentDiscountTypeForm } from "pages/EditOnSitePaymentDiscountType/EditOnSitePaymentDiscountTypeForm/useEditOnSitePaymentDiscountTypeForm";
import { OnSitePaymentDiscountType } from "pages/OnSitePaymentDiscountTypes/types";
import { OnSitePaymentDiscountTypeSetInput } from "types/graphql";

type Props = {
  onSitePaymentDiscountType: OnSitePaymentDiscountType;
  onSubmit: (onSitePaymentDiscountType: OnSitePaymentDiscountTypeSetInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditOnSitePaymentDiscountTypeForm = memo<Props>(
  ({ onSitePaymentDiscountType, onClose, onSubmit, loading }) => {
    const { form, initialValues, submit } = useEditOnSitePaymentDiscountTypeForm(
      onSitePaymentDiscountType,
      onSubmit,
    );

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form
            name="onSitePaymentDiscountType"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <LabelField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
