import React, { memo } from "react";
import { Popconfirm, Typography } from "antd";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Company } from "../types";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  companies: Company[];
  onArchive: ({ companyId }: { companyId: string }) => void;
};

export const CompanyTable = memo<Props>(({ loading, companies, onArchive }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    { title: "業態名", dataIndex: "name" },
    {
      title: "法人名",
      render(_: string, { corporation }: Company) {
        return corporation?.name;
      },
    } as const,
    {
      title: "",
      align: "center",
      width: 60,
      fixed: "right",
      render(_: string, { companyId }: Company) {
        return (
          <IconLink to={`/company/${companyId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
    {
      title: "",
      align: "center",
      width: 60,
      fixed: "right",
      render(_: string, { id }: Company) {
        return (
          <Popconfirm
            title={
              <>
                <Paragraph>業態をアーカイブしますか？</Paragraph>
                <Paragraph>一度アーカイブした業態を元に戻すことはできません。</Paragraph>
              </>
            }
            okText="はい"
            cancelText="キャンセル"
            onConfirm={() => onArchive({ companyId: id })}
          >
            <DeleteIcon />
          </Popconfirm>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="companyId"
      columns={columns}
      dataSource={companies}
      loading={loading}
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
