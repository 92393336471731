import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTableGetTable = gql`
    query EditTableGetTable($tableId: Int!) {
  table(where: {tableId: {_eq: $tableId}}, limit: 1) {
    name
    tableId
    tableAreaId
  }
}
    `;
export const EditTableUpdateTable = gql`
    mutation EditTableUpdateTable($tableId: Int!, $table: table_set_input!) {
  update_table(_set: $table, where: {tableId: {_eq: $tableId}}) {
    affected_rows
  }
}
    `;
export const EditTableGetTableAreas = gql`
    query EditTableGetTableAreas($shopId: uuid!) {
  tableArea(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    name
  }
}
    `;
export type EditTableGetTableQueryVariables = Types.Exact<{
  tableId: Types.Scalars['Int'];
}>;


export type EditTableGetTableQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'name' | 'tableId' | 'tableAreaId'>
  )> }
);

export type EditTableUpdateTableMutationVariables = Types.Exact<{
  tableId: Types.Scalars['Int'];
  table: Types.TableSetInput;
}>;


export type EditTableUpdateTableMutation = (
  { __typename?: 'mutation_root' }
  & { update_table?: Types.Maybe<(
    { __typename?: 'table_mutation_response' }
    & Pick<Types.TableMutationResponse, 'affected_rows'>
  )> }
);

export type EditTableGetTableAreasQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditTableGetTableAreasQuery = (
  { __typename?: 'query_root' }
  & { tableArea: Array<(
    { __typename?: 'tableArea' }
    & Pick<Types.TableArea, 'id' | 'name'>
  )> }
);


export const EditTableGetTableDocument = gql`
    query EditTableGetTable($tableId: Int!) {
  table(where: {tableId: {_eq: $tableId}}, limit: 1) {
    name
    tableId
    tableAreaId
  }
}
    `;

/**
 * __useEditTableGetTableQuery__
 *
 * To run a query within a React component, call `useEditTableGetTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTableGetTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTableGetTableQuery({
 *   variables: {
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useEditTableGetTableQuery(baseOptions: Apollo.QueryHookOptions<EditTableGetTableQuery, EditTableGetTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTableGetTableQuery, EditTableGetTableQueryVariables>(EditTableGetTableDocument, options);
      }
export function useEditTableGetTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTableGetTableQuery, EditTableGetTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTableGetTableQuery, EditTableGetTableQueryVariables>(EditTableGetTableDocument, options);
        }
export type EditTableGetTableQueryHookResult = ReturnType<typeof useEditTableGetTableQuery>;
export type EditTableGetTableLazyQueryHookResult = ReturnType<typeof useEditTableGetTableLazyQuery>;
export type EditTableGetTableQueryResult = Apollo.QueryResult<EditTableGetTableQuery, EditTableGetTableQueryVariables>;
export const EditTableUpdateTableDocument = gql`
    mutation EditTableUpdateTable($tableId: Int!, $table: table_set_input!) {
  update_table(_set: $table, where: {tableId: {_eq: $tableId}}) {
    affected_rows
  }
}
    `;
export type EditTableUpdateTableMutationFn = Apollo.MutationFunction<EditTableUpdateTableMutation, EditTableUpdateTableMutationVariables>;

/**
 * __useEditTableUpdateTableMutation__
 *
 * To run a mutation, you first call `useEditTableUpdateTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTableUpdateTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTableUpdateTableMutation, { data, loading, error }] = useEditTableUpdateTableMutation({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      table: // value for 'table'
 *   },
 * });
 */
export function useEditTableUpdateTableMutation(baseOptions?: Apollo.MutationHookOptions<EditTableUpdateTableMutation, EditTableUpdateTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTableUpdateTableMutation, EditTableUpdateTableMutationVariables>(EditTableUpdateTableDocument, options);
      }
export type EditTableUpdateTableMutationHookResult = ReturnType<typeof useEditTableUpdateTableMutation>;
export type EditTableUpdateTableMutationResult = Apollo.MutationResult<EditTableUpdateTableMutation>;
export type EditTableUpdateTableMutationOptions = Apollo.BaseMutationOptions<EditTableUpdateTableMutation, EditTableUpdateTableMutationVariables>;
export const EditTableGetTableAreasDocument = gql`
    query EditTableGetTableAreas($shopId: uuid!) {
  tableArea(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useEditTableGetTableAreasQuery__
 *
 * To run a query within a React component, call `useEditTableGetTableAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTableGetTableAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTableGetTableAreasQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditTableGetTableAreasQuery(baseOptions: Apollo.QueryHookOptions<EditTableGetTableAreasQuery, EditTableGetTableAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTableGetTableAreasQuery, EditTableGetTableAreasQueryVariables>(EditTableGetTableAreasDocument, options);
      }
export function useEditTableGetTableAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTableGetTableAreasQuery, EditTableGetTableAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTableGetTableAreasQuery, EditTableGetTableAreasQueryVariables>(EditTableGetTableAreasDocument, options);
        }
export type EditTableGetTableAreasQueryHookResult = ReturnType<typeof useEditTableGetTableAreasQuery>;
export type EditTableGetTableAreasLazyQueryHookResult = ReturnType<typeof useEditTableGetTableAreasLazyQuery>;
export type EditTableGetTableAreasQueryResult = Apollo.QueryResult<EditTableGetTableAreasQuery, EditTableGetTableAreasQueryVariables>;