import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableDefaultInflowSourceTagField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="会計時の流入媒体選択のデフォルト値を「フリー」にする"
        help="流入媒体入力のデフォルト値を決めない場合は毎回の会計で媒体入力を行う必要があります"
      />
    }
    name="enableDefaultInflowSourceTag"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
