import React, { memo } from "react";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

type Props = Omit<FormItemProps, "children">;

export const WinboardShopCdField = memo<Props>((props) => (
  <FormItem label="店舗CD" name="winboardShopCd" rules={[{ required: true }]} required {...props}>
    <InputCode />
  </FormItem>
));
