import React, { memo, useMemo } from "react";
import { Menu, SubMenuProps } from "antd";
import { getRole } from "models/user";

import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useCurrentUser } from "hooks/useUser";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";
import { Role } from "types/role";

type Props = {
  features:
    | DashboardFeatureEnum[]
    // feature は一つの SubMenu に複数の feature の機能が入ることがある（本部機能など）
    | "onlyServiceAdmin";
  // ServiceAdmin only な機能向けに type を一つ追加
  canShow?: boolean;
} & SubMenuProps;

export type SubMenuPropsWithoutFeatures = Omit<Props, "features">;

export const SubMenu = memo<Props>(({ features, canShow = true, ...props }) => {
  const user = useCurrentUser();
  const role = getRole(user);
  const { SubMenu } = Menu;

  const { permissions: dashboardAccountRolePermissions } = useCurrentUserPermissions();

  const shouldShowSubMenu = useMemo(() => {
    if (role === undefined) {
      return false;
    }
    if (!canShow) {
      return false;
    }
    if (role === Role.ServiceAdmin) {
      return true;
    }
    if (features === "onlyServiceAdmin") {
      // 上でチェックしているので、ここでは serviceAdmin ではないユーザ
      return false;
    }

    const hasViewPermission = features.some((feature) =>
      dashboardAccountRolePermissions.some(
        (permission) =>
          permission.feature === feature &&
          permission.type === DashboardAccountRolePermissionTypeEnum.View,
      ),
    );

    return hasViewPermission;
  }, [role, canShow, features, dashboardAccountRolePermissions]);

  return shouldShowSubMenu ? <SubMenu {...props} /> : null;
});
