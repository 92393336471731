import React, { memo } from "react";
import { Checkbox } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const OpenPriceField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem.NonProperty {...props}>
    <EditMenuFormItem
      name="openPrice"
      valuePropName="checked"
      help="ハンディから注文時に価格を都度設定できるようになります。利用時にはモバイルオーダーの画面には表示されなくなります。"
    >
      <Checkbox disabled={disabled}>フリーキーとして利用する</Checkbox>
    </EditMenuFormItem>
  </EditMenuFormItem.NonProperty>
));
