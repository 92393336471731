import React, { useCallback } from "react";
import styled from "styled-components";
import { Alert, Button } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { colors } from "constants/colors";
import { useCompany } from "hooks/useCompany";

import { EnableAutoTranslationSwitch } from "./EnableAutoTranslationSwitch";
import {
  useAutoTranslationGetEnableAutoTranslationQuery,
  useAutoTranslationUpdateEnableAutoTranslationMutation,
} from "./queries";
import { useAutoTranslationForm } from "./useAutoTranslationForm";

const Description = styled.div`
  background-color: ${colors.BackGround.PrimarySecondary};
  border-radius: 8px;
  padding: 8px 12px;
  line-height: 22px;
  p {
    margin: 0;
  }
`;

export const AutoTranslation = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data,
    loading: loadingGetEnableAutoTranslation,
    error,
  } = useAutoTranslationGetEnableAutoTranslationQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const enableAutoTranslation = data?.company[0]?.enableAutoTranslation;

  const { form, initialValues } = useAutoTranslationForm({ enableAutoTranslation });

  const [updateEnableAutoTranslationMutation, { loading: loadingUpdateEnableAutoTranslation }] =
    useAutoTranslationUpdateEnableAutoTranslationMutation();

  const handleSubmit = useCallback(async () => {
    if (!companyId) return;
    try {
      const { enableAutoTranslation } = form.getFieldsValue();
      await updateEnableAutoTranslationMutation({
        variables: { companyId, enableAutoTranslation },
      });
      message.success("編集を保存しました");
    } catch (err) {
      message.error("編集の保存に失敗しました");
    }
  }, [companyId, form, updateEnableAutoTranslationMutation]);

  const loading = loadingGetEnableAutoTranslation || loadingUpdateEnableAutoTranslation;

  return (
    <DashboardLayout title="自動翻訳設定">
      <PageHeader title="自動翻訳設定" />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {enableAutoTranslation !== undefined && (
        <>
          <FormContent>
            <Form
              name="autoTranslation"
              form={form}
              layout="vertical"
              initialValues={initialValues}
            >
              <EnableAutoTranslationSwitch />
            </Form>
            <Spacer size={16} />
            <Description>
              <p>【翻訳箇所】</p>
              <p>・メニュー設定：メニュー名、メニュー説明文、特集ラベル</p>
              <p>・プラン設定：プラン名、プラン説明文、特集ラベル</p>
              <p>・オプション設定：オプション名、選択肢名</p>
              <p>・カテゴリ設定：カテゴリ名</p>
              <p>・おすすめ設定：おすすめ名</p>
              <br />
              <p>以上の入力情報を英語、中国語（簡体字）、韓国語に自動翻訳する機能です。</p>
              <p>
                また日本語メニュー名を変更されたら、それに伴い英語、中国語（簡体字）、韓国語も自動更新されます。
              </p>
              <p>【自動翻訳更新時間】</p>
              <p>・毎朝5時〜</p>
              <p>・翻訳数によって終了時刻は異なります</p>
              <br />
              <p>
                ※自動翻訳は<a href="https://www.deepl.com/ja/translator">「DeepL翻訳」</a>
                で行っており、空欄になっている場合や誤りがある場合がございます。必ず、お客様への公開前に問題がないかご確認ください。
              </p>
            </Description>
          </FormContent>
          <Spacer size={24} />
          <FormActions>
            <Button onClick={() => form.resetFields()}>キャンセル</Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              更新
            </Button>
          </FormActions>
        </>
      )}
    </DashboardLayout>
  );
};
