import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SalesGetSales = gql`
    query SalesGetSales($input: QuerySalesInput!) {
  sales(input: $input) {
    aggregatedSales {
      dimension
      taxExcludedAmount
      taxIncludedAmount
      taxIncludedCostPrice
      taxExcludedCostPrice
    }
  }
}
    `;
export type SalesGetSalesQueryVariables = Types.Exact<{
  input: Types.QuerySalesInput;
}>;


export type SalesGetSalesQuery = (
  { __typename?: 'query_root' }
  & { sales: (
    { __typename?: 'Sales' }
    & { aggregatedSales: Array<(
      { __typename?: 'AggregatedSales' }
      & Pick<Types.AggregatedSales, 'dimension' | 'taxExcludedAmount' | 'taxIncludedAmount' | 'taxIncludedCostPrice' | 'taxExcludedCostPrice'>
    )> }
  ) }
);


export const SalesGetSalesDocument = gql`
    query SalesGetSales($input: QuerySalesInput!) {
  sales(input: $input) {
    aggregatedSales {
      dimension
      taxExcludedAmount
      taxIncludedAmount
      taxIncludedCostPrice
      taxExcludedCostPrice
    }
  }
}
    `;

/**
 * __useSalesGetSalesQuery__
 *
 * To run a query within a React component, call `useSalesGetSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesGetSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesGetSalesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesGetSalesQuery(baseOptions: Apollo.QueryHookOptions<SalesGetSalesQuery, SalesGetSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesGetSalesQuery, SalesGetSalesQueryVariables>(SalesGetSalesDocument, options);
      }
export function useSalesGetSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesGetSalesQuery, SalesGetSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesGetSalesQuery, SalesGetSalesQueryVariables>(SalesGetSalesDocument, options);
        }
export type SalesGetSalesQueryHookResult = ReturnType<typeof useSalesGetSalesQuery>;
export type SalesGetSalesLazyQueryHookResult = ReturnType<typeof useSalesGetSalesLazyQuery>;
export type SalesGetSalesQueryResult = Apollo.QueryResult<SalesGetSalesQuery, SalesGetSalesQueryVariables>;