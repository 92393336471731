import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { Bar, Cell, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { colors } from "constants/colors";
import { SalesAnalyticsRow } from "hooks/useSalesAnalytics/types";

import { salesAnalyticsDayOfWeekLabelMap } from "..";

import { formatPriceForSalesAnalyticsChart } from "./utils";

const Container = styled.div`
  background: ${colors.BackGround.Default};
  border-radius: 6px;
  width: 100%;
  height: 230px;
`;

const RadioGroupContainer = styled.div`
  padding: 20px;
`;

const ToolTipContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.BackGround.Default};
  box-sizing: border-box;
  border: 1px solid ${colors.Border.Default};
  padding: 12px;
`;

const ToolTipTitle = styled.text`
  font-weight: bold;
  font-size: 14px;
`;

const ToolTipTextContainer = styled.text`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding-top: 6px;
`;

const enum DisplayTarget {
  totalAmount = "totalAmount",
  numPeople = "numPeople",
  salesPerCustomerAmount = "salesPerCustomerAmount",
}

type Props = {
  rows: SalesAnalyticsRow[];
};

const renderXAxisTick = ({
  x,
  y,
  payload: { value },
}: {
  x: number;
  y: number;
  payload: { value: string };
}) => {
  const color =
    value === salesAnalyticsDayOfWeekLabelMap.Sunday
      ? "#FF4D4F"
      : value === salesAnalyticsDayOfWeekLabelMap.Saturday
      ? "#1677FF"
      : "#999999";
  return (
    <g transform={`translate(${x},${y})`}>
      <text fill={color} dy={16} textAnchor="middle" fontSize={10}>
        {value ?? ""}
      </text>
    </g>
  );
};

export const DayOfWeekSalesAnalyticsChart = React.memo(({ rows }: Props) => {
  const [displayTarget, setDisplayTarget] = useState<DisplayTarget>(DisplayTarget.totalAmount);
  const [focusedBar, setFocusedBar] = useState<number | null>(null);
  const filteredRows = useMemo(
    () => rows.filter((row) => row.isEmpty || !row.isSummaryRow),
    [rows],
  );

  const renderYAxisTick = useCallback(
    ({ x, y, payload: { value } }: { x: number; y: number; payload: { value: number } }) => {
      const formattedValue =
        displayTarget !== DisplayTarget.numPeople
          ? Math.floor(value / 10000) > 0
            ? `${Math.floor(value / 10000)}万`
            : value
          : value;

      if (!formattedValue) return <></>;

      return (
        <g transform={`translate(${x - 20},${y + 5})`}>
          <text fill="#999999" textAnchor="middle" fontSize={10}>
            {formattedValue}
          </text>
        </g>
      );
    },
    [displayTarget],
  );

  const renderToolTip = useCallback(
    ({
      payload,
      label,
      active,
    }: {
      payload?: {
        dataKey?: string | number | undefined;
        value?: number;
      }[];
      label?: string;
      active?: boolean;
    }) => {
      if (active && payload && label) {
        const referenceRow = rows.find(({ name }) => name === label);

        if (!referenceRow || referenceRow.isEmpty) {
          return <></>;
        }

        return (
          <ToolTipContainer>
            <ToolTipTitle>{label ?? ""}</ToolTipTitle>
            <ToolTipTextContainer>
              売上：{formatPriceForSalesAnalyticsChart(referenceRow.totalAmount)}
            </ToolTipTextContainer>
            <ToolTipTextContainer>客数：{referenceRow.numPeople}名</ToolTipTextContainer>
            <ToolTipTextContainer>
              客単価：{formatPriceForSalesAnalyticsChart(referenceRow.salesPerCustomerAmount)}
            </ToolTipTextContainer>
          </ToolTipContainer>
        );
      }

      return <></>;
    },
    [rows],
  );

  return (
    <Container>
      <RadioGroupContainer>
        <Radio.Group
          defaultValue={DisplayTarget.totalAmount}
          onChange={(e: RadioChangeEvent) => setDisplayTarget(e.target.value as DisplayTarget)}
        >
          <Radio value={DisplayTarget.totalAmount}>売上</Radio>
          <Radio value={DisplayTarget.numPeople}>客数</Radio>
          <Radio value={DisplayTarget.salesPerCustomerAmount}>客単価</Radio>
        </Radio.Group>
      </RadioGroupContainer>

      <ResponsiveContainer width="100%" height="80%">
        <ComposedChart
          width={976}
          height={230}
          data={filteredRows}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          onMouseMove={(state) => {
            if (state.isTooltipActive) {
              setFocusedBar(
                state.activeTooltipIndex !== undefined ? state.activeTooltipIndex : null,
              );
            } else {
              setFocusedBar(null);
            }
          }}
        >
          <XAxis
            xAxisId={0}
            tick={renderXAxisTick}
            tickLine={false}
            dataKey="name"
            scale="auto"
            interval={0}
            axisLine={{ stroke: colors.Border.Default, strokeDasharray: "5 5" }}
            color="#ffffff"
          />
          <YAxis
            tick={renderYAxisTick}
            tickLine={false}
            domain={[0, "dataMax"]}
            axisLine={{ stroke: colors.Border.Default }}
            yAxisId="left"
          />
          <YAxis
            orientation="right"
            axisLine={{ stroke: colors.Border.Default }}
            tickLine={false}
          />

          <Tooltip content={renderToolTip} />

          <Bar dataKey={displayTarget} barSize={16} yAxisId="left">
            {rows.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  focusedBar === null || focusedBar === index
                    ? colors.BackGround.PrimaryDefault
                    : "#B9D6FF"
                }
              />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
});
