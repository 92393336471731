import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { BranchCode } from "hooks/useBankCode";

import { EditGmoBankAccountFormItem } from "../useEditGmoBankAccountForm";

const { Option } = Select;

type Props = {
  branches: BranchCode[];
  defaultValue: string;
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const BranchField = memo<Props>(({ branches, defaultValue, disabled, ...props }) => (
  <EditGmoBankAccountFormItem.NonProperty noStyle>
    <EditGmoBankAccountFormItem
      label="支店"
      name="branch"
      rules={[{ required: true, message: "支店を選択して下さい" }]}
      {...props}
    >
      <Select<string>
        showSearch
        placeholder="支店名"
        optionFilterProp="children"
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {branches.map(({ code, name }) => (
          <Option key={code} value={code}>
            {name}
          </Option>
        ))}
      </Select>
    </EditGmoBankAccountFormItem>
  </EditGmoBankAccountFormItem.NonProperty>
));
