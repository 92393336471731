import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import dayjs from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useShop } from "hooks/useShop";
import {
  DailySalesBudgetInsertInput,
  MonthlySalesBudgetSetInput,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import { EditSalesBudgetForm } from "./EditSalesBudgetForm";
import {
  useEditSalesBudgetGetBudgetQuery,
  useEditSalesBudgetGetSalesBudgetAllocationSettingQuery,
  useEditSalesBudgetGetSalesForBudgetQuery,
  useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation,
  useEditSalesBudgetUpdateBudgetMutation,
} from "./queries";

export const EditSalesBudget = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { id: monthlyBudgetId } = useParams<{ id: string }>();

  const {
    data: allocationSettingData,
    loading: loadingAllocationSetting,
    refetch: refetchAllocationSetting,
  } = useEditSalesBudgetGetSalesBudgetAllocationSettingQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const allocationSetting = allocationSettingData?.salesBudgetAllocationSetting[0];

  const {
    data: getBudgetData,
    loading: loadingBudgetData,
    refetch: refetchBudgetData,
  } = useEditSalesBudgetGetBudgetQuery(
    shopId && monthlyBudgetId ? { variables: { id: monthlyBudgetId, shopId } } : { skip: true },
  );
  const budget = useMemo(() => getBudgetData?.monthlySalesBudget[0], [getBudgetData]);
  const targetMonth = useMemo(() => dayjs(budget?.businessDate), [budget?.businessDate]);

  const { data: salesForBudgetData } = useEditSalesBudgetGetSalesForBudgetQuery(
    shopId && targetMonth
      ? {
          variables: {
            input: {
              shopId,
              averageAndMaxSalesByDayOfWeekInput: {
                targetMonth: dayjs(targetMonth).subtract(1, "month").startOf("month").toISOString(),
              },
              salesByDaysInput: null,
              salesByMonthsInput: {
                from: targetMonth.subtract(1, "year").startOf("month").toISOString(),
                to: targetMonth.endOf("month").toISOString(),
              },
            },
          },
        }
      : { skip: true },
  );
  const salesForBudget = salesForBudgetData?.salesForBudget;
  const averageAndMaxSalesByDayOfWeek = salesForBudget?.averageAndMaxSalesByDayOfWeek ?? [];
  const salesByMonths = salesForBudget?.salesByMonths ?? undefined;

  const [updateBudget, { loading: loadingUpdateBudget }] = useEditSalesBudgetUpdateBudgetMutation();
  const [insertAllocationSetting, { loading: loadingInsertSalesBudgetAllocationSetting }] =
    useEditSalesBudgetInsertSalesBudgetAllocationSettingMutation();

  const onSubmit = useCallback(
    async ({
      monthlySalesBudget,
      dailySalesBudgets,
    }: {
      monthlySalesBudget: MonthlySalesBudgetSetInput;
      dailySalesBudgets: DailySalesBudgetInsertInput[];
    }) => {
      if (!monthlyBudgetId || !shopId) return;
      try {
        await updateBudget({
          variables: { id: monthlyBudgetId, monthlySalesBudget, dailySalesBudgets },
        });

        await refetchBudgetData();

        message.success("更新しました");

        goBack();
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [monthlyBudgetId, shopId, updateBudget, goBack, refetchBudgetData],
  );

  const [{ loading: loadingSubmitAllocationSetting }, onSubmitAllocationSetting] = useAsyncFn(
    async (allocationSetting: SalesBudgetAllocationSettingInsertInput) => {
      try {
        await insertAllocationSetting({
          variables: { allocationSetting },
        });
        await refetchAllocationSetting();
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [insertAllocationSetting, refetchAllocationSetting],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const loading =
    loadingBudgetData ||
    loadingUpdateBudget ||
    loadingAllocationSetting ||
    loadingInsertSalesBudgetAllocationSetting;

  const loadingInitialAllocationSetting =
    !loadingSubmitAllocationSetting && loadingAllocationSetting;

  return (
    <DashboardLayout
      title="目標設定編集"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "目標設定" }],
      }}
    >
      <PageHeader title="目標設定編集" onBack={goBack} />
      {loadingInitialAllocationSetting || !shopId || !budget ? (
        <Loading />
      ) : (
        <EditSalesBudgetForm
          budget={budget}
          shopId={shopId}
          loading={loading}
          onSubmit={onSubmit}
          onSubmitAllocationSetting={onSubmitAllocationSetting}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
          allocationSetting={allocationSetting}
          averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
          salesByMonths={salesByMonths}
        />
      )}
    </DashboardLayout>
  );
};
