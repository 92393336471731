import React from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { FormHelp } from "components/Form/FormHelp";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { OrderableTimeShopsTable } from "./OrderableTimeShopsTable";
import {
  useOrderableTimeShopsGetOrderableTimeQuery,
  useOrderableTimeShopsGetShopsQuery,
} from "./queries";

export const OrderableTimeShops = () => {
  const { id = "" } = useParams<{ id: string }>();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getShopsData,
    loading: loadingGetShops,
    error: getShopsError,
  } = useOrderableTimeShopsGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = getShopsData?.shop ?? [];

  const {
    data: getOrderableTimeData,
    loading: loadingGetOrderableTime,
    error: getOrderableTimeError,
  } = useOrderableTimeShopsGetOrderableTimeQuery({
    variables: { orderableTimeId: id },
  });
  const orderableTime = getOrderableTimeData?.orderableTime_by_pk;
  const shopOrderableTimeTerms = orderableTime?.shopOrderableTimeTerms ?? [];

  const shouldShowAlert = getShopsError || getOrderableTimeError;

  return (
    <DashboardLayout title={orderableTime?.name}>
      <PageHeader
        title={orderableTime?.name}
        description={
          <FormHelp
            label="アプリ表示時間設定では、メニューやプランがお客様のスマートフォンアプリ上の画面に表示される時間帯を設定することが可能です。"
            help="ハンディではアプリ表示時間に関係なく常に注文が可能です。"
          />
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <OrderableTimeShopsTable
        shops={shops}
        orderableTimeId={orderableTime?.id}
        shopOrderableTimeTerms={shopOrderableTimeTerms}
        loading={loadingGetShops || loadingGetOrderableTime}
      />
    </DashboardLayout>
  );
};
