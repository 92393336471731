import dayjs from "dayjs";

import { CoineySettlement } from "types/graphql";

export type FilterConditions = {
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
};

export const filter = <T extends Pick<CoineySettlement, "chargeDate">>(
  coineySettlements: T[],
  { range }: FilterConditions,
) => {
  const start = range?.[0];
  const end = range?.[1];
  const filteredCoineySettlements = coineySettlements.filter(
    (coineySettlement) =>
      (start ? dayjs(coineySettlement.chargeDate).isSameOrAfter(start) : true) &&
      (end ? dayjs(coineySettlement.chargeDate).isSameOrBefore(end) : true),
  );

  return filteredCoineySettlements;
};
