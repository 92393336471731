import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { insertWithRelation } from "util/type/insert-with-relation";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { AddCookingItemForm } from "pages/AddCookingItem/AddCookingItemForm";
import {
  useAddCookingItemGetRolesQuery,
  useAddCookingItemInsertShopCookingItemRoleMutation,
} from "pages/AddCookingItem/queries";

import { AddCookingItemFormValues } from "./AddCookingItemForm/useAddCookingItemForm";

export const AddCookingItem = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data: getRolesData, error } = useAddCookingItemGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = useMemo(
    () => getRolesData?.shop_by_pk?.roles ?? [],
    [getRolesData?.shop_by_pk?.roles],
  );

  const [addCookingItemMutation, { loading: loadingInsertCookingItem }] =
    useAddCookingItemInsertShopCookingItemRoleMutation();

  const onSubmit = useCallback(
    async (cookingItemFormValues: AddCookingItemFormValues) => {
      if (!companyId) return;
      if (!shopId) return;

      const role = roles.find((role) => role.roleId === cookingItemFormValues.roleId);

      if (!role) return;

      const cookingItemId = uuidv4();

      const shopCookingItemRole = insertWithRelation<["shopCookingItemRole", "cookingItem"]>({
        roleId: role.id,
        _roleId: role.roleId,
        shopId,
        cookingItemId,
        cookingItem: {
          data: {
            cookingItemId,
            name: cookingItemFormValues.name,
            companyId,
            shopId,
          },
        },
      });

      try {
        const { data: createdShopCookingItemRole } = await addCookingItemMutation({
          variables: { shopCookingItemRole },
        });
        const id = createdShopCookingItemRole?.insert_shopCookingItemRole_one?._cookingItemId;

        if (id === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/cookingItem/${id}/edit`, { replace: true });
      } catch (err) {
        console.error(err);
        message.error("作成に失敗しました");
      }
    },
    [companyId, shopId, roles, addCookingItemMutation, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="調理アイテムを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "調理アイテム" }],
      }}
    >
      <PageHeader title="調理アイテムを新規作成" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddCookingItemForm
        roles={roles}
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertCookingItem}
      />
    </DashboardLayout>
  );
};
