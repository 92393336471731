import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled: boolean;
};

export const NameField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanChoiceFormItem
    label="選択肢名 (日本語)"
    name="name"
    rules={[{ required: true, message: "選択肢名 (日本語)を入力してください" }]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditPlanChoiceFormItem>
));
