import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddGiftFormItem } from "../useAddGiftForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <AddGiftFormItem label="説明" name="description" {...props}>
    <Input />
  </AddGiftFormItem>
));
