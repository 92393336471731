import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { Maybe, WinboardConfigInsertInput } from "types/graphql";

import { WinboardConfig } from "./types";

export type WinboardConfigFormValues = Pick<
  Partial<WinboardConfig>,
  | "enabled"
  | "ftpHost"
  | "ftpPassword"
  | "ftpPort"
  | "ftpUser"
  | "putPath"
  | "winboardCompanyCd"
  | "winboardPosNumber"
  | "winboardShopCd"
>;

export const WinboardConfigFormItem = createFormItem<WinboardConfigFormValues>();

export const useWinboardConfigForm = ({
  config,
  onSubmit,
  onFormValidationError,
}: {
  config?: Maybe<WinboardConfig>;
  onSubmit: (args: Omit<WinboardConfigInsertInput, "shopId">) => Promise<void>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<WinboardConfigFormValues>(
    () => ({
      enabled: config?.enabled ?? false,
      ftpHost: config?.ftpHost,
      ftpPassword: config?.ftpPassword,
      ftpPort: config?.ftpPort,
      ftpUser: config?.ftpUser,
      putPath: config?.putPath,
      winboardCompanyCd: config?.winboardCompanyCd,
      winboardPosNumber: config?.winboardPosNumber,
      winboardShopCd: config?.winboardShopCd,
    }),
    [config],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as WinboardConfigFormValues;

      await onSubmit({
        enabled: values.enabled ?? false,
        ftpHost: values.ftpHost ?? "",
        ftpPassword: values.ftpPassword ?? "",
        ftpPort: values.ftpPort ?? 0,
        ftpUser: values.ftpUser ?? "",
        putPath: values.putPath ?? "",
        winboardCompanyCd: values.winboardCompanyCd ?? "",
        winboardPosNumber: values.winboardPosNumber ?? "",
        winboardShopCd: values.winboardShopCd ?? "",
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, submit };
};
