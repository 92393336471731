import React, { memo } from "react";
import { Button } from "antd";

import { EditPlanPikaichiPlanFormItem } from "../../useEditPlanPikaichiPlanForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const PikaichiGetAvailableMenuCdForOptionButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed: handleAutoAdoptMenuCodeButtonPressed }) => (
    <EditPlanPikaichiPlanFormItem
      name="pikaichiGetAvailableMenuCdForOptionButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditPlanPikaichiPlanFormItem>
  ),
);
