import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PlanGroupInsertInput } from "types/graphql";

import { NameField } from "./NameField";
import { useAddPlanGroupForm } from "./useAddPlanGroupForm";

type Props = {
  onSubmit: (planGroup: PlanGroupInsertInput) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddPlanGroupForm = memo<Props>(({ onClose, onSubmit, loading }) => {
  const { form, submit } = useAddPlanGroupForm(onSubmit);

  const handleSubmit = useCallback(async () => {
    await form
      .validateFields()
      .then(() => submit())
      .catch(() => message.error(`作成に失敗しました`));
  }, [form, submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="planGroup" form={form} layout="vertical">
          <NameField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          登録
        </Button>
      </FormActions>
    </>
  );
});
