import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuRecommendationGetMenuRecommendation = gql`
    query EditMenuRecommendationGetMenuRecommendation($id: uuid!) {
  menuRecommendationMeta_by_pk(id: $id) {
    id
    name
  }
}
    `;
export const EditMenuRecommendationUpdateMenuRecommendation = gql`
    mutation EditMenuRecommendationUpdateMenuRecommendation($id: uuid!, $menuRecommendation: menuRecommendationMeta_set_input!) {
  update_menuRecommendationMeta_by_pk(
    pk_columns: {id: $id}
    _set: $menuRecommendation
  ) {
    id
  }
}
    `;
export type EditMenuRecommendationGetMenuRecommendationQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditMenuRecommendationGetMenuRecommendationQuery = (
  { __typename?: 'query_root' }
  & { menuRecommendationMeta_by_pk?: Types.Maybe<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id' | 'name'>
  )> }
);

export type EditMenuRecommendationUpdateMenuRecommendationMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  menuRecommendation: Types.MenuRecommendationMetaSetInput;
}>;


export type EditMenuRecommendationUpdateMenuRecommendationMutation = (
  { __typename?: 'mutation_root' }
  & { update_menuRecommendationMeta_by_pk?: Types.Maybe<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id'>
  )> }
);


export const EditMenuRecommendationGetMenuRecommendationDocument = gql`
    query EditMenuRecommendationGetMenuRecommendation($id: uuid!) {
  menuRecommendationMeta_by_pk(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useEditMenuRecommendationGetMenuRecommendationQuery__
 *
 * To run a query within a React component, call `useEditMenuRecommendationGetMenuRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuRecommendationGetMenuRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuRecommendationGetMenuRecommendationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditMenuRecommendationGetMenuRecommendationQuery(baseOptions: Apollo.QueryHookOptions<EditMenuRecommendationGetMenuRecommendationQuery, EditMenuRecommendationGetMenuRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuRecommendationGetMenuRecommendationQuery, EditMenuRecommendationGetMenuRecommendationQueryVariables>(EditMenuRecommendationGetMenuRecommendationDocument, options);
      }
export function useEditMenuRecommendationGetMenuRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuRecommendationGetMenuRecommendationQuery, EditMenuRecommendationGetMenuRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuRecommendationGetMenuRecommendationQuery, EditMenuRecommendationGetMenuRecommendationQueryVariables>(EditMenuRecommendationGetMenuRecommendationDocument, options);
        }
export type EditMenuRecommendationGetMenuRecommendationQueryHookResult = ReturnType<typeof useEditMenuRecommendationGetMenuRecommendationQuery>;
export type EditMenuRecommendationGetMenuRecommendationLazyQueryHookResult = ReturnType<typeof useEditMenuRecommendationGetMenuRecommendationLazyQuery>;
export type EditMenuRecommendationGetMenuRecommendationQueryResult = Apollo.QueryResult<EditMenuRecommendationGetMenuRecommendationQuery, EditMenuRecommendationGetMenuRecommendationQueryVariables>;
export const EditMenuRecommendationUpdateMenuRecommendationDocument = gql`
    mutation EditMenuRecommendationUpdateMenuRecommendation($id: uuid!, $menuRecommendation: menuRecommendationMeta_set_input!) {
  update_menuRecommendationMeta_by_pk(
    pk_columns: {id: $id}
    _set: $menuRecommendation
  ) {
    id
  }
}
    `;
export type EditMenuRecommendationUpdateMenuRecommendationMutationFn = Apollo.MutationFunction<EditMenuRecommendationUpdateMenuRecommendationMutation, EditMenuRecommendationUpdateMenuRecommendationMutationVariables>;

/**
 * __useEditMenuRecommendationUpdateMenuRecommendationMutation__
 *
 * To run a mutation, you first call `useEditMenuRecommendationUpdateMenuRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuRecommendationUpdateMenuRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuRecommendationUpdateMenuRecommendationMutation, { data, loading, error }] = useEditMenuRecommendationUpdateMenuRecommendationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      menuRecommendation: // value for 'menuRecommendation'
 *   },
 * });
 */
export function useEditMenuRecommendationUpdateMenuRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuRecommendationUpdateMenuRecommendationMutation, EditMenuRecommendationUpdateMenuRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuRecommendationUpdateMenuRecommendationMutation, EditMenuRecommendationUpdateMenuRecommendationMutationVariables>(EditMenuRecommendationUpdateMenuRecommendationDocument, options);
      }
export type EditMenuRecommendationUpdateMenuRecommendationMutationHookResult = ReturnType<typeof useEditMenuRecommendationUpdateMenuRecommendationMutation>;
export type EditMenuRecommendationUpdateMenuRecommendationMutationResult = Apollo.MutationResult<EditMenuRecommendationUpdateMenuRecommendationMutation>;
export type EditMenuRecommendationUpdateMenuRecommendationMutationOptions = Apollo.BaseMutationOptions<EditMenuRecommendationUpdateMenuRecommendationMutation, EditMenuRecommendationUpdateMenuRecommendationMutationVariables>;