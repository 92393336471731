import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { CategoryMenu } from "pages/EditMenusBulk/types";
import { UpdateCategoryMenusBulkInput } from "types/graphql";

export type EditMenusBulkFormValues = {
  price: number | undefined;
  taxRate: number | undefined;
  taxMethod: string | undefined;
  costPrice: number | undefined;
  costTaxRate: number | undefined;
  displayType: string | undefined;
  imageUrl: string | undefined;
};

export type EditMenusBulkTargetValues = Record<number, EditMenusBulkFormValues>;

export type EditMenusBulkMultipleEditValues = { multiEditValues: EditMenusBulkFormValues };

export type EditMenusBulkTargetValueCandidate =
  | { imageUrl: string | undefined }
  | { displayType: string | undefined }
  | { price: number | undefined }
  | { taxRate: number | undefined }
  | { taxMethod: string | undefined }
  | { costPrice: number | undefined }
  | { costTaxRate: number | undefined };

export type EditMenusBulkFormValue =
  | { imageUrl: string | undefined }
  | { displayType: string | undefined }
  | { price: string | undefined }
  | { taxRate: string | undefined }
  | { taxMethod: string | undefined }
  | { costPrice: string | undefined }
  | { costTaxRate: string | undefined };

export type EditMenusBulkFormInitialValue = {
  categoryName: string;
  categoryMenuId: number;
  categoryId: number;
  displayType: string | undefined;
  imageUrl: string | undefined | null;
  menuId: number;
  name: string;
  price: number;
  taxRate: number;
  taxMethod: string;
  costPrice: number | undefined | null;
  costTaxRate: number | undefined | null;
  shopIds: string[];
  canEdit: boolean;
};

const getInitialValues = (categoryMenus: CategoryMenu[]): EditMenusBulkFormInitialValue[] =>
  categoryMenus
    .map((categoryMenu) => {
      const menu = categoryMenu.menu;
      const { categoryId, categoryMenuId, displayType } = categoryMenu;
      const {
        name,
        menuId,
        taxRate,
        taxMethod,
        costPrice,
        costTaxRate,
        shopMenus,
        price,
        imageUrl,
      } = menu;
      const { name: categoryName } = categoryMenu.category;

      return {
        categoryName,
        categoryMenuId,
        categoryId,
        displayType,
        imageUrl,
        menuId,
        name,
        price,
        taxRate,
        taxMethod,
        costPrice,
        costTaxRate,
        shopIds: shopMenus.map((sm) => sm.shop.shopId),
        // NOTE: すべての取扱を参照可能な場合にメニュー本体も編集可能になる
        canEdit: shopMenus.length === menu.totalDealingShopCount,
      };
    })
    .sort((a, b) => a.categoryId - b.categoryId);

export const EditMenusBulkFormItem = createFormItem<EditMenusBulkFormValues>();

export const useEditMenusBulkForm = ({
  categoryMenus,
  filteredCategoryMenus,
  onSubmit,
}: {
  categoryMenus: CategoryMenu[];
  filteredCategoryMenus: CategoryMenu[];
  onSubmit: (input: UpdateCategoryMenusBulkInput) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = useMemo(
    () => getInitialValues(filteredCategoryMenus),
    [filteredCategoryMenus],
  );
  const categoryMenuIdToInitialValueMap = useMemo(
    () => new Map(getInitialValues(categoryMenus).map((v) => [v.categoryMenuId, v])),
    [categoryMenus],
  );

  const [targetValues, setTargetValues] = useState<EditMenusBulkTargetValues>({});

  const handleSetTargetValues: Dispatch<SetStateAction<EditMenusBulkTargetValues>> = useCallback(
    (setStateAction) =>
      setTargetValues((values) => {
        const targetValues =
          typeof setStateAction === "function" ? setStateAction(values) : setStateAction;

        form.setFieldsValue(targetValues);

        return targetValues;
      }),
    [form],
  );

  const submit = useCallback(() => {
    const categoryMenus: UpdateCategoryMenusBulkInput["categoryMenus"] = Object.entries(
      targetValues,
    ).map(([categoryMenuIdString, value]) => ({
      categoryMenuId: Number(categoryMenuIdString),
      price: value.price, // TODO: categoryMenu.price が使用されなくなったら消す
      menu: {
        taxRate: value.taxRate,
        taxMethod: value.taxMethod ?? undefined,
        costPrice: value.costPrice,
        costTaxRate: value.costTaxRate,
        price: value.price,
        displayType: value.displayType,
        imageUrl: value.imageUrl,
      },
    }));

    onSubmit({ categoryMenus });

    setTargetValues({});
    form.resetFields();
  }, [form, onSubmit, targetValues]);

  return {
    initialValues,
    submit,
    form,
    targetValues,
    setTargetValues: handleSetTargetValues,
    categoryMenuIdToInitialValueMap,
  };
};
