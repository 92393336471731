import React, { memo } from "react";
import styled from "styled-components";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

const HelpText = styled.span`
  display: block;
`;

export const EnableDisplayTaxExcludedPriceForCustomer = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="税抜価格表示設定"
        help={
          <>
            <HelpText>モバイルオーダー画面で、メニューが税抜価格で表示されます。</HelpText>
            <HelpText>表示例: ¥100(税込¥110)</HelpText>
          </>
        }
      />
    }
    name="enableDisplayTaxExcludedPriceForCustomer"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
