import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";

export const TablePrintingMenu = memo<SubMenuPropsWithoutFeatures>((props) => (
  <SubMenu key="tablePrinting" title="テーブル出し分け" features="onlyServiceAdmin" {...props}>
    <MenuItem
      route="kitchenSlipTablePrinting"
      to="/tablePrinting/kitchenSlip"
      text="キッチン伝票"
    />
    <MenuItem
      route="dishUpSlipGroupTablePrinting"
      to="/tablePrinting/dishUpSlipGroup"
      text="デシャップ伝票グループ"
    />
    <MenuItem
      route="paymentReceiptRoleTablePrinting"
      to="/tablePrinting/paymentReceiptRole"
      text="自動会計伝票"
    />
  </SubMenu>
));
