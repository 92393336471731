import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationOnSitePaymentDetailTypeGetDetailType = gql`
    query EditTecAggregationOnSitePaymentDetailTypeGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDetailType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    tecAggregationOnSitePaymentDetailTypes {
      id
      onSitePaymentDetailTypeEntityType
      mediaCode
      mediaName
      paymentType
      tecAggregationMediaMapId
      tecAggregationMediaMap {
        aggregationNumber
        mediaName
      }
    }
  }
}
    `;
export const EditTecAggregationOnSitePaymentDetailTypeGetMediaMaps = gql`
    query EditTecAggregationOnSitePaymentDetailTypeGetMediaMaps($companyId: uuid!) {
  tecAggregationMediaMap(where: {companyId: {_eq: $companyId}}) {
    id
    aggregationNumber
    mediaName
  }
}
    `;
export const EditTecAggregationOnSitePaymentDetailTypeUpsertDetailType = gql`
    mutation EditTecAggregationOnSitePaymentDetailTypeUpsertDetailType($detailType: tecAggregationOnSitePaymentDetailType_insert_input!) {
  insert_tecAggregationOnSitePaymentDetailType_one(
    object: $detailType
    on_conflict: {constraint: tecAggregationOnSitePaymentDetailType_pkey, update_columns: [mediaCode, mediaName, paymentType, tecAggregationMediaMapId, onSitePaymentDetailTypeId]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables = Types.Exact<{
  type: Types.Scalars['String'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
    & { tecAggregationOnSitePaymentDetailTypes: Array<(
      { __typename?: 'tecAggregationOnSitePaymentDetailType' }
      & Pick<Types.TecAggregationOnSitePaymentDetailType, 'id' | 'onSitePaymentDetailTypeEntityType' | 'mediaCode' | 'mediaName' | 'paymentType' | 'tecAggregationMediaMapId'>
      & { tecAggregationMediaMap?: Types.Maybe<(
        { __typename?: 'tecAggregationMediaMap' }
        & Pick<Types.TecAggregationMediaMap, 'aggregationNumber' | 'mediaName'>
      )> }
    )> }
  )> }
);

export type EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMediaMap: Array<(
    { __typename?: 'tecAggregationMediaMap' }
    & Pick<Types.TecAggregationMediaMap, 'id' | 'aggregationNumber' | 'mediaName'>
  )> }
);

export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationVariables = Types.Exact<{
  detailType: Types.TecAggregationOnSitePaymentDetailTypeInsertInput;
}>;


export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tecAggregationOnSitePaymentDetailType_one?: Types.Maybe<(
    { __typename?: 'tecAggregationOnSitePaymentDetailType' }
    & Pick<Types.TecAggregationOnSitePaymentDetailType, 'id'>
  )> }
);


export const EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeDocument = gql`
    query EditTecAggregationOnSitePaymentDetailTypeGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDetailType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
    tecAggregationOnSitePaymentDetailTypes {
      id
      onSitePaymentDetailTypeEntityType
      mediaCode
      mediaName
      paymentType
      tecAggregationMediaMapId
      tecAggregationMediaMap {
        aggregationNumber
        mediaName
      }
    }
  }
}
    `;

/**
 * __useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery, EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery, EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables>(EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeDocument, options);
      }
export function useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery, EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery, EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables>(EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeDocument, options);
        }
export type EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryHookResult = ReturnType<typeof useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery>;
export type EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeLazyQueryHookResult = ReturnType<typeof useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeLazyQuery>;
export type EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryResult = Apollo.QueryResult<EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery, EditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQueryVariables>;
export const EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsDocument = gql`
    query EditTecAggregationOnSitePaymentDetailTypeGetMediaMaps($companyId: uuid!) {
  tecAggregationMediaMap(where: {companyId: {_eq: $companyId}}) {
    id
    aggregationNumber
    mediaName
  }
}
    `;

/**
 * __useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery, EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery, EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables>(EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsDocument, options);
      }
export function useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery, EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery, EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables>(EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsDocument, options);
        }
export type EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryHookResult = ReturnType<typeof useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery>;
export type EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsLazyQueryHookResult = ReturnType<typeof useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsLazyQuery>;
export type EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryResult = Apollo.QueryResult<EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery, EditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQueryVariables>;
export const EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeDocument = gql`
    mutation EditTecAggregationOnSitePaymentDetailTypeUpsertDetailType($detailType: tecAggregationOnSitePaymentDetailType_insert_input!) {
  insert_tecAggregationOnSitePaymentDetailType_one(
    object: $detailType
    on_conflict: {constraint: tecAggregationOnSitePaymentDetailType_pkey, update_columns: [mediaCode, mediaName, paymentType, tecAggregationMediaMapId, onSitePaymentDetailTypeId]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationFn = Apollo.MutationFunction<EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation, EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationVariables>;

/**
 * __useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation, { data, loading, error }] = useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation({
 *   variables: {
 *      detailType: // value for 'detailType'
 *   },
 * });
 */
export function useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation, EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation, EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationVariables>(EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeDocument, options);
      }
export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationHookResult = ReturnType<typeof useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation>;
export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationResult = Apollo.MutationResult<EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation>;
export type EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation, EditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutationVariables>;