import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ConnectGameConfigsGetConnectGameConfigs = gql`
    query ConnectGameConfigsGetConnectGameConfigs($companyId: uuid!) {
  connectGameConfig(
    order_by: {createdAt: desc}
    where: {companyId: {_eq: $companyId}, connectGameConfigShops: {shop: {archivedAt: {_is_null: true}}}}
  ) {
    id
    enabled
    maxWinningRate
    startTime
    endTime
    startImageUrl
    prizeCoupon {
      id
      name
    }
    consolationCoupon {
      id
      name
    }
    connectGameConfigShops {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;
export type ConnectGameConfigsGetConnectGameConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ConnectGameConfigsGetConnectGameConfigsQuery = (
  { __typename?: 'query_root' }
  & { connectGameConfig: Array<(
    { __typename?: 'connectGameConfig' }
    & Pick<Types.ConnectGameConfig, 'id' | 'enabled' | 'maxWinningRate' | 'startTime' | 'endTime' | 'startImageUrl'>
    & { prizeCoupon: (
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'name'>
    ), consolationCoupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'name'>
    )>, connectGameConfigShops: Array<(
      { __typename?: 'connectGameConfigShop' }
      & Pick<Types.ConnectGameConfigShop, 'id'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
        & { id: Types.Shop['shopId'] }
      ) }
    )> }
  )> }
);


export const ConnectGameConfigsGetConnectGameConfigsDocument = gql`
    query ConnectGameConfigsGetConnectGameConfigs($companyId: uuid!) {
  connectGameConfig(
    order_by: {createdAt: desc}
    where: {companyId: {_eq: $companyId}, connectGameConfigShops: {shop: {archivedAt: {_is_null: true}}}}
  ) {
    id
    enabled
    maxWinningRate
    startTime
    endTime
    startImageUrl
    prizeCoupon {
      id
      name
    }
    consolationCoupon {
      id
      name
    }
    connectGameConfigShops {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;

/**
 * __useConnectGameConfigsGetConnectGameConfigsQuery__
 *
 * To run a query within a React component, call `useConnectGameConfigsGetConnectGameConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectGameConfigsGetConnectGameConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectGameConfigsGetConnectGameConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useConnectGameConfigsGetConnectGameConfigsQuery(baseOptions: Apollo.QueryHookOptions<ConnectGameConfigsGetConnectGameConfigsQuery, ConnectGameConfigsGetConnectGameConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectGameConfigsGetConnectGameConfigsQuery, ConnectGameConfigsGetConnectGameConfigsQueryVariables>(ConnectGameConfigsGetConnectGameConfigsDocument, options);
      }
export function useConnectGameConfigsGetConnectGameConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectGameConfigsGetConnectGameConfigsQuery, ConnectGameConfigsGetConnectGameConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectGameConfigsGetConnectGameConfigsQuery, ConnectGameConfigsGetConnectGameConfigsQueryVariables>(ConnectGameConfigsGetConnectGameConfigsDocument, options);
        }
export type ConnectGameConfigsGetConnectGameConfigsQueryHookResult = ReturnType<typeof useConnectGameConfigsGetConnectGameConfigsQuery>;
export type ConnectGameConfigsGetConnectGameConfigsLazyQueryHookResult = ReturnType<typeof useConnectGameConfigsGetConnectGameConfigsLazyQuery>;
export type ConnectGameConfigsGetConnectGameConfigsQueryResult = Apollo.QueryResult<ConnectGameConfigsGetConnectGameConfigsQuery, ConnectGameConfigsGetConnectGameConfigsQueryVariables>;