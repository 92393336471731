import React, { memo } from "react";
import { isNot } from "util/type/primitive";

import { Select } from "components/Input/Select";
import type { Role } from "pages/ShopMenus/types";

type Props = {
  onChange: (roleIds: "empty" | number[]) => void;
  roles: Role[];
  value: "empty" | number[] | undefined;
};

export const KitchenRoleField = memo<Props>(({ onChange, roles, value }) => (
  <Select<(number | "empty")[]>
    mode="multiple"
    allowClear
    placeholder="キッチンプリンター"
    defaultValue={[]}
    value={value === "empty" ? [value] : value ?? []}
    onChange={(roleIds) =>
      onChange(roleIds.slice(-1)[0] === "empty" ? "empty" : roleIds.filter(isNot("empty")))
    }
    optionFilterProp="label"
    options={[
      { label: "未設定", value: "empty" },
      ...roles.map(({ roleId, name }) => ({ label: name, value: roleId })),
    ]}
    data-cy="select-kitchen-role"
  />
));
