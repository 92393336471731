import { useCallback, useState } from "react";
import { Store } from "antd/lib/form/interface";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import {
  EditShopMenuOutputGetMenu,
  useEditShopMenuOutputDeleteShopMenuRolesRoleMutation,
  useEditShopMenuOutputInsertShopMenuRolesRoleMutation,
} from "pages/EditShopMenuOutput/queries";
import { Menu, Role } from "pages/EditShopMenuOutput/types";
import { ShopMenuKitchenRoleInsertInput } from "types/graphql";

export type EditMenuRoleFormValues = Record<string, boolean>;

const getInitialValues = (menuRoles: Role[], roles: Role[]) => {
  const menuRolesRoleIds = menuRoles.map(({ id }) => id);
  return Object.fromEntries(roles.map(({ id }) => [id, menuRolesRoleIds.includes(id)]));
};

const useLoadings = (roles: Role[]) => {
  const initialLoadings = Object.fromEntries<boolean>(roles.map(({ id }) => [id, false]));
  const [loadings, setLoadings] = useState(initialLoadings);

  const onLoad = useCallback(
    (roleId: string, state: boolean) =>
      setLoadings((loadings) => ({ ...loadings, [roleId]: state })),
    [],
  );

  const onRequest = useCallback((roleId: string) => onLoad(roleId, true), [onLoad]);
  const onFinish = useCallback((roleId: string) => onLoad(roleId, false), [onLoad]);

  return { loadings, onRequest, onFinish };
};

export const EditMenuRoleFormItem = createFormItem<EditMenuRoleFormValues>();

export const useEditMenuRoleForm = (
  shopId: string,
  menu: Menu,
  menuRoles: Role[],
  roles: Role[],
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(menuRoles, roles);

  const { loadings, onRequest, onFinish } = useLoadings(roles);

  const [insertShopMenuRolesRoleMutation] = useEditShopMenuOutputInsertShopMenuRolesRoleMutation();

  const insertMenuRolesRole = useCallback(
    async (roleId: string) => {
      try {
        onRequest(roleId);

        const role = roles.find((role) => role.id === roleId);

        if (!role) throw new Error("role not found");

        const shopMenuKitchenRole: ShopMenuKitchenRoleInsertInput[] = [
          { menuId: menu.id, _menuId: menu.menuId, roleId: role.id, _roleId: role.roleId, shopId },
        ];
        await insertShopMenuRolesRoleMutation({
          variables: {
            shopMenuKitchenRole,
          },
          refetchQueries: [
            {
              query: EditShopMenuOutputGetMenu,
              variables: { menuId: menu.menuId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [onRequest, roles, menu.id, menu.menuId, shopId, insertShopMenuRolesRoleMutation, onFinish],
  );

  const [deleteShopMenuRolesRoleMutation] = useEditShopMenuOutputDeleteShopMenuRolesRoleMutation();

  const deleteMenuRolesRole = useCallback(
    async (roleId: string) => {
      try {
        onRequest(roleId);

        await deleteShopMenuRolesRoleMutation({
          variables: { menuId: menu.menuId, roleId },
          refetchQueries: [
            {
              query: EditShopMenuOutputGetMenu,
              variables: { menuId: menu.menuId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [onRequest, deleteShopMenuRolesRoleMutation, menu.menuId, shopId, onFinish],
  );

  const change = useCallback(
    (changedValues: Store) => {
      const values = changedValues as EditMenuRoleFormValues;

      for (const [roleId, state] of Object.entries(values)) {
        if (state) {
          insertMenuRolesRole(roleId);
        } else {
          deleteMenuRolesRole(roleId);
        }
      }
    },
    [insertMenuRolesRole, deleteMenuRolesRole],
  );

  return { form, initialValues, change, loadings };
};
