import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from "react";
import { ConnectDragSource, ConnectDropTarget, DragSource, DropTarget } from "react-dnd";
import styled from "styled-components";
import { List } from "antd";
import { MenuOutlined } from "@ant-design/icons";

type Props = {
  id: string | number;
  priority: number;
  name: string;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  showIndex?: boolean;
};

type DropTargetProps = {
  connectDropTarget: ConnectDropTarget;
  hoveredFrom: "above" | "bottom" | null;
};

type DragSourceProps = {
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
};

type ItemInstance = {
  getNode(): HTMLDivElement | null;
};

const withDropTarget = DropTarget<Props, DropTargetProps>(
  "priority",
  {
    hover: ({ index }, monitor, component: ItemInstance) => {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }

      const dragIndex = monitor.getItem().index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      if (!monitor.isOver()) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect, monitor, { index }) => {
    const monitorItemIndex = monitor.getItem()?.index;
    return {
      connectDropTarget: connect.dropTarget(),
      hoveredFrom:
        monitorItemIndex && monitorItemIndex !== index && monitor.isOver()
          ? index > monitorItemIndex
            ? "above"
            : "bottom"
          : null,
    };
  },
);

const withDragSource = DragSource<Props, DragSourceProps>(
  "priority",
  {
    beginDrag: ({ id, index }) => ({ id, index }),
    endDrag: ({ index, onMove }, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }

      const dragIndex = index;
      const hoverIndex = monitor.getItem().index;

      onMove(dragIndex, hoverIndex);
    },
  },
  (connect, monitor) => ({
    isDragging: monitor.isDragging(),
    connectDragSource: connect.dragSource(),
  }),
);

const Container = styled.div<{ opacity: number; hoveredFrom: DropTargetProps["hoveredFrom"] }>`
  opacity: ${(props) => props.opacity};
  border-top: ${(props) => (props.hoveredFrom === "bottom" ? "2px" : "0")} dashed #40a9ff;
  border-bottom: ${(props) => (props.hoveredFrom === "above" ? "2px" : "0")} dashed #40a9ff;
`;

const ListItem = styled(List.Item)`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  cursor: move;
`;

const Name = styled.div`
  flex: 1;
  margin-left: 18px;
`;

const Index = styled.div`
  margin-left: 18px;
`;

const Item: ForwardRefRenderFunction<HTMLDivElement, Props & DropTargetProps & DragSourceProps> = (
  { name, isDragging, connectDragSource, hoveredFrom, connectDropTarget, index, showIndex },
  ref,
) => {
  const elementRef = useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);

  const opacity = isDragging ? 0 : 1;
  useImperativeHandle<any, ItemInstance>(ref, () => ({
    getNode: () => elementRef.current,
  }));

  return (
    <Container
      ref={elementRef}
      data-cy="priority-list-item"
      opacity={opacity}
      hoveredFrom={hoveredFrom}
    >
      <ListItem>
        <MenuOutlined />
        {showIndex && <Index>{index + 1}</Index>}
        <Name>{name}</Name>
      </ListItem>
    </Container>
  );
};

export const PriorityListItem = withDropTarget(withDragSource(forwardRef(Item)));
