import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireConfigsGetQuestionnaireConfigs = gql`
    query QuestionnaireConfigsGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;
export type QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type QuestionnaireConfigsGetQuestionnaireConfigsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig: Array<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id' | 'imageUrl'>
    & { questionnaire: (
      { __typename?: 'questionnaire' }
      & Pick<Types.Questionnaire, 'id' | 'name'>
    ), coupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'title'>
    )> }
  )> }
);


export const QuestionnaireConfigsGetQuestionnaireConfigsDocument = gql`
    query QuestionnaireConfigsGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;

/**
 * __useQuestionnaireConfigsGetQuestionnaireConfigsQuery__
 *
 * To run a query within a React component, call `useQuestionnaireConfigsGetQuestionnaireConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireConfigsGetQuestionnaireConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireConfigsGetQuestionnaireConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useQuestionnaireConfigsGetQuestionnaireConfigsQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireConfigsGetQuestionnaireConfigsQuery, QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireConfigsGetQuestionnaireConfigsQuery, QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables>(QuestionnaireConfigsGetQuestionnaireConfigsDocument, options);
      }
export function useQuestionnaireConfigsGetQuestionnaireConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireConfigsGetQuestionnaireConfigsQuery, QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireConfigsGetQuestionnaireConfigsQuery, QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables>(QuestionnaireConfigsGetQuestionnaireConfigsDocument, options);
        }
export type QuestionnaireConfigsGetQuestionnaireConfigsQueryHookResult = ReturnType<typeof useQuestionnaireConfigsGetQuestionnaireConfigsQuery>;
export type QuestionnaireConfigsGetQuestionnaireConfigsLazyQueryHookResult = ReturnType<typeof useQuestionnaireConfigsGetQuestionnaireConfigsLazyQuery>;
export type QuestionnaireConfigsGetQuestionnaireConfigsQueryResult = Apollo.QueryResult<QuestionnaireConfigsGetQuestionnaireConfigsQuery, QuestionnaireConfigsGetQuestionnaireConfigsQueryVariables>;