import React, { memo } from "react";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { Category } from "pages/PlanCategories/types";

type Props = {
  loading?: boolean;
  categories: Category[];
  onRemove: (categoryId: number) => void;
  canEditPlan: boolean;
  canEditCategory: boolean;
};

export const CategoryTable = memo<Props>(
  ({ loading, categories, onRemove, canEditPlan, canEditCategory }) => {
    const [pagination, setPagination] = usePagination();

    const columns = [
      { title: "カテゴリ名", dataIndex: "name" },
      canEditCategory
        ? ({
            title: "",
            align: "center",
            width: 60,
            render(_: string, { categoryId }: Category) {
              return (
                <IconLink to={`/category/${categoryId}/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const)
        : null,
      canEditPlan
        ? ({
            title: "",
            align: "center",
            width: 60,
            render(_: string, { categoryId }: Category) {
              return <DeleteIcon onClick={() => onRemove(categoryId)} />;
            },
          } as const)
        : null,
    ].filter(isNotNull);

    return (
      <Table
        rowKey="categoryId"
        columns={columns}
        dataSource={categories}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
