import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { QuestionnaireConfig } from "types/graphql";

export type AddQuestionnaireConfigFormValues = Pick<
  QuestionnaireConfig,
  "imageUrl" | "rewardCouponId"
>;

const initialValues = {};

export const AddQuestionnaireConfigFormItem = createFormItem<AddQuestionnaireConfigFormValues>();

export const useAddQuestionnaireConfigForm = ({
  onSubmit,
}: {
  onSubmit: ({
    questionnaireConfig,
  }: {
    questionnaireConfig: AddQuestionnaireConfigFormValues;
  }) => void;
}) => {
  const [form] = Form.useForm();

  const submit = useCallback(
    ({ imageUrl }: { imageUrl: string }) => {
      const formValues = form.getFieldsValue() as AddQuestionnaireConfigFormValues;

      onSubmit({ questionnaireConfig: { ...formValues, imageUrl } });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
