import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import {
  CardRank,
  cardRankToCardMap,
  englishCardTypeToJapaneseCardTypeMap,
} from "models/membershipCard";
import { membershipRanks, membershipRanksDisplayOrder } from "models/membershipRank";

import { FormHelp } from "components/Form/FormHelp";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { getCroppedUrl } from "libs/imgix";
import { MembershipCardAppearanceTypeEnum, MembershipRankEnum } from "types/graphql";

import { AppearanceType, MembershipRankConfig } from "../../types";

type Props = {
  loading: boolean;
  appearanceTypes: AppearanceType[];
  value?: MembershipCardAppearanceTypeEnum | undefined;
  membershipRankConfig: MembershipRankConfig;
  onChange?: (value: MembershipCardAppearanceTypeEnum | undefined) => void;
};

const ColumnHeaderTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
`;

const Image = styled.img`
  width: 128px;
  height: 68px;
  object-fit: cover;
  border-radius: 4px;
`;

const membershipRankAndCardRankMapper: Record<MembershipRankEnum, CardRank> = {
  [MembershipRankEnum.Regular]: "bronze",
  [MembershipRankEnum.Silver]: "silver",
  [MembershipRankEnum.Gold]: "gold",
  [MembershipRankEnum.Platinum]: "platinum",
} as const;

const getCardTypeUrl = (rank: CardRank, type: MembershipCardAppearanceTypeEnum) => {
  const url = getCroppedUrl({
    url: cardRankToCardMap[rank].categories[type].imageUrl,
    w: 128,
    h: 68,
  });
  return url;
};

const getHelpTexts = (
  membershipRankConfig: MembershipRankConfig,
): Record<MembershipRankEnum, string> | null => {
  if (membershipRankConfig.length === 0) return null;
  const requiredVisitCounts = Object.fromEntries(
    membershipRankConfig.map(({ membershipRank, requiredVisitCount }) => [
      membershipRank,
      requiredVisitCount,
    ]),
  ) as Record<MembershipRankEnum, number>;

  return {
    [MembershipRankEnum.Regular]: `来店回数${requiredVisitCounts.regular}〜${
      requiredVisitCounts.silver - 1
    }回のランク`,
    [MembershipRankEnum.Silver]: `来店回数${requiredVisitCounts.silver}〜${
      requiredVisitCounts.gold - 1
    }回のランク`,
    [MembershipRankEnum.Gold]: `来店回数${requiredVisitCounts.gold}〜${
      requiredVisitCounts.platinum - 1
    }回のランク`,
    [MembershipRankEnum.Platinum]: `来店回数${requiredVisitCounts.platinum}回以上のランク`,
  };
};

const getRankParams = (membershipRankConfig: MembershipRankConfig) => {
  const helpTexts = getHelpTexts(membershipRankConfig);
  return Object.values(MembershipRankEnum)
    .map((rank) => ({
      rank,
      rankName: membershipRanks[rank],
      helpText: helpTexts?.[rank] ?? "",
      cardRank: membershipRankAndCardRankMapper[rank],
      order: membershipRanksDisplayOrder[rank],
    }))
    .sort((left, right) => left.order - right.order);
};

export const AppearanceTypeTable = memo(
  ({ loading, appearanceTypes, membershipRankConfig, value, onChange }: Props) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const cardColumns = useMemo(
      () =>
        getRankParams(membershipRankConfig).map((param) => ({
          title: (
            <ColumnHeaderTitle>
              <FormHelp label={param.rankName} help={param.helpText} />
            </ColumnHeaderTitle>
          ),
          dataIndex: "value",
          render: (value: MembershipCardAppearanceTypeEnum) => {
            const url = getCardTypeUrl(param.cardRank, value);
            return url ? <Image src={url} /> : null;
          },
          width: 180,
        })),
      [membershipRankConfig],
    );
    const columns: ColumnsType<AppearanceType> = useMemo(
      () => [
        {
          title: <ColumnHeaderTitle>カードデザイン名</ColumnHeaderTitle>,
          width: 140,
          dataIndex: "value",
          render: (value: MembershipCardAppearanceTypeEnum) =>
            englishCardTypeToJapaneseCardTypeMap[value as MembershipCardAppearanceTypeEnum] ?? "",
        },
        ...cardColumns,
      ],
      [cardColumns],
    );

    return (
      <Table
        rowKey="value"
        loading={loading}
        rowSelection={{
          type: "radio",
          selectedRowKeys: value ? [value] : [],
          onChange: (_, rows) => {
            onChange?.(rows[0]?.value as MembershipCardAppearanceTypeEnum | undefined);
          },
          getCheckboxProps: () => ({
            disabled: !isFeatureEnabled("editMembershipCardAppearanceType"),
          }),
        }}
        columns={columns}
        dataSource={appearanceTypes}
        bordered
        pagination={false}
      />
    );
  },
);
