import React, { memo } from "react";
import styled from "styled-components";
import { Col, Input, Row } from "antd";
import dayjs from "dayjs";
import {
  formatExternalOnlineMenuAvailableTimeWithChangeDateTime,
  isValidExternalOnlineMenuAvailableTimeDuration,
} from "models/externalOnlineMenuAvailableTime";
import { normalizeHoursAndMinutes } from "util/time";

import { FormItemProps } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";

import { EditShopExternalOnlineMenuFormItem } from "../useEditShopExternalOnlineMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { name: number; changeDateTime: string };

const StyledEditShopExternalOnlineMenuFormItem = styled(
  EditShopExternalOnlineMenuFormItem.NonProperty,
)`
  .ant-picker {
    width: 100%;
  }
`;

const TextContainer = styled.div``;

const Tilda = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 8px;
`;

const ExternalOnlineMenuAvailableTimePreview = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  color: ${grey[6]};
`;

export const ExternalOnlineMenuAvailableTimeTermField = memo<Props>((props) => (
  <StyledEditShopExternalOnlineMenuFormItem label="アプリ表示時間" required {...props}>
    <Input.Group>
      <Row>
        <Col span={22}>
          <EditShopExternalOnlineMenuFormItem
            name={[props.name, "start"]}
            rules={[{ required: true, message: "開始時間を入力してください" }]}
            endSpacer={null}
          >
            <TimePicker formItemName={[props.name, "start"]} />
          </EditShopExternalOnlineMenuFormItem>
        </Col>
        <Col span={2}>
          <TextContainer>
            <Tilda>~</Tilda>
          </TextContainer>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <EditShopExternalOnlineMenuFormItem
            name={[props.name, "end"]}
            rules={[
              { required: true, message: "終了時間を入力してください" },
              ({ getFieldValue }) => ({
                validator: async () => {
                  const start: dayjs.Dayjs | undefined = getFieldValue([
                    "terms",
                    props.name,
                    "start",
                  ]);
                  const end: dayjs.Dayjs | undefined = getFieldValue(["terms", props.name, "end"]);

                  if (!start || !end) return;

                  const normalizedStart = normalizeHoursAndMinutes(start);
                  const normalizedEnd = normalizeHoursAndMinutes(end);

                  if (
                    !isValidExternalOnlineMenuAvailableTimeDuration({
                      start: normalizedStart,
                      end: normalizedEnd,
                      changeDateTime: props.changeDateTime,
                    })
                  ) {
                    throw new Error("開始時間より後の時間を入力してください");
                  }
                },
              }),
            ]}
            endSpacer={null}
          >
            <TimePicker formItemName={[props.name, "end"]} />
          </EditShopExternalOnlineMenuFormItem>
        </Col>
      </Row>
      <Row>
        <Spacer size={16} />
        <EditShopExternalOnlineMenuFormItem.NonProperty noStyle shouldUpdate endSpacer={null}>
          {({ getFieldValue }) => {
            const start: dayjs.Dayjs | undefined = getFieldValue(["terms", props.name, "start"]);
            const end: dayjs.Dayjs | undefined = getFieldValue(["terms", props.name, "end"]);

            if (start && end) {
              const normalizedStart = normalizeHoursAndMinutes(start);
              const normalizedEnd = normalizeHoursAndMinutes(end);
              const duration = formatExternalOnlineMenuAvailableTimeWithChangeDateTime({
                start: normalizedStart,
                end: normalizedEnd,
                changeDateTime: props.changeDateTime,
              });

              return (
                <TextContainer>
                  <ExternalOnlineMenuAvailableTimePreview>
                    {duration.start} ~ {duration.end}
                  </ExternalOnlineMenuAvailableTimePreview>
                </TextContainer>
              );
            }
          }}
        </EditShopExternalOnlineMenuFormItem.NonProperty>
      </Row>
    </Input.Group>
  </StyledEditShopExternalOnlineMenuFormItem>
));
