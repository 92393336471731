import React, { memo } from "react";
import styled from "styled-components";
import { magenta } from "@ant-design/colors";
import { UserOutlined } from "@ant-design/icons";

import { Dropdown } from "components/Dropdown";
import { UserMenu } from "components/Layout/DashboardLayout/AppBar/UserMenuDropdown/UserMenu";
import { grey } from "constants/colors";

const UserIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;

const UserIcon = styled(UserOutlined)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: ${grey[0]};
  font-size: 18px;
  background: ${magenta[6]};
  border-radius: 50%;
  transition: 0.3s;
`;

const UserName = styled.span`
  transition: 0.3s;
  margin-left: 4px;
  color: white;
`;

const UserDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover span {
    color: ${grey[5]};
  }
  &:hover ${UserIcon} {
    background: ${magenta[7]};
  }
`;

type Props = {
  userName: string | undefined;
  signOut: () => void;
};

export const UserMenuDropdown = memo<Props>(({ userName, signOut }) => (
  <Dropdown overlay={<UserMenu signOut={signOut} />} placement="bottomRight">
    <UserDropdownContainer>
      <UserIconContainer>
        <UserIcon />
      </UserIconContainer>
      <UserName>{userName}</UserName>
    </UserDropdownContainer>
  </Dropdown>
));
