import React, { memo, useCallback } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddPlanFormItem } from "../useAddPlanForm";

type Props = {
  hasPlanTimeLimit: boolean;
  setHasPlanTimeLimit: (hasPlanTimeLimit: boolean) => void;
} & Omit<FormItemProps, "children" | "name">;

export const PlanTimeLimitField = memo<Props>(
  ({ hasPlanTimeLimit, setHasPlanTimeLimit, ...props }) => {
    const handleClickToggleHasPlanTimeLimit = useCallback(
      () => setHasPlanTimeLimit(!hasPlanTimeLimit),
      [setHasPlanTimeLimit, hasPlanTimeLimit],
    );

    return (
      <AddPlanFormItem.NonProperty label="制限時間" {...props}>
        <Switch checked={hasPlanTimeLimit} onClick={handleClickToggleHasPlanTimeLimit} />
      </AddPlanFormItem.NonProperty>
    );
  },
);
