import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Radio, RadioChangeEvent, Space } from "antd";

import { MessageDeliveryCustomerSegmentGenderEnum } from "types/graphql";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GenderFilter = memo(() => {
  const [radioValue, setRadioValue] = useState<MessageDeliveryCustomerSegmentGenderEnum | "All">(
    "All",
  );

  const onChangeRadio = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;
    setRadioValue(value);
  }, []);

  return (
    <Content>
      <MessageDeliveryJobFormItem name="genderFilter">
        <Radio.Group value={radioValue} onChange={onChangeRadio}>
          <Radio value="All">すべて</Radio>
          <Radio value={MessageDeliveryCustomerSegmentGenderEnum.Male}>
            <Space direction="vertical">男性</Space>
          </Radio>
          <Radio value={MessageDeliveryCustomerSegmentGenderEnum.Female}>
            <Space direction="vertical">女性</Space>
          </Radio>
        </Radio.Group>
      </MessageDeliveryJobFormItem>
    </Content>
  );
});
