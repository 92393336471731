import { signInWithEmailAndPassword } from "firebase/auth";

import {
  claimKeyForHasuraCompanyUuid,
  claimKeyForHasuraCorporationId,
  claimNamespaceForHasura,
} from "constants/hasura";
import { auth } from "libs/firebase";
import { logger } from "libs/logger";
import {
  SignInSignInToDashboardAccountDocument,
  SignInSignInToDashboardAccountMutation,
  SignInSignInToDashboardAccountMutationVariables,
} from "store/mutations/signIn/queries";
import { MutationRootResolvers } from "store/types";

export const signIn: MutationRootResolvers["signIn"] = async (
  _,
  { email, password },
  { client },
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);

    await client.mutate<
      SignInSignInToDashboardAccountMutation,
      SignInSignInToDashboardAccountMutationVariables
    >({
      mutation: SignInSignInToDashboardAccountDocument,
      fetchPolicy: "no-cache",
    });

    const claims = (await auth.currentUser?.getIdTokenResult(true))?.claims;
    const hasuraClaims = claims?.[claimNamespaceForHasura];

    // NOTE: x-hasura-company-uuid があって x-hasura-corporation-id が無いアカウントは invalid なのでサインアウトして再度ログインしてもらう
    // FIXME: corporation の導入のために既にログインしているアカウントをサインアウトさせる必要がある。2023 年ごろに削除する。
    if (
      hasuraClaims?.[claimKeyForHasuraCompanyUuid] &&
      !hasuraClaims[claimKeyForHasuraCorporationId]
    ) {
      await auth.signOut();
      return false;
    }

    return true;
  } catch (error) {
    logger.warn(error instanceof Error ? error.message : "ログインに失敗しました", { error });

    throw error;
  }
};
