import { useCallback } from "react";

import {
  useGiftOptionGetGiftOptionMetaQuery,
  useGiftOptionInsertGiftOptionMetaMutation,
  useGiftOptionInsertGiftOptionMutation,
} from "hooks/useGiftOption/queries";

export const useGiftOption = () => {
  const { refetch: refetchGiftOptionMeta } = useGiftOptionGetGiftOptionMetaQuery({ skip: true });

  const [addGiftOptionMutation, { loading: loadingInsertGiftOption }] =
    useGiftOptionInsertGiftOptionMutation();

  const [addGiftOptionMetaMutation, { loading: loadingInsertGiftOptionMeta }] =
    useGiftOptionInsertGiftOptionMetaMutation();

  const loading = loadingInsertGiftOption || loadingInsertGiftOptionMeta;

  const findOrIssueGiftOption = useCallback(
    async ({ companyId, shopId }: { companyId: string; shopId: string }) => {
      const { data: giftOptionMetaData } = await refetchGiftOptionMeta({ shopId });
      const giftOptionMeta = giftOptionMetaData.giftOptionMeta[0] ?? null;

      if (giftOptionMeta) {
        return giftOptionMeta.option;
      }

      const { data: addGiftOptionData } = await addGiftOptionMutation({
        variables: {
          option: {
            companyId,
            name: "推しエール",
            receiptDisplayName: "推しエール",
          },
        },
      });
      const option = addGiftOptionData?.insert_option_one;

      if (!option) throw new Error("Failed to create option for gift");

      await addGiftOptionMetaMutation({
        variables: {
          giftOptionMeta: {
            optionId: option.id,
            _optionId: option.optionId,
            shopId,
          },
        },
      });

      return option;
    },
    [addGiftOptionMetaMutation, addGiftOptionMutation, refetchGiftOptionMeta],
  );

  return { loading, findOrIssueGiftOption };
};
