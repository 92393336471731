import React, { memo } from "react";
import styled from "styled-components";
import { Descriptions } from "antd";
import { sum, uniq } from "util/array";

import { grey } from "constants/colors";
import { Maybe } from "types/Maybe";

const StyledDescriptions = styled(Descriptions)`
  background-color: ${grey[0]};
`;

const contentStyle = {
  backgroundColor: grey[0],
};

type Props = {
  tableUsers: {
    numPeople: number;
    customerSegment?: Maybe<string>;
    inflowSourceTag?: Maybe<{ displayName?: Maybe<string> }>;
  }[];
  tables: {
    name: string;
  }[];
};

export const CustomerSummary = memo<Props>(({ tableUsers, tables }) => {
  const descriptionItems = [
    {
      key: "tables",
      label: "卓",
      children: tables.map((table) => table.name).join(","),
      contentStyle,
    },
    {
      key: "people",
      label: "人数",
      children: sum(tableUsers.map((tu) => tu.numPeople)),
      contentStyle,
    },
    {
      key: "media",
      label: "媒体",
      children: uniq(tableUsers.map((tu) => tu.inflowSourceTag?.displayName).filter(Boolean)).join(
        ",",
      ),
      contentStyle,
    },
    {
      key: "attributes",
      label: "属性",
      children: uniq(tableUsers.map((tu) => tu.customerSegment).filter(Boolean)).join(","),
      contentStyle,
    },
  ];

  return <StyledDescriptions bordered column={1} items={descriptionItems} />;
});
