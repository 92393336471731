import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors } from "constants/colors";

const StyledLink = styled(Link)`
  color: ${colors.Text.Default};
`;

type Props = {
  to: string;
  replace?: boolean;
  children: React.ReactNode;
};

export const IconLink = memo<Props>((props) => <StyledLink {...props} />);
