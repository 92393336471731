import styled from "styled-components";

export const HeadingH1 = styled.h1`
  font-size: 38px;
  line-height: 38px;
  font-weight: bold;
`;

export const HeadingH2 = styled.h1`
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
`;

export const HeadingH3 = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
`;

export const BaseLight = styled.span`
  font-size: 14px;
  line-height: 22px;
`;

export const BaseBold = styled.span`
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
`;

export const BaseCaption = styled.span`
  font-size: 12px;
  line-height: 20px;
`;

export const BaseSmall = styled.span`
  font-size: 10px;
  line-height: 17px;
`;

export const ChartTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
`;

export const ChartBody = styled.span`
  font-size: 12px;
  line-height: 14px;
`;

export const ChartNumber = styled.span`
  font-size: 10px;
  line-height: auto;
  font-weight: bold;
`;

export const ChartCaption = styled.span`
  font-size: 8px;
  line-height: 12px;
`;
