import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DashboardAccountRolesGetDashboardAccountRoles = gql`
    query DashboardAccountRolesGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
    dashboardAccountRolePermissions {
      id
      type
      feature
    }
    dashboardAccounts {
      id
    }
  }
}
    `;
export const DeleteDashboardAccountRole = gql`
    mutation DeleteDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  delete_dashboardAccountRolePermission(
    where: {dashboardAccountRoleId: {_eq: $dashboardAccountRoleId}}
  ) {
    affected_rows
    returning {
      id
    }
  }
  delete_dashboardAccountRole(where: {id: {_eq: $dashboardAccountRoleId}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DashboardAccountRolesGetDashboardAccountRolesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type DashboardAccountRolesGetDashboardAccountRolesQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountRole: Array<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id' | 'name'>
    & { dashboardAccountRolePermissions: Array<(
      { __typename?: 'dashboardAccountRolePermission' }
      & Pick<Types.DashboardAccountRolePermission, 'id' | 'type' | 'feature'>
    )>, dashboardAccounts: Array<(
      { __typename?: 'dashboardAccount' }
      & Pick<Types.DashboardAccount, 'id'>
    )> }
  )> }
);

export type DeleteDashboardAccountRoleMutationVariables = Types.Exact<{
  dashboardAccountRoleId: Types.Scalars['uuid'];
}>;


export type DeleteDashboardAccountRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dashboardAccountRolePermission?: Types.Maybe<(
    { __typename?: 'dashboardAccountRolePermission_mutation_response' }
    & Pick<Types.DashboardAccountRolePermissionMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dashboardAccountRolePermission' }
      & Pick<Types.DashboardAccountRolePermission, 'id'>
    )> }
  )>, delete_dashboardAccountRole?: Types.Maybe<(
    { __typename?: 'dashboardAccountRole_mutation_response' }
    & Pick<Types.DashboardAccountRoleMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dashboardAccountRole' }
      & Pick<Types.DashboardAccountRole, 'id'>
    )> }
  )> }
);


export const DashboardAccountRolesGetDashboardAccountRolesDocument = gql`
    query DashboardAccountRolesGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
    dashboardAccountRolePermissions {
      id
      type
      feature
    }
    dashboardAccounts {
      id
    }
  }
}
    `;

/**
 * __useDashboardAccountRolesGetDashboardAccountRolesQuery__
 *
 * To run a query within a React component, call `useDashboardAccountRolesGetDashboardAccountRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAccountRolesGetDashboardAccountRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAccountRolesGetDashboardAccountRolesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useDashboardAccountRolesGetDashboardAccountRolesQuery(baseOptions: Apollo.QueryHookOptions<DashboardAccountRolesGetDashboardAccountRolesQuery, DashboardAccountRolesGetDashboardAccountRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAccountRolesGetDashboardAccountRolesQuery, DashboardAccountRolesGetDashboardAccountRolesQueryVariables>(DashboardAccountRolesGetDashboardAccountRolesDocument, options);
      }
export function useDashboardAccountRolesGetDashboardAccountRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAccountRolesGetDashboardAccountRolesQuery, DashboardAccountRolesGetDashboardAccountRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAccountRolesGetDashboardAccountRolesQuery, DashboardAccountRolesGetDashboardAccountRolesQueryVariables>(DashboardAccountRolesGetDashboardAccountRolesDocument, options);
        }
export type DashboardAccountRolesGetDashboardAccountRolesQueryHookResult = ReturnType<typeof useDashboardAccountRolesGetDashboardAccountRolesQuery>;
export type DashboardAccountRolesGetDashboardAccountRolesLazyQueryHookResult = ReturnType<typeof useDashboardAccountRolesGetDashboardAccountRolesLazyQuery>;
export type DashboardAccountRolesGetDashboardAccountRolesQueryResult = Apollo.QueryResult<DashboardAccountRolesGetDashboardAccountRolesQuery, DashboardAccountRolesGetDashboardAccountRolesQueryVariables>;
export const DeleteDashboardAccountRoleDocument = gql`
    mutation DeleteDashboardAccountRole($dashboardAccountRoleId: uuid!) {
  delete_dashboardAccountRolePermission(
    where: {dashboardAccountRoleId: {_eq: $dashboardAccountRoleId}}
  ) {
    affected_rows
    returning {
      id
    }
  }
  delete_dashboardAccountRole(where: {id: {_eq: $dashboardAccountRoleId}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type DeleteDashboardAccountRoleMutationFn = Apollo.MutationFunction<DeleteDashboardAccountRoleMutation, DeleteDashboardAccountRoleMutationVariables>;

/**
 * __useDeleteDashboardAccountRoleMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardAccountRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardAccountRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardAccountRoleMutation, { data, loading, error }] = useDeleteDashboardAccountRoleMutation({
 *   variables: {
 *      dashboardAccountRoleId: // value for 'dashboardAccountRoleId'
 *   },
 * });
 */
export function useDeleteDashboardAccountRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardAccountRoleMutation, DeleteDashboardAccountRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardAccountRoleMutation, DeleteDashboardAccountRoleMutationVariables>(DeleteDashboardAccountRoleDocument, options);
      }
export type DeleteDashboardAccountRoleMutationHookResult = ReturnType<typeof useDeleteDashboardAccountRoleMutation>;
export type DeleteDashboardAccountRoleMutationResult = Apollo.MutationResult<DeleteDashboardAccountRoleMutation>;
export type DeleteDashboardAccountRoleMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardAccountRoleMutation, DeleteDashboardAccountRoleMutationVariables>;