import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditDashboardAccountGetCompanies = gql`
    query EditDashboardAccountGetCompanies($corporationId: uuid!) {
  company(where: {corporationId: {_eq: $corporationId}}, order_by: {name: asc}) {
    id
    corporationId
    companyId
    name
  }
}
    `;
export const EditDashboardAccountGetShops = gql`
    query EditDashboardAccountGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const EditDashboardAccountGetDashboardAccountRoles = gql`
    query EditDashboardAccountGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
  }
}
    `;
export const EditDashboardAccountGetDashboardAccount = gql`
    query EditDashboardAccountGetDashboardAccount($dashboardAccountId: uuid!) {
  dashboardAccount(
    where: {id: {_eq: $dashboardAccountId}}
    order_by: {createdAt: asc}
  ) {
    corporation {
      id
      name
    }
    email
    id
    userName
    role
    dashboardAccountRole {
      id
      name
    }
    dashboardAccountAccessibleCompanies {
      company {
        id
        name
      }
    }
    dashboardAccountAccessibleShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;
export const EditDashboardAccountUpdateAccount = gql`
    mutation EditDashboardAccountUpdateAccount($input: UpdateDashboardAccountInput!) {
  dashboardAccountUpdateDashboardAccount(input: $input)
}
    `;
export type EditDashboardAccountGetCompaniesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type EditDashboardAccountGetCompaniesQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'corporationId' | 'companyId' | 'name'>
  )> }
);

export type EditDashboardAccountGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type EditDashboardAccountGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditDashboardAccountGetDashboardAccountRolesQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type EditDashboardAccountGetDashboardAccountRolesQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccountRole: Array<(
    { __typename?: 'dashboardAccountRole' }
    & Pick<Types.DashboardAccountRole, 'id' | 'name'>
  )> }
);

export type EditDashboardAccountGetDashboardAccountQueryVariables = Types.Exact<{
  dashboardAccountId: Types.Scalars['uuid'];
}>;


export type EditDashboardAccountGetDashboardAccountQuery = (
  { __typename?: 'query_root' }
  & { dashboardAccount: Array<(
    { __typename?: 'dashboardAccount' }
    & Pick<Types.DashboardAccount, 'email' | 'id' | 'userName' | 'role'>
    & { corporation?: Types.Maybe<(
      { __typename?: 'corporation' }
      & Pick<Types.Corporation, 'id' | 'name'>
    )>, dashboardAccountRole: (
      { __typename?: 'dashboardAccountRole' }
      & Pick<Types.DashboardAccountRole, 'id' | 'name'>
    ), dashboardAccountAccessibleCompanies: Array<(
      { __typename?: 'dashboardAccountAccessibleCompany' }
      & { company?: Types.Maybe<(
        { __typename?: 'company' }
        & Pick<Types.Company, 'id' | 'name'>
      )> }
    )>, dashboardAccountAccessibleShops: Array<(
      { __typename?: 'dashboardAccountAccessibleShop' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )> }
  )> }
);

export type EditDashboardAccountUpdateAccountMutationVariables = Types.Exact<{
  input: Types.UpdateDashboardAccountInput;
}>;


export type EditDashboardAccountUpdateAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountUpdateDashboardAccount'>
);


export const EditDashboardAccountGetCompaniesDocument = gql`
    query EditDashboardAccountGetCompanies($corporationId: uuid!) {
  company(where: {corporationId: {_eq: $corporationId}}, order_by: {name: asc}) {
    id
    corporationId
    companyId
    name
  }
}
    `;

/**
 * __useEditDashboardAccountGetCompaniesQuery__
 *
 * To run a query within a React component, call `useEditDashboardAccountGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDashboardAccountGetCompaniesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useEditDashboardAccountGetCompaniesQuery(baseOptions: Apollo.QueryHookOptions<EditDashboardAccountGetCompaniesQuery, EditDashboardAccountGetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDashboardAccountGetCompaniesQuery, EditDashboardAccountGetCompaniesQueryVariables>(EditDashboardAccountGetCompaniesDocument, options);
      }
export function useEditDashboardAccountGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDashboardAccountGetCompaniesQuery, EditDashboardAccountGetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDashboardAccountGetCompaniesQuery, EditDashboardAccountGetCompaniesQueryVariables>(EditDashboardAccountGetCompaniesDocument, options);
        }
export type EditDashboardAccountGetCompaniesQueryHookResult = ReturnType<typeof useEditDashboardAccountGetCompaniesQuery>;
export type EditDashboardAccountGetCompaniesLazyQueryHookResult = ReturnType<typeof useEditDashboardAccountGetCompaniesLazyQuery>;
export type EditDashboardAccountGetCompaniesQueryResult = Apollo.QueryResult<EditDashboardAccountGetCompaniesQuery, EditDashboardAccountGetCompaniesQueryVariables>;
export const EditDashboardAccountGetShopsDocument = gql`
    query EditDashboardAccountGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditDashboardAccountGetShopsQuery__
 *
 * To run a query within a React component, call `useEditDashboardAccountGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDashboardAccountGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useEditDashboardAccountGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditDashboardAccountGetShopsQuery, EditDashboardAccountGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDashboardAccountGetShopsQuery, EditDashboardAccountGetShopsQueryVariables>(EditDashboardAccountGetShopsDocument, options);
      }
export function useEditDashboardAccountGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDashboardAccountGetShopsQuery, EditDashboardAccountGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDashboardAccountGetShopsQuery, EditDashboardAccountGetShopsQueryVariables>(EditDashboardAccountGetShopsDocument, options);
        }
export type EditDashboardAccountGetShopsQueryHookResult = ReturnType<typeof useEditDashboardAccountGetShopsQuery>;
export type EditDashboardAccountGetShopsLazyQueryHookResult = ReturnType<typeof useEditDashboardAccountGetShopsLazyQuery>;
export type EditDashboardAccountGetShopsQueryResult = Apollo.QueryResult<EditDashboardAccountGetShopsQuery, EditDashboardAccountGetShopsQueryVariables>;
export const EditDashboardAccountGetDashboardAccountRolesDocument = gql`
    query EditDashboardAccountGetDashboardAccountRoles($corporationId: uuid!) {
  dashboardAccountRole(where: {corporationId: {_eq: $corporationId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditDashboardAccountGetDashboardAccountRolesQuery__
 *
 * To run a query within a React component, call `useEditDashboardAccountGetDashboardAccountRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountGetDashboardAccountRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDashboardAccountGetDashboardAccountRolesQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useEditDashboardAccountGetDashboardAccountRolesQuery(baseOptions: Apollo.QueryHookOptions<EditDashboardAccountGetDashboardAccountRolesQuery, EditDashboardAccountGetDashboardAccountRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDashboardAccountGetDashboardAccountRolesQuery, EditDashboardAccountGetDashboardAccountRolesQueryVariables>(EditDashboardAccountGetDashboardAccountRolesDocument, options);
      }
export function useEditDashboardAccountGetDashboardAccountRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDashboardAccountGetDashboardAccountRolesQuery, EditDashboardAccountGetDashboardAccountRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDashboardAccountGetDashboardAccountRolesQuery, EditDashboardAccountGetDashboardAccountRolesQueryVariables>(EditDashboardAccountGetDashboardAccountRolesDocument, options);
        }
export type EditDashboardAccountGetDashboardAccountRolesQueryHookResult = ReturnType<typeof useEditDashboardAccountGetDashboardAccountRolesQuery>;
export type EditDashboardAccountGetDashboardAccountRolesLazyQueryHookResult = ReturnType<typeof useEditDashboardAccountGetDashboardAccountRolesLazyQuery>;
export type EditDashboardAccountGetDashboardAccountRolesQueryResult = Apollo.QueryResult<EditDashboardAccountGetDashboardAccountRolesQuery, EditDashboardAccountGetDashboardAccountRolesQueryVariables>;
export const EditDashboardAccountGetDashboardAccountDocument = gql`
    query EditDashboardAccountGetDashboardAccount($dashboardAccountId: uuid!) {
  dashboardAccount(
    where: {id: {_eq: $dashboardAccountId}}
    order_by: {createdAt: asc}
  ) {
    corporation {
      id
      name
    }
    email
    id
    userName
    role
    dashboardAccountRole {
      id
      name
    }
    dashboardAccountAccessibleCompanies {
      company {
        id
        name
      }
    }
    dashboardAccountAccessibleShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __useEditDashboardAccountGetDashboardAccountQuery__
 *
 * To run a query within a React component, call `useEditDashboardAccountGetDashboardAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountGetDashboardAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDashboardAccountGetDashboardAccountQuery({
 *   variables: {
 *      dashboardAccountId: // value for 'dashboardAccountId'
 *   },
 * });
 */
export function useEditDashboardAccountGetDashboardAccountQuery(baseOptions: Apollo.QueryHookOptions<EditDashboardAccountGetDashboardAccountQuery, EditDashboardAccountGetDashboardAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDashboardAccountGetDashboardAccountQuery, EditDashboardAccountGetDashboardAccountQueryVariables>(EditDashboardAccountGetDashboardAccountDocument, options);
      }
export function useEditDashboardAccountGetDashboardAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDashboardAccountGetDashboardAccountQuery, EditDashboardAccountGetDashboardAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDashboardAccountGetDashboardAccountQuery, EditDashboardAccountGetDashboardAccountQueryVariables>(EditDashboardAccountGetDashboardAccountDocument, options);
        }
export type EditDashboardAccountGetDashboardAccountQueryHookResult = ReturnType<typeof useEditDashboardAccountGetDashboardAccountQuery>;
export type EditDashboardAccountGetDashboardAccountLazyQueryHookResult = ReturnType<typeof useEditDashboardAccountGetDashboardAccountLazyQuery>;
export type EditDashboardAccountGetDashboardAccountQueryResult = Apollo.QueryResult<EditDashboardAccountGetDashboardAccountQuery, EditDashboardAccountGetDashboardAccountQueryVariables>;
export const EditDashboardAccountUpdateAccountDocument = gql`
    mutation EditDashboardAccountUpdateAccount($input: UpdateDashboardAccountInput!) {
  dashboardAccountUpdateDashboardAccount(input: $input)
}
    `;
export type EditDashboardAccountUpdateAccountMutationFn = Apollo.MutationFunction<EditDashboardAccountUpdateAccountMutation, EditDashboardAccountUpdateAccountMutationVariables>;

/**
 * __useEditDashboardAccountUpdateAccountMutation__
 *
 * To run a mutation, you first call `useEditDashboardAccountUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDashboardAccountUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDashboardAccountUpdateAccountMutation, { data, loading, error }] = useEditDashboardAccountUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDashboardAccountUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditDashboardAccountUpdateAccountMutation, EditDashboardAccountUpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDashboardAccountUpdateAccountMutation, EditDashboardAccountUpdateAccountMutationVariables>(EditDashboardAccountUpdateAccountDocument, options);
      }
export type EditDashboardAccountUpdateAccountMutationHookResult = ReturnType<typeof useEditDashboardAccountUpdateAccountMutation>;
export type EditDashboardAccountUpdateAccountMutationResult = Apollo.MutationResult<EditDashboardAccountUpdateAccountMutation>;
export type EditDashboardAccountUpdateAccountMutationOptions = Apollo.BaseMutationOptions<EditDashboardAccountUpdateAccountMutation, EditDashboardAccountUpdateAccountMutationVariables>;