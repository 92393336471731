import React, { memo } from "react";
import styled from "styled-components";
import { Radio } from "antd";

import { withFormDependencies } from "components/antd/Form";
import { colors, grey } from "constants/colors";
import { CouponTypeEnum } from "types/graphql";

import ticketCouponImg from "../../../../assets/ticket_coupon.png";
import { AddCouponFormItem } from "../useAddCouponForm";

const couponTypeToTableTitleMap: Record<CouponTypeEnum, string> = {
  [CouponTypeEnum.MenuDiscount]: "通常メニューの値引・割引",
  [CouponTypeEnum.LimitedMenu]: "限定メニューの開放",
  [CouponTypeEnum.CheckoutDiscount]: "会計の値引・割引",
  [CouponTypeEnum.Ticket]: "画面提示",
};

const StyledCouponFormItem = styled(AddCouponFormItem)`
  overflow-x: scroll;
`;

const StyledTable = styled.table`
  thead {
    background-color: ${colors.BackGround.Tertiary};
    th {
      width: 148px;
      border: 1px solid ${grey[3]};
      padding: 16px;
      text-align: left;
      color: ${colors.Text.Default};
      font-weight: 500;
      font-size: 14px;
    }
  }
  tbody {
    td {
      border: 1px solid ${grey[3]};
      padding: 12px 16px;
      text-align: center;
      &.checked {
        background-color: ${colors.BackGround.PrimarySecondary};
      }
    }
  }
`;

const Description = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
  justify-content: space-between;
  text-align: left;
`;

const Text = styled.div`
  font-size: 14px;
  flex: 1;
`;

const Chip = styled.div`
  display: inline-flex;
  flex-basis: max-content;
  border: 1px solid #d6d6d6;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 2px;
`;

const StyledRadio = styled(Radio)`
  padding: 16px;
  margin-inline-end: 0;
`;

const Image = styled.img`
  width: 140px;
  height: 140px;
  object-fit: cover;
`;

export const CouponTypeField = memo(() => (
  <AddCouponFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ couponType }) => [couponType])}
    noStyle
  >
    {({ getFieldValue }) => {
      const couponTypeFieldValue: CouponTypeEnum = getFieldValue("couponType");
      const isSelected = (couponType: CouponTypeEnum) => couponTypeFieldValue === couponType;
      return (
        <StyledCouponFormItem
          name="couponType"
          rules={[{ required: true, message: "クーポンタイプを選択してください" }]}
        >
          <Radio.Group>
            <StyledTable>
              <thead>
                <tr>
                  {/* NOTE: 通常メニュー、限定メニュー、会計の割引クーポン実装時に解放する */}
                  {/* <th>{couponTypeToTableTitleMap[CouponTypeEnum.MenuDiscount]}</th>
                  <th>{couponTypeToTableTitleMap[CouponTypeEnum.LimitedMenu]}</th>
                  <th>{couponTypeToTableTitleMap[CouponTypeEnum.CheckoutDiscount]}</th> */}
                  <th>{couponTypeToTableTitleMap[CouponTypeEnum.Ticket]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td className={isSelected(CouponTypeEnum.MenuDiscount) ? "checked" : undefined}>
                    <Image src={menuDiscountCouponImg} />
                  </td>
                  <td className={isSelected(CouponTypeEnum.LimitedMenu) ? "checked" : undefined}>
                    <Image src={limitedMenuCouponImg} />
                  </td>
                  <td
                    className={isSelected(CouponTypeEnum.CheckoutDiscount) ? "checked" : undefined}
                  >
                    <Image src={checkoutDiscountCouponImg} />
                  </td> */}
                  <td className={isSelected(CouponTypeEnum.Ticket) ? "checked" : undefined}>
                    <Image src={ticketCouponImg} />
                  </td>
                </tr>
                <tr>
                  {/* <td className={isSelected(CouponTypeEnum.MenuDiscount) ? "checked" : undefined}>
                    <Description>
                      <Text>
                        メニューマスターに登録されたメニューに対して、値引や割引するクーポンです。
                      </Text>
                      <div>
                        <Chip>自動注文可</Chip>
                        <Spacer size={8} />
                        <Chip>キッチン伝票印刷可</Chip>
                      </div>
                    </Description>
                  </td>
                  <td className={isSelected(CouponTypeEnum.LimitedMenu) ? "checked" : undefined}>
                    <Description>
                      <Text>
                        メニューマスターには登録されていない限定メニューを提供するクーポンです。
                      </Text>
                      <div>
                        <Chip>自動注文可</Chip>
                        <Spacer size={8} />
                        <Chip>キッチン伝票印刷可</Chip>
                      </div>
                    </Description>
                  </td>
                  <td
                    className={isSelected(CouponTypeEnum.CheckoutDiscount) ? "checked" : undefined}
                  >
                    <Description>
                      <Text>会計から値引・割引をするクーポンです。</Text>
                      <div>
                        <Chip>自動注文可</Chip>
                        <Spacer size={8} />
                        <Chip>キッチン伝票印刷可</Chip>
                      </div>
                    </Description>
                  </td> */}
                  <td className={isSelected(CouponTypeEnum.Ticket) ? "checked" : undefined}>
                    <Description>
                      <Text>
                        メニューだけではなくノベルティプレゼントや特別なお席ご用意など、多様なサービスを登録できるクーポンです。
                      </Text>
                      <div>
                        <Chip>スタッフがクーポン画面確認</Chip>
                      </div>
                    </Description>
                  </td>
                </tr>
                <tr>
                  {/* <td className={isSelected(CouponTypeEnum.MenuDiscount) ? "checked" : undefined}>
                    <StyledRadio value={CouponTypeEnum.MenuDiscount} />
                  </td>
                  <td className={isSelected(CouponTypeEnum.LimitedMenu) ? "checked" : undefined}>
                    <StyledRadio value={CouponTypeEnum.LimitedMenu} />
                  </td>
                  <td
                    className={isSelected(CouponTypeEnum.CheckoutDiscount) ? "checked" : undefined}
                  >
                    <StyledRadio value={CouponTypeEnum.CheckoutDiscount} />
                  </td> */}
                  <td className={isSelected(CouponTypeEnum.Ticket) ? "checked" : undefined}>
                    <StyledRadio value={CouponTypeEnum.Ticket} />
                  </td>
                </tr>
              </tbody>
            </StyledTable>
          </Radio.Group>
        </StyledCouponFormItem>
      );
    }}
  </AddCouponFormItem.NonProperty>
));
