import { useQuestionnaireAnalyticsFeatureFlagQuery } from "./queries";

export const useQuestionnaireAnalyticsFeatureFlag = ({
  corporationId,
}: {
  corporationId?: string;
}) => {
  const { data, loading } = useQuestionnaireAnalyticsFeatureFlagQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );

  return {
    loading,
    isQuestionnaireAnalyticsFeatureAvailable: Boolean(data?.questionnaireConfig.length),
  };
};
