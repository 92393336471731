import React, { memo } from "react";
import { Select } from "antd";
import { taxRates } from "models/taxRate";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

const { Option } = Select;

type Props = {
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const CostTaxRateField = memo<Props>((props) => (
  <AddMenuFormItem label="原価税率" name="costTaxRate" {...props}>
    <Select<string>>
      {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
        <Option key={taxRateKey} value={taxRate}>
          {taxRate * 100}%
        </Option>
      ))}
    </Select>
  </AddMenuFormItem>
));
