import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { OnSitePaymentDetailTypeCategory } from "pages/EditOnSitePaymentDetailType/types";
import { OnSitePaymentDetailTypeCategoryEnum } from "types/graphql";

import { AllowChangeField } from "./AllowChangeField";
import { CategoryField } from "./CategoryField";
import { LabelField } from "./LabelField";
import { useAddOnSitePaymentDetailTypeForm } from "./useAddOnSitePaymentDetailTypeForm";

type Props = {
  onSubmit: (args: {
    label: string;
    type: string;
    allowChange: boolean;
    onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum;
  }) => void;
  loading: boolean;
  onSitePaymentDetailTypeCategories: OnSitePaymentDetailTypeCategory[];
};

export const AddOnSitePaymentDetailTypeForm = memo<Props>(
  ({ onSubmit, loading, onSitePaymentDetailTypeCategories }) => {
    const { form, initialValues, submit } = useAddOnSitePaymentDetailTypeForm(onSubmit);
    const { isFeatureEnabled } = useIsFeatureEnabled();

    return (
      <>
        <FormContent>
          <Form
            name="onSitePaymentDiscountType"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <LabelField />
            <CategoryField onSitePaymentDetailTypeCategories={onSitePaymentDetailTypeCategories} />
            {isFeatureEnabled("showAllowChangeField") && <AllowChangeField />}
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
