import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditServiceAdminGetServiceAdmin = gql`
    query EditServiceAdminGetServiceAdmin($serviceAdminId: uuid!) {
  serviceAdmin_by_pk(id: $serviceAdminId) {
    email
    serviceAdminId: id
  }
}
    `;
export type EditServiceAdminGetServiceAdminQueryVariables = Types.Exact<{
  serviceAdminId: Types.Scalars['uuid'];
}>;


export type EditServiceAdminGetServiceAdminQuery = (
  { __typename?: 'query_root' }
  & { serviceAdmin_by_pk?: Types.Maybe<(
    { __typename?: 'serviceAdmin' }
    & Pick<Types.ServiceAdmin, 'email'>
    & { serviceAdminId: Types.ServiceAdmin['id'] }
  )> }
);


export const EditServiceAdminGetServiceAdminDocument = gql`
    query EditServiceAdminGetServiceAdmin($serviceAdminId: uuid!) {
  serviceAdmin_by_pk(id: $serviceAdminId) {
    email
    serviceAdminId: id
  }
}
    `;

/**
 * __useEditServiceAdminGetServiceAdminQuery__
 *
 * To run a query within a React component, call `useEditServiceAdminGetServiceAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditServiceAdminGetServiceAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditServiceAdminGetServiceAdminQuery({
 *   variables: {
 *      serviceAdminId: // value for 'serviceAdminId'
 *   },
 * });
 */
export function useEditServiceAdminGetServiceAdminQuery(baseOptions: Apollo.QueryHookOptions<EditServiceAdminGetServiceAdminQuery, EditServiceAdminGetServiceAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditServiceAdminGetServiceAdminQuery, EditServiceAdminGetServiceAdminQueryVariables>(EditServiceAdminGetServiceAdminDocument, options);
      }
export function useEditServiceAdminGetServiceAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditServiceAdminGetServiceAdminQuery, EditServiceAdminGetServiceAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditServiceAdminGetServiceAdminQuery, EditServiceAdminGetServiceAdminQueryVariables>(EditServiceAdminGetServiceAdminDocument, options);
        }
export type EditServiceAdminGetServiceAdminQueryHookResult = ReturnType<typeof useEditServiceAdminGetServiceAdminQuery>;
export type EditServiceAdminGetServiceAdminLazyQueryHookResult = ReturnType<typeof useEditServiceAdminGetServiceAdminLazyQuery>;
export type EditServiceAdminGetServiceAdminQueryResult = Apollo.QueryResult<EditServiceAdminGetServiceAdminQuery, EditServiceAdminGetServiceAdminQueryVariables>;