import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { DatePicker, Flex, Select } from "antd";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";

import { Company } from "../type";

const MAX_SELECTABLE_DAYS = 366;

const StyledSelect = styled(Select<string>)`
  width: 256px;
`;

export type FilterConditions = {
  companyId?: string;
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null] | undefined;
} & Omit<FilterConditions, "range">;

type Props = {
  companies: Company[];
  companyIdDefaultValue?: string;
  filterConditions: FilterConditions;
  onChangeFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  loading: boolean;
};

export const Filters = memo<Props>(
  ({ companies, companyIdDefaultValue, filterConditions, onChangeFilterCondition, loading }) => {
    const handleChangeCompany = useCallback(
      (value: string | null) => {
        if (value) onChangeFilterCondition({ companyId: value });
      },
      [onChangeFilterCondition],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const startAt = values?.[0]?.startOf("day") ?? null;
        const endAt = values?.[1]?.endOf("day") ?? null;

        onChangeFilterCondition({ range: [startAt, endAt] });
      },
      [onChangeFilterCondition],
    );

    // NOTE: API のパフォーマンス観点から選択可能な期間を最大 1 ヶ月とする
    const disabledDate = useCallback(
      (current: dayjs.Dayjs | null) => {
        const startAt = filterConditions.range?.[0] ?? null;
        const endAt = filterConditions.range?.[1] ?? null;

        if (startAt === null && endAt === null) return false;

        const isTooLate = Boolean(
          current && startAt && current.diff(startAt, "day") > MAX_SELECTABLE_DAYS,
        );
        const isTooEarly = Boolean(
          current && endAt && endAt.diff(current, "day") > MAX_SELECTABLE_DAYS,
        );

        return isTooLate || isTooEarly;
      },
      [filterConditions.range],
    );

    const options = useMemo(
      () =>
        companies.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      [companies],
    );

    const { rangePresets } = useRangePresets();

    return (
      <Flex gap={16}>
        <StyledSelect
          showSearch
          optionFilterProp="label"
          optionLabelProp="label"
          value={filterConditions.companyId ?? companyIdDefaultValue}
          onChange={handleChangeCompany}
          options={options}
          loading={loading}
        />
        <DatePicker.RangePicker
          value={filterConditions.range}
          ranges={rangePresets}
          onCalendarChange={handleChangeRange}
          clearIcon={false}
          disabledDate={disabledDate}
          renderExtraFooter={() => "※ 選択可能な期間は最大1年間です"}
        />
      </Flex>
    );
  },
);
