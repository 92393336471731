import React, { useCallback } from "react";
import { Alert, DatePicker } from "antd";
import dayjs from "dayjs";
import { groupBy } from "util/array";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useLocationState } from "hooks/useLocationState";

import {
  DailyCashRegisterBalancingTable,
  ShopDailyCashRegisterBalancing,
} from "./DailyCashRegisterBalancingTable";
import {
  useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery,
  useDailyCashRegisterBalancingGetShopsQuery,
} from "./queries";

export const DailyCashRegisterBalancing = () => {
  const [{ targetDateString }, setTargetDateString] = useLocationState<{
    targetDateString: string | null;
  }>("targetDateString", { targetDateString: dayjs().subtract(1, "days").format("YYYY-MM-DD") });
  const targetDate = targetDateString ? dayjs(targetDateString) : undefined;

  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const handleChangeDate = useCallback(
    (_: dayjs.Dayjs | null, targetDateString: string) => {
      setTargetDateString({ targetDateString });
    },
    [setTargetDateString],
  );

  const {
    data: getShopsData,
    loading: loadingGetShops,
    error: errorGetShops,
  } = useDailyCashRegisterBalancingGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const {
    data: getDailyCashRegisterBalancingData,
    loading: loadingGetClosingProcessingInformation,
    error: errorGetClosingProcessingInformation,
  } = useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery(
    _companyId && targetDateString
      ? { variables: { input: { _companyId, targetDateString } } }
      : { skip: true },
  );
  const shopDailyCashRegisterBalancings =
    getDailyCashRegisterBalancingData?.dailyCashRegisterBalancing.shopDailyCashRegisterBalancings ??
    [];

  const shopDailyCashRegisterBalancingsMap = groupBy(
    shopDailyCashRegisterBalancings,
    (b) => b.shopId,
  );

  const dataSource: ShopDailyCashRegisterBalancing[] = shops.flatMap((shop) => {
    const shopDailyCashRegisterBalancing = shopDailyCashRegisterBalancingsMap[shop.shopId];

    if (shopDailyCashRegisterBalancing) {
      return shopDailyCashRegisterBalancing
        .slice()
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map<ShopDailyCashRegisterBalancing>(
          ({ closeCashRegisterId, createdAt, totalSales, depositAmount, cashDiscrepancies }) => ({
            shopId: shop.shopId,
            shopName: shop.name,
            closeCashRegisterId,
            createdAt,
            totalSales: totalSales ?? null,
            depositAmount: depositAmount ?? null,
            cashDiscrepancies: cashDiscrepancies ?? null,
          }),
        );
    } else {
      return [
        {
          shopId: shop.shopId,
          shopName: shop.name,
          closeCashRegisterId: null,
          createdAt: null,
          totalSales: null,
          depositAmount: null,
          cashDiscrepancies: null,
        },
      ];
    }
  });

  const loading = loadingGetShops || loadingGetClosingProcessingInformation;

  return (
    <DashboardLayout title="日次処理一覧">
      <PageHeader
        title="日次処理一覧"
        footer={
          <DatePicker defaultValue={targetDate} value={targetDate} onChange={handleChangeDate} />
        }
      />
      {(errorGetShops || errorGetClosingProcessingInformation) && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <DailyCashRegisterBalancingTable
        loading={loading}
        dataSource={dataSource}
        date={targetDateString}
      />
    </DashboardLayout>
  );
};
