import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopSelectorGetShops = gql`
    query ShopSelectorGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const ShopSelectorGetShopsPerCorporation = gql`
    query ShopSelectorGetShopsPerCorporation($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export type ShopSelectorGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ShopSelectorGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type ShopSelectorGetShopsPerCorporationQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type ShopSelectorGetShopsPerCorporationQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const ShopSelectorGetShopsDocument = gql`
    query ShopSelectorGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useShopSelectorGetShopsQuery__
 *
 * To run a query within a React component, call `useShopSelectorGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopSelectorGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopSelectorGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useShopSelectorGetShopsQuery(baseOptions: Apollo.QueryHookOptions<ShopSelectorGetShopsQuery, ShopSelectorGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopSelectorGetShopsQuery, ShopSelectorGetShopsQueryVariables>(ShopSelectorGetShopsDocument, options);
      }
export function useShopSelectorGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopSelectorGetShopsQuery, ShopSelectorGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopSelectorGetShopsQuery, ShopSelectorGetShopsQueryVariables>(ShopSelectorGetShopsDocument, options);
        }
export type ShopSelectorGetShopsQueryHookResult = ReturnType<typeof useShopSelectorGetShopsQuery>;
export type ShopSelectorGetShopsLazyQueryHookResult = ReturnType<typeof useShopSelectorGetShopsLazyQuery>;
export type ShopSelectorGetShopsQueryResult = Apollo.QueryResult<ShopSelectorGetShopsQuery, ShopSelectorGetShopsQueryVariables>;
export const ShopSelectorGetShopsPerCorporationDocument = gql`
    query ShopSelectorGetShopsPerCorporation($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useShopSelectorGetShopsPerCorporationQuery__
 *
 * To run a query within a React component, call `useShopSelectorGetShopsPerCorporationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopSelectorGetShopsPerCorporationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopSelectorGetShopsPerCorporationQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useShopSelectorGetShopsPerCorporationQuery(baseOptions: Apollo.QueryHookOptions<ShopSelectorGetShopsPerCorporationQuery, ShopSelectorGetShopsPerCorporationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopSelectorGetShopsPerCorporationQuery, ShopSelectorGetShopsPerCorporationQueryVariables>(ShopSelectorGetShopsPerCorporationDocument, options);
      }
export function useShopSelectorGetShopsPerCorporationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopSelectorGetShopsPerCorporationQuery, ShopSelectorGetShopsPerCorporationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopSelectorGetShopsPerCorporationQuery, ShopSelectorGetShopsPerCorporationQueryVariables>(ShopSelectorGetShopsPerCorporationDocument, options);
        }
export type ShopSelectorGetShopsPerCorporationQueryHookResult = ReturnType<typeof useShopSelectorGetShopsPerCorporationQuery>;
export type ShopSelectorGetShopsPerCorporationLazyQueryHookResult = ReturnType<typeof useShopSelectorGetShopsPerCorporationLazyQuery>;
export type ShopSelectorGetShopsPerCorporationQueryResult = Apollo.QueryResult<ShopSelectorGetShopsPerCorporationQuery, ShopSelectorGetShopsPerCorporationQueryVariables>;