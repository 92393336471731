import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DishUpSlipGroupTablePrintingGetTables = gql`
    query DishUpSlipGroupTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
    tableId
    dishUpSlipGroupTables {
      dishUpSlipGroupId
      tableId: _tableId
    }
  }
  dishUpSlipGroup(where: {shopId: {_eq: $shopId}}) {
    id: serial
    dishUpSlipGroupId
    name
  }
}
    `;
export const DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTable = gql`
    mutation DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTable($dishUpSlipGroupId: uuid!, $tableId: Int!) {
  delete_dishUpSlipGroupTables(
    where: {dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, _tableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export const DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTable = gql`
    mutation DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTable($input: dishUpSlipGroupTables_insert_input!) {
  insert_dishUpSlipGroupTables_one(object: $input) {
    __typename
  }
}
    `;
export type DishUpSlipGroupTablePrintingGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type DishUpSlipGroupTablePrintingGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'id' | 'name' | 'tableId'>
    & { dishUpSlipGroupTables: Array<(
      { __typename?: 'dishUpSlipGroupTables' }
      & Pick<Types.DishUpSlipGroupTables, 'dishUpSlipGroupId'>
      & { tableId: Types.DishUpSlipGroupTables['_tableId'] }
    )> }
  )>, dishUpSlipGroup: Array<(
    { __typename?: 'dishUpSlipGroup' }
    & Pick<Types.DishUpSlipGroup, 'dishUpSlipGroupId' | 'name'>
    & { id: Types.DishUpSlipGroup['serial'] }
  )> }
);

export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationVariables = Types.Exact<{
  dishUpSlipGroupId: Types.Scalars['uuid'];
  tableId: Types.Scalars['Int'];
}>;


export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dishUpSlipGroupTables?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupTables_mutation_response' }
    & Pick<Types.DishUpSlipGroupTablesMutationResponse, 'affected_rows'>
  )> }
);

export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationVariables = Types.Exact<{
  input: Types.DishUpSlipGroupTablesInsertInput;
}>;


export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dishUpSlipGroupTables_one?: Types.Maybe<{ __typename: 'dishUpSlipGroupTables' }> }
);


export const DishUpSlipGroupTablePrintingGetTablesDocument = gql`
    query DishUpSlipGroupTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
    tableId
    dishUpSlipGroupTables {
      dishUpSlipGroupId
      tableId: _tableId
    }
  }
  dishUpSlipGroup(where: {shopId: {_eq: $shopId}}) {
    id: serial
    dishUpSlipGroupId
    name
  }
}
    `;

/**
 * __useDishUpSlipGroupTablePrintingGetTablesQuery__
 *
 * To run a query within a React component, call `useDishUpSlipGroupTablePrintingGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupTablePrintingGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishUpSlipGroupTablePrintingGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useDishUpSlipGroupTablePrintingGetTablesQuery(baseOptions: Apollo.QueryHookOptions<DishUpSlipGroupTablePrintingGetTablesQuery, DishUpSlipGroupTablePrintingGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DishUpSlipGroupTablePrintingGetTablesQuery, DishUpSlipGroupTablePrintingGetTablesQueryVariables>(DishUpSlipGroupTablePrintingGetTablesDocument, options);
      }
export function useDishUpSlipGroupTablePrintingGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DishUpSlipGroupTablePrintingGetTablesQuery, DishUpSlipGroupTablePrintingGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DishUpSlipGroupTablePrintingGetTablesQuery, DishUpSlipGroupTablePrintingGetTablesQueryVariables>(DishUpSlipGroupTablePrintingGetTablesDocument, options);
        }
export type DishUpSlipGroupTablePrintingGetTablesQueryHookResult = ReturnType<typeof useDishUpSlipGroupTablePrintingGetTablesQuery>;
export type DishUpSlipGroupTablePrintingGetTablesLazyQueryHookResult = ReturnType<typeof useDishUpSlipGroupTablePrintingGetTablesLazyQuery>;
export type DishUpSlipGroupTablePrintingGetTablesQueryResult = Apollo.QueryResult<DishUpSlipGroupTablePrintingGetTablesQuery, DishUpSlipGroupTablePrintingGetTablesQueryVariables>;
export const DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableDocument = gql`
    mutation DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTable($dishUpSlipGroupId: uuid!, $tableId: Int!) {
  delete_dishUpSlipGroupTables(
    where: {dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, _tableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationFn = Apollo.MutationFunction<DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationVariables>;

/**
 * __useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation__
 *
 * To run a mutation, you first call `useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation, { data, loading, error }] = useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation({
 *   variables: {
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation(baseOptions?: Apollo.MutationHookOptions<DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationVariables>(DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableDocument, options);
      }
export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationHookResult = ReturnType<typeof useDishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation>;
export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationResult = Apollo.MutationResult<DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation>;
export type DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationOptions = Apollo.BaseMutationOptions<DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingDeleteDishUpSlipGroupTableMutationVariables>;
export const DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableDocument = gql`
    mutation DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTable($input: dishUpSlipGroupTables_insert_input!) {
  insert_dishUpSlipGroupTables_one(object: $input) {
    __typename
  }
}
    `;
export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationFn = Apollo.MutationFunction<DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationVariables>;

/**
 * __useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation__
 *
 * To run a mutation, you first call `useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation, { data, loading, error }] = useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation(baseOptions?: Apollo.MutationHookOptions<DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationVariables>(DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableDocument, options);
      }
export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationHookResult = ReturnType<typeof useDishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation>;
export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationResult = Apollo.MutationResult<DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation>;
export type DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationOptions = Apollo.BaseMutationOptions<DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutation, DishUpSlipGroupTablePrintingInsertDishUpSlipGroupTableMutationVariables>;