import React, { memo, ReactNode } from "react";
import styled from "styled-components";
import { Dropdown as AntdDropdown } from "antd";
import { DropDownProps } from "antd/lib/dropdown";

import { grey } from "constants/colors";
import { viewport } from "constants/viewport";

const StyledAntdDropdown = styled(AntdDropdown)`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${grey[0]};
  margin-right: 24px;
`;

export const Dropdown = memo<DropDownProps & { children: ReactNode }>(({ overlay, ...props }) => (
  <StyledAntdDropdown overlay={<Wrapper>{overlay}</Wrapper>} trigger={["click"]} {...props} />
));
