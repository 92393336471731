import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { FormHelp } from "components/Form/FormHelp";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const FeaturedLabelTextField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    label={
      <FormHelp
        label="特集ラベル (日本語)"
        help="メニュー画像の左下にラベルを追加することができます。「期間限定！」「大人気メニュー」などの短いフレーズがおすすめです"
      />
    }
    name="featuredLabelText"
    {...props}
  >
    <Input disabled={disabled} />
  </EditMenuFormItem>
));
