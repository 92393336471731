import React, { memo, useCallback } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = {
  hasNonZeroPlanTimeLimit: boolean;
  hasPlanTimeLimit: boolean;
  hasEndNotificationTime: boolean;
  setHasEndNotificationTime: (hasPlanTimeLimit: boolean) => void;
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const PlanEnableEndNotificationField = memo<Props>(
  ({
    hasNonZeroPlanTimeLimit,
    hasPlanTimeLimit,
    hasEndNotificationTime,
    setHasEndNotificationTime,
    disabled,
    ...props
  }) => {
    const handleClickToggleHasPlanTimeLimit = useCallback(
      () => setHasEndNotificationTime(!hasEndNotificationTime),
      [setHasEndNotificationTime, hasEndNotificationTime],
    );

    return (
      <EditPlanFormItem.NonProperty label="終了時間を通知する" {...props}>
        <Switch
          checked={hasEndNotificationTime}
          onClick={handleClickToggleHasPlanTimeLimit}
          disabled={disabled || !hasNonZeroPlanTimeLimit || !hasPlanTimeLimit}
        />
      </EditPlanFormItem.NonProperty>
    );
  },
);
