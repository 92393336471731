import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanFirstOrderCategoryMenuModalGetMenus = gql`
    query AddPlanFirstOrderCategoryMenuModalGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      id
      categoryMenuId
      menu {
        name
      }
    }
  }
}
    `;
export const AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenus = gql`
    mutation AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenus($planFirstOrderCategoryMenus: [planFirstOrderCategoryMenu_insert_input!]!) {
  insert_planFirstOrderCategoryMenu(
    objects: $planFirstOrderCategoryMenus
    on_conflict: {constraint: planFirstOrderCategoryMenu_planId_categoryMenuId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export type AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanFirstOrderCategoryMenuModalGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'categoryId' | 'name' | 'shopSideName'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'id' | 'categoryMenuId'>
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'name'>
      ) }
    )> }
  )> }
);

export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationVariables = Types.Exact<{
  planFirstOrderCategoryMenus: Array<Types.PlanFirstOrderCategoryMenuInsertInput> | Types.PlanFirstOrderCategoryMenuInsertInput;
}>;


export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planFirstOrderCategoryMenu?: Types.Maybe<(
    { __typename?: 'planFirstOrderCategoryMenu_mutation_response' }
    & Pick<Types.PlanFirstOrderCategoryMenuMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planFirstOrderCategoryMenu' }
      & { categoryMenuId: Types.PlanFirstOrderCategoryMenu['_categoryMenuId'] }
    )> }
  )> }
);


export const AddPlanFirstOrderCategoryMenuModalGetMenusDocument = gql`
    query AddPlanFirstOrderCategoryMenuModalGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    id
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      id
      categoryMenuId
      menu {
        name
      }
    }
  }
}
    `;

/**
 * __useAddPlanFirstOrderCategoryMenuModalGetMenusQuery__
 *
 * To run a query within a React component, call `useAddPlanFirstOrderCategoryMenuModalGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanFirstOrderCategoryMenuModalGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanFirstOrderCategoryMenuModalGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanFirstOrderCategoryMenuModalGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddPlanFirstOrderCategoryMenuModalGetMenusQuery, AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanFirstOrderCategoryMenuModalGetMenusQuery, AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables>(AddPlanFirstOrderCategoryMenuModalGetMenusDocument, options);
      }
export function useAddPlanFirstOrderCategoryMenuModalGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanFirstOrderCategoryMenuModalGetMenusQuery, AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanFirstOrderCategoryMenuModalGetMenusQuery, AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables>(AddPlanFirstOrderCategoryMenuModalGetMenusDocument, options);
        }
export type AddPlanFirstOrderCategoryMenuModalGetMenusQueryHookResult = ReturnType<typeof useAddPlanFirstOrderCategoryMenuModalGetMenusQuery>;
export type AddPlanFirstOrderCategoryMenuModalGetMenusLazyQueryHookResult = ReturnType<typeof useAddPlanFirstOrderCategoryMenuModalGetMenusLazyQuery>;
export type AddPlanFirstOrderCategoryMenuModalGetMenusQueryResult = Apollo.QueryResult<AddPlanFirstOrderCategoryMenuModalGetMenusQuery, AddPlanFirstOrderCategoryMenuModalGetMenusQueryVariables>;
export const AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusDocument = gql`
    mutation AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenus($planFirstOrderCategoryMenus: [planFirstOrderCategoryMenu_insert_input!]!) {
  insert_planFirstOrderCategoryMenu(
    objects: $planFirstOrderCategoryMenus
    on_conflict: {constraint: planFirstOrderCategoryMenu_planId_categoryMenuId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationFn = Apollo.MutationFunction<AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation, AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationVariables>;

/**
 * __useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation__
 *
 * To run a mutation, you first call `useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation, { data, loading, error }] = useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation({
 *   variables: {
 *      planFirstOrderCategoryMenus: // value for 'planFirstOrderCategoryMenus'
 *   },
 * });
 */
export function useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation, AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation, AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationVariables>(AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusDocument, options);
      }
export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationHookResult = ReturnType<typeof useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation>;
export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationResult = Apollo.MutationResult<AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation>;
export type AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationOptions = Apollo.BaseMutationOptions<AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation, AddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutationVariables>;