import React, { memo } from "react";
import { Switch } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const EnabledField = memo<Props>((props) => (
  <FormItem
    label="連携"
    name="enabled"
    rules={[{ required: true }]}
    required
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </FormItem>
));
