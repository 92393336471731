import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import {
  useEditMenuDealersDeleteShopMenusMutation,
  useEditMenuDealersGetMenuQuery,
  useEditMenuDealersGetShopsQuery,
  useEditMenuDealersInsertShopMenusMutation,
  useEditMenuDealersUpdateShopMenuMutation,
  useEditMenuDealersUpdateShopMenusMutation,
  useEditMenuDealersUpdateStockMutation,
} from "pages/EditMenuDealers/queries";
import type {
  CreateShopMenusInput,
  DeleteShopMenusInput,
  UpdateShopMenusBulkInput,
} from "types/graphql";

import { ShopMenuTable } from "./ShopMenuTable";

export const EditMenuDealers = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const _menuId = Number(id);
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getMenuData,
    loading: loadingMenu,
    error: getMenuDataError,
    refetch: refetchMenu,
  } = useEditMenuDealersGetMenuQuery(
    Number.isNaN(_menuId) ? { skip: true } : { variables: { menuId: _menuId } },
  );
  const menu = getMenuData?.menu?.[0];
  const menuId = menu?.id;
  const shopMenus = useMemo(() => menu?.shopMenus ?? [], [menu?.shopMenus]);

  setCompany(menu?.companyId);

  const {
    data: getShopsData,
    loading: loadingShops,
    error: getShopsDataError,
  } = useEditMenuDealersGetShopsQuery(
    menu?.companyId ? { variables: { companyId: menu?.companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const shouldShowNetworkAlert = getMenuDataError || getShopsDataError;

  const [updateShopMenuMutation, { loading: loadingUpdateShopMenu }] =
    useEditMenuDealersUpdateShopMenuMutation();
  const [updateShopMenuStockMutation, { loading: loadingUpdateShopMenuStock }] =
    useEditMenuDealersUpdateStockMutation();
  const [insertShopMenusMutation, { loading: loadingInsertShopMenus }] =
    useEditMenuDealersInsertShopMenusMutation();
  const [deleteShopMenusMutation, { loading: loadingDeleteShopMenus }] =
    useEditMenuDealersDeleteShopMenusMutation();
  const [updateShopMenusMutation, { loading: loadingUpdateShopMenus }] =
    useEditMenuDealersUpdateShopMenusMutation();

  const updateShopMenus = useCallback(
    async ({
      createShopMenusInput,
      updateShopMenusInput,
      deleteShopMenusInput,
    }: {
      createShopMenusInput?: CreateShopMenusInput;
      updateShopMenusInput?: UpdateShopMenusBulkInput;
      deleteShopMenusInput?: DeleteShopMenusInput;
    }) => {
      try {
        if (createShopMenusInput) {
          await insertShopMenusMutation({
            variables: { input: createShopMenusInput },
          });
        }
        if (updateShopMenusInput) {
          await updateShopMenusMutation({
            variables: { input: updateShopMenusInput },
          });
        }
        if (deleteShopMenusInput) {
          await deleteShopMenusMutation({
            variables: { input: deleteShopMenusInput },
          });
        }
        await refetchMenu();

        message.success("取扱設定を更新しました");
      } catch {
        message.error("取扱設定の更新に失敗しました");
      }
    },
    [deleteShopMenusMutation, insertShopMenusMutation, refetchMenu, updateShopMenusMutation],
  );

  const updateMenuIsVisibleForCustomer = useCallback(
    async ({ shopId, isVisibleForCustomer }: { shopId: string; isVisibleForCustomer: boolean }) => {
      if (!menuId) return;
      try {
        await updateShopMenuMutation({
          variables: {
            shopId,
            menuId,
            input: {
              isVisibleForCustomer,
            },
          },
        });
        await refetchMenu();

        message.success("表示設定を更新しました");
      } catch {
        message.error("表示設定の更新に失敗しました");
      }
    },
    [menuId, refetchMenu, updateShopMenuMutation],
  );

  const updateMenuIsVisibleForStaff = useCallback(
    async ({ shopId, isVisibleForStaff }: { shopId: string; isVisibleForStaff: boolean }) => {
      if (!menuId) return;
      try {
        await updateShopMenuMutation({
          variables: {
            shopId,
            menuId,
            input: {
              isVisibleForStaff,
            },
          },
        });
        await refetchMenu();

        message.success("表示設定を更新しました");
      } catch {
        message.error("表示設定の更新に失敗しました");
      }
    },
    [menuId, refetchMenu, updateShopMenuMutation],
  );

  const updateShopMenuIsSoldOut = useCallback(
    async (shopId: string, isSoldOut: boolean) => {
      if (!menuId) return;
      try {
        const stockId =
          shopMenus.find((shopMenu) => shopMenu.shopId === shopId)?.stock?.id ?? uuidv4();
        await updateShopMenuStockMutation({
          variables: {
            stockId,
            menuId,
            shopId,
            stock: { currentStockNum: isSoldOut ? 0 : null, id: stockId, shopId },
          },
        });
        await refetchMenu();

        message.success(`売切設定を更新しました`);
      } catch (err) {
        message.error(`売切設定の更新に失敗しました`);
      }
    },
    [menuId, refetchMenu, shopMenus, updateShopMenuStockMutation],
  );

  const selectShop = useCallback(
    async ({ shopId }: { shopId: string }) => {
      if (!menuId) return;
      await updateShopMenus({
        createShopMenusInput: {
          menuId,
          shopIds: [shopId],
        },
      });
    },
    [menuId, updateShopMenus],
  );

  const deselectShop = useCallback(
    async ({ shopId }: { shopId: string }) => {
      if (!menuId) return;
      const targetShopMenu = shopMenus.find(
        (shopMenu) => shopMenu.menuId === menuId && shopMenu.shopId === shopId,
      );
      if (!targetShopMenu) return;

      await updateShopMenus({
        deleteShopMenusInput: { shopMenuIds: [targetShopMenu.id] },
      });
    },
    [menuId, shopMenus, updateShopMenus],
  );

  const loading =
    loadingMenu ||
    loadingShops ||
    loadingUpdateShopMenu ||
    loadingUpdateShopMenuStock ||
    loadingInsertShopMenus ||
    loadingDeleteShopMenus ||
    loadingUpdateShopMenus;

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }, { name: menu?.name ?? "" }],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(shouldShowNetworkAlert)}
      />
      {menu && shops && menuId && (
        <FormContent>
          <ShopMenuTable
            loading={loading}
            shopMenus={shopMenus}
            shops={shops}
            menuId={menuId}
            _menuId={_menuId}
            onSelectShopId={selectShop}
            deselectShop={deselectShop}
            onCheckIsSoldOut={updateShopMenuIsSoldOut}
            onCheckIsVisibleForCustomer={updateMenuIsVisibleForCustomer}
            onCheckIsVisibleForStaff={updateMenuIsVisibleForStaff}
            updateShopMenus={updateShopMenus}
          />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
