import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopMenuStockFormItem } from "../useEditShopMenuStockForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NoCurrentStockNumField = memo<Props>(({ ...props }) => (
  <EditShopMenuStockFormItem name="noCurrentStockNum" valuePropName="checked" {...props}>
    <Checkbox>無制限にする</Checkbox>
  </EditShopMenuStockFormItem>
));
