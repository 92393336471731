import React, { memo } from "react";
import styled from "styled-components";
import {
  applySignToFluctuation,
  getRankViewPropertiesByScore,
} from "models/questionnaireAnalytics";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Rank = styled.span<{ color: string; large: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ large }) => (large ? 36 : 32)}px;
  height: ${({ large }) => (large ? 36 : 32)}px;
  margin-right: ${({ large }) => (large ? 12 : 8)}px;
  background: ${({ color }) => color};
  border-radius: 34px;
  font-weight: bold;
  font-size: ${({ large }) => (large ? 24 : 20)}px;
  color: #ffffff;
`;

const Score = styled.span<{ large: boolean }>`
  font-weight: bold;
  font-size: ${({ large }) => (large ? 40 : 30)}px;
  margin-right: ${({ large }) => (large ? 8 : 4)}px;
`;

const Fluctuation = styled.span<{ large: boolean }>`
  font-weight: bold;
  font-size: 20px;
  font-size: ${({ large }) => (large ? 20 : 14)}px;
  color: rgba(0, 0, 0, 0.4);
`;

type Props = {
  score: number;
  fluctuation?: number;
  large?: boolean;
  shouldShowRank?: boolean;
  isEmpty: boolean;
};

export const ScoreMetrics = memo<Props>(
  ({ score, fluctuation, large = false, shouldShowRank = true, isEmpty }) => {
    const { rank, color } = getRankViewPropertiesByScore(score);

    return (
      <FlexBox>
        {!isEmpty && shouldShowRank && (
          <Rank color={color} large={large}>
            {rank}
          </Rank>
        )}

        <Score large={large}>{isEmpty ? "-" : score}</Score>

        {!isEmpty && typeof fluctuation !== "undefined" && (
          <Fluctuation large={large}>{applySignToFluctuation(fluctuation)}</Fluctuation>
        )}
      </FlexBox>
    );
  },
);
