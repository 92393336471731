import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { AddPlanCategoryModal } from "pages/PlanCategories/AddPlanCategoryModal";
import { CategoryTable } from "pages/PlanCategories/CategoryTable";
import {
  usePlanCategoriesDeletePlanMenuCategoryMutation,
  usePlanCategoriesGetPlanQuery,
} from "pages/PlanCategories/queries";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const PlanCategories = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    refetch,
    loading: loadingPlan,
    error,
  } = usePlanCategoriesGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];

  setCompany(plan?.companyId);

  const categories = plan?.planMenuCategories.map(({ category }) => category) ?? [];

  const [deletePlanMenuCategoryMutation, { loading: loadingDeletePlanMenuCategory }] =
    usePlanCategoriesDeletePlanMenuCategoryMutation();

  const onRemove = useCallback(
    async (categoryId: number) => {
      const planId = plan?.planId;
      if (planId) {
        try {
          const { data: deletePlanMenuCategory } = await deletePlanMenuCategoryMutation({
            variables: { planId, categoryId },
          });
          const returning = deletePlanMenuCategory?.delete_planMenuCategory?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("プランから削除しました");
        } catch (err) {
          message.success("プランからの削除に失敗しました");
        }
      }
    },
    [plan?.planId, deletePlanMenuCategoryMutation, refetch],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToPlanCategories = useCallback(() => {
    navigate(`/plan/${planId}/category`, { replace: true });
  }, [navigate, planId]);

  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");
  const canEditPlan = isEditPlanFeatureEnabled && canEditAllShopPlans;

  return (
    <DashboardLayout title={plan?.planName}>
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loadingPlan && <Loading height={300} />}
      {plan && (
        <FormContent>
          {canEditPlan && (
            <ButtonWrapper>
              <Link key="addCategory" to={`/plan/${planId}/category/add`} replace>
                <Button type="primary">追加</Button>
              </Link>
            </ButtonWrapper>
          )}
          <CategoryTable
            categories={categories}
            loading={loadingDeletePlanMenuCategory}
            onRemove={onRemove}
            canEditCategory={isFeatureEnabled("editCategory")}
            canEditPlan={canEditPlan}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddPlanCategoryModal
                  plan={plan}
                  goBack={goBackToPlanCategories}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
