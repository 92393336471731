import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardPlansGetPlans = gql`
    query WinboardPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;
export const WinboardPlansGetCategories = gql`
    query WinboardPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const WinboardPlansGetShops = gql`
    query WinboardPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const WinboardPlansDeleteWinboardMenu = gql`
    mutation WinboardPlansDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardPlansGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plans: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'name' | 'planOptionId'>
    )>, winboardMenus: Array<(
      { __typename?: 'winboardMenu' }
      & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
    )> }
  )> }
);

export type WinboardPlansGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardPlansGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type WinboardPlansGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardPlansGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type WinboardPlansDeleteWinboardMenuMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type WinboardPlansDeleteWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_winboardMenu_by_pk?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const WinboardPlansGetPlansDocument = gql`
    query WinboardPlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    planId
    planName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    winboardMenus(where: {_planChoiceId: {_is_null: true}}) {
      id
      name
      code
      bumonCode
      bumonName
      categoryCode
      categoryName
    }
  }
}
    `;

/**
 * __useWinboardPlansGetPlansQuery__
 *
 * To run a query within a React component, call `useWinboardPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardPlansGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<WinboardPlansGetPlansQuery, WinboardPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardPlansGetPlansQuery, WinboardPlansGetPlansQueryVariables>(WinboardPlansGetPlansDocument, options);
      }
export function useWinboardPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardPlansGetPlansQuery, WinboardPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardPlansGetPlansQuery, WinboardPlansGetPlansQueryVariables>(WinboardPlansGetPlansDocument, options);
        }
export type WinboardPlansGetPlansQueryHookResult = ReturnType<typeof useWinboardPlansGetPlansQuery>;
export type WinboardPlansGetPlansLazyQueryHookResult = ReturnType<typeof useWinboardPlansGetPlansLazyQuery>;
export type WinboardPlansGetPlansQueryResult = Apollo.QueryResult<WinboardPlansGetPlansQuery, WinboardPlansGetPlansQueryVariables>;
export const WinboardPlansGetCategoriesDocument = gql`
    query WinboardPlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useWinboardPlansGetCategoriesQuery__
 *
 * To run a query within a React component, call `useWinboardPlansGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlansGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardPlansGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardPlansGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<WinboardPlansGetCategoriesQuery, WinboardPlansGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardPlansGetCategoriesQuery, WinboardPlansGetCategoriesQueryVariables>(WinboardPlansGetCategoriesDocument, options);
      }
export function useWinboardPlansGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardPlansGetCategoriesQuery, WinboardPlansGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardPlansGetCategoriesQuery, WinboardPlansGetCategoriesQueryVariables>(WinboardPlansGetCategoriesDocument, options);
        }
export type WinboardPlansGetCategoriesQueryHookResult = ReturnType<typeof useWinboardPlansGetCategoriesQuery>;
export type WinboardPlansGetCategoriesLazyQueryHookResult = ReturnType<typeof useWinboardPlansGetCategoriesLazyQuery>;
export type WinboardPlansGetCategoriesQueryResult = Apollo.QueryResult<WinboardPlansGetCategoriesQuery, WinboardPlansGetCategoriesQueryVariables>;
export const WinboardPlansGetShopsDocument = gql`
    query WinboardPlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useWinboardPlansGetShopsQuery__
 *
 * To run a query within a React component, call `useWinboardPlansGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlansGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardPlansGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardPlansGetShopsQuery(baseOptions: Apollo.QueryHookOptions<WinboardPlansGetShopsQuery, WinboardPlansGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardPlansGetShopsQuery, WinboardPlansGetShopsQueryVariables>(WinboardPlansGetShopsDocument, options);
      }
export function useWinboardPlansGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardPlansGetShopsQuery, WinboardPlansGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardPlansGetShopsQuery, WinboardPlansGetShopsQueryVariables>(WinboardPlansGetShopsDocument, options);
        }
export type WinboardPlansGetShopsQueryHookResult = ReturnType<typeof useWinboardPlansGetShopsQuery>;
export type WinboardPlansGetShopsLazyQueryHookResult = ReturnType<typeof useWinboardPlansGetShopsLazyQuery>;
export type WinboardPlansGetShopsQueryResult = Apollo.QueryResult<WinboardPlansGetShopsQuery, WinboardPlansGetShopsQueryVariables>;
export const WinboardPlansDeleteWinboardMenuDocument = gql`
    mutation WinboardPlansDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardPlansDeleteWinboardMenuMutationFn = Apollo.MutationFunction<WinboardPlansDeleteWinboardMenuMutation, WinboardPlansDeleteWinboardMenuMutationVariables>;

/**
 * __useWinboardPlansDeleteWinboardMenuMutation__
 *
 * To run a mutation, you first call `useWinboardPlansDeleteWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWinboardPlansDeleteWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [winboardPlansDeleteWinboardMenuMutation, { data, loading, error }] = useWinboardPlansDeleteWinboardMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWinboardPlansDeleteWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<WinboardPlansDeleteWinboardMenuMutation, WinboardPlansDeleteWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WinboardPlansDeleteWinboardMenuMutation, WinboardPlansDeleteWinboardMenuMutationVariables>(WinboardPlansDeleteWinboardMenuDocument, options);
      }
export type WinboardPlansDeleteWinboardMenuMutationHookResult = ReturnType<typeof useWinboardPlansDeleteWinboardMenuMutation>;
export type WinboardPlansDeleteWinboardMenuMutationResult = Apollo.MutationResult<WinboardPlansDeleteWinboardMenuMutation>;
export type WinboardPlansDeleteWinboardMenuMutationOptions = Apollo.BaseMutationOptions<WinboardPlansDeleteWinboardMenuMutation, WinboardPlansDeleteWinboardMenuMutationVariables>;