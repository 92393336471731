import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { CreateShopInput } from "types/graphql";

import { useShopForm } from "../useShopForm";

import { EnglishNameField } from "./EnglishNameField";
import { NameField } from "./NameField";

type Props = {
  companyId: string;
  onSubmit: (shop: CreateShopInput) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
};

export const AddShopForm = memo<Props>(
  ({ companyId, onSubmit, onFormValidationError, loading }) => {
    const { form, initialValues, submit } = useShopForm({
      companyId,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <>
        <FormContent>
          <Form name="shop" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <EnglishNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
