import { useCallback } from "react";

import { message } from "components/antd/message";
import { FeatureFlag, FeatureFlagEnum } from "types/graphql";

import { isFeatureFlagEnum } from "./isFeatureFlagEnum";
import {
  useFeatureFlagGetAdyenPaymentShopConfigLazyQuery,
  useFeatureFlagsDeleteFeatureFlagShopMutation,
  useFeatureFlagsDisableEbicaConfigMutation,
  useFeatureFlagsEnableAdyenOnlinePaymentMutation,
  useFeatureFlagsEnableEbicaConfigMutation,
  useFeatureFlagsEnableGmoOnlinePaymentMutation,
  useFeatureFlagsEnableStripeOnlinePaymentMutation,
  useFeatureFlagsInsertFeatureFlagShopMutation,
} from "./queries";

export const useOnSwitchChange = ({
  shopId,
  refetchFeatureFlagShops,
}: {
  shopId: string;
  refetchFeatureFlagShops: () => void;
}) => {
  const [addFeatureFlagShopMutation] = useFeatureFlagsInsertFeatureFlagShopMutation();
  const [deleteFeatureFlagShopMutation] = useFeatureFlagsDeleteFeatureFlagShopMutation();
  const [enableStripeOnlinePaymentMutation] = useFeatureFlagsEnableStripeOnlinePaymentMutation();
  const [enableGmoOnlinePaymentMutation] = useFeatureFlagsEnableGmoOnlinePaymentMutation();
  const [enableAdyenOnlinePaymentMutation] = useFeatureFlagsEnableAdyenOnlinePaymentMutation();
  const [enableEbicaConfigMutation] = useFeatureFlagsEnableEbicaConfigMutation();
  const [disableEbicaConfigMutation] = useFeatureFlagsDisableEbicaConfigMutation();
  const [fetchAdyenPaymentShopConfigQuery] = useFeatureFlagGetAdyenPaymentShopConfigLazyQuery();

  const validateAdyenPaymentShopConfig = useCallback(async () => {
    // Adyen の feature flag 以外はこのデータを使わないため、必要なタイミングで取得する
    const result = await fetchAdyenPaymentShopConfigQuery({
      variables: { shopId },
    });
    const shopConfig = result.data?.adyenPaymentShopConfig;
    // 料率設定 = paymentMethod が少なくとも1つ設定されている
    return Boolean(shopConfig?.[0]?.adyenPaymentShopPaymentMethods.length);
  }, [fetchAdyenPaymentShopConfigQuery, shopId]);

  const enableAdyenOnlinePayment = useCallback(async () => {
    const isValid = await validateAdyenPaymentShopConfig();
    if (!isValid) {
      message.error("Adyen 決済の料率設定が見つかりません");
      return;
    }
    await enableAdyenOnlinePaymentMutation({ variables: { shopId } });
  }, [validateAdyenPaymentShopConfig, enableAdyenOnlinePaymentMutation, shopId]);

  const enableFlag = useCallback(
    async (name: FeatureFlagEnum) => {
      if (name === FeatureFlagEnum.EnableOnlinePayment) {
        return await enableStripeOnlinePaymentMutation({ variables: { shopId } });
      }

      if (name === FeatureFlagEnum.EnableGmoOnlinePayment) {
        return await enableGmoOnlinePaymentMutation({ variables: { shopId } });
      }

      if (name === FeatureFlagEnum.EnableAdyenOnlinePayment) {
        return await enableAdyenOnlinePayment();
      }

      if (name === FeatureFlagEnum.Ebica) {
        await enableEbicaConfigMutation({ variables: { shopId } });
      }

      await addFeatureFlagShopMutation({
        variables: { featureFlagShop: { name, shopId } },
      });
    },
    [
      addFeatureFlagShopMutation,
      enableGmoOnlinePaymentMutation,
      enableStripeOnlinePaymentMutation,
      enableAdyenOnlinePayment,
      enableEbicaConfigMutation,
      shopId,
    ],
  );

  const disableFlag = useCallback(
    async (name: FeatureFlagEnum) => {
      if (name === FeatureFlagEnum.Ebica) {
        await disableEbicaConfigMutation({ variables: { shopId } });
      }

      await deleteFeatureFlagShopMutation({ variables: { name, shopId } });
    },
    [deleteFeatureFlagShopMutation, disableEbicaConfigMutation, shopId],
  );

  const onSwitchChange = useCallback(
    async ({ name, checked }: { name: FeatureFlag["name"]; checked: boolean }) => {
      try {
        if (!isFeatureFlagEnum(name)) {
          throw new Error("Invalid feature flag name");
        }

        if (checked) {
          await enableFlag(name);
        } else {
          await disableFlag(name);
        }

        message.success("フラグを更新しました");
      } catch (err) {
        message.error("フラグの更新に失敗しました");
      }
      refetchFeatureFlagShops();
    },
    [enableFlag, disableFlag, refetchFeatureFlagShops],
  );

  return {
    onSwitchChange,
  };
};
