import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";
import { isNotNull } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { KdDisplayTarget } from "pages/KitchenDisplayDisplayTargetMenus/types";

export type EditMenuFormValues = {
  categoriesAndMenus: string[];
};

const getInitialValues = ({ kdDisplayTarget }: { kdDisplayTarget: KdDisplayTarget }) => {
  const categoriesAndMenus = kdDisplayTarget.shopMenuKdDisplayTargets.flatMap(({ shopMenu }) =>
    shopMenu?.menu.categoryMenus.map(
      (category) => `categoryId:${category.categoryId} menuId:${shopMenu?.menuId}`,
    ),
  );
  return { categoriesAndMenus };
};

export const EditMenuFormItem = createFormItem<EditMenuFormValues>();

export const useEditMenuForm = ({
  onChange,
  kdDisplayTarget,
}: {
  onChange: ({ menuIds }: { menuIds: number[] }) => void;
  kdDisplayTarget: KdDisplayTarget;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues({ kdDisplayTarget });

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditMenuFormValues;
      const menuIds = values.categoriesAndMenus
        .map((key) => key.match(/(?:menuId:)(?<id>\d+)/)?.groups?.id ?? null)
        .filter(isNotNull)
        .map((menuId) => Number(menuId));
      onChange({ menuIds });
    },
    [onChange],
  );

  return { form, initialValues, change };
};
