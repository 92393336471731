import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddCategoryGetCategoryMaxPriority = gql`
    query AddCategoryGetCategoryMaxPriority($companyId: uuid!) {
  category_aggregate(
    where: {companyId: {_eq: $companyId}, _not: {menuRecommendationMeta: {}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddCategoryInsertCategory = gql`
    mutation AddCategoryInsertCategory($category: CreateCategoryInputCategorySource!) {
  createCategory(input: $category) {
    categoryId
  }
}
    `;
export type AddCategoryGetCategoryMaxPriorityQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddCategoryGetCategoryMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { category_aggregate: (
    { __typename?: 'category_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'category_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'category_max_fields' }
        & Pick<Types.CategoryMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddCategoryInsertCategoryMutationVariables = Types.Exact<{
  category: Types.CreateCategoryInputCategorySource;
}>;


export type AddCategoryInsertCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { createCategory: (
    { __typename?: 'CreateCategoryOutput' }
    & Pick<Types.CreateCategoryOutput, 'categoryId'>
  ) }
);


export const AddCategoryGetCategoryMaxPriorityDocument = gql`
    query AddCategoryGetCategoryMaxPriority($companyId: uuid!) {
  category_aggregate(
    where: {companyId: {_eq: $companyId}, _not: {menuRecommendationMeta: {}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddCategoryGetCategoryMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddCategoryGetCategoryMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryGetCategoryMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddCategoryGetCategoryMaxPriorityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddCategoryGetCategoryMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddCategoryGetCategoryMaxPriorityQuery, AddCategoryGetCategoryMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddCategoryGetCategoryMaxPriorityQuery, AddCategoryGetCategoryMaxPriorityQueryVariables>(AddCategoryGetCategoryMaxPriorityDocument, options);
      }
export function useAddCategoryGetCategoryMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddCategoryGetCategoryMaxPriorityQuery, AddCategoryGetCategoryMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddCategoryGetCategoryMaxPriorityQuery, AddCategoryGetCategoryMaxPriorityQueryVariables>(AddCategoryGetCategoryMaxPriorityDocument, options);
        }
export type AddCategoryGetCategoryMaxPriorityQueryHookResult = ReturnType<typeof useAddCategoryGetCategoryMaxPriorityQuery>;
export type AddCategoryGetCategoryMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddCategoryGetCategoryMaxPriorityLazyQuery>;
export type AddCategoryGetCategoryMaxPriorityQueryResult = Apollo.QueryResult<AddCategoryGetCategoryMaxPriorityQuery, AddCategoryGetCategoryMaxPriorityQueryVariables>;
export const AddCategoryInsertCategoryDocument = gql`
    mutation AddCategoryInsertCategory($category: CreateCategoryInputCategorySource!) {
  createCategory(input: $category) {
    categoryId
  }
}
    `;
export type AddCategoryInsertCategoryMutationFn = Apollo.MutationFunction<AddCategoryInsertCategoryMutation, AddCategoryInsertCategoryMutationVariables>;

/**
 * __useAddCategoryInsertCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryInsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryInsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryInsertCategoryMutation, { data, loading, error }] = useAddCategoryInsertCategoryMutation({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useAddCategoryInsertCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryInsertCategoryMutation, AddCategoryInsertCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryInsertCategoryMutation, AddCategoryInsertCategoryMutationVariables>(AddCategoryInsertCategoryDocument, options);
      }
export type AddCategoryInsertCategoryMutationHookResult = ReturnType<typeof useAddCategoryInsertCategoryMutation>;
export type AddCategoryInsertCategoryMutationResult = Apollo.MutationResult<AddCategoryInsertCategoryMutation>;
export type AddCategoryInsertCategoryMutationOptions = Apollo.BaseMutationOptions<AddCategoryInsertCategoryMutation, AddCategoryInsertCategoryMutationVariables>;