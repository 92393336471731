import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form, withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { ShopBusinessOperationHourInsertInput } from "types/graphql";

import { Shop, ShopBusinessOperationHour } from "../types";

import { ShopBusinessOperationHourField } from "./ShopBusinessOperationHourField";
import {
  BusinessOperationHourFormValues,
  EditShopBusinessOperationHourFormItem,
  useEditShopBusinessOperationHourForm,
} from "./useEditShopBusinessOperationHourForm";

type Props = {
  shop: Shop;
  shopBusinessOperationHours: ShopBusinessOperationHour[];
  onSubmit: (shopBusinessHours: ShopBusinessOperationHourInsertInput[]) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const EditShopBusinessOperationHourForm = memo<Props>(
  ({ shop, shopBusinessOperationHours, onSubmit, onClose, loading, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditShopBusinessOperationHourForm({
      shop,
      shopBusinessOperationHours,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, submit, onFormValidationError]);

    return (
      <>
        <FormContent>
          <Form name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <EditShopBusinessOperationHourFormItem.NonProperty
              shouldUpdate={withFormDependencies(({ businessOperationHours }) => [
                businessOperationHours,
              ])}
              noStyle
            >
              {({ getFieldValue }) => {
                const businessOperationHours = getFieldValue(
                  "businessOperationHours",
                ) as BusinessOperationHourFormValues[];
                return (
                  <ShopBusinessOperationHourField
                    formValues={businessOperationHours}
                    changeDateTime={shop.changeDateTime}
                  />
                );
              }}
            </EditShopBusinessOperationHourFormItem.NonProperty>
          </Form>
        </FormContent>
        <Spacer size={32} />
        <Footer>
          <Button onClick={onClose}>キャンセル</Button>
          <Button
            type="primary"
            onClick={loading ? undefined : handleSubmit}
            loading={loading}
            disabled={loading}
          >
            更新
          </Button>
        </Footer>
      </>
    );
  },
);
