import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlansGetPlans = gql`
    query PlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    description
    imageUrl
    planId
    id
    planName
    shopSideName
    receiptDisplayName
    planTime
    displayType
    featuredLabelText
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    orderableTime {
      id
      name
    }
    planGroup {
      name
    }
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;
export const PlansGetCategories = gql`
    query PlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const PlansGetShops = gql`
    query PlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const PlansArchivePlan = gql`
    mutation PlansArchivePlan($input: ArchivePlanInput!) {
  archivePlan(input: $input) {
    result
  }
}
    `;
export type PlansGetPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { plans: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'totalDealingShopCount' | 'description' | 'imageUrl' | 'planId' | 'id' | 'planName' | 'shopSideName' | 'receiptDisplayName' | 'planTime' | 'displayType' | 'featuredLabelText'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'id'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'name' | 'planOptionId'>
    )>, orderableTime: (
      { __typename?: 'orderableTime' }
      & Pick<Types.OrderableTime, 'id' | 'name'>
    ), planGroup?: Types.Maybe<(
      { __typename?: 'planGroup' }
      & Pick<Types.PlanGroup, 'name'>
    )>, planMenuCategories: Array<(
      { __typename?: 'planMenuCategory' }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
      ) }
    )> }
  )> }
);

export type PlansGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PlansGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type PlansGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PlansGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type PlansArchivePlanMutationVariables = Types.Exact<{
  input: Types.ArchivePlanInput;
}>;


export type PlansArchivePlanMutation = (
  { __typename?: 'mutation_root' }
  & { archivePlan: (
    { __typename?: 'ArchivePlanResult' }
    & Pick<Types.ArchivePlanResult, 'result'>
  ) }
);


export const PlansGetPlansDocument = gql`
    query PlansGetPlans($companyId: uuid!) {
  plans: plan(
    order_by: [{priority: asc}, {planId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
  ) {
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    description
    imageUrl
    planId
    id
    planName
    shopSideName
    receiptDisplayName
    planTime
    displayType
    featuredLabelText
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      name
      planOptionId
    }
    orderableTime {
      id
      name
    }
    planGroup {
      name
    }
    planMenuCategories(where: {category: {archivedAt: {_is_null: true}}}) {
      category {
        categoryId
        name
        shopSideName
      }
    }
  }
}
    `;

/**
 * __usePlansGetPlansQuery__
 *
 * To run a query within a React component, call `usePlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansGetPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<PlansGetPlansQuery, PlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansGetPlansQuery, PlansGetPlansQueryVariables>(PlansGetPlansDocument, options);
      }
export function usePlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansGetPlansQuery, PlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansGetPlansQuery, PlansGetPlansQueryVariables>(PlansGetPlansDocument, options);
        }
export type PlansGetPlansQueryHookResult = ReturnType<typeof usePlansGetPlansQuery>;
export type PlansGetPlansLazyQueryHookResult = ReturnType<typeof usePlansGetPlansLazyQuery>;
export type PlansGetPlansQueryResult = Apollo.QueryResult<PlansGetPlansQuery, PlansGetPlansQueryVariables>;
export const PlansGetCategoriesDocument = gql`
    query PlansGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __usePlansGetCategoriesQuery__
 *
 * To run a query within a React component, call `usePlansGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePlansGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PlansGetCategoriesQuery, PlansGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansGetCategoriesQuery, PlansGetCategoriesQueryVariables>(PlansGetCategoriesDocument, options);
      }
export function usePlansGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansGetCategoriesQuery, PlansGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansGetCategoriesQuery, PlansGetCategoriesQueryVariables>(PlansGetCategoriesDocument, options);
        }
export type PlansGetCategoriesQueryHookResult = ReturnType<typeof usePlansGetCategoriesQuery>;
export type PlansGetCategoriesLazyQueryHookResult = ReturnType<typeof usePlansGetCategoriesLazyQuery>;
export type PlansGetCategoriesQueryResult = Apollo.QueryResult<PlansGetCategoriesQuery, PlansGetCategoriesQueryVariables>;
export const PlansGetShopsDocument = gql`
    query PlansGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __usePlansGetShopsQuery__
 *
 * To run a query within a React component, call `usePlansGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePlansGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PlansGetShopsQuery, PlansGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansGetShopsQuery, PlansGetShopsQueryVariables>(PlansGetShopsDocument, options);
      }
export function usePlansGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansGetShopsQuery, PlansGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansGetShopsQuery, PlansGetShopsQueryVariables>(PlansGetShopsDocument, options);
        }
export type PlansGetShopsQueryHookResult = ReturnType<typeof usePlansGetShopsQuery>;
export type PlansGetShopsLazyQueryHookResult = ReturnType<typeof usePlansGetShopsLazyQuery>;
export type PlansGetShopsQueryResult = Apollo.QueryResult<PlansGetShopsQuery, PlansGetShopsQueryVariables>;
export const PlansArchivePlanDocument = gql`
    mutation PlansArchivePlan($input: ArchivePlanInput!) {
  archivePlan(input: $input) {
    result
  }
}
    `;
export type PlansArchivePlanMutationFn = Apollo.MutationFunction<PlansArchivePlanMutation, PlansArchivePlanMutationVariables>;

/**
 * __usePlansArchivePlanMutation__
 *
 * To run a mutation, you first call `usePlansArchivePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlansArchivePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plansArchivePlanMutation, { data, loading, error }] = usePlansArchivePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlansArchivePlanMutation(baseOptions?: Apollo.MutationHookOptions<PlansArchivePlanMutation, PlansArchivePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlansArchivePlanMutation, PlansArchivePlanMutationVariables>(PlansArchivePlanDocument, options);
      }
export type PlansArchivePlanMutationHookResult = ReturnType<typeof usePlansArchivePlanMutation>;
export type PlansArchivePlanMutationResult = Apollo.MutationResult<PlansArchivePlanMutation>;
export type PlansArchivePlanMutationOptions = Apollo.BaseMutationOptions<PlansArchivePlanMutation, PlansArchivePlanMutationVariables>;