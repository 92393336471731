import React from "react";
import styled from "styled-components";
import { Empty, QRCode } from "antd";
import { getRole } from "models/user";
import { getSelfWebUrl } from "util/selfWeb";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { colors, grey } from "constants/colors";
import { useShop } from "hooks/useShop";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

const getPreviewSettingsUrl = (shopId: string) =>
  getSelfWebUrl(`shops/${shopId}/items/preview/settings`);

const Container = styled.div`
  background-color: ${grey[0]};
  padding: 40px;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const TitleContainer = styled.h3`
  font-weight: bold;
  margin-bottom: 16px;
`;

const PreviewUrlAnchor = styled.a`
  color: ${colors.Text.Secondary};
`;

export const PreviewUserApp = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;
  const user = useCurrentUser();
  const role = getRole(user);
  const url = shopId ? getPreviewSettingsUrl(shopId) : null;

  return (
    <DashboardLayout title="アプリ表示確認">
      <PageHeader title="アプリ表示確認" footer={<ShopSelector />} />
      <Container>
        {shopId && url ? (
          <ContentsContainer>
            <QRCode value={url} data-cy="previewUserAppQrCode" />
            <TextContainer>
              <TitleContainer>アプリ表示確認用QRコード</TitleContainer>
              <div>表示されているQRコードを読み取るとプレビューが試せます。</div>
              <div>※こちらのQRコードは表示確認用のものです。実際の注文などは行えません。</div>
              <Spacer size={20} />
              {role === Role.ServiceAdmin && <PreviewUrlAnchor href={url}>{url}</PreviewUrlAnchor>}
            </TextContainer>
          </ContentsContainer>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="店舗を選択してください" />
        )}
      </Container>
    </DashboardLayout>
  );
};
