import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories = gql`
    query AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories {
  onSitePaymentDetailTypeCategoryPriority(order_by: {priority: asc}) {
    value
  }
}
    `;
export const AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriority = gql`
    query AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriority($companyId: uuid!) {
  onSitePaymentDetailType_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailType = gql`
    mutation AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailType($onSitePaymentDetailType: onSitePaymentDetailType_insert_input!) {
  insert_onSitePaymentDetailType_one(object: $onSitePaymentDetailType) {
    __typename
  }
}
    `;
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailTypeCategoryPriority: Array<(
    { __typename?: 'onSitePaymentDetailTypeCategoryPriority' }
    & Pick<Types.OnSitePaymentDetailTypeCategoryPriority, 'value'>
  )> }
);

export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType_aggregate: (
    { __typename?: 'onSitePaymentDetailType_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'onSitePaymentDetailType_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'onSitePaymentDetailType_max_fields' }
        & Pick<Types.OnSitePaymentDetailTypeMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  onSitePaymentDetailType: Types.OnSitePaymentDetailTypeInsertInput;
}>;


export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onSitePaymentDetailType_one?: Types.Maybe<{ __typename: 'onSitePaymentDetailType' }> }
);


export const AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument = gql`
    query AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategories {
  onSitePaymentDetailTypeCategoryPriority(order_by: {priority: asc}) {
    value
  }
}
    `;

/**
 * __useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery__
 *
 * To run a query within a React component, call `useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>(AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument, options);
      }
export function useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>(AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesDocument, options);
        }
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryHookResult = ReturnType<typeof useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery>;
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQueryHookResult = ReturnType<typeof useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesLazyQuery>;
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryResult = Apollo.QueryResult<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQueryVariables>;
export const AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityDocument = gql`
    query AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriority($companyId: uuid!) {
  onSitePaymentDetailType_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables>(AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityDocument, options);
      }
export function useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables>(AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityDocument, options);
        }
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryHookResult = ReturnType<typeof useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery>;
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityLazyQuery>;
export type AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryResult = Apollo.QueryResult<AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery, AddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQueryVariables>;
export const AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeDocument = gql`
    mutation AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailType($onSitePaymentDetailType: onSitePaymentDetailType_insert_input!) {
  insert_onSitePaymentDetailType_one(object: $onSitePaymentDetailType) {
    __typename
  }
}
    `;
export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation, AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation, { data, loading, error }] = useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      onSitePaymentDetailType: // value for 'onSitePaymentDetailType'
 *   },
 * });
 */
export function useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation, AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation, AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationVariables>(AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeDocument, options);
      }
export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation>;
export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation>;
export type AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation, AddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutationVariables>;