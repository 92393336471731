export enum RepeaterTableType {
  repeaterTables = "repeaterTables",
  nonRepeaterTables = "nonRepeaterTables",
  allTables = "allTables",
}

export enum ReportByType {
  category = "カテゴリ別",
  menu = "商品別",
  menuType = "メニュータイプ別",
}

export type SortKey =
  | "orderedQuantity"
  | "taxExcludedAmount"
  | "taxExcludedNetProfitAmount"
  | "taxIncludedAmount"
  | "taxIncludedNetProfitAmount"
  | "repeaterPercentage";

type SortDirection = "ascend" | "descend";

export type Sort = {
  column: SortKey;
  direction: SortDirection;
};

export type RawMenuOrderItemAnalyticsMenuRow = {
  categoryId: string;
  menuId: string;
  menuName: string;
  menuType: string | null;
  categoryName: string;
  totalTaxIncludedAmount: number;
  totalTaxExcludedAmount: number;
  totalTaxIncludedCostAmount: number;
  totalTaxExcludedCostAmount: number;
  totalTaxIncludedNetProfitAmount: number;
  totalTaxExcludedNetProfitAmount: number;
  orderedQuantity: number;
  repeatOrderQuantity: number;
  firstTimeOrderQuantity: number;
};

export type RawMenuOrderItemAnalyticsRow = RawMenuOrderItemAnalyticsMenuRow & {
  businessOperationHourType: string;
  isRepeaterTable: boolean;
};

export type NormalizedMenuOrderItemAnalyticsInputRow = {
  id: string;
  name: string;
  totalTaxIncludedAmount: number;
  totalTaxExcludedAmount: number;
  totalTaxIncludedCostAmount: number;
  totalTaxExcludedCostAmount: number;
  totalTaxIncludedNetProfitAmount: number;
  totalTaxExcludedNetProfitAmount: number;
  orderedQuantity: number;
  repeatOrderQuantity: number;
  firstTimeOrderQuantity: number;
};

export type AbcRank = "A" | "B" | "C";

export type MenuOrderItemAnalyticsField = {
  actualValue: number;
  actualPercentage: number;
  cumulativeValue: number;
  cumulativePercentage: number;
  abcRank: AbcRank;
};

export type MenuOrderItemAnalyticsRow = {
  // menu id, plan id, category id, or menuType depending on reportingType
  id: string;
  // menu name, plan name, category name, or menuType label depending on reportingType
  name: string;
  orderedQuantity: MenuOrderItemAnalyticsField;
  repeatOrderQuantity: MenuOrderItemAnalyticsField;
  firstTimeOrderQuantity: MenuOrderItemAnalyticsField;
  taxIncludedAmount: MenuOrderItemAnalyticsField;
  taxExcludedAmount: MenuOrderItemAnalyticsField;
  taxIncludedNetProfitAmount: MenuOrderItemAnalyticsField;
  taxExcludedNetProfitAmount: MenuOrderItemAnalyticsField;
  repeaterPercentage: number;
};

export type MenuOrderItemAnalyticsRowWithSortedIndex = MenuOrderItemAnalyticsRow & {
  sortedIndex: number;
};
