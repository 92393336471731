import React, { memo } from "react";
import { NamePath } from "antd/es/form/interface";

import { FormList } from "components/antd/Form";
import { locales } from "constants/locales";

import { FeaturedLabelTextField } from "./FeaturedLabelTextField";
import { OriginalFeaturedLabelTextField } from "./OriginalFeatureLabelTextField";

type Props = {
  originalFieldName: NamePath;
  label: string;
};

export const TranslationFeaturedLabelTextFields = memo<Props>(({ originalFieldName, label }) => (
  <>
    <OriginalFeaturedLabelTextField label={`${label}：原文`} name={originalFieldName} />
    <FormList
      name="featureLabelSources"
      initialValue={locales.map(({ code }) => ({ locale: code, value: null }))}
    >
      {(fields) =>
        fields.map((field, ind) => (
          <FeaturedLabelTextField
            key={field.key}
            name={[field.name, "value"]}
            label={`${label}：${locales?.[ind]?.label}`}
          />
        ))
      }
    </FormList>
  </>
));
