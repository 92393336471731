import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditConnectGameConfigGetConnectGameConfig = gql`
    query EditConnectGameConfigGetConnectGameConfig($id: uuid, $companyId: uuid!) {
  connectGameConfig(where: {id: {_eq: $id}, companyId: {_eq: $companyId}}) {
    id
    enabled
    maxWinningRate
    startTime
    endTime
    startImageUrl
    prizeCouponId
    consolationCouponId
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;
export const EditConnectGameConfigGetCoupons = gql`
    query EditConnectGameConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditConnectGameConfigGetShops = gql`
    query EditConnectGameConfigGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export const EditConnectGameConfigGetConnectGameConfigShops = gql`
    query EditConnectGameConfigGetConnectGameConfigShops($companyId: uuid!) {
  connectGameConfig(where: {enabled: {_eq: true}, companyId: {_eq: $companyId}}) {
    id
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;
export const EditConnectGameConfigUpdateConnectGameConfig = gql`
    mutation EditConnectGameConfigUpdateConnectGameConfig($id: uuid!, $connectGameConfig: connectGameConfig_set_input!, $deletedShopIds: [uuid!]!, $connectGameConfigShops: [connectGameConfigShop_insert_input!]!) {
  update_connectGameConfig(_set: $connectGameConfig, where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_connectGameConfigShop(
    where: {connectGameConfigId: {_eq: $id}, shopId: {_in: $deletedShopIds}}
  ) {
    affected_rows
  }
  insert_connectGameConfigShop(
    objects: $connectGameConfigShops
    on_conflict: {constraint: connectGameConfigShop_shopId_connectGameConfigId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditConnectGameConfigGetConnectGameConfigQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']>;
  companyId: Types.Scalars['uuid'];
}>;


export type EditConnectGameConfigGetConnectGameConfigQuery = (
  { __typename?: 'query_root' }
  & { connectGameConfig: Array<(
    { __typename?: 'connectGameConfig' }
    & Pick<Types.ConnectGameConfig, 'id' | 'enabled' | 'maxWinningRate' | 'startTime' | 'endTime' | 'startImageUrl' | 'prizeCouponId' | 'consolationCouponId'>
    & { connectGameConfigShops: Array<(
      { __typename?: 'connectGameConfigShop' }
      & Pick<Types.ConnectGameConfigShop, 'id'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
        & { id: Types.Shop['shopId'] }
      ) }
    )> }
  )> }
);

export type EditConnectGameConfigGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditConnectGameConfigGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type EditConnectGameConfigGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditConnectGameConfigGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditConnectGameConfigGetConnectGameConfigShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditConnectGameConfigGetConnectGameConfigShopsQuery = (
  { __typename?: 'query_root' }
  & { connectGameConfig: Array<(
    { __typename?: 'connectGameConfig' }
    & Pick<Types.ConnectGameConfig, 'id'>
    & { connectGameConfigShops: Array<(
      { __typename?: 'connectGameConfigShop' }
      & Pick<Types.ConnectGameConfigShop, 'id'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
        & { id: Types.Shop['shopId'] }
      ) }
    )> }
  )> }
);

export type EditConnectGameConfigUpdateConnectGameConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  connectGameConfig: Types.ConnectGameConfigSetInput;
  deletedShopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  connectGameConfigShops: Array<Types.ConnectGameConfigShopInsertInput> | Types.ConnectGameConfigShopInsertInput;
}>;


export type EditConnectGameConfigUpdateConnectGameConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_connectGameConfig?: Types.Maybe<(
    { __typename?: 'connectGameConfig_mutation_response' }
    & Pick<Types.ConnectGameConfigMutationResponse, 'affected_rows'>
  )>, delete_connectGameConfigShop?: Types.Maybe<(
    { __typename?: 'connectGameConfigShop_mutation_response' }
    & Pick<Types.ConnectGameConfigShopMutationResponse, 'affected_rows'>
  )>, insert_connectGameConfigShop?: Types.Maybe<(
    { __typename?: 'connectGameConfigShop_mutation_response' }
    & Pick<Types.ConnectGameConfigShopMutationResponse, 'affected_rows'>
  )> }
);


export const EditConnectGameConfigGetConnectGameConfigDocument = gql`
    query EditConnectGameConfigGetConnectGameConfig($id: uuid, $companyId: uuid!) {
  connectGameConfig(where: {id: {_eq: $id}, companyId: {_eq: $companyId}}) {
    id
    enabled
    maxWinningRate
    startTime
    endTime
    startImageUrl
    prizeCouponId
    consolationCouponId
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;

/**
 * __useEditConnectGameConfigGetConnectGameConfigQuery__
 *
 * To run a query within a React component, call `useEditConnectGameConfigGetConnectGameConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditConnectGameConfigGetConnectGameConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditConnectGameConfigGetConnectGameConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditConnectGameConfigGetConnectGameConfigQuery(baseOptions: Apollo.QueryHookOptions<EditConnectGameConfigGetConnectGameConfigQuery, EditConnectGameConfigGetConnectGameConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditConnectGameConfigGetConnectGameConfigQuery, EditConnectGameConfigGetConnectGameConfigQueryVariables>(EditConnectGameConfigGetConnectGameConfigDocument, options);
      }
export function useEditConnectGameConfigGetConnectGameConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditConnectGameConfigGetConnectGameConfigQuery, EditConnectGameConfigGetConnectGameConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditConnectGameConfigGetConnectGameConfigQuery, EditConnectGameConfigGetConnectGameConfigQueryVariables>(EditConnectGameConfigGetConnectGameConfigDocument, options);
        }
export type EditConnectGameConfigGetConnectGameConfigQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetConnectGameConfigQuery>;
export type EditConnectGameConfigGetConnectGameConfigLazyQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetConnectGameConfigLazyQuery>;
export type EditConnectGameConfigGetConnectGameConfigQueryResult = Apollo.QueryResult<EditConnectGameConfigGetConnectGameConfigQuery, EditConnectGameConfigGetConnectGameConfigQueryVariables>;
export const EditConnectGameConfigGetCouponsDocument = gql`
    query EditConnectGameConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditConnectGameConfigGetCouponsQuery__
 *
 * To run a query within a React component, call `useEditConnectGameConfigGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditConnectGameConfigGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditConnectGameConfigGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditConnectGameConfigGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<EditConnectGameConfigGetCouponsQuery, EditConnectGameConfigGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditConnectGameConfigGetCouponsQuery, EditConnectGameConfigGetCouponsQueryVariables>(EditConnectGameConfigGetCouponsDocument, options);
      }
export function useEditConnectGameConfigGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditConnectGameConfigGetCouponsQuery, EditConnectGameConfigGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditConnectGameConfigGetCouponsQuery, EditConnectGameConfigGetCouponsQueryVariables>(EditConnectGameConfigGetCouponsDocument, options);
        }
export type EditConnectGameConfigGetCouponsQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetCouponsQuery>;
export type EditConnectGameConfigGetCouponsLazyQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetCouponsLazyQuery>;
export type EditConnectGameConfigGetCouponsQueryResult = Apollo.QueryResult<EditConnectGameConfigGetCouponsQuery, EditConnectGameConfigGetCouponsQueryVariables>;
export const EditConnectGameConfigGetShopsDocument = gql`
    query EditConnectGameConfigGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useEditConnectGameConfigGetShopsQuery__
 *
 * To run a query within a React component, call `useEditConnectGameConfigGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditConnectGameConfigGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditConnectGameConfigGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditConnectGameConfigGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditConnectGameConfigGetShopsQuery, EditConnectGameConfigGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditConnectGameConfigGetShopsQuery, EditConnectGameConfigGetShopsQueryVariables>(EditConnectGameConfigGetShopsDocument, options);
      }
export function useEditConnectGameConfigGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditConnectGameConfigGetShopsQuery, EditConnectGameConfigGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditConnectGameConfigGetShopsQuery, EditConnectGameConfigGetShopsQueryVariables>(EditConnectGameConfigGetShopsDocument, options);
        }
export type EditConnectGameConfigGetShopsQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetShopsQuery>;
export type EditConnectGameConfigGetShopsLazyQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetShopsLazyQuery>;
export type EditConnectGameConfigGetShopsQueryResult = Apollo.QueryResult<EditConnectGameConfigGetShopsQuery, EditConnectGameConfigGetShopsQueryVariables>;
export const EditConnectGameConfigGetConnectGameConfigShopsDocument = gql`
    query EditConnectGameConfigGetConnectGameConfigShops($companyId: uuid!) {
  connectGameConfig(where: {enabled: {_eq: true}, companyId: {_eq: $companyId}}) {
    id
    connectGameConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shop {
        id: shopId
        name
      }
    }
  }
}
    `;

/**
 * __useEditConnectGameConfigGetConnectGameConfigShopsQuery__
 *
 * To run a query within a React component, call `useEditConnectGameConfigGetConnectGameConfigShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditConnectGameConfigGetConnectGameConfigShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditConnectGameConfigGetConnectGameConfigShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditConnectGameConfigGetConnectGameConfigShopsQuery(baseOptions: Apollo.QueryHookOptions<EditConnectGameConfigGetConnectGameConfigShopsQuery, EditConnectGameConfigGetConnectGameConfigShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditConnectGameConfigGetConnectGameConfigShopsQuery, EditConnectGameConfigGetConnectGameConfigShopsQueryVariables>(EditConnectGameConfigGetConnectGameConfigShopsDocument, options);
      }
export function useEditConnectGameConfigGetConnectGameConfigShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditConnectGameConfigGetConnectGameConfigShopsQuery, EditConnectGameConfigGetConnectGameConfigShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditConnectGameConfigGetConnectGameConfigShopsQuery, EditConnectGameConfigGetConnectGameConfigShopsQueryVariables>(EditConnectGameConfigGetConnectGameConfigShopsDocument, options);
        }
export type EditConnectGameConfigGetConnectGameConfigShopsQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetConnectGameConfigShopsQuery>;
export type EditConnectGameConfigGetConnectGameConfigShopsLazyQueryHookResult = ReturnType<typeof useEditConnectGameConfigGetConnectGameConfigShopsLazyQuery>;
export type EditConnectGameConfigGetConnectGameConfigShopsQueryResult = Apollo.QueryResult<EditConnectGameConfigGetConnectGameConfigShopsQuery, EditConnectGameConfigGetConnectGameConfigShopsQueryVariables>;
export const EditConnectGameConfigUpdateConnectGameConfigDocument = gql`
    mutation EditConnectGameConfigUpdateConnectGameConfig($id: uuid!, $connectGameConfig: connectGameConfig_set_input!, $deletedShopIds: [uuid!]!, $connectGameConfigShops: [connectGameConfigShop_insert_input!]!) {
  update_connectGameConfig(_set: $connectGameConfig, where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_connectGameConfigShop(
    where: {connectGameConfigId: {_eq: $id}, shopId: {_in: $deletedShopIds}}
  ) {
    affected_rows
  }
  insert_connectGameConfigShop(
    objects: $connectGameConfigShops
    on_conflict: {constraint: connectGameConfigShop_shopId_connectGameConfigId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditConnectGameConfigUpdateConnectGameConfigMutationFn = Apollo.MutationFunction<EditConnectGameConfigUpdateConnectGameConfigMutation, EditConnectGameConfigUpdateConnectGameConfigMutationVariables>;

/**
 * __useEditConnectGameConfigUpdateConnectGameConfigMutation__
 *
 * To run a mutation, you first call `useEditConnectGameConfigUpdateConnectGameConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConnectGameConfigUpdateConnectGameConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConnectGameConfigUpdateConnectGameConfigMutation, { data, loading, error }] = useEditConnectGameConfigUpdateConnectGameConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      connectGameConfig: // value for 'connectGameConfig'
 *      deletedShopIds: // value for 'deletedShopIds'
 *      connectGameConfigShops: // value for 'connectGameConfigShops'
 *   },
 * });
 */
export function useEditConnectGameConfigUpdateConnectGameConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditConnectGameConfigUpdateConnectGameConfigMutation, EditConnectGameConfigUpdateConnectGameConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditConnectGameConfigUpdateConnectGameConfigMutation, EditConnectGameConfigUpdateConnectGameConfigMutationVariables>(EditConnectGameConfigUpdateConnectGameConfigDocument, options);
      }
export type EditConnectGameConfigUpdateConnectGameConfigMutationHookResult = ReturnType<typeof useEditConnectGameConfigUpdateConnectGameConfigMutation>;
export type EditConnectGameConfigUpdateConnectGameConfigMutationResult = Apollo.MutationResult<EditConnectGameConfigUpdateConnectGameConfigMutation>;
export type EditConnectGameConfigUpdateConnectGameConfigMutationOptions = Apollo.BaseMutationOptions<EditConnectGameConfigUpdateConnectGameConfigMutation, EditConnectGameConfigUpdateConnectGameConfigMutationVariables>;