import React, { memo } from "react";
import styled from "styled-components";

import { withFormDependencies } from "components/antd/Form";
import { colors } from "constants/colors";
import { DisplayTypeField } from "pages/EditMenu/EditMenuForm/DisplayTypeFields/DisplayTypeField";

import { EditMenuFormItem } from "../useEditMenuForm";

const Title = styled.div`
  background-color: ${colors.BackGround.Tertiary};
  height: 32px;
  padding: 4px 8px;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 16px;
`;

type Props = {
  categoryMaps: Record<number, string>;
  defaultCategoryId: number;
  disabled?: boolean;
};

export const DisplayTypeFields = memo<Props>(({ categoryMaps, defaultCategoryId, disabled }) => (
  <EditMenuFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ categoryIds }) => [categoryIds])}
    noStyle
  >
    {({ getFieldValue }) => {
      const [firstCategoryId, ...restCategoryIds]: [number | undefined, ...number[]] =
        getFieldValue("categoryIds") ?? [];

      const shouldShowFirstCategorySubTitle =
        typeof firstCategoryId === "number" && restCategoryIds.length !== 0;

      return (
        <>
          {shouldShowFirstCategorySubTitle && firstCategoryId && (
            <Title>{categoryMaps[firstCategoryId]}</Title>
          )}
          <DisplayTypeField categoryId={firstCategoryId ?? defaultCategoryId} disabled={disabled} />

          {restCategoryIds.map((categoryId) => (
            <React.Fragment key={categoryId}>
              <Title>{categoryMaps[categoryId]}</Title>
              <DisplayTypeField key={categoryId} categoryId={categoryId} disabled={disabled} />
            </React.Fragment>
          ))}
        </>
      );
    }}
  </EditMenuFormItem.NonProperty>
));
