import React, { memo, useCallback, useState } from "react";
import { Button, Modal } from "antd";
import { isNumber } from "util/type/primitive";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { EditBankDepositAmountForm } from "pages/ShopDailyCashRegisterBalancing/EditBankDepositAmountModal/EditBankDepositAmountForm";
import { EditBankDepositAmountFormValues } from "pages/ShopDailyCashRegisterBalancing/EditBankDepositAmountModal/EditBankDepositAmountForm/useEditBankDepositAmountForm";
import { useEditBankDepositAmountModalUpdateBankDepositAmountMutation } from "pages/ShopDailyCashRegisterBalancing/EditBankDepositAmountModal/queries";
import { CashManagement } from "pages/ShopDailyCashRegisterBalancing/types";

type Props = {
  loading: boolean;
  cashManagement?: CashManagement;
  goBack: () => void;
  onUpdated: () => void;
};

export const EditBankDepositAmountModal = memo<Props>(
  ({ loading, cashManagement, goBack, onUpdated }) => {
    const [updateBankDepositAmountMutation] =
      useEditBankDepositAmountModalUpdateBankDepositAmountMutation();

    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<EditBankDepositAmountFormValues | null>(null);
    const onOk = useCallback(async () => {
      const closeCashRegisterId = cashManagement?.closeCashRegisterId;

      if (closeCashRegisterId && formValues) {
        try {
          await form.validateFields();

          const { bankDepositAmount } = formValues;

          await updateBankDepositAmountMutation({
            variables: { input: { closeCashRegisterId, bankDepositAmount } },
          });

          onUpdated();

          message.success("編集を保存しました");
        } catch (err) {
          return message.error("編集の保存に失敗しました");
        }
      }

      goBack();
    }, [cashManagement, form, formValues, updateBankDepositAmountMutation, onUpdated, goBack]);
    const onCancel = goBack;

    return (
      <Modal
        title="銀行預入金額修正"
        open
        width={900}
        onOk={goBack}
        onCancel={goBack}
        footer={[
          <Button key="save" type="primary" onClick={onOk} disabled={loading} loading={false}>
            保存
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>,
        ]}
      >
        {loading && <Loading fullWidth height={300} />}
        {cashManagement && isNumber(cashManagement.bankDepositAmount) && (
          <EditBankDepositAmountForm
            form={form}
            bankDepositAmount={cashManagement.bankDepositAmount}
            onChange={setFormValues}
          />
        )}
      </Modal>
    );
  },
);
