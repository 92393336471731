import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanChoiceWinboardMenuFormItem } from "../useEditPlanChoiceWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditPlanChoiceWinboardMenuFormItem label="メニュー名称" name="name" {...props}>
    <Input />
  </EditPlanChoiceWinboardMenuFormItem>
));
