import React, { memo } from "react";
import styled, { css } from "styled-components";
import { List, Row } from "antd";

import { Spacer } from "components/Spacer";
import { NameCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/NameCell";
import { RankCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/RankCell";
import { ScoreCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/ScoreCell";
import { QuestionCategoryType } from "types/graphql";

const TextCentering = styled.div`
  text-align: center;
`;

const AnswerCountText = styled.span<{ isInactive: boolean }>`
  font-weight: bold;
  ${(props) =>
    props.isInactive &&
    css`
      color: rgba(0, 0, 0, 0.4);
    `};
`;

const StyledListItem = styled(List.Item)`
  flex-direction: column;
  padding: 16px 4px;
`;

const Top = styled(Row)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Bottom = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px repeat(4, minmax(60px, 200px));
  gap: 4px;
`;

const SmallText = styled.span`
  font-size: 10px;
  padding-right: 4px;
`;

type ScoreWithFluctuation = {
  score: number;
  fluctuation: number;
};

export type DataSource = {
  name: string;
  answerCount: number;
  repeatability: ScoreWithFluctuation;
  customerService: ScoreWithFluctuation;
  deliciousness: ScoreWithFluctuation;
  speed: ScoreWithFluctuation;
  cleanliness: ScoreWithFluctuation;
} & (
  | { type: "shop"; ranking: number; shopId: string; isEmpty: boolean }
  | { type: "corporation"; corporationId: string }
  | { type: "dinii" }
);

const isShopTypeDataSource = (
  dataSource: DataSource,
): dataSource is DataSource & { type: "shop" } => "shopId" in dataSource;

type Props = {
  dataSource: DataSource[] | undefined;
};

export const ResponsiveScoreList = memo<Props>(({ dataSource }) => (
  <>
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(data) => (
        <StyledListItem>
          <Top>
            <Row align="middle">
              {isShopTypeDataSource(data) ? (
                data.isEmpty ? null : (
                  <RankCell rank={data.ranking} />
                )
              ) : (
                <img
                  src={`/questionnaire-analytics/shop_score_rank_${data.type}.png`}
                  alt={`rank ${data.type}`}
                  width="18"
                  height="18"
                />
              )}
              <Spacer size={8} />
              <NameCell
                name={data.name}
                shopId={isShopTypeDataSource(data) ? data.shopId : undefined}
                disabled={!isShopTypeDataSource(data)}
              />
            </Row>
            <TextCentering>
              <SmallText>回答数</SmallText>
              <AnswerCountText isInactive={false}>{data.answerCount}</AnswerCountText>
            </TextCentering>
          </Top>
          <Spacer size={12} />
          <Bottom>
            <Wrapper>
              <div>
                <ScoreCell
                  score={data.repeatability}
                  category={QuestionCategoryType.Repeatability}
                  showIcon
                  isInactive={!isShopTypeDataSource(data)}
                  isEmpty={isShopTypeDataSource(data) && data.isEmpty}
                />
              </div>
              <div>
                <ScoreCell
                  score={data.customerService}
                  category={QuestionCategoryType.CustomerService}
                  isInactive={!isShopTypeDataSource(data)}
                  isEmpty={isShopTypeDataSource(data) && data.isEmpty}
                />
              </div>
              <div>
                <ScoreCell
                  score={data.deliciousness}
                  category={QuestionCategoryType.Deliciousness}
                  isInactive={!isShopTypeDataSource(data)}
                  isEmpty={isShopTypeDataSource(data) && data.isEmpty}
                />
              </div>
              <div>
                <ScoreCell
                  score={data.speed}
                  category={QuestionCategoryType.Speed}
                  isInactive={!isShopTypeDataSource(data)}
                  isEmpty={isShopTypeDataSource(data) && data.isEmpty}
                />
              </div>
              <div>
                <ScoreCell
                  score={data.cleanliness}
                  category={QuestionCategoryType.Cleanliness}
                  isInactive={!isShopTypeDataSource(data)}
                  isEmpty={isShopTypeDataSource(data) && data.isEmpty}
                />
              </div>
            </Wrapper>
          </Bottom>
        </StyledListItem>
      )}
    />
  </>
));
