import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem, Form } from "components/antd/Form";

export type AddDishUpSlipGroupPlanFormValues = {
  plans: number[];
};

const getInitialValues = () => ({});

export const AddDishUpSlipGroupPlanFormItem = createFormItem<AddDishUpSlipGroupPlanFormValues>();

export const useAddDishUpSlipGroupPlanForm = (
  onChange: (plan: AddDishUpSlipGroupPlanFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as AddDishUpSlipGroupPlanFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { form, initialValues, change };
};
