import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddOrderableTimeGetOrderableTimes = gql`
    query AddOrderableTimeGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddOrderableTimeInsertOrderableTime = gql`
    mutation AddOrderableTimeInsertOrderableTime($orderableTimes: [orderableTime_insert_input!]!) {
  insert_orderableTime(objects: $orderableTimes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type AddOrderableTimeGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddOrderableTimeGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type AddOrderableTimeInsertOrderableTimeMutationVariables = Types.Exact<{
  orderableTimes: Array<Types.OrderableTimeInsertInput> | Types.OrderableTimeInsertInput;
}>;


export type AddOrderableTimeInsertOrderableTimeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_orderableTime?: Types.Maybe<(
    { __typename?: 'orderableTime_mutation_response' }
    & Pick<Types.OrderableTimeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'orderableTime' }
      & Pick<Types.OrderableTime, 'id'>
    )> }
  )> }
);


export const AddOrderableTimeGetOrderableTimesDocument = gql`
    query AddOrderableTimeGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddOrderableTimeGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useAddOrderableTimeGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOrderableTimeGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOrderableTimeGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddOrderableTimeGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<AddOrderableTimeGetOrderableTimesQuery, AddOrderableTimeGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOrderableTimeGetOrderableTimesQuery, AddOrderableTimeGetOrderableTimesQueryVariables>(AddOrderableTimeGetOrderableTimesDocument, options);
      }
export function useAddOrderableTimeGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOrderableTimeGetOrderableTimesQuery, AddOrderableTimeGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOrderableTimeGetOrderableTimesQuery, AddOrderableTimeGetOrderableTimesQueryVariables>(AddOrderableTimeGetOrderableTimesDocument, options);
        }
export type AddOrderableTimeGetOrderableTimesQueryHookResult = ReturnType<typeof useAddOrderableTimeGetOrderableTimesQuery>;
export type AddOrderableTimeGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useAddOrderableTimeGetOrderableTimesLazyQuery>;
export type AddOrderableTimeGetOrderableTimesQueryResult = Apollo.QueryResult<AddOrderableTimeGetOrderableTimesQuery, AddOrderableTimeGetOrderableTimesQueryVariables>;
export const AddOrderableTimeInsertOrderableTimeDocument = gql`
    mutation AddOrderableTimeInsertOrderableTime($orderableTimes: [orderableTime_insert_input!]!) {
  insert_orderableTime(objects: $orderableTimes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type AddOrderableTimeInsertOrderableTimeMutationFn = Apollo.MutationFunction<AddOrderableTimeInsertOrderableTimeMutation, AddOrderableTimeInsertOrderableTimeMutationVariables>;

/**
 * __useAddOrderableTimeInsertOrderableTimeMutation__
 *
 * To run a mutation, you first call `useAddOrderableTimeInsertOrderableTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderableTimeInsertOrderableTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderableTimeInsertOrderableTimeMutation, { data, loading, error }] = useAddOrderableTimeInsertOrderableTimeMutation({
 *   variables: {
 *      orderableTimes: // value for 'orderableTimes'
 *   },
 * });
 */
export function useAddOrderableTimeInsertOrderableTimeMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderableTimeInsertOrderableTimeMutation, AddOrderableTimeInsertOrderableTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderableTimeInsertOrderableTimeMutation, AddOrderableTimeInsertOrderableTimeMutationVariables>(AddOrderableTimeInsertOrderableTimeDocument, options);
      }
export type AddOrderableTimeInsertOrderableTimeMutationHookResult = ReturnType<typeof useAddOrderableTimeInsertOrderableTimeMutation>;
export type AddOrderableTimeInsertOrderableTimeMutationResult = Apollo.MutationResult<AddOrderableTimeInsertOrderableTimeMutation>;
export type AddOrderableTimeInsertOrderableTimeMutationOptions = Apollo.BaseMutationOptions<AddOrderableTimeInsertOrderableTimeMutation, AddOrderableTimeInsertOrderableTimeMutationVariables>;