import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Menu, MenuInsertInput } from "types/graphql";

export type AddGiftFormValues = Pick<
  Menu,
  "name" | "description" | "imageUrl" | "receiptDisplayName"
>;

export const AddGiftFormItem = createFormItem<AddGiftFormValues>();

export const useAddGiftForm = (
  onSubmit: ({
    menu,
  }: {
    menu: Pick<MenuInsertInput, "name" | "description" | "imageUrl" | "receiptDisplayName">;
  }) => void,
) => {
  const [form] = Form.useForm();

  const submit = useCallback(
    (imageUrl: string | null | undefined) => {
      const formValues = form.getFieldsValue() as AddGiftFormValues;

      onSubmit({
        menu: {
          ...formValues,
          imageUrl,
        },
      });
    },
    [form, onSubmit],
  );

  return { form, submit };
};
