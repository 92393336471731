import React, { memo } from "react";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrNull } from "hooks/useFilterConditions";

type Props = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  disabled?: boolean;
};

export const IsVisibleForCustomerField = memo<Props>(({ value, onChange, disabled }) => (
  <Select<string>
    showSearch
    allowClear
    placeholder="お客様への公開設定"
    value={typeof value === "boolean" ? String(value) : null}
    onChange={(isDisplay) => onChange(convertStringToBooleanOrNull(isDisplay))}
    optionFilterProp="label"
    options={[
      { label: "お客様に公開", value: "true" },
      { label: "お客様に非公開", value: "false" },
    ]}
    disabled={disabled}
  />
));
