import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCorporation } from "hooks/useCorporation";
import { EditDashboardAccountRoleForm } from "pages/EditDashboardAccountRole/EditDashboardAccountRoleForm";
import { DashboardAccountRoleFormValuesWithPermissions } from "pages/EditDashboardAccountRole/EditDashboardAccountRoleForm/useEditDashboardAccountRoleForm";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

import {
  useEditDashboardAccountRoleGetDashboardAccountRoleQuery,
  useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation,
} from "./queries";

export const EditDashboardAccountRole = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [updateDashboardAccountRoleMutation, { loading: loadingEditDashboardAccountRoleMutation }] =
    useEditDashboardAccountRoleUpdateDashboardAccountRoleMutation();

  const {
    data: dashboardAccountRoleData,
    error,
    loading: loadingDashboardAccountRole,
  } = useEditDashboardAccountRoleGetDashboardAccountRoleQuery(
    id ? { variables: { dashboardAccountRoleId: id } } : { skip: true },
  );
  const dashboardAccountRole = dashboardAccountRoleData?.dashboardAccountRole[0];

  const onSubmit = useCallback(
    async (formValues: DashboardAccountRoleFormValuesWithPermissions) => {
      if (!corporationId) return;
      if (!id) return;

      try {
        const { name, permissions } = formValues;
        await updateDashboardAccountRoleMutation({
          variables: {
            dashboardAccountRoleId: id,
            dashboardAccountRole: { corporationId, name },
            dashboardAccountRolePermissions: Object.entries(permissions).flatMap(
              ([feature, type]) => {
                // Edit 権限は View 権限も追加する
                if (type === DashboardAccountRolePermissionTypeEnum.Edit) {
                  return [
                    {
                      dashboardAccountRoleId: id,
                      feature: feature as DashboardFeatureEnum,
                      type,
                    },
                    {
                      dashboardAccountRoleId: id,
                      feature: feature as DashboardFeatureEnum,
                      type: DashboardAccountRolePermissionTypeEnum.View,
                    },
                  ];
                }
                if (type) {
                  return [
                    {
                      dashboardAccountRoleId: id,
                      feature: feature as DashboardFeatureEnum,
                      type,
                    },
                  ];
                }
                return [];
              },
            ),
          },
        });
        message.success("保存しました");
        goBack();
      } catch (err) {
        console.error(err);
        message.error("保存に失敗しました");
      }
    },
    [corporationId, id, updateDashboardAccountRoleMutation, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading = loadingEditDashboardAccountRoleMutation || loadingDashboardAccountRole;

  return (
    <DashboardLayout
      title="権限を編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "権限一覧" }],
      }}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {dashboardAccountRole && (
        <EditDashboardAccountRoleForm
          dashboardAccountRole={dashboardAccountRole}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
