import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableReserveCashSeparationField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label="レジ締めと準備金入力を分けて行う"
    name="enableReserveCashSeparation"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
