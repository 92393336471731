import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditCategoryTranslationsFormItem } from "./useEditCategoryTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>(({ ...props }) => (
  <EditCategoryTranslationsFormItem label="カテゴリ名：原文" name="name" {...props}>
    <Input disabled />
  </EditCategoryTranslationsFormItem>
));
