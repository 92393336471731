import { useCallback, useState } from "react";
import { Store } from "antd/lib/form/interface";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import {
  EditShopPlanRolesGetPlanRolesRoles,
  useEditShopPlanRolesDeletePlanRolesRoleMutation,
  useEditShopPlanRolesInsertPlanRolesRoleMutation,
} from "pages/EditShopPlanRoles/queries";
import { Plan, Role } from "pages/EditShopPlanRoles/types";
import { ShopPlanKitchenRoleInsertInput } from "types/graphql";

export type EditPlanRoleFormValues = Record<string, boolean>;

const getInitialValues = (planRoles: Role[], roles: Role[]) => {
  const planRolesRoleIds = planRoles.map(({ id }) => id);
  return Object.fromEntries(roles.map(({ id }) => [id, planRolesRoleIds.includes(id)]));
};

const useLoadings = (roles: Role[]) => {
  const initialLoadings = Object.fromEntries<boolean>(roles.map(({ id }) => [id, false]));
  const [loadings, setLoadings] = useState(initialLoadings);

  const onLoad = useCallback(
    (roleId: string, state: boolean) =>
      setLoadings((loadings) => ({ ...loadings, [roleId]: state })),
    [],
  );

  const onRequest = useCallback((roleId: string) => onLoad(roleId, true), [onLoad]);
  const onFinish = useCallback((roleId: string) => onLoad(roleId, false), [onLoad]);

  return { loadings, onRequest, onFinish };
};

export const EditPlanRoleFormItem = createFormItem<EditPlanRoleFormValues>();

export const useEditPlanRoleForm = (
  shopId: string,
  plan: Plan,
  planRoles: Role[],
  roles: Role[],
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(planRoles, roles);

  const { loadings, onRequest, onFinish } = useLoadings(roles);

  const [insertPlanRolesRoleMutation] = useEditShopPlanRolesInsertPlanRolesRoleMutation();

  const insertPlanRolesRole = useCallback(
    async (roleId: string) => {
      try {
        onRequest(roleId);

        const role = roles.find((role) => role.id === roleId);

        if (!role) throw new Error("role not found");

        const shopPlanKitchenRoles: ShopPlanKitchenRoleInsertInput[] = [
          { planId: plan.id, _planId: plan.planId, roleId: role.id, _roleId: role.roleId, shopId },
        ];

        await insertPlanRolesRoleMutation({
          variables: { shopPlanKitchenRoles },
          refetchQueries: [
            {
              query: EditShopPlanRolesGetPlanRolesRoles,
              variables: { planId: plan.planId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [onRequest, roles, plan, shopId, insertPlanRolesRoleMutation, onFinish],
  );

  const [deletePlanRolesRoleMutation] = useEditShopPlanRolesDeletePlanRolesRoleMutation();

  const deletePlanRolesRole = useCallback(
    async (roleId: string) => {
      try {
        onRequest(roleId);

        await deletePlanRolesRoleMutation({
          variables: { planId: plan.planId, roleId },
          refetchQueries: [
            {
              query: EditShopPlanRolesGetPlanRolesRoles,
              variables: { planId: plan.planId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(roleId);
      }
    },
    [shopId, plan, deletePlanRolesRoleMutation, onRequest, onFinish],
  );

  const change = useCallback(
    (changedValues: Store) => {
      const values = changedValues as EditPlanRoleFormValues;

      for (const [roleId, state] of Object.entries(values)) {
        if (state) {
          insertPlanRolesRole(roleId);
        } else {
          deletePlanRolesRole(roleId);
        }
      }
    },
    [insertPlanRolesRole, deletePlanRolesRole],
  );

  return { form, initialValues, change, loadings };
};
