import React, { memo } from "react";
import { DatePicker } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCouponFormItem } from "../useEditCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AvailableFromField = memo<Props>((props) => (
  <EditCouponFormItem
    label="開始日時"
    name="availableFrom"
    rules={[{ required: true, message: "クーポンの開始日時を入力してください" }]}
    {...props}
  >
    <DatePicker showTime={{ format: "HH:mm" }} format="YYYY-MM-DD HH:mm" />
  </EditCouponFormItem>
));
