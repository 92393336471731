import React, { memo } from "react";
import { formatCashDenominationCount } from "models/cashDenominationCount";

import { Table } from "components/Table";
import { CashBalance } from "pages/ShopDailyCashRegisterBalancing/types";

type Props = {
  loading?: boolean;
  cashBalance?: CashBalance;
};

const cashDenominationCountTypes = [
  "tenThousandYenBillCount",
  "fiveThousandYenBillCount",
  "twoThousandYenBillCount",
  "oneThousandYenBillCount",
  "fiveHundredYenCoinCount",
  "oneHundredYenCoinCount",
  "fiftyYenCoinCount",
  "tenYenCoinCount",
  "fiveYenCoinCount",
  "oneYenCoinCount",
] as const;

export const CashBalanceTable = memo<Props>(({ loading, cashBalance }) => {
  const cashDenominationCounts = cashBalance
    ? cashDenominationCountTypes.map((type) => ({ type, amount: cashBalance[type] }))
    : [];

  const columns = [
    {
      title: "金種",
      dataIndex: "type",
      render(_: unknown, { type }: typeof cashDenominationCounts[number]) {
        return formatCashDenominationCount(type);
      },
    },
    {
      title: "枚数",
      dataIndex: "amount",
      align: "right",
      render(_: unknown, { amount }: typeof cashDenominationCounts[number]) {
        return `${amount} 枚`;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="type"
      columns={columns}
      dataSource={cashDenominationCounts}
      loading={loading}
      bordered
      title={() => "現金在高"}
      pagination={false}
      data-cy="cash-balance-table"
    />
  );
});
