import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { DishUpSlipGroup, Role } from "pages/EditDishUpSlipGroup/types";
import { DishUpSlipGroupRolesInsertInput, DishUpSlipGroupSetInput } from "types/graphql";

export type EditDishUpSlipGroupFormValues = Pick<DishUpSlipGroup, "name" | "id"> & {
  roleIds: Role["id"][];
};

const getInitialValues = (dishUpSlipGroup: DishUpSlipGroup) => {
  const { name, id, dishUpSlipGroupRoles } = dishUpSlipGroup;
  return {
    name,
    id,
    roleIds: dishUpSlipGroupRoles.map(({ roleId }) => roleId),
  };
};

export const EditDishUpSlipGroupFormItem = createFormItem<EditDishUpSlipGroupFormValues>();

export const useEditDishUpSlipGroupForm = ({
  roles,
  shopId,
  dishUpSlipGroup,
  onSubmit,
}: {
  roles: Role[];
  shopId: string;
  dishUpSlipGroup: DishUpSlipGroup;
  onSubmit: ({
    dishUpSlipGroup,
    dishUpSlipGroupRoles,
  }: {
    dishUpSlipGroup: DishUpSlipGroupSetInput;
    dishUpSlipGroupRoles: DishUpSlipGroupRolesInsertInput[];
  }) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(dishUpSlipGroup);

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue() as EditDishUpSlipGroupFormValues;
    const { roleIds, ...values } = formValues;
    const dishUpSlipGroupRoles = roleIds.map((roleId) => {
      const role = roles.find((role) => role.id === roleId);

      if (!role) throw new Error("role not found");

      return {
        shopId,
        roleId: role.id,
        _roleId: role.roleId,
        _dishUpSlipGroupId: dishUpSlipGroup.id,
      };
    });
    onSubmit({
      dishUpSlipGroup: {
        ...values,
      },
      dishUpSlipGroupRoles,
    });
  }, [dishUpSlipGroup.id, form, onSubmit, roles, shopId]);

  return { form, initialValues, submit };
};
