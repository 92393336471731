import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";

import { EditShopExternalOnlineMenuConfigForm } from "./EditShopExternalOnlineMenuConfigForm";
import {
  useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery,
  useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation,
} from "./queries";

export const EditShopExternalOnlineMenuConfig = () => {
  const navigate = useNavigate();
  const { shopId } = useParams<{ shopId: string }>();

  const { data, error, loading } =
    useEditShopExternalOnlineMenuConfigGetExternalOnlineMenuConfigQuery(
      shopId ? { variables: { shopId } } : { skip: true },
    );
  const externalOnlineMenuConfig = data?.externalOnlineMenuConfig[0];

  const [updateExternalOnlineMenuConfig, { loading: loadingUpdateExternalOnlineMenuConfig }] =
    useEditShopExternalOnlineMenuConfigUpdateExternalOnlineMenuConfigMutation();

  const onSubmit = useCallback(
    async ({ id, defaultMenuUrl }: { id: string; defaultMenuUrl: string }) => {
      try {
        await updateExternalOnlineMenuConfig({
          variables: { id, defaultMenuUrl },
        });
        message.success("作成しました");

        navigate("/externalOnlineMenu", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [updateExternalOnlineMenuConfig, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="外部オンラインメニューのグランドメニューを編集"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "外部オンラインメニュー" }],
      }}
    >
      <PageHeader title="外部オンラインメニューのグランドメニューを編集" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shopId && externalOnlineMenuConfig && (
        <EditShopExternalOnlineMenuConfigForm
          shopId={shopId}
          externalOnlineMenuConfig={externalOnlineMenuConfig}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          onCancel={goBack}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
