import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCouponFormItem } from "../useEditCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ContentField = memo<Props>((props) => (
  <EditCouponFormItem
    label="クーポン内容"
    name="content"
    rules={[{ required: true, message: "クーポン内容を入力してください" }]}
    {...props}
  >
    <Input placeholder="会計時〇〇円割引" />
  </EditCouponFormItem>
));
