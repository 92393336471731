import React, { memo } from "react";
import styled from "styled-components";
import { Card, QRCode } from "antd";
import Meta from "antd/lib/card/Meta";

const StyledCard = styled(Card)`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

type Props = {
  title: string;
  url: string;
};

export const QrCodeCard = memo<Props>(({ title, url }) => (
  <StyledCard hoverable>
    <Meta avatar={<QRCode value={url} />} title={title} description={url} />
  </StyledCard>
));
