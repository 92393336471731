import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert, Spin } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { EditQuestionnaireConfigFormValues } from "./EditQuestionnaireConfigForm/useEditQuestionnaireConfigForm";
import { EditQuestionnaireConfigForm } from "./EditQuestionnaireConfigForm";
import {
  useEditQuestionnaireConfigGetCouponsQuery,
  useEditQuestionnaireConfigGetQuestionnaireConfigsQuery,
  useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation,
} from "./queries";

export const EditQuestionnaireConfig = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;
  const { id } = useParams<{ id: string }>();

  const {
    data: getQuestionnaireConfigData,
    loading: loadingQuestionnaireConfig,
    refetch: refetchQuestionnaireConfig,
    error: getQuestionnaireError,
  } = useEditQuestionnaireConfigGetQuestionnaireConfigsQuery(
    id ? { variables: { id } } : { skip: true },
  );
  const questionnaireConfig = getQuestionnaireConfigData?.questionnaireConfig_by_pk;

  const {
    data: getCouponsData,
    loading: loadingCoupons,
    error: getCouponsError,
  } = useEditQuestionnaireConfigGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = getCouponsData?.coupon ?? [];

  const [updateQuestionnaireConfigMutation, { loading: updateLoading }] =
    useEditQuestionnaireConfigUpdateQuestionnaireConfigMutation();

  const loading = loadingQuestionnaireConfig || loadingCoupons || updateLoading;
  const shouldShowAlert = getQuestionnaireError || getCouponsError;

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({
      questionnaireConfig: formValues,
    }: {
      questionnaireConfig: EditQuestionnaireConfigFormValues;
    }) => {
      try {
        if (!companyId) return;
        if (!id) return;

        // TODO: ImageField でバリデーションしたい
        if (!formValues.imageUrl) return message.error("画像を登録してください");

        const questionnaireConfig = {
          ...formValues,
          rewardCouponId: formValues.rewardCouponId ?? null,
        };

        const { errors } = await updateQuestionnaireConfigMutation({
          variables: { id, companyId, questionnaireConfig },
        });
        await refetchQuestionnaireConfig();
        if (errors) throw errors;

        message.success("更新しました");
      } catch (e) {
        message.error("更新に失敗しました");
      }
    },
    [updateQuestionnaireConfigMutation, id, companyId, refetchQuestionnaireConfig],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback((_: { formValidationError: ValidateErrorEntity }) => {
    message.error("入力内容に誤りがあります");
  }, []);

  return (
    <DashboardLayout
      title="アンケートを編集"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "アンケート" }],
      }}
    >
      <PageHeader title="アンケートを編集" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spin spinning={loading}>
        {questionnaireConfig && (
          <EditQuestionnaireConfigForm
            loading={submitting}
            questionnaireConfig={questionnaireConfig}
            coupons={coupons}
            onSubmit={onSubmit}
            onClose={goBack}
            onFormValidationError={onFormValidationError}
          />
        )}
      </Spin>
    </DashboardLayout>
  );
};
