import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Option } from "../types";

import { PikaichiGetAvailableMenuCodeButtonField } from "./PikaichiGetAvailableMenuCodeButtonField";
import { PikaichiOptionChoiceTable } from "./PikaichiOptionChoiceTable";
import {
  EditOptionPikaichiOptionFormValues,
  useEditOptionPikaichiOptionForm,
} from "./useEditOptionPikaichiOptionForm";

type Props = {
  option: Option;
  onSubmit: (args: EditOptionPikaichiOptionFormValues) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => number;
  loading: boolean;
};

export const EditOptionPikaichiOptionForm = memo<Props>(
  ({ option, onClose, onSubmit, onGetAvailableMenuCodeButtonPressed, loading }) => {
    const { form, initialValues, submit } = useEditOptionPikaichiOptionForm({
      option,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();

      const fieldsValue = form.getFieldsValue();

      const choices = fieldsValue.option.choices.flatMap((choice, index) => ({
        ...choice,
        pikaichiMenuChoices:
          choice.pikaichiMenuChoices.length === 0
            ? [{ pikaichiMenu: { pikaichiMenuCd: (maxMenuCode + index).toString() } }]
            : choice.pikaichiMenuChoices.flatMap((pikaichiMenuChoice) => ({
                ...pikaichiMenuChoice,
                pikaichiMenu: {
                  ...pikaichiMenuChoice.pikaichiMenu,
                  pikaichiMenuCd: (maxMenuCode + index).toString(),
                },
              })),
      }));

      form.setFieldsValue({ option: { choices } });
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form form={form} initialValues={initialValues} layout="vertical">
            <PikaichiOptionChoiceTable />
            <Spacer size={16} />
            <PikaichiGetAvailableMenuCodeButtonField
              handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
