import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { WinboardMenuInsertInput } from "types/graphql";

import { Plan, WinboardMenu } from "../types";

export type EditWinboardPlanFormValues = Pick<
  WinboardMenu,
  "name" | "code" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
>;

export const EditWinboardPlanFormItem = createFormItem<EditWinboardPlanFormValues>();

export const useEditWinboardPlanForm = ({
  plan,
  winboardMenu,
  onSubmit,
}: {
  plan?: Plan;
  winboardMenu?: WinboardMenu;
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId">) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditWinboardPlanFormValues>(
    () => ({
      name: winboardMenu?.name ?? plan?.planName ?? "",
      code: winboardMenu?.code ?? "",
      bumonCode: winboardMenu?.bumonCode ?? "",
      bumonName: winboardMenu?.bumonName ?? "",
      categoryCode: winboardMenu?.categoryCode ?? "",
      categoryName: winboardMenu?.categoryName ?? "",
    }),
    [plan, winboardMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditWinboardPlanFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
