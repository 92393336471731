import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanTranslationsGetPlan = gql`
    query EditPlanTranslationsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    planId
    planName
    description
    featuredLabelText
    totalDealingShopCount
    company {
      id
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    translations(where: {tableName: {_eq: "plan"}}) {
      columnName
      locale
      value
    }
  }
}
    `;
export const EditPlanTranslationsUpdatePlanTranslations = gql`
    mutation EditPlanTranslationsUpdatePlanTranslations($input: UpsertTranslationsForPlanInput!) {
  upsertTranslationsForPlan(input: $input)
}
    `;
export type EditPlanTranslationsGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanTranslationsGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'planId' | 'planName' | 'description' | 'featuredLabelText' | 'totalDealingShopCount'>
    & { company: (
      { __typename?: 'company' }
      & Pick<Types.Company, 'id'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type EditPlanTranslationsUpdatePlanTranslationsMutationVariables = Types.Exact<{
  input: Types.UpsertTranslationsForPlanInput;
}>;


export type EditPlanTranslationsUpdatePlanTranslationsMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForPlan'>
);


export const EditPlanTranslationsGetPlanDocument = gql`
    query EditPlanTranslationsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    planId
    planName
    description
    featuredLabelText
    totalDealingShopCount
    company {
      id
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    translations(where: {tableName: {_eq: "plan"}}) {
      columnName
      locale
      value
    }
  }
}
    `;

/**
 * __useEditPlanTranslationsGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanTranslationsGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanTranslationsGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanTranslationsGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanTranslationsGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanTranslationsGetPlanQuery, EditPlanTranslationsGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanTranslationsGetPlanQuery, EditPlanTranslationsGetPlanQueryVariables>(EditPlanTranslationsGetPlanDocument, options);
      }
export function useEditPlanTranslationsGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanTranslationsGetPlanQuery, EditPlanTranslationsGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanTranslationsGetPlanQuery, EditPlanTranslationsGetPlanQueryVariables>(EditPlanTranslationsGetPlanDocument, options);
        }
export type EditPlanTranslationsGetPlanQueryHookResult = ReturnType<typeof useEditPlanTranslationsGetPlanQuery>;
export type EditPlanTranslationsGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanTranslationsGetPlanLazyQuery>;
export type EditPlanTranslationsGetPlanQueryResult = Apollo.QueryResult<EditPlanTranslationsGetPlanQuery, EditPlanTranslationsGetPlanQueryVariables>;
export const EditPlanTranslationsUpdatePlanTranslationsDocument = gql`
    mutation EditPlanTranslationsUpdatePlanTranslations($input: UpsertTranslationsForPlanInput!) {
  upsertTranslationsForPlan(input: $input)
}
    `;
export type EditPlanTranslationsUpdatePlanTranslationsMutationFn = Apollo.MutationFunction<EditPlanTranslationsUpdatePlanTranslationsMutation, EditPlanTranslationsUpdatePlanTranslationsMutationVariables>;

/**
 * __useEditPlanTranslationsUpdatePlanTranslationsMutation__
 *
 * To run a mutation, you first call `useEditPlanTranslationsUpdatePlanTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanTranslationsUpdatePlanTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanTranslationsUpdatePlanTranslationsMutation, { data, loading, error }] = useEditPlanTranslationsUpdatePlanTranslationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlanTranslationsUpdatePlanTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanTranslationsUpdatePlanTranslationsMutation, EditPlanTranslationsUpdatePlanTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanTranslationsUpdatePlanTranslationsMutation, EditPlanTranslationsUpdatePlanTranslationsMutationVariables>(EditPlanTranslationsUpdatePlanTranslationsDocument, options);
      }
export type EditPlanTranslationsUpdatePlanTranslationsMutationHookResult = ReturnType<typeof useEditPlanTranslationsUpdatePlanTranslationsMutation>;
export type EditPlanTranslationsUpdatePlanTranslationsMutationResult = Apollo.MutationResult<EditPlanTranslationsUpdatePlanTranslationsMutation>;
export type EditPlanTranslationsUpdatePlanTranslationsMutationOptions = Apollo.BaseMutationOptions<EditPlanTranslationsUpdatePlanTranslationsMutation, EditPlanTranslationsUpdatePlanTranslationsMutationVariables>;