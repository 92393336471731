import { theme } from "antd";

// 2023/11/30時点でoldColors, greyを使用している箇所は残っているが一括以降が難しくタスクを切って移行を行う予定
// NOTE: colorsへの移行が完了した時点でこの変数は削除すること
/**
 * @deprecated
 * 原則、こちらは使用せずに、colorsを使用すること。
 * 理由としては、デザイントークンが導入され、デザイン(Figma)でもデザイントークンをもとに色を決定していくため、デザイントークンと一致しているcolorsを今後利用する
 */
export const oldColors = {
  DiniiBlue: "#22ABE1",
  BrightSun400: "#FFC20B",
  AlertYellow: "#f1c335",
  Neutral100: "#F1F1F1",
  Neutral200: "#e0e0e0",
  Neutral400: "#999999",
  BaseGrey: "rgba(0, 0, 0, 0.4)",
  Orange: "#F37D58",
} as const;

export type Grey = typeof grey[number];
// NOTE: colorsへの移行が完了した時点でこの変数は削除すること
export const grey = [
  "#ffffff",
  "#fafafa",
  "#f5f5f5",
  "#f0f0f0",
  "#d9d9d9",
  "#bfbfbf",
  "#8c8c8c",
  "#595959",
  "#262626",
  "#1f1f1f",
  "#000000",
] as const;

// DesignToken ref: https://www.figma.com/file/NcQ1q7nvwQ60Xkua10X6oM/ColorPalette?type=design&node-id=0-1&mode=design&t=FKhhOjA9vrHNTIrJ-0
// antDesignToken ref: https://ant.design/docs/react/customize-theme#design-token

const antDesignToken = theme.getDesignToken();

// NOTE: 2023/11 時点でカラーコードベタ書きになっているものは意図通りです
export const colors = {
  Text: {
    /** #000000 */
    Default: antDesignToken.colorText,
    /** #1677FF */
    Primary: antDesignToken.colorPrimaryText,
    /** #00000045 */
    Secondary: antDesignToken.colorTextTertiary,
    /** #00000025 */
    Disabled: antDesignToken.colorTextDisabled,
    OnColor: "#FFFFFF",
    /** #FF4D4F */
    Error: antDesignToken.colorErrorText,
  },
  BackGround: {
    /** #FFFFFF */
    Default: antDesignToken.colorBgContainer,
    /** #1677FF */
    PrimaryDefault: antDesignToken.colorPrimary,
    /** #E6F7FF */
    PrimarySecondary: antDesignToken.colorPrimaryBg,
    /** #F5F5F5 */
    Secondary: antDesignToken.colorBgLayout,
    /** #00000006 */
    Tertiary: antDesignToken.colorFillSecondary,
    /** #00000004 */
    Disabled: antDesignToken.colorBgContainerDisabled,
    /** #FF4D4F */
    Error: antDesignToken.colorError,
  },
  Border: {
    /** #D9D9D9 */
    Default: antDesignToken.colorBorder,
    /** #91CAFF */
    Primary: antDesignToken.colorPrimaryBorder,
  },
  Divider: {
    /** #F0F0F0 */
    Default: antDesignToken.colorBorderSecondary,
    /** #00000006 */
    Pale: antDesignToken.colorFillSecondary,
  },
  Other: {
    Header: "#001529",
    /** #00000045 */
    ModalOverlay: antDesignToken.colorBgMask,
  },
} as const;
