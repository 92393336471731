import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddCorporationInsertCorporation = gql`
    mutation AddCorporationInsertCorporation($input: CreateCorporationInput!) {
  dashboardAccountCreateCorporation(input: $input)
}
    `;
export type AddCorporationInsertCorporationMutationVariables = Types.Exact<{
  input: Types.CreateCorporationInput;
}>;


export type AddCorporationInsertCorporationMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCreateCorporation'>
);


export const AddCorporationInsertCorporationDocument = gql`
    mutation AddCorporationInsertCorporation($input: CreateCorporationInput!) {
  dashboardAccountCreateCorporation(input: $input)
}
    `;
export type AddCorporationInsertCorporationMutationFn = Apollo.MutationFunction<AddCorporationInsertCorporationMutation, AddCorporationInsertCorporationMutationVariables>;

/**
 * __useAddCorporationInsertCorporationMutation__
 *
 * To run a mutation, you first call `useAddCorporationInsertCorporationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCorporationInsertCorporationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCorporationInsertCorporationMutation, { data, loading, error }] = useAddCorporationInsertCorporationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCorporationInsertCorporationMutation(baseOptions?: Apollo.MutationHookOptions<AddCorporationInsertCorporationMutation, AddCorporationInsertCorporationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCorporationInsertCorporationMutation, AddCorporationInsertCorporationMutationVariables>(AddCorporationInsertCorporationDocument, options);
      }
export type AddCorporationInsertCorporationMutationHookResult = ReturnType<typeof useAddCorporationInsertCorporationMutation>;
export type AddCorporationInsertCorporationMutationResult = Apollo.MutationResult<AddCorporationInsertCorporationMutation>;
export type AddCorporationInsertCorporationMutationOptions = Apollo.BaseMutationOptions<AddCorporationInsertCorporationMutation, AddCorporationInsertCorporationMutationVariables>;