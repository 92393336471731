import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanDealersGetPlan = gql`
    query EditPlanDealersGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    companyId
    planId
    planName
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shopId
      planId
      _planId
      shop {
        shopId
        name
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopPlanKitchenRoles(order_by: {role: {roleId: asc}}) {
        role {
          roleId
          name
        }
      }
      dishUpSlipGroupShopPlans(order_by: {dishUpSlipGroup: {id: asc}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
    }
  }
}
    `;
export const EditPlanDealersGetShops = gql`
    query EditPlanDealersGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const EditPlanDealersUpdateShopPlan = gql`
    mutation EditPlanDealersUpdateShopPlan($shopId: uuid!, $planId: uuid!, $input: shopPlan_set_input!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: $input
  ) {
    affected_rows
  }
}
    `;
export const EditPlanDealersUpdateStock = gql`
    mutation EditPlanDealersUpdateStock($shopId: uuid!, $planId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    returning {
      planId: _planId
    }
  }
}
    `;
export const EditPlanDealersInsertShopPlan = gql`
    mutation EditPlanDealersInsertShopPlan($input: CreateShopPlanInput!) {
  createShopPlan(input: $input) {
    result
  }
}
    `;
export const EditPlanDealersDeleteShopPlan = gql`
    mutation EditPlanDealersDeleteShopPlan($input: DeleteShopPlansInput!) {
  deleteShopPlans(input: $input) {
    result
  }
}
    `;
export type EditPlanDealersGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanDealersGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'companyId' | 'planId' | 'planName' | 'totalDealingShopCount'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'id' | 'shopId' | 'planId' | '_planId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ), stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum' | 'dailyStockNum'>
      )>, shopPlanKitchenRoles: Array<(
        { __typename?: 'shopPlanKitchenRole' }
        & { role: (
          { __typename?: 'role' }
          & Pick<Types.Role, 'roleId' | 'name'>
        ) }
      )>, dishUpSlipGroupShopPlans: Array<(
        { __typename?: 'dishUpSlipGroupShopPlans' }
        & { dishUpSlipGroup?: Types.Maybe<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      )> }
    )> }
  )> }
);

export type EditPlanDealersGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanDealersGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditPlanDealersUpdateShopPlanMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  planId: Types.Scalars['uuid'];
  input: Types.ShopPlanSetInput;
}>;


export type EditPlanDealersUpdateShopPlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopPlan?: Types.Maybe<(
    { __typename?: 'shopPlan_mutation_response' }
    & Pick<Types.ShopPlanMutationResponse, 'affected_rows'>
  )> }
);

export type EditPlanDealersUpdateStockMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  planId: Types.Scalars['uuid'];
  stockId: Types.Scalars['uuid'];
  stock: Types.StockInsertInput;
}>;


export type EditPlanDealersUpdateStockMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stock_one?: Types.Maybe<(
    { __typename?: 'stock' }
    & Pick<Types.Stock, 'id'>
  )>, update_shopPlan?: Types.Maybe<(
    { __typename?: 'shopPlan_mutation_response' }
    & { returning: Array<(
      { __typename?: 'shopPlan' }
      & { planId: Types.ShopPlan['_planId'] }
    )> }
  )> }
);

export type EditPlanDealersInsertShopPlanMutationVariables = Types.Exact<{
  input: Types.CreateShopPlanInput;
}>;


export type EditPlanDealersInsertShopPlanMutation = (
  { __typename?: 'mutation_root' }
  & { createShopPlan: (
    { __typename?: 'CreateShopPlanResult' }
    & Pick<Types.CreateShopPlanResult, 'result'>
  ) }
);

export type EditPlanDealersDeleteShopPlanMutationVariables = Types.Exact<{
  input: Types.DeleteShopPlansInput;
}>;


export type EditPlanDealersDeleteShopPlanMutation = (
  { __typename?: 'mutation_root' }
  & { deleteShopPlans: (
    { __typename?: 'DeleteShopPlansResult' }
    & Pick<Types.DeleteShopPlansResult, 'result'>
  ) }
);


export const EditPlanDealersGetPlanDocument = gql`
    query EditPlanDealersGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    companyId
    planId
    planName
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      id
      shopId
      planId
      _planId
      shop {
        shopId
        name
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopPlanKitchenRoles(order_by: {role: {roleId: asc}}) {
        role {
          roleId
          name
        }
      }
      dishUpSlipGroupShopPlans(order_by: {dishUpSlipGroup: {id: asc}}) {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
    }
  }
}
    `;

/**
 * __useEditPlanDealersGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanDealersGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanDealersGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanDealersGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanDealersGetPlanQuery, EditPlanDealersGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanDealersGetPlanQuery, EditPlanDealersGetPlanQueryVariables>(EditPlanDealersGetPlanDocument, options);
      }
export function useEditPlanDealersGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanDealersGetPlanQuery, EditPlanDealersGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanDealersGetPlanQuery, EditPlanDealersGetPlanQueryVariables>(EditPlanDealersGetPlanDocument, options);
        }
export type EditPlanDealersGetPlanQueryHookResult = ReturnType<typeof useEditPlanDealersGetPlanQuery>;
export type EditPlanDealersGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanDealersGetPlanLazyQuery>;
export type EditPlanDealersGetPlanQueryResult = Apollo.QueryResult<EditPlanDealersGetPlanQuery, EditPlanDealersGetPlanQueryVariables>;
export const EditPlanDealersGetShopsDocument = gql`
    query EditPlanDealersGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditPlanDealersGetShopsQuery__
 *
 * To run a query within a React component, call `useEditPlanDealersGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanDealersGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanDealersGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditPlanDealersGetShopsQuery, EditPlanDealersGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanDealersGetShopsQuery, EditPlanDealersGetShopsQueryVariables>(EditPlanDealersGetShopsDocument, options);
      }
export function useEditPlanDealersGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanDealersGetShopsQuery, EditPlanDealersGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanDealersGetShopsQuery, EditPlanDealersGetShopsQueryVariables>(EditPlanDealersGetShopsDocument, options);
        }
export type EditPlanDealersGetShopsQueryHookResult = ReturnType<typeof useEditPlanDealersGetShopsQuery>;
export type EditPlanDealersGetShopsLazyQueryHookResult = ReturnType<typeof useEditPlanDealersGetShopsLazyQuery>;
export type EditPlanDealersGetShopsQueryResult = Apollo.QueryResult<EditPlanDealersGetShopsQuery, EditPlanDealersGetShopsQueryVariables>;
export const EditPlanDealersUpdateShopPlanDocument = gql`
    mutation EditPlanDealersUpdateShopPlan($shopId: uuid!, $planId: uuid!, $input: shopPlan_set_input!) {
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: $input
  ) {
    affected_rows
  }
}
    `;
export type EditPlanDealersUpdateShopPlanMutationFn = Apollo.MutationFunction<EditPlanDealersUpdateShopPlanMutation, EditPlanDealersUpdateShopPlanMutationVariables>;

/**
 * __useEditPlanDealersUpdateShopPlanMutation__
 *
 * To run a mutation, you first call `useEditPlanDealersUpdateShopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersUpdateShopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanDealersUpdateShopPlanMutation, { data, loading, error }] = useEditPlanDealersUpdateShopPlanMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      planId: // value for 'planId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlanDealersUpdateShopPlanMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanDealersUpdateShopPlanMutation, EditPlanDealersUpdateShopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanDealersUpdateShopPlanMutation, EditPlanDealersUpdateShopPlanMutationVariables>(EditPlanDealersUpdateShopPlanDocument, options);
      }
export type EditPlanDealersUpdateShopPlanMutationHookResult = ReturnType<typeof useEditPlanDealersUpdateShopPlanMutation>;
export type EditPlanDealersUpdateShopPlanMutationResult = Apollo.MutationResult<EditPlanDealersUpdateShopPlanMutation>;
export type EditPlanDealersUpdateShopPlanMutationOptions = Apollo.BaseMutationOptions<EditPlanDealersUpdateShopPlanMutation, EditPlanDealersUpdateShopPlanMutationVariables>;
export const EditPlanDealersUpdateStockDocument = gql`
    mutation EditPlanDealersUpdateStock($shopId: uuid!, $planId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum, dailyStockNum]}
  ) {
    id
  }
  update_shopPlan(
    where: {shopId: {_eq: $shopId}, planId: {_eq: $planId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    returning {
      planId: _planId
    }
  }
}
    `;
export type EditPlanDealersUpdateStockMutationFn = Apollo.MutationFunction<EditPlanDealersUpdateStockMutation, EditPlanDealersUpdateStockMutationVariables>;

/**
 * __useEditPlanDealersUpdateStockMutation__
 *
 * To run a mutation, you first call `useEditPlanDealersUpdateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersUpdateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanDealersUpdateStockMutation, { data, loading, error }] = useEditPlanDealersUpdateStockMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      planId: // value for 'planId'
 *      stockId: // value for 'stockId'
 *      stock: // value for 'stock'
 *   },
 * });
 */
export function useEditPlanDealersUpdateStockMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanDealersUpdateStockMutation, EditPlanDealersUpdateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanDealersUpdateStockMutation, EditPlanDealersUpdateStockMutationVariables>(EditPlanDealersUpdateStockDocument, options);
      }
export type EditPlanDealersUpdateStockMutationHookResult = ReturnType<typeof useEditPlanDealersUpdateStockMutation>;
export type EditPlanDealersUpdateStockMutationResult = Apollo.MutationResult<EditPlanDealersUpdateStockMutation>;
export type EditPlanDealersUpdateStockMutationOptions = Apollo.BaseMutationOptions<EditPlanDealersUpdateStockMutation, EditPlanDealersUpdateStockMutationVariables>;
export const EditPlanDealersInsertShopPlanDocument = gql`
    mutation EditPlanDealersInsertShopPlan($input: CreateShopPlanInput!) {
  createShopPlan(input: $input) {
    result
  }
}
    `;
export type EditPlanDealersInsertShopPlanMutationFn = Apollo.MutationFunction<EditPlanDealersInsertShopPlanMutation, EditPlanDealersInsertShopPlanMutationVariables>;

/**
 * __useEditPlanDealersInsertShopPlanMutation__
 *
 * To run a mutation, you first call `useEditPlanDealersInsertShopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersInsertShopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanDealersInsertShopPlanMutation, { data, loading, error }] = useEditPlanDealersInsertShopPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlanDealersInsertShopPlanMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanDealersInsertShopPlanMutation, EditPlanDealersInsertShopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanDealersInsertShopPlanMutation, EditPlanDealersInsertShopPlanMutationVariables>(EditPlanDealersInsertShopPlanDocument, options);
      }
export type EditPlanDealersInsertShopPlanMutationHookResult = ReturnType<typeof useEditPlanDealersInsertShopPlanMutation>;
export type EditPlanDealersInsertShopPlanMutationResult = Apollo.MutationResult<EditPlanDealersInsertShopPlanMutation>;
export type EditPlanDealersInsertShopPlanMutationOptions = Apollo.BaseMutationOptions<EditPlanDealersInsertShopPlanMutation, EditPlanDealersInsertShopPlanMutationVariables>;
export const EditPlanDealersDeleteShopPlanDocument = gql`
    mutation EditPlanDealersDeleteShopPlan($input: DeleteShopPlansInput!) {
  deleteShopPlans(input: $input) {
    result
  }
}
    `;
export type EditPlanDealersDeleteShopPlanMutationFn = Apollo.MutationFunction<EditPlanDealersDeleteShopPlanMutation, EditPlanDealersDeleteShopPlanMutationVariables>;

/**
 * __useEditPlanDealersDeleteShopPlanMutation__
 *
 * To run a mutation, you first call `useEditPlanDealersDeleteShopPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanDealersDeleteShopPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanDealersDeleteShopPlanMutation, { data, loading, error }] = useEditPlanDealersDeleteShopPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPlanDealersDeleteShopPlanMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanDealersDeleteShopPlanMutation, EditPlanDealersDeleteShopPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanDealersDeleteShopPlanMutation, EditPlanDealersDeleteShopPlanMutationVariables>(EditPlanDealersDeleteShopPlanDocument, options);
      }
export type EditPlanDealersDeleteShopPlanMutationHookResult = ReturnType<typeof useEditPlanDealersDeleteShopPlanMutation>;
export type EditPlanDealersDeleteShopPlanMutationResult = Apollo.MutationResult<EditPlanDealersDeleteShopPlanMutation>;
export type EditPlanDealersDeleteShopPlanMutationOptions = Apollo.BaseMutationOptions<EditPlanDealersDeleteShopPlanMutation, EditPlanDealersDeleteShopPlanMutationVariables>;