import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanChoiceModalGetPlanChoice = gql`
    query EditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}, planOption: {archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
    description
    imageUrl
    isDefaultSelection
    maxOrderableNum
    name
    planChoiceId
    price
    receiptDisplayName
    costPrice
    costTaxRate
    menuType
    translations(where: {tableName: {_eq: "planChoice"}}) {
      columnName
      locale
      value
    }
    planOption {
      inputType
      plan {
        taxMethod
        taxRate
        company {
          id
          defaultCostTaxMethod
        }
      }
    }
  }
}
    `;
export const EditPlanChoiceModalUpdatePlanChoice = gql`
    mutation EditPlanChoiceModalUpdatePlanChoice($planChoiceId: Int!, $planChoice: planChoice_set_input!, $translations: UpsertTranslationsForPlanChoiceInput!) {
  update_planChoice(
    _set: $planChoice
    where: {planChoiceId: {_eq: $planChoiceId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanChoice(input: $translations)
}
    `;
export type EditPlanChoiceModalGetPlanChoiceQueryVariables = Types.Exact<{
  planChoiceId: Types.Scalars['Int'];
}>;


export type EditPlanChoiceModalGetPlanChoiceQuery = (
  { __typename?: 'query_root' }
  & { planChoice: Array<(
    { __typename?: 'planChoice' }
    & Pick<Types.PlanChoice, 'id' | 'description' | 'imageUrl' | 'isDefaultSelection' | 'maxOrderableNum' | 'name' | 'planChoiceId' | 'price' | 'receiptDisplayName' | 'costPrice' | 'costTaxRate' | 'menuType'>
    & { translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )>, planOption: (
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'inputType'>
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'taxMethod' | 'taxRate'>
        & { company: (
          { __typename?: 'company' }
          & Pick<Types.Company, 'id' | 'defaultCostTaxMethod'>
        ) }
      ) }
    ) }
  )> }
);

export type EditPlanChoiceModalUpdatePlanChoiceMutationVariables = Types.Exact<{
  planChoiceId: Types.Scalars['Int'];
  planChoice: Types.PlanChoiceSetInput;
  translations: Types.UpsertTranslationsForPlanChoiceInput;
}>;


export type EditPlanChoiceModalUpdatePlanChoiceMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'upsertTranslationsForPlanChoice'>
  & { update_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanChoiceModalGetPlanChoiceDocument = gql`
    query EditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}, planOption: {archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
    description
    imageUrl
    isDefaultSelection
    maxOrderableNum
    name
    planChoiceId
    price
    receiptDisplayName
    costPrice
    costTaxRate
    menuType
    translations(where: {tableName: {_eq: "planChoice"}}) {
      columnName
      locale
      value
    }
    planOption {
      inputType
      plan {
        taxMethod
        taxRate
        company {
          id
          defaultCostTaxMethod
        }
      }
    }
  }
}
    `;

/**
 * __useEditPlanChoiceModalGetPlanChoiceQuery__
 *
 * To run a query within a React component, call `useEditPlanChoiceModalGetPlanChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanChoiceModalGetPlanChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanChoiceModalGetPlanChoiceQuery({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *   },
 * });
 */
export function useEditPlanChoiceModalGetPlanChoiceQuery(baseOptions: Apollo.QueryHookOptions<EditPlanChoiceModalGetPlanChoiceQuery, EditPlanChoiceModalGetPlanChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanChoiceModalGetPlanChoiceQuery, EditPlanChoiceModalGetPlanChoiceQueryVariables>(EditPlanChoiceModalGetPlanChoiceDocument, options);
      }
export function useEditPlanChoiceModalGetPlanChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanChoiceModalGetPlanChoiceQuery, EditPlanChoiceModalGetPlanChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanChoiceModalGetPlanChoiceQuery, EditPlanChoiceModalGetPlanChoiceQueryVariables>(EditPlanChoiceModalGetPlanChoiceDocument, options);
        }
export type EditPlanChoiceModalGetPlanChoiceQueryHookResult = ReturnType<typeof useEditPlanChoiceModalGetPlanChoiceQuery>;
export type EditPlanChoiceModalGetPlanChoiceLazyQueryHookResult = ReturnType<typeof useEditPlanChoiceModalGetPlanChoiceLazyQuery>;
export type EditPlanChoiceModalGetPlanChoiceQueryResult = Apollo.QueryResult<EditPlanChoiceModalGetPlanChoiceQuery, EditPlanChoiceModalGetPlanChoiceQueryVariables>;
export const EditPlanChoiceModalUpdatePlanChoiceDocument = gql`
    mutation EditPlanChoiceModalUpdatePlanChoice($planChoiceId: Int!, $planChoice: planChoice_set_input!, $translations: UpsertTranslationsForPlanChoiceInput!) {
  update_planChoice(
    _set: $planChoice
    where: {planChoiceId: {_eq: $planChoiceId}}
  ) {
    affected_rows
  }
  upsertTranslationsForPlanChoice(input: $translations)
}
    `;
export type EditPlanChoiceModalUpdatePlanChoiceMutationFn = Apollo.MutationFunction<EditPlanChoiceModalUpdatePlanChoiceMutation, EditPlanChoiceModalUpdatePlanChoiceMutationVariables>;

/**
 * __useEditPlanChoiceModalUpdatePlanChoiceMutation__
 *
 * To run a mutation, you first call `useEditPlanChoiceModalUpdatePlanChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanChoiceModalUpdatePlanChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanChoiceModalUpdatePlanChoiceMutation, { data, loading, error }] = useEditPlanChoiceModalUpdatePlanChoiceMutation({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *      planChoice: // value for 'planChoice'
 *      translations: // value for 'translations'
 *   },
 * });
 */
export function useEditPlanChoiceModalUpdatePlanChoiceMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanChoiceModalUpdatePlanChoiceMutation, EditPlanChoiceModalUpdatePlanChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanChoiceModalUpdatePlanChoiceMutation, EditPlanChoiceModalUpdatePlanChoiceMutationVariables>(EditPlanChoiceModalUpdatePlanChoiceDocument, options);
      }
export type EditPlanChoiceModalUpdatePlanChoiceMutationHookResult = ReturnType<typeof useEditPlanChoiceModalUpdatePlanChoiceMutation>;
export type EditPlanChoiceModalUpdatePlanChoiceMutationResult = Apollo.MutationResult<EditPlanChoiceModalUpdatePlanChoiceMutation>;
export type EditPlanChoiceModalUpdatePlanChoiceMutationOptions = Apollo.BaseMutationOptions<EditPlanChoiceModalUpdatePlanChoiceMutation, EditPlanChoiceModalUpdatePlanChoiceMutationVariables>;