import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPlanGetPlan = gql`
    query EditPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    companyId
    description
    displayType
    featuredLabelText
    imageUrl
    orderableTimeId
    planGroupId: _planGroupId
    planId
    planName
    planTime
    endNotificationThresholdMinutes
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
  }
}
    `;
export const EditPlanGetCategories = gql`
    query EditPlanGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const EditPlanGetOrderableTimes = gql`
    query EditPlanGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditPlanGetPlanGroups = gql`
    query EditPlanGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}) {
    name
    planGroupId
  }
}
    `;
export const EditPlanUpdatePlan = gql`
    mutation EditPlanUpdatePlan($planId: Int!, $plan: plan_set_input!) {
  update_plan(
    _set: $plan
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type EditPlanGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'displayType' | 'featuredLabelText' | 'imageUrl' | 'orderableTimeId' | 'planId' | 'planName' | 'planTime' | 'endNotificationThresholdMinutes' | 'receiptDisplayName' | 'shopSideName' | 'taxMethod' | 'taxRate'>
    & { planGroupId: Types.Plan['_planGroupId'] }
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ) }
  )> }
);

export type EditPlanGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type EditPlanGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type EditPlanGetPlanGroupsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditPlanGetPlanGroupsQuery = (
  { __typename?: 'query_root' }
  & { planGroup: Array<(
    { __typename?: 'planGroup' }
    & Pick<Types.PlanGroup, 'name' | 'planGroupId'>
  )> }
);

export type EditPlanUpdatePlanMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  plan: Types.PlanSetInput;
}>;


export type EditPlanUpdatePlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_plan?: Types.Maybe<(
    { __typename?: 'plan_mutation_response' }
    & Pick<Types.PlanMutationResponse, 'affected_rows'>
  )> }
);


export const EditPlanGetPlanDocument = gql`
    query EditPlanGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    category {
      categoryId
      name
    }
    companyId
    description
    displayType
    featuredLabelText
    imageUrl
    orderableTimeId
    planGroupId: _planGroupId
    planId
    planName
    planTime
    endNotificationThresholdMinutes
    receiptDisplayName
    shopSideName
    taxMethod
    taxRate
  }
}
    `;

/**
 * __useEditPlanGetPlanQuery__
 *
 * To run a query within a React component, call `useEditPlanGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useEditPlanGetPlanQuery(baseOptions: Apollo.QueryHookOptions<EditPlanGetPlanQuery, EditPlanGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanGetPlanQuery, EditPlanGetPlanQueryVariables>(EditPlanGetPlanDocument, options);
      }
export function useEditPlanGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanGetPlanQuery, EditPlanGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanGetPlanQuery, EditPlanGetPlanQueryVariables>(EditPlanGetPlanDocument, options);
        }
export type EditPlanGetPlanQueryHookResult = ReturnType<typeof useEditPlanGetPlanQuery>;
export type EditPlanGetPlanLazyQueryHookResult = ReturnType<typeof useEditPlanGetPlanLazyQuery>;
export type EditPlanGetPlanQueryResult = Apollo.QueryResult<EditPlanGetPlanQuery, EditPlanGetPlanQueryVariables>;
export const EditPlanGetCategoriesDocument = gql`
    query EditPlanGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useEditPlanGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditPlanGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditPlanGetCategoriesQuery, EditPlanGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanGetCategoriesQuery, EditPlanGetCategoriesQueryVariables>(EditPlanGetCategoriesDocument, options);
      }
export function useEditPlanGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanGetCategoriesQuery, EditPlanGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanGetCategoriesQuery, EditPlanGetCategoriesQueryVariables>(EditPlanGetCategoriesDocument, options);
        }
export type EditPlanGetCategoriesQueryHookResult = ReturnType<typeof useEditPlanGetCategoriesQuery>;
export type EditPlanGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditPlanGetCategoriesLazyQuery>;
export type EditPlanGetCategoriesQueryResult = Apollo.QueryResult<EditPlanGetCategoriesQuery, EditPlanGetCategoriesQueryVariables>;
export const EditPlanGetOrderableTimesDocument = gql`
    query EditPlanGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditPlanGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useEditPlanGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<EditPlanGetOrderableTimesQuery, EditPlanGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanGetOrderableTimesQuery, EditPlanGetOrderableTimesQueryVariables>(EditPlanGetOrderableTimesDocument, options);
      }
export function useEditPlanGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanGetOrderableTimesQuery, EditPlanGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanGetOrderableTimesQuery, EditPlanGetOrderableTimesQueryVariables>(EditPlanGetOrderableTimesDocument, options);
        }
export type EditPlanGetOrderableTimesQueryHookResult = ReturnType<typeof useEditPlanGetOrderableTimesQuery>;
export type EditPlanGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useEditPlanGetOrderableTimesLazyQuery>;
export type EditPlanGetOrderableTimesQueryResult = Apollo.QueryResult<EditPlanGetOrderableTimesQuery, EditPlanGetOrderableTimesQueryVariables>;
export const EditPlanGetPlanGroupsDocument = gql`
    query EditPlanGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}) {
    name
    planGroupId
  }
}
    `;

/**
 * __useEditPlanGetPlanGroupsQuery__
 *
 * To run a query within a React component, call `useEditPlanGetPlanGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPlanGetPlanGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPlanGetPlanGroupsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPlanGetPlanGroupsQuery(baseOptions: Apollo.QueryHookOptions<EditPlanGetPlanGroupsQuery, EditPlanGetPlanGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPlanGetPlanGroupsQuery, EditPlanGetPlanGroupsQueryVariables>(EditPlanGetPlanGroupsDocument, options);
      }
export function useEditPlanGetPlanGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPlanGetPlanGroupsQuery, EditPlanGetPlanGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPlanGetPlanGroupsQuery, EditPlanGetPlanGroupsQueryVariables>(EditPlanGetPlanGroupsDocument, options);
        }
export type EditPlanGetPlanGroupsQueryHookResult = ReturnType<typeof useEditPlanGetPlanGroupsQuery>;
export type EditPlanGetPlanGroupsLazyQueryHookResult = ReturnType<typeof useEditPlanGetPlanGroupsLazyQuery>;
export type EditPlanGetPlanGroupsQueryResult = Apollo.QueryResult<EditPlanGetPlanGroupsQuery, EditPlanGetPlanGroupsQueryVariables>;
export const EditPlanUpdatePlanDocument = gql`
    mutation EditPlanUpdatePlan($planId: Int!, $plan: plan_set_input!) {
  update_plan(
    _set: $plan
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}
  ) {
    affected_rows
  }
}
    `;
export type EditPlanUpdatePlanMutationFn = Apollo.MutationFunction<EditPlanUpdatePlanMutation, EditPlanUpdatePlanMutationVariables>;

/**
 * __useEditPlanUpdatePlanMutation__
 *
 * To run a mutation, you first call `useEditPlanUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPlanUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPlanUpdatePlanMutation, { data, loading, error }] = useEditPlanUpdatePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useEditPlanUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<EditPlanUpdatePlanMutation, EditPlanUpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPlanUpdatePlanMutation, EditPlanUpdatePlanMutationVariables>(EditPlanUpdatePlanDocument, options);
      }
export type EditPlanUpdatePlanMutationHookResult = ReturnType<typeof useEditPlanUpdatePlanMutation>;
export type EditPlanUpdatePlanMutationResult = Apollo.MutationResult<EditPlanUpdatePlanMutation>;
export type EditPlanUpdatePlanMutationOptions = Apollo.BaseMutationOptions<EditPlanUpdatePlanMutation, EditPlanUpdatePlanMutationVariables>;