import React from "react";
import { parseCostPrice } from "util/input/parsePrice";

import { InputNumber, Props as InputNumberProps } from "components/Input/InputNumber";

type Props = InputNumberProps;

export const InputCostPrice = (props: Props) => (
  <InputNumber formatter={(value) => `${value}円`} parser={parseCostPrice} min={0} {...props} />
);
