import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { PlanRoleField } from "pages/EditShopPlanOptionRoles/EditPlanOptionRoleForm/PlanRoleField";
import { useEditPlanOptionRoleForm } from "pages/EditShopPlanOptionRoles/EditPlanOptionRoleForm/useEditPlanOptionRoleForm";
import { PlanOption, Role } from "pages/EditShopPlanOptionRoles/types";

type Props = {
  shopId: string;
  planOption: PlanOption;
  planOptionRoles: Role[];
  roles: Role[];
};

export const EditPlanOptionRoleForm = memo<Props>(
  ({ shopId, planOption, planOptionRoles, roles }) => {
    const {
      form,
      initialValues,
      change: onChange,
      loadings,
    } = useEditPlanOptionRoleForm(shopId, planOption, planOptionRoles, roles);

    return (
      <Form
        name="planOptionRoles"
        form={form}
        initialValues={initialValues}
        onValuesChange={onChange}
        layout="vertical"
      >
        {roles.map(({ roleId, name }) => (
          <PlanRoleField key={roleId} label={name} name={roleId} loading={loadings[roleId]} />
        ))}
      </Form>
    );
  },
);
