import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";

import {
  EditCorporationGetCorporationDocument,
  useEditCorporationUpdateCorporationMutation,
} from "../queries";
import { Corporation } from "../types";

type EditCorporationFormValues = {
  name: string;
};

export const EditCorporationFormItem = createFormItem<EditCorporationFormValues>();

export const useEditCorporationForm = (corporation: Corporation) => {
  const [form] = Form.useForm();
  const initialValues = { name: corporation.name };

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [updateCorporationMutation, { loading }] = useEditCorporationUpdateCorporationMutation();

  const submit = useCallback(
    async (corporation: Corporation) => {
      const { id } = corporation;
      await form.validateFields();
      const field = form.getFieldsValue() as EditCorporationFormValues;

      if (!id) return;

      try {
        await updateCorporationMutation({
          variables: {
            corporationId: id,
            name: field.name,
          },
          refetchQueries: [
            {
              query: EditCorporationGetCorporationDocument,
              variables: { corporationId: id },
            },
          ],
        });
        message.success("編集を保存しました");
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [form, updateCorporationMutation, goBack],
  );
  const onSubmit = useCallback(() => submit(corporation), [corporation, submit]);

  return { form, initialValues, loading, onSubmit, goBack };
};
