import React, { memo } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { NameField } from "pages/AddOrderableTime/AddOrderableTimeForm/NameField";
import { useAddOrderableTimeForm } from "pages/AddOrderableTime/AddOrderableTimeForm/useAddOrderableTimeForm";
import { OrderableTimeInsertInput } from "types/graphql";

import { useAddOrderableTimeGetOrderableTimesQuery } from "../queries";

type Props = {
  onSubmit: (input: OrderableTimeInsertInput) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose: () => void;
  loading: boolean;
};

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const AddOrderableTimeForm = memo<Props>(
  ({ onClose, onSubmit, onFormValidationError, loading }) => {
    const [company] = useCompany();
    const { data } = useAddOrderableTimeGetOrderableTimesQuery(
      company ? { variables: { companyId: company.id } } : { skip: true },
    );

    const existingOrderableTimeNames = (data?.orderableTime ?? []).map(({ name }) => name);

    const { form, initialValues, submit } = useAddOrderableTimeForm(
      onSubmit,
      onFormValidationError,
    );

    return (
      <>
        <FormContent>
          <StyledForm
            name="orderableTime"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <NameField
              wrapperCol={{ span: 8 }}
              existingOrderableTimeNames={existingOrderableTimeNames}
            />
          </StyledForm>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
