import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddMenuRecommendationMeta = gql`
    mutation AddMenuRecommendationMeta($menuRecommendationMetaInput: CreateMenuRecommendationInputSource!) {
  createMenuRecommendation(input: $menuRecommendationMetaInput) {
    menuRecommendationMetaId
  }
}
    `;
export type AddMenuRecommendationMetaMutationVariables = Types.Exact<{
  menuRecommendationMetaInput: Types.CreateMenuRecommendationInputSource;
}>;


export type AddMenuRecommendationMetaMutation = (
  { __typename?: 'mutation_root' }
  & { createMenuRecommendation: (
    { __typename?: 'CreateMenuRecommendationOutput' }
    & Pick<Types.CreateMenuRecommendationOutput, 'menuRecommendationMetaId'>
  ) }
);


export const AddMenuRecommendationMetaDocument = gql`
    mutation AddMenuRecommendationMeta($menuRecommendationMetaInput: CreateMenuRecommendationInputSource!) {
  createMenuRecommendation(input: $menuRecommendationMetaInput) {
    menuRecommendationMetaId
  }
}
    `;
export type AddMenuRecommendationMetaMutationFn = Apollo.MutationFunction<AddMenuRecommendationMetaMutation, AddMenuRecommendationMetaMutationVariables>;

/**
 * __useAddMenuRecommendationMetaMutation__
 *
 * To run a mutation, you first call `useAddMenuRecommendationMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuRecommendationMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuRecommendationMetaMutation, { data, loading, error }] = useAddMenuRecommendationMetaMutation({
 *   variables: {
 *      menuRecommendationMetaInput: // value for 'menuRecommendationMetaInput'
 *   },
 * });
 */
export function useAddMenuRecommendationMetaMutation(baseOptions?: Apollo.MutationHookOptions<AddMenuRecommendationMetaMutation, AddMenuRecommendationMetaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMenuRecommendationMetaMutation, AddMenuRecommendationMetaMutationVariables>(AddMenuRecommendationMetaDocument, options);
      }
export type AddMenuRecommendationMetaMutationHookResult = ReturnType<typeof useAddMenuRecommendationMetaMutation>;
export type AddMenuRecommendationMetaMutationResult = Apollo.MutationResult<AddMenuRecommendationMetaMutation>;
export type AddMenuRecommendationMetaMutationOptions = Apollo.BaseMutationOptions<AddMenuRecommendationMetaMutation, AddMenuRecommendationMetaMutationVariables>;