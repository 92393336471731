import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { AddClerkForm } from "pages/AddClerk/AddClerkForm";
import { useAddClerkInsertClerkMutation } from "pages/AddClerk/queries";
import { ClerkInsertInput } from "types/graphql";

export const AddClerk = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const [shop] = useShop();

  const companyId = company?.id;
  const shopId = shop?.shopId;

  const [addClerkMutation, { loading: loadingInsertClerk }] = useAddClerkInsertClerkMutation();

  const onSubmit = useCallback(
    async (clerk: Omit<ClerkInsertInput, "companyId" | "shopId">) => {
      if (!companyId) return;
      if (!shopId) return;
      try {
        const { data: addClerkData } = await addClerkMutation({
          variables: { clerk: { ...clerk, companyId, shopId } },
        });
        const clerkId = addClerkData?.insert_clerk_one?.clerkId;

        if (clerkId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate("/clerk", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addClerkMutation, navigate, companyId, shopId],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="店員を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "店員" }],
      }}
    >
      <PageHeader title="店員を新規作成" onBack={goBack} />
      <AddClerkForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertClerk}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
