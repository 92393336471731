import React, { memo } from "react";
import { Input } from "antd";
import { getTaxMethodName } from "models/taxMethod";

import { AddPlanChoiceFormItem } from "../useAddPlanChoiceForm";

type Props = {
  costTaxMethod: string;
};

export const CostTaxMethodField = memo<Props>(({ costTaxMethod }) => (
  <AddPlanChoiceFormItem.NonProperty label="原価税種別">
    {/* NOTE: 変更不可な仕様 */}
    <Input disabled={true} value={getTaxMethodName(costTaxMethod)} />
  </AddPlanChoiceFormItem.NonProperty>
));
