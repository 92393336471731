import React, { memo } from "react";
import styled, { css } from "styled-components";

import { FluctuatingStatusType } from "types/graphql";

import { FluctuatingStatusIcon } from "../FluctuatingStatusIcon";
import type { PanelProps, PanelSize } from "../types";

type PropsWithSize = {
  size: PanelSize;
};

const BG_COLOR = "#FFFFFF";

const Wrapper = styled.div<PropsWithSize>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 286px;
  height: 120px;
  background-color: ${BG_COLOR};
  box-shadow: 0px 22px 32px rgba(216, 233, 242, 0.45);
  border-radius: 10px;
  padding-right: 16px;
  padding-left: 8px;

  ${({ size }) =>
    size === "sm" &&
    css`
      height: 72px;
      padding-left: 6px;
    `}
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const Title = styled.p<PropsWithSize>`
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.customerDashboard.gray800};

  ${({ size }) =>
    size === "sm" &&
    css`
      margin-bottom: 2px;
    `}
`;

const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Value = styled.p<PropsWithSize>`
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: right;
  letter-spacing: -0.42px;
  color: ${({ theme }) => theme.customerDashboard.gray800};

  ${({ size }) =>
    size === "sm" &&
    css`
      font-size: 34px;
      line-height: 34px;
    `}
`;

const Unit = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  margin-left: 4px;
  margin-bottom: 0px;
`;

const CORNER_FOIL_COLOR: Record<FluctuatingStatusType, string> = {
  [FluctuatingStatusType.Up]: "#D1EFFC",
  [FluctuatingStatusType.Down]: "#FFD3D0",
  [FluctuatingStatusType.Keep]: "#E5E5E5",
};

const CornerFoil = styled.div<{ status: FluctuatingStatusType }>`
  position: absolute;
  transform-origin: top left;
  transform: matrix(0.8, -0.52, 0.67, 0.82, 0, 0);

  background-color: ${({ status }) => CORNER_FOIL_COLOR[status]};
`;

const FoilTopLeft = styled(CornerFoil)`
  left: -14%;
  right: 75%;
  top: 2%;
  bottom: 67%;
`;

const FoilBottomRight = styled(CornerFoil)`
  left: 78%;
  right: -17%;
  top: 110%;
  bottom: -41%;
`;

export const PanelPc = memo<PanelProps>(
  ({ title, value, unit, fluctuatingStatus, size = "md" }) => {
    const showCornerFoil = size === "md";

    const formatter = new Intl.NumberFormat("ja");

    return (
      <Wrapper size={size}>
        {showCornerFoil && <FoilTopLeft status={fluctuatingStatus} />}

        <FluctuatingStatusIcon status={fluctuatingStatus} size={size} />

        <TextWrapper>
          <Title size={size}>{title}</Title>
          <ValueWrapper>
            <Value size={size}>{formatter.format(value)}</Value>
            <Unit>{unit}</Unit>
          </ValueWrapper>
        </TextWrapper>

        {showCornerFoil && <FoilBottomRight status={fluctuatingStatus} />}
      </Wrapper>
    );
  },
);
