import React, { memo, ReactNode, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Modal, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { red } from "@ant-design/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  UpdatingChoice,
  UpdatingMenu,
  UpdatingPlan,
  UpdatingPlanChoice,
  ValidatedCsvBiMenuCode,
  ValidateMenuCodeResult,
} from "models/menuCodeCsv";

import { Spacer } from "components/Spacer";

type Props = {
  companyId: string | null;
  updatingMenus: ValidateMenuCodeResult["updatingMenus"];
  updatingPlans: ValidateMenuCodeResult["updatingPlans"];
  updatingChoices: ValidateMenuCodeResult["updatingChoices"];
  updatingPlanChoices: ValidateMenuCodeResult["updatingPlanChoices"];
  deletingMenus: ValidateMenuCodeResult["deletingMenus"];
  deletingChoices: ValidateMenuCodeResult["deletingChoices"];
  deletingPlans: ValidateMenuCodeResult["deletingPlans"];
  deletingPlanChoices: ValidateMenuCodeResult["deletingPlanChoices"];
  submit: () => void;
  closeModal: () => void;
};

const ModalTitle = styled.div`
  font-size: 18px;
`;

const TableTitle = styled.h3`
  font-size: 16px;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${red[5]};
`;

const ContentWrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const ConflictWarningText = styled.span`
  color: #ff4d4f;
`;

type ValidatedCsvBiMenuWithChanges = ValidatedCsvBiMenuCode & {
  changingMenuColumns?: UpdatingMenu["changingMenuColumns"];
  changingChoiceColumns?: UpdatingChoice["changingChoiceColumns"];
  changingPlanColumns?: UpdatingPlan["changingPlanColumns"];
  changingPlanChoiceColumns?: UpdatingPlanChoice["changingPlanChoiceColumns"];
};

type MenuTableColumnType = ColumnsType<ValidatedCsvBiMenuWithChanges>;

type Column = {
  title: string;
  width?: number;
  dataIndex?:
    | UpdatingMenu["changingMenuColumns"][number]
    | UpdatingChoice["changingChoiceColumns"][number]
    | UpdatingPlan["changingPlanColumns"][number]
    | UpdatingPlanChoice["changingPlanChoiceColumns"][number];
  render?: MenuTableColumnType[number]["render"];
};

const withCellStyles = ({
  isUpdated,
  isConflicted,
  element,
}: {
  isUpdated: boolean;
  isConflicted: boolean;
  element: ReactNode;
}) => ({
  props: {
    style: {
      fontWeight: isUpdated ? "bold" : "normal",
      ...(isUpdated
        ? {
            backgroundColor: isConflicted ? "#F06C631A" : "#FEFFE6",
          }
        : {}),
    },
  },
  children: element,
});

const createStyleParamsAndElement = ({
  column,
  menuRow,
  index,
}: {
  column: Column;
  menuRow: ValidatedCsvBiMenuWithChanges;
  index: number;
}) => ({
  isUpdated: column.dataIndex
    ? (
        menuRow.changingMenuColumns ??
        menuRow.changingChoiceColumns ??
        menuRow.changingPlanColumns ??
        menuRow.changingPlanChoiceColumns ??
        []
      ).includes(column.dataIndex)
    : false,
  isConflicted: menuRow.isConflicted,
  element: column.render
    ? column.render(null, menuRow, index)
    : column.dataIndex
    ? menuRow[column.dataIndex]
    : null,
});

const getWrappedColumns = (columns: Column[]): MenuTableColumnType =>
  columns.map((column) => ({
    title: column.title,
    render: (_, menuRow, index) =>
      withCellStyles(
        createStyleParamsAndElement({
          column,
          menuRow,
          index,
        }),
      ),
  }));

const updatingMenusColumns: MenuTableColumnType = getWrappedColumns([
  {
    title: "種別",
    width: 100,
    render: (_, { kind }) => {
      switch (kind) {
        case "menu":
          return "メニュー";
        case "choice":
          return "チョイス";
        case "plan":
          return "プラン";
        case "planChoice":
          return "プランチョイス";
        default:
          return "";
      }
    },
  },
  {
    title: "メニュー名",
    dataIndex: "menuName",
    width: 180,
    render: (_, { isConflicted, menuName }) => (
      <>
        {isConflicted ? (
          <>
            <Tooltip title="CSV ファイルのダウンロード後に、メニューコード情報が更新されています。更新された項目（ピンク色の箇所）をご確認ください。">
              <StyledExclamationCircleOutlined />
            </Tooltip>
            <Spacer size={5} inline />
          </>
        ) : null}
        {menuName}
      </>
    ),
  },
  {
    title: "メニューコード",
    dataIndex: "menuCode",
    width: 180,
  },
  {
    title: "部門コード",
    dataIndex: "dpCode",
    width: 100,
  },
  {
    title: "部門名",
    dataIndex: "dpName",
    width: 180,
  },
  {
    title: "分類コード",
    dataIndex: "gpCode",
    width: 100,
  },
  {
    title: "分類名",
    dataIndex: "gpName",
    width: 180,
  },
]);

type DeletingRow = {
  _menuId?: number;
  _choiceId?: number;
  _planId?: number;
  _planChoiceId?: number;
  menuName: string;
  kind: string;
};

const deletingMenusColumns: ColumnsType<DeletingRow> = [
  {
    title: "種別",
    width: 100,
    render: (_, { kind }) => {
      switch (kind) {
        case "menu":
          return "メニュー";
        case "choice":
          return "チョイス";
        case "plan":
          return "プラン";
        case "planChoice":
          return "プランチョイス";
        default:
          return "";
      }
    },
  },
  {
    title: "メニュー名",
    dataIndex: "name",
    width: 180,
  },
  {
    title: "メニューID",
    width: 110,
    render: (_, { kind, _menuId, _choiceId, _planId, _planChoiceId }) => {
      switch (kind) {
        case "menu":
          return _menuId;
        case "choice":
          return _choiceId;
        case "plan":
          return _planId;
        case "planChoice":
          return _planChoiceId;
        default:
          return "";
      }
    },
  },
];

export const UploadMenuCodeCsvVerifyModal = memo(
  ({
    updatingMenus,
    updatingChoices,
    updatingPlans,
    updatingPlanChoices,
    deletingMenus,
    deletingChoices,
    deletingPlans,
    deletingPlanChoices,
    submit,
    closeModal,
  }: Props) => {
    const updatingBiMenuRows = useMemo(() => {
      const menus = updatingMenus
        .filter(({ changingMenuColumns }) => changingMenuColumns.length !== 0)
        .map(({ _menuId, isConflicted, to, changingMenuColumns }) => ({
          ...to,
          _menuId,
          isConflicted,
          changingMenuColumns,
        }));
      const choices = updatingChoices
        .filter(({ changingChoiceColumns }) => changingChoiceColumns.length !== 0)
        .map(({ _choiceId, isConflicted, to, changingChoiceColumns }) => ({
          ...to,
          _choiceId,
          isConflicted,
          changingChoiceColumns,
        }));
      const plans = updatingPlans
        .filter(({ changingPlanColumns }) => changingPlanColumns.length !== 0)
        .map(({ _planId, isConflicted, to, changingPlanColumns }) => ({
          ...to,
          _planId,
          isConflicted,
          changingPlanColumns,
        }));
      const planChoices = updatingPlanChoices
        .filter(({ changingPlanChoiceColumns }) => changingPlanChoiceColumns.length !== 0)
        .map(({ _planChoiceId, isConflicted, to, changingPlanChoiceColumns }) => ({
          ...to,
          _planChoiceId,
          isConflicted,
          changingPlanChoiceColumns,
        }));

      return [...menus, ...choices, ...plans, ...planChoices];
    }, [updatingMenus, updatingChoices, updatingPlans, updatingPlanChoices]);

    const addingBiMenuRows = useMemo(() => {
      const menus = updatingMenus
        .filter(({ isNewEntry }) => isNewEntry)
        .map(({ to }) => ({ ...to }));
      const choices = updatingChoices
        .filter(({ isNewEntry }) => isNewEntry)
        .map(({ to }) => ({ ...to }));
      const plans = updatingPlans
        .filter(({ isNewEntry }) => isNewEntry)
        .map(({ to }) => ({ ...to }));
      const planChoices = updatingPlanChoices
        .filter(({ isNewEntry }) => isNewEntry)
        .map(({ to }) => ({ ...to }));

      return [...menus, ...choices, ...plans, ...planChoices];
    }, [updatingMenus, updatingChoices, updatingPlans, updatingPlanChoices]);

    const deletingBiMenuRows = useMemo(
      () => [...deletingMenus, ...deletingChoices, ...deletingPlans, ...deletingPlanChoices],
      [deletingMenus, deletingChoices, deletingPlans, deletingPlanChoices],
    );

    const hasConflictedUpdatingMenu = useMemo(
      () => updatingBiMenuRows.some(({ isConflicted }) => isConflicted),
      [updatingBiMenuRows],
    );

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    return (
      <Modal
        width="100%"
        title={<ModalTitle>更新内容確認</ModalTitle>}
        zIndex={1002}
        centered
        closable={false}
        open
        footer={
          <>
            <Button type="primary" onClick={handleSubmit}>
              メニューコードを一括更新
            </Button>
            <Button onClick={closeModal}>キャンセル</Button>
          </>
        }
        onCancel={closeModal}
      >
        <ContentWrapper>
          <Spacer size={16} />
          <TableTitle>追加対象のメニューコード {addingBiMenuRows.length}件</TableTitle>
          <Table
            scroll={{ x: 1000 }}
            dataSource={addingBiMenuRows}
            columns={updatingMenusColumns}
            rowKey={({ menuCode }) => `add-bi-menu-${menuCode}`}
          />
          <Spacer size={16} />
          <TableTitle>更新対象のメニューコード {updatingBiMenuRows.length}件</TableTitle>
          {hasConflictedUpdatingMenu ? (
            <>
              <ConflictWarningText>
                <ExclamationCircleOutlined />
                <Spacer size={5} inline />
                アイコンが表示されているメニューは、CSV
                ファイルのダウンロード後に、メニューコード情報が更新されています。更新された項目（ピンク色の箇所）をご確認ください。
              </ConflictWarningText>
              <Spacer size={5} />
            </>
          ) : null}
          <Table
            scroll={{ x: 1000 }}
            dataSource={updatingBiMenuRows}
            columns={updatingMenusColumns}
            rowKey={({ menuCode }) => `bi-menu-${menuCode}`}
          />

          <Spacer size={16} />
          <TableTitle>削除対象のメニューコード {deletingBiMenuRows.length}件</TableTitle>
          <Table dataSource={deletingBiMenuRows} columns={deletingMenusColumns} />
        </ContentWrapper>
      </Modal>
    );
  },
);
