import React, { memo, useState } from "react";
import styled from "styled-components";
import { Avatar, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { UserOutlined } from "@ant-design/icons";
import { isNotNull } from "util/type/primitive";

import { FormItemProps } from "components/antd/Form";
import { getCroppedUrl } from "libs/imgix";
import { Customer } from "pages/EditMessageDelivery/types";

import { TargetCustomers } from "../TargetCustomers";
import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

const { Option } = Select;

const Profile = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type Props = { customers: Customer[]; initialTargetCustomersCount: number } & Omit<
  FormItemProps,
  "children" | "name"
>;

export const CustomerListField = memo<Props>(
  ({ customers, initialTargetCustomersCount, ...props }) => {
    const [targetCustomersCount, setTargetCustomersCount] = useState(initialTargetCustomersCount);

    return (
      <>
        <MessageDeliveryJobFormItem
          label="LINEアカウント"
          name="customerIds"
          rules={[{ required: true, message: "アカウントを選択してください" }]}
          {...props}
        >
          <Select
            mode="multiple"
            optionFilterProp="name"
            showSearch
            onChange={(v) => {
              setTargetCustomersCount(v.length);
            }}
            options={customers
              .map<DefaultOptionType | null>(({ id, lineProfile }) => {
                if (lineProfile === null) return null;

                const url = getCroppedUrl({
                  url: lineProfile?.profileImage,
                  w: 24,
                  h: 24,
                  frame: 0,
                });
                return {
                  value: id,
                  name: lineProfile?.name,
                  label: (
                    <Profile>
                      <Avatar icon={<UserOutlined />} size={24} src={url} />
                      {lineProfile?.name}
                    </Profile>
                  ),
                };
              })
              .filter(isNotNull)}
          ></Select>
        </MessageDeliveryJobFormItem>
        <TargetCustomers number={targetCustomersCount} />
      </>
    );
  },
);
