import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditAccountFormItem } from "../useEditAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NewPasswordField = memo<Props>((props) => (
  <EditAccountFormItem
    name="new"
    rules={[
      { required: true, message: "パスワードを入力してください" },
      {
        pattern: new RegExp(/(?=.*[0-9a-zA-Z]).{6,}/),
        message: "パスワードは6文字以上の英数字を入力してください",
      },
    ]}
    {...props}
  >
    <Input.Password placeholder="新しいパスワード" />
  </EditAccountFormItem>
));
