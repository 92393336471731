import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditLineChannelConfigFormItem } from "../useEditLineChannelConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChannelIdField = memo<Props>((props) => (
  <EditLineChannelConfigFormItem
    label="チャネルID"
    name="channelId"
    wrapperCol={{ span: 20 }}
    {...props}
  >
    <InputCode />
  </EditLineChannelConfigFormItem>
));
