import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopDailyCashRegisterBalancingGetShops = gql`
    query ShopDailyCashRegisterBalancingGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export const ShopDailyCashRegisterBalancingGetDailySalesSummary = gql`
    query ShopDailyCashRegisterBalancingGetDailySalesSummary($input: QueryDailySalesSummaryInput!) {
  dailySalesSummary(input: $input) {
    closeCashRegisterId
    createdAt
    totalSales
    customerCount
    groupCount
  }
}
    `;
export const ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethod = gql`
    query ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethod($input: QuerySalesBreakdownByPaymentMethodInput!) {
  salesBreakdownByPaymentMethod(input: $input) {
    shopId
    closeCashRegisterId
    createdAt
    salesBreakdown {
      sales
      type
      label
    }
  }
}
    `;
export const ShopDailyCashRegisterBalancingGetCashBalance = gql`
    query ShopDailyCashRegisterBalancingGetCashBalance($input: QueryCashBalanceInput!) {
  cashBalance(input: $input) {
    closeCashRegisterId
    createdAt
    fiftyYenCoinCount
    fiveHundredYenCoinCount
    fiveThousandYenBillCount
    fiveYenCoinCount
    oneHundredYenCoinCount
    oneThousandYenBillCount
    oneYenCoinCount
    tenThousandYenBillCount
    tenYenCoinCount
    twoThousandYenBillCount
  }
}
    `;
export const ShopDailyCashRegisterBalancingGetCashManagement = gql`
    query ShopDailyCashRegisterBalancingGetCashManagement($input: QueryCashManagementInput!) {
  cashManagement(input: $input) {
    closeCashRegisterId
    createdAt
    actualCashAmount
    bankDepositAmount
    carriedForwardReserveCashAmount
    cashAmount
    cashDiscrepancies
    cashSales
    closeCashRegisterId
    depositAmount
    previousReserveCashAmount
    remark
    withdrawalAmount
  }
}
    `;
export type ShopDailyCashRegisterBalancingGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ShopDailyCashRegisterBalancingGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);

export type ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables = Types.Exact<{
  input: Types.QueryDailySalesSummaryInput;
}>;


export type ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery = (
  { __typename?: 'query_root' }
  & { dailySalesSummary: Array<(
    { __typename?: 'DailySalesSummary' }
    & Pick<Types.DailySalesSummary, 'closeCashRegisterId' | 'createdAt' | 'totalSales' | 'customerCount' | 'groupCount'>
  )> }
);

export type ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables = Types.Exact<{
  input: Types.QuerySalesBreakdownByPaymentMethodInput;
}>;


export type ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery = (
  { __typename?: 'query_root' }
  & { salesBreakdownByPaymentMethod: Array<(
    { __typename?: 'SalesBreakdownByPaymentMethod' }
    & Pick<Types.SalesBreakdownByPaymentMethod, 'shopId' | 'closeCashRegisterId' | 'createdAt'>
    & { salesBreakdown: Array<(
      { __typename?: 'SalesWithPaymentMethod' }
      & Pick<Types.SalesWithPaymentMethod, 'sales' | 'type' | 'label'>
    )> }
  )> }
);

export type ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables = Types.Exact<{
  input: Types.QueryCashBalanceInput;
}>;


export type ShopDailyCashRegisterBalancingGetCashBalanceQuery = (
  { __typename?: 'query_root' }
  & { cashBalance: Array<(
    { __typename?: 'CashBalanceCashDenominationCount' }
    & Pick<Types.CashBalanceCashDenominationCount, 'closeCashRegisterId' | 'createdAt' | 'fiftyYenCoinCount' | 'fiveHundredYenCoinCount' | 'fiveThousandYenBillCount' | 'fiveYenCoinCount' | 'oneHundredYenCoinCount' | 'oneThousandYenBillCount' | 'oneYenCoinCount' | 'tenThousandYenBillCount' | 'tenYenCoinCount' | 'twoThousandYenBillCount'>
  )> }
);

export type ShopDailyCashRegisterBalancingGetCashManagementQueryVariables = Types.Exact<{
  input: Types.QueryCashManagementInput;
}>;


export type ShopDailyCashRegisterBalancingGetCashManagementQuery = (
  { __typename?: 'query_root' }
  & { cashManagement: Array<(
    { __typename?: 'CashManagement' }
    & Pick<Types.CashManagement, 'closeCashRegisterId' | 'createdAt' | 'actualCashAmount' | 'bankDepositAmount' | 'carriedForwardReserveCashAmount' | 'cashAmount' | 'cashDiscrepancies' | 'cashSales' | 'depositAmount' | 'previousReserveCashAmount' | 'remark' | 'withdrawalAmount'>
  )> }
);


export const ShopDailyCashRegisterBalancingGetShopsDocument = gql`
    query ShopDailyCashRegisterBalancingGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useShopDailyCashRegisterBalancingGetShopsQuery__
 *
 * To run a query within a React component, call `useShopDailyCashRegisterBalancingGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDailyCashRegisterBalancingGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDailyCashRegisterBalancingGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useShopDailyCashRegisterBalancingGetShopsQuery(baseOptions: Apollo.QueryHookOptions<ShopDailyCashRegisterBalancingGetShopsQuery, ShopDailyCashRegisterBalancingGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopDailyCashRegisterBalancingGetShopsQuery, ShopDailyCashRegisterBalancingGetShopsQueryVariables>(ShopDailyCashRegisterBalancingGetShopsDocument, options);
      }
export function useShopDailyCashRegisterBalancingGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopDailyCashRegisterBalancingGetShopsQuery, ShopDailyCashRegisterBalancingGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopDailyCashRegisterBalancingGetShopsQuery, ShopDailyCashRegisterBalancingGetShopsQueryVariables>(ShopDailyCashRegisterBalancingGetShopsDocument, options);
        }
export type ShopDailyCashRegisterBalancingGetShopsQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetShopsQuery>;
export type ShopDailyCashRegisterBalancingGetShopsLazyQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetShopsLazyQuery>;
export type ShopDailyCashRegisterBalancingGetShopsQueryResult = Apollo.QueryResult<ShopDailyCashRegisterBalancingGetShopsQuery, ShopDailyCashRegisterBalancingGetShopsQueryVariables>;
export const ShopDailyCashRegisterBalancingGetDailySalesSummaryDocument = gql`
    query ShopDailyCashRegisterBalancingGetDailySalesSummary($input: QueryDailySalesSummaryInput!) {
  dailySalesSummary(input: $input) {
    closeCashRegisterId
    createdAt
    totalSales
    customerCount
    groupCount
  }
}
    `;

/**
 * __useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery__
 *
 * To run a query within a React component, call `useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery(baseOptions: Apollo.QueryHookOptions<ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery, ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery, ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables>(ShopDailyCashRegisterBalancingGetDailySalesSummaryDocument, options);
      }
export function useShopDailyCashRegisterBalancingGetDailySalesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery, ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery, ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables>(ShopDailyCashRegisterBalancingGetDailySalesSummaryDocument, options);
        }
export type ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery>;
export type ShopDailyCashRegisterBalancingGetDailySalesSummaryLazyQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetDailySalesSummaryLazyQuery>;
export type ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryResult = Apollo.QueryResult<ShopDailyCashRegisterBalancingGetDailySalesSummaryQuery, ShopDailyCashRegisterBalancingGetDailySalesSummaryQueryVariables>;
export const ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodDocument = gql`
    query ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethod($input: QuerySalesBreakdownByPaymentMethodInput!) {
  salesBreakdownByPaymentMethod(input: $input) {
    shopId
    closeCashRegisterId
    createdAt
    salesBreakdown {
      sales
      type
      label
    }
  }
}
    `;

/**
 * __useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery__
 *
 * To run a query within a React component, call `useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery, ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery, ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables>(ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodDocument, options);
      }
export function useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery, ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery, ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables>(ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodDocument, options);
        }
export type ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery>;
export type ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodLazyQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodLazyQuery>;
export type ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryResult = Apollo.QueryResult<ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery, ShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQueryVariables>;
export const ShopDailyCashRegisterBalancingGetCashBalanceDocument = gql`
    query ShopDailyCashRegisterBalancingGetCashBalance($input: QueryCashBalanceInput!) {
  cashBalance(input: $input) {
    closeCashRegisterId
    createdAt
    fiftyYenCoinCount
    fiveHundredYenCoinCount
    fiveThousandYenBillCount
    fiveYenCoinCount
    oneHundredYenCoinCount
    oneThousandYenBillCount
    oneYenCoinCount
    tenThousandYenBillCount
    tenYenCoinCount
    twoThousandYenBillCount
  }
}
    `;

/**
 * __useShopDailyCashRegisterBalancingGetCashBalanceQuery__
 *
 * To run a query within a React component, call `useShopDailyCashRegisterBalancingGetCashBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDailyCashRegisterBalancingGetCashBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDailyCashRegisterBalancingGetCashBalanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopDailyCashRegisterBalancingGetCashBalanceQuery(baseOptions: Apollo.QueryHookOptions<ShopDailyCashRegisterBalancingGetCashBalanceQuery, ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopDailyCashRegisterBalancingGetCashBalanceQuery, ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables>(ShopDailyCashRegisterBalancingGetCashBalanceDocument, options);
      }
export function useShopDailyCashRegisterBalancingGetCashBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopDailyCashRegisterBalancingGetCashBalanceQuery, ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopDailyCashRegisterBalancingGetCashBalanceQuery, ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables>(ShopDailyCashRegisterBalancingGetCashBalanceDocument, options);
        }
export type ShopDailyCashRegisterBalancingGetCashBalanceQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetCashBalanceQuery>;
export type ShopDailyCashRegisterBalancingGetCashBalanceLazyQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetCashBalanceLazyQuery>;
export type ShopDailyCashRegisterBalancingGetCashBalanceQueryResult = Apollo.QueryResult<ShopDailyCashRegisterBalancingGetCashBalanceQuery, ShopDailyCashRegisterBalancingGetCashBalanceQueryVariables>;
export const ShopDailyCashRegisterBalancingGetCashManagementDocument = gql`
    query ShopDailyCashRegisterBalancingGetCashManagement($input: QueryCashManagementInput!) {
  cashManagement(input: $input) {
    closeCashRegisterId
    createdAt
    actualCashAmount
    bankDepositAmount
    carriedForwardReserveCashAmount
    cashAmount
    cashDiscrepancies
    cashSales
    closeCashRegisterId
    depositAmount
    previousReserveCashAmount
    remark
    withdrawalAmount
  }
}
    `;

/**
 * __useShopDailyCashRegisterBalancingGetCashManagementQuery__
 *
 * To run a query within a React component, call `useShopDailyCashRegisterBalancingGetCashManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDailyCashRegisterBalancingGetCashManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDailyCashRegisterBalancingGetCashManagementQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopDailyCashRegisterBalancingGetCashManagementQuery(baseOptions: Apollo.QueryHookOptions<ShopDailyCashRegisterBalancingGetCashManagementQuery, ShopDailyCashRegisterBalancingGetCashManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopDailyCashRegisterBalancingGetCashManagementQuery, ShopDailyCashRegisterBalancingGetCashManagementQueryVariables>(ShopDailyCashRegisterBalancingGetCashManagementDocument, options);
      }
export function useShopDailyCashRegisterBalancingGetCashManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopDailyCashRegisterBalancingGetCashManagementQuery, ShopDailyCashRegisterBalancingGetCashManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopDailyCashRegisterBalancingGetCashManagementQuery, ShopDailyCashRegisterBalancingGetCashManagementQueryVariables>(ShopDailyCashRegisterBalancingGetCashManagementDocument, options);
        }
export type ShopDailyCashRegisterBalancingGetCashManagementQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetCashManagementQuery>;
export type ShopDailyCashRegisterBalancingGetCashManagementLazyQueryHookResult = ReturnType<typeof useShopDailyCashRegisterBalancingGetCashManagementLazyQuery>;
export type ShopDailyCashRegisterBalancingGetCashManagementQueryResult = Apollo.QueryResult<ShopDailyCashRegisterBalancingGetCashManagementQuery, ShopDailyCashRegisterBalancingGetCashManagementQueryVariables>;