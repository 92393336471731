import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Pagination, PaginationProps } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import type { RankingData } from "models/customerDashboard";

import { Loading } from "components/Loading";
import { colors } from "constants/colors";

import { CustomerRankingTable } from "./CustomerRankingTable";

const DEFAULT_PAGE_SIZE = 10;

const Wrapper = styled.div`
  background-color: #ffffff;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.Border.Default};
`;

const ArrowLeftIcon = styled(ArrowLeftOutlined)`
  margin-right: 8px;
  svg {
    fill: rgba(0, 0, 0, 0.85);
  }
`;

const HeaderText = styled.p`
  font-size: 20px;
  color: ${colors.Text.Default};
  font-weight: bold;
  margin-bottom: 0px;
`;

const TableWrapper = styled.div`
  padding: 0px 16px;
  min-height: 480px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 16px 32px;
`;

type Props = {
  rankingData: RankingData;
  loading: boolean;
  title: string;
  valueUnit: string;
};

export const CustomerRanking = memo<Props>(({ rankingData, loading, title, valueUnit }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const onChangePage: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (_, size) => {
    setPageSize(size);
  };

  const currentRankingData = useMemo(() => {
    const rankingDataStartIndex = (currentPage - 1) * pageSize;
    const rankingDataEndIndex = currentPage * pageSize;
    return rankingData.slice(rankingDataStartIndex, rankingDataEndIndex);
  }, [rankingData, currentPage, pageSize]);

  return (
    <Wrapper>
      <Header>
        <Link to="/">
          <ArrowLeftIcon />
        </Link>
        <HeaderText>{title}</HeaderText>
      </Header>

      {loading ? (
        <Loading height={586} />
      ) : (
        <>
          <TableWrapper>
            <CustomerRankingTable rankingData={currentRankingData} valueUnit={valueUnit} />
          </TableWrapper>

          <PaginationWrapper>
            <Pagination
              pageSize={pageSize}
              onChange={onChangePage}
              onShowSizeChange={onShowSizeChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} 人`}
              total={rankingData.length}
            />
          </PaginationWrapper>
        </>
      )}
    </Wrapper>
  );
});
