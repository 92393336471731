import dayjs from "dayjs";

import type { CustomerFaveYellRanking, CustomerRanking } from "types/graphql";

export type RankingData = {
  customerId: string;
  lastVisitedAt: string;
  lineProfileImage: string;
  lineProfileName: string;
  value: string;
  rank: number;
}[];

export const convertToRanking = (
  customers: CustomerRanking["customers"] | undefined,
): RankingData => {
  if (!customers) return [];

  const formatter = new Intl.NumberFormat("ja");

  return customers.map((customer, index) => ({
    customerId: customer.customerId,
    lastVisitedAt: dayjs(customer.lastVisitedAt).format("YYYY/M/D H:mm"),
    lineProfileImage: customer.lineProfileImage,
    lineProfileName: customer.lineProfileName,
    value: formatter.format(customer.value),
    rank: index + 1,
  }));
};

export const convertToFaveYellRanking = (
  customers: CustomerFaveYellRanking["customers"] | undefined,
): RankingData => {
  if (!customers) return [];

  const formatter = new Intl.NumberFormat("ja");

  return customers.map((customer, index) => ({
    customerId: customer.customerId,
    lastVisitedAt: dayjs(customer.lastFaveYellCreatedAt).format("YYYY/M/D H:mm"),
    lineProfileImage: customer.lineProfileImage,
    lineProfileName: customer.lineProfileName,
    value: formatter.format(customer.value),
    rank: index + 1,
  }));
};
