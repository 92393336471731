import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuTecAggregationMenuFormItem } from "../useEditMenuTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationDpNameField = memo<Props>((props) => (
  <EditMenuTecAggregationMenuFormItem
    label="部門名"
    name="dpName"
    rules={[{ required: true, max: 20, message: "20文字以下で入力してください" }]}
    required
    {...props}
  >
    <Input />
  </EditMenuTecAggregationMenuFormItem>
));
