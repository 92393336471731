import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MobileOrderDesignSettingsGetDesignSettings = gql`
    query MobileOrderDesignSettingsGetDesignSettings($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}}) {
    id
    enableDarkTheme
  }
}
    `;
export const MobileOrderDesignSettingsUpdateEnableDarkTheme = gql`
    mutation MobileOrderDesignSettingsUpdateEnableDarkTheme($companyId: uuid!, $enableDarkTheme: Boolean!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {enableDarkTheme: $enableDarkTheme}
  ) {
    returning {
      id
      enableDarkTheme
    }
  }
}
    `;
export type MobileOrderDesignSettingsGetDesignSettingsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MobileOrderDesignSettingsGetDesignSettingsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'id' | 'enableDarkTheme'>
  )> }
);

export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  enableDarkTheme: Types.Scalars['Boolean'];
}>;


export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutation = (
  { __typename?: 'mutation_root' }
  & { update_company?: Types.Maybe<(
    { __typename?: 'company_mutation_response' }
    & { returning: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'id' | 'enableDarkTheme'>
    )> }
  )> }
);


export const MobileOrderDesignSettingsGetDesignSettingsDocument = gql`
    query MobileOrderDesignSettingsGetDesignSettings($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}}) {
    id
    enableDarkTheme
  }
}
    `;

/**
 * __useMobileOrderDesignSettingsGetDesignSettingsQuery__
 *
 * To run a query within a React component, call `useMobileOrderDesignSettingsGetDesignSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMobileOrderDesignSettingsGetDesignSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMobileOrderDesignSettingsGetDesignSettingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMobileOrderDesignSettingsGetDesignSettingsQuery(baseOptions: Apollo.QueryHookOptions<MobileOrderDesignSettingsGetDesignSettingsQuery, MobileOrderDesignSettingsGetDesignSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MobileOrderDesignSettingsGetDesignSettingsQuery, MobileOrderDesignSettingsGetDesignSettingsQueryVariables>(MobileOrderDesignSettingsGetDesignSettingsDocument, options);
      }
export function useMobileOrderDesignSettingsGetDesignSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MobileOrderDesignSettingsGetDesignSettingsQuery, MobileOrderDesignSettingsGetDesignSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MobileOrderDesignSettingsGetDesignSettingsQuery, MobileOrderDesignSettingsGetDesignSettingsQueryVariables>(MobileOrderDesignSettingsGetDesignSettingsDocument, options);
        }
export type MobileOrderDesignSettingsGetDesignSettingsQueryHookResult = ReturnType<typeof useMobileOrderDesignSettingsGetDesignSettingsQuery>;
export type MobileOrderDesignSettingsGetDesignSettingsLazyQueryHookResult = ReturnType<typeof useMobileOrderDesignSettingsGetDesignSettingsLazyQuery>;
export type MobileOrderDesignSettingsGetDesignSettingsQueryResult = Apollo.QueryResult<MobileOrderDesignSettingsGetDesignSettingsQuery, MobileOrderDesignSettingsGetDesignSettingsQueryVariables>;
export const MobileOrderDesignSettingsUpdateEnableDarkThemeDocument = gql`
    mutation MobileOrderDesignSettingsUpdateEnableDarkTheme($companyId: uuid!, $enableDarkTheme: Boolean!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {enableDarkTheme: $enableDarkTheme}
  ) {
    returning {
      id
      enableDarkTheme
    }
  }
}
    `;
export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutationFn = Apollo.MutationFunction<MobileOrderDesignSettingsUpdateEnableDarkThemeMutation, MobileOrderDesignSettingsUpdateEnableDarkThemeMutationVariables>;

/**
 * __useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation__
 *
 * To run a mutation, you first call `useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mobileOrderDesignSettingsUpdateEnableDarkThemeMutation, { data, loading, error }] = useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      enableDarkTheme: // value for 'enableDarkTheme'
 *   },
 * });
 */
export function useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation(baseOptions?: Apollo.MutationHookOptions<MobileOrderDesignSettingsUpdateEnableDarkThemeMutation, MobileOrderDesignSettingsUpdateEnableDarkThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MobileOrderDesignSettingsUpdateEnableDarkThemeMutation, MobileOrderDesignSettingsUpdateEnableDarkThemeMutationVariables>(MobileOrderDesignSettingsUpdateEnableDarkThemeDocument, options);
      }
export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutationHookResult = ReturnType<typeof useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation>;
export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutationResult = Apollo.MutationResult<MobileOrderDesignSettingsUpdateEnableDarkThemeMutation>;
export type MobileOrderDesignSettingsUpdateEnableDarkThemeMutationOptions = Apollo.BaseMutationOptions<MobileOrderDesignSettingsUpdateEnableDarkThemeMutation, MobileOrderDesignSettingsUpdateEnableDarkThemeMutationVariables>;