import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddMenuFormItem
    label={<FormHelp label="メニュー名" help="お客様が見る注文画面や会計伝票での表示名" />}
    name="name"
    rules={[{ required: true, message: "メニュー名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddMenuFormItem>
));
