import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DailyCashRegisterBalancingGetDailyCashRegisterBalancing = gql`
    query DailyCashRegisterBalancingGetDailyCashRegisterBalancing($input: QueryDailyCashRegisterBalancingInput!) {
  dailyCashRegisterBalancing(input: $input) {
    shopDailyCashRegisterBalancings {
      closeCashRegisterId
      createdAt
      cashDiscrepancies
      depositAmount
      shopId
      totalSales
    }
  }
}
    `;
export const DailyCashRegisterBalancingGetShops = gql`
    query DailyCashRegisterBalancingGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;
export type DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables = Types.Exact<{
  input: Types.QueryDailyCashRegisterBalancingInput;
}>;


export type DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery = (
  { __typename?: 'query_root' }
  & { dailyCashRegisterBalancing: (
    { __typename?: 'DailyCashRegisterBalancing' }
    & { shopDailyCashRegisterBalancings: Array<(
      { __typename?: 'ShopDailyCashRegisterBalancing' }
      & Pick<Types.ShopDailyCashRegisterBalancing, 'closeCashRegisterId' | 'createdAt' | 'cashDiscrepancies' | 'depositAmount' | 'shopId' | 'totalSales'>
    )> }
  ) }
);

export type DailyCashRegisterBalancingGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type DailyCashRegisterBalancingGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'name' | 'shopId'>
  )> }
);


export const DailyCashRegisterBalancingGetDailyCashRegisterBalancingDocument = gql`
    query DailyCashRegisterBalancingGetDailyCashRegisterBalancing($input: QueryDailyCashRegisterBalancingInput!) {
  dailyCashRegisterBalancing(input: $input) {
    shopDailyCashRegisterBalancings {
      closeCashRegisterId
      createdAt
      cashDiscrepancies
      depositAmount
      shopId
      totalSales
    }
  }
}
    `;

/**
 * __useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery__
 *
 * To run a query within a React component, call `useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery(baseOptions: Apollo.QueryHookOptions<DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery, DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery, DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables>(DailyCashRegisterBalancingGetDailyCashRegisterBalancingDocument, options);
      }
export function useDailyCashRegisterBalancingGetDailyCashRegisterBalancingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery, DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery, DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables>(DailyCashRegisterBalancingGetDailyCashRegisterBalancingDocument, options);
        }
export type DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryHookResult = ReturnType<typeof useDailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery>;
export type DailyCashRegisterBalancingGetDailyCashRegisterBalancingLazyQueryHookResult = ReturnType<typeof useDailyCashRegisterBalancingGetDailyCashRegisterBalancingLazyQuery>;
export type DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryResult = Apollo.QueryResult<DailyCashRegisterBalancingGetDailyCashRegisterBalancingQuery, DailyCashRegisterBalancingGetDailyCashRegisterBalancingQueryVariables>;
export const DailyCashRegisterBalancingGetShopsDocument = gql`
    query DailyCashRegisterBalancingGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    name
    shopId
  }
}
    `;

/**
 * __useDailyCashRegisterBalancingGetShopsQuery__
 *
 * To run a query within a React component, call `useDailyCashRegisterBalancingGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyCashRegisterBalancingGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyCashRegisterBalancingGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDailyCashRegisterBalancingGetShopsQuery(baseOptions: Apollo.QueryHookOptions<DailyCashRegisterBalancingGetShopsQuery, DailyCashRegisterBalancingGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyCashRegisterBalancingGetShopsQuery, DailyCashRegisterBalancingGetShopsQueryVariables>(DailyCashRegisterBalancingGetShopsDocument, options);
      }
export function useDailyCashRegisterBalancingGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyCashRegisterBalancingGetShopsQuery, DailyCashRegisterBalancingGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyCashRegisterBalancingGetShopsQuery, DailyCashRegisterBalancingGetShopsQueryVariables>(DailyCashRegisterBalancingGetShopsDocument, options);
        }
export type DailyCashRegisterBalancingGetShopsQueryHookResult = ReturnType<typeof useDailyCashRegisterBalancingGetShopsQuery>;
export type DailyCashRegisterBalancingGetShopsLazyQueryHookResult = ReturnType<typeof useDailyCashRegisterBalancingGetShopsLazyQuery>;
export type DailyCashRegisterBalancingGetShopsQueryResult = Apollo.QueryResult<DailyCashRegisterBalancingGetShopsQuery, DailyCashRegisterBalancingGetShopsQueryVariables>;