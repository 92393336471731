import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { MenuHeader } from "components/PageHeader/MenuHeader";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { EditMenuTranslationsForm } from "./EditMenuTranslationsForm";
import {
  useEditMenuTranslationsGetMenuQuery,
  useEditMenuTranslationsUpdateMenuTranslationsMutation,
} from "./queries";

export const EditMenuTranslations = () => {
  const { id } = useParams<{ id: string }>();
  const menuId = Number(id);
  const navigate = useNavigate();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const {
    data: getMenuData,
    loading: loadingMenu,
    error: errorGetMenuData,
    refetch: refetchGetMenuData,
  } = useEditMenuTranslationsGetMenuQuery(
    !Number.isNaN(menuId) ? { variables: { menuId } } : { skip: true },
  );
  const menu = useMemo(() => getMenuData?.menu?.[0], [getMenuData?.menu]);
  const translations = useMemo(() => menu?.translations ?? [], [menu?.translations]);

  const [
    updateMenuTranslationsMutation,
    { loading: loadingUpdateMenuTranslationsMutation, error: errorUpdateMenuTranslationsMutation },
  ] = useEditMenuTranslationsUpdateMenuTranslationsMutation();

  const [
    { loading: loadingUpdateMenuTranslations, error: errorUpdateMenuTranslations },
    updateMenuTranslations,
  ] = useAsyncFn(
    async ({ menuId, companyId, nameSources, descriptionSources, featureLabelSources }) => {
      await updateMenuTranslationsMutation({
        variables: {
          input: {
            companyId,
            menuId,
            nameSources,
            descriptionSources,
            featureLabelSources,
          },
        },
      });

      message.success("編集を保存しました");

      goBack();

      refetchGetMenuData();
    },
    [updateMenuTranslationsMutation, goBack, refetchGetMenuData],
  );

  const showErrorMessage = useCallback(() => {
    message.error("編集に失敗しました");
  }, []);

  const loading =
    loadingMenu || loadingUpdateMenuTranslations || loadingUpdateMenuTranslationsMutation;
  const error =
    errorGetMenuData || errorUpdateMenuTranslations || errorUpdateMenuTranslationsMutation;

  const isEditMenuFeatureEnabled = isFeatureEnabled("editMenu");
  const canEditAllShopMenus = menu?.totalDealingShopCount === menu?.shopMenus.length;

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "メニュー" }],
      }}
    >
      <MenuHeader
        menu={menu ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditMenuFeatureEnabled && !canEditAllShopMenus}
      />
      {loading && <Loading height={300} />}
      {menu && (
        <EditMenuTranslationsForm
          menu={menu}
          translations={translations}
          onSubmit={updateMenuTranslations}
          onClose={goBack}
          onFormValidationError={showErrorMessage}
          disabled={!isEditMenuFeatureEnabled || !canEditAllShopMenus}
        />
      )}
    </DashboardLayout>
  );
};
