import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox, Col, Row } from "antd";
import * as holiday_jp from "@holiday-jp/holiday_jp";
import dayjs from "dayjs";
import { DayOfWeek } from "models/dayOfWeek";

import { withFormDependencies } from "components/antd/Form";
import { InputPrice } from "components/Input/InputPrice";
import { colors, grey } from "constants/colors";

import { AddSalesBudgetFormItem } from "../../useAddSalesBudgetForm";

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  border-top: 2px solid ${grey[3]};
  padding: 6px 8px 12px;
  margin: 0 4px;
  cursor: auto;
  text-align: left;
`;

const Date = styled(Col).attrs({ span: 4 })<{ dayOfWeek: string; isHoliday: boolean }>`
  color: ${({ isHoliday, dayOfWeek }) =>
    isHoliday || dayOfWeek === `${DayOfWeek.Sun}`
      ? "#F46F46"
      : dayOfWeek === `${DayOfWeek.Sat}`
      ? `${colors.Text.Primary}`
      : "inherit"};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
`;

const Label = styled.span`
  color: ${colors.Text.Secondary};
  font-size: 12px;
`;

type Props = {
  targetMonth: dayjs.Dayjs;
  date: dayjs.Dayjs;
};

export const CalendarCell = memo<Props>(({ targetMonth, date }) => (
  <Cell>
    {targetMonth.month() === date.month() && (
      <>
        <Row justify="space-between">
          <Date dayOfWeek={date.format("ddd")} isHoliday={holiday_jp.isHoliday(date.toDate())}>
            {date.format("D")}
          </Date>
          <Col flex="36px" style={{ textAlign: "right" }}>
            <AddSalesBudgetFormItem.NonProperty
              noStyle
              shouldUpdate={withFormDependencies(({ dayOff }) => [
                dayOff?.[date.format("YYYY-MM-DD")],
              ])}
            >
              {({ getFieldsValue, setFieldValue }) => {
                const { dayOff: dayOffValues } = getFieldsValue();
                const dayOff = dayOffValues?.[date.format("YYYY-MM-DD")];
                return (
                  <CheckboxWrapper>
                    {dayOff && <Label>休</Label>}
                    <AddSalesBudgetFormItem
                      name={["dayOff", date.format("YYYY-MM-DD")]}
                      valuePropName="checked"
                      noStyle
                    >
                      <Checkbox
                        onChange={() =>
                          setFieldValue(["dailyBudget", date.format("YYYY-MM-DD")], 0)
                        }
                      />
                    </AddSalesBudgetFormItem>
                  </CheckboxWrapper>
                );
              }}
            </AddSalesBudgetFormItem.NonProperty>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <AddSalesBudgetFormItem.NonProperty
              noStyle
              shouldUpdate={withFormDependencies(({ dayOff }) => [
                dayOff?.[date.format("YYYY-MM-DD")],
              ])}
            >
              {({ getFieldsValue }) => {
                const { dayOff: dayOffValues } = getFieldsValue();
                const dayOff = dayOffValues?.[date.format("YYYY-MM-DD")];
                return (
                  <AddSalesBudgetFormItem name={["dailyBudget", date.format("YYYY-MM-DD")]} noStyle>
                    <InputPrice disabled={dayOff} onKeyDown={(e) => e.stopPropagation()} />
                  </AddSalesBudgetFormItem>
                );
              }}
            </AddSalesBudgetFormItem.NonProperty>
          </Col>
        </Row>
      </>
    )}
  </Cell>
));
