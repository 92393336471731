import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { WinboardMenuInsertInput } from "types/graphql";

import { PlanChoiceWithWinboardMenu, WinboardMenu } from "../../types";

export type EditPlanChoiceWinboardMenuFormValues = Pick<
  WinboardMenu,
  "name" | "code" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
>;

export const EditPlanChoiceWinboardMenuFormItem =
  createFormItem<EditPlanChoiceWinboardMenuFormValues>();

export const useEditPlanChoiceWinboardMenuForm = ({
  planChoice,
  winboardMenu,
  onSubmit,
}: {
  planChoice?: PlanChoiceWithWinboardMenu;
  winboardMenu?: WinboardMenu;
  onSubmit: (args: Omit<WinboardMenuInsertInput, "companyId">) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditPlanChoiceWinboardMenuFormValues>(
    () => ({
      name: winboardMenu?.name ?? planChoice?.name ?? "",
      code: winboardMenu?.code ?? "",
      bumonCode: winboardMenu?.bumonCode ?? "",
      bumonName: winboardMenu?.bumonName ?? "",
      categoryCode: winboardMenu?.categoryCode ?? "",
      categoryName: winboardMenu?.categoryName ?? "",
    }),
    [planChoice, winboardMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditPlanChoiceWinboardMenuFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
