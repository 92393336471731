import React, { memo, useCallback } from "react";
import { Button, DatePicker } from "antd";
import { Dayjs } from "dayjs";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import {
  EditTargetDateFormItem,
  EditTargetDateFormValues,
  useEditTargetDateForm,
} from "./useEditTargetDateForm";

type Props = {
  targetDate: Dayjs;
  onSubmit: (option: EditTargetDateFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  onCancel: () => void;
  loading: boolean;
};

export const EditTargetDateForm = memo<Props>(
  ({ targetDate, onSubmit, onFormValidationError, onCancel, loading }) => {
    const { form, initialValues, submit } = useEditTargetDateForm(targetDate, onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <>
        <FormContent>
          <Form name="editTargetDate" form={form} initialValues={initialValues} layout="vertical">
            <EditTargetDateFormItem
              label="対象日"
              name="targetDate"
              rules={[{ required: true, message: "対象日を指定してください" }]}
            >
              <DatePicker allowClear={false} />
            </EditTargetDateFormItem>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button key="update" type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>
        </FormActions>
      </>
    );
  },
);
