import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTecAggregationConfigGetShopTecAggregationConfig = gql`
    query EditTecAggregationConfigGetShopTecAggregationConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    tecAggregationConfig {
      id
      enabled
      companyCode
      shopCode
      posNumber
      ftpHost
      ftpPassword
      ftpPort
      ftpUser
      integrationType
      salesDataPutPath
    }
  }
}
    `;
export const EditTecAggregationConfigUpsertTecAggregationConfig = gql`
    mutation EditTecAggregationConfigUpsertTecAggregationConfig($tecAggregationConfig: tecAggregationConfig_insert_input!) {
  insert_tecAggregationConfig_one(
    object: $tecAggregationConfig
    on_conflict: {constraint: tecAggregationConfig_pkey, update_columns: [enabled, companyCode, shopCode, posNumber, ftpHost, ftpPassword, ftpPort, ftpUser, salesDataPutPath, integrationType]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditTecAggregationConfigGetShopTecAggregationConfigQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { tecAggregationConfig?: Types.Maybe<(
      { __typename?: 'tecAggregationConfig' }
      & Pick<Types.TecAggregationConfig, 'id' | 'enabled' | 'companyCode' | 'shopCode' | 'posNumber' | 'ftpHost' | 'ftpPassword' | 'ftpPort' | 'ftpUser' | 'integrationType' | 'salesDataPutPath'>
    )> }
  )> }
);

export type EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables = Types.Exact<{
  tecAggregationConfig: Types.TecAggregationConfigInsertInput;
}>;


export type EditTecAggregationConfigUpsertTecAggregationConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tecAggregationConfig_one?: Types.Maybe<(
    { __typename?: 'tecAggregationConfig' }
    & Pick<Types.TecAggregationConfig, 'id'>
  )> }
);


export const EditTecAggregationConfigGetShopTecAggregationConfigDocument = gql`
    query EditTecAggregationConfigGetShopTecAggregationConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    tecAggregationConfig {
      id
      enabled
      companyCode
      shopCode
      posNumber
      ftpHost
      ftpPassword
      ftpPort
      ftpUser
      integrationType
      salesDataPutPath
    }
  }
}
    `;

/**
 * __useEditTecAggregationConfigGetShopTecAggregationConfigQuery__
 *
 * To run a query within a React component, call `useEditTecAggregationConfigGetShopTecAggregationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationConfigGetShopTecAggregationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTecAggregationConfigGetShopTecAggregationConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditTecAggregationConfigGetShopTecAggregationConfigQuery(baseOptions: Apollo.QueryHookOptions<EditTecAggregationConfigGetShopTecAggregationConfigQuery, EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditTecAggregationConfigGetShopTecAggregationConfigQuery, EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables>(EditTecAggregationConfigGetShopTecAggregationConfigDocument, options);
      }
export function useEditTecAggregationConfigGetShopTecAggregationConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditTecAggregationConfigGetShopTecAggregationConfigQuery, EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditTecAggregationConfigGetShopTecAggregationConfigQuery, EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables>(EditTecAggregationConfigGetShopTecAggregationConfigDocument, options);
        }
export type EditTecAggregationConfigGetShopTecAggregationConfigQueryHookResult = ReturnType<typeof useEditTecAggregationConfigGetShopTecAggregationConfigQuery>;
export type EditTecAggregationConfigGetShopTecAggregationConfigLazyQueryHookResult = ReturnType<typeof useEditTecAggregationConfigGetShopTecAggregationConfigLazyQuery>;
export type EditTecAggregationConfigGetShopTecAggregationConfigQueryResult = Apollo.QueryResult<EditTecAggregationConfigGetShopTecAggregationConfigQuery, EditTecAggregationConfigGetShopTecAggregationConfigQueryVariables>;
export const EditTecAggregationConfigUpsertTecAggregationConfigDocument = gql`
    mutation EditTecAggregationConfigUpsertTecAggregationConfig($tecAggregationConfig: tecAggregationConfig_insert_input!) {
  insert_tecAggregationConfig_one(
    object: $tecAggregationConfig
    on_conflict: {constraint: tecAggregationConfig_pkey, update_columns: [enabled, companyCode, shopCode, posNumber, ftpHost, ftpPassword, ftpPort, ftpUser, salesDataPutPath, integrationType]}
  ) {
    id
  }
}
    `;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationFn = Apollo.MutationFunction<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>;

/**
 * __useEditTecAggregationConfigUpsertTecAggregationConfigMutation__
 *
 * To run a mutation, you first call `useEditTecAggregationConfigUpsertTecAggregationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTecAggregationConfigUpsertTecAggregationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTecAggregationConfigUpsertTecAggregationConfigMutation, { data, loading, error }] = useEditTecAggregationConfigUpsertTecAggregationConfigMutation({
 *   variables: {
 *      tecAggregationConfig: // value for 'tecAggregationConfig'
 *   },
 * });
 */
export function useEditTecAggregationConfigUpsertTecAggregationConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>(EditTecAggregationConfigUpsertTecAggregationConfigDocument, options);
      }
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationHookResult = ReturnType<typeof useEditTecAggregationConfigUpsertTecAggregationConfigMutation>;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationResult = Apollo.MutationResult<EditTecAggregationConfigUpsertTecAggregationConfigMutation>;
export type EditTecAggregationConfigUpsertTecAggregationConfigMutationOptions = Apollo.BaseMutationOptions<EditTecAggregationConfigUpsertTecAggregationConfigMutation, EditTecAggregationConfigUpsertTecAggregationConfigMutationVariables>;