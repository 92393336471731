import React, { memo } from "react";

import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { InputNumber } from "components/Input/InputNumber";

type Props = {
  value: number | undefined;
  initialValue: number | undefined;
  categoryMenuId: number;
  disabled: boolean;
};

export const CostPriceField = memo<Props>(({ initialValue, value, categoryMenuId, disabled }) => (
  <CostPriceWithCostTaxRateField
    name={[categoryMenuId, "costPrice"]}
    costTaxRateFieldName={[categoryMenuId, "costTaxRate"]}
    noStyle
    disabled={initialValue !== undefined}
  >
    <InputNumber
      defaultValue={value}
      placeholder={initialValue ? initialValue.toString() : ""}
      min={0}
      step={0.01}
      precision={2}
      fullWidth
      disabled={disabled}
    />
  </CostPriceWithCostTaxRateField>
));
