import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Col, List, Row } from "antd";

import { QuestionnaireAnalyticsCommentModal } from "components/QuestionnaireAnalyticsCommentModal";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useViewport } from "hooks/useViewport";
import { QuestionnaireComment } from "types/graphql";

const StyledListItem = styled(List.Item)`
  &:hover {
    background-color: ${colors.BackGround.Tertiary};
  }
`;

const StyledImg = styled.img`
  width: 32px;
  height: 32px;
`;

const StyledButton = styled(Button)`
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const Comment = styled.div`
  margin: 0;
  font-size: 14px;
  color: #000000;
  word-break: break-all;
`;

const Attribute = styled.div`
  display: flex;
  gap: 12px;
  margin: 0;
  font-size: 12px;
  color: ${colors.Text.Secondary};
`;

const AttributeItem = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  comment: QuestionnaireComment;
};

export const ListItem = memo<Props>(({ comment }) => {
  const { isDesktop } = useViewport();

  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const openModal = useCallback(() => setIsVisibleModal(true), [setIsVisibleModal]);
  const dismissModal = useCallback(() => setIsVisibleModal(false), [setIsVisibleModal]);

  return (
    <>
      <StyledListItem>
        <Row align="middle" gutter={[isDesktop ? 24 : 16, 0]} wrap={false}>
          <Spacer size={16} />
          <Col>
            <StyledImg
              src={
                comment.score >= 80
                  ? "/questionnaire-analytics/sun.png"
                  : "/questionnaire-analytics/cloud.png"
              }
              alt="icon"
            />
          </Col>
          <Col>
            <Comment>{comment.text}</Comment>
            <Attribute>
              <AttributeItem>{comment.shopName}</AttributeItem>
              <AttributeItem>{comment.gender ?? "未回答"}</AttributeItem>
              <AttributeItem>{comment.age ? `${comment.age}歳` : "未回答"}</AttributeItem>
              <AttributeItem>{comment.visitedAt}</AttributeItem>
              <StyledButton type="link" onClick={openModal}>
                回答をみる
              </StyledButton>
            </Attribute>
          </Col>
        </Row>
      </StyledListItem>
      <QuestionnaireAnalyticsCommentModal
        customerQuestionnaireId={comment.customerQuestionnaireId}
        isVisibleModal={isVisibleModal}
        onDismiss={dismissModal}
      />
    </>
  );
});
