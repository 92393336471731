import dayjs from "dayjs";
import { range } from "lodash";
import { sum } from "util/array";

import { UnreachableError } from "libs/unreachable";
import { SalesBudgetAllocationSetting } from "types/graphql";

export const dayOfWeekToSalesBudgetAllocationSettingColumnName = {
  月: "monRate",
  火: "tueRate",
  水: "wedRate",
  木: "thuRate",
  金: "friRate",
  土: "satRate",
  日: "sunRate",
} as const;

export const getDayOfWeek = (target: dayjs.Dayjs) => {
  const dayOfWeek = target.format(
    "ddd",
  ) as keyof typeof dayOfWeekToSalesBudgetAllocationSettingColumnName;
  switch (dayOfWeek) {
    case "月":
    case "火":
    case "水":
    case "木":
    case "金":
    case "土":
    case "日":
      return dayOfWeek;
    default:
      throw new UnreachableError(dayOfWeek);
  }
};

export const getAllocatedDailySalesBudget = ({
  targetMonth,
  monthlyBudget,
  dayOffDates,
  allocationSetting,
}: {
  targetMonth: dayjs.Dayjs;
  monthlyBudget: number;
  dayOffDates: string[];
  allocationSetting: Pick<
    SalesBudgetAllocationSetting,
    "monRate" | "tueRate" | "wedRate" | "thuRate" | "friRate" | "satRate" | "sunRate"
  >;
}) => {
  const denominator = sum(
    range(0, targetMonth.daysInMonth()).map((i) => {
      const date = targetMonth.add(i, "day").format("YYYY-MM-DD");
      const dayOfWeek = getDayOfWeek(targetMonth.add(i, "day"));
      return dayOffDates.includes(date)
        ? 0
        : allocationSetting[dayOfWeekToSalesBudgetAllocationSettingColumnName[dayOfWeek]];
    }),
  );
  const allocatedDailyBudget = Object.fromEntries(
    range(0, targetMonth.daysInMonth()).map((i) => {
      const current = targetMonth.add(i, "day");
      const date = current.format("YYYY-MM-DD");
      const dayOfWeek = getDayOfWeek(current);
      return [
        date,
        dayOffDates.includes(date)
          ? 0
          : Math.floor(
              (monthlyBudget / denominator) *
                allocationSetting[dayOfWeekToSalesBudgetAllocationSettingColumnName[dayOfWeek]],
            ),
      ];
    }),
  );
  return allocatedDailyBudget;
};
