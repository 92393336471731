import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { EditMenuRecommendationTranslationsFormItem } from "./useEditMenuRecommendationTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameCnField = memo<Props>(({ ...props }) => (
  <EditMenuRecommendationTranslationsFormItem
    label="表示名：中国語 (簡体字)"
    name="nameCn"
    {...props}
  >
    <Input />
  </EditMenuRecommendationTranslationsFormItem>
));
