import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputPrice } from "components/Input/InputPrice";

import { EditPlanChoiceFormItem } from "../useEditPlanChoiceForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled: boolean;
};

export const PriceField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanChoiceFormItem
    label="価格"
    name="price"
    rules={[{ required: true, message: "価格を入力してください" }]}
    {...props}
  >
    <InputPrice disabled={disabled} />
  </EditPlanChoiceFormItem>
));
