import { useCallback, useState } from "react";
import { Store } from "antd/lib/form/interface";
import dayjs from "dayjs";
import {
  findSortKeyByDayWeek,
  formatExternalOnlineMenuAvailableTimeTermDayWeek,
  isExternalOnlineMenuAvailableTimeTermDayWeek,
} from "models/externalOnlineMenuAvailableTime";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { normalizeDuration } from "util/duration";
import { isNotNull } from "util/type/primitive";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import {
  ExternalOnlineMenu,
  ExternalOnlineMenuAvailableTimeTermInsertInput,
  ExternalOnlineMenuSetInput,
  OrderableTimeTermDayWeekEnum,
} from "types/graphql";

import { ExternalOnlineMenu as ExternalOnlineMenuInitialValues } from "../types";

type EditShopExternalOnlineMenuFormValues = Pick<ExternalOnlineMenu, "name" | "url"> & {
  terms: ({
    dayWeek?: { value: OrderableTimeTermDayWeekEnum };
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  } | null)[];
};

const getInitialValues = (
  externalOnlineMenu: ExternalOnlineMenuInitialValues,
): EditShopExternalOnlineMenuFormValues => ({
  name: externalOnlineMenu?.name ?? "",
  url: externalOnlineMenu?.url ?? "",
  terms: (externalOnlineMenu?.externalOnlineMenuAvailableTimeTerms ?? [])
    .slice()
    .sort((a, b) => findSortKeyByDayWeek(a.dayWeek) - findSortKeyByDayWeek(b.dayWeek))
    .map((term) => ({
      dayWeek: {
        value: term.dayWeek,
        label: formatExternalOnlineMenuAvailableTimeTermDayWeek(term.dayWeek),
      },
      ...normalizeDuration(term),
      id: term.id,
    })),
});

export const EditShopExternalOnlineMenuFormItem =
  createFormItem<EditShopExternalOnlineMenuFormValues>();

export const useEditShopExternalOnlineMenuForm = (
  onSubmit: ({
    externalOnlineMenu,
    externalOnlineMenuAvailableTimeTerms,
  }: {
    externalOnlineMenu: ExternalOnlineMenuSetInput;
    externalOnlineMenuAvailableTimeTerms: Omit<
      ExternalOnlineMenuAvailableTimeTermInsertInput,
      "externalOnlineMenuId"
    >[];
  }) => void,
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void,
  externalOnlineMenu: ExternalOnlineMenuInitialValues,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(externalOnlineMenu);

  const [takenDayWeeks, setTakenDayWeeks] = useState<OrderableTimeTermDayWeekEnum[]>([]);
  const [lastStartAtAndEndAt, setLastStartAtAndEndAt] = useState<{
    startAt?: dayjs.Dayjs;
    endAt?: dayjs.Dayjs;
  }>({ startAt: undefined, endAt: undefined });

  const change = useCallback(
    (changedValues: Store, formValues: unknown) => {
      const externalOnlineMenuAvailableTimeFormValues =
        formValues as EditShopExternalOnlineMenuFormValues;
      const changedExternalOnlineMenuAvailableTimeFormValues =
        changedValues as EditShopExternalOnlineMenuFormValues;
      const changedTerms = changedExternalOnlineMenuAvailableTimeFormValues.terms ?? [];

      if (changedTerms.length === 0) return;

      const lastStartAt = changedTerms.find(Boolean)?.start;
      if (lastStartAt) setLastStartAtAndEndAt((prev) => ({ ...prev, startAt: lastStartAt }));
      const lastEndAt = changedTerms.find(Boolean)?.end;
      if (lastEndAt) setLastStartAtAndEndAt((prev) => ({ ...prev, endAt: lastEndAt }));

      form.setFields([
        {
          name: "terms",
          value: externalOnlineMenuAvailableTimeFormValues.terms
            .filter((term) => term?.dayWeek)
            .sort(
              (term1, term2) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                findSortKeyByDayWeek(term1!.dayWeek!.value) -
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                findSortKeyByDayWeek(term2!.dayWeek!.value),
            )
            .map((term) => ({
              ...term,
              start: term?.start ?? lastStartAt,
              end: term?.end ?? lastEndAt,
            })),
        },
      ]);

      setTakenDayWeeks(
        externalOnlineMenuAvailableTimeFormValues.terms
          .map((term) => term?.dayWeek?.value)
          .filter(isExternalOnlineMenuAvailableTimeTermDayWeek),
      );
    },
    [form],
  );

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as EditShopExternalOnlineMenuFormValues;

      onSubmit({
        externalOnlineMenuAvailableTimeTerms: (values.terms ?? [])
          .filter(isNotNull)
          .map((term) => ({
            // NOTE: バリデーションが効いているので実際に dayWeek が undefined になることはない
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dayWeek: term.dayWeek!.value,
            start: term.start.format("HH:mm"),
            end: term.end.format("HH:mm"),
          })),
        externalOnlineMenu: {
          name: values.name,
          url: values.url,
        },
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, change, submit, takenDayWeeks, lastStartAtAndEndAt };
};
