import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableAlertField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="ハードウェアアラート"
        help="有効にするとハードウェアに関するアラートが Slack 上で通知されます"
      />
    }
    name="enableAlert"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
