import React, { memo } from "react";
import styled from "styled-components";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

const StyledUserMenu = styled(Menu)`
  min-width: 200px;
  border: none;
`;

type Props = {
  signOut: () => void;
};

export const UserMenu = memo<Props>(({ signOut }) => (
  <StyledUserMenu>
    <Menu.Item key="0" onClick={signOut}>
      <LogoutOutlined /> ログアウト
    </Menu.Item>
  </StyledUserMenu>
));
