import React, { memo } from "react";
import { Select } from "antd";
import { onSitePaymentDetailTypeCategory } from "models/onSitePaymentDetailTypeCategory";

import { FormItemProps } from "components/antd/Form";
import { OnSitePaymentDetailTypeCategory } from "pages/EditOnSitePaymentDetailType/types";

import { EditOnSitePaymentDetailTypeFormItem } from "../useEditOnSitePaymentDetailTypeForm";

type Props = {
  onSitePaymentDetailTypeCategories: OnSitePaymentDetailTypeCategory[];
} & Omit<FormItemProps, "children" | "name">;

export const CategoryField = memo<Props>(({ onSitePaymentDetailTypeCategories, ...props }) => (
  <EditOnSitePaymentDetailTypeFormItem
    label="支払種別名"
    name="onSitePaymentDetailTypeCategory"
    rules={[{ required: true, message: "支払種別を選択してください" }]}
    {...props}
  >
    <Select<number>
      placeholder="支払種別を選択してください"
      options={onSitePaymentDetailTypeCategories.map(({ value }) => ({
        value,
        label: onSitePaymentDetailTypeCategory[value],
      }))}
    />
  </EditOnSitePaymentDetailTypeFormItem>
));
