import { memo } from "react";
import React, { Button, Checkbox, Col, Row } from "antd";
import { sum } from "util/array";

import { withFormDependencies } from "components/antd/Form";

import { EditSalesBudgetFormItem, EditSalesBudgetFormValues } from "../../useEditSalesBudgetForm";

type Props = {
  onOk: () => void;
  onCancel: () => void;
};

export const AllocationSettingModalFooter = memo<Props>(({ onOk, onCancel }) => (
  <Row align="middle" justify="space-between" gutter={16}>
    <Col>
      <Button onClick={onCancel}>キャンセル</Button>
    </Col>
    <Col flex={1} />
    <Col>
      <EditSalesBudgetFormItem name="shouldSaveAllocationSetting" valuePropName="checked" noStyle>
        <Checkbox>この設定を保存する</Checkbox>
      </EditSalesBudgetFormItem>
    </Col>
    <Col>
      <EditSalesBudgetFormItem.NonProperty
        noStyle
        shouldUpdate={withFormDependencies(({ allocationSetting }) => [
          allocationSetting["monRate"],
          allocationSetting["tueRate"],
          allocationSetting["wedRate"],
          allocationSetting["thuRate"],
          allocationSetting["friRate"],
          allocationSetting["satRate"],
          allocationSetting["sunRate"],
        ])}
      >
        {({ getFieldValue }) => {
          const allocationSetting = getFieldValue(
            "allocationSetting",
          ) as EditSalesBudgetFormValues["allocationSetting"];
          const total = sum(Object.values(allocationSetting));
          const disabled = total !== 100;
          return (
            <Button type="primary" onClick={onOk} disabled={disabled}>
              この条件で日別に割り振る
            </Button>
          );
        }}
      </EditSalesBudgetFormItem.NonProperty>
    </Col>
  </Row>
));
