import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Col, Divider, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { grey } from "constants/colors";
import { EditPlanFormValues, useEditPlanForm } from "pages/EditPlan/EditPlanForm/useEditPlanForm";
import { Category, OrderableTime, Plan, PlanGroup } from "pages/EditPlan/types";
import { PlanSetInput } from "types/graphql";

import { CategoryField } from "./CategoryField";
import { DescriptionField } from "./DescriptionField";
import { DisplayTypeField } from "./DisplayTypeField";
import { FeaturedLabelTextField } from "./FeaturedLabelTextField";
import { NameField } from "./NameField";
import { OrderableTimeField } from "./OrderableTimeField";
import { PlanEnableEndNotificationField } from "./PlanEnableEndNotificationField";
import { PlanEndNotificationThresholdField } from "./PlanEndNotificationThresholdField";
import { PlanGroupField } from "./PlanGroupField";
import { PlanTimeField } from "./PlanTimeField";
import { PlanTimeLimitField } from "./PlanTimeLimitField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { ShopSideNameField } from "./ShopSideNameField";
import { TaxMethodField } from "./TaxMethodField";
import { TaxRateField } from "./TaxRateField";

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

const StyledForm = styled(Form)`
  width: 640px;
  margin: 0 auto;
`;

const StyledFormSection = styled(FormSection)`
  padding: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 32px 0;
`;

type Props = {
  plan: Plan;
  categories: Category[];
  orderableTimes: OrderableTime[];
  planGroups: PlanGroup[];
  onSubmit: (plan: PlanSetInput) => void;
  onClose: () => void;
  loading: boolean;
  disabled: boolean;
};

export const EditPlanForm = memo<Props>(
  ({
    plan,
    categories,
    orderableTimes,
    planGroups,
    onSubmit,
    onClose: handleClose,
    loading,
    disabled,
  }) => {
    const { form, initialValues, submit } = useEditPlanForm(plan, onSubmit);
    const [error, setError] = useState("");

    const [hasNonZeroPlanTimeLimit, setHasNonZeroPlanTimeLimit] = useState(
      typeof plan.planTime === "number",
    );
    const [hasPlanTimeLimit, setHasPlanTimeLimit] = useState(typeof plan.planTime === "number");
    const [hasEndNotificationTime, setHasEndNotificationTime] = useState(
      typeof plan.endNotificationThresholdMinutes === "number",
    );
    const [isDisabledTaxRateField, setIsDisabledTaxRateField] = useState(
      plan.taxMethod === "nonTaxable",
    );
    const [uploadImage, setUploadImage] = useState(initialValues.imageUrl);

    const handleChange = useCallback(
      (changedValues: Store) => {
        setError("");
        const { taxMethod, planTime } = changedValues as Partial<EditPlanFormValues>;

        if (taxMethod) {
          setIsDisabledTaxRateField(taxMethod === "nonTaxable");
        }
        if (planTime) {
          setHasNonZeroPlanTimeLimit(true);
        }
        if (planTime === null) {
          setHasNonZeroPlanTimeLimit(false);
          setHasEndNotificationTime(false);
        }
      },
      [setError],
    );

    const showErrorMessage = useCallback(() => message.error("入力内容に誤りがあります"), []);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();

        submit({ imageUrl: uploadImage, hasPlanTimeLimit, hasEndNotificationTime });
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          showErrorMessage();
        }
      }
    }, [form, showErrorMessage, submit, uploadImage, hasPlanTimeLimit, hasEndNotificationTime]);

    const handleSetHasPlanTimeLimitChange = useCallback(
      (newHasPlanTimeLimit: boolean) => {
        if (!newHasPlanTimeLimit) {
          // NOTE: there can't be an end notification without a plan end time
          setHasEndNotificationTime(false);
        }

        setHasPlanTimeLimit(newHasPlanTimeLimit);
      },
      [setHasPlanTimeLimit, setHasEndNotificationTime],
    );

    return (
      <>
        <FormWrapper>
          <StyledForm
            name="plan"
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={handleChange}
          >
            <StyledFormSection title="商品画像">
              <ImageField
                image={uploadImage}
                setUploadImage={setUploadImage}
                disabled={disabled}
                uploadImageApiKey="plan"
                formName="EditPlanForm"
                label="プラン画像"
                isMovieAccepted
              />
            </StyledFormSection>

            <StyledDivider />

            <StyledFormSection title="プラン情報">
              <Row gutter={24}>
                <Col span={12}>
                  <NameField disabled={disabled} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <ReceiptDisplayNameField disabled={disabled} />
                </Col>
                <Col span={12}>
                  <ShopSideNameField disabled={disabled} />
                </Col>
              </Row>

              <CategoryField categories={categories} disabled={disabled} />

              <OrderableTimeField orderableTimes={orderableTimes} disabled={disabled} />

              <PlanGroupField planGroups={planGroups} disabled={disabled} />

              <DescriptionField disabled={disabled} />

              <Row>
                <Col span={12}>
                  <PlanTimeLimitField
                    hasPlanTimeLimit={hasPlanTimeLimit}
                    setHasPlanTimeLimit={handleSetHasPlanTimeLimitChange}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12}>
                  <PlanTimeField
                    hasPlanTimeLimit={hasPlanTimeLimit}
                    disabled={disabled}
                    wrapperCol={{ span: 8 }}
                  />
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <PlanEnableEndNotificationField
                    hasNonZeroPlanTimeLimit={hasNonZeroPlanTimeLimit}
                    hasPlanTimeLimit={hasPlanTimeLimit}
                    hasEndNotificationTime={hasEndNotificationTime}
                    setHasEndNotificationTime={setHasEndNotificationTime}
                    disabled={disabled}
                  />
                </Col>
                <Col span={12}>
                  <PlanEndNotificationThresholdField
                    form={form}
                    hasEndNotificationTime={hasEndNotificationTime}
                    disabled={disabled}
                    wrapperCol={{ span: 8 }}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <FeaturedLabelTextField disabled={disabled} />
                </Col>
              </Row>

              <DisplayTypeField disabled={disabled} />

              <Row>
                <Col span={12}>
                  <TaxMethodField wrapperCol={{ span: 8 }} disabled={disabled} />
                </Col>
                <Col span={12}>
                  <TaxRateField
                    wrapperCol={{ span: 8 }}
                    disabled={isDisabledTaxRateField || disabled}
                  />
                </Col>
              </Row>
            </StyledFormSection>
            {error && <StyledAlert message={error} type="error" />}
          </StyledForm>
        </FormWrapper>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading} disabled={disabled}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
