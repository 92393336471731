import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { AddMenuForm } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/AddMenuForm";
import { AddMenuFormValues } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/AddMenuForm/useAddMenuForm";
import {
  useAddPlanFirstOrderCategoryMenuModalGetMenusQuery,
  useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation,
} from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/queries";
import { Plan } from "pages/PlanFirstOrderCategoryMenus/types";

type Props = {
  plan: Plan;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddPlanFirstOrderCategoryMenuModal = memo<Props>(({ plan, goBack, onUpdated }) => {
  const planId = plan.planId;

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getMenusData, error } = useAddPlanFirstOrderCategoryMenuModalGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = useMemo(() => getMenusData?.category ?? [], [getMenusData?.category]);

  const [
    addPlanFirstOrderCategoryMenusMenu,
    { loading: loadingInsertPlanFirstOrderCategoryMenusMenu },
  ] = useAddPlanFirstOrderCategoryMenuModalInsertPlanFirstOrderCategoryMenusMutation();

  const [formValues, setFormValues] = useState<AddMenuFormValues | null>(null);
  const onOk = useCallback(async () => {
    if (formValues) {
      try {
        const { menus: menuIds } = formValues;

        const planFirstOrderCategoryMenus = menuIds.map((_categoryMenuId, index) => {
          const categoryMenu = categories
            .flatMap(({ categoryMenus }) => categoryMenus)
            .find((categoryMenu) => categoryMenu.categoryMenuId === _categoryMenuId);

          if (!categoryMenu) {
            throw new Error("categoryMenu is not found");
          }

          return {
            categoryMenuId: categoryMenu.id,
            _categoryMenuId: categoryMenu.categoryMenuId,
            planId: plan.id,
            _planId: plan.planId,
            priority:
              Math.max(0, ...plan.planFirstOrderCategoryMenus.map(({ priority }) => priority)) +
              index +
              1,
          };
        });
        const { data: addPlanMenuMenusFirstOrderMenuData } =
          await addPlanFirstOrderCategoryMenusMenu({
            variables: { planFirstOrderCategoryMenus },
          });
        const returning =
          addPlanMenuMenusFirstOrderMenuData?.insert_planFirstOrderCategoryMenu?.returning;

        if (returning?.length === 0) {
          throw new Error("");
        }

        onUpdated();

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
    }

    goBack();
  }, [
    formValues,
    goBack,
    addPlanFirstOrderCategoryMenusMenu,
    onUpdated,
    categories,
    plan.id,
    plan.planId,
    plan.planFirstOrderCategoryMenus,
  ]);

  return (
    <Modal
      title="自動注文設定"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button
          key="add"
          type="primary"
          onClick={onOk}
          loading={loadingInsertPlanFirstOrderCategoryMenusMenu}
        >
          追加
        </Button>,
        <Button key="cancel" onClick={goBack}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddMenuForm plan={plan} categories={categories} onChange={setFormValues} />
    </Modal>
  );
});
