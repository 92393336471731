import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";

import { EditCompanyForm } from "./EditCompanyForm";
import { useEditCompanyGetCompanyQuery } from "./queries";

export const EditCompany = () => {
  const { id } = useParams<{ id: string }>();
  const companySerialId = Number(id);

  const {
    data,
    loading: loadingCompany,
    error,
  } = useEditCompanyGetCompanyQuery(
    companySerialId !== undefined ? { variables: { serial: companySerialId } } : { skip: true },
  );
  const company = data?.company?.[0];

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={company?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "業態一覧" }],
      }}
    >
      <PageHeader title={company?.name} onBack={goBack} />
      {loadingCompany && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {company && <EditCompanyForm company={company} />}
    </DashboardLayout>
  );
};
