import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { AllTenantAverageScore, getRankViewPropertiesByScore } from "models/questionnaireAnalytics";

import { Loading } from "components/Loading";
import { ScoreMetricsPanel } from "components/QuestionnaireAnalytics/ScoreMetricsPanel/ScoreMetricsPanel";
import { viewport } from "constants/viewport";
import { CompanyRankingPanel } from "pages/QuestionnaireAnalyticsShopMetrics/ScoreMetrics/CompanyRankingPanel";
import { PrimaryScoreMetricsPanel } from "pages/QuestionnaireAnalyticsShopMetrics/ScoreMetrics/PrimaryScoreMetricsPanel";
import { ScoreGauge } from "pages/QuestionnaireAnalyticsShopMetrics/ScoreMetrics/ScoreGauge";
import { QuestionnaireScoreMetrics } from "pages/QuestionnaireAnalyticsShopMetrics/types";

type ScoreReportType = {
  scores: {
    repeatability: {
      shopAverageScore: { score: number; fluctuation: number };
      corporationAverageScore: number;
      allTenantAverageScore: number;
    };
    answerCount: number;
    customerService: {
      score: number;
      fluctuation: number;
    };
    deliciousness: {
      score: number;
      fluctuation: number;
    };
    speed: {
      score: number;
      fluctuation: number;
    };
    cleanliness: {
      score: number;
      fluctuation: number;
    };
  };
  ranking: {
    ranking: number;
    prevRanking: number;
  };
  shopCount: number;
};

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, 172px);
  grid-template-rows: 240px 118px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    gap: 8px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 240px 350px repeat(2, 118px);
  }
`;

const StyledCompanyRankingPanel = styled(CompanyRankingPanel)`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    order: 1;
  }
`;

const StyledPrimaryScoreMetricsPanel = styled(PrimaryScoreMetricsPanel)`
  grid-column-start: 2;
  grid-column-end: 6;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    grid-column-start: 1;
    grid-column-end: 3;
    order: 3;
  }
`;

const StyledScoreMetricsPanel = styled(ScoreMetricsPanel)<{ order: number }>`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    order: ${({ order }) => order};
  }
`;

type Props = {
  shopId: string;
  questionnaireScoreMetrics: QuestionnaireScoreMetrics | undefined;
  isLoading: boolean;
  isEmpty: boolean;
  lastMonthAllTenantAverageScore: AllTenantAverageScore;
};

export const ScoreMetrics = memo<Props>(
  ({ shopId, questionnaireScoreMetrics, isLoading, isEmpty, lastMonthAllTenantAverageScore }) => {
    const metricsSource = useMemo<ScoreReportType | null>(() => {
      if (!questionnaireScoreMetrics) return null;

      const { shopAverageScores, corporationAverageScore } = questionnaireScoreMetrics;

      const sortedShopScores = [...shopAverageScores]
        .sort((a, b) => b.repeatability.score - a.repeatability.score)
        .map((shopScore, index) => ({ ...shopScore, ranking: index + 1 }));

      const shopScore = sortedShopScores.find((shopScore) => shopScore.shopId === shopId);
      if (!shopScore) return null;

      const sortedPrevShopScores = [...shopAverageScores]
        .sort(
          (a, b) =>
            b.repeatability.score +
            b.repeatability.fluctuation -
            (a.repeatability.score + a.repeatability.fluctuation),
        )
        .map((shopScore, index) => ({ ...shopScore, ranking: index + 1 }));

      const prevShopScore = sortedPrevShopScores.find((shopScore) => shopScore.shopId === shopId);
      if (!prevShopScore) return null;

      return {
        scores: {
          repeatability: {
            shopAverageScore: shopScore.repeatability,
            corporationAverageScore: corporationAverageScore.repeatability,
            allTenantAverageScore: lastMonthAllTenantAverageScore.repeatability,
          },
          answerCount: shopScore.answerCount,
          customerService: shopScore.customerService,
          deliciousness: shopScore.deliciousness,
          speed: shopScore.speed,
          cleanliness: shopScore.cleanliness,
        },
        ranking: {
          ranking: shopScore.ranking,
          prevRanking: prevShopScore.ranking,
        },
        shopCount: shopAverageScores.length,
      };
    }, [questionnaireScoreMetrics, shopId, lastMonthAllTenantAverageScore]);

    if (!metricsSource || isLoading) {
      return <Loading height={300} />;
    }

    const {
      scores: { repeatability, answerCount, customerService, deliciousness, speed, cleanliness },
      ranking,
      shopCount,
    } = metricsSource;

    const { rank } = getRankViewPropertiesByScore(repeatability.shopAverageScore.score);

    return (
      <Wrapper>
        <StyledCompanyRankingPanel ranking={ranking} shopCount={shopCount} isEmpty={isEmpty} />

        <StyledPrimaryScoreMetricsPanel
          title="再来店意欲"
          shopAverageScore={repeatability.shopAverageScore}
          corporationAverageScore={repeatability.corporationAverageScore}
          allTenantAverageScore={repeatability.allTenantAverageScore}
          shouldShowStarIcon={false}
          isEmpty={isEmpty}
        >
          <ScoreGauge rank={rank} />
        </StyledPrimaryScoreMetricsPanel>

        <StyledScoreMetricsPanel
          title="回答数"
          score={answerCount}
          shouldShowRank={false}
          order={2}
        />

        <StyledScoreMetricsPanel
          title="接客"
          score={customerService.score}
          fluctuation={customerService.fluctuation}
          isEmpty={isEmpty}
          order={4}
        />

        <StyledScoreMetricsPanel
          title="料理"
          score={deliciousness.score}
          fluctuation={deliciousness.fluctuation}
          isEmpty={isEmpty}
          order={5}
        />

        <StyledScoreMetricsPanel
          title="提供速度"
          score={speed.score}
          fluctuation={speed.fluctuation}
          isEmpty={isEmpty}
          order={6}
        />

        <StyledScoreMetricsPanel
          title="清潔感"
          score={cleanliness.score}
          fluctuation={cleanliness.fluctuation}
          isEmpty={isEmpty}
          order={7}
        />
      </Wrapper>
    );
  },
);
