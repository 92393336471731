import React, { memo } from "react";

import { withFormDependencies } from "components/antd/Form";

import { EditPlanOptionFormItem } from "../useEditPlanOptionForm";

import { NameField } from "./NameField";

type Props = {
  disabled?: boolean | undefined;
};

export const TranslationField = memo<Props>(({ disabled }) => (
  <EditPlanOptionFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ name }) => [name])}
    noStyle
  >
    <NameField name="name" label="オプション名：原文" disabled />
    <NameField name="nameEn" label="オプション名：英語" disabled={disabled} />
    <NameField name="nameCn" label="オプション名：中国語 (簡体字)" disabled={disabled} />
    <NameField name="nameKr" label="オプション名：韓国語" disabled={disabled} />
  </EditPlanOptionFormItem.NonProperty>
));
