import React, { memo } from "react";
import { BarChartOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { DashboardFeatureEnum } from "types/graphql";

export const CoineyMenu = memo<SubMenuPropsWithoutFeatures>(({ ...props }) => (
  <SubMenu key="coiney" title="コイニー" features={[DashboardFeatureEnum.Aggregation]} {...props}>
    <MenuItem route="coineySales" to="/coiney/sale" icon={<BarChartOutlined />} text="売上" />
  </SubMenu>
));
