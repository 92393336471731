import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddLineOfficialAccountInsertLineOfficialAccount = gql`
    mutation AddLineOfficialAccountInsertLineOfficialAccount($input: CreateLineOfficialAccountInput!) {
  createLineOfficialAccount(input: $input)
}
    `;
export type AddLineOfficialAccountInsertLineOfficialAccountMutationVariables = Types.Exact<{
  input: Types.CreateLineOfficialAccountInput;
}>;


export type AddLineOfficialAccountInsertLineOfficialAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'createLineOfficialAccount'>
);


export const AddLineOfficialAccountInsertLineOfficialAccountDocument = gql`
    mutation AddLineOfficialAccountInsertLineOfficialAccount($input: CreateLineOfficialAccountInput!) {
  createLineOfficialAccount(input: $input)
}
    `;
export type AddLineOfficialAccountInsertLineOfficialAccountMutationFn = Apollo.MutationFunction<AddLineOfficialAccountInsertLineOfficialAccountMutation, AddLineOfficialAccountInsertLineOfficialAccountMutationVariables>;

/**
 * __useAddLineOfficialAccountInsertLineOfficialAccountMutation__
 *
 * To run a mutation, you first call `useAddLineOfficialAccountInsertLineOfficialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLineOfficialAccountInsertLineOfficialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLineOfficialAccountInsertLineOfficialAccountMutation, { data, loading, error }] = useAddLineOfficialAccountInsertLineOfficialAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLineOfficialAccountInsertLineOfficialAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddLineOfficialAccountInsertLineOfficialAccountMutation, AddLineOfficialAccountInsertLineOfficialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLineOfficialAccountInsertLineOfficialAccountMutation, AddLineOfficialAccountInsertLineOfficialAccountMutationVariables>(AddLineOfficialAccountInsertLineOfficialAccountDocument, options);
      }
export type AddLineOfficialAccountInsertLineOfficialAccountMutationHookResult = ReturnType<typeof useAddLineOfficialAccountInsertLineOfficialAccountMutation>;
export type AddLineOfficialAccountInsertLineOfficialAccountMutationResult = Apollo.MutationResult<AddLineOfficialAccountInsertLineOfficialAccountMutation>;
export type AddLineOfficialAccountInsertLineOfficialAccountMutationOptions = Apollo.BaseMutationOptions<AddLineOfficialAccountInsertLineOfficialAccountMutation, AddLineOfficialAccountInsertLineOfficialAccountMutationVariables>;