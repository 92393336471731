import React, { memo, useMemo } from "react";
import { Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { EbicaApiEbicaTable, EbicaTable, Table as TableType } from "../types";

import { TableField } from "./TableField";

type Props = {
  ebicaTables: EbicaTable[];
  ebicaApiEbicaTables: EbicaApiEbicaTable[];
  tables: TableType[];
  loading: boolean;
  onUpdate: (input: { ebicaTableId: number; tableId: string }) => void;
  onDelete: (input: { ebicaTableId: number }) => void;
};

type DataSource = {
  ebicaApiEbicaTable: EbicaApiEbicaTable;
  table: TableType | undefined;
};

export const EbicaTableTable = memo<Props>(
  ({ ebicaTables, ebicaApiEbicaTables, tables, loading, onDelete, onUpdate }) => {
    const [pagination, setPagination] = usePagination();

    const selectableTables = useMemo(
      () => tables.filter(({ id }) => ebicaTables.every((ebicaTable) => ebicaTable.tableId !== id)),
      [ebicaTables, tables],
    );

    const columns: ColumnsType<DataSource> = useMemo(
      () => [
        {
          title: "ebica / resty テーブル",
          width: 300,
          fixed: "left",
          render(_: string, { ebicaApiEbicaTable }: DataSource) {
            return ebicaApiEbicaTable.name;
          },
        },
        {
          title: "dinii テーブル",
          fixed: "left",
          render(_: string, { ebicaApiEbicaTable, table }: DataSource) {
            return (
              <TableField
                value={table?.id}
                tables={[...(table ? [table] : []), ...selectableTables]}
                onChange={(value: string) => {
                  onUpdate({
                    ebicaTableId: ebicaApiEbicaTable.id,
                    tableId: value,
                  });
                }}
              />
            );
          },
        },
        {
          title: "",
          align: "center",
          width: 60,
          render(_: string, { ebicaApiEbicaTable, table }: DataSource) {
            const disabled = !table;

            return (
              <Popconfirm
                title={<Paragraph>テーブル連携を解除しますか？</Paragraph>}
                okText="はい"
                cancelText="キャンセル"
                onConfirm={() => onDelete({ ebicaTableId: ebicaApiEbicaTable.id })}
                disabled={disabled}
              >
                <DeleteIcon disabled={disabled} />
              </Popconfirm>
            );
          },
        } as const,
      ],
      [selectableTables, onUpdate, onDelete],
    );

    const ebicaTableIdToEbicaTableMap = useMemo(
      () => new Map(ebicaTables.map((ebicaTable) => [ebicaTable.ebicaTableId, ebicaTable])),
      [ebicaTables],
    );
    const tableIdToTableMap = useMemo(
      () => new Map(tables.map((table) => [table.id, table])),
      [tables],
    );

    const dataSource = useMemo<DataSource[]>(
      () =>
        ebicaApiEbicaTables.map((ebicaApiEbicaTable) => {
          const tableId = ebicaTableIdToEbicaTableMap.get(ebicaApiEbicaTable.id)?.tableId;
          const table = tableId ? tableIdToTableMap.get(tableId) : undefined;
          return { ebicaApiEbicaTable, table };
        }),
      [ebicaApiEbicaTables, ebicaTableIdToEbicaTableMap, tableIdToTableMap],
    );

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        loading={loading}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
