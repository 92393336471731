import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useShop } from "hooks/useShop";
import { AddRoleForm } from "pages/AddRole/AddRoleForm";
import { AddRoleFormValues } from "pages/AddRole/AddRoleForm/useAddRoleForm";
import {
  useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation,
  useAddRoleCreateRoleMutation,
  useAddRoleGetRolesQuery,
} from "pages/AddRole/queries";
import { RoleInsertInput } from "types/graphql";

export const AddRole = () => {
  const navigate = useNavigate();
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [insertRoleMutation, { loading: loadingUpdateRole }] = useAddRoleCreateRoleMutation();

  const [insertOnlinePaymentPrinterRoleMeta, { loading: loadingOnlinePaymentPrinterRoleMeta }] =
    useAddRoleCreateOnlinePaymentPrinterRoleMetaMutation();

  const {
    data: getRolesData,
    loading,
    error,
  } = useAddRoleGetRolesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const onSubmit = useCallback(
    async (role: AddRoleFormValues) => {
      if (!shopId) return;

      try {
        const {
          shouldPrintFreeOrderOnDishUpSlip,
          companyId,
          shouldPrintOnlinePaymentSlip,
          ...rest
        } = role;

        const roles: RoleInsertInput[] = [
          {
            ...rest,
            companyId,
            removeFreeOrderFromDishUpSlip: !shouldPrintFreeOrderOnDishUpSlip,
          },
        ];

        const { data: addRoleData } = await insertRoleMutation({
          variables: { roles },
        });
        const roleId = addRoleData?.insert_role?.returning?.[0]?.id;
        if (roleId === undefined) {
          throw new Error("");
        }

        if (shouldPrintOnlinePaymentSlip) {
          await insertOnlinePaymentPrinterRoleMeta({
            variables: {
              onlinePaymentPrinterRoleMeta: {
                shopId,
                printerRoleId: roleId,
              },
            },
          });
        }

        message.success("作成しました");

        navigate(`/role/${roleId}/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [insertOnlinePaymentPrinterRoleMeta, insertRoleMutation, navigate, shopId],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="キッチンプリンターを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "キッチンプリンター" }],
      }}
    >
      <PageHeader title="キッチンプリンターを新規作成" onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddRoleForm
        roles={roles}
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingUpdateRole || loadingOnlinePaymentPrinterRoleMeta}
      />
    </DashboardLayout>
  );
};
