import React, { memo, useMemo } from "react";
import * as uuid from "uuid";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { TecAggregationMediaMap } from "pages/TecAggregationMediaMaps/types";

import { defaultTecAggregationMediaMaps } from "../const";

type Props = {
  loading?: boolean;
  tecAggregationMediaMaps: TecAggregationMediaMap[];
};

export const TecAggregationMediaMapTable = memo<Props>(({ loading, tecAggregationMediaMaps }) => {
  const [pagination, setPagination] = usePagination();

  const mergedTecAggregationMediaMaps = useMemo(() => {
    const aggregationNumberToMediaNameMap = new Map(
      tecAggregationMediaMaps.map((tecAggregationMediaMap) => [
        tecAggregationMediaMap.aggregationNumber,
        tecAggregationMediaMap,
      ]),
    );

    return defaultTecAggregationMediaMaps.map(({ aggregationNumber, mediaName, itemNumber }) => {
      const mediaMap = aggregationNumberToMediaNameMap.get(aggregationNumber);

      return {
        id: mediaMap?.id ?? uuid.v4(),
        aggregationNumber,
        mediaName: mediaMap?.mediaName ?? mediaName,
        itemNumber,
      };
    });
  }, [tecAggregationMediaMaps]);

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns = useMemo(
    () => [
      {
        title: "項番",
        dataIndex: "itemNumber",
      } as const,
      {
        title: "項目名",
        dataIndex: "mediaName",
      },
      ...(isFeatureEnabled("editTecMediaMap")
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(
                _: string,
                {
                  id,
                  aggregationNumber,
                }: { id: string | undefined; aggregationNumber: number; mediaName: string },
              ) {
                return (
                  <IconLink to={`/tec/mediaMap/${id}/edit?aggregationNumber=${aggregationNumber}`}>
                    <EditIcon />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
    ],
    [isFeatureEnabled],
  );

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={mergedTecAggregationMediaMaps}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
