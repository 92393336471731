import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { CompaniesField } from "pages/AddDashboardAccount/AddDashboardAccountForm/CompaniesField";
import { DashboardAccountRoleField } from "pages/AddDashboardAccount/AddDashboardAccountForm/DashboardAccountRoleField";
import { EmailField } from "pages/AddDashboardAccount/AddDashboardAccountForm/EmailField";
import { NameField } from "pages/AddDashboardAccount/AddDashboardAccountForm/NameField";
import { PasswordField } from "pages/AddDashboardAccount/AddDashboardAccountForm/PasswordField";
import { ScopeField } from "pages/AddDashboardAccount/AddDashboardAccountForm/ScopeField";
import { ShopsField } from "pages/AddDashboardAccount/AddDashboardAccountForm/ShopsField";
import {
  AddDashboardAccountFormValues,
  useAddDashboardAccountForm,
} from "pages/AddDashboardAccount/AddDashboardAccountForm/useAddDashboardAccountForm";
import { Company, DashboardAccountRole, Shop } from "pages/AddDashboardAccount/types";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  onSubmit: (formValues: AddDashboardAccountFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
  companies: Company[];
  shops: Shop[];
  dashboardAccountRoles: DashboardAccountRole[];
};

export const AddDashboardAccountForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, companies, shops, dashboardAccountRoles }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const { form, initialValues, submit } = useAddDashboardAccountForm(onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <Wrapper>
        <PageHeader title="アカウントを新規作成" onBack={goBack} />
        <StyledForm name="addAccount" form={form} layout="vertical" initialValues={initialValues}>
          <FormSection title="アカウントの基本情報">
            <NameField />
            <EmailField />
            <PasswordField />
          </FormSection>

          <FormSection title="アカウント詳細">
            <DashboardAccountRoleField dashboardAccountRoles={dashboardAccountRoles} />
            <ScopeField />
            <CompaniesField companies={companies} />
            <ShopsField shops={shops} />
          </FormSection>
        </StyledForm>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={goBack}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </Wrapper>
    );
  },
);
