import React, { memo, useCallback, useState } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { MenuSetInput } from "types/graphql";

import { Menu } from "../types";

import { DescriptionField } from "./DescriptionField";
import { NameField } from "./NameField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { useEditGiftForm } from "./useEditGiftForm";

type Props = {
  menu: Menu;
  onSubmit: ({ menu }: { menu: MenuSetInput }) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditGiftForm = memo<Props>(({ menu, onClose, onSubmit, loading }) => {
  const { form, initialValues, submit } = useEditGiftForm(menu, onSubmit);

  const [uploadImage, setUploadImage] = useState(initialValues.imageUrl);

  const handleSubmit = useCallback(() => {
    submit(uploadImage);
  }, [submit, uploadImage]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="gift" form={form} layout="vertical" initialValues={initialValues}>
          <ImageField
            image={uploadImage}
            setUploadImage={setUploadImage}
            uploadImageApiKey="menu"
            formName="EditGiftForm"
            label="メニュー画像"
            isMovieAccepted
          />
          <NameField wrapperCol={{ span: 16 }} />
          <ReceiptDisplayNameField wrapperCol={{ span: 16 }} />
          <DescriptionField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
