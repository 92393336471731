import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const LineOfficialAccounts = gql`
    query LineOfficialAccounts($companyId: String!) {
  lineOfficialAccounts(input: {companyId: $companyId}) {
    lineOfficialAccountId
    channelName
  }
}
    `;
export type LineOfficialAccountsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type LineOfficialAccountsQuery = (
  { __typename?: 'query_root' }
  & { lineOfficialAccounts: Array<(
    { __typename?: 'LineOfficialAccountProfile' }
    & Pick<Types.LineOfficialAccountProfile, 'lineOfficialAccountId' | 'channelName'>
  )> }
);


export const LineOfficialAccountsDocument = gql`
    query LineOfficialAccounts($companyId: String!) {
  lineOfficialAccounts(input: {companyId: $companyId}) {
    lineOfficialAccountId
    channelName
  }
}
    `;

/**
 * __useLineOfficialAccountsQuery__
 *
 * To run a query within a React component, call `useLineOfficialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineOfficialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineOfficialAccountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useLineOfficialAccountsQuery(baseOptions: Apollo.QueryHookOptions<LineOfficialAccountsQuery, LineOfficialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LineOfficialAccountsQuery, LineOfficialAccountsQueryVariables>(LineOfficialAccountsDocument, options);
      }
export function useLineOfficialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LineOfficialAccountsQuery, LineOfficialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LineOfficialAccountsQuery, LineOfficialAccountsQueryVariables>(LineOfficialAccountsDocument, options);
        }
export type LineOfficialAccountsQueryHookResult = ReturnType<typeof useLineOfficialAccountsQuery>;
export type LineOfficialAccountsLazyQueryHookResult = ReturnType<typeof useLineOfficialAccountsLazyQuery>;
export type LineOfficialAccountsQueryResult = Apollo.QueryResult<LineOfficialAccountsQuery, LineOfficialAccountsQueryVariables>;