import { useLiffIdGetShopQuery } from "hooks/useLiffId/queries";

export const useLiffId = (shopId?: string) => {
  const {
    data: getShopData,
    loading,
    error,
  } = useLiffIdGetShopQuery(
    shopId
      ? {
          variables: { shopId },
          fetchPolicy: "cache-first",
        }
      : { skip: true },
  );
  const shop = getShopData?.shop_by_pk;
  const liffId = shop?.lineChannelConfig?.liffId;

  return { liffId, loading, error };
};
