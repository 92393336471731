import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditDefaultTaxMethodsGetCompany = gql`
    query EditDefaultTaxMethodsGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultCostTaxMethod
    defaultMenuTaxMethod
  }
}
    `;
export const EditDefaultTaxMethodsUpdateDefaultTaxMethod = gql`
    mutation EditDefaultTaxMethodsUpdateDefaultTaxMethod($companyId: uuid!, $defaultCostTaxMethod: taxMethod_enum!, $defaultMenuTaxMethod: taxMethod_enum!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {defaultCostTaxMethod: $defaultCostTaxMethod, defaultMenuTaxMethod: $defaultMenuTaxMethod}
  ) {
    affected_rows
  }
}
    `;
export type EditDefaultTaxMethodsGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditDefaultTaxMethodsGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'companyId' | 'defaultCostTaxMethod' | 'defaultMenuTaxMethod'>
  )> }
);

export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  defaultCostTaxMethod: Types.TaxMethodEnum;
  defaultMenuTaxMethod: Types.TaxMethodEnum;
}>;


export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation = (
  { __typename?: 'mutation_root' }
  & { update_company?: Types.Maybe<(
    { __typename?: 'company_mutation_response' }
    & Pick<Types.CompanyMutationResponse, 'affected_rows'>
  )> }
);


export const EditDefaultTaxMethodsGetCompanyDocument = gql`
    query EditDefaultTaxMethodsGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    companyId
    defaultCostTaxMethod
    defaultMenuTaxMethod
  }
}
    `;

/**
 * __useEditDefaultTaxMethodsGetCompanyQuery__
 *
 * To run a query within a React component, call `useEditDefaultTaxMethodsGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultTaxMethodsGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDefaultTaxMethodsGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditDefaultTaxMethodsGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<EditDefaultTaxMethodsGetCompanyQuery, EditDefaultTaxMethodsGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDefaultTaxMethodsGetCompanyQuery, EditDefaultTaxMethodsGetCompanyQueryVariables>(EditDefaultTaxMethodsGetCompanyDocument, options);
      }
export function useEditDefaultTaxMethodsGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDefaultTaxMethodsGetCompanyQuery, EditDefaultTaxMethodsGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDefaultTaxMethodsGetCompanyQuery, EditDefaultTaxMethodsGetCompanyQueryVariables>(EditDefaultTaxMethodsGetCompanyDocument, options);
        }
export type EditDefaultTaxMethodsGetCompanyQueryHookResult = ReturnType<typeof useEditDefaultTaxMethodsGetCompanyQuery>;
export type EditDefaultTaxMethodsGetCompanyLazyQueryHookResult = ReturnType<typeof useEditDefaultTaxMethodsGetCompanyLazyQuery>;
export type EditDefaultTaxMethodsGetCompanyQueryResult = Apollo.QueryResult<EditDefaultTaxMethodsGetCompanyQuery, EditDefaultTaxMethodsGetCompanyQueryVariables>;
export const EditDefaultTaxMethodsUpdateDefaultTaxMethodDocument = gql`
    mutation EditDefaultTaxMethodsUpdateDefaultTaxMethod($companyId: uuid!, $defaultCostTaxMethod: taxMethod_enum!, $defaultMenuTaxMethod: taxMethod_enum!) {
  update_company(
    where: {id: {_eq: $companyId}}
    _set: {defaultCostTaxMethod: $defaultCostTaxMethod, defaultMenuTaxMethod: $defaultMenuTaxMethod}
  ) {
    affected_rows
  }
}
    `;
export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationFn = Apollo.MutationFunction<EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation, EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationVariables>;

/**
 * __useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation__
 *
 * To run a mutation, you first call `useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDefaultTaxMethodsUpdateDefaultTaxMethodMutation, { data, loading, error }] = useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      defaultCostTaxMethod: // value for 'defaultCostTaxMethod'
 *      defaultMenuTaxMethod: // value for 'defaultMenuTaxMethod'
 *   },
 * });
 */
export function useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation(baseOptions?: Apollo.MutationHookOptions<EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation, EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation, EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationVariables>(EditDefaultTaxMethodsUpdateDefaultTaxMethodDocument, options);
      }
export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationHookResult = ReturnType<typeof useEditDefaultTaxMethodsUpdateDefaultTaxMethodMutation>;
export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationResult = Apollo.MutationResult<EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation>;
export type EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationOptions = Apollo.BaseMutationOptions<EditDefaultTaxMethodsUpdateDefaultTaxMethodMutation, EditDefaultTaxMethodsUpdateDefaultTaxMethodMutationVariables>;