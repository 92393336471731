import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditPikaichiOnSitePaymentDetailGetDetailType = gql`
    query EditPikaichiOnSitePaymentDetailGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDetailType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}}
  ) {
    id
    type
    label
    pikaichiOnSitePaymentDetailTypes {
      pikaichiPaymentDetailTypeId
      onSitePaymentDetailTypeEntityType
      paymentMediaCd
      paymentMediaName
      paymentUchiwakeCd
      paymentUchiwakeName
    }
  }
}
    `;
export const EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailType = gql`
    mutation EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailType($pikaichiOnSitePaymentDetailType: pikaichiOnSitePaymentDetailType_insert_input!) {
  insert_pikaichiOnSitePaymentDetailType_one(
    object: $pikaichiOnSitePaymentDetailType
    on_conflict: {constraint: pikaichiOnSitePaymentDetailType_pkey, update_columns: [paymentMediaCd, paymentMediaName, paymentUchiwakeCd, paymentUchiwakeName, onSitePaymentDetailTypeId]}
  ) {
    pikaichiPaymentDetailTypeId
  }
}
    `;
export type EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables = Types.Exact<{
  type: Types.Scalars['String'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditPikaichiOnSitePaymentDetailGetDetailTypeQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
    & { pikaichiOnSitePaymentDetailTypes: Array<(
      { __typename?: 'pikaichiOnSitePaymentDetailType' }
      & Pick<Types.PikaichiOnSitePaymentDetailType, 'pikaichiPaymentDetailTypeId' | 'onSitePaymentDetailTypeEntityType' | 'paymentMediaCd' | 'paymentMediaName' | 'paymentUchiwakeCd' | 'paymentUchiwakeName'>
    )> }
  )> }
);

export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationVariables = Types.Exact<{
  pikaichiOnSitePaymentDetailType: Types.PikaichiOnSitePaymentDetailTypeInsertInput;
}>;


export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiOnSitePaymentDetailType_one?: Types.Maybe<(
    { __typename?: 'pikaichiOnSitePaymentDetailType' }
    & Pick<Types.PikaichiOnSitePaymentDetailType, 'pikaichiPaymentDetailTypeId'>
  )> }
);


export const EditPikaichiOnSitePaymentDetailGetDetailTypeDocument = gql`
    query EditPikaichiOnSitePaymentDetailGetDetailType($type: String!, $companyId: uuid!) {
  onSitePaymentDetailType(
    where: {type: {_eq: $type}, companyId: {_eq: $companyId}}
  ) {
    id
    type
    label
    pikaichiOnSitePaymentDetailTypes {
      pikaichiPaymentDetailTypeId
      onSitePaymentDetailTypeEntityType
      paymentMediaCd
      paymentMediaName
      paymentUchiwakeCd
      paymentUchiwakeName
    }
  }
}
    `;

/**
 * __useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery__
 *
 * To run a query within a React component, call `useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery(baseOptions: Apollo.QueryHookOptions<EditPikaichiOnSitePaymentDetailGetDetailTypeQuery, EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditPikaichiOnSitePaymentDetailGetDetailTypeQuery, EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables>(EditPikaichiOnSitePaymentDetailGetDetailTypeDocument, options);
      }
export function useEditPikaichiOnSitePaymentDetailGetDetailTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditPikaichiOnSitePaymentDetailGetDetailTypeQuery, EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditPikaichiOnSitePaymentDetailGetDetailTypeQuery, EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables>(EditPikaichiOnSitePaymentDetailGetDetailTypeDocument, options);
        }
export type EditPikaichiOnSitePaymentDetailGetDetailTypeQueryHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery>;
export type EditPikaichiOnSitePaymentDetailGetDetailTypeLazyQueryHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDetailGetDetailTypeLazyQuery>;
export type EditPikaichiOnSitePaymentDetailGetDetailTypeQueryResult = Apollo.QueryResult<EditPikaichiOnSitePaymentDetailGetDetailTypeQuery, EditPikaichiOnSitePaymentDetailGetDetailTypeQueryVariables>;
export const EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeDocument = gql`
    mutation EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailType($pikaichiOnSitePaymentDetailType: pikaichiOnSitePaymentDetailType_insert_input!) {
  insert_pikaichiOnSitePaymentDetailType_one(
    object: $pikaichiOnSitePaymentDetailType
    on_conflict: {constraint: pikaichiOnSitePaymentDetailType_pkey, update_columns: [paymentMediaCd, paymentMediaName, paymentUchiwakeCd, paymentUchiwakeName, onSitePaymentDetailTypeId]}
  ) {
    pikaichiPaymentDetailTypeId
  }
}
    `;
export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationFn = Apollo.MutationFunction<EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationVariables>;

/**
 * __useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation__
 *
 * To run a mutation, you first call `useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation, { data, loading, error }] = useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation({
 *   variables: {
 *      pikaichiOnSitePaymentDetailType: // value for 'pikaichiOnSitePaymentDetailType'
 *   },
 * });
 */
export function useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationVariables>(EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeDocument, options);
      }
export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationHookResult = ReturnType<typeof useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation>;
export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationResult = Apollo.MutationResult<EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation>;
export type EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationOptions = Apollo.BaseMutationOptions<EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation, EditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutationVariables>;