import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopPlanRolesGetPlanRolesRoles = gql`
    query EditShopPlanRolesGetPlanRolesRoles($planId: Int!, $shopId: uuid!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    companyId
    planId
    planName
    shopPlanKitchenRoles(
      where: {shopPlan: {archivedAt: {_is_null: true}}, shop: {archivedAt: {_is_null: true}}, _planId: {_eq: $planId}, shopId: {_eq: $shopId}}
    ) {
      role {
        id
        name
        roleId
      }
    }
  }
}
    `;
export const EditShopPlanRolesGetRoles = gql`
    query EditShopPlanRolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      id
      name
      roleId
    }
  }
}
    `;
export const EditShopPlanRolesInsertPlanRolesRole = gql`
    mutation EditShopPlanRolesInsertPlanRolesRole($shopPlanKitchenRoles: [shopPlanKitchenRole_insert_input!]!) {
  insert_shopPlanKitchenRole(
    objects: $shopPlanKitchenRoles
    on_conflict: {constraint: shopPlanKitchenRole_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export const EditShopPlanRolesDeletePlanRolesRole = gql`
    mutation EditShopPlanRolesDeletePlanRolesRole($planId: Int!, $roleId: uuid!) {
  delete_shopPlanKitchenRole(
    where: {_and: [{_planId: {_eq: $planId}}, {roleId: {_eq: $roleId}}]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanRolesGetPlanRolesRolesQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopPlanRolesGetPlanRolesRolesQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'companyId' | 'planId' | 'planName'>
    & { shopPlanKitchenRoles: Array<(
      { __typename?: 'shopPlanKitchenRole' }
      & { role: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'name' | 'roleId'>
      ) }
    )> }
  )> }
);

export type EditShopPlanRolesGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopPlanRolesGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )> }
  )> }
);

export type EditShopPlanRolesInsertPlanRolesRoleMutationVariables = Types.Exact<{
  shopPlanKitchenRoles: Array<Types.ShopPlanKitchenRoleInsertInput> | Types.ShopPlanKitchenRoleInsertInput;
}>;


export type EditShopPlanRolesInsertPlanRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopPlanKitchenRole?: Types.Maybe<(
    { __typename?: 'shopPlanKitchenRole_mutation_response' }
    & Pick<Types.ShopPlanKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type EditShopPlanRolesDeletePlanRolesRoleMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  roleId: Types.Scalars['uuid'];
}>;


export type EditShopPlanRolesDeletePlanRolesRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopPlanKitchenRole?: Types.Maybe<(
    { __typename?: 'shopPlanKitchenRole_mutation_response' }
    & Pick<Types.ShopPlanKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);


export const EditShopPlanRolesGetPlanRolesRolesDocument = gql`
    query EditShopPlanRolesGetPlanRolesRoles($planId: Int!, $shopId: uuid!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    id
    companyId
    planId
    planName
    shopPlanKitchenRoles(
      where: {shopPlan: {archivedAt: {_is_null: true}}, shop: {archivedAt: {_is_null: true}}, _planId: {_eq: $planId}, shopId: {_eq: $shopId}}
    ) {
      role {
        id
        name
        roleId
      }
    }
  }
}
    `;

/**
 * __useEditShopPlanRolesGetPlanRolesRolesQuery__
 *
 * To run a query within a React component, call `useEditShopPlanRolesGetPlanRolesRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanRolesGetPlanRolesRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlanRolesGetPlanRolesRolesQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopPlanRolesGetPlanRolesRolesQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlanRolesGetPlanRolesRolesQuery, EditShopPlanRolesGetPlanRolesRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlanRolesGetPlanRolesRolesQuery, EditShopPlanRolesGetPlanRolesRolesQueryVariables>(EditShopPlanRolesGetPlanRolesRolesDocument, options);
      }
export function useEditShopPlanRolesGetPlanRolesRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlanRolesGetPlanRolesRolesQuery, EditShopPlanRolesGetPlanRolesRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlanRolesGetPlanRolesRolesQuery, EditShopPlanRolesGetPlanRolesRolesQueryVariables>(EditShopPlanRolesGetPlanRolesRolesDocument, options);
        }
export type EditShopPlanRolesGetPlanRolesRolesQueryHookResult = ReturnType<typeof useEditShopPlanRolesGetPlanRolesRolesQuery>;
export type EditShopPlanRolesGetPlanRolesRolesLazyQueryHookResult = ReturnType<typeof useEditShopPlanRolesGetPlanRolesRolesLazyQuery>;
export type EditShopPlanRolesGetPlanRolesRolesQueryResult = Apollo.QueryResult<EditShopPlanRolesGetPlanRolesRolesQuery, EditShopPlanRolesGetPlanRolesRolesQueryVariables>;
export const EditShopPlanRolesGetRolesDocument = gql`
    query EditShopPlanRolesGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      id
      name
      roleId
    }
  }
}
    `;

/**
 * __useEditShopPlanRolesGetRolesQuery__
 *
 * To run a query within a React component, call `useEditShopPlanRolesGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanRolesGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopPlanRolesGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopPlanRolesGetRolesQuery(baseOptions: Apollo.QueryHookOptions<EditShopPlanRolesGetRolesQuery, EditShopPlanRolesGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopPlanRolesGetRolesQuery, EditShopPlanRolesGetRolesQueryVariables>(EditShopPlanRolesGetRolesDocument, options);
      }
export function useEditShopPlanRolesGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopPlanRolesGetRolesQuery, EditShopPlanRolesGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopPlanRolesGetRolesQuery, EditShopPlanRolesGetRolesQueryVariables>(EditShopPlanRolesGetRolesDocument, options);
        }
export type EditShopPlanRolesGetRolesQueryHookResult = ReturnType<typeof useEditShopPlanRolesGetRolesQuery>;
export type EditShopPlanRolesGetRolesLazyQueryHookResult = ReturnType<typeof useEditShopPlanRolesGetRolesLazyQuery>;
export type EditShopPlanRolesGetRolesQueryResult = Apollo.QueryResult<EditShopPlanRolesGetRolesQuery, EditShopPlanRolesGetRolesQueryVariables>;
export const EditShopPlanRolesInsertPlanRolesRoleDocument = gql`
    mutation EditShopPlanRolesInsertPlanRolesRole($shopPlanKitchenRoles: [shopPlanKitchenRole_insert_input!]!) {
  insert_shopPlanKitchenRole(
    objects: $shopPlanKitchenRoles
    on_conflict: {constraint: shopPlanKitchenRole_pkey, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanRolesInsertPlanRolesRoleMutationFn = Apollo.MutationFunction<EditShopPlanRolesInsertPlanRolesRoleMutation, EditShopPlanRolesInsertPlanRolesRoleMutationVariables>;

/**
 * __useEditShopPlanRolesInsertPlanRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopPlanRolesInsertPlanRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanRolesInsertPlanRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopPlanRolesInsertPlanRolesRoleMutation, { data, loading, error }] = useEditShopPlanRolesInsertPlanRolesRoleMutation({
 *   variables: {
 *      shopPlanKitchenRoles: // value for 'shopPlanKitchenRoles'
 *   },
 * });
 */
export function useEditShopPlanRolesInsertPlanRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopPlanRolesInsertPlanRolesRoleMutation, EditShopPlanRolesInsertPlanRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopPlanRolesInsertPlanRolesRoleMutation, EditShopPlanRolesInsertPlanRolesRoleMutationVariables>(EditShopPlanRolesInsertPlanRolesRoleDocument, options);
      }
export type EditShopPlanRolesInsertPlanRolesRoleMutationHookResult = ReturnType<typeof useEditShopPlanRolesInsertPlanRolesRoleMutation>;
export type EditShopPlanRolesInsertPlanRolesRoleMutationResult = Apollo.MutationResult<EditShopPlanRolesInsertPlanRolesRoleMutation>;
export type EditShopPlanRolesInsertPlanRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopPlanRolesInsertPlanRolesRoleMutation, EditShopPlanRolesInsertPlanRolesRoleMutationVariables>;
export const EditShopPlanRolesDeletePlanRolesRoleDocument = gql`
    mutation EditShopPlanRolesDeletePlanRolesRole($planId: Int!, $roleId: uuid!) {
  delete_shopPlanKitchenRole(
    where: {_and: [{_planId: {_eq: $planId}}, {roleId: {_eq: $roleId}}]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopPlanRolesDeletePlanRolesRoleMutationFn = Apollo.MutationFunction<EditShopPlanRolesDeletePlanRolesRoleMutation, EditShopPlanRolesDeletePlanRolesRoleMutationVariables>;

/**
 * __useEditShopPlanRolesDeletePlanRolesRoleMutation__
 *
 * To run a mutation, you first call `useEditShopPlanRolesDeletePlanRolesRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopPlanRolesDeletePlanRolesRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopPlanRolesDeletePlanRolesRoleMutation, { data, loading, error }] = useEditShopPlanRolesDeletePlanRolesRoleMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useEditShopPlanRolesDeletePlanRolesRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditShopPlanRolesDeletePlanRolesRoleMutation, EditShopPlanRolesDeletePlanRolesRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopPlanRolesDeletePlanRolesRoleMutation, EditShopPlanRolesDeletePlanRolesRoleMutationVariables>(EditShopPlanRolesDeletePlanRolesRoleDocument, options);
      }
export type EditShopPlanRolesDeletePlanRolesRoleMutationHookResult = ReturnType<typeof useEditShopPlanRolesDeletePlanRolesRoleMutation>;
export type EditShopPlanRolesDeletePlanRolesRoleMutationResult = Apollo.MutationResult<EditShopPlanRolesDeletePlanRolesRoleMutation>;
export type EditShopPlanRolesDeletePlanRolesRoleMutationOptions = Apollo.BaseMutationOptions<EditShopPlanRolesDeletePlanRolesRoleMutation, EditShopPlanRolesDeletePlanRolesRoleMutationVariables>;