import React, { memo } from "react";
import { Tag } from "antd";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Menu } from "../types";

type Props = {
  loading?: boolean;
  menus: Menu[];
  onRemove: (menuId: number) => void;
};

export const MenuTable = memo<Props>(({ loading, menus, onRemove }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "カテゴリ名",
      render(_: string, { categoryMenus }: Menu) {
        return categoryMenus.map(({ categoryId, category: { name } }) => (
          <Tag key={categoryId}>{name}</Tag>
        ));
      },
    },
    { title: "メニュー名", dataIndex: "name" },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { menuId }: Menu) {
        return (
          <IconLink to={`/menu/${menuId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { menuId }: Menu) {
        return <DeleteIcon onClick={() => onRemove(menuId)} />;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="menuId"
      columns={columns}
      dataSource={menus}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
