import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopExternalOnlineMenuFormItem } from "../useEditShopExternalOnlineMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const UrlField = memo<Props>((props) => (
  <EditShopExternalOnlineMenuFormItem
    label="メニューURL"
    name="url"
    rules={[{ required: true, message: "メニューURLを入力してください" }]}
    required
    {...props}
  >
    <Input />
  </EditShopExternalOnlineMenuFormItem>
));
