import React, { memo, useCallback } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = {
  hasPlanTimeLimit: boolean;
  setHasPlanTimeLimit: (hasPlanTimeLimit: boolean) => void;
  disabled: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const PlanTimeLimitField = memo<Props>(
  ({ hasPlanTimeLimit, setHasPlanTimeLimit, disabled, ...props }) => {
    const handleClickToggleHasPlanTimeLimit = useCallback(
      () => setHasPlanTimeLimit(!hasPlanTimeLimit),
      [setHasPlanTimeLimit, hasPlanTimeLimit],
    );

    return (
      <EditPlanFormItem.NonProperty label="制限時間を設定する" {...props}>
        <Switch
          checked={hasPlanTimeLimit}
          onClick={handleClickToggleHasPlanTimeLimit}
          disabled={disabled}
        />
      </EditPlanFormItem.NonProperty>
    );
  },
);
