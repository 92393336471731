import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Alert, Badge, Menu } from "antd";
import { getTecAggregationIntegrationTypeName } from "models/tecAggregationIntegrationType";

import { PageHeader } from "components/antd/PageHeader";
import { Dealers } from "components/Dealers";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { Plan as PlanType, Shop } from "types/graphql";

import { usePlanHeaderGetBiPlansQuery } from "./queries";

type Props = {
  plan:
    | (Pick<PlanType, "planName" | "planId" | "totalDealingShopCount"> & {
        shopPlans: { shop: Pick<Shop, "shopId" | "name"> }[];
      })
    | null;
  onBack: () => void;
  shouldShowNetworkErrorAlert?: boolean;
  shouldShowManagingShopErrorAlert?: boolean;
};

export const PlanHeader = memo<Props>(
  ({ plan, onBack, shouldShowNetworkErrorAlert, shouldShowManagingShopErrorAlert }) => {
    const { pathname } = useLocation();
    const { canAccess } = useCanAccess();
    const [company] = useCompany();

    const selectedKey = pathname.split("/")[3];
    const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

    const dealers = plan?.shopPlans.flatMap(({ shop }) => shop);

    const isPikaichiEnabled = Boolean(
      canAccess("editPlanPikaichiPlan") &&
        company?.shops.some((shop) => shop.pikaichiConfig?.enabled ?? false),
    );

    const isTecAggregationEnabled = Boolean(
      canAccess("editPlanTecAggregationPlan") &&
        company?.shops.some((shop) => shop.tecAggregationConfig?.enabled ?? false),
    );

    const isWinboardEnabled = Boolean(
      canAccess("editPlanWinboardPlan") &&
        company?.shops.some((shop) => shop.winboardConfig?.enabled ?? false),
    );

    const { data: getPlanBiData } = usePlanHeaderGetBiPlansQuery(
      plan?.planId
        ? {
            variables: {
              planId: plan.planId,
              isPikaichiEnabled,
              isTecAggregationEnabled,
              isWinboardEnabled,
            },
          }
        : { skip: true },
    );

    const pikaichiMenuPlan = getPlanBiData?.pikaichiMenuPlan?.[0];
    const tecAggregationMenuPlan = getPlanBiData?.tecAggregationMenu?.[0];
    const winboardMenuPlan = getPlanBiData?.winboardMenu?.[0];

    const tecAggregationIntegrationTypeName = company?.shops
      .map(({ tecAggregationConfig }) =>
        getTecAggregationIntegrationTypeName(tecAggregationConfig?.integrationType ?? ""),
      )
      .find((v) => v !== "");

    return (
      <>
        <PageHeader
          onBack={onBack}
          title={plan?.planName ?? ""}
          footer={
            <Dealers
              shops={dealers ?? null}
              hiddenShopCount={(plan?.totalDealingShopCount ?? 0) - (dealers?.length ?? 0)}
            />
          }
        />
        {shouldShowNetworkErrorAlert ? (
          <>
            <Alert
              message="通信に失敗しました"
              type="error"
              description="ネットワーク環境を確認してください"
            />
            <Spacer size={16} />
          </>
        ) : shouldShowManagingShopErrorAlert ? (
          <>
            <Alert
              showIcon
              type="warning"
              message="このプランは編集できません。"
              description="このプランは他の店舗でも取り扱われているため、編集することができません。編集したい場合は法人管理者または業態管理者に依頼してください。"
            />
            <Spacer size={16} />
          </>
        ) : null}
        {plan && (
          <Menu selectedKeys={selectedKeys} mode="horizontal">
            <Menu.Item key="edit">
              <Link to={`/plan/${plan.planId}/edit`} replace>
                基本情報
              </Link>
            </Menu.Item>
            <Menu.Item key="option">
              <Link to={`/plan/${plan.planId}/option`} replace>
                オプション
              </Link>
            </Menu.Item>
            <Menu.Item key="category">
              <Link to={`/plan/${plan.planId}/category`} replace>
                プラン内カテゴリ
              </Link>
            </Menu.Item>
            <Menu.Item key="menu">
              <Link to={`/plan/${plan.planId}/menu`} replace>
                プラン内メニュー
              </Link>
            </Menu.Item>
            <Menu.Item key="firstOrderCategoryMenu">
              <Link to={`/plan/${plan.planId}/firstOrderCategoryMenu`} replace>
                自動注文設定
              </Link>
            </Menu.Item>
            <Menu.Item key="dealer">
              <Link to={`/plan/${plan.planId}/dealer/edit`} replace>
                取扱設定
              </Link>
            </Menu.Item>
            <Menu.Item key="translation">
              <Link to={`/plan/${plan.planId}/translation/edit`} replace>
                多言語設定
              </Link>
            </Menu.Item>
            {isPikaichiEnabled && (
              <Menu.Item key="pikaichi">
                <Link to={`/plan/${plan.planId}/pikaichi/edit`} replace>
                  ぴかいちナビ連携
                </Link>
                {!pikaichiMenuPlan && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Menu.Item>
            )}
            {isTecAggregationEnabled && (
              <Menu.Item key="tec">
                <Link to={`/plan/${plan.planId}/tec/edit`} replace>
                  {tecAggregationIntegrationTypeName ? tecAggregationIntegrationTypeName : "-"}
                </Link>
                {!tecAggregationMenuPlan && (
                  <>
                    <Spacer width={5} inline />
                    <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                  </>
                )}
              </Menu.Item>
            )}
            {isWinboardEnabled && (
              <Menu.Item key="winboard">
                <Link to={`/plan/${plan.planId}/winboard/edit`} replace>
                  ウィンボード連携
                  {!winboardMenuPlan && (
                    <>
                      <Spacer width={5} inline />
                      <Badge color={colors.Text.Primary} offset={[0, -3]} count="未設定" />
                    </>
                  )}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        )}
      </>
    );
  },
);
