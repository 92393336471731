import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { PikaichiOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { EditPikaichiOnSitePaymentDetailForm } from "./EditPikaichiOnSitePaymentDetailTypeForm";
import {
  useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery,
  useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation,
} from "./queries";

export const EditPikaichiOnSitePaymentDetailType = () => {
  const [company] = useCompany();

  const { type } = useParams<{ type: string }>();
  const navigate = useNavigate();

  const {
    data: getDetailTypeData,
    loading: loadingDetailType,
    refetch: refetchDetailType,
    error,
  } = useEditPikaichiOnSitePaymentDetailGetDetailTypeQuery(
    company && type ? { variables: { type, companyId: company.id } } : { skip: true },
  );
  const onSitePaymentDetailType = getDetailTypeData?.onSitePaymentDetailType[0];
  const pikaichiOnSitePaymentDetailType =
    onSitePaymentDetailType?.pikaichiOnSitePaymentDetailTypes[0];

  const [
    updatePikaichiOnSitePaymentDetailTypeMutation,
    { loading: loadingUpdatePikaichiOnSitePaymentDetailType },
  ] = useEditPikaichiOnSitePaymentDetailTypeUpdatePikaichiDetailTypeMutation();

  const onSubmit = useCallback(
    async (
      input: Omit<
        PikaichiOnSitePaymentDetailTypeInsertInput,
        "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
      >,
    ) => {
      if (!company) return;
      if (!type) return;
      if (!onSitePaymentDetailType) return;

      const pikaichiPaymentDetailTypeId =
        pikaichiOnSitePaymentDetailType?.pikaichiPaymentDetailTypeId ?? uuidv4();

      try {
        await updatePikaichiOnSitePaymentDetailTypeMutation({
          variables: {
            pikaichiOnSitePaymentDetailType: {
              pikaichiPaymentDetailTypeId,
              onSitePaymentDetailTypeEntityType: type,
              companyId: company.id,
              onSitePaymentDetailTypeId: onSitePaymentDetailType.id,
              ...input,
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchDetailType();
    },
    [
      company,
      onSitePaymentDetailType,
      pikaichiOnSitePaymentDetailType?.pikaichiPaymentDetailTypeId,
      type,
      updatePikaichiOnSitePaymentDetailTypeMutation,
      refetchDetailType,
    ],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingDetailType && loadingUpdatePikaichiOnSitePaymentDetailType;
  return (
    <DashboardLayout
      title={onSitePaymentDetailType?.label}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビメニュー" }],
      }}
    >
      <PageHeader title={onSitePaymentDetailType?.label} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {onSitePaymentDetailType && (
        <EditPikaichiOnSitePaymentDetailForm
          onSitePaymentDetailType={onSitePaymentDetailType}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingDetailType}
        />
      )}
    </DashboardLayout>
  );
};
