import React, { memo } from "react";
import { Select } from "antd";
import { FormItemProps } from "antd/lib/form";

import { FormHelp } from "components/Form/FormHelp";
import { OrderableTime } from "pages/EditMenu/types";

import { EditMenuFormItem } from "../useEditMenuForm";

const { Option } = Select;

type Props = {
  orderableTimes: OrderableTime[];
  disabled?: boolean;
} & Omit<FormItemProps, "children" | "name">;

export const OrderableTimeField = memo<Props>(({ orderableTimes, disabled, ...props }) => (
  <EditMenuFormItem
    label={
      <FormHelp
        label="表示時間帯"
        help="お客様の注文画面に表示される時間の設定です。ハンディからは常時注文可能です"
      />
    }
    name="orderableTimeId"
    rules={[{ required: true, message: "アプリ表示時間名を入力してください" }]}
    {...props}
  >
    <Select<number> placeholder="表示時間帯を選択してください" disabled={disabled}>
      {orderableTimes.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  </EditMenuFormItem>
));
