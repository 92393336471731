import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { insertWithRelation } from "util/type/insert-with-relation";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { useQueryParams } from "hooks/useQuery";
import { AddCouponFormValues } from "pages/AddCoupon/AddCouponForm/useAddCouponForm";
import {
  useAddCouponGetMenusQuery,
  useAddCouponInsertCouponMutation,
  useCopyCouponGetCouponQuery,
} from "pages/AddCoupon/queries";
import { CouponTypeEnum } from "types/graphql";

import { AddCouponForm } from "./AddCouponForm";

export const AddCoupon = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getMenusData,
    loading: loadingMenusData,
    error: getMenusError,
  } = useAddCouponGetMenusQuery(companyId ? { variables: { companyId } } : { skip: true });
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData]);

  const [addCouponMutation, { loading: insertLoading }] = useAddCouponInsertCouponMutation();

  const query = useQueryParams();
  const sourceCouponId = query.get("id");

  const { data: sourceCouponData, loading: loadingSourceCoupon } = useCopyCouponGetCouponQuery(
    sourceCouponId
      ? {
          variables: { id: sourceCouponId },
        }
      : { skip: true },
  );
  const sourceCoupon = useMemo(
    () => sourceCouponData?.coupon_by_pk ?? undefined,
    [sourceCouponData?.coupon_by_pk],
  );

  const loading = Boolean(loadingMenusData || insertLoading || loadingSourceCoupon);
  const shouldShowAlert = Boolean(getMenusError);

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({ coupon: formValues }: { coupon: AddCouponFormValues }) => {
      try {
        if (!companyId) return;
        const {
          name,
          title,
          availableDays,
          availableFrom,
          availableUntil,
          content,
          description,
          termsOfUse,
          couponType,
          ticket: ticketTypeCouponFormValues,
          menuDiscount: menuDiscountTypeCouponFormValues,
        } = formValues;
        const commonFormValues = {
          name,
          title,
          availableDays,
          availableFrom,
          availableUntil,
          content,
          description,
          termsOfUse,
          companyId,
          couponType,
        };

        const coupon =
          couponType === CouponTypeEnum.MenuDiscount
            ? insertWithRelation<["coupon", "couponMenuDiscount"]>({
                ...commonFormValues,
                couponMenuDiscount: {
                  data: {
                    // NOTE: バリデーションでチェックできている
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                    discountedPrice: menuDiscountTypeCouponFormValues?.discountedPrice!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
                    _menuId: menuDiscountTypeCouponFormValues?.menuId!,
                  },
                },
              })
            : couponType === CouponTypeEnum.Ticket
            ? { ...commonFormValues, imageUrl: ticketTypeCouponFormValues?.imageUrl }
            : null;

        if (!coupon) throw new Error("Coupon type specified is not implemented yet");

        await addCouponMutation({
          variables: { coupon },
        });

        message.success("作成しました");

        navigate("/coupon", { replace: true });
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [companyId, addCouponMutation, navigate],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="クーポンを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "クーポン" }],
      }}
    >
      <PageHeader title="クーポンを新規作成" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {loading ? (
        <Loading height={300} />
      ) : (
        <AddCouponForm
          sourceCoupon={sourceCoupon}
          loading={submitting}
          onSubmit={onSubmit}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
          menus={menus}
        />
      )}
    </DashboardLayout>
  );
};
