import useAsync from "react-use/esm/useAsync";
import { isAxiosError } from "util/type/axios";

export const useExtractErrorResponse = (error: Error | undefined) => {
  const errorResponseState = useAsync(async () => {
    if (!isAxiosError<{ text: () => string }>(error)) return null;
    if (!error.response) return null;

    const responseText = await error.response.data.text();
    const { title, message } = JSON.parse(responseText);

    return { title, message };
  }, [error]);

  return errorResponseState.value ?? null;
};
