import React, { useCallback } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PikaichiPlanHeader } from "components/PageHeader/PikaichiPlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";

import { EditPlanChoiceModal } from "./EditPlanChoicePikaichiMenuModal";
import { PlanOptionTable } from "./PlanOptionTable";
import { usePikaichiPlanOptionsGetPlanQuery } from "./queries";

export const PikaichiPlanOptions = () => {
  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getMenuData,
    loading: loadingPlan,
    refetch: refetchMenuData,
    error,
  } = usePikaichiPlanOptionsGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getMenuData?.plan?.[0];

  setCompany(plan?.companyId);

  const planOptions = plan?.planOptions;

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToPlanOption = useCallback(() => {
    navigate(`/pikaichi/plan/${planId}/option`, { replace: true });
    refetchMenuData();
  }, [navigate, planId, refetchMenuData]);

  const onCompleted = useCallback(async () => {
    await refetchMenuData();
    goBackToPlanOption();
  }, [refetchMenuData, goBackToPlanOption]);

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "ぴかいちナビプラン" }],
      }}
    >
      <PikaichiPlanHeader plan={plan ?? null} onBack={goBack} />
      {loadingPlan && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {plan && planOptions && (
        <FormContent>
          <PlanOptionTable planId={plan.planId} planOptions={planOptions} />
          <Routes>
            <Route
              path=":planOptionId/choice/:planChoiceId/edit"
              element={
                <EditPlanChoiceModal onCloseModal={goBackToPlanOption} onCompleted={onCompleted} />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
