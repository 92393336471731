import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Descriptions, Select, Tag } from "antd";
import { NamePath } from "antd/lib/form/interface";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { Option } from "pages/AddMenu/types";

const { Option: SelectOption } = Select;

type Props = {
  name: NamePath;
  options: Option[];
};

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-content {
    flex-wrap: wrap;
    row-gap: 8px;
  }
`;

const generateDescriptionsItems = (option: Option) => [
  {
    key: "choices",
    label: "選択肢",
    children: option.choices.map((choice) => <Tag key={choice.choiceId}>{choice.name}</Tag>),
  },
  {
    key: "menuOptions",
    label: "紐づくメニュー",
    children: option.menuOptions.map((menuOption) => (
      <Tag key={menuOption.menuId}>{menuOption.menu.name}</Tag>
    )),
  },
];

export const OptionField = memo<Props>(({ name, options }) => {
  const [isFocused, setIsFocused] = useState(false);

  // NOTE: ドラッグ時に Descriptions の分の高さの要素が操作されてしまいデザインに違和感があるため、非ドラッグ時（フォーカスがあたっていないとき）は Descriptions を非表示にする
  // https://dinii.atlassian.net/browse/DN-2889
  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  return (
    <FormItem
      rules={[{ required: true, message: "オプションを選択してください" }]}
      name={name}
      endSpacer={null}
    >
      <Select
        showSearch
        optionFilterProp="label"
        placeholder="オプションを選択してください"
        listHeight={400}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width: "100%" }}
        optionLabelProp="label"
      >
        {options.map((option) => (
          <SelectOption key={option.optionId} label={option.name} value={option.optionId}>
            {option.name}
            {isFocused && (
              <StyledDescriptions column={1} items={generateDescriptionsItems(option)} />
            )}
          </SelectOption>
        ))}
      </Select>
    </FormItem>
  );
});
