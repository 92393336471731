import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditWinboardMenuFormItem } from "../useEditWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CodeField = memo<Props>((props) => (
  <EditWinboardMenuFormItem label="メニューコード" name="code" {...props}>
    <InputCode />
  </EditWinboardMenuFormItem>
));
