import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { TecAggregationOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { OnSitePaymentDetail, TecAggregationOnSitePaymentDetailType } from "../types";

export type EditDetailTypeFormValues = Pick<
  TecAggregationOnSitePaymentDetailType,
  "mediaCode" | "mediaName" | "paymentType"
> & { aggregationNumber: number };

const getInitialValues = (
  detailType: OnSitePaymentDetailTypeWithTecAggregationOnSitePaymentDetailType,
) => {
  const tecAggregationOnSitePaymentDetailType = detailType.tecAggregationOnSitePaymentDetailType;
  return {
    mediaCode: tecAggregationOnSitePaymentDetailType?.mediaCode,
    mediaName: tecAggregationOnSitePaymentDetailType?.mediaName,
    paymentType: tecAggregationOnSitePaymentDetailType?.paymentType,
    aggregationNumber:
      tecAggregationOnSitePaymentDetailType?.tecAggregationMediaMap?.aggregationNumber,
  };
};

type OnSitePaymentDetailTypeWithTecAggregationOnSitePaymentDetailType = Omit<
  OnSitePaymentDetail,
  "tecAggregationOnSitePaymentDetailTypes"
> & { tecAggregationOnSitePaymentDetailType?: TecAggregationOnSitePaymentDetailType };

export const EditDetailTypeFormItem = createFormItem<EditDetailTypeFormValues>();

export const useEditDetailTypeForm = ({
  onSubmit,
  onSitePaymentDetailType,
}: {
  onSubmit: ({
    detailType,
    aggregationNumber,
  }: {
    detailType: Omit<
      TecAggregationOnSitePaymentDetailTypeInsertInput,
      "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
    >;
    aggregationNumber: number;
  }) => void;
  onSitePaymentDetailType: OnSitePaymentDetailTypeWithTecAggregationOnSitePaymentDetailType;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(onSitePaymentDetailType);

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditDetailTypeFormValues;
    onSubmit({ detailType: { ...values }, aggregationNumber: values.aggregationNumber });
  }, [form, onSubmit]);

  return { form, submit, initialValues };
};
