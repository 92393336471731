import React, { memo } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-left: 8px;
  font-size: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.25);
`;

type Props = { help: string; label: string };

export const FormHelp = memo<Props>(({ help, label }) => (
  <Tooltip title={help}>
    <Wrapper>
      {label}
      <StyledQuestionCircleOutlined />
    </Wrapper>
  </Tooltip>
));
