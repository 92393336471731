import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditCookingItemGetCookingItemsAndShopAndRoles = gql`
    query EditCookingItemGetCookingItemsAndShopAndRoles($id: Int!) {
  cookingItem(where: {serial: {_eq: $id}}) {
    name
    shopId
    id: serial
    cookingItemId
    shopCookingItemRole {
      role {
        id
        roleId
        name
      }
    }
  }
  shop(
    where: {cookingItems: {serial: {_eq: $id}}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    companyId
    roles {
      id
      name
      roleId
    }
  }
}
    `;
export const EditCookingItemUpdateCookingItem = gql`
    mutation EditCookingItemUpdateCookingItem($id: Int!, $shopId: uuid!, $cookingItem: cookingItem_set_input!, $shopCookingItemRole: shopCookingItemRole_insert_input!) {
  update_cookingItem(
    _set: $cookingItem
    where: {serial: {_eq: $id}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopCookingItemRole_one(
    object: $shopCookingItemRole
    on_conflict: {constraint: shopCookingItemRole__cookingItemId_key, update_columns: [roleId, _roleId]}
  ) {
    id
    roleId
  }
}
    `;
export const EditCookingItemUpdateCookingItemAndDeleteRole = gql`
    mutation EditCookingItemUpdateCookingItemAndDeleteRole($id: Int!, $shopId: uuid!, $cookingItem: cookingItem_set_input!) {
  update_cookingItem(_set: $cookingItem, where: {serial: {_eq: $id}}) {
    affected_rows
  }
  delete_shopCookingItemRole(
    where: {_cookingItemId: {_eq: $id}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
}
    `;
export type EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type EditCookingItemGetCookingItemsAndShopAndRolesQuery = (
  { __typename?: 'query_root' }
  & { cookingItem: Array<(
    { __typename?: 'cookingItem' }
    & Pick<Types.CookingItem, 'name' | 'shopId' | 'cookingItemId'>
    & { id: Types.CookingItem['serial'] }
    & { shopCookingItemRole?: Types.Maybe<(
      { __typename?: 'shopCookingItemRole' }
      & { role: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'id' | 'roleId' | 'name'>
      ) }
    )> }
  )>, shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'companyId'>
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name' | 'roleId'>
    )> }
  )> }
);

export type EditCookingItemUpdateCookingItemMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  shopId: Types.Scalars['uuid'];
  cookingItem: Types.CookingItemSetInput;
  shopCookingItemRole: Types.ShopCookingItemRoleInsertInput;
}>;


export type EditCookingItemUpdateCookingItemMutation = (
  { __typename?: 'mutation_root' }
  & { update_cookingItem?: Types.Maybe<(
    { __typename?: 'cookingItem_mutation_response' }
    & Pick<Types.CookingItemMutationResponse, 'affected_rows'>
  )>, insert_shopCookingItemRole_one?: Types.Maybe<(
    { __typename?: 'shopCookingItemRole' }
    & Pick<Types.ShopCookingItemRole, 'id' | 'roleId'>
  )> }
);

export type EditCookingItemUpdateCookingItemAndDeleteRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  shopId: Types.Scalars['uuid'];
  cookingItem: Types.CookingItemSetInput;
}>;


export type EditCookingItemUpdateCookingItemAndDeleteRoleMutation = (
  { __typename?: 'mutation_root' }
  & { update_cookingItem?: Types.Maybe<(
    { __typename?: 'cookingItem_mutation_response' }
    & Pick<Types.CookingItemMutationResponse, 'affected_rows'>
  )>, delete_shopCookingItemRole?: Types.Maybe<(
    { __typename?: 'shopCookingItemRole_mutation_response' }
    & Pick<Types.ShopCookingItemRoleMutationResponse, 'affected_rows'>
  )> }
);


export const EditCookingItemGetCookingItemsAndShopAndRolesDocument = gql`
    query EditCookingItemGetCookingItemsAndShopAndRoles($id: Int!) {
  cookingItem(where: {serial: {_eq: $id}}) {
    name
    shopId
    id: serial
    cookingItemId
    shopCookingItemRole {
      role {
        id
        roleId
        name
      }
    }
  }
  shop(
    where: {cookingItems: {serial: {_eq: $id}}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    companyId
    roles {
      id
      name
      roleId
    }
  }
}
    `;

/**
 * __useEditCookingItemGetCookingItemsAndShopAndRolesQuery__
 *
 * To run a query within a React component, call `useEditCookingItemGetCookingItemsAndShopAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCookingItemGetCookingItemsAndShopAndRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCookingItemGetCookingItemsAndShopAndRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditCookingItemGetCookingItemsAndShopAndRolesQuery(baseOptions: Apollo.QueryHookOptions<EditCookingItemGetCookingItemsAndShopAndRolesQuery, EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditCookingItemGetCookingItemsAndShopAndRolesQuery, EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables>(EditCookingItemGetCookingItemsAndShopAndRolesDocument, options);
      }
export function useEditCookingItemGetCookingItemsAndShopAndRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditCookingItemGetCookingItemsAndShopAndRolesQuery, EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditCookingItemGetCookingItemsAndShopAndRolesQuery, EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables>(EditCookingItemGetCookingItemsAndShopAndRolesDocument, options);
        }
export type EditCookingItemGetCookingItemsAndShopAndRolesQueryHookResult = ReturnType<typeof useEditCookingItemGetCookingItemsAndShopAndRolesQuery>;
export type EditCookingItemGetCookingItemsAndShopAndRolesLazyQueryHookResult = ReturnType<typeof useEditCookingItemGetCookingItemsAndShopAndRolesLazyQuery>;
export type EditCookingItemGetCookingItemsAndShopAndRolesQueryResult = Apollo.QueryResult<EditCookingItemGetCookingItemsAndShopAndRolesQuery, EditCookingItemGetCookingItemsAndShopAndRolesQueryVariables>;
export const EditCookingItemUpdateCookingItemDocument = gql`
    mutation EditCookingItemUpdateCookingItem($id: Int!, $shopId: uuid!, $cookingItem: cookingItem_set_input!, $shopCookingItemRole: shopCookingItemRole_insert_input!) {
  update_cookingItem(
    _set: $cookingItem
    where: {serial: {_eq: $id}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopCookingItemRole_one(
    object: $shopCookingItemRole
    on_conflict: {constraint: shopCookingItemRole__cookingItemId_key, update_columns: [roleId, _roleId]}
  ) {
    id
    roleId
  }
}
    `;
export type EditCookingItemUpdateCookingItemMutationFn = Apollo.MutationFunction<EditCookingItemUpdateCookingItemMutation, EditCookingItemUpdateCookingItemMutationVariables>;

/**
 * __useEditCookingItemUpdateCookingItemMutation__
 *
 * To run a mutation, you first call `useEditCookingItemUpdateCookingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCookingItemUpdateCookingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCookingItemUpdateCookingItemMutation, { data, loading, error }] = useEditCookingItemUpdateCookingItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shopId: // value for 'shopId'
 *      cookingItem: // value for 'cookingItem'
 *      shopCookingItemRole: // value for 'shopCookingItemRole'
 *   },
 * });
 */
export function useEditCookingItemUpdateCookingItemMutation(baseOptions?: Apollo.MutationHookOptions<EditCookingItemUpdateCookingItemMutation, EditCookingItemUpdateCookingItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCookingItemUpdateCookingItemMutation, EditCookingItemUpdateCookingItemMutationVariables>(EditCookingItemUpdateCookingItemDocument, options);
      }
export type EditCookingItemUpdateCookingItemMutationHookResult = ReturnType<typeof useEditCookingItemUpdateCookingItemMutation>;
export type EditCookingItemUpdateCookingItemMutationResult = Apollo.MutationResult<EditCookingItemUpdateCookingItemMutation>;
export type EditCookingItemUpdateCookingItemMutationOptions = Apollo.BaseMutationOptions<EditCookingItemUpdateCookingItemMutation, EditCookingItemUpdateCookingItemMutationVariables>;
export const EditCookingItemUpdateCookingItemAndDeleteRoleDocument = gql`
    mutation EditCookingItemUpdateCookingItemAndDeleteRole($id: Int!, $shopId: uuid!, $cookingItem: cookingItem_set_input!) {
  update_cookingItem(_set: $cookingItem, where: {serial: {_eq: $id}}) {
    affected_rows
  }
  delete_shopCookingItemRole(
    where: {_cookingItemId: {_eq: $id}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
}
    `;
export type EditCookingItemUpdateCookingItemAndDeleteRoleMutationFn = Apollo.MutationFunction<EditCookingItemUpdateCookingItemAndDeleteRoleMutation, EditCookingItemUpdateCookingItemAndDeleteRoleMutationVariables>;

/**
 * __useEditCookingItemUpdateCookingItemAndDeleteRoleMutation__
 *
 * To run a mutation, you first call `useEditCookingItemUpdateCookingItemAndDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCookingItemUpdateCookingItemAndDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCookingItemUpdateCookingItemAndDeleteRoleMutation, { data, loading, error }] = useEditCookingItemUpdateCookingItemAndDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shopId: // value for 'shopId'
 *      cookingItem: // value for 'cookingItem'
 *   },
 * });
 */
export function useEditCookingItemUpdateCookingItemAndDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditCookingItemUpdateCookingItemAndDeleteRoleMutation, EditCookingItemUpdateCookingItemAndDeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCookingItemUpdateCookingItemAndDeleteRoleMutation, EditCookingItemUpdateCookingItemAndDeleteRoleMutationVariables>(EditCookingItemUpdateCookingItemAndDeleteRoleDocument, options);
      }
export type EditCookingItemUpdateCookingItemAndDeleteRoleMutationHookResult = ReturnType<typeof useEditCookingItemUpdateCookingItemAndDeleteRoleMutation>;
export type EditCookingItemUpdateCookingItemAndDeleteRoleMutationResult = Apollo.MutationResult<EditCookingItemUpdateCookingItemAndDeleteRoleMutation>;
export type EditCookingItemUpdateCookingItemAndDeleteRoleMutationOptions = Apollo.BaseMutationOptions<EditCookingItemUpdateCookingItemAndDeleteRoleMutation, EditCookingItemUpdateCookingItemAndDeleteRoleMutationVariables>;