import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { EmailField } from "pages/EditServiceAdmin/EditServiceAdminForm/EmailField";
import { useEditServiceAdminForm } from "pages/EditServiceAdmin/EditServiceAdminForm/useEditServiceAdminForm";
import { ServiceAdmin } from "pages/EditServiceAdmin/types";

type Props = {
  serviceAdmin: ServiceAdmin;
  onClose: () => void;
  loading: boolean;
};

export const EditServiceAdminForm = memo<Props>(({ serviceAdmin, onClose, loading }) => {
  const { form, initialValues } = useEditServiceAdminForm(serviceAdmin);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="serviceAdmin" form={form} initialValues={initialValues} layout="vertical">
          <EmailField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" loading={loading} disabled>
          更新
        </Button>
      </FormActions>
    </>
  );
});
