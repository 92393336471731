import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { TreeSelect } from "antd";

import { useShopPerCorporation } from "hooks/useShopPerCorporation";

const StyledTreeSelect = styled(TreeSelect<string>)`
  width: 256px;
`;

const DropdownWrapper = styled.div`
  .ant-select-tree .ant-select-tree-list .ant-select-tree-treenode {
    &.ant-select-tree-treenode-disabled {
      font-size: 12px;
      padding-left: 4px;
    }
    .ant-select-tree-switcher {
      display: none;
    }
    .ant-select-tree-indent .ant-select-tree-indent-unit {
      width: 16px;
    }
  }
`;

export type Company = {
  id: string;
  name: string;
  shops: {
    shopId: string;
    name: string;
  }[];
};

type Props = {
  companies: Company[];
  loading: boolean;
};

export const ShopMetricsShopSelector = memo<Props>(({ companies, loading }) => {
  const shopTreeData = useMemo(
    () =>
      (
        companies
          .filter(({ shops }) => shops.length > 0)
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      ).map((company) => ({
        key: company.id,
        value: company.id,
        title: company.name,
        selectable: false,
        disabled: true,
        children: company.shops
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((shop) => ({
            key: shop.shopId,
            value: shop.shopId,
            title: shop.name,
            selectable: true,
          })),
      })),
    [companies],
  );
  const { shop, setShop } = useShopPerCorporation();

  return (
    <StyledTreeSelect
      treeData={shopTreeData}
      treeDefaultExpandAll
      value={shop?.shopId}
      onChange={setShop}
      placeholder="店舗を選択してください"
      treeNodeFilterProp="title"
      loading={loading}
      showSearch
      dropdownRender={(option) => <DropdownWrapper>{option}</DropdownWrapper>}
    />
  );
});
