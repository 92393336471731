import React, { memo } from "react";
import { taxMethods } from "models/taxMethod";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { Select } from "components/Input/Select";
import { CouponTypeEnum } from "types/graphql";

export const TaxMethodField = memo(() => (
  <FormItem name={[CouponTypeEnum.MenuDiscount, "taxMethod"]} label="税率">
    <Select
      showSearch
      options={Object.entries(taxMethods).map(([taxMethod, taxMethodName]) => ({
        label: taxMethodName,
        value: taxMethod,
      }))}
      disabled
    />
  </FormItem>
));
