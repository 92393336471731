import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Maybe } from "types/graphql";

import {
  Plan,
  PlanChoice,
  PlanOption,
  TecAggregationMenu,
  TecAggregationPlanChoiceMenu,
} from "../types";

export type EditPlanTecAggregationPlanFormValues = Pick<
  TecAggregationMenu,
  "name" | "menuCode" | "dpCode" | "dpName" | "gpCode" | "gpName"
> & {
  name: string;
  plan?: Pick<Plan, "planId"> & {
    planOptions: Array<
      Pick<PlanOption, "name"> & {
        planChoices: Array<
          Pick<PlanChoice, "name" | "planChoiceId"> & {
            tecAggregationMenu?: Maybe<
              Pick<
                TecAggregationPlanChoiceMenu,
                "id" | "menuCode" | "name" | "gpCode" | "gpName" | "dpCode" | "dpName"
              >
            >;
          }
        >;
      }
    >;
  };
};

export const EditPlanTecAggregationPlanFormItem =
  createFormItem<EditPlanTecAggregationPlanFormValues>();

export const useEditPlanTecAggregationPlanForm = ({
  plan,
  tecAggregationMenu,
  onSubmit,
}: {
  plan?: Plan;
  tecAggregationMenu?: TecAggregationMenu;
  onSubmit: (args: EditPlanTecAggregationPlanFormValues) => void;
}) => {
  const [form] = Form.useForm<EditPlanTecAggregationPlanFormValues>();

  const initialValues = useMemo<EditPlanTecAggregationPlanFormValues>(
    () => ({
      name: tecAggregationMenu?.name ?? plan?.planName ?? "",
      menuCode: tecAggregationMenu?.menuCode ?? "",
      dpCode: tecAggregationMenu?.dpCode ?? "",
      dpName: tecAggregationMenu?.dpName ?? "",
      gpCode: tecAggregationMenu?.gpCode ?? "",
      gpName: tecAggregationMenu?.gpName ?? "",
      plan,
    }),
    [plan, tecAggregationMenu],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
