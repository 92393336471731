import dayjs from "dayjs";

import { extractNotOverlappedDuration } from "../../../../../models/orderableTime";
import { positionToEvent } from "../calcEventPosition";
import { HEIGHT_PER_HOUR } from "../constants";
import { WeeklyEvent } from "../types";

import { DragStateWithDayWeek } from ".";
/**
 * ドラッグ中の新規イベントが既存のイベントに重ならないように制限する
 */
export const avoidExistedEvents = ({
  event,
  existedEvents,
  changeDateTime,
}: {
  event: WeeklyEvent;
  existedEvents: WeeklyEvent[];
  changeDateTime: dayjs.Dayjs;
}): WeeklyEvent => {
  const existedEventsOnTheDayWeek = existedEvents.filter(
    (existedEvent) => existedEvent.dayWeek === event.dayWeek,
  );

  const avoidedEvent = existedEventsOnTheDayWeek.reduce((extractedEvent, existedEvent) => {
    const notOverlappedDuration = extractNotOverlappedDuration({
      duration: extractedEvent,
      avoidDuration: existedEvent,
      changeDateTime,
    });
    return notOverlappedDuration
      ? {
          ...notOverlappedDuration,
          dayWeek: event.dayWeek,
        }
      : extractedEvent;
  }, event);

  return avoidedEvent;
};

export const makeEventFromDragState = (
  dragState: DragStateWithDayWeek,
  existedEvents: WeeklyEvent[],
  changeDateTime: dayjs.Dayjs,
) => {
  if (!dragState.dayWeek) {
    return undefined;
  }

  // 下から上にドラッグした場合にも上を start にする
  const absoluteDistance = Math.abs(dragState.distance);
  const normalizedStart =
    dragState.distance > 0 ? dragState.start : dragState.start - absoluteDistance;

  const event = positionToEvent(
    {
      top: normalizedStart,
      height: absoluteDistance,
    },
    changeDateTime,
    HEIGHT_PER_HOUR,
  );

  return avoidExistedEvents({
    event: {
      ...event,
      dayWeek: dragState.dayWeek,
    },
    existedEvents,
    changeDateTime,
  });
};
