import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PaymentReceiptRoleTablePrintingGetTables = gql`
    query paymentReceiptRoleTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
    tableId
    paymentReceiptRoleTables {
      roleId
      tableId: _tableId
    }
  }
  role(where: {shopId: {_eq: $shopId}, shouldPrintPaymentReceipt: {_eq: true}}) {
    id
    roleId
    name
  }
}
    `;
export const DeletePaymentReceiptRoleTablePrintingTables = gql`
    mutation DeletePaymentReceiptRoleTablePrintingTables($roleId: uuid!, $tableId: Int!) {
  delete_paymentReceiptRoleTable(
    where: {roleId: {_eq: $roleId}, _tableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export const InsertPaymentReceiptRoleTablePrintingTables = gql`
    mutation InsertPaymentReceiptRoleTablePrintingTables($input: paymentReceiptRoleTable_insert_input!) {
  insert_paymentReceiptRoleTable_one(object: $input) {
    __typename
  }
}
    `;
export type PaymentReceiptRoleTablePrintingGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type PaymentReceiptRoleTablePrintingGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'id' | 'name' | 'tableId'>
    & { paymentReceiptRoleTables: Array<(
      { __typename?: 'paymentReceiptRoleTable' }
      & Pick<Types.PaymentReceiptRoleTable, 'roleId'>
      & { tableId: Types.PaymentReceiptRoleTable['_tableId'] }
    )> }
  )>, role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'id' | 'roleId' | 'name'>
  )> }
);

export type DeletePaymentReceiptRoleTablePrintingTablesMutationVariables = Types.Exact<{
  roleId: Types.Scalars['uuid'];
  tableId: Types.Scalars['Int'];
}>;


export type DeletePaymentReceiptRoleTablePrintingTablesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_paymentReceiptRoleTable?: Types.Maybe<(
    { __typename?: 'paymentReceiptRoleTable_mutation_response' }
    & Pick<Types.PaymentReceiptRoleTableMutationResponse, 'affected_rows'>
  )> }
);

export type InsertPaymentReceiptRoleTablePrintingTablesMutationVariables = Types.Exact<{
  input: Types.PaymentReceiptRoleTableInsertInput;
}>;


export type InsertPaymentReceiptRoleTablePrintingTablesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_paymentReceiptRoleTable_one?: Types.Maybe<{ __typename: 'paymentReceiptRoleTable' }> }
);


export const PaymentReceiptRoleTablePrintingGetTablesDocument = gql`
    query paymentReceiptRoleTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
    tableId
    paymentReceiptRoleTables {
      roleId
      tableId: _tableId
    }
  }
  role(where: {shopId: {_eq: $shopId}, shouldPrintPaymentReceipt: {_eq: true}}) {
    id
    roleId
    name
  }
}
    `;

/**
 * __usePaymentReceiptRoleTablePrintingGetTablesQuery__
 *
 * To run a query within a React component, call `usePaymentReceiptRoleTablePrintingGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentReceiptRoleTablePrintingGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentReceiptRoleTablePrintingGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function usePaymentReceiptRoleTablePrintingGetTablesQuery(baseOptions: Apollo.QueryHookOptions<PaymentReceiptRoleTablePrintingGetTablesQuery, PaymentReceiptRoleTablePrintingGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentReceiptRoleTablePrintingGetTablesQuery, PaymentReceiptRoleTablePrintingGetTablesQueryVariables>(PaymentReceiptRoleTablePrintingGetTablesDocument, options);
      }
export function usePaymentReceiptRoleTablePrintingGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentReceiptRoleTablePrintingGetTablesQuery, PaymentReceiptRoleTablePrintingGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentReceiptRoleTablePrintingGetTablesQuery, PaymentReceiptRoleTablePrintingGetTablesQueryVariables>(PaymentReceiptRoleTablePrintingGetTablesDocument, options);
        }
export type PaymentReceiptRoleTablePrintingGetTablesQueryHookResult = ReturnType<typeof usePaymentReceiptRoleTablePrintingGetTablesQuery>;
export type PaymentReceiptRoleTablePrintingGetTablesLazyQueryHookResult = ReturnType<typeof usePaymentReceiptRoleTablePrintingGetTablesLazyQuery>;
export type PaymentReceiptRoleTablePrintingGetTablesQueryResult = Apollo.QueryResult<PaymentReceiptRoleTablePrintingGetTablesQuery, PaymentReceiptRoleTablePrintingGetTablesQueryVariables>;
export const DeletePaymentReceiptRoleTablePrintingTablesDocument = gql`
    mutation DeletePaymentReceiptRoleTablePrintingTables($roleId: uuid!, $tableId: Int!) {
  delete_paymentReceiptRoleTable(
    where: {roleId: {_eq: $roleId}, _tableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export type DeletePaymentReceiptRoleTablePrintingTablesMutationFn = Apollo.MutationFunction<DeletePaymentReceiptRoleTablePrintingTablesMutation, DeletePaymentReceiptRoleTablePrintingTablesMutationVariables>;

/**
 * __useDeletePaymentReceiptRoleTablePrintingTablesMutation__
 *
 * To run a mutation, you first call `useDeletePaymentReceiptRoleTablePrintingTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentReceiptRoleTablePrintingTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentReceiptRoleTablePrintingTablesMutation, { data, loading, error }] = useDeletePaymentReceiptRoleTablePrintingTablesMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useDeletePaymentReceiptRoleTablePrintingTablesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePaymentReceiptRoleTablePrintingTablesMutation, DeletePaymentReceiptRoleTablePrintingTablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePaymentReceiptRoleTablePrintingTablesMutation, DeletePaymentReceiptRoleTablePrintingTablesMutationVariables>(DeletePaymentReceiptRoleTablePrintingTablesDocument, options);
      }
export type DeletePaymentReceiptRoleTablePrintingTablesMutationHookResult = ReturnType<typeof useDeletePaymentReceiptRoleTablePrintingTablesMutation>;
export type DeletePaymentReceiptRoleTablePrintingTablesMutationResult = Apollo.MutationResult<DeletePaymentReceiptRoleTablePrintingTablesMutation>;
export type DeletePaymentReceiptRoleTablePrintingTablesMutationOptions = Apollo.BaseMutationOptions<DeletePaymentReceiptRoleTablePrintingTablesMutation, DeletePaymentReceiptRoleTablePrintingTablesMutationVariables>;
export const InsertPaymentReceiptRoleTablePrintingTablesDocument = gql`
    mutation InsertPaymentReceiptRoleTablePrintingTables($input: paymentReceiptRoleTable_insert_input!) {
  insert_paymentReceiptRoleTable_one(object: $input) {
    __typename
  }
}
    `;
export type InsertPaymentReceiptRoleTablePrintingTablesMutationFn = Apollo.MutationFunction<InsertPaymentReceiptRoleTablePrintingTablesMutation, InsertPaymentReceiptRoleTablePrintingTablesMutationVariables>;

/**
 * __useInsertPaymentReceiptRoleTablePrintingTablesMutation__
 *
 * To run a mutation, you first call `useInsertPaymentReceiptRoleTablePrintingTablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPaymentReceiptRoleTablePrintingTablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPaymentReceiptRoleTablePrintingTablesMutation, { data, loading, error }] = useInsertPaymentReceiptRoleTablePrintingTablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertPaymentReceiptRoleTablePrintingTablesMutation(baseOptions?: Apollo.MutationHookOptions<InsertPaymentReceiptRoleTablePrintingTablesMutation, InsertPaymentReceiptRoleTablePrintingTablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertPaymentReceiptRoleTablePrintingTablesMutation, InsertPaymentReceiptRoleTablePrintingTablesMutationVariables>(InsertPaymentReceiptRoleTablePrintingTablesDocument, options);
      }
export type InsertPaymentReceiptRoleTablePrintingTablesMutationHookResult = ReturnType<typeof useInsertPaymentReceiptRoleTablePrintingTablesMutation>;
export type InsertPaymentReceiptRoleTablePrintingTablesMutationResult = Apollo.MutationResult<InsertPaymentReceiptRoleTablePrintingTablesMutation>;
export type InsertPaymentReceiptRoleTablePrintingTablesMutationOptions = Apollo.BaseMutationOptions<InsertPaymentReceiptRoleTablePrintingTablesMutation, InsertPaymentReceiptRoleTablePrintingTablesMutationVariables>;