import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useCompany } from "hooks/useCompany";
import { defaultTecAggregationMediaMaps } from "pages/TecAggregationMediaMaps/const";
import { TecAggregationOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { EditTecAggregationOnSitePaymentDetailTypeForm } from "./EditDetailTypeForm";
import {
  useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery,
  useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery,
  useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation,
} from "./queries";

export const EditTecAggregationOnSitePaymentDetailType = () => {
  const { type = "" } = useParams<{ type: string }>();

  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const navigate = useNavigate();

  const {
    data: getDetailTypeData,
    loading: loadingGetDetailType,
    refetch: refetchDetailType,
    error: getDetailTypeError,
  } = useEditTecAggregationOnSitePaymentDetailTypeGetDetailTypeQuery(
    companyId ? { variables: { type, companyId } } : { skip: true },
  );

  const onSitePaymentDetailType = useMemo(
    () =>
      getDetailTypeData?.onSitePaymentDetailType[0]
        ? {
            ...getDetailTypeData.onSitePaymentDetailType[0],
            tecAggregationOnSitePaymentDetailType:
              getDetailTypeData.onSitePaymentDetailType[0]
                ?.tecAggregationOnSitePaymentDetailTypes[0],
          }
        : undefined,
    [getDetailTypeData?.onSitePaymentDetailType],
  );

  const {
    data: getMediaMapsData,
    loading: loadingGetMediaMaps,
    error: getMediaMapsError,
  } = useEditTecAggregationOnSitePaymentDetailTypeGetMediaMapsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const tecAggregationMediaMaps = useMemo(
    () => getMediaMapsData?.tecAggregationMediaMap ?? [],
    [getMediaMapsData?.tecAggregationMediaMap],
  );

  const mergedMediaMaps = useMemo(
    () =>
      defaultTecAggregationMediaMaps.map(({ aggregationNumber, mediaName }) => {
        const mediaMap = tecAggregationMediaMaps.find(
          (map) => map.aggregationNumber === aggregationNumber,
        );
        return {
          aggregationNumber,
          mediaName: mediaMap?.mediaName ?? mediaName,
        };
      }),
    [tecAggregationMediaMaps],
  );

  const [upsertTecAggregationOnSitePaymentDetailTypeMutation, { loading: submitting }] =
    useEditTecAggregationOnSitePaymentDetailTypeUpsertDetailTypeMutation();

  const onSubmit = useCallback(
    async ({
      detailType,
      aggregationNumber,
    }: {
      detailType: Omit<
        TecAggregationOnSitePaymentDetailTypeInsertInput,
        "companyId" | "onSitePaymentDetailTypeEntityType" | "onSitePaymentDetailTypeId"
      >;
      aggregationNumber: number;
    }) => {
      if (!companyId || !_companyId) return;
      if (!onSitePaymentDetailType) return;

      const id = onSitePaymentDetailType.tecAggregationOnSitePaymentDetailType?.id ?? uuidv4();
      const referenceMediaMap = tecAggregationMediaMaps.find(
        (map) => map.aggregationNumber === aggregationNumber,
      );
      const commonDto: TecAggregationOnSitePaymentDetailTypeInsertInput = {
        id,
        companyId,
        mediaCode: detailType.mediaCode,
        mediaName: detailType.mediaName,
        paymentType: detailType.paymentType,
        onSitePaymentDetailTypeEntityType: onSitePaymentDetailType.type,
        onSitePaymentDetailTypeId: onSitePaymentDetailType.id,
      };
      const dto = referenceMediaMap
        ? {
            ...commonDto,
            tecAggregationMediaMapId: referenceMediaMap.id,
          }
        : {
            ...commonDto,
            // NOTE: tecAggregationMediaMap レコードがない場合はデフォルトの値でレコードを作成する
            tecAggregationMediaMap: {
              data: {
                id: uuidv4(),
                companyId,
                aggregationNumber,
                mediaName:
                  defaultTecAggregationMediaMaps.find(
                    (map) => map.aggregationNumber === aggregationNumber,
                  )?.mediaName ?? "",
              },
            },
          };

      try {
        await upsertTecAggregationOnSitePaymentDetailTypeMutation({
          variables: { detailType: dto },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }
      await refetchDetailType();
    },
    [
      _companyId,
      companyId,
      onSitePaymentDetailType,
      tecAggregationMediaMaps,
      upsertTecAggregationOnSitePaymentDetailTypeMutation,
      refetchDetailType,
    ],
  );

  const loading = loadingGetDetailType || loadingGetMediaMaps || submitting;
  const shouldShowAlert = getDetailTypeError || getMediaMapsError;

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <DashboardLayout
      title={onSitePaymentDetailType?.label ?? ""}
      locationBreadcrumb={{ showShop: true, items: [{ name: "支払方法" }] }}
    >
      <PageHeader title={onSitePaymentDetailType?.label} onBack={goBack} />
      {loading && <Loading height={300} />}

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {onSitePaymentDetailType && (
        <EditTecAggregationOnSitePaymentDetailTypeForm
          onSitePaymentDetailType={onSitePaymentDetailType}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loading}
          mediaMaps={mergedMediaMaps}
        />
      )}
    </DashboardLayout>
  );
};
