import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanGroupsGetPlanGroups = gql`
    query PlanGroupsGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}, order_by: {planGroupId: asc}) {
    name
    planGroupId
  }
}
    `;
export type PlanGroupsGetPlanGroupsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PlanGroupsGetPlanGroupsQuery = (
  { __typename?: 'query_root' }
  & { planGroup: Array<(
    { __typename?: 'planGroup' }
    & Pick<Types.PlanGroup, 'name' | 'planGroupId'>
  )> }
);


export const PlanGroupsGetPlanGroupsDocument = gql`
    query PlanGroupsGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}, order_by: {planGroupId: asc}) {
    name
    planGroupId
  }
}
    `;

/**
 * __usePlanGroupsGetPlanGroupsQuery__
 *
 * To run a query within a React component, call `usePlanGroupsGetPlanGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanGroupsGetPlanGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanGroupsGetPlanGroupsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePlanGroupsGetPlanGroupsQuery(baseOptions: Apollo.QueryHookOptions<PlanGroupsGetPlanGroupsQuery, PlanGroupsGetPlanGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanGroupsGetPlanGroupsQuery, PlanGroupsGetPlanGroupsQueryVariables>(PlanGroupsGetPlanGroupsDocument, options);
      }
export function usePlanGroupsGetPlanGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanGroupsGetPlanGroupsQuery, PlanGroupsGetPlanGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanGroupsGetPlanGroupsQuery, PlanGroupsGetPlanGroupsQueryVariables>(PlanGroupsGetPlanGroupsDocument, options);
        }
export type PlanGroupsGetPlanGroupsQueryHookResult = ReturnType<typeof usePlanGroupsGetPlanGroupsQuery>;
export type PlanGroupsGetPlanGroupsLazyQueryHookResult = ReturnType<typeof usePlanGroupsGetPlanGroupsLazyQuery>;
export type PlanGroupsGetPlanGroupsQueryResult = Apollo.QueryResult<PlanGroupsGetPlanGroupsQuery, PlanGroupsGetPlanGroupsQueryVariables>;