import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useShop } from "hooks/useShop";
import {
  useAddMenuCookingItemModalGetCookingItemsQuery,
  useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation,
} from "pages/EditShopMenuOutput/AddMenuCookingItemModal/queries";
import { Menu, ShopMenu } from "pages/EditShopMenuOutput/types";

import { AddCookingItemFormValues } from "./AddCookingItemForm/useAddCookingItemForm";
import { AddCookingItemForm } from "./AddCookingItemForm";

type Props = {
  menu: Menu;
  shopMenu: ShopMenu;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddMenuCookingItemModal = memo<Props>(({ menu, shopMenu, goBack, onUpdated }) => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data: getCookingItemsData, error } = useAddMenuCookingItemModalGetCookingItemsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const cookingItems = useMemo(
    () => getCookingItemsData?.cookingItem ?? [],
    [getCookingItemsData?.cookingItem],
  );

  const [addMenuCookingItemsCookingItem, { loading: loadingInsertMenuCookingItemsCookingItem }] =
    useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation();

  const [formValues, setFormValues] = useState<AddCookingItemFormValues | null>(null);

  const onOk = useCallback(async () => {
    if (!shopId) return;

    if (formValues) {
      try {
        const { cookingItems: _cookingItemIds } = formValues;
        const shopMenuCookingItem = _cookingItemIds.map((_cookingItemId) => {
          const cookingItem = cookingItems.find(({ id }) => id === _cookingItemId);

          if (!cookingItem) throw new Error("cookingItem not found");

          return {
            shopId,
            cookingItemId: cookingItem.cookingItemId,
            _cookingItemId: cookingItem.id,
            menuId: menu.id,
            _menuId: menu.menuId,
          };
        });
        const { data: addMenuCookingItemsCookingItemData } = await addMenuCookingItemsCookingItem({
          variables: { shopMenuCookingItem },
        });
        const returning = addMenuCookingItemsCookingItemData?.insert_shopMenuCookingItem?.returning;

        if (returning?.length === 0) {
          throw new Error("");
        }

        onUpdated();

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
    }

    goBack();
  }, [
    shopId,
    formValues,
    goBack,
    addMenuCookingItemsCookingItem,
    onUpdated,
    cookingItems,
    menu.id,
    menu.menuId,
  ]);
  const onCancel = goBack;

  return (
    <Modal
      title="調理アイテム設定"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button
          key="add"
          type="primary"
          onClick={onOk}
          loading={loadingInsertMenuCookingItemsCookingItem}
        >
          追加
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddCookingItemForm
        shopMenu={shopMenu}
        cookingItems={cookingItems}
        onChange={setFormValues}
      />
    </Modal>
  );
});
