import { useCallback } from "react";
import * as uuid from "uuid";

import { createFormItem, Form } from "components/antd/Form";

type AddOnSitePaymentDiscountTypeFormValues = {
  label: string;
};

const getInitialValues = () => ({ label: "" });

export const AddOnSitePaymentDiscountTypeFormItem =
  createFormItem<AddOnSitePaymentDiscountTypeFormValues>();

export const useAddOnSitePaymentDiscountTypeForm = (
  onSubmit: (args: { label: string; type: string }) => void,
) => {
  const [form] = Form.useForm<AddOnSitePaymentDiscountTypeFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    onSubmit({ label: values.label, type: uuid.v4() });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
