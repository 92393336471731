import React, { memo, ReactNode } from "react";
import styled from "styled-components";

import { NoDataDisplay } from "components/QuestionnaireAnalytics/NoDataDisplay";
import { viewport } from "constants/viewport";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 16px 12px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 24px;
`;

type Props = {
  title?: ReactNode;
  className?: string;
  isEmpty?: boolean;
  children?: ReactNode;
};

export const BasePanel = memo<Props>(({ title, className, isEmpty = false, children }) => (
  <Wrapper className={className}>
    {title && <Title>{title}</Title>}
    {isEmpty ? <NoDataDisplay /> : children}
  </Wrapper>
));
