import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Col, Row, Select } from "antd";
import { blue } from "@ant-design/colors";
import { CloseOutlined } from "@ant-design/icons";

import { IsDealingField } from "../../EditShopMenusBulkFields/IsDealingField";
import { IsSoldOutField } from "../../EditShopMenusBulkFields/IsSoldOutField";
import { IsVisibleForCustomerField } from "../../EditShopMenusBulkFields/IsVisibleForCustomerField";
import { IsVisibleForStaffField } from "../../EditShopMenusBulkFields/IsVisibleForStaffField";
import type { BulkEditConditions, Shop } from "../../types";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  background-color: ${blue[0]};
  gap: 20px;
`;

const LeftColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-right: 6px;
`;

const SelectedCount = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-right: 4px;
`;

const CenterColumn = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.div`
  white-space: nowrap;
`;

type Props = {
  selectedCount: number;
  onCancel: () => void;
  bulkEditConditions: BulkEditConditions;
  setBulkEditConditions: (bulkEditConditions: BulkEditConditions) => void;
  shops: Shop[];
};

export const BulkUpdateBanner = memo<Props>(
  ({ selectedCount, onCancel, bulkEditConditions, setBulkEditConditions, shops }) => {
    const handleChangeIsDealing = useCallback(
      (value: boolean | null) => {
        setBulkEditConditions({
          ...bulkEditConditions,
          isDealing: value,
          ...(value !== false
            ? {}
            : {
                isVisibleForCustomer: null,
                isVisibleForStaff: null,
                isSoldOut: null,
              }),
        });
      },
      [bulkEditConditions, setBulkEditConditions],
    );

    const handleChangeShopIds = useCallback(
      (value: string[] | null) => {
        setBulkEditConditions({
          ...bulkEditConditions,
          shopIds: value,
        });
      },
      [bulkEditConditions, setBulkEditConditions],
    );

    const handleChangeIsVisibleForCustomer = useCallback(
      (value: boolean | null) => {
        setBulkEditConditions({
          ...bulkEditConditions,
          isVisibleForCustomer: value,
        });
      },
      [bulkEditConditions, setBulkEditConditions],
    );

    const handleChangeIsVisibleForStaff = useCallback(
      (value: boolean | null) => {
        setBulkEditConditions({
          ...bulkEditConditions,
          isVisibleForStaff: value,
        });
      },
      [bulkEditConditions, setBulkEditConditions],
    );

    const handleChangeIsSoldOut = useCallback(
      (value: boolean | null) => {
        setBulkEditConditions({
          ...bulkEditConditions,
          isSoldOut: value,
        });
      },
      [bulkEditConditions, setBulkEditConditions],
    );

    return (
      <Wrapper>
        <LeftColumn>
          <ClearButton role="button" tabIndex={0} onClick={onCancel}>
            <CloseOutlined />
          </ClearButton>

          <SelectedCount>{selectedCount}</SelectedCount>
          <span>件 選択中</span>
        </LeftColumn>

        <CenterColumn>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FieldContainer>
                <Label>変更対象店舗</Label>
                <Select<string[]>
                  mode="multiple"
                  showSearch
                  allowClear
                  placeholder="すべての店舗"
                  value={bulkEditConditions.shopIds ?? []}
                  onChange={handleChangeShopIds}
                  optionFilterProp="label"
                  options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
                  style={{ width: "100%" }}
                />
              </FieldContainer>
            </Col>
            <Col flex="1 0">
              <FieldContainer>
                <IsDealingField
                  value={bulkEditConditions.isDealing}
                  onChange={handleChangeIsDealing}
                />
                <Label>に変更</Label>
              </FieldContainer>
            </Col>
            <Col flex="1 0">
              <FieldContainer>
                <IsVisibleForCustomerField
                  value={bulkEditConditions.isVisibleForCustomer}
                  onChange={handleChangeIsVisibleForCustomer}
                  disabled={bulkEditConditions.isDealing === false}
                />
                <Label>に変更</Label>
              </FieldContainer>
            </Col>
            <Col flex="1 0">
              <FieldContainer>
                <IsVisibleForStaffField
                  value={bulkEditConditions.isVisibleForStaff}
                  onChange={handleChangeIsVisibleForStaff}
                  disabled={bulkEditConditions.isDealing === false}
                />
                <Label>に変更</Label>
              </FieldContainer>
            </Col>
            <Col flex="1 0">
              <FieldContainer>
                <IsSoldOutField
                  value={bulkEditConditions.isSoldOut}
                  onChange={handleChangeIsSoldOut}
                  disabled={bulkEditConditions.isDealing === false}
                />
                <Label>に変更</Label>
              </FieldContainer>
            </Col>
          </Row>
        </CenterColumn>
        <Link to="/shopMenu/edit/bulk/confirm" replace>
          <Button type="primary">確認して更新</Button>
        </Link>
      </Wrapper>
    );
  },
);
