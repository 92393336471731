import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditInflowSourceTagFormItem } from "../useEditInflowSourceTagForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DisplayNameField = memo<Props>((props) => (
  <EditInflowSourceTagFormItem
    label="媒体名"
    name="displayName"
    rules={[{ required: true, message: "媒体名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditInflowSourceTagFormItem>
));
