import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanGroupInsertPlanGroup = gql`
    mutation AddPlanGroupInsertPlanGroup($planGroups: [planGroup_insert_input!]!) {
  insert_planGroup(
    objects: $planGroups
    on_conflict: {constraint: idx_46627_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      planGroupId
    }
  }
}
    `;
export type AddPlanGroupInsertPlanGroupMutationVariables = Types.Exact<{
  planGroups: Array<Types.PlanGroupInsertInput> | Types.PlanGroupInsertInput;
}>;


export type AddPlanGroupInsertPlanGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planGroup?: Types.Maybe<(
    { __typename?: 'planGroup_mutation_response' }
    & Pick<Types.PlanGroupMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planGroup' }
      & Pick<Types.PlanGroup, 'planGroupId'>
    )> }
  )> }
);


export const AddPlanGroupInsertPlanGroupDocument = gql`
    mutation AddPlanGroupInsertPlanGroup($planGroups: [planGroup_insert_input!]!) {
  insert_planGroup(
    objects: $planGroups
    on_conflict: {constraint: idx_46627_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      planGroupId
    }
  }
}
    `;
export type AddPlanGroupInsertPlanGroupMutationFn = Apollo.MutationFunction<AddPlanGroupInsertPlanGroupMutation, AddPlanGroupInsertPlanGroupMutationVariables>;

/**
 * __useAddPlanGroupInsertPlanGroupMutation__
 *
 * To run a mutation, you first call `useAddPlanGroupInsertPlanGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanGroupInsertPlanGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanGroupInsertPlanGroupMutation, { data, loading, error }] = useAddPlanGroupInsertPlanGroupMutation({
 *   variables: {
 *      planGroups: // value for 'planGroups'
 *   },
 * });
 */
export function useAddPlanGroupInsertPlanGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanGroupInsertPlanGroupMutation, AddPlanGroupInsertPlanGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanGroupInsertPlanGroupMutation, AddPlanGroupInsertPlanGroupMutationVariables>(AddPlanGroupInsertPlanGroupDocument, options);
      }
export type AddPlanGroupInsertPlanGroupMutationHookResult = ReturnType<typeof useAddPlanGroupInsertPlanGroupMutation>;
export type AddPlanGroupInsertPlanGroupMutationResult = Apollo.MutationResult<AddPlanGroupInsertPlanGroupMutation>;
export type AddPlanGroupInsertPlanGroupMutationOptions = Apollo.BaseMutationOptions<AddPlanGroupInsertPlanGroupMutation, AddPlanGroupInsertPlanGroupMutationVariables>;