import React, { memo } from "react";
import { Select } from "antd";

import { EditConnectGameConfigFormItem } from "../useEditConnectGameConfigForm";

const { Option } = Select;

type Props = {
  coupons: { name: string; id: string }[];
};

export const PrizeCouponField = memo<Props>(({ coupons }) => (
  <EditConnectGameConfigFormItem
    label="クーポン"
    name="prizeCouponId"
    rules={[{ required: true, message: "クーポンを選択してください" }]}
  >
    <Select<string> placeholder="選択してください">
      {coupons.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  </EditConnectGameConfigFormItem>
));
