import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShop = gql`
    query AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
  }
}
    `;
export const AddShopExternalOnlineMenuInsertExternalOnlineMenuConfig = gql`
    mutation AddShopExternalOnlineMenuInsertExternalOnlineMenuConfig($input: externalOnlineMenuConfig_insert_input!) {
  insert_externalOnlineMenuConfig_one(object: $input) {
    __typename
  }
}
    `;
export const AddShopExternalOnlineMenuInsertExternalOnlineMenu = gql`
    mutation AddShopExternalOnlineMenuInsertExternalOnlineMenu($input: externalOnlineMenu_insert_input!) {
  insert_externalOnlineMenu_one(object: $input) {
    __typename
  }
}
    `;
export type AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'changeDateTime'>
  )>, externalOnlineMenuConfig: Array<(
    { __typename?: 'externalOnlineMenuConfig' }
    & Pick<Types.ExternalOnlineMenuConfig, 'id' | 'defaultMenuUrl'>
  )> }
);

export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationVariables = Types.Exact<{
  input: Types.ExternalOnlineMenuConfigInsertInput;
}>;


export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_externalOnlineMenuConfig_one?: Types.Maybe<{ __typename: 'externalOnlineMenuConfig' }> }
);

export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationVariables = Types.Exact<{
  input: Types.ExternalOnlineMenuInsertInput;
}>;


export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_externalOnlineMenu_one?: Types.Maybe<{ __typename: 'externalOnlineMenu' }> }
);


export const AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopDocument = gql`
    query AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    changeDateTime
  }
  externalOnlineMenuConfig(where: {shopId: {_eq: $shopId}}) {
    id
    defaultMenuUrl
  }
}
    `;

/**
 * __useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery__
 *
 * To run a query within a React component, call `useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery(baseOptions: Apollo.QueryHookOptions<AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery, AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery, AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables>(AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopDocument, options);
      }
export function useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery, AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery, AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables>(AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopDocument, options);
        }
export type AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryHookResult = ReturnType<typeof useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery>;
export type AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopLazyQueryHookResult = ReturnType<typeof useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopLazyQuery>;
export type AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryResult = Apollo.QueryResult<AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery, AddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQueryVariables>;
export const AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigDocument = gql`
    mutation AddShopExternalOnlineMenuInsertExternalOnlineMenuConfig($input: externalOnlineMenuConfig_insert_input!) {
  insert_externalOnlineMenuConfig_one(object: $input) {
    __typename
  }
}
    `;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationFn = Apollo.MutationFunction<AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationVariables>;

/**
 * __useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation__
 *
 * To run a mutation, you first call `useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation, { data, loading, error }] = useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation(baseOptions?: Apollo.MutationHookOptions<AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationVariables>(AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigDocument, options);
      }
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationHookResult = ReturnType<typeof useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation>;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationResult = Apollo.MutationResult<AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation>;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationOptions = Apollo.BaseMutationOptions<AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutationVariables>;
export const AddShopExternalOnlineMenuInsertExternalOnlineMenuDocument = gql`
    mutation AddShopExternalOnlineMenuInsertExternalOnlineMenu($input: externalOnlineMenu_insert_input!) {
  insert_externalOnlineMenu_one(object: $input) {
    __typename
  }
}
    `;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationFn = Apollo.MutationFunction<AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationVariables>;

/**
 * __useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation__
 *
 * To run a mutation, you first call `useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShopExternalOnlineMenuInsertExternalOnlineMenuMutation, { data, loading, error }] = useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation(baseOptions?: Apollo.MutationHookOptions<AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationVariables>(AddShopExternalOnlineMenuInsertExternalOnlineMenuDocument, options);
      }
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationHookResult = ReturnType<typeof useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation>;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationResult = Apollo.MutationResult<AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation>;
export type AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationOptions = Apollo.BaseMutationOptions<AddShopExternalOnlineMenuInsertExternalOnlineMenuMutation, AddShopExternalOnlineMenuInsertExternalOnlineMenuMutationVariables>;