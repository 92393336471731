import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditAccountingSlipImageFormItem } from "../useEditAccountingSlipImageForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>((props) => (
  <EditAccountingSlipImageFormItem
    label={<FormHelp label="コメント" help="画像の下にこのメッセージが印字されます。" />}
    name="description"
    {...props}
  >
    <TextArea rows={4} />
  </EditAccountingSlipImageFormItem>
));
