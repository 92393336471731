import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes = gql`
    query SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes($companyId: uuid!) {
  steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, onSitePaymentDetailType: {isArchived: {_eq: false}}}
  ) {
    id
    steraPaymentMethodType
    onSitePaymentDetailTypeEntityType
    onSitePaymentDetailType {
      id
      label
      type
    }
  }
}
    `;
export const SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypes = gql`
    query SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;
export const SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailType = gql`
    mutation SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailType($companyId: uuid!, $steraPaymentMethodType: steraPaymentMethodType_enum!, $objects: [steraOnSitePaymentDetailType_insert_input!]!) {
  delete_steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, _and: {steraPaymentMethodType: {_eq: $steraPaymentMethodType}}}
  ) {
    affected_rows
  }
  insert_steraOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export const SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailType = gql`
    mutation SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailType($companyId: uuid!, $steraPaymentMethodType: steraPaymentMethodType_enum!) {
  delete_steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, steraPaymentMethodType: {_eq: $steraPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { steraOnSitePaymentDetailType: Array<(
    { __typename?: 'steraOnSitePaymentDetailType' }
    & Pick<Types.SteraOnSitePaymentDetailType, 'id' | 'steraPaymentMethodType' | 'onSitePaymentDetailTypeEntityType'>
    & { onSitePaymentDetailType: (
      { __typename?: 'onSitePaymentDetailType' }
      & Pick<Types.OnSitePaymentDetailType, 'id' | 'label' | 'type'>
    ) }
  )> }
);

export type SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
  )> }
);

export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  steraPaymentMethodType: Types.SteraPaymentMethodTypeEnum;
  objects: Array<Types.SteraOnSitePaymentDetailTypeInsertInput> | Types.SteraOnSitePaymentDetailTypeInsertInput;
}>;


export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_steraOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'steraOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.SteraOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )>, insert_steraOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'steraOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.SteraOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);

export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  steraPaymentMethodType: Types.SteraPaymentMethodTypeEnum;
}>;


export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_steraOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'steraOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.SteraOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);


export const SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument = gql`
    query SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypes($companyId: uuid!) {
  steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, onSitePaymentDetailType: {isArchived: {_eq: false}}}
  ) {
    id
    steraPaymentMethodType
    onSitePaymentDetailTypeEntityType
    onSitePaymentDetailType {
      id
      label
      type
    }
  }
}
    `;

/**
 * __useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>(SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument, options);
      }
export function useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>(SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesDocument, options);
        }
export type SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery>;
export type SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesLazyQuery>;
export type SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQueryVariables>;
export const SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesDocument = gql`
    query SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;

/**
 * __useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables>(SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesDocument, options);
      }
export function useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables>(SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesDocument, options);
        }
export type SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery>;
export type SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesLazyQuery>;
export type SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery, SteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQueryVariables>;
export const SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeDocument = gql`
    mutation SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailType($companyId: uuid!, $steraPaymentMethodType: steraPaymentMethodType_enum!, $objects: [steraOnSitePaymentDetailType_insert_input!]!) {
  delete_steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, _and: {steraPaymentMethodType: {_eq: $steraPaymentMethodType}}}
  ) {
    affected_rows
  }
  insert_steraOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [steraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation, { data, loading, error }] = useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      steraPaymentMethodType: // value for 'steraPaymentMethodType'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationVariables>(SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeDocument, options);
      }
export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation>;
export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation>;
export type SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutationVariables>;
export const SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeDocument = gql`
    mutation SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailType($companyId: uuid!, $steraPaymentMethodType: steraPaymentMethodType_enum!) {
  delete_steraOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, steraPaymentMethodType: {_eq: $steraPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [steraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation, { data, loading, error }] = useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      steraPaymentMethodType: // value for 'steraPaymentMethodType'
 *   },
 * });
 */
export function useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationVariables>(SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeDocument, options);
      }
export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation>;
export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation>;
export type SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation, SteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutationVariables>;