import React, { memo } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { ExternalOnlineMenuConfig } from "../types";

import { UrlField } from "./UrlField";
import { useEditShopExternalOnlineMenuConfigForm } from "./useEditShopExternalOnlineMenuConfigForm";

type Props = {
  onSubmit: ({ id, defaultMenuUrl }: { id: string; defaultMenuUrl: string }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onCancel: () => void;
  externalOnlineMenuConfig: ExternalOnlineMenuConfig;
  shopId: string;
  loading: boolean;
};

export const EditShopExternalOnlineMenuConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, onCancel, shopId, externalOnlineMenuConfig, loading }) => {
    const { form, initialValues, submit } = useEditShopExternalOnlineMenuConfigForm(
      shopId,
      externalOnlineMenuConfig,
      onSubmit,
      onFormValidationError,
    );
    return (
      <>
        <FormContent>
          <Form
            name="externalOnlineMenuConfig"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <UrlField />
            <Spacer size={24} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
