import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type AddCookingItemFormValues = {
  name: string;
  roleId: number;
};

const getInitialValues = () => ({});

export const AddCookingItemFormItem = createFormItem<AddCookingItemFormValues>();

export const useAddCookingItemForm = (
  onSubmit: (
    // shopCookingItemRole: Omit<ShopCookingItemRoleInsertInput, "shopId">,
    formValues: AddCookingItemFormValues,
  ) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as AddCookingItemFormValues;
    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
