import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditLineReportingBotConfigGetLineReportingBotConfig = gql`
    query EditLineReportingBotConfigGetLineReportingBotConfig($lineReportingBotConfigId: uuid!) {
  lineReportingBotConfig_by_pk(id: $lineReportingBotConfigId) {
    id
    name
    isSuspended
    lineReportingBotConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shopId
    }
  }
}
    `;
export const EditLineReportingBotConfigGetShops = gql`
    query EditLineReportingBotConfigGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const EditLineReportingBotConfigUpdateLineReportingBotConfig = gql`
    mutation EditLineReportingBotConfigUpdateLineReportingBotConfig($lineReportingBotConfigId: uuid!, $corporationId: uuid!, $name: String!, $isSuspended: Boolean, $input: [lineReportingBotConfigShop_insert_input!]!) {
  update_lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, id: {_eq: $lineReportingBotConfigId}}
    _set: {name: $name, isSuspended: $isSuspended}
  ) {
    affected_rows
  }
  delete_lineReportingBotConfigShop(
    where: {lineReportingBotConfigId: {_eq: $lineReportingBotConfigId}}
  ) {
    affected_rows
  }
  insert_lineReportingBotConfigShop(objects: $input) {
    affected_rows
  }
}
    `;
export type EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables = Types.Exact<{
  lineReportingBotConfigId: Types.Scalars['uuid'];
}>;


export type EditLineReportingBotConfigGetLineReportingBotConfigQuery = (
  { __typename?: 'query_root' }
  & { lineReportingBotConfig_by_pk?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfig' }
    & Pick<Types.LineReportingBotConfig, 'id' | 'name' | 'isSuspended'>
    & { lineReportingBotConfigShops: Array<(
      { __typename?: 'lineReportingBotConfigShop' }
      & Pick<Types.LineReportingBotConfigShop, 'id' | 'shopId'>
    )> }
  )> }
);

export type EditLineReportingBotConfigGetShopsQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type EditLineReportingBotConfigGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables = Types.Exact<{
  lineReportingBotConfigId: Types.Scalars['uuid'];
  corporationId: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
  isSuspended?: Types.InputMaybe<Types.Scalars['Boolean']>;
  input: Array<Types.LineReportingBotConfigShopInsertInput> | Types.LineReportingBotConfigShopInsertInput;
}>;


export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_lineReportingBotConfig?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfig_mutation_response' }
    & Pick<Types.LineReportingBotConfigMutationResponse, 'affected_rows'>
  )>, delete_lineReportingBotConfigShop?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfigShop_mutation_response' }
    & Pick<Types.LineReportingBotConfigShopMutationResponse, 'affected_rows'>
  )>, insert_lineReportingBotConfigShop?: Types.Maybe<(
    { __typename?: 'lineReportingBotConfigShop_mutation_response' }
    & Pick<Types.LineReportingBotConfigShopMutationResponse, 'affected_rows'>
  )> }
);


export const EditLineReportingBotConfigGetLineReportingBotConfigDocument = gql`
    query EditLineReportingBotConfigGetLineReportingBotConfig($lineReportingBotConfigId: uuid!) {
  lineReportingBotConfig_by_pk(id: $lineReportingBotConfigId) {
    id
    name
    isSuspended
    lineReportingBotConfigShops(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      shopId
    }
  }
}
    `;

/**
 * __useEditLineReportingBotConfigGetLineReportingBotConfigQuery__
 *
 * To run a query within a React component, call `useEditLineReportingBotConfigGetLineReportingBotConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditLineReportingBotConfigGetLineReportingBotConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditLineReportingBotConfigGetLineReportingBotConfigQuery({
 *   variables: {
 *      lineReportingBotConfigId: // value for 'lineReportingBotConfigId'
 *   },
 * });
 */
export function useEditLineReportingBotConfigGetLineReportingBotConfigQuery(baseOptions: Apollo.QueryHookOptions<EditLineReportingBotConfigGetLineReportingBotConfigQuery, EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditLineReportingBotConfigGetLineReportingBotConfigQuery, EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables>(EditLineReportingBotConfigGetLineReportingBotConfigDocument, options);
      }
export function useEditLineReportingBotConfigGetLineReportingBotConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditLineReportingBotConfigGetLineReportingBotConfigQuery, EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditLineReportingBotConfigGetLineReportingBotConfigQuery, EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables>(EditLineReportingBotConfigGetLineReportingBotConfigDocument, options);
        }
export type EditLineReportingBotConfigGetLineReportingBotConfigQueryHookResult = ReturnType<typeof useEditLineReportingBotConfigGetLineReportingBotConfigQuery>;
export type EditLineReportingBotConfigGetLineReportingBotConfigLazyQueryHookResult = ReturnType<typeof useEditLineReportingBotConfigGetLineReportingBotConfigLazyQuery>;
export type EditLineReportingBotConfigGetLineReportingBotConfigQueryResult = Apollo.QueryResult<EditLineReportingBotConfigGetLineReportingBotConfigQuery, EditLineReportingBotConfigGetLineReportingBotConfigQueryVariables>;
export const EditLineReportingBotConfigGetShopsDocument = gql`
    query EditLineReportingBotConfigGetShops($corporationId: uuid!) {
  shop(
    where: {company: {corporationId: {_eq: $corporationId}, archivedAt: {_is_null: true}}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditLineReportingBotConfigGetShopsQuery__
 *
 * To run a query within a React component, call `useEditLineReportingBotConfigGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditLineReportingBotConfigGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditLineReportingBotConfigGetShopsQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useEditLineReportingBotConfigGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditLineReportingBotConfigGetShopsQuery, EditLineReportingBotConfigGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditLineReportingBotConfigGetShopsQuery, EditLineReportingBotConfigGetShopsQueryVariables>(EditLineReportingBotConfigGetShopsDocument, options);
      }
export function useEditLineReportingBotConfigGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditLineReportingBotConfigGetShopsQuery, EditLineReportingBotConfigGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditLineReportingBotConfigGetShopsQuery, EditLineReportingBotConfigGetShopsQueryVariables>(EditLineReportingBotConfigGetShopsDocument, options);
        }
export type EditLineReportingBotConfigGetShopsQueryHookResult = ReturnType<typeof useEditLineReportingBotConfigGetShopsQuery>;
export type EditLineReportingBotConfigGetShopsLazyQueryHookResult = ReturnType<typeof useEditLineReportingBotConfigGetShopsLazyQuery>;
export type EditLineReportingBotConfigGetShopsQueryResult = Apollo.QueryResult<EditLineReportingBotConfigGetShopsQuery, EditLineReportingBotConfigGetShopsQueryVariables>;
export const EditLineReportingBotConfigUpdateLineReportingBotConfigDocument = gql`
    mutation EditLineReportingBotConfigUpdateLineReportingBotConfig($lineReportingBotConfigId: uuid!, $corporationId: uuid!, $name: String!, $isSuspended: Boolean, $input: [lineReportingBotConfigShop_insert_input!]!) {
  update_lineReportingBotConfig(
    where: {corporationId: {_eq: $corporationId}, id: {_eq: $lineReportingBotConfigId}}
    _set: {name: $name, isSuspended: $isSuspended}
  ) {
    affected_rows
  }
  delete_lineReportingBotConfigShop(
    where: {lineReportingBotConfigId: {_eq: $lineReportingBotConfigId}}
  ) {
    affected_rows
  }
  insert_lineReportingBotConfigShop(objects: $input) {
    affected_rows
  }
}
    `;
export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutationFn = Apollo.MutationFunction<EditLineReportingBotConfigUpdateLineReportingBotConfigMutation, EditLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>;

/**
 * __useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation__
 *
 * To run a mutation, you first call `useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLineReportingBotConfigUpdateLineReportingBotConfigMutation, { data, loading, error }] = useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation({
 *   variables: {
 *      lineReportingBotConfigId: // value for 'lineReportingBotConfigId'
 *      corporationId: // value for 'corporationId'
 *      name: // value for 'name'
 *      isSuspended: // value for 'isSuspended'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditLineReportingBotConfigUpdateLineReportingBotConfigMutation, EditLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLineReportingBotConfigUpdateLineReportingBotConfigMutation, EditLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>(EditLineReportingBotConfigUpdateLineReportingBotConfigDocument, options);
      }
export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutationHookResult = ReturnType<typeof useEditLineReportingBotConfigUpdateLineReportingBotConfigMutation>;
export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutationResult = Apollo.MutationResult<EditLineReportingBotConfigUpdateLineReportingBotConfigMutation>;
export type EditLineReportingBotConfigUpdateLineReportingBotConfigMutationOptions = Apollo.BaseMutationOptions<EditLineReportingBotConfigUpdateLineReportingBotConfigMutation, EditLineReportingBotConfigUpdateLineReportingBotConfigMutationVariables>;