import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddTableInsertTable = gql`
    mutation AddTableInsertTable($tables: [table_insert_input!]!) {
  insert_table(
    objects: $tables
    on_conflict: {constraint: idx_46683_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      tableId
    }
  }
}
    `;
export const AddTableGetTableAreas = gql`
    query AddTableGetTableAreas($shopId: uuid!) {
  tableArea(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    name
  }
}
    `;
export const AddTableGetTableMaxPriority = gql`
    query AddTableGetTableMaxPriority($shopId: uuid!, $tableAreaId: uuid!) {
  table_aggregate(
    where: {shopId: {_eq: $shopId}, tableAreaId: {_eq: $tableAreaId}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export type AddTableInsertTableMutationVariables = Types.Exact<{
  tables: Array<Types.TableInsertInput> | Types.TableInsertInput;
}>;


export type AddTableInsertTableMutation = (
  { __typename?: 'mutation_root' }
  & { insert_table?: Types.Maybe<(
    { __typename?: 'table_mutation_response' }
    & Pick<Types.TableMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'table' }
      & Pick<Types.Table, 'tableId'>
    )> }
  )> }
);

export type AddTableGetTableAreasQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddTableGetTableAreasQuery = (
  { __typename?: 'query_root' }
  & { tableArea: Array<(
    { __typename?: 'tableArea' }
    & Pick<Types.TableArea, 'id' | 'name'>
  )> }
);

export type AddTableGetTableMaxPriorityQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  tableAreaId: Types.Scalars['uuid'];
}>;


export type AddTableGetTableMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { table_aggregate: (
    { __typename?: 'table_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'table_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'table_max_fields' }
        & Pick<Types.TableMaxFields, 'priority'>
      )> }
    )> }
  ) }
);


export const AddTableInsertTableDocument = gql`
    mutation AddTableInsertTable($tables: [table_insert_input!]!) {
  insert_table(
    objects: $tables
    on_conflict: {constraint: idx_46683_PRIMARY, update_columns: []}
  ) {
    affected_rows
    returning {
      tableId
    }
  }
}
    `;
export type AddTableInsertTableMutationFn = Apollo.MutationFunction<AddTableInsertTableMutation, AddTableInsertTableMutationVariables>;

/**
 * __useAddTableInsertTableMutation__
 *
 * To run a mutation, you first call `useAddTableInsertTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTableInsertTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTableInsertTableMutation, { data, loading, error }] = useAddTableInsertTableMutation({
 *   variables: {
 *      tables: // value for 'tables'
 *   },
 * });
 */
export function useAddTableInsertTableMutation(baseOptions?: Apollo.MutationHookOptions<AddTableInsertTableMutation, AddTableInsertTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTableInsertTableMutation, AddTableInsertTableMutationVariables>(AddTableInsertTableDocument, options);
      }
export type AddTableInsertTableMutationHookResult = ReturnType<typeof useAddTableInsertTableMutation>;
export type AddTableInsertTableMutationResult = Apollo.MutationResult<AddTableInsertTableMutation>;
export type AddTableInsertTableMutationOptions = Apollo.BaseMutationOptions<AddTableInsertTableMutation, AddTableInsertTableMutationVariables>;
export const AddTableGetTableAreasDocument = gql`
    query AddTableGetTableAreas($shopId: uuid!) {
  tableArea(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useAddTableGetTableAreasQuery__
 *
 * To run a query within a React component, call `useAddTableGetTableAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTableGetTableAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTableGetTableAreasQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddTableGetTableAreasQuery(baseOptions: Apollo.QueryHookOptions<AddTableGetTableAreasQuery, AddTableGetTableAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTableGetTableAreasQuery, AddTableGetTableAreasQueryVariables>(AddTableGetTableAreasDocument, options);
      }
export function useAddTableGetTableAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTableGetTableAreasQuery, AddTableGetTableAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTableGetTableAreasQuery, AddTableGetTableAreasQueryVariables>(AddTableGetTableAreasDocument, options);
        }
export type AddTableGetTableAreasQueryHookResult = ReturnType<typeof useAddTableGetTableAreasQuery>;
export type AddTableGetTableAreasLazyQueryHookResult = ReturnType<typeof useAddTableGetTableAreasLazyQuery>;
export type AddTableGetTableAreasQueryResult = Apollo.QueryResult<AddTableGetTableAreasQuery, AddTableGetTableAreasQueryVariables>;
export const AddTableGetTableMaxPriorityDocument = gql`
    query AddTableGetTableMaxPriority($shopId: uuid!, $tableAreaId: uuid!) {
  table_aggregate(
    where: {shopId: {_eq: $shopId}, tableAreaId: {_eq: $tableAreaId}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddTableGetTableMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddTableGetTableMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddTableGetTableMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTableGetTableMaxPriorityQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      tableAreaId: // value for 'tableAreaId'
 *   },
 * });
 */
export function useAddTableGetTableMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddTableGetTableMaxPriorityQuery, AddTableGetTableMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddTableGetTableMaxPriorityQuery, AddTableGetTableMaxPriorityQueryVariables>(AddTableGetTableMaxPriorityDocument, options);
      }
export function useAddTableGetTableMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddTableGetTableMaxPriorityQuery, AddTableGetTableMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddTableGetTableMaxPriorityQuery, AddTableGetTableMaxPriorityQueryVariables>(AddTableGetTableMaxPriorityDocument, options);
        }
export type AddTableGetTableMaxPriorityQueryHookResult = ReturnType<typeof useAddTableGetTableMaxPriorityQuery>;
export type AddTableGetTableMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddTableGetTableMaxPriorityLazyQuery>;
export type AddTableGetTableMaxPriorityQueryResult = Apollo.QueryResult<AddTableGetTableMaxPriorityQuery, AddTableGetTableMaxPriorityQueryVariables>;