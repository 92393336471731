import React, { memo } from "react";
import styled from "styled-components";
import { Progress } from "antd";
import { getRankViewPropertiesByScore } from "models/questionnaireAnalytics";

import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";
import { PrimaryScoreMetricsPanel } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory/PrimaryScoreMetricsPanel";
import { QuestionAnswerMetricsPanel } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory/QuestionAnswerMetricsPanel";
import { ScoreDistributionGraph } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/MetricsPerCategory/ScoreDistributionGraph";
import { QuestionnaireMetricsPerCategory } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory/types";

const MIN_HEIGHT = 700;

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 392px 502px;
  grid-template-rows: 240px 280px 280px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    gap: 8px;
    grid-template-columns: auto;
    grid-template-rows: repeat(240px, auto) repeat(4, auto);
  }
`;

type Props = {
  questionnaireMetricsPerCategory: QuestionnaireMetricsPerCategory | undefined;
  allTenantAverageScore: number;
  isLoading: boolean;
  isEmpty: boolean;
};

export const MetricsPerCategory = memo<Props>(
  ({ questionnaireMetricsPerCategory, allTenantAverageScore, isLoading, isEmpty }) => {
    const { isDesktop } = useViewport();

    if (!questionnaireMetricsPerCategory || isLoading) {
      return (
        <Wrapper>
          <Loading height={MIN_HEIGHT} />
        </Wrapper>
      );
    }

    const { scoreMetrics, scoreDistributionMetrics, questionAnswerMetrics } =
      questionnaireMetricsPerCategory;

    return (
      <Wrapper>
        {scoreMetrics && (
          <PrimaryScoreMetricsPanel
            title="スコア"
            shopAverageScore={scoreMetrics.shopAverageScore}
            corporationAverageScore={scoreMetrics.corporationAverageScore}
            allTenantAverageScore={allTenantAverageScore}
            isEmpty={isEmpty}
          >
            <Progress
              type="circle"
              width={isDesktop ? 136 : 100}
              percent={scoreMetrics.shopAverageScore.score}
              strokeColor={getRankViewPropertiesByScore(scoreMetrics.shopAverageScore.score).color}
              format={(score) =>
                score === undefined ? null : (
                  <img
                    src={getRankViewPropertiesByScore(score).iconSrc}
                    width={42}
                    height={42}
                    alt="icon"
                  />
                )
              }
            />
            {isDesktop && <Spacer size={8} />}
          </PrimaryScoreMetricsPanel>
        )}

        {scoreDistributionMetrics && (
          <ScoreDistributionGraph scoreDistributionMetrics={scoreDistributionMetrics} />
        )}

        {questionAnswerMetrics.map((answerMetrics, index) => (
          <QuestionAnswerMetricsPanel key={index} questionAnswerMetrics={answerMetrics} />
        ))}
      </Wrapper>
    );
  },
);
