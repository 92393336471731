import React, { memo, useMemo } from "react";
import { filterMenusByName } from "models/menu";

import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";

import { Category, Menu } from "../types";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import { CategoryMenuTable } from "./MenuTable";

type Props = {
  menus: Menu[];
  menuRecommendationCategoryId: number;
  disabledIds: number[];
  loading: boolean;
  categories: Category[];
  onChange: (menuIds: number[]) => void;
};

export const filterMenus = (menus: Menu[], { name, categoryIds }: FilterConditions) => {
  const filteredMenus = menus.filter(
    (menu) =>
      categoryIds === undefined ||
      menu.categoryMenus
        .flatMap(({ category }) => category)
        .some((category) => categoryIds.includes(category.categoryId)),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const AddCategoryMenuForm = memo<Props>(
  ({ menuRecommendationCategoryId, disabledIds, onChange, menus, loading, categories }) => {
    const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
      useFilterConditions<FilterConditions>({});
    const filteredMenus = useMemo(
      () => filterMenus(menus, filterConditions),
      [menus, filterConditions],
    );

    return (
      <>
        <MenuFilter
          categories={categories}
          hasFilterConditions={hasFilterConditions}
          filterConditions={filterConditions}
          updateFilterCondition={updateFilterCondition}
          clearFilterConditions={clearFilterConditions}
        />
        <Spacer size={24} />
        <CategoryMenuTable
          loading={loading}
          menus={filteredMenus}
          menuRecommendationCategoryId={menuRecommendationCategoryId}
          disabledIds={disabledIds}
          onChange={onChange}
        />
      </>
    );
  },
);
