import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ExcludeFromAggregationGetCloseCashRegisters = gql`
    query ExcludeFromAggregationGetCloseCashRegisters($shopId: uuid!, $from: timestamptz!, $to: timestamptz!) {
  closeCashRegister(
    where: {shopId: {_eq: $shopId}, createdAt: {_gt: $from, _lt: $to}}
    order_by: {createdAt: asc}
  ) {
    id
    amount
    createdAt
    excludedFromAggregation
    targetDate
  }
}
    `;
export const ExcludeFromAggregationUpdateExcludeFromAggregation = gql`
    mutation ExcludeFromAggregationUpdateExcludeFromAggregation($id: uuid!, $excludedFromAggregation: Boolean!) {
  update_closeCashRegister(
    where: {id: {_eq: $id}}
    _set: {excludedFromAggregation: $excludedFromAggregation}
  ) {
    affected_rows
    returning {
      id
      excludedFromAggregation
    }
  }
}
    `;
export type ExcludeFromAggregationGetCloseCashRegistersQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  from: Types.Scalars['timestamptz'];
  to: Types.Scalars['timestamptz'];
}>;


export type ExcludeFromAggregationGetCloseCashRegistersQuery = (
  { __typename?: 'query_root' }
  & { closeCashRegister: Array<(
    { __typename?: 'closeCashRegister' }
    & Pick<Types.CloseCashRegister, 'id' | 'amount' | 'createdAt' | 'excludedFromAggregation' | 'targetDate'>
  )> }
);

export type ExcludeFromAggregationUpdateExcludeFromAggregationMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  excludedFromAggregation: Types.Scalars['Boolean'];
}>;


export type ExcludeFromAggregationUpdateExcludeFromAggregationMutation = (
  { __typename?: 'mutation_root' }
  & { update_closeCashRegister?: Types.Maybe<(
    { __typename?: 'closeCashRegister_mutation_response' }
    & Pick<Types.CloseCashRegisterMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'closeCashRegister' }
      & Pick<Types.CloseCashRegister, 'id' | 'excludedFromAggregation'>
    )> }
  )> }
);


export const ExcludeFromAggregationGetCloseCashRegistersDocument = gql`
    query ExcludeFromAggregationGetCloseCashRegisters($shopId: uuid!, $from: timestamptz!, $to: timestamptz!) {
  closeCashRegister(
    where: {shopId: {_eq: $shopId}, createdAt: {_gt: $from, _lt: $to}}
    order_by: {createdAt: asc}
  ) {
    id
    amount
    createdAt
    excludedFromAggregation
    targetDate
  }
}
    `;

/**
 * __useExcludeFromAggregationGetCloseCashRegistersQuery__
 *
 * To run a query within a React component, call `useExcludeFromAggregationGetCloseCashRegistersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExcludeFromAggregationGetCloseCashRegistersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExcludeFromAggregationGetCloseCashRegistersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useExcludeFromAggregationGetCloseCashRegistersQuery(baseOptions: Apollo.QueryHookOptions<ExcludeFromAggregationGetCloseCashRegistersQuery, ExcludeFromAggregationGetCloseCashRegistersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExcludeFromAggregationGetCloseCashRegistersQuery, ExcludeFromAggregationGetCloseCashRegistersQueryVariables>(ExcludeFromAggregationGetCloseCashRegistersDocument, options);
      }
export function useExcludeFromAggregationGetCloseCashRegistersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExcludeFromAggregationGetCloseCashRegistersQuery, ExcludeFromAggregationGetCloseCashRegistersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExcludeFromAggregationGetCloseCashRegistersQuery, ExcludeFromAggregationGetCloseCashRegistersQueryVariables>(ExcludeFromAggregationGetCloseCashRegistersDocument, options);
        }
export type ExcludeFromAggregationGetCloseCashRegistersQueryHookResult = ReturnType<typeof useExcludeFromAggregationGetCloseCashRegistersQuery>;
export type ExcludeFromAggregationGetCloseCashRegistersLazyQueryHookResult = ReturnType<typeof useExcludeFromAggregationGetCloseCashRegistersLazyQuery>;
export type ExcludeFromAggregationGetCloseCashRegistersQueryResult = Apollo.QueryResult<ExcludeFromAggregationGetCloseCashRegistersQuery, ExcludeFromAggregationGetCloseCashRegistersQueryVariables>;
export const ExcludeFromAggregationUpdateExcludeFromAggregationDocument = gql`
    mutation ExcludeFromAggregationUpdateExcludeFromAggregation($id: uuid!, $excludedFromAggregation: Boolean!) {
  update_closeCashRegister(
    where: {id: {_eq: $id}}
    _set: {excludedFromAggregation: $excludedFromAggregation}
  ) {
    affected_rows
    returning {
      id
      excludedFromAggregation
    }
  }
}
    `;
export type ExcludeFromAggregationUpdateExcludeFromAggregationMutationFn = Apollo.MutationFunction<ExcludeFromAggregationUpdateExcludeFromAggregationMutation, ExcludeFromAggregationUpdateExcludeFromAggregationMutationVariables>;

/**
 * __useExcludeFromAggregationUpdateExcludeFromAggregationMutation__
 *
 * To run a mutation, you first call `useExcludeFromAggregationUpdateExcludeFromAggregationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeFromAggregationUpdateExcludeFromAggregationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeFromAggregationUpdateExcludeFromAggregationMutation, { data, loading, error }] = useExcludeFromAggregationUpdateExcludeFromAggregationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      excludedFromAggregation: // value for 'excludedFromAggregation'
 *   },
 * });
 */
export function useExcludeFromAggregationUpdateExcludeFromAggregationMutation(baseOptions?: Apollo.MutationHookOptions<ExcludeFromAggregationUpdateExcludeFromAggregationMutation, ExcludeFromAggregationUpdateExcludeFromAggregationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExcludeFromAggregationUpdateExcludeFromAggregationMutation, ExcludeFromAggregationUpdateExcludeFromAggregationMutationVariables>(ExcludeFromAggregationUpdateExcludeFromAggregationDocument, options);
      }
export type ExcludeFromAggregationUpdateExcludeFromAggregationMutationHookResult = ReturnType<typeof useExcludeFromAggregationUpdateExcludeFromAggregationMutation>;
export type ExcludeFromAggregationUpdateExcludeFromAggregationMutationResult = Apollo.MutationResult<ExcludeFromAggregationUpdateExcludeFromAggregationMutation>;
export type ExcludeFromAggregationUpdateExcludeFromAggregationMutationOptions = Apollo.BaseMutationOptions<ExcludeFromAggregationUpdateExcludeFromAggregationMutation, ExcludeFromAggregationUpdateExcludeFromAggregationMutationVariables>;