import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { insertWithRelation } from "util/type/insert-with-relation";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useAddMessageDeliveryGetShopsQuery } from "pages/AddMessageDelivery/queries";

import { AddConnectGameConfigFormValues } from "./AddConnectGameConfigForm/useAddConnectGameConfigForm";
import { AddConnectGameConfigForm } from "./AddConnectGameConfigForm";
import {
  useAddConnectGameConfigGetConnectGameConfigShopsQuery,
  useAddConnectGameConfigGetCouponsQuery,
  useAddConnectGameConfigInsertConnectGameConfigMutation,
} from "./queries";

export const AddConnectGameConfig = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getCouponsData,
    loading: loadingCouponsData,
    error: getCouponsError,
  } = useAddConnectGameConfigGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = useMemo(() => getCouponsData?.coupon ?? [], [getCouponsData]);

  const {
    data: getShopsData,
    loading: loadingShopsData,
    error: getShopsError,
  } = useAddMessageDeliveryGetShopsQuery(companyId ? { variables: { companyId } } : { skip: true });
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData]);

  const {
    data: getConnectGameConfigGetConnectGameConfigShopsData,
    loading: loadingConnectGameConfigGetConnectGameConfigShopsData,
    error: getConnectGameConfigGetConnectGameConfigShopsError,
  } = useAddConnectGameConfigGetConnectGameConfigShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const connectGameConfigShopsInCompany = useMemo(
    () =>
      getConnectGameConfigGetConnectGameConfigShopsData?.connectGameConfig.flatMap(
        ({ connectGameConfigShops }) => connectGameConfigShops,
      ) ?? [],
    [getConnectGameConfigGetConnectGameConfigShopsData?.connectGameConfig],
  );

  const [addConnectGameConfig] = useAddConnectGameConfigInsertConnectGameConfigMutation();

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({
      connectGameConfig: formValues,
    }: {
      connectGameConfig: AddConnectGameConfigFormValues;
    }) => {
      try {
        if (!companyId) return;

        const {
          enabled,
          startImageUrl,
          startTime,
          endTime,
          prizeCouponId,
          consolationCouponId: consolationCouponIdValue,
          shopIds,
          maxWinningPercentageType,
          selectedMaxWinningPercentage,
          enteredMaxWinningPercentage,
          consolationType,
        } = formValues;

        const consolationCouponId =
          consolationType === "coupon" ? consolationCouponIdValue ?? undefined : undefined;
        const maxWinningRate =
          (maxWinningPercentageType === "select"
            ? selectedMaxWinningPercentage ?? 0
            : maxWinningPercentageType === "custom"
            ? enteredMaxWinningPercentage ?? 0
            : 0) / 100;

        await addConnectGameConfig({
          variables: {
            connectGameConfig: insertWithRelation<["connectGameConfig", "connectGameConfigShops"]>({
              companyId,
              connectGameConfigShops: {
                data: shopIds.map((shopId) => ({ shopId })),
              },
              consolationCouponId,
              enabled,
              endTime: endTime.format("HH:mm:00"),
              maxWinningRate,
              prizeCouponId,
              startImageUrl,
              startTime: startTime.format("HH:mm:00"),
            }),
          },
        });

        message.success("作成しました");

        navigate("/connectGameConfig", { replace: true });
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [companyId, addConnectGameConfig, navigate],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const onImageFieldValidationError = useCallback((e: Error) => {
    message.error(e.message);
  }, []);

  const loading =
    loadingCouponsData ||
    loadingShopsData ||
    loadingConnectGameConfigGetConnectGameConfigShopsData ||
    submitting;
  const shouldShowAlert =
    getCouponsError || getShopsError || getConnectGameConfigGetConnectGameConfigShopsError;

  return (
    <DashboardLayout title="ミニゲームを設定">
      <PageHeader title="ミニゲームを設定" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddConnectGameConfigForm
        loading={loading}
        coupons={coupons}
        shops={shops}
        connectGameConfigShopsInCompany={connectGameConfigShopsInCompany}
        onFormValidationError={onFormValidationError}
        onImageFieldValidationError={onImageFieldValidationError}
        onSubmit={onSubmit}
        onCancel={goBack}
      />
    </DashboardLayout>
  );
};
