import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Choice, ChoiceInsertInput } from "types/graphql";

export type AddGiftChoiceFormValues = Pick<Choice, "price">;

const getInitialValues = () => ({});

export const AddGiftChoiceFormItem = createFormItem<AddGiftChoiceFormValues>();

export const useAddGiftChoiceForm = (
  onSubmit: (
    choice: Pick<ChoiceInsertInput, "name" | "receiptDisplayName" | "price" | "priority">,
  ) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const { price } = form.getFieldsValue() as AddGiftChoiceFormValues;
    onSubmit({
      name: `${price} 円`,
      receiptDisplayName: `${price} 円`,
      price,
      priority: price,
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
