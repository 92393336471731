import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditInflowSourceTagGetInflowSourceTag = gql`
    query EditInflowSourceTagGetInflowSourceTag($id: uuid!) {
  inflowSourceTag(where: {id: {_eq: $id}}, order_by: {id: asc}) {
    id
    displayName
    isWalkIn
  }
}
    `;
export const EditInflowSourceTagGetShops = gql`
    query EditInflowSourceTagGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopInflowSourceTags(
      where: {inflowSourceTag: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;
export const EditInflowSourceTagUpdateInflowSourceTag = gql`
    mutation EditInflowSourceTagUpdateInflowSourceTag($inflowSourceTagId: uuid!, $inflowSourceTag: inflowSourceTag_set_input!) {
  update_inflowSourceTag(
    _set: $inflowSourceTag
    where: {id: {_eq: $inflowSourceTagId}}
  ) {
    affected_rows
  }
}
    `;
export const EditInflowSourceTagUpdateShopInflowSourceTag = gql`
    mutation EditInflowSourceTagUpdateShopInflowSourceTag($shopInflowSourceTag: shopInflowSourceTag_insert_input!) {
  insert_shopInflowSourceTag_one(
    object: $shopInflowSourceTag
    on_conflict: {constraint: shopInflowSourceTag_shopId_inflowSourceTagId_key, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export const EditInflowSourceTagBulkUpdateInflowSourceTags = gql`
    mutation EditInflowSourceTagBulkUpdateInflowSourceTags($shopInflowSourceTags: [shopInflowSourceTag_insert_input!]!) {
  insert_shopInflowSourceTag(
    objects: $shopInflowSourceTags
    on_conflict: {constraint: shopInflowSourceTag_shopId_inflowSourceTagId_key, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditInflowSourceTagGetInflowSourceTagQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditInflowSourceTagGetInflowSourceTagQuery = (
  { __typename?: 'query_root' }
  & { inflowSourceTag: Array<(
    { __typename?: 'inflowSourceTag' }
    & Pick<Types.InflowSourceTag, 'id' | 'displayName' | 'isWalkIn'>
  )> }
);

export type EditInflowSourceTagGetShopsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditInflowSourceTagGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { shopInflowSourceTags: Array<(
      { __typename?: 'shopInflowSourceTag' }
      & Pick<Types.ShopInflowSourceTag, 'isDisplay'>
    )> }
  )> }
);

export type EditInflowSourceTagUpdateInflowSourceTagMutationVariables = Types.Exact<{
  inflowSourceTagId: Types.Scalars['uuid'];
  inflowSourceTag: Types.InflowSourceTagSetInput;
}>;


export type EditInflowSourceTagUpdateInflowSourceTagMutation = (
  { __typename?: 'mutation_root' }
  & { update_inflowSourceTag?: Types.Maybe<(
    { __typename?: 'inflowSourceTag_mutation_response' }
    & Pick<Types.InflowSourceTagMutationResponse, 'affected_rows'>
  )> }
);

export type EditInflowSourceTagUpdateShopInflowSourceTagMutationVariables = Types.Exact<{
  shopInflowSourceTag: Types.ShopInflowSourceTagInsertInput;
}>;


export type EditInflowSourceTagUpdateShopInflowSourceTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopInflowSourceTag_one?: Types.Maybe<(
    { __typename?: 'shopInflowSourceTag' }
    & Pick<Types.ShopInflowSourceTag, 'shopId'>
  )> }
);

export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutationVariables = Types.Exact<{
  shopInflowSourceTags: Array<Types.ShopInflowSourceTagInsertInput> | Types.ShopInflowSourceTagInsertInput;
}>;


export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopInflowSourceTag?: Types.Maybe<(
    { __typename?: 'shopInflowSourceTag_mutation_response' }
    & Pick<Types.ShopInflowSourceTagMutationResponse, 'affected_rows'>
  )> }
);


export const EditInflowSourceTagGetInflowSourceTagDocument = gql`
    query EditInflowSourceTagGetInflowSourceTag($id: uuid!) {
  inflowSourceTag(where: {id: {_eq: $id}}, order_by: {id: asc}) {
    id
    displayName
    isWalkIn
  }
}
    `;

/**
 * __useEditInflowSourceTagGetInflowSourceTagQuery__
 *
 * To run a query within a React component, call `useEditInflowSourceTagGetInflowSourceTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditInflowSourceTagGetInflowSourceTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditInflowSourceTagGetInflowSourceTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditInflowSourceTagGetInflowSourceTagQuery(baseOptions: Apollo.QueryHookOptions<EditInflowSourceTagGetInflowSourceTagQuery, EditInflowSourceTagGetInflowSourceTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditInflowSourceTagGetInflowSourceTagQuery, EditInflowSourceTagGetInflowSourceTagQueryVariables>(EditInflowSourceTagGetInflowSourceTagDocument, options);
      }
export function useEditInflowSourceTagGetInflowSourceTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditInflowSourceTagGetInflowSourceTagQuery, EditInflowSourceTagGetInflowSourceTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditInflowSourceTagGetInflowSourceTagQuery, EditInflowSourceTagGetInflowSourceTagQueryVariables>(EditInflowSourceTagGetInflowSourceTagDocument, options);
        }
export type EditInflowSourceTagGetInflowSourceTagQueryHookResult = ReturnType<typeof useEditInflowSourceTagGetInflowSourceTagQuery>;
export type EditInflowSourceTagGetInflowSourceTagLazyQueryHookResult = ReturnType<typeof useEditInflowSourceTagGetInflowSourceTagLazyQuery>;
export type EditInflowSourceTagGetInflowSourceTagQueryResult = Apollo.QueryResult<EditInflowSourceTagGetInflowSourceTagQuery, EditInflowSourceTagGetInflowSourceTagQueryVariables>;
export const EditInflowSourceTagGetShopsDocument = gql`
    query EditInflowSourceTagGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopInflowSourceTags(
      where: {inflowSourceTag: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;

/**
 * __useEditInflowSourceTagGetShopsQuery__
 *
 * To run a query within a React component, call `useEditInflowSourceTagGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditInflowSourceTagGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditInflowSourceTagGetShopsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditInflowSourceTagGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditInflowSourceTagGetShopsQuery, EditInflowSourceTagGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditInflowSourceTagGetShopsQuery, EditInflowSourceTagGetShopsQueryVariables>(EditInflowSourceTagGetShopsDocument, options);
      }
export function useEditInflowSourceTagGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditInflowSourceTagGetShopsQuery, EditInflowSourceTagGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditInflowSourceTagGetShopsQuery, EditInflowSourceTagGetShopsQueryVariables>(EditInflowSourceTagGetShopsDocument, options);
        }
export type EditInflowSourceTagGetShopsQueryHookResult = ReturnType<typeof useEditInflowSourceTagGetShopsQuery>;
export type EditInflowSourceTagGetShopsLazyQueryHookResult = ReturnType<typeof useEditInflowSourceTagGetShopsLazyQuery>;
export type EditInflowSourceTagGetShopsQueryResult = Apollo.QueryResult<EditInflowSourceTagGetShopsQuery, EditInflowSourceTagGetShopsQueryVariables>;
export const EditInflowSourceTagUpdateInflowSourceTagDocument = gql`
    mutation EditInflowSourceTagUpdateInflowSourceTag($inflowSourceTagId: uuid!, $inflowSourceTag: inflowSourceTag_set_input!) {
  update_inflowSourceTag(
    _set: $inflowSourceTag
    where: {id: {_eq: $inflowSourceTagId}}
  ) {
    affected_rows
  }
}
    `;
export type EditInflowSourceTagUpdateInflowSourceTagMutationFn = Apollo.MutationFunction<EditInflowSourceTagUpdateInflowSourceTagMutation, EditInflowSourceTagUpdateInflowSourceTagMutationVariables>;

/**
 * __useEditInflowSourceTagUpdateInflowSourceTagMutation__
 *
 * To run a mutation, you first call `useEditInflowSourceTagUpdateInflowSourceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInflowSourceTagUpdateInflowSourceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInflowSourceTagUpdateInflowSourceTagMutation, { data, loading, error }] = useEditInflowSourceTagUpdateInflowSourceTagMutation({
 *   variables: {
 *      inflowSourceTagId: // value for 'inflowSourceTagId'
 *      inflowSourceTag: // value for 'inflowSourceTag'
 *   },
 * });
 */
export function useEditInflowSourceTagUpdateInflowSourceTagMutation(baseOptions?: Apollo.MutationHookOptions<EditInflowSourceTagUpdateInflowSourceTagMutation, EditInflowSourceTagUpdateInflowSourceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInflowSourceTagUpdateInflowSourceTagMutation, EditInflowSourceTagUpdateInflowSourceTagMutationVariables>(EditInflowSourceTagUpdateInflowSourceTagDocument, options);
      }
export type EditInflowSourceTagUpdateInflowSourceTagMutationHookResult = ReturnType<typeof useEditInflowSourceTagUpdateInflowSourceTagMutation>;
export type EditInflowSourceTagUpdateInflowSourceTagMutationResult = Apollo.MutationResult<EditInflowSourceTagUpdateInflowSourceTagMutation>;
export type EditInflowSourceTagUpdateInflowSourceTagMutationOptions = Apollo.BaseMutationOptions<EditInflowSourceTagUpdateInflowSourceTagMutation, EditInflowSourceTagUpdateInflowSourceTagMutationVariables>;
export const EditInflowSourceTagUpdateShopInflowSourceTagDocument = gql`
    mutation EditInflowSourceTagUpdateShopInflowSourceTag($shopInflowSourceTag: shopInflowSourceTag_insert_input!) {
  insert_shopInflowSourceTag_one(
    object: $shopInflowSourceTag
    on_conflict: {constraint: shopInflowSourceTag_shopId_inflowSourceTagId_key, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export type EditInflowSourceTagUpdateShopInflowSourceTagMutationFn = Apollo.MutationFunction<EditInflowSourceTagUpdateShopInflowSourceTagMutation, EditInflowSourceTagUpdateShopInflowSourceTagMutationVariables>;

/**
 * __useEditInflowSourceTagUpdateShopInflowSourceTagMutation__
 *
 * To run a mutation, you first call `useEditInflowSourceTagUpdateShopInflowSourceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInflowSourceTagUpdateShopInflowSourceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInflowSourceTagUpdateShopInflowSourceTagMutation, { data, loading, error }] = useEditInflowSourceTagUpdateShopInflowSourceTagMutation({
 *   variables: {
 *      shopInflowSourceTag: // value for 'shopInflowSourceTag'
 *   },
 * });
 */
export function useEditInflowSourceTagUpdateShopInflowSourceTagMutation(baseOptions?: Apollo.MutationHookOptions<EditInflowSourceTagUpdateShopInflowSourceTagMutation, EditInflowSourceTagUpdateShopInflowSourceTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInflowSourceTagUpdateShopInflowSourceTagMutation, EditInflowSourceTagUpdateShopInflowSourceTagMutationVariables>(EditInflowSourceTagUpdateShopInflowSourceTagDocument, options);
      }
export type EditInflowSourceTagUpdateShopInflowSourceTagMutationHookResult = ReturnType<typeof useEditInflowSourceTagUpdateShopInflowSourceTagMutation>;
export type EditInflowSourceTagUpdateShopInflowSourceTagMutationResult = Apollo.MutationResult<EditInflowSourceTagUpdateShopInflowSourceTagMutation>;
export type EditInflowSourceTagUpdateShopInflowSourceTagMutationOptions = Apollo.BaseMutationOptions<EditInflowSourceTagUpdateShopInflowSourceTagMutation, EditInflowSourceTagUpdateShopInflowSourceTagMutationVariables>;
export const EditInflowSourceTagBulkUpdateInflowSourceTagsDocument = gql`
    mutation EditInflowSourceTagBulkUpdateInflowSourceTags($shopInflowSourceTags: [shopInflowSourceTag_insert_input!]!) {
  insert_shopInflowSourceTag(
    objects: $shopInflowSourceTags
    on_conflict: {constraint: shopInflowSourceTag_shopId_inflowSourceTagId_key, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutationFn = Apollo.MutationFunction<EditInflowSourceTagBulkUpdateInflowSourceTagsMutation, EditInflowSourceTagBulkUpdateInflowSourceTagsMutationVariables>;

/**
 * __useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation__
 *
 * To run a mutation, you first call `useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInflowSourceTagBulkUpdateInflowSourceTagsMutation, { data, loading, error }] = useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation({
 *   variables: {
 *      shopInflowSourceTags: // value for 'shopInflowSourceTags'
 *   },
 * });
 */
export function useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation(baseOptions?: Apollo.MutationHookOptions<EditInflowSourceTagBulkUpdateInflowSourceTagsMutation, EditInflowSourceTagBulkUpdateInflowSourceTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInflowSourceTagBulkUpdateInflowSourceTagsMutation, EditInflowSourceTagBulkUpdateInflowSourceTagsMutationVariables>(EditInflowSourceTagBulkUpdateInflowSourceTagsDocument, options);
      }
export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutationHookResult = ReturnType<typeof useEditInflowSourceTagBulkUpdateInflowSourceTagsMutation>;
export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutationResult = Apollo.MutationResult<EditInflowSourceTagBulkUpdateInflowSourceTagsMutation>;
export type EditInflowSourceTagBulkUpdateInflowSourceTagsMutationOptions = Apollo.BaseMutationOptions<EditInflowSourceTagBulkUpdateInflowSourceTagsMutation, EditInflowSourceTagBulkUpdateInflowSourceTagsMutationVariables>;