import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiOnSitePaymentDiscountTypeInsertInput } from "types/graphql";

import { OnSitePaymentDiscountType } from "../types";

import { PikaichiNewariCdField } from "./PikaichiNewariCdField";
import { PikaichiNewariKubunField } from "./PikaichiNewariKubunField";
import { PikaichiNewariNameField } from "./PikaichiNewariNameField";
import { useEditPikaichiOnSitePaymentDiscountTypeForm } from "./useEditPikaichiOnSitePaymentDiscountTypeForm";

type Props = {
  onSitePaymentDiscountType: OnSitePaymentDiscountType;
  onSubmit: (
    args: Omit<
      PikaichiOnSitePaymentDiscountTypeInsertInput,
      "companyId" | "onSitePaymentDiscountTypeKey" | "onSitePaymentDiscountTypeId"
    >,
  ) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPikaichiOnSitePaymentDiscountForm = memo<Props>(
  ({ onSitePaymentDiscountType, onClose, onSubmit, loading }) => {
    const pikaichiOnSitePaymentDiscountType =
      onSitePaymentDiscountType.pikaichiOnSitePaymentDiscountTypes[0];

    const { form, initialValues, submit } = useEditPikaichiOnSitePaymentDiscountTypeForm({
      pikaichiDiscountType: pikaichiOnSitePaymentDiscountType,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form
            name="pikaichiOnSitePaymentDiscountType"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <PikaichiNewariKubunField />
            <PikaichiNewariCdField />
            <PikaichiNewariNameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
