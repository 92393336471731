import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { AddLineOfficialAccountFormItem } from "../useAddLineOfficialAccountForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const LineIdField = memo<Props>((props) => (
  <AddLineOfficialAccountFormItem
    label="LINE ID"
    name="lineId"
    rules={[{ required: true, pattern: /\S{1,}/, message: "LINE IDを入力してください" }]}
    {...props}
  >
    <Input />
  </AddLineOfficialAccountFormItem>
));
