import React, { memo } from "react";
import styled from "styled-components";
import { Typography } from "antd";

import { grey } from "constants/colors";

const Wrapper = styled.div`
  padding: 24px 32px;
  background-color: ${grey[0]};
  border-radius: 8px;
`;

const Title = styled(Typography.Title)`
  &.ant-typography {
    margin-bottom: 24px;
  }
`;

type Props = {
  title?: string;
  className?: string;
  children: React.ReactNode;
};

export const FormSection = memo<Props>(({ title, className, children }) => (
  <Wrapper className={className}>
    {title && <Title level={4}>{title}</Title>}
    {children}
  </Wrapper>
));
