import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { useCompany } from "hooks/useCompany";
import { Category, CreateCategoryInputCategorySource } from "types/graphql";

export type AddCategoryFormValues = Pick<
  Category,
  "name" | "shopSideName" | "shopSideBackgroundColor"
> & {
  nameSources: LocaleValuePair[];
};

const getInitialValues = () => ({});

export const AddCategoryFormItem = createFormItem<AddCategoryFormValues>();

export const useAddCategoryForm = (
  onSubmit: (category: Omit<CreateCategoryInputCategorySource, "priority">) => void,
) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [form] = Form.useForm<AddCategoryFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(
    ({
      imageUrl,
      shopSideBackgroundColor,
    }: {
      imageUrl: string | null | undefined;
      shopSideBackgroundColor: string;
    }) => {
      if (!companyId) return;

      const { name, shopSideName, nameSources } = form.getFieldsValue();
      onSubmit({
        name,
        shopSideName,
        imageUrl,
        shopSideBackgroundColor,
        companyId,
        createTranslationSource: {
          nameSources: (nameSources ?? []).filter(({ value }) => value),
        },
      });
    },
    [companyId, form, onSubmit],
  );

  return { form, initialValues, submit };
};
