import { MenuTypeEnum } from "types/graphql";

export const menuTypes = {
  food: "フード",
  drink: "ドリンク",
  other: "その他",
} as const;

// メニュー作成などに使わないメニュータイプも含まれてるメニュータイプ
// 分析とか、データーを見る時のみに使う
export const readonlyMenuTypes: { [key: string]: string } = {
  food: "フード",
  drink: "ドリンク",
  faveYell: "推しエール",
  plan: "プラン",
  other: "その他",
} as const;

export const isMenuType = (menuTypeKey: string): menuTypeKey is MenuTypeEnum =>
  menuTypeKey in menuTypes;
