import { useMemo } from "react";
import { filterMenusByName } from "models/menu";

import { useFilterConditions } from "hooks/useFilterConditions";
import { FilterConditions } from "pages/EditMenusBulk/MenuFilter";
import {
  EditMenusBulkGetMenusQuery,
  useEditMenusBulkGetCategoriesQuery,
  useEditMenusBulkGetShopsQuery,
} from "pages/EditMenusBulk/queries";
import { CategoryMenu } from "pages/EditMenusBulk/types";

export const filterCategoryMenus = (
  categoryMenus: CategoryMenu[],
  { categoryIds, name, shopId, displayType }: FilterConditions,
) => {
  const filteredCategoryMenus = categoryMenus.filter(
    (cm) =>
      (categoryIds === undefined || categoryIds.includes(cm.categoryId)) &&
      (shopId === undefined || cm.menu.shopMenus.map(({ shop }) => shop.shopId).includes(shopId)) &&
      (displayType === undefined || cm.displayType === displayType),
  );

  return name
    ? filterMenusByName(
        filteredCategoryMenus.map((cm) => ({ ...cm, name: cm.menu.name })),
        name,
      )
    : filteredCategoryMenus;
};

type Props = {
  getMenusData: EditMenusBulkGetMenusQuery | undefined;
  companyId: string | undefined;
};

export const useCategoryFilter = ({ getMenusData, companyId }: Props) => {
  const categoryMenus = useMemo(
    () => getMenusData?.category.flatMap(({ categoryMenus }) => categoryMenus) ?? [],
    [getMenusData?.category],
  );

  const { data: getCategoriesData } = useEditMenusBulkGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const { data: getShopsData } = useEditMenusBulkGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredCategoryMenus = useMemo(
    () => filterCategoryMenus(categoryMenus, filterConditions),
    [categoryMenus, filterConditions],
  );

  return {
    shops,
    categories,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
    filteredCategoryMenus,
    categoryMenus,
  };
};
