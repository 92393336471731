import React, { memo } from "react";
import styled from "styled-components";

import { grey } from "constants/colors";

const Wrapper = styled.div`
  background: ${grey[0]};
  padding: 24px;
  border-radius: 8px;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const FormContent = memo<Props>(({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
));
