import React, { memo } from "react";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrNull } from "hooks/useFilterConditions";

type Props = {
  value: boolean | null;
  onChange: (value: boolean | null) => void;
  disabled?: boolean;
};

export const IsSoldOutField = memo<Props>(({ value, onChange, disabled }) => (
  <Select<string>
    showSearch
    allowClear
    placeholder="在庫設定"
    value={typeof value === "boolean" ? String(value) : null}
    onChange={(soldOut) => onChange(convertStringToBooleanOrNull(soldOut))}
    optionFilterProp="label"
    options={[
      { label: "在庫あり", value: "false" },
      { label: "在庫なし", value: "true" },
    ]}
    disabled={disabled}
  />
));
