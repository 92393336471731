import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditCouponFormItem } from "../useEditCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TitleField = memo<Props>((props) => (
  <EditCouponFormItem
    label="クーポン表示名"
    name="title"
    rules={[{ required: true, message: "表示名を入力してください" }]}
    {...props}
  >
    <Input />
  </EditCouponFormItem>
));
