import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

type AddInflowSourceTagFormValues = {
  displayName: string;
};

const getInitialValues = () => ({ displayName: "" });

export const AddInflowSourceTagFormItem = createFormItem<AddInflowSourceTagFormValues>();

export const useAddInflowSourceTagForm = (onSubmit: (args: { displayName: string }) => void) => {
  const [form] = Form.useForm<AddInflowSourceTagFormValues>();
  const initialValues = getInitialValues();

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    onSubmit({ displayName: values.displayName });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
