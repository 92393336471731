import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { FormInstance, Radio, RadioChangeEvent, Select, Space } from "antd";

import { Shop } from "pages/AddMessageDelivery/types";
import {
  EditMessageDeliveryFormItem,
  EditMessageDeliveryFormValues,
} from "pages/EditMessageDelivery/EditMessageDeliveryForm/useEditMessageDeliveryForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  form: FormInstance<EditMessageDeliveryFormValues>;
  shops: Shop[];
};

export const VisitedShopsFilter = memo<Props>(({ form, shops }) => {
  const [radioValue, setRadioValue] = useState<"allShops" | "selectShops">(
    (form.getFieldValue("visitedShopsFilterShopIds") ?? []).length === 0
      ? "allShops"
      : "selectShops",
  );

  const revalidate = useCallback(
    () => form.validateFields([["visitedShopsFilterShopIds"]]),
    [form],
  );

  const validator = useCallback(
    async (_, visitedShopsFilterShopIds) => {
      if (radioValue === "selectShops" && !visitedShopsFilterShopIds?.length) {
        throw new Error("店舗を選択してください");
      }
    },
    [radioValue],
  );

  const onChangeRadio = useCallback(
    (e: RadioChangeEvent) => {
      const value = e.target.value;

      if (value === "allShops") {
        form.setFieldsValue({ visitedShopsFilterShopIds: undefined });
        revalidate();
      }

      setRadioValue(value);
    },
    [form, revalidate],
  );

  return (
    <Content>
      <Radio.Group value={radioValue} onChange={onChangeRadio}>
        <Space direction="vertical">
          <Radio value="allShops">絞り込まない</Radio>
          <Radio value="selectShops">絞り込む</Radio>
        </Space>
      </Radio.Group>
      <EditMessageDeliveryFormItem name="visitedShopsFilterShopIds" rules={[() => ({ validator })]}>
        <Select
          mode="multiple"
          disabled={radioValue === "allShops"}
          placeholder="店舗を選択"
          optionFilterProp="label"
          options={shops.map(({ name, shopId }) => ({ label: name, value: shopId }))}
        />
      </EditMessageDeliveryFormItem>
    </Content>
  );
});
