import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanWinboardPlanFormItem } from "../useEditPlanWinboardPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditPlanWinboardPlanFormItem label="メニュー名称" name="name" {...props}>
    <Input />
  </EditPlanWinboardPlanFormItem>
));
