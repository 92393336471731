import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import {
  SupportedLocaleEnum,
  SupportedLocaleEnumType,
  UpsertTranslationsForMenuRecommendationMetaInput,
} from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

import { MenuRecommendation, Translation } from "../types";

export type EditMenuRecommendationTranslationsFormValues = Partial<{
  name: string;
  nameEn: string;
  nameKr: string;
  nameCn: string;
}>;

const getInitialValues = ({
  menuRecommendation,
  localeToNameTranslationMap,
}: {
  menuRecommendation: MenuRecommendation | undefined;
  localeToNameTranslationMap: Map<SupportedLocaleEnum, Translation>;
}): EditMenuRecommendationTranslationsFormValues => ({
  name: menuRecommendation?.name,
  nameEn: localeToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
  nameKr: localeToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
  nameCn: localeToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
});

export const EditMenuRecommendationTranslationsFormItem =
  createFormItem<EditMenuRecommendationTranslationsFormValues>();

export const useEditMenuRecommendationTranslationsForm = ({
  menuRecommendation,
  translations,
  onSubmit,
}: {
  menuRecommendation: MenuRecommendation | undefined;
  translations: Translation[];
  onSubmit: (inputs: UpsertTranslationsForMenuRecommendationMetaInput) => void;
}) => {
  const localeToNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Name)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const [form] = Form.useForm<EditMenuRecommendationTranslationsFormValues>();

  const initialValues = useMemo(
    () =>
      getInitialValues({
        menuRecommendation,
        localeToNameTranslationMap,
      }),
    [menuRecommendation, localeToNameTranslationMap],
  );

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue();

    const { nameEn, nameKr, nameCn } = formValues;

    const nameSources = [
      {
        value: nameEn ?? "",
        locale: SupportedLocaleEnumType.EnUs,
      },
      {
        value: nameKr ?? "",
        locale: SupportedLocaleEnumType.KoKr,
      },
      {
        value: nameCn ?? "",
        locale: SupportedLocaleEnumType.ZhCn,
      },
    ];

    menuRecommendation &&
      onSubmit({
        menuRecommendationMetaId: menuRecommendation.id,
        companyId: menuRecommendation.company.id,
        nameSources,
      });
  }, [form, menuRecommendation, onSubmit]);

  return { form, initialValues, submit };
};
