import React, { memo } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { Sales } from "pages/Sales/types";

type Props = {
  loading?: boolean;
  sales: Sales[];
};

export const SalesTable = memo<Props>(({ loading, sales }) => {
  const [pagination, setPagination] = usePagination();

  const formatter = new Intl.NumberFormat("ja");

  const columns = [
    {
      title: "区分",
      dataIndex: "dimension",
    },
    {
      title: "売上 (税込)",
      dataIndex: "taxIncludedAmount",
      align: "right",
      render(taxIncludedAmount: number) {
        return `${formatter.format(taxIncludedAmount)} 円`;
      },
    } as const,
    {
      title: "売上 (税抜)",
      dataIndex: "taxExcludedAmount",
      align: "right",
      render(taxExcludedAmount: number) {
        return `${formatter.format(taxExcludedAmount)} 円`;
      },
    } as const,
    {
      title: "原価 (税込)",
      dataIndex: "taxIncludedCostPrice",
      align: "right",
      render(taxIncludedCostPrice: number) {
        return `${formatter.format(taxIncludedCostPrice)} 円`;
      },
    } as const,
    {
      title: "原価 (税抜)",
      dataIndex: "taxExcludedCostPrice",
      align: "right",
      render(taxExcludedCostPrice: number) {
        return `${formatter.format(taxExcludedCostPrice)} 円`;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="dimension"
      columns={columns}
      dataSource={sales}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
