import React, { memo } from "react";
import { Switch } from "antd";

import { EditConnectGameConfigFormItem } from "../useEditConnectGameConfigForm";

export const EnabledField = memo(() => (
  <EditConnectGameConfigFormItem
    label="ミニゲームの利用"
    name="enabled"
    horizontal
    valuePropName="checked"
  >
    <Switch checkedChildren="利用する" unCheckedChildren="利用しない" />
  </EditConnectGameConfigFormItem>
));
