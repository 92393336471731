import React, { memo } from "react";
import styled from "styled-components";
import { Button, Select, Tooltip } from "antd";
import { SelectProps } from "antd/lib/select";
import { ClearOutlined } from "@ant-design/icons";

import { Category } from "pages/DishUpSlipGroupMenus/types";

const { Option } = Select;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const CategorySelect = memo<SelectProps<number>>((props) => <Select<number> {...props} />);

const StyledCategorySelect = styled(CategorySelect)`
  flex: 1;
  margin-right: 16px;
`;

type Props = {
  categories: Category[];
  categoryId: number | null;
  setCategoryId: (categoryId: number | null) => void;
};

export const MenuFilter = memo<Props>(({ categories, categoryId, setCategoryId }) => (
  <Wrapper>
    <StyledCategorySelect
      placeholder="カテゴリを選択してください"
      defaultValue={undefined}
      value={categoryId ?? undefined}
      onChange={setCategoryId}
    >
      {categories.map(({ categoryId, name, shopSideName }) => (
        <Option key={categoryId} value={categoryId}>
          {shopSideName || name}
        </Option>
      ))}
    </StyledCategorySelect>
    <Tooltip title="フィルター条件をクリア">
      <Button
        shape="circle"
        icon={<ClearOutlined />}
        disabled={categoryId === null}
        onClick={() => setCategoryId(null)}
      />
    </Tooltip>
  </Wrapper>
));
