import React, { memo, useCallback } from "react";
import { FormInstance, NamePath } from "rc-field-form/lib/interface";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

const COST_PRICE_FIELD = "costPrice";

const COST_TAX_RATE_FIELD = "costTaxRate";
const COST_TAX_RATE_FIELD_LABEL = "原価税率";

type Props = FormItemProps & {
  costPriceFieldName?: NamePath;
  getCostPriceFieldValue?: (form: FormInstance) => any;
  disabled?: boolean;
};

export const CostTaxRateWithCostPriceField = memo<Props>(
  ({
    children,
    costPriceFieldName = COST_PRICE_FIELD,
    getCostPriceFieldValue,
    disabled = false,
    rules = [],
    ...props
  }) => {
    const costTaxRateWithCostPrice = useCallback(
      (form: FormInstance) => {
        // NOTE: 原価が入力されていたら必須に
        const costPrice: number | undefined =
          getCostPriceFieldValue?.(form) ?? form.getFieldValue(costPriceFieldName);
        return {
          required: typeof costPrice === "number",
          message: "原価税率を入力してください",
        };
      },
      [costPriceFieldName, getCostPriceFieldValue],
    );

    return (
      <FormItem
        label={props.label ?? COST_TAX_RATE_FIELD_LABEL}
        name={props.name ?? COST_TAX_RATE_FIELD}
        rules={disabled ? [] : [...rules, costTaxRateWithCostPrice]}
        {...props}
      >
        {children}
      </FormItem>
    );
  },
);
