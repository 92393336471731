import React, { memo } from "react";
import { FormItemProps, Select } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { LineOfficialAccount } from "pages/EditLineChannelConfig/types";

import { EditLineChannelConfigFormItem } from "../useEditLineChannelConfigForm";

const { Option } = Select;

type Props = {
  lineOfficialAccounts: LineOfficialAccount[];
} & Omit<FormItemProps, "children" | "name">;

export const LineOfficialAccountField = memo<Props>(({ lineOfficialAccounts, ...props }) => (
  <EditLineChannelConfigFormItem
    label="LINE ID"
    name="lineOfficialAccountId"
    wrapperCol={{ span: 20 }}
    {...props}
  >
    <Select<string>
      placeholder="LINE IDを選択してください"
      showSearch
      allowClear
      notFoundContent={<Paragraph type="secondary">選択できるLINE IDが存在しません。</Paragraph>}
    >
      {lineOfficialAccounts.map(({ lineId, lineOfficialAccountId }) => (
        <Option key={lineOfficialAccountId} value={lineOfficialAccountId}>
          {lineId}
        </Option>
      ))}
    </Select>
  </EditLineChannelConfigFormItem>
));
