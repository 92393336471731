import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const OfflinePaymentDescriptionField = memo<Props>((props) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="会計時の備考"
        help="会計時にお客様の画面にて表示されるメッセージを設定できます"
      />
    }
    name="offlinePaymentDescription"
    {...props}
  >
    <Input />
  </EditShopFormItem>
));
