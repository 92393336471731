import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountType = gql`
    query EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountType($id: uuid!) {
  onSitePaymentDiscountType(where: {id: {_eq: $id}}, order_by: {id: asc}) {
    id
    type
    label
  }
}
    `;
export const EditOnSitePaymentDiscountTypeGetShops = gql`
    query EditOnSitePaymentDiscountTypeGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopDiscountTypes(
      where: {onSitePaymentDiscountType: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;
export const EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountType = gql`
    mutation EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountType($onSitePaymentDiscountTypeId: uuid!, $onSitePaymentDiscountType: onSitePaymentDiscountType_set_input!) {
  update_onSitePaymentDiscountType(
    _set: $onSitePaymentDiscountType
    where: {id: {_eq: $onSitePaymentDiscountTypeId}}
  ) {
    affected_rows
  }
}
    `;
export const EditOnSitePaymentDiscountTypeUpdateShopDiscountType = gql`
    mutation EditOnSitePaymentDiscountTypeUpdateShopDiscountType($shopDiscountType: shopDiscountType_insert_input!) {
  insert_shopDiscountType_one(
    object: $shopDiscountType
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export const EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypes = gql`
    mutation EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypes($shopDiscountTypes: [shopDiscountType_insert_input!]!) {
  insert_shopDiscountType(
    objects: $shopDiscountTypes
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'id' | 'type' | 'label'>
  )> }
);

export type EditOnSitePaymentDiscountTypeGetShopsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDiscountTypeGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { shopDiscountTypes: Array<(
      { __typename?: 'shopDiscountType' }
      & Pick<Types.ShopDiscountType, 'isDisplay'>
    )> }
  )> }
);

export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationVariables = Types.Exact<{
  onSitePaymentDiscountTypeId: Types.Scalars['uuid'];
  onSitePaymentDiscountType: Types.OnSitePaymentDiscountTypeSetInput;
}>;


export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_onSitePaymentDiscountType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDiscountType_mutation_response' }
    & Pick<Types.OnSitePaymentDiscountTypeMutationResponse, 'affected_rows'>
  )> }
);

export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationVariables = Types.Exact<{
  shopDiscountType: Types.ShopDiscountTypeInsertInput;
}>;


export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopDiscountType_one?: Types.Maybe<(
    { __typename?: 'shopDiscountType' }
    & Pick<Types.ShopDiscountType, 'shopId'>
  )> }
);

export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationVariables = Types.Exact<{
  shopDiscountTypes: Array<Types.ShopDiscountTypeInsertInput> | Types.ShopDiscountTypeInsertInput;
}>;


export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopDiscountType?: Types.Maybe<(
    { __typename?: 'shopDiscountType_mutation_response' }
    & Pick<Types.ShopDiscountTypeMutationResponse, 'affected_rows'>
  )> }
);


export const EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeDocument = gql`
    query EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountType($id: uuid!) {
  onSitePaymentDiscountType(where: {id: {_eq: $id}}, order_by: {id: asc}) {
    id
    type
    label
  }
}
    `;

/**
 * __useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery, EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery, EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables>(EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeDocument, options);
      }
export function useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery, EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery, EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables>(EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeDocument, options);
        }
export type EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery>;
export type EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeLazyQuery>;
export type EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryResult = Apollo.QueryResult<EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQuery, EditOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeQueryVariables>;
export const EditOnSitePaymentDiscountTypeGetShopsDocument = gql`
    query EditOnSitePaymentDiscountTypeGetShops($id: uuid!, $companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
    shopDiscountTypes(
      where: {onSitePaymentDiscountType: {id: {_eq: $id}}, isDisplay: {_eq: false}}
    ) {
      isDisplay
    }
  }
}
    `;

/**
 * __useEditOnSitePaymentDiscountTypeGetShopsQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDiscountTypeGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypeGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDiscountTypeGetShopsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypeGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDiscountTypeGetShopsQuery, EditOnSitePaymentDiscountTypeGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDiscountTypeGetShopsQuery, EditOnSitePaymentDiscountTypeGetShopsQueryVariables>(EditOnSitePaymentDiscountTypeGetShopsDocument, options);
      }
export function useEditOnSitePaymentDiscountTypeGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDiscountTypeGetShopsQuery, EditOnSitePaymentDiscountTypeGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDiscountTypeGetShopsQuery, EditOnSitePaymentDiscountTypeGetShopsQueryVariables>(EditOnSitePaymentDiscountTypeGetShopsDocument, options);
        }
export type EditOnSitePaymentDiscountTypeGetShopsQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeGetShopsQuery>;
export type EditOnSitePaymentDiscountTypeGetShopsLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeGetShopsLazyQuery>;
export type EditOnSitePaymentDiscountTypeGetShopsQueryResult = Apollo.QueryResult<EditOnSitePaymentDiscountTypeGetShopsQuery, EditOnSitePaymentDiscountTypeGetShopsQueryVariables>;
export const EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeDocument = gql`
    mutation EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountType($onSitePaymentDiscountTypeId: uuid!, $onSitePaymentDiscountType: onSitePaymentDiscountType_set_input!) {
  update_onSitePaymentDiscountType(
    _set: $onSitePaymentDiscountType
    where: {id: {_eq: $onSitePaymentDiscountTypeId}}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationFn = Apollo.MutationFunction<EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationVariables>;

/**
 * __useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation, { data, loading, error }] = useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation({
 *   variables: {
 *      onSitePaymentDiscountTypeId: // value for 'onSitePaymentDiscountTypeId'
 *      onSitePaymentDiscountType: // value for 'onSitePaymentDiscountType'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationVariables>(EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeDocument, options);
      }
export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation>;
export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationResult = Apollo.MutationResult<EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation>;
export type EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateOnSitePaymentDiscountTypeMutationVariables>;
export const EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeDocument = gql`
    mutation EditOnSitePaymentDiscountTypeUpdateShopDiscountType($shopDiscountType: shopDiscountType_insert_input!) {
  insert_shopDiscountType_one(
    object: $shopDiscountType
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    shopId
  }
}
    `;
export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationFn = Apollo.MutationFunction<EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationVariables>;

/**
 * __useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation, { data, loading, error }] = useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation({
 *   variables: {
 *      shopDiscountType: // value for 'shopDiscountType'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationVariables>(EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeDocument, options);
      }
export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation>;
export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationResult = Apollo.MutationResult<EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation>;
export type EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutation, EditOnSitePaymentDiscountTypeUpdateShopDiscountTypeMutationVariables>;
export const EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesDocument = gql`
    mutation EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypes($shopDiscountTypes: [shopDiscountType_insert_input!]!) {
  insert_shopDiscountType(
    objects: $shopDiscountTypes
    on_conflict: {constraint: shopDiscountType_pkey, update_columns: [isDisplay]}
  ) {
    affected_rows
  }
}
    `;
export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationFn = Apollo.MutationFunction<EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation, EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationVariables>;

/**
 * __useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation, { data, loading, error }] = useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation({
 *   variables: {
 *      shopDiscountTypes: // value for 'shopDiscountTypes'
 *   },
 * });
 */
export function useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation, EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation, EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationVariables>(EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesDocument, options);
      }
export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationHookResult = ReturnType<typeof useEditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation>;
export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationResult = Apollo.MutationResult<EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation>;
export type EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutation, EditOnSitePaymentDiscountTypeBulkUpdateShopDiscountTypesMutationVariables>;