import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { useCompany } from "hooks/useCompany";

import { AddMenuRecommendationFormValues } from "./AddMenuRecommendationForm/useAddMenuRecommendationForm";
import { AddMenuRecommendationForm } from "./AddMenuRecommendationForm";
import { useAddMenuRecommendationMetaMutation } from "./queries";

export const AddMenuRecommendation = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const navigate = useNavigate();
  const [addMenuRecommendationMetaMutation, { loading: loadingInsertMenuRecommendationMeta }] =
    useAddMenuRecommendationMetaMutation();

  const onSubmit = useCallback(
    async ({
      name,
      nameSources,
    }: AddMenuRecommendationFormValues & { nameSources: LocaleValuePair[] }) => {
      try {
        if (!companyId) return;

        const { data: addMenuRecommendationMetaData } = await addMenuRecommendationMetaMutation({
          variables: {
            menuRecommendationMetaInput: {
              companyId,
              name,
              createTranslationSource: {
                nameSources,
              },
            },
          },
        });

        const menuRecommendationId =
          addMenuRecommendationMetaData?.createMenuRecommendation.menuRecommendationMetaId;

        if (!menuRecommendationId) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/menuRecommendation/${menuRecommendationId}/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addMenuRecommendationMetaMutation, companyId, navigate],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="おすすめを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "おすすめ" }],
      }}
    >
      <PageHeader title="おすすめを新規作成" onBack={goBack} />
      <AddMenuRecommendationForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertMenuRecommendationMeta}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
