import React, { memo, useCallback } from "react";
import { updatePriorities } from "util/priority";

import { PriorityList } from "components/PriorityList";
import { PriorityListItem } from "components/PriorityList/PriorityListItem";
import { InflowSourceTag } from "pages/EditInflowSourceTagPriorities/types";
import { InflowSourceTagInsertInput } from "types/graphql";

type Props = {
  loading?: boolean;
  inflowSourceTags: InflowSourceTag[];
  onUpdate: (inflowSourceTags: InflowSourceTagInsertInput[]) => void;
};

export const EditInflowSourceTagPriorityList = memo<Props>(
  ({ loading, inflowSourceTags, onUpdate }) => {
    const handleMoveItem = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        if (dragIndex !== hoverIndex) {
          const inflowSourceTagsInput = inflowSourceTags.map((inflowSourceTag) => ({
            id: inflowSourceTag.id,
            companyId: inflowSourceTag.companyId,
            priority: inflowSourceTag.priority,
            displayName: inflowSourceTag.displayName,
          }));
          onUpdate(updatePriorities(inflowSourceTagsInput, dragIndex, hoverIndex));
        }
      },
      [inflowSourceTags, onUpdate],
    );

    return (
      <PriorityList<InflowSourceTag>
        itemLayout="horizontal"
        loading={loading}
        dataSource={inflowSourceTags}
        renderItem={({ id, displayName, priority }, index) => (
          <PriorityListItem
            id={id}
            name={displayName}
            priority={priority}
            index={index}
            onMove={handleMoveItem}
          />
        )}
      />
    );
  },
);
