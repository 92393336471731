import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PikaichiMenuInsertInput } from "types/graphql";

import { Plan } from "../types";

import { PikaichiBumonCdField } from "./PikaichiBumonCdField";
import { PikaichiBumonNameField } from "./PikaichiBumonNameField";
import { PikaichiCategoryCdField } from "./PikaichiCategoryCdField";
import { PikaichiCategoryNameField } from "./PikaichiCategoryNameField";
import { PikaichiMenuCdField } from "./PikaichiMenuCdField";
import { PikaichiMenuNameField } from "./PikaichiMenuNameField";
import { useEditPikaichiPlanForm } from "./useEditPikaichiPlanForm";

type Props = {
  plan: Plan;
  onSubmit: (args: Omit<PikaichiMenuInsertInput, "companyId">) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditPikaichiPlanForm = memo<Props>(({ plan, onClose, onSubmit, loading }) => {
  const pikaichiMenu = plan?.pikaichiMenuPlans?.[0]?.pikaichiMenu;

  const { form, initialValues, submit } = useEditPikaichiPlanForm({ plan, pikaichiMenu, onSubmit });

  return (
    <>
      <FormContent>
        <Form name="pikaichiMenu" form={form} initialValues={initialValues} layout="vertical">
          <PikaichiMenuCdField />
          <PikaichiMenuNameField />
          <PikaichiBumonCdField />
          <PikaichiBumonNameField />
          <PikaichiCategoryCdField />
          <PikaichiCategoryNameField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button type="primary" onClick={submit} loading={loading}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
