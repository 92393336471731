import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { EditMenuForm } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/EditMenuForm";
import {
  useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery,
  useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation,
} from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/queries";
import { KdDisplayTarget } from "pages/KitchenDisplayDisplayTargetMenus/types";
import { ShopMenuKdDisplayTargetInsertInput } from "types/graphql";

type Props = {
  kdDisplayTarget: KdDisplayTarget;
  goBack: () => void;
  onUpdated: () => void;
};

export const EditKitchenDisplayDisplayTargetMenuModal = memo<Props>(
  ({ kdDisplayTarget, goBack, onUpdated }) => {
    const { id } = useParams<{ id: string }>();
    const kdDisplayTargetId = String(id);

    const [company] = useCompany();
    const [shop] = useShop();

    const companyId = company?.id;
    const shopId = shop?.shopId;

    const { data: getMenusData, error } = useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery(
      companyId && shopId ? { variables: { companyId, shopId } } : { skip: true },
    );
    const categories = getMenusData?.category ?? [];

    const [addKdDisplayTargetMenus, { loading: loadingInsertKdDisplayTargetMenus }] =
      useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation();

    const [formValues, setFormValues] = useState<{ menuIds: number[] } | null>(null);
    const onOk = useCallback(async () => {
      if (!shopId) return;
      if (formValues) {
        try {
          const { menuIds } = formValues;
          const kdDisplayTargetMenus: ShopMenuKdDisplayTargetInsertInput[] = menuIds.map(
            (menuId) => {
              const menu = getMenusData?.category
                .flatMap((category) => category.categoryMenus)
                .map((categoryMenu) => categoryMenu.menu)
                .find((menu) => menu.menuId === menuId);

              if (!menu) throw new Error("menu not found");

              return {
                menuId: menu.id,
                _menuId: menu.menuId,
                kdDisplayTargetId,
                shopId,
              };
            },
          );
          const { errors } = await addKdDisplayTargetMenus({
            variables: { kdDisplayTargetMenus, kdDisplayTargetId },
          });
          if (errors && errors?.length > 0) {
            throw new Error("");
          }

          onUpdated();

          message.success("更新しました");
        } catch (err) {
          message.error("更新に失敗しました");
        }
      }

      goBack();
    }, [
      shopId,
      formValues,
      goBack,
      addKdDisplayTargetMenus,
      kdDisplayTargetId,
      onUpdated,
      getMenusData?.category,
    ]);
    const onCancel = goBack;

    return (
      <Modal
        title="メニュー"
        open
        width={900}
        onOk={goBack}
        onCancel={goBack}
        footer={[
          <Button
            key="add"
            type="primary"
            onClick={onOk}
            loading={loadingInsertKdDisplayTargetMenus}
            disabled={formValues === null}
          >
            更新
          </Button>,
          <Button key="cancel" onClick={onCancel}>
            キャンセル
          </Button>,
        ]}
      >
        {error && (
          <Alert
            message="通信に失敗しました"
            type="error"
            description="ネットワーク環境を確認してください"
          />
        )}
        <EditMenuForm
          kdDisplayTarget={kdDisplayTarget}
          categories={categories}
          onChange={setFormValues}
        />
      </Modal>
    );
  },
);
