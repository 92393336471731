import React, { useMemo } from "react";
import { Alert } from "antd";
import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { FormHelp } from "components/Form/FormHelp";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useShop } from "hooks/useShop";
import {
  FilterConditions,
  PlanMainOptionPrintingFilter,
} from "pages/PlanMainOptionPrintings/PlanMainOptionPrintingFilter";
import { PlanMainOptionPrintingTable } from "pages/PlanMainOptionPrintings/PlanMainOptionPrintingTable";
import {
  usePlanMainOptionPrintingsGetCategoriesQuery,
  usePlanMainOptionPrintingsGetPlanOptionsQuery,
  usePlanMainOptionPrintingsGetRolesQuery,
} from "pages/PlanMainOptionPrintings/queries";
import { PlanOption } from "pages/PlanMainOptionPrintings/types";

const isIncludedInRoleIds = (planOption: PlanOption, roleIds: number[]) => {
  const planOptionRoleIds = planOption.shopPlanOptionKitchenRoles.map(({ role }) => role?.roleId);
  return roleIds.some((categoryId) => planOptionRoleIds.includes(categoryId));
};

const filterPlanOptionByName = <T extends Pick<PlanOption, "name">>(
  planOptions: T[],
  name: string,
) => {
  const fuse = new Fuse(
    planOptions.map(({ name }) => ({ name: hiraganaToKatakana(name) })),
    { keys: ["name"] },
  );
  const indexes = fuse.search(hiraganaToKatakana(name)).map(({ refIndex }) => refIndex);

  return indexes.map((index) => planOptions[index]);
};

const filterPlanOptions = (
  planOptions: PlanOption[],
  { name, categoryIds, roleIds }: FilterConditions,
) => {
  const filteredPlanOptions = planOptions.filter(
    (planOption) =>
      (categoryIds === undefined || categoryIds.includes(planOption.plan.planCategoryId)) &&
      (roleIds === undefined || isIncludedInRoleIds(planOption, roleIds)),
  );

  return name
    ? filterPlanOptionByName(filteredPlanOptions, name).filter(isNotNullable)
    : filteredPlanOptions;
};

export const PlanMainOptionPrintings = () => {
  const [company] = useCompany();
  const [shop] = useShop();

  const companyId = company?.id;
  const shopId = shop?.shopId;

  const {
    data: getPlanOptionsData,
    loading: loadingPlanOptions,
    error: errorGetPlanOptionsData,
  } = usePlanMainOptionPrintingsGetPlanOptionsQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const planOptions = useMemo(() => getPlanOptionsData?.planOption ?? [], [getPlanOptionsData]);

  const { data: getCategoriesData, error: errorGetCategoriesData } =
    usePlanMainOptionPrintingsGetCategoriesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const categories = getCategoriesData?.category ?? [];

  const { data: getRolesData, error: errorGetRolesData } = usePlanMainOptionPrintingsGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredPlanOptions = useMemo(
    () => filterPlanOptions(planOptions, filterConditions),
    [planOptions, filterConditions],
  );

  const shouldShowAlert = errorGetPlanOptionsData || errorGetCategoriesData || errorGetRolesData;

  return (
    <DashboardLayout title="プランオプション印刷設定一覧">
      <PageHeader
        title={
          <FormHelp
            label="プランオプション印刷設定一覧"
            help="プランオプションの設定にて印刷を有効にしたオプションだけが表示されています。"
          />
        }
        footer={
          <>
            <ShopSelector />
            <PlanMainOptionPrintingFilter
              categories={categories}
              roles={roles}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <PlanMainOptionPrintingTable
        shopId={shopId}
        planOptions={filteredPlanOptions}
        loading={loadingPlanOptions}
      />
    </DashboardLayout>
  );
};
