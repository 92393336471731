import React, { useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert, Descriptions } from "antd";
import { red } from "@ant-design/colors";
import { isNotNull } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useDinii } from "hooks/useDinii";

import { InputMasterDataFormValues } from "./InputMasterDataForm/useInputMasterDataForm";
import { InputMasterDataForm } from "./InputMasterDataForm";
import { useInputMasterDataGetCompanyQuery } from "./queries";

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const InputMasterData = () => {
  const [company] = useCompany();
  const [dinii, getContext] = useDinii();

  const [tecAggregationMenuCodesValidationError, setTecAggregationMenuCodesValidationError] =
    useState<{
      csvName: string;
      index: number;
      menuCode: string;
      dpCode?: string;
      dpName?: string;
      gpCode?: string;
      gpName?: string;
    } | null>(null);

  const { data } = useInputMasterDataGetCompanyQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );

  const [{ loading, error }, onSubmit] = useAsyncFn(
    async (args: InputMasterDataFormValues) => {
      setTecAggregationMenuCodesValidationError(null);

      if (!company) throw new Error("company is not available");

      const context = await getContext();

      if (!context) throw new Error("context is not available");

      const formData = new FormData();

      formData.append("companyId", String(company.companyId));
      formData.append("orderableTime", args.orderableTime);
      formData.append("category", args.category);
      formData.append("menu", args.menu);
      formData.append("option", args.option);
      formData.append("choice", args.choice);
      formData.append("planGroup", args.planGroup);
      formData.append("plan", args.plan);
      formData.append("planOption", args.planOption);
      formData.append("planChoice", args.planChoice);

      const { data } = await dinii.inputMasterData(context, formData);

      if (!data.result) {
        if (!data.menuCode || !data.csvName || data.index === undefined) throw new Error();

        setTecAggregationMenuCodesValidationError({
          csvName: data.csvName,
          index: data.index,
          menuCode: data.menuCode,
          dpCode: data.dpCode,
          dpName: data.dpName,
          gpCode: data.gpCode,
          gpName: data.gpName,
        });

        return;
      }

      await message.loading(
        "マスターデータ投入が完了しました。最新のデータを表示するためにブラウザをリロードします。",
      );

      window.location.reload();
    },
    [company, dinii, getContext],
  );

  return (
    <DashboardLayout title="マスターデータ投入">
      <PageHeader
        title="マスターデータ投入"
        description="マスターデータ投入はスプレッドシートに記載された法人のデータをデータベースに投入する作業のことです。"
      />
      {error && (
        <Alert
          type="error"
          message={(error as any)?.response?.data?.title}
          description={(error as any)?.response?.data?.message}
        />
      )}

      {tecAggregationMenuCodesValidationError && (
        <>
          <Alert
            message={`${tecAggregationMenuCodesValidationError.csvName} ${
              tecAggregationMenuCodesValidationError.index + 1
            }行目のメニューコード ${
              tecAggregationMenuCodesValidationError.menuCode
            } の設定が以下の設定と競合しています。`}
            description={
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    tecAggregationMenuCodesValidationError.dpCode
                      ? {
                          label: "部門コード",
                          children: tecAggregationMenuCodesValidationError.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    tecAggregationMenuCodesValidationError.dpName
                      ? {
                          label: "部門名称",
                          children: tecAggregationMenuCodesValidationError.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    tecAggregationMenuCodesValidationError.gpCode
                      ? {
                          label: "分類コード",
                          children: tecAggregationMenuCodesValidationError.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    tecAggregationMenuCodesValidationError.gpName
                      ? {
                          label: "分類名称",
                          children: tecAggregationMenuCodesValidationError.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            }
            type="error"
          />
          <Spacer size={10} />
        </>
      )}
      <InputMasterDataForm
        selectedCompany={data?.company?.[0]?.name}
        onSubmit={onSubmit}
        submitting={loading}
      />
    </DashboardLayout>
  );
};
