import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Coupon } from "../types";

import { CouponSelectField } from "./CouponSelectField";
import {
  AddQuestionnaireConfigFormValues,
  useAddQuestionnaireConfigForm,
} from "./useAddQuestionnaireConfigForm";

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

type Props = {
  loading: boolean;
  coupons: Coupon[];
  onSubmit: ({
    questionnaireConfig,
  }: {
    questionnaireConfig: AddQuestionnaireConfigFormValues;
  }) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddQuestionnaireConfigForm = memo<Props>(
  ({ loading, coupons, onSubmit, onClose, onFormValidationError }) => {
    const { form, initialValues, submit } = useAddQuestionnaireConfigForm({ onSubmit });
    const [uploadImage, setUploadImage] = useState("");

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, submit, onFormValidationError, uploadImage]);

    return (
      <>
        <FormContent>
          <Form name="questionnaire" form={form} initialValues={initialValues} layout="vertical">
            <ImageField
              image={uploadImage}
              setUploadImage={setUploadImage}
              uploadImageApiKey="questionnaire"
              formName="AddQuestionnaireConfigForm"
            />
            <CouponSelectField coupons={coupons} wrapperCol={{ span: 8 }} />
          </Form>
        </FormContent>
        <Spacer size={32} />
        <Footer>
          <Button onClick={onClose}>キャンセル</Button>
          <Button
            type="primary"
            onClick={loading ? undefined : handleSubmit}
            loading={loading}
            disabled={loading}
          >
            登録
          </Button>
        </Footer>
      </>
    );
  },
);
