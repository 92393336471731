import React, { memo, useCallback } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

export type Dimension = "month" | "week" | "dayOfWeek" | "day" | "hour";

export type GroupVariables = {
  dimension: Dimension;
};

type Props = {
  values: GroupVariables;
  onChange: (values: GroupVariables) => void;
};

export const CoineySalesGroup = memo<Props>(({ values, onChange }) => {
  const handleChange = useCallback(
    (e: RadioChangeEvent) => onChange({ ...values, dimension: e.target.value }),
    [values, onChange],
  );

  return (
    <Radio.Group value={values.dimension} onChange={handleChange}>
      <Radio.Button value="month">月別</Radio.Button>
      <Radio.Button value="week">週別</Radio.Button>
      <Radio.Button value="dayOfWeek">曜日別</Radio.Button>
      <Radio.Button value="day">日別</Radio.Button>
      <Radio.Button value="hour">時間別</Radio.Button>
    </Radio.Group>
  );
});
