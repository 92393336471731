import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";

import Notify from "assets/notify.svg";
import { colors } from "constants/colors";
import { logger } from "libs/logger";

const Wrapper = styled.div`
  background: ${colors.BackGround.Tertiary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

export const Forbidden = () => {
  useEffect(
    () => logger.warn("403: user opened forbidden page", { path: window.location.href }),
    [],
  );

  return (
    <Wrapper>
      <Notify width={480} height={480} />
      <p>このページへのアクセスは禁止されています。</p>

      <Link to="/">
        <Button type="primary">トップページに戻る</Button>
      </Link>
    </Wrapper>
  );
};
