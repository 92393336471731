import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditTargetDateModalUpdateCloseCashRegister = gql`
    mutation EditTargetDateModalUpdateCloseCashRegister($closeCashRegisterId: uuid!, $targetDate: date) {
  update_closeCashRegister(
    where: {id: {_eq: $closeCashRegisterId}}
    _set: {targetDate: $targetDate}
  ) {
    affected_rows
    returning {
      id
      targetDate
    }
  }
}
    `;
export type EditTargetDateModalUpdateCloseCashRegisterMutationVariables = Types.Exact<{
  closeCashRegisterId: Types.Scalars['uuid'];
  targetDate?: Types.InputMaybe<Types.Scalars['date']>;
}>;


export type EditTargetDateModalUpdateCloseCashRegisterMutation = (
  { __typename?: 'mutation_root' }
  & { update_closeCashRegister?: Types.Maybe<(
    { __typename?: 'closeCashRegister_mutation_response' }
    & Pick<Types.CloseCashRegisterMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'closeCashRegister' }
      & Pick<Types.CloseCashRegister, 'id' | 'targetDate'>
    )> }
  )> }
);


export const EditTargetDateModalUpdateCloseCashRegisterDocument = gql`
    mutation EditTargetDateModalUpdateCloseCashRegister($closeCashRegisterId: uuid!, $targetDate: date) {
  update_closeCashRegister(
    where: {id: {_eq: $closeCashRegisterId}}
    _set: {targetDate: $targetDate}
  ) {
    affected_rows
    returning {
      id
      targetDate
    }
  }
}
    `;
export type EditTargetDateModalUpdateCloseCashRegisterMutationFn = Apollo.MutationFunction<EditTargetDateModalUpdateCloseCashRegisterMutation, EditTargetDateModalUpdateCloseCashRegisterMutationVariables>;

/**
 * __useEditTargetDateModalUpdateCloseCashRegisterMutation__
 *
 * To run a mutation, you first call `useEditTargetDateModalUpdateCloseCashRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTargetDateModalUpdateCloseCashRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTargetDateModalUpdateCloseCashRegisterMutation, { data, loading, error }] = useEditTargetDateModalUpdateCloseCashRegisterMutation({
 *   variables: {
 *      closeCashRegisterId: // value for 'closeCashRegisterId'
 *      targetDate: // value for 'targetDate'
 *   },
 * });
 */
export function useEditTargetDateModalUpdateCloseCashRegisterMutation(baseOptions?: Apollo.MutationHookOptions<EditTargetDateModalUpdateCloseCashRegisterMutation, EditTargetDateModalUpdateCloseCashRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTargetDateModalUpdateCloseCashRegisterMutation, EditTargetDateModalUpdateCloseCashRegisterMutationVariables>(EditTargetDateModalUpdateCloseCashRegisterDocument, options);
      }
export type EditTargetDateModalUpdateCloseCashRegisterMutationHookResult = ReturnType<typeof useEditTargetDateModalUpdateCloseCashRegisterMutation>;
export type EditTargetDateModalUpdateCloseCashRegisterMutationResult = Apollo.MutationResult<EditTargetDateModalUpdateCloseCashRegisterMutation>;
export type EditTargetDateModalUpdateCloseCashRegisterMutationOptions = Apollo.BaseMutationOptions<EditTargetDateModalUpdateCloseCashRegisterMutation, EditTargetDateModalUpdateCloseCashRegisterMutationVariables>;