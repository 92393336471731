import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { getDayBeforeWorkDay } from "util/workDay";

import { useChangeDateTime } from "hooks/useChangeDateTime";
import { useLocationState } from "hooks/useLocationState";

export const useTargetDate = () => {
  const [{ targetDateString }, setTargetDateString] = useLocationState<{
    targetDateString: string | null;
  }>("targetDateString", { targetDateString: null });

  const targetDate = targetDateString ? dayjs(targetDateString) : undefined;

  const handleChangeDate = useCallback(
    (_: dayjs.Dayjs | null, targetDateString: string) => {
      setTargetDateString({ targetDateString });
    },
    [setTargetDateString],
  );

  const { changeDateTime } = useChangeDateTime();

  useEffect(() => {
    if (!changeDateTime || targetDate) return;

    const targetDateString = getDayBeforeWorkDay(changeDateTime).format("YYYY-MM-DD");
    setTargetDateString({ targetDateString });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDateTime]);

  return {
    targetDate,
    handleChangeDate,
  };
};
