import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Avatar, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { UserOutlined } from "@ant-design/icons";

import { FormItemProps } from "components/antd/Form";
import { colors } from "constants/colors";
import { getCroppedUrl } from "libs/imgix";
import { Customer } from "pages/AddMessageDelivery/types";

import { isNotNull } from "../../../../util/type/primitive";
import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormContent = styled.div`
  padding: 24px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 2px;
`;

const Profile = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

type Props = {
  onSearchCustomersByLikeName: ({ likeName }: { likeName: string }) => Promise<Customer[]>;
} & Omit<FormItemProps, "children" | "name">;

export const CustomerListField = memo<Props>(({ onSearchCustomersByLikeName, ...props }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  const handleSearch = useCallback(
    async (value: string) => {
      const res = await onSearchCustomersByLikeName({ likeName: value });

      setCustomers(res);
    },
    [onSearchCustomersByLikeName],
  );

  const options = useMemo<DefaultOptionType[]>(
    () =>
      customers
        .map(({ id, lineProfile }) => {
          if (lineProfile === null) return null;

          const url = getCroppedUrl({ url: lineProfile?.profileImage, w: 24, h: 24, frame: 0 });

          return {
            value: id,
            name: lineProfile?.name ?? "",
            label: (
              <Profile>
                <Avatar icon={<UserOutlined />} size={24} src={url} />
                {lineProfile?.name ?? ""}
              </Profile>
            ),
          };
        })
        .filter(isNotNull),
    [customers],
  );

  return (
    <FormWrapper>
      <FormContent>
        <AddMessageDeliveryFormItem
          label="LINEアカウント"
          name="customerIds"
          rules={[{ required: true, message: "アカウントを選択してください" }]}
          {...props}
        >
          <Select
            mode="multiple"
            optionFilterProp="name"
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={handleSearch}
            options={options}
          ></Select>
        </AddMessageDeliveryFormItem>
      </FormContent>
    </FormWrapper>
  );
});
