import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Descriptions, Modal } from "antd";
import { red } from "@ant-design/colors";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { EditChoiceTecAggregationMenuForm } from "./EditChoiceTecAggregationMenuForm";
import {
  useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery,
  useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery,
  useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation,
} from "./queries";

type Props = {
  onCompleted: () => void;
  onCloseModal: () => void;
};

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const EditChoiceModal = memo<Props>(({ onCloseModal, onCompleted }) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { choiceId: id } = useParams<{ choiceId: string }>();
  const _choiceId = Number(id);

  if (Number.isNaN(_choiceId)) {
    onCloseModal();
  }

  const [errorCodes, setErrorCodes] = useState<{
    name: string;
    menuCode: string;
    dpName?: string | null;
    dpCode?: string | null;
    gpCode?: string | null;
    gpName?: string | null;
  } | null>(null);

  const { data: getChoiceData, loading: loadingGetChoiceData } =
    useTecAggregationMenuOptionsEditChoiceModalGetChoiceQuery({
      variables: { choiceId: _choiceId },
    });
  const choice = getChoiceData?.choice?.[0];
  const choiceId = choice?.id;
  const tecAggregationMenu = choice?.tecAggregationMenu;

  const { data: getMenuCodesData, loading: loadingGetMenuCodes } =
    useTecAggregationMenuOptionsEditChoiceModalGetMenuCodeQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );

  const [updateTecAggregationMenuMutation, { loading: loadingUpdateTecAggregationMenu }] =
    useTecAggregationMenuOptionsUpdateTecAggregationMenuMutation();

  const onSubmit = useCallback(
    async (
      input: Pick<
        DashboardAccountUpsertTecAggregationMenuInput,
        "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
      >,
    ) => {
      if (!companyId) return;

      setErrorCodes(null);

      const id = tecAggregationMenu?.id ?? uuidv4();
      const tecAggregationMenuDto = {
        id,
        companyId,
        choiceId,
        _choiceId,
        ...input,
      };

      try {
        const result = await updateTecAggregationMenuMutation({
          variables: { input: tecAggregationMenuDto },
        });

        if (
          result.data?.upsertTecAggregationMenu.__typename ===
          "DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode"
        ) {
          const { name, menuCode, dpCode, dpName, gpCode, gpName } =
            result.data.upsertTecAggregationMenu;
          setErrorCodes({
            name,
            menuCode,
            dpCode,
            dpName,
            gpCode,
            gpName,
          });

          message.error("編集の保存に失敗しました");
          return;
        }

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }

      onCompleted();
    },
    [
      companyId,
      tecAggregationMenu?.id,
      choiceId,
      _choiceId,
      onCompleted,
      updateTecAggregationMenuMutation,
    ],
  );

  const adoptMenuCodeAutomatically = useCallback(() => {
    const menuCodes = (getMenuCodesData?.tecAggregationMenu ?? [])
      .map((c) => (!isNaN(Number(c.menuCode)) ? Number(c.menuCode) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? (Math.max(...menuCodes) + 1).toString() : "1";
  }, [getMenuCodesData?.tecAggregationMenu]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading = loadingGetChoiceData || loadingGetMenuCodes;

  return (
    <Modal title={choice?.name} open width={900} onCancel={onCloseModal} footer={null}>
      {loading && <Loading fullWidth height={300} />}
      {errorCodes && (
        <>
          <Alert
            message="以下のコード設定と競合しています"
            description={
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    {
                      label: "メニュー名",
                      children: errorCodes.name,
                      contentStyle: { backgroundColor: "white" },
                    },
                    {
                      label: "メニューコード",
                      children: errorCodes.menuCode,
                      contentStyle: { backgroundColor: "white" },
                    },
                    errorCodes.dpCode
                      ? {
                          label: "部門コード",
                          children: errorCodes.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.dpName
                      ? {
                          label: "部門名称",
                          children: errorCodes.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpCode
                      ? {
                          label: "分類コード",
                          children: errorCodes.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpName
                      ? {
                          label: "分類名称",
                          children: errorCodes.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            }
            type="error"
          />
          <Spacer size={10} />
        </>
      )}
      {choice && (
        <EditChoiceTecAggregationMenuForm
          choice={choice}
          onSubmit={onSubmit}
          onClose={onCloseModal}
          adoptMenuCodeAutomatically={adoptMenuCodeAutomatically}
          loading={loadingUpdateTecAggregationMenu}
          onFormValidationError={onFormValidationError}
        />
      )}
    </Modal>
  );
});
