import React, { memo } from "react";
import { Button } from "antd";

import { EditOptionWinboardOptionFormItem } from "../useEditOptionWinboardOptionForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const WinboardGetAvailableMenuCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed }) => (
    <EditOptionWinboardOptionFormItem
      name="winboardGetAvailableMenuCdButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleGetAvailableMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditOptionWinboardOptionFormItem>
  ),
);
