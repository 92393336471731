import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";

import { AddCompanyFormValues } from "./AddCompanyForm/useAddCompanyForm";
import { AddCompanyForm } from "./AddCompanyForm";
import { useAddCompanyGetCorporationsQuery, useAddCompanyInsertCompanyMutation } from "./queries";

export const AddCompany = () => {
  const [addCompanyMutation, { loading: loadingAddCompanyMutation }] =
    useAddCompanyInsertCompanyMutation();

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async ({ name, corporationId }: AddCompanyFormValues) => {
      try {
        await addCompanyMutation({
          variables: { input: { companyName: name, corporationId } },
        });
        message.success("作成しました");
        goBack();
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addCompanyMutation, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const { data: getCorporationsData, error } = useAddCompanyGetCorporationsQuery();
  const corporations = getCorporationsData?.corporation ?? [];

  return (
    <DashboardLayout
      title="業態を新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "業態一覧" }],
      }}
    >
      <PageHeader title="業態を新規作成" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddCompanyForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        loading={loadingAddCompanyMutation}
        corporations={corporations}
      />
    </DashboardLayout>
  );
};
