import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddOrderableTimeFormItem } from "../useAddOrderableTimeForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  existingOrderableTimeNames: string[];
};

export const NameField = memo<Props>((props) => (
  <AddOrderableTimeFormItem
    label="名前"
    name="name"
    rules={[
      { required: true, message: "アプリ表示時間の名前を入力してください" },
      {
        validator: async (_: unknown, value: string) => {
          if (props.existingOrderableTimeNames.includes(value)) {
            throw new Error(
              "すでに同じ名前のアプリ表示時間が存在します。別の名前を入力してください",
            );
          }
        },
      },
    ]}
    required
    {...props}
  >
    <Input />
  </AddOrderableTimeFormItem>
));
