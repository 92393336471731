import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddClerkFormItem } from "../useAddClerkForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DisplayField = memo<Props>((props) => (
  <AddClerkFormItem label="表示" name="isDisplay" valuePropName="checked" {...props}>
    <Switch />
  </AddClerkFormItem>
));
