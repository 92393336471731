import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Avatar, List } from "antd";
import { RankingData } from "models/customerDashboard";

import { colors, oldColors } from "constants/colors";

import { CustomerDashboardCard } from "../CustomerDashboardCard";

const ListWrapper = styled.div<Pick<Props, "small">>`
  width: ${({ small }) => (small ? "254" : "372")}px;
  margin-bottom: 12px;
`;

const ListItem = styled.li`
  height: 32px;
  border-bottom: 1px solid ${oldColors.Neutral100};
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-size: 13px;
  color: ${colors.Text.Default};
  margin-left: 16px;
  flex: 1;
  word-break: break-all;
`;

const DateText = styled.div`
  font-size: 10px;
  color: ${colors.Text.Secondary};
`;

const SmallNameWrapper = styled.div`
  margin-left: 16px;
  flex: 1;
`;

const SmallName = styled(Name)`
  height: 12px;
  font-size: 10px;
  margin-bottom: 2px;
  margin-left: 0px;
`;

const ValueText = styled.span`
  font-weight: bold;
  font-size: 13px;
  color: ${colors.Text.Secondary};
  margin-left: 20px;
  min-width: 40px;
  text-align: right;
`;

const BUTTON_COLOR = "#0091cf";

const ShowAllButton = styled.button`
  display: block;
  width: 80px;
  height: 17px;
  margin-left: auto;
  padding: 0;
  border: 1px solid ${BUTTON_COLOR};
  border-radius: 8px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  color: ${BUTTON_COLOR};
  background-color: transparent;
`;

type Props = {
  loading: boolean;
  title: string;
  caption?: string;
  data: RankingData;
  unit: string;
  rankingPagePath: string;
  small?: boolean;
};

export const CustomerRankingTop = memo<Props>(
  ({ title, caption, data, unit, loading, rankingPagePath, small }) => (
    <CustomerDashboardCard title={title} caption={caption}>
      <ListWrapper small={small}>
        <List
          loading={loading}
          dataSource={data}
          renderItem={(item) => (
            <ListItem>
              <Avatar size={18} src={item.lineProfileImage} alt={item.lineProfileName} />
              {small ? (
                <SmallNameWrapper>
                  <SmallName>{item.lineProfileName}</SmallName>
                  <DateText>{item.lastVisitedAt}</DateText>
                </SmallNameWrapper>
              ) : (
                <>
                  <Name>{item.lineProfileName}</Name>
                  <DateText>{item.lastVisitedAt}</DateText>
                </>
              )}

              <ValueText>
                {item.value}
                {unit}
              </ValueText>
            </ListItem>
          )}
        />
      </ListWrapper>

      {!loading && data.length !== 0 && (
        <Link to={rankingPagePath}>
          <ShowAllButton type="button">すべて見る &gt;</ShowAllButton>
        </Link>
      )}
    </CustomerDashboardCard>
  ),
);
