import React from "react";

import { ReportByType, SalesAnalyticsRow } from "hooks/useSalesAnalytics/types";

import { BusinessOperationHourTypeSalesAnalyticsCharts } from "./BusinessOperationHourTypeSalesAnalyticsChart";
import { DayOfWeekSalesAnalyticsChart } from "./DayOfWeekSalesAnalyticsChart";
import { DaySalesAnalyticsCharts } from "./DaySalesAnalyticsChart";
import { HourlySalesAnalyticsChart } from "./HourlySalesAnalyticsChart";
import { MonthSalesAnalyticsCharts } from "./MonthSalesAnalyticsChart";

type Props = {
  rows: SalesAnalyticsRow[];
  reportByType: ReportByType;
};

export const SalesAnalyticsCharts = ({ rows, reportByType }: Props) =>
  reportByType === ReportByType.day ? (
    <DaySalesAnalyticsCharts rows={rows} />
  ) : reportByType === ReportByType.month ? (
    <MonthSalesAnalyticsCharts rows={rows} />
  ) : reportByType === ReportByType.businessOperationHourType ? (
    <BusinessOperationHourTypeSalesAnalyticsCharts rows={rows} />
  ) : reportByType === ReportByType.dayOfWeek ? (
    <DayOfWeekSalesAnalyticsChart rows={rows} />
  ) : reportByType === ReportByType.hour ? (
    <HourlySalesAnalyticsChart rows={rows} />
  ) : (
    <></>
  );
