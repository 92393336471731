import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Popconfirm, Tag, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getTypeName } from "models/displayType";

import { CopyIcon } from "components/ColorIcon/CopyIcon";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useViewport } from "hooks/useViewport";
import { Plan } from "pages/Plans/types";

const { Paragraph } = Typography;

export type PlanTableDisplayType = "compact" | "detail";

type Props = {
  displayType: PlanTableDisplayType;
  loading?: boolean;
  plans: Plan[];
  onDelete: ({ planId }: { planId: string }) => void;
};

export const PlanTable = memo<Props>(({ displayType, loading, plans, onDelete }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const [pagination, setPagination] = usePagination();

  const { isDesktop } = useViewport();

  const compactColumns: ColumnsType<Plan> = [
    {
      title: "画像",
      align: "center",
      width: 100,
      render(_: string, { imageUrl }: Plan) {
        return <Thumbnail url={imageUrl} width={64} height={64} />;
      },
    },
    {
      title: "カテゴリ名",
      dataIndex: "category",
      width: 200,
      render(_: string, { category }: Plan) {
        return category.name;
      },
    },
    {
      title: "プラン名",
      width: 300,
      render(_: unknown, { planId, planName }: Plan) {
        return isFeatureEnabled("editPlan") ? (
          <Tooltip title="プランを編集">
            <Link to={`/plan/${planId}/edit`}>{planName}</Link>
          </Tooltip>
        ) : (
          <Link to={`/plan/${planId}/edit`}>{planName}</Link>
        );
      },
    },
    {
      title: "オプション",
      width: 150,
      render(_: string, { planOptions }: Plan) {
        return planOptions.map(({ name, planOptionId }) => <Tag key={planOptionId}>{name}</Tag>);
      },
    },
    {
      title: "プラン内カテゴリ",
      width: 200,
      render(_: unknown, { planMenuCategories }: Plan) {
        return planMenuCategories.map(({ category }) => (
          <Link key={category.categoryId} to={`/category/${category.categoryId}/edit`}>
            <Tag color="processing">{category.name}</Tag>
          </Link>
        ));
      },
    },
    {
      title: "制限時間",
      width: 200,
      dataIndex: "planTime",
      align: "right",
      render(_: string, { planTime }: Plan) {
        return typeof planTime === "number" ? `${planTime}分` : "無制限";
      },
    },
    {
      title: "アプリ表示時間",
      align: "center",
      width: 200,
      render(_: string, { orderableTime }: Plan) {
        if (!orderableTime) return "未設定";
        return <Tag>{orderableTime.name}</Tag>;
      },
    },
    {
      title: "画像の表示サイズ",
      align: "center",
      width: 200,
      render(_: unknown, { displayType }: Plan) {
        return <Tag>{getTypeName(displayType ?? "medium")}</Tag>;
      },
    },
    {
      title: "プラングループ",
      width: 150,
      align: "center",
      render(_: string, { planGroup }: Plan) {
        if (!planGroup) return "";
        return <Tag>{planGroup.name}</Tag>;
      },
    },
    ...(isFeatureEnabled("addPlan")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { planId }: Plan) {
              return (
                <Tooltip title="コピーして新規作成">
                  <IconLink to={`/plan/add?planId=${planId}`}>
                    <CopyIcon />
                  </IconLink>
                </Tooltip>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("deletePlan")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { id, shopPlans, totalDealingShopCount }: Plan) {
              const disabled = totalDealingShopCount !== shopPlans.length;

              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>プランを削除しますか？</Paragraph>
                      <Paragraph>一度削除したプランを元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDelete({ planId: id });
                  }}
                  disabled={disabled}
                >
                  <DeleteIcon disabled={disabled} />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  const detailColumns: ColumnsType<Plan> = [
    {
      title: "画像",
      align: "center",
      width: 100,
      fixed: isDesktop ? "left" : undefined,
      render(_: string, { imageUrl }: Plan) {
        return <Thumbnail url={imageUrl} width={64} height={64} />;
      },
    },
    {
      title: "カテゴリ名",
      dataIndex: "category",
      width: 150,
      fixed: isDesktop ? "left" : undefined,
      render(_: string, { category }: Plan) {
        return category.name;
      },
    },
    {
      title: "プラン名",
      width: 300,
      fixed: isDesktop ? "left" : undefined,
      render(_: unknown, { planId, planName }: Plan) {
        return isFeatureEnabled("editPlan") ? (
          <Tooltip title="プランを編集">
            <Link to={`/plan/${planId}/edit`}>{planName}</Link>
          </Tooltip>
        ) : (
          <Link to={`/plan/${planId}/edit`}>{planName}</Link>
        );
      },
    },
    {
      title: "取扱店舗",
      width: 150,
      render(_: unknown, { planId, shopPlans }: Plan) {
        return shopPlans.map(({ shop }) => (
          <Link to={`/plan/${planId}/dealer/edit`} key={shop.shopId}>
            <Tag color="processing">{shop.name}</Tag>
          </Link>
        ));
      },
    },
    {
      title: "伝票表示名",
      dataIndex: "receiptDisplayName",
      width: 150,
    },
    {
      title: "ハンディ表示名",
      dataIndex: "shopSideName",
      width: 150,
    },
    {
      title: "オプション",
      width: 200,
      render(_: string, { planOptions }: Plan) {
        return planOptions.map(({ name, planOptionId }) => <Tag key={planOptionId}>{name}</Tag>);
      },
    },
    {
      title: "プラン内カテゴリ",
      width: 150,
      render(_: unknown, { planMenuCategories }: Plan) {
        return planMenuCategories.map(({ category }) => (
          <Link key={category.categoryId} to={`/category/${category.categoryId}/edit`}>
            <Tag color="processing">{category.name}</Tag>
          </Link>
        ));
      },
    },
    {
      title: "制限時間",
      width: 150,
      dataIndex: "planTime",
      align: "right",
      render(_: string, { planTime }: Plan) {
        return typeof planTime === "number" ? `${planTime}分` : "無制限";
      },
    },
    { title: "説明", width: 150, dataIndex: "description" },
    {
      title: "アプリ表示時間",
      align: "center",
      width: 200,
      render(_: string, { orderableTime }: Plan) {
        if (!orderableTime) return "未設定";
        return <Tag>{orderableTime.name}</Tag>;
      },
    },
    {
      title: "画像の表示サイズ",
      align: "center",
      width: 200,
      render(_: unknown, { displayType }: Plan) {
        return <Tag>{getTypeName(displayType ?? "medium")}</Tag>;
      },
    },
    {
      title: "特集ラベル",
      align: "center",
      width: 150,
      render(_: unknown, { featuredLabelText }: Plan) {
        return featuredLabelText;
      },
    },
    {
      title: "プラングループ",
      align: "center",
      width: 150,
      render(_: string, { planGroup }: Plan) {
        if (!planGroup) return "";
        return <Tag>{planGroup.name}</Tag>;
      },
    },
    ...(isFeatureEnabled("addPlan")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { planId }: Plan) {
              return (
                <Tooltip title="コピーして新規作成">
                  <IconLink to={`/plan/add?planId=${planId}`}>
                    <CopyIcon />
                  </IconLink>
                </Tooltip>
              );
            },
          } as const,
        ]
      : []),
    ...(isFeatureEnabled("deletePlan")
      ? [
          {
            title: "",
            align: "center",
            fixed: "right",
            width: 60,
            render(_: string, { id, shopPlans, totalDealingShopCount }: Plan) {
              const disabled = totalDealingShopCount !== shopPlans.length;

              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>プランを削除しますか？</Paragraph>
                      <Paragraph>一度削除したプランを元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDelete({ planId: id });
                  }}
                  disabled={disabled}
                >
                  <DeleteIcon disabled={disabled} />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="planId"
      columns={displayType === "detail" ? detailColumns : compactColumns}
      dataSource={plans}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
