import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Row, Typography } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { LineOfficialAccount, Shop } from "../types";

import { ChannelIdField } from "./ChannelIdField";
import { ChannelSecretField } from "./ChannelSecretField";
import { LiffIdField } from "./LiffIdField";
import { LineOfficialAccountField } from "./LineOfficialAccountField";
import {
  EditLineChannelConfigFormValues,
  useEditLineChannelConfigForm,
} from "./useEditLineChannelConfigForm";

type Props = {
  shop: Shop;
  lineOfficialAccounts: LineOfficialAccount[];
  onSubmit: (config: EditLineChannelConfigFormValues) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const LinkWrapper = styled.div`
  margin-bottom: 8px;
`;

export const EditLineChannelConfigForm = memo<Props>(
  ({ shop, lineOfficialAccounts, onSubmit, onClose, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditLineChannelConfigForm({
      shop,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, submit, onFormValidationError]);

    return (
      <>
        <FormContent>
          <LinkWrapper>
            <Typography.Link href="https://developers.line.biz/console/" target="_blank">
              LINE Developers console
            </Typography.Link>
          </LinkWrapper>
          <Form
            name="lineChannelConfig"
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row>
              <Col span={12}>
                <LiffIdField />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ChannelIdField />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <ChannelSecretField />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <LineOfficialAccountField lineOfficialAccounts={lineOfficialAccounts} />
              </Col>
            </Row>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
