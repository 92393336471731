import React, { memo, useMemo } from "react";
import { TableColumnsType } from "antd";

import { AsyncSwitch } from "components/AsyncSwitch";
import { Table } from "components/Table";
import { TablePrintingText } from "components/TablePrintingText";
import { usePagination } from "hooks/usePagination";

import { Role, Table as TableType } from "../types";

type Props = {
  loading?: boolean;
  tables: TableType[];
  roles: Role[];
  onTogglePrintingConfig(args: { _roleId: number; tableId: number }): Promise<void>;
};

export const ShouldPrintPaymentReceiptTablePrintingSettingTable = memo<Props>(
  ({ loading, tables, roles, onTogglePrintingConfig }) => {
    const [pagination, setPagination] = usePagination();

    const columns = useMemo<TableColumnsType<TableType>>(
      () => [
        { title: "テーブル名", dataIndex: "name", fixed: "left" as const, width: 100 },
        {
          title: "出し分け",
          width: 50,
          fixed: "left" as const,
          render(_, table) {
            const isTablePrintingEnabled =
              table.paymentReceiptRoleTables.filter(({ roleId }) =>
                roles.some((role) => role.id === roleId),
              ).length > 0;

            return <TablePrintingText isEnabled={isTablePrintingEnabled} />;
          },
        },
        ...roles.map<TableColumnsType<TableType>[number]>((role) => ({
          title: role.name,
          width: 130,
          render(_, table) {
            const checked = table.paymentReceiptRoleTables.some(({ roleId }) => roleId === role.id);

            const handleChange = async () => {
              await onTogglePrintingConfig({ _roleId: role.roleId, tableId: table.tableId });
            };

            return (
              <AsyncSwitch
                data-cy={`toggle-${role.name}`}
                checked={checked}
                onChange={handleChange}
              />
            );
          },
        })),
      ],
      [onTogglePrintingConfig, roles],
    );

    return (
      <Table
        rowKey="tableId"
        columns={columns}
        dataSource={tables}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
