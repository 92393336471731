import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";

import { EditCompanyGetCompanyDocument, useEditCompanyUpdateCompanyMutation } from "../queries";
import { Company } from "../types";

type EditCompanyFormValues = {
  name: string;
};

export const EditCompanyFormItem = createFormItem<EditCompanyFormValues>();

export const useEditCompanyForm = (company: Company) => {
  const [form] = Form.useForm();
  const initialValues = { name: company.name };

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [updateCompanyMutation, { loading }] = useEditCompanyUpdateCompanyMutation();

  const submit = useCallback(
    async (company: Company) => {
      const { id: companyId } = company;
      await form.validateFields();
      const field = form.getFieldsValue() as EditCompanyFormValues;

      if (!companyId) return;

      try {
        await updateCompanyMutation({
          variables: {
            companyId,
            name: field.name,
          },
          refetchQueries: [
            {
              query: EditCompanyGetCompanyDocument,
              variables: { companyId },
            },
          ],
        });
        message.success("編集を保存しました");
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [form, updateCompanyMutation, goBack],
  );
  const onSubmit = useCallback(() => submit(company), [company, submit]);

  return { form, initialValues, loading, onSubmit, goBack };
};
