import React, { memo } from "react";
import styled from "styled-components";
import { InputNumber, Select, Space } from "antd";
import { FormInstance } from "antd/lib";

import { VisitedBusinessOperationHoursLabel } from "components/CustomerSegment/VisitedBusinessOperationHoursLabel";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { Shop } from "pages/AddMessageDelivery/types";
import { Menu } from "pages/MessageDeliveryJob/types";
import { BusinessOperationHourTypeEnum } from "types/graphql";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

import { BirthdayCountFilter } from "./BirthdayCountFilter";
import { ConsecutiveUnvisitedDayCountFilter } from "./ConsecutiveUnvisitedDayCountFilter";
import { GenderFilter } from "./GenderFilter";
import { VisitedBusinessOperationHoursFilter } from "./VisitedBusinessOperationHoursFilter";
import { VisitedDayCountFilterField } from "./VisitedDayCountFilterField";
import { VisitedDayOfWeekFilter } from "./VisitedDayOfWeekFilter";
import { VisitedQuestionnaireAnswerFilter } from "./VisitedQuestionnaireAnswerFilter";

const Wrapper = styled.div`
  padding: 24px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
`;

const ItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type Props = {
  shops: Shop[];
  menus: Menu[];
  form: FormInstance;
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
};

export const CustomerSegmentField = memo<Props>(
  ({ form, shops, menus, shopBusinessOperationHourTypes }) => (
    <Wrapper>
      <MessageDeliveryJobFormItem.NonProperty
        label={
          <>
            <img
              src="/message-delivery/directions-walk.png"
              width={20}
              height={20}
              alt="来店回数"
            />
            <Spacer size={4} />
            来店回数
          </>
        }
        style={{ marginBottom: 0 }}
      >
        <ItemContent>
          <VisitedDayCountFilterField form={form} />
        </ItemContent>
      </MessageDeliveryJobFormItem.NonProperty>
      <MessageDeliveryJobFormItem.NonProperty
        label={
          <>
            <img
              src="/message-delivery/carry-out.png"
              width={20}
              height={20}
              alt="来店からの日数"
            />
            <Spacer size={4} />
            来店からの日数
          </>
        }
        style={{ marginBottom: 0 }}
      >
        <ItemContent>
          <ConsecutiveUnvisitedDayCountFilter />
        </ItemContent>
      </MessageDeliveryJobFormItem.NonProperty>
      <MessageDeliveryJobFormItem.NonProperty label="来店した曜日">
        <ItemContent>
          <VisitedDayOfWeekFilter />
        </ItemContent>
      </MessageDeliveryJobFormItem.NonProperty>
      {Boolean(shopBusinessOperationHourTypes.length) && (
        <MessageDeliveryJobFormItem.NonProperty label={<VisitedBusinessOperationHoursLabel />}>
          <ItemContent>
            <VisitedBusinessOperationHoursFilter
              shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
            />
          </ItemContent>
        </MessageDeliveryJobFormItem.NonProperty>
      )}
      <MessageDeliveryJobFormItem.NonProperty label="アンケート回答状況">
        <ItemContent>
          <VisitedQuestionnaireAnswerFilter />
        </ItemContent>
      </MessageDeliveryJobFormItem.NonProperty>

      <MessageDeliveryJobFormItem name="visitedShopsFilterShopIds" label="来店した店舗">
        <Select
          mode="multiple"
          optionFilterProp="label"
          options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
        />
      </MessageDeliveryJobFormItem>

      <MessageDeliveryJobFormItem.NonProperty label="注文したメニュー">
        <MessageDeliveryJobFormItem name={["orderedMenusFilter", "menuIds"]} noStyle>
          <Select mode="multiple" disabled>
            {menus.map(({ id, name }) => (
              <Select.Option key={id} value={id}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </MessageDeliveryJobFormItem>
        <Spacer size={4} />
        <Space size="small">
          上記のメニューのいずれかをトータル
          <MessageDeliveryJobFormItem
            name={["orderedMenusFilter", "moreThanOrEqualQuantity"]}
            noStyle
          >
            <InputNumber style={{ width: "88px" }} disabled min={1} />
          </MessageDeliveryJobFormItem>
          回以上注文
        </Space>
      </MessageDeliveryJobFormItem.NonProperty>

      <MessageDeliveryJobFormItem.NonProperty label="性別">
        <GenderFilter />
      </MessageDeliveryJobFormItem.NonProperty>

      <MessageDeliveryJobFormItem.NonProperty label="誕生日までの日数">
        <BirthdayCountFilter form={form} />
      </MessageDeliveryJobFormItem.NonProperty>
    </Wrapper>
  ),
);
