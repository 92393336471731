import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { PriceField } from "pages/EditGiftChoice/EditGiftChoiceForm/PriceField";
import { useEditGiftChoiceForm } from "pages/EditGiftChoice/EditGiftChoiceForm/useEditGiftChoiceForm";
import { Choice } from "pages/EditGiftChoice/types";
import { ChoiceSetInput } from "types/graphql";

type Props = {
  choice: Choice;
  onSubmit: (choice: ChoiceSetInput) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditGiftChoiceForm = memo<Props>(
  ({ choice, onClose: handleClose, onSubmit, loading, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditGiftChoiceForm(choice, onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    return (
      <>
        <FormContent>
          <Form name="choice" form={form} initialValues={initialValues} layout="vertical">
            <PriceField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
