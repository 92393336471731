import React, { useCallback } from "react";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { PikaichiConfigInsertInput } from "types/graphql";

import { EditPikaichiConfigForm } from "./EditPikaichiConfigForm";
import {
  useEditPikaichiConfigGetShopPikaichiConfigQuery,
  useEditPikaichiConfigUpsertPikaichiConfigMutation,
} from "./queries";

export const EditPikaichiConfig = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const { data, error, refetch } = useEditPikaichiConfigGetShopPikaichiConfigQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );

  const pikaichiConfig = data?.shop_by_pk?.pikaichiConfig;

  const [upsertPikaichiConfigMutation, { loading: submitting }] =
    useEditPikaichiConfigUpsertPikaichiConfigMutation();

  const onSubmit = useCallback(
    async (input: Omit<PikaichiConfigInsertInput, "shopId">) => {
      if (!shopId) return;

      const pikaichiConfigId = pikaichiConfig?.pikaichiConfigId ?? uuidv4();
      const pikaichiConfigDto = {
        pikaichiConfigId,
        shopId,
        ...input,
      };

      try {
        await upsertPikaichiConfigMutation({
          variables: {
            pikaichiConfig: pikaichiConfigDto,
          },
        });
        message.success("更新しました");
      } catch {
        message.error("更新に失敗しました");
      }

      await refetch();
    },
    [pikaichiConfig, shopId, upsertPikaichiConfigMutation, refetch],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout title="ぴかいちナビ連携">
      <PageHeader title="ぴかいちナビ連携設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <EditPikaichiConfigForm
          pikaichiConfig={pikaichiConfig}
          onSubmit={onSubmit}
          onFormValidationError={onFormValidationError}
          loading={submitting}
        />
      )}
    </DashboardLayout>
  );
};
