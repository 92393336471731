import React, { memo, useCallback, useState } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { CollapsableFormSection } from "components/Form/CollapsableFormSection";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { TranslationNameFields } from "components/TranslationsFields/TranslationNameFields";
import { HeaderUploadField } from "pages/AddCategory/AddCategoryForm/HeaderUploadField";
import { NameField } from "pages/AddCategory/AddCategoryForm/NameField";
import { ShopSideBackgroundColorField } from "pages/AddCategory/AddCategoryForm/ShopDisplayBackgroundColorField";
import { ShopSideNameField } from "pages/AddCategory/AddCategoryForm/ShopSideNameField";
import { useAddCategoryForm } from "pages/AddCategory/AddCategoryForm/useAddCategoryForm";
import { CreateCategoryInputCategorySource } from "types/graphql";

type Props = {
  onSubmit: (category: Omit<CreateCategoryInputCategorySource, "priority">) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddCategoryForm = memo<Props>(
  ({ onClose, onSubmit, loading, onFormValidationError }) => {
    const { form, initialValues, submit } = useAddCategoryForm(onSubmit);

    const [uploadImage, setUploadImage] = useState("");

    const [shopSideBackgroundColor, setShopSideBackgroundColor] = useState("#FFFFFF");

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
          shopSideBackgroundColor,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, uploadImage, shopSideBackgroundColor, onFormValidationError, form]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="category" form={form} initialValues={initialValues} layout="vertical">
            <HeaderUploadField uploadImage={uploadImage} setUploadImage={setUploadImage} />
            <NameField />
            <ShopSideNameField />
            <ShopSideBackgroundColorField
              shopSideBackgroundColor={shopSideBackgroundColor}
              setShopSideBackgroundColor={setShopSideBackgroundColor}
            />

            <CollapsableFormSection title="多言語設定">
              <TranslationNameFields originalFieldName="name" label="カテゴリ名" />
            </CollapsableFormSection>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
