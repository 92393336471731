import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationMediaMapsGetTecAggregationMediaMaps = gql`
    query TecAggregationMediaMapsGetTecAggregationMediaMaps($companyId: uuid!) {
  tecAggregationMediaMap(
    where: {companyId: {_eq: $companyId}}
    order_by: {aggregationNumber: asc}
  ) {
    id
    aggregationNumber
    mediaName
  }
}
    `;
export type TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type TecAggregationMediaMapsGetTecAggregationMediaMapsQuery = (
  { __typename?: 'query_root' }
  & { tecAggregationMediaMap: Array<(
    { __typename?: 'tecAggregationMediaMap' }
    & Pick<Types.TecAggregationMediaMap, 'id' | 'aggregationNumber' | 'mediaName'>
  )> }
);


export const TecAggregationMediaMapsGetTecAggregationMediaMapsDocument = gql`
    query TecAggregationMediaMapsGetTecAggregationMediaMaps($companyId: uuid!) {
  tecAggregationMediaMap(
    where: {companyId: {_eq: $companyId}}
    order_by: {aggregationNumber: asc}
  ) {
    id
    aggregationNumber
    mediaName
  }
}
    `;

/**
 * __useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery__
 *
 * To run a query within a React component, call `useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationMediaMapsGetTecAggregationMediaMapsQuery, TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationMediaMapsGetTecAggregationMediaMapsQuery, TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables>(TecAggregationMediaMapsGetTecAggregationMediaMapsDocument, options);
      }
export function useTecAggregationMediaMapsGetTecAggregationMediaMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationMediaMapsGetTecAggregationMediaMapsQuery, TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationMediaMapsGetTecAggregationMediaMapsQuery, TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables>(TecAggregationMediaMapsGetTecAggregationMediaMapsDocument, options);
        }
export type TecAggregationMediaMapsGetTecAggregationMediaMapsQueryHookResult = ReturnType<typeof useTecAggregationMediaMapsGetTecAggregationMediaMapsQuery>;
export type TecAggregationMediaMapsGetTecAggregationMediaMapsLazyQueryHookResult = ReturnType<typeof useTecAggregationMediaMapsGetTecAggregationMediaMapsLazyQuery>;
export type TecAggregationMediaMapsGetTecAggregationMediaMapsQueryResult = Apollo.QueryResult<TecAggregationMediaMapsGetTecAggregationMediaMapsQuery, TecAggregationMediaMapsGetTecAggregationMediaMapsQueryVariables>;