import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Descriptions } from "antd";
import { red } from "@ant-design/colors";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { TecAggregationMenuHeader } from "components/PageHeader/TecAggregationMenuHeader";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { EditTecAggregationMenuForm } from "./EditTecAggregationMenuForm";
import {
  useEditTecAggregationMenuGetMenuCodeQuery,
  useEditTecAggregationMenuGetMenuQuery,
  useEditTecAggregationMenuUpdateTecAggregationMenuMutation,
} from "./queries";

const errorContentStyle: React.CSSProperties = { color: red[5], backgroundColor: "white" };

export const EditTecAggregationMenu = () => {
  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const _menuId = Number(id);
  const navigate = useNavigate();

  const [errorCodes, setErrorCodes] = useState<{
    name: string;
    menuCode: string;
    dpName?: string | null;
    dpCode?: string | null;
    gpCode?: string | null;
    gpName?: string | null;
  } | null>(null);

  const {
    data: getMenuData,
    loading: loadingMenu,
    refetch: refetchMenu,
  } = useEditTecAggregationMenuGetMenuQuery(
    !Number.isNaN(_menuId) ? { variables: { menuId: _menuId } } : { skip: true },
  );
  const menu = getMenuData?.menu?.[0];
  const menuId = menu?.id;
  const companyId = menu?.companyId;
  setCompany(menu?.companyId);

  const {
    data: getMenuCodesData,
    loading: loadingGetMenuCodes,
    refetch: refetchMenuCodes,
  } = useEditTecAggregationMenuGetMenuCodeQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const tecAggregationMenu = menu?.tecAggregationMenu;

  const [updateTecAggregationMenuMutation, { loading: loadingUpdateTecAggregationMenu }] =
    useEditTecAggregationMenuUpdateTecAggregationMenuMutation();

  const onSubmit = useCallback(
    async (
      input: Pick<
        DashboardAccountUpsertTecAggregationMenuInput,
        "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
      >,
    ) => {
      if (!company) return;

      setErrorCodes(null);

      const id = tecAggregationMenu?.id ?? uuidv4();
      const tecAggregationMenuDto = {
        id,
        menuId,
        _menuId,
        companyId: company.id,
        ...input,
      };

      try {
        const result = await updateTecAggregationMenuMutation({
          variables: { input: tecAggregationMenuDto },
        });

        await refetchMenu();

        if (
          result.data?.upsertTecAggregationMenu.__typename ===
          "DashboardUpsertTecAggregationMenuDifferentCodeIsUsedForSameMenuCode"
        ) {
          const { name, menuCode, dpCode, dpName, gpCode, gpName } =
            result.data.upsertTecAggregationMenu;
          setErrorCodes({
            name,
            menuCode,
            dpCode,
            dpName,
            gpCode,
            gpName,
          });

          message.error("編集の保存に失敗しました");
          return;
        }

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      _menuId,
      company,
      menuId,
      tecAggregationMenu?.id,
      updateTecAggregationMenuMutation,
      refetchMenu,
    ],
  );

  const adoptMenuCodeAutomatically = useCallback(() => {
    const menuCodes = (getMenuCodesData?.tecAggregationMenu ?? [])
      .map((c) => (!isNaN(Number(c.menuCode)) ? Number(c.menuCode) : null))
      .filter(isNotNull);

    return menuCodes.length > 0 ? (Math.max(...menuCodes) + 1).toString() : "1";
  }, [getMenuCodesData?.tecAggregationMenu]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading = loadingMenu || loadingGetMenuCodes;

  return (
    <DashboardLayout
      title={menu?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "連携メニュー" }],
      }}
    >
      <TecAggregationMenuHeader menu={menu ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}

      {errorCodes && (
        <>
          <Alert
            message="以下のコード設定と競合しています"
            description={
              <>
                <Spacer size={10} />
                <Descriptions
                  column={2}
                  bordered
                  items={[
                    {
                      label: "メニュー名",
                      children: errorCodes.name,
                      contentStyle: { backgroundColor: "white" },
                    },
                    {
                      label: "メニューコード",
                      children: errorCodes.menuCode,
                      contentStyle: { backgroundColor: "white" },
                    },
                    errorCodes.dpCode
                      ? {
                          label: "部門コード",
                          children: errorCodes.dpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.dpName
                      ? {
                          label: "部門名称",
                          children: errorCodes.dpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpCode
                      ? {
                          label: "分類コード",
                          children: errorCodes.gpCode,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                    errorCodes.gpName
                      ? {
                          label: "分類名称",
                          children: errorCodes.gpName,
                          contentStyle: errorContentStyle,
                        }
                      : null,
                  ].filter(isNotNull)}
                />
              </>
            }
            type="error"
          />
          <Spacer size={10} />
        </>
      )}

      {menu && (
        <EditTecAggregationMenuForm
          menu={menu}
          onAutoAdoptMenuCodeButtonPressed={adoptMenuCodeAutomatically}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateTecAggregationMenu}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
