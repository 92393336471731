import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddQuestionnaireConfigGetQuestionnaires = gql`
    query AddQuestionnaireConfigGetQuestionnaires {
  questionnaire {
    id
    name
  }
}
    `;
export const AddQuestionnaireConfigGetCoupons = gql`
    query AddQuestionnaireConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddQuestionnaireConfigInsertQuestionnaireConfig = gql`
    mutation AddQuestionnaireConfigInsertQuestionnaireConfig($questionnaireConfig: questionnaireConfig_insert_input!) {
  insert_questionnaireConfig_one(object: $questionnaireConfig) {
    id
  }
}
    `;
export type AddQuestionnaireConfigGetQuestionnairesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddQuestionnaireConfigGetQuestionnairesQuery = (
  { __typename?: 'query_root' }
  & { questionnaire: Array<(
    { __typename?: 'questionnaire' }
    & Pick<Types.Questionnaire, 'id' | 'name'>
  )> }
);

export type AddQuestionnaireConfigGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddQuestionnaireConfigGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type AddQuestionnaireConfigInsertQuestionnaireConfigMutationVariables = Types.Exact<{
  questionnaireConfig: Types.QuestionnaireConfigInsertInput;
}>;


export type AddQuestionnaireConfigInsertQuestionnaireConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_questionnaireConfig_one?: Types.Maybe<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id'>
  )> }
);


export const AddQuestionnaireConfigGetQuestionnairesDocument = gql`
    query AddQuestionnaireConfigGetQuestionnaires {
  questionnaire {
    id
    name
  }
}
    `;

/**
 * __useAddQuestionnaireConfigGetQuestionnairesQuery__
 *
 * To run a query within a React component, call `useAddQuestionnaireConfigGetQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireConfigGetQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddQuestionnaireConfigGetQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddQuestionnaireConfigGetQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<AddQuestionnaireConfigGetQuestionnairesQuery, AddQuestionnaireConfigGetQuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddQuestionnaireConfigGetQuestionnairesQuery, AddQuestionnaireConfigGetQuestionnairesQueryVariables>(AddQuestionnaireConfigGetQuestionnairesDocument, options);
      }
export function useAddQuestionnaireConfigGetQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddQuestionnaireConfigGetQuestionnairesQuery, AddQuestionnaireConfigGetQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddQuestionnaireConfigGetQuestionnairesQuery, AddQuestionnaireConfigGetQuestionnairesQueryVariables>(AddQuestionnaireConfigGetQuestionnairesDocument, options);
        }
export type AddQuestionnaireConfigGetQuestionnairesQueryHookResult = ReturnType<typeof useAddQuestionnaireConfigGetQuestionnairesQuery>;
export type AddQuestionnaireConfigGetQuestionnairesLazyQueryHookResult = ReturnType<typeof useAddQuestionnaireConfigGetQuestionnairesLazyQuery>;
export type AddQuestionnaireConfigGetQuestionnairesQueryResult = Apollo.QueryResult<AddQuestionnaireConfigGetQuestionnairesQuery, AddQuestionnaireConfigGetQuestionnairesQueryVariables>;
export const AddQuestionnaireConfigGetCouponsDocument = gql`
    query AddQuestionnaireConfigGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddQuestionnaireConfigGetCouponsQuery__
 *
 * To run a query within a React component, call `useAddQuestionnaireConfigGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireConfigGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddQuestionnaireConfigGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddQuestionnaireConfigGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<AddQuestionnaireConfigGetCouponsQuery, AddQuestionnaireConfigGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddQuestionnaireConfigGetCouponsQuery, AddQuestionnaireConfigGetCouponsQueryVariables>(AddQuestionnaireConfigGetCouponsDocument, options);
      }
export function useAddQuestionnaireConfigGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddQuestionnaireConfigGetCouponsQuery, AddQuestionnaireConfigGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddQuestionnaireConfigGetCouponsQuery, AddQuestionnaireConfigGetCouponsQueryVariables>(AddQuestionnaireConfigGetCouponsDocument, options);
        }
export type AddQuestionnaireConfigGetCouponsQueryHookResult = ReturnType<typeof useAddQuestionnaireConfigGetCouponsQuery>;
export type AddQuestionnaireConfigGetCouponsLazyQueryHookResult = ReturnType<typeof useAddQuestionnaireConfigGetCouponsLazyQuery>;
export type AddQuestionnaireConfigGetCouponsQueryResult = Apollo.QueryResult<AddQuestionnaireConfigGetCouponsQuery, AddQuestionnaireConfigGetCouponsQueryVariables>;
export const AddQuestionnaireConfigInsertQuestionnaireConfigDocument = gql`
    mutation AddQuestionnaireConfigInsertQuestionnaireConfig($questionnaireConfig: questionnaireConfig_insert_input!) {
  insert_questionnaireConfig_one(object: $questionnaireConfig) {
    id
  }
}
    `;
export type AddQuestionnaireConfigInsertQuestionnaireConfigMutationFn = Apollo.MutationFunction<AddQuestionnaireConfigInsertQuestionnaireConfigMutation, AddQuestionnaireConfigInsertQuestionnaireConfigMutationVariables>;

/**
 * __useAddQuestionnaireConfigInsertQuestionnaireConfigMutation__
 *
 * To run a mutation, you first call `useAddQuestionnaireConfigInsertQuestionnaireConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireConfigInsertQuestionnaireConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionnaireConfigInsertQuestionnaireConfigMutation, { data, loading, error }] = useAddQuestionnaireConfigInsertQuestionnaireConfigMutation({
 *   variables: {
 *      questionnaireConfig: // value for 'questionnaireConfig'
 *   },
 * });
 */
export function useAddQuestionnaireConfigInsertQuestionnaireConfigMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionnaireConfigInsertQuestionnaireConfigMutation, AddQuestionnaireConfigInsertQuestionnaireConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionnaireConfigInsertQuestionnaireConfigMutation, AddQuestionnaireConfigInsertQuestionnaireConfigMutationVariables>(AddQuestionnaireConfigInsertQuestionnaireConfigDocument, options);
      }
export type AddQuestionnaireConfigInsertQuestionnaireConfigMutationHookResult = ReturnType<typeof useAddQuestionnaireConfigInsertQuestionnaireConfigMutation>;
export type AddQuestionnaireConfigInsertQuestionnaireConfigMutationResult = Apollo.MutationResult<AddQuestionnaireConfigInsertQuestionnaireConfigMutation>;
export type AddQuestionnaireConfigInsertQuestionnaireConfigMutationOptions = Apollo.BaseMutationOptions<AddQuestionnaireConfigInsertQuestionnaireConfigMutation, AddQuestionnaireConfigInsertQuestionnaireConfigMutationVariables>;