import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { CategoryHeader } from "components/PageHeader/CategoryHeader";
import { useCompany } from "hooks/useCompany";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { EditCategoryForm } from "pages/EditCategory/EditCategoryForm";
import {
  useEditCategoryGetCategoryQuery,
  useEditCategoryUpdateCategoryMutation,
} from "pages/EditCategory/queries";
import { CategorySetInput, DashboardAccountRoleTypeEnum } from "types/graphql";

export const EditCategory = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const categoryId = Number(id);
  const navigate = useNavigate();

  const {
    data: getCategoryData,
    loading: loadingCategory,
    error,
  } = useEditCategoryGetCategoryQuery(
    !Number.isNaN(categoryId) ? { variables: { categoryId } } : { skip: true },
  );
  const category = getCategoryData?.category?.[0];

  setCompany(category?.companyId);

  const [updateCategoryMutation, { loading: loadingUpdateCategory }] =
    useEditCategoryUpdateCategoryMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (category: CategorySetInput) => {
      if (!Number.isNaN(categoryId)) {
        try {
          await updateCategoryMutation({
            variables: { categoryId, category },
          });

          message.success("編集を保存しました");
          goBack();
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [categoryId, updateCategoryMutation, goBack],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const { role } = useCurrentUserPermissions();
  const shouldShowManagingShopAlert =
    isFeatureEnabled("editMenuRecommendation") && role === DashboardAccountRoleTypeEnum.ShopMember;

  return (
    <DashboardLayout
      title={category?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "カテゴリ" }],
      }}
    >
      <CategoryHeader
        category={category ?? null}
        onBack={goBack}
        shouldShowManagingShopAlert={shouldShowManagingShopAlert}
      />
      {loadingCategory && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {category && (
        <EditCategoryForm
          category={category}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateCategory}
          onFormValidationError={onFormValidationError}
          disabled={!isFeatureEnabled("editCategory")}
        />
      )}
    </DashboardLayout>
  );
};
