import React, { memo } from "react";
import { Tabs } from "antd";
import { FormInstance } from "antd/es/form";
import { Form } from "antd/lib";

import { OptionInputTypeEnum } from "types/graphql";

import { TranslationField } from "./EditPlanChoiceForm/TranslationField";
import {
  EditPlanChoiceFormValues,
  useEditPlanChoiceForm,
} from "./EditPlanChoiceForm/useEditPlanChoiceForm";
import { EditPlanChoiceForm } from "./EditPlanChoiceForm";
import { PlanChoice, Translation } from "./types";

type Props = {
  form: FormInstance;
  planChoice: PlanChoice;
  translations: Translation[];
  inputType: OptionInputTypeEnum;
  onChange: (planChoice: EditPlanChoiceFormValues) => void;
  disabled: boolean;
};

const tabKey = {
  general: "1",
  translation: "2",
};

export const EditPlanChoiceModalForm = memo<Props>(
  ({ form, planChoice, translations, inputType, onChange, disabled }) => {
    const {
      initialValues,
      formValues,
      change: onValuesChange,
      changeImage: setUploadImage,
      uploadImage,
    } = useEditPlanChoiceForm({
      form,
      planChoice,
      translations,
      inputType,
      onChange,
    });

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Tabs defaultActiveKey={tabKey.general}>
          <Tabs.TabPane tab="基本情報" key={tabKey.general}>
            <EditPlanChoiceForm
              planChoice={planChoice}
              inputType={inputType}
              disabled={disabled}
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              formValues={formValues}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="多言語設定" key={tabKey.translation}>
            <TranslationField disabled={disabled} />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    );
  },
);
