import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Role } from "pages/AddCookingItem/types";

import { AddCookingItemFormItem } from "../useAddCookingItemForm";

const { Option } = Select;

type Props = {
  roles: Role[];
} & Omit<FormItemProps, "children" | "name">;

export const RoleField = memo<Props>(({ roles, ...props }) => (
  <AddCookingItemFormItem
    label="印刷先"
    name="roleId"
    rules={[{ required: true, message: "印刷先を入力してください" }]}
    {...props}
  >
    <Select<number>>
      {roles.map(({ roleId, name }) => (
        <Option key={roleId} value={roleId}>
          {name}
        </Option>
      ))}
    </Select>
  </AddCookingItemFormItem>
));
