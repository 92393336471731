import React, { Fragment, memo, useMemo } from "react";
import styled, { css } from "styled-components";

import AccountNoImageIcon from "assets/account_no_image.svg";
import { colors, grey } from "constants/colors";

import { TextMessageFormValue } from "../../types";

const Wrapper = styled.div<{ showSpeechBubble: boolean }>`
  position: relative;
  padding: 8px 10px 8px 14px;
  border-radius: 15px;
  background: ${grey[0]};

  ${({ showSpeechBubble }) =>
    showSpeechBubble &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: -3px;
        border: 10px solid transparent;
        border-top: 10px solid ${grey[0]};
        transform: rotate(10deg);
      }
    `}
`;

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-wrap;
  word-break: break-all;

  > span {
    color: #1890ff;
    text-decoration: underline;
  }
`;

const DummyOgpWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  column-gap: 16px;
  border-left: 2px solid ${colors.Border.Default};
`;

const DummyOgpTitle = styled.p`
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
`;

const DummyOgpDescription = styled.p`
  font-size: 10px;
  line-height: 1.3;
  margin: 0;
  color: ${colors.Text.Secondary};
  white-space: pre-wrap;
`;

const DummyOgpImage = styled.div`
  width: 44px;
  height: 44px;
  background: ${grey[4]};
  border-radius: 4px;
`;

type Props = {
  index: number;
  message: TextMessageFormValue;
};

export const TextMessage = memo(({ index, message }: Props) => {
  const urls = useMemo(() => message.text.match(/https?:\/\/\S+/g), [message.text]);

  const splitText = useMemo(
    () =>
      message.text
        .split(/(https?:\/\/\S+)/)
        .map((part, index) => ({ isLink: index % 2 === 1, text: part })),
    [message.text],
  );

  return (
    <Wrapper showSpeechBubble={index === 0}>
      <Text>
        {splitText.map((text, index) => (
          <Fragment key={index}>{text.isLink ? <span>{text.text}</span> : text.text}</Fragment>
        ))}
      </Text>

      {urls
        ? urls.map((_, index) => (
            <DummyOgpWrapper key={index}>
              <div>
                <DummyOgpTitle>ダイニーバル</DummyOgpTitle>
                <DummyOgpDescription>{"ダイニーバル\nTEL：000-0000-0000"}</DummyOgpDescription>
              </div>
              <DummyOgpImage>
                <AccountNoImageIcon />
              </DummyOgpImage>
            </DummyOgpWrapper>
          ))
        : null}
    </Wrapper>
  );
});
