import { GetMenuAutoCompulsoryAppetizerConfigQuery } from "./queries";

export type Menu = GetMenuAutoCompulsoryAppetizerConfigQuery["company"][number]["menus"][number];

export type Category = Menu["categoryMenus"][number]["category"];

export type CompulsoryAppetizerConfig =
  GetMenuAutoCompulsoryAppetizerConfigQuery["compulsoryAppetizerConfig"][number];

export enum CompulsoryAppetizerModalRoute {
  MenuSelect = "menuSelect",
  TimeSelect = "timeSelect",
}
