import React, { memo } from "react";
import styled from "styled-components";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { Company } from "../types";

import { CustomerDashboardShopSelector } from "./CustomerDashboardShopSelector";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

type Props = {
  companies: Company[];
  targetDate: dayjs.Dayjs | undefined;
  handleChangeDate: (_: dayjs.Dayjs | null, targetDateString: string) => void;
  loading: boolean;
};

// TODO: DatePicker のデザイン
export const CustomerDashboardCondition = memo<Props>(
  ({ companies, targetDate, handleChangeDate, loading }) => (
    <Wrapper>
      <CustomerDashboardShopSelector companies={companies} loading={loading} />

      <DatePicker value={targetDate} onChange={handleChangeDate} />
    </Wrapper>
  ),
);
