import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { OrderableTime } from "pages/AddPlan/types";

import { AddPlanFormItem } from "../useAddPlanForm";

const { Option } = Select;

type Props = {
  orderableTimes: OrderableTime[];
} & Omit<FormItemProps, "children" | "name">;

export const OrderableTimeField = memo<Props>(({ orderableTimes, ...props }) => (
  <AddPlanFormItem
    label={
      <FormHelp
        label="表示時間帯"
        help="お客様の注文画面に表示される時間の設定です。ハンディからは常時注文可能です"
      />
    }
    name="orderableTimeId"
    rules={[{ required: true, message: "アプリ表示時間名を入力してください" }]}
    {...props}
  >
    <Select<number> placeholder="表示時間帯を選択してください">
      {orderableTimes.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  </AddPlanFormItem>
));
