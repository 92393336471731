import React, { memo } from "react";
import { DatePicker, Radio, Space } from "antd";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { Spacer } from "components/Spacer";

import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const AvailableUntilField = memo<Props>((props) => (
  <AddCouponFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ isDisabledAvailableUntil }) => [
      isDisabledAvailableUntil,
    ])}
    noStyle
  >
    {({ getFieldValue }) => (
      <AddCouponFormItem
        label="終了日時"
        name="isDisabledAvailableUntil"
        rules={[
          { required: true },
          {
            validator: async (_, isDisabledAvailableUntil) => {
              if (isDisabledAvailableUntil === true) return;

              const availableUntil = getFieldValue("availableUntil");

              if (!availableUntil) {
                throw new Error("クーポンの終了日時を入力してください");
              }

              if (availableUntil <= getFieldValue("availableFrom")) {
                throw new Error("終了日時は開始日より後ろである必要があります");
              }
            },
            validateTrigger: "onSubmit",
          },
        ]}
        {...props}
      >
        <Radio.Group>
          <Space direction="vertical">
            <Radio value={true}>指定しない (無期限)</Radio>
            <Radio value={false}>
              指定する
              <Spacer size={4} />
              <AddCouponFormItem name="availableUntil" noStyle>
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  disabled={getFieldValue("isDisabledAvailableUntil")}
                />
              </AddCouponFormItem>
            </Radio>
          </Space>
        </Radio.Group>
      </AddCouponFormItem>
    )}
  </AddCouponFormItem.NonProperty>
));
