import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Drawer, Layout, Menu as AntdMenu } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import constate from "constate";

import { Menu } from "components/Layout/DashboardLayout/NavigationDrawer/Menu";
import { UserCard } from "components/Layout/DashboardLayout/NavigationDrawer/UserCard";
import { Company } from "components/Layout/DashboardLayout/types";
import { colors, grey } from "constants/colors";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { useViewport } from "hooks/useViewport";

import pkg from "../../../../../package.json";

const appVersion = pkg.version;

const { Sider } = Layout;

const StyledSider = styled(Sider).attrs({ theme: "light" })`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
  }

  .ant-layout-sider-trigger {
    background: ${colors.BackGround.Tertiary};
  }
`;

const VersionText = styled.div`
  margin-bottom: 4px;
  text-align: center;
  font-size: 12px;
  color: ${grey[5]};

  span {
    display: inline-block;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    padding-top: 64px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${grey[3]};
`;

const DrawerMenuContainer = styled.div`
  flex: 1;
  overflow: scroll;
`;

const helpPageUrl = "https://dinii.wraptas.site/";

const navigationDrawerCollapsedKey = "navigationDrawerCollapsed";

const firstMenuItemKey = "tmp_key-0";

const useNavigationDrawer = () => {
  const [selectedKey, setSelectedKey] = useState("");
  const [collapsed, setCollapsed] = useState(
    JSON.parse(localStorage.getItem(navigationDrawerCollapsedKey) ?? "false"),
  );
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const updateSelectedKey = useCallback(({ key }: { key: string }) => setSelectedKey(key), []);
  const resetSelectedKey = useCallback(() => setSelectedKey(firstMenuItemKey), []);
  const updateCollapsed = useCallback((collapsed: boolean) => {
    localStorage.setItem(navigationDrawerCollapsedKey, JSON.stringify(collapsed));
    setCollapsed(collapsed);
  }, []);
  const updateOpenKeys = useCallback(
    (openKeys: string[]) => {
      if (!collapsed) setOpenKeys(openKeys);
    },
    [collapsed],
  );

  return {
    selectedKey,
    collapsed,
    openKeys,
    updateSelectedKey,
    resetSelectedKey,
    updateCollapsed,
    updateOpenKeys,
  };
};

export const [NavigationDrawerProvider, useConstateNavigationDrawer] =
  constate(useNavigationDrawer);

type Props = {
  accountName: string | undefined;
  companies: Company[];
  setCompany: (companyId: string) => void;
  open: boolean;
  onClose: () => void;
  signOut: () => void;
};

export const NavigationDrawer = memo<Props>(
  ({ accountName, companies, open, onClose, signOut }) => {
    const [shop] = useShop();
    const currentShop = companies
      .flatMap(({ shops }) => shops)
      .find(({ shopId }) => shopId === shop?.shopId);

    const [company] = useCompany();
    const currentCompany = companies.find(({ companyId }) => companyId === company?.companyId);

    const { isDesktop } = useViewport();
    const { collapsed, updateCollapsed } = useConstateNavigationDrawer();

    if (isDesktop) {
      return (
        <StyledSider collapsible collapsed={collapsed} onCollapse={updateCollapsed} width={240}>
          <Menu company={currentCompany} shop={currentShop} onSelect={onClose} />
          <VersionText>
            <span>{appVersion}</span>
            {import.meta.env.APP_ENV !== "production" && <span>({import.meta.env.APP_ENV})</span>}
          </VersionText>
        </StyledSider>
      );
    }

    return (
      <StyledDrawer placement="left" closable={false} visible={open} onClose={onClose} width={240}>
        <UserCard
          companyName={currentCompany?.name}
          accountName={accountName}
          companies={companies}
          signOut={signOut}
        />
        <DrawerMenuContainer>
          <Menu company={currentCompany} shop={currentShop} onSelect={onClose} />
        </DrawerMenuContainer>
        <Divider />
        <AntdMenu mode="inline">
          <AntdMenu.Item key="help">
            <a href={helpPageUrl} aria-label="ヘルプサイト">
              <QuestionCircleOutlined />
              <span>ヘルプサイト</span>
            </a>
          </AntdMenu.Item>
        </AntdMenu>
        <Divider />
        <VersionText>
          <span>{appVersion}</span>
          {import.meta.env.APP_ENV !== "production" && <span>({import.meta.env.APP_ENV})</span>}
        </VersionText>
      </StyledDrawer>
    );
  },
);
