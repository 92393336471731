import React, { memo, ReactNode } from "react";
import styled from "styled-components";
import { Col, Divider, Row } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { rankViewProperties } from "models/questionnaireAnalytics";

import { Spacer } from "components/Spacer";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

export const TABLE_MIN_HEIGHT = 700;

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  min-height: ${TABLE_MIN_HEIGHT}px;
  padding: 16px;

  .ant-table-thead .ant-table-cell {
    background-color: #f0f2f5;
  }
`;

const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 18px;
  color: #1890ff;
`;

const StyledEyeInvisibleOutlined = styled(EyeInvisibleOutlined)`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-top: 0;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    padding: 0;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Right = styled(Row).attrs({ gutter: 18 })``;

const StyledDivider = styled(Divider)`
  margin: 16px 0 0;
`;

const IconInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const dataSource = [
  {
    iconSource: rankViewProperties.D.iconSrc,
    rank: rankViewProperties.D.rank,
    range: "~59",
  },
  {
    iconSource: rankViewProperties.C.iconSrc,
    rank: rankViewProperties.C.rank,
    range: "60~69",
  },
  {
    iconSource: rankViewProperties.B.iconSrc,
    rank: rankViewProperties.B.rank,
    range: "70~79",
  },
  {
    iconSource: rankViewProperties.A.iconSrc,
    rank: rankViewProperties.A.rank,
    range: "80~89",
  },
  {
    iconSource: rankViewProperties.S.iconSrc,
    rank: rankViewProperties.S.rank,
    range: "90~100",
  },
] as const;

type Props = {
  onComparisonButtonClick: () => void;
  isComparisonVisible: boolean;
  children: ReactNode;
};

export const TableWrapper = memo<Props>(
  ({ onComparisonButtonClick, isComparisonVisible, children }) => {
    const { isDesktop } = useViewport();
    return (
      <Wrapper>
        <Container>
          <Top>
            {!isDesktop && (
              <img
                src="/questionnaire-analytics/rank_barometer.png"
                alt="rank barometer"
                width="100%"
                height="auto"
              />
            )}
            <Left onClick={onComparisonButtonClick}>
              {isComparisonVisible ? <StyledEyeOutlined /> : <StyledEyeInvisibleOutlined />}
              <Spacer size={8} />
              社内平均・ダイニー導入店平均
            </Left>
          </Top>
          {isDesktop && (
            <Right>
              {dataSource.map(({ iconSource, rank, range }, index) => (
                <Col key={index}>
                  <IconInfoContainer>
                    <img src={iconSource} width={18} height={18} alt={`icon ${rank}`} />
                    <Spacer size={8} />
                    {rank}：{range}
                  </IconInfoContainer>
                </Col>
              ))}
            </Right>
          )}
        </Container>
        {!isDesktop && <StyledDivider />}
        {children}
      </Wrapper>
    );
  },
);
