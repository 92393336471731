import React, { memo } from "react";
import { Select } from "antd";
import { taxRates } from "models/taxRate";

import { FormItemProps } from "components/antd/Form";

import { AddPlanFormItem } from "../useAddPlanForm";

const { Option } = Select;

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const TaxRateField = memo<Props>(({ disabled, ...props }) => (
  <AddPlanFormItem label="税率" name="taxRate" {...props}>
    <Select<string> disabled={disabled}>
      {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
        <Option key={taxRateKey} value={taxRate}>
          {taxRate * 100}%
        </Option>
      ))}
    </Select>
  </AddPlanFormItem>
));
