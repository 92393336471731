import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCorporationAnalyticsSetting = gql`
    query GetCorporationAnalyticsSetting($corporationId: uuid!) {
  analyticsSetting(where: {corporationId: {_eq: $corporationId}}, limit: 1) {
    id
    displayTaxIncluded
  }
}
    `;
export const AnalyticsSettingInsertSetting = gql`
    mutation AnalyticsSettingInsertSetting($input: analyticsSetting_insert_input!) {
  insert_analyticsSetting_one(object: $input) {
    id
  }
}
    `;
export const AnalyticsSettingUpdateSetting = gql`
    mutation AnalyticsSettingUpdateSetting($id: uuid!, $input: analyticsSetting_set_input!) {
  update_analyticsSetting_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
  }
}
    `;
export type GetCorporationAnalyticsSettingQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
}>;


export type GetCorporationAnalyticsSettingQuery = (
  { __typename?: 'query_root' }
  & { analyticsSetting: Array<(
    { __typename?: 'analyticsSetting' }
    & Pick<Types.AnalyticsSetting, 'id' | 'displayTaxIncluded'>
  )> }
);

export type AnalyticsSettingInsertSettingMutationVariables = Types.Exact<{
  input: Types.AnalyticsSettingInsertInput;
}>;


export type AnalyticsSettingInsertSettingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_analyticsSetting_one?: Types.Maybe<(
    { __typename?: 'analyticsSetting' }
    & Pick<Types.AnalyticsSetting, 'id'>
  )> }
);

export type AnalyticsSettingUpdateSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  input: Types.AnalyticsSettingSetInput;
}>;


export type AnalyticsSettingUpdateSettingMutation = (
  { __typename?: 'mutation_root' }
  & { update_analyticsSetting_by_pk?: Types.Maybe<(
    { __typename?: 'analyticsSetting' }
    & Pick<Types.AnalyticsSetting, 'id'>
  )> }
);


export const GetCorporationAnalyticsSettingDocument = gql`
    query GetCorporationAnalyticsSetting($corporationId: uuid!) {
  analyticsSetting(where: {corporationId: {_eq: $corporationId}}, limit: 1) {
    id
    displayTaxIncluded
  }
}
    `;

/**
 * __useGetCorporationAnalyticsSettingQuery__
 *
 * To run a query within a React component, call `useGetCorporationAnalyticsSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorporationAnalyticsSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorporationAnalyticsSettingQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *   },
 * });
 */
export function useGetCorporationAnalyticsSettingQuery(baseOptions: Apollo.QueryHookOptions<GetCorporationAnalyticsSettingQuery, GetCorporationAnalyticsSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCorporationAnalyticsSettingQuery, GetCorporationAnalyticsSettingQueryVariables>(GetCorporationAnalyticsSettingDocument, options);
      }
export function useGetCorporationAnalyticsSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCorporationAnalyticsSettingQuery, GetCorporationAnalyticsSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCorporationAnalyticsSettingQuery, GetCorporationAnalyticsSettingQueryVariables>(GetCorporationAnalyticsSettingDocument, options);
        }
export type GetCorporationAnalyticsSettingQueryHookResult = ReturnType<typeof useGetCorporationAnalyticsSettingQuery>;
export type GetCorporationAnalyticsSettingLazyQueryHookResult = ReturnType<typeof useGetCorporationAnalyticsSettingLazyQuery>;
export type GetCorporationAnalyticsSettingQueryResult = Apollo.QueryResult<GetCorporationAnalyticsSettingQuery, GetCorporationAnalyticsSettingQueryVariables>;
export const AnalyticsSettingInsertSettingDocument = gql`
    mutation AnalyticsSettingInsertSetting($input: analyticsSetting_insert_input!) {
  insert_analyticsSetting_one(object: $input) {
    id
  }
}
    `;
export type AnalyticsSettingInsertSettingMutationFn = Apollo.MutationFunction<AnalyticsSettingInsertSettingMutation, AnalyticsSettingInsertSettingMutationVariables>;

/**
 * __useAnalyticsSettingInsertSettingMutation__
 *
 * To run a mutation, you first call `useAnalyticsSettingInsertSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSettingInsertSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsSettingInsertSettingMutation, { data, loading, error }] = useAnalyticsSettingInsertSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsSettingInsertSettingMutation(baseOptions?: Apollo.MutationHookOptions<AnalyticsSettingInsertSettingMutation, AnalyticsSettingInsertSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnalyticsSettingInsertSettingMutation, AnalyticsSettingInsertSettingMutationVariables>(AnalyticsSettingInsertSettingDocument, options);
      }
export type AnalyticsSettingInsertSettingMutationHookResult = ReturnType<typeof useAnalyticsSettingInsertSettingMutation>;
export type AnalyticsSettingInsertSettingMutationResult = Apollo.MutationResult<AnalyticsSettingInsertSettingMutation>;
export type AnalyticsSettingInsertSettingMutationOptions = Apollo.BaseMutationOptions<AnalyticsSettingInsertSettingMutation, AnalyticsSettingInsertSettingMutationVariables>;
export const AnalyticsSettingUpdateSettingDocument = gql`
    mutation AnalyticsSettingUpdateSetting($id: uuid!, $input: analyticsSetting_set_input!) {
  update_analyticsSetting_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
  }
}
    `;
export type AnalyticsSettingUpdateSettingMutationFn = Apollo.MutationFunction<AnalyticsSettingUpdateSettingMutation, AnalyticsSettingUpdateSettingMutationVariables>;

/**
 * __useAnalyticsSettingUpdateSettingMutation__
 *
 * To run a mutation, you first call `useAnalyticsSettingUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsSettingUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyticsSettingUpdateSettingMutation, { data, loading, error }] = useAnalyticsSettingUpdateSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyticsSettingUpdateSettingMutation(baseOptions?: Apollo.MutationHookOptions<AnalyticsSettingUpdateSettingMutation, AnalyticsSettingUpdateSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnalyticsSettingUpdateSettingMutation, AnalyticsSettingUpdateSettingMutationVariables>(AnalyticsSettingUpdateSettingDocument, options);
      }
export type AnalyticsSettingUpdateSettingMutationHookResult = ReturnType<typeof useAnalyticsSettingUpdateSettingMutation>;
export type AnalyticsSettingUpdateSettingMutationResult = Apollo.MutationResult<AnalyticsSettingUpdateSettingMutation>;
export type AnalyticsSettingUpdateSettingMutationOptions = Apollo.BaseMutationOptions<AnalyticsSettingUpdateSettingMutation, AnalyticsSettingUpdateSettingMutationVariables>;