import React, { memo, useMemo } from "react";
import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { getTaxMethodName } from "models/taxMethod";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";

import { CompulsoryAppetizerConfig } from "../types";
import { TimeSelectPath } from "..";

type Props = {
  compulsoryAppetizerConfig: CompulsoryAppetizerConfig | null;
  onDeleteCompulsoryAppetizerConfig: () => void;
};

export const CompulsoryAppetizerConfigTable = memo<Props>(
  ({ compulsoryAppetizerConfig, onDeleteCompulsoryAppetizerConfig }) => {
    const columns: ColumnsType<CompulsoryAppetizerConfig> = useMemo(
      () => [
        {
          title: "商品名",
          render: ({ menu: { name } }: CompulsoryAppetizerConfig) => name,
          width: 200,
        },
        {
          title: "カテゴリ名",
          width: 200,
          render({ menu: { categoryMenus } }: CompulsoryAppetizerConfig) {
            return (
              <>
                {categoryMenus.map(({ category }) => (
                  <Tag key={category.categoryId}>{category.name}</Tag>
                ))}
              </>
            );
          },
        },
        {
          title: "価格",
          width: 150,
          render: ({ menu: { price, openPrice, taxMethod } }: CompulsoryAppetizerConfig) =>
            openPrice ? "フリー" : `${price.toLocaleString()}円 (${getTaxMethodName(taxMethod)})`,
        },
        {
          title: "開始時間",
          dataIndex: "autoOrderStartTime",
          width: 150,
          render: (autoOrderStartTime: string) =>
            dayjs(autoOrderStartTime, "HH:mm:ss").format("HH:mm"),
        },
        {
          title: "終了時間",
          dataIndex: "autoOrderEndTime",
          width: 150,
          render: (autoOrderEndTime: string) => dayjs(autoOrderEndTime, "HH:mm:ss").format("HH:mm"),
        },
        {
          title: "",
          align: "center",
          width: 60,
          render: () => (
            <IconLink to={TimeSelectPath}>
              <EditIcon />
            </IconLink>
          ),
        },
        {
          title: "",
          align: "center",
          width: 60,
          render: () => (
            <Button onClick={onDeleteCompulsoryAppetizerConfig}>
              <DeleteIcon />
            </Button>
          ),
        },
      ],
      [onDeleteCompulsoryAppetizerConfig],
    );

    return (
      <Table
        rowKey="menuId"
        columns={columns}
        dataSource={compulsoryAppetizerConfig ? [compulsoryAppetizerConfig] : []}
        bordered
        pagination={false}
      />
    );
  },
);
