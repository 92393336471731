import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiGiftsGetMenus = gql`
    query PikaichiGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const PikaichiGiftsGetShops = gql`
    query PikaichiGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const PikaichiGiftsDeletePikaichiMenu = gql`
    mutation PikaichiGiftsDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiGiftsGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiGiftsGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'menuId' | 'name'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { option: (
        { __typename?: 'option' }
        & Pick<Types.Option, 'optionId' | 'name'>
      ) }
    )>, pikaichiMenuMenus: Array<(
      { __typename?: 'pikaichiMenuMenu' }
      & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId'>
      & { menuId: Types.PikaichiMenuMenu['_menuId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type PikaichiGiftsGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiGiftsGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type PikaichiGiftsDeletePikaichiMenuMutationVariables = Types.Exact<{
  pikaichiMenuId: Types.Scalars['uuid'];
}>;


export type PikaichiGiftsDeletePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_pikaichiMenu_by_pk?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )> }
);


export const PikaichiGiftsGetMenusDocument = gql`
    query PikaichiGiftsGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, giftMeta: {}}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    menuId
    name
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    menuOptions(
      where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_optionId: asc}]
    ) {
      option {
        optionId
        name
      }
    }
    pikaichiMenuMenus {
      pikaichiMenuId
      menuId: _menuId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __usePikaichiGiftsGetMenusQuery__
 *
 * To run a query within a React component, call `usePikaichiGiftsGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiGiftsGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiGiftsGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiGiftsGetMenusQuery(baseOptions: Apollo.QueryHookOptions<PikaichiGiftsGetMenusQuery, PikaichiGiftsGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiGiftsGetMenusQuery, PikaichiGiftsGetMenusQueryVariables>(PikaichiGiftsGetMenusDocument, options);
      }
export function usePikaichiGiftsGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiGiftsGetMenusQuery, PikaichiGiftsGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiGiftsGetMenusQuery, PikaichiGiftsGetMenusQueryVariables>(PikaichiGiftsGetMenusDocument, options);
        }
export type PikaichiGiftsGetMenusQueryHookResult = ReturnType<typeof usePikaichiGiftsGetMenusQuery>;
export type PikaichiGiftsGetMenusLazyQueryHookResult = ReturnType<typeof usePikaichiGiftsGetMenusLazyQuery>;
export type PikaichiGiftsGetMenusQueryResult = Apollo.QueryResult<PikaichiGiftsGetMenusQuery, PikaichiGiftsGetMenusQueryVariables>;
export const PikaichiGiftsGetShopsDocument = gql`
    query PikaichiGiftsGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __usePikaichiGiftsGetShopsQuery__
 *
 * To run a query within a React component, call `usePikaichiGiftsGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiGiftsGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiGiftsGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiGiftsGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PikaichiGiftsGetShopsQuery, PikaichiGiftsGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiGiftsGetShopsQuery, PikaichiGiftsGetShopsQueryVariables>(PikaichiGiftsGetShopsDocument, options);
      }
export function usePikaichiGiftsGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiGiftsGetShopsQuery, PikaichiGiftsGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiGiftsGetShopsQuery, PikaichiGiftsGetShopsQueryVariables>(PikaichiGiftsGetShopsDocument, options);
        }
export type PikaichiGiftsGetShopsQueryHookResult = ReturnType<typeof usePikaichiGiftsGetShopsQuery>;
export type PikaichiGiftsGetShopsLazyQueryHookResult = ReturnType<typeof usePikaichiGiftsGetShopsLazyQuery>;
export type PikaichiGiftsGetShopsQueryResult = Apollo.QueryResult<PikaichiGiftsGetShopsQuery, PikaichiGiftsGetShopsQueryVariables>;
export const PikaichiGiftsDeletePikaichiMenuDocument = gql`
    mutation PikaichiGiftsDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiGiftsDeletePikaichiMenuMutationFn = Apollo.MutationFunction<PikaichiGiftsDeletePikaichiMenuMutation, PikaichiGiftsDeletePikaichiMenuMutationVariables>;

/**
 * __usePikaichiGiftsDeletePikaichiMenuMutation__
 *
 * To run a mutation, you first call `usePikaichiGiftsDeletePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePikaichiGiftsDeletePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pikaichiGiftsDeletePikaichiMenuMutation, { data, loading, error }] = usePikaichiGiftsDeletePikaichiMenuMutation({
 *   variables: {
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *   },
 * });
 */
export function usePikaichiGiftsDeletePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<PikaichiGiftsDeletePikaichiMenuMutation, PikaichiGiftsDeletePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PikaichiGiftsDeletePikaichiMenuMutation, PikaichiGiftsDeletePikaichiMenuMutationVariables>(PikaichiGiftsDeletePikaichiMenuDocument, options);
      }
export type PikaichiGiftsDeletePikaichiMenuMutationHookResult = ReturnType<typeof usePikaichiGiftsDeletePikaichiMenuMutation>;
export type PikaichiGiftsDeletePikaichiMenuMutationResult = Apollo.MutationResult<PikaichiGiftsDeletePikaichiMenuMutation>;
export type PikaichiGiftsDeletePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<PikaichiGiftsDeletePikaichiMenuMutation, PikaichiGiftsDeletePikaichiMenuMutationVariables>;