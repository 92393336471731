import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AccountingHistoriesGetOnSitePayments = gql`
    query AccountingHistoriesGetOnSitePayments($shopId: uuid!, $targetDate: date!) {
  closeCashRegister(
    where: {shopId: {_eq: $shopId}, targetDate: {_eq: $targetDate}, excludedFromAggregation: {_eq: false}}
  ) {
    id
    onSitePayments(order_by: {createdAt: asc}) {
      onSitePaymentId
      localAccountingSlipId
      createdAt
      voidedAt
      onSitePaymentDetails {
        netPrice
        onSitePaymentDetailType
        onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype {
          label
        }
      }
      onSitePaymentDiscounts {
        amount
        onSitePaymentDiscountType
        onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype {
          label
        }
      }
      onSitePaymentTableUsers {
        tableUser {
          table {
            tableId
            name
            priority
          }
        }
      }
    }
  }
}
    `;
export const AccountingHistoriesGetShops = gql`
    query AccountingHistoriesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const AccountingHistoriesGetOnSitePaymentTypes = gql`
    query AccountingHistoriesGetOnSitePaymentTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
}
    `;
export const AccountingHistoriesGetTables = gql`
    query AccountingHistoriesGetTables($shopId: uuid!) {
  table(
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
    where: {shopId: {_eq: $shopId}}
  ) {
    tableId
    name
    priority
  }
}
    `;
export type AccountingHistoriesGetOnSitePaymentsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  targetDate: Types.Scalars['date'];
}>;


export type AccountingHistoriesGetOnSitePaymentsQuery = (
  { __typename?: 'query_root' }
  & { closeCashRegister: Array<(
    { __typename?: 'closeCashRegister' }
    & Pick<Types.CloseCashRegister, 'id'>
    & { onSitePayments: Array<(
      { __typename?: 'onSitePayment' }
      & Pick<Types.OnSitePayment, 'onSitePaymentId' | 'localAccountingSlipId' | 'createdAt' | 'voidedAt'>
      & { onSitePaymentDetails: Array<(
        { __typename?: 'onSitePaymentDetail' }
        & Pick<Types.OnSitePaymentDetail, 'netPrice' | 'onSitePaymentDetailType'>
        & { onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype: (
          { __typename?: 'onSitePaymentDetailType' }
          & Pick<Types.OnSitePaymentDetailType, 'label'>
        ) }
      )>, onSitePaymentDiscounts: Array<(
        { __typename?: 'onSitePaymentDiscount' }
        & Pick<Types.OnSitePaymentDiscount, 'amount' | 'onSitePaymentDiscountType'>
        & { onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype: (
          { __typename?: 'onSitePaymentDiscountType' }
          & Pick<Types.OnSitePaymentDiscountType, 'label'>
        ) }
      )>, onSitePaymentTableUsers: Array<(
        { __typename?: 'onSitePaymentTableUsers' }
        & { tableUser: (
          { __typename?: 'tableUser' }
          & { table: (
            { __typename?: 'table' }
            & Pick<Types.Table, 'tableId' | 'name' | 'priority'>
          ) }
        ) }
      )> }
    )> }
  )> }
);

export type AccountingHistoriesGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AccountingHistoriesGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type AccountingHistoriesGetOnSitePaymentTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AccountingHistoriesGetOnSitePaymentTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'type' | 'label'>
  )>, onSitePaymentDiscountType: Array<(
    { __typename?: 'onSitePaymentDiscountType' }
    & Pick<Types.OnSitePaymentDiscountType, 'type' | 'label'>
  )> }
);

export type AccountingHistoriesGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AccountingHistoriesGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'tableId' | 'name' | 'priority'>
  )> }
);


export const AccountingHistoriesGetOnSitePaymentsDocument = gql`
    query AccountingHistoriesGetOnSitePayments($shopId: uuid!, $targetDate: date!) {
  closeCashRegister(
    where: {shopId: {_eq: $shopId}, targetDate: {_eq: $targetDate}, excludedFromAggregation: {_eq: false}}
  ) {
    id
    onSitePayments(order_by: {createdAt: asc}) {
      onSitePaymentId
      localAccountingSlipId
      createdAt
      voidedAt
      onSitePaymentDetails {
        netPrice
        onSitePaymentDetailType
        onSitePaymentDetailTypeByCompanyidOnsitepaymentdetailtype {
          label
        }
      }
      onSitePaymentDiscounts {
        amount
        onSitePaymentDiscountType
        onSitePaymentDiscountTypeByCompanyidOnsitepaymentdiscounttype {
          label
        }
      }
      onSitePaymentTableUsers {
        tableUser {
          table {
            tableId
            name
            priority
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAccountingHistoriesGetOnSitePaymentsQuery__
 *
 * To run a query within a React component, call `useAccountingHistoriesGetOnSitePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingHistoriesGetOnSitePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingHistoriesGetOnSitePaymentsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      targetDate: // value for 'targetDate'
 *   },
 * });
 */
export function useAccountingHistoriesGetOnSitePaymentsQuery(baseOptions: Apollo.QueryHookOptions<AccountingHistoriesGetOnSitePaymentsQuery, AccountingHistoriesGetOnSitePaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingHistoriesGetOnSitePaymentsQuery, AccountingHistoriesGetOnSitePaymentsQueryVariables>(AccountingHistoriesGetOnSitePaymentsDocument, options);
      }
export function useAccountingHistoriesGetOnSitePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingHistoriesGetOnSitePaymentsQuery, AccountingHistoriesGetOnSitePaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingHistoriesGetOnSitePaymentsQuery, AccountingHistoriesGetOnSitePaymentsQueryVariables>(AccountingHistoriesGetOnSitePaymentsDocument, options);
        }
export type AccountingHistoriesGetOnSitePaymentsQueryHookResult = ReturnType<typeof useAccountingHistoriesGetOnSitePaymentsQuery>;
export type AccountingHistoriesGetOnSitePaymentsLazyQueryHookResult = ReturnType<typeof useAccountingHistoriesGetOnSitePaymentsLazyQuery>;
export type AccountingHistoriesGetOnSitePaymentsQueryResult = Apollo.QueryResult<AccountingHistoriesGetOnSitePaymentsQuery, AccountingHistoriesGetOnSitePaymentsQueryVariables>;
export const AccountingHistoriesGetShopsDocument = gql`
    query AccountingHistoriesGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useAccountingHistoriesGetShopsQuery__
 *
 * To run a query within a React component, call `useAccountingHistoriesGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingHistoriesGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingHistoriesGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAccountingHistoriesGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AccountingHistoriesGetShopsQuery, AccountingHistoriesGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingHistoriesGetShopsQuery, AccountingHistoriesGetShopsQueryVariables>(AccountingHistoriesGetShopsDocument, options);
      }
export function useAccountingHistoriesGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingHistoriesGetShopsQuery, AccountingHistoriesGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingHistoriesGetShopsQuery, AccountingHistoriesGetShopsQueryVariables>(AccountingHistoriesGetShopsDocument, options);
        }
export type AccountingHistoriesGetShopsQueryHookResult = ReturnType<typeof useAccountingHistoriesGetShopsQuery>;
export type AccountingHistoriesGetShopsLazyQueryHookResult = ReturnType<typeof useAccountingHistoriesGetShopsLazyQuery>;
export type AccountingHistoriesGetShopsQueryResult = Apollo.QueryResult<AccountingHistoriesGetShopsQuery, AccountingHistoriesGetShopsQueryVariables>;
export const AccountingHistoriesGetOnSitePaymentTypesDocument = gql`
    query AccountingHistoriesGetOnSitePaymentTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
  onSitePaymentDiscountType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    type
    label
  }
}
    `;

/**
 * __useAccountingHistoriesGetOnSitePaymentTypesQuery__
 *
 * To run a query within a React component, call `useAccountingHistoriesGetOnSitePaymentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingHistoriesGetOnSitePaymentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingHistoriesGetOnSitePaymentTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAccountingHistoriesGetOnSitePaymentTypesQuery(baseOptions: Apollo.QueryHookOptions<AccountingHistoriesGetOnSitePaymentTypesQuery, AccountingHistoriesGetOnSitePaymentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingHistoriesGetOnSitePaymentTypesQuery, AccountingHistoriesGetOnSitePaymentTypesQueryVariables>(AccountingHistoriesGetOnSitePaymentTypesDocument, options);
      }
export function useAccountingHistoriesGetOnSitePaymentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingHistoriesGetOnSitePaymentTypesQuery, AccountingHistoriesGetOnSitePaymentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingHistoriesGetOnSitePaymentTypesQuery, AccountingHistoriesGetOnSitePaymentTypesQueryVariables>(AccountingHistoriesGetOnSitePaymentTypesDocument, options);
        }
export type AccountingHistoriesGetOnSitePaymentTypesQueryHookResult = ReturnType<typeof useAccountingHistoriesGetOnSitePaymentTypesQuery>;
export type AccountingHistoriesGetOnSitePaymentTypesLazyQueryHookResult = ReturnType<typeof useAccountingHistoriesGetOnSitePaymentTypesLazyQuery>;
export type AccountingHistoriesGetOnSitePaymentTypesQueryResult = Apollo.QueryResult<AccountingHistoriesGetOnSitePaymentTypesQuery, AccountingHistoriesGetOnSitePaymentTypesQueryVariables>;
export const AccountingHistoriesGetTablesDocument = gql`
    query AccountingHistoriesGetTables($shopId: uuid!) {
  table(
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
    where: {shopId: {_eq: $shopId}}
  ) {
    tableId
    name
    priority
  }
}
    `;

/**
 * __useAccountingHistoriesGetTablesQuery__
 *
 * To run a query within a React component, call `useAccountingHistoriesGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingHistoriesGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingHistoriesGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAccountingHistoriesGetTablesQuery(baseOptions: Apollo.QueryHookOptions<AccountingHistoriesGetTablesQuery, AccountingHistoriesGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingHistoriesGetTablesQuery, AccountingHistoriesGetTablesQueryVariables>(AccountingHistoriesGetTablesDocument, options);
      }
export function useAccountingHistoriesGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingHistoriesGetTablesQuery, AccountingHistoriesGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingHistoriesGetTablesQuery, AccountingHistoriesGetTablesQueryVariables>(AccountingHistoriesGetTablesDocument, options);
        }
export type AccountingHistoriesGetTablesQueryHookResult = ReturnType<typeof useAccountingHistoriesGetTablesQuery>;
export type AccountingHistoriesGetTablesLazyQueryHookResult = ReturnType<typeof useAccountingHistoriesGetTablesLazyQuery>;
export type AccountingHistoriesGetTablesQueryResult = Apollo.QueryResult<AccountingHistoriesGetTablesQuery, AccountingHistoriesGetTablesQueryVariables>;