import dayjs from "dayjs";
import { addHours, getHours } from "util/duration";
import { normalizeHoursAndMinutes } from "util/time";

import { OrderableTimeTermDayWeekEnum } from "types/graphql";

export const ExternalOnlineMenuAvailableTimeTermDayWeek = {
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  Holiday: "Holiday",
} as const;

export const ExternalOnlineMenuAvailableTimeTermDayWeekToWord: Record<
  OrderableTimeTermDayWeekEnum,
  string
> = {
  Mon: "月曜日",
  Tue: "火曜日",
  Wed: "水曜日",
  Thu: "木曜日",
  Fri: "金曜日",
  Sat: "土曜日",
  Sun: "日曜日",
  Holiday: "祝日",
};

export const isExternalOnlineMenuAvailableTimeTermDayWeek = (
  arg: string | undefined,
): arg is OrderableTimeTermDayWeekEnum => {
  if (!arg) return false;

  return arg in ExternalOnlineMenuAvailableTimeTermDayWeekToWord;
};

export const formatExternalOnlineMenuAvailableTimeTermDayWeek = (arg: string) => {
  if (isExternalOnlineMenuAvailableTimeTermDayWeek(arg)) {
    return ExternalOnlineMenuAvailableTimeTermDayWeekToWord[arg];
  }

  return "";
};

export const formatExternalOnlineMenuAvailableTimeTermTime = (time: string) => {
  const [hour, minute] = time.split(":");

  return `${hour}:${minute}`;
};

export const findSortKeyByDayWeek = (dayWeek: OrderableTimeTermDayWeekEnum) =>
  Object.keys(ExternalOnlineMenuAvailableTimeTermDayWeek).findIndex((v) => v === dayWeek);

export const isValidExternalOnlineMenuAvailableTimeDuration = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const ExternalOnlineMenuAvailableTimeStart = startTime.isBefore(baseTime)
    ? startTime.add(24, "hours")
    : startTime;
  const ExternalOnlineMenuAvailableTimeEnd = endTime.isSameOrBefore(baseTime)
    ? endTime.add(24, "hours")
    : endTime;

  return ExternalOnlineMenuAvailableTimeStart.isBefore(ExternalOnlineMenuAvailableTimeEnd);
};

export const formatExternalOnlineMenuAvailableTimeWithChangeDateTime = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const ExternalOnlineMenuAvailableTimeStart = startTime.isBefore(baseTime)
    ? addHours(startTime.format("HH:mm"), 24)
    : startTime.format("HH:mm");
  const ExternalOnlineMenuAvailableTimeEnd = endTime.isSameOrBefore(baseTime)
    ? addHours(endTime.format("HH:mm"), 24)
    : endTime.format("HH:mm");

  return { start: ExternalOnlineMenuAvailableTimeStart, end: ExternalOnlineMenuAvailableTimeEnd };
};
