import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";

import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { FormContent } from "components/Template/FormTemplate";

import { FeatureFlagTable } from "./FeatureFlagTable";
import {
  useFeatureFlagsGetFeatureFlagShopsQuery,
  useFeatureFlagsGetFeatureFlagsQuery,
} from "./queries";
import { useOnSwitchChange } from "./useOnSwitchChange";

export const FeatureFlags = () => {
  const { id: shopId = "" } = useParams<{ id: string }>();

  const {
    data: getFeatureFlagShopsData,
    loading: loadingFeatureFlagShops,
    error: getFeatureFlagShopsError,
    refetch: refetchFeatureFlagShops,
  } = useFeatureFlagsGetFeatureFlagShopsQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shop = getFeatureFlagShopsData?.shop[0];
  const featureFlagShops = shop?.featureFlagShops ?? [];

  const {
    data: getFeatureFlagsData,
    loading: loadingFeatureFlags,
    error: getFeatureFlagsError,
  } = useFeatureFlagsGetFeatureFlagsQuery();

  const featureFlags = getFeatureFlagsData?.featureFlag ?? [];

  const { onSwitchChange } = useOnSwitchChange({ shopId, refetchFeatureFlagShops });

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const shouldShowError = getFeatureFlagShopsError || getFeatureFlagsError;
  const loading = loadingFeatureFlagShops || loadingFeatureFlags;

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <FormContent>
          <FeatureFlagTable
            featureFlagShops={featureFlagShops}
            featureFlags={featureFlags}
            onSwitchChange={onSwitchChange}
            loading={loading}
          />
        </FormContent>
      )}
    </DashboardLayout>
  );
};
