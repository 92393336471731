import React, { memo } from "react";
import styled from "styled-components";
import { Radio, Select, Space } from "antd";
import { parsePercentage } from "util/input/parsePercentage";

import { withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import {
  AddConnectGameConfigFormItem,
  MaxWinningPercentageType,
} from "../useAddConnectGameConfigForm";

const { Option } = Select;

const maxWinningPercentagesForSelect = [
  {
    value: 30,
    name: "おすすめ (30%)",
  },
  {
    value: 70,
    name: "当たりやすい (70%)",
  },
  {
    value: 10,
    name: "当たりづらい (10%)",
  },
];

const StyledSelect = styled(Select)`
  min-width: 160px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 90px;
`;

export const MaxWinningPercentageField = memo(() => (
  <AddConnectGameConfigFormItem label="当選確率" name="maxWinningPercentageType" required>
    <Radio.Group>
      <Space direction="vertical">
        <Radio value="select">
          <AddConnectGameConfigFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ maxWinningPercentageType }) => [
              maxWinningPercentageType,
            ])}
          >
            {({ getFieldValue }) => {
              const disabled =
                (getFieldValue("maxWinningPercentageType") as MaxWinningPercentageType) !==
                "select";
              return (
                <AddConnectGameConfigFormItem
                  noStyle
                  name="selectedMaxWinningPercentage"
                  rules={[{ required: !disabled, message: "当選確率を選択してください" }]}
                >
                  <StyledSelect
                    placeholder="選択してください"
                    disabled={disabled}
                    onClick={(e) => e.preventDefault()}
                  >
                    {maxWinningPercentagesForSelect.map(({ value, name }) => (
                      <Option key={value} value={value}>
                        {name}
                      </Option>
                    ))}
                  </StyledSelect>
                </AddConnectGameConfigFormItem>
              );
            }}
          </AddConnectGameConfigFormItem.NonProperty>
        </Radio>
        <Radio value="custom">
          <AddConnectGameConfigFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ maxWinningPercentageType }) => [
              maxWinningPercentageType,
            ])}
          >
            {({ getFieldValue }) => {
              const disabled =
                (getFieldValue("maxWinningPercentageType") as MaxWinningPercentageType) !==
                "custom";
              return (
                <AddConnectGameConfigFormItem
                  noStyle
                  name="enteredMaxWinningPercentage"
                  rules={[{ required: !disabled, message: "当選確率を入力してください" }]}
                >
                  <StyledInputNumber
                    formatter={(value) => `${value}%`}
                    parser={parsePercentage}
                    min={1}
                    max={100}
                    placeholder="0%"
                    disabled={disabled}
                  />
                </AddConnectGameConfigFormItem>
              );
            }}
          </AddConnectGameConfigFormItem.NonProperty>
        </Radio>
      </Space>
    </Radio.Group>
  </AddConnectGameConfigFormItem>
));
