import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoice = gql`
    query PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}, planOption: {archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    planOption {
      plan {
        companyId
      }
    }
    pikaichiMenuPlanChoices {
      pikaichiMenuId
      planChoiceId: _planChoiceId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const PikaichiPlanOptionsUpdatePikaichiMenu = gql`
    mutation PikaichiPlanOptionsUpdatePikaichiMenu($planChoiceId: uuid!, $_planChoiceId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlanChoice_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planChoiceId: $planChoiceId, _planChoiceId: $_planChoiceId}
    on_conflict: {constraint: pikaichiMenuPlanChoice_pikaichiMenuId_planChoiceId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planChoiceId
    _planChoiceId
  }
}
    `;
export type PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables = Types.Exact<{
  planChoiceId: Types.Scalars['Int'];
}>;


export type PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery = (
  { __typename?: 'query_root' }
  & { planChoice: Array<(
    { __typename?: 'planChoice' }
    & Pick<Types.PlanChoice, 'id' | 'planChoiceId' | 'name'>
    & { planOption: (
      { __typename?: 'planOption' }
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'companyId'>
      ) }
    ), pikaichiMenuPlanChoices: Array<(
      { __typename?: 'pikaichiMenuPlanChoice' }
      & Pick<Types.PikaichiMenuPlanChoice, 'pikaichiMenuId'>
      & { planChoiceId: Types.PikaichiMenuPlanChoice['_planChoiceId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type PikaichiPlanOptionsUpdatePikaichiMenuMutationVariables = Types.Exact<{
  planChoiceId: Types.Scalars['uuid'];
  _planChoiceId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
}>;


export type PikaichiPlanOptionsUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuPlanChoice_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlanChoice' }
    & Pick<Types.PikaichiMenuPlanChoice, 'pikaichiMenuId' | 'planChoiceId' | '_planChoiceId'>
  )> }
);


export const PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument = gql`
    query PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoice($planChoiceId: Int!) {
  planChoice(
    where: {planChoiceId: {_eq: $planChoiceId}, archivedAt: {_is_null: true}, planOption: {archivedAt: {_is_null: true}, plan: {archivedAt: {_is_null: true}}}}
    limit: 1
  ) {
    id
    planChoiceId
    name
    planOption {
      plan {
        companyId
      }
    }
    pikaichiMenuPlanChoices {
      pikaichiMenuId
      planChoiceId: _planChoiceId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery__
 *
 * To run a query within a React component, call `usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *   },
 * });
 */
export function usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery(baseOptions: Apollo.QueryHookOptions<PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
      }
export function usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>(PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceDocument, options);
        }
export type PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryHookResult = ReturnType<typeof usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery>;
export type PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQueryHookResult = ReturnType<typeof usePikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceLazyQuery>;
export type PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryResult = Apollo.QueryResult<PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQuery, PikaichiPlanOptionsEditPlanChoiceModalGetPlanChoiceQueryVariables>;
export const PikaichiPlanOptionsUpdatePikaichiMenuDocument = gql`
    mutation PikaichiPlanOptionsUpdatePikaichiMenu($planChoiceId: uuid!, $_planChoiceId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuPlanChoice_one(
    object: {pikaichiMenuId: $pikaichiMenuId, planChoiceId: $planChoiceId, _planChoiceId: $_planChoiceId}
    on_conflict: {constraint: pikaichiMenuPlanChoice_pikaichiMenuId_planChoiceId_key, update_columns: []}
  ) {
    pikaichiMenuId
    planChoiceId
    _planChoiceId
  }
}
    `;
export type PikaichiPlanOptionsUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<PikaichiPlanOptionsUpdatePikaichiMenuMutation, PikaichiPlanOptionsUpdatePikaichiMenuMutationVariables>;

/**
 * __usePikaichiPlanOptionsUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `usePikaichiPlanOptionsUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePikaichiPlanOptionsUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pikaichiPlanOptionsUpdatePikaichiMenuMutation, { data, loading, error }] = usePikaichiPlanOptionsUpdatePikaichiMenuMutation({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *      _planChoiceId: // value for '_planChoiceId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *   },
 * });
 */
export function usePikaichiPlanOptionsUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<PikaichiPlanOptionsUpdatePikaichiMenuMutation, PikaichiPlanOptionsUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PikaichiPlanOptionsUpdatePikaichiMenuMutation, PikaichiPlanOptionsUpdatePikaichiMenuMutationVariables>(PikaichiPlanOptionsUpdatePikaichiMenuDocument, options);
      }
export type PikaichiPlanOptionsUpdatePikaichiMenuMutationHookResult = ReturnType<typeof usePikaichiPlanOptionsUpdatePikaichiMenuMutation>;
export type PikaichiPlanOptionsUpdatePikaichiMenuMutationResult = Apollo.MutationResult<PikaichiPlanOptionsUpdatePikaichiMenuMutation>;
export type PikaichiPlanOptionsUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<PikaichiPlanOptionsUpdatePikaichiMenuMutation, PikaichiPlanOptionsUpdatePikaichiMenuMutationVariables>;