import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";

import { EditLineOfficialAccountForm } from "./EditLineOfficialAccountForm";
import { useEdiLineOfficialAccountGetLineOfficialAccountQuery } from "./queries";

export const EditLineOfficialAccount = () => {
  const { id } = useParams<{ id: string }>();
  const lineOfficialAccountId = Number(id);

  const { data, loading, error } = useEdiLineOfficialAccountGetLineOfficialAccountQuery(
    lineOfficialAccountId !== undefined ? { variables: { lineOfficialAccountId } } : { skip: true },
  );
  const lineOfficialAccount = data?.lineOfficialAccount[0];

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={lineOfficialAccount?.lineId}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "LINE公式アカウント" }],
      }}
    >
      <PageHeader title={lineOfficialAccount?.lineId} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {lineOfficialAccount && (
        <EditLineOfficialAccountForm lineOfficialAccount={lineOfficialAccount} />
      )}
    </DashboardLayout>
  );
};
