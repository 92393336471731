import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const KitchenSlipTablePrintingGetTables = gql`
    query KitchenSlipTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    name
    tableId
    roleTablesTables {
      roleRoleId
      tableTableId
    }
  }
  role(where: {shopId: {_eq: $shopId}}) {
    roleId
    name
  }
}
    `;
export const KitchenSlipTablePrintingDeleteRoleTablesTable = gql`
    mutation KitchenSlipTablePrintingDeleteRoleTablesTable($roleId: Int!, $tableId: Int!) {
  delete_roleTablesTable(
    where: {roleRoleId: {_eq: $roleId}, tableTableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export const KitchenSlipTablePrintingInsertRoleTablesTable = gql`
    mutation KitchenSlipTablePrintingInsertRoleTablesTable($input: roleTablesTable_insert_input!) {
  insert_roleTablesTable_one(object: $input) {
    __typename
  }
}
    `;
export type KitchenSlipTablePrintingGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type KitchenSlipTablePrintingGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'name' | 'tableId'>
    & { roleTablesTables: Array<(
      { __typename?: 'roleTablesTable' }
      & Pick<Types.RoleTablesTable, 'roleRoleId' | 'tableTableId'>
    )> }
  )>, role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'roleId' | 'name'>
  )> }
);

export type KitchenSlipTablePrintingDeleteRoleTablesTableMutationVariables = Types.Exact<{
  roleId: Types.Scalars['Int'];
  tableId: Types.Scalars['Int'];
}>;


export type KitchenSlipTablePrintingDeleteRoleTablesTableMutation = (
  { __typename?: 'mutation_root' }
  & { delete_roleTablesTable?: Types.Maybe<(
    { __typename?: 'roleTablesTable_mutation_response' }
    & Pick<Types.RoleTablesTableMutationResponse, 'affected_rows'>
  )> }
);

export type KitchenSlipTablePrintingInsertRoleTablesTableMutationVariables = Types.Exact<{
  input: Types.RoleTablesTableInsertInput;
}>;


export type KitchenSlipTablePrintingInsertRoleTablesTableMutation = (
  { __typename?: 'mutation_root' }
  & { insert_roleTablesTable_one?: Types.Maybe<{ __typename: 'roleTablesTable' }> }
);


export const KitchenSlipTablePrintingGetTablesDocument = gql`
    query KitchenSlipTablePrintingGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    name
    tableId
    roleTablesTables {
      roleRoleId
      tableTableId
    }
  }
  role(where: {shopId: {_eq: $shopId}}) {
    roleId
    name
  }
}
    `;

/**
 * __useKitchenSlipTablePrintingGetTablesQuery__
 *
 * To run a query within a React component, call `useKitchenSlipTablePrintingGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenSlipTablePrintingGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenSlipTablePrintingGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useKitchenSlipTablePrintingGetTablesQuery(baseOptions: Apollo.QueryHookOptions<KitchenSlipTablePrintingGetTablesQuery, KitchenSlipTablePrintingGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenSlipTablePrintingGetTablesQuery, KitchenSlipTablePrintingGetTablesQueryVariables>(KitchenSlipTablePrintingGetTablesDocument, options);
      }
export function useKitchenSlipTablePrintingGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenSlipTablePrintingGetTablesQuery, KitchenSlipTablePrintingGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenSlipTablePrintingGetTablesQuery, KitchenSlipTablePrintingGetTablesQueryVariables>(KitchenSlipTablePrintingGetTablesDocument, options);
        }
export type KitchenSlipTablePrintingGetTablesQueryHookResult = ReturnType<typeof useKitchenSlipTablePrintingGetTablesQuery>;
export type KitchenSlipTablePrintingGetTablesLazyQueryHookResult = ReturnType<typeof useKitchenSlipTablePrintingGetTablesLazyQuery>;
export type KitchenSlipTablePrintingGetTablesQueryResult = Apollo.QueryResult<KitchenSlipTablePrintingGetTablesQuery, KitchenSlipTablePrintingGetTablesQueryVariables>;
export const KitchenSlipTablePrintingDeleteRoleTablesTableDocument = gql`
    mutation KitchenSlipTablePrintingDeleteRoleTablesTable($roleId: Int!, $tableId: Int!) {
  delete_roleTablesTable(
    where: {roleRoleId: {_eq: $roleId}, tableTableId: {_eq: $tableId}}
  ) {
    affected_rows
  }
}
    `;
export type KitchenSlipTablePrintingDeleteRoleTablesTableMutationFn = Apollo.MutationFunction<KitchenSlipTablePrintingDeleteRoleTablesTableMutation, KitchenSlipTablePrintingDeleteRoleTablesTableMutationVariables>;

/**
 * __useKitchenSlipTablePrintingDeleteRoleTablesTableMutation__
 *
 * To run a mutation, you first call `useKitchenSlipTablePrintingDeleteRoleTablesTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitchenSlipTablePrintingDeleteRoleTablesTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitchenSlipTablePrintingDeleteRoleTablesTableMutation, { data, loading, error }] = useKitchenSlipTablePrintingDeleteRoleTablesTableMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      tableId: // value for 'tableId'
 *   },
 * });
 */
export function useKitchenSlipTablePrintingDeleteRoleTablesTableMutation(baseOptions?: Apollo.MutationHookOptions<KitchenSlipTablePrintingDeleteRoleTablesTableMutation, KitchenSlipTablePrintingDeleteRoleTablesTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KitchenSlipTablePrintingDeleteRoleTablesTableMutation, KitchenSlipTablePrintingDeleteRoleTablesTableMutationVariables>(KitchenSlipTablePrintingDeleteRoleTablesTableDocument, options);
      }
export type KitchenSlipTablePrintingDeleteRoleTablesTableMutationHookResult = ReturnType<typeof useKitchenSlipTablePrintingDeleteRoleTablesTableMutation>;
export type KitchenSlipTablePrintingDeleteRoleTablesTableMutationResult = Apollo.MutationResult<KitchenSlipTablePrintingDeleteRoleTablesTableMutation>;
export type KitchenSlipTablePrintingDeleteRoleTablesTableMutationOptions = Apollo.BaseMutationOptions<KitchenSlipTablePrintingDeleteRoleTablesTableMutation, KitchenSlipTablePrintingDeleteRoleTablesTableMutationVariables>;
export const KitchenSlipTablePrintingInsertRoleTablesTableDocument = gql`
    mutation KitchenSlipTablePrintingInsertRoleTablesTable($input: roleTablesTable_insert_input!) {
  insert_roleTablesTable_one(object: $input) {
    __typename
  }
}
    `;
export type KitchenSlipTablePrintingInsertRoleTablesTableMutationFn = Apollo.MutationFunction<KitchenSlipTablePrintingInsertRoleTablesTableMutation, KitchenSlipTablePrintingInsertRoleTablesTableMutationVariables>;

/**
 * __useKitchenSlipTablePrintingInsertRoleTablesTableMutation__
 *
 * To run a mutation, you first call `useKitchenSlipTablePrintingInsertRoleTablesTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKitchenSlipTablePrintingInsertRoleTablesTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kitchenSlipTablePrintingInsertRoleTablesTableMutation, { data, loading, error }] = useKitchenSlipTablePrintingInsertRoleTablesTableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKitchenSlipTablePrintingInsertRoleTablesTableMutation(baseOptions?: Apollo.MutationHookOptions<KitchenSlipTablePrintingInsertRoleTablesTableMutation, KitchenSlipTablePrintingInsertRoleTablesTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KitchenSlipTablePrintingInsertRoleTablesTableMutation, KitchenSlipTablePrintingInsertRoleTablesTableMutationVariables>(KitchenSlipTablePrintingInsertRoleTablesTableDocument, options);
      }
export type KitchenSlipTablePrintingInsertRoleTablesTableMutationHookResult = ReturnType<typeof useKitchenSlipTablePrintingInsertRoleTablesTableMutation>;
export type KitchenSlipTablePrintingInsertRoleTablesTableMutationResult = Apollo.MutationResult<KitchenSlipTablePrintingInsertRoleTablesTableMutation>;
export type KitchenSlipTablePrintingInsertRoleTablesTableMutationOptions = Apollo.BaseMutationOptions<KitchenSlipTablePrintingInsertRoleTablesTableMutation, KitchenSlipTablePrintingInsertRoleTablesTableMutationVariables>;