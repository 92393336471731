import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { MenusField } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/EditMenuForm/MenusField";
import { useEditMenuForm } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/EditMenuForm/useEditMenuForm";
import { Category } from "pages/KitchenDisplayDisplayTargetMenus/EditKitchenDisplayDisplayTargetMenuModal/types";
import { KdDisplayTarget } from "pages/KitchenDisplayDisplayTargetMenus/types";

type Props = {
  kdDisplayTarget: KdDisplayTarget;
  categories: Category[];
  onChange: ({ menuIds }: { menuIds: number[] }) => void;
};

export const EditMenuForm = memo<Props>(({ kdDisplayTarget, categories, onChange }) => {
  const {
    form,
    change: onValuesChange,
    initialValues,
  } = useEditMenuForm({
    onChange,
    kdDisplayTarget,
  });

  return (
    <Form
      name="menus"
      form={form}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      layout="vertical"
    >
      <MenusField categories={categories} />
    </Form>
  );
});
