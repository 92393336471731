import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const LineOfficialAccount = gql`
    fragment lineOfficialAccount on LineOfficialAccountProfile {
  lineOfficialAccountId
  channelName
  pictureUrl
}
    `;
export const MessageDeliveryFormRoomMessageGetCoupon = gql`
    query MessageDeliveryFormRoomMessageGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    title
    availableFrom
    availableUntil
    availableDays
    description
    content
    imageUrl
  }
}
    `;
export const MessageDeliveryFormRoomMessageGetQuestionnaireConfig = gql`
    query MessageDeliveryFormRoomMessageGetQuestionnaireConfig($id: uuid!) {
  questionnaireConfig_by_pk(id: $id) {
    imageUrl
  }
}
    `;
export const MessageDeliveryFormGetLineOfficialAccounts = gql`
    query MessageDeliveryFormGetLineOfficialAccounts($companyId: String!) {
  lineOfficialAccounts(input: {companyId: $companyId}) {
    ...lineOfficialAccount
  }
}
    ${LineOfficialAccount}`;
export type MessageDeliveryFormRoomMessageGetCouponQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type MessageDeliveryFormRoomMessageGetCouponQuery = (
  { __typename?: 'query_root' }
  & { coupon_by_pk?: Types.Maybe<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'title' | 'availableFrom' | 'availableUntil' | 'availableDays' | 'description' | 'content' | 'imageUrl'>
  )> }
);

export type MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig_by_pk?: Types.Maybe<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'imageUrl'>
  )> }
);

export type LineOfficialAccountFragment = (
  { __typename?: 'LineOfficialAccountProfile' }
  & Pick<Types.LineOfficialAccountProfile, 'lineOfficialAccountId' | 'channelName' | 'pictureUrl'>
);

export type MessageDeliveryFormGetLineOfficialAccountsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type MessageDeliveryFormGetLineOfficialAccountsQuery = (
  { __typename?: 'query_root' }
  & { lineOfficialAccounts: Array<(
    { __typename?: 'LineOfficialAccountProfile' }
    & LineOfficialAccountFragment
  )> }
);

export const LineOfficialAccountFragmentDoc = gql`
    fragment lineOfficialAccount on LineOfficialAccountProfile {
  lineOfficialAccountId
  channelName
  pictureUrl
}
    `;
export const MessageDeliveryFormRoomMessageGetCouponDocument = gql`
    query MessageDeliveryFormRoomMessageGetCoupon($id: uuid!) {
  coupon_by_pk(id: $id) {
    title
    availableFrom
    availableUntil
    availableDays
    description
    content
    imageUrl
  }
}
    `;

/**
 * __useMessageDeliveryFormRoomMessageGetCouponQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryFormRoomMessageGetCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryFormRoomMessageGetCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryFormRoomMessageGetCouponQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageDeliveryFormRoomMessageGetCouponQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryFormRoomMessageGetCouponQuery, MessageDeliveryFormRoomMessageGetCouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryFormRoomMessageGetCouponQuery, MessageDeliveryFormRoomMessageGetCouponQueryVariables>(MessageDeliveryFormRoomMessageGetCouponDocument, options);
      }
export function useMessageDeliveryFormRoomMessageGetCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryFormRoomMessageGetCouponQuery, MessageDeliveryFormRoomMessageGetCouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryFormRoomMessageGetCouponQuery, MessageDeliveryFormRoomMessageGetCouponQueryVariables>(MessageDeliveryFormRoomMessageGetCouponDocument, options);
        }
export type MessageDeliveryFormRoomMessageGetCouponQueryHookResult = ReturnType<typeof useMessageDeliveryFormRoomMessageGetCouponQuery>;
export type MessageDeliveryFormRoomMessageGetCouponLazyQueryHookResult = ReturnType<typeof useMessageDeliveryFormRoomMessageGetCouponLazyQuery>;
export type MessageDeliveryFormRoomMessageGetCouponQueryResult = Apollo.QueryResult<MessageDeliveryFormRoomMessageGetCouponQuery, MessageDeliveryFormRoomMessageGetCouponQueryVariables>;
export const MessageDeliveryFormRoomMessageGetQuestionnaireConfigDocument = gql`
    query MessageDeliveryFormRoomMessageGetQuestionnaireConfig($id: uuid!) {
  questionnaireConfig_by_pk(id: $id) {
    imageUrl
  }
}
    `;

/**
 * __useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery, MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery, MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables>(MessageDeliveryFormRoomMessageGetQuestionnaireConfigDocument, options);
      }
export function useMessageDeliveryFormRoomMessageGetQuestionnaireConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery, MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery, MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables>(MessageDeliveryFormRoomMessageGetQuestionnaireConfigDocument, options);
        }
export type MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryHookResult = ReturnType<typeof useMessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery>;
export type MessageDeliveryFormRoomMessageGetQuestionnaireConfigLazyQueryHookResult = ReturnType<typeof useMessageDeliveryFormRoomMessageGetQuestionnaireConfigLazyQuery>;
export type MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryResult = Apollo.QueryResult<MessageDeliveryFormRoomMessageGetQuestionnaireConfigQuery, MessageDeliveryFormRoomMessageGetQuestionnaireConfigQueryVariables>;
export const MessageDeliveryFormGetLineOfficialAccountsDocument = gql`
    query MessageDeliveryFormGetLineOfficialAccounts($companyId: String!) {
  lineOfficialAccounts(input: {companyId: $companyId}) {
    ...lineOfficialAccount
  }
}
    ${LineOfficialAccountFragmentDoc}`;

/**
 * __useMessageDeliveryFormGetLineOfficialAccountsQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryFormGetLineOfficialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryFormGetLineOfficialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryFormGetLineOfficialAccountsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryFormGetLineOfficialAccountsQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryFormGetLineOfficialAccountsQuery, MessageDeliveryFormGetLineOfficialAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryFormGetLineOfficialAccountsQuery, MessageDeliveryFormGetLineOfficialAccountsQueryVariables>(MessageDeliveryFormGetLineOfficialAccountsDocument, options);
      }
export function useMessageDeliveryFormGetLineOfficialAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryFormGetLineOfficialAccountsQuery, MessageDeliveryFormGetLineOfficialAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryFormGetLineOfficialAccountsQuery, MessageDeliveryFormGetLineOfficialAccountsQueryVariables>(MessageDeliveryFormGetLineOfficialAccountsDocument, options);
        }
export type MessageDeliveryFormGetLineOfficialAccountsQueryHookResult = ReturnType<typeof useMessageDeliveryFormGetLineOfficialAccountsQuery>;
export type MessageDeliveryFormGetLineOfficialAccountsLazyQueryHookResult = ReturnType<typeof useMessageDeliveryFormGetLineOfficialAccountsLazyQuery>;
export type MessageDeliveryFormGetLineOfficialAccountsQueryResult = Apollo.QueryResult<MessageDeliveryFormGetLineOfficialAccountsQuery, MessageDeliveryFormGetLineOfficialAccountsQueryVariables>;