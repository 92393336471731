import React, { memo, useCallback } from "react";
import { Alert } from "antd";
import Modal from "antd/lib/modal/Modal";
import { AddOptionForm } from "forms/AddOptionForm";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { CreateOptionInputOptionSource } from "types/graphql";

import {
  useAddOptionFormInsertOptionMutation,
  useAddOptionGetOptionsQuery,
} from "../../../forms/AddOptionForm/queries";

type Props = {
  onDismiss(): void;
  onAddOption: (option: { optionId: string; _optionId: number }) => void | Promise<void>;
};

export const AddOptionModal = memo<Props>(({ onDismiss, onAddOption }) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getOptionsData, error: getOptionsError } = useAddOptionGetOptionsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const { option: options } = getOptionsData ?? {
    option: [],
  };

  const [createOption, { loading: loadingInsertOption }] = useAddOptionFormInsertOptionMutation();

  const shouldShowAlert = Boolean(getOptionsError);

  const onSubmit = useCallback(
    async (option: CreateOptionInputOptionSource) => {
      try {
        const { data: createOptionData } = await createOption({ variables: { option } });

        const optionId = createOptionData?.createOption.optionId;
        const _optionId = createOptionData?.createOption._optionId;
        if (optionId && _optionId) {
          await onAddOption({ optionId, _optionId });
        } else {
          throw new Error("");
        }
        message.success("作成しました");
        onDismiss();
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [createOption, onDismiss, onAddOption],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <Modal title="新規オプション追加" open footer={null} width={1000} onCancel={onDismiss}>
      <AddOptionForm
        options={options}
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        onClose={onDismiss}
        loading={loadingInsertOption}
        companyId={companyId}
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
    </Modal>
  );
});
