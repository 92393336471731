import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanMainOptionPrintingsGetPlanOptions = gql`
    query PlanMainOptionPrintingsGetPlanOptions($shopId: uuid!) {
  planOption(
    where: {archivedAt: {_is_null: true}, plan: {shopPlans: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}, category: {archivedAt: {_is_null: true}}, archivedAt: {_is_null: true}}}
    order_by: {plan: {planName: asc}}
  ) {
    planOptionId
    name
    plan {
      planId
      planName
      planCategoryId
      category {
        categoryId
        name
      }
    }
    shopPlanOptionKitchenRoles(where: {shopId: {_eq: $shopId}}) {
      role {
        name
        roleId
      }
    }
  }
}
    `;
export const PlanMainOptionPrintingsGetCategories = gql`
    query PlanMainOptionPrintingsGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const PlanMainOptionPrintingsGetRoles = gql`
    query PlanMainOptionPrintingsGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      roleId
      name
    }
  }
}
    `;
export type PlanMainOptionPrintingsGetPlanOptionsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type PlanMainOptionPrintingsGetPlanOptionsQuery = (
  { __typename?: 'query_root' }
  & { planOption: Array<(
    { __typename?: 'planOption' }
    & Pick<Types.PlanOption, 'planOptionId' | 'name'>
    & { plan: (
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'planId' | 'planName' | 'planCategoryId'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ) }
    ), shopPlanOptionKitchenRoles: Array<(
      { __typename?: 'shopPlanOptionKitchenRole' }
      & { role: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'name' | 'roleId'>
      ) }
    )> }
  )> }
);

export type PlanMainOptionPrintingsGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PlanMainOptionPrintingsGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type PlanMainOptionPrintingsGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type PlanMainOptionPrintingsGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'roleId' | 'name'>
    )> }
  )> }
);


export const PlanMainOptionPrintingsGetPlanOptionsDocument = gql`
    query PlanMainOptionPrintingsGetPlanOptions($shopId: uuid!) {
  planOption(
    where: {archivedAt: {_is_null: true}, plan: {shopPlans: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}, category: {archivedAt: {_is_null: true}}, archivedAt: {_is_null: true}}}
    order_by: {plan: {planName: asc}}
  ) {
    planOptionId
    name
    plan {
      planId
      planName
      planCategoryId
      category {
        categoryId
        name
      }
    }
    shopPlanOptionKitchenRoles(where: {shopId: {_eq: $shopId}}) {
      role {
        name
        roleId
      }
    }
  }
}
    `;

/**
 * __usePlanMainOptionPrintingsGetPlanOptionsQuery__
 *
 * To run a query within a React component, call `usePlanMainOptionPrintingsGetPlanOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanMainOptionPrintingsGetPlanOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanMainOptionPrintingsGetPlanOptionsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function usePlanMainOptionPrintingsGetPlanOptionsQuery(baseOptions: Apollo.QueryHookOptions<PlanMainOptionPrintingsGetPlanOptionsQuery, PlanMainOptionPrintingsGetPlanOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanMainOptionPrintingsGetPlanOptionsQuery, PlanMainOptionPrintingsGetPlanOptionsQueryVariables>(PlanMainOptionPrintingsGetPlanOptionsDocument, options);
      }
export function usePlanMainOptionPrintingsGetPlanOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanMainOptionPrintingsGetPlanOptionsQuery, PlanMainOptionPrintingsGetPlanOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanMainOptionPrintingsGetPlanOptionsQuery, PlanMainOptionPrintingsGetPlanOptionsQueryVariables>(PlanMainOptionPrintingsGetPlanOptionsDocument, options);
        }
export type PlanMainOptionPrintingsGetPlanOptionsQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetPlanOptionsQuery>;
export type PlanMainOptionPrintingsGetPlanOptionsLazyQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetPlanOptionsLazyQuery>;
export type PlanMainOptionPrintingsGetPlanOptionsQueryResult = Apollo.QueryResult<PlanMainOptionPrintingsGetPlanOptionsQuery, PlanMainOptionPrintingsGetPlanOptionsQueryVariables>;
export const PlanMainOptionPrintingsGetCategoriesDocument = gql`
    query PlanMainOptionPrintingsGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __usePlanMainOptionPrintingsGetCategoriesQuery__
 *
 * To run a query within a React component, call `usePlanMainOptionPrintingsGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanMainOptionPrintingsGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanMainOptionPrintingsGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePlanMainOptionPrintingsGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PlanMainOptionPrintingsGetCategoriesQuery, PlanMainOptionPrintingsGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanMainOptionPrintingsGetCategoriesQuery, PlanMainOptionPrintingsGetCategoriesQueryVariables>(PlanMainOptionPrintingsGetCategoriesDocument, options);
      }
export function usePlanMainOptionPrintingsGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanMainOptionPrintingsGetCategoriesQuery, PlanMainOptionPrintingsGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanMainOptionPrintingsGetCategoriesQuery, PlanMainOptionPrintingsGetCategoriesQueryVariables>(PlanMainOptionPrintingsGetCategoriesDocument, options);
        }
export type PlanMainOptionPrintingsGetCategoriesQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetCategoriesQuery>;
export type PlanMainOptionPrintingsGetCategoriesLazyQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetCategoriesLazyQuery>;
export type PlanMainOptionPrintingsGetCategoriesQueryResult = Apollo.QueryResult<PlanMainOptionPrintingsGetCategoriesQuery, PlanMainOptionPrintingsGetCategoriesQueryVariables>;
export const PlanMainOptionPrintingsGetRolesDocument = gql`
    query PlanMainOptionPrintingsGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    roles {
      roleId
      name
    }
  }
}
    `;

/**
 * __usePlanMainOptionPrintingsGetRolesQuery__
 *
 * To run a query within a React component, call `usePlanMainOptionPrintingsGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanMainOptionPrintingsGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanMainOptionPrintingsGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function usePlanMainOptionPrintingsGetRolesQuery(baseOptions: Apollo.QueryHookOptions<PlanMainOptionPrintingsGetRolesQuery, PlanMainOptionPrintingsGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanMainOptionPrintingsGetRolesQuery, PlanMainOptionPrintingsGetRolesQueryVariables>(PlanMainOptionPrintingsGetRolesDocument, options);
      }
export function usePlanMainOptionPrintingsGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanMainOptionPrintingsGetRolesQuery, PlanMainOptionPrintingsGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanMainOptionPrintingsGetRolesQuery, PlanMainOptionPrintingsGetRolesQueryVariables>(PlanMainOptionPrintingsGetRolesDocument, options);
        }
export type PlanMainOptionPrintingsGetRolesQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetRolesQuery>;
export type PlanMainOptionPrintingsGetRolesLazyQueryHookResult = ReturnType<typeof usePlanMainOptionPrintingsGetRolesLazyQuery>;
export type PlanMainOptionPrintingsGetRolesQueryResult = Apollo.QueryResult<PlanMainOptionPrintingsGetRolesQuery, PlanMainOptionPrintingsGetRolesQueryVariables>;