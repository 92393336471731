import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";

import { AddCategoryFormItem } from "../useAddCategoryForm";

type Props = {
  uploadImage: string;
  setUploadImage: React.Dispatch<React.SetStateAction<string>>;
} & Omit<FormItemProps, "children">;

export const HeaderUploadField = memo<Props>(({ uploadImage, setUploadImage, ...props }) => (
  <AddCategoryFormItem.NonProperty
    label={
      <FormHelp
        label="カテゴリ画像"
        help="カテゴリ画像の比率は、特に指定はありません。最適なサイズに自動的に変換されます"
      />
    }
    {...props}
  >
    <ImageField
      image={uploadImage}
      setUploadImage={setUploadImage}
      uploadImageApiKey="category"
      formName="AddCategoryForm"
      width={416}
      height={234}
      isAutoHeight
      isMovieAccepted
    />
  </AddCategoryFormItem.NonProperty>
));
