import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const TecAggregationPlanOptionsGetPlan = gql`
    query TecAggregationPlanOptionsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    planId
    planName
    companyId
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;
export type TecAggregationPlanOptionsGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type TecAggregationPlanOptionsGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'planId' | 'planName' | 'companyId' | 'totalDealingShopCount'>
    & { shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'planChoiceId' | 'name'>
        & { tecAggregationMenu?: Types.Maybe<(
          { __typename?: 'tecAggregationMenu' }
          & Pick<Types.TecAggregationMenu, 'id' | 'name' | 'menuCode' | 'dpCode' | 'dpName' | 'gpCode' | 'gpName'>
        )> }
      )> }
    )> }
  )> }
);


export const TecAggregationPlanOptionsGetPlanDocument = gql`
    query TecAggregationPlanOptionsGetPlan($planId: Int!) {
  plan(where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}}, limit: 1) {
    planId
    planName
    companyId
    totalDealingShopCount
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        planChoiceId
        name
        tecAggregationMenu {
          id
          name
          menuCode
          dpCode
          dpName
          gpCode
          gpName
        }
      }
    }
  }
}
    `;

/**
 * __useTecAggregationPlanOptionsGetPlanQuery__
 *
 * To run a query within a React component, call `useTecAggregationPlanOptionsGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useTecAggregationPlanOptionsGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTecAggregationPlanOptionsGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useTecAggregationPlanOptionsGetPlanQuery(baseOptions: Apollo.QueryHookOptions<TecAggregationPlanOptionsGetPlanQuery, TecAggregationPlanOptionsGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TecAggregationPlanOptionsGetPlanQuery, TecAggregationPlanOptionsGetPlanQueryVariables>(TecAggregationPlanOptionsGetPlanDocument, options);
      }
export function useTecAggregationPlanOptionsGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TecAggregationPlanOptionsGetPlanQuery, TecAggregationPlanOptionsGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TecAggregationPlanOptionsGetPlanQuery, TecAggregationPlanOptionsGetPlanQueryVariables>(TecAggregationPlanOptionsGetPlanDocument, options);
        }
export type TecAggregationPlanOptionsGetPlanQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsGetPlanQuery>;
export type TecAggregationPlanOptionsGetPlanLazyQueryHookResult = ReturnType<typeof useTecAggregationPlanOptionsGetPlanLazyQuery>;
export type TecAggregationPlanOptionsGetPlanQueryResult = Apollo.QueryResult<TecAggregationPlanOptionsGetPlanQuery, TecAggregationPlanOptionsGetPlanQueryVariables>;