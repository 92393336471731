import React, { memo } from "react";
import { DashboardOutlined } from "@ant-design/icons";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { DashboardFeatureEnum } from "types/graphql";

import { QuestionnaireAnalyticsMenu } from "./QuestionnaireAnalyticsMenu";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const DashboardMenu = memo<Props>((props) => (
  <SubMenu
    key="dashboard"
    icon={<DashboardOutlined />}
    title="ダッシュボード"
    features={[
      DashboardFeatureEnum.ShopManagement,
      DashboardFeatureEnum.Questionnaire,
      DashboardFeatureEnum.CustomerRelationshipManagement,
    ]}
    {...props}
  >
    <MenuItem route="dashboard" to="/" text="顧客分析" />
    <QuestionnaireAnalyticsMenu company={props.company} />
    <MenuItem
      route="messageDeliveryAnalytics"
      to="/messageDeliveryAnalytics"
      text="メッセージ配信分析"
    />
    <MenuItem route="couponAnalytics" to="/couponAnalytics" text="クーポン利用分析" />
  </SubMenu>
));
