import React, { memo, ReactNode, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { Spacer } from "components/Spacer";

const Preview = styled.div<{ isOpen: boolean }>`
  position: relative;
  height: ${({ isOpen }) => (isOpen ? 532 : 48)}px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  transition: height 0.3s ease-in-out;
`;

const Header = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 12px;
  background: #001529;
  color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const ArrowIcon = styled.span<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #ffffff;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? undefined : `rotate(180deg) translateY(2px)`)};
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  font-size: 14px;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  background: #ffffff;
`;

type Props = {
  /** マウント時にプレビューを開いた状態にするかどうか (defaultはtrue) */
  initialOpen?: boolean;
  children: ReactNode;
};

export const UserAppPreview = memo(({ initialOpen = true, children }: Props) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const scrollContainer = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => {
    setIsOpen((current) => !current);

    scrollContainer.current?.scrollTo({ top: 0 });
  }, []);

  return (
    <Preview isOpen={isOpen}>
      <Header onClick={toggle} type="button">
        <HeaderContent>
          <ArrowIcon isOpen={isOpen} />
          <span>プレビュー</span>
        </HeaderContent>
        <Tooltip title="端末やOSによっては、実際に表示される画面と異なる場合があります。">
          <StyledQuestionCircleOutlined />
        </Tooltip>
      </Header>
      <Spacer height={48} />
      <Content ref={scrollContainer}>{children}</Content>
    </Preview>
  );
});
