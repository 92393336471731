import React from "react";
import { Alert, Card } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { useCompany } from "hooks/useCompany";

import { DetailTypeTable } from "./DetailTypeTable";
import { DiscountTypeTable } from "./DiscountTypeTable";
import {
  usePikaichiOnSitePaymentTypesGetDetailTypesQuery,
  usePikaichiOnSitePaymentTypesGetDiscountTypesQuery,
  usePikaichiOnSitePaymentTypesGetShopsQuery,
} from "./queries";

export const PikaichiOnSitePaymentTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getDetailTypesData, loading: loadingDetailTypes } =
    usePikaichiOnSitePaymentTypesGetDetailTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const { data: getDiscountTypesData, loading: loadingDiscountTypes } =
    usePikaichiOnSitePaymentTypesGetDiscountTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const { data: getShopsData } = usePikaichiOnSitePaymentTypesGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const onSitePaymentDetailTypes = getDetailTypesData?.onSitePaymentDetailType ?? [];
  const onSitePaymentDiscountTypes = getDiscountTypesData?.onSitePaymentDiscountType ?? [];
  const isPikaichiConfigEnabled = getShopsData?.shop.some((shop) => shop.pikaichiConfig);

  const shouldShowAlert = false;

  return (
    <DashboardLayout title="支払・割引方法一覧">
      <PageHeader title="支払・割引方法一覧" />

      {!isPikaichiConfigEnabled && (
        <Card>
          <Paragraph>ぴかいちナビ集計が有効になっていない法人です。</Paragraph>
        </Card>
      )}

      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <SubTitle bottomSpacer={<Spacer size={24} />}>支払方法一覧</SubTitle>
      <DetailTypeTable detailTypes={onSitePaymentDetailTypes} loading={loadingDetailTypes} />
      <Spacer size={16} /* Table 下の Pagination に 16px margin あり */ />

      <SubTitle bottomSpacer={<Spacer size={24} />}>割引方法一覧</SubTitle>
      <DiscountTypeTable
        discountTypes={onSitePaymentDiscountTypes}
        loading={loadingDiscountTypes}
      />
    </DashboardLayout>
  );
};
