import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Role } from "pages/EditKitchenDisplayDisplayTarget/types";

import { EditKitchenDisplayDisplayTargetFormItem } from "../useEditKitchenDisplayDisplayTargetForm";

const { Option } = Select;

type Props = Omit<FormItemProps, "children" | "name"> & { roles: Role[] };

export const RoleField = memo<Props>(({ roles, ...props }) => (
  <EditKitchenDisplayDisplayTargetFormItem label="提供伝票印刷プリンター" name="roleId" {...props}>
    <Select<number> allowClear>
      {roles.map(({ roleId, name }) => (
        <Option key={roleId} value={roleId}>
          {name}
        </Option>
      ))}
    </Select>
  </EditKitchenDisplayDisplayTargetFormItem>
));
