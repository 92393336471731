import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { Plan, TecAggregationMenu } from "../types";

export type EditTecAggregationPlanFormValues = Pick<
  TecAggregationMenu,
  "name" | "menuCode" | "dpCode" | "dpName" | "gpCode" | "gpName"
> & { name: string };

export const EditTecAggregationPlanFormItem = createFormItem<EditTecAggregationPlanFormValues>();

export const useEditTecAggregationPlanForm = ({
  plan,
  tecAggregationMenu,
  onSubmit,
}: {
  plan?: Plan;
  tecAggregationMenu?: TecAggregationMenu;
  onSubmit: (
    args: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditTecAggregationPlanFormValues>(
    () => ({
      name: tecAggregationMenu?.name ?? plan?.planName ?? "",
      menuCode: tecAggregationMenu?.menuCode ?? "",
      dpCode: tecAggregationMenu?.dpCode ?? "",
      dpName: tecAggregationMenu?.dpName ?? "",
      gpCode: tecAggregationMenu?.gpCode ?? "",
      gpName: tecAggregationMenu?.gpName ?? "",
    }),
    [plan, tecAggregationMenu],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditTecAggregationPlanFormValues;

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
