import React, { memo } from "react";
import { Typography } from "antd";

import { Form } from "components/antd/Form";
import { MenuKdDisplayTargetField } from "pages/EditShopMenuOutput/EditMenuKdDisplayTargetForm/MenuKdDisplayTargetField";
import { useEditMenuKdDisplayTargetForm } from "pages/EditShopMenuOutput/EditMenuKdDisplayTargetForm/useEditMenuKdDisplayTargetForm";
import { KdDisplayTarget, Menu } from "pages/EditShopMenuOutput/types";

const { Title } = Typography;

type Props = {
  shopId: string;
  menu: Menu;
  menuKdDisplayTargets: KdDisplayTarget[];
  kdDisplayTargets: KdDisplayTarget[];
};

export const EditMenuKdDisplayTargetForm = memo<Props>(
  ({ shopId, menu, menuKdDisplayTargets, kdDisplayTargets }) => {
    const {
      form,
      initialValues,
      change: onChange,
      loadings,
    } = useEditMenuKdDisplayTargetForm(shopId, menu, menuKdDisplayTargets, kdDisplayTargets);

    return (
      <Form
        name="menuKdDisplayTargets"
        form={form}
        initialValues={initialValues}
        onValuesChange={onChange}
        layout="vertical"
      >
        <Title level={5}>キッチンディスプレイ</Title>
        {kdDisplayTargets.map(({ id, name }) => (
          <MenuKdDisplayTargetField key={id} label={name} name={id} loading={loadings[id]} />
        ))}
      </Form>
    );
  },
);
