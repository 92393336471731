import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

export type EditOnboardingHandoutFormValues = {
  shopId?: string;
  onboardingHandout: string;
};

const onboardingHandout = `
Google
https://google.com

ラベル
テキスト
`.trim();

const getInitialValues = () => ({
  onboardingHandout,
});

export const EditOnboardingHandoutFormItem = createFormItem<EditOnboardingHandoutFormValues>();

export const useEditOnboardingHandoutForm = (
  onSubmit: (formValues: EditOnboardingHandoutFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const values = form.getFieldsValue() as EditOnboardingHandoutFormValues;
    onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
