import React, { memo } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  margin-left: 8px;
`;

type Props = {
  title: string;
  help: React.ReactNode;
};

export const TableHeaderHelp = memo<Props>(({ title, help }) => (
  <Tooltip title={help}>
    <span>
      {title}
      <StyledQuestionCircleOutlined />
    </span>
  </Tooltip>
));
