import React, { memo, useCallback, useState } from "react";

import { Dropdown } from "components/Dropdown";

import { ShopSelectOverlay } from "../ShopSelectOverlay";
import { Shop } from "../types";

type Props = {
  shops: Shop[];
  setShop: (shopId: string) => void;
  children: React.ReactNode;
};

export const ShopSelectDropdown = memo<Props>(({ shops, setShop, children }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const onSelect = useCallback(
    (shopId) => {
      setShop(shopId);
      setDropdownVisible(false);
    },
    [setShop, setDropdownVisible],
  );

  return (
    <Dropdown
      overlay={<ShopSelectOverlay shops={shops} onSelect={onSelect} />}
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
      arrow
    >
      {children}
    </Dropdown>
  );
});
