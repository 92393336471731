import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditFoodingJournalConfigGetShopFoodingJournalConfig = gql`
    query EditFoodingJournalConfigGetShopFoodingJournalConfig($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    foodingJournalConfig {
      id
      shopCode
      posNumber
      configId
      configPassword
      configSecretKey
      inputUrl
      enabled
    }
  }
}
    `;
export const EditFoodingJournalConfigUpsertFoodingJournalConfig = gql`
    mutation EditFoodingJournalConfigUpsertFoodingJournalConfig($foodingJournalConfig: foodingJournalConfig_insert_input!) {
  insert_foodingJournalConfig_one(
    object: $foodingJournalConfig
    on_conflict: {constraint: foodingJournalConfig_pkey, update_columns: [shopCode, posNumber, configId, configPassword, configSecretKey, inputUrl, enabled]}
  ) {
    id
  }
}
    `;
export type EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditFoodingJournalConfigGetShopFoodingJournalConfigQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { foodingJournalConfig?: Types.Maybe<(
      { __typename?: 'foodingJournalConfig' }
      & Pick<Types.FoodingJournalConfig, 'id' | 'shopCode' | 'posNumber' | 'configId' | 'configPassword' | 'configSecretKey' | 'inputUrl' | 'enabled'>
    )> }
  )> }
);

export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutationVariables = Types.Exact<{
  foodingJournalConfig: Types.FoodingJournalConfigInsertInput;
}>;


export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_foodingJournalConfig_one?: Types.Maybe<(
    { __typename?: 'foodingJournalConfig' }
    & Pick<Types.FoodingJournalConfig, 'id'>
  )> }
);


export const EditFoodingJournalConfigGetShopFoodingJournalConfigDocument = gql`
    query EditFoodingJournalConfigGetShopFoodingJournalConfig($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    foodingJournalConfig {
      id
      shopCode
      posNumber
      configId
      configPassword
      configSecretKey
      inputUrl
      enabled
    }
  }
}
    `;

/**
 * __useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery__
 *
 * To run a query within a React component, call `useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery(baseOptions: Apollo.QueryHookOptions<EditFoodingJournalConfigGetShopFoodingJournalConfigQuery, EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditFoodingJournalConfigGetShopFoodingJournalConfigQuery, EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables>(EditFoodingJournalConfigGetShopFoodingJournalConfigDocument, options);
      }
export function useEditFoodingJournalConfigGetShopFoodingJournalConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditFoodingJournalConfigGetShopFoodingJournalConfigQuery, EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditFoodingJournalConfigGetShopFoodingJournalConfigQuery, EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables>(EditFoodingJournalConfigGetShopFoodingJournalConfigDocument, options);
        }
export type EditFoodingJournalConfigGetShopFoodingJournalConfigQueryHookResult = ReturnType<typeof useEditFoodingJournalConfigGetShopFoodingJournalConfigQuery>;
export type EditFoodingJournalConfigGetShopFoodingJournalConfigLazyQueryHookResult = ReturnType<typeof useEditFoodingJournalConfigGetShopFoodingJournalConfigLazyQuery>;
export type EditFoodingJournalConfigGetShopFoodingJournalConfigQueryResult = Apollo.QueryResult<EditFoodingJournalConfigGetShopFoodingJournalConfigQuery, EditFoodingJournalConfigGetShopFoodingJournalConfigQueryVariables>;
export const EditFoodingJournalConfigUpsertFoodingJournalConfigDocument = gql`
    mutation EditFoodingJournalConfigUpsertFoodingJournalConfig($foodingJournalConfig: foodingJournalConfig_insert_input!) {
  insert_foodingJournalConfig_one(
    object: $foodingJournalConfig
    on_conflict: {constraint: foodingJournalConfig_pkey, update_columns: [shopCode, posNumber, configId, configPassword, configSecretKey, inputUrl, enabled]}
  ) {
    id
  }
}
    `;
export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutationFn = Apollo.MutationFunction<EditFoodingJournalConfigUpsertFoodingJournalConfigMutation, EditFoodingJournalConfigUpsertFoodingJournalConfigMutationVariables>;

/**
 * __useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation__
 *
 * To run a mutation, you first call `useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodingJournalConfigUpsertFoodingJournalConfigMutation, { data, loading, error }] = useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation({
 *   variables: {
 *      foodingJournalConfig: // value for 'foodingJournalConfig'
 *   },
 * });
 */
export function useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditFoodingJournalConfigUpsertFoodingJournalConfigMutation, EditFoodingJournalConfigUpsertFoodingJournalConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditFoodingJournalConfigUpsertFoodingJournalConfigMutation, EditFoodingJournalConfigUpsertFoodingJournalConfigMutationVariables>(EditFoodingJournalConfigUpsertFoodingJournalConfigDocument, options);
      }
export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutationHookResult = ReturnType<typeof useEditFoodingJournalConfigUpsertFoodingJournalConfigMutation>;
export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutationResult = Apollo.MutationResult<EditFoodingJournalConfigUpsertFoodingJournalConfigMutation>;
export type EditFoodingJournalConfigUpsertFoodingJournalConfigMutationOptions = Apollo.BaseMutationOptions<EditFoodingJournalConfigUpsertFoodingJournalConfigMutation, EditFoodingJournalConfigUpsertFoodingJournalConfigMutationVariables>;