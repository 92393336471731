import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { BankCode } from "hooks/useBankCode";

import { EditGmoBankAccountFormItem } from "../useEditGmoBankAccountForm";

const { Option } = Select;

type Props = {
  banks: BankCode[];
} & Omit<FormItemProps, "children" | "name">;

export const BankField = memo<Props>(({ banks, ...props }) => (
  <EditGmoBankAccountFormItem.NonProperty noStyle>
    <EditGmoBankAccountFormItem
      label="金融機関（ゆうちょ銀行は除く）"
      name="bank"
      rules={[{ required: true, message: "金融機関を選択して下さい" }]}
      {...props}
    >
      <Select<string> showSearch placeholder="金融機関名" optionFilterProp="children">
        {banks.map(({ code, name }) => (
          <Option key={code} value={code}>
            {name}
          </Option>
        ))}
      </Select>
    </EditGmoBankAccountFormItem>
  </EditGmoBankAccountFormItem.NonProperty>
));
