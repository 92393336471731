import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox, Col, Row, Select } from "antd";
import { taxRates } from "models/taxRate";

import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { ImageField } from "components/Form/ImageField";
import { InputCostPrice } from "components/Input/InputCostPrice";
import { InputQuantity } from "components/Input/InputQuantity";
import { PlanChoice } from "pages/PlanOptions/EditPlanChoiceModal/types";
import { OptionInputTypeEnum } from "types/graphql";

import { FormHelp } from "../../../../components/Form/FormHelp/index";

import { CostTaxMethodField } from "./CostTaxMethodField";
import { MenuTypeField } from "./MenuTypeField";
import { NameField } from "./NameField";
import { PriceField } from "./PriceField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { TaxMethodField } from "./TaxMethodField";
import { TaxRateField } from "./TaxRateField";
import { EditPlanChoiceFormItem, EditPlanChoiceFormValues } from "./useEditPlanChoiceForm";

const CheckboxWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

type Props = {
  planChoice: PlanChoice;
  inputType: OptionInputTypeEnum;
  disabled: boolean;
  uploadImage: string | null;
  setUploadImage: (imageUrl: string) => void;
  formValues: EditPlanChoiceFormValues;
};

const { Option } = Select;

export const EditPlanChoiceForm = memo<Props>(
  ({ planChoice, inputType, disabled, uploadImage, setUploadImage, formValues }) => (
    <>
      <Row>
        <ImageField
          image={uploadImage ?? null}
          setUploadImage={setUploadImage}
          uploadImageApiKey="planChoice"
          formName="EditPlanChoiceForm"
          label="プラン選択肢画像"
          isMovieAccepted
          disabled={disabled}
        />
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <NameField disabled={disabled} wrapperCol={{ span: 12 }} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <ReceiptDisplayNameField disabled={disabled} wrapperCol={{ span: 12 }} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <MenuTypeField disabled={disabled} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <TaxMethodField taxMethod={planChoice.planOption.plan.taxMethod} />
        </Col>
        <Col span={6}>
          <TaxRateField taxRate={planChoice.planOption.plan.taxRate} />
        </Col>
        <Col span={6}>
          <PriceField disabled={disabled} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <CostTaxMethodField
            costTaxMethod={planChoice.planOption.plan.company.defaultCostTaxMethod}
          />
        </Col>
        <Col span={6}>
          <CostTaxRateWithCostPriceField>
            <Select<string> disabled={disabled}>
              {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
                <Option key={taxRateKey} value={taxRate}>
                  {taxRate * 100}%
                </Option>
              ))}
            </Select>
          </CostTaxRateWithCostPriceField>
        </Col>
        <Col span={6}>
          <CostPriceWithCostTaxRateField
            label={<FormHelp label="原価" help="小数点以下二桁までの数値を設定できます。" />}
          >
            <InputCostPrice step={0.01} precision={2} disabled={disabled} />
          </CostPriceWithCostTaxRateField>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <CheckboxWrapper>
            <EditPlanChoiceFormItem name="isDefaultSelection" valuePropName="checked">
              <Checkbox disabled={disabled}>デフォルトで選択済みの状態にする</Checkbox>
            </EditPlanChoiceFormItem>
          </CheckboxWrapper>
        </Col>
      </Row>

      {inputType === OptionInputTypeEnum.Count && (
        <Row gutter={24}>
          <Col span={20}>
            <CheckboxWrapper>
              <EditPlanChoiceFormItem name="hasOrderCountLimit" valuePropName="checked">
                <Checkbox value={formValues.hasOrderCountLimit} disabled={disabled}>
                  注文個数上限を設定する
                </Checkbox>
              </EditPlanChoiceFormItem>
            </CheckboxWrapper>
          </Col>
          <Col span={4}>
            <EditPlanChoiceFormItem name="maxOrderableNum">
              <InputQuantity disabled={!formValues.hasOrderCountLimit || disabled} />
            </EditPlanChoiceFormItem>
          </Col>
        </Row>
      )}
    </>
  ),
);
