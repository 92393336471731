import { useCallback, useMemo, useState } from "react";
import { taxRates } from "models/taxRate";

import { createFormItem, Form } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { useCompany } from "hooks/useCompany";
import { Category, CategoryMenu, Menu as TypeMenu } from "pages/AddMenu/types";
import {
  CreateMenuInputCategoryMenu,
  CreateMenuInputMenuSource,
  CreateMenuInputTranslationSource,
  DisplayTypeEnum,
  Menu,
  TaxMethodEnum,
} from "types/graphql";

export const DEFAULT_CATEGORY_ID = -1;

export type AddMenuFormValues = Pick<
  Menu,
  | "description"
  | "featuredLabelText"
  | "imageUrl"
  | "name"
  | "orderMaxNum"
  | "orderMaxNumPerTableUser"
  | "receiptDisplayName"
  | "shopSideName"
  | "taxMethod"
  | "taxRate"
  | "unitMaxOrderNumForNumPeople"
  | "unitMaxOrderNumPerCustomer"
  | "openPrice"
  | "orderableTimeId"
  | "shouldMergeSlipOptions"
  | "costPrice"
  | "costTaxRate"
  | "menuType"
> & {
  price: number;
  categoryIds: CategoryMenu["categoryId"][];
  displayTypes: Record<number, CategoryMenu["displayType"]>;
} & {
  options: {
    optionId: string | null;
    _optionId: number;
  }[];
  hasOrderLimit: boolean;
  hasOrderLimitPerTableUser: boolean;
  hasOrderLimitPerCustomer: boolean;
  hasOrderLimitForNumPeople: boolean;

  nameSources: (LocaleValuePair & { columnName: never })[];
  featureLabelSources: (LocaleValuePair & { columnName: never })[];
  descriptionSources: (LocaleValuePair & { columnName: never })[];
};

const getPriority = (categories: Category[], categoryId: number) => {
  const category = categories.find((category) => category.categoryId === categoryId);
  const priorities = category?.categoryMenus.flatMap(({ priority }) => priority) ?? [];
  return Math.max(-1, ...priorities) + 1;
};

export const AddMenuFormItem = createFormItem<AddMenuFormValues>();

export const useAddMenuForm = ({
  categories,
  defaultMenuTaxMethod,
  onSubmit,
}: {
  categories: Category[];
  defaultMenuTaxMethod: TaxMethodEnum | undefined;
  onSubmit: ({
    menu,
    categoryMenuSources,
    sourceMenuId,
    createTranslationsSource,
  }: {
    menu: CreateMenuInputMenuSource;
    categoryMenuSources: CreateMenuInputCategoryMenu[];
    sourceMenuId?: number;
    createTranslationsSource: CreateMenuInputTranslationSource;
  }) => void;
}) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [isCopied, setIsCopied] = useState(false);
  const [form] = Form.useForm<AddMenuFormValues>();

  const initialValues = useMemo(
    () => ({
      orderMaxNum: null,
      orderMaxNumPerTableUser: null,
      unitMaxOrderNumForNumPeople: null,
      unitMaxOrderNumPerCustomer: null,
      options: [],
      taxMethod: defaultMenuTaxMethod,
      // NOTE: テイクアウトより店内飲食をする商品の方が圧倒的に多いので、販売価格の税率のデフォルトは10%とする
      taxRate: taxRates["0.1"],
      openPrice: false,
      costPrice: 0,
      // NOTE: 仕入れ商品の多くは軽減税率商品となるので、デフォルトは8%とする
      costTaxRate: taxRates["0.08"],
      displayTypes: {
        [DEFAULT_CATEGORY_ID]: DisplayTypeEnum.Text,
      },
    }),
    [defaultMenuTaxMethod],
  );

  const copyFromAnotherMenu = useCallback(
    (menu: TypeMenu) => {
      const {
        categoryMenus,
        menuOptions,
        menuId: _menuId,
        orderMaxNumPerTableUser,
        unitMaxOrderNumForNumPeople,
        unitMaxOrderNumPerCustomer,
        ...clone
      } = menu;

      form.setFieldsValue({
        ...clone,
        orderMaxNumPerTableUser,
        unitMaxOrderNumForNumPeople,
        unitMaxOrderNumPerCustomer,
        categoryIds: categoryMenus.map((categoryMenu) => categoryMenu.categoryId),
        displayTypes: categoryMenus.reduce((result, current) => {
          result[current.categoryId] = current.displayType;
          return result;
        }, {} as Record<number, CategoryMenu["displayType"]>),
        price: menu.price,
        options: menuOptions.map((menuOption) => ({
          optionId: menuOption.optionId,
          _optionId: menuOption._optionId,
        })),
      });

      setIsCopied(true);
    },
    [form],
  );

  const submit = useCallback(
    ({
      imageUrl,
      sourceMenuId,
    }: {
      imageUrl: string | null | undefined;
      sourceMenuId?: number;
    }) => {
      if (!companyId) throw new Error("no companyId");

      const formValues = form.getFieldsValue();
      const {
        categoryIds,
        displayTypes = {},
        options = [],
        hasOrderLimit,
        hasOrderLimitPerTableUser,
        hasOrderLimitPerCustomer,
        hasOrderLimitForNumPeople,
        receiptDisplayName,
        shopSideName,
        openPrice = false,
        price,
        description,
        featuredLabelText,
        name,
        orderMaxNum: formOrderMaxNum,
        orderMaxNumPerTableUser: formOrderMaxNumPerTableUser,
        unitMaxOrderNumForNumPeople: formUnitMaxOrderNumForNumPeople,
        unitMaxOrderNumPerCustomer: formUnitMaxOrderNumPerCustomer,
        taxMethod,
        taxRate,
        orderableTimeId,
        shouldMergeSlipOptions,
        costPrice,
        costTaxRate,
        menuType,
        nameSources,
        descriptionSources,
        featureLabelSources,
      } = formValues;

      const categoryMenus = categoryIds.map((categoryId) => {
        const category = categories.find((category) => category.categoryId === categoryId);

        if (!category) throw new Error("category not found");

        const displayType = displayTypes[categoryId];

        if (displayType === undefined) throw new Error("displayType not found");

        return {
          categoryId: category.id,
          _categoryId: category.categoryId,
          displayType,
          priority: getPriority(categories, categoryId),
        };
      });

      const orderMaxNum = !hasOrderLimit ? null : formOrderMaxNum;
      const orderMaxNumPerTableUser = !hasOrderLimitPerTableUser
        ? null
        : formOrderMaxNumPerTableUser;
      const unitMaxOrderNumForNumPeople = !hasOrderLimitForNumPeople
        ? null
        : formUnitMaxOrderNumForNumPeople;

      const unitMaxOrderNumPerCustomer = !hasOrderLimitPerCustomer
        ? null
        : formUnitMaxOrderNumPerCustomer;

      onSubmit({
        menu: {
          description,
          featuredLabelText,
          name,
          taxMethod,
          orderableTimeId,
          shouldMergeSlipOptions,
          costPrice,
          costTaxRate,
          menuType,
          receiptDisplayName: receiptDisplayName || name,
          shopSideName: shopSideName || name,
          imageUrl,
          orderMaxNum,
          orderMaxNumPerTableUser,
          companyId,
          unitMaxOrderNumForNumPeople,
          unitMaxOrderNumPerCustomer,
          menuOptions:
            options?.map((option, index) => ({
              optionId: option?.optionId ?? null,
              _optionId: option?._optionId ?? -1,
              priority: index,
            })) ?? [],
          // NOTE: 税種別を非課税にしていた場合は税率を 0 とする
          taxRate: taxMethod === TaxMethodEnum.NonTaxable ? 0 : taxRate,
          openPrice,
          price,
        },
        categoryMenuSources: categoryMenus,
        sourceMenuId,
        createTranslationsSource: {
          nameSources: (nameSources ?? []).filter(({ value }) => value),
          descriptionSources: (descriptionSources ?? []).filter(({ value }) => value),
          featureLabelSources: (featureLabelSources ?? []).filter(({ value }) => value),
        },
      });
    },
    [categories, companyId, form, onSubmit],
  );

  const updateDisplayTypesWithCategoryIds = useCallback(
    (changedValues: Partial<AddMenuFormValues>) => {
      const changedCategoryIds = changedValues.categoryIds;

      if (!changedCategoryIds) return;

      if (changedCategoryIds.length === 0) {
        form.setFieldsValue({ displayTypes: { [DEFAULT_CATEGORY_ID]: DisplayTypeEnum.Text } });

        return;
      }

      const currentDisplayTypes = form.getFieldsValue().displayTypes;

      form.setFieldsValue({
        displayTypes: Object.fromEntries(
          changedCategoryIds.map((categoryId) => [
            categoryId,
            currentDisplayTypes[DEFAULT_CATEGORY_ID] ??
              currentDisplayTypes[categoryId] ??
              DisplayTypeEnum.Text,
          ]),
        ),
      });
    },
    [form],
  );

  return {
    form,
    initialValues,
    isCopied,
    submit,
    copyFromAnotherMenu,
    updateDisplayTypesWithCategoryIds,
  };
};
