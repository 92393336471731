import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button, Divider, Form } from "antd";
import { isValidOrderableTimeDurationTerms } from "models/orderableTime";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useAddOrderableTimeGetOrderableTimesQuery } from "pages/AddOrderableTime/queries";
import { NameField } from "pages/EditOrderableTime/EditOrderableTimeForm/NameField";
import {
  EditOrderableTimeFormValues,
  useEditOrderableTimeForm,
} from "pages/EditOrderableTime/EditOrderableTimeForm/useEditOrderableTimeForm";

import { OrderableTime } from "../types";

import { DayWeekSchedulerForm } from "./DayWeekSchedulerForm";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

type Props = {
  onSubmit: Parameters<typeof useEditOrderableTimeForm>[0]["onSubmit"];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  orderableTime: OrderableTime;
  changeDateTime: string;
  onClose: () => void;
  loading: boolean;
};

export const EditOrderableTimeForm = memo<Props>(
  ({ onClose, onSubmit, onFormValidationError, loading, orderableTime, changeDateTime }) => {
    const [company] = useCompany();
    const { data } = useAddOrderableTimeGetOrderableTimesQuery(
      company ? { variables: { companyId: company.id } } : { skip: true },
    );

    const { form, initialValues, submit, change } = useEditOrderableTimeForm({
      orderableTime,
      onSubmit,
      onFormValidationError,
    });

    const existingOrderableTimeNames = useMemo(
      () =>
        (data?.orderableTime ?? [])
          .map(({ name }) => name)
          .filter((name) => name !== initialValues.name),
      [data?.orderableTime, initialValues.name],
    );

    return (
      <>
        <FormContent>
          <StyledForm
            name="orderableTime"
            form={form}
            initialValues={initialValues}
            onValuesChange={change}
            layout="vertical"
          >
            <NameField
              wrapperCol={{ span: 8 }}
              existingOrderableTimeNames={existingOrderableTimeNames}
            />

            <Divider />

            <Form.List
              name="terms"
              rules={[
                {
                  message: "重複している表示時間があります",
                  validator: async (rule, value) => {
                    const terms = value as EditOrderableTimeFormValues["terms"];
                    const isValid = isValidOrderableTimeDurationTerms({ terms, changeDateTime });
                    if (!isValid) {
                      throw Error("重複している表示時間があります");
                    }
                  },
                },
              ]}
            >
              {(_, actions, { errors }) => (
                <>
                  <Form.ErrorList errors={errors} />
                  <DayWeekSchedulerForm
                    terms={form.getFieldValue("terms") as EditOrderableTimeFormValues["terms"]}
                    changeDateTime={changeDateTime}
                    onCreateTerm={(val) => actions.add(val)}
                    onDeleteTerm={(idx) => actions.remove(idx)}
                  />
                </>
              )}
            </Form.List>
          </StyledForm>
        </FormContent>
        <Spacer size={24} />

        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
