import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardMenusGetMenus = gql`
    query WinboardMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, categoryMenus: {menu: {_not: {giftMeta: {}}, archivedAt: {_is_null: true}}}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
          }
        }
        winboardMenus(where: {_choiceId: {_is_null: true}}) {
          id
          name
          code
          bumonCode
          bumonName
          categoryCode
          categoryName
        }
      }
    }
  }
}
    `;
export const WinboardMenusGetCategories = gql`
    query WinboardMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const WinboardMenusGetShops = gql`
    query WinboardMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const WinboardMenusDeleteWinboardMenu = gql`
    mutation WinboardMenusDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardMenusGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
        & { shopMenus: Array<(
          { __typename?: 'shopMenu' }
          & { shop: (
            { __typename?: 'shop' }
            & Pick<Types.Shop, 'shopId' | 'name'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'optionId' | 'name'>
          ) }
        )>, winboardMenus: Array<(
          { __typename?: 'winboardMenu' }
          & Pick<Types.WinboardMenu, 'id' | 'name' | 'code' | 'bumonCode' | 'bumonName' | 'categoryCode' | 'categoryName'>
        )> }
      ) }
    )> }
  )> }
);

export type WinboardMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type WinboardMenusGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardMenusGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type WinboardMenusDeleteWinboardMenuMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type WinboardMenusDeleteWinboardMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_winboardMenu_by_pk?: Types.Maybe<(
    { __typename?: 'winboardMenu' }
    & Pick<Types.WinboardMenu, 'id'>
  )> }
);


export const WinboardMenusGetMenusDocument = gql`
    query WinboardMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, categoryMenus: {menu: {_not: {giftMeta: {}}, archivedAt: {_is_null: true}}}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
          }
        }
        winboardMenus(where: {_choiceId: {_is_null: true}}) {
          id
          name
          code
          bumonCode
          bumonName
          categoryCode
          categoryName
        }
      }
    }
  }
}
    `;

/**
 * __useWinboardMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useWinboardMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenusGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenusGetMenusQuery, WinboardMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenusGetMenusQuery, WinboardMenusGetMenusQueryVariables>(WinboardMenusGetMenusDocument, options);
      }
export function useWinboardMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenusGetMenusQuery, WinboardMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenusGetMenusQuery, WinboardMenusGetMenusQueryVariables>(WinboardMenusGetMenusDocument, options);
        }
export type WinboardMenusGetMenusQueryHookResult = ReturnType<typeof useWinboardMenusGetMenusQuery>;
export type WinboardMenusGetMenusLazyQueryHookResult = ReturnType<typeof useWinboardMenusGetMenusLazyQuery>;
export type WinboardMenusGetMenusQueryResult = Apollo.QueryResult<WinboardMenusGetMenusQuery, WinboardMenusGetMenusQueryVariables>;
export const WinboardMenusGetCategoriesDocument = gql`
    query WinboardMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useWinboardMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useWinboardMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenusGetCategoriesQuery, WinboardMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenusGetCategoriesQuery, WinboardMenusGetCategoriesQueryVariables>(WinboardMenusGetCategoriesDocument, options);
      }
export function useWinboardMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenusGetCategoriesQuery, WinboardMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenusGetCategoriesQuery, WinboardMenusGetCategoriesQueryVariables>(WinboardMenusGetCategoriesDocument, options);
        }
export type WinboardMenusGetCategoriesQueryHookResult = ReturnType<typeof useWinboardMenusGetCategoriesQuery>;
export type WinboardMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useWinboardMenusGetCategoriesLazyQuery>;
export type WinboardMenusGetCategoriesQueryResult = Apollo.QueryResult<WinboardMenusGetCategoriesQuery, WinboardMenusGetCategoriesQueryVariables>;
export const WinboardMenusGetShopsDocument = gql`
    query WinboardMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useWinboardMenusGetShopsQuery__
 *
 * To run a query within a React component, call `useWinboardMenusGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenusGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenusGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardMenusGetShopsQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenusGetShopsQuery, WinboardMenusGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenusGetShopsQuery, WinboardMenusGetShopsQueryVariables>(WinboardMenusGetShopsDocument, options);
      }
export function useWinboardMenusGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenusGetShopsQuery, WinboardMenusGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenusGetShopsQuery, WinboardMenusGetShopsQueryVariables>(WinboardMenusGetShopsDocument, options);
        }
export type WinboardMenusGetShopsQueryHookResult = ReturnType<typeof useWinboardMenusGetShopsQuery>;
export type WinboardMenusGetShopsLazyQueryHookResult = ReturnType<typeof useWinboardMenusGetShopsLazyQuery>;
export type WinboardMenusGetShopsQueryResult = Apollo.QueryResult<WinboardMenusGetShopsQuery, WinboardMenusGetShopsQueryVariables>;
export const WinboardMenusDeleteWinboardMenuDocument = gql`
    mutation WinboardMenusDeleteWinboardMenu($id: uuid!) {
  delete_winboardMenu_by_pk(id: $id) {
    id
  }
}
    `;
export type WinboardMenusDeleteWinboardMenuMutationFn = Apollo.MutationFunction<WinboardMenusDeleteWinboardMenuMutation, WinboardMenusDeleteWinboardMenuMutationVariables>;

/**
 * __useWinboardMenusDeleteWinboardMenuMutation__
 *
 * To run a mutation, you first call `useWinboardMenusDeleteWinboardMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenusDeleteWinboardMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [winboardMenusDeleteWinboardMenuMutation, { data, loading, error }] = useWinboardMenusDeleteWinboardMenuMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWinboardMenusDeleteWinboardMenuMutation(baseOptions?: Apollo.MutationHookOptions<WinboardMenusDeleteWinboardMenuMutation, WinboardMenusDeleteWinboardMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WinboardMenusDeleteWinboardMenuMutation, WinboardMenusDeleteWinboardMenuMutationVariables>(WinboardMenusDeleteWinboardMenuDocument, options);
      }
export type WinboardMenusDeleteWinboardMenuMutationHookResult = ReturnType<typeof useWinboardMenusDeleteWinboardMenuMutation>;
export type WinboardMenusDeleteWinboardMenuMutationResult = Apollo.MutationResult<WinboardMenusDeleteWinboardMenuMutation>;
export type WinboardMenusDeleteWinboardMenuMutationOptions = Apollo.BaseMutationOptions<WinboardMenusDeleteWinboardMenuMutation, WinboardMenusDeleteWinboardMenuMutationVariables>;