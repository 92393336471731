import React, { memo, useMemo } from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { CaretRightOutlined, RightOutlined } from "@ant-design/icons";
import { couponAnalyticsProperties } from "models/couponAnalytics";

import { Panel } from "components/AnalyticsTable/SummaryPanel/Panel";
import { PanelItem } from "components/AnalyticsTable/SummaryPanel/PanelItem";
import { Loading } from "components/Loading";
import { colors } from "constants/colors";

import { CouponAnalyticsSummary } from "../type";

const SUMMARY_PANEL_HEIGHT = 204;

const StyledRightOutlined = styled(RightOutlined).attrs({
  style: { fontSize: 24 },
})`
  color: ${colors.Text.Secondary};
`;

const StyledCaretRightOutlined = styled(CaretRightOutlined).attrs({
  style: { fontSize: 32 },
})`
  color: ${colors.Text.Secondary};
`;

const LeftContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 14px;
`;

const RightContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const PanelItemContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;

const formatter = new Intl.NumberFormat("ja");

type Props = {
  summary: CouponAnalyticsSummary | undefined;
};

export const SummaryPanel = memo<Props>(({ summary }) => {
  const averageTableUserCustomersCount = useMemo(
    () =>
      !summary || summary.couponUsedCount === 0
        ? 0
        : Math.round((summary.visitedTableUserCustomersCount / summary.couponUsedCount) * 10) / 10,

    [summary],
  );

  if (!summary) return <Loading height={SUMMARY_PANEL_HEIGHT} />;

  const { couponUsedCount, visitedTableUserCustomersCount, visitedTableUserCustomersSales } =
    couponAnalyticsProperties;

  return (
    <Panel
      height={SUMMARY_PANEL_HEIGHT}
      right={
        <RightContainer>
          <PanelItem
            icon={visitedTableUserCustomersSales.iconSrc}
            title={visitedTableUserCustomersSales.label}
            value={
              <CountUp end={summary.visitedTableUserCustomersSales} separator="," duration={1} />
            }
            unit="円"
          />
        </RightContainer>
      }
      left={
        <LeftContainer>
          <PanelItemContainer>
            <PanelItem
              icon={couponUsedCount.iconSrc}
              title={couponUsedCount.label}
              value={formatter.format(summary.couponUsedCount)}
              unit="件"
            />
            <StyledRightOutlined />
          </PanelItemContainer>

          <PanelItemContainer>
            <PanelItem
              icon={visitedTableUserCustomersCount.iconSrc}
              title={visitedTableUserCustomersCount.label}
              value={formatter.format(summary.visitedTableUserCustomersCount)}
              subValue={`平均組客数 ${averageTableUserCustomersCount}人`}
              unit="人"
            />
            <StyledCaretRightOutlined />
          </PanelItemContainer>
        </LeftContainer>
      }
    />
  );
});
