import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { Dealers } from "components/Dealers";
import { Plan, Shop } from "types/graphql";

type Props = {
  plan:
    | (Pick<Plan, "planName" | "planId" | "totalDealingShopCount"> & {
        shopPlans: { shop: Pick<Shop, "shopId" | "name"> }[];
      })
    | null;
  onBack: () => void;
};

export const PikaichiPlanHeader = memo<Props>(({ plan, onBack }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[4];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  const dealers = plan?.shopPlans.flatMap(({ shop }) => shop);

  return (
    <>
      <PageHeader
        onBack={onBack}
        title={plan?.planName ?? ""}
        footer={
          <Dealers
            shops={dealers ?? null}
            hiddenShopCount={(plan?.totalDealingShopCount ?? 0) - (plan?.shopPlans.length ?? 0)}
          />
        }
      />
      {plan && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/pikaichi/plan/${plan.planId}/edit`} replace>
              プラン
            </Link>
          </Menu.Item>
          <Menu.Item key="option">
            <Link to={`/pikaichi/plan/${plan.planId}/option`} replace>
              オプション
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
