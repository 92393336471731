import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenuPrioritiesGetMenus = gql`
    query EditMenuPrioritiesGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      categoryId: _categoryId
      priority
      menu {
        menuId
        name
        shopSideName
      }
    }
  }
}
    `;
export const EditMenuPrioritiesGetCategories = gql`
    query EditMenuPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const EditMenuPrioritiesInsertCategoryMenus = gql`
    mutation EditMenuPrioritiesInsertCategoryMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type EditMenuPrioritiesGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuPrioritiesGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId' | 'priority'>
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name' | 'shopSideName'>
      ) }
    )> }
  )> }
);

export type EditMenuPrioritiesGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenuPrioritiesGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type EditMenuPrioritiesInsertCategoryMenusMutationVariables = Types.Exact<{
  categoryMenus: Array<Types.CategoryMenuInsertInput> | Types.CategoryMenuInsertInput;
}>;


export type EditMenuPrioritiesInsertCategoryMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_categoryMenu?: Types.Maybe<(
    { __typename?: 'categoryMenu_mutation_response' }
    & Pick<Types.CategoryMenuMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'categoryMenu' }
      & { menuId: Types.CategoryMenu['_menuId'] }
    )> }
  )> }
);


export const EditMenuPrioritiesGetMenusDocument = gql`
    query EditMenuPrioritiesGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      categoryId: _categoryId
      priority
      menu {
        menuId
        name
        shopSideName
      }
    }
  }
}
    `;

/**
 * __useEditMenuPrioritiesGetMenusQuery__
 *
 * To run a query within a React component, call `useEditMenuPrioritiesGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPrioritiesGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuPrioritiesGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuPrioritiesGetMenusQuery(baseOptions: Apollo.QueryHookOptions<EditMenuPrioritiesGetMenusQuery, EditMenuPrioritiesGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuPrioritiesGetMenusQuery, EditMenuPrioritiesGetMenusQueryVariables>(EditMenuPrioritiesGetMenusDocument, options);
      }
export function useEditMenuPrioritiesGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuPrioritiesGetMenusQuery, EditMenuPrioritiesGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuPrioritiesGetMenusQuery, EditMenuPrioritiesGetMenusQueryVariables>(EditMenuPrioritiesGetMenusDocument, options);
        }
export type EditMenuPrioritiesGetMenusQueryHookResult = ReturnType<typeof useEditMenuPrioritiesGetMenusQuery>;
export type EditMenuPrioritiesGetMenusLazyQueryHookResult = ReturnType<typeof useEditMenuPrioritiesGetMenusLazyQuery>;
export type EditMenuPrioritiesGetMenusQueryResult = Apollo.QueryResult<EditMenuPrioritiesGetMenusQuery, EditMenuPrioritiesGetMenusQueryVariables>;
export const EditMenuPrioritiesGetCategoriesDocument = gql`
    query EditMenuPrioritiesGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useEditMenuPrioritiesGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditMenuPrioritiesGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPrioritiesGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenuPrioritiesGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenuPrioritiesGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditMenuPrioritiesGetCategoriesQuery, EditMenuPrioritiesGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenuPrioritiesGetCategoriesQuery, EditMenuPrioritiesGetCategoriesQueryVariables>(EditMenuPrioritiesGetCategoriesDocument, options);
      }
export function useEditMenuPrioritiesGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenuPrioritiesGetCategoriesQuery, EditMenuPrioritiesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenuPrioritiesGetCategoriesQuery, EditMenuPrioritiesGetCategoriesQueryVariables>(EditMenuPrioritiesGetCategoriesDocument, options);
        }
export type EditMenuPrioritiesGetCategoriesQueryHookResult = ReturnType<typeof useEditMenuPrioritiesGetCategoriesQuery>;
export type EditMenuPrioritiesGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditMenuPrioritiesGetCategoriesLazyQuery>;
export type EditMenuPrioritiesGetCategoriesQueryResult = Apollo.QueryResult<EditMenuPrioritiesGetCategoriesQuery, EditMenuPrioritiesGetCategoriesQueryVariables>;
export const EditMenuPrioritiesInsertCategoryMenusDocument = gql`
    mutation EditMenuPrioritiesInsertCategoryMenus($categoryMenus: [categoryMenu_insert_input!]!) {
  insert_categoryMenu(
    objects: $categoryMenus
    on_conflict: {constraint: categoryMenu_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type EditMenuPrioritiesInsertCategoryMenusMutationFn = Apollo.MutationFunction<EditMenuPrioritiesInsertCategoryMenusMutation, EditMenuPrioritiesInsertCategoryMenusMutationVariables>;

/**
 * __useEditMenuPrioritiesInsertCategoryMenusMutation__
 *
 * To run a mutation, you first call `useEditMenuPrioritiesInsertCategoryMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMenuPrioritiesInsertCategoryMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMenuPrioritiesInsertCategoryMenusMutation, { data, loading, error }] = useEditMenuPrioritiesInsertCategoryMenusMutation({
 *   variables: {
 *      categoryMenus: // value for 'categoryMenus'
 *   },
 * });
 */
export function useEditMenuPrioritiesInsertCategoryMenusMutation(baseOptions?: Apollo.MutationHookOptions<EditMenuPrioritiesInsertCategoryMenusMutation, EditMenuPrioritiesInsertCategoryMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMenuPrioritiesInsertCategoryMenusMutation, EditMenuPrioritiesInsertCategoryMenusMutationVariables>(EditMenuPrioritiesInsertCategoryMenusDocument, options);
      }
export type EditMenuPrioritiesInsertCategoryMenusMutationHookResult = ReturnType<typeof useEditMenuPrioritiesInsertCategoryMenusMutation>;
export type EditMenuPrioritiesInsertCategoryMenusMutationResult = Apollo.MutationResult<EditMenuPrioritiesInsertCategoryMenusMutation>;
export type EditMenuPrioritiesInsertCategoryMenusMutationOptions = Apollo.BaseMutationOptions<EditMenuPrioritiesInsertCategoryMenusMutation, EditMenuPrioritiesInsertCategoryMenusMutationVariables>;