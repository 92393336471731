export const groupBy = <T>(array: T[], getKey: string | ((object: T) => string)) => {
  const accumulator: Record<string, T[]> = {};
  array.forEach((v) => {
    const key = typeof getKey === "string" ? getKey : getKey(v);
    const objects = accumulator[key] ?? [];
    accumulator[key] = [...objects, v];
  });
  return accumulator;
};

export const move = <T>(array: T[], from: number, to: number) => {
  const a = [...array];
  const args = a.splice(from, 1)[0];
  if (!args) {
    throw new Error("Do not access to undefined index");
  }
  a.splice(to, 0, args);
  return a;
};

export const sum = (array: number[]) => array.reduce((p, c) => p + c, 0);

export const sumBy = <T extends Record<string, any>>(
  array: T[],
  getValue: keyof T | ((object: T) => number),
) =>
  array.reduce((p, c) => {
    const value = typeof getValue === "function" ? getValue(c) : c[getValue];
    return p + value;
  }, 0);

export const uniq = <T>(array: T[]) => {
  const map = new Map<T, T>();
  for (const e of array) map.set(e, e);
  return Array.from(map.values());
};

export const uniqBy = <T extends Record<string, any>>(
  array: T[],
  getValue: keyof T | ((object: T) => any),
) => {
  const getValueFromArray = (e: T) => (typeof getValue === "function" ? getValue(e) : e[getValue]);
  const map = new Map<any, T>();
  for (const e of array) map.set(getValueFromArray(e), e);
  return Array.from(map.values());
};

export const count = <T extends string | number | symbol>(array: T[]): Record<T, number> => {
  const accumulator = {} as Record<T, number>;
  array.forEach((v) => (accumulator[v] = (accumulator[v] ?? 0) + 1));
  return accumulator;
};

export const range = (length: number) => Array.from({ length }, (v, k) => k);

export const isEqualWith = <T>({
  array,
  other,
  getIdentifier,
}: {
  array: T[];
  other: T[];
  getIdentifier?: (value: T) => string | number;
}) => {
  if (array.length !== other.length) return false;

  return array.every((value, index) => {
    const otherValue = other[index];
    if (otherValue === undefined) return false;

    return getIdentifier
      ? getIdentifier(otherValue) === getIdentifier(value)
      : otherValue === value;
  });
};
