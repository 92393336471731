import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanOptionModalGetMaxPlanOptionPriority = gql`
    query AddPlanOptionModalGetMaxPlanOptionPriority($planId: Int!) {
  planOption_aggregate(where: {_planId: {_eq: $planId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddPlanOptionModalInsertPlanOption = gql`
    mutation AddPlanOptionModalInsertPlanOption($planOption: CreatePlanOptionInput!) {
  createPlanOption(input: $planOption) {
    planOptionId
  }
}
    `;
export type AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type AddPlanOptionModalGetMaxPlanOptionPriorityQuery = (
  { __typename?: 'query_root' }
  & { planOption_aggregate: (
    { __typename?: 'planOption_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'planOption_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'planOption_max_fields' }
        & Pick<Types.PlanOptionMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddPlanOptionModalInsertPlanOptionMutationVariables = Types.Exact<{
  planOption: Types.CreatePlanOptionInput;
}>;


export type AddPlanOptionModalInsertPlanOptionMutation = (
  { __typename?: 'mutation_root' }
  & { createPlanOption: (
    { __typename?: 'CreatePlanOptionOutput' }
    & Pick<Types.CreatePlanOptionOutput, 'planOptionId'>
  ) }
);


export const AddPlanOptionModalGetMaxPlanOptionPriorityDocument = gql`
    query AddPlanOptionModalGetMaxPlanOptionPriority($planId: Int!) {
  planOption_aggregate(where: {_planId: {_eq: $planId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddPlanOptionModalGetMaxPlanOptionPriorityQuery__
 *
 * To run a query within a React component, call `useAddPlanOptionModalGetMaxPlanOptionPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanOptionModalGetMaxPlanOptionPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanOptionModalGetMaxPlanOptionPriorityQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useAddPlanOptionModalGetMaxPlanOptionPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddPlanOptionModalGetMaxPlanOptionPriorityQuery, AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanOptionModalGetMaxPlanOptionPriorityQuery, AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables>(AddPlanOptionModalGetMaxPlanOptionPriorityDocument, options);
      }
export function useAddPlanOptionModalGetMaxPlanOptionPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanOptionModalGetMaxPlanOptionPriorityQuery, AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanOptionModalGetMaxPlanOptionPriorityQuery, AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables>(AddPlanOptionModalGetMaxPlanOptionPriorityDocument, options);
        }
export type AddPlanOptionModalGetMaxPlanOptionPriorityQueryHookResult = ReturnType<typeof useAddPlanOptionModalGetMaxPlanOptionPriorityQuery>;
export type AddPlanOptionModalGetMaxPlanOptionPriorityLazyQueryHookResult = ReturnType<typeof useAddPlanOptionModalGetMaxPlanOptionPriorityLazyQuery>;
export type AddPlanOptionModalGetMaxPlanOptionPriorityQueryResult = Apollo.QueryResult<AddPlanOptionModalGetMaxPlanOptionPriorityQuery, AddPlanOptionModalGetMaxPlanOptionPriorityQueryVariables>;
export const AddPlanOptionModalInsertPlanOptionDocument = gql`
    mutation AddPlanOptionModalInsertPlanOption($planOption: CreatePlanOptionInput!) {
  createPlanOption(input: $planOption) {
    planOptionId
  }
}
    `;
export type AddPlanOptionModalInsertPlanOptionMutationFn = Apollo.MutationFunction<AddPlanOptionModalInsertPlanOptionMutation, AddPlanOptionModalInsertPlanOptionMutationVariables>;

/**
 * __useAddPlanOptionModalInsertPlanOptionMutation__
 *
 * To run a mutation, you first call `useAddPlanOptionModalInsertPlanOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanOptionModalInsertPlanOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanOptionModalInsertPlanOptionMutation, { data, loading, error }] = useAddPlanOptionModalInsertPlanOptionMutation({
 *   variables: {
 *      planOption: // value for 'planOption'
 *   },
 * });
 */
export function useAddPlanOptionModalInsertPlanOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanOptionModalInsertPlanOptionMutation, AddPlanOptionModalInsertPlanOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanOptionModalInsertPlanOptionMutation, AddPlanOptionModalInsertPlanOptionMutationVariables>(AddPlanOptionModalInsertPlanOptionDocument, options);
      }
export type AddPlanOptionModalInsertPlanOptionMutationHookResult = ReturnType<typeof useAddPlanOptionModalInsertPlanOptionMutation>;
export type AddPlanOptionModalInsertPlanOptionMutationResult = Apollo.MutationResult<AddPlanOptionModalInsertPlanOptionMutation>;
export type AddPlanOptionModalInsertPlanOptionMutationOptions = Apollo.BaseMutationOptions<AddPlanOptionModalInsertPlanOptionMutation, AddPlanOptionModalInsertPlanOptionMutationVariables>;