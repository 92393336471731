import { useCallback } from "react";
import { PaginationConfig } from "antd/lib/pagination";
import { removeNullableEntries } from "util/object";

import { useLocationState } from "hooks/useLocationState";

const defaultPageSizeKey = "defaultPageSize";

type LocationState = {
  page?: number;
};

export type Pagination = {
  current: number;
  defaultPageSize: number;
  defaultCurrent: number;
  showSizeChanger: boolean;
};

export const usePagination = () => {
  const [locationState, setLocationState] = useLocationState<LocationState>("pagination", {});

  const defaultCurrent = locationState.page;
  const defaultPageSize = Number(localStorage.getItem(defaultPageSizeKey)) || undefined;

  const pagination: Pagination = removeNullableEntries({
    current: defaultCurrent,
    defaultPageSize,
    defaultCurrent,
    showSizeChanger: true,
  });

  const setPagination = useCallback(
    ({ pageSize, current }: PaginationConfig) => {
      if (pageSize) {
        localStorage.setItem(defaultPageSizeKey, String(pageSize));
      }

      if (current) {
        setLocationState({ page: current });
      }
    },
    [setLocationState],
  );

  return [pagination, setPagination] as const;
};
