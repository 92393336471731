import React, { memo, useMemo } from "react";
import { Switch, Tag } from "antd";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { FormHelp } from "components/Form/FormHelp";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useShop } from "hooks/useShop";
import { useViewport } from "hooks/useViewport";
import { Shop, ShopPlan } from "pages/EditPlanDealers/types";

import { DealingSwitch } from "../DealingSwitch";

export type PlanTableDisplayType = "compact" | "detail";

type Props = {
  loading?: boolean;
  shops: Shop[];
  shopPlans: ShopPlan[];
  onSelectShopId({ shopId }: { shopId: string }): void;
  onDeselectShopId({ shopId }: { shopId: string }): void;
  onCheckIsVisibleForCustomer({
    shopId,
    isVisibleForCustomer,
  }: {
    shopId: string;
    isVisibleForCustomer: boolean;
  }): void;
  onCheckIsVisibleForStaff({
    shopId,
    isVisibleForStaff,
  }: {
    shopId: string;
    isVisibleForStaff: boolean;
  }): void;
  onCheckIsSoldOut(shopId: string, isSoldOut: boolean): void;
};

const createColumns = ({
  shopIdToShopPlan,
  onCheckIsVisibleForCustomer,
  onCheckIsVisibleForStaff,
  onCheckIsSoldOut,
  onSelectShopId,
  onDeselectShopId,
  setShop,
  useFixedColumns,
  canEditPlanDealers,
  canEditShopPlanStock,
  canEditShopPlanDisplaySettings,
  canAccessToEditShopPlanRoles,
}: Pick<
  Props,
  | "onCheckIsVisibleForCustomer"
  | "onCheckIsVisibleForStaff"
  | "onCheckIsSoldOut"
  | "onSelectShopId"
  | "onDeselectShopId"
> & {
  shopIdToShopPlan: { [shopId: string]: ShopPlan };
  setShop(shopId: string): void;
  useFixedColumns: boolean;
  canEditPlanDealers: boolean;
  canEditShopPlanStock: boolean;
  canEditShopPlanDisplaySettings: boolean;
  canAccessToEditShopPlanRoles: boolean;
}) => [
  {
    title: "取扱",
    width: 100,
    align: "center" as const,
    fixed: useFixedColumns ? ("left" as const) : undefined,
    render(_: string, { shopId }: Shop) {
      const shopPlan = shopIdToShopPlan[shopId];
      return (
        <DealingSwitch
          shopPlan={shopPlan}
          shopId={shopId}
          onSelectShopId={onSelectShopId}
          onDeselectShopId={onDeselectShopId}
          disabled={!canEditPlanDealers}
        />
      );
    },
  },
  {
    title: "店舗名",
    dataIndex: "name",
    width: 240,
    fixed: useFixedColumns ? ("left" as const) : undefined,
  },
  {
    title: "公開設定",
    children: [
      {
        title() {
          return <FormHelp label="お客様" help="スマートフォン上での表示設定" />;
        },
        width: 100,
        align: "center",
        render(_: string, { shopId }: Shop) {
          const shopPlan = shopIdToShopPlan[shopId];
          if (!shopPlan) return null;

          return (
            <Switch
              checked={shopPlan.isVisibleForCustomer}
              onChange={(checked) =>
                onCheckIsVisibleForCustomer({ shopId, isVisibleForCustomer: checked })
              }
              disabled={!canEditShopPlanDisplaySettings}
            />
          );
        },
      },
      {
        title() {
          return <FormHelp label="スタッフ" help="ハンディ上での表示設定" />;
        },
        width: 120,
        align: "center",
        render(_: string, { shopId }: Shop) {
          const shopPlan = shopIdToShopPlan[shopId];
          if (!shopPlan) return null;

          return (
            <Switch
              checked={shopPlan.isVisibleForStaff}
              onChange={(checked) =>
                onCheckIsVisibleForStaff({ shopId, isVisibleForStaff: checked })
              }
              disabled={!canEditShopPlanDisplaySettings}
            />
          );
        },
      },
    ],
  },
  {
    title: "在庫設定",
    children: [
      {
        title: "在庫",
        width: 100,
        align: "center",
        render(_: string, { shopId }: Shop) {
          const shopPlan = shopIdToShopPlan[shopId];
          if (!shopPlan) return null;
          const isSoldOut = shopPlan.stock?.currentStockNum === 0;
          return (
            <Switch
              checked={!isSoldOut}
              onChange={(checked) => onCheckIsSoldOut(shopId, !checked)}
              disabled={!canEditShopPlanStock}
            />
          );
        },
      },
    ],
  },
  {
    title: "印刷設定",
    children: [
      {
        title: "プリンター",
        width: 160,
        render(_: string, { shopId }: Shop) {
          const shopPlan = shopIdToShopPlan[shopId];
          if (!shopPlan) return null;
          return shopPlan.shopPlanKitchenRoles.length === 0 ? (
            <Tag>設定なし</Tag>
          ) : (
            shopPlan.shopPlanKitchenRoles.map((role) => (
              <Tag color="blue" key={role.role?.roleId ?? 0}>
                {role.role?.name}
              </Tag>
            ))
          );
        },
      },
      {
        title: "デシャップグループ",
        width: 160,
        render(_: string, { shopId }: Shop) {
          const shopPlan = shopIdToShopPlan[shopId];
          if (!shopPlan) return null;
          return shopPlan.dishUpSlipGroupShopPlans.length === 0 ? (
            <Tag>設定なし</Tag>
          ) : (
            shopPlan.dishUpSlipGroupShopPlans.map((dishUp) => (
              <Tag color="blue" key={dishUp.dishUpSlipGroup?.id ?? 0}>
                {dishUp.dishUpSlipGroup?.name}
              </Tag>
            ))
          );
        },
      },
      ...(canAccessToEditShopPlanRoles
        ? [
            {
              title: "",
              align: "center",
              width: 60,
              render(_: string, { shopId }: Shop) {
                const shopPlan = shopIdToShopPlan[shopId];
                if (!shopPlan) return null;
                return (
                  <IconLink to={`/shop/${shopId}/plan/${shopPlan._planId}/role/edit`}>
                    <EditIcon onClick={() => setShop(shopId)} />
                  </IconLink>
                );
              },
            } as const,
          ]
        : []),
    ],
  },
];

export const ShopPlanTable = memo<Props>(
  ({
    loading,
    shopPlans,
    shops,
    onSelectShopId,
    onDeselectShopId,
    onCheckIsVisibleForCustomer,
    onCheckIsVisibleForStaff,
    onCheckIsSoldOut,
  }) => {
    const [pagination, setPagination] = usePagination();

    const shopIdToShopPlan = useMemo(
      () => Object.fromEntries<ShopPlan>(shopPlans.map((shopPlan) => [shopPlan.shopId, shopPlan])),
      [shopPlans],
    );

    const [, setShop] = useShop();

    const { isDesktop } = useViewport();

    const { canAccess } = useCanAccess();
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = useMemo(
      () =>
        createColumns({
          shopIdToShopPlan,
          onSelectShopId,
          onDeselectShopId,
          onCheckIsVisibleForCustomer,
          onCheckIsVisibleForStaff,
          onCheckIsSoldOut,
          setShop,
          useFixedColumns: isDesktop,
          canEditPlanDealers: isFeatureEnabled("editPlanDealer"),
          canEditShopPlanDisplaySettings: isFeatureEnabled("editShopPlanDisplaySettings"),
          canEditShopPlanStock: isFeatureEnabled("editShopPlanStock"),
          canAccessToEditShopPlanRoles: canAccess("editShopPlanRoles"),
        }),
      [
        shopIdToShopPlan,
        onSelectShopId,
        onDeselectShopId,
        onCheckIsVisibleForCustomer,
        onCheckIsVisibleForStaff,
        onCheckIsSoldOut,
        setShop,
        isDesktop,
        isFeatureEnabled,
        canAccess,
      ],
    );

    return (
      <Table
        loading={loading}
        rowClassName="row-class-name"
        dataSource={shops}
        columns={columns}
        rowKey="shopId"
        bordered
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        pagination={pagination}
      />
    );
  },
);
