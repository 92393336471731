import React, { memo, useCallback } from "react";
import { FormInstance, NamePath } from "rc-field-form/lib/interface";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

const MIN_CHOICE_NUM_FIELD = "minChoiceNum";
const MAX_CHOICE_NUM_FIELD = "maxChoiceNum";

type Props = FormItemProps & {
  maxChoiceNumFieldName?: NamePath;
  disabled?: boolean;
};

export const MinChoiceNumWithMaxChoiceNumField = memo<Props>(
  ({
    children,
    maxChoiceNumFieldName = MAX_CHOICE_NUM_FIELD,
    disabled = false,
    rules = [],
    ...props
  }) => {
    const maxChoiceNumWithMinChoiceNum = useCallback(
      (form: FormInstance) => {
        // NOTE: 最小選択数が最大選択数より大きい場合にエラーを出す
        const maxChoiceNum: number | null = form.getFieldValue(maxChoiceNumFieldName) ?? null;
        return {
          async validator(_: unknown, value: number | null) {
            if (maxChoiceNum === null || !value || value <= maxChoiceNum) return;
            throw new Error("最大選択数未満の値を入力してください");
          },
        };
      },
      [maxChoiceNumFieldName],
    );
    return (
      <FormItem
        name={props.name ?? MIN_CHOICE_NUM_FIELD}
        rules={disabled ? [] : [...rules, maxChoiceNumWithMinChoiceNum]}
        // NOTE: maxChoiceNumFieldName の変更時にバリデーションの再検証を走らせるため（shouldUpdate では再レンダリングはされるがバリデーションが実行されず代用不可）
        // eslint-disable-next-line react/forbid-component-props
        dependencies={[maxChoiceNumFieldName]}
        {...props}
      >
        {children}
      </FormItem>
    );
  },
);
