import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddPlanGetCategoriesAndPlans = gql`
    query AddPlanGetCategoriesAndPlans($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    order_by: [{planName: asc}, {planId: asc}]
  ) {
    imageUrl
    planCategoryId
    category {
      categoryId
      name
    }
    planId
    planName
    receiptDisplayName
    shopSideName
    orderableTimeId
    planGroupId: _planGroupId
    description
    planTime
    featuredLabelText
    displayType
    taxMethod
    taxRate
    translations(where: {tableName: {_eq: "plan"}}) {
      columnName
      locale
      value
    }
    endNotificationThresholdMinutes
  }
}
    `;
export const AddPlanGetOrderableTimes = gql`
    query AddPlanGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddPlanGetPlanGroups = gql`
    query AddPlanGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}) {
    name
    planGroupId
  }
}
    `;
export const AddPlanInsertPlan = gql`
    mutation AddPlanInsertPlan($plan: CreatePlanInput!) {
  createPlan(input: $plan) {
    planId
  }
}
    `;
export type AddPlanGetCategoriesAndPlansQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanGetCategoriesAndPlansQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )>, plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'imageUrl' | 'planCategoryId' | 'planId' | 'planName' | 'receiptDisplayName' | 'shopSideName' | 'orderableTimeId' | 'description' | 'planTime' | 'featuredLabelText' | 'displayType' | 'taxMethod' | 'taxRate' | 'endNotificationThresholdMinutes'>
    & { planGroupId: Types.Plan['_planGroupId'] }
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )> }
  )> }
);

export type AddPlanGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type AddPlanGetPlanGroupsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddPlanGetPlanGroupsQuery = (
  { __typename?: 'query_root' }
  & { planGroup: Array<(
    { __typename?: 'planGroup' }
    & Pick<Types.PlanGroup, 'name' | 'planGroupId'>
  )> }
);

export type AddPlanInsertPlanMutationVariables = Types.Exact<{
  plan: Types.CreatePlanInput;
}>;


export type AddPlanInsertPlanMutation = (
  { __typename?: 'mutation_root' }
  & { createPlan: (
    { __typename?: 'CreatePlanOutput' }
    & Pick<Types.CreatePlanOutput, 'planId'>
  ) }
);


export const AddPlanGetCategoriesAndPlansDocument = gql`
    query AddPlanGetCategoriesAndPlans($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
  plan(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    order_by: [{planName: asc}, {planId: asc}]
  ) {
    imageUrl
    planCategoryId
    category {
      categoryId
      name
    }
    planId
    planName
    receiptDisplayName
    shopSideName
    orderableTimeId
    planGroupId: _planGroupId
    description
    planTime
    featuredLabelText
    displayType
    taxMethod
    taxRate
    translations(where: {tableName: {_eq: "plan"}}) {
      columnName
      locale
      value
    }
    endNotificationThresholdMinutes
  }
}
    `;

/**
 * __useAddPlanGetCategoriesAndPlansQuery__
 *
 * To run a query within a React component, call `useAddPlanGetCategoriesAndPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanGetCategoriesAndPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanGetCategoriesAndPlansQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanGetCategoriesAndPlansQuery(baseOptions: Apollo.QueryHookOptions<AddPlanGetCategoriesAndPlansQuery, AddPlanGetCategoriesAndPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanGetCategoriesAndPlansQuery, AddPlanGetCategoriesAndPlansQueryVariables>(AddPlanGetCategoriesAndPlansDocument, options);
      }
export function useAddPlanGetCategoriesAndPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanGetCategoriesAndPlansQuery, AddPlanGetCategoriesAndPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanGetCategoriesAndPlansQuery, AddPlanGetCategoriesAndPlansQueryVariables>(AddPlanGetCategoriesAndPlansDocument, options);
        }
export type AddPlanGetCategoriesAndPlansQueryHookResult = ReturnType<typeof useAddPlanGetCategoriesAndPlansQuery>;
export type AddPlanGetCategoriesAndPlansLazyQueryHookResult = ReturnType<typeof useAddPlanGetCategoriesAndPlansLazyQuery>;
export type AddPlanGetCategoriesAndPlansQueryResult = Apollo.QueryResult<AddPlanGetCategoriesAndPlansQuery, AddPlanGetCategoriesAndPlansQueryVariables>;
export const AddPlanGetOrderableTimesDocument = gql`
    query AddPlanGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddPlanGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useAddPlanGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<AddPlanGetOrderableTimesQuery, AddPlanGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanGetOrderableTimesQuery, AddPlanGetOrderableTimesQueryVariables>(AddPlanGetOrderableTimesDocument, options);
      }
export function useAddPlanGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanGetOrderableTimesQuery, AddPlanGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanGetOrderableTimesQuery, AddPlanGetOrderableTimesQueryVariables>(AddPlanGetOrderableTimesDocument, options);
        }
export type AddPlanGetOrderableTimesQueryHookResult = ReturnType<typeof useAddPlanGetOrderableTimesQuery>;
export type AddPlanGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useAddPlanGetOrderableTimesLazyQuery>;
export type AddPlanGetOrderableTimesQueryResult = Apollo.QueryResult<AddPlanGetOrderableTimesQuery, AddPlanGetOrderableTimesQueryVariables>;
export const AddPlanGetPlanGroupsDocument = gql`
    query AddPlanGetPlanGroups($companyId: uuid!) {
  planGroup(where: {companyId: {_eq: $companyId}}) {
    name
    planGroupId
  }
}
    `;

/**
 * __useAddPlanGetPlanGroupsQuery__
 *
 * To run a query within a React component, call `useAddPlanGetPlanGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPlanGetPlanGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPlanGetPlanGroupsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddPlanGetPlanGroupsQuery(baseOptions: Apollo.QueryHookOptions<AddPlanGetPlanGroupsQuery, AddPlanGetPlanGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddPlanGetPlanGroupsQuery, AddPlanGetPlanGroupsQueryVariables>(AddPlanGetPlanGroupsDocument, options);
      }
export function useAddPlanGetPlanGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddPlanGetPlanGroupsQuery, AddPlanGetPlanGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddPlanGetPlanGroupsQuery, AddPlanGetPlanGroupsQueryVariables>(AddPlanGetPlanGroupsDocument, options);
        }
export type AddPlanGetPlanGroupsQueryHookResult = ReturnType<typeof useAddPlanGetPlanGroupsQuery>;
export type AddPlanGetPlanGroupsLazyQueryHookResult = ReturnType<typeof useAddPlanGetPlanGroupsLazyQuery>;
export type AddPlanGetPlanGroupsQueryResult = Apollo.QueryResult<AddPlanGetPlanGroupsQuery, AddPlanGetPlanGroupsQueryVariables>;
export const AddPlanInsertPlanDocument = gql`
    mutation AddPlanInsertPlan($plan: CreatePlanInput!) {
  createPlan(input: $plan) {
    planId
  }
}
    `;
export type AddPlanInsertPlanMutationFn = Apollo.MutationFunction<AddPlanInsertPlanMutation, AddPlanInsertPlanMutationVariables>;

/**
 * __useAddPlanInsertPlanMutation__
 *
 * To run a mutation, you first call `useAddPlanInsertPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanInsertPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanInsertPlanMutation, { data, loading, error }] = useAddPlanInsertPlanMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useAddPlanInsertPlanMutation(baseOptions?: Apollo.MutationHookOptions<AddPlanInsertPlanMutation, AddPlanInsertPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlanInsertPlanMutation, AddPlanInsertPlanMutationVariables>(AddPlanInsertPlanDocument, options);
      }
export type AddPlanInsertPlanMutationHookResult = ReturnType<typeof useAddPlanInsertPlanMutation>;
export type AddPlanInsertPlanMutationResult = Apollo.MutationResult<AddPlanInsertPlanMutation>;
export type AddPlanInsertPlanMutationOptions = Apollo.BaseMutationOptions<AddPlanInsertPlanMutation, AddPlanInsertPlanMutationVariables>;