import dayjs from "dayjs";
import { addHours, getHours } from "util/duration";
import { normalizeHoursAndMinutes } from "util/time";

import { BusinessOperationHourFormValues } from "pages/EditShopBusinessOperationHour/EditShopBusinessOperationHourForm/useEditShopBusinessOperationHourForm";
import { BusinessOperationHourTypeEnum } from "types/graphql";

export const businessOperationHourTypeToWord: Record<string, string> = {
  morning: "モーニング",
  lunch: "ランチ",
  cafe: "カフェ",
  happyHour: "ハッピーアワー",
  dinner: "ディナー",
  midnight: "深夜",
};

const businessOperationHourTypeOrder = [
  "morning",
  "lunch",
  "cafe",
  "happyHour",
  "dinner",
  "midnight",
];

export const sortBusinessOperationHourTypes = (types: BusinessOperationHourTypeEnum[]) =>
  types.sort((a, b) =>
    businessOperationHourTypeOrder.indexOf(a) > businessOperationHourTypeOrder.indexOf(b) ? 1 : -1,
  );

export const isValidBusinessOperationHourDuration = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const businessOperationHourStart = startTime.isBefore(baseTime)
    ? startTime.add(24, "hours")
    : startTime;
  const businessOperationHourEnd = endTime.isSameOrBefore(baseTime)
    ? endTime.add(24, "hours")
    : endTime;

  return businessOperationHourStart.isBefore(businessOperationHourEnd);
};

export const isValidBusinessOperationHourInterval = ({
  end,
  nextStart,
  changeDateTime,
}: {
  end: dayjs.Dayjs;
  nextStart: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const endTime = normalizeHoursAndMinutes(end);
  const nextStartTime = normalizeHoursAndMinutes(nextStart);

  const businessOperationHourEnd = endTime.isSameOrBefore(baseTime)
    ? endTime.add(24, "hours")
    : endTime;
  const businessOperationHourNextStartTime = nextStartTime.isBefore(baseTime)
    ? nextStartTime.add(24, "hours")
    : nextStartTime;

  return businessOperationHourEnd.isSameOrBefore(businessOperationHourNextStartTime);
};

export const getDateTimeBasedOnChangeDateTime = ({
  dateTime,
  changeDateTime,
}: {
  dateTime: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const time = normalizeHoursAndMinutes(dateTime);
  return time.isBefore(baseTime) ? addHours(time.format("HH:mm"), 24) : time.format("HH:mm");
};

export const isDateChanged = ({
  dateTime,
  changeDateTime,
}: {
  dateTime: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const time = normalizeHoursAndMinutes(dateTime);
  return time.isBefore(baseTime);
};

export const sortShopBusinessOperationHours = ({
  shopBusinessOperationHours,
  changeDateTime,
}: {
  shopBusinessOperationHours: BusinessOperationHourFormValues[];
  changeDateTime: string;
}): BusinessOperationHourFormValues[] => {
  const baseTime = getHours(changeDateTime);
  return shopBusinessOperationHours.sort((a, b) => {
    if (!a.start || !b.start) return 0;
    const normalizedStartA = normalizeHoursAndMinutes(a.start);
    const normalizedStartB = normalizeHoursAndMinutes(b.start);
    const startA = normalizedStartA.isBefore(baseTime)
      ? normalizedStartA.add(24, "hours")
      : normalizedStartA;
    const startB = normalizedStartB.isBefore(baseTime)
      ? normalizedStartB.add(24, "hours")
      : normalizedStartB;
    return startA.isAfter(startB) ? 1 : -1;
  });
};
