import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanGroupPlansGetPlanGroup = gql`
    query PlanGroupPlansGetPlanGroup($planGroupId: Int!) {
  planGroup(where: {planGroupId: {_eq: $planGroupId}}, limit: 1) {
    companyId
    name
    planGroupId
    plans {
      planId
      planName
      planGroupId: _planGroupId
    }
  }
}
    `;
export const PlanGroupPlansDeletePlanGroup = gql`
    mutation PlanGroupPlansDeletePlanGroup($planId: Int!) {
  update_plan(_set: {_planGroupId: null}, where: {planId: {_eq: $planId}}) {
    affected_rows
  }
}
    `;
export type PlanGroupPlansGetPlanGroupQueryVariables = Types.Exact<{
  planGroupId: Types.Scalars['Int'];
}>;


export type PlanGroupPlansGetPlanGroupQuery = (
  { __typename?: 'query_root' }
  & { planGroup: Array<(
    { __typename?: 'planGroup' }
    & Pick<Types.PlanGroup, 'companyId' | 'name' | 'planGroupId'>
    & { plans: Array<(
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'planId' | 'planName'>
      & { planGroupId: Types.Plan['_planGroupId'] }
    )> }
  )> }
);

export type PlanGroupPlansDeletePlanGroupMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PlanGroupPlansDeletePlanGroupMutation = (
  { __typename?: 'mutation_root' }
  & { update_plan?: Types.Maybe<(
    { __typename?: 'plan_mutation_response' }
    & Pick<Types.PlanMutationResponse, 'affected_rows'>
  )> }
);


export const PlanGroupPlansGetPlanGroupDocument = gql`
    query PlanGroupPlansGetPlanGroup($planGroupId: Int!) {
  planGroup(where: {planGroupId: {_eq: $planGroupId}}, limit: 1) {
    companyId
    name
    planGroupId
    plans {
      planId
      planName
      planGroupId: _planGroupId
    }
  }
}
    `;

/**
 * __usePlanGroupPlansGetPlanGroupQuery__
 *
 * To run a query within a React component, call `usePlanGroupPlansGetPlanGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanGroupPlansGetPlanGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanGroupPlansGetPlanGroupQuery({
 *   variables: {
 *      planGroupId: // value for 'planGroupId'
 *   },
 * });
 */
export function usePlanGroupPlansGetPlanGroupQuery(baseOptions: Apollo.QueryHookOptions<PlanGroupPlansGetPlanGroupQuery, PlanGroupPlansGetPlanGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanGroupPlansGetPlanGroupQuery, PlanGroupPlansGetPlanGroupQueryVariables>(PlanGroupPlansGetPlanGroupDocument, options);
      }
export function usePlanGroupPlansGetPlanGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanGroupPlansGetPlanGroupQuery, PlanGroupPlansGetPlanGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanGroupPlansGetPlanGroupQuery, PlanGroupPlansGetPlanGroupQueryVariables>(PlanGroupPlansGetPlanGroupDocument, options);
        }
export type PlanGroupPlansGetPlanGroupQueryHookResult = ReturnType<typeof usePlanGroupPlansGetPlanGroupQuery>;
export type PlanGroupPlansGetPlanGroupLazyQueryHookResult = ReturnType<typeof usePlanGroupPlansGetPlanGroupLazyQuery>;
export type PlanGroupPlansGetPlanGroupQueryResult = Apollo.QueryResult<PlanGroupPlansGetPlanGroupQuery, PlanGroupPlansGetPlanGroupQueryVariables>;
export const PlanGroupPlansDeletePlanGroupDocument = gql`
    mutation PlanGroupPlansDeletePlanGroup($planId: Int!) {
  update_plan(_set: {_planGroupId: null}, where: {planId: {_eq: $planId}}) {
    affected_rows
  }
}
    `;
export type PlanGroupPlansDeletePlanGroupMutationFn = Apollo.MutationFunction<PlanGroupPlansDeletePlanGroupMutation, PlanGroupPlansDeletePlanGroupMutationVariables>;

/**
 * __usePlanGroupPlansDeletePlanGroupMutation__
 *
 * To run a mutation, you first call `usePlanGroupPlansDeletePlanGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanGroupPlansDeletePlanGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planGroupPlansDeletePlanGroupMutation, { data, loading, error }] = usePlanGroupPlansDeletePlanGroupMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanGroupPlansDeletePlanGroupMutation(baseOptions?: Apollo.MutationHookOptions<PlanGroupPlansDeletePlanGroupMutation, PlanGroupPlansDeletePlanGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanGroupPlansDeletePlanGroupMutation, PlanGroupPlansDeletePlanGroupMutationVariables>(PlanGroupPlansDeletePlanGroupDocument, options);
      }
export type PlanGroupPlansDeletePlanGroupMutationHookResult = ReturnType<typeof usePlanGroupPlansDeletePlanGroupMutation>;
export type PlanGroupPlansDeletePlanGroupMutationResult = Apollo.MutationResult<PlanGroupPlansDeletePlanGroupMutation>;
export type PlanGroupPlansDeletePlanGroupMutationOptions = Apollo.BaseMutationOptions<PlanGroupPlansDeletePlanGroupMutation, PlanGroupPlansDeletePlanGroupMutationVariables>;