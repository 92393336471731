import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { grey } from "constants/colors";
import { UpsertTranslationsForMenuRecommendationMetaInput } from "types/graphql";

import { MenuRecommendation, Translation } from "../types";

import { NameCnField } from "./NameCnField";
import { NameEnField } from "./NameEnField";
import { NameField } from "./NameField";
import { NameKrField } from "./NameKrField";
import { useEditMenuRecommendationTranslationsForm } from "./useEditMenuRecommendationTranslationsForm";

const FormWrapper = styled.div`
  background-color: ${grey[0]};
  padding: 24px;
  margin-bottom: 24px;
`;

type Props = {
  menuRecommendation: MenuRecommendation;
  translations: Translation[];
  onSubmit: (inputs: UpsertTranslationsForMenuRecommendationMetaInput) => Promise<void>;
  onClose: () => void;
  onFormValidationError: () => void;
};

export const EditMenuRecommendationTranslationsForm = memo<Props>(
  ({ menuRecommendation, translations, onSubmit, onClose, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditMenuRecommendationTranslationsForm({
      menuRecommendation,
      onSubmit,
      translations,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [submit, form, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormWrapper>
          <Form form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <NameEnField />
            <NameCnField />
            <NameKrField />
          </Form>
        </FormWrapper>

        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
