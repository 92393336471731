import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriority = gql`
    query AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriority($companyId: uuid!) {
  onSitePaymentDiscountType_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountType = gql`
    mutation AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountType($onSitePaymentDiscountType: onSitePaymentDiscountType_insert_input!) {
  insert_onSitePaymentDiscountType_one(
    object: $onSitePaymentDiscountType
    on_conflict: {constraint: onSitePaymentDiscountType_pkey, update_columns: [isArchived]}
  ) {
    __typename
  }
}
    `;
export type AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDiscountType_aggregate: (
    { __typename?: 'onSitePaymentDiscountType_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'onSitePaymentDiscountType_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'onSitePaymentDiscountType_max_fields' }
        & Pick<Types.OnSitePaymentDiscountTypeMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationVariables = Types.Exact<{
  onSitePaymentDiscountType: Types.OnSitePaymentDiscountTypeInsertInput;
}>;


export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onSitePaymentDiscountType_one?: Types.Maybe<{ __typename: 'onSitePaymentDiscountType' }> }
);


export const AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityDocument = gql`
    query AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriority($companyId: uuid!) {
  onSitePaymentDiscountType_aggregate(where: {companyId: {_eq: $companyId}}) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery, AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery, AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables>(AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityDocument, options);
      }
export function useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery, AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery, AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables>(AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityDocument, options);
        }
export type AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryHookResult = ReturnType<typeof useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery>;
export type AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityLazyQuery>;
export type AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryResult = Apollo.QueryResult<AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQuery, AddOnSitePaymentDiscountTypeGetOnSitePaymentDiscountTypeMaxPriorityQueryVariables>;
export const AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeDocument = gql`
    mutation AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountType($onSitePaymentDiscountType: onSitePaymentDiscountType_insert_input!) {
  insert_onSitePaymentDiscountType_one(
    object: $onSitePaymentDiscountType
    on_conflict: {constraint: onSitePaymentDiscountType_pkey, update_columns: [isArchived]}
  ) {
    __typename
  }
}
    `;
export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationFn = Apollo.MutationFunction<AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation, AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationVariables>;

/**
 * __useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation__
 *
 * To run a mutation, you first call `useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation, { data, loading, error }] = useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation({
 *   variables: {
 *      onSitePaymentDiscountType: // value for 'onSitePaymentDiscountType'
 *   },
 * });
 */
export function useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation, AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation, AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationVariables>(AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeDocument, options);
      }
export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationHookResult = ReturnType<typeof useAddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation>;
export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationResult = Apollo.MutationResult<AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation>;
export type AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationOptions = Apollo.BaseMutationOptions<AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutation, AddOnSitePaymentDiscountTypeInsertOnSitePaymentDiscountTypeMutationVariables>;