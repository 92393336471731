import { datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";

import { reportToSentry } from "./sentry";

export const logger = {
  addContext({ key, value }: { key: string; value: number | string | null }) {
    Sentry.setTag(key, value);
    datadogLogs.addLoggerGlobalContext(key, value);
  },

  /** 想定していないフローだが続行可能な異常に対するロギング */
  warn(message: string, extra?: Record<string, unknown>) {
    reportToSentry({ message, extra, severity: Sentry.Severity.Warning });
    datadogLogs.logger.warn(message, extra);
  },
  error(message: string, extra?: Record<string, unknown>) {
    reportToSentry({ message, extra, severity: Sentry.Severity.Error });
    datadogLogs.logger.error(message, extra);
  },

  /** 続行が不能な不具合が生じてユーザー操作を完了できなかった場合に使用するロギング */
  critical(message: string, extra?: Record<string, unknown>) {
    reportToSentry({ message, extra, severity: Sentry.Severity.Critical });
    datadogLogs.logger.error(message, extra);
  },

  /** console.log の代わりに使えるが Sentry で表示するためにペイロードを文字列化する */
  info(message: string, extra?: Record<string, unknown>) {
    reportToSentry({ message, extra, severity: Sentry.Severity.Info });
    datadogLogs.logger.info(message, extra);
  },
};
