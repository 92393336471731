import React, { memo, useCallback } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Option } from "../types";

import {
  EditOptionWinboardOptionFormValues,
  useEditOptionWinboardOptionForm,
} from "./useEditOptionWinboardOptionForm";
import { WinboardGetAvailableMenuCodeButtonField } from "./WinboardGetAvailableMenuCodeButtonField";
import { WinboardOptionChoiceTable } from "./WinboardOptionChoiceTable";

type Props = {
  option: Option;
  onSubmit: (args: EditOptionWinboardOptionFormValues) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => number;
  loading: boolean;
};

export const EditOptionWinboardOptionForm = memo<Props>(
  ({ option, onClose, onSubmit, onGetAvailableMenuCodeButtonPressed, loading }) => {
    const { form, initialValues, submit } = useEditOptionWinboardOptionForm({
      option,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();

      const fieldsValue = form.getFieldsValue();

      const choices = fieldsValue.option.choices.flatMap((choice, index) => ({
        ...choice,
        winboardMenus: [{ ...choice.winboardMenus?.[0], code: (maxMenuCode + index).toString() }],
      }));

      form.setFieldsValue({ option: { choices } });
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    return (
      <>
        <FormContent>
          <Form form={form} initialValues={initialValues} layout="vertical">
            <WinboardOptionChoiceTable />
            <Spacer size={16} />
            <WinboardGetAvailableMenuCodeButtonField
              handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
