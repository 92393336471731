import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GmoOffPaymentFeatureFlagGetShopIds = gql`
    query GmoOffPaymentFeatureFlagGetShopIds($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
  }
}
    `;
export const GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIds = gql`
    query GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIds($shopIds: [uuid!]!) {
  gmoOffPaymentShopConfig(
    where: {shopId: {_in: $shopIds}, isEnabled: {_eq: true}}
  ) {
    shopId
  }
}
    `;
export type GmoOffPaymentFeatureFlagGetShopIdsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GmoOffPaymentFeatureFlagGetShopIdsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
  )> }
);

export type GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables = Types.Exact<{
  shopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery = (
  { __typename?: 'query_root' }
  & { gmoOffPaymentShopConfig: Array<(
    { __typename?: 'gmoOffPaymentShopConfig' }
    & Pick<Types.GmoOffPaymentShopConfig, 'shopId'>
  )> }
);


export const GmoOffPaymentFeatureFlagGetShopIdsDocument = gql`
    query GmoOffPaymentFeatureFlagGetShopIds($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
  }
}
    `;

/**
 * __useGmoOffPaymentFeatureFlagGetShopIdsQuery__
 *
 * To run a query within a React component, call `useGmoOffPaymentFeatureFlagGetShopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGmoOffPaymentFeatureFlagGetShopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGmoOffPaymentFeatureFlagGetShopIdsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGmoOffPaymentFeatureFlagGetShopIdsQuery(baseOptions: Apollo.QueryHookOptions<GmoOffPaymentFeatureFlagGetShopIdsQuery, GmoOffPaymentFeatureFlagGetShopIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GmoOffPaymentFeatureFlagGetShopIdsQuery, GmoOffPaymentFeatureFlagGetShopIdsQueryVariables>(GmoOffPaymentFeatureFlagGetShopIdsDocument, options);
      }
export function useGmoOffPaymentFeatureFlagGetShopIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GmoOffPaymentFeatureFlagGetShopIdsQuery, GmoOffPaymentFeatureFlagGetShopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GmoOffPaymentFeatureFlagGetShopIdsQuery, GmoOffPaymentFeatureFlagGetShopIdsQueryVariables>(GmoOffPaymentFeatureFlagGetShopIdsDocument, options);
        }
export type GmoOffPaymentFeatureFlagGetShopIdsQueryHookResult = ReturnType<typeof useGmoOffPaymentFeatureFlagGetShopIdsQuery>;
export type GmoOffPaymentFeatureFlagGetShopIdsLazyQueryHookResult = ReturnType<typeof useGmoOffPaymentFeatureFlagGetShopIdsLazyQuery>;
export type GmoOffPaymentFeatureFlagGetShopIdsQueryResult = Apollo.QueryResult<GmoOffPaymentFeatureFlagGetShopIdsQuery, GmoOffPaymentFeatureFlagGetShopIdsQueryVariables>;
export const GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsDocument = gql`
    query GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIds($shopIds: [uuid!]!) {
  gmoOffPaymentShopConfig(
    where: {shopId: {_in: $shopIds}, isEnabled: {_eq: true}}
  ) {
    shopId
  }
}
    `;

/**
 * __useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery__
 *
 * To run a query within a React component, call `useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery({
 *   variables: {
 *      shopIds: // value for 'shopIds'
 *   },
 * });
 */
export function useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery(baseOptions: Apollo.QueryHookOptions<GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery, GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery, GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables>(GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsDocument, options);
      }
export function useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery, GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery, GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables>(GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsDocument, options);
        }
export type GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryHookResult = ReturnType<typeof useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery>;
export type GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsLazyQueryHookResult = ReturnType<typeof useGmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsLazyQuery>;
export type GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryResult = Apollo.QueryResult<GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQuery, GmoOffPaymentFeatureFlagGetGmoOffPaymentShopIdsQueryVariables>;