import useAsyncFn from "react-use/esm/useAsyncFn";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { isAxiosError } from "util/type/axios";

import { message } from "components/antd/message";
import { useDinii } from "hooks/useDinii";

export const useTecAggregationGetMasterCsv = () => {
  const [dinii, getContext] = useDinii();

  const [{ loading }, submit] = useAsyncFn(async () => {
    const context = await getContext();

    if (!context) {
      return;
    }

    try {
      const { data } = await dinii.tec.getMasterCsv(context);
      saveAs(data, `tec-master-${dayjs().format("YYYY-MM-DD")}.csv`);
    } catch (err) {
      if (isAxiosError<{ text: () => string }>(err)) {
        try {
          // responseType が blob となっている
          const resString = await err.response?.data.text();
          const res = resString ? JSON.parse(resString) : null;
          message.error(res?.title);
        } catch {
          message.error("ダウンロードに失敗しました");
        }
      }
    }
  }, [dinii.tec, getContext]);

  return {
    loading,
    submit,
  };
};
