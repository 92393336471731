import React, { memo, useMemo, useState } from "react";
import useInterval from "react-use/esm/useInterval";
import styled from "styled-components";
import { Button, Steps } from "antd";
import dayjs from "dayjs";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { AddLineReportingBotConfigText } from "../AddLineReportingBotConfigText";

const VerificationCode = styled.p`
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  color: ${colors.Text.Default};
  margin: 0;
  letter-spacing: 2px;
`;

type Props = {
  onClickBackButton: () => void;
  onClickForwardButton: () => void;
  verificationCode: string;
  verificationCodeExpiredAt: dayjs.Dayjs;
  disabled: boolean;
};

export const SendVerificationCodeToLine = memo<Props>(
  ({
    onClickBackButton,
    onClickForwardButton,
    verificationCode,
    verificationCodeExpiredAt,
    disabled,
  }) => {
    const [remainingTimeText, setRemainingTime] = useState("");

    useInterval(() => {
      const now = dayjs();
      const minutesDiff = verificationCodeExpiredAt.diff(now, "minutes");
      const secondsDiff = verificationCodeExpiredAt.diff(now, "seconds") % 60;

      setRemainingTime(
        secondsDiff < 0
          ? "00:00"
          : `${String(minutesDiff).padStart(2, "0")}:${String(secondsDiff).padStart(2, "0")}`,
      );
    }, 1000);

    const steps = useMemo(
      () => [
        { title: "グループに追加" },
        { title: "コードをLINEに投稿", subTitle: `残り時間 ${remainingTimeText}` },
        { title: "店舗選択" },
      ],
      [remainingTimeText],
    );

    return (
      <Form layout="vertical">
        <FormSection>
          <Steps current={1} percent={66} items={steps} />
          <Spacer size={24} />
          <AddLineReportingBotConfigText>
            画面に表示されているコードをLINEグループに投稿してください。
          </AddLineReportingBotConfigText>
          <Spacer size={8} />
          <VerificationCode>{verificationCode}</VerificationCode>
        </FormSection>
        <Spacer size={16} />
        <FormActions>
          <Button onClick={onClickBackButton}>キャンセル</Button>
          <Button type="primary" onClick={onClickForwardButton} disabled={disabled}>
            次へ
          </Button>
        </FormActions>
      </Form>
    );
  },
);
