import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatPrice } from "util/price";
import { isNotNull } from "util/type/primitive";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { Choice } from "../types";

const { Paragraph } = Typography;

type Props = {
  loading?: boolean;
  choices: Choice[];
  onDelete: ({ choiceId }: { choiceId: number }) => void;
};

export const GiftChoiceTable = memo<Props>(({ loading, choices, onDelete }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns: ColumnsType<Choice> = [
    {
      title: "金額",
      dataIndex: "price",
      render(_: unknown, { price, choiceId }: Choice) {
        return canAccess("editGiftChoice") ? (
          <Link to={`/giftChoice/${choiceId}/edit`}>
            <Button type="link">{formatPrice(price)}</Button>
          </Link>
        ) : (
          formatPrice(price)
        );
      },
    },
    ...(isFeatureEnabled("deleteGiftChoice")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { choiceId }: Choice) {
              return (
                <Popconfirm
                  title={
                    <>
                      <Paragraph>金額を削除しますか？</Paragraph>
                      <Paragraph>一度削除した金額を元に戻すことはできません。</Paragraph>
                      <Paragraph>売上の記録は維持されます。</Paragraph>
                    </>
                  }
                  okText="はい"
                  cancelText="キャンセル"
                  onConfirm={() => {
                    onDelete({ choiceId });
                  }}
                >
                  <DeleteIcon />
                </Popconfirm>
              );
            },
          } as const,
        ]
      : []),
  ].filter(isNotNull);

  return (
    <Table
      rowKey="choiceId"
      columns={columns}
      dataSource={choices}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
