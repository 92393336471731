import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMembershipRankConfigGetRankConfig = gql`
    query EditMembershipRankConfigGetRankConfig($id: uuid!, $companyId: uuid!) {
  membershipRankConfig_by_pk(id: $id) {
    id
    membershipRank
    requiredVisitCount
    rewardCouponId
  }
  coupon(where: {companyId: {_eq: $companyId}}, order_by: {name: asc}) {
    id
    name
  }
}
    `;
export const EditMembershipRankConfigUpdateRankConfig = gql`
    mutation EditMembershipRankConfigUpdateRankConfig($id: uuid!, $input: membershipRankConfig_set_input!) {
  update_membershipRankConfig(_set: $input, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type EditMembershipRankConfigGetRankConfigQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
}>;


export type EditMembershipRankConfigGetRankConfigQuery = (
  { __typename?: 'query_root' }
  & { membershipRankConfig_by_pk?: Types.Maybe<(
    { __typename?: 'membershipRankConfig' }
    & Pick<Types.MembershipRankConfig, 'id' | 'membershipRank' | 'requiredVisitCount' | 'rewardCouponId'>
  )>, coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type EditMembershipRankConfigUpdateRankConfigMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  input: Types.MembershipRankConfigSetInput;
}>;


export type EditMembershipRankConfigUpdateRankConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_membershipRankConfig?: Types.Maybe<(
    { __typename?: 'membershipRankConfig_mutation_response' }
    & Pick<Types.MembershipRankConfigMutationResponse, 'affected_rows'>
  )> }
);


export const EditMembershipRankConfigGetRankConfigDocument = gql`
    query EditMembershipRankConfigGetRankConfig($id: uuid!, $companyId: uuid!) {
  membershipRankConfig_by_pk(id: $id) {
    id
    membershipRank
    requiredVisitCount
    rewardCouponId
  }
  coupon(where: {companyId: {_eq: $companyId}}, order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useEditMembershipRankConfigGetRankConfigQuery__
 *
 * To run a query within a React component, call `useEditMembershipRankConfigGetRankConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMembershipRankConfigGetRankConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMembershipRankConfigGetRankConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMembershipRankConfigGetRankConfigQuery(baseOptions: Apollo.QueryHookOptions<EditMembershipRankConfigGetRankConfigQuery, EditMembershipRankConfigGetRankConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMembershipRankConfigGetRankConfigQuery, EditMembershipRankConfigGetRankConfigQueryVariables>(EditMembershipRankConfigGetRankConfigDocument, options);
      }
export function useEditMembershipRankConfigGetRankConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMembershipRankConfigGetRankConfigQuery, EditMembershipRankConfigGetRankConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMembershipRankConfigGetRankConfigQuery, EditMembershipRankConfigGetRankConfigQueryVariables>(EditMembershipRankConfigGetRankConfigDocument, options);
        }
export type EditMembershipRankConfigGetRankConfigQueryHookResult = ReturnType<typeof useEditMembershipRankConfigGetRankConfigQuery>;
export type EditMembershipRankConfigGetRankConfigLazyQueryHookResult = ReturnType<typeof useEditMembershipRankConfigGetRankConfigLazyQuery>;
export type EditMembershipRankConfigGetRankConfigQueryResult = Apollo.QueryResult<EditMembershipRankConfigGetRankConfigQuery, EditMembershipRankConfigGetRankConfigQueryVariables>;
export const EditMembershipRankConfigUpdateRankConfigDocument = gql`
    mutation EditMembershipRankConfigUpdateRankConfig($id: uuid!, $input: membershipRankConfig_set_input!) {
  update_membershipRankConfig(_set: $input, where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type EditMembershipRankConfigUpdateRankConfigMutationFn = Apollo.MutationFunction<EditMembershipRankConfigUpdateRankConfigMutation, EditMembershipRankConfigUpdateRankConfigMutationVariables>;

/**
 * __useEditMembershipRankConfigUpdateRankConfigMutation__
 *
 * To run a mutation, you first call `useEditMembershipRankConfigUpdateRankConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMembershipRankConfigUpdateRankConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMembershipRankConfigUpdateRankConfigMutation, { data, loading, error }] = useEditMembershipRankConfigUpdateRankConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMembershipRankConfigUpdateRankConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditMembershipRankConfigUpdateRankConfigMutation, EditMembershipRankConfigUpdateRankConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMembershipRankConfigUpdateRankConfigMutation, EditMembershipRankConfigUpdateRankConfigMutationVariables>(EditMembershipRankConfigUpdateRankConfigDocument, options);
      }
export type EditMembershipRankConfigUpdateRankConfigMutationHookResult = ReturnType<typeof useEditMembershipRankConfigUpdateRankConfigMutation>;
export type EditMembershipRankConfigUpdateRankConfigMutationResult = Apollo.MutationResult<EditMembershipRankConfigUpdateRankConfigMutation>;
export type EditMembershipRankConfigUpdateRankConfigMutationOptions = Apollo.BaseMutationOptions<EditMembershipRankConfigUpdateRankConfigMutation, EditMembershipRankConfigUpdateRankConfigMutationVariables>;