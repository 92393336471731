import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GiftsGetMenus = gql`
    query GiftsGetMenus($shopId: uuid!) {
  menu(
    where: {archivedAt: {_is_null: true}, _and: [{shopMenus: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}}, {giftMeta: {id: {_is_null: false}}}]}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    id
    imageUrl
    menuId
    name
    receiptDisplayName
    shopSideName
    shopMenus(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
      isVisibleForCustomer
      isVisibleForStaff
      menuId
      shopMenuKitchenRoles {
        roleId
      }
    }
    giftMeta {
      id
      menuId: _menuId
    }
  }
}
    `;
export const GiftsGetRoles = gql`
    query GiftsGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;
export const GiftsUpdateShopMenuDisplay = gql`
    mutation GiftsUpdateShopMenuDisplay($shopId: uuid!, $menuId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export const GiftsInsertShopMenuKitchenRole = gql`
    mutation GiftsInsertShopMenuKitchenRole($menuRolesRole: [shopMenuKitchenRole_insert_input!]!) {
  insert_shopMenuKitchenRole(
    objects: $menuRolesRole
    on_conflict: {constraint: shopMenuKitchenRole_shopId_menuId_roleId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export const GiftsDeleteShopMenuKitchenRole = gql`
    mutation GiftsDeleteShopMenuKitchenRole($menuId: Int!, $roleId: uuid!) {
  delete_shopMenuKitchenRole(
    where: {_menuId: {_eq: $menuId}, roleId: {_eq: $roleId}}
  ) {
    affected_rows
  }
}
    `;
export const GiftsDeleteMenu = gql`
    mutation GiftsDeleteMenu($input: ArchiveMenuInput!) {
  archiveMenu(input: $input) {
    result
  }
}
    `;
export type GiftsGetMenusQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type GiftsGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'imageUrl' | 'menuId' | 'name' | 'receiptDisplayName' | 'shopSideName'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & Pick<Types.ShopMenu, 'isVisibleForCustomer' | 'isVisibleForStaff' | 'menuId'>
      & { shopMenuKitchenRoles: Array<(
        { __typename?: 'shopMenuKitchenRole' }
        & Pick<Types.ShopMenuKitchenRole, 'roleId'>
      )> }
    )>, giftMeta?: Types.Maybe<(
      { __typename?: 'giftMeta' }
      & Pick<Types.GiftMeta, 'id'>
      & { menuId: Types.GiftMeta['_menuId'] }
    )> }
  )> }
);

export type GiftsGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type GiftsGetRolesQuery = (
  { __typename?: 'query_root' }
  & { role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'id' | 'name' | 'roleId'>
  )> }
);

export type GiftsUpdateShopMenuDisplayMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['uuid'];
  isVisibleForCustomer: Types.Scalars['Boolean'];
}>;


export type GiftsUpdateShopMenuDisplayMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type GiftsInsertShopMenuKitchenRoleMutationVariables = Types.Exact<{
  menuRolesRole: Array<Types.ShopMenuKitchenRoleInsertInput> | Types.ShopMenuKitchenRoleInsertInput;
}>;


export type GiftsInsertShopMenuKitchenRoleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopMenuKitchenRole?: Types.Maybe<(
    { __typename?: 'shopMenuKitchenRole_mutation_response' }
    & Pick<Types.ShopMenuKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type GiftsDeleteShopMenuKitchenRoleMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  roleId: Types.Scalars['uuid'];
}>;


export type GiftsDeleteShopMenuKitchenRoleMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuKitchenRole?: Types.Maybe<(
    { __typename?: 'shopMenuKitchenRole_mutation_response' }
    & Pick<Types.ShopMenuKitchenRoleMutationResponse, 'affected_rows'>
  )> }
);

export type GiftsDeleteMenuMutationVariables = Types.Exact<{
  input: Types.ArchiveMenuInput;
}>;


export type GiftsDeleteMenuMutation = (
  { __typename?: 'mutation_root' }
  & { archiveMenu: (
    { __typename?: 'ArchiveMenuResult' }
    & Pick<Types.ArchiveMenuResult, 'result'>
  ) }
);


export const GiftsGetMenusDocument = gql`
    query GiftsGetMenus($shopId: uuid!) {
  menu(
    where: {archivedAt: {_is_null: true}, _and: [{shopMenus: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}, shop: {archivedAt: {_is_null: true}}}}, {giftMeta: {id: {_is_null: false}}}]}
    order_by: [{giftMeta: {priority: asc}}, {giftMeta: {_menuId: asc}}]
  ) {
    id
    imageUrl
    menuId
    name
    receiptDisplayName
    shopSideName
    shopMenus(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
      isVisibleForCustomer
      isVisibleForStaff
      menuId
      shopMenuKitchenRoles {
        roleId
      }
    }
    giftMeta {
      id
      menuId: _menuId
    }
  }
}
    `;

/**
 * __useGiftsGetMenusQuery__
 *
 * To run a query within a React component, call `useGiftsGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftsGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftsGetMenusQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useGiftsGetMenusQuery(baseOptions: Apollo.QueryHookOptions<GiftsGetMenusQuery, GiftsGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftsGetMenusQuery, GiftsGetMenusQueryVariables>(GiftsGetMenusDocument, options);
      }
export function useGiftsGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftsGetMenusQuery, GiftsGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftsGetMenusQuery, GiftsGetMenusQueryVariables>(GiftsGetMenusDocument, options);
        }
export type GiftsGetMenusQueryHookResult = ReturnType<typeof useGiftsGetMenusQuery>;
export type GiftsGetMenusLazyQueryHookResult = ReturnType<typeof useGiftsGetMenusLazyQuery>;
export type GiftsGetMenusQueryResult = Apollo.QueryResult<GiftsGetMenusQuery, GiftsGetMenusQueryVariables>;
export const GiftsGetRolesDocument = gql`
    query GiftsGetRoles($shopId: uuid!) {
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;

/**
 * __useGiftsGetRolesQuery__
 *
 * To run a query within a React component, call `useGiftsGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftsGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftsGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useGiftsGetRolesQuery(baseOptions: Apollo.QueryHookOptions<GiftsGetRolesQuery, GiftsGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftsGetRolesQuery, GiftsGetRolesQueryVariables>(GiftsGetRolesDocument, options);
      }
export function useGiftsGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftsGetRolesQuery, GiftsGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftsGetRolesQuery, GiftsGetRolesQueryVariables>(GiftsGetRolesDocument, options);
        }
export type GiftsGetRolesQueryHookResult = ReturnType<typeof useGiftsGetRolesQuery>;
export type GiftsGetRolesLazyQueryHookResult = ReturnType<typeof useGiftsGetRolesLazyQuery>;
export type GiftsGetRolesQueryResult = Apollo.QueryResult<GiftsGetRolesQuery, GiftsGetRolesQueryVariables>;
export const GiftsUpdateShopMenuDisplayDocument = gql`
    mutation GiftsUpdateShopMenuDisplay($shopId: uuid!, $menuId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export type GiftsUpdateShopMenuDisplayMutationFn = Apollo.MutationFunction<GiftsUpdateShopMenuDisplayMutation, GiftsUpdateShopMenuDisplayMutationVariables>;

/**
 * __useGiftsUpdateShopMenuDisplayMutation__
 *
 * To run a mutation, you first call `useGiftsUpdateShopMenuDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftsUpdateShopMenuDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftsUpdateShopMenuDisplayMutation, { data, loading, error }] = useGiftsUpdateShopMenuDisplayMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      isVisibleForCustomer: // value for 'isVisibleForCustomer'
 *   },
 * });
 */
export function useGiftsUpdateShopMenuDisplayMutation(baseOptions?: Apollo.MutationHookOptions<GiftsUpdateShopMenuDisplayMutation, GiftsUpdateShopMenuDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftsUpdateShopMenuDisplayMutation, GiftsUpdateShopMenuDisplayMutationVariables>(GiftsUpdateShopMenuDisplayDocument, options);
      }
export type GiftsUpdateShopMenuDisplayMutationHookResult = ReturnType<typeof useGiftsUpdateShopMenuDisplayMutation>;
export type GiftsUpdateShopMenuDisplayMutationResult = Apollo.MutationResult<GiftsUpdateShopMenuDisplayMutation>;
export type GiftsUpdateShopMenuDisplayMutationOptions = Apollo.BaseMutationOptions<GiftsUpdateShopMenuDisplayMutation, GiftsUpdateShopMenuDisplayMutationVariables>;
export const GiftsInsertShopMenuKitchenRoleDocument = gql`
    mutation GiftsInsertShopMenuKitchenRole($menuRolesRole: [shopMenuKitchenRole_insert_input!]!) {
  insert_shopMenuKitchenRole(
    objects: $menuRolesRole
    on_conflict: {constraint: shopMenuKitchenRole_shopId_menuId_roleId_key, update_columns: []}
  ) {
    affected_rows
  }
}
    `;
export type GiftsInsertShopMenuKitchenRoleMutationFn = Apollo.MutationFunction<GiftsInsertShopMenuKitchenRoleMutation, GiftsInsertShopMenuKitchenRoleMutationVariables>;

/**
 * __useGiftsInsertShopMenuKitchenRoleMutation__
 *
 * To run a mutation, you first call `useGiftsInsertShopMenuKitchenRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftsInsertShopMenuKitchenRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftsInsertShopMenuKitchenRoleMutation, { data, loading, error }] = useGiftsInsertShopMenuKitchenRoleMutation({
 *   variables: {
 *      menuRolesRole: // value for 'menuRolesRole'
 *   },
 * });
 */
export function useGiftsInsertShopMenuKitchenRoleMutation(baseOptions?: Apollo.MutationHookOptions<GiftsInsertShopMenuKitchenRoleMutation, GiftsInsertShopMenuKitchenRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftsInsertShopMenuKitchenRoleMutation, GiftsInsertShopMenuKitchenRoleMutationVariables>(GiftsInsertShopMenuKitchenRoleDocument, options);
      }
export type GiftsInsertShopMenuKitchenRoleMutationHookResult = ReturnType<typeof useGiftsInsertShopMenuKitchenRoleMutation>;
export type GiftsInsertShopMenuKitchenRoleMutationResult = Apollo.MutationResult<GiftsInsertShopMenuKitchenRoleMutation>;
export type GiftsInsertShopMenuKitchenRoleMutationOptions = Apollo.BaseMutationOptions<GiftsInsertShopMenuKitchenRoleMutation, GiftsInsertShopMenuKitchenRoleMutationVariables>;
export const GiftsDeleteShopMenuKitchenRoleDocument = gql`
    mutation GiftsDeleteShopMenuKitchenRole($menuId: Int!, $roleId: uuid!) {
  delete_shopMenuKitchenRole(
    where: {_menuId: {_eq: $menuId}, roleId: {_eq: $roleId}}
  ) {
    affected_rows
  }
}
    `;
export type GiftsDeleteShopMenuKitchenRoleMutationFn = Apollo.MutationFunction<GiftsDeleteShopMenuKitchenRoleMutation, GiftsDeleteShopMenuKitchenRoleMutationVariables>;

/**
 * __useGiftsDeleteShopMenuKitchenRoleMutation__
 *
 * To run a mutation, you first call `useGiftsDeleteShopMenuKitchenRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftsDeleteShopMenuKitchenRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftsDeleteShopMenuKitchenRoleMutation, { data, loading, error }] = useGiftsDeleteShopMenuKitchenRoleMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGiftsDeleteShopMenuKitchenRoleMutation(baseOptions?: Apollo.MutationHookOptions<GiftsDeleteShopMenuKitchenRoleMutation, GiftsDeleteShopMenuKitchenRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftsDeleteShopMenuKitchenRoleMutation, GiftsDeleteShopMenuKitchenRoleMutationVariables>(GiftsDeleteShopMenuKitchenRoleDocument, options);
      }
export type GiftsDeleteShopMenuKitchenRoleMutationHookResult = ReturnType<typeof useGiftsDeleteShopMenuKitchenRoleMutation>;
export type GiftsDeleteShopMenuKitchenRoleMutationResult = Apollo.MutationResult<GiftsDeleteShopMenuKitchenRoleMutation>;
export type GiftsDeleteShopMenuKitchenRoleMutationOptions = Apollo.BaseMutationOptions<GiftsDeleteShopMenuKitchenRoleMutation, GiftsDeleteShopMenuKitchenRoleMutationVariables>;
export const GiftsDeleteMenuDocument = gql`
    mutation GiftsDeleteMenu($input: ArchiveMenuInput!) {
  archiveMenu(input: $input) {
    result
  }
}
    `;
export type GiftsDeleteMenuMutationFn = Apollo.MutationFunction<GiftsDeleteMenuMutation, GiftsDeleteMenuMutationVariables>;

/**
 * __useGiftsDeleteMenuMutation__
 *
 * To run a mutation, you first call `useGiftsDeleteMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiftsDeleteMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giftsDeleteMenuMutation, { data, loading, error }] = useGiftsDeleteMenuMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiftsDeleteMenuMutation(baseOptions?: Apollo.MutationHookOptions<GiftsDeleteMenuMutation, GiftsDeleteMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GiftsDeleteMenuMutation, GiftsDeleteMenuMutationVariables>(GiftsDeleteMenuDocument, options);
      }
export type GiftsDeleteMenuMutationHookResult = ReturnType<typeof useGiftsDeleteMenuMutation>;
export type GiftsDeleteMenuMutationResult = Apollo.MutationResult<GiftsDeleteMenuMutation>;
export type GiftsDeleteMenuMutationOptions = Apollo.BaseMutationOptions<GiftsDeleteMenuMutation, GiftsDeleteMenuMutationVariables>;