import {
  OrderableTimeTermDayWeek,
  OrderableTimeTermDayWeekType,
} from "../../../../models/orderableTime";

export const HEIGHT_PER_HOUR = 45;

export const Hours = Array.from(Array(24).keys());

export const DayWeekKeys = Object.keys(OrderableTimeTermDayWeek) as OrderableTimeTermDayWeekType[];
