export const couponAnalyticsProperties = {
  couponUsedCount: {
    label: "利用数",
    iconSrc: "/coupon-analytics/coupon-usage-count.png",
  },
  visitedTableUserCustomersCount: {
    label: "来店組客数",
    iconSrc: "/coupon-analytics/visited_table_user_customers_count.png",
  },
  visitedTableUserCustomersSales: {
    label: "クーポン効果売上",
    iconSrc: "/coupon-analytics/visited_table_user_customers_sales.png",
  },
};
