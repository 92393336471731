import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableRemarkField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="備考入力"
        help="備考入力を有効にすると来店客が注文時に備考を入力できるようになります"
      />
    }
    name="enableRemark"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
