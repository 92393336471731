import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCanAccess } from "hooks/useCanAccess";
import { useCompany } from "hooks/useCompany";
import { QuestionnaireConfigsTable } from "pages/QuestionnaireConfigs/QuestionnaireConfigsTable";

import { useQuestionnaireConfigsGetQuestionnaireConfigsQuery } from "./queries";

export const QuestionnaireConfigs = () => {
  const { canAccess } = useCanAccess();
  const [company] = useCompany();
  const companyId = company?.id;

  const { data, error, loading } = useQuestionnaireConfigsGetQuestionnaireConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const questionnaireConfigs = data?.questionnaireConfig ?? [];

  const hasQuestionnaireConfig = questionnaireConfigs.length > 0;

  return (
    <DashboardLayout title="アンケート一覧">
      <PageHeader
        title="アンケート一覧"
        extra={[
          canAccess("addQuestionnaireConfig") && !hasQuestionnaireConfig && (
            <Link key="add" to="/questionnaireConfig/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <QuestionnaireConfigsTable loading={loading} questionnaireConfigs={questionnaireConfigs} />
    </DashboardLayout>
  );
};
