import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Option } from "../types";

import { TecAggregationGetAvailableMenuCodeButtonField } from "./TecAggregationGetAvailableMenuCodeButtonField";
import { TecAggregationOptionChoiceTable } from "./TecAggregationOptionChoiceTable";
import {
  EditOptionTecAggregationOptionFormValues,
  useEditOptionTecAggregationOptionForm,
} from "./useEditOptionTecAggregationOptionForm";

type Props = {
  option: Option;
  onSubmit: (args: EditOptionTecAggregationOptionFormValues) => void;
  onClose: () => void;
  onGetAvailableMenuCodeButtonPressed: () => number;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditOptionTecAggregationOptionForm = memo<Props>(
  ({
    option,
    onClose,
    onSubmit,
    onGetAvailableMenuCodeButtonPressed,
    loading,
    onFormValidationError,
  }) => {
    const { form, initialValues, submit } = useEditOptionTecAggregationOptionForm({
      option,
      onSubmit,
    });

    const handleGetAvailableMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = onGetAvailableMenuCodeButtonPressed();

      const fieldsValue = form.getFieldsValue();

      const choices = fieldsValue.option.choices.flatMap((choice, index) => ({
        ...choice,
        tecAggregationMenu: {
          ...choice.tecAggregationMenu,
          menuCode: (maxMenuCode + index).toString(),
        },
      }));

      form.setFieldsValue({ option: { choices } });
    }, [form, onGetAvailableMenuCodeButtonPressed]);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    return (
      <>
        <FormContent>
          <Form
            name="tecAggregationMenu"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <TecAggregationOptionChoiceTable />
            <Spacer size={16} />
            <TecAggregationGetAvailableMenuCodeButtonField
              handleGetAvailableMenuCodeButtonPressed={handleGetAvailableMenuCodeButtonPressed}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
