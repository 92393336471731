import { UnreachableError } from "libs/unreachable";
import { MessageDeliveryShopJobErrorTypeEnum } from "types/graphql";

export const getMessageDeliveryShopJobErrorMessage = ({
  error,
}: {
  error: MessageDeliveryShopJobErrorTypeEnum;
}) => {
  switch (error) {
    case MessageDeliveryShopJobErrorTypeEnum.InvalidLineConfigError:
      return "LINE の設定に誤りがあります。ダイニーへお問い合わせください。";
    case MessageDeliveryShopJobErrorTypeEnum.ReachedMonthlyLimitLineError:
      return "配信可能数がありません。プラン変更もしくは、メッセージ数を追加してください。";
    case MessageDeliveryShopJobErrorTypeEnum.TargetCustomerNotFoundError:
      return "有効な送信対象者がいません。配信対象を見直してください。";
    case MessageDeliveryShopJobErrorTypeEnum.TooManyTargetCustomerError:
      return "送信対象者が多すぎます。条件を限定して送信対象者を減らしてください。";
    case MessageDeliveryShopJobErrorTypeEnum.UnexpectedLineError:
      return "LINE のサーバー側で問題が発生しました。ダイニーへお問い合わせください。";
    case MessageDeliveryShopJobErrorTypeEnum.UnexpectedServerError:
      return "ダイニーのサーバー側で問題が発生しました。ダイニーへお問い合わせください。";
    default:
      new UnreachableError(error);
  }
};
