import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { MenuRecommendation, MenuRecommendationSetInput } from "../types";

type EditMenuRecommendationFormValues = {
  name: string;
};

const getInitialValues = (menuRecommendation: MenuRecommendation) => {
  const { name } = menuRecommendation;
  return { name };
};

export const EditMenuRecommendationFormItem = createFormItem<EditMenuRecommendationFormValues>();

export const useEditMenuRecommendationForm = (
  menuRecommendation: MenuRecommendation,
  onSubmit: (menuRecommendation: MenuRecommendationSetInput) => void,
) => {
  const [form] = Form.useForm<EditMenuRecommendationFormValues>();
  const initialValues = getInitialValues(menuRecommendation);

  const submit = useCallback(() => {
    const { name } = form.getFieldsValue() as MenuRecommendationSetInput;
    onSubmit({ name });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
