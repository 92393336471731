import React, { memo } from "react";

import { MessageDeliveryMessageTypeEnum } from "types/graphql";

import { MessageFormValue } from "../../types";

import { CouponMessage } from "./CouponMessage";
import { ImageMessage } from "./ImageMessage";
import { QuestionnaireMessage } from "./QuestionnaireMessage";
import { TextMessage } from "./TextMessage";

type Props = {
  index: number;
  message: MessageFormValue;
};

export const Message = memo(({ index, message }: Props) => {
  switch (message.type) {
    case MessageDeliveryMessageTypeEnum.Text:
      return <TextMessage message={message} index={index} />;
    case MessageDeliveryMessageTypeEnum.Image:
      return <ImageMessage message={message} />;
    case MessageDeliveryMessageTypeEnum.Coupon:
      return <CouponMessage message={message} />;
    case MessageDeliveryMessageTypeEnum.Questionnaire:
      return <QuestionnaireMessage message={message} />;
  }
});
