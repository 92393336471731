import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row } from "antd";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { DishUpSlipGroupField } from "pages/ShopMenus/ShopMenuFields/DishUpSlipGroupField";
import { IsSoldOutField } from "pages/ShopMenus/ShopMenuFields/IsSoldOutField";
import { IsVisibleForCustomerField } from "pages/ShopMenus/ShopMenuFields/IsVisibleForCustomerField";
import { KdDisplayTargetField } from "pages/ShopMenus/ShopMenuFields/KdDisplayTargetField";
import { KitchenRoleField } from "pages/ShopMenus/ShopMenuFields/KitchenRoleField";
import { Category, DishUpSlipGroup, KdDisplayTarget, Role } from "pages/ShopMenus/types";

import { IsVisibleForStaffField } from "../ShopMenuFields/IsVisibleForStaffField";

export type FilterConditions = {
  name?: string;
  categoryIds?: number[];
  isVisibleForCustomer?: boolean;
  isVisibleForStaff?: boolean;
  isSoldOut?: boolean;
  roleIds?: number[] | "empty";
  dishUpSlipGroupIds?: number[] | "empty";
  kdDisplayTargetIds?: string[] | "empty";
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  categories: Category[];
  roles: Role[];
  dishUpSlipGroups: DishUpSlipGroup[];
  kdDisplayTargets: KdDisplayTarget[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
  shouldShowKdDisplayTargetFilter: boolean;
};

export const ShopMenuFilter = memo<Props>(
  ({
    categories,
    roles,
    dishUpSlipGroups,
    kdDisplayTargets,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
    shouldShowKdDisplayTargetFilter,
  }) => (
    <Wrapper>
      <Col flex="2 0">
        <Input
          allowClear
          placeholder="メニュー名"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
          data-cy="filter-menu-name"
        />
      </Col>
      <Col flex="2 0">
        <Select<number[]>
          mode="multiple"
          allowClear
          placeholder="カテゴリ名"
          defaultValue={[]}
          value={filterConditions.categoryIds ?? []}
          onChange={(categoryIds) => updateFilterCondition({ categoryIds })}
          optionFilterProp="label"
          options={categories.map(({ categoryId, name }) => ({ label: name, value: categoryId }))}
        />
      </Col>
      <Col flex="1 0">
        <IsVisibleForCustomerField
          value={filterConditions.isVisibleForCustomer}
          onChange={(isVisibleForCustomer) => updateFilterCondition({ isVisibleForCustomer })}
        />
      </Col>
      <Col flex="1 0">
        <IsVisibleForStaffField
          value={filterConditions.isVisibleForStaff}
          onChange={(isVisibleForStaff) => updateFilterCondition({ isVisibleForStaff })}
        />
      </Col>
      <Col flex="1 0">
        <IsSoldOutField
          value={filterConditions.isSoldOut}
          onChange={(isSoldOut) => updateFilterCondition({ isSoldOut })}
        />
      </Col>
      <Col flex="2 0">
        <KitchenRoleField
          value={filterConditions.roleIds}
          onChange={(roleIds) =>
            updateFilterCondition({
              roleIds,
            })
          }
          roles={roles}
        />
      </Col>
      <Col flex="2 0">
        <DishUpSlipGroupField
          value={filterConditions.dishUpSlipGroupIds}
          onChange={(dishUpSlipGroupIds) =>
            updateFilterCondition({
              dishUpSlipGroupIds,
            })
          }
          dishUpSlipGroups={dishUpSlipGroups}
        />
      </Col>
      {shouldShowKdDisplayTargetFilter && (
        <Col flex="2 0">
          <KdDisplayTargetField
            value={filterConditions.kdDisplayTargetIds}
            onChange={(kdDisplayTargetIds) => updateFilterCondition({ kdDisplayTargetIds })}
            kdDisplayTargets={kdDisplayTargets}
          />
        </Col>
      )}
      <Col flex="none">
        <Button type="primary" disabled={hasFilterConditions} onClick={clearFilterConditions}>
          クリア
        </Button>
      </Col>
    </Wrapper>
  ),
);
