import React, { memo } from "react";
import styled from "styled-components";
import { Radio, Select, Space } from "antd";

import { withFormDependencies } from "components/antd/Form";

import { ConsolationType, EditConnectGameConfigFormItem } from "../useEditConnectGameConfigForm";

const { Option } = Select;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  width: 100%;
  > span:last-child {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

type Props = {
  coupons: { name: string; id: string }[];
};

export const ConsolationField = memo<Props>(({ coupons }) => (
  <EditConnectGameConfigFormItem name="consolationType" required>
    <StyledRadioGroup>
      <StyledSpace direction="vertical">
        <Radio value="losingPage">はずれ画面を表示する</Radio>
        <StyledRadio value="coupon">
          クーポンを用意する
          <EditConnectGameConfigFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ consolationType }) => [consolationType])}
          >
            {({ getFieldValue }) => {
              const disabled = (getFieldValue("consolationType") as ConsolationType) !== "coupon";
              return (
                <EditConnectGameConfigFormItem
                  name="consolationCouponId"
                  rules={[{ required: !disabled, message: "クーポンを選択してください" }]}
                >
                  <StyledSelect
                    placeholder="選択してください"
                    disabled={disabled}
                    onClick={(e) => e.preventDefault()}
                  >
                    {coupons.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </StyledSelect>
                </EditConnectGameConfigFormItem>
              );
            }}
          </EditConnectGameConfigFormItem.NonProperty>
        </StyledRadio>
      </StyledSpace>
    </StyledRadioGroup>
  </EditConnectGameConfigFormItem>
));
