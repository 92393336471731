import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { red } from "@ant-design/colors";
import { CloseCircleOutlined } from "@ant-design/icons";
import { partition } from "lodash";

import { Spacer } from "components/Spacer";
import { MenuMasterUpdateResult } from "libs/dinii/rest";

type Props = {
  visible: boolean;
  menuMasterUpdateResult: MenuMasterUpdateResult | null;
  closeModal: () => void;
};

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  color: ${red[5]};
  font-size: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const UploadMenuCsvUploadFailureModal = memo(
  ({ menuMasterUpdateResult, visible, closeModal }: Props) => {
    const hasMenuResults = (menuMasterUpdateResult?.menuResult.rowResults ?? []).length > 0;
    const hasOptionResults = (menuMasterUpdateResult?.optionResult.rowResults ?? []).length > 0;

    const [succeededMenuRowResults, failedMenuRowResults] = useMemo(
      () =>
        partition(
          menuMasterUpdateResult?.menuResult.rowResults ?? [],
          ({ isSucceeded }) => isSucceeded,
        ),
      [menuMasterUpdateResult],
    );

    const [succeededOptionRowResults, failedOptionRowResults] = useMemo(
      () =>
        partition(
          menuMasterUpdateResult?.optionResult.rowResults ?? [],
          ({ isSucceeded }) => isSucceeded,
        ),
      [menuMasterUpdateResult],
    );

    return (
      <Modal
        title={
          <TitleContainer>
            <StyledCloseCircleOutlined />
            <Spacer size={16} />
            メニュー一括登録に失敗しました
          </TitleContainer>
        }
        centered
        closable={false}
        open={visible}
        footer={<Button onClick={closeModal}>閉じる</Button>}
        onCancel={closeModal}
      >
        <div>
          {/* NOTE: Modal のタイトルと左を揃える */}
          <Spacer size={12} />
          CSV のデータ反映時にエラーが発生しました。
          {hasMenuResults ? (
            <div>
              <br />
              <div>
                <b>メニュー更新成功行数</b>: {succeededMenuRowResults.length} /{" "}
                {failedMenuRowResults.length + succeededMenuRowResults.length}
              </div>
            </div>
          ) : null}
          {hasOptionResults ? (
            <div>
              <br />
              <div>
                <b>オプション更新成功行数</b>: {succeededOptionRowResults.length} /{" "}
                {failedOptionRowResults.length + succeededOptionRowResults.length}
              </div>
            </div>
          ) : null}
          <br />
          データを確認するか、もう一度同じファイルのアップロードをお試しください。
        </div>
      </Modal>
    );
  },
);
