import { UploadFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";
import { emptyNumericFilter, MenusFilter, NumericFilter } from "models/customerSegment";
import { isNotNull } from "util/type/primitive";

import { createFormItem, Form } from "components/antd/Form";
import { MessageFormValue } from "components/MessageDeliveryForm/types";
import { Scope } from "pages/EditMessageDelivery/EditMessageDeliveryForm/ScopeField";
import {
  BusinessOperationHourTypeEnum,
  MessageDeliveryCustomerSegmentDayOfWeekTypeEnum,
  MessageDeliveryCustomerSegmentGenderEnum,
  MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum,
  MessageDeliveryMessageTypeEnum,
} from "types/graphql";

import { MessageDelivery } from "../types";

type MessageDeliveryJobFormOptionalValues = Partial<{
  customerIds: string[];
  customerIdsFromCsv: string[];
  uploadedCsv: { file: UploadFile; fileList: UploadFile[] };
  visitedDayCountFilter: NumericFilter;
  consecutiveUnvisitedDayCountFilter: NumericFilter;
  visitedShopsFilterShopIds: string[];
  visitedBusinessOperationHoursFilterBusinessOperationHourTypes: (
    | BusinessOperationHourTypeEnum
    | "All"
  )[];
  visitedDayOfWeekAllChecker: boolean;
  visitedDayOfWeekFilter: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum[];
  questionnaireAnswerStatusFilter:
    | MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum
    | "All";
  orderedMenusFilter: Omit<MenusFilter, "type">;
  genderFilter: MessageDeliveryCustomerSegmentGenderEnum | "All";
  birthdayCountFilter: NumericFilter;
}>;

export type MessageDeliveryJobFormValues = {
  scope: Scope;
  messages: MessageFormValue[];
  deliverDate: dayjs.Dayjs;
  deliverTime: dayjs.Dayjs;
  isRepeatDelivery: boolean;
} & MessageDeliveryJobFormOptionalValues &
  Pick<
    MessageDelivery,
    | "name"
    | "isSuspended"
    | "customerSegmentId"
    | "customerSegment"
    | "customerListId"
    | "customerList"
  >;

const getInitialDayCountFilterValue = (filter: NumericFilter) => {
  const { moreThanOrEqual, equal, lessThanOrEqual } = filter;

  return {
    type: "numeric",
    moreThanOrEqual,
    equal,
    lessThanOrEqual,
  };
};

const getInitialValues = ({
  messageDelivery,
}: {
  messageDelivery: MessageDelivery;
}): MessageDeliveryJobFormValues => {
  const {
    name,
    customerSegmentId,
    customerListId,
    repeatDeliveryTime,
    messageDeliveryMessages,
    customerSegment,
    isSuspended,
  } = messageDelivery;

  const deliverAt = dayjs(messageDelivery.deliverAt);

  const visitedDayCountFilter = getInitialDayCountFilterValue(
    customerSegment?.messageDeliveryCustomerSegmentVisitedDayCountCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter,
  );
  const consecutiveUnvisitedDayCountFilter = getInitialDayCountFilterValue(
    customerSegment?.messageDeliveryCustomerSegmentUnvisitedDayCountCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter,
  );
  const visitedShopsFilter = customerSegment?.messageDeliveryCustomerSegmentShopConditions.map(
    ({ shopId }) => shopId,
  );
  const visitedBusinessOperationHoursFilter =
    customerSegment?.messageDeliveryCustomerSegmentBusinessOperationHoursConditions.map(
      ({ businessOperationHourType }) => businessOperationHourType,
    ) ?? [];

  const visitedDayOfWeekFilter =
    customerSegment?.messageDeliveryCustomerSegmentDayOfWeekConditions.map(
      ({ messageDeliveryDayOfWeek }) => messageDeliveryDayOfWeek,
    ) ?? [];

  const visitedDayOfWeekAllChecker =
    visitedDayOfWeekFilter.length === 8 || visitedDayOfWeekFilter.length === 0;

  const questionnaireAnswerStatusFilter =
    customerSegment?.messageDeliveryCustomerSegmentQuestionnaireAnswerCondition
      ?.messageDeliveryQuestionnaireAnswerStatus ?? "All";

  const menuConditions = customerSegment?.messageDeliveryCustomerSegmentMenuConditions;
  const orderedMenusFilter = menuConditions?.length
    ? {
        type: "menu",
        menuIds: menuConditions.map(({ menuId }) => menuId),
        moreThanOrEqualQuantity: menuConditions[0]?.moreThanOrEqualQuantity ?? null,
      }
    : undefined;

  const genderFilter =
    customerSegment?.messageDeliveryCustomerSegmentGenderCondition?.messageDeliveryGender ?? "All";

  const birthdayCountFilter =
    customerSegment?.messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter;

  const customerIds =
    messageDelivery.customerList?.customerListCustomers?.map(({ customer }) => customer.id) ?? [];

  const isFromCsv = messageDelivery.customerList?.isFromCsv;
  const uploadedCsvFile =
    isFromCsv && messageDelivery.customerListId
      ? {
          uid: messageDelivery.customerListId,
          name: "顧客リスト",
        }
      : undefined;

  return {
    name,
    scope: customerSegmentId
      ? "customerSegment"
      : customerListId && isFromCsv
      ? "customerListCsvUpload"
      : customerListId
      ? "customerList"
      : "broadcast",
    customerSegmentId,
    visitedDayCountFilter,
    visitedDayOfWeekAllChecker,
    visitedDayOfWeekFilter: visitedDayOfWeekAllChecker ? [] : visitedDayOfWeekFilter,
    consecutiveUnvisitedDayCountFilter,
    questionnaireAnswerStatusFilter,
    visitedBusinessOperationHoursFilterBusinessOperationHourTypes:
      visitedBusinessOperationHoursFilter.length === 0
        ? ["All"]
        : visitedBusinessOperationHoursFilter,
    visitedShopsFilterShopIds: visitedShopsFilter,
    orderedMenusFilter,
    genderFilter,
    birthdayCountFilter,
    customerListId,
    deliverDate: deliverAt,
    deliverTime: deliverAt,
    isRepeatDelivery: Boolean(repeatDeliveryTime),
    customerIds: !isFromCsv ? customerIds : [],
    customerIdsFromCsv: isFromCsv ? customerIds : [],
    uploadedCsv:
      isFromCsv && uploadedCsvFile
        ? { file: uploadedCsvFile, fileList: [uploadedCsvFile] }
        : undefined,
    isSuspended,
    messages: messageDeliveryMessages
      .map((messageDeliveryMessage) => {
        const { type, textTypeMeta, imageTypeMeta, couponTypeMeta, questionnaireTypeMeta } =
          messageDeliveryMessage;

        if (type === MessageDeliveryMessageTypeEnum.Text && textTypeMeta) {
          return { type, text: textTypeMeta.text };
        }

        if (type === MessageDeliveryMessageTypeEnum.Image && imageTypeMeta) {
          return { type, imageUrl: imageTypeMeta.imageUrl, url: imageTypeMeta.url };
        }

        if (type === MessageDeliveryMessageTypeEnum.Coupon && couponTypeMeta) {
          return { type, couponId: couponTypeMeta.couponId };
        }

        if (type === MessageDeliveryMessageTypeEnum.Questionnaire && questionnaireTypeMeta) {
          return { type, questionnaireConfigId: questionnaireTypeMeta.questionnaireConfigId };
        }

        return null;
      })
      .filter(isNotNull),
  };
};

export const MessageDeliveryJobFormItem = createFormItem<MessageDeliveryJobFormValues>();

export const useMessageDeliveryJobForm = ({
  messageDelivery,
}: {
  messageDelivery: MessageDelivery;
}) => {
  const [form] = Form.useForm<MessageDeliveryJobFormValues>();

  const initialValues = getInitialValues({ messageDelivery });

  return { form, initialValues };
};
