import React from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { convertToFaveYellRanking } from "models/customerDashboard";

import { CustomerRanking } from "components/CustomerRanking";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { useRankingFaveYellCountsGetCustomerFaveYellCountRankingQuery } from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const RankingFaveYellCounts = () => {
  const { shop } = useShopPerCorporation();

  const {
    data: getCustomerFaveYellCountRankingData,
    loading: getCustomerFaveYellCountRankingDataLoading,
    error: getCustomerFaveYellCountRankingDataError,
  } = useRankingFaveYellCountsGetCustomerFaveYellCountRankingQuery(
    shop ? { variables: { input: { shopId: shop.shopId } } } : { skip: true },
  );

  const viralRankingData = convertToFaveYellRanking(
    getCustomerFaveYellCountRankingData?.customerFaveYellCountRanking.customers,
  );

  return (
    <DashboardLayout title="推しエール回数ランキング">
      {getCustomerFaveYellCountRankingDataError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CustomerRanking
        rankingData={viralRankingData}
        loading={getCustomerFaveYellCountRankingDataLoading}
        title="推しエール回数ランキング"
        valueUnit="回"
      />
    </DashboardLayout>
  );
};
