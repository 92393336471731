import React, { memo, useMemo } from "react";
import { Popconfirm, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";

import { Plan } from "../types";

type Props = {
  loading?: boolean;
  plans: Plan[];
  onDelete: ({ pikaichiMenuId }: { pikaichiMenuId: string }) => void;
};

const getPikaichiMenuFromPlan = (plan: Plan) => plan.pikaichiMenuPlans[0]?.pikaichiMenu ?? null;

export const PlanTable = memo<Props>(({ loading, plans, onDelete }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const columns: ColumnsType<Plan> = useMemo(
    () => [
      {
        title: "カテゴリ名",
        width: 150,
        fixed: "left",
        render(_: string, { category }: Plan) {
          return category.name;
        },
      },
      {
        title: "プラン名",
        dataIndex: "planName",
        fixed: "left",
        width: 200,
      },
      {
        title: "オプション",
        width: 200,
        render(_: string, { planOptions }: Plan) {
          return planOptions.map(({ name, planOptionId }) => <Tag key={planOptionId}>{name}</Tag>);
        },
      },
      {
        title: "メニューコード",
        align: "left",
        width: 130,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiMenuCd;
        },
      },
      {
        title: "メニュー名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiMenuName;
        },
      },
      {
        title: "部門コード",
        align: "left",
        width: 100,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiBumonCd;
        },
      },
      {
        title: "部門名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiBumonName;
        },
      },
      {
        title: "分類コード",
        align: "left",
        width: 100,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiCategoryCd;
        },
      },
      {
        title: "分類名",
        align: "left",
        width: 150,
        render(_: string, plan: Plan) {
          const pikaichiMenu = getPikaichiMenuFromPlan(plan);
          return pikaichiMenu?.pikaichiCategoryName;
        },
      },
      ...(canAccess("editPikaichiPlan")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, { planId }: Plan) {
                return (
                  <Tooltip title="メニューを編集">
                    <IconLink to={`/pikaichi/plan/${planId}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
      ...(isFeatureEnabled("deletePikaichiPlan")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, plan: Plan) {
                const pikaichiMenuId = getPikaichiMenuFromPlan(plan)?.pikaichiMenuId;

                if (!pikaichiMenuId) return <DeleteIcon disabled />;

                return (
                  <Popconfirm
                    title={
                      <>
                        <Paragraph>ぴかいちのメニューを削除しますか？</Paragraph>
                        <Paragraph>一度削除したメニューを元に戻すことはできません。</Paragraph>
                      </>
                    }
                    okText="はい"
                    cancelText="キャンセル"
                    onConfirm={() => onDelete({ pikaichiMenuId })}
                  >
                    <DeleteIcon />
                  </Popconfirm>
                );
              },
            } as const,
          ]
        : []),
    ],
    [onDelete, canAccess, isFeatureEnabled],
  );

  return (
    <Table
      rowKey="planId"
      columns={columns}
      dataSource={plans}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
