import React, { useCallback, useMemo, useState } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button } from "antd";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { DishUpSlipGroupHeader } from "components/PageHeader/DishUpReceiptGroupHeader";
import { FormContent } from "components/Template/FormTemplate";
import { AddDishUpSlipGroupMenuModal } from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal";
import { MenuTable } from "pages/DishUpSlipGroupMenus/DishUpSlipGroupMenuTable";
import { MenuFilter } from "pages/DishUpSlipGroupMenus/MenuFilter";
import {
  useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation,
  useDishUpSlipGroupMenusGetCategoriesQuery,
  useDishUpSlipGroupMenusGetMenusQuery,
} from "pages/DishUpSlipGroupMenus/queries";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

export const DishUpSlipGroupMenus = () => {
  const { id } = useParams<{ id: string }>();
  const dishUpSlipGroupId = Number(id);
  const navigate = useNavigate();

  const {
    data: getDishUpSlipGroupData,
    refetch,
    loading: loadingDishUpSlipGroup,
    error: errorDishUpSlipGroup,
  } = useDishUpSlipGroupMenusGetMenusQuery(
    !Number.isNaN(dishUpSlipGroupId) ? { variables: { dishUpSlipGroupId } } : { skip: true },
  );
  const dishUpSlipGroup = getDishUpSlipGroupData?.dishUpSlipGroup[0];
  const shop = dishUpSlipGroup?.shop;
  const menus = useMemo(
    () => dishUpSlipGroup?.dishUpSlipGroupShopMenus?.map(({ menu }) => menu) ?? [],
    [dishUpSlipGroup],
  );

  const companyId = shop?.companyId;

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    error: errorGetCategories,
  } = useDishUpSlipGroupMenusGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const [categoryId, setCategoryId] = useState<number | null>(null);

  const [
    deleteDishUpSlipGroupDeleteDishUpSlipGroupMenusMenu,
    { loading: loadingDeleteDishUpSlipGroupMenusMenu },
  ] = useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation();

  const onRemove = useCallback(
    async (menuId: number) => {
      const dishUpSlipGroupId = dishUpSlipGroup?.dishUpSlipGroupId;
      if (dishUpSlipGroupId) {
        try {
          const { data: deleteDishUpSlipGroupDeleteDishUpSlipGroupMenu } =
            await deleteDishUpSlipGroupDeleteDishUpSlipGroupMenusMenu({
              variables: { dishUpSlipGroupId, menuId },
            });
          const returning =
            deleteDishUpSlipGroupDeleteDishUpSlipGroupMenu?.delete_dishUpSlipGroupShopMenus
              ?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("デシャップグループから削除しました");
        } catch (err) {
          message.success("デシャップグループからの削除に失敗しました");
        }
      }
    },
    [
      deleteDishUpSlipGroupDeleteDishUpSlipGroupMenusMenu,
      dishUpSlipGroup?.dishUpSlipGroupId,
      refetch,
    ],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const goBackToDishUpSlipGroupMenus = useCallback(() => {
    navigate(`/dishUpSlipGroup/${dishUpSlipGroupId}/menu`, { replace: true });
  }, [navigate, dishUpSlipGroupId]);

  const filteredMenus = useMemo(
    () =>
      categoryId
        ? menus
            .filter((menu) =>
              menu?.categoryMenus?.some((categoryMenu) => categoryMenu.categoryId === categoryId),
            )
            .filter(isNotNullable)
        : menus.filter(isNotNullable),
    [menus, categoryId],
  );

  return (
    <DashboardLayout
      title={dishUpSlipGroup?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "デシャップ伝票グループ" }],
      }}
    >
      <DishUpSlipGroupHeader dishUpSlipGroup={dishUpSlipGroup ?? null} onBack={goBack} />
      {(loadingCategories || loadingDishUpSlipGroup) && <Loading height={300} />}
      {(errorGetCategories || errorDishUpSlipGroup) && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {dishUpSlipGroup && !loadingCategories && (
        <FormContent>
          <ButtonWrapper>
            <Link key="addMenu" to={`/dishUpSlipGroup/${dishUpSlipGroupId}/menu/add`} replace>
              <Button type="primary">追加</Button>
            </Link>
          </ButtonWrapper>
          <MenuFilter
            categories={categories}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
          />
          <MenuTable
            menus={filteredMenus}
            loading={loadingDeleteDishUpSlipGroupMenusMenu}
            onRemove={onRemove}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddDishUpSlipGroupMenuModal
                  dishUpSlipGroup={dishUpSlipGroup}
                  goBack={goBackToDishUpSlipGroupMenus}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
