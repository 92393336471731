import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ExternalOnlineMenuConfigInsertInput, ExternalOnlineMenuInsertInput } from "types/graphql";

import { AddShopExternalOnlineMenuConfigForm } from "./AddShopExternalOnlineMenuConfigForm";
import { AddShopExternalOnlineMenuForm } from "./AddShopExternalOnlineMenuForm";
import {
  useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery,
  useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation,
  useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation,
} from "./queries";

export const AddShopExternalOnlineMenu = () => {
  const navigate = useNavigate();
  const { shopId } = useParams<{ shopId: string }>();

  const {
    data,
    loading: loadingGetExternalOnlineMenuConfig,
    error,
  } = useAddShopExternalOnlineMenuGetExternalOnlineMenuConfigAndShopQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const changeDateTime = data?.shop_by_pk?.changeDateTime;
  const configId = data?.externalOnlineMenuConfig[0]?.id;

  const [addExternalOnlineMenuMutation, { loading: loadingAddExternalOnlineMenuMutation }] =
    useAddShopExternalOnlineMenuInsertExternalOnlineMenuMutation();

  const [
    addExternalOnlineMenuConfigMutation,
    { loading: loadingAddExternalOnlineMenuConfigMutation },
  ] = useAddShopExternalOnlineMenuInsertExternalOnlineMenuConfigMutation();

  const loading =
    loadingGetExternalOnlineMenuConfig ||
    loadingAddExternalOnlineMenuMutation ||
    loadingAddExternalOnlineMenuConfigMutation;

  const onSubmitExternalOnlineMenu = useCallback(
    async (input: ExternalOnlineMenuInsertInput) => {
      try {
        await addExternalOnlineMenuMutation({
          variables: { input },
        });
        message.success("作成しました");

        navigate("/externalOnlineMenu", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addExternalOnlineMenuMutation, navigate],
  );

  const onSubmitExternalOnlineMenuConfig = useCallback(
    async (input: ExternalOnlineMenuConfigInsertInput) => {
      try {
        await addExternalOnlineMenuConfigMutation({
          variables: { input },
        });
        message.success("作成しました");

        navigate("/externalOnlineMenu", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addExternalOnlineMenuConfigMutation, navigate],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title="外部オンラインメニューを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "外部オンラインメニュー" }],
      }}
    >
      <PageHeader title="外部オンラインメニューを新規作成" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {configId ? (
        <>
          {changeDateTime && (
            <AddShopExternalOnlineMenuForm
              onSubmit={onSubmitExternalOnlineMenu}
              onFormValidationError={onFormValidationError}
              onCancel={goBack}
              externalOnlineMenuConfigId={configId}
              changeDateTime={changeDateTime}
              loading={loading}
            />
          )}
        </>
      ) : (
        <>
          {shopId && (
            <AddShopExternalOnlineMenuConfigForm
              shopId={shopId}
              onSubmit={onSubmitExternalOnlineMenuConfig}
              onFormValidationError={onFormValidationError}
              onCancel={goBack}
              loading={loading}
            />
          )}
        </>
      )}
    </DashboardLayout>
  );
};
