import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { AddPlanFormItem } from "../useAddPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <AddPlanFormItem
    label={<FormHelp label="プラン名" help="お客様が見る注文画面や会計伝票での表示名" />}
    name="planName"
    rules={[{ required: true, message: "プラン名を入力してください" }]}
    {...props}
  >
    <Input />
  </AddPlanFormItem>
));
