import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddGiftGetGiftMetaMaxPriority = gql`
    query AddGiftGetGiftMetaMaxPriority($shopId: uuid!) {
  giftMeta_aggregate(
    where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;
export const AddGiftInsertMenu = gql`
    mutation AddGiftInsertMenu($menu: menu_insert_input!) {
  insert_menu_one(
    object: $menu
    on_conflict: {constraint: idx_46512_PRIMARY, update_columns: []}
  ) {
    id
    serial
  }
}
    `;
export const AddGiftInsertShopMenus = gql`
    mutation AddGiftInsertShopMenus($shopMenus: CreateShopMenusInput!, $giftMeta: giftMeta_insert_input!) {
  createShopMenus(input: $shopMenus) {
    result
  }
  insert_giftMeta_one(object: $giftMeta) {
    id
  }
}
    `;
export type AddGiftGetGiftMetaMaxPriorityQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddGiftGetGiftMetaMaxPriorityQuery = (
  { __typename?: 'query_root' }
  & { giftMeta_aggregate: (
    { __typename?: 'giftMeta_aggregate' }
    & { aggregate?: Types.Maybe<(
      { __typename?: 'giftMeta_aggregate_fields' }
      & { max?: Types.Maybe<(
        { __typename?: 'giftMeta_max_fields' }
        & Pick<Types.GiftMetaMaxFields, 'priority'>
      )> }
    )> }
  ) }
);

export type AddGiftInsertMenuMutationVariables = Types.Exact<{
  menu: Types.MenuInsertInput;
}>;


export type AddGiftInsertMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_menu_one?: Types.Maybe<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'serial'>
  )> }
);

export type AddGiftInsertShopMenusMutationVariables = Types.Exact<{
  shopMenus: Types.CreateShopMenusInput;
  giftMeta: Types.GiftMetaInsertInput;
}>;


export type AddGiftInsertShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & { createShopMenus: (
    { __typename?: 'CreateShopMenusResult' }
    & Pick<Types.CreateShopMenusResult, 'result'>
  ), insert_giftMeta_one?: Types.Maybe<(
    { __typename?: 'giftMeta' }
    & Pick<Types.GiftMeta, 'id'>
  )> }
);


export const AddGiftGetGiftMetaMaxPriorityDocument = gql`
    query AddGiftGetGiftMetaMaxPriority($shopId: uuid!) {
  giftMeta_aggregate(
    where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}}}
  ) {
    aggregate {
      max {
        priority
      }
    }
  }
}
    `;

/**
 * __useAddGiftGetGiftMetaMaxPriorityQuery__
 *
 * To run a query within a React component, call `useAddGiftGetGiftMetaMaxPriorityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddGiftGetGiftMetaMaxPriorityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddGiftGetGiftMetaMaxPriorityQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddGiftGetGiftMetaMaxPriorityQuery(baseOptions: Apollo.QueryHookOptions<AddGiftGetGiftMetaMaxPriorityQuery, AddGiftGetGiftMetaMaxPriorityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddGiftGetGiftMetaMaxPriorityQuery, AddGiftGetGiftMetaMaxPriorityQueryVariables>(AddGiftGetGiftMetaMaxPriorityDocument, options);
      }
export function useAddGiftGetGiftMetaMaxPriorityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddGiftGetGiftMetaMaxPriorityQuery, AddGiftGetGiftMetaMaxPriorityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddGiftGetGiftMetaMaxPriorityQuery, AddGiftGetGiftMetaMaxPriorityQueryVariables>(AddGiftGetGiftMetaMaxPriorityDocument, options);
        }
export type AddGiftGetGiftMetaMaxPriorityQueryHookResult = ReturnType<typeof useAddGiftGetGiftMetaMaxPriorityQuery>;
export type AddGiftGetGiftMetaMaxPriorityLazyQueryHookResult = ReturnType<typeof useAddGiftGetGiftMetaMaxPriorityLazyQuery>;
export type AddGiftGetGiftMetaMaxPriorityQueryResult = Apollo.QueryResult<AddGiftGetGiftMetaMaxPriorityQuery, AddGiftGetGiftMetaMaxPriorityQueryVariables>;
export const AddGiftInsertMenuDocument = gql`
    mutation AddGiftInsertMenu($menu: menu_insert_input!) {
  insert_menu_one(
    object: $menu
    on_conflict: {constraint: idx_46512_PRIMARY, update_columns: []}
  ) {
    id
    serial
  }
}
    `;
export type AddGiftInsertMenuMutationFn = Apollo.MutationFunction<AddGiftInsertMenuMutation, AddGiftInsertMenuMutationVariables>;

/**
 * __useAddGiftInsertMenuMutation__
 *
 * To run a mutation, you first call `useAddGiftInsertMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftInsertMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftInsertMenuMutation, { data, loading, error }] = useAddGiftInsertMenuMutation({
 *   variables: {
 *      menu: // value for 'menu'
 *   },
 * });
 */
export function useAddGiftInsertMenuMutation(baseOptions?: Apollo.MutationHookOptions<AddGiftInsertMenuMutation, AddGiftInsertMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGiftInsertMenuMutation, AddGiftInsertMenuMutationVariables>(AddGiftInsertMenuDocument, options);
      }
export type AddGiftInsertMenuMutationHookResult = ReturnType<typeof useAddGiftInsertMenuMutation>;
export type AddGiftInsertMenuMutationResult = Apollo.MutationResult<AddGiftInsertMenuMutation>;
export type AddGiftInsertMenuMutationOptions = Apollo.BaseMutationOptions<AddGiftInsertMenuMutation, AddGiftInsertMenuMutationVariables>;
export const AddGiftInsertShopMenusDocument = gql`
    mutation AddGiftInsertShopMenus($shopMenus: CreateShopMenusInput!, $giftMeta: giftMeta_insert_input!) {
  createShopMenus(input: $shopMenus) {
    result
  }
  insert_giftMeta_one(object: $giftMeta) {
    id
  }
}
    `;
export type AddGiftInsertShopMenusMutationFn = Apollo.MutationFunction<AddGiftInsertShopMenusMutation, AddGiftInsertShopMenusMutationVariables>;

/**
 * __useAddGiftInsertShopMenusMutation__
 *
 * To run a mutation, you first call `useAddGiftInsertShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGiftInsertShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGiftInsertShopMenusMutation, { data, loading, error }] = useAddGiftInsertShopMenusMutation({
 *   variables: {
 *      shopMenus: // value for 'shopMenus'
 *      giftMeta: // value for 'giftMeta'
 *   },
 * });
 */
export function useAddGiftInsertShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<AddGiftInsertShopMenusMutation, AddGiftInsertShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGiftInsertShopMenusMutation, AddGiftInsertShopMenusMutationVariables>(AddGiftInsertShopMenusDocument, options);
      }
export type AddGiftInsertShopMenusMutationHookResult = ReturnType<typeof useAddGiftInsertShopMenusMutation>;
export type AddGiftInsertShopMenusMutationResult = Apollo.MutationResult<AddGiftInsertShopMenusMutation>;
export type AddGiftInsertShopMenusMutationOptions = Apollo.BaseMutationOptions<AddGiftInsertShopMenusMutation, AddGiftInsertShopMenusMutationVariables>;