import { OnSitePaymentDetailTypeCategoryEnum } from "types/graphql";

export const onSitePaymentDetailTypeCategory: {
  [K in OnSitePaymentDetailTypeCategoryEnum]: string;
} = {
  cash: "現金",
  point: "ポイント",
  creditCard: "クレジット",
  electronicMoney: "電子マネー",
  installmentSales: "掛売",
  giftCertificate: "商品券",
  QRPayment: "QR決済",
  onlinePayment: "オンライン決済",
  campaign: "キャンペーン",
  other: "その他",
};
