import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditDiniiPayConfigGetGmoShopConfigDetail = gql`
    query EditDiniiPayConfigGetGmoShopConfigDetail($input: DashboardAccountGmoShopConfigDetailInput!) {
  onlinePaymentRoot {
    gmoShopConfigDetail(input: $input) {
      amexCardFeeRate
      dinersCardFeeRate
      gmoShopId
      gmoShopPassword
      jcbCardFeeRate
      masterCardFeeRate
      visaCardFeeRate
    }
  }
}
    `;
export const EditDiniiPayConfigGetGmoOffPaymentShopConfig = gql`
    query EditDiniiPayConfigGetGmoOffPaymentShopConfig($shopId: uuid!) {
  gmoOffPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    isEnabled
    terminalId
  }
}
    `;
export const EditDiniiPayConfigGetAdyenPaymentShopConfig = gql`
    query EditDiniiPayConfigGetAdyenPaymentShopConfig($shopId: uuid!) {
  adyenPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    adyenPaymentShopPaymentMethods {
      id
      paymentMethod
      transactionFeeRate
    }
  }
}
    `;
export const EditDiniiPayConfigGetAdyenTerminalPaymentShopConfig = gql`
    query EditDiniiPayConfigGetAdyenTerminalPaymentShopConfig($shopId: uuid!) {
  adyenTerminalPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    adyenTerminalPaymentShopPaymentMethods {
      id
      paymentMethod
      transactionFeeRate
    }
  }
}
    `;
export const EditDiniiPayConfigGetShop = gql`
    query EditDiniiPayConfigGetShop($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export const EditDiniiPayConfigUpsertDiniiPayConfig = gql`
    mutation EditDiniiPayConfigUpsertDiniiPayConfig($input: DashboardAccountUpsertDiniiPayConfigInput!) {
  onlinePaymentRoot {
    upsertDiniiPayConfig(input: $input) {
      result
    }
  }
}
    `;
export type EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables = Types.Exact<{
  input: Types.DashboardAccountGmoShopConfigDetailInput;
}>;


export type EditDiniiPayConfigGetGmoShopConfigDetailQuery = (
  { __typename?: 'query_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootQuery' }
    & { gmoShopConfigDetail?: Types.Maybe<(
      { __typename?: 'DashboardAccountGmoShopConfigDetailOutput' }
      & Pick<Types.DashboardAccountGmoShopConfigDetailOutput, 'amexCardFeeRate' | 'dinersCardFeeRate' | 'gmoShopId' | 'gmoShopPassword' | 'jcbCardFeeRate' | 'masterCardFeeRate' | 'visaCardFeeRate'>
    )> }
  )> }
);

export type EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery = (
  { __typename?: 'query_root' }
  & { gmoOffPaymentShopConfig: Array<(
    { __typename?: 'gmoOffPaymentShopConfig' }
    & Pick<Types.GmoOffPaymentShopConfig, 'id' | 'isEnabled' | 'terminalId'>
  )> }
);

export type EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditDiniiPayConfigGetAdyenPaymentShopConfigQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentShopConfig: Array<(
    { __typename?: 'adyenPaymentShopConfig' }
    & Pick<Types.AdyenPaymentShopConfig, 'id'>
    & { adyenPaymentShopPaymentMethods: Array<(
      { __typename?: 'adyenPaymentShopPaymentMethod' }
      & Pick<Types.AdyenPaymentShopPaymentMethod, 'id' | 'paymentMethod' | 'transactionFeeRate'>
    )> }
  )> }
);

export type EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery = (
  { __typename?: 'query_root' }
  & { adyenTerminalPaymentShopConfig: Array<(
    { __typename?: 'adyenTerminalPaymentShopConfig' }
    & Pick<Types.AdyenTerminalPaymentShopConfig, 'id'>
    & { adyenTerminalPaymentShopPaymentMethods: Array<(
      { __typename?: 'adyenTerminalPaymentShopPaymentMethod' }
      & Pick<Types.AdyenTerminalPaymentShopPaymentMethod, 'id' | 'paymentMethod' | 'transactionFeeRate'>
    )> }
  )> }
);

export type EditDiniiPayConfigGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditDiniiPayConfigGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditDiniiPayConfigUpsertDiniiPayConfigMutationVariables = Types.Exact<{
  input: Types.DashboardAccountUpsertDiniiPayConfigInput;
}>;


export type EditDiniiPayConfigUpsertDiniiPayConfigMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { upsertDiniiPayConfig: (
      { __typename?: 'DashboardAccountUpsertDiniiPayConfigOutput' }
      & Pick<Types.DashboardAccountUpsertDiniiPayConfigOutput, 'result'>
    ) }
  )> }
);


export const EditDiniiPayConfigGetGmoShopConfigDetailDocument = gql`
    query EditDiniiPayConfigGetGmoShopConfigDetail($input: DashboardAccountGmoShopConfigDetailInput!) {
  onlinePaymentRoot {
    gmoShopConfigDetail(input: $input) {
      amexCardFeeRate
      dinersCardFeeRate
      gmoShopId
      gmoShopPassword
      jcbCardFeeRate
      masterCardFeeRate
      visaCardFeeRate
    }
  }
}
    `;

/**
 * __useEditDiniiPayConfigGetGmoShopConfigDetailQuery__
 *
 * To run a query within a React component, call `useEditDiniiPayConfigGetGmoShopConfigDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigGetGmoShopConfigDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDiniiPayConfigGetGmoShopConfigDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDiniiPayConfigGetGmoShopConfigDetailQuery(baseOptions: Apollo.QueryHookOptions<EditDiniiPayConfigGetGmoShopConfigDetailQuery, EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDiniiPayConfigGetGmoShopConfigDetailQuery, EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables>(EditDiniiPayConfigGetGmoShopConfigDetailDocument, options);
      }
export function useEditDiniiPayConfigGetGmoShopConfigDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDiniiPayConfigGetGmoShopConfigDetailQuery, EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDiniiPayConfigGetGmoShopConfigDetailQuery, EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables>(EditDiniiPayConfigGetGmoShopConfigDetailDocument, options);
        }
export type EditDiniiPayConfigGetGmoShopConfigDetailQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetGmoShopConfigDetailQuery>;
export type EditDiniiPayConfigGetGmoShopConfigDetailLazyQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetGmoShopConfigDetailLazyQuery>;
export type EditDiniiPayConfigGetGmoShopConfigDetailQueryResult = Apollo.QueryResult<EditDiniiPayConfigGetGmoShopConfigDetailQuery, EditDiniiPayConfigGetGmoShopConfigDetailQueryVariables>;
export const EditDiniiPayConfigGetGmoOffPaymentShopConfigDocument = gql`
    query EditDiniiPayConfigGetGmoOffPaymentShopConfig($shopId: uuid!) {
  gmoOffPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    isEnabled
    terminalId
  }
}
    `;

/**
 * __useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery__
 *
 * To run a query within a React component, call `useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery(baseOptions: Apollo.QueryHookOptions<EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery, EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery, EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetGmoOffPaymentShopConfigDocument, options);
      }
export function useEditDiniiPayConfigGetGmoOffPaymentShopConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery, EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery, EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetGmoOffPaymentShopConfigDocument, options);
        }
export type EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetGmoOffPaymentShopConfigQuery>;
export type EditDiniiPayConfigGetGmoOffPaymentShopConfigLazyQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetGmoOffPaymentShopConfigLazyQuery>;
export type EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryResult = Apollo.QueryResult<EditDiniiPayConfigGetGmoOffPaymentShopConfigQuery, EditDiniiPayConfigGetGmoOffPaymentShopConfigQueryVariables>;
export const EditDiniiPayConfigGetAdyenPaymentShopConfigDocument = gql`
    query EditDiniiPayConfigGetAdyenPaymentShopConfig($shopId: uuid!) {
  adyenPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    adyenPaymentShopPaymentMethods {
      id
      paymentMethod
      transactionFeeRate
    }
  }
}
    `;

/**
 * __useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery__
 *
 * To run a query within a React component, call `useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery(baseOptions: Apollo.QueryHookOptions<EditDiniiPayConfigGetAdyenPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDiniiPayConfigGetAdyenPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetAdyenPaymentShopConfigDocument, options);
      }
export function useEditDiniiPayConfigGetAdyenPaymentShopConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDiniiPayConfigGetAdyenPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDiniiPayConfigGetAdyenPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetAdyenPaymentShopConfigDocument, options);
        }
export type EditDiniiPayConfigGetAdyenPaymentShopConfigQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetAdyenPaymentShopConfigQuery>;
export type EditDiniiPayConfigGetAdyenPaymentShopConfigLazyQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetAdyenPaymentShopConfigLazyQuery>;
export type EditDiniiPayConfigGetAdyenPaymentShopConfigQueryResult = Apollo.QueryResult<EditDiniiPayConfigGetAdyenPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenPaymentShopConfigQueryVariables>;
export const EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigDocument = gql`
    query EditDiniiPayConfigGetAdyenTerminalPaymentShopConfig($shopId: uuid!) {
  adyenTerminalPaymentShopConfig(where: {shopId: {_eq: $shopId}}) {
    id
    adyenTerminalPaymentShopPaymentMethods {
      id
      paymentMethod
      transactionFeeRate
    }
  }
}
    `;

/**
 * __useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery__
 *
 * To run a query within a React component, call `useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery(baseOptions: Apollo.QueryHookOptions<EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigDocument, options);
      }
export function useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables>(EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigDocument, options);
        }
export type EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery>;
export type EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigLazyQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetAdyenTerminalPaymentShopConfigLazyQuery>;
export type EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryResult = Apollo.QueryResult<EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQuery, EditDiniiPayConfigGetAdyenTerminalPaymentShopConfigQueryVariables>;
export const EditDiniiPayConfigGetShopDocument = gql`
    query EditDiniiPayConfigGetShop($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useEditDiniiPayConfigGetShopQuery__
 *
 * To run a query within a React component, call `useEditDiniiPayConfigGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditDiniiPayConfigGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditDiniiPayConfigGetShopQuery(baseOptions: Apollo.QueryHookOptions<EditDiniiPayConfigGetShopQuery, EditDiniiPayConfigGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditDiniiPayConfigGetShopQuery, EditDiniiPayConfigGetShopQueryVariables>(EditDiniiPayConfigGetShopDocument, options);
      }
export function useEditDiniiPayConfigGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditDiniiPayConfigGetShopQuery, EditDiniiPayConfigGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditDiniiPayConfigGetShopQuery, EditDiniiPayConfigGetShopQueryVariables>(EditDiniiPayConfigGetShopDocument, options);
        }
export type EditDiniiPayConfigGetShopQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetShopQuery>;
export type EditDiniiPayConfigGetShopLazyQueryHookResult = ReturnType<typeof useEditDiniiPayConfigGetShopLazyQuery>;
export type EditDiniiPayConfigGetShopQueryResult = Apollo.QueryResult<EditDiniiPayConfigGetShopQuery, EditDiniiPayConfigGetShopQueryVariables>;
export const EditDiniiPayConfigUpsertDiniiPayConfigDocument = gql`
    mutation EditDiniiPayConfigUpsertDiniiPayConfig($input: DashboardAccountUpsertDiniiPayConfigInput!) {
  onlinePaymentRoot {
    upsertDiniiPayConfig(input: $input) {
      result
    }
  }
}
    `;
export type EditDiniiPayConfigUpsertDiniiPayConfigMutationFn = Apollo.MutationFunction<EditDiniiPayConfigUpsertDiniiPayConfigMutation, EditDiniiPayConfigUpsertDiniiPayConfigMutationVariables>;

/**
 * __useEditDiniiPayConfigUpsertDiniiPayConfigMutation__
 *
 * To run a mutation, you first call `useEditDiniiPayConfigUpsertDiniiPayConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiniiPayConfigUpsertDiniiPayConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiniiPayConfigUpsertDiniiPayConfigMutation, { data, loading, error }] = useEditDiniiPayConfigUpsertDiniiPayConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDiniiPayConfigUpsertDiniiPayConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditDiniiPayConfigUpsertDiniiPayConfigMutation, EditDiniiPayConfigUpsertDiniiPayConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDiniiPayConfigUpsertDiniiPayConfigMutation, EditDiniiPayConfigUpsertDiniiPayConfigMutationVariables>(EditDiniiPayConfigUpsertDiniiPayConfigDocument, options);
      }
export type EditDiniiPayConfigUpsertDiniiPayConfigMutationHookResult = ReturnType<typeof useEditDiniiPayConfigUpsertDiniiPayConfigMutation>;
export type EditDiniiPayConfigUpsertDiniiPayConfigMutationResult = Apollo.MutationResult<EditDiniiPayConfigUpsertDiniiPayConfigMutation>;
export type EditDiniiPayConfigUpsertDiniiPayConfigMutationOptions = Apollo.BaseMutationOptions<EditDiniiPayConfigUpsertDiniiPayConfigMutation, EditDiniiPayConfigUpsertDiniiPayConfigMutationVariables>;