import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EdiLineOfficialAccountGetLineOfficialAccount = gql`
    query EdiLineOfficialAccountGetLineOfficialAccount($lineOfficialAccountId: Int!) {
  lineOfficialAccount(
    where: {lineOfficialAccountId: {_eq: $lineOfficialAccountId}}
  ) {
    id
    lineOfficialAccountId
    lineId
    channelId
    channelSecret
    longLivedChannelAccessToken
  }
}
    `;
export const EdiLineOfficialAccountUpdateLineOfficialAccount = gql`
    mutation EdiLineOfficialAccountUpdateLineOfficialAccount($input: UpdateLineOfficialAccountInput!) {
  updateLineOfficialAccount(input: $input)
}
    `;
export type EdiLineOfficialAccountGetLineOfficialAccountQueryVariables = Types.Exact<{
  lineOfficialAccountId: Types.Scalars['Int'];
}>;


export type EdiLineOfficialAccountGetLineOfficialAccountQuery = (
  { __typename?: 'query_root' }
  & { lineOfficialAccount: Array<(
    { __typename?: 'lineOfficialAccount' }
    & Pick<Types.LineOfficialAccount, 'id' | 'lineOfficialAccountId' | 'lineId' | 'channelId' | 'channelSecret' | 'longLivedChannelAccessToken'>
  )> }
);

export type EdiLineOfficialAccountUpdateLineOfficialAccountMutationVariables = Types.Exact<{
  input: Types.UpdateLineOfficialAccountInput;
}>;


export type EdiLineOfficialAccountUpdateLineOfficialAccountMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'updateLineOfficialAccount'>
);


export const EdiLineOfficialAccountGetLineOfficialAccountDocument = gql`
    query EdiLineOfficialAccountGetLineOfficialAccount($lineOfficialAccountId: Int!) {
  lineOfficialAccount(
    where: {lineOfficialAccountId: {_eq: $lineOfficialAccountId}}
  ) {
    id
    lineOfficialAccountId
    lineId
    channelId
    channelSecret
    longLivedChannelAccessToken
  }
}
    `;

/**
 * __useEdiLineOfficialAccountGetLineOfficialAccountQuery__
 *
 * To run a query within a React component, call `useEdiLineOfficialAccountGetLineOfficialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEdiLineOfficialAccountGetLineOfficialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEdiLineOfficialAccountGetLineOfficialAccountQuery({
 *   variables: {
 *      lineOfficialAccountId: // value for 'lineOfficialAccountId'
 *   },
 * });
 */
export function useEdiLineOfficialAccountGetLineOfficialAccountQuery(baseOptions: Apollo.QueryHookOptions<EdiLineOfficialAccountGetLineOfficialAccountQuery, EdiLineOfficialAccountGetLineOfficialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EdiLineOfficialAccountGetLineOfficialAccountQuery, EdiLineOfficialAccountGetLineOfficialAccountQueryVariables>(EdiLineOfficialAccountGetLineOfficialAccountDocument, options);
      }
export function useEdiLineOfficialAccountGetLineOfficialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EdiLineOfficialAccountGetLineOfficialAccountQuery, EdiLineOfficialAccountGetLineOfficialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EdiLineOfficialAccountGetLineOfficialAccountQuery, EdiLineOfficialAccountGetLineOfficialAccountQueryVariables>(EdiLineOfficialAccountGetLineOfficialAccountDocument, options);
        }
export type EdiLineOfficialAccountGetLineOfficialAccountQueryHookResult = ReturnType<typeof useEdiLineOfficialAccountGetLineOfficialAccountQuery>;
export type EdiLineOfficialAccountGetLineOfficialAccountLazyQueryHookResult = ReturnType<typeof useEdiLineOfficialAccountGetLineOfficialAccountLazyQuery>;
export type EdiLineOfficialAccountGetLineOfficialAccountQueryResult = Apollo.QueryResult<EdiLineOfficialAccountGetLineOfficialAccountQuery, EdiLineOfficialAccountGetLineOfficialAccountQueryVariables>;
export const EdiLineOfficialAccountUpdateLineOfficialAccountDocument = gql`
    mutation EdiLineOfficialAccountUpdateLineOfficialAccount($input: UpdateLineOfficialAccountInput!) {
  updateLineOfficialAccount(input: $input)
}
    `;
export type EdiLineOfficialAccountUpdateLineOfficialAccountMutationFn = Apollo.MutationFunction<EdiLineOfficialAccountUpdateLineOfficialAccountMutation, EdiLineOfficialAccountUpdateLineOfficialAccountMutationVariables>;

/**
 * __useEdiLineOfficialAccountUpdateLineOfficialAccountMutation__
 *
 * To run a mutation, you first call `useEdiLineOfficialAccountUpdateLineOfficialAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEdiLineOfficialAccountUpdateLineOfficialAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ediLineOfficialAccountUpdateLineOfficialAccountMutation, { data, loading, error }] = useEdiLineOfficialAccountUpdateLineOfficialAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEdiLineOfficialAccountUpdateLineOfficialAccountMutation(baseOptions?: Apollo.MutationHookOptions<EdiLineOfficialAccountUpdateLineOfficialAccountMutation, EdiLineOfficialAccountUpdateLineOfficialAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EdiLineOfficialAccountUpdateLineOfficialAccountMutation, EdiLineOfficialAccountUpdateLineOfficialAccountMutationVariables>(EdiLineOfficialAccountUpdateLineOfficialAccountDocument, options);
      }
export type EdiLineOfficialAccountUpdateLineOfficialAccountMutationHookResult = ReturnType<typeof useEdiLineOfficialAccountUpdateLineOfficialAccountMutation>;
export type EdiLineOfficialAccountUpdateLineOfficialAccountMutationResult = Apollo.MutationResult<EdiLineOfficialAccountUpdateLineOfficialAccountMutation>;
export type EdiLineOfficialAccountUpdateLineOfficialAccountMutationOptions = Apollo.BaseMutationOptions<EdiLineOfficialAccountUpdateLineOfficialAccountMutation, EdiLineOfficialAccountUpdateLineOfficialAccountMutationVariables>;