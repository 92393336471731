import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";

import { EditShopPlansBulkSummary } from "../EditShopPlansBulkSummary";
import { BulkEditConditions, Plan, PlanChoice, Shop } from "../types";

type Props = {
  selectedPlanRowKeys: string[];
  selectedPlanChoiceRowKeys: string[];
  selectedRowKeys: string[];
  planRowKeyToPlanMap: Map<string, Plan>;
  planChoiceRowKeyToPlanChoiceMap: Map<string, PlanChoice>;
  shopIdToShopMap: Map<string, Shop>;
  bulkEditConditions: BulkEditConditions;
  loading: boolean;
  onSubmit: () => void;
  allShopIds: string[];
};

export const EditShopPlansBulkConfirmModal = memo<Props>(
  ({
    selectedPlanRowKeys,
    selectedPlanChoiceRowKeys,
    selectedRowKeys,
    planRowKeyToPlanMap,
    planChoiceRowKeyToPlanChoiceMap,
    shopIdToShopMap,
    bulkEditConditions,
    loading,
    onSubmit,
    allShopIds,
  }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => {
      navigate(`/shopPlan/edit/bulk`, { replace: true });
    }, [navigate]);
    return (
      <Modal
        title="変更内容確認"
        open
        width="100%"
        onCancel={goBack}
        footer={[
          <Button key="cancel" onClick={goBack}>
            キャンセル
          </Button>,
          <Button
            key="add"
            type="primary"
            loading={loading}
            onClick={onSubmit}
            disabled={selectedRowKeys.length === 0}
          >
            更新
          </Button>,
        ]}
      >
        <EditShopPlansBulkSummary
          selectedPlanRowKeys={selectedPlanRowKeys}
          selectedPlanChoiceRowKeys={selectedPlanChoiceRowKeys}
          planRowKeyToPlanMap={planRowKeyToPlanMap}
          planChoiceRowKeyToPlanChoiceMap={planChoiceRowKeyToPlanChoiceMap}
          shopIdToShopMap={shopIdToShopMap}
          bulkEditConditions={bulkEditConditions}
          allShopIds={allShopIds}
        />
      </Modal>
    );
  },
);
