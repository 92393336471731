import React, { memo, useCallback, useMemo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DishUpReceiptField } from "pages/EditRole/EditRoleForm/DishUpReceiptField";
import { FirstSpareRoleField } from "pages/EditRole/EditRoleForm/FirstSpareRoleField";
import { NameField } from "pages/EditRole/EditRoleForm/NameField";
import { PaymentReceiptField } from "pages/EditRole/EditRoleForm/PaymentReceiptField";
import { PrinterSoundField } from "pages/EditRole/EditRoleForm/PrinterSoundField";
import { SecondSpareRoleField } from "pages/EditRole/EditRoleForm/SecondSpareRoleField";
import { ShouldPrintFreeOrderOnDishUpSlipField } from "pages/EditRole/EditRoleForm/ShouldPrintFreeOrderOnDishUpSlipField";
import { EditRoleFormValues, useEditRoleForm } from "pages/EditRole/EditRoleForm/useEditRoleForm";
import { OnlinePaymentPrinterRoleMeta, Role, RolesRole } from "pages/EditRole/types";

import { ClerkCallSlipField } from "./ClerkCallSlipField";
import { OnlinePaymentSlipField } from "./OnlinePaymentSlipField";
import { PrintDishUpSlipAsPrimarySlipField } from "./PrintDishUpSlipAsPrimarySlipField";

type Props = {
  role: Role;
  onlinePaymentPrinterRoleMeta: OnlinePaymentPrinterRoleMeta | undefined;
  roles: RolesRole[];
  onSubmit: (role: EditRoleFormValues) => void;
  onClose: () => void;
  loading: boolean;
};

export const EditRoleForm = memo<Props>(
  ({ role, onlinePaymentPrinterRoleMeta, roles, onClose, onSubmit, loading }) => {
    const { form, initialValues, submit } = useEditRoleForm(
      role,
      onlinePaymentPrinterRoleMeta,
      onSubmit,
    );

    const { isFeatureEnabled } = useIsFeatureEnabled();

    const otherRoles = useMemo(
      () => roles.filter(({ roleId }) => roleId !== role.roleId),
      [roles, role],
    );

    const handleSubmit = useCallback(() => {
      submit();
    }, [submit]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="role" form={form} layout="vertical" initialValues={initialValues}>
            <NameField wrapperCol={{ span: 8 }} />
            <OnlinePaymentSlipField />
            <ClerkCallSlipField />
            <PaymentReceiptField />
            <DishUpReceiptField />
            <PrintDishUpSlipAsPrimarySlipField />
            <ShouldPrintFreeOrderOnDishUpSlipField />
            <PrinterSoundField />
            <FirstSpareRoleField roles={otherRoles} wrapperCol={{ span: 8 }} />
            <SecondSpareRoleField roles={otherRoles} wrapperCol={{ span: 8 }} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
