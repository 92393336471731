import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddServiceAdminFormItem } from "../useAddServiceAdminForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PasswordField = memo<Props>((props) => (
  <AddServiceAdminFormItem
    label="パスワード"
    name="password"
    rules={[{ required: true, message: "パスワードを入力してください" }]}
    {...props}
  >
    <Input type="password" />
  </AddServiceAdminFormItem>
));
