import React, { memo, Ref } from "react";
import styled from "styled-components";
import { Button, Input, InputRef } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  padding: 20px 24px;
  padding-bottom: 0;
  gap: 16px;
  align-items: center;
`;

const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 20px;
`;

type Props = {
  inputRef?: Ref<InputRef>;
  onChangeName: (name: string) => void;
  onClose?: () => void;
};

export const Navigation = memo<Props>(({ inputRef, onChangeName, onClose }) => (
  <Wrapper>
    <StyledInput
      ref={inputRef}
      placeholder="業態名で検索"
      prefix={<SearchOutlined />}
      onChange={(e) => onChangeName(e.target.value)}
      onClick={(e) => e.stopPropagation()}
    />
    {onClose && <Button icon={<CloseOutlined />} shape="circle" size="large" onClick={onClose} />}
  </Wrapper>
));
