import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Category } from "pages/AddMenu/types";

import { AddMenuFormItem } from "../useAddMenuForm";

const { Option } = Select;

type Props = {
  categories: Category[];
} & Omit<FormItemProps, "children" | "name">;

export const CategoryField = memo<Props>(({ categories, ...props }) => (
  <AddMenuFormItem
    label="カテゴリ"
    name="categoryIds"
    rules={[{ required: true, message: "カテゴリ名を入力してください" }]}
    {...props}
  >
    <Select<number>
      mode="multiple"
      placeholder="カテゴリを選択してください"
      optionFilterProp="children"
    >
      {categories.map(({ categoryId, name, shopSideName }) => (
        <Option key={categoryId} value={categoryId}>
          {shopSideName || name}
        </Option>
      ))}
    </Select>
  </AddMenuFormItem>
));
