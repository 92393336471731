import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { KdDisplayTarget, Role } from "pages/EditKitchenDisplayDisplayTarget/types";
import { KdDisplayTargetSetInput } from "types/graphql";

export type EditKitchenDisplayDisplayTargetFormValues = Pick<KdDisplayTarget, "name" | "roleId">;

const getInitialValues = (kdDisplayTarget: KdDisplayTarget) => {
  const { name, roleId } = kdDisplayTarget;
  return { name, roleId };
};

export const EditKitchenDisplayDisplayTargetFormItem =
  createFormItem<EditKitchenDisplayDisplayTargetFormValues>();

export const useEditKitchenDisplayDisplayTargetForm = ({
  roles,
  kdDisplayTarget,
  onSubmit,
}: {
  roles: Role[];
  kdDisplayTarget: KdDisplayTarget;
  onSubmit: (kdDisplayTarget: KdDisplayTargetSetInput) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(kdDisplayTarget);

  const submit = useCallback(() => {
    const { name, roleId } = form.getFieldsValue() as EditKitchenDisplayDisplayTargetFormValues;

    const role = roles.find((role) => role.roleId === roleId);

    onSubmit({
      name,
      roleId: role?.id,
      _roleId: role?.roleId,
    });
  }, [form, onSubmit, roles]);

  return { form, initialValues, submit };
};
