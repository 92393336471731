import React, { memo } from "react";
import { Typography } from "antd";

import { Form } from "components/antd/Form";
import { MenuRoleField } from "pages/EditShopMenuOutput/EditMenuRoleForm/MenuRoleField";
import { useEditMenuRoleForm } from "pages/EditShopMenuOutput/EditMenuRoleForm/useEditMenuRoleForm";
import { Menu, Role } from "pages/EditShopMenuOutput/types";

const { Title } = Typography;

type Props = {
  shopId: string;
  menu: Menu;
  menuRoles: Role[];
  roles: Role[];
};

export const EditMenuRoleForm = memo<Props>(({ shopId, menu, menuRoles, roles }) => {
  const {
    form,
    initialValues,
    change: onChange,
    loadings,
  } = useEditMenuRoleForm(shopId, menu, menuRoles, roles);

  return (
    <Form
      name="menuRoles"
      form={form}
      initialValues={initialValues}
      onValuesChange={onChange}
      layout="vertical"
    >
      <Title level={5}>キッチンプリンター</Title>
      {roles.map(({ id, name }) => (
        <MenuRoleField key={id} label={name} name={id} loading={loadings[id]} />
      ))}
    </Form>
  );
});
