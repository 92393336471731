import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditPlanWinboardPlanFormItem } from "../../useEditPlanWinboardPlanForm";

type Props = {
  optionField: FormListFieldData;
  loading?: boolean;
};

type FormListFieldDataSource = {
  planOptionField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
  planChoiceField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
};

export const PlanChoiceTable = memo<Props>(({ loading, optionField }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ plan }) => [
              plan?.planOptions.map((option) => {
                option.planChoices.map((choice) => choice.name);
              }),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue([
                "plan",
                "planOptions",
                field.planOptionField.name,
                "planChoices",
                field.planChoiceField.name,
                "name",
              ]);

              return <span>{choiceName}</span>;
            }}
          </EditPlanWinboardPlanFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "code",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "code"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "name"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "bumonCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "bumonCode"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "bumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "bumonName"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "categoryCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "categoryCode"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "categoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanWinboardPlanFormItem
            noStyle
            name={[field.planChoiceField.name, "winboardMenus", 0, "categoryName"]}
          >
            <InputCode />
          </EditPlanWinboardPlanFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={[optionField.name, "planChoices"]}>
      {(planChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={planChoiceFields.map((field) => ({
            planOptionField: optionField,
            planChoiceField: field,
          }))}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
