import React, { useCallback, useMemo } from "react";
import { Alert } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useShop } from "hooks/useShop";
import { EbicaTableTable } from "pages/EbicaTables/EbicaTableTable";
import {
  useEbicaTablesDeleteEbicaTableMutation,
  useEbicaTablesGetEbicaApiEbicaTablesQuery,
  useEbicaTablesGetEbicaTablesQuery,
  useEbicaTablesGetTablesQuery,
  useEbicaTablesInsertEbicaTableMutation,
} from "pages/EbicaTables/queries";

export const EbicaTables = () => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getEbicaTablesData,
    loading: loadingEbicaTables,
    error: getEbicaTablesError,
    refetch: refetchEbicaTables,
  } = useEbicaTablesGetEbicaTablesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const ebicaTables = getEbicaTablesData?.ebicaTable ?? [];

  const {
    data: getEbicaApiEbicaTablesData,
    loading: loadingEbicaApiEbicaTables,
    error: getEbicaApiEbicaTablesError,
    refetch: refetchEbicaApiEbicaTables,
  } = useEbicaTablesGetEbicaApiEbicaTablesQuery(
    shopId ? { variables: { input: { shopId } } } : { skip: true },
  );
  const ebicaApiEbicaTables = useMemo(
    () => getEbicaApiEbicaTablesData?.ebicaApiTables ?? [],
    [getEbicaApiEbicaTablesData?.ebicaApiTables],
  );

  const {
    data: getTablesData,
    loading: loadingTables,
    error: getTablesError,
    refetch: refetchTables,
  } = useEbicaTablesGetTablesQuery(shopId ? { variables: { shopId } } : { skip: true });
  const tables = getTablesData?.table ?? [];

  const [insertEbicaTable, { loading: loadingUpdateEbicaTable }] =
    useEbicaTablesInsertEbicaTableMutation();

  const [deleteEbicaTable, { loading: loadingDeleteEbicaTable }] =
    useEbicaTablesDeleteEbicaTableMutation();

  const updateDetailType = useCallback(
    async ({ ebicaTableId, tableId }: { ebicaTableId: number; tableId: string }) => {
      if (!shopId) return;

      try {
        await insertEbicaTable({
          variables: {
            shopId,
            ebicaTableId,
            ebicaTable: { shopId, ebicaTableId, tableId },
          },
        });

        refetchEbicaTables();
        refetchEbicaApiEbicaTables();
        refetchTables();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, insertEbicaTable, refetchEbicaTables, refetchEbicaApiEbicaTables, refetchTables],
  );

  const deleteDetailType = useCallback(
    async ({ ebicaTableId }: { ebicaTableId: number }) => {
      if (!shopId) return;

      try {
        await deleteEbicaTable({ variables: { shopId, ebicaTableId } });

        refetchEbicaTables();
        refetchEbicaApiEbicaTables();
        refetchTables();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [deleteEbicaTable, refetchEbicaApiEbicaTables, refetchEbicaTables, refetchTables, shopId],
  );

  const loading =
    loadingEbicaTables ||
    loadingTables ||
    loadingEbicaApiEbicaTables ||
    loadingUpdateEbicaTable ||
    loadingDeleteEbicaTable;

  const shouldShowError = getEbicaTablesError || getEbicaApiEbicaTablesError || getTablesError;

  return (
    <DashboardLayout title="テーブル連携設定">
      <PageHeader title="テーブル連携設定" footer={<ShopSelector />} />
      {!shop && <Paragraph>店舗を選択してください。</Paragraph>}
      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <EbicaTableTable
        ebicaApiEbicaTables={ebicaApiEbicaTables}
        tables={tables}
        ebicaTables={ebicaTables}
        loading={loading}
        onUpdate={updateDetailType}
        onDelete={deleteDetailType}
      />
    </DashboardLayout>
  );
};
