import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditMenuRecommendationFormItem } from "../useEditMenuRecommendationForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled: boolean };

export const NameField = memo<Props>((props) => (
  <EditMenuRecommendationFormItem
    label="表示名"
    name="name"
    rules={[{ required: true, message: "表示名を入力してください" }]}
    {...props}
  >
    <Input disabled={props.disabled} />
  </EditMenuRecommendationFormItem>
));
