import React, { memo } from "react";
import styled from "styled-components";
import { Tag } from "antd";

import { grey, oldColors } from "constants/colors";

type Props = {
  quotaLimit: number | undefined;
  isInvalidAccount: boolean;
};

const BaseTag = styled(Tag)`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${grey[10]};
  background-color: ${grey[0]};
  border-color: #d6d6d6;
`;

const MiddleTag = styled(BaseTag)`
  color: ${oldColors.Orange};
  border-color: ${oldColors.Orange};
`;

export const QuotaLimitTag = memo<Props>(({ quotaLimit, isInvalidAccount }) => {
  if (isInvalidAccount) {
    return <BaseTag color={oldColors.Orange}>無効</BaseTag>;
  }

  if (quotaLimit === undefined) {
    return <BaseTag color={oldColors.Orange}>上限未設定</BaseTag>;
  }

  if (quotaLimit >= 45000) {
    return <BaseTag color={oldColors.Orange}>{quotaLimit.toLocaleString()}通</BaseTag>;
  }

  if (quotaLimit >= 15000) {
    return <MiddleTag>{quotaLimit.toLocaleString()}通</MiddleTag>;
  }

  return <BaseTag>{quotaLimit.toLocaleString()}通</BaseTag>;
});
