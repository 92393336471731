import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DishUpSlipGroupPlansGetPlans = gql`
    query DishUpSlipGroupPlansGetPlans($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    id: serial
    dishUpSlipGroupId
    name
    dishUpSlipGroupShopPlans(
      where: {shopPlan: {archivedAt: {_is_null: true}}, plan: {category: {archivedAt: {_is_null: true}}, archivedAt: {_is_null: true}}}
    ) {
      planId
      _planId
      dishUpSlipGroupId
      plan {
        planName
        planId
        planGroupId: _planGroupId
        category {
          categoryId
          name
        }
      }
    }
    shop {
      companyId
      shopId
    }
  }
}
    `;
export const DishUpSlipGroupDeleteDishUpSlipGroupPlansPlan = gql`
    mutation DishUpSlipGroupDeleteDishUpSlipGroupPlansPlan($planId: Int!, $dishUpSlipGroupId: uuid!) {
  delete_dishUpSlipGroupShopPlans(
    where: {_planId: {_eq: $planId}, dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      planId: _planId
    }
  }
}
    `;
export type DishUpSlipGroupPlansGetPlansQueryVariables = Types.Exact<{
  dishUpSlipGroupId: Types.Scalars['Int'];
}>;


export type DishUpSlipGroupPlansGetPlansQuery = (
  { __typename?: 'query_root' }
  & { dishUpSlipGroup: Array<(
    { __typename?: 'dishUpSlipGroup' }
    & Pick<Types.DishUpSlipGroup, 'dishUpSlipGroupId' | 'name'>
    & { id: Types.DishUpSlipGroup['serial'] }
    & { dishUpSlipGroupShopPlans: Array<(
      { __typename?: 'dishUpSlipGroupShopPlans' }
      & Pick<Types.DishUpSlipGroupShopPlans, 'planId' | '_planId' | 'dishUpSlipGroupId'>
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'planName' | 'planId'>
        & { planGroupId: Types.Plan['_planGroupId'] }
        & { category: (
          { __typename?: 'category' }
          & Pick<Types.Category, 'categoryId' | 'name'>
        ) }
      ) }
    )>, shop: (
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'companyId' | 'shopId'>
    ) }
  )> }
);

export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  dishUpSlipGroupId: Types.Scalars['uuid'];
}>;


export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dishUpSlipGroupShopPlans?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupShopPlans_mutation_response' }
    & Pick<Types.DishUpSlipGroupShopPlansMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dishUpSlipGroupShopPlans' }
      & Pick<Types.DishUpSlipGroupShopPlans, 'dishUpSlipGroupId'>
      & { planId: Types.DishUpSlipGroupShopPlans['_planId'] }
    )> }
  )> }
);


export const DishUpSlipGroupPlansGetPlansDocument = gql`
    query DishUpSlipGroupPlansGetPlans($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    id: serial
    dishUpSlipGroupId
    name
    dishUpSlipGroupShopPlans(
      where: {shopPlan: {archivedAt: {_is_null: true}}, plan: {category: {archivedAt: {_is_null: true}}, archivedAt: {_is_null: true}}}
    ) {
      planId
      _planId
      dishUpSlipGroupId
      plan {
        planName
        planId
        planGroupId: _planGroupId
        category {
          categoryId
          name
        }
      }
    }
    shop {
      companyId
      shopId
    }
  }
}
    `;

/**
 * __useDishUpSlipGroupPlansGetPlansQuery__
 *
 * To run a query within a React component, call `useDishUpSlipGroupPlansGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupPlansGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishUpSlipGroupPlansGetPlansQuery({
 *   variables: {
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *   },
 * });
 */
export function useDishUpSlipGroupPlansGetPlansQuery(baseOptions: Apollo.QueryHookOptions<DishUpSlipGroupPlansGetPlansQuery, DishUpSlipGroupPlansGetPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DishUpSlipGroupPlansGetPlansQuery, DishUpSlipGroupPlansGetPlansQueryVariables>(DishUpSlipGroupPlansGetPlansDocument, options);
      }
export function useDishUpSlipGroupPlansGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DishUpSlipGroupPlansGetPlansQuery, DishUpSlipGroupPlansGetPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DishUpSlipGroupPlansGetPlansQuery, DishUpSlipGroupPlansGetPlansQueryVariables>(DishUpSlipGroupPlansGetPlansDocument, options);
        }
export type DishUpSlipGroupPlansGetPlansQueryHookResult = ReturnType<typeof useDishUpSlipGroupPlansGetPlansQuery>;
export type DishUpSlipGroupPlansGetPlansLazyQueryHookResult = ReturnType<typeof useDishUpSlipGroupPlansGetPlansLazyQuery>;
export type DishUpSlipGroupPlansGetPlansQueryResult = Apollo.QueryResult<DishUpSlipGroupPlansGetPlansQuery, DishUpSlipGroupPlansGetPlansQueryVariables>;
export const DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanDocument = gql`
    mutation DishUpSlipGroupDeleteDishUpSlipGroupPlansPlan($planId: Int!, $dishUpSlipGroupId: uuid!) {
  delete_dishUpSlipGroupShopPlans(
    where: {_planId: {_eq: $planId}, dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      planId: _planId
    }
  }
}
    `;
export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationFn = Apollo.MutationFunction<DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation, DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationVariables>;

/**
 * __useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation__
 *
 * To run a mutation, you first call `useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation, { data, loading, error }] = useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *   },
 * });
 */
export function useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation(baseOptions?: Apollo.MutationHookOptions<DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation, DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation, DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationVariables>(DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanDocument, options);
      }
export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationHookResult = ReturnType<typeof useDishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation>;
export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationResult = Apollo.MutationResult<DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation>;
export type DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationOptions = Apollo.BaseMutationOptions<DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutation, DishUpSlipGroupDeleteDishUpSlipGroupPlansPlanMutationVariables>;