import React, { memo, useMemo } from "react";
import { uniqBy } from "lodash";
import { filterMenusByName } from "models/menu";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";
import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";

import { Menu } from "../../types";

import { FilterConditions, MenuFilter } from "./MenuFilter";
import { MenuTable } from "./MenuTable";

type Props = {
  menus: Menu[];
};

export const filterMenus = (menus: Menu[], { name, categoryIds }: FilterConditions) => {
  const filteredMenus = menus.filter(
    (menu) =>
      categoryIds === undefined ||
      menu.categoryMenus
        .flatMap(({ category }) => category)
        .some((category) => categoryIds.includes(category.categoryId)),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const SelectAppetizerMenu = memo<Props>(({ menus }) => {
  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const categories = useMemo(
    () =>
      uniqBy(
        menus.flatMap(({ categoryMenus }) => categoryMenus.map(({ category }) => category)),
        ({ categoryId }) => categoryId,
      ),
    [menus],
  );

  return (
    <>
      <MenuFilter
        categories={categories}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
        clearFilterConditions={clearFilterConditions}
      />
      <Spacer size={20} />

      <FormItem name="menuId">
        <MenuTable menus={filteredMenus} />
      </FormItem>
    </>
  );
});
