import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanWinboardPlanFormItem } from "../useEditPlanWinboardPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const BumonCodeField = memo<Props>((props) => (
  <EditPlanWinboardPlanFormItem label="部門コード" name="bumonCode" {...props}>
    <InputCode />
  </EditPlanWinboardPlanFormItem>
));
