import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EnableCallClerkField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label={
      <FormHelp
        label="スタッフ呼び出し"
        help="スタッフ呼び出しを有効にすると来店客がアプリからスタッフ呼び出しを行えるようになります"
      />
    }
    name="enableCallClerk"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
