import { useMemo } from "react";
import dayjs from "dayjs";
import { EventValue } from "rc-picker/lib/interface";

const getRangePresets: (
  now: dayjs.Dayjs,
) => Record<string, [EventValue<dayjs.Dayjs>, EventValue<dayjs.Dayjs>]> = (now) => ({
  昨日: [now.subtract(1, "days").startOf("day"), now.subtract(1, "days").endOf("day")],
  直近7日間: [now.subtract(7, "days").startOf("day"), now.endOf("day")],
  直近30日間: [now.subtract(30, "days").startOf("day"), now.endOf("day")],
  先月: [
    now.subtract(1, "months").startOf("month").startOf("day"),
    now.subtract(1, "months").endOf("month").endOf("day"),
  ],
  今月: [now.startOf("month").startOf("day"), now.endOf("month").endOf("day")],
});

export const useRangePresets = () => {
  const hourText = dayjs().format("MM-DD-HH");

  const rangePresets = useMemo(() => {
    const now = dayjs();
    // hourText を dependencies に明示的に追加するため、意味のない if 文を通す
    if (!hourText) {
      return {};
    }
    return getRangePresets(now);
  }, [hourText]);

  return { rangePresets };
};
