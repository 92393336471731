import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Plan } from "pages/EditPlan/types";
import { PlanSetInput } from "types/graphql";

export type EditPlanFormValues = Pick<
  Plan,
  | "description"
  | "displayType"
  | "featuredLabelText"
  | "imageUrl"
  | "planGroupId"
  | "planName"
  | "planTime"
  | "endNotificationThresholdMinutes"
  | "receiptDisplayName"
  | "shopSideName"
  | "taxMethod"
  | "taxRate"
  | "orderableTimeId"
> & {
  categoryId: number;
};

const getInitialValues = (plan: Plan) => {
  const {
    category,
    description,
    displayType,
    featuredLabelText,
    imageUrl,
    planGroupId,
    planName,
    planTime,
    endNotificationThresholdMinutes,
    receiptDisplayName,
    shopSideName,
    taxMethod,
    taxRate,
    orderableTimeId,
  } = plan;

  const categoryId = category.categoryId;

  return {
    categoryId,
    description,
    displayType,
    featuredLabelText,
    imageUrl: imageUrl ?? null,
    planGroupId,
    planName,
    planTime,
    endNotificationThresholdMinutes,
    receiptDisplayName,
    shopSideName,
    taxMethod,
    taxRate,
    orderableTimeId,
  };
};

export const EditPlanFormItem = createFormItem<EditPlanFormValues>();

export const useEditPlanForm = (plan: Plan, onSubmit: (plan: PlanSetInput) => void) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(plan);

  const submit = useCallback(
    ({
      imageUrl,
      hasPlanTimeLimit,
      hasEndNotificationTime,
    }: {
      imageUrl: string | null | undefined;
      hasPlanTimeLimit: boolean;
      hasEndNotificationTime: boolean;
    }) => {
      const formValues = form.getFieldsValue() as EditPlanFormValues;
      const { categoryId: planCategoryId, planGroupId, ...values } = formValues;
      const planTime = hasPlanTimeLimit ? values.planTime : null;
      const endNotificationThresholdMinutes = hasEndNotificationTime
        ? values.endNotificationThresholdMinutes
        : null;
      const taxRate = values.taxMethod !== "nonTaxable" ? values.taxRate : 0;

      onSubmit({
        ...values,
        _planGroupId: planGroupId,
        // NOTE: 伝票表示名が未入力時はプラン名を入れる
        receiptDisplayName: formValues.receiptDisplayName || values.planName,
        // NOTE: ハンディ表示名が未入力時はプラン名を入れる
        shopSideName: formValues.shopSideName || values.planName,
        planCategoryId,
        imageUrl,
        planTime,
        endNotificationThresholdMinutes,
        taxRate,
      });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
