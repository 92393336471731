import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopBusinessOperationHourGetShopAndShopBusinessOperationHour = gql`
    query EditShopBusinessOperationHourGetShopAndShopBusinessOperationHour($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    changeDateTime
  }
  shopBusinessOperationHour(
    where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    order_by: {start: asc}
  ) {
    id
    shopId
    businessOperationHourType
    start
    end
  }
}
    `;
export const EditShopBusinessOperationHourUpdateShopBusinessOperationHour = gql`
    mutation EditShopBusinessOperationHourUpdateShopBusinessOperationHour($shopId: uuid!, $shopBusinessOperationHours: [shopBusinessOperationHour_insert_input!]!, $deletedIds: [uuid!]!) {
  delete_shopBusinessOperationHour(
    where: {id: {_in: $deletedIds}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopBusinessOperationHour(
    objects: $shopBusinessOperationHours
    on_conflict: {constraint: shopBusinessOperationHour_shopId_businessOperationHourType_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'changeDateTime'>
  )>, shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'id' | 'shopId' | 'businessOperationHourType' | 'start' | 'end'>
  )> }
);

export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  shopBusinessOperationHours: Array<Types.ShopBusinessOperationHourInsertInput> | Types.ShopBusinessOperationHourInsertInput;
  deletedIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopBusinessOperationHour?: Types.Maybe<(
    { __typename?: 'shopBusinessOperationHour_mutation_response' }
    & Pick<Types.ShopBusinessOperationHourMutationResponse, 'affected_rows'>
  )>, insert_shopBusinessOperationHour?: Types.Maybe<(
    { __typename?: 'shopBusinessOperationHour_mutation_response' }
    & Pick<Types.ShopBusinessOperationHourMutationResponse, 'affected_rows'>
  )> }
);


export const EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourDocument = gql`
    query EditShopBusinessOperationHourGetShopAndShopBusinessOperationHour($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    changeDateTime
  }
  shopBusinessOperationHour(
    where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    order_by: {start: asc}
  ) {
    id
    shopId
    businessOperationHourType
    start
    end
  }
}
    `;

/**
 * __useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery__
 *
 * To run a query within a React component, call `useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery(baseOptions: Apollo.QueryHookOptions<EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery, EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery, EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables>(EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourDocument, options);
      }
export function useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery, EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery, EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables>(EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourDocument, options);
        }
export type EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryHookResult = ReturnType<typeof useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery>;
export type EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourLazyQueryHookResult = ReturnType<typeof useEditShopBusinessOperationHourGetShopAndShopBusinessOperationHourLazyQuery>;
export type EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryResult = Apollo.QueryResult<EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQuery, EditShopBusinessOperationHourGetShopAndShopBusinessOperationHourQueryVariables>;
export const EditShopBusinessOperationHourUpdateShopBusinessOperationHourDocument = gql`
    mutation EditShopBusinessOperationHourUpdateShopBusinessOperationHour($shopId: uuid!, $shopBusinessOperationHours: [shopBusinessOperationHour_insert_input!]!, $deletedIds: [uuid!]!) {
  delete_shopBusinessOperationHour(
    where: {id: {_in: $deletedIds}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopBusinessOperationHour(
    objects: $shopBusinessOperationHours
    on_conflict: {constraint: shopBusinessOperationHour_shopId_businessOperationHourType_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationFn = Apollo.MutationFunction<EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation, EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationVariables>;

/**
 * __useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation__
 *
 * To run a mutation, you first call `useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopBusinessOperationHourUpdateShopBusinessOperationHourMutation, { data, loading, error }] = useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      shopBusinessOperationHours: // value for 'shopBusinessOperationHours'
 *      deletedIds: // value for 'deletedIds'
 *   },
 * });
 */
export function useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation(baseOptions?: Apollo.MutationHookOptions<EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation, EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation, EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationVariables>(EditShopBusinessOperationHourUpdateShopBusinessOperationHourDocument, options);
      }
export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationHookResult = ReturnType<typeof useEditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation>;
export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationResult = Apollo.MutationResult<EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation>;
export type EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationOptions = Apollo.BaseMutationOptions<EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutation, EditShopBusinessOperationHourUpdateShopBusinessOperationHourMutationVariables>;