import { useCallback } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";
import { LocaleValuePair } from "components/TranslationsFields/types";
import { OptionInputTypeEnum, PlanOption } from "types/graphql";

export type AddPlanOptionFormValues = Pick<
  PlanOption,
  | "description"
  | "inputType"
  | "isMainOption"
  | "maxChoiceNum"
  | "minChoiceNum"
  | "name"
  | "receiptDisplayName"
> & {
  nameSources: LocaleValuePair[];
};

const getInitialValues = () => ({
  inputType: OptionInputTypeEnum.Count,
  hasStock: true,
  isDisplay: true,
  roles: [],
});

export const AddPlanOptionFormItem = createFormItem<AddPlanOptionFormValues>();

export const useAddPlanOptionForm = (onChange: (option: AddPlanOptionFormValues) => void) => {
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as AddPlanOptionFormValues;
      onChange({ ...values });
    },
    [onChange],
  );

  return { initialValues, change };
};
