import React, { memo, useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import * as Sentry from "@sentry/react";

import Notify from "assets/notify.svg";
import { colors } from "constants/colors";

const Wrapper = styled.div`
  background: ${colors.BackGround.Tertiary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

// エラー時のフォールバック用のコンポーネント
export const ErrorFallback = memo<FallbackProps>(({ error, resetErrorBoundary }) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Wrapper>
      <Notify width={480} height={480} />
      <p>予期しないエラーが発生しました</p>

      <Link to="/">
        <StyledButton onClick={resetErrorBoundary} type="primary">
          トップページに戻る
        </StyledButton>
      </Link>

      <Link to="/signOut">
        <Button onClick={resetErrorBoundary}>ログアウト</Button>
      </Link>
    </Wrapper>
  );
});
