import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Tag } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { difference } from "lodash";
import { uniq } from "util/array";

import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";

import { BulkEditConditions, Plan, RowItem, RowItemPlan, RowItemPlanChoice, Shop } from "../types";

import { BulkUpdateBanner } from "./BulkUpdateBanner";

const isRowItemPlan = (rowItem: RowItem): rowItem is RowItemPlan => rowItem.type === "plan";
const isRowItemPlanChoice = (rowItem: RowItem): rowItem is RowItemPlanChoice =>
  rowItem.type === "planChoice";

const DEFAULT_CURRENT = 1;
const DEFAULT_PAGE_SIZE = 10;

type Props = {
  plans: Plan[];
  shops: Shop[];
  shopIdToShopMap: Map<string, Shop>;
  bulkEditConditions: BulkEditConditions;
  setBulkEditConditions: (bulkEditConditions: BulkEditConditions) => void;
  selectedPlanRowKeys: string[];
  setSelectedPlanRowKeys: (rowKeys: string[]) => void;
  selectedPlanChoiceRowKeys: string[];
  setSelectedPlanChoiceRowKeys: (rowKeys: string[]) => void;
  selectedRowKeys: string[];
  clearBulkEditConditions: () => void;
};

export const EditShopPlansBulkFormTable = memo<Props>(
  ({
    plans,
    shops,
    shopIdToShopMap,
    bulkEditConditions,
    setBulkEditConditions,
    selectedPlanRowKeys,
    setSelectedPlanRowKeys,
    selectedPlanChoiceRowKeys,
    setSelectedPlanChoiceRowKeys,
    selectedRowKeys,
    clearBulkEditConditions,
  }) => {
    const columns = useMemo(
      () => [
        {
          title: "",
          width: 40,
        } as const,
        {
          title: "画像",
          align: "center",
          width: 100,
          render(_: string, rowItem: RowItem) {
            if (rowItem.type !== "plan") return null;
            return <Thumbnail url={rowItem.imageUrl} width={64} height={64} />;
          },
        } as const,
        {
          title: "プラン名",
          width: 240,
          render(_: string, rowItem: RowItem) {
            switch (rowItem.type) {
              case "plan": {
                const { planId, planName } = rowItem;
                return <Link to={`/plan/${planId}/edit`}>{planName}</Link>;
              }
              case "planChoice": {
                const { _planId, planOptionId, planChoiceId, name } = rowItem;
                return (
                  <Link to={`/plan/${_planId}/option/${planOptionId}/choice/${planChoiceId}/edit`}>
                    {name}
                  </Link>
                );
              }
              default:
                return null;
            }
          },
        },
        {
          title: "取扱店舗",
          width: 400,
          render(_: string, rowItem: RowItem) {
            switch (rowItem.type) {
              case "plan": {
                return rowItem.shopPlans.map(({ shopId }) => {
                  const shop = shopIdToShopMap.get(shopId);
                  return shop ? (
                    <Tag key={shopId} color="processing">
                      {shop.name}
                    </Tag>
                  ) : null;
                });
              }
              case "planChoice": {
                return rowItem.shopPlanChoices.map(({ shopId }) => {
                  const shop = shopIdToShopMap.get(shopId);
                  return shop ? (
                    <Tag key={shopId} color="processing">
                      {shop.name}
                    </Tag>
                  ) : null;
                });
              }
              default:
                return null;
            }
          },
        },
      ],
      [shopIdToShopMap],
    );

    const rows = useMemo<RowItem[]>(
      () =>
        plans.map((plan) => ({
          ...plan,
          rowKey: plan.id,
          type: "plan",
          children:
            plan.planOptions.length > 0
              ? plan.planOptions.flatMap((planOption) =>
                  planOption.planChoices.map((planChoice) => ({
                    ...planChoice,
                    _planId: plan.planId,
                    rowKey: `${plan.id}-${planChoice.id}`,
                    type: "planChoice",
                    planOptionId: planOption.planOptionId,
                    name: `${planOption.name} ${planChoice.name}`,
                  })),
                )
              : null,
        })),
      [plans],
    );

    const [defaultPagination, setPagination] = usePagination();
    const pagination = useMemo(
      () => ({
        ...defaultPagination,
        defaultCurrent: defaultPagination.defaultCurrent ?? DEFAULT_CURRENT,
        defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
      }),
      [defaultPagination],
    );

    const currentPagePlanRowKeys = useMemo(() => {
      const startIndex = (pagination.defaultCurrent - 1) * pagination.defaultPageSize;
      const endIndex = pagination.defaultCurrent * pagination.defaultPageSize;

      return plans.slice(startIndex, endIndex).map((plan) => plan.id);
    }, [pagination, plans]);

    const currentPageSelectedPlanRowKeys = useMemo(
      () => selectedPlanRowKeys.filter((rowKey) => currentPagePlanRowKeys.includes(rowKey)),
      [selectedPlanRowKeys, currentPagePlanRowKeys],
    );

    const currentPagePlanChoiceRowKeys = useMemo(
      () =>
        plans
          .filter((plan) => plan.planOptions.length !== 0)
          .filter((plan) => currentPagePlanRowKeys.includes(plan.id))
          .flatMap((plan) =>
            plan.planOptions.flatMap(({ planChoices }) =>
              planChoices.map((planChoice) => `${plan.id}-${planChoice.id}`),
            ),
          ),
      [plans, currentPagePlanRowKeys],
    );

    const currentPageSelectedPlanChoiceRowKeys = useMemo(
      () =>
        selectedPlanChoiceRowKeys.filter((rowKey) => currentPagePlanChoiceRowKeys.includes(rowKey)),
      [selectedPlanChoiceRowKeys, currentPagePlanChoiceRowKeys],
    );

    const currentPageSelectedRowKeys = useMemo(
      () => [...currentPageSelectedPlanRowKeys, ...currentPageSelectedPlanChoiceRowKeys],
      [currentPageSelectedPlanRowKeys, currentPageSelectedPlanChoiceRowKeys],
    );

    const isSelectedAllRows =
      plans.length !== 0 && currentPageSelectedPlanRowKeys.length === currentPagePlanRowKeys.length;

    const onAllCheckboxClick = useCallback(() => {
      if (isSelectedAllRows) {
        setSelectedPlanRowKeys(difference(selectedPlanRowKeys, currentPageSelectedRowKeys));
        setSelectedPlanChoiceRowKeys(
          difference(selectedPlanChoiceRowKeys, currentPageSelectedPlanChoiceRowKeys),
        );
        return;
      }

      setSelectedPlanRowKeys(uniq([...selectedPlanRowKeys, ...currentPagePlanRowKeys]));
    }, [
      isSelectedAllRows,
      setSelectedPlanRowKeys,
      setSelectedPlanChoiceRowKeys,
      selectedPlanRowKeys,
      selectedPlanChoiceRowKeys,
      currentPageSelectedRowKeys,
      currentPagePlanRowKeys,
      currentPageSelectedPlanChoiceRowKeys,
    ]);

    const allCheckboxProps = useMemo(
      () => ({
        indeterminate: currentPageSelectedRowKeys.length !== 0 && !isSelectedAllRows,
        checked: isSelectedAllRows,
        onClick: onAllCheckboxClick,
      }),
      [currentPageSelectedRowKeys, isSelectedAllRows, onAllCheckboxClick],
    );

    const rowSelection: TableRowSelection<RowItem> = useMemo(
      () => ({
        columnTitle: <Checkbox {...allCheckboxProps} />,
        selectedRowKeys,
        onChange: (_, rows) => {
          setSelectedPlanRowKeys(rows.filter(isRowItemPlan).map(({ rowKey }) => rowKey));
          setSelectedPlanChoiceRowKeys(
            rows.filter(isRowItemPlanChoice).map(({ rowKey }) => rowKey),
          );
        },
        columnWidth: 48,
      }),
      [selectedRowKeys, allCheckboxProps, setSelectedPlanRowKeys, setSelectedPlanChoiceRowKeys],
    );

    return (
      <>
        {selectedRowKeys.length !== 0 && (
          <BulkUpdateBanner
            onCancel={clearBulkEditConditions}
            selectedCount={selectedRowKeys.length}
            bulkEditConditions={bulkEditConditions}
            setBulkEditConditions={setBulkEditConditions}
            shops={shops}
          />
        )}
        <Table<RowItem>
          rowKey={({ rowKey }) => rowKey}
          rowSelection={rowSelection}
          dataSource={rows}
          columns={columns}
          pagination={pagination}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        />
      </>
    );
  },
);
