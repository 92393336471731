import React, { memo } from "react";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";

import { placeholder } from "../placeholder";
import { AddCouponFormItem } from "../useAddCouponForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TermsOfUseField = memo<Props>((props) => (
  <AddCouponFormItem
    label="利用規約"
    name="termsOfUse"
    rules={[{ required: true, message: "利用規約を入力してください" }]}
    {...props}
  >
    <TextArea rows={4} placeholder={placeholder.termsOfUse} />
  </AddCouponFormItem>
));
