import React from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useServiceAdminsGetServiceAdminsQuery } from "pages/ServiceAdmins/queries";
import { ServiceAdminTable } from "pages/ServiceAdmins/ServiceAdminTable";

export const ServiceAdmins = () => {
  const {
    data: getServiceAdminsData,
    loading: loadingServiceAdmins,
    error,
  } = useServiceAdminsGetServiceAdminsQuery();
  const serviceAdmins = getServiceAdminsData?.serviceAdmin ?? [];

  return (
    <DashboardLayout title="サービス管理者一覧">
      <PageHeader
        title="サービス管理者一覧"
        extra={[
          <Link key="add" to="/serviceAdmin/add">
            <Button type="primary">新規作成</Button>
          </Link>,
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ServiceAdminTable serviceAdmins={serviceAdmins} loading={loadingServiceAdmins} />
    </DashboardLayout>
  );
};
