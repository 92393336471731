import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Card, Collapse, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { PageHeader } from "@ant-design/pro-layout";

import { message } from "components/antd/message";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { useCompany } from "hooks/useCompany";

import MobileOrderDesignDark from "../../assets/mobile_order_design_dark.png";
import MobileOrderDesignLight from "../../assets/mobile_order_design_light.png";

import {
  MobileOrderDesignSettingsGetDesignSettingsDocument,
  useMobileOrderDesignSettingsGetDesignSettingsQuery,
  useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation,
} from "./queries";

const LayoutWrapper = styled.div`
  display: flex;
`;

const Form = styled.form`
  flex: 1;
`;

const Required = styled.span`
  color: red;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const StyledRadio = styled(Radio)`
  line-height: 22px;
`;

const PreviewWrapper = styled.div`
  width: 300px;
`;

const StyledCollapse = styled(Collapse)`
  background-color: ${colors.Other.Header};
  border-radius: 0px;

  .ant-collapse-item {
    .ant-collapse-header {
      color: ${colors.Text.OnColor};
    }

    .ant-collapse-content {
      border-radius: 0px !important;

      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
`;

const PreviewImage = styled.img`
  width: 100%;
`;

export const MobileOrderDesignSettings = () => {
  const [company] = useCompany();

  const { data, loading: getDesignSettingLoading } =
    useMobileOrderDesignSettingsGetDesignSettingsQuery({
      ...(company?.id ? { variables: { companyId: company.id } } : { skip: true }),
    });
  const currentCompanySetting = data?.company?.[0];

  const [enableDarkTheme, setEnableDarkTheme] = useState<boolean | null>(null);

  const currentDisplayValues = useMemo(
    () => ({
      enableDarkTheme:
        enableDarkTheme === null ? currentCompanySetting?.enableDarkTheme : enableDarkTheme,
    }),
    [enableDarkTheme, currentCompanySetting?.enableDarkTheme],
  );

  const isValueUpdated =
    enableDarkTheme !== null && enableDarkTheme !== currentCompanySetting?.enableDarkTheme;

  const collapseItems = useMemo(
    () => [
      {
        key: "preview",
        label: "プレビュー",
        style: { borderRadius: 0 },
        children: (
          <PreviewImage
            src={
              currentDisplayValues.enableDarkTheme ? MobileOrderDesignDark : MobileOrderDesignLight
            }
            alt="モバイルオーダーテーマプレビュー画像"
          />
        ),
      },
    ],
    [currentDisplayValues.enableDarkTheme],
  );

  const onChangeDarkTheme = useCallback(
    (e: RadioChangeEvent) => {
      setEnableDarkTheme(e.target.value === "dark");
    },
    [setEnableDarkTheme],
  );

  const [updateEnableDarkTheme, { loading: updating }] =
    useMobileOrderDesignSettingsUpdateEnableDarkThemeMutation({
      awaitRefetchQueries: true,
      refetchQueries: [MobileOrderDesignSettingsGetDesignSettingsDocument],
    });

  const loading = getDesignSettingLoading || updating;

  const handleReset = useCallback(() => {
    setEnableDarkTheme(currentCompanySetting?.enableDarkTheme ?? false);
  }, [currentCompanySetting?.enableDarkTheme]);

  const handleSubmit = useCallback(async () => {
    if (!company?.id || enableDarkTheme === null) return;

    const didSucceed = await updateEnableDarkTheme({
      variables: {
        companyId: company?.id,
        enableDarkTheme,
      },
    });

    if (didSucceed) {
      message.success("更新が成功しました");
    } else {
      message.error("更新が失敗しました");
    }
  }, [company?.id, enableDarkTheme, updateEnableDarkTheme]);

  return (
    <DashboardLayout title="モバイルオーダーデザイン設定">
      <PageHeader title="モバイルオーダーデザイン設定" />
      <Spacer size={16} />
      <LayoutWrapper>
        <Form>
          <Card loading={loading}>
            <Required>*</Required>
            <Spacer inline size={4} />
            <label htmlFor="enableDarkTheme">背景色</label>

            <Spacer size={5} />

            <StyledRadioGroup
              name="enableDarkTheme"
              onChange={onChangeDarkTheme}
              defaultValue={currentDisplayValues.enableDarkTheme ? "dark" : "light"}
            >
              <StyledRadio value="dark">ダーク</StyledRadio>
              <Spacer size={5} />
              <StyledRadio value="light">ライト</StyledRadio>
            </StyledRadioGroup>
          </Card>
          <Spacer size={10} />
          <FormActions>
            <Button onClick={handleReset} disabled={!isValueUpdated}>
              キャンセル
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!isValueUpdated}
            >
              更新
            </Button>
          </FormActions>
        </Form>
        <Spacer size={32} />

        <PreviewWrapper>
          <StyledCollapse defaultActiveKey={["preview"]} items={collapseItems} />
        </PreviewWrapper>
      </LayoutWrapper>
    </DashboardLayout>
  );
};
