import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const ReceiptDisplayNameField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanFormItem
    label={
      <FormHelp
        label="伝票表示名"
        help="スタッフが見るキッチン伝票等での表示名。未入力時はプラン名が表示されます"
      />
    }
    name="receiptDisplayName"
    {...props}
  >
    <Input disabled={disabled} />
  </EditPlanFormItem>
));
