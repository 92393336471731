import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Col, Popconfirm, Row, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { CustomerSummary } from "./CustomerSummary";
import { OnSitePaymentBreakdownByDetailType } from "./OnSitePaymentBreakdownByDetailType";
import { OnSitePaymentBreakdownByDiscountType } from "./OnSitePaymentBreakdownByDiscountType";
import { OnSitePaymentDealingDetail } from "./OnSitePaymentDealingDetail";
import { OnSitePaymentPayingItemTable } from "./OnSitePaymentPayingItemTable";
import { OnSitePaymentSummary } from "./OnSitePaymentSummary";
import {
  useAccountingDetailCancelOnSitePaymentMutation,
  useAccountingDetailGetOnSitePaymentQuery,
} from "./queries";
import { useUpdateOnSitePayment } from "./useUpdateOnSitePayment";

const { Paragraph } = Typography;

const RowSectionWrapper = styled(Row)`
  margin-bottom: 32px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const StyledParagraph = styled(Paragraph)`
  white-space: pre-wrap;
`;

export const AccountingDetail = () => {
  const { id } = useParams<{ id: string }>();
  const onSitePaymentId = Number(id);

  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { data: onSitePaymentData, refetch: refetchOnSitePayment } =
    useAccountingDetailGetOnSitePaymentQuery(
      onSitePaymentId ? { variables: { onSitePaymentId } } : { skip: true },
    );

  const onSitePayment = onSitePaymentData?.onSitePayment?.[0];
  const shopId = onSitePayment?.shop.shopId;
  const date = useMemo(
    () => (onSitePayment?.createdAt ? dayjs(onSitePayment?.createdAt).format("YYYY-MM-DD") : null),
    [onSitePayment],
  );

  const navigate = useNavigate();

  const {
    updateOnSitePaymentDetail,
    updateOnSitePaymentDiscount,
    updatePayingActivePlanChoice,
    updatePayingActivePlanOpenPriceMeta,
    updatePayingMenuOrderItem,
    loading,
    updateOnSitePayment,
    validationErrorMessage,
  } = useUpdateOnSitePayment({ onSitePayment, shopId, date });

  const clerkName = onSitePayment?.onSitePaymentDetails
    // NOTE: オンライン決済の場合のみ onSitePaymentDetail.clerkId と onSitePaymentDiscount.clerkId が null になる
    .map((d) => d.clerk?.name ?? "オンライン決済")
    .concat(onSitePayment?.onSitePaymentDiscounts.map((d) => d.clerk?.name ?? "オンライン決済"))[0];
  const tables =
    onSitePayment?.onSitePaymentTableUsers
      ?.map((tu) => tu.tableUser)
      .map(({ table }) => table)
      .sort((t1, t2) => t1.tableId - t2.tableId) ?? [];
  const tableUsers = onSitePayment?.onSitePaymentTableUsers.map(({ tableUser }) => tableUser) ?? [];

  const [cancelOnSitePaymentMutation, { loading: loadingCancelOnSitePayment }] =
    useAccountingDetailCancelOnSitePaymentMutation();

  const onClickCancelPayment = useCallback(async () => {
    if (!onSitePayment) return;

    await cancelOnSitePaymentMutation({
      variables: { input: { onSitePaymentId: onSitePayment?.onSitePaymentId } },
    });

    await refetchOnSitePayment();
  }, [cancelOnSitePaymentMutation, onSitePayment, refetchOnSitePayment]);

  const canceled = Boolean(onSitePayment?.voidedAt);
  const notClosed = Boolean(onSitePayment && !onSitePayment.closeCashRegisterId);

  const editDisabled = canceled || notClosed || !isFeatureEnabled("editOnSitePayment");

  return (
    <DashboardLayout title="会計履歴一覧">
      <PageHeader title="会計履歴一覧" onBack={() => navigate(-1)} />

      {onSitePayment && shopId ? (
        <>
          <RowSectionWrapper gutter={32}>
            <Col span={8}>
              <SubTitle bottomSpacer={<Spacer size={24} />}>取扱情報</SubTitle>
              <OnSitePaymentSummary
                clerkName={clerkName}
                date={onSitePayment.createdAt}
                onSitePaymentId={onSitePaymentId}
                accountingSlipId={onSitePayment.localAccountingSlipId}
                voidedAt={
                  typeof onSitePayment.voidedAt === "number"
                    ? dayjs.unix(onSitePayment.voidedAt).format()
                    : typeof onSitePayment.voidedAt === "string"
                    ? dayjs(onSitePayment.voidedAt).format()
                    : undefined
                }
              />
            </Col>
            <Col span={8}>
              <SubTitle bottomSpacer={<Spacer size={24} />}>顧客情報</SubTitle>
              <CustomerSummary tableUsers={tableUsers} tables={tables} />
            </Col>
          </RowSectionWrapper>

          <SubTitle bottomSpacer={<Spacer size={24} />}>商品明細</SubTitle>
          <OnSitePaymentPayingItemTable
            payingActivePlanChoices={onSitePayment.payingActivePlanChoices}
            payingActivePlanOpenPriceMetas={onSitePayment.payingActivePlanOpenPriceMetas}
            payingMenuOrderItems={onSitePayment.payingMenuOrderItems}
            updatePayingMenuOrderItem={updatePayingMenuOrderItem}
            updatePayingActivePlanChoice={updatePayingActivePlanChoice}
            updatePayingActivePlanOpenPriceMeta={updatePayingActivePlanOpenPriceMeta}
            disabled={editDisabled}
          />
          <Spacer size={8} /* Pagination の下に 24px の margin がある */ />

          <SubTitle>取扱明細</SubTitle>
          <RowSectionWrapper gutter={32}>
            <Col span={12}>
              <SubTitle level={5} bottomSpacer={<Spacer size={24} />}>
                総額
              </SubTitle>
              <OnSitePaymentDealingDetail onSitePayment={onSitePayment} />
            </Col>
            <Col span={12}>
              <SubTitle level={5} bottomSpacer={<Spacer size={24} />}>
                支払内訳
              </SubTitle>
              <OnSitePaymentBreakdownByDetailType
                shopId={shopId}
                onSitePaymentDetails={onSitePayment.onSitePaymentDetails}
                updateOnSitePaymentDetail={updateOnSitePaymentDetail}
                disabled={editDisabled}
              />
            </Col>
          </RowSectionWrapper>
          {onSitePayment.onSitePaymentDiscounts.length > 0 && (
            <RowSectionWrapper gutter={32}>
              <Col span={12}></Col>
              <Col span={12}>
                <SubTitle level={5} bottomSpacer={<Spacer size={24} />}>
                  割引内訳
                </SubTitle>
                <OnSitePaymentBreakdownByDiscountType
                  disabled={editDisabled}
                  shopId={shopId}
                  onSitePaymentDiscounts={onSitePayment.onSitePaymentDiscounts}
                  updateOnSitePaymentDiscount={updateOnSitePaymentDiscount}
                />
              </Col>
            </RowSectionWrapper>
          )}

          {validationErrorMessage && (
            <StyledAlert
              message="修正後の会計金額にズレがあります"
              description={<StyledParagraph>{validationErrorMessage}</StyledParagraph>}
              type="warning"
              banner
            />
          )}
          {canceled && <StyledAlert message="キャンセル済みの会計です" type="error" banner />}
          {notClosed && (
            <StyledAlert message="日次処理が行われていない会計です" type="warning" banner />
          )}

          <FormActions>
            <Button onClick={() => navigate(-1)}>キャンセル</Button>
            <Popconfirm
              title="会計を取り消しますか？一度取り消した会計を元に戻すことはできません"
              icon={<QuestionCircleOutlined />}
              onConfirm={onClickCancelPayment}
              disabled={editDisabled}
            >
              <Button danger loading={loadingCancelOnSitePayment} disabled={editDisabled}>
                会計を取り消す
              </Button>
            </Popconfirm>
            {!editDisabled && !validationErrorMessage && (
              <Button
                type="primary"
                onClick={updateOnSitePayment}
                loading={loading}
                disabled={!onSitePayment.closeCashRegisterId}
              >
                修正
              </Button>
            )}
          </FormActions>
        </>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
};
