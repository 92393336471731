import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";
import { Typography } from "antd";

import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type Props = {
  title: string;
};

export const FormSection = memo(({ title, children }: PropsWithChildren<Props>) => (
  <Wrapper>
    <FormContent>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Spacer size={12} />
      <Content>{children}</Content>
    </FormContent>
  </Wrapper>
));
