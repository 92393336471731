import React, { memo } from "react";

import { InputNumber } from "components/Input/InputNumber";

import { EditMenusBulkFormItem } from "../../useEditMenusBulkForm";

type Props = {
  initialValue: number;
  categoryMenuId: number;
  value: number | undefined;
  disabled: boolean;
};

export const PriceField = memo<Props>(({ initialValue, categoryMenuId, value, disabled }) => (
  <EditMenusBulkFormItem name={[categoryMenuId, "price"]} noStyle>
    <InputNumber
      placeholder={initialValue.toString()}
      defaultValue={value}
      min={0}
      fullWidth
      disabled={disabled}
    />
  </EditMenusBulkFormItem>
));
