import React, { memo, useCallback, useMemo, useState } from "react";
import { Alert, Button, Modal } from "antd";

import { message } from "components/antd/message";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { AddMenuForm } from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal/AddDishUpSlipGroupMenuForm";
import {
  useAddDishUpSlipGroupMenuModalGetMenusQuery,
  useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation,
} from "pages/DishUpSlipGroupMenus/AddDishUpSlipGroupMenuModal/queries";

import { DishUpSlipGroup } from "../types";

type Props = {
  dishUpSlipGroup: DishUpSlipGroup;
  goBack: () => void;
  onUpdated: () => void;
};

export const AddDishUpSlipGroupMenuModal = memo<Props>(({ dishUpSlipGroup, goBack, onUpdated }) => {
  const dishUpSlipGroupId = dishUpSlipGroup.dishUpSlipGroupId;
  const _dishUpSlipGroupId = dishUpSlipGroup.id;

  const [company] = useCompany();
  const [shop] = useShop();

  const companyId = company?.id;
  const shopId = shop?.shopId;

  const { data: getMenusData, error } = useAddDishUpSlipGroupMenuModalGetMenusQuery(
    companyId && shopId ? { variables: { companyId, shopId } } : { skip: true },
  );
  const categories = useMemo(() => getMenusData?.category ?? [], [getMenusData?.category]);

  const [addDishUpSlipGroupMenusMenu, { loading: loadingInsertDishUpSlipGroupMenusMenu }] =
    useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation();

  const [formValues, setFormValues] = useState<{ menuIds: number[] } | null>(null);
  const onOk = useCallback(async () => {
    if (formValues && shopId) {
      try {
        const { menuIds } = formValues;
        const dishUpSlipGroupShopMenus = menuIds.map((menuId) => {
          const menu = categories
            .flatMap((category) => category.categoryMenus.map(({ menu }) => menu))
            .find(({ menuId: _menuId }) => _menuId === menuId);

          if (!menu) throw new Error("menu not found");

          return {
            dishUpSlipGroupId,
            _dishUpSlipGroupId,
            menuId: menu.id,
            _menuId: menu.menuId,
            shopId,
          };
        });
        const { data: addDishUpSlipGroupMenusMenuData } = await addDishUpSlipGroupMenusMenu({
          variables: { dishUpSlipGroupShopMenus },
        });
        const returning =
          addDishUpSlipGroupMenusMenuData?.insert_dishUpSlipGroupShopMenus?.returning;

        if (returning?.length === 0) {
          throw new Error("");
        }

        onUpdated();

        message.success("追加しました");
      } catch (err) {
        message.error("追加に失敗しました");
      }
    }

    goBack();
  }, [
    formValues,
    shopId,
    goBack,
    addDishUpSlipGroupMenusMenu,
    onUpdated,
    categories,
    dishUpSlipGroupId,
    _dishUpSlipGroupId,
  ]);
  const onCancel = goBack;

  return (
    <Modal
      title="メニュー追加"
      open
      width={900}
      onOk={goBack}
      onCancel={goBack}
      footer={[
        <Button
          key="add"
          type="primary"
          onClick={onOk}
          loading={loadingInsertDishUpSlipGroupMenusMenu}
          disabled={formValues === null || formValues.menuIds.length === 0}
        >
          追加
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          キャンセル
        </Button>,
      ]}
    >
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddMenuForm
        dishUpSlipGroup={dishUpSlipGroup}
        categories={categories}
        onChange={setFormValues}
      />
    </Modal>
  );
});
