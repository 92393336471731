import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { extractError } from "util/remoteSchema";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { MessageContent } from "components/MessageContent";
import { useCorporation } from "hooks/useCorporation";
import { AddDashboardAccountForm } from "pages/AddDashboardAccount/AddDashboardAccountForm";
import { AddDashboardAccountFormValues } from "pages/AddDashboardAccount/AddDashboardAccountForm/useAddDashboardAccountForm";
import {
  useAddDashboardAccountAddAccountMutation,
  useAddDashboardAccountGetCompaniesQuery,
  useAddDashboardAccountGetDashboardAccountRolesQuery,
  useAddDashboardAccountGetShopsQuery,
} from "pages/AddDashboardAccount/queries";

export const AddDashboardAccount = () => {
  const [corporation] = useCorporation();

  const navigate = useNavigate();

  const {
    data: companiesData,
    error: getCompaniesError,
    loading: loadingCompaniesData,
  } = useAddDashboardAccountGetCompaniesQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const companies = useMemo(() => companiesData?.company ?? [], [companiesData?.company]);

  const {
    data: shopsData,
    error: getShopsError,
    loading: loadingShopsData,
  } = useAddDashboardAccountGetShopsQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const shops = useMemo(() => shopsData?.shop ?? [], [shopsData?.shop]);

  const {
    data: dashboardAccountRolesData,
    error: getDashboardAccountRolesError,
    loading: loadingDashboardAccountRolesData,
  } = useAddDashboardAccountGetDashboardAccountRolesQuery(
    corporation?.corporationId
      ? { variables: { corporationId: corporation.corporationId } }
      : { skip: true },
  );
  const dashboardAccountRoles = useMemo(
    () => dashboardAccountRolesData?.dashboardAccountRole ?? [],
    [dashboardAccountRolesData?.dashboardAccountRole],
  );

  const [addAccountMutation, { loading: loadingAddAccount }] =
    useAddDashboardAccountAddAccountMutation();
  const onSubmit = useCallback(
    async (formValues: AddDashboardAccountFormValues) => {
      if (!corporation) return;

      try {
        const companyIds =
          formValues.scope === "corporation"
            ? companies.map(({ id }) => id)
            : formValues.scope === "company"
            ? formValues.companies
            : [];
        const shopIds =
          formValues.scope === "corporation"
            ? shops.map(({ shopId }) => shopId)
            : formValues.scope === "shop"
            ? formValues.shops
            : [];

        const role =
          formValues.scope === "corporation"
            ? "corporationManager"
            : formValues.scope === "company"
            ? "companyManager"
            : formValues.scope === "shop"
            ? "shopMember"
            : "";

        const { errors } = await addAccountMutation({
          variables: {
            input: {
              corporationId: corporation.corporationId,
              email: formValues.email,
              name: formValues.name,
              password: formValues.password,
              role,
              companyIds,
              shopIds,
              dashboardAccountRoleId: formValues.dashboardAccountRoleId,
            },
          },
        });

        if (errors) {
          return message.error("アカウントの作成に失敗しました");
        }

        message.success("アカウントを作成しました");

        navigate("/setting/account", { replace: true });
      } catch (e) {
        const remoteError = e instanceof ApolloError ? extractError(e) : null;
        if (remoteError) return message.error(<MessageContent {...remoteError} />);

        message.error("アカウントの作成に失敗しました");
      }
    },
    [addAccountMutation, companies, corporation, navigate, shops],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingCompaniesData ||
    loadingShopsData ||
    loadingAddAccount ||
    loadingDashboardAccountRolesData;
  const shouldShowAlert = getCompaniesError ?? getShopsError ?? getDashboardAccountRolesError;

  return (
    <DashboardLayout
      title="アカウントを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "アカウント" }],
      }}
    >
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddDashboardAccountForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        loading={loading}
        companies={companies}
        shops={shops}
        dashboardAccountRoles={dashboardAccountRoles}
      />
    </DashboardLayout>
  );
};
