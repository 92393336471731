import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/AddCorporation/AddCorporationForm/NameField";
import { useAddCorporationForm } from "pages/AddCorporation/AddCorporationForm/useAddCorporationForm";

type Props = {
  onSubmit: (args: { name: string }) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
};

export const AddCorporationForm = memo<Props>(({ onSubmit, onFormValidationError, loading }) => {
  const { form, initialValues, submit } = useAddCorporationForm(onSubmit);

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      submit();
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onFormValidationError, submit]);

  return (
    <>
      <FormContent>
        <Form name="corporation" form={form} initialValues={initialValues} layout="vertical">
          <NameField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          作成
        </Button>
      </FormActions>
    </>
  );
});
