import React, { useCallback, useMemo } from "react";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { SteraOnSitePaymentDetailTypeInsertInput } from "types/graphql";

import { DetailTypeTable } from "./DetailTypeTable";
import {
  useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation,
  useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery,
  useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery,
  useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation,
} from "./queries";
import {
  DeleteSteraOnSitePaymentDetailTypeInput,
  UpdateSteraOnSitePaymentDetailTypeInput,
} from "./types";

export const SteraOnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getSteraOnSitePaymentDetailTypes,
    loading: loadingSteraOnSitePaymentDetailTypes,
    refetch: refetchSteraOnSitePaymentDetailTypes,
  } = useSteraOnSitePaymentDetailTypesGetSteraOnSitePaymentDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const { data: getOnSitePaymentDetailTypes, loading: loadingOnSitePaymentDetailTypes } =
    useSteraOnSitePaymentDetailTypesGetOnSitePaymentDetailTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );

  const [
    updateSteraOnSitePaymentDetailType,
    { loading: loadingUpdateSteraOnSitePaymentDetailType },
  ] = useSteraOnSitePaymentDetailTypesUpdateSteraOnSitePaymentDetailTypeMutation();

  const [
    deleteSteraOnSitePaymentDetailType,
    { loading: loadingDeleteSteraOnSitePaymentDetailType },
  ] = useSteraOnSitePaymentDetailTypesDeleteSteraOnSitePaymentDetailTypeMutation();

  const steraOnSitePaymentDetailTypes =
    getSteraOnSitePaymentDetailTypes?.steraOnSitePaymentDetailType ?? [];

  const onSitePaymentDetailTypes = useMemo(
    () => getOnSitePaymentDetailTypes?.onSitePaymentDetailType ?? [],
    [getOnSitePaymentDetailTypes?.onSitePaymentDetailType],
  );

  const updateDetailType = useCallback(
    async (input: UpdateSteraOnSitePaymentDetailTypeInput) => {
      if (!company) return;

      try {
        const objects = input.onSitePaymentDetailTypeIds.map(
          (onSitePaymentDetailTypeId): SteraOnSitePaymentDetailTypeInsertInput => {
            const onSitePaymentDetailType = onSitePaymentDetailTypes.find(
              (detailType) => detailType.id === onSitePaymentDetailTypeId,
            );

            if (!onSitePaymentDetailType) throw new Error("onSitePaymentDetailType not found");

            return {
              companyId: company.id,
              onSitePaymentDetailTypeId,
              onSitePaymentDetailTypeEntityType: onSitePaymentDetailType.type,
              steraPaymentMethodType: input.steraPaymentMethodType,
            };
          },
        );

        await updateSteraOnSitePaymentDetailType({
          variables: {
            companyId: company.id,
            steraPaymentMethodType: input.steraPaymentMethodType,
            objects,
          },
        });

        refetchSteraOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      company,
      onSitePaymentDetailTypes,
      refetchSteraOnSitePaymentDetailTypes,
      updateSteraOnSitePaymentDetailType,
    ],
  );

  const deleteDetailType = useCallback(
    async (input: DeleteSteraOnSitePaymentDetailTypeInput) => {
      if (!companyId) return;

      try {
        await deleteSteraOnSitePaymentDetailType({
          variables: { companyId, ...input },
        });

        refetchSteraOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [companyId, deleteSteraOnSitePaymentDetailType, refetchSteraOnSitePaymentDetailTypes],
  );

  const loading =
    loadingOnSitePaymentDetailTypes ||
    loadingSteraOnSitePaymentDetailTypes ||
    loadingUpdateSteraOnSitePaymentDetailType ||
    loadingDeleteSteraOnSitePaymentDetailType;

  return (
    <DashboardLayout title="stera 支払方法設定">
      <PageHeader title="stera 支払方法設定" />
      <DetailTypeTable
        steraOnSitePaymentDetailTypes={steraOnSitePaymentDetailTypes}
        onSitePaymentDetailTypes={onSitePaymentDetailTypes}
        loading={loading}
        onUpdate={updateDetailType}
        onDelete={deleteDetailType}
      />
    </DashboardLayout>
  );
};
