const lastShopIdHistoryKey = "shopHistory";
const companyIdsHistoryKey = "companyHistory";
const lastShopIdHistoryPerCorporationKey = "shopHistoryPerCorporation";

/**
 * NOTE: company.serial (number) を埋めていたのを company.id (string) を埋めるように変更したため、当面はどちらが返ってくるか不定
 */
const loadCompanyIds = (): { companyIds: Array<string | number> } => ({
  companyIds: JSON.parse(localStorage.getItem(companyIdsHistoryKey) ?? "[]"),
});

const addCompanyId = ({ companyId, _companyId }: { companyId: string; _companyId: number }) =>
  localStorage.setItem(
    companyIdsHistoryKey,
    JSON.stringify(
      [
        companyId,
        ...loadCompanyIds().companyIds.filter((id) => id !== companyId && id !== _companyId),
      ].slice(0, 10),
    ),
  );

const loadLastShopId = () => localStorage.getItem(lastShopIdHistoryKey);

const setShopId = (shopId: string | null) =>
  shopId
    ? localStorage.setItem(lastShopIdHistoryKey, shopId)
    : localStorage.removeItem(lastShopIdHistoryKey);

const loadLastShopIdPerCorporation = () => localStorage.getItem(lastShopIdHistoryPerCorporationKey);

const setShopIdPerCorporation = (shopId: string | null) =>
  shopId
    ? localStorage.setItem(lastShopIdHistoryPerCorporationKey, shopId)
    : localStorage.removeItem(lastShopIdHistoryPerCorporationKey);

export const useStorage = () => ({
  loadCompanyIds,
  addCompanyId,
  loadLastShopId,
  setShopId,
  loadLastShopIdPerCorporation,
  setShopIdPerCorporation,
});
