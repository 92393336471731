import React, { useCallback } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Col, DatePicker, Row, Select } from "antd";
import dayjs from "dayjs";
import { isNotUndefined } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useQueryParams } from "hooks/useQuery";
import { extractException } from "libs/dinii/graphql";
import { CashBalanceTable } from "pages/ShopDailyCashRegisterBalancing/CashBalanceTable";
import { CashManagementTable } from "pages/ShopDailyCashRegisterBalancing/CashManagementTable";
import { DailySalesSummary } from "pages/ShopDailyCashRegisterBalancing/DailySalesSummary";
import { EditBankDepositAmountModal } from "pages/ShopDailyCashRegisterBalancing/EditBankDepositAmountModal";
import { EditTargetDateModal } from "pages/ShopDailyCashRegisterBalancing/EditTargetDateModal";
import {
  useShopDailyCashRegisterBalancingGetCashBalanceQuery,
  useShopDailyCashRegisterBalancingGetCashManagementQuery,
  useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery,
  useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery,
  useShopDailyCashRegisterBalancingGetShopsQuery,
} from "pages/ShopDailyCashRegisterBalancing/queries";
import { SalesBreakdownByPaymentMethodTable } from "pages/ShopDailyCashRegisterBalancing/SalesBreakdownByPaymentMethodTable";

const { Option } = Select;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

export const ShopDailyCashRegisterBalancing = () => {
  const { shopId = "", date = "" } = useParams<Record<"shopId" | "date", string>>();
  const targetDate = date ? dayjs(date) : undefined;
  const query = useQueryParams();
  const selectedIndex = Number(query.get("index") ?? 0);

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isEditTargetDate = isFeatureEnabled("editCloseCashRegisterTargetDate");

  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getShopsData } = useShopDailyCashRegisterBalancingGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const {
    data: getDailySalesSummaryData,
    loading: loadingDailySalesSummaryData,
    error: getDailySalesSummaryDataError,
  } = useShopDailyCashRegisterBalancingGetDailySalesSummaryQuery(
    shopId && date ? { variables: { input: { shopId, targetDate: date } } } : { skip: true },
  );
  const dailySalesSummary = getDailySalesSummaryData?.dailySalesSummary
    .slice()
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())?.[
    selectedIndex
  ];

  const {
    data: getSalesBreakdownByPaymentMethod,
    loading: loadingGetSalesBreakdownByPaymentMethod,
    error: getSalesBreakdownByPaymentMethodError,
  } = useShopDailyCashRegisterBalancingGetSalesBreakdownByPaymentMethodQuery(
    shopId && date ? { variables: { input: { shopId, targetDateString: date } } } : { skip: true },
  );
  const salesBreakdownByPaymentMethod =
    getSalesBreakdownByPaymentMethod?.salesBreakdownByPaymentMethod
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())?.[
      selectedIndex
    ]?.salesBreakdown ?? [];

  const {
    data: getCashBalanceData,
    loading: loadingGetCashBalance,
    error: getCashBalanceError,
  } = useShopDailyCashRegisterBalancingGetCashBalanceQuery(
    shopId && date ? { variables: { input: { shopId, targetDateString: date } } } : { skip: true },
  );
  const cashBalance = getCashBalanceData?.cashBalance
    .slice()
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())?.[
    selectedIndex
  ];

  const {
    data: getCashManagementData,
    loading: loadingGetCashManagement,
    error: getCashManagementError,
    refetch,
  } = useShopDailyCashRegisterBalancingGetCashManagementQuery(
    shopId && date ? { variables: { input: { shopId, targetDateString: date } } } : { skip: true },
  );
  const cashManagement = getCashManagementData?.cashManagement
    .slice()
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())?.[
    selectedIndex
  ];

  const handleChangeDate = useCallback(
    (_: dayjs.Dayjs | null, targetDateString: string) => {
      navigate(`/dailyCashRegisterBalancing/${shopId}/${targetDateString}`, { replace: true });
    },
    [navigate, shopId],
  );

  const handleChangeShop = useCallback(
    (shopId: string) => {
      navigate(`/dailyCashRegisterBalancing/${shopId}/${date}`, { replace: true });
    },
    [navigate, date],
  );

  const goBackToCashRegisterBalancing = useCallback(() => {
    navigate(`/dailyCashRegisterBalancing/${shopId}/${date}?index=${selectedIndex}`, {
      replace: true,
    });
  }, [navigate, shopId, date, selectedIndex]);

  const onUpdateTargetDate = useCallback(
    (targetDateString: string) => {
      navigate(`/dailyCashRegisterBalancing/${shopId}/${targetDateString}`, {
        replace: true,
      });
    },
    [navigate, shopId],
  );

  const shouldShowAlert =
    getDailySalesSummaryDataError ||
    getSalesBreakdownByPaymentMethodError ||
    getCashBalanceError ||
    getCashManagementError;

  const exception = extractException(
    [
      getDailySalesSummaryDataError,
      getSalesBreakdownByPaymentMethodError,
      getCashBalanceError,
      getCashManagementError,
    ].filter(isNotUndefined),
  );

  const createdAts =
    getDailySalesSummaryData?.dailySalesSummary
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .map(({ createdAt }) => createdAt) ?? [];

  const handleChangeIndex = useCallback(
    (index) => {
      navigate(`/dailyCashRegisterBalancing/${shopId}/${date}?index=${index}`);
    },
    [navigate, shopId, date],
  );

  return (
    <DashboardLayout
      title="日次処理"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "日次処理" }],
      }}
    >
      <PageHeader
        title="日次処理"
        footer={
          <Row gutter={16}>
            <Col>
              <DatePicker
                defaultValue={targetDate}
                value={targetDate}
                onChange={handleChangeDate}
                allowClear={false}
              />
            </Col>
            <Col>
              <Select<string> defaultValue={shopId} onChange={handleChangeShop}>
                {shops.map(({ shopId, name }) => (
                  <Option key={shopId} value={shopId}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select<string> defaultValue={`${selectedIndex}`} onChange={handleChangeIndex}>
                {createdAts.map((createdAt, index) => (
                  <Option key={`${index}`} value={`${index}`}>
                    {dayjs(createdAt).format("YYYY/MM/DD HH:mm")}
                  </Option>
                ))}
              </Select>
            </Col>
            {isEditTargetDate && (
              <Col>
                <Link
                  key="editTargetDate"
                  to={`/dailyCashRegisterBalancing/${shopId}/${date}/targetDate/edit?index=${selectedIndex}`}
                  replace
                >
                  <Button>対象日を変更する</Button>
                </Link>
              </Col>
            )}
          </Row>
        }
      />

      {shouldShowAlert &&
        (exception ? (
          <StyledAlert type="error" message={exception.title} description={exception.message} />
        ) : (
          <StyledAlert
            type="error"
            message="通信に失敗しました"
            description="ネットワーク環境を確認してください"
          />
        ))}
      <DailySalesSummary dailySalesSummary={dailySalesSummary} />
      <Spacer size={32} />

      <SubTitle bottomSpacer={<Spacer size={24} />}>売上の内訳</SubTitle>
      <SalesBreakdownByPaymentMethodTable
        loading={loadingGetSalesBreakdownByPaymentMethod}
        salesBreakdownByPaymentMethod={salesBreakdownByPaymentMethod}
      />
      <Spacer size={32} />

      <SubTitle bottomSpacer={<Spacer size={24} />}>現金管理</SubTitle>
      <Row gutter={16}>
        <Col span={12}>
          <CashBalanceTable loading={loadingGetCashBalance} cashBalance={cashBalance} />
        </Col>
        <Col span={12}>
          <CashManagementTable
            shopId={shopId}
            date={date}
            loading={loadingGetCashManagement}
            cashManagement={cashManagement}
            index={selectedIndex}
          />
        </Col>
      </Row>
      <Routes>
        <Route
          path="bankDepositAmount/edit"
          element={
            <EditBankDepositAmountModal
              loading={loadingGetCashManagement}
              cashManagement={cashManagement}
              goBack={goBackToCashRegisterBalancing}
              onUpdated={refetch}
            />
          }
        />
        {isEditTargetDate && (
          <Route
            path="targetDate/edit"
            element={
              <EditTargetDateModal
                loading={loadingDailySalesSummaryData}
                closeCashRegisterId={dailySalesSummary?.closeCashRegisterId}
                targetDate={dayjs(date)}
                goBack={goBackToCashRegisterBalancing}
                onUpdated={onUpdateTargetDate}
              />
            }
          />
        )}
      </Routes>
    </DashboardLayout>
  );
};
