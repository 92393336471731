import { useCallback } from "react";
import { insertWithRelation } from "util/type/insert-with-relation";
import { v4 as uuidv4 } from "uuid";

import { createFormItem, Form } from "components/antd/Form";
import { useShop } from "hooks/useShop";
import { Clerk, ClerkInsertInput, ShopClerk } from "types/graphql";

export type AddClerkFormValues = Pick<Clerk, "name"> & Pick<ShopClerk, "isDisplay">;

const getInitialValues = () => ({
  isDisplay: true,
});

export const AddClerkFormItem = createFormItem<AddClerkFormValues>();

export const useAddClerkForm = (
  onSubmit: (clerk: Omit<ClerkInsertInput, "companyId" | "shopId">) => void,
) => {
  const [shop] = useShop();
  const shopId = shop?.shopId;

  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    if (!shopId) return;
    const { isDisplay, name } = form.getFieldsValue() as AddClerkFormValues;
    const clerkId = uuidv4();

    onSubmit(
      insertWithRelation<["clerk", "shopClerks"], "companyId" | "shopId">({
        id: clerkId,
        name,
        shopClerks: {
          data: [{ shopId, isDisplay, clerkId }],
        },
      }),
    );
  }, [form, onSubmit, shopId]);

  return { form, initialValues, submit };
};
