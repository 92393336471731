import React, { memo } from "react";
import { Button } from "antd";

import { EditOptionPikaichiOptionFormItem } from "../useEditOptionPikaichiOptionForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const PikaichiGetAvailableMenuCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed }) => (
    <EditOptionPikaichiOptionFormItem
      name="pikaichiGetAvailableMenuCdButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleGetAvailableMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditOptionPikaichiOptionFormItem>
  ),
);
