import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const KitchenDisplayDisplayTargetGetKdDisplayTargets = gql`
    query KitchenDisplayDisplayTargetGetKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    kdDisplayTargets {
      id
      name
      shopId
      role {
        name
      }
    }
  }
}
    `;
export type KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { kdDisplayTargets: Array<(
      { __typename?: 'kdDisplayTarget' }
      & Pick<Types.KdDisplayTarget, 'id' | 'name' | 'shopId'>
      & { role?: Types.Maybe<(
        { __typename?: 'role' }
        & Pick<Types.Role, 'name'>
      )> }
    )> }
  )> }
);


export const KitchenDisplayDisplayTargetGetKdDisplayTargetsDocument = gql`
    query KitchenDisplayDisplayTargetGetKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    kdDisplayTargets {
      id
      name
      shopId
      role {
        name
      }
    }
  }
}
    `;

/**
 * __useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery__
 *
 * To run a query within a React component, call `useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery(baseOptions: Apollo.QueryHookOptions<KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery, KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery, KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables>(KitchenDisplayDisplayTargetGetKdDisplayTargetsDocument, options);
      }
export function useKitchenDisplayDisplayTargetGetKdDisplayTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery, KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery, KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables>(KitchenDisplayDisplayTargetGetKdDisplayTargetsDocument, options);
        }
export type KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetGetKdDisplayTargetsQuery>;
export type KitchenDisplayDisplayTargetGetKdDisplayTargetsLazyQueryHookResult = ReturnType<typeof useKitchenDisplayDisplayTargetGetKdDisplayTargetsLazyQuery>;
export type KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryResult = Apollo.QueryResult<KitchenDisplayDisplayTargetGetKdDisplayTargetsQuery, KitchenDisplayDisplayTargetGetKdDisplayTargetsQueryVariables>;