import React, { memo, useCallback, useRef } from "react";
import { useKeyPressEvent, useLockBodyScroll } from "react-use";
import styled from "styled-components";
import { InputRef, Modal } from "antd";

import { CompanySelectOverlay } from "components/Layout/DashboardLayout/CompanySelect/CompanySelectOverlay";
import { useWindowHeight } from "components/Layout/DashboardLayout/CompanySelect/useWindowHeight";
import { Company } from "components/Layout/DashboardLayout/types";
import { grey } from "constants/colors";
import { useCompanySelectOverlay } from "hooks/useCompanySelectOverlay";
import { useViewport } from "hooks/useViewport";

const StyledModal = styled(Modal)`
  z-index: 2000;

  .ant-modal-content {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  background: ${grey[0]};
  z-index: 2000;
`;

type Props = {
  companies: Company[];
  setCompany: (companyId: string) => void;
};

export const CompanySelect = memo<Props>(({ setCompany, companies }) => {
  const { isCompanySelectOverlayVisible, setCompanySelectOverlayVisible } =
    useCompanySelectOverlay();

  const { isSmartphone, isTablet, isDesktop } = useViewport();

  const { height: windowHeight } = useWindowHeight();
  const height = windowHeight * (isSmartphone || isTablet ? 0.6 : 0.8);

  const inputRef = useRef<InputRef>(null);

  const onSelect = useCallback(
    (companyId: string) => {
      setCompany(companyId);
      setCompanySelectOverlayVisible(false);
    },
    [setCompany, setCompanySelectOverlayVisible],
  );
  const onClose = useCallback(
    () => setCompanySelectOverlayVisible(false),
    [setCompanySelectOverlayVisible],
  );

  useKeyPressEvent("Escape", onClose);

  useLockBodyScroll(isCompanySelectOverlayVisible);

  if (!isCompanySelectOverlayVisible) {
    return null;
  }

  if (isDesktop) {
    return (
      <StyledModal
        open
        width={900}
        bodyStyle={{ height, padding: 0 }}
        onCancel={onClose}
        footer={null}
        afterOpenChange={() => inputRef.current?.focus()}
      >
        <CompanySelectOverlay
          inputRef={inputRef}
          companies={companies}
          onSelect={onSelect}
          onClose={onClose}
          height={height}
        />
      </StyledModal>
    );
  }

  return (
    <Wrapper>
      <CompanySelectOverlay
        companies={companies}
        onSelect={onSelect}
        onClose={onClose}
        height={height}
      />
    </Wrapper>
  );
});
