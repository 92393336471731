import React, { memo, useMemo } from "react";
import { Tag } from "antd";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";
import { CategoryMenu } from "pages/PlanMenus/types";

type Menu = CategoryMenu["menu"] & Pick<CategoryMenu, "category">;

type Props = {
  loading?: boolean;
  categoryMenus: CategoryMenu[];
};

export const MenuTable = memo<Props>(({ loading, categoryMenus }) => {
  const [pagination, setPagination] = usePagination();

  const menus: Menu[] = useMemo(
    () => categoryMenus.flatMap(({ category, menu }) => ({ category, ...menu })),
    [categoryMenus],
  );

  const columns = [
    {
      title: "画像",
      align: "center",
      render(_: string, { imageUrl }: Menu) {
        return <Thumbnail url={imageUrl} width={64} height={64} />;
      },
    } as const,
    {
      title: "カテゴリ名",
      render(_: string, { category }: Menu) {
        return category.shopSideName || category.name;
      },
    },
    { title: "商品名", dataIndex: "name" },
    {
      title: "オプション",
      render(_: string, { menuOptions }: Menu) {
        return menuOptions.map(({ option }) => <Tag key={option.optionId}>{option.name}</Tag>);
      },
    },
    {
      title: "価格",
      dataIndex: "price",
      align: "right",
      render(_: string, { price, openPrice }: Menu) {
        return openPrice ? "フリー" : `${price}円`;
      },
    } as const,
    { title: "説明", dataIndex: "description" },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: string, { menuId }: Menu) {
        return (
          <IconLink to={`/menu/${menuId}/edit`}>
            <EditIcon />
          </IconLink>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="menuId"
      columns={columns}
      dataSource={menus}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
