import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddOptionGetOptions = gql`
    query AddOptionGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftOptionMeta: {}}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    receiptDisplayName
    optionInputType {
      value
    }
    maxChoiceNum
    minChoiceNum
    translations {
      columnName
      locale
      value
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      priority
      name
      receiptDisplayName
      price
      costPrice
      costTaxRate
      isDefaultSelection
      menuType
      imageUrl
      translations {
        columnName
        locale
        value
      }
    }
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    ) {
      menu {
        menuId
        name
      }
    }
  }
}
    `;
export const AddOptionFormInsertOption = gql`
    mutation AddOptionFormInsertOption($option: CreateOptionInputOptionSource!) {
  createOption(input: $option) {
    optionId
    _optionId
  }
}
    `;
export type AddOptionGetOptionsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddOptionGetOptionsQuery = (
  { __typename?: 'query_root' }
  & { option: Array<(
    { __typename?: 'option' }
    & Pick<Types.Option, 'optionId' | 'name' | 'receiptDisplayName' | 'maxChoiceNum' | 'minChoiceNum'>
    & { optionInputType: (
      { __typename?: 'optionInputType' }
      & Pick<Types.OptionInputType, 'value'>
    ), translations: Array<(
      { __typename?: 'translation' }
      & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
    )>, choices: Array<(
      { __typename?: 'choice' }
      & Pick<Types.Choice, 'choiceId' | 'priority' | 'name' | 'receiptDisplayName' | 'price' | 'costPrice' | 'costTaxRate' | 'isDefaultSelection' | 'menuType' | 'imageUrl'>
      & { translations: Array<(
        { __typename?: 'translation' }
        & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
      )> }
    )>, menuOptions: Array<(
      { __typename?: 'menuOption' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
      ) }
    )> }
  )> }
);

export type AddOptionFormInsertOptionMutationVariables = Types.Exact<{
  option: Types.CreateOptionInputOptionSource;
}>;


export type AddOptionFormInsertOptionMutation = (
  { __typename?: 'mutation_root' }
  & { createOption: (
    { __typename?: 'CreateOptionOutput' }
    & Pick<Types.CreateOptionOutput, 'optionId' | '_optionId'>
  ) }
);


export const AddOptionGetOptionsDocument = gql`
    query AddOptionGetOptions($companyId: uuid!) {
  option(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {giftOptionMeta: {}}}
    order_by: {optionId: asc}
  ) {
    optionId
    name
    receiptDisplayName
    optionInputType {
      value
    }
    maxChoiceNum
    minChoiceNum
    translations {
      columnName
      locale
      value
    }
    choices(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {choiceId: asc}]
    ) {
      choiceId
      priority
      name
      receiptDisplayName
      price
      costPrice
      costTaxRate
      isDefaultSelection
      menuType
      imageUrl
      translations {
        columnName
        locale
        value
      }
    }
    menuOptions(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    ) {
      menu {
        menuId
        name
      }
    }
  }
}
    `;

/**
 * __useAddOptionGetOptionsQuery__
 *
 * To run a query within a React component, call `useAddOptionGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOptionGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOptionGetOptionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddOptionGetOptionsQuery(baseOptions: Apollo.QueryHookOptions<AddOptionGetOptionsQuery, AddOptionGetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddOptionGetOptionsQuery, AddOptionGetOptionsQueryVariables>(AddOptionGetOptionsDocument, options);
      }
export function useAddOptionGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddOptionGetOptionsQuery, AddOptionGetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddOptionGetOptionsQuery, AddOptionGetOptionsQueryVariables>(AddOptionGetOptionsDocument, options);
        }
export type AddOptionGetOptionsQueryHookResult = ReturnType<typeof useAddOptionGetOptionsQuery>;
export type AddOptionGetOptionsLazyQueryHookResult = ReturnType<typeof useAddOptionGetOptionsLazyQuery>;
export type AddOptionGetOptionsQueryResult = Apollo.QueryResult<AddOptionGetOptionsQuery, AddOptionGetOptionsQueryVariables>;
export const AddOptionFormInsertOptionDocument = gql`
    mutation AddOptionFormInsertOption($option: CreateOptionInputOptionSource!) {
  createOption(input: $option) {
    optionId
    _optionId
  }
}
    `;
export type AddOptionFormInsertOptionMutationFn = Apollo.MutationFunction<AddOptionFormInsertOptionMutation, AddOptionFormInsertOptionMutationVariables>;

/**
 * __useAddOptionFormInsertOptionMutation__
 *
 * To run a mutation, you first call `useAddOptionFormInsertOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOptionFormInsertOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOptionFormInsertOptionMutation, { data, loading, error }] = useAddOptionFormInsertOptionMutation({
 *   variables: {
 *      option: // value for 'option'
 *   },
 * });
 */
export function useAddOptionFormInsertOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddOptionFormInsertOptionMutation, AddOptionFormInsertOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOptionFormInsertOptionMutation, AddOptionFormInsertOptionMutationVariables>(AddOptionFormInsertOptionDocument, options);
      }
export type AddOptionFormInsertOptionMutationHookResult = ReturnType<typeof useAddOptionFormInsertOptionMutation>;
export type AddOptionFormInsertOptionMutationResult = Apollo.MutationResult<AddOptionFormInsertOptionMutation>;
export type AddOptionFormInsertOptionMutationOptions = Apollo.BaseMutationOptions<AddOptionFormInsertOptionMutation, AddOptionFormInsertOptionMutationVariables>;