type CsvInput = {
  columnHeaders: string[];
  rows: string[][];
  fileName: string;
};

// first character to make excel use utf-8
const BOM = "\uFEFF";
const lineBreak = "\r\n";

const arrayToCsvRow = (row: string[]) => row.map((cell) => `"${cell}"`).join(",") + lineBreak;

/*
 * Create a csv locally and download it.
 */
export const exportCsv = ({ columnHeaders, fileName, rows }: CsvInput) => {
  const csvContent = BOM + arrayToCsvRow(columnHeaders) + rows.map(arrayToCsvRow).join("");

  /*
    To create and download a file locally:
    1. create a URI
    2. add it to a link element, add it to the dom
    3. click it.
    4. clean up by removing link element
  */
  const encodedCsvContent = encodeURIComponent(csvContent);
  const link = document.createElement("a");

  link.setAttribute("href", `data:text/csv; charset=utf-8,${encodedCsvContent}`);
  link.setAttribute("download", `${fileName}.csv`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export type CsvHeaders<T> = {
  property: keyof T;
  label: string;
}[];

type CsvWithTypeInput<T> = {
  rows: T[];
  headers: CsvHeaders<T>;
  fileName: string;
};

export const exportCsvWithType = <T>({ rows, headers, fileName }: CsvWithTypeInput<T>) => {
  const headerProperties = headers.map(({ property }) => property);
  const headersLabels = headers.map(({ label }) => label);
  const convertedRows = rows.map((row) =>
    headerProperties.map((property) => String(row[property])),
  );

  exportCsv({
    fileName,
    columnHeaders: headersLabels,
    rows: convertedRows,
  });
};
