import React, { memo } from "react";
import { Button, Divider } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { Maybe } from "types/Maybe";

import { FoodingJournalConfig } from "../types";
import { useFoodingJournalConfigForm } from "../useFoodingJournalConfigForm";

import { ConfigIdField } from "./ConfigIdField";
import { ConfigPasswordField } from "./ConfigPasswordField";
import { ConfigSecretKeyField } from "./ConfigSecretKeyField";
import { EnabledField } from "./EnabledField";
import { InputUrlField } from "./InputUrlField";
import { PosNumberField } from "./PosNumberField";
import { ShopCodeField } from "./ShopCodeField";

type Props = {
  foodingJournalConfig?: Maybe<FoodingJournalConfig>;
  onSubmit: Parameters<typeof useFoodingJournalConfigForm>[0]["onSubmit"];
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  loading: boolean;
};

export const EditFoodingJournalConfigForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, foodingJournalConfig }) => {
    const { form, initialValues, submit } = useFoodingJournalConfigForm({
      foodingJournalConfig,
      onFormValidationError,
      onSubmit,
    });

    return (
      <>
        <FormContent>
          <Form
            name="foodingJournalConfig"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <EnabledField />
            <ShopCodeField />
            <PosNumberField />
            <ConfigIdField />
            <ConfigPasswordField />
            <ConfigSecretKeyField />
            <InputUrlField />
            <Divider />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={submit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
