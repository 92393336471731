import React, { memo, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Tag } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { difference } from "lodash";
import { uniq } from "util/array";

import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { usePagination } from "hooks/usePagination";

import { BulkEditConditions, Menu, RowItem, RowItemChoice, RowItemMenu, Shop } from "../types";

import { BulkUpdateBanner } from "./BulkUpdateBanner";

const isRowItemMenu = (rowItem: RowItem): rowItem is RowItemMenu => rowItem.type === "menu";
const isRowItemChoice = (rowItem: RowItem): rowItem is RowItemChoice => rowItem.type === "choice";

const DEFAULT_CURRENT = 1;
const DEFAULT_PAGE_SIZE = 10;

type Props = {
  menus: Menu[];
  shops: Shop[];
  shopIdToShopMap: Map<string, Shop>;
  bulkEditConditions: BulkEditConditions;
  setBulkEditConditions: (bulkEditConditions: BulkEditConditions) => void;
  selectedMenuRowKeys: string[];
  setSelectedMenuRowKeys: (rowKeys: string[]) => void;
  selectedChoiceRowKeys: string[];
  setSelectedChoiceRowKeys: (rowKeys: string[]) => void;
  selectedRowKeys: string[];
  clearBulkEditConditions: () => void;
};

export const EditShopMenusBulkFormTable = memo<Props>(
  ({
    menus,
    shops,
    shopIdToShopMap,
    bulkEditConditions,
    setBulkEditConditions,
    selectedMenuRowKeys,
    setSelectedMenuRowKeys,
    selectedChoiceRowKeys,
    setSelectedChoiceRowKeys,
    selectedRowKeys,
    clearBulkEditConditions,
  }) => {
    const columns = useMemo(
      () => [
        {
          title: "",
          width: 40,
        } as const,
        {
          title: "画像",
          align: "center",
          width: 100,
          render(_: string, rowItem: RowItem) {
            if (rowItem.type !== "menu") return null;
            return <Thumbnail url={rowItem.imageUrl} width={64} height={64} />;
          },
        } as const,
        {
          title: "商品名",
          width: 240,
          render(_: string, rowItem: RowItem) {
            switch (rowItem.type) {
              case "choice": {
                const { optionId, name } = rowItem;
                return <Link to={`/option/${optionId}/edit`}>{name}</Link>;
              }
              case "menu": {
                const { menuId, name } = rowItem;
                return <Link to={`/menu/${menuId}/edit`}>{name}</Link>;
              }
              default:
                return null;
            }
          },
        },
        {
          title: "カテゴリ名",
          width: 240,
          render(_: string, rowItem: RowItem) {
            if (rowItem.type !== "menu") return null;

            return rowItem.categoryMenus.map(({ category: { name } }) => name).join(", ");
          },
        },
        {
          title: "取扱店舗",
          width: 400,
          render(_: string, rowItem: RowItem) {
            switch (rowItem.type) {
              case "choice": {
                return rowItem.shopChoices.map(({ shopId }) => {
                  const shop = shopIdToShopMap.get(shopId);
                  return shop ? (
                    <Tag key={shopId} color="processing">
                      {shop.name}
                    </Tag>
                  ) : null;
                });
              }
              case "menu": {
                return rowItem.shopMenus.map(({ shopId }) => {
                  const shop = shopIdToShopMap.get(shopId);
                  return shop ? (
                    <Tag key={shopId} color="processing">
                      {shop.name}
                    </Tag>
                  ) : null;
                });
              }
              default:
                return null;
            }
          },
        },
      ],
      [shopIdToShopMap],
    );

    const rows = useMemo<RowItem[]>(
      () =>
        menus.map((menu) => ({
          ...menu,
          rowKey: menu.id,
          type: "menu",
          children:
            menu.menuOptions.length > 0
              ? menu.menuOptions.flatMap(({ option }) =>
                  option.choices.map((choice) => ({
                    ...choice,
                    rowKey: `${menu.id}-${choice.id}`,
                    type: "choice",
                    optionId: option.optionId,
                    name: `${option.name} ${choice.name}`,
                  })),
                )
              : null,
        })),
      [menus],
    );

    const [defaultPagination, setPagination] = usePagination();
    const pagination = useMemo(
      () => ({
        ...defaultPagination,
        defaultCurrent: defaultPagination.defaultCurrent ?? DEFAULT_CURRENT,
        defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
      }),
      [defaultPagination],
    );

    const currentPageMenuRowKeys = useMemo(() => {
      const startIndex = (pagination.defaultCurrent - 1) * pagination.defaultPageSize;
      const endIndex = pagination.defaultCurrent * pagination.defaultPageSize;

      return menus.slice(startIndex, endIndex).map((menu) => menu.id);
    }, [menus, pagination]);

    const currentPageSelectedMenuRowKeys = useMemo(
      () => selectedMenuRowKeys.filter((rowKey) => currentPageMenuRowKeys.includes(rowKey)),
      [selectedMenuRowKeys, currentPageMenuRowKeys],
    );

    const currentPageChoiceRowKeys = useMemo(
      () =>
        menus
          .filter((menu) => menu.menuOptions.length !== 0)
          .filter((menu) => currentPageMenuRowKeys.includes(menu.id))
          .flatMap((menu) =>
            menu.menuOptions.flatMap(({ option }) =>
              option.choices.map((choice) => `${menu.id}-${choice.id}`),
            ),
          ),
      [menus, currentPageMenuRowKeys],
    );

    const currentPageSelectedChoiceRowKeys = useMemo(
      () => selectedChoiceRowKeys.filter((rowKey) => currentPageChoiceRowKeys.includes(rowKey)),
      [selectedChoiceRowKeys, currentPageChoiceRowKeys],
    );

    const currentPageSelectedRowKeys = useMemo(
      () => [...currentPageSelectedMenuRowKeys, ...currentPageSelectedChoiceRowKeys],
      [currentPageSelectedMenuRowKeys, currentPageSelectedChoiceRowKeys],
    );

    const isSelectedAllRows =
      menus.length !== 0 && currentPageSelectedMenuRowKeys.length === currentPageMenuRowKeys.length;

    const onAllCheckboxClick = useCallback(() => {
      if (isSelectedAllRows) {
        setSelectedMenuRowKeys(difference(selectedMenuRowKeys, currentPageSelectedRowKeys));
        setSelectedChoiceRowKeys(
          difference(selectedChoiceRowKeys, currentPageSelectedChoiceRowKeys),
        );
        return;
      }

      setSelectedMenuRowKeys(uniq([...selectedMenuRowKeys, ...currentPageMenuRowKeys]));
    }, [
      isSelectedAllRows,
      setSelectedMenuRowKeys,
      setSelectedChoiceRowKeys,
      selectedMenuRowKeys,
      selectedChoiceRowKeys,
      currentPageSelectedRowKeys,
      currentPageMenuRowKeys,
      currentPageSelectedChoiceRowKeys,
    ]);

    const allCheckboxProps = useMemo(
      () => ({
        indeterminate: currentPageSelectedRowKeys.length !== 0 && !isSelectedAllRows,
        checked: isSelectedAllRows,
        onClick: onAllCheckboxClick,
      }),
      [currentPageSelectedRowKeys, isSelectedAllRows, onAllCheckboxClick],
    );

    const rowSelection: TableRowSelection<RowItem> = useMemo(
      () => ({
        columnTitle: <Checkbox {...allCheckboxProps} />,
        selectedRowKeys,
        onChange: (_, rows) => {
          setSelectedMenuRowKeys(rows.filter(isRowItemMenu).map(({ rowKey }) => rowKey));
          setSelectedChoiceRowKeys(rows.filter(isRowItemChoice).map(({ rowKey }) => rowKey));
        },
        columnWidth: 48,
      }),
      [selectedRowKeys, allCheckboxProps, setSelectedMenuRowKeys, setSelectedChoiceRowKeys],
    );

    return (
      <>
        {selectedRowKeys.length !== 0 && (
          <BulkUpdateBanner
            onCancel={clearBulkEditConditions}
            selectedCount={selectedRowKeys.length}
            bulkEditConditions={bulkEditConditions}
            setBulkEditConditions={setBulkEditConditions}
            shops={shops}
          />
        )}
        <Table<RowItem>
          rowKey={({ rowKey }) => rowKey}
          rowSelection={rowSelection}
          dataSource={rows}
          columns={columns}
          pagination={pagination}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
        />
      </>
    );
  },
);
