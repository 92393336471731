import { useEffect, useState } from "react";

export const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const resize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return { height };
};
