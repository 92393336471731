import React, { memo } from "react";
import styled, { css } from "styled-components";

import { colors, grey } from "constants/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{
  isHighlight: boolean;
  isLight: boolean;
  textAlign?: "left" | "center" | "right";
}>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  ${({ isHighlight }) =>
    !isHighlight
      ? ""
      : css`
          color: ${grey[0]};
        `};
  ${({ isLight }) =>
    !isLight
      ? ""
      : css`
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: ${colors.Text.Secondary};
        `}
  ${({ textAlign }) => (!textAlign ? "" : `text-align: ${textAlign};`)}
`;

const SubContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

export const TableContent = memo<{
  content: string;
  subContent?: string;
  isHighlight?: boolean;
  isLight?: boolean;
  textAlign?: "left" | "center" | "right";
}>(({ content, subContent, textAlign, isHighlight = false, isLight = false }) => (
  <Container>
    <Content isHighlight={isHighlight} isLight={isLight} textAlign={textAlign}>
      {content}
    </Content>
    {subContent && <SubContent>{subContent}</SubContent>}
  </Container>
));
