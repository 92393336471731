import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddPlanFormItem } from "../useAddPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const FeaturedLabelTextField = memo<Props>((props) => (
  <AddPlanFormItem label="特集ラベル：(日本語)" name="featuredLabelText" {...props}>
    <Input />
  </AddPlanFormItem>
));
