import { useCallback } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { useCompany } from "hooks/useCompany";
import { OrderableTime, OrderableTimeInsertInput } from "types/graphql";

type AddOrderableTimeFormValues = Pick<OrderableTime, "name">;

export const AddOrderableTimeFormItem = createFormItem<AddOrderableTimeFormValues>();

export const useAddOrderableTimeForm = (
  onSubmit: (orderableTime: OrderableTimeInsertInput) => void,
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void,
) => {
  const [company] = useCompany();
  const companyId = company?.id;

  const [form] = Form.useForm();
  const initialValues: AddOrderableTimeFormValues = { name: "" };

  const submit = useCallback(async () => {
    if (!companyId) return;
    try {
      await form.validateFields();

      const values = form.getFieldsValue() as AddOrderableTimeFormValues;

      onSubmit({
        companyId,
        name: values.name,
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, companyId, onFormValidationError]);

  return { form, initialValues, submit };
};
