import React, { memo } from "react";
import { FormItemProps, Input } from "antd";

import { EditDetailTypeFormItem } from "../useEditDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PaymentTypeField = memo<Props>((props) => (
  <EditDetailTypeFormItem
    label="明細種別"
    name="paymentType"
    rules={[{ required: true, message: "明細種別を入力してください" }]}
    {...props}
  >
    <Input />
  </EditDetailTypeFormItem>
));
