import React, { memo } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";

import { Dropdown } from "components/Dropdown";
import { UserMenu } from "components/Layout/DashboardLayout/NavigationDrawer/UserCard/UserMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { grey } from "constants/colors";
import { useCompanySelectOverlay } from "hooks/useCompanySelectOverlay";
import { useCurrentRole } from "hooks/useCurrentRole";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px;
`;

const CompanyName = styled(Typography.Title)``;

const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  ${CompanyName} {
    margin: 0;
  }
`;

const CaretDown = styled(CaretDownOutlined)`
  margin-left: 8px;
`;

const UserIcon = styled(UserOutlined)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: ${grey[0]};
  font-size: 18px;
  background: ${grey[6]};
  border-radius: 50%;
  transition: 0.3s;
`;

const UserName = styled.span`
  flex: 1;
  transition: 0.3s;
`;

const UserDropdownContainer = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  &:hover span {
    color: ${grey[5]};
  }
  &:hover ${UserIcon} {
    background: ${grey[7]};
  }
`;

type Props = {
  companyName: string | undefined;
  accountName: string | undefined;
  companies: Company[];
  signOut: () => void;
};

export const UserCard = memo<Props>(({ companyName, accountName, companies, signOut }) => {
  const user = useCurrentUser();
  const role = useCurrentRole();
  const { setCompanySelectOverlayVisible } = useCompanySelectOverlay();

  return (
    <Container>
      {companies.length > 1 ? (
        <Button type="text" onClick={() => setCompanySelectOverlayVisible(true)}>
          <CompanyContainer>
            <CompanyName level={5}>{companyName}</CompanyName>
            <CaretDown />
          </CompanyContainer>
        </Button>
      ) : (
        <CompanyName level={5}>{companyName}</CompanyName>
      )}
      <Dropdown overlay={<UserMenu signOut={signOut} />} placement="bottomRight">
        <UserDropdownContainer>
          <UserIcon />
          <UserName>{role === Role.ServiceAdmin ? user?.email ?? undefined : accountName}</UserName>
        </UserDropdownContainer>
      </Dropdown>
    </Container>
  );
});
