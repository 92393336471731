import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditClerkPrioritiesGetShopClerks = gql`
    query EditClerkPrioritiesGetShopClerks($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopClerks(
      where: {shop: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_clerkId: asc}]
    ) {
      clerk {
        id
        clerkId
        name
      }
      isDisplay
      priority
      shopId
    }
  }
}
    `;
export const EditClerkPrioritiesInsertShopClerks = gql`
    mutation EditClerkPrioritiesInsertShopClerks($shopClerks: [shopClerk_insert_input!]!) {
  insert_shopClerk(
    objects: $shopClerks
    on_conflict: {constraint: shopClerk_shopId_clerkId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      clerkId
    }
  }
}
    `;
export type EditClerkPrioritiesGetShopClerksQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditClerkPrioritiesGetShopClerksQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & { shopClerks: Array<(
      { __typename?: 'shopClerk' }
      & Pick<Types.ShopClerk, 'isDisplay' | 'priority' | 'shopId'>
      & { clerk: (
        { __typename?: 'clerk' }
        & Pick<Types.Clerk, 'id' | 'clerkId' | 'name'>
      ) }
    )> }
  )> }
);

export type EditClerkPrioritiesInsertShopClerksMutationVariables = Types.Exact<{
  shopClerks: Array<Types.ShopClerkInsertInput> | Types.ShopClerkInsertInput;
}>;


export type EditClerkPrioritiesInsertShopClerksMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopClerk?: Types.Maybe<(
    { __typename?: 'shopClerk_mutation_response' }
    & Pick<Types.ShopClerkMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopClerk' }
      & Pick<Types.ShopClerk, 'clerkId'>
    )> }
  )> }
);


export const EditClerkPrioritiesGetShopClerksDocument = gql`
    query EditClerkPrioritiesGetShopClerks($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopClerks(
      where: {shop: {archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_clerkId: asc}]
    ) {
      clerk {
        id
        clerkId
        name
      }
      isDisplay
      priority
      shopId
    }
  }
}
    `;

/**
 * __useEditClerkPrioritiesGetShopClerksQuery__
 *
 * To run a query within a React component, call `useEditClerkPrioritiesGetShopClerksQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditClerkPrioritiesGetShopClerksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditClerkPrioritiesGetShopClerksQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditClerkPrioritiesGetShopClerksQuery(baseOptions: Apollo.QueryHookOptions<EditClerkPrioritiesGetShopClerksQuery, EditClerkPrioritiesGetShopClerksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditClerkPrioritiesGetShopClerksQuery, EditClerkPrioritiesGetShopClerksQueryVariables>(EditClerkPrioritiesGetShopClerksDocument, options);
      }
export function useEditClerkPrioritiesGetShopClerksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditClerkPrioritiesGetShopClerksQuery, EditClerkPrioritiesGetShopClerksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditClerkPrioritiesGetShopClerksQuery, EditClerkPrioritiesGetShopClerksQueryVariables>(EditClerkPrioritiesGetShopClerksDocument, options);
        }
export type EditClerkPrioritiesGetShopClerksQueryHookResult = ReturnType<typeof useEditClerkPrioritiesGetShopClerksQuery>;
export type EditClerkPrioritiesGetShopClerksLazyQueryHookResult = ReturnType<typeof useEditClerkPrioritiesGetShopClerksLazyQuery>;
export type EditClerkPrioritiesGetShopClerksQueryResult = Apollo.QueryResult<EditClerkPrioritiesGetShopClerksQuery, EditClerkPrioritiesGetShopClerksQueryVariables>;
export const EditClerkPrioritiesInsertShopClerksDocument = gql`
    mutation EditClerkPrioritiesInsertShopClerks($shopClerks: [shopClerk_insert_input!]!) {
  insert_shopClerk(
    objects: $shopClerks
    on_conflict: {constraint: shopClerk_shopId_clerkId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      clerkId
    }
  }
}
    `;
export type EditClerkPrioritiesInsertShopClerksMutationFn = Apollo.MutationFunction<EditClerkPrioritiesInsertShopClerksMutation, EditClerkPrioritiesInsertShopClerksMutationVariables>;

/**
 * __useEditClerkPrioritiesInsertShopClerksMutation__
 *
 * To run a mutation, you first call `useEditClerkPrioritiesInsertShopClerksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClerkPrioritiesInsertShopClerksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClerkPrioritiesInsertShopClerksMutation, { data, loading, error }] = useEditClerkPrioritiesInsertShopClerksMutation({
 *   variables: {
 *      shopClerks: // value for 'shopClerks'
 *   },
 * });
 */
export function useEditClerkPrioritiesInsertShopClerksMutation(baseOptions?: Apollo.MutationHookOptions<EditClerkPrioritiesInsertShopClerksMutation, EditClerkPrioritiesInsertShopClerksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditClerkPrioritiesInsertShopClerksMutation, EditClerkPrioritiesInsertShopClerksMutationVariables>(EditClerkPrioritiesInsertShopClerksDocument, options);
      }
export type EditClerkPrioritiesInsertShopClerksMutationHookResult = ReturnType<typeof useEditClerkPrioritiesInsertShopClerksMutation>;
export type EditClerkPrioritiesInsertShopClerksMutationResult = Apollo.MutationResult<EditClerkPrioritiesInsertShopClerksMutation>;
export type EditClerkPrioritiesInsertShopClerksMutationOptions = Apollo.BaseMutationOptions<EditClerkPrioritiesInsertShopClerksMutation, EditClerkPrioritiesInsertShopClerksMutationVariables>;