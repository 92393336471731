import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOptionDealersGetKitchenRoles = gql`
    query EditOptionDealersGetKitchenRoles($shopId: uuid!, $optionId: uuid!) {
  shop: shop_by_pk(shopId: $shopId) {
    shopId
    name
    roles {
      id
      name
    }
    shopOptionKitchenRoles(where: {optionId: {_eq: $optionId}}) {
      id
      kitchenRoleId
    }
  }
}
    `;
export const EditOptionDealersUpdateKitchenRoles = gql`
    mutation EditOptionDealersUpdateKitchenRoles($shopId: uuid!, $optionId: uuid!, $objects: [shopOptionKitchenRole_insert_input!]!) {
  delete_shopOptionKitchenRole(
    where: {shopId: {_eq: $shopId}, optionId: {_eq: $optionId}}
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_shopOptionKitchenRole(objects: $objects) {
    affected_rows
    returning {
      id
      optionId
      kitchenRoleId
      shopId
    }
  }
}
    `;
export type EditOptionDealersGetKitchenRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  optionId: Types.Scalars['uuid'];
}>;


export type EditOptionDealersGetKitchenRolesQuery = (
  { __typename?: 'query_root' }
  & { shop?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'id' | 'name'>
    )>, shopOptionKitchenRoles: Array<(
      { __typename?: 'shopOptionKitchenRole' }
      & Pick<Types.ShopOptionKitchenRole, 'id' | 'kitchenRoleId'>
    )> }
  )> }
);

export type EditOptionDealersUpdateKitchenRolesMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  optionId: Types.Scalars['uuid'];
  objects: Array<Types.ShopOptionKitchenRoleInsertInput> | Types.ShopOptionKitchenRoleInsertInput;
}>;


export type EditOptionDealersUpdateKitchenRolesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopOptionKitchenRoleMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopOptionKitchenRole' }
      & Pick<Types.ShopOptionKitchenRole, 'id'>
    )> }
  )>, insert_shopOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopOptionKitchenRoleMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopOptionKitchenRole' }
      & Pick<Types.ShopOptionKitchenRole, 'id' | 'optionId' | 'kitchenRoleId' | 'shopId'>
    )> }
  )> }
);


export const EditOptionDealersGetKitchenRolesDocument = gql`
    query EditOptionDealersGetKitchenRoles($shopId: uuid!, $optionId: uuid!) {
  shop: shop_by_pk(shopId: $shopId) {
    shopId
    name
    roles {
      id
      name
    }
    shopOptionKitchenRoles(where: {optionId: {_eq: $optionId}}) {
      id
      kitchenRoleId
    }
  }
}
    `;

/**
 * __useEditOptionDealersGetKitchenRolesQuery__
 *
 * To run a query within a React component, call `useEditOptionDealersGetKitchenRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOptionDealersGetKitchenRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOptionDealersGetKitchenRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useEditOptionDealersGetKitchenRolesQuery(baseOptions: Apollo.QueryHookOptions<EditOptionDealersGetKitchenRolesQuery, EditOptionDealersGetKitchenRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOptionDealersGetKitchenRolesQuery, EditOptionDealersGetKitchenRolesQueryVariables>(EditOptionDealersGetKitchenRolesDocument, options);
      }
export function useEditOptionDealersGetKitchenRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOptionDealersGetKitchenRolesQuery, EditOptionDealersGetKitchenRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOptionDealersGetKitchenRolesQuery, EditOptionDealersGetKitchenRolesQueryVariables>(EditOptionDealersGetKitchenRolesDocument, options);
        }
export type EditOptionDealersGetKitchenRolesQueryHookResult = ReturnType<typeof useEditOptionDealersGetKitchenRolesQuery>;
export type EditOptionDealersGetKitchenRolesLazyQueryHookResult = ReturnType<typeof useEditOptionDealersGetKitchenRolesLazyQuery>;
export type EditOptionDealersGetKitchenRolesQueryResult = Apollo.QueryResult<EditOptionDealersGetKitchenRolesQuery, EditOptionDealersGetKitchenRolesQueryVariables>;
export const EditOptionDealersUpdateKitchenRolesDocument = gql`
    mutation EditOptionDealersUpdateKitchenRoles($shopId: uuid!, $optionId: uuid!, $objects: [shopOptionKitchenRole_insert_input!]!) {
  delete_shopOptionKitchenRole(
    where: {shopId: {_eq: $shopId}, optionId: {_eq: $optionId}}
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_shopOptionKitchenRole(objects: $objects) {
    affected_rows
    returning {
      id
      optionId
      kitchenRoleId
      shopId
    }
  }
}
    `;
export type EditOptionDealersUpdateKitchenRolesMutationFn = Apollo.MutationFunction<EditOptionDealersUpdateKitchenRolesMutation, EditOptionDealersUpdateKitchenRolesMutationVariables>;

/**
 * __useEditOptionDealersUpdateKitchenRolesMutation__
 *
 * To run a mutation, you first call `useEditOptionDealersUpdateKitchenRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOptionDealersUpdateKitchenRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOptionDealersUpdateKitchenRolesMutation, { data, loading, error }] = useEditOptionDealersUpdateKitchenRolesMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      optionId: // value for 'optionId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useEditOptionDealersUpdateKitchenRolesMutation(baseOptions?: Apollo.MutationHookOptions<EditOptionDealersUpdateKitchenRolesMutation, EditOptionDealersUpdateKitchenRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOptionDealersUpdateKitchenRolesMutation, EditOptionDealersUpdateKitchenRolesMutationVariables>(EditOptionDealersUpdateKitchenRolesDocument, options);
      }
export type EditOptionDealersUpdateKitchenRolesMutationHookResult = ReturnType<typeof useEditOptionDealersUpdateKitchenRolesMutation>;
export type EditOptionDealersUpdateKitchenRolesMutationResult = Apollo.MutationResult<EditOptionDealersUpdateKitchenRolesMutation>;
export type EditOptionDealersUpdateKitchenRolesMutationOptions = Apollo.BaseMutationOptions<EditOptionDealersUpdateKitchenRolesMutation, EditOptionDealersUpdateKitchenRolesMutationVariables>;