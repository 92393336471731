import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";

import { FormHelp } from "components/Form/FormHelp";
import { InputCostPrice } from "components/Input/InputCostPrice";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const CostPriceField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    label={<FormHelp label="原価" help="原価は小数点以下二桁まで設定できます" />}
    name="costPrice"
    // NOTE: インシデントの一次対応としてオプショナルにする
    // https://dinii.slack.com/archives/C0333NCAAVC/p1661481099482809
    // rules={[{ required: true, message: "原価を入力してください" }]}
    {...props}
  >
    <InputCostPrice step={0.01} precision={2} disabled={disabled} />
  </EditMenuFormItem>
));
