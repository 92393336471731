import React, { memo } from "react";
import { Tabs } from "antd";
import { FormInstance } from "antd/es/form";
import { Form } from "antd/lib";

import { TranslationField } from "./EditPlanOptionForm/TranslationField";
import {
  EditPlanOptionFormValues,
  useEditPlanOptionForm,
} from "./EditPlanOptionForm/useEditPlanOptionForm";
import { EditPlanOptionForm } from "./EditPlanOptionForm";
import { PlanOption, Translation } from "./types";

type Props = {
  form: FormInstance;
  planOption: PlanOption;
  translations: Translation[];
  onChange: (planOption: EditPlanOptionFormValues) => void;
  disabled: boolean;
};

const tabKey = {
  general: "1",
  translation: "2",
};

export const EditPlanOptionModalForm = memo<Props>(
  ({ form, planOption, translations, onChange, disabled }) => {
    const { initialValues, change: onValuesChange } = useEditPlanOptionForm(
      planOption,
      translations,
      onChange,
    );

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Tabs defaultActiveKey={tabKey.general}>
          <Tabs.TabPane tab="基本情報" key={tabKey.general}>
            <EditPlanOptionForm disabled={disabled} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="多言語設定" key={tabKey.translation}>
            <TranslationField disabled={disabled} />
          </Tabs.TabPane>
        </Tabs>
      </Form>
    );
  },
);
