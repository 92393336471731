import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";

import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { MessageDeliveryJob } from "../types";

const StyledCheckCircleFilled = styled(CheckCircleFilled)`
  color: #52c41a;
`;

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
  color: #ff4d4f;
`;

type Props = {
  loading?: boolean;
  messageDeliveryJobs: MessageDeliveryJob[];
};

export const MessageDeliveryJobTable = memo<Props>(({ loading, messageDeliveryJobs }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<MessageDeliveryJob> = [
    {
      title: "",
      align: "center",
      width: 48,
      render(_: unknown, { messageDeliveryShopJobs }: MessageDeliveryJob) {
        return messageDeliveryShopJobs.some((messageDeliveryShopJob) =>
          Boolean(messageDeliveryShopJob.errorType),
        ) ? (
          <StyledCloseCircleFilled />
        ) : (
          <StyledCheckCircleFilled />
        );
      },
    },
    {
      title: "配信管理名",
      dataIndex: "name",
      render(_: unknown, { messageDelivery, id }: MessageDeliveryJob) {
        if (!messageDelivery) return null;

        return canAccess("messageDeliveryJob") ? (
          <Link to={`/messageDelivery/job/${id}`}>
            <Button type="link">{messageDelivery.name}</Button>
          </Link>
        ) : (
          messageDelivery.name
        );
      },
    },
    {
      title: "配信日時",
      align: "center" as const,
      width: 200,
      render(_: unknown, { executedAt }: MessageDeliveryJob) {
        return dayjs(executedAt).format("YYYY/MM/DD HH:mm");
      },
    },
    {
      title: "配信対象数",
      align: "right" as const,
      width: 100,
      render(_: unknown, { targetLineMessagingCount }: MessageDeliveryJob) {
        return targetLineMessagingCount;
      },
    },
    {
      title: "配信成功数",
      align: "right" as const,
      width: 100,
      render(_: unknown, { succeededLineMessagingCount }: MessageDeliveryJob) {
        return succeededLineMessagingCount;
      },
    },
    {
      title: "配信失敗数",
      align: "right" as const,
      width: 100,
      render(
        _: unknown,
        { targetLineMessagingCount, succeededLineMessagingCount }: MessageDeliveryJob,
      ) {
        return targetLineMessagingCount - succeededLineMessagingCount;
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={messageDeliveryJobs}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
