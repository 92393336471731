import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRoles = gql`
    query EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRoles($shopId: uuid!, $kdDisplayTargetId: uuid!) {
  kdDisplayTarget_by_pk(id: $kdDisplayTargetId) {
    id
    name
    roleId: _roleId
  }
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;
export const EditKitchenDisplayDisplayTargetUpdateKdDisplayTarget = gql`
    mutation EditKitchenDisplayDisplayTargetUpdateKdDisplayTarget($kdDisplayTargetId: uuid!, $kdDisplayTarget: kdDisplayTarget_set_input!) {
  update_kdDisplayTarget(
    _set: $kdDisplayTarget
    where: {id: {_eq: $kdDisplayTargetId}}
  ) {
    affected_rows
  }
}
    `;
export type EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  kdDisplayTargetId: Types.Scalars['uuid'];
}>;


export type EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery = (
  { __typename?: 'query_root' }
  & { kdDisplayTarget_by_pk?: Types.Maybe<(
    { __typename?: 'kdDisplayTarget' }
    & Pick<Types.KdDisplayTarget, 'id' | 'name'>
    & { roleId: Types.KdDisplayTarget['_roleId'] }
  )>, role: Array<(
    { __typename?: 'role' }
    & Pick<Types.Role, 'id' | 'name' | 'roleId'>
  )> }
);

export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationVariables = Types.Exact<{
  kdDisplayTargetId: Types.Scalars['uuid'];
  kdDisplayTarget: Types.KdDisplayTargetSetInput;
}>;


export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation = (
  { __typename?: 'mutation_root' }
  & { update_kdDisplayTarget?: Types.Maybe<(
    { __typename?: 'kdDisplayTarget_mutation_response' }
    & Pick<Types.KdDisplayTargetMutationResponse, 'affected_rows'>
  )> }
);


export const EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesDocument = gql`
    query EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRoles($shopId: uuid!, $kdDisplayTargetId: uuid!) {
  kdDisplayTarget_by_pk(id: $kdDisplayTargetId) {
    id
    name
    roleId: _roleId
  }
  role(where: {shopId: {_eq: $shopId}}) {
    id
    name
    roleId
  }
}
    `;

/**
 * __useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery__
 *
 * To run a query within a React component, call `useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *   },
 * });
 */
export function useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery(baseOptions: Apollo.QueryHookOptions<EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery, EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery, EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables>(EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesDocument, options);
      }
export function useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery, EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery, EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables>(EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesDocument, options);
        }
export type EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryHookResult = ReturnType<typeof useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery>;
export type EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesLazyQueryHookResult = ReturnType<typeof useEditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesLazyQuery>;
export type EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryResult = Apollo.QueryResult<EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQuery, EditKitchenDisplayDisplayTargetGetKdDisplayTargetAndRolesQueryVariables>;
export const EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetDocument = gql`
    mutation EditKitchenDisplayDisplayTargetUpdateKdDisplayTarget($kdDisplayTargetId: uuid!, $kdDisplayTarget: kdDisplayTarget_set_input!) {
  update_kdDisplayTarget(
    _set: $kdDisplayTarget
    where: {id: {_eq: $kdDisplayTargetId}}
  ) {
    affected_rows
  }
}
    `;
export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationFn = Apollo.MutationFunction<EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation, EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationVariables>;

/**
 * __useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation__
 *
 * To run a mutation, you first call `useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation, { data, loading, error }] = useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation({
 *   variables: {
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *      kdDisplayTarget: // value for 'kdDisplayTarget'
 *   },
 * });
 */
export function useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation(baseOptions?: Apollo.MutationHookOptions<EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation, EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation, EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationVariables>(EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetDocument, options);
      }
export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationHookResult = ReturnType<typeof useEditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation>;
export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationResult = Apollo.MutationResult<EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation>;
export type EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationOptions = Apollo.BaseMutationOptions<EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutation, EditKitchenDisplayDisplayTargetUpdateKdDisplayTargetMutationVariables>;