import React, { memo, useCallback } from "react";
import { Button, Col, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { DashboardAccountUpsertTecAggregationMenuInput } from "types/graphql";

import { PlanChoice } from "../../types";

import { NameField } from "./NameField";
import { TecAggregationDpCodeField } from "./TecAggregationDpCodeField";
import { TecAggregationDpNameField } from "./TecAggregationDpNameField";
import { TecAggregationGpCodeField } from "./TecAggregationGpCodeField";
import { TecAggregationGpNameField } from "./TecAggregationGpNameField";
import { TecAggregationMenuCodeField } from "./TecAggregationMenuCodeField";
import { useEditPlanChoiceTecAggregationMenuForm } from "./useEditPlanChoiceTecAggregationMenuForm";

type Props = {
  planChoice: PlanChoice;
  onSubmit: (
    input: Pick<
      DashboardAccountUpsertTecAggregationMenuInput,
      "dpCode" | "dpName" | "gpCode" | "gpName" | "name" | "menuCode"
    >,
  ) => void;
  onClose: () => void;
  adoptMenuCodeAutomatically: () => string;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditPlanChoiceTecAggregationMenuForm = memo<Props>(
  ({
    planChoice,
    onClose,
    onSubmit,
    adoptMenuCodeAutomatically,
    loading,
    onFormValidationError,
  }) => {
    const tecAggregationMenu = planChoice?.tecAggregationMenu ?? undefined;

    const { form, initialValues, submit } = useEditPlanChoiceTecAggregationMenuForm({
      planChoice,
      tecAggregationMenu,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    const handleAutoAdoptMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = adoptMenuCodeAutomatically();
      form.setFields([{ name: "menuCode", value: maxMenuCode }]);
    }, [adoptMenuCodeAutomatically, form]);

    return (
      <Form name="tecAggregationMenu" form={form} layout="vertical" initialValues={initialValues}>
        <Row>
          <Col span={24}>
            <TecAggregationMenuCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <NameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TecAggregationDpCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TecAggregationDpNameField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TecAggregationGpCodeField />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TecAggregationGpNameField />
          </Col>
        </Row>

        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            保存
          </Button>
        </FormActions>
      </Form>
    );
  },
);
