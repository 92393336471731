import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";

import { RouteName } from "components/Router";
import { useCanAccess } from "hooks/useCanAccess";

const StyledLink = styled(Link)`
  position: absolute;
  inset: 0;
`;

type Props = {
  route: RouteName;
  to: string;
  icon?: React.ReactElement;
  text: string;
  canShow?: boolean;
} & Omit<MenuItemProps, "children">;

export const MenuItem: React.VFC<Props> = ({ route, to, icon, text, canShow = true, ...props }) => {
  const { canAccess } = useCanAccess();

  if (!canAccess(route)) return null;

  if (!canShow) return null;

  return (
    <Menu.Item key={route} {...props}>
      {icon}
      <span>{text}</span>
      <StyledLink to={to} />
    </Menu.Item>
  );
};
