import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Checkbox, Modal, TreeSelect } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { DownloadOutlined } from "@ant-design/icons";
import { isArray } from "lodash";

import { Spacer } from "components/Spacer";

import { Company } from "../types";

const StyledModal = styled(Modal)`
  width: 720px;
  height: 298px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TitleText = styled.text`
  size: 16px;
  font-weight: bold;
`;

const StyledText = styled.text<{ isBold: boolean }>`
  size: 14px;
  font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
`;

const ShopFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
`;

type Props = {
  isOpen: boolean;
  companies: Company[];
  onDownload: (args: { shopIds: string[] }) => void;
  onClose: () => void;
};

export const CsvExportModal = memo<Props>(({ companies, isOpen, onDownload, onClose }) => {
  const shopIds = useMemo(
    () =>
      companies
        .filter(({ shops }) => shops.length > 0)
        .flatMap(({ shops }) => shops.map(({ shopId }) => shopId)),
    [companies],
  );
  const [selectedShopIds, setSelectedShopIds] = useState<string[]>(shopIds);

  useEffect(() => {
    setSelectedShopIds(shopIds);
  }, [shopIds]);

  const shopTreeData = useMemo(
    () =>
      companies
        .filter(({ shops }) => shops.length > 0)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          key: company.id,
          value: company.id,
          title: company.name,
          selectable: true,
          children: company.shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop) => ({
              key: shop.shopId,
              value: shop.shopId,
              title: shop.name,
              selectable: true,
            })),
        })),
    [companies],
  );

  const handleShopsSelect = useCallback((shopIds: unknown) => {
    if (isArray(shopIds)) {
      setSelectedShopIds(shopIds);
    }
  }, []);

  const handleCheckAll = useCallback(
    (event: CheckboxChangeEvent) => {
      const checked = event.target.checked;

      if (checked) {
        setSelectedShopIds(shopIds);
      } else {
        setSelectedShopIds([]);
      }
    },
    [shopIds],
  );

  return (
    <StyledModal
      open={isOpen}
      width={720}
      okText="ダウンロードする"
      cancelText="閉じる"
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>閉じる</Button>
          <Button
            onClick={() => onDownload({ shopIds: selectedShopIds })}
            type="primary"
            icon={<DownloadOutlined />}
            disabled={selectedShopIds.length === 0}
          >
            ダウンロード
          </Button>
        </>
      }
    >
      <Container>
        <TitleText>CSVダウンロード</TitleText>

        <Spacer height={8} />

        <StyledText isBold={false}>表示している項目をそのままCSVで出力します。</StyledText>

        <Spacer height={16} />

        <ShopFilterContainer>
          <StyledText isBold>出力する店舗</StyledText>

          <Spacer width={20} />

          <Checkbox defaultChecked onChange={handleCheckAll}>
            全選択
          </Checkbox>
        </ShopFilterContainer>

        <Spacer height={8} />

        <StyledTreeSelect
          placeholder="店舗を選択"
          treeData={shopTreeData}
          treeDefaultExpandAll
          treeCheckable
          value={selectedShopIds}
          defaultValue={shopIds}
          onChange={handleShopsSelect}
          maxTagCount={5}
          treeNodeFilterProp="title"
        />

        <Spacer height={8} />
      </Container>
    </StyledModal>
  );
});
