import { useCallback, useState } from "react";
import constate from "constate";

export type CurrentUser = {
  uid: string;
  role: string;
  email?: string | null;
};

const __useUser = () => {
  const [user, setUser] = useState<CurrentUser | null | undefined>(undefined);
  const isInitialized = user !== undefined;
  const setCurrentUser = useCallback((user: CurrentUser | null) => setUser(user), []);

  return { isInitialized, user, setCurrentUser };
};

export const [UserContext, useUser] = constate(__useUser);

export const useSetCurrentUser = () => {
  const { setCurrentUser } = useUser();
  return { setCurrentUser };
};

export const useCurrentUser = () => useUser().user;
