import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { AddCategoryForm } from "pages/AddCategory/AddCategoryForm";
import {
  useAddCategoryGetCategoryMaxPriorityQuery,
  useAddCategoryInsertCategoryMutation,
} from "pages/AddCategory/queries";
import { CreateCategoryInputCategorySource } from "types/graphql";

export const AddCategory = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const navigate = useNavigate();
  const [addCategoryMutation, { loading: loadingInsertCategory }] =
    useAddCategoryInsertCategoryMutation();

  const { data: categoryMaxPriorityData, refetch: refetchMaxPriority } =
    useAddCategoryGetCategoryMaxPriorityQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const categoryMaxPriority =
    categoryMaxPriorityData?.category_aggregate.aggregate?.max?.priority ?? 0;

  const onSubmit = useCallback(
    async ({
      imageUrl,
      name,
      shopSideBackgroundColor,
      shopSideName,
      createTranslationSource,
    }: Omit<CreateCategoryInputCategorySource, "priority">) => {
      try {
        if (!companyId || !name || !shopSideBackgroundColor) return;

        const { data: addCategoryData } = await addCategoryMutation({
          variables: {
            category: {
              imageUrl,
              name,
              shopSideBackgroundColor,
              shopSideName,
              companyId,
              priority: categoryMaxPriority + 1,
              createTranslationSource,
            },
          },
        });

        await refetchMaxPriority();

        const categoryId = addCategoryData?.createCategory.categoryId;

        if (categoryId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/category/${categoryId}/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [companyId, categoryMaxPriority, addCategoryMutation, navigate, refetchMaxPriority],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="カテゴリを新規作成"
      locationBreadcrumb={{
        // TODO: ID 統合完了後に false にする
        showShop: true,
        items: [{ name: "カテゴリ" }],
      }}
    >
      <PageHeader title="カテゴリを新規作成" onBack={goBack} />
      <AddCategoryForm
        onSubmit={onSubmit}
        onClose={goBack}
        loading={loadingInsertCategory}
        onFormValidationError={onFormValidationError}
      />
    </DashboardLayout>
  );
};
