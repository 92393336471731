import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiMenuOptionsEditChoiceModalGetChoice = gql`
    query PikaichiMenuOptionsEditChoiceModalGetChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}, option: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    choiceId
    price
    name
    option {
      companyId
    }
    pikaichiMenuChoices {
      pikaichiMenuId
      choiceId: _choiceId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;
export const PikaichiMenuOptionsUpdatePikaichiMenu = gql`
    mutation PikaichiMenuOptionsUpdatePikaichiMenu($choiceId: uuid!, $_choiceId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuChoice_one(
    object: {pikaichiMenuId: $pikaichiMenuId, choiceId: $choiceId, _choiceId: $_choiceId}
    on_conflict: {constraint: pikaichiMenuChoice_pikaichiMenuId_choiceId_key, update_columns: []}
  ) {
    pikaichiMenuId
    choiceId
    _choiceId
  }
}
    `;
export type PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables = Types.Exact<{
  choiceId: Types.Scalars['Int'];
}>;


export type PikaichiMenuOptionsEditChoiceModalGetChoiceQuery = (
  { __typename?: 'query_root' }
  & { choice: Array<(
    { __typename?: 'choice' }
    & Pick<Types.Choice, 'id' | 'choiceId' | 'price' | 'name'>
    & { option: (
      { __typename?: 'option' }
      & Pick<Types.Option, 'companyId'>
    ), pikaichiMenuChoices: Array<(
      { __typename?: 'pikaichiMenuChoice' }
      & Pick<Types.PikaichiMenuChoice, 'pikaichiMenuId'>
      & { choiceId: Types.PikaichiMenuChoice['_choiceId'] }
      & { pikaichiMenu: (
        { __typename?: 'pikaichiMenu' }
        & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
      ) }
    )> }
  )> }
);

export type PikaichiMenuOptionsUpdatePikaichiMenuMutationVariables = Types.Exact<{
  choiceId: Types.Scalars['uuid'];
  _choiceId: Types.Scalars['Int'];
  pikaichiMenuId: Types.Scalars['uuid'];
  pikaichiMenu: Types.PikaichiMenuInsertInput;
}>;


export type PikaichiMenuOptionsUpdatePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pikaichiMenu_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )>, insert_pikaichiMenuChoice_one?: Types.Maybe<(
    { __typename?: 'pikaichiMenuChoice' }
    & Pick<Types.PikaichiMenuChoice, 'pikaichiMenuId' | 'choiceId' | '_choiceId'>
  )> }
);


export const PikaichiMenuOptionsEditChoiceModalGetChoiceDocument = gql`
    query PikaichiMenuOptionsEditChoiceModalGetChoice($choiceId: Int!) {
  choice(
    where: {choiceId: {_eq: $choiceId}, archivedAt: {_is_null: true}, option: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    choiceId
    price
    name
    option {
      companyId
    }
    pikaichiMenuChoices {
      pikaichiMenuId
      choiceId: _choiceId
      pikaichiMenu {
        pikaichiMenuId
        pikaichiMenuCd
        pikaichiMenuName
        pikaichiBumonCd
        pikaichiBumonName
        pikaichiCategoryCd
        pikaichiCategoryName
      }
    }
  }
}
    `;

/**
 * __usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery__
 *
 * To run a query within a React component, call `usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *   },
 * });
 */
export function usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery(baseOptions: Apollo.QueryHookOptions<PikaichiMenuOptionsEditChoiceModalGetChoiceQuery, PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiMenuOptionsEditChoiceModalGetChoiceQuery, PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables>(PikaichiMenuOptionsEditChoiceModalGetChoiceDocument, options);
      }
export function usePikaichiMenuOptionsEditChoiceModalGetChoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiMenuOptionsEditChoiceModalGetChoiceQuery, PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiMenuOptionsEditChoiceModalGetChoiceQuery, PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables>(PikaichiMenuOptionsEditChoiceModalGetChoiceDocument, options);
        }
export type PikaichiMenuOptionsEditChoiceModalGetChoiceQueryHookResult = ReturnType<typeof usePikaichiMenuOptionsEditChoiceModalGetChoiceQuery>;
export type PikaichiMenuOptionsEditChoiceModalGetChoiceLazyQueryHookResult = ReturnType<typeof usePikaichiMenuOptionsEditChoiceModalGetChoiceLazyQuery>;
export type PikaichiMenuOptionsEditChoiceModalGetChoiceQueryResult = Apollo.QueryResult<PikaichiMenuOptionsEditChoiceModalGetChoiceQuery, PikaichiMenuOptionsEditChoiceModalGetChoiceQueryVariables>;
export const PikaichiMenuOptionsUpdatePikaichiMenuDocument = gql`
    mutation PikaichiMenuOptionsUpdatePikaichiMenu($choiceId: uuid!, $_choiceId: Int!, $pikaichiMenuId: uuid!, $pikaichiMenu: pikaichiMenu_insert_input!) {
  insert_pikaichiMenu_one(
    object: $pikaichiMenu
    on_conflict: {constraint: pikaichiMenu_pkey, update_columns: [pikaichiMenuCd, pikaichiMenuName, pikaichiCategoryCd, pikaichiCategoryName, pikaichiBumonCd, pikaichiBumonName]}
  ) {
    pikaichiMenuId
  }
  insert_pikaichiMenuChoice_one(
    object: {pikaichiMenuId: $pikaichiMenuId, choiceId: $choiceId, _choiceId: $_choiceId}
    on_conflict: {constraint: pikaichiMenuChoice_pikaichiMenuId_choiceId_key, update_columns: []}
  ) {
    pikaichiMenuId
    choiceId
    _choiceId
  }
}
    `;
export type PikaichiMenuOptionsUpdatePikaichiMenuMutationFn = Apollo.MutationFunction<PikaichiMenuOptionsUpdatePikaichiMenuMutation, PikaichiMenuOptionsUpdatePikaichiMenuMutationVariables>;

/**
 * __usePikaichiMenuOptionsUpdatePikaichiMenuMutation__
 *
 * To run a mutation, you first call `usePikaichiMenuOptionsUpdatePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenuOptionsUpdatePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pikaichiMenuOptionsUpdatePikaichiMenuMutation, { data, loading, error }] = usePikaichiMenuOptionsUpdatePikaichiMenuMutation({
 *   variables: {
 *      choiceId: // value for 'choiceId'
 *      _choiceId: // value for '_choiceId'
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *      pikaichiMenu: // value for 'pikaichiMenu'
 *   },
 * });
 */
export function usePikaichiMenuOptionsUpdatePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<PikaichiMenuOptionsUpdatePikaichiMenuMutation, PikaichiMenuOptionsUpdatePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PikaichiMenuOptionsUpdatePikaichiMenuMutation, PikaichiMenuOptionsUpdatePikaichiMenuMutationVariables>(PikaichiMenuOptionsUpdatePikaichiMenuDocument, options);
      }
export type PikaichiMenuOptionsUpdatePikaichiMenuMutationHookResult = ReturnType<typeof usePikaichiMenuOptionsUpdatePikaichiMenuMutation>;
export type PikaichiMenuOptionsUpdatePikaichiMenuMutationResult = Apollo.MutationResult<PikaichiMenuOptionsUpdatePikaichiMenuMutation>;
export type PikaichiMenuOptionsUpdatePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<PikaichiMenuOptionsUpdatePikaichiMenuMutation, PikaichiMenuOptionsUpdatePikaichiMenuMutationVariables>;