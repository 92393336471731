import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { Alert } from "antd";
import { uniq } from "lodash";
import { sortBusinessOperationHourTypes } from "models/businessOperationHour";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isNotNull } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useMessageDeliveryFormGetLineOfficialAccountsQuery } from "components/MessageDeliveryForm/MessagePreview/queries";
import { useCompany } from "hooks/useCompany";
import { useQueryParams } from "hooks/useQuery";
import { AddMessageDeliveryForm } from "pages/AddMessageDelivery/AddMessageDeliveryForm";
import {
  useAddMessageDeliveryGetCouponsQuery,
  useAddMessageDeliveryGetCustomersByIdsLazyQuery,
  useAddMessageDeliveryGetCustomersByLikeNameLazyQuery,
  useAddMessageDeliveryGetMenusQuery,
  useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery,
  useAddMessageDeliveryGetShopsQuery,
  useAddMessageDeliveryInsertMessageDeliveryMutation,
  useCopyMessageDeliveryGetMessageDeliveryQuery,
} from "pages/AddMessageDelivery/queries";
import { useEditMessageDeliveryGetQuestionnaireConfigsQuery } from "pages/EditMessageDelivery/queries";
import { CreateMessageDeliveryInput } from "types/graphql";

export const AddMessageDelivery = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;

  const query = useQueryParams();
  const sourceMessageDeliveryId = query.get("id");

  const { data: sourceMessageDeliveryData, loading: loadingSourceMessageDelivery } =
    useCopyMessageDeliveryGetMessageDeliveryQuery(
      sourceMessageDeliveryId
        ? {
            variables: { id: sourceMessageDeliveryId },
          }
        : { skip: true },
    );
  const sourceMessageDelivery = useMemo(
    () => sourceMessageDeliveryData?.messageDelivery_by_pk ?? undefined,
    [sourceMessageDeliveryData?.messageDelivery_by_pk],
  );

  const {
    data: getCouponsData,
    loading: loadingCouponsData,
    error: getCouponsError,
  } = useAddMessageDeliveryGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = useMemo(() => getCouponsData?.coupon ?? [], [getCouponsData?.coupon]);

  const {
    data: getQuestionnaireConfigsData,
    loading: loadingQuestionnaireConfig,
    error: getQuestionnaireConfigsError,
  } = useEditMessageDeliveryGetQuestionnaireConfigsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const questionnaireConfigs = getQuestionnaireConfigsData?.questionnaireConfig ?? [];

  const { data: getShopsData } = useAddMessageDeliveryGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = useMemo(() => getShopsData?.shop ?? [], [getShopsData?.shop]);

  const { data: getShopBusinessOperationHourTypesData } =
    useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const shopBusinessOperationHourTypes = sortBusinessOperationHourTypes(
    uniq(
      getShopBusinessOperationHourTypesData?.shopBusinessOperationHour.map(
        ({ businessOperationHourType }) => businessOperationHourType,
      ) ?? [],
    ),
  );

  const { data: getMenusData } = useAddMessageDeliveryGetMenusQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const menus = useMemo(() => getMenusData?.menu ?? [], [getMenusData?.menu]);

  const [getCustomersByLikeNameQuery] = useAddMessageDeliveryGetCustomersByLikeNameLazyQuery();

  const getCustomersByLikeName = useCallback(
    async ({ likeName }: { likeName: string }) => {
      if (!companyId) return [];
      if (likeName.length === 0) return [];

      const res = await getCustomersByLikeNameQuery({
        variables: { companyId, likeName: `%${likeName}%` },
      });
      return res.data?.customer ?? [];
    },
    [companyId, getCustomersByLikeNameQuery],
  );

  const [getCustomersByCustomerIdsQuery] = useAddMessageDeliveryGetCustomersByIdsLazyQuery();

  const getCustomersByCustomerIds = useCallback(
    async ({ customerIds }: { customerIds: string[] }) => {
      if (!companyId) return [];
      if (customerIds.length === 0) return [];

      const res = await getCustomersByCustomerIdsQuery({
        variables: { companyId, customerIds },
      });
      return res.data?.customer ?? [];
    },
    [companyId, getCustomersByCustomerIdsQuery],
  );

  const {
    data: getLineOfficialAccounts,
    loading: loadingLineOfficialAccounts,
    error: getLineOfficialAccountsError,
  } = useMessageDeliveryFormGetLineOfficialAccountsQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );
  const lineOfficialAccounts = useMemo(
    () =>
      [...(getLineOfficialAccounts?.lineOfficialAccounts ?? [])].sort((a, b) =>
        a.channelName > b.channelName ? 1 : -1,
      ),
    [getLineOfficialAccounts?.lineOfficialAccounts],
  );

  const [addMessageDeliveryMutation] = useAddMessageDeliveryInsertMessageDeliveryMutation();

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({ messageDelivery }: { messageDelivery: CreateMessageDeliveryInput }) => {
      if (!companyId) return;

      // 画像形式 && 画像が登録されていないメッセージが存在する場合エラーを表示する
      // TODO: ImageField でバリデーションしたい
      const imageEmptyMessageIndices = messageDelivery.messageDeliveryMessages
        .map((message, index) =>
          message.imageTypeMeta && !message.imageTypeMeta.imageUrl ? index + 1 : null,
        )
        .filter(isNotNull);
      if (imageEmptyMessageIndices.length > 0) {
        return message.error(
          `${imageEmptyMessageIndices.join(",")}番目のメッセージに画像を登録してください`,
        );
      }

      try {
        await addMessageDeliveryMutation({ variables: { messageDelivery } });

        message.success("作成しました");

        const isDraft = messageDelivery.isDraft;

        navigate(`/messageDelivery/${isDraft ? "draft" : "reserved"}`, { replace: true });
      } catch {
        message.error("作成に失敗しました");
      }
    },
    [addMessageDeliveryMutation, companyId, navigate],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingCouponsData ||
    loadingQuestionnaireConfig ||
    loadingLineOfficialAccounts ||
    loadingSourceMessageDelivery;

  const error = getCouponsError || getQuestionnaireConfigsError || getLineOfficialAccountsError;

  return (
    <DashboardLayout
      title="メッセージを新規作成する"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "メッセージ配信" }],
      }}
    >
      <PageHeader title="メッセージを新規作成する" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {loading ? (
        <Loading height={300} />
      ) : (
        <AddMessageDeliveryForm
          sourceMessageDelivery={sourceMessageDelivery}
          loading={loading}
          submitting={submitting}
          coupons={coupons}
          shops={shops}
          menus={menus}
          questionnaireConfigs={questionnaireConfigs}
          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
          lineOfficialAccounts={lineOfficialAccounts}
          onSubmit={onSubmit}
          onClose={goBack}
          onSearchCustomersByCustomerIds={getCustomersByCustomerIds}
          onSearchCustomersByLikeName={getCustomersByLikeName}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
