import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";

import { PlanChoice } from "../../types";

type Props = {
  loading?: boolean;
  planId: number;
  planOptionId: number;
  planChoices: PlanChoice[];
};

const getPikaichiMenuFromChoice = (choice: PlanChoice) =>
  choice.pikaichiMenuPlanChoices[0]?.pikaichiMenu ?? null;

export const PlanChoiceTable = memo<Props>(({ loading, planId, planOptionId, planChoices }) => {
  const columns: ColumnsType<PlanChoice> = [
    { title: "選択肢名", dataIndex: "name", key: "name", fixed: "left", width: 150 },
    {
      title: "メニューコード",
      align: "left",
      width: 130,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiMenuCd;
      },
    },
    {
      title: "メニュー名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiMenuName;
      },
    },
    {
      title: "部門コード",
      align: "left",
      width: 100,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiBumonCd;
      },
    },
    {
      title: "部門名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiBumonName;
      },
    },
    {
      title: "分類コード",
      align: "left",
      width: 100,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiCategoryCd;
      },
    },
    {
      title: "分類名",
      align: "left",
      width: 150,
      render(_: string, choice: PlanChoice) {
        const pikaichiMenu = getPikaichiMenuFromChoice(choice);
        return pikaichiMenu?.pikaichiCategoryName;
      },
    },
    {
      title: "",
      width: 60,
      align: "center",
      fixed: "right",
      render(_: string, { planChoiceId }: PlanChoice) {
        return (
          <IconLink
            to={`/pikaichi/plan/${planId}/option/${planOptionId}/choice/${planChoiceId}/edit`}
          >
            <EditIcon />
          </IconLink>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="planChoiceId"
      columns={columns}
      dataSource={planChoices}
      loading={loading}
      bordered
      pagination={false}
    />
  );
});
