import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DisableClerkCacheField = memo<Props>(({ ...props }) => (
  <EditCashRegisterConfigFormItem
    label={
      <FormHelp
        label="会計時の担当者を毎回選択する"
        help="毎回選択しない場合は前回の会計担当者がデフォルト値として入力されます"
      />
    }
    name="disableClerkCache"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditCashRegisterConfigFormItem>
));
