import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuRecommendationGetMenuRecommendation = gql`
    query MenuRecommendationGetMenuRecommendation($companyId: uuid!) {
  menuRecommendationMeta(where: {companyId: {_eq: $companyId}}) {
    id
    name
    enabled
    category {
      categoryMenus(
        order_by: {priority: asc}
        where: {menu: {archivedAt: {_is_null: true}}}
      ) {
        menu {
          menuId
          name
        }
      }
    }
  }
}
    `;
export const MenuRecommendationUpdateMenuRecommendationEnabled = gql`
    mutation MenuRecommendationUpdateMenuRecommendationEnabled($id: uuid!, $enabled: Boolean!) {
  update_menuRecommendationMeta_by_pk(
    pk_columns: {id: $id}
    _set: {enabled: $enabled}
  ) {
    id
  }
}
    `;
export type MenuRecommendationGetMenuRecommendationQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenuRecommendationGetMenuRecommendationQuery = (
  { __typename?: 'query_root' }
  & { menuRecommendationMeta: Array<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id' | 'name' | 'enabled'>
    & { category: (
      { __typename?: 'category' }
      & { categoryMenus: Array<(
        { __typename?: 'categoryMenu' }
        & { menu: (
          { __typename?: 'menu' }
          & Pick<Types.Menu, 'menuId' | 'name'>
        ) }
      )> }
    ) }
  )> }
);

export type MenuRecommendationUpdateMenuRecommendationEnabledMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  enabled: Types.Scalars['Boolean'];
}>;


export type MenuRecommendationUpdateMenuRecommendationEnabledMutation = (
  { __typename?: 'mutation_root' }
  & { update_menuRecommendationMeta_by_pk?: Types.Maybe<(
    { __typename?: 'menuRecommendationMeta' }
    & Pick<Types.MenuRecommendationMeta, 'id'>
  )> }
);


export const MenuRecommendationGetMenuRecommendationDocument = gql`
    query MenuRecommendationGetMenuRecommendation($companyId: uuid!) {
  menuRecommendationMeta(where: {companyId: {_eq: $companyId}}) {
    id
    name
    enabled
    category {
      categoryMenus(
        order_by: {priority: asc}
        where: {menu: {archivedAt: {_is_null: true}}}
      ) {
        menu {
          menuId
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMenuRecommendationGetMenuRecommendationQuery__
 *
 * To run a query within a React component, call `useMenuRecommendationGetMenuRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuRecommendationGetMenuRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuRecommendationGetMenuRecommendationQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenuRecommendationGetMenuRecommendationQuery(baseOptions: Apollo.QueryHookOptions<MenuRecommendationGetMenuRecommendationQuery, MenuRecommendationGetMenuRecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuRecommendationGetMenuRecommendationQuery, MenuRecommendationGetMenuRecommendationQueryVariables>(MenuRecommendationGetMenuRecommendationDocument, options);
      }
export function useMenuRecommendationGetMenuRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuRecommendationGetMenuRecommendationQuery, MenuRecommendationGetMenuRecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuRecommendationGetMenuRecommendationQuery, MenuRecommendationGetMenuRecommendationQueryVariables>(MenuRecommendationGetMenuRecommendationDocument, options);
        }
export type MenuRecommendationGetMenuRecommendationQueryHookResult = ReturnType<typeof useMenuRecommendationGetMenuRecommendationQuery>;
export type MenuRecommendationGetMenuRecommendationLazyQueryHookResult = ReturnType<typeof useMenuRecommendationGetMenuRecommendationLazyQuery>;
export type MenuRecommendationGetMenuRecommendationQueryResult = Apollo.QueryResult<MenuRecommendationGetMenuRecommendationQuery, MenuRecommendationGetMenuRecommendationQueryVariables>;
export const MenuRecommendationUpdateMenuRecommendationEnabledDocument = gql`
    mutation MenuRecommendationUpdateMenuRecommendationEnabled($id: uuid!, $enabled: Boolean!) {
  update_menuRecommendationMeta_by_pk(
    pk_columns: {id: $id}
    _set: {enabled: $enabled}
  ) {
    id
  }
}
    `;
export type MenuRecommendationUpdateMenuRecommendationEnabledMutationFn = Apollo.MutationFunction<MenuRecommendationUpdateMenuRecommendationEnabledMutation, MenuRecommendationUpdateMenuRecommendationEnabledMutationVariables>;

/**
 * __useMenuRecommendationUpdateMenuRecommendationEnabledMutation__
 *
 * To run a mutation, you first call `useMenuRecommendationUpdateMenuRecommendationEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuRecommendationUpdateMenuRecommendationEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuRecommendationUpdateMenuRecommendationEnabledMutation, { data, loading, error }] = useMenuRecommendationUpdateMenuRecommendationEnabledMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useMenuRecommendationUpdateMenuRecommendationEnabledMutation(baseOptions?: Apollo.MutationHookOptions<MenuRecommendationUpdateMenuRecommendationEnabledMutation, MenuRecommendationUpdateMenuRecommendationEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MenuRecommendationUpdateMenuRecommendationEnabledMutation, MenuRecommendationUpdateMenuRecommendationEnabledMutationVariables>(MenuRecommendationUpdateMenuRecommendationEnabledDocument, options);
      }
export type MenuRecommendationUpdateMenuRecommendationEnabledMutationHookResult = ReturnType<typeof useMenuRecommendationUpdateMenuRecommendationEnabledMutation>;
export type MenuRecommendationUpdateMenuRecommendationEnabledMutationResult = Apollo.MutationResult<MenuRecommendationUpdateMenuRecommendationEnabledMutation>;
export type MenuRecommendationUpdateMenuRecommendationEnabledMutationOptions = Apollo.BaseMutationOptions<MenuRecommendationUpdateMenuRecommendationEnabledMutation, MenuRecommendationUpdateMenuRecommendationEnabledMutationVariables>;