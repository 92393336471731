import React from "react";
import { Button, Input, Select } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import {
  GenerateQrCodesFormItem,
  GenerateQrCodesFormValues,
  useGenerateQrCodesForm,
} from "pages/GenerateQrCodes/GenerateQrCodesForm/useGenerateQrCodesForm";
import { Shop } from "pages/GenerateQrCodes/types";

type Props = {
  shops: Shop[];
  canSubmit: boolean;
  onChange: (formValues: GenerateQrCodesFormValues) => void;
  onSubmit: (formValues: GenerateQrCodesFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const GenerateQrCodesForm = ({
  shops,
  canSubmit,
  onFormValidationError,
  onChange,
  onSubmit,
}: Props) => {
  const {
    form,
    initialValues,
    change: handleChange,
    submit: handleSubmit,
  } = useGenerateQrCodesForm(onChange, onSubmit, onFormValidationError);

  return (
    <>
      <FormContent>
        <Form
          name="qr"
          form={form}
          initialValues={initialValues}
          layout="vertical"
          onValuesChange={handleChange}
        >
          <GenerateQrCodesFormItem
            label="店舗"
            name="shopId"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Select
              disabled={shops.length === 0}
              showSearch
              options={shops.map((shop) => ({ label: shop.name, value: shop.shopId }))}
              optionFilterProp="label"
            />
          </GenerateQrCodesFormItem>
          <GenerateQrCodesFormItem
            label="QRコードのダウンロード先"
            name="zipPath"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Input placeholder="/Users/YourName/Downloads/" />
          </GenerateQrCodesFormItem>
          <GenerateQrCodesFormItem
            label="ファイル名"
            name="fileNamePrefix"
            rules={[{ required: true }]}
            wrapperCol={{ span: 8 }}
          >
            <Input placeholder="店舗を識別できるファイル名を指定してください" />
          </GenerateQrCodesFormItem>
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={handleSubmit} disabled={!canSubmit}>
          生成
        </Button>
      </FormActions>
    </>
  );
};
