import React, { memo } from "react";
import { parseNumber } from "util/input/parseNumber";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopMenuStockFormItem } from "../useEditShopMenuStockForm";

type Props = Omit<FormItemProps, "children" | "name">;

const parseCount = (value: string | undefined) =>
  parseNumber(value, (value) => value.replace("個", ""));

export const DailyStockNumField = memo<Props>((props) => (
  <EditShopMenuStockFormItem.NonProperty
    label="日次在庫数"
    shouldUpdate={withFormDependencies(({ noDailyStockNum }) => [noDailyStockNum])}
    {...props}
  >
    {({ getFieldValue }) => {
      const noDailyStockNum: boolean = getFieldValue("noDailyStockNum");

      return (
        <EditShopMenuStockFormItem name="dailyStockNum" noStyle>
          <InputNumber
            formatter={(value) => `${value}個`}
            parser={parseCount}
            min={0}
            disabled={noDailyStockNum}
          />
        </EditShopMenuStockFormItem>
      );
    }}
  </EditShopMenuStockFormItem.NonProperty>
));
