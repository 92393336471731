import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { CategoryTable } from "pages/Categories/CategoryTable";
import {
  useCategoriesDeleteCategoryMutation,
  useCategoriesGetCategoriesQuery,
  useCategoriesGetCategoryLazyQuery,
} from "pages/Categories/queries";

export const Categories = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getCategoriesData,
    loading: loadingCategories,
    refetch: refetchCategories,
    error,
  } = useCategoriesGetCategoriesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const [getCategory] = useCategoriesGetCategoryLazyQuery();
  const categories = getCategoriesData?.category ?? [];

  const [deleteCategoryMutation, { loading: loadingDeleteCategory }] =
    useCategoriesDeleteCategoryMutation();

  const deleteCategory = useCallback(
    async (categoryId: number) => {
      if (!companyId) return;

      try {
        const { data: getCategoryData } = await getCategory({
          variables: { companyId, categoryId },
        });

        const referenceCategory = getCategoryData?.category[0];

        if (!referenceCategory) {
          return message.error("削除に失敗しました");
        }

        // NOTE: ページ外で削除対象のカテゴリに新しくデータが紐づく可能性があるので、削除時に改めて確認する
        if (
          referenceCategory.plans.length > 0 ||
          referenceCategory.categoryMenus.length > 0 ||
          referenceCategory.planMenuCategories.length > 0
        ) {
          return message.error(
            "削除に失敗しました。ページを再度読み込み、もう一度お試しください。",
          );
        }

        await deleteCategoryMutation({
          variables: { categoryId, archivedAt: new Date().toISOString() },
        });
        message.success("削除しました");
      } catch (e) {
        message.error("削除に失敗しました");
      }
      await refetchCategories();
    },
    [companyId, deleteCategoryMutation, getCategory, refetchCategories],
  );

  return (
    <DashboardLayout title="カテゴリ一覧">
      <PageHeader
        title="カテゴリ一覧"
        extra={[
          isFeatureEnabled("addCategory") && (
            <Link key="add" to="/category/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
          isFeatureEnabled("editCategory") && (
            <Link key="editPriority" to="/category/priority/edit">
              <Button>表示順編集</Button>
            </Link>
          ),
        ]}
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <CategoryTable
        categories={categories}
        loading={loadingCategories || loadingDeleteCategory}
        deleteCategory={deleteCategory}
      />
    </DashboardLayout>
  );
};
