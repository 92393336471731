import React, { memo } from "react";
import styled, { css } from "styled-components";
import { convertUnitToTenThousand } from "util/numberUnit/numberUnit";

import { Loading } from "components/Loading";

import type { CustomerDashboardKpis } from "../types";

import { PanelPc } from "./Panel/PanelPc";
import type { PanelProps } from "./Panel/types";

const PanelsWrapper = styled.div<{ mb: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${({ mb }) => css`
    margin-bottom: ${mb}px;
  `}
`;

const getMbPanelData = (customerDashboardKpis: CustomerDashboardKpis): PanelProps[] => {
  const { value: salesValue, isOveredMillion: isSalesOveredMillion } = convertUnitToTenThousand(
    customerDashboardKpis.sales.value,
  );

  return [
    {
      title: "売上",
      fluctuatingStatus: customerDashboardKpis.sales.fluctuatingStatus,
      value: salesValue,
      unit: isSalesOveredMillion ? "万円" : "円",
    },
    {
      title: "来店客数",
      fluctuatingStatus: customerDashboardKpis.customerCount.fluctuatingStatus,
      value: customerDashboardKpis.customerCount.value,
      unit: "人",
    },
    {
      title: "客単価",
      fluctuatingStatus: customerDashboardKpis.salesPerCustomer.fluctuatingStatus,
      value: customerDashboardKpis.salesPerCustomer.value,
      unit: "円",
    },
    {
      title: "リピーター数",
      fluctuatingStatus: customerDashboardKpis.repeaterCount.fluctuatingStatus,
      value: customerDashboardKpis.repeaterCount.value,
      unit: "人",
    },
  ];
};

const getSmPanelData = (customerDashboardKpis: CustomerDashboardKpis): PanelProps[] => [
  {
    title: "獲得ID数",
    fluctuatingStatus: customerDashboardKpis.newLineIdCount.fluctuatingStatus,
    value: customerDashboardKpis.newLineIdCount.value,
    unit: "人",
  },
  {
    title: "チェックインレート",
    fluctuatingStatus: customerDashboardKpis.checkInRate.fluctuatingStatus,
    value: customerDashboardKpis.checkInRate.value,
    unit: "%",
  },
  {
    title: "アンバサダー客数",
    fluctuatingStatus: customerDashboardKpis.ambassadorCount.fluctuatingStatus,
    value: customerDashboardKpis.ambassadorCount.value,
    unit: "人",
  },
  {
    title: "アンバサダー経由新規客数",
    fluctuatingStatus: customerDashboardKpis.newCustomerViaAmbassadorCount.fluctuatingStatus,
    value: customerDashboardKpis.newCustomerViaAmbassadorCount.value,
    unit: "人",
  },
  {
    title: "来店組数",
    fluctuatingStatus: customerDashboardKpis.groupCount.fluctuatingStatus,
    value: customerDashboardKpis.groupCount.value,
    unit: "組",
  },
];

const getFaveYellPanelData = (customerDashboardKpis: CustomerDashboardKpis): PanelProps[] => [
  {
    title: "推しエール人数",
    fluctuatingStatus: customerDashboardKpis.faveYellUserCount.fluctuatingStatus,
    value: customerDashboardKpis.faveYellUserCount.value,
    unit: "人",
  },
  {
    title: "推しエール総額",
    fluctuatingStatus: customerDashboardKpis.faveYellAmount.fluctuatingStatus,
    value: customerDashboardKpis.faveYellAmount.value,
    unit: "円",
  },
];

type Props = {
  loading: boolean;
  isYellAvailable: boolean;
  kpisData: CustomerDashboardKpis | undefined;
};

export const CustomerDashboardKpiPanels = memo<Props>(({ kpisData, loading, isYellAvailable }) => {
  if (loading) return <Loading height={312} />;

  const mdPanelData = kpisData ? getMbPanelData(kpisData) : [];
  const smPanelData = kpisData ? getSmPanelData(kpisData) : [];
  const faveYellPanelData = isYellAvailable && kpisData ? getFaveYellPanelData(kpisData) : [];

  return (
    <>
      <PanelsWrapper mb={16}>
        {mdPanelData.map((panelProps) => (
          <PanelPc key={panelProps.title} {...panelProps} />
        ))}
      </PanelsWrapper>

      <PanelsWrapper mb={24}>
        {[...smPanelData, ...faveYellPanelData].map((panelProps) => (
          <PanelPc key={panelProps.title} {...panelProps} size="sm" />
        ))}
      </PanelsWrapper>
    </>
  );
});
