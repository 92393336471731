import React, { memo } from "react";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

type AggregatedCoineySettlement = {
  dimension: string;
  amount: number;
};

type Props = {
  loading?: boolean;
  coineySettlements: AggregatedCoineySettlement[];
};

export const CoineySalesTable = memo<Props>(({ loading, coineySettlements }) => {
  const [pagination, setPagination] = usePagination();

  const formatter = new Intl.NumberFormat("ja");

  const columns = [
    {
      title: "区分",
      dataIndex: "dimension",
    },
    {
      title: "売上 (税込)",
      dataIndex: "amount",
      align: "right",
      render(amount: number) {
        return `${formatter.format(amount)} 円`;
      },
    } as const,
  ];

  return (
    <Table
      rowKey="dimension"
      columns={columns}
      dataSource={coineySettlements}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
