import React, { memo } from "react";

import { AsyncSwitch } from "components/AsyncSwitch";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { DishUpSlipGroup, Table as TableType } from "../type";

type Props = {
  loading?: boolean;
  tables: TableType[];
  dishUpSlipGroups: DishUpSlipGroup[];
  onTogglePrintingConfig(args: { dishUpSlipGroupId: string; tableId: number }): Promise<void>;
};

export const DishUpSlipGroupTableTable = memo<Props>(
  ({ loading, tables, dishUpSlipGroups, onTogglePrintingConfig }) => {
    const [pagination, setPagination] = usePagination();

    const columns = [
      { title: "テーブル名", dataIndex: "name", fixed: "left" as const, width: 100 },
      ...dishUpSlipGroups.map((dishUpSlipGroup) => ({
        title: dishUpSlipGroup.name,
        width: 130,
        render(_: string, table: TableType) {
          const checked = table.dishUpSlipGroupTables.some(
            ({ dishUpSlipGroupId }) => dishUpSlipGroupId === dishUpSlipGroup.dishUpSlipGroupId,
          );

          const handleChange = async () => {
            await onTogglePrintingConfig({
              dishUpSlipGroupId: dishUpSlipGroup.dishUpSlipGroupId,
              tableId: table.tableId,
            });
          };

          return (
            <AsyncSwitch
              data-cy={`toggle-${dishUpSlipGroup.name}`}
              checked={checked}
              onChange={handleChange}
            />
          );
        },
      })),
    ];

    return (
      <Table
        rowKey="tableId"
        columns={columns}
        dataSource={tables}
        loading={loading}
        bordered
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
