import { useCallback, useState } from "react";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";

import { auth } from "libs/firebase";

export const useUpdateAccountPassword = () => {
  const [loading, setLoading] = useState(false);

  const updateAccountPassword = useCallback(
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: string;
      newPassword: string;
    }): Promise<{ error: null | string }> => {
      setLoading(true);

      try {
        const user = auth.currentUser;
        if (!user?.email) throw new Error("ログインしてください");

        try {
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
          await reauthenticateWithCredential(user, credential);

          await updatePassword(user, newPassword);
        } catch (e: any) {
          switch (e.code) {
            case "auth/wrong-password":
              return { error: "パスワードが間違っています" };
          }
          return { error: "パスワードの更新に失敗しました" };
        }
      } finally {
        setLoading(false);
      }
      return { error: null };
    },
    [],
  );

  return { updateAccountPassword, loading };
};
