import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DownloadQrModalGetTables = gql`
    query DownloadQrModalGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    shopId
    tableId
  }
}
    `;
export type DownloadQrModalGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type DownloadQrModalGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'code' | 'name' | 'shopId' | 'tableId'>
  )> }
);


export const DownloadQrModalGetTablesDocument = gql`
    query DownloadQrModalGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    code
    name
    shopId
    tableId
  }
}
    `;

/**
 * __useDownloadQrModalGetTablesQuery__
 *
 * To run a query within a React component, call `useDownloadQrModalGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadQrModalGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadQrModalGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useDownloadQrModalGetTablesQuery(baseOptions: Apollo.QueryHookOptions<DownloadQrModalGetTablesQuery, DownloadQrModalGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadQrModalGetTablesQuery, DownloadQrModalGetTablesQueryVariables>(DownloadQrModalGetTablesDocument, options);
      }
export function useDownloadQrModalGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadQrModalGetTablesQuery, DownloadQrModalGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadQrModalGetTablesQuery, DownloadQrModalGetTablesQueryVariables>(DownloadQrModalGetTablesDocument, options);
        }
export type DownloadQrModalGetTablesQueryHookResult = ReturnType<typeof useDownloadQrModalGetTablesQuery>;
export type DownloadQrModalGetTablesLazyQueryHookResult = ReturnType<typeof useDownloadQrModalGetTablesLazyQuery>;
export type DownloadQrModalGetTablesQueryResult = Apollo.QueryResult<DownloadQrModalGetTablesQuery, DownloadQrModalGetTablesQueryVariables>;