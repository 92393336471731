import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EbicaTablesGetEbicaTables = gql`
    query EbicaTablesGetEbicaTables($shopId: uuid!) {
  ebicaTable(where: {shopId: {_eq: $shopId}}, order_by: [{tableId: asc}]) {
    id
    ebicaTableId
    tableId
  }
}
    `;
export const EbicaTablesGetEbicaApiEbicaTables = gql`
    query EbicaTablesGetEbicaApiEbicaTables($input: EbicaApiTablesInput!) {
  ebicaApiTables(input: $input) {
    id
    name
  }
}
    `;
export const EbicaTablesGetTables = gql`
    query EbicaTablesGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
  }
}
    `;
export const EbicaTablesInsertEbicaTable = gql`
    mutation EbicaTablesInsertEbicaTable($shopId: uuid!, $ebicaTableId: Int!, $ebicaTable: ebicaTable_insert_input!) {
  delete_ebicaTable(
    where: {shopId: {_eq: $shopId}, ebicaTableId: {_eq: $ebicaTableId}}
  ) {
    affected_rows
  }
  insert_ebicaTable_one(object: $ebicaTable) {
    id
  }
}
    `;
export const EbicaTablesDeleteEbicaTable = gql`
    mutation EbicaTablesDeleteEbicaTable($shopId: uuid!, $ebicaTableId: Int!) {
  delete_ebicaTable(
    where: {shopId: {_eq: $shopId}, ebicaTableId: {_eq: $ebicaTableId}}
  ) {
    affected_rows
  }
}
    `;
export type EbicaTablesGetEbicaTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EbicaTablesGetEbicaTablesQuery = (
  { __typename?: 'query_root' }
  & { ebicaTable: Array<(
    { __typename?: 'ebicaTable' }
    & Pick<Types.EbicaTable, 'id' | 'ebicaTableId' | 'tableId'>
  )> }
);

export type EbicaTablesGetEbicaApiEbicaTablesQueryVariables = Types.Exact<{
  input: Types.EbicaApiTablesInput;
}>;


export type EbicaTablesGetEbicaApiEbicaTablesQuery = (
  { __typename?: 'query_root' }
  & { ebicaApiTables: Array<(
    { __typename?: 'EbicaApiTable' }
    & Pick<Types.EbicaApiTable, 'id' | 'name'>
  )> }
);

export type EbicaTablesGetTablesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EbicaTablesGetTablesQuery = (
  { __typename?: 'query_root' }
  & { table: Array<(
    { __typename?: 'table' }
    & Pick<Types.Table, 'id' | 'name'>
  )> }
);

export type EbicaTablesInsertEbicaTableMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  ebicaTableId: Types.Scalars['Int'];
  ebicaTable: Types.EbicaTableInsertInput;
}>;


export type EbicaTablesInsertEbicaTableMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ebicaTable?: Types.Maybe<(
    { __typename?: 'ebicaTable_mutation_response' }
    & Pick<Types.EbicaTableMutationResponse, 'affected_rows'>
  )>, insert_ebicaTable_one?: Types.Maybe<(
    { __typename?: 'ebicaTable' }
    & Pick<Types.EbicaTable, 'id'>
  )> }
);

export type EbicaTablesDeleteEbicaTableMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  ebicaTableId: Types.Scalars['Int'];
}>;


export type EbicaTablesDeleteEbicaTableMutation = (
  { __typename?: 'mutation_root' }
  & { delete_ebicaTable?: Types.Maybe<(
    { __typename?: 'ebicaTable_mutation_response' }
    & Pick<Types.EbicaTableMutationResponse, 'affected_rows'>
  )> }
);


export const EbicaTablesGetEbicaTablesDocument = gql`
    query EbicaTablesGetEbicaTables($shopId: uuid!) {
  ebicaTable(where: {shopId: {_eq: $shopId}}, order_by: [{tableId: asc}]) {
    id
    ebicaTableId
    tableId
  }
}
    `;

/**
 * __useEbicaTablesGetEbicaTablesQuery__
 *
 * To run a query within a React component, call `useEbicaTablesGetEbicaTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEbicaTablesGetEbicaTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEbicaTablesGetEbicaTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEbicaTablesGetEbicaTablesQuery(baseOptions: Apollo.QueryHookOptions<EbicaTablesGetEbicaTablesQuery, EbicaTablesGetEbicaTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EbicaTablesGetEbicaTablesQuery, EbicaTablesGetEbicaTablesQueryVariables>(EbicaTablesGetEbicaTablesDocument, options);
      }
export function useEbicaTablesGetEbicaTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicaTablesGetEbicaTablesQuery, EbicaTablesGetEbicaTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EbicaTablesGetEbicaTablesQuery, EbicaTablesGetEbicaTablesQueryVariables>(EbicaTablesGetEbicaTablesDocument, options);
        }
export type EbicaTablesGetEbicaTablesQueryHookResult = ReturnType<typeof useEbicaTablesGetEbicaTablesQuery>;
export type EbicaTablesGetEbicaTablesLazyQueryHookResult = ReturnType<typeof useEbicaTablesGetEbicaTablesLazyQuery>;
export type EbicaTablesGetEbicaTablesQueryResult = Apollo.QueryResult<EbicaTablesGetEbicaTablesQuery, EbicaTablesGetEbicaTablesQueryVariables>;
export const EbicaTablesGetEbicaApiEbicaTablesDocument = gql`
    query EbicaTablesGetEbicaApiEbicaTables($input: EbicaApiTablesInput!) {
  ebicaApiTables(input: $input) {
    id
    name
  }
}
    `;

/**
 * __useEbicaTablesGetEbicaApiEbicaTablesQuery__
 *
 * To run a query within a React component, call `useEbicaTablesGetEbicaApiEbicaTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEbicaTablesGetEbicaApiEbicaTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEbicaTablesGetEbicaApiEbicaTablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEbicaTablesGetEbicaApiEbicaTablesQuery(baseOptions: Apollo.QueryHookOptions<EbicaTablesGetEbicaApiEbicaTablesQuery, EbicaTablesGetEbicaApiEbicaTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EbicaTablesGetEbicaApiEbicaTablesQuery, EbicaTablesGetEbicaApiEbicaTablesQueryVariables>(EbicaTablesGetEbicaApiEbicaTablesDocument, options);
      }
export function useEbicaTablesGetEbicaApiEbicaTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicaTablesGetEbicaApiEbicaTablesQuery, EbicaTablesGetEbicaApiEbicaTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EbicaTablesGetEbicaApiEbicaTablesQuery, EbicaTablesGetEbicaApiEbicaTablesQueryVariables>(EbicaTablesGetEbicaApiEbicaTablesDocument, options);
        }
export type EbicaTablesGetEbicaApiEbicaTablesQueryHookResult = ReturnType<typeof useEbicaTablesGetEbicaApiEbicaTablesQuery>;
export type EbicaTablesGetEbicaApiEbicaTablesLazyQueryHookResult = ReturnType<typeof useEbicaTablesGetEbicaApiEbicaTablesLazyQuery>;
export type EbicaTablesGetEbicaApiEbicaTablesQueryResult = Apollo.QueryResult<EbicaTablesGetEbicaApiEbicaTablesQuery, EbicaTablesGetEbicaApiEbicaTablesQueryVariables>;
export const EbicaTablesGetTablesDocument = gql`
    query EbicaTablesGetTables($shopId: uuid!) {
  table(
    where: {shopId: {_eq: $shopId}, isArchived: {_eq: false}}
    order_by: [{tableArea: {priority: asc}}, {priority: asc}, {tableId: asc}]
  ) {
    id
    name
  }
}
    `;

/**
 * __useEbicaTablesGetTablesQuery__
 *
 * To run a query within a React component, call `useEbicaTablesGetTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEbicaTablesGetTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEbicaTablesGetTablesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEbicaTablesGetTablesQuery(baseOptions: Apollo.QueryHookOptions<EbicaTablesGetTablesQuery, EbicaTablesGetTablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EbicaTablesGetTablesQuery, EbicaTablesGetTablesQueryVariables>(EbicaTablesGetTablesDocument, options);
      }
export function useEbicaTablesGetTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EbicaTablesGetTablesQuery, EbicaTablesGetTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EbicaTablesGetTablesQuery, EbicaTablesGetTablesQueryVariables>(EbicaTablesGetTablesDocument, options);
        }
export type EbicaTablesGetTablesQueryHookResult = ReturnType<typeof useEbicaTablesGetTablesQuery>;
export type EbicaTablesGetTablesLazyQueryHookResult = ReturnType<typeof useEbicaTablesGetTablesLazyQuery>;
export type EbicaTablesGetTablesQueryResult = Apollo.QueryResult<EbicaTablesGetTablesQuery, EbicaTablesGetTablesQueryVariables>;
export const EbicaTablesInsertEbicaTableDocument = gql`
    mutation EbicaTablesInsertEbicaTable($shopId: uuid!, $ebicaTableId: Int!, $ebicaTable: ebicaTable_insert_input!) {
  delete_ebicaTable(
    where: {shopId: {_eq: $shopId}, ebicaTableId: {_eq: $ebicaTableId}}
  ) {
    affected_rows
  }
  insert_ebicaTable_one(object: $ebicaTable) {
    id
  }
}
    `;
export type EbicaTablesInsertEbicaTableMutationFn = Apollo.MutationFunction<EbicaTablesInsertEbicaTableMutation, EbicaTablesInsertEbicaTableMutationVariables>;

/**
 * __useEbicaTablesInsertEbicaTableMutation__
 *
 * To run a mutation, you first call `useEbicaTablesInsertEbicaTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEbicaTablesInsertEbicaTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ebicaTablesInsertEbicaTableMutation, { data, loading, error }] = useEbicaTablesInsertEbicaTableMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      ebicaTableId: // value for 'ebicaTableId'
 *      ebicaTable: // value for 'ebicaTable'
 *   },
 * });
 */
export function useEbicaTablesInsertEbicaTableMutation(baseOptions?: Apollo.MutationHookOptions<EbicaTablesInsertEbicaTableMutation, EbicaTablesInsertEbicaTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EbicaTablesInsertEbicaTableMutation, EbicaTablesInsertEbicaTableMutationVariables>(EbicaTablesInsertEbicaTableDocument, options);
      }
export type EbicaTablesInsertEbicaTableMutationHookResult = ReturnType<typeof useEbicaTablesInsertEbicaTableMutation>;
export type EbicaTablesInsertEbicaTableMutationResult = Apollo.MutationResult<EbicaTablesInsertEbicaTableMutation>;
export type EbicaTablesInsertEbicaTableMutationOptions = Apollo.BaseMutationOptions<EbicaTablesInsertEbicaTableMutation, EbicaTablesInsertEbicaTableMutationVariables>;
export const EbicaTablesDeleteEbicaTableDocument = gql`
    mutation EbicaTablesDeleteEbicaTable($shopId: uuid!, $ebicaTableId: Int!) {
  delete_ebicaTable(
    where: {shopId: {_eq: $shopId}, ebicaTableId: {_eq: $ebicaTableId}}
  ) {
    affected_rows
  }
}
    `;
export type EbicaTablesDeleteEbicaTableMutationFn = Apollo.MutationFunction<EbicaTablesDeleteEbicaTableMutation, EbicaTablesDeleteEbicaTableMutationVariables>;

/**
 * __useEbicaTablesDeleteEbicaTableMutation__
 *
 * To run a mutation, you first call `useEbicaTablesDeleteEbicaTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEbicaTablesDeleteEbicaTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ebicaTablesDeleteEbicaTableMutation, { data, loading, error }] = useEbicaTablesDeleteEbicaTableMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      ebicaTableId: // value for 'ebicaTableId'
 *   },
 * });
 */
export function useEbicaTablesDeleteEbicaTableMutation(baseOptions?: Apollo.MutationHookOptions<EbicaTablesDeleteEbicaTableMutation, EbicaTablesDeleteEbicaTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EbicaTablesDeleteEbicaTableMutation, EbicaTablesDeleteEbicaTableMutationVariables>(EbicaTablesDeleteEbicaTableDocument, options);
      }
export type EbicaTablesDeleteEbicaTableMutationHookResult = ReturnType<typeof useEbicaTablesDeleteEbicaTableMutation>;
export type EbicaTablesDeleteEbicaTableMutationResult = Apollo.MutationResult<EbicaTablesDeleteEbicaTableMutation>;
export type EbicaTablesDeleteEbicaTableMutationOptions = Apollo.BaseMutationOptions<EbicaTablesDeleteEbicaTableMutation, EbicaTablesDeleteEbicaTableMutationVariables>;