import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type Transformers<T, U> = {
  serialize: (data: T) => U;
  deserialize: (data: U) => T;
};

export const useLocationState = <T extends Record<string, any>, U = T>(
  key: string,
  initialState: T,
  transformers?: Transformers<T, U>,
) => {
  const serialize = transformers?.serialize;
  const deserialize = transformers?.deserialize;

  const navigate = useNavigate();
  const location = useLocation();
  const status = location.state as Record<string, U> | undefined;
  const state = status?.[key];
  const [locationState, setLocationState] = useState<T>({
    ...initialState,
    ...(state && deserialize ? deserialize(state) : state),
  });

  const handleSetLocationState = useCallback(
    (state: T) => {
      navigate(
        { pathname: location.pathname, search: location.search },
        {
          replace: true,
          state: { ...status, [key]: serialize ? serialize(state) : state },
        },
      );
      setLocationState(state);
    },
    [navigate, location.pathname, location.search, status, key, serialize],
  );

  return [locationState, handleSetLocationState] as const;
};
