import React, { memo } from "react";
import { Tag } from "antd";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";
import { PlanOption } from "pages/PlanMainOptionPrintings/types";

type Props = {
  loading?: boolean;
  shopId?: string;
  planOptions: PlanOption[];
};

export const PlanMainOptionPrintingTable = memo<Props>(({ loading, shopId, planOptions }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns = [
    {
      title: "カテゴリ名",
      render(_: string, { plan }: PlanOption) {
        return plan.category.name;
      },
    },
    {
      title: "プラン",
      render(_: unknown, { plan: { planName } }: PlanOption) {
        return planName;
      },
    },
    {
      title: "プランオプション名",
      width: 150,
      dataIndex: "name",
    },
    {
      title: "キッチンプリンター",
      width: 160,
      render(_: unknown, { shopPlanOptionKitchenRoles }: PlanOption) {
        return shopPlanOptionKitchenRoles.map(({ role }) => (
          <Tag key={role?.roleId}>{role?.name}</Tag>
        ));
      },
    },
    ...(canAccess("editShopPlanOptionRoles")
      ? [
          {
            title: "",
            align: "center",
            width: 60,
            render(_: string, { planOptionId }: PlanOption) {
              if (shopId === undefined) return null;

              return (
                <IconLink to={`/shop/${shopId}/mainOption/${planOptionId}/role/edit`}>
                  <EditIcon />
                </IconLink>
              );
            },
          } as const,
        ]
      : []),
  ];

  return (
    <Table
      rowKey="planOptionId"
      columns={columns}
      dataSource={planOptions}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
