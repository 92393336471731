import React, { memo } from "react";
import styled from "styled-components";
import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { parse } from "papaparse";
import { isNotNullable } from "util/type/primitive";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { colors } from "constants/colors";
import { Customer } from "pages/AddMessageDelivery/types";
import { TargetCustomers } from "pages/EditMessageDelivery/EditMessageDeliveryForm/TargetCustomers";

import { EditMessageDeliveryFormItem } from "../useEditMessageDeliveryForm";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormContent = styled.div`
  padding: 24px;
  border: 1px solid ${colors.Border.Default};
  border-radius: 2px;
`;

type Props = {
  onSearchCustomersByCustomerIds: ({
    customerIds,
  }: {
    customerIds: string[];
  }) => Promise<Customer[]>;
} & Omit<FormItemProps, "children" | "name">;

export const CSVUploadCustomerListField = memo<Props>(
  ({ onSearchCustomersByCustomerIds, ...props }) => (
    <EditMessageDeliveryFormItem.NonProperty
      shouldUpdate={withFormDependencies(({ uploadedCsv, customerIdsFromCsv }) => [
        uploadedCsv,
        customerIdsFromCsv,
      ])}
      noStyle
    >
      {({ setFieldsValue, getFieldsValue }) => {
        const { uploadedCsv, customerIdsFromCsv } = getFieldsValue([
          "uploadedCsv",
          "customerIdsFromCsv",
        ]);
        return (
          <FormWrapper>
            <FormContent>
              <EditMessageDeliveryFormItem
                label="CSV アップロード"
                name="uploadedCsv"
                rules={[{ required: true, message: "CSV をアップロードしてください" }]}
                {...props}
              >
                <Upload
                  fileList={uploadedCsv?.fileList}
                  accept=".csv"
                  maxCount={1}
                  beforeUpload={(file) => {
                    parse(file, {
                      complete: async ({ data = [] }) => {
                        const uploadedCustomerIds = data
                          .map((rowCells: unknown) => Array.isArray(rowCells) && rowCells[0])
                          .filter(isNotNullable)
                          .filter((customerId) => customerId);

                        const res = await onSearchCustomersByCustomerIds({
                          customerIds: uploadedCustomerIds,
                        });

                        setFieldsValue({ customerIdsFromCsv: res.map(({ id }) => id) });
                      },
                    });

                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>ファイルを選択する</Button>
                </Upload>
              </EditMessageDeliveryFormItem>
              <EditMessageDeliveryFormItem name="customerIdsFromCsv" noStyle />
            </FormContent>
            <TargetCustomers number={customerIdsFromCsv?.length ?? 0} />
          </FormWrapper>
        );
      }}
    </EditMessageDeliveryFormItem.NonProperty>
  ),
);
