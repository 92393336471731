import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypes = gql`
    query EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    label
    priority
    type
    onSitePaymentDetailTypeCategory
  }
}
    `;
export const EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypes = gql`
    mutation EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypes($onSitePaymentDetailTypes: [onSitePaymentDetailType_insert_input!]!) {
  insert_onSitePaymentDetailType(
    objects: $onSitePaymentDetailTypes
    on_conflict: {constraint: onSitePaymentDetailType_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'label' | 'priority' | 'type' | 'onSitePaymentDetailTypeCategory'>
  )> }
);

export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationVariables = Types.Exact<{
  onSitePaymentDetailTypes: Array<Types.OnSitePaymentDetailTypeInsertInput> | Types.OnSitePaymentDetailTypeInsertInput;
}>;


export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'onSitePaymentDetailType_mutation_response' }
    & Pick<Types.OnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'onSitePaymentDetailType' }
      & Pick<Types.OnSitePaymentDetailType, 'id'>
    )> }
  )> }
);


export const EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesDocument = gql`
    query EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, isArchived: {_eq: false}}
    order_by: [{priority: asc}, {id: asc}]
  ) {
    id
    label
    priority
    type
    onSitePaymentDetailTypeCategory
  }
}
    `;

/**
 * __useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery, EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery, EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables>(EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesDocument, options);
      }
export function useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery, EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery, EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables>(EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesDocument, options);
        }
export type EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery>;
export type EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesLazyQuery>;
export type EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQuery, EditOnSitePaymentDetailTypePrioritiesGetOnSitePaymentDetailTypesQueryVariables>;
export const EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesDocument = gql`
    mutation EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypes($onSitePaymentDetailTypes: [onSitePaymentDetailType_insert_input!]!) {
  insert_onSitePaymentDetailType(
    objects: $onSitePaymentDetailTypes
    on_conflict: {constraint: onSitePaymentDetailType_pkey, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationFn = Apollo.MutationFunction<EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation, EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationVariables>;

/**
 * __useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation__
 *
 * To run a mutation, you first call `useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation, { data, loading, error }] = useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation({
 *   variables: {
 *      onSitePaymentDetailTypes: // value for 'onSitePaymentDetailTypes'
 *   },
 * });
 */
export function useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation(baseOptions?: Apollo.MutationHookOptions<EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation, EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation, EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationVariables>(EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesDocument, options);
      }
export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationHookResult = ReturnType<typeof useEditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation>;
export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationResult = Apollo.MutationResult<EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation>;
export type EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationOptions = Apollo.BaseMutationOptions<EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutation, EditOnSitePaymentDetailTypePrioritiesInsertOnSitePaymentDetailTypesMutationVariables>;