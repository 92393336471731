import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = Omit<FormItemProps, "children"> & { disabled: boolean };

export const ChoiceNameCnField = memo<Props>(({ ...props }) => (
  <FormItem label="選択肢名：中国語 (簡体字)" name="choiceNameCn" {...props}>
    <Input disabled={props.disabled} />
  </FormItem>
));
