import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { Table } from "components/Table";
import { CompanySalesAnalyticsRow } from "hooks/useCompanySalesAnalytics/types";
import { Pagination } from "hooks/usePagination";

import { CompanySalesAnalyticsColumnWithSelectState } from "../types";

const TableStyleProvider = styled.div`
  .ant-table-container {
    border: none;
  }
`;

type Props = {
  rows: CompanySalesAnalyticsRow[];
  columnsWithEnabledState: CompanySalesAnalyticsColumnWithSelectState[];
  isLoading: boolean;
  pagination: Pagination;
  onPaginationChange: (newPagination: Pagination) => void;
};

export const CompanySalesAnalyticsTable = ({
  rows,
  columnsWithEnabledState,
  isLoading,
  pagination,
  onPaginationChange,
}: Props) => {
  const handleChange = useCallback(
    ({ position: _, ...pagination }, sorter) => {
      onPaginationChange(pagination);
    },
    [onPaginationChange],
  );

  const columns = useMemo(
    () => columnsWithEnabledState.filter(({ isEnabled }) => isEnabled),
    [columnsWithEnabledState],
  );

  return (
    <TableStyleProvider>
      <Table
        key="companySalesAnalytics"
        loading={isLoading}
        columns={columns}
        dataSource={rows}
        bordered
        pagination={pagination}
        onChange={handleChange}
        rowKey="shopId"
      />
    </TableStyleProvider>
  );
};
