import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditMenuWinboardMenuFormItem } from "../useEditMenuWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const WinboardBumonCodeField = memo<Props>((props) => (
  <EditMenuWinboardMenuFormItem label="部門コード" name="bumonCode" {...props}>
    <InputCode />
  </EditMenuWinboardMenuFormItem>
));
