import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ShouldMergeSlipOptionsField = memo<Props>((props) => (
  <AddMenuFormItem
    name="shouldMergeSlipOptions"
    valuePropName="checked"
    help="本メニューが異なるオプションで複数注文された際、それらをまとめて伝票に印字します"
    {...props}
  >
    <Checkbox>オプションをまとめて印字する</Checkbox>
  </AddMenuFormItem>
));
