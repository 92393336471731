import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { DisplayField } from "pages/AddClerk/AddClerkForm/DisplayField";
import { NameField } from "pages/AddClerk/AddClerkForm/NameField";
import { useAddClerkForm } from "pages/AddClerk/AddClerkForm/useAddClerkForm";
import { ClerkInsertInput } from "types/graphql";

type Props = {
  onSubmit: (clerk: Omit<ClerkInsertInput, "companyId" | "shopId">) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const AddClerkForm = memo<Props>(({ onClose, onSubmit, loading, onFormValidationError }) => {
  const { form, initialValues, submit } = useAddClerkForm(onSubmit);

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      submit();
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      throw e;
    }
  }, [form, onFormValidationError, submit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <FormContent>
        <Form name="clerk" form={form} initialValues={initialValues} layout="vertical">
          <NameField />
          <DisplayField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button type="primary" onClick={handleSubmit} loading={loading}>
          登録
        </Button>
      </FormActions>
    </>
  );
});
