import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditOrderableTimeGetOrderableTimes = gql`
    query EditOrderableTimeGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const EditOrderableTimeGetOrderableTimeAndShop = gql`
    query EditOrderableTimeGetOrderableTimeAndShop($orderableTimeId: uuid!, $shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    changeDateTime
  }
  orderableTime_by_pk(id: $orderableTimeId) {
    id
    name
    shopOrderableTimeTerms(
      where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    ) {
      id
      dayWeek
      start
      end
    }
  }
}
    `;
export const EditOrderableTimeUpdateOrderableTime = gql`
    mutation EditOrderableTimeUpdateOrderableTime($shopId: uuid!, $orderableTimeId: uuid!, $orderableTime: orderableTime_set_input!, $shopOrderableTimeTermIds: [uuid!]!, $shopOrderableTimeTerms: [shopOrderableTimeTerm_insert_input!]!) {
  update_orderableTime(_set: $orderableTime, where: {id: {_eq: $orderableTimeId}}) {
    affected_rows
  }
  delete_shopOrderableTimeTerm(
    where: {id: {_nin: $shopOrderableTimeTermIds}, orderableTimeId: {_eq: $orderableTimeId}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopOrderableTimeTerm(
    objects: $shopOrderableTimeTerms
    on_conflict: {constraint: shopOrderableTimeTerm_dayWeek_shopId_orderableTimeId_start_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditOrderableTimeGetOrderableTimesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditOrderableTimeGetOrderableTimesQuery = (
  { __typename?: 'query_root' }
  & { orderableTime: Array<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
  )> }
);

export type EditOrderableTimeGetOrderableTimeAndShopQueryVariables = Types.Exact<{
  orderableTimeId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
}>;


export type EditOrderableTimeGetOrderableTimeAndShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'companyId' | 'name' | 'changeDateTime'>
  )>, orderableTime_by_pk?: Types.Maybe<(
    { __typename?: 'orderableTime' }
    & Pick<Types.OrderableTime, 'id' | 'name'>
    & { shopOrderableTimeTerms: Array<(
      { __typename?: 'shopOrderableTimeTerm' }
      & Pick<Types.ShopOrderableTimeTerm, 'id' | 'dayWeek' | 'start' | 'end'>
    )> }
  )> }
);

export type EditOrderableTimeUpdateOrderableTimeMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  orderableTimeId: Types.Scalars['uuid'];
  orderableTime: Types.OrderableTimeSetInput;
  shopOrderableTimeTermIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
  shopOrderableTimeTerms: Array<Types.ShopOrderableTimeTermInsertInput> | Types.ShopOrderableTimeTermInsertInput;
}>;


export type EditOrderableTimeUpdateOrderableTimeMutation = (
  { __typename?: 'mutation_root' }
  & { update_orderableTime?: Types.Maybe<(
    { __typename?: 'orderableTime_mutation_response' }
    & Pick<Types.OrderableTimeMutationResponse, 'affected_rows'>
  )>, delete_shopOrderableTimeTerm?: Types.Maybe<(
    { __typename?: 'shopOrderableTimeTerm_mutation_response' }
    & Pick<Types.ShopOrderableTimeTermMutationResponse, 'affected_rows'>
  )>, insert_shopOrderableTimeTerm?: Types.Maybe<(
    { __typename?: 'shopOrderableTimeTerm_mutation_response' }
    & Pick<Types.ShopOrderableTimeTermMutationResponse, 'affected_rows'>
  )> }
);


export const EditOrderableTimeGetOrderableTimesDocument = gql`
    query EditOrderableTimeGetOrderableTimes($companyId: uuid!) {
  orderableTime(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useEditOrderableTimeGetOrderableTimesQuery__
 *
 * To run a query within a React component, call `useEditOrderableTimeGetOrderableTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrderableTimeGetOrderableTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrderableTimeGetOrderableTimesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditOrderableTimeGetOrderableTimesQuery(baseOptions: Apollo.QueryHookOptions<EditOrderableTimeGetOrderableTimesQuery, EditOrderableTimeGetOrderableTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOrderableTimeGetOrderableTimesQuery, EditOrderableTimeGetOrderableTimesQueryVariables>(EditOrderableTimeGetOrderableTimesDocument, options);
      }
export function useEditOrderableTimeGetOrderableTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOrderableTimeGetOrderableTimesQuery, EditOrderableTimeGetOrderableTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOrderableTimeGetOrderableTimesQuery, EditOrderableTimeGetOrderableTimesQueryVariables>(EditOrderableTimeGetOrderableTimesDocument, options);
        }
export type EditOrderableTimeGetOrderableTimesQueryHookResult = ReturnType<typeof useEditOrderableTimeGetOrderableTimesQuery>;
export type EditOrderableTimeGetOrderableTimesLazyQueryHookResult = ReturnType<typeof useEditOrderableTimeGetOrderableTimesLazyQuery>;
export type EditOrderableTimeGetOrderableTimesQueryResult = Apollo.QueryResult<EditOrderableTimeGetOrderableTimesQuery, EditOrderableTimeGetOrderableTimesQueryVariables>;
export const EditOrderableTimeGetOrderableTimeAndShopDocument = gql`
    query EditOrderableTimeGetOrderableTimeAndShop($orderableTimeId: uuid!, $shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    changeDateTime
  }
  orderableTime_by_pk(id: $orderableTimeId) {
    id
    name
    shopOrderableTimeTerms(
      where: {shop: {archivedAt: {_is_null: true}}, shopId: {_eq: $shopId}}
    ) {
      id
      dayWeek
      start
      end
    }
  }
}
    `;

/**
 * __useEditOrderableTimeGetOrderableTimeAndShopQuery__
 *
 * To run a query within a React component, call `useEditOrderableTimeGetOrderableTimeAndShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrderableTimeGetOrderableTimeAndShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrderableTimeGetOrderableTimeAndShopQuery({
 *   variables: {
 *      orderableTimeId: // value for 'orderableTimeId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditOrderableTimeGetOrderableTimeAndShopQuery(baseOptions: Apollo.QueryHookOptions<EditOrderableTimeGetOrderableTimeAndShopQuery, EditOrderableTimeGetOrderableTimeAndShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditOrderableTimeGetOrderableTimeAndShopQuery, EditOrderableTimeGetOrderableTimeAndShopQueryVariables>(EditOrderableTimeGetOrderableTimeAndShopDocument, options);
      }
export function useEditOrderableTimeGetOrderableTimeAndShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditOrderableTimeGetOrderableTimeAndShopQuery, EditOrderableTimeGetOrderableTimeAndShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditOrderableTimeGetOrderableTimeAndShopQuery, EditOrderableTimeGetOrderableTimeAndShopQueryVariables>(EditOrderableTimeGetOrderableTimeAndShopDocument, options);
        }
export type EditOrderableTimeGetOrderableTimeAndShopQueryHookResult = ReturnType<typeof useEditOrderableTimeGetOrderableTimeAndShopQuery>;
export type EditOrderableTimeGetOrderableTimeAndShopLazyQueryHookResult = ReturnType<typeof useEditOrderableTimeGetOrderableTimeAndShopLazyQuery>;
export type EditOrderableTimeGetOrderableTimeAndShopQueryResult = Apollo.QueryResult<EditOrderableTimeGetOrderableTimeAndShopQuery, EditOrderableTimeGetOrderableTimeAndShopQueryVariables>;
export const EditOrderableTimeUpdateOrderableTimeDocument = gql`
    mutation EditOrderableTimeUpdateOrderableTime($shopId: uuid!, $orderableTimeId: uuid!, $orderableTime: orderableTime_set_input!, $shopOrderableTimeTermIds: [uuid!]!, $shopOrderableTimeTerms: [shopOrderableTimeTerm_insert_input!]!) {
  update_orderableTime(_set: $orderableTime, where: {id: {_eq: $orderableTimeId}}) {
    affected_rows
  }
  delete_shopOrderableTimeTerm(
    where: {id: {_nin: $shopOrderableTimeTermIds}, orderableTimeId: {_eq: $orderableTimeId}, shopId: {_eq: $shopId}}
  ) {
    affected_rows
  }
  insert_shopOrderableTimeTerm(
    objects: $shopOrderableTimeTerms
    on_conflict: {constraint: shopOrderableTimeTerm_dayWeek_shopId_orderableTimeId_start_key, update_columns: [start, end]}
  ) {
    affected_rows
  }
}
    `;
export type EditOrderableTimeUpdateOrderableTimeMutationFn = Apollo.MutationFunction<EditOrderableTimeUpdateOrderableTimeMutation, EditOrderableTimeUpdateOrderableTimeMutationVariables>;

/**
 * __useEditOrderableTimeUpdateOrderableTimeMutation__
 *
 * To run a mutation, you first call `useEditOrderableTimeUpdateOrderableTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderableTimeUpdateOrderableTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderableTimeUpdateOrderableTimeMutation, { data, loading, error }] = useEditOrderableTimeUpdateOrderableTimeMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      orderableTimeId: // value for 'orderableTimeId'
 *      orderableTime: // value for 'orderableTime'
 *      shopOrderableTimeTermIds: // value for 'shopOrderableTimeTermIds'
 *      shopOrderableTimeTerms: // value for 'shopOrderableTimeTerms'
 *   },
 * });
 */
export function useEditOrderableTimeUpdateOrderableTimeMutation(baseOptions?: Apollo.MutationHookOptions<EditOrderableTimeUpdateOrderableTimeMutation, EditOrderableTimeUpdateOrderableTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrderableTimeUpdateOrderableTimeMutation, EditOrderableTimeUpdateOrderableTimeMutationVariables>(EditOrderableTimeUpdateOrderableTimeDocument, options);
      }
export type EditOrderableTimeUpdateOrderableTimeMutationHookResult = ReturnType<typeof useEditOrderableTimeUpdateOrderableTimeMutation>;
export type EditOrderableTimeUpdateOrderableTimeMutationResult = Apollo.MutationResult<EditOrderableTimeUpdateOrderableTimeMutation>;
export type EditOrderableTimeUpdateOrderableTimeMutationOptions = Apollo.BaseMutationOptions<EditOrderableTimeUpdateOrderableTimeMutation, EditOrderableTimeUpdateOrderableTimeMutationVariables>;