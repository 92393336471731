import React, { memo } from "react";
import { Input } from "antd";

// eslint-disable-next-line no-restricted-imports
import { FormItem, FormItemProps } from "components/antd/Form";

type Props = Omit<FormItemProps, "children">;

export const FtpPasswordField = memo<Props>((props) => (
  <FormItem
    label="FTPパスワード"
    name="ftpPassword"
    rules={[{ required: true }]}
    required
    {...props}
  >
    <Input type="password" />
  </FormItem>
));
