import { ApolloError } from "@apollo/client";
import { isObject } from "util/type/primitive";

type DiniiException = {
  severity: string;
  code: string;
  title: string;
  message: string;
};

export const isDiniiException = (
  exception: Record<string, unknown>,
): exception is DiniiException => {
  const { severity, code, title, message } = exception;

  return (
    typeof severity === "string" &&
    typeof code === "string" &&
    typeof title === "string" &&
    typeof message === "string"
  );
};

export const extractException = (apolloErrors: ApolloError[]): DiniiException | undefined => {
  const exceptions = apolloErrors.flatMap((apolloError) =>
    apolloError.graphQLErrors.flatMap((graphqlError) => graphqlError.extensions?.exception),
  );

  for (const exception of exceptions) {
    if (isObject(exception) && isDiniiException(exception)) {
      return exception;
    }
  }
};
