import React, { memo, ReactNode } from "react";
import styled from "styled-components";

import { colors } from "constants/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
`;

const Icon = styled.img`
  width: auto;
  height: 52px;
`;

const TextBase = styled.p`
  margin: 0;
  width: 100%;
  text-align: center;
`;

const Title = styled(TextBase)`
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
`;

const Unit = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 4px;
`;

const Value = styled(TextBase)`
  font-size: 40px;
  line-height: 38px;
  font-weight: 700;
`;

const SubValue = styled(TextBase)`
  font-weight: 500;
  font-size: 14px;
  height: 22px;
  color: ${colors.Text.Secondary};
`;

type Props = { icon: string; title: string; value: ReactNode; subValue?: string; unit: string };

export const PanelItem = memo<Props>(({ icon, title, value, subValue = "", unit }) => (
  <Container>
    <Icon src={icon} alt="" />
    <Title>{title}</Title>
    <Value>
      {value}
      <Unit>{unit}</Unit>
    </Value>
    <SubValue>{subValue}</SubValue>
  </Container>
));
