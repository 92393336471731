import React, { memo } from "react";
import { parseNumber } from "util/input/parseNumber";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { EditShopMenuStockFormItem } from "../useEditShopMenuStockForm";

type Props = Omit<FormItemProps, "children" | "name">;

const parseCount = (value: string | undefined) =>
  parseNumber(value, (value) => value.replace("個", ""));

export const CurrentStockNumField = memo<Props>((props) => (
  <EditShopMenuStockFormItem.NonProperty
    label="在庫数"
    shouldUpdate={withFormDependencies(({ noCurrentStockNum }) => [noCurrentStockNum])}
    {...props}
  >
    {({ getFieldValue }) => {
      const noCurrentStockNum: boolean = getFieldValue("noCurrentStockNum");

      return (
        <EditShopMenuStockFormItem name="currentStockNum" noStyle>
          <InputNumber
            formatter={(value) => `${value}個`}
            parser={parseCount}
            min={0}
            disabled={noCurrentStockNum}
          />
        </EditShopMenuStockFormItem>
      );
    }}
  </EditShopMenuStockFormItem.NonProperty>
));
