import { useCallback } from "react";
import { Store } from "antd/lib/form/interface";

import { createFormItem, Form } from "components/antd/Form";

export type WinboardMenuRegistrationDataFormValues = {
  shopIds?: string[];
};

const getInitialValues = () => ({});

export const WinboardMenuRegistrationDataFormItem =
  createFormItem<WinboardMenuRegistrationDataFormValues>();

export const useWinboardMenuRegistrationDataForm = (
  onChange: (formData: WinboardMenuRegistrationDataFormValues) => void,
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as WinboardMenuRegistrationDataFormValues;
      onChange(values);
    },
    [onChange],
  );

  return { form, initialValues, change };
};
