import React, { memo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Role } from "pages/AddDishUpSlipGroup/types";

import { AddDishUpSlipGroupFormItem } from "../useAddDishUpSlipGroupForm";

const { Option } = Select;

type Props = {
  roles: Role[];
} & Omit<FormItemProps, "children" | "name">;

export const RoleField = memo<Props>(({ roles, ...props }) => (
  <AddDishUpSlipGroupFormItem
    label="ロール"
    name="roleIds"
    rules={[{ required: true, message: "ロール名を入力してください" }]}
    {...props}
  >
    <Select<number> mode="multiple">
      {roles.map(({ roleId, name }) => (
        <Option key={roleId} value={roleId}>
          {name}
        </Option>
      ))}
    </Select>
  </AddDishUpSlipGroupFormItem>
));
