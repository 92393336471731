import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useCompany } from "hooks/useCompany";
import { Plan as TypePlan } from "pages/AddPlan/types";
import {
  CreatePlanInputPlanSource,
  CreatePlanInputTranslationSource,
  DisplayTypeEnum,
  Plan,
} from "types/graphql";

import { LocaleValuePair } from "./PlanTranslationOptions/types";

export type AddPlanFormValues = Pick<
  Plan,
  | "description"
  | "displayType"
  | "featuredLabelText"
  | "imageUrl"
  | "orderableTimeId"
  | "planCategoryId"
  | "planName"
  | "planTime"
  | "endNotificationThresholdMinutes"
  | "receiptDisplayName"
  | "shopSideName"
  | "taxMethod"
  | "taxRate"
> & {
  planGroupId: Plan["_planGroupId"];
  categoryId: number;
  nameSources: LocaleValuePair[];
  descriptionSources: LocaleValuePair[];
  featureLabelSources: LocaleValuePair[];
};

const getInitialValues = () => ({ displayType: DisplayTypeEnum.Text });

export const AddPlanFormItem = createFormItem<AddPlanFormValues>();

export const useAddPlanForm = (
  onSubmit: ({
    plan,
    sourcePlanId,
    createTranslationsSource,
  }: {
    plan: CreatePlanInputPlanSource;
    sourcePlanId?: number;
    createTranslationsSource: CreatePlanInputTranslationSource;
  }) => void,
) => {
  const [company] = useCompany();

  const companyId = company?.id;

  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(
    ({
      imageUrl,
      hasPlanTimeLimit,
      hasEndNotificationTime,
      sourcePlanId,
      createTranslationsSource,
    }: {
      imageUrl: string | null | undefined;
      hasPlanTimeLimit: boolean;
      hasEndNotificationTime: boolean;
      sourcePlanId?: number;
      createTranslationsSource?: CreatePlanInputTranslationSource | null;
    }) => {
      if (!companyId) throw new Error("no companyId");

      const formValues = form.getFieldsValue() as AddPlanFormValues;

      const {
        categoryId: planCategoryId,
        planGroupId,
        description,
        displayType,
        featuredLabelText,
        orderableTimeId,
        planName,
        planTime,
        endNotificationThresholdMinutes,
        receiptDisplayName,
        shopSideName,
        taxMethod,
        taxRate,
        nameSources,
        descriptionSources,
        featureLabelSources,
      } = formValues;

      onSubmit({
        plan: {
          _planGroupId: planGroupId,
          description,
          displayType,
          featuredLabelText,
          orderableTimeId,
          planName,
          taxMethod,
          // NOTE: 伝票表示名が未入力時はプラン名を入れる
          receiptDisplayName: receiptDisplayName || planName,
          // NOTE: ハンディ表示名が未入力時はプラン名を入れる
          shopSideName: shopSideName || planName,
          imageUrl,
          planTime: hasPlanTimeLimit ? planTime : null,
          endNotificationThresholdMinutes: hasEndNotificationTime
            ? endNotificationThresholdMinutes
            : null,
          taxRate: taxMethod !== "nonTaxable" ? taxRate : 0,
          planCategoryId,
          companyId,
        },
        sourcePlanId,
        createTranslationsSource: {
          nameSources: (nameSources ?? []).filter(({ value }) => value !== null),
          descriptionSources: (descriptionSources ?? []).filter(({ value }) => value !== null),
          featureLabelSources: (featureLabelSources ?? []).filter(({ value }) => value !== null),
        },
      });
    },
    [companyId, form, onSubmit],
  );

  const copyFromAnotherPlan = useCallback(
    (plan: TypePlan) => {
      const { planCategoryId: categoryId, translations, ...clone } = plan;

      form.setFieldsValue({
        ...clone,
        categoryId,
      });
    },
    [form],
  );

  return { form, initialValues, submit, copyFromAnotherPlan };
};
