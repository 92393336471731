import React, { memo } from "react";
import { Button, Typography } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { FileUploadField } from "./FileUploadField";
import { InputMasterDataFormValues, useInputMasterDataForm } from "./useInputMasterDataForm";

type Props = {
  selectedCompany: string | undefined;
  onSubmit: (args: InputMasterDataFormValues) => void;
  submitting: boolean;
};

export const InputMasterDataForm = memo<Props>(({ selectedCompany, onSubmit, submitting }) => {
  const { form, submit } = useInputMasterDataForm({ onSubmit });

  return (
    <>
      <FormContent>
        <Typography.Paragraph>
          対象法人: <strong>{selectedCompany}</strong>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <ul>
            <li>
              <Typography.Link
                href="https://www.notion.so/diniinote/44a44c7ff3fb4017b52c070e576b665c"
                target="_blank"
              >
                作業マニュアル
              </Typography.Link>
            </li>
            <li>
              <Typography.Link
                href="https://drive.google.com/drive/u/1/folders/1rxwxZDvZcS93zcTjvCZinvldmdV5KwBJ"
                target="_blank"
              >
                メニュー入力テンプレート
              </Typography.Link>
            </li>
          </ul>
        </Typography.Paragraph>
        <Form name="inputMasterData" form={form}>
          <FileUploadField title="orderable_time.csv" name="orderableTime" />
          <FileUploadField title="category.csv" name="category" />
          <FileUploadField title="menu.csv" name="menu" />
          <FileUploadField title="option.csv" name="option" />
          <FileUploadField title="choice.csv" name="choice" />
          <FileUploadField title="plan_group.csv" name="planGroup" />
          <FileUploadField title="plan.csv" name="plan" />
          <FileUploadField title="plan_option.csv" name="planOption" />
          <FileUploadField title="plan_choice.csv" name="planChoice" />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button type="primary" onClick={submit} loading={submitting}>
          アップロード
        </Button>
      </FormActions>
    </>
  );
});
