import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditRoleForm } from "pages/EditRole/EditRoleForm";
import { EditRoleFormValues } from "pages/EditRole/EditRoleForm/useEditRoleForm";
import {
  useEditRoleAddOnlinePaymentPrinterRoleMetaMutation,
  useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation,
  useEditRoleGetRoleQuery,
  useEditRoleGetRolesQuery,
  useEditRoleUpdateRoleMutation,
} from "pages/EditRole/queries";

export const EditRole = () => {
  const { id: roleId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: getRoleData,
    loading: loadingRole,
    error: getRoleDataError,
    refetch: refetchRoleData,
  } = useEditRoleGetRoleQuery(roleId ? { variables: { roleId } } : { skip: true });
  const role = getRoleData?.role?.[0];
  const onlinePaymentPrinterRoleMeta = getRoleData?.onlinePaymentPrinterRoleMeta?.[0];

  useSetCurrentCompanyEffect(role);

  const {
    data: getRolesData,
    loading: loadingRoles,
    error: getRolesDataError,
  } = useEditRoleGetRolesQuery(
    role?.shopId ? { variables: { shopId: role.shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const [updateRoleMutation, { loading: loadingUpdateRole }] = useEditRoleUpdateRoleMutation();
  const [
    deleteOnlinePaymentPrinterRoleMeta,
    { loading: loadingDeleteOnlinePaymentPrinterRoleMeta },
  ] = useEditRoleDeleteOnlinePaymentPrinterRoleMetaMutation();
  const [addOnlinePaymentPrinterRoleMeta, { loading: loadingAddOnlinePaymentPrinterRoleMeta }] =
    useEditRoleAddOnlinePaymentPrinterRoleMetaMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (formValues: EditRoleFormValues) => {
      if (!role) return;

      const {
        shouldPrintFreeOrderOnDishUpSlip,
        firstSpareRoleId,
        secondSpareRoleId,
        shouldPrintOnlinePaymentSlip,
        ...roleParams
      } = formValues;

      try {
        await updateRoleMutation({
          variables: {
            roleId: role.id,
            role: {
              ...roleParams,
              firstSpareRoleId: firstSpareRoleId ?? null,
              secondSpareRoleId: secondSpareRoleId ?? null,
              removeFreeOrderFromDishUpSlip: !shouldPrintFreeOrderOnDishUpSlip,
            },
          },
        });

        if (shouldPrintOnlinePaymentSlip && !onlinePaymentPrinterRoleMeta) {
          await addOnlinePaymentPrinterRoleMeta({
            variables: {
              onlinePaymentPrinterRoleMeta: {
                shopId: role.shopId,
                printerRoleId: role.id,
              },
            },
          });
        }

        if (!shouldPrintOnlinePaymentSlip && onlinePaymentPrinterRoleMeta) {
          await deleteOnlinePaymentPrinterRoleMeta({
            variables: {
              onlinePaymentPrinterRoleMetaId: onlinePaymentPrinterRoleMeta.id,
            },
          });
        }

        message.success("編集を保存しました");

        refetchRoleData();
        goBack();
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      role,
      updateRoleMutation,
      onlinePaymentPrinterRoleMeta,
      refetchRoleData,
      goBack,
      addOnlinePaymentPrinterRoleMeta,
      deleteOnlinePaymentPrinterRoleMeta,
    ],
  );

  const loading =
    loadingRole ||
    loadingRoles ||
    loadingAddOnlinePaymentPrinterRoleMeta ||
    loadingDeleteOnlinePaymentPrinterRoleMeta;

  const shouldShowAlert = getRoleDataError || getRolesDataError;

  return (
    <DashboardLayout
      title={role?.name}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "キッチンプリンター" }],
      }}
    >
      <PageHeader title={role?.name} onBack={goBack} />
      {loading && <Loading height={300} />}
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {role && (
        <EditRoleForm
          role={role}
          onlinePaymentPrinterRoleMeta={onlinePaymentPrinterRoleMeta}
          roles={roles}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateRole}
        />
      )}
    </DashboardLayout>
  );
};
