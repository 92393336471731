import { useCallback, useState } from "react";
import { Store } from "antd/lib/form/interface";

import { createFormItem, Form } from "components/antd/Form";
import { message } from "components/antd/message";
import {
  EditShopMenuOutputGetMenu,
  useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation,
  useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation,
} from "pages/EditShopMenuOutput/queries";
import { KdDisplayTarget, Menu } from "pages/EditShopMenuOutput/types";
import { ShopMenuKdDisplayTargetInsertInput } from "types/graphql";

export type EditMenuKdDisplayTargetFormValues = Record<string, boolean>;

const getInitialValues = (
  menuKdDisplayTarget: KdDisplayTarget[],
  kdDisplayTargets: KdDisplayTarget[],
) => {
  const menuKdDisplayTargetsKdDisplayTargetIds = menuKdDisplayTarget.map(({ id }) => id);
  return Object.fromEntries(
    kdDisplayTargets.map(({ id }) => [id, menuKdDisplayTargetsKdDisplayTargetIds.includes(id)]),
  );
};

const useLoadings = (kdDisplayTargets: KdDisplayTarget[]) => {
  const initialLoadings = Object.fromEntries<boolean>(
    kdDisplayTargets.map(({ id }) => [id, false]),
  );
  const [loadings, setLoadings] = useState(initialLoadings);

  const onLoad = useCallback(
    (kdDisplayTargetId: string, state: boolean) =>
      setLoadings((loadings) => ({ ...loadings, [kdDisplayTargetId]: state })),
    [],
  );

  const onRequest = useCallback(
    (kdDisplayTargetId: string) => onLoad(kdDisplayTargetId, true),
    [onLoad],
  );
  const onFinish = useCallback(
    (kdDisplayTargetId: string) => onLoad(kdDisplayTargetId, false),
    [onLoad],
  );

  return { loadings, onRequest, onFinish };
};

export const EditMenuKdDisplayTargetFormItem = createFormItem<EditMenuKdDisplayTargetFormValues>();

export const useEditMenuKdDisplayTargetForm = (
  shopId: string,
  menu: Menu,
  menuKdDisplayTarget: KdDisplayTarget[],
  kdDisplayTargets: KdDisplayTarget[],
) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(menuKdDisplayTarget, kdDisplayTargets);

  const { loadings, onRequest, onFinish } = useLoadings(kdDisplayTargets);

  const [insertShopMenuKdDisplayTargetMutation] =
    useEditShopMenuOutputInsertShopMenuKdDisplayTargetMutation();

  const insertMenuKdDisplayTargetsKdDisplayTarget = useCallback(
    async (kdDisplayTargetId: string) => {
      try {
        onRequest(kdDisplayTargetId);

        const shopMenuKdDisplayTarget: ShopMenuKdDisplayTargetInsertInput[] = [
          { menuId: menu.id, _menuId: menu.menuId, kdDisplayTargetId, shopId },
        ];
        await insertShopMenuKdDisplayTargetMutation({
          variables: {
            shopMenuKdDisplayTarget,
          },
          refetchQueries: [
            {
              query: EditShopMenuOutputGetMenu,
              variables: { menuId: menu.menuId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(kdDisplayTargetId);
      }
    },
    [onRequest, menu.id, menu.menuId, shopId, insertShopMenuKdDisplayTargetMutation, onFinish],
  );

  const [deleteShopMenuKdDisplayTargetMutation] =
    useEditShopMenuOutputDeleteShopMenuKdDisplayTargetMutation();

  const deleteMenuKdDisplayTargetsKdDisplayTarget = useCallback(
    async (kdDisplayTargetId: string) => {
      try {
        onRequest(kdDisplayTargetId);

        await deleteShopMenuKdDisplayTargetMutation({
          variables: { menuId: menu.menuId, kdDisplayTargetId },
          refetchQueries: [
            {
              query: EditShopMenuOutputGetMenu,
              variables: { menuId: menu.menuId, shopId },
            },
          ],
        });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      } finally {
        onFinish(kdDisplayTargetId);
      }
    },
    [onRequest, deleteShopMenuKdDisplayTargetMutation, menu.menuId, shopId, onFinish],
  );

  const change = useCallback(
    (changedValues: Store) => {
      const values = changedValues as EditMenuKdDisplayTargetFormValues;

      for (const [kdDisplayTargetId, state] of Object.entries(values)) {
        if (state) {
          insertMenuKdDisplayTargetsKdDisplayTarget(kdDisplayTargetId);
        } else {
          deleteMenuKdDisplayTargetsKdDisplayTarget(kdDisplayTargetId);
        }
      }
    },
    [insertMenuKdDisplayTargetsKdDisplayTarget, deleteMenuKdDisplayTargetsKdDisplayTarget],
  );

  return { form, initialValues, change, loadings };
};
