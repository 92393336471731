import React, { memo } from "react";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { useShopSelect } from "hooks/useShopSelect";

import { ShopSelectDropdown } from "./ShopSelectDropdown";

export const ShopSelector = memo(() => {
  const { currentShopName, loading, setShop, shops } = useShopSelect();
  return (
    <div>
      <ShopSelectDropdown shops={shops} setShop={setShop}>
        <Button loading={loading}>
          {currentShopName ?? "店舗を選択"} <DownOutlined />
        </Button>
      </ShopSelectDropdown>
    </div>
  );
});
