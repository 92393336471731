import { useCallback, useEffect, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";

import { Choice, Option, WinboardMenu } from "../types";

export type EditOptionWinboardOptionFormValues = {
  option: Pick<Option, "optionId" | "name"> & {
    choices: Array<
      Pick<Choice, "name" | "choiceId"> & {
        winboardMenus: Array<
          Pick<
            WinboardMenu,
            "id" | "code" | "name" | "bumonCode" | "bumonName" | "categoryCode" | "categoryName"
          >
        >;
      }
    >;
  };
};

export const EditOptionWinboardOptionFormItem =
  createFormItem<EditOptionWinboardOptionFormValues>();

export const useEditOptionWinboardOptionForm = ({
  option,
  onSubmit,
}: {
  option: Option;
  onSubmit: (args: EditOptionWinboardOptionFormValues) => void;
}) => {
  const [form] = Form.useForm<EditOptionWinboardOptionFormValues>();

  const initialValues = useMemo<EditOptionWinboardOptionFormValues>(() => ({ option }), [option]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue();

    await onSubmit(values);
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
