import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { EnableAutoPrintReceiptField } from "pages/EditCashRegisterConfig/EditCashRegisterConfigForm/EnableAutoPrintReceipt";
import { OpenDrawerAtOnSitePaymentEveryTimeField } from "pages/EditCashRegisterConfig/EditCashRegisterConfigForm/OpenDrawerAtOnSitePaymentEveryTimeField";
import {
  AccountingSlipImagePositionEnum,
  ServiceChargeConfigInput,
  SpecificTimeSurchargeConfigInput,
} from "types/graphql";

import { Shop, ShopSetInputWithCashRegisterConfig } from "../types";

import { useGetTaxOfficesQuery } from "./SelectTaxOfficeField/queries";
import { AccountingSlipImageTable } from "./AccountingSlipImageTable";
import { ContactInfoField } from "./ContactInfoField";
import { DisableClerkCacheField } from "./DisableClerkCacheField";
import { DrawerOpenPasswordField } from "./DrawerOpenPasswordField";
import { EligibleInvoiceIssuerRegistrationNumberField } from "./EligibleInvoiceIssuerRegistrationNumberField";
import { EnableAutoFractionalDiscountField } from "./EnableAutoFractionalDiscountField";
import { EnableDefaultInflowSourceTagField } from "./EnableDefaultInflowSourceTagField";
import { EnableRequiringPasswordForDrawerOpenField } from "./EnableRequiringPasswordForDrawerOpenField";
import { EnableReserveCashSeparationField } from "./EnableReserveCashSeparationField";
import { InitialSetupDrawerOpenPasswordFields } from "./InitialSetupDrawerOpenPasswordFields";
import { IssuerField } from "./IssuerField";
import { RequirePasswordOnVoidAndTableClearField } from "./RequirePasswordOnVoidAndTableClearField";
import { SelectTaxOfficeField } from "./SelectTaxOfficeField";
import { ShowFreeItemInAccountingSlipField } from "./ShowFreeItemInAccountingSlipField";
import { SurchargeConfigFields } from "./SurchargeConfigFields";
import { useEditCashRegisterConfigForm } from "./useEditCashRegisterConfigForm";
import { UseSteraField } from "./UseSteraField";

const TableContainer = styled.div`
  padding: 32px 16px;
`;

type Props = {
  shop: Shop;
  onSubmit: ({
    shop,
    serviceChargeConfig,
    specificTimeSurchargeConfig,
  }: {
    shop: ShopSetInputWithCashRegisterConfig;
    serviceChargeConfig: ServiceChargeConfigInput | null;
    specificTimeSurchargeConfig: SpecificTimeSurchargeConfigInput | null;
  }) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  refetchShop: () => void;
};

export const EditCashRegisterConfigForm = memo<Props>(
  ({ shop, onSubmit, onClose, loading, onFormValidationError, refetchShop }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const { data: taxOfficesData } = useGetTaxOfficesQuery();
    const taxOffices = useMemo(() => taxOfficesData?.taxOffice ?? [], [taxOfficesData]);

    // 本来はヘッダー画像もフッター画像も複数登録/順序設定できるが、現状そこまで使いたいユースケースがほぼないため、サポートしない
    const headerImage = shop?.accountingSlipImages?.find(
      (accountingSlipImage) =>
        accountingSlipImage.position === AccountingSlipImagePositionEnum.Header,
    );
    const footerImage = shop?.accountingSlipImages?.find(
      (accountingSlipImage) =>
        accountingSlipImage.position === AccountingSlipImagePositionEnum.Footer,
    );
    const hasDrawerOpenPassword = shop?.cashRegisterConfig?.drawerOpenPassword;
    const DRAWER_OPEN_PASSWORD_MIN_LENGTH = 4;
    const drawerOpenPasswordRegex = new RegExp(
      `^[A-Za-z0-9]{${DRAWER_OPEN_PASSWORD_MIN_LENGTH},}$`,
    );

    const { form, initialValues, submit } = useEditCashRegisterConfigForm({
      shop,
      onSubmit,
    });

    const [isEditingDrawerOpenPassword, setIsEditingDrawerOpenPassword] = useState(false);
    const handleClickPasswordChangeButton = useCallback(() => {
      setIsEditingDrawerOpenPassword(true);
    }, []);
    const resetDrawerOpenPasswordField = useCallback(() => {
      setIsEditingDrawerOpenPassword(false);
      form.resetFields(["drawerOpenPassword"]);
    }, [form]);
    const handleChangeEnableRequiringPasswordForDrawerOpenField = useCallback(
      (checked: boolean) => {
        if (!checked) resetDrawerOpenPasswordField();
      },
      [resetDrawerOpenPasswordField],
    );

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
        resetDrawerOpenPasswordField();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, submit, onFormValidationError, resetDrawerOpenPasswordField]);

    return (
      <>
        <FormContent>
          <Form name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <IssuerField />
            <ContactInfoField />
            <EligibleInvoiceIssuerRegistrationNumberField />
            <SelectTaxOfficeField taxOffices={taxOffices} />
            <Spacer size={20} />
            <DisableClerkCacheField />
            <EnableReserveCashSeparationField />
            <EnableDefaultInflowSourceTagField />
            <ShowFreeItemInAccountingSlipField />
            <EnableAutoFractionalDiscountField />
            <OpenDrawerAtOnSitePaymentEveryTimeField />
            {hasDrawerOpenPassword ? (
              <>
                <EnableRequiringPasswordForDrawerOpenField
                  onChangeSwitch={handleChangeEnableRequiringPasswordForDrawerOpenField}
                />
                <DrawerOpenPasswordField
                  passwordMinLength={DRAWER_OPEN_PASSWORD_MIN_LENGTH}
                  passwordRegex={drawerOpenPasswordRegex}
                  isEditingPassword={isEditingDrawerOpenPassword}
                  onClickPasswordChangeButton={handleClickPasswordChangeButton}
                />
              </>
            ) : (
              <InitialSetupDrawerOpenPasswordFields
                passwordMinLength={DRAWER_OPEN_PASSWORD_MIN_LENGTH}
                passwordRegex={drawerOpenPasswordRegex}
              />
            )}
            <RequirePasswordOnVoidAndTableClearField />
            <UseSteraField />
            <EnableAutoPrintReceiptField endSpacer={<Spacer size={32} />} />
            {isFeatureEnabled("editSurchargeConfig") && (
              <SurchargeConfigFields changeDateTime={shop.changeDateTime} />
            )}
            <TableContainer>
              <AccountingSlipImageTable
                shopId={shop.shopId}
                headerImage={headerImage}
                footerImage={footerImage}
                refetchShop={refetchShop}
              />
            </TableContainer>
          </Form>
        </FormContent>
        <Spacer height={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
