import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { PlanGroup } from "types/graphql";

type Props = {
  planGroup: Pick<PlanGroup, "name" | "planGroupId"> | null;
  onBack: () => void;
};

export const PlanGroupHeader = memo<Props>(({ planGroup, onBack }) => {
  const { pathname } = useLocation();

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={onBack} title={planGroup?.name ?? ""} />
      {planGroup && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/planGroup/${planGroup.planGroupId}/edit`}>プラングループ</Link>
          </Menu.Item>
          <Menu.Item key="plan">
            <Link to={`/planGroup/${planGroup.planGroupId}/plan`}>プラングループ内プラン</Link>
          </Menu.Item>
        </Menu>
      )}
    </>
  );
});
