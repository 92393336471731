import React, { memo } from "react";
import { parseInteger } from "util/input/parseInteger";

import { FormItemProps, withFormDependencies } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";
import { AddMenuFormItem } from "pages/AddMenu/AddMenuForm/useAddMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

const parseCount = (value: string | undefined) =>
  parseInteger(value, (value) => value.replace("個", ""));

export const OrderLimitCountPerTableUserField = memo<Props>((props) => (
  <AddMenuFormItem.NonProperty
    shouldUpdate={withFormDependencies(({ hasOrderLimitPerTableUser }) => [
      hasOrderLimitPerTableUser,
    ])}
    noStyle
    {...props}
  >
    {({ getFieldValue }) => {
      const hasOrderLimitPerTableUser: boolean = getFieldValue("hasOrderLimitPerTableUser");

      return (
        <AddMenuFormItem
          name="orderMaxNumPerTableUser"
          rules={
            hasOrderLimitPerTableUser ? [{ required: true, message: "個数を入力してください" }] : []
          }
        >
          <InputNumber
            formatter={(value) => `${value}個`}
            parser={parseCount}
            min={1}
            disabled={!hasOrderLimitPerTableUser}
          />
        </AddMenuFormItem>
      );
    }}
  </AddMenuFormItem.NonProperty>
));
