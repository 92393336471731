import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Divider, Input, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ExternalOnlineMenuAvailableTimeTermDayWeek,
  findSortKeyByDayWeek,
  formatExternalOnlineMenuAvailableTimeTermDayWeek,
} from "models/externalOnlineMenuAvailableTime";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { Form, FormList } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { ExternalOnlineMenuInsertInput, OrderableTimeTermDayWeekEnum } from "types/graphql";

import { DayWeekField } from "./DayWeekField";
import { ExternalOnlineMenuAvailableTimeTermField } from "./ExternalOnlineMenuAvailableTimeTermField";
import { NameField } from "./NameField";
import { UrlField } from "./UrlField";
import {
  AddShopExternalOnlineMenuFormItem,
  useAddShopExternalOnlineMenuForm,
} from "./useAddShopExternalOnlineMenuForm";

const Container = styled.div`
  flex-direction: row;
  justify-self: stretch;
`;

type Props = {
  onSubmit: (input: ExternalOnlineMenuInsertInput) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onCancel: () => void;
  externalOnlineMenuConfigId: string;
  changeDateTime: string;
  loading: boolean;
};

export const AddShopExternalOnlineMenuForm = memo<Props>(
  ({
    onSubmit,
    onFormValidationError,
    onCancel,
    externalOnlineMenuConfigId,
    changeDateTime,
    loading,
  }) => {
    const { form, submit, change, takenDayWeeks, lastStartAtAndEndAt } =
      useAddShopExternalOnlineMenuForm(onSubmit, onFormValidationError, externalOnlineMenuConfigId);

    const getSortedPosition = useCallback(
      ({
        selectedDayWeek,
        currentPosition,
      }: {
        selectedDayWeek: string;
        currentPosition: number;
      }) => {
        const selectedDayWeekSortKey = findSortKeyByDayWeek(
          selectedDayWeek as OrderableTimeTermDayWeekEnum,
        );
        const dayWeeks = takenDayWeeks.slice();
        // NOTE: 変更対象のフィールドを除いて既に選択されている曜日
        dayWeeks.splice(currentPosition, 1);

        return dayWeeks
          .map((dayWeek) => findSortKeyByDayWeek(dayWeek))
          .filter((i) => i < selectedDayWeekSortKey).length;
      },
      [takenDayWeeks],
    );
    return (
      <>
        <FormContent>
          <Form name="externalOnlineMenu" form={form} layout="vertical" onValuesChange={change}>
            <NameField />
            <UrlField />

            <Divider />

            <FormList name="terms">
              {(fields, { add, remove, move }) => (
                <Container>
                  {fields.map((field) => (
                    <Row key={field.key}>
                      <Col span={4} offset={11}>
                        <DayWeekField
                          onChange={(value: { value: string; label: string }) => {
                            const position = getSortedPosition({
                              selectedDayWeek: value.value,
                              currentPosition: field.name,
                            });
                            move(field.name, position);
                          }}
                          name={[field.name, "dayWeek"]}
                          takenDayWeeks={takenDayWeeks}
                          horizontal
                        />
                      </Col>
                      <Col span={6} offset={1}>
                        <ExternalOnlineMenuAvailableTimeTermField
                          name={field.name}
                          changeDateTime={changeDateTime}
                          horizontal
                        />
                      </Col>
                      <Col span={2}>
                        <Button
                          type="text"
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          削除する
                        </Button>
                      </Col>
                      <AddShopExternalOnlineMenuFormItem name={[field.name, "id"]}>
                        <Input type="hidden" />
                      </AddShopExternalOnlineMenuFormItem>
                    </Row>
                  ))}

                  {fields.length <
                    Object.keys(ExternalOnlineMenuAvailableTimeTermDayWeek).length && (
                    <Row>
                      <Col flex="auto" />
                      <Col>
                        <Button
                          type="dashed"
                          onClick={() => {
                            const days = Object.keys(
                              ExternalOnlineMenuAvailableTimeTermDayWeek,
                            ).map((day) => day as OrderableTimeTermDayWeekEnum);
                            let dayWeek = days.find(
                              (key) => !takenDayWeeks.includes(key as OrderableTimeTermDayWeekEnum),
                            ) as OrderableTimeTermDayWeekEnum;
                            if (takenDayWeeks.length > 0) {
                              const lastDayIndex = Math.max(
                                ...takenDayWeeks.map((takenDayWeek) =>
                                  days.findIndex((day) => day === takenDayWeek),
                                ),
                              );
                              if (lastDayIndex < 7 /* Holiday */) {
                                dayWeek = days[lastDayIndex + 1] ?? dayWeek;
                              }
                            }
                            add({
                              dayWeek: {
                                key: dayWeek,
                                value: dayWeek,
                                label: formatExternalOnlineMenuAvailableTimeTermDayWeek(dayWeek),
                              },
                              start: lastStartAtAndEndAt.startAt,
                              end: lastStartAtAndEndAt.endAt,
                            });
                          }}
                          icon={<PlusOutlined />}
                        >
                          アプリ表示時間を追加する
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Container>
              )}
            </FormList>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onCancel}>キャンセル</Button>
          <Button type="primary" onClick={submit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
