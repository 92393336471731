import React, { memo, useState } from "react";
import styled from "styled-components";
import { Popover } from "antd";
import dayjs from "dayjs";
import { formatOrderableTimeWithChangeDateTime } from "models/orderableTime";

import { message } from "components/antd/message";
import { DragState } from "components/VerticalDragEventObserver/types";

import { eventToPosition } from "../calcEventPosition";
import { HEIGHT_PER_HOUR } from "../constants";
import { EventCell } from "../EventCell";
import { WeeklyEvent } from "../types";

import { EditEventForm, EventFormValues } from "./EditEventForm";

const AdjustDistanceDraggableArea = styled.div`
  position: absolute;
  width: 100%;
  height: 8px;
  bottom: 0px;
  cursor: ns-resize;
`;

export const EventCellList = memo(
  ({
    events,
    changeDateTime,
    onCreateEvent,
    onDeleteEvent,
    onSetDragState,
  }: {
    events: WeeklyEvent[];
    changeDateTime: dayjs.Dayjs;
    onCreateEvent: (_: WeeklyEvent) => void;
    onDeleteEvent: (_: WeeklyEvent) => void;
    onSetDragState: (_: DragState) => void;
  }) => {
    const [formValues, setFromValues] = useState<EventFormValues | null>(null);

    return (
      <>
        {events.map((event) => {
          const key = `${event.dayWeek}-${event.start.format("HH:mm")}-${event.end.format(
            "HH:mm",
          )}`;

          const { top, height } = eventToPosition(event, changeDateTime, HEIGHT_PER_HOUR);

          const text = formatOrderableTimeWithChangeDateTime({
            ...event,
            changeDateTime: changeDateTime.format("HH:mm"),
          });

          const onStartStretchEvent: React.MouseEventHandler<HTMLDivElement> = (e) => {
            e.stopPropagation();

            setFromValues(null);
            // NOTE: mouseMoveEvent 等のハンドリングは VerticalDragEventObserver にて
            onDeleteEvent(event);
            onSetDragState({ isDrag: true, start: top, distance: height });
          };

          const onStartMoveEvent: React.MouseEventHandler<HTMLDivElement> = ({
            nativeEvent,
            target,
            currentTarget,
          }) => {
            if (target !== currentTarget) return;

            setFromValues(null);
            // NOTE: mouseMoveEvent 等のハンドリングは VerticalDragEventObserver にて
            onDeleteEvent(event);
            onSetDragState({
              isDrag: true,
              start: top,
              distance: height,
              fixDistance: true,
              dragStartedEventOffsetY: nativeEvent.offsetY,
            });
          };

          return (
            <Popover
              key={key}
              content={
                formValues ? (
                  <EditEventForm
                    event={event}
                    formValues={formValues}
                    setFromValues={setFromValues}
                    onCreateEvent={onCreateEvent}
                    onDeleteEvent={onDeleteEvent}
                  />
                ) : null
              }
              title="アプリ表示時間"
              trigger="click"
              visible={key === formValues?.key}
              onVisibleChange={(visible) => setFromValues(visible ? { key, ...event } : null)}
            >
              <EventCell
                label={key}
                top={top}
                height={height}
                onDeleteButtonClick={() => {
                  message.warning(
                    "変更はまだ適用されていません。右下から「更新」を行ってください。",
                  );
                  onDeleteEvent(event);
                }}
                onStartMove={onStartMoveEvent}
              >
                {text.start} - {text.end}
                <AdjustDistanceDraggableArea onMouseDown={onStartStretchEvent} />
              </EventCell>
            </Popover>
          );
        })}
      </>
    );
  },
);
