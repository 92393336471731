import React, { memo } from "react";
import { Tree } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Category } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal/types";

import { AddMenuFormItem } from "../useAddMenuForm";

type Props = {
  categories: Category[];
} & Omit<FormItemProps, "children" | "name">;

export const MenusField = memo<Props>(({ categories, ...props }) => {
  const treeData = categories.map(
    ({ categoryId: key, name: title, shopSideName, categoryMenus }) => ({
      key,
      title: shopSideName || title,
      disableCheckbox: true,
      selectable: false,
      children: categoryMenus.map(({ categoryMenuId: key, menu }) => ({
        key,
        title: menu.name,
        selectable: false,
      })),
    }),
  );

  return (
    <AddMenuFormItem name="menus" valuePropName="checkedKeys" trigger="onCheck" {...props}>
      <Tree treeData={treeData} checkable />
    </AddMenuFormItem>
  );
});
