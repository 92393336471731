import React, { memo } from "react";
import type { DefaultOptionType } from "antd/lib/select";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrUndefined } from "hooks/useFilterConditions";

type Props = {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
  options: DefaultOptionType[];
  disabled?: boolean;
};

export const SelectField = memo<Props>(({ value, onChange, options, disabled }) => (
  <Select<string>
    showSearch
    allowClear
    defaultValue="undefined"
    value={String(value)}
    onChange={(changedValue) => onChange(convertStringToBooleanOrUndefined(changedValue))}
    optionFilterProp="label"
    options={options}
    disabled={disabled}
  />
));
