import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditLineChannelConfigGetConfig = gql`
    query EditLineChannelConfigGetConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    companyId
    lineChannelConfig {
      id
      shopId
      channelId
      channelSecret
      liffId
    }
    lineOfficialAccountId: _lineOfficialAccountId
  }
}
    `;
export const EditLineChannelGetLineOfficialAccount = gql`
    query EditLineChannelGetLineOfficialAccount($companyId: String!) {
  validLineOfficialAccounts(input: {companyId: $companyId}) {
    id
    lineId
    lineOfficialAccountId
  }
}
    `;
export const EditLineChannelConfigUpdateConfig = gql`
    mutation EditLineChannelConfigUpdateConfig($input: lineChannelConfig_insert_input!, $shopId: uuid!, $lineOfficialAccountId: uuid, $_lineOfficialAccountId: Int) {
  insert_lineChannelConfig_one(
    object: $input
    on_conflict: {constraint: lineMessagingConfig_shopId_key, update_columns: [channelId, channelSecret, liffId]}
  ) {
    id
  }
  update_shop_by_pk(
    pk_columns: {shopId: $shopId}
    _set: {lineOfficialAccountId: $lineOfficialAccountId, _lineOfficialAccountId: $_lineOfficialAccountId}
  ) {
    shopId
  }
}
    `;
export type EditLineChannelConfigGetConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditLineChannelConfigGetConfigQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'companyId'>
    & { lineOfficialAccountId: Types.Shop['_lineOfficialAccountId'] }
    & { lineChannelConfig?: Types.Maybe<(
      { __typename?: 'lineChannelConfig' }
      & Pick<Types.LineChannelConfig, 'id' | 'shopId' | 'channelId' | 'channelSecret' | 'liffId'>
    )> }
  )> }
);

export type EditLineChannelGetLineOfficialAccountQueryVariables = Types.Exact<{
  companyId: Types.Scalars['String'];
}>;


export type EditLineChannelGetLineOfficialAccountQuery = (
  { __typename?: 'query_root' }
  & { validLineOfficialAccounts: Array<(
    { __typename?: 'ValidLineOfficialAccount' }
    & Pick<Types.ValidLineOfficialAccount, 'id' | 'lineId' | 'lineOfficialAccountId'>
  )> }
);

export type EditLineChannelConfigUpdateConfigMutationVariables = Types.Exact<{
  input: Types.LineChannelConfigInsertInput;
  shopId: Types.Scalars['uuid'];
  lineOfficialAccountId?: Types.InputMaybe<Types.Scalars['uuid']>;
  _lineOfficialAccountId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type EditLineChannelConfigUpdateConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_lineChannelConfig_one?: Types.Maybe<(
    { __typename?: 'lineChannelConfig' }
    & Pick<Types.LineChannelConfig, 'id'>
  )>, update_shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
  )> }
);


export const EditLineChannelConfigGetConfigDocument = gql`
    query EditLineChannelConfigGetConfig($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
    companyId
    lineChannelConfig {
      id
      shopId
      channelId
      channelSecret
      liffId
    }
    lineOfficialAccountId: _lineOfficialAccountId
  }
}
    `;

/**
 * __useEditLineChannelConfigGetConfigQuery__
 *
 * To run a query within a React component, call `useEditLineChannelConfigGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditLineChannelConfigGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditLineChannelConfigGetConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditLineChannelConfigGetConfigQuery(baseOptions: Apollo.QueryHookOptions<EditLineChannelConfigGetConfigQuery, EditLineChannelConfigGetConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditLineChannelConfigGetConfigQuery, EditLineChannelConfigGetConfigQueryVariables>(EditLineChannelConfigGetConfigDocument, options);
      }
export function useEditLineChannelConfigGetConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditLineChannelConfigGetConfigQuery, EditLineChannelConfigGetConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditLineChannelConfigGetConfigQuery, EditLineChannelConfigGetConfigQueryVariables>(EditLineChannelConfigGetConfigDocument, options);
        }
export type EditLineChannelConfigGetConfigQueryHookResult = ReturnType<typeof useEditLineChannelConfigGetConfigQuery>;
export type EditLineChannelConfigGetConfigLazyQueryHookResult = ReturnType<typeof useEditLineChannelConfigGetConfigLazyQuery>;
export type EditLineChannelConfigGetConfigQueryResult = Apollo.QueryResult<EditLineChannelConfigGetConfigQuery, EditLineChannelConfigGetConfigQueryVariables>;
export const EditLineChannelGetLineOfficialAccountDocument = gql`
    query EditLineChannelGetLineOfficialAccount($companyId: String!) {
  validLineOfficialAccounts(input: {companyId: $companyId}) {
    id
    lineId
    lineOfficialAccountId
  }
}
    `;

/**
 * __useEditLineChannelGetLineOfficialAccountQuery__
 *
 * To run a query within a React component, call `useEditLineChannelGetLineOfficialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditLineChannelGetLineOfficialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditLineChannelGetLineOfficialAccountQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditLineChannelGetLineOfficialAccountQuery(baseOptions: Apollo.QueryHookOptions<EditLineChannelGetLineOfficialAccountQuery, EditLineChannelGetLineOfficialAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditLineChannelGetLineOfficialAccountQuery, EditLineChannelGetLineOfficialAccountQueryVariables>(EditLineChannelGetLineOfficialAccountDocument, options);
      }
export function useEditLineChannelGetLineOfficialAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditLineChannelGetLineOfficialAccountQuery, EditLineChannelGetLineOfficialAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditLineChannelGetLineOfficialAccountQuery, EditLineChannelGetLineOfficialAccountQueryVariables>(EditLineChannelGetLineOfficialAccountDocument, options);
        }
export type EditLineChannelGetLineOfficialAccountQueryHookResult = ReturnType<typeof useEditLineChannelGetLineOfficialAccountQuery>;
export type EditLineChannelGetLineOfficialAccountLazyQueryHookResult = ReturnType<typeof useEditLineChannelGetLineOfficialAccountLazyQuery>;
export type EditLineChannelGetLineOfficialAccountQueryResult = Apollo.QueryResult<EditLineChannelGetLineOfficialAccountQuery, EditLineChannelGetLineOfficialAccountQueryVariables>;
export const EditLineChannelConfigUpdateConfigDocument = gql`
    mutation EditLineChannelConfigUpdateConfig($input: lineChannelConfig_insert_input!, $shopId: uuid!, $lineOfficialAccountId: uuid, $_lineOfficialAccountId: Int) {
  insert_lineChannelConfig_one(
    object: $input
    on_conflict: {constraint: lineMessagingConfig_shopId_key, update_columns: [channelId, channelSecret, liffId]}
  ) {
    id
  }
  update_shop_by_pk(
    pk_columns: {shopId: $shopId}
    _set: {lineOfficialAccountId: $lineOfficialAccountId, _lineOfficialAccountId: $_lineOfficialAccountId}
  ) {
    shopId
  }
}
    `;
export type EditLineChannelConfigUpdateConfigMutationFn = Apollo.MutationFunction<EditLineChannelConfigUpdateConfigMutation, EditLineChannelConfigUpdateConfigMutationVariables>;

/**
 * __useEditLineChannelConfigUpdateConfigMutation__
 *
 * To run a mutation, you first call `useEditLineChannelConfigUpdateConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLineChannelConfigUpdateConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLineChannelConfigUpdateConfigMutation, { data, loading, error }] = useEditLineChannelConfigUpdateConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *      shopId: // value for 'shopId'
 *      lineOfficialAccountId: // value for 'lineOfficialAccountId'
 *      _lineOfficialAccountId: // value for '_lineOfficialAccountId'
 *   },
 * });
 */
export function useEditLineChannelConfigUpdateConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditLineChannelConfigUpdateConfigMutation, EditLineChannelConfigUpdateConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLineChannelConfigUpdateConfigMutation, EditLineChannelConfigUpdateConfigMutationVariables>(EditLineChannelConfigUpdateConfigDocument, options);
      }
export type EditLineChannelConfigUpdateConfigMutationHookResult = ReturnType<typeof useEditLineChannelConfigUpdateConfigMutation>;
export type EditLineChannelConfigUpdateConfigMutationResult = Apollo.MutationResult<EditLineChannelConfigUpdateConfigMutation>;
export type EditLineChannelConfigUpdateConfigMutationOptions = Apollo.BaseMutationOptions<EditLineChannelConfigUpdateConfigMutation, EditLineChannelConfigUpdateConfigMutationVariables>;