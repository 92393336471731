import { useCallback } from "react";
import { getRole } from "models/user";

import { Feature, featurePermissions } from "constants/featurePermissions";
import { useCurrentUserPermissions } from "hooks/useCurrentUserPermissions";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

export const useIsFeatureEnabled = () => {
  const user = useCurrentUser();
  const role = getRole(user);
  const { hasPermission } = useCurrentUserPermissions();

  const isFeatureEnabled = useCallback(
    (feature: Feature) => {
      if (role === undefined) return false;

      // service admin はすべての機能にアクセスできる
      if (role === Role.ServiceAdmin) return true;

      const featureAccessiblePermission = featurePermissions[feature];

      if (!featureAccessiblePermission) {
        return false;
      }

      return hasPermission(featureAccessiblePermission);
    },
    [role, hasPermission],
  );

  return { isFeatureEnabled };
};
