import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Switch, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { Category, Shop } from "../types";

export type FilterConditions = {
  shopId?: string;
  categoryIds?: number[];
  name?: string;
  showOnlyMenusWithoutCode?: boolean;
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  shops: Shop[];
  categories: Category[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const MenuFilter = memo<Props>(
  ({
    shops,
    categories,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => (
    <Wrapper>
      <Col flex="1 0">
        <Select<string>
          showSearch
          allowClear
          placeholder="店舗"
          value={filterConditions.shopId}
          onChange={(shopId) => updateFilterCondition({ shopId })}
          optionFilterProp="label"
          options={shops.map(({ shopId, name }) => ({ label: name, value: shopId }))}
        />
      </Col>
      <Col flex="1 0">
        <Select<number[]>
          mode="multiple"
          allowClear
          placeholder="カテゴリ"
          defaultValue={[]}
          value={filterConditions.categoryIds ?? []}
          onChange={(categoryIds) => updateFilterCondition({ categoryIds })}
          optionFilterProp="label"
          options={categories.map(({ categoryId, name, shopSideName }) => ({
            label: shopSideName || name,
            value: categoryId,
          }))}
        />
      </Col>
      <Col flex="1 0">
        <Input
          allowClear
          placeholder="メニュー名"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
        />
      </Col>
      <Col flex="1 0">
        未設定のみ{" "}
        <Switch
          checked={Boolean(filterConditions.showOnlyMenusWithoutCode)}
          onChange={(value) => updateFilterCondition({ showOnlyMenusWithoutCode: value })}
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
