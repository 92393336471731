import React, { memo } from "react";
import { Col, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { FormItemProps } from "components/antd/Form";
import { FormHelp } from "components/Form/FormHelp";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const IssuerField = memo<Props>((props) => (
  <Row>
    <Col span={12}>
      <EditCashRegisterConfigFormItem
        label={
          <FormHelp
            label="伝票記載住所"
            help="伝票には1行で12文字入力できます。レイアウトを整えるため適度に改行ください。"
          />
        }
        name="issuer"
        wrapperCol={{ span: 20 }}
        {...props}
      >
        <TextArea
          placeholder="〒105-8001 東京都港区芝浦1-1-1 浜松町ビルディング (東芝ビルディング) 15階 D区画"
          rows={4}
        />
      </EditCashRegisterConfigFormItem>
    </Col>
  </Row>
));
