import dayjs from "dayjs";
import { addHours, getHours } from "util/duration";
import { normalizeHoursAndMinutes } from "util/time";

export const isValidSpecificTimeSurchargeHoursDuration = ({
  start,
  end,
  changeDateTime,
}: {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const startTime = normalizeHoursAndMinutes(start);
  const endTime = normalizeHoursAndMinutes(end);

  const businessOperationHourStart = startTime.isBefore(baseTime)
    ? startTime.add(24, "hours")
    : startTime;
  const businessOperationHourEnd = endTime.isSameOrBefore(baseTime)
    ? endTime.add(24, "hours")
    : endTime;

  return businessOperationHourStart.isBefore(businessOperationHourEnd);
};

export const getDateTimeBasedOnChangeDateTime = ({
  dateTime,
  changeDateTime,
}: {
  dateTime: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const time = normalizeHoursAndMinutes(dateTime);
  return time.isBefore(baseTime) ? addHours(time.format("HH:mm"), 24) : time.format("HH:mm");
};

export const isDateChanged = ({
  dateTime,
  changeDateTime,
}: {
  dateTime: dayjs.Dayjs;
  changeDateTime: string;
}) => {
  const baseTime = getHours(changeDateTime);
  const time = normalizeHoursAndMinutes(dateTime);
  return time.isBefore(baseTime);
};
