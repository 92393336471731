import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { Spacer } from "components/Spacer";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";

import { useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery } from "./queries";
import { ShopExternalOnlineMenuTable } from "./ShopExternalOnlineMenuTable";
import { ExternalOnlineMenu } from "./types";

export const ExternalOnlineMenus = () => {
  const [company] = useCompany();
  const companyId = company?.companyId;

  const [shop] = useShop();
  const shopId = shop?.shopId ?? null;

  const { data, loading, error } = useExternalOnlineMenusGetExternalOnlineMenuAndShopQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const changeDateTime = data?.shop_by_pk?.changeDateTime;
  const externalOnlineMenuConfig = data?.externalOnlineMenuConfig[0];

  const externalOnlineMenus: ExternalOnlineMenu[] = useMemo(
    () =>
      externalOnlineMenuConfig
        ? [
            {
              id: externalOnlineMenuConfig.id,
              name: "グランドメニュー",
              url: externalOnlineMenuConfig.defaultMenuUrl ?? "",
              externalOnlineMenuAvailableTimeTerms: [],
            },
            ...externalOnlineMenuConfig.externalOnlineMenus,
          ]
        : [],
    [externalOnlineMenuConfig],
  );

  return (
    <DashboardLayout title="外部オンラインメニュー">
      <PageHeader
        title="外部オンラインメニュー"
        extra={[
          <Link key="add" to={`/shop/${shopId}/externalOnlineMenu/add`}>
            <Button type="primary">新規作成</Button>
          </Link>,
        ]}
      />
      <span>
        オンラインメニューの表示を設定できます。利用時間の設定がないものを標準で表示して、利用時間の設定がある場合はその時間にそのメニューを表示します。
      </span>
      <Spacer height={16} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spacer height={16} />
      <ShopSelector />
      {changeDateTime && (
        <ShopExternalOnlineMenuTable
          externalOnlineMenus={externalOnlineMenus}
          externalOnlineMenuConfigId={externalOnlineMenuConfig?.id}
          shopId={shopId}
          changeDateTime={changeDateTime}
          loading={loading}
        />
      )}
    </DashboardLayout>
  );
};
