import { useEffect } from "react";
import { isSoldOut } from "models/stock";

import type { DishUpSlipGroup, KdDisplayTarget, Menu, Role } from "pages/ShopMenus/types";

import { isBulkEditConditionValueChanged } from "./functions";
import type { BulkEditConditions, BulkEditConditionsEntries, Entries } from "./types";

const excludeKeys = ["isSoldOut", "currentStockNum", "dailyStockNum"] as const;
type ExcludeKeys = typeof excludeKeys[number];

const filterCondition = (
  entry: BulkEditConditionsEntries[number],
): entry is Entries<Required<Omit<BulkEditConditions, ExcludeKeys>>>[number] => {
  const [key, value] = entry;

  return excludeKeys.every((v) => v !== key) && isBulkEditConditionValueChanged(value);
};

export const useApplyBulkEditConditionsToMenus = ({
  menus,
  bulkEditConditions,
  currentPageSelectedMenuIds,
  roles,
  dishUpSlipGroups,
  kdDisplayTargets,
  setDisplayedMenus,
}: {
  menus: Menu[];
  bulkEditConditions: BulkEditConditions;
  currentPageSelectedMenuIds: number[];
  roles: Role[];
  dishUpSlipGroups: DishUpSlipGroup[];
  kdDisplayTargets: KdDisplayTarget[];
  setDisplayedMenus: React.Dispatch<React.SetStateAction<Menu[]>>;
}) => {
  useEffect(() => {
    const menuAppliedConditions = Object.fromEntries(
      (Object.entries(bulkEditConditions) as BulkEditConditionsEntries)
        .filter(filterCondition)
        .map(([key, value]) => {
          if (key === "kitchenRoleIds" && Array.isArray(value)) {
            return [
              "shopMenuKitchenRoles",
              roles
                .filter((role) => value.some((id) => id === role.roleId))
                .map((role) => ({ role })),
            ];
          }

          if (key === "kitchenRoleIds" && value === "empty") {
            return ["shopMenuKitchenRoles", []];
          }

          if (key === "dishUpSlipGroupIds" && Array.isArray(value)) {
            return [
              "dishUpSlipGroupShopMenus",
              dishUpSlipGroups
                .filter((dishUpSlipGroup) => value.some((id) => id === dishUpSlipGroup.id))
                .map((dishUpSlipGroup) => ({ dishUpSlipGroup })),
            ];
          }

          if (key === "dishUpSlipGroupIds" && value === "empty") {
            return ["dishUpSlipGroupShopMenus", []];
          }

          if (key === "kdDisplayTargetIds" && Array.isArray(value)) {
            return [
              "shopMenuKdDisplayTargets",
              kdDisplayTargets
                .filter((kdDisplayTarget) => value.some((id) => id === kdDisplayTarget.id))
                .map((kdDisplayTarget) => ({ kdDisplayTarget })),
            ];
          }

          if (key === "kdDisplayTargetIds" && value === "empty") {
            return ["shopMenuKdDisplayTargets", []];
          }

          return [key, value];
        }),
    );

    const nextMenus = menus.map((menu) => {
      if (!currentPageSelectedMenuIds.includes(menu.menuId)) return menu;

      const soldOut = bulkEditConditions.isSoldOut ?? isSoldOut({ stock: menu.stock });
      const defaultCurrentStockNum =
        menu.stock?.currentStockNum !== 0 ? menu.stock?.currentStockNum : null;

      const nextStock = {
        currentStockNum: soldOut ? 0 : bulkEditConditions.currentStockNum ?? defaultCurrentStockNum,
        dailyStockNum: bulkEditConditions.dailyStockNum ?? menu.stock?.dailyStockNum,
      };

      return {
        ...menu,
        ...menuAppliedConditions,
        stock: nextStock,
      };
    });

    setDisplayedMenus(nextMenus);
  }, [
    menus,
    bulkEditConditions,
    currentPageSelectedMenuIds,
    roles,
    dishUpSlipGroups,
    kdDisplayTargets,
    setDisplayedMenus,
  ]);
};
