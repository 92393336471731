import { useCallback } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";
import {
  emptyNumericFilter,
  getBusinessOperationHourType,
  getMessageDeliveryDayOfWeekTypeType,
  getMessageDeliveryGender,
  getMessageDeliveryQuestionnaireAnswerStatusType,
  MenusFilter,
  NumericFilter,
} from "models/customerSegment";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { floor } from "util/dayjs";
import { isNotNull, isNotNullable } from "util/type/primitive";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { MessageFormValue } from "components/MessageDeliveryForm/types";
import { useCompany } from "hooks/useCompany";
import { Scope } from "pages/EditMessageDelivery/EditMessageDeliveryForm/ScopeField";
import { MessageDelivery, Shop } from "pages/EditMessageDelivery/types";
import {
  BusinessOperationHourTypeEnum,
  MessageDeliveryCustomerSegmentDayOfWeekTypeEnum,
  MessageDeliveryCustomerSegmentGenderEnum,
  MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum,
  MessageDeliveryMessageTypeEnum,
  UpdateMessageDeliveryInput,
} from "types/graphql";

type MessageDeliveryFormOptionalValues = Partial<{
  customerIds: string[];
  customerIdsFromCsv: string[];
  uploadedCsv: { file: UploadFile; fileList: UploadFile[] };
  visitedDayCountFilter: NumericFilter;
  consecutiveUnvisitedDayCountFilter: NumericFilter;
  visitedShopsFilterShopIds: string[];
  visitedBusinessOperationHoursFilterBusinessOperationHourTypes: (
    | BusinessOperationHourTypeEnum
    | "All"
  )[];
  visitedDayOfWeekAllChecker: boolean;
  visitedDayOfWeekFilter: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum[];
  questionnaireAnswerStatusFilter:
    | MessageDeliveryCustomerSegmentQuestionnaireAnswerStatusTypeEnum
    | "All";
  orderedMenusFilter: Omit<MenusFilter, "type">;
  genderFilter: MessageDeliveryCustomerSegmentGenderEnum | "All";
  birthdayCountFilter: NumericFilter;
  orderedMenusFilterValidation: never;
}>;

export type EditMessageDeliveryFormValues = {
  scope: Scope;
  messages: MessageFormValue[];
  deliverDate: dayjs.Dayjs;
  deliverTime: dayjs.Dayjs;
  isRepeatDelivery: boolean;
} & MessageDeliveryFormOptionalValues &
  Pick<
    MessageDelivery,
    | "name"
    | "isSuspended"
    | "customerSegmentId"
    | "customerSegment"
    | "customerListId"
    | "customerList"
  >;

const getInitialDayCountFilterValue = (filter: NumericFilter) => {
  const { moreThanOrEqual, equal, lessThanOrEqual } = filter;

  if (equal && !lessThanOrEqual && !moreThanOrEqual) {
    return {
      equal,
      moreThanOrEqual: equal,
      lessThanOrEqual: equal,
    };
  }

  return {
    type: "numeric",
    moreThanOrEqual,
    equal,
    lessThanOrEqual,
  };
};

const getInitialValues = ({
  messageDelivery,
}: {
  messageDelivery: MessageDelivery;
}): EditMessageDeliveryFormValues => {
  const {
    name,
    customerSegmentId,
    customerListId,
    repeatDeliveryTime,
    messageDeliveryMessages,
    customerSegment,
    isSuspended,
  } = messageDelivery;

  const deliverAt = dayjs(messageDelivery.deliverAt);

  const visitedDayCountFilter = getInitialDayCountFilterValue(
    customerSegment?.messageDeliveryCustomerSegmentVisitedDayCountCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter,
  );
  const consecutiveUnvisitedDayCountFilter = getInitialDayCountFilterValue(
    customerSegment?.messageDeliveryCustomerSegmentUnvisitedDayCountCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter,
  );
  const visitedShopsFilter = customerSegment?.messageDeliveryCustomerSegmentShopConditions.map(
    ({ shopId }) => shopId,
  );
  const visitedBusinessOperationHoursFilter =
    customerSegment?.messageDeliveryCustomerSegmentBusinessOperationHoursConditions.map(
      ({ businessOperationHourType }) => businessOperationHourType,
    ) ?? [];

  const visitedDayOfWeekFilter =
    customerSegment?.messageDeliveryCustomerSegmentDayOfWeekConditions.map(
      ({ messageDeliveryDayOfWeek }) => messageDeliveryDayOfWeek,
    ) ?? [];

  const visitedDayOfWeekAllChecker =
    visitedDayOfWeekFilter.length === 8 || visitedDayOfWeekFilter.length === 0;

  const questionnaireAnswerStatusFilter =
    customerSegment?.messageDeliveryCustomerSegmentQuestionnaireAnswerCondition
      ?.messageDeliveryQuestionnaireAnswerStatus ?? "All";

  const menuConditions = customerSegment?.messageDeliveryCustomerSegmentMenuConditions;
  const orderedMenusFilter = menuConditions?.length
    ? {
        type: "menu",
        menuIds: menuConditions.map(({ menuId }) => menuId),
        moreThanOrEqualQuantity: menuConditions[0]?.moreThanOrEqualQuantity ?? null,
      }
    : undefined;

  const genderFilter =
    customerSegment?.messageDeliveryCustomerSegmentGenderCondition?.messageDeliveryGender ?? "All";

  const birthdayCountFilter =
    customerSegment?.messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition
      ?.messageDeliveryCustomerSegmentNumericCondition ?? emptyNumericFilter;

  const customerIds =
    messageDelivery.customerList?.customerListCustomers?.map(({ customer }) => customer.id) ?? [];

  const isFromCsv = messageDelivery.customerList?.isFromCsv;
  const uploadedCsvFile =
    isFromCsv && messageDelivery.customerListId
      ? {
          uid: messageDelivery.customerListId,
          name: "顧客リスト",
        }
      : undefined;

  return {
    name,
    scope: customerSegmentId
      ? "customerSegment"
      : customerListId && isFromCsv
      ? "customerListCsvUpload"
      : customerListId
      ? "customerList"
      : "broadcast",
    customerSegmentId,
    visitedDayCountFilter,
    visitedDayOfWeekAllChecker,
    visitedDayOfWeekFilter: visitedDayOfWeekAllChecker ? [] : visitedDayOfWeekFilter,
    consecutiveUnvisitedDayCountFilter,
    questionnaireAnswerStatusFilter,
    visitedBusinessOperationHoursFilterBusinessOperationHourTypes:
      visitedBusinessOperationHoursFilter.length === 0
        ? ["All"]
        : visitedBusinessOperationHoursFilter,
    visitedShopsFilterShopIds: visitedShopsFilter,
    orderedMenusFilter,
    genderFilter,
    birthdayCountFilter,
    customerListId,
    deliverDate: deliverAt,
    deliverTime: deliverAt,
    isRepeatDelivery: Boolean(repeatDeliveryTime),
    customerIds: !isFromCsv ? customerIds : [],
    customerIdsFromCsv: isFromCsv ? customerIds : [],
    uploadedCsv:
      isFromCsv && uploadedCsvFile
        ? { file: uploadedCsvFile, fileList: [uploadedCsvFile] }
        : undefined,
    isSuspended,
    messages: messageDeliveryMessages
      .map((messageDeliveryMessage) => {
        const { type, textTypeMeta, imageTypeMeta, couponTypeMeta, questionnaireTypeMeta } =
          messageDeliveryMessage;

        if (type === MessageDeliveryMessageTypeEnum.Text && textTypeMeta) {
          return { type, text: textTypeMeta.text };
        }

        if (type === MessageDeliveryMessageTypeEnum.Image && imageTypeMeta) {
          return { type, imageUrl: imageTypeMeta.imageUrl, url: imageTypeMeta.url };
        }

        if (type === MessageDeliveryMessageTypeEnum.Coupon && couponTypeMeta) {
          return { type, couponId: couponTypeMeta.couponId };
        }

        if (type === MessageDeliveryMessageTypeEnum.Questionnaire && questionnaireTypeMeta) {
          return { type, questionnaireConfigId: questionnaireTypeMeta.questionnaireConfigId };
        }

        return null;
      })
      .filter(isNotNull),
  };
};

export const EditMessageDeliveryFormItem = createFormItem<EditMessageDeliveryFormValues>();

export const useEditMessageDeliveryForm = ({
  messageDelivery,
  inputDisabled,
  onSubmit,
  onSuspend,
  onResume,
  onFormValidationError,
  shops,
}: {
  messageDelivery: MessageDelivery;
  inputDisabled: boolean;
  onSubmit: ({ messageDelivery }: { messageDelivery: UpdateMessageDeliveryInput }) => void;
  onSuspend: () => void;
  onResume: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  shops: Shop[];
}) => {
  const [company] = useCompany();
  const companyId = company?.id;
  const _companyId = company?.companyId;

  const [form] = Form.useForm<EditMessageDeliveryFormValues>();

  const initialValues = getInitialValues({ messageDelivery });

  const handleSubmit = useCallback(
    async (
      { isDraft, isSuspended }: { isDraft: boolean; isSuspended: boolean } = {
        isDraft: false,
        isSuspended: false,
      },
    ) => {
      if (!companyId || !_companyId) return;

      try {
        await form.validateFields();

        const formValues = form.getFieldsValue();

        const deliveryDate = dayjs(formValues.deliverDate.toDate());
        const deliveryTime = floor(dayjs(formValues.deliverTime.toDate()), "minutes", 10);
        const deliverAt = deliveryDate.hour(deliveryTime.hour()).minute(deliveryTime.minute());

        const menuFilterMoreThanOrEqualQuantity =
          formValues.orderedMenusFilter?.moreThanOrEqualQuantity;
        const newMessageDelivery: UpdateMessageDeliveryInput = {
          id: messageDelivery.id,
          name: formValues.name,
          isSuspended,
          companyId,
          _companyId,
          scope: formValues.scope === "broadcast" ? "broadcast" : "narrowcast",
          customerSegment:
            formValues.scope === "customerSegment" && !formValues.customerSegmentId
              ? {
                  visitedDayCountCondition: {
                    messageDeliveryCustomerSegmentNumericCondition:
                      formValues.visitedDayCountFilter ?? emptyNumericFilter,
                  },
                  unvisitedDayCountCondition: {
                    messageDeliveryCustomerSegmentNumericCondition:
                      formValues.consecutiveUnvisitedDayCountFilter ?? emptyNumericFilter,
                  },
                  dayOfWeekConditions: formValues.visitedDayOfWeekAllChecker
                    ? []
                    : formValues.visitedDayOfWeekFilter?.map((dayOfWeek) => ({
                        messageDeliveryDayOfWeek: getMessageDeliveryDayOfWeekTypeType(dayOfWeek),
                      })) ?? [],
                  questionnaireAnswerCondition:
                    formValues.questionnaireAnswerStatusFilter === "All" ||
                    !formValues.questionnaireAnswerStatusFilter
                      ? undefined
                      : {
                          messageDeliveryQuestionnaireAnswerStatus:
                            getMessageDeliveryQuestionnaireAnswerStatusType(
                              formValues.questionnaireAnswerStatusFilter,
                            ),
                        },
                  businessOperationHoursConditions:
                    formValues.visitedBusinessOperationHoursFilterBusinessOperationHourTypes?.includes(
                      "All",
                    )
                      ? []
                      : formValues.visitedBusinessOperationHoursFilterBusinessOperationHourTypes
                          ?.map((businessOperationHourType) =>
                            getBusinessOperationHourType(businessOperationHourType),
                          )
                          .filter(isNotNullable)
                          .map((ExclusionTypeAllBusinessOperationHourType) => ({
                            businessOperationHourType: ExclusionTypeAllBusinessOperationHourType,
                          })) ?? [],
                  shopConditions: (formValues.visitedShopsFilterShopIds ?? []).map((shopId) => ({
                    shopId,
                  })),
                  menuConditions: menuFilterMoreThanOrEqualQuantity
                    ? formValues.orderedMenusFilter?.menuIds.map((menuId) => ({
                        menuId,
                        moreThanOrEqualQuantity: menuFilterMoreThanOrEqualQuantity,
                      })) ?? []
                    : [],
                  genderCondition:
                    formValues.genderFilter && formValues.genderFilter !== "All"
                      ? {
                          messageDeliveryGender: getMessageDeliveryGender(formValues.genderFilter),
                        }
                      : undefined,
                  daysCountUntilBirthdayCondition:
                    formValues.birthdayCountFilter &&
                    (formValues.birthdayCountFilter.equal ||
                      formValues.birthdayCountFilter.moreThanOrEqual ||
                      formValues.birthdayCountFilter.lessThanOrEqual)
                      ? {
                          messageDeliveryCustomerSegmentNumericCondition:
                            formValues.birthdayCountFilter ?? emptyNumericFilter,
                        }
                      : undefined,
                }
              : null,
          customerList:
            formValues.scope === "customerList"
              ? {
                  customerListCustomers: (formValues.customerIds ?? []).map((id) => ({
                    customerId: id,
                  })),
                  isFromCsv: false,
                }
              : formValues.scope === "customerListCsvUpload"
              ? {
                  customerListCustomers: (formValues.customerIdsFromCsv ?? []).map((id) => ({
                    customerId: id,
                  })),
                  isFromCsv: true,
                }
              : null,
          isDraft,
          deliverAt: deliverAt.toISOString(),
          repeatDeliveryTime: formValues.isRepeatDelivery
            ? deliveryTime.format("HH:mm:ssZZ")
            : null,
          messageDeliveryMessages: formValues.messages.map((message, index) => ({
            type: message.type,
            ...(message.type === MessageDeliveryMessageTypeEnum.Text
              ? { textTypeMeta: { text: message.text } }
              : message.type === MessageDeliveryMessageTypeEnum.Image
              ? {
                  imageTypeMeta: { imageUrl: message.imageUrl, url: message.url },
                }
              : message.type === MessageDeliveryMessageTypeEnum.Coupon
              ? { couponTypeMeta: { couponId: message.couponId } }
              : message.type === MessageDeliveryMessageTypeEnum.Questionnaire
              ? {
                  questionnaireTypeMeta: {
                    questionnaireConfigId: message.questionnaireConfigId,
                  },
                }
              : {}),
            position: index,
          })),
        };
        onSubmit({ messageDelivery: newMessageDelivery });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    },
    [_companyId, companyId, form, messageDelivery.id, onFormValidationError, onSubmit],
  );

  const handleSubmitAsDraft = useCallback(
    () => handleSubmit({ isDraft: true, isSuspended: false }),
    [handleSubmit],
  );

  const handleSubmitAsSuspended = useCallback(
    () => handleSubmit({ isDraft: false, isSuspended: true }),
    [handleSubmit],
  );

  // フォームの入力が制限されている場合、フォームの送信は行わない
  const submit = useCallback(
    () => (inputDisabled ? onResume() : handleSubmit()),
    [handleSubmit, inputDisabled, onResume],
  );
  const submitAsSuspended = useCallback(
    () => (inputDisabled ? onSuspend() : handleSubmitAsSuspended()),
    [handleSubmitAsSuspended, inputDisabled, onSuspend],
  );

  return {
    form,
    initialValues,
    submit,
    submitAsSuspended,
    submitAsDraft: handleSubmitAsDraft,
  };
};
