import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPikaichiOnSitePaymentDetailTypeFormItem } from "../useEditPikaichiOnSitePaymentDetailTypeForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiMediaCdField = memo<Props>((props) => (
  <EditPikaichiOnSitePaymentDetailTypeFormItem
    label="ぴかいちメディアコード"
    name="paymentMediaCd"
    rules={[{ required: true, message: "メディアコードを入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditPikaichiOnSitePaymentDetailTypeFormItem>
));
