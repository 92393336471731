import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Typography } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import getGoogleMapPlaceId from "../../../assets/get_google_map_place_id.png";
import { Shop } from "../types";

import { EnableGoogleMapReviewField } from "./EnableGoogleMapReviewField";
import { GoogleMapPlaceIdField } from "./GoogleMapPlaceIdField";
import {
  EditGoogleMapConfigFormValues,
  useEditGoogleMapConfigForm,
} from "./useEditGoogleMapConfigForm";

type Props = {
  shop: Shop;
  loading: boolean;
  onSubmit: (values: EditGoogleMapConfigFormValues) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const LinkWrapper = styled.div`
  margin-bottom: 8px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const EditGoogleMapConfigForm = memo<Props>(
  ({ shop, loading, onSubmit, onClose, onFormValidationError }) => {
    const { form, initialValues, submit } = useEditGoogleMapConfigForm({
      shop,
      onSubmit,
    });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, onFormValidationError, submit]);

    return (
      <>
        <FormContent>
          <Form name="googleMapConfig" form={form} layout="vertical" initialValues={initialValues}>
            <Row>
              <Col span={12}>
                <LinkWrapper>
                  <Typography.Link
                    href="https://developers.google.com/maps/documentation/places/web-service/place-id?hl=ja#find-id"
                    target="_blank"
                  >
                    こちらのページ
                  </Typography.Link>
                  から店舗の所在地を検索し、プレイス IDを取得してください。
                </LinkWrapper>
                <GoogleMapPlaceIdField />
                <EnableGoogleMapReviewField />
              </Col>
              <Col span={12}>
                <Image alt="Google Map プレイスIDを取得する方法" src={getGoogleMapPlaceId} />
              </Col>
            </Row>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button loading={loading} onClick={onClose}>
            キャンセル
          </Button>
          <Button loading={loading} type="primary" onClick={handleSubmit}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
