import React, { useCallback, useMemo } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Divider } from "antd";
import { removeTypename } from "util/apollo";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { AddPlanFirstOrderCategoryMenuModal } from "pages/PlanFirstOrderCategoryMenus/AddPlanFirstOrderCategoryMenuModal";
import { EditPlanChoiceCountAsFirstOrderMenuQuantityForm } from "pages/PlanFirstOrderCategoryMenus/EditPlanChoiceCountAsFirstOrderMenuQuantityForm";
import { EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues } from "pages/PlanFirstOrderCategoryMenus/EditPlanChoiceCountAsFirstOrderMenuQuantityForm/useEditPlanChoiceCountAsFirstOrderMenuQuantityForm";
import { MenuTable } from "pages/PlanFirstOrderCategoryMenus/MenuTable";
import {
  usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation,
  usePlanFirstOrderCategoryMenusGetPlanQuery,
  usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation,
  usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation,
} from "pages/PlanFirstOrderCategoryMenus/queries";

import { CategoryMenu } from "./types";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const PlanFirstOrderCategoryMenus = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    refetch,
    loading: loadingPlan,
    error,
  } = usePlanFirstOrderCategoryMenusGetPlanQuery(
    !Number.isNaN(planId) ? { variables: { planId } } : { skip: true },
  );
  const plan = getPlanData?.plan?.[0];

  useSetCurrentCompanyEffect(plan);

  const categoryMenus =
    plan?.planFirstOrderCategoryMenus.map(({ categoryMenu }) => categoryMenu) ?? [];
  const planOptions = useMemo(() => plan?.planOptions ?? [], [plan]);
  const planChoices = useMemo(
    () => planOptions.flatMap(({ planChoices }) => planChoices),
    [planOptions],
  );
  const hasChoiceToCountAsFirstOrderMenuQuantity = planChoices.some(
    (choice) => choice.countAsFirstOrderMenuQuantity,
  );
  const hasFirstOrderCategoryMenus = categoryMenus.length > 0;
  const mustSetCountAsFirstOrderMenuQuantity =
    !hasChoiceToCountAsFirstOrderMenuQuantity && hasFirstOrderCategoryMenus;

  const [
    deletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation,
    { loading: loadingDeletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu },
  ] = usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation();

  const onRemove = useCallback(
    async (categoryMenuId: number) => {
      const planId = plan?.planId;
      if (planId) {
        try {
          const { data: deletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu } =
            await deletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation({
              variables: { planId, categoryMenuId },
            });
          const returning =
            deletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu
              ?.delete_planFirstOrderCategoryMenu?.returning?.[0];

          if (returning === undefined) {
            throw new Error("");
          }

          refetch();

          message.success("プランから削除しました");
        } catch (err) {
          message.error("プランからの削除に失敗しました");
        }
      }
    },
    [
      plan?.planId,
      deletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation,
      refetch,
    ],
  );

  const [insertPlanChoicesMutation, { loading: loadingInsertPlanChoicesMutation }] =
    usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation();

  const onChangePlanChoiceCountAsFirstOrderMenuQuantity = useCallback(
    async (formValues: EditPlanChoiceCountAsFirstOrderMenuQuantityFormValues) => {
      const { countAsFirstOrderMenuQuantity } = formValues;
      const planChoiceInsertInputs = removeTypename(
        planChoices.map((planChoice) => {
          const { planChoiceId, planOptionId, _planOptionId, ...rest } = planChoice;

          return {
            ...rest,
            serial: planChoiceId,
            planOptionId,
            _planOptionId,
            countAsFirstOrderMenuQuantity: countAsFirstOrderMenuQuantity.includes(
              planChoice.planChoiceId,
            ),
          };
        }),
      );

      try {
        const { data: insertPlanChoices } = await insertPlanChoicesMutation({
          variables: { planChoices: planChoiceInsertInputs },
        });
        const returning = insertPlanChoices?.insert_planChoice?.returning ?? [];

        if (returning.length === 0) {
          throw new Error("");
        }

        refetch();

        message.success("自動注文数を保存しました");
      } catch (err) {
        message.error("自動注文数の保存に失敗しました");
      }
    },
    [planChoices, insertPlanChoicesMutation, refetch],
  );

  const [insertPlanFirstOrderCategoryMenus, { loading: loadingInsertPlanFirstOrderCategoryMenus }] =
    usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation();

  const onChangeOrder = useCallback(
    async (categoryMenus: CategoryMenu[]) => {
      if (!plan) throw new Error("plan is not found");

      const categoryMenusWithPriority = categoryMenus.map(
        ({ categoryMenuId: _categoryMenuId }, index) => {
          const categoryMenu = categoryMenus.find(
            ({ categoryMenuId }) => categoryMenuId === _categoryMenuId,
          );

          if (!categoryMenu) throw new Error("categoryMenu is not found");

          return {
            categoryMenuId: categoryMenu.id,
            _categoryMenuId: categoryMenu.categoryMenuId,
            planId: plan.id,
            _planId: plan.planId,
            priority: index,
          };
        },
      );

      try {
        await insertPlanFirstOrderCategoryMenus({
          variables: { planFirstOrderCategoryMenus: categoryMenusWithPriority },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetch();
    },
    [insertPlanFirstOrderCategoryMenus, plan, refetch],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const loading =
    loadingPlan ||
    loadingInsertPlanChoicesMutation ||
    loadingDeletePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu ||
    loadingInsertPlanFirstOrderCategoryMenus;

  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");
  const canEditPlan = isEditPlanFeatureEnabled && canEditAllShopPlans;

  return (
    <DashboardLayout title={plan?.planName}>
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={Boolean(error)}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {plan && (
        <FormContent>
          {mustSetCountAsFirstOrderMenuQuantity && (
            <StyledAlert
              message="自動注文数が設定されていません"
              description="自動注文するメニューの数として数えるオプションの選択肢を設定してください。自動注文数が設定されていないとお客様はこのプランを開始することができません。"
              type="error"
              showIcon
            />
          )}
          <EditPlanChoiceCountAsFirstOrderMenuQuantityForm
            plan={plan}
            onChange={onChangePlanChoiceCountAsFirstOrderMenuQuantity}
            disabled={!canEditPlan}
          />
          <Divider />
          {canEditPlan && (
            <ButtonWrapper>
              <Link key="addMenu" to={`/plan/${planId}/firstOrderCategoryMenu/add`}>
                <Button type="primary">追加</Button>
              </Link>
            </ButtonWrapper>
          )}
          <MenuTable
            menus={categoryMenus}
            loading={loading}
            onRemove={onRemove}
            onChangeOrder={onChangeOrder}
            canEditPlan={canEditPlan}
          />
          <Routes>
            <Route
              path="add"
              element={
                <AddPlanFirstOrderCategoryMenuModal
                  plan={plan}
                  goBack={goBack}
                  onUpdated={refetch}
                />
              }
            />
          </Routes>
        </FormContent>
      )}
    </DashboardLayout>
  );
};
