import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { CookingItem, Role } from "pages/EditCookingItem/types";

import { CookingItemSetInput, ShopCookingItemRoleInsertInput } from "../../../types/graphql";

export type EditCookingItemFormValues = {
  name: string;
  roleId: number;
};

const getInitialValues = (cookingItem: CookingItem) => {
  const { name, shopCookingItemRole } = cookingItem;
  return { name, roleId: shopCookingItemRole?.role?.roleId };
};

export const EditCookingItemFormItem = createFormItem<EditCookingItemFormValues>();

export const useEditCookingItemForm = ({
  shopId,
  cookingItem,
  roles,
  onSubmit,
}: {
  shopId: string;
  cookingItem: CookingItem;
  roles: Role[];
  onSubmit: (
    cookingItem: CookingItemSetInput,
    shopCookingItemRole:
      | ShopCookingItemRoleInsertInput
      | {
          roleId: undefined;
          _roleId: undefined;
        },
  ) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(cookingItem);

  const submit = useCallback(() => {
    if (!cookingItem) return;

    const values = form.getFieldsValue() as EditCookingItemFormValues;
    const role = roles.find((role) => role.roleId === values.roleId);

    onSubmit(
      {
        name: values.name,
      },
      role
        ? {
            cookingItemId: cookingItem.cookingItemId,
            _cookingItemId: cookingItem.id,
            roleId: role.id,
            _roleId: role.roleId,
            shopId,
          }
        : {
            roleId: undefined,
            _roleId: undefined,
          },
    );
  }, [cookingItem, form, onSubmit, roles, shopId]);

  return { form, initialValues, submit };
};
