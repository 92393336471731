import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";

import { ConnectGameConfig } from "../types";

import { maxWinningPercentagesForSelect } from "./MaxWinningPercentageField";

export type MaxWinningPercentageType = "select" | "custom";

export type ConsolationType = "losingPage" | "coupon";

export type EditConnectGameConfigFormValues = Pick<
  ConnectGameConfig,
  "enabled" | "startImageUrl" | "prizeCouponId" | "consolationCouponId"
> & {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  shopIds: string[];
  maxWinningPercentageType: MaxWinningPercentageType;
  selectedMaxWinningPercentage?: number;
  enteredMaxWinningPercentage?: number;
  consolationType: ConsolationType;
};

const maxWinningRateChoices = maxWinningPercentagesForSelect.map(({ value }) => value / 100);

const getInitialValues = ({
  enabled,
  startImageUrl,
  startTime,
  endTime,
  prizeCouponId,
  consolationCouponId,
  connectGameConfigShops,
  maxWinningRate,
}: ConnectGameConfig): EditConnectGameConfigFormValues => {
  const isMaxWinningRateSelected = maxWinningRateChoices.includes(maxWinningRate);
  return {
    enabled,
    startImageUrl,
    startTime: dayjs(startTime, "HH:mm"),
    endTime: dayjs(endTime, "HH:mm"),
    prizeCouponId,
    consolationCouponId,
    shopIds: connectGameConfigShops.map(({ shop }: { shop: { id: string } }) => shop.id),
    maxWinningPercentageType: isMaxWinningRateSelected ? "select" : "custom",
    selectedMaxWinningPercentage: isMaxWinningRateSelected ? maxWinningRate * 100 : undefined,
    enteredMaxWinningPercentage: isMaxWinningRateSelected ? undefined : maxWinningRate * 100,
    consolationType: consolationCouponId ? "coupon" : "losingPage",
  };
};

export const EditConnectGameConfigFormItem = createFormItem<EditConnectGameConfigFormValues>();

export const useEditConnectGameConfigForm = ({
  connectGameConfig,
  onSubmit,
}: {
  connectGameConfig: ConnectGameConfig;
  onSubmit: ({ connectGameConfig }: { connectGameConfig: EditConnectGameConfigFormValues }) => void;
}) => {
  const [form] = Form.useForm<EditConnectGameConfigFormValues>();
  const initialValues = getInitialValues(connectGameConfig);

  const submit = useCallback(
    ({ startImageUrl }: { startImageUrl: string }) => {
      const formValues = form.getFieldsValue();
      onSubmit({ connectGameConfig: { ...formValues, startImageUrl } });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
