import { useEffect } from "react";

import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { Maybe } from "types/Maybe";

/**
 * 現在選択中のエンティティの CompanyId, ShopId を global にセットする
 * タブの複製などに対応するため
 */
export const useSetCurrentCompanyEffect = (
  source?: Maybe<{
    shopId?: Maybe<string>;
    companyId: string;
  }>,
) => {
  const [, setCompany] = useCompany();
  const [, setShop] = useShop();

  useEffect(() => {
    if (source?.companyId) setCompany(source.companyId);
    if (source?.shopId) setShop(source.shopId);
  }, [source, setCompany, setShop]);
};
