export const isObject = (value: unknown): value is Record<string, unknown> =>
  value !== null && typeof value === "object";

export const isNumber = (value: unknown): value is number => typeof value === "number";

export const isNotNull = <T>(value: T): value is Exclude<T, null> => value !== null;

export const isNotUndefined = <T>(value: T): value is Exclude<T, undefined> => value !== undefined;

export const isNotNullable = <T>(value: T): value is Exclude<T, null | undefined> =>
  value !== null && value !== undefined;

export const isNot =
  <T extends string | number>(value: T) =>
  <U extends string | number>(v: U | T): v is Exclude<U, T> =>
    v !== value;
