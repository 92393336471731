import React, { memo } from "react";
import { Button } from "antd";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/EditCompany/EditCompanyForm/NameField";

import { Company } from "../types";

import { useEditCompanyForm } from "./useEditCompanyForm";

type Props = {
  company: Company;
};

export const EditCompanyForm = memo<Props>(({ company }) => {
  const { form, initialValues, loading, onSubmit, goBack } = useEditCompanyForm(company);
  return (
    <>
      <FormContent>
        <Form name="company" form={form} initialValues={initialValues} layout="vertical">
          <NameField />
        </Form>
      </FormContent>
      <Spacer size={24} />
      <FormActions>
        <Button onClick={goBack}>キャンセル</Button>
        <Button type="primary" loading={loading} onClick={onSubmit}>
          更新
        </Button>
      </FormActions>
    </>
  );
});
