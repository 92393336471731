import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { type ColumnsType } from "antd/lib/table";
import { AllTenantAverageScore } from "models/questionnaireAnalytics";

import { Loading } from "components/Loading";
import { Table } from "components/Table";
import { useLocationState } from "hooks/useLocationState";
import { useViewport } from "hooks/useViewport";
import { NameCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/NameCell";
import { RankCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/RankCell";
import {
  type DataSource,
  ResponsiveScoreList,
} from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/ResponsiveScoreList";
import { ScoreCell } from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/ScoreCell";
import {
  TABLE_MIN_HEIGHT,
  TableWrapper,
} from "pages/QuestionnaireAnalyticsScoresMetrics/ScoreList/TableWrapper";
import type {
  CorporationAverageScore,
  QuestionnaireScoreMetrics,
  ShopAverageScore,
} from "pages/QuestionnaireAnalyticsScoresMetrics/types";

const TextCentering = styled.div`
  text-align: center;
`;

const AnswerCountText = styled.span<{ isInactive: boolean }>`
  font-weight: bold;
  color: ${(props) => props.isInactive && "rgba(0, 0, 0, 0.4)"};
`;

const LoadMore = styled.div`
  text-align: center;
`;

const isShopScore = (
  score: ShopAverageScore | CorporationAverageScore | AllTenantAverageScore,
): score is ShopAverageScore => "shopId" in score;

const isCorporationScore = (
  score: ShopAverageScore | CorporationAverageScore | AllTenantAverageScore,
): score is CorporationAverageScore => "corporationId" in score;

const isShopTypeDataSource = (
  dataSource: DataSource,
): dataSource is DataSource & { type: "shop" } => "shopId" in dataSource;

const columns: ColumnsType<DataSource> = [
  {
    dataIndex: "rank",
    title: "",
    width: 64,
    align: "center",
    render(_, data) {
      if (isShopTypeDataSource(data)) return data.isEmpty ? null : <RankCell rank={data.ranking} />;

      return (
        <img
          src={`/questionnaire-analytics/shop_score_rank_${data.type}.png`}
          alt={`rank ${data.type}`}
          width="18"
          height="18"
        />
      );
    },
    sorter: (a, b) => {
      const aRanking = isShopTypeDataSource(a) ? a.ranking : 0;
      const bRanking = isShopTypeDataSource(b) ? b.ranking : 0;
      return aRanking - bRanking;
    },
  },
  {
    dataIndex: "name",
    title: "店舗名",
    render(_, data) {
      return (
        <NameCell
          name={data.name}
          shopId={isShopTypeDataSource(data) ? data.shopId : undefined}
          disabled={!isShopTypeDataSource(data)}
        />
      );
    },
    sorter: (a, b) => a.name.localeCompare(b.name, "ja"),
  },
  {
    dataIndex: "answerCount",
    title: "回答数",
    width: 100,
    render(_, data) {
      return (
        <TextCentering>
          <AnswerCountText isInactive={!isShopTypeDataSource(data)}>
            {data.answerCount}
          </AnswerCountText>
        </TextCentering>
      );
    },
    sorter: (a, b) => a.answerCount - b.answerCount,
  },
  {
    title: "再来店意欲",
    width: 136,
    render(_, data) {
      return (
        <ScoreCell
          score={data.repeatability}
          showIcon
          isInactive={!isShopTypeDataSource(data)}
          isEmpty={isShopTypeDataSource(data) ? data.isEmpty : false}
        />
      );
    },
    sorter: (a, b) => a.repeatability.score - b.repeatability.score,
  },
  {
    title: "接客",
    width: 110,
    render(_, data) {
      return (
        <ScoreCell
          score={data.customerService}
          isInactive={!isShopTypeDataSource(data)}
          isEmpty={isShopTypeDataSource(data) ? data.isEmpty : false}
        />
      );
    },
    sorter: (a, b) => a.customerService.score - b.customerService.score,
  },
  {
    title: "料理",
    width: 110,
    render(_, data) {
      return (
        <ScoreCell
          score={data.deliciousness}
          isInactive={!isShopTypeDataSource(data)}
          isEmpty={isShopTypeDataSource(data) ? data.isEmpty : false}
        />
      );
    },
    sorter: (a, b) => a.deliciousness.score - b.deliciousness.score,
  },
  {
    title: "提供速度",
    width: 110,
    render(_, data) {
      return (
        <ScoreCell
          score={data.speed}
          isInactive={!isShopTypeDataSource(data)}
          isEmpty={isShopTypeDataSource(data) ? data.isEmpty : false}
        />
      );
    },
    sorter: (a, b) => a.speed.score - b.speed.score,
  },
  {
    title: "清潔感",
    width: 110,
    render(_, data) {
      return (
        <ScoreCell
          score={data.cleanliness}
          isInactive={!isShopTypeDataSource(data)}
          isEmpty={isShopTypeDataSource(data) ? data.isEmpty : false}
        />
      );
    },
    sorter: (a, b) => a.cleanliness.score - b.cleanliness.score,
  },
];

type Props = {
  questionnaireScoreMetrics: QuestionnaireScoreMetrics | undefined;
  isLoading: boolean;
  isEmpty: boolean;
  lastMonthAllTenantAverageScore: AllTenantAverageScore;
};

type ComparisonLocationState = {
  isComparisonVisible: boolean;
};

export const ScoreList = memo<Props>(
  ({ questionnaireScoreMetrics, isLoading, isEmpty, lastMonthAllTenantAverageScore }) => {
    const [comparisonLocationState, setComparisonLocationState] =
      useLocationState<ComparisonLocationState>("isComparisonVisible", {
        isComparisonVisible: false,
      });

    const changeIsComparisonVisible = useCallback(
      () =>
        setComparisonLocationState({
          isComparisonVisible: !comparisonLocationState.isComparisonVisible,
        }),
      [comparisonLocationState, setComparisonLocationState],
    );

    const dataSource = useMemo<DataSource[] | undefined>(() => {
      if (!questionnaireScoreMetrics) return undefined;

      const { shopAverageScores, corporationAverageScore } = questionnaireScoreMetrics;

      // デフォルトは再来店意欲スコア順に並べる
      const sortedShopScores = [...shopAverageScores]
        .sort((a, b) => b.repeatability.score - a.repeatability.score)
        .map((shopScore, index) => ({ ...shopScore, ranking: index + 1 }));

      if (!comparisonLocationState.isComparisonVisible) {
        return sortedShopScores.map((score) => ({
          ...score,
          type: "shop",
          name: score.shopName,
          isEmpty,
        }));
      }

      return [...sortedShopScores, corporationAverageScore, lastMonthAllTenantAverageScore]
        .sort((a, b) => {
          const aRepeatabilityScore = isShopScore(a) ? a.repeatability.score : a.repeatability;
          const bRepeatabilityScore = isShopScore(b) ? b.repeatability.score : b.repeatability;

          return bRepeatabilityScore - aRepeatabilityScore;
        })
        .map((score) =>
          isShopScore(score)
            ? {
                ...score,
                type: "shop",
                name: score.shopName,
                isEmpty,
              }
            : {
                ...(isCorporationScore(score)
                  ? {
                      type: "corporation",
                      name: "社内平均",
                      corporationId: score.corporationId,
                    }
                  : {
                      type: "dinii",
                      name: "ダイニー導入店平均",
                    }),
                answerCount: score.answerCount,
                repeatability: {
                  score: score.repeatability,
                  fluctuation: 0,
                },
                customerService: {
                  score: score.customerService,
                  fluctuation: 0,
                },
                deliciousness: {
                  score: score.deliciousness,
                  fluctuation: 0,
                },
                speed: {
                  score: score.speed,
                  fluctuation: 0,
                },
                cleanliness: {
                  score: score.cleanliness,
                  fluctuation: 0,
                },
              },
        );
    }, [
      comparisonLocationState.isComparisonVisible,
      isEmpty,
      questionnaireScoreMetrics,
      lastMonthAllTenantAverageScore,
    ]);

    const { isDesktop } = useViewport();

    return (
      <>
        <TableWrapper
          isComparisonVisible={comparisonLocationState.isComparisonVisible}
          onComparisonButtonClick={changeIsComparisonVisible}
        >
          {isLoading ? (
            <Loading height={TABLE_MIN_HEIGHT} />
          ) : isDesktop ? (
            <Table columns={columns} dataSource={dataSource} pagination={false} bordered={false} />
          ) : (
            <ResponsiveScoreList dataSource={dataSource} />
          )}
        </TableWrapper>
      </>
    );
  },
);
