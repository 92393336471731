import Fuse from "fuse.js";
import { hiraganaToKatakana } from "util/japanese";

import { isUUID } from "libs/isUUID";
import { Company, Shop } from "types/graphql";

export type FilterConditions = {
  name?: string;
};

export const filterCompany = <
  T extends Pick<Company, "companyId" | "name"> & {
    shops?: Pick<Shop, "shopId" | "name">[];
  },
>(
  companies: T[],
  { name }: FilterConditions,
) => {
  if (name) {
    if (isUUID(name)) {
      const candidateCompany = companies.find(({ shops }) =>
        shops?.some(({ shopId }) => shopId === name),
      );

      if (candidateCompany) {
        return [candidateCompany];
      }
    }

    if (!isNaN(Number(name))) {
      const targetCompanyId = Number(name);
      const targetCompany = companies.find(({ companyId }) => targetCompanyId === companyId);

      if (targetCompany) {
        return [targetCompany];
      }
    }

    const katakanaName = hiraganaToKatakana(name);

    const companyFuse = new Fuse(
      companies.map(({ companyId, name }) => ({ companyId, name: hiraganaToKatakana(name) })),
      { keys: ["name"], useExtendedSearch: true },
    );
    const companyIds = companyFuse.search(`'${katakanaName}`).map(({ item }) => item.companyId);

    const shopFuse = new Fuse(
      companies.flatMap(({ shops }) =>
        shops?.map(({ shopId, name }) => ({ shopId, name: hiraganaToKatakana(name) })),
      ),
      { keys: ["name"], useExtendedSearch: true },
    );
    const shopIds = shopFuse.search(`'${katakanaName}`).map(({ item }) => item?.shopId);

    return companies
      .map((company) => {
        if (companyIds.includes(company.companyId)) return company;

        const shops = company.shops?.filter(({ shopId }) => shopIds.includes(shopId)) ?? [];
        if (shops.length > 0) return { ...company, shops };

        return null;
      })
      .filter(Boolean) as T[];
  }

  return companies;
};
