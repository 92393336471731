import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert, Spin } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";

import { AddQuestionnaireConfigFormValues } from "./AddQuestionnaireConfigForm/useAddQuestionnaireConfigForm";
import { AddQuestionnaireConfigForm } from "./AddQuestionnaireConfigForm";
import {
  useAddQuestionnaireConfigGetCouponsQuery,
  useAddQuestionnaireConfigGetQuestionnairesQuery,
  useAddQuestionnaireConfigInsertQuestionnaireConfigMutation,
} from "./queries";

export const AddQuestionnaireConfig = () => {
  const navigate = useNavigate();
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data: getQuestionnairesData,
    loading: loadingQuestionnaires,
    error: getQuestionnairesError,
  } = useAddQuestionnaireConfigGetQuestionnairesQuery();
  const defaultQuestionnaireId = getQuestionnairesData?.questionnaire[0]?.id;

  const {
    data: getCouponsData,
    loading: loadingCoupons,
    error: getCouponsError,
  } = useAddQuestionnaireConfigGetCouponsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const coupons = getCouponsData?.coupon ?? [];

  const [addQuestionnaireConfigMutation, { loading: insertLoading }] =
    useAddQuestionnaireConfigInsertQuestionnaireConfigMutation();

  const loading = loadingQuestionnaires || loadingCoupons || insertLoading;
  const shouldShowAlert = getCouponsError || getQuestionnairesError;

  const [{ loading: submitting }, onSubmit] = useAsyncFn(
    async ({
      questionnaireConfig: formValues,
    }: {
      questionnaireConfig: AddQuestionnaireConfigFormValues;
    }) => {
      try {
        if (!companyId) return;
        if (!defaultQuestionnaireId) return;

        // TODO: ImageField でバリデーションしたい
        if (!formValues.imageUrl) return message.error("画像を登録してください");

        const questionnaireConfig = {
          ...formValues,
          questionnaireId: defaultQuestionnaireId,
          companyId,
        };
        const { errors } = await addQuestionnaireConfigMutation({
          variables: { questionnaireConfig },
        });
        if (errors) throw errors;

        message.success("作成しました");

        navigate("/questionnaireConfig", { replace: true });
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [companyId, defaultQuestionnaireId, addQuestionnaireConfigMutation, navigate],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title="アンケートを新規作成"
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "アンケート" }],
      }}
    >
      <PageHeader title="アンケートを新規作成" onBack={goBack} />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <Spin spinning={loading}>
        <AddQuestionnaireConfigForm
          loading={submitting}
          coupons={coupons}
          onSubmit={onSubmit}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
        />
      </Spin>
    </DashboardLayout>
  );
};
