import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddMenuCookingItemModalGetCookingItems = gql`
    query AddMenuCookingItemModalGetCookingItems($shopId: uuid!) {
  cookingItem(where: {shopId: {_eq: $shopId}}) {
    id: serial
    name
    cookingItemId
    shopCookingItemRole {
      role {
        roleId
        name
      }
    }
  }
}
    `;
export const AddMenuCookingItemModalInsertMenuCookingItemsCookingItem = gql`
    mutation AddMenuCookingItemModalInsertMenuCookingItemsCookingItem($shopMenuCookingItem: [shopMenuCookingItem_insert_input!]!) {
  insert_shopMenuCookingItem(
    objects: $shopMenuCookingItem
    on_conflict: {constraint: shopMenuCookingItem_fk_key, update_columns: []}
  ) {
    affected_rows
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type AddMenuCookingItemModalGetCookingItemsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type AddMenuCookingItemModalGetCookingItemsQuery = (
  { __typename?: 'query_root' }
  & { cookingItem: Array<(
    { __typename?: 'cookingItem' }
    & Pick<Types.CookingItem, 'name' | 'cookingItemId'>
    & { id: Types.CookingItem['serial'] }
    & { shopCookingItemRole?: Types.Maybe<(
      { __typename?: 'shopCookingItemRole' }
      & { role: (
        { __typename?: 'role' }
        & Pick<Types.Role, 'roleId' | 'name'>
      ) }
    )> }
  )> }
);

export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationVariables = Types.Exact<{
  shopMenuCookingItem: Array<Types.ShopMenuCookingItemInsertInput> | Types.ShopMenuCookingItemInsertInput;
}>;


export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_shopMenuCookingItem?: Types.Maybe<(
    { __typename?: 'shopMenuCookingItem_mutation_response' }
    & Pick<Types.ShopMenuCookingItemMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopMenuCookingItem' }
      & { menuId: Types.ShopMenuCookingItem['_menuId'] }
    )> }
  )> }
);


export const AddMenuCookingItemModalGetCookingItemsDocument = gql`
    query AddMenuCookingItemModalGetCookingItems($shopId: uuid!) {
  cookingItem(where: {shopId: {_eq: $shopId}}) {
    id: serial
    name
    cookingItemId
    shopCookingItemRole {
      role {
        roleId
        name
      }
    }
  }
}
    `;

/**
 * __useAddMenuCookingItemModalGetCookingItemsQuery__
 *
 * To run a query within a React component, call `useAddMenuCookingItemModalGetCookingItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMenuCookingItemModalGetCookingItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMenuCookingItemModalGetCookingItemsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddMenuCookingItemModalGetCookingItemsQuery(baseOptions: Apollo.QueryHookOptions<AddMenuCookingItemModalGetCookingItemsQuery, AddMenuCookingItemModalGetCookingItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMenuCookingItemModalGetCookingItemsQuery, AddMenuCookingItemModalGetCookingItemsQueryVariables>(AddMenuCookingItemModalGetCookingItemsDocument, options);
      }
export function useAddMenuCookingItemModalGetCookingItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMenuCookingItemModalGetCookingItemsQuery, AddMenuCookingItemModalGetCookingItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMenuCookingItemModalGetCookingItemsQuery, AddMenuCookingItemModalGetCookingItemsQueryVariables>(AddMenuCookingItemModalGetCookingItemsDocument, options);
        }
export type AddMenuCookingItemModalGetCookingItemsQueryHookResult = ReturnType<typeof useAddMenuCookingItemModalGetCookingItemsQuery>;
export type AddMenuCookingItemModalGetCookingItemsLazyQueryHookResult = ReturnType<typeof useAddMenuCookingItemModalGetCookingItemsLazyQuery>;
export type AddMenuCookingItemModalGetCookingItemsQueryResult = Apollo.QueryResult<AddMenuCookingItemModalGetCookingItemsQuery, AddMenuCookingItemModalGetCookingItemsQueryVariables>;
export const AddMenuCookingItemModalInsertMenuCookingItemsCookingItemDocument = gql`
    mutation AddMenuCookingItemModalInsertMenuCookingItemsCookingItem($shopMenuCookingItem: [shopMenuCookingItem_insert_input!]!) {
  insert_shopMenuCookingItem(
    objects: $shopMenuCookingItem
    on_conflict: {constraint: shopMenuCookingItem_fk_key, update_columns: []}
  ) {
    affected_rows
    returning {
      menuId: _menuId
    }
  }
}
    `;
export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationFn = Apollo.MutationFunction<AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation, AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationVariables>;

/**
 * __useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation__
 *
 * To run a mutation, you first call `useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation, { data, loading, error }] = useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation({
 *   variables: {
 *      shopMenuCookingItem: // value for 'shopMenuCookingItem'
 *   },
 * });
 */
export function useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation(baseOptions?: Apollo.MutationHookOptions<AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation, AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation, AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationVariables>(AddMenuCookingItemModalInsertMenuCookingItemsCookingItemDocument, options);
      }
export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationHookResult = ReturnType<typeof useAddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation>;
export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationResult = Apollo.MutationResult<AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation>;
export type AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationOptions = Apollo.BaseMutationOptions<AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutation, AddMenuCookingItemModalInsertMenuCookingItemsCookingItemMutationVariables>;