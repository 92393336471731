import React, { memo } from "react";

import { Form } from "components/antd/Form";
import { PlanRoleField } from "pages/EditShopPlanRoles/EditPlanRoleForm/PlanRoleField";
import { useEditPlanRoleForm } from "pages/EditShopPlanRoles/EditPlanRoleForm/useEditPlanRoleForm";
import { Plan, Role } from "pages/EditShopPlanRoles/types";

type Props = {
  shopId: string;
  plan: Plan;
  planRoles: Role[];
  roles: Role[];
};

export const EditPlanRoleForm = memo<Props>(({ shopId, plan, planRoles, roles }) => {
  const {
    form,
    initialValues,
    change: onChange,
    loadings,
  } = useEditPlanRoleForm(shopId, plan, planRoles, roles);

  return (
    <Form
      name="planRoles"
      form={form}
      initialValues={initialValues}
      onValuesChange={onChange}
      layout="vertical"
    >
      {roles.map(({ id, name }) => (
        <PlanRoleField key={id} label={name} name={id} loading={loadings[id]} />
      ))}
    </Form>
  );
});
