import React, { memo, useCallback } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { FilterConditions } from "models/onSitePayment";
import { RangeValue } from "rc-picker/lib/interface";
import { isNotNullable } from "util/type/primitive";

import { useRangePresets } from "hooks/useRangePresets";
import { CoineySettlement } from "pages/CoineySales/types";

const { RangePicker } = DatePicker;

type Props = {
  filterConditions: FilterConditions;
  coineySettlements: CoineySettlement[];
  onChange: (filterConditions: FilterConditions) => void;
};

const getDate = (value: dayjs.Dayjs | null) =>
  value?.hour(0).minute(0).second(0).millisecond(0) ?? null;

export const CoineySalesFilter = memo<Props>(
  ({ filterConditions, coineySettlements, onChange }) => {
    const changeDates = coineySettlements.map(({ chargeDate }) => chargeDate).filter(isNotNullable);
    const createdAts = changeDates.map((chargeDate) => new Date(chargeDate).getTime());
    const minCreatedAt = Math.min(...createdAts);
    const maxCreatedAt = Math.max(...createdAts);

    const disabledDate = useCallback(
      (moment: dayjs.Dayjs) => moment.isBefore(minCreatedAt) || moment.isAfter(maxCreatedAt),
      [minCreatedAt, maxCreatedAt],
    );

    const handleChange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) =>
        onChange(
          values
            ? {
                range: [getDate(values[0]) ?? null, getDate(values[1]) ?? null],
              }
            : {},
        ),
      [onChange],
    );

    const { rangePresets } = useRangePresets();

    return (
      <RangePicker
        value={filterConditions.range}
        ranges={rangePresets}
        disabledDate={disabledDate}
        onChange={handleChange}
      />
    );
  },
);
