import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputNumber } from "components/Input/InputNumber";

import { MAX_FEE_RATE, MIN_FEE_RATE } from "../../constant";
import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";
import { formatPercent, parsePercentValue } from "../../util";

type Props = { disabled?: boolean } & Omit<FormItemProps, "children">;

export const CardFeeRateField = memo<Props>(({ name, disabled, ...props }) => (
  <EditDiniiPayConfigFormItem
    name={name}
    rules={[{ min: MIN_FEE_RATE, max: MAX_FEE_RATE, type: "number" }]}
    {...props}
  >
    <InputNumber
      fullWidth
      formatter={formatPercent}
      parser={parsePercentValue}
      min={0}
      placeholder="決済不可"
      disabled={disabled}
    />
  </EditDiniiPayConfigFormItem>
));
