import React, { memo } from "react";
import styled from "styled-components";
import { yellow } from "@ant-design/colors";
import { WarningOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  display: flex;
  background-color: ${yellow[5]};
  border: 1px solid ${yellow[6]};
  border-radius: 6px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  max-width: 60px;
  margin-left: 8px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.4;
`;

export const WarningText = memo(({ children }) => (
  <Wrapper>
    <WarningOutlined />
    <Text>{children}</Text>
  </Wrapper>
));
