import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { useShop } from "hooks/useShop";
import {
  MonthlySalesBudgetInsertInput,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import { AddSalesBudgetForm } from "./AddSalesBudgetForm";
import {
  useAddSalesBudgetGetSalesBudgetAllocationSettingQuery,
  useAddSalesBudgetInsertBudgetMutation,
  useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation,
} from "./queries";

export const AddSalesBudget = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: allocationSettingData,
    loading: loadingAllocationSetting,
    refetch: refetchAllocationSetting,
  } = useAddSalesBudgetGetSalesBudgetAllocationSettingQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const allocationSetting = allocationSettingData?.salesBudgetAllocationSetting[0];

  const [insertBudget, { loading: loadingInsertBudget }] = useAddSalesBudgetInsertBudgetMutation();
  const [insertAllocationSetting, { loading: loadingInsertSalesBudgetAllocationSetting }] =
    useAddSalesBudgetInsertSalesBudgetAllocationSettingMutation();

  const onSubmit = useCallback(
    async (budget: MonthlySalesBudgetInsertInput) => {
      try {
        await insertBudget({
          variables: { budget },
        });
        message.success("作成しました");

        goBack();
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [insertBudget, goBack],
  );

  const [{ loading: loadingSubmitAllocationSetting }, onSubmitAllocationSetting] = useAsyncFn(
    async (allocationSetting: SalesBudgetAllocationSettingInsertInput) => {
      try {
        await insertAllocationSetting({
          variables: { allocationSetting },
        });
        await refetchAllocationSetting();
      } catch (e) {
        message.error("作成に失敗しました");
      }
    },
    [insertAllocationSetting, refetchAllocationSetting],
  );

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  const loading = loadingInsertBudget || loadingInsertSalesBudgetAllocationSetting;
  const loadingInitialAllocationSetting =
    !loadingSubmitAllocationSetting && loadingAllocationSetting;

  return (
    <DashboardLayout
      title="新規目標設定"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "目標設定" }],
      }}
    >
      <PageHeader title="新規目標設定" onBack={goBack} />
      {loadingInitialAllocationSetting || !shopId ? (
        <Loading />
      ) : (
        <AddSalesBudgetForm
          shopId={shopId}
          loading={loading}
          onSubmit={onSubmit}
          onSubmitAllocationSetting={onSubmitAllocationSetting}
          onClose={goBack}
          onFormValidationError={onFormValidationError}
          allocationSetting={allocationSetting}
        />
      )}
    </DashboardLayout>
  );
};
