import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const WinboardMenuRegistrationCsvGetShops = gql`
    query WinboardMenuRegistrationCsvGetShops($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
    }
  }
}
    `;
export type WinboardMenuRegistrationCsvGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type WinboardMenuRegistrationCsvGetShopsQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'name' | 'shopId'>
    )> }
  )> }
);


export const WinboardMenuRegistrationCsvGetShopsDocument = gql`
    query WinboardMenuRegistrationCsvGetShops($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}, limit: 1) {
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      name
      shopId
    }
  }
}
    `;

/**
 * __useWinboardMenuRegistrationCsvGetShopsQuery__
 *
 * To run a query within a React component, call `useWinboardMenuRegistrationCsvGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinboardMenuRegistrationCsvGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinboardMenuRegistrationCsvGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useWinboardMenuRegistrationCsvGetShopsQuery(baseOptions: Apollo.QueryHookOptions<WinboardMenuRegistrationCsvGetShopsQuery, WinboardMenuRegistrationCsvGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WinboardMenuRegistrationCsvGetShopsQuery, WinboardMenuRegistrationCsvGetShopsQueryVariables>(WinboardMenuRegistrationCsvGetShopsDocument, options);
      }
export function useWinboardMenuRegistrationCsvGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WinboardMenuRegistrationCsvGetShopsQuery, WinboardMenuRegistrationCsvGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WinboardMenuRegistrationCsvGetShopsQuery, WinboardMenuRegistrationCsvGetShopsQueryVariables>(WinboardMenuRegistrationCsvGetShopsDocument, options);
        }
export type WinboardMenuRegistrationCsvGetShopsQueryHookResult = ReturnType<typeof useWinboardMenuRegistrationCsvGetShopsQuery>;
export type WinboardMenuRegistrationCsvGetShopsLazyQueryHookResult = ReturnType<typeof useWinboardMenuRegistrationCsvGetShopsLazyQuery>;
export type WinboardMenuRegistrationCsvGetShopsQueryResult = Apollo.QueryResult<WinboardMenuRegistrationCsvGetShopsQuery, WinboardMenuRegistrationCsvGetShopsQueryVariables>;