import React, { memo } from "react";

import { EditMenusBulkFormItem } from "../../useEditMenusBulkForm";

type Props = {
  value: string;
  categoryMenuId: number;
};

export const NameField = memo<Props>(({ value, categoryMenuId }) => (
  <EditMenusBulkFormItem name={[categoryMenuId, "name"]} initialValue={value} noStyle>
    {value}
  </EditMenusBulkFormItem>
));
