import React, { memo } from "react";
import styled from "styled-components";
import { Checkbox, Col, FormInstance, Row, Select } from "antd";
import { taxRates } from "models/taxRate";

import { Form } from "components/antd/Form";
import { CostPriceWithCostTaxRateField } from "components/Form/CostPriceWithCostTaxRateField";
import { CostTaxRateWithCostPriceField } from "components/Form/CostTaxRateWithCostPriceField";
import { FormHelp } from "components/Form/FormHelp";
import { ImageField } from "components/Form/ImageField";
import { InputCostPrice } from "components/Input/InputCostPrice";
import { InputQuantity } from "components/Input/InputQuantity";
import { PlanOption } from "pages/PlanOptions/AddPlanChoiceModal/types";
import { OptionInputTypeEnum } from "types/graphql";

import { AddPlanChoiceTranslationOptions } from "./AddPlanChoiceTranslationOptions";
import { CostTaxMethodField } from "./CostTaxMethodField";
import { MenuTypeField } from "./MenuTypeField";
import { NameField } from "./NameField";
import { PriceField } from "./PriceField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { TaxMethodField } from "./TaxMethodField";
import { TaxRateField } from "./TaxRateField";
import {
  AddPlanChoiceFormItem,
  AddPlanChoiceFormValues,
  useAddPlanChoiceForm,
} from "./useAddPlanChoiceForm";

const CheckboxWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

type Props = {
  form: FormInstance;
  planOption: PlanOption;
  inputType: OptionInputTypeEnum;
  onChange: (planChoice: AddPlanChoiceFormValues) => void;
};

const { Option } = Select;

export const AddPlanChoiceForm = memo<Props>(({ form, planOption, inputType, onChange }) => {
  const {
    formValues,
    change: onValuesChange,
    changeImage: setUploadImage,
    uploadImage,
  } = useAddPlanChoiceForm({ form, inputType, onChange });
  return (
    <Form name="planChoice" form={form} layout="vertical" onValuesChange={onValuesChange}>
      <Row>
        <ImageField
          image={uploadImage}
          setUploadImage={setUploadImage}
          uploadImageApiKey="planChoice"
          formName="AddPlanChoiceForm"
          label="プラン選択肢画像"
          isMovieAccepted
        />
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <NameField wrapperCol={{ span: 12 }} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <ReceiptDisplayNameField wrapperCol={{ span: 12 }} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <MenuTypeField />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <TaxMethodField taxMethod={planOption.plan.taxMethod} />
        </Col>
        <Col span={6}>
          <TaxRateField taxRate={planOption.plan.taxRate} />
        </Col>
        <Col span={6}>
          <PriceField />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <CostTaxMethodField costTaxMethod={planOption.plan.company.defaultCostTaxMethod} />
        </Col>
        <Col span={6}>
          <CostTaxRateWithCostPriceField>
            <Select<string>>
              {Object.entries(taxRates).map(([taxRateKey, taxRate]) => (
                <Option key={taxRateKey} value={taxRate}>
                  {taxRate * 100}%
                </Option>
              ))}
            </Select>
          </CostTaxRateWithCostPriceField>
        </Col>
        <Col span={6}>
          <CostPriceWithCostTaxRateField
            label={<FormHelp label="原価" help="小数点以下二桁までの数値を設定できます。" />}
          >
            <InputCostPrice step={0.01} precision={2} />
          </CostPriceWithCostTaxRateField>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <CheckboxWrapper>
            <AddPlanChoiceFormItem name="isDefaultSelection" valuePropName="checked">
              <Checkbox>デフォルトで選択済みの状態にする</Checkbox>
            </AddPlanChoiceFormItem>
          </CheckboxWrapper>
        </Col>
      </Row>

      {inputType === OptionInputTypeEnum.Count && (
        <Row gutter={24}>
          <Col span={20}>
            <CheckboxWrapper>
              <AddPlanChoiceFormItem name="hasOrderCountLimit" valuePropName="checked">
                <Checkbox value={formValues.hasOrderCountLimit}>注文個数上限を設定する</Checkbox>
              </AddPlanChoiceFormItem>
            </CheckboxWrapper>
          </Col>
          <Col span={4}>
            <AddPlanChoiceFormItem name="maxOrderableNum">
              <InputQuantity disabled={!formValues.hasOrderCountLimit} />
            </AddPlanChoiceFormItem>
          </Col>
        </Row>
      )}

      <AddPlanChoiceTranslationOptions />
    </Form>
  );
});
