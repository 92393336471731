import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { Table } from "pages/EditTable/types";
import { TableSetInput } from "types/graphql";

export type EditTableFormValues = Pick<Table, "name" | "tableAreaId">;

const getInitialValues = (table: Table) => {
  const { name, tableAreaId } = table;
  return { name, tableAreaId };
};

export const EditTableFormItem = createFormItem<EditTableFormValues>();

export const useEditTableForm = (
  table: Table,
  onSubmit: (table: TableSetInput) => Promise<void>,
) => {
  const [form] = Form.useForm<EditTableFormValues>();
  const initialValues = getInitialValues(table);

  const submit = useCallback(async () => {
    const { name, tableAreaId } = form.getFieldsValue();

    await onSubmit({
      name,
      tableAreaId,
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
