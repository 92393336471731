import React, { memo, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Menu } from "antd";

export const LineReportingBotMenu = memo(() => {
  const { state } = useParams<{ state?: string }>();
  const { pathname } = useLocation();

  const key = pathname.split("/")[2];
  const selectedKey = state ?? key;
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <Menu selectedKeys={selectedKeys} mode="horizontal">
      <Menu.Item key="active">
        <Link to="/lineReportingBotConfig/active" replace>
          予約中
        </Link>
      </Menu.Item>
      <Menu.Item key="suspended">
        <Link to="/lineReportingBotConfig/suspended" replace>
          停止中
        </Link>
      </Menu.Item>
    </Menu>
  );
});
