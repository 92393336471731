import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditLineChannelConfigFormItem } from "../useEditLineChannelConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const ChannelSecretField = memo<Props>((props) => (
  <EditLineChannelConfigFormItem
    label="チャネルシークレット"
    name="channelSecret"
    wrapperCol={{ span: 20 }}
    {...props}
  >
    <Input />
  </EditLineChannelConfigFormItem>
));
