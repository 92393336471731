import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { BulkUpdateIsDisplayByShopTable } from "components/BulkUpdateIsDisplayByShopTable";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { SubTitle } from "components/SubTitle";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { EditOnSitePaymentDetailTypeForm } from "pages/EditOnSitePaymentDetailType/EditOnSitePaymentDetailTypeForm";
import {
  useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation,
  useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery,
  useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery,
  useEditOnSitePaymentDetailTypeGetShopsQuery,
  useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation,
  useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation,
} from "pages/EditOnSitePaymentDetailType/queries";
import { OnSitePaymentDetailTypeSetInput } from "types/graphql";

export const EditOnSitePaymentDetailType = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: getOnSitePaymentDetailTypeData,
    loading: loadingOnSitePaymentDetailType,
    refetch: refetchOnSitePaymentDetailType,
    error,
  } = useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeQuery(
    id ? { variables: { id } } : { skip: true },
  );
  const onSitePaymentDetailType = getOnSitePaymentDetailTypeData?.onSitePaymentDetailType[0];

  const {
    data: getShopsData,
    loading: loadingShops,
    refetch: refetchShops,
  } = useEditOnSitePaymentDetailTypeGetShopsQuery(
    id && companyId ? { variables: { id, companyId } } : { skip: true },
  );

  const { data: getOnSitePaymentDetailTypeCategoriesData } =
    useEditOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery();
  const onSitePaymentDetailTypeCategories = useMemo(
    () => getOnSitePaymentDetailTypeCategoriesData?.onSitePaymentDetailTypeCategoryPriority ?? [],
    [getOnSitePaymentDetailTypeCategoriesData?.onSitePaymentDetailTypeCategoryPriority],
  );

  const shops = getShopsData?.shop ?? [];

  const [updateOnSitePaymentDetailTypeMutation, { loading: loadingUpdateOnSitePaymentDetail }] =
    useEditOnSitePaymentDetailTypeUpdateOnSitePaymentDetailTypeMutation();

  const onSubmit = useCallback(
    async (onSitePaymentDetailType: OnSitePaymentDetailTypeSetInput) => {
      try {
        await updateOnSitePaymentDetailTypeMutation({
          variables: { onSitePaymentDetailTypeId: id, onSitePaymentDetailType },
        });
        await refetchOnSitePaymentDetailType();
        message.success("編集を保存しました");

        navigate("/onSitePaymentDetailType", { replace: true });
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [updateOnSitePaymentDetailTypeMutation, id, navigate, refetchOnSitePaymentDetailType],
  );

  const [updateShopPaymentTypeMutation] =
    useEditOnSitePaymentDetailTypeUpdateShopPaymentTypeMutation();

  const [bulkUpdateShopPaymentTypesMutation] =
    useEditOnSitePaymentDetailTypeBulkUpdateShopPaymentTypesMutation();

  const onUpdateIsDisplay = useCallback(
    async (shopId: string, isDisplay: boolean) => {
      if (!companyId) return;
      if (!onSitePaymentDetailType) return;

      try {
        await updateShopPaymentTypeMutation({
          variables: {
            shopPaymentType: {
              shopId,
              type: onSitePaymentDetailType.type,
              onSitePaymentDetailTypeId: id,
              companyId,
              isDisplay,
            },
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [companyId, id, onSitePaymentDetailType, updateShopPaymentTypeMutation, refetchShops],
  );

  const onBulkUpdateIsDisplay = useCallback(
    async ({ shopIds, isDisplay }: { shopIds: string[]; isDisplay: boolean }) => {
      if (!companyId || !onSitePaymentDetailType) return;

      try {
        await bulkUpdateShopPaymentTypesMutation({
          variables: {
            shopPaymentTypes: shopIds.map((shopId) => ({
              shopId,
              type: onSitePaymentDetailType.type,
              onSitePaymentDetailTypeId: onSitePaymentDetailType.id,
              companyId,
              isDisplay,
            })),
          },
        });

        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
      await refetchShops();
    },
    [companyId, onSitePaymentDetailType, bulkUpdateShopPaymentTypesMutation, refetchShops],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout
      title={onSitePaymentDetailType?.label}
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "支払方法" }],
      }}
    >
      <PageHeader title={onSitePaymentDetailType?.label} onBack={goBack} />
      {loadingOnSitePaymentDetailType && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {onSitePaymentDetailType && (
        <>
          <EditOnSitePaymentDetailTypeForm
            onSitePaymentDetailType={onSitePaymentDetailType}
            onSubmit={onSubmit}
            onClose={goBack}
            loading={loadingUpdateOnSitePaymentDetail}
            onSitePaymentDetailTypeCategories={onSitePaymentDetailTypeCategories}
          />
          <Spacer height={32} />
          <FormContent>
            <SubTitle>店舗ごとの表示設定</SubTitle>
          </FormContent>
          <BulkUpdateIsDisplayByShopTable
            loading={loadingShops}
            shops={shops}
            onUpdateIsDisplay={onUpdateIsDisplay}
            onBulkUpdateIsDisplay={onBulkUpdateIsDisplay}
            updateTarget="shopPaymentTypes"
          />
        </>
      )}
    </DashboardLayout>
  );
};
