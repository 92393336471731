import React, { useCallback, useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Alert, Button, DatePicker, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelectDropdown } from "components/ShopSelector/ShopSelectDropdown";
import { Spacer } from "components/Spacer";
import { useCorporation } from "hooks/useCorporation";
import { useDinii } from "hooks/useDinii";

import { useLineReportingManualImagesGetShopsQuery } from "./queries";

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ControlsWrapper = styled.div`
  width: 50%;
`;

const StyledImage = styled.img`
  width: 500px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 8px;
  width: 500px;
  position: relative;
`;

export const LineReportingImages = () => {
  const [corporation] = useCorporation();
  const [dinii, getContext] = useDinii();
  const [selectedShopId, setSelectedShopId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const {
    data: shopsData,
    error: shopsError,
    loading,
  } = useLineReportingManualImagesGetShopsQuery(
    corporation
      ? {
          variables: { corporationId: corporation.corporationId },
        }
      : { skip: true },
  );

  const shops = shopsData?.shop ?? [];
  const selectedShopName = shops.find(({ shopId }) => shopId === selectedShopId)?.name;

  const onDateChanged = useCallback(
    (_, targetDateString: string) => setSelectedDate(targetDateString),
    [],
  );

  const [{ loading: loadingImage }, generateImage] = useAsyncFn(
    async (reportingTerm: "Monthly" | "Daily" | "Weekly") => {
      const context = await getContext();

      if (!context || !selectedShopId || !selectedDate) return;

      const { data } = await dinii.lineReportingManualImage[`generate${reportingTerm}Report`]({
        shopId: selectedShopId,
        targetDate: selectedDate,
        context,
        config: {},
      });

      setGeneratedImageUrl(data.imageUrl);
    },
    [dinii.lineReportingManualImage, getContext, selectedShopId, selectedDate],
  );

  const disableButtons = !selectedShopId || !selectedDate || loadingImage;

  return (
    <DashboardLayout title="AIくん画像作成">
      <PageHeader
        title="AIくん画像作成"
        footer={
          <ControlsWrapper>
            <ShopSelectDropdown shops={shops} setShop={setSelectedShopId}>
              <Button loading={loading}>
                {selectedShopName ?? "店舗を選択"} <DownOutlined />
              </Button>
            </ShopSelectDropdown>

            <Spacer size={8} inline />

            <DatePicker onChange={onDateChanged} />

            <Spacer size={12} />

            <ButtonsRow>
              <Button disabled={disableButtons} onClick={() => generateImage("Daily")}>
                日報を作成する
              </Button>
              <Spacer size={8} inline />
              <Button disabled={disableButtons} onClick={() => generateImage("Weekly")}>
                週報を作成する
              </Button>
              <Spacer size={8} inline />
              <Button disabled={disableButtons} onClick={() => generateImage("Monthly")}>
                月報を作成する
              </Button>
              <Spacer size={8} inline />
            </ButtonsRow>
          </ControlsWrapper>
        }
      />

      {shopsError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <Container>
        <ImageWrapper>
          {loadingImage && <Spin size="large" />}
          {shopsData && !loadingImage && generatedImageUrl && (
            <StyledImage width={500} alt="作成された画像" src={generatedImageUrl ?? ""} />
          )}
        </ImageWrapper>
        <Spacer size={40} />
      </Container>
    </DashboardLayout>
  );
};
