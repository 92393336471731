import React, { memo, useCallback, useState } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { HeaderUploadField } from "pages/EditCategory/EditCategoryForm/HeaderUploadField";
import { NameField } from "pages/EditCategory/EditCategoryForm/NameField";
import { ShopSideBackgroundColorField } from "pages/EditCategory/EditCategoryForm/ShopDisplayBackgroundColorField";
import { ShopSideNameField } from "pages/EditCategory/EditCategoryForm/ShopSideNameField";
import { useEditCategoryForm } from "pages/EditCategory/EditCategoryForm/useEditCategoryForm";
import { Category } from "pages/EditCategory/types";
import { CategorySetInput } from "types/graphql";

type Props = {
  category: Category;
  onSubmit: (plan: CategorySetInput) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  disabled: boolean;
};

export const EditCategoryForm = memo<Props>(
  ({ category, onClose, onSubmit, loading, onFormValidationError, disabled }) => {
    const { form, initialValues, submit } = useEditCategoryForm(category, onSubmit);

    const [uploadImage, setUploadImage] = useState(initialValues.imageUrl);

    const [shopSideBackgroundColor, setShopSideBackgroundColor] = useState(
      initialValues.shopSideBackgroundColor,
    );

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({ imageUrl: uploadImage, shopSideBackgroundColor });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, uploadImage, shopSideBackgroundColor, onFormValidationError, form]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="category" form={form} layout="vertical" initialValues={initialValues}>
            <HeaderUploadField
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              disabled={disabled}
            />
            <NameField disabled={disabled} />
            <ShopSideNameField disabled={disabled} />
            <ShopSideBackgroundColorField
              shopSideBackgroundColor={shopSideBackgroundColor}
              setShopSideBackgroundColor={setShopSideBackgroundColor}
              disabled={disabled}
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading} disabled={disabled}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
