import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { PlanHeader } from "components/PageHeader/PlanHeader";
import { useCompany } from "hooks/useCompany";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { EditPlanForm } from "pages/EditPlan/EditPlanForm";
import {
  useEditPlanGetCategoriesQuery,
  useEditPlanGetOrderableTimesQuery,
  useEditPlanGetPlanGroupsQuery,
  useEditPlanGetPlanQuery,
  useEditPlanUpdatePlanMutation,
} from "pages/EditPlan/queries";
import { PlanSetInput } from "types/graphql";

export const EditPlan = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [company, setCompany] = useCompany();

  const { id } = useParams<{ id: string }>();
  const planId = Number(id);
  const navigate = useNavigate();

  const {
    data: getPlanData,
    loading: loadingPlan,
    refetch: refetchPlan,
    error: getPlanDataError,
  } = useEditPlanGetPlanQuery(!Number.isNaN(planId) ? { variables: { planId } } : { skip: true });
  const plan = getPlanData?.plan?.[0];

  setCompany(plan?.companyId);

  const companyId = company?.id;

  const { data: getCategoriesData, error: getCategoriesDataError } = useEditPlanGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];

  const { data: getOrderableTimesData, error: getOrderableTimesDataError } =
    useEditPlanGetOrderableTimesQuery(companyId ? { variables: { companyId } } : { skip: true });
  const orderableTimes = getOrderableTimesData?.orderableTime ?? [];

  const { data: getPlanGroupsData, error: getPlanGroupsDataError } = useEditPlanGetPlanGroupsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const shouldShowNetworkErrorAlert = Boolean(
    getPlanGroupsDataError ||
      getOrderableTimesDataError ||
      getCategoriesDataError ||
      getPlanDataError,
  );

  const planGroups = getPlanGroupsData?.planGroup ?? [];

  const [updatePlanMutation, { loading: loadingUpdatePlan }] = useEditPlanUpdatePlanMutation();

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = useCallback(
    async (plan: PlanSetInput) => {
      if (!Number.isNaN(planId)) {
        try {
          await updatePlanMutation({
            variables: { planId, plan },
          });
          await refetchPlan();
          message.success("編集を保存しました");
          goBack();
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [planId, updatePlanMutation, goBack, refetchPlan],
  );

  const canEditAllShopPlans = plan?.totalDealingShopCount === plan?.shopPlans.length;
  const isEditPlanFeatureEnabled = isFeatureEnabled("editPlan");

  return (
    <DashboardLayout
      title={plan?.planName}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "プラン" }],
      }}
    >
      <PlanHeader
        plan={plan ?? null}
        onBack={goBack}
        shouldShowNetworkErrorAlert={shouldShowNetworkErrorAlert}
        shouldShowManagingShopErrorAlert={isEditPlanFeatureEnabled && !canEditAllShopPlans}
      />
      {loadingPlan && <Loading height={300} />}
      {plan && (
        <EditPlanForm
          plan={plan}
          categories={categories}
          orderableTimes={orderableTimes}
          planGroups={planGroups}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdatePlan}
          disabled={!isEditPlanFeatureEnabled || !canEditAllShopPlans}
        />
      )}
    </DashboardLayout>
  );
};
