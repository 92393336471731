import React, { memo } from "react";
import styled from "styled-components";
import { convertToFaveYellRanking, convertToRanking } from "models/customerDashboard";

import type { CustomerFaveYellRanking, CustomerRanking } from "types/graphql";

import type { Transitions } from "../types";

import { BarGraph } from "./TransitionGraph/BarGraph";
import { LineGraph } from "./TransitionGraph/LineGraph";
import { CustomerRankingTop } from "./CustomerRankingTop";

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const KpiTransitionsTitle = styled.p`
  margin-top: 32px;
  margin-bottom: 16px;
  height: 24px;
  font-weight: bold;
  font-size: 18px;
  color: #404040;
`;

type Props = {
  transitions: Transitions | undefined;
  transitionsLoading: boolean;
  customerVisitedCountRankingData: CustomerRanking | undefined;
  customerVisitedCountRankingDataLoading: boolean;
  customerViralCountRankingData: CustomerRanking | undefined;
  customerViralCountRankingDataLoading: boolean;
  customerFaveYellAmountRankingData: CustomerFaveYellRanking | undefined;
  customerFaveYellAmountRankingDataLoading: boolean;
  customerFaveYellCountRankingData: CustomerFaveYellRanking | undefined;
  customerFaveYellCountRankingDataLoading: boolean;
  isYellAvailable: boolean;
};

export const CustomerDashboardTransitions = memo<Props>(
  ({
    transitions,
    transitionsLoading,
    customerVisitedCountRankingData,
    customerVisitedCountRankingDataLoading,
    customerViralCountRankingData,
    customerViralCountRankingDataLoading,
    customerFaveYellAmountRankingData,
    customerFaveYellAmountRankingDataLoading,
    customerFaveYellCountRankingData,
    customerFaveYellCountRankingDataLoading,
    isYellAvailable,
  }) => {
    const repeatRankingData = convertToRanking(customerVisitedCountRankingData?.customers);
    const viralRankingData = convertToRanking(customerViralCountRankingData?.customers);
    const faveYellAmountRankingData = convertToFaveYellRanking(
      customerFaveYellAmountRankingData?.customers,
    );
    const faveYellCountRankingData = convertToFaveYellRanking(
      customerFaveYellCountRankingData?.customers,
    );

    return (
      <>
        <Wrapper>
          <LineGraph
            title="リピーター推移"
            caption="再来店数によるリピーターを来店人数全体からの割合で表示します"
            data={transitions?.repeater ?? []}
            loading={transitionsLoading}
            dataKeys={["repeaterRate"]}
            units="%"
            width={724}
            height={350}
          />

          <CustomerRankingTop
            loading={customerVisitedCountRankingDataLoading}
            title="リピート数ランキング"
            caption="合計来店数の多い順でユーザーを表示します"
            data={repeatRankingData}
            unit="回"
            rankingPagePath="/ranking/visitedCount"
          />

          <LineGraph
            title="アンバサダー推移"
            data={transitions?.ambassador ?? []}
            loading={transitionsLoading}
            dataKeys={["ambassadorRate", "newCustomerViaAmbassadorCount"]}
            units={["%", "人"]}
            width={724}
            height={350}
          />

          <CustomerRankingTop
            loading={customerViralCountRankingDataLoading}
            title="アンバサダーランキング"
            caption="過去紹介者数の多い順でユーザーを表示します"
            data={viralRankingData}
            unit="人"
            rankingPagePath="/ranking/viralCount"
          />

          {isYellAvailable && (
            <>
              <LineGraph
                title="推しエール推移"
                data={transitions?.faveYell ?? []}
                loading={transitionsLoading}
                dataKeys={["faveYellAmount", "faveYellUserCount"]}
                units={["円", "人"]}
                width={548}
                height={350}
              />
              <CustomerRankingTop
                loading={customerFaveYellAmountRankingDataLoading}
                title="推しエール金額ランキング"
                data={faveYellAmountRankingData}
                unit="円"
                rankingPagePath="/ranking/faveYellAmount"
                small
              />

              <CustomerRankingTop
                loading={customerFaveYellCountRankingDataLoading}
                title="推しエール回数ランキング"
                data={faveYellCountRankingData}
                unit="回"
                rankingPagePath="/ranking/faveYellCount"
                small
              />
            </>
          )}
        </Wrapper>

        <KpiTransitionsTitle>各KPIの推移</KpiTransitionsTitle>

        <Wrapper>
          <LineGraph
            title="売上"
            data={transitions?.sales ?? []}
            loading={transitionsLoading}
            dataKeys={["value"]}
            units="円"
            width={548}
            height={250}
          />
          <LineGraph
            title="チェックインレート"
            data={transitions?.checkInRate ?? []}
            loading={transitionsLoading}
            dataKeys={["value"]}
            units="%"
            width={548}
            height={250}
          />
          <BarGraph
            title="来店客数"
            data={transitions?.customerCount ?? []}
            loading={transitionsLoading}
            dataKeys={["newCustomer", "newCustomerViaAmbassador", "repeater"]}
            unit="人"
            width={548}
            height={250}
          />
          <LineGraph
            title="獲得ID数"
            data={transitions?.newLineIdCount ?? []}
            loading={transitionsLoading}
            dataKeys={["value"]}
            units="ID"
            width={548}
            height={250}
          />
          <LineGraph
            title="客単価"
            data={transitions?.salesPerCustomer ?? []}
            loading={transitionsLoading}
            dataKeys={["value"]}
            units="円"
            width={548}
            height={250}
          />
        </Wrapper>
      </>
    );
  },
);
