import { useCallback, useMemo, useState } from "react";
import { FormInstance, Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";
import { PlanChoice, Translation } from "pages/PlanOptions/EditPlanChoiceModal/types";
import { OptionInputTypeEnum, SupportedLocaleEnum } from "types/graphql";
import { TranslationColumnNameEnum } from "types/translation";

export type EditPlanChoiceFormValues = Pick<
  PlanChoice,
  | "description"
  | "isDefaultSelection"
  | "maxOrderableNum"
  | "name"
  | "price"
  | "receiptDisplayName"
  | "costPrice"
  | "costTaxRate"
  | "menuType"
  | "imageUrl"
> & {
  hasOrderCountLimit: boolean;
} & Partial<{
    nameEn: string;
    nameKr: string;
    nameCn: string;
  }>;

type usePlanChoiceFormInput = {
  form: FormInstance<unknown>;
  planChoice: PlanChoice;
  translations: Translation[];
  inputType: OptionInputTypeEnum;
  onChange: (formValues: EditPlanChoiceFormValues) => void;
};

const getInitialValues = (
  planChoice: PlanChoice,
  inputType: OptionInputTypeEnum,
  localeToNameTranslationMap: Map<SupportedLocaleEnum, Translation>,
): EditPlanChoiceFormValues => {
  const {
    description,
    isDefaultSelection,
    maxOrderableNum,
    name,
    price,
    receiptDisplayName,
    costPrice,
    costTaxRate,
    menuType,
    imageUrl,
  } = planChoice;

  return {
    description,
    isDefaultSelection,
    maxOrderableNum,
    name,
    price,
    receiptDisplayName,
    costPrice,
    costTaxRate,
    menuType,
    imageUrl: imageUrl ?? null,
    hasOrderCountLimit: maxOrderableNum !== null,
    nameEn: localeToNameTranslationMap.get(SupportedLocaleEnum.EnUs)?.value,
    nameKr: localeToNameTranslationMap.get(SupportedLocaleEnum.KoKr)?.value,
    nameCn: localeToNameTranslationMap.get(SupportedLocaleEnum.ZhCn)?.value,
  };
};

export const EditPlanChoiceFormItem = createFormItem<EditPlanChoiceFormValues>();

export const useEditPlanChoiceForm = ({
  form,
  planChoice,
  translations,
  inputType,
  onChange,
}: usePlanChoiceFormInput) => {
  const localeToNameTranslationMap = useMemo(
    () =>
      new Map(
        translations
          .filter((translation) => translation.columnName === TranslationColumnNameEnum.Name)
          .map((translation) => [translation.locale, translation]),
      ),
    [translations],
  );

  const initialValues = getInitialValues(planChoice, inputType, localeToNameTranslationMap);
  const [formValues, setFormValues] = useState(initialValues);
  const [uploadImage, setUploadImage] = useState<string | null>(initialValues.imageUrl ?? null);

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditPlanChoiceFormValues;
      const imageUrl = uploadImage;
      setFormValues({ ...values, imageUrl });
      onChange({ ...values, imageUrl });
    },
    [onChange, uploadImage],
  );

  const changeImage = useCallback(
    (imageUrl: string) => {
      const formValues = form.getFieldsValue() as EditPlanChoiceFormValues;
      setUploadImage(imageUrl);
      onChange({ ...formValues, imageUrl });
    },
    [form, onChange],
  );

  return { initialValues, formValues, change, changeImage, uploadImage };
};
