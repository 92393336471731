import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanFormItem } from "../useEditPlanForm";

type Props = { disabled: boolean } & Omit<FormItemProps, "children" | "name">;

export const FeaturedLabelTextField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanFormItem label="特集ラベル (日本語)" name="featuredLabelText" {...props}>
    <Input disabled={disabled} />
  </EditPlanFormItem>
));
