import React, { memo, useCallback, useMemo } from "react";
import { BlockPicker as ReactColorBlockPicker, ColorChangeHandler } from "react-color";
import styled from "styled-components";

import { colors } from "constants/colors";

const StyledBlockPicker = styled(ReactColorBlockPicker)`
  border: 1px solid ${colors.Border.Default};
  overflow: hidden;
`;

type Props = {
  color: string;
  colors: string[];
  onChange: (color: string) => void;
  disabled?: boolean;
};

export const BlockPicker = memo<Props>(({ color, colors, onChange, disabled = false }) => {
  const styles = useMemo(
    () => ({
      default: {
        card: { borderRadius: 2, boxShadow: "none" },
        head: { height: 60, borderRadius: 0 },
        ...(disabled ? { body: { display: "none" } } : {}),
      },
    }),
    [disabled],
  );

  const handleChange: ColorChangeHandler = useCallback(({ hex }) => onChange(hex), [onChange]);

  return (
    <StyledBlockPicker
      width="204px"
      triangle="hide"
      color={color}
      colors={colors}
      onChangeComplete={handleChange}
      styles={styles}
    />
  );
});
