import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import {
  getDateTimeBasedOnChangeDateTime,
  isDateChanged,
  isValidSpecificTimeSurchargeHoursDuration,
} from "models/specificTimeSurcharge";
import { parsePercentage } from "util/input/parsePercentage";
import { normalizeHoursAndMinutes } from "util/time";

import { withFormDependencies } from "components/antd/Form";
import { TimePicker } from "components/antd/TimePicker";
import { InputNumber } from "components/Input/InputNumber";
import { Spacer } from "components/Spacer";

import { EditCashRegisterConfigFormItem } from "../useEditCashRegisterConfigForm";

const StyledTitle = styled(Title).attrs({ level: 4 })`
  &.ant-typography {
    margin-bottom: 0px;
  }
`;

const Tilda = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: 0 8px;
`;

const DateTime = styled.span`
  color: #999999;
  font-size: 12px;
  width: 80px;
`;

type Props = {
  changeDateTime: string;
};

export const SurchargeConfigFields = memo<Props>(({ changeDateTime }) => (
  <div>
    <StyledTitle>割増設定</StyledTitle>
    <Spacer size={24} />
    <Row justify="start" align="top" gutter={[24, 0]}>
      <Col>
        <EditCashRegisterConfigFormItem
          label="タイムチャージ料率"
          name={["specificTimeSurchargeConfig", "chargeRate"]}
        >
          <InputNumber
            formatter={(value) => `${value}%`}
            parser={parsePercentage}
            min={0}
            max={100}
            placeholder="0%"
          />
        </EditCashRegisterConfigFormItem>
      </Col>
      <Col>
        <Row>
          <Col>タイムチャージ料率の適用時間帯</Col>
        </Row>
        <Spacer size={4} />
        <Row>
          <Col>
            <EditCashRegisterConfigFormItem
              name={["specificTimeSurchargeConfig", "startTime"]}
              endSpacer={null}
              rules={[
                ({ getFieldValue }) => ({
                  validator: async () => {
                    const start = getFieldValue(["specificTimeSurchargeConfig", "startTime"]);
                    const end = getFieldValue(["specificTimeSurchargeConfig", "endTime"]);
                    const chargeRate = getFieldValue(["specificTimeSurchargeConfig", "chargeRate"]);

                    if (!chargeRate) return;

                    if ((chargeRate || end) && !start) {
                      throw new Error("開始時間を設定してください");
                    }
                  },
                }),
              ]}
            >
              <TimePicker formItemName={["specificTimeSurchargeConfig", "startTime"]} />
            </EditCashRegisterConfigFormItem>
            <EditCashRegisterConfigFormItem.NonProperty
              noStyle
              shouldUpdate={withFormDependencies(({ specificTimeSurchargeConfig }) => [
                specificTimeSurchargeConfig?.startTime,
              ])}
            >
              {({ getFieldValue }) => {
                const dateTime = dayjs(
                  getFieldValue(["specificTimeSurchargeConfig", "startTime"]),
                  "HH:mm",
                );
                const shouldShowDateTime = isDateChanged({
                  dateTime,
                  changeDateTime,
                });
                return (
                  shouldShowDateTime && (
                    <DateTime>
                      {getDateTimeBasedOnChangeDateTime({
                        dateTime,
                        changeDateTime,
                      })}
                    </DateTime>
                  )
                );
              }}
            </EditCashRegisterConfigFormItem.NonProperty>
          </Col>
          <Col>
            <Tilda>~</Tilda>
          </Col>
          <Col>
            <EditCashRegisterConfigFormItem
              name={["specificTimeSurchargeConfig", "endTime"]}
              endSpacer={null}
              rules={[
                ({ getFieldValue }) => ({
                  validator: async () => {
                    const start = getFieldValue(["specificTimeSurchargeConfig", "startTime"]);
                    const end = getFieldValue(["specificTimeSurchargeConfig", "endTime"]);
                    const chargeRate = getFieldValue(["specificTimeSurchargeConfig", "chargeRate"]);

                    if (!chargeRate || !start) return;

                    if (!end) {
                      throw new Error("終了時間を設定してください");
                    }

                    const normalizedStart = normalizeHoursAndMinutes(start);
                    const normalizedEnd = normalizeHoursAndMinutes(end);

                    if (
                      !isValidSpecificTimeSurchargeHoursDuration({
                        start: normalizedStart,
                        end: normalizedEnd,
                        changeDateTime,
                      })
                    ) {
                      throw new Error("開始時間より後の時間を入力してください");
                    }
                  },
                }),
              ]}
            >
              <TimePicker formItemName={["specificTimeSurchargeConfig", "endTime"]} />
            </EditCashRegisterConfigFormItem>
            <EditCashRegisterConfigFormItem.NonProperty
              noStyle
              shouldUpdate={withFormDependencies(({ specificTimeSurchargeConfig }) => [
                specificTimeSurchargeConfig?.endTime,
              ])}
            >
              {({ getFieldValue }) => {
                const dateTime = dayjs(
                  getFieldValue(["specificTimeSurchargeConfig", "endTime"]),
                  "HH:mm",
                );
                const shouldShowDateTime = isDateChanged({
                  dateTime,
                  changeDateTime,
                });
                return (
                  shouldShowDateTime && (
                    <DateTime>
                      {getDateTimeBasedOnChangeDateTime({
                        dateTime,
                        changeDateTime,
                      })}
                    </DateTime>
                  )
                );
              }}
            </EditCashRegisterConfigFormItem.NonProperty>
          </Col>
        </Row>
      </Col>
    </Row>
    <Spacer size={8} />
    <Row>
      <Col>
        <EditCashRegisterConfigFormItem
          label="サービス料率"
          name={["serviceChargeConfig", "chargeRate"]}
        >
          <InputNumber
            formatter={(value) => `${value}%`}
            parser={parsePercentage}
            min={0}
            max={100}
            placeholder="0%"
          />
        </EditCashRegisterConfigFormItem>
      </Col>
    </Row>
  </div>
));
