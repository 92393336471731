import React from "react";
import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { Select } from "components/Input/Select";
import { QuestionnaireConfig } from "pages/EditMessageDelivery/types";

import { AddMessageDeliveryFormItem } from "../useAddMessageDeliveryForm";

type Props = {
  name: number;
  questionnaireConfigs: QuestionnaireConfig[];
};

export const QuestionnaireConfigField = React.memo<Props>(({ name, questionnaireConfigs }) => (
  <AddMessageDeliveryFormItem
    name={[name, "questionnaireConfigId"]}
    endSpacer={null}
    rules={[{ required: true, message: "アンケートを選択してください" }]}
  >
    <Select
      placeholder="アンケートを選択してください"
      options={questionnaireConfigs.map(({ id, questionnaire }) => ({
        label: questionnaire.name,
        value: id,
      }))}
      showSearch
      allowClear
      fullWidth
      notFoundContent={
        <Paragraph type="secondary">
          選択できるアンケートが存在しません。
          <Typography.Link href="/questionnaireConfig/add" target="_blank">
            アンケート作成画面
          </Typography.Link>
          にてアンケートを作成してください。
        </Paragraph>
      }
    />
  </AddMessageDeliveryFormItem>
));
