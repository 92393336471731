import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/AddCompany/AddCompanyForm/NameField";
import {
  AddCompanyFormValues,
  useAddCompanyForm,
} from "pages/AddCompany/AddCompanyForm/useAddCompanyForm";

import { Corporation, CorporationField } from "./CorporationField";

type Props = {
  onSubmit: (args: AddCompanyFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  loading: boolean;
  corporations: Corporation[];
};

export const AddCompanyForm = memo<Props>(
  ({ onSubmit, onFormValidationError, loading, corporations }) => {
    const { form, initialValues, submit } = useAddCompanyForm(onSubmit);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    return (
      <>
        <FormContent>
          <Form name="company" form={form} initialValues={initialValues} layout="vertical">
            <CorporationField corporations={corporations} />
            <NameField />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </>
    );
  },
);
