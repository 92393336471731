import React, { memo } from "react";
import { Input } from "antd";
import { FormItemProps } from "antd/lib/form";

import { FormHelp } from "components/Form/FormHelp";

import { EditMenuFormItem } from "../useEditMenuForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled?: boolean };

export const ShopSideNameField = memo<Props>(({ disabled, ...props }) => (
  <EditMenuFormItem
    label={
      <FormHelp
        label="ハンディ表示名"
        help="ハンディでの表示名。未入力時はメニュー名が表示されます"
      />
    }
    name="shopSideName"
    {...props}
  >
    <Input disabled={disabled} />
  </EditMenuFormItem>
));
