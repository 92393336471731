import { useCallback, useState } from "react";
import { Store } from "rc-field-form/lib/interface";

import { createFormItem } from "components/antd/Form";

import { TecAggregationMediaMap } from "../types";

export type EditTecAggregationMediaMapFormValues = Pick<TecAggregationMediaMap, "mediaName">;

const getInitialValues = (tecAggregationMediaMap: EditTecAggregationMediaMapFormValues) => {
  const { mediaName } = tecAggregationMediaMap;

  return { mediaName };
};

export const EditTecAggregationMediaMapFormItem =
  createFormItem<EditTecAggregationMediaMapFormValues>();

export const useEditTecAggregationMediaMapForm = (
  tecAggregationMediaMap: EditTecAggregationMediaMapFormValues,
  onChange: (tecAggregationMediaMap: EditTecAggregationMediaMapFormValues) => void,
) => {
  const initialValues = getInitialValues(tecAggregationMediaMap);
  const [formValues, setFormValues] =
    useState<Partial<EditTecAggregationMediaMapFormValues>>(initialValues);

  const change = useCallback(
    (_: Store, formValues: Store) => {
      const values = formValues as EditTecAggregationMediaMapFormValues;
      setFormValues({ ...values });
      onChange({ ...values });
    },
    [onChange],
  );

  return { initialValues, formValues, change };
};
