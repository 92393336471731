import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FeatureFlagsGetFeatureFlagShops = gql`
    query FeatureFlagsGetFeatureFlagShops($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    name
    featureFlagShops {
      name
    }
  }
}
    `;
export const FeatureFlagsGetFeatureFlags = gql`
    query FeatureFlagsGetFeatureFlags {
  featureFlag {
    name
  }
}
    `;
export const FeatureFlagGetAdyenPaymentShopConfig = gql`
    query FeatureFlagGetAdyenPaymentShopConfig($shopId: uuid!) {
  adyenPaymentShopConfig(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    adyenPaymentShopPaymentMethods {
      paymentMethod
    }
  }
}
    `;
export const FeatureFlagsInsertFeatureFlagShop = gql`
    mutation FeatureFlagsInsertFeatureFlagShop($featureFlagShop: featureFlagShop_insert_input!) {
  insert_featureFlagShop_one(
    object: $featureFlagShop
    on_conflict: {constraint: featureFlagShop_pkey, update_columns: []}
  ) {
    name
  }
}
    `;
export const FeatureFlagsDeleteFeatureFlagShop = gql`
    mutation FeatureFlagsDeleteFeatureFlagShop($name: featureFlag_enum!, $shopId: uuid!) {
  delete_featureFlagShop_by_pk(name: $name, shopId: $shopId) {
    shopId
    name
  }
}
    `;
export const FeatureFlagsEnableStripeOnlinePayment = gql`
    mutation FeatureFlagsEnableStripeOnlinePayment($shopId: String!) {
  dashboardAccountEnableStripeOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export const FeatureFlagsEnableGmoOnlinePayment = gql`
    mutation FeatureFlagsEnableGmoOnlinePayment($shopId: String!) {
  dashboardAccountEnableGmoOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export const FeatureFlagsEnableAdyenOnlinePayment = gql`
    mutation FeatureFlagsEnableAdyenOnlinePayment($shopId: String!) {
  dashboardAccountEnableAdyenOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export const FeatureFlagsEnableEbicaConfig = gql`
    mutation FeatureFlagsEnableEbicaConfig($shopId: uuid!) {
  update_ebicaConfig(where: {shopId: {_eq: $shopId}}, _set: {enabled: true}) {
    affected_rows
  }
}
    `;
export const FeatureFlagsDisableEbicaConfig = gql`
    mutation FeatureFlagsDisableEbicaConfig($shopId: uuid!) {
  update_ebicaConfig(where: {shopId: {_eq: $shopId}}, _set: {enabled: false}) {
    affected_rows
  }
}
    `;
export type FeatureFlagsGetFeatureFlagShopsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type FeatureFlagsGetFeatureFlagShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
    & { featureFlagShops: Array<(
      { __typename?: 'featureFlagShop' }
      & Pick<Types.FeatureFlagShop, 'name'>
    )> }
  )> }
);

export type FeatureFlagsGetFeatureFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FeatureFlagsGetFeatureFlagsQuery = (
  { __typename?: 'query_root' }
  & { featureFlag: Array<(
    { __typename?: 'featureFlag' }
    & Pick<Types.FeatureFlag, 'name'>
  )> }
);

export type FeatureFlagGetAdyenPaymentShopConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type FeatureFlagGetAdyenPaymentShopConfigQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentShopConfig: Array<(
    { __typename?: 'adyenPaymentShopConfig' }
    & { adyenPaymentShopPaymentMethods: Array<(
      { __typename?: 'adyenPaymentShopPaymentMethod' }
      & Pick<Types.AdyenPaymentShopPaymentMethod, 'paymentMethod'>
    )> }
  )> }
);

export type FeatureFlagsInsertFeatureFlagShopMutationVariables = Types.Exact<{
  featureFlagShop: Types.FeatureFlagShopInsertInput;
}>;


export type FeatureFlagsInsertFeatureFlagShopMutation = (
  { __typename?: 'mutation_root' }
  & { insert_featureFlagShop_one?: Types.Maybe<(
    { __typename?: 'featureFlagShop' }
    & Pick<Types.FeatureFlagShop, 'name'>
  )> }
);

export type FeatureFlagsDeleteFeatureFlagShopMutationVariables = Types.Exact<{
  name: Types.FeatureFlagEnum;
  shopId: Types.Scalars['uuid'];
}>;


export type FeatureFlagsDeleteFeatureFlagShopMutation = (
  { __typename?: 'mutation_root' }
  & { delete_featureFlagShop_by_pk?: Types.Maybe<(
    { __typename?: 'featureFlagShop' }
    & Pick<Types.FeatureFlagShop, 'shopId' | 'name'>
  )> }
);

export type FeatureFlagsEnableStripeOnlinePaymentMutationVariables = Types.Exact<{
  shopId: Types.Scalars['String'];
}>;


export type FeatureFlagsEnableStripeOnlinePaymentMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountEnableStripeOnlinePayment: (
    { __typename?: 'EnableOnlinePaymentOutput' }
    & Pick<Types.EnableOnlinePaymentOutput, 'result'>
  ) }
);

export type FeatureFlagsEnableGmoOnlinePaymentMutationVariables = Types.Exact<{
  shopId: Types.Scalars['String'];
}>;


export type FeatureFlagsEnableGmoOnlinePaymentMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountEnableGmoOnlinePayment: (
    { __typename?: 'EnableOnlinePaymentOutput' }
    & Pick<Types.EnableOnlinePaymentOutput, 'result'>
  ) }
);

export type FeatureFlagsEnableAdyenOnlinePaymentMutationVariables = Types.Exact<{
  shopId: Types.Scalars['String'];
}>;


export type FeatureFlagsEnableAdyenOnlinePaymentMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountEnableAdyenOnlinePayment: (
    { __typename?: 'EnableOnlinePaymentOutput' }
    & Pick<Types.EnableOnlinePaymentOutput, 'result'>
  ) }
);

export type FeatureFlagsEnableEbicaConfigMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type FeatureFlagsEnableEbicaConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_ebicaConfig?: Types.Maybe<(
    { __typename?: 'ebicaConfig_mutation_response' }
    & Pick<Types.EbicaConfigMutationResponse, 'affected_rows'>
  )> }
);

export type FeatureFlagsDisableEbicaConfigMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type FeatureFlagsDisableEbicaConfigMutation = (
  { __typename?: 'mutation_root' }
  & { update_ebicaConfig?: Types.Maybe<(
    { __typename?: 'ebicaConfig_mutation_response' }
    & Pick<Types.EbicaConfigMutationResponse, 'affected_rows'>
  )> }
);


export const FeatureFlagsGetFeatureFlagShopsDocument = gql`
    query FeatureFlagsGetFeatureFlagShops($shopId: uuid!) {
  shop(where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}) {
    shopId
    name
    featureFlagShops {
      name
    }
  }
}
    `;

/**
 * __useFeatureFlagsGetFeatureFlagShopsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsGetFeatureFlagShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsGetFeatureFlagShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsGetFeatureFlagShopsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsGetFeatureFlagShopsQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagsGetFeatureFlagShopsQuery, FeatureFlagsGetFeatureFlagShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagsGetFeatureFlagShopsQuery, FeatureFlagsGetFeatureFlagShopsQueryVariables>(FeatureFlagsGetFeatureFlagShopsDocument, options);
      }
export function useFeatureFlagsGetFeatureFlagShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsGetFeatureFlagShopsQuery, FeatureFlagsGetFeatureFlagShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagsGetFeatureFlagShopsQuery, FeatureFlagsGetFeatureFlagShopsQueryVariables>(FeatureFlagsGetFeatureFlagShopsDocument, options);
        }
export type FeatureFlagsGetFeatureFlagShopsQueryHookResult = ReturnType<typeof useFeatureFlagsGetFeatureFlagShopsQuery>;
export type FeatureFlagsGetFeatureFlagShopsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsGetFeatureFlagShopsLazyQuery>;
export type FeatureFlagsGetFeatureFlagShopsQueryResult = Apollo.QueryResult<FeatureFlagsGetFeatureFlagShopsQuery, FeatureFlagsGetFeatureFlagShopsQueryVariables>;
export const FeatureFlagsGetFeatureFlagsDocument = gql`
    query FeatureFlagsGetFeatureFlags {
  featureFlag {
    name
  }
}
    `;

/**
 * __useFeatureFlagsGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsGetFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsGetFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagsGetFeatureFlagsQuery, FeatureFlagsGetFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagsGetFeatureFlagsQuery, FeatureFlagsGetFeatureFlagsQueryVariables>(FeatureFlagsGetFeatureFlagsDocument, options);
      }
export function useFeatureFlagsGetFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsGetFeatureFlagsQuery, FeatureFlagsGetFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagsGetFeatureFlagsQuery, FeatureFlagsGetFeatureFlagsQueryVariables>(FeatureFlagsGetFeatureFlagsDocument, options);
        }
export type FeatureFlagsGetFeatureFlagsQueryHookResult = ReturnType<typeof useFeatureFlagsGetFeatureFlagsQuery>;
export type FeatureFlagsGetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useFeatureFlagsGetFeatureFlagsLazyQuery>;
export type FeatureFlagsGetFeatureFlagsQueryResult = Apollo.QueryResult<FeatureFlagsGetFeatureFlagsQuery, FeatureFlagsGetFeatureFlagsQueryVariables>;
export const FeatureFlagGetAdyenPaymentShopConfigDocument = gql`
    query FeatureFlagGetAdyenPaymentShopConfig($shopId: uuid!) {
  adyenPaymentShopConfig(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
  ) {
    adyenPaymentShopPaymentMethods {
      paymentMethod
    }
  }
}
    `;

/**
 * __useFeatureFlagGetAdyenPaymentShopConfigQuery__
 *
 * To run a query within a React component, call `useFeatureFlagGetAdyenPaymentShopConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagGetAdyenPaymentShopConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagGetAdyenPaymentShopConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagGetAdyenPaymentShopConfigQuery(baseOptions: Apollo.QueryHookOptions<FeatureFlagGetAdyenPaymentShopConfigQuery, FeatureFlagGetAdyenPaymentShopConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagGetAdyenPaymentShopConfigQuery, FeatureFlagGetAdyenPaymentShopConfigQueryVariables>(FeatureFlagGetAdyenPaymentShopConfigDocument, options);
      }
export function useFeatureFlagGetAdyenPaymentShopConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagGetAdyenPaymentShopConfigQuery, FeatureFlagGetAdyenPaymentShopConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagGetAdyenPaymentShopConfigQuery, FeatureFlagGetAdyenPaymentShopConfigQueryVariables>(FeatureFlagGetAdyenPaymentShopConfigDocument, options);
        }
export type FeatureFlagGetAdyenPaymentShopConfigQueryHookResult = ReturnType<typeof useFeatureFlagGetAdyenPaymentShopConfigQuery>;
export type FeatureFlagGetAdyenPaymentShopConfigLazyQueryHookResult = ReturnType<typeof useFeatureFlagGetAdyenPaymentShopConfigLazyQuery>;
export type FeatureFlagGetAdyenPaymentShopConfigQueryResult = Apollo.QueryResult<FeatureFlagGetAdyenPaymentShopConfigQuery, FeatureFlagGetAdyenPaymentShopConfigQueryVariables>;
export const FeatureFlagsInsertFeatureFlagShopDocument = gql`
    mutation FeatureFlagsInsertFeatureFlagShop($featureFlagShop: featureFlagShop_insert_input!) {
  insert_featureFlagShop_one(
    object: $featureFlagShop
    on_conflict: {constraint: featureFlagShop_pkey, update_columns: []}
  ) {
    name
  }
}
    `;
export type FeatureFlagsInsertFeatureFlagShopMutationFn = Apollo.MutationFunction<FeatureFlagsInsertFeatureFlagShopMutation, FeatureFlagsInsertFeatureFlagShopMutationVariables>;

/**
 * __useFeatureFlagsInsertFeatureFlagShopMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsInsertFeatureFlagShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsInsertFeatureFlagShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsInsertFeatureFlagShopMutation, { data, loading, error }] = useFeatureFlagsInsertFeatureFlagShopMutation({
 *   variables: {
 *      featureFlagShop: // value for 'featureFlagShop'
 *   },
 * });
 */
export function useFeatureFlagsInsertFeatureFlagShopMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsInsertFeatureFlagShopMutation, FeatureFlagsInsertFeatureFlagShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsInsertFeatureFlagShopMutation, FeatureFlagsInsertFeatureFlagShopMutationVariables>(FeatureFlagsInsertFeatureFlagShopDocument, options);
      }
export type FeatureFlagsInsertFeatureFlagShopMutationHookResult = ReturnType<typeof useFeatureFlagsInsertFeatureFlagShopMutation>;
export type FeatureFlagsInsertFeatureFlagShopMutationResult = Apollo.MutationResult<FeatureFlagsInsertFeatureFlagShopMutation>;
export type FeatureFlagsInsertFeatureFlagShopMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsInsertFeatureFlagShopMutation, FeatureFlagsInsertFeatureFlagShopMutationVariables>;
export const FeatureFlagsDeleteFeatureFlagShopDocument = gql`
    mutation FeatureFlagsDeleteFeatureFlagShop($name: featureFlag_enum!, $shopId: uuid!) {
  delete_featureFlagShop_by_pk(name: $name, shopId: $shopId) {
    shopId
    name
  }
}
    `;
export type FeatureFlagsDeleteFeatureFlagShopMutationFn = Apollo.MutationFunction<FeatureFlagsDeleteFeatureFlagShopMutation, FeatureFlagsDeleteFeatureFlagShopMutationVariables>;

/**
 * __useFeatureFlagsDeleteFeatureFlagShopMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsDeleteFeatureFlagShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsDeleteFeatureFlagShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsDeleteFeatureFlagShopMutation, { data, loading, error }] = useFeatureFlagsDeleteFeatureFlagShopMutation({
 *   variables: {
 *      name: // value for 'name'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsDeleteFeatureFlagShopMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsDeleteFeatureFlagShopMutation, FeatureFlagsDeleteFeatureFlagShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsDeleteFeatureFlagShopMutation, FeatureFlagsDeleteFeatureFlagShopMutationVariables>(FeatureFlagsDeleteFeatureFlagShopDocument, options);
      }
export type FeatureFlagsDeleteFeatureFlagShopMutationHookResult = ReturnType<typeof useFeatureFlagsDeleteFeatureFlagShopMutation>;
export type FeatureFlagsDeleteFeatureFlagShopMutationResult = Apollo.MutationResult<FeatureFlagsDeleteFeatureFlagShopMutation>;
export type FeatureFlagsDeleteFeatureFlagShopMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsDeleteFeatureFlagShopMutation, FeatureFlagsDeleteFeatureFlagShopMutationVariables>;
export const FeatureFlagsEnableStripeOnlinePaymentDocument = gql`
    mutation FeatureFlagsEnableStripeOnlinePayment($shopId: String!) {
  dashboardAccountEnableStripeOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export type FeatureFlagsEnableStripeOnlinePaymentMutationFn = Apollo.MutationFunction<FeatureFlagsEnableStripeOnlinePaymentMutation, FeatureFlagsEnableStripeOnlinePaymentMutationVariables>;

/**
 * __useFeatureFlagsEnableStripeOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsEnableStripeOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsEnableStripeOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsEnableStripeOnlinePaymentMutation, { data, loading, error }] = useFeatureFlagsEnableStripeOnlinePaymentMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsEnableStripeOnlinePaymentMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsEnableStripeOnlinePaymentMutation, FeatureFlagsEnableStripeOnlinePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsEnableStripeOnlinePaymentMutation, FeatureFlagsEnableStripeOnlinePaymentMutationVariables>(FeatureFlagsEnableStripeOnlinePaymentDocument, options);
      }
export type FeatureFlagsEnableStripeOnlinePaymentMutationHookResult = ReturnType<typeof useFeatureFlagsEnableStripeOnlinePaymentMutation>;
export type FeatureFlagsEnableStripeOnlinePaymentMutationResult = Apollo.MutationResult<FeatureFlagsEnableStripeOnlinePaymentMutation>;
export type FeatureFlagsEnableStripeOnlinePaymentMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsEnableStripeOnlinePaymentMutation, FeatureFlagsEnableStripeOnlinePaymentMutationVariables>;
export const FeatureFlagsEnableGmoOnlinePaymentDocument = gql`
    mutation FeatureFlagsEnableGmoOnlinePayment($shopId: String!) {
  dashboardAccountEnableGmoOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export type FeatureFlagsEnableGmoOnlinePaymentMutationFn = Apollo.MutationFunction<FeatureFlagsEnableGmoOnlinePaymentMutation, FeatureFlagsEnableGmoOnlinePaymentMutationVariables>;

/**
 * __useFeatureFlagsEnableGmoOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsEnableGmoOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsEnableGmoOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsEnableGmoOnlinePaymentMutation, { data, loading, error }] = useFeatureFlagsEnableGmoOnlinePaymentMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsEnableGmoOnlinePaymentMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsEnableGmoOnlinePaymentMutation, FeatureFlagsEnableGmoOnlinePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsEnableGmoOnlinePaymentMutation, FeatureFlagsEnableGmoOnlinePaymentMutationVariables>(FeatureFlagsEnableGmoOnlinePaymentDocument, options);
      }
export type FeatureFlagsEnableGmoOnlinePaymentMutationHookResult = ReturnType<typeof useFeatureFlagsEnableGmoOnlinePaymentMutation>;
export type FeatureFlagsEnableGmoOnlinePaymentMutationResult = Apollo.MutationResult<FeatureFlagsEnableGmoOnlinePaymentMutation>;
export type FeatureFlagsEnableGmoOnlinePaymentMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsEnableGmoOnlinePaymentMutation, FeatureFlagsEnableGmoOnlinePaymentMutationVariables>;
export const FeatureFlagsEnableAdyenOnlinePaymentDocument = gql`
    mutation FeatureFlagsEnableAdyenOnlinePayment($shopId: String!) {
  dashboardAccountEnableAdyenOnlinePayment(input: {shopId: $shopId}) {
    result
  }
}
    `;
export type FeatureFlagsEnableAdyenOnlinePaymentMutationFn = Apollo.MutationFunction<FeatureFlagsEnableAdyenOnlinePaymentMutation, FeatureFlagsEnableAdyenOnlinePaymentMutationVariables>;

/**
 * __useFeatureFlagsEnableAdyenOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsEnableAdyenOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsEnableAdyenOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsEnableAdyenOnlinePaymentMutation, { data, loading, error }] = useFeatureFlagsEnableAdyenOnlinePaymentMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsEnableAdyenOnlinePaymentMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsEnableAdyenOnlinePaymentMutation, FeatureFlagsEnableAdyenOnlinePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsEnableAdyenOnlinePaymentMutation, FeatureFlagsEnableAdyenOnlinePaymentMutationVariables>(FeatureFlagsEnableAdyenOnlinePaymentDocument, options);
      }
export type FeatureFlagsEnableAdyenOnlinePaymentMutationHookResult = ReturnType<typeof useFeatureFlagsEnableAdyenOnlinePaymentMutation>;
export type FeatureFlagsEnableAdyenOnlinePaymentMutationResult = Apollo.MutationResult<FeatureFlagsEnableAdyenOnlinePaymentMutation>;
export type FeatureFlagsEnableAdyenOnlinePaymentMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsEnableAdyenOnlinePaymentMutation, FeatureFlagsEnableAdyenOnlinePaymentMutationVariables>;
export const FeatureFlagsEnableEbicaConfigDocument = gql`
    mutation FeatureFlagsEnableEbicaConfig($shopId: uuid!) {
  update_ebicaConfig(where: {shopId: {_eq: $shopId}}, _set: {enabled: true}) {
    affected_rows
  }
}
    `;
export type FeatureFlagsEnableEbicaConfigMutationFn = Apollo.MutationFunction<FeatureFlagsEnableEbicaConfigMutation, FeatureFlagsEnableEbicaConfigMutationVariables>;

/**
 * __useFeatureFlagsEnableEbicaConfigMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsEnableEbicaConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsEnableEbicaConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsEnableEbicaConfigMutation, { data, loading, error }] = useFeatureFlagsEnableEbicaConfigMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsEnableEbicaConfigMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsEnableEbicaConfigMutation, FeatureFlagsEnableEbicaConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsEnableEbicaConfigMutation, FeatureFlagsEnableEbicaConfigMutationVariables>(FeatureFlagsEnableEbicaConfigDocument, options);
      }
export type FeatureFlagsEnableEbicaConfigMutationHookResult = ReturnType<typeof useFeatureFlagsEnableEbicaConfigMutation>;
export type FeatureFlagsEnableEbicaConfigMutationResult = Apollo.MutationResult<FeatureFlagsEnableEbicaConfigMutation>;
export type FeatureFlagsEnableEbicaConfigMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsEnableEbicaConfigMutation, FeatureFlagsEnableEbicaConfigMutationVariables>;
export const FeatureFlagsDisableEbicaConfigDocument = gql`
    mutation FeatureFlagsDisableEbicaConfig($shopId: uuid!) {
  update_ebicaConfig(where: {shopId: {_eq: $shopId}}, _set: {enabled: false}) {
    affected_rows
  }
}
    `;
export type FeatureFlagsDisableEbicaConfigMutationFn = Apollo.MutationFunction<FeatureFlagsDisableEbicaConfigMutation, FeatureFlagsDisableEbicaConfigMutationVariables>;

/**
 * __useFeatureFlagsDisableEbicaConfigMutation__
 *
 * To run a mutation, you first call `useFeatureFlagsDisableEbicaConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsDisableEbicaConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [featureFlagsDisableEbicaConfigMutation, { data, loading, error }] = useFeatureFlagsDisableEbicaConfigMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useFeatureFlagsDisableEbicaConfigMutation(baseOptions?: Apollo.MutationHookOptions<FeatureFlagsDisableEbicaConfigMutation, FeatureFlagsDisableEbicaConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FeatureFlagsDisableEbicaConfigMutation, FeatureFlagsDisableEbicaConfigMutationVariables>(FeatureFlagsDisableEbicaConfigDocument, options);
      }
export type FeatureFlagsDisableEbicaConfigMutationHookResult = ReturnType<typeof useFeatureFlagsDisableEbicaConfigMutation>;
export type FeatureFlagsDisableEbicaConfigMutationResult = Apollo.MutationResult<FeatureFlagsDisableEbicaConfigMutation>;
export type FeatureFlagsDisableEbicaConfigMutationOptions = Apollo.BaseMutationOptions<FeatureFlagsDisableEbicaConfigMutation, FeatureFlagsDisableEbicaConfigMutationVariables>;