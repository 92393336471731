import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";

type Props = { disabled?: boolean } & Omit<FormItemProps, "children" | "name">;

export const GmoShopIdField = memo<Props>(({ disabled, ...props }) => (
  <EditDiniiPayConfigFormItem
    label="GMO ショップ ID"
    name={["onlinePaymentConfig", "gmoShopId"]}
    rules={[{ required: true }]}
    {...props}
  >
    <Input disabled={disabled} />
  </EditDiniiPayConfigFormItem>
));
