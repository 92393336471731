import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import { EditMenuTranslationsFormItem } from "./useEditMenuTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const DescriptionField = memo<Props>(({ ...props }) => (
  <EditMenuTranslationsFormItem label="原文" name="description" {...props}>
    <TextArea rows={2} disabled />
  </EditMenuTranslationsFormItem>
));
