import { useCallback, useMemo } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { PikaichiOnSitePaymentDiscountTypeInsertInput } from "types/graphql";

import { PikaichiOnSitePaymentDiscountType } from "../types";

export type EditPikaichiOnSitePaymentDiscountTypeFormValues = {
  newariCd: number | undefined;
  newariKubun: number | undefined;
  newariName: string | undefined;
};

export const EditPikaichiOnSitePaymentDiscountTypeFormItem =
  createFormItem<EditPikaichiOnSitePaymentDiscountTypeFormValues>();

export const useEditPikaichiOnSitePaymentDiscountTypeForm = ({
  pikaichiDiscountType,
  onSubmit,
}: {
  pikaichiDiscountType?: PikaichiOnSitePaymentDiscountType;
  onSubmit: (
    args: Omit<
      PikaichiOnSitePaymentDiscountTypeInsertInput,
      "companyId" | "onSitePaymentDiscountTypeKey" | "onSitePaymentDiscountTypeId"
    >,
  ) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<EditPikaichiOnSitePaymentDiscountTypeFormValues>(
    () => ({
      newariCd: pikaichiDiscountType?.newariCd,
      newariKubun: pikaichiDiscountType?.newariKubun,
      newariName: pikaichiDiscountType?.newariName,
    }),
    [
      pikaichiDiscountType?.newariCd,
      pikaichiDiscountType?.newariKubun,
      pikaichiDiscountType?.newariName,
    ],
  );

  const submit = useCallback(async () => {
    await form.validateFields();

    const values = form.getFieldsValue() as EditPikaichiOnSitePaymentDiscountTypeFormValues;

    onSubmit({
      newariCd: values.newariCd ?? 0,
      newariKubun: values.newariKubun ?? 0,
      newariName: values.newariName ?? "",
    });
  }, [form, onSubmit]);

  return { form, initialValues, submit };
};
