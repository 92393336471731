import React from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import { Alert } from "antd";
import { saveAs } from "file-saver";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { useDinii } from "hooks/useDinii";
import { useExtractErrorResponse } from "hooks/useExtractErrorResponse";
import { useShop } from "hooks/useShop";
import { InputShopDataForm } from "pages/InputShopData/InputShopDataForm";
import { InputShopDataFormValues } from "pages/InputShopData/InputShopDataForm/useInputShopDataForm";

import { useInputShopDataGetCompanyQuery } from "./queries";

export const InputShopData = () => {
  const [company] = useCompany();
  const [shop] = useShop();
  const [dinii, getContext] = useDinii();

  const { data } = useInputShopDataGetCompanyQuery(
    company ? { variables: { companyId: company.id } } : { skip: true },
  );

  const [_, downloadTemplate] = useAsyncFn(async () => {
    const context = await getContext();
    if (!company || !context) return;
    const { data } = await dinii.downloadInputShopDataTemplate(context, {
      companyId: company.companyId,
    });

    saveAs(data, `店舗データ投入テンプレート.xlsx`);
  }, [company, dinii, getContext]);

  const [{ loading, error }, onSubmit] = useAsyncFn(
    async (args: InputShopDataFormValues) => {
      if (!company) throw new Error("company is not available");

      if (!shop) throw new Error("shop is not available");

      const context = await getContext();

      if (!context) throw new Error("context is not available");

      const formData = new FormData();

      formData.append("companyId", company.id);
      formData.append("_companyId", String(company.companyId));
      formData.append("shopId", shop.shopId);
      formData.append("table", args.table);
      formData.append("dishUpSlipGroup", args.dishUpSlipGroup);
      formData.append("role", args.role);
      formData.append("shopMenu", args.shopMenu);
      formData.append("shopPlan", args.shopPlan);
      formData.append("shopOrderableTimeTerm", args.shopOrderableTimeTerm);
      formData.append("cookingItem", args.cookingItem);

      await dinii.inputShopData(context, formData);

      await message.loading(
        "店舗データ投入が完了しました。最新のデータを表示するためにブラウザをリロードします。",
      );

      window.location.reload();
    },
    [company, dinii, getContext, shop],
  );

  const errorResponse = useExtractErrorResponse(error);

  return (
    <DashboardLayout title="店舗データ投入">
      <PageHeader
        title="店舗データ投入"
        description="店舗データ投入はスプレッドシートに記載された店舗のデータをデータベースに投入する作業のことです。"
      />
      {errorResponse && (
        <Alert type="error" message={errorResponse.title} description={errorResponse.message} />
      )}
      <InputShopDataForm
        selectedCompany={data?.company?.[0]?.name}
        downloadTemplate={downloadTemplate}
        onSubmit={onSubmit}
        submitting={loading}
      />
    </DashboardLayout>
  );
};
