import React, { memo } from "react";
import styled from "styled-components";
import { Row } from "antd";
import { ColProps } from "antd/lib/col";

import { InitialAvatarListItem } from "components/InitialAvatarListItem";

import { Shop } from "../types";

const Wrapper = styled(Row)`
  padding: 8px;
`;

type Props = {
  shops: Shop[];
  onSelect: (shopId: string) => void;
} & Pick<ColProps, "span">;

export const ShopSelect = memo<Props>(({ shops, onSelect, span }) => (
  <Wrapper>
    {shops.map(({ shopId, name }) => (
      <InitialAvatarListItem
        key={shopId}
        name={name}
        span={span}
        onClick={() => onSelect(shopId)}
      />
    ))}
  </Wrapper>
));
