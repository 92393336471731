import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";

import { MessageDeliveryJobFormItem } from "../useMessageDeliveryJobForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const IsRepeatDeliveryField = memo<Props>((props) => (
  <MessageDeliveryJobFormItem {...props} name="isRepeatDelivery" valuePropName="checked" noStyle>
    <Checkbox>毎日繰り返す</Checkbox>
  </MessageDeliveryJobFormItem>
));
