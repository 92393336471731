import { useMemo, useState } from "react";

import type { Menu } from "pages/ShopMenus/types";

import type { RowItemChoice } from "./types";

export const useChoices = ({
  menus,
  currentPageMenuIds,
}: {
  menus: Menu[];
  currentPageMenuIds: number[];
}) => {
  const choices = useMemo<RowItemChoice[]>(
    () =>
      menus
        .filter((menu) => menu.menuOptions.length !== 0)
        .flatMap((menu) =>
          menu.menuOptions.flatMap(({ option }) =>
            option.choices.map((choice) => ({
              ...choice,
              type: "choice" as const,
              optionId: option.optionId,
              menuId: menu.menuId,
              name: `${option.name} ${choice.name}`,
            })),
          ),
        ),
    [menus],
  );

  const [displayedChoices, setDisplayedChoices] = useState<RowItemChoice[]>(choices);

  const currentPageChoiceRowKeys = useMemo(
    () =>
      menus
        .filter((menu) => menu.menuOptions.length !== 0)
        .filter((menu) => currentPageMenuIds.includes(menu.menuId))
        .flatMap((menu) =>
          menu.menuOptions.flatMap(({ option }) =>
            option.choices.map((choice) => `${menu.menuId}-${choice.choiceId}`),
          ),
        ),
    [menus, currentPageMenuIds],
  );

  return {
    currentPageChoiceRowKeys,
    choices,
    displayedChoices,
    setDisplayedChoices,
  };
};
