import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddDishUpSlipGroupMenuModalGetMenus = gql`
    query AddDishUpSlipGroupMenuModalGetMenus($companyId: uuid!, $shopId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        id
        menuId
        name
      }
    }
  }
}
    `;
export const AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenus = gql`
    mutation AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenus($dishUpSlipGroupShopMenus: [dishUpSlipGroupShopMenus_insert_input!]!) {
  insert_dishUpSlipGroupShopMenus(
    objects: $dishUpSlipGroupShopMenus
    on_conflict: {constraint: dishUpSlipGroupShopMenus_shopId_menuId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      menuId: _menuId
    }
  }
}
    `;
export type AddDishUpSlipGroupMenuModalGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
}>;


export type AddDishUpSlipGroupMenuModalGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'id' | 'menuId' | 'name'>
      ) }
    )> }
  )> }
);

export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationVariables = Types.Exact<{
  dishUpSlipGroupShopMenus: Array<Types.DishUpSlipGroupShopMenusInsertInput> | Types.DishUpSlipGroupShopMenusInsertInput;
}>;


export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_dishUpSlipGroupShopMenus?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupShopMenus_mutation_response' }
    & Pick<Types.DishUpSlipGroupShopMenusMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dishUpSlipGroupShopMenus' }
      & Pick<Types.DishUpSlipGroupShopMenus, 'dishUpSlipGroupId'>
      & { menuId: Types.DishUpSlipGroupShopMenus['_menuId'] }
    )> }
  )> }
);


export const AddDishUpSlipGroupMenuModalGetMenusDocument = gql`
    query AddDishUpSlipGroupMenuModalGetMenus($companyId: uuid!, $shopId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, _not: {giftMeta: {}}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        id
        menuId
        name
      }
    }
  }
}
    `;

/**
 * __useAddDishUpSlipGroupMenuModalGetMenusQuery__
 *
 * To run a query within a React component, call `useAddDishUpSlipGroupMenuModalGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpSlipGroupMenuModalGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDishUpSlipGroupMenuModalGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddDishUpSlipGroupMenuModalGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddDishUpSlipGroupMenuModalGetMenusQuery, AddDishUpSlipGroupMenuModalGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddDishUpSlipGroupMenuModalGetMenusQuery, AddDishUpSlipGroupMenuModalGetMenusQueryVariables>(AddDishUpSlipGroupMenuModalGetMenusDocument, options);
      }
export function useAddDishUpSlipGroupMenuModalGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddDishUpSlipGroupMenuModalGetMenusQuery, AddDishUpSlipGroupMenuModalGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddDishUpSlipGroupMenuModalGetMenusQuery, AddDishUpSlipGroupMenuModalGetMenusQueryVariables>(AddDishUpSlipGroupMenuModalGetMenusDocument, options);
        }
export type AddDishUpSlipGroupMenuModalGetMenusQueryHookResult = ReturnType<typeof useAddDishUpSlipGroupMenuModalGetMenusQuery>;
export type AddDishUpSlipGroupMenuModalGetMenusLazyQueryHookResult = ReturnType<typeof useAddDishUpSlipGroupMenuModalGetMenusLazyQuery>;
export type AddDishUpSlipGroupMenuModalGetMenusQueryResult = Apollo.QueryResult<AddDishUpSlipGroupMenuModalGetMenusQuery, AddDishUpSlipGroupMenuModalGetMenusQueryVariables>;
export const AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusDocument = gql`
    mutation AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenus($dishUpSlipGroupShopMenus: [dishUpSlipGroupShopMenus_insert_input!]!) {
  insert_dishUpSlipGroupShopMenus(
    objects: $dishUpSlipGroupShopMenus
    on_conflict: {constraint: dishUpSlipGroupShopMenus_shopId_menuId_dishUpSlipGroupId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      menuId: _menuId
    }
  }
}
    `;
export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationFn = Apollo.MutationFunction<AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation, AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationVariables>;

/**
 * __useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation__
 *
 * To run a mutation, you first call `useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation, { data, loading, error }] = useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation({
 *   variables: {
 *      dishUpSlipGroupShopMenus: // value for 'dishUpSlipGroupShopMenus'
 *   },
 * });
 */
export function useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation, AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation, AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationVariables>(AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusDocument, options);
      }
export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationHookResult = ReturnType<typeof useAddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation>;
export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationResult = Apollo.MutationResult<AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation>;
export type AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationOptions = Apollo.BaseMutationOptions<AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutation, AddDishUpSlipGroupMenuModalInsertDishUpSlipGroupShopMenusMutationVariables>;