import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button, Card, Col, Row } from "antd";

import { Form, FormList } from "components/antd/Form";
import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { ImageField } from "components/Form/ImageField";
import { WithPreviewFormContainer } from "components/Form/WithPreviewFormContainer";
import { MessagePreviewContent } from "components/MessageDeliveryForm/MessagePreview/MessagePreviewContent";
import { LineOfficialAccountFragment } from "components/MessageDeliveryForm/MessagePreview/queries";
import { Spacer } from "components/Spacer";
import { UserAppPreview } from "components/UserAppPreview";
import { CustomerListField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/CustomerListField";
import { CustomerSegmentField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/CustomerSegmentField";
import { NameField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/NameField";
import { QuestionnaireConfigField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/QuestionnaireConfigField";
import { Scope, ScopeField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/ScopeField";
import { TargetCustomers } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/TargetCustomers";
import { TextField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/TextField";
import { TypeField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/TypeField";
import { UrlField } from "pages/MessageDeliveryJob/MessageDeliveryJobForm/UrlField";
import {
  MessageDeliveryJobFormItem,
  MessageDeliveryJobFormValues,
  useMessageDeliveryJobForm,
} from "pages/MessageDeliveryJob/MessageDeliveryJobForm/useMessageDeliveryJobForm";
import {
  Coupon,
  Menu,
  MessageDelivery,
  QuestionnaireConfig,
  Shop,
} from "pages/MessageDeliveryJob/types";
import { BusinessOperationHourTypeEnum, MessageDeliveryMessageTypeEnum } from "types/graphql";

import { CouponSelectField } from "./CouponSelectField";
import { CSVUploadCustomerListField } from "./CSVUploadCustomerListField";
import { DateField } from "./DateField";
import { FormSection } from "./FormSection";
import { IsRepeatDeliveryField } from "./IsRepeatDeliveryField";
import { TimeField } from "./TimeField";

const StyledCard = styled(Card)`
  margin: 20px;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  width: 300px;
`;

type Props = {
  loading: boolean;
  messageDelivery: MessageDelivery;
  shops: Shop[];
  coupons: Coupon[];
  menus: Menu[];
  questionnaireConfigs: QuestionnaireConfig[];
  shopBusinessOperationHourTypes: BusinessOperationHourTypeEnum[];
  lineOfficialAccounts: LineOfficialAccountFragment[];
};

export const MessageDeliveryJobForm = memo<Props>(
  ({
    coupons,
    questionnaireConfigs,
    messageDelivery,
    shops,
    menus,
    shopBusinessOperationHourTypes,
    lineOfficialAccounts,
  }: Props) => {
    const customers = useMemo(
      () =>
        messageDelivery.customerList?.customerListCustomers?.map(({ customer }) => customer) ?? [],
      [messageDelivery.customerList?.customerListCustomers],
    );

    const { form, initialValues } = useMessageDeliveryJobForm({ messageDelivery });

    return (
      <Form
        name="messageDelivery"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        disabled
      >
        <WithPreviewFormContainer>
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ情報">
                <NameField endSpacer={null} />
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="配信対象">
                <ScopeField endSpacer={null} />
                <Spacer size={24} />
                <MessageDeliveryJobFormItem.NonProperty noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const scope: Scope = getFieldValue("scope");
                    if (scope === "customerSegment") {
                      return (
                        <CustomerSegmentField
                          form={form}
                          shops={shops}
                          menus={menus}
                          shopBusinessOperationHourTypes={shopBusinessOperationHourTypes}
                        />
                      );
                    }
                    if (scope === "customerListCsvUpload") {
                      return <CSVUploadCustomerListField customers={customers} />;
                    }
                    if (scope === "customerList") {
                      return (
                        <CustomerListField
                          customers={customers}
                          initialTargetCustomersCount={initialValues.customerIds?.length ?? 0}
                        />
                      );
                    }
                    return <TargetCustomers number={customers.length} />;
                  }}
                </MessageDeliveryJobFormItem.NonProperty>
              </FormSection>
            </Col>
          </Row>
          <Spacer size={32} />
          <FormSection title="配信日時">
            <Row>
              <Col span={12}>
                <DateField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverTimeName="deliverTime"
                />
              </Col>
              <Col span={12}>
                <TimeField
                  wrapperCol={{ span: 16 }}
                  endSpacer={null}
                  deliverDateName="deliverDate"
                />
              </Col>
            </Row>
            <Spacer size={24} />
            <IsRepeatDeliveryField />
          </FormSection>
          <Spacer size={32} />
          <Row>
            <Col flex="auto">
              <FormSection title="メッセージ">
                <FormList name="messages">
                  {(fields, { remove }) => (
                    <>
                      {fields.map((field) => (
                        <StyledCard key={field.key}>
                          <Row justify="space-around" align="middle">
                            <Col flex={1}>
                              <TypeField name={field.name} />
                            </Col>
                            <Col>
                              <Button
                                icon={<DeleteIcon disabled={fields.length === 1} />}
                                onClick={() => remove(field.name)}
                                disabled={fields.length === 1}
                              />
                            </Col>
                          </Row>
                          <Spacer height={20} />
                          <MessageDeliveryJobFormItem.NonProperty noStyle shouldUpdate>
                            {({ getFieldValue, setFields }) => {
                              const type: MessageDeliveryMessageTypeEnum = getFieldValue([
                                "messages",
                                field.name,
                                "type",
                              ]);

                              switch (type) {
                                case "text":
                                  return (
                                    <TextField
                                      name={field.name}
                                      endSpacer={<Spacer size={22} /* char count の高さ */ />}
                                      disabled
                                    />
                                  );
                                case "image": {
                                  const imageNamePath = ["messages", field.name, "imageUrl"];
                                  const imageUrl: string = getFieldValue(imageNamePath);
                                  return (
                                    <>
                                      <Row gutter={16}>
                                        <Col>
                                          <ImageField
                                            image={imageUrl}
                                            setUploadImage={(image) =>
                                              setFields([{ name: imageNamePath, value: image }])
                                            }
                                            uploadImageApiKey="messageImage"
                                            formName="MessageDeliveryJobForm"
                                            bottomHelpText="画像は、正方形のみ選択可能です"
                                            shouldValidateSquareImage
                                          />
                                        </Col>
                                        <Col flex={1}>
                                          <UrlField name={field.name} endSpacer={null} />
                                        </Col>
                                      </Row>
                                      <Spacer
                                        size={8} /* ImageField に 24px の margin-bottom がある */
                                      />
                                    </>
                                  );
                                }
                                case "coupon": {
                                  const couponId = getFieldValue([
                                    "messages",
                                    field.name,
                                    "couponId",
                                  ]);
                                  return (
                                    <>
                                      <CouponSelectField
                                        name={field.name}
                                        couponId={couponId}
                                        coupons={coupons}
                                      />
                                      <Spacer size={32} />
                                    </>
                                  );
                                }
                                case "questionnaire": {
                                  const questionnaireConfigId = getFieldValue([
                                    "messages",
                                    field.name,
                                    "questionnaireConfigId",
                                  ]);
                                  return (
                                    <>
                                      <QuestionnaireConfigField
                                        name={field.name}
                                        questionnaireConfigId={questionnaireConfigId}
                                        questionnaireConfigs={questionnaireConfigs}
                                      />
                                      <Spacer size={32} />
                                    </>
                                  );
                                }
                                default:
                                  return <p>Unreachable</p>;
                              }
                            }}
                          </MessageDeliveryJobFormItem.NonProperty>
                        </StyledCard>
                      ))}
                    </>
                  )}
                </FormList>
              </FormSection>
            </Col>
          </Row>

          <MessageDeliveryJobFormItem.NonProperty shouldUpdate noStyle initialValue={initialValues}>
            {({ getFieldsValue }) => {
              const { messages } = getFieldsValue() as MessageDeliveryJobFormValues;

              return (
                <PreviewWrapper>
                  <UserAppPreview initialOpen={false}>
                    <MessagePreviewContent
                      lineOfficialAccount={lineOfficialAccounts[0]}
                      messages={messages ?? []}
                    />
                  </UserAppPreview>
                </PreviewWrapper>
              );
            }}
          </MessageDeliveryJobFormItem.NonProperty>
        </WithPreviewFormContainer>
      </Form>
    );
  },
);
