import * as Types from '../../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AccountingSlipImageTableDeleteAccountingSlipImage = gql`
    mutation AccountingSlipImageTableDeleteAccountingSlipImage($accountingSlipImageId: uuid!) {
  delete_accountingSlipImage_by_pk(id: $accountingSlipImageId) {
    accountingSlipImageId: id
  }
}
    `;
export type AccountingSlipImageTableDeleteAccountingSlipImageMutationVariables = Types.Exact<{
  accountingSlipImageId: Types.Scalars['uuid'];
}>;


export type AccountingSlipImageTableDeleteAccountingSlipImageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_accountingSlipImage_by_pk?: Types.Maybe<(
    { __typename?: 'accountingSlipImage' }
    & { accountingSlipImageId: Types.AccountingSlipImage['id'] }
  )> }
);


export const AccountingSlipImageTableDeleteAccountingSlipImageDocument = gql`
    mutation AccountingSlipImageTableDeleteAccountingSlipImage($accountingSlipImageId: uuid!) {
  delete_accountingSlipImage_by_pk(id: $accountingSlipImageId) {
    accountingSlipImageId: id
  }
}
    `;
export type AccountingSlipImageTableDeleteAccountingSlipImageMutationFn = Apollo.MutationFunction<AccountingSlipImageTableDeleteAccountingSlipImageMutation, AccountingSlipImageTableDeleteAccountingSlipImageMutationVariables>;

/**
 * __useAccountingSlipImageTableDeleteAccountingSlipImageMutation__
 *
 * To run a mutation, you first call `useAccountingSlipImageTableDeleteAccountingSlipImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountingSlipImageTableDeleteAccountingSlipImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountingSlipImageTableDeleteAccountingSlipImageMutation, { data, loading, error }] = useAccountingSlipImageTableDeleteAccountingSlipImageMutation({
 *   variables: {
 *      accountingSlipImageId: // value for 'accountingSlipImageId'
 *   },
 * });
 */
export function useAccountingSlipImageTableDeleteAccountingSlipImageMutation(baseOptions?: Apollo.MutationHookOptions<AccountingSlipImageTableDeleteAccountingSlipImageMutation, AccountingSlipImageTableDeleteAccountingSlipImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountingSlipImageTableDeleteAccountingSlipImageMutation, AccountingSlipImageTableDeleteAccountingSlipImageMutationVariables>(AccountingSlipImageTableDeleteAccountingSlipImageDocument, options);
      }
export type AccountingSlipImageTableDeleteAccountingSlipImageMutationHookResult = ReturnType<typeof useAccountingSlipImageTableDeleteAccountingSlipImageMutation>;
export type AccountingSlipImageTableDeleteAccountingSlipImageMutationResult = Apollo.MutationResult<AccountingSlipImageTableDeleteAccountingSlipImageMutation>;
export type AccountingSlipImageTableDeleteAccountingSlipImageMutationOptions = Apollo.BaseMutationOptions<AccountingSlipImageTableDeleteAccountingSlipImageMutation, AccountingSlipImageTableDeleteAccountingSlipImageMutationVariables>;