import React, { memo, useCallback, useState } from "react";
import { Alert, Button } from "antd";
import { getAllocatedDailySalesBudget } from "models/salesBudget";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isNotUndefined } from "util/type/primitive";

import { Form, withFormDependencies } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import {
  MonthlySalesBudgetInsertInput,
  SalesBudgetAllocationSettingInsertInput,
} from "types/graphql";

import {
  useAddSalesBudgetGetMonthlySalesBudgetLazyQuery,
  useAddSalesBudgetGetSalesForBudgetLazyQuery,
} from "../queries";
import { AllocationSetting } from "../types";

import { AllocationSettingModal } from "./AllocationSettingModal";
import { DailyBudgetField } from "./DailyBudgetField";
import { MonthlyBudgetField } from "./MonthlyBudgetField";
import { TargetMonthField } from "./TargetMonthField";
import {
  AddSalesBudgetFormItem,
  AddSalesBudgetFormValues,
  useAddSalesBudgetForm,
} from "./useAddSalesBudgetForm";

type Props = {
  shopId: string;
  loading: boolean;
  onSubmit: (budget: MonthlySalesBudgetInsertInput) => void;
  onSubmitAllocationSetting: (allocationSetting: SalesBudgetAllocationSettingInsertInput) => void;
  onClose: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  allocationSetting?: AllocationSetting;
};

export const AddSalesBudgetForm = memo<Props>(
  ({
    shopId,
    loading,
    onSubmit,
    onSubmitAllocationSetting,
    onClose,
    onFormValidationError,
    allocationSetting,
  }) => {
    const {
      form,
      submit,
      submitAllocationSetting,
      resetAllocationSettingToFormInitialValue,
      resetAllocationSettingToDefaultValue,
      initialValues,
    } = useAddSalesBudgetForm({
      shopId,
      onSubmit,
      onSubmitAllocationSetting,
      onFormValidationError,
      allocationSetting,
    });

    const [getMonthlySalesBudgetQuery, { data: monthlySalesBudgetData }] =
      useAddSalesBudgetGetMonthlySalesBudgetLazyQuery();
    const [getSalesForBudgetQuery, { data: salesForBudgetData }] =
      useAddSalesBudgetGetSalesForBudgetLazyQuery();

    const handleChangeTargetMonth = useCallback(
      async (value: AddSalesBudgetFormValues["targetMonth"] | null) => {
        const targetMonth = value ? value.startOf("month") : undefined;
        form.setFieldsValue({ targetMonth });
        if (!targetMonth) return;

        await Promise.all([
          getMonthlySalesBudgetQuery({
            variables: { shopId, targetMonth: targetMonth.format("YYYY-MM-DD") },
          }),
          getSalesForBudgetQuery({
            variables: {
              input: {
                shopId,
                averageAndMaxSalesByDayOfWeekInput: {
                  targetMonth: targetMonth.subtract(1, "month").startOf("month").toISOString(),
                },
                salesByDaysInput: null,
                salesByMonthsInput: {
                  from: targetMonth.subtract(1, "year").startOf("month").toISOString(),
                  to: targetMonth.endOf("month").toISOString(),
                },
              },
            },
          }),
        ]);
      },
      [form, getMonthlySalesBudgetQuery, getSalesForBudgetQuery, shopId],
    );
    const hasExistingMonthlySalesBudget = Boolean(
      monthlySalesBudgetData?.monthlySalesBudget[0]?.id,
    );
    const salesForBudget = salesForBudgetData?.salesForBudget;
    const averageAndMaxSalesByDayOfWeek = salesForBudget?.averageAndMaxSalesByDayOfWeek ?? [];
    const salesByMonths = salesForBudget?.salesByMonths ?? undefined;

    const [allocationSettingModalVisible, setAllocationSettingModalVisible] = useState(false);
    const openAllocationSettingModal = useCallback(
      () => setAllocationSettingModalVisible(true),
      [],
    );
    const onCancelAllocationSetting = useCallback(() => {
      resetAllocationSettingToFormInitialValue();
      setAllocationSettingModalVisible(false);
    }, [resetAllocationSettingToFormInitialValue]);

    const allocateDailySalesBudget = useCallback(() => {
      const targetMonth = form.getFieldValue("targetMonth") as
        | AddSalesBudgetFormValues["targetMonth"];
      const allocationSetting = form.getFieldValue("allocationSetting") as
        | AddSalesBudgetFormValues["allocationSetting"];
      const monthlyBudget = form.getFieldValue("monthlyBudget") as
        | AddSalesBudgetFormValues["monthlyBudget"];
      if (!allocationSetting || !targetMonth || !monthlyBudget) return;

      const dayOff = form.getFieldValue("dayOff") as AddSalesBudgetFormValues["dayOff"];
      const dayOffDates = Object.entries(dayOff ?? {})
        .map(([date, dayOff]) => (dayOff ? date : undefined))
        .filter(isNotUndefined);

      const allocatedDailySalesBudget = getAllocatedDailySalesBudget({
        targetMonth,
        monthlyBudget,
        dayOffDates,
        allocationSetting,
      });
      form.setFieldsValue({
        dailyBudget: allocatedDailySalesBudget,
      });
    }, [form]);

    const onConfirmAllocationSetting = useCallback(async () => {
      await submitAllocationSetting();
      allocateDailySalesBudget();
      setAllocationSettingModalVisible(false);
    }, [submitAllocationSetting, allocateDailySalesBudget]);

    return (
      <Form name="addSalesBudget" form={form} layout="vertical" initialValues={initialValues}>
        <TargetMonthField onChange={(value) => handleChangeTargetMonth(value)} />
        <AddSalesBudgetFormItem.NonProperty
          noStyle
          shouldUpdate={withFormDependencies(({ targetMonth }) => [targetMonth])}
        >
          {({ getFieldsValue }) => {
            const { targetMonth } = getFieldsValue();

            if (!targetMonth) return null;
            return !hasExistingMonthlySalesBudget ? (
              <>
                <Spacer size={32} />
                <MonthlyBudgetField targetMonth={targetMonth} salesByMonths={salesByMonths} />
                <Spacer size={32} />
                <DailyBudgetField
                  targetMonth={targetMonth}
                  averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
                  onOpenAllocationSettingModal={openAllocationSettingModal}
                  allocateDailySalesBudget={allocateDailySalesBudget}
                />
                <AllocationSettingModal
                  averageAndMaxSalesByDayOfWeek={averageAndMaxSalesByDayOfWeek}
                  open={allocationSettingModalVisible}
                  onOk={onConfirmAllocationSetting}
                  onCancel={onCancelAllocationSetting}
                  resetAllocationSettingToDefaultValue={resetAllocationSettingToDefaultValue}
                />
              </>
            ) : (
              <>
                <Spacer size={32} />
                <Alert message="既に作成された目標設定があります" type="error" />
              </>
            );
          }}
        </AddSalesBudgetFormItem.NonProperty>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button
            type="primary"
            onClick={submit}
            loading={loading}
            disabled={hasExistingMonthlySalesBudget}
          >
            作成
          </Button>
        </FormActions>
      </Form>
    );
  },
);
