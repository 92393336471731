import React, { memo } from "react";
import styled from "styled-components";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

import { Loading } from "components/Loading";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { QuestionnaireScoreChangesDimensionType } from "types/graphql";

import { GraphData, QuestionnaireScoreChangesItem } from "../types";

import { XAxisCustomizedTick } from "./XAxisCustomizedTick";

type Props = {
  title: string;
  data: GraphData;
  loading: boolean;
  lines: { dataKey: QuestionnaireScoreChangesItem; stroke: string; type: "score" | "count" }[];
  dimension: QuestionnaireScoreChangesDimensionType;
  displayItems: QuestionnaireScoreChangesItem[];
  width: number;
  height: number;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
`;

export const questionnaireScoreChangesItemMap: Record<QuestionnaireScoreChangesItem, string> = {
  shopAverageScore: "スコア",
  corporationAverageScore: "社内平均",
};

export const LineGraph = memo<Props>(
  ({ title, data, loading, lines, dimension, displayItems, width, height }) => (
    <Wrapper>
      <Title>{title}</Title>
      <Spacer height={24} />
      {loading ? (
        <Loading width={width} height={height} />
      ) : (
        <LineChart width={width} height={height} data={data}>
          <CartesianGrid
            vertical={false}
            stroke={`${colors.Border.Default}`}
            strokeDasharray="5 5"
          />
          <XAxis
            dataKey="dimension"
            axisLine={false}
            tick={
              <XAxisCustomizedTick
                style={{ fontSize: 10, fontWeight: 700, color: `${colors.Border.Default}` }}
                dimension={dimension}
              />
            }
            tickLine={{ stroke: `${colors.Border.Default}` }}
            tickMargin={12}
            height={60}
          />
          <YAxis
            yAxisId="score"
            width={40}
            orientation="left"
            allowDecimals={false}
            domain={[0, 100]}
            tick={{ fontSize: 10, fontWeight: 700, color: `${colors.Border.Default}` }}
            tickLine={{ stroke: `${colors.Border.Default}` }}
            tickMargin={8}
            tickFormatter={(value) => (value === 0 ? `${value}点` : value)}
          />
          <YAxis
            yAxisId="count"
            width={40}
            orientation="right"
            allowDecimals={false}
            tick={{ fontSize: 10, fontWeight: 700, color: `${colors.Border.Default}` }}
            tickLine={{ stroke: `${colors.Border.Default}` }}
            tickMargin={8}
            tickFormatter={(value) => (value === 0 ? `${value}人` : value)}
          />
          <Tooltip
            formatter={(value: number, name: QuestionnaireScoreChangesItem) => [
              value,
              questionnaireScoreChangesItemMap[name],
            ]}
          />

          {lines.map(
            ({ dataKey, stroke, type }) =>
              displayItems.includes(dataKey) && (
                <Line
                  key={dataKey}
                  yAxisId={type}
                  dataKey={dataKey}
                  unit={type === "score" ? "点" : "人"}
                  stroke={stroke}
                  strokeWidth={2}
                  dot={false}
                  isAnimationActive={false}
                  connectNulls
                />
              ),
          )}
        </LineChart>
      )}
    </Wrapper>
  ),
);
