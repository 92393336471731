import useMedia from "react-use/lib/useMedia";

import { viewport } from "constants/viewport";

export const useViewport = () => {
  const isSmartphone = useMedia(viewport.smartphone);
  const isTablet = useMedia(viewport.tablet);
  const isDesktop = useMedia(viewport.desktop);

  return { isSmartphone, isTablet, isDesktop };
};
