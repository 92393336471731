import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Button, Col, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { PageHeader } from "components/antd/PageHeader";
import { FormSection } from "components/Form/FormSection";
import { ImageField } from "components/Form/ImageField";
import { useQueryParams } from "hooks/useQuery";
import { PlanTranslationOptions } from "pages/AddPlan/AddPlanForm/PlanTranslationOptions";
import { AddPlanFormValues, useAddPlanForm } from "pages/AddPlan/AddPlanForm/useAddPlanForm";
import { Category, OrderableTime, Plan, PlanGroup } from "pages/AddPlan/types";
import { CreatePlanInputPlanSource, CreatePlanInputTranslationSource } from "types/graphql";

import { CategoryField } from "./CategoryField";
import { DescriptionField } from "./DescriptionField";
import { DisplayTypeField } from "./DisplayTypeField";
import { FeaturedLabelTextField } from "./FeaturedLabelTextField";
import { NameField } from "./NameField";
import { OrderableTimeField } from "./OrderableTimeField";
import { PlanEnableEndNotificationField } from "./PlanEnableEndNotificationField";
import { PlanEndNotificationThresholdField } from "./PlanEndNotificationThresholdField";
import { PlanGroupField } from "./PlanGroupField";
import { PlanTimeField } from "./PlanTimeField";
import { PlanTimeLimitField } from "./PlanTimeLimitField";
import { ReceiptDisplayNameField } from "./ReceiptDisplayNameField";
import { SelectCopyPlanModal } from "./SelectCopyPlanModal";
import { ShopSideNameField } from "./ShopSideNameField";
import { TaxMethodField } from "./TaxMethodField";
import { TaxRateField } from "./TaxRateField";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

type Props = {
  categories: Category[];
  plans: Plan[];
  orderableTimes: OrderableTime[];
  planGroups: PlanGroup[];
  onSubmit: ({
    plan,
    sourcePlanId,
    createTranslationsSource,
  }: {
    plan: CreatePlanInputPlanSource;
    sourcePlanId?: number;
    createTranslationsSource: CreatePlanInputTranslationSource;
  }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddPlanForm = memo<Props>(
  ({
    categories,
    plans,
    orderableTimes,
    planGroups,
    onClose,
    onSubmit,
    onFormValidationError,
    loading,
  }) => {
    const { form, initialValues, submit, copyFromAnotherPlan } = useAddPlanForm(onSubmit);
    const navigate = useNavigate();

    const [hasPlanTimeLimit, setHasPlanTimeLimit] = useState(false);
    const [hasNonZeroPlanTimeLimit, setHasNonZeroPlanTimeLimit] = useState(false);
    const [hasEndNotificationTime, setHasEndNotificationTime] = useState(false);
    const [isDisabledTaxRateField, setIsDisabledTaxRateField] = useState(false);
    const [uploadImage, setUploadImage] = useState("");

    const [selectCopyPlanModalVisible, setSelectCopyPlanModalVisible] = useState(false);
    const openSelectCopyPlanModal = useCallback(() => setSelectCopyPlanModalVisible(true), []);
    const closeSelectCopyPlanModal = useCallback(() => setSelectCopyPlanModalVisible(false), []);

    const query = useQueryParams();
    const sourcePlanId = query.get("planId");

    useEffect(() => {
      if (!sourcePlanId) return;

      const plan = plans.find((plan) => plan.planId === Number(sourcePlanId));

      if (plan) {
        setUploadImage(plan.imageUrl ?? "");
        copyFromAnotherPlan(plan);
        setHasPlanTimeLimit(plan.planTime !== null);
        setHasEndNotificationTime(plan.endNotificationThresholdMinutes !== null);
      }
    }, [sourcePlanId, setHasPlanTimeLimit, plans, copyFromAnotherPlan]);

    const handleSetHasPlanTimeLimitChange = useCallback(
      (newHasPlanTimeLimit: boolean) => {
        if (!newHasPlanTimeLimit) {
          // NOTE: there can't be an end notification without a plan end time
          setHasEndNotificationTime(false);
        }

        setHasPlanTimeLimit(newHasPlanTimeLimit);
      },
      [setHasPlanTimeLimit, setHasEndNotificationTime],
    );

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({
          imageUrl: uploadImage,
          hasPlanTimeLimit,
          hasEndNotificationTime,
          sourcePlanId: sourcePlanId ? Number(sourcePlanId) : undefined,
        });
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [
      form,
      submit,
      onFormValidationError,
      uploadImage,
      hasPlanTimeLimit,
      hasEndNotificationTime,
      sourcePlanId,
    ]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleOnValuesChange = useCallback((changedValues: Store) => {
      const { taxMethod, planTime } = changedValues as Partial<AddPlanFormValues>;

      if (taxMethod) {
        setIsDisabledTaxRateField(taxMethod === "nonTaxable");
      }
      if (planTime) {
        setHasNonZeroPlanTimeLimit(true);
      }
      if (planTime === null) {
        setHasNonZeroPlanTimeLimit(false);
        setHasEndNotificationTime(false);
      }
    }, []);

    const goBack = useCallback(() => navigate(-1), [navigate]);

    return (
      <Wrapper>
        <PageHeader
          title="プランを新規作成"
          onBack={goBack}
          extra={<Button onClick={openSelectCopyPlanModal}>プラン情報をコピー</Button>}
        />
        <StyledForm
          name="menu"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={handleOnValuesChange}
        >
          <FormSection title="プラン画像">
            <ImageField
              image={uploadImage}
              setUploadImage={setUploadImage}
              uploadImageApiKey="plan"
              formName="AddPlanForm"
              isMovieAccepted
            />
          </FormSection>

          <FormSection title="プラン情報">
            <Row gutter={24}>
              <Col span={12}>
                <NameField />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <ReceiptDisplayNameField />
              </Col>
              <Col span={12}>
                <ShopSideNameField />
              </Col>
            </Row>

            <CategoryField data-cy="plan_category_select" categories={categories} />

            <OrderableTimeField
              data-cy="plan_orderableTime_select"
              orderableTimes={orderableTimes}
            />

            <PlanGroupField planGroups={planGroups} />

            <DescriptionField />

            <Row gutter={24}>
              <Col span={12}>
                <PlanTimeLimitField
                  hasPlanTimeLimit={hasPlanTimeLimit}
                  setHasPlanTimeLimit={handleSetHasPlanTimeLimitChange}
                />
              </Col>
              <Col span={12}>
                <PlanTimeField hasPlanTimeLimit={hasPlanTimeLimit} wrapperCol={{ span: 8 }} />
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <PlanEnableEndNotificationField
                  hasNonZeroPlanTimeLimit={hasNonZeroPlanTimeLimit}
                  hasPlanTimeLimit={hasPlanTimeLimit}
                  hasEndNotificationTime={hasEndNotificationTime}
                  setHasEndNotificationTime={setHasEndNotificationTime}
                />
              </Col>
              <Col span={12}>
                <PlanEndNotificationThresholdField
                  form={form}
                  hasEndNotificationTime={hasEndNotificationTime}
                  wrapperCol={{ span: 8 }}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <FeaturedLabelTextField />
              </Col>
            </Row>

            <DisplayTypeField />

            <Row gutter={24}>
              <Col span={12}>
                <TaxMethodField wrapperCol={{ span: 8 }} />
              </Col>
              <Col span={12}>
                <TaxRateField disabled={isDisabledTaxRateField} wrapperCol={{ span: 8 }} />
              </Col>
            </Row>
          </FormSection>

          <PlanTranslationOptions />

          <ButtonWrapper>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              登録
            </Button>
          </ButtonWrapper>

          <SelectCopyPlanModal
            visible={selectCopyPlanModalVisible}
            plans={plans}
            onDismiss={closeSelectCopyPlanModal}
            onSubmit={(plan: Plan) => {
              setUploadImage(plan.imageUrl ?? "");
              copyFromAnotherPlan(plan);
              setHasPlanTimeLimit(plan.planTime !== null);
              navigate(`/plan/add?planId=${plan.planId}`, { replace: true });
            }}
          />
        </StyledForm>
      </Wrapper>
    );
  },
);
