import React, { useCallback } from "react";
import { Alert } from "antd";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { EditOnboardingHandoutForm } from "pages/OnboardingHandout/EditOnboardingHandoutForm";
import { EditOnboardingHandoutFormValues } from "pages/OnboardingHandout/EditOnboardingHandoutForm/useEditOnboardingHandoutForm";
import {
  useOnboardingHandoutGetShopsQuery,
  useOnboardingHandoutUpdateOnboardingHandoutMutation,
} from "pages/OnboardingHandout/queries";

export const OnboardingHandout = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const { data: getShopsData, error: getShopsDataError } = useOnboardingHandoutGetShopsQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const shops = getShopsData?.shop ?? [];

  const [updateOnboardingHandoutMutation, { loading }] =
    useOnboardingHandoutUpdateOnboardingHandoutMutation();

  const onSubmit = useCallback(
    async (formValues: EditOnboardingHandoutFormValues) => {
      const { shopId, onboardingHandout } = formValues;
      if (companyId && shopId) {
        try {
          await updateOnboardingHandoutMutation({ variables: { shopId, onboardingHandout } });

          message.success("更新しました");
        } catch (err) {
          message.error("更新に失敗しました");
        }
      }
    },
    [companyId, updateOnboardingHandoutMutation],
  );

  const shouldShowAlert = getShopsDataError;

  return (
    <DashboardLayout title="配布物">
      <PageHeader title="配布物" />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <EditOnboardingHandoutForm shops={shops} onSubmit={onSubmit} loading={loading} />
    </DashboardLayout>
  );
};
