import React, { memo, useMemo } from "react";
import { Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useCanAccess } from "hooks/useCanAccess";
import { usePagination } from "hooks/usePagination";

import { Menu } from "../types";

type Props = {
  loading?: boolean;
  menus: Menu[];
};

const getTecAggregationMenuFromMenu = (menu: Menu) => menu.tecAggregationMenu ?? null;

export const MenuTable = memo<Props>(({ loading, menus }) => {
  const [pagination, setPagination] = usePagination();

  const { canAccess } = useCanAccess();

  const columns: ColumnsType<Menu> = useMemo(
    () => [
      {
        title: "商品名",
        dataIndex: "name",
        fixed: "left",
        width: 200,
      },
      {
        title: "オプション",
        width: 200,
        render(_: string, { menuOptions }: Menu) {
          return menuOptions.map(({ option }) => <Tag key={option.optionId}>{option.name}</Tag>);
        },
      },
      {
        title: "メニューコード",
        align: "left",
        width: 130,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.menuCode;
        },
      },
      {
        title: "メニュー名",
        align: "left",
        width: 150,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.name;
        },
      },
      {
        title: "部門コード",
        align: "left",
        width: 100,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.dpCode;
        },
      },
      {
        title: "部門名",
        align: "left",
        width: 150,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.dpName;
        },
      },
      {
        title: "分類コード",
        align: "left",
        width: 100,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.gpCode;
        },
      },
      {
        title: "分類名",
        align: "left",
        width: 150,
        render(_: string, menu: Menu) {
          const tecAggregationMenu = getTecAggregationMenuFromMenu(menu);
          return tecAggregationMenu?.gpName;
        },
      },
      ...(canAccess("editTecAggregationMenu")
        ? [
            {
              title: "",
              width: 60,
              align: "center",
              fixed: "right",
              render(_: string, { menuId }: Menu) {
                return (
                  <Tooltip title="メニューを編集">
                    <IconLink to={`/tec/menu/${menuId}/edit`}>
                      <EditIcon />
                    </IconLink>
                  </Tooltip>
                );
              },
            } as const,
          ]
        : []),
    ],
    [canAccess],
  );

  return (
    <Table
      rowKey="categoryMenuId"
      columns={columns}
      dataSource={menus}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
