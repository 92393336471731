import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Col, Row } from "antd";
import { filterPlansByName } from "models/plan";
import * as stock from "models/stock";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { useShop } from "hooks/useShop";
import { FilterConditions, ShopPlanFilter } from "pages/ShopPlans/ShopPlanFilter";
import { Plan } from "pages/ShopPlans/types";

import {
  useShopPlansGetDishUpSlipGroupsQuery,
  useShopPlansGetPlansQuery,
  useShopPlansGetRolesQuery,
  useShopPlansUpdateShopPlanStockMutation,
  useShopPlansUpdateShopPlanVisibleForCustomerMutation,
  useShopPlansUpdateShopPlanVisibleForStaffMutation,
  useShopPlansUpsertShopPlanChoiceMutation,
} from "./queries";
import { ShopPlanTable } from "./ShopPlanTable";

const StyledButton = styled(Button)`
  color: #4096ff;
  border-color: #4096ff;
`;

const isIncludedInRoleIds = (plan: Plan, roleIds: number[]) => {
  const planRoleIds = plan.shopPlanKitchenRoles.map(({ role }) => role?.roleId);
  return roleIds.some((categoryId) => planRoleIds.includes(categoryId));
};

const isIncludedInDishUpSlipGroupIds = (plan: Plan, dishUpSlipGroupIds: number[]) => {
  const planDishUpSlipGroupIds = plan.dishUpSlipGroupShopPlans.map(
    ({ dishUpSlipGroup }) => dishUpSlipGroup?.id,
  );
  return dishUpSlipGroupIds.some((categoryId) => planDishUpSlipGroupIds.includes(categoryId));
};

const filterPlans = (
  plans: Plan[],
  {
    name,
    isVisibleForCustomer,
    isVisibleForStaff,
    isSoldOut,
    ...filterConditions
  }: FilterConditions,
) => {
  const roleIds = filterConditions.roleIds !== "empty" ? filterConditions.roleIds : undefined;
  const dishUpSlipGroupIds =
    filterConditions.dishUpSlipGroupIds !== "empty"
      ? filterConditions.dishUpSlipGroupIds
      : undefined;

  const filteredPlans = plans.filter(
    (plan) =>
      (isVisibleForCustomer === undefined || plan.isVisibleForCustomer === isVisibleForCustomer) &&
      (isVisibleForStaff === undefined || plan.isVisibleForStaff === isVisibleForStaff) &&
      (isSoldOut === undefined || stock.isSoldOut({ stock: plan.stock }) === isSoldOut) &&
      (roleIds === undefined || isIncludedInRoleIds(plan, roleIds)) &&
      (filterConditions.roleIds !== "empty" || plan.shopPlanKitchenRoles.length === 0) &&
      (dishUpSlipGroupIds === undefined ||
        isIncludedInDishUpSlipGroupIds(plan, dishUpSlipGroupIds)) &&
      (filterConditions.dishUpSlipGroupIds !== "empty" ||
        plan.dishUpSlipGroupShopPlans.length === 0),
  );

  return name ? filterPlansByName(filteredPlans, name) : filteredPlans;
};

export const ShopPlans = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [shop] = useShop();
  const shopId = shop?.shopId;

  const {
    data: getPlansData,
    loading: loadingPlans,
    error: getPlansError,
    refetch: refetchPlans,
  } = useShopPlansGetPlansQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shopPlans = useMemo(() => getPlansData?.shop_by_pk?.shopPlans ?? [], [getPlansData]);
  const plans = shopPlans.map(({ plan, ...shopPlan }) => ({
    ...plan,
    ...shopPlan,
    planId: plan.planId,
  }));

  const { data: getRolesData, error: getRolesError } = useShopPlansGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const { data: getDishUpSlipGroupsData, error: getDishUpSlipGroupsError } =
    useShopPlansGetDishUpSlipGroupsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const dishUpSlipGroups = getDishUpSlipGroupsData?.shop_by_pk?.dishUpSlipGroups ?? [];

  const [updateShopPlanVisibleForCustomerMutation] =
    useShopPlansUpdateShopPlanVisibleForCustomerMutation();
  const [updateShopPlanVisibleForStaffMutation] =
    useShopPlansUpdateShopPlanVisibleForStaffMutation();
  const [updateShopPlanStockMutation] = useShopPlansUpdateShopPlanStockMutation();
  const [upsertShopPlanChoiceMutation] = useShopPlansUpsertShopPlanChoiceMutation();

  const updateIsVisibleForCustomer = useCallback(
    async ({ planId, isVisibleForCustomer }: { planId: string; isVisibleForCustomer: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopPlanVisibleForCustomerMutation({
          variables: { planId, shopId, isVisibleForCustomer },
        });
        await refetchPlans();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [refetchPlans, shopId, updateShopPlanVisibleForCustomerMutation],
  );

  const updateIsVisibleForStaff = useCallback(
    async ({ planId, isVisibleForStaff }: { planId: string; isVisibleForStaff: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopPlanVisibleForStaffMutation({
          variables: { planId, shopId, isVisibleForStaff },
        });
        await refetchPlans();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [refetchPlans, shopId, updateShopPlanVisibleForStaffMutation],
  );

  const updateIsSoldOut = useCallback(
    async ({ planId, isSoldOut }: { planId: string; isSoldOut: boolean }) => {
      if (!shopId) return;
      const shopPlan = shopPlans.find(
        (shopPlan) => shopPlan.shopId === shopId && shopPlan.planId === planId,
      );
      const stockId = shopPlan?.stock?.id ?? uuidv4();
      const stock = { id: stockId, shopId, currentStockNum: isSoldOut ? null : 0 };
      try {
        await updateShopPlanStockMutation({
          variables: {
            planId,
            shopId,
            stockId,
            stock,
          },
        });
        await refetchPlans();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [refetchPlans, shopId, shopPlans, updateShopPlanStockMutation],
  );

  const upsertShopPlanChoice = useCallback(
    async ({
      planChoiceId,
      isVisibleForCustomer,
      isVisibleForStaff,
      isSoldOut,
    }: {
      planChoiceId: string;
      isVisibleForCustomer?: boolean;
      isVisibleForStaff?: boolean;
      isSoldOut?: boolean;
    }) => {
      if (!shopId) return;
      try {
        await upsertShopPlanChoiceMutation({
          variables: {
            input: {
              shopId,
              planChoiceId,
              isVisibleForCustomer,
              isVisibleForStaff,
              isSoldOut,
            },
          },
        });
        await refetchPlans();
        message.success("編集を保存しました");
      } catch {
        message.error("編集の保存に失敗しました");
      }
    },
    [refetchPlans, shopId, upsertShopPlanChoiceMutation],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredPlans = useMemo(
    () => filterPlans(plans, filterConditions),
    [plans, filterConditions],
  );

  const shouldShowError = getPlansError || getRolesError || getDishUpSlipGroupsError;

  return (
    <DashboardLayout title="取扱プラン一覧">
      <PageHeader
        title="取扱プラン一覧"
        extra={[
          isFeatureEnabled("addPlan") && (
            <Link key="add" to="/plan/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={
          <>
            <Row gutter={16}>
              <Col>
                <ShopSelector />
              </Col>
              {isFeatureEnabled("editShopPlansBulk") && (
                <Col>
                  <Link to="/shopPlan/edit/bulk">
                    <StyledButton>複数店舗の取扱一括設定</StyledButton>
                  </Link>
                </Col>
              )}
            </Row>
            <ShopPlanFilter
              roles={roles}
              dishUpSlipGroups={dishUpSlipGroups}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
            />
          </>
        }
      />
      {shouldShowError && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ShopPlanTable
        shopId={shopId}
        plans={filteredPlans}
        loading={loadingPlans}
        onUpdateIsVisibleForCustomer={updateIsVisibleForCustomer}
        onUpdateIsVisibleForStaff={updateIsVisibleForStaff}
        onUpdateIsSoldOut={updateIsSoldOut}
        onUpsertShopPlanChoice={upsertShopPlanChoice}
      />
    </DashboardLayout>
  );
};
