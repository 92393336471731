import { auth } from "libs/firebase";
import { MutationRootResolvers } from "store/types";

export const signOut: MutationRootResolvers["signOut"] = async () => {
  await auth.signOut();

  // useAuthEffect により自動的に /signIn に移動する

  return true;
};
