import React, { memo } from "react";
import { HomeOutlined } from "@ant-design/icons";

import { EbicaMenu } from "components/Layout/DashboardLayout/NavigationDrawer/HeadquartersMenu/EbicaMenu";
import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { DashboardFeatureEnum } from "types/graphql";

import { AdyenTerminalPaymentMenu } from "./AdyenTerminalPaymentMenu";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const HeadquartersMenu = memo<Props>(({ ...props }) => (
  <SubMenu
    key="headquarters"
    icon={<HomeOutlined />}
    title="本部機能"
    features={[
      DashboardFeatureEnum.DailyReport,
      DashboardFeatureEnum.AccountingHistory,
      DashboardFeatureEnum.TableClearHistory,
      DashboardFeatureEnum.PaymentMethodSettings,
      DashboardFeatureEnum.SalesBudget,
      DashboardFeatureEnum.TerminalPayment,
    ]}
    {...props}
  >
    <MenuItem route="salesBudget" to="/salesBudget" text="目標管理" />
    <MenuItem
      route="dailyCashRegisterBalancing"
      to="/dailyCashRegisterBalancing"
      text="日次処理一覧"
    />
    <MenuItem route="accountingHistory" to="/accounting/history" text="会計履歴一覧" />
    <AdyenTerminalPaymentMenu company={props.company} />
    <MenuItem route="clearTableHistory" to="/clearTableHistory" text="テーブルクリア履歴一覧" />
    <EbicaMenu company={props.company} />
    <MenuItem
      route="lineReportingBotConfigs"
      to="/lineReportingBotConfig/active"
      text="ダイニー AI くん設定"
    />
  </SubMenu>
));
