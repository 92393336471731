import React, { memo } from "react";
import { Button } from "antd";

import { EditPlanTecAggregationPlanFormItem } from "../useEditPlanTecAggregationPlanForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const TecAggregationGetAvailableCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed: handleAutoAdoptMenuCodeButtonPressed }) => (
    <EditPlanTecAggregationPlanFormItem label=" " name="TecAggregationGetAvailableCodeButton">
      <Button onClick={handleAutoAdoptMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditPlanTecAggregationPlanFormItem>
  ),
);
