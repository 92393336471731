import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MenuOrderItemAnalyticsGetEntities = gql`
    query MenuOrderItemAnalyticsGetEntities($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    id
    name
  }
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    shopId
    businessOperationHourType
    start
    end
  }
}
    `;
export const GetBusinessOperationHourTypesByShopIds = gql`
    query GetBusinessOperationHourTypesByShopIds($shopIds: [uuid!]) {
  shopBusinessOperationHour(where: {shopId: {_in: $shopIds}}) {
    businessOperationHourType
  }
}
    `;
export type MenuOrderItemAnalyticsGetEntitiesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MenuOrderItemAnalyticsGetEntitiesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'id' | 'name'>
  )>, shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )>, shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'id' | 'shopId' | 'businessOperationHourType' | 'start' | 'end'>
  )> }
);

export type GetBusinessOperationHourTypesByShopIdsQueryVariables = Types.Exact<{
  shopIds?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type GetBusinessOperationHourTypesByShopIdsQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'businessOperationHourType'>
  )> }
);


export const MenuOrderItemAnalyticsGetEntitiesDocument = gql`
    query MenuOrderItemAnalyticsGetEntities($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    id
    name
  }
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    shopId
    businessOperationHourType
    start
    end
  }
}
    `;

/**
 * __useMenuOrderItemAnalyticsGetEntitiesQuery__
 *
 * To run a query within a React component, call `useMenuOrderItemAnalyticsGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOrderItemAnalyticsGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOrderItemAnalyticsGetEntitiesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMenuOrderItemAnalyticsGetEntitiesQuery(baseOptions: Apollo.QueryHookOptions<MenuOrderItemAnalyticsGetEntitiesQuery, MenuOrderItemAnalyticsGetEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuOrderItemAnalyticsGetEntitiesQuery, MenuOrderItemAnalyticsGetEntitiesQueryVariables>(MenuOrderItemAnalyticsGetEntitiesDocument, options);
      }
export function useMenuOrderItemAnalyticsGetEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuOrderItemAnalyticsGetEntitiesQuery, MenuOrderItemAnalyticsGetEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuOrderItemAnalyticsGetEntitiesQuery, MenuOrderItemAnalyticsGetEntitiesQueryVariables>(MenuOrderItemAnalyticsGetEntitiesDocument, options);
        }
export type MenuOrderItemAnalyticsGetEntitiesQueryHookResult = ReturnType<typeof useMenuOrderItemAnalyticsGetEntitiesQuery>;
export type MenuOrderItemAnalyticsGetEntitiesLazyQueryHookResult = ReturnType<typeof useMenuOrderItemAnalyticsGetEntitiesLazyQuery>;
export type MenuOrderItemAnalyticsGetEntitiesQueryResult = Apollo.QueryResult<MenuOrderItemAnalyticsGetEntitiesQuery, MenuOrderItemAnalyticsGetEntitiesQueryVariables>;
export const GetBusinessOperationHourTypesByShopIdsDocument = gql`
    query GetBusinessOperationHourTypesByShopIds($shopIds: [uuid!]) {
  shopBusinessOperationHour(where: {shopId: {_in: $shopIds}}) {
    businessOperationHourType
  }
}
    `;

/**
 * __useGetBusinessOperationHourTypesByShopIdsQuery__
 *
 * To run a query within a React component, call `useGetBusinessOperationHourTypesByShopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessOperationHourTypesByShopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessOperationHourTypesByShopIdsQuery({
 *   variables: {
 *      shopIds: // value for 'shopIds'
 *   },
 * });
 */
export function useGetBusinessOperationHourTypesByShopIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetBusinessOperationHourTypesByShopIdsQuery, GetBusinessOperationHourTypesByShopIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessOperationHourTypesByShopIdsQuery, GetBusinessOperationHourTypesByShopIdsQueryVariables>(GetBusinessOperationHourTypesByShopIdsDocument, options);
      }
export function useGetBusinessOperationHourTypesByShopIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessOperationHourTypesByShopIdsQuery, GetBusinessOperationHourTypesByShopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessOperationHourTypesByShopIdsQuery, GetBusinessOperationHourTypesByShopIdsQueryVariables>(GetBusinessOperationHourTypesByShopIdsDocument, options);
        }
export type GetBusinessOperationHourTypesByShopIdsQueryHookResult = ReturnType<typeof useGetBusinessOperationHourTypesByShopIdsQuery>;
export type GetBusinessOperationHourTypesByShopIdsLazyQueryHookResult = ReturnType<typeof useGetBusinessOperationHourTypesByShopIdsLazyQuery>;
export type GetBusinessOperationHourTypesByShopIdsQueryResult = Apollo.QueryResult<GetBusinessOperationHourTypesByShopIdsQuery, GetBusinessOperationHourTypesByShopIdsQueryVariables>;