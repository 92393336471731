import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Checkbox, Input } from "antd";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";

import { LineReportingBotConfig, Shop } from "../types";

import {
  EditLineReportingBotConfigFormItem,
  useEditLineReportingBotConfigForm,
} from "./useEditLineReportingBotConfigForm";

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckBox = styled(Checkbox)`
  && {
    margin-left: 0;
  }
`;

type Props = {
  shops: Shop[];
  lineReportingBotConfig: LineReportingBotConfig;
  onClickBackButton: () => void;
  onSubmit: ({
    name,
    shopIds,
    isSuspended,
  }: {
    name: string;
    shopIds: string[];
    isSuspended: boolean;
  }) => void;
  onFormValidationError: () => void;
  disabled: boolean;
};

export const EditLineReportingBotConfigForm = memo<Props>(
  ({
    shops,
    lineReportingBotConfig,
    onClickBackButton,
    onSubmit,
    onFormValidationError,
    disabled,
  }) => {
    const lineReportingBotConfigShopIds = useMemo(
      () => lineReportingBotConfig.lineReportingBotConfigShops.map(({ shopId }) => shopId) ?? [],
      [lineReportingBotConfig],
    );

    const { form, submit, initialValues, submitAsSuspended, submitAsUnsuspend } =
      useEditLineReportingBotConfigForm({
        onSubmit,
        currentEditLineReportingBotConfigName: lineReportingBotConfig.name,
        currentSelectedShopIds: lineReportingBotConfigShopIds,
        currentIsSuspended: lineReportingBotConfig.isSuspended,
      });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();

        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [form, onFormValidationError, submit]);

    const handleSubmitAsSuspended = useCallback(async () => {
      try {
        await form.validateFields();

        await submitAsSuspended();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [form, onFormValidationError, submitAsSuspended]);

    const handleSubmitAsUnsuspend = useCallback(async () => {
      try {
        await form.validateFields();

        await submitAsUnsuspend();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError();
      }
    }, [form, onFormValidationError, submitAsUnsuspend]);

    return (
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <FormSection>
          <EditLineReportingBotConfigFormItem
            label="グループ名を登録してください。"
            name="name"
            rules={[{ required: true, message: "グループ名を登録してください。" }]}
            labelSpacer={<Spacer size={16} />}
            endSpacer={null}
          >
            <Input placeholder="ダイニー経営陣グループ" />
          </EditLineReportingBotConfigFormItem>
          <Spacer size={24} />

          <EditLineReportingBotConfigFormItem
            name="shopIds"
            label="配信する店舗を選択してください。"
            rules={[{ required: true, message: "配信する店舗を選択してください。" }]}
            labelSpacer={<Spacer size={16} />}
          >
            <CheckboxGroup>
              {shops.map((shop) => (
                <StyledCheckBox key={shop.shopId} value={shop.shopId}>
                  {shop.name}
                </StyledCheckBox>
              ))}
            </CheckboxGroup>
          </EditLineReportingBotConfigFormItem>
        </FormSection>
        <Spacer size={16} />
        <FormActions>
          {lineReportingBotConfig.isSuspended || (
            <Button danger onClick={handleSubmitAsSuspended}>
              配信停止
            </Button>
          )}
          <Button onClick={onClickBackButton}>キャンセル</Button>
          {lineReportingBotConfig.isSuspended ? (
            <Button type="primary" onClick={handleSubmitAsUnsuspend}>
              配信再開
            </Button>
          ) : (
            <Button type="primary" onClick={handleSubmit} disabled={disabled}>
              更新
            </Button>
          )}
        </FormActions>
      </Form>
    );
  },
);
