import React, { memo } from "react";
import { ColumnsType } from "antd/lib/table";
import moment from "moment-timezone";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { Clerk, TableUser } from "../types";

type Props = {
  loading?: boolean;
  clearTableHistories: TableUser[];
};

const createColumns = (): ColumnsType<TableUser> => [
  {
    title: "テーブル番号",
    align: "right",
    width: 120,
    render(_: string, { table }: { table: { name: string } }) {
      return table.name;
    },
  },
  {
    title: "クリアスタッフ",
    align: "center",
    width: 140,
    render(_: string, { tableClearedClerk }: { tableClearedClerk?: Clerk }) {
      return tableClearedClerk?.name;
    },
  },
  {
    title: "日時",
    align: "center",
    width: 160,
    render(_: string, { deactivatedAt }: { deactivatedAt?: string | null }) {
      return deactivatedAt
        ? moment(new Date(deactivatedAt)).utc().tz("Asia/Tokyo").format("YYYY/MM/DD HH:mm")
        : "";
    },
  },
  {
    title: "注文金額",
    align: "right",
    width: 100,
    render(_: string, { taxIncludedPaidAmount }: { taxIncludedPaidAmount: number }) {
      return `${String(taxIncludedPaidAmount)}円`;
    },
  },
  {
    title: "理由",
    align: "left",
    width: 300,
    dataIndex: "tableClearReason",
  },
];

export const ClearTableHistoryTable = memo<Props>(({ loading, clearTableHistories }) => {
  const [pagination, setPagination] = usePagination();

  const columns = createColumns();

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={clearTableHistories}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
