import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MembershipRankConfigsGetRankConfigs = gql`
    query MembershipRankConfigsGetRankConfigs($companyId: uuid!) {
  membershipRankConfig(
    where: {company: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    order_by: {requiredVisitCount: asc}
  ) {
    id
    membershipRankByMembershipRank {
      value
      companyMembers_aggregate(
        where: {company: {archivedAt: {_is_null: true}, id: {_eq: $companyId}}}
      ) {
        aggregate {
          count
        }
      }
    }
    rewardCoupon {
      name
    }
    requiredVisitCount
  }
}
    `;
export type MembershipRankConfigsGetRankConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MembershipRankConfigsGetRankConfigsQuery = (
  { __typename?: 'query_root' }
  & { membershipRankConfig: Array<(
    { __typename?: 'membershipRankConfig' }
    & Pick<Types.MembershipRankConfig, 'id' | 'requiredVisitCount'>
    & { membershipRankByMembershipRank: (
      { __typename?: 'membershipRank' }
      & Pick<Types.MembershipRank, 'value'>
      & { companyMembers_aggregate: (
        { __typename?: 'companyMember_aggregate' }
        & { aggregate?: Types.Maybe<(
          { __typename?: 'companyMember_aggregate_fields' }
          & Pick<Types.CompanyMemberAggregateFields, 'count'>
        )> }
      ) }
    ), rewardCoupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'name'>
    )> }
  )> }
);


export const MembershipRankConfigsGetRankConfigsDocument = gql`
    query MembershipRankConfigsGetRankConfigs($companyId: uuid!) {
  membershipRankConfig(
    where: {company: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}}
    order_by: {requiredVisitCount: asc}
  ) {
    id
    membershipRankByMembershipRank {
      value
      companyMembers_aggregate(
        where: {company: {archivedAt: {_is_null: true}, id: {_eq: $companyId}}}
      ) {
        aggregate {
          count
        }
      }
    }
    rewardCoupon {
      name
    }
    requiredVisitCount
  }
}
    `;

/**
 * __useMembershipRankConfigsGetRankConfigsQuery__
 *
 * To run a query within a React component, call `useMembershipRankConfigsGetRankConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipRankConfigsGetRankConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipRankConfigsGetRankConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMembershipRankConfigsGetRankConfigsQuery(baseOptions: Apollo.QueryHookOptions<MembershipRankConfigsGetRankConfigsQuery, MembershipRankConfigsGetRankConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipRankConfigsGetRankConfigsQuery, MembershipRankConfigsGetRankConfigsQueryVariables>(MembershipRankConfigsGetRankConfigsDocument, options);
      }
export function useMembershipRankConfigsGetRankConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipRankConfigsGetRankConfigsQuery, MembershipRankConfigsGetRankConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipRankConfigsGetRankConfigsQuery, MembershipRankConfigsGetRankConfigsQueryVariables>(MembershipRankConfigsGetRankConfigsDocument, options);
        }
export type MembershipRankConfigsGetRankConfigsQueryHookResult = ReturnType<typeof useMembershipRankConfigsGetRankConfigsQuery>;
export type MembershipRankConfigsGetRankConfigsLazyQueryHookResult = ReturnType<typeof useMembershipRankConfigsGetRankConfigsLazyQuery>;
export type MembershipRankConfigsGetRankConfigsQueryResult = Apollo.QueryResult<MembershipRankConfigsGetRankConfigsQuery, MembershipRankConfigsGetRankConfigsQueryVariables>;