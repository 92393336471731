import React, { memo } from "react";
import { FormItemProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";

import { EditPlanTranslationsFormItem } from "./useEditPlanTranslationsForm";

type Props = Omit<FormItemProps, "children" | "name"> & { disabled: boolean };

export const DescriptionCnField = memo<Props>(({ disabled, ...props }) => (
  <EditPlanTranslationsFormItem label="中国語 (簡体字)" name="descriptionCn" {...props}>
    <TextArea rows={2} disabled={disabled} />
  </EditPlanTranslationsFormItem>
));
