import { useCallback } from "react";
import dayjs from "dayjs";

import { createFormItem, Form } from "components/antd/Form";
import { ConnectGameConfig } from "types/graphql";

export type MaxWinningPercentageType = "select" | "custom";

export type ConsolationType = "losingPage" | "coupon";

export type AddConnectGameConfigFormValues = Pick<
  ConnectGameConfig,
  "enabled" | "startImageUrl" | "prizeCouponId" | "consolationCouponId"
> & {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  shopIds: string[];
  maxWinningPercentageType: MaxWinningPercentageType;
  selectedMaxWinningPercentage?: number;
  enteredMaxWinningPercentage?: number;
  consolationType: ConsolationType;
};

export const AddConnectGameConfigFormItem = createFormItem<AddConnectGameConfigFormValues>();

export const useAddConnectGameConfigForm = ({
  onSubmit,
}: {
  onSubmit: ({ connectGameConfig }: { connectGameConfig: AddConnectGameConfigFormValues }) => void;
}) => {
  const [form] = Form.useForm<AddConnectGameConfigFormValues>();
  const initialValues = {
    enabled: true,
    maxWinningPercentageType: "select",
    consolationType: "losingPage",
  };

  const submit = useCallback(
    ({ startImageUrl }: { startImageUrl: string }) => {
      const formValues = form.getFieldsValue();
      onSubmit({ connectGameConfig: { ...formValues, startImageUrl } });
    },
    [form, onSubmit],
  );

  return { form, initialValues, submit };
};
