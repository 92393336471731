import React, { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { filterShop } from "models/shop";

import { Navigation } from "components/ShopSelector/Navigation";
import { grey } from "constants/colors";

import { ShopSelect } from "../ShopSelect";
import { Shop } from "../types";

type Props = {
  shops: Shop[];
  onSelect: (shopId: string) => void;
};

const Wrapper = styled.div`
  min-width: 768px;
  max-width: 768px;
  border-right: 1px solid ${grey[2]};
  max-height: 600px;
  overflow: scroll;
`;

export const ShopSelectOverlay = memo<Props>(({ shops, onSelect }) => {
  const [name, setName] = useState("");
  const filteredShops = useMemo(() => filterShop(shops, { name }), [name, shops]);

  return (
    <Wrapper>
      <Navigation onChangeName={setName} />
      <ShopSelect shops={filteredShops} onSelect={onSelect} span={12} />
    </Wrapper>
  );
});
