import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { OnSitePaymentDetailTypeCategoryEnum } from "types/graphql";

import { AddOnSitePaymentDetailTypeForm } from "./AddOnSitePaymentDetailTypeForm";
import {
  useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery,
  useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery,
  useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation,
} from "./queries";

export const AddOnSitePaymentDetailType = () => {
  const navigate = useNavigate();

  const [company] = useCompany();
  const companyId = company?.id;

  const { data: onSitePaymentDetailTypeMaxPriorityData, refetch: refetchMaxPriority } =
    useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeMaxPriorityQuery(
      companyId ? { variables: { companyId } } : { skip: true },
    );
  const onSitePaymentDetailTypeMaxPriority =
    onSitePaymentDetailTypeMaxPriorityData?.onSitePaymentDetailType_aggregate.aggregate?.max
      ?.priority ?? 0;

  const { data: getOnSitePaymentDetailTypeCategoriesData } =
    useAddOnSitePaymentDetailTypeGetOnSitePaymentDetailTypeCategoriesQuery();
  const onSitePaymentDetailTypeCategories = useMemo(
    () => getOnSitePaymentDetailTypeCategoriesData?.onSitePaymentDetailTypeCategoryPriority ?? [],
    [getOnSitePaymentDetailTypeCategoriesData?.onSitePaymentDetailTypeCategoryPriority],
  );

  const [addOnSitePaymentDetailType, { loading }] =
    useAddOnSitePaymentDetailTypeInsertOnSitePaymentDetailTypeMutation();

  const handleSubmit = useCallback(
    async ({
      label,
      type,
      allowChange,
      onSitePaymentDetailTypeCategory,
    }: {
      label: string;
      type: string;
      allowChange: boolean;
      onSitePaymentDetailTypeCategory: OnSitePaymentDetailTypeCategoryEnum;
    }) => {
      if (!companyId) return;

      try {
        const priority = onSitePaymentDetailTypeMaxPriority + 1;
        await addOnSitePaymentDetailType({
          variables: {
            onSitePaymentDetailType: {
              companyId,
              label,
              type,
              priority,
              allowChange,
              onSitePaymentDetailTypeCategory,
            },
          },
        });

        await refetchMaxPriority();

        message.success("作成しました");

        navigate("/onSitePaymentDetailType", { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [
      companyId,
      onSitePaymentDetailTypeMaxPriority,
      addOnSitePaymentDetailType,
      navigate,
      refetchMaxPriority,
    ],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <DashboardLayout title="支払方法を新規作成">
      <PageHeader title="支払方法を新規作成" onBack={goBack} />
      <AddOnSitePaymentDetailTypeForm
        onSubmit={handleSubmit}
        loading={loading}
        onSitePaymentDetailTypeCategories={onSitePaymentDetailTypeCategories}
      />
    </DashboardLayout>
  );
};
