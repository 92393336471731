import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { red } from "@ant-design/colors";
import { CloseCircleOutlined } from "@ant-design/icons";
import { MasterCsvError } from "models/menuMasterCsv";

import { Spacer } from "components/Spacer";
import { Table } from "components/Table";

type Props = {
  visible: boolean;
  errors: MasterCsvError[];
  closeModal: () => void;
};

const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  color: ${red[5]};
  font-size: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const columns: ColumnsType<MasterCsvError> = [
  {
    title: "行数",
    dataIndex: "row",
    width: 70,
  },
  {
    title: "項目",
    dataIndex: "column",
  },
  {
    title: "エラー内容",
    dataIndex: "message",
  },
];

export const VerifyMenuCsvFailureModal = memo(({ visible, errors, closeModal }: Props) => {
  const errorsWithRowKeys = useMemo(
    () => errors.map((error) => ({ ...error, rowKey: `${error.row}-${error.column}` })),
    [errors],
  );
  return (
    <Modal
      width={720}
      title={
        <TitleContainer>
          <StyledCloseCircleOutlined />
          <Spacer size={16} />
          メニュー一括登録に失敗しました
        </TitleContainer>
      }
      centered
      closable={false}
      open={visible}
      footer={<Button onClick={closeModal}>閉じる</Button>}
      onCancel={closeModal}
    >
      <ContentWrapper>
        {/* Modal のタイトルと左を揃える */}
        <Spacer size={38} />
        アップロードした CSV ファイルにエラーが検出されました。
        <br />
        データを修正して再度アップロードしてください。
      </ContentWrapper>
      <Spacer size={16} />
      <Table dataSource={errorsWithRowKeys} columns={columns} rowKey="rowKey" />
    </Modal>
  );
});
