import { useMemo, useState } from "react";
import constate from "constate";

export const [CompanySelectOverlayProvider, useCompanySelectOverlay] = constate(() => {
  const [isCompanySelectOverlayVisible, setCompanySelectOverlayVisible] = useState(false);

  return useMemo(
    () => ({ isCompanySelectOverlayVisible, setCompanySelectOverlayVisible }),
    [isCompanySelectOverlayVisible],
  );
});
