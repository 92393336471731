import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";
import { FoodingJournalConfigInsertInput, Maybe } from "types/graphql";

import { FoodingJournalConfig } from "./types";

export type FoodingJournalConfigFormValues = Pick<
  Partial<FoodingJournalConfig>,
  | "shopCode"
  | "posNumber"
  | "configId"
  | "configPassword"
  | "configSecretKey"
  | "inputUrl"
  | "enabled"
>;

export const FoodingJournalConfigFormItem = createFormItem<FoodingJournalConfigFormValues>();

export const useFoodingJournalConfigForm = ({
  foodingJournalConfig,
  onSubmit,
  onFormValidationError,
}: {
  foodingJournalConfig?: Maybe<FoodingJournalConfig>;
  onSubmit: (args: Omit<FoodingJournalConfigInsertInput, "shopId">) => Promise<void>;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm();

  const initialValues = useMemo<FoodingJournalConfigFormValues>(
    () => ({
      shopCode: foodingJournalConfig?.shopCode,
      posNumber: foodingJournalConfig?.posNumber,
      configId: foodingJournalConfig?.configId,
      configPassword: foodingJournalConfig?.configPassword,
      configSecretKey: foodingJournalConfig?.configSecretKey,
      inputUrl: foodingJournalConfig?.inputUrl,
      enabled: foodingJournalConfig?.enabled ?? false,
    }),
    [foodingJournalConfig],
  );

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue() as FoodingJournalConfigFormValues;

      await onSubmit({
        shopCode: values.shopCode ?? "",
        posNumber: values.posNumber ?? "",
        configId: values.configId ?? "",
        configPassword: values.configPassword ?? "",
        configSecretKey: values.configSecretKey ?? "",
        inputUrl: values.inputUrl ?? "",
        enabled: values.enabled ?? false,
      });
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onSubmit, onFormValidationError]);

  return { form, initialValues, submit };
};
