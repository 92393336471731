export const parseInteger = (
  value: string | undefined,
  parse: (value: string) => string,
  defaultValue = 0,
) => {
  if (value === undefined) return defaultValue;

  const parsedValue = parse(value);
  if (Number.isNaN(Number(parsedValue))) return defaultValue;
  if (!Number.isInteger(Number(parsedValue))) return Number.parseInt(parsedValue);

  return Number(parsedValue);
};
