import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { EditShopForm } from "pages/EditShop/EditShopForm";
import { useEditShopGetShopQuery, useEditShopUpdateShopMutation } from "pages/EditShop/queries";
import { ShopSetInput } from "types/graphql";

export const EditShop = () => {
  const { id: shopId } = useParams<{ id: string }>();

  const {
    data: getShopData,
    loading: loadingShop,
    error,
  } = useEditShopGetShopQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shop = getShopData?.shop_by_pk;

  useSetCurrentCompanyEffect(shop);

  const [updateShopMutation, { loading: loadingUpdateShop }] = useEditShopUpdateShopMutation();

  const onSubmit = useCallback(
    async (shop: ShopSetInput) => {
      if (shopId) {
        try {
          await updateShopMutation({
            variables: { shopId, shop },
          });

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [shopId, updateShopMutation],
  );
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loadingShop && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <EditShopForm
          shop={shop}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateShop}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
