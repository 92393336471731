import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const FoodingJournalUploadGetShops = gql`
    query FoodingJournalUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    foodingJournalConfig {
      id
      enabled
    }
  }
}
    `;
export const FoodingJournalUpload = gql`
    mutation FoodingJournalUpload($shopId: String!, $targetDate: String!) {
  foodingJournalUploadSales(input: {shopId: $shopId, targetDate: $targetDate})
}
    `;
export type FoodingJournalUploadGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type FoodingJournalUploadGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shops: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { foodingJournalConfig?: Types.Maybe<(
      { __typename?: 'foodingJournalConfig' }
      & Pick<Types.FoodingJournalConfig, 'id' | 'enabled'>
    )> }
  )> }
);

export type FoodingJournalUploadMutationVariables = Types.Exact<{
  shopId: Types.Scalars['String'];
  targetDate: Types.Scalars['String'];
}>;


export type FoodingJournalUploadMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'foodingJournalUploadSales'>
);


export const FoodingJournalUploadGetShopsDocument = gql`
    query FoodingJournalUploadGetShops($companyId: uuid!) {
  shops: shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    foodingJournalConfig {
      id
      enabled
    }
  }
}
    `;

/**
 * __useFoodingJournalUploadGetShopsQuery__
 *
 * To run a query within a React component, call `useFoodingJournalUploadGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalUploadGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodingJournalUploadGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useFoodingJournalUploadGetShopsQuery(baseOptions: Apollo.QueryHookOptions<FoodingJournalUploadGetShopsQuery, FoodingJournalUploadGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodingJournalUploadGetShopsQuery, FoodingJournalUploadGetShopsQueryVariables>(FoodingJournalUploadGetShopsDocument, options);
      }
export function useFoodingJournalUploadGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodingJournalUploadGetShopsQuery, FoodingJournalUploadGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodingJournalUploadGetShopsQuery, FoodingJournalUploadGetShopsQueryVariables>(FoodingJournalUploadGetShopsDocument, options);
        }
export type FoodingJournalUploadGetShopsQueryHookResult = ReturnType<typeof useFoodingJournalUploadGetShopsQuery>;
export type FoodingJournalUploadGetShopsLazyQueryHookResult = ReturnType<typeof useFoodingJournalUploadGetShopsLazyQuery>;
export type FoodingJournalUploadGetShopsQueryResult = Apollo.QueryResult<FoodingJournalUploadGetShopsQuery, FoodingJournalUploadGetShopsQueryVariables>;
export const FoodingJournalUploadDocument = gql`
    mutation FoodingJournalUpload($shopId: String!, $targetDate: String!) {
  foodingJournalUploadSales(input: {shopId: $shopId, targetDate: $targetDate})
}
    `;
export type FoodingJournalUploadMutationFn = Apollo.MutationFunction<FoodingJournalUploadMutation, FoodingJournalUploadMutationVariables>;

/**
 * __useFoodingJournalUploadMutation__
 *
 * To run a mutation, you first call `useFoodingJournalUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFoodingJournalUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [foodingJournalUploadMutation, { data, loading, error }] = useFoodingJournalUploadMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      targetDate: // value for 'targetDate'
 *   },
 * });
 */
export function useFoodingJournalUploadMutation(baseOptions?: Apollo.MutationHookOptions<FoodingJournalUploadMutation, FoodingJournalUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FoodingJournalUploadMutation, FoodingJournalUploadMutationVariables>(FoodingJournalUploadDocument, options);
      }
export type FoodingJournalUploadMutationHookResult = ReturnType<typeof useFoodingJournalUploadMutation>;
export type FoodingJournalUploadMutationResult = Apollo.MutationResult<FoodingJournalUploadMutation>;
export type FoodingJournalUploadMutationOptions = Apollo.BaseMutationOptions<FoodingJournalUploadMutation, FoodingJournalUploadMutationVariables>;