import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanOptionsGetPlan = gql`
    query PlanOptionsGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      categoryId
      name
    }
    companyId
    description
    displayType
    imageUrl
    planId
    planName
    planTime
    receiptDisplayName
    shopSideName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopId
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      inputType
      maxChoiceNum
      minChoiceNum
      name
      planId
      _planId
      planOptionId
      priority
      receiptDisplayName
      translations {
        columnName
        locale
        value
      }
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        imageUrl
        isDefaultSelection
        maxOrderableNum
        name
        planChoiceId
        planOptionId
        _planOptionId
        price
        priority
        receiptDisplayName
        shopPlanChoices(where: {archivedAt: {_is_null: true}}) {
          id
        }
      }
    }
  }
}
    `;
export const PlanOptionsInsertPlanChoices = gql`
    mutation PlanOptionsInsertPlanChoices($planChoices: [planChoice_insert_input!]!) {
  insert_planChoice(
    objects: $planChoices
    on_conflict: {constraint: idx_46612_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planChoiceId
    }
  }
}
    `;
export const PlanOptionsInsertPlanOptions = gql`
    mutation PlanOptionsInsertPlanOptions($planOptions: [planOption_insert_input!]!) {
  insert_planOption(
    objects: $planOptions
    on_conflict: {constraint: idx_46636_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planOptionId
    }
  }
}
    `;
export const PlanOptionsDeletePlanOption = gql`
    mutation PlanOptionsDeletePlanOption($planOptionId: Int!, $archivedAt: timestamptz!) {
  update_planOption(
    _set: {archivedAt: $archivedAt}
    where: {planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  delete_shopPlanOptionKitchenRole(where: {_planOptionId: {_eq: $planOptionId}}) {
    affected_rows
  }
  delete_shopPlanOption(where: {_planOptionId: {_eq: $planOptionId}}) {
    affected_rows
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuPlanChoices: {planChoice: {_planOptionId: {_eq: $planOptionId}}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuPlanChoice(
    where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}
  ) {
    affected_rows
  }
  delete_winboardMenu(where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(
    where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}
  ) {
    affected_rows
  }
}
    `;
export const PlanOptionsDeletePlanChoice = gql`
    mutation PlanOptionsDeletePlanChoice($planChoiceId: Int!, $archivedAt: timestamptz!, $shopPlanChoiceIds: [String!]!) {
  update_planChoice(
    _set: {archivedAt: $archivedAt}
    where: {planChoiceId: {_eq: $planChoiceId}}
  ) {
    affected_rows
  }
  deleteShopPlanChoices(input: {shopPlanChoiceIds: $shopPlanChoiceIds}) {
    result
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuPlanChoices: {_planChoiceId: {_eq: $planChoiceId}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuPlanChoice(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
}
    `;
export type PlanOptionsGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PlanOptionsGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'displayType' | 'imageUrl' | 'planId' | 'planName' | 'planTime' | 'receiptDisplayName' | 'shopSideName'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'categoryId' | 'name'>
    ), shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & Pick<Types.ShopPlan, 'shopId'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )>, planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'inputType' | 'maxChoiceNum' | 'minChoiceNum' | 'name' | 'planId' | '_planId' | 'planOptionId' | 'priority' | 'receiptDisplayName'>
      & { translations: Array<(
        { __typename?: 'translation' }
        & Pick<Types.Translation, 'columnName' | 'locale' | 'value'>
      )>, planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'imageUrl' | 'isDefaultSelection' | 'maxOrderableNum' | 'name' | 'planChoiceId' | 'planOptionId' | '_planOptionId' | 'price' | 'priority' | 'receiptDisplayName'>
        & { shopPlanChoices: Array<(
          { __typename?: 'shopPlanChoice' }
          & Pick<Types.ShopPlanChoice, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type PlanOptionsInsertPlanChoicesMutationVariables = Types.Exact<{
  planChoices: Array<Types.PlanChoiceInsertInput> | Types.PlanChoiceInsertInput;
}>;


export type PlanOptionsInsertPlanChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planChoice' }
      & Pick<Types.PlanChoice, 'planChoiceId'>
    )> }
  )> }
);

export type PlanOptionsInsertPlanOptionsMutationVariables = Types.Exact<{
  planOptions: Array<Types.PlanOptionInsertInput> | Types.PlanOptionInsertInput;
}>;


export type PlanOptionsInsertPlanOptionsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planOption?: Types.Maybe<(
    { __typename?: 'planOption_mutation_response' }
    & Pick<Types.PlanOptionMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId'>
    )> }
  )> }
);

export type PlanOptionsDeletePlanOptionMutationVariables = Types.Exact<{
  planOptionId: Types.Scalars['Int'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type PlanOptionsDeletePlanOptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_planOption?: Types.Maybe<(
    { __typename?: 'planOption_mutation_response' }
    & Pick<Types.PlanOptionMutationResponse, 'affected_rows'>
  )>, delete_shopPlanOptionKitchenRole?: Types.Maybe<(
    { __typename?: 'shopPlanOptionKitchenRole_mutation_response' }
    & Pick<Types.ShopPlanOptionKitchenRoleMutationResponse, 'affected_rows'>
  )>, delete_shopPlanOption?: Types.Maybe<(
    { __typename?: 'shopPlanOption_mutation_response' }
    & Pick<Types.ShopPlanOptionMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenuPlanChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlanChoice_mutation_response' }
    & Pick<Types.PikaichiMenuPlanChoiceMutationResponse, 'affected_rows'>
  )>, delete_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationMenu?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu_mutation_response' }
    & Pick<Types.TecAggregationMenuMutationResponse, 'affected_rows'>
  )> }
);

export type PlanOptionsDeletePlanChoiceMutationVariables = Types.Exact<{
  planChoiceId: Types.Scalars['Int'];
  archivedAt: Types.Scalars['timestamptz'];
  shopPlanChoiceIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type PlanOptionsDeletePlanChoiceMutation = (
  { __typename?: 'mutation_root' }
  & { update_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
  )>, deleteShopPlanChoices: (
    { __typename?: 'DeleteShopPlanChoicesResult' }
    & Pick<Types.DeleteShopPlanChoicesResult, 'result'>
  ), delete_pikaichiMenu?: Types.Maybe<(
    { __typename?: 'pikaichiMenu_mutation_response' }
    & Pick<Types.PikaichiMenuMutationResponse, 'affected_rows'>
  )>, delete_pikaichiMenuPlanChoice?: Types.Maybe<(
    { __typename?: 'pikaichiMenuPlanChoice_mutation_response' }
    & Pick<Types.PikaichiMenuPlanChoiceMutationResponse, 'affected_rows'>
  )>, delete_winboardMenu?: Types.Maybe<(
    { __typename?: 'winboardMenu_mutation_response' }
    & Pick<Types.WinboardMenuMutationResponse, 'affected_rows'>
  )>, delete_tecAggregationMenu?: Types.Maybe<(
    { __typename?: 'tecAggregationMenu_mutation_response' }
    & Pick<Types.TecAggregationMenuMutationResponse, 'affected_rows'>
  )> }
);


export const PlanOptionsGetPlanDocument = gql`
    query PlanOptionsGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      categoryId
      name
    }
    companyId
    description
    displayType
    imageUrl
    planId
    planName
    planTime
    receiptDisplayName
    shopSideName
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shopId
      shop {
        shopId
        name
      }
    }
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      inputType
      maxChoiceNum
      minChoiceNum
      name
      planId
      _planId
      planOptionId
      priority
      receiptDisplayName
      translations {
        columnName
        locale
        value
      }
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        imageUrl
        isDefaultSelection
        maxOrderableNum
        name
        planChoiceId
        planOptionId
        _planOptionId
        price
        priority
        receiptDisplayName
        shopPlanChoices(where: {archivedAt: {_is_null: true}}) {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePlanOptionsGetPlanQuery__
 *
 * To run a query within a React component, call `usePlanOptionsGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanOptionsGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanOptionsGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanOptionsGetPlanQuery(baseOptions: Apollo.QueryHookOptions<PlanOptionsGetPlanQuery, PlanOptionsGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanOptionsGetPlanQuery, PlanOptionsGetPlanQueryVariables>(PlanOptionsGetPlanDocument, options);
      }
export function usePlanOptionsGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanOptionsGetPlanQuery, PlanOptionsGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanOptionsGetPlanQuery, PlanOptionsGetPlanQueryVariables>(PlanOptionsGetPlanDocument, options);
        }
export type PlanOptionsGetPlanQueryHookResult = ReturnType<typeof usePlanOptionsGetPlanQuery>;
export type PlanOptionsGetPlanLazyQueryHookResult = ReturnType<typeof usePlanOptionsGetPlanLazyQuery>;
export type PlanOptionsGetPlanQueryResult = Apollo.QueryResult<PlanOptionsGetPlanQuery, PlanOptionsGetPlanQueryVariables>;
export const PlanOptionsInsertPlanChoicesDocument = gql`
    mutation PlanOptionsInsertPlanChoices($planChoices: [planChoice_insert_input!]!) {
  insert_planChoice(
    objects: $planChoices
    on_conflict: {constraint: idx_46612_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planChoiceId
    }
  }
}
    `;
export type PlanOptionsInsertPlanChoicesMutationFn = Apollo.MutationFunction<PlanOptionsInsertPlanChoicesMutation, PlanOptionsInsertPlanChoicesMutationVariables>;

/**
 * __usePlanOptionsInsertPlanChoicesMutation__
 *
 * To run a mutation, you first call `usePlanOptionsInsertPlanChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanOptionsInsertPlanChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planOptionsInsertPlanChoicesMutation, { data, loading, error }] = usePlanOptionsInsertPlanChoicesMutation({
 *   variables: {
 *      planChoices: // value for 'planChoices'
 *   },
 * });
 */
export function usePlanOptionsInsertPlanChoicesMutation(baseOptions?: Apollo.MutationHookOptions<PlanOptionsInsertPlanChoicesMutation, PlanOptionsInsertPlanChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanOptionsInsertPlanChoicesMutation, PlanOptionsInsertPlanChoicesMutationVariables>(PlanOptionsInsertPlanChoicesDocument, options);
      }
export type PlanOptionsInsertPlanChoicesMutationHookResult = ReturnType<typeof usePlanOptionsInsertPlanChoicesMutation>;
export type PlanOptionsInsertPlanChoicesMutationResult = Apollo.MutationResult<PlanOptionsInsertPlanChoicesMutation>;
export type PlanOptionsInsertPlanChoicesMutationOptions = Apollo.BaseMutationOptions<PlanOptionsInsertPlanChoicesMutation, PlanOptionsInsertPlanChoicesMutationVariables>;
export const PlanOptionsInsertPlanOptionsDocument = gql`
    mutation PlanOptionsInsertPlanOptions($planOptions: [planOption_insert_input!]!) {
  insert_planOption(
    objects: $planOptions
    on_conflict: {constraint: idx_46636_PRIMARY, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      planOptionId
    }
  }
}
    `;
export type PlanOptionsInsertPlanOptionsMutationFn = Apollo.MutationFunction<PlanOptionsInsertPlanOptionsMutation, PlanOptionsInsertPlanOptionsMutationVariables>;

/**
 * __usePlanOptionsInsertPlanOptionsMutation__
 *
 * To run a mutation, you first call `usePlanOptionsInsertPlanOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanOptionsInsertPlanOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planOptionsInsertPlanOptionsMutation, { data, loading, error }] = usePlanOptionsInsertPlanOptionsMutation({
 *   variables: {
 *      planOptions: // value for 'planOptions'
 *   },
 * });
 */
export function usePlanOptionsInsertPlanOptionsMutation(baseOptions?: Apollo.MutationHookOptions<PlanOptionsInsertPlanOptionsMutation, PlanOptionsInsertPlanOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanOptionsInsertPlanOptionsMutation, PlanOptionsInsertPlanOptionsMutationVariables>(PlanOptionsInsertPlanOptionsDocument, options);
      }
export type PlanOptionsInsertPlanOptionsMutationHookResult = ReturnType<typeof usePlanOptionsInsertPlanOptionsMutation>;
export type PlanOptionsInsertPlanOptionsMutationResult = Apollo.MutationResult<PlanOptionsInsertPlanOptionsMutation>;
export type PlanOptionsInsertPlanOptionsMutationOptions = Apollo.BaseMutationOptions<PlanOptionsInsertPlanOptionsMutation, PlanOptionsInsertPlanOptionsMutationVariables>;
export const PlanOptionsDeletePlanOptionDocument = gql`
    mutation PlanOptionsDeletePlanOption($planOptionId: Int!, $archivedAt: timestamptz!) {
  update_planOption(
    _set: {archivedAt: $archivedAt}
    where: {planOptionId: {_eq: $planOptionId}}
  ) {
    affected_rows
  }
  delete_shopPlanOptionKitchenRole(where: {_planOptionId: {_eq: $planOptionId}}) {
    affected_rows
  }
  delete_shopPlanOption(where: {_planOptionId: {_eq: $planOptionId}}) {
    affected_rows
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuPlanChoices: {planChoice: {_planOptionId: {_eq: $planOptionId}}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuPlanChoice(
    where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}
  ) {
    affected_rows
  }
  delete_winboardMenu(where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}) {
    affected_rows
  }
  delete_tecAggregationMenu(
    where: {planChoice: {_planOptionId: {_eq: $planOptionId}}}
  ) {
    affected_rows
  }
}
    `;
export type PlanOptionsDeletePlanOptionMutationFn = Apollo.MutationFunction<PlanOptionsDeletePlanOptionMutation, PlanOptionsDeletePlanOptionMutationVariables>;

/**
 * __usePlanOptionsDeletePlanOptionMutation__
 *
 * To run a mutation, you first call `usePlanOptionsDeletePlanOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanOptionsDeletePlanOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planOptionsDeletePlanOptionMutation, { data, loading, error }] = usePlanOptionsDeletePlanOptionMutation({
 *   variables: {
 *      planOptionId: // value for 'planOptionId'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function usePlanOptionsDeletePlanOptionMutation(baseOptions?: Apollo.MutationHookOptions<PlanOptionsDeletePlanOptionMutation, PlanOptionsDeletePlanOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanOptionsDeletePlanOptionMutation, PlanOptionsDeletePlanOptionMutationVariables>(PlanOptionsDeletePlanOptionDocument, options);
      }
export type PlanOptionsDeletePlanOptionMutationHookResult = ReturnType<typeof usePlanOptionsDeletePlanOptionMutation>;
export type PlanOptionsDeletePlanOptionMutationResult = Apollo.MutationResult<PlanOptionsDeletePlanOptionMutation>;
export type PlanOptionsDeletePlanOptionMutationOptions = Apollo.BaseMutationOptions<PlanOptionsDeletePlanOptionMutation, PlanOptionsDeletePlanOptionMutationVariables>;
export const PlanOptionsDeletePlanChoiceDocument = gql`
    mutation PlanOptionsDeletePlanChoice($planChoiceId: Int!, $archivedAt: timestamptz!, $shopPlanChoiceIds: [String!]!) {
  update_planChoice(
    _set: {archivedAt: $archivedAt}
    where: {planChoiceId: {_eq: $planChoiceId}}
  ) {
    affected_rows
  }
  deleteShopPlanChoices(input: {shopPlanChoiceIds: $shopPlanChoiceIds}) {
    result
  }
  delete_pikaichiMenu(
    where: {pikaichiMenuPlanChoices: {_planChoiceId: {_eq: $planChoiceId}}}
  ) {
    affected_rows
  }
  delete_pikaichiMenuPlanChoice(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
  delete_winboardMenu(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
  delete_tecAggregationMenu(where: {_planChoiceId: {_eq: $planChoiceId}}) {
    affected_rows
  }
}
    `;
export type PlanOptionsDeletePlanChoiceMutationFn = Apollo.MutationFunction<PlanOptionsDeletePlanChoiceMutation, PlanOptionsDeletePlanChoiceMutationVariables>;

/**
 * __usePlanOptionsDeletePlanChoiceMutation__
 *
 * To run a mutation, you first call `usePlanOptionsDeletePlanChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanOptionsDeletePlanChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planOptionsDeletePlanChoiceMutation, { data, loading, error }] = usePlanOptionsDeletePlanChoiceMutation({
 *   variables: {
 *      planChoiceId: // value for 'planChoiceId'
 *      archivedAt: // value for 'archivedAt'
 *      shopPlanChoiceIds: // value for 'shopPlanChoiceIds'
 *   },
 * });
 */
export function usePlanOptionsDeletePlanChoiceMutation(baseOptions?: Apollo.MutationHookOptions<PlanOptionsDeletePlanChoiceMutation, PlanOptionsDeletePlanChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanOptionsDeletePlanChoiceMutation, PlanOptionsDeletePlanChoiceMutationVariables>(PlanOptionsDeletePlanChoiceDocument, options);
      }
export type PlanOptionsDeletePlanChoiceMutationHookResult = ReturnType<typeof usePlanOptionsDeletePlanChoiceMutation>;
export type PlanOptionsDeletePlanChoiceMutationResult = Apollo.MutationResult<PlanOptionsDeletePlanChoiceMutation>;
export type PlanOptionsDeletePlanChoiceMutationOptions = Apollo.BaseMutationOptions<PlanOptionsDeletePlanChoiceMutation, PlanOptionsDeletePlanChoiceMutationVariables>;