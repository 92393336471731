import React, { memo } from "react";
import { Button } from "antd";

import { EditOptionTecAggregationOptionFormItem } from "../useEditOptionTecAggregationOptionForm";

type Props = {
  handleGetAvailableMenuCodeButtonPressed: () => void;
};

export const TecAggregationGetAvailableMenuCodeButtonField = memo<Props>(
  ({ handleGetAvailableMenuCodeButtonPressed }) => (
    <EditOptionTecAggregationOptionFormItem
      name="tecAggregationGetAvailableMenuCdButton"
      style={{ textAlign: "right" }}
      endSpacer={null}
    >
      <Button onClick={handleGetAvailableMenuCodeButtonPressed}>メニューコード自動採番</Button>
    </EditOptionTecAggregationOptionFormItem>
  ),
);
