import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { Button, Col, InputNumber, Row, Select, Space } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";

import { membershipRanks } from "../../../models/membershipRank";
import { Coupon, MembershipRankConfig } from "../types";

import {
  EditMembershipRankConfigFormItem,
  EditMembershipRankConfigFormValues,
  useEditMembershipRankConfigForm,
} from "./useEditMembershipRankConfigForm";

type Props = {
  rankConfig: MembershipRankConfig;
  coupons: Coupon;
  loading: boolean;
  onSubmit: ({ input }: { input: EditMembershipRankConfigFormValues }) => void;
  onCancel: () => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const StyledEditMembershipRankConfigFormItem = styled(EditMembershipRankConfigFormItem.NonProperty)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EditMembershipRankConfigForm = memo(
  ({ rankConfig, coupons, loading, onSubmit, onCancel, onFormValidationError }: Props) => {
    const { form, initialValues, submit } = useEditMembershipRankConfigForm(rankConfig, onSubmit);
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, submit, onFormValidationError]);
    return (
      <>
        <FormContent>
          <Form
            name="membershipRankConfig"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <Row>
              <Col span={24}>
                <EditMembershipRankConfigFormItem label="会員ランク名" name="membershipRank">
                  <Select
                    disabled={true}
                    options={Object.entries(membershipRanks).map(([key, value]) => ({
                      label: value,
                      value: key,
                    }))}
                  />
                </EditMembershipRankConfigFormItem>
              </Col>

              <Col span={24}>
                <StyledEditMembershipRankConfigFormItem label="来店回数">
                  <Space size="small">
                    <EditMembershipRankConfigFormItem name="requiredVisitCount" noStyle>
                      <InputNumber
                        min={1}
                        disabled={!isFeatureEnabled("editMembershipRankRequiredVisitCount")}
                      />
                    </EditMembershipRankConfigFormItem>
                    回以上
                  </Space>
                </StyledEditMembershipRankConfigFormItem>
              </Col>

              <Col span={24}>
                <EditMembershipRankConfigFormItem label="クーポン" name="rewardCouponId">
                  <Select
                    placeholder="クーポンを選択してください"
                    options={coupons.map(({ id, name }) => ({ label: name, value: id }))}
                    allowClear
                  />
                </EditMembershipRankConfigFormItem>
              </Col>
            </Row>
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onCancel}>キャンセル</Button>
          <Button
            type="primary"
            onClick={loading ? undefined : handleSubmit}
            loading={loading}
            disabled={loading}
          >
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
