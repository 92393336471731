import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PlanFirstOrderCategoryMenusGetPlan = gql`
    query PlanFirstOrderCategoryMenusGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      id
      categoryId
      name
    }
    companyId
    description
    planId
    planName
    planTime
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        imageUrl
        isDefaultSelection
        name
        planChoiceId
        planOptionId
        _planOptionId
        price
        priority
        receiptDisplayName
        countAsFirstOrderMenuQuantity
      }
    }
    planFirstOrderCategoryMenus(
      where: {categoryMenu: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}}
      order_by: [{priority: asc}, {_categoryMenuId: asc}]
    ) {
      id
      priority
      categoryMenu {
        id
        categoryMenuId
        menu {
          id
          menuId
          name
        }
        category {
          id
          categoryId
          name
        }
      }
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;
export const PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu = gql`
    mutation PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu($planId: Int!, $categoryMenuId: Int!) {
  delete_planFirstOrderCategoryMenu(
    where: {_and: [{_planId: {_eq: $planId}, _categoryMenuId: {_eq: $categoryMenuId}}]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export const PlanFirstOrderCategoryMenusInsertPlanChoices = gql`
    mutation PlanFirstOrderCategoryMenusInsertPlanChoices($planChoices: [planChoice_insert_input!]!) {
  insert_planChoice(
    objects: $planChoices
    on_conflict: {constraint: idx_46612_PRIMARY, update_columns: [countAsFirstOrderMenuQuantity]}
  ) {
    affected_rows
    returning {
      planChoiceId
    }
  }
}
    `;
export const PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenus = gql`
    mutation PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenus($planFirstOrderCategoryMenus: [planFirstOrderCategoryMenu_insert_input!]!) {
  insert_planFirstOrderCategoryMenu(
    objects: $planFirstOrderCategoryMenus
    on_conflict: {constraint: planFirstOrderCategoryMenu_planId_categoryMenuId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export type PlanFirstOrderCategoryMenusGetPlanQueryVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
}>;


export type PlanFirstOrderCategoryMenusGetPlanQuery = (
  { __typename?: 'query_root' }
  & { plan: Array<(
    { __typename?: 'plan' }
    & Pick<Types.Plan, 'id' | 'totalDealingShopCount' | 'companyId' | 'description' | 'planId' | 'planName' | 'planTime'>
    & { category: (
      { __typename?: 'category' }
      & Pick<Types.Category, 'id' | 'categoryId' | 'name'>
    ), planOptions: Array<(
      { __typename?: 'planOption' }
      & Pick<Types.PlanOption, 'planOptionId' | 'name'>
      & { planChoices: Array<(
        { __typename?: 'planChoice' }
        & Pick<Types.PlanChoice, 'imageUrl' | 'isDefaultSelection' | 'name' | 'planChoiceId' | 'planOptionId' | '_planOptionId' | 'price' | 'priority' | 'receiptDisplayName' | 'countAsFirstOrderMenuQuantity'>
      )> }
    )>, planFirstOrderCategoryMenus: Array<(
      { __typename?: 'planFirstOrderCategoryMenu' }
      & Pick<Types.PlanFirstOrderCategoryMenu, 'id' | 'priority'>
      & { categoryMenu: (
        { __typename?: 'categoryMenu' }
        & Pick<Types.CategoryMenu, 'id' | 'categoryMenuId'>
        & { menu: (
          { __typename?: 'menu' }
          & Pick<Types.Menu, 'id' | 'menuId' | 'name'>
        ), category: (
          { __typename?: 'category' }
          & Pick<Types.Category, 'id' | 'categoryId' | 'name'>
        ) }
      ) }
    )>, shopPlans: Array<(
      { __typename?: 'shopPlan' }
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'shopId' | 'name'>
      ) }
    )> }
  )> }
);

export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationVariables = Types.Exact<{
  planId: Types.Scalars['Int'];
  categoryMenuId: Types.Scalars['Int'];
}>;


export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_planFirstOrderCategoryMenu?: Types.Maybe<(
    { __typename?: 'planFirstOrderCategoryMenu_mutation_response' }
    & Pick<Types.PlanFirstOrderCategoryMenuMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planFirstOrderCategoryMenu' }
      & { categoryMenuId: Types.PlanFirstOrderCategoryMenu['_categoryMenuId'] }
    )> }
  )> }
);

export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutationVariables = Types.Exact<{
  planChoices: Array<Types.PlanChoiceInsertInput> | Types.PlanChoiceInsertInput;
}>;


export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planChoice?: Types.Maybe<(
    { __typename?: 'planChoice_mutation_response' }
    & Pick<Types.PlanChoiceMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planChoice' }
      & Pick<Types.PlanChoice, 'planChoiceId'>
    )> }
  )> }
);

export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationVariables = Types.Exact<{
  planFirstOrderCategoryMenus: Array<Types.PlanFirstOrderCategoryMenuInsertInput> | Types.PlanFirstOrderCategoryMenuInsertInput;
}>;


export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_planFirstOrderCategoryMenu?: Types.Maybe<(
    { __typename?: 'planFirstOrderCategoryMenu_mutation_response' }
    & Pick<Types.PlanFirstOrderCategoryMenuMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'planFirstOrderCategoryMenu' }
      & { categoryMenuId: Types.PlanFirstOrderCategoryMenu['_categoryMenuId'] }
    )> }
  )> }
);


export const PlanFirstOrderCategoryMenusGetPlanDocument = gql`
    query PlanFirstOrderCategoryMenusGetPlan($planId: Int!) {
  plan(
    where: {planId: {_eq: $planId}, archivedAt: {_is_null: true}, category: {archivedAt: {_is_null: true}}}
    limit: 1
  ) {
    id
    totalDealingShopCount
    category {
      id
      categoryId
      name
    }
    companyId
    description
    planId
    planName
    planTime
    planOptions(
      where: {archivedAt: {_is_null: true}}
      order_by: [{priority: asc}, {planOptionId: asc}]
    ) {
      planOptionId
      name
      planChoices(
        where: {archivedAt: {_is_null: true}}
        order_by: [{priority: asc}, {planChoiceId: asc}]
      ) {
        imageUrl
        isDefaultSelection
        name
        planChoiceId
        planOptionId
        _planOptionId
        price
        priority
        receiptDisplayName
        countAsFirstOrderMenuQuantity
      }
    }
    planFirstOrderCategoryMenus(
      where: {categoryMenu: {menu: {archivedAt: {_is_null: true}}, category: {archivedAt: {_is_null: true}}}}
      order_by: [{priority: asc}, {_categoryMenuId: asc}]
    ) {
      id
      priority
      categoryMenu {
        id
        categoryMenuId
        menu {
          id
          menuId
          name
        }
        category {
          id
          categoryId
          name
        }
      }
    }
    shopPlans(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
    ) {
      shop {
        shopId
        name
      }
    }
  }
}
    `;

/**
 * __usePlanFirstOrderCategoryMenusGetPlanQuery__
 *
 * To run a query within a React component, call `usePlanFirstOrderCategoryMenusGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanFirstOrderCategoryMenusGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanFirstOrderCategoryMenusGetPlanQuery({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function usePlanFirstOrderCategoryMenusGetPlanQuery(baseOptions: Apollo.QueryHookOptions<PlanFirstOrderCategoryMenusGetPlanQuery, PlanFirstOrderCategoryMenusGetPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanFirstOrderCategoryMenusGetPlanQuery, PlanFirstOrderCategoryMenusGetPlanQueryVariables>(PlanFirstOrderCategoryMenusGetPlanDocument, options);
      }
export function usePlanFirstOrderCategoryMenusGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanFirstOrderCategoryMenusGetPlanQuery, PlanFirstOrderCategoryMenusGetPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanFirstOrderCategoryMenusGetPlanQuery, PlanFirstOrderCategoryMenusGetPlanQueryVariables>(PlanFirstOrderCategoryMenusGetPlanDocument, options);
        }
export type PlanFirstOrderCategoryMenusGetPlanQueryHookResult = ReturnType<typeof usePlanFirstOrderCategoryMenusGetPlanQuery>;
export type PlanFirstOrderCategoryMenusGetPlanLazyQueryHookResult = ReturnType<typeof usePlanFirstOrderCategoryMenusGetPlanLazyQuery>;
export type PlanFirstOrderCategoryMenusGetPlanQueryResult = Apollo.QueryResult<PlanFirstOrderCategoryMenusGetPlanQuery, PlanFirstOrderCategoryMenusGetPlanQueryVariables>;
export const PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuDocument = gql`
    mutation PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenu($planId: Int!, $categoryMenuId: Int!) {
  delete_planFirstOrderCategoryMenu(
    where: {_and: [{_planId: {_eq: $planId}, _categoryMenuId: {_eq: $categoryMenuId}}]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationFn = Apollo.MutationFunction<PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation, PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationVariables>;

/**
 * __usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation__
 *
 * To run a mutation, you first call `usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation, { data, loading, error }] = usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      categoryMenuId: // value for 'categoryMenuId'
 *   },
 * });
 */
export function usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation(baseOptions?: Apollo.MutationHookOptions<PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation, PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation, PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationVariables>(PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuDocument, options);
      }
export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationHookResult = ReturnType<typeof usePlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation>;
export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationResult = Apollo.MutationResult<PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation>;
export type PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationOptions = Apollo.BaseMutationOptions<PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutation, PlanFirstOrderCategoryMenusDeletePlanFirstOrderCategoryMenusMenuMutationVariables>;
export const PlanFirstOrderCategoryMenusInsertPlanChoicesDocument = gql`
    mutation PlanFirstOrderCategoryMenusInsertPlanChoices($planChoices: [planChoice_insert_input!]!) {
  insert_planChoice(
    objects: $planChoices
    on_conflict: {constraint: idx_46612_PRIMARY, update_columns: [countAsFirstOrderMenuQuantity]}
  ) {
    affected_rows
    returning {
      planChoiceId
    }
  }
}
    `;
export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutationFn = Apollo.MutationFunction<PlanFirstOrderCategoryMenusInsertPlanChoicesMutation, PlanFirstOrderCategoryMenusInsertPlanChoicesMutationVariables>;

/**
 * __usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation__
 *
 * To run a mutation, you first call `usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planFirstOrderCategoryMenusInsertPlanChoicesMutation, { data, loading, error }] = usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation({
 *   variables: {
 *      planChoices: // value for 'planChoices'
 *   },
 * });
 */
export function usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation(baseOptions?: Apollo.MutationHookOptions<PlanFirstOrderCategoryMenusInsertPlanChoicesMutation, PlanFirstOrderCategoryMenusInsertPlanChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanFirstOrderCategoryMenusInsertPlanChoicesMutation, PlanFirstOrderCategoryMenusInsertPlanChoicesMutationVariables>(PlanFirstOrderCategoryMenusInsertPlanChoicesDocument, options);
      }
export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutationHookResult = ReturnType<typeof usePlanFirstOrderCategoryMenusInsertPlanChoicesMutation>;
export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutationResult = Apollo.MutationResult<PlanFirstOrderCategoryMenusInsertPlanChoicesMutation>;
export type PlanFirstOrderCategoryMenusInsertPlanChoicesMutationOptions = Apollo.BaseMutationOptions<PlanFirstOrderCategoryMenusInsertPlanChoicesMutation, PlanFirstOrderCategoryMenusInsertPlanChoicesMutationVariables>;
export const PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusDocument = gql`
    mutation PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenus($planFirstOrderCategoryMenus: [planFirstOrderCategoryMenu_insert_input!]!) {
  insert_planFirstOrderCategoryMenu(
    objects: $planFirstOrderCategoryMenus
    on_conflict: {constraint: planFirstOrderCategoryMenu_planId_categoryMenuId_key, update_columns: [priority]}
  ) {
    affected_rows
    returning {
      categoryMenuId: _categoryMenuId
    }
  }
}
    `;
export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationFn = Apollo.MutationFunction<PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation, PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationVariables>;

/**
 * __usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation__
 *
 * To run a mutation, you first call `usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation, { data, loading, error }] = usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation({
 *   variables: {
 *      planFirstOrderCategoryMenus: // value for 'planFirstOrderCategoryMenus'
 *   },
 * });
 */
export function usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation(baseOptions?: Apollo.MutationHookOptions<PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation, PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation, PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationVariables>(PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusDocument, options);
      }
export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationHookResult = ReturnType<typeof usePlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation>;
export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationResult = Apollo.MutationResult<PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation>;
export type PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationOptions = Apollo.BaseMutationOptions<PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutation, PlanFirstOrderCategoryMenusInsertPlanFirstOrderCategoryMenusMutationVariables>;