import React, { memo } from "react";
import { Checkbox } from "antd";

import { EditDiniiPayConfigFormItem } from "../../useEditDiniiPayConfigForm";

export const UseGmoTerminalPaymentField = memo(() => (
  <EditDiniiPayConfigFormItem
    name={["terminalPaymentConfig", "useGmoTerminalPayment"]}
    valuePropName="checked"
  >
    <Checkbox>GMOを利用する</Checkbox>
  </EditDiniiPayConfigFormItem>
));
