import React, { memo, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";

const DEFAULT_KEY = "default";

const StyledMenu = styled(Menu)`
  white-space: nowrap;
  overflow-x: scroll;
  height: 50px;
`;

export const ShopMetricsMenu = memo(() => {
  const { category } = useParams<{ category?: string }>();
  const { pathname, state } = useLocation();

  const key = pathname.split("/")[3];
  const selectedKey = category || key || DEFAULT_KEY;
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <StyledMenu selectedKeys={selectedKeys} mode="horizontal" disabledOverflow>
      <Menu.Item key={DEFAULT_KEY}>
        <Link to="/questionnaireAnalytics/shop" replace state={state}>
          レポート
        </Link>
      </Menu.Item>
      <Menu.Item key="repeatability">
        <Link to="/questionnaireAnalytics/shop/repeatability" replace state={state}>
          再来店意欲
        </Link>
      </Menu.Item>
      <Menu.Item key="customerService">
        <Link to="/questionnaireAnalytics/shop/customerService" replace state={state}>
          接客
        </Link>
      </Menu.Item>
      <Menu.Item key="deliciousness">
        <Link to="/questionnaireAnalytics/shop/deliciousness" replace state={state}>
          料理
        </Link>
      </Menu.Item>
      <Menu.Item key="speed">
        <Link to="/questionnaireAnalytics/shop/speed" replace state={state}>
          提供速度
        </Link>
      </Menu.Item>
      <Menu.Item key="cleanliness">
        <Link to="/questionnaireAnalytics/shop/cleanliness" replace state={state}>
          清潔感
        </Link>
      </Menu.Item>
    </StyledMenu>
  );
});
